import { MODULE_STATUS } from '../../../constants/learningPoint'

export const breadcrumbList = [
  { title: 'Learning Point Management', link: '/', pointer: false },
  { title: 'ตั้งค่าคะแนน', link: '/', pointer: false },
]

export const breadcrumbListView = [
  { title: 'Learning Point Management', link: '/', pointer: false },
  {
    title: 'ตั้งค่าคะแนน',
    link: '/learning-point/setting-point',
    pointer: true,
  },
  { title: 'รายละเอียดคะแนน', link: '/', pointer: false },
]

export const breadcrumbListCreate = [
  { title: 'Learning Point Management', link: '/', pointer: false },
  {
    title: 'ตั้งค่าคะแนน',
    link: '/learning-point/setting-point',
    pointer: true,
  },
  { title: 'สร้าง คะแนน', link: '/', pointer: false },
]

export const breadcrumbListEdit = [
  { title: 'Learning Point Management', link: '/', pointer: false },
  {
    title: 'ตั้งค่าคะแนน',
    link: '/learning-point/setting-point',
    pointer: true,
  },
  { title: 'แก้ไข คะแนน', link: '/', pointer: false },
]

export const DEFUALT_MODULE_STATUS = [
  MODULE_STATUS.ACTIVE,
  MODULE_STATUS.INACTIVE,
  MODULE_STATUS.DELETED,
  MODULE_STATUS.DRAFT,
]

export const headerConfig = {
  headers: {
    'x-type': 'LEARNING_POINT',
  },
}
