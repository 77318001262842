import * as yup from 'yup'
import _ from 'lodash'
import dayjs from 'dayjs'
import { validateVideo } from '../../Module/Form/TypeContent/EmbedContent/events'
import { validateMinDate } from '../../components/events'

export const courseSchema = yup.array().of(
  yup.object().shape({
    course: yup.object().shape({
      uuid: yup
        .string()
        .test(
          'required',
          'กรุณาเลือกหลักสูตร',
          (value, { from }) => isDraft(from[2]?.value) || value,
        ),
    }),
  }),
)

export const settingSchema = yup.object().shape({
  distribution: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ช่องทางการจัดจำหน่าย อย่างน้อย 1 ช่อง',
      (value, { from }) => isDraft(from[1]?.value) || !_.isEmpty(value),
    ),
  remark: yup.string().max(3000, 'Remark กรอกได้ไม่เกิน 3000 ตัวอักษร'),
})

export const schema = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อ Learning path')
    .max(255, 'ชื่อ Learning pathกรอกได้ไม่เกิน 255 ตัวอักษร'),
  nameForBanner: yup
    .string()
    .max(60, 'ชื่อหัวข้อบน Banner กรอกได้ไม่เกิน 60 ตัวอักษร')
    .test(
      'required',
      'กรุณากรอกชื่อหัวข้อบน Banner',
      (value, { parent }) => isDraft(parent) || value,
    ),
  detailForBanner: yup
    .string()
    .max(255, 'ชื่อหัวข้อบน Banner กรอกได้ไม่เกิน 255 ตัวอักษร'),
  description: yup.string().max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  coverImage: yup
    .string()
    .test(
      'required',
      'กรุณาอัปโหลดรูปภาพหน้าปก',
      (value, { parent }) => isDraft(parent) || value,
    ),
  linkExampleVideo: yup
    .string()
    .test(
      'validate-link',
      'Link สำหรับวิดีโอตัวอย่างต้องมากจาก YouTube หรือ Vimeo เท่านั้น',
      (value, { parent }) =>
        isDraft(parent) || _.isEmpty(value) || validateVideo(value),
    )
    .max(3000, 'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  remark: yup.string().max(3000, 'Remark กรอกได้ไม่เกิน 3000 ตัวอักษร'),
  status: yup.string(),
  eLearningLearningPathCourses: courseSchema,
  periodStart: yup
    .string()
    .required('กรุณาเลือกวันเริ่มต้น')
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value) => _.isEmpty(value) || dayjs(value).isValid(),
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันเริ่มต้นย้อนหลังวันปัจจุบันได้',
      (value) => validateMinDate(value, 'eLearningPathForm.oldPeriodStart'),
    ),
  dueDateType: yup
    .string()
    .test(
      'required',
      'กรุณาเลือก',
      (value, { parent }) => isDraft(parent) || value,
    ),
  dueDate: yup
    .string()
    .nullable()
    .test('require-due-date', 'กรุณาเลือกวันที่', (value, { parent }) => {
      if (isDraft(parent)) return true
      if (parent?.dueDateType === 'NONE') return true
      return !_.isEmpty(value)
    })
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value, { parent }) => {
        if (isDraft(parent)) return true
        if (parent?.dueDateType === 'NONE') return true
        return _.isEmpty(value) || dayjs(value).isValid()
      },
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันย้อนหลังวันปัจจุบันได้',
      (value, { parent }) => {
        if (isDraft(parent)) return true
        if (parent?.dueDateType === 'NONE') return true
        return validateMinDate(value, 'eLearningPathForm.oldDueDate')
      },
    ),
  courseAccessExpiration: yup.string().nullable(),
  timeSpanType: yup.string().nullable(),
  timeSpan: yup.string().nullable(),
  levelOfLearner: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ระดับผู้เรียน อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  acquiredSkill: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ระดับหลักสูตรอย่างน้อย อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  productType: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ทักษะที่ได้จากหลักสูตร อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  setting: settingSchema,
  advanceNotificationCheck: yup.boolean(),
  advanceNotification: yup
    .number()
    .nullable()
    .test(
      'required',
      'กรุณาระบุจำนวน',
      (value, { parent }) =>
        !parent?.advanceNotificationCheck || !_.isNil(value),
    ),
  continuousNotificationsCheck: yup.boolean(),
  continuousNotifications: yup
    .number()
    .nullable()
    .test(
      'required',
      'กรุณาระบุจำนวน',
      (value, { parent }) =>
        !parent?.continuousNotificationsCheck || !_.isNil(value),
    ),
})

export const schemaDraft = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อหลักสูตร')
    .max(255, 'ชื่อหลักสูตรกรอกได้ไม่เกิน 255 ตัวอักษร'),
  status: yup.string(),
  periodStart: yup
    .string()
    .required('กรุณาเลือกวันเริ่มต้น')
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value) => _.isEmpty(value) || dayjs(value).isValid(),
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันเริ่มต้นย้อนหลังวันปัจจุบันได้',
      (value) => validateMinDate(value, 'eLearningPathForm.oldPeriodStart'),
    ),
})

export const isDraft = (parent) => _.get(parent, 'isDraft', false)
