import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'
import { StatusComponent } from '../../../../components/CRUD/components/StatusComponent'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  return (
    <>
      <ViewRow title="ID" value={formData.id} />
      <ViewRow title="Group Code" value={formData.groupCode} />
      <ViewRow title="Cost Center ID" value={formData.costCenter} />
      <ViewRow title="Cost Center Name" value={formData.descriptionName} />
      <ViewRow title="สถานะ" valueComponent={StatusComponent(formData)} />
      <ViewRow title="หมายเหตุ" value={formData.remark} />
      <Divider />
      <UpdatedComponent />
    </>
  )
}
