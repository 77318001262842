import {
  StyledCard,
} from '../../Module/Lib/CSS/Styled'
import Table from '../../../../../components/redux/Table'
import { useSelector } from 'react-redux'
import { fetchDataList } from './event'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import FilterDrawer from './FilterDrawer'
import { useParams } from 'react-router-dom'
import { 
  setSearchText,
  setFilterProp,
  resetTablePage,
  setStatusFilter,
  setDefaultSort,
  setSelected,
  setOrder,
} from '../../../../../redux/slices/table'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { status, filterProp, isFilterDrawer } = filter
  const manageProps = { uuid }
  const { searchText } = search

  const resetTable = async () => {
    dispatch(setSearchText(''))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(setStatusFilter([]))
    dispatch(setSelected([]))
    dispatch(setDefaultSort({ state: 0, id: '', active: false }))
    dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  }

  useEffect(() => {
    resetTable()
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        setInitial
      )
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList('fetch', table, page, status, filterProp, searchText)
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <StyledCard>
        <Table />
      </StyledCard>
      <FilterDrawer
          open={isFilterDrawer}
          table={table}
          manageProps={manageProps}
        />
    </>
  )
}

export default Index
