import _ from 'lodash'
import {
  setInitialPreview,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eTestingPreview'
import { store } from '../../../../App'

export const fetchTesting = (eTesting, isModule) => (dispatch) => {
  if (isModule) {
    const { eTestingUuid } = store.getState().eLearningPreview.selectedLesson
    if (eTestingUuid !== eTesting.uuid) return
  }

  dispatch(startLoading())
  const { information, questions, setting } = eTesting
  const { randomQuestion, randomAnswer } = setting

  let quesArr = questions
  if (randomQuestion) quesArr = _.shuffle(questions)

  const initialValues = quesArr.map((question, index) => {
    const type = _.get(question, 'questionType', '')
    let answArr = question.answers
    if (randomAnswer || type === 'SEQUENCE') {
      answArr = _.shuffle(question.answers)
    }

    const score =
      type?.includes('FILL_IN_BLANK') ||
      type === 'MATCHING' ||
      type === 'SEQUENCE'
        ? question.score * answArr.filter((ans) => ans.isCorrect).length
        : question.score

    const choices = answArr.map((choice, index) => ({
      ...choice,
      id: `id-${index + 1}`,
      uuid: Date.now() + index,
      subAnswers: _.get(choice, 'subAnswers', []).map((ans, id) => ({
        ...ans,
        uuid: Date.now() + id,
      })),
    }))

    let answer = []
    if (type === 'MULTIPLE') {
      answer = answArr.map((choice, index) => ({
        uuid: Date.now() + index,
        title: choice.title,
        isChecked: false,
      }))
    } else if (type === 'SEQUENCE') answer = choices

    return {
      uuid: question.uuid,
      key: question.key,
      sequence: index + 1,
      mediaType: question.mediaType,
      url: question.url,
      type,
      title: question.title,
      subTitle: question.subTitle,
      score,
      choices,
      answer,
      isAnswered: type === 'SEQUENCE',
    }
  })

  dispatch(
    setInitialPreview({
      uuid: eTesting.uuid,
      information,
      setting,
      testing: initialValues,
    }),
  )
  dispatch(stopLoading())
}
