import React from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TextInput from '../../../../../../components/Input/TextInput'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import { StyledColumn, StyledRow } from '../../../Styled'
import ActiveSwitch from '../../../../components/ActiveSwitch'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledFooter,
} from '../../Styled'
import {
  handleInputChange,
  handleSubmitData,
  handleSwitchChange,
} from './events'
import { schema } from './schema'

const TrainingDrawer = ({ data, isOpen, onCloseDrawer }) => {
  const productTypes = useSelector(
    (state) => state.manageContentHome.options.productTypes,
    shallowEqual
  )
  const no = _.get(data, 'no', 1)
  const dispatch = useDispatch()

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Formik
        initialValues={data}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          onCloseDrawer()
          dispatch(handleSubmitData(values, no))
        }}
      >
        {({ values, errors, setValues }) => (
          <Form>
            <StyledContent>
              <StyledHeadLabel>
                <Box id="header">
                  <Typography variant="h5">Platform {no}</Typography>
                </Box>
                <Divider />
              </StyledHeadLabel>
              <StyledColumn sx={{ width: 'auto', m: 3, mb: 0, gap: 3 }}>
                <StyledColumn sx={{ gap: 0.5 }}>
                  <TextInput
                    required
                    name="title"
                    type="basic"
                    labelText="Title"
                    id="input-title"
                    isShowTextError={false}
                    value={_.get(values, 'title', '')}
                    textError={_.get(errors, 'title', '')}
                    onChange={(e) => handleInputChange(e, setValues)}
                  />
                  <InfoOrErrorText
                    field="title"
                    info="แนะนำให้ใช้ข้อความที่มีความยาว 50 ตัวอักษร หรือน้อยกว่านั้นจึงจะสามารถแสดงได้พอดี"
                  />
                </StyledColumn>
                <StyledColumn sx={{ gap: 0.5 }}>
                  <TextInput
                    required
                    name="description"
                    type="basic"
                    labelText="Description"
                    id="input-description"
                    isShowTextError={false}
                    value={_.get(values, 'description', '')}
                    textError={_.get(errors, 'description', '')}
                    onChange={(e) => handleInputChange(e, setValues)}
                  />
                  <InfoOrErrorText
                    field="description"
                    info="แนะนำให้ใช้ข้อความที่มีความยาว 60 ตัวอักษร หรือน้อยกว่านั้นจึงจะสามารถแสดงได้พอดี"
                  />
                </StyledColumn>

                <StyledRow sx={{ gap: 3, pb: 3, alignItems: 'start' }}>
                  <SelectGroupChip
                    boxSx={{ width: 'calc(100% - 160px)' }}
                    required
                    name="productTypes"
                    labelText="ทักษะที่ได้จากหลักสูตร"
                    placeholder="กรุณาเลือก"
                    options={productTypes}
                    value={_.get(data, 'productTypes', '')}
                    textError={_.get(errors, 'productTypes', '')}
                    onChange={(productTypes) =>
                      setValues((val) => ({ ...val, productTypes }))
                    }
                  />

                  <ActiveSwitch
                    sx={{ mt: 3.5 }}
                    name="status"
                    dataTestId={`switch-training-${no}`}
                    checked={_.get(values, 'status', false)}
                    onChange={(e) => handleSwitchChange(e, setValues)}
                  />
                </StyledRow>
              </StyledColumn>
              <StyledFooter sx={{ zIndex: 0 }}>
                <Button
                  data-testid="btn-cancel"
                  variant="outlined"
                  onClick={() => {
                    onCloseDrawer()
                    setValues(data)
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  data-testid="btn-submit"
                  variant="contained"
                  type="submit"
                >
                  ตกลง
                </Button>
              </StyledFooter>
            </StyledContent>
          </Form>
        )}
      </Formik>
    </StyledDrawer>
  )
}

export default TrainingDrawer

export const InfoOrErrorText = ({ field, info }) => {
  const { errors } = useFormikContext()
  if (_.isUndefined(errors[field])) {
    return (
      <Typography variant="body2" color="text.secondary">
        {info}
      </Typography>
    )
  }

  return (
    <Typography variant="body2" color="error">
      {_.get(errors, field, '')}
    </Typography>
  )
}
