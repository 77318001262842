import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { assetType } from '../enum/assetTypEnum'
import { paymentMethodType } from '../enum/paymentMethod'
import { getBodyMutation } from '../mutation/get'
import { getMaxFileSize } from '../../../../../modules/ELearning/Module/Form/TypeContent/events'

export const setInitalValues = () => {
  store.dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
  const demoOption = [
    { label: 'option1', value: '1' },
    { label: 'option2', value: '2' },
    { label: 'option3', value: '3' },
  ]
  const demoOptionOwner = [
    {
      label: 'Name Surname',
      value: 'name',
      name: 'Name',
      lastname: 'Surname',
      email: 'email',
    },
    {
      label: 'Name Surname2',
      value: 'name2',
      name: 'Name2',
      lastname: 'Surname2',
      email: 'email2',
    },
  ]
  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {
        type: { isLoading: false, options: assetType },
        product: { isLoading: false, options: demoOption },
        holder: { isLoading: false, options: demoOptionOwner },
        payer: { isLoading: false, options: demoOptionOwner },
        paymentType: { isLoading: false, options: paymentMethodType },
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'isOpenFormDrawer',
      value: false,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'assetData',
      value: [],
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'assetEdit',
      value: '',
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'fileProp',
      value: {
        file: '',
        isUploadLoading: false,
        isUploadSuccess: false,
        fileName: '',
        status: '',
        percent: '',
        uploadError: '',
        maxFileSize: getMaxFileSize(),
      },
    }),
  )
}
