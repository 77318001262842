import React, { useMemo, useState } from 'react'
import CourseCard from '../../../../../../components/Preview/CourseCard'
import CustomTabs from '../../../../../../components/Preview/Tabs'
import { StyledContainerCard, StyledTabBox } from './Styled'
import { handleCourseData } from './events'

const TabContainer = ({ tabs }) => {
  const [value, setValue] = useState(1)
  const activeTab = tabs.filter((item) => item.status)
  const courseTab = activeTab.map((item, index) => ({
    label: item.tabTitle,
    value: index + 1,
  }))
  const courseList = useMemo(
    () => handleCourseData(activeTab, value),
    [activeTab, value]
  )

  return (
    <StyledTabBox>
      <CustomTabs
        listTab={courseTab}
        value={value}
        onChange={(tab) => setValue(tab)}
      />
      <StyledContainerCard>
        {courseList.map((course, index) => (
          <CourseCard size="l" key={index} course={course} />
        ))}
      </StyledContainerCard>
    </StyledTabBox>
  )
}

export default TabContainer
