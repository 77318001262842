import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import Close from '@mui/icons-material/Close'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledContentHeader,
  StyledBoxContainer,
  StyledTextTile,
} from './Styled'
import { downloadFile, downloadAllFiles } from './events'
import DownloadIcon from '@mui/icons-material/Download'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import _ from 'lodash'

const StudyPapersDrawer = ({ isOpen, onCloseDrawer }) => {
  const manageClassData = useSelector(
    (state) => state.manageClassDetail.files,
    shallowEqual
  )
  const electronicFiles = _.get(manageClassData, 'electronic', [])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">
              เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม
            </Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <StyledContentHeader>
          <Typography>ไฟล์</Typography>
          <Button
            data-testid="btn-download-all"
            onClick={() => {
              downloadAllFiles(electronicFiles)
            }}
            size="m"
          >
            ดาวน์โหลดทั้งหมด
          </Button>
        </StyledContentHeader>

        {electronicFiles.map((item, index) => (
          <>
            {item.type === 'Link' && <LinkItem index={index} item={item} />}

            {item.type === 'UploadFile' && (
              <StyledBoxContainer key={index}>
                <Box>
                  <StyledTextTile variant="body1">{item.name}</StyledTextTile>
                  <Typography variant="body2" color="text.secondary">
                    ประเภท: E-Content
                  </Typography>
                  {item.isDownload && (
                    <Typography variant="caption" color="text.secondary">
                      *อนุญาตผู้เรียนดาวน์โหลด
                    </Typography>
                  )}
                </Box>
                <Button
                  data-testid="btn-download-file"
                  variant="outlined"
                  size="m"
                  onClick={() => downloadFile(item.key, item.name)}
                >
                  <DownloadIcon />
                </Button>
              </StyledBoxContainer>
            )}
          </>
        ))}
      </StyledContent>
    </StyledDrawer>
  )
}

export default StudyPapersDrawer

export const LinkItem = ({ index, item }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [open])

  return (
    <StyledBoxContainer key={index}>
      <Box>
        <StyledTextTile variant="body1">{item.name}</StyledTextTile>
        <StyledTextTile variant="body2" color="primary">
          {item.key}
        </StyledTextTile>
      </Box>
      <Tooltip
        PopperProps={{ disablePortal: true }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<Typography variant="tooltip">คัดลอกแล้ว</Typography>}
        arrow
        placement="bottom"
      >
        <Button
          data-testid="btn-copy"
          variant="outlined"
          size="m"
          onClick={() => {
            setOpen(true)
            navigator.clipboard.writeText(item.key)
          }}
        >
          <ContentCopyIcon />
        </Button>
      </Tooltip>
    </StyledBoxContainer>
  )
}
