import styled from '@mui/system/styled'
import { Column, Row } from '../../../../Styled'

export const StyledFilterColumn = styled(Column)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(3)}` },
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))

export const StyledFilterHeader = styled(Row)(({ theme }) => ({
  color: theme.palette?.primary?.main,
  gap: theme.spacing(1.5),
}))
