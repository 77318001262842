import React, { useState, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import UserLevelList, {
  UserLevelListGroup,
} from '../../components/Table/UserLevelList'
import Upload from '../../components/Upload/BasicUpload'
import { convertFormatDateTime } from '../../utils/lib'
import Dot from '../../components/Table/Dot'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import ViewConditions from './ViewConditions'
import _ from 'lodash'
import Table from '../../components/Table/NormalTable/NormalTable'
import Checkbox from '@mui/material/Checkbox'

import IconCheckBox from '@mui/icons-material/CheckBox'
import DisabledByDefault from '@mui/icons-material/DisabledByDefault'
import { ModalCertification } from '../CourseSetting/ECertificationSetting/ECertificationView'
import { useContainerDimensions } from '../CourseSetting/ECertificationSetting/ECertificationForm/event'

import { RenderTextEditor } from '../../components/Editor/BasicEditor'

const courseStatus = {
  DRAFT: 'ฉบับร่าง',
  WAITING_FOR_ACTIVE: 'รอเปิดใช้งาน',
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}

export const filterType = (type, percent) => {
  let result = ''
  switch (type) {
    case 'NOT_REQUIRED':
      result = 'ไม่จำเป็น'
      break
    case 'REQUIRED':
      result = 'ต้องทำ'
      break
    case 'REQUIRED_PERCENT':
      result = `${percent}%`
      break
  }
  return result
}

export const filterTestAndEvaluate = (list) => {
  if (!list.length) return []
  let result = []
  list.forEach((item, index) => {
    result.push({
      no: index + 1,
      eType:
        _.get(item, 'testAndEvaluateType') === 'E_TESTING'
          ? 'แบบทดสอบ'
          : 'แบบประเมิน',
      itemName: item.testOrEvaluateName,
      type: _.isEmpty(item.type) ? '-' : item.type,
      criteriaType: filterType(item.testCriteriaType, item.criteriaPercent),
    })
  })
  return result
}

export const filterElectronic = (list) => {
  if (!list.length) return []
  let result = []
  list.forEach((item) => {
    const check = String(item.key).indexOf('/course') !== -1
    result.push({
      itemName: item.name,
      type: item.type,
      custom: (
        <Box sx={{ pl: 5 }}>
          <Checkbox
            disabled={true}
            checked={true}
            checkedIcon={
              item.isDownload ? <IconCheckBox /> : <DisabledByDefault />
            }
          />
        </Box>
      ),
      files: true,
      url: item.key,
      isDowload: check,
    })
  })
  return result
}

const CourseSettingView = (props) => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  const componentRef = useRef()
  const { width: widthModel } = useContainerDimensions(componentRef)
  const { initialValues } = props
  const {
    distribution,
    roadmap,
    trainingPlatform,
    courseCode,
    englishName,
    thaiName,
    nameForLearner,
    startDate,
    hours,
    minutes,
    imageKey,
    levelOfLearner,
    otherLevelOfLearner,
    acquiredSkill,
    productType,
    coursePurpose,
    courseOutline,
    version,
    assignee,
    courseLevelControl,
    isApproved,
    hasBreakMeal,
    breakMealBudget,
    isActive,
    status,
    createdAt,
    updatedAt,
    updatedBy,
    coursePrerequisite,
    pointStatus,
    point,
    pointFromLearningPoint,
    pointType,
    tag,
  } = initialValues
  const startDateValue = new Date(startDate)
  const startDateText = `${startDateValue.getDate()}/${
    startDateValue.getMonth() + 1
  }/${startDateValue.getFullYear()}`
  const expireECerYear = _.get(initialValues, 'expireECertificationYear', '')
  const requireOicExam = _.get(initialValues, 'requireOicExam', false)
  const agentConfirmation = _.get(initialValues, 'agentConfirmation', false)
  const requireDocument = _.get(initialValues, 'requireDocument', false)
  const documentList = _.get(initialValues, 'documentList', [])
  const isAttendance = _.get(initialValues, 'isAttendance', false)
  const attendanceCount = _.get(initialValues, 'attendanceCount', '-')
  const attendanceCriteria = _.get(initialValues, 'attendanceCriteria', '-')
  const qrCodeTime = _.get(initialValues, 'qrCodeTime', '-')
  const listTestAndEvaluate = filterTestAndEvaluate(
    _.get(initialValues, 'testAndEvaluate', []),
  )
  const listelEctronic = filterElectronic(
    _.get(initialValues, 'electronic', []),
  )
  const templateCer = _.get(initialValues, 'eCertificationVersion.name', '-')
  const certificate = _.get(initialValues, 'englishCertificateName', '-')

  return (
    <ContainerBox
      sx={{
        gap: 3,
        py: 3,
        px: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '1 0 0',
          gap: 3,
        }}
      >
        <ContainerBox sx={{ width: 'calc(100% - 264px)' }}>
          <Typography sx={{ pb: 1.5, wordWrap: 'break-word' }} variant="h4">
            {englishName}
          </Typography>

          <Box
            sx={{
              display: 'none',
              [theme.breakpoints.down('sm')]: {
                display: 'block',
                pointerEvents: imageKey ? 'unset' : 'none',
                alignSelf: 'center',
              },
            }}
          >
            <Upload
              value={imageKey}
              isShowDescription={false}
              isPreview={true}
              style={{ width: '240px', height: '160px' }}
              titlePreviewImg={'รูปหน้าปกหลักสูตร'}
            />
          </Box>

          <ContentField field="เวอร์ชั่น" content={version} />
          <ContentField field="ชื่อหลักสูตร EN" content={englishName} />
          <ContentField field="ชื่อหลักสูตร TH" content={thaiName || '-'} />
          <ContentField
            field="ชื่อหลักสูตร (สำหรับ Learner)"
            content={nameForLearner || '-'}
          />
        </ContainerBox>
        <Box
          sx={{
            display: 'block',
            pointerEvents: imageKey ? 'unset' : 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          }}
        >
          <Upload
            value={imageKey}
            isShowDescription={false}
            isPreview={true}
            style={{ width: '240px', height: '160px' }}
            titlePreviewImg={'รูปหน้าปกหลักสูตร'}
          />
        </Box>
      </Box>
      <ContainerBox>
        <LabelField text="ระยะเวลาของหลักสูตร" />
        <ContentField field="วันเริ่มต้น" content={`${startDateText}`} />
        <ContentField
          field="จำนวนชั่วโมง (ไม่รวมพักเที่ยง)"
          content={`${hours} ชั่วโมง ${minutes ? minutes : 0} นาที`}
        />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="E-Certificate" />
        <ContentField field="ชื่อ Certificate" content={certificate} />
        <ContentField
          field="ชื่อเทมเพลต"
          content={
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              onClick={() => templateCer !== '-' && handleOpen()}
            >
              {templateCer}
              {templateCer !== '-' && (
                <RemoveRedEyeTwoToneIcon
                  sx={{ cursor: 'pointer', color: 'action.active' }}
                />
              )}
            </Box>
          }
        />
        <ContentField
          field="อายุของประกาศนียบัตร"
          content={_.isEmpty(expireECerYear) ? '-' : `${expireECerYear} ปี`}
        />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="รายละเอียดหลักสูตร" />
        <ContentField
          field="Tag"
          content={
            <UserLevelList levelList={_.map(tag, (item) => item.label)} />
          }
        />
        <ContentField
          field="ช่องทางการจำหน่าย"
          content={distribution.distribution || '-'}
        />
        <ContentField
          field="ชื่อย่อของช่องทางการจำหน่าย"
          content={distribution.abbreviation || '-'}
        />
        <ContentField field="กลุ่มหลักสูตร" content={roadmap.title || '-'} />
        <ContentField
          field="ชื่อย่อของกลุ่มหลักสูตร"
          content={roadmap.abbreviation || '-'}
        />

        <ContentField
          field="ระดับของผู้เรียน"
          content={<UserLevelList levelList={levelOfLearner} />}
        />
        <ContentField
          field="ระดับของผู้เรียนช่องทางการจำหน่ายอื่น"
          content={
            otherLevelOfLearner.length > 0 ? (
              <UserLevelList levelList={otherLevelOfLearner} />
            ) : (
              '-'
            )
          }
        />
        <ContentField
          field="รูปแบบของการสอน"
          content={
            trainingPlatform.length > 0 ? (
              <UserLevelList levelList={trainingPlatform} />
            ) : (
              '-'
            )
          }
        />
        <ContentField field="รหัสหลักสูตร" content={courseCode} />
        <ContentField
          field="ระดับหลักสูตร"
          content={
            acquiredSkill.length > 0 ? (
              <UserLevelListGroup levelList={acquiredSkill} />
            ) : (
              '-'
            )
          }
        />
        <ContentField
          field="ทักษะที่ได้จากหลักสูตร"
          content={
            productType.length > 0 ? (
              <UserLevelList levelList={productType} />
            ) : (
              '-'
            )
          }
        />
      </ContainerBox>
      <ContainerBox>
        <Typography variant="h6">จุดประสงค์หลักสูตร</Typography>
        <RenderTextEditor style={{ ml: 2 }} labelText={coursePurpose} />
      </ContainerBox>
      <ContainerBox>
        <Typography variant="h6">โครงร่างหลักสูตร</Typography>
        <RenderTextEditor style={{ ml: 2 }} labelText={courseOutline} />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="คะแนนหลักสูตร" />
        <ContentField
          field="สถานะการเปิดใช้งานคะแนน"
          content={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Dot sx={{ mt: 1.5, mr: 1.5 }} status={pointStatus} />
              {courseStatus[pointStatus]}
            </Box>
          }
        />
        <ContentField
          field="จำนวนคะแนน"
          content={point || pointFromLearningPoint || 0}
        />
        <ContentField
          field="กำหนดรูปแบบคะแนน"
          content={pointType == 'LINK' ? 'ผูกคะแนน' : 'กำหนดเอง'}
        />
      </ContainerBox>
      <ContainerBox>
        <Typography variant="h6">เงื่อนไขการเข้าอบรม</Typography>
        <ViewConditions prerequisite={coursePrerequisite} />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="ระดับการควบคุม / การจัดการ" />
        <ContentField
          field="ระดับการควบคุมหลักสูตร"
          content={courseLevelControl || '-'}
        />
        <ContentField
          field="การอนุมัติหลักสูตร"
          content={isApproved ? 'มีการอนุมัติ' : 'ไม่มีการอนุมัติ'}
        />
        <ContentField
          field="การเตรียมอาหาร Break / Lunch"
          content={hasBreakMeal ? 'มี' : 'ไม่มี'}
        />
        <ContentField
          field="ค่าใช้จ่ายต่อหัว"
          content={
            hasBreakMeal ? Number(breakMealBudget).toFixed(2) + ' บาท' : '-'
          }
        />
      </ContainerBox>

      <ContainerBox>
        <LabelField text="ผลสอบใบอนุญาตตัวแทน" />
        <ContentField
          field="ผลสอบใบอนุญาตตัวแทน"
          content={requireOicExam ? 'ต้องการ' : 'ไม่ต้องการ'}
        />
        <ContentField
          field="ขั้นตอนการคอนเฟิร์มตัวแทน"
          content={agentConfirmation ? 'มี' : 'ไม่มี'}
        />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="เอกสารการสมัคร" />
        <ContentField
          field="เอกสารการสมัคร"
          content={requireDocument ? 'ต้องการ' : 'ไม่ต้องการ'}
        />
        <ContentField
          field="รายการเอกสาร"
          content={!_.isEmpty(documentList) ? documentList.join(', ') : '-'}
        />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="เกณฑ์การเข้าเรียน" />
        <ContentField
          field="เกณฑ์การเข้าเรียน"
          content={isAttendance ? 'กำหนด' : 'ไม่กำหนด'}
        />
        <ContentField
          field="จำนวนการเช็คชื่อ"
          content={`${attendanceCount} ครั้ง/วัน`}
        />
        <ContentField
          field="เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)"
          content={`${attendanceCriteria} ครั้ง`}
        />
        <ContentField field="อายุของ QR Code" content={`${qrCodeTime} นาที`} />
      </ContainerBox>

      {/* -- */}
      <ContainerBox>
        <LabelField text="เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม" />
        <Typography variant="body1b">Electronic file</Typography>

        <Table
          headCells={[
            {
              id: 'itemName',
              label: 'รายการ',
            },
            {
              id: 'type',
              label: 'ประเภท',
            },
            {
              id: 'custom',
              label: 'อนุญาตผู้เรียนดาวน์โหลด',
            },
            {
              id: 'action',
              disablePadding: false,
              label: 'คำสั่ง',
              width: '90px',
              viewPath: ``,
            },
          ]}
          listRows={listelEctronic}
          totalRows={listelEctronic.length}
          showPagination={false}
          isHiddenOptions
        />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="แบบทดสอบ / แบบประเมิน" />
        <Table
          headCells={[
            {
              id: 'no',
              label: 'ชุดที่',
              width: '30px',
            },
            {
              id: 'eType',
              label: 'ประเภท',
            },
            {
              id: 'itemName',
              label: 'ชื่อ',
            },
            {
              id: 'type',
              label: 'รูปแบบ',
            },
            {
              id: 'criteriaType',
              label: 'เกณฑ์ผ่าน',
            },
          ]}
          listRows={listTestAndEvaluate}
          totalRows={listTestAndEvaluate.length}
          showPagination={false}
          isHiddenOptions
        />
      </ContainerBox>

      <ContainerBox>
        <LabelField text="สถานะหลักสูตร" />
        <ContentField
          field="สถานะ"
          content={isActive ? 'เผยแพร่' : 'ไม่เผยแพร่'}
          isExcepted
        />
        <ContentField
          field="การเปิดใช้งาน"
          content={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Dot sx={{ mt: 1.5, mr: 1.5 }} status={status} />
              {courseStatus[status]}
            </Box>
          }
          isExcepted
        />
        <ContentField
          field="วันที่สร้าง"
          content={convertFormatDateTime({
            value: createdAt,
            type: 'date',
          })}
          isExcepted
        />
        <ContentField
          field="วันที่แก้ไขล่าสุด"
          content={convertFormatDateTime({
            value: updatedAt,
            type: 'date',
          })}
          isExcepted
        />
        <ContentField field="แก้ไขล่าสุดโดย" content={updatedBy} isExcepted />
      </ContainerBox>
      <ContainerBox>
        <LabelField text="ผู้รับผิดชอบ" />
        {assignee.map((item, index) => {
          return (
            <ContentField
              key={index}
              index={index}
              field={item.userRole === 'CREATOR' ? 'ผู้จัดทำ' : 'ผู้รับผิดชอบ'}
              content={
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  {item.user.image ? (
                    <Upload
                      value={item.user.image}
                      isShowDescription={false}
                      isPreview={true}
                      isShowTextError={false}
                      isStopPropagation={true}
                      style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                      }}
                    />
                  ) : (
                    <AccountCircleRoundedIcon sx={{ width: 32, height: 32 }} />
                  )}
                  <Typography variant="body1">
                    {item?.user?.firstNameTH} {item?.user?.lastNameTH}
                  </Typography>
                </Box>
              }
              isExcepted
            />
          )
        })}
      </ContainerBox>

      <Box
        sx={{
          width: '80%',
          position: 'absolute',
          maxWidth: '1028px',
          top: -1,
        }}
        ref={componentRef}
      />

      <ModalCertification
        isLoading={false}
        openModal={openModal}
        handleClose={handleClose}
        version={_.get(initialValues, 'eCertificationVersion', {})}
        englishCertificateName={certificate}
        eCertificationVersionUuid={_.get(
          initialValues,
          'eCertificationVersion.uuid',
          '',
        )}
        certificateLanguage={_.get(initialValues, 'certificateLanguage', '')}
        width={widthModel - 50}
        isCertificationPrefix={_.get(
          initialValues,
          'isCertificationPrefix',
          true,
        )}
      />
    </ContainerBox>
  )
}
export default CourseSettingView

const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& > .MuiBox-root:last-child': {
      paddingBottom: 0,
    },
  },
}))

export const ContentField = (props) => {
  const theme = useTheme()
  const { field, content, limitWidth, index, isExcepted } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pb: 1.5,
        [theme.breakpoints.down('sm')]: {
          flexDirection: isExcepted ? 'row' : 'column',
          pb: isExcepted ? 1.5 : 2,
        },
      }}
    >
      <Typography
        sx={{
          alignSelf: field === 'ผู้รับผิดชอบ' ? 'start' : 'center',
          // width: isInfo ? '40%' : '30%',
          width: 306,
          minWidth: 306,
          textIndent: 20,
          [theme.breakpoints.down('sm')]: {
            fontWeight: 'bold',
            textIndent: 0,
            width: '100%',
            alignSelf: 'start',
            flex:
              field === 'ผู้จัดทำ' || field === 'ผู้รับผิดชอบ' ? 35 : 'unset',
          },
        }}
      >
        {index > 1 ? '' : field}
      </Typography>
      <Box sx={{ width: 'calc(100% - 306px)' }}>
        <Typography
          sx={{
            alignSelf: 'center',
            maxWidth: limitWidth ? limitWidth : 'unset',
            wordWrap: 'break-word',
            [theme.breakpoints.down('sm')]: {
              textIndent: 0,
              width: '100%',
              alignSelf: 'start',
              flex:
                field === 'ผู้จัดทำ' || field === 'ผู้รับผิดชอบ' ? 65 : 'unset',
            },
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  )
}

export const LabelField = (props) => {
  const theme = useTheme()
  const { text } = props
  return (
    <Typography
      sx={{
        pb: 1.5,
        [theme.breakpoints.down('sm')]: {
          pb: 1,
        },
      }}
      variant="h6"
    >
      {text}
    </Typography>
  )
}
