import CircularProgress from '@mui/material/CircularProgress'
import { Backdrop } from '@mui/material'

export const Loading = ({ isCalendarLoading }) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(255,255,255, 0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
      }}
      open={isCalendarLoading}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
