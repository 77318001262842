import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/manageContent/station'
import { getManageContentStation } from '../../../../services/manageContent'

export const fetchManageStation = (data) => async (dispatch) => {
  if (_.isNil(data)) {
    dispatch(startLoading())
    await dispatch(getManageContentStation())
  }
  dispatch(stopLoading())
}
