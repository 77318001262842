import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Column } from '../../../../../../components/Preview/Layout/Styled'
import BasicTable from '../../BasicTable'
import { TABLE_KEY } from '../../model'
import { fetchAttend } from './events'

const AttendHistory = () => {
  const { id: uuid } = useParams()
  const { table, expandedRound } = useSelector(
    (state) => ({
      table:
        state.eLearningCourseBatchLearnerDetail.tables[TABLE_KEY.attendance],
      expandedRound: state.eLearningCourseBatchLearnerDetail.expandedRound,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAttend({ ...table, uuid, expandedRound }))
  }, [table.limit, table.page, expandedRound])

  return (
    <Column sx={{ gap: 1 }}>
      <Typography variant="h6">ประวัติการเข้าเรียน</Typography>
      <BasicTable keyTable={TABLE_KEY.attendance} />
    </Column>
  )
}

export default AttendHistory
