import _ from 'lodash'
import { sectionName } from './../../../../../constants/eEvaluation'

export const handleAssessmentAmount = (detail) => {
  const questions = _.get(detail, 'questions', []).length
  const courses = _.get(detail, 'courseAssessments', []).length
  const coursesManagements = _.get(
    detail,
    'courseManagementAssessments',
    []
  ).length
  const lecturers = _.get(detail, 'lecturerAssessments', []).length
  return questions + courses + coursesManagements + lecturers
}

export const handleSectionList = (detail) => {
  const course = _.get(detail, 'courseAssessments', []).length > 0
  const lecturer = _.get(detail, 'lecturerAssessments', []).length > 0
  const courseManagement =
    _.get(detail, 'courseManagementAssessments', []).length > 0
  const question = _.get(detail, 'questions', []).length > 0

  const sectionList = []
  if (course) sectionList.push(sectionName.courses)
  if (lecturer) sectionList.push(sectionName.lecturers)
  if (courseManagement) sectionList.push(sectionName.courseManagements)
  if (question) sectionList.push(sectionName.question)
  return sectionList
}

export const handleQuestionTypeList = (detail) => {
  if (detail) {
    const lecturerAssessments = _.get(detail, 'lecturerAssessments', [])
    const courseManagementAssessments = _.get(
      detail,
      'courseManagementAssessments',
      []
    )
    const courseAssessments = _.get(detail, 'courseAssessments', [])

    const groupType = _.groupBy(detail.questions, 'questionType')

    const arrType = Object.keys(groupType)

    if (
      lecturerAssessments.length > 0 ||
      courseManagementAssessments.length > 0 ||
      courseAssessments.length > 0
    ) {
      if (!arrType.some((item) => item == 'RATING')) arrType.push('RATING')
    }
    return arrType
  } else return []
}
