import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LearnerDrawer from './LearnerDrawer'
import {
  StyledCardLearner,
  StyledLearnerMenu,
  StyledTypography,
} from './Styled'

const LearnerMenu = ({ isStandalone }) => {
  const [open, setOpen] = useState(false)
  const { tab, learnerExam, learners } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const codeId = _.get(learnerExam, 'codeId', '')
  const learner = useMemo(
    () => _.get(learners, 'learnerAll', []).find((e) => e.codeId === codeId),
    [learners, codeId, tab]
  )
  const imgPath = _.get(learner, 'image', undefined)
  const learnerName = `${_.get(learner, 'firstNameTH', 'ไม่พบรายชื่อ')} ${
    _.get(learner, 'lastNameTH', '') || ''
  }`

  return (
    <>
      <StyledLearnerMenu onClick={() => setOpen(true)}>
        <StyledCardLearner data-testid={`btn-learner-0`}>
          {_.isUndefined(imgPath) ? null : (
            <img src={`${window.__env__.REACT_APP_API_URL}/file/${imgPath}`} />
          )}
          <StyledTypography>{learnerName}</StyledTypography>
        </StyledCardLearner>
        <IconButton>
          <ExpandMore color="primary" />
        </IconButton>
      </StyledLearnerMenu>
      <LearnerDrawer
        isStandalone={isStandalone}
        isOpen={open}
        onCloseDrawer={() => setOpen(false)}
      />
    </>
  )
}

export default LearnerMenu
