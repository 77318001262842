import {
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../../../../redux/slices/dialog'

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.value != '') dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleSuccess = (isSuccess) => (dispatch) => {
  if (isSuccess) {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => window.location.reload(),
      }),
    )
  }
}
