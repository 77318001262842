import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'

export const textColor = 'text.wildSand'

export const StyledContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height,
  overflow: 'hidden',
  marginTop: '-12px',
}))

export const StyledExpandButton = styled((props) => (
  <Button {...props} size="small" variant="outlined" />
))(() => ({
  minWidth: 86,
  fontSize: '17px',
}))

export const StyledFloatButton = styled(Button)(({ isFile }) => ({
  position: 'absolute',
  top: 30,
  zIndex: isFile ? 2 : 3,
  fontSize: '17px',
}))

export const StyledFloatBg = styled(Box)(({ theme, isFile }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  background: theme.palette?.background?.paper,
  width: '100%',
  height: 96,
  zIndex: isFile ? 1 : 2,
}))

export const StyledFloatIconButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  zIndex: 2,
  minWidth: 35,
  width: 35,
  height: 35,
  borderRadius: '100%',
  opacity: 0,
  svg: { marginLeft: theme.spacing(0.5), fontSize: '16px' },
}))

export const height = 'calc(100vh - 69px)'

export const StyledCircularBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.4)',
  zIndex: 1000,
}))

export const StyledCircularLoader = styled((props) => (
  <CircularProgress {...props} size="50px" />
))(() => ({
  display: 'inline-block',
  position: 'absolute',
  top: 'calc(50% - 25px)',
  left: 'calc(50% - 25px)',
  zIndex: 999,
}))
