import Card from '@mui/material/Card'
import _ from 'lodash'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { BoxLabel } from '../Information'
import { ProfileImg } from './Styled'

const MainInformation = () => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual
  )

  const userId = _.get(data, 'info.uuid', '-')
  const fullName = `${_.get(data, 'info.prefixTH', '')} ${_.get(
    data,
    'info.firstNameTH',
    '-'
  )} ${_.get(data, 'info.lastNameTH', '-')}`
  const fullNameEn = `${_.get(data, 'info.prefix', '')} ${_.get(
    data,
    'info.firstName',
    '-'
  )} ${_.get(data, 'info.lastName', '-')}`
  const tempCode = _.get(data, 'info.tempCode', '-')
  const image = _.get(data, 'info.image', '')
  return (
    <>
      <Card sx={{ p: 3 }}>
        <ProfileImg
          url={
            image
              ? `${window.__env__.REACT_APP_API_URL}/api/file${image}`
              : '/logo/staff_profile_small.png'
          }
        />
        <BoxLabel labelLeft="User ID" labelRight={userId} />
        <BoxLabel labelLeft="Temp Code" labelRight={tempCode} />
        <BoxLabel labelLeft="Name" labelRight={fullNameEn} />
        <BoxLabel labelLeft="ชื่อ - นามสกุล" labelRight={fullName} />
      </Card>
    </>
  )
}

export default MainInformation
