import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { draftSubQuestions } from '../../../../../../../../../redux/slices/eEvaluationForm'
import Select from '../../../../../../../../../components/Input/Select'
import {
  listNextQuestionType,
  handleChangeNextQuestion,
  listSubQuestionSequence,
} from './events'
import { handleChange } from '../event'

import { BoxContent, BoxQuestionType, QuestionSequence } from './Styled'

const Index = ({ props }) => {
  const {
    answers,
    sequence,
    indexQuestion,
    indexAnswer,
    length,
    answerType,
    onTesting,
  } = props
  const dispatch = useDispatch()
  const listSubQuestion = useSelector(draftSubQuestions(sequence), shallowEqual)
  const nextQuestionType = _.get(
    answers,
    `[${indexAnswer}].nextQuestionType`,
    'NONE'
  )

  const nextQuestionSequence = _.get(
    answers,
    `[${indexAnswer}].nextQuestionSequence`,
    0
  )
  const nextSubQuestionSequence = _.get(
    answers,
    `[${indexAnswer}].nextSubQuestionSequence`,
    0
  )

  const propsMoreEvent = {
    sequence,
    indexAnswer,
    answers,
  }

  return (
    <BoxContent answerType={answerType === 'OTHER'}>
      <BoxQuestionType isSub={nextQuestionType === 'NEXT_SUB'}>
        <Select
          disabled={onTesting}
          dataTestid="select-typeOfAnswers"
          id="typeOfAnswers"
          name="typeOfAnswers"
          labelText=""
          type="basic"
          options={listNextQuestionType(length, indexQuestion)}
          handleChange={(e) =>
            dispatch(
              handleChange({
                ...propsMoreEvent,
                key: 'nextQuestionType',
                value: _.get(e, 'target.value', ''),
              })
            )
          }
          value={
            nextQuestionType === 'NEXT_TO'
              ? nextQuestionSequence
              : nextQuestionType
          }
        />
      </BoxQuestionType>
      {nextQuestionType === 'NEXT_SUB' && (
        <QuestionSequence>
          <Select
            disabled={onTesting}
            dataTestid="select-sub-question-sequence"
            id="subQuestionSequence"
            name="subQuestionSequence"
            labelText=""
            type="basic"
            placeholder="เลือก"
            options={listSubQuestionSequence(
              listSubQuestion?.length || 0,
              sequence
            )}
            handleChange={(e) =>
              dispatch(
                handleChangeNextQuestion({
                  ...propsMoreEvent,
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            value={
              !nextQuestionSequence || !nextSubQuestionSequence
                ? ''
                : `${nextQuestionSequence}.${nextSubQuestionSequence}`
            }
          />
        </QuestionSequence>
      )}
    </BoxContent>
  )
}

export default Index
