import TextInput from '../../../../../../components/Input/TextInput'
import { useDispatch } from 'react-redux'
import { handleInputRoomChange } from '../events'
import {
  handleInputFormat,
  handleDefaultValue,
} from './event'
import { handlePasteFormat } from '../../../../../../utils/lib'
import _ from 'lodash'

export const NumberOfAttendees = ({
  numberOfAttendees,
  roomDetailIndex,
  errors,
  bookingDate,
  validateIndex,
}) => {
  const dispatch = useDispatch()

  return (
    <TextInput
      type="basic"
      id="numberOfAttendees"
      name="numberOfAttendees"
      labelText={'จำนวนผู้เข้าร่วม'}
      placeholder="กรุณากรอกจำนวนผู้เข้าร่วม"
      value={handleDefaultValue(numberOfAttendees || null)}
      inputProps={{
        type: 'text',
        onInput: (e) => handleInputFormat(e),
        onPaste: handlePasteFormat,
      }}
      onChange={(e) => {
        dispatch(
          handleInputRoomChange(
            e,
            'numberOfAttendees',
            roomDetailIndex,
            bookingDate,
          ),
        )
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      isShowTextError={
        !_.isEmpty(
          errors,
          `roomManagement.roomManagementMeetingRoom[${validateIndex}].numberOfAttendees`,
        )
      }
      textError={_.get(
        errors,
        `roomManagement.roomManagementMeetingRoom[${validateIndex}].numberOfAttendees`,
        '',
      )}
    />
  )
}
