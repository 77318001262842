import React from 'react'
import _ from 'lodash'
import { TableCell, TableRow, Typography, Box } from '@mui/material'
import { headerUpload } from '../../../model/headerUpload'
import { BGM_UPLOAD_STATUS } from '../../../../../../constants/budgetManagement/budgetManagement'
import { TooltipError } from './TooltipError'
import { useTheme } from '@mui/system'

export const TableContent = ({ results }) => {
  const theme = useTheme()

  return (
    <>
      {_.map(results, (item) => {
        return (
          <TableRow
            sx={{
              background:
                item.statusUpload === 'SUCCESS'
                  ? theme?.palette?.success?.outlinedHoverBackground
                  : 'transparent',
            }}
          >
            {_.map(headerUpload, (header, key) => {
              let value = _.get(item, `${key}`, '-')
              if (key === 'statusUpload') value = BGM_UPLOAD_STATUS[value]
              const column = key

              return (
                <TableCell key={key} sx={{ minWidth: 130 }}>
                  <Box display="flex" gap={0.5}>
                    <Typography
                      color={
                        key === 'statusUpload'
                          ? value === BGM_UPLOAD_STATUS['SUCCESS']
                            ? 'text.success'
                            : 'error'
                          : 'text.primary'
                      }
                    >
                      {!_.isNull(value) ? value : '-'}
                    </Typography>
                    <TooltipError column={column} item={item} />
                  </Box>
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
