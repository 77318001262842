export const TOP_LIST_TYPE_LABEL = {
  SPEAKER: 'Speaker',
  WORKLOAD: 'Workload',
  LEAVE: 'Leave',
}

export const TOP_LIST_TYPE = {
  SPEAKER: 'SPEAKER',
  WORKLOAD: 'WORKLOAD',
  LEAVE: 'LEAVE',
}

export const TYPE_DOWNLOAD_SUMMARY = {
  SUMMARY: 'SUMMARY',
  FIRST_VIEW: 'FIRST_VIEW',
  SECOND_VIEW: 'SECOND_VIEW',
}
