import * as yup from 'yup'

export const schema = yup.object({
  status: yup.boolean(),
  title: yup
    .string()
    .test(
      'title-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  description: yup
    .string()
    .test(
      'description-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  productTypes: yup
    .array()
    .test(
      'productTypes-validate',
      'กรุณาเลือก',
      (value, { parent }) => !parent.status || value.length > 0
    ),
})
