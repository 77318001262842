import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Stepper from '@mui/material/Stepper'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 894,
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(2),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledStepper = styled(Stepper)(({ theme, activeStep }) => {
  const isStep2 = activeStep === 1
  return {
    '& .MuiSvgIcon-root': {
      width: 38,
      height: 38,
      borderRadius: '50%',
    },
    '& .MuiStepConnector-line': {
      borderTopStyle: isStep2 ? 'solid' : 'dashed',
      borderTopWidth: 2,
      borderColor: isStep2
        ? theme.palette?.primary?.outlinedResting
        : '#E4E4E4',
    },
    '& .MuiStepConnector-root': {
      top: theme.spacing(2.5),
      left: 'calc(-50% + 24px)',
      right: 'calc(50% + 24px)',
    },
    '& .MuiStepLabel-label': { fontSize: '20px', fontWeight: 700 },
    '& .MuiStepLabel-label:not(.Mui-active)': {
      color: isStep2
        ? theme.palette?.text?.primary
        : theme.palette?.text?.lightGray,
    },
    '& .MuiStepLabel-root text': { fontWeight: 700 },
    '& .MuiStepLabel-root .MuiSvgIcon-root:not(.Mui-active)': {
      '& circle': {
        color: isStep2
          ? theme.palette?.primary?.main
          : theme.palette?.text?.lightBlue,
      },
      '& text': {
        fill: isStep2
          ? theme.palette?.text?.white
          : theme.palette?.primary?.main,
        fontWeight: 700,
      },
    },
  }
})

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(3)}`,
}))

export const styledTextInput = {
  minHeight: 98,
  maxHeight: '100%',
  height: 'auto',
}
