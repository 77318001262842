import _ from 'lodash'
import {
  setSelectedLesson,
  setVideoState,
  stopVideoLoading,
} from '../../../../../../redux/slices/eLearning/preview'
import { store } from '../../../../../../App'

export const handleVideoReady =
  (playerSecond, videoPlayerRef) => (dispatch) => {
    dispatch(stopVideoLoading())
    const timeToStart = _.isNil(playerSecond) ? 0 : playerSecond
    videoPlayerRef.current.seekTo(timeToStart, 'seconds')
  }

export const handleWindowBlur = () => (dispatch) => {
  const handleClickOutside = () => {
    dispatch(setVideoState({ playing: false }))
  }

  window.addEventListener('blur', handleClickOutside)
  return () => {
    window.removeEventListener('blur', handleClickOutside)
  }
}

export const progressHandler = (state, eventProps) => (dispatch) => {
  const { videoState, setSecond } = eventProps
  dispatch(setVideoState({ ...state }))

  // save seconds of video every ten secs
  const playedSeconds = _.get(videoState, 'playedSeconds', 0)
  const floorSec = _.floor(playedSeconds)
  if (floorSec % 10 === 0) setSecond(floorSec)
}

export const handleEnded = (setSecond) => (dispatch) => {
  const { setting, selectedLesson, lessons } = store.getState().eLearningPreview
  dispatch(setVideoState({ playing: false, isFinished: true }))

  if (setting.autoNextLesson) {
    const selectedIndex = _.get(selectedLesson, 'no', 1) - 1
    dispatch(setSelectedLesson(lessons[selectedIndex + 1]))
    setSecond(0)
  }
}
