import * as yup from 'yup'
import { vendorTypeEnum } from '../enum/vendorTypeEnum'
import dayjs from 'dayjs'

export const formValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('กรุณาระบุชื่อบริษัท')
    .max(255, 'กรุณาระบุชื่อบริษัท ภายใน 255 ตัวอักษร'),
  station: yup
    .object({
      value: yup.string().nullable().required('กรุณาเลือก Station'),
    })
    .nullable()
    .required('กรุณาเลือก Station'),
  annotation: yup.string().nullable().max(3000, 'กรุณาระบุภายใน 3,000 ตัวอักษร'),
  vendorType: yup
    .object({
      value: yup
        .string()
        .nullable()
        .required('กรุณาเลือกประเภท Vendor (Vendor type)'),
    })
    .nullable()
    .required('กรุณาเลือกประเภท Vendor (Vendor type)'),
  qtyType: yup
    .string()
    .max(5, 'กรุณาระบุจำนวน ภายใน 5 หลัก')
    .when('vendorType', (vendorType, schema) => {
      if (vendorType?.value == vendorTypeEnum.ASSET)
        return schema.required('กรุณาระบุจำนวน ภายใน 5 หลัก')
      return schema.nullable()
    }),
  descriptionType: yup
    .string()
    .max(255, 'กรุณากรอกรายละเอียดของบริการ ภายใน 255 ตัวอักษร')
    .when('vendorType', (vendorType, schema) => {
      if (vendorType?.value == vendorTypeEnum.OTHER)
        return schema.required(
          'กรุณากรอกรายละเอียดของบริการ ภายใน 255 ตัวอักษร',
        )
      return schema.nullable()
    }),
  serviceDescription: yup
    .string()
    .nullable()
    .max(65535, 'กรุณากรอกรายละเอียดของบริการ ภายใน 65,535 ตัวอักษร'),
  startDate: yup.date().nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  endDate: yup
    .date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    .when('startDate', (startDate, schema) => {
      if (startDate && dayjs(startDate).isValid()) {
        const dayAfter = new Date(startDate.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewDate: yup
    .date()
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    .when('startDate', (startDate, schema) => {
      if (startDate && dayjs(startDate).isValid()) {
        const startDateDate = new Date(startDate)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  insideContacts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .object({
            value: yup.string().nullable().required('กรุณาเลือกรายชื่อพนักงาน'),
          })
          .nullable()
          .required('กรุณาเลือกรายชื่อพนักงาน'),
      }),
    )
    .min(1, 'กรุณาเลือกรายชื่อพนักงาน'),
  outsideContacts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .nullable()
          .required('กรุณาระบุชื่อผู้ติดต่อ')
          .max(255, 'กรุณาระบุชื่อผู้ติดต่อ ภายใน 255 ตัวอักษร'),
        phone: yup
          .string()
          .nullable()
          .required('กรุณาระบุเบอร์โทรศัพท์')
          .max(10, 'กรุณาระบุเบอร์โทรศัพท์ ภายใน 10 หลัก'),
        email: yup
          .string()
          .nullable()
          .required('กรุณาระบุ E-mail')
          .email('รูปแบบ E-mail ไม่ถูกต้อง'),
      }),
    )
    .min(0, 'กรุณาระบุชื่อผู้ติดต่อ'),
})
