import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
//import { filtereTestingDashboard } from '../../../services/eTestingDashboard'

const eTestingDashboard = createSlice({
  name: 'eTestingDashboard',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setIsOpenFilter: (state, { payload }) => {
      state.isOpenFilter = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setType: (state, { payload }) => {
      state.type = payload
    },
    openCourseDrawer: (state, { payload }) => {
      const { header, drawerType, courseType, score } = payload
      state.courseDrawer.isOpen = true
      state.courseDrawer.header = header
      state.courseDrawer.drawerType = drawerType
      state.courseDrawer.courseType = courseType
      state.courseDrawer.score = score
    },
    closeCourseDrawer: (state) => {
      state.courseDrawer.isOpen = false
    },
    openEnrollDrawer: (state, { payload }) => {
      const { header, uuid } = payload
      state.enrollDrawer.isOpen = true
      state.enrollDrawer.header = header
      state.enrollDrawer.uuid = uuid
    },
    closeEnrollDrawer: (state) => {
      state.enrollDrawer.isOpen = false
    },
    setFilterMemo: (state, { payload }) => {
      state.filterMemo = payload
    },
    resetFilter: (state, { payload }) => {
      state.filterMemo = payload
      state.filter = initialState.filter
    },
  },
  extraReducers: (builder) => {
    builder
    // .addCase(filtereTestingDashboard.fulfilled, (state, { payload }) => {
    //   const {
    //     totalCount,
    //     learnerCount,
    //     agencyData,
    //     bancaData,
    //     ktbData,
    //     otherData,
    //     createdAt,
    //     updatedAt,
    //     moduleResult,
    //   } = payload
    //   state.overview = {
    //     totalCourse: totalCount || 0,
    //     totalEnroll: learnerCount || 0,
    //     latestCreate: createdAt || [],
    //     latestUpdate: updatedAt || [],
    //     AGENCY: {
    //       totalCourse: agencyData.totalCount || 0,
    //       totalEnroll: agencyData.learnerCount || 0,
    //       latestCreate: agencyData.createdAt || [],
    //       latestUpdate: agencyData.updatedAt || [],
    //     },
    //     BANCA: {
    //       totalCourse: bancaData.totalCount || 0,
    //       totalEnroll: bancaData.learnerCount || 0,
    //       latestCreate: bancaData.createdAt || [],
    //       latestUpdate: bancaData.updatedAt || [],
    //     },
    //     KTB: {
    //       totalCourse: ktbData.totalCount || 0,
    //       totalEnroll: ktbData.learnerCount || 0,
    //       latestCreate: ktbData.createdAt || [],
    //       latestUpdate: ktbData.updatedAt || [],
    //     },
    //     OTHER: {
    //       totalCourse: otherData.totalCount || 0,
    //       totalEnroll: otherData.learnerCount || 0,
    //       latestCreate: otherData.createdAt || [],
    //       latestUpdate: otherData.updatedAt || [],
    //     },
    //     module: {
    //       E_TESTING: moduleResult.totalETest || 0,
    //       E_EVALUATION: moduleResult.totalEEva || 0,
    //       EMBED_CONTENT: moduleResult.totalEmbedContent || 0,
    //       LINK_TO_ANOTHER_WEBSITE: moduleResult.totalLintToAnother || 0,
    //       UPLOAD_FILE: moduleResult.totalUploadFile || 0,
    //       SCORM: moduleResult.totalScorm || 0,
    //     },
    //     chartData: {
    //       course: [
    //         agencyData.totalCount,
    //         bancaData.totalCount,
    //         ktbData.totalCount,
    //         otherData.totalCount,
    //       ],
    //       enroll: [
    //         agencyData.learnerCount,
    //         bancaData.learnerCount,
    //         ktbData.learnerCount,
    //         otherData.learnerCount,
    //       ],
    //       module: [
    //         moduleResult.totalETest,
    //         moduleResult.totalEEva,
    //         moduleResult.totalEmbedContent,
    //         moduleResult.totalLintToAnother,
    //         moduleResult.totalUploadFile,
    //         moduleResult.totalScorm,
    //       ],
    //       totalModule: moduleResult.totalModule,
    //     },
    //   }
    // })
    // .addCase(filtereTestingDashboard.rejected, (state, action) => {
    //   state.error = action.meta?.message
    // })
  },
})

export const {
  startLoading,
  stopLoading,
  setIsOpenFilter,
  setFilter,
  setType,
  openCourseDrawer,
  closeCourseDrawer,
  openEnrollDrawer,
  closeEnrollDrawer,
  setFilterMemo,
  resetFilter,
} = eTestingDashboard.actions

export default eTestingDashboard.reducer
