import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../../redux/slices/dialog'
import { get } from 'lodash'
import { store } from '../../../../../App'
import { callAPI } from '../../../../../utils/lib'
import {
  displayClientError,
  displayServerError,
} from '../../../../../components/CRUD/handler/handlerFetchError'
import { productSkuUpdate } from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { setIsShowEditForm } from './handleClickEditAsset'

export const handleProcessSubmitForm = async (body) => {
  startDialogLoading()

  try {
    const response = await submitFormData(body)

    handleSuccessfulSubmission(response)
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const submitFormData = async (body) => {
  return await callAPI({
    method: 'put',
    url: productSkuUpdate,
    body: body,
  })
}

const handleSuccessfulSubmission = (response) => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => reloadData(response),
    }),
  )
}

export const handleFormSubmissionError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      handleBadRequestError(error.response)
    } else {
      displayServerError()
    }
  } else {
    displayClientError()
  }
}

const handleBadRequestError = (response) => {
  const errorData = get(response, 'data', '')

  store.dispatch(
    openErrorDialog({
      message: errorData.message,
      title: 'ผิดพลาด',
      isCloseDialog: false,
    }),
  )
}

const reloadData = (response) => {
  store.dispatch(
    setFieldValue({
      key: 'isReloadAssetListDrawer',
      value: true,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'assetDetailDrawer',
      value: {
        ...response,
        holderInfo: response?.holder,
        payerInfo: response?.payer,
      },
    }),
  )
  setIsShowEditForm(false)
  store.dispatch(closeDialog())
}
