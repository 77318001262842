export const filterStatusItems = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รออนุมัติ',
    name: 'waitingApproveChecked',
  },
  {
    label: 'อนุมัติ',
    name: 'approvedChecked',
  },
  {
    label: 'ปฏิเสธ',
    name: 'rejectedChecked',
  },
  {
    label: 'ยกเลิก',
    name: 'canceledChecked',
  },
]

export const filterItems = [
  {
    label: `ต้นทาง`,
    nameCheckBox: 'fromChecked',
    nameInput: 'from',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `ปลายทาง`,
    nameCheckBox: 'toChecked',
    nameInput: 'to',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `จำนวนรายการทรัพย์สิน`,
    nameCheckBox: 'qtyChecked',
    nameInput: 'qty',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'qtyFrom',
    inputTo: 'qtyTo',
    type: 'number_between',
  },
  {
    label: `หมวดหมู่ ในรายการทรัพย์สิน`,
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `หมวดหมู่ย่อย ในรายการทรัพย์สิน`,
    nameCheckBox: 'subCategoryChecked',
    nameInput: 'subCategory',
    placeholder: ``,
    type: 'text',
  },
  {
    label: 'วันที่สร้าง',
    placeholder: 'เลือก ระยะสัญญา',
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    dateStateStart: 'createDateStartDate',
    dateStateFinish: 'createDateEndDate',
    dateStateDisplay: 'createDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
