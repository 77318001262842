import { IconButton } from '@mui/material'
import { StyledColumn, StyledRow } from '../../../../Styled'
import { DeleteTwoTone } from '@mui/icons-material'
import { handleDeleteRoom } from '../event'
import { shallowEqual, useDispatch } from 'react-redux'
import Equipment from './Equipment'
import { AdditionalEquipment } from './AdditionalEquipment'
import { NumberOfAttendees } from './NumberOfAttendees'
import { RoomLayout } from './RoomLayout'
import { MeetingRoomSelect } from './MeetingRoomSelect'
import { MeetingRoomOther } from './MeetingRoomOther'
import { GroupSection } from './GroupSection'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { roomSelector } from '../../../../../../redux/slices/roomManagement/Drawer'

export const DetailsRoom = memo((props) => {
  const dispatch = useDispatch()

  const { bookingDate, idx } = props
  const isOnline = useSelector(
    roomSelector({
      bookingDate,
      roomIndex: idx,
      key: 'isOnline',
    }),
    shallowEqual,
  )

  return (
    <StyledColumn sx={{ gap: 2, mb: 3 }}>
      <StyledRow sx={{ gap: 2, alignItems: 'stretch' }}>
        <MeetingRoomSelect
          bookingDate={bookingDate}
          roomIndex={idx}
          isOnline={isOnline}
        />
        {idx !== 0 && !isOnline && (
          <IconButton
            onClick={() => dispatch(handleDeleteRoom(bookingDate, idx))}
          >
            <DeleteTwoTone color="action.active" />
          </IconButton>
        )}
      </StyledRow>
      {!isOnline && (
        <>
          <MeetingRoomOther bookingDate={bookingDate} roomIndex={idx} />

          <StyledRow sx={{ gap: 2, pl: 10, alignItems: 'stretch' }}>
            <NumberOfAttendees bookingDate={bookingDate} roomIndex={idx} />
            <RoomLayout bookingDate={bookingDate} roomIndex={idx} />
          </StyledRow>
          <GroupSection bookingDate={bookingDate} roomIndex={idx} />
          <Equipment bookingDate={bookingDate} roomIndex={idx} />
          <AdditionalEquipment bookingDate={bookingDate} roomIndex={idx} />
        </>
      )}
    </StyledColumn>
  )
})
DetailsRoom.displayName = 'DetailsRoom'
