import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import Launch from '@mui/icons-material/Launch'
import { StyledCardContent } from '../../../../../../modules/ELearning/components/View/Styled'
import { ContentRow } from '../../../../../../modules/ELearning/components/View'
import {
  StyledHeadCard,
  StyledItemCard,
} from '../../../../../../modules/ELearning/components/Styled'

export const Courses = () => {
  const { formData } = useSelector((state) => state.crud)
  const contents = _.get(formData, 'contents', [])
  let costLength = 0
  contents.map((data) => {
    if (data.type === `LEARNING_PATH`) {
      costLength += _.get(
        data,
        'content.eLearningLearningPathCourses',
        [],
      ).length
    }
    if (data.type === `COURSE`) {
      costLength += 1
    }
  })
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        รายการใน Collection
      </Typography>
      <ContentRow
        title="จำนวนรายการทั้งหมด"
        content={`${contents.length} รายการ`}
      />
      <ContentRow
        title="จำนวนหลักสูตรทั้งหมด"
        content={`${costLength} หลักสูตร`}
      />
      {_.map(contents, (data, index) => (
        <CourseCard key={index} index={index} course={data} />
      ))}
    </StyledCardContent>
  )
}

export const CourseCard = ({ course, index }) => {
  const [isExpanded, setExpand] = useState(true)
  const isLearningPath = course.type === 'LEARNING_PATH'
  const isELearning = course.content.type === 'ELearning'
  const learningPathCourseCount =
    isLearningPath &&
    _.get(course, 'content.eLearningLearningPathCourses', []).length
  const selectedCourse = _.get(course, 'content.uuid', '')
  const coursePath = isLearningPath
    ? '/e-learning/learning-path/view'
    : isELearning
    ? '/e-learning/course/view'
    : '/course-setting/version/preview'
  return (
    <StyledItemCard>
      <StyledHeadCard>
        <Typography variant="body1b" color="primary">
          รายการที่ {index + 1} :{' '}
          {isLearningPath
            ? `Learning Path ( ${learningPathCourseCount} หลักสูตร)`
            : `หลักสูตร`}
        </Typography>
        <IconButton
          sx={{ width: 30, height: 30 }}
          onClick={() => setExpand(!isExpanded)}
        >
          {isExpanded ? (
            <KeyboardArrowUpTwoTone />
          ) : (
            <KeyboardArrowDownTwoTone />
          )}
        </IconButton>
      </StyledHeadCard>
      {isExpanded && (
        <>
          <Divider
            sx={{ width: '100%', borderStyle: 'dashed', color: '#BDBDBD' }}
          />
          <ContentRow
            title={isLearningPath ? 'ชื่อ Learning Path' : 'ชื่อหลักสูตร'}
            content={_.get(course, 'content.label', '-')}
          />
          <Button
            variant="outlined"
            startIcon={<Launch />}
            size="s"
            onClick={() =>
              window.open(`${coursePath}/${selectedCourse}`, '_blank')
            }
          >
            {isLearningPath ? 'รายละเอียด Learning Path' : 'รายละเอียดหลักสูตร'}
          </Button>
        </>
      )}
    </StyledItemCard>
  )
}
