import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { setLessonData } from '../../../../../../redux/slices/eLearning/preview'
import { MediaBoxStyled } from '../Styled'
import { InProgressDisplay } from '../FileRender'
import { getSettingsFromParams } from './events'

const Scorm = ({ selectedLesson }) => {
  const status = _.get(selectedLesson.eLearningModuleFileUpload, '[0].status')

  if (status !== 'SUCCESS') return <InProgressDisplay />
  return <ScormPlayer selectedLesson={selectedLesson} />
}

export default Scorm

export const ScormPlayer = ({ selectedLesson }) => {
  const fileUploaded = selectedLesson.eLearningModuleFileUpload
  const fileName = _.get(fileUploaded, '[0].fileName', [])
  const version = _.get(fileUploaded, '[0].version', '2004')
  const src = `${window.__env__.REACT_APP_APP_URL}${_.get(fileName, '0', '')}`
  const dispatch = useDispatch()
  let completionStatus
  let completionStatusInterval

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const settings = getSettingsFromParams(urlParams)
    const script = document.createElement('script')
    script.src = '../../../script/scorm-again.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = async () => {
      const iframe = document.getElementById('scormFrame')
      iframe.src = src
      if (version === '1.2') {
        window.API = new window.Scorm12API(settings)
        window.API.on('LMSSetValue.cmi.*', function () {
          completionStatus = window.API.lmsGetValue('cmi.core.lesson_status')
        })
      }
      if (version === '2004') {
        window.API_1484_11 = new window.Scorm2004API(settings)
        window.API_1484_11.on('SetValue.cmi.*', function () {
          completionStatus = window.API_1484_11.lmsGetValue(
            'cmi.completion_status',
          )
        })
      }

      completionStatusInterval = setInterval(() => {
        if (completionStatus === 'completed' || completionStatus === 'passed') {
          console.warn('status >>>>>>>>', completionStatus)
          dispatch(setLessonData({ key: 'progress', value: 100 }))
          clearInterval(completionStatusInterval)
        }
      }, 300)
    }

    return () => {
      clearInterval(completionStatusInterval)
      document.body.removeChild(script)
    }
  }, [src])

  return (
    <MediaBoxStyled>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 0 }}
        id="scormFrame"
      />
    </MediaBoxStyled>
  )
}
