import { filterEtestingDashboardResult } from '../../../../../../utils/apiPath'
import { headerCells } from './model/headerCells'

export const crudConfig = {
  moduleId: 'ETESTING_DASHBOARD_TESTLIST',
  moduleSlug: 'e-testing-dashboard-testList',
  moduleTitleFull: 'DashboardTest',
  modulesTitleShort: 'DashboardTest',
  modulePath: '/e-testing/dashboard/view/:uuid',
  filterPath: filterEtestingDashboardResult,
  headerCells: headerCells,
}
