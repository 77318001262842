import { Box } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'

export const Loading = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress sx={{ mx: 4 }} />
    </Box>
  )
}
