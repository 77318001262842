import React, { useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector, useDispatch } from 'react-redux'

import TextInput from '../../../../../../components/Input/TextInput'
import * as dialog from '../../../../../../redux/slices/dialog'
import { setChange } from '../../../../../../redux/slices/manageClassLearnerDetail'
import { handleDialogConfirm } from '../events'

const Index = ({ body, title, contentText, dialogType }) => {
  const dispatch = useDispatch()
  const remark = useSelector((state) => state.manageClassLearnerDetail.remark)
  const isLoading = useSelector((state) => state.dialog.isLoading)
  const [error, setError] = useState('')

  return (
    <Box sx={{ mx: 3, my: 2 }}>
      <Typography variant="h6">{title}</Typography>
      {contentText && <Typography variant="body1">{contentText}</Typography>}
      <Box sx={{ ml: 1, mt: 1 }}>
        <TextInput
          required
          type="basic"
          name="remark"
          rows={2.5}
          labelText="เหตุผล"
          placeholder=""
          multiline={true}
          value={remark}
          onChange={(e) =>
            dispatch(
              setChange({ key: 'remark', value: _.get(e, 'target.value', '') }),
            )
          }
          textError={error}
        />
      </Box>

      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          sx={{ width: 80 }}
          isLoading={isLoading}
          data-testid="btn-disagree"
          onClick={() => dispatch(dialog.closeDialog())}
          variant="outlined"
          endIcon=""
        >
          ยกเลิก
        </Button>
        <Button
          sx={{ width: 80 }}
          data-testid="btn-agree"
          variant="contained"
          endIcon=""
          isLoading={isLoading}
          disabled={!remark || isLoading}
          onClick={() => {
            if (remark.length > 3000) {
              setError('ระบุได้ไม่เกิน 3000 ตัวอักษร')
            } else {
              dispatch(
                handleDialogConfirm({ body: { ...body, remark }, dialogType }),
              )
            }
          }}
        >
          บันทึก
        </Button>
      </Box>
    </Box>
  )
}

export default Index
