import { TableCell, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { E_LEARNING_COURSE_STATUS_LABEL } from '../../../../constants/eLearning'

const StatusLearner = ({ row }) => {
  const theme = useTheme()
  const status = _.get(row, 'learnerStatus', '')
  const color =
    status === E_LEARNING_COURSE_STATUS_LABEL.NOT_STARTED
      ? theme.palette.text.gray
      : status === E_LEARNING_COURSE_STATUS_LABEL.INPROGRESS
      ? theme.palette.graph.blue3
      : theme.palette.graph.green2

  return (
    <TableCell key="learnerStatus" sx={{ minWidth: 170 }}>
      <Typography variant="body2b" color={color}>
        {status}
      </Typography>
    </TableCell>
  )
}

export default StatusLearner
