import React from 'react'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../Styled'
import { toggleEvaluationDrawer } from '../../handler/handleClickOpenDrawer'
import { Row } from '../../components/Styled'
import { useTheme } from '@emotion/react'
import { shallowEqual, useSelector } from 'react-redux'
import { Part1Content } from './components/Part1Content'
import { Part2Content } from './components/Part2Content'
import { Part3Content } from './components/Part3Content'
import { Part4Content } from './components/Part4Content'
import { questionTypeText } from '../../constants/type'
import { StyledDownloadButton } from '../../../../../../../components/redux/Table/Styled'
import { handleDownloadFreeText } from '../../handler/handleDownloadFreeText'
import TruncatedText from '../../components/TruncatedText'

const EvaluationDrawer = () => {
  const { open, title, dataType, drawerType, part4 } = useSelector(
    (state) => ({
      open: state.crud.eEvaluationDashboard?.evaluationDrawer?.isOpen,
      title: state.crud.eEvaluationDashboard?.evaluationDrawer?.title,
      dataType: state.crud.eEvaluationDashboard?.evaluationDrawer?.dataType,
      drawerType: state.crud.eEvaluationDashboard?.evaluationDrawer?.drawerType,
      part4: state.crud.eEvaluationDashboard?.evaluationDrawer?.part4,
    }),
    shallowEqual,
  )
  const theme = useTheme()

  return (
    <StyledDrawer open={open} onClose={() => toggleEvaluationDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <TruncatedText
            text={title ?? ''}
            readMoreTxt={'ดูเพิ่มเติม'}
            maxWidth={'72vw'}
            titleVariant={'h5'}
            maxLines={3}
            dialogTitle={'คำถาม'}
          />
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleEvaluationDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box m={`${theme.spacing(0.5)} ${theme.spacing(2.75)}`}>
        <Row gap={2}>
          <Box
            sx={{
              p: '4px 16px',
              borderRadius: 5,
              background: '#F1F4FA',
              maxWidth: 'max-content',
            }}
          >
            <Typography variant="body2b" color={theme.palette.primary.main}>
              {dataType ?? ''}
            </Typography>
          </Box>
          {drawerType === 'part4' &&
            part4?.type === questionTypeText.FREE_TEXT && (
              <StyledDownloadButton
                data-testid="btn-download"
                disabled={false}
                onClick={() => {
                  handleDownloadFreeText(part4)
                }}
                sx={{ width: '150px' }}
              >
                ดาวน์โหลด
              </StyledDownloadButton>
            )}
        </Row>
      </Box>
      <Box>{drawerType === 'part1' && <Part1Content />}</Box>
      <Box>{drawerType === 'part2' && <Part2Content />}</Box>
      <Box>{drawerType === 'part3' && <Part3Content />}</Box>
      <Box>{drawerType === 'part4' && <Part4Content />}</Box>
      <StyledFooter>
        <Button
          onClick={() => toggleEvaluationDrawer(false)}
          variant="contained"
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default EvaluationDrawer
