export const statusEnum = {
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
  WAITING_REQUISITION_APPROVE: 'รออนุมัติเบิก',
  WAITING_RETURN: 'รอคืน',
  WAITING_RETURN_APPROVE: 'รออนุมัติคืน',
  RETURN_APPROVED: 'เสร็จสิ้น',
  NOT_RETURN: 'ไม่คืน',
}

export const statusValueEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  WAITING_REQUISITION_APPROVE: 'WAITING_REQUISITION_APPROVE',
  WAITING_RETURN: 'WAITING_RETURN',
  WAITING_RETURN_APPROVE: 'WAITING_RETURN_APPROVE',
  RETURN_APPROVED: 'RETURN_APPROVED',
  NOT_RETURN: 'NOT_RETURN',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_CONDITION: 'COMPLETED_WITH_CONDITION',
}

export const statusColorEnum = {
  WAITING_REQUISITION_APPROVE: '#00008F',
  WAITING_RETURN: '#F07662',
  WAITING_RETURN_APPROVE: '#00008F',
  RETURN_APPROVED: '#1CC54E',
  NOT_RETURN: '#FFFFFF',
}
