import { setInitialTable } from '../../../../../redux/slices/table'
import _ from 'lodash'
import { store } from '../../../../../App'
import { handleUpdateTable } from './handleUpdateTable'

export const setDashboardTable = (props = {}, tableHeadText) => {
  const { setTableProps } = props
  const crudConfig = store.getState().crud.crudConfig
  const formData = store.getState().crud.formData
  const headerCells = _.get(crudConfig, 'headerCells', () => {})
  const uniqueScoreData = _.get(formData, 'countUniqueScore', {})
  const { searchText } = store.getState().table.search
  const sort = store.getState().table.table.sort
  const order = store.getState().table.table.order

  const arrayOfData = Object.keys(uniqueScoreData)
    .filter((n) => n !== 'null')
    .sort(function (a, b) {
      if (uniqueScoreData[a] > uniqueScoreData[b]) {
        return -1
      } else if (uniqueScoreData[a] < uniqueScoreData[b]) {
        return 1
      } else {
        if (Number(a) > Number(b)) {
          return -1
        } else if (Number(a) < Number(b)) {
          return 1
        } else {
          return 0
        }
      }
    })
    .map((key, index) => ({
      id: index + 1,
      score: key,
      count: uniqueScoreData[key],
    }))
  const rowLength = arrayOfData.length
  let row = arrayOfData.filter((data) => {
    if (searchText) {
      return data?.score?.includes(searchText)
    } else return true
  })
  if (sort && order) {
    row.sort(function (a, b) {
      if (order === 'desc') return b[sort] - a[sort]
      else if (order === 'asc') return a[sort] - b[sort]
    })
  }

  store.dispatch(
    setInitialTable({
      tableHeadText: tableHeadText,
      customToolbarSx: { width: '100%' },
      sxTable: {
        border: '1px solid #DBE4F199',
        margin: '12px 16px',
        width: 'calc(100% - 36px)',
      },
      onDownload: 'hidden',
      isShowPagination: false,
      isCheckBox: false,
      hideFilter: true,
      rows: row,
      allCount: rowLength,
      headCells: headerCells(),
      placeholder: props.placeholder || 'ค้นหา',
      searchKey: 'name',
      onDelete: () => {},
      ...setTableProps,
    }),
  )
  handleUpdateTable()
}
