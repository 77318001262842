import { crudConfig } from '../crudConfig'

export const breadcrumb =  [
  { title: 'Inventory', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: '/inventory/product',
    pointer: true,
  },
  {
    title: `รายละเอียด${crudConfig.moduleTitleFull}`,
    pointer: false,
  },
]
