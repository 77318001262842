import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import {
  filterEtestingDashboardOverview,
  filterEtestingDashboardOverviewLearner,
  filterEtestingDashboardResult,
} from '../../../../../utils/apiPath'
import { fetchDashboardDetail } from './fetchDashboardDetail'

export const handleFetchDashboardData = async (
  mutateFilterPropToBody,
  uuid,
) => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
  await fetchDashboardDetail(
    mutateFilterPropToBody(uuid),
    filterEtestingDashboardOverview,
  )
  await fetchDashboardDetail(
    mutateFilterPropToBody(uuid),
    filterEtestingDashboardOverviewLearner,
    'learner',
  )
  await fetchDashboardDetail(
    mutateFilterPropToBody(uuid),
    filterEtestingDashboardResult,
    'formData.qResult',
  )
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
