import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import TextInput from '../../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import { getErrorMessage } from '../../event'
import { TooltipForm } from '../../Styled'
import { handleChange, handleDelete } from '../../Answer/event'

const AnswerMatching = (props) => {
  const { indexQuestion, indexAnswer, answers, keyQuestion, disableForm } =
    props
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { title, question } = _.get(answers, indexAnswer, {
    title: '',
    question: '',
  })

  const propsEvent = {
    keyQuestion,
    indexAnswer,
    answers,
  }
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })
  const firstQuestionError = getErrorMessage({
    field: 'ANSWER_QUESTION',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })

  return (
    <Styled.BoxAnswer>
      <Styled.BoxInput>
        <Styled.ContentInput>
          <Styled.BoxNumber>
            <Typography variant="body1b" sx={{ mt: 1.5, minWidth: 60 }}>
              คู่ที่ {indexAnswer + 1}
            </Typography>
            <Divider sx={{ width: 'calc(100% - 88px)' }}/>
            <DeleteButton
              isHover={isHover}
              setIsHover={setIsHover}
              propsEvent={propsEvent}
              disableForm={disableForm}
              isTop
            />
          </Styled.BoxNumber>
          <TextInput
            id="title-question"
            name="question"
            placeholder="คำถาม"
            type="basic"
            boxSx={{ mt: 1, width: '100%' }}
            disabled={disableForm}
            value={question}
            multiline
            maxRows={2}
            onChange={(e) =>
              dispatch(
                handleChange({
                  ...propsEvent,
                  key: 'question',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            textError={firstQuestionError}
          />
        </Styled.ContentInput>

        <Styled.ContentInput>
          <TextInput
            id="title-answer"
            name="title"
            placeholder="คำตอบ"
            type="basic"
            boxSx={{ mt: 1, width: '100%' }}
            multiline
            maxRows={2}
            disabled={disableForm}
            value={title}
            onChange={(e) =>
              dispatch(
                handleChange({
                  ...propsEvent,
                  key: 'title',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            textError={answerErrorMessage}
          />
          <DeleteButton
            isHover={isHover}
            setIsHover={setIsHover}
            propsEvent={propsEvent}
            disableForm={disableForm}
          />
        </Styled.ContentInput>
      </Styled.BoxInput>
    </Styled.BoxAnswer>
  )
}
export default AnswerMatching

export const DeleteButton = (props) => {
  const { isHover, setIsHover, propsEvent, disableForm, isTop } = props
  const { indexAnswer } = propsEvent
  const dispatch = useDispatch()
  return (
    <Styled.BoxIcon isTop={isTop}>
      <TooltipForm
        placement="bottom"
        title={<Typography variant="tooltip">ลบ</Typography>}
      >
        <IconButton
          sx={{ mt: -2 }}
          onMouseLeave={() => setIsHover(false)}
          onMouseOver={() => setIsHover(true)}
          onClick={() => !disableForm && dispatch(handleDelete(propsEvent))}
          data-testid={`btn-delete-${indexAnswer}`}
        >
          <CloseOutlined color={isHover ? 'primary' : 'action'} />
        </IconButton>
      </TooltipForm>
    </Styled.BoxIcon>
  )
}
