import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { manageClassDashboardFilter } from '../../../../utils/apiPath'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { overviewsDemo, overviewStatusDemo } from '../models/overview'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { manageClassDashboard } = store.getState().crud
  const station = _.defaultTo(manageClassDashboard?.filters?.station, []).map(
    (item) => item.uuid,
  )
  const body = {
    station: station,
    startDate: _.defaultTo(
      manageClassDashboard?.filters?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      manageClassDashboard?.filters?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
  }
  // let result = []
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  await callAxios
    .post(manageClassDashboardFilter, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'manageClassDashboard.latestUpdated',
          value: _.get(data, 'latestUpdated', undefined),
        }),
      )
      const overviewsData = overviewsDemo.map((item) => {
        return {
          ...item,
          number: _.get(data, item.value, 0),
        }
      })
      store.dispatch(
        setFieldValue({
          key: 'manageClassDashboard.overviewsData',
          value: overviewsData,
        }),
      )

      const overviewsStatusData = overviewStatusDemo.map((item) => {
        return {
          ...item,
          number: _.get(data, item.value, 0),
          percent: _.get(data, item.value, 0),
        }
      })
      store.dispatch(
        setFieldValue({
          key: 'manageClassDashboard.overviewsStatusData',
          value: overviewsStatusData,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
