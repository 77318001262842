import { store } from '../../../App'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { fetchReports } from './fetchReports'

export const handleChangeRowsPerPage = async (event) => {
  const { isInitial } = store.getState().reportComp
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1

  await store.dispatch(
    setReduxValue({
      key: 'rowsPerPage',
      value: { label: '' + rowsPerPageChange, value: rowsPerPageChange },
    }),
  )
  await store.dispatch(setReduxValue({ key: 'page', value: -1 }))
  await store.dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
  if (!isInitial) fetchReports()
}
