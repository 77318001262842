import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import {
  EXPIRATION_TYPE,
  TIME_SPAN_TYPE,
} from '../../../../../constants/eLearning'
import { DetailCard } from '../components'
import { StyledContainer, StyledTopWrapper } from './Styled'

const TopSection = () => {
  const data = useSelector(
    (state) => state.eLearningPathPreview.data,
    shallowEqual,
  )
  return (
    <StyledTopWrapper>
      <Typography variant="h5" color="primary">
        {_.get(data, 'name', '')}
      </Typography>
      <BoxContents data={data} />
      <Button variant="contained">ลงทะเบียน</Button>
    </StyledTopWrapper>
  )
}

export default TopSection

export const BoxContents = ({ data }) => {
  const courses = _.get(data, 'eLearningLearningPathCourses', [])
  const courseAccessExpiration = _.get(data, 'courseAccessExpiration', '')
  return (
    <StyledContainer>
      <DetailCard sx={{ maxWidth: 226 }} icon="ic_book_open.svg">
        เนื้อหาทั้งหมด
        <br />
        {courses.length} บทเรียน
      </DetailCard>
      {courseAccessExpiration === EXPIRATION_TYPE.TIME_SPAN && (
        <DetailCard
          id="timeSpan"
          icon="ic_screen_time.svg"
          sx={{ maxWidth: 226, px: 1.5, gap: '14px' }}
        >
          เรียนให้จบภายใน {_.get(data, 'timeSpan', 0)}{' '}
          {TIME_SPAN_TYPE[_.get(data, 'timeSpanType', '')]}
          <br />
          <Typography variant="span" color="secondary">
            นับจากวันที่เริ่มอบรม
          </Typography>
        </DetailCard>
      )}
      {!_.isEmpty(data.eLearningLearningPathRefCertificate) && (
        <DetailCard sx={{ maxWidth: 226 }} icon="ic_cert.svg">
          ได้รับประกาศนียบัตร
        </DetailCard>
      )}
    </StyledContainer>
  )
}
