import React from 'react'
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined'
export const dialogType = {
  fail: {
    title: 'ผิดพลาด',
    message: 'ระบบขัดข้อง',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
    icon: <ErrorOutlineOutlined color="error" />,
  },
  delete: {
    title: 'ยืนยันลบข้อมูล',
    message: 'คุณต้องการลบหรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ลบ',
    color: 'error',
    icon: '',
  },
  success: {
    title: 'สำเร็จ',
    message: 'ดำเนินการสำเร็จ',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'primary',
    icon: <CheckCircleOutlineOutlined color="primary" />,
  },
  confirm: {
    title: 'ยืนยันการดำเนินการ',
    message: 'ต้องการดำเนินการหรือไม่?',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
    icon: '',
  },
  content: {
    titile: 'การดำเนินการ',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
    icon: '',
  },
  setActive: {
    titile: 'การดำเนินการ',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
    icon: '',
  },
  yesOrNot: {
    titile: 'ยืนยันการเปลี่ยนแปลงข้อมูล',
    disagreeText: 'ไม่ใช่',
    agreeText: 'ใช่',
    color: 'primary',
    icon: '',
  },
}
