import React from 'react'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { StyledBox } from '../Styled'
import { openEnrollDrawer } from '../../../../../redux/slices/eLearning/dashboard'

const ActionViewEnrollList = ({ row }) => {
  const dispatch = useDispatch()
  return (
    <StyledBox minWidth={0}>
      <>
        <IconButton
          color="primary"
          component="span"
          onClick={() =>
            dispatch(
              openEnrollDrawer({
                header: `รายชื่อผู้เรียน Enroll (${row.courseType}) - ${row.name}`,
                uuid: row.uuid,
              }),
            )
          }
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      </>
    </StyledBox>
  )
}

export default ActionViewEnrollList
