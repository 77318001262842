import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: '0px 20px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px',
  },
}))

export const BoxLeft = styled(Box)(() => ({
  width: 400,
}))

export const BoxRight = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  width: '60%',
  maxWidth: '300px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}))

export const BoxRight2 = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  width: '60%',
  display: 'block',
  alignItems: 'center',
  wordWrap: 'break-word',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}))

export const StyledDot = styled(Box)(({ theme, status }) => ({
  width: 8,
  height: 8,
  borderRadius: '100%',
  backgroundColor:
    status == 'ACTIVE'
      ? theme?.palette?.success?.main
      : theme?.palette?.action?.disabled,
}))
