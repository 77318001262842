import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  responsiveText,
  StyledHeaderContainer,
} from './../../../../../components/Preview/Header/Styled'
import Timer from './Timer'
import Round from './Round'
import { StyledHeaderTitle } from './Styled'

const Header = () => {
  const { information, limitTime, isSubmit, isReview, section } = useSelector(
    (state) => ({
      information: state.eTestingPreview.information,
      limitTime: state.eTestingPreview.setting.limitTime,
      isSubmit: state.eTestingPreview.isSubmit,
      isReview: state.eTestingPreview.isReview,
      section: state.eTestingPreview.section,
    }),
    shallowEqual
  )
  const isSetTimer = _.get(limitTime, 'isOpen', false)
  return (
    <StyledHeaderContainer>
      <Box>
        <StyledHeaderTitle
          section={section}
          isSetTimer={!isReview && isSetTimer}
        >
          <Typography variant={responsiveText('h4', 'h5', 'body2b')}>
            {_.get(information, 'title1', '')}
          </Typography>
          <Typography variant={responsiveText('h6', 'body1b', 'subtitle2')}>
            {_.get(information, 'title2', '')}
          </Typography>
        </StyledHeaderTitle>
        {!isReview && section == 'TESTING' && isSetTimer && (
          <Timer limitTime={limitTime} />
        )}
        {isSubmit && section == 'RESULT' && <Round />}
      </Box>
    </StyledHeaderContainer>
  )
}

export default Header
