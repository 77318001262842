import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import {
  COURSE_TYPE,
  RADIO_TYPE_ENUM,
  RADIO_TYPE_LABEL,
} from '../../../../../../constants/eLearning'
import DatePicker from '../../../../../../components/Input/DatePicker'
import CreatableGroupChip from '../../../../../../components/CreatableGroupChip'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import Select from '../../../../../../components/Input/Select'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import { onDateChange } from '../Period/events'
import { StyledRadioGroup } from '../Styled'
import { timeSpanTypeOptions } from './model'
import {
  handleDefaultList,
  handleDefaultSelect,
  handleKeyDown,
  handleTimeSpanOptions,
} from './events'

const Course = () => {
  const [inputValue, setInputValue] = useState('')
  const tagKey = 'setting.tag'
  const distributionKey = 'setting.distribution'
  const levelOfLearnerKey = 'levelOfLearner'
  const acquiredSkillKey = 'acquiredSkill'
  const productTypeKey = 'productType'
  const dueDateKey = 'dueDate'
  const remarkKey = 'setting.remark'
  const timeSpanTypeKey = 'timeSpanType'
  const timeSpanKey = 'timeSpan'
  const isSkipAllowedKey = 'setting.isSkipAllowed'
  const dispatch = useDispatch()
  const {
    distributionOption,
    levelOfLearnerOption,
    acquireSkillList,
    productTypeList,
    courseType,
    dueDateType,
    courseAccessExpiration,
    timeSpanTypeState,
    timeSpanState,
    tag,
    distribution,
    levelOfLearner,
    acquiredSkill,
    productType,
    dueDate,
    remark,
    isSkipAllowed,
  } = useSelector(
    (state) => ({
      distributionOption: state.eLearningCourseForm.distributionOption,
      levelOfLearnerOption: state.eLearningCourseForm.levelOfLearnerOption,
      acquireSkillList: state.eLearningCourseForm.acquireSkillList,
      productTypeList: state.eLearningCourseForm.productTypeList,
      courseType: getStateValue('courseType'),
      dueDateType: getStateValue('dueDateType'),
      courseAccessExpiration: getStateValue('courseAccessExpiration'),
      timeSpanTypeState: getStateValue(timeSpanTypeKey),
      timeSpanState: getStateValue(timeSpanKey),
      tag: getStateValue(tagKey, []),
      distribution: getStateValue(distributionKey, []),
      levelOfLearner: getStateValue(levelOfLearnerKey, []),
      acquiredSkill: getStateValue(acquiredSkillKey, []),
      productType: getStateValue(productTypeKey, []),
      dueDate: getStateValue(dueDateKey, null),
      remark: getStateValue(remarkKey, null),
      isSkipAllowed: getStateValue(isSkipAllowedKey, false),
    }),
    shallowEqual,
  )
  const timeSpanType = useMemo(
    () => handleDefaultSelect(timeSpanTypeOptions, timeSpanTypeState),
    [timeSpanTypeOptions, timeSpanTypeState],
  )
  const timeSpanDynamicOption = useMemo(
    () => handleTimeSpanOptions(timeSpanType),
    [timeSpanType],
  )
  const timeSpan = useMemo(
    () => handleDefaultSelect(timeSpanDynamicOption, timeSpanState),
    [timeSpanDynamicOption, timeSpanState],
  )
  const { errors, setFieldError } = useFormikContext()
  const errorDueDate = _.get(errors, dueDateKey, '')
  const isDueDateError = !_.isEmpty(errorDueDate)
  const errorTimeSpanType = _.get(errors, timeSpanTypeKey, '')
  const errorTimeSpan = _.get(errors, timeSpanKey, '')

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="การตั้งค่าหลักสูตร" />
        <CreatableGroupChip
          required={courseType === COURSE_TYPE.OIC}
          id="tag"
          labelText="Tag"
          placeholder="กรุณาเพิ่ม Tag"
          value={tag}
          textError={_.get(errors, tagKey, '')}
          inputValue={inputValue}
          onChange={(value) => dispatch(handleChange(tagKey, value))}
          onInputChange={(value) => setInputValue(value)}
          onKeyDown={(e) =>
            dispatch(handleKeyDown(e, inputValue, setInputValue))
          }
        />
        <SelectGroupChip
          required
          id="distribution"
          name="distribution"
          labelText="ช่องทางการจัดจำหน่าย"
          placeholder="กรุณาเลือก"
          options={[...distributionOption]}
          textError={_.get(errors, distributionKey, '')}
          isShowTextError={_.get(errors, distributionKey, '')}
          value={distribution}
          onChange={(list) => dispatch(handleChange(distributionKey, list))}
        />
        <SelectGroupChip
          required
          id={levelOfLearnerKey}
          name={levelOfLearnerKey}
          labelText="ระดับของผู้เรียน"
          placeholder="กรุณาเลือก"
          options={[...levelOfLearnerOption]}
          textError={_.get(errors, levelOfLearnerKey, '')}
          isShowTextError={_.get(errors, levelOfLearnerKey, '')}
          value={handleDefaultList(levelOfLearnerOption, levelOfLearner)}
          onChange={(list) => dispatch(handleChange(levelOfLearnerKey, list))}
        />
      </Box>

      <Divider sx={{ mx: 0.5 }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="Skill Mapping" />
        <SelectGroupChip
          required
          id={acquiredSkillKey}
          name={acquiredSkillKey}
          labelText="ระดับหลักสูตร"
          placeholder="กรุณาเลือก"
          options={acquireSkillList}
          textError={_.get(errors, acquiredSkillKey, '')}
          isShowTextError={_.get(errors, acquiredSkillKey, '')}
          value={handleDefaultList(acquireSkillList, acquiredSkill)}
          onChange={(list) => dispatch(handleChange(acquiredSkillKey, list))}
        />
        <SelectGroupChip
          required
          id={productTypeKey}
          name={productTypeKey}
          labelText="ทักษะที่ได้จากหลักสูตร"
          placeholder="กรุณาเลือก"
          options={productTypeList}
          textError={_.get(errors, productTypeKey, '')}
          isShowTextError={_.get(errors, productTypeKey, '')}
          value={handleDefaultList(productTypeList, productType)}
          onChange={(list) => dispatch(handleChange(productTypeKey, list))}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <HeadLabel text="ระยะเวลาแสดงบนเว็บไซต์" required />
        <StyledRadioGroup
          name="dueDateType"
          value={dueDateType}
          onChange={(e) => {
            dispatch(handleChangeEvent(e))
            dispatch(handleChange(dueDateKey, null))
            if (!isDueDateError) setFieldError(dueDateKey, undefined)
          }}
        >
          <FormControlLabel
            value={RADIO_TYPE_ENUM.NONE}
            control={<Radio />}
            label={RADIO_TYPE_LABEL.NONE}
          />
          <FormControlLabel
            value={RADIO_TYPE_ENUM.SPECIFIC_DATE}
            control={<Radio />}
            label={RADIO_TYPE_LABEL.SPECIFIC_DATE}
          />
        </StyledRadioGroup>
        {dueDateType === RADIO_TYPE_ENUM.SPECIFIC_DATE && (
          <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
            <HeadLabel
              text="แสดงหลักสูตรนี้จนถึง"
              variant="body1"
              required
              sx={{ mt: 1 }}
            />
            <DatePicker
              boxSx={{ width: 'auto', minWidth: 180 }}
              sxTextError={{ mb: 0 }}
              id="dueDate"
              name="dueDate"
              disablePast
              hideDisableDateRange
              textError={errorDueDate}
              hideTextError={!isDueDateError}
              value={dueDate || ''}
              onChange={(value) => dispatch(onDateChange(value, dueDateKey))}
            />
          </Box>
        )}

        {courseType === COURSE_TYPE.GENERAL && (
          <>
            <HeadLabel text="ระยะเวลาการเข้าถึงหลักสูตร" required />
            <StyledRadioGroup
              name="courseAccessExpiration"
              value={courseAccessExpiration}
              onChange={(e) => {
                dispatch(handleChangeEvent(e))
                if (e.target.value === RADIO_TYPE_ENUM.TIME_SPAN) {
                  dispatch(handleChange(timeSpanTypeKey, 'MONTH'))
                  dispatch(handleChange(timeSpanKey, 1))
                } else {
                  dispatch(handleChange(timeSpanTypeKey, null))
                  dispatch(handleChange(timeSpanKey, null))
                }
                if (
                  !_.isEmpty(errorTimeSpanType) ||
                  !_.isEmpty(errorTimeSpan)
                ) {
                  setFieldError(timeSpanTypeKey, undefined)
                  setFieldError(timeSpanKey, undefined)
                }
              }}
            >
              <FormControlLabel
                value={RADIO_TYPE_ENUM.NONE}
                control={<Radio />}
                label={RADIO_TYPE_LABEL.NONE}
              />
              <FormControlLabel
                value={RADIO_TYPE_ENUM.TIME_SPAN}
                control={<Radio />}
                label={RADIO_TYPE_LABEL.TIME_SPAN}
              />
            </StyledRadioGroup>
            {courseAccessExpiration === 'TIME_SPAN' && (
              <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
                <HeadLabel
                  text="ระยะเวลา"
                  variant="body1"
                  required
                  sx={{ mt: 1.5 }}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Select
                    boxSx={{ width: 100 }}
                    selectSx={{ height: 54 }}
                    textErrorSx={{ mb: 0 }}
                    type="basic"
                    dataTestid="select-time-span-type"
                    id="timeSpanType"
                    name="timeSpanType"
                    options={timeSpanTypeOptions}
                    showTextError={errorTimeSpanType}
                    textError={errorTimeSpanType}
                    value={timeSpanType}
                    handleChange={(e) => {
                      dispatch(handleChangeEvent(e))
                      dispatch(handleChange(timeSpanKey, null))
                    }}
                  />
                  <Select
                    boxSx={{ width: 100 }}
                    selectSx={{ height: 54 }}
                    textErrorSx={{ mb: 0 }}
                    type="basic"
                    dataTestid="select-time-span"
                    id="timeSpan"
                    name="timeSpan"
                    options={timeSpanDynamicOption}
                    showTextError={errorTimeSpan}
                    textError={errorTimeSpan}
                    value={timeSpan}
                    handleChange={(e) => dispatch(handleChangeEvent(e))}
                  />
                </Box>
              </Box>
            )}
          </>
        )}

        <HeadLabel text="การอนุญาตให้ข้ามโมดูลในหลักสูตร" required />
        <StyledRadioGroup
          name={isSkipAllowedKey}
          value={isSkipAllowed}
          onChange={(e) => {
            const isAllowed = e.target.value === 'true'
            dispatch(handleChange(isSkipAllowedKey, isAllowed))
          }}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={RADIO_TYPE_LABEL.ALLOW}
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label={RADIO_TYPE_LABEL.NOT_ALLOW}
          />
        </StyledRadioGroup>
      </Box>

      <Divider sx={{ mx: 0.5 }} />

      <TextInput
        multiline
        rows={3}
        name="setting.remark"
        type="basic"
        labelText="Remark"
        id="input-setting-remark"
        textError={_.get(errors, remarkKey, '')}
        isShowTextError={_.get(errors, remarkKey, '')}
        value={remark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default Course

export const HeadLabel = ({ text, variant = 'h6', required, sx }) => {
  return (
    <Typography variant={variant} sx={sx}>
      {text}{' '}
      {required && (
        <Typography variant="span" color="error">
          *
        </Typography>
      )}
    </Typography>
  )
}
