import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Drawer from '@mui/material/Drawer'
import _ from 'lodash'
import { manageClassTheme } from '../../../../../constants/manageClass'

export const StyledInventoryCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '33%',
  minWidth: 248,
  display: 'flex',
  flexDirection: 'column',
  background: _.get(manageClassTheme.inventory, 'background', 'transparent'),
  border: '1px solid',
  borderColor: _.get(manageClassTheme.inventory, 'border', 'transparent'),
  borderRadius: theme.spacing(1),
  padding: `${theme.spacing(0.75)} ${theme.spacing(3)}`,
  hr: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderWidth: `0 0 0 ${theme.spacing(1)}`,
    borderColor: _.get(manageClassTheme.inventory, 'leftBorder', 'transparent'),
    borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
  },
  button: {
    position: 'absolute',
    bottom: 7,
    right: 12,
  },
  [theme.breakpoints.down('md')]: { minWidth: 'unset' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: 130,
  height: 24,
  background: theme.palette?.primary?.outlinedHoverBackground,
  span: {
    color: theme.palette?.primary?.main,
    fontSize: 16,
    fontWeight: 400,
  },
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({ height: '100vh', '& .MuiDrawer-paper': { width: 360 } }))

export const StyledContent = styled(Box)(() => ({
  position: 'relative',
  opacity: 'unset',
  pointerEvents: 'unset',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

export const DetailContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  border: '1px solid #CCCCCC',
  button: {
    position: 'absolute',
    bottom: 'calc(50% - 17px)',
    right: 18,
  },
}))

export const DotStyle = styled('div')(({ color }) => ({
  borderRadius: 5,
  backgroundColor: color,
  width: 8,
  height: 8,
}))
