import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const CheckboxFilterStation = () => {
  const dispatch = useDispatch()
  const { isFilterStation } = useSelector(
    (state) => ({
      isFilterStation:
        state.roomManagement.drawerCalendarFilter.isFilterStation,
    }),
    shallowEqual,
  )
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isFilterStation}
          onChange={(e) => {
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'isFilterStation',
                value: e.target.checked,
              }),
            )
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'filterStation',
                value: [],
              }),
            )
          }}
        />
      }
      label="Station"
    />
  )
}
