import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Information from './Information'
import Footer from './Footer'
import MainInformation from './MainInformation'
import EducationSection from './Education'
import CourseSection from './Course'
import ExamSection from './Exam'
import ETestingSection from './ETesting'

const Index = ({ type }) => {
  const userId = useSelector(
    (state) => _.get(state.prospectProfile.initialState, 'info.uuid', ''),
    shallowEqual,
  )
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <MainInformation />
      <Information type={type} />
      <EducationSection />
      <CourseSection />
      <ExamSection />
      {userId && <ETestingSection userId={userId} />}
      <Footer />
    </Box>
  )
}

export default Index
