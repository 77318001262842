import _ from 'lodash'

export const getOptionsCostCenter = (data) => {
  return _.map(data, (item) => {
    return {
      ...item,
      label: item['costCenter'],
      value: item['uuid'],
    }
  })
}
