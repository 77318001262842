import { Box } from '@mui/material'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import _ from 'lodash'

export const handleIsCanDelete = (row) => {
  const status = _.get(row, 'status', '')
  return status != 'CANCEL' && status != 'CANCELED'
}

export const handleDeleteDialog = (content) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'Error',
      type: 'content',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
          {content}
        </Box>
      ),
      hideCancel: true,
      handleConfirm: () => dispatch(closeDialog()),
    }),
  )
}
