export const SECTION_KEY = {
  BANNER: 'BANNER',
  COURSE: 'COURSE',
  LEARNING_PATH: 'LEARNING_PATH',
  COLLECTION: 'COLLECTION',
}

export const SECTION_OBJ = {
  [SECTION_KEY.BANNER]: {
    sectionNo: 1,
    title: 'Banner Section',
    keyInfo: 'bannerSection',
  },
  [SECTION_KEY.COURSE]: {
    sectionNo: 2,
    title: 'Courses Recommend',
    keyInfo: 'coursesInformation',
    keySub: 'courses',
    keyValue: 'course',
    keyDistribution: 'distributionlevel',
    inputTitle: 'หลักสูตรใน',
    label: 'รหัสหลักสูตร',
  },
  [SECTION_KEY.LEARNING_PATH]: {
    sectionNo: 3,
    title: 'เส้นทางการเรียนรู้',
    keyInfo: 'learningPathInformation',
    keySub: 'learningPaths',
    keyValue: 'learningPath',
    keyDistribution: 'distributionlevelForPath',
    inputTitle: 'เส้นทางการเรียนรู้ที่ต้องแสดงในหน้าแรก E-Learning',
    label: 'รหัส Learning path',
  },
  [SECTION_KEY.COLLECTION]: {
    sectionNo: 4,
    title: 'Collection',
    keyInfo: 'collectionInformation',
    keySub: 'collections',
    keyValue: 'collection',
    keyDistribution: 'distributionlevelCollection',
    inputTitle: 'หลักสูตรที่ต้องแสดงในหน้าแรก Collection',
    label: 'ชื่อ Collection',
  },
}
