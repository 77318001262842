import { defaultValue } from './../../../constants/eTesting'

export const initialState = {
  isLoading: false,
  isButtonLoading: false,
  isSubmit: false,
  isReview: false,
  isTimeUp: false,
  section: defaultValue.defaultSection.testing, // TESTING | RESULT
  page: 1,
  information: {
    coverImage: '',
    coverText: '',
    description: '',
    descriptionPosition: defaultValue.position.left,
    name: '',
    title1: '',
    title1Position: defaultValue.position.left,
    title2: '',
    title2Position: defaultValue.position.left,
  },
  setting: {
    statusTesting: 'draft',
    startDate: null,
    limitTime: {
      isOpen: false, // true | false
      minute: 30,
    },
    assignees: [],
    displayQuestion: defaultValue.displayQuestion.all, // ALL | ONE
    randomQuestion: false, // true | false
    randomAnswer: false, // true | false
    testResult: defaultValue.testResult.later,
    questionResult: {
      isOpen: false, // true | false
      result: defaultValue.testResult.later,
      resultType: defaultValue.resultType.showFalseOnly,
    },
    testRound: {
      roundUnlimit: true, // true | false
      round: 1,
    },
    notification: true, // true | false
  },
  testing: [
    {
      uuid: '',
      key: 0,
      sequence: 1,
      url: '',
      type: defaultValue.questionType.single,
      title: '',
      score: 0,
      choices: [],
      answer: [],
      isAnswered: false,
    },
  ],
  mobileProps: {
    isMobile: false,
    selectedItem: null,
  }
}
