import _ from 'lodash'
import {
  setAnswered,
  setChangeStateByKey,
} from '../../../../../../../../redux/slices/eTestingPreview'

export const handleRadioChange =
  (e, answer, choice, keyQuestion) => (dispatch) => {
    const value = _.get(e, 'target.value', null)
    const newAnswer = [...answer]
    const answerIndex = answer.findIndex(
      (ans) => ans.questionUuid === choice.uuid
    )
    const objValue = {
      questionUuid: choice.uuid,
      score: choice.score,
      booleanAns: value,
      isCorrect: value === choice.booleanAns.toString(),
    }
    if (answerIndex === -1) newAnswer.push(objValue)
    else newAnswer[answerIndex] = objValue
    dispatch(
      setChangeStateByKey({
        key: 'answer',
        value: newAnswer,
        keyQuestion,
      })
    )
    dispatch(setAnswered({ keyQuestion, value }))
  }
