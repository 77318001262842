import { createAsyncThunk } from '@reduxjs/toolkit'
import callAxios from '../../utils/baseService'
import {
  manageClassLearnerDocument,
  manageClassLearnerApprove,
  manageClassLearnerReject,
  manageClassLearnerApproveStatus,
} from '../../utils/apiPath'

export const getManageClassLearnerDocument = createAsyncThunk(
  'manageClassDetail/getManageClassLearnerDocument',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await callAxios.get(`${manageClassLearnerDocument}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const putManageClassLearnerApprove = createAsyncThunk(
  'manageClassDetail/putManageClassLearnerApprove',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await callAxios.put(`${manageClassLearnerApprove}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const putManageClassLearnerReject = createAsyncThunk(
  'manageClassDetail/putManageClassLearnerReject',
  async (body, { rejectWithValue }) => {
    try {
      const response = await callAxios.put(manageClassLearnerReject, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const postManageClassLearnerApproveStatus = createAsyncThunk(
  'manageClassDetail/postManageClassLearnerApproveStatus',
  async (body, { rejectWithValue }) => {
    try {
      const response = await callAxios.post(manageClassLearnerApproveStatus, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
