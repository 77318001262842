import { contentTypeEnum } from '../enum/contentTypEnum'

export const contentTypeKB = {
  content: {
    question: '',
    answer: '',
    files: [],
  },
  type: contentTypeEnum.KB.key,
}
