import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoadingPageCircular from '../../../../modules/ELearning/components/LoadingPageCircular'
import { StyledFormWrapper } from './Styled'
import Header from './components/header'
import TabMenu from './components/tabMenu'
import ContentTab from './components/contentTab'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import _ from 'lodash'
import { clearFieldValue, setFieldValue } from '../../../../redux/slices/crud'
import { getBodyMutation } from './mutation/get'
import SettingTab from './components/settingTab'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { setupLevel } from './handler/setupLevel'
import { initialForm } from './handler/initialForm'
import { checkFormPermission } from './handler/checkFormPermission'

const ContentForm = () => {
  const { uuid } = useParams()
  const [user] = useLocalStorage('user')
  const [canEdit, setCanEdit] = useState(true)
  const { crudConfig, isLoading, tab } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
      tab: state.crud.tab,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const initializeCrud = async () => {
    if (uuid && !_.isUndefined(crudConfig) && !_.isEmpty(crudConfig)) {
      await initialForm(user)
      const response = await fetchDetail(uuid, getBodyMutation, user)
      setCanEdit(checkFormPermission(response, user))
      setupLevel()
    } else if (!uuid && !_.isUndefined(crudConfig) && !_.isEmpty(crudConfig)) {
      await initialForm(user)
      dispatch(
        setFieldValue({ key: 'formData', value: getBodyMutation({}, user) }),
      )
    } else {
      initialConfig(initialCrudConfig)
    }
  }

  useEffect(initializeCrud, [crudConfig])
  useEffect(() => {
    if (tab !== 'content' && tab !== 'setting') {
      dispatch(setFieldValue({ key: 'tab', value: 'content' }))
    }
  }, [tab])
  useEffect(() => {
    return () => {
      dispatch(clearFieldValue())
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <LoadingPageCircular isLoading={isLoading} />
      ) : canEdit ? (
        !_.isUndefined(crudConfig) && (
          <Box sx={{ mx: 3 }}>
            <div>
              <Header />
              <TabMenu tab={tab} />
              <FormContent />
            </div>
          </Box>
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '45%',
            left: '45%',
          }}
        >
          <Typography variant="h4">403 Forbidden</Typography>
          <Button
            sx={{ alignSelf: 'center' }}
            variant="contained"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        </Box>
      )}
    </>
  )
}

export default ContentForm

export const FormContent = () => {
  const { tab } = useSelector(
    (state) => ({
      tab: state.crud.tab,
    }),
    shallowEqual,
  )
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'content' && <ContentTab />}
      {tab === 'setting' && <SettingTab />}
    </StyledFormWrapper>
  )
}
