import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledMatchingContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const StyledKeywordContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  minHeight: 74,
  alignItems: 'center',
  background: theme.palette?.background?.paper,
  border: '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
}))

export const StyledQuestionList = styled(Box)(({ theme, resultProps }) => ({
  minHeight: 50,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  background: displayResult(theme, resultProps),
  padding: theme.spacing(resultProps.isSubmit ? 1.5 : 0),
}))

export const StyledSelect = styled(Select)(({ theme, isSubmit, isAnswer }) => ({
  width: 72,
  height: 50,
  pointerEvents: isSubmit ? 'none' : 'auto',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: isSubmit
      ? isAnswer
        ? theme.palette?.success?.main
        : theme.palette?.error?.main
      : 'rgba(0, 0, 0, 0.23)',
  },
}))

export const StyledNumberBadge = styled(Box)(({ theme, isAnswer }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 25,
  color: theme.palette?.text?.white,
  borderRadius: '100%',
  backgroundColor: isAnswer
    ? theme.palette?.success?.main
    : theme.palette?.error?.main,
}))

export const StyledKeyword = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  minHeight: 40,
  minWidth: 'auto',
  overflow: 'hidden',
  border: '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: '2px',
  background: selected
    ? theme.palette?.action?.disabled
    : 'linear-gradient(180deg, #FAFAFA 0%, #EEE 100%)',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  gap: theme.spacing(2),
  '& > #title': {
    minWidth: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    textAlign: 'start',
  },
}))

export const StyledQuestion = styled(Typography)(() => ({
  minWidth: 'auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  textAlign: 'start',
}))

export const displayResult = (theme, resultProps) => {
  const { isSubmit, isAnswer, questionResult } = resultProps
  const { isOpen } = questionResult

  if (!isOpen) return 'none'
  if (!isSubmit) return 'none'

  return isAnswer
    ? theme?.palette?.success?.background
    : theme?.palette?.error?.background
}
