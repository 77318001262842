import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import dayjs from 'dayjs'
import useTheme from '@mui/system/useTheme'
import {
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
  Tooltip,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Close from '@mui/icons-material/Close'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab'
import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { StyledColumn, StyledRow } from '../../Styled'
import DatePicker from '../../../../components/Input/DatePicker'
import { fetchRoomHistoryAll } from './event'
import { convertFormatDateTime, isValidateDate } from '../../../../utils/lib'
import { LoadingPage } from '../../Styled'
import {
  startFetch,
  loadNextPage,
  setFilter,
  stopFetch,
  clearState,
} from '../../../../redux/slices/roomManagementHistory'
import { requestType } from '../../View/Content/CalendarCard/constanst'
import BookingDetail from './BookingDetail'
import { setIsOpenHistoryDrawer } from '../../../../redux/slices/roomManagement/Dashboard'

const TimelineContentHeader = ({ bookingTopic, requestTypeData }) => {
  return (
    <StyledRow>
      <StyledColumn>
        <Typography variant="body2" color="text.lightGray">
          หัวข้อ
        </Typography>
        <Typography
          variant="body2b"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '350px',
          }}
        >
          <Tooltip
            title={<Typography variant="body2">{bookingTopic}</Typography>}
            componentsProps={{
              tooltip: {
                sx: {
                  background: '#3032C1',
                },
              },
            }}
          >
            {bookingTopic}
          </Tooltip>
        </Typography>
      </StyledColumn>
      <StyledColumn sx={{ mb: 'auto', ml: 'auto' }}>
        <Box
          sx={{
            background: requestType.find(
              (type) => type.value === requestTypeData,
            ).background,
            borderRadius: 2,
            px: 1,
          }}
        >
          <Typography
            sx={{
              color: requestType.find((type) => type.value === requestTypeData)
                .color,
            }}
            variant="body3b"
          >
            {requestType.find((type) => type.value === requestTypeData).label}
          </Typography>
        </Box>
      </StyledColumn>
    </StyledRow>
  )
}

const HistoryDrawer = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    stationUuid,
    isLoading,
    isFetch,
    filter,
    hasMore,
    history,
    isOpenHistoryDrawer,
  } = useSelector(
    (state) => ({
      stationUuid: state.roomManagement.stationUuid,
      isLoading: state.roomManagementHistory.isLoading,
      isFetch: state.roomManagementHistory.isFetch,
      filter: state.roomManagementHistory.filter,
      hasMore: state.roomManagementHistory.hasMore,
      history: state.roomManagementHistory.history,
      isOpenHistoryDrawer: state.roomManagement.isOpenHistoryDrawer,
    }),
    shallowEqual,
  )
  useEffect(() => {
    if (isFetch && filter.startDate && filter.endDate) {
      dispatch(
        fetchRoomHistoryAll({
          stationUuid,
          ...filter,
          page: filter.page + 1,
          currentLength: history.length,
        }),
      )
    } else {
      dispatch(stopFetch())
    }
  }, [isFetch])

  const handleChangeFilter = (key, value) => {
    dispatch(
      setFilter({
        ...filter,
        [key]: value,
      }),
    )
  }

  return (
    <StyledDrawer
      open={isOpenHistoryDrawer}
      onClose={() => {
        dispatch(setIsOpenHistoryDrawer(false))
        dispatch(clearState())
      }}
    >
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ประวัติการจองทั้งหมด</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            component="span"
            onClick={() => {
              dispatch(setIsOpenHistoryDrawer(false))
              dispatch(clearState())
            }}
          >
            <Close color="action.active" />
          </IconButton>
        </Box>
        <Divider sx={{ mx: 3, borderColor: theme.palette?.blue?.blueLine }} />
      </StyledHeadLabel>
      <StyledContent
        sx={{ overflowY: 'auto', height: '100%' }}
        onScroll={(e) => {
          if (!hasMore || isFetch) return
          if (
            e.target.offsetHeight + e.target.scrollTop + 10 >
            e.target.scrollHeight
          ) {
            dispatch(loadNextPage())
          }
        }}
      >
        <StyledRow
          sx={{ gap: 2, background: '#EDF4FF', p: 2, borderRadius: 2 }}
        >
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            required
            placeholder="กรุณาเลือก"
            hideTextError={true}
            onChange={(value) => {
              handleChangeFilter('startDate', dayjs(value).format('YYYY-MM-DD'))
            }}
            value={isValidateDate(filter.startDate) ? filter.startDate : ''}
            disabledStartDate={'1900-01-01'}
            disabledEndDate={dayjs(new Date()).format(
              window.__env__.REACT_APP_DATE_DB,
            )}
            hideDisableDateRange={true}
          />
          <DatePicker
            id="endDate"
            name="endDate"
            labelText="จนถึงวันที่"
            required
            placeholder="กรุณาเลือก"
            hideTextError={true}
            onChange={(value) => {
              handleChangeFilter('endDate', dayjs(value).format('YYYY-MM-DD'))
            }}
            value={isValidateDate(filter.endDate) ? filter.endDate : ''}
            disabledStartDate={'1900-01-01'}
            disabledEndDate={dayjs(new Date()).format(
              window.__env__.REACT_APP_DATE_DB,
            )}
            hideDisableDateRange={true}
          />
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => {
              dispatch(startFetch())
            }}
            disabled={isLoading || !(filter.startDate && filter.endDate)}
          >
            ค้นหา
          </Button>
        </StyledRow>
        {!isLoading && (
          <>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                padding: 0,
              }}
            >
              {history.map((ele, idx) => {
                return (
                  <TimelineItem key={idx}>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          backgroundColor: theme.palette?.primary?.main,
                          width: 24,
                          height: 24,
                          position: 'relative',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: theme.palette?.common?.white,
                            width: 12,
                            height: 12,
                            position: 'absolute',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 'auto',
                          }}
                        />
                      </TimelineDot>
                      <TimelineConnector
                        sx={{ backgroundColor: theme.palette?.text?.mercury }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ display: 'flex', flexDirection: 'column', pr: 0 }}
                    >
                      <Typography variant="body3" color="text.secondary">
                        ทำรายการเมื่อ
                      </Typography>
                      <Typography variant="body1b" sx={{ mb: 0.5 }}>
                        {convertFormatDateTime({
                          value: ele.createdAt,
                          type: 'dateTime',
                        })}
                      </Typography>
                      <StyledColumn
                        sx={{
                          border: `1px solid ${theme.palette?.blue?.blueLine}`,
                          p: 2,
                          borderRadius: 2,
                          gap: 1,
                        }}
                      >
                        <TimelineContentHeader
                          bookingTopic={ele.bookingTopic}
                          requestTypeData={ele.requestType}
                        />
                        <Divider
                          sx={{ borderColor: theme.palette?.blue?.blueLine }}
                        />
                        <BookingDetail booking={ele} />
                      </StyledColumn>
                    </TimelineContent>
                  </TimelineItem>
                )
              })}
            </Timeline>
            {hasMore && (
              <Button sx={{ alignItems: 'center' }}>
                LOAD MORE
                {isFetch && filter.page > 1 && (
                  <CircularProgress size={20} sx={{ margin: '8px' }} />
                )}
              </Button>
            )}
          </>
        )}
        <LoadingPage isLoading={isLoading} />
      </StyledContent>
    </StyledDrawer>
  )
}

export default HistoryDrawer
