import { getAllLayout, handleRoomLayoutOptions } from '../event'
import {
  bookingItemSelector,
  roomSelector,
  setRoom,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from '../../../../../../components/Input/Select'
import _ from 'lodash'

export const RoomLayout = ({ bookingDate, roomIndex }) => {
  const dispatch = useDispatch()
  const meetingRoom = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoom',
    }),
    shallowEqual,
  )

  const roomLayout = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'roomLayout',
    }),
    shallowEqual,
  )

  const meetingRoomUuid = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoomUuid',
    }),
    shallowEqual,
  )

  const isLoading = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'isLoading',
    }),
    shallowEqual,
  )

  const masterRooms = useSelector(
    bookingItemSelector({
      bookingDate,
      key: 'masterRooms',
    }),
    shallowEqual,
  )

  return (
    <Select
      dataTestid="select-roomLayout"
      id="roomLayout"
      name="roomLayout"
      labelText={'รูปแบบการจัดห้อง'}
      placeholder="กรุณาเลือก"
      disabled={isLoading || !masterRooms.length || _.isEmpty(meetingRoomUuid)}
      options={
        !_.isEmpty(meetingRoom)
          ? handleRoomLayoutOptions(meetingRoom)
          : getAllLayout()
      }
      value={roomLayout}
      handleChange={(e) => {
        const value = _.get(e, 'target.value', '')
        dispatch(
          setRoom({
            key: 'roomLayout',
            value: value,
            bookingDate,
            roomIndex,
          }),
        )
      }}
      showTextError={false}
      textError={''}
    />
  )
}
