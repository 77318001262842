export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]


export const arrayCheckboxAssoc = [
  {
    label: 'ภูมิภาค',
    nameCheckBox: 'regionChecked',
    nameInput: 'region',
    placeholder: 'ค้นหาภูมิภาค',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'จังหวัด',
    nameCheckBox: 'provinceNameChecked',
    nameInput: 'provinceName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'รหัสสถานที่สอบ',
    nameCheckBox: 'locationCodeChecked',
    nameInput: 'locationCode',
    placeholder: 'ค้นหา',
    type: 'number',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const defaultFilterAssoc = {
  regionChecked: false,
  provinceNameChecked: false,
  locationCodeChecked: false,
  createdByChecked: false,
  updatedByChecked: false,
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  stationNameChecked: false,
}

export const arrayCheckboxCo = [
  {
    label: 'Station',
    nameCheckBox: 'stationNameChecked',
    nameInput: 'stationName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'รหัสสถานที่สอบ',
    nameCheckBox: 'locationCodeChecked',
    nameInput: 'locationCode',
    placeholder: 'ค้นหา',
    type: 'number',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

