import _ from 'lodash'
import { blankSubAnswerObj } from '../../../modules/ETesting/Form/TestingForm/FillInBlank/events'
import { defaultValue, questionTypeString } from '../../../constants/eTesting'

export const addKeyArray = (list) => {
  let result = []
  if (list.length) {
    result = list.map((item, index) => {
      return {
        ...item,
        key: index,
        answers: _.get(item, 'answers', []).map((itemAnswers, index) => {
          return { ...itemAnswers, id: `id-${index}` }
        }),
      }
    })
  }
  return result
}

export const resetValueAnswer = ({ itemQuestion, questionType, value }) => {
  if (value === 'FREE_TEXT') return []
  const result = deleteValueAnswer({
    oldType: questionType,
    newType: value,
    answers: itemQuestion.answers,
  })
  return result
}

export const deleteValueAnswer = ({ oldType, newType, answers }) => {
  const type = defaultValue.questionType
  const isOldTypeFill = oldType?.includes(questionTypeString.TYPE_FILL)
  const isNewTypeFill = newType?.includes(questionTypeString.TYPE_FILL)
  let result = []

  if (oldType === type.freeText || isOldTypeFill) {
    if (isNewTypeFill) {
      result = answers.map((item) => {
        item = {
          ...item,
          questionType: newType,
          score: 0,
          isCorrect: false,
        }

        if (oldType === type.fillInBlankChoice) {
          item.title = ''
          item.subAnswers = []
        }

        if (newType === type.fillInBlankChoice) {
          item.subAnswers = [{ ...blankSubAnswerObj }]
        }

        return item
      })

      if (oldType === type.fillInBlankDrag) {
        result = result.filter((item) => !_.isEmpty(item.target))
      }
    } else {
      const obj = {
        title: '',
        sequence: 0,
        mediaUrl: '',
        isCorrect: false,
        score: 0,
      }
      result = [obj]
    }
    return result
  }

  if (answers.length > 0) {
    if (isNewTypeFill) return []

    result = answers.map((item) => {
      item = {
        ...item,
        questionType: newType,
        score: 0,
        isCorrect: newType === type.matching || newType === type.sequence,
      }
      if (newType === type.matching) item.question = ''
      return item
    })
    return result
  }
}

export const validateVideo = (url) => {
  if (!url) return 'กรุณกรอก url'
  let text =
    'Link ไม่ตรงกับ Format Link วิดีโอต้องเป็น Youtube หรือ Vimeo เท่านั้น'
  if (url.length < 8) return text
  const value = url.slice(8).split('/')[0]
  if (
    value === 'vimeo.com' ||
    value === 'www.youtube.com' ||
    value === 'youtu.be' ||
    value === 'player.vimeo.com'
  )
    return ''
  return text
}
