import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '117px',
  justifyContent: 'flex-end',
  borderLeft: `1px solid ${theme?.palette?.text?.silver}`,
  height: '46px',
  alignItems: 'center',
  marginTop: '-5px',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
  },
}))

export const BoxScore = styled(Box)(({ theme, isMultiple }) => ({
  display: 'flex',
  justifyContent: !isMultiple ? 'flex-end' : 'space-between',
  margin: theme.spacing(3),
  marginBottom: theme.spacing(0.5),
}))

export const BoxInput = styled(Box)(() => ({
  display: 'flex',
  width: '120px',
}))

export const BoxScoreInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  '& > .MuiTypography-root': { marginTop: theme.spacing(1) },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 0,
    '& > .MuiTypography-root': { marginTop: 0, marginBottom: theme.spacing(1) },
  },
}))
