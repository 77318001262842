import axios from 'axios'
import _ from 'lodash'
import { agentProfileUpdate } from '../../../../../../src/utils/apiPath'
import {
  openDialog,
  closeDialog,
} from '../../../../../../src/redux/slices/dialog'
import {
  startLoading,
  stopLoading,
} from '../../../../../../src/redux/slices/manage/prospectProfile'

export function handleScroll(setHeaderStyle) {
  return function () {
    if (window.scrollY > 30) {
      setHeaderStyle(true)
    } else {
      setHeaderStyle(false)
      closeDialog()
    }
  }
}

export const filterEmptyObjects = (arr) =>
  arr.filter((obj) => Object.values(obj).some((val) => val !== ''))

export const handleSubmit = (data, uuid) => (dispatch) => {
  const division = _.get(data, 'division', '')

  const body = {
    agentUuid: uuid,
    division: division,
  }

  dispatch(
    openDialog({
      open: false,
      variant: 'save',
      title: 'ยืนยันบันทึกข้อมูล',
      icon: '',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => {
        dispatch(handleSendData(body, uuid))
        dispatch(closeDialog())
      },
    })
  )
}

export const handleSendData = (body, uuid) => async (dispatch) => {
  dispatch(startLoading())
  await axios
    .put(agentProfileUpdate, body)
    .then(() => {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
            handleView(uuid)
          },
        })
      )
    })
    .catch(() => {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
          handleConfirm: () => {
            dispatch(closeDialog())
            handleView(uuid)
          },
        })
      )
    })
  dispatch(stopLoading())
}

export const handleView = (uuid) => {
  window.location.href = `/manage/cs-agent-profile/view/${uuid}`
}
