import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: '154px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}))

export const StyledResultContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2.5),
}))
