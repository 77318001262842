import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxBorder = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

export const BoxHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const BoxGroups = styled(Box)(({ theme }) => ({
  background: theme.palette?.background?.default,
  border: `1px solid ${theme.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

export const CardContent = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1),
  marginLeft: theme.spacing(0),
  marginRight: theme.spacing(0),
  boxShadow: 'unset',
}))

export const BoxButton = styled(Box)(({ theme, isError }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: !isError ? 0 : theme.spacing(-3),
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
}))

export const BoxBtnAnd = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
}))
