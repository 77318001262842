import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassDetail'
import { getLearnerHistory } from '../../../../../services/manageClass/learnerDetail'
import { headCellsEvent } from '../../../../../constants/manageClass'
import { setInitialTable } from '../../../../../redux/slices/table/basicTable'
import { StyledTable } from './Styled'

export const fetchManageClassEvent = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`

  dispatch(startLoading())
  const result = await dispatch(getLearnerHistory(url))
  const payload = _.get(result, 'payload', undefined)
  if (payload) {
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows: _.get(payload, 'data.result', []).map((item) => ({
          ...item,
          remark: _.isNil(item.remark) ? '-' : item.remark,
        })),
        allCount: _.get(payload, 'data.totalCount', 0),
        headCells: headCellsEvent,
        customStyle: StyledTable,
      })
    )
  }
  dispatch(stopLoading())
}
