import _ from 'lodash'
import { store } from '../../../../../../App'
import {
  replaceFieldError,
  setFieldValue,
} from '../../../../../../redux/slices/crud'

export const handleDragEnd =
  (result, keyField, setErrors, contents) => (dispatch) => {
    if (!result.destination) return

    let errorArr = store.getState().crud.formErrors
    errorArr = _.filter(errorArr, (obj) => {
      return !obj.path.includes(`content`)
    })
    dispatch(replaceFieldError(errorArr))

    const list = contents
    const reorderedList = reorder(
      [...list],
      result.source.index,
      result.destination.index,
    )

    dispatch(setFieldValue({ key: keyField, value: reorderedList }))
    setErrors({})
  }

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
