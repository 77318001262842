import _ from 'lodash'
import { postModuleConvertAgain } from '../service/postModuleConvertAgain'
import { dispatchUploadValue } from './dispatchUploadValue'

export const handleConvertAgain = async (
  file,
  index,
  fieldName,
  uploadType,
) => {
  const uuid = _.get(file, 'uuid', '')
  const targetFileField = `formData[${fieldName}]files[${index}]`
  const body = { uuid, uploadType }
  dispatchUploadValue(`${targetFileField}.status`, 'INPROGRESS')
  await postModuleConvertAgain({ body, targetFileField })
}
