import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const apiToRcms = createSlice({
  name: 'apiToRcms',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChangeStatus: (state, { payload }) => {
      state[payload.name] = payload.value
    },
    setStatusClear: (state) => {
      state.YES = false
      state.NO = false
      state.WAIT_STATUS = false
      state.SUCCESS = false
      state.FAIL = false
      state.NOT_SEND = false
      state.WAIT_SEND = false
    },
    setFetchRcmsData: (state, { payload }) => {
      state.license_status = payload.license_status
      state.rcms_status = payload.rcms_status
      state.license_detail = payload.license_detail
      state.agent_detail = payload.agent_detail
      state.englishName = payload.englishName
      state.finishDate = payload.finishDate
    },
    setChangeStatusLicense: (state, { payload }) => {
      state.statusList = payload
    },
    setFetchAgentDetail: (state, { payload }) => {
      state.agent_detail = payload.agent_detail
      state.rcms_detail = payload.rcms_detail
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setFetchRcmsData,
  setChangeStatusLicense,
  setChangeStatus,
  setStatusClear,
  setFetchAgentDetail,
} = apiToRcms.actions

export default apiToRcms.reducer
