import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import LicenseDrawer from './LicenseDrawer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import { agentProfileLicense } from '../../../../../constants/manage/agentProfile'
import _ from 'lodash'
import { onSort } from '../../../../../utils/lib'
import { fetchLicense } from './events'
import { useParams } from 'react-router-dom'

const LicenseSection = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [headLicense, setHeadLicense] = useState([])

  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual
  )

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(fetchLicense({ agentUuid: uuid, order: orderBy, sort }))
    setHeadLicense(head)
  }

  useEffect(() => {
    for (const agentLicense of agentProfileLicense) {
      delete agentLicense['order']
    }
    setHeadLicense(agentProfileLicense)
  }, [])

  return (
    <>
      <Card sx={{ pb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
          <Typography variant="h6">ข้อมูลใบอนุญาต</Typography>

          <Button
            data-testid="btn-open-drawer"
            variant="outlined"
            size="m"
            onClick={() => setIsOpen(true)}
          >
            <HistoryTwoTone color="primary.main" />
          </Button>
        </Box>
        <NormalTable
          headCells={headLicense}
          listRows={_.get(data, 'license', [])}
          totalRows={0}
          showPagination={false}
          onRequestSort={onRequestSort}
        />
      </Card>
      <LicenseDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </>
  )
}

export default LicenseSection
