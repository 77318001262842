import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { MONTH_LIST } from './Text'
import Select from '../../components/Input/Select'
import FilterDrawer from './Drawer/FilterDrawer/index'
import { fetchCourseSettingList, fetchStaffList } from './api/Index'
import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../utils/lib'
import Fullscreen from '@mui/icons-material/Fullscreen'
import FullscreenExit from '@mui/icons-material/FullscreenExit'
import { setFieldValue } from '../../redux/slices/crud'
import { useDispatch } from 'react-redux'

export default function HeaderCalendar({
  calendarComponentRef,
  setState,
  state,
  setDialog,
  setNewEvent,
  options,
  setCalendarType,
  calendarType,
  setConfirmDialog,
  headerVisible,
  setHeaderVisible,
  setHeight,
  handleFetchEvents,
  setHistoryDrawer,
  allowEditPlan,
}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [courseSettingList, setCourseSettingList] = useState({
    isLoading: false,
    data: [],
  })
  const [staffList, setStaffList] = useState({
    isLoading: false,
    data: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const masterPlanStatus = _.get(state, 'masterPlan.status', '')
  const [user] = useLocalStorage('user')
  const permission = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_MP', 'APPROVE_ZONE', 'ASSISTS_ZONE'],
  })

  let correctYear = _.get(state, 'data.year', '')
  let correctMonth =
    _.get(state, 'data.month', null) !== null
      ? convertDate({ date: state.data.month + 1 })
      : ''

  let area = _.get(state, 'area.value', '')

  const [isYear, setIsYear] = useState(correctYear)
  const [isMonth, setIsMonth] = useState(correctMonth)
  useEffect(() => {
    if (area) {
      setUpCourseSettingList()
      setupStaffList()
    }

    if (!permission) {
      setCalendarType('ALL')
    }
    setIsLoading(false)
  }, [isLoading, isYear, isMonth, area, isFilterDrawer])

  const setUpCourseSettingList = async () => {
    setCourseSettingList({
      isLoading: true,
      data: [],
    })
    const result = await fetchCourseSettingList({
      isYear,
      isMonth,
      area,
    })
    setCourseSettingList({
      isLoading: false,
      data: result,
    })
  }

  const setupStaffList = async () => {
    if (_.isEmpty(area)) {
      return false
    }
    setStaffList({
      isLoading: true,
      data: [],
    })
    const result = await fetchStaffList({
      isYear,
      isMonth,
      area,
      type: calendarType,
    })
    setStaffList({
      isLoading: false,
      data: result,
    })
  }

  return (
    <Box
      sx={{
        py: 2,
        backgroundColor: 'background.paper',
        justifyContent: 'space-between',
        display: 'flex',
        height: '80px',
        zIndex: 11,
        position: 'sticky',
        top: '0',
        left: '0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '500px',
          mt: 1,
          mx: 2,
        }}
      >
        <IconButton
          data-testid="icon-prev"
          sx={{ height: 36, width: 36 }}
          onClick={() =>
            handleDateCalendar({
              calendarComponentRef,
              type: 'prev',
              setState,
              state,
              setIsYear,
              setIsMonth,
              setHistoryDrawer,
            })
          }
        >
          <ArrowBackIosIcon
            fontSize="small"
            color="primary"
            sx={{ cursor: 'pointer' }}
          />
        </IconButton>
        <IconButton
          data-testid="icon-next"
          sx={{ height: 36, width: 36 }}
          onClick={() =>
            handleDateCalendar({
              calendarComponentRef,
              type: 'next',
              setState,
              state,
              setIsYear,
              setIsMonth,
              setHistoryDrawer,
            })
          }
        >
          <ArrowForwardIosIcon
            fontSize="small"
            color="primary"
            sx={{ cursor: 'pointer' }}
          />
        </IconButton>

        <Box sx={{ width: 105, ml: 2, mr: 1 }}>
          <Select
            dataTestid="select-month"
            id="month"
            name="month"
            type="basic"
            showTextError={false}
            options={MONTH_LIST}
            placeholder="เดือน"
            handleChange={(e) =>
              handleDateCalendar({
                calendarComponentRef,
                type: 'month',
                setState,
                state,
                value: e.target.value,
                setIsYear,
                setIsMonth,
                setHistoryDrawer,
              })
            }
            value={state.data.month}
          />
        </Box>
        <Box sx={{ width: 94, ml: 1, mr: 2 }}>
          <Select
            dataTestid="select-year"
            id="year"
            name="year"
            type="basic"
            showTextError={false}
            options={optionsYear({ start: 2000, end: 2100 })}
            placeholder="ปี"
            handleChange={(e) =>
              handleDateCalendar({
                calendarComponentRef,
                type: 'year',
                setState,
                state,
                value: e.target.value,
                setIsYear,
                setIsMonth,
                setHistoryDrawer,
              })
            }
            value={isYear}
          />
        </Box>
        <IconButton onClick={handleFetchEvents}>
          <RefreshIcon color="primary" />
        </IconButton>
        {user.area.length > 1 && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Switch
              checked={state.isAllStation}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  isAllStation: !prev.isAllStation,
                }))
              }
            />
            <Typography sx={{ py: 0.5 }}> ALL</Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: 2,
          [theme.breakpoints.up('md')]: {},
          right: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexDirection: 'row',
            mr: 2,
          }}
        >
          {/* filter workload */}
          {/* {calendarType === 'ALL' && ( */}
          {!state.isAllStation && (
            <Button
              sx={{ px: 3 }}
              variant="outlined"
              startIcon={<FilterListOutlinedIcon />}
              onClick={() => setIsFilterDrawer(true)}
            >
              ตัวกรอง
            </Button>
          )}
          {/* )} */}

          <IconButton
            className="example-css-selector "
            onClick={() => {
              setHeaderVisible(!headerVisible)
              setHeight(0)
            }}
            aria-label="delete"
          >
            {headerVisible ? <Fullscreen /> : <FullscreenExit />}
          </IconButton>
          <>
            {validatePermission({
              user: user,
              moduleType: 'MONTHLY_PLAN',
              permission: [
                'CREATE_ALL',
                'CREATE_ALL_EXC_HOLIDAY',
                'CREATE_LEAVE',
                'APPROVE_ZONE',
                'ASSISTS_ZONE',
              ],
              data: state,
            }) &&
              allowEditPlan &&
              !state.isAllStation && (
                <Button
                  sx={{ px: 3, ml: 2 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if (masterPlanStatus === 'WAITING') {
                      setConfirmDialog({
                        open: true,
                        variant: 'fail',
                        title: 'ยังไม่สามารถแก้ไขได้',
                        content:
                          'เนื่องจากอยู่ระหว่างการส่งตรวจสอบแผนรายเดือนนี้ หากต้องการแก้ไข \n กรุณายกเลิกการส่งแผนรายเดือนนี้ก่อน \nหรือรอจนกว่าจะตรวจสอบเรียบร้อย',
                        onConfirmClick: () => {
                          setConfirmDialog((prev) => ({
                            ...prev,
                            open: false,
                          }))
                        },
                      })
                      return
                    }

                    setNewEvent((prevState) => ({
                      ...prevState,
                      title: 'New Event Button',
                      start: null,
                      end: null,
                      allDay: true,
                      uuidStaff: undefined,
                    }))
                    setDialog((prevState) => ({
                      ...prevState,
                      open: true,
                    }))
                  }}
                >
                  เพิ่มตารางงาน
                </Button>
              )}
            <Box sx={{ ml: 2 }}>
              {permission && (
                <Button
                  sx={{
                    height: 40,
                    p: 0,
                    borderRadius: '4px 0px 0px 4px',
                  }}
                  variant={
                    calendarType === 'PERSONAL' ? 'contained' : 'outlined'
                  }
                  startIcon={<CalendarViewWeekIcon sx={{ p: 0, ml: 1 }} />}
                  onClick={() => {
                    setCalendarType('PERSONAL')
                    dispatch(
                      setFieldValue({
                        key: 'calendarType',
                        value: 'PERSONAL',
                      }),
                    )
                  }}
                />
              )}

              <Button
                sx={{
                  height: 40,
                  p: 0,
                  borderRadius: permission ? '0px 4px 4px 0px' : '4px',
                }}
                variant={calendarType === 'ALL' ? 'contained' : 'outlined'}
                startIcon={<CalendarViewMonthIcon sx={{ ml: 1 }} />}
                onClick={() => {
                  setCalendarType('ALL')
                  setNewEvent((prevState) => ({
                    ...prevState,
                    uuidStaff: undefined,
                  }))
                  dispatch(
                    setFieldValue({
                      key: 'calendarType',
                      value: 'ALL',
                    }),
                  )
                }}
              />
            </Box>
          </>
        </Box>
      </Box>

      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
        state={state}
        setState={setState}
        courseSettingList={courseSettingList.data}
        staffList={staffList.data}
        options={options}
        calendarType={calendarType}
        isLoading={staffList.isLoading || courseSettingList.isLoading}
      />
    </Box>
  )
}

export const optionsYear = ({ start, end }) => {
  let list = []
  for (let i = start; i <= end; i++) {
    list = [...list, { label: `${i}`, value: i }]
  }
  return list
}
export const convertDate = ({ date }) =>
  `${date}`.length === 1 ? `0${date}` : date

export const onFilterClick = (filter, setState, calendarType) => {
  let listWorkSchedule = []
  let listTeamCalendar = []
  let listCourse = []

  for (let key in filter) {
    const value = filter[key]

    if (key === 'workSchedule') {
      for (let objWorkSchedule in value) {
        const workSchedule = value[objWorkSchedule]
        if (workSchedule) listWorkSchedule.push(objWorkSchedule)
      }
    }
    if (key === 'teamCalendar') {
      for (let objTeamCalendar in value) {
        const teamCalendar = value[objTeamCalendar]
        if (teamCalendar) listTeamCalendar.push(objTeamCalendar)
      }
    }
    if (key === 'course') {
      for (let name in value) {
        const course = value[name]
        if (course.status) listCourse.push(course.code)
      }
    }
  }
  //workload filter
  const filterName = calendarType === 'PERSONAL' ? 'filterWorkload' : 'filter'
  setState((prevState) => ({
    ...prevState,
    [filterName]: {
      courses: listCourse,
      staffs: listTeamCalendar,
      workTypes: listWorkSchedule,
    },
    isFirstInitial: false,
    handleType: 'filter',
  }))
}

export const handleDateCalendar = ({
  calendarComponentRef,
  type,
  setState,
  state,
  value = 0,
  setIsYear,
  setIsMonth,
}) => {
  let {
    data: { month, year },
  } = state

  if (type === 'prev') {
    const dataPrev = dayjs(
      `${year}-${convertDate({ date: month + 1 })}-01`,
    ).add(-1, 'month')

    month = dayjs(dataPrev).month()
    year = dayjs(dataPrev).year()
  } else if (type === 'next') {
    const dataNext = dayjs(
      `${year}-${convertDate({ date: month + 1 })}-01`,
    ).add(1, 'month')

    month = dayjs(dataNext).month()
    year = dayjs(dataNext).year()
  } else if (type === 'month') {
    month = value
  } else if (type === 'year') {
    year = value
  }

  setIsYear(year)
  setIsMonth(convertDate({ date: month + 1 }))
  const calendar = `${year}-${convertDate({ date: month + 1 })}-01`

  if (calendarComponentRef.current) {
    const calendarApi = calendarComponentRef.current.getApi()
    calendarApi.gotoDate(calendar)
  }

  setState((prevState) => ({
    ...prevState,
    data: {
      ...prevState.data,
      month: month,
      year: year,
    },
  }))
}
