import { Box } from '@mui/material'
import React from 'react'
import Overview from '../components/Overview'
import StatusOverview from '../components/StatusOverview'

const index = () => {
  return (
    <Box>
      <Box>
        <Overview />
      </Box>
      <Box mt={3}>
        <StatusOverview />
      </Box>
    </Box>
  )
}

export default index
