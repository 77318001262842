import React from 'react'
import {
  StyledColumnTextBox,
  StyledDeleteImage,
  StyledTooltip,
} from '../styled'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'
import { HandleClearImage } from '../handler/handleClearImage'

export const CoverImage = ({ onChange, disabled }) => {
  const { coverImage, fileName, isUploading } = useSelector(
    (state) => ({
      coverImage: state.uploadCropper.coverImage,
      fileName: state.uploadCropper.fileName,
      isUploading: state.uploadCropper.isUploading,
    }),
    shallowEqual,
  )
  if (!coverImage || isUploading) return null
  return (
    <>
      <img
        src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
        width={190}
        height={120}
        style={{
          objectFit: 'cover',
          borderRadius: 4,
          border: '1px solid #ccc',
        }}
        alt=""
      />

      <StyledDeleteImage
        onClick={(e) => (disabled ? {} : HandleClearImage(e, onChange))}
        disabled={disabled}
      >
        <Close />
      </StyledDeleteImage>

      <StyledColumnTextBox>
        <StyledTooltip title={fileName}>
          <Typography id="fileName" variant="h6">
            {fileName}
          </Typography>
        </StyledTooltip>
        <Typography variant="body2" color="text.secondary">
          ขนาดแนะนำ 900x600 px
        </Typography>
      </StyledColumnTextBox>
    </>
  )
}
