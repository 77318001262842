import React from 'react'
import { DateRangePicker } from 'react-date-range'
import useTheme from '@mui/system/useTheme'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './date-range-style.css'

const DateRange = (props) => {
  const { selectedDates, disableDates, maxDate, minDate, onChange } = props
  const theme = useTheme()
  return (
    <DateRangePicker
      onChange={onChange}
      showSelectionPreview={true}
      months={1}
      ranges={selectedDates}
      direction="horizontal"
      className="dateRange"
      maxDate={maxDate}
      minDate={minDate}
      rangeColors={[theme?.palette?.primary?.main]}
      disabledDates={disableDates}
    />
  )
}

export default DateRange
