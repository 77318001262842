import React from 'react'
import Typography from '@mui/material/Typography'
import { Row } from '../../Styled'
import { Container } from './Styled'
import { KeyboardArrowRightRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const InfoCard = ({
  label,
  number,
  unit,
  background,
  backgroundButton,
  isOnClick,
  onClick,
  isLockWidth,
}) => {
  return (
    <Container
      background={background}
      minWidth={200}
      width={isLockWidth ? 'fit-content' : 200}
    >
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Row sx={{ gap: 1 }}>
        <Typography variant="h4">{number}</Typography>
        <Typography
          variant="body2"
          color="text.lightGray"
          sx={{ paddingTop: '8px' }}
        >
          {unit}
        </Typography>
        {isOnClick && (
          <IconButton
            background={backgroundButton}
            onClick={() => onClick()}
            sx={{
              padding: '4px',
              backgroundColor: '#4976BA14',
              marginLeft: 'auto',
            }}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        )}
      </Row>
    </Container>
  )
}

export default InfoCard
