import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import _ from 'lodash'
import { resetTable, setChangePage, setChangeRowsPerPage } from '../../table'
import {
  setStatusLearnerDrawer,
  setReduxValue,
  setIsOpenLearnerDrawer,
  setFilter,
} from '.'
import {
  fetchLearnerList,
  resetLearnerDrawer,
  summaryFilterTotal,
} from './events'

export const listenerMiddlewareELearningDashboardByCourse =
  createListenerMiddleware()

listenerMiddlewareELearningDashboardByCourse.startListening({
  matcher: isAnyOf(
    setReduxValue,
    setStatusLearnerDrawer,
    setChangeRowsPerPage,
    setChangePage,
  ),
  effect: async (action) => {
    const {
      payload: { key },
      type,
    } = action
    if (
      _.includes(['eLearningDashboardByCourse/setReduxValue'], type) &&
      !_.includes(
        [
          'eLearningDashboardByCourse/setStatusLearnerDrawer',
          'table/setChangeRowsPerPage',
          'table/setChangePage',
        ],
        type,
      ) &&
      !_.includes(['learnerTypeSelected'], key)
    )
      return

    fetchLearnerList()
  },
})

listenerMiddlewareELearningDashboardByCourse.startListening({
  actionCreator: setIsOpenLearnerDrawer,
  effect: async (action, listenerApi) => {
    const { payload } = action
    if (payload) return

    listenerApi.dispatch(resetTable())
    resetLearnerDrawer()
  },
})

listenerMiddlewareELearningDashboardByCourse.startListening({
  actionCreator: setFilter,
  effect: async () => {
    summaryFilterTotal()
  },
})
