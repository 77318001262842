import React from 'react'
import Typography from '@mui/material/Typography'

const SubInfoInput = ({ label }) => {
  if (!label) return <></>
  return (
    <Typography
      sx={{
        height: 25,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {label}
    </Typography>
  )
}

export default SubInfoInput
