import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  meetingRoom: {
    total: 0,
    single: 0,
    connecting: 0,
    other: 0,
  },
  reportByType: {
    total: 0,
    eBookingCount: 0,
    eBookingAttendee: 0,
    manageClassCount: 0,
    manageClassAttendee: 0,
    monthlyPlanCount: 0,
    monthlyPlanAttendee: 0,
  },
  roomStat: {
    dataSets: {
      MONTHLY_PLAN: new Array(7).fill(0),
      MANAGE_CLASS: new Array(7).fill(0),
      E_BOOKING: new Array(7).fill(0),
    },
    label: {
      MONTHLY_PLAN: new Array(7).fill('0% 0'),
      MANAGE_CLASS: new Array(7).fill('0% 0'),
      E_BOOKING: new Array(7).fill('0% 0'),
    },
  },
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  station: [],
  stationName: [],
  filterTotal: 2,
}

export const ROW_PAGE_PER_LIST = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: 'All', value: -1 },
]

export const attendeeTableOrderOptions = [
  { label: 'จำนวนชั่วโมงที่ใช้ห้อง : มาก-น้อย', value: 'desc' },
  { label: 'จำนวนชั่วโมงที่ใช้ห้อง : น้อย-มาก', value: 'asc' },
]

export const tabList = {
  MEETING_ROOM: 'meetingRoom',
  STATISTIC: 'statistic',
}

export const timeTableOrderOptions = [
  { label: 'รวมจำนวนชั่วโมงที่ใช้ห้อง : มาก-น้อย', value: 'desc' },
  { label: 'รวมจำนวนชั่วโมงที่ใช้ห้อง : น้อย-มาก', value: 'asc' },
]
