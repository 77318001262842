import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Formik, Form } from 'formik'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import {
  StyledContainer,
  StyledContent,
  StyledCard,
  LoadingPage,
} from '../../Styled'
import {
  breadcrumbBackgroundEdit,
  breadcrumbBackgroundForm,
  breadcrumbBackgroundView,
} from '../../model'
import Header from './Header'
import UploadBackground from './UploadBackground/uploadBackground'
import { initialState } from '../../../../redux/slices/eCertificateForm/model'
import { validationSchema } from './FormSchema'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as event from './event'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import FooterEdit from './Footer'
import { HeaderLayout } from './UploadBackground/Styled'

const Index = ({ isView = false }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.eCertificateForm.isLoading,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (!_.isEmpty(uuid)) {
      dispatch(event.fetchDataFormUuid(uuid))
    } else {
      dispatch(event.fetchDataForm())
    }
  }, [])

  const breadcrumb = () => {
    if (isView) {
      return breadcrumbBackgroundView
    } else if (!_.isEmpty(uuid)) {
      return breadcrumbBackgroundEdit
    } else {
      return breadcrumbBackgroundForm
    }
  }

  const breadcrumbText = () => {
    if (isView) {
      return breadcrumbBackgroundView[2].title
    } else if (!_.isEmpty(uuid)) {
      return breadcrumbBackgroundEdit[2].title
    } else {
      return breadcrumbBackgroundForm[2].title
    }
  }

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(value, { setErrors }) => {
            const propSubmit = {
              stateCertificate: value,
              history,
              setErrors,
            }
            dispatch(event.onSubmitFormECertificate(propSubmit))
          }}
        >
          <Form>
            <HeaderLayout container>
              <Grid item xs={12} md={10}>
                <Typography variant="h4">{breadcrumbText()}</Typography>
                <Breadcrumbs menuList={breadcrumb()} />
              </Grid>

              {!isView && (
                <Header item xs={12} md={1} isEditForm={!_.isEmpty(uuid)} />
              )}
            </HeaderLayout>

            <StyledCard>
              <UploadBackground isView={isView} />
            </StyledCard>
          </Form>
        </Formik>
      </StyledContent>
      {!_.isEmpty(uuid) && <FooterEdit uuid={uuid} />}
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Index
