import TextInput from '../../../../../../components/Input/TextInput'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'
import { handleInputRoomChange } from '../events'
import _ from 'lodash'

export const MeetingRoomOther = ({
  meetingRoomOther,
  roomDetailIndex,
  errors,
  bookingDate,
  validateIndex,
}) => {
  const dispatch = useDispatch()

  return (
    <Box sx={{ pl: 10 }}>
      <TextInput
        type="basic"
        id="meetingRoomOther"
        name="meetingRoomOther"
        required
        labelText={'กรอกชื่อสถานที่'}
        placeholder="กรุณากรอกชื่อสถานที่"
        value={meetingRoomOther}
        onChange={(e) => {
          dispatch(
            handleInputRoomChange(
              e,
              'meetingRoomOther',
              roomDetailIndex,
              bookingDate,
            ),
          )
        }}
        isShowTextError={
          !_.isEmpty(
            errors,
            `roomManagement.roomManagementMeetingRoom[${validateIndex}].meetingRoomOther`,
          )
        }
        textError={_.get(
          errors,
          `roomManagement.roomManagementMeetingRoom[${validateIndex}].meetingRoomOther`,
          '',
        )}
      />
    </Box>
  )
}
