import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledGroupBox } from '../../../../components/FilterDrawer/Styled'
import DateRangeInput from '../../../../components/DateRangeInput'
import { handleChange, handleSelectDate } from './events'
import _ from 'lodash'
import ListSubCheckbox from './ListSubCheckbox'
import SelectGroupChip from '../../../../components/SelectGroupChip'

export const ListCheckbox = () => {
  const { displayFilters, filtersList } = useSelector(
    (state) => ({
      displayFilters: state.monthlyPlan.dashboard.displayFilters,
      filtersList: state.monthlyPlan.dashboard.displayFilters.filtersList,
    }),
    shallowEqual,
  )

  return _.map(filtersList, (item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={displayFilters[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    isCheck: true,
                  })
                }}
              />
            }
            label={item.label}
            sx={!displayFilters[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {displayFilters[item.nameCheckBox] && (
            <>
              {item.type === 'dateRange' && (
                <DateRangeInput
                  placeholder={item.placeholder}
                  dateState={{
                    startDate: displayFilters.startDate || new Date(),
                    endDate: displayFilters.endDate || new Date(),
                    display: displayFilters.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    handleSelectDate(selectedDates)
                  }}
                />
              )}
              {item.type === 'select' && (
                <SelectGroupChip
                  boxSx={{ maxWidth: 269 }}
                  id={item.nameInput}
                  name={item.nameInput}
                  placeholder={item.placeholder}
                  options={_.get(
                    displayFilters,
                    `${item.nameInput}Options`,
                    [],
                  )}
                  value={displayFilters[item.nameInput]}
                  onChange={(list) =>
                    handleChange({ key: item.nameInput, value: list })
                  }
                />
              )}
              {item.type === 'checkbox' && (
                <Box display="flex" flexDirection="column">
                  <ListSubCheckbox list={item.list} listKey={item.nameInput} />
                </Box>
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}
