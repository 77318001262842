import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import NormalTable from '../../../../../../components/Table/NormalTable/NormalTable'

import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { fetchLicenseHistory, headCells } from './events'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { LoadingPage } from '../../Exam/ExamDrawer/Styled'

const LicenseDrawer = ({ isOpen, onCloseDrawer }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.agentProfile.isLoading,
    }),
    shallowEqual,
  )
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  })

  useEffect(() => {
    if (isOpen) dispatch(fetchLicenseHistory({ uuid, setData }))
  }, [isOpen])

  const rows = data.result.map((item) => {
    return {
      ...item,
      nameEvent: item.name,
    }
  })

  const isLoadingAll = isLoading
  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Box
        sx={{
          opacity: isLoadingAll ? 0.4 : 'unset',
          pointerEvents: isLoadingAll ? 'none' : 'unset',
        }}
      >
        <StyledContent>
          <StyledHeadLabel>
            <Box id="header">
              <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ m: 3, gap: 4 }}>
            <NormalTable
              headCells={headCells}
              listRows={rows}
              totalRows={data.totalCount}
              showPagination
              handlePagination={(events) =>
                dispatch(fetchLicenseHistory({ uuid, setData, events }))
              }
            />
          </Box>
        </StyledContent>
      </Box>
      <LoadingPage isLoading={isLoadingAll} />
    </StyledDrawer>
  )
}

export default LicenseDrawer
