import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

import CircularProgress from '@mui/material/CircularProgress'

export const BoxContentMian = styled(Box)(({ theme }) => ({
  width: '100%',
  zIndex: 5,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 850,
    paddingTop: theme.spacing(8),
  },
}))

export const CardCalendar = styled(Card)(({ theme, height }) => ({
  minWidth: 275,
  marginLeft: theme.spacing(0),
  marginRight: theme.spacing(0),
  paddingBottom: theme.spacing(2),
  zIndex: 5,
  height: `calc(100vh - ${height + 95}px)`,
  overflow: 'auto',
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
  zIndex: 100,
}))

export const BoxContent = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
