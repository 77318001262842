import * as yup from 'yup'
import { eTestingSchema } from './TypeContent/ETesting/schema'
import { eEvaluationSchema } from './TypeContent/EEvaluation/schema'
import { linkToWebsiteSchema } from './TypeContent/LinkToWebsite/schema'
import { embedContentSchema } from './TypeContent/EmbedContent/schema'

export const schema = yup.object({
  moduleCode: yup.string().nullable(),
  moduleType: yup.string().nullable().required('กรุณาเลือกรูปแบบโมดูล'),
  name: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .max(255, 'ชื่อโมดูลกรอกได้ไม่เกิน 255 ตัวอักษร'),
  description: yup.string().max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  status: yup.string(),
  eTesting: eTestingSchema,
  eEvaluation: eEvaluationSchema,
  linkToAnotherWebsite: linkToWebsiteSchema,
  embedContent: embedContentSchema,
})
