import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledGroupBox } from '../../../../../../../components/FilterDrawer/Styled'
import DateRangeInput from '../../../../../../../components/DateRangeInput'
import SelectGroupChip from '../../../../../../../components/SelectGroupChip'
import { handleChange, handleSelectDate, handleChangeValue } from './events'
import { arrayCheckbox } from '../model'
import _ from 'lodash'
import { COURSE_TYPE } from '../../../../../../../constants/eLearning'

export const ListCheckbox = () => {
  const { filter, courseBatchList, courseBatchOptions, courseType } =
    useSelector(
      (state) => ({
        filter: state.eLearningDashboardByCourse.filter,
        courseBatchList:
          state.eLearningDashboardByCourse.filter.courseBatchList,
        courseBatchOptions: state.eLearningDashboardByCourse.courseBatchOptions,
        courseType: state.eLearningCourseDetail.result.courseType,
      }),
      shallowEqual,
    )

  const courseBatchSelected = _.filter(courseBatchOptions, (course) =>
    _.includes(courseBatchList, _.get(course, 'uuid')),
  )
  const listArrayCheckbox =
    courseType === COURSE_TYPE.GENERAL
      ? _.filter(
          arrayCheckbox,
          (arr) => arr.nameCheckBox !== 'courseBatchChecked',
        )
      : arrayCheckbox

  return _.map(listArrayCheckbox, (item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={filter[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    isCheck: true,
                  })
                }}
              />
            }
            label={item.label}
            sx={!filter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {filter[item.nameCheckBox] && (
            <>
              {item.type === 'dateRange' && (
                <DateRangeInput
                  placeholder={item.placeholder}
                  dateState={{
                    startDate: filter.periodStart || new Date(),
                    endDate: filter.periodEnd || new Date(),
                    display: filter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    handleSelectDate(selectedDates)
                  }}
                />
              )}
              {item.type === 'multiDropdown' && (
                <SelectGroupChip
                  boxSx={{ mb: 3 }}
                  id={`${item.nameInput}`}
                  name={`${item.nameInput}`}
                  labelText={`ชื่อ${item.label}`}
                  placeholder="กรุณาเลือก"
                  options={courseBatchOptions}
                  value={courseBatchSelected}
                  onChange={(list) => handleChangeValue(list)}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}
