import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import { MINI_ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import {
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableSortLabel,
  StyledFullWidth,
} from '../../../../../components/redux/Table/Styled'
import { handleLabelDisplay } from '../../../../../components/redux/Table/events'
import {
  checkConditionView,
  handleChangePage,
  handleChangeRowsPerPage,
} from './events'

const BasicTable = ({ keyTable }) => {
  const dispatch = useDispatch()
  const table = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail.tables[keyTable],
  )
  const {
    isLoading,
    rows,
    headCells,
    allCount,
    isShowPagination,
    rowsPerPage,
    tablePage,
  } = table

  return (
    <StyledFullWidth isLoading={isLoading}>
      <TableContainer>
        <StyledTable>
          <BasicTableHead headCells={headCells} />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => (
                <TableRow key={rowIndex} color="primary" type="hover">
                  {headCells.map((item, cellIndex) => {
                    return checkConditionView(
                      headCells,
                      row,
                      rowIndex,
                      cellIndex,
                    )
                  })}
                </TableRow>
              ))}

            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={MINI_ROW_PAGE_PER_LIST}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(e, tPage) =>
            dispatch(handleChangePage(tPage, keyTable))
          }
          onRowsPerPageChange={(event) =>
            dispatch(handleChangeRowsPerPage(event, keyTable))
          }
        />
      )}
    </StyledFullWidth>
  )
}

export default BasicTable

export const BasicTableHead = ({ headCells }) => {
  return (
    <TableHead>
      <TableRow key="head">
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id

            return (
              <StyledTableCell
                key={valueId}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                headcellwidth={headCell.width}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  direction="asc"
                  hideSortIcon
                  headcell={headCell}
                >
                  {headCell.label}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}
