import _ from 'lodash'
import {
  examinationAssociation,
  examinationAssociationDownload,
  examinationAssociationFilter,
  examinationCompany,
  examinationCompanyDownload,
  examinationCompanyFilter
} from '../../../../../utils/apiPath'
import { examinationAssocCells, examinationCoCells } from '../../../../../constants/MasterData/headerCell'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import { initialState } from '../../../../../redux/slices/table/model'
import callAxios from '../../../../../utils/baseService'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { callAPI } from '../../../../../utils/lib'

export const onDownload = (selected, sort, order, examType) => async (dispatch) => {
  dispatch(startLoading())
  let body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }
  if(examType !== 'สนามสอบสมาคม'){
    body = {
      ...body,
      isStation: false
    }
  }
  const url = examType === 'สนามสอบสมาคม' ? examinationAssociationDownload : examinationCompanyDownload
  const name = examType === 'สนามสอบสมาคม' ? 'รายการสนามสอบสมาคม' : 'รายการสนามสอบบริษัท'
  await dispatch(
    downloadFile({
      url: url,
      body: body,
      fileName: `${name}.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, statusList, filterProp, keyword, examType, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const regionUuid = _.get(filterProp, 'regionUuid', [])
    const provinceName = _.get(filterProp, 'provinceName', '')
    const stationName = _.get(filterProp, 'stationName', '')
    const locationCode = _.get(filterProp, 'locationCode', '')
    const createdBy = _.get(filterProp, 'createdBy', '')
    const updatedBy = _.get(filterProp, 'updatedBy', '')
    const status = _.get(filterProp, 'status', [])
    if(status.length > 0){
      statusList = status
    }
    const body = {
      search: keyword,
      region: regionUuid,
      provinceName: provinceName,
      searchStation: '',
      station: stationName,
      locationCode: locationCode,
      createdBy: createdBy,
      updatedBy: updatedBy,
      status: isInitial ? [] : statusList,
      limit: tableProps.limit,
      order: tableProps.order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const examinationCells = examType === 'สนามสอบสมาคม' ? examinationAssocCells : examinationCoCells
    const examinationUrl = examType === 'สนามสอบสมาคม' ? examinationAssociationFilter : examinationCompanyFilter
    const searchKey = examType === 'สนามสอบสมาคม' ? 'provinceName' : 'station'

    const isCancel = await callAxios
      .post(examinationUrl, body)
      .then(({ data }) => {
        dispatch(
          setInitialTable({
            rows: data.result,
            allCount: data.totalCount,
            headCells: examinationCells,
            placeholder: 'ค้นหา',
            searchKey: searchKey,
            status: statusList,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text, examType, filterProp)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order, examType)),
            onDelete: (selected) =>
              dispatch(
                openDialog({
                  title: 'ยืนยันลบข้อมูล',
                  message: 'คุณต้องการลบรายการนี้หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  handleConfirm: () =>
                    dispatch(onDelete(selected, table, filterProp, statusList, keyword, examType)),
                }),
              ),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: examinationCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter, region, manageProps, examType) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  const regionList = []

  if (filter.regionChecked) {
    if(examType === 'สนามสอบสมาคม'){
      for (const key in filter.region) {
        if (!filter.region[key]) continue
        const item = region.find((e) => e.name === key)
        regionList.push(_.get(item, 'uuid', ''))
      }
    }
  }

  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  } else {
    statusList.push('ACTIVE', 'INACTIVE', 'DELETED')
  }
  dispatch(setStatusFilter(statusList))

  const filterText = {
    name: '',
    regionUuid: filter.regionChecked ? regionList : [],
    provinceName: filter.provinceNameChecked ? filter.provinceName : '',
    locationCode: filter.locationCodeChecked ? filter.locationCode : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    stationName: filter.stationNameChecked ? filter.stationName : '',
  }
  
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList('filter', table, 1, statusList, filterText, filterText.name, examType)
  )
}

export const handleQuickSearch = (table, text, examType, filterProp) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(fetchDataList('search', table, 1, statusList, filterProp, text, examType))
}

export const onDelete = (selected, table, filterProp, statusList, keyword, examType) => async (dispatch) => {
  dispatch(startLoading())
  const url = examType === 'สนามสอบสมาคม' ? examinationAssociation : examinationCompany
  await callAPI({
    method: 'delete',
    url: `${url}/${selected.uuid}`,
  })

  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ถูกลบแล้ว',
      isCloseDialog: false,
      handleConfirm: () => {
        dispatch(
          fetchDataList(
            'search',
            table,
            1,
            statusList,
            filterProp,
            keyword, 
            examType,
          ),
        )
        dispatch(resetTablePage())
        dispatch(closeDialog())
      },
    }),
  )

  dispatch(stopLoading())
}