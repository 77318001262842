import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import TextInput from '../../../../../../../components/Input/TextInput'
import { StyledColumn } from '../../../../Styled'
import ImageUploader from '../ImageUploader'
import { handleInputChange } from '../events'
import { StyledOutlinedBox } from './Styled'
import { getDefaultType } from './events'

const BannerMediaForm = () => {
  const { values, errors, setValues } = useFormikContext()
  const error = useSelector(
    (state) => state.manageContentHome.error,
    shallowEqual
  )
  const [mediaType, setMediaType] = useState(getDefaultType(values))
  const key = mediaType === 'IMAGE' ? 'image' : 'videoURL'
  const mobileKey = mediaType === 'IMAGE' ? 'mobileImg' : 'videoImg'
  const isError =
    !!_.get(error, 'upload', null) ||
    !!_.get(errors, key, '') ||
    !!_.get(errors, mobileKey, '')
    
  return (
    <StyledOutlinedBox isError={isError}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          เลือกประเภทของแบนเนอร์ <span>*</span>
        </Typography>
        <RadioGroup
          sx={{ pl: 1.5, gap: 0.5 }}
          data-testid="radio-setMediaType"
          name="setMediaType"
          value={mediaType}
          onChange={(e) => {
            setMediaType(e.target.value)
            setValues((prev) => ({
              ...prev,
              image: '',
              mobileImg: '',
              videoURL: '',
              videoImg: '',
              mediaType: e.target.value,
            }))
          }}
        >
          <FormControlLabel value="IMAGE" control={<Radio />} label="Image" />
          <FormControlLabel
            value="VIDEO"
            control={<Radio inputProps={{ 'data-testId': 'radio-video' }} />}
            label="Video URL"
          />
        </RadioGroup>
      </Box>

      <StyledColumn>
        {mediaType == 'IMAGE' ? (
          <>
            <Typography mb={3} variant="body2" color="text.secondary">
              Desktop Image <span>*</span>
            </Typography>
            <ImageUploader mediaType={mediaType} />
          </>
        ) : (
          <TextInput
            required
            name="videoURL"
            type="basic"
            labelText="Video URL"
            id="input-video-url"
            placeholder="https://"
            value={_.get(values, 'videoURL', '')}
            textError={_.get(errors, 'videoURL', '')}
            onChange={(e) => handleInputChange(e, setValues)}
          />
        )}

        <Typography my={3} variant="body2" color="text.secondary">
          Mobile Image <span>*</span>
        </Typography>

        <ImageUploader mediaType={mediaType} isMobile />
      </StyledColumn>
    </StyledOutlinedBox>
  )
}

export default BannerMediaForm
