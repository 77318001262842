import React, { createRef, useRef, useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'

import TextInput from '../../../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import { TooltipForm } from '../../../Styled'
import { handleChange, handleDelete, onDragEnd } from './events'
import { getErrorMessage, scrollSmoothHandler } from '../../../../event'
import { draftQuestionNotOther } from '../../../../../../../redux/slices/eEvaluationForm'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export const Question = ({ type, sequence, indexQuestion, length }) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)
  const { itemQuestion, disableForm, errorSchemaMessage } = useSelector(
    (state) => ({
      itemQuestion: _.get(
        state,
        `eEvaluationForm[${type}][0].questions`,
        []
      ).find((item) => item.sequence === sequence),
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  let fieldString = ''
  switch (type) {
    case 'courseAssessments':
      fieldString = 'ASSESSMENT'
      break
    case 'lecturerAssessments':
      fieldString = 'LECTURER_ASSESSMENT'
      break
    case 'courseManagementAssessments':
      fieldString = 'MANAGE_ASSESSMENT'
      break
    default:
      fieldString = ''
      break
  }

  const questionErrorMessage = getErrorMessage({
    field: fieldString,
    errorSchemaMessage,
    indexQuestion,
    type: type,
  })
  return (
    <Draggable
      key={itemQuestion.id}
      draggableId={itemQuestion.id}
      index={indexQuestion}
    >
      {(provided) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{ display: 'flex', width: '100%' }}
        >
          {!disableForm ? (
            <Styled.BoxDragIndicator {...provided.dragHandleProps}>
              <DragIndicatorTwoTone />
            </Styled.BoxDragIndicator>
          ) : (
            <Styled.BoxDragIndicator>
              <DragIndicatorTwoTone />
            </Styled.BoxDragIndicator>
          )}

          <Styled.BoxContent>
            <Styled.BoxInput length>
              <TextInput
                id="title-answer"
                name="title"
                placeholder="คำถาม"
                type="basic"
                boxSx={{ mt: 1, mb: 1 }}
                disabled={disableForm}
                value={_.get(itemQuestion, 'title')}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      type,
                      sequence,
                      key: 'title',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }
                isShowTextError={questionErrorMessage ? true : false}
                textError={questionErrorMessage}
              />
            </Styled.BoxInput>
            {length > 1 && (
              <Box sx={{ ml: 2, position: 'relative', top: 8 }}>
                <TooltipForm
                  placement="bottom"
                  title={<Typography variant="tooltip">ลบ</Typography>}
                  onClick={() =>
                    !disableForm &&
                    dispatch(
                      handleDelete({
                        type,
                        sequence,
                      })
                    )
                  }
                >
                  <IconButton
                    onMouseLeave={() => setIsHover(false)}
                    onMouseOver={() => setIsHover(true)}
                    data-testid={`btn-delete-${sequence}`}
                  >
                    <CloseOutlined color={isHover ? 'primary' : 'action'} />
                  </IconButton>
                </TooltipForm>
              </Box>
            )}
          </Styled.BoxContent>
        </Box>
      )}
    </Draggable>
  )
}

const Index = ({ type }) => {
  const myRef = useRef({ current: [] })
  const listQuestion = useSelector(draftQuestionNotOther(type), shallowEqual)
  const dispatch = useDispatch()
  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (listQuestion.length > 0) {
      myRef.current = listQuestion.map(
        (_, i) => myRef.current[i] ?? createRef()
      )
    }
  }, [listQuestion])
  useEffect(() => {
    if (
      (_.get(errorSchemaMessage, 'field', '') !== '' &&
        errorSchemaMessage.sectionType === 'courses' &&
        type === 'courseAssessments') ||
      (_.get(errorSchemaMessage, 'sectionType', '') === 'lecturers' &&
        type === 'lecturerAssessments') ||
      (_.get(errorSchemaMessage, 'sectionType', '') === 'courseManagements' &&
        type === 'courseManagementAssessments')
    ) {
      scrollSmoothHandler(_.get(errorSchemaMessage, 'questionNo', -1), myRef)
    }
  }, [errorSchemaMessage])

  return (
    <>
      <Styled.LabelQuestion variant="body1b">คำถาม</Styled.LabelQuestion>

      <DragDropContext onDragEnd={(e) => dispatch(onDragEnd(e, type))}>
        <Droppable droppableId={`list-${type}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {listQuestion.map((item, indexQuestion) => (
                <div key={item} ref={myRef?.current[indexQuestion]}>
                  <Question
                    key={item}
                    type={type}
                    indexQuestion={indexQuestion}
                    length={listQuestion.length}
                    sequence={item}
                  />
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
export default Index
