import _ from 'lodash'
import { useDispatch } from 'react-redux'
import DateRangeInput from '../../../components/DateRangeInput'
import { setHandleChange } from '../../../redux/slices/table'

export const CustomDatePicker = ({ filterState, item, placeholder }) => {
  const dispatch = useDispatch()

  const getDateState = (item) => {
    return {
      startDate: filterState[item.dateStateStart]
        ? filterState[item.dateStateStart]
        : new Date(),
      endDate: filterState[item.dateStateFinish]
        ? filterState[item.dateStateFinish]
        : new Date(),
      display: _.get(filterState, item.dateStateDisplay, false),
      key: 'selection',
    }
  }

  const handleSelectDate = (selectedDates, filter, item) => {
    const objState = {
      ...filter,
      [item.dateStateStart]: selectedDates.startDate,
      [item.dateStateFinish]: selectedDates.endDate,
      [item.dateStateDisplay]: selectedDates.display,
      [item.nameInput]: selectedDates.startDate,
    }
    dispatch(setHandleChange({ key: 'filterState', value: objState }))
  }

  return (
    <DateRangeInput
      dateState={getDateState(item)}
      onChange={(selectedDates) => {
        handleSelectDate(selectedDates, filterState, item)
      }}
      placeholder={placeholder}
    />
  )
}
