import React, { useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Videocam from '@mui/icons-material/Videocam'
import TextInput from '../../../../../../components/Input/TextInput'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import {
  startVideoLoading,
  stopVideoLoading,
} from '../../../../../../redux/slices/eLearning/moduleForm'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import StatusSwitch from '../StatusSwitch'
import { TextLabel } from '..'
import { StyledBoxVideo } from './Styled'
import { EMBED_TYPE } from './model'
import { handleDisplayLink, handleInitialSet, validateVideo } from './events'
import './style.css'

const EmbedContent = () => {
  const { id: moduleUuid } = useParams()
  const typeField = _.camelCase(MODULE_TYPE.EMBED_CONTENT)
  const keyType = `${typeField}.type`
  const dispatch = useDispatch()
  const { type, isVideoLoading, onGoing } = useSelector(
    (state) => ({
      type: getStateValue(state, keyType),
      isVideoLoading: state.eLearningModuleForm.isVideoLoading,
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const { setFieldError } = useFormikContext()

  useEffect(() => {
    dispatch(handleInitialSet(moduleUuid, keyType))
  }, [])

  return (
    <StyledFormCard isLoading={isVideoLoading}>
      <Typography variant="h6">Embed Content</Typography>
      <FormControl>
        <TextLabel label="เลือกรูปแบบ Link" />
        <RadioGroup
          name={keyType}
          sx={{ ml: 3, gap: 1 }}
          value={type}
          onChange={(e) => {
            dispatch(handleChangeEvent(e))
            dispatch(handleChange(`${typeField}.value`, ''))
            setFieldError(`${typeField}.value`, undefined)
          }}
        >
          <FormControlLabel
            value={EMBED_TYPE.LINK}
            control={<Radio />}
            label="Link"
            disabled={onGoing}
          />
          {type === EMBED_TYPE.LINK && (
            <InputAndPreview typeField={typeField} label="Link" />
          )}
          <FormControlLabel
            value={EMBED_TYPE.HTML}
            control={<Radio />}
            label="HTML"
            disabled={onGoing}
          />
          {type === EMBED_TYPE.HTML && (
            <InputAndPreview typeField={typeField} label="HTML" />
          )}
        </RadioGroup>
      </FormControl>
      <Divider />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default EmbedContent

export const InputAndPreview = ({ typeField, label }) => {
  const dispatch = useDispatch()
  const keyValue = `${typeField}.value`
  const keyDuration = `${typeField}.duration`
  const { value, duration, type, onGoing } = useSelector(
    (state) => ({
      value: getStateValue(state, keyValue),
      duration: getStateValue(state, keyDuration),
      type: getStateValue(state, `${typeField}.type`),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const isHtml = type === EMBED_TYPE.HTML
  const displayLink = useMemo(() => handleDisplayLink(type, value), [value])
  const isFormatCorrect = useMemo(() => validateVideo(displayLink), [value])
  const videoPlayerRef = useRef(null)
  const { errors } = useFormikContext()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextInput
        multiline
        rows={3}
        id="input-upload-value"
        type="basic"
        placeholder={`วาง ${label}`}
        name={keyValue}
        value={value}
        textError={_.get(errors, keyValue, '')}
        isShowTextError={_.get(errors, keyValue, '')}
        onChange={(e) => {
          dispatch(handleChange(keyValue, null))
          dispatch(handleChange(keyDuration, null))
          if (validateVideo(e.target.value, isHtml)) {
            dispatch(startVideoLoading())
          }
          setTimeout(() => {
            dispatch(handleChangeEvent(e))
          }, 300)
        }}
        disabled={onGoing}
      />

      <ReactPlayer
        ref={videoPlayerRef}
        volume={0}
        playing
        muted
        style={{ display: 'none', pointerEvents: 'none' }}
        url={isFormatCorrect ? displayLink : ''}
        onError={() => dispatch(stopVideoLoading())}
        onDuration={(duration) => {
          dispatch(handleChange(keyDuration, duration))
          dispatch(stopVideoLoading())
        }}
      />
      {!_.isEmpty(value) && !_.isNil(duration) && isFormatCorrect && (
        <VideoPreview type={type} value={value} />
      )}
    </Box>
  )
}

export const VideoPreview = ({ type, value }) => {
  const displayLink = useMemo(() => handleDisplayLink(type, value), [value])
  return (
    <StyledBoxVideo>
      <ReactPlayer
        light
        volume={0}
        playing={false}
        width="150px"
        height="100px"
        style={{ pointerEvents: 'none' }}
        url={displayLink}
      />
      <Videocam />
    </StyledBoxVideo>
  )
}
