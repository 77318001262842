import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { StyledContainer, StyledContent } from './Styled'
import Header from './components/Header'
import OverviewCourseSection from './components/OverviewCourseSection'
import OverviewByTypeSection from './components/OverviewByTypeSection'
import OverviewModule from './components/OverviewModule'
import CourseDrawer from './components/CourseDrawer'
import { fetchDashboardData } from './events'
import {
  closeCourseDrawer,
  closeEnrollDrawer,
  resetFilter,
} from '../../../redux/slices/eLearning/dashboard'
import { LoadingCircular } from '../Styled'
import { defaultFilter } from './model'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { isLoading, startDate, endDate, courseType, status } = useSelector(
    (state) => ({
      isLoading: state.eLearningDashboard.isLoading,
      startDate: state.eLearningDashboard.filter.startDate,
      endDate: state.eLearningDashboard.filter.endDate,
      courseType: state.eLearningDashboard.filter.courseType,
      status: state.eLearningDashboard.filter.status,
    }),
    shallowEqual,
  )
  useEffect(() => {
    dispatch(fetchDashboardData({ startDate, endDate, courseType, status }))
    return () => {
      dispatch(closeCourseDrawer())
      dispatch(closeEnrollDrawer())
      dispatch(resetFilter(defaultFilter))
    }
  }, [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Header />
        <OverviewCourseSection />
        <OverviewByTypeSection />
        <OverviewModule />
      </StyledContent>
      <CourseDrawer />
      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Dashboard
