import styled from '@mui/system/styled'
import { Row } from '../../../../Styled'

export const StyledLessonLabel = styled(Row)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  background: theme.palette?.text?.alabaster,
  cursor: 'pointer',
}))

export const StyledModuleLabel = styled(Row)(({ theme }) => ({
  padding: `10px ${theme.spacing(2)}`,
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  background: theme.palette?.background?.paper,
  justifyContent: 'space-between',
}))
