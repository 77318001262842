import React from 'react'
import { Box } from '@mui/system'

import { StyledCropperWrapper } from '../styled'
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider'
import Button from '@mui/material/Button'
import { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledDialog } from '../../redux/PopUpDialog/Styled'
import Typography from '@mui/material/Typography'
import ImageIcon from '@mui/icons-material/Image'
import { closeCropperDialog } from '../handler/closeCropperDialog'
import { handleCropLocal } from '../handler/handleCropLocal'
import { handleReadFile } from '../handler/handleReadFile'

export const CropperDialog = ({ folder, imageLocalSrc, onChange }) => {
  if (!imageLocalSrc) return null

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [file, setFile] = useState(undefined)
  const [zoom, setZoom] = useState(1)
  const ref = useRef(null)
  const { isOpen } = useSelector(
    (state) => ({
      isOpen: state.uploadCropper.isOpen,
    }),
    shallowEqual,
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setFile(await handleReadFile(imageLocalSrc))
  }

  if (!file) return null

  return (
    <Box>
      <StyledDialog maxWidth="table" open={isOpen}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ m: 2 }}
        >
          <Typography variant="h5">ครอบตัดรูปภาพของคุณ</Typography>
          <Typography variant="body2b" color="primary" sx={{ marginBottom: 2 }}>
            คลิก-ลากเพื่อขยับรูปภาพตามความเหมาะสม
          </Typography>
          <StyledCropperWrapper>
            <Cropper
              ref={ref}
              image={file}
              crop={crop}
              rotation={0}
              zoom={zoom}
              aspect={684 / 454}
              onCropChange={setCrop}
              onZoomChange={setZoom}
            />
          </StyledCropperWrapper>
          <Box
            display="flex"
            width="100%"
            gap={2}
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <ImageIcon fontSize="16" />
            <Slider
              min={1}
              max={10}
              step={0.01}
              defaultValue={1}
              size="small"
              onChange={(_, value) => setZoom(value)}
            />
            <ImageIcon fontSize="24" />
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <Button variant="outlined" onClick={closeCropperDialog}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setZoom(1)
                handleCropLocal({ ref, file, onChange, folder })
              }}
            >
              ยืนยัน
            </Button>
          </Box>
        </Box>
      </StyledDialog>
    </Box>
  )
}
