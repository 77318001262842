import { path } from './path'

export const headCells = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    disablePadding: false,
    width: '180px',
    minWidth: '180px',
  },
  {
    id: 'courseEnglishName',
    label: 'ชื่อหลักสูตร EN',
    disablePadding: false,
    minWidth: '230px',
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    disablePadding: false,
    minWidth: '230px',
  },
  {
    id: 'licenseDescription',
    label: 'ชื่อใบอนุญาต',
    disablePadding: false,
  },
  {
    id: 'flag',
    label: 'Flag',
    disablePadding: false,
  },
  {
    id: 'licenseStatus',
    label: 'สถานะใบอนุญาต',
    disablePadding: false,
  },
  {
    id: 'rcmsStatus',
    label: 'ส่งข้อมูลไป RCMS',
    disablePadding: false,
    width: '80px',
    minWidth: '80px',
  },
  {
    id: 'finishDate',
    label: 'วันสิ้นสุดอบรม',
    disablePadding: false,
    width: '80px',
    minWidth: '80px',
  },
  {
    id: 'createdBy',
    label: 'สร้างโดย',
    disablePadding: false,
  },
  {
    id: 'createdAt',
    label: 'วันที่สร้าง',
    disablePadding: false,
    width: '80px',
    minWidth: '80px',
  },
  {
    id: 'actionRcms',
    label: 'คำสั่ง',
    disablePadding: false,
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.apiToRcms,
  },
]

export const headDetailCells = [
  {
    id: 'fullAgentCode',
    label: 'รหัส Agent',
    disablePadding: false,
  },
  {
    id: 'fullName',
    label: 'ชื่อ - นามสกุล',
    disablePadding: false,
  },
  {
    id: 'bankCode',
    label: 'Bank Code',
    disablePadding: false,
  },
  {
    id: 'agentCategory',
    label: 'ประเภท Agent',
    disablePadding: false,
  },
  {
    id: 'effectiveDate',
    label: 'วันมีผลบังคับ',
    disablePadding: false,
  },
  {
    id: 'expiryDate',
    label: 'วันหมดอายุ',
    disablePadding: false,
  },
  {
    id: 'licenseStatus',
    label: 'สถานะใบอนุญาต',
    disablePadding: false,
  },
  {
    id: 'rcmsStatus',
    label: 'ส่งข้อมูลไป RCMS',
    disablePadding: false,
  },
  {
    id: 'remark',
    label: 'หมายเหตุ',
    disablePadding: false,
  },
  {
    id: 'actionRcmsDetail',
    label: 'คำสั่ง',
    disablePadding: false,
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.apiToRcmsPreview,
  },
]

export const MORE_OPTIONS = [
  {
    text: 'ดาวน์โหลด Template',
    method: 'download',
  },
  {
    text: 'ประวัติการนำเข้าข้อมูล',
    method: 'history',
  },
]

export const MORE_DETAIL_OPTIONS = [
  {
    text: 'นำเข้าข้อมูล',
    method: 'import',
  },
  {
    text: 'ดาวน์โหลดข้อมูล',
    method: 'download',
  },
  {
    text: 'ประวัติการเปลี่ยนแปลง',
    method: 'history',
  },
]

export const rcmsStatus = {
  NOTFOUND: 'ไม่ถูกต้อง',
  ERROR: 'ไม่ถูกต้อง',
  DUPLICATE: 'ข้อมูลซ้ำ',
  SUCCESS: 'เพิ่มได้',
  TERMINATE: 'ไม่ถูกต้อง',
}

export const rcmsDetailStatus = {
  NOTFOUND: 'ไม่ถูกต้อง',
  DUPLICATE: 'เคยส่ง RCMS แล้ว',
  SUCCESS: 'เพิ่มได้',
  ERROR: 'ไม่ถูกต้อง',
  TERMINATE: 'ไม่ถูกต้อง',
}
