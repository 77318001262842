export const defaultFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  startTestDateChecked: false,
  startTestDate: '',
  endTestDate: '',
  distributionChecked: false,
  distribution: '',
  agentCodeChecked: false,
  agentCode: '',
  unitCodeChecked: false,
  unitCode: '',
  sRamChecked: false,
  sRam: '',
  RAMChecked: false,
  RAM: '',
  scoreChecked: false,
  scoreFrom: '',
  scoreTo: '',
  testResultChecked: false,
  testResult: {
    passChecked: false,
    failChecked: false,
    waitingChecked: false,
  },
}
