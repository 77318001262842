import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const sortQuestion = (questionsDatas = [], sort) => {
  let questionsDatasSort = [...questionsDatas]

  switch (sort) {
    case 'wrongDESC':
      questionsDatasSort = placeFreeTextAtEnd(
        questionsDatasSort.sort((a, b) => {
          return a.correctAnswer - b.correctAnswer
        }),
      )
      break
    case 'correctDESC':
      questionsDatasSort = placeFreeTextAtEnd(
        questionsDatasSort.sort((a, b) => {
          return b.correctAnswer - a.correctAnswer
        }),
      )
      break
    case 'sequence':
      questionsDatasSort.sort((a, b) => {
        return a.sequence - b.sequence
      })
      break
    default:
      break
  }

  store.dispatch(
    setFieldValue({
      key: 'formData.questionsDatas',
      value: questionsDatasSort,
    }),
  )
}

function placeFreeTextAtEnd(arr) {
  return arr.filter(isntFreeText).concat(arr.filter(isFreeText))
}
function isntFreeText(element) {
  return element.questionType !== 'FREE_TEXT'
}
function isFreeText(element) {
  return element.questionType === 'FREE_TEXT'
}
