import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'
import { StatusComponent } from '../../../../components/CRUD/components/StatusComponent'
import _ from 'lodash'
import { contentType } from './model/contentType'
import { Typography } from '@mui/material'
import { themeColor } from '../../../../utils/themeData'
import Tab from '@mui/material/Tab'
import { Box } from '@mui/system'
import { useState } from 'react'
import { StyledTabView } from '../Form/components/tabMenu/Styled'
import { StyledChipTypography } from '../../../ELearning/components/View/Styled'
import { convertFormatDateTime } from '../../../../utils/lib'
import { ChipViewRow } from '../../../../components/CRUD/components/StyledChipViewBox'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display="flex" flexDirection="column" gap={1}>
          {children}
        </Box>
      )}
    </div>
  )
}
export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  const [value, setValue] = useState(0)

  if (_.isEmpty(formData) || _.isUndefined(formData.name)) return <div />

  const isDownloadText = () => {
    if (formData.isDownload === 'ACTIVE') {
      return 'อนุญาต'
    } else {
      return 'ไม่อนุญาต'
    }
  }

  const isActiveText = () => {
    if (formData.isActive === 'ACTIVE') {
      return 'เปิดใช้งาน'
    } else {
      return 'ปิดใช้งาน'
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabView value={value} onChange={handleChange}>
          <Tab label="เนื้อหา" />
          <Tab label="การตั้งค่า" />
        </StyledTabView>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Typography
          variant="h6"
          fontWeight="bold"
          color={themeColor.primary.main}
        >
          ข้อมูลพื้นฐาน
        </Typography>
        <ViewRow title="ชื่อเรื่อง" value={formData.name} />
        <ViewRow title="ID" value={formData.id} />
        <ViewRow
          title="หมวดหมู่"
          value={_.get(formData, 'category.label', '')}
        />
        <ViewRow
          title="หมวดหมู่ย่อย"
          value={_.get(formData, 'subCategory.label', '')}
        />

        <ViewRow
          title="ประเภทเนื้อหา"
          value={contentType[formData.contentType]}
        />
        <ViewRow
          boxSx={{ mb: 1 }}
          title="รูปภาพหน้าปก"
          valueComponent={
            <>
              {_.isEmpty(formData.coverImage.key) && '-'}
              {!_.isEmpty(formData.coverImage.key) && (
                <img
                  src={`${window.__env__.REACT_APP_API_URL}/file${formData.coverImage.key}`}
                  height="100"
                  alt=""
                />
              )}
            </>
          }
        />
        <Divider />
        <Typography
          variant="h6"
          fontWeight="bold"
          color={themeColor.primary.main}
        >
          การอนุญาต
        </Typography>
        <ViewRow
          title="อนุญาตการดาวน์โหลดเนื้อหา"
          value={isDownloadText(formData.isDownload)}
        />
        <ViewRow title="สถานะ" valueComponent={StatusComponent(formData)} />
        <ViewRow
          title="การเปิดใช้งาน"
          value={isActiveText(formData.isActiveText)}
        />

        <Divider />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Typography
          variant="h6"
          fontWeight="bold"
          color={themeColor.primary.main}
        >
          การเผยแพร่
        </Typography>
        <ViewRow
          title="วันเริ่มต้น"
          value={convertFormatDateTime({
            value: formData.startPublishDate,
            type: 'date',
          })}
        />
        <ViewRow
          title="วันสิ้นสุด"
          value={
            formData.isEndPublishDate == 'ACTIVE'
              ? convertFormatDateTime({
                  value: formData.endPublishDate,
                  type: 'date',
                })
              : 'ไม่มีกำหนด'
          }
        />
        <Divider />
        <Typography
          variant="h6"
          fontWeight="bold"
          color={themeColor.primary.main}
        >
          การตั้งค่าบทความ
        </Typography>

        <ViewRow
          title="ผู้ร่วมจัดทำ"
          valueComponent={
            <>
              {formData.assignees.map((item) => (
                <StyledChipTypography mr={1} key={item.label} variant="chip">
                  {item.label}
                </StyledChipTypography>
              ))}
            </>
          }
        />
        <ChipViewRow
          title="ช่องทางการจำหน่าย (Distribution Channel)"
          valueComponent={
            <>
              {formData.distributionCh.map((item) => (
                <StyledChipTypography mr={1} key={item.labe} variant="chip">
                  {item.label}
                </StyledChipTypography>
              ))}
            </>
          }
        />
        <ChipViewRow
          title="ระดับของผู้เรียน"
          valueComponent={
            <>
              {formData.levelOfLearner.map((item) => (
                <StyledChipTypography mr={1} key={item.label} variant="chip">
                  {item.label}
                </StyledChipTypography>
              ))}
            </>
          }
        />
        <ChipViewRow
          title="Tag"
          valueComponent={
            <>
              {formData.eContentTag.map((item) => (
                <StyledChipTypography mr={1} key={item.label} variant="chip">
                  {item.label}
                </StyledChipTypography>
              ))}
            </>
          }
        />
        <ViewRow title="หมายเหตุ" value={formData?.remark} />
        <Divider />
      </CustomTabPanel>
      <UpdatedComponent />
    </>
  )
}
