import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import GroupContent from './GroupContent'

import { BoxContent, BoxContentHeader } from './Styled'

import { mapLabelDistribution } from './events'

const Index = () => {
  const {
    licenseConfig,
    listDistribution,
    licenseList,
    listDistributionELearning,
  } = useSelector(
    (state) => ({
      licenseConfig: state.masterDataLicenseForm.initialState.licenseConfig,
      listDistribution:
        state.masterDataLicenseForm.initialState.listDistribution,
      listDistributionELearning:
        state.masterDataLicenseForm.initialState.listDistributionELearning,
      licenseList: state.masterDataLicenseForm.initialState.licenseList,
    }),
    shallowEqual,
  )

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Typography variant="h6">เงื่อนไขการได้รับใบอนุญาต</Typography>

        {licenseConfig.length > 0 &&
          licenseConfig.map((itemConfig, indexConfig) => {
            const listGroups = _.get(itemConfig, 'groups', [])

            const distribution = _.get(
              itemConfig,
              'distributionChannelUuid',
              '',
            )

            return (
              <>
                <BoxContent key={indexConfig}>
                  <Box sx={{ m: 2, mb: 1 }}>
                    <Typography variant="body1b">
                      เงื่อนไข กลุ่ม {indexConfig + 1}
                    </Typography>
                  </Box>

                  <BoxContentHeader>
                    <Box sx={{ width: 400 }}>
                      <Typography variant="body1" color="text.secondary">
                        ช่องทางการจำหน่าย
                      </Typography>
                    </Box>

                    <Box sx={{ ml: 2, width: '65%' }}>
                      <Typography variant="body1">
                        {mapLabelDistribution(listDistribution, distribution)}
                      </Typography>
                    </Box>
                  </BoxContentHeader>

                  <BoxContentHeader>
                    <Box sx={{ width: 400 }}>
                      <Typography variant="body1" color="text.secondary">
                        การส่งข้อมูลไป RCMS
                      </Typography>
                    </Box>

                    <Box sx={{ ml: 2, width: '65%' }}>
                      <Typography variant="body1">
                        {_.get(itemConfig, 'flag', '') == 'Y'
                          ? 'Auto'
                          : 'Manual'}
                      </Typography>
                    </Box>
                  </BoxContentHeader>
                  {listGroups.map((itemGroup, indexGroup) => {
                    const listSubGroup = _.get(itemGroup, 'subGroups', [])
                    const section = `${indexConfig + 1}.${indexGroup + 1}`

                    return (
                      <Box key={indexGroup}>
                        <Box sx={{ mt: indexGroup ? 4 : 2 }}>
                          <Typography variant="body1b" sx={{ m: 1, mx: 5 }}>
                            {!indexGroup ? section : `หรือ ${section}`}
                          </Typography>
                        </Box>

                        {listSubGroup.map((itemSubGroup, indexSubGroup) => {
                          const listContents = _.get(
                            itemSubGroup,
                            'contents',
                            [],
                          )

                          let lastSubGroup = false
                          if (listSubGroup.length == indexSubGroup + 1)
                            lastSubGroup = true

                          return (
                            <>
                              <GroupContent
                                listContents={listContents}
                                listDistribution={listDistribution}
                                listDistributionELearning={
                                  listDistributionELearning
                                }
                                distribution={distribution}
                                indexSubGroup={indexSubGroup}
                                lastSubGroup={lastSubGroup}
                                licenseList={licenseList}
                              />
                            </>
                          )
                        })}
                      </Box>
                    )
                  })}
                </BoxContent>
              </>
            )
          })}
      </Box>
    </>
  )
}

export default Index
