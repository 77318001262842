import _ from 'lodash'
import callAxios from '../../../../../../../utils/baseService'
import {
  departmentAll,
  distributionChannelFilter,
  filterStaffProfile,
} from '../../../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enums/drawerEnum'
import { fetchOverview } from '../../handler/fetchOverview'
import { courseNameType, defaultFilter } from './model'
import { store } from '../../../../../../../App'
import { openErrorDialog } from '../../../../../../../redux/slices/dialog'
import { getOptionsReportByKey } from '../../../../../../../services/reportComp'
import { reportConfig } from '../../../../../../../modules/EEvaluation/ReportRawDataByCourse/reportConfig'
import { fetchStationList } from '../../../../../../../modules/Inventory/Product/List'
import axios from 'axios'

export const handleFiltering = (filter) => (dispatch) => {
  let countFilterTotal = 0
  const isFilterDate =
    filter.dateFilterChecked && filter.dateFilterStartDate != ''
  if (isFilterDate) ++countFilterTotal
  const isFilterUserResponseDate =
    filter.userResponseDateChecked && filter.userResponseDateStartDate != ''
  if (isFilterUserResponseDate) ++countFilterTotal
  if (!isFilterDate && !isFilterUserResponseDate) {
    store.dispatch(
      openErrorDialog({
        message:
          'กรุณาเลือกตัวกรองวันที่เริ่มต้นหลักสูตร หรือวันที่ทำแบบประเมิน',
        title: 'ผิดพลาด',
        isCloseDialog: false,
      }),
    )
    return false
  }
  const isFilterCourseName = filter.courseNameChecked && filter.courseName != ''
  if (isFilterCourseName) ++countFilterTotal
  const isFilterTrainingDate =
    filter.trainingDateChecked && filter.trainingDateStartDate != ''
  if (isFilterTrainingDate) ++countFilterTotal
  const isFilterTrainerName =
    filter.trainerNameChecked && filter.trainerName != ''
  if (isFilterTrainerName) ++countFilterTotal
  const isFilterDistribution =
    filter.distributionChecked && filter.distribution != ''
  if (isFilterDistribution) ++countFilterTotal
  const isFilterCourseCode = filter.courseCodeChecked && filter.courseCode != ''
  if (isFilterCourseCode) ++countFilterTotal
  const isFilterDepartment = filter.departmentChecked && filter.department != ''
  if (isFilterDepartment) ++countFilterTotal
  const isFilterStation = filter.stationChecked && filter.station != ''
  if (isFilterStation) ++countFilterTotal
  dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        dateFilterStartDate: isFilterDate
          ? dayjs(filter.dateFilterStartDate).format('YYYY-MM-DD')
          : isFilterUserResponseDate
          ? ''
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        dateFilterFinishDate: isFilterDate
          ? dayjs(filter.dateFilterFinishDate).format('YYYY-MM-DD')
          : isFilterUserResponseDate
          ? ''
          : dayjs().format('YYYY-MM-DD'),
        userResponseDateStart: isFilterUserResponseDate
          ? dayjs(filter.userResponseDateStartDate).format('YYYY-MM-DD')
          : '',
        userResponseDateEnd: isFilterUserResponseDate
          ? dayjs(filter.userResponseDateFinishDate).format('YYYY-MM-DD')
          : '',
        courseName: isFilterCourseName ? filter.courseName : '',
        trainingDateStartDate: isFilterTrainingDate
          ? dayjs(filter.trainingDateStartDate).format('YYYY-MM-DD')
          : '',
        trainingDateFinishDate: isFilterTrainingDate
          ? dayjs(filter.trainingDateFinishDate).format('YYYY-MM-DD')
          : '',
        trainerName: isFilterTrainerName ? filter.trainerName : '',
        distribution: isFilterDistribution ? filter.distribution : '',
        courseCode: isFilterCourseCode ? filter.courseCode : '',
        department: isFilterDepartment ? filter.department : '',
        station: isFilterStation ? filter.station : '',
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
        isFilterUserResponseDate: isFilterUserResponseDate,
        isFilterCourseName,
        isFilterTrainingDate,
        isFilterTrainerName,
        isFilterDistribution,
        isFilterCourseCode,
        isFilterDepartment,
        isFilterStation,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const fetchCourseName = async (eEvaluationVersionUuid) => {
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: reportConfig.modulePath,
      body: {
        columnSelected: 'courseNameEN',
        moduleId: reportConfig.moduleId,
        eEvaluationVersionUuid: eEvaluationVersionUuid,
      },
    }),
  )
  const results = _.get(response, 'results', []).map((item) => {
    const type = _.get(courseNameType, item?.type, item?.type)
    return {
      ...item,
      type: type ?? '',
    }
  })
  return _.orderBy(results, 'type')
}

export const fetchCourseCode = async (eEvaluationVersionUuid) => {
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: reportConfig.modulePath,
      body: {
        columnSelected: 'courseCode',
        moduleId: reportConfig.moduleId,
        eEvaluationVersionUuid: eEvaluationVersionUuid,
      },
    }),
  )
  const results = _.get(response, 'results', []).map((item) => {
    const type = _.get(courseNameType, item?.type, item?.type)
    return {
      ...item,
      type: type ?? '',
    }
  })
  return results
}

export const fetchFilterOptions = async (eEvaluationVersionUuid) => {
  const courseNameList = await fetchCourseName(eEvaluationVersionUuid)
  store.dispatch(
    setFieldValue({
      key: 'filterList.courseName',
      value: courseNameList,
    }),
  )

  const courseCodeList = await fetchCourseCode(eEvaluationVersionUuid)
  store.dispatch(
    setFieldValue({
      key: 'filterList.courseCode',
      value: courseCodeList,
    }),
  )

  const departmentList = await axios({
    method: 'get',
    url: departmentAll,
  }).then((results) => {
    return _.map(_.get(results, 'data', []), (dt) => {
      return { value: _.get(dt, 'uuid'), label: _.get(dt, 'department') }
    })
  })

  store.dispatch(
    setFieldValue({
      key: 'filterList.department',
      value: departmentList,
    }),
  )

  const stationList = (await fetchStationList()).map((item) => {
    return {
      ...item,
      value: item.name,
    }
  })
  store.dispatch(
    setFieldValue({
      key: 'filterList.station',
      value: stationList,
    }),
  )

  const bodyTrainerName = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    agentCode: '',
    firstNameTH: '',
    lastNameTH: '',
    jobTitle: '',
    department: '',
    station: '',
    reportTo: '',
    position: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  let trainerName = []
  await callAxios
    .post(filterStaffProfile, bodyTrainerName)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const firstName = _.get(item, 'firstNameTH', '')
        const lastName = _.get(item, 'lastNameTH', '')
        const fullName = firstName + ' ' + lastName
        const email = _.get(item, 'email', '')
        const tel = _.get(item, 'tel', '')
        const prefixTH = _.get(item, 'prefixTH', '')
        trainerName.push({
          value: uuid,
          label: fullName,
          firstName: firstName,
          lastName: lastName,
          email: email,
          tel: tel,
          prefixTH: prefixTH,
        })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList.trainerName',
      value: trainerName,
    }),
  )
  const distribution = []
  const bodyDistribution = {
    abbreviation: '',
    distribution: '',
    level: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  await callAxios
    .post(distributionChannelFilter, bodyDistribution)
    .then(({ data }) => {
      _.get(data, 'result', []).forEach((d) => {
        distribution.push({
          label: d.distribution || '',
          title: d.distribution || '',
          value: d.uuid || '',
          uuid: d.uuid || '',
          id: d.id || '',
        })
      })
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList.distribution',
      value: distribution,
    }),
  )
}

export const handleSelectDate = (selectedDates, filter, setFilter, item) => {
  const objState = {
    ...filter,
    [item.dateStateStart]: selectedDates.startDate,
    [item.dateStateFinish]: selectedDates.endDate,
    [item.dateStateDisplay]: selectedDates.display,
    [item.nameInput]: selectedDates.startDate,
  }
  setFilter(objState)
}

export const handleSelectMultiple = (list, filter, setFilter, nameInput) => {
  let objState = {
    ...filter,
    [`${nameInput}List`]: list,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateFilterChecked: filter.userResponseDateChecked
        ? false
        : defaultFilter.dateFilterChecked,
      dateFilterStartDate: filter.userResponseDateChecked
        ? ''
        : defaultFilter.dateFilterStartDate,
      dateFilterFinishDate: filter.userResponseDateChecked
        ? ''
        : defaultFilter.dateFilterEndDate,
      dateFilterDisplayDate: filter.userResponseDateChecked
        ? false
        : defaultFilter.dateFilterDisplayDate,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: false,
      station: undefined,
    }
  } else if (key === 'userResponseDate') {
    objState = {
      ...objState,
      userResponseDateChecked: false,
      userResponseDateStartDate: '',
      userResponseDateFinishDate: '',
      userResponseDateDisplayDate: false,
    }
  }

  if (!objState.dateFilterChecked && !objState.userResponseDateChecked) {
    objState = {
      ...objState,
      dateFilterChecked: defaultFilter.dateFilterChecked,
      dateFilterStartDate: defaultFilter.dateFilterStartDate,
      dateFilterFinishDate: defaultFilter.dateFilterEndDate,
      dateFilterDisplayDate: defaultFilter.dateFilterDisplayDate,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}

export const setFilterMemoSelectDropdown = async ({
  filterMemo,
  arrayCheckbox,
}) => {
  const { filterList } = store.getState().crud
  await arrayCheckbox.map((item) => {
    if (['select_dropdown'].includes(item.type)) {
      let list = item?.list ?? []
      if (filterList?.[item.nameInput]) {
        list = filterList?.[item.nameInput]
      }
      const value = _.get(filterMemo, item.nameInput, '')
      const findValue = list.find((itemList) => itemList.value === value)
      store.dispatch(
        setFieldValue({
          key: `formData.${item.nameInput}-filter-dropdown`,
          value: findValue ?? '',
        }),
      )
    }
  })
}

export const clearSelectDropdown = async ({ arrayCheckbox }) => {
  await arrayCheckbox.map((item) => {
    if (['select_dropdown'].includes(item.type)) {
      store.dispatch(
        setFieldValue({
          key: `formData.${item.nameInput}-filter-dropdown`,
          value: '',
        }),
      )
    }
  })
}
