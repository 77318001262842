import dayjs from 'dayjs'
import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RestoreTwoTone from '@mui/icons-material/RestoreTwoTone'
import * as StyledForm from '../Styled'
import { BoxIconHistory } from './Styled'
import { setChangeSetting } from '../../../../../redux/slices/eTestingForm'

export const mapStatusTesting = (status) => {
  let result = 'ฉบับร่าง'

  switch (status) {
    case 'WAITING':
      result = 'รอเปิดใช้งาน'
      break
    case 'ACTIVE':
      result = 'เปิดใช้งาน'
      break
    case 'INACTIVE':
      result = 'ปิดใช้งาน'
      break
    case 'DELETE':
      result = 'ถูกลบแล้ว'
      break
    default:
      break
  }
  return result
}

export const handleChangeInfo = (props) => (dispatch) => {
  const { key, value } = props
  dispatch(
    setChangeSetting({
      key,
      value:
        key === 'startDate'
          ? dayjs(value).format(window.__env__.REACT_APP_DATE_DB) ===
            'Invalid Date'
            ? null
            : dayjs(value).format(window.__env__.REACT_APP_DATE_DB)
          : value,
    })
  )
}

export const BoxInfo = ({ labelText, labelValue, isIcon, onChangeHistory }) => {
  return (
    <>
      <StyledForm.BoxFlex isIcon={isIcon}>
        <Typography variant="body1" sx={{ width: 150 }}>
          {labelText}
        </Typography>
        <BoxIconHistory>
          <StyledForm.TypographyLabel variant="body1">
            {labelValue}
          </StyledForm.TypographyLabel>
          {isIcon && (
            <Box sx={{ ml: 'auto' }} onClick={onChangeHistory}>
              <IconButton>
                <RestoreTwoTone color="action" />
              </IconButton>
            </Box>
          )}
        </BoxIconHistory>
      </StyledForm.BoxFlex>
    </>
  )
}

export const mapAssignee = (listAssignee) => {
  let result = []
  if (listAssignee.length) {
    result = listAssignee.map((item) => {
      return {
        ...item,
        label: item.label || item.name,
        value: item.uuid,
      }
    })
  }

  return result
}

export const checkDateDisabled = (date, dateServer) => {
  // if(status === 'ACTIVE') return date
  return date > dateServer ? dateServer : date
}
