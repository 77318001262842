import {
  setChangeQuestionTypeOther,
  setChangeQuestionByKey,
  setChangeSubQuestionByKey,
} from '../../../../../../../redux/slices/eEvaluationForm'

export const handleChangeQuestion = (props) => (dispatch) => {
  props.isSubQuestions
    ? dispatch(setChangeSubQuestionByKey(props))
    : dispatch(setChangeQuestionByKey(props))
}

export const handleChangeTypeQuestion = (props) => (dispatch) => {
  dispatch(setChangeQuestionTypeOther(props))
}
