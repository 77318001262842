import { breadcrumb, breadcrumbReturn } from './model/breadcrumb'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { useSelector } from 'react-redux'
import { crudConfig, crudConfig as initialCrudConfig } from '../crudConfig'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { CPageViewContainer } from '../../../../components/CRUD/components/PageViewContainer'
import { ActionComponent } from './components/ViewHeaderAction'
import { ViewContent } from './Content'
import CHistoryDrawer from '../../../../components/CRUD/components/History'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { getBodyMutation } from './mutation/get'
import { CPageContainer } from '../../../../components/CRUD/components/PageContainer'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import { AssetList } from './components/AssetList'
import { viewTypeEnum } from './enum/viewTypeEnum'
import NotReturnDrawer from './Drawer/NotReturnForm'
import { setAssets } from './handler/setAssets'
import { RequisitionStatus } from '../../../../constants/stock'
import { getQueryParams } from '../../../../utils/lib'
import { handleViewUpdatedAt } from '../../utils'

export const InventoryRequisitionView = () => {
  const { uuid } = useParams()
  const { formData, isLoading, viewType, isOpenFormDrawer } = useSelector(
    (state) => state.crud,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation).then((detail) => {
        demoData(detail)
        handleViewUpdatedAt()
        setAssets()
        const isReturnForm = getQueryParams('isReturnForm')
        if (
          detail.status === RequisitionStatus.WAITING_RETURN_APPROVE &&
          isReturnForm
        ) {
          dispatch(
            setFieldValue({
              key: 'viewType',
              value: viewTypeEnum.RETURN,
            }),
          )
        }
      })
    } else {
      demoData()
    }
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  const demoData = (detail = {}) => {
    //set ViewPageType
    //[TICKET, WAITING, APPROVED, RETURN, WAITING_RETURN_APPROVE, RETURN_APPROVED]
    const status =
      viewTypeEnum?.[_.get(detail, 'status', viewTypeEnum.TICKET)] ??
      viewTypeEnum.TICKET
    dispatch(
      setFieldValue({
        key: 'viewType',
        value: status,
      }),
    )
  }

  return (
    <>
      <CPageViewContainer
        isLoading={isLoading}
        header={
          <CHeader
            title={`${
              viewType === viewTypeEnum.RETURN
                ? 'คืนทรัพย์สิน'
                : 'รายละเอียด Ticket'
            }`}
            breadcrumbs={
              viewType === viewTypeEnum.RETURN ? breadcrumbReturn : breadcrumb
            }
            action={<ActionComponent formData={formData} uuid={uuid} />}
          />
        }
        content={<ViewContent />}
      />

      <CPageContainer gap="3" content={<AssetList />} />

      <CPageContainer gap="3" content={<UpdatedComponent />} />

      {isOpenFormDrawer && <NotReturnDrawer />}

      <CHistoryDrawer />
    </>
  )
}