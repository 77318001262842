import { theme } from '../../../../../utils/theme'

export const enumRequestType = {
  E_BOOKING: 'E_BOOKING',
  MONTHLY_PLAN: 'MONTHLY_PLAN',
  MANAGE_CLASS: 'MANAGE_CLASS',
}
export const dayList = [
  'จันทร์',
  'อังคาร',
  'พุธ',
  'พฤหัสบดี',
  'ศุกร์',
  'เสาร์',
  'อาทิตย์',
]

export const requestType = [
  {
    value: enumRequestType.MONTHLY_PLAN,
    label: 'Monthly plan',
    color: theme.palette?.warning?.main,
    background: theme.palette?.warning?.outlinedHoverBackground,
  },
  {
    value: enumRequestType.MANAGE_CLASS,
    label: 'Manage Class',
    color: '#069E93',
    background: '#ECFBFA',
  },
  {
    value: enumRequestType.E_BOOKING,
    label: 'E-Booking',
    color: theme.palette?.primary?.main,
    background: theme.palette?.blue?.blueLine,
  },
  {
    value: 'HOLIDAY',
    label: 'Holiday',
    color: theme.palette?.success?.main,
    background: theme.palette?.success?.outlinedHoverBackground,
  },
]
