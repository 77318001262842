export const MONTH_LIST = [
  { label: 'มกราคม', value: 0 },
  { label: 'กุมภาพันธ์', value: 1 },
  { label: 'มีนาคม', value: 2 },
  { label: 'เมษายน', value: 3 },
  { label: 'พฤษภาคม', value: 4 },
  { label: 'มิถุนายน', value: 5 },
  { label: 'กรกฎาคม', value: 6 },
  { label: 'สิงหาคม', value: 7 },
  { label: 'กันยายน', value: 8 },
  { label: 'ตุลาคม', value: 9 },
  { label: 'พฤศจิกายน', value: 10 },
  { label: 'ธันวาคม', value: 11 },
]
export const WORK_WORK_LIST = [
  'Holiday',
  'Leave',
  'Academy Activity',
  'Training',
  'Activity Support',
  'Office Work',
]
export const LEAVE_LIST = [
  'Annual Leave',
  'Birthday Leave',
  'Family Care Leave',
  'Personal Leave',
  'Sick Leave',
  'Other Leave',
  'Day Off',
]

export const SUB_WORK_TYPE_ACADEMY = [
  'Office Work',
  'External Training',
  'Academy Training',
  'Certified',
  'Travelling',
  'Meeting',
  'Other Activity',
]

export const WORK_WORK_JSON = {
  Holiday: { class: 'holiday' },
  Leave: {
    class: 'leave',
    classLeave: {
      'Annual Leave': { class: 'annual-leave' },
      'Birthday Leave': { class: 'birthday-leave' },
      'Family Care Leave': { class: 'family-care-leave' },
      'Personal Leave': { class: 'personal-leave' },
      'Sick Leave': { class: 'sick-leave' },
      'Other Leave': { class: 'other-leave' },
      'Day Off': { class: 'day-off-leave' },
    },
  },
  'Academy Activity': { class: 'academy-activity' },
  Training: { class: 'training-other' },
  'Activity Support': { class: 'training-other' },
  'Academy Activity Borrow': { class: 'academy-activity-borrow' },
}

export const LIST_TYPE_DATE = [
  {
    label: 'เต็มวัน',
    value: 'FULL',
  },
  {
    label: 'ครึ่งเช้า',
    value: 'MORNING',
  },
  {
    label: 'ครึ่งบ่าย',
    value: 'AFTERNOON',
  },
]
export const LIST_TYPE_TRAINING = [
  {
    label: 'เต็มวัน',
    value: 'FULL',
  },
  {
    label: 'ครึ่งเช้า',
    value: 'MORNING',
  },
  {
    label: 'ครึ่งบ่าย',
    value: 'AFTERNOON',
  },
  {
    label: 'อื่นๆ',
    value: 'OTHER',
  },
]

export const MESSAGE_HOLIDAY = [
  {
    message: 'กรุณาเลือกประเภทตารางงานในช่องนี้',
    value: 'workType',
  },
  {
    message: 'กรุณาเลือกประเภทวันหยุดในช่องนี้',
    value: 'holidayType',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'startDate',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'finishDate',
  },
  {
    message: 'กรุณากรอกวันหยุดในช่องนี้',
    value: 'dayOff',
  },
  {
    message: 'กรุณากรอกหมายเหตุในช่องนี้',
    value: 'remark',
  },
]

export const MESSAGE_LEAVE = [
  {
    message: 'กรุณาเลือกประเภทตารางงานในช่องนี้',
    value: 'workType',
  },
  {
    message: 'กรุณาเลือกประเภทวันลาในช่องนี้',
    value: 'subWorkType',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'startDate',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'finishDate',
  },
  {
    message: 'กรุณากรอกเหตุผลการลาในช่องนี้',
    value: 'remarkLeave',
  },
  {
    message: 'กรุณากรอกหมายเหตุในช่องนี้',
    value: 'remark',
  },
  {
    message: 'กรุณาเลือกผู้ลาในช่องนี้',
    value: 'staff',
  },
]

export const MESSAGE_ACADEMY_ACTIVITY = [
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'startDate',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'finishDate',
  },
  {
    message: 'กรุณาเลือกเวลาในช่องนี้',
    value: 'startTime',
  },
  {
    message: 'กรุณาเลือกเวลาในช่องนี้',
    value: 'endTime',
  },
  {
    message: 'กรุณากรอก Link ห้องประชุมออนไลน์ในช่องนี้',
    value: 'link',
  },
  {
    message: 'กรุณาเลือกห้องประชุมในช่องนี้',
    value: 'selectOnsite',
  },
  {
    message: 'กรุณากรอกหัวข้อในช่องนี้',
    value: 'title',
  },
  {
    message: 'กรุณาเลิอก TO ผู้รับจองห้องประชุมในช่องนี้',
    value: 'toMeetingRoom',
  },
  {
    message: 'กรุณาเลิอกห้องประชุมในช่องนี้',
    value: 'meetingRoom',
  },
  {
    message: 'กรุณาเลือกผู้เข้าร่วมประชุมในช่องนี้',
    value: 'attendees',
  },
  {
    message: 'กรุณาเลือกวิทยากรในช่องนี้',
    value: 'staff',
  },
  {
    message: 'กรุณาเลือกหน้าที่ในช่องนี้',
    value: 'speakerRole',
  },
  {
    message: 'กรุณาเลือกเวลา',
    value: 'time',
  },
  {
    message: 'กรุณาเลือกหลักสูตรอบรมในช่องนี้',
    value: 'course',
  },
]

export const MESSAGE_ACADEMY_TRAINING = [
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'startDate',
  },
  {
    message: 'กรุณาเลือกวันที่ให้ถูกต้อง',
    value: 'finishDate',
  },
  {
    message: 'กรุณาเลือกเวลาในช่องนี้',
    value: 'startTime',
  },
  {
    message: 'กรุณาเลือกเวลาในช่องนี้',
    value: 'endTime',
  },
  {
    message: 'กรุณาเลือกห้องประชุมในช่องนี้',
    value: 'selectOnsite',
  },
  {
    message: 'กรุณาเลิอกห้องประชุมในช่องนี้',
    value: 'meetingRoom',
  },
  {
    message: 'กรุณาเลือกรูปแบบการจัดห้องเรียนในช่องนี้',
    value: 'classroom',
  },
  {
    message: 'กรุณาเลือกวิทยากรในช่องนี้',
    value: 'staff',
  },
  {
    message: 'กรุณาเลือกหน้าที่ในช่องนี้',
    value: 'responsibility',
  },
  {
    message: 'กรุณาเลือกเวลา',
    value: 'time',
  },
  {
    message: 'กรุณาเลือกหลักสูตรอบรมในช่องนี้',
    value: 'course',
  },
  {
    message: 'กรุณากรอกจำนวนคนที่คาดหวังในช่องนี้',
    value: 'expectNumberPeople',
  },
  {
    message: 'กรุณากรอกค่าใช้จ่ายต่อหัวในช่องนี้',
    value: 'cost',
  },
  {
    message: 'กรุณากรอกรายละเอียดเพิ่มเติมในช่องนี้',
    value: 'remarkCost',
  },
  {
    message: 'กรุณาเลือกช่องทางการจำหน่ายในช่องนี้',
    value: 'distributionChannel',
  },
  {
    message: 'กรุณาเลือกรูปแบบการสอนในช่องนี้',
    value: 'trainingPlatform',
  },
]

export const MESSAGE_FROM_DIALOG = [
  {
    message: 'กรุณาเลือก Department ในช่องนี้',
    value: 'department',
  },
  {
    message: 'กรุณาเลือก Station ในช่องนี้',
    value: 'station',
  },
  {
    message: 'กรุณากรอกจุดประสงค์ของการยืมในช่องนี้',
    value: 'purpose',
  },
  {
    message: 'กรุณาเลือกหน้าที่ในช่องนี้',
    value: 'responsibility',
  },
  {
    message: 'กรุณากรอกจำนวนวิทยากรที่ต้องการในช่องนี้',
    value: 'numSpeaker',
  },
  {
    message: 'กรุณากรอก Skillที่ต้องการในช่องนี้',
    value: 'skillSpeaker',
  },
  {
    message: 'กรุณาเลือกเวลาเริ่มในช่องนี้',
    value: 'startTime',
  },
  {
    message: 'กรุณาเลือกเวลาสิ้นสุดในช่องนี้',
    value: 'endTime',
  },
]

export const MESSAGE_APPROVE = [
  {
    message: 'กรุณาเลือกวิทยากร',
    value: 'staff',
  },
  {
    message: 'กรุณากรอกหมายเหตุในช่องนี้',
    value: 'remark',
  },
]

export const DEFAULT_DIALOG_VALUE = {
  approver: [],
  department: '',
  station: '',
  purpose: '',
  responsibility: '',
  numSpeaker: '',
  skillSpeaker: '',
  startDate: Date.now(),
  finishDate: Date.now(),
  startTime: '',
  endTime: '',
  remark: '',
}
