import localforage from 'localforage'
import { downloadTemplate } from '../../../../services/eTesting/form'
import { openDialog } from '../../../../redux/slices/dialog'
import AnnounceExaminerDialog from '../AnnounceExaminerDialog'
import EligibleExaminerDialog from '../EligibleExaminerDialog'

export const onClickAddTest = (option, history) => {
  localforage.removeItem('persist:root')
  history.push(option.link)
}

export const onDownloadTemplate = () => async (dispatch) => {
  await dispatch(downloadTemplate())
}

export const handleAnnounceExaminer = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      disableButton: true,
      content: <AnnounceExaminerDialog />,
    }),
  )
}

export const handleEligibleExaminer = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      disableButton: true,
      content: <EligibleExaminerDialog />,
    }),
  )
}
