import React, { useEffect } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
// import _ from 'lodash'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'
import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../utils/lib'
import DateRangeInput from '../../components/DateRangeInput'

const FilterDrawer = ({
  open,
  onClose,
  onFilterClick,
  filter,
  setFilter,
  defaultFilter,
}) => {
  const [user] = useLocalStorage('user')

  const getDateState = () => {
    return {
      startDate: filter.startDate ? filter.startDate : new Date(),
      endDate: filter.endDate ? filter.endDate : new Date(),
      display:
        filter.startDate != '' && filter.endDate != ''
          ? true
          : _.get(filter, 'displayDate', false),
      key: 'selection',
    }
  }

  const handleSelectDate = (selectedDates) => {
    const objState = {
      ...filter,
      startDate: selectedDates.startDate,
      endDate: selectedDates.endDate,
      display: selectedDates.display,
    }

    setFilter(objState)
  }

  const handleChangeArrayByField = (e, field) => {
    const { name, checked } = e.target

    setFilter({
      ...filter,
      [field]: {
        ...filter[field],
        [name]: checked,
      },
    })
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }
  useEffect(() => {
    if (filter) {
      setFilter(filter)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  const hasPermissionHF = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_MP'],
  })

  const hasPermissionHZ = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_ALL_EXC_HOLIDAY'],
  })

  const hasPermissionTrainer = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_LEAVE'],
  })

  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['EDIT_ALL_CLASS'],
  })

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'startDateChecked',
                }}
                name="startDateChecked"
                checked={filter.startDateChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'startDateChecked',
                  })
                }}
              />
            }
            label="วันที่รับ Assign งาน"
            sx={!filter.startDateChecked ? { marginBottom: -1 } : {}}
          />
          {filter.startDateChecked && (
            <>
              <DateRangeInput
                dateState={getDateState()}
                onChange={(selectedDates) => {
                  handleSelectDate(selectedDates)
                }}
              />
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="หมวดหมู่งาน"
          control={
            <Checkbox
              data-testid="checkbox-speaker-status"
              name="workTypeChecked"
              checked={filter.workTypeChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'workTypeChecked',
                })
              }}
            />
          }
        />
        {filter.workTypeChecked && (
          <>
            {(hasPermissionHF || hasPermissionHZ) && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  label="Monthly Plan"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="monthlyPlanChecked"
                      checked={filter.workType.monthlyPlanChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'workType')}
                    />
                  }
                />
              </Box>
            )}
            {(hasPermissionTrainer || hasPermissionAdmin) && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="Manage Class"
                    control={
                      <Checkbox
                        data-testid="checkbox-status-waiting"
                        name="manageClassChecked"
                        checked={filter.workType.manageClassChecked}
                        onChange={(e) =>
                          handleChangeArrayByField(e, 'workType')
                        }
                      />
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="E-Testing"
                    control={
                      <Checkbox
                        data-testid="checkbox-status-waiting"
                        name="eTestingChecked"
                        checked={filter.workType.eTestingChecked}
                        onChange={(e) =>
                          handleChangeArrayByField(e, 'workType')
                        }
                      />
                    }
                  />
                </Box>
              </>
            )}
          </>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="ชื่องาน"
          control={
            <Checkbox
              data-testid="checkbox-speaker-status"
              name="taskNameChecked"
              checked={filter.taskNameChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'taskNameChecked',
                })
              }}
            />
          }
        />
        {filter.taskNameChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {hasPermissionHF && (
              <>
                <FormControlLabel
                  label="อนุมัติแผนงาน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="approveMonthlyPlanChecked"
                      checked={filter.taskName.approveMonthlyPlanChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="อนุมัติแผนรายเดือน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-reject"
                      name="approveMasterPlanChecked"
                      checked={filter.taskName.approveMasterPlanChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
              </>
            )}
            {hasPermissionHZ && (
              <>
                <FormControlLabel
                  label="อนุมัติคำขอยืมวิทยากรโซนอื่น"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-reject"
                      name="requestSpeakerBorrowChecked"
                      checked={filter.taskName.requestSpeakerBorrowChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="คำของบ Break/Lunch ถูกปฏิเสธ"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-reject"
                      name="disapproveBuggetChecked"
                      checked={filter.taskName.disapproveBuggetChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ไม่อนุมัติแผนงาน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-reject"
                      name="disapproveMonthlyPlanChecked"
                      checked={filter.taskName.disapproveMonthlyPlanChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ไม่อนุมัติแผนรายเดือน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-reject"
                      name="disapproveMasterPlanChecked"
                      checked={filter.taskName.disapproveMasterPlanChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
              </>
            )}
            {hasPermissionTrainer && (
              <>
                <FormControlLabel
                  label="ตรวจข้อสอบ"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="checkExamChecked"
                      checked={filter.taskName.checkExamChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ข้อสอบ Assessment"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="assignmentTestChecked"
                      checked={filter.taskName.assignmentTestChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ปิดคลาส"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="closeClassChecked"
                      checked={filter.taskName.closeClassChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ตรวจข้อสอบ Standalone"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="checkTestStandaloneChecked"
                      checked={filter.taskName.checkTestStandaloneChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
              </>
            )}
            {hasPermissionAdmin && (
              <>
                <FormControlLabel
                  label="ประกาศรายชื่อผู้มีสิทธิ์เรียน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="announceStudentChecked"
                      checked={filter.taskName.announceStudentChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ตรวจเอกสาร"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="checkDocumentChecked"
                      checked={filter.taskName.checkDocumentChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                <FormControlLabel
                  label="ตั้งค่าคลาส"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-waiting"
                      name="settingClassChecked"
                      checked={filter.taskName.settingClassChecked}
                      onChange={(e) => handleChangeArrayByField(e, 'taskName')}
                    />
                  }
                />
                {!hasPermissionTrainer && (
                  <FormControlLabel
                    label="ปิดคลาส"
                    control={
                      <Checkbox
                        data-testid="checkbox-status-waiting"
                        name="closeClassChecked"
                        checked={filter.taskName.closeClassChecked}
                        onChange={(e) =>
                          handleChangeArrayByField(e, 'taskName')
                        }
                      />
                    }
                  />
                )}
              </>
            )}
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              data-testid="checkbox-speaker-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              label="ยังไม่ได้ทำ"
              control={
                <Checkbox
                  data-testid="checkbox-status-waiting"
                  name="toDoChecked"
                  checked={filter.status.toDoChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="กำลังทำ"
              control={
                <Checkbox
                  data-testid="checkbox-status-reject"
                  name="onWorkingChecked"
                  checked={filter.status.onWorkingChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="เสร็จสิ้น"
              control={
                <Checkbox
                  data-testid="checkbox-status-approve"
                  name="doneChecked"
                  checked={filter.status.doneChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />

            {(hasPermissionHF || hasPermissionHZ) && (
              <FormControlLabel
                label="ยกเลิก"
                control={
                  <Checkbox
                    data-testid="checkbox-status-cancel"
                    name="cancelChecked"
                    checked={filter.status.cancelChecked}
                    onChange={(e) => handleChangeArrayByField(e, 'status')}
                  />
                }
              />
            )}
          </Box>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          onClick={() => {
            onClose()
            onFilterClick(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
