import { store } from "../../../../App"
import { setFieldValue } from "../../../../redux/slices/crud"

export const handleClickTopRank = (type) => {
  store.dispatch(setFieldValue({
    key: 'apiRcmsDashboard.topDrawer.drawerType',
    value: type,
  }))
  toggleTopDrawer(true)
}

export const toggleTopDrawer = (value) => {
  store.dispatch(setFieldValue({
    key: 'apiRcmsDashboard.topDrawer.isOpen',
    value: value,
  }))
}