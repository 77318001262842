import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getUserAgent,
  getUserAgentDetail,
  getUserProfile,
  postAddLearner,
  postUploadLearner,
} from './../../../services/manageClass/form'
import {
  announceLearner,
  getLearnerRoom,
  putManageClassLearnerCancel,
} from './../../../services/manageClass/learnerDetail'
import { initialState } from './model'

const manageClassLearner = createSlice({
  name: 'manageClassLearner',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setFormType: (state, { payload }) => {
      state.formType = payload
    },
    setUserType: (state, { payload }) => {
      state.userType = payload
    },
    setUserProfile: (state, { payload }) => {
      const userProfile = state.userProfileList.find(
        (item) => item.uuid === payload
      )
      state.userProfile[0] = userProfile
    },
    setStaffProfile: (state, { payload }) => {
      const staffProfile = state.userProfileList.find(
        (item) => item.uuid === payload
      )
      state.staffProfile[0] = staffProfile
    },
    setImportFile: (state, { payload }) => {
      const file = payload.target.files[0]
      const fileName = _.get(payload, 'target.files[0].name', 'No File Chosen')
      state.fileName = fileName
      state.file = file
    },
    setRemark: (state, { payload }) => {
      state.remark = payload
    },
    setDateRageList: (state, { payload }) => {
      state.dateRangeList = payload
    },
    setUserAgentList: (state, { payload }) => {
      state.userAgentList = state.userAgentList.filter(
        (item) => item.agentCode == payload
      )
    },
    resetUserAgentList: (state) => {
      state.userAgentList = []
    },
    resetUserProfile: (state) => {
      state.userProfile = []
    },
    resetStaffProfile: (state) => {
      state.staffProfile = []
    },
    resetForm: (state) => {
      state.userType = ''
      state.userProfileList = []
      state.userAgentList = []
      state.userProfile = []
      state.staffProfile = []
      state.fileName = 'No File Chosen'
      state.file = null
      state.remark = ''
      state.isSuccess = false
    },
    resetFile: (state) => {
      state.file = null
      state.fileName = 'No File Chosen'
    },
    setUploadForm: (state, { payload }) => {
      state.uploadResult = payload
    },
    setProcessAgent: (state, { payload }) => {
      state.success = 0
      state.processAgent = _.get(payload, 'processAgent')
      state.total = _.get(payload, 'total')
    },
    setProcess: (state, { payload }) => {
      state.success = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userProfileList = data
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getUserAgent.pending, (state, { meta }) => {
        state.isAgentLoading = true
        state.userAgentList = [{ agentCode: _.get(meta.arg, 'userCode', '') }]
      })
      .addCase(getUserAgent.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userAgentList = data
        state.isAgentLoading = false
      })
      .addCase(getUserAgent.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isAgentLoading = false
      })
      .addCase(getUserAgentDetail.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userProfile = data.map((user) => ({
          ...user,
          uuid: user.userCode,
          firstNameTH: user.firstName,
          lastNameTH: user.lastName,
          mobileNo: user.mobilePhone,
        }))
      })
      .addCase(getUserAgentDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postAddLearner.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(postAddLearner.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postUploadLearner.fulfilled, (state, { payload }) => {
        state.uploadResult = _.get(payload, 'data', '')
      })
      .addCase(postUploadLearner.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(putManageClassLearnerCancel.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(putManageClassLearnerCancel.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(announceLearner.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(getLearnerRoom.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.meetingRoomList = data.map((room) => ({
          label: room,
          value: room,
        }))
      })
      .addCase(getLearnerRoom.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setFormType,
  setUserType,
  setUserProfile,
  setStaffProfile,
  setImportFile,
  setRemark,
  setDateRageList,
  setUserAgentList,
  resetUserAgentList,
  resetUserProfile,
  resetStaffProfile,
  resetForm,
  resetFile,
  setUploadForm,
  setProcessAgent,
  setProcess,
} = manageClassLearner.actions

export default manageClassLearner.reducer
