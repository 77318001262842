import {
  closeDrawer,
  setChangeFiler,
  setClearFilterCalendar,
} from '../../../../redux/slices/manageClassCalendar'

export const closeFilterDrawer = () => (dispatch) => {
  dispatch(closeDrawer())
}

export const clearFilterCalendar = () => (dispatch) => {
  dispatch(setClearFilterCalendar())
}

export const checkedFilter = (props) => (dispatch) => {
  dispatch(setChangeFiler(props))
}
