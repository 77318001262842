export const FORM_TAB = {
  overview: 'overview',
  testerList: 'testerList',
  testList: 'testList',
}

export const FORM_TAB_TH = {
  overview: 'ภาพรวม',
  testerList: 'รายชื่อผู้ทำข้อสอบ',
  testList: 'รายละเอียดรายข้อ',
}
