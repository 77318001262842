import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { getQty, handleClickButton } from './events'

const ActionCart = ({ row }) => {
  const dispatch = useDispatch()
  const { cart, remaining } = useSelector(
    (state) => ({
      cart: state.agentProfile.pointDialog.cart,
      remaining: state.agentProfile.pointDialog.remaining,
    }),
    shallowEqual,
  )
  const qty = getQty(cart, row.uuid)
  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid #00008F80',
        borderRadius: '4px',
        width: '81px',
        minWidth: '81px',
      }}
    >
      <Typography
        variant="body2"
        color={qty > 0 ? 'primary.main' : 'action.disabled'}
        sx={{
          width: '33%',
          textAlign: 'center',
          cursor: qty > 0 ? 'pointer' : 'not-allowed',
          background: qty > 0 ? '' : '#F5F5F5',
        }}
        onClick={() => {
          if (qty > 0) {
            dispatch(handleClickButton({ cart, row, action: 'minus' }))
          }
        }}
      >
        -
      </Typography>
      <Typography
        variant="body2b"
        color="primary.main"
        sx={{
          width: '34%',
          textAlign: 'center',
          borderLeft: '1px solid #00008F80',
          borderRight: '1px solid #00008F80',
        }}
      >
        {qty}
      </Typography>
      <Typography
        variant="body2"
        color={
          remaining >= row.point && qty < row.qty
            ? 'primary.main'
            : 'action.disabled'
        }
        sx={{
          width: '33%',
          textAlign: 'center',
          cursor:
            remaining >= row.point && qty < row.qty ? 'pointer' : 'not-allowed',
          background: remaining >= row.point && qty < row.qty ? '' : '#F5F5F5',
        }}
        onClick={() => {
          if (remaining >= row.point && qty < row.qty) {
            dispatch(handleClickButton({ cart, row, action: 'plus' }))
          }
        }}
      >
        +
      </Typography>
    </Box>
  )
}

export default ActionCart
