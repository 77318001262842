import _ from 'lodash'
import {
  setFilterProp,
  setOther,
  setSearchText,
} from '../../../../redux/slices/table'

export const handleRadioChange = (filter, field) => (dispatch) => {
  const payload = {
    myJob: { checked: false, count: 0 },
    highClass: { checked: false, count: 0 },
  }
  if (!_.isUndefined(field)) payload[field].checked = true
  dispatch(setOther(payload))
  dispatch(setFilterProp({ ...filter.filterProp, course: '' }))
  dispatch(setSearchText(''))
}
