import { store } from '../../../../../App'
import _ from 'lodash'
import { enumProductType } from '../../../../../modules/Inventory/constant'
import { STATUS } from '../model/status'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { tabActive } = store.getState().crud
  let productType = [enumProductType.STOCK, enumProductType.STOCK_ASSET]
  switch (tabActive) {
    case 0:
      break
    case 1:
      productType = [enumProductType.ASSET]
      break
    case 2:
      productType = [enumProductType.LICENSE]
      break
  }

  return {
    name: _.get(filterProp, 'name', ''),
    status: _.get(filterProp, 'status', [
      STATUS.ACTIVE,
      STATUS.INACTIVE,
      STATUS.DELETED,
    ]),
    search: _.get(filterProp, 'search', ''),
    availableFrom: _.get(filterProp, 'availableFrom', ''),
    availableTo: _.get(filterProp, 'availableTo', ''),
    productType,

    category: _.get(filterProp, 'category', ''),
    subCategory: _.get(filterProp, 'subCategory', ''),
    vendor: _.get(filterProp, 'vendor', ''),
    stockValueFrom: _.get(filterProp, 'stockValueFrom', ''),
    stockValueTo: _.get(filterProp, 'stockValueTo', ''),
    createdBy: _.get(filterProp, 'createdBy', ''),
    startCreatedDate: _.get(filterProp, 'createStartDate', ''),
    endCreatedDate: _.get(filterProp, 'createFinishDate', ''),
    startUpdatedDate: _.get(filterProp, 'updateStartDate', ''),
    endUpdatedDate: _.get(filterProp, 'updateFinishDate', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),

    station: _.get(filterProp, 'station', ''),
    stationList: _.get(filterProp, 'stationCheckedList', []),
    warehouse: _.get(filterProp, 'warehouse', ''),
    costCenter: _.get(filterProp, 'costCenter', ''),
    description: _.get(filterProp, 'description', ''),
    returnYear: _.get(filterProp, 'returnYear', ''),
    returnMonth: _.get(filterProp, 'returnMonth', ''),
    returnDay: _.get(filterProp, 'returnDay', ''),
    totalFrom: _.get(filterProp, 'totalFrom', ''),
    totalTo: _.get(filterProp, 'totalTo', ''),
    quantityAlertFrom: _.get(filterProp, 'quantityAlertFrom', ''),
    quantityAlertTo: _.get(filterProp, 'quantityAlertTo', ''),
    unitPriceFrom: _.get(filterProp, 'unitPriceFrom', ''),
    unitPriceTo: _.get(filterProp, 'unitPriceTo', ''),
    holder: _.get(filterProp, 'holder', ''),
  }
}
