import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import _ from 'lodash'

import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { convertTime, convertTimeToApi } from '../View/Index'
import { getExpandDate } from './Handle'

const Index = (props) => {
  const {
    state,
    stateForm,
    setStateForm,
    options,
    validateForm,
    setValidateForm,
    statePermission,
    type,
  } = props

  const [expandDate, setExpandDate] = useState(
    _.get(stateForm, 'academyActivity.expandDate.value', [])
  )
  const defaultExpandDate = {
    typeTime: 'CUSTOM',
    startTime: '',
    endTime: '',
  }
  useEffect(() => {
    getExpandDate(
      stateForm,
      setExpandDate,
      setStateForm,
      defaultExpandDate,
      true
    )
  }, [
    stateForm.academyActivity.startDate,
    stateForm.academyActivity.finishDate,
  ])

  const startDate = _.get(stateForm, 'academyActivity.startDate', null)

  return (
    <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography>รูปแบบการเดินทาง</Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        </Box>
        <RadioGroup
          sx={{ gap: 1, pl: 1, width: '200px' }}
          aria-label="travelType"
          defaultValue={_.get(
            stateForm,
            'academyActivity.travelType',
            'THISDAY'
          )}
          name="radio-buttons-group"
          onChange={(e) =>
            onChangeRadio(
              e,
              expandDate,
              validateForm,
              setStateForm,
              setValidateForm
            )
          }
        >
          <FormControlLabel
            value="FORWARD"
            control={<Radio />}
            label="เดินทางล่วงหน้า"
          />
          <FormControlLabel
            value="THISDAY"
            control={<Radio />}
            label="เดินทางภายในวันที่ไปสอน"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="วันที่เดินทาง"
            disabledDateRange={true}
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            isShouldDisableDate={true}
            required
            onChange={(value) =>
              onChangeDate(value, stateForm, setStateForm, setValidateForm)
            }
            value={startDate === 'Invalid Date' ? null : startDate}
            textError={_.get(validateForm, 'academyActivity.startDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.startDate', '')
                ? false
                : true
            }
          />
        </Box>
        <Box sx={{ width: '50%', display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              mx: 5,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="body2">เวลาเริ่ม</Typography>
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Box>

            <TimePicker
              id="startTime"
              onChange={(event) =>
                onChangeForm({
                  event,
                  key: 'startTime',
                  isArray: false,
                  date: dayjs(startDate).format(
                    window.__env__.REACT_APP_DATE_DB
                  ),
                  listExpandDate: _.get(
                    stateForm,
                    'academyActivity.expandDate',
                    []
                  ),
                  validateForm,
                  setStateForm,
                  setValidateForm,
                })
              }
              value={checkValueState(stateForm, 'startTime')}
              disabledStartTime={checkValueState(stateForm, 'startTime')}
              disabledEndTime={checkValueState(stateForm, 'endTime')}
              helperText={_.get(
                validateForm,
                'academyActivity.expandDate[0].startTime',
                ''
              )}
              disabled={
                _.get(stateForm, 'academyActivity.expandDate', []).length
                  ? false
                  : true
              }
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              mx: 5,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="body2">เวลาสิ้นสุด</Typography>
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Box>
            <TimePicker
              id="endTime"
              onChange={(event) =>
                onChangeForm({
                  event,
                  key: 'endTime',
                  isArray: false,
                  date: dayjs(startDate).format(
                    window.__env__.REACT_APP_DATE_DB
                  ),
                  listExpandDate: _.get(
                    stateForm,
                    'academyActivity.expandDate',
                    []
                  ),
                  validateForm,
                  setStateForm,
                  setValidateForm,
                })
              }
              value={checkValueState(stateForm, 'endTime')}
              disabledStartTime={checkValueState(stateForm, 'startTime')}
              disabledEndTime={checkValueState(stateForm, 'endTime')}
              helperText={_.get(
                validateForm,
                'academyActivity.expandDate[0].endTime',
                ''
              )}
              disabled={
                _.get(stateForm, 'academyActivity.expandDate', []).length
                  ? false
                  : true
              }
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextInput
          id="description"
          name="description"
          type="basic"
          labelText="รายละเอียดเพิ่มเติม"
          placeholder="รายละเอียดเพิ่มเติม"
          multiline={true}
          rows={3}
          onChange={(value) =>
            onChangeForm({
              event: value,
              key: 'description',
              isArray: false,
              validateForm,
              setStateForm,
              setValidateForm,
            })
          }
          value={stateForm.academyActivity.description}
          textError={_.get(validateForm, 'academyActivity.description', '')}
        />

        <SelectGroupChip
          id="attendees"
          name="attendees"
          required
          placeholder={'เลือกผู้รับผิดชอบงาน'}
          labelText="ผู้รับผิดชอบงาน"
          options={options.staff}
          onChange={(event) =>
            onChangeForm({
              event,
              key: 'attendees',
              isArray: true,
              validateForm,
              setStateForm,
              setValidateForm,
            })
          }
          value={_.get(stateForm, 'academyActivity.attendees', '')}
          textError={_.get(validateForm, 'academyActivity.attendees', '')}
          disabled={statePermission}
        />
      </Box>
    </Box>
  )
}
export default Index

export const onChangeRadio = (
  e,
  expandDate,
  validateForm,
  setStateForm,
  setValidateForm
) => {
  const defaultTime = {
    startTime: '',
    endTime: '',
  }
  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      travelType: e.target.value,
      expandDate: expandDate.length
        ? expandDate.map((item) => {
            return {
              ...item,
              ...defaultTime,
            }
          })
        : [],
    },
  }))
  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      expandDate: _.get(validateForm, 'academyActivity.expandDate', []).map(
        (item) => {
          return {
            ...item,
            ...defaultTime,
          }
        }
      ),
    },
  }))
}

export const onChangeDate = (
  value,
  stateForm,
  setStateForm,
  setValidateForm
) => {
  const dates = _.get(stateForm, 'academyActivity.expandDate', [])

  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      startDate: dayjs(value).format(window.__env__.REACT_APP_DATE_DB),
      finishDate: dayjs(value).format(window.__env__.REACT_APP_DATE_DB),
      expandDate: [
        dates.length
          ? {
              ...stateForm.academyActivity.expandDate[0],
              date: dayjs(value).format(window.__env__.REACT_APP_DATE_DB),
            }
          : {
              date: dayjs(value).format(window.__env__.REACT_APP_DATE_DB),
              typeTime: 'CUSTOM',
              startTime: '',
              endTime: '',
            },
      ],
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      startDate: '',
      finishDate: '',
    },
  }))
}

export const onChangeForm = ({
  event,
  key,
  isArray,
  date,
  listExpandDate,
  validateForm,
  setStateForm,
  setValidateForm,
}) => {
  let value = ''
  let valueValidate = ''

  const listValidateExpandDate = _.get(
    validateForm,
    'academyActivity.expandDate',
    []
  )

  if (isArray) {
    value = event
  } else {
    value = event.target.value
  }

  if (key === 'startTime' || key === 'endTime') {
    value = listExpandDate.length
      ? listExpandDate.map((item) => {
          let result = item
          if (item.date === date) {
            result = {
              ...item,
              [key]: convertTimeToApi(value),
            }
          }
          return result
        })
      : []

    valueValidate = listValidateExpandDate.map((item) => {
      let result = item
      if (item.date === date) {
        result = {
          ...item,
          [key]: '',
        }
      }
      return result
    })
    key = 'expandDate'
  }

  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: value,
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: valueValidate,
    },
  }))
}

export const checkValueState = (stateForm, key) => {
  return _.get(stateForm, `academyActivity.expandDate[0].${key}`, '')
    ? convertTime(_.get(stateForm, `academyActivity.expandDate[0].${key}`, ''))
    : ''
}
