import React from 'react'
import Button from '@mui/material/Button'
import _ from 'lodash'
import { StyledHeaderContainer } from '../../Styled'
import MoreDropdownButton from './DropdownButton'
import { handleEditVersion } from './DropdownButton/events'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { onErrorValidate } from '../../Background/Form/event'
import * as reducer from '../../../../redux/slices/eCertificateForm'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../../constants/eCertification'
import { validateEPermission } from '../../../../utils/lib'
import { onDownload } from './events'

const Header = ({ activeStep, uuid, isView }) => {
  const dispatch = useDispatch()
  const {
    formReasonEdit,
    formCodeId,
    formName,
    formLayout,
    formBackground,
    assignees,
  } = useSelector(
    (state) => ({
      formReasonEdit: state.eCertificateForm.formReasonEdit,
      formCodeId: state.eCertificateForm.formCodeId,
      formName: state.eCertificateForm.formName,
      formLayout: state.eCertificateForm.formLayout,
      formBackground: state.eCertificateForm.formBackground,
      assignees: state.eCertificateForm.assignees,
    }),
    shallowEqual
  )

  const onSubmit = () => {
    let error = {}
    if (_.isEmpty(_.trim(formReasonEdit))) {
      error = { ...error, reason: 'กรุณากรอกข้อมูลในช่องนี้' }
    }
    if (_.isEmpty(formName)) {
      error = { ...error, name: 'กรุณากรอกข้อมูลในช่องนี้' }
    }
    if (_.isEmpty(formLayout?.name)) {
      error = { ...error, layout: 'กรุณาเลือก Layout' }
    }

    if (!_.isEmpty(error)) {
      dispatch(onErrorValidate())
      dispatch(
        reducer.setChange({
          key: 'errors',
          value: error,
        })
      )
      return null
    } else {
      dispatch(
        reducer.setChange({
          key: 'errors',
          value: {},
        })
      )
    }
    dispatch(
      handleEditVersion({
        uuid: uuid,
        formReasonEdit: formReasonEdit,
        formName: formName,
        formLayout: formLayout,
        formBackground: formBackground,
        formCodeId: formCodeId,
        assignees: assignees,
      })
    )
  }

  const hasCreatePermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.CREATE,
  })

  return (
    <StyledHeaderContainer>
      {isView && (
        <Button
          startIcon={<FileDownloadOutlined />}
          data-testid="btn-download"
          variant="contained"
          size="m"
          onClick={() => dispatch(onDownload(uuid))}
        >
          ดาวน์โหลด
        </Button>
      )}
      {hasCreatePermission && activeStep === 2 && !isView && (
        <Button
          data-testid="btn-submit"
          variant="contained"
          size="m"
          onClick={() => onSubmit()}
        >
          บันทึกและเผยแพร่
        </Button>
      )}
      {!isView && <MoreDropdownButton />}
    </StyledHeaderContainer>
  )
}
export default Header
