import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
} from '../../../../../redux/slices/table'
import { manageClassDashboardList } from '../../../../../utils/apiPath'
import { store } from '../../../../../App'
import dayjs from 'dayjs'
import callAxios from '../../../../../utils/baseService'

export const headCellsClass = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'station',
    label: 'Station',
    disablePadding: false,
    hideSortIcon: true,
    width: '200px',
  },
  {
    id: 'online',
    label: 'Online',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'classroom',
    label: 'Classroom',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'total',
    label: 'รวม',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'actionMcDashboard',
    label: '',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `/manage-class/report-export-by-class`,
    minWidth: '40px',
    isMcDashboard: true,
  },
]

export const fetchData =
  ({ table, courseType }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsClass
    try {
      const { filters } = store.getState().crud.manageClassDashboard
      const station = _.defaultTo(filters?.station, []).map((item) => item.uuid)
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage - 1,
        status: courseType ?? '',
        station: station,
        startDate: _.defaultTo(
          filters?.startDate,
          dayjs().startOf('month').format('YYYY-MM-DD'),
        ),
        endDate: _.defaultTo(filters?.endDate, dayjs().format('YYYY-MM-DD')),
      }
      await callAxios
        .post(manageClassDashboardList, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          dispatch(
            setInitialTable({
              rows: prepareRow(results),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              searchKey: 'name',
              hideFilter: true,
              isCheckBox: false,
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
    }
  })
}
