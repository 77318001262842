import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { LoadingPage } from '../../ManageClass/Styled'
import Header from './Header'
import Content from './Content'
import { fetchData } from './events'
import { setClearSearch } from '../../../redux/slices/manage/agentProfile'

const AgentProfileView = ({ type, editable }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const {
    isLoading,
    search,
    pagination,
    isLoading2,
    isLoading3,
    pointLoading,
  } = useSelector(
    (state) => ({
      isLoading: state.agentProfile.isLoading,
      isLoading2: state.agentProfile.isLoading2,
      isLoading3: state.agentProfile.isLoading3,
      search: state.agentProfile.search,
      pagination: state.agentProfile.pagination,
      pointLoading: state.agentProfile.pointDialog.loading,
    }),
    shallowEqual,
  )
  const { courseSearch, examSearch } = search

  useEffect(() => {
    dispatch(setClearSearch())
  }, [])

  useEffect(() => {
    dispatch(fetchData({ uuid, courseSearch, examSearch, pagination }))
  }, [])

  const isLoadingAll = isLoading || isLoading2 || isLoading3 || pointLoading

  const SyncData = (code, agentType) => {
    console.log('agentType ->', agentType)
    let type = 'A'
    if (agentType === 'L: License broker') {
      type = 'L'
    }
    dispatch(
      fetchData({
        uuid,
        courseSearch,
        examSearch,
        pagination,
        isSwitch: null,
        asyncData: { code, type },
      }),
    )
  }

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Box
          sx={{
            opacity: isLoadingAll ? 0.4 : 'unset',
            pointerEvents: isLoadingAll ? 'none' : 'unset',
          }}
        >
          <Header uuid={uuid} type={type} editable={editable} />
          <Content type={type} editable={editable} SyncData={SyncData} />
        </Box>

        <LoadingPage isLoading={isLoadingAll} />
      </Box>
    </>
  )
}

export default AgentProfileView
