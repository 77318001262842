import { store } from '../../../../../App'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table

  let status = []
  let statusWarehouse = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  if (filterState.statusWarehouseChecked) {
    if (filterState.statusWarehouse.activeChecked) {
      statusWarehouse.push(STATUS.ACTIVE)
    }
    if (filterState.statusWarehouse.inActiveChecked) {
      statusWarehouse.push(STATUS.INACTIVE)
    }
    if (filterState.statusWarehouse.deletedChecked) {
      statusWarehouse.push(STATUS.DELETED)
    }
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    stock: filterState.stockChecked ? filterState.stock : '',
    station: filterState.stationChecked ? filterState.station : '',
    totalFrom: filterState.totalChecked ? filterState.totalFrom : '',
    totalTo: filterState.totalChecked ? filterState.totalTo : '',
    availableFrom: filterState.availableChecked
      ? filterState.availableFrom
      : '',
    availableTo: filterState.availableChecked ? filterState.availableTo : '',
    stockValueFrom: filterState.stockValueChecked
      ? filterState.stockValueFrom
      : '',
    stockValueTo: filterState.stockValueChecked ? filterState.stockValueTo : '',
    status: status,
    statusWarehouse: statusWarehouse,
  }
}
