import React from 'react'
import Box from '@mui/material/Box'
import { StyledChipTypography } from './Styled'

const ChipContent = ({ data }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {data.map((ele, ind) => {
        return (
          <StyledChipTypography variant="chip" key={ind}>
            {ele}
          </StyledChipTypography>
        )
      })}
    </Box>
  )
}

export default ChipContent
