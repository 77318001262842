import { Box, Card } from '@mui/material'
import { styled } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

export const LoadingPage = styled(CircularProgress)(({ theme, isLoading }) => ({
  display: isLoading ? 'block' : 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  color: theme.palette?.action?.active,
}))

export const StyledRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledGrid = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',
  gridGap: '8px',
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.10)',
}))

export const StyledImg = styled('img')(() => ({
  borderRadius: 8,
  objectFit: 'cover',
  cursor: 'pointer',
}))

export const StyledImgThumb = styled('img')(() => ({
  borderRadius: 8,
  objectFit: 'cover',
}))

export const SliderItem = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  height: 200,
  [theme.breakpoints.up('sm')]: {
    height: 350,
  },
}))

export const CalendarCardStyle = styled((props) => (
  <StyledCard
    {...props}
    sx={{
      position: 'relative',
      mt: 2,
      px: 3,
      pb: '65px',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}
  />
))(() => ({}))

export const PrevButton = styled((props) => (
  <IconButton
    {...props}
    sx={{
      pl: { sm: 2, xs: 1 },
      pr: { sm: 2, xs: 0 },
      py: 1,
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      height: '100%',
    }}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette?.grey?.grey100,
}))

export const NextButton = styled((props) => (
  <IconButton
    {...props}
    sx={{
      pl: { sm: 2, xs: 1 },
      pr: { sm: 2, xs: 0 },
      py: 1,
      borderRadius: 0,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      height: '100%',
    }}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette?.grey?.grey100,
}))

export const StyledImgStatusCard = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: 72,
  bottom: 22,
  [theme.breakpoints.up('sm')]: {
    width: 192,
    height: 195,
  },
  [theme.breakpoints.up('550')]: {
    right: 15,
    width: 102,
    height: 105,
  },
  [theme.breakpoints.down('550')]: {
    display: 'none',
  },
}))
