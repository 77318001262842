import * as yup from 'yup'

export const validationSchema = yup.object({
  productName: yup
    .string()
    .max(255, 'ชื่อทรัพย์สินกรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุชื่อทรัพย์สิน'),
  category: yup.object().nullable().required('กรุณาเลือกหมวดหมู่'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
})
