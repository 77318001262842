import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import TextInput from '../../../../components/Input/TextInput'
import { setApproveObj } from '../../../../redux/slices/speaker'
import { StyledFormWrapper } from './Styled'

const BorrowApprovement = ({ data, index, staffList }) => {
  const dispatch = useDispatch()
  const { approveList, errors } = useSelector(
    (state) => ({
      approveList: state.speaker.approveList,
      errors: state.speaker.errors,
    }),
    shallowEqual
  )
  const approveObj = _.get(approveList, index, {})
  const status = _.get(approveObj, 'status', '')

  return (
    <StyledFormWrapper>
      <RadioGroup
        sx={{ flexDirection: 'row', ml: 1, gap: 1 }}
        name="status-radio-group"
        value={status}
        onChange={(e) =>
          dispatch(
            setApproveObj({ index, key: 'status', value: e.target.value })
          )
        }
      >
        <FormControlLabel value="APPROVE" control={<Radio />} label="อนุมัติ" />
        <FormControlLabel
          value="REJECT"
          control={<Radio />}
          label="ไม่อนุมัติ"
        />
      </RadioGroup>

      {status === 'APPROVE' && (
        <SelectGroupChip
          id="staffs"
          name="staffs"
          labelText="วิทยากร"
          placeholder="เลือกวิทยากร"
          required
          options={staffList.filter(
            (staff) =>
              !approveList.some((list) =>
                list.staffs?.some((selected) => selected.uuid === staff.value)
              )
          )}
          isMenuPosition={true}
          isOptionDisabled={_.get(data, 'numSpeaker', '')}
          textError={_.get(errors[index], 'staffs', '')}
          value={approveObj.staffs}
          onChange={(list) =>
            dispatch(setApproveObj({ index, key: 'staffs', value: list }))
          }
        />
      )}

      {status == 'REJECT' && (
        <TextInput
          required
          id="remark"
          name="remark"
          type="basic"
          labelText="เหตุผล"
          placeholder=""
          multiline={true}
          rows={3}
          textError={_.get(errors[index], 'remark', '')}
          onChange={(e) =>
            dispatch(
              setApproveObj({ index, key: 'remark', value: e.target.value })
            )
          }
        />
      )}
    </StyledFormWrapper>
  )
}

export default BorrowApprovement
