import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { resetApproveObj, setValidate } from '../../../redux/slices/speaker'
import { handleApproveBorrow, handleCancelRequest } from './events'

const DrawerButtons = (props) => {
  const { onClose } = props
  const dispatch = useDispatch()
  const { data, speakerType, approveList, errors, isSubmit } = useSelector(
    (state) => ({
      data: state.speaker.data,
      speakerType: state.speaker.speakerType,
      approveList: state.speaker.approveList,
      errors: state.speaker.errors,
      isSubmit: state.speaker.isSubmit,
    }),
    shallowEqual
  )
  const isDisable = approveList.some((item) => _.isEmpty(item.status))

  useEffect(() => {
    if (_.isEmpty(errors) && isSubmit) {
      dispatch(handleApproveBorrow({ data, approveList, ...props }))
    }
  }, [errors, isSubmit])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 4, gap: 1.5 }}>
      {speakerType === 'REQUESTER' && (
        <>
          <Button
            data-testid="btn-reject"
            variant="outlined"
            onClick={() => {
              onClose()
              dispatch(resetApproveObj())
            }}
          >
            ยกเลิก
          </Button>
          <Button
            data-testid="btn-approve"
            variant="contained"
            disabled={isDisable}
            onClick={() => dispatch(setValidate())}
          >
            ยืนยัน
          </Button>
        </>
      )}

      {speakerType === 'APPROVER' && (
        <Button
          data-testid="btn-cancel-request"
          variant="outlined"
          color="error"
          onClick={() =>
            dispatch(handleCancelRequest({ uuid: data.uuid, ...props }))
          }
        >
          ยกเลิกคำขอ
        </Button>
      )}
    </Box>
  )
}

export default DrawerButtons
