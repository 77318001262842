import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_VIEW_TEXT,
} from '../../../../constants/stock'
import { StatusComponent } from '../../../../components/CRUD/components/StatusComponent'
import { getStatusText, convertFloatWithComma } from '../../../../utils/lib'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  const productType = _.get(formData, 'productType')
  const productImage = _.get(formData, 'productImage', '')
  let refundText = ''
  if (_.get(formData, 'refundYear', false))
    refundText += ` ${_.get(formData, 'refundYear', '')} ปี`
  if (_.get(formData, 'refundMonth', false))
    refundText += ` ${_.get(formData, 'refundMonth', '')} เดือน`
  if (_.get(formData, 'refundDay', false))
    refundText += ` ${_.get(formData, 'refundDay', '')} วัน`
  const quantityAlert = formData?.quantityAlert
    ? convertFloatWithComma(formData?.quantityAlert)
    : '-'
  const averageUnitPrice = formData?.averageUnitPrice
    ? convertFloatWithComma(formData?.averageUnitPrice)
    : '-'
  const allStockValue = formData?.allStockValue
    ? convertFloatWithComma(formData?.allStockValue)
    : '-'
  return (
    <Box
      sx={{ my: 2, mx: 2, display: 'flex', gap: 2, flexDirection: 'column' }}
    >
      <ViewRow
        title="ประเภททรัพย์สิน"
        variant="variant"
        value={PRODUCT_TYPE_VIEW_TEXT?.[productType] ?? '-'}
      />

      {productType !== PRODUCT_TYPE.LICENSE ? (
        <>
          <ViewRow
            title="ชื่อทรัพย์สิน"
            variant="variant"
            value={_.get(formData, 'productName', '-')}
          />
        </>
      ) : (
        <>
          <ViewRow
            title="ชื่อซอฟต์แวร์"
            variant="variant"
            value={_.get(formData, 'productName', '-')}
          />
          <ViewRow
            title="Vendor"
            variant="variant"
            value={_.get(formData, 'vendor', '-')}
          />
        </>
      )}

      <ViewRow
        title="หมวดหมู่"
        variant="variant"
        value={_.get(formData, 'category.label', '-')}
      />
      <ViewRow
        title="หมวดหมู่ย่อย"
        variant="variant"
        value={_.get(formData, 'subCategory.label', '-')}
      />

      {(productType === PRODUCT_TYPE.STOCK ||
        productType === PRODUCT_TYPE.STOCK_ASSET) && (
        <>
          <ViewRow
            title="จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย"
            variant="variant"
            value={`${quantityAlert}`}
          />
          <ViewRow
            title="Cost center"
            variant="variant"
            value={_.get(formData, 'costCenter.label', '-')}
          />
          <ViewRow
            boxSx={{ display: 'flex', flexDirection: 'column', my: 2 }}
            title="คำอธิบาย"
            variant="variant"
            value={_.get(formData, 'description', '-')}
          />
          <ViewRow
            title="รูปประกอบ"
            variant="variant"
            value={
              productImage?.key ? (
                <img
                  srcSet={`${window.__env__.REACT_APP_API_URL}/file/get${productImage?.key}`}
                  src={
                    !_.isEmpty(productImage)
                      ? `${window.__env__.REACT_APP_API_URL}/file/get${productImage?.key}`
                      : ''
                  }
                  // alt={item.title}
                  loading="lazy"
                  width={150}
                />
              ) : (
                '-'
              )
            }
          />
          <ViewRow
            title="สถานะทรัพย์สิน"
            variant="variant"
            valueComponent={StatusComponent(formData)}
          />
          <ViewRow
            title="ระบบ รหัสทรัพย์สิน"
            variant="variant"
            value={getStatusText(
              productType === PRODUCT_TYPE.STOCK_ASSET ? 'ACTIVE' : 'INACTIVE',
            )}
            valueComponent={StatusComponent(formData)}
          />
          <ViewRow
            title="Flag คืนทรัพย์สิน"
            variant="variant"
            value={getStatusText(_.get(formData, 'statusFlagReturn', '-'))}
          />

          <ViewRow
            title="คืนทรัพย์สินภายใน"
            variant="variant"
            value={refundText ?? '-'}
          />
          <ViewRow
            title="มูลค่าต่อหน่วย (฿) เฉลี่ยต่อชิ้น"
            variant="variant"
            value={`${averageUnitPrice} บาท`}
          />
          <ViewRow
            title="มูลค่ารวม (฿)"
            variant="variant"
            value={`${allStockValue} บาท`}
          />
        </>
      )}

      {(productType === PRODUCT_TYPE.ASSET ||
        productType === PRODUCT_TYPE.LICENSE) && (
        <>
          <ViewRow
            title="มูลค่ารวม (฿)"
            variant="variant"
            value={`${allStockValue} บาท`}
          />
          <ViewRow
            title="สถานะทรัพย์สิน"
            variant="variant"
            value={StatusComponent(formData)}
          />
        </>
      )}
      <ViewRow
        boxSx={{ display: 'flex', flexDirection: 'column', my: 2 }}
        title="หมายเหตุ"
        variant="variant"
        value={formData?.remark ?? '-'}
      />
    </Box>
  )
}
