import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import {
  StyledCardContent,
  StyledDashedDivider,
} from '../../../../../components/View/Styled'
import ECertificatePreview from '../../../../../components/ECertificatePreview'

const ECertificate = () => {
  const detail = useSelector((state) => state.eLearningCourseDetail.result)

  return (
    <>
      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          E-Certificate
        </Typography>
        <ECertificatePreview certificate={detail} />
      </StyledCardContent>
    </>
  )
}

export default ECertificate
