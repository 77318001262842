import { store } from '../../../../App'
import { handleCheckStatusReportUpdated } from '../../../../components/Report/handler/handleCheckStatusReportUpdated'
import { setFieldValue } from '../../../../redux/slices/crud'

export const checkUpdateStatus = async () => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const res = await handleCheckStatusReportUpdated('REPORT_ROOM_MANAGEMENT')
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.updateStatus',
      value: res?.status,
    }),
  )
}
