import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
export const ProfileImg = styled(Box)(({ url }) => ({
  width: '100px',
  height: '100px',
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '50%',
  margin: '8px auto 32px auto',
}))
