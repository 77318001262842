import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Column, Row } from '../../../Layout/Styled'
import Autocomplete from '../../../Input/Autocomplete'
import {
  setColumnSelected,
  setReduxValue,
} from '../../../../redux/slices/reportComp'
import ValueOfColumn from './ValueOfColumn'
import { handleAddFilter } from '../../handler/handleAddFilter'
import _ from 'lodash'

const AddFilterSection = () => {
  const dispatch = useDispatch()
  const { columnOptions, columnSelected, disabledAddBtn } = useSelector(
    (state) => ({
      columnOptions: state.reportComp.columnOptions,
      columnSelected: state.reportComp.columnSelected,
      disabledAddBtn: state.reportComp.disabledAddBtn,
    }),
    shallowEqual,
  )

  return (
    <Box sx={{ p: 3, pt: 0, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">เพิ่มตัวกรอง</Typography>
      <Column>
        <Row sx={{ gap: 3, alignItems: 'stretch' }}>
          <Autocomplete
            boxSx={{
              width: '50%',
            }}
            id="column-selected"
            name="column-selected"
            labelText="คอลัมน์"
            placeholder="เลือกคอลัมน์"
            options={columnOptions}
            defaultValue={{
              value: columnSelected?.value || '',
              label: columnSelected?.label || '',
            }}
            isHideSort={true}
            renderInput={(params) => (
              <TextField placeholder="กรุณาเลือก" {...params} />
            )}
            handleChange={(e, obj) => {
              dispatch(setReduxValue({ key: 'options', value: [] }))
              dispatch(setColumnSelected(obj))
            }}
          />
          <ValueOfColumn />
        </Row>
        <Button
          sx={{ maxWidth: 105 }}
          variant="contained"
          size="s"
          disabled={disabledAddBtn || _.isNull(columnSelected)}
          onClick={() => dispatch(handleAddFilter())}
        >
          เพิ่มในรายการ
        </Button>
      </Column>
    </Box>
  )
}

export default AddFilterSection
