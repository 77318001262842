import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import SelectIcon from '../../../../components/redux/SelectIcon'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../components/FilterDrawer/Styled'
import { arrayCheckbox, defaultFilter } from './model'
import * as events from './events'
import { handleNumberInput } from '../../../../utils/lib'

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { dateRangeList, meetingRoomList } = useSelector(
    (state) => ({
      dateRangeList: state.manageClassLearner.dateRangeList,
      meetingRoomList: state.manageClassLearner.meetingRoomList,
    }),
    shallowEqual
  )
  const roomDate = _.get(stateFilter, 'date', '')

  return listArray.map((item, index) => (
    <StyledGroupBox key={index}>
      <FormGroup>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              inputProps={{
                'data-testid': `check-${item.nameCheckBox}`,
              }}
              name={item.nameCheckBox}
              checked={stateFilter[item.nameCheckBox]}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter: stateFilter,
                  setFilter,
                })
              }}
            />
          }
          sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
        />

        {stateFilter[item.nameCheckBox] && (
          <>
            {item.type == 'checkbox' && (
              <StyledList>
                <ListCheckboxChild
                  status={stateFilter[item.nameInput]}
                  filter={stateFilter}
                  setFilter={setFilter}
                  arrayList={_.get(item, 'list', [])}
                  listKey={item.nameInput}
                />
              </StyledList>
            )}
            {item.type == 'text' && (
              <InputBase
                sx={{ borderRadius: 0 }}
                type="basic"
                inputProps={
                  item.nameInput == 'attend'
                    ? {
                        'data-testid': `input-${item.nameInput}`,
                        type: 'number',
                        min: '0',
                        onInput: handleNumberInput,
                      }
                    : { 'data-testid': `input-${item.nameInput}` }
                }
                name={item.nameInput}
                disabled={!stateFilter[item.nameCheckBox]}
                placeholder={item.placeholder}
                value={stateFilter[item.nameInput]}
                onChange={(e) => {
                  events.handleChange({
                    key: e.target.name,
                    value: e.target.value,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            )}
            {item.type == 'selects' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <SelectIcon
                  menuSx={MenuStyled()}
                  dataTestId="select-date"
                  listOption={dateRangeList}
                  placeholder="เลือกวันที่เรียน"
                  value={roomDate}
                  handleChange={(e) =>
                    dispatch(
                      events.handleDateChange({
                        date: e.target.value,
                        setFilter,
                        id,
                      })
                    )
                  }
                />

                <SelectGroupChip
                  boxSx={{ maxWidth: 278 }}
                  id="roomList"
                  name={item.nameInput}
                  placeholder="เลือกห้องเรียน"
                  options={roomDate == '' ? [] : meetingRoomList}
                  showTextError={false}
                  value={roomDate == '' ? [] : _.get(stateFilter, 'room', [])}
                  onChange={(list) => {
                    events.handleChange({
                      key: 'room',
                      value: list,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              </Box>
            )}
          </>
        )}
      </FormGroup>
    </StyledGroupBox>
  ))
}

export const MenuStyled = () => {
  const theme = useTheme()
  return {
    '&.Mui-selected[aria-selected="false"], &.Mui-selected[aria-selected="false"].Mui-focusVisible':
      {
        color: theme?.palette?.text?.primary,
        background: theme?.palette?.background?.paper,
      },
    '&.Mui-selected[aria-selected="false"]:hover': {
      background: theme?.palette?.action?.hover,
    },
    '&[aria-selected="true"], &[aria-selected="true"]:hover, &[aria-selected="true"].Mui-focusVisible':
      {
        color: theme?.palette?.primary?.main,
        background: theme?.palette?.primary?.outlinedHoverBackground,
      },
  }
}

export const ListCheckboxChild = (props) => {
  const { arrayList, status, filter, setFilter, listKey } = props
  return arrayList.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table, drawerType }) => {
  const { id: uuid } = useParams()
  const [filter, setFilter] = useState(defaultFilter[drawerType])
  const [filterMemo, setFilterMemo] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter[drawerType])
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={arrayCheckbox[drawerType]}
        stateFilter={filter}
        setFilter={setFilter}
      />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter[drawerType])}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter, uuid))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
