import { store } from '../../../../../../App'
import { fetchOverview } from './fetchOverview'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../../../redux/slices/crud'
// import _ from 'lodash'

export const handleClearFilter = (key) => {
  const { filtersDrawer } = store.getState().crud.eEvaluationDashboard
  let countFilterTotal = filtersDrawer?.filterTotal ?? 1
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.dateFilterStartDate',
        value: filtersDrawer?.isFilterUserResponseDate
          ? ''
          : dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.dateFilterFinishDate',
        value: filtersDrawer?.isFilterUserResponseDate
          ? ''
          : dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.isClearFilter',
        value: 'date',
      }),
    )
  } else if (key === 'userResponseDate') {
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.userResponseDateStart',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.userResponseDateEnd',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.isFilterUserResponseDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.isClearFilter',
        value: 'userResponseDate',
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.filtersDrawer.filterTotal',
      value: countFilterTotal - 1,
    }),
  )
  if (!filtersDrawer?.isFilterDate && key === 'userResponseDate') {
    //reset to default MTD if both clear
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.dateFilterStartDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'eEvaluationDashboard.filtersDrawer.dateFilterFinishDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
  }
  fetchOverview()
}
