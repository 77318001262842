import React, { useRef, useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import { useDispatch } from 'react-redux'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import History from '../History'
import * as events from './events'
import { StyledPopper } from './Styled'
import { useParams } from 'react-router-dom'
const Index = ({ trainingPlanUuid }) => {
  const anchorRef = useRef(null)
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const [open, setOpen] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) =>
                  events.handleClose(event, anchorRef, setOpen)
                }
              >
                <MenuList autoFocusItem>
                  <MenuItem
                    component="span"
                    data-testid="menu-history"
                    onClick={() => setOpenHistory(true)}
                  >
                    <Typography variant="body1" component="span">
                      ประวัติการเปลี่ยนแปลง
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component="span"
                    data-testid="menu-cancel"
                    onClick={() =>
                      dispatch(events.cancelDialog({ trainingPlanUuid, uuid }))
                    }
                  >
                    <Typography variant="body1" component="span">
                      ยกเลิกการสมัครเรียน
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      <History
        isOpen={openHistory}
        onCloseDrawer={() => setOpenHistory(false)}
      />
    </>
  )
}

export default Index
