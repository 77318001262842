import { shallowEqual, useSelector } from 'react-redux'
import DragDropColumnCard from './DragDropColumnCard'
import ManageColumnCard from './ManageColumnCard'
import ContentsDrawer from './ContentMain'

const TabContentMain = () => {
  const { tabActiveColDrawer } = useSelector(
    (state) => ({
      tabActiveColDrawer: state.reportComp.tabActiveColDrawer,
    }),
    shallowEqual,
  )

  return (
    <ContentsDrawer>
      {tabActiveColDrawer === 0 && <DragDropColumnCard />}
      {tabActiveColDrawer === 1 && (
        <>
          <ManageColumnCard />
          <DragDropColumnCard showTitle={true} />
        </>
      )}
    </ContentsDrawer>
  )
}
export default TabContentMain
