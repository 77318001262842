import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { vendorTypeEnum } from '../enum/vendorTypeEnum'

export const getBodyMutation = (data) => {
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    name: _.get(data, 'name', ''),
    station: _.get(data, 'station', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    annotation: _.get(data, 'annotation', ''),
    vendorType: _.get(data, 'vendorType', vendorTypeEnum.VENDOR),
    qtyType: _.get(data, 'qtyType', ''),
    descriptionType: _.get(data, 'descriptionType', ''),
    serviceDescription: _.get(data, 'serviceDescription', ''),
    startDate: _.get(data, 'startDate', ''),
    endDate: _.get(data, 'endDate', ''),
    renewDate: _.get(data, 'renewDate', ''),
    checkEndDate: _.get(data, 'checkEndDate', false),
    insideContacts: _.get(data, 'insideContacts', [
      {name: '',}
    ]),
    outsideContacts: _.get(data, 'outsideContacts', []),
    createdBy: _.get(data, 'createdBy', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    createdAt: _.get(data, 'createdAt', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
  }
}