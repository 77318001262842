import React from 'react'
import _ from 'lodash'
import { StyledTab, StyledTabBox } from './Styled'
import Tabs from '@mui/material/Tabs'

const CustomTabs = (props) => {
  const listTab = _.get(props, 'listTab', [])
  const value = _.get(props, 'value', '')
  const onChange = _.get(props, 'onChange', null)
  return (
    <StyledTabBox>
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="secondary"
        value={value}
        onChange={(e, tab) => onChange(tab)}
      >
        {listTab.map((item, index) => (
          <StyledTab
            data-testid={`tab-${index}`}
            key={index}
            label={_.get(item, 'label', '')}
            value={_.get(item, 'value', '')}
            {...tabProps(index)}
          />
        ))}
      </Tabs>
    </StyledTabBox>
  )
}

export default CustomTabs

const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabcontent-${index}`,
  }
}
