import React from 'react'
import _ from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  handleStyleEvent,
  handleStyleStatus,
  handleStyleBorrow,
} from '../../modules/MonthlyPlan/Drawer/Handle'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popover from '@mui/material/Popover'

const EventColumn = ({ events, getEventTooltipText, handleClickEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.parentNode)
  }

  const handleClose = (e) => {
    setAnchorEl(null)
    e.stopPropagation()
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const introEvents = events.slice(0, 2)
  const RenderList = (items) => {
    return items.map((work, key) => {
      const subWorkType = _.get(work, 'subWorkType.subWorkType', '')
      const stylesEvent = handleStyleEvent({
        work: work.workType.workType,
        leave: subWorkType,
      })
      const getStatus =
        _.get(work, 'statusBorrow', '') === 'WAITING_APPROVE_BORROW'
          ? 'WAITING_APPROVE_BORROW'
          : _.get(work, 'status', '')
      let stylesStatus = handleStyleStatus({
        status: getStatus,
      })

      const isBorrow = _.get(work, 'borrow', false)
      const stylesIsBorrow = handleStyleBorrow({
        isBorrow,
      })

      if (isBorrow && getStatus === 'REQUEST_DELETE') {
        stylesStatus = stylesIsBorrow
      }
      let title =
        work.title === 'Leave'
          ? _.get(work, 'subWorkType.subWorkType', '')
          : _.get(work, 'title', '')
      const status = _.get(work, 'status', '')
      const isStatusDeleted = status === 'DELETED' || status === 'CANCEL'
      const workPlace = _.get(work, 'workPlace', '')
      if (workPlace === 'OFFICE') title = 'Work from office'
      if (workPlace === 'HOME') title = 'Smart working'

      const monthlyPlanUuid = _.get(work, 'id', '')

      return (
        <Tooltip
          key={key}
          title={
            <Typography variant="tooltip">
              {getEventTooltipText(work)}
            </Typography>
          }
          arrow
          placement="right"
        >
          <Typography
            monthly-plan-uuid={monthlyPlanUuid}
            data-testid={`text-event-${key}`}
            sx={{
              ...stylesEvent,
              ...stylesIsBorrow,
              ...stylesStatus,
              borderRadius: '4px',
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-between',
              px: '6px',
              py: '2px',
              cursor: 'pointer',
              fontSize: '14px',
              marginBottom: 0.1,
            }}
            variant="tooltip"
            onClick={(e) => {
              e.stopPropagation()
              handleClose && handleClose(e)
              handleClickEdit(work.id, work)
            }}
          >
            {_.get(work, 'statusApprove', '') === 'REJECTED' && (
              <Box
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  right: -2,
                  top: -2,
                  width: '8px',
                  height: '8px',
                  borderRadius: '24px',
                  backgroundColor: 'error.main',
                }}
              />
            )}
            {isStatusDeleted && (
              <img
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: 6,
                  top: 6,
                }}
                src="/icon/ic_calendar_deleted.svg"
              />
            )}
            {/* validate title === '' because when box no text is wrong layout */}
            <Box
              sx={{
                width: 110,
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: isStatusDeleted ? 2 : 0.5,
                visibility: title === '' ? 'hidden' : 'unset',
              }}
            >
              {title === '' ? '.' : title}
            </Box>
            <Box>{work.hours}</Box>
          </Typography>
        </Tooltip>
      )
    })
  }
  if (events.length <= 2) {
    return <Box sx={{ width: 150 }}>{RenderList(introEvents)}</Box>
  } else {
    return (
      <Box sx={{ width: 150 }}>
        {RenderList(introEvents)}
        <Typography
          data-testid={'box-events'}
          aria-describedby={id}
          sx={{
            display: 'block',
            fontSize: '14px',
            px: '6px',
            py: '3px',
            cursor: 'pointer',
            textAlign: 'right',
            textDecoration: 'underline',
          }}
          variant="tooltip"
          onClick={(e) => {
            handleClick(e)
            e.stopPropagation()
          }}
        >
          อีก {events.length - 2} กิจกรรม
        </Typography>
        <ClickAwayListener onClickAway={handleClose}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                p: 1,
                width: 170,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <CloseIcon
                  data-testid="btn-close-box-events"
                  sx={{ cursor: 'pointer', width: '16px' }}
                  onClick={handleClose}
                />
              </Box>
              {RenderList(events)}
            </Box>
          </Popover>
        </ClickAwayListener>
      </Box>
    )
  }
}

export default EventColumn
