import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { handlePeriod } from '../../../../../modules/ELearning/components/View/events'
import {
  StyledCardContent,
  StyledDashedDivider,
} from '../../../../../modules/ELearning/components/View/Styled'
import {
  ContentRow,
  ChipContent,
  TypeContent,
} from '../../../../../modules/ELearning/components/View'
import { UpdatedComponent } from '../../../../../components/CRUD/components/UpdatedComponent'

const Setting = () => {
  const { formData } = useSelector((state) => state.crud)
  const period = useMemo(() => handlePeriod(formData), [formData])
  const setting = _.get(formData, 'setting', {})
  const dueDateType = _.get(formData, 'dueDateType', '')
  return (
    <>
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลา
        </Typography>
        <ContentRow title="วันเริ่มต้น" content={period} />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          การตั้งค่า
        </Typography>
        <ChipContent title="Tag" list={_.get(setting, 'tag', [])} />
        <ChipContent
          title="ช่องการจัดจำหน่าย"
          list={_.get(setting, 'distribution', [])}
        />
        <ChipContent
          title="ระดับของผู้เรียน"
          field="levelOfLearner"
          list={_.get(formData, 'levelOfLearner', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          Skill Mapping
        </Typography>
        <ChipContent
          title="ระดับหลักสูตร"
          field="acquiredSkill"
          list={_.get(formData, 'acquiredSkill', [])}
        />
        <ChipContent
          title="ทักษะที่ได้จากหลักสูตร"
          field="productType"
          list={_.get(formData, 'productType', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาแสดงบนเว็บไซต์
        </Typography>
        <TypeContent type={dueDateType}>
          แสดง Collection บนเว็บไซต์ถึง{' '}
          {convertFormatDateTime({
            value: _.get(formData, 'dueDate', ''),
            type: 'date',
          })}
        </TypeContent>
      </StyledCardContent>

      <StyledDashedDivider />

      <StyledCardContent>
        <ContentRow
          title="Remark"
          content={_.get(setting, 'remark', '-') || '-'}
        />
      </StyledCardContent>

      <Divider />

      <UpdatedComponent />
    </>
  )
}

export default Setting
