import _ from 'lodash'
import { clearSubCategoryFields } from './clearSubCategoryFields'
import { fetchSubCategory } from './fetchSubCategory'

export const handleCategoryChange = (event, value) => {
  if (_.isNull(value)) {
    clearSubCategoryFields()
    return
  }
  fetchSubCategory(value.value)
}
