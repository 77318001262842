import { Box, Drawer } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 360,
    height: '100vh',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginLeft: 0,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
      2,
    )} ${theme.spacing(0)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(7)}`,
  },
}))
