import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'

export const StyledBorrowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  padding: `0 ${theme.spacing(3)}`,
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  height: 40,
  margin: `${theme.spacing(1)} 0`,
}))

export const StyledRow = styled(Box)(({ theme, isRejected }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  margin: `${theme.spacing(1)} 0 ${isRejected ? 0 : theme.spacing(1)}`,
  '& .MuiTypography-root': { padding: '5px 0', wordWrap: 'break-word' },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueLine,
}))

export const StyledBoxDot = styled(Box)(({ theme }) => ({
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}))

export const StyledFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  margin: `0 0 ${theme.spacing(1)} 102px`,
}))

export const StyledFlexColumn = styled(Box)(({ theme, isDecided }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginLeft: isDecided ? '102px' : '75px',
  marginBottom: theme.spacing(2),
}))
