import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../../redux/slices/eTestingForm'

export const handleRadioChange = (props) => (dispatch) => {
  const { answers, indexMainAnswer, keyQuestion, value } = props
  const result = _.get(answers[indexMainAnswer], 'subAnswers', []).map(
    (item, indexAnswer) => ({
      ...item,
      isCorrect: indexAnswer === Number(value),
    })
  )

  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: `answers[${indexMainAnswer}].subAnswers`,
      value: result,
    })
  )
}
