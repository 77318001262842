import {
  resetPage,
  resetTablePage,
  setSearchText,
} from '../../../../../redux/slices/table'
import { setDashboardTable } from './setDashboardTable'
import { handleUpdateTable } from './handleUpdateTable'

export const handleQuickSearch =
  (table, keyword, props) => async (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      setDashboardTable({ ...props })
      handleUpdateTable()
    } else {
      dispatch(resetPage())
    }
  }
