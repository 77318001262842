import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import PreviewImg from '../Preview/PreviewImg'
import { getHasInvalidFiles } from './BasicUpload'
import { useDispatch } from 'react-redux'

const StaffUpload = (props) => {
  const {
    handleUploadFile,
    value,
    textError,
    isShowDescription,
    imageType,
    name,
    style,
    onDelete,
    formik,
    setDialog,
  } = props
  const [src, setSrc] = useState(value)
  const dispatch = useDispatch()
  useEffect(() => {
    if (value && value !== '') {
      setUrlImage(value)
    }
  }, [value])

  const setUrlImage = async (key) => {
    setSrc(`${window.__env__.REACT_APP_API_URL}/file/${key}`)
  }

  const onUpload = (e) => {
    handleUploadFile({ e, setDialog, formik, dispatch, name })
  }

  var fileInput = document.getElementById(`btn-add-file-${name}`)

  if (fileInput) {
    fileInput.addEventListener('change', function () {
      const checkInvalidFiles = getHasInvalidFiles(this.files)
      if (checkInvalidFiles) {
        fileInput.value = ''
      }
    })
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body1">
          {imageType === 'full'
            ? 'รูปภาพหน้าตรง (สำหรับเอกสารทางการ)'
            : 'รูปภาพ (สำหรับใช้บนเว็บไซต์) '}
        </Typography>
        {imageType === 'small' && (
          <Typography variant="body2" color="error">
            *
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 2,
        }}
      >
        {src === '' && imageType === 'full' && (
          <Typography variant="body1" sx={{ width: 130, mr: 3 }}>
            <img src="/logo/staff_profile_full.png" alt="StaffProfileFull" />
          </Typography>
        )}

        {src === '' && imageType === 'small' && (
          <Typography variant="body1" sx={{ width: 100, mr: 3 }}>
            <img src="/logo/staff_profile_small.png" alt="StaffProfile" />
          </Typography>
        )}

        {src !== '' && (
          <Box sx={{ mr: 3 }}>
            <PreviewImg
              srcImg={src}
              setSrc={setSrc}
              isPreview={true}
              style={style}
            />
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <label htmlFor={`btn-add-image-${name}`}>
              <Button
                sx={{ mr: 3, minWidth: 119 }}
                variant="contained"
                size="m"
                component="span"
              >
                <Input
                  data-testid={`btn-add-image-${name}`}
                  accept="image/*"
                  id={`btn-add-image-${name}`}
                  type="file"
                  onChange={onUpload}
                />
                <Typography color="text.silver" sx={{ cursor: 'pointer' }}>
                  อัปโหลดรูปภาพ
                </Typography>
              </Button>
            </label>
            {src !== '' && (
              <Button
                sx={{ minWidth: 94 }}
                data-testid={'btn-img-delete'}
                variant="outlined"
                size="m"
                component="span"
                onClick={() => {
                  onDelete(formik, imageType)
                  setSrc('')
                }}
              >
                <Typography sx={{ cursor: 'pointer' }}>ลบรูปภาพ</Typography>
              </Button>
            )}
          </Box>

          <Box sx={{ flexDirection: 'row', mt: 1 }}>
            {isShowDescription !== false && (
              <>
                <Typography color="body2">
                  ขนาดแนะนำ {imageType === 'full' ? '300px400px' : '200px200px'}
                  ,
                </Typography>
                <Typography color="body2">
                  นามสกุลไฟล์ที่รองรับ .jpg, .png
                </Typography>
                {textError ? (
                  <Typography variant="body2" color="error">
                    {textError}
                  </Typography>
                ) : (
                  <Box sx={{ height: '25px' }}>{textError}</Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const Input = styled('input')({
  display: 'none',
})

export default StaffUpload
