import React from 'react'
import _ from 'lodash'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { COURSE_TYPE } from '../../../../../constants/eLearning'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { FORM_TAB, FORM_TAB_TH } from '../model'
import { getStateValue } from '../events'
import { handleChangeTab } from './event'
import { StyledTab } from './Styled'

const TabMenu = () => {
  const dispatch = useDispatch()
  const { tab, uuid, courseType } = useSelector(
    (state) => ({
      tab: state.eLearningCourseForm.tab,
      uuid: getStateValue('uuid'),
      courseType: getStateValue('courseType'),
    }),
    shallowEqual,
  )

  return (
    <StyledTab
      data-testid="tap-menu-form"
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      aria-label="primary tabs"
      onChange={(_, value) => dispatch(handleChangeTab(value))}
    >
      <Tab
        value={FORM_TAB.course}
        data-testid="tab-course"
        label={
          <Typography variant="body1b" color="primary">
            {FORM_TAB_TH.course}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.setting}
        data-testid="tab-setting"
        label={
          <Typography variant="body1b" color="primary">
            {FORM_TAB_TH.setting}
          </Typography>
        }
      />
      {!_.isEmpty(uuid) && courseType === COURSE_TYPE.OIC && (
        <Tab
          value={FORM_TAB.batch}
          data-testid="tab-batch"
          label={
            <Typography variant="body1b" color="primary">
              {FORM_TAB_TH.batch}
            </Typography>
          }
        />
      )}
    </StyledTab>
  )
}
export default TabMenu
