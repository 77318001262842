import React from 'react'
import Typography from '@mui/material/Typography'
import { Column } from '../../../../Styled'
import { StyledCircleBox, StyledContentBox } from './Styled'

const GetReadyTab = () => {
  return (
    <Column sx={{ p: 3, background: '#FFF', gap: 2 }}>
      <Column sx={{ gap: 0.5, textAlign: 'center' }}>
        <Typography variant="body1b" mb={0.5}>
          เตรียมพร้อมก่อนอบรม
        </Typography>
        <Typography variant="body1b">
          หลักสูตรนี้เป็นการอบรมที่{' '}
          <Typography variant="span" color="primary">
            “มีการยืนยันตัวตน”
          </Typography>
        </Typography>
        <Typography>
          กรุณาเตรียมพร้อมตามข้อมูลด้านล่าง
          <br />
          เพื่อความสะดวกในการอบรมที่รวดเร็ว
        </Typography>
      </Column>
      <Column sx={{ alignItems: 'center' }}>
        <ContentBox
          title="มีกล้องเชื่อมต่ออุปกรณ์ที่ใช้ในการอบรม"
          subTitle="เช่น คอมพิวเตอร์เชื่อมต่อเว็บแคม, โน้ตบุ๊ค, สมาร์ตโฟนหรือแท็บเล็ต"
          img="ic_webcam.svg"
        />
        <ContentBox
          title="หลักฐานยืนยันตัวตน"
          subTitle="เช่น บัตรประชาชนหรือใบขับขี่"
          img="ic_user_id.svg"
        />
        <ContentBox
          title="ความเร็วอินเทอร์เน็ต"
          subTitle="ควรมีความเร็วขั้นต่ำ 3Mbps ขึ้นไป"
          img="ic_wifi.svg"
        />
        <ContentBox
          title="สภาพแวดล้อมที่เหมาะสม"
          subTitle="มีแสงสว่างเพียงพอและไม่มีสิ่งปิดบังใบหน้า และอยู่ในที่ที่เหมาะสมสำหรับการอบรม
ไม่ควรอบรมระหว่างเดินทาง"
          img="ic_face_id.svg"
        />
      </Column>
    </Column>
  )
}

export default GetReadyTab

export const ContentBox = ({ title, subTitle, img }) => {
  return (
    <StyledContentBox>
      <StyledCircleBox>
        <img src={`/icon/${img}`} />
      </StyledCircleBox>
      <Typography variant="body2b">{title}</Typography>
      <Typography variant="body3" color="text.lightGray">
        {subTitle}
      </Typography>
    </StyledContentBox>
  )
}
