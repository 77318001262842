import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getQueryParams } from '../../utils/lib'

export const objStatusText = {
  SUCCESS: {
    title: 'You have already confirm',
    content: 'คุณได้ยืนยันการจองห้องประชุมเรียบร้อยแล้ว',
    ic: '/icon/ic_confirm.png',
  },
  CONFLICT: {
    title: 'You already confirmed',
    content: 'คุณเคยยืนยันการจองห้องประชุมไปแล้ว',
    ic: '/icon/ic_confirmed.png',
  },
  NOTFOUND: {
    title: "Oops, This link isn't working",
    content: 'กรุณาลองใหม่อีกครั้ง',
    ic: '/icon/ic_incorrect.png',
  },
  EXPIRE: {
    title: 'Oops, This link has expire',
    content: 'กรุณาลองใหม่อีกครั้ง',
    ic: '/icon/ic_incorrect.png',
  },
}

const Booking = () => {
  const caseParam = getQueryParams('case')
  const statusText =
    objStatusText[caseParam.toUpperCase()] || objStatusText.NOTFOUND
  return (
    <Box>
      <img
        src="/logo/bg_confirm_email.png"
        style={{ position: 'absolute', left: 0, top: 0, height: '100%' }}
      />
      <Box
        sx={{
          flexDirection: 'column',
          position: 'absolute',
          top: '30%',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <img src={statusText.ic} style={{ width: '200px' }} />
        <Typography variant="h2">{statusText.title}</Typography>
        <Typography variant="h5">{statusText.content}</Typography>
      </Box>
    </Box>
  )
}

export default Booking
