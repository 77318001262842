import { store } from '../../../App'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import {
  setFiltersData,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setReduxValue,
} from '../../../redux/slices/reportComp'

export const handleConfirmCancelDraft = () => {
  const {
    displayFilters,
    isOpenFilterDrawer,
    isOpenManageColumnDrawer,
    reportsData,
  } = store.getState().reportComp

  const defaultFilter = displayFilters.filter((e) => e?.cantDel)
  if (
    ((displayFilters.length > 0 || reportsData.length > 0) &&
      isOpenFilterDrawer) ||
    isOpenManageColumnDrawer
  ) {
    store.dispatch(
      openDialog({
        type: 'confirm',
        title: 'ยืนยันการละทิ้ง',
        message:
          'เนื้อหาที่สร้างไว้ จะไม่สามารถกู้คืนได้ คุณต้องการละทิ้งใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        handleConfirm: () => {
          store.dispatch(setFiltersData(defaultFilter))
          store.dispatch(setReduxValue({ key: 'reportsData', value: [] }))
          store.dispatch(setReduxValue({ key: 'allCount', value: 0 }))
          store.dispatch(setIsOpenFilterDrawer(false))
          store.dispatch(setIsOpenManageColumnDrawer(false))
          store.dispatch(closeDialog())
        },
        handleCancel: () => {
          store.dispatch(closeDialog())
        },
      }),
    )
  } else {
    store.dispatch(setIsOpenFilterDrawer(false))
    store.dispatch(setIsOpenManageColumnDrawer(false))
  }
}
