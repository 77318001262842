import TruncateMarkup from 'react-truncate-markup'

const TextWithTruncate = (props) => {
  const { isTruncated, setTruncate, setExpand, children, sx, align } = props

  return (
    <TruncateMarkup
      sx={{ ...sx }}
      line="1"
      onTruncate={(isTrunC) => setTruncate(isTrunC)}
      ellipsis={<span>...</span>}
    >
      <div
        onClick={() => setExpand(true)}
        style={{
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'row',
          cursor: isTruncated ? 'pointer' : 'default',
          wordBreak: 'break-all',
          verticalAlign: 'middle',
          justifyContent: align ? align : 'flex-start',
        }}
      >
        {children}
      </div>
    </TruncateMarkup>
  )
}
export default TextWithTruncate
