import React from 'react'
import { useSelector, shallowEqual,  } from 'react-redux'
import TabMenu from '../../../components/TabMenu'
import { tabs } from '../model'
import { handleChangeTab } from './event'

const TabMenuContent = () => {
  const tab = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail.tab,
    shallowEqual,
  )

  return <TabMenu tab={tab} tabs={tabs} handleChange={handleChangeTab} />
}
export default TabMenuContent
