import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const attendanceDialog = createSlice({
  name: 'attendanceDialog',
  initialState,
  reducers: {
    setStartLoading: (state) => {
      state.isLoading = true
    },
    setStopLoading: (state) => {
      state.isLoading = false
    },

    setChange: (state, { payload }) => {
      state.bodyDialog = {
        ...state.bodyDialog,
        ...payload,
      }
    },
    setLearnerList: (state, { payload }) => {
      state.learnerList = payload
    },
    setClearBodyDialog: (state) => {
      state.bodyDialog = initialState.bodyDialog
    },
  },
})

export const {
  setStartLoading,
  setStopLoading,
  setChange,
  setLearnerList,
  setClearBodyDialog,
} = attendanceDialog.actions

export default attendanceDialog.reducer
