import { setAddQuestionOther } from '../../../../../../../redux/slices/eEvaluationForm'

export const handleAddQuestionOther =
  (questionType, sequence) => (dispatch) => {
    dispatch(
      setAddQuestionOther({ value: mapObjQuestions(questionType, sequence) })
    )
  }

export const mapObjQuestions = (questionType, sequence) => {
  let result = {
    title: '',
    questionType,
    sequence,
    url: '',
    mediaType: 'IMAGE',
    setNextQuestion: false, // SINGLE
    setTypeOfAnswers: false, // MULTIPLE
    typeOfAnswers: 'EQUAL', // MULTIPLE
    numberOfAnswers: 0, // MULTIPLE
    isSuggestion: false, // RATING
  }
  const otherObject = {
    id: 'id-1',
    title: '',
    sequence: 1,
    mediaUrl: '',
    answerType: 'CHOICE',
    questionType: questionType,
  }
  switch (questionType) {
    case 'SINGLE':
      return {
        ...result,
        answers: [
          {
            ...otherObject,
            nextQuestionType: 'NONE',
            nextQuestionSequence: 0,
            nextSubQuestionSequence: 0,
          },
        ],
        subQuestions: [],
      }
    case 'MULTIPLE':
      return {
        ...result,
        answers: [otherObject],
      }
    case 'RANKING':
      return {
        ...result,
        answers: [otherObject],
      }
    case 'FREE_TEXT':
      return result
    case 'RATING':
      return {
        ...result,
        questions: [
          {
            id: 'id-1',
            title: '',
            sequence: 1,
          },
        ],
        choices: [
          {
            id: 'id-1',
            title: '',
            sequence: 1,
          },
        ],
      }
    default:
      break
  }
}
