import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDot = styled(Box)(({ theme, data }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor:
    data === 'DONE' ? theme.palette?.success?.main : theme.palette?.error?.main,
}))
