import { StyledRow } from '../../../Styled'
import { Typography } from '@mui/material'

export const RowLabels = ({ title, label, titleSx, labelSx }) => {
  return (
    <StyledRow sx={{ gap: 2, alignItems: 'flex-start' }}>
      <Typography sx={{ ...titleSx }} variant="body1b">
        {title}
      </Typography>
      <Typography sx={{ ...labelSx }} variant="body1">
        {label}
      </Typography>
    </StyledRow>
  )
}
