import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import { handleChange } from '../../event'
import { ROOM_TYPE } from '../../../../../constants/MasterData/meetingRoom'

const Index = () => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const { roomType, roomTypeOther } = useSelector(
    (state) => state.masterDataMeetingRoomForm.information,
    shallowEqual,
  )
  const isOther = roomType === 'OTHER'
  const roomTypeOptions = [
    {
      value: 'CLASSROOM',
      label: ROOM_TYPE.CLASSROOM,
    },
    {
      value: 'COMPUTER_ROOM',
      label: ROOM_TYPE.COMPUTER_ROOM,
    },
    {
      value: 'MEETING_ROOM',
      label: ROOM_TYPE.MEETING_ROOM,
    },
    {
      value: 'OTHER',
      label: ROOM_TYPE.OTHER,
    },
  ]
  return (
    <>
      <Select
        boxSx={{ mt: 3 }}
        dataTestid="select-station"
        id="roomType"
        name="roomType"
        labelText="ประเภทห้อง"
        placeholder="กรุณาเลือก"
        options={roomTypeOptions}
        value={roomType}
        // textError={_.get(errors, `information.roomType`, '')}
        handleChange={(e) =>
          dispatch(
            handleChange({
              key: 'roomType',
              value: _.get(e, 'target.value', ''),
            }),
          )
        }
      />
      {isOther && (
        <TextInput
          required
          type="basic"
          name="roomTypeOther"
          labelText={'ระบุประเภทห้อง (Other)'}
          placeholder="กรุณากรอก"
          value={roomTypeOther}
          onChange={(e) => {
            dispatch(
              handleChange({
                key: 'roomTypeOther',
                value: _.get(e, 'target.value', ''),
              }),
            )
          }}
          textError={_.get(errors, `information.roomTypeOther`, '')}
        />
      )}
    </>
  )
}

export default Index
