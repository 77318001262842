import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
}))

export const StyledBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${theme?.palette?.divider}`,
  marginBottom: '24px',
}))

export const InlineBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(2),
}))

export const BoxFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(3),
  justifyContent: 'unset',
  alignItems: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    gap: theme.spacing(2),
    alignItems: 'none',
  },
}))

export const StyledContainerFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(3),
  width: '100%',
}))

export const StyledGroupFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '50%',
}))

export const StyledSubGroupFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '20%',
  },
  minWidth: '50%',
  gap: '10px 80px',
}))

export const StyledHistoryFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'right',
  alignItems: 'flex-end',
}))

export const StyledCourseRefWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}))
