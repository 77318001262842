import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  Container,
  Dot,
  Row,
  Column,
  StyledButton,
  SubLableBox,
} from '../Styled'
import { Box } from '@mui/material'
import _ from 'lodash'

const ModuleCard = ({
  label,
  subLable,
  labelVariant = 'body2b',
  background,
  dotColor,
  value,
  unit,
  percent,
  handleClickButton,
  fontColor,
  border,
  boxSx = {},
  minWidth = 200,
  padding,
  subPercent,
  icon,
}) => {
  return (
    <Container
      background={background}
      border={!_.isEmpty(border) ? border : 'solid 1px #DBE4F1'}
      minWidth={minWidth}
      sx={boxSx}
      padding={padding}
    >
      {icon && <>{icon}</>}
      <Box>
        <Box display="flex" alignItems={'center'} gap={1} mb={1}>
          {dotColor && <Dot background={dotColor} />}
          <Typography variant={labelVariant}>{label}</Typography>
        </Box>
        {subLable && (
          <SubLableBox background={dotColor} fontColor={fontColor}>
            {subLable}
          </SubLableBox>
        )}
        <Row sx={{ justifyContent: 'space-between' }}>
          <Column>
            <Row sx={{ gap: 1, ml: dotColor ? 2 : '' }}>
              <Typography
                variant="h4"
                sx={{
                  lineHeight: '20px',
                  mt: 1,
                }}
              >
                {value}
              </Typography>
              <Typography
                variant="body2"
                color="text.lightGray"
                sx={{
                  lineHeight: '18px',
                  mt: 'auto',
                }}
              >
                {unit} {subPercent && <>{subPercent}</>}
              </Typography>
            </Row>
            <Row sx={{ gap: 1, ml: dotColor ? 2 : '' }}>
              {percent && (
                <Typography
                  variant="body2"
                  color="text.lightGray"
                  sx={{
                    lineHeight: '18px',
                    mt: 'auto',
                  }}
                >
                  {percent}
                </Typography>
              )}
            </Row>
          </Column>
          {handleClickButton ? (
            <Column>
              <StyledButton
                color="text.lightGray"
                size="small"
                onClick={handleClickButton}
              >
                <ChevronRight fontSize="small" />
              </StyledButton>
            </Column>
          ) : (
            ''
          )}
        </Row>
      </Box>
    </Container>
  )
}

export default ModuleCard
