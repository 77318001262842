import _ from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'

import { initialStateFeedback } from './model'
import { defaultValue } from '../../../constants/eEvaluation'

import {
  addSequenceInArray,
  dataCartQuestions,
  setFiledNextQuestions,
  setFiledTypeDefault,
  setFiledTypeLecturer,
  dataCartSubQuestions,
} from './event'
import { convertFormatDateTime } from '../../../utils/lib'

const eEvaluationFeedback = createSlice({
  name: 'eEvaluationFeedback',
  initialState: initialStateFeedback,
  reducers: {
    setNextQuestions: (state, { payload }) => {
      const { type, value, sequence, sequenceSubQuestions } = payload

      const nextQuestions = state.nextQuestions[type].find(
        (item) => item.sequence === sequence
      )

      if (type === 'subQuestions') {
        const itemSubQuestions = _.get(nextQuestions, 'value', []).find(
          (item) => item.sequence === sequenceSubQuestions
        )
        _.set(itemSubQuestions, 'value', value)
      } else {
        _.set(nextQuestions, 'value', value)
      }
    },
    setFetchDataFeedback: (state, { payload }) => {
      state.eEvaluationType = _.get(
        payload,
        'eEvaluationType',
        defaultValue.eEvaluationType.class
      )
      state.startDate = _.get(payload, 'startDate', '') || state.startDate
      state.toDate = _.get(payload, 'toDate', '') || state.toDate
      state.totalLearnerAnswer = _.get(payload, 'totalLearnerAnswer', 0)

      state.courseAssessments = setFiledTypeDefault(
        _.get(payload, 'courseAssessments[0]', {})
      )
      state.lecturerAssessments = setFiledTypeLecturer(
        _.get(payload, 'lecturerAssessments[0]', {})
      )
      state.courseManagementAssessments = setFiledTypeDefault(
        _.get(payload, 'courseManagementAssessments[0]', {})
      )
      state.questions = dataCartQuestions(payload.questions)

      const { nextSubQuestions, subQuestions } = dataCartSubQuestions(
        payload.questions
      )
      state.subQuestions = subQuestions
      state.nextQuestions = {
        ...initialStateFeedback.nextQuestions,
        lecturerAssessments: addSequenceInArray(
          _.get(payload, 'lecturerAssessments[0].staff', []),
          true
        ),
        questions: setFiledNextQuestions(payload.questions),
        subQuestions: nextSubQuestions,
      }
    },
    setFilterFeedback: (state, { payload }) => {
      const startDate = convertFormatDateTime({
        value: _.get(payload, 'startDate', new Date()),
        type: 'date',
      })
      const toDate = convertFormatDateTime({
        value: _.get(payload, 'toDate', new Date()),
        type: 'date',
      })
      state.filter = payload
      state.startDate = startDate
      state.toDate = toDate
    },
    setClearData: (state) => {
      state.eEvaluationType = initialStateFeedback.eEvaluationType
      state.filter = initialStateFeedback.filter
      state.totalLearnerAnswer = initialStateFeedback.totalLearnerAnswer
      state.startDate = initialStateFeedback.startDate
      state.toDate = initialStateFeedback.toDate
      state.nextQuestions = initialStateFeedback.nextQuestions
      state.courseAssessments = initialStateFeedback.courseAssessments
      state.lecturerAssessments = initialStateFeedback.lecturerAssessments
      state.courseManagementAssessments =
        initialStateFeedback.courseManagementAssessments
      state.questions = initialStateFeedback.questions
      state.subQuestions = initialStateFeedback.subQuestions
    },
  },
})

export const draftQuestion = createSelector(
  (state) => state.eEvaluationFeedback,
  (state) => state.questions.map((item) => item.sequence)
)

export const draftLecturer = createSelector(
  (state) => state.eEvaluationFeedback,
  (state) => {
    if (!_.get(state, 'lecturerAssessments.staff', []).length) return []
    return state.lecturerAssessments.staff.map((item) => item.sequence)
  }
)

export const draftSubQuestions = (sequence) =>
  createSelector(
    (state) => state.eEvaluationFeedback,
    (state) => {
      const listSubQuestion = state.subQuestions
      if (!listSubQuestion.length) return []
      const itemSubQuestion = listSubQuestion.find(
        (item) => item.sequence === sequence
      )
      return _.get(itemSubQuestion, 'value', [])
    }
  )

export const {
  setFetchDataFeedback,
  setNextQuestions,
  setFilterFeedback,
  setClearData,
} = eEvaluationFeedback.actions
export default eEvaluationFeedback.reducer
