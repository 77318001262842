import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from '../Styled'
import FilterDrawer from '../FilterDrawer'
import { breadcrumbBackgroundList } from '../model'
import HeaderList from './Header'
import { fetchDataList } from './event'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../constants/eCertification'
import { validateEPermission } from '../../../utils/lib'

const List = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList(
        'fetch',
        table,
        page,
        status,
        filterProp,
        searchText,
        isInitial
      )
    )
    setInitial(false)
  }, [limit, order, page, sort])
  const hasCreateBackgroundPermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.CREATE_BG,
  })

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">
            {breadcrumbBackgroundList[1].title}
          </Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbBackgroundList} />
        {hasCreateBackgroundPermission && <HeaderList />}
        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="background"
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default List
