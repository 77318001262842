import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import TextInput from '../../../../../../../components/Input/TextInput'
import ActiveSwitch from '../../../../../components/ActiveSwitch'
import { StyledColumn, StyledRow } from '../../../../Styled'
import { handleInputChange } from '../events'
import { handleSwitchChange } from './events'
import { ButtonColorPreview } from './Styled'

const ButtonForm = () => {
  const { values, errors, setValues } = useFormikContext()
  const no = _.get(values, 'no', 1)
  const primaryStatus = _.get(values, 'primaryStatus', false)
  const secondaryStatus = _.get(values, 'secondaryStatus', false)
  const colorDisabled = !primaryStatus && !secondaryStatus

  return (
    <>
      <StyledColumn>
        <StyledRow sx={{ gap: 3, alignItems: 'start' }}>
          <TextInput
            boxSx={{ width: 'calc(50% - 92px)' }}
            name="primaryButtonTitle"
            type="basic"
            labelText="Primary Button"
            id="input-primary-button"
            value={_.get(values, 'primaryButtonTitle', '')}
            onChange={(e) => handleInputChange(e, setValues)}
            textError={_.get(errors, 'primaryButtonTitle', '')}
            textInfo="แนะนำให้ใช้ข้อความที่มีความยาวประมาณ 20 ตัวอักษร "
          />
          <TextInput
            boxSx={{ width: 'calc(50% - 92px)' }}
            name="primaryButtonURL"
            type="basic"
            labelText="URL"
            id="input-primary-url"
            value={_.get(values, 'primaryButtonURL', '')}
            onChange={(e) => handleInputChange(e, setValues)}
            textError={_.get(errors, 'primaryButtonURL', '')}
          />
          <ButtonActive
            name="primaryStatus"
            checked={primaryStatus}
            setValue={setValues}
            testId={`primary-${no}`}
          />
        </StyledRow>
        <StyledRow sx={{ gap: 3, alignItems: 'start' }}>
          <TextInput
            boxSx={{ width: 'calc(50% - 92px)' }}
            name="secondaryButtonTitle"
            type="basic"
            labelText="Secondary Button"
            id="input-secondary-button"
            value={_.get(values, 'secondaryButtonTitle', '')}
            onChange={(e) => handleInputChange(e, setValues)}
            textError={_.get(errors, 'secondaryButtonTitle', '')}
            textInfo="แนะนำให้ใช้ข้อความที่มีความยาวประมาณ 20 ตัวอักษร "
          />
          <TextInput
            boxSx={{ width: 'calc(50% - 92px)' }}
            name="secondaryButtonURL"
            type="basic"
            labelText="URL"
            id="input-secondary-url"
            value={_.get(values, 'secondaryButtonURL', '')}
            onChange={(e) => handleInputChange(e, setValues)}
            textError={_.get(errors, 'secondaryButtonURL', '')}
          />
          <ButtonActive
            name="secondaryStatus"
            checked={secondaryStatus}
            testId={`secondary-${no}`}
          />
        </StyledRow>
      </StyledColumn>

      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
        <Typography variant="body2" color="text.secondary">
          Theme Color Button
        </Typography>
        <RadioGroup
          name="colorButton"
          value={_.get(values, 'colorButton', 'PRIMARY')}
          onChange={(e) => handleInputChange(e, setValues)}
        >
          <FormControlLabel
            disabled={colorDisabled}
            sx={{ margin: 0, gap: '18px' }}
            value="PRIMARY"
            control={<Radio />}
            label={<ButtonColorPreview value="primary" />}
          />
          <FormControlLabel
            disabled={colorDisabled}
            sx={{ margin: 0, gap: '18px' }}
            value="SECONDARY"
            control={<Radio />}
            label={<ButtonColorPreview value="secondary" />}
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default ButtonForm

export const ButtonActive = ({ name, checked, testId }) => {
  const { setValues } = useFormikContext()
  return (
    <ActiveSwitch
      sx={{ mt: 3.5 }}
      name={name}
      checked={checked}
      onChange={(e) => handleSwitchChange(e, setValues)}
      dataTestId={`active-${testId}`}
    />
  )
}
