import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'

import React, { useRef, useState } from 'react'
import { getMoreOptions } from './events'
import {
  handleClose,
  onOptionClick,
} from '../../../../../modules/EExamination/ExaminerList/Header/DropdownButton/events'
import { StyledPopper } from './Styled'
import IconButton from '@mui/material/IconButton'
import MoreVert from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'

const DropdownButton = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const getMoreOptionsList = getMoreOptions()
  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem>
                  {getMoreOptionsList.map((option, index) => {
                    return (
                      <label key={index} htmlFor={`menu-${option.method}`}>
                        <MenuItem
                          key={index}
                          component="span"
                          data-testid={`menu-${index}`}
                          onClick={() => {
                            dispatch(onOptionClick(option.method))
                          }}
                        >
                          <Typography variant="body1" component="span">
                            {option.text}
                          </Typography>
                        </MenuItem>
                      </label>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
