import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledHeadLabel } from './Styled'
import { openHistoryDrawer } from '../../handler/openHistoryDrawer'
import Table from './Table'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'

const index = ({ open }) => {
  const { assetDrawerInfo } = useSelector(
    (state) => ({
      assetDrawerInfo: state.crud.assetDrawerInfo,
      stockDetail: state.stockList.stockDetail,
    }),
    shallowEqual,
  )
  let product = _.get(assetDrawerInfo, 'product', '')
  let warehouse = _.get(assetDrawerInfo, 'warehouse', '')
  if (assetDrawerInfo?.productWarehouse) {
    product = _.get(assetDrawerInfo, 'productWarehouse.product', '')
    warehouse = _.get(assetDrawerInfo, 'productWarehouse.warehouse', '')
  }

  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={open}
        onClose={() => openHistoryDrawer(false)}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: 'auto' },
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">ประวัติการเคลื่อนไหว</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openHistoryDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box maxWidth="1200px" mx={2}>
            <Table product={product} warehouse={warehouse} />
          </Box>
          {/* <LoadingExaminer isLoading={isLoadingDrawer} /> */}
        </Box>
      </Drawer>
    </>
  )
}

export default index
