import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Select from '../../../components/Input/Select'
import Breadcrumbs from '../../../components/Breadcrumbs'
import CalendarViewDayOutlined from '@mui/icons-material/CalendarViewDayOutlined'
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth'

import { breadcrumbList } from '../model'
import { handleChange } from './events'
import { BoxLabel, BoxMenu, BoxContent, BoxMenuSelect } from './Styled'

const Index = () => {
  const dispatch = useDispatch()
  const { defaultMenu, station, listStation, isLoading } = useSelector(
    (state) => ({
      defaultMenu: state.manageClass.defaultMenu,
      station: state.manageClass.station,
      listStation: state.manageClass.listStation,
      isLoading: state.manageClass.isLoading,
    }),
    shallowEqual
  )

  return (
    <BoxContent>
      <BoxLabel>
        <Typography variant="h4">คลาสทั้งหมด</Typography>
        <Breadcrumbs menuList={breadcrumbList} />
      </BoxLabel>
      <BoxMenu>
        <BoxMenuSelect>
          <Select
            disabled={isLoading}
            dataTestid="select-station"
            id="selectStation"
            name="selectStation"
            labelText=""
            type="basic"
            showTextError={false}
            options={listStation}
            handleChange={(event) =>
              dispatch(
                handleChange({
                  key: 'station',
                  value: _.get(event, 'target.value', ''),
                })
              )
            }
            value={station}
          />
        </BoxMenuSelect>
        <Box sx={{ display: 'flex' }}>
          <Button
            data-testid="btn-menu-list"
            disabled={isLoading}
            sx={{
              height: 40,
              borderRadius: '4px 0px 0px 4px',
            }}
            variant={defaultMenu === 'list' ? 'contained' : 'outlined'}
            startIcon={<CalendarViewDayOutlined sx={{ p: 0, ml: 1 }} />}
            onClick={() =>
              dispatch(
                handleChange({
                  key: 'defaultMenu',
                  value: 'list',
                })
              )
            }
          />
          <Button
            data-testid="btn-menu-calendar"
            disabled={isLoading}
            sx={{
              height: 40,
              borderRadius: '0px 4px 4px 0px',
            }}
            variant={defaultMenu === 'calendar' ? 'contained' : 'outlined'}
            startIcon={<CalendarViewMonth sx={{ ml: 1 }} />}
            onClick={() =>
              dispatch(
                handleChange({
                  key: 'defaultMenu',
                  value: 'calendar',
                })
              )
            }
          />
        </Box>
      </BoxMenu>
    </BoxContent>
  )
}
export default Index
