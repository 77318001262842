import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const StyledAlertBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: theme?.palette?.info?.background,
  borderRadius: '4px',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  marginTop: theme.spacing(2),
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))

export const BoxWarning = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}))

export const BoxIconInfo = styled(InfoOutlinedIcon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(0.5),
  },
}))
