import _ from 'lodash'

export const getMediaType = (data) => {
  const image = _.get(data, 'image', '')
  const videoUrl = _.get(data, 'videoURL', '')
  return image != '' ? 'IMAGE' : videoUrl != '' ? 'VIDEO' : 'NO CONTENT'
}

export const getDefaultValue = (data, key) => {
  const value = _.get(data, key, '')
  return value == '' ? '-' : value
}
