import {
  // setEquipmentSelected,
  setFetchData,
} from '../../../redux/slices/masterData/meetingRoomForm'
import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/masterData/meetingRoomForm/index'
import { getMeetingRoomById } from '../../../services/masterData/meetingRoom'
import _ from 'lodash'
import { setFieldUploadValue } from '../../../redux/slices/uploadFile'
import { uniqId } from '../../../services/util'

export const fetchMeetingRoom = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const data = await dispatch(getMeetingRoomById(uuid))

  const information = _.get(data, 'payload', {})
  const meetingRoomEquipment = _.get(information, 'meetingRoomEquipment', [])

  const arrayMeetingRoomEquipment = meetingRoomEquipment.map((equip, idx) => {
    const equipment = _.get(equip, 'equipment.uuid', '')
    const equipmentName = _.get(equip, 'equipment.equipmentName', '')
    let newEquipment = { ...equip }
    newEquipment = {
      ...newEquipment,
      equipment: equipment,
      equipmentName: equipmentName,
      meetingRoom: uuid,
      id: idx + 1,
    }

    return newEquipment
  })

  const meetingRoomImages = _.get(information, 'image', [])
  const arrayMeetingRoomImages = _.map(meetingRoomImages, (img) => {
    const displayName = _.get(img, 'title', '')
    const fileType = displayName.split('.').pop()
    return {
      ...img,
      displayName,
      fileType,
      key: _.get(img, 'imageKey', ''),
      _id: uniqId(),
      percent: 100,
      fileSize: _.get(img, 'imageFileSize', ''),
    }
  })

  dispatch(
    setFieldUploadValue({
      key: `formData.meetingRoomImages`,
      value: { files: arrayMeetingRoomImages, isUploading: false },
    }),
  )

  const floor = _.get(information, 'floor', null)
  const sqm = _.get(information, 'sqm', null)
  const capacityClassroom = _.get(information, 'capacityClassroom', null)
  const initialInformation = {
    uuid: _.get(information, 'uuid', ''),
    stationUuid: _.get(information, 'station.uuid', ''),
    stationName: _.get(information, 'station.station', ''),
    roomName: _.get(information, 'roomName', ''),
    floor: !_.isNull(floor) ? floor : null,
    sqm: !_.isNull(sqm) ? sqm : null,
    capacityClassroom: !_.isNull(capacityClassroom) ? capacityClassroom : null,
    roomFunction: _.get(information, 'roomFunction', ''),
    roomType: _.get(information, 'roomType', ''),
    status: _.get(information, 'status', ''),
    remark: _.get(information, 'remark', null),
    createdAt: _.get(information, 'createdAt', ''),
    updatedAt: _.get(information, 'updatedAt', ''),
    createdBy: _.get(information, 'createdBy', ''),
    updatedBy: _.get(information, 'updatedBy', ''),
    meetingRoomImages: arrayMeetingRoomImages,
    meetingRoomEquipment: arrayMeetingRoomEquipment,
    meetingRoomImagesUploadList: [],
  }
  const width = _.get(information, 'width', null)
  const length = _.get(information, 'length', null)
  const height = _.get(information, 'height', null)
  const capacityTheater = _.get(information, 'capacityTheater', null)
  const capacityGroup = _.get(information, 'capacityGroup', null)
  const capacityUShape = _.get(information, 'capacityUShape', null)
  let newInformation = { ...initialInformation }
  const roomFunction = _.get(information, 'roomFunction', null)
  const roomType = _.get(information, 'roomType', null)

  newInformation = {
    ...newInformation,
    width: width,
    length: length,
    height: height,
    capacityTheater: capacityTheater,
    capacityUShape: capacityUShape,
    capacityGroup: capacityGroup,
  }
  if (roomFunction === 'OTHER') {
    newInformation = {
      ...newInformation,
      roomFunctionOther: _.get(information, 'roomFunctionOther', ''),
    }
  }
  if (roomType === 'OTHER') {
    newInformation = {
      ...newInformation,
      roomTypeOther: _.get(information, 'roomTypeOther', ''),
    }
  }

  dispatch(
    setFetchData({
      information: newInformation,
    }),
  )
  dispatch(stopLoading())
}
