import { Typography } from '@mui/material'
import { StyledColumn, StyledRow } from '../../Styled'
import ListCourse from './ListCourse'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

const ListCourseSection = () => {
  const { courseShowList } = useSelector(
    (state) => ({
      courseShowList: state.reportCourseSettingDashboard.courseShowList,
    }),
    shallowEqual,
  )

  return (
    <StyledColumn sx={{ gap: 1.5, mt: 3 }}>
      <Typography variant="h5">แสดงข้อมูลหลักสูตร</Typography>
      <StyledRow
        sx={{
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {_.map(courseShowList, (item, idx) => {
          return <ListCourse key={idx} {...item} />
        })}
      </StyledRow>
    </StyledColumn>
  )
}
export default ListCourseSection
