import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const TitleEdit = ({ type }) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      {type === 'cancel' ? (
        <>
          <img src="/icon/bi_person-cancel.svg" />
          <Typography variant="body1b">ยกเลิกวิทยากร</Typography>
        </>
      ) : (
        <>
          <img src="/icon/bi_person-update.svg" />
          <Typography variant="body1b">เปลี่ยนวิทยากร</Typography>
        </>
      )}
    </Box>
  )
}

export default TitleEdit
