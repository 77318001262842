export const SYNC_AGENT = 'SYNC_AGENT'
export const OWNER = {
  ALL: 'ALL',
}

export const PERMISSION = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  MENU: 'MENU',
  ADMIN: 'ADMIN',
}

export const permissionSyncAgent = [
  {
    module: SYNC_AGENT,
    owner: OWNER.ALL,
    permission: [PERMISSION.ADMIN],
  },
]
