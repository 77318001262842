import React, { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, Card, Typography } from '@mui/material'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import ClearIcon from '@mui/icons-material/Clear'
import { handleRemoveInsideContact } from '../../handler/handleRemoveInsideContact'
import _ from 'lodash'

export const InsideContactInfo = ({ index }) => {
  const { insideContacts, mDataInside } = useSelector(
    (state) => ({
      insideContacts: state.crud.formData.insideContacts,
      mDataInside: state.crud.masterData.insideContact,
    }),
    shallowEqual,
  )

  const insideContactOptions = useMemo(() => {
    const options = _.defaultTo(mDataInside?.options, []).filter((mData) => {
      const findInside = insideContacts.find(
        (ic) => ic?.name?.value === mData?.value,
      )
      return _.isEmpty(findInside)
    })
    return { isLoading: false, options: options }
  }, [mDataInside, insideContacts])

  return (
    <Card sx={{ p: 2 }}>
      <Box display="flex" gap={3}>
        <Box display="flex" alignItems="start" width={'100%'}>
          <CustomDropdown
            masterDataName="insideContact"
            required
            labelName="ชื่อผู้ติดต่อ"
            fieldName={`insideContacts[${index}].name`}
            placeholder="เลือกรายชื่อพนักงาน"
            isCustomOption={true}
            customOptions={insideContactOptions}
          />
        </Box>
        {insideContacts?.length > 1 && (
          <Box
            position="relative"
            width="30px"
            onClick={() => handleRemoveInsideContact(index)}
          >
            <ClearIcon
              sx={{ position: 'absolute', top: '30px', cursor: 'pointer' }}
            />
          </Box>
        )}
      </Box>
      {insideContacts?.[index]?.name?.value && (
        <>
          <Card sx={{ my: 3, p: 2 }}>
            <Box display="flex" justifyContent="start" mb={2}>
              <Typography variant="body2" mr={2} minWidth="120px">
                คำนำหน้าชื่อ
              </Typography>
              <Typography variant="body2" mr={2} minWidth="230px">
                {insideContacts?.[index]?.name?.prefixTH ?? '-'}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                ชื่อ-สกุล
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {insideContacts?.[index]?.name?.label ?? '-'}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="start">
              <Typography variant="body2" mr={2} minWidth="120px">
                เบอร์โทรศัพท์
              </Typography>
              <Typography variant="body2" mr={2} minWidth="230px">
                {insideContacts?.[index]?.name?.tel ?? '-'}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                Email
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {insideContacts?.[index]?.name?.email ?? '-'}
              </Typography>
            </Box>
          </Card>
        </>
      )}
    </Card>
  )
}
