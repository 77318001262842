import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// import Form from './form'
import { StyledHeadLabel } from './Styled'
import { openDetailDrawer } from '../../handler/openDetailDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'
import { Stock } from './components/Stock'
import { SKU } from './components/SKU'
import { Asset } from './components/Asset'
import { License } from './components/License'
import _ from 'lodash'

const DetailDrawer = () => {
  const { isOpenDetailDrawer, formData } = useSelector(
    (state) => ({
      isOpenDetailDrawer: state.crud?.isOpenDetailDrawer,
      formData: state.crud?.formData,
    }),
    shallowEqual,
  )
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  // const from = _.get(formData, 'from', '')
  // const status = _.get(formData, 'status', '')
  // const checkIfImportProduct =
  //   from?.value === fromTypeEnum.IMPORT_PRODUCT ? true : false
  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={isOpenDetailDrawer}
        onClose={() => openDetailDrawer(false)}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: 1000 },
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">รายละเอียดทรัพย์สิน</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openDetailDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box mx={3}>
            {productType === PRODUCT_TYPE.STOCK && <Stock />}
            {productType === PRODUCT_TYPE.STOCK_ASSET && <SKU />}
            {productType === PRODUCT_TYPE.ASSET && <Asset />}
            {productType === PRODUCT_TYPE.LICENSE && <License />}
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default DetailDrawer
