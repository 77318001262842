import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="left" {...props} />
))(() => ({ height: '100vh', '& .MuiDrawer-paper': { width: 248 } }))

export const StyledContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
}))