import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Help from '@mui/icons-material/Help'

import { CustomWidthTooltip, StyledSwitchContainer } from './Styled'

const VersionSwitch = ({ title, onChange, isDisable, isAvailable }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <StyledSwitchContainer>
      <Box>
        <Typography>เปิดใช้งาน</Typography>
        <CustomWidthTooltip
          onClose={() => setOpen(false)}
          open={open}
          title={title}
        >
          <Help onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen} />
        </CustomWidthTooltip>
      </Box>
      <Switch
        data-testid="switch-status"
        disabled={isDisable ?? true}
        checked={isAvailable ?? true}
        onChange={onChange}
      />
    </StyledSwitchContainer>
  )
}

export default VersionSwitch
