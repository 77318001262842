import React from 'react'
import { StyledColumnTextBox } from '../styled'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

export const UploadError = () => {
  const { uploadError, fileName } = useSelector(
    (state) => ({
      uploadError: state.uploadCropper.uploadError,
      fileName: state.uploadCropper.fileName,
    }),
    shallowEqual,
  )

  if (_.isEmpty(uploadError)) return null
  return (
    <>
      <img src="/icon/circle_error.svg" alt="" />

      <StyledColumnTextBox>
        {uploadError === 'ERROR_FILE_FAIL' ? (
          <>
            <Typography variant="body1b" color="text.secondary">
              อัพโหลดไฟล์ไม่สำเร็จ
            </Typography>
            <Typography variant="h6" color="primary.main">
              {fileName}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1b" color="error.main">
              ไฟล์ไม่ถูกต้อง
            </Typography>
            <Typography variant="body1b" color="text.secondary">
              กรุณาอัปโหลดไฟล์ตามชนิดที่กำหนดเท่านั้น
            </Typography>
            <Typography color="text.secondary">.jpeg / .jpg / .png</Typography>
          </>
        )}
      </StyledColumnTextBox>
    </>
  )
}
