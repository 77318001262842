import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'

import TextInput from '../../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import { getErrorMessage } from '../../event'
import {
  handleChange,
  handleDelete,
  handleChangeScoreAnswer,
} from '../../Answer/event'
import { TooltipForm } from '../../Styled'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const AnswerTrueFalse = (props) => {
  const { indexQuestion, indexAnswer, answers, keyQuestion, disableForm } =
    props
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { title, score, booleanAns } = useMemo(
    () => answers[indexAnswer],
    [answers[indexAnswer]]
  )

  const propsEvent = {
    keyQuestion,
    indexAnswer,
    answers,
  }
  const answerErrorMessage = getErrorMessage({
    field: 'SUB_QUESTION',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })

  return (
    <Styled.BoxAnswer>
      <Styled.ContentInput>
        <Typography
          variant="h6"
          sx={{
            marginTop: 1,
            minWidth: 15,
          }}
        >
          {indexAnswer + 1})
        </Typography>
        <TextInput
          id="title-answer"
          name="title"
          placeholder="คำถาม"
          type="basic"
          multiline
          maxRows={2}
          boxSx={{ mt: 1, width: '100%' }}
          disabled={disableForm}
          value={title}
          onChange={(e) =>
            dispatch(
              handleChange({
                ...propsEvent,
                key: 'title',
                value: _.get(e, 'target.value', ''),
              })
            )
          }
          textError={answerErrorMessage}
        />
      </Styled.ContentInput>
      <RadioGroup
        sx={{ width: '100%' }}
        value={booleanAns}
        onChange={(event) => {
          dispatch(
            handleChange({
              ...propsEvent,
              key: 'booleanAns',
              value: _.get(event, 'target.value', ''),
            })
          )
        }}
      >
        <Styled.StyledRadioContent>
          <Styled.StyledRadioFlex>
            <Typography variant="body1b">
              คำตอบ{' '}
              <Typography variant="span" color="error.main">
                *
              </Typography>{' '}
              :
            </Typography>
            <FormControlLabel
              disabled={disableForm}
              value={true}
              control={<Radio />}
            />
            <Typography variant="body1b">ใช่</Typography>
            <FormControlLabel
              disabled={disableForm}
              value={false}
              control={<Radio />}
            />
            <Typography variant="body1b">ไม่ใช่</Typography>
          </Styled.StyledRadioFlex>
          <Styled.StyledScoreBox>
            <Styled.ContentScoreAndButton>
              <Styled.BoxScoreAnswer>
                <TextInput
                  id="score"
                  name="score"
                  type="basic"
                  boxSx={{ mt: 1 }}
                  disabled={disableForm}
                  endAdornmentText={'คะแนน'}
                  value={score}
                  onChange={(e) =>
                    dispatch(
                      handleChangeScoreAnswer({
                        ...propsEvent,
                        value: e.target.value,
                      })
                    )
                  }
                />
              </Styled.BoxScoreAnswer>
            </Styled.ContentScoreAndButton>
            <Styled.BoxIcon>
              <TooltipForm
                placement="bottom"
                title={<Typography variant="tooltip">ลบ</Typography>}
              >
                <IconButton
                  sx={{ mt: -2 }}
                  onMouseLeave={() => setIsHover(false)}
                  onMouseOver={() => setIsHover(true)}
                  onClick={() =>
                    !disableForm && dispatch(handleDelete(propsEvent))
                  }
                  data-testid={`btn-delete-${indexAnswer}`}
                >
                  <CloseOutlined color={isHover ? 'primary' : 'action'} />
                </IconButton>
              </TooltipForm>
            </Styled.BoxIcon>
          </Styled.StyledScoreBox>
        </Styled.StyledRadioContent>
      </RadioGroup>
    </Styled.BoxAnswer>
  )
}
export default AnswerTrueFalse
