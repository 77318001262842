import { store } from '../../../../App'
import { openDialog } from '../../../../redux/slices/dialog'
import ImportTemplateDialog from '../components/ImportTemplateDialog'

export const handleOpenImportTemplateDialog = () => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: false,
      content: <ImportTemplateDialog />,
    }),
  )
}
