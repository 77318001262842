import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, Divider, IconButton } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import * as events from './event'
import { StyledColumn } from '../Styled'
import {
  arrayStatus,
  arrayCheckbox,
  defaultFilter,
  arrayCheckboxOther,
} from './model'
import {
  setToggleFilterDrawer,
  setFilterTotal,
} from '../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../components/FilterDrawer/Styled'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../utils/lib'
import DateRangeInput from '../../../components/DateRangeInput'
import InputBase from '@mui/material/InputBase'
import _ from 'lodash'
import { E_EXAM_TYPE } from '../../../constants/eExamination'

export const ListCheckbox = ({
  stateFilter,
  setFilter,
  eExamType,
  timeList,
  regionsList,
}) => {
  let listArrayByType = arrayCheckbox
  if (eExamType === E_EXAM_TYPE.OTHER) {
    listArrayByType = arrayCheckboxOther
  }
  if (eExamType === E_EXAM_TYPE.COMPANY) {
    listArrayByType = listArrayByType.filter(
      (item) => item.nameInput !== 'region',
    )
  }
  if (!_.isEmpty(timeList)) {
    timeList = timeList.map((item) => {
      return {
        ...item,
        label: item.timeName,
        value: item.uuid,
        name: item.uuid,
      }
    })
  }
  if (!_.isEmpty(regionsList)) {
    regionsList = regionsList.map((item) => {
      return {
        ...item,
        label: item.regionName,
        value: item.uuid,
        name: item.uuid,
      }
    })
  }
  return listArrayByType.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item?.type === 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={
                    item.nameInput == 'peopleNumber'
                      ? {
                          'data-testid': `input-${item.nameInput}`,
                          type: 'number',
                          min: '0',
                          onInput: handleNumberInput,
                          onKeyDown: handleNumberKeyDown,
                          onPaste: handlePasteFormat,
                        }
                      : { 'data-testid': `input-${item.nameInput}` }
                  }
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.isDate && (
                <DateRangeInput
                  dateState={{
                    startDate: stateFilter[item.nameInput].startDate
                      ? stateFilter[item.nameInput].startDate
                      : new Date(),
                    endDate: stateFilter[item.nameInput].toDate
                      ? stateFilter[item.nameInput].toDate
                      : new Date(),
                    display: stateFilter[item.nameInput].displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      item.nameInput,
                      selectedDates,
                      stateFilter,
                      setFilter,
                    )
                  }}
                  placeholder={'จากวันที่ - ถึงวันที่'}
                />
              )}

              {item.type == 'checkbox' && (
                <StyledList>
                  <ListResult
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={
                      item.nameInput === 'time'
                        ? timeList
                        : item.nameInput === 'region'
                        ? regionsList
                        : item.list
                    }
                    listKeys={item.nameInput}
                  />
                </StyledList>
              )}

              {item.type == 'region' && (
                <StyledList>
                  <ListRegion
                    status={stateFilter.regionChecked}
                    filter={stateFilter}
                    setFilter={setFilter}
                    optionList={regionsList}
                  />
                </StyledList>
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListStatus = ({ status, filter, setFilter }) => {
  return arrayStatus.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'status',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListRegion = ({ filter, setFilter, optionList }) => {
  return optionList.map((item, index) => {
    const isChecked = _.includes(_.get(filter, 'region', []), item.uuid)
    return (
      <FormControlLabel
        key={index}
        label={item.regionName}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.uuid}
            checked={isChecked}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'region',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(filter[listKeys], item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const { id } = useParams()
  const { regionsList, eExamType, timeList } = useSelector(
    (state) => ({
      regionsList: state.eExamList.regionsList,
      eExamType: state.eExamList.eExamType,
      timeList: state.eExamList.timeList,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  useEffect(() => {
    setFilter(defaultFilter)
    setFilterMemo(defaultFilter)
    dispatch(setFilterTotal(defaultFilter))
  }, [eExamType])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledColumn sx={{ position: 'relative' }}>
        <ListCheckbox
          listArray={arrayCheckbox}
          stateFilter={filter}
          setFilter={setFilter}
          eExamType={eExamType}
          timeList={timeList}
          regionsList={regionsList}
        />
      </StyledColumn>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => events.handleResetFilter(setFilter, defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter, id))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
