export const arrayStatus = [
  { label: 'ฉบับร่าง', name: 'draftChecked' },
  { label: 'รอเปิดรับสมัคร', name: 'waitingChecked' },
  { label: 'เปิดรับสมัคร', name: 'openChecked' },
  { label: 'ปิดรับสมัคร', name: 'clossChecked' },
  { label: 'กำลังอบรม', name: 'inProgressChecked' },
  { label: 'จบการอบรม', name: 'successChecked' },
  { label: 'เสร็จสมบูรณ์', name: 'completChecked' },
  { label: 'ยกเลิกรอบอบรม', name: 'cancelChecked' },
]

export const arrayOnShelfStatus = [
  { label: 'เปิดใช้งาน', name: 'activeChecked' },
  { label: 'ปิดใช้งาน', name: 'inactiveChecked' },
  { label: 'ถูกลบแล้ว', name: 'deletedChecked' },
]

export const arrayCheckbox = [
  {
    label: 'ชื่อรอบอบรม',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อรอบอบรม',
    type: 'text',
  },
  {
    label: 'ชื่อวิทยากร',
    nameCheckBox: 'nameSpearkerChecked',
    nameInput: 'nameSpearker',
    placeholder: 'ค้นหาชื่อวิทยากร',
    type: 'text',
  },
  {
    label: 'ช่วงเวลารอบอบรม',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'วัน',
    type: 'date',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'การรับสมัคร',
    nameCheckBox: 'onShelfStatusChecked',
    nameInput: 'onShelfStatus',
    type: 'checkbox',
    list: arrayOnShelfStatus,
  },
]

export const defaultFilter = {
  nameChecked: false,
  name: '',
  nameSpearkerChecked: false,
  nameSpearker: '',
  dateChecked: false,
  startDate: new Date(),
  endDate: new Date(),
  displayDate: false,
  statusChecked: false,
  status: {
    waitingChecked: false,
    inProgressChecked: false,
    successChecked: false,
    cancelChecked: false,
    draftChecked: false,
  },
  onShelfStatusChecked: false,
  onShelfStatus: {
    activeChecked: false,
    inactiveChecked: false,
    deletedChecked: false,
  },
}
