import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import Table from '../../../../../../components/redux/Table'
import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { fetchLearnerCheckHistory } from './events'

const History = ({ isOpen, onCloseDrawer, isStandalone }) => {
  const idParams = useParams()
  const { table, learnerExam } = useSelector(
    (state) => ({
      table: state.table.table,
      learnerExam: state.manageClassCheckExam.learnerExam,
    }),
    shallowEqual
  )
  const { page, limit } = table
  const idProps = {
    ...idParams,
    uuid: _.get(learnerExam, 'uuid', ''),
  }
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen)
      dispatch(fetchLearnerCheckHistory({ table, idProps, isStandalone }))
  }, [isOpen, page, limit])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">ประวัติการแก้ไข</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <Box sx={{ m: 3, gap: 4 }}>
          <Table />
        </Box>
      </StyledContent>
    </StyledDrawer>
  )
}

export default History
