import { useEffect } from 'react'
import { CalendarCardStyle, StyledColumn } from '../../../Styled'
import { HeaderCalendar } from './HeaderCalendar'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './Calendar.css'
import { createRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ColorInformation from './ColorInformation'
import {
  handleClickEvent,
  handleDateRender,
  mutateResultToEvent,
  renderEvent,
} from '../../event'
import { Box } from '@mui/system'
import { Loading } from './Loading'
import { FilterDrawer } from './FilterDrawer'
import Popover from '@mui/material/Popover'
import {
  setInitialCalendar,
  setReduxValue,
} from '../../../../../redux/slices/roomManagement/Dashboard'
import { PopUp } from './PopUp'
import { dayList } from './constanst'
import dayjs from 'dayjs'
import { isValidateDate } from '../../../../../utils/lib'

export const CalendarCard = () => {
  const calendarComponentRef = createRef()
  const dispatch = useDispatch()

  const {
    roomDetailCalendar,
    viewCalendar,
    isCalendarLoading,
    isPopupEventSummary,
    anchorElTop,
    anchorElLeft,
    dateQueryStringCalendar,
  } = useSelector(
    (state) => ({
      roomDetailCalendar: state.roomManagement.roomDetailCalendar,
      viewCalendar: state.roomManagement.viewCalendar,
      isCalendarLoading: state.roomManagement.isCalendarLoading,
      isPopupEventSummary: state.roomManagement.isPopupEventSummary,
      anchorElTop: state.roomManagement.anchorElTop,
      anchorElLeft: state.roomManagement.anchorElLeft,
      dateQueryStringCalendar: state.roomManagement.dateQueryStringCalendar,
    }),
    shallowEqual,
  )
  useEffect(() => {
    return () => {
      dispatch(setInitialCalendar())
    }
  }, [])

  return (
    <CalendarCardStyle>
      <HeaderCalendar calendarComponentRef={calendarComponentRef} />
      <ColorInformation />
      <StyledColumn>
        <Box id="room-calendar" sx={{ overflowY: 'auto' }}>
          <FullCalendar
            ref={calendarComponentRef}
            timeZone={'GMT+7'}
            initialView={viewCalendar}
            firstDay={1}
            header={false}
            eventLimit={2}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            allDayText={'ทั้งวัน'}
            defaultDate={
              isValidateDate(dateQueryStringCalendar)
                ? dateQueryStringCalendar
                : null
            }
            columnHeaderText={function (date) {
              let fullDate = ''
              if (viewCalendar === 'timeGridWeek') {
                fullDate = dayjs(date).format('DD/MM/YY')
              }
              const day = date.getDay()
              if (day === 0) {
                return `${dayList[6]} ${fullDate}`
              }
              return `${dayList[date.getDay() - 1]} ${fullDate}`
            }}
            events={mutateResultToEvent(roomDetailCalendar)}
            eventRender={renderEvent}
            eventLimitText=""
            fixedWeekCount={false}
            eventClick={(info) => dispatch(handleClickEvent(info))}
            datesRender={(arg) => dispatch(handleDateRender(arg))}
            allDaySlot={false}
            locale="th-TH"
            scrollTime="08:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false,
              hour12: false,
            }}
          />
          <Loading isCalendarLoading={isCalendarLoading} />
        </Box>
      </StyledColumn>
      <FilterDrawer />
      <Popover
        slotProps={{
          paper: { style: { border: 'none', borderRadius: '16px' } },
        }}
        open={isPopupEventSummary}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{
          top: anchorElTop,
          left: anchorElLeft,
        }}
        onClose={() =>
          dispatch(
            setReduxValue({
              key: 'isPopupEventSummary',
              value: false,
            }),
          )
        }
      >
        <PopUp />
      </Popover>
    </CalendarCardStyle>
  )
}
