import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledDrawer, LoadingPage } from './Styled'
import { useParams } from 'react-router-dom'
import { ErrorPage } from './ErrorPage'
import { Header } from './Header/index'
import { Content } from './Content/index'

const QREnrollDrawer = ({ isOpen, onClose }) => {
  const { id: uuid } = useParams()
  const { isLoading, iserror } = useSelector(
    (state) => state.manageClassDetail.qrEnrollProps,
    shallowEqual
  )

  const showContent = () => {
    return !isLoading && !iserror
  }

  const showError = () => {
    return !isLoading && iserror
  }

  return (
    <StyledDrawer open={isOpen} onClose={onClose}>
      <Header onClose={onClose} />
      {showContent() && <Content uuid={uuid} />}
      <LoadingPage isLoading={isLoading} />
      {showError() && <ErrorPage uuid={uuid} />}
    </StyledDrawer>
  )
}

export default QREnrollDrawer
