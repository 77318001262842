import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(8),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(3),
  paddingBottom: '170px',
  gap: theme.spacing(5),
}))

export const StyledContent = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
}))
