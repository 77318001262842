import React from 'react'

import Information from './Information'
import HeaderQuestion from './HeaderQuestion'
import ConditionQuestion from './ConditionQuestion'

const Index = () => {
  return (
    <>
      <Information />
      <HeaderQuestion />
      <ConditionQuestion />
    </>
  )
}

export default Index
