import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { LoadingButton } from '@mui/lab'
import { StyledColumn, StyledRow } from '../../Styled'

export const StyleScoreContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledLabel = styled(StyledColumn)(({ theme }) => ({
  alignItems: 'end',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

export const StyledScoreForm = styled(StyledRow)(({ theme }) => ({
  gap: theme.spacing(2),
}))

export const StyledSubmitButton = styled((props) => (
  <LoadingButton size="l" color="secondary" variant="contained" {...props} />
))(({ theme }) => ({
  color: theme?.palette?.text?.white,
}))

export const StyledCancelButton = styled((props) => (
  <Button size="l" color="secondary" {...props} />
))(() => ({}))

export const StyledDialog = styled(StyledColumn)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(4)}`,
  gap: theme.spacing(1.5),
}))
