import * as yup from 'yup'

export const validateSchema = yup.object().shape({
  information: yup.object().shape({
    stationUuid: yup.string().required('กรุณาเลือก'),
    roomName: yup
      .string()
      .required('กรุณากรอกชื่อห้อง')
      .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
    floor: yup
      .number()
      .required('กรุณากรอกชั้น')
      .max(999, 'กรอกได้ไม่เกิน 3 หลัก')
      .nullable(),
    sqm: yup
      .number()
      .required('กรุณากรอกขนาดห้อง')
      .max(10000, 'กรอกได้ไม่เกิน 10,000 ตารางเมตร')
      .nullable(),
    width: yup.number().max(999, 'กรอกได้ไม่เกิน 3 หลัก').nullable(),
    length: yup.number().max(999, 'กรอกได้ไม่เกิน 3 หลัก').nullable(),
    height: yup.number().max(999, 'กรอกได้ไม่เกิน 3 หลัก').nullable(),
    capacityClassroom: yup
      .number()
      .required('กรุณากรอกความจุ')
      .max(9999, 'กรอกได้ไม่เกิน 4 หลัก')
      .nullable(),
    capacityGroup: yup.number().max(9999, 'กรอกได้ไม่เกิน 4 หลัก').nullable(),
    capacityUShape: yup.number().max(9999, 'กรอกได้ไม่เกิน 4 หลัก').nullable(),
    capacityTheater: yup.number().max(9999, 'กรอกได้ไม่เกิน 4 หลัก').nullable(),
    roomFunction: yup.string().required('กรุณาเลือกฟังก์ชันห้อง'),
    roomFunctionOther: yup
      .string()
      .test(
        'check-room-function-other',
        'กรุณากรอกฟังก์ชันห้อง',
        (value, context) => {
          if (context.parent.roomFunction !== 'OTHER') return true
          return value
        }
      )
      .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
    meetingRoomEquipment: yup.array().of(
      yup.object().shape({
        equipment: yup.string().required('กรุณาเลือกชื่ออุปกรณ์'),
        quantity: yup
          .number()
          .required('กรุณากรอกจำนวน')
          .max(999, 'กรอกได้ไม่เกิน 3 หลัก'),
      })
    ),
    roomTypeOther: yup
      .string()
      .test(
        'check-room-type-other',
        'กรุณากรอกประเภทห้อง',
        (value, context) => {
          if (context.parent.roomType !== 'OTHER') return true
          return value
        }
      )
      .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
    remark: yup
      .string()
      .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3,000 ตัวอักษร')
      .nullable(),
  }),
})
