import React, { useRef, createRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { CardCalendar, LoadingPage, BoxContent } from './Styled'
import Header from './Header'
import Calendar from './Content'
import ClassDetailDrawer from '../Detail/Dashboard/ClassDetailDrawer'
import { handleEventDrawer } from './events'

const Index = () => {
  const ref = useRef(null)
  const calendarRef = createRef()
  const [drawer, setDrawer] = useState({
    isOpen: false,
    uuid: '',
    isHoliday: false,
  })
  const isLoading = useSelector((state) => state.manageClass.isLoading)

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <BoxContent isLoading={isLoading}>
        <CardCalendar height={ref?.current?.clientHeight}>
          <Header />
          <Calendar
            calendarRef={calendarRef}
            handleEventDrawer={(info) => handleEventDrawer(info, setDrawer)}
          />
        </CardCalendar>
      </BoxContent>

      <ClassDetailDrawer
        isOpen={drawer.isOpen}
        uuid={drawer.uuid}
        isHoliday={drawer.isHoliday}
        onCloseDrawer={() => setDrawer({ isOpen: false, uuid: '' })}
      />
    </>
  )
}
export default Index
