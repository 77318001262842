import {
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import {
  postRoomManagement,
  putRoomManagement,
} from '../../../../services/roomManagement'
import _ from 'lodash'
import { store } from '../../../../App'

import { EditBooking } from './components/EditBooking'
import { handleFail, handleSuccess } from '../event'
import dayjs from 'dayjs'
import { closeDrawer } from '../../../../redux/slices/roomManagement/Drawer'
import { STATUS_TYPE } from '../../../../constants/roomManagement'

export const handleConfirmBooking = () => (dispatch) => {
  const state = store.getState()
  const { status } = state.roomManagementDrawer.bookingInformation
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      agreeText: 'บันทึก',
      handleConfirm: () => {
        if (status === STATUS_TYPE.IN_PROGRESS) {
          dispatch(submitEditBooking())
        } else {
          dispatch(submitBooking())
        }
      },
    }),
  )
}

const getBody = () => {
  const state = store.getState()
  const { bookingInformation } = state.roomManagementDrawer
  const { stationUuid } = state.roomManagement

  const rooms = []

  for (const item of bookingInformation.roomBookingList) {
    for (const room of item.rooms) {
      const startTimeStamp = dayjs(
        `${item.bookingDate} ${item.startTimeRoom}`,
        'YYYY-MM-DD HH:mm',
      ).unix()
      const endTimeStamp = dayjs(
        `${item.bookingDate} ${item.endTimeRoom}`,
        'YYYY-MM-DD HH:mm',
      ).unix()
      const durationInMinute = (endTimeStamp - startTimeStamp) / 60
      const roomLayout = _.get(room, 'roomLayout', null)
      const meetingRoomUuid = _.get(room, 'meetingRoomUuid', null)
      const isOnline = _.get(room, 'isOnline', false)
      const meetingRoomOther = _.get(room, 'meetingRoomOther', null)
      const numberOfAttendees = _.get(room, 'numberOfAttendees', null)
      const numberOfGroup = _.get(room, 'numberOfGroup', null)
      const attendeeInGroup = _.get(room, 'attendeeInGroup', null)
      const additionalEquipment = _.get(room, 'additionalEquipment', null)

      rooms.push({
        uuid: room.uuid,
        bookingDate: item.bookingDate,
        period: item?.period,
        startTimeTraining: item?.startTimeTraining,
        endTimeTraining: item?.endTimeTraining,
        startTimeRoom: item?.startTimeRoom,
        endTimeRoom: item?.endTimeRoom,
        meetingRoom:
          meetingRoomUuid === 'OTHER' || isOnline ? null : meetingRoomUuid,
        station: stationUuid,
        meetingRoomOther: meetingRoomUuid === 'OTHER' ? meetingRoomOther : null,
        numberOfAttendees: _.toNumber(numberOfAttendees),
        roomLayout: roomLayout?.length > 0 ? roomLayout : null,
        additionalEquipment: additionalEquipment,
        durationInMinute,
        numberOfGroup: _.toNumber(numberOfGroup),
        attendeeInGroup: _.toNumber(attendeeInGroup),
        isOnline: isOnline,
      })
    }
  }

  return {
    uuid: bookingInformation.bookingUuid,
    roomManagementMeetingRoom: rooms,
    emailOther: bookingInformation.emailOther,
    remark: bookingInformation.remark,
    mobileNoOther: bookingInformation.mobileNoOther,
    bookForOther: bookingInformation.bookForOther,
    bookFor:
      bookingInformation.academy === 'NON_ACADEMY'
        ? null
        : bookingInformation.bookForUuid,
    academy: bookingInformation.academy,
    bookingPeriod: bookingInformation.bookingPeriod,
    startDate: bookingInformation.startDate,
    endDate: bookingInformation.endDate,
    bookingTopic: bookingInformation.bookingTopic,
    reason: bookingInformation.reason,
  }
}

export const submitBooking = () => async (dispatch) => {
  dispatch(loadingDialog())
  const response = await dispatch(postRoomManagement({ body: getBody() }))

  dispatch(stopLoadingDialog())

  const requestStatus = _.get(response, 'meta.requestStatus', 'rejected')

  if (requestStatus === 'fulfilled') {
    dispatch(handleSuccess('ข้อมูลถูกบันทึกเรียบร้อยแล้ว'))
    dispatch(closeDrawer())
  } else {
    const title = _.get(
      response,
      'meta.response.data.constraints.title',
      'ผิดพลาด',
    )
    const msg = _.get(
      response,
      'meta.response.data.constraints.value',
      'ไม่สามารถบันทึกข้อมูลได้',
    )
    dispatch(handleFail({ title: title, msg: msg }))
  }
}

export const handleEditBookingDialog = (bookingUuid) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'เปลี่ยนแปลง',
      content: <EditBooking bookingUuid={bookingUuid} />,
      showButton: false,
    }),
  )
}

export const submitEditBooking = () => async (dispatch) => {
  dispatch(loadingDialog())
  const response = await dispatch(putRoomManagement({ body: getBody() }))
  const requestStatus = _.get(response, 'meta.requestStatus', 'rejected')
  if (requestStatus === 'fulfilled') {
    dispatch(handleSuccess('บันทึกการเปลี่ยนแปลงเรียบร้อยแล้ว'))
    dispatch(stopLoadingDialog())
    dispatch(closeDrawer())
  }
}
