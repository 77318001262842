import _ from 'lodash'

export const handleNumberInput = (e) => {
  const target = e.target
  const numVal = Math.abs(target.value)
  const isDot = e.nativeEvent.data == '.'

  if (_.startsWith(target.value, '0')) target.value = 1
  if (target.value == '' && isDot) target.value = ''

  if (!isDot) {
    target.value = numVal >= 0 ? target.value : 1 // min 1
    target.value = numVal <= 100 ? target.value : 100 // max 100
  }

  if (target.name == 'qrCodeTime') {
    const splitVal = _.split(target.value, '.')
    if (splitVal[1] && splitVal[1].length > 2) {
      target.value = _.floor(numVal, 2)
    }
  } else if (isDot) target.value = ''

  return target.value
}

export const handleTextInput = (e, setInitialValues) => {
  const target = e.target
  const valueNum = target.value == '' ? '' : Number(target.value)
  setInitialValues((prev) => ({
    ...prev,
    [target.name]: valueNum,
  }))
}
