import _ from 'lodash'
import { alphabets } from '../../../../../../constants/eTesting'

export const handleCalculateTotal = (testing) => {
  let score = 0
  testing.forEach((item) => {
    score = score + parseFloat(item.score)
  })
  return Number.isInteger(score) ? score : score.toFixed(2)
}

export const handleCalculateScore = (testing) => {
  let score = 0
  testing.forEach((item) => {
    switch (item.type) {
      case 'MULTIPLE':
        item.choices.forEach((choice, index) => {
          if (choice.isCorrect && item.answer[index].isChecked)
            score = score + parseFloat(choice.score)
        })
        break
      case 'TRUE_FALSE': {
        item.answer.forEach((ans) => {
          if (ans.isCorrect) score = score + parseFloat(ans.score)
        })
        break
      }
      case 'SEQUENCE':
      case 'MATCHING':
      case 'FILL_IN_BLANK_CHOICE': {
        const scorePerChoice =
          item.score / item.choices.filter((choice) => choice.isCorrect).length
        item.answer.forEach((ans, index) => {
          const type = item.type
          const sequenceCond = type === 'SEQUENCE' && ans.sequence === index + 1
          const matchingCond =
            type === 'MATCHING' && ans.questionUuid === ans.answerUuid
          const fillCond = type === 'FILL_IN_BLANK_CHOICE' && ans.isCorrect
          if (sequenceCond || matchingCond || fillCond) {
            score = score + parseFloat(scorePerChoice)
          }
        })
        break
      }
      case 'FILL_IN_BLANK_DRAG':
        item.answer.forEach((ans, index) => {
          if (ans?.target === alphabets[index]) {
            const correctNum = item.choices.filter(
              (choice) => choice.isCorrect
            ).length
            const scorePerChoice = item.score / correctNum
            score = score + parseFloat(scorePerChoice)
          }
        })
        break
      default: {
        const correctAnswer = item.choices.find(
          (choice) => choice.isCorrect && item.answer[0] == choice.uuid
        )
        if (!_.isNil(correctAnswer)) score = score + parseFloat(item.score)
        break
      }
    }
  })
  return Number.isInteger(score) ? score : score.toFixed(2)
}

export const handleCalculatePercent = (correctScore, totalScore) => {
  const cal = correctScore / totalScore || 0
  const percent = cal * 100
  const twoDigitsPercent = parseFloat(percent).toFixed(2)
  return parseFloat(twoDigitsPercent)
}
