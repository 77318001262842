import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledDropBox = styled(Typography)((props) => {
  const { theme, value, isSubmit, isCorrect, isMobileSelect, isDragOut } = props
  const submitBorderColor = isCorrect
    ? theme.palette?.success?.main
    : theme.palette?.error?.main
  const submitBgColor = isCorrect
    ? theme.palette?.success?.background
    : theme.palette?.error?.background
  return {
    display: isDragOut ? 'inline-block' : 'inline',
    height: isDragOut ? 41 : 'auto',
    width: isDragOut ? 200 : 'auto',
    border: '1px',
    borderStyle: isSubmit ? 'solid' : 'dashed',
    borderRadius: '2px',
    borderColor: isSubmit
      ? submitBorderColor
      : isMobileSelect
      ? theme.palette?.secondary?.main
      : theme.palette?.text?.silver,
    background: isSubmit ? submitBgColor : theme.palette?.background?.default,
    padding: isDragOut ? '0px 12px' : '8px 12px',
    margin: '0px 8px',
    wordBreak: !value && 'break-all',
  }
})
