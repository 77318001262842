import { store } from '../../../App'
import _ from 'lodash'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleDisabledColumnOptions } from '../../../redux/slices/reportComp/event'

export const handleDeleteColumn = (id) => async (dispatch) => {
  const { displayColumns } = store.getState().reportComp

  const newColumns = _.filter(displayColumns, (column) => column.id !== id)
  await dispatch(setReduxValue({ key: 'displayColumns', value: newColumns }))
  await dispatch(handleDisabledColumnOptions())
}
