import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import TextInput from '../../../../components/Input/TextInput'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import { handleChangeWorkTypeData } from '../handler/handleChangeWorkTypeData'
import { handleNumberInput } from '../../../../components/Report/handler/handleNumberInput'

const InputForCompareChart = () => {
  const theme = useTheme()

  const {
    workTypeData: { title, workTypeList },
    errors,
  } = useSelector(
    (state) => ({
      workTypeData: state.monthlyPlan.dashboard.workTypeData,
      errors: state.monthlyPlan.dashboard.errors,
    }),
    shallowEqual,
  )

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        border: `1px solid ${theme.palette.blue.blueLine}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
      <TextInput
        boxSx={{ mb: 0, maxWidth: 'calc(50% - 12px)' }}
        type="basic"
        name="title"
        labelText={'Title ชื่อของข้อมูล'}
        placeholder={'กรอก Title ชื่อของข้อมูล'}
        isShowTextError={
          !_.isNil(_.get(errors, 'dashboard.workTypeData.title'))
        }
        textError={_.get(errors, 'dashboard.workTypeData.title')}
        value={title}
        onChange={(e) => {
          const value = _.get(e, 'target.value', null)
          handleChangeWorkTypeData({
            key: 'dashboard.workTypeData.title',
            value: !_.isEmpty(value) ? value : null,
          })
        }}
      />
      <Typography variant="body1b">
        Input master data สำหรับเปรียบเทียบกับสรุปรายปี
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {_.map(workTypeList, (li, idx) => {
          return (
            <TextInput
              key={idx}
              boxSx={{ mb: 0, flexBasis: 'calc(50% - 12px)' }}
              type="basic"
              name="title"
              labelText={li.label}
              placeholder={`กรอก ${li.label}`}
              value={li.value}
              isShowTextError={
                !_.isNil(
                  _.get(
                    errors,
                    `dashboard.workTypeData.workTypeList[${idx}].value`,
                  ),
                )
              }
              textError={_.get(
                errors,
                `dashboard.workTypeData.workTypeList[${idx}].value`,
              )}
              inputProps={{
                type: 'text',
                onInput: (e) => {
                  handleNumberInput(e)
                },
              }}
              onChange={(e) => {
                const value = _.get(e, 'target.value', null)
                handleChangeWorkTypeData({
                  key: `dashboard.workTypeData.workTypeList[${idx}].value`,
                  value: !_.isEmpty(value) ? value : null,
                })
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default InputForCompareChart
