export const defaultFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  startTestDateChecked: false,
  startTestDate: '',
  endTestDate: '',
  userProfileUuidChecked: false,
  userProfileUuid: '',
  courseCodeChecked: false,
  courseCode: '',
  classUuidChecked: false,
  classUuid: '',
  distributionChecked: false,
  distribution: '',
  ramCodeChecked: false,
  ramCode: '',
  trainerChecked: false,
  trainer: '',
}
