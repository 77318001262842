import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
export const StyledContainer = styled(Grid)(() => ({
  display: 'flex',
}))

export const StyledPreviewBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(3),
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledInputBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    borderTop: `1px solid ${theme.palette?.other?.divider}`,
  },
  [theme.breakpoints.up('md')]: {
    borderLeft: `1px solid ${theme.palette?.other?.divider}`,
  },
}))

export const StyledDisplayBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}))

export const StyledFullBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderTop: `1px solid ${theme.palette?.other?.divider}`,
}))

export const StyledPreviewImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: theme?.palette?.background?.default,
  border: `1px solid ${theme.palette?.other?.outlineBorder}`,
  color: theme?.palette?.silver,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '508px',
  height: '100%',
}))

export const LayoutIconPreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  color: theme?.palette?.text?.silver,
}))

export const StyledGroupLayoutLable = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
}))

export const ButtonPopperStyle = styled('div')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: `${theme?.palette?.primary?.main}`,
  cursor: 'pointer',
}))

export const ButtonColorStyle = styled('button')(({ color, theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme?.palette?.other?.outlineBorder}`,
  minWidth: '60px',
  height: '40px',
  backgroundColor: `${color}`,
  cursor: 'pointer',
}))

export const ButtonBoldStyle = styled('button')(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme?.palette?.other?.outlineBorder}`,
  minWidth: '40px',
  height: '40px',
  cursor: 'pointer',
}))

export const BoxDisplay = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '12px',
}))

export const TypographyDisplay = styled(Typography)(() => ({
  width: '45%',
  minWidth: '45%',
}))

export const EditByDisplay = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: theme.spacing(3),
  paddingTop: theme.spacing(1),
  borderTop: `1px solid ${theme.palette?.other?.divider}`,
}))
