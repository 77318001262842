export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รอเปิดใช้งาน',
    name: 'pendingChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayType = [
  { label: 'Online test', name: 'onlineTestChecked' },
  { label: 'Assessment', name: 'assessmentChecked' },
  { label: 'Standalone', name: 'standaloneChecked' },
]

export const arrayCheckbox = {
  etesting: [
    {
      label: 'ID',
      nameCheckBox: 'codeIdChecked',
      nameInput: 'codeId',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อแบบทดสอบ',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหา',
    },
    {
      label: 'สร้างโดย',
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: 'ค้นหา',
    },
    {
      label: 'แก้ไขล่าสุดโดย',
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: 'ค้นหา',
    },
  ],
  versions: [
    {
      label: 'เวอร์ชั่น',
      nameCheckBox: 'versionChecked',
      nameInput: 'version',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อแบบทดสอบ',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหา',
    },
    {
      label: 'วันเริ่มต้นใช้งาน',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'ค้นหา',
      isDate: true,
    },
    {
      label: 'สร้างโดย',
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: 'ค้นหา',
    },
    {
      label: 'แก้ไขล่าสุดโดย',
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: 'ค้นหา',
    },
  ],
}

export const defaultFilter = {
  etesting: {
    codeIdChecked: false,
    codeId: '',
    nameChecked: false,
    name: '',
    numberOfVersionsChecked: false,
    numberOfVersions: '',
    createdByChecked: false,
    createdBy: '',
    updatedByChecked: false,
    updatedBy: '',
    typeChecked: false,
    type: {
      onlineTestChecked: false,
      assessmentChecked: false,
    },
    statusChecked: false,
    status: {
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
  },
  versions: {
    versionChecked: false,
    version: '',
    nameChecked: false,
    name: '',
    startDateChecked: false,
    startDate: new Date(),
    toDate: new Date(),
    displayDate: false,
    createdByChecked: false,
    createdBy: '',
    updatedByChecked: false,
    updatedBy: '',
    statusChecked: false,
    status: {
      draftChecked: false,
      pendingChecked: false,
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
  },
}

export const examinerTypes = [
  {
    label: 'Agent',
    name: 'A',
  },
  {
    label: 'LB',
    name: 'L',
  },
  {
    label: 'Prospect',
    name: 'PROSPECT',
  },
  {
    label: 'Staff',
    name: 'STAFF',
  },
  // {
  //   label: 'Non Academy',
  //   name: 'NON_ACADEMY',
  // },
]

export const onView = (row, history) => {
  history.push('/e-examination/examiner/detail/' + row.uuid)
}

export const examinerPrefix = [
  {
    label: 'นาย',
    value: 'นาย',
  },
  {
    label: 'นาง',
    value: 'นาง',
  },
  {
    label: 'นางสาว',
    value: 'นางสาว',
  },
]
