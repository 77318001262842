import { overviewEnum, overviewStatusEnum } from '../enums/overviewEnum'

export const overviewsDemo = [
  {
    label: 'จำนวนคลาสทั้งหมด',
    number: '240',
    background: '',
    dotColor: '',
    value: overviewEnum.ALL,
    unit: 'คลาส',
    percent: '',
  },
  {
    label: 'คลาส Classroom',
    number: '200',
    background: '#DBE4F199',
    dotColor: '',
    value: overviewEnum.CLASSROOM,
    unit: 'คลาส',
    percent: '',
  },
  {
    label: 'คลาส Online',
    number: '240',
    background: '#DBE4F199',
    dotColor: '',
    value: overviewEnum.ONLINE,
    unit: 'คลาส',
    percent: '',
  },
]

export const overviewStatusDemo = [
  {
    label: 'กำลังเปิดรับสมัคร',
    subLable: 'Open regis',
    number: '20',
    background: '#EBF6F3',
    dotColor: '#32A287',
    fontColor: '#FFFFFF',
    value: overviewStatusEnum.OPENREGISTRATION,
    unit: 'คลาส',
    percent: '20%',
  },
  {
    label: 'รอการอบรม',
    subLable: 'Closed regis',
    number: '30',
    background: '#FFF4C0',
    dotColor: '#FFE66D',
    fontColor: '',
    value: overviewStatusEnum.CLOSEREGISTRATION,
    unit: 'คลาส',
    percent: '30%',
  },
  {
    label: 'กำลังอบรม',
    subLable: 'Start class',
    number: '40',
    background: '#BED0ED',
    dotColor: '#4976BA',
    fontColor: '#FFFFFF',
    value: overviewStatusEnum.STARTCLASS,
    unit: 'คลาส',
    percent: '40%',
  },
  {
    label: 'ยกเลิก',
    subLable: 'Cancel',
    number: '50',
    background: '#FFDDDD',
    dotColor: '#D64F65',
    fontColor: '#FFFFFF',
    value: overviewStatusEnum.CANCELED,
    unit: 'คลาส',
    percent: '50%',
  },
  {
    label: 'อื่นๆ',
    subLable: 'Unassigned, To do, In progress',
    number: '60',
    background: '#F5F5F5',
    dotColor: '#E0DFDF',
    fontColor: '',
    value: overviewStatusEnum.OTHER,
    unit: 'คลาส',
    percent: '60%',
  },
  {
    label: 'End Class',
    subLable: 'End Class',
    number: '70',
    background: '#FFDACA',
    dotColor: '#E99E7D',
    fontColor: '#FFFFFF',
    value: overviewStatusEnum.ENDCLASS,
    unit: 'คลาส',
    percent: '70%',
  },
  {
    label: 'Closed Class',
    subLable: 'Closed Class',
    number: '80',
    background: '#E9DEFF',
    dotColor: '#B49CE4',
    fontColor: '',
    value: overviewStatusEnum.CLOSED,
    unit: 'คลาส',
    percent: '80%',
  },
]
