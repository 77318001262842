import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import CustomStatusSwitch from '../../../../../components/CRUD/components/CustomStatusSwitch'
import { Box, Typography } from '@mui/material'
import { StyledFormCard } from '../Styled'
import { UploadCropper } from '../../../../../components/UploadCropper'
import { handleFileChange } from './handler/handleFileChange'
import { AddCollectionDragDrop } from './AddCollectionDragDrop'
import { crudConfig } from '../../crudConfig'
import { useInputField } from '../../../../../components/CRUD/hooks/useInputField'

export const CollectionTab = () => {
  const { value, isError, message } = useInputField('coverImage')

  return (
    <>
      <StyledFormCard sx={{ gap: 3 }}>
        <Typography variant="h6">รายละเอียดพื้นฐาน</Typography>
        <CustomInput required fieldName="name" labelName="ชื่อ Collection" />
        <CustomInput
          required
          fieldName="nameForBanner"
          labelName="ชื่อหัวข้อบน Banner"
          sx={{ width: 'calc(50% - 12px)' }}
        />
        <CustomInput
          fieldName="detailForBanner"
          labelName="รายละเอียดบน Banner"
        />
        <CustomTextArea
          fieldName="description"
          labelName="รายละเอียดเพิ่มเติม"
        />
        <UploadCropper
          folder={'e-learning'}
          accept={{ 'image/png': ['.png', '.jpg', '.jpeg'] }}
          value={value}
          onChange={handleFileChange}
          id={`${crudConfig.moduleSlug}-coverImage`}
        />
        {isError && (
          <Box>
            {message && message !== '' && (
              <Typography variant="body2" color="error">
                {message}
              </Typography>
            )}
          </Box>
        )}
        <CustomTextArea
          fieldName="linkExampleVideo"
          labelName="Link สำหรับวิดิโอตัวอย่าง"
        />
        <CustomTextArea fieldName="remark" labelName="remark" />
        <Typography variant="h6">สถานะเปิด/ปิดใช้งาน</Typography>
        <CustomStatusSwitch
          fieldName="status"
          labelText=""
          labelName="เปิดปิดการใช้งาน Collection"
        />
      </StyledFormCard>
      <AddCollectionDragDrop />
    </>
  )
}
