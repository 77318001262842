import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import BarChart from '../../../../components/Chart/BarChart'
import { categoryEnum } from '../enum/enum'
import { StyledButton } from '../Styled'
import WhiteCard from './WhiteCard'
import CatCard from './CatCard'

const CatSummary = ({ type }) => {
  const [open, setOpen] = useState(true)
  const { content } = useSelector(
    (state) => ({
      content: state.crud?.eContentDashboard?.overview?.[type],
    }),
    shallowEqual,
  )
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <Typography variant="body1b">{categoryEnum[type].label}</Typography>
        <StyledButton
          color="text.lightGray"
          size="small"
          marginLeft=""
          background="#4976BA14"
          onClick={() => window.open(categoryEnum[type].path, '_blank')}
        >
          <ChevronRight fontSize="small" />
        </StyledButton>
        <StyledButton
          color="text.lightGray"
          size="small"
          marginLeft=""
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )}
        </StyledButton>
      </Box>
      <Collapse timeout="auto" in={open}>
        <Grid container>
          <Grid item xs={2.25}>
            <WhiteCard
              value={content?.total}
              label={`จำนวน${categoryEnum[type].label}`}
              unit="หน่วย"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={6}>
            <BarChart
              dataSet={{
                data: content?.dataList,
                backgroundColor: new Array(10).fill(
                  categoryEnum[type].chartColor,
                ),
                hoverBackgroundColor: new Array(10).fill(
                  categoryEnum[type].chartColor,
                ),
              }}
              labels={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              height={'360px'}
              showXAxisLabel={true}
              showYAxisLabel={true}
              xAxisLabel="ลำดับ"
              yAxisLabel="จำนวน Content"
              eleId={categoryEnum[type].chartId}
            />
          </Grid>
          <Grid item xs={6}>
            <CatCard type={type} />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default CatSummary
