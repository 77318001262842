import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { manageClassDashboardFilter } from '../../../../utils/apiPath'
import { store } from '../../../../App'
import dayjs from 'dayjs'
import { validateDate } from 'src/utils/lib'

export const fetchManageClassDashboard = async () => {
  const {
    dashboard: { classStatusDetails, filters },
  } = store.getState().monthlyPlan

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
  } = filters

  const body = {
    station: stationList.length > 0 ? stationList : defaultStation,
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList.length > 0 ? departmentList : defaultDepartment,
  }
  return await callAxios
    .post(manageClassDashboardFilter, body)
    .then(({ data }) => {
      const { ALL_CLASS = 0, CLASSROOM_CLASS = 0, ONLINE_CLASS = 0 } = data
      const updatedStatusDetails = _.map(classStatusDetails, (dt) => {
        const percent =
          ALL_CLASS > 0
            ? ((data[`${dt.key}`] / ALL_CLASS) * 100).toFixed(2)
            : '0.00'
        return {
          ...dt,
          count: data[`${dt.key}`],
          percent: percent,
          unit: `คลาส (${percent}%)`,
        }
      })
      return {
        allClass: ALL_CLASS,
        classroomClass: CLASSROOM_CLASS,
        onlineClass: ONLINE_CLASS,
        classStatusDetails: updatedStatusDetails,
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
