import React, { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import LeftMenu from './components/LeftMenu/LeftMenu'
import { leftMenuList } from './components/LeftMenu/LeftMenuData'
import { theme } from './utils/theme'
import { DialogContext } from './context/dialog-context'
import AlertDialog from './components/AlertDialog'
import PopUpDialog from './components/redux/PopUpDialog'
import PopUpDialog2 from './components/redux/PopUpDialog2'
import _ from 'lodash'
import axios from 'axios'
import { useLocalStorage } from '@rehooks/local-storage'
import localforage from 'localforage'
import { openErrorDialog } from './redux/slices/dialog'
import { handleUrlError, IgnoreAxiosDialog, parseJwt } from './utils/lib'
import { resetTable } from './redux/slices/table'
import { getInstance } from './utils/baseService'
import Keycloak from './keycloak'
import { instanceKeycloak, instanceOneAccount } from './App'
import { useAuth } from 'react-oidc-context'
import { handleLogout } from './components/Appbar/Appbar'
import OneAccount from './OneAccount'

const qs = require('qs')

export default function Layout(props) {
  const auth = useAuth()
  const dispatch = useDispatch()
  const lastLocation = useLocation()
  const user = localStorage.getItem('user')
  const [leftMenuLS, setLeftMenuLS] = useLocalStorage('leftMenu2')
  const [menu, setMenu] = useState([])

  const isELearningUploading = useSelector(
    (state) => state.eLearningModuleForm.isUploadLoading,
    shallowEqual,
  )

  const [leftMenuDrawer, setLeftMenuDrawer] = useState(false)
  if (props.layout === 'login' && !user) {
    return <>{props.children}</>
  }

  if (user && props.layout === 'login') {
    window.location.href = '/to-do-list'
  }

  if (user) {
    const redirectTo = `${lastLocation.search}`
    const inOf = redirectTo.indexOf('?state')
    if (inOf === 0) {
      window.history.replaceState(null, '', lastLocation.pathname)
    }
  }
  const { context } = useContext(DialogContext)

  const leftMenu = leftMenuList()

  const [sessionExpire, setSessionExpire] = useState({
    open: false,
    title: 'session หมดอายุ',
    content: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
    variant: 'fail',
    onConfirmClick: () => handleLogout(auth, true),
  })

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token')
      const keycloak = window.__env__.ENV === 'DEV' ? Keycloak : OneAccount
      const body = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: 'openid email profile offline_access',
        client_id: keycloak.client_id,
      }

      if (keycloak.client_secret) {
        body['client_secret'] = keycloak.client_secret
      }

      if (window.__env__.ENV === 'DEV') {
        const getToken = await instanceKeycloak({
          method: 'post',
          url: `/protocol/openid-connect/token`,
          data: qs.stringify(body),
        })
        const { refresh_token, access_token, token_type } = getToken.data
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
        return `${token_type} ${access_token}`
      } else {
        body['scope'] = 'openid email'
        const getToken = await instanceOneAccount({
          method: 'post',
          url: `/as/token.oauth2`,
          data: qs.stringify(body),
        })
        const { refresh_token, access_token, token_type } = getToken.data
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
        return `${token_type} ${access_token}`
      }
    } catch (error) {
      console.log('session expire')
    }
  }

  axios.interceptors.request.use(
    async (req) => {
      const token = localStorage.getItem('token')
      if (!_.isEmpty(token)) {
        req.headers = {
          ...req.headers,
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          Authorization: `${token}`,
        }
      }
      return req
    },
    (error) => Promise.reject(error),
  )

  axios.interceptors.response.use(
    async (response) => {
      const getToken = localStorage.getItem('token')

      const token = getToken?.replace('Bearer ', '')
      const decodedJwt = parseJwt(token)

      if (decodedJwt && token) {
        const expire = 60000 * 10
        const expirationTime = decodedJwt?.exp * 1000 - expire
        const dateNow = new Date()
        if (dateNow >= expirationTime) {
          await refreshToken()
        }
      }
      return response
    },
    async (error) => {
      const status = _.get(error, 'response.status', 500)
      const config = error?.config
      if (status === 401 && !config?.sent) {
        config.sent = true
        await refreshToken()
        config.headers = { ...config.headers }
        return axios(config)
      }
      if (status === 401) {
        setSessionExpire({
          open: true,
          title: sessionExpire.title,
          content: sessionExpire.content,
          variant: sessionExpire.variant,
          onConfirmClick: sessionExpire.onConfirmClick,
        })
        return Promise.reject(error)
      }
      return dispatch(handleError(error))
    },
  )

  const instance = getInstance(window.location.pathname)
  instance.interceptors.request.use(
    async (req) => {
      const token = localStorage.getItem('token')
      if (!_.isEmpty(token)) {
        req.headers = {
          ...req.headers,
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          Authorization: `${token}`,
        }
      }
      return req
    },
    (error) => Promise.reject(error),
  )

  instance.interceptors.response.use(
    async (response) => {
      const getToken = localStorage.getItem('token')

      const token = getToken?.replace('Bearer ', '')
      const decodedJwt = parseJwt(token)

      if (decodedJwt && token) {
        const expire = 60000 * 10
        const expirationTime = decodedJwt?.exp * 1000 - expire
        const dateNow = new Date()
        if (dateNow >= expirationTime) {
          await refreshToken()
        }
      }
      return response
    },
    async (error) => {
      const status = _.get(error, 'response.status', 500)
      const config = error?.config
      if (status === 401 && !config?.sent) {
        config.sent = true
        await refreshToken()
        config.headers = { ...config.headers }
        return instance(config)
      }

      if (status === 401) {
        setSessionExpire({
          open: true,
          title: sessionExpire.title,
          content: sessionExpire.content,
          variant: sessionExpire.variant,
          onConfirmClick: sessionExpire.onConfirmClick,
        })
        return Promise.reject(error)
      }

      return dispatch(handleError(error))
    },
  )

  useEffect(() => {
    setLeftMenuLS(
      leftMenu.map((item) => {
        return item.active
      }),
    )
    if (_.isNil(leftMenuLS)) {
      setMenu(leftMenu)
    } else {
      setMenu(
        leftMenu.map((item, i) => {
          return { ...item, active: leftMenuLS[i] }
        }),
      )
    }
  }, [])

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0)
      dispatch(resetTable())
    }
  }, [])

  useEffect(() => {
    localforage
      .clear()
      .then(() => {
        console.log('localforage is now empty.')
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <Box sx={{ pointerEvents: isELearningUploading ? 'none' : 'auto' }}>
      <AlertDialog
        open={sessionExpire.open}
        title={sessionExpire.title}
        content={sessionExpire.content}
        variant={sessionExpire.variant}
        onConfirmClick={sessionExpire.onConfirmClick}
      />
      {user ? (
        <>
          {props.layout !== 'login' && (
            <LeftMenu
              menu={menu}
              setMenu={setMenu}
              open={leftMenuDrawer}
              setOpen={setLeftMenuDrawer}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 10,
              ml: 0,
              [theme.breakpoints.up('md')]: {
                ml: 5,
              },
            }}
          >
            <Box sx={{ maxWidth: 1440, width: '100%' }}>{props.children}</Box>
          </Box>
          <AlertDialog
            open={context.dialog.open}
            title={context.dialog.title}
            content={context.dialog.content}
            variant={context.dialog.variant}
            onConfirmClick={context.dialog.onConfirmClick}
            onCancelClick={context.dialog.onCancelClick}
            isLoading={context.dialog.isLoading}
          ></AlertDialog>
          <PopUpDialog />
          <PopUpDialog2 />
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )}
    </Box>
  )
}

export const handleError = (error) => (dispatch) => {
  const url = _.get(error, 'config.url', '')
  if (handleUrlError(url)) return Promise.reject(error)
  const constraintsKey = _.get(error, 'response.data.constraints.key', '')
  if (
    constraintsKey === 'IsRequestSendRemarkEditDateLate' ||
    constraintsKey === 'IsDateAvailableConstraint' ||
    constraintsKey === 'IsValidateStatus' ||
    constraintsKey === 'InternalServerError' ||
    constraintsKey === 'TrainingPlanNotFound' ||
    constraintsKey === 'IsNotPresentTime' ||
    (constraintsKey === 'IsOverlappingClasses' &&
      window.location.href.includes('monthly-plan/calendar'))
  ) {
    return Promise.reject(error)
  }
  const messageList = _.get(error, 'response.data.message', 'Server error')
  let arrayText = []
  let errorText =
    error.response?.data?.message || error.response?.data?.constraints?.value

  let titleText = error.response?.data?.constraints?.title || undefined

  if (Array.isArray(messageList) && messageList.length > 0) {
    _.forIn(messageList, (item) => {
      arrayText.push(`${item.constraints.value}`)
    })
    errorText = arrayText.join(', ')
  }

  if (IgnoreAxiosDialog(error?.config)) {
    dispatch(
      openErrorDialog({ ...error, message: errorText, title: titleText }),
    )
  }
  return Promise.reject(error)
}
