import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import { Column, Row } from '../../Form/Styled'
import { StyledCardContent, StyledContentRow } from './Styled'
import Dot from '../../../../../components/redux/Dot'
import { versionStatus } from '../../../../../constants/learningPoint'
import { themeColor } from '../../../../../utils/themeData'
import { nl2br } from '../../../../../utils/lib'

const Content = () => {
  const { view } = useSelector(
    (state) => ({
      view: state.settingPoint.view,
    }),
    shallowEqual,
  )
  const isActive =
    view.status == 'DRAFT' ? view.isActive : view.status === 'ACTIVE'
  return (
    <Column>
      <Row sx={{ p: 3 }}>
        <StyledCardContent>
          <Typography variant="h6">ข้อมูลคะแนน</Typography>
          <ContentRow title="ID" content={view?.id || '-'} />
          <ContentRow title="ชื่อคะแนน" content={view?.name || '-'} />
          <ContentRow title="จำนวนคะแนน" content={view?.point || '-'} />
          <StatusContent title="สถานะ" content={view?.status} type="POINT" />
          <ContentRow
            title="สถานะการได้รับคะแนน"
            content={isActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
            color="primary.main"
          />
          <ContentRow
            title="วันเริ่มต้น"
            content={
              view.startDate ? dayjs(view.startDate).format('DD/MM/YYYY') : '-'
            }
          />
          <ContentRow
            title="วันสิ้นสุด"
            content={
              view.isDueDate && view.endDate
                ? dayjs(view.endDate).format('DD/MM/YYYY')
                : '-'
            }
          />
          <ContentRow title="หมายเหตุ" content={nl2br(view?.remark || '-')} />

          <Column sx={{ gap: 1.5 }}>
            <Typography variant="h6">เลือกหลักสูตร</Typography>
            {_.get(view, 'course', []).map((ele, ind) => {
              return (
                <ContentRow
                  key={ind}
                  title={ele?.courseType?.label || '-'}
                  content={ele?.uuid?.label || '-'}
                />
              )
            })}
          </Column>
          <Column sx={{ gap: 1.5 }}>
            <Typography variant="h6">ข้อมูลเหรียญรางวัล</Typography>
            <StatusContent
              title="สถานะเหรียญรางวัล"
              content={view?.badgeStatus}
              type="BADGE"
            />
            <ContentRow title="รูปเหรียญรางวัล">
              {!_.isEmpty(view.badgeImage) ? (
                <img
                  src={`${window.__env__.REACT_APP_API_URL}/file/get${view.badgeImage.key}`}
                  width={56}
                  height={56}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              ) : (
                ''
              )}
            </ContentRow>

            <ContentRow
              title="ชื่อเหรียญรางวัล"
              content={view?.badgeName || '-'}
            />
            <ContentRow
              title="คำอธิบาย"
              content={nl2br(view?.badgeDescription || '-')}
            />
            <ContentRow
              title="หมายเหตุ"
              content={nl2br(view?.badgeRemark || '-')}
            />
          </Column>
        </StyledCardContent>
      </Row>
      <Divider />
      <Column sx={{ px: 3, pb: 3, pt: 1.5 }}>
        <Row sx={{ justifyContent: 'end' }}>
          <Typography variant="caption" color="text.lightGray">
            {`สร้างโดย ${view?.createdBy || '-'} ${
              view.createdAt
                ? dayjs(view.createdAt)
                    .subtract(7, 'hours')
                    .format('DD/MM/YYYY HH:mm')
                : '-'
            }`}
          </Typography>
        </Row>
        <Row sx={{ justifyContent: 'end' }}>
          <Typography variant="caption" color="text.lightGray">
            {`แก้ไขล่าสุดโดย ${view?.updatedBy || '-'} ${
              view.updatedAt
                ? dayjs(view.updatedAt)
                    .subtract(7, 'hours')
                    .format('DD/MM/YYYY HH:mm')
                : '-'
            }`}
          </Typography>
        </Row>
      </Column>
    </Column>
  )
}

export default Content

export const ContentRow = ({ title, content, children, color }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1" color="text.secondary">
        {title}
      </Typography>
      {children ? (
        children
      ) : (
        <Typography variant="body1" color={color || 'text.primary'}>
          {content || '-'}
        </Typography>
      )}
    </StyledContentRow>
  )
}

export const StatusContent = ({ title, content, type }) => {
  const color = {
    BADGE: {
      ACTIVE: themeColor?.success?.main,
      INACTIVE: themeColor?.error?.main,
    },
    POINT: {
      ACTIVE: themeColor?.success?.main,
      INACTIVE: themeColor?.text?.silver,
      DELETED: themeColor?.text?.white,
      DRAFT: themeColor?.text?.silver,
    },
  }
  const badgeStatusText = {
    ACTIVE: 'เปิดใช้งาน',
    INACTIVE: 'ปิดใช้งาน',
  }
  return (
    <ContentRow title={title}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot color={color[type][content]} status={content} sx={{ mt: 1.5 }} />
        {type === 'POINT' ? versionStatus[content] : badgeStatusText[content]}
      </Box>
    </ContentRow>
  )
}
