import { eEvaluationVersion } from '../../../../../utils/apiPath'
import {
  setResult,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/eEvaluationDetail'
import callAxios from '../../../../../utils/baseService'

export const fetchEEvaluation = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const url = `report${eEvaluationVersion}/${uuid}`
  await callAxios
    .get(url)
    .then(({ data }) => {
      dispatch(setResult(data))
    })
    .catch((err) => {
      console.log(err)
    })
  dispatch(stopLoading())
}
