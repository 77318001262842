import React, { useEffect } from 'react'
import { Box } from '@mui/material'

import Header from './Header'
import Content from './Content'
import FilterDrawer from './Drawers/FilterDrawer'
import ClassDrawer from './Drawers/ClassDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import { fetchOverview } from './handler/fetchOverview'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import dayjs from 'dayjs'
import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

const Index = () => {
  const { isLoading } = useSelector(
    (state) => ({
      allClassDrawer: state.crud.manageClassDashboard?.allClassDrawer,
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )

  useEffect(() => {
    initializeOverview()
  }, [])

  const initializeOverview = async () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const station = _.get(user, 'area[0].station')
    await store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters',
        value: {
          station: station
            ? [
                {
                  label: station.station,
                  name: `${station.station.toLowerCase()}Checked`,
                  uuid: station.uuid,
                },
              ]
            : [],
          startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        },
      }),
    )
    await fetchOverview()
  }

  return (
    <Box px={2}>
      <Box>
        <Header />
      </Box>
      <Box my={3}>
        <Content />
      </Box>
      <FilterDrawer />
      <ClassDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default Index
