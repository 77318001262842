export const AGENT_LICENSE_SETTING = 'AGENT_LICENSE_SETTING'

export const PERMISSION = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  MENU: 'MENU',
}

export const OWNER = {
  ALL: 'ALL',
}

export const permissionApiToRcmsMenu = [
  {
    module: AGENT_LICENSE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.MENU],
  },
]

export const permissionApiToRcmsView = [
  {
    module: AGENT_LICENSE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.VIEW],
  },
]

export const permissionApiToRcmsCreate = [
  {
    module: AGENT_LICENSE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.CREATE],
  },
]

export const permissionApiToRcmsEdit = [
  {
    module: AGENT_LICENSE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.EDIT],
  },
]
