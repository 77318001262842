import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'

import CertificateForm from '../CertificateForm'
import SettingForm from '../SettingForm'
import { defaultValue } from '../../../../constants/eCertification'

const Index = ({
  setActiveStep,
  activeStep,
  isEditForm = false,
  isClone = false,
  isView = false,
}) => {
  const defaultTap = useSelector(
    (state) => state.eCertificateForm.defaultTap,
    shallowEqual
  )

  return (
    <Box>
      {defaultTap === defaultValue.defaultTap.certificate && (
        <CertificateForm
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          isEditForm={isEditForm}
          isClone={isClone}
          isView={isView}
        />
      )}
      {defaultTap === defaultValue.defaultTap.setting && <SettingForm />}
    </Box>
  )
}
export default Index
