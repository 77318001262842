import React from 'react'
import { Box, Card, Drawer } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledDrawer = styled(
  (props) => <Drawer anchor="right" {...props} />,
  {
    shouldForwardProp(propName) {
      return propName !== 'isShowPreview'
    },
  },
)(({ theme, isShowPreview }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    width: isShowPreview ? 900 : 1100,
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const StyledContent = styled(Box)(({ isLoading, theme }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  background: theme.palette?.background?.blue,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.10)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))
