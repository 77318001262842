import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import DisabledByDefault from '@mui/icons-material/DisabledByDefault'
import CheckBox from '@mui/icons-material/CheckBox'

import DialogImage, { NewDialogImage } from '../DialogImage'

import * as events from '../events'
import { BoxTable, StyledInputImport, TableCheckbox } from '../Styled'
import '../table.css'
import { UploadFile } from '@mui/icons-material'

export const ButtonAction = ({
  disabled,
  indexDoc,
  title,
  titleDownload,
  url,
  footer,
  hasSlash = true,
  indexDocument,
  indexList,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const idImage = `image-${indexDoc}`
  const type = _.get(url.split('.'), '[1]', 'png').toUpperCase()
  const typeFile = _.get(url.split('.'), '[1]', 'png')
  const newUrl = hasSlash
    ? window.__env__.REACT_APP_API_URL + '/file' + url
    : window.__env__.REACT_APP_API_URL + '/file/' + url

  const newDisabled = !url.length ? true : disabled

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton disabled={newDisabled} onClick={() => setIsOpen(true)}>
          <RemoveRedEyeTwoTone
            color={newDisabled ? 'action.disabled' : 'action'}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
        <IconButton
          disabled={newDisabled}
          onClick={() =>
            type === 'PDF'
              ? events.downloadFilePDF(titleDownload, newUrl, typeFile)
              : events.downloadImageDocument(titleDownload, newUrl, typeFile)
          }
        >
          <FileDownloadOutlined
            color={newDisabled ? 'action.disabled' : 'action'}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
      </Box>
      <DialogImage
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        url={newUrl}
        idImage={idImage}
        title={title}
        type={type}
        footer={footer}
        indexDoc={indexDocument}
        indexList={indexList}
      />
    </>
  )
}

export const NewButtonAction = ({
  user,
  indexDoc,
  indexDocument,
  indexList,
  titleDownload,
  url,
  footer,
  hasSlash = true,
  document,
  disableDelete = false,
  hideUpload = false,
  disableUpload = false,
  documentList,
  round,
  classLearnerDocumentUuid,
  trainingPlanUuid,
  classLearnerUuid,
}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const idImage = `image-${indexDoc}`
  const type = _.get(url?.split('.'), '[1]', 'png').toUpperCase()
  const typeFile = _.get(url?.split('.'), '[1]', 'png')
  const newUrl = hasSlash
    ? window.__env__.REACT_APP_API_URL + '/file' + url
    : window.__env__.REACT_APP_API_URL + '/file/' + url
  const newDisabled = !url?.length ? true : false
  user = JSON.parse(user)
  const userName = `${_.get(user, 'firstNameTH', '')} ${_.get(
    user,
    'lastNameTH',
    '',
  )}`
  const title = _.get(document, 'title', '')
  const editUser = JSON.stringify({
    uuid: _.get(user, 'uuid', ''),
    name: userName,
    email: _.get(user, 'email', ''),
  })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {hideUpload !== true && (
          <IconButton component="label" disabled={disableUpload}>
            <UploadFile
              color={disableUpload ? 'action.disabled' : 'action'}
              sx={{ width: 20, height: 20 }}
            />
            <StyledInputImport
              data-testid="import-template"
              id="import-template"
              accept=".pdf, .jpg, .png"
              type="file"
              onChange={(e) =>
                dispatch(
                  events.onUploadDocument(
                    e,
                    indexDocument,
                    _.get(document, 'title', ''),
                    editUser,
                    document,
                    documentList,
                    classLearnerDocumentUuid,
                    trainingPlanUuid,
                    classLearnerUuid,
                    round,
                  ),
                )
              }
            />
          </IconButton>
        )}
        <IconButton disabled={newDisabled} onClick={() => setIsOpen(true)}>
          <RemoveRedEyeTwoTone
            color={newDisabled ? 'action.disabled' : 'action'}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
        <IconButton
          disabled={newDisabled}
          onClick={() =>
            type === 'PDF'
              ? events.downloadFilePDF(titleDownload, newUrl, typeFile)
              : events.downloadImageDocument(titleDownload, newUrl, typeFile)
          }
        >
          <FileDownloadOutlined
            color={newDisabled ? 'action.disabled' : 'action'}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
      </Box>
      <NewDialogImage
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        url={newUrl}
        idImage={idImage}
        title={title}
        type={type}
        footer={footer}
        indexDocument={indexDocument}
        indexList={indexList}
        document={document}
        documentList={documentList}
        disableDelete={disableDelete || disableUpload}
        classLearnerDocumentUuid={classLearnerDocumentUuid}
      />
    </>
  )
}

const Index = (props) => {
  const {
    documentData,
    documentList,
    documentBody,
    lastDocument,
    round,
    roundLabel,
    itemId,
    name,
    listIndex,
    user,
    classLearnerDocumentUuid,
    trainingPlanUuid,
    statusClass,
    uploadRound,
    classLearnerUuid,
  } = props
  const dispatch = useDispatch()
  const status = _.get(lastDocument, 'status', '')
  return (
    <BoxTable>
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6">
          {events.labelText(roundLabel, status)}
        </Typography>
      </Box>
      <table id="table-document">
        <tr>
          <th id="title-document" className="th-border">
            <Typography variant="tableHeader">รายการ</Typography>
          </th>
          <th id="action" className="th-border">
            <Typography variant="tableHeader">คำสั่ง</Typography>
          </th>
          <th id="document-heder" className="th-border">
            <Typography variant="tableHeader">เอกสารไม่ผ่าน</Typography>
          </th>
        </tr>

        {documentList.map((item, index) => {
          const checkIndex = documentList.length === index + 1
          let isChecked = events.findUuid(
            documentBody.learnerDocumentList,
            item.uuid,
          )
          const className = checkIndex ? 'last-item' : 'item'

          const checkedStatus =
            item.status === 'APPROVED' || item.status === 'REJECTED'
              ? true
              : false

          if (checkedStatus) isChecked = true
          const titleDownload = `${_.get(item, 'title', '')}_${name}`
          const updatedBy = _.get(item, 'updatedBy', 'null')
          const updatedByObj = JSON.parse(updatedBy)
          const newDate = events.offsetDate(_.get(item, 'updatedAt', ''), -7)
          const footerName =
            _.get(updatedByObj, 'name', '') +
            ' ' +
            newDate.toLocaleString('en-GB', {
              dateStyle: 'short',
              timeStyle: 'short',
            })
          let disableUpload = true
          const lastDocument = _.maxBy(documentData, 'id')
          if (itemId === _.get(lastDocument, 'id')) {
            disableUpload = false
          }

          return (
            <>
              <tr key={index}>
                <td id="title-document" className={'td-border' + className}>
                  <Typography variant="body2">
                    {_.get(item, 'title', '')}
                  </Typography>
                </td>
                <td id="action" className={'td-border' + className}>
                  <NewButtonAction
                    user={user}
                    document={item}
                    documentList={documentList}
                    classLearnerDocumentUuid={classLearnerDocumentUuid}
                    trainingPlanUuid={trainingPlanUuid}
                    title={_.get(item, 'title', '')}
                    titleDownload={titleDownload}
                    indexDoc={`${round}-${index}`}
                    indexDocument={index}
                    indexList={listIndex}
                    url={_.get(item, 'key', '')}
                    footer={footerName}
                    documentData={documentData}
                    disableUpload={statusClass === 'CLOSED' || disableUpload}
                    round={uploadRound}
                    classLearnerUuid={classLearnerUuid}
                  />
                </td>
                <td id="document" className={'td-border' + className}>
                  <TableCheckbox
                    disabled={round === 0 || checkedStatus}
                    inputProps={{
                      'data-testid': 'checked',
                    }}
                    name="activeChecked"
                    checked={isChecked}
                    isChecked={isChecked}
                    isApprove={item.status === 'APPROVED'}
                    checkedStatus={checkedStatus}
                    isRound={round === 0}
                    indeterminate={round === 0}
                    checkedIcon={
                      item.status === 'APPROVED' ? (
                        <CheckBox />
                      ) : (
                        <DisabledByDefault />
                      )
                    }
                    onChange={(e) =>
                      dispatch(
                        events.handleChecked({
                          isChecked: _.get(e, 'target.checked', false),
                          uuid: item.uuid,
                        }),
                      )
                    }
                  />
                </td>
              </tr>
            </>
          )
        })}
      </table>
    </BoxTable>
  )
}

export default Index
