import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  eLearningDashboardByCourse,
  eLearningDashboardLearner,
} from '../../../utils/apiPath'

export const filterELearningDashboardByCourse = createAsyncThunk(
  'eLearningDashboardByCourse/filterELearningDashboard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningDashboardByCourse, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const filterLearnerByCourse = createAsyncThunk(
  'eLearningDashboardByCourse/filterLearnerByCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningDashboardLearner, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
