import { Divider, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import React from 'react'
import {
  StyledColumn,
  StyledRow,
} from '../../../../../../modules/RoomManagement/Styled'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { roomSelector } from '../../../../../../redux/slices/roomManagement/Drawer'

export default function Equipment({ bookingDate, roomIndex }) {
  const meetingRoomEquipment = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoom.meetingRoomEquipment',
    }),
    shallowEqual,
  )

  const roomName = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoom.roomName',
    }),
    shallowEqual,
  )

  const theme = useTheme()
  if (meetingRoomEquipment?.length === 0 || !roomName) {
    return null
  }

  return (
    <StyledColumn sx={{ gap: 1, pl: 10 }}>
      <Typography variant="body2b">อุปกรณ์ภายในห้อง</Typography>
      <StyledRow sx={{ gap: 2 }}>
        <Typography variant="body2b" color="primary">
          {roomName.toString()}
        </Typography>
        <StyledRow
          sx={{
            borderRadius: 1,
            px: 2,
            py: 1,
            gap: 2,
            border: `1px solid ${theme.palette?.blue?.blueLine}`,
          }}
        >
          {_.map(_.castArray(meetingRoomEquipment), (item, idx) => {
            const { equipment, quantity } = item
            return (
              <StyledRow sx={{ gap: 2 }} key={idx}>
                <StyledRow>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {equipment.equipmentName}
                  </Typography>
                  <Typography variant="body1b">
                    {_.toInteger(quantity)}
                  </Typography>
                </StyledRow>
                {idx !== meetingRoomEquipment.length - 1 && (
                  <Divider
                    sx={{
                      height: 'auto',
                      borderColor: theme.palette?.blue?.blueLine,
                    }}
                    orientation="vertical"
                    flexItem
                  />
                )}
              </StyledRow>
            )
          })}
        </StyledRow>
      </StyledRow>
    </StyledColumn>
  )
}
