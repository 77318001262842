import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { StyledCard } from '../../../Styled'

export const StyledNewCard = styled((props) => (
  <StyledCard
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      pt: 1,
      pb: 3,
      px: 3,
    }}
  />
))(() => {})

export const StyledCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueLine,
}))

export const StyledFooterText = styled(Typography)({ textAlign: 'right' })

export const StyledContentRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  '& > .MuiTypography-root#label': { width: 300, minWidth: 300 },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const StyledBtnMore = styled((props) => (
  <Link underline="none" {...props} />
))(() => ({ display: 'flex', alignItems: 'center', cursor: 'pointer' }))

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="m" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledUl = styled('ul')(() => ({ paddingLeft: 30, margin: 0 }))

export const StyledDashedDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueLine,
  borderStyle: 'dashed',
}))

export const StyledChipTypography = styled(Typography)(({ theme }) => ({
  height: 22,
  lineHeight: '20px',
  border: '1px solid',
  borderColor: theme.palette?.text?.lightGray,
  borderRadius: 100,
  padding: `0 ${theme.spacing(1)}`,
  marginTop: theme.spacing(0.5),
}))
