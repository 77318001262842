import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  Column,
  Container,
  Row,
  // Dot,
  // Row,
  // Column,
  StyledButton,
  StyledName,
  StyledNumber,
  // SubLableBox,
} from '../Styled'
import { Box } from '@mui/material'
import _ from 'lodash'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'
import { useTheme } from '@emotion/react'

const RankingCard = ({
  title,
  type,
  // headers,
  // data,
  // dotColor,
  // value,
  // unit,
  // percent,
  // handleClickButton,
  // fontColor,
  background,
  border,
  boxSx = {},
  minWidth = 200,
  list = [],
  header1 = 'สนามสอบบริษัท',
  header2 = 'จำนวนรอบ',
}) => {
  const theme = useTheme()
  return (
    <Container
      background={background}
      border={!_.isEmpty(border) ? border : 'solid 1px #DBE4F1'}
      minWidth={minWidth}
      sx={boxSx}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent="space-between"
      >
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
          {title}
        </Typography>
        <StyledButton
          color="text.lightGray"
          size="small"
          background="#4976BA14"
          marginLeft={''}
          onClick={() => handleClickOpenDrawer(type)}
        >
          <ChevronRight fontSize="small" />
        </StyledButton>
      </Box>
      <Box>
        <Row sx={{ mb: 1 }}>
          <Typography
            variant="body3"
            color="text.lightGray"
            sx={{ width: 24, ml: 0.5, mr: 0.75, textAlign: 'center' }}
          >
            #
          </Typography>
          <Typography
            variant="body3"
            color="text.lightGray"
            sx={{ width: 110, ml: 0.5, mr: 0.75 }}
          >
            {header1}
          </Typography>
          <Typography
            variant="body3"
            color="text.lightGray"
            sx={{ width: 100, ml: 0.5, mr: 0.75 }}
          >
            {header2}
          </Typography>
        </Row>
        <Column sx={{ gap: 1 }}>
          {list.length > 0 ? (
            list.map((ele, ind) => {
              return (
                <List
                  key={ind}
                  number={ind + 1}
                  data={ele}
                  color={theme.palette.primary.main}
                  background="#F1F4FA"
                />
              )
            })
          ) : (
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ margin: '98px', textAlign: 'center' }}
            >
              ไม่พบข้อมูล
            </Typography>
          )}
        </Column>
      </Box>
    </Container>
  )
}

export default RankingCard

const List = ({ number, data, color, background, nameColor }) => {
  return (
    <Row sx={{ p: 0.5, borderRadius: 2, background: background }}>
      <StyledNumber variant="caption" color="text.white" background={color}>
        {number}
      </StyledNumber>
      <StyledName
        variant="body2b"
        color={nameColor || color}
        sx={{ width: 110, ml: 0.5, mr: 0.75 }}
      >
        {data.name}
      </StyledName>
      <StyledName variant="body2b" color={nameColor || color}>
        {data.number} {data.unit}
      </StyledName>
    </Row>
  )
}
