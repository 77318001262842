import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'

import Score from '../Score'
import Question from '../Question'
import DragDropAnswer from '../DragDropAnswer'
import AddAnswer from '../AddAnswer'
import AlertErrorBox from '../AlertErrorBox'

import * as StyledForm from '../Styled'
import { getErrorMessage } from '../event'

const Sequence = ({ keyQuestion, indexQuestion }) => {
  const errorSchemaMessage = useSelector(
    (state) => state.eTestingForm.errorSchemaMessage,
    shallowEqual
  )
  const answerErrorMessage = getErrorMessage({
    field: 'CHOICE_NUM',
    errorSchemaMessage,
    indexQuestion,
  })

  return (
    <Card>
      <StyledForm.BoxForm>
        <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
        {answerErrorMessage !== '' && (
          <Box mb={3}>
            <AlertErrorBox text={answerErrorMessage} />
          </Box>
        )}

        <Box sx={{ pt: 2 }}>
          <Box sx={{ ml: 1.5, mb: 1, display: 'flex', gap: 3 }}>
            <Typography color="text.secondary" variant="body1b">
              ลำดับของคำตอบ{' '}
              <Typography color="error.main" variant="span">
                *
              </Typography>
            </Typography>
          </Box>

          <DragDropAnswer
            keyQuestion={keyQuestion}
            indexQuestion={indexQuestion}
          />
        </Box>

        <AddAnswer label="เพิ่มตัวเลือก" keyQuestion={keyQuestion} />
      </StyledForm.BoxForm>

      <Score keyQuestion={keyQuestion} questionType="SEQUENCE" />
    </Card>
  )
}

export default Sequence
