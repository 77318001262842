import React, { useEffect } from 'react'

import Close from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import { getHistory } from '../../../../../services/monthlyPlan/index'

import { DrawerHistory, HeadLabelHistory, StyledIcon } from './Styled'
import TimelineHistory from './TimelineHistory'

const HistoryDrawer = ({
  isOpen,
  onCloseDrawer,
  planUuid,
  typeTime,
  startTime,
  endTime,
  classTime,
  checkRoom,
}) => {
  const dispatch = useDispatch()
  const historyList = useSelector((state) => state.monthlyPlan.historyList)
  const url = `?planUuid=${planUuid}`

  useEffect(() => {
    dispatch(getHistory(url))
  }, [])

  return (
    <DrawerHistory anchor={'right'} open={isOpen} onClose={onCloseDrawer}>
      <HeadLabelHistory>
        <Box
          sx={{
            m: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            <StyledIcon></StyledIcon>
            ประวัติการเปลี่ยนแปลงวิทยากร
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={onCloseDrawer}
            sx={{ ml: 35 }}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ gap: 1 }}>
          <Typography variant="h5">{classTime}</Typography>
          <Typography variant="h5">{typeTime}</Typography>
          <Typography variant="h5">
            {startTime} - {endTime}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ mt: -3 }}>
            {checkRoom}
          </Typography>
        </Box>
        <Divider />
      </HeadLabelHistory>
      <Box sx={{ mx: 1 }}>
        <TimelineHistory historyList={historyList} />
      </Box>
    </DrawerHistory>
  )
}

export default HistoryDrawer
