import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  paddingLeft: theme.spacing(6),
  '& .MuiBox-root': {
    margin: `${theme.spacing(1)} 0`,
  },
}))

export const StyledLabel = styled(Typography)(({ theme }) => ({
  span: { color: theme?.palette?.error?.main },
}))
