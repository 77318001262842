import React from 'react'
import { useDispatch /*useSelector*/ } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table2'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  TicketStatus,
} from '../../../../../constants/stock'
import { onCancel } from './events'
import _ from 'lodash'
import { validatePermission } from '../../../../../utils/lib'

const Action = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const { onDelete } = useSelector((state) => state.table2)
  const viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`

  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionALL = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const hasPermissionPIC = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.CREATE],
  })
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const toStationUuid = _.get(row, 'to.station.uuid', '')
  const checkStaffStationMatched = staffStationUuid === toStationUuid

  return (
    <StyledBox minWidth={minWidth}>
      {![TicketStatus.DRAFT].includes(row.status) && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}

      {[TicketStatus.DRAFT].includes(row.status) && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => {
            dispatch(setOnEdit({ history, editUrl }))
          }}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {[TicketStatus.DRAFT].includes(row.status) && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onCancel(row, TicketStatus.CANCELED)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}

      {[TicketStatus.WAITING_APPROVE].includes(row.status) &&
        (hasPermissionALL ||
          (hasPermissionPIC && checkStaffStationMatched)) && (
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => onCancel(row, TicketStatus.REJECTED)}
          >
            <CloseIcon color="action" />
          </IconButton>
        )}
    </StyledBox>
  )
}

export default Action
