import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่เริ่มต้นหลักสูตร',
    nameCheckBox: 'dateFilterChecked',
    nameInput: 'dateFilter',
    placeholder: 'เลือกวันที่',
    dateStateStart: 'dateFilterStartDate',
    dateStateFinish: 'dateFilterFinishDate',
    dateStateDisplay: 'dateFilterDisplayDate',
    type: 'daterange',
  },
  {
    label: 'RAM',
    nameCheckBox: 'ramChecked',
    nameInput: 'ram',
    type: 'DROPDOWN_MULTI',
    list: [],
    placeholder: 'เลือก RAM',
  },
  {
    label: 'SRAM',
    nameCheckBox: 'sramChecked',
    nameInput: 'sram',
    type: 'DROPDOWN_MULTI',
    list: [],
    placeholder: 'เลือก SRAM',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'DROPDOWN_MULTI',
    list: [],
  },
  {
    label: 'ระดับหลักสูตร',
    nameCheckBox: 'skillChecked',
    nameInput: 'skill',
    type: 'DROPDOWN_MULTI',
    list: [],
  },
  {
    label: 'ช่องทางการจัดจำหน่าย',
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    type: 'checkbox',
    list: [],
  },
]

export const defaultFilter = {
  dateFilterChecked: true,
  dateFilterStartDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  dateFilterFinishDate: new Date(dayjs().format('YYYY-MM-DD')),
  dateFilterDisplayDate: true,
  ramChecked: false,
  ramList: [],
  sramChecked: false,
  sramList: [],
  stationChecked: false,
  stationList: [],
  skillChecked: false,
  skillList: [],
  distributionChecked: false,
}
