import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Background from './../../../../components/Preview/Background'
import Header from './Header'
import Footer from './Footer'
import Questions from './Questions'
import Result from './Result'
import { fetchEvaluate } from './events'

export const EvaluateForm = () => {
  return (
    <>
      <Questions />
      <Footer />
    </>
  )
}

const Evaluate = ({ isModule }) => {
  const [eEvaluation] = useLocalStorage('eEvaluation')
  const dispatch = useDispatch()
  const { section, isLoading } = useSelector(
    (state) => ({
      section: state.eEvaluationPreview.section,
      isLoading: state.eEvaluationPreview.isLoading,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(fetchEvaluate(eEvaluation, isModule))
  }, [])

  if (isLoading) return <></>
  return (
    <>
      {!isModule && <Header />}
      <Background withHeader>
        {section == 'EVALUATE' && <EvaluateForm />}
        {section == 'RESULT' && <Result isModule={isModule} />}
      </Background>
    </>
  )
}

export default Evaluate
