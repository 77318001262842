import _ from 'lodash'

export const getOptionsAccountCode = (data) => {
  return _.map(data, (item) => {
    return {
      ...item,
      label: `${item['oldAccountCode']} / ${item['newAccountCode']}`,
      value: item['uuid'],
    }
  })
}
