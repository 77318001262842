import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ReactInputMask from 'react-input-mask'

const InputMask = (props) => {
  const {
    boxSx,
    id,
    name,
    maskChar,
    mask,
    placeholder,
    labelText,
    required,
    disabled,
    onChange,
    textError,
    value,
    isShowTextError = true,
    testId,
  } = props

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <ReactInputMask
        id={id}
        name={name}
        maskChar={maskChar}
        mask={mask}
        onChange={onChange}
        value={value}
      >
        {() => (
          <TextField
            placeholder={disabled ? '' : placeholder}
            inputProps={{
              'data-testid': testId,
              disabled: disabled,
            }}
            sx={{ backgroundColor: disabled && '#F5F5F5' }}
          />
        )}
      </ReactInputMask>

      {isShowTextError && (
        <Typography sx={{ height: '25px' }} variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}
export default InputMask
