import _ from 'lodash'
import { initialState } from '../../../../../../../redux/slices/eLearning/dashboardByCourse/model'
import {
  setFilter,
  setIsOpenFilterDrawer,
  startLoadingDrawer,
  stopLoadingDrawer,
} from '../../../../../../../redux/slices/eLearning/dashboardByCourse'
import { store } from '../../../../../../../App'
import { fetchDashboardByCourse } from '../events'

export const handleChange = ({ key, value }) => {
  const { filter } = store.getState().eLearningDashboardByCourse

  const objState = {
    ...filter,
    [key]: value,
    courseBatchList:
      key === 'courseBatchChecked' && !value
        ? []
        : _.get(filter, 'courseBatchList'),
  }
  store.dispatch(setFilter(objState))
}

export const handleSelectDate = (selectedDates) => {
  const { filter } = store.getState().eLearningDashboardByCourse
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  store.dispatch(setFilter(objState))
}

export const onClearClick = () => {
  store.dispatch(startLoadingDrawer())
  store.dispatch(setFilter(_.get(initialState, 'filter')))
  setTimeout(() => {
    store.dispatch(stopLoadingDrawer())
  }, 2000)
}

export const handleChangeValue = (list) => {
  const { filter } = store.getState().eLearningDashboardByCourse

  let objState = {
    ...filter,
    courseBatchList: _.map(list, (li) => _.get(li, 'uuid')),
  }
  store.dispatch(setFilter(objState))
}

export const filterDashboard = async () => {
  const { filter } = store.getState().eLearningDashboardByCourse
  let objState = {
    ...filter,
    periodStart: filter.startDate,
    periodEnd: filter.endDate,
  }
  store.dispatch(setIsOpenFilterDrawer(false))
  store.dispatch(setFilter(objState))
  await fetchDashboardByCourse()
}
