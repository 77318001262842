import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { ImageDialog } from '../ImageDialog'

const Image = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState()
  const handleClickOpen = (e) => {
    setIsOpen(true)
    setSelectedValue(e.target.src)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleColumn = (length) => {
    switch (length) {
      case 1:
        return {
          xs: 6,
          lg: 3,
        }
      case 2:
        return {
          xs: 12,
          lg: 6,
        }
      case 3:
        return {
          xs: 12,
          lg: 9,
        }
      default:
        return {
          xs: 12,
          lg: 12,
        }
    }
  }

  return (
    <>
      <Grid
        container
        columns={handleColumn(data.content.files.length)}
        spacing={1}
      >
        {data.content.files.map((item, index) => (
          <Grid item key={index} xs={6} lg={3}>
            <img
              src={`${window.__env__.REACT_APP_API_URL}/file/get${item.key}`}
              alt="image"
              width={'100%'}
              onClick={(e) => handleClickOpen(e)}
            ></img>
          </Grid>
        ))}
      </Grid>
      <ImageDialog
        selectedValue={selectedValue}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  )
}

export default Image
