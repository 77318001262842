export const headCells = [
  {
    id: 'event',
    disablePadding: false,
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'author',
    disablePadding: false,
    label: 'ผู้แก้ไข',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },
]
