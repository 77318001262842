import axios from 'axios'
import _ from 'lodash'
import { prospectProfileLicense } from '../../../../../../utils/apiPath'
import {
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/manage/agentProfile'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    hideSortIcon: true,
  },
  {
    id: 'englishName',
    label: 'หลักสูตร',
    hideSortIcon: true,
  },
  {
    id: 'createdBy',
    label: 'ผู้ทำรายการ',
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'registerDate',
    label: 'วันเวลาที่สมัคร',
    hideSortIcon: true,
  },
]

export const fetchCourseHistory =
  ({ uuid, setData, events }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const page = _.get(events, 'page', 0)
    const body = {
      prospectUuid: uuid,
      limit: _.get(events, 'limit', 20),
      page: page <= 0 ? 1 : page,
    }
    try {
      const response = await axios.post(prospectProfileLicense, body)
      setData(response?.data)
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(stopLoading())
    }
  }
