import React from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import styled from '@mui/system/styled'
export const StyledPopper = styled((props) => (
  <Popper role={undefined} transition disablePortal {...props} />
))(() => ({
  zIndex: 99,
  '& .MuiPaper-root': {
    borderRadius: 0,
    '& .MuiDivider-root': {
      margin: '4px 0 !important',
    },
  },
}))

export const DialogDelete = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '& .MuiTypography-root': {
    wordBreak: 'break-word',
    span: {
      color: theme?.palette?.info?.main,
      fontStyle: 'italic',
    },
  },
  '& .MuiBox-root': {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}))
