import React from 'react'
import Button from '@mui/material/Button'
import { StyledHeaderContainer } from '../../Styled'
import Add from '@mui/icons-material/Add'
import { handleOpenCreateStockDialog } from '../../../../../redux/slices/inventory/stock/list/event'
import { validatePermission } from '../../../../../utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

const Index = () => {
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  return (
    <StyledHeaderContainer>
      <Button
        disabled={!hasPermissionCreate}
        data-testid="btn-download-0"
        variant="contained"
        size="m"
        onClick={() => {
          handleOpenCreateStockDialog()
        }}
        startIcon={<Add />}
      >
        สร้างคลังทรัพย์สิน
      </Button>
    </StyledHeaderContainer>
  )
}
export default Index
