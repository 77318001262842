import React, { useRef, useState, useEffect, useMemo } from 'react'
import {
  BoxDisplay,
  LayoutIconPreview,
  StyledContainer,
  StyledDisplayBox,
  StyledFullBox,
  StyledInputBox,
  StyledPreviewBox,
  StyledPreviewImageBox,
  TypographyDisplay,
} from './Styled'
import { LoadingButton } from '@mui/lab'
import _ from 'lodash'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import CoverImage from '../../Background/Form/CoverImage/index'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as event from '../event'
import TextInput from '../../../../components/Input/TextInput'
import ButtonPopper from './ButtonPopper'
import { LINE_OPTION } from '../../../../constants/eCertification'
import * as reducer from '../../../../redux/slices/eCertificateForm'
import { useHistory, useParams } from 'react-router-dom'
import { onErrorNextValidate } from '../../Background/Form/event'
import Dot from '../../../../components/Table/Dot'
import { getStatusText } from '../../../../utils/lib'
import { StyledBtnMore } from '../../../../modules/EEvaluation/Version/View/Content/Styled'

const LayoutCertificate = ({
  setActiveStep,
  activeStep,
  isEditForm = false,
  isClone = false,
  isView = false,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { codeId } = useParams()
  const [isSelectLayout, setSelectLayout] = useState(0)
  const [IsSelectBackground, setSelectBackground] = useState('')
  const {
    formReasonEdit,
    formCodeId,
    formVersion,
    formName,
    formImageKey,
    formLayout,
    loadingProgress,
    uploadError,
    layouts,
    backgrounds,
    formBackground,
    errors,
    startDate,
    createdBy,
    status,
  } = useSelector(
    (state) => ({
      isLoading: state.eCertificateForm.isLoading,
      formReasonEdit: state.eCertificateForm.formReasonEdit,
      formCodeId: state.eCertificateForm.formCodeId,
      formVersion: state.eCertificateForm.formVersion,
      formName: state.eCertificateForm.formName,
      formLayout: state.eCertificateForm.formLayout,
      formBackground: state.eCertificateForm.formBackground,
      formImageKey: state.eCertificateForm.formBackground?.imageKey,
      loadingProgress: state.eCertificateForm?.loadingProgress,
      uploadError: state.eCertificateForm?.uploadError,
      status: state.eCertificateForm.status,
      layouts: state.eCertificateForm.layouts,
      backgrounds: state.eCertificateForm.backgrounds,
      errors: state.eCertificateForm.errors,
      createdBy: state.eCertificateForm.createdBy,
      startDate: state.eCertificateForm.startDate,
    }),
    shallowEqual
  )
  const componentRef = useRef()
  const componentRef2 = useRef()
  const { width } = event.useContainerDimensions(componentRef, activeStep)
  const { width: w2 } = event.useContainerDimensions(componentRef2, activeStep)

  const changeBackground = async (id) => {
    dispatch(
      event.changeBackgroundEvent({
        id: id,
        setSelectBackground: setSelectBackground,
        backgrounds: backgrounds,
      })
    )
  }
  const changeLayout = async (id) => {
    dispatch(
      event.changeLayoutEvent({
        id: id,
        setSelectLayout: setSelectLayout,
        layouts: layouts,
      })
    )
  }

  const debouncedMultiline = useMemo(
    () =>
      _.debounce((e, key, k) => {
        if (String(e.target.value).length <= 255)
          dispatch(
            event.ChangeLayout({
              list: formLayout,
              text: e.target.value,
              key: key,
              subKey: k,
              type: LINE_OPTION.MULTILINE,
            })
          )
      }, 1000),
    [codeId, formLayout]
  )

  const debouncedSigle = useMemo(
    () =>
      _.debounce((e, key) => {
        if (String(e.target.value).length <= 255)
          dispatch(
            event.ChangeLayout({
              list: formLayout,
              text: !_.isEmpty(e.target.value) ? e.target.value : '\n',
              key: key,
            })
          )
      }, 1000),
    [codeId, formLayout]
  )

  useEffect(() => {
    if (_.isEmpty(formBackground?.codeId) && isSelectLayout > 0)
      changeBackground('BG0')
  }, [isSelectLayout])

  const NextStep = ({ isSubmit = false }) => {
    let error = {}
    if (_.isEmpty(formName)) {
      error = { ...error, name: 'กรุณากรอกข้อมูลในช่องนี้' }
    }
    if (_.isEmpty(formLayout?.name)) {
      error = { ...error, layout: 'กรุณาเลือก Layout' }
    }

    if (
      !isEditForm &&
      !isClone &&
      _.isEmpty(formBackground?.imageKey) &&
      _.isEmpty(IsSelectBackground)
    ) {
      error = { ...error, background: 'กรุณาเลือกภาพพื้นหลัง' }
    }
    if (!_.isEmpty(error)) {
      dispatch(onErrorNextValidate())
      dispatch(
        reducer.setChange({
          key: 'errors',
          value: error,
        })
      )
      return null
    } else {
      dispatch(
        reducer.setChange({
          key: 'errors',
          value: {},
        })
      )
    }
    if (isSubmit) {
      dispatch(
        event.onSubmitFormECertificationVersion({
          codeId: isClone ? formCodeId : codeId,
          name: formName,
          layout: formLayout,
          background: _.isEmpty(formBackground) ? null : formBackground?.uuid,
          setActiveStep: setActiveStep,
          history: history,
          isClone: isClone,
        })
      )
    } else {
      setActiveStep((o) => o + 1)
    }
  }

  return (
    <StyledContainer container>
      <StyledPreviewBox
        item
        xs={12}
        md={activeStep === 2 && !isEditForm ? 12 : 7}
      >
        {activeStep === 2 && !isEditForm && (
          <Typography color="text.primary" variant="body1">
            กรุณาตรวจสอบความถูกต้อง
          </Typography>
        )}
        <StyledPreviewImageBox ref={componentRef}>
          {!_.isEmpty(loadingProgress) ||
          formImageKey ||
          !_.isEmpty(formLayout.name) ? (
            <CoverImage
              layouts={_.get(layouts, 'result', [])}
              isError={uploadError}
              formLayout={formLayout}
              IsSelectBackground={IsSelectBackground}
              isSelectLayout={isSelectLayout}
              loadingProgress={loadingProgress}
              url={formImageKey}
              width={width}
            />
          ) : (
            <LayoutIconPreview>
              <RemoveRedEye />
              <Typography variant="body1b" color="text.sliver">
                ภาพตัวอย่าง
              </Typography>
            </LayoutIconPreview>
          )}
        </StyledPreviewImageBox>
        {!isView && (
          <Box sx={{ display: 'flex' }}>
            <Typography color="text.gray" variant="body2">
              ภาพตัวอย่าง
            </Typography>
            {formLayout && activeStep === 2 && !isEditForm && (
              <Box>
                <Typography color="text.gray" variant="body2">
                  {`: ${_.get(formLayout, 'name', '')} + ${
                    !_.isEmpty(formBackground?.name)
                      ? formBackground?.name
                      : 'ไม่ใส่ภาพพื้นหลัง'
                  }`}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </StyledPreviewBox>
      {activeStep === 0 && !isView && (
        <StyledInputBox xs={12} item md={5}>
          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
              }}
            >
              <TextInput
                id="formCodeId"
                name="formCodeId"
                labelText="ID"
                type="basic"
                disabled={true}
                textError={_.get(errors, 'codeId', '')}
                required
                defaultValue={formCodeId}
              />
              <TextInput
                id="formVersion"
                name="formVersion"
                labelText="เวอร์ชั่น"
                type="basic"
                disabled={true}
                textError={_.get(errors, 'version', '')}
                required
                defaultValue={formVersion}
              />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <TextInput
              id="formName"
              name="formName"
              labelText="ชื่อประกาศนียบัตร"
              type="basic"
              disabled={false}
              textError={
                String(formName).length >= 255
                  ? 'ครบตามจำนวนที่กำหนด 255 ตัวอักษร'
                  : _.get(errors, 'name', '')
              }
              required
              maxlength={255}
              defaultValue={formName}
              onBlur={(e) => dispatch(event.changeFormName(e))}
            />
          </Box>
          <Grid container ref={componentRef2}>
            <Typography
              sx={{ display: 'flex' }}
              variant="body2"
              color="text.secondary"
            >
              เลือก Layout ({_.get(layouts, 'totalCount', 0)})
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
            <event.LayoutOption
              layouts={_.get(layouts, 'result', [])}
              setSelectLayout={changeLayout}
              isSelectLayout={formLayout.id}
              width={w2}
            />
            {errors.layout && (
              <Typography sx={{ height: '25px' }} variant="body2" color="error">
                {errors.layout}
              </Typography>
            )}
          </Grid>
          <Grid container sx={{ marginTop: '24px' }}>
            <Typography
              sx={{ display: 'flex' }}
              variant="body2"
              color="text.secondary"
            >
              เลือกภาพพื้นหลัง ({_.get(backgrounds, 'totalCount', 0)})
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
            <event.BackgroundOption
              layouts={_.get(backgrounds, 'result', [])}
              setSelectLayout={changeBackground}
              IsSelectBackground={formBackground.codeId}
              width={w2}
            />
            {errors.background && (
              <Typography sx={{ height: '25px' }} variant="body2" color="error">
                {errors.background}
              </Typography>
            )}
          </Grid>
          <Box
            sx={{
              marginTop: '24px',
              display: 'flex',
              height: '100%',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            <LoadingButton
              data-testid="btn-next"
              variant="contained"
              size="medium"
              onClick={NextStep}
              endIcon={<ArrowForward />}
              loading={false}
              loadingPosition="end"
              disabled={false}
            >
              ถัดไป
            </LoadingButton>
          </Box>
        </StyledInputBox>
      )}
      {activeStep === 1 && (
        <StyledInputBox xs={12} item md={5}>
          {_.map(formLayout.layout, (item, key) => {
            if (item.type === LINE_OPTION.MULTILINE) {
              return (
                <Box
                  key={`layouy_by_key_${key}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '12px',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{ display: 'flex' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      {`Line ${key + 1}`}
                    </Typography>
                    <ButtonPopper
                      list={formLayout}
                      propColor={_.get(item, 'style.color', '#000')}
                      propFontSize={_.get(item, 'style.fontSize', 0).replace(
                        'px',
                        ''
                      )}
                      propFontFamily={_.get(
                        item,
                        'style.fontFamily',
                        'Helvethaica'
                      )}
                      propFontWeight={_.get(item, 'style.fontWeight', 'normal')}
                      Index={key}
                    />
                  </Box>
                  {_.map(item.layout, (i, k) => (
                    <TextInput
                      key={`sub_list_key_${k}_${key}`}
                      boxSx={{ marginBottom: 1 }}
                      id="name"
                      name="name"
                      type="basic"
                      maxlength={255}
                      disabled={i.disable}
                      defaultValue={i.text}
                      textError={
                        String(i.text).length >= 255
                          ? 'ครบตามจำนวนที่กำหนด 255 ตัวอักษร'
                          : ''
                      }
                      onChange={(e) => debouncedMultiline(e, key, k)}
                    />
                  ))}
                </Box>
              )
            }
            return (
              <Box
                key={`layouy_by_key_${key}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{ display: 'flex' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {`Line ${key + 1}`}
                  </Typography>
                  <ButtonPopper
                    list={formLayout}
                    propColor={_.get(item, 'style.color', '#000')}
                    propFontSize={_.get(item, 'style.fontSize', 0).replace(
                      'px',
                      ''
                    )}
                    propFontFamily={_.get(
                      item,
                      'style.fontFamily',
                      'Helvethaica'
                    )}
                    propFontWeight={_.get(item, 'style.fontWeight', 'normal')}
                    Index={key}
                  />
                </Box>
                <TextInput
                  id="name"
                  name="name"
                  type="basic"
                  disabled={item.disable}
                  defaultValue={item.text}
                  maxlength={255}
                  textError={
                    String(item.text).length >= 255
                      ? 'ครบตามจำนวนที่กำหนด 255 ตัวอักษร'
                      : ''
                  }
                  onChange={(e) => debouncedSigle(e, key)}
                />
              </Box>
            )
          })}

          <Box
            sx={{
              marginTop: '24px',
              display: 'flex',
              height: '100%',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            {activeStep === 1 && (
              <Button
                sx={{ marginRight: '24px' }}
                data-testid="btn-back"
                variant="outlined"
                size="medium"
                onClick={() => setActiveStep((o) => o - 1)}
                startIcon={<ArrowBack />}
                disabled={false}
              >
                กลับ
              </Button>
            )}
            {activeStep !== 2 && (
              <LoadingButton
                variant="contained"
                data-testid="btn-next"
                size="medium"
                onClick={() => setActiveStep((o) => o + 1)}
                endIcon={<ArrowForward />}
                loading={false}
                loadingPosition="end"
                disabled={false}
              >
                ถัดไป
              </LoadingButton>
            )}
          </Box>
        </StyledInputBox>
      )}
      {activeStep === 2 && !isEditForm && (
        <StyledFullBox xs={12} item md={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            <Typography color="text.primary" variant="body1">
              กรุณาตรวจสอบความถูกต้อง
            </Typography>
            <Box
              sx={{
                marginTop: '24px',
                display: 'flex',
                height: '100%',
              }}
            >
              <Button
                sx={{ marginRight: '24px' }}
                data-testid="btn-back"
                variant="outlined"
                size="medium"
                onClick={() => setActiveStep((o) => o - 1)}
                startIcon={<ArrowBack />}
                disabled={false}
              >
                กลับ
              </Button>
              <LoadingButton
                variant="contained"
                size="medium"
                onClick={() => NextStep({ isSubmit: true })}
                loading={false}
                disabled={false}
              >
                บันทึกและเผยแพร่
              </LoadingButton>
            </Box>
          </Box>
        </StyledFullBox>
      )}
      {activeStep === 2 && isEditForm && (
        <StyledInputBox xs={12} item md={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <TextInput
              id="formReasonEdit"
              name="formReasonEdit"
              labelText="เหตุผลการแก้ไข"
              type="basic"
              multiline={true}
              rows={3}
              maxlength={3000}
              disabled={false}
              onBlur={(e) => dispatch(event.changeFormReasonEdit(e))}
              defaultValue={formReasonEdit}
              required
              textError={
                String(formReasonEdit).length >= 3000
                  ? 'ครบตามจำนวนที่กำหนด 3,000 ตัวอักษร'
                  : _.get(errors, 'reason', '')
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            <Button
              data-testid="btn-back"
              variant="outlined"
              size="medium"
              onClick={() => setActiveStep((o) => o - 1)}
              startIcon={<ArrowBack />}
              disabled={false}
            >
              กลับ
            </Button>
          </Box>
        </StyledInputBox>
      )}
      {isView && !_.isEmpty(formCodeId) && (
        <StyledDisplayBox xs={12} item md={5}>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              ID
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {formCodeId}
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              เวอร์ชั่น
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {formVersion}
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              ชื่อประกาศนียบัตร
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {formName}
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              Layout
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {_.get(formLayout, 'name')}
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              ภาพพื้นหลัง
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {_.get(formBackground, 'name', '')}
            </Typography>
          </BoxDisplay>
          <CourseContent />
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              ผู้สร้าง
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {_.get(createdBy, 'name', '')}
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              ผู้ร่วมจัดทำ
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              -
            </Typography>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              สถานะ
            </TypographyDisplay>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Dot sx={{ mt: 1.5, mr: 1.5 }} status={status} />
              <Typography color="text.primary" variant="body1">
                {getStatusText(status)}
              </Typography>
            </Box>
          </BoxDisplay>
          <BoxDisplay>
            <TypographyDisplay color="text.primary" variant="body1b">
              วันเริ่มต้นใช้งาน
            </TypographyDisplay>
            <Typography color="text.primary" variant="body1">
              {startDate}
            </Typography>
          </BoxDisplay>
        </StyledDisplayBox>
      )}
    </StyledContainer>
  )
}

export default LayoutCertificate

export const CourseContent = () => {
  const [limit, setLimit] = useState(5)
  const courseVersion = useSelector(
    (state) => _.get(state.eCertificateForm, 'courseVersion', []),
    shallowEqual
  )
  return (
    <BoxDisplay>
      <TypographyDisplay color="text.primary" variant="body1b">
        หลักสูตรที่นำไปใช้
      </TypographyDisplay>
      <Box sx={{ width: '55%', display: 'flex', flexDirection: 'column' }}>
        {courseVersion.length > 0 ? (
          <ul style={{ margin: 0, marginLeft: '-20px' }}>
            {courseVersion.slice(0, limit).map((item, index) => (
              <li key={index}>
                <Typography
                  variant="body1"
                  sx={{
                    top: '-2px',
                    position: 'relative',
                    wordWrap: 'break-word',
                  }}
                >
                  {item.englishName}{' '}
                  <Typography variant="span" color="primary">
                    {item.nameForLearner || ''}
                  </Typography>{' '}
                  เวอร์ชั่น {item.version}
                </Typography>
              </li>
            ))}
            {courseVersion.length > 5 && limit == 5 && (
              <StyledBtnMore onClick={() => setLimit(courseVersion.length)}>
                แสดงทั้งหมด <KeyboardArrowDown />
              </StyledBtnMore>
            )}
            {courseVersion.length > 5 && limit == courseVersion.length && (
              <StyledBtnMore onClick={() => setLimit(5)}>
                ซ่อน <KeyboardArrowUp />
              </StyledBtnMore>
            )}
          </ul>
        ) : (
          <Typography color="text.primary" variant="body1">
            -
          </Typography>
        )}
      </Box>
    </BoxDisplay>
  )
}
