import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import PopUpDialog from './../../../../../../components/redux/PopUpDialog'
import { StyledTimerContainer } from './Styled'
import { countdown } from './events'
import PopUpDialog2 from './../../../../../../components/redux/PopUpDialog2'

const Timer = ({ limitTime }) => {
  const { minute } = limitTime
  const hours = Math.floor(minute / 60)
  const minutes = minute % 60
  const seconds = 0
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds])
  const time = { hrs, mins, secs }
  const dispatch = useDispatch()
  let timer = null

  useEffect(() => {
    timer = setInterval(() => dispatch(countdown(time, timer, setTime)), 1000)
    return () => clearInterval(timer)
  })

  const hrStr = hrs.toString().padStart(2, '0')
  const minStr = mins.toString().padStart(2, '0')
  const secStr = secs.toString().padStart(2, '0')

  return (
    <StyledTimerContainer>
      <Typography variant="body1" color="text.white">
        เหลือเวลา
      </Typography>
      <Typography variant="h6" color="text.white">
        {`${hrStr}:${minStr}:${secStr}`}
      </Typography>
      <PopUpDialog />
      <PopUpDialog2 />
    </StyledTimerContainer>
  )
}

export default Timer
