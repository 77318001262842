import { createListenerMiddleware } from '@reduxjs/toolkit'
import { setChangeInformation } from '.'

export const listenerMiddlewareMasterDataRoom = createListenerMiddleware()

listenerMiddlewareMasterDataRoom.startListening({
  actionCreator: setChangeInformation,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action
    const {
      information: { meetingRoomImagesUploadList },
      imagesErrorList,
    } = listenerApi.getState().masterDataMeetingRoomForm

    if (key === 'meetingRoomImagesUploadList') {
      const imageCanUploadList = meetingRoomImagesUploadList
        .filter((img) => !imagesErrorList.includes(img))
        .map((img, idx) => ({
          ...img,
          sequence: idx + 1,
          dragId: `meeting-room-image-${idx + 1}`,
        }))

      listenerApi.dispatch(
        setChangeInformation({
          key: 'imageCanUploadList',
          value: imageCanUploadList,
        }),
      )
    }
  },
})
