import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const tableAttendance = createSlice({
  name: 'tableAttendance',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialTable: (state, { payload }) => {
      state.listTable = payload.listTable
      state.headCells = payload.headCells
    },
    setChange: (state, { payload }) => {
      _.set(state, payload.key, payload.value)
    },
    setChangeExpiredDate: (state, { payload }) => {
      const { indexRound, rowIndex, value } = payload
      _.set(
        state,
        `listTable[${indexRound}].rounds[${rowIndex}].expiredDate`,
        value
      )
    },
    setOnView: (_, { payload }) => {
      window.location.href = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialTable,
  setChange,
  setOnView,
  setChangeExpiredDate,
} = tableAttendance.actions

export default tableAttendance.reducer
