import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Menu from '@mui/material/Menu'

import AddIcon from '@mui/icons-material/Add'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import Close from '@mui/icons-material/Close'

import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'

import {
  hasPermissionDefaultAndMonthlyPlanArea,
  getOptionsKey,
} from '../../../../../utils/lib'
import { StyledBox } from '../Styled'
import { openDialog } from '../../../../../redux/slices/dialog'
import { fetchAdminList } from '../../../../../services/manageClass'
import ViewImage from '../../../../Upload/BasicUpload'
import { onAdminChange } from './event'
import * as Styled from './Styled'

export const ContentDialog = ({ assignee, onClose }) => {
  return (
    <Box sx={{ px: 2, py: 1, width: 220 }}>
      <Styled.BoxHeaderDialog>
        <Typography variant="body1b">แอดมิน</Typography>
        <Close sx={{ cursor: 'pointer' }} color="action" onClick={onClose} />
      </Styled.BoxHeaderDialog>
      {assignee.map((item, index) => {
        return (
          <Styled.BoxImageDialog key={index}>
            <Styled.ImageOne
              src={`${window.__env__.REACT_APP_API_URL}/file${item.image}`}
            />
            <Typography variant="body1">{item.label}</Typography>
          </Styled.BoxImageDialog>
        )
      })}
    </Box>
  )
}

const Action = ({ row, rowIndex }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const table = useSelector((state) => state.table2)
  const [user] = useLocalStorage('user')
  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: row,
  })
  const status = _.get(row, 'status')
  const assignee = _.get(row, 'assignnee', []).map((item) => {
    const firstNameTH = _.get(item, 'firstNameTH', '')
    const lastNameTH = _.get(item, 'lastNameTH', '')
    return {
      ...item,
      label: `${firstNameTH} ${lastNameTH}`,
    }
  })

  return (
    <StyledBox>
      {assignee.length > 0 ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {assignee.map((item, index) => {
              const firstNameTH = _.get(item, 'firstNameTH', '')
              const lastNameTH = _.get(item, 'lastNameTH', '')
              return (
                <>
                  <Styled.TooltipDialog
                    arrow
                    key={index}
                    title={
                      <Styled.LabelTooltip variant="tooltip">
                        {index <= 2
                          ? firstNameTH + ' ' + lastNameTH
                          : `เพิ่มเติม +${assignee.length - 3}`}
                      </Styled.LabelTooltip>
                    }
                    placement="bottom"
                  >
                    <Styled.BoxLeft indexImage={index}>
                      {index <= 2 && (
                        <Styled.ImageOne
                          src={`${window.__env__.REACT_APP_API_URL}/file${item.image}`}
                        />
                      )}
                      {index == 3 && (
                        <Styled.BoxMoreImage
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                          <Styled.LabelMoreImage variant="body2">{`+${
                            assignee.length - 3
                          }`}</Styled.LabelMoreImage>
                        </Styled.BoxMoreImage>
                      )}
                    </Styled.BoxLeft>
                  </Styled.TooltipDialog>
                </>
              )
            })}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ style: { borderRadius: 8 } }}
          >
            <ContentDialog
              assignee={assignee.sort(
                (a, b) => -b.label.localeCompare(a.label),
              )}
              onClose={() => setAnchorEl(null)}
            />
          </Menu>
        </Box>
      ) : (
        <Button
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          startIcon={<AddIcon />}
          size="s"
          variant="contained"
          disabled={
            !hasPermissionEdit ||
            status === 'CANCEL' ||
            status === 'CANCELED' ||
            status === 'CLOSED'
          }
          onClick={() =>
            dispatch(
              openDialog({
                type: 'content',
                title: 'เพิ่มแอดมินหลัก',
                content: <DialogContent row={row} table={table} />,
                maxWidth: 'xs',
                disableButton: true,
              }),
            )
          }
        >
          เลือก
        </Button>
      )}
    </StyledBox>
  )
}

export default Action

export const DialogContent = ({ row, table }) => {
  const dispatch = useDispatch()
  const dialog = useSelector((state) => state.dialog)
  const [adminOptions, setAdminOptions] = useState([])

  useEffect(() => {
    const fetchAdmin = async () => {
      const data = await dispatch(fetchAdminList(row.uuid))
      const listUser = _.get(data, 'payload.user', [])
      const options = getOptionsKey({
        data: listUser,
        key: 'uuid',
        label: 'fullnameTH',
      })
      setAdminOptions(options)
    }

    if (dialog.isOpen) fetchAdmin()
  }, [dialog])

  return (
    <Box>
      <Autocomplete
        sx={{ pb: 3, px: 3 }}
        id="select-admin"
        name="select-admin"
        options={adminOptions.sort((a, b) => -b.label.localeCompare(a.label))}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box {...props} key={option.value} id={option.value}>
            {!_.isNil(option.image) ? (
              <ViewImage
                value={option.image}
                isShowDescription={false}
                isPreview={true}
                isShowTextError={false}
                isStopPropagation={true}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <AccountCircleRoundedIcon sx={{ width: 24, height: 24, mr: 1 }} />
            )}
            <Typography variant="body1" id={option.value}>
              {option.label}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'กรุณาเลือก'}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'select-admin',
            }}
          />
        )}
        onChange={(e, newValue) =>
          dispatch(onAdminChange({ dialog, row, table, newValue }))
        }
      />
    </Box>
  )
}
