import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import Clear from '@mui/icons-material/Clear'
import _ from 'lodash'
import DragAndDrop from '../../modules/ETesting/Preview/Testing/Questions/QuestionCard/Choice/DragAndDrop'
import EditorForDragDrop from '../../components/EditorForDragDrop'
import { alphabets } from '../../constants/eTesting'
import { getPlainTextWithBlank, handleEditorChange } from './events'

const blankComp = (blankNums) =>
  `<span class="blank" contenteditable="false"><em>${alphabets[blankNums]}</em></span>`

const DragAndDropPage = () => {
  const [submitValue, setSubmitValue] = useState('')
  const [value, setValue] = useState('')
  const [blankNums, setBlankNums] = useState(0)
  const [keywords, setKeywords] = useState([])
  const [trickKeywords, setTrickKeywords] = useState([])
  const stateProps = {
    value,
    setValue,
    blankNums,
    setBlankNums,
    keywords,
    setKeywords,
  }

  return (
    <Box
      sx={{
        width: 800,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 2,
        mb: 5,
      }}
    >
      <DragAndDrop
        dialog={submitValue || undefined}
        keywords={
          submitValue
            ? _.concat(
                keywords.map((kw) => kw.value),
                trickKeywords
              )
            : undefined
        }
      />

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          onClick={() => setSubmitValue(getPlainTextWithBlank(value))}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() =>
            window.tinymce.execCommand(
              'mceInsertContent',
              false,
              blankComp(blankNums)
            )
          }
        >
          แทรกช่องว่าง
        </Button>
      </Box>
      <EditorForDragDrop
        value={value}
        onChange={(newValue) => handleEditorChange(newValue, stateProps)}
      />
      <Typography variant="body1b">คำตอบ</Typography>
      {keywords.map((kw, index) => (
        <Box sx={{ display: 'flex', gap: 2 }} key={index}>
          <Typography>{kw.key}</Typography> :
          <input
            key={index}
            value={kw.value}
            onChange={(e) => {
              const newKeywords = [...keywords]
              newKeywords[index].value = e.target.value
              setKeywords(newKeywords)
            }}
          />
          <IconButton
            onClick={() => setValue(value.replace(blankComp(index), ''))}
          >
            <Clear />
          </IconButton>
        </Box>
      ))}

      <Typography variant="body1b">ตัวเลือกหลอก</Typography>
      <Button
        startIcon={<Add />}
        onClick={() => {
          const newKeywords = [...trickKeywords]
          newKeywords.push('')
          setTrickKeywords(newKeywords)
        }}
      >
        เพิ่มตัวเลือกหลอก
      </Button>
      {trickKeywords.map((value, index) => (
        <Box sx={{ display: 'flex', gap: 2 }} key={index}>
          <input
            key={index}
            value={value}
            onChange={(e) => {
              const newKeywords = [...trickKeywords]
              newKeywords[index] = e.target.value
              setTrickKeywords(newKeywords)
            }}
          />
          <IconButton
            onClick={() => {
              const newKeywords = [...trickKeywords]
              newKeywords.splice(index, 1)
              setTrickKeywords(newKeywords)
            }}
          >
            <Clear />
          </IconButton>
        </Box>
      ))}
    </Box>
  )
}

export default DragAndDropPage
