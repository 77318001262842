import React from 'react'
import { Column, Row } from '../../Styled'
import { CustomDropdown } from '../../../../../../../components/CRUD/components/CustomDropdown'
import { StyledDeleteIconBtn } from './Styled'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import { handleDeleteContentItem } from '../../handler/handleDeleteContentItem'
import { handleOptionChoosed } from '../../handler/handleOptionChoosed'
import {
  StyledGroupHeader,
  StyledGroupItems,
} from '../../../../../../../modules/ELearning/LearningPath/Form/LearningPathTab/Course/CourseForm/Styled'

const Course = ({ index }) => {
  return (
    <Column sx={{ gap: 2 }}>
      <Row>
        <CustomDropdown
          required
          masterDataName={'course'}
          fieldName={`contents[${index}].content`}
          placeholder="กรุณากรอก ชื่อหลักสูตร"
          labelName="ชื่อหลักสูตร"
          handleExtraChange={handleOptionChoosed}
          groupBy={(option) => option.type}
          renderGroup={(params) => (
            <li key={params.key}>
              <StyledGroupHeader>{params.group}</StyledGroupHeader>
              <StyledGroupItems>{params.children}</StyledGroupItems>
            </li>
          )}
        />
        <StyledDeleteIconBtn
          onClick={async () => {
            await handleDeleteContentItem(index)
            handleOptionChoosed()
          }}
        >
          <DeleteTwoTone />
        </StyledDeleteIconBtn>
      </Row>
    </Column>
  )
}

export default Course
