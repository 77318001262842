import React from 'react'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import styled from '@mui/system/styled'

Chart.register(CategoryScale)

export const ChartBar = styled((props) => <Bar {...props} />)(
  ({ theme, length }) => ({
    width: 1000,
    height: length,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100% !important',
      height: `${length}px !important`,
      marginLeft: '8px !important',
      marginRight: '8px !important',
    },
  })
)
