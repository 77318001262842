import React, { useState } from 'react'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'

export const handlePreview = (
  e,
  isStopPropagation,
  showPreview,
  setShowPreview
) => {
  if (isStopPropagation) {
    e.stopPropagation()
  }
  setShowPreview(!showPreview)
}

const PreviewImg = (props) => {
  const {
    srcImg,
    setSrc,
    isPreview,
    title,
    style,
    isStopPropagation,
    onDelete,
  } = props

  const [showPreview, setShowPreview] = useState(false)

  return (
    <Box
      sx={{ position: 'relative', justifyCentent: 'center', display: 'flex' }}
    >
      {!isPreview && (
        <CancelUploadButton
          data-testid="cancel-upload"
          onClick={() => {
            setSrc('')
            onDelete()
          }}
        >
          <CloseIcon sx={{ fontSize: '16px' }} />
        </CancelUploadButton>
      )}

      <SmallImg
        style={style}
        src={srcImg}
        data-testid="small-image"
        onClick={(e) =>
          handlePreview(e, isStopPropagation, showPreview, setShowPreview)
        }
      />
      {showPreview && (
        <Dialog
          open={open}
          onClose={(e) =>
            handlePreview(e, isStopPropagation, showPreview, setShowPreview)
          }
          maxWidth="md"
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {title}
            <IconButton
              data-testid="close-preview"
              color="primary"
              onClick={(e) =>
                handlePreview(e, isStopPropagation, showPreview, setShowPreview)
              }
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <BigImg src={srcImg} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

const CancelUploadButton = styled(IconButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: theme?.palette?.error?.main,
  color: theme?.palette?.primary?.contrast,
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  '&:hover': {
    backgroundColor: theme?.palette?.error?.main,
  },
}))

export const SmallImg = styled('img')((style) => ({
  ...style,
  width: '100%',
  height: '100%',
  maxWidth: '120px',
  maxHeight: '120px',
  cursor: 'pointer',
}))

const BigImg = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: '2100px',
  maxHeight: '1600px',
}))

export default PreviewImg
