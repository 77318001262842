import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import {
  StyledBoxButton,
  StyledBoxHeader,
  StyledBoxLabelTitle,
} from '../../HomePage/Header/Styled'
import { handleSubmitData } from './events'

const Header = () => {
  const dispatch = useDispatch()
  const { loginPages, uuid } = useSelector(
    (state) => ({
      loginPages: state.manageContentLogin.loginPages,
      uuid: state.manageContentLogin.uuid,
    }),
    shallowEqual
  )
  return (
    <StyledBoxHeader pb={2} zIndex={100}>
      <StyledBoxLabelTitle>
        <Typography variant="h4">Login</Typography>
      </StyledBoxLabelTitle>
      <StyledBoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          onClick={() => window.open('login/preview', '_blank')}
        >
          <RemoveRedEye sx={{ mr: 1 }} /> ดูตัวอย่าง
        </Button>
        <Button
          data-testid="btn-submit-form"
          type="submit"
          variant="contained"
          onClick={() =>
            dispatch(
              handleSubmitData({
                uuid,
                loginPages,
              })
            )
          }
        >
          บันทึกและเผยแพร่
        </Button>
      </StyledBoxButton>
    </StyledBoxHeader>
  )
}
export default Header
