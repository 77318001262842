import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import InsertInvitation from '@mui/icons-material/InsertInvitation'
import Select from '../../../../../components/Input/Select'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import TextInput from '../../../../../components/Input/TextInput'
import { learnerTypeOption } from '../model'
import { StyledFormContainer } from '../Styled'
import { StyledDateInput } from './Styled'
import {
  fetchUserOption,
  handleDefaultBirthDay,
  handleDefaultPhoneNumber,
  handleDefaultUser,
  handleInputBlur,
  handleInputChange,
  handleInputFormat,
  handleOptions,
  handlePasteFormat,
  handleSelectChange,
} from './events'

const ManualForm = () => {
  const learnerData = useSelector(
    (state) => ({
      isAgentLoading: state.manageClassLearner.isAgentLoading,
      userType: state.manageClassLearner.userType,
      userProfileList: state.manageClassLearner.userProfileList,
      userAgentList: state.manageClassLearner.userAgentList,
      userProfile: state.manageClassLearner.userProfile,
      staffProfile: state.manageClassLearner.staffProfile,
    }),
    shallowEqual
  )
  const { userType, userProfile, staffProfile, isAgentLoading } = learnerData
  const dispatch = useDispatch()
  const userProfileOption = useMemo(
    () => _.uniqBy(handleOptions(learnerData), 'value'),
    [learnerData]
  )

  const userSelected = useMemo(
    () => (_.isEmpty(userProfile) ? staffProfile : userProfile),
    [userProfile, staffProfile]
  )

  const defaultUser = useMemo(
    () => handleDefaultUser(userSelected, userType)[userProfile]
  )

  const defaultBirthDate = useMemo(
    () => handleDefaultBirthDay(userSelected, userType),
    [userSelected, userType]
  )

  const defaultPhoneNumber = useMemo(
    () => handleDefaultPhoneNumber(userSelected, userType),
    [userSelected, userType]
  )

  const handleChangeDebounce = useMemo(
    () =>
      _.debounce(
        (e, userType) => dispatch(handleInputChange(e, userType)),
        800
      ),
    []
  )

  return (
    <StyledFormContainer>
      <Select
        dataTestid="select-learnerType"
        labelText="ประเภทของผู้เรียน"
        placeholder="เลือกประเภทผู้เรียน"
        type="basic"
        showTextError={false}
        required
        options={learnerTypeOption}
        value={userType}
        handleChange={(e) => dispatch(fetchUserOption(e.target.value))}
      />
      <Autocomplete
        name="code"
        type="basic"
        showTextError={false}
        required
        labelText="Code 6 หลัก"
        options={userProfileOption}
        defaultValue={defaultUser}
        isLoading={isAgentLoading}
        renderInput={(params) => (
          <TextField
            placeholder="กรอกรหัส"
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'select-code',
              onInput: (e) => handleInputFormat(e, userType),
              onPaste: (e) => handlePasteFormat(e, userType),
            }}
            onChange={(e) => handleChangeDebounce(e, userType)}
            onBlur={() => dispatch(handleInputBlur(userProfile))}
          />
        )}
        handleChange={(e, element) =>
          dispatch(handleSelectChange(element, userType))
        }
      />
      <TextInput
        disabled
        isShowTextError={false}
        type="basic"
        labelText="เลขบัตรประชาชน"
        value={_.get(userSelected, '[0].idCardNo', '') || ''}
      />
      <Box sx={{ width: '100%', display: 'flex', gap: 2.5 }}>
        <StyledDateInput>
          <TextInput
            required
            disabled
            isShowTextError={false}
            type="basic"
            labelText="วัน เดือน ปี เกิด"
            value={defaultBirthDate}
          />
          <InsertInvitation />
        </StyledDateInput>
        <TextInput
          required
          disabled
          isShowTextError={false}
          type="basic"
          labelText="เบอร์โทรศัพท์"
          value={defaultPhoneNumber}
        />
      </Box>
      <TextInput
        required
        disabled
        isShowTextError={false}
        type="basic"
        labelText="อีเมล"
        value={_.get(userSelected, '[0].email', '')}
      />
    </StyledFormContainer>
  )
}

export default ManualForm
