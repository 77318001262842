import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'

import Content from '../Content'

import { draftSubQuestions } from '../../../../../../../redux/slices/eEvaluationForm/feedback'

const SubQuestions = ({ sequence, itemSubQuestions }) => {
  const nextSubQuestions = useSelector(
    (state) =>
      state.eEvaluationFeedback.nextQuestions.subQuestions.find(
        (item) => item.sequence === sequence
      ),
    shallowEqual
  )
  const sequenceSubQuestions = _.get(itemSubQuestions, 'sequence', 1)
  const nextQuestions = _.get(nextSubQuestions, 'value', []).find(
    (item) => item.sequence === sequenceSubQuestions
  )

  const propsEvent = {
    type: 'subQuestions',
    sequence,
    itemContent: itemSubQuestions,
    nextQuestions: _.get(nextQuestions, 'value', 1),
  }

  return <Content props={propsEvent} />
}

const Index = ({ sequence }) => {
  const listSubQuestion = useSelector(draftSubQuestions(sequence), shallowEqual)

  return listSubQuestion && listSubQuestion.length > 0 ? (
    <>
      {listSubQuestion.map((item, index) => (
        <>
          <SubQuestions
            id={`sub-questions-${index}`}
            key={index}
            sequence={sequence}
            itemSubQuestions={item}
          />
        </>
      ))}
    </>
  ) : (
    <></>
  )
}

export default Index
