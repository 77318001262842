import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import {
  Add,
  DeleteTwoTone,
  // RefreshTwoTone,
  //  Launch
} from '@mui/icons-material'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { StyledColumn, StyledRow } from '../Styled'
import {
  fetchEquipmentList,
  handleAddEquipmentList,
  handleChangeEquipmentList,
  // handleClearEquipment,
  handleDeleteEquipment,
  handleEquipmentSelected,
} from './event'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'

const Index = () => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [equipmentList, setEquipmentList] = useState([])
  const { errors } = useFormikContext()
  const { meetingRoomEquipment, equipmentSelected } = useSelector(
    (state) => ({
      meetingRoomEquipment:
        state.masterDataMeetingRoomForm.information.meetingRoomEquipment,
      equipmentSelected: state.masterDataMeetingRoomForm.equipmentSelected,
    }),
    shallowEqual,
  )
  const isNotSm = isMediaWidthMoreThan('sm')
  const addEquipmentProps = {
    meetingRoomEquipment: meetingRoomEquipment,
    meetingRoomId: uuid,
  }
  const canAddEquipment = meetingRoomEquipment?.length < equipmentList?.length
  useEffect(() => {
    fetchEquipmentList(setEquipmentList)
  }, [])

  useEffect(() => {
    dispatch(handleEquipmentSelected(equipmentList, meetingRoomEquipment))
  }, [equipmentList, meetingRoomEquipment])

  return (
    <StyledColumn sx={{ my: theme.spacing(3), width: '100%' }}>
      <Typography sx={{ mb: theme.spacing(3) }} variant="body2b">
        อุปกรณ์ที่มาพร้อมกับห้อง
      </Typography>
      {meetingRoomEquipment?.length > 0 &&
        meetingRoomEquipment?.map((item, idx) => {
          const { id, equipment, quantity } = item
          const changeEquipmentProps = {
            id: id,
            meetingRoomEquipment: meetingRoomEquipment,
            meetingRoomId: uuid,
          }
          return (
            <StyledRow
              sx={{
                width: '100%',
                gap: 3,
                mb: 3,
              }}
              key={idx}
              isMobile={!isNotSm}
            >
              <StyledRow sx={{ gap: theme.spacing(1) }}>
                <Select
                  dataTestid="select-equipment"
                  id="equipment"
                  name="equipment"
                  labelText="ชื่ออุปกรณ์"
                  required
                  placeholder="กรุณาเลือก"
                  textErrorSx={{ mb: 0 }}
                  options={equipmentList}
                  isSelected={equipmentSelected}
                  value={equipment}
                  handleChange={(e) => {
                    const value = _.get(e, 'target.value', '')
                    dispatch(
                      handleChangeEquipmentList({
                        ...changeEquipmentProps,
                        key: 'equipment',
                        value: value,
                        equipmentSelected,
                      }),
                    )
                  }}
                  textError={_.get(
                    errors,
                    `information.meetingRoomEquipment[${idx}].equipment`,
                    '',
                  )}
                />
                {/* <StyledRow
                  sx={{
                    justifyContent: 'flex-end',
                    width: 'auto',
                    px: theme.spacing(1),
                    py: theme.spacing(1.25),
                    height: 56,
                    my: 'auto',
                  }}
                >
                  <IconButton
                    sx={{ px: 0 }}
                    data-testid="btn-refresh"
                    onClick={() => dispatch(handleClearEquipment(idx))}
                  >
                    <RefreshTwoTone color="primary" />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} data-testid="btn-launch">
                    <Launch color="primary" />
                  </IconButton>
                </StyledRow> */}
              </StyledRow>
              <StyledRow>
                <TextInput
                  required
                  type="basic"
                  name="quantity"
                  labelText={'จำนวน'}
                  placeholder="กรุณากรอก"
                  value={quantity}
                  inputProps={{ type: 'number' }}
                  onChange={(e) => {
                    let inputValue = _.get(e, 'target.value', null)
                    if (!isNaN(inputValue) && !_.isEmpty(inputValue)) {
                      inputValue = Number(inputValue)
                      if (inputValue <= 0) {
                        inputValue = 1
                      }
                    }
                    dispatch(
                      handleChangeEquipmentList({
                        ...changeEquipmentProps,
                        key: 'quantity',
                        value: inputValue,
                      }),
                    )
                  }}
                  textError={_.get(
                    errors,
                    `information.meetingRoomEquipment[${idx}].quantity`,
                    '',
                  )}
                />
                <Box
                  sx={{
                    display: 'flex',
                    gap: theme.spacing(2),
                    alignItems: 'center',
                  }}
                >
                  <Divider
                    sx={{
                      maxHeight: '48px',
                      mt: theme.spacing(2.25),
                    }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <IconButton
                    sx={{
                      marginLeft: 'auto',
                      height: 56,
                      p: theme.spacing(1),
                    }}
                    data-testid="btn-delete"
                    onClick={() =>
                      dispatch(
                        handleDeleteEquipment(
                          meetingRoomEquipment,
                          id,
                          equipment,
                          equipmentSelected,
                        ),
                      )
                    }
                  >
                    <DeleteTwoTone />
                  </IconButton>
                </Box>
              </StyledRow>
            </StyledRow>
          )
        })}
      {canAddEquipment && (
        <Button
          sx={{
            width: 122,
            padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
          }}
          variant="outlined"
          size="s"
          startIcon={<Add />}
          onClick={() => dispatch(handleAddEquipmentList(addEquipmentProps))}
        >
          อุปกรณ์ในห้อง
        </Button>
      )}
    </StyledColumn>
  )
}

export default Index
