import { Box, Typography } from '@mui/material'

export const LabelBox = ({
  index,
  toggleDatasetVisibility,
  backgroundColor,
  borderColor,
  label,
  isHidden,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        maxWidth: 100,
        cursor: 'pointer',
        textDecoration: isHidden ? 'line-through' : 'none',
        textDecorationColor: 'lightGray',
      }}
      onClick={() => toggleDatasetVisibility(index)}
    >
      <Box
        sx={{
          width: 12,
          height: 12,
          backgroundColor: backgroundColor,
          border: `1px solid ${borderColor}`,
          borderRadius: '50%',
        }}
      />
      <Typography
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: 17,
        }}
        color={isHidden ? 'lightGray' : 'text.primary'}
      >
        {label.slice(0, 15)}
      </Typography>
    </Box>
  )
}
