import {
  memo, //useState
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../../../../../components/Input/Autocomplete'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Chip, Typography } from '@mui/material'
import { setHandleChange } from '../../../../../../redux/slices/table'

const CustomDropdown = memo(
  ({
    required,
    disabled,
    fieldName,
    labelName,
    handleExtraChange,
    boxSx = {},
    errorSx = { mb: 0 },
    sx = { width: '100%' },
    placeholder = 'กรุณาเลือก',
    isCustomOption = false,
    customOptions = { isLoading: false, options: [] },
    multiple,
    disableCloseOnSelect = false,
    addAllOption = false,
    groupBy,
    renderGroup,
    renderOption,
    noOptionsText,
    onFocus,
    message,
    isError,
    disableClearable = false,
    isReadOnly = false,
  }) => {
    const dispatch = useDispatch()
    // const [inputValue, setInputValue] = useState('')
    const { filterState } = useSelector(
      (state) => ({ filterState: state.table.filterState }),
      shallowEqual,
    )
    const value = filterState[fieldName]

    const { crudConfig, filterList } = useSelector(
      (state) => ({
        crudConfig: state.crud.crudConfig,
        filterList: state.crud.filterList,
      }),
      shallowEqual,
    )

    function handleValueChange(event, value) {
      dispatch(
        setHandleChange({
          key: `filterState.${fieldName}`,
          value: value?.value,
        }),
      )
      if (handleExtraChange) {
        handleExtraChange(event, value)
      }
    }

    const getDefaultValue = () => {
      if (multiple) {
        return []
      }
      return ''
    }

    const getOptions = () => {
      let options = [
        ...(isCustomOption
          ? customOptions.options
          : filterList?.[fieldName] ?? []),
      ]
      if (addAllOption) {
        options.unshift({ label: 'ทั้งหมด', value: 'all' })
      }

      if (fieldName === 'courseCode') {
        const newOptions = []
        for (const data of options) {
          if (data.type === 'Classroom') {
            const codePosition = data?.value?.split('-', 1).join('-').length
            const classType = data?.value?.substring(
              codePosition + 1,
              codePosition + 3,
            )
            switch (classType) {
              case 'CR':
                newOptions.push({ ...data, type: 'Classroom' })
                break
              case 'VC':
                newOptions.push({ ...data, type: 'Virtual Classroom' })
                break
              case 'HB':
                newOptions.push({ ...data, type: 'Hybrid Class' })
                break
              default:
                break
            }
          } else {
            newOptions.push(data)
          }
        }

        options = newOptions
      }

      if (groupBy) {
        options = options.sort((a, b) => -b.type.localeCompare(a.type))
      }

      return options
    }
    return (
      <Autocomplete
        boxSx={{ ...boxSx }}
        disabled={disabled}
        loading={isCustomOption ? '' : filterList?.isLoading}
        required={required}
        isHideSort={true}
        labelText={labelName}
        name={fieldName}
        defaultValue={
          _.isEmpty(value) || _.isNil(value) ? getDefaultValue() : value
        }
        filterSelectedOptions={true}
        disableCloseOnSelect={disableCloseOnSelect}
        isOptionEqualToValue={(option, selected) => {
          return option.label === selected.label
        }}
        id={`${crudConfig.moduleSlug}-${fieldName}`}
        options={getOptions()}
        sx={sx}
        errorSx={errorSx}
        textError={message}
        showTextError={isError}
        renderInput={(params) => {
          const label = _.find(getOptions(), function (data) {
            return data.value === value
          })?.label
          let val = params.inputProps?.value

          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                value: label ?? val,
                readOnly: isReadOnly,
                placeholder: _.isEmpty(val) || _.isNil(val) ? placeholder : '',
                style: {
                  paddingLeft: '8px',
                },
              }}
            />
          )
        }}
        handleChange={handleValueChange}
        multiple={multiple}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={<Typography sx={{}}>{option.label}</Typography>}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#FFFFFF',
                border: 1,
                borderColor: 'text.disabled',
              }}
              key={index}
            />
          ))
        }
        groupBy={groupBy}
        renderGroup={renderGroup}
        renderOption={renderOption}
        noOptionsText={noOptionsText}
        onFocus={onFocus}
        disableClearable={disableClearable}
      />
    )
  },
)

CustomDropdown.displayName = 'CustomDropdownComponent'

export { CustomDropdown }
