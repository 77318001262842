import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import _ from 'lodash'
import CourseDrawer from './CourseDrawer'
import { prospectProfileCourse } from '../../../../../constants/manage/prospectProfile'
import SearchCustom from '../../../Search'
import {
  startLoading,
  stopLoading,
  setPagination,
  setSearch,
} from '../../../../../redux/slices/manage/prospectProfile'
import { useParams } from 'react-router-dom'
import { onSort } from '../../../../../utils/lib'
import { fetchCourse } from '../../../View/Content/Course/events'

const CourseSection = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const [head, setHead] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { search, pagination, data } = useSelector(
    (state) => ({
      search: state.prospectProfile.search,
      pagination: state.prospectProfile.pagination,
      data: state.prospectProfile.initialState,
    }),
    shallowEqual
  )

  useEffect(() => {
    for (const prospectProfile of prospectProfileCourse) {
      delete prospectProfile['order']
    }
    setHead(prospectProfileCourse)
  }, [])

  const {
    courseSearch,
    examSearch,
    handleCourseSearch,
    orderCourseBy,
    sortCourseBy,
  } = search

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(
      setSearch({
        key: 'orderCourseBy',
        value: orderBy,
      })
    )
    dispatch(
      setSearch({
        key: 'sortCourseBy',
        value: sort,
      })
    )
    dispatch(
      fetchCourse({
        ...pagination,
        prospectUuid: uuid,
        order: orderBy,
        sort: sort,
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
      })
    )
    setHead(head)
  }

  useEffect(() => {
    dispatch(
      fetchCourse({
        ...pagination,
        prospectUuid: uuid,
        order: orderCourseBy ?? 'DESC',
        sort: sortCourseBy ?? 'updatedAt',
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
      })
    )
  }, [handleCourseSearch, pagination])

  return (
    <>
      <Card sx={{ pb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Typography variant="h6">ประวัติการเรียน</Typography>
            <SearchCustom searchKey="courseSearch" />
          </Box>
          <Button variant="outlined" size="m" onClick={() => setIsOpen(true)}>
            <HistoryTwoTone color="primary.main" />
          </Button>
        </Box>
        <NormalTable
          headCells={head}
          listRows={_.get(data, 'course.result', [])}
          totalRows={_.get(data, 'course.totalCount', 0)}
          showPagination
          isHiddenOptions
          startLoading={startLoading}
          stopLoading={stopLoading}
          onRequestSort={onRequestSort}
          handlePagination={(events) =>
            dispatch(
              setPagination({
                key: 'coursePage',
                value: events.page,
              })
            )
          }
        />
      </Card>
      <CourseDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </>
  )
}

export default CourseSection
