import React from 'react'
import Info from './Info'
import Permission from './Permission'
import Content from './Content'
import ContentType from '../../components/contentType'

const ContentTab = () => {
  return (
    <>
      <Info />
      <Permission />
      <ContentType />
      <Content />
    </>
  )
}
export default ContentTab
