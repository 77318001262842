import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'

const prospectProfile = createSlice({
  name: 'prospectProfile',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoading2: (state) => {
      state.isLoading2 = true
    },
    stopLoading2: (state) => {
      state.isLoading2 = false
    },
    startLoading3: (state) => {
      state.isLoading3 = true
    },
    stopLoading3: (state) => {
      state.isLoading3 = false
    },
    setFetchData: (state, { payload }) => {
      state.initialState = payload
    },
    setPayloadKey: (state, { payload }) => {
      state.initialState[payload?.key] = payload?.data
    },
    setChangeInformation: (state, { payload }) => {
      const { key, value } = payload
      state.initialState.info[key] = value
    },
    setChangeInformation2: (state, { payload }) => {
      const { key, value } = payload
      state.initialState.info2[key] = value
    },
    setChangeContact: (state, { payload }) => {
      const { key, value } = payload
      state.initialState.contact[key] = value
    },
    setChangeExperience: (state, { payload }) => {
      const { value, where } = payload
      if (where === 'institution') {
        state.initialState.institution = value
      } else if (where === 'gpa') {
        state.initialState.gpa = value
      } else if (where === 'between') {
        state.initialState.between = value
      } else if (where === 'branch') {
        state.initialState.branch = value
      } else if (where === 'qualification') {
        state.initialState.qualification = value
      }
    },
    setAddExperience: (state, { payload }) => {
      state.initialState.educational.push(payload)
    },
    setDeleteExperience: (state, { payload }) => {
      const educationalConfig = [...state.initialState.educational]
      educationalConfig.splice(payload, 1)
      _.set(state, 'initialState.educational', educationalConfig)
    },
    setProfileImage: (state, { payload }) => {
      state.initialState.info.image = payload
    },
    setDivision: (state, { payload }) => {
      state.initialState.division = payload
    },
    setSearch: (state, { payload }) => {
      const { key, value } = payload
      state.search[key] = value
    },
    setClearSearch: (state) => {
      state.search = {
        courseSearch: '',
        examSearch: '',
        handleCourseSearch: '',
        handleExamSearch: '',
      }
    },
    handleSearch: (state, { payload }) => {
      const { key, value } = payload
      state.search[key] = value
    },
    setPagination: (state, { payload }) => {
      const { key, value } = payload
      state.pagination[key] = value
    },
    setErrors: (state, { payload }) => {
      state.errors = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoading2,
  stopLoading2,
  startLoading3,
  stopLoading3,
  setFetchData,
  setChangeInformation,
  setChangeInformation2,
  setChangeContact,
  setChangeExperience,
  setAddExperience,
  setDeleteExperience,
  setProfileImage,
  setSearch,
  setClearSearch,
  handleSearch,
  setDivision,
  setPagination,
  setPayloadKey,
  setErrors,
} = prospectProfile.actions

export default prospectProfile.reducer
