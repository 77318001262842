import {
  setChangeInformation,
  setPayloadKey,
  startLoading3,
  stopLoading3,
} from '../../../../../redux/slices/manage/agentProfile'
import axios from 'axios'
import { agentProfileCourseView } from '../../../../../utils/apiPath'
export const handleSwitch = (props) => (dispatch) => {
  dispatch(
    setChangeInformation({
      key: 'isSwitch',
      value: props.isSwitch,
    })
  )
  dispatch(fetchCourse(props))
}

export const fetchCourse = (body) => async (dispatch) => {
  dispatch(startLoading3())
  const response = await axios.post(agentProfileCourseView, body)
  dispatch(setPayloadKey({ key: 'course', data: response?.data?.course }))
  dispatch(stopLoading3())
}
