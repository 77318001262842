import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import History from '../History'

const DropdownButton = ({ id, isStandalone }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setOpen] = useState(false)
  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid={`btn-${id}`}
        id={id}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': id }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          data-testid={`menu-history`}
          onClick={() => {
            setOpen(true)
            setAnchorEl(null)
          }}
        >
          ประวัติการแก้ไข
        </MenuItem>
      </Menu>
      <History
        isStandalone={isStandalone}
        isOpen={isOpen}
        onCloseDrawer={() => setOpen(false)}
      />
    </>
  )
}

export default DropdownButton
