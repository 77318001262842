import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DatePicker from '../../../../../../components/Input/DatePicker'
import { isValidateDate } from '../../../../../../utils/lib'
import _ from 'lodash'
import {
  errorSelector,
  removeValidateError,
  setChangeBookingInformation,
  setValidateError,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import dayjs from 'dayjs'

function handleChangeDate(value, dispatch, field) {
  const dateValue = !_.isNull(value) ? dayjs(value).format('YYYY-MM-DD') : ''
  if (!isValidateDate(dayjs(value))) {
    dispatch(setValidateError({ key: field, message: 'วันที่ไม่ถูกต้อง' }))
  } else {
    dispatch(setChangeBookingInformation({ key: field, value: dateValue }))
    dispatch(removeValidateError({ key: field, message: 'วันที่ไม่ถูกต้อง' }))
  }
}

export const BookingDate = ({ field }) => {
  const dispatch = useDispatch()
  const { value, requestType } = useSelector(
    (state) => ({
      value: state.roomManagementDrawer.bookingInformation[field],
      requestType: state.roomManagementDrawer.bookingInformation.requestType,
    }),
    shallowEqual,
  )
  const error = useSelector(errorSelector(field), shallowEqual)

  return (
    <DatePicker
      sxTextError={{ mb: 0 }}
      id={field}
      name={field}
      labelText={field === 'startDate' ? 'วันที่เริ่ม' : 'วันที่สิ้นสุด'}
      required
      disabledInput={requestType === 'MONTHLY_PLAN'}
      placeholder={
        field === 'startDate'
          ? 'กรุณาเลือกวันที่เริ่ม'
          : 'กรุณาเลือกวันที่สิ้นสุด'
      }
      onChange={(value) => {
        handleChangeDate(value, dispatch, field)
      }}
      value={isValidateDate(value) ? value : ''}
      disablePast={true}
      disabledDateRange={true}
      hideTextError={_.isEmpty(error)}
      textError={_.get(error, `message[0]`, '')}
    />
  )
}
