import { Box } from '@mui/material'
import { useTheme } from '@mui/system'
import { useSelector, shallowEqual } from 'react-redux'
import TableSection from './components/TableSection'
import ExpandableHeader from './components/ExpandableHeader'
import SearchAndDownloadToolbar from './components/SearchAndDownloadToolbar'

const DetailsTable = () => {
  const theme = useTheme()
  const { isExpand } = useSelector(
    (state) => ({
      isExpand: state.monthlyPlan.dashboard.isExpand,
    }),
    shallowEqual,
  )

  return (
    <Box
      sx={{
        borderRadius: 2,
        border: `1px solid ${theme.palette.blue.blueLine}`,
      }}
    >
      <ExpandableHeader />
      {isExpand && (
        <>
          <SearchAndDownloadToolbar />
          <TableSection />
        </>
      )}
    </Box>
  )
}
export default DetailsTable
