export const filterTestResultItems = [
  {
    label: 'ผ่าน',
    name: 'passChecked',
  },
  {
    label: 'ไม่ผ่าน',
    name: 'failChecked',
  },
  {
    label: 'ไม่ทำ',
    name: 'waitingChecked',
  },
]

export const filterItems = [
  {
    label: `วันที่อบรม`,
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'startDateDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `วันที่ทำแบบทดสอบ`,
    nameCheckBox: 'startTestDateChecked',
    nameInput: 'startTestDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'startTestDate',
    dateStateFinish: 'endTestDate',
    dateStateDisplay: 'startTestDateDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `Agent Code`,
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: `เลือก Agent Code`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
  },
  {
    label: `Unit Code`,
    nameCheckBox: 'unitCodeChecked',
    nameInput: 'unitCode',
    placeholder: `เลือก Unit Code`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
  },
  {
    label: `SRAM`,
    nameCheckBox: 'sRamChecked',
    nameInput: 'sRam',
    placeholder: `เลือก SRAM`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
  },
  {
    label: `RAM`,
    nameCheckBox: 'ramCodeChecked',
    nameInput: 'ramCode',
    placeholder: `เลือก RAM`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
  },
  {
    label: 'ผลการสอบ',
    nameCheckBox: 'testResultChecked',
    nameInput: 'testResult',
    type: 'checkbox',
    list: filterTestResultItems,
  },
  {
    label: `ช่วงคะแนน`,
    nameCheckBox: 'scoreChecked',
    nameInput: 'score',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'scoreFrom',
    inputTo: 'scoreTo',
    type: 'number_between',
  },
  {
    label: `ช่องทางการจัดจำหน่ายของผู้เรียน`,
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    placeholder: `เลือก ช่องทางการจัดจำหน่ายของผู้เรียน`,
    type: 'select_dropdown',
    fetchOptions: 'masterData',
  },
]
