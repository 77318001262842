import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import { StyledCardDrawer } from '../../Styled'
import FilterListItem from './FilterListItem'

const FilterListCard = () => {
  const { displayFilters } = useSelector(
    (state) => ({
      displayFilters: state.reportComp.displayFilters,
    }),
    shallowEqual,
  )
  return (
    <StyledCardDrawer>
      <Typography variant="h6">รายการ</Typography>
      <List>
        {_.map(displayFilters, (filter, ind) => (
          <FilterListItem key={ind} filter={filter} />
        ))}
      </List>
    </StyledCardDrawer>
  )
}

export default FilterListCard
