import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { StyledCardContent } from '../../../../../components/View/Styled'
import {
  ContentRow,
  CoverImageContent,
  StatusContent,
} from '../../../../../components/View'
import PreviewVideoContent from '../../../../../components/PreviewVideoContent'

const Detail = () => {
  const detail = useSelector((state) => state.eLearningPathDetail.result)
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        รายละเอียด Learning path
      </Typography>
      <ContentRow
        title="รหัส Learning path"
        content={_.get(detail, 'id', '-')}
      />
      <ContentRow
        title="ชื่อ Learning path"
        content={_.get(detail, 'name', '-')}
      />
      <ContentRow
        title="ชื่อบน Banner"
        content={_.get(detail, 'nameForBanner', '-') || '-'}
      />
      <ContentRow
        title="รายละเอียด Banner"
        content={_.get(detail, 'detailForBanner', '-') || '-'}
      />
      <ContentRow
        title="รายละเอียด Learning path"
        content={_.get(detail, 'description', '-') || '-'}
      />

      <CoverImageContent detail={detail} />

      <PreviewVideoContent url={_.get(detail, 'linkExampleVideo', '')} />

      <ContentRow
        title="Remark"
        content={_.get(detail, 'remark', '-') || '-'}
      />

      <StatusContent status={_.get(detail, 'status', false)} />
    </StyledCardContent>
  )
}

export default Detail
