import { Grid } from '@mui/material'

export const ContentRow = ({ columnLeft, columnRight }) => {
  return (
    <Grid container spacing={3} columns={16}>
      <Grid item xs={7}>
        {columnLeft}
      </Grid>
      <Grid item xs={9}>
        {columnRight}
      </Grid>
    </Grid>
  )
}
