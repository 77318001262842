import React from 'react'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { path } from './../../../../../../constants/path'
import { setStopModule } from '../../../../../../redux/slices/eLearning/preview'
import { responsiveButton } from '../Styled'

const BackToMainBtn = ({ isModule }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const url = `${path.eTesting}/preview/${uuid}`
  const buttonSize = responsiveButton()
  return (
    <Button
      variant="contained"
      size={buttonSize}
      data-testid="btn-back-main"
      onClick={() =>
        isModule ? dispatch(setStopModule()) : (window.location.href = url)
      }
    >
      กลับสู่หน้าหลัก
    </Button>
  )
}

export default BackToMainBtn
