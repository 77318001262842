import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  COURSE_TYPE,
  EXPIRATION_TYPE,
  TIME_SPAN_TYPE,
} from '../../../../../../../constants/eLearning'
import {
  convertFormatTime,
  isMediaWidthMoreThan,
} from '../../../../../../../utils/lib'
import { Column } from '../../../Styled'
import { AssigneeContent, DetailCard } from '../../../components'
import {
  StyledAssigneeWrapper,
  StyledContainer,
} from '../../../components/Styled'
import { handleModuleETesting } from './events'

const RightContent = () => {
  const data = useSelector(
    (state) => state.eLearningPathPreview.course,
    shallowEqual,
  )
  const [isExpanded, setExpand] = useState(false)
  const assignees = _.get(data, 'setting.assignees', []).filter(
    (item) => item.type === 'SPEAKER',
  )
  const displayAssignees = useMemo(
    () => (isExpanded ? assignees : _.filter(assignees, (e, i) => i < 2)),
    [assignees, isExpanded],
  )
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const width = { lg: 468, md: '100%' }
  const fontSize = isLg ? 'h6' : isNotSm ? 'h5' : 'body1b'

  return (
    <Column sx={{ minWidth: width, width }}>
      <BoxContents data={data} />

      {assignees.length > 0 && (
        <Column sx={{ gap: 1, mb: { lg: 4, md: 4, sm: 2, xs: 2 } }}>
          <Typography variant={fontSize} color="primary">
            วิทยากรบรรยาย
          </Typography>

          <StyledAssigneeWrapper>
            {displayAssignees.map((assignee, index) => (
              <AssigneeContent key={index} data={assignee} />
            ))}
          </StyledAssigneeWrapper>

          {assignees.length > 3 && (
            <Box m="auto">
              <Button
                size={isNotSm ? 'm' : 's'}
                variant="outlined"
                onClick={() => setExpand(!isExpanded)}
              >
                {isExpanded ? 'ซ่อนวิทยากรบรรยาย' : 'ดูวิทยากรบรรยายเพิ่มเติม'}
              </Button>
            </Box>
          )}
        </Column>
      )}
    </Column>
  )
}

export default RightContent

export const BoxContents = ({ data }) => {
  const setting = _.get(data, 'setting', {})
  const sellCourse = _.get(setting, 'sellCourse', {})
  const time = convertFormatTime({
    hours: _.get(data, 'totalHour', 0),
    minutes: _.get(data, 'totalMinute', 0),
  })
  const lessons = _.get(data, 'eLearningCourseLesson', [])
  const eTesting = handleModuleETesting(lessons)
  const courseAccessExpiration = _.get(data, 'courseAccessExpiration', '')
  const courseType = _.get(data, 'courseType', '')
  const assignees = _.get(data, 'setting.assignees', [])
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledContainer
      isOnly={courseType !== COURSE_TYPE.OIC && assignees.length === 0}
    >
      {sellCourse.isSell && (
        <DetailCard
          fullWidth
          icon="ic_banknotes.svg"
          sx={{ minHeight: isNotSm ? 64 : 52 }}
        >
          ค่าใช้จ่าย{' '}
          <Typography
            variant="span"
            color="primary"
            sx={{ fontSize: '28px', fontWeight: 700 }}
          >
            {Number(
              `${_.get(sellCourse, 'cost', 0)}`.replace(',', ''),
            ).toLocaleString()}
          </Typography>{' '}
          บาท
        </DetailCard>
      )}
      <DetailCard icon="ic_timer.svg">
        ความยาวทั้งหมด
        <br />
        {time}
      </DetailCard>
      <DetailCard icon="ic_book_open.svg">
        เนื้อหาทั้งหมด
        <br />
        {lessons.length} บทเรียน
      </DetailCard>
      {eTesting.length > 0 && (
        <DetailCard icon="ic_list.svg">
          แบบฝึกหัดทั้งหมด
          <br />
          {eTesting.length} แบบฝึกหัด
        </DetailCard>
      )}
      {courseAccessExpiration === EXPIRATION_TYPE.TIME_SPAN && (
        <DetailCard
          id="timeSpan"
          icon="ic_screen_time.svg"
          sx={{ px: 1.5, gap: '14px' }}
        >
          เรียนให้จบภายใน {_.get(data, 'timeSpan', 0)}{' '}
          {TIME_SPAN_TYPE[_.get(data, 'timeSpanType', '')]}
          <br />
          <Typography variant="span" color="secondary">
            นับจากวันที่เริ่มอบรม
          </Typography>
        </DetailCard>
      )}
      {data.hasCertificate && (
        <DetailCard icon="ic_cert.svg">ได้รับประกาศนียบัตร</DetailCard>
      )}
    </StyledContainer>
  )
}
