import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  BoxLabelText,
} from '../Styled'
import { breadcrumbExaminerDetail } from '../model'
import { fetchExaminerDetail } from './event'
import _ from 'lodash'
import {
  STUDENT_STATUS_TEXT,
  STUDENT_STATUS_DOT_COLOR_TEXT,
  EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS,
} from '../../../constants/eExamination'
import Dot from '../../../components/redux/Dot'
import {
  convertFormatDateTime,
  getFormatDate,
  formatPhoneNo,
  formatIdCard,
} from '../../../utils/lib'
import { ButtonAction } from '../../../modules/ManageClass/Learner/Document/Content/ContentTableLeft'
import { TEST_RESULT_STATUS_TEXT } from '../../../constants/eExamination'
import { getOtherDocConfig } from '../ExaminerList/event'

export const BoxLabel = ({ labelLeft, labelRight, isIcon }) => {
  return (
    <BoxLabelText>
      <Box sx={{ width: '25%' }}>
        <Typography variant="body1">{labelLeft}</Typography>
      </Box>
      {isIcon ? (
        labelRight
      ) : (
        <Typography variant="body1">{labelRight}</Typography>
      )}
    </BoxLabelText>
  )
}

export const OtherDocumentsBox = ({ otherDocuments, otherDocConfig }) => {
  const previewButtons = otherDocuments.map((item, index) => {
    let url = item?.cardImage ?? ''
    let imageName = item?.imageName ?? ''
    const label = otherDocConfig?.config?.label ?? 'เอกสารเพิ่มเติม'
    if (!item?.imageName) {
      url = item
      imageName = label
    }
    return (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1">{imageName}</Typography>
        <ButtonAction
          indexDoc={0}
          title={`${label}`}
          titleDownload={`${
            imageName.substring(0, imageName.lastIndexOf('.')) || imageName
          }`}
          url={`/get${url}`}
          hasSlash={true}
        />
      </Box>
    )
  })

  return <Box>{previewButtons}</Box>
}

const ContentField = (props) => {
  const { field, content, limitWidth, status } = props
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
      <Typography sx={{ alignSelf: 'center', width: '25%', ml: 3 }}>
        {field}
      </Typography>
      <Typography
        sx={{
          alignSelf: 'center',
          width: '75%',
          maxWidth: limitWidth ? limitWidth : 'unset',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {field === 'สถานะ' && <Dot sx={{ mt: 0, mr: 1 }} status={status} />}
          {content !== '' ? content : 'ไม่ได้ระบุ'}
        </Box>
      </Typography>
    </Box>
  )
}
const LabelField = (props) => {
  const { text } = props
  return (
    <Typography sx={{ pb: 1.5 }} variant="h6">
      {text}
    </Typography>
  )
}

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { isLoading, eExaminerDetail, otherDocConfig } = useSelector(
    (state) => ({
      isLoading: state.eExaminerDetail.isLoading,
      eExaminerDetail: state.eExaminerDetail.eExaminerDetail,
      otherDocConfig: state.eExamList.otherDocConfig,
    }),
    shallowEqual,
  )
  const userProfile = _.get(eExaminerDetail, 'userProfile')
  const userImage = _.get(eExaminerDetail, 'userProfile.image')
  const schedule = _.get(eExaminerDetail, 'schedule')
  const titleDownload = `บัตรประชาชน_${_.get(
    eExaminerDetail,
    'firstNameTH',
    '-',
  )}`
  const idCardImageKey = _.get(eExaminerDetail, 'idCardImageKey')
  const qualification = _.get(eExaminerDetail, 'qualification')
  const addressData = `${_.get(eExaminerDetail, 'address', '')} ${_.get(
    eExaminerDetail,
    'tambon.tambonName',
    '',
  )} ${_.get(eExaminerDetail, 'amphure.amphureName', '')} ${_.get(
    eExaminerDetail,
    'province.provinceName',
    '',
  )} ${_.get(eExaminerDetail, 'tambon.zipCode', '')}`

  useEffect(() => {
    dispatch(getOtherDocConfig())
    dispatch(fetchExaminerDetail(uuid))
  }, [])
  const otherDocuments = _.get(eExaminerDetail, 'otherDocuments', [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">{`รายละเอียดผู้สอบ : ${_.get(
                eExaminerDetail,
                'firstNameTH',
                '',
              )} ${eExaminerDetail?.lastNameTH ?? ''}`}</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbExaminerDetail} />
          </Box>
        </StyledHeader>

        <StyledCard sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Typography sx={{ pb: 1.5 }} variant="h3">
              {`${_.get(eExaminerDetail, 'firstNameTH', '')} ${
                eExaminerDetail?.lastNameTH ?? ''
              }`}
            </Typography>
            <ContentField
              field="สถานะ"
              content={
                STUDENT_STATUS_TEXT[_.get(eExaminerDetail, 'status', '')]
              }
              status={
                STUDENT_STATUS_DOT_COLOR_TEXT[_.get(eExaminerDetail, 'status')]
              }
            />
            <ContentField
              field="หมายเหตุ"
              content={
                _.isEmpty(_.get(eExaminerDetail, 'annotation'))
                  ? '-'
                  : _.get(eExaminerDetail, 'annotation', '-')
              }
            />
            <ContentField
              field="สนามสอบ"
              content={_.get(eExaminerDetail, 'examinationType', '-')}
            />
            <ContentField
              field="วันและเวลาที่สอบ"
              content={`${convertFormatDateTime({
                value: _.get(schedule, 'date', ''),
                type: 'date',
              })} ${_.get(schedule, 'time.startTime', '')} - ${_.get(
                schedule,
                'time.endTime',
                '',
              )}`}
            />
            <ContentField
              field="ผลการสอบ"
              content={
                _.isEmpty(_.get(eExaminerDetail, 'result'))
                  ? '-'
                  : TEST_RESULT_STATUS_TEXT[
                      _.get(eExaminerDetail, 'result', '-')
                    ]
              }
            />
          </Box>
          <Box>
            <img
              src={
                !_.isEmpty(userImage)
                  ? `${window.__env__.REACT_APP_API_URL}/file/${userImage}`
                  : '/logo/staff_profile_small.png'
              }
              width={160}
            />
          </Box>
        </StyledCard>
        <StyledCard sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelField text="ข้อมูลส่วนตัว" />
            <ContentField
              field="คำนำหน้าชื่อ"
              content={_.get(eExaminerDetail, 'prefixTH', '-')}
            />
            <ContentField
              field="ชื่อ - นามสกุล"
              content={`${_.get(eExaminerDetail, 'firstNameTH', '-')} ${
                eExaminerDetail?.lastNameTH ?? ''
              }`}
            />
            <ContentField
              field="เลขบัตรประชาชน"
              content={formatIdCard(_.get(eExaminerDetail, 'idCardNo', '-'))}
            />
            <ContentField
              field="วัน เดือน ปี เกิด"
              content={
                _.isEmpty(_.get(eExaminerDetail, 'dateOfBirth'))
                  ? '-'
                  : getFormatDate(
                      _.get(eExaminerDetail, 'dateOfBirth', '-'),
                      'DD MMMM BBBB',
                    )
              }
            />
            <ContentField
              field="ที่อยู่ปัจจุบัน"
              content={!_.isEmpty(addressData) ? addressData : '-'}
            />
            <ContentField
              field="เบอร์โทรศัพท์"
              content={formatPhoneNo(_.get(eExaminerDetail, 'mobileNo', '-'))}
            />
            <ContentField
              field="อีเมล"
              content={_.get(userProfile, 'email', '-')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelField text="ข้อมูลการทำงาน" />
            <ContentField
              field="ชื่อหน่วย"
              content={
                _.isEmpty(_.get(userProfile, 'unitName'))
                  ? '-'
                  : _.get(userProfile, 'unitName', '-')
              }
            />
            <ContentField
              field="สังกัดหน่วย"
              content={
                _.isEmpty(_.get(userProfile, 'unitNumber'))
                  ? '-'
                  : _.get(userProfile, 'unitNumber', '-')
              }
            />
            <ContentField
              field="รหัสตัวแทน"
              content={_.get(userProfile, 'userCode', '-')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelField text="ประสบการณ์" />
            <ContentField
              field="สถานศึกษา (มหาวิทยาลัย)"
              content={_.get(eExaminerDetail, 'institution', '-')}
            />
            <ContentField
              field="ระหว่าง"
              content={_.get(eExaminerDetail, 'between', '-')}
            />
            <ContentField
              field="วุฒิการศึกษา"
              content={_.get(qualification, 'qualificationName', '-')}
            />
            <ContentField
              field="สาขา"
              content={_.get(eExaminerDetail, 'branch', '-')}
            />
            <ContentField
              field="เกรดเฉลี่ย"
              content={_.get(eExaminerDetail, 'gpa', '-')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelField text="เอกสาร" />
            {!_.isEmpty(idCardImageKey) ? (
              <Box>
                <BoxLabel
                  isIcon={true}
                  labelLeft={'บัตรประชาชน'}
                  labelRight={
                    <ButtonAction
                      indexDoc={0}
                      title={'บัตรประชาชน'}
                      titleDownload={titleDownload}
                      url={`/get${idCardImageKey?.key}`}
                      hasSlash={true}
                    />
                  }
                />
              </Box>
            ) : (
              <ContentField field="บัตรประชาชน" content={'-'} />
            )}
            {((otherDocConfig &&
              otherDocConfig?.config?.status ===
                EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE) ||
              otherDocuments.length > 0) && (
              <Box>
                {otherDocuments.length > 0 ? (
                  <BoxLabel
                    isIcon={true}
                    labelLeft={
                      otherDocConfig?.config?.label ?? 'เอกสารเพิ่มเติม'
                    }
                    labelRight={OtherDocumentsBox({
                      otherDocuments,
                      otherDocConfig,
                    })}
                  />
                ) : (
                  <ContentField
                    field={otherDocConfig?.config?.label}
                    content={'-'}
                  />
                )}
              </Box>
            )}
          </Box>
        </StyledCard>
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
