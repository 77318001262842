import { store } from '../../../../../App'
import { STATUS } from '../model/status'
import { getFormatDate } from '../../../../../utils/lib'
import _ from 'lodash'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  const formatDate = 'MM/DD/YYYY'
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  let stationCheckedList = []
  if (filterState.stationChecked) {
    if (!_.isEmpty(filterState.station)) {
      const stationList = filterState.station
      Object.entries(stationList).forEach(([key, value]) => {
        if (value) stationCheckedList.push(key)
      })
    }
  }

  return {
    category: filterState.categoryChecked ? filterState.category : '',
    subCategory: filterState.subCategoryChecked ? filterState.subCategory : '',
    vendor: filterState.vendorChecked ? filterState.vendor : '',
    stockValueFrom: filterState.stockValueChecked
      ? filterState.stockValueFrom
      : '',
    stockValueTo: filterState.stockValueChecked ? filterState.stockValueTo : '',
    createdBy: filterState.createdByChecked ? filterState.createdBy : '',
    createStartDate: filterState.createDateChecked
      ? getFormatDate(filterState.createStartDate, formatDate)
      : '',
    createFinishDate: filterState.createDateChecked
      ? getFormatDate(filterState.createFinishDate, formatDate)
      : '',
    updateStartDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateStartDate, formatDate)
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateFinishDate, formatDate)
      : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,

    stationCheckedList: stationCheckedList,
    warehouse: filterState.warehouseChecked ? filterState.warehouse : '',
    costCenter: filterState.costCenterChecked ? filterState.costCenter : '',
    description: filterState.descriptionChecked ? filterState.description : '',
    returnYear: filterState.returnChecked ? filterState.returnYear : '',
    returnMonth: filterState.returnChecked ? filterState.returnMonth : '',
    returnDay: filterState.returnChecked ? filterState.returnDay : '',
    totalFrom: filterState.totalChecked ? filterState.totalFrom : '',
    totalTo: filterState.totalChecked ? filterState.totalTo : '',
    quantityAlertFrom: filterState.quantityAlertChecked
      ? filterState.quantityAlertFrom
      : '',
    quantityAlertTo: filterState.quantityAlertChecked
      ? filterState.quantityAlertTo
      : '',
    unitPriceFrom: filterState.unitPriceChecked
      ? filterState.unitPriceFrom
      : '',
    unitPriceTo: filterState.unitPriceChecked ? filterState.unitPriceTo : '',
    holder: filterState.holderChecked ? filterState.holder : '',
  }
}
