import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReviewAnswer } from './../../../../../../redux/slices/eTestingPreview'
import { StyledResultContent } from './../../../../../../components/Preview/Result/Styled'

const BannerFinish = () => {
  const dispatch = useDispatch()
  const questionResult = useSelector(
    (state) => state.eTestingPreview.setting.questionResult,
    shallowEqual
  )
  const isShowResult = _.get(questionResult, 'isOpen', false)
  const showResult = _.get(questionResult, 'result', 'LATER')
  return (
    <>
      <StyledResultContent>
        <img src="/icon/main_success.svg" />
        <Typography variant="h3" color="primary">
          ทำแบบทดสอบสำเร็จ
        </Typography>
        <Typography variant="h6" color="text.secondary">
          ระบบจะแจ้งให้คุณทราบในภายหลัง
        </Typography>
      </StyledResultContent>
      {isShowResult && showResult == 'LATER' && (
        <Button
          data-testid="btn-result"
          variant="contained"
          size="l"
          sx={{ mb: 0.5 }}
          onClick={() => dispatch(setReviewAnswer())}
        >
          ดูเฉลย
        </Button>
      )}
    </>
  )
}

export default BannerFinish
