import React from 'react'
import _ from 'lodash'
import { Card, Box } from '@mui/material'
import { ViewRow } from '../../../../../components/CRUD/components/StyledViewBox'

export const OutsideContactInfo = ({ item }) => {
  return (
    <Card sx={{ my: 1, p: 2 }}>
      <Box display="flex" justifyContent="start" px={2}>
        <ViewRow variant="body1" titleSx={{ minWidth: 150 }} title="ชื่อผู้ติดต่อ" value={_.get(item, 'name', '-')} />
      </Box>
      <Box display="flex" justifyContent="start" px={2}>
        <ViewRow boxSx={{width: '50%'}} titleSx={{ minWidth: 150 }} variant="body1" title="เบอร์โทรศัพท์" value={_.get(item, 'phone', '-')} />
        <ViewRow boxSx={{width: '50%'}} titleSx={{ minWidth: 150 }} variant="body1" title="Email" value={_.get(item, 'email', '-')} />
      </Box>
    </Card>
  )
}
