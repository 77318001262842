import React from 'react'

import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormGroupLeaveStaff } from '../FormView'

export default function index({ dataList }) {
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
          ผู้เข้าร่วมประชุม
        </Typography>

        <FormGroupLeaveStaff listStaff={_.get(dataList, 'staffs', [])} />
      </Box>

      <Box sx={{ display: 'flex', mt: 3 }}>
        <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
          สถานที่จัดประชุม
        </Typography>
        <Typography variant="body1">
          ประชุมแบบ{' '}
          {_.get(dataList, 'meetingType', 'ONSITE') === 'ONSITE'
            ? 'Onsite'
            : 'Online'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body1" sx={{ width: '65%', mr: 3 }}>
          หัวข้อ
        </Typography>
        <Typography variant="body1" sx={{ width: '100%' }}>
          {_.get(dataList, 'title', '')}
        </Typography>
      </Box>
      {dataList.meetingType === 'ONSITE' && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ width: '65%', mr: 3 }}>
              TO ผู้รับจองห้องประชุม
            </Typography>
            <Typography variant="body1" sx={{ width: '100%' }}>{`${_.get(
              dataList,
              'staffProfile.firstNameTH',
              ''
            )} ${_.get(dataList, 'staffProfile.lastNameTH', '')}`}</Typography>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
              สถานะการยืนยันจาก TO
            </Typography>
            <Typography variant="body1">
              {_.get(dataList, 'statusTO', 'INACTIVE') === 'ACTIVE'
                ? 'ยืนยันรับจอง'
                : 'ไม่ยืนยันรับจอง'}
            </Typography>
          </Box>
        </>
      )}

      <Box sx={{ display: 'flex' }}>
        <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
          รายละเอียดเพิ่มเติม
        </Typography>
        <Typography variant="body1" sx={{ width: 250 }}>
          {_.get(dataList, 'description', '')}
        </Typography>
      </Box>
    </>
  )
}
