import React from 'react'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'

import Info from './Info'
import Setting from './Setting'
import Share from './Share'
import Notification from './Notification'
import TestingRound from './Setting/TestingRound'
import { BoxCard } from './Styled'

const Index = () => {
  const typeOfETesting = useSelector(
    (state) => state.eTestingForm.typeOfETesting,
    shallowEqual
  )

  return (
    <Box sx={{ my: 3 }}>
      <Info />
      {typeOfETesting === 'ONLINE_TEST' && (
        <>
          <Setting />
          <Notification />
        </>
      )}
      {typeOfETesting === 'STANDALONE' && (
        <>
          <Setting />
          <Share />
        </>
      )}
      {typeOfETesting === 'ASSESSMENT' && (
        <BoxCard>
          <TestingRound />
        </BoxCard>
      )}
    </Box>
  )
}

export default Index
