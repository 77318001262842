import React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import DatePicker from '../../../../../../components/Input/DatePicker'
import { StyledFormCard } from '../../../../Styled'

import { onDateChange } from './events'
import { useInputField } from '../../../../../../components/CRUD/hooks/useInputField'

const Period = () => {
  const dispatch = useDispatch()

  const { value, isError, message } = useInputField('periodStart')

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">ระยะเวลาของ Collection</Typography>
      <DatePicker
        id="startDate"
        labelText="วันเริ่มต้น"
        placeholder=""
        required
        disablePast
        hideDisableDateRange
        sxTextError={{ mb: 0 }}
        textError={message}
        hideTextError={!isError}
        value={value}
        onChange={(value) =>
          dispatch(onDateChange(value, 'formData.periodStart'))
        }
      />
    </StyledFormCard>
  )
}

export default Period
