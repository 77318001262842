import _ from 'lodash'
import {
  setInitialModulePreview,
  setInitialPreview,
  startLoading,
  stopLoading,
} from '../../../redux/slices/eLearning/preview'
import { getDetail } from '../../../services/eLearning/module/detail'
import { handleModuleBody } from '../../../redux/slices/eLearning/moduleForm/events'

export const handleInitialPreview = (props) => async (dispatch) => {
  const { eLearning, setELearning, uuid } = props
  if (_.isEmpty(uuid)) {
    if (eLearning.isCourse) dispatch(setInitialPreview(eLearning))
    else dispatch(setInitialModulePreview(eLearning))
    return
  }

  dispatch(startLoading())
  const response = await dispatch(getDetail(uuid))
  if (_.isNil(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const fetchedELearning = handleModuleBody(data)
    dispatch(setInitialModulePreview(fetchedELearning))
    setELearning(fetchedELearning)
  }
  dispatch(stopLoading())
}
