import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxCardBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme?.palette?.primary?.contrast,
  border: '1px solid #DBE4F1',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
}))

export const BoxBetween = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

export const BoxSwitch = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

export const BoxCardContent = styled(Box)(({ theme, isDefault }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  width: '100%',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  background: isDefault ? '#F5F5F5' : '#FFFFFF',
}))

export const BoxImgContent = styled(Box)(({ theme, isDefault }) => ({
  width: isDefault ? '50%' : '70%',
  height: 400,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
  [theme.breakpoints.down('lg')]: {
    width: isDefault ? '50%' : '100%',
    height: 297,
  },
  [theme.breakpoints.down('sm')]: {
    width: isDefault ? '50%' : '100%',
    height: 138,
  },
}))
