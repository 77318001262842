import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/manageClassCheckExam'
import {
  putManageClassAnnouncement,
  putStandaloneAnnounce,
} from '../../../../services/manageClass/check'
import {
  loadingDialog,
  openDialog,
  openErrorDialog,
  openSuccessDialog,
  setEnableButton,
} from '../../../../redux/slices/dialog'

export const checkScrollWidth = (setScroll, headerHeight) => {
  if (window !== undefined) {
    const scrollRange = window.innerWidth < 600 ? headerHeight - 20 : 30
    setScroll(window.scrollY > scrollRange)
  }
}

export const handleAnnouncing = (idProps) => async (dispatch) => {
  const { idParams, isStandalone } = idProps
  const body = { isCheck: true }

  dispatch(startLoading())
  let response = null
  if (isStandalone) {
    body.eTestingVersionUuid = _.get(idParams, 'eTestingVersionUuid', '')
    response = await dispatch(putStandaloneAnnounce(body))
  } else {
    body.trainingPlanUuid = _.get(idParams, 'id', '')
    body.courseTestAndEvaUuid = _.get(idParams, 'courseId', '')
    response = await dispatch(putManageClassAnnouncement(body))
  }
  dispatch(stopLoading())
  const data = _.get(response, 'payload.data', undefined)
  if (data) {
    const waiting = _.get(data, 'waitingToCheckExam', 0)
    if (waiting > 0) {
      dispatch(
        openErrorDialog({
          title: 'พบแบบทดสอบรอตรวจเหลืออยู่',
          message: `เหลือแบบทดสอบ ${waiting} รายการที่รอตรวจอยู่ กรุณาตรวจให้ครบก่อนทำการประกาศผล `,
          isCloseDialog: true,
        })
      )
    } else {
      body.isCheck = false
      dispatch(
        openDialog({
          title: 'ประกาศผลสอบ',
          message:
            'คุณต้องการประกาศผลแบบทดสอบที่ทำการตรวจแล้ว และส่งอีเมลแจ้งผู้เรียนเลยหรือไม่',
          handleConfirm: async () => {
            dispatch(loadingDialog())
            let result = null
            if (isStandalone) {
              result = await dispatch(putStandaloneAnnounce(body))
            } else {
              result = await dispatch(putManageClassAnnouncement(body))
            }
            const data = _.get(result, 'payload.data', undefined)
            if (data) {
              dispatch(setEnableButton())
              dispatch(
                openSuccessDialog({
                  message: _.get(data, 'message', '-'),
                  handleConfirm: () => window.location.reload(),
                })
              )
            }
          },
        })
      )
    }
  }
}
