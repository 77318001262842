import _ from 'lodash'
import { ratingChoices } from '../../../../../../../../constants/eEvaluation'

export const handleSelector = (state, keyProps) => {
  const { keyField, keyAssessment, keySub, keyQuestion } = keyProps
  const assessment = state.eEvaluationPreview[keyField].find(
    (item) => item.key == keyAssessment
  )

  if (!keySub) {
    const question = assessment.questions.find(
      (item) => item.key == keyQuestion
    )
    return {
      choices: _.get(assessment, 'choices', ratingChoices),
      title: _.get(question, 'title', ''),
      answer: _.get(question, 'answer', ''),
    }
  }

  const choice = assessment.choices.find(
    (item) => item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})
  const question = subQuestion.questions.find((item) => item.key == keyQuestion)
  return {
    choices: _.get(subQuestion, 'choices', ratingChoices),
    title: _.get(question, 'title', ''),
    answer: _.get(question, 'answer', ''),
  }
}
