import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/system/styled'

export const StyledChoice = styled(FormControlLabel)((props) => {
  const { isSubmit, isLoading } = props.resultProps
  return {
    background: displayResult(props),
    marginBottom: props.theme.spacing(1),
    pointerEvents: isSubmit || isLoading ? 'none' : 'auto',
    cursor: isSubmit || isLoading ? 'default' : 'pointer',
    wordBreak: 'break-word',
    alignItems: 'flex-start',
    input: {
      cursor: isSubmit || isLoading ? 'default' : 'pointer',
    },
    '& > .MuiCheckbox-root': { padding: '6px' },
    '& > .MuiFormControlLabel-label': { marginTop: '4px' },
  }
})

export const displayResult = (props) => {
  const { theme, checked, resultProps } = props
  const { isSubmit, isAnswer, questionResult } = resultProps
  const { isOpen, resultType } = questionResult

  if (!isOpen) return 'none'
  if (!isSubmit) return 'none'

  if (resultType == 'SHOW_TRUE_FALSE') {
    if (isAnswer) return theme?.palette?.success?.background
    if (checked) return theme?.palette?.error?.background
  } else {
    if (checked) {
      return isAnswer
        ? theme?.palette?.success?.background
        : theme?.palette?.error?.background
    }
  }
}
