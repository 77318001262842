import { Box, Tab, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { tooltipClasses } from '@mui/material/Tooltip'

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette?.text?.lightGray,
  textTransform: 'none',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&.Mui-selected': { borderBottom: `${theme.spacing(0.5)} solid` },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 18,
  },
}))

export const StyledPagination = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})
