import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import TextInput from '../../../../../components/Input/TextInput'
import { Box } from '@mui/system'
import _ from 'lodash'
import { handleChange } from '../../event'
import Button from '@mui/material/Button'
import { closeDialog } from '../../../../../redux/slices/dialog'
import { getOnlineRoomForDelete, handleDeleteBooking } from '../event'
import LoadingButton from '@mui/lab/LoadingButton'

export const DeleteBooking = ({ bookingUuid }) => {
  const dispatch = useDispatch()
  const { roomBookingList, reason, stationUuid, isLoading } = useSelector(
    (state) => ({
      roomBookingList:
        state.roomManagementDrawer.bookingInformation.roomBookingList,
      reason: state.roomManagementDrawer.bookingInformation.reason,
      stationUuid: state.roomManagement.stationUuid,
      isLoading: state.dialog.isLoading,
    }),
    shallowEqual,
  )
  const [error, setErrors] = useState('')

  useEffect(() => {
    if (reason?.length > 3000) {
      setErrors('กรอกได้ไม่เกิน 3,000 ตัวอักษร')
    } else {
      setErrors('')
    }
  }, [reason])

  return (
    <Box sx={{ px: 2 }}>
      <TextInput
        type="basic"
        name="reason"
        labelText={'กรุณากรอกสาเหตุการยกเลิก'}
        placeholder="กรุณากรอก"
        rows={3}
        multiline={true}
        value={reason}
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          dispatch(
            handleChange({
              key: 'reason',
              value: value,
            }),
          )
        }}
        isShowTextError={!_.isEmpty(error)}
        textError={error}
      />
      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          sx={{ width: 80 }}
          data-testid="btn-disagree"
          onClick={() => dispatch(closeDialog())}
          variant="outlined"
          color="error"
          endIcon=""
        >
          ยกเลิก
        </Button>
        <LoadingButton
          data-testid="btn-confirm-dialog"
          loading={isLoading}
          loadingPosition="end"
          variant="contained"
          color="error"
          endIcon=""
          disabled={!reason}
          onClick={() => {
            if (_.isEmpty(error))
              dispatch(
                handleDeleteBooking({
                  reason: reason,
                  uuid: bookingUuid,
                  stationUuid: stationUuid,
                  onlineList: getOnlineRoomForDelete(roomBookingList),
                }),
              )
          }}
        >
          ยืนยัน
        </LoadingButton>
      </Box>
    </Box>
  )
}
