import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { examinationDashboard } from '../../../../utils/apiPath'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
// import { overviewsDemo, overviewStatusDemo } from '../models/overview'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { filtersDrawer } = store.getState().crud.eExamDashboard
  const station = _.defaultTo(filtersDrawer?.station, []).map(
    (item) => item.uuid,
  )
  const associationRegion = _.defaultTo(
    filtersDrawer?.associationRegion,
    [],
  ).map((item) => item.uuid)
  const associationProvince = _.defaultTo(
    filtersDrawer?.associationProvince,
    [],
  ).map((item) => item.uuid)
  const otherRegion = _.defaultTo(filtersDrawer?.otherRegion, []).map(
    (item) => item.uuid,
  )
  const otherProvince = _.defaultTo(filtersDrawer?.otherProvince, []).map(
    (item) => item.uuid,
  )
  const body = {
    station: station,
    startDate: _.defaultTo(
      filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(filtersDrawer?.endDate, dayjs().format('YYYY-MM-DD')),
    associationRegion: associationRegion,
    associationProvince: associationProvince,
    otherRegion: otherRegion,
    otherProvince: otherProvince,
  }
  // let result = []
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  await callAxios
    .post(examinationDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'eExamDashboard.latestUpdated',
          value: _.get(data, 'latestUpdated', undefined),
        }),
      )

      store.dispatch(
        setFieldValue({
          key: 'eExamDashboard.overviewDataCount',
          value: data,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
