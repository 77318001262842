import _ from 'lodash'
import {
  closeDialog,
  setEnableButton,
  setDisableButton,
} from '../../../../../../redux/slices/dialog'

import {
  setChange,
  setClearBodyDialog,
  setStopLoading,
  setStartLoading,
} from '../../../../../../redux/slices/manageClassAttendance'
import { putAttendanceCheck } from '../../../../../../services/attendance'

export const revertDate = (date) => {
  if (_.isEmpty(date)) return '-'
  const listDate = date.split('/')
  return `${listDate[2]}-${listDate[1]}-${listDate[0]}`
}

export const handleChangeBodyDialog = (props) => (dispatch) => {
  dispatch(setChange(props))
}

export const handleDisabledDialog = (value) => (dispatch) => {
  if (value) {
    dispatch(setEnableButton())
  } else {
    dispatch(setDisableButton())
  }
}

export const handleChangeRadio = (value) => (dispatch) => {
  let result = {
    type: value,
  }
  if (value === 'CHECK_STUDY_ALL') {
    result = {
      ...result,
      classLearnerUuid: [],
      isCheckOtherToOpposite: false,
    }
  }

  dispatch(setChange(result))
}

export const handleSubmitCheck = (body) => async (dispatch) => {
  dispatch(setStartLoading())
  dispatch(closeDialog())
  await dispatch(putAttendanceCheck(filterUuid(body)))
  dispatch(setClearBodyDialog())
  dispatch(setStopLoading())
  window.location.reload()
}

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
  dispatch(setClearBodyDialog())
}

export const filterUuid = (body) => {
  const classLearnerUuid = _.get(body, 'classLearnerUuid', [])
  if (!classLearnerUuid.length) return body

  return {
    ...body,
    classLearnerUuid: classLearnerUuid.map((item) => item.value),
  }
}
