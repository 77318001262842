import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { setPointDialog } from '../../../../../../redux/slices/manage/agentProfile'
import { DIALOG_TAB } from '../model'
import { StyledTab } from '../PointDrawer/Styled'

const TabMenu = () => {
  const dispatch = useDispatch()
  const { tab } = useSelector(
    (state) => ({
      tab: state.agentProfile.pointDialog.tab,
    }),
    shallowEqual,
  )
  return (
    <StyledTab
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, value) => {
        dispatch(setPointDialog({ key: 'tab', value: value }))
      }}
    >
      <Tab
        sx={{ width: 'calc(50% - 12px)' }}
        value={DIALOG_TAB.MANAGE}
        label={
          <Typography variant="body1b" color="primary">
            จัดการคะแนน
          </Typography>
        }
      />
      <Tab
        sx={{ width: 'calc(50% - 12px)' }}
        value={DIALOG_TAB.REDEEM}
        label={
          <Typography variant="body1b" color="primary">
            แลกคะแนน
          </Typography>
        }
      />
    </StyledTab>
  )
}

export default TabMenu
