import React, { useEffect, useState } from 'react'
import { InputImageUpload } from '../../Styled'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import * as service from '../../../../services/eCertiticate/form'
import * as reducer from '../../../../redux/slices/eCertificateForm'
import {
  openErrorDialog,
  openDialog,
  loadingDialog,
  closeDialog,
} from '../../../../redux/slices/dialog'
import { eCertificationBackground } from '../../../../utils/apiPath'
import {
  StyledGroupLayoutBox,
  StyledLayoutBox,
  StyledLayoutBoxAll,
} from './UploadBackground/Styled'
import { LINE_OPTION } from '../../../../constants/eCertification'

export const fetchDataFormUuid = (uuid) => async (dispatch) => {
  const layoutOption = {
    limit: -1,
    page: 1,
  }
  dispatch(reducer.startLoading())
  const data = await Promise.all([
    dispatch(service.getFormDetailECertificate(uuid)),
    dispatch(service.getLayoutBackgroundECertificateBackground(layoutOption)),
  ])
  const detailData = _.get(data, '[0].payload', {})
  const layoutData = _.get(data, '[1].payload', [])

  dispatch(
    reducer.setFetchData({
      ...detailData,
      layouts: layoutData,
    })
  )
  dispatch(reducer.stopLoading())
}

export const fetchDataForm = () => async (dispatch) => {
  const layoutOption = {
    limit: -1,
    page: 1,
  }
  dispatch(reducer.startLoading())
  const data = await Promise.all([
    dispatch(service.getLayoutBackgroundECertificateBackground(layoutOption)),
  ])
  const layoutData = _.get(data, '[0].payload', {})

  dispatch(
    reducer.setLayouts({
      layouts: layoutData,
    })
  )
  dispatch(reducer.stopLoading())
}

export const UploadImage = ({ htmlFor, labelText, onUpload, disabled }) => {
  return (
    <label htmlFor={htmlFor}>
      <Button
        sx={{ mr: 2 }}
        disabled={disabled}
        variant="contained"
        size="s"
        component="span"
        htmlFor={htmlFor}
      >
        <InputImageUpload
          accept="image/jpg, image/jpeg, image/png"
          type="file"
          id={htmlFor}
          disabled={disabled}
          onChange={onUpload}
        />
        {labelText && (
          <Typography noWrap variant="buttons">
            {labelText}
          </Typography>
        )}
      </Button>
    </label>
  )
}

export const fileName = (imageKey) => {
  return imageKey.length > 0
    ? imageKey.split('/')[imageKey.split('/').length - 1]
    : ''
}

export const handleUploadImage =
  (e, keyValue, setErrors) => async (dispatch) => {
    let body = new FormData()
    if (e.target.files.length === 0) return
    body.append('file', e.target.files[0])
    const getNameImage = _.get(e, 'target.files[0].name', '')

    if (getNameImage) {
      const mimetype = ['jpg', 'png', 'jpeg']
      const splitName = getNameImage.split('.')
      if (!mimetype.includes(splitName[splitName.length - 1].toLowerCase())) {
        dispatch(
          openErrorDialog({
            message:
              'ไม่สามารถ Upload ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้องกรุณา Upload ตามที่กำหนดไว้',
            title: 'นามสกุลไฟล์ไม่ถูกต้อง',
          })
        )
        setErrors('นามสกุลไฟล์ไม่ถูกต้อง')
        _.set(document.getElementById('btn-add-image'), 'value', null)
        return null
      }
      const sizeFile = _.get(e, 'target.files[0].size', 0)
      if (sizeFile > 4000000) {
        dispatch(
          openErrorDialog({
            message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 4 MB กรุณาลองใหม่อีกครั้ง',
            title: 'ขนาดไฟล์ใหญ่เกินไป',
          })
        )
        setErrors('ไฟล์เกิน 4MB')
        _.set(document.getElementById('btn-add-image'), 'value', null)
        return null
      }
    }
    setErrors('')
    // service upload
    const payload = {
      body,
      key: 'loadingProgress',
    }
    const dataUpload = await dispatch(
      service.uploadFileCertificateBackground(payload)
    )
    dispatch(
      reducer.setChange({
        key: keyValue,
        value: _.get(dataUpload, 'payload.data.key', ''),
      })
    )

    document.getElementById('btn-add-image').value = null
  }

export const handleChange = (props) => (dispatch) => {
  dispatch(reducer.setChange(props))
}
export const onErrorNextValidate = () => async (dispatch) => {
  dispatch(
    openErrorDialog({
      title: 'กรอกข้อมูลไม่ครบ',
      message: (
        <>
          <Typography variant="body1">
            ไม่สามารถกดถัดไปได้ เนื่องจากกรอกข้อมูลไม่ครบ
          </Typography>
          <Typography variant="body1">หรือไม่ตรงตามเงื่อนไขที่กำหนด</Typography>
        </>
      ),
    })
  )
}

export const onErrorValidate = () => async (dispatch) => {
  dispatch(
    openErrorDialog({
      title: 'กรอกข้อมูลไม่ครบ',
      message: (
        <>
          <Typography variant="body1">
            ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ
          </Typography>
          <Typography variant="body1">หรือไม่ตรงตามเงื่อนไขที่กำหนด</Typography>
        </>
      ),
    })
  )
}

export const onErrorSubmitValidate = (title, message) => async (dispatch) => {
  dispatch(
    openErrorDialog({
      title: title,
      message: message,
      handleConfirm: () => {
        dispatch(closeDialog())
        dispatch(reducer.stopLoading())
      },
    })
  )
}

export const onSubmitFormECertificate = (props) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(actionSubmitForm(props)),
    })
  )
}

export const actionSubmitForm = (props) => async (dispatch) => {
  const { stateCertificate, history, setErrors } = props

  const uuid = _.get(stateCertificate, 'uuid', '')
  const body = {
    codeId: _.get(stateCertificate, 'codeId', ''),
    name: _.get(stateCertificate, 'name', ''),
    imageKey: _.get(stateCertificate, 'imageKey', ''),
    status: _.get(stateCertificate, 'status', ''),
  }

  dispatch(reducer.startLoading())
  dispatch(loadingDialog())

  const result = await dispatch(
    service.submitFormCertificateBackground(!uuid ? body : { ...body, uuid })
  )
  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  console.log('payload ->', payload)
  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        handleConfirm: () => {
          history.push(`${eCertificationBackground}`)
          dispatch(closeDialog())
          dispatch(reducer.setClearData())
        },
      })
    )
  } else if (payload === 'rejected') {
    const getErrorMessage = _.get(result, 'meta.response.data.message')
    if (getErrorMessage === undefined) {
      const resError = _.get(result, 'meta.response.data', {})
      const title = _.get(resError, 'constraints.title', '')
      const value = _.get(resError, 'constraints.value', '')
      dispatch(onErrorSubmitValidate(title, value))
    } else {
      let Errors = {}
      for (const item of getErrorMessage) {
        Errors = {
          ...Errors,
          [`${item.property}`]: `${item.constraints.value}`,
        }
      }
      setErrors(Errors)
      dispatch(closeDialog())
    }
  }
  dispatch(reducer.stopLoading())
}

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}

export const LayoutBox = ({ layoutNumber, layouts, formLayout }) => {
  const Layout = !_.isEmpty(formLayout)
    ? formLayout
    : _.filter(layouts, (l) => l.id === layoutNumber)[0]
  if (_.isEmpty(Layout)) return <></>
  const DivLayout = _.map(Layout.layout, (item, key) => {
    if (item.type === LINE_OPTION.MULTILINE) {
      return (
        <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
          {_.map(item.layout, (li, n) => (
            <Typography
              key={`layout_box_n_${n}`}
              sx={{ ...item.style, ...li.style }}
            >
              {li.text}
            </Typography>
          ))}
        </div>
      )
    }
    return (
      <Typography key={`layout_box_${key}`} sx={item.style}>
        {!_.isEmpty(item.text) ? item.text : ' '}
      </Typography>
    )
  })
  return DivLayout
}

export const LayoutFormBox = ({ layouts }) => {
  if (_.isEmpty(layouts)) return <></>
  const DivLayout = _.map(layouts, (item, key) => {
    if (item.type === LINE_OPTION.MULTILINE) {
      return (
        <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
          {_.map(item.layout, (li, n) => (
            <Typography
              key={`layout_box_n_${n}`}
              sx={{ ...item.style, ...li.style }}
            >
              {li.text}
            </Typography>
          ))}
        </div>
      )
    }
    return (
      <Typography key={`layout_box_${key}`} sx={item.style}>
        {item.text}
      </Typography>
    )
  })
  return DivLayout
}

export const LayoutOption = ({
  setSelectLayout,
  isSelectLayout,
  layouts,
  width,
}) => {
  return (
    <StyledGroupLayoutBox container height={width / 3}>
      {_.map(layouts, (item) => (
        <StyledLayoutBoxAll item key={`layout_${item.uuid}`}>
          <StyledLayoutBox
            width={width}
            onClick={() => setSelectLayout(item.id)}
            active={isSelectLayout === item.id ? 1 : undefined}
          >
            {_.map(item.layout, (l, key) => {
              if (l.type === LINE_OPTION.MULTILINE) {
                return (
                  <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
                    {_.map(l.layout, (li, n) => (
                      <Typography
                        key={`layout_box_n_${n}`}
                        sx={{ ...l.style, ...li.style }}
                      >
                        {li.text}
                      </Typography>
                    ))}
                  </div>
                )
              }
              return (
                <Typography key={`layout_list_${key}-${item.id}`} sx={l.style}>
                  {l.text}
                </Typography>
              )
            })}
          </StyledLayoutBox>
          <Typography variant="caption" color="text.secondary">
            {item.name}
          </Typography>
        </StyledLayoutBoxAll>
      ))}
    </StyledGroupLayoutBox>
  )
}
