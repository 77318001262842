import { Tab } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  color: theme.palette?.primary.main,
  textTransform: 'none',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&.Mui-selected': {
    borderBottom: `${theme.spacing(0.5)} solid`,
    fontSize: theme.typography.body1b.fontSize,
    fontWeight: theme.typography.body1b.fontWeight,
    color: theme.palette?.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 18,
  },
  minWidth: 'fit-content',
}))
