import styled from '@mui/system/styled'
import { Row } from '../../../../../../../components/Layout/Styled'

export const StyledImgBox = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'isFace',
})(({ theme, isFace }) => ({
  width: 100,
  minWidth: 100,
  height: 100,
  alignItems: 'center',
  border: '2px solid',
  background: theme.palette?.common?.black,
  img: {
    width: '100%',
    height: '100%',
    objectFit: isFace ? 'cover' : 'contain',
  },
}))
