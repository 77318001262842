import {
  stockListFilter,
  stockDetail,
  getStockDetailHistory,
  transferDownloadTemplate,
  transferUpload,
  inventoryTicketFromMonthlyPlan,
  inventoryTrainingPlanFilter,
} from '../../utils/apiPath'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { CRUDListFilterPath } from '../../utils/apiPath'
import _ from 'lodash'
import callAxios from '../../utils/baseService'
import { callAPI } from '../../utils/lib'

export const getStockListFilter = createAsyncThunk(
  'inventory/getStockListFilter',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(stockListFilter, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getStockDetail = createAsyncThunk(
  'inventory/getStockDetail',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${stockDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getVendorFilterDefault = createAsyncThunk(
  'inventory/getVendorFilterDefault',
  async (n, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        quickSearch: '',
        name: '',
        station: [],
        vendorType: [],
        dateStartDate: '',
        dateFinishDate: '',
        renewDateStartDate: '',
        renewDateFinishDate: '',
        createStartDate: '',
        createFinishDate: '',
        updateStartDate: '',
        updateFinishDate: '',
        insideContact: '',
        outsideContact: '',
        status: ['ACTIVE'],
        createdBy: '',
        updatedBy: '',
      }

      const response = await callAxios.post(CRUDListFilterPath, body, {
        'x-type': 'VENDOR',
      })
      return _.get(response, 'data.result', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const createStock = createAsyncThunk(
  'inventory/createStock',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(stockDetail, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateStock = createAsyncThunk(
  'inventory/updateStock',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.put(stockDetail, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getStockDetailHistoryFilter = createAsyncThunk(
  'inventory/getStockDetailHistoryFilter',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(getStockDetailHistory, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteStock = createAsyncThunk(
  'inventory/deleteStock',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${stockDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadTemplate = createAsyncThunk(
  'inventory/downloadTransferTemplate',
  async (obj, { rejectWithValue }) => {
    try {
      callAPI({
        method: 'post',
        url: transferDownloadTemplate,
        responseType: 'blob',
        body: obj,
        onSuccess: (data) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Template_Transfer_${obj.productType}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        },
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadProductWarehouse = createAsyncThunk(
  'inventory/uploadProductWarehouse',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(transferUpload, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getTicketFromMonthlyPlan = createAsyncThunk(
  'inventory/getTicketFromMonthlyPlan',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        inventoryTicketFromMonthlyPlan,
        bodyProps,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getTrainingPlan = createAsyncThunk(
  'inventory/getTrainingPlan',
  async (n, { rejectWithValue }) => {
    try {
      const response = await callAxios.get(inventoryTrainingPlanFilter)
      return _.get(response, 'data.result', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
