import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'

export const handleDeleteContentItem = async (index) => {
  const state = store.getState().crud

  const contents = state.formData.contents.filter((_, i) => i !== index)

  store.dispatch(
    setFieldValue({
      key: `formData.contents`,
      value: contents,
    }),
  )
}
