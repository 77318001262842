import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { Column, Row } from '../../../../../../../components/Preview/Layout/Styled'
import { E_LEARNING_LEARNER_LOG_RESULT } from '../../../../../../../constants/eLearning'
import { convertFormatDateTime } from '../../../../../../../utils/lib'
import { handleTitleWording } from './events'
import {
  STATUS_COLOR,
  StyledFaceCard,
  StyledImage,
  StyledImageWrapper,
} from './Styled'

const FaceCard = ({ items }) => {
  const data = _.get(items, '0', {})
  const dateTime = convertFormatDateTime({
    value: data.createdAt,
    type: 'dateTime',
  })
  const failTotal = _.filter(
    items,
    (item) => item.status === E_LEARNING_LEARNER_LOG_RESULT.FAIL,
  ).length
  const successTotal = _.filter(
    items,
    (item) => item.status === E_LEARNING_LEARNER_LOG_RESULT.SUCCESS,
  ).length

  return (
    <StyledFaceCard>
      <Column sx={{ width: 189, height: 130, justifyContent: 'space-between' }}>
        <Column sx={{ width: 'auto', gap: 0.5 }}>
          <Typography variant="body1b">{handleTitleWording(data)}</Typography>
          <Typography variant="body3" color="text.secondary">
            เมื่อ {dateTime}
          </Typography>
        </Column>
        <Column sx={{ width: 'auto', gap: 0.5 }}>
          <Row sx={{ width: 'auto', gap: 1 }}>
            <Typography width={90} variant="body2" color="error">
              ไม่ผ่าน
            </Typography>
            <Typography width={90} variant="body2b">
              {failTotal}
            </Typography>
          </Row>
          <Row sx={{ width: 'auto', gap: 1 }}>
            <Typography width={90} variant="body2" color="success.main">
              ผ่าน
            </Typography>
            <Typography width={90} variant="body2b">
              {successTotal}
            </Typography>
          </Row>
        </Column>
      </Column>

      <StyledImageWrapper>
        {items.map((item, index) => (
          <ImageResult key={index} data={item} />
        ))}
      </StyledImageWrapper>
    </StyledFaceCard>
  )
}

export default FaceCard

export const ImageResult = ({ data }) => {
  const status = _.get(data, 'status', E_LEARNING_LEARNER_LOG_RESULT.FAIL)
  const percent = _.get(data, 'percent', 0)
  const faceImageKey = _.get(data, 'faceImageKey', '')
  return (
    <Column sx={{ width: 'auto', alignItems: 'center', gap: 0.5 }}>
      <StyledImage
        src={`${window.__env__.REACT_APP_API_URL}/face-rekognition${faceImageKey}`}
        status={status}
      />
      <Typography variant="body2" color={STATUS_COLOR[status]}>
        {percent} %
      </Typography>
    </Column>
  )
}
