import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Form, Formik, useFormikContext } from 'formik'
import _ from 'lodash'

import {
  setToggleFilterDrawer,
  setFilterTotal,
} from '../../../redux/slices/table'
import DateRangeInput from '../../../components/DateRangeInput'
import { StyledList } from '../../../components/FilterDrawer/Styled'
import {
  BoxDrawer,
  BoxFooter,
  BoxGroupBox,
  BoxHeadLabel,
  StyledInputBase,
} from './Styled'
import {
  arrayCheckbox,
  arrayStandaloneCheckbox,
  arrayStatus,
  arrayVersionCheckbox,
  defaultFilter,
  standaloneFilter,
  versionFilter,
} from '../model'
import * as events from './events'
import { onSubmitFilter } from '../events'
import { onSubmitVersionFilter } from '../Version/events'
import { schema } from './schema'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../utils/lib'

export const ListCheckbox = ({ arrayCheckbox, stateFilter, setFilter }) => {
  const { errors } = useFormikContext()
  return arrayCheckbox.map((item, index) => {
    const keyEndDate =
      item.nameInput === 'startDate' ? 'toStartDate' : 'toEndDate'
    const displayEDate =
      item.nameInput === 'startDate' ? 'displayStartDate' : 'displayEndDate'
    const textError = _.get(errors, item.nameInput, '')
    const isError = !_.isEmpty(textError)
    return (
      <BoxGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type === 'text' && (
                <>
                  <StyledInputBase
                    type="basic"
                    isError={isError}
                    inputProps={
                      item.nameInput === 'version'
                        ? {
                            'data-testid': `input-${item.nameInput}`,
                            type: 'number',
                            min: '1',
                            onInput: handleNumberInput,
                            onKeyDown: handleNumberKeyDown,
                            onPaste: handlePasteFormat,
                          }
                        : { 'data-testid': `input-${item.nameInput}` }
                    }
                    name={item.nameInput}
                    disabled={!stateFilter[item.nameCheckBox]}
                    placeholder={item.placeholder}
                    value={stateFilter[item.nameInput]}
                    onChange={(e) => {
                      events.handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: stateFilter,
                        setFilter,
                      })
                    }}
                  />
                  {isError && (
                    <Typography color="error.main">{textError}</Typography>
                  )}
                </>
              )}
              {item.isDate && (
                <DateRangeInput
                  dateState={{
                    startDate: stateFilter[item.nameInput],
                    endDate: stateFilter[keyEndDate],
                    display: stateFilter[displayEDate],
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate({
                      filter: stateFilter,
                      setFilter,
                      selectedDates,
                      nameInput: item.nameInput,
                    })
                  }}
                />
              )}
              {item.type === 'checkbox' && (
                <StyledList>
                  <ListResult
                    status={stateFilter.status}
                    filter={stateFilter}
                    setFilter={setFilter}
                    listKeys="status"
                  />
                </StyledList>
              )}
            </>
          )}
        </FormGroup>
      </BoxGroupBox>
    )
  })
}

export const ListResult = ({ status, filter, setFilter, listKeys }) => {
  return arrayStatus.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table, isStandalone, isVersion }) => {
  const { id: codeId } = useParams()
  const dispatch = useDispatch()
  const initFilter = isStandalone
    ? standaloneFilter
    : isVersion
    ? versionFilter
    : defaultFilter
  const [filter, setFilter] = useState(initFilter)
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(initFilter)
  }, [open])

  return (
    <BoxDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <BoxHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </BoxHeadLabel>

      <Formik
        initialValues={filter}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={() => {
          if (isVersion) {
            dispatch(onSubmitVersionFilter(table, filter, codeId))
          } else dispatch(onSubmitFilter(table, filter, isStandalone))

          setFilterMemo(filter)
        }}
      >
        {({ setValues }) => (
          <Form
            style={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ListCheckbox
              arrayCheckbox={
                isStandalone
                  ? arrayStandaloneCheckbox
                  : isVersion
                  ? arrayVersionCheckbox
                  : arrayCheckbox
              }
              stateFilter={filter}
              setFilter={setFilter}
            />

            <BoxFooter>
              <Button
                data-testid="btn-clear"
                variant="outlined"
                onClick={() => setFilter(initFilter)}
              >
                ล้าง
              </Button>
              <Button
                type="submit"
                data-testid="btn-submit"
                onClick={() => {
                  setValues(filter)
                  dispatch(
                    setFilterTotal({
                      ...filter,
                      displayStartDate: false,
                    })
                  )
                }}
                variant="contained"
              >
                ยืนยัน
              </Button>
            </BoxFooter>
          </Form>
        )}
      </Formik>
    </BoxDrawer>
  )
}
export default FilterDrawer
