import { CContainer } from '../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { Typography } from '@mui/material'
import { setFieldValue } from '../../../../redux/slices/crud'
import { store } from '../../../../App'
import { setChangeRowsPerPage } from '../../../../redux/slices/table'
import { ROW_PAGE_PER_LIST } from '../../../../constants/table'
import { handleSetDataRows } from './handler/handleSetDataRows'

export const TicketDetailList = ({ ticketUuid = null }) => {
  const { page, sort, order, limit, rows } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
      rows: state.table.table.rows,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  const { isFetchTicketDetail } = useSelector((state) => state.crud)

  useEffect(() => {
    if (isFetchTicketDetail) {
      initialConfig({ ...initialCrudConfig, ticketUuid }, false)
      store.dispatch(
        setFieldValue({
          key: `isHideEditAndApprove`,
          value: true,
        }),
      )
    }
  }, [isFetchTicketDetail])

  useEffect(() => {
    const setTableProps = {
      handleSearch: '',
      onDownload: '',
      onDelete: '',
      isCheckBox: false,
      isShowPagination: false,
    }
    store.dispatch(
      setChangeRowsPerPage({
        limit: -1,
        rowsPerPage: ROW_PAGE_PER_LIST[3],
      }),
    )
    if (
      !_.isUndefined(crudConfig) &&
      crudConfig.moduleId === 'INVENTORY_TICKET_DETAIL' &&
      isFetchTicketDetail
    ) {
      fetchDataList(mutateFilterPropToBody, { setTableProps }).then(() => {
        handleSetDataRows()
      })
    }
  }, [limit, order, page, sort, crudConfig, isFetchTicketDetail])

  useEffect(() => {
    if (rows?.length > 0) {
      rows.map((item, index) => {
        store.dispatch(
          setFieldValue({
            key: `formData.products[${index}]`,
            value: item,
          }),
        )
      })
    }
  }, [rows])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      content={
        <Typography my={3} variant="h6">
          รายการทรัพย์สิน
        </Typography>
      }
      cardSx={{ px: 3, pb: 3 }}
    />
  )
}
