import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Tooltip from '@mui/material/Tooltip'
import useTheme from '@mui/system/useTheme'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleEnableButton,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead,
} from './Styled'
import _ from 'lodash'
import {
  E_EXAM_UPLOAD_STATUS,
  IMPORT_EXAMINER_TYPE,
  E_EXAM_UPLOAD_TEST_STATUS,
  EXAMINER_TEST_RESULT_TEXT,
} from '../../../../constants/eExamination'
import { useParams } from 'react-router-dom'
import { setEnableButton } from '../../../../redux/slices/dialog'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Truncate from '../../../../components/redux/Table/Truncate'

export const CheckDialog = () => {
  const { id: uuid } = useParams()
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const { importExaminerFile, importExaminerType } = useSelector(
    (state) => ({
      importExaminerFile: state.eExamList.importExaminerFile,
      importExaminerType: state.eExamList.importExaminerType,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const theme = useTheme()
  const uploadResult = _.get(importExaminerFile, 'uploadResult', [])
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const { successCount, passCount, notPassCount, missedCount } = useMemo(() => {
    let successCount = 0
    let passCount = 0
    let notPassCount = 0
    let missedCount = 0
    if (importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT) {
      const successList = result.filter((item) => item.status === 'SUCCESS')
      if (_.size(successList) == _.size(result)) {
        dispatch(setEnableButton())
      }

      successCount = successList.length
    } else {
      const successList = result.filter((item) => item.status === 'ACTIVE')
      if (_.size(successList) == _.size(result)) {
        dispatch(setEnableButton())
      }
      successCount = successList.length
      passCount = _.size(result.filter((item) => _.get(item, 'RESULT') === 'P'))
      notPassCount = _.size(
        result.filter((item) => _.get(item, 'RESULT') === 'F'),
      )
      missedCount = _.size(
        result.filter((item) => _.get(item, 'RESULT') === 'M'),
      )
    }

    return { successCount, passCount, notPassCount, missedCount }
  }, [result])

  const uploadSuccess = status === 'SUCCESS'

  return (
    <>
      {uploadSuccess ? (
        <SuccessDialogHeader type={importExaminerType} />
      ) : (
        <WarningDialogHeader />
      )}
      <StyledDialog>
        {!uploadSuccess && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              สำเร็จ <span>{successCount} รายการ</span>
              {' , '}
              ไม่สำเร็จ <span>{_.size(result) - successCount} รายการ</span>{' '}
            </Typography>
          </Box>
        )}
        {importExaminerType === IMPORT_EXAMINER_TYPE.TEST_RESULT && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              ผลสอบ <span>ผ่าน {passCount} รายการ</span> ,{' '}
              <span>ไม่ผ่าน {notPassCount} รายการ</span> ,{' '}
              <span>ขาดสอบ {missedCount} รายการ</span>
            </Typography>
          </Box>
        )}
        <StyledTable>
          <StyledTableHead type={importExaminerType}>
            <TableRow>
              {importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT ? (
                <>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>สถานะ</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 80 }}>
                    <Typography>No.</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>เลขบัตรประชาชน</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>คำนำหน้า</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>ชื่อ</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>นามสกุล</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>เพศ</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>วันที่เกิด</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>เดือนเกิด</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>ปี พ.ศ. เกิด</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>วุฒิการศึกษา</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>รหัสหน่วย</Typography>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>สถานะ</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 80 }}>
                    <Typography>No.</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>เลขบัตรประชาชน</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>คำนำหน้า</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>ชื่อ</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>นามสกุล</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>ผลสอบ</Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {result.map((item) => (
              <StyledTableData
                status={item.status}
                key={item.index}
                type={importExaminerType}
              >
                <TableCell sx={{ minWidth: 130 }}>
                  {item.status === 'SUCCESS' || item.status === 'ACTIVE' ? (
                    <Typography>
                      {importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT
                        ? E_EXAM_UPLOAD_STATUS[item.status]
                        : E_EXAM_UPLOAD_TEST_STATUS[item.status]}
                    </Typography>
                  ) : (
                    <Typography color="error" sx={{ display: 'flex', gap: 1 }}>
                      <Typography>
                        {importExaminerType ===
                        IMPORT_EXAMINER_TYPE.ANNOUNCEMENT
                          ? E_EXAM_UPLOAD_STATUS[item.status]
                          : E_EXAM_UPLOAD_TEST_STATUS[item.status]}
                      </Typography>
                      <Tooltip
                        title={
                          <ul style={{ margin: 0, marginLeft: '-20px' }}>
                            {_.get(item, 'error', []).map((err) => {
                              return (
                                <li
                                  key={err}
                                  style={{ display: 'list-item', p: 0 }}
                                >
                                  {err}
                                </li>
                              )
                            })}
                          </ul>
                        }
                        followCursor
                        componentsProps={{
                          tooltip: {
                            sx: {
                              background: theme?.palette?.blue?.azure,
                              color: theme?.palette?.common?.white,
                              boxShadow: theme?.palette?.boxShadow?.main,
                            },
                          },
                        }}
                      >
                        <ErrorOutline color="error" />
                      </Tooltip>
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <TruncateData>{'' + item.id}</TruncateData>
                </TableCell>
                {importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT ? (
                  <>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.idCard}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.prefixTH}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.firstNameTH}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.lastNameTH}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.gender}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.dayOfBirthDate}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.monthOfBirthDate}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.yearOfBirthDate}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.qualification}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.unitCode}</TruncateData>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.CID}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.TITLE}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.FNAME}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>{'' + item.LNAME}</TruncateData>
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }}>
                      <TruncateData>
                        {'' + EXAMINER_TEST_RESULT_TEXT[item.RESULT]}
                      </TruncateData>
                    </TableCell>
                  </>
                )}
              </StyledTableData>
            ))}
          </TableBody>
        </StyledTable>
        {successCount > 0 && successCount != _.size(result) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label={
                importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT ? (
                  <Typography>
                    อัปเดตสถานะผู้สอบที่ <span>สำเร็จ</span>{' '}
                    เป็นมีรอประกาศสิทธิ์
                  </Typography>
                ) : (
                  <Typography>
                    อัปเดตสถานะผู้สอบที่ <span>สำเร็จ</span> เป็น ผ่าน / ไม่ผ่าน
                    / ขาดสอบ
                  </Typography>
                )
              }
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(handleSubmitAdd(uuid, uploadResult, importExaminerType))
            }
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = ({ type }) => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">
        {type === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT
          ? 'สามารถอัปโหลดรายชื่อผู้มีสิทธิ์สอบได้ทั้งหมด'
          : 'สามารถอัปโหลดผลสอบได้ทั้งหมด'}
      </Typography>
    </Box>
  )
}

export const TruncateData = ({ children }) => {
  return (
    <>
      {children && children != '' ? (
        <Truncate>{'' + children}</Truncate>
      ) : (
        children
      )}
    </>
  )
}
