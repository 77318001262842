import React from 'react'

import { StyledDivider } from '../../../../../modules/ELearning/components/View/Styled'
import { Box } from '@mui/material'
import { Detail } from './Detail'
import { Courses } from './Courses'

export const Collection = () => {
  return (
    <>
      <Box>
        <Detail />
        <StyledDivider />
        <Courses />
      </Box>
    </>
  )
}
