import ExpandMore from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect, useMemo, useRef } from 'react'
import useTheme from '@mui/system/useTheme'
import { ButtonBoldStyle, ButtonColorStyle, ButtonPopperStyle } from '../Styled'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import { ColorBox } from 'mui-color'
import * as events from '../../event'
import _ from 'lodash'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const useOutside = (ref, setClose) => {
  const handleClickOutside = (e) => {
    if (ref.current === null) return null
    if (!ref.current.contains(e.target)) {
      setClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })
}

const ButtonPopper = ({
  propColor,
  propFontSize,
  propFontFamily,
  propFontWeight,
  Index,
  list,
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorColor, setAnchorColor] = useState(null)
  const [color, setColor] = useState(propColor)
  const [fontSize, setFontSize] = useState(propFontSize)
  const [fontFamily, setFontFamily] = useState(propFontFamily)
  const [fontWeight, setFontWeight] = useState(propFontWeight)
  const [anchorSelect, setAnchorSelect] = useState(false)

  const dispatch = useDispatch()

  const _refModal = useRef(null)
  const _refColor = useRef(null)

  const handleClick = (event) => {
    setAnchorColor(null)
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const handleColorClick = (event) => {
    setAnchorColor(anchorColor ? null : event.currentTarget)
  }

  const openColor = Boolean(anchorColor)
  const idColor = openColor ? 'color-popper' : undefined

  const palette = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: '#fff',
    pink: 'pink',
    darkblue: 'darkblue',
  }

  const fontList = [
    {
      labelText: 'DB Helvethaica X',
      label: 'DB Helvethaica X',
      value: 'Helvethaica',
    },
    { labelText: 'Baijam', label: 'Baijam', value: 'Baijam' },
    { labelText: 'Sarabun', label: 'Sarabun', value: 'Sarabun' },
  ]

  useEffect(() => {
    if (
      color !== propColor ||
      fontSize !== propFontSize ||
      fontFamily !== propFontFamily ||
      fontWeight !== propFontWeight
    ) {
      const tmpStyle = {
        ...list?.layout[Index].style,
        color: color,
        fontFamily: fontFamily,
        fontSize: `${fontSize}px`,
        fontWeight: fontWeight,
      }
      dispatch(
        events.ChangeStyleLayout({ list: list, style: tmpStyle, key: Index })
      )
    }
  }, [color, fontSize, fontFamily, fontWeight])

  const { formLayout } = useSelector(
    (state) => ({
      formLayout: state.eCertificateForm.formLayout,
    }),
    shallowEqual
  )

  const debouncedColor = useMemo(
    () => _.debounce((e) => setColor(e), 100),
    [formLayout]
  )

  const debouncedFontSize = useMemo(
    () => _.debounce((e) => setFontSize(e), 1000),
    [formLayout]
  )

  const isRefColorCloseModal = () => {
    setAnchorColor(false)
  }

  const isRefPaperCloseModal = () => {
    if (!anchorColor && !anchorSelect) setAnchorEl(false)
  }

  useOutside(_refColor, isRefColorCloseModal)
  useOutside(_refModal, isRefPaperCloseModal)

  return (
    <>
      <ButtonPopperStyle theme={theme} onClick={handleClick}>
        <Typography
          sx={{ display: 'flex', userSelect: 'none' }}
          variant="subtitle2"
        >
          Aa
        </Typography>
        <ExpandMore />
      </ButtonPopperStyle>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={4}
                sx={{ p: 1, width: '238px' }}
                ref={_refModal}
              >
                <Box>
                  <Select
                    id="level"
                    name="level"
                    type="basic"
                    disabled={false}
                    handleClick={() => setAnchorSelect(true)}
                    handleClose={() => setAnchorSelect(false)}
                    options={fontList}
                    handleChange={(e) =>
                      setFontFamily(_.get(e, 'target.value', ''))
                    }
                    value={fontFamily}
                    placeholder={'เลือก'}
                  ></Select>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                  <ButtonColorStyle
                    theme={theme}
                    color={color}
                    onClick={handleColorClick}
                  />
                  <Popper
                    id={idColor}
                    open={openColor}
                    anchorEl={anchorColor}
                    placement="bottom"
                  >
                    <Paper elevation={4} sx={{ p: 1 }} ref={_refColor}>
                      <ColorBox
                        onChange={(e) => debouncedColor(e.css.backgroundColor)}
                        defaultValue={color}
                        hideTextfield
                        palette={palette}
                      />
                    </Paper>
                  </Popper>

                  <TextInput
                    sx={{
                      minWidth: '90px',
                      display: 'flex',
                    }}
                    id="name"
                    name="name"
                    isShowTextError={false}
                    type="basic"
                    inputProps={{
                      type: 'number',
                      min: '0',
                      max: '1000',
                      onInput: events.handleNumberInput,
                    }}
                    disabled={false}
                    defaultValue={fontSize}
                    endAdornmentText="px"
                    onChange={(e) => debouncedFontSize(e.target.value)}
                  />
                  <ButtonBoldStyle
                    sx={{
                      backgroundColor:
                        fontWeight === 'bold'
                          ? 'primary.outlinedHoverBackground'
                          : 'primary.contrast',
                    }}
                    onClick={() =>
                      setFontWeight((o) => (o === 'bold' ? 'normal' : 'bold'))
                    }
                  >
                    <Typography color="text.secondary" variant="h5">
                      B
                    </Typography>
                  </ButtonBoldStyle>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  )
}

export default ButtonPopper
