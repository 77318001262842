export const assetType = [
  { label: 'Stock', value: 'stock' },
  { label: 'Stock ระบบ รหัสทรัพย์สิน', value: 'sku' },
  { label: 'Asset', value: 'asset' },
  { label: 'License & Software', value: 'license' },
]

export const assetTypeEnum = {
  STOCK: 'stock',
  STOCK_ASSET: 'sku',
  SKU: 'sku',
  ASSET: 'asset',
  LICENSE: 'license',
}

export const fromTypeEnum = {
  IMPORT_PRODUCT: 'IMPORT_PRODUCT',
}
