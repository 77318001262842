import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getCourseDetail,
  getELearningDetail,
  postCourseClassList,
} from '../../../../services/eLearning/learningPath/preview'
import { initialState } from './model'

const eLearningPathPreview = createSlice({
  name: 'eLearningPathPreview',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startCourseLoading: (state) => {
      state.isCourseLoading = true
    },
    stopCourseLoading: (state) => {
      state.isCourseLoading = false
    },
    startTrainingPlanLoading: (state) => {
      state.isTrainingPlanLoading = true
    },
    stopTrainingPlanLoading: (state) => {
      state.isTrainingPlanLoading = false
    },
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setExpand: (state, { payload }) => {
      state.isExpanded = payload
    },
    setInitialPreview: (state, { payload }) => {
      state.data = payload
    },
    setCourseType: (state, { payload }) => {
      state.courseType = payload
    },
    setSelectedCourse: (state, { payload }) => {
      state.selectedCourse = payload
      state.filterBody = initialState.filterBody
    },
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClass / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getELearningDetail.fulfilled, (state, { payload }) => {
        state.course = payload.data
      })
      .addCase(getELearningDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getCourseDetail.fulfilled, (state, { payload }) => {
        state.course = payload.data
      })
      .addCase(getCourseDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postCourseClassList.fulfilled, (state, action) => {
        const { meta, payload } = action
        const classList = _.get(payload, 'data.returnTrainingPlans', [])
        const isFilter = _.get(meta.arg, 'body.page', 1) == 1
        if (isFilter) state.classList = classList
        else state.classList = _.concat(state.classList, classList)
        state.totalClass = _.get(payload.data, 'getTotalTrainingPlans', 0)
      })
      .addCase(postCourseClassList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startCourseLoading,
  stopCourseLoading,
  startTrainingPlanLoading,
  stopTrainingPlanLoading,
  startLoadMore,
  stopLoadMore,
  setExpand,
  setInitialPreview,
  setCourseType,
  setSelectedCourse,
  setFilter,
  setLoadMore,
} = eLearningPathPreview.actions

export default eLearningPathPreview.reducer
