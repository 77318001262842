import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { store } from '../../../../../App'
import CloseIcon from '@mui/icons-material/Close'

const ActionEvaDashboard = ({ row, condition }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <TruncateText
        text={row.answer}
        onReadMore={handleReadMore}
        readMoreTxt={condition?.readMoreTxt ?? 'ดูเพิ่มเติม'}
        maxWidth={condition?.maxWidth ?? '600px'}
      />
    </Box>
  )
}

const handleReadMore = (text) => {
  store.dispatch(
    openDialog({
      type: 'confirm',
      title: (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>คำตอบ</Box>
          <CloseIcon
            onClick={() => {
              store.dispatch(closeDialog())
            }}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
            }}
          />
        </Box>
      ),
      message: text,
      agreeText: 'ตกลง',
      hideCancel: true,
      maxWidth: '500px',
      handleConfirm: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}

const TruncateText = ({ text, onReadMore, readMoreTxt, maxWidth }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  const handleClick = () => {
    if (onReadMore) {
      onReadMore(text)
    }
  }

  return (
    <Box display="flex">
      <Box
        ref={textRef}
        sx={{
          display: 'inline-block',
          maxWidth: maxWidth ? maxWidth : '500px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography variant="body1" component="span">
          {text}
        </Typography>
      </Box>
      {isOverflowing && (
        <Typography
          variant="body1"
          component="span"
          onClick={handleClick}
          sx={{
            fontWeight: 'bold',
            cursor: 'pointer',
            color: 'primary.main',
            marginLeft: '5px',
          }}
        >
          {readMoreTxt ?? 'read more'}
        </Typography>
      )}
    </Box>
  )
}

export default ActionEvaDashboard
