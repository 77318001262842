import React, { useState } from 'react'
import _ from 'lodash'
import { Box, Grid, Typography } from '@mui/material'
import { versionStatus } from '../../../../constants/eTesting'
import Dot from '../../../../components/redux/Dot'
import { StyledCard } from '../../Styled'
import { StyledContentRow, StyledDivider, StyledFooterText } from './Styled'
import {
  ROOM_FUNCTION,
  ROOM_TYPE,
} from '../../../../constants/MasterData/meetingRoom'
import { handleRoomLayout, handleScale } from './event'
import { shallowEqual, useSelector } from 'react-redux'
import { DialogShowImages } from './DialogShowImages'
import { formattedNumberToString } from '../../Form/event'

export const ContentRow = ({ title, content, children }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      {children ? children : <Typography variant="body1">{content}</Typography>}
    </StyledContentRow>
  )
}

export const CapacityRoom = ({ arrayRoomLayout }) => {
  return (
    <ContentRow title="รูปแบบการจัดห้อง">
      <ul style={{ margin: 0, marginLeft: '-10px', minWidth: 250 }}>
        {arrayRoomLayout?.map((item) => {
          const { id, capacity, label } = item
          return (
            <li key={id}>
              <Grid container spacing={3}>
                <Grid sx={{ paddingTop: `10 !important` }} item xs={6}>
                  <Typography variant="body1">{label}</Typography>
                </Grid>
                <Grid sx={{ paddingTop: `10 !important` }} item xs={6}>
                  <Typography variant="body1b">
                    {formattedNumberToString(capacity)} คน
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        })}
      </ul>
    </ContentRow>
  )
}

export const RoomFunction = (props) => {
  const { roomFunction, roomFunctionOther } = props
  return (
    <ContentRow title="ฟังก์ชันห้อง">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>{roomFunction}</label>
        <ul style={{ margin: 0, marginLeft: '-10px' }}>
          {roomFunctionOther && <li>{roomFunctionOther}</li>}
        </ul>
      </Box>
    </ContentRow>
  )
}

export const Equipment = ({ meetingRoomEquipment }) => {
  return (
    <ContentRow title="อุปกรณ์ที่มาพร้อมกับห้อง">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!_.isEmpty(meetingRoomEquipment) ? (
          <ul style={{ margin: 0, marginLeft: '-10px', minWidth: 250 }}>
            {meetingRoomEquipment?.map((item, index) => {
              const equipmentName = _.get(item, 'equipmentName', '')
              const count = _.get(item, 'quantity', 0)
              return (
                <li key={index}>
                  <Grid container spacing={3}>
                    <Grid sx={{ paddingTop: `10 !important` }} item xs={6}>
                      <Typography variant="body1">{equipmentName}</Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: `10 !important` }} item xs={6}>
                      <Typography variant="body1b"> {count}</Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            })}
          </ul>
        ) : (
          '-'
        )}
      </Box>
    </ContentRow>
  )
}

export const RoomType = (props) => {
  const { roomType, roomTypeOther } = props

  return (
    <ContentRow title="ประเภทห้อง">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!_.isEmpty(roomType) ? (
          <>
            <label>{roomType}</label>
            <ul style={{ margin: 0, marginLeft: '-10px' }}>
              {roomTypeOther && <li>{roomTypeOther}</li>}
            </ul>
          </>
        ) : (
          '-'
        )}
      </Box>
    </ContentRow>
  )
}

export const StatusContent = ({ status }) => {
  return (
    <ContentRow title="สถานะ">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot status={status} sx={{ mt: 1.5 }} />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}

export const FooterContent = (props) => {
  const { createdBy, createdAt, updatedBy, updatedAt } = props
  const createdLabel =
    _.isEmpty(createdBy) && _.isEmpty(createdAt)
      ? '-'
      : `${createdBy.trim()} ${createdAt}`
  const updatedLabel =
    _.isEmpty(updatedBy) && _.isEmpty(updatedAt)
      ? '-'
      : `${updatedBy.trim()} ${updatedAt}`

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledFooterText variant="caption" color="text.lightGray">
        สร้างโดย {createdLabel}
      </StyledFooterText>
      <StyledFooterText variant="caption" color="text.lightGray">
        แก้ไขล่าสุดโดย {updatedLabel}
      </StyledFooterText>
    </Box>
  )
}

const Content = ({ componentRef }) => {
  const { information } = useSelector(
    (state) => state.masterDataMeetingRoomForm,
    shallowEqual,
  )
  const {
    width,
    length,
    height,
    sqm,
    stationName,
    meetingRoomImages,
    meetingRoomEquipment,
    remark,
    roomName,
    roomFunction,
    roomFunctionOther,
    roomType,
    roomTypeOther,
    floor,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
  } = information

  const scale = handleScale({ width, length, height })
  const arrayRoomLayout = handleRoomLayout(information)
  const [openDialogImages, setOpenDialogImages] = useState(false)
  const [imgSelected, setImgSelected] = useState(0)

  return (
    <>
      <StyledCard id="view">
        {meetingRoomImages?.length > 0 && (
          <Box
            sx={{
              p: 3,
              pb: 0,
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              '& > .MuiBox-root': {
                width: 'calc(100% / 5 - 24px)',
                minWidth: 230,
                height: 125,
              },
              img: { objectFit: 'cover' },
            }}
          >
            {meetingRoomImages?.map((item, index) => (
              <Box
                sx={{
                  boxShadow: '1px 0px 5px 0px rgba(81, 81, 81, 0.30)',
                  cursor: 'pointer',
                  ':hover': {
                    transform: 'scale(1.15)',
                  },
                }}
                key={index}
              >
                <img
                  onClick={() => {
                    setOpenDialogImages(true)
                    setImgSelected(index)
                  }}
                  src={`${window.__env__.REACT_APP_API_URL}/file${item.imageKey}`}
                  width={'100%'}
                  height={'100%'}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ p: 3 }} ref={componentRef}>
          <ContentRow title="Station" content={stationName} />
          <ContentRow title="ชื่อห้อง" content={roomName} />
          <ContentRow title="ชั้น" content={floor} />
          <ContentRow
            title="ขนาดห้อง"
            content={
              !_.isNull(sqm)
                ? ` ${formattedNumberToString(sqm)} ตารางเมตร ${scale}`
                : '-'
            }
          />
          <CapacityRoom arrayRoomLayout={arrayRoomLayout} />
          <RoomFunction
            roomFunction={ROOM_FUNCTION[roomFunction]}
            roomFunctionOther={roomFunctionOther}
          />
          <Equipment meetingRoomEquipment={meetingRoomEquipment} />
          <RoomType
            roomType={ROOM_TYPE[roomType]}
            roomTypeOther={roomTypeOther}
          />
          <ContentRow
            title="Remark"
            content={!_.isEmpty(remark) ? remark : '-'}
          />
          <StatusContent status={status} />
          <StyledDivider />
          <FooterContent
            createdBy={createdBy}
            createdAt={createdAt}
            updatedBy={updatedBy}
            updatedAt={updatedAt}
          />
        </Box>
      </StyledCard>
      <DialogShowImages
        isOpen={openDialogImages}
        setOpen={setOpenDialogImages}
        imgSelected={imgSelected}
      />
    </>
  )
}

export default Content
