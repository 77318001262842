import React from 'react'
import _ from 'lodash'
import TruncateMarkup from 'react-truncate-markup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { versionStatus } from '../../../../../constants/eTesting'
import Dot from '../../../../../components/redux/Dot'
import { MODULE_TYPE } from '../../../../../constants/eLearning'
import { themeColor } from '../../../../../utils/themeData'
import { VideoPreview } from '../../Form/TypeContent/EmbedContent'
import {
  CRITERIA_TYPE,
  E_TESTING_TYPE,
} from '../../Form/TypeContent/ETesting/model'
import {
  StyledCardContent,
  StyledDivider,
} from '../../../components/View/Styled'
import { ContentRow, FooterContent } from '../../../components/View'
import { StyledCard } from '../../../Styled'
import {
  StyledFileBox,
  StyledTooltip,
  StyledTypography,
  StyledIconBox,
} from './Styled'
import { MODULE_TYPE_EN, MODULE_TYPE_TH } from './model'

const Content = ({ detail }) => {
  const moduleType = _.get(detail, 'moduleType', '')
  const typeField = _.camelCase(moduleType)
  const moduleTypeEN = MODULE_TYPE_EN[moduleType]
  const moduleTypeTH = MODULE_TYPE_TH[moduleType]
  const type = _.get(detail, 'moduleDetail.type', '')
  return (
    <StyledCard id="view">
      <StyledCardContent sx={{ p: 3 }}>
        <ContentRow title="ID" content={_.get(detail, 'moduleCode', '-')} />
        <ContentRow
          title="รูปแบบ"
          content={`${moduleTypeEN}${moduleTypeTH ? ` / ${moduleTypeTH}` : ''}`}
        />
        <ContentRow title="ชื่อโมดูล" content={_.get(detail, 'name', '-')} />
        <ContentRow
          title="รายละเอียดเพิ่มเติม"
          content={_.get(detail, 'description', '-') || '-'}
        />

        {(moduleType === MODULE_TYPE.E_TESTING ||
          moduleType === MODULE_TYPE.E_EVALUATION) && (
          <>
            <ContentRow
              title={`ประเภท${moduleTypeTH}`}
              content={MODULE_TYPE_EN[type] || '-'}
            />
            <ContentRow
              title={MODULE_TYPE_TH[moduleType]}
              content={_.get(detail, `${typeField}.name`, '-')}
            />
            {moduleType === MODULE_TYPE.E_TESTING && (
              <CriteriaContent detail={detail} />
            )}
          </>
        )}

        {moduleType === MODULE_TYPE.EMBED_CONTENT && (
          <>
            <ContentRow
              title="รูปแบบ Link"
              content={type === 'LINK' ? _.capitalize(type) : type}
            />
            <Box ml={40.5}>
              <VideoPreview
                type={type}
                value={_.get(detail, 'moduleDetail.value')}
              />
            </Box>
          </>
        )}

        {moduleType === MODULE_TYPE.LINK_TO && (
          <>
            <ContentRow
              title="ชื่อเว็บไซต์"
              content={_.get(detail, 'moduleDetail.webName', '-') || '-'}
            />
            <ContentRow
              title="Link"
              content={_.get(detail, 'moduleDetail.link', '-')}
            />
            <ContentRow
              title="คำอธิบาย Link"
              content={_.get(detail, 'moduleDetail.linkDescription', '-')}
            />
          </>
        )}

        {moduleType === MODULE_TYPE.UPLOAD_FILE && (
          <>
            <ContentRow
              title="จำนวนไฟล์ที่อัปโหลด"
              content={_.get(detail, 'eLearningModuleFileUpload', []).length}
            />
            <FileContent detail={detail} />
          </>
        )}

        <StatusContent detail={detail} />
        <StyledDivider sx={{ pt: 1.5 }} />
        <FooterContent title="สร้าง" field="created" detail={detail} />
        <FooterContent title="แก้ไขล่าสุด" field="updated" detail={detail} />
      </StyledCardContent>
    </StyledCard>
  )
}

export default Content

export const CriteriaContent = ({ detail }) => {
  const type = _.get(detail, 'moduleDetail.type', '')
  const criteriaType =
    type === E_TESTING_TYPE.STANDALONE
      ? _.get(detail, 'eTesting.testCriteriaType', '')
      : _.get(detail, 'moduleDetail.criteriaType', '')
  const criteriaPercent =
    type === E_TESTING_TYPE.STANDALONE
      ? _.get(detail, 'eTesting.criteriaPercent', null)
      : _.get(detail, 'moduleDetail.criteriaPercent', null)

  return (
    <ContentRow title="เกณฑ์การผ่าน">
      <Typography variant="body1">
        {criteriaType === CRITERIA_TYPE.REQUIRED && 'ต้องทำ'}
        {criteriaType === CRITERIA_TYPE.REQUIRED_PERCENT &&
          `ต้องทำ และผ่านตามเกณฑ์ (${criteriaPercent}% ของคะแนนทั้งหมด)`}
      </Typography>
    </ContentRow>
  )
}

export const FileContent = ({ detail }) => {
  const fileList = _.get(detail, 'eLearningModuleFileUpload', [])

  return (
    <ContentRow title="ชื่อไฟล์">
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {fileList.map((file, index) => {
          const splitName = file.oldFileName.split('.')
          const surfix = `...${splitName[0][splitName[0].length - 2]}${
            splitName[0][splitName[0].length - 1]
          }.${splitName[1]}`

          return (
            <StyledTooltip key={index} title={file.oldFileName}>
              <StyledFileBox>
                <StyledIconBox>
                  <img src={`/icon/${_.toLower(file.fileType)}.svg`} />
                </StyledIconBox>
                <Box
                  sx={{ width: 132, display: 'flex', flexDirection: 'column' }}
                >
                  <TruncateMarkup lines={1} ellipsis={surfix}>
                    <StyledTypography variant="body1b" color="primary">
                      {file.oldFileName}
                    </StyledTypography>
                  </TruncateMarkup>
                  <Typography variant="body3" color="text.lightGray">
                    {file.fileSize} MB
                  </Typography>
                </Box>
              </StyledFileBox>
            </StyledTooltip>
          )
        })}
      </Box>
    </ContentRow>
  )
}

export const StatusContent = ({ detail }) => {
  const isInProgress = _.get(detail, 'isInProgress', false)
  const status = isInProgress ? 'INPROGRESS' : _.get(detail, 'status', false)
  const color = {
    ACTIVE: themeColor?.success?.main,
    INACTIVE: themeColor?.error?.main,
    DELETED: themeColor?.text?.white,
    INPROGRESS: themeColor?.other?.ratingActive,
  }
  return (
    <ContentRow title="สถานะ">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot status={status} color={color[status]} sx={{ mt: 1.5 }} />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}
