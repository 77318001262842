import styled from '@mui/system/styled'

export const BadgeImage = styled('img')(({ theme, $disable }) => ({
  width: 56,
  height: 56,
  objectFit: 'cover',
  marginRight: theme.spacing(10),
  filter: `grayscale(${$disable ? '1' : '0'})`,
  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(5),
  },
}))
