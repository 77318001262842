// import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  //const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['DELETE'],
  // })

  return [
    {
      id: 'status',
      label: '',
      disablePadding: false,
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'codeId',
      label: 'ID',
      disablePadding: false,
    },
    {
      id: 'name',
      label: 'แบบทดสอบ',
      disablePadding: false,
    },
    {
      id: 'typeOfETesting',
      label: 'ประเภท',
      disablePadding: false,
    },
    {
      id: 'numberOfVersions',
      label: 'จำนวนเวอร์ชัน',
      disablePadding: false,
    },
    {
      id: 'updateByName',
      label: 'แก้ไขล่าสุดโดย',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `${crudConfig.modulePath}/version`,
      viewId: 'codeId',
    },
  ]
}
