import { TableBody, TableCell } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import TableRowReport from './TableRowReport'
import DetailsReport from './DetailsReport'
import { Fragment } from 'react'
import { useTheme } from '@mui/system'

const TableBodyReport = () => {
  const theme = useTheme()
  const { headCellsReport, reportsData } = useSelector(
    (state) => ({
      headCellsReport: state.monthlyPlan.report.headCellsReport,
      reportsData: state.monthlyPlan.report.reportsData,
    }),
    shallowEqual,
  )

  return (
    <TableBody>
      {_.map(reportsData, (dt, idx) => {
        const { details, workType } = dt

        return (
          <Fragment key={idx}>
            {workType && (
              <TableRowReport
                sx={{
                  '.MuiTableCell-root': {
                    backgroundColor: theme.palette.blue.oceanBlue,
                    color: theme.palette.common.white,
                  },
                }}
              >
                <TableCell
                  sx={{
                    py: 0,
                    px: 1,
                    fontSize: theme.typography.body2b.fontSize,
                    fontWeight: theme.typography.body2b.fontWeight,
                  }}
                  colSpan={headCellsReport.length}
                >
                  {workType}
                </TableCell>
              </TableRowReport>
            )}

            <DetailsReport details={details} />
          </Fragment>
        )
      })}
    </TableBody>
  )
}

export default TableBodyReport
