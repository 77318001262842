import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { StyledRow } from '../Styled'

export const StyledCardHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: theme.palette?.other?.divider,
  [theme.breakpoints.down('sm')]: { flexDirection: 'column-reverse'}
}))

export const StyledCardStatus = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export const StyledImageNone = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const StyledScoreRow = styled(StyledRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))
