import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import InputMask from 'react-input-mask'
import TimeChoosing from './TimeChoosing'
import Typography from '@mui/material/Typography'
import AlertDialog from '../../components/AlertDialog'

export default function TimePicker(props) {
  const {
    id,
    onChange,
    helperText,
    value,
    disabled,
    name,
    disabledStartTime,
    disabledEndTime,
    placeholder,
    timeFieldSx,
    dataTestId = 'handle-change',
  } = props

  const [hours, setHours] = useState(0)
  const [mins, setMins] = useState(0)
  const [dialog, setDialog] = useState({
    open: false,
    variant: 'fail',
    title: '',
    icon: '',
    content: '',
    onConfirmClick: null,
    onCancelClick: null,
  })
  const handleCloseDialog = () => {
    setDialog({ ...dialog, open: false })
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const popover = open ? 'simple-popover' : ''

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    checkValue()

    const textHours = hours <= 9 ? `0${hours}` : hours
    const textMins = mins <= 9 ? `0${mins}` : mins
    onChange({
      target: {
        value: `${textHours}:${textMins}`,
      },
    })
  }

  const handleChange = (e) => {
    onChange({
      target: {
        value: e.target.value,
      },
    })
  }

  let mask = '12:34'
  let formatChars = {
    1: '[0-2]',
    2: '[0-9]',
    3: '[0-5]',
    4: '[0-9]',
  }

  const alert = () => {
    setDialog({
      variant: 'timeFail',
      open: true,
      onConfirmClick: () =>
        setDialog({
          open: false,
          variant: 'timeFail',
        }),
    })
  }

  let beforeMaskedValueChange = (newState) => {
    checkValue()
    if (newState.value.startsWith('2')) formatChars['2'] = '[0-3]'
    else formatChars['2'] = '[0-9]'
    return { value: newState.value, selection: newState.selection }
  }

  const checkValue = () => {
    const checkStart = Number(disabledStartTime.replace(':', ''))
    const checkEnd = Number(disabledEndTime.replace(':', ''))
    if (value !== '') {
      if (disabledEndTime !== '') {
        if (id === 'endTime' || id === 'endTimeSK') {
          if (checkStart >= checkEnd || checkEnd <= checkStart) {
            alert()
            onChange({
              target: {
                value: '',
              },
            })
          }
        }
      }
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <TimeField
        sx={{ ...timeFieldSx }}
        data-testid={dataTestId}
        name={name}
        mask={mask}
        id={id}
        placeholder={placeholder ? placeholder : 'เวลา'}
        onChange={handleChange}
        disabled={disabled}
        formatChars={formatChars}
        beforeMaskedValueChange={(e) => beforeMaskedValueChange(e)}
        value={value}
        isError={helperText && helperText.includes('กรุณา')}
      />
      <IconButton
        data-testid={'handle-click'}
        sx={{ position: 'absolute', top: '0', right: '0' }}
        onClick={handleClick}
        color="primary"
        disabled={disabled}
      >
        <TimerOutlinedIcon />
      </IconButton>
      <Popover
        id={popover}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TimeChoosing
          setHours={setHours}
          setMins={setMins}
          hours={hours}
          mins={mins}
          Submit={() => handleClose()}
        />
      </Popover>
      {helperText && (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" color={'error'}>
            {helperText}
          </Typography>
        </Box>
      )}

      <AlertDialog
        data-testid={'dialog-handle'}
        open={dialog.open}
        setOpen={setDialog}
        onConfirmClick={dialog.onConfirmClick}
        handleClose={handleCloseDialog}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onCancelClick={dialog.onCancelClick}
      />
    </Box>
  )
}

const TimeField = styled(InputMask, {
  shouldForwardProp: (prop) => prop !== 'isError',
})(({ theme, isError, disabled }) => ({
  width: '100%',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  color: disabled
    ? theme?.palette?.text?.disabled
    : theme?.palette?.text?.secondary,
  border: '1px solid',
  borderColor: isError
    ? theme?.palette?.error?.main
    : theme.palette?.text?.silver,
  outline: 'none',
  background: disabled && theme?.palette?.action?.disabledBackground,
  '&:focus': { borderColor: theme?.palette?.primary?.main },
  paddingLeft: theme.spacing(1),
  '&::placeholder': {
    color: theme?.palette?.text?.lightGray,
  },
  fontFamily: 'Helvethaica',
  fontSize: theme.typography.body1.fontSize,
}))
