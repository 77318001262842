import React, { useState } from 'react'
import Appbar from '../Appbar/Appbar'
import {
  DrawerHeader,
  Drawer,
  DrawerMobile,
  StyledBackG,
} from '../Drawer/Drawer'
import Box from '@mui/material/Box'
import { useHistory, useLocation } from 'react-router-dom'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useTheme from '@mui/system/useTheme'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { callAPI, validatePermission } from '../../utils/lib'
import { useLocalStorage } from '@rehooks/local-storage'
import { themeColor } from '../../utils/themeData'
import { CRUDListFilterPath, countSpeakerBorrow } from '../../utils/apiPath'
import _ from 'lodash'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  TicketStatus,
} from '../../constants/stock'
import { transferByEnum } from '../../modules/Inventory/Transfer/Listing/enum/transferTypeEnum'

export const handleMenuClick = (
  i,
  menu,
  setMenu,
  history,
  open,
  setOpen,
  leftMenuLS,
  setLeftMenuLS,
  setApproverTotalCount,
  setRequesterTotalCount,
  setInventoryRequisitionTotalCount,
  setInventoryTransferTotalCount,
) => {
  const menuItem = menu[i]
  if (_.get(menuItem, 'list', []).length === 0) {
    history.push(menuItem.path)
  }
  handleChange({
    setApproverTotalCount,
    setRequesterTotalCount,
    setInventoryRequisitionTotalCount,
    setInventoryTransferTotalCount,
    open,
    setOpen,
  })
  const newItems = {
    ...menuItem,
    active: menu[i].list.length === 0 ? true : !menuItem?.active,
  }
  menu[i] = newItems
  leftMenuLS[i] = !menuItem?.active
  setLeftMenuLS(leftMenuLS)
  setOpen(true)
  setMenu([...menu])
}

export const redirectTo = (
  menu,
  setMenu,
  history,
  path,
  setOpen,
  leftMenuLS,
  setLeftMenuLS,
) => {
  const newMenu = menu.map((value) => {
    if (value?.list?.length === 0) {
      value.active = false
    }
    value.active = false
    return value
  })
  setOpen(false)
  history.push(path)
  setMenu(newMenu)
  leftMenuLS[0] = false
  setLeftMenuLS(leftMenuLS)
}

export const handleChange = ({
  setApproverTotalCount,
  setRequesterTotalCount,
  setInventoryRequisitionTotalCount,
  setInventoryTransferTotalCount,
  setOpen,
  open,
}) => {
  const bodyApprover = {
    typeRole: 'APPROVER',
  }
  const bodyReqeuster = {
    typeRole: 'REQUESTER',
  }
  const bodyRequisition = {
    limit: '100',
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    quickSearch: '',
    name: '',
    search: '',
    status: [],
    transferType: ['REQUISITION'],
    transferBy: ['ALL'],
    requisitionType: ['MONTHLY_PLAN'],
    station: [],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    product: '',
    qtyFrom: '',
    qtyTo: '',
    category: '',
    subCategory: '',
    createdBy: '',
    updatedBy: '',
    menu: true,
  }

  const bodyTransfer = {
    limit: 10,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    quickSearch: '',
    station: [],
    createdBy: '',
    updatedBy: '',
    search: '',
    status: [TicketStatus.WAITING_APPROVE],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    transferType: ['TRANSFER'],
    transferBy: [transferByEnum.TRANSFER_OTHER],
    menu: true,
  }

  callAPI({
    url: countSpeakerBorrow,
    method: 'POST',
    body: bodyApprover,
    onSuccess: (res) => {
      setApproverTotalCount(res.totalCount)
    },
  })
  callAPI({
    url: countSpeakerBorrow,
    method: 'POST',
    body: bodyReqeuster,
    onSuccess: (res) => {
      setRequesterTotalCount(res.totalCount)
    },
  })
  callAPI({
    url: CRUDListFilterPath,
    method: 'POST',
    body: bodyRequisition,
    headers: { 'x-type': 'INVENTORY_REQUISITION' },
    onSuccess: (res) => {
      setInventoryRequisitionTotalCount(
        res.countRequisitionMonthly + res.countRequisitionOther,
      )
    },
  })
  callAPI({
    url: CRUDListFilterPath,
    method: 'POST',
    body: bodyTransfer,
    headers: { 'x-type': 'INVENTORY_TRANSFER' },
    onSuccess: (res) => {
      setInventoryTransferTotalCount(res.requestCount)
    },
  })
  setOpen(!open)
}

const LeftMenu = ({ menu, setMenu, open, setOpen, children }) => {
  const theme = useTheme()
  let history = useHistory()
  let location = useLocation()
  const [isListMenu, setIsListMenu] = useState(false)
  const [approverTotalCount, setApproverTotalCount] = useState(0)
  const [requesterTotalCount, setRequesterTotalCount] = useState(0)
  const [inventoryRequisitionTotalCount, setInventoryRequisitionTotalCount] =
    useState(0)
  const [inventoryTransferTotalCount, setInventoryTransferTotalCount] =
    useState(0)

  return (
    <Box sx={{ justifyContent: 'flex-start' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {open && <StyledBackG open={open} onClick={() => setOpen(false)} />}

        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              minWidth: 55,
            },
          }}
        >
          <ListSX
            theme={theme}
            menu={menu}
            history={history}
            location={location}
            isListMenu={isListMenu}
            setMenu={setMenu}
            setIsListMenu={setIsListMenu}
            open={open}
            setOpen={setOpen}
            approverTotalCount={approverTotalCount}
            requesterTotalCount={requesterTotalCount}
            inventoryRequisitionTotalCount={inventoryRequisitionTotalCount}
            inventoryTransferTotalCount={inventoryTransferTotalCount}
            setApproverTotalCount={setApproverTotalCount}
            setRequesterTotalCount={setRequesterTotalCount}
            setInventoryRequisitionTotalCount={
              setInventoryRequisitionTotalCount
            }
            setInventoryTransferTotalCount={setInventoryTransferTotalCount}
          />
        </Drawer>
        <Appbar open={open} setOpen={setOpen} />
        <DrawerMobile variant="permanent" open={open}>
          <ListSX
            theme={theme}
            menu={menu}
            history={history}
            location={location}
            isListMenu={isListMenu}
            setMenu={setMenu}
            setIsListMenu={setIsListMenu}
            open={open}
            setOpen={setOpen}
          />
        </DrawerMobile>
        {children}
      </Box>
    </Box>
  )
}
export default LeftMenu

export const findClassName = (item) => {
  let result = 'body1'
  if (
    (_.get(item, 'list', []).length && _.get(item, 'active', false)) ||
    (_.get(item, 'list', []).length === 0 &&
      _.get(item, 'name', '') === 'จัดการหลักสูตร' &&
      _.get(item, 'active', false))
  )
    result = 'body1b'
  return result
}

export const MenuView = ({
  item,
  i,
  setMenu,
  menu,
  history,
  location,
  open,
  setOpen,
  approverTotalCount,
  requesterTotalCount,
  inventoryRequisitionTotalCount,
  inventoryTransferTotalCount,
  setApproverTotalCount,
  setRequesterTotalCount,
  setInventoryRequisitionTotalCount,
  setInventoryTransferTotalCount,
}) => {
  const [leftMenuLS, setLeftMenuLS] = useLocalStorage('leftMenu2')
  const list = _.get(item, 'list', [])
  const isList = list.length > 0
  const active = _.get(item, 'active', false)
  const isActive = isList
    ? list.some((itemList) => location.pathname.includes(itemList.path))
    : location.pathname.includes(item.path)
  return (
    <div key={'menu-' + i}>
      <ListItemButton
        sx={{
          px: 1.5,
          background:
            (!isList || !open) && isActive
              ? 'rgba(73, 118, 186, 0.08)'
              : 'unset',
        }}
        key={'item-btn-' + i}
        onClick={() =>
          handleMenuClick(
            i,
            menu,
            setMenu,
            history,
            open,
            setOpen,
            leftMenuLS,
            setLeftMenuLS,
            setApproverTotalCount,
            setRequesterTotalCount,
            setInventoryRequisitionTotalCount,
            setInventoryTransferTotalCount,
          )
        }
      >
        <ListItemIcon sx={{ minWidth: 44 }}>
          {item && item.icon && item.icon(isActive)}
        </ListItemIcon>
        <ListItemText
          primary={
            isActive ? (
              <Typography variant="body1b" sx={{ color: 'primary.main' }}>
                {_.get(item, 'name', '')}
              </Typography>
            ) : (
              <Typography variant="body1">{_.get(item, 'name', '')}</Typography>
            )
          }
        />
        {isList &&
          (active ? (
            <ExpandLess sx={{ color: isActive ? 'primary.main' : 'unset' }} />
          ) : (
            <ExpandMore />
          ))}
      </ListItemButton>
      {open && (
        <Collapse key={i} in={active} timeout="auto" unmountOnExit>
          {list &&
            list.map((menuList, itemIndex) => {
              return (
                <MenuItem
                  key={itemIndex}
                  i={itemIndex}
                  history={history}
                  location={location}
                  menuList={menuList}
                  menu={menu}
                  setMenu={setMenu}
                  open={open}
                  setOpen={setOpen}
                  approverTotalCount={approverTotalCount}
                  requesterTotalCount={requesterTotalCount}
                  inventoryRequisitionTotalCount={
                    inventoryRequisitionTotalCount
                  }
                  inventoryTransferTotalCount={inventoryTransferTotalCount}
                  leftMenuLS={leftMenuLS}
                  setLeftMenuLS={setLeftMenuLS}
                />
              )
            })}
        </Collapse>
      )}
    </div>
  )
}

export const handleActivePath = (location, menuList) => {
  const pathArr = _.get(location, 'pathname', '').split(menuList.path)
  const path = _.get(pathArr, '[1]', null)
  const firstLetter = _.get(path, '[0]', null)

  if (menuList.path == '/room-management') {
    return (
      path == '' ||
      (firstLetter == '/' &&
        !path.includes('/dashboard') &&
        !path.includes('/report'))
    )
  }
  if (menuList.path == '/manage-class') {
    return (
      path == '' ||
      (firstLetter == '/' &&
        !path.includes('/check-exam') &&
        !path.includes('/oic') &&
        !path.includes('/dashboard') &&
        !path.includes('/report'))
    )
  }
  if (menuList.path == '/report-course-setting') {
    return path == '' || (firstLetter == '/' && !path.includes('/dashboard'))
  }
  if (menuList.path == '/e-testing') {
    return (
      path == '' ||
      (firstLetter == '/' &&
        !path.includes('/check-exam') &&
        !path.includes('/report') &&
        !path.includes('/dashboard'))
    )
  }
  if (menuList.path == '/api-to-rcms') {
    return (
      path == '' ||
      (firstLetter == '/' &&
        !path.includes('/dashboard') &&
        !path.includes('/report'))
    )
  }
  return path == '' || firstLetter == '/'
}

export const MenuItem = ({
  i,
  menu,
  setMenu,
  history,
  location,
  menuList,
  setOpen,
  approverTotalCount,
  requesterTotalCount,
  inventoryRequisitionTotalCount,
  inventoryTransferTotalCount,
  leftMenuLS,
  setLeftMenuLS,
}) => {
  const isPathActive = handleActivePath(location, menuList)
  return (
    <div key={'item-' + i}>
      <List component="div" disablePadding>
        <ListItemButton
          sx={{ pl: 7, background: isPathActive && 'rgba(73, 118, 186, 0.08)' }}
          onClick={() =>
            redirectTo(
              menu,
              setMenu,
              history,
              menuList.path,
              setOpen,
              leftMenuLS,
              setLeftMenuLS,
            )
          }
        >
          <ListItemText
            sx={{ color: isPathActive ? 'primary.main' : 'text.primary' }}
            primary={
              <Typography variant={isPathActive ? 'body1b' : 'body1'}>
                {_.get(menuList, 'name', '')}
              </Typography>
            }
          />
          {_.get(menuList, 'name', '') === 'คำขอยืมวิทยากรจากโซนอื่น' && (
            <Badge number={approverTotalCount} />
          )}
          {_.get(menuList, 'name', '') === 'รายการยืมวิทยากร' && (
            <Badge number={requesterTotalCount} />
          )}
          {_.get(menuList, 'name', '') === 'การเบิก - คืนทรัพย์สิน' && (
            <Badge
              number={inventoryRequisitionTotalCount}
              isCheckInventory={true}
            />
          )}
          {_.get(menuList, 'name', '') === 'โอนย้าย' && (
            <Badge number={inventoryTransferTotalCount} />
          )}
        </ListItemButton>
      </List>
    </div>
  )
}
export const Badge = ({ number, isCheckInventory }) => {
  if (isCheckInventory) {
    const user = JSON.parse(localStorage.getItem('user'))
    const hasPermissionInventory = validatePermission({
      user: user,
      moduleType: INVENTORY_SETTING,
      permission: [
        STOCK_PERMISSION.ADMIN,
        STOCK_PERMISSION.MP,
        STOCK_PERMISSION.CREATE,
      ],
    })
    if (!hasPermissionInventory) return <></>
  }
  return (
    <>
      <div
        style={{
          width: '24px',
          lineHeight: '24px',
          borderRadius: '50%',
          textAlign: 'center',
          backgroundColor: themeColor?.secondary?.main,
          color: themeColor?.secondary?.contrast,
        }}
      >
        {number}
      </div>
    </>
  )
}

export const ListSX = ({
  theme,
  menu,
  setMenu,
  history,
  location,
  open,
  setOpen,
  approverTotalCount,
  requesterTotalCount,
  inventoryRequisitionTotalCount,
  inventoryTransferTotalCount,
  setApproverTotalCount,
  setRequesterTotalCount,
  setInventoryRequisitionTotalCount,
  setInventoryTransferTotalCount,
}) => {
  return (
    <List
      sx={{
        maxWidth: 300,
        pb: 0,
        boxShadow: themeColor?.shadow?.main,
        height: '100vh',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{
            boxShadow: themeColor?.shadow?.main,
            pr: 1,
            border: `1px solid ${themeColor.shadow.main} `,
            maxHeight: '71px',
            minWidth: '45px',
          }}
        >
          <DrawerHeader>
            <Box
              sx={{
                width: 300,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mr: 1.5,
                  [theme?.breakpoints.up('sm')]: {
                    display: 'none',
                  },
                }}
              >
                <CloseIcon
                  onClick={() => setOpen(false)}
                  width="24"
                  height="24"
                  color="primary"
                  sx={{ mt: 3, mr: 2 }}
                />
                <Divider
                  sx={{
                    maxHeight: '71px',
                    backgroundColor: 'primary.outlinedHoverBackground',
                  }}
                  orientation="vertical"
                />
              </Box>
              <Box
                sx={{
                  mt: 1.5,
                }}
              >
                <img src="/logo/logo_app.png" alt="AMS" height="48px" />
              </Box>
              <IconButton
                onClick={() =>
                  handleChange({
                    setApproverTotalCount,
                    setRequesterTotalCount,
                    setInventoryRequisitionTotalCount,
                    setInventoryTransferTotalCount,
                    open,
                    setOpen,
                  })
                }
                sx={{
                  ml: 16,
                  mt: 2,
                  height: 36,
                  [theme?.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
              >
                {open ? (
                  <svg height="17" viewBox="0 0 19 17">
                    <path
                      d="M19 14.9998V16.9998H1V14.9998H19ZM4.596 0.903809L6.01 2.31781L2.828 5.49981L6.01 8.68181L4.596 10.0958L0 5.49981L4.596 0.903809ZM19 7.99981V9.99981H10V7.99981H19ZM19 0.999809V2.99981H10V0.999809H19Z"
                      fill="#00008F"
                    />
                  </svg>
                ) : (
                  <svg height="17" viewBox="0 0 19 17">
                    <path
                      d="M18 15.0003V17.0003H0V15.0003H18ZM14.404 0.904297L19 5.5003L14.404 10.0963L12.99 8.6823L16.172 5.5003L12.99 2.3183L14.404 0.904297ZM9 8.0003V10.0003H0V8.0003H9ZM9 1.0003V3.0003H0V1.0003H9Z"
                      fill="#00008F"
                    />
                  </svg>
                )}
              </IconButton>
            </Box>
          </DrawerHeader>
        </ListSubheader>
      }
    >
      {menu?.length &&
        menu.map((item, i) => (
          <MenuView
            key={i}
            item={item}
            i={i}
            setMenu={setMenu}
            menu={menu}
            history={history}
            location={location}
            open={open}
            setOpen={setOpen}
            approverTotalCount={approverTotalCount}
            requesterTotalCount={requesterTotalCount}
            inventoryRequisitionTotalCount={inventoryRequisitionTotalCount}
            inventoryTransferTotalCount={inventoryTransferTotalCount}
            setApproverTotalCount={setApproverTotalCount}
            setRequesterTotalCount={setRequesterTotalCount}
            setInventoryRequisitionTotalCount={
              setInventoryRequisitionTotalCount
            }
            setInventoryTransferTotalCount={setInventoryTransferTotalCount}
          />
        ))}
    </List>
  )
}
