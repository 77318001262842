import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Router } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { DialogContextProvider } from './context/dialog-context'
import Keycloak from './keycloak'
import { theme } from './utils/theme'
import RoutePage from './Route'
import { callAPI } from './utils/lib'
import AlertDialog from './components/AlertDialog'
import { Provider } from 'react-redux'
import { setupPersistor, setupStore } from './redux/config/configStore'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { AuthProvider, useAuth } from 'react-oidc-context'
import _ from 'lodash'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { handleLogout } from './components/Appbar/Appbar'
import OneAccount from './OneAccount'
import appHistory from './appHistory'

export const store = setupStore()
const persistor = setupPersistor(store)

// for jest test
export const getStore = (preloadedState) => setupStore(preloadedState)

export const instanceKeycloak = axios.create({
  baseURL: `${Keycloak.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 10000,
})

export const instanceOneAccount = axios.create({
  baseURL: `${OneAccount.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 10000,
})

const App = (props) => {
  const { user } = props
  const auth = useAuth()

  if (auth.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: '99999',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={30} />
        <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
      </Box>
    )
  }

  return (
    <>
      <Router history={appHistory}>
        <RoutePage user={user} />
      </Router>
    </>
  )
}

const DialogContextProviders = () => {
  const auth = useAuth()
  const [user, setUser] = useLocalStorage('user')
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    title: 'ไม่มีสิทธิ์',
    content: 'Permission Denied กรุณาติดต่อผู้ดูแลระบบ',
    variant: 'fail',
    onConfirmClick: () => handleLogout(auth),
  })

  useEffect(() => {
    return auth.events.addUserLoaded((authorization) => {
      if (!_.isEmpty(authorization?.access_token) && _.isEmpty(user)) {
        axios.defaults.baseURL = window.__env__.REACT_APP_API_URL
        axios.defaults.headers = {
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          Authorization: `${authorization.token_type} ${authorization?.access_token}`,
        }
        setLoadingProfile(true)
        localStorage.setItem('refresh_token', authorization.refresh_token)
        localStorage.setItem(
          'token',
          `${authorization.token_type} ${authorization?.access_token}`,
        )
        callAPI({
          url: 'staffprofile',
          method: 'get',
          onSuccess: (res) => {
            setUser(res)
            setLoadingProfile(false)
          },
          onError: () => {
            setDialog({
              open: true,
              title: dialog.title,
              content: dialog.content,
              variant: dialog.variant,
              onConfirmClick: dialog.onConfirmClick,
            })
          },
        })
      }
    })
  }, [auth.events, user])

  axios.defaults.baseURL = window.__env__.REACT_APP_API_URL
  const onFocus = () => {
    if (_.isEmpty(user) && window.location.pathname !== '/') {
      window.location.href = '/'
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [user])

  const handleSwitchFromLearner = () => {
    if (_.isNull(user)) {
      handleLogout(auth, true)
    } else {
      window.location = '/manage/staff-profile'
    }
  }

  useEffect(() => {
    if (window?.location?.pathname !== '/switch-from-learner') return
    handleSwitchFromLearner()
  }, [user, window?.location?.pathname])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DialogContextProvider>
            {!loadingProfile && <App user={user} />}
            <AlertDialog
              open={dialog.open}
              title={dialog.title}
              content={dialog.content}
              variant={dialog.variant}
              onConfirmClick={dialog.onConfirmClick}
            />
          </DialogContextProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

export const Root = () => {
  const getKey = () => {
    if (window.__env__.ENV === 'DEV') {
      return Keycloak
    }
    return OneAccount
  }
  return (
    <AuthProvider {...getKey()}>
      <DialogContextProviders />
    </AuthProvider>
  )
}
export default App
