import _ from 'lodash'
import {
  resetErrorUpload,
  setChangeBody,
  setErrorUpload,
  startUploadLoading,
  stopUploadLoading,
} from '../../../../../../redux/slices/manageContent/elearning'
import { postUploadImage } from '../../../../../../services/manageContent'

export const handleSwitchTabBody = (e, keyField) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  const payload = { value: checked, key: `${keyField}.${key}` }
  dispatch(setChangeBody(payload))
}

export const handleInputTabBody = (e, keyField) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  const payload = { value, key: `${keyField}.${key}` }

  dispatch(setChangeBody(payload))
}

export const handleUploadImage = (props) => async (dispatch) => {
  const { e, keyField, setValues } = props
  if (e.target.files.length === 0) return

  const imageName = _.get(e, 'target.files[0].name', undefined)

  if (!imageName) return

  const getExtensionImage = imageName.split('.').pop()
  const sizeFile = _.get(e, 'target.files[0].size', 0)

  if (!checkTypeFile(getExtensionImage)) {
    dispatch(
      setErrorUpload({ upload: 'นามสกุลไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' }),
    )
    return
  }
  if (sizeFile > 2000000) {
    dispatch(
      setErrorUpload({
        upload: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
      }),
    )
    return
  }

  const body = new FormData()
  body.append('file', e.target.files[0])

  const upload = document.getElementById(`${keyField}-upload`)
  upload.style.width = '0%'

  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted =
        (progressEvent.loaded / progressEvent.total) * 100
      upload.style.width = `${percentCompleted}%`
    },
  }

  dispatch(startUploadLoading(keyField))
  const response = await dispatch(postUploadImage({ body, config }))
  dispatch(stopUploadLoading(keyField))
  const data = _.get(response, 'payload.data.key', undefined)
  if (data) {
    setValues((prev) => ({ ...prev, [`${keyField}.image`]: data }))
    const payload = { value: data, key: `${keyField}.image` }
    dispatch(setChangeBody(payload))
    dispatch(resetErrorUpload())
  } else {
    dispatch(
      setErrorUpload({ upload: 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง' }),
    )
  }
}

export const checkTypeFile = (type) => {
  return type === 'jpg' || type === 'jpeg' || type === 'png'
}

export const handleDelete = (keyField) => async (dispatch) => {
  const payload = { value: '', key: `${keyField}.image` }
  dispatch(setChangeBody(payload))
}
