import React from 'react'
import Typography from '@mui/material/Typography'
import { Dot } from '../Styled'
import { Row } from 'src/components/Layout/Styled'

const RoomRowData = ({ color, dotColor, label, data, middleData }) => {
  return (
    <Row
      sx={{
        justifyContent: 'space-between',
        borderRadius: '24px',
        background: color,
        mt: 1,
        p: '3.5px 8px 3.5px 4px',
      }}
    >
      <Row>
        <Dot background={dotColor} />
        <Typography variant="body2b" sx={{ minWidth: 145, mr: '18px' }}>
          {label}
        </Typography>
        {middleData && <Typography variant="body2b">{middleData}</Typography>}
      </Row>
      <Typography variant="body2b" sx={{ minWidth: 'fit-content' }}>
        {data}
      </Typography>
    </Row>
  )
}

export default RoomRowData
