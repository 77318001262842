import axios from 'axios'
import _ from 'lodash'
import { prospectProfileExamHistory } from '../../../../../../utils/apiPath'
import {
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/manage/agentProfile'
import { EXAMINER_EVENT_STATUS_TH } from '../../../../../../constants/eExamination'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'author',
    label: 'ผู้ทำรายการ',
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่สมัคร',
    hideSortIcon: true,
  },
]

export const fetchExamHistory =
  ({ uuid, setData, events }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const page = _.get(events, 'page', 0)
    const body = {
      prospectUuid: uuid,
      limit: _.get(events, 'limit', 100),
      page: page <= 0 ? 1 : page,
    }

    try {
      await axios
        .post(prospectProfileExamHistory, body)
        .then((response) => {
          const result = _.map(
            _.get(response, 'data.result', []) ?? [],
            (result) => {
              return {
                ...result,
                event: EXAMINER_EVENT_STATUS_TH[result?.event],
              }
            },
          )
          setData({ ...response?.data, result })
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(stopLoading())
    }
  }
