import { Box, Card, CardContent } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import RankingCard from '../ModuleCard/RankingCard'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import { prepareTopList } from '../../handler/prepareTopList'

const TopCourse = () => {
  const { overview } = useSelector(
    (state) => ({
      overview: state.crud.mixModuleDashboard?.overview,
    }),
    shallowEqual,
  )

  const topListAllCourse = prepareTopList(overview?.topListAllCourse ?? [], 5)
  const topListExpense = prepareTopList(overview?.topListExpense ?? [], 5)
  const topListAgencyCourse = prepareTopList(
    overview?.topListAgencyCourse ?? [],
    5,
  )
  const topListBancaCourse = prepareTopList(
    overview?.topListBancaCourse ?? [],
    5,
  )
  const topListKtbCourse = prepareTopList(overview?.topListKtbCourse ?? [], 5)
  const topListOtherCourse = prepareTopList(
    overview?.topListOtherCourse ?? [],
    5,
  )

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={'Top อันดับหลักสูตร'}
            type={''}
            contentId="topCourse"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              flexWrap="wrap"
            >
              <RankingCard
                title={rankingTitle('จัดสอน', 'รวม')}
                type="rankingCourse"
                list={topListAllCourse}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
              />
              <RankingCard
                title={rankingTitle('ค่าใช้จ่าย', 'รวม')}
                type="rankingBudget"
                list={topListExpense}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
              />
              <RankingCard
                title={rankingTitle('จัดสอน', 'Agency')}
                type="rankingCourseAgency"
                list={topListAgencyCourse}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
                colorList={'#32A287'}
                backgroundList={'#EBF6F3'}
                nameColor={'#000'}
              />
              <RankingCard
                title={rankingTitle('จัดสอน', 'BANCA')}
                type="rankingCourseBanca"
                list={topListBancaCourse}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
                colorList={'#FFE66D'}
                backgroundList={'#FFF4C0'}
                nameColor={'#000'}
              />
              <RankingCard
                title={rankingTitle('จัดสอน', 'KTB')}
                type="rankingCourseKtb"
                list={topListKtbCourse}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
                colorList={'#99C2FF'}
                backgroundList={'#D4E5FF'}
                nameColor={'#000'}
              />
              <RankingCard
                title={rankingTitle('จัดสอน', 'Other')}
                type="rankingCourseOther"
                list={topListOtherCourse}
                header1="ชื่อหลักสูตร"
                header2=""
                boxSx={{
                  width: {
                    sm: '100%',
                    md: '49%',
                    lg: '49%',
                  },
                }}
                hideHeader2Column={true}
                colorList={'#B49CE4'}
                backgroundList={'#E9DEFF'}
                nameColor={'#000'}
              />
            </Box>
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

const rankingTitle = (text, type) => {
  return (
    <>
      5 หลักสูตร <b>{text}</b> มากที่สุด <b>({type})</b>
    </>
  )
}

export default TopCourse
