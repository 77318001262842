import React from 'react'
import useTheme from '@mui/system/useTheme'
import { Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { StyledColumn, StyledRow } from '../../../../modules/Styled'
import { convertFormatDateTime, isMediaWidthMoreThan } from '../../../../utils/lib'
import { SubmitButton } from './SubmitButton'
import { CancelButton } from './CancelButton'

const createLabel = (user, time) => {
  if (!user) return '-'
  return (
    user +
    ' ' +
    convertFormatDateTime({
      value: time,
      type: 'dateTime',
    })
  )
}

const getFormData = (state) => ({
  createdBy: state.crud.formData.createdBy,
  createdAt: state.crud.formData.createdAt,
  updatedBy: state.crud.formData.updatedBy,
  updatedAt: state.crud.formData.updatedAt,
})

const FormFooter = ({ handleSubmitClick, bodyMutation, disabled }) => {
  const theme = useTheme()
  const history = useHistory()

  const { createdBy, createdAt, updatedBy, updatedAt } = useSelector(
    getFormData,
    shallowEqual,
  )
  const isNotSm = isMediaWidthMoreThan('sm')

  const createdLabel = createLabel(createdBy, createdAt)
  const updatedLabel = createLabel(updatedBy, updatedAt)

  return (
    <StyledRow
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: isNotSm ? 'row' : 'column',
      }}
    >
      <StyledRow sx={{ gap: theme.spacing(3) }}>
        <SubmitButton
          disabled={disabled}
          handleSubmitClick={() => handleSubmitClick(bodyMutation)}
        />
        <CancelButton onClick={history.goBack} />
      </StyledRow>

      <StyledColumn
        sx={{
          minWidth: '40%',
          alignItems: isNotSm ? 'flex-end' : 'flex-start',
        }}
      >
        <Typography variant="body3" color="text.lightGray">
          สร้างโดย {createdLabel}
        </Typography>
        <Typography variant="body3" color="text.lightGray">
          แก้ไขล่าสุดโดย {updatedLabel}
        </Typography>
      </StyledColumn>
    </StyledRow>
  )
}

export default FormFooter
