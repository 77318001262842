import { startLoading, stopLoading } from '../../../../redux/slices/table'
import { downloadFile } from '../../../../services/util'
import { store } from '../../../../App'
import { budgetManagementDownload } from '../../../../utils/apiPath'

export const onDownload = async (selected, sort, order) => {
  const { crudConfig } = store.getState().crud
  store.dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    budgetManagementUuidList: selected,
  }

  await store.dispatch(
    downloadFile({
      url: budgetManagementDownload,
      body: body,
      fileName: `รายการ ${crudConfig.moduleTitleFull}.xlsx`,
    }),
  )
  store.dispatch(stopLoading())
}
