import React from 'react'
import _ from 'lodash'
import { StyledRow } from '../../Styled'
import { StyledList } from '../../../../components/FilterDrawer/Styled'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useDispatch } from 'react-redux'
import { handleChange } from '../event'

export const ListResult = ({ filter, arrayResult, listKeys }) => {
  const dispatch = useDispatch()
  return arrayResult.map((item, index) => {
    const list = _.get(item, 'list', [])
    return (
      <>
        <FormControlLabel
          key={index}
          label={item.label}
          control={
            <Checkbox
              inputProps={{ 'data-testid': `check-${item.name}` }}
              name={item.name}
              checked={_.get(filter[listKeys], item.name, false)}
              onChange={(e) => {
                dispatch(
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    listKey: listKeys,
                    filter,
                  }),
                )
              }}
            />
          }
        />
        {filter?.roomStatus[item.name] && item?.type == 'checkbox' && (
          <StyledList sx={{ mx: 2 }}>
            {_.map(list, (li, idx) => (
              <>
                <FormControlLabel
                  key={idx}
                  label={li.label}
                  control={
                    <Checkbox
                      inputProps={{ 'data-testid': `check-${li.name}` }}
                      name={li.name}
                      checked={_.get(filter[listKeys], li.name, false)}
                      onChange={(e) => {
                        dispatch(
                          handleChange({
                            value: e.target.checked,
                            key: e.target.name,
                            listKey: listKeys,
                            filter,
                          }),
                        )
                      }}
                    />
                  }
                />
                {filter?.roomStatus[li.name] && li?.type === 'time' && (
                  <StyledRow sx={{ gap: 2 }}>
                    <TimePicker
                      timeFieldSx={{ borderRadius: 2 }}
                      id="startTime"
                      name="startTime"
                      value={filter?.startTime}
                      disabledStartTime={''}
                      disabledEndTime={''}
                      onChange={(e) =>
                        dispatch(
                          handleChange({
                            value: e.target.value,
                            key: 'startTime',
                            filter,
                          }),
                        )
                      }
                      placeholder={'เริ่มต้น'}
                    />
                    <TimePicker
                      timeFieldSx={{ borderRadius: 2 }}
                      id="endTime"
                      name="endTime"
                      value={filter?.endTime}
                      disabledStartTime={''}
                      disabledEndTime={''}
                      onChange={(e) =>
                        dispatch(
                          handleChange({
                            value: e.target.value,
                            key: 'endTime',
                            filter,
                          }),
                        )
                      }
                      placeholder={'สิ้นสุด'}
                    />
                  </StyledRow>
                )}
              </>
            ))}
          </StyledList>
        )}
        {filter?.dateRange?.timeRange &&
          item.name != 'inProgressChecked' &&
          item.name != 'completedChecked' &&
          item.name != 'cancelChecked' && (
            <StyledRow sx={{ gap: 2 }}>
              <TimePicker
                timeFieldSx={{ borderRadius: 2 }}
                id="startTime"
                name="startTime"
                value={filter?.startTime}
                disabledStartTime={''}
                disabledEndTime={''}
                onChange={(e) => {
                  dispatch(
                    handleChange({
                      value: e.target.value,
                      key: 'startTime',
                      filter,
                    }),
                  )
                }}
                placeholder={'เริ่มต้น'}
              />
              <TimePicker
                timeFieldSx={{ borderRadius: 2 }}
                id="endTime"
                name="endTime"
                value={filter?.endTime}
                disabledStartTime={''}
                disabledEndTime={''}
                onChange={(e) => {
                  dispatch(
                    handleChange({
                      value: e.target.value,
                      key: 'endTime',
                      filter,
                    }),
                  )
                }}
                placeholder={'สิ้นสุด'}
              />
            </StyledRow>
          )}
      </>
    )
  })
}
