import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTruncateBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTruncated',
})(({ maxWidth, isTruncated }) => ({
  maxWidth: maxWidth,
  display: 'flex',
  flexDirection: 'row',
  cursor: isTruncated ? 'pointer' : 'default',
  wordBreak: 'break-all',
  verticalAlign: 'middle',
}))
