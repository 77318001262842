import { Box, Card, Divider } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: theme?.spacing(3),
  marginTop: theme?.spacing(3),
}))

export const StyledRow = styled(Box)(({ theme, isMobile }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: isMobile ? 0 : theme.spacing(3),
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette?.text?.mercury,
  borderStyle: 'dashed',
}))
