import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import _ from 'lodash'
import { HeaderSection } from './Styled'
import { handleScroll, handleSubmit, handleView } from './events'
import { useParams } from 'react-router-dom'

export const listBreadcrumbEdit = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล Prospect',
    link: '/manage/prospect-profile',
    pointer: true,
  },
  { title: 'แก้ไขข้อมูล Prospect', link: '/', pointer: false },
]

export const listBreadcrumbEditCsUser = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล CS User',
    link: '/manage/cs-user-profile',
    pointer: true,
  },
  { title: 'แก้ไขข้อมูล CS User', link: '/', pointer: false },
]

const Index = ({ type, setCheckClick }) => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual
  )
  const { id: uuid } = useParams()

  const fullName = `${_.get(data, 'fullName', '')}`

  const dispatch = useDispatch()

  const [headerStyle, setHeaderStyle] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll(setHeaderStyle))
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setHeaderStyle])

  return (
    <HeaderSection headerStyle={headerStyle}>
      <Box>
        <Typography variant="h4">
          {`แก้ไขข้อมูล ${
            type === 'CS' ? 'CS User' : 'Prospect'
          } : ${fullName}`}
        </Typography>
        {!headerStyle && (
          <Breadcrumbs
            menuList={
              type === 'CS' ? listBreadcrumbEditCsUser : listBreadcrumbEdit
            }
          />
        )}
      </Box>

      <Box sx={{ gap: 3, display: 'flex' }}>
        <Button
          variant="outlined"
          size="m"
          onClick={() => handleView(uuid, type)}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="m"
          onClick={() => {
            setCheckClick(true)
            dispatch(handleSubmit(data, uuid, type))
          }}
        >
          บันทึก
        </Button>
      </Box>
    </HeaderSection>
  )
}

export default Index
