import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import UploadFileComp from '../../../../../../components/UploadFile'
import { UPLOAD_TYPE } from '../../../../../../constants/eLearning'
import { StyledFormCard } from '../../../../Styled'
import StatusSwitch from '../StatusSwitch'
import { getMaxFileSize } from '../events'
import { handleSetUploadFile } from './events'

const UploadFile = () => {
  const dispatch = useDispatch()
  const { fileModule, maxFileSize, onGoing } = useSelector(
    (state) => ({
      fileModule: state.uploadFile.formData.eLearningModule,
      maxFileSize: getMaxFileSize(),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const isUploadLoading = _.get(fileModule, 'isUploading', false)
  const files = _.get(fileModule, 'files', [])

  useEffect(() => {
    dispatch(handleSetUploadFile(files))
  }, [files])

  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <UploadFileComp
        isMultiple
        isShowDownloadable
        label="Upload File"
        uploadType={UPLOAD_TYPE.GENERAL}
        maxFileSize={maxFileSize}
        accept={{
          'application/msword': ['.doc'],
          'application/pdf': ['.pdf'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            ['.docx'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            ['.pptx'],
          'application/zip': ['.zip'],
        }}
        acceptLabel=".ppt, .pptx, .pdf, .doc, .docx, .zip"
        fieldName="eLearningModule"
        sx={{ border: 'unset', boxShadow: 'unset', p: 0 }}
        disabled={onGoing}
      />

      <Divider />

      <StatusSwitch />
    </StyledFormCard>
  )
}

export default UploadFile
