import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่เริ่มต้นหลักสูตร',
    nameCheckBox: 'dateFilterChecked',
    nameInput: 'dateFilter',
    placeholder: 'เลือกวันที่',
    dateStateStart: 'dateFilterStartDate',
    dateStateFinish: 'dateFilterFinishDate',
    dateStateDisplay: 'dateFilterDisplayDate',
    type: 'daterange',
  },
  {
    label: 'Course Code',
    nameCheckBox: 'courseCodeChecked',
    nameInput: 'courseCode',
    type: 'select_dropdown',
    placeholder: 'เลือก Course Code',
    isGroup: true,
  },
  {
    label: 'Course Name',
    nameCheckBox: 'courseNameChecked',
    nameInput: 'courseName',
    type: 'select_dropdown',
    placeholder: 'เลือก Course Name',
    isGroup: true,
  },
  {
    label: 'Training Date',
    nameCheckBox: 'trainingDateChecked',
    nameInput: 'trainingDate',
    placeholder: 'เลือก Training Date',
    dateStateStart: 'trainingDateStartDate',
    dateStateFinish: 'trainingDateFinishDate',
    dateStateDisplay: 'trainingDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'Trainer Name',
    nameCheckBox: 'trainerNameChecked',
    nameInput: 'trainerName',
    type: 'select_dropdown',
    placeholder: 'เลือก Trainer Name',
  },
  {
    label: 'Distribution Channel',
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    type: 'select_dropdown',
    placeholder: 'เลือก Distribution Channel',
  },
  {
    label: 'วันที่ทำแบบประเมิน',
    nameCheckBox: 'userResponseDateChecked',
    nameInput: 'userResponseDate',
    placeholder: 'เลือกวันที่',
    dateStateStart: 'userResponseDateStartDate',
    dateStateFinish: 'userResponseDateFinishDate',
    dateStateDisplay: 'userResponseDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'Department',
    nameCheckBox: 'departmentChecked',
    nameInput: 'department',
    type: 'select_dropdown',
    placeholder: 'เลือก Department',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'select_dropdown',
    placeholder: 'เลือก Station',
  },
]

export const defaultFilter = {
  dateFilterChecked: true,
  dateFilterStartDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  dateFilterFinishDate: new Date(dayjs().format('YYYY-MM-DD')),
  dateFilterDisplayDate: true,
  courseNameChecked: false,
  courseName: '',
  trainingDateChecked: false,
  trainingDateStartDate: '',
  trainingDateFinishDate: '',
  trainerNameChecked: false,
  trainerName: '',
  distributionChecked: false,
  distribution: '',
  userResponseDateChecked: false,
  userResponseDateStartDate: '',
  userResponseDateFinishDate: '',
  userResponseDateDisplayDate: false,
  courseCodeChecked: false,
  courseCode: '',
  departmentChecked: false,
  department: '',
  stationChecked: false,
  station: '',
}

export const courseNameType = {
  CLASSROOM: 'Classroom',
  E_LEARNING: 'E-Learning',
  ONLINE: 'Virtual Classroom',
}
