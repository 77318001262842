import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { initialState } from '../../redux/slices/table/model'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from './Styled'
import { breadcrumbLicense } from './model'
import FilterDrawer from './FilterDrawer'
import Header from './Header'

const Index = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const [isInitial, setInitial] = useState(true)
  const dispatch = useDispatch()
  const { table } = useSelector(
    (state) => ({
      table: state.table,
    }),
    shallowEqual
  )
  const { limit, order, page, sort, isLoading } = table.table
  const { filterProp, isFilterDrawer } = table.filter
  const manageProps = { uuid }
  const quickSeach = ''

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'fetch',
        table: initialState,
        quickSeach,
        manageProps,
        setInitial,
        history,
      })
    )
  }, [])

  useEffect(() => {
    if (isInitial) return
    dispatch(
      fetchDataList({
        method: 'fetch',
        table,
        quickSeach,
        manageProps,
      })
    )
  }, [limit, order, page, sort, filterProp])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box>
            <Typography variant="h4">ใบอนุญาต (License)</Typography>
            <Breadcrumbs menuList={breadcrumbLicense(uuid)} />
          </Box>
          <Header />
        </StyledHeader>

        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
