import { createAsyncThunk } from '@reduxjs/toolkit'
import callAxios from '../../utils/baseService'
import {
  manageClassAnnouncement,
  manageClassCheckExam,
  manageClassCheckExamList,
  manageClassGiveScore,
  manageClassSetToNotPass,
  standaloneAnnouncement,
  standaloneExamList,
  standaloneExamVersionList,
  standaloneGiveScore,
} from '../../utils/apiPath'

export const getManageClassExamList = createAsyncThunk(
  'manageClassCheckExam/getManageClassExamList',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid, courseTestAndEvaUuid } = payload
      return await callAxios.get(
        `${manageClassCheckExam}/${trainingPlanUuid}/${courseTestAndEvaUuid}`
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStandaloneExamList = createAsyncThunk(
  'manageClassCheckExam/getStandaloneExamList',
  async (eTestingVersionUuid, { rejectWithValue }) => {
    try {
      return await callAxios.get(`${standaloneExamList}/${eTestingVersionUuid}`)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageClassCheckHistory = createAsyncThunk(
  'manageClassCheckExam/getManageClassCheckHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid, courseTestAndEvaUuid, body } = payload
      return await callAxios.post(
        `${manageClassCheckExam}/${trainingPlanUuid}/${courseTestAndEvaUuid}/history`,
        body
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageClassLearnerExam = createAsyncThunk(
  'manageClassCheckExam/getManageClassLearnerExam',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid, courseTestAndEvaUuid, learnerUuid } = payload
      return await callAxios.get(
        `${manageClassCheckExam}/${trainingPlanUuid}/${courseTestAndEvaUuid}/learner/${learnerUuid}`
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLearnerCheckHistory = createAsyncThunk(
  'manageClassCheckExam/getLearnerCheckHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const { trainingPlanUuid, courseTestAndEvaUuid, learnerUuid, body } =
        payload
      return await callAxios.post(
        `${manageClassCheckExam}/${trainingPlanUuid}/${courseTestAndEvaUuid}/learner/${learnerUuid}/history`,
        body
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putManageClassExamScore = createAsyncThunk(
  'manageClassCheckExam/putManageClassExamScore',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.put(manageClassGiveScore, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postSetExamNotPass = createAsyncThunk(
  'manageClassCheckExam/postSetExamNotPass',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(manageClassSetToNotPass, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putManageClassAnnouncement = createAsyncThunk(
  'manageClassCheckExam/putManageClassAnnouncement',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.put(manageClassAnnouncement, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

//  list
export const postManageClassExamList = createAsyncThunk(
  'manageClassCheckExam/list',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(manageClassCheckExamList, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postStandaloneExamList = createAsyncThunk(
  'manageClassCheckExam/postStandaloneExamList',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(standaloneExamList, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postStandaloneExamVersionList = createAsyncThunk(
  'manageClassCheckExam/postStandaloneExamVersionList',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(standaloneExamVersionList, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStandaloneUser = createAsyncThunk(
  'manageClassCheckExam/getStandaloneUser',
  async (payload, { rejectWithValue }) => {
    try {
      const { eTestingVersionUuid, userUuid } = payload
      return await callAxios.get(
        `${standaloneExamList}/${eTestingVersionUuid}/user/${userUuid}`
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStandaloneUserHistory = createAsyncThunk(
  'manageClassCheckExam/getStandaloneUserHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const { eTestingVersionUuid, userUuid, body } = payload
      return await callAxios.post(
        `${standaloneExamList}/${eTestingVersionUuid}/user/${userUuid}/history`,
        body
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStandaloneAnnouncementHistory = createAsyncThunk(
  'manageClassCheckExam/getStandaloneAnnouncementHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const { eTestingVersionUuid, body } = payload
      return await callAxios.post(
        `${standaloneExamList}/${eTestingVersionUuid}/history`,
        body
      )
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putStandaloneGiveScore = createAsyncThunk(
  'manageClassCheckExam/putStandaloneGiveScore',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.put(standaloneGiveScore, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putStandaloneAnnounce = createAsyncThunk(
  'manageClassCheckExam/putStandaloneAnnounce',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.put(standaloneAnnouncement, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
