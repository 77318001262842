import React from 'react'
import _ from 'lodash'
import Add from '@mui/icons-material/Add'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '../../../../components/Input/Autocomplete'

import Headers from './Headers'
import Groups from './Groups'

import * as Styled from './Styled'
import * as events from './events'

export const SelectBox = (props) => {
  const {
    type,
    indexContent,
    label,
    listOptions,
    value,
    onChangeSelect,
    textError,
    isHeader,
    disabled,
  } = props
  return (
    <Autocomplete
      id={`select-${type}-${indexContent}`}
      name={`select-${type}-${indexContent}`}
      labelText={label}
      boxSx={{ width: isHeader ? 254 : '100%' }}
      isHideSort={true}
      options={listOptions}
      defaultValue={value}
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField placeholder={'กรุณาเลือก'} {...params} />
      )}
      handleChange={onChangeSelect}
      textError={textError}
    />
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const {
    licenseConfig,
    listType,
    listDistribution,
    listDistributionELearning,
    licenseList,
    defaultListDistribution,
    defaultListDistributionELearning,
  } = useSelector((state) => ({
    licenseConfig: state.masterDataLicenseForm.initialState.licenseConfig,
    listDistribution: state.masterDataLicenseForm.initialState.listDistribution,
    listDistributionELearning:
      state.masterDataLicenseForm.initialState.listDistributionELearning,
    listType: state.masterDataLicenseForm.initialState.listType,
    licenseList: state.masterDataLicenseForm.initialState.licenseList,
    defaultListDistribution:
      state.masterDataLicenseForm.initialState.defaultListDistribution,
    defaultListDistributionELearning:
      state.masterDataLicenseForm.initialState.defaultListDistributionELearning,
  }))

  return (
    <Card sx={{ mt: 3 }}>
      <Box sx={{ m: 3 }}>
        <Typography variant="h6">เงื่อนไขการได้รับใบอนุญาต</Typography>

        {!!licenseConfig.length &&
          licenseConfig.map((itemConfig, indexConfig) => {
            const listGroups = _.get(itemConfig, 'groups', [])
            const distributionChannelUuid = events.checkValueEmpty(
              itemConfig,
              'distributionChannelUuid',
            )

            const listCourse = events.findListCourse({
              listDistribution,
              uuid: distributionChannelUuid,
            })

            const listCorseELarning = events.findListCourse({
              listDistribution: listDistributionELearning,
              uuid: distributionChannelUuid,
            })

            const defaultCourse = events.findListCourse({
              listDistribution:
                listType === 'COURSE'
                  ? defaultListDistribution
                  : defaultListDistributionELearning,
              uuid: distributionChannelUuid,
            })
            const valueDistribution = events.findValueSelected({
              list: listDistribution,
              value: distributionChannelUuid,
            })

            return (
              <>
                <Styled.BoxBorder key={indexConfig}>
                  <Box sx={{ m: 2 }}>
                    <Headers
                      props={{
                        itemConfig,
                        indexConfig,
                        listDistribution,
                        valueDistribution,
                      }}
                    />
                    {!!listGroups.length && (
                      <Groups
                        props={{
                          indexConfig,
                          listGroups,
                          distributionChannelUuid,
                          listCourse,
                          listCorseELarning,
                          listType,
                          licenseList,
                          defaultCourse,
                        }}
                      />
                    )}
                    <Button
                      sx={{ mt: 3, mb: 1 }}
                      disabled={false}
                      data-testid={`btn-list-${indexConfig}`}
                      id={`btn-list-${indexConfig}`}
                      name={`btn-list-${indexConfig}`}
                      variant="outlined"
                      size="m"
                      startIcon={<Add />}
                      onClick={() =>
                        dispatch(events.handleAddGroup(indexConfig))
                      }
                    >
                      <Typography variant="button">หรือ เงื่อนไข</Typography>
                    </Button>
                  </Box>
                </Styled.BoxBorder>
              </>
            )
          })}

        <Button
          size="m"
          variant="contained"
          startIcon={<Add />}
          onClick={() => dispatch(events.handleAddConfigGroup())}
        >
          <Typography variant="button">เงื่อนไขกลุ่มใหม่</Typography>
        </Button>
      </Box>
    </Card>
  )
}

export default Index
