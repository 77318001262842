import { Box } from '@mui/material'
import { StyledCard } from './Styled'

export const ButtonFooterCard = ({ children, boxSx }) => {
  return (
    <StyledCard sx={{ p: 2 }}>
      <Box sx={{ ...boxSx, alignSelf: 'flex-end', mx: 3 }}>{children}</Box>
    </StyledCard>
  )
}
