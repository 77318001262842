import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxQuestion = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))
