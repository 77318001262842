import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import {
  agentProfileCourse,
  agentProfileCourseDefault,
} from '../../../../../constants/manage/agentProfile'
import _ from 'lodash'
import CourseDrawer from './CourseDrawer'
import {
  startLoading,
  stopLoading,
  setPagination,
  setSearch,
} from '../../../../../redux/slices/manage/agentProfile'
import SearchCustom from '../Search'
import { BoxHeder, BoxGroup, BoxSwitch } from './Styled'
import { fetchCourse, handleSwitch } from './events'
import { onSort } from '../../../../../utils/lib'

const CourseSection = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const { id: uuid } = useParams()
  const { search, pagination, data } = useSelector(
    (state) => ({
      search: state.agentProfile.search,
      pagination: state.agentProfile.pagination,
      data: state.agentProfile.initialState,
    }),
    shallowEqual
  )
  const {
    courseSearch,
    examSearch,
    orderCourseBy,
    sortCourseBy,
    handleCourseSearch,
  } = search
  const isSwitch = _.get(data, 'isSwitch', false)
  const [head, setHead] = useState([])

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(
      setSearch({
        key: 'orderCourseBy',
        value: orderBy,
      })
    )
    dispatch(
      setSearch({
        key: 'sortCourseBy',
        value: sort,
      })
    )
    dispatch(
      fetchCourse({
        ...pagination,
        agentUuid: uuid,
        order: orderBy,
        sort: sort,
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
        courseOriginal: isSwitch,
      })
    )
    setHead(head)
  }

  useEffect(() => {
    dispatch(
      fetchCourse({
        ...pagination,
        agentUuid: uuid,
        order: orderCourseBy ?? 'DESC',
        sort: sortCourseBy ?? 'updatedAt',
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
        courseOriginal: isSwitch,
      })
    )
  }, [pagination, handleCourseSearch])

  useEffect(() => {
    for (const agentDefault of agentProfileCourse) {
      delete agentDefault['order']
    }

    for (const agentDefault of agentProfileCourseDefault) {
      delete agentDefault['order']
    }
    setHead(isSwitch ? agentProfileCourseDefault : agentProfileCourse)
  }, [isSwitch])

  return (
    <>
      <Card sx={{ pb: 3 }}>
        <BoxHeder>
          <BoxGroup>
            <Typography variant="h6">ประวัติการเรียน</Typography>
            {type === 'AGENT' && (
              <BoxSwitch>
                <Switch
                  data-testid="switch-history"
                  checked={isSwitch}
                  onClick={(e) =>
                    dispatch(
                      handleSwitch({
                        coursePage: 1,
                        courseLimit: 100,
                        agentUuid: uuid,
                        order: orderCourseBy ?? 'DESC',
                        sort: sortCourseBy ?? 'updatedAt',
                        examSearch: _.defaultTo(examSearch, ''),
                        courseSearch: _.defaultTo(courseSearch, ''),
                        courseOriginal: _.get(e, 'target.checked', false),
                        isSwitch: _.get(e, 'target.checked', false),
                      })
                    )
                  }
                />
                <Typography variant="body1">ดูประวัติระบบเดิม </Typography>
              </BoxSwitch>
            )}
          </BoxGroup>
          <BoxGroup>
            <SearchCustom searchKey="courseSearch" />
            {!isSwitch && (
              <Button
                data-testid="btn-open-drawer"
                variant="outlined"
                size="m"
                onClick={() => setIsOpen(true)}
              >
                <HistoryTwoTone color="primary.main" />
              </Button>
            )}
          </BoxGroup>
        </BoxHeder>
        <NormalTable
          headCells={head}
          listRows={_.get(data, 'course.result', [])}
          totalRows={_.get(data, 'course.totalCount', 0)}
          showPagination
          isHiddenOptions
          startLoading={startLoading}
          stopLoading={stopLoading}
          onRequestSort={onRequestSort}
          handlePagination={(events) =>
            dispatch(
              setPagination({
                key: 'coursePage',
                value: events.page,
              })
            )
          }
        />
      </Card>
      <CourseDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </>
  )
}

export default CourseSection
