import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassCheckExam'
import { downloadFile } from '../../../../../services/util'
import { manageClassCheckEva } from '../../../../../utils/apiPath'

export const handleOnClickMenu = (trainingPlan) => (dispatch) => {
  dispatch(onDownload(trainingPlan))
}

export const onDownload = (trainingPlan) => async (dispatch) => {
  const body = { order: 'DESC', sort: 'status', type: 'ALL' }
  dispatch(startLoading())
  await dispatch(
    downloadFile({
      url: `${manageClassCheckEva}/${trainingPlan}/download`,
      body: body,
      fileName: `รายการสถานะทำแบบประเมิน.xlsx`,
    }),
  )
  dispatch(stopLoading())
}
