import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const fetchLevelOfLearner = (selectedItems) => {
  if (!selectedItems) return
  const { masterData } = store.getState().crud

  const allDistribution = _.get(masterData, 'distributionCh.options', [])
  const distribution = selectedItems.map((item) =>
    allDistribution.find((option) => option.value === item.value),
  )
  let option = []
  _.forEach(
    distribution,
    (dis) => (option = _.concat(option, _.get(dis, 'level', []))),
  )
  store.dispatch(
    setFieldValue({
      key: 'masterData.levelOfLearner',
      value: {
        isLoading: false,
        options: option.map((item) => ({
          value: item.uuid,
          label: item.title,
        })),
      },
    }),
  )

  const {
    formData,
    masterData: { levelOfLearner },
  } = store.getState().crud

  function itemExistsInMaster(item, masterList) {
    return masterList.some((masterItem) => item.value === masterItem.value)
  }

  const reducedLevel = _.get(formData, 'levelOfLearner', []).filter((item) =>
    itemExistsInMaster(item, [
      {
        label: 'ทั้งหมด',
        value: 'all',
      },
      ...levelOfLearner.options,
    ]),
  )

  store.dispatch(
    setFieldValue({
      key: 'formData.levelOfLearner',
      value: reducedLevel,
    }),
  )
}
