import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import useTheme from '@mui/system/useTheme'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import Breadcrumbs from '../../components/Breadcrumbs'
import { validationSchema } from './FormSchema'
import useFetchList from '../../hooks/useFetchList'
import { defaultCoursePlatform, documentDefault } from './DefaultValue'
import {
  convertValueToOptionValue,
  getDialogErrorFormMessage,
  callAPI,
  validateCourseCondition,
  validatePermission,
  validateEPermission,
  replaceTextAreaNewLine,
} from '../../utils/lib'
import {
  filterLevelOfLearner,
  distributionFilter,
  filterProductType,
  filterRoadMap,
  filterTrainingPlatform,
  course,
  courseDraft,
  filterAcquiredSkill,
  uploadFileCourse,
  filterVersion,
  courseVersion,
  coursePeriod,
  filterLicense,
  filterCourse,
  filterStaffProfile,
  eCertificationFilter,
  CRUDListFilterPath,
  eLearningCourseFilter,
} from '../../utils/apiPath'

import { filterDataPrerequisite } from './Prerequisite/events'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../src/constants/eCertification'

export const menuMoreOptions = [
  'คัดลอกเพื่อสร้างหลักสูตรใหม่',
  'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
  'ลบ',
]
export const menuDeleteStatusMoreOptions = [
  'คัดลอกเพื่อสร้างหลักสูตรใหม่',
  'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
]
export const bodyLevel = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'title',
  title: '',
  status: ['ACTIVE'],
}
export const bodyDistribution = {
  abbreviation: '',
  distribution: '',
  level: [],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyProductType = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  productType: '',
  status: ['ACTIVE'],
}
export const bodyRoadMap = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  title: '',
  abbreviation: '',
  distribution: [],
  status: ['ACTIVE'],
}
export const bodyTrainingPlatform = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  status: ['ACTIVE'],
  trainingPlatform: '',
  abbreviation: '',
}
export const bodyAcquiredSkill = {
  acquiredSkill: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyLicense = {
  category: '',
  description: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE', 'INACTIVE', 'DELETED'],
  licenseId: '',
  createdBy: '',
  updatedBy: '',
}
export const bodyAllCourse = {
  courseCode: '',
  englishName: '',
  nameForLearner: '',
  numberOfVersions: -1,
  updatedBy: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'updatedAt',
  createdBy: '',
  status: ['DRAFT', 'ACTIVE', 'INACTIVE', 'WAITING_FOR_ACTIVE', 'DELETED'],
}
export const bodyAllELearningCourse = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  status: ['ACTIVE'],
  courseType: [],
  courseCode: '',
  name: '',
  nameForLearner: '',
  createdBy: '',
  updatedBy: '',
}
export const bodyOtheRoadMap = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  title: '',
  abbreviation: 'OTHE',
  distribution: [],
  status: ['ACTIVE'],
}
export const bodyStaff = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  agentCode: '',
  firstNameTH: '',
  lastNameTH: '',
  jobTitle: '',
  department: '',
  station: '',
  reportTo: '',
  position: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyECertification = {
  codeId: '',
  name: '',
  createdBy: '',
  updatedBy: '',
  limit: 100,
  order: 'ASC',
  page: -1,
  sort: 'codeId',
  status: ['ACTIVE'],
}

export const bodyPoint = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  point: '',
  badgeName: '',
  name: '',
  status: ['ACTIVE'],
}

const CourseSettingForm = ({ isEditForm, newVersion, cloneBy }) => {
  const { id: uuid } = useParams()
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const [menuOption, setMenuOption] = useState(menuMoreOptions)
  const [disabledDate, setDisabledDate] = useState([])
  const [displayTitle, setDisplayTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(defaultCoursePlatform)
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const getPermissionView = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['VIEW'],
  })

  const getPermissionEdit = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['CREATE'],
    data: initialValues,
  })

  const fetchOptions = useFetchList([
    {
      body: bodyLevel,
      url: filterLevelOfLearner,
      method: 'post',
    },
    {
      body: bodyRoadMap,
      url: filterRoadMap,
      method: 'post',
    },
    {
      body: bodyTrainingPlatform,
      url: filterTrainingPlatform,
      method: 'post',
    },
    {
      body: bodyProductType,
      url: filterProductType,
      method: 'post',
    },
    {
      body: bodyDistribution,
      url: distributionFilter,
      method: 'post',
    },
    {
      body: bodyAcquiredSkill,
      url: filterAcquiredSkill,
      method: 'post',
    },
    {
      body: bodyLicense,
      url: filterLicense,
      method: 'post',
    },
    {
      body: bodyAllCourse,
      url: filterCourse,
      method: 'post',
    },
    {
      body: bodyOtheRoadMap,
      url: filterRoadMap,
      method: 'post',
    },
    {
      body: bodyStaff,
      url: filterStaffProfile,
      method: 'post',
    },
    {
      body: bodyPoint,
      url: CRUDListFilterPath,
      method: 'post',
      headers: {
        'x-type': 'LEARNING_POINT',
      },
    },
    {
      body: bodyAllELearningCourse,
      url: eLearningCourseFilter,
      method: 'post',
    },
  ])

  const hasViewPermission = !validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.VIEW,
  })

  const fetchPermissionOptions = hasViewPermission
    ? []
    : useFetchList([
        {
          body: bodyECertification,
          url: eCertificationFilter,
          method: 'post',
        },
      ])

  const level = _.get(fetchOptions, '[0].result', [])
  const roadmap = _.get(fetchOptions, '[1].result', [])
  const trainingPlatform = _.get(fetchOptions, '[2].result', [])
  const productType = _.get(fetchOptions, '[3].result', [])
  const distribution = _.get(fetchOptions, '[4].result', [])
  const acquiredSkill = _.get(fetchOptions, '[5].result', [])
  const license = _.get(fetchOptions, '[6].result', [])
  const listCourse = _.get(fetchOptions, '[7].result', [])
  const listELearningCourse = _.get(fetchOptions, '[11].result', [])
  const allCourse = [...listCourse, ...listELearningCourse]
  const otheRoadmap = _.get(fetchOptions, '[8].result', [])
  const staff = _.get(fetchOptions, '[9].result', [])
  const point = _.get(fetchOptions, '[10].result', [])
  const eCertification = _.get(fetchPermissionOptions, '[0].result', [])

  const formik = useFormik({
    initialValues: convertInitialValue(initialValues),
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      values = {
        ...values,
        certificateLanguage: values.certificateLanguage
          ? values.certificateLanguage
          : initialValues.certificateLanguage,
      }
      handleSubmitActive({
        dialog,
        formik,
        setDialog,
        values,
        type: 'SAVE',
        uuid,
        newVersion,
        cloneBy,
        trainingPlatform,
        isEditForm,
        initialValues,
        disabledDate,
        setIsLoading,
      })
    },
  })
  let titleText = checkTitleText(isEditForm, newVersion)

  const breadcrumbList = checkBreadcrumb(initialValues, titleText, isEditForm)

  const fetchCourseByCode = async (courseCode) => {
    const body = {
      calendar: false,
      version: -1,
      englishName: '',
      courseCode: courseCode,
      startDate: '',
      updatedBy: '',
      limit: 100,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE', 'WAITING_FOR_ACTIVE', 'INACTIVE'],
      createdBy: '',
      assignee: '',
    }

    await callAPI({
      url: filterVersion,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        const resDistribution = _.get(res, 'result[0].distribution', [])
        const resRoadmap = _.get(res, 'result[0].roadmap', [])
        const resTrainingPlatform = _.get(res, 'result[0].trainingPlatform', [])
        const resCourseCode = _.get(res, 'result[0].courseCode', '')

        setInitialValues({
          ...initialValues,
          courseCode: resCourseCode,
          roadmap: resRoadmap,
          distribution: resDistribution,
          trainingPlatform: resTrainingPlatform.map((item) => {
            return { title: item.trainingPlatform, uuid: item.uuid }
          }),
        })

        formik.setValues({
          roadmap: resRoadmap,
          distribution: resDistribution,
          trainingPlatform: resTrainingPlatform.map((item) => {
            return { label: item.trainingPlatform, uuid: item.uuid }
          }),
        })
      },
    })
  }

  const fetchCourseById = async (uuidCourse) => {
    await callAPI({
      url: courseVersion + `/${uuidCourse}`,
      method: 'GET',
      onSuccess: (res) => {
        setCourseData(
          res,
          user,
          formik,
          cloneBy,
          isEditForm,
          setMenuOption,
          fetchCoursePeriod,
          setDisplayTitle,
          setInitialValues,
        )
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  const fetchCoursePeriod = async (courseCode, startDate) => {
    await callAPI({
      url: coursePeriod + `/${courseCode}`,
      method: 'GET',
      onSuccess: (res) => {
        let periodList = res
        const ignoreDateRange = {
          startDate: startDate,
        }
        if (isEditForm && periodList && periodList.length > 0) {
          const filter = periodList.filter(
            (item) => !_.isEqual(ignoreDateRange, item),
          )
          setDisabledDate(filter)
        } else {
          setDisabledDate(periodList)
        }
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  useEffect(() => {
    if (isEditForm || cloneBy) {
      setIsPageLoading(true)
      fetchCourseById(uuid)
    } else if (newVersion) {
      setIsPageLoading(true)
      fetchCourseByCode(uuid)
      fetchCoursePeriod(uuid)
    }
  }, [isEditForm, cloneBy])

  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      setDialog({
        title: 'ผิดพลาด',
        content:
          'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบหรือไม่ตรงตามเงื่อนไขที่กำหนด',
        variant: 'fail',
        open: true,
        onConfirmClick: () => onCancelClick(setDialog),
      })
    }
  }, [formik.errors])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            opacity: isPageLoading ? 0.4 : 'unset',
            pointerEvents: isPageLoading ? 'none' : 'unset',
          }}
        >
          <Box
            sx={{
              mx: 3,
              py: 1,
              justifyContent: 'space-between',
              backgroundColor: 'background.basic',
              display: 'flex',
              height: '60px',
              width: '100%',
              top: 66,
              position: 'fixed',
              zIndex: 3,
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                position: 'relative',
                height: '100%',
                top: 0,
                mx: 0,
                p: 3,
              },
            }}
          >
            <Typography variant="h4">
              {titleText}
              {isEditForm && ` : ${displayTitle}`}
            </Typography>
            <Breadcrumbs
              sx={{
                display: 'none',
                mx: 3,
                mb: 3,
                pt: 5,
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                  pt: 0,
                  ml: 0,
                },
              }}
              menuList={breadcrumbList}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                ml: 2,
                [theme.breakpoints.up('md')]: {
                  position: 'fixed',
                  top: 74,
                },
                right: 0,
                zIndex: 3,
                [theme.breakpoints.down('md')]: {
                  ml: 0,
                  justifyContent: 'start',
                  flexWrap: 'wrap',
                  rowGap: 2,
                },
              }}
            >
              {isEditForm && getPermissionView && (
                <Button
                  sx={{ mr: 3 }}
                  startIcon={<VisibilityIcon />}
                  variant="outlined"
                  onClick={() => {
                    window.location.href = `/course-setting/version/preview/${uuid}`
                  }}
                >
                  ดูตัวอย่าง
                </Button>
              )}

              {isPageLoading !== true && initialValues.status !== 'DELETED' && (
                <>
                  {(initialValues.status === 'DRAFT' ||
                    initialValues.status === '' ||
                    cloneBy) && (
                    <Button
                      sx={{ mr: 3 }}
                      variant="outlined"
                      onClick={() =>
                        handleSubmitDraft({
                          formik,
                          setDialog,
                          uuid,
                          newVersion,
                          cloneBy,
                          trainingPlatform,
                          isEditForm,
                          initialValues,
                          disabledDate,
                          setIsLoading,
                        })
                      }
                    >
                      บันทึกฉบับร่าง
                    </Button>
                  )}
                  {(isEditForm === false || getPermissionEdit) && (
                    <Button
                      type="submit"
                      sx={{ mr: 3 }}
                      variant="contained"
                      onClick={() => validateActive(formik, setDialog)}
                    >
                      บันทึกและเผยแพร่
                    </Button>
                  )}
                  {isEditForm && (
                    <Box
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          position: 'absolute',
                          right: 8,
                        },
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => handleMoreClick(event, setAnchorEl)}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={(e) =>
                          handleMoreClose(
                            e,
                            uuid,
                            formik,
                            initialValues,
                            setDialog,
                            setAnchorEl,
                          )
                        }
                        PaperProps={{
                          style: {
                            maxHeight: 48 * 4.5,
                            width: '26ch',
                          },
                        }}
                      >
                        {menuOption.map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            name={option}
                            onClick={(e) =>
                              handleMoreClose(
                                e,
                                uuid,
                                formik,
                                initialValues,
                                setDialog,
                                setAnchorEl,
                              )
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Breadcrumbs
            sx={{
              mx: 3,
              mb: 3,
              pt: 5,
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
            menuList={breadcrumbList}
          />
          {!isPageLoading && (
            <Form
              courseCode={newVersion ? uuid : initialValues.courseCode}
              newVersion={newVersion}
              cloneBy={cloneBy}
              isEditForm={isEditForm}
              dialog={dialog}
              setDialog={setDialog}
              handleClose={() => onCancelClick(setDialog)}
              formik={formik}
              isLoading={isLoading}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              level={level}
              roadmap={roadmap}
              staff={staff}
              trainingPlatform={trainingPlatform}
              productType={productType}
              distribution={distribution}
              acquiredSkill={acquiredSkill}
              license={license}
              allCourse={allCourse}
              otheRoadmap={otheRoadmap}
              point={point}
              eCertification={eCertification}
              handleUploadFile={(e) =>
                handleUploadFile(
                  e,
                  initialValues,
                  formik,
                  setDialog,
                  setIsLoading,
                )
              }
              disabledDate={disabledDate}
            />
          )}
        </Box>
        <CircularProgress
          disableShrink
          sx={{
            position: 'absolute',
            display: isPageLoading ? 'unset' : 'none',
            top: '50%',
            left: '50%',
            color: 'primary.main',
          }}
        />
      </Box>
    </form>
  )
}
export default CourseSettingForm

export const handleMoreClick = (event, setAnchorEl) => {
  setAnchorEl(event.currentTarget)
}

export const handleMoreClose = (
  e,
  uuid,
  formik,
  initialValues,
  setDialog,
  setAnchorEl,
) => {
  const text = e.target.outerText
  let link = ``
  if (text === 'คัดลอกเพื่อสร้างหลักสูตรใหม่') {
    link = `/course-setting/clone/course/${uuid}`
  } else if (text === 'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่') {
    link = `/course-setting/clone/version/${uuid}`
  } else if (text === 'ลบ') {
    setDialog({
      content: 'คุณต้องการลบรายการนี้หรือไม่',
      variant: 'delete',
      open: true,
      onConfirmClick: () =>
        handleDelete(uuid, formik, initialValues, setDialog),
      onCancelClick: () =>
        setDialog({
          variant: 'delete',
          content: 'คุณต้องการลบรายการนี้หรือไม่',
          open: false,
        }),
    })
  }
  if (link !== '') {
    window.location.href = link
  }
  setAnchorEl(null)
}

export const handleDelete = (uuid, formik, initialValues, setDialog) => {
  callAPI({
    url: `${courseVersion}/${uuid}`,
    method: 'delete',
    onSuccess: () => {
      onCancelClick(setDialog)
      window.location.href = '/course-setting'
    },
    onError: (er) => {
      setDialog(getDialogErrorFormMessage(formik, er, initialValues, setDialog))
    },
  })
}

export const onCancelClick = (setDialog) => {
  setDialog({
    open: false,
  })
}

export const convertInitialValue = (initialValue) => {
  const convertValue = {
    ...initialValue,
    minutes: initialValue.minutes,
    distribution:
      initialValue.distribution.uuid !== ''
        ? convertValueToOptionValue({
            data: initialValue.distribution,
            key: 'uuid',
            label: 'abbreviation',
          })
        : defaultCoursePlatform.distribution,
  }

  return convertValue
}

export const checkTitleText = (isEditForm, newVersion) => {
  let titleText = 'สร้างหลักสูตร'
  if (isEditForm) {
    titleText = 'แก้ไขหลักสูตร'
  } else if (newVersion) {
    titleText = 'สร้างเวอร์ชั่นใหม่'
  }

  return titleText
}

export const checkBreadcrumb = (initialValues, titleText, isEditForm) => {
  let result = [
    { title: 'จัดการหลักสูตร', link: '/course-setting', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: initialValues.courseCode
        ? '/course-setting/version/' + initialValues.courseCode
        : '/course-setting/version/',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
  if (!isEditForm) result.splice(1, 1)
  return result
}

export const validateActive = (formik, setDialog) => {
  if (Object.keys(formik.errors).length) {
    setDialog({
      title: 'ผิดพลาด',
      content:
        'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบหรือไม่ตรงตามเงื่อนไขที่กำหนด',
      variant: 'fail',
      open: true,
      onConfirmClick: () => onCancelClick(setDialog),
    })
  }

  return null
}

export const handleSubmitActive = ({
  dialog,
  formik,
  setDialog,
  values,
  type,
  uuid,
  newVersion,
  cloneBy,
  trainingPlatform,
  isEditForm,
  initialValues,
  disabledDate,
  setIsLoading,
}) => {
  setDialog({
    ...dialog,
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    open: true,
    onConfirmClick: () =>
      submitCourse(
        values,
        type,
        uuid,
        newVersion,
        cloneBy,
        trainingPlatform,
        isEditForm,
        formik,
        initialValues,
        disabledDate,
        setDialog,
        setIsLoading,
      ),
    onCancelClick: () => onCancelClick(setDialog),
  })
}

export const validateDraft = (formik) => {
  let status = true
  let resultMessage = {}
  const textError = 'กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง'
  const values = formik.values

  _.forIn(values, (item, index) => {
    if (
      index === 'englishName' ||
      index === 'startDate' ||
      index === 'trainingPlatform'
    ) {
      if (!item || item.length < 1) {
        resultMessage = {
          ...resultMessage,
          [index]: textError,
        }
      }
    }
    if (index === 'distribution') {
      if (item.id === null) {
        resultMessage = {
          ...resultMessage,
          [index]: textError,
        }
      }
    }
    if (index === 'roadmap') {
      if (item.uuid === '') {
        resultMessage = {
          ...resultMessage,
          [index]: textError,
        }
      }
    }
  })

  formik.setErrors(resultMessage)

  if (!_.isEmpty(resultMessage)) {
    status = false
  }

  return status
}

export const handleSubmitDraft = (props) => {
  const {
    formik,
    setDialog,
    uuid,
    newVersion,
    cloneBy,
    trainingPlatform,
    isEditForm,
    initialValues,
    disabledDate,
    setIsLoading,
  } = props
  if (!validateDraft(formik)) {
    setDialog({
      title: 'ผิดพลาด',
      content:
        'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบหรือไม่ตรงตามเงื่อนไขที่กำหนด',
      variant: 'fail',
      open: true,
      onConfirmClick: () => onCancelClick(setDialog),
    })

    return
  }
  formik.setFieldValue('status', 'DRAFT')
  setDialog({
    title: 'ยืนยันบันทึกฉบับร่าง',
    content: 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่',
    variant: 'save',
    open: true,
    onConfirmClick: () =>
      submitCourse(
        formik.values,
        'DRAFT',
        uuid,
        newVersion,
        cloneBy,
        trainingPlatform,
        isEditForm,
        formik,
        initialValues,
        disabledDate,
        setDialog,
        setIsLoading,
      ),
    onCancelClick: () => onCancelClick(setDialog),
  })
}

export const handleUploadFile = async (
  e,
  initialValues,
  formik,
  setDialog,
  setIsLoading,
) => {
  setIsLoading(true)
  const files = e.target.files
  const sizeFile = _.get(e, 'target.files[0].size', 0)
  let isWrongType = true

  if (files.length === 0) {
    isWrongType = true
  } else {
    const type = ['jpg', 'png', 'jpeg']
    const getNameImage = _.get(e, 'target.files[0].name', '')
    const splitName = getNameImage.split('.')
    const checkType = type.includes(
      splitName[splitName.length - 1].toLowerCase(),
    )
    if (checkType) isWrongType = false
  }
  let message = 'นามสกุลไฟล์ไม่ถูกต้อง'
  if (sizeFile > 2000000) {
    isWrongType = true
    message = 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง'
  }

  if (isWrongType) {
    _.set(document.getElementById('icon-button-file'), 'value', null)
    setDialog({
      open: true,
      title: 'ผิดพลาด',
      variant: 'fail',
      content: message,
      onConfirmClick: () =>
        setDialog({
          title: 'ผิดพลาด',
          variant: 'fail',
          content: message,
          open: false,
        }),
    })
    setIsLoading(false)
    return
  }

  const data = new FormData()
  data.append('file', e.target.files[0])
  await callAPI({
    url: uploadFileCourse,
    method: 'POST',
    body: data,
    onSuccess: (res) => {
      const key = res?.key
      formik.setFieldValue('imageKey', key)
    },
    onError: (er) => {
      setDialog(getDialogErrorFormMessage(formik, er, initialValues, setDialog))
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const getCouseCode = (
  roadmapValue,
  trainingPlatformValue,
  distributionValue,
  cloneBy,
  initialValues,
  trainingPlatform,
) => {
  if (cloneBy == 'version') {
    return initialValues.courseCode
  }
  let trainingPlatformCode = ''
  if (trainingPlatformValue) {
    const findChange = trainingPlatform.find(
      (item) => item.uuid === trainingPlatformValue[0].value,
    )
    if (findChange) {
      trainingPlatformCode = findChange.abbreviation
    }
    if (trainingPlatformValue.length > 1) {
      trainingPlatformCode = 'HB'
    }
  }
  const code =
    distributionValue.abbreviation +
    '-' +
    trainingPlatformCode +
    '-' +
    roadmapValue.abbreviation +
    '-' +
    'XXXXX'
  return code
}

export const submitCourse = async (
  values,
  typeSubmit,
  uuid,
  newVersion,
  cloneBy,
  trainingPlatform,
  isEditForm,
  formik,
  initialValues,
  disabledDate,
  setDialog,
  setIsLoading,
) => {
  setDialog({
    title: typeSubmit === 'SAVE' ? 'บันทึกและเผยแพร่' : 'ยืนยันบันทึกฉบับร่าง',
    content:
      typeSubmit === 'SAVE'
        ? 'คุณต้องการบันทึกข้อมูลนี้หรือไม่'
        : 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่',
    variant: 'save',
    isLoading: true,
  })

  const methodType = isEditForm ? 'put' : 'post'
  const breakMealBudget =
    values.breakMealBudget === '' ? 0 : parseFloat(values.breakMealBudget)
  let code = uuid ? uuid : 'DRAFT-XXXXX'
  if (
    values.roadmap &&
    values.trainingPlatform.length > 0 &&
    values.distribution
  ) {
    code =
      newVersion && cloneBy !== 'version'
        ? uuid
        : getCouseCode(
            values.roadmap,
            values.trainingPlatform,
            values.distribution,
            cloneBy,
            initialValues,
            trainingPlatform,
          )
  }

  let electronic = values.electronic

  if (!_.isEmpty(cloneBy)) {
    const electronicList = []
    for (const item of electronic) {
      delete item.uuid
      electronicList.push(item)
    }
    electronic = electronicList
  }

  const defaultBody = {
    ...values,
    point: parseInt(values.point) || 0,
    status: typeSubmit === 'SAVE' ? 'ACTIVE' : 'DRAFT',
    hours: +values.hours,
    breakMealBudget: breakMealBudget,
    courseCondition: validateCourseCondition(values),
    coursePrerequisite: filterDataPrerequisite(
      values.coursePrerequisite,
      cloneBy,
    ),
    electronic: electronic,
  }
  const body = isEditForm
    ? {
        ...defaultBody,
        uuid: uuid,
        title: values.title,
        courseCode: values.courseCode,
      }
    : {
        ...defaultBody,
        courseCode: code,
      }

  const isTestAndEvaluateEmpty = body.testAndEvaluate.filter((item) => {
    const hasUuid = item.testOrEvaluateUuid !== ''
    const isTypeOfETest =
      item.type !== '' && item.testAndEvaluateType === 'E_TESTING'
    const isTypeOfEVa =
      item.type === '' && item.testAndEvaluateType === 'E_EVALUATION'
    return hasUuid && (isTypeOfETest || isTypeOfEVa)
  })

  body.testAndEvaluate = isTestAndEvaluateEmpty.map((item) => {
    let criteriaPercent = Number(item.criteriaPercent)
    if (item.testCriteriaType == 'REQUIRED_PERCENT') {
      criteriaPercent = criteriaPercent !== 0 ? criteriaPercent : 100
    } else criteriaPercent = null
    return { ...item, criteriaPercent }
  })

  if (!_.isEmpty(body?.documentList) && body?.requireDocument) {
    const filterDocument = _.filter(
      body.documentList,
      (i) => !_.isEmpty(i.name) && i.isSelect,
    )
    body.documentList = _.map(filterDocument, (item) => item.name)
  } else {
    body.requireDocument = false
    body.documentList = []
  }

  setIsLoading(true)
  callAPI({
    url: typeSubmit === 'SAVE' ? course : courseDraft,
    method: methodType,
    body: body,
    onSuccess: (data) => {
      let uuidLink = ''
      if (newVersion && !cloneBy) {
        uuidLink = uuid
      } else if (isEditForm || cloneBy) {
        uuidLink = data?.courseCode
      }
      setDialog({
        open: true,
        variant: 'success',
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        isLoading: false,
        onConfirmClick: () => {
          setDialog({ variant: 'success', open: false })
          window.location.href =
            uuidLink === ''
              ? '/course-setting'
              : `/course-setting/version/${uuidLink}`
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
  })
}

export const setMenuOptionByCondition = (data, user, setMenuOption) => {
  const getPermissionDelete = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['DELETE'],
    data: data,
  })

  if (!getPermissionDelete) {
    setMenuOption(menuDeleteStatusMoreOptions)
  }
}

export const setCourseData = (
  data,
  user,
  formik,
  cloneBy,
  isEditForm,
  setMenuOption,
  fetchCoursePeriod,
  setDisplayTitle,
  setInitialValues,
) => {
  const {
    distribution,
    roadmap,
    trainingPlatform,
    courseCode,
    englishName,
    thaiName,
    nameForLearner,
    startDate,
    hours,
    minutes,
    englishCertificateName,
    imageKey,
    levelOfLearner,
    otherLevelOfLearner,
    acquiredSkill,
    productType,
    coursePurpose,
    courseOutline,
    courseCondition,
    isApproved,
    hasBreakMeal,
    status,
    isActive,
    courseLevelControl,
    id,
    version,
    assignee,
    electronic,
    breakMealBudget,
    tag,
  } = data
  if (cloneBy !== 'course') {
    fetchCoursePeriod(courseCode, startDate)
  }
  let labelStr = ''
  let countObjTrainingPlatform = 0
  trainingPlatform.map((item) => {
    countObjTrainingPlatform = _.size(item)
  })
  if (countObjTrainingPlatform > 2) {
    labelStr = 'trainingPlatform'
  } else {
    labelStr = 'label'
  }

  setMenuOptionByCondition(data, user, setMenuOption)
  setDisplayTitle(
    englishName.length > 60
      ? englishName.substring(0, 60) + '...'
      : englishName,
  )

  let DocuemntList = []

  if (!_.isEmpty(data?.documentList)) {
    const initialDocumentDefault = _.map(documentDefault, (item, key) => {
      const find = _.filter(data?.documentList, (i) => i === item)
      if (!_.isEmpty(find)) {
        return {
          uuid: key,
          name: item,
          isSelect: true,
          isDefault: true,
        }
      }
      return {
        uuid: key,
        name: item,
        isSelect: false,
        isDefault: true,
      }
    })
    DocuemntList = _.map(data?.documentList, (item, key) => {
      const filterDefeatul = _.filter(documentDefault, (i) => i === item)
      if (!_.isEmpty(filterDefeatul)) {
        return null
      }
      return {
        uuid: key + (documentDefault.length - 1),
        name: item,
        isSelect: true,
        isDefault: false,
        isError: false,
      }
    })
    DocuemntList = [
      ..._.compact(initialDocumentDefault),
      ..._.compact(DocuemntList),
    ]
  } else {
    DocuemntList = [
      {
        uuid: 1,
        name: 'บัตรประชาชน',
        isSelect: false,
        isDefault: true,
      },
      {
        uuid: 2,
        name: 'สลิปโอนเงินค่าสมัคร',
        isSelect: false,
        isDefault: true,
      },
    ]
  }

  setInitialValues({
    ...data,
    documentList: DocuemntList,
    electronic: _.isEmpty(electronic)
      ? []
      : _.orderBy(electronic, ['index'], ['asc']),
    trainingPlatform: trainingPlatform.map((item) => {
      return {
        title: item[labelStr],
        label: item[labelStr],
        value: item.uuid,
      }
    }),
    acquiredSkill: acquiredSkill.map((item) => {
      return {
        title: item.acquiredSkill,
        label: `${item?.skillType?.skillType} : ${item?.acquiredSkill}`,
        value: item.uuid,
      }
    }),
    productType: productType.map((item) => {
      return {
        title: item.productType,
        label: item.productType,
        value: item.uuid,
      }
    }),
    status: cloneBy
      ? data?.status === 'DRAFT'
        ? 'DRAFT'
        : 'INACTIVE'
      : data?.status,
  })

  const objValue = {
    ...formik.values,
    assignee: assignee,
    courseLevelControl: courseLevelControl,
    hours: hours,
    minutes: minutes,
    englishCertificateName: replaceTextAreaNewLine(englishCertificateName),
    imageKey: imageKey,
    levelOfLearner: levelOfLearner.map((item) => {
      return { label: item.title, value: item.uuid }
    }),
    otherLevelOfLearner: otherLevelOfLearner.map((item) => {
      return { label: item.title, value: item.uuid }
    }),
    acquiredSkill: acquiredSkill.map((item) => {
      return {
        label: `${item?.skillType?.skillType} : ${item?.acquiredSkill}`,
        value: item.uuid,
      }
    }),
    productType: productType.map((item) => {
      return { label: item.productType, value: item.uuid }
    }),
    version: version,
    coursePurpose: coursePurpose,
    courseOutline: courseOutline,
    courseCondition: courseCondition,
    isApproved: isApproved,
    hasBreakMeal: hasBreakMeal,
    breakMealBudget: breakMealBudget,
    status: status,
    isActive: isActive,
    thaiName: thaiName,
    englishName: englishName,
    nameForLearner: nameForLearner,
    roadmap: roadmap,
    distribution: distribution,
    trainingPlatform: trainingPlatform.map((item) => {
      return { label: item[labelStr], value: item.uuid }
    }),
    coursePrerequisite: _.get(data, 'coursePrerequisite', []),
    tag: tag,
  }

  if (cloneBy) {
    objValue['status'] = 'INACTIVE'
  }
  if (isEditForm || cloneBy === 'course') {
    objValue['startDate'] = startDate
  }
  if (courseCondition.length === 0) {
    objValue['courseCondition'] = [
      [{ type: '', uuid: '', name: '', conditionType: '' }],
    ]
  }
  if (cloneBy) {
    objValue['assignee'] = []
  }
  if (cloneBy && cloneBy === 'course') {
    const strCode = courseCode.split('-')
    let concatCode = ''
    strCode.map((str, index) => {
      if (index !== strCode.length - 1) {
        concatCode += str
        concatCode += '-'
      } else {
        concatCode += 'XXXXX'
      }
    })
    objValue['courseCode'] = concatCode
  } else {
    objValue['courseCode'] = courseCode
    objValue['id'] = id
  }
  formik.setValues(objValue)
}
