import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  StyledCard,
  LoadingCircular,
} from '../CourseDrawer/Styled'
import { closeEnrollDrawer } from '../../../../../redux/slices/eLearning/dashboard'
import Table2 from '../../../../../components/redux/Table2'
import { fetchData } from './events'
import {
  resetRowsPerPage,
  setSearchText,
} from '../../../../../redux/slices/table2'
import { typeTab } from '../../model'

const EnrollDrawer = () => {
  const dispatch = useDispatch()
  const {
    open,
    header,
    uuid,
    courseType,
    type,
    startDate,
    endDate,
    courseTypeFilter,
    status,
  } = useSelector(
    (state) => ({
      open: state.eLearningDashboard.enrollDrawer.isOpen,
      header: state.eLearningDashboard.enrollDrawer.header,
      uuid: state.eLearningDashboard.enrollDrawer.uuid,
      courseType: state.eLearningDashboard.courseDrawer.courseType,
      type: state.eLearningDashboard.type,
      startDate: state.eLearningDashboard.filter.startDate,
      endDate: state.eLearningDashboard.filter.endDate,
      courseTypeFilter: state.eLearningDashboard.filter.courseType,
      status: state.eLearningDashboard.filter.status,
    }),
    shallowEqual,
  )
  const { table, search } = useSelector((state) => state.table2)
  const { limit, page, isLoading } = table
  const { searchText } = search

  useEffect(() => {
    open &&
      dispatch(
        fetchData({
          table,
          uuid,
          keyword: searchText,
          startDate,
          endDate,
          type: courseTypeFilter,
          status,
          courseType
        }),
      )
    if (!open) {
      dispatch(setSearchText(''))
      dispatch(resetRowsPerPage())
    }
  }, [open, page, limit])

  return (
    <StyledDrawer open={open} onClose={() => dispatch(closeEnrollDrawer())}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">
            {courseType === 'all'
              ? header.replace(/\((.+?)\)/g, '(ทุกประเภท)')
              : header.replace(/\((.+?)\)/g, `(${typeTab[type].label})`)}
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(closeEnrollDrawer())}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledCard isLoading={isLoading}>
        <Table2 />
      </StyledCard>
      <StyledFooter>
        <Button
          onClick={() => dispatch(closeEnrollDrawer())}
          variant="contained"
        >
          ปิด
        </Button>
      </StyledFooter>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default EnrollDrawer
