import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import { shouldForwardProp } from '../Styled'

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
  gap: theme.spacing(2),
}))

export const StyledFormCard = styled(
  Card,
  shouldForwardProp,
)(({ theme, isLoading, isUploadLoading }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading || isUploadLoading ? 'none' : 'auto',
}))

export const CourseWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  diplay: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  background: theme.palette?.info?.background,
  border: '1px solid #DBE4F1',
}))

export const Row = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}))

export const Column = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="m" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  gap: theme.spacing(1),
  marginLeft: theme.spacing(1.5),
}))
