import React from 'react'
import Station from './Station'
import Footer from './Footer'
import Title from './Title'
import { StyledFormContainer } from './Styled'
import MainBanner from './MainBanner'

const Form = () => {
  return (
    <StyledFormContainer>
      <MainBanner />
      <Title />
      <Station />

      <Footer />
    </StyledFormContainer>
  )
}

export default Form
