import _ from 'lodash'
import { store } from '../../../../App'

export const handleClickOpenReport = (value = null) => {
  const { apiRcmsDashboard, filterList } = store.getState().crud
  const start_date = apiRcmsDashboard?.filtersDrawer?.startDate
  const end_date = apiRcmsDashboard?.filtersDrawer?.endDate
  const courseUuid = apiRcmsDashboard?.filtersDrawer?.courseUuid
  const platformLearning = apiRcmsDashboard?.filtersDrawer?.platformLearning
  let params = `?start_date=${start_date}&end_date=${end_date}`
  const courseCodeParams = []
  if (courseUuid) {
    const findCourse = _.defaultTo(filterList?.course ?? []).find(
      (item) => item.uuid === courseUuid,
    )
    courseCodeParams.push(findCourse?.courseCode)
  }
  if (courseCodeParams.length > 0) {
    params += `&courseCode=` + courseCodeParams.join(',')
  }
  if (platformLearning) {
    params += `&platformLearning=${platformLearning}`
  }
  if (value) {
    params += `&status=${value}` //Manual, Admin, Auto, Failed
  }
  const url = `/api-to-rcms/report`
  window.open(`${url}${params}`, '_blank')
}
