import React from 'react'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import {
  setChangePage,
  setChangeRowsPerPage,
} from '../../../../../redux/slices/eLearning/courseBatchLearnerDetail'
import Truncate from '../../../../../components/redux/Table/Truncate'
import Action from '../../../../../components/redux/Table/Action'
import {
  convertDurationToTime,
  convertFormatDateTime,
} from '../../../../../utils/lib'
import {
  E_LEARNING_LEARNER_LOG_RESULT,
  E_LEARNING_LEARNER_LOG_RESULT_TH,
} from '../../../../../constants/eLearning'
import ModuleAndProgress from './ModuleAndProgress'

export const checkConditionView = (headCells, row, rowIndex, cellIndex) => {
  const columnKey = headCells[cellIndex].id
  const data = _.get(row, `${columnKey}`, '-')

  if (columnKey === 'action') {
    return (
      <TableCell width={90} key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          minWidth="90px"
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'result') {
    return (
      <TableCell key={columnKey}>
        <Typography variant="body2" color={mapColorLabel(data)}>
          {E_LEARNING_LEARNER_LOG_RESULT_TH[data]}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'moduleAndProgress') {
    return (
      <TableCell key={columnKey}>
        <ModuleAndProgress row={row} />
      </TableCell>
    )
  } else if (columnKey === 'date' || columnKey === 'time') {
    return (
      <TableCell key={columnKey}>
        {convertFormatDateTime({ type: columnKey, value: data })}
      </TableCell>
    )
  } else if (columnKey === 'duration') {
    return <TableCell key={columnKey}>{convertDurationToTime(data)}</TableCell>
  }

  return (
    <TableCell key={columnKey}>
      <Truncate>{'' + data || '-'}</Truncate>
    </TableCell>
  )
}

export const mapColorLabel = (label) => {
  if (label === E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS) {
    return 'primary.main'
  } else if (
    label === E_LEARNING_LEARNER_LOG_RESULT.PASS ||
    label === E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
  ) {
    return 'success.main'
  } else if (
    label === E_LEARNING_LEARNER_LOG_RESULT.NOT_PASS ||
    label === E_LEARNING_LEARNER_LOG_RESULT.FAIL
  ) {
    return 'error.main'
  } else {
    return 'warning.main'
  }
}

export const handleChangePage = (newPage, key) => (dispatch) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  const page = newPage + 1

  dispatch(setChangePage({ key, page, tablePage }))
}

export const handleChangeRowsPerPage = (event, key) => (dispatch) => {
  const value = parseInt(event.target.value, 10)
  const rowsPerPageChange = value > 0 ? value : -1
  const rowsPerPage = {
    label: '' + rowsPerPageChange,
    value: rowsPerPageChange,
  }
  const limit = value

  dispatch(setChangeRowsPerPage({ key, rowsPerPage, limit }))
}
