import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette?.blue?.oceanBlue,
  color: theme.palette?.text?.white,
}))

export const StyledFooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1146,
  margin: '0px auto',
  padding: '48px 0 40px',
  [theme.breakpoints.down('lg')]: {
    padding: '36px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}))

export const StyledFooterTop = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '70px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '48px',
  },
}))

export const StyledFooterColumn = styled(Box)(({ gap, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: gap ? gap : '16px',
  maxWidth: '300px',
  a: { color: theme?.palette?.text?.white, textDecoration: 'none' },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '16px',
  },
}))

export const TextBox = styled(Box)(() => ({
  display: 'flex',
  gap: '11px',
}))

export const ColumnBox = styled(Box)(({ theme }) => ({
  margin: '24px 0px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  [theme.breakpoints.down('sm')]: {
    margin: '16px 0px',
  },
}))

export const ImageBox = styled(Box)(() => ({
  width: '18px',
}))

export const StyledFooterBottom = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  borderTop: `1px solid ${theme.palette?.other?.dividerWhite}`,
  [theme.breakpoints.down('lg')]: {
    gap: '60px',
  },
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.palette?.other?.dividerWhite,
  backgroundColor: theme.palette?.blue?.oceanBlue,
  '::before': { opacity: 0 },
  '&.Mui-expanded': { margin: '0 !important' },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(3),
  '& .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
  '& .MuiAccordionSummary-content': { margin: 0 },
  '& .MuiTypography-root': {
    color: theme?.palette?.text?.white,
  },
  '& .MuiSvgIcon-root': {
    color: theme?.palette?.text?.white,
  },
}))

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledLargeFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(8),
  margin: `0 auto`,
  borderBottom: '1px solid',
  borderColor: theme.palette?.other?.dividerWhite,
  [theme.breakpoints.down('lg')]: {
    paddingTop: '37px',
    paddingBottom: theme.spacing(5),
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const StyledColumn = styled(Box)(({ theme }) => ({
  width: 280,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  color: theme?.palette?.text?.white,
  a: { color: theme?.palette?.text?.white, textDecoration: 'none' },
  [theme.breakpoints.down('lg')]: {
    width: 151,
  },
}))

export const StyledSmallFooter = styled(Box)(({ theme }) => ({
  width: 1280,
  height: 48,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '14px 0',
  [theme.breakpoints.down('lg')]: {
    width: 768,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 70,
    justifyContent: 'center',
  },
}))
