import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledInputHidden = styled('input')({
  display: 'none',
  width: '18%',
})

export const StyledImagePreview = styled(Box)(() => ({
  maxWidth: 177,
  height: 108,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
}))

export const ButtonColorPreview = styled(Box)(({ theme, variant }) => {
  const colorObj = handlePreviewColor(theme, variant)
  return {
    width: 32,
    height: 24,
    border: '1px solid',
    borderRadius: theme.spacing(0.5),
    borderColor: colorObj?.border,
    backgroundColor: colorObj?.background,
  }
})

export const handlePreviewColor = (theme, variant) => {
  const obj = {
    white: {
      border: theme.palette?.text?.mercury,
      background: theme.palette?.text?.white,
    },
    black: {
      border: 'transparent',
      background: theme.palette?.text?.primary,
    },
  }
  return obj[variant]
}
