import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import { StyledViewTitle } from '../../../../modules/ETesting/Version/View/Styled'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { getQueryParams } from '../../../../utils/lib'
import { StyledContainer, StyledContent, StyledFormWrapper } from '../../Styled'
import TabMenuContent from './TabMenuContent'
import AttendanceTab from './AttendanceTab'
import DetailTab from './DetailTab'
import { fetchCourseLearner } from './events'
import { LEARNER_TAB, breadcrumbLearnerView } from './model'

const Learner = () => {
  const { id: uuid } = useParams()
  const courseUuid = getQueryParams('course')
  const { result, isLoading } = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCourseLearner(uuid, courseUuid))
  }, [])

  const name =
    _.get(result, 'userProfile.firstNameTH', '') +
    ' ' +
    _.get(result, 'userProfile.lastNameTH', '')

  const batchUuid = _.get(result, 'eLearningCourseBatch.uuid', result?.uuid)

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <StyledViewTitle sx={{ width: 'calc(100% - 420px)' }} variant="h4">
            {name}
          </StyledViewTitle>
        </Box>
        <Breadcrumbs
          menuList={breadcrumbLearnerView(name, batchUuid, courseUuid)}
        />
        <TabMenuContent />
        <Content />
      </StyledContent>
    </StyledContainer>
  )
}

export default Learner

export const Content = () => {
  const { result, tab, enrollData } = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail,
    shallowEqual,
  )
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === LEARNER_TAB.detail && (
        <DetailTab detail={result} enrollData={enrollData} />
      )}
      {tab === LEARNER_TAB.attendance && <AttendanceTab />}
    </StyledFormWrapper>
  )
}
