import { eLearningModuleUploadPart } from '../../../utils/apiPath'
import axios from 'axios'
import { setFieldUploadValue } from '../../../redux/slices/uploadFile'
import { store } from '../../../App'

export const postModuleUploadPart = async ({
  body,
  currentChunk,
  totalChunks,
  index,
  fieldName,
}) => {
  try {
    return await axios.post(eLearningModuleUploadPart, body, {
      onUploadProgress: (e) => {
        const chunkProgress = (e.loaded / e.total) * 100

        const totalProgress =
          (currentChunk / totalChunks) * 100 + chunkProgress / totalChunks

        store.dispatch(
          setFieldUploadValue({
            key: `formData[${fieldName}]files[${index}].percent`,
            value: totalProgress,
          }),
        )
      },
    })
  } catch (err) {
    throw new Error(err)
  }
}
