import { store } from '../../../../../App'
import { getFormatDate } from '../../../../../utils/lib'
import _ from 'lodash'
import { RequisitionStatus } from '../../../../../constants/stock'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  const formatDate = 'MM/DD/YYYY'
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.draftChecked) {
      status.push(RequisitionStatus.DRAFT)
    }
    if (filterState.status.waitingRequisitionConfirmChecked) {
      status.push(RequisitionStatus.WAITING_REQUISITION_COMFIRM)
    }
    if (filterState.status.waitingRequisitionApproveChecked) {
      status.push(RequisitionStatus.WAITING_REQUISITION_APPROVE)
    }
    if (filterState.status.waitingRequisitionConfirmChecked) {
      status.push(RequisitionStatus.WAITING_REQUISITION_COMFIRM)
    }
    if (filterState.status.waitingReturnChecked) {
      status.push(RequisitionStatus.WAITING_RETURN)
    }
    if (filterState.status.waitingReturnApproveChecked) {
      status.push(RequisitionStatus.WAITING_RETURN_APPROVE)
    }
    if (filterState.status.completedChecked) {
      status.push(RequisitionStatus.COMPLETED)
      status.push(RequisitionStatus.COMPLETED_WITH_CONDITION)
    }
    if (filterState.status.rejectedChecked) {
      status.push(RequisitionStatus.REJECTED)
    }
    if (filterState.status.canceledChecked) {
      status.push(RequisitionStatus.CANCELED)
      status.push(RequisitionStatus.DELETED)
    }
  } else {
    status = []
  }

  let station = []
  if (filterState.stationChecked) {
    if (!_.isEmpty(filterState.station)) {
      const stationList = filterState.station
      Object.entries(stationList).forEach(([key, value]) => {
        if (value) station.push(key)
      })
    }
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    product: filterState.productChecked ? filterState.product : '',
    station: station,
    qtyFrom: filterState.qtyChecked ? filterState.qtyFrom : '',
    qtyTo: filterState.qtyChecked ? filterState.qtyTo : '',
    category: filterState.categoryChecked ? filterState.category : '',
    subCategory: filterState.subCategoryChecked ? filterState.subCategory : '',
    createdBy: filterState.createdByChecked ? filterState.createdBy : '',
    createStartDate: filterState.createDateChecked
      ? getFormatDate(filterState.createStartDate, formatDate)
      : '',
    createFinishDate: filterState.createDateChecked
      ? getFormatDate(filterState.createFinishDate, formatDate)
      : '',
    updateStartDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateStartDate, formatDate)
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateFinishDate, formatDate)
      : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,
  }
}
