import React from 'react'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import { StyledColumn, StyledRow } from '../../Styled'

export const StyledContainerPlatform = styled(StyledRow)(({ theme }) => ({
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    width: '200%',
    height: 946,
    flexDirection: 'column',
    gap: 0,
    alignItems: 'center',
  },
}))

export const StyledPlatformBox = styled(StyledColumn)(({ theme }) => ({
  width: 'calc(33% - 7px)',
  height: 301,
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  padding: `10px ${theme.spacing(2)} ${theme.spacing(2)}`,
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: { width: 'calc(50% - 8px)' },
}))

export const StyledFooter = styled(Tabs)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette?.other?.divider,
  paddingTop: theme.spacing(2),
  alignItems: 'center',
  '& .MuiTabs-flexContainer': { gap: theme.spacing(1) },
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledArrowButton = styled(IconButton)(() => ({
  width: 34,
  height: 34,
  borderRadius: 0,
  '&.Mui-disabled': {
    width: 0,
    padding: 0,
    svg: { width: 0 },
  },
  transition: '0.15s',
}))

export const StyledChip = styled((props) => (
  <Chip {...props} variant="outlined" />
))(({ theme }) => ({
  borderColor: theme.palette?.other?.outlineBorder,
  fontSize: '18px',
}))

export const StyledTypography = styled(Typography)(({ theme, label }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: label == 'Title' ? 'block' : '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  whiteSpace: label == 'Title' ? 'nowrap' : 'pre-wrap',
  [theme.breakpoints.down('sm')]: {
    WebkitLineClamp: '2',
  },
}))
