import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import * as Styled from './Styled'
import * as events from './events'
import { fetchDataCalendar } from '../Content/events'

export const ListCheckBox = ({ listData, label, type }) => {
  const dispatch = useDispatch()

  if (!listData.length)
    return (
      <Typography variant="body1" color="text.lightGray">
        ไม่มีข้อมูล{label}
      </Typography>
    )
  return listData.map((item, index) => {
    return (
      <>
        <Box sx={{ mx: 2 }}>
          <FormControlLabel
            key={index}
            label={<Typography variant="body1">{item.label}</Typography>}
            control={
              <Checkbox
                inputProps={{ 'data-testid': `check-${item.name}` }}
                name={item.name}
                checked={item.checked}
                typeColor={_.get(item, 'typeColor', 'primary')}
                onClick={() =>
                  dispatch(
                    events.checkedFilter({
                      type,
                      value: item.value,
                    })
                  )
                }
              />
            }
          />
        </Box>
      </>
    )
  })
}

const FilterDrawer = () => {
  const dispatch = useDispatch()

  const uuidStation = useSelector((state) => state.manageClass.station)

  const { filterCalendar, month, year } = useSelector(
    (state) => ({
      filterCalendar: state.manageClassCalendar.filterCalendar,
      month: state.manageClassCalendar.month,
      year: state.manageClassCalendar.year,
    }),
    shallowEqual
  )

  return (
    <Styled.BoxDrawer
      open={filterCalendar.isOpen}
      onClose={() => dispatch(events.closeFilterDrawer())}
    >
      <Styled.BoxHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(events.closeFilterDrawer())}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </Styled.BoxHeadLabel>

      <Box sx={{ mx: 3 }}>
        <Typography variant="h6">ประเภทตารางงาน</Typography>
        <ListCheckBox
          label="ประเภทตารางงาน"
          type="scheduleType"
          listData={_.get(filterCalendar, 'scheduleType', [])}
        />
      </Box>
      <Box sx={{ mx: 3 }}>
        <Typography variant="h6">ปฎิทินของทีม</Typography>
        <ListCheckBox
          label="ปฎิทินของทีม"
          type="teamCalendar"
          listData={_.get(filterCalendar, 'teamCalendar', [])}
        />
      </Box>
      <Box sx={{ mx: 3 }}>
        <Typography variant="h6">หลักสูตรอบรม</Typography>
        <ListCheckBox
          label="หลักสูตรอบรม"
          type="trainingCourse"
          listData={_.get(filterCalendar, 'trainingCourse', [])}
        />
      </Box>
      <Styled.BoxFooter>
        <Button
          data-testid="btn-clear-filter"
          variant="outlined"
          onClick={() => dispatch(events.clearFilterCalendar())}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit-filter"
          onClick={() => {
            dispatch(
              fetchDataCalendar({
                type: 'filter',
                filterCalendar,
                month,
                year,
                uuid: uuidStation,
              })
            )
            dispatch(events.closeFilterDrawer())
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </Styled.BoxFooter>
    </Styled.BoxDrawer>
  )
}
export default FilterDrawer
