import { shallowEqual, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  Cancel,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  CopyAll,
} from '@mui/icons-material'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setSelected } from '../events'
import { SingleAnswer } from './SingleAnswer'
import { DuoAnswer } from './DuoAnswer'
import { MatchAnswer } from './MatchAnswer'
import { useReactToPrint } from 'react-to-print'
import { TooltipDialog } from '../../Styled'
import { sortQuestion } from '../../../handler/sortQuestion'
import { FreeTextAnswer } from './FreeTextAnswer'
import './style.css'

export const QuestionContent = ({ isOverviewPage }) => {
  const dispatch = useDispatch()
  const contentRef = useRef()
  const reactToPrintFn = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `@media print {
      @page {
        margin: 10px;
        @page {
          size: A4 landscape;
        }
      }
    }`,
  })
  const { formData, selected } = useSelector(
    (state) => ({
      selected: state.crud.selected,
      formData: state.crud.formData,
    }),
    shallowEqual,
  )
  const [openAll, setOpenAll] = useState(false)
  let openAllExist = false
  const sortDb = useSelector((state) => state.crud.sortDb, shallowEqual)
  const questionsDatas = _.get(formData, 'questionsDatas', [])
  const subTitle = _.get(questionsDatas[selected], `subTitle`, '')
  const questionType = _.get(questionsDatas[selected], 'questionType')

  let titleLength = 180
  if (questionType === 'SINGLE') {
    titleLength = 140
  }
  if (questionType === 'MATCHING') {
    titleLength = 75
  }
  if (
    [
      'MULTIPLE',
      'FILL_IN_BLANK_CHOICE',
      'FILL_IN_BLANK_DRAG',
      'FREE_TEXT',
    ].includes(questionType)
  ) {
    openAllExist = false
  } else {
    for (const data of _.get(questionsDatas[selected], 'answers', [])) {
      if (titleLength > 0) {
        if (data.title.length > titleLength && openAllExist !== true) {
          openAllExist = true
          break
        }
      }
    }
  }

  let optionsArray = []
  const allCount = questionsDatas?.length
  for (let i = 0; i < allCount; i++) {
    optionsArray.push(i)
  }

  useEffect(() => {
    sortQuestion(questionsDatas, sortDb)
    dispatch(setSelected(0))
  }, [sortDb])

  return (
    <div
      ref={contentRef}
      style={{
        display: 'grid',
        gap: '24px',
      }}
    >
      {!isOverviewPage && (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Typography variant="h6" fontSize="20px" sx={{ p: '4px' }}>
            ทั้งหมด
          </Typography>
          <FormControl>
            <Select
              labelId="select-label"
              id="simple-select"
              value={selected ?? 0}
              onChange={async (e) => dispatch(setSelected(e.target.value))}
            >
              {optionsArray.map((value) => {
                return (
                  <MenuItem value={value} key={value}>
                    {value + 1}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Typography sx={{ padding: '6px' }}>/ {allCount}</Typography>
          <IconButton
            data-testid={`btn-previous-${selected}`}
            disabled={selected === 0}
            color="primary"
            onClick={() => dispatch(setSelected(selected - 1))}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            data-testid={`btn-next-${selected}`}
            disabled={selected === allCount - 1 || allCount === 0}
            color="primary"
            onClick={() => dispatch(setSelected(selected + 1))}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Typography color="#999999" sx={{ padding: '4px' }}>
          คำถาม
        </Typography>
        <TooltipDialog title="คัดลอกคำถาม">
          {!isOverviewPage && (
            <IconButton
              data-testid={`btn-next-${selected}`}
              disabled={selected === allCount}
              color="#999999"
              onClick={reactToPrintFn}
            >
              <CopyAll />
            </IconButton>
          )}
        </TooltipDialog>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', padding: '4px' }}>
        <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
          Q{selected + 1}.
        </Typography>
        <Typography variant="h6" fontSize="20px">
          {['FILL_IN_BLANK_CHOICE', 'FILL_IN_BLANK_DRAG'].includes(
            questionType,
          ) ? (
            <div
              className="QContentDragDrop"
              style={{ margin: '0', whiteSpace: 'normal' }}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          ) : (
            _.get(questionsDatas[selected], `title`, '')
          )}
        </Typography>
      </Box>
      {['SEQUENCE', 'MULTIPLE'].includes(
        _.get(questionsDatas[selected], `questionType`),
      ) && (
        <Box sx={{ display: 'grid', padding: '4px', gap: '8px' }}>
          <Typography color="#999999">คำตอบ</Typography>
          {_.get(questionsDatas[selected], `answers`, []).map((data, i) => {
            return (
              <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                {_.get(questionsDatas[selected], `questionType`) ===
                  'MULTIPLE' &&
                  (_.get(data, `isCorrectAnswer`) === true ? (
                    <CheckCircle color="success" />
                  ) : (
                    <Cancel color="error" />
                  ))}
                <Typography fontWeight="bold" color="#4040AB">
                  {i + 1}.{' '}
                </Typography>
                <Typography fontWeight="bold">{data.title}</Typography>
              </Box>
            )
          })}
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '16px', paddingLeft: '4px' }}>
        <Typography color="#999999">สถิติการตอบ</Typography>
        <Typography color="#999999">
          (ทั้งหมด {_.get(questionsDatas[selected], `totalAnswer`, 0)} ครั้ง)
        </Typography>
        {openAllExist && (
          <Button
            color="primary"
            variant="text"
            sx={{ ml: 1, padding: 0 }}
            onClick={() => {
              setOpenAll(!openAll)
            }}
          >
            {openAll ? 'ย่อลงทั้งหมด' : 'ดูเพิ่มเติมทั้งหมด'}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          paddingLeft: '4px',
          display: 'grid',
          gap: questionType === 'SINGLE' ? '12px' : '24px',
        }}
      >
        {questionType === 'MULTIPLE' ? (
          <DuoAnswer
            data={questionsDatas[selected]}
            type={questionType}
            openAll={openAll}
          />
        ) : (
          _.get(questionsDatas[selected], `answers`, []).map((data, i) => {
            if (questionType === 'SINGLE') {
              const mostIncorrect = _.get(
                questionsDatas[selected],
                `answers`,
                [],
              )
                .filter((obj) => obj.isCorrectAnswer === false)
                .reduce(function (prev, curr) {
                  return prev.answerCount > curr.answerCount ? prev : curr
                }, false)
              const longestWord = _.get(
                questionsDatas[selected],
                `answers`,
                [],
              ).reduce(function (a, b) {
                return a.title.length > b.title.length ? a : b
              })
              const minWidth = `${longestWord.title.length + 5}ch`

              return (
                <>
                  {i !== 0 && <Divider />}
                  <SingleAnswer
                    data={data}
                    question={questionsDatas[selected]}
                    key={i}
                    mostIncorrect={mostIncorrect?.answerCount}
                    minWidth={minWidth}
                    openAll={openAll}
                  />
                </>
              )
            } else if (
              [
                'TRUE_FALSE',
                'MULTIPLE',
                'FILL_IN_BLANK_CHOICE',
                'FILL_IN_BLANK_DRAG',
                'SEQUENCE',
              ].includes(questionType)
            ) {
              return (
                <>
                  {i !== 0 && <Divider />}
                  <DuoAnswer
                    data={data}
                    question={questionsDatas[selected]}
                    key={i}
                    type={questionType}
                    seq={questionType === 'SEQUENCE' ? i + 1 : undefined}
                    openAll={openAll}
                  />
                </>
              )
            } else if (
              questionType === 'MATCHING' &&
              !_.isEmpty(data.question)
            ) {
              {
                return (
                  <>
                    {i !== 0 && <Divider />}
                    <MatchAnswer
                      data={data}
                      question={questionsDatas[selected]}
                      key={i}
                      seq={i}
                      openAll={openAll}
                    />
                  </>
                )
              }
            } else if (questionType === 'FREE_TEXT') {
              return (
                <>
                  {i !== 0 && <Divider />}
                  <FreeTextAnswer
                    data={data}
                    question={questionsDatas[selected]}
                    key={i}
                  />
                </>
              )
            }
          })
        )}
      </Box>
    </div>
  )
}
