import React, { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'

import TextInput from '../../../../../../../components/Input/TextInput'
import { TooltipForm } from '../../../Styled'
import { getErrorMessage } from '../../../event'
import * as Styled from './Styled'
import { handleChange, handleDeleteFillInBlank } from './event'

const Keyword = (props) => {
  const { indexQuestion, indexMainAnswer, indexAnswer, answers, keyQuestion } =
    props
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)
  const { question, errorSchemaMessage, disableForm } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )
  const { title } = answers[indexAnswer]
  const propsEvent = {
    question,
    keyQuestion,
    indexAnswer,
    indexMainAnswer,
    answers,
  }
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
    indexMainAnswer,
  })

  return (
    <Styled.BoxAnswer>
      <Styled.ContentInput>
        <Styled.RadioAnswer
          sx={{ m: 0, mt: 1.5, ml: 1.5 }}
          disabled={disableForm}
          value={indexAnswer}
          control={<Radio />}
        />
        <TextInput
          id="title-answer"
          name="title"
          placeholder="ตัวเลือก"
          type="basic"
          boxSx={{ mt: 1 }}
          disabled={disableForm}
          value={title}
          onChange={(e) =>
            dispatch(handleChange({ ...propsEvent, value: e.target.value }))
          }
          textError={answerErrorMessage}
          multiline
          maxRows={2}
        />
      </Styled.ContentInput>
      <Styled.ContentScoreAndButton>
        <Styled.BoxIcon>
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">ลบ</Typography>}
          >
            <IconButton
              sx={{ mt: -2 }}
              onMouseLeave={() => setIsHover(false)}
              onMouseOver={() => setIsHover(true)}
              onClick={() =>
                !disableForm && dispatch(handleDeleteFillInBlank(propsEvent))
              }
              data-testid={`btn-delete-${indexAnswer}`}
            >
              <CloseOutlined color={isHover ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>
        </Styled.BoxIcon>
      </Styled.ContentScoreAndButton>
    </Styled.BoxAnswer>
  )
}
export default Keyword
