import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  StyledDragHolder,
  StyledFlexRow,
  StyledLessonCard,
  StyledLessonHeader,
  StyledMiniIconBtn,
} from './Styled'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import { contentTypeEnum } from '../../../../../../../modules/ELearning/Collection/model'
import Course from './Course'
import LearningPath from './LearningPath'
import { RefreshTwoTone } from '@mui/icons-material'
import { fetchCourse } from '../../../Handler/fetchCourse'
import { fetchLearningPath } from '../../../Handler/fetchLearningPath'
import { handleOptionChoosed } from '../../handler/handleOptionChoosed'
import { setFieldValue } from '../../../../../../../redux/slices/crud'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

const DragDropContent = ({ provided, content, index }) => {
  const dispatch = useDispatch()
  const expand = _.get(content, 'isExpand', true)

  return (
    <StyledLessonCard {...provided.draggableProps} ref={provided.innerRef}>
      <StyledLessonHeader>
        <StyledFlexRow sx={{ alignItems: 'center' }}>
          <DragHolder provided={provided} />
          <Typography variant="body1b">
            รายการที่ {index + 1} : {contentTypeEnum[content.type].name}
          </Typography>
          <StyledMiniIconBtn
            onClick={async () => {
              await fetchCourse()
              await fetchLearningPath()
              handleOptionChoosed()
            }}
          >
            <RefreshTwoTone color="primary" />
          </StyledMiniIconBtn>
        </StyledFlexRow>
        <StyledFlexRow sx={{ alignItems: 'end' }}>
          <StyledMiniIconBtn
            onClick={() =>
              dispatch(
                setFieldValue({
                  key: `formData.contents[${index}].isExpand`,
                  value: !expand,
                }),
              )
            }
          >
            {expand ? <KeyboardArrowUpTwoTone /> : <KeyboardArrowDownTwoTone />}
          </StyledMiniIconBtn>
        </StyledFlexRow>
      </StyledLessonHeader>
      <StyledFlexRow>{expand && getContent(content, index)}</StyledFlexRow>
    </StyledLessonCard>
  )
}

export default DragDropContent

const getContent = (content, index) => {
  switch (content.type) {
    case contentTypeEnum.LEARNING_PATH.key:
      return <LearningPath content={content} index={index} />
    case contentTypeEnum.COURSE.key:
      return <Course content={content} index={index} />
  }
}

export const DragHolder = ({ provided }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...provided.dragHandleProps}
    >
      <StyledDragHolder />
    </Box>
  )
}
