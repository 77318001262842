import { CContainer } from '../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../components/CRUD/components/Header'
import localforage from 'localforage'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { ListHeaderAction } from '../../../../components/CRUD/components/Header/components/listHeaderAction'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadcrumb } from './model/breadcrumb'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { useHistory } from 'react-router-dom'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../../utils/lib'
import callAxios from '../../../../utils/baseService'
import { stationFilter } from '../../../../utils/apiPath'
import { setFieldValue } from '../../../../redux/slices/crud'
import { store } from '../../../../App'
import { INVENTORY_SETTING, STOCK_PERMISSION } from '../../../../constants/stock'

export const VendorManagement = () => {
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  const [user] = useLocalStorage('user')
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  useEffect(() => {
    initialConfig(initialCrudConfig)
    fetchStationList()
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      fetchDataList(mutateFilterPropToBody).then()
    }
  }, [limit, order, page, sort, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleFiltering(mutateFilterSateToProp, mutateFilterPropToBody)
          }
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadcrumb}
          action={
            hasPermission ? (
              <ListHeaderAction
                onClick={() => {
                  localforage.removeItem('persist:root').then()
                  history.push(`${crudConfig.modulePath}/form`)
                }}
              />
            ) : (
              <div />
            )
          }
        />
      }
    />
  )
}

export const fetchStationList = async () => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }

  let result = []
  await callAxios
    .post(stationFilter, body)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const station = _.get(item, 'station', '')
        result.push({ label: station, name: uuid, })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList',
      value: {
        station: result ,
      },
    }),
  )
}
