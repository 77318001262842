import { useDispatch } from 'react-redux'
import { memo, useRef, useState } from 'react'
import { useInputField } from '../hooks/useInputField'
import Editor from '../../../components/Editor/BasicEditor'
import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

const CustomTextEditor = memo(({ disabled, fieldName, toolbar }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { value, message } = useInputField(fieldName)

  const editorRef = useRef(null)

  function handleChange(value) {
    dispatch(setFieldValue({ key: `formData.${fieldName}`, value: value }))
  }

  return (
    <>
      {loading && <div>Loading...</div>}
      <Editor
        // id="detail"
        // name="detail"
        onInit={() => {
          setLoading(false)
        }}
        plugins={[
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help',
          'image code',
        ]}
        toolbar={
          !_.isEmpty(toolbar)
            ? toolbar
            : 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | ' +
              'link image'
        }
        editorRef={editorRef}
        onBlur={() => handleChange(editorRef.current.getContent())}
        value={value || ''}
        disabled={disabled}
        textError={message}
      />
    </>
  )
})
CustomTextEditor.displayName = 'TextEditorName'
export { CustomTextEditor }
