import Select from '../../../../../../components/Input/Select'
import _ from 'lodash'
import { handleSelectRoomChange } from '../events'
import { useDispatch } from 'react-redux'

export const MeetingRoomSelect = ({
  roomIndex,
  roomAvailableOptions,
  meetingRoomDetail,
  roomDetailIndex,
  bookingDate,
  cardIndex,
  validateIndex,
  errors,
}) => {
  const dispatch = useDispatch()
  return (
    <Select
      boxSx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}
      labelSx={{
        textWrap: 'nowrap',
        fontWeight: 700,
        pl: 2,
      }}
      textErrorSx={{ mb: 0 }}
      selectSx={{ width: '100%' }}
      dataTestid="select-meetingRoom"
      id="meetingRoomUuid"
      name="meetingRoomUuid"
      required
      labelText={`ห้องที่ ${roomIndex + 1}`}
      placeholder="กรุณาเลือกห้อง"
      value={_.get(meetingRoomDetail, 'meetingRoom.uuid')}
      options={roomAvailableOptions}
      handleChange={(e) => {
        dispatch(
          handleSelectRoomChange(
            e,
            roomDetailIndex,
            roomAvailableOptions,
            bookingDate,
            cardIndex,
          ),
        )
      }}
      showTextError={
        !_.isEmpty(
          errors,
          `roomManagement.roomManagementMeetingRoom[${validateIndex}].meetingRoomUuid`,
        )
      }
      textError={_.get(
        errors,
        `roomManagement.roomManagementMeetingRoom[${validateIndex}].meetingRoomUuid`,
        '',
      )}
    />
  )
}
