import * as yup from 'yup'
import _ from 'lodash'

export const validationSchema = yup.object({
  yearBudget: yup
    .object()
    .nullable()
    .test('validate-year-budget', 'กรุณาเลือก ปี Budget', (yearBudget) => {
      return _.get(yearBudget, 'value', null)
    }),
  costCenter: yup
    .object()
    .nullable()
    .test('validate-cost-center', 'กรุณาเลือก Cost center', (costCenter) => {
      return _.get(costCenter, 'value', null)
    }),
  accountCode: yup
    .object()
    .nullable()
    .test('validate-account-code', 'กรุณาเลือก Account code', (accountCode) => {
      return _.get(accountCode, 'value', null)
    }),
  remark: yup.string().nullable().max(3000, 'กรุณาระบุภายใน 3000 ตัวอักษร'),
  budgetDetails: yup.array().of(
    yup.object().shape({
      data: yup.array().of(
        yup.object().shape({
          value: yup
            .number()
            .nullable()
            .test(
              'validate-budget-required',
              'กรุณาระบุ ภายใน 10 ตัวเลข',
              (value, context) => {
                const { required } = context.parent

                if (_.isUndefined(required) && _.isNull(value)) return true

                return Number.isNaN(value)
                  ? null
                  : !_.isNull(value) && Number(value) <= 9999999999
              },
            ),
        }),
      ),
    }),
  ),
})
