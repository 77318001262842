import _ from 'lodash'
import * as yup from 'yup'

export const validationSchema = yup.object({
  oldAccountCode: yup
    .string()
    .max(255, 'Old Account Code กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  newAccountCode: yup
    .string()
    .max(255, 'New Account Code กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  category: yup
    .object()
    .nullable()
    .test(
      'empty-check',
      'กรุณาเลือก Categories',
      (category) => _.get(category, 'value', null) != null,
    )
    .shape({
      name: yup.string(),
      label: yup.string(),
      value: yup.string(),
    }),
  description: yup
    .string()
    .nullable()
    .max(3000, 'Description กรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุ กรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  active: yup.boolean(),
})
