import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from './Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table'
import _ from 'lodash'
import { openCourseDrawer } from '../../../../../redux/slices/ETestingDashboard'
import { fetchLearnerResultScore } from '../../../../../modules/ETesting/Dashboard/View/handler/fetchDashboardDetail'
import { filterEtestingDashboardOverviewLearner } from '../../../../../utils/apiPath'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ActionETestDashboard = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { uuid } = useParams()
  const { onDelete } = useSelector((state) => state.table)
  const viewPath = _.get(row, 'viewPath', undefined)
  const viewId = _.get(condition, 'viewId', undefined)
  const viewDashboardActionType = _.get(
    condition,
    'viewDashboardActionType',
    undefined,
  )
  const mutateFilterPropToBody = _.get(
    condition,
    'mutateFilterPropToBody',
    undefined,
  )
  const viewUrl = !_.isUndefined(viewId)
    ? `${condition.viewPath}/${row[viewId]}`
    : _.isUndefined(viewPath)
    ? `${condition.viewPath}/${row.uuid}`
    : viewPath
  const editUrl = `${condition.editPath}/${row.uuid}`

  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={async () => {
            if (viewDashboardActionType === 'จำนวนผู้ได้คะแนน') {
              await fetchLearnerResultScore(
                mutateFilterPropToBody(uuid),
                filterEtestingDashboardOverviewLearner,
                row.score,
              ),
                dispatch(
                  openCourseDrawer({
                    header: `รายชื่อผู้สอบ (ได้ ${row.score} คะแนน)`,
                    drawerType: 'formData',
                    courseType: 'resultScore',
                    score: row.score,
                  }),
                )
            } else dispatch(setOnView({ history, viewUrl }))
          }}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionETestDashboard
