import { store } from '../../../../App'
import { validateFormData } from '../../../../components/CRUD/handler/handleSubmitClick'
import { validationSchema } from '../schema/schema'
import { openDialog } from '../../../../redux/slices/dialog'
import { handleProcessSubmitForm } from './handleProcessSubmitForm'

export const handleSubmitClick = async () => {
  const { formData } = store.getState().crud
  const isValid = await validateFormData(formData, validationSchema)

  if (isValid) {
    store.dispatch(
      openDialog({
        title: 'ยืนยันบันทึกข้อมูล',
        message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        handleConfirm: () => handleProcessSubmitForm(),
        isCloseDialog: false,
      }),
    )
  }
}
