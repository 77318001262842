import _ from 'lodash'
import { assessmentField } from './../../../constants/eEvaluation'

export const isHaveType = (list) => !_.isUndefined(list) && list.length > 0

export const getAssessmentPointer = (assessments, keyField) => {
  return assessments.map((item) => ({
    uuid: item.uuid,
    section: item.section,
    key: item.key,
    keyField,
  }))
}

export const getQuestionPointer = (questions) => {
  return questions.map((item) => {
    let obj = {
      uuid: item.uuid,
      section: item.section,
      type: item.type,
      key: item.key,
      keyField: assessmentField.question,
    }

    if (item.type == 'MULTIPLE') {
      obj = {
        ...obj,
        setTypeOfAnswers: _.get(item, 'setTypeOfAnswers', false),
        typeOfAnswers: _.get(item, 'typeOfAnswers', 'EQUAL'),
        numberOfAnswers: _.get(item, 'numberOfAnswers', 0),
      }
    }

    return obj
  })
}

export const getBetweenRedirectPage = (redirectPage, item) => {
  const { from, to } = redirectPage
  if (to > from) return item.key > from && item.key < to
  else return item.key < from && item.key > to
}

export const resetNextQuestionAnswer = (state, from) => {
  return state.questions.map((item) => {
    if (item.key > from) {
      let answer = ['']
      if (item.type == 'MULTIPLE') {
        answer = item.answer.map((answ) => ({
          ...answ,
          isChecked: false,
        }))
      }
      return { ...item, isAnswered: false, answer }
    }
    return item
  })
}
