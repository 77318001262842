import { store } from '../../../../../App'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    station: _.get(filterProp, 'station', []),
    vendorType: _.get(filterProp, 'vendorType', []),
    dateStartDate: _.get(filterProp, 'dateStartDate', ''),
    dateFinishDate: _.get(filterProp, 'dateFinishDate', ''),
    renewDateStartDate: _.get(filterProp, 'renewDateStartDate', ''),
    renewDateFinishDate: _.get(filterProp, 'renewDateFinishDate', ''),
    createStartDate: _.get(filterProp, 'createStartDate', ''),
    createFinishDate: _.get(filterProp, 'createFinishDate', ''),
    updateStartDate: _.get(filterProp, 'updateStartDate', ''),
    updateFinishDate: _.get(filterProp, 'updateFinishDate', ''),
    insideContact: _.get(filterProp, 'insideContact', ''),
    outsideContact: _.get(filterProp, 'outsideContact', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    status: _.get(filterProp, 'status', []),
  }
}
