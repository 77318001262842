import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const CheckboxFilterTopic = () => {
  const dispatch = useDispatch()
  const { isFilterTopic } = useSelector(
    (state) => ({
      isFilterTopic: state.roomManagement.drawerCalendarFilter.isFilterTopic,
    }),
    shallowEqual,
  )
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isFilterTopic}
          onChange={(e) => {
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'isFilterTopic',
                value: e.target.checked,
              }),
            )
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'filterTopicQuery',
                value: '',
              }),
            )
          }}
        />
      }
      label="หลักสูตร / หัวข้อ"
    />
  )
}
