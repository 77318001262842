import dayjs from 'dayjs'
import _ from 'lodash'
import { handleChange } from '../../events'

const regex = /^[0-9]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  if (Number(e.target.value) > 9999) e.target.value = ''
  if (e.target.value[0] === '0') e.target.value = Number(e.target.value)
  return e.target.value
}

export const handlePasteFormat = (e) => {
  const key = e.clipboardData.getData('text')
  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}

export const onDateChange = (value, key) => (dispatch) => {
  const format = dayjs(value).format(window.__env__.REACT_APP_DATE_DB)
  dispatch(handleChange(key, _.isNull(value) ? '' : format))
}
