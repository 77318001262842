import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  resetPage,
  resetRowsPerPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import { staffProfileDashboard } from '../../../../../utils/apiPath'
import { TEST_RESULT_STATUS } from '../../../../../constants/eExamination'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'
import dayjs from 'dayjs'

export const scheduleStatuses = [
  {
    label: 'เข้าสอบ',
    fieldName: 'eExamDashboard.examinerDrawer.EXAMINEES',
    fontColor: '#99C2FF',
    value: 'EXAMINEES',
  },
  {
    label: 'ขาดสอบ',
    fieldName: 'eExamDashboard.examinerDrawer.ABSENCE',
    fontColor: '#B49CE4',
    value: TEST_RESULT_STATUS.ABSENCE,
  },
  {
    label: 'สอบผ่าน',
    fieldName: 'eExamDashboard.examinerDrawer.PASS',
    fontColor: '#1CC54E',
    value: TEST_RESULT_STATUS.PASS,
  },
  {
    label: 'สอบไม่ผ่าน',
    fieldName: 'eExamDashboard.examinerDrawer.NOT_PASS',
    fontColor: '#FFB547',
    value: TEST_RESULT_STATUS.NOT_PASS,
  },
]

export const examFieldTypes = [
  {
    label: 'สนามสอบบริษัท',
    fieldName: 'eExamDashboard.examinerDrawer.isCompany',
    type: 'บริษัท',
  },
  {
    label: 'สนามสอบสมาคม',
    fieldName: 'eExamDashboard.examinerDrawer.isAssociation',
    type: 'สมาคม',
  },
  {
    label: 'สนามสอบอื่นๆ',
    fieldName: 'eExamDashboard.examinerDrawer.isOther',
    type: 'อื่นๆ',
  },
]

export const headCellsExamField = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examField',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'other',
    label: 'อื่นๆ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวนการสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countExaminees',
    label: 'เข้าสอบ',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#99C2FF',
  },
  {
    id: 'countMissed',
    label: 'ขาดสอบ',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#B49CE4',
  },
  {
    id: 'countPass',
    label: 'สอบผ่าน',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#1CC54E',
  },
  {
    id: 'countFail',
    label: 'สอบไม่ผ่าน',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#FFB547',
  },
]

export const headCellsSram = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examField',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'other',
    label: 'อื่นๆ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวนการสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'passCount',
    label: 'สอบผ่าน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countFail',
    label: 'สอบไม่ผ่าน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countMissed',
    label: 'ขาดสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const onDownload = (selected, dataType) => async (dispatch) => {
  dispatch(startLoading())
  let body = {
    dataType: dataType,
    list: selected,
  }
  if (dataType === 'SRAM') {
    const getBody = await getDownloadSRamBody()
    body = {
      ...body,
      ...getBody,
    }
  }

  await dispatch(
    downloadFile({
      url: staffProfileDashboard + '/download',
      body: body,
      fileName: `รายการAgency.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const getDownloadSRamBody = () => {
  const { manageProfileDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      manageProfileDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      manageProfileDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
  }
  return body
}

export const changeDrawerType = (value) => (dispatch) => {
  dispatch(resetPage())
  dispatch(resetRowsPerPage())
  dispatch(
    setFieldValue({
      key: 'eExamDashboard.examinerDrawer.drawerType',
      value: value,
    }),
  )
}
