import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {
  StyledKeyword,
  StyledKeywordContainer,
  StyledMatchingContainer,
  StyledNumberBadge,
  StyledQuestion,
  StyledQuestionList,
  StyledSelect,
} from './Styled'
import { handleSelectionChange } from './events'

const Matching = ({ keyQuestion, question, isSubmit, questionResult }) => {
  const [shuffledKeywords, setKeyword] = useState([])
  const choices = _.get(question, 'choices', [])
  const answer = _.get(question, 'answer', [])

  const dispatch = useDispatch()

  useEffect(() => {
    setKeyword(_.shuffle(choices))
  }, [choices])

  return (
    <StyledMatchingContainer>
      <StyledKeywordContainer>
        {shuffledKeywords.map((data, index) => (
          <StyledKeyword
            selected={answer.some((ans) => ans.answerUuid === data.uuid)}
            key={index}
          >
            <Typography>({index + 1})</Typography>
            <Typography id="title">{_.get(data, 'title', '')}</Typography>
          </StyledKeyword>
        ))}
      </StyledKeywordContainer>

      {choices
        .filter((choice) => choice.isCorrect && !_.isEmpty(choice.question))
        .map((choice, index) => {
          const selected = answer.find(
            (ans) => ans.questionUuid === choice.uuid
          )
          const isAnswer =
            selected && selected.questionUuid === selected.answerUuid
          return (
            <StyledQuestionList
              resultProps={{ isSubmit, isAnswer, questionResult }}
              key={index}
            >
              <Box sx={{ display: 'flex', alignItems: 'start', gap: 2.5 }}>
                <Typography variant="body1b" minWidth={30}>
                  {_.get(question, 'sequence', 0)}.{index + 1}
                </Typography>
                <StyledQuestion>{_.get(choice, 'question', '')}</StyledQuestion>
              </Box>
              <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <StyledSelect
                  isSubmit={isSubmit}
                  isAnswer={isAnswer}
                  value={_.get(selected, 'answerUuid', '')}
                  onChange={(e) =>
                    dispatch(
                      handleSelectionChange(e, answer, choice, keyQuestion)
                    )
                  }
                >
                  {shuffledKeywords.map((data, index) => {
                    const dataUuid = _.get(data, 'uuid', '')
                    const isSelectedItem = answer.some(
                      (item) => item.answerUuid === dataUuid
                    )
                    return (
                      <MenuItem
                        sx={{ display: isSelectedItem ? 'none' : 'flex' }}
                        value={dataUuid}
                        key={index}
                      >
                        {index + 1}
                      </MenuItem>
                    )
                  })}
                </StyledSelect>
                {isSubmit && questionResult.resultType == 'SHOW_TRUE_FALSE' && (
                  <StyledNumberBadge isAnswer={isAnswer}>
                    {shuffledKeywords.findIndex(
                      (kw) => kw.uuid === choice.uuid
                    ) + 1}
                  </StyledNumberBadge>
                )}
              </Box>
            </StyledQuestionList>
          )
        })}
    </StyledMatchingContainer>
  )
}

export default Matching
