import { DetailsRoom } from './DetailsRoom'
import { shallowEqual, useSelector } from 'react-redux'
import { roomCountSelector } from '../../../../../../redux/slices/roomManagement/Drawer'

export const RoomItems = ({ bookingDate, innerRef }) => {
  const count = useSelector(roomCountSelector({ bookingDate }), shallowEqual)

  if (count === 0) return null

  return (
    <div ref={innerRef}>
      {Array.from({
        length: count,
      }).map((roomItem, idx) => (
        <DetailsRoom key={idx} idx={idx} bookingDate={bookingDate} />
      ))}
    </div>
  )
}
