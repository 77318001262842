import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, Divider, IconButton } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import * as events from './event'
import { StyledColumn } from '../Styled'
import {
  arrayStatus,
  arrayCheckbox,
  defaultFilter,
  arrayWarehouseType,
} from './model'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../components/FilterDrawer/Styled'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../utils/lib'
import DateRangeInput from '../../../../components/DateRangeInput'
import InputBase from '@mui/material/InputBase'
import _ from 'lodash'
import { validatePermission } from '../../../../utils/lib'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../constants/stock'
import SelectIcon from '../../../../components/redux/SelectIcon'

export const ListCheckbox = ({ stateFilter, setFilter, stationList }) => {
  return arrayCheckbox.map((item, index) => {
    return (
      <>
        {item.isStation ? (
          <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
            <FormControlLabel
              label={item.label}
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'check-stationChecked',
                  }}
                  name={item.nameCheckBox}
                  checked={stateFilter.stationChecked}
                  onChange={(e) => {
                    events.handleChange({
                      value: e.target.checked,
                      key: e.target.name,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              }
            />
            {stateFilter.stationChecked && (
              <StyledList>
                <ListStation
                  status={stateFilter.station}
                  filter={stateFilter}
                  setFilter={setFilter}
                  optionList={stationList}
                />
              </StyledList>
            )}
          </StyledGroupBox>
        ) : (
          <StyledGroupBox key={index}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{
                      'data-testid': `check-${item.nameCheckBox}`,
                    }}
                    name={item.nameCheckBox}
                    checked={stateFilter[item.nameCheckBox]}
                    onChange={(e) => {
                      events.handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                        filter: stateFilter,
                        setFilter,
                      })
                    }}
                  />
                }
                label={item.label}
                sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
              />

              {stateFilter[item.nameCheckBox] && (
                <>
                  {!item.isDate && !item.isStation && (
                    <InputBase
                      sx={{ borderRadius: 0 }}
                      type="basic"
                      inputProps={
                        item.nameInput == 'peopleNumber'
                          ? {
                              'data-testid': `input-${item.nameInput}`,
                              type: 'number',
                              min: '0',
                              onInput: handleNumberInput,
                              onKeyDown: handleNumberKeyDown,
                              onPaste: handlePasteFormat,
                            }
                          : { 'data-testid': `input-${item.nameInput}` }
                      }
                      name={item.nameInput}
                      disabled={!stateFilter[item.nameCheckBox]}
                      placeholder={item.placeholder}
                      value={stateFilter[item.nameInput]}
                      onWheel={(e) =>
                        e.target instanceof HTMLElement && e.target.blur()
                      }
                      onChange={(e) => {
                        events.handleChange({
                          key: e.target.name,
                          value: e.target.value,
                          filter: stateFilter,
                          setFilter,
                        })
                      }}
                    />
                  )}
                  {item.isDate && (
                    <DateRangeInput
                      dateState={{
                        startDate: stateFilter[item.nameInput].startDate
                          ? stateFilter[item.nameInput].startDate
                          : new Date(),
                        endDate: stateFilter[item.nameInput].toDate
                          ? stateFilter[item.nameInput].toDate
                          : new Date(),
                        display: stateFilter[item.nameInput].displayDate,
                        key: 'selection',
                      }}
                      onChange={(selectedDates) => {
                        events.handleSelectDate(
                          item.nameInput,
                          selectedDates,
                          stateFilter,
                          setFilter,
                        )
                      }}
                      placeholder={'จากวันที่ - ถึงวันที่'}
                    />
                  )}
                </>
              )}
            </FormGroup>
          </StyledGroupBox>
        )}
      </>
    )
  })
}

export const ListStatus = ({ status, filter, setFilter }) => {
  return arrayStatus.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'status',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListWarehouse = ({ filter, setFilter }) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE, STOCK_PERMISSION.MP],
  })
  let warehouseOptions = arrayWarehouseType
  if (!hasPermission) {
    warehouseOptions = arrayWarehouseType.filter(
      (item) => item.label != 'Academy',
    )
  }

  return (
    <>
      <SelectIcon
        dataTestId="select-warehosue"
        listOption={warehouseOptions}
        placeholder={'เลือกประเภท'}
        value={filter['warehouseType']}
        handleChange={(e) => {
          events.handleChange({
            key: 'warehouseType',
            value: e.target.value,
            filter,
            setFilter,
          })
        }}
      />
    </>
  )
}

export const ListStation = ({ filter, setFilter, optionList }) => {
  return optionList.map((item, index) => {
    const isChecked = _.includes(_.get(filter, 'station', []), item.uuid)
    return (
      <FormControlLabel
        key={index}
        label={item.stationName}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.uuid}
            checked={isChecked}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'station',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const { id } = useParams()
  const { stationList } = useSelector(
    (state) => ({
      stationList: state.stockList.stationList,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
        <FormControlLabel
          label="ประเภทคลังทรัพย์สิน"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-warehouseTypeChecked',
              }}
              name="warehouseTypeChecked"
              checked={filter.warehouseTypeChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.warehouseTypeChecked && (
          <StyledList>
            <ListWarehouse
              // status={filter.warehouseType}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>

      <StyledColumn sx={{ position: 'relative' }}>
        <ListCheckbox
          listArray={arrayCheckbox}
          stateFilter={filter}
          setFilter={setFilter}
          stationList={stationList}
        />
      </StyledColumn>
      <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <StyledList>
            <ListStatus
              status={filter.status}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => events.handleResetFilter(setFilter, defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter, id))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
