// import React from 'react'
import { mapStepSizeChart } from '../../../../../utils/lib'

export const optionsConfig = (highestScore) => {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
        position: 'nearest',

        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip')

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
            tooltipEl.innerHTML = '<div></div>'
            document.body.appendChild(tooltipEl)
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
            tooltipEl.classList.add('no-transform')
          }

          function getBody(bodyItem) {
            return bodyItem.lines
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || []
            const bodyLines = tooltipModel.body.map(getBody)

            let innerHtml = '<div style="margin: 8px">'

            titleLines.forEach(function (title) {
              innerHtml += '<div>' + title + '</div>'
            })

            bodyLines.forEach(function (body, i) {
              const colors = tooltipModel.labelColors[i]
              let style = 'background:' + colors.backgroundColor
              style += '; border-color:' + colors.borderColor
              style += '; border-width: 2px'
              style += '; width:10px ; height: 10px;'
              const span = '<div style="' + style + '"></div>'
              const styleFooter =
                'display: flex;flex-direction: row; ; align-items: center; gap: 8px '
              innerHtml +=
                '<div style="' + styleFooter + '">' + span + body + '</div>'
            })
            innerHtml += '</div>'

            tooltipEl.innerHTML = innerHtml
          }

          const position = context.chart.canvas.getBoundingClientRect()
          const lengthTitle = tooltipModel.title[0].length

          const pLeft =
            lengthTitle <= 100
              ? position.left +
                window.pageXOffset +
                tooltipModel.caretX +
                5 +
                'px'
              : position.left - 20 + 'px'
          const pTop =
            position.top + window.pageYOffset + tooltipModel.caretY + 5 + 'px'

          tooltipEl.style.opacity = 1
          tooltipEl.style.fontSize = '17px'
          tooltipEl.style.color = '#FFFFFF'
          tooltipEl.style.width =
            lengthTitle <= 60
              ? '300px'
              : lengthTitle > 60 && lengthTitle <= 100
              ? '450px'
              : 'auto'
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.backgroundColor = '#344563'
          tooltipEl.style.left = pLeft
          tooltipEl.style.top = pTop
          tooltipEl.style.right = '5%'
          tooltipEl.style.padding =
            tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
          tooltipEl.style.pointerEvents = 'none'
        },
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: mapStepSizeChart(true, highestScore),
          callback: function (label) {
            return label
          },
        },
      },
      yAxes: {
        grid: {
          display: true,
          lineWidth: 1,
        },
        ticks: {
          callback: function (value) {
            const label = this.getLabelForValue(value)
            if (label.length > 17) return label.substring(0, 17) + '...'
            return label
          },
        },
      },
    },
  }
}

export const setDataConfig = (labels, data) => {
  return {
    labels,
    datasets: [
      {
        label: 'Total ',
        data,
        count: data.length,
        backgroundColor: '#494DF4',
        borderColor: '#494DF4',
        borderRadius: 2,
        categoryPercentage: 1,
        barPercentage: 0.5,
      },
    ],
  }
}
