import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import { handleCloseDialog, handleSubmitAdd } from './events'
import useTheme from '@mui/system/useTheme'
import {
  HeaderItem,
  HeaderMainItem,
  HeaderTable,
  StyledDialog,
  StyledTable,
  StyledTableBody,
  StyledTableData,
  StyledTableHead,
  StyledTableHeader,
} from './Styled'
import DatePicker from '../../../../components/Input/DatePicker'
import TextInput from '../../../../components/Input/TextInput'
import _ from 'lodash'
import dayjs from 'dayjs'
import { setChangeStatusLicense } from '../../../../../src/redux/slices/apiToRcms'
import { licenseStatusText } from '../../event'

export const ChangeStatusDialog = ({
  rcmsUuid,
  agent_detail,
  license_detail,
  fetchData,
  finishDate,
}) => {
  const { isLoading } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
  }))
  const dispatch = useDispatch()
  const licenseStatus =
    _.get(agent_detail, '0.licenseStatus', 'ให้') !== 'ไม่ให้'
      ? 'ให้'
      : 'ไม่ให้'
  const [isStatus, setIsStatus] = useState(licenseStatus)
  const [disable, setDisable] = useState(true)
  const theme = useTheme()
  const [effectiveDate, setEffectiveDate] = useState(finishDate)
  const [expiryDate, setExpiryDate] = useState('')
  const [licenseNumber, setLicenseNumber] = useState('')
  const [exemptionFlag, setExemptionFlag] = useState('')
  const [remark, setRemark] = useState('')
  const onDateChange = (value, name) => {
    if (name === 'effectiveDate') {
      setExpiryDate(null)
      setEffectiveDate(value)
    }
    if (name === 'expiryDate') setExpiryDate(value)
  }
  const debouncedLicenseNumber = useMemo(
    () => _.debounce((e) => setLicenseNumber(e.target.value), 1000),
    []
  )

  const debouncedExemptionFlag = useMemo(
    () => _.debounce((e) => setExemptionFlag(e.target.value), 1000),
    []
  )

  const debouncedRemark = useMemo(
    () => _.debounce((e) => setRemark(e.target.value), 1000),
    []
  )

  useEffect(() => {
    if (isStatus === 'ให้') {
      if (dayjs(effectiveDate).isValid() && !_.isEmpty(remark)) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    } else {
      if (!_.isEmpty(remark)) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    }
  }, [remark, effectiveDate, isStatus])

  return (
    <>
      <DialogHeader />
      <StyledDialog>
        <HeaderMainItem>
          <HeaderItem>
            <Typography variant="body2" color="text.secondary">
              ชื่อใบอนุญาต
            </Typography>
            <Typography variant="body1">
              {license_detail.description}
            </Typography>
          </HeaderItem>
          <HeaderItem>
            <Typography variant="body2" color="text.secondary">
              ประเภทใบอนุญาต
            </Typography>
            <Typography variant="body1">{license_detail.category}</Typography>
          </HeaderItem>
        </HeaderMainItem>

        <Box>
          <HeaderTable>
            <Typography variant="body1">
              ปรับสถานะให้รายการผู้เรียนที่เลือก จำนวน {agent_detail.length} คน
            </Typography>
          </HeaderTable>
          <StyledTable>
            <StyledTableHead>
              <StyledTableHeader>
                <TableCell sx={{ minWidth: '25%' }}>
                  <Typography color="text.primary">รหัส Agent</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: '33%' }}>
                  <Typography color="text.primary">ชื่อ - นามสกุล</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: '22%' }}>
                  <Typography color="text.primary">ประเภท Agent</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: '22%' }}>
                  <Typography color="text.primary">สถานะใบอนุญาต</Typography>
                </TableCell>
              </StyledTableHeader>
            </StyledTableHead>
            <StyledTableBody>
              {_.map(agent_detail, (item, key) => (
                <StyledTableData key={key}>
                  <TableCell sx={{ minWidth: '25%' }}>
                    <Typography>{item?.fullAgentCode ?? '-'}</Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '33%' }}>
                    <Typography color="text.primary" variant="body2">
                      {item?.fullName ?? '-'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '22%' }}>
                    <Typography color="text.primary" variant="body2">
                      {item?.agentCategory ?? '-'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: '22%' }}>
                    {licenseStatusText(item?.licenseStatus)}
                  </TableCell>
                </StyledTableData>
              ))}
            </StyledTableBody>
          </StyledTable>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body2" color="text.secondary">
              สถานะใบอนุญาต
            </Typography>
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          </Box>
          <RadioGroup
            data-testid="radio-changeStatus"
            sx={{ pl: 1.5, gap: 0.5 }}
            aria-label="changeStatus"
            name="changeStatus"
            defaultValue={isStatus}
            value={isStatus}
            onChange={(e) => {
              const { value } = e.target
              setIsStatus(value)
            }}
          >
            <FormControlLabel value="ให้" control={<Radio />} label="ให้" />
            <FormControlLabel
              value="ไม่ให้"
              control={<Radio />}
              label="ไม่ให้"
            />
          </RadioGroup>
        </Box>
        {isStatus === 'ให้' && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mr: 3 }}>
              <HeaderItem>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 3,
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      gap: 0,
                    },
                  }}
                >
                  <DatePicker
                    id="effectiveDate"
                    name="effectiveDate"
                    labelText="วันมีผลบังคับ"
                    required
                    onChange={(value) => onDateChange(value, 'effectiveDate')}
                    placeholder=" "
                    value={effectiveDate}
                    textError={''}
                    disabledDateRange={false}
                    hideTextError={true}
                  />
                </Box>
              </HeaderItem>
              <HeaderItem>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 3,
                    [theme.breakpoints.down('sm')]: {
                      flexDirection: 'column',
                      gap: 0,
                    },
                  }}
                >
                  <DatePicker
                    id="finishDate2"
                    name="expiryDate"
                    labelText="วันหมดอายุ"
                    onChange={(value) => onDateChange(value, 'expiryDate')}
                    placeholder=" "
                    value={expiryDate}
                    disabledStartDate={effectiveDate}
                    disabledInput={!dayjs(effectiveDate).isValid()}
                    textError={''}
                    disabledDateRange={false}
                    hideTextError={true}
                  />
                </Box>
              </HeaderItem>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mr: 3 }}>
              <HeaderItem>
                <TextInput
                  id="license_number"
                  name="license_number"
                  labelText="License Number"
                  type="basic"
                  defaultValue={licenseNumber}
                  onChange={(e) => debouncedLicenseNumber(e)}
                  isShowTextError={false}
                />
              </HeaderItem>
              <HeaderItem>
                <TextInput
                  id="exemption_flag"
                  name="exemption_flag"
                  labelText="Exemption Flag"
                  type="basic"
                  defaultValue={exemptionFlag}
                  onChange={(e) => debouncedExemptionFlag(e)}
                  isShowTextError={false}
                />
              </HeaderItem>
            </Box>
          </>
        )}

        <Box sx={{ mr: 3 }}>
          <TextInput
            id="remark"
            name="remark"
            labelText="หมายเหตุ"
            type="basic"
            required
            onChange={(e) => debouncedRemark(e)}
            defaultValue={remark}
            isShowTextError={false}
            multiline={true}
            rows={2}
          />
        </Box>
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => {
              dispatch(setChangeStatusLicense([]))
              dispatch(handleCloseDialog())
            }}
            variant="outlined"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          loading={isLoading}
          onClick={() =>
            dispatch(
              handleSubmitAdd({
                rcmsUuid,
                agent_detail,
                isStatus,
                effectiveDate,
                expiryDate,
                licenseNumber,
                exemptionFlag,
                remark,
                fetchData,
              })
            )
          }
          color="primary"
          disabled={disable}
          endIcon=""
        >
          บันทึก
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}

export default ChangeStatusDialog

export const DialogHeader = () => {
  return (
    <Box id="dialog-header">
      <Typography variant="h6">ปรับสถานะใบอนุญาต</Typography>
    </Box>
  )
}
