import React from 'react'
import { ErrorOutline } from '@mui/icons-material'
import _ from 'lodash'
import { Tooltip } from '@mui/material'
import { useTheme } from '@mui/system'

export const TooltipError = ({ column, item }) => {
  const theme = useTheme()
  const error = _.get(item, 'error', [])

  return (
    <>
      {column === 'statusUpload' && error.length > 0 && (
        <Tooltip
          title={
            <ul style={{ margin: 0, marginLeft: '-20px' }}>
              {_.map(error, (err) => {
                return (
                  <li
                    key={err}
                    style={{
                      display: 'list-item',
                      p: 0,
                    }}
                  >
                    {err}
                  </li>
                )
              })}
            </ul>
          }
          followCursor
          componentsProps={{
            tooltip: {
              sx: {
                background: theme?.palette?.blue?.azure,
                color: theme?.palette?.common?.white,
                boxShadow: theme?.palette?.boxShadow?.main,
              },
            },
          }}
        >
          <ErrorOutline color="error" />
        </Tooltip>
      )}
    </>
  )
}
