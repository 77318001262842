import React from 'react'
import { Button, Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useSelector, shallowEqual } from 'react-redux'
// import _ from 'lodash'
import { OutsideContactInfo } from './OutsideContactInfo'
import { handleAddOutsideContact } from '../handler/handleAddOutsideContact'

const OutsideContact = () => {
  const { outsideContacts } = useSelector(
      (state) => ({
        outsideContacts: state.crud.formData.outsideContacts,
      }),
      shallowEqual,
    )

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6" color={'text.primary'}>
          การติดต่อจากภายนอก
        </Typography>
      </Box>
      { outsideContacts && outsideContacts.map((item, index) => {
        return <OutsideContactInfo key={index} item={item} index={index} />
      })}
      <Box width="120px">
        <Button
            size="medium"
            data-testid={`btn-add-inside`}
            startIcon={<AddIcon />}
            variant="contained"
              onClick={() => handleAddOutsideContact()}
        >
            เพิ่มผู้ติดต่อ
        </Button>
      </Box>
    </>
  )
}

export default OutsideContact
