import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { breadcrumb } from './model/breadcrumb'
import { CHeader } from '../../../components/CRUD/components/Header'
import { LoadingPage, StyledContainer } from '../Styled'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { Content } from './Content'
import { fetchDetails } from '../Form/handler/fetchDetails'
import { useSelector, shallowEqual } from 'react-redux'
import { ActionComponent } from './components/ActionComponent'
import _ from 'lodash'
import { HistoryDrawer } from './components/HistoryDrawer'

export const BudgetManagementView = () => {
  const { uuid } = useParams()
  const { isLoading, crudConfig } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    initialConfig(initialCrudConfig)
    if (uuid) fetchDetails('view', uuid).then()
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <StyledContainer isLoading={isLoading}>
      <CHeader
        title={`รายละเอียด ${crudConfig.moduleTitleFull}`}
        breadcrumbs={breadcrumb()}
        action={<ActionComponent uuid={uuid} />}
      />
      <Content />
      <LoadingPage isLoading={isLoading} />
      <HistoryDrawer />
    </StyledContainer>
  )
}
