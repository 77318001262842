import _ from 'lodash'

const setWidthAndHeight = (el, value) => {
  el.style.width = `${value}px`
  el.style.height = `${value}px`
}

const checkCondition = (item, condition) => {
  if (condition) setWidthAndHeight(item, 6)
  else setWidthAndHeight(item, 8)
}

export const handleAnimatedDots = (selectedNum) => {
  const roadMap = document.querySelector('#roadmap')

  if (_.isNil(roadMap)) return

  const allDots = roadMap.querySelectorAll('.dot') || []

  allDots.forEach((item, index) => {
    const first = index == 0
    const last = index == allDots.length - 1
    const second = index == 1
    const secondLast = index == allDots.length - 2

    if (index == selectedNum) {
      setWidthAndHeight(item, 8)
    } else if (index == selectedNum - 1) {
      checkCondition(item, first || second)
    } else if (index == selectedNum + 1) {
      checkCondition(item, last || secondLast)
    } else {
      if (first || last) setWidthAndHeight(item, 4)
      else if (second || secondLast) setWidthAndHeight(item, 6)
    }
  })
}