import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import ClassOutlined from '@mui/icons-material/ClassOutlined'
import MessageOutlined from '@mui/icons-material/MessageOutlined'

import { useHistory } from 'react-router-dom'
import { StyledHeaderContainer } from '../../Styled'
import { handleDownloadTemplate, onClickAdd } from './events'
import DropdownButton from './DropdownButton'

export const createOptions = [
  {
    text: 'Class',
    icon: <ClassOutlined />,
    link: '/e-evaluation/form/class',
  },
  {
    text: 'Standalone',
    icon: <MessageOutlined />,
    link: '/e-evaluation/form/standalone',
  },
]

const Header = () => {
  const history = useHistory()
  return (
    <StyledHeaderContainer>
      <Button
        data-testid="btn-download"
        variant="outlined"
        onClick={handleDownloadTemplate}
        size="m"
      >
        ดาวน์โหลด Template
      </Button>

      <DropdownButton label="สร้างแบบประเมิน" id="create-dropdown-eva">
        {createOptions.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`menu-create-${index}`}
            onClick={() => onClickAdd(option, history)}
          >
            <Box sx={{ mr: 1, display: 'flex' }}>
              {option.icon && <>{option.icon}</>}
            </Box>
            <Box>
              <Typography variant="body1">{option.text}</Typography>
            </Box>
          </MenuItem>
        ))}
      </DropdownButton>
    </StyledHeaderContainer>
  )
}
export default Header
