import _ from 'lodash'
import { store } from '../../../../../App'
import {
  paymentMethodEnum,
  paymentMethodTxt,
} from '../../../../../modules/Inventory/Transfer/Form/enum/paymentMethod'
import {
  replaceFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import { setFieldUploadValue } from '../../../../../redux/slices/uploadFile'

export const handleClickEditAsset = () => {
  setIsShowEditForm(true)
}

export const setIsShowEditForm = async (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isShowEditForm',
      value: value,
    }),
  )
  store.dispatch(replaceFieldError([]))
  if (!value) {
    clearInputEditForm()
  } else {
    const formObj = await setAssetDetailFormObj(false)
    store.dispatch(
      setFieldValue({
        key: 'formData',
        value: formObj,
      }),
    )
    if (formObj.paymentFileForm && !_.isEmpty(formObj.paymentFileForm)) {
      store.dispatch(
        setFieldUploadValue({
          key: `formData.paymentImgForm`,
          value: {
            files: [
              {
                id: 1,
                key: formObj.paymentFileForm?.key,
                displayName: formObj.paymentFileForm?.fileName,
                fileSize: formObj.paymentFileForm?.fileSize,
                fileType: formObj.paymentFileForm?.fileType,
                percent: 100,
              },
            ],
            isUploading: false,
          },
        }),
      )
    }
  }
}

export const setAssetDetailFormObj = async (isClear = false) => {
  const { assetDetailDrawer, formData } = store.getState().crud
  const formObj = {
    ...formData,
    skuUuid: isClear ? '' : _.get(assetDetailDrawer, 'uuid', ''),
    skuNoForm: isClear ? '' : _.get(assetDetailDrawer, 'skuNo', ''),
    unitPriceForm: isClear ? '' : _.get(assetDetailDrawer, 'unitPrice', ''),
    costCenterForm: isClear
      ? ''
      : {
          label: _.get(assetDetailDrawer, 'costCenter.descriptionName', '-'),
          value: _.get(assetDetailDrawer, 'costCenter.uuid', '-'),
        },
    skuStatusForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'status', 'INACTIVE'),
    assetNoForm: isClear ? '' : _.get(assetDetailDrawer, 'assetNo', ''),
    contractStartDateForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'contractStartDate', ''),
    contractEndDateForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'contractEndDate', ''),
    checkEndDateForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'checkEndDate', ''),
    renewContractDateForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'renewContractDate', ''),
    holderForm: isClear
      ? ''
      : !_.isEmpty(assetDetailDrawer?.holderInfo)
      ? {
          prefix: assetDetailDrawer?.holderInfo?.prefixTH,
          label: `${assetDetailDrawer?.holderInfo?.firstNameTH} ${assetDetailDrawer?.holderInfo?.lastNameTH}`,
          value: assetDetailDrawer?.holderInfo?.uuid,
          name: assetDetailDrawer?.holderInfo?.firstNameTH,
          lastname: assetDetailDrawer?.holderInfo?.lastNameTH,
          email: assetDetailDrawer?.holderInfo?.email,
          tel: assetDetailDrawer?.holderInfo?.tel,
        }
      : '',
    paymentTypeForm: isClear
      ? ''
      : assetDetailDrawer?.paymentType
      ? {
          label:
            paymentMethodTxt?.[_.get(assetDetailDrawer, 'paymentType', '-')],
          value: _.get(assetDetailDrawer, 'paymentType', ''),
        }
      : '',
    paymentDescForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'paymentType', '') ===
        paymentMethodEnum.CREDIT_CARD
      ? _.get(assetDetailDrawer, 'creditCardName', '')
      : _.get(assetDetailDrawer, 'paymentType', '') === paymentMethodEnum.OTHER
      ? _.get(assetDetailDrawer, 'creditCardDescription', '')
      : '',
    paymentFileForm: isClear
      ? ''
      : _.get(assetDetailDrawer, 'paymentType', '') ===
        paymentMethodEnum.CASH_INVOICE
      ? _.get(assetDetailDrawer, 'paymentFile', '')
      : '',
    paymentImgForm: isClear ? '' : '',
    payerForm: isClear
      ? ''
      : !_.isEmpty(assetDetailDrawer?.payerInfo)
      ? {
          prefix: assetDetailDrawer?.payerInfo?.prefixTH,
          label: `${assetDetailDrawer?.payerInfo?.firstNameTH} ${assetDetailDrawer?.payerInfo?.lastNameTH}`,
          value: assetDetailDrawer?.payerInfo?.uuid,
          name: assetDetailDrawer?.payerInfo?.firstNameTH,
          lastname: assetDetailDrawer?.payerInfo?.lastNameTH,
          email: assetDetailDrawer?.payerInfo?.email,
          tel: assetDetailDrawer?.payerInfo?.tel,
        }
      : '',
  }
  return formObj
}
// paymentImgForm --> to componenet
// paymentFileForm --> key: 'formData.paymentFileForm',
// value: {
//   key: key,
//   fileName: displayName,
//   fileType: fileType,
//   fileSize: fileSize,
// },

export const clearInputEditForm = async () => {
  const formObj = await setAssetDetailFormObj(true)
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: formObj,
    }),
  )
}
