import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

import { getManageContentLogin } from '../../../../services/manageContent'
import { convertFormatDateTime } from '../../../../utils/lib'
import { addFiledLoginPages } from './events'
const manageContentLogin = createSlice({
  name: 'manageContentLogin',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startUploadLoading: (state) => {
      state.isUploadLoading = true
    },
    stopUploadLoading: (state) => {
      state.isUploadLoading = false
    },
    setErrorUpload: (state, { payload }) => {
      state.error.upload = payload
    },
    setDrawer: (state, { payload }) => {
      if (payload.isClose) {
        state.drawer = initialState.drawer
        state.error = initialState.error
      } else {
        state.drawer = payload
      }
    },
    setChangeData: (state, { payload }) => {
      const { indexData, key, value } = payload
      _.set(state, `loginPages[${indexData}][${key}]`, value)
    },
    setChangeDrawer: (state, { payload }) => {
      const { indexData, values } = payload
      const defaultObj = _.get(state, `loginPages[${indexData}]`, {})
      _.set(state, `loginPages[${indexData}]`, {
        ...defaultObj,
        ...values,
      })
      state.drawer = initialState.drawer
      state.error = initialState.error
    },
    resetErrorUpload: (state) => {
      state.error.upload = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageContentLogin.fulfilled, (state, { payload }) => {
        const result = _.get(payload, 'data', null)
        state.isUploadLoading = false
        state.drawer = initialState.drawer
        state.uuid = _.get(result, 'uuid', '')
        state.loginPages = addFiledLoginPages(_.get(result, 'loginPages', []))
        state.createdAt = convertFormatDateTime({
          type: 'date',
          value: _.get(result, 'createdAt', ''),
        })
        state.createdBy = _.get(result, 'createdBy.name', '-')
        state.updatedAt = convertFormatDateTime({
          type: 'date',
          value: _.get(result, 'updatedAt', ''),
        })
        state.updatedBy = _.get(result, 'updatedBy.name', '-')
      })
      .addCase(getManageContentLogin.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startUploadLoading,
  stopUploadLoading,
  setDrawer,
  setChangeData,
  setChangeDrawer,
  setErrorUpload,
  resetErrorUpload,
} = manageContentLogin.actions

export default manageContentLogin.reducer
