import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleSelectAsset = (value, index) => {
  const assetCodeOption = _.get(value, 'assetCodeOption', [])
  store.dispatch(
    setFieldValue({
      key: `masterData.assetCodeOption[${index}].option`,
      value: {
        isLoading: false,
        options: assetCodeOption,
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: `formData.assets[${index}].assetCode`,
      value: '',
    }),
  )
  store.dispatch(
    setFieldValue({
      key: `formData.assets[${index}].qty`,
      value: '',
    }),
  )
}
