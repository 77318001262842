import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data) => {
  return {
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    remark: _.get(data, 'remark', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    uuid: _.get(data, 'uuid', undefined),
    createdAt: _.get(data, 'createdAt', ''),
    createdBy: _.get(data, 'createdBy', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    category: {
      label: _.get(data, 'category.name', undefined),
      name: _.get(data, 'category.name', undefined),
      value: _.get(data, 'category.uuid', undefined),
    },
  }
}
