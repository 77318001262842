import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { onFilterClick } from '../event'

export const handleFiltering = (table, filter, type) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(table, filter, type))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}
