import _ from 'lodash'
import { eTestEEvaText } from '../../../../../constants/manageClass'
import { openDialog } from '../../../../../redux/slices/dialog'
import { putStartETestEEva } from '../../../../../services/manageClass/detail'

export const checkETestEEvaInTime = (row) => {
  const startDateTime = _.get(row, 'startDate', null)
  const expiredDateTime = _.get(row, 'expiredDate', null)
  if (_.isNull(startDateTime)) return false
  if (_.isNull(expiredDateTime)) return true

  const formatStart = formatDateTime(startDateTime)
  const formatExpired = formatDateTime(expiredDateTime)
  const now = new Date()
  const isInTime = now > formatStart && now < formatExpired
  return isInTime
}

export const formatDateTime = (dateTime) => {
  const dateParts = dateTime.split('/') // dateParts = dd, mm, yy hh:mm
  const splitYearTime = dateParts[2].split(' ') // splitYearTime = yy, hh:mm
  const splitTime = splitYearTime[1].split(':') // splitTime = hh, mm
  return new Date( // new Date(20yy, mm-1, dd, hh, mm)
    '20' + splitYearTime[0],
    dateParts[1] - 1,
    dateParts[0],
    splitTime[0],
    splitTime[1]
  )
}

export const handleToggleETestEEva = (toggleProps) => async (dispatch) => {
  const { e, row, trainingPlanUuid } = toggleProps
  const isChecked = _.get(e.target, 'checked', false)
  const courseTestAndEvaluateEntityUuid = _.get(row, 'uuid', '')
  const testOrEvaluateUuid = _.get(row, 'testOrEvaluateUuid', '')
  const type = _.get(row, 'testAndEvaluateType', 'E_TESTING')
  const payload = {
    trainingPlanUuid,
    courseTestAndEvaluateEntityUuid,
    testOrEvaluateUuid,
    testAndEvaluateType: type,
  }

  const data = await dispatch(putCheckETestEEva(payload))
  if (data) {
    const text = isChecked ? 'เปิด' : 'ปิด'
    const body = { ...payload, isCheckExist: false }
    if (type == 'E_EVALUATION') {
      dispatch(
        openDialog({
          title: `${text}${eTestEEvaText[type]}`,
          message: isChecked
            ? 'คุณต้องการเปิดแบบประเมินให้ทำตอนนี้เลยหรือไม่'
            : 'คุณต้องการปิดแบบประเมินเลยหรือไม่',
          agreeText: text,
          handleConfirm: () => dispatch(putStartETestEEva(body)),
        })
      )
    } else {
      const isLimitTime = _.get(data, 'isLimitTime', false)
      const durationMinuteTime = _.get(data, 'durationMinuteTime', 0)
      const message = isLimitTime
        ? limitMessage(durationMinuteTime)
        : noLimitMessage
      const closeMessage = 'คุณต้องการปิดแบบทดสอบเลยหรือไม่'
      dispatch(
        openDialog({
          title: `${text}${eTestEEvaText[type]}`,
          message: isChecked ? message : closeMessage,
          agreeText: text,
          handleConfirm: () => dispatch(putStartETestEEva(body)),
        })
      )
    }
  }
}

export const putCheckETestEEva = (payload) => async (dispatch) => {
  const body = { ...payload, isCheckExist: true }
  const response = await dispatch(putStartETestEEva(body))
  return _.get(response, 'payload.response.data', null)
}

export const limitMessage = (durationMinuteTime) =>
  `แบบทดสอบนี้มีระยะเวลาในการเปิดให้ทำ ${durationMinuteTime} นาที คุณต้องการเปิดให้ทำตอนนี้เลยหรือไม่`

export const noLimitMessage =
  'แบบทดสอบนี้ไม่จำกัดเวลาทำข้อสอบ กรุณาปิดด้วยตนเอง คุณต้องการเปิดให้ทำตอนนี้เลยหรือไม่'
