import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { useParams, useHistory } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'

import Dot from '../../components/Table/Dot'
import HistoryDrawer from './HistoryDrawer'
import { callAPI, getStatusText } from '../../utils/lib'
import Breadcrumbs from '../../components/Breadcrumbs'
import { acquiredSkill, historyAcquiredSkill } from '../../utils/apiPath'

const AcquiredSkillView = () => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const history = useHistory()
  const [product, setProduct] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ระดับหลักสูตร',
      link: '/master-data/acquired-skill',
      pointer: true,
    },
    { title: 'รายละเอียดระดับหลักสูตร', link: '/', pointer: false },
  ]

  const fetchAcquiredSkillById = async () => {
    await callAPI({
      url: acquiredSkill + `/${uuid}`,
      method: 'GET',
      onSuccess: (res) => {
        setProduct(res)
      },
    })
  }

  const fetchHistory = async () => {
    const realPage = page === 0 ? 1 : page + 1
    const url = `${historyAcquiredSkill}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }
  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchAcquiredSkillById()
    setIsPageLoading(false)
  }, [])

  useEffect(() => {
    fetchHistory()
  }, [limit, page])

  const [isPageLoading, setIsPageLoading] = useState(false)

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
            }}
          >
            รายละเอียดระดับหลักสูตร : {product.acquiredSkill}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Box
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            sx={product.status !== 'DELETED' ? { mr: 3 } : {}}
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={onHistoryClick}
          >
            ประวัติการแก้ไข
          </Button>

          {product.status !== 'DELETED' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                history.push('/master-data/acquired-skill/edit/' + product.uuid)
              }}
            >
              แก้ไข
            </Button>
          )}
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ID
          </Typography>
          <Typography variant="body1">{product.id}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ระดับหลักสูตร
          </Typography>
          <Typography variant="body1">{product.acquiredSkill}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            สถานะ
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Dot sx={{ mt: 1.5, mr: 1.5 }} status={product.status} />
            <Typography variant="body1">
              {getStatusText(product.status)}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mx: 3 }} />
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'right',
          }}
        >
          <Typography variant="caption" color="text.lightGray">
            แก้ไขล่าสุดโดย{' '}
            {historyList && historyList.length > 0 && historyList[0].author}{' '}
            {historyList && historyList.length > 0 && historyList[0].createdAt}
          </Typography>
        </Box>
      </Card>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default AcquiredSkillView
