import React from 'react'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import { checkColorStatus } from './events'
import DotStyle from '../../../../../../components/redux/Dot'
import { Box } from '@mui/material'
import _ from 'lodash'

const Dot = ({ status, row }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <DotStyle status={_.toLower(row.onShelfStatus)} />
      <Typography variant="body2" color={checkColorStatus(status, theme)}>
        {STATUS_TEXT[status]}
      </Typography>
    </Box>
  )
}

export default Dot

export const STATUS_TEXT = {
  DRAFT: 'ฉบับร่าง',
  WAITING: 'รอเปิดรับสมัคร',
  OPENREGISTRATION: 'เปิดรับสมัคร',
  CLOSEREGISTRATION: 'ปิดรับสมัคร',
  INPROGRESS: 'กำลังอบรม',
  SUCCESS: 'จบการอบรม',
  COMPLETED: 'เสร็จสมบูรณ์',
  CANCEL: 'ยกเลิกรอบอบรม',
}
