export const headCells = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsSKU = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsAsset = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'assetNo',
    label: 'Item Name',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'ownerFName',
    label: 'ชื่อผู้ถือครอง',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsLicense = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'ownerFName',
    label: 'ชื่อผู้ถือครอง',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsFormNotImportModule = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsFormNotImportModuleSKU = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    minWidth: '100px',
    hideSortIcon: true,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsNotImportModule = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '300px',
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '100%',
    hideSortIcon: true,
  },
]
