import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import { convertTime } from '../../modules/MonthlyPlan/Drawer/View/Index'
import Table from '../../components/Table'
import ViewDrawer from './ViewDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import AlertDialog from '../../components/AlertDialog'
import FilterDrawer from './FilterDrawer'
import {
  fetchResponsibility,
  fetchTrainingPlatform,
} from './../MonthlyPlan/api/Training'
import { handleMessage } from '../MonthlyPlan/Drawer/Handle'

import {
  filterSpeakerBorrow,
  statusSpeakerBorrow,
  cancelSpeakerBorrow,
} from '../../utils/apiPath'
import { handleFilterTotal } from '../../redux/slices/table/events'
import { callAPI, convertFormatDateTime, getQueryParams } from '../../utils/lib'
import _ from 'lodash'

export const speakerType = {
  REQUESTER: {
    title: 'คำขอยืมวิทยากรจากโซนอื่น',
    bodyTypeRole: 'APPROVER',
  },
  APPROVER: {
    title: 'รายการยืมวิทยากร',
    bodyTypeRole: 'REQUESTER',
  },
}

export const body = {
  typeRole: '',
  limit: -1,
  order: 'DESC',
  page: 0,
  sort: 'id',
  status: [],
  startDateBorrow: '',
  finishDateBorrow: '',
  staffRequest: '',
  staffApprove: '',
  startDate: '',
  finishDate: '',
  responsibility: [],
  department: '',
  station: '',
  courseTh: '',
  courseEng: '',
  nameForLearner: '',
  trainingPlatform: [],
}

export const onView = (row, i, setIsDrawer) => {
  setIsDrawer({ status: true, type: 'view', publicId: row.uuid })
}

export const onFilterClick = async (
  filter,
  limit,
  order,
  sort,
  setTablePage,
  setText,
  setSearchText,
  fetchDataList,
  setFilterTotal
) => {
  setSearchText('')
  setText('')
  const statusList = []
  const responsibilityList = []
  const trainingPlatformList = []
  if (filter.statusChecked) {
    const STATUS_FILTER = {
      waitingChecked: 'WAITING',
      rejectChecked: 'REJECT',
      approveChecked: 'APPROVE',
      cancelChecked: 'CANCEL',
      draftChecked: 'DRAFT',
    }
    _.forOwn(filter.status, (num, key) => {
      if (num) {
        statusList.push(STATUS_FILTER[key])
      }
    })
    body.status = statusList
  } else {
    body.status = []
  }

  if (filter.responsibilityChecked) {
    _.forOwn(filter.responsibility, (num, key) => {
      if (num) {
        responsibilityList.push(key)
      }
    })
    body.responsibility = responsibilityList
  } else {
    body.responsibility = []
  }

  if (filter.trainingPlatformChecked) {
    _.forOwn(filter.trainingPlatform, (num, key) => {
      if (num) {
        trainingPlatformList.push(key)
      }
    })
    body.trainingPlatform = trainingPlatformList
  } else {
    body.trainingPlatform = []
  }
  body.courseEng = filter.courseEnChecked ? filter.courseEnText : ''
  body.courseTh = filter.courseThChecked ? filter.courseThText : ''
  body.nameForLearner = filter.nameForLearnerChecked ? filter.nameForLearnerText : ''
  body.department = filter.departmentChecked ? filter.departmentText : ''
  body.startDateBorrow = filter.startDateBorrowChecked
    ? filter.startDateBorrowText
    : ''
  body.finishDateBorrow = filter.finishDateBorrowChecked
    ? filter.finishDateBorrowText
    : ''
  body.startDate = filter.startDateChecked ? filter.startDateText : ''
  body.finishDate = filter.finishDateText ? filter.finishDateText : ''
  body.staffApprove = filter.staffApproveChecked ? filter.staffApproveText : ''
  body.staffRequest = filter.staffRequestChecked ? filter.staffRequestText : ''
  body.station = filter.stationChecked ? filter.stationText : ''
  setTablePage(0)
  fetchDataList('filter', limit, order, 1, sort, null)
  setFilterTotal(handleFilterTotal(filter))
}

const SpeakerList = ({ typeRole }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [filterMasterData, setFilterMasterData] = useState({
    responsibility: [],
    trainingPlatform: [],
  })
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [dialog, setDialog] = useState({
    open: false,
    variant: 'delete',
    title: '',
    icon: '',
    content: '',
    onConfirmClick: null,
    onCancelClick: null,
  })
  const [isDrawer, setIsDrawer] = useState({
    type: '',
    status: false,
    publicId: '',
  })
  const [filterTotal, setFilterTotal] = useState(0)

  const breadcrumbList = [
    { title: 'แผนรายเดือน', link: '/', pointer: false },
    { title: speakerType[typeRole].title, link: '/', pointer: false },
  ]

  const headCells = [
    {
      id: 'createdAt',
      label: 'ส่งคำขอเมื่อ',
      width: '150px',
      minWidth: '150px',
    },
    {
      id: 'requestName',
      label: 'ผู้ขอยืม',
    },
    {
      id: 'approverName',
      label: 'ผู้อนุมัติ',
    },
    {
      id: 'numSpeaker',
      label: 'จำนวนขอยืม',
      hideSortIcon: true,
      width: '100px',
      minWidth: '100px',
    },
    {
      id: 'numApproveSpeaker',
      label: 'จำนวนอนุมัติ',
      hideSortIcon: true,
      width: '100px',
      minWidth: '100px',
    },
    {
      id: 'dateTraining',
      label: 'วันที่อบรม',
      width: '120px',
      minWidth: '120px',
    },
    {
      id: 'statusApprove',
      label: 'สถานะ',
      width: '150px',
      minWidth: '150px',
      hideSortIcon: true,
    },
    {
      id: 'actionSpeaker',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      typeRole: typeRole,
      hideSortIcon: true,
    },
  ]

  const objOnLink = {
    borrowUuid: getQueryParams('borrowUuid'),
  }

  useEffect(() => {
    if (
      _.get(objOnLink, 'borrowUuid') &&
      _.get(objOnLink, 'borrowUuid') !== ''
    ) {
      setIsDrawer({
        status: true,
        type: 'view',
        publicId: _.get(objOnLink, 'borrowUuid'),
      })
    }
  }, [])

  useEffect(() => {
    fetchAllFilterData()

    fetchDataList('fetch', limit, order, page, sort, '')
  }, [limit, order, sort, page])

  const onDelete = (row) => {
    const values = { uuid: row.uuid }
    setDialog({
      ...dialog,
      variant: 'cancel',
      content: 'คุณต้องการยกเลิกคำขอยืมวิทยากรนี้หรือไม่',
      onConfirmClick: () => handleSubmitFormDrawer(values, 'CANCEL'),
      onCancelClick: () => setDialog({ ...dialog, open: false }),
      open: true,
    })
  }

  const handleSubmitFormDrawer = (values, approveType) => {
    let convertStaff = []
    if (values?.staff?.length) {
      convertStaff = values?.staff?.map((item) => {
        return { label: item.label, value: item.value }
      })
    }

    const body =
      approveType !== 'CANCEL'
        ? {
            uuid: values?.uuid || '',
            status: approveType,
            staffs: convertStaff || '',
            remark: values?.remark || '',
          }
        : {}

    const path =
      approveType !== 'CANCEL'
        ? statusSpeakerBorrow
        : cancelSpeakerBorrow + '/' + values?.uuid
    callAPI({
      url: path,
      method: approveType !== 'CANCEL' ? 'put' : 'get',
      body: body,
      onSuccess: () => {
        setDialog({
          open: true,
          title: 'สำเร็จ',
          variant: 'success',
          onConfirmClick: () => {
            handleClose()
            setDialog({ variant: 'success', open: false })
          },
        })
        fetchDataList('fetch', limit, order, page, sort, null)
      },
      onError: (er) => {
        const message = _.get(er, 'response.data.message', '')

        const constraintsKey = _.get(er, 'response.data.constraints.key', '')

        setDialog({
          open: true,
          variant: 'fail',
          title:
            constraintsKey === 'IsDateAvailableConstraint'
              ? 'เพิ่มตารางงานไม่ได้ มีกิจกรรมอื่นในช่วงเวลาเดียวกัน'
              : constraintsKey === 'IsValidateStatus'
              ? _.get(er, 'response.data.constraints.title', '')
              : 'ผิดพลาด',
          content:
            constraintsKey === 'IsDateAvailableConstraint'
              ? handleMessage({
                  listError: _.get(er, 'response.data.constraints.value', []),
                  text: 'วิทยากรและผู้เข้าร่วมประชุม',
                })
              : constraintsKey === 'IsValidateStatus'
              ? _.get(er, 'response.data.constraints.value', '')
              : message,
          onConfirmClick: () =>
            constraintsKey === 'IsDateAvailableConstraint'
              ? setDialog({
                  open: false,
                  variant: 'delete',
                  title: '',
                  icon: '',
                  content: '',
                  onConfirmClick: null,
                  onCancelClick: null,
                })
              : setDialog({
                  variant: 'fail',
                  title: 'ผิดพลาด',
                  content: message,
                  open: false,
                }),
          maxWidth: constraintsKey === 'IsDateAvailableConstraint' ? 'sm' : '',
        })
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }
  const handleClose = () => {
    setDialog({ ...dialog, open: false })
    setIsDrawer({
      type: '',
      status: false,
      publicId: '',
    })
  }

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    fetchDataList('search', limit, order, 1, sort, text)
  }

  const fetchAllFilterData = async () => {
    const fetch = await Promise.all([
      fetchResponsibility(),
      fetchTrainingPlatform(),
    ])
    setFilterMasterData({
      responsibility: fetch[0],
      trainingPlatform: fetch[1],
    })
  }
  const fetchDataList = async (method, limit, order, page, sort, search) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = !_.isNil(page) && page <= 0 ? 1 : +page
    body.page = realPage
    body.sort =
      sortBy === 'responsibility.responsibility' ? 'responsibility' : sortBy
    body.limit = limit
    body.order = order.toUpperCase()
    body.typeRole = speakerType[typeRole].bodyTypeRole

    if (method !== 'filter') {
      body.courseEng = ''
      body.courseTh = ''
      body.nameForLearner = ''
      body.department = ''
      body.startDateBorrow = ''
      body.finishDateBorrow = ''
      body.startDate = ''
      body.finishDate = ''
      body.staffApprove = ''
      body.staffRequest = ''
      body.station = ''
      body.status = []
      body.responsibility = []
      body.trainingPlatform = []
    }
    if (method === 'search') body.staffRequest = search

    setIsLoading(true)
    callAPI({
      method: 'post',
      url: filterSpeakerBorrow,
      body: body,
      onSuccess: (data) => {
        data.result.map((item) => {
          item.approverName =
            _.get(item, 'approver.firstNameTH', '-') +
            ' ' +
            _.get(item, 'approver.lastNameTH', '')
          item.requestName =
            _.get(item, 'requester.firstNameTH', '-') +
            ' ' +
            _.get(item, 'requester.lastNameTH', '')
          item.dateTraining =
            _.get(item, 'startDate', null) == _.get(item, 'finishDate', null)
              ? convertFormatDateTime({
                  value: _.get(item, 'startDate', null),
                  type: 'date',
                })
              : convertFormatDateTime({
                  value: _.get(item, 'startDate', null),
                  type: 'date',
                }) +
                ' - ' +
                convertFormatDateTime({
                  value: _.get(item, 'finishDate', null),
                  type: 'date',
                })
          item.timeTraining =
            convertTime(item.startTime) + ' - ' + convertTime(item.endTime)
          item.statusApprove = item.status
        })

        setDataList(data.result)
        setAllCount(data.totalCount)
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }

  const handleFetchData = () =>
    fetchDataList('fetch', limit, order, page, sort, null)

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">{speakerType[typeRole].title}</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            isCheckBox={false}
            data={dataList}
            headCells={headCells}
            onView={(row, i) => {
              onView(row, i, setIsDrawer)
            }}
            onDelete={onDelete}
            placeholder={'ค้นหาผู้ขอยืม'}
            searchKey={'title'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={handleQuickSearch}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            onDownload={'hidden'}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <ViewDrawer
        open={isDrawer.status}
        isDrawer={isDrawer}
        onClose={handleClose}
        speakerType={typeRole}
        handleFetchData={handleFetchData}
        isSpeakerPage
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={handleClose}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        maxWidth={dialog.maxWidth}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        filterMasterData={filterMasterData}
        onFilterClick={(filter) =>
          onFilterClick(
            filter,
            limit,
            order,
            sort,
            setTablePage,
            setText,
            setSearchText,
            fetchDataList,
            setFilterTotal
          )
        }
      />
    </Box>
  )
}
export default SpeakerList
