import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  eLearning,
  eLearningModule,
  eLearningModuleCheckUpload,
  eLearningModuleCompleteMultiPart,
  eLearningModuleCreateMultiPart,
  eLearningModuleUploadPart,
} from '../../../utils/apiPath'

export const postCreateModule = createAsyncThunk(
  'moduleForm/postCreateModule',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningModule, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCreateModule = createAsyncThunk(
  'moduleForm/putCreateModule',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningModule, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postModuleCreateMultiPart = createAsyncThunk(
  'moduleForm/postModuleCreateMultiPart',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningModuleCreateMultiPart, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postModuleUploadPart = createAsyncThunk(
  'moduleForm/postModuleUploadPart',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningModuleUploadPart, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postModuleCompleteMultiPart = createAsyncThunk(
  'moduleForm/postModuleCompleteMultiPart',
  async ({ body, index }, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningModuleCompleteMultiPart, body)
      return { ...response, index }
    } catch (err) {
      return rejectWithValue(index, err)
    }
  },
)

export const getCheckUploadStatus = createAsyncThunk(
  'moduleForm/getCheckUploadStatus',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningModuleCheckUpload}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteModule = createAsyncThunk(
  'moduleForm/deleteModule',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${eLearning}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
