import dayjs from 'dayjs'

export const filtersDrawerEnum = {
  isOpen: false,
  dateFilterStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  dateFilterFinishDate: dayjs().format('YYYY-MM-DD'),
  ram: [],
  sram: [],
  station: [],
  skill: [],
  distribution: [],
  filterTotal: 0,
}

export const detailDrawerEnum = {
  isOpen: false,
}
