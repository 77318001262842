import * as yup from 'yup'

export const CONFIGURATION_SETTING = 'CONFIGURATION'

export const CONFIG_PERMISSION = {
  ADMIN: 'ADMIN',
}

export const CONFIG_OWNER = {
  ALL: 'ALL',
}


export const validationSchema = yup.object({
  //GENERAL
  NEW_CLASS_AFTER_APPROVE: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  CAN_EDIT_PREVIOUS_PLAN: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EDIT_TRAINING_PLAN_DATE_AHEAD: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  //E_LEARNING
  EL_UPLOAD_MAX_FILE_SIZE: yup.object().shape({
    size: yup
      .string().required('กรุณากรอกข้อมูล')
      .when('sizeType', (sizeType) => {
        if(sizeType === 'GB'){
            return yup.number().required('กรุณากรอกข้อมูล')
              .max(1, 'กรอกขนาดได้ 1 GB เท่านั้น')
              .min(1, 'กรอกขนาดได้ 1 GB เท่านั้น')
        }else{
            return yup.number().required('กรุณากรอกข้อมูล')
            .max(1023, 'กรอกขนาดได้ 0.1 - 1023 MB เท่านั้น')
            .min(0.1, 'กรอกขนาดได้ 0.1 - 1023 MB เท่านั้น')
        }
      }),
    sizeType: yup
      .string().required('กรุณากรอกข้อมูล'),
  }),
  EL_BATCH_START_AFTER_CLOSE_REGISTER: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EL_FACE_DETECTION_PERCENT: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EL_COUNTDOWN_FACE_DETECTION: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EL_COUNTDOWN_CLICK: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EL_FACE_IMAGE_RETENTION_PERIOD: yup.object().shape({
    year: yup
      .string().required('กรุณากรอกข้อมูล'),
    month: yup
      .string().required('กรุณากรอกข้อมูล')
      .max(11, 'กรุณากรอกตัวเลข 0 - 11 เท่านั้น'),
  }),
  EL_ID_CARD_RETENTION_PERION: yup.object().shape({
    year: yup
      .string().required('กรุณากรอกข้อมูล'),
    month: yup
      .string().required('กรุณากรอกข้อมูล')
      .max(11, 'กรุณากรอกตัวเลข 0 - 11 เท่านั้น'),
  }),
  EL_TRAINING_HISTORY_RETENTION_PERIOD: yup.object().shape({
    year: yup
      .string().required('กรุณากรอกข้อมูล'),
    month: yup
      .string().required('กรุณากรอกข้อมูล')
      .max(11, 'กรุณากรอกตัวเลข 0 - 11 เท่านั้น'),
  }),
  EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION: yup
    .string()
    .required('กรุณาเลือกช่วงเวลา'),
  //EExam
  CC_EMAIL_ANNOUNCEMENT_EXAMINATION: yup
    .array()
    .min(1, 'กรุณากรอกอีเมลผู้ที่เกี่ยวข้องอย่างน้อย 1 อีเมล'),
  EXAMINATION_APPLICATION_FEE: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  EXAMINATION_UPLOAD_OTHER_DOCUMENT: yup.object().shape({
    status: yup
      .string().required('กรุณากรอกข้อมูล'),
    label: yup
      .string().when('status', (status) => {
        if(status === EXAM_UPLOAD_DOCUMENT_STATUS.ACTIVE){
            return yup.string().nullable().required('กรุณากรอกข้อมูล')
        }else{
            return yup.string().nullable()
        }
      }),
  }),
  //Terms
  consentBodyTextEN: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  consentBodyTextTH: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  TERMS_AND_CONDITIONS_EN: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
  TERMS_AND_CONDITIONS_TH: yup
    .string()
    .required('กรุณากรอกข้อมูล'),
})

export const eConfigActionList = {
  NEW_CLASS_AFTER_APPROVE: 'การสร้างคลาสใหม่หลังจาก Approve แผนล่วงหน้า',
  CAN_EDIT_PREVIOUS_PLAN: 'อนุญาตให้แก้ไขแผนงานเดือนก่อนหน้าได้ไม่เกินวันที่',
  EDIT_TRAINING_PLAN_DATE_AHEAD: 'การแก้ไขวันที่อบรมในแผนรายเดือน ประเภทตารางงาน Training หลังจากแผนรายเดือนอนุมัติแล้ว',
  EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE: 'อนุญาตแก้ไขพื้นที่อบรมหลังจากอนุมัติแผนรายเดือน',
  EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE: 'อนุญาตให้แก้ไข Budget หลังจากอนุมัติแผนรายเดือน',
  //ELEARNING
  EL_UPLOAD_MAX_FILE_SIZE: 'ขนาดของไฟล์ที่อนุญาตให้อัปโหลด',
  EL_BATCH_START_AFTER_CLOSE_REGISTER: 'การตั้งค่าวันที่เริ่มวันอบรมหลังจากวันที่ปิดรับสมัครของหลักสูตรระดับสูง OIC',
  EL_FACE_DETECTION_PERCENT: 'จำนวนเปอร์เซนต์ความถูกต้องของการตรวจสอบเพื่อยืนยันตัวตนผู้เรียน (face detection)',
  EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING: 'จำนวนครั้งการสุ่มตรวจสอบเพื่อยืนยันตัวตนผู้เรียนระหว่างเรียนทุกๆ 30 นาที - จำนวนครั้งที่ต้องการสุ่ม',
  EL_COUNTDOWN_FACE_DETECTION: 'จำนวนครั้งการสุ่มตรวจสอบเพื่อยืนยันตัวตนผู้เรียนระหว่างเรียนทุกๆ 30 นาที - ระยะเวลานับถอยหลังระหว่างตรวจสอบใบหน้าผู้เรียน',
  EL_COUNTDOWN_CLICK: 'จำนวนครั้งการสุ่มตรวจสอบเพื่อยืนยันตัวตนผู้เรียนระหว่างเรียนทุกๆ 30 นาที - ระยะเวลานับถอยหลังระหว่างผู้เรียนคลิกยืนยันระหว่างเรียน',
  EL_FACE_IMAGE_RETENTION_PERIOD: 'ระยะเวลาการเก็บข้อมูลผู้เรียน - ไฟล์รูปภาพหน้าตรง',
  EL_ID_CARD_RETENTION_PERION: 'ระยะเวลาการเก็บข้อมูลผู้เรียน - ไฟล์บัตรประชาชน',
  EL_TRAINING_HISTORY_RETENTION_PERIOD: 'ระยะเวลาการเก็บข้อมูลผู้เรียน - ประวัติการเข้าเรียน',
  EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION: 'ช่วงเวลาการแจ้งเตือนให้ Admin อนุมัติผู้เรียน',
  //EExam
  CC_EMAIL_ANNOUNCEMENT_EXAMINATION: 'การประกาศผู้มีสิทธิ์สอบ',
  EXAMINATION_APPLICATION_FEE: 'ค่าสมัครสอบ',
  EXAMINATION_UPLOAD_OTHER_DOCUMENT: 'ประเภทไฟล์อื่นๆที่ต้องการให้ผู้สมัครแนบเพิ่ม',
  //Terms
  consentBodyTextEN: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice) - EN',
  consentBodyTextTH: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice) - TH',
  TERMS_AND_CONDITIONS_EN: 'ข้อตกลงและเงื่อนไขการใช้งาน (เฉพาะ E-Learning) - EN',
  TERMS_AND_CONDITIONS_TH: 'ข้อตกลงและเงื่อนไขการใช้งาน (เฉพาะ E-Learning) - TH',
}

export const EXAM_UPLOAD_DOCUMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const SIZE_TYPE = { B: 'B', KB: 'KB', MB: 'MB', GB: 'GB' }

export const SIZE_TYPE_VALUE = {
  B: 1,
  KB: 1000,
  MB: 1000000,
  GB: 1000000000,
}
