import { fetchDetail as fetchDetailCRUD } from '../../../../../components/CRUD/handler/fetchDetail'
import { store } from '../../../../../App'
import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { vendorType } from '../model/vendorType'

export const fetchDetail = async (uuid, getBodyMutation) => {
  await fetchDetailCRUD(uuid, getBodyMutation).then()
  const { formData } = store.getState().crud
  const station = _.get(formData, 'station', '')
  if (station)
    store.dispatch(
      setFieldValue({
        key: 'formData.station',
        value: { ...station, label: station?.station, value: station?.uuid },
      }),
    )
  const vendorTypeValue = _.get(formData, 'vendorType', '')
  const vendorObj = vendorType.find((item) => {
    return item.value === vendorTypeValue
  })
  if (vendorObj)
    store.dispatch(
      setFieldValue({
        key: 'formData.vendorType',
        value: vendorObj,
      }),
    )
  const insideContacts = _.get(formData, 'insideContacts', [])
  if (insideContacts?.length <= 0) {
    store.dispatch(
      setFieldValue({
        key: 'formData.insideContacts',
        value: [{ name: '' }],
      }),
    )
  }
}
