import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import styled from '@mui/system/styled'

export const BoxAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: 0,
  },
}))

export const ContentInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  minWidth: '70%',
  justifyContent: 'start',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const ContentScoreAndButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 1.5,
  width: 'auto',
  '& > .MuiBox-root:last-child': { marginLeft: 0 },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    paddingLeft: 0,
    justifyContent: 'space-between',
  },
}))

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    width: 'auto',
  },
}))

export const BoxCheckbox = styled(Checkbox)(({ theme }) => ({
  marginLeft: theme.spacing(-0.5),
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme?.palette?.action?.active,
  marginTop: theme.spacing(-2),
  ':hover': {
    background: theme?.palette?.primary?.outlinedHoverBackground,
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(0.5),
  },
}))

export const BoxScoreAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: 100,
  maxWidth: 120,
  marginLeft: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}))

export const StyledRadioContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  minWidth: '30%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
}))

export const StyledRadioFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& label': { marginLeft: '-11px', marginRight: theme.spacing(2) },
  '& .MuiTypography-root': { display: 'none' },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& label': { margin: 0 },
    '& .MuiTypography-root': { display: 'unset' },
  },
}))

export const StyledScoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
  },
}))
