import React, { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import SingleChoice from '../../SingleChoice'
import MultipleChoice from '../../MultipleChoice'
import FreeText from '../../FreeText'
import FillInBlankDrag from '../../FillInBlank/Drag'
import FillInBlankChoice from '../../FillInBlank/Choice'
import TrueFalse from '../../TrueFalse'
import Matching from '../../Matching'
import Sequence from '../../Sequence'
// import TrueFalse from '../../TrueFalse'

const MapQuestionType = ({ keyQuestion, indexQuestion }) => {
  const itemQuestion = useSelector(
    (state) =>
      state.eTestingForm.questions.find((item) => item.key === keyQuestion),
    shallowEqual
  )

  useEffect(() => {
    const playIcon = document.getElementsByClassName('react-player__play-icon')
    for (let i = 0; i < playIcon.length; i++) {
      playIcon[i].style.display = 'none'
    }
  }, [itemQuestion])

  switch (itemQuestion?.questionType) {
    case 'SINGLE':
      return (
        <SingleChoice keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
      )
    case 'MULTIPLE':
      return (
        <MultipleChoice
          keyQuestion={keyQuestion}
          indexQuestion={indexQuestion}
        />
      )
    case 'SEQUENCE':
      return (
        <Sequence keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
      )
    case 'FREE_TEXT':
      return (
        <FreeText keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
      )
    case 'MATCHING':
      return (
        <Matching keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
      )
    case 'TRUE_FALSE':
      return (
        <TrueFalse keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
      )
    case 'FILL_IN_BLANK_CHOICE':
      return (
        <FillInBlankChoice
          keyQuestion={keyQuestion}
          indexQuestion={indexQuestion}
        />
      )
    case 'FILL_IN_BLANK_DRAG':
      return (
        <FillInBlankDrag
          keyQuestion={keyQuestion}
          indexQuestion={indexQuestion}
        />
      )
    default:
      return <></>
  }
}
export default MapQuestionType
