import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Form, Formik } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchManageContent, handleError, handleSubmitForm } from './events'
import ContentForm from './Form'
import Header from './Header'
import { schema } from './schema'
import { LoadingPage } from './Styled'

const HomePage = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const history = useHistory()
  const { isLoading, data, error } = useSelector(
    (state) => ({
      isLoading: state.manageContentHome.isLoading,
      data: state.manageContentHome.data,
      error: state.manageContentHome.error.all,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(fetchManageContent(onEdit, setOnEdit))
  }, [])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        {data && (
          <Formik
            initialValues={data}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              dispatch(handleSubmitForm(values))
            }}
          >
            <Form>
              <Header />

              <ContentForm />
            </Form>
          </Formik>
        )}
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default HomePage
