import { Box, Button, IconButton, Typography } from '@mui/material'
import { NextButton, PrevButton, StyledRow } from '../../../Styled'
import {
  ArrowBackIos,
  ArrowForwardIos,
  FilterList,
  RefreshTwoTone,
} from '@mui/icons-material'
import useTheme from '@mui/system/useTheme'
import { handleDateCalendar, handleViewCalendar } from './event'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  fetchRoomDetailCalendar,
  handleOpenDrawerFilterCalendar,
} from '../../event'
import { LabelCurrentMonth } from './LabelCurrentMonth'
import { LabelCurrentWeek } from './LabelCurrentWeek'
import { fetchCalendar } from '../../../../../modules/RoomManagement/BookingRoomSection/event'

export const HeaderCalendar = (props) => {
  const { monthYear, viewCalendar, endDate, mondayDate, calendarPage } =
    useSelector(
      (state) => ({
        viewCalendar: state.roomManagement.viewCalendar,
        mondayDate: state.roomManagement.mondayDate,
        endDate: state.roomManagement.endDate,
        monthYear: state.roomManagement.monthYear,
        calendarPage: state.roomManagement.calendarPage,
      }),
      shallowEqual,
    )
  const { calendarComponentRef } = props
  const dispatch = useDispatch()
  const theme = useTheme()

  return (
    <StyledRow sx={{ justifyContent: 'space-between', overflowY: 'auto' }}>
      <StyledRow sx={{ gap: 0.25, height: 45 }}>
        <PrevButton
          onClick={() =>
            dispatch(
              handleDateCalendar({
                calendarComponentRef,
                type: 'prev',
                page: calendarPage,
              }),
            )
          }
        >
          <ArrowBackIos sx={{ width: 20, height: 20 }} color="primary" />
        </PrevButton>
        <Box
          sx={{
            backgroundColor: theme.palette?.grey?.grey100,
            py: 1,
            px: { sm: 2, xs: 0.5 },
          }}
        >
          <Typography
            sx={{
              typography: { sm: 'body1b', xs: 'body2b' },
              pb: { sm: '0', xs: '5px' },
              minWidth: 'max-content',
            }}
          >
            {viewCalendar === 'dayGridMonth' && (
              <LabelCurrentMonth monthYear={monthYear} />
            )}
            {viewCalendar === 'timeGridWeek' && (
              <LabelCurrentWeek mondayDate={mondayDate} endDate={endDate} />
            )}
          </Typography>
        </Box>
        <NextButton
          onClick={() =>
            dispatch(
              handleDateCalendar({
                calendarComponentRef,
                type: 'next',
                page: calendarPage,
              }),
            )
          }
        >
          <ArrowForwardIos sx={{ width: 20, height: 20 }} color="primary" />
        </NextButton>
      </StyledRow>
      <StyledRow>
        <Button
          variant={viewCalendar === 'dayGridMonth' ? 'contained' : 'text'}
          onClick={() => {
            dispatch(
              handleViewCalendar(
                calendarComponentRef,
                'dayGridMonth',
                calendarPage,
              ),
            )
          }}
        >
          MONTH
        </Button>
        <Button
          variant={viewCalendar === 'timeGridWeek' ? 'contained' : 'text'}
          onClick={() => {
            dispatch(
              handleViewCalendar(
                calendarComponentRef,
                'timeGridWeek',
                calendarPage,
              ),
            )
          }}
        >
          WEEK
        </Button>
      </StyledRow>
      <StyledRow>
        <IconButton>
          <RefreshTwoTone
            color="primary"
            onClick={() => {
              if (calendarPage == 'dashboard') {
                dispatch(fetchCalendar())
              } else {
                dispatch(fetchRoomDetailCalendar())
              }
            }}
          />
        </IconButton>
        <Button
          startIcon={<FilterList />}
          onClick={() => {
            dispatch(handleOpenDrawerFilterCalendar())
          }}
        >
          <Typography
            variant="body1b"
            sx={{ lineHeight: 1.75, display: { sm: 'block', xs: 'none' } }}
          >
            ตัวกรอง
          </Typography>
        </Button>
      </StyledRow>
    </StyledRow>
  )
}
