import React, { useState } from 'react'
import _ from 'lodash'
import { deleteCertificateBackground } from '../../../../../services/eCertiticate/form'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/eTestingForm'
import {
  setDisableButton,
  setEnableButton,
  loadingDialog,
  closeDialog,
  openDialog,
} from '../../../../../redux/slices/dialog'
import { useDispatch } from 'react-redux'

import { DialogDelete } from './Styled'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import { eCertificationBackground } from '../../../../../utils/apiPath'
import * as events from '../../event'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
    return
  }
  setOpen(false)
}

export const handleMoreMenu = (method, onHistoryClick) => (dispatch) => {
  switch (method) {
    case 'download':
      dispatch(events.onDownloadDetail())
      break
    case 'history':
      onHistoryClick()
      break
    default:
      break
  }
}

export const handleDeleteVersion = (uuid) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบข้อมูล',
      type: 'content',
      content: <DialogContentDelete />,
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      disableButton: true,
      handleConfirm: () => dispatch(submitDeleteVersion(uuid)),
    })
  )
}

export const DialogContentDelete = () => {
  const dispatch = useDispatch()
  const [toggleDelete, setToggleDelete] = useState(false)

  return (
    <DialogDelete>
      <Typography variant="body1">
        การลบข้อมูล จะ
        <span style={{ color: 'red' }}>ไม่มีผลย้อนหลัง </span>
        กับหลักสูตร หรือคลาสที่เลือกไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
        ยังคงสามารถใช้งานต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง ที่หน้าจัดการหลักสูตร
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="error"
              checked={toggleDelete}
              onChange={(e) => dispatch(handleToggleDelete(e, setToggleDelete))}
            />
          }
          label="ฉันรับทราบเงื่อนไขการลบข้อมูลแล้ว"
        />
      </Box>
    </DialogDelete>
  )
}

export const handleToggleDelete = (event, setToggleDelete) => (dispatch) => {
  setToggleDelete((prevToggle) => !prevToggle)
  const checked = event.target.checked
  !checked ? dispatch(setDisableButton()) : dispatch(setEnableButton())
}

export const submitDeleteVersion = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())

  const result = await dispatch(deleteCertificateBackground(uuid))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          window.location.href = `${eCertificationBackground}`
          dispatch(closeDialog())
        },
      })
    )
  }
  dispatch(stopLoading())
}
