import React from 'react'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { path } from '../../../../constants/path'
import { StyledHeaderContainer } from '../../Styled'
import AddIcon from '@mui/icons-material/Add'
import SettingsOutlined from '@mui/icons-material/SettingsOutlined'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../../constants/eCertification'
import { validateEPermission } from '../../../../utils/lib'

const Header = () => {
  const hasCreatePermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.CREATE,
  })
  const history = useHistory()
  const hasViewBackgroundPermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.VIEW,
  })
  return (
    <StyledHeaderContainer>
      {hasViewBackgroundPermission && (
        <Button
          startIcon={<SettingsOutlined />}
          data-testid="btn-download"
          variant="outlined"
          onClick={() => history.push(`${path.eCertification}/background`)}
          size="m"
        >
          จัดการภาพพื้นหลัง
        </Button>
      )}

      {hasCreatePermission && (
        <Button
          startIcon={<AddIcon />}
          data-testid="btn-create"
          variant="contained"
          onClick={() => history.push(`${path.eCertification}/form`)}
          size="m"
        >
          สร้างประกาศนียบัตร
        </Button>
      )}
    </StyledHeaderContainer>
  )
}
export default Header
