import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import { requisitionTicketStatus } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { fetchDataList } from '../../../../../components/CRUD/handler/fetchDataList'
import { mutateFilterPropToBody } from '../../../../../modules/Inventory/Requisition/Listing/mutation/filterPropToBody'
import {
  RequisitionStatus,
  RequisitionStatusText,
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import { countWaitingApprove } from '../../../../../modules/Inventory/Requisition/Listing/handler/countOtherWaitingApprove'
import { validatePermission } from '../../../../../utils/lib'
import _ from 'lodash'

export const onCancel = async (row, status) => {
  confirmDialog(row.uuid, status)
}

function confirmDialog(uuid, status) {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  if (RequisitionStatus.CANCELED === status) {
    DIALOG_TITLE = `ยืนยันการยกเลิก`
    agreeText = 'ยืนยัน'
    disagreeText = 'ปฏิเสธ'
  } else if (RequisitionStatus.REJECTED === status) {
    DIALOG_TITLE = `ยืนยันการปฏิเสธ`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
  }
  store.dispatch(
    openDialog({
      type: 'delete',
      title: DIALOG_TITLE,
      message: `คุณต้องการ${RequisitionStatusText?.[status]}รายการนี้ใช่หรือไม่`,
      agreeText: agreeText,
      disagreeText: disagreeText,
      handleConfirm: () => {
        changeTransferStatus(uuid, status)
      },
      isCloseDialog: false,
    }),
  )
}

const changeTransferStatus = async (uuid, status) => {
  const body = {
    uuid,
    status,
  }
  store.dispatch(loadingDialog())
  await callAxios
    .put(requisitionTicketStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            fetchDataList(mutateFilterPropToBody).then(() => {
              countWaitingApprove()
            })
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}

export const checkIsStaff = async () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasInventoryPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [
      STOCK_PERMISSION.ADMIN,
      STOCK_PERMISSION.MP,
      STOCK_PERMISSION.CREATE,
    ],
  })
  return _.isEmpty(hasInventoryPermission)
}

export const checkRejectAndApproveButtons = (props) => {
  const {
    row,
    isSameUser,
    hasAdminPermission,
    hasMpPermission,
    hasCreatePermission,
    checkStaffStationMatched,
  } = props
  const monthlyPlan = row?.monthlyPlan
  const status = row.status
  const isToInventory = hasMpPermission && hasCreatePermission
  const isMP = hasMpPermission
  let isShow = false
  if (hasAdminPermission) {
    isShow = true
  } else if (isToInventory) {
    if (
      [
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
        RequisitionStatus.WAITING_RETURN_APPROVE,
      ].includes(status) &&
      (!isSameUser || (isSameUser && !monthlyPlan)) &&
      checkStaffStationMatched
    ) {
      isShow = true
    }
  } else if (isMP) {
    if (
      [
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
      ].includes(status) &&
      !isSameUser &&
      monthlyPlan &&
      checkStaffStationMatched
    ) {
      isShow = true
    }
  }
  return isShow
}
