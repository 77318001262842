import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Launch from '@mui/icons-material/Launch'
import { setPathCreateCourse } from '../../../../../../../redux/slices/eLearning/learningPathForm'
import {
  closeDialog,
  openDialog,
} from '../../../../../../../redux/slices/dialog'
import Select from '../../../../../../../components/Input/Select'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../../components/redux/PopUpDialog/Styled'
import { StyledSmallButton } from '../Styled'
import { courseTypeOption } from './model'

const CreateCourseButton = () => {
  const dispatch = useDispatch()
  return (
    <StyledSmallButton
      variant="outlined"
      startIcon={<Launch />}
      onClick={() =>
        dispatch(
          openDialog({
            title: 'สร้างหลักสูตร',
            type: 'content',
            showButton: false,
            content: <SelectCourseTypeDialog />,
          }),
        )
      }
    >
      สร้างหลักสูตร
    </StyledSmallButton>
  )
}

export default CreateCourseButton

export const SelectCourseTypeDialog = () => {
  const pathCreateCourse = useSelector(
    (state) => state.eLearningPathForm.pathCreateCourse,
    shallowEqual,
  )
  const dispatch = useDispatch()

  return (
    <>
      <Box sx={{ px: 2, pb: 1 }}>
        <Select
          type="basic"
          dataTestid="select-course-type"
          id="courseType"
          name="courseType"
          showTextError={false}
          options={courseTypeOption}
          value={pathCreateCourse}
          handleChange={(e) => dispatch(setPathCreateCourse(e.target.value))}
        />
      </Box>
      <StyledDialogActions>
        <StyledCancelButton
          data-testid="btn-disagree"
          variant="outlined"
          onClick={() => dispatch(dispatch(closeDialog()))}
        >
          ยกเลิก
        </StyledCancelButton>
        <StyledLoadingButton
          data-testid="btn-agree"
          onClick={() => {
            window.open(pathCreateCourse, '_blank')
            dispatch(closeDialog())
          }}
        >
          ยืนยัน
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}
