import _ from 'lodash'
import { store } from '../../../../../App'
import {
  setFieldValue,
  replaceFieldError,
  setFieldError,
} from '../../../../../redux/slices/crud'
import { statusValueEnum } from '../enum/statusEnum'
import { notReturnValidation } from '../schema/schema'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'

export const handleSubmitDrawer = async (assetsIndex) => {
  const { formData } = store.getState().crud
  const isValid = await validateFormData(formData, notReturnValidation)
  if (isValid) {
    const remark = _.get(formData, 'drawer.remark', null)
    const reasonNotReturnFile = _.get(
      formData,
      'drawer.reasonNotReturnFile',
      null,
    )
    const asset = _.get(formData, `assets[${assetsIndex}]`, null)
    store.dispatch(
      setFieldValue({
        key: 'isOpenFormDrawer',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: `formData.assets[${assetsIndex}]`,
        value: {
          ...asset,
          withdrawStatus: statusValueEnum.NOT_RETURN,
          returnCheckbox: false,
          remark: remark,
          img: reasonNotReturnFile,
        },
      }),
    )
  }
}

export async function validateFormData(formData, validationSchema) {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}
