import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'VENDOR',
  moduleSlug: 'vendor-management',
  moduleTitleFull: 'Vendor Management',
  modulesTitleShort: 'Vendor',
  modulePath: '/inventory-data/vendor-management',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
