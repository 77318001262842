import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data) => {
  return {
    productType: _.get(data, 'productType', 'License & Software'),
    productName: _.get(data, 'productName', ''),
    vendor: _.get(data, 'vendor', ''),
    category: _.get(data, 'category', ''),
    subCategory: _.get(data, 'subCategory', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    remark: _.get(data, 'remark', ''),
    uuid: _.get(data, 'uuid', undefined),
  }
}
