/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import _ from 'lodash'
import Select from '../../../../components/Input/Select'
import { LIST_TYPE_TRAINING } from '../../Text'

import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import { convertTime, convertTimeToApi } from '../View/Index'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { getExpandDate } from './Handle'
import { convertFormatDateTime } from '../../../../utils/lib'

export const EventExpandDate = (props) => {
  const {
    stateForm,
    expandDate,
    onChangeForm,
    validateForm,
    setStateForm,
    setValidateForm,
  } = props

  const listExpandDate = _.get(stateForm, 'academyActivity.expandDate', [])
  return (
    <>
      {expandDate.length ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <Box sx={{ width: '50%' }}>
              <Typography variant="body1">วันที่</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant="body1" sx={{ display: 'flex' }}>
                ช่วงเวลา
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant="body1" sx={{ display: 'flex' }}>
                เวลาเริ่ม
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Typography variant="body1" sx={{ display: 'flex' }}>
                เวลาสิ้นสุด
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>
          </Box>
          {expandDate.map((item, index) => {
            const dateExpand = dayjs(item.date).format(
              window.__env__.REACT_APP_DATE_DB
            )

            const objExpandDate = listExpandDate.find((itemExpandDate) => {
              return itemExpandDate.date === dateExpand
            })

            const findRequireFile = _.get(
              validateForm,
              'academyActivity.expandDate',
              []
            ).find((itemExpand) => {
              return itemExpand.date === dateExpand
            })

            return (
              <Box
                key={`event-${index}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 3,
                  }}
                >
                  <Box sx={{ width: '50%' }}>
                    <Typography>
                      {convertFormatDateTime({
                        value: _.get(item, 'date', null),
                        type: 'date',
                      })}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Select
                      dataTestid="select-typeTime"
                      id="typeTime"
                      name="typeTime"
                      type="basic"
                      options={LIST_TYPE_TRAINING}
                      handleChange={(event) =>
                        onChangeForm({
                          event,
                          key: 'typeTime',
                          isArray: false,
                          date: dayjs(item.date).format(
                            window.__env__.REACT_APP_DATE_DB
                          ),
                          listExpandDate: _.get(
                            stateForm,
                            'academyActivity.expandDate',
                            []
                          ),
                          validateForm,
                          setStateForm,
                          setValidateForm,
                        })
                      }
                      value={_.get(objExpandDate, 'typeTime', 'FULL')}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <TimePicker
                      disabled={
                        _.get(objExpandDate, 'typeTime', 'FULL') === 'OTHER'
                          ? false
                          : true
                      }
                      id="startTime"
                      onChange={(event) => {
                        onChangeForm({
                          event,
                          key: 'startTime',
                          isArray: false,
                          date: dayjs(item.date).format(
                            window.__env__.REACT_APP_DATE_DB
                          ),
                          listExpandDate: _.get(
                            stateForm,
                            'academyActivity.expandDate',
                            []
                          ),
                          validateForm,
                          setStateForm,
                          setValidateForm,
                        })
                      }}
                      value={checkValueTime(objExpandDate, 'startTime')}
                      disabledStartTime={checkValueTime(
                        objExpandDate,
                        'startTime'
                      )}
                      disabledEndTime={checkValueTime(objExpandDate, 'endTime')}
                      helperText={_.get(findRequireFile, 'startTime', '')}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <TimePicker
                      disabled={
                        _.get(objExpandDate, 'typeTime', 'FULL') === 'OTHER'
                          ? false
                          : true
                      }
                      id="endTime"
                      onChange={(event) => {
                        onChangeForm({
                          event,
                          key: 'endTime',
                          isArray: false,
                          date: dayjs(item.date).format(
                            window.__env__.REACT_APP_DATE_DB
                          ),
                          listExpandDate: _.get(
                            stateForm,
                            'academyActivity.expandDate',
                            []
                          ),
                          validateForm,
                          setStateForm,
                          setValidateForm,
                        })
                      }}
                      value={checkValueTime(objExpandDate, 'endTime')}
                      disabledStartTime={checkValueTime(
                        objExpandDate,
                        'startTime'
                      )}
                      disabledEndTime={checkValueTime(objExpandDate, 'endTime')}
                      helperText={_.get(findRequireFile, 'endTime', '')}
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

const Index = (props) => {
  const {
    state,
    stateForm,
    setStateForm,
    options,
    validateForm,
    setValidateForm,
    statePermission,
    type,
  } = props

  const [expandDate, setExpandDate] = useState(
    _.get(stateForm, 'academyActivity.expandDate.value', [])
  )
  const defaultExpandDate = {
    typeTime: 'FULL',
    startTime: '08:30',
    endTime: '17:00',
  }

  useEffect(() => {
    getExpandDate(stateForm, setExpandDate, setStateForm, defaultExpandDate)
  }, [
    stateForm.academyActivity.startDate,
    stateForm.academyActivity.finishDate,
  ])
  return (
    <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography>รูปแบบการทำงาน</Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        </Box>
        <RadioGroup
          sx={{ gap: 1, pl: 1, width: '160px' }}
          aria-label="workPlace"
          defaultValue={_.get(stateForm, 'academyActivity.workPlace', 'office')}
          name="radio-buttons-group"
          onChange={(e) =>
            onChangeRadio(
              e,
              expandDate,
              validateForm,
              setStateForm,
              setValidateForm
            )
          }
        >
          <FormControlLabel
            value="office"
            control={<Radio />}
            label="Work from office"
          />
          <FormControlLabel
            value="home"
            control={<Radio />}
            label="Smart working"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            onChange={(value) =>
              onDateChange(value, 'startDate', setStateForm, setValidateForm)
            }
            isShouldDisableDate={true}
            value={checkValueDate(stateForm, 'startDate')}
            disabledStartDate={checkValueDate(stateForm, 'startDate')}
            disabledEndDate={checkValueDate(stateForm, 'finishDate')}
            textError={_.get(validateForm, 'academyActivity.startDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.startDate', '')
                ? false
                : true
            }
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="finishDate"
            name="finishDate"
            labelText="ถึงวันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            isShouldDisableDate={true}
            onChange={(value) =>
              onDateChange(value, 'finishDate', setStateForm, setValidateForm)
            }
            value={checkValueDate(stateForm, 'finishDate')}
            disabledStartDate={checkValueDate(stateForm, 'startDate')}
            disabledEndDate={checkValueDate(stateForm, 'finishDate')}
            textError={_.get(validateForm, 'academyActivity.finishDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.finishDate', '')
                ? false
                : true
            }
          />
        </Box>
      </Box>

      <EventExpandDate
        stateForm={stateForm}
        expandDate={expandDate}
        onChangeForm={onChangeForm}
        options={options}
        validateForm={validateForm}
        setStateForm={setStateForm}
        setValidateForm={setValidateForm}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextInput
          id="remark"
          name="remark"
          type="basic"
          labelText="รายละเอียดเพิ่มเติม"
          placeholder="รายละเอียดเพิ่มเติม"
          multiline={true}
          rows={3}
          onChange={(value) =>
            onChangeForm({
              event: value,
              key: 'remark',
              isArray: false,
              validateForm,
              setStateForm,
              setValidateForm,
            })
          }
          value={stateForm.academyActivity.remark}
          textError={_.get(validateForm, 'academyActivity.remark', '')}
        />

        <SelectGroupChip
          id="attendees"
          name="attendees"
          required
          placeholder={'เลือกผู้รับผิดชอบงาน'}
          labelText="ผู้รับผิดชอบงาน"
          options={options.staff}
          onChange={(event) =>
            onChangeForm({
              event,
              key: 'attendees',
              isArray: true,
              validateForm,
              setStateForm,
              setValidateForm,
            })
          }
          value={_.get(stateForm, 'academyActivity.attendees', '')}
          textError={_.get(validateForm, 'academyActivity.attendees', '')}
          disabled={statePermission}
        />
      </Box>
    </Box>
  )
}
export default Index

export const onChangeRadio = (
  e,
  expandDate,
  validateForm,
  setStateForm,
  setValidateForm
) => {
  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      workPlace: e.target.value,
      expandDate: _.defaultTo(expandDate, []),
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      expandDate: _.get(validateForm, 'academyActivity.expandDate', []),
    },
  }))
}

export const onDateChange = (event, key, setStateForm, setValidateForm) => {
  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: dayjs(event).format(window.__env__.REACT_APP_DATE_DB),
    },
  }))
  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      startDate: '',
      finishDate: '',
    },
  }))
}

export const onChangeForm = ({
  event,
  key,
  isArray,
  date,
  listExpandDate,
  validateForm,
  setStateForm,
  setValidateForm,
}) => {
  let value = ''
  let valueValidate = ''

  const listValidateExpandDate = _.get(
    validateForm,
    'academyActivity.expandDate',
    []
  )

  if (isArray) {
    value = event
  } else {
    value = event.target.value
  }

  if (key === 'typeTime') {
    let newValue = {}
    if (value === 'MORNING') {
      newValue = {
        [key]: value,
        startTime: '08:30',
        endTime: '12:00',
      }
    } else if (value === 'AFTERNOON') {
      newValue = {
        [key]: value,
        startTime: '13:00',
        endTime: '17:00',
      }
    } else if (value === 'FULL') {
      newValue = {
        [key]: value,
        startTime: '08:30',
        endTime: '17:00',
      }
    } else {
      newValue = {
        [key]: value,
      }
    }
    value = listExpandDate.length
      ? listExpandDate.map((item) => {
          let result = item
          if (item.date === date) {
            result = {
              ...item,
              ...newValue,
            }
          }
          return result
        })
      : []

    valueValidate = listValidateExpandDate.map((item) => {
      let result = item
      if (item.date === date) {
        result = {
          ...item,
          [key]: '',
        }
      }
      return result
    })
    key = 'expandDate'
  } else if (key === 'startTime' || key === 'endTime') {
    value = listExpandDate.length
      ? listExpandDate.map((item) => {
          let result = item
          if (item.date === date) {
            result = {
              ...item,
              [key]: convertTimeToApi(value),
            }
          }
          return result
        })
      : []

    valueValidate = listValidateExpandDate.map((item) => {
      let result = item
      if (item.date === date) {
        result = {
          ...item,
          [key]: '',
        }
      }
      return result
    })
    key = 'expandDate'
  }

  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: value,
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      [key]: valueValidate,
    },
  }))
}

export const checkValueTime = (objExpandDate, key) => {
  return _.get(objExpandDate, key, '')
    ? convertTime(_.get(objExpandDate, key, ''))
    : ''
}

export const checkValueDate = (stateForm, key) => {
  return _.get(stateForm, `academyActivity.${key}`, null) === 'Invalid Date'
    ? null
    : _.get(stateForm, `academyActivity.${key}`, null)
}

export const LabelRequired = ({ text }) => {
  return (
    <Typography sx={{ width: '35%', display: 'flex' }}>
      {text}
      <Typography sx={{ width: '35%', display: 'flex' }} color={'error'}>
        *
      </Typography>
    </Typography>
  )
}
