import { FormControl, MenuItem, Select } from '@mui/material'
import { store } from '../../../../../../../App'
import { setFieldValue } from '../../../../../../../redux/slices/crud'

export const SortDropdown = ({ sort }) => {
  return (
    <FormControl>
      <Select
        labelId="select-label"
        id="simple-select"
        value={sort}
        onChange={(e) =>
          store.dispatch(
            setFieldValue({ key: 'sortDb', value: e.target.value }),
          )
        }
      >
        <MenuItem value={'wrongDESC'}>ตอบผิด : สูง-ต่ำ</MenuItem>
        <MenuItem value={'correctDESC'}>ตอบถูก : สูง-ต่ำ</MenuItem>
        <MenuItem value={'sequence'}>ลำดับคำถาม</MenuItem>
      </Select>
    </FormControl>
  )
}
