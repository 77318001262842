import _ from 'lodash'
import { initialAttendance, initialAttendanceTrue } from '../DefaultValue'

export const handleInitialForm = (initialValues, setAttendance, formik) => {
  const isAttendance = _.get(initialValues, 'isAttendance', false)
  const attendanceCount = _.get(initialValues, 'attendanceCount', 0)
  const attendanceCriteria = _.get(initialValues, 'attendanceCriteria', 0)
  const qrCodeTime = _.get(initialValues, 'qrCodeTime', 0)
  const attendant = {
    isAttendance,
    attendanceCount,
    attendanceCriteria,
    qrCodeTime,
  }
  setAttendance(attendant)
  formik.setFieldValue('isAttendance', isAttendance)
  formik.setFieldValue('attendanceCount', attendanceCount)
  formik.setFieldValue('attendanceCriteria', attendanceCriteria)
  formik.setFieldValue('qrCodeTime', qrCodeTime)
}

export const handleSettingRadioChange = (e, setInitialValues) => {
  const value = _.get(e, 'target.value', 'no')
  const isAttendance = value == 'yes'
  const newAttendance = isAttendance ? initialAttendanceTrue : initialAttendance

  setInitialValues((prev) => ({
    ...prev,
    ...newAttendance,
  }))
}
