import _ from 'lodash'
import {
  setChangeFillInBlank,
  setChangeQuestionByKey,
} from '../../../../../redux/slices/eTestingForm'
import { alphabets } from '../../../../../constants/eTesting'
import {
  blankHtmlReg,
  blankReg,
} from '../../../Preview/Testing/Questions/QuestionCard/Choice/DragAndDrop/model'

export const getPlainTextWithBlank = (value) => {
  const newValue = value
    ?.replaceAll('<span class="blank"', '[<span')
    ?.replaceAll('</span>', '</span>]')
  const plainText = new DOMParser().parseFromString(newValue, 'text/html')
    .documentElement.textContent
  return plainText
}

export const getBlankArr = (value) => {
  const plainText = getPlainTextWithBlank(value)
  return plainText
    .split(blankReg)
    .filter((val) => val.includes('['))
    .map((x) => x.replace('[', '').replace(']', ''))
}

export const handleEditorChange = (newValue, props, isChoice) => (dispatch) => {
  const { answers, subTitle, setBlankNums, keyQuestion } = props
  const newBlanks = getBlankArr(newValue)
  const oldBlanks = getBlankArr(subTitle)
  const delIndex = oldBlanks.findIndex((blank) => !newBlanks.includes(blank))
  const diffIndex = newBlanks.findIndex((blank) => !oldBlanks.includes(blank))

  // re-sequencing blank alphabet
  let num = -1
  newValue = newValue.replace(blankHtmlReg, () => {
    num++
    return `<em>${alphabets[num]}</em>`
  })
  setBlankNums(newBlanks.length)

  const questionType = isChoice ? 'FILL_IN_BLANK_CHOICE' : 'FILL_IN_BLANK_DRAG'
  let newKeyword = answers.filter((ans) => newBlanks.includes(ans.target))
  if (delIndex > -1) {
    // delete with keyboard
    newKeyword = newKeyword.map((kw, i) => {
      const obj = {
        ...blankAnswerObj,
        questionType,
        id: `id-${i}`,
        sequence: i + 1,
        target: alphabets[i],
        title: kw.title,
        subAnswers: kw.subAnswers,
      }
      if (i >= delIndex) {
        obj.title = _.get(answers[i + 1], 'title', '')
        if (isChoice) {
          obj.subAnswers = _.get(answers[i + 1], 'subAnswers', [
            { ...blankSubAnswerObj },
          ])
        }
      }
      return obj
    })
  } else {
    if (diffIndex === newKeyword.length) {
      // add new blank at the end
      newBlanks.forEach((blank, i) => {
        if (answers.some((kw) => kw.target === blank)) return
        newKeyword.push({
          ...blankAnswerObj,
          questionType,
          id: `id-${i}`,
          sequence: i + 1,
          target: blank,
          title: isChoice ? '-' : '',
          subAnswers: isChoice ? [{ ...blankSubAnswerObj }] : [],
        })
      })
    } else if (diffIndex !== -1) {
      // add new blank between the sentence
      newKeyword.splice(diffIndex, 0, {
        ...blankAnswerObj,
        questionType,
        id: `id-${diffIndex}`,
        sequence: diffIndex + 1,
        target: alphabets[diffIndex],
        title: isChoice ? '-' : '',
        subAnswers: isChoice ? [{ ...blankSubAnswerObj }] : [],
      })
    }
  }

  // re-sequencing keyword alphabet
  newKeyword = newKeyword.map((val, i) => ({
    ...val,
    target: alphabets[i],
  }))
  const trickAnswers = answers.filter((ans) => _.isNil(ans.target))
  dispatch(
    setChangeFillInBlank({
      keyQuestion,
      subTitle: newValue,
      answers: _.concat(newKeyword, trickAnswers),
    })
  )
}

const blankAnswerObj = {
  id: 'id-1',
  target: null,
  title: '',
  sequence: 1,
  mediaUrl: '',
  isCorrect: true,
  score: 0,
  uuid: '',
  questionType: 'FILL_IN_BLANK_DRAG',
  subAnswers: [],
}

export const blankSubAnswerObj = {
  id: 'kw-1',
  uuid: '',
  isCorrect: false,
  title: '',
  sequence: 1,
}

export const handleAddAnswer = (keyQuestion, answers, target) => (dispatch) => {
  let key = 'answers'
  let value = [...answers]
  if (target) {
    const targetIndex = answers.findIndex((ans) => ans?.target === target)
    const subAnswers = [..._.get(answers[targetIndex], 'subAnswers', [])]
    const newSubAnswer = {
      ...blankSubAnswerObj,
      id: `kw-${subAnswers.length + 1}`,
      sequence: subAnswers.length + 1,
    }
    value = [...subAnswers, newSubAnswer]
    key = `answers[${targetIndex}].subAnswers`
  } else {
    const newAnswer = {
      ...blankAnswerObj,
      id: `id-${answers.length}`,
      sequence: answers.length + 1,
      isCorrect: false,
    }
    value = [...answers, newAnswer]
  }
  const result = { keyQuestion, key, value }
  dispatch(setChangeQuestionByKey(result))
}
