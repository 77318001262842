import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
export const ListMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(1.5),
}))

export const ButtonLabelText = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
}))

export const BoxButton = styled(Box)(({ theme, disabled }) => ({
  display: 'flex',
  background: disabled
    ? theme.palette?.action?.disabledBackground
    : theme?.palette?.primary?.main,
  borderRadius: theme.spacing(0.5),
}))
