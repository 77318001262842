import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { getQRForEnroll } from '../events'

export const ErrorPage = ({ uuid }) => {
  const dispatch = useDispatch()
  const { errorMessage } = useSelector(
    (state) => state.manageClassDetail.qrEnrollProps,
    shallowEqual
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img src="/icon/error.svg" width={83} height={83} />
      <Typography variant="h5">{errorMessage}</Typography>
      <Button
        data-testid="btn-reload"
        onClick={() => dispatch(getQRForEnroll(uuid))}
      >
        <Typography variant="h6" color={'primary'}>
          กรุณาลองอีกครั้ง
        </Typography>
      </Button>
    </Box>
  )
}
