import TextInput from '../../../../../../components/Input/TextInput'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  roomSelector,
  setRoom,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import _ from 'lodash'
import { handleNumberInput } from '../../../../../../utils/lib'
import { getErrorMessage, getIndexByBookingDate } from '../../../event'

export const NumberOfGroup = ({ bookingDate, roomIndex }) => {
  const dispatch = useDispatch()
  const numberOfGroup = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'numberOfGroup',
    }),
    shallowEqual,
  )
  const formError = useSelector(
    (state) => state.roomManagementDrawer.formError,
    shallowEqual,
  )

  return (
    <TextInput
      type="basic"
      id="numberOfGroup"
      name="numberOfGroup"
      required
      labelText={'จำนวนกลุ่ม (กลุ่ม)'}
      placeholder="กรุณากรอกจำนวนกลุ่ม"
      // disabled={disabledAll}
      value={numberOfGroup || ''}
      inputProps={{
        type: 'text',
        onInput: (e) => handleNumberInput(e),
      }}
      onChange={(e) => {
        const value = _.get(e, 'target.value', '').replace(/[^0-9]/g, '')
        dispatch(
          setRoom({
            key: 'numberOfGroup',
            value: value,
            bookingDate: bookingDate,
            roomIndex,
          }),
        )
      }}
      textError={getErrorMessage(
        formError,
        `roomBookingList[${getIndexByBookingDate(
          bookingDate,
        )}].rooms[${roomIndex}].numberOfGroup`,
      )}
      isShowTextError={
        !_.isEmpty(
          getErrorMessage(
            formError,
            `roomBookingList[${getIndexByBookingDate(
              bookingDate,
            )}].rooms[${roomIndex}].numberOfGroup`,
          ),
        )
      }
    />
  )
}
