import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { SIZE_TYPE } from '../../../constants/eConfig'
import { StyledColumnTextBox } from '../stlyed'

export const InitialUploadWording = ({
  acceptLabel,
  maxFileSize,
  maxLength,
}) => {
  return (
    <>
      <img src="/icon/upload_amico.svg" width={92} height={82} alt="" />

      <StyledColumnTextBox>
        <>
          <Typography variant="body1b">วางไฟล์ที่นี่</Typography>
          <Typography color="text.secondary">
            {acceptLabel ? acceptLabel : 'รองรับทุกนามสกุล'}
          </Typography>
        </>
        <Typography variant="body2" color="text.secondary">
          ขนาดไฟล์ไม่เกิน {_.get(maxFileSize, 'size', 0)}{' '}
          {_.get(maxFileSize, 'sizeType', SIZE_TYPE.B)}
        </Typography>
        {maxLength && (
          <Typography variant="body2b" color="text.secondary">
            (อัปโหลดได้ไม่เกิน {maxLength} ไฟล์)
          </Typography>
        )}
      </StyledColumnTextBox>

      <Typography variant="body1b">หรือ</Typography>
    </>
  )
}
