import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../utils/lib'

const ActionCourse = ({ row, rowIndex, onView, onEdit }) => {
  const [user] = useLocalStorage('user')
  const getPermissionCouseSettingView = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['VIEW'],
  })
  const getPermissionCouseSettingEdit = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['CREATE'],
    data: row,
  })
  return (
    <Box sx={{ minWidth: 120 }}>
      {getPermissionCouseSettingView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onView(row, rowIndex)}
        >
          <RemoveRedEyeTwoToneIcon color="action" />
        </IconButton>
      )}
      {getPermissionCouseSettingEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onEdit(row, rowIndex)}
        >
          <EditTwoToneIcon color="action" />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionCourse
