import { Box } from '@mui/material'
import React from 'react'
import TotalUser from '../TotalUser'
import TotalProspect from '../TotalProspect'
import UserAccess from '../UserAccess'
import UserLoginHistory from '../UserLoginHistory'

const index = () => {
  return (
    <Box>
      <Box>
        <TotalUser />
      </Box>
      <Box mt={3}>
        <TotalProspect />
      </Box>
      <Box mt={3}>
        <UserAccess />
      </Box>
      <Box mt={3}>
        <UserLoginHistory />
      </Box>
    </Box>
  )
}

export default index
