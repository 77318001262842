import TextInput from '../../../../components/Input/TextInput'
import { StyledColumn } from './Styled'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InsertChartTwoTone from '@mui/icons-material/InsertChartTwoTone'
import Button from '@mui/material/Button'
import { useEffect, useState, useMemo } from 'react'
import {
  getStaffInArea,
  putMonthlyPlanChangeSpeaker,
} from '../../../../services/monthlyPlan'

import {
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'

import { useDispatch } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import Autocomplete from '../../../../components/Input/Autocomplete'

import TextField from '@mui/material/TextField'
import { shallowEqual, useSelector } from 'react-redux'
import { schema } from './schema'
import _ from 'lodash'
import { Form, Formik } from 'formik'
import { stopLoading } from '../../../../redux/slices/monthlyPlan'

const EditInput = ({ setShow, type, item }) => {
  let { staffList } = useSelector(
    (state) => ({
      staffList: state.monthlyPlan.staffList,
      isLoading: state.monthlyPlan.isLoading,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')

  useEffect(() => {
    dispatch(getStaffInArea(_.get(user, 'defaultArea')))
  }, [])

  staffList = staffList.filter((a) => a.uuid !== item.staff.uuid)

  const staffOptions = staffList.map((item) => ({
    label:
      _.get(item, 'area[0].station.station', '') +
      ' ' +
      item.firstNameTH +
      ' ' +
      item.lastNameTH,
    value: item.uuid,
  }))

  const [bodyEdit, setBodyEdit] = useState({
    staffPlanWorkUuid: item.uuid,
    event: type === 'update' ? 'SPEAKER_UPDATED' : 'SPEAKER_CANCEL',
    remark: '',
    dates: [],
    workType: {
      uuid: 'f5330c9d-c9fb-459b-81e1-6eb93b04911f',
      workType: 'Training',
    },
  })

  const handleSubmitForm = (value) => async (dispatch) => {
    dispatch(
      openDialog({
        type: 'yesOrNot',
        title: 'ยืนยันการเปลี่ยนแปลงข้อมูล',
        message: 'คุณต้องการบันทึกการเปลี่ยนแปลงข้อมูลนี้หรือไม่',
        handleConfirm: () => dispatch(handleSubmitDialog(value)),
      }),
    )
  }
  const defaultStaff = useMemo(() => {
    const staffUuid = _.get(bodyEdit, 'dates[0].speakers[0].staff.uuid', '')
    const find = staffList.find((a) => a.uuid === staffUuid)

    return {
      label:
        _.get(find, 'firstNameTH', '') + ' ' + _.get(find, 'lastNameTH', ''),
      value: staffUuid,
    }
  }, [bodyEdit])

  const handleSuccess = () => (dispatch) => {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'บันทึกการเปลี่ยนแปลงข้อมูลเรียบร้อยแล้ว',
        handleConfirm: () => window.location.reload(),
      }),
    )
  }

  const getTitle = (constraintsKey, res) => {
    let title = 'ผิดพลาด'
    if (constraintsKey === 'IsDateAvailableConstraint') {
      title = 'เพิ่มตารางงานไม่ได้ มีกิจกรรมอื่นในช่วงเวลาเดียวกัน'
    } else if (_.get(res, 'meta.response.data.constraints.title', '')) {
      title = _.get(res, 'meta.response.data.constraints.title', '')
    }
    return title
  }

  const getMessage = (constraintsKey, res) => {
    let message = 'ระบบขัดข้อง'
    if (constraintsKey === 'IsDateAvailableConstraint') {
      message = handleMessage({
        listError: _.get(res, 'meta.response.data.constraints.value', []),
        text: 'วิทยากรและผู้เข้าร่วมประชุม',
      })
    } else if (constraintsKey === 'IsValidateStatus') {
      message = _.get(res, 'meta.response.data.constraints.value', '')
    } else if (_.get(res, 'meta.response.data.message', '')) {
      message = _.get(res, 'meta.response.data.message', '')
    }

    return message
  }

  const handleSubmitDialog = async (body) => {
    dispatch(loadingDialog())
    const res = await dispatch(putMonthlyPlanChangeSpeaker(body))
    dispatch(stopLoadingDialog())
    if (res.error) {
      const constraintsKey = _.get(
        res,
        'meta.response.data.constraints.key',
        '',
      )

      dispatch(
        openErrorDialog({
          title: getTitle(constraintsKey, res),
          message: getMessage(constraintsKey, res),
        }),
      )
    } else {
      dispatch(handleSuccess())
    }
    dispatch(stopLoading())
  }

  return (
    <Formik
      initialValues={bodyEdit}
      validationSchema={schema(type)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        dispatch(handleSubmitForm(values))
      }}
    >
      {(formik) => {
        const { errors, handleChange, setFieldValue, values } = formik

        let disable = true

        let checkDates = false
        if (values.dates.length > 0) {
          const regexExp =
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
          const check = regexExp.test(values.dates[0].speakers[0].staff.uuid)
          if (check) {
            checkDates = true
          }
        }

        disable =
          values.event === 'SPEAKER_UPDATED'
            ? checkDates && values.remark
              ? false
              : true
            : values.remark
            ? false
            : true

        return (
          <Form>
            <StyledColumn sx={{ gap: 0, mt: 2 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {item.staff.isChangeStatistic && (
                  <>
                    <InsertChartTwoTone />
                    {type === 'update' ? (
                      <Typography>
                        {' '}
                        มีการโอนย้ายคะแนนประเมินวิทยากรรายบุคคล
                      </Typography>
                    ) : (
                      <Typography>
                        {' '}
                        มีการยกเลิกผลประเมินวิทยากรรายบุคคล
                      </Typography>
                    )}
                  </>
                )}
              </Box>

              {type === 'update' && (
                <>
                  {' '}
                  <Autocomplete
                    name="dates"
                    labelText="วิทยากรใหม่"
                    options={[...staffOptions]}
                    required
                    isShowTextError={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={defaultStaff}
                    renderInput={(params) => (
                      <TextField
                        placeholder="เลือกวิทยากร"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': 'select-main-admin',
                        }}
                      />
                    )}
                    handleChange={(e) => {
                      setFieldValue('dates', [
                        {
                          typeTime: 'FULL',
                          date: item.date,
                          startTime: item.startTime,
                          endTime: item.endTime,
                          speakers: [
                            {
                              staff: {
                                id: 7,
                                uuid: e.target.id,
                              },
                              startTime: item.startTime,
                              endTime: item.endTime,
                            },
                          ],
                        },
                      ])
                      setBodyEdit((val) => ({
                        ...val,
                        dates: [
                          {
                            typeTime: 'FULL',
                            date: item.date,
                            startTime: item.startTime,
                            endTime: item.endTime,
                            speakers: [
                              {
                                staff: {
                                  id: 7,
                                  uuid: e.target.id,
                                },
                                startTime: item.startTime,
                                endTime: item.endTime,
                              },
                            ],
                          },
                        ],
                      }))
                    }}
                  />
                </>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pb: 1,
                }}
              >
                <TextInput
                  id="remark"
                  name="remark"
                  labelText="เหตุผล"
                  type="basic"
                  required
                  textError={_.get(errors, 'remark', '')}
                  onChange={(e) => {
                    handleChange(e)
                    setBodyEdit((val) => ({
                      ...val,
                      remark: e.target.value,
                    }))
                  }}
                  textInfo="ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร"
                  multiline={true}
                  rows={3}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 2,
                  width: '100%',
                  backgroundColor: 'background.paper',
                  gap: 2,
                  pb: 1,
                  pr: 2,
                }}
              >
                <Button
                  sx={{ minWidth: '50%' }}
                  variant="outlined"
                  color={'primary'}
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  sx={{ minWidth: '50%' }}
                  variant="contained"
                  color={'primary'}
                  type="submit"
                  disabled={disable}
                >
                  ยืนยัน
                </Button>
              </Box>
            </StyledColumn>
          </Form>
        )
      }}
    </Formik>
  )
}

export const handleRequireFile = (value) => {
  let status = true
  let resultMessage = {}

  if (value.bodyEdit.remark.length > 3000) {
    resultMessage = {
      ...resultMessage,
      remark: 'ระบุข้อความได้ไม่เกิน 3,000 ตัวอักษร',
    }
  }
  for (let key in resultMessage) {
    if (!_.isEmpty(String(resultMessage[key]))) {
      status = false
      break
    }
  }
  return { status, resultMessage }
}

export default EditInput

export const FormMessage = ({ listError }) => {
  let result = []
  if (!_.isEmpty(listError)) {
    let index = 0
    for (let name in listError) {
      const listValue = listError[name]
      result.push(
        <Box sx={{ mb: 2, mt: index === 0 ? 2 : 0 }}>
          <Typography variant="body1">กิจกรรมอื่นๆของ {name} :</Typography>
          {listValue.length ? (
            listValue.map((item, index) => (
              <Typography key={index} variant="body1">
                {item.date} เวลา {item.time + ' ' + item.title}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </Box>,
      )
      index++
    }
  } else {
    result.push(<></>)
  }

  return result
}

export const handleMessage = ({ listError, text = '' }) => {
  const newObj = listError.length ? _.groupBy(listError, 'name') : []
  return (
    <Box sx={{ mr: 2 }}>
      <Box>
        <Typography variant="body1">
          เนื่องจาก{text} มีกิจกรรมอื่นในช่วงเวลาเดียวกัน
          หากต้องการเพิ่มตารางงาน
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">กรุณาเลื่อนเวลาของกิจกรรม</Typography>
      </Box>
      <FormMessage listError={newObj} />
    </Box>
  )
}
