import dayjs from 'dayjs'
import _ from 'lodash'
import utc from 'dayjs/plugin/utc'

export const convertFormatDateTime = ({ value, type, defaultText = '' }) => {
  if (_.isNil(value)) return defaultText
  dayjs.extend(utc)
  let result = '-'
  if (type === 'date') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_SHOW)
  }

  if (type === 'dateTime') {
    result =
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_SHOW) +
      ' ' +
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'time') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'dayMonth') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_DAY_MONTH)
  }

  if (type === 'dateDb') {
    result = dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_DB)
  }

  return result
}
