import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

export const defaultFilter = {
  distributionChecked: false,
  distributionText: '',
  abbreviationChecked: false,
  abbreviationText: '',
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  levelOfLearner: {},
  levelOfLearnerChecked: false,
  statusChecked: false,
}

const FilterDrawer = ({ open, onClose, onFilterClick, levelOfLearnerList }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    if (!filterMemo && levelOfLearnerList.length) {
      let result = {}
      levelOfLearnerList.filter((item) => {
        const title = item.title + 'Checked'
        result = {
          ...result,
          [title]: {
            status: false,
            uuid: item.uuid,
          },
        }
      })
      setFilter({
        ...defaultFilter,
        levelOfLearner: result,
      })
    }
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  const handleDistributionTextChange = (e) => {
    setFilter({
      ...filter,
      distributionText: e.target.value,
    })
  }

  const handleAbbreviationTextChange = (e) => {
    setFilter({
      ...filter,
      abbreviationText: e.target.value,
    })
  }

  const handleChangeStatus = (event) => {
    if (event.target.name === 'activeChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: event.target.checked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'inActiveChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: event.target.checked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'deletedChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: event.target.checked,
        },
      })
    }
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }

  const handleChangeLevel = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const LevelOfLearnerChecked = filter?.levelOfLearner?.[name]

    setFilter({
      ...filter,
      levelOfLearner: {
        ...filter.levelOfLearner,
        [name]: {
          ...LevelOfLearnerChecked,
          status: checked,
        },
      },
    })
  }

  const FormLevelOfLearnerList = () => {
    if (levelOfLearnerList.length) {
      return levelOfLearnerList.map((item, index) => {
        const title = item.title + 'Checked'

        const checkedStatus = filter?.levelOfLearner?.[title]?.status || false

        return (
          <FormControlLabel
            key={index}
            label={item.title}
            control={
              <Checkbox
                data-testid={`checkbox-level-${index}`}
                name={title}
                checked={checkedStatus}
                onChange={handleChangeLevel}
              />
            }
          />
        )
      })
    }
  }

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-title"
                name="distributionChecked"
                checked={filter.distributionChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'distributionChecked',
                  })
                }}
              />
            }
            label="ช่องทางการจำหน่าย"
            sx={!filter.distributionChecked ? { marginBottom: -1 } : {}}
          />
          {filter.distributionChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.distributionChecked}
              placeholder="ค้นหาช่องทางการจำหน่าย"
              value={filter.distributionText}
              onChange={handleDistributionTextChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-abbreviation"
                name="abbreviationChecked"
                checked={filter.abbreviationChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'abbreviationChecked',
                  })
                }}
              />
            }
            label="ชื่อย่อ"
            sx={!filter.abbreviationChecked ? { marginBottom: -1 } : {}}
          />
          {filter.abbreviationChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.abbreviationChecked}
              placeholder="ค้นหาชื่อย่อ"
              value={filter.abbreviationText}
              onChange={handleAbbreviationTextChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>

      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="ระดับของผู้เรียน"
          control={
            <Checkbox
              data-testid="checkbox-distribution-level"
              name="LevelOfLearnerChecked"
              checked={filter.levelOfLearnerChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'levelOfLearnerChecked',
                })
              }}
            />
          }
        />
        {filter.levelOfLearnerChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            {FormLevelOfLearnerList()}
          </Box>
        )}
      </StyledGroupBox>

      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              data-testid="checkbox-distribution-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
              <FormControlLabel
                label="เปิดใช้งาน"
                control={
                  <Checkbox
                    data-testid="checkbox-status-active"
                    name="activeChecked"
                    checked={filter.status.activeChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
              <FormControlLabel
                label="ปิดใช้งาน"
                control={
                  <Checkbox
                    data-testid="checkbox-status-inactive"
                    name="inActiveChecked"
                    checked={filter.status.inActiveChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
              <FormControlLabel
                label="ถูกลบแล้ว"
                control={
                  <Checkbox
                    data-testid="checkbox-status-deleted"
                    name="deletedChecked"
                    checked={filter.status.deletedChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
            </Box>
          </>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid={`btn-clear-filter`}
          variant="outlined"
          onClick={onClearClick}
        >
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
