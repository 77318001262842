import { store } from '../../../../App'
import { fetchOverview } from './fetchOverview'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../redux/slices/crud'

export const handleClearFilter = (key) => {
  const { filtersDrawer } = store.getState().crud.mixModuleDashboard
  let countFilterTotal = filtersDrawer?.filterTotal ?? 1
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'mixModuleDashboard.filtersDrawer.dateFilterStartDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'mixModuleDashboard.filtersDrawer.dateFilterFinishDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'mixModuleDashboard.filtersDrawer.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'mixModuleDashboard.filtersDrawer.isClearFilter',
        value: 'date',
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'mixModuleDashboard.filtersDrawer.filterTotal',
      value: countFilterTotal - 1,
    }),
  )
  fetchOverview()
}
