import React from 'react'
import {
  setSectionResult,
  setSubmit,
  setTimeUp,
  startLoading,
  stopLoading,
} from './../../../../../../redux/slices/eTestingPreview'
import {
  closeDialog,
  openDialog,
} from './../../../../../../redux/slices/dialog'
import DialogContent from './DialogContent'

export const countdown = (time, timer, setTime) => (dispatch) => {
  const { hrs, mins, secs } = time
  if (hrs === 0 && mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (mins === 0 && secs === 0) {
    setTime([hrs - 1, 59, 59])
  } else if (secs === 0) {
    setTime([hrs, mins - 1, 59])
  } else {
    setTime([hrs, mins, secs - 1])
  }

  if (hrs === 0 && mins === 5 && secs === 1) {
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'mini',
        content: <DialogContent />,
        handleConfirm: () => dispatch(closeDialog()),
      })
    )
  }

  if (hrs === 0 && mins === 0 && secs === 1) {
    dispatch(setTimeUp())
    dispatch(startLoading())
    setTimeout(() => {
      dispatch(setSubmit())
      dispatch(setSectionResult())
      dispatch(stopLoading())
    }, 3000)
  }
}
