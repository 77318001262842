import { USER_POINT_EVENT } from '../../../../../constants/learningPoint'

export const DIALOG_TAB = {
  MANAGE: 'MANAGE',
  REDEEM: 'REDEEM',
  CONFIRMATION: 'CONFIRMATION',
}

export const historyHeadCells = [
  { id: 'event', label: 'รายการ', hideSortIcon: true },
  { id: 'historyPoint', label: 'คะแนนที่เปลี่ยนแปลง', hideSortIcon: true },
  { id: 'updatedAt', label: 'วันและเวลา', hideSortIcon: true, minWidth: 130 },
  { id: 'remark', label: 'หมายเหตุ', hideSortIcon: true },
  { id: 'ActionViewPointHistory', label: 'คำสั่ง', hideSortIcon: true },
]

export const eventOptions = [
  { label: 'เพิ่ม', value: USER_POINT_EVENT.INCREASE_FROM_ADMIN },
  { label: 'ลด', value: USER_POINT_EVENT.DECREASE_FROM_ADMIN },
]

export const catalogHeadCells = [
  {
    id: 'image',
    minWidth: '42px',
    label: '',
    hideSortIcon: true,
    disablePadding: true,
  },
  {
    id: 'name',
    minWidth: 'unset',
    label: '',
    hideSortIcon: true,
    disablePadding: true,
    disableTruncate: true,
  },
  {
    id: 'point',
    minWidth: '150px',
    label: '',
    hideSortIcon: true,
    type: 'number',
    haveUnit: 'คะแนน',
  },
  { id: 'actionCart', label: '', hideSortIcon: true, disablePadding: true },
  {
    id: 'qty',
    minWidth: '80px',
    label: ' จำนวนคงเหลือ',
    hideSortIcon: true,
    disablePadding: true,
    type: 'number',
    haveUnit: 'ชิ้น',
    sx: { textAlign: 'right' },
  },
]
