import { store } from '../../../../../App'
import { PRODUCT_TYPE, TicketStatus } from '../../../../../constants/stock'
import _ from 'lodash'
import { fromTypeEnum } from '../../Form/enum/assetTypEnum'

export const headCellsStock = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const headCellsStockAsset = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'costCenter.descriptionName',
    label: 'Cost center',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const headCellsAsset = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'costCenter.descriptionName',
    label: 'Cost center',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'assetNo',
    label: 'Item Name',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'holder.firstNameTH',
    label: 'ชื่อผู้ถือครอง',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
]

export const headCellsLicense = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'costCenter.descriptionName',
    label: 'Cost center',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
  {
    id: 'holder.firstNameTH',
    label: 'ชื่อผู้ถือครอง',
    isCustom: true,
    customWidth: '180px',
    hideSortIcon: true,
  },
]

export const headCellsStockForm = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวน',
    disablePadding: false,
    width: '50px',
    hideSortIcon: true,
  },
  {
    id: 'transferEditInput',
    label: ' ',
    disablePadding: false,
    inputType: 'number',
    width: '150px',
    hideSortIcon: true,
  },
  {
    id: 'transferEditRemark',
    label: ' ',
    disablePadding: false,
    hideSortIcon: true,
    inputType: 'number',
  },
]

export const headCellsStockSkuNoForm = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    disablePadding: false,
    width: '200px',
    hideSortIcon: true,
  },
  {
    id: 'transferEditInput',
    label: ' ',
    disablePadding: false,
    inputType: 'switch',
    width: '50px',
    hideSortIcon: true,
  },
  {
    id: 'transferEditRemark',
    label: ' ',
    disablePadding: false,
    hideSortIcon: true,
    inputType: 'switch',
  },
]

export const headCellsStockEditApprove = [
  {
    id: 'no',
    label: 'No.',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
    width: '350px',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    disablePadding: false,
    width: '200px',
    hideSortIcon: true,
  },
  {
    id: 'quantity',
    label: 'จำนวนเดิม',
    disablePadding: false,
    width: '50px',
    hideSortIcon: true,
  },
  {
    id: 'acceptReturnQuantity',
    label: 'จำนวนหลังแก้ไข',
    disablePadding: false,
    width: '50px',
    hideSortIcon: true,
  },
  {
    id: 'reason',
    label: 'สาเหตุ',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const headerCells = () => {
  const { formData } = store.getState().crud
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  const from = _.get(formData, 'from', '')
  const status = _.get(formData, 'status', '')
  const checkIfImportProduct =
    from?.value === fromTypeEnum.IMPORT_PRODUCT ? true : false
  let headCells = []
  if (productType === PRODUCT_TYPE.STOCK) {
    headCells = !checkIfImportProduct ? headCellsStockForm : headCellsStock
  } else if (productType === PRODUCT_TYPE.STOCK_ASSET) {
    headCells = !checkIfImportProduct
      ? headCellsStockSkuNoForm
      : headCellsStockAsset
  } else if (productType === PRODUCT_TYPE.ASSET) {
    headCells = !checkIfImportProduct ? headCellsStockSkuNoForm : headCellsAsset
  } else if (productType === PRODUCT_TYPE.LICENSE) {
    headCells = !checkIfImportProduct
      ? headCellsStockSkuNoForm
      : headCellsLicense
  }

  if (status === TicketStatus.EDITED_APPROVED) {
    headCells = headCellsStockEditApprove
    if (productType === PRODUCT_TYPE.STOCK) {
      headCells = headCells.filter((item) => item.id !== 'skuNo')
    }
  }

  headCells = [
    ...headCells,
    {
      id: 'actionViewTransfer',
      label: ' ',
      disablePadding: false,
      hideSortIcon: true,
    }
  ]
  return headCells
}
