import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column } from '../../../../Styled'

export const StyledCircleBox = styled(Box)(({ theme }) => ({
  background: theme.palette?.blue?.blueBorder,
  width: 63,
  height: 63,
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledContentBox = styled(Column)(({ theme }) => ({
  width: 293,
  padding: theme.spacing(2),
  gap: '10px',
  alignItems: 'center',
  textAlign: 'center',
}))
