import React from 'react'
import _ from 'lodash'
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  checkingStateColor,
  standaloneCheckingState,
} from '../../../constants/eTesting'

const CheckingState = ({ data }) => {
  if (!_.isNil(data.version)) {
    const noSubmit = data.totalSubmit === 0
    const haveWaiting = data.totalWaitingToCheck > 0
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <HourglassBottomTwoTone
          fontSize="20px"
          sx={{
            color: noSubmit || haveWaiting ? 'warning.main' : 'silver.primary',
          }}
        />
        <Typography variant="body2" sx={{ mt: 0.5, ml: 1 }}>
          {noSubmit ? '-' : haveWaiting ? data.totalWaitingToCheck : '0'}
        </Typography>
      </Box>
    )
  }

  const checkingState = _.get(data, 'checkingState', '')
  if (checkingState === standaloneCheckingState.NO_SUBMIT) {
    return <Typography variant="body2">-</Typography>
  }
  return (
    <HourglassBottomTwoTone
      sx={{ width: 16, height: 16, color: checkingStateColor[checkingState] }}
    />
  )
}

export default CheckingState
