import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { MODULE_STATUS } from '../../../../../../constants/eLearning'
import { handleChange } from '../../events'
import { StyledBoxContent } from './Styled'

const StatusSwitch = () => {
  const dispatch = useDispatch()
  const { status, onGoing } = useSelector(
    (state) => ({
      status: _.get(
        state.eLearningModuleForm.body,
        'status',
        MODULE_STATUS.ACTIVE,
      ),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">สถานะเปิดปิดการใช้งานโมดูล</Typography>
      <StyledBoxContent>
        <Typography>เปิดปิดการใช้งานโมดูล</Typography>

        <Switch
          name="status"
          data-testId="switch-status"
          checked={status === MODULE_STATUS.ACTIVE}
          onChange={(e) =>
            dispatch(
              handleChange(
                'status',
                e.target.checked
                  ? MODULE_STATUS.ACTIVE
                  : MODULE_STATUS.INACTIVE,
              ),
            )
          }
          disabled={onGoing}
        />
      </StyledBoxContent>
    </Box>
  )
}

export default StatusSwitch
