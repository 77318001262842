import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'

import _ from 'lodash'
import dayjs from 'dayjs'
import { useLocalStorage } from '@rehooks/local-storage'
import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import { convertTime, convertTimeToApi } from '../View/Index'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { getExpandDate } from './Handle'
import {
  convertFormatDateTime,
  getStaffStationOption,
  getStaffAreaStationOption,
} from '../../../../utils/lib'

export const EventPeriodList = (props) => {
  const { stateForm, expandDate, onChangeForm, validateForm } = props

  const meetingType = _.get(stateForm, 'academyActivity.meetingType', 'online')
  const listExpandDate = _.get(stateForm, 'academyActivity.expandDate', [])

  return (
    <>
      {expandDate.length ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 3,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              <Typography sx={{ width: '30%' }}>วันที่เวลา</Typography>
              <Typography sx={{ width: '35%', display: 'flex' }}>
                เวลาเริ่มต้น
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
              <Typography sx={{ width: '35%', display: 'flex' }}>
                เวลาสิ้นสุด
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
              {meetingType === 'online' && (
                <Typography>{'Link ห้องประชุมออนไลน์'}</Typography>
              )}
            </Box>
          </Box>
          {expandDate.map((item, index) => {
            const objExpandDate = listExpandDate.find((itemMeetingRoom) => {
              return (
                itemMeetingRoom.date ===
                dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB)
              )
            })

            const findRequireFile = _.get(
              validateForm,
              'academyActivity.expandDate',
              [],
            ).find((itemExpand) => {
              return (
                itemExpand.date ===
                dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB)
              )
            })

            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    gap: 3,
                  }}
                >
                  <Typography sx={{ width: '26%' }}>
                    {convertFormatDateTime({
                      value: _.get(item, 'date', null),
                      type: 'date',
                    })}
                  </Typography>

                  <TimePicker
                    id="startTime"
                    onChange={(event) =>
                      onChangeForm(
                        event,
                        'startTime',
                        false,
                        dayjs(item.date).format(
                          window.__env__.REACT_APP_DATE_DB,
                        ),
                        _.get(stateForm, 'academyActivity.expandDate', []),
                      )
                    }
                    value={
                      _.get(objExpandDate, 'startTime', '')
                        ? convertTime(_.get(objExpandDate, 'startTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(objExpandDate, 'startTime', '')
                        ? convertTime(_.get(objExpandDate, 'startTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(objExpandDate, 'endTime', '')
                        ? convertTime(_.get(objExpandDate, 'endTime', ''))
                        : ''
                    }
                    helperText={_.get(findRequireFile, 'startTime', '')}
                  />

                  <TimePicker
                    id="endTime"
                    onChange={(event) =>
                      onChangeForm(
                        event,
                        'endTime',
                        false,
                        dayjs(item.date).format(
                          window.__env__.REACT_APP_DATE_DB,
                        ),
                        _.get(stateForm, 'academyActivity.expandDate', []),
                      )
                    }
                    value={
                      _.get(objExpandDate, 'endTime', '')
                        ? convertTime(_.get(objExpandDate, 'endTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(objExpandDate, 'startTime', '')
                        ? convertTime(_.get(objExpandDate, 'startTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(objExpandDate, 'endTime', '')
                        ? convertTime(_.get(objExpandDate, 'endTime', ''))
                        : ''
                    }
                    helperText={_.get(findRequireFile, 'endTime', '')}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                  }}
                >
                  {meetingType === 'online' && (
                    <TextInput
                      id="onlineLink"
                      name="onlineLink"
                      type="basic"
                      placeholder={'URL'}
                      onChange={(value) =>
                        onChangeForm(
                          value,
                          'onlineLink',
                          false,
                          dayjs(item.date).format(
                            window.__env__.REACT_APP_DATE_DB,
                          ),
                          _.get(stateForm, 'academyActivity.expandDate', []),
                        )
                      }
                      textError={_.get(findRequireFile, 'link', '')}
                      value={_.get(objExpandDate, 'link', '')}
                    />
                  )}
                </Box>
              </Box>
            )
          })}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

const Index = (props) => {
  const {
    state,
    stateForm,
    setStateForm,
    options,
    validateForm,
    setValidateForm,
    loadingDrawer,
    setLoadingDrawer,
    statePermission,
    type,
  } = props
  const [user] = useLocalStorage('user')
  const [expandDate, setExpandDate] = useState(
    _.get(stateForm, 'academyActivity.expandDate.value', []),
  )

  const defaultExpandDate = {
    typeTime: 'CUSTOM',
    startTime: '',
    endTime: '',
    link: '',
    meetingRoom: {},
  }

  useEffect(() => {
    getExpandDate(
      stateForm,
      setExpandDate,
      setStateForm,
      defaultExpandDate,
      false,
      setLoadingDrawer,
    )
  }, [
    stateForm.academyActivity.startDate,
    stateForm.academyActivity.finishDate,
  ])

  const allStaff = _.get(options, 'allStaffs', [])
  const getOptionsByPermission = () => {
    const findCreateAllPermission = _.get(user, 'permission', []).find(
      (item) =>
        item.module === 'MONTHLY_PLAN' &&
        item.permission === 'CREATE_ALL' &&
        item.owner === 'ALL',
    )
    let option = []
    if (findCreateAllPermission) {
      option = allStaff.map((item) => {
        return {
          ...item,
          label: `${item.firstNameTH} ${item.lastNameTH}`,
          value: item.uuid,
        }
      })
      return getStaffStationOption(option)
    } else {
      option = _.get(options, 'staff', [])
      return getStaffAreaStationOption(option)
    }
  }

  const attendeesOption = getOptionsByPermission()

  const onDateChange = (event, key) => {
    setStateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        [key]: dayjs(event).format(window.__env__.REACT_APP_DATE_DB),
      },
    }))

    setValidateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        startDate: '',
        finishDate: '',
      },
    }))
  }

  const onChangeForm = (event, key, isArray, date, listExpandDate) => {
    let value = ''
    let valueValidate = ''
    const listValidateExpandDate = _.get(
      validateForm,
      'academyActivity.expandDate',
      [],
    )

    if (isArray) {
      value = event
    } else {
      value = event.target.value
    }

    if (key === 'selectOnsite' || key === 'onlineLink') {
      value = listExpandDate.length
        ? listExpandDate.map((item) => {
            let result = item
            if (item.date === date) {
              if (key === 'selectOnsite') {
                result = {
                  ...item,
                  meetingRoom: _.get(options, 'meetingRoom', []).find(
                    (item) => {
                      return item.uuid === value
                    },
                  ),
                }
              } else {
                result = {
                  ...item,
                  link: value,
                }
              }
            }
            return result
          })
        : []
      valueValidate = listValidateExpandDate.map((item) => {
        let resultValidate = item
        if (item.date === date) {
          if (key === 'selectOnsite') {
            resultValidate = {
              ...item,
              meetingRoom: '',
            }
          } else {
            resultValidate = {
              ...item,
              link: '',
            }
          }
        }
        return resultValidate
      })

      key = 'expandDate'
    }

    if (key === 'startTime' || key === 'endTime') {
      value = listExpandDate.length
        ? listExpandDate.map((item) => {
            let result = item
            if (item.date === date) {
              result = {
                ...item,
                [key]: convertTimeToApi(value),
              }
            }
            return result
          })
        : []

      valueValidate = listValidateExpandDate.map((item) => {
        let result = item
        if (item.date === date) {
          result = {
            ...item,
            [key]: '',
          }
        }
        return result
      })
      key = 'expandDate'
    }

    if (key === 'checkAllUser') {
      value = event.target.checked
      const attendeesValue = attendeesOption
      setStateForm((prevState) => ({
        ...prevState,
        academyActivity: {
          ...prevState.academyActivity,
          checkAllUser: value,
          attendees: attendeesValue,
        },
      }))
    } else {
      if (key === 'attendees') {
        setStateForm((prevState) => ({
          ...prevState,
          academyActivity: {
            ...prevState.academyActivity,
            attendees: value,
            checkAllUser:
              attendeesOption.length ===
              prevState.academyActivity.attendees.length
                ? !prevState.academyActivity.checkAllUser
                : false,
          },
        }))
      } else {
        setStateForm((prevState) => ({
          ...prevState,
          academyActivity: {
            ...prevState.academyActivity,
            [key]: value,
          },
        }))
      }

      setValidateForm((prevState) => ({
        ...prevState,
        academyActivity: {
          ...prevState.academyActivity,
          [key]: valueValidate,
        },
      }))
    }
  }

  const onChangeRadio = (e) => {
    setStateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        meetingType: e.target.value,
        expandDate: expandDate.length
          ? expandDate.map((item) => {
              return {
                ...item,
                startTime: '',
                endTime: '',
                link: '',
                meetingRoom: e.target.value === 'onsite' ? null : {},
              }
            })
          : [],
      },
    }))

    setValidateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        toMeetingRoom: '',
        expandDate: _.get(validateForm, 'academyActivity.expandDate', []).map(
          (item) => {
            return {
              ...item,
              startTime: '',
              endTime: '',
              link: '',
              meetingRoom: '',
            }
          },
        ),
      },
    }))
  }

  return (
    <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Typography>สถานที่จัดประชุม</Typography>
        <RadioGroup
          sx={{ gap: 1, pl: 1, width: '160px' }}
          aria-label="meetingType"
          defaultValue={_.get(
            stateForm,
            'academyActivity.meetingType',
            'online',
          )}
          name="radio-buttons-group"
          onChange={onChangeRadio}
        >
          <FormControlLabel
            value="online"
            control={<Radio />}
            label="ประชุมแบบ Online"
          />
          <FormControlLabel
            value="onsite"
            control={<Radio />}
            label="ประชุมแบบ Onsite"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            onChange={(value) => onDateChange(value, 'startDate')}
            value={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            isShouldDisableDate={true}
            disabledEndDate={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            textError={_.get(validateForm, 'academyActivity.startDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.startDate', '')
                ? false
                : true
            }
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <DatePicker
            id="finishDate"
            name="finishDate"
            labelText="ถึงวันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            isShouldDisableDate={true}
            required
            onChange={(value) => onDateChange(value, 'finishDate')}
            value={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'academyActivity.startDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'academyActivity.finishDate', null) ===
              'Invalid Date'
                ? null
                : _.get(stateForm, 'academyActivity.finishDate', null)
            }
            textError={_.get(validateForm, 'academyActivity.finishDate', '')}
            hideTextError={
              _.get(validateForm, 'academyActivity.finishDate', '')
                ? false
                : true
            }
          />
        </Box>
      </Box>
      <EventPeriodList
        stateForm={stateForm}
        expandDate={expandDate}
        onChangeForm={onChangeForm}
        options={options}
        validateForm={validateForm}
        loadingDrawer={loadingDrawer}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextInput
          id="title"
          name="title"
          type="basic"
          boxSx={{ mt: -2 }}
          labelText="หัวข้อ"
          placeholder="กรุณากรอกหัวข้อ"
          required
          onChange={(value) => onChangeForm(value, 'title')}
          value={stateForm.academyActivity.title}
          textError={_.get(validateForm, 'academyActivity.title', '')}
        />
        <TextInput
          id="description"
          name="description"
          type="basic"
          labelText="รายละเอียด"
          placeholder="รายละเอียด"
          multiline={true}
          rows={3}
          onChange={(value) => onChangeForm(value, 'description')}
          value={stateForm.academyActivity.description}
          textError={_.get(validateForm, 'academyActivity.description', '')}
        />

        <SelectGroupChip
          id="attendees"
          name="attendees"
          placeholder={'เลือกผู้เข้าร่วมประชุม'}
          labelText="ผู้เข้าร่วมประชุม"
          options={attendeesOption}
          onChange={(event) => onChangeForm(event, 'attendees', true)}
          value={_.get(stateForm, 'academyActivity.attendees', '')}
          textError={_.get(validateForm, 'academyActivity.attendees', '')}
          disabled={statePermission}
        />
        <FormControlLabel
          sx={{ width: 120 }}
          label="เลือกทั้งหมด"
          control={
            <Checkbox
              data-testid="cb-all-attendees"
              color="primary"
              name="checkAllUser"
              checked={
                attendeesOption.length ===
                _.get(stateForm, 'academyActivity.attendees', []).length
              }
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={(event) => onChangeForm(event, 'checkAllUser')}
              disabled={
                statePermission ||
                attendeesOption.length ===
                  _.get(stateForm, 'academyActivity.attendees', []).length
              }
            />
          }
        />
      </Box>
    </Box>
  )
}
export default Index
