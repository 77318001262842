export const headerCells = () => {
  return [
    {
      id: 'agentCode',
      label: 'Agent Code',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'fullName',
      label: 'ชื่อผู้ทำแบบทดสอบ',
    },
    {
      id: 'testerListCourseName', //reportEtesting.courseNameEN
      label: 'ชื่อหลักสูตร',
      disablePadding: false,
      hideSortIcon: true,
    },
    {
      id: 'testerListTestType', //reportEtesting.testType
      label: 'รูปแบบการทดสอบ',
      disablePadding: false,
      hideSortIcon: true,
    },
    {
      id: 'testScore',
      label: 'คะแนน',
      disablePadding: false,
    },
    {
      id: 'testResult',
      label: 'ผลการสอบ',
      disablePadding: false,
      hideSortIcon: true,
    },
    {
      id: 'endTestDateTime',
      label: 'ช่วงเวลาทำแบบทดสอบ',
      disablePadding: false,
      hideSortIcon: true,
    },

    {
      id: 'ActionViewUserProfile',
      label: 'คำสั่ง',
      disablePadding: false,
      hideDelete: true,
      hideEdit: true,
      hideSortIcon: true,
      minWidth: '40px',
      isETest: true,
    },
  ]
}
