export const scrollToLast = () => {
  setTimeout(
    () =>
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth',
      }),
    100,
  )
}
