import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { getErrorMessage } from '../../../../../event'
import TextInput from '../../../../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import { TooltipForm } from '../../../../Styled'
import { handleChangeContent, handleDeleteContent } from './events'

const Index = ({ props }) => {
  const { disableForm, listData, indexTypeList, indexQuestion, type } = props
  const item = listData[indexTypeList]
  const dispatch = useDispatch()
  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const choicesErrorMessage = getErrorMessage({
    field: type === 'questions' ? 'QUESTION_QUESTION' : 'CHOICES',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer: indexTypeList,
  })

  return (
    <>
      <>
        <Styled.BoxContent>
          <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
            <TextInput
              id="title-answer"
              name="title"
              placeholder={type === 'questions' ? 'คำถาม' : 'ตัวเลือก'}
              type="basic"
              boxSx={{ mt: 1 }}
              disabled={disableForm}
              value={_.get(item, 'title', '')}
              onChange={(e) =>
                dispatch(
                  handleChangeContent({
                    ...props,
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              isShowTextError={choicesErrorMessage ? true : false}
              textError={choicesErrorMessage}
            />

            {listData.length > 1 && (
              <Box
                sx={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  top: 8,
                }}
              >
                <TooltipForm
                  placement="bottom"
                  title={<Typography variant="tooltip">ลบ</Typography>}
                  onClick={() =>
                    !disableForm &&
                    dispatch(
                      handleDeleteContent({
                        ...props,
                        sequenceChoice: _.get(item, 'sequence', 0),
                      })
                    )
                  }
                >
                  <IconButton data-testid={`btn-delete-${indexTypeList}`}>
                    <CloseOutlined color={'action'} />
                  </IconButton>
                </TooltipForm>
              </Box>
            )}
          </Box>
        </Styled.BoxContent>
      </>
    </>
  )
}
export default Index
