import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Footer from '../../../../components/Footer'
import BodyLocation from './Body'
import LocationHeader from './Header'
import { StyledTemplate } from './Styled'
import { fetchManageStation } from './events'

const Location = () => {
  const { data, isLoading } = useSelector(
    (state) => state.manageContentStation,
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchManageStation(data))
  }, [data])

  return (
    <Box
      sx={{
        opacity: isLoading ? 0.4 : 'unset',
        pointerEvents: isLoading ? 'none' : 'unset',
        display: 'flex',
        flexDirection: 'column',
        mt: 0,
      }}
    >
      <LocationHeader />
      <StyledTemplate>
        <BodyLocation />
      </StyledTemplate>
      <Footer />
    </Box>
  )
}

export default Location
