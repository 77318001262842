import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import { StyledBox } from '../Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table'
import { validateEPermission } from '../../../../../utils/lib'

const ActionEVersion = ({ row, rowIndex, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { module, viewPath, editPath, hideEdit } = condition
  const getPermissionView = validateEPermission({
    module,
    permission: 'VIEW',
    data: row,
  })
  const getPermissionEdit = validateEPermission({
    module,
    permission: 'EDIT',
    data: row,
  })

  const viewUrl = `${viewPath}/${row.uuid}`
  const editUrl = `${editPath}/${row.uuid}`

  return (
    <StyledBox>
      {getPermissionView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
      {getPermissionEdit && !hideEdit &&
        row.status !== 'DELETED' &&
        row.status !== 'INACTIVE' && (
          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(setOnEdit({ history, editUrl }))}
          >
            <EditTwoTone color="action" />
          </IconButton>
        )}
    </StyledBox>
  )
}

export default ActionEVersion
