import _ from 'lodash'
import { store } from '../../../App'
import { dispatchUploadValue } from './dispatchUploadValue'

export const handleDeleteFile = (index, onChange, fieldName) => {
  const { uploadFile } = store.getState()
  const fileKey = `formData[${fieldName}].files`
  const fileList = _.get(uploadFile, fileKey, [])

  const updatedFileList = fileList
    .filter((_, fileIndex) => fileIndex !== index)
    .map((item, fileIndex) => ({ ...item, id: fileIndex }))

  dispatchUploadValue(fileKey, updatedFileList)

  onChange(updatedFileList)
}
