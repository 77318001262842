import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useLocalStorage from '@rehooks/local-storage'
import { styled } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useTheme from '@mui/system/useTheme'
import Tooltip from '@mui/material/Tooltip'

import { handleTrainingActionClick } from './BudgetDialog'
import { hasApproved } from '../handle'

export default function index({
  dataList,
  state,
  setDialog,
  fetchEvent,
  setIsDrawer,
}) {
  const statusInfo = _.get(dataList, 'statusInfo', '')
  const status = _.get(dataList, 'status', '')

  const [user] = useLocalStorage('user')
  const theme = useTheme()
  const cost = _.get(dataList, 'cost', 0)
  const breakMealBudget = _.get(dataList, 'course.breakMealBudget', 0)
  const reviewedReason = _.get(dataList, 'reviewedReason', '')
  const isStatusWaitingBorrow =
    _.get(dataList, 'statusBorrow', '') === 'WAITING_APPROVE_BORROW'
  let getHasApproved = hasApproved({
    status,
    statusInfo,
    user,
    masterStatus: _.get(state, 'masterPlan.status', ''),
  })
  if (_.isEmpty(_.get(dataList, 'remarkCost', ''))) getHasApproved = false

  const listTrainingPlatform = _.get(dataList, 'course.trainingPlatform', [])
  let arrayTrainingPlatform = []
  if (listTrainingPlatform.length) {
    _.forIn(listTrainingPlatform, (item) => {
      arrayTrainingPlatform.push(_.get(item, 'trainingPlatform', ''))
    })
  }

  const textTrainingPlatform = arrayTrainingPlatform.join(', ')

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">หลักสูตร</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'course.courseCode', '-')}{' '}
          {_.get(dataList, 'course.englishName', '')}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">ชื่อหลักสูตร (สำหรับ Learner)</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'course.nameForLearner', '-') || '-'}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">รูปแบบการสอน</LabelText>
        <LabelTextValue variant="Body1">{textTrainingPlatform}</LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">Department</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'area.department.department', '-')}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">Station</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'area.station.station', '-')}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">จำนวนคนที่คาดหวัง</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'expectNumberPeople', 0)}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">รูปแบบการจัดห้องเรียน</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'classroom.classroom', '')}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">TO ผู้รับผิดชอบ</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'assignNee', {})
            ? _.get(dataList, 'assignNee.firstNameTH', '') +
              ' ' +
              _.get(dataList, 'assignNee.lastNameTH', '')
            : '-'}
        </LabelTextValue>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <LabelText variant="body1">เบอร์โทรติดต่อ TO</LabelText>
        <LabelTextValue variant="body1">
          {_.get(dataList, 'assignNee', {})
            ? _.get(dataList, 'assignNee.tel', '-')
            : '-'}
        </LabelTextValue>
      </Box>
      {_.get(dataList, 'break', false) && (
        <>
          <Box sx={{ display: 'flex' }}>
            <LabelText variant="body1">การเตรียมอาหาร Break / Lunch</LabelText>
            <LabelTextValue variant="body1">
              {_.get(dataList, 'break', false) ? 'ต้องการ' : 'ไม่ต้องการ'}
            </LabelTextValue>
          </Box>
          <Box
            sx={{
              display: 'flex',
              color: getHasApproved ? 'warning.main' : 'unset',
              alignItems: 'center',
            }}
          >
            <LabelText variant="body1">ค่าใช้จ่ายต่อหัว</LabelText>
            <Typography variant="body1">
              {Number(cost).toFixed(2)} บาท
            </Typography>

            {cost !== breakMealBudget && (
              <Tooltip
                title={
                  <Typography variant="tooltip">
                    ค่าใช้จ่ายที่อนุมัติต่อคนของหลักสูตรนี้{' '}
                    {Number(breakMealBudget).toFixed(2)} บาท
                  </Typography>
                }
                arrow
                placement="right"
              >
                <InfoOutlinedIcon
                  sx={{
                    color: `${theme?.palette?.warning?.main}`,
                    cursor: 'pointer',
                    pl: 1,
                  }}
                />
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              color: getHasApproved ? 'warning.main' : 'unset',
            }}
          >
            <LabelText variant="body1">หมายเหตุ</LabelText>
            <LabelTextValue variant="body1">
              {_.isEmpty(_.get(dataList, 'remarkCost', '-'))
                ? '-'
                : _.get(dataList, 'remarkCost', '-')}
            </LabelTextValue>
          </Box>
        </>
      )}

      {reviewedReason && (
        <Box sx={{ display: 'flex' }}>
          <LabelText variant="body1">เหตุผลจาก HF</LabelText>
          <LabelTextValue variant="body1">{reviewedReason}</LabelTextValue>
        </Box>
      )}

      {getHasApproved && !isStatusWaitingBorrow && (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: 200, mr: 3 }} />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              size="s"
              onClick={() => {
                handleTrainingActionClick(
                  'EDIT',
                  setDialog,
                  dataList,
                  setIsDrawer,
                  fetchEvent
                )
              }}
            >
              แก้ไขงบ
            </Button>
            <Button
              variant="contained"
              size="s"
              onClick={() => {
                handleTrainingActionClick(
                  'OLD_COST',
                  setDialog,
                  dataList,
                  setIsDrawer,
                  fetchEvent
                )
              }}
            >
              ใช้งบเดิม
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
export const convertDate = ({ date }) =>
  `${date}`.length === 1 ? `0${date}` : date

const LabelText = styled(Typography)(({ theme }) => ({
  width: '42%',
  minWidth: '42%',
  paddingRight: theme.spacing(3),
}))

const LabelTextValue = styled(Typography)(() => ({
  width: '58%',
  wordWrap: 'break-word',
}))
