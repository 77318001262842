import { store } from '../../../App'
import {
  setColumns,
  setIsFilter,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import { fetchReports } from './fetchReports'

export const handleSaveColumns = async () => {
  const {
    displayColumns,
    filters,
    reportConfig: { moduleId },
  } = store.getState().reportComp

  let findFilter = false
  if (moduleId === 'REPORT_MIX_MODULE') {
    findFilter = filters.length > 0
  }

  localStorage.setItem(`columns_${moduleId}`, JSON.stringify(displayColumns))
  store.dispatch(setReduxValue({ key: 'hasSortedColumns', value: true }))
  store.dispatch(setColumns(displayColumns))
  if (
    (findFilter && moduleId === 'REPORT_MIX_MODULE') ||
    moduleId !== 'REPORT_MIX_MODULE'
  ) {
    store.dispatch(setIsFilter(true))
    await fetchReports()
  }
}
