import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(0.5),
}))

export const StyledTitleColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 430,
  minWidth: 320,
  gap: theme.spacing(1),
  // boxShadow: '2px 0px 20px rgb(0 0 0 / 25%)',
  [theme.breakpoints.down('sm')]: {
    minWidth: 180,
  },
}))

export const StyledRatingColumn = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flexWrap: 'nowrap',
  overflow: 'auto',
  '& .MuiBox-root#header': { display: 'flex' },
}))

export const StyledRatingHead = styled(Box)(({ theme, ratingWidth }) => ({
  width: '100%',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > .MuiTypography-root': {
    maxHeight: 40,
    maxWidth: ratingWidth,
    wordBreak: 'break-word',
    overflow: 'auto',
    '::-webkit-scrollbar': { display: 'none' },
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 80,
  },
}))
