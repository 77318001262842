import _ from 'lodash'
import { getHistory } from '../../../../../../services/manageClass/form'

import { headCellsHistory } from '../../../../../../constants/manageClass'

import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'
import { StyledTable } from './Styled'

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`

  dispatch(startLoading())
  const result = await dispatch(getHistory(url))
  const payload = _.get(result, 'payload', undefined)

  if (payload) {
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows: mapRowsTable(_.get(result, 'payload.data.result', [])),
        allCount: _.get(result, 'payload.data.totalCount', 0),
        headCells: headCellsHistory,
        customStyle: StyledTable,
      })
    )
  }
  dispatch(stopLoading())
}

export const mapRowsTable = (list) => {
  if (list.length <= 0) return []
  return list.map((item) => {
    return {
      historyName: _.get(item, 'createdBy', '-'),
      historyDate: _.get(item, 'createdAt', '-'),
    }
  })
}
