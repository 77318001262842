import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxUl = styled('ul')(() => ({
  margin: '0 0 0 0',
}))

export const BoxLi = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const BoxIconHistory = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}))

export const BoxCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

export const BoxForm = styled(Box)(({ theme, isGap, isBottom }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  gap: isGap ? theme.spacing(0.5) : theme.spacing(2),
  flexDirection: 'column',
  marginBottom: isBottom ? theme.spacing(1) : theme.spacing(3),
}))

export const BoxFlex = styled(Box)(
  ({ theme, isSwitch, paddingLeft, isSelect, isIcon }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(3),
    justifyContent: isSwitch ? 'space-between' : 'unset',
    paddingLeft: theme.spacing(paddingLeft),
    alignItems: isSelect || isIcon ? 'center' : 'none',
    [theme.breakpoints.down('sm')]: {
      display: isSwitch ? 'display' : 'block',
      flexDirection: isSelect ? 'column' : 'unset',
      gap: theme.spacing(2),
      alignItems: isSelect ? 'flex-start' : 'none',
    },
  })
)

export const TypographyLabel = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.text?.lightGray,
}))
