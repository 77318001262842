import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { getQueryParams } from '../../../../utils/lib'
import { breadcrumbEvaStatus } from '../model'
import { StyledBoxHeader, StyledBoxLabelTitle } from './Styled'
import DropdownButton from './DropdownButton'

const Header = ({ data }) => {
  const trainingPlan = getQueryParams('trainingPlanUuid')
  const eEvaName = _.get(data, 'eEvaluationVersion.name', '-')

  return (
    <StyledBoxHeader>
      <StyledBoxLabelTitle>
        <Typography variant="h4">{eEvaName}</Typography>
        <Breadcrumbs menuList={breadcrumbEvaStatus(trainingPlan)} />
      </StyledBoxLabelTitle>
      <DropdownButton />
    </StyledBoxHeader>
  )
}

export default Header
