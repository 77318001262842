import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { openDialog } from '../../../../../../redux/slices/dialog'
import { handleCallApiCloseClass } from '../event'
import { DialogCloseClass } from '.'

export const handleCloseClass = (uuid, remarkText) => (dispatch) => {
  const body = {
    manageClassUuid: `${uuid}`,
    remarkClosed: `${remarkText}`,
    checkNoCondition: false,
  }

  dispatch(
    openDialog({
      title: 'ปิดคลาส',
      type: 'content',
      disableButton: true,
      content: <DialogCloseClass />,
      icon: <ErrorOutlineIcon color="error" />,
      agreeText: 'ปิดคลาส',
      handleConfirm: () => {
        dispatch(handleCallApiCloseClass(uuid, body))
      },
    })
  )
}
