import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'

import PreviewImg from '../../components/Preview/PreviewImg'
import HistoryDrawer from '../../components/Drawer/History'
import { useHistory } from 'react-router-dom'
import Dot from '../../components/Table/Dot'
import { callAPI, getStatusText } from '../../utils/lib'
import { roadMap, historyRoadMap } from '../../utils/apiPath'
import UserLevelList from '../../components/Table/UserLevelList'
import Breadcrumbs from '../../components/Breadcrumbs'
import { onEdit } from './RoadMapList'
const RoadMapView = () => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const history = useHistory()
  const [stateRoadMap, setStateRoadMap] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'กลุ่มหลักสูตร',
      link: '/master-data/road-map',
      pointer: true,
    },
    { title: 'รายละเอียดกลุ่มหลักสูตร', link: '/', pointer: false },
  ]

  const fetchRoadMapById = async () => {
    await callAPI({
      url: roadMap + `/${uuid}`,
      method: 'GET',
      onSuccess: (res) => {
        let result = res || {}
        if (Object.keys(result).length) {
          result = {
            ...result,
            distribution: filterDistributionName({
              listLevel: result.distribution || [],
            }),
          }
        }
        setStateRoadMap(result)
      },
    })
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchRoadMapById()
    setIsPageLoading(false)
  }, [])

  useEffect(() => {
    fetchHistory(uuid, page, limit, setAllCount, setHistoryList)
  }, [limit, page])

  const [isPageLoading, setIsPageLoading] = useState(false)

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            ml: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
            }}
          >
            รายละเอียดกลุ่มหลักสูตร : {stateRoadMap.title}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Box
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            data-testid="btn-history"
            sx={stateRoadMap.status !== 'DELETED' ? { mr: 3 } : {}}
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={() => onHistoryClick(isHistoryDrawer, setIsHistoryDrawer)}
          >
            ประวัติการแก้ไข
          </Button>
          {stateRoadMap.status !== 'DELETED' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => onEdit(stateRoadMap, history)}
              data-testid="btn-edit"
            >
              แก้ไข
            </Button>
          )}
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
        <Box
          sx={{
            mr: 3,
            height: 120,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PreviewImg
            srcImg={`${window.__env__.REACT_APP_API_URL}/file/${stateRoadMap.imgKey}`}
            isPreview={true}
            style={{ width: 120, height: 120, background: '#3B3FD8' }}
          />
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ID
          </Typography>
          <Typography variant="body1">{stateRoadMap.id}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            หัวข้อ
          </Typography>
          <Typography variant="body1">{stateRoadMap.title}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ชื่อย่อ
          </Typography>
          <Typography variant="body1">{stateRoadMap.abbreviation}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ช่องทางการจำหน่าย
          </Typography>
          <UserLevelList levelList={stateRoadMap.distribution} />
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            รายละเอียด
          </Typography>
          <Typography variant="body1">
            {stateRoadMap.description || '-'}
          </Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            หมายเหตุ
          </Typography>
          <Typography variant="body1">{stateRoadMap.remark || '-'}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            สถานะ
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {' '}
            <Dot sx={{ mt: 1.5, mr: 1.5 }} status={stateRoadMap.status} />
            <Typography variant="body1">
              {getStatusText(stateRoadMap.status)}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mx: 3 }} />
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'right',
          }}
        >
          <Typography variant="caption" color="text.lightGray">
            แก้ไขล่าสุดโดย{' '}
            {historyList && historyList.length > 0 && historyList[0].author}{' '}
            {historyList && historyList.length > 0 && historyList[0].createdAt}
          </Typography>
        </Box>
      </Card>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default RoadMapView

export const onHistoryClick = (isHistoryDrawer, setIsHistoryDrawer) => {
  setIsHistoryDrawer(!isHistoryDrawer)
}

export const filterDistributionName = ({ listLevel }) => {
  let result = []
  if (listLevel.length) {
    listLevel.filter((item) => {
      if (item.abbreviation) {
        result.push(item.abbreviation)
      }
    })
  }
  return result
}

export const fetchHistory = async (
  uuid,
  page,
  limit,
  setAllCount,
  setHistoryList
) => {
  const realPage = page === 0 ? 1 : page + 1
  const url = `${historyRoadMap}?limit=${limit}&page=${realPage}&uuid=${uuid}`
  await callAPI({
    url: url,
    method: 'GET',
    onSuccess: (res) => {
      setHistoryList(res.result)
      setAllCount(res.totalCount)
    },
  })
}
