import { inventoryTicket } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_TICKET',
  moduleSlug: 'inventory-ticket',
  moduleTitleFull: 'รายการโอน',
  modulesTitleShort: 'รายการโอน',
  modulePath: '/inventory/transfer',
  filterPath: inventoryTicket,
  headerCells: () => {
    return []
  },
}
