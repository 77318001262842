import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { convertFormatDateTime } from '../../../../../utils/lib'
import {
  defaultValue,
  questionType,
  versionStatus,
} from '../../../../../constants/eTesting'
import Dot from '../../../../../components/redux/Dot'
import { StyledBtnMore } from './../../../../EEvaluation/Version/View/Content/Styled'
import { StyledCard } from '../../../Styled'
import {
  StyledCardContent,
  StyledContainerChip,
  StyledContentRow,
  StyledDivider,
  StyledFooterText,
  StyledOutlinedChip,
} from './Styled'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

export const ContentRow = ({ title, content, children }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      {children ? children : <Typography variant="body1">{content}</Typography>}
    </StyledContentRow>
  )
}

export const QuestionTypeContent = ({ detail }) => {
  const questionTypes = useMemo(() => {
    if (detail) {
      const groupType = _.groupBy(detail.questions, 'questionType')
      return Object.keys(groupType)
    } else return []
  }, [detail])

  return (
    <ContentRow title="รูปแบบคำถาม">
      <StyledContainerChip>
        {_.uniq(questionTypes).map((type) => (
          <StyledOutlinedChip
            label={_.get(questionType, type, questionType.FREE_TEXT)}
            key={type}
          />
        ))}
      </StyledContainerChip>
    </ContentRow>
  )
}

export const CourseContent = ({ detail }) => {
  const [limit, setLimit] = useState(5)
  const courses = _.get(detail, 'setting.courseRef', [])

  return (
    <ContentRow title="หลักสูตรที่นำไปใช้">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>{courses.length} หลักสูตร</label>
        <ul style={{ margin: 0, marginLeft: '-10px' }}>
          {courses.slice(0, limit).map((item, index) => (
            <li key={index}>
              {item.courseName ?? item}{' '}
              <Typography variant="span" color="primary">
                {item.nameForLearner}
              </Typography>{' '}
              เวอร์ชั่น {item.version}
            </li>
          ))}
          {courses.length > 5 && limit == 5 && (
            <StyledBtnMore onClick={() => setLimit(courses.length)}>
              แสดงทั้งหมด <KeyboardArrowDown />
            </StyledBtnMore>
          )}
          {courses.length > 5 && limit == courses.length && (
            <StyledBtnMore onClick={() => setLimit(5)}>
              ซ่อน <KeyboardArrowUp />
            </StyledBtnMore>
          )}
        </ul>
      </Box>
    </ContentRow>
  )
}
export const RandomContent = ({ detail }) => {
  const randomAnswer = _.get(detail, 'setting.randomAnswer', false)
  const randomQuestion = _.get(detail, 'setting.randomQuestion', false)

  return (
    <ContentRow title="การสุ่ม">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ul style={{ margin: 0, marginLeft: '-10px' }}>
          <li>สุ่มตัวเลือกคำตอบ : {randomAnswer ? 'เปิด' : 'ปิด'}</li>
          <li>สุ่มคำถาม : {randomQuestion ? 'เปิด' : 'ปิด'}</li>
        </ul>
      </Box>
    </ContentRow>
  )
}
export const ResultContent = ({ detail }) => {
  const isOpen = _.get(detail, 'setting.questionResult.isOpen', false)
  const resultType = _.get(
    detail,
    'setting.questionResult.resultType',
    'SHOW_FALSE_ONLY'
  )
  const result = _.get(detail, 'setting.questionResult.result', 'LATER')

  return (
    <ContentRow title="การเฉลยคำตอบ">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>{isOpen ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</label>
        {isOpen && (
          <ul style={{ margin: 0, marginLeft: '-10px' }}>
            <li>
              ช่วงเวลาในการเฉลย :{' '}
              {result === 'NOW' ? 'ระหว่างการทำข้อสอบ' : 'หลังทำข้อสอบเสร็จ'}
            </li>
            <li>
              รูปแบบการเฉลย :{' '}
              {resultType === 'SHOW_TRUE_FALSE'
                ? 'เฉลยทั้งข้อถูกและข้อผิด'
                : 'เฉลยเฉพาะข้อที่ผู้เรียนเลือกตอบว่าถูกหรือผิด'}
            </li>
          </ul>
        )}
      </Box>
    </ContentRow>
  )
}

export const StaffListContent = ({ detail, field }) => {
  const title = field === 'assignees' ? 'ผู้ร่วมจัดทำ' : 'ผู้ตรวจข้อสอบ'
  const staffList = _.get(detail, `setting.${field}`, [])
  return (
    <ContentRow title={title}>
      {staffList.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {staffList.map((item, index) => (
            <Typography key={index}>
              {_.get(item, 'name', item.label) || ''}
              {staffList.length > 1 && index < staffList.length - 1 && ','}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography>-</Typography>
      )}
    </ContentRow>
  )
}

export const StatusContent = ({ detail }) => {
  const status = _.get(detail, 'setting.status', 'ACTIVE')
  return (
    <ContentRow title="สถานะ">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot status={status} sx={{ mt: 1.5 }} />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}

export const CriteriaContent = ({ detail }) => {
  const testCriteriaType =
    _.get(detail, 'setting.testCriteriaType', 'REQUIRED') || 'REQUIRED'
  const criteriaPercent = _.get(detail, 'setting.criteriaPercent', null)
  const isTestOnlyRequire = testCriteriaType === 'REQUIRED'

  return (
    <ContentRow title="เกณฑ์คะแนนผ่าน">
      {isTestOnlyRequire ? (
        <Typography variant="body1">ต้องทำ</Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1">ต้องทำ และผ่านตามเกณฑ์</Typography>
          <ul style={{ margin: 0, marginLeft: '-10px' }}>
            <li>{criteriaPercent}% ของคะแนนทั้งหมด</li>
          </ul>
        </Box>
      )}
    </ContentRow>
  )
}

export const FooterContent = ({ detail }) => {
  const updatedBy = _.get(detail, 'setting.updatedBy.name', '-')
  const updatedAt = _.get(detail, 'setting.viewUpdatedAt', '-')

  return (
    <StyledFooterText variant="caption" color="text.lightGray">
      แก้ไขล่าสุดโดย {updatedBy} {updatedAt}
    </StyledFooterText>
  )
}

const Content = ({ detail }) => {
  const type = _.get(detail, 'type', 'ASSESSMENT')
  const limitTime = _.get(detail, 'setting.limitTime.isOpen', false)
  const minute = _.get(detail, 'setting.limitTime.minute', 30)
  const displayQuestion = _.get(detail, 'setting.displayQuestion', 'ALL')
  const testResult = _.get(detail, 'setting.testResult', 'NOW')
  const roundUnlimit = _.get(detail, 'setting.testRound.roundUnlimit', true)
  const round = _.get(detail, 'setting.testRound.round', 1)
  const typeOfETesting = defaultValue.typeOfETesting
  const isAssessment = type === typeOfETesting.assessment
  const isStandalone = type === typeOfETesting.standalone

  return (
    <StyledCard id="view">
      <StyledCardContent>
        <ContentRow title="ID" content={_.get(detail, 'codeId', 0)} />
        <ContentRow
          title="เวอร์ชั่น"
          content={
            _.get(detail, 'version', -1) === 0
              ? ''
              : _.get(detail, 'version', -1)
          }
        />
        <ContentRow
          title="ชื่อแบบทดสอบ"
          content={_.get(detail, 'information.name', '-')}
        />
        <ContentRow
          title="จำนวนคำถาม"
          content={`${_.get(detail, 'questions', []).length} ข้อ`}
        />
        <QuestionTypeContent detail={detail} />
        {!isAssessment && (
          <>
            <ContentRow
              title="เวลาในการทำข้อสอบ"
              content={
                !limitTime
                  ? 'ไม่จำกัดเวลาในการทำ'
                  : `จำกัดเวลาในการทำ ${minute} นาที`
              }
            />
            <ContentRow
              title="การแสดงคำถาม"
              content={
                displayQuestion === 'ALL' ? 'แสดงทั้งหมด' : 'แสดงทีละข้อ'
              }
            />
            <RandomContent detail={detail} />
            <ContentRow
              title="การรายงานผลการทดสอบ"
              content={
                testResult === 'NOW' ? 'ออกผลสอบทันที' : 'ออกผลสอบภายหลัง'
              }
            />
            <ResultContent detail={detail} />
          </>
        )}

        <ContentRow
          title="จำนวนครั้งในการสอบ"
          content={
            roundUnlimit
              ? 'กรณีสอบไม่ผ่าน กำหนดให้ผู้เรียนสอบใหม่ได้ ไม่จำกัดครั้ง'
              : `กรณีสอบไม่ผ่าน กำหนดให้ผู้เรียนสอบใหม่ได้ ${round} ครั้ง`
          }
        />
        {isStandalone && <CriteriaContent detail={detail} />}
        {!isStandalone && <CourseContent detail={detail} />}
        <ContentRow
          title="ผู้สร้าง"
          content={_.get(detail, 'setting.createdBy.name', '-')}
        />
        <StaffListContent detail={detail} field="assignees" />
        {isStandalone && <StaffListContent detail={detail} field="examiners" />}
        <StatusContent detail={detail} />
        <ContentRow
          title="วันเริ่มต้นใช้งาน"
          content={convertFormatDateTime({
            value: _.get(detail, 'setting.startDate', '-'),
            type: 'date',
          })}
        />

        <StyledDivider />
        <FooterContent detail={detail} />
      </StyledCardContent>
    </StyledCard>
  )
}

export default Content
