import { Box, Button, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { FilterBox } from '../FilterBox'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'

const LeftInfo = ({ uuid, isPrinting = false }) => {
  const { latestUpdated, isManageClass } = useSelector(
    (state) => ({
      latestUpdated: state.crud.eEvaluationDashboard?.latestUpdated,
      isManageClass: state.crud.eEvaluationDashboard?.isManageClass,
    }),
    shallowEqual,
  )
  const url = `/e-evaluation/view-mode/${uuid}?tab=feedback`
  const user = JSON.parse(localStorage.getItem('user'))
  const permissionList = _.get(user, 'permission', [])
  const hasPermission = _.find(permissionList, (item) => {
    return (
      ['ALL'].includes(item.owner) &&
      item.permission === 'VIEW' &&
      item.module === 'E_EVALUATION_SETTING'
    )
  })
  return (
    <Box>
      {(!isManageClass || (isManageClass && hasPermission)) && (
        <Box
          display="flex"
          gap={2}
          className={`${isPrinting ? 'hide-pdf' : ''}`}
        >
          <Typography variant="h6">ภาพรวม</Typography>
          <Button
            variant="contained"
            onClick={() => {
              window.open(`${url}`, '_blank')
            }}
            size="m"
            endIcon={<ChevronRightIcon />}
            sx={{ padding: '8px 16px 12px 20px' }}
          >
            ดูการตอบรับ
          </Button>
        </Box>
      )}
      <Box mt={2}>
        {!isManageClass && <FilterBox />}
        <Typography variant="body2">
          ข้อมูลเมื่อวันที่{' '}
          {latestUpdated ?? dayjs().format('DD/MM/YYYY 02:00')}
        </Typography>
      </Box>
    </Box>
  )
}

export default LeftInfo
