import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const check = (questionType) => {
  switch (questionType) {
    case 'MULTIPLE':
    case 'MATCHING':
      return 51
    case 'SINGLE':
    case 'SEQUENCE':
    case 'TRUE_FALSE':
      return 50
    default:
      return 53
  }
}

export const IconDragDrop = styled(Box)(({ theme, questionType }) => ({
  top: check(questionType),
  position: 'relative',
  left: theme.spacing(-3),
  color: theme?.palette?.action?.active,
}))
