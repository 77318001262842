import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import Template from '../../../../../components/Template'
import { LoadingCircular } from '../../../Styled'
import { Row } from '../Styled'
import { COURSE_TYPE } from '../model'
import { StyledColumn, StyledContentWrapper } from './Styled'
import CourseList from './CourseList'
import ELearningContent from './ELearningContent'
import CourseContent from './CourseContent'

const Content = () => {
  const isCourseLoading = useSelector(
    (state) => state.eLearningPathPreview.isCourseLoading,
    shallowEqual,
  )
  return (
    <StyledContentWrapper>
      <Template noMinHeight>
        <StyledColumn>
          <CourseList />

          {isCourseLoading ? (
            <LoadingCircular sx={{ position: 'relative' }} isLoading />
          ) : (
            <SelectedContent />
          )}
        </StyledColumn>
      </Template>
    </StyledContentWrapper>
  )
}

export default Content

export const SelectedContent = () => {
  const { courseType, course } = useSelector(
    (state) => ({
      courseType: state.eLearningPathPreview.courseType,
      course: state.eLearningPathPreview.course,
    }),
    shallowEqual,
  )
  const isELearning = courseType === COURSE_TYPE.E_LEARNING
  const uuid = _.get(course, 'uuid', '')
  const coursePath = `${isELearning ? '/e-learning' : ''}/course/${uuid}`

  return (
    <>
      <Row
        sx={{ alignItems: 'start', justifyContent: 'space-between', gap: 1 }}
      >
        <Typography variant="h5" color="primary">
          {_.get(course, 'name', '') || _.get(course, 'englishName', '')}
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIos />}
          sx={{ minWidth: 126 }}
          onClick={() =>
            window.open(`${window.__env__.REACT_APP_LEARNER_URL}${coursePath}`)
          }
        >
          ไปยังหลักสูตร
        </Button>
      </Row>

      {isELearning ? <ELearningContent /> : <CourseContent />}
    </>
  )
}
