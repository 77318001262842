import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

export const StyledCard = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}))

export const StyledCardBoxPreview = styled(Box)(() => ({
  width: '159px',
}))

export const StyledLayoutBox = styled(Grid)(({ url, width }) => ({
  width: '3508px',
  height: '2480px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundImage: `url(${url})`,
  zoom: `calc(1 + (${width} - 3508) / 3508)`,
}))

export const StyledLayoutPreview = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: theme.spacing(3),
}))

export const StyledLayoutPreviewBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const StyledModalBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1028px',
  backgroundColor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 3,
}))

export const StyledModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'unset' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
