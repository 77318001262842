import React from 'react'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import _ from 'lodash'
import { blankReg } from './model'
import {
  StyledDialogContent,
  StyledDragDropContainer,
} from '../DragAndDrop/Styled'
import { getPlainTextWithBlank, handleRadioChange } from './events'
import { Box, Radio, RadioGroup } from '@mui/material'
import { StyledChoice } from '../Styled'

const FillChoice = ({ keyQuestion, question, isSubmit, questionResult }) => {
  const dispatch = useDispatch()
  const dialog = getPlainTextWithBlank(_.get(question, 'subTitle', ''))
  const choices = _.get(question, 'choices', [])
  const answer = _.get(question, 'answer', [])
  const resultProps = { isSubmit, isLoading: false, questionResult }

  return (
    <StyledDragDropContainer>
      <StyledDialogContent>
        <Typography>
          {reactStringReplace(dialog, blankReg, (match) => (
            <Typography
              variant="span"
              color="text.lightGray"
            >{`_____${match}_____`}</Typography>
          ))}
        </Typography>
      </StyledDialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {choices.map((choice, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
          >
            <Typography variant="body1b" color="text.secondary">
              ช่องว่าง {_.get(choice, 'target', '')}
            </Typography>
            <RadioGroup
              sx={{ ml: 3 }}
              value={_.get(answer, `[${index}].uuid`, '')}
              onChange={(e) =>
                dispatch(handleRadioChange(e, keyQuestion, choice, index))
              }
            >
              {_.get(choice, 'subAnswers', []).map((ans, key) => (
                <StyledChoice
                  key={key}
                  control={<Radio />}
                  checked={ans?.uuid === _.get(answer, `[${index}].uuid`, '')}
                  label={_.get(ans, 'title', '')}
                  value={_.get(ans, 'uuid', '')}
                  resultProps={{
                    ...resultProps,
                    isAnswer: ans.isCorrect,
                  }}
                />
              ))}
            </RadioGroup>
          </Box>
        ))}
      </Box>
    </StyledDragDropContainer>
  )
}
export default FillChoice
