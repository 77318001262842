import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { setExpand } from '../../../../redux/slices/eLearning/preview'
import { StyledTooltip } from '../../Module/View/Content/Styled'
import { COURSE_TYPE_TH } from '../../Course/Form/model'
import { StyledExpandButton } from '../Styled'
import ModuleLabel from './ModuleLabel'
import LessonLabel from './LessonLabel'
import {
  StyledHeader,
  StyledListContainer,
  StyledRightContent,
  StyledDoubleDivider,
  StyledHeaderText,
  StyledListColumn,
  StyledOneLineText,
  StyledProgressPercent,
} from './Styled'
import { handleProgressChange } from './events'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import MobileTabDisplay from './MobileTabDisplay'

const RightPanel = () => {
  const dispatch = useDispatch()
  const { isExpanded, course, lessons } = useSelector(
    (state) => ({
      isExpanded: state.eLearningPreview.isExpanded,
      course: state.eLearningPreview.course,
      lessons: state.eLearningPreview.lessons,
    }),
    shallowEqual,
  )
  const courseType = _.get(course, 'courseType', '')
  const courseName = _.get(course, 'name', '') || 'ชื่อหลักสูตร'
  const isNotSm = isMediaWidthMoreThan('sm')

  const progressPercent = useMemo(
    () => handleProgressChange(lessons),
    [lessons],
  )

  return (
    <StyledRightContent isExpanded={isExpanded}>
      {isExpanded && (
        <StyledHeader>
          <ProgressPercent progressPercent={progressPercent} />
          <StyledHeaderText>
            <Typography
              variant="body2"
              color="text.gray"
              sx={{ lineHeight: '20px' }}
            >
              {COURSE_TYPE_TH[_.lowerCase(courseType)] || 'ประเภทหลักสูตร'}
            </Typography>
            <StyledTooltip title={courseName}>
              <StyledOneLineText variant="body1b" sx={{ lineHeight: '24px' }}>
                {courseName}
              </StyledOneLineText>
            </StyledTooltip>
          </StyledHeaderText>
          <StyledExpandButton
            startIcon={<img src="/icon/player/hide_menu.svg" />}
            onClick={() => dispatch(setExpand(false))}
          >
            ซ่อนเมนู
          </StyledExpandButton>
        </StyledHeader>
      )}
      <StyledDoubleDivider />
      {isNotSm ? <LessonList /> : <MobileTabDisplay />}
    </StyledRightContent>
  )
}

export default RightPanel

export const ProgressPercent = ({ progressPercent }) => {
  return (
    <StyledProgressPercent>
      <Typography variant="h6" color="text.white">
        {progressPercent}%
      </Typography>
    </StyledProgressPercent>
  )
}

export const LessonList = () => {
  const { isCourse, lessons } = useSelector(
    (state) => ({
      isCourse: state.eLearningPreview.isCourse,
      lessons: state.eLearningPreview.lessons,
    }),
    shallowEqual,
  )

  return (
    <StyledListContainer sx={{ mb: '69px' }}>
      <StyledListColumn>
        {lessons.map((data, index) => {
          if (isCourse) {
            return <LessonLabel key={index} data={data} index={index} />
          } else {
            return (
              <ModuleLabel key={index} data={data} index={0} mIndex={index} />
            )
          }
        })}
      </StyledListColumn>
    </StyledListContainer>
  )
}
