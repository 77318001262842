import React from 'react'
import { useParams } from 'react-router-dom'
import { deleteFromStorage } from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { examLabel } from '../../../../../constants/manageClass'
import { handleChangeTab } from './events'

const TabContainer = ({ isStandalone }) => {
  const idProps = useParams()
  const { tab, learners } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const { learnerAll, learnerWait, learnerPass, learnerFail, learnerNotSent } =
    learners
  const dispatch = useDispatch()

  return (
    <Box>
      <Tabs
        variant="scrollable"
        value={tab}
        onChange={(e, tab) => {
          dispatch(handleChangeTab({ idProps, learners, tab, isStandalone }))
          deleteFromStorage('presentLearner')
        }}
      >
        <Tab
          data-testid="tab-all"
          label={`${examLabel.ALL} (${learnerAll.length})`}
          value="ALL"
        />
        <Tab
          data-testid="tab-waiting"
          label={`${examLabel.WAITING} (${learnerWait.length})`}
          value="WAITING"
        />
        <Tab
          data-testid="tab-pass"
          label={`${examLabel.PASS} (${learnerPass.length})`}
          value="PASS"
        />
        <Tab
          data-testid="tab-fail"
          label={`${examLabel.FAIL} (${learnerFail.length})`}
          value="FAIL"
        />
        {!isStandalone && (
          <Tab
            data-testid="tab-not-sent"
            label={`${examLabel.NOT_SENT} (${learnerNotSent.length})`}
            value="NOT_SENT"
          />
        )}
      </Tabs>
    </Box>
  )
}

export default TabContainer
