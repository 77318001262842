import axios from 'axios'
import _ from 'lodash'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from '../../../../../../redux/slices/dialog'
import {
  resetPage,
  setInitialTable,
  setSearchText,
  resetTablePage,
} from '../../../../../../redux/slices/table'
import {
  closePointDialog,
  setPayloadKey,
  startPointLoading,
  stopPointLoading,
  setNewPointRemain,
} from '../../../../../../redux/slices/manage/agentProfile'
import {
  postPointByUuid,
  postRedeem,
  postSavePoint,
  postredeemableCatalog,
} from '../../../../../../utils/apiPath'
import { catalogHeadCells } from '../model'

export const handleSubmit = (values, uuid) => async (dispatch) => {
  try {
    dispatch(startPointLoading())
    await axios.post(postSavePoint, { ...values, userUuid: uuid })
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        agreeText: 'ตกลง',
        isCloseDialog: false,
        handleConfirm: async () => {
          dispatch(closeDialog())
          await dispatch(getPoint(uuid))
          dispatch(stopPointLoading())
          dispatch(closePointDialog())
        },
      }),
    )
  } catch {
    dispatch(stopPointLoading())
  }
}

const getPoint = (uuid) => async (dispatch) => {
  const res = await axios.post(postPointByUuid, { userUuid: uuid })
  dispatch(setPayloadKey({ key: 'point', data: res.data.point }))
}

export const fetchCatalog =
  ({ table, page, keyword, point, cart }) =>
  async (dispatch) => {
    dispatch(startPointLoading())
    const { limit } = table
    const newPage = page <= 0 ? 1 : page
    const body = { limit, page: newPage, name: keyword, point }

    dispatch(
      setInitialTable({
        rows: [],
        allCount: 0,
        headCells: catalogHeadCells,
        isCheckBox: false,
      }),
    )

    await axios
      .post(postredeemableCatalog, body)
      .then((res) => {
        const data = _.get(res, 'data.results', [])
        const outOfStock = _.filter(data, (ele) => ele.qty <= 0)
        const outOfStockInCart = _.filter(outOfStock, (ele) =>
          _.find(cart, (item) => item.uuid === ele.uuid),
        )
        const rowsData = [
          ...outOfStockInCart,
          ..._.filter(data, (ele) => ele.qty > 0),
        ]
        dispatch(
          setInitialTable({
            rows: rowsData,
            allCount:
              _.get(res, 'data.totalCount', 0) -
              outOfStock.length +
              outOfStockInCart.length,
            headCells: catalogHeadCells,
            isCheckBox: false,
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: catalogHeadCells,
            isCheckBox: false,
          }),
        )
        return e.message?.includes('method')
      })
    dispatch(stopPointLoading())
  }

export const handleQuickSearch =
  (table, keyword, point, cart) => (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())

    if (table.page === 1) {
      dispatch(
        fetchCatalog({
          table,
          page: 1,
          keyword,
          point,
          cart,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const handleRedeem =
  ({ userUuid, cart, cost, table, point }) =>
  async (dispatch) => {
    try {
      dispatch(startPointLoading())
      await axios.post(postRedeem, { userUuid, cart, cost })
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          agreeText: 'ตกลง',
          isCloseDialog: false,
          handleConfirm: async () => {
            dispatch(closeDialog())
            await dispatch(getPoint(userUuid))
            dispatch(stopPointLoading())
            dispatch(closePointDialog())
          },
        }),
      )
    } catch (e) {
      dispatch(
        openErrorDialog({
          title: e.response.data.constraints.title,
          message: e.response.data.constraints.value,
          isCloseDialog: false,
          handleError: async () => {
            await dispatch(
              fetchCatalog({ table, page: 1, keyword: '', point, cart }),
            )
            await dispatch(getPoint(userUuid))
            dispatch(setNewPointRemain())
            dispatch(closeDialog())
            dispatch(stopPointLoading())
          },
        }),
      )
    }
  }
