import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}))

export const StyledColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledContainer = styled(StyledRow)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: { paddingTop: 164 },
  [theme.breakpoints.down('sm')]: { paddingTop: theme.spacing(0.5) },
}))

export const StyledListContainer = styled(Box)(({ listHeight }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 266px)',
  height: listHeight,
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledCardLearner = styled(Box)(({ theme, selected }) => ({
  position: 'relative',
  width: 200,
  display: 'flex',
  gap: theme.spacing(1.5),
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  background: selected
    ? theme?.palette?.primary?.outlinedHoverBackground
    : 'transparent',
  cursor: 'pointer',
  img: {
    width: 32,
    heigth: 32,
    borderRadius: '100%',
  },
  svg: {
    position: 'absolute',
    right: 12,
    top: 16,
    fontSize: '16px',
  },
  '& > .MuiTypography-root': {
    width: 104,
    whiteSpace: 'pre',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
