import * as yup from 'yup'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { isValidationExcepted } from '../../events'

export const eEvaluationSchema = yup
  .object()
  .nullable()
  .shape({
    uuid: yup
      .string()
      .test('required', 'กรุณาเลือกแบบประเมิน', (value, context) => {
        if (isValidationExcepted(context, MODULE_TYPE.E_EVALUATION)) return true
        return value
      }),
  })
