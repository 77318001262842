import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({ height: '100vh', '& .MuiDrawer-paper': { width: 360 } }))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

export const StyledContent = styled(Box)(() => ({
  position: 'relative',
  opacity: 'unset',
  pointerEvents: 'unset',
}))

export const StyledContentHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '24px 24px 0px 24px',
}))

export const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '24px 24px',
  border: '1px solid #CCCCCC',
  borderRadius: '8px',
  padding: '12px 24px',
}))

export const StyledTextTile = styled(Typography)(() => ({
  width: 190,
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
