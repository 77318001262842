import dayjs from 'dayjs'

export const arrayALLCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'checkbox',
  },
]

export const arrayPICCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
]

export const defaultFilter = {
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  stationChecked: true,
  station: [],
  stationName: [],
  displayDate: true,
}
