import React from 'react'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledCard = styled((props) => <Card {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  minWidth: 275,
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
