import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import {
  StyledBoxGraph,
  StyledLegendBox,
  StyledCircle,
  StyledTextContainer,
  StyledContainer,
  StyledBoxPercentage,
} from './Styled'
import _ from 'lodash'

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutGraph = ({ size, showPercentage, graphData, haveWaiting }) => {
  const graphColors = ['#1CC54E', '#C91432', haveWaiting && '#DBE4F1']
  const lodashGraph = _.defaultTo(graphData, [])
  let getGraphData = [
    lodashGraph['pass']?.length,
    lodashGraph['notPass']?.length,
  ]
  let labels = ['ผ่าน', 'ไม่ผ่าน']

  if (haveWaiting) {
    labels.push('รอผล')
    getGraphData.push(lodashGraph['waiting']?.length)
  }

  const data = {
    labels: labels,
    datasets: [
      {
        data: getGraphData,
        backgroundColor: graphColors,
        borderWidth: 1,
        clip: 1,
        borderColor: graphColors,
      },
    ],
  }
  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }

  const sum = data.datasets[0].data.reduce((a, b) => a + b, 0)
  return (
    <StyledContainer>
      <StyledBoxGraph size={size}>
        {showPercentage && (
          <StyledBoxPercentage>
            <Typography variant="body2">
              {data.datasets[0].data[0]
                ? ((data.datasets[0].data[0] / sum) * 100).toFixed(0)
                : 0}
              %
            </Typography>
          </StyledBoxPercentage>
        )}
        <Doughnut data={data} options={options} />
      </StyledBoxGraph>
      <StyledLegendBox>
        {data.labels.map((item, index) => (
          <StyledTextContainer key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <StyledCircle color={data.datasets[0].backgroundColor[index]} />
              <Typography variant="body2">{item}</Typography>
            </Box>
            <Typography variant="body2">
              {data.datasets[0].data[index]
                ? ((data.datasets[0].data[index] / sum) * 100).toFixed(0)
                : 0}
              %
            </Typography>
          </StyledTextContainer>
        ))}
      </StyledLegendBox>
    </StyledContainer>
  )
}

export default DoughnutGraph
