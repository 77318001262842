import _ from 'lodash'
import * as tableAttendance from '../../../../redux/slices/tableAttendance'
import { headCells } from '../../../../constants/manageClass'
import { getDataAttendance } from '../../../../services/attendance'

export const fetchDataCheckName = (uuid) => async (dispatch) => {
  dispatch(tableAttendance.startLoading())
  const result = await dispatch(getDataAttendance(uuid))
  dispatch(
    tableAttendance.setInitialTable({
      headCells,
      listTable: _.get(result, 'payload', []),
    })
  )
  dispatch(tableAttendance.stopLoading())
}
