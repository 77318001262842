import { Box } from '@mui/material'
import Header from '../components/Header'
import FiltersSummary from '../components/FiltersSummary'
import SummaryAllClassCard from './components/SummaryAllClassCard'
import SummaryStatusOfClassCard from './components/SummaryStatusOfClassCard'
import ActivityList from './components/ActivityList'
import FilterDrawer from '../components/FilterDrawer'
import ClassDrawer from './components/ClassDrawer'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { LoadingPage } from './Styled'
import {
  setReduxDashboardByKey,
  setFiltersDashboard,
} from 'src/redux/slices/monthlyPlan'
import { getDefaultFiltersDashboard } from '../handler/getDefaultFiltersDashboard'

const ClassDashboard = () => {
  const dispatch = useDispatch()
  const {
    academyActivityList,
    activitySupportList,
    isLoading,
    totalAcademyActivity,
    totalActivitySupport,
  } = useSelector(
    (state) => ({
      academyActivityList: state.monthlyPlan.dashboard.academyActivityList,
      activitySupportList: state.monthlyPlan.dashboard.activitySupportList,
      isLoading: state.monthlyPlan.dashboard.isLoading,
      totalAcademyActivity: state.monthlyPlan.dashboard.totalAcademyActivity,
      totalActivitySupport: state.monthlyPlan.dashboard.totalActivitySupport,
    }),
    shallowEqual,
  )

  useEffect(async () => {
    dispatch(setReduxDashboardByKey({ key: 'name', value: 'class' }))

    const defaultFilters = await getDefaultFiltersDashboard()
    dispatch(setFiltersDashboard(defaultFilters))
  }, [])

  return (
    <Box
      sx={{
        opacity: isLoading ? 0.4 : 'unset',
        pointerEvents: isLoading ? 'none' : 'unset',
      }}
      display="flex"
      flexDirection="column"
      gap={3}
      mx={3}
      mb={3}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Header />
        <FiltersSummary />
      </Box>
      <SummaryAllClassCard />
      <SummaryStatusOfClassCard />
      <Box display="flex" gap={3}>
        <ActivityList
          title="Academy Activity"
          activityList={academyActivityList}
          count={totalAcademyActivity}
        />
        <ActivityList
          title="Activity Support"
          activityList={activitySupportList}
          count={totalActivitySupport}
        />
      </Box>
      <FilterDrawer />
      <ClassDrawer />
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default ClassDashboard
