import { StyledRow } from '../../../Styled'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainerCourse = styled(StyledRow)(({ theme }) => ({
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: { width: '200%', justifyContent: 'center' },
}))

export const StyledCourseCard = styled(Box)(({ theme }) => ({
  width: 'calc(25% - 14px)',
  minHeight: 194,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'end',
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { width: 'calc(50% - 8px)' },
}))

export const StyledTabHeader = styled(StyledRow)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))
