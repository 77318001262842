export const initialState = {
  isOpen: false,
  isConfirmation: false,
  isLoading: false,
  title: '',
  icon: undefined,
  colorDisagreeText: undefined,
  colorAgreeText: undefined,
  message: '',
  type: '',
  content: null,
  maxWidth: null,
  disableButton: false,
  handleConfirm: null,
  handleError: null,
  handleCancel: null,
  agreeText: undefined,
  isCloseDialog: true,
  fullWidth: true,
  sx: {},
  dataField: '',
  hideCancel: false,
}
