import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import Select from '../../../../components/Input/Select'
import { useLocalStorage } from '@rehooks/local-storage'
import { hasPermissionCreateMasterData, getOptionsKey } from '../../../../utils/lib'
import TextInput from '../../../../components/Input/TextInput'
import AlertDialog from '../../../../components/AlertDialog'
import SwitchInput from '../../../../components/Input/SwitchInput'
import Divider from '@mui/material/Divider'
import {
  handleNumberKeyDown,
  handlePasteFormat,
  convertFormatDateTime,
} from '../../../../utils/lib'

import _ from 'lodash'

const ExaminationForm = ({
  isEditForm,
  dialog,
  setDialog,
  handleClose,
  formik,
  initialValues,
  setInitialValues,
  getRegion,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [disableSubmit, setDisableSubmit] = useState(false)
  const RegionOption =
  getRegion &&
  getOptionsKey({
    data: getRegion,
    label: 'regionName',
    key: 'uuid',
  })

  useEffect(() => {
    if(
      initialValues.region != '' 
      && (initialValues.provinceName != '' && String(initialValues.provinceName).length <= 255)
      && (initialValues.locationCode != '' && String(initialValues.locationCode).length <= 4)
    ){
      setDisableSubmit(false)
    }else{
      setDisableSubmit(true)
    }
  }, [initialValues])

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5' marginY={theme.spacing(2)} marginX={3}>
          รายละเอียดสนามสอบสมาคม
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: '50%'
          }}
        >
          <Select
            dataTestid="select-region"
            id="region"
            name="region"
            labelText="ภูมิภาค"
            type="basic"
            required
            disabled={!hasPermissionCreate}
            placeholder={'เลือก'}
            boxSx={{ mx: 3 }}
            options={!_.isNil(RegionOption) ? RegionOption : []}
            value={initialValues.region}
            handleChange={(e) => {
              formik.setFieldValue('region', e.target.value)
              setInitialValues({
                ...initialValues,
                region: e.target.value,
              })
            }}
            textError={formik?.errors?.region}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="provinceName"
            name="provinceName"
            labelText="ชื่อจังหวัด"
            type="basic"
            inputProps={{
              'data-testid': 'provinceName',
            }}
            textError={
              String(initialValues.provinceName).length > 255
                ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
                : formik?.errors?.provinceName
            }
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            value={initialValues.provinceName}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, provinceName: '' })
              formik.setFieldValue('provinceName', e.target.value)
              setInitialValues({
                ...initialValues,
                provinceName: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
          <TextInput
            id="locationCode"
            name="locationCode"
            labelText="รหัสสถานที่สอบ"
            type="basic"
            inputProps={{
              'data-testid': 'locationCode',
              onInput: handleInputFormat,
              onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
              onPaste: handlePasteFormat,
            }}
            textError={
              String(initialValues.locationCode).length >= 5
                ? 'กรอกได้ไม่เกิน 4 ตัวอักษร'
                : formik?.errors?.locationCode
            }
            value={initialValues.locationCode}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, locationCode: '' })
              formik.setFieldValue('locationCode', e.target.value)
              setInitialValues({
                ...initialValues,
                locationCode: e.target.value,
              })
            }}
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
          ></TextInput>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            multiline
            rows={3}
            id="description"
            name="description"
            type="basic"
            labelText="Description"
            inputProps={{
              'data-testid': 'description',
            }}
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            textError={formik?.errors?.description}
            value={initialValues.description}
            onChange={(e) => {
              formik.values.description = e.target.value
              setInitialValues({
                ...initialValues,
                description: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Divider sx={{ mx: 3, mb: 3 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            multiline
            rows={3}
            id="annotation"
            name="annotation"
            type="basic"
            labelText="หมายเหตุ"
            inputProps={{
              'data-testid': 'annotation',
            }}
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            textError={formik?.errors?.annotation}
            value={initialValues.annotation}
            onChange={(e) => {
              formik.values.annotation = e.target.value
              setInitialValues({
                ...initialValues,
                annotation: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            value={initialValues.status}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
            boxSx={{ mx: 3 }}
          ></SwitchInput>
        </Box>

        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate || disableSubmit}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              marginLeft: 'auto',
              flexDirection: 'column',
              [theme.breakpoints.down('md')]: {
                mt: 2,
              },
            }}
          >
            <Typography variant="caption" color="text.lightGray">
              สร้างโดย{' '} { !isEditForm ? '-' : (
                <>
                  {initialValues && initialValues.createdBy
                    ? initialValues.createdBy?.name
                    : ''}
                  {' '}
                  {initialValues && initialValues.createdAt
                    ? convertFormatDateTime({ value: initialValues.createdAt, type: 'dateTime' })
                    : ''}
                </>
              ) }
            </Typography>
            <Typography variant="caption" color="text.lightGray">
              แก้ไขล่าสุดโดย{' '} { !isEditForm ? '-' : (
                <>
                  {initialValues && initialValues.updatedBy
                    ? initialValues.updatedBy?.name
                    : ''}
                  {' '}
                  {initialValues && initialValues.updatedAt
                    ? convertFormatDateTime({ value: initialValues.updatedAt, type: 'dateTime' })
                    : '-'}
                </>
              ) }
            </Typography>
          </Box>
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </Card>
  )
}
export default ExaminationForm

export const regex = {
  NUMBER: /^[0-9]+$/,
}

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value

  if (!regex['NUMBER'].test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex['NUMBER'].test(e.target.value)) e.target.value = ''
  return e.target.value
}