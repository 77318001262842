import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { detailDrawerEnum, filtersDrawerEnum } from '../enums/drawerEnum'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  store.dispatch(
    setFieldValue({
      key: 'mixModuleDashboard.filtersDrawer',
      value: filtersDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'mixModuleDashboard.evaluationDrawer',
      value: detailDrawerEnum,
    }),
  )
  await fetchOverview()
}
