import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../../../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenStaffDrawer } from '../../../../../redux/slices/monthlyPlan'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Table from '../../../../../components/redux/Table'
import { useTheme } from '@mui/system'
import {
  TOP_LIST_TYPE,
  TOP_LIST_TYPE_LABEL,
} from 'src/constants/monthlyPlan/dashboard'
import SearchAndDownloadToolbar from '../../DetailsTable/components/SearchAndDownloadToolbar'
import { downloadStaffList } from '../../handler/downloadStaffList'

const StaffDrawer = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isOpenStaffDrawer, staffType, allCount } = useSelector(
    (state) => ({
      isOpenStaffDrawer: state.monthlyPlan.dashboard.isOpenStaffDrawer,
      staffType: state.monthlyPlan.dashboard.staffType,
      allCount: state.table.table.allCount,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{
        position: 'relative',
        '& .MuiPaper-root': {
          width: 630,
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      open={isOpenStaffDrawer}
      onClose={() => dispatch(setIsOpenStaffDrawer(false))}
    >
      <StyledHeadLabel sx={{ mb: 1.25 }}>
        <Box>
          <Typography variant="h5">
            รายชื่อ
            {`${
              staffType === TOP_LIST_TYPE.SPEAKER
                ? 'หน้าที่'
                : staffType === TOP_LIST_TYPE.WORKLOAD
                ? 'ที่มี'
                : ''
            } ${TOP_LIST_TYPE_LABEL[staffType]} ${
              staffType !== TOP_LIST_TYPE.SPEAKER
                ? 'สูงสุด (ทุกประเภท)'
                : 'มากที่สุด'
            }`}
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenStaffDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <Box
        sx={{
          backgroundColor: '#F1F4FA',
          borderRadius: 4,
          width: 'fit-content',
        }}
        mx={3}
        px={1.25}
        py={0.75}
        mb={1}
      >
        <Typography variant="body2b" color="primary">
          รายชื่อทั้งหมด {allCount} คน
        </Typography>
      </Box>

      <Box
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: 1,
        }}
        mx={3}
      >
        <SearchAndDownloadToolbar onDownload={() => downloadStaffList()} />
        <Table />
      </Box>

      <StyledFooter>
        <Button
          sx={{ ml: 'auto', maxWidth: 40 }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => dispatch(setIsOpenStaffDrawer(false))}
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default StaffDrawer
