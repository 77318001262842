import { Box } from '@mui/system'
import { StyledDragHolder } from './Styled'

export const DragHolder = ({ provided }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...provided.dragHandleProps}
    >
      <StyledDragHolder />
    </Box>
  )
}
