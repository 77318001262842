import React from 'react'
import { openDialog } from '../../../../../redux/slices/dialog'
import { setClearBodyDialog } from '../../../../../redux/slices/manageClassAttendance'

import ContentDialog from './ContentDialog'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
    return
  }
  setOpen(false)
}

export const attendanceDialog = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      content: <ContentDialog />,
      maxWidth: 'sm',
      handleCancel: () => dispatch(setClearBodyDialog()),
    })
  )
}
