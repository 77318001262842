import React, { useRef, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Print from '@mui/icons-material/Print'
import { Column, Row } from '../../../../../components/Preview/Layout/Styled'
import { StyledDivider } from '../../../components/View/Styled'
import { StyledCard, StyledFormColumn } from '../../../Styled'
import BasicTable from '../BasicTable'
import { TABLE_KEY } from '../model'
import { isOic } from '../events'
import FaceHistory from './FaceHistory'
import ClickHistory from './ClickHistory'
import AttendHistory from './AttendHistory'
import { fetchLearnerDetail } from './events'

const AttendanceTab = () => {
  const learningRound = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail.learningRound,
  )
  return (
    <StyledFormColumn>
      {learningRound.map((item, index) => (
        <AttendanceCard key={index} data={item} index={index} />
      ))}
    </StyledFormColumn>
  )
}

export const AttendanceCard = ({ data, index }) => {
  const { isLoading, enrollData, expandedRound } = useSelector((state) => ({
    isLoading: state.eLearningCourseBatchLearnerDetail.isLoading,
    enrollData: state.eLearningCourseBatchLearnerDetail.enrollData,
    expandedRound: state.eLearningCourseBatchLearnerDetail.expandedRound,
  }))
  const isTypeOic = isOic()
  const uuid = data.uuid
  const isExpand = isTypeOic || uuid === expandedRound
  const eLearningCourse = isTypeOic
    ? _.get(enrollData, 'eLearningCourseBatch.eLearningCourse', {})
    : _.get(data, 'eLearningCourse', {})
  const dispatch = useDispatch()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrinting(false),
  })
  const [isPrinting, setIsPrinting] = useState(false)
  return (
    <div ref={componentRef}>
      <StyledCard sx={{ mt: 0, p: 3 }}>
        <Column sx={{ gap: 3 }}>
          <Row sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">
              หลักสูตร : {_.get(eLearningCourse, 'name', '')}
              {!isTypeOic && ` (ครั้งที่ ${index + 1})`}
            </Typography>
            <Row sx={{ width: 'auto', gap: 2 }}>
              {isExpand && (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setIsPrinting(true)
                    setTimeout(() => {
                      handlePrint()
                    }, 300)
                  }}
                >
                  <Print />
                </IconButton>
              )}
              {!isTypeOic && (
                <IconButton
                  onClick={() => dispatch(fetchLearnerDetail(isExpand, uuid))}
                >
                  {isExpand ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </Row>
          </Row>
          {!isLoading && isExpand && (
            <>
              {isTypeOic && (
                <>
                  <StyledDivider />
                  <FaceHistory isPrinting={isPrinting} />

                  <StyledDivider />
                  <ClickHistory />
                </>
              )}

              <StyledDivider />
              <AttendHistory />

              <StyledDivider />
              <Column sx={{ gap: 1 }}>
                <Typography variant="h6">โมดูล</Typography>
                <BasicTable keyTable={TABLE_KEY.module} />
              </Column>
            </>
          )}
        </Column>
      </StyledCard>
    </div>
  )
}

export default AttendanceTab
