import _ from 'lodash'
import { store } from '../../../App'
import { postModuleCreateMultiPart } from '../service/postModuleCreateMultiPart'
import { postModuleCompleteMultiPart } from '../service/postModuleCompleteMultiPart'
import { uniqId } from '../../../services/util'
import { openErrorDialog } from '../../../redux/slices/dialog'
import { postModuleUploadPart } from '../service/postModuleUploadPart'
import { componentConfig } from '../component.config'
import { dispatchUploadValue } from './dispatchUploadValue'

export const CHUNK_SIZE = componentConfig.CHUNK_SIZE * 1024 * 1024 // 10MB chunk size

export const handleMultipleFilesUpload = (
  files,
  isMultiple,
  fileRejections,
  uploadType,
  fieldName,
  maxLength,
  onChange,
) => {
  if (fileRejections.length) {
    store.dispatch(
      openErrorDialog({
        message:
          'กรุณาตรวจสอบว่าขนาดไฟล์, จำนวนไฟล์, หรือประเภทไฟล์ของคุณว่าเป็นไปตามที่ระบบกำหนดหรือไม่',
        title: 'ไม่สามารถดำเนินการได้',
        isCloseDialog: false,
      }),
    )
    return
  }
  dispatchUploadValue(`formData[${fieldName}].isUploading`, true)
  if (!isMultiple) {
    dispatchUploadValue(`formData[${fieldName}].files`, [])
  }
  if (files.length === 0) return
  const state = store.getState()
  const uploadedFiles = _.get(
    state,
    `uploadFile.formData[${fieldName}].files`,
    [],
  )

  let totalFiles = _.concat(uploadedFiles, files)
  if (totalFiles.length > maxLength) {
    totalFiles.slice(maxLength * -1)
  }
  _.forEach(totalFiles, async (file, index) => {
    try {
      if (_.isEmpty(file) || file.fileType) {
        return
      }
      const _id = uniqId()
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE)
      const displayName = _.get(file, 'name', '') || ''
      const fileName = `${Date.now().toString()}_${_.replace(displayName, ' ')}`
      const fileType = displayName.split('.').pop()
      const fileSize = _.get(file, 'size', 0)
      const fileValue = {
        displayName,
        fileType,
        id: index + 1,
        _id,
        downloadable: false,
        fileSize: (fileSize / 1000000).toFixed(2),
      }
      dispatchUploadValue(`formData[${fieldName}].files[${index}]`, fileValue)
      const requestBody = { fileName }
      const res = await postModuleCreateMultiPart(requestBody)
      const uploadId = _.get(res.data, 'uploadId', undefined)
      const uploadPromises = []
      let uploadedChunks = 0
      let start = 0
      let end
      for (let i = 0; i < totalChunks; i++) {
        try {
          end = start + CHUNK_SIZE
          const chunk = file.slice(start, end)
          const formData = new FormData()
          formData.append('index', i)
          formData.append('totalChunks', totalChunks)
          formData.append('fileName', fileName)
          formData.append('file', chunk)
          formData.append('uploadId', uploadId)
          formData.append('uploadType', uploadType)
          const uploadPromise = await postModuleUploadPart({
            body: formData,
            currentChunk: uploadedChunks,
            totalChunks,
            index,
            fieldName,
          })
          uploadedChunks++
          uploadPromises.push(uploadPromise)
          start = end
        } catch (e) {
          throw new Error(e)
        }
      }
      await Promise.all(uploadPromises)
      const request = {
        body: { fileName, uploadId, uploadType },
        _id,
        index,
        fieldName,
        onChange,
      }
      await postModuleCompleteMultiPart(request)
      onChange(store.getState().uploadFile.formData[fieldName].files)
      dispatchUploadValue(`formData[${fieldName}].isUploading`, false)
    } catch (e) {
      console.log(e)
      dispatchUploadValue(
        `formData[${fieldName}].files[${index}].error`,
        'ERROR_UPLOAD_FILE',
      )
      dispatchUploadValue(`formData[${fieldName}].isUploading`, false)
    }
  })
}
