import { memo } from 'react'
import { useInputField } from '../hooks/useInputField'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setFieldValue } from '../../../redux/slices/crud'
import DatePicker from '../../../components/Input/DatePicker'
// import _ from 'lodash'
import dayjs from 'dayjs'

const CustomDateInput = memo(
  ({
    boxSx,
    disabled,
    fieldName,
    labelName,
    handleExtraChange,
    placeholder,
    required = false,
    disablePast,
    disabledStartDate = null,
    disabledEndDate = null,
    hideDisableDateRange = true,
    isShouldDisableDate,
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)

    const { crudConfig } = useSelector(
      (state) => ({ crudConfig: state.crud.crudConfig }),
      shallowEqual,
    )

    function handleValueChange(event) {
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: event }))
      if (handleExtraChange) {
        handleExtraChange(event)
      }
    }

    return (
      <DatePicker
        disablePast={disablePast}
        boxSx={boxSx}
        id={`${crudConfig.moduleSlug}-${fieldName}`}
        labelText={labelName}
        name={fieldName}
        required={required}
        placeholder={placeholder ? placeholder : 'เลือกวันที่'}
        hideTextError={false}
        disabledStartDate={
          disabledStartDate && dayjs(disabledStartDate).isValid()
            ? dayjs(disabledStartDate).format(window.__env__.REACT_APP_DATE_DB)
            : '1900-01-01'
        }
        disabledEndDate={
          disabledEndDate && dayjs(disabledEndDate).isValid()
            ? dayjs(disabledEndDate).format(window.__env__.REACT_APP_DATE_DB)
            : disabledEndDate === 'none'
            ? ''
            : dayjs(new Date()).format(window.__env__.REACT_APP_DATE_DB)
        }
        hideDisableDateRange={hideDisableDateRange}
        isShouldDisableDate={isShouldDisableDate}
        value={value ?? null}
        onChange={handleValueChange}
        textError={message}
        showTextError={isError}
        disabled={disabled}
        disabledInput={disabled}
      />
    )
  },
)

CustomDateInput.displayName = 'CustomDateInputComponent'

export { CustomDateInput }
