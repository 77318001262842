import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect } from 'react'
import {
  StyledContainer,
  StyledBox,
  InlineBox,
  BoxFlex,
  StyledContainerFooter,
  StyledGroupFooter,
  StyledSubGroupFooter,
  StyledHistoryFooter,
  StyledCourseRefWrapper,
} from './Styled'
import _ from 'lodash'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'

import HistoryDrawer from './HistoryDrawer'
import { eCertificationVersionHistory } from '../../../../utils/apiPath'
import { callAPI } from '../../../../utils/lib'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { mapAssignee } from '../event'
import { useDispatch } from 'react-redux'
import * as reducer from '../../../../redux/slices/eCertificateForm'
import {
  versionStatus,
  versionStatusColor,
} from '../../../../constants/eCertification'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'

const Index = () => {
  const { id: uuid } = useParams()
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [isExpanded, setExpand] = useState(false)
  const courseVersion = useSelector(
    (state) => _.get(state.eCertificateForm, 'courseVersion', []),
    shallowEqual
  )

  const dispatch = useDispatch()

  const {
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    activeDate,
    status,
    listAssignee,
    assignees,
  } = useSelector(
    (state) => ({
      createdBy: state.eCertificateForm.createdBy,
      createdAt: state.eCertificateForm.createdAt,
      updatedBy: state.eCertificateForm.updatedBy,
      updatedAt: state.eCertificateForm.updatedAt,
      activeDate: state.eCertificateForm.activeDate,
      status: state.eCertificateForm.status,
      listAssignee: state.eCertificateForm.listAssignee,
      assignees: state.eCertificateForm.assignees,
    }),
    shallowEqual
  )

  const onHistoryClick = () => {
    setIsHistoryDrawer((o) => !o)
  }

  const fetchHistory = async () => {
    const realPage = page === 0 ? 1 : page + 1
    const url = `${eCertificationVersionHistory}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  useEffect(() => {
    fetchHistory()
  }, [limit, page])

  return (
    <StyledContainer container>
      <StyledBox item xs={12} md={12}>
        <InlineBox>
          <Typography color="text.primary" variant="body1">
            วันเริ่มต้นใช้งาน
          </Typography>
          <Typography color="text.primary" variant="body1">
            {dayjs(activeDate).format('DD/MM/YYYY')}
          </Typography>
        </InlineBox>
        <InlineBox>
          <Typography color="text.primary" variant="body1">
            สถานะของประกาศนียบัตร
          </Typography>
          <Typography color={versionStatusColor[status]} variant="body1">
            {versionStatus[status]}
          </Typography>
        </InlineBox>
        <StyledCourseRefWrapper>
          <InlineBox sx={{ m: 0, mb: isExpanded ? 0 : 2 }}>
            <Typography color="text.primary" variant="body1">
              หลักสูตรที่นำไปใช้ ({courseVersion.length})
            </Typography>
            <IconButton
              sx={{ width: 30, height: 30 }}
              data-testid="expand-corseRef"
              onClick={() => setExpand(!isExpanded)}
            >
              {isExpanded && <ExpandLess color="primary" />}
              {!isExpanded && <ExpandMoreOutlined color="primary" />}
            </IconButton>
          </InlineBox>

          {isExpanded && courseVersion.length > 0 && (
            <ul
              style={{
                width: '100%',
                margin: 0,
                marginBottom: isExpanded ? 16 : 0,
              }}
            >
              {courseVersion.map((item, index) => (
                <li key={index} style={{ textAlign: 'start' }}>
                  <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                    {item.englishName}{' '}
                    <Typography variant="span" color="primary">
                      {item.nameForLearner}
                    </Typography>{' '}
                    เวอร์ชั่น {item.version}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </StyledCourseRefWrapper>
      </StyledBox>
      <BoxFlex item xs={12} md={12}>
        <SelectGroupChip
          id="assignee"
          name="assignee"
          labelText="ผู้ร่วมจัดทำ"
          placeholder=""
          options={listAssignee}
          showTextError={false}
          value={mapAssignee(assignees)}
          onChange={(list) =>
            dispatch(
              reducer.setChange({
                key: 'assignees',
                value: list,
              })
            )
          }
          menuSx={{ width: 'calc(100% - 48px)', top: 325 }}
        />
      </BoxFlex>
      <StyledContainerFooter item xs={12} md={12}>
        <StyledGroupFooter>
          <StyledSubGroupFooter>
            <Typography variant="body1">วันที่สร้าง</Typography>
            <Typography variant="body1">วันที่แก้ไขล่าสุด</Typography>
          </StyledSubGroupFooter>
          <StyledSubGroupFooter>
            <Typography variant="body1">{createdAt}</Typography>
            <Typography variant="body1">{updatedAt}</Typography>
          </StyledSubGroupFooter>
        </StyledGroupFooter>
        <StyledGroupFooter>
          <StyledSubGroupFooter>
            <Typography variant="body1">สร้างโดย</Typography>
            <Typography variant="body1">แก้ไขล่าสุดโดย</Typography>
          </StyledSubGroupFooter>
          <StyledSubGroupFooter>
            <Typography variant="body1">
              {_.get(createdBy, 'name', '-')}
            </Typography>
            <Typography variant="body1">
              {_.get(updatedBy, 'name', '-')}
            </Typography>
          </StyledSubGroupFooter>
        </StyledGroupFooter>
        <StyledHistoryFooter>
          <IconButton onClick={onHistoryClick}>
            <HistoryTwoTone />
          </IconButton>
        </StyledHistoryFooter>
        <HistoryDrawer
          open={isHistoryDrawer}
          onClose={() => setIsHistoryDrawer(false)}
          historyList={historyList}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
          allCount={allCount}
        />
      </StyledContainerFooter>
    </StyledContainer>
  )
}

export default Index
