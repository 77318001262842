export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayWarehouseType = [
  {
    label: 'Academy',
    value: 'ACADEMY',
  },
  {
    label: 'Vendor',
    value: 'PROVIDER',
  },
]

export const arrayCheckbox = [
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'ค้นหา',
    isStation: true,
  },
  {
    label: 'Vendor',
    nameCheckBox: 'vendorChecked',
    nameInput: 'vendor',
    placeholder: 'ค้นหา vendor',
  },
  {
    label: 'วันที่สร้าง',
    nameCheckBox: 'createdAtChecked',
    nameInput: 'createdAt',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'วันที่แก้ไขล่าสุด',
    nameCheckBox: 'updatedAtChecked',
    nameInput: 'updatedAt',
    placeholder: 'จากวันที่ - ถึงวันที่',
    isDate: true,
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
  },
]

export const defaultFilter = {
  warehouseTypeChecked: false,
  warehouseType: '',
  stationChecked: false,
  station: [],
  vendorChecked: false,
  vendor: '',
  createdAtChecked: false,
  createdAt: '',
  updatedAtChecked: false,
  updatedAt: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
