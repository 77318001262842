import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import UploadLoading from '../../../../../UploadLoading'
import { StyledColumn, StyledRow } from '../../../../Styled'
import { StyledImagePreview, StyledInputHidden } from './Styled'
import { handleUpload } from './events'

const ImageUploader = ({ mediaType, isMobile }) => {
  const dispatch = useDispatch()
  const { isUploadLoading, error } = useSelector(
    (state) => ({
      isUploadLoading: _.get(
        state.manageContentELearningHome,
        'isUploadLoading',
        {},
      ),
      error: state.manageContentELearningHome.error,
    }),
    shallowEqual,
  )
  const { values, errors, setValues } = useFormikContext()
  const image = _.get(values, 'image', '')
  const videoImg = _.get(values, 'videoImg', '')
  const mobileImg = _.get(values, 'mobileImg', '')
  const media =
    mediaType === 'IMAGE' ? (isMobile ? mobileImg : image) : videoImg
  const key =
    mediaType === 'IMAGE' ? (isMobile ? 'mobileImg' : 'image') : 'videoImg'

  return (
    <StyledColumn>
      <StyledRow alignItems="center">
        {isUploadLoading[key] ? (
          <UploadLoading id={`upload-${key}`} />
        ) : (
          media != '' && (
            <StyledImagePreview>
              <img src={`${window.__env__.REACT_APP_API_URL}/file${media}`} />
            </StyledImagePreview>
          )
        )}

        <StyledRow alignItems="center" flexWrap="wrap">
          <label htmlFor={`${key}-upload`}>
            <Button variant="contained" size="s" component="span">
              อัปโหลดรูปภาพ
            </Button>
            <StyledInputHidden
              data-testid="input-upload"
              id={`${key}-upload`}
              accept=".jpg, .jpeg, .png"
              type="file"
              onChange={(e) => dispatch(handleUpload(e, key, setValues))}
            />
          </label>
          {media != '' && (
            <Button
              data-testId="btn-delete"
              variant="outlined"
              size="s"
              onClick={() => {
                _.set(document.getElementById(`${key}-upload`), 'value', null)
                setValues((prev) => ({ ...prev, [key]: '' }))
              }}
            >
              ลบรูปภาพ
            </Button>
          )}
          <Typography variant="body2" color="text.secondary">
            ขนาดแนะนำ {isMobile ? '600 x 352' : '1440 x 500'} px ไฟล์ไม่เกิน
            2MB, นามสกุลไฟล์ที่รองรับ .jpg, .jpeg .png
          </Typography>
        </StyledRow>
      </StyledRow>
      <Typography sx={{ mt: 0.5 }} variant="body2" color="error">
        {_.get(error, `upload.${key}`, null) || _.get(errors, key, '')}
      </Typography>
    </StyledColumn>
  )
}

export default ImageUploader
