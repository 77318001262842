export const COLUMN_NAME_REPORT_CLASS = {
  NO: 'no',
  COURSE_CODE: 'courseCode',
  COURSE_NAME_EN: 'courseNameEN',
  COURSE_NAME_TH: 'courseNameTH',
  COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
  TRAININGPLATFORM: 'trainingPlatform',
  EXPECT_NUMBER_PEOPLE: 'expectNumberPeople',
  COUNT_STUDENT: 'countStudent',
  COUNT_ATTENDACE: 'countAttendance',
  COUNT_PASS: 'countPass',
  COUNT_NOT_PASS: 'countNotPass',
  TEACHING_TIME: 'teachingTime',
  HOUR: 'hours',
  MINUTE: 'minutes',
  STATUS_CLASS: 'statusClass',
  DATE_TRAINING: 'dateTraining',
  START_CLASS_DATETIME: 'startClassDateTime',
  END_CLASS_DATETIME: 'endClassDateTime',
  CLOSED_CLASS_DATETIME: 'closeClassDateTime',
  DIFF_DATETIME: 'diffClassDateTime',
  STATION: 'station',
  DISTRIBUTION: 'distribution',
  ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
  ROADMAP: 'roadMap',
  ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
  LEVEL_OF_LEARNER: 'levelOfLearner',
  OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
  DEPARTMENT: 'department',
  REQUIRED_DOCUMENT: 'requiredDocument',
  ACQUIRED_SKILL: 'acquiredSkill',
  TRAINER_STAFF_ID: 'trainerStaffID',
  TRAINER_NAME: 'trainerName',
  VERSION_NUMBER: 'courseVersion',
  REQUIRE_DOCUMENT: 'requireDocument',
}

export const COLUMN_NAME_TH = {
  NO: 'ลำดับ',
  COURSE_CODE: 'รหัสหลักสูตร',
  COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
  COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
  COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
  TRAININGPLATFORM: 'รูปแบบการสอน',
  EXPECT_NUMBER_PEOPLE: 'จำนวนที่คาดหวัง',
  COUNT_STUDENT: 'จำนวนสมัคร',
  COUNT_ATTENDACE: 'จำนวนผู้เข้าเรียน',
  COUNT_PASS: 'ผ่าน',
  COUNT_NOT_PASS: 'ไม่ผ่าน',
  TEACHING_TIME: 'เวลาจัดสอนทั้งหมด',
  HOUR: 'ชั่วโมง',
  MINUTE: 'นาที',
  STATUS_CLASS: 'สถานะคลาส',
  DATE_TRAINING: 'วันที่เริ่มอบรม',
  START_CLASS_DATETIME: 'วันเวลาเริ่มคลาส',
  END_CLASS_DATETIME: 'วันเวลาสิ้นสุดคลาส',
  CLOSED_CLASS_DATETIME: 'วันเวลาปิดคลาส',
  DIFF_DATETIME: 'วันเวลา Diff คลาส',
  STATION: 'Station',
  TRAINER_ID_1: 'Trainer ID 1',
  TRAINER_ID_2: 'Trainer ID 2',
  TRAINER_ID_3: 'Trainer ID 3',
  TRAINER_ID_4: 'Trainer ID 4',
  TRAINER_ID_5: 'Trainer ID 5',
  TRAINER_ID_6: 'Trainer ID 6',
  TRAINER_ID_7: 'Trainer ID 7',
  TRAINER_ID_8: 'Trainer ID 8',
  TRAINER_ID_9: 'Trainer ID 9',
  TRAINER_ID_10: 'Trainer ID 10',
  TRAINER_NAME_1: 'Trainer Name 1',
  TRAINER_NAME_2: 'Trainer Name 2',
  TRAINER_NAME_3: 'Trainer Name 3',
  TRAINER_NAME_4: 'Trainer Name 4',
  TRAINER_NAME_5: 'Trainer Name 5',
  TRAINER_NAME_6: 'Trainer Name 6',
  TRAINER_NAME_7: 'Trainer Name 7',
  TRAINER_NAME_8: 'Trainer Name 8',
  TRAINER_NAME_9: 'Trainer Name 9',
  TRAINER_NAME_10: 'Trainer Name 10',
  TRAINER_ROLE_1: 'Trainer Role 1',
  TRAINER_ROLE_2: 'Trainer Role 2',
  TRAINER_ROLE_3: 'Trainer Role 3',
  TRAINER_ROLE_4: 'Trainer Role 4',
  TRAINER_ROLE_5: 'Trainer Role 5',
  TRAINER_ROLE_6: 'Trainer Role 6',
  TRAINER_ROLE_7: 'Trainer Role 7',
  TRAINER_ROLE_8: 'Trainer Role 8',
  TRAINER_ROLE_9: 'Trainer Role 9',
  TRAINER_ROLE_10: 'Trainer Role 10',
  MAIN_ADMIN_ID: 'Admin หลัก ID',
  MAIN_ADMIN_NAME: 'Admin หลัก Name',
  SUB_ADMIN_ID_1: 'Admin รอง ID (1)',
  SUB_ADMIN_ID_2: 'Admin รอง ID (2)',
  SUB_ADMIN_ID_3: 'Admin รอง ID (3)',
  SUB_ADMIN_ID_4: 'Admin รอง ID (4)',
  SUB_ADMIN_ID_5: 'Admin รอง ID (5)',
  SUB_ADMIN_ID_6: 'Admin รอง ID (6)',
  SUB_ADMIN_ID_7: 'Admin รอง ID (7)',
  SUB_ADMIN_ID_8: 'Admin รอง ID (8)',
  SUB_ADMIN_ID_9: 'Admin รอง ID (9)',
  SUB_ADMIN_ID_10: 'Admin รอง ID (10)',
  SUB_ADMIN_NAME_1: 'Admin รอง Name (1)',
  SUB_ADMIN_NAME_2: 'Admin รอง Name (2)',
  SUB_ADMIN_NAME_3: 'Admin รอง Name (3)',
  SUB_ADMIN_NAME_4: 'Admin รอง Name (4)',
  SUB_ADMIN_NAME_5: 'Admin รอง Name (5)',
  SUB_ADMIN_NAME_6: 'Admin รอง Name (6)',
  SUB_ADMIN_NAME_7: 'Admin รอง Name (7)',
  SUB_ADMIN_NAME_8: 'Admin รอง Name (8)',
  SUB_ADMIN_NAME_9: 'Admin รอง Name (9)',
  SUB_ADMIN_NAME_10: 'Admin รอง Name (10)',
  DISTRIBUTION: 'ช่องทางการจำหน่าย',
  ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
  ROADMAP: 'กลุ่มหลักสูตร',
  ABBREVIATION_ROADMAP: 'ชื่อย่อกลุ่มหลักสูตร',
  LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
  OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนชองทางการจำหน่ายอื่น',
  DEPARTMENT: 'Department',
  REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
  ACQUIRED_SKILL: 'ระดับหลักสูตร',
  TRAINER_STAFF_ID: 'Trainer Staff ID',
  TRAINER_NAME: 'Trainer Name',
  VERSION_NUMBER: 'เลขเวอร์ชันของหลักสูตร',
}

export const TYPE_OF_VALUE = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT_NUMBER: 'INPUT_NUMBER',
  INPUT_TEXT: 'INPUT_TEXT',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
}

export const COURSE_FOR = {
  ALL: 'ALL',
  AGENCY: 'AGENCY',
  BA: 'BA',
  KTB: 'KTB',
  OTHER: 'OTHER',
}

export const SHOW_COURSE_TYPE = {
  SORTED_BY_UPDATED: 'SORTED_BY_UPDATED',
  COURSE_ENROLL: 'COURSE_ENROLL',
  COURSE_OPENED: 'COURSE_OPENED',
}

export const DISTRIBUTION = {
  AGENCY: 'Agency',
  BA: 'Banc',
  KTB: 'KTB',
}

export const ABBREVIATION_DISTRIBUTION = {
  AGENCY: 'AGC',
  BA: 'BAC',
  KTB: 'KTB',
}
