import React from 'react'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import DatePicker from '../../../../../../../components/Input/DatePicker'
import TimePicker from '../../../../../../../components/TimePicker/TimePicker'
import { StyledColumn } from '../../../../Dashboard/Styled'
import { isValidateDate } from '../../../../../../../utils/lib'
import useTheme from '@mui/system/useTheme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  closeDatePicker,
  openDatePicker,
  setExpiryDate,
  setExpiryTime,
  setLink,
} from '../../../../../../../redux/slices/manageClassDetail'

export const SetDateTimeExpirySection = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { expiryDate, expiryTime } = useSelector(
    (state) => state.manageClassDetail.qrEnrollProps,
    shallowEqual
  )

  const onChangeExpiryDate = (expDate) => {
    dispatch(setExpiryDate(dayjs(expDate).format('YYYY-MM-DD')))
    dispatch(setLink(null))
  }

  const onChangeExpiryTime = (expTime) => {
    dispatch(setExpiryTime(expTime))
    dispatch(setLink(null))
  }

  return (
    <>
      <DatePicker
        boxSx={{
          width: '50%',
          [theme.breakpoints.up('sm')]: {
            width: '40%',
          },
        }}
        id="expiryDate"
        name="expiryDate"
        labelText="วันปิดรับสมัคร"
        placeholder="เลือกวันที่"
        required
        onChange={(value) => {
          onChangeExpiryDate(value)
        }}
        value={isValidateDate(expiryDate) ? expiryDate : ''}
        disabledDate={[
          {
            startDate: '1900-01-01',
            finishDate: dayjs(new Date()).format(
              window.__env__.REACT_APP_DATE_DB
            ),
          },
        ]}
        handleOpen={() => dispatch(openDatePicker())}
        handleClose={() => dispatch(closeDatePicker())}
      />
      <StyledColumn
        sx={{
          width: '50%',
          [theme.breakpoints.up('sm')]: {
            width: '20%',
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{ display: 'flex' }}
          color={'text.secondary'}
        >
          เวลาปิด
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        </Typography>
        <TimePicker
          id="expiryTime"
          name="expiryTime"
          value={expiryTime}
          disabledStartTime={''}
          disabledEndTime={''}
          onChange={(e) => {
            onChangeExpiryTime(e.target.value)
          }}
        />
      </StyledColumn>
    </>
  )
}
