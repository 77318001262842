import React from 'react'
import _ from 'lodash'
import {
  putManageClassExamScore,
  putStandaloneGiveScore,
} from '../../../../../../services/manageClass/check'
import {
  closeDialog,
  openDialog,
  openSuccessDialog,
} from '../../../../../../redux/slices/dialog'
import {
  resetChangeState,
  setBody,
  setChangeState,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/manageClassCheckExam'
import EditRemarkDialog from '../EditRemarkDialog'

export const handleNumberInput = (e, max) => {
  const target = e.target
  const numVal = Math.abs(target.value)
  const isDot = e.nativeEvent.data === '.'
  const isZero = e.nativeEvent.data === '0'

  if (_.get(target.value, '0', '') === '0' && target.value.length === 2) {
    if (isZero) target.value = '0'
    else if (!isDot) target.value = numVal
  }
  
  target.value = numVal <= max ? target.value : max
  if (isDot && numVal === max) target.value = max

  const splitNum = target.value.split('.')
  const decimalNum = _.get(splitNum, '1', '')
  const isDecimal = splitNum.length === 2 && !_.isEmpty(decimalNum)
  if (isDecimal && (!isZero || (isZero && decimalNum.length > 2))) {
    target.value = _.floor(Number(target.value), 2).toString()
  }

  return target.value
}

export const handleInputChange = (e, index) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  let value = _.get(e.target, 'value', '')
  dispatch(setChangeState({ index, name, value }))
}

export const handleResetChange = (index, setEdit) => (dispatch) => {
  setEdit(false)
  dispatch(resetChangeState(index))
}

export const handleSubmit = (props) => async (dispatch) => {
  const { idProps, data, isEdit, isStandalone } = props
  const { uuid } = idProps
  const eTestingAnswerUuid = _.get(data, 'uuid', null)
  const questionUuid = _.get(data, 'questionUuid', '')
  const score = Number(_.get(data, 'answerScore', 0))
  const suggestion = _.get(data, 'freetextSuggestion', '') || ''
  const body = {
    questionUuid,
    eTestingAnswerUuid,
    score,
    suggestion,
    reasonToChange: '',
  }
  if (isStandalone) {
    const { eTestingVersionUuid } = idProps
    body.userUuid = uuid
    body.eTestingVersionUuid = eTestingVersionUuid
  } else {
    const { id, courseId } = idProps
    body.classLearnerUuid = uuid
    body.trainingPlanUuid = id
    body.courseTestAndEvaUuid = courseId
  }
  dispatch(setBody(body))

  if (isEdit) {
    dispatch(
      dispatch(
        openDialog({
          title: 'แก้ไขคะแนน',
          type: 'mini',
          disableButton: true,
          content: <EditRemarkDialog isStandalone={isStandalone} />,
        })
      )
    )
  } else {
    dispatch(startLoading())
    if (isStandalone) await dispatch(putStandaloneGiveScore(body))
    else await dispatch(putManageClassExamScore(body))
    dispatch(stopLoading())
  }
}

export const handleEditSuccess = (props) => (dispatch) => {
  const { isSuccess, editProps, learnerExam, history, writeStorage } = props
  const { isEdit, setEdit } = editProps
  if (isSuccess && isEdit) {
    setEdit(false)
    dispatch(
      openSuccessDialog({
        message: 'แก้ไขคะแนนเรียบร้อยแล้ว',
        handleConfirm: async () => {
          dispatch(closeDialog())
          writeStorage('presentLearner', learnerExam)
          history.go(0)
        },
      })
    )
  }
}
