import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import Select from '../../../../../components/Input/Select'

import { SelectBox } from '..'
import * as Styled from '../Styled'
import * as events from '../events'

const Index = ({ props }) => {
  const {
    listContent,
    listCourse,
    listCorseELarning,
    propsSubGroup,
    distributionChannelUuid,
    listType,
    listSubGroup,
    licenseList,
    defaultCourse,
  } = props
  const dispatch = useDispatch()
  const { errors, setErrors } = useFormikContext()
  const isDisabled = events.findDisabled(listContent)
  return (
    <>
      <Styled.CardContent>
        {listContent.map((itemContent, indexContent) => {
          const courseOrLicenseUuid = events.checkValueEmpty(
            itemContent,
            'courseOrLicenseUuid',
          )
          const defaultUuid = events.checkValueEmpty(itemContent, 'defaultUuid')

          const propsContent = {
            ...propsSubGroup,
            indexContent,
          }
          const valueType = _.get(itemContent, 'type', 'COURSE')

          let listOptions = []
          if (valueType === 'COURSE') {
            listOptions = listCourse
          } else if (valueType === 'LICENSE') {
            listOptions = events.filterDataActive(licenseList)
          } else {
            listOptions = listCorseELarning
          }

          console.log('listOptions', listOptions)
          let valueSelect = events.findValueSelected({
            list: listOptions,
            value: courseOrLicenseUuid,
          })

          let defaultValueCourse = events.findValueSelected({
            list: listOptions,
            value: defaultUuid,
          })

          const errorType = _.get(
            errors,
            events.mapKeyError({ ...propsContent, key: 'type' }),
            '',
          )

          const errorSelect = _.get(
            errors,
            events.mapKeyError({
              ...propsContent,
              key: 'courseOrLicenseUuid',
            }),
            '',
          )

          if (_.isNull(defaultValueCourse)) {
            const newCourse = events.addOptionCourse({
              defaultOption:
                valueType === 'COURSE' || valueType === 'ELEARNING'
                  ? defaultCourse
                  : licenseList,
              listOptions,
              defaultUuid,
              courseOrLicenseUuid,
              valueSelect,
            })

            listOptions = newCourse.listOptions
            valueSelect = newCourse.valueSelect
          }

          return (
            <>
              <Styled.BoxContent
                key={indexContent}
                sx={{
                  m: 2,
                  display: 'flex',
                  gap: 2,
                }}
              >
                <Select
                  disabled={!distributionChannelUuid}
                  dataTestid={`select-type-${indexContent}`}
                  boxSx={{
                    minWidth: 130,
                    maxWidth: 130,
                  }}
                  id="type"
                  name="type"
                  labelText="ประเภท"
                  type="basic"
                  options={listType}
                  handleChange={(e) =>
                    dispatch(
                      events.handleChangeContent({
                        ...propsContent,
                        key: 'type',
                        value: _.get(e, 'target.value', ''),
                      }),
                    )
                  }
                  placeholder="กรุณาเลือก"
                  value={valueType}
                  textError={errorType}
                />

                <SelectBox
                  type="course"
                  indexContent={indexContent}
                  disabled={!distributionChannelUuid || !valueType}
                  isHeader={false}
                  label="หลักสูตร"
                  listOptions={listOptions}
                  value={valueSelect}
                  onChangeSelect={(e) =>
                    dispatch(
                      events.handleChangeContent({
                        ...propsContent,
                        key: 'courseOrLicenseUuid',
                        value: _.get(e, 'target.id', ''),
                      }),
                    )
                  }
                  textError={errorSelect}
                />

                {/* button , icon */}
                <Styled.BoxButton isError={errorSelect || errorType}>
                  <Button
                    disabled={!distributionChannelUuid || isDisabled}
                    data-testid={`btn-or-${indexContent}`}
                    id={`btn-or-${indexContent}`}
                    name={`btn-or-${indexContent}`}
                    variant="outlined"
                    size="m"
                    onClick={() =>
                      dispatch(events.handleAddContent(propsContent))
                    }
                  >
                    <Typography variant="buttonl">หรือ</Typography>
                  </Button>
                  <IconButton
                    data-testid={`icon-delete-${indexContent}`}
                    disabled={
                      !distributionChannelUuid ||
                      (listContent.length == 1 && listSubGroup.length <= 1)
                    }
                    onClick={() =>
                      dispatch(
                        events.handleDeleteContent({
                          ...propsContent,
                          errors,
                          setErrors,
                        }),
                      )
                    }
                  >
                    <DeleteTwoTone />
                  </IconButton>
                </Styled.BoxButton>
              </Styled.BoxContent>
            </>
          )
        })}
      </Styled.CardContent>
    </>
  )
}

export default Index
