import Dot from '../../../../../components/Table/Dot'
import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import {
  RequisitionStatusText,
  RequisitionStatus,
} from '../../../../../constants/stock'
import { StyledStatusText } from './Styled'
import { statusValueEnum } from '../enum/statusEnum'

export function StatusComponent(formData, dotSx = {}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Dot
        sx={{ mr: 1.2, ...dotSx }}
        status={
          formData.status === statusValueEnum.NOT_RETURN
            ? 'deleted'
            : formData.status
        }
      />
      <StyledStatusText variant="body1" requisitionStatus={formData.status}>
        {RequisitionStatusText?.[formData.status] ?? '-'}
      </StyledStatusText>
      {formData.status === RequisitionStatus.COMPLETED_WITH_CONDITION && (
        <Typography sx={{ ml: 0.5 }} color={'error'}>
          *
        </Typography>
      )}
    </Box>
  )
}
