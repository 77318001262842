export const defaultFilter = {
  totalChecked: false,
  totalFrom: '',
  totalTo: '',
  availableChecked: false,
  availableFrom: '',
  availableTo: '',
  unitPriceChecked: false,
  unitPriceFrom: '',
  unitPriceTo: '',
  refundChecked: false,
  refundYear: '',
  refundMonth: '',
  refundDay: '',
  contractDateChecked: false,
  renewContractChecked: false,
  updatedDateChecked: false,
  holderChecked: false,
  holder: '',
  updatedByChecked: false,
  updatedBy: '',
  statusItemsChecked: false,
  statusItems: {
    normalChecked: false,
    lowChecked: false,
  },
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
