import * as yup from 'yup'

export const validationSchema = yup.object({
    examinerType: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),
    code: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),
    idCardNo: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),
    prefix: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//คำนำหน้า
    firstName: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//ชื่อ
    lastName:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//นามสกุล
    gender:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//เพศ
    dateOfBirth:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//วันเดือนปีเกิด
    qualification:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),//วุฒิการศึกษา
    mobilePhone:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//เบอร์โทรศัพท์
    email:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//อีเมล
    zipCode:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),//รหัสไปรษณีย
    province:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),//จังหวัด
    amphure:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),//อำเภอ/เขต
    tambon:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้'),
    address: yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .required('กรุณาเลือกข้อมูลในช่องนี้')
        .max(255, 'กรุณากรอกข้อมูลได้ไม่เกิน 255 ตัวอักษร'),
    unitName:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//ชื่อหน่วย
    unitNumber:  yup
        .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
        .nullable(),//รหัสหน่วยงาน
})