import callAxios from '../../../../../utils/baseService'
import _ from 'lodash'
import { filterStaffProfile } from '../../../../../utils/apiPath'

export const fetchStaffProfileList = async () => {
  const body = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    agentCode: '',
    firstNameTH: '',
    lastNameTH: '',
    jobTitle: '',
    department: '',
    station: '',
    reportTo: '',
    position: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  let result = []
  await callAxios
    .post(filterStaffProfile, body)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const firstName = _.get(item, 'firstNameTH', '')
        const lastName = _.get(item, 'lastNameTH', '')
        const fullName = firstName + ' ' + lastName
        const email = _.get(item, 'email', '')
        const tel = _.get(item, 'tel', '')
        const prefixTH = _.get(item, 'prefixTH', '')

        result.push({
          value: uuid,
          label: fullName,
          firstName: firstName,
          lastName: lastName,
          email: email,
          tel: tel,
          prefixTH: prefixTH,
        })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}
