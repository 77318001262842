import { store } from '../../../App'
import { setCheckUploadStatus } from '../../../redux/slices/uploadFile'
import { getCheckUploadStatus } from '../../../services/eLearning/module/form'

export const handleCheckUploadStatus = async ({ uuid, fieldName }) => {
  try {
    const result = await store.dispatch(getCheckUploadStatus(uuid))

    store.dispatch(setCheckUploadStatus({ ...result.payload, fieldName }))
    return result.payload
  } catch (err) {
    throw new Error(err)
  }
}
