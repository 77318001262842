import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import Table from '../../../components/redux/Table'

import Header from './../HeaderList'
import { LoadingPage } from './../Styled'
import FilterDrawer from './../FilterDrawerList'
import { fetchDataList } from './events'

const CheckExamVersion = () => {
  const { id: codeId } = useParams()
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)

  const { table, search, filter } = useSelector(
    (state) => state.table,
    shallowEqual
  )

  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page: page,
        filterProp,
        keyword: searchText,
        setInitial,
        codeId,
      })
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page: page,
          filterProp,
          keyword: searchText,
          codeId,
        })
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <Box sx={{ mx: 3 }}>
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Header isVersion />

          <Card sx={{ mt: 3 }}>
            <Table />
          </Card>

          <FilterDrawer isVersion open={isFilterDrawer} table={table} />
        </Box>
        <LoadingPage isLoading={isLoading} />
      </Box>
    </>
  )
}

export default CheckExamVersion
