import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { DrawerHistory, HeadLabelHistory, ContentHistory } from './Styled'
import { setPointDrawerOpen } from '../../../../../../redux/slices/manage/agentProfile'
import HistoryTable from './History'

const PointDrawer = () => {
  const dispatch = useDispatch()
  const { isOpen, table } = useSelector(
    (state) => ({
      isOpen: state.agentProfile.pointDrawerOpen,
      table: state.table,
    }),
    shallowEqual,
  )
  return (
    <DrawerHistory
      anchor={'right'}
      open={isOpen}
      onClose={() => dispatch(setPointDrawerOpen(false))}
    >
      <ContentHistory isLoading={table.table.isLoading}>
        <HeadLabelHistory>
          <Box>
            <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
            <IconButton
              data-testid="btn-close-history"
              color="primary"
              component="span"
              onClick={() => dispatch(setPointDrawerOpen(false))}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </HeadLabelHistory>
        <HistoryTable />
      </ContentHistory>
    </DrawerHistory>
  )
}

export default PointDrawer
