import React from 'react'
import { checkTypeFile } from '../handler/checkFileType'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import {
  StyledDragHolder,
  StyledIconBox,
  StyledImg,
  StyledUploadItem,
} from '../stlyed'
import { UploadLoadingCircular } from './UploadLoadingCircular'
import { IconUploadSuccessStatus } from './IconUploadSuccessStatus'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import Refresh from '@mui/icons-material/Refresh'
import Close from '@mui/icons-material/Close'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'
import { addEclipseToFilename } from '../../../services/util'
import { handleDeleteFile } from '../handler/handleDeleteFile'
import { handleChange } from '../handler/handleChange'
import { handleConvertAgain } from '../handler/handleConvertAgain'
import PreviewImage from './PreviewImage'

export const UploadItem = ({
  isForImage,
  isShowDownloadable,
  provided,
  index,
  onChange,
  fieldName,
  disabled,
  popupLabel,
  disabledRemove,
  uploadType,
}) => {
  const file = useSelector(
    (state) => state.uploadFile.formData[fieldName].files[index],
    shallowEqual,
  )
  const error = _.get(file, 'error', '')
  const downloadable = _.get(file, 'downloadable', false)
  const percent = _.get(file, 'percent', 0)
  const status = _.get(file, 'status', '')
  const isFail = status === 'FAIL'
  const isError = !_.isEmpty(error) || isFail
  const isUploadSuccess = !isError && percent === 100
  const isTypeValid = checkTypeFile(file.fileType)

  if (_.isEmpty(file.fileType)) return <></>

  return (
    <StyledUploadItem
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      isError={isError}
    >
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        {(isUploadSuccess || file.uuid) && (
          <StyledDragHolder {...provided.dragHandleProps}>
            <DragIndicatorTwoTone />
          </StyledDragHolder>
        )}

        <>
          {isError ? (
            <StyledImg src="/icon/circle_error.svg" />
          ) : percent < 100 ? (
            <UploadLoadingCircular percent={percent} />
          ) : (
            <IconUploadSuccessStatus isForImage={isForImage} status={status} />
          )}
        </>

        {isForImage ? (
          <PreviewImage file={file} popupLabel={popupLabel} />
        ) : (
          <StyledIconBox sx={{ ml: 2 }}>
            <img
              src={`/icon/${
                isTypeValid ? _.toLower(file.fileType) : 'file'
              }.svg`}
              alt=""
            />
          </StyledIconBox>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1b" color="primary">
            {addEclipseToFilename(file.displayName || file['oldFileName'])}
          </Typography>
          {isError ? (
            <Typography variant="body3" color="error">
              อัปโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง
            </Typography>
          ) : (
            <Typography variant="body3" color="text.lightGray">
              {file.fileSize} MB
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        {isFail && (
          <Button
            sx={{ minWidth: 114 }}
            variant="outlined"
            size="s"
            startIcon={<Refresh />}
            onClick={() =>
              handleConvertAgain(file, index, fieldName, uploadType)
            }
          >
            ลองอีกครั้ง
          </Button>
        )}

        {isShowDownloadable && (
          <Switch
            disabled={disabled}
            sx={{ display: isError ? 'none' : 'inline-flex' }}
            checked={downloadable}
            onChange={(e) =>
              handleChange(index, e.target.checked, onChange, fieldName)
            }
          />
        )}

        <IconButton
          disabled={!_.isUndefined(disabledRemove) ? disabledRemove : disabled}
          onClick={() => handleDeleteFile(index, onChange, fieldName)}
        >
          <Close />
        </IconButton>
      </Box>
    </StyledUploadItem>
  )
}
