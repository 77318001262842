import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { crudConfig } from '../../crudConfig'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'

export const fetchCategory = async () => {
  const body = {
    name: '',
    order: 'ASC',
    sort: 'name',
    status: ['ACTIVE'],
    limit: -1,
    page: 0,
  }
  try {
    const response = await callAxios.post(crudConfig.filterPath, body, {
      'x-type': 'EXPENSE_CATEGORY',
    })
    store.dispatch(
      setFieldValue({
        key: 'masterData.category',
        value: {
          isLoading: false,
          options: response.data.result.map((item) => ({
            value: item.uuid,
            label: item.name,
          })),
        },
      }),
    )
  } catch (e) {
    handleFetchError(e)
  }
}
