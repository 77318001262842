import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(3),
}))

export const BoxInput = styled(Box)(({ length }) => ({
  display: 'flex',
  width: length <= 1 ? '95%' : '100%',
}))

export const LabelQuestion = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginLeft: theme.spacing(4),
    position: 'relative',
    top: theme.spacing(1),
  },
}))

export const BoxDragIndicator = styled(Box)(({ theme }) => ({
  position: 'relative',
  left: '-2px',
  top: theme.spacing(2),
  color: theme?.palette?.action?.active,
}))
