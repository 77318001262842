import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'

const Index = ({
  boxSx,
  labelText,
  required,
  inputText,
  id,
  name,
  onChange,
  onBlur,
  onClick,
  value,
  defaultChecked,
  disabled,
}) => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body1b" color="text.primary">
          {labelText}
        </Typography>
        {required && (
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
            *
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {inputText && (
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ lineHeight: 2 }}
          >
            <>{inputText}</>
          </Typography>
        )}
        <Switch
          id={id}
          name={name}
          defaultChecked={defaultChecked}
          checked={value}
          onClick={onClick}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          inputProps={{
            'data-testid': 'status',
          }}
        />
      </Box>
    </Box>
  )
}
export default Index
