import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import PreviewImg from '../Preview/PreviewImg'

export const getHasInvalidFiles = (files) => {
  var hasInvalidFiles = true
  for (var i = 0; i < files.length; i++) {
    var file = files[i]
    const type = ['jpg', 'png', 'jpeg']
    const getNameImage = _.get(file, 'name', '')
    const splitName = getNameImage.split('.')
    const checkType = type.includes(
      splitName[splitName.length - 1].toLowerCase()
    )
    if (!checkType) {
      hasInvalidFiles = false
    } else {
      hasInvalidFiles = true
    }
  }
  return hasInvalidFiles
}

const BasicUpload = (props) => {
  const {
    handleUploadFile,
    value,
    textError,
    isShowDescription,
    isShowTextError = true,
    isPreview,
    style = {},
    isStopPropagation = false,
    titlePreviewImg,
    onDelete,
    disabled,
  } = props
  const [src, setSrc] = useState(value)
  useEffect(() => {
    if (value && value !== '') {
      setSrc(`${window.__env__.REACT_APP_API_URL}/file/${value}`)
    }
  }, [value])

  const onUpload = (e) => handleUploadFile(e)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isShowDescription === false ? 'end' : 'unset',
      }}
    >
      {src === '' && (
        <label htmlFor="icon-button-file">
          <Button sx={{ width: '100%' }} variant="upload" component="span">
            {!disabled && (
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={onUpload}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <UploadFileIcon
                sx={{ color: 'text.silver', cursor: 'pointer' }}
              />
              <Typography color="text.silver" sx={{ cursor: 'pointer' }}>
                อัปโหลด
              </Typography>
            </Box>
          </Button>
        </label>
      )}
      {src !== '' && (
        <>
          <PreviewImg
            title={titlePreviewImg}
            srcImg={src}
            style={style}
            setSrc={setSrc}
            isPreview={isPreview}
            isStopPropagation={isStopPropagation}
            onDelete={onDelete}
          />
        </>
      )}
      {isShowDescription !== false && (
        <Typography color="body2">ขนาดแนะนำ 900x600px</Typography>
      )}

      {textError ? (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      ) : (
        <Box sx={{ height: isShowTextError ? '25px' : 'unset' }}>
          {textError}
        </Box>
      )}
    </Box>
  )
}

const Input = styled('input')({
  display: 'none',
})

export default BasicUpload
