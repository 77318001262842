import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import { eLearningLearningPathCourses } from '../../../../../utils/apiPath'
import _ from 'lodash'

export const fetchCourse = async () => {
  store.dispatch(
    setFieldValue({
      key: 'masterData.course',
      value: {
        isLoading: true,
        options: [],
        allOptions: [],
      },
    }),
  )

  try {
    const response = await callAxios.get(eLearningLearningPathCourses)
    const options = _.sortBy(
      response.data.map((item) => ({
        ...item,
        value: item.uuid,
        label: _.isEmpty(item.name) ? item.englishName : item.name,
      })),
      'type',
    )

    store.dispatch(
      setFieldValue({
        key: 'masterData.course',
        value: {
          isLoading: false,
          options: options,
          allOptions: options,
        },
      }),
    )
  } catch (e) {
    handleFetchError(e)
  }
}
