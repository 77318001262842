import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Box from '@mui/material/Box'

const PieChart = ({ dataSet, labels, height = '100%', idx = 1 }) => {
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }
    const ctx = document.getElementById(`pieChart-${idx}`)
    const newChart = new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [
          {
            borderWidth: 0,
            ...dataSet,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 50,
          },
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                weight: 'bold',
              },
            },
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            color: '#ffffff',
            anchor: 'center',
            align: 'center',
            formatter: (value) => {
              if (value === 0) return ''
              return `(${Math.floor(value)}%)`
            },
            font: {
              weight: 'bold',
              size: 16,
            },
          },
        },
      },
    })
    setChart(newChart)
  }, [dataSet, labels])

  return (
    <Box sx={{ height: height }}>
      <canvas id={`pieChart-${idx}`} />
    </Box>
  )
}

export default PieChart
