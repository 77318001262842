import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useReactToPrint } from 'react-to-print'

const DropdownButton = ({ componentRef, roomName }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handlePrint = useReactToPrint({
    documentTitle: roomName,
    content: () => componentRef.current,
  })

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions.map((option, index) => {
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => handlePrint()}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [
  { text: 'ดาวน์โหลดข้อมูลห้องนี้', value: 'download' },
]
