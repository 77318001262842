import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { shouldForwardProp } from '../../../Styled'

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const BoxHeader = styled(
  Box,
  shouldForwardProp,
)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const BoxLabelTitle = styled(
  Box,
  shouldForwardProp,
)(({ theme, isEdit, isDraft }) => {
  let editWidth = 'calc(100% - 350px)'
  if (isDraft) editWidth = 'calc(100% - 395px)'
  return {
    display: 'flex',
    flexDirection: 'column',
    width: isEdit ? editWidth : 'auto',
    [theme.breakpoints.down('md')]: { width: '100%' },
  }
})

export const StyledViewTitle = styled(Typography)(() => ({
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  // position: 'sticky',
  // top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(3),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(0)}`,
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledInputImport = styled('input')({ display: 'none' })