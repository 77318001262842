import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { FindIcon } from './event'
import { BoxSelect, StyleSelect } from './Styled'
import ExpandMore from '@mui/icons-material/ExpandMore'

const Index = (props) => {
  const {
    boxSx,
    menuSx,
    dataTestId,
    value,
    labelText,
    listOption,
    handleChange,
    disabled,
    placeholder,
  } = props
  return (
    <BoxSelect sx={{ ...boxSx }}>
      <Typography variant="body2">{labelText}</Typography>

      <StyleSelect
        data-testid={dataTestId || 'select-icon'}
        value={value}
        displayEmpty
        disabled={disabled}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ width: '100%', '& .MuiSelect-select': { pl: 1 } }}
        onChange={handleChange}
        IconComponent={(propsCom) => <ExpandMore {...propsCom} />}
      >
        {placeholder && (
          <MenuItem sx={{ display: 'none' }} disabled value="">
            <Typography variant="body1" color="text.silver">
              {placeholder}
            </Typography>
          </MenuItem>
        )}
        {listOption.map((item) => (
          <MenuItem sx={{ ...menuSx }} value={item.value} key={item.value}>
            <FindIcon item={item} />
          </MenuItem>
        ))}
      </StyleSelect>
    </BoxSelect>
  )
}
export default Index
