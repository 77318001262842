import _ from 'lodash'
import { MODULE_TYPE } from '../../../../../constants/eLearning'
import { setBody } from '../../../../../redux/slices/eLearning/moduleForm'
import { getModuleFormState } from '../events'

export const handleSelectType = (e) => (dispatch) => {
  const body = getModuleFormState()
  const moduleType = e.target.id
  const newValues = {
    moduleCode: body.moduleCode,
    name: body.name,
    description: body.description,
    status: body.status,
    moduleType,
    [_.camelCase(moduleType)]: moduleType === MODULE_TYPE.UPLOAD_FILE ? [] : {},
  }
  if (body.uuid) newValues.uuid = body.uuid
  dispatch(setBody(newValues))
}
