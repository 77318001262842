import React from 'react'
import SwapVertOutlined from '@mui/icons-material/SwapVertOutlined'

import SwapQuestion from './SwapQuestion'

import * as reducerETest from '../../../../../redux/slices/eTestingForm'
import * as reducerDialog from '../../../../../redux/slices/dialog'

export const handleChangeScore = (props) => (dispatch) => {
  let newScore = props.value
  const regex = /^[0-9]*(\.[0-9]{0,2})?$/
  const checkRegex = regex.test(newScore)

  if (newScore > 100) newScore = 100
  if (!checkRegex) newScore = newScore.substring(0, newScore.length - 1)
  if (newScore === '') newScore = 0
  if (newScore.length >= 2 && newScore !== '0.') {
    const firstCheck = newScore.substring(0, newScore.length - 1)
    if (firstCheck === '0') {
      const lastCheck = newScore.substring(1, newScore.length)
      newScore = lastCheck
    }
  }

  dispatch(
    reducerETest.setChangeQuestionByKey({
      ...props,
      value: newScore,
    })
  )
}

export const handleDeleteQuestion = (props) => (dispatch) => {
  dispatch(reducerETest.startLoading())
  setTimeout(() => {
    dispatch(reducerETest.setDeleteQuestion(props))
    dispatch(reducerETest.stopLoading())
  }, 700)
}

export const handleSwapQuestion =
  (keyQuestion, indexQuestion) => (dispatch) => {
    dispatch(
      reducerETest.setChange({
        key: 'swapQuestion.keyQuestion',
        value: keyQuestion,
      })
    )
    dispatch(
      reducerETest.setChange({
        key: 'swapQuestion.indexSelect',
        value: indexQuestion === 0 ? 1 : 0,
      })
    )

    dispatch(
      reducerDialog.openDialog({
        type: 'content',
        title: 'ย้ายคำถามไป',
        icon: <SwapVertOutlined color="primary" />,
        content: <SwapQuestion keyQuestion={keyQuestion} />,
        handleConfirm: () => dispatch(handleSubmitSwap()),
      })
    )
  }

export const handleSubmitSwap = () => (dispatch) => {
  dispatch(reducerETest.startLoading())
  dispatch(reducerDialog.loadingDialog())

  setTimeout(() => {
    dispatch(reducerETest.setSwapOption())
    dispatch(reducerETest.resetErrorSchemaMessage())
    dispatch(reducerETest.stopLoading())
    dispatch(reducerDialog.closeDialog())
  }, 700)
}
