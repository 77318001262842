import React from 'react'
import Typography from '@mui/material/Typography'
import {
  StyledFlexRow,
  StyledLessonCard,
  StyledLessonHeader,
  StyledMiniIconBtn,
} from './Styled'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import { shallowEqual, useSelector } from 'react-redux'
import { contentTypeEnum } from '../../../enum/contentTypEnum'
import { handleDeleteContentItem } from '../../../handler/handleDeleteContentItem'
import { DragHolder } from './dragHolder'
import { RenderContent } from '../../../handler/getContent'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../../../../redux/slices/crud'

const DragDropContent = ({ provided, index }) => {
  const dispatch = useDispatch()
  const { content } = useSelector(
    (state) => ({
      content: state.crud.formData.contents[index],
    }),
    shallowEqual,
  )
  const expand = _.get(content, 'isExpand', true)

  return (
    <StyledLessonCard {...provided.draggableProps} ref={provided.innerRef}>
      <StyledLessonHeader>
        <StyledFlexRow sx={{ alignItems: 'center' }}>
          <DragHolder provided={provided} />
          <Typography variant="body1b">
            {contentTypeEnum[content.type].name}
          </Typography>
          <Typography variant="body1b" color="error.main">
            *
          </Typography>
        </StyledFlexRow>
        <StyledFlexRow sx={{ alignItems: 'end' }}>
          <StyledMiniIconBtn onClick={() => handleDeleteContentItem(index)}>
            <DeleteTwoToneIcon />
          </StyledMiniIconBtn>
          <StyledMiniIconBtn
            onClick={() =>
              dispatch(
                setFieldValue({
                  key: `formData.contents[${index}].isExpand`,
                  value: !expand,
                }),
              )
            }
          >
            {expand ? <KeyboardArrowUpTwoTone /> : <KeyboardArrowDownTwoTone />}
          </StyledMiniIconBtn>
        </StyledFlexRow>
      </StyledLessonHeader>
      {expand && <RenderContent content={content} index={index} />}
    </StyledLessonCard>
  )
}

export default DragDropContent
