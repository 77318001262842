import _ from 'lodash'
// import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { tabTypeEnum } from '../enum/requisitionTypeEnum'

export const getBodyMutation = (data) => {
  const monthlyPlan = _.get(data, 'monthlyPlan', '')
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    requisitionType: monthlyPlan ? tabTypeEnum.MONTHLY : tabTypeEnum.OTHER,
    trainingDate: _.get(data, 'trainingDate', ''),
    station: _.get(data, 'station', ''),
    responsibleUser: _.get(data, 'responsibleUser', ''),
    warehouse: _.get(data, 'to', ''),
    annotation: _.get(data, 'remark', ''),
    status: _.get(data, 'status', ''),
    assets: _.get(data, 'assets', [
      {
        label: '',
        value: '',
        assetCode: '',
        assetCodeOption: [],
        qty: '',
        availableQty: '',
        totalQty: '',
        status: '',
      },
    ]),
    createdBy: _.get(data, 'createdBy', {}),
    updatedBy: _.get(data, 'updatedBy', {}),
    createdAt: _.get(data, 'createdAt', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
    trainingPlan: _.get(data, 'trainingPlan', ''),
    monthlyPlan: monthlyPlan,
    responsibility: _.get(data, 'responsibility', ''),
    createdByInfo: _.get(data, 'createdBy', {}),
    sentMailAt: _.get(data, 'sentMailAt', ''),
    rejectReason: _.get(data, 'rejectReason', ''),
  }
}
