import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import TruncateMarkup from 'react-truncate-markup'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Settings from '@mui/icons-material/Settings'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbDetail } from '../../model'
import { checkScrollWidth, handleCloseDrawer, handleCopyLink } from './events'
import {
  StyledBoxButton,
  StyledBoxHeader,
  StyledBoxLabelTitle,
  StyledTooltip,
} from './Styled'
import CloseClass from './CloseClass'
import {
  hasPermissionDefaultAndMonthlyPlanArea,
  isMediaWidthMoreThan,
} from '../../../../utils/lib'
import { manageClassCheckClose } from '../../../../../src/services/manageClass/detail'
import QREnrollDrawer from './QREnrollDrawer'
import { EnrollClassButton } from './EnrollClassButton'
import { setIsMobile } from '../../../../redux/slices/manageClassDetail'

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const url = `/manage-class/setting/${uuid}`
  const [isScrolled, setScroll] = useState(false)
  const [user] = useLocalStorage('user')
  const [showCloseButton, setShowCloseButton] = useState(false)
  const [isOpenQREnrollDrawer, setOpenQREnrollDrawer] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')

  const {
    isCancel,
    manageClassData,
    manageClassClose,
    qrEnrollProps: { statusQRCode },
  } = useSelector(
    (state) => ({
      isCancel: state.manageClassDetail.isCancel,
      manageClassData: state.manageClassDetail.data,
      manageClassClose: state.manageClassDetail.checkClose,
      qrEnrollProps: state.manageClassDetail.qrEnrollProps,
    }),
    shallowEqual
  )
  const nameForLearner = _.get(
    manageClassData,
    'monthlyPlan.trainingInfo.[0].course.nameForLearner',
    ''
  )

  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: manageClassData,
  })

  const body = {
    manageClassUuid: `${uuid}`,
    remarkClosed: '',
    checkNoCondition: false,
    checkPassCourse: false,
    checkAttendance: [],
  }

  useEffect(async () => {
    await dispatch(manageClassCheckClose(body, setShowCloseButton))
    setShowCloseButton(true)
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  const status = _.get(manageClassData, 'status', '')

  useEffect(() => {
    dispatch(setIsMobile(!isNotSm))
  }, [isNotSm])

  return (
    <StyledBoxHeader scrolled={isScrolled}>
      <StyledBoxLabelTitle>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TruncateMarkup lines={1}>
            <Typography variant="h4">
              คลาส: {_.get(manageClassData, 'course', '')}
            </Typography>
          </TruncateMarkup>
          <Tooltip
            arrow
            title="Copy link to learner"
            placement="right"
            componentsProps={StyledTooltip}
          >
            <IconButton onClick={() => handleCopyLink(manageClassData, uuid)}>
              <LinkIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
        {!_.isEmpty(nameForLearner) && (
          <TruncateMarkup lines={1}>
            <Typography
              variant="body1b"
              sx={{ lineHeight: '20px', mb: '-6px', mt: '-10px' }}
            >
              ชื่อหลักสูตร (สำหรับ Learner): {nameForLearner}
            </Typography>
          </TruncateMarkup>
        )}
        {!isScrolled && <Breadcrumbs menuList={breadcrumbDetail} />}
      </StyledBoxLabelTitle>
      <StyledBoxButton>
        <EnrollClassButton
          uuid={uuid}
          handleOpenQrDrawer={setOpenQREnrollDrawer}
          statusQRCode={statusQRCode}
          isClosed={
            status === 'UNASSIGN' ||
            status === 'TODO' ||
            status === 'CLOSED' ||
            status === 'CANCELED'
          }
        />
        {(status === 'ENDCLASS' || status === 'STARTCLASS') &&
        showCloseButton ? (
          <CloseClass
            status={status}
            uuid={uuid}
            manageClassClose={manageClassClose}
            hasPermissionEdit={hasPermissionEdit}
          />
        ) : (
          ''
        )}
        <Button
          data-testid="btn-setting"
          variant="outlined"
          disabled={!hasPermissionEdit || isCancel}
          onClick={() => history.push(url)}
        >
          <Settings />
        </Button>
      </StyledBoxButton>
      <QREnrollDrawer
        isOpen={isOpenQREnrollDrawer}
        onClose={() => dispatch(handleCloseDrawer(setOpenQREnrollDrawer))}
      />
    </StyledBoxHeader>
  )
}
export default Header
