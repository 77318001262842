export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    waiting: theme?.palette?.text?.silver,
    draft: theme?.palette?.text?.silver,
    cancel: theme?.palette?.text?.silver,
    deleted: theme?.palette?.text?.white,
    inprogress: theme?.palette?.other?.ratingActive,
    error: theme?.palette?.error?.main,
    ready: theme?.palette?.success?.main,
    readyinactive: theme?.palette?.text?.silver,
  }
  return colorList[statusText]
}
