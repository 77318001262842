import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Content from '../components/Content'
import MediaBox from '../components/MediaBox'
import LearningPathTitle from '../components/LearningPathTitle'
import CourseTitle from '../components/CourseTitle'
import ChipContainer from '../components/ChipContainer'
import { CourseAssignee } from '../components/CourseAssignee'
import { StyledContentBox, StyledTitleBox } from '../Styled'
import CollectionTitle from '../components/CollectionTitle'

const TabletDesktopContent = ({ course, isHover, isPath, isCollection }) => {
  const isInProgress = _.get(course, 'isInProgress', false)
  return (
    <Box sx={{ height: 507, position: 'relative' }}>
      <MediaBox
        course={course}
        isHover={isHover}
        isNotELearning={isPath || isCollection}
      />
      <StyledContentBox id="content">
        <StyledTitleBox id="title" isInProgress={isInProgress}>
          {isPath ? (
            <LearningPathTitle course={course} />
          ) : isCollection ? (
            <CollectionTitle course={course} />
          ) : (
            <>
              <CourseTitle course={course} />
              <CourseAssignee course={course} />
            </>
          )}

          <ChipContainer course={course} />

          <Content
            course={course}
            isPath={isPath}
            isCollection={isCollection}
          />
        </StyledTitleBox>
      </StyledContentBox>
    </Box>
  )
}

export default TabletDesktopContent
