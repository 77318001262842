import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { deleteFromStorage } from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import HourglassTopTwoTone from '@mui/icons-material/HourglassTopTwoTone'
import SearchCustom from '../../../../../components/Search/Search'
import { StyledCardLearner, StyledColumn, StyledListContainer } from '../Styled'
import { getLearnerList } from '../events'
import { handleSelectLearner } from './events'

const LearnerList = ({ listHeight, isStandalone }) => {
  const [search, setSearch] = useState('')
  const { learners, tab, learnerExam } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const codeId = _.get(learnerExam, 'codeId', '')
  const learnerList = useMemo(
    () => getLearnerList(learners, tab, search),
    [learners, tab, search]
  )

  return (
    <StyledColumn sx={{ width: 200 }} gap={3.5}>
      <SearchCustom
        sx={{ right: 10 }}
        boxSx={{ minWidth: 200, mt: 1 }}
        placeholder="ค้นหา"
        handleSearch={null}
        setSearchText={setSearch}
        setText={setSearch}
        text={search}
      />
      <StyledListContainer listHeight={listHeight}>
        {learnerList.length == 0 ? (
          <Typography textAlign="center" color="text.lightGray">
            ไม่พบรายชื่อ
          </Typography>
        ) : (
          learnerList.map((item, index) => (
            <CardLearner
              key={index}
              selected={codeId == item.codeId}
              index={index}
              data={item}
              isStandalone={isStandalone}
            />
          ))
        )}
      </StyledListContainer>
    </StyledColumn>
  )
}

export default LearnerList

export const CardLearner = ({ data, index, selected, isStandalone }) => {
  const idProps = useParams()
  const learnerUuid = isStandalone
    ? _.get(data, 'userUuid', '')
    : _.get(data, 'learnerUuid', '')
  const props = { ...idProps, learnerUuid, isStandalone }
  const status = _.get(data, 'status', null)
  const imgPath = _.get(data, 'image', '-')
  const dispatch = useDispatch()

  return (
    <StyledCardLearner
      data-testid={`btn-learner-${index}`}
      selected={selected}
      onClick={() => {
        dispatch(handleSelectLearner({ props, data, index }))
        deleteFromStorage('presentLearner')
      }}
    >
      <img src={`${window.__env__.REACT_APP_API_URL}/file/${imgPath}`} />
      <Typography>
        {_.get(data, 'firstNameTH', '-')} {_.get(data, 'lastNameTH', '-') || ''}
      </Typography>
      {status == 'WAITING' && <HourglassTopTwoTone color="secondary" />}
      {status == 'CHECKED' && <CheckCircleOutline color="primary" />}
    </StyledCardLearner>
  )
}
