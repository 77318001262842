import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import Close from '@mui/icons-material/Close'
import NormalTable from '../../../../../../components/Table/NormalTable/NormalTable'

import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { fetchExamHistory, headCells } from './events'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { LoadingPage } from '../../../../../AgentProfile/View/Content/Exam/ExamDrawer/Styled'

const ExamDrawer = ({ isOpen, onCloseDrawer }) => {
  const dispatch = useDispatch()
  const { isLoading, isLoading2, isLoading3 } = useSelector(
    (state) => ({
      isLoading: state.agentProfile.isLoading,
      isLoading2: state.agentProfile.isLoading2,
      isLoading3: state.agentProfile.isLoading3,
      search: state.agentProfile.search,
      pagination: state.agentProfile.pagination,
    }),
    shallowEqual,
  )
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  })
  const { id: uuid } = useParams()

  useEffect(() => {
    if (isOpen) dispatch(fetchExamHistory({ uuid, setData }))
  }, [isOpen])

  const isLoadingAll = isLoading || isLoading2 || isLoading3
  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Box
        sx={{
          opacity: isLoadingAll ? 0.4 : 'unset',
          pointerEvents: isLoadingAll ? 'none' : 'unset',
        }}
      >
        <StyledContent>
          <StyledHeadLabel>
            <Box id="header">
              <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ m: 3, gap: 4 }}>
            <NormalTable
              headCells={headCells}
              listRows={data.result}
              totalRows={data.totalCount}
              showPagination
              handlePagination={(events) =>
                dispatch(fetchExamHistory({ uuid, setData, events }))
              }
            />
          </Box>
        </StyledContent>
      </Box>
      <LoadingPage isLoading={isLoadingAll} />
    </StyledDrawer>
  )
}

export default ExamDrawer
