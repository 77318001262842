import { setFieldValue } from '../../../../redux/slices/crud'
import { setChange } from '../../../../redux/slices/eContent/content'
import { pinContent } from '../../../../services/eContent'

export const setPin = (uuid) => async (dispatch) => {
  dispatch(setChange({ key: 'isPinLoading', value: true }))
  const result = await dispatch(pinContent({ uuid }))
  if (result.meta.requestStatus == 'fulfilled') {
    const { data } = result.payload
    dispatch(
      setFieldValue({ key: 'formData.totalPinned', value: data.totalPinned }),
    )
    dispatch(setFieldValue({ key: 'formData.isPinned', value: data.pin }))
  }
  dispatch(setChange({ key: 'isPinLoading', value: false }))
}
