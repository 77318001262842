import _ from 'lodash'

export const handleOptionObj = (options, type) => {
  return options.map((item) => ({ ...handleStaffLabelValue(item), type }))
}

export const handleStaffLabelValue = (item) => {
  const label = `${_.get(item, 'area[0].station.station')} ${_.get(
    item,
    'firstNameTH',
    '',
  )} ${_.get(item, 'lastNameTH', '')}`
  return { value: _.get(item, 'uuid', ''), label }
}
