import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
}))

export const StyledSubBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  minWidth: '300px',
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  })
)
