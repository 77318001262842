import dayjs from 'dayjs'
import { store } from '../../../../../../../App'
import _ from 'lodash'

export const mutateFilterPropToBody = (versionUuid) => {
  const { filterProp } = store.getState().table.filter

  return {
    eTestVersionUuid: versionUuid,
    courseCode: _.get(filterProp, 'courseCode', ''),
    classUuid: _.get(filterProp, 'classUuid', ''),
    userProfileUuid: _.get(filterProp, 'userProfileUuid', ''),
    distribution: _.get(filterProp, 'distribution', ''),
    agentCode: _.get(filterProp, 'agentCode', ''),
    unitCode: _.get(filterProp, 'unitCode', ''),
    ramCode: _.get(filterProp, 'ramCode', ''),
    trainer: _.get(filterProp, 'trainer', ''),
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    startTestDate: _.isDate(filterProp?.startTestDate)
      ? dayjs(filterProp?.startTestDate).utc(true).toDate()
      : '',
    endTestDate: _.isDate(filterProp?.endTestDate)
      ? dayjs(filterProp?.endTestDate).utc(true).toDate()
      : '',
  }
}
