import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { Header, HeaderSection, TopicSection } from './Styled'

const LocationHeader = () => {
  const data = useSelector(
    (state) => state.manageContentStation.data,
    shallowEqual
  )
  const mainBannerInformation = _.get(data, 'mainBannerInformation', {})
  const image = _.get(mainBannerInformation, 'image', '')
  const imgUrl = `${window.__env__.REACT_APP_API_URL}/api/file${image}`
  return (
    <Header img={imgUrl}>
      <HeaderSection />
      <TopicSection>
        <Typography
          variant="h5"
          sx={{ typography: { sm: 'h4', lg: 'h3' } }}
          color="primary"
        >
          {_.get(mainBannerInformation, 'subtitle', '')}
        </Typography>
        <Typography
          variant="h5"
          sx={{ typography: { sm: 'h3', lg: 'h2' } }}
          color="primary"
        >
          {_.get(mainBannerInformation, 'title', '')}
        </Typography>
      </TopicSection>
    </Header>
  )
}

export default LocationHeader
