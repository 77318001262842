import { Divider, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledColumn, StyledRow } from '../../../Styled'
import {
  BOOKING_PERIOD_TYPE,
  ROOM_LAYOUT,
} from '../../../../../constants/roomManagement'
import dayjs from 'dayjs'
import { Detail } from './PopUp'

export const PopUpRoomDetail = () => {
  const theme = useTheme()
  const { bookingPeriod, meetingRoom, roomDate, requestType } = useSelector(
    (state) => ({
      bookingPeriod: state.roomManagement.PopupEventSummary.bookingPeriod,
      meetingRoom: state.roomManagement.PopupEventSummary.meetingRoom,
      roomDate: state.roomManagement.PopupEventSummary.roomDate,
      requestType: state.roomManagement.PopupEventSummary.requestType,
    }),
    shallowEqual,
  )
  const firstDate = Object.values(meetingRoom)[0].bookingDate
  const rooms =
    bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME &&
    requestType !== 'MANAGE_CLASS'
      ? { [firstDate]: Object.values(meetingRoom)[0] }
      : meetingRoom
  return _.map(Object.keys(rooms), (key) => {
    const room = rooms[key]
    return (
      <StyledColumn
        sx={{
          border: '1px dashed',
          borderColor: theme.palette?.primary?.main,
          borderRadius: 2,
          rowGap: 1,
        }}
      >
        <StyledColumn
          sx={{
            background: '#f3f8ff',
            pt: 1,
            px: 2,
            borderRadius: '8px 8px 0 0',
            columnGap: 2,
          }}
        >
          <StyledRow sx={{ columnGap: 1 }}>
            <Typography variant="body3b" color={theme.palette?.primary?.main}>
              ใช้งานวันที่
            </Typography>
            <Typography variant="body3">
              {bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM ||
              requestType === 'MANAGE_CLASS'
                ? dayjs(key).format('DD/MM/YYYY')
                : roomDate}
            </Typography>
          </StyledRow>
          <StyledRow sx={{ columnGap: 1 }}>
            <Typography variant="body3b" color={theme.palette?.primary?.main}>
              เริ่มใช้ห้องตั้งแต่
            </Typography>
            <Typography variant="body3">{room[0].roomTime}</Typography>
          </StyledRow>
          <StyledRow sx={{ columnGap: 1 }}>
            <Typography variant="body3b" color={theme.palette?.primary?.main}>
              เวลาอบรม
            </Typography>
            <Typography variant="body3">{room[0].timeTraining}</Typography>
          </StyledRow>
        </StyledColumn>

        <StyledColumn
          sx={{
            pb: 1,
            px: 2,
            borderRadius: '0 0 8px 8px',
          }}
        >
          {_.map(room, (item, ind) => {
            const isOnline = _.get(item, 'isOnline', false)
            const roomNameOther = _.get(item, 'roomNameOther', '')
            let roomName = 'Other'
            if (isOnline) roomName = 'Online'
            else if (_.isNull(roomNameOther)) {
              roomName = _.get(item, 'roomName', '')
            }

            return (
              <StyledRow>
                <StyledColumn>
                  <Detail label={`ห้องที่ ${ind + 1}`} value={roomName} />
                  {(item.roomType === 'OTHER' || item.roomName === 'Other') &&
                    !isOnline && (
                      <Detail label="ชื่อสถานที่" value={item.roomNameOther} />
                    )}
                  <Detail
                    label="รูปแบบการจัดห้อง"
                    value={item.roomLayout ? ROOM_LAYOUT[item.roomLayout] : ''}
                  />
                  {!_.isEmpty(item.roomLayout) &&
                    _.get(item, 'roomLayout', '').toUpperCase() === 'GROUP' && (
                      <>
                        <Detail
                          label="จำนวนกลุ่ม (กลุ่ม)"
                          value={Number(item.group).toLocaleString()}
                        />
                        <Detail
                          label="กลุ่มละ (คน)"
                          value={Number(item.attendeeInGroup).toLocaleString()}
                        />
                      </>
                    )}
                  <Detail
                    label="จำนวนผู้เข้าร่วม"
                    value={Number(item.attendees).toLocaleString()}
                  />
                  {item?.equipment?.length > 0 && (
                    <>
                      <Typography variant="body3b">อุปกรณ์ภายในห้อง</Typography>
                      <StyledRow
                        sx={{
                          borderRadius: 1,
                          px: 1,
                          gap: 0.5,
                          border: `1px solid ${theme.palette?.blue?.blueLine}`,
                          background: theme.palette?.background?.paper,
                          width: 'fit-content',
                        }}
                      >
                        {_.map(item.equipment, (equipments, idx) => {
                          const {
                            equipment: { equipmentName },
                            quantity,
                          } = equipments
                          return (
                            <StyledRow sx={{ gap: 0.5 }} key={idx}>
                              <StyledRow>
                                <Typography variant="body3">
                                  {equipmentName} {quantity}
                                </Typography>
                              </StyledRow>
                              {idx !== item.equipment.length - 1 && (
                                <Divider
                                  sx={{
                                    my: 0.5,
                                    borderColor: theme.palette?.blue?.blueLine,
                                  }}
                                  orientation="vertical"
                                  flexItem
                                />
                              )}
                            </StyledRow>
                          )
                        })}
                      </StyledRow>
                    </>
                  )}
                  <Typography variant="body3b">รายละเอียดเพิ่มเติม</Typography>
                  <Typography variant="body3">
                    {item.additionalEquipment || '-'}
                  </Typography>
                </StyledColumn>
              </StyledRow>
            )
          })}
        </StyledColumn>
      </StyledColumn>
    )
  })
}
