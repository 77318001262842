import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const ImageBox = styled('img')(() => ({
  height: 'auto',
  boxShadow:
    '0px 1px 5px 0px #0000001f, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033',
  borderRadius: '8px',
}))

export const FlexCenterBox = styled(Box)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette?.primary?.main,
  width: 'fit-content',
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  padding: '12px 0',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    padding: '12px 0',
  },
  '@media print': {
    display: 'none',
  },
}))

export const DownloadText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body1,
  },
}))

export const DownloadTextSize = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}))
