import { Close } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { handleCloseDrawer } from '../../handler/handleCloseDrawer'
import { useSelector, shallowEqual } from 'react-redux'

export const Header = () => {
  const { uuid } = useSelector(
    (state) => ({
      uuid: state.crud.formData.uuid,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" justifyContent="space-between" px={3} pt={3} pb={2}>
      <Typography variant="h5">
        {uuid ? `แก้ไข` : `สร้าง`} Class expense
      </Typography>
      <IconButton onClick={() => handleCloseDrawer()}>
        <Close />
      </IconButton>
    </Box>
  )
}
