export const initialState = {
  isLoading: false,
  isUploadLoading: {},
  isSuccess: false,
  error: {
    all: null,
    upload: null,
  },
  data: null,
}

export const initialStation = {
  title: '',
  description: '',
  phoneNumber: '',
  img: '',
  imgMap: '',
  googleMap: '',
  status: true,
}