import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import { StyledBox } from '../Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table2'
import { validateEPermission } from '../../../../../utils/lib'

const ActionECertiticateBackground = ({ row, rowIndex, condition, isTest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { module, viewPath, editPath } = condition
  const getPermissionView = isTest
    ? true
    : validateEPermission({
        module,
        permission: 'VIEW',
        data: row,
      })
  const getPermissionEdit = isTest
    ? true
    : validateEPermission({
        module,
        permission: 'EDIT',
        data: row,
      })

  const viewUrl = `${viewPath}/${row.uuid}`
  const editUrl = `${editPath}/${row.uuid}`

  return (
    <StyledBox>
      {getPermissionView && row.status === 'DELETED' && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
      {getPermissionEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionECertiticateBackground
