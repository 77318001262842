import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getQueryParams } from '../../../../../utils/lib'
import { handleOnClickMenu } from './events'

const DropdownButton = () => {
  const trainingPlan = getQueryParams('trainingPlanUuid')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`menu-${index}`}
            onClick={() => {
              dispatch(handleOnClickMenu(trainingPlan))
              setAnchorEl(null)
            }}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [{ text: 'ดาวน์โหลดรายชื่อ', value: 'download' }]
