import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { closePointDialog } from '../../../../../../redux/slices/manage/agentProfile'
import { setPointDialog } from '../../../../../../redux/slices/manage/agentProfile'
import {
  Row,
  Column,
  StyledCard,
  PointBox,
  BoxSearch,
  SearchInput,
  StyledSearchIcon,
} from './Styled'
import Table from '../../../../../../components/redux/Table'
import { fetchCatalog, handleQuickSearch } from './events'
import { convertNumberWithComma } from '../../../../../../utils/lib'
import { DIALOG_TAB } from '../model'

const RedeemForm = () => {
  const dispatch = useDispatch()
  const { isOpen, point, cost, remaining, keyword, cart, isConfirmation } =
    useSelector(
      (state) => ({
        isOpen: state.agentProfile.pointDialog.open,
        point: state.agentProfile.initialState.point,
        cost: state.agentProfile.pointDialog.cost,
        remaining: state.agentProfile.pointDialog.remaining,
        keyword: state.agentProfile.pointDialog.keyword,
        cart: state.agentProfile.pointDialog.cart,
        isConfirmation: state.agentProfile.pointDialog.isConfirmation,
      }),
      shallowEqual,
    )
  const { table } = useSelector((state) => state.table)
  const { page, limit } = table

  useEffect(() => {
    if (isConfirmation) {
      dispatch(setPointDialog({ key: 'isConfirmation', value: false }))
    } else {
      isOpen && dispatch(fetchCatalog({ table, page, keyword, point, cart }))
    }
  }, [isOpen, page, limit])

  return (
    <Column>
      <PointBox>
        <Typography variant="body1b" sx={{ textAlign: 'center' }}>
          จำนวนคะแนนสะสมปัจจุบัน
        </Typography>
        <Row sx={{ alignItems: 'center', justifyContent: 'center', gap: 3 }}>
          <Typography variant="h5">{convertNumberWithComma(point)}</Typography>
          <Typography variant="body1">คะแนน</Typography>
        </Row>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body1b">จำนวนคะแนนที่ใช้แลกของรางวัล</Typography>
          <Row sx={{ gap: '15px' }}>
            <Typography variant="body1b">
              {convertNumberWithComma(cost)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </Row>
        </Row>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body1b">จำนวนคะแนนคงเหลือ</Typography>
          <Row sx={{ gap: '15px' }}>
            <Typography variant="body1b">
              {convertNumberWithComma(remaining)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </Row>
        </Row>
        <Divider />
      </PointBox>
      <StyledCard>
        <Typography variant="body1b" color="primary.main">
          รายการของรางวัลที่แลกได้
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2.5 }}>
          ค้นหา
        </Typography>
        <BoxSearch>
          <SearchInput
            onChange={(e) =>
              dispatch(
                setPointDialog({ key: 'keyword', value: e.target.value }),
              )
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(handleQuickSearch(table, keyword, point, cart))
              }
            }}
            value={keyword}
          />
          <StyledSearchIcon
            color="primary"
            onClick={() =>
              dispatch(handleQuickSearch(table, keyword, point, cart))
            }
          />
        </BoxSearch>
        <Table />
      </StyledCard>
      <Box sx={{ display: 'flex', gap: 3, mb: 3, justifyContent: 'center' }}>
        <Button
          variant="outlined"
          size="m"
          sx={{ minWidth: 144 }}
          onClick={() => dispatch(closePointDialog())}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="m"
          sx={{ minWidth: 144 }}
          disabled={cart.length <= 0}
          onClick={() => {
            dispatch(setPointDialog({ key: 'isConfirmation', value: true }))
            dispatch(
              setPointDialog({ key: 'tab', value: DIALOG_TAB.CONFIRMATION }),
            )
          }}
        >
          ต่อไป
        </Button>
      </Box>
    </Column>
  )
}

export default RedeemForm
