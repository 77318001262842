import _ from 'lodash'
import { validatePermission } from '../../../../../utils/lib'

export const checkFormPermission = (data, user) => {
  const contentType = _.get(data, 'contentType', '')
  user
  if (contentType) {
    const hasPermissionAll = validatePermission({
      user: user,
      moduleType: 'E_CONTENT_AND_KB',
      permission: ['CREATE', 'EDIT'],
    })
    const hasPermissionEContent = validatePermission({
      user: user,
      moduleType: 'E_CONTENT_AND_KB',
      permission: ['CREATE_ECONTENT', 'EDIT_ECONTENT'],
    })
    const hasPermissionKB = validatePermission({
      user: user,
      moduleType: 'E_CONTENT_AND_KB',
      permission: ['CREATE_KB', 'EDIT_KB'],
    })
    if (
      contentType === 'E_CONTENT' &&
      (hasPermissionAll || hasPermissionEContent)
    ) {
      return true
    } else if (contentType === 'KB' && (hasPermissionAll || hasPermissionKB)) {
      return true
    } else {
      return false
    }
  }
}
