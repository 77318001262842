import _ from 'lodash'
import * as yup from 'yup'

export const validationSchema = yup.object({
  name: yup
    .string()
    .max(255, 'ชื่อหมวดหมู่ย่อยกรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  category: yup
    .object()
    .nullable()
    .test(
      'empty-check',
      'กรุณาเลือกหมวดหมู่หลัก',
      (category) => _.get(category, 'value', null) != null,
    )
    .shape({
      name: yup.string(),
      label: yup.string(),
      value: yup.string(),
    }),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  active: yup.boolean(),
})
