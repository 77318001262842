import React from 'react'
import { Cancel } from "@mui/icons-material"
import ClearIcon from '@mui/icons-material/Clear'
import { Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useRef, useState } from "react"
import { StyledTextField } from './Styled'

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        border: 'solid 1px #BDBDBD',
        borderRadius: '64px',
        padding: '5px 8px',
        display: 'inline-block',
        alignItems: 'center',
        marginRight: '5px',
        marginBottom: '5px',
      }}
    >
      <Stack direction='row' gap={1} alignItems={'center'}>
        <Typography>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer", color: '#BDBDBD' }}
          onClick={() => {
            handleDelete(data)
          }}
        />
      </Stack>
    </Box>
  )
}

const InputTags = ({
  values,
  textError,
  setInputValue,
  keyValue,
}) => {
  const tagRef = useRef()
  const [error, setError] = useState('')

  const handleDelete = (value) => {
    const newtags = values.filter((val) => val !== value)
    setInputValue(keyValue, newtags)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setError('')
    const email = tagRef.current.value
    if (!isEmail(email)) {
      setError('กรุณากรอกให้ถูกรูปแบบอีเมล')
    }else{
      const currentList = [...values, email]
      setInputValue(keyValue, currentList)
      tagRef.current.value = ""
    }
  }

  const handleClearTags = () => {
    setInputValue(keyValue, [])
    setError('')
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <Box 
          sx={{
            display: 'flex',
            border: 'solid 1px',
            borderColor: '#CCC',
            alignItems: 'center',
            padding: '10px 15px',
          }}
        >
          <Box sx={{ 
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {values.map((data, index) => {
              return (
                <Tags data={data} handleDelete={handleDelete} key={index} />
              )
            })}
            <StyledTextField
              inputRef={tagRef}
              variant='standard'
              size='small'
              margin='none'
              sx={{ flexGrow: 1, minWidth: 0, }}
              placeholder={ values.length > 0 ? "" : 'Ex. abc@krungthai-axa.co.th' }
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center',}}>
              <ClearIcon onClick={handleClearTags} sx={{ cursor: 'pointer', }} />
          </Box>
        </Box>
      </form>
      <Box
          variant="body2"
          sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              mb: 3,
          }}
      >
          <Typography
              variant="body2"
              color="error"
          >
              {textError ? textError : ''}
          </Typography>
          <Typography
              variant="body2"
              color="error"
          >
              {error ? error : ''}
          </Typography>
      </Box>
    </Box>
  )
}

export default InputTags