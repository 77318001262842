import { store } from '../../../../App'
import _ from 'lodash'
import { getStationFilterDefault } from 'src/services/masterData/meetingRoom'
import { setFieldValue } from '../../../../redux/slices/crud'

export const fetchStation = async (user) => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'DASHBOARD_REPORT' &&
      item.module === 'REPORT_ROOM_MANAGEMENT'
    )
  })

  const response = await store.dispatch(getStationFilterDefault())

  const station = []
  const stationName = []
  const stationList = []
  //get all station list
  _.get(response, 'payload', []).map((item) => {
    stationList.push({
      label: item.station,
      name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
      value: item.uuid,
    })
  })
  //get default station
  const defaultStation = _.get(user, 'area', []).find(
    (ele) => ele.station.uuid === _.get(user, 'stationUuid', ''),
  )
  station.push(defaultStation.station.uuid)
  stationName.push(defaultStation.station.station)

  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.defaultStation',
      value: station,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.defaultStationName',
      value: stationName,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.filtersDrawer.station',
      value: station,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.filtersDrawer.stationName',
      value: stationName,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.filtersDrawer.filterTotal',
      value: hasPermissionALL ? 2 : 1,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.hasPermissionALL',
      value: hasPermissionALL,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.stationList',
      value: stationList,
    }),
  )
}
