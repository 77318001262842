import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { CustomTextArea } from '../../../../../../components/CRUD/components/CustomTextArea'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import { ViewRow } from '../../../../../../components/CRUD/components/StyledViewBox'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import UploadFile from '../../../../../../components/UploadFile'
import { handleUploadFile } from '../../handler/handleUploadFile'

const Form = () => {
  const { drawer, reasonNotReturnFile, reasonNotReturnFileError, crudConfig } =
    useSelector(
      (state) => ({
        drawer: state.crud.formData?.drawer,
        crudConfig: state.crud.crudConfig,
        reasonNotReturnFile: state.uploadFile.formData?.reasonNotReturnFile,
        reasonNotReturnFileError: _.find(state.crud.formErrors, {
          path: 'drawer.reasonNotReturnFile',
        }),
      }),
      shallowEqual,
    )

  useEffect(() => {
    if (!reasonNotReturnFile?.isUploading && reasonNotReturnFile) {
      handleUploadFile(reasonNotReturnFile)
    }
  }, [reasonNotReturnFile])

  return (
    <>
      <ViewRow title="ชื่อทรัพย์สิน" value={_.get(drawer, 'assetName', '-')} />
      <ViewRow
        title="รหัสทรัพย์สิน"
        value={_.get(drawer, 'assetCode', '-')}
        boxSx={{ mt: 2 }}
      />
      <Box mt={2}>
        {drawer?.isView ? (
          <ViewRow
            boxSx={{ display: 'flex', flexDirection: 'column' }}
            title="สาเหตุการไม่คืนทรัพย์สิน"
            variant="variant"
            value={`${_.get(drawer, 'remark', '-')}`}
          />
        ) : (
          <CustomTextArea
            fieldName="drawer.remark"
            labelName="สาเหตุการไม่คืนทรัพย์สิน"
            required
          />
        )}
      </Box>
      <Box id={`${crudConfig.moduleSlug}-reasonNotReturnFile`} mt={2}>
        {drawer?.isView ? (
          <>
            {drawer?.img?.key ? (
              <ViewRow
                variant="body1"
                title={`${'รูปประกอบ'}`}
                valueComponent={
                  <>
                    <img
                      src={`${window.__env__.REACT_APP_API_URL}/file/get${_.get(
                        drawer,
                        'img.key',
                        '-',
                      )}`}
                      height={150}
                      style={{ objectFit: 'cover', maxWidth: '300px' }}
                    />
                  </>
                }
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <Box display="flex">
              <Typography variant="body2" sx={{ mb: 1 }}>
                รูปประกอบ
              </Typography>
            </Box>
            <UploadFile
              sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
              folder="reasonNotReturnFile"
              maxFileSize={{ size: 2, sizeType: 'MB' }}
              accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
                'image/jpg': ['.jpg'],
              }}
              acceptLabel=".jpg, .jpeg, .png"
              fieldName="reasonNotReturnFile"
              maxLength={1}
              isForImage
            />
            {!_.isEmpty(reasonNotReturnFileError) && (
              <Box>
                <Typography color={(theme) => theme.palette.error.main}>
                  {_.get(reasonNotReturnFileError, 'message') ?? ''}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box display="flex" my={3} gap={3} justifyContent="end">
        {drawer?.isView ? (
          <Button
            variant="contained"
            size="m"
            type="button"
            onClick={() => openStockDrawer(false)}
          >
            ตกลง
          </Button>
        ) : (
          <>
            <CancelButton onClick={() => openStockDrawer(false)} />
            <SubmitButton
              handleSubmitClick={() =>
                handleSubmitDrawer(_.get(drawer, 'assetsIndex', null))
              }
            />
          </>
        )}
      </Box>
    </>
  )
}

export default Form
