import { setChange } from '../../../../../../redux/slices/eEvaluationForm'

import { openDialog, closeDialog } from '../../../../../../redux/slices/dialog'

export const handleAddQuestion = (type, toggleQuestions) => (dispatch) => {
  dispatch(
    setChange({
      key: `toggleQuestions.${type}`,
      value: !toggleQuestions,
    }),
  )

  if (type !== 'questions') {
    let obj = {
      title: '',
      sequence: 1,
      questionType: 'RATING',
      url: '',
      mediaType: 'IMAGE',
      isSuggestion: false,
      questions: [
        {
          id: `id-1`,
          title: '',
          sequence: 1,
        },
      ],
    }
    if (type === 'lecturerAssessments') obj = { ...obj, isPersonal: true }
    dispatch(
      setChange({
        key: type,
        value: [obj],
      }),
    )
  }
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChange(props))
}

export const handleDeleteQuestion = (type, toggleQuestions) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบรายการนี้หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      agreeText: 'ลบ',
      handleConfirm: () =>
        dispatch(confirmDeleteQuestion(type, toggleQuestions)),
    }),
  )
}

export const confirmDeleteQuestion = (type, toggleQuestions) => (dispatch) => {
  dispatch(
    setChange({
      key: `toggleQuestions.${type}`,
      value: !toggleQuestions,
    }),
  )
  if (type === 'questions') {
    dispatch(
      setChange({
        key: 'subQuestions',
        value: [],
      }),
    )
  }
  dispatch(
    setChange({
      key: type,
      value: [],
    }),
  )

  dispatch(closeDialog())
}
