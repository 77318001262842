import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningLearningPath,
  eLearningLearningPathHistory,
} from '../../../utils/apiPath'

export const getDetail = createAsyncThunk(
  'eLearningPathDetail/getDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningLearningPath}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearningPathHistory = createAsyncThunk(
  'eLearningPathDetail/getLearningPathHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningLearningPathHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
