import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import PagesOutlined from '@mui/icons-material/PagesOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import PaidOutlined from '@mui/icons-material/PaidOutlined'
import {
  convertFormatTime,
  isMediaWidthMoreThan,
} from '../../../../../../../utils/lib'
import { Column, Row } from '../../../Styled'
import { DetailCard } from '../../../components'
import { StyledContainer } from '../../../components/Styled'
import { courseInclude } from './model'
import { StyledCard, StyledChip, StyledRow } from './Styled'

const RightContent = () => {
  const data = useSelector(
    (state) => state.eLearningPathPreview.course,
    shallowEqual,
  )
  const width = { lg: 468, md: '100%' }
  const paidCost = _.get(data, 'paidCost', false)
  const skills = _.get(data, 'getAcquiredSkill', [])
  const time = convertFormatTime(data)

  return (
    <Column sx={{ gap: 3, minWidth: width, width }}>
      <StyledRow>
        <CourseDetailCard
          fullWidth
          icon={<CameraOutdoor color="primary" />}
          label="รูปแบบการสอน"
          data={_.get(data, 'trainingPlatformName', '-')}
        />
        <CourseDetailCard
          icon={<PagesOutlined color="primary" />}
          label="ช่องทางการจำหน่าย"
          data={_.get(data, 'distribution', '-')}
        />
        <CourseDetailCard
          icon={<TimerOutlined color="primary" />}
          label="ระยะเวลาของหลักสูตร"
          data={time}
        />
      </StyledRow>

      <Column gap={1}>
        <Typography variant="h5" color="primary">
          กลุ่มหลักสูตร
        </Typography>
        <Typography variant="h6">{_.get(data, 'roadmap', '-')}</Typography>
      </Column>

      <Column gap={1}>
        <Typography variant="h5" color="primary">
          หลักสูตรนี้ประกอบไปด้วย
        </Typography>
        <CourseIncludes data={data} />
      </Column>

      <Column gap={1}>
        <Typography variant="h5" color="primary">
          ทักษะที่ได้จากหลักสูตร
        </Typography>
        <Row flexWrap="wrap" gap={1}>
          {skills.map((item, index) => (
            <StyledChip key={index} variant="outlined" label={item} />
          ))}
        </Row>
      </Column>

      {paidCost && (
        <Alert
          sx={{ alignItems: 'center' }}
          variant="outlined"
          severity="info"
          icon={<PaidOutlined />}
        >
          หลักสูตรนี้มีค่าใช้จ่ายโปรดดำเนินการชำระเงินก่อนสมัคร สอบถามเพิ่มเติม{' '}
          {
            <Link
              target="_blank"
              href={`${window.__env__.REACT_APP_LEARNER_URL}/location`}
            >
              ติดต่อเจ้าหน้าที่
            </Link>
          }
        </Alert>
      )}
    </Column>
  )
}

export default RightContent

export const CourseDetailCard = ({ id, fullWidth, icon, label, data }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const textVariant = isNotSm ? 'body1' : 'body2'
  return (
    <StyledCard id={id} fullWidth={fullWidth}>
      <Row sx={{ justifyContent: 'center', gap: 1 }}>
        {icon}
        <Typography variant={textVariant}>{label}</Typography>
      </Row>
      <Typography variant={`${textVariant}b`} color="primary">
        {data}
      </Typography>
    </StyledCard>
  )
}

export const CourseIncludes = ({ data }) => {
  const isAttendance = _.get(data, 'isAttendance', false)
  const preTest = _.get(data, 'preTest', false)
  const postTest = _.get(data, 'postTest', false)
  const evaluation = _.get(data, 'evaluation', false)
  const assessment = _.get(data, 'assessment', false)
  const eCert = _.get(data, 'eCertificationVersion', false)
  return (
    <StyledContainer mb={0}>
      {isAttendance && <IncludeCard field="attendance" />}
      {preTest && <IncludeCard field="preTest" />}
      {postTest && <IncludeCard field="postTest" />}
      {evaluation && <IncludeCard field="evaluation" />}
      {eCert && <IncludeCard field="eCert" />}
      {assessment && <IncludeCard field="assessment" />}
    </StyledContainer>
  )
}

export const IncludeCard = ({ field }) => {
  const data = _.get(courseInclude, field, '')
  return (
    <DetailCard icon={_.get(data, 'icon', <></>)} sx={{ pr: 1 }}>
      {_.get(data, 'label', '')}
    </DetailCard>
  )
}
