import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: 576,
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  postition: 'relative',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const StyledQRContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: 'none',
  width: 'auto',
  background: theme.palette?.grey?.grey100,
  borderRadius: theme.spacing(1.5),
  margin: `0 ${theme.spacing(3)}`,
  padding: `${theme.spacing(3)}`,
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: `0`,
    padding: `${theme.spacing(2)}`,
  },
  position: 'relative',
}))

export const StyledCountDown = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 8,
}))

export const StyledShowCountdown = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading, theme }) => ({
  position: 'absolute',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: theme?.palette?.common?.black,
  opacity: 0.54,
}))

export const StyledSetExpiryDateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
