import { InfoOutlined } from '@mui/icons-material'
import { Typography, List, ListItemText, Box, Tooltip } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'

export const SummaryList = ({ summaryList }) => {
  const theme = useTheme()

  return (
    <List
      sx={{
        listStyleType: 'disc',
        p: 0,
        color: theme.palette?.text?.gray,
        pr: 10,
      }}
    >
      {_.map(summaryList, (item) => (
        <ListItemText sx={{ display: 'list-item', ml: 3 }}>
          <Box display="flex" justifyContent="space-between" gap={3}>
            <Box sx={{ width: 250 }} display="flex" gap={1}>
              <Typography variant="body1" color="gray">
                {_.get(item, 'label')}
              </Typography>
              {_.get(item, 'info') && (
                <Tooltip
                  arrow
                  title={
                    <Typography variant="tooltip">
                      {_.get(item, 'info')}
                    </Typography>
                  }
                  background="primary"
                >
                  <InfoOutlined color="primary" />
                </Tooltip>
              )}
            </Box>
            <Typography variant="body1" color="text.primary">
              {_.get(item, 'summary').toLocaleString()}
            </Typography>
            <Typography variant="body1" color="text.primary">
              บาท
            </Typography>
          </Box>
        </ListItemText>
      ))}
    </List>
  )
}
