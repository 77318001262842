import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { shallowEqual, useSelector } from 'react-redux'
import { setFieldValue } from '../../../../../../../redux/slices/crud'
const SearchCustom = () => {
  const dispatch = useDispatch()
  const { searchAsset } = useSelector(
    (state) => ({
      searchAsset: state.crud?.searchAsset,
    }),
    shallowEqual,
  )

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder="ค้นหาชื่อทรัพย์สิน"
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          dispatch(setFieldValue({ key: 'searchAsset', value: value }))
        }}
        // onKeyPress={(e) => {
        //   dispatch(onEnterSearch(e, searchKey))
        // }}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     const value = _.get(e, 'target.value', '')
        //     dispatch(
        //       setSearch({
        //         key: searchKey,
        //         value: value,
        //       }),
        //     )
        //     dispatch(onEnterSearch(e, searchKey))
        //   }
        // }}
        value={searchAsset}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => {
          dispatch(setFieldValue({ key: 'searchAsset', value: searchAsset }))
        }}
      />
    </BoxSearch>
  )
}

export default SearchCustom
