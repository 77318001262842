import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledCard,
} from './Styled'
import { Loading } from './components/Loading'
import ActionButtons from './components/ActionButtons'
import FilterDrawer from './components/FilterDrawer'
import TableReport from './components/TableReport'
import AuditLogTab from './components/AuditLogTab'
import _ from 'lodash'
import { setInitialReport } from '../../redux/slices/reportComp'
import ManageColumnDrawer from './components/ManageColumnDrawer'
import { useReportConstants } from './hooks/useReportConstants'
import { Box, Typography } from '@mui/material'
import Breadcrumbs from '../../components/Breadcrumbs'
import { reportClassConst } from '../../constants/report/ReportClass'
import { handleParamsNames } from './handler/handleParamsNames'
import { fetchSearchParams } from './handler/fetchSearchParams'
import { fetchSearchParamsAgentProfile } from './handler/fetchSearchParamsAgentProfile'
import { fetchSearchParamsProspectProfile } from './handler/fetchSearchParamsProspectProfile'
import { fetchSearchParamsRcms } from './handler/fetchSearchParamsRcms'
import { fetchSearchParamsRoomManagement } from './handler/fetchSearchParamsRoomManagement'

const Report = ({ tabs, user }) => {
  const dispatch = useDispatch()
  const queryString = window.location.search
  let statusName = ''
  const urlParams = new URLSearchParams(queryString)
  if (!_.isEmpty(urlParams.get('STATUS'))) {
    statusName = handleParamsNames(
      reportClassConst.statusOptions,
      urlParams.get('STATUS'),
    )
  }

  const { isLoading, moduleId, breadcrumb } = useSelector(
    (state) => ({
      isLoading: state.reportComp.isLoading,
      moduleId: state.reportComp.reportConfig.moduleId,
      breadcrumb: state.reportComp.reportConfig.breadcrumb,
    }),
    shallowEqual,
  )
  const { defaultColumns, filterColumnOptions, COLUMN_NAME, COLUMN_NAME_TH } =
    useReportConstants(moduleId)

  useEffect(() => {
    const columnsStorage = localStorage.getItem(`columns_${moduleId}`)
    let columns = defaultColumns
    if (!_.isNull(columnsStorage)) {
      columns = JSON.parse(columnsStorage)
    }
    dispatch(
      setInitialReport({
        filterColumnOptions: filterColumnOptions,
        columns: columns,
        defaultColumns: defaultColumns,
        columnName: COLUMN_NAME,
        columnNameTH: COLUMN_NAME_TH,
        hasSortedColumns: !_.isNull(columnsStorage),
      }),
    )
    if (
      !_.isEmpty(urlParams.get('Station')) ||
      !_.isEmpty(urlParams.get('STATUS')) ||
      !_.isEmpty(urlParams.get('START')) ||
      !_.isEmpty(urlParams.get('END'))
    ) {
      dispatch(fetchSearchParams(urlParams, statusName))
    }
    if (moduleId === 'REPORT_AGENT_PROFILE') {
      dispatch(fetchSearchParamsAgentProfile())
    }
    if (moduleId === 'REPORT_PROSPECT_PROFILE') {
      dispatch(fetchSearchParamsProspectProfile())
    }
    if (moduleId === 'REPORT_RCMS') {
      dispatch(fetchSearchParamsRcms())
    }
    if (
      moduleId === 'REPORT_ROOM_MANAGEMENT_ALL' ||
      moduleId === 'REPORT_ROOM_MANAGEMENT_PIC'
    ) {
      dispatch(fetchSearchParamsRoomManagement(user))
    }
  }, [moduleId])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4">รายงาน </Typography>
            <Breadcrumbs menuList={breadcrumb} />
          </Box>
        </StyledHeader>
        {tabs}
        <StyledCard isLoading={isLoading}>
          {moduleId === 'AUDIT_LOG' && <AuditLogTab />}
          <ActionButtons />
          <TableReport />
        </StyledCard>
        <FilterDrawer />
        <ManageColumnDrawer />
      </StyledContent>
      <Loading isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Report
