import _ from 'lodash'
import * as yup from 'yup'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { isValidationExcepted } from '../../events'

export const linkToWebsiteSchema = yup.object().shape({
  webName: yup.string().max(255, 'รายละเอียดกรอกได้ไม่เกิน 255 ตัวอักษร'),
  link: yup
    .string()
    .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร')
    .test('required', 'กรุณากรอกข้อมูล', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.LINK_TO)) return true
      return value
    })
    .test(
      'require-protocal',
      'รูปแบบ Link ต้องมี http:// หรือ https://',
      (value, context) => {
        if (isValidationExcepted(context, MODULE_TYPE.LINK_TO)) return true
        return LINK_FORMAT.some((text) => value.includes(text))
      },
    )
    .test('format-link', 'รูปแบบ Link ไม่ถูกต้อง', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.LINK_TO)) return true
      const splitted = _.get(value.split('://'), 0, '')
      return LINK_FORMAT.some((text) => splitted === text)
    }),
  linkDescription: yup
    .string()
    .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร')
    .test('required', 'กรุณากรอกข้อมูล', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.LINK_TO)) return true
      return value
    }),
})

export const LINK_FORMAT = ['http', 'https']
