import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledAlertBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: theme?.palette?.error?.background,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  marginTop: theme.spacing(2),
  gap: theme.spacing(1.5),
  alignItems: 'center',
}))
