import { store } from '../../../../App'
import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setSearchText,
  setToggleFilterDrawer,
} from '../../../../redux/slices/table'
import { fetchBudgetManagementList } from './fetchBudgetManagementList'

export const handleFiltering =
  (mutateFilterSateToProp, mutateFilterPropToBody) => (dispatch) => {
    const { filterState } = store.getState().table
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))

    const filterProp = mutateFilterSateToProp()

    dispatch(setFilterProp(filterProp))
    dispatch(setFilterTotal(filterState))
    dispatch(resetTablePage())
    fetchBudgetManagementList(mutateFilterPropToBody).then()
  }
