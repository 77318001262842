import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import CreatableGroupChip from '../../../../../../../components/CreatableGroupChip'
import SelectGroupChip from '../../../../../../../components/SelectGroupChip'
import HeadLabel from '../../../../../components/HeadLabel'
import { handleChange } from '../../events'
import { handleDefaultList, handleKeyDown } from '../events'
import { useInputField } from '../../../../../../../components/CRUD/hooks/useInputField'
import { fetchLevelOfLearner } from '../../../Handler/fetchLevelOfLearner'

const ChipForm = () => {
  const [inputValue, setInputValue] = useState('')
  const distributionKey = 'formData.setting.distribution'
  const acquiredSkillKey = 'formData.acquiredSkill'
  const productTypeKey = 'formData.productType'
  const levelOfLearnerKey = 'formData.levelOfLearner'
  const dispatch = useDispatch()
  const {
    distributionOption,
    acquiredSkillOption,
    productTypeOption,
    levelOfLearnerOption,
    setting,
    acquiredSkill,
    productType,
  } = useSelector(
    (state) => ({
      distributionOption: state.crud.masterData.distributionCh.options,
      acquiredSkillOption: state.crud.masterData.acquiredSkill.options,
      productTypeOption: state.crud.masterData.productType.options,
      levelOfLearnerOption: state.crud.masterData.levelOfLearner.options,
      setting: state.crud.formData.setting,
      levelOfLearner: state.crud.formData.levelOfLearner,
      acquiredSkill: state.crud.formData.acquiredSkill,
      productType: state.crud.formData.productType,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  const {
    value: levelOfLearner,
    isError: isLevelOfLearnerError,
    message: levelOfLearnerMessage,
  } = useInputField('levelOfLearner')
  const { isError: isAcquiredSKillError, message: acquiredSkillMessage } =
    useInputField('acquiredSkill')
  const { isError: isProductTypeError, message: ProductTypeMessage } =
    useInputField('productType')
  const {
    value: distribution,
    isError: isDistributionError,
    message: distributionMessage,
  } = useInputField('setting.distribution')

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="การตั้งค่า Collection" />
        <CreatableGroupChip
          id="tag"
          labelText="Tag"
          placeholder="กรุณาเพิ่ม Tag"
          value={_.get(setting, 'tag', '')}
          textError={_.get(errors, _.get(setting, 'tag', ''), '')}
          inputValue={inputValue}
          onChange={(value) =>
            dispatch(handleChange('formData.setting.tag', value))
          }
          onInputChange={(value) => setInputValue(value)}
          onKeyDown={(e) =>
            dispatch(
              handleKeyDown(
                e,
                inputValue,
                setInputValue,
                _.get(setting, 'tag', ''),
              ),
            )
          }
        />
        <SelectGroupChip
          required
          id="setting.distribution"
          name="setting.distribution"
          labelText="ช่องทางการจัดจำหน่าย"
          placeholder="กรุณาเลือก"
          options={distributionOption}
          textError={distributionMessage}
          isShowTextError={isDistributionError}
          value={handleDefaultList(distributionOption, distribution)}
          onChange={(list) => {
            dispatch(handleChange(distributionKey, list))
            fetchLevelOfLearner(list)
          }}
        />
        <SelectGroupChip
          required
          id={levelOfLearnerKey}
          name={levelOfLearnerKey}
          labelText="ระดับของผู้เรียน"
          placeholder="กรุณาเลือก"
          options={[...levelOfLearnerOption]}
          textError={levelOfLearnerMessage}
          isShowTextError={isLevelOfLearnerError}
          value={handleDefaultList(levelOfLearnerOption, levelOfLearner)}
          onChange={(list) => dispatch(handleChange(levelOfLearnerKey, list))}
        />
      </Box>

      <Divider sx={{ mx: 0.5 }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="Skill Mapping" />
        <SelectGroupChip
          required
          id={acquiredSkillKey}
          name={acquiredSkillKey}
          labelText="ระดับหลักสูตร"
          placeholder="กรุณาเลือก"
          options={acquiredSkillOption}
          textError={acquiredSkillMessage}
          isShowTextError={isAcquiredSKillError}
          value={handleDefaultList(acquiredSkillOption, acquiredSkill)}
          onChange={(list) => dispatch(handleChange(acquiredSkillKey, list))}
        />
        <SelectGroupChip
          required
          id={productTypeKey}
          name={productTypeKey}
          labelText="ทักษะที่ได้จากหลักสูตร"
          placeholder="กรุณาเลือก"
          options={productTypeOption}
          textError={ProductTypeMessage}
          isShowTextError={isProductTypeError}
          value={handleDefaultList(productTypeOption, productType)}
          onChange={(list) => dispatch(handleChange(productTypeKey, list))}
        />
      </Box>
    </>
  )
}

export default ChipForm
