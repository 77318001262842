import {
  COURSE_FOR,
  SHOW_COURSE_TYPE,
} from '../../../../constants/reportCourseSetting/reportCourseSetting'
import { themeColor } from '../../../../utils/themeData'

export const courseForList = [
  {
    courseForLabel: 'สำหรับ Agency',
    color: '#F08428',
    bgColor: '#FFF0C3',
    courseFor: COURSE_FOR.AGENCY,
  },
  {
    courseForLabel: 'สำหรับ Banc',
    color: '#46B465',
    bgColor: '#D8F8DA',
    courseFor: COURSE_FOR.BA,
  },
  {
    courseForLabel: 'สำหรับ KTB',
    color: '#D64760',
    bgColor: '#FFE0E9',
    courseFor: COURSE_FOR.KTB,
  },
  {
    courseForLabel: 'สำหรับ Other',
    color: '#3A3EC3',
    bgColor: '#EEE8FF',
    courseFor: COURSE_FOR.OTHER,
  },
]

export const courseShowList = [
  {
    title: 'หลักสูตรแก้ไขล่าสุด',
    color: themeColor.success?.light,
    showCourseType: SHOW_COURSE_TYPE.SORTED_BY_UPDATED,
  },
  {
    title: 'หลักสูตรที่เปิดรับสมัคร',
    color: themeColor.warning?.main,
    showCourseType: SHOW_COURSE_TYPE.COURSE_ENROLL,
  },
  {
    title: 'หลักสูตรที่เปิดอบรม',
    color: themeColor.blue?.dodgerBlue,
    showCourseType: SHOW_COURSE_TYPE.COURSE_OPENED,
  },
]

export const initialState = {
  isLoading: false,
  selectedCourseFor: COURSE_FOR.ALL,
  courseForList: courseForList,
  countCourseAll: 0,
  courseShowList: courseShowList,
  dateRange: {},
}
