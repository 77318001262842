import { store } from '../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    categoryName: _.get(filterProp, 'categoryName', ''),
    updateStartDate: _.isNil(filterProp?.updateStartDate)
      ? undefined
      : dayjs(filterProp.updateStartDate).utc(true).toDate(),
    updateFinishDate: _.isNil(filterProp?.updateFinishDate)
      ? undefined
      : dayjs(filterProp.updateFinishDate).utc(true).toDate(),
    createStartDate: _.isNil(filterProp?.createStartDate)
      ? undefined
      : dayjs(filterProp.createStartDate).utc(true).toDate(),
    createFinishDate: _.isNil(filterProp?.createFinishDate)
      ? undefined
      : dayjs(filterProp.createFinishDate).utc(true).toDate(),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    status: _.get(filterProp, 'status', []),
  }
}
