import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Editor } from '@tinymce/tinymce-react'
import { checkUpload, getPlainText } from '../../utils/lib'
import { StyledEditorBox } from './Styled'
import { useDispatch } from 'react-redux'
import { uploadEditor } from '../../services/manageClass/detail'
import ButtonPopper from './ButtonColor'

export const RenderTextEditor = ({ style, variant, labelText, color }) => {
  return (
    <Box sx={style}>
      <Typography
        variant={variant ? variant : 'body1'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& p': {
            margin: '0px',
          },
          '& ul , ol': {
            margin: '0px',
            lineHeight: '12px',
          },
          '& *': {
            color: color ? `${color} !important` : 'auto',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: labelText,
        }}
      />
    </Box>
  )
}

const BasicEditor = (props) => {
  const {
    onInit,
    boxSx,
    labelText,
    required,
    disabled,
    textError,
    textInfo,
    editorRef,
    onBlur,
    onChange,
    value,
    id,
    toolbar,
    plugins,
    height,
    showMaxLength,
    isTextLefe,
    isChange,
    colorValue,
    isPopperColor,
    onChangeColor,
    isPasteAsText,
  } = props
  const plainText = getPlainText(value)
  const dispatch = useDispatch()
  const propInit = {
    height: 270,
    menubar: false,
    contextmenu: false,
    paste_as_text: isPasteAsText,
    // plugins: "paste",
    plugins: plugins || [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help',
    ],
    toolbar: !_.isEmpty(toolbar)
      ? toolbar
      : toolbar === 'none'
      ? ''
      : 'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    max_height: height,
    image_title: true,
    automatic_uploads: true,
    // image
    file_picker_types: 'image',
    file_picker_callback: function (cb) {
      let input = document.createElement('input')
      input.setAttribute('id', 'toolbar-add-image')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.onchange = function () {
        let file = this.files[0]
        const checkFile = dispatch(
          checkUpload({
            events: {
              target: {
                files: [file],
              },
            },
            type: 'image',
            id: 'toolbar-add-image',
          }),
        )
        if (checkFile) return

        let reader = new FileReader()
        reader.onload = async function () {
          const body = new FormData()
          body.append('file', file)
          const response = await dispatch(uploadEditor(body))
          const url = _.get(response, 'payload.data.key')
          cb(`${window.__env__.REACT_APP_API_URL}/file/${url}`, {
            title: file.name,
          })
        }
        reader.readAsDataURL(file)
      }
      input.click()
    },
  }

  return (
    <StyledEditorBox sx={{ ...boxSx }} textError={textError}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex">
          <Typography
            variant="body2"
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {labelText}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>
        {isPopperColor && (
          <ButtonPopper
            colorValue={_.defaultTo(colorValue, '#000')}
            onChangeColor={onChangeColor}
          />
        )}
      </Box>
      {isChange && (
        <Editor
          id={id}
          onInit={(evt, editor) => {
            onInit && onInit()
            return (editorRef.current = editor)
          }}
          apiKey={window.__env__.TINY_KEY}
          onEditorChange={onChange}
          value={value}
          disabled={disabled}
          init={propInit}
        />
      )}
      {!isChange && (
        <Editor
          id={id}
          onInit={(evt, editor) => {
            onInit && onInit()
            return (editorRef.current = editor)
          }}
          apiKey={window.__env__.TINY_KEY}
          onBlur={onBlur}
          initialValue={value}
          disabled={disabled}
          init={propInit}
        />
      )}

      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: isTextLefe ? 'flex-start' : 'space-between',
        }}
      >
        <Box>
          {!textError && textInfo && textInfo.length > 0 && (
            <Typography variant="body2" color="text.lightGray">
              {textInfo}
            </Typography>
          )}
          {textError && textError.length > 0 && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
        {showMaxLength > 0 && (
          <Typography
            sx={{
              height: '25px',

              textAlign: 'end',
              color: plainText.length > showMaxLength ? 'error.main' : 'unset',
            }}
            variant="body2"
          >
            {`${plainText.length}/${showMaxLength}`}
          </Typography>
        )}
      </Box>
    </StyledEditorBox>
  )
}

export default BasicEditor
