import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import { Column } from '../Styled'
import { StyledAssigneeImage, StyledDetailCard } from './Styled'

export const DetailCard = ({ id, fullWidth, icon, children, sx }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledDetailCard id={id} fullWidth={fullWidth} sx={sx}>
      {typeof icon === 'string' ? <img src={`/icon/${icon}`} /> : icon}
      <Typography variant={isNotSm ? 'body1' : 'body2'} sx={{ width: 'auto' }}>
        {children}
      </Typography>
    </StyledDetailCard>
  )
}

export const AssigneeContent = ({ data }) => {
  const image = _.get(data, 'image', '')
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file${image}`
  const name = _.get(data, 'name', '')
    ?.replace('นางสาว', '')
    ?.replace('นาง', '')
    ?.replace('นาย', '')
    ?.trim()
  return (
    <Box>
      <StyledAssigneeImage src={imageSrc} />
      <Column>
        <Typography variant="body1b">{name}</Typography>
        <Typography>{_.get(data, 'area.station.station', '-')}</Typography>
      </Column>
    </Box>
  )
}
