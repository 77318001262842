import _ from 'lodash'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { BoxLabel } from '.'
import { BoxFlex, SubTitle } from './Styled'

const AgentInfo = ({ status }) => {
  const information = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual,
  )
  const agentType = _.get(information, 'work.agentType', '')
  return (
    <>
      {agentType === 'A: Agent' ? (
        <>
          {status === 'Active' ? (
            <>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ประเภท Agent"
                  labelRight={_.get(information, 'work.agentType', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Level"
                  labelRight={_.get(information, 'work.level', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Position"
                  labelRight={_.get(information, 'work.position', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="วันเริ่มงาน"
                  labelRight={_.get(information, 'work.workStart', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="อายุงาน"
                  labelRight={_.get(information, 'work.workDistance', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="License No."
                  labelRight={_.get(information, 'work.licenseNo', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Reg. Effective Date"
                  labelRight={_.get(information, 'work.regEffectiveDate', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Reg. Renewal Date"
                  labelRight={_.get(information, 'work.regRenewalDate', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="สถานะการทำงาน"
                  labelRight={status}
                  status={_.get(information, 'work.status', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Unit Number"
                  labelRight={_.get(information, 'work.unitNumber', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ชื่อ Unit"
                  labelRight={_.get(information, 'work.agentUnitName', '-')}
                  isHalf
                />

                <BoxLabel
                  labelLeft="RAM Code"
                  labelRight={_.get(information, 'work.RAMCode', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="GA Code"
                  labelRight={_.get(information, 'work.gACode', '-')}
                  isHalf
                />

                <BoxLabel
                  labelLeft="Dummy Agent Flag"
                  labelRight={_.get(information, 'work.dummyAgentFlag', '-')}
                  isHalf
                />
              </BoxFlex>
            </>
          ) : (
            <>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ประเภท Agent"
                  labelRight={_.get(information, 'work.agentType', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Level"
                  labelRight={_.get(information, 'work.level', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Position"
                  labelRight={_.get(information, 'work.position', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="วันเริ่มงาน"
                  labelRight={_.get(information, 'work.workStart', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="อายุงาน"
                  labelRight={_.get(information, 'work.workDistance', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="License No."
                  labelRight={_.get(information, 'work.licenseNo', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Reg. Effective Date"
                  labelRight={_.get(information, 'work.regEffectiveDate', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Reg. Renewal Date"
                  labelRight={_.get(information, 'work.regRenewalDate', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="สถานะการทำงาน"
                  labelRight={status}
                  status={_.get(information, 'work.status', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Termination Code"
                  labelRight={_.get(information, 'work.terminationCode', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Termination Date"
                  labelRight={_.get(information, 'work.terminationDate', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Termination Description"
                  labelRight={_.get(
                    information,
                    'work.terminationDescription',
                    '-',
                  )}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Unit Number"
                  labelRight={_.get(information, 'work.unitNumber', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="ชื่อ Unit"
                  labelRight={_.get(information, 'work.agentUnitName', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="RAM Code"
                  labelRight={_.get(information, 'work.RAMCode', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="GA Code"
                  labelRight={_.get(information, 'work.gACode', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Dummy Agent Flag"
                  labelRight={_.get(information, 'work.dummyAgentFlag', '-')}
                  isHalf
                />
                <BoxLabel labelLeft="" labelRight="" isHalf />
              </BoxFlex>
            </>
          )}

          <BoxFlex>
            <SubTitle variant="body1b">Agent Unit</SubTitle>
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Agency Code"
              labelRight={_.get(information, 'work.agencyCode', '-')}
              isHalf
            />

            <BoxLabel
              labelLeft="Agency Name"
              labelRight={_.get(information, 'work.agencyName', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Assistant Branch Code"
              labelRight={_.get(information, 'work.assistantBranchCode', '-')}
              isHalf
            />

            <BoxLabel
              labelLeft="Territory Code"
              labelRight={_.get(information, 'work.territoryCode', '-')}
              isHalf
            />
          </BoxFlex>
        </>
      ) : (
        <>
          {status === 'Active' ? (
            <>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ประเภท Agent"
                  labelRight={_.get(information, 'work.agentType', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Position"
                  labelRight={_.get(information, 'work.position', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="License No."
                  labelRight={_.get(information, 'work.licenseNo', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="License Issue Date"
                  labelRight={_.get(information, 'work.licenseIssueDate', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="License Expiry Date"
                  labelRight={_.get(information, 'work.licenseExpiryDate', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="สถานะการทำงาน"
                  labelRight={status}
                  status={_.get(information, 'work.status', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Termination Code"
                  labelRight={_.get(information, 'work.terminationCode', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="สาขา"
                  labelRight={_.get(information, 'work.branch', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Unit Number"
                  labelRight={_.get(information, 'work.unitNumber', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="ชื่อ Unit"
                  labelRight={_.get(information, 'work.agentUnitName', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="พื้นที่"
                  labelRight={_.get(information, 'work.area', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="กลุ่ม"
                  labelRight={_.get(information, 'work.group', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ภูมิภาค"
                  labelRight={_.get(information, 'work.region', '-')}
                  isHalf
                />
              </BoxFlex>
            </>
          ) : (
            <>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ประเภท Agent"
                  labelRight={_.get(information, 'work.agentType', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="Position"
                  labelRight={_.get(information, 'work.position', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="License No."
                  labelRight={_.get(information, 'work.licenseNo', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="License Issue Date"
                  labelRight={_.get(information, 'work.licenseIssueDate', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="License Expiry Date"
                  labelRight={_.get(information, 'work.licenseExpiryDate', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="สถานะการทำงาน"
                  labelRight={status}
                  status={_.get(information, 'work.status', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="Termination Code"
                  labelRight={_.get(information, 'work.terminationCode', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="สาขา"
                  labelRight={_.get(information, 'work.branch', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="พื้นที่"
                  labelRight={_.get(information, 'work.area', '-')}
                  isHalf
                />
                <BoxLabel
                  labelLeft="กลุ่ม"
                  labelRight={_.get(information, 'work.group', '-')}
                  isHalf
                />
              </BoxFlex>
              <BoxFlex>
                <BoxLabel
                  labelLeft="ภูมิภาค"
                  labelRight={_.get(information, 'work.region', '-')}
                  isHalf
                />
                <BoxLabel labelLeft="" labelRight="" isHalf />
              </BoxFlex>
            </>
          )}
        </>
      )}
    </>
  )
}

export default AgentInfo
