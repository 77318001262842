import styled from '@mui/system/styled'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette?.blue?.azure,
    marginBottom: '-6px !important',
    width: 10,
    height: 6,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    marginBottom: '0 !important',
    color: theme.palette?.text?.white,
    background: theme.palette?.blue?.azure,
    boxShadow: theme.palette?.boxShadow?.menu,
  },
}))
