import _ from 'lodash'

export const handleAnswerAmount = (evaluation) => {
  const {
    setting: { displayQuestion },
    page,
    totalAssessments,
  } = evaluation
  const rawValue =
    displayQuestion == 'ALL' ? handleAnswerAmountForAll(evaluation) : page
  const percent = 100 * (rawValue / totalAssessments)
  return _.isFinite(percent) ? percent.toFixed() : 0
}

export const handleAnswerAmountForAll = (evaluation) => {
  const { courses, lecturers, courseManagements, questions } = evaluation
  const questionDone = getTotalAnswered(questions)
  const courseDone = getTotalAnswered(courses)
  const lecturerDone = getTotalAnswered(lecturers)
  const courseManagementDone = getTotalAnswered(courseManagements)
  return questionDone + courseDone + lecturerDone + courseManagementDone
}

export const getTotalAnswered = (list) =>
  list.filter((e) => e.isAnswered || e.isCompleted).length
