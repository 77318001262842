import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import styled from '@mui/system/styled'

export const CardAll = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.secondary?.main}`,
  borderLeftWidth: '8px',
  borderRadius: theme.spacing(1),
  filter: 'drop-shadow(0px 12px 40px rgba(73, 118, 186, 0.1))',
  background:
    'linear-gradient(274.82deg, rgba(255, 198, 189, 0.35) 0%, rgba(255, 255, 255, 0) 100%)',
  width: '50%',
  padding: `${theme.spacing(1.5)}`,
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const CardVirtual = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.other?.blueCard}`,
  borderLeft: `8px solid ${theme?.palette?.other?.blueBorder}`,
  borderRadius: theme.spacing(1),
  filter: 'drop-shadow(0px 12px 40px rgba(73, 118, 186, 0.1))',
  background:
    'linear-gradient(274.82deg, rgba(219, 228, 241, 0.6) 0%, rgba(255, 255, 255, 0) 100%)',
  width: '50%',
  padding: `${theme.spacing(1.5)}`,
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const CardMyTask = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.other?.greenCard}`,
  borderLeft: `8px solid ${theme?.palette?.other?.greenBorder}`,
  borderRadius: theme.spacing(1),
  filter: 'drop-shadow(0px 12px 40px rgba(73, 118, 186, 0.1))',
  background:
    'linear-gradient(274.82deg, #E8F5E9 0%, rgba(255, 255, 255, 0) 100%)',
  width: '50%',
  padding: `${theme.spacing(1.5)}`,
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const Container = styled(Box)(({ theme }) => ({
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const RadioAll = styled(Radio)(({ theme }) => ({
  color: theme?.palette?.secondary?.main,
  '&.Mui-checked': {
    color: theme?.palette?.secondary?.main,
  },
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(3),
}))

export const RadioVirtual = styled(Radio)(({ theme }) => ({
  color: theme?.palette?.other?.blueBorder,
  '&.Mui-checked': {
    color: theme?.palette?.other?.blueBorder,
  },
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(3),
}))

export const RadioMyTask = styled(Radio)(({ theme }) => ({
  color: theme?.palette?.other?.greenBorder,
  '&.Mui-checked': {
    color: theme?.palette?.other?.greenBorder,
  },
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(3),
}))

export const BoxHighLevel = styled(Box)(({ theme }) => ({
  background: theme?.palette?.info?.main,
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  verticalAlign: 'center',
  color: theme?.palette?.secondary?.contrast,
  fontSize: '17px',
  height: theme.spacing(3),
  width: theme.spacing(7),
}))
