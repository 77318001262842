import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Button from '@mui/material/Button'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { COURSE_STATUS } from '../../../../../constants/eLearning'
import { breadcrumbCreate, breadcrumbEdit } from '../../model'
import { FORM_TAB } from '../model'
import { getStateValue } from '../events'
import {
  checkScrollWidth,
  handlePreview,
  handleSubmitBody,
  handleSubmitBodyDraft,
} from './events'
import { BoxButton, BoxHeader, BoxLabelTitle, StyledViewTitle } from './Styled'

const Header = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [, setELearningPreview] = useLocalStorage('learningPathPreview')
  const [isScrolled, setScroll] = useState(false)
  const { tab, name, status, uploadError } = useSelector(
    (state) => ({
      tab: state.eLearningPathForm.tab,
      name: getStateValue('name'),
      status: getStateValue('status'),
      uploadError: state.eLearningPathForm.uploadError,
    }),
    shallowEqual,
  )
  const { setValues, submitForm } = useFormikContext()
  const isDraft = _.isEmpty(uuid) || status === COURSE_STATUS.DRAFT

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle isEdit={uuid} isDraft={status === COURSE_STATUS.DRAFT}>
        <StyledViewTitle variant="h4">
          {uuid
            ? `แก้ไข Learning path (เส้นทางการเรียนรู้): ${name}`
            : 'สร้าง Learning path (เส้นทางการเรียนรู้)'}
        </StyledViewTitle>
        {!isScrolled && (
          <Breadcrumbs menuList={uuid ? breadcrumbEdit : breadcrumbCreate} />
        )}
      </BoxLabelTitle>
      <BoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          size="m"
          startIcon={<RemoveRedEye />}
          disabled={isDraft}
          onClick={() => handlePreview(setELearningPreview)}
        >
          ดูตัวอย่าง
        </Button>

        {isDraft && (
          <Button
            type="submit"
            data-testid="btn-draft"
            variant="outlined"
            size="m"
            disabled={!_.isEmpty(uploadError)}
            onClick={() => dispatch(handleSubmitBodyDraft(setValues))}
          >
            บันทึกฉบับร่าง
          </Button>
        )}
        <Button
          type={tab === FORM_TAB.batch ? 'button' : 'submit'}
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={!_.isEmpty(uploadError)}
          onClick={async () => {
            await dispatch(handleSubmitBody(setValues))
            submitForm()
          }}
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}
export default Header
