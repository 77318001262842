import React, { useEffect } from 'react'
import { StyledContainer, StyledContent } from './Styled'
import { Box, Typography } from '@mui/material'
import { breadcrumbReportCourseSettingDashboard } from './model'
import Breadcrumbs from '../../components/Breadcrumbs'
import DashboardSection from './components/DashboardSection'
import ListCourseSection from './components/ListCourseSection'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { fetchReportCourseSettingDashboard } from './event'
import { Loading } from './components/Loading'

const Index = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.reportCourseSettingDashboard.isLoading,
    }),
    shallowEqual,
  )

  useEffect(() => dispatch(fetchReportCourseSettingDashboard()), [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Dashboard Course Setting</Typography>
          <Breadcrumbs menuList={breadcrumbReportCourseSettingDashboard()} />
        </Box>
        <DashboardSection />
        <ListCourseSection />
      </StyledContent>
      <Loading isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
