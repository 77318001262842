import _ from 'lodash'

export const handleOnlineRoomNo = (data) => {
  return _.get(data, 'monthlyPlan.dates', []).map((date) => ({
    ...date,
    onlineRoom: _.get(date, 'onlineRoom').map((room, index) => ({
      ...room,
      no: index + 1,
    })),
  }))
}
