import { store } from '../../../../App'
import _ from 'lodash'
import { BGM_STATUS } from '../../../../constants/budgetManagement/budgetManagement'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    yearBudget: _.get(filterProp, 'yearBudget', 0),
    costCenter: _.get(filterProp, 'costCenter', ''),
    costCenterName: _.get(filterProp, 'costCenterName', ''),
    oldAccountCode: _.get(filterProp, 'oldAccountCode', ''),
    newAccountCode: _.get(filterProp, 'newAccountCode', ''),
    categoryAccountCode: _.get(filterProp, 'categoryAccountCode', ''),
    createdAt: _.get(filterProp, 'createdAt', {}),
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedAt: _.get(filterProp, 'updatedAt', {}),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    status: _.get(filterProp, 'status', [
      BGM_STATUS.ACTIVE,
      BGM_STATUS.INACTIVE,
      BGM_STATUS.DELETED,
    ]),
  }
}
