import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { filterELearningDashboardByCourse } from '../../../../services/eLearning/course/dashboard'
import _ from 'lodash'
import {
  E_LEARNING_COURSE_STATUS,
  E_LEARNING_LEARNER_TYPE,
} from '../../../../constants/eLearning'
import { current } from 'immer'
import { MODULE_TYPE_EN } from '../../../../modules/ELearning/Module/View/Content/model'

const ELearningDashboardByCourse = createSlice({
  name: 'eLearningDashboardByCourse',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenLearnerDrawer: (state, { payload }) => {
      state.isOpenLearnerDrawer = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setStatusLearnerDrawer: (state, { payload }) => {
      state.statusLearnerDrawer = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload?.key] = payload?.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        filterELearningDashboardByCourse.fulfilled,
        (state, { payload }) => {
          const { learnerStatusByType } = current(state)

          const {
            totalLearner = 0,
            notStart: notStarted = 0,
            inProgress,
            complete: completed = 0,
            totalModule = 0,
            resultModule = [],
            agentLearner = {},
            bancaLearner = {},
            ktbLearner = {},
            otherLearner = {},
          } = payload

          state.totalLearner = totalLearner
          state.inProgress = inProgress
          state.notStarted = notStarted
          state.completed = completed
          state.totalModule = totalModule
          const resultModules = _.map(resultModule, (res, idx) => {
            return {
              ...res,
              no: idx + 1,
              moduleName: _.get(res, 'name'),
              moduleType: MODULE_TYPE_EN[_.get(res, 'moduleType')],
            }
          })

          state.resultModule = resultModules
          const newLearnerStatusByType = _.map(learnerStatusByType, (item) => {
            const { type, progress } = item
            let learnerData = {}
            switch (type) {
              case E_LEARNING_LEARNER_TYPE.AGENT:
                learnerData = agentLearner
                break
              case E_LEARNING_LEARNER_TYPE.BANCA:
                learnerData = bancaLearner
                break
              case E_LEARNING_LEARNER_TYPE.KTB:
                learnerData = ktbLearner
                break
              case E_LEARNING_LEARNER_TYPE.OTHER:
                learnerData = otherLearner
                break
            }
            const totalLearner = _.get(learnerData, 'learner', 0)
            const newProgress = _.map(progress, (pg) => {
              const { status } = pg
              let count = 0
              switch (status) {
                case E_LEARNING_COURSE_STATUS.NOT_STARTED:
                  count = _.get(learnerData, 'status.notStart')
                  break
                case E_LEARNING_COURSE_STATUS.INPROGRESS:
                  count = _.get(learnerData, 'status.inProgress')
                  break
                case E_LEARNING_COURSE_STATUS.COMPLETED:
                  count = _.get(learnerData, 'status.complete')
                  break
              }
              return {
                ...pg,
                count: count,
                percent: totalLearner > 0 ? (count / totalLearner) * 100 : 0,
              }
            })
            return {
              ...item,
              totalCount: totalLearner,
              progress: newProgress,
            }
          })
          state.learnerStatusByType = newLearnerStatusByType
        },
      )
      .addCase(filterELearningDashboardByCourse.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setIsOpenFilterDrawer,
  setIsOpenLearnerDrawer,
  setFilter,
  setStatusLearnerDrawer,
  setReduxValue,
} = ELearningDashboardByCourse.actions

export default ELearningDashboardByCourse.reducer
