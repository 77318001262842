import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const HeaderSection = styled(Box)(({ headerStyle, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: headerStyle ? 'fixed' : '',
  top: '60px',
  left: 0,
  width: '100%',
  padding: headerStyle ? '20px 25px 20px 70px' : '',
  backgroundColor: headerStyle ? theme.palette?.background?.blue : '',
  transform: headerStyle ? `translateY(5%)` : `translateY(0%)`,
  transition: `transform 0.5s ease-out`,
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
    padding: headerStyle ? '25px' : '0px 5px 0px 0px',
  },
}))
