import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'

import { approveStatus } from '../../../constants/monthlyPlan/speaker'
import Dot from '../../../components/Table/Dot'
import { convertFormatDateTime, getStatusText } from '../../../utils/lib'
import { themeColor } from '../../../utils/themeData'
import ViewDrawer from '../../../modules/Speaker/ViewDrawer'
import { convertTime } from '../Drawer/View/Index'
import BorrowDrawer from '../Drawer/BorrowDrawer'
import { handleDeleteSavedData } from './events'

export const findNameStation = (listStation, valueStation) => {
  let result = {}
  if (listStation.length) {
    result = listStation.find((item) => {
      return item.value === valueStation
    })
  }
  return result ? _.get(result, 'label', '') : ''
}

export const Action = (props) => {
  const {
    data,
    borrowData,
    rowIndex,
    handleClickOpen,
    areaUuid,
    listResponsibility,
    setStateForm,
    field,
    setOpenDetailDrawer,
    speakerBorrows,
  } = props
  const [openBorrowDrawer, setOpenBorrowDrawer] = useState(false)
  const dispatch = useDispatch()
  return (
    <Box
      sx={{
        minWidth: 120,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'flex-end',
      }}
    >
      {data?.apiType && data.status === 'WAITING' && (
        <Typography
          variant="body1"
          sx={{ color: themeColor.error.main, cursor: 'pointer' }}
          onClick={() => handleClickOpen(data, 'cancel', rowIndex)}
        >
          ยกเลิกคำขอ
        </Typography>
      )}

      {!data.apiType && (data.status === 'DRAFT' || data.status === 'WAITING') && (
        <>
          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => setOpenBorrowDrawer(true)}
          >
            <EditTwoToneIcon color="action" />
          </IconButton>
        </>
      )}
      <BorrowDrawer
        field={field}
        open={openBorrowDrawer}
        onClose={() => setOpenBorrowDrawer(false)}
        data={data}
        borrowData={borrowData}
        areaUuid={areaUuid}
        listResponsibility={listResponsibility}
        setStateForm={setStateForm}
        editIndex={rowIndex}
        speakerBorrows={speakerBorrows}
      />
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => setOpenDetailDrawer(true)}
      >
        <RemoveRedEyeTwoToneIcon color="action" />
      </IconButton>

      {!data.apiType && (data.status === 'DRAFT' || data.status === 'WAITING') && (
        <>
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => {
              if (data.uuid) {
                dispatch(
                  handleDeleteSavedData({ setStateForm, field, rowIndex, data })
                )
              } else {
                handleClickOpen(data, 'delete', rowIndex)
              }
            }}
          >
            <DeleteTwoToneIcon color="action" />
          </IconButton>
        </>
      )}
    </Box>
  )
}

export const BoxSpeaker = (props) => {
  const {
    stateForm,
    item,
    borrowData,
    index,
    handleClickOpen,
    setLoadingDrawer,
    areaUuid,
    listResponsibility,
    setStateForm,
    field,
    speakerBorrows,
  } = props
  if (_.isEmpty(item)) return <></>

  const [openDetailDrawer, setOpenDetailDrawer] = useState(false)

  const approver = `${_.get(item, 'approver.firstNameTH', '-')} ${
    _.get(item, 'approver.lastNameTH', '') || ''
  }`

  const DepartmentAndStation = item?.department?.label
    ? `${_.get(item, 'department.label', '')}`
    : `(${_.get(item, 'approverDepartment.initial', '')}) ${_.get(
        item,
        'approverDepartment.department',
        ''
      )}`

  const displayDate = convertFormatDateTime({
    value: item.startDate,
    type: 'date',
  })

  const displayDateEnd = convertFormatDateTime({
    value: item.finishDate,
    type: 'date',
  })

  return (
    <Box sx={{ mb: 1 }}>
      <Box
        sx={{ border: `1px solid ${themeColor.silver.primary}`, width: '100%' }}
      >
        <Box sx={{ mx: 2, my: 1 }}>
          <Box
            sx={{
              display: 'flex',
              my: 0.5,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Dot
                sx={{ mt: 0, mr: 1 }}
                status={!item.apiType ? 'draft' : item.status}
              />
              <Typography variant="body1b">
                {getStatusText(
                  !item.apiType && item.status === 'WAITING'
                    ? 'REQUEST'
                    : item.status
                )}{' '}
                <Typography variant="span" sx={{ fontWeight: 400 }}>
                  วันที่อบรม: {displayDate}{' '}
                  {stateForm.typeDate === 'DUPLICATE' &&
                    item.startDate !== item.finishDate &&
                    `- ${displayDateEnd}`}
                </Typography>
              </Typography>
            </Box>
            <Action
              data={item}
              borrowData={borrowData}
              rowIndex={index}
              handleClickOpen={handleClickOpen}
              setLoadingDrawer={setLoadingDrawer}
              areaUuid={areaUuid}
              listResponsibility={listResponsibility}
              setStateForm={setStateForm}
              field={field}
              setOpenDetailDrawer={setOpenDetailDrawer}
              speakerBorrows={speakerBorrows}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Typography variant="body1b">Department & Station</Typography>
              <Typography variant="body1">{DepartmentAndStation}</Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant="body1b">ผู้อนุมัติ</Typography>
              <Typography variant="body1">{approver}</Typography>
            </Box>
          </Box>
          {_.get(item, 'speakerBorrowResponsibility', [])
            .filter((speaker) => speaker.status !== approveStatus.DELETED)
            .map((speaker, index) => {
              const isDecided =
                item.status === approveStatus.APPROVE ||
                item.status === approveStatus.REJECT
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: isDecided ? 3 : 0,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ gap: 1, display: 'flex' }}>
                    <Typography
                      variant="body1b"
                      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    >
                      {isDecided && <Dot status={speaker.status} />}
                      หน้าที่
                    </Typography>
                    <Typography variant="body1" sx={{ minWidth: 120 }}>
                      {_.get(
                        speaker,
                        'responsibility.label',
                        _.get(speaker, 'responsibility.responsibility', '')
                      )}
                    </Typography>
                    <Typography variant="body1b">จำนวน</Typography>
                    <Typography variant="body1">
                      {_.get(speaker, 'numSpeaker', '')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography variant="body1b">เวลา</Typography>
                    <Typography variant="body1">
                      {convertTime(speaker.startTime) +
                        ' - ' +
                        convertTime(speaker.endTime)}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Box>

      <ViewDrawer
        open={openDetailDrawer}
        detail={item}
        stateForm={field === 'academyActivity' ? stateForm[field] : stateForm}
        onClose={() => setOpenDetailDrawer(false)}
        speakerType="APPROVER"
      />
    </Box>
  )
}

export default function Index(props) {
  const {
    stateForm,
    borrowData,
    listSpeakerBorrow,
    handleClickOpen,
    setLoadingDrawer,
    areaUuid,
    listResponsibility,
    setStateForm,
    field,
    speakerBorrows,
  } = props
  if (listSpeakerBorrow.length) {
    return listSpeakerBorrow.map((itemObj, index) => {
      const status = _.get(itemObj, 'status', '')
      if (status === approveStatus.DELETED) return <></>
      if (status === 'DRAFT') {
        itemObj = { ...itemObj, apiType: false }
      }

      return (
        <BoxSpeaker
          key={index}
          stateForm={stateForm}
          item={itemObj}
          borrowData={borrowData}
          index={index}
          handleClickOpen={handleClickOpen}
          setLoadingDrawer={setLoadingDrawer}
          areaUuid={areaUuid}
          listResponsibility={listResponsibility}
          setStateForm={setStateForm}
          field={field}
          speakerBorrows={speakerBorrows}
        />
      )
    })
  } else {
    return <></>
  }
}
