import { store } from '../../../../../../../App'
import _ from 'lodash'
import { setStatusLearnerDrawer } from '../../../../../../../redux/slices/eLearning/dashboardByCourse'

export const handleChange = ({ key, value }) => {
  const { statusLearnerDrawer } = store.getState().eLearningDashboardByCourse

  const changeStatusLearner = _.map(statusLearnerDrawer, (st) => {
    if (st.status === key) return { ...st, checked: value }
    return st
  })
  store.dispatch(setStatusLearnerDrawer(changeStatusLearner))
}
