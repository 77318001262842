import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  return [
    {
      id: 'status',
      label: 'สถานะ',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'yearBudget',
      label: 'ปี Budget',
      disablePadding: false,
    },
    {
      id: 'costCenter',
      label: 'Cost center',
      disablePadding: false,
    },
    {
      id: 'costCenterName',
      label: 'Cost center name',
      disablePadding: false,
    },
    {
      id: 'oldAccountCode',
      label: 'Account code (Old)',
      disablePadding: false,
    },
    {
      id: 'newAccountCode',
      label: 'Account code (New)',
      disablePadding: false,
    },
    {
      id: 'accCategory',
      label: 'Categories',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
      editPath: `${crudConfig.modulePath}/form`,
    },
  ]
}
