import { COURSE_STATUS } from '../../../../../../constants/eLearning'

export const onGoingStatus = [
  COURSE_STATUS.INPROGRESS,
  COURSE_STATUS.SUCCESS,
  COURSE_STATUS.COMPLETED,
]

export const registerStatus = [
  COURSE_STATUS.OPENREGISTRATION,
  COURSE_STATUS.CLOSEREGISTRATION,
]
