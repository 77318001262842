import { store } from '../../../../../../../App'

export const mutateFilterSateToProp = (versionUuid) => {
  const { filterState } = store.getState().table

  return {
    eTestVersionUuid: versionUuid,
    courseCode: filterState.courseCodeChecked ? filterState.courseCode : '',
    userProfileUuid: filterState.userProfileUuidChecked
      ? filterState.userProfileUuid
      : '',
    classUuid: filterState.classUuidChecked ? filterState.classUuid : '',
    distribution: filterState.distributionChecked
      ? filterState.distribution
      : '',
    startDate: filterState.startDateChecked ? filterState.startDate : '',
    endDate: filterState.startDateChecked ? filterState.endDate : '',
    startTestDate: filterState.startTestDateChecked
      ? filterState.startTestDate
      : '',
    endTestDate: filterState.startTestDateChecked
      ? filterState.endTestDate
      : '',
    unitCode: filterState.unitCodeChecked ? filterState.unitCode : '',
    ramCode: filterState.ramCodeChecked ? filterState.ramCode : '',
    trainer: filterState.trainerChecked ? filterState.trainer : '',
  }
}
