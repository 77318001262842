import useTheme from '@mui/system/useTheme'
import { Box, IconButton, Typography } from '@mui/material'

import { StyledRow } from '../../../Styled'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

export const CarHeader = (props) => {
  const theme = useTheme()
  const { no, title, isShowContent = true, setIsShowContent = () => {} } = props

  return (
    <StyledRow sx={{ gap: 1.5 }}>
      <Box
        sx={{
          background: theme.palette?.primary?.main,
          borderRadius: '50%',
          width: 30,
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1b" color="text.white">
          {no}
        </Typography>
      </Box>
      <Typography variant="h6">{title}</Typography>
      <IconButton
        sx={{ p: 0, marginLeft: 'auto' }}
        data-testid={`btn-close-drawer`}
        component="span"
        onClick={() => setIsShowContent((prev) => !prev)}
      >
        {isShowContent ? (
          <KeyboardArrowUp color="action.active" />
        ) : (
          <KeyboardArrowDown color="action.active" />
        )}
      </IconButton>
    </StyledRow>
  )
}
