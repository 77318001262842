import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../../redux/slices/dialog'
import _, { get } from 'lodash'
import appHistory from '../../../../../appHistory'
import { store } from '../../../../../App'
import { callAPI } from '../../../../../utils/lib'
import {
  displayClientError,
  displayServerError,
} from '../../../../../components/CRUD/handler/handlerFetchError'
import { requisitionTicket } from '../../../../../utils/apiPath'
import { TicketStatus } from '../../../../../constants/stock'

export const handleProcessSubmitForm = async (
  uuid,
  postBodyMutation,
  isDraft,
) => {
  startDialogLoading()

  try {
    const response = await submitFormData(uuid, isDraft)

    handleSuccessfulSubmission(response, isDraft)
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const submitFormData = async (uuid, isDraft = false) => {
  const { formData } = store.getState().crud
  const url = requisitionTicket
  return await callAPI({
    method: _.isEmpty(uuid) ? 'post' : 'put',
    url,
    body: {
      ...formData,
      station: _.get(formData, 'courseSelected.uuid'),
      responsibleUser: _.get(formData, 'responsibleUser.uuid'),
      monthlyPlanUuid: _.get(formData, 'courseSelected.monthlyPlanUuid'),
      status: isDraft ? TicketStatus.DRAFT : '',
      warehouseUuid: _.get(formData, 'warehouse.value', ''),
      responsibility: _.get(formData, 'responsibleUser.value', ''),
    },
  })
}

const handleSuccessfulSubmission = (response, isDraft) => {
  const uuid = get(response, '[0]', '')

  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => navigateToViewPath(uuid, isDraft),
    }),
  )
}

export const handleFormSubmissionError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      handleBadRequestError(error.response)
    } else {
      displayServerError()
    }
  } else {
    displayClientError()
  }
}

const handleBadRequestError = (response) => {
  const errorData = get(response, 'data', '')

  store.dispatch(
    openErrorDialog({
      message: errorData.message.replace('Error :', '').trim(),
      title: 'ผิดพลาด',
      isCloseDialog: false,
    }),
  )
}

const navigateToViewPath = (uuid, isDraft) => {
  const { crudConfig } = store.getState().crud
  const viewPath = isDraft
    ? `${crudConfig.modulePath}`
    : `${crudConfig.modulePath}/view/${uuid}`
  appHistory.push(viewPath)
  store.dispatch(closeDialog())
}
