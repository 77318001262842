import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const BoxContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}))

export const AlertTooltip = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}))

export const IconAlert = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme?.palette?.warning?.main,
  cursor: 'pointer',
}))
