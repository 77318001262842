import _ from 'lodash'
import { getStockDetail } from '../../../../services/inventory'
import {
  setStockDetail,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/inventory/stock/list'
import { setFieldValue } from '../../../../redux/slices/crud'

export const fetchStockDetail = (uuid) => async (dispatch) => {
  dispatch(setFieldValue({ key: 'isFetchProductDetail', value: false }))
  dispatch(startLoading())
  const response = await dispatch(getStockDetail({ uuid }))
  const results = _.get(response, 'payload.data', [])
  dispatch(setStockDetail(results))
  dispatch(stopLoading())
  dispatch(setFieldValue({ key: 'isFetchProductDetail', value: true }))
}
