import React, { useEffect, useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { configTabs } from '../model'
import { setTab, setIsInit, setIsLoading } from '../../../redux/slices/eConfig'
import { useFormik } from 'formik'

import Header from '../Header'
import GeneralForm from './GeneralForm'
import ELearningForm from './ELearningForm'
import EExamForm from './EExamForm'
import TermsForm from './TermsForm'
import HistoryDrawer from './HistoryDrawer'
import DetailDrawyer from './DetailDrawyer'
import { handleChangeInput, handleConfirmConfig } from './event'
import { setInitialForm } from '../../../redux/slices/eConfig'
import { initialState } from '../../../redux/slices/eConfig/model'
import { validationSchema } from '../../../constants/eConfig'
import { DialogContext } from '../../../context/dialog-context'
import { openSuccessDialog } from '../../../redux/slices/dialog'
import { getEConfiguration } from '../../../services/eConfiguration'
import { callAPI } from '../../../utils/lib'
import { eConfigurationHistory } from '../../../utils/apiPath'

const Content = () => {
  const dispatch = useDispatch()
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [isDetailDrawer, setIsDetailDrawer] = useState(false)
  const [historyData, setHistoryData] = useState({})
  const { tab, body, isInit } = useSelector(
    (state) => ({
      tab: state.eConfiguration.tab,
      body: state.eConfiguration.body,
      isInit: state.eConfiguration.isInit,
    }),
    shallowEqual,
  )

  const formik = useFormik({
    initialValues: initialState.body,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        title: 'ยืนยันบันทึก',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        isLoading: false,
        onConfirmClick: () => {
          dispatch(
            handleConfirmConfig({
              values,
              setDialog,
              openSuccessDialog,
              formik,
              fetchHistory,
              fetchEConfig,
            }),
          )
        },
        onCancelClick: () => {
          setDialog({
            variant: 'save',
            content: '',
            open: false,
            isLoading: false,
          })
        },
      })
    },
  })

  const setInputValue = (key, value) => {
    formik.setFieldValue(key, value)
    dispatch(handleChangeInput(key, value))
  }

  useEffect(() => {
    dispatch(setIsInit(true))
    dispatch(setIsLoading(true))
    dispatch(setInitialForm(initialState.body))
    fetchEConfig()
  }, [])

  useEffect(() => {
    fetchHistory()
  }, [limit, page])

  const fetchEConfig = async () => {
    dispatch(setIsLoading(true))
    dispatch(getEConfiguration())
  }

  useEffect(() => {
    if (isInit) {
      dispatch(setIsInit(false))
      formik.setValues(body, false)
    }
  }, [body])

  const fetchHistory = async () => {
    const realPage = page === 0 ? 1 : page + 1
    const body = {
      limit: limit,
      page: realPage,
    }
    await callAPI({
      url: eConfigurationHistory,
      method: 'POST',
      body,
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  const getDetail = (historyId) => {
    setIsDetailDrawer(true)
    const selectedHistory = historyList.find((item) => item.id === historyId)
    setHistoryData(selectedHistory)
  }

  return (
    <>
      <Header formik={formik} setIsHistoryDrawer={setIsHistoryDrawer} />
      <Box>
        {configTabs.map((configTab, index) => {
          const keyTab = configTab.key
          return (
            <Button
              key={index}
              sx={{ height: 40, p: 1, textTransform: 'none' }}
              onClick={() => {
                dispatch(setTab(keyTab))
              }}
            >
              <Typography
                variant="body1b"
                sx={
                  tab === keyTab
                    ? { borderBottom: 4, px: 3 }
                    : { color: '#A9B8CD' }
                }
              >
                {configTab.label}
              </Typography>
            </Button>
          )
        })}
      </Box>
      <GeneralForm
        formik={formik}
        body={body}
        setInputValue={setInputValue}
        isHide={tab !== 'GENERAL'}
      />
      <ELearningForm
        formik={formik}
        body={body}
        setInputValue={setInputValue}
        isHide={tab !== 'E_LEARNING'}
      />
      <EExamForm
        formik={formik}
        body={body}
        setInputValue={setInputValue}
        isHide={tab !== 'EEXAM'}
      />
      <TermsForm
        formik={formik}
        body={body}
        setInputValue={setInputValue}
        isHide={tab !== 'TERMS'}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        allCount={allCount}
        getDetail={getDetail}
      />
      <DetailDrawyer
        open={isDetailDrawer}
        onClose={() => setIsDetailDrawer(false)}
        historyData={historyData}
      />
    </>
  )
}

export default Content
