export const levelOfLearner = '/levelOfLearner'
export const filterLevelOfLearner = '/levelOfLearner/filter'
export const downloadLevelOfLearner = '/levelOfLearner/download'
export const historyLevelOfLearner = '/levelOfLearner/history'
//distribution
export const distributionChannel = '/distributionChannel'
export const distributionFilter = '/distributionChannel/filter'
export const distributionDownload = '/distributionChannel/download'
export const distributionHistory = '/distributionChannel/history'

// product type
export const productType = '/productType'
export const filterProductType = '/productType/filter'
export const downloadProductType = '/productType/download'
export const historyProductType = '/productType/history'

// road map
export const roadMap = '/roadmap'
export const filterRoadMap = '/roadmap/filter'
export const downloadRoadMap = '/roadmap/download'
export const historyRoadMap = '/roadmap/history'
export const roadMapOrder = '/roadmap/order'

// training platform
export const trainingPlatform = '/trainingplatform'
export const filterTrainingPlatform = '/trainingplatform/filter'
export const downloadTrainingPlatform = '/trainingplatform/download'
export const historyTrainingPlatform = '/trainingplatform/history'

// acquired skill
export const acquiredSkill = '/acquiredskill'
export const filterAcquiredSkill = '/acquiredskill/filter'
export const downloadAcquiredSkill = '/acquiredskill/download'
export const historyAcquiredSkill = '/acquiredskill/history'

//level
export const filterLevel = '/level/filter'
export const historyLevel = '/level/history'
export const level = '/level'
export const downloadLevel = '/level/download'

// skill type
export const skillType = '/skilltype'
export const filterSkillType = '/skilltype/filter'
export const downloadSkillType = '/skilltype/download'
export const historySkillType = '/skilltype/history'

//course
export const course = '/course'
export const filterCourse = '/course/filter'
export const downloadCourse = '/course/download'
export const filterVersion = '/course/version/filter'
export const downloadVersion = '/course/version/download'
export const courseVersion = '/course/version'
export const coursePeriod = '/course/period'
export const filterLicense = '/license/filter'
export const courseDraft = '/course/draft'

//file
export const uploadFile = '/file'
export const uploadFileCourse = '/file/course'
export const uploadFileProfile = '/file/profile'
export const uploadFileCourseVersion = '/file/course/document'
export const uploadFileRoadmap = '/file/roadMap'
export const uploadFileCatalog = '/file/catalog'
export const uploadFileSettingPoint = '/file/setting-point'
export const uploadFileLearnerDocument = '/file/learner-document'

//monthy-plan
export const monthlyPlanHistory = '/monthlyPlan/history'
export const monthlyPlanFilter = '/monthlyPlan/filter'
export const academyActivity = '/activity/filter'
export const leaveType = '/leavetype/filter'
export const monthlyPlanArea = '/monthlyPlan/area'
export const monthlyPlanWorkload = '/monthlyPlan/workload'
export const monthlyPlanPersonal = '/monthlyPlan/personal/filter/all'
export const monthlyPlanFilterStaff = '/monthlyPlan/staff/filter'

export const monthlyPlanHoliday = '/monthlyPlan/holiday'
export const monthlyPlanLeave = '/monthlyPlan/leave'
export const monthlyPlanTraining = '/monthlyPlan/training'
export const monthlyPlanTrainingOther = '/monthlyPlan/trainingother'
export const monthlyPlanHeadZone = '/monthlyPlan/hz'
export const monthlyPlanSpeakerBorrowCancel =
  '/monthlyPlan/speakerborrow/cancel'
export const monthlyPlanCostApproveHistory = '/monthlyPlan/costApprove/history'
export const monthlyPlanCostApproveDownload =
  '/monthlyPlan/costApprove/download'
export const monthlyPlanCostApprove = '/monthlyPlan/costApprove/list'
export const monthlyPlanCostApproveStatus = '/monthlyPlan/costApprove/status'
export const monthlyPlanMasterPlan = '/monthlyPlan/masterplan'
export const monthlyPlanMasterPlanStatus = '/monthlyPlan/masterplan/status'
export const monthlyPlanChangeSpeaker = '/monthlyPlan/changeSpeaker'
export const monthlyPlanChangeSpeakerHistory =
  '/monthlyPlan/changeSpeakerHistory'
export const monthlyPlanFilterActivity = '/report/filter/monthly-plan/activity'
export const monthlyPlanFilterTopList = '/report/filter/monthly-plan/top-list'
export const monthlyPlanFilterSummaryWorkload =
  '/report/filter/monthly-plan/summary-workload'
export const monthlyPlanFilterSummaryFirstView =
  '/report/filter/monthly-plan/summary-workload/first-view'
export const monthlyPlanFilterSummarySecondView =
  '/report/filter/monthly-plan/summary-workload/second-view'
export const monthlyPlanDownloadSummary =
  '/report/download/monthly-plan/summary-workload'
export const monthlyPlanDownloadStaff = '/report/download/monthly-plan/top-list'

export const academyMeeting = '/monthlyPlan/academy/meeting'
export const academyOneOnOne = '/monthlyPlan/academy/oneonone'
export const academyWorkOffice = '/monthlyPlan/academy/workOffice'
export const academyTravelling = '/monthlyPlan/academy/travelling'
export const academyTrainTheTrainer = '/monthlyPlan/academy/trainthetrainer'
export const academyCertified = '/monthlyPlan/academy/certified'
export const academyStaffTraining = '/monthlyPlan/academy/StaffTraining'
export const academyZone = '/department/filter'
export const department = '/department/filter'
export const departmentAll = '/department/all'

export const responsibilityFilter = '/responsibility/filter'
export const classroomFilter = '/classroom/filter'
export const distributionChannelFilter = '/distributionChannel/filter'

export const overrideBudget = '/flow/considerBudget/overrideBudget'
export const originalBudget = '/flow/considerBudget/originalBudget'
export const masterPlanRecall = '/flow/masterPlan/recall'
export const masterPlanReject = '/flow/masterPlan/reject'
export const masterPlanApprove = '/flow/masterPlan/approve'

// staffprofile
export const staffProfile = '/staffprofile'
export const filterStaffProfile = '/staffprofile/filter'
export const filterNonAcademyProfile = '/staffprofile/non-acdemy/filter'
export const staffProfileInArea = '/staffprofile/area'
export const staffProfileInAreaDetail = '/staffprofile/area/detail'
export const historyStaffProfile = '/staffprofile/history'
export const downloadStaffProfile = '/staffprofile/download'
export const staffProfileArea = '/staffprofile/staffinarea'
export const staffProfileDashboard = '/staffprofile/dashboard'
export const staffProfileDashboardLoginHistory =
  '/staffprofile/dashboard/login-history'

// meeting room
export const filterMeetingRoom = '/meetingroom/filter'

// worktype
export const workType = '/worktype/filter'

// subWorktype
export const subWorkType = '/subWorktype'

// speaker borrow
export const filterSpeakerBorrow = '/monthlyPlan/speakerborrow/filter'
export const speakerBorrow = '/monthlyPlan/speakerborrow'
export const cancelSpeakerBorrow = '/monthlyPlan/speakerborrow/cancel'
export const statusSpeakerBorrow = '/monthlyPlan/speakerborrow/status'

//permission
export const permission = '/permission'
export const permissionGroup = '/permissionGroup'
export const downloadPermissionGroup = '/permissionGroup/download'
export const filterPermissionGroup = '/permissionGroup/filter'
export const historyPermissionGroup = '/permissionGroup/history'

export const filterRole = '/role/filter'
export const filterArea = '/area/filter'

export const filterCostCenter = '/costCenter/filter'
export const filterExperienced = '/experienced/filter'
export const filterInstitute = '/institute/filter'

// trainingPlan
export const trainingPlan = '/trainingPlan'
export const trainingPlanFilter = '/trainingPlan/filter'
export const trainingPlanStaffTo = '/trainingPlan/StaffTo'
export const trainingPlanExternalSpeaker = '/trainingPlan/externalSpeaker'
export const trainingPlanAdminStaff = '/trainingPlan/AdminStaff'
export const trainingPlanHistory = '/trainingPlan/history'
export const trainingPlanCalendar = '/trainingPlan/calendar'
export const trainingPlanArea = '/trainingPlan/area'
export const trainingPlanDownload = '/trainingPlan/download'
export const trainingPlanGetQRForEnroll = '/trainingPlan/qr-code-for-enroll'
export const trainingPlanSaveQRForEnroll =
  '/trainingPlan/save-qr-code-for-enroll-class'

// trainingPlan
export const countSpeakerBorrow = '/monthlyPlan/speakerborrow/count'

// eTesting
export const eTesting = '/e-testing'
export const eTestingFilter = '/e-testing/filter'
export const eTestingDownload = '/e-testing/download'
export const eTestingVersionFilter = '/e-testing/version/filter'
export const eTestingVersionDownload = '/e-testing/version/download'
export const eTestingVersion = '/e-testing/version'
export const eTestingUploadFile = '/file/e-testing'
export const eTestingDownloadTemplate =
  '/file/e-testing/Template_E_Testing.xlsx'
export const eTestingHistory = '/e-testing/history'
export const eTestingList = '/e-testing/list'
export const eTestingTypeList = '/e-testing/typelist'
export const eTestingAssignees = '/e-testing/assignees'
export const eTestingDownloadResult = '/e-testing/result/download'
export const eTestingExaminers = '/e-testing/examiners'
export const eTestingVersionList = '/e-testing/listforelearning'
export const eTestingDashboardTestList = '/e-testing/dashboardTestList'
export const filterEtestingDashboardOverview =
  'report/filter/e-testing/dashboard/overview'
export const filterEtestingDashboardOverviewLearner =
  'report/filter/e-testing/dashboard/overview/Learner'
export const filterEtestingDashboardExaminer =
  'report/filter/e-testing/dashboard/overview/examiner'
export const filterEtestingDashboardResult =
  'report/filter/e-testing/dashboard/overview/Result'

//standalone check exma
export const standaloneExamList = '/e-testing/check-e-testing-standalone'
export const standaloneExamVersionList =
  '/e-testing/version/check-e-testing-standalone'
export const standaloneGiveScore = '/e-testing/give-score-e-testing-standalone'
export const standaloneAnnouncement =
  '/e-testing/exam-results-announcement-standalone'

// current date
export const currentDate = '/system-config/currentdate'

// eEvaluation
export const eEvaluation = '/e-evaluation'
export const eEvaluationList = '/e-evaluation/list'
export const eEvaluationFilter = '/e-evaluation/filter'
export const eEvaluationDownload = '/e-evaluation/download'
export const eEvaluationVersionFilter = '/e-evaluation/version/filter'
export const eEvaluationVersionDownload = '/e-evaluation/version/download'
export const eEvaluationDownloadTemplate =
  '/file/e-evaluation/Template_E_Evaluation.xlsx'
export const eEvaluationDashboard = '/report/e-evaluation/dashboard'

export const eEvaluationVersion = '/e-evaluation/version'
export const eEvaluationAssignees = '/e-evaluation/assignees'
export const eEvaluationHistory = '/e-evaluation/history'
export const eEvaluationUploadFile = '/file/e-evaluation'
export const eEvaluationStat = '/e-evaluation/stat'
export const eEvaluationStatFilter = '/e-evaluation/filter/stat'
export const eEvaluationVersionList = '/e-evaluation/listforelearning'

// jobTitle
export const listJobTitle = '/job'
export const updateCreateJobTitle = '/job'
export const deleteJobTitle = '/job'
export const getByUuidJobTitle = '/job'
export const historyJobTitle = '/job/history'
export const filterJobTitle = '/job/filter'
export const downloadJobTitle = '/job/download'
export const jobTitlSequence = '/job/sequence'

// eEvaluation
export const eCertification = '/e-certification'
export const eCertificationFilter = '/e-certification/filter'
export const eCertificationDownload = '/e-certification/download'
export const eCertificationCourseSettingDownload =
  '/e-certification/course-setting'

export const eCertificationBackground = '/e-certification/background'
export const eCertificationBackgroundLayout =
  '/e-certification/background/layout'
export const eCertificationBackgroundEdit = '/e-certification/background/edit'
export const eCertificationBackgroundFilter =
  '/e-certification/background/filter'
export const eCertificationBackgroundHistory =
  '/e-certification/background/history'
export const eCertificationBackgroundUploadFile =
  '/file/e-certification/background'

export const eCertificationVersion = '/e-certification/version'
export const eCertificationVersionFilter = '/e-certification/version/filter'
export const eCertificationVersionHistory = '/e-certification/version/history'
export const eCertificationVersionDownload = '/e-certification/version/download'
export const eCertificationAssignees = '/e-certification/assignees'

// api to rcms
export const apiToRcmsFilter = '/api-to-rcms/filter'
export const apiToRcmsFilterDetail = '/api-to-rcms/filter/detail'
export const apiToRcmsDownload = '/api-to-rcms/download'
export const apiToRcmsHistoryDetail = '/api-to-rcms/detail/history'
export const apiToRcmsHistoryImport = '/api-to-rcms/history'
export const apiToRcmsDetailDownload = '/api-to-rcms/detail/download'
export const apiToRcmsDetailLicenseDownload =
  '/api-to-rcms/detail/license/download'
export const apiToRcmsUpload = '/api-to-rcms/upload'
export const apiToRcmsCheck = '/api-to-rcms/upload/check'
export const apiToRcmsCreate = '/api-to-rcms'
export const apiToRcmsDetail = '/api-to-rcms/detail'
export const apiToRcmsChangeStatus = '/api-to-rcms/change-status'
export const apiToRcmsUploadDetail = '/api-to-rcms/upload/detail'
export const apiToRcmsSubmit = '/api-to-rcms/submit'
export const apiToRcmsAgentDetail = '/api-to-rcms/detail/agent'
export const apiToRcmsDashboard = '/api-to-rcms/dashboard'
export const apiToRcmsDashboardLicense = '/api-to-rcms/dashboard/license'
export const apiToRcmsDashboardLicenseDownload =
  '/api-to-rcms/dashboard/license/download'

// manage class
export const manageClassFile = '/file/manage-class'
export const manageClass = '/manage-class'
export const manageClassDetail = '/manage-class/detail'
export const manageClassETestEEvaList =
  '/manage-Class/get-e-testing-and-eva-list'
export const manageClassETestEEvaStart =
  '/manage-Class/e-testing-and-eva-start-timer'
export const manageClassAdminStaff = '/manage-class/adminStaff'
export const manageClassExternalSpeaker = '/manage-class/externalSpeaker'
export const manageClassStation = '/manage-class/station'
export const manageClassMeetingRoom = '/manage-class/meetingRoom'
export const manageClassHistory = '/manage-class/history'
export const manageClassLearner = '/manage-class/learner'
export const manageClassLearnerListHistory =
  '/manage-class/class-learner/history'
export const manageClassLearnerFilter = '/manage-class/learner/filter'
export const manageClassLearnerDownload = '/manage-class/learner/download'
export const manageClassLearnerDownloadTemplate =
  '/manage-class/learner/downloadTemplate'
export const manageClassLearnerUpload = '/manage-class/learner/upload'
export const manageClassLearnerAgentCheck = '/manage-class/learner/agent/check'
export const manageClassLearnerDelete = '/manage-class/learner'
export const manageClassLearnerRoom = '/manage-Class/learner/meetingRoom/filter'
export const manageClassFilter = '/manage-class/filter '
export const manageClassUserProfile = '/manage-class/userProfile/filter'
export const manageClassUserAgent = '/manage-class/userProfile/agent/filter'
export const manageClassUserAgentDetail =
  '/manage-class/userProfile/agent/detail'
export const manageClassDocumentFilter = '/manage-Class/learner/document/filter'
export const manageClassApprove = '/manage-Class/learner/approve-confirm'
export const manageClassDocumentDownload =
  '/manage-Class/learner/document/download'
export const manageClassDownload = '/manage-class/download'
export const manageClassAssignee = '/manage-class/assignee'
export const manageClassAnnounceLearner = '/manage-Class/announceLearner'
export const manageClassClose = '/manage-Class/closed'
export const manageClassCheckPassUser = '/manage-Class/checkPassUser'
export const manageClassDashboardFilter = '/manage-Class/dashboard'
export const manageClassDashboardList = '/manage-Class/dashboard/list'
export const manageClassPutLearnerDocument =
  '/manage-class/learner/document/update'

//manage class oic
export const manageClassOicFilter = '/manage-class/oic/exam/filter'
export const manageClassOicDownload = '/manage-class/oic/exam/download'
export const manageClassOicTemplateDownload =
  '/manage-class/oic/template/exam/download'
export const manageClassOicUpload = '/manage-class/oic/exam/upload'
export const manageClassOicCreate = '/manage-class/oic/exam/create'
export const manageClassOicDelete = '/manage-class/oic/exam/delete'
export const manageClassOicView = '/manage-class/oic/exam'

//manage class eva status
export const manageClassEvaStatus = '/manage-class/get-check-e-evaluation'

//course setting manager docs
export const manageDocsUpload = '/file/course/document'

// attendance
export const attendance = '/manage-class/attendance'
export const attendanceStartTimer = '/manage-class/attendance/start-timer'
export const attendanceList = '/manage-Class/attendance-list/'
export const getAttendanceLearnerList = '/manage-Class/get-learner-list'
export const attendanceCheck = '/manage-Class/attendance/check'

// calendar
export const manageClassCalendar = '/manage-Class/calendar'
export const manageClassStaffFilter = '/manage-Class/staff/filter'
export const manageClassCourseVersionFilter =
  '/manage-Class/course/version/filter'

// Document
export const manageClassLearnerDocument = '/manage-Class/learner/document'
export const manageClassLearnerDetail = '/manage-Class/learner/detail'
export const manageClassLearnerApprove = '/manage-Class/learner/approve'
export const manageClassLearnerReject = '/manage-Class/learner/reject'
export const manageClassLearnerApproveStatus =
  '/manage-Class/learner/approve-status'

// attendance detail
export const manageClassAttendanceStatus = '/manage-Class/attendance/status'
export const manageClassLearnerHistory = '/manage-Class/learner/history'
export const manageClassLearnerCancel = '/manage-Class/learner/cancel'
export const manageClassPasscourse = '/manage-Class/passCourse'
export const manageClassNotPasscourse = '/manage-Class/notPassCourse'
export const manageClassListAttendanceHistory =
  '/manage-class/get-check-attendance-history'
export const manageClassAbsentCourse = '/manage-Class/absentCourse'

// check exam
export const manageClassCheckExam = '/manage-class/check-e-testing'
export const manageClassCheckEva = '/manage-class/check-e-evaluation'
export const manageClassGiveScore = '/manage-Class/give-score-exam-learner'
export const manageClassAnnouncement = '/manage-Class/exam-results-announcement'
export const manageClassSetToNotPass =
  '/manage-Class/exam-assesment-stamp-to-not-pass'
export const manageClassCheckExamList = '/manage-class/get-check-exam-list'

// manage content
export const manageContent = '/manage-content'
export const manageContentUpload = '/file/manageContent'
export const manageContentInfo = '/manage-content/info'
export const manageLocation = '/manage-content/location'
export const manageLocationUpload = '/file/location'
export const manageLocationUploadBanner = '/file/location/banner'
export const manageContentLogin = '/manage-content/login'
export const manageLoginUploadBanner = '/file/login/banner'
export const manageElearning = '/manage-content/elearning'
export const manageELearningHistory = '/manage-content/history'

// license
export const license = '/license'
export const licenseHistory = '/license/history'
export const licenseDocument = '/file/license/document'
export const licenseConfigType = '/license/config-type'
export const licenseDistributionList =
  '/distributionChannel/license-config/list'
export const licenseDistributionELearningList =
  '/distributionChannel/license-config/e-learning/list'
export const licenseFilter = '/license/filter'
export const licenseDownload = '/license/download'
export const licenseList = '/license/list'

// manage
export const prospectProfileFilter = '/manage-user/prospect/filter'
export const prospectProfileDownload = '/manage-user/prospect/download'
export const prospectProfileView = '/manage-user/prospect'
export const prospectProfileCourseView = '/manage-user/prospect/course'
export const prospectProfileExamView = '/manage-user/prospect/exam'
export const prospectProfileLicense = '/manage-user/prospect/course/history'
export const prospectProfileHistory = '/manage-user/prospect/history'
export const prospectProfileExamHistory = '/manage-user/prospect/exam/history'

export const agentUpdateProfile = '/manage-user/agent-update'
export const agentUpdateProfileFilter = '/manage-user/agent-update/filter'

export const prospectProfileUpdate = '/manage-user/prospect'
export const prospectUploadImage = '/api/file/prospect'
export const agentProfileFilter = '/manage-user/agent/filter'
export const agentProfileView = '/manage-user/agent'
export const agentProfileLicenseView = '/manage-user/agent/licence'
export const agentProfileCourseView = '/manage-user/agent/course'
export const agentProfileExamView = '/manage-user/agent/exam'
export const agentProfileETetsingView =
  '/report/filter/e-testing/report-by-user'
export const agentProfileUpdate = 'manage-user/agent'
export const agentProfileDownload = '/manage-user/agent/download'
export const agentProfileLicenseHistory = '/api-to-rcms/detail/history'
export const agentProfileCourseHistory = '/manage-user/agent/course/history'
export const agentProfileExamHistory = '/manage-user/agent/exam/history'
export const eCertificationDownloadProfile = '/e-certification/certificate/'
export const agentProfileHistory = '/manage-user/agent/history'
export const agentProfileAgentCheck = '/manage-user/agent/check'
export const agentPointUpdate = '/manage-user/agent/point-update'
export const agentPointTemplate = '/manage-user/agent/template/download'

export const reportRawData = '/manage-class/raw-data/download'
export const manageClassCourseList = '/manage-class/course/list'
export const eLearningCourseList = '/e-learning/course/list/all'

//station
export const stationFilter = '/station/filter'

// meeting room
export const meetingRoom = '/meetingroom'
export const meetingRoomFilter = '/meetingroom/filter'
export const meetingRoomDownload = '/meetingroom/download'
export const meetingRoomImageUpload = '/file/meeting-room'
export const meetingRoomEquipmentList = '/equipment/filter'
export const meetingRoomHistory = '/meetingroom/history'

// to do List
export const toDoListSummary = '/toDoList/summary'
export const toDoListFilter = '/toDoList/filter'
export const toDoListNotificationFilter = '/toDoList/notification/filter'
export const toDoListClearNotification = '/toDoList/clear-notification'
export const toDoListUpdateStatus = '/toDoList/update-status'

//room management
export const roomManagement = '/room-management'
export const roomManagementDashboard = '/room-management/dashboard'
export const roomManagementWeek = '/room-management/week'
export const roomManagementAvailable = '/room-management/room-available'
export const roomManagementMeetingRoom = '/room-management/filter/meeting-room'
export const roomManagementRoomRequest = '/room-management/filter/room-request'
export const roomManagementStaffList = '/room-management/staff-list'
export const roomManagementFilterCalendar =
  '/room-management/filter/calendar-meeting-room'
export const roomManagementDashboardCalendar =
  '/room-management/filter/calendar-by-station'
export const roomManagementHistory = '/room-management/history'
export const roomManagementHistoryAll = '/room-management/history-all'
export const roomManagementDownloadOperator =
  '/room-management/download-operator'
export const getRoomManagementDashboard = '/room-management/dashboard/overview'
export const getRoomManagementDashboardByTime =
  '/room-management/dashboard/room-by-time'
export const getRoomManagementDashboardByAttendee =
  '/room-management/dashboard/room-by-attendee'

// e-learning
export const eLearning = '/e-learning'
export const eLearningModule = '/e-learning/module'
export const eLearningModuleFilter = '/e-learning/module/filter'
export const eLearningModuleHistory = '/e-learning/history'
export const eLearningModuleDownload = '/e-learning/download'
export const eLearningQuestionDownload =
  '/e-learning/course/batch/question/download'
export const eLearningModuleCreateMultiPart = '/e-learning/create-multipart'
export const eLearningModuleUploadPart = '/e-learning/upload-part'
export const eLearningModuleCompleteMultiPart = '/e-learning/complete-multipart'
export const eLearningModuleCheckUpload = '/e-learning/module/uploadFile/check'
export const eLearningModuleConvertAgain =
  '/e-learning/module/uploadFile/convert-again'
export const eLearningModuleDownloadFile = '/e-learning/moduleFile'
export const eLearningCourse = '/e-learning/course'
export const eLearningCourseDraft = '/e-learning/course/draft'
export const eLearningCourseHistory = '/e-learning/course/history'
export const eLearningCourseFilter = '/e-learning/course/filter'
export const eLearningCourseDownload = '/e-learning/course/download'
export const eLearningCourseUploadFile = '/file/e-learning/course'
export const eLearningCourseBatchFilter = '/e-learning/course/batch/filter'
export const eLearningCourseBatch = '/e-learning/course/batch'
export const eLearningCourseBatchHistory = '/e-learning/course/batch/history'
export const eLearningCourseBatchCompleted =
  '/e-learning/course/batch/completed'
export const eLearningCourseLearnerFilter = '/e-learning/course/learner/filter'
export const eLearningCourseLearnerApprove =
  '/e-learning/course/batch/learner/is-checked'
export const eLearningCourseLearnerNotVerify =
  '/e-learning/course/batch/learner/not-verify'
export const eLearningCourseLearnerIneligible =
  '/e-learning/course/batch/ineligibleLearner'
export const eLearningCourseLearnerDownload =
  '/e-learning/course/learner/download'
export const eLearningCourseLearnerDetail =
  '/e-learning/course/batch/learner/detail'
export const eLearningCourseLearnerRound = '/e-learning/course/learner/round'
export const eLearningCourseLearnerRekog =
  '/e-learning/course/batch/learner/rekognitions'
export const eLearningCourseLearnerRandomClick =
  '/e-learning/course/batch/learner/randomClick'
export const eLearningCourseLearnerAttend =
  '/e-learning/course/batch/learner/logLearners'
export const eLearningCourseBatchLearner = '/e-learning/course/batch/learner'
export const eLearningCourseBatchLearnerHistory =
  '/e-learning/course/batch/learner/History'
export const eLearningCourseBatchLearnerUpload =
  '/e-learning/course/batch/learner/upload'
export const eLearningCourseBatchLearnerAgentCheck =
  '/e-learning/course/batch/learner/agent/check'
export const eLearningCourseBatchLearnerFilter =
  '/e-learning/course/batch/learner/filter'
export const eLearningCourseBatchLearnerCancel =
  '/e-learning/course/batch/learner/cancel'
export const eLearningCourseBatchAnnounceLearner =
  '/e-learning/course/batch/announceLearner'
export const eLearningCourseBatchDownload = '/e-learning/course/batch/download'
export const eLearningCourseBatchLearnerDownload =
  '/e-learning/course/batch/learner/download'
export const eLearningAdminList = '/e-learning/adminStaff'
export const eLearningTrainerList = '/e-learning/trainerStaff'
export const eLearningTrainerQuestion = '/e-learning/trainerQuestion'
export const eLearningQuestionFilter =
  '/e-learning/course/batch/question/filter'
export const eLearningQuestion = '/e-learning/course/batch/question'
export const eLearningQuestionHistory =
  '/e-learning/course/batch/question/history'
export const eLearningLearningPathFilter = '/e-learning/learningPath/filter'
export const eLearningLearningPath = '/e-learning/learningPath'
export const eLearningLearningPathDraft = '/e-learning/learningPath/draft'
export const eLearningLearningPathFile = '/file/e-learning/learningPath'
export const eLearningLearningPathCourses = '/e-learning/learningPath/courses'
export const eLearningLearningPathDownload = '/e-learning/learningPath/download'
export const eLearningLearningPathHistory = '/e-learning/learningPath/history'
export const eLearningDetail = '/api/e-learning/get-course-detail'
export const eLearningDashboard = '/e-learning/dashboard'
export const eLearningDashboardAllCourse = '/e-learning/all/course'
export const eLearningDashboardAllCourseDownload =
  '/e-learning/all/course/download'
export const eLearningDashboardCourseLearner =
  '/e-learning/dashboard/course/learner'
export const eLearningDashboardByCourse = '/e-learning/dashboard/course'
export const eLearningDashboardLearner =
  '/e-learning/dashboard/course/detail/learner'
export const eLearningDashboardLearnerDownload =
  '/e-learning/dashboard/learner/download'

export const courseDetail = '/api/manage-class/get-course-detail'
export const trainingPlanByCourseVersion =
  '/api/manage-class/get-training-plan-by-course-version'

//Examination (association)
export const examinationRegionList = '/master-data/examination/regions/filter'
export const examinationAssociation = '/master-data/examination/association'
export const examinationAssociationFilter =
  '/master-data/examination/association/filter'
export const examinationAssociationEventFilter =
  '/master-data/examination/association/events/filter'
export const examinationAssociationDownload =
  '/master-data/examination/association/download'

//Examination (company) - station
export const examinationCompany = '/master-data/examination/company'
export const examinationCompanyFilter =
  '/master-data/examination/company/filter'
export const examinationCompanyEventFilter =
  '/master-data/examination/company/events/filter'
export const examinationCompanyDownload =
  '/master-data/examination/company/download'
export const examinationTime = '/master-data/examination/time'

//Examination - schedule
export const examinationListFilter = '/examination/schedule/filter'
export const examinationTemplateDownload = '/examination/schedule/download'
export const examinationTemplateReportDownload = '/examination/report/download'
export const examinationSchedule = '/examination/schedule'
export const examinationScheduleEventFilter =
  '/examination/schedule/events/filter'
export const examinationStudentFilter = '/examination/student/filter'
export const examinationStudentDetail = '/examination/student'
export const examinationCheckOtherDocConfig =
  '/examination/check-other-doc-config'

//Examination - Dashboard
export const examinationDashboard = '/examination/dashboard'
export const examinationDashboardExamField =
  '/examination/dashboard/examination-field'
export const examinationDashboardSchedule = '/examination/dashboard/schedule'
export const examinationDashboardTop = '/examination/dashboard/top-exam'
export const examinationDashboardTopDownload =
  '/examination/dashboard/top-exam/download'
export const examinationDashboardEnroll = '/examination/dashboard/enroll'

//Examiner
export const examinationGender = '/master-data/examination/gender'
export const examinationQualification = '/master-data/examination/qualification'
export const examinationAddress = '/master-data/examination/address'
export const examinationIdCard = '/file/examination/idcard'
export const examinationUploadSchedule = '/examination/schedule/upload'
export const examinationUploadStudent = '/examination/student/upload'
export const examinationUpdateStudentStatus = '/examination/student/status'
export const examinationStudentEvent = '/examination/student/events/filter'
export const examinationnUploadResult = '/examination/student/upload/result'
export const examinationnUpdateResult = '/examination/student/result'
export const examinationStudentDownload = '/examination/student/download'
export const examinationUploadOtherDocs = '/file/examination/other-docs'
export const examinationStudentResultTemplateDownload =
  '/examination/student/result/template/download'

//Report Course Setting
export const reportCourseSettingDashboard = '/report-course-setting/dashboard'

//Report
export const reportFilter = '/report/filter'
export const reportGetOptions = '/report/options-by-key'
export const reportDownload = '/report/download'
export const ETestingReportQuestionCount = '/report/e-testing/question-count'
export const EEVAReportQuestionCount = '/report/e-evaluation/question-count'
export const checkStatusReportUpdated = '/report/check/status-report-updated'

//E-configuration
export const eConfiguration = '/e-configuration'
export const eConfigurationHistory = '/e-configuration/history'

//Inventory - stock
export const stockListFilter = '/inventory/warehouse/filter'
export const stockDetail = '/inventory/warehouse'
export const getStockDetailHistory = '/inventory/warehouse/history/filter'
export const stockDownload = '/inventory/warehouse/download'

//Inventory - product
export const productListFilter = '/inventory/product/filter'
export const inventoryProduct = '/inventory/product'
export const productReduce = '/inventory/product/reduce'
export const productDownload = '/inventory/product/download'
export const productSkuStatus = '/inventory/product/sku/status'

//Inventory - ticket
export const inventoryTicket = '/inventory/ticket'
export const inventoryTicketDetailFilter = '/inventory/ticket/detail/filter'
export const inventoryTicketFromMonthlyPlan =
  '/inventory/ticket/requisition/monthly-plan'

//Inventory - transfer
export const transferDownloadTemplate = '/inventory/ticket/transfer/download'
export const transferTicket = '/inventory/ticket/transfer'
export const transferTicketStatus = '/inventory/ticket/transfer/status'
export const transferUpload = '/inventory/ticket/transfer/upload'
export const transferDownload = '/inventory/transfer/list/download'
export const inventoryCheckSkuNo = '/inventory/check-sku-no'

//Inventory - requisition
export const requisitionTicket = '/inventory/ticket/requisition'
export const requisitionTicketStatus = '/inventory/ticket/requisition/status'

//Inventoey - product warehouse
export const productWarehouseListFilter = '/inventory/warehouse/product/filter'
export const productWarehouseMovementFilter =
  '/inventory/warehouse/movement/filter'
export const productWarehouseMovementDownload =
  '/inventory/warehouse/movement/download'
export const productWarehouseMovementHistoryDownload =
  '/inventory/warehouse/movement/history/download'

//Inventory - product sku warehouse
export const productSkuUpdate = '/inventory/product/sku/update'
export const productSkuUpdateStatus = '/inventory/product/sku/status'
export const skuHolderFilter = '/inventory/product/sku-holder/filter'

//Requisition
export const requisitionDownload = '/inventory/requisition/list/download'
export const inventoryTrainingPlanFilter = '/inventory/trainingPlan/filter'
export const requisitionSendMail = '/inventory/requisition/send-mail'

//CRUD
export const CRUDListFilterPath = '/crud/filter'
export const CRUDListDownloadPath = '/crud/download'
export const CRUDDeletedPath = '/crud'
export const CRUDGetByIdPath = '/crud'
export const CRUDPostCreatePath = '/crud'
export const CRUDPutUpdatePath = '/crud'
export const CRUDGetHistoryPath = '/crud/history'

export const uploadFileKey = '/file/upload'

export const eContentUploadFile = '/file/e-content'

//Learning Point
export const learningPointGetCourse = '/learning-point/course'
export const learningPointCreate = '/learning-point'
export const learningPointUpdate = '/learning-point'
export const learningPointDelete = '/learning-point'
export const postPointHistoryByUuid = '/learning-point/point-history'
export const postBadgeByUuid = '/learning-point/get-badge'
export const postSavePoint = 'learning-point/save-point'
export const postPointByUuid = 'learning-point/get-point'
export const postredeemableCatalog = 'learning-point/redeemable-catalog'
export const postRedeem = 'learning-point/redeem'

//expense
export const expense = '/expense'
export const expenseAll = '/expense/all'

//budget-management
export const budgetManagement = '/budget-management'
export const budgetManagementFilter = '/budget-management/filter'
export const budgetManagementDownload = '/budget-management/download'
export const budgetManagementHistory = '/budget-management/history'
export const budgetManagementTemplateDownload =
  '/budget-management/template/download'
export const budgetManagementUpload = '/budget-management/upload'

// e-content
export const eContentAssignees = '/e-content/assignees'
export const eContentSetPin = '/e-content/pin'
export const getEContentDashboard = '/e-content/dashboard'
