import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '& .MuiTypography-root': {
    wordBreak: 'break-word',
    span: {
      color: theme?.palette?.info?.main,
      fontStyle: 'italic',
    },
  },
  '& .MuiBox-root': {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}))
