import _ from 'lodash'
import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '@mui/material/Drawer'
import Tabs from '@mui/material/Tabs'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.background.paper,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  border: '1px #DBE4F1',
  width: 'fit-content',
}))

export const FilterStyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  background: '#DBE4F199',
  borderRadius: '4px',
  border: '1px #DBE4F1',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  width: '0.8em',
  marginLeft: theme.spacing(1),
  fontSize: '1em',
  cursor: 'pointer',
}))

export const StyledButton = styled(IconButton)(
  ({ background, marginLeft }) => ({
    marginLeft: !_.isUndefined(marginLeft) ? marginLeft : 'auto',
    background: background,
    borderRadius: '50%',
  }),
)

export const Dot = styled(Box)(({ background }) => ({
  marginRight: '4px',
  width: 16,
  height: 16,
  background: background,
  borderRadius: '50%',
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root.MuiDrawer-paper ': {
    width: '85%',
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(3),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledLoadingBg = styled(
  Box,
  shouldForwardProp,
)(({ isLoading }) => ({
  display: isLoading ? 'block' : 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.3)',
  zIndex: 1200,
}))

export const LoadingCircular = styled(
  CircularProgress,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette?.primary?.main,
}))

export const StyledTab = styled(Tabs)(({ theme }) => ({
  width: '50%',
  '& .MuiTabs-indicator': { height: theme.spacing(0.5) },
  '& .MuiTab-root': {
    color: '#A9B8CD',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: { paddingTop: theme.spacing(20) },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    width: '100%',
    '& .MuiTab-root': { width: '50%' },
  },
}))
