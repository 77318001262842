import styled from '@mui/system/styled'

export const DotStyle = styled('div')(({ theme, status, color, sx }) => {
  const lwStatus = status?.toString().toLowerCase()
  const isDisable = lwStatus === 'deleted'
  return {
    borderRadius: 5,
    border: isDisable ? `1px solid ${theme?.palette?.text?.gray}` : 'unset',
    backgroundColor: color,
    width: 8,
    height: 8,
    ...sx,
  }
})
