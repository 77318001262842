import React from 'react'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const StyledLessonCard = styled(Box)(({ theme, disabled }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  background: theme.palette?.info?.background,
  '& .MuiInputBase-root': {
    background: disabled
      ? theme.palette?.text?.wildSand
      : theme.palette?.background?.paper,
  },
}))

export const StyledLessonHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  svg: { cursor: 'pointer' },
}))

export const StyledDragHolder = styled(DragIndicatorTwoTone)(
  ({ theme, disabled }) => ({
    color: theme.palette?.grey?.grey400,
    cursor: disabled ? 'default' : 'pointer',
  }),
)

export const StyledFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'start',
}))

export const StyledSmallButton = styled((props) => (
  <Button size="small" {...props} />
))(() => ({
  fontSize: '17px',
  lineHeight: '22px',
}))

export const StyledMiniIconBtn = styled(IconButton)(() => ({
  width: 24,
  height: 24,
}))
