import {
  setPayloadKey,
  startLoading2,
  stopLoading2,
} from '../../../../../redux/slices/manage/prospectProfile'
import axios from 'axios'
import { prospectProfileExamView } from '../../../../../utils/apiPath'

export const fetchExam = (body) => async (dispatch) => {
  dispatch(startLoading2())
  const response = await axios.post(prospectProfileExamView, body)
  dispatch(setPayloadKey({ key: 'exam', data: response?.data?.exam }))
  dispatch(stopLoading2())
}
