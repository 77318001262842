import _ from 'lodash'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { eCertificationBackgroundHistory } from '../../../../../utils/apiPath'
import { callAPI } from '../../../../../utils/lib'
import {
  StyledContainerFooter,
  StyledGroupFooter,
  StyledHistoryFooter,
  StyledSubGroupFooter,
} from '../UploadBackground/Styled'
import HistoryDrawer from './HistoryDrawer'

export const FooterEdit = ({ uuid }) => {
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const { createdBy, createdAt, updatedBy, updatedAt } = useSelector(
    (state) => ({
      createdBy: state.eCertificateForm.createdBy,
      createdAt: state.eCertificateForm.createdAt,
      updatedBy: state.eCertificateForm.updatedBy,
      updatedAt: state.eCertificateForm.updatedAt,
    }),
    shallowEqual
  )

  const fetchHistory = async () => {
    const realPage = page === 0 ? 1 : page + 1
    const url = `${eCertificationBackgroundHistory}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  useEffect(() => {
    fetchHistory()
  }, [limit, page])

  return (
    <StyledContainerFooter>
      <StyledGroupFooter>
        <StyledSubGroupFooter>
          <Typography variant="body1">วันที่สร้าง</Typography>
          <Typography variant="body1">วันที่แก้ไขล่าสุด</Typography>
        </StyledSubGroupFooter>
        <StyledSubGroupFooter>
          <Typography variant="body1">{createdAt}</Typography>
          <Typography variant="body1">{updatedAt ?? '-'}</Typography>
        </StyledSubGroupFooter>
      </StyledGroupFooter>
      <StyledGroupFooter>
        <StyledSubGroupFooter>
          <Typography variant="body1">สร้างโดย</Typography>
          <Typography variant="body1">แก้ไขล่าสุดโดย</Typography>
        </StyledSubGroupFooter>
        <StyledSubGroupFooter>
          <Typography variant="body1">
            {_.get(createdBy, 'name', '-')}
          </Typography>
          <Typography variant="body1">
            {_.get(updatedBy, 'name', '-')}
          </Typography>
        </StyledSubGroupFooter>
      </StyledGroupFooter>
      <StyledHistoryFooter>
        <IconButton onClick={onHistoryClick}>
          <HistoryTwoTone />
        </IconButton>
      </StyledHistoryFooter>
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        allCount={allCount}
      />
    </StyledContainerFooter>
  )
}

export default FooterEdit
