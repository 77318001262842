import { STOCK_TYPE, STOCK_STATUS } from '../../../../../constants/stock'

export const initialState = {
  errors: [],
  formError: [],
  isLoading: false,
  isLoadingDrawer: false,
  isOpenFilterDrawer: false,
  warehouseType: STOCK_TYPE.ACADEMY,
  isOpenHistoryFilterDrawer: false,
  historyList: {
    result: [],
    totalCount: 0,
  },
  stationList: [],
  initialStockList: true,
  stockList: [],
  stockDetail: {},
  stockForm: {
    warehouseName: '',
    station: null,
    status: STOCK_STATUS.ACTIVE,
    remark: '',
    vendor: '',
  },
}
