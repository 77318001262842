import {
  closeDialog,
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../redux/slices/dialog'
import _ from 'lodash'
import {
  updateStudentStatus,
  updateStudentResultStatus,
} from '../../../../services/eExamination'
import { fetchExaminerListFilter } from '../../ExaminerList/event'
import { store } from '../../../../App'
import {
  STUDENT_STATUS,
  IMPORT_EXAMINER_TYPE,
} from '../../../../constants/eExamination'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const resetFilter = (uuid) => (dispatch) => {
  const { table } = store.getState().table
  dispatch(
    fetchExaminerListFilter({
      uuid,
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      filterText: null,
      keyword: '',
    }),
  )
  dispatch(closeDialog())
}

export const handleSubmitAdd =
  (uuid, uploadResult, importExaminerType) => async (dispatch) => {
    dispatch(loadingDialog())
    const result = _.get(uploadResult, 'result', [])
    let uuidList = result.filter(
      (item) => item.status === 'SUCCESS' || item.status === 'ACTIVE',
    )
    let response
    if (importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT) {
      uuidList = _.map(uuidList, (item) => {
        return item.uuid
      })

      const body = {
        schedule: uuid,
        list: uuidList,
        status: STUDENT_STATUS.WAITING_ANNOUNCEMENT,
        scheduleName: null,
        annotation: null,
      }

      response = await dispatch(updateStudentStatus(body))
    } else {
      const body = {
        oicExamResult: uuidList,
      }
      response = await dispatch(updateStudentResultStatus(body))
    }
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(resetFilter(uuid))
          },
          handleCancel: () => {
            dispatch(resetFilter(uuid))
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
          handleConfirm: () => {
            dispatch(resetFilter(uuid))
          },
          handleCancel: () => {
            dispatch(resetFilter(uuid))
          },
        }),
      )
    }
  }
