import React, { useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import _ from 'lodash'
import RadioGroup from '@mui/material/RadioGroup'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import TestingRound from './TestingRound'
import QuestionResult from './QuestionResult'
import LimitTime from './LimitTime'

import * as Styled from '../Styled'
import { handleChange } from '../event'

import { TooltipForm } from '../../TestingForm/Styled'
import { BoxTooltipAlert, IconInfo } from './Styled'
import { checkEventDisplayQuestion, checkEventTestResult } from './event'

import { questionFreeText } from '../../../../../redux/slices/eTestingForm'
import TestingCriteria from './TestingCriteria'

const Index = () => {
  const dispatch = useDispatch()
  const { setting, disableSetting, typeOfETesting } = useSelector(
    (state) => ({
      setting: state.eTestingForm.setting,
      disableSetting:
        state.eTestingForm.onViewMode || state.eTestingForm.disableSetting,
      typeOfETesting: state.eTestingForm.typeOfETesting,
    }),
    shallowEqual
  )
  const {
    displayQuestion,
    randomQuestion,
    randomAnswer,
    testResult,
    questionResult,
  } = setting
  const [openDisplay, setOpenDisplay] = useState(false)
  const [openReport, setOpenReport] = useState(false)

  const handleOpenDisplay = () => {
    setOpenDisplay(true)
  }

  const handleOpenReport = () => {
    setOpenReport(true)
  }

  const checkTestResult = useSelector(questionFreeText, shallowEqual)

  useEffect(() => {
    dispatch(checkEventTestResult(checkTestResult))
  }, [])

  return (
    <>
      <Styled.BoxCard>
        <LimitTime />
        <Styled.BoxForm isGap>
          <Typography variant="h6">การแสดงคำถาม</Typography>

          <RadioGroup
            sx={{ pl: 3, gap: 0.5 }}
            aria-label="approval"
            name="displayQuestion"
            id="displayQuestion"
            data-testid="radio-displayQuestion"
            defaultValue={displayQuestion}
            value={displayQuestion}
            onChange={(e) => {
              dispatch(
                handleChange({
                  value: _.get(e, 'target.value', 'ALL'),
                  key: 'displayQuestion',
                })
              )
            }}
          >
            <FormControlLabel
              disabled={
                disableSetting || checkEventDisplayQuestion(questionResult)
              }
              value={'ALL'}
              control={<Radio />}
              label="แสดงทั้งหมด"
            />
            <FormControlLabel
              disabled={
                disableSetting || checkEventDisplayQuestion(questionResult)
              }
              value={'ONE'}
              control={<Radio />}
              label={
                <>
                  {checkEventDisplayQuestion(questionResult) ? (
                    <BoxTooltipAlert>
                      <Typography variant="body1">แสดงทีละข้อ</Typography>
                      <TooltipForm
                        onClose={() => setOpenDisplay(false)}
                        open={openDisplay}
                        title={
                          <Typography variant="tooltip">
                            {`มีการตั้งค่าเฉลยคำตอบระหว่างทำข้อสอบ จึงต้องแสดงคำถามทีละข้อ`}
                          </Typography>
                        }
                      >
                        <IconInfo
                          id="display"
                          onClick={handleOpenDisplay}
                          onMouseOver={handleOpenDisplay}
                        />
                      </TooltipForm>
                    </BoxTooltipAlert>
                  ) : (
                    <Typography variant="body1">แสดงทีละข้อ</Typography>
                  )}
                </>
              }
            />
          </RadioGroup>

          <Divider sx={{ mt: 2 }} />
        </Styled.BoxForm>
        <Styled.BoxForm isGap>
          <Typography variant="h6">การสุ่ม</Typography>
          <Styled.BoxFlex isSwitch paddingLeft={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1">สุ่มตัวเลือกคำตอบ</Typography>
            </Box>
            <Switch
              data-testid="switch-randomAnswer"
              checked={randomAnswer}
              disabled={disableSetting}
              onChange={(e) => {
                dispatch(
                  handleChange({
                    value: _.get(e, 'target.checked', false),
                    key: 'randomAnswer',
                  })
                )
              }}
            />
          </Styled.BoxFlex>

          <Styled.BoxFlex isSwitch paddingLeft={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1">สุ่มคำถาม</Typography>
            </Box>

            <Switch
              data-testid="switch-randomQuestion"
              checked={randomQuestion}
              disabled={disableSetting}
              onChange={(e) => {
                dispatch(
                  handleChange({
                    value: _.get(e, 'target.checked', false),
                    key: 'randomQuestion',
                  })
                )
              }}
            />
          </Styled.BoxFlex>

          <Divider sx={{ mt: 2 }} />
        </Styled.BoxForm>
        <Styled.BoxForm isGap>
          <Typography variant="h6">การรายงานผลการทดสอบ</Typography>

          <RadioGroup
            sx={{ pl: 3, gap: 0.5 }}
            aria-label="approval"
            name="testResult"
            id="testResult"
            data-testid="radio-testResult"
            defaultValue={testResult}
            value={testResult}
            onChange={(e) => {
              dispatch(
                handleChange({
                  value: _.get(e, 'target.value', 'NOW'),
                  key: 'testResult',
                })
              )
            }}
          >
            <FormControlLabel
              disabled={(!checkTestResult ? false : true) || disableSetting}
              value={'NOW'}
              control={<Radio />}
              label="ออกผลสอบทันที"
            />
            <FormControlLabel
              disabled={(!checkTestResult ? false : true) || disableSetting}
              value={'LATER'}
              control={<Radio />}
              label={
                <>
                  {!checkTestResult ? (
                    <Typography variant="body1">ออกผลสอบภายหลัง</Typography>
                  ) : (
                    <BoxTooltipAlert>
                      <Typography variant="body1">ออกผลสอบภายหลัง</Typography>
                      <TooltipForm
                        onClose={() => setOpenReport(false)}
                        open={openReport}
                        title={
                          <Typography variant="tooltip">
                            {`ในชุดข้อสอบมีคำถามประเภทที่ต้องรอการตรวจให้คะแนนจากวิทยากร จึงไม่สามารถออกผลได้ทันทีที่สอบเสร็จ`}
                          </Typography>
                        }
                      >
                        <IconInfo
                          id="report"
                          onClick={handleOpenReport}
                          onMouseOver={handleOpenReport}
                        />
                      </TooltipForm>
                    </BoxTooltipAlert>
                  )}
                </>
              }
            />
          </RadioGroup>

          <Divider sx={{ mt: 2 }} />
        </Styled.BoxForm>
        <QuestionResult />
        <Divider sx={{ mt: 2, mx: 3 }} />
        <TestingRound />

        {typeOfETesting === 'STANDALONE' && (
          <TestingCriteria setting={setting} disableSetting={disableSetting} />
        )}
      </Styled.BoxCard>
    </>
  )
}

export default Index
