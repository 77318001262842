import { MeetingRoom } from './MeetingRoom'
import { RoomRequest } from './RoomRequest'
import { shallowEqual, useSelector } from 'react-redux'
import { CalendarCard } from '../View/Content/CalendarCard'

export const TabContent = () => {
  const { tabActive } = useSelector(
    (state) => ({
      tabActive: state.roomManagement.tabActive,
    }),
    shallowEqual,
  )

  return (
    <>
      {tabActive === 0 && <MeetingRoom />}
      {tabActive === 1 && <CalendarCard />}
      {tabActive === 2 && <RoomRequest />}
    </>
  )
}
