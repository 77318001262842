import React from 'react'
import Button from '@mui/material/Button'
import { StyledHeaderContainer } from '../../Styled'
import { handleOpenImportTemplateDialog } from '../../../../redux/slices/eExamination/list/event'
import Add from '@mui/icons-material/Add'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

const Index = () => {
  const { eExamType } = useSelector(
    (state) => ({
      eExamType: state.eExamList.eExamType,
    }),
    shallowEqual,
  )

  return (
    <StyledHeaderContainer>
      <Button
        data-testid="btn-download-0"
        variant="outlined"
        size="m"
        onClick={() => {
          handleOpenImportTemplateDialog()
        }}
        startIcon={<UploadFileRoundedIcon />}
      >
        นำเข้า Template
      </Button>

      <Link to={`/e-examination/form?type=${eExamType}`}>
        <Button
          data-testid="btn-download-0"
          variant="contained"
          size="m"
          startIcon={<Add />}
        >
          ตารางสอบ
        </Button>
      </Link>
    </StyledHeaderContainer>
  )
}
export default Index
