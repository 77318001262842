import React from 'react'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNull } from 'lodash'
import { StyledBoxButton } from '../../../Styled'
import { saveQRForEnroll, hasExpiry } from '../../events'
import {
  setExpiryType,
  startLoadingSaveQr,
  stopLoadingSaveQr,
} from '../../../../../../../redux/slices/manageClassDetail'
import { EXPIRY_TYPE } from '../../../../../../../constants/manageClass/qrCodeForEnroll'
import { StyledSetExpiryDateTime } from '../../Styled'
import { useEffect, useState } from 'react'
import { SetDateTimeExpirySection } from './SetDateTimeExpirySection'

export const SetExpirySection = ({ uuid }) => {
  const [isDisableButton, setIsDisabled] = useState(false)
  const dispatch = useDispatch()
  const {
    qrEnrollProps: {
      expiryDate,
      expiryTime,
      expiryType,
      isEnable,
      isLoadingBtn,
    },
    isMobile,
  } = useSelector((state) => state.manageClassDetail, shallowEqual)
  const qrExpiryType = [
    {
      label: 'ไม่กำหนด',
      enum: EXPIRY_TYPE.NO_EXPIRY,
    },
    {
      label: 'กำหนด',
      enum: EXPIRY_TYPE.HAS_EXPIRY,
    },
  ]

  const handleSaveQRCode = async () => {
    const props = {
      uuid,
      expiryType,
      expiryDate: dayjs(expiryDate).format('YYYY-MM-DD'),
      expiryTime,
      isEnable,
    }
    dispatch(startLoadingSaveQr())
    await dispatch(saveQRForEnroll(props))
    dispatch(stopLoadingSaveQr())
  }

  useEffect(() => {
    setIsDisabled(
      hasExpiry(expiryType) && (isNull(expiryTime) || isNull(expiryDate))
    )
  }, [expiryType, expiryDate, expiryTime])

  return (
    <>
      <Typography variant={isMobile ? 'body1b' : 'h6'}>
        กำหนดเวลาใช้งาน QR Code ลงทะเบียน
      </Typography>
      <RadioGroup
        row
        data-testid="radio-expiry"
        sx={{ gap: 6 }}
        aria-label="expiry"
        name="expiry"
        defaultValue={expiryType}
        value={expiryType}
        onChange={(e) => {
          const { value } = e.target
          dispatch(setExpiryType(value))
        }}
      >
        {qrExpiryType.map((type, idx) => (
          <FormControlLabel
            key={idx}
            value={type.enum}
            control={<Radio />}
            label={type.label}
          />
        ))}
      </RadioGroup>
      <StyledSetExpiryDateTime>
        {hasExpiry(expiryType) && <SetDateTimeExpirySection />}
        <StyledBoxButton sx={{ alignItems: 'center' }}>
          <LoadingButton
            variant="contained"
            size="m"
            data-testid="btn-save-qr-code"
            disabled={isDisableButton}
            loading={isLoadingBtn}
            onClick={() => handleSaveQRCode()}
          >
            สร้าง QR Code
          </LoadingButton>
        </StyledBoxButton>
      </StyledSetExpiryDateTime>
    </>
  )
}
