import {
  setPayloadKey,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manage/agentProfile'
import axios from 'axios'
import { agentProfileLicenseView } from '../../../../../utils/apiPath'

export const fetchLicense = (body) => async (dispatch) => {
  dispatch(startLoading())
  const response = await axios.post(agentProfileLicenseView, body)
  dispatch(setPayloadKey({ key: 'license', data: response?.data?.license }))
  dispatch(stopLoading())
}
