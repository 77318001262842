import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Course from './Course'
import TrainingPlatform from './TrainingPlatform'
import RoadMap from './RoadMap'
import Trainer from './Trainer'
import { StyledDotBg, StyledHome } from './Styled'
import HomeHeader from './HomeHeader'

import Footer from '../../../../components/Footer'

const Preview = () => {
  const homeContent = useSelector(
    (state) => state.manageContentHome.data,
    shallowEqual
  )
  const showRoadMap = _.get(homeContent, 'roadMapInformation.status', false)
  const showTrainer = _.get(homeContent, 'trainerInformation.status', false)
  const showTraining = _.get(
    homeContent,
    'trainingPlatformInformation.status',
    false
  )
  const showCourse = _.get(homeContent, 'coursesTitle.status', false)

  return (
    <>
      <StyledHome>
        <HomeHeader bannerSection={_.get(homeContent, 'bannerSection', [])} />
        <Box sx={{ position: 'relative' }}>
          {showRoadMap && (
            <RoadMap roadMap={_.get(homeContent, 'roadMapInformation', null)} />
          )}
          {showTrainer && (
            <Trainer trainer={_.get(homeContent, 'trainerInformation', null)} />
          )}
          {showRoadMap && showTrainer && (
            <StyledDotBg src="/image/home_dot_pattern.svg" />
          )}
        </Box>
        {showTraining && (
          <TrainingPlatform
            training={_.get(homeContent, 'trainingPlatformInformation', null)}
          />
        )}
        {showCourse && (
          <Course
            coursesTitle={_.get(homeContent, 'coursesTitle', null)}
            courses={_.get(homeContent, 'coursesInformation', null)}
          />
        )}
      </StyledHome>
      <Footer />
    </>
  )
}

export default Preview
