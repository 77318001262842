import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import _ from 'lodash'

import PreviewImg from '../../components/Preview/PreviewImg'
import { useDispatch } from 'react-redux'
import { checkUpload } from '../../utils/lib'

const Upload = (props) => {
  const {
    handleUploadFile,
    value,
    isShowDescription,
    name,
    style,
    onDelete,
    formik,
    setDialog,
    disabled,
  } = props
  const [src, setSrc] = useState(value)
  const dispatch = useDispatch()
  const idImage = `icon-button-file-${name}`
  useEffect(() => {
    if (value && value !== '') {
      setUrlImage(value)
    }
  }, [value])
  const setUrlImage = async (key) => {
    setSrc(`${window.__env__.REACT_APP_API_URL}/file/${key}`)
  }

  const onUpload = (e) => {
    const check = dispatch(
      checkUpload({
        type: 'image',
        events: e,
        id: idImage,
      })
    )
    if (!check) handleUploadFile({ e, setDialog, formik, dispatch, name })
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body1">รูปภาพ (สำหรับแสดงหน้า Learner)</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 1,
          mb: 3,
        }}
      >
        {src && src !== '' ? (
          <Box sx={{ mr: 3, height: 120, width: 120 }}>
            <PreviewImg
              srcImg={src}
              setSrc={setSrc}
              isPreview={true}
              style={style}
            />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ height: 120, width: 120, mr: 3 }}>
            <Box
              sx={{
                backgroundColor: '#E0E0E0',
                height: 120,
                width: 120,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <img src="/icon/ic_blank_img.png" alt="ic_blank_img" />
            </Box>
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <label htmlFor={idImage}>
              <Button
                sx={{ mr: 3 }}
                variant="contained"
                size="m"
                component="span"
                disabled={disabled}
              >
                <InputUpload
                  accept="image/jpg, image/jpeg, image/png"
                  id={idImage}
                  type="file"
                  disabled={disabled}
                  onChange={onUpload}
                />
                <Typography color="text.silver" sx={{ cursor: 'pointer' }}>
                  อัปโหลดรูปภาพ
                </Typography>
              </Button>
            </label>
            {src !== undefined && src !== '' && (
              <Button
                data-testid={'btn-img-delete'}
                variant="outlined"
                size="m"
                component="span"
                onClick={() => {
                  onDelete(formik)
                  setSrc('')
                  _.set(document.getElementById(idImage), 'value', null)
                }}
                disabled={disabled}
              >
                <Typography sx={{ cursor: 'pointer' }}>ลบรูปภาพ</Typography>
              </Button>
            )}
          </Box>

          <Box sx={{ flexDirection: 'row', mt: 1 }}>
            {isShowDescription !== false && (
              <>
                <Typography color="body2">ขนาดแนะนำ 240x240px,</Typography>
                <Typography color="body2">
                  นามสกุลไฟล์ที่รองรับ .jpg, .jpeg, .png
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const InputUpload = styled('input')({
  display: 'none',
})

export default Upload
