import _ from 'lodash'

export const handleCourseTime = (data) => {
  const hours = _.get(data, 'hours', 0)
  const minutes = _.get(data, 'minutes', 0)

  if (hours > 0 && minutes > 0) return `${hours} ชม. ${minutes} นาที`
  if (hours == 0) return `${minutes} นาที`
  if (minutes == 0) return `${hours} ชม.`
}
