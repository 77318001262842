import React from 'react'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import localforage from 'localforage'
import Switch from '../../../../components/Version/Switch'
import VersionDialog from '../../../../components/Version/Dialog'
import { tooltipText } from '../../../../constants/eTesting'
import { validateEPermission } from '../../../../utils/lib'
import { StyledHeaderContainer } from '../../Styled'
import { handleSwitch, handleToggleButton } from './events'

const Index = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { table, search, filter } = useSelector((state) => state.table)
  const {
    limit,
    order,
    page,
    sort,
    isAvailable,
    isDisable,
    uuid,
    eEvaluation,
  } = table
  const { status, filterProp } = filter
  const { searchText } = search
  const { id: codeId } = useParams()
  const tableProps = {
    table,
    limit,
    order,
    page,
    sort,
    status,
    filterProp,
    searchText,
    codeId,
  }
  const getPermissionCreate = validateEPermission({
    module: 'E_EVALUATION_SETTING',
    permission: 'CREATE',
    data: eEvaluation,
  })
  const getPermissionEdit = validateEPermission({
    module: 'E_EVALUATION_SETTING',
    permission: 'EDIT',
    data: eEvaluation,
  })

  const eEvaluationType =
    _.get(eEvaluation, 'eEvaluationType', 'CLASS') == 'CLASS'
      ? 'class'
      : 'standalone'
  const url = `/e-evaluation/create-version/${eEvaluationType}/${codeId}`

  return (
    <>
      {getPermissionCreate && (
        <StyledHeaderContainer>
          <Button
            data-testid="btn-create"
            variant="contained"
            onClick={() => {
              localforage.removeItem('persist:root')
              history.push(url)
            }}
            size="m"
          >
            <Add />
            สร้างเวอร์ชั่นใหม่
          </Button>
        </StyledHeaderContainer>
      )}
      {getPermissionEdit && (
        <Switch
          title={tooltipText}
          onChange={(e) => dispatch(handleSwitch(e, uuid, tableProps))}
          isDisable={isDisable}
          isAvailable={isAvailable}
        />
      )}
    </>
  )
}

export default Index

export const DialogContent = () => {
  const dispatch = useDispatch()
  return (
    <VersionDialog onChange={(e) => dispatch(handleToggleButton(e))}>
      การปิดใช้งาน จะ
      <span>ไม่มีผลย้อนหลัง</span> กับหลักสูตร
      หรือคลาสที่เลือกแบบประเมินนี้ไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
      ยังคงสามารถใช้งานแบบประเมินนี้ต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง
      ที่หน้าจัดการหลักสูตร
    </VersionDialog>
  )
}
