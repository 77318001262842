import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'

export const handleShowDetails = async (idx) => {
  const { showDetails } = store.getState().crud.formData.expenseList[idx]
  store.dispatch(
    setFieldValue({
      key: `formData.expenseList[${idx}].showDetails`,
      value: !showDetails,
    }),
  )
}
