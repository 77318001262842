import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DragHandle from '@mui/icons-material/DragHandle'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  StyledAnswerContent,
  StyledAnswerList,
  StyledChoiceContent,
  StyledDragBox,
  StyledImgBox,
  StyledNumberBadge,
  StyledSequenceContainer,
} from './Styled'
import { onDragEnd } from './events'

const Sequence = ({ keyQuestion, answer, isSubmit, questionResult }) => {
  const dispatch = useDispatch()
  return (
    <StyledSequenceContainer>
      <DragDropContext
        onDragEnd={(e) => dispatch(onDragEnd(e, answer, keyQuestion))}
      >
        <Droppable droppableId="answers">
          {(provided) => (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {answer.map((item, index) => {
                const isAnswer = item.sequence === index + 1
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <StyledAnswerList
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={index}
                        resultProps={{ isSubmit, isAnswer, questionResult }}
                      >
                        <StyledAnswerContent
                          {...provided.dragHandleProps}
                          index={index}
                          isSubmit={isSubmit}
                        >
                          {!isSubmit && (
                            <StyledDragBox img={item.mediaUrl}>
                              <DragHandle />
                            </StyledDragBox>
                          )}
                          <StyledChoiceContent>
                            {item.mediaUrl && (
                              <StyledImgBox isSubmit={isSubmit}>
                                <img
                                  src={`${window.__env__.REACT_APP_API_URL}/file${item.mediaUrl}`}
                                />
                              </StyledImgBox>
                            )}
                            <Typography sx={{ wordBreak: 'break-word' }}>
                              {_.get(item, 'title', '')}
                            </Typography>
                          </StyledChoiceContent>
                        </StyledAnswerContent>
                        {isSubmit &&
                          questionResult.resultType == 'SHOW_TRUE_FALSE' && (
                            <StyledNumberBadge isAnswer={isAnswer}>
                              {_.get(item, 'sequence', 0)}
                            </StyledNumberBadge>
                          )}
                      </StyledAnswerList>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </StyledSequenceContainer>
  )
}

export default Sequence
