import styled from '@mui/system/styled'
import Typography from '@mui/material/Typography'

export const StyledChipTypography = styled(Typography)(({ theme }) => ({
  height: 22,
  lineHeight: '20px',
  border: '1px solid',
  borderColor: theme.palette?.text?.lightGray,
  borderRadius: 100,
  padding: `0 ${theme.spacing(1)}`,
  marginTop: theme.spacing(0.5),
}))
