import { store } from '../../../../../App'
import { convertFloatWithComma } from '../../../../../utils/lib'
import _ from 'lodash'
import { setRows } from '../../../../../redux/slices/table'

export const handleSetDataRows = () => {
  const { rows } = store.getState().table.table
  const modifiedRows = _.defaultTo(rows, []).map((item) => {
    const total =
      item?.total && item?.total !== '-'
        ? convertFloatWithComma(String(item?.total).replace(/,/g, ''))
        : '-'
    const available =
      item?.available && item?.available !== '-'
        ? convertFloatWithComma(String(item?.available).replace(/,/g, ''))
        : '-'
    const stockValue =
      item?.stockValue && item?.stockValue !== '-'
        ? convertFloatWithComma(String(item?.stockValue).replace(/,/g, ''))
        : '-'
    return {
      ...item,
      total,
      available,
      stockValue,
    }
  })
  store.dispatch(setRows(modifiedRows))
}
