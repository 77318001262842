import { fetchDepartmentList } from 'src/modules/MonthlyPlan/handler/fetchDepartmentList'
import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const COLUMN_NAME = {
  NO: 'no',
  AGENT_CODE: 'agentCode',
  STAFF_NAME_TH: 'staffNameTH',
  JOB_TITLE: 'jobTitle',
  DEPARTMENT: 'department',
  STATION: 'station',
  POSITION: 'position',
  LEVEL_EMPLOYEE: 'levelEmployee',
  COURSE_CODE: 'courseCode',
  COURSE_NAME_EN: 'courseNameEN',
  TRAINING_DATE: 'trainingDate',
  CLASSROOM_TOTAL: 'classroomTotal',
  ONLINE_TOTAL: 'onlineTotal',
  OFFICE_WFO: 'officeWFO',
  OFFICE_SM: 'officeSmart',
  CERTIFIED: 'certified',
  TRAVELLING: 'travelling',
  EXTERNAL_TRAINING: 'externalTraining',
  ACADEMY_TRAINING: 'academyTraining',
  MEETING: 'meeting',
  OTHER_ACTIVITY: 'otherActivity',
  ACADEMY_TOTAL: 'academyTotal',
  CLASSROOM_ACTIVITY: 'classroomActivity',
  ONLINE_ACTIVITY: 'onlineActivity',
  ACTIVITY_TOTAL: 'activityTotal',
  ACTIVITY_SUPPORT: 'activitySupport',
  PUBLIC_HOLIDAY: 'publicHoliday',
  HOLIDAY: 'holiday',
  HOLIDAY_TOTAL: 'holidayTotal',
  ANNUAL_LEAVE: 'annualLeave',
  BIRTHDAY_LEAVE: 'birthdayLeave',
  FAMILY_CARE_LEAVE: 'familyCareLeave',
  PERSONAL_LEAVE: 'personalLeave',
  SICK_LEAVE: 'sickLeave',
  OTHER_LEAVE: 'otherLeave',
  DAY_OFF: 'dayOff',
  LEAVE_TOTAL: 'leaveTotal',
  LEAVE: 'leave',
  SUM_WORKLOAD_TRAINING: 'sumWorkloadTraining',
  SUM_WORKLOAD_ACADEMY: 'sumWorkloadAcademy',
  SUM_WORKLOAD_ACTIVITY: 'sumWorkloadActivity',
  SUM_WORKLOAD_HOLIDAY: 'sumWorkloadHoliday',
  SUM_WORKLOAD_LEAVE: 'sumWorkloadLeave',
  SUMMARY_TOTAL: 'summaryTotal',
  LEVEL: 'level',
  TRAINING: 'training',
  AREA: 'area',
  PUBLIC_HOLIDAY_YEARLY: 'publicHolidayYearly',
  HOLIDAY_TOTAL_YEARLY: 'holidayTotalYearly',
  SUM_WORKLOAD_HOLIDAY_YEARLY: 'sumWorkloadHolidayYearly',
  ACADEMY_TOTAL_YEARLY: 'academyTotalYearly',
  SUM_WORKLOAD_ACADEMY_YEARLY: 'sumWorkloadAcademyYearly',
  SUMMARY_TOTAL_YEARLY: 'summaryTotalYearly',
}

export const COLUMN_NAME_TH = {
  NO: 'No',
  AGENT_CODE: 'รหัสพนักงาน',
  STAFF_NAME_TH: 'ชื่อ - สกุล',
  STAFF_NAME_EMP: 'ชื่อ - สกุล พนักงาน',
  JOB_TITLE: 'Job title',
  DEPARTMENT: 'Department',
  STATION: 'Station',
  POSITION: 'ประเภทของพนักงาน',
  LEVEL_EMPLOYEE: 'Level Employee',
  COURSE_CODE: 'Course Code',
  COURSE_NAME_EN: 'Course Name',
  TRAINING_DATE: 'วันที่จัดอบรม',
  TRAINING: 'Training(Classroom,Online)',
  OFFICE_WFO: 'Office Work (Work form office)',
  OFFICE_SM: 'Office Work (Smart working)',
  CERTIFIED: 'Certified',
  TRAVELLING: 'Travelling',
  EXTERNAL_TRAINING: 'External Training',
  ACADEMY_TRAINING: 'Academy Training',
  MEETING: 'Meeting',
  OTHER_ACTIVITY: 'Other Activity',
  CLASSROOM_ACTIVITY: 'Activity Support Classroom',
  ONLINE_ACTIVITY: 'Activity Support Online',
  ACTIVITY_SUPPORT: 'Activity Support',
  PUBLIC_HOLIDAY: 'วันหยุด / หยุดชดเชย',
  HOLIDAY: 'Holiday',
  ANNUAL_LEAVE: 'Annual Leave',
  BIRTHDAY_LEAVE: 'Birthday Leave',
  FAMILY_CARE_LEAVE: 'Family Care Leave',
  PERSONAL_LEAVE: 'Personal Leave',
  SICK_LEAVE: 'Sick Leave',
  OTHER_LEAVE: 'Other Leave',
  DAY_OFF: 'Day Off',
  LEAVE: 'Leave',
  SUM_WORKLOAD_TRAINING: 'Summary Workload Training(Classroom/Online)',
  SUM_WORKLOAD_ACADEMY: 'Summary Workload Academy Activity',
  SUM_WORKLOAD_ACTIVITY: 'Summary Workload Activity Support',
  SUM_WORKLOAD_HOLIDAY: 'Summary Workload Holiday',
  SUM_WORKLOAD_LEAVE: 'Summary Workload Leave',
  SUMMARY_TOTAL: 'รวมทั้งหมด',
  TOTAL: 'รวม',
}

export const positionOptions = [
  { label: 'Staff', value: 'Staff' },
  { label: 'Trainer', value: 'Trainer' },
]

export const TYPE_OF_VALUE = {
  DATE_RANGE: 'DATE_RANGE',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
}

export const filterColumnOptions = [
  {
    id: 1,
    name: COLUMN_NAME.TRAINING_DATE,
    value: COLUMN_NAME.TRAINING_DATE,
    label: COLUMN_NAME_TH.TRAINING_DATE,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 2,
    name: COLUMN_NAME.DEPARTMENT,
    value: COLUMN_NAME.DEPARTMENT,
    label: COLUMN_NAME_TH.DEPARTMENT,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchDepartmentList(),
  },
  {
    id: 3,
    name: COLUMN_NAME.STATION,
    value: COLUMN_NAME.STATION,
    label: COLUMN_NAME_TH.STATION,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.STATION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 4,
    name: COLUMN_NAME.POSITION,
    value: COLUMN_NAME.POSITION,
    label: COLUMN_NAME_TH.POSITION,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    options: positionOptions,
  },
  {
    id: 5,
    name: COLUMN_NAME.LEVEL_EMPLOYEE,
    value: COLUMN_NAME.LEVEL_EMPLOYEE,
    label: COLUMN_NAME_TH.LEVEL_EMPLOYEE,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.LEVEL,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 6,
    name: COLUMN_NAME.AGENT_CODE,
    value: COLUMN_NAME.AGENT_CODE,
    label: COLUMN_NAME_TH.AGENT_CODE,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 7,
    name: COLUMN_NAME.STAFF_NAME_TH,
    value: COLUMN_NAME.STAFF_NAME_TH,
    label: COLUMN_NAME_TH.STAFF_NAME_EMP,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 8,
    name: COLUMN_NAME.JOB_TITLE,
    value: COLUMN_NAME.JOB_TITLE,
    label: COLUMN_NAME_TH.JOB_TITLE,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.JOB_TITLE,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 9,
    name: COLUMN_NAME.COURSE_CODE,
    value: COLUMN_NAME.COURSE_CODE,
    label: COLUMN_NAME_TH.COURSE_CODE,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 10,
    name: COLUMN_NAME.COURSE_NAME_EN,
    value: COLUMN_NAME.COURSE_NAME_EN,
    label: COLUMN_NAME_TH.COURSE_NAME_EN,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
]

export const defaultColumns = [
  {
    id: 'type',
    minWidth: 100,
  },
  {
    id: 'list',
    label: 'รายการ',
    minWidth: 190,
  },
]

export const TYPE_CLASS = {
  OPEN_REGISTRATION: 'OPENREGISTRATION',
  CLOSE_REGISTRATION: 'CLOSEREGISTRATION',
  START_CLASS: 'STARTCLASS',
  CANCELED: 'CANCELED',
  OTHER: 'OTHER',
  END_CLASS: 'ENDCLASS',
  CLOSED: 'CLOSED',
}

export const WORK_TYPE = {
  ACADEMY_TRAINING: 'academyTraining',
  CERTIFIED: 'certified',
  EXTERNAL_TRAINING: 'externalTraining',
  MEETING: 'meeting',
  OFFICE_WORK: 'officeWork',
  OTHER_ACTIVITY: 'otherActivities',
  TRAVELING: 'traveling',
}

export const TYPE_CLASS_LABEL = {
  OPENREGISTRATION: 'กำลังเปิดรับสมัคร',
  CLOSEREGISTRATION: 'รอการอบรม',
  STARTCLASS: 'กำลังอบรม',
  CANCELED: 'ยกเลิก',
  OTHER: 'อื่นๆ',
  ENDCLASS: 'End Class',
  CLOSED: 'Closed Class',
}
