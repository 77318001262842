import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../../../components/Input/Autocomplete'

const Index = ({
  type,
  itemIndex,
  label,
  listOptions,
  value,
  onChangeSelect,
  textError,
  disabled,
}) => {
  return (
    <Autocomplete
      id={`select-${type}-${itemIndex}`}
      name={`select-${type}-${itemIndex}`}
      labelText={label}
      boxSx={{ mt: 2, width: '100%' }}
      isHideSort={true}
      options={listOptions}
      defaultValue={value}
      disabled={!type || disabled}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField placeholder={'กรุณาเลือก'} {...params} />
      )}
      handleChange={onChangeSelect}
      textError={textError}
    />
  )
}

export default Index
