import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '60px',
  justifyContent: 'flex-end',
  borderLeft: `1px solid ${theme?.palette?.text?.silver}`,
  marginLeft: theme.spacing(2),
  height: '46px',
  alignItems: 'center',
  marginTop: '-5px',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
  },
}))

export const BoxFooter = styled(Box)(({ theme, isRating }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: theme.spacing(3),
  marginBottom: theme.spacing(2),
  marginRight: isRating ? theme.spacing(2.5) : theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
  },
}))
