import { eConfiguration, eConfigurationHistory } from '../../utils/apiPath'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getEConfiguration = createAsyncThunk(
  'eConfiguration/getEConfiguration',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(eConfiguration)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postEConfiguration = createAsyncThunk(
  'eConfiguration/postEConfiguration',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(eConfiguration, payload)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getEConfigurationHistory = createAsyncThunk(
  'eConfiguration/getEConfigurationHistory',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(eConfigurationHistory, payload)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
