import Tabs from '@mui/material/Tabs'
import styled from '@mui/system/styled'

export const StyledTab = styled(Tabs)(({ theme }) => ({
  zIndex: -1,
  width: '50%',
  paddingTop: theme.spacing(12),
  '& .MuiTabs-indicator': { height: theme.spacing(0.5) },
  [theme.breakpoints.down('md')]: { paddingTop: theme.spacing(20) },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    width: '100%',
    '& .MuiTab-root': { width: '50%' },
  },
}))

export const StyledTabView = styled(Tabs)(({ theme }) => ({
  zIndex: -1,
  width: '50%',
  '& .MuiTabs-indicator': { height: theme.spacing(0.5) },
}))
