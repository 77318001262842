import { store } from '../../../../../App'
import { setHandleChange } from '../../../../../redux/slices/table'
import { setFieldValue } from '../../../../../redux/slices/crud'

import { mutateFilterSateToProp } from '../components/overviewTab/mutation/filterStateToProp'
import { mutateFilterPropToBody } from '../components/overviewTab/mutation/filterPropToBody'
import { filterEtestingDashboardOverview } from '../../../../../utils/apiPath'
import { handleFiltering } from '../../Listing/handler/handleFiltering'

export const handleClearFilter = async (
  filterState,
  key,
  uuid,
  filterTotal,
  isClassUuid,
) => {
  if (key === 'filterDate') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.startDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.startDateDisplayDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.startDate',
        value: '',
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.endDate',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.startDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterState.startDateDisplayDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.startDate',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.endDate',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.isClearFilter',
        value: 'date',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.isClearFilter',
        value: 'date',
      }),
    )
  }
  if (key === 'filterTestDate') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.startTestDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.startTestDateDisplayDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.startTestDate',
        value: '',
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.endTestDate',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.startTestDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.startTestDateDisplayDateChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.startTestDate',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.endTestDate',
        value: '',
      }),
    )
  }
  if (key === 'testerFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.userProfileUuidChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.userProfileUuid',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.userProfileUuidChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.userProfileUuid',
        value: '',
      }),
    )
  }
  if (key === 'courseCodeFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.courseCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.courseCode',
        value: '',
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.classUuid',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.courseCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.courseCode',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.classUuid',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'classUuidLabel',
        value: '',
      }),
    )
    if (isClassUuid) {
      store.dispatch(
        setHandleChange({
          key: 'filter.filterTotal',
          value: filterTotal - 1,
        }),
      )
    }
  }
  if (key === 'classUuidFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.classUuidChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.classUuid',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.classUuidChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.classUuid',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'classUuidLabel',
        value: '',
      }),
    )
  }
  if (key === 'distributionFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.distributionChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.distribution',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.distributionChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.distribution',
        value: '',
      }),
    )
  }
  if (key === 'unitCodeFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.unitCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.unitCode',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.unitCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.unitCode',
        value: '',
      }),
    )
  }
  if (key === 'sRamFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.sRamChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.sRam',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.sRamChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.sRam',
        value: '',
      }),
    )
  }
  if (key === 'ramCodeFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.ramCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.ramCode',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.ramCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.ramCode',
        value: '',
      }),
    )
  }
  if (key === 'trainerFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.trainerChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.trainer',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.trainerChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.trainer',
        value: '',
      }),
    )
  }
  if (key === 'agentCodeFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.agentCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterState.agentCode',
        value: '',
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.agentCodeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.agentCode',
        value: '',
      }),
    )
  }
  if (key === 'testTypeFilter') {
    store.dispatch(
      setHandleChange({
        key: 'filterState.testTypeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt.testTypeChecked',
        value: false,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: `filterState.testType`,
        value: {
          preTestChecked: false,
          postTestChecked: false,
          otherChecked: false,
        },
      }),
    )
    store.dispatch(
      setFieldValue({
        key: `filterStateBelt.testType`,
        value: {
          preTestChecked: false,
          postTestChecked: false,
          otherChecked: false,
        },
      }),
    )
  }
  store.dispatch(
    setHandleChange({
      key: 'filter.filterTotal',
      value: filterTotal - 1,
    }),
  )
  store.dispatch(
    handleFiltering(mutateFilterSateToProp, mutateFilterPropToBody, {
      hideDownload: true,
      isDashboardOverviewPage: true,
      uuid: uuid,
      placeholder: 'ค้นหาด้วยคะแนน',
      url: filterEtestingDashboardOverview,
    }),
  )
}
