import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import Table from '../../../components/redux/Table'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from './Styled'
import { getQueryParams } from '../../../../src/utils/lib'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchDataCount, fetchDataList } from './event'
import Header from './Header'

const EvaStatus = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const trainingPlan = getQueryParams('trainingPlanUuid')
  const [data, setData] = useState([])
  const { table } = useSelector((state) => state.table, shallowEqual)
  const { limit, order, page, sort, isLoading } = table
  const [value, setValue] = useState('')

  useEffect(() => {
    dispatch(fetchDataCount(trainingPlan, uuid, setData))
    dispatch(fetchDataList(table, trainingPlan, uuid))
  }, [limit, order, page, sort])

  const getData = _.get(data, 'returnLearner', [])
  const done = getData.filter((i) => i.status === 'DONE')
  const notDone = getData.filter((i) => i.status === 'NOT_YET_DONE')

  const onChange = (tab) => {
    setValue(tab)
    dispatch(fetchDataList(table, trainingPlan, uuid, tab))
  }

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Header data={data} />

        <Tabs value={value} onChange={(e, tab) => onChange(tab)}>
          <Tab
            data-testid="tab-all"
            label={`ทั้งหมด (${_.size(getData)})`}
            value=""
          />
          <Tab
            data-testid="tab-done"
            label={`ทำแล้ว (${_.size(done)})`}
            value="DONE"
          />
          <Tab
            data-testid="tab-not-yet"
            label={`ยังไม่ได้ทำ (${_.size(notDone)})`}
            value="NOT_YET_DONE"
          />
        </Tabs>
        <StyledCard>
          <Table />
        </StyledCard>
      </StyledContent>

      <LoadingPage />
    </StyledContainer>
  )
}
export default EvaStatus
