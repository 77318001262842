const { createSlice } = require('@reduxjs/toolkit')
import { initialState } from './model'

const knowledgeBaseForm = createSlice({
  name: 'knowledgeBaseForm',
  initialState,
  reducers: {
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      state.body[key] = value
    },
    setCropperData: (state, { payload }) => {
      const { key, value } = payload
      state.cropper[key] = value
    },
    startUploadLoading: (state) => {
      state.isUploadLoading = true
    },
    stopUploadLoading: (state) => {
      state.isUploadLoading = false
    },
    setUploadSuccess: (state) => {
      state.isUploadSuccess = true
    },
  },
})

export const {
  setChange,
  setFieldValue,
  setCropperData,
  setUploadSuccess,
  startUploadLoading,
  stopUploadLoading,
} = knowledgeBaseForm.actions

export default knowledgeBaseForm.reducer
