export const defaultFilter = {
  idChecked: false,
  id: '',
  nameChecked: false,
  name: '',
  openDateChecked: false,
  openDateStart: '',
  openDateFinish: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
    draftChecked: false,
  },
}
