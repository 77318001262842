import { Box, Typography } from '@mui/material'
import ModuleCard from './ModuleCard'
import _ from 'lodash'
import { Container } from '../Styled'
import { convertNumberWithComma } from '../../../../../../../utils/lib'

export const DataCard = ({
  dataList,
  title,
  border,
  totalModule,
  padding,
  handleClickButton,
}) => {
  return (
    <Container
      border={!_.isEmpty(border) ? border : 'solid 1px #DBE4F1'}
      sx={{ width: '100%' }}
      padding={padding}
    >
      <Typography variant="h6">{title}</Typography>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        mt={2}
        sx={{
          flexWrap: {
            xs: 'wrap',
            sm: 'wrap',
            md: 'wrap',
          },
        }}
      >
        {_.defaultTo(dataList, []).map((ele, index) => {
          const percent =
            ele.number > 0 ? ((ele.number / totalModule) * 100).toFixed(2) : 0
          return (
            <Box key={`totalUser-${ele.type}-${index}`} minHeight={'92px'}>
              <ModuleCard
                key={index}
                label={ele.label}
                subLable={ele.subLable}
                labelVariant={ele.labelVariant}
                background={ele.background}
                dotColor={ele.dotColor}
                value={
                  ele.isString
                    ? ele.number
                    : ele.number > 0
                    ? convertNumberWithComma(ele.number)
                    : 0
                }
                unit={ele.unit}
                percent={ele?.hidePercent ? ' ' : `${percent}%`}
                minWidth={'133.33px'}
                padding={'8px 8px'}
                boxSx={{
                  height: '100%',
                  width: {
                    sm: '200px',
                    md: '250px',
                    lg: ele?.width,
                  },
                }}
                handleClickButton={
                  handleClickButton && !ele.hideClick
                    ? () => {
                        handleClickButton(ele)
                      }
                    : undefined
                }
                border={ele.border}
              />
            </Box>
          )
        })}
      </Box>
    </Container>
  )
}
