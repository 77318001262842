import styled from '@mui/system/styled'
import _ from 'lodash'
import { Row } from '../../../../../../../components/Preview/Layout/Styled'

export const StyledFaceCard = styled(Row)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  background: theme.palette?.background?.blue,
  borderRadius: theme.spacing(1),
  gap: theme.spacing(1),
}))

export const StyledImageWrapper = styled(Row)(({ theme }) => ({
  gap: theme.spacing(1),
  overflow: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledImage = styled('img')(({ theme, status }) => ({
  width: 100,
  height: 100,
  border: '2px solid',
  borderColor: _.get(theme.palette, STATUS_COLOR[status], 'unset'),
}))

export const STATUS_COLOR = {
  SUCCESS: 'success.main',
  FAIL: 'error.main',
}
