import { handleChange } from '../../event'
import {
  BOOKING_PERIOD_TYPE,
  OVERTIME,
  overTimeOptions,
  periodOptions,
} from '../../../../../constants/roomManagement'
import _ from 'lodash'
import dayjs from 'dayjs'
import { store } from '../../../../../App'

export const generateRooms = (props) => {
  const { startDate, endDate, roomBookingList, bookingPeriod } = props
  const currentDate = new Date(new Date(startDate))
  let newRoomBookingList = []
  let roomBookingItem = {
    bookingDate: startDate,
    masterRooms: [],
    isLoadingMasterRooms: false,
    period: '',
    startTimeTraining: '',
    endTimeTraining: '',
    startTimeRoom: '',
    endTimeRoom: '',
    rooms: [
      {
        meetingRoomUuid: '',
        meetingRoomOther: null,
        numberOfAttendees: null,
        roomLayout: '',
        additionalEquipment: '',
        numberOfGroup: null,
        attendeeInGroup: null,
        isLoading: false,
        meetingRoom: null,
      },
    ],
  }

  while (currentDate <= new Date(endDate)) {
    const currentBooking = roomBookingList.find((item) => {
      return item.bookingDate === dayjs(currentDate).format('YYYY-MM-DD')
    })
    const booking =
      bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME &&
      roomBookingList.length > 0
        ? roomBookingList[0]
        : currentBooking
        ? currentBooking
        : roomBookingItem

    const { startTimeTraining, endTimeTraining } = booking
    const startDateTraining = new Date(`${startDate} 00:00`)
    const startTraining = new Date(`${startDate} ${startTimeTraining}`)
    const overTimeBeforeOptions = handleOverTimeOptions(
      startDateTraining,
      startTraining,
    )

    const endDateTraining = new Date(`${startDate} 23:59`)
    const endTraining = new Date(`${startDate} ${endTimeTraining}`)
    const overTimeAfterOptions = handleOverTimeOptions(
      endDateTraining,
      endTraining,
    )

    const newRoomBookingItem = {
      ...booking,
      bookingDate: dayjs(currentDate).format('YYYY-MM-DD'),
      beforeTraining: OVERTIME.AT_TRAINING_TIME,
      afterTraining: OVERTIME.AT_TRAINING_TIME,
      overTimeAfterOptions: overTimeAfterOptions,
      overTimeBeforeOptions: overTimeBeforeOptions,
    }
    newRoomBookingList.push(newRoomBookingItem)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return newRoomBookingList
}

export const handlePeriod = (state, value) => {
  // const { beforeTraining, afterTraining } = state
  const startTimeTraining = _.chain(periodOptions)
    .find({ value: value })
    .get('startTimeTraining', '')
    .value()

  const endTimeTraining = _.chain(periodOptions)
    .find({ value: value })
    .get('endTimeTraining', '')
    .value()
  // const startTimeRoom = handleStartTimeRoom(
  //   beforeTraining,
  //   startTimeTraining,
  //   bookingDate
  // )
  //
  // const endTimeRoom = handleEndTimeRoom(
  //   afterTraining,
  //   endTimeTraining,
  //   bookingDate
  // )
  return {
    startTimeTraining: startTimeTraining,
    endTimeTraining: endTimeTraining,
  }
}

export const handleStartTimeRoom = (value, startTimeTraining, startDate) => {
  let currentDateTime
  let newDateTime = ''
  currentDateTime = dayjs(new Date(`${startDate} ${startTimeTraining}`))
  switch (value) {
    case OVERTIME.AT_TRAINING_TIME:
      newDateTime = startTimeTraining
      break
    case OVERTIME.HALF_HOUR:
      newDateTime = currentDateTime.add(-30, 'minute').format('HH:mm')
      break
    case OVERTIME.ONE_HOUR:
      newDateTime = currentDateTime.add(-1, 'hour').format('HH:mm')
      break
    case OVERTIME.ONE_HALF_HOURS:
      newDateTime = currentDateTime
        .add(-1, 'hour')
        .add(-30, 'minute')
        .format('HH:mm')
      break
    case OVERTIME.TWO_HOURS:
      newDateTime = currentDateTime.add(-2, 'hour').format('HH:mm')
      break
  }

  return newDateTime
}

export const handleEndTimeRoom = (value, endTimeTraining, endDate) => {
  let currentDateTime
  let newDateTime = ''

  currentDateTime = dayjs(new Date(`${endDate} ${endTimeTraining}`))
  switch (value) {
    case OVERTIME.AT_TRAINING_TIME:
      newDateTime = endTimeTraining
      break
    case OVERTIME.HALF_HOUR:
      newDateTime = currentDateTime.add(30, 'minute').format('HH:mm')
      break
    case OVERTIME.ONE_HOUR:
      newDateTime = currentDateTime.add(1, 'hour').format('HH:mm')
      break
    case OVERTIME.ONE_HALF_HOURS:
      newDateTime = currentDateTime
        .add(1, 'hour')
        .add(30, 'minute')
        .format('HH:mm')
      break
    case OVERTIME.TWO_HOURS:
      newDateTime = currentDateTime.add(2, 'hour').format('HH:mm')
      break
  }

  return newDateTime
}

export const handleDeleteRoom = (bookingDate, idx) => (dispatch) => {
  const state = store.getState()
  const { roomBookingList } = state.roomManagementDrawer.bookingInformation

  let newRoomBookingList = JSON.parse(JSON.stringify(roomBookingList))

  newRoomBookingList = roomBookingList.map((item) => {
    if (
      state.roomManagementDrawer.bookingInformation.bookingPeriod ===
      BOOKING_PERIOD_TYPE.SAME_TIME
    ) {
      return {
        ...item,
        rooms: item.rooms.filter((_, index) => index !== idx),
      }
    } else if (item.bookingDate === bookingDate) {
      return {
        ...item,
        rooms: item.rooms.filter((_, index) => index !== idx),
      }
    }
    return item
  })

  dispatch(
    handleChange({
      key: 'roomBookingList',
      value: newRoomBookingList,
    }),
  )
}

export const handleRoomLayoutOptions = (room) => {
  const { capacityClassroom, capacityGroup, capacityTheater, capacityUShape } =
    room

  let roomLayoutOptions = []
  if (!_.isNull(capacityClassroom)) {
    roomLayoutOptions.push({
      keyField: 'capacityClassroom',
      value: 'CLASSROOM',
      label: 'Classroom',
    })
  }
  if (!_.isNull(capacityGroup)) {
    roomLayoutOptions.push({
      keyField: 'capacityGroup',
      value: 'GROUP',
      label: 'Group',
    })
  }
  if (!_.isNull(capacityUShape)) {
    roomLayoutOptions.push({
      keyField: 'capacityUShape',
      value: 'USHAPE',
      label: 'U Shape',
    })
  }
  if (!_.isNull(capacityTheater)) {
    roomLayoutOptions.push({
      keyField: 'capacityTheater',
      value: 'THEATER',
      label: 'Theater',
    })
  }
  return roomLayoutOptions
}

export const getAllLayout = () => {
  const roomLayoutOptions = []

  roomLayoutOptions.push({
    keyField: 'capacityClassroom',
    value: 'CLASSROOM',
    label: 'Classroom',
  })

  roomLayoutOptions.push({
    keyField: 'capacityGroup',
    value: 'GROUP',
    label: 'Group',
  })

  roomLayoutOptions.push({
    keyField: 'capacityUShape',
    value: 'USHAPE',
    label: 'U Shape',
  })

  roomLayoutOptions.push({
    keyField: 'capacityTheater',
    value: 'THEATER',
    label: 'Theater',
  })

  return roomLayoutOptions
}

export const handleNumberInput = (e) => {
  const target = _.get(e, 'target', {})
  const regex = /^[0-9,]*$/

  if (!target?.value?.match(regex)) {
    target.value = e.target.value.slice(0, -1)
  }
  return target.value.replaceAll(',', '')
}

export const handleOverTimeOptions = (bookingDate, trainingDate) => {
  const bookingTimeDate = bookingDate.getTime()
  const trainingTimeDate = trainingDate.getTime()

  const diffTime = Math.abs((bookingTimeDate - trainingTimeDate) / (1000 * 60))
  let overTimeList = []

  _.map(overTimeOptions, (overtime) => {
    const { durationInMinute } = overtime
    if (diffTime >= durationInMinute) {
      overTimeList.push(overtime)
    }
  })

  return overTimeList
}

export function isNotSameDate(startDate, endDate) {
  return !dayjs(startDate)
    .startOf('date')
    .isSame(dayjs(endDate).startOf('date'))
}

export function handleDisableRoom(masterRooms, selectedRoomUuid, oldRoomUuid) {
  return masterRooms.map((item) => {
    if (selectedRoomUuid === item['roomUuid']) {
      return {
        ...item,
        disabled: true,
      }
    } else if (oldRoomUuid === item['roomUuid']) {
      return {
        ...item,
        disabled: false,
      }
    }
    return item
  })
}
