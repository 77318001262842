import axios from 'axios'
import { eLearningModuleConvertAgain } from '../../../utils/apiPath'
import { dispatchUploadValue } from '../handler/dispatchUploadValue'

export const postModuleConvertAgain = async ({ body, targetFileField }) => {
  try {
    dispatchUploadValue(`${targetFileField}.percent`, 0)
    const response = await axios.post(eLearningModuleConvertAgain, body, {
      onUploadProgress: (e) => {
        const chunkProgress = (e.loaded / e.total) * 100
        dispatchUploadValue(`${targetFileField}.percent`, chunkProgress)
      },
    })
    return response
  } catch (err) {
    throw new Error(err)
  }
}
