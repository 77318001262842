import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import TextInput from '../../../../../../components/Input/TextInput'
import FileInput from '../../../../../../components/Input/FileInput'
import UploadLoading from '../../../../UploadLoading'
import { StyledColumn, StyledRow } from '../../../Styled'
import { handleSelector } from '../../events'
import {
  ButtonColorPreview,
  StyledImagePreview,
  StyledInputHidden,
} from './Styled'
import {
  handleDelete,
  handleInputTabBody,
  handleSwitchTabBody,
  handleUploadImage,
} from './events'

const TabInformation = ({ keyField }) => {
  const { isUploadLoading, error, tab } = useSelector(
    (state) => ({
      isUploadLoading: _.get(
        state.manageContentELearningHome.isUploadLoading,
        keyField,
        false,
      ),
      tab: handleSelector(state, keyField),
      error: state.manageContentELearningHome.error,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const { values, errors, setValues } = useFormikContext()
  const image = _.get(values, `${keyField}.image`, false)
  const errorText =
    _.get(error, `upload.upload`, null) || _.get(errors, 'image', '')

  return (
    <StyledColumn gap={3}>
      <TextInput
        required
        name="tabTitle"
        labelText="Title"
        type="basic"
        id="input-tab-title"
        placeholder="Title"
        value={_.get(tab, 'tabTitle', '')}
        textError={_.get(errors, `${keyField}.tabTitle`, '')}
        textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
        onChange={(e) => dispatch(handleInputTabBody(e, keyField))}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'Row' }}>
          <Typography variant="body2" color="text.secondary">
            สีตัวอักษร Title
          </Typography>
          <Typography lineHeight="1" color="error">
            *
          </Typography>
        </Box>
        <RadioGroup
          name="whiteTitle"
          value={_.get(values, `${keyField}.whiteTitle`, `false`)}
          onChange={(e) => {
            dispatch(handleInputTabBody(e, keyField))
            setValues((prev) => ({
              ...prev,
              [`${keyField}.whiteTitle`]: e.target.value,
            }))
          }}
        >
          <FormControlLabel
            sx={{ margin: 0, gap: '18px' }}
            value="false"
            control={<Radio />}
            label={<ButtonColorPreview variant="black" />}
          />
          <FormControlLabel
            sx={{ margin: 0, gap: '18px' }}
            value="true"
            control={<Radio />}
            label={<ButtonColorPreview variant="white" />}
          />
        </RadioGroup>
      </Box>
      <StyledRow alignItems="center" flexWrap="wrap">
        <FileInput
          required
          name="tabImage"
          labelText="ภาพพิ้นหลัง"
          type="file"
          id="input-tab-title"
          placeholder="ภาพพิ้นหลัง"
          value={_.get(tab, 'image', '')}
          isShowTextError={!!errorText}
          textError={errorText}
          textInfo="ขนาดแนะนำ 342 x 132 px, ไฟล์ไม่เกิน 2MB, นามสกุลไฟล์ที่รองรับ .jpg, .jpeg, .png"
          switchName="imageStatus"
          switchChecked={_.get(tab, 'imageStatus', false)}
          switchonChanged={(e) => dispatch(handleSwitchTabBody(e, keyField))}
          isImage={image}
          isUploadLoading={isUploadLoading}
          StyledImagePreview={StyledImagePreview}
          UploadLoading={
            <UploadLoading
              keyField="img"
              id={`${keyField}-upload`}
              sx={{
                display: isUploadLoading ? 'flex' : 'none',
                width: 177,
                minWidth: 177,
              }}
            />
          }
          onDelete={() => {
            dispatch(handleDelete(keyField))
            setValues((prev) => ({ ...prev, [`${keyField}.image`]: '' }))
          }}
        >
          <StyledInputHidden
            data-testid="image-upload"
            id="image-upload"
            accept=".jpg, .jpeg, .png"
            type="file"
            onChange={(e) =>
              dispatch(handleUploadImage({ e, keyField, setValues }))
            }
          />
        </FileInput>
      </StyledRow>
    </StyledColumn>
  )
}

export default TabInformation
