import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'
import { Column, Row } from '../../../../../../components/Layout/Styled'

export const StyledContainer = styled(Box)(() => ({
  minWidth: 342,
}))

export const StyledMediaBox = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  img: { height: '100%', objectFit: 'cover' },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '& .carousel-root-custom .slider-wrapper': {
    height: '560px !important',
    [theme.breakpoints.down('sm')]: { height: '461px !important' },
  },
}))

export const StyledWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(2),
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
  padding: `15px ${theme.spacing(10)} 47px`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)} 0`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)} 0`,
  },
}))

export const StyledRowContainer = styled(Row)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledGrid = styled(Grid)(() => ({
  flexWrap: 'nowrap',
  width: 'auto',
}))
