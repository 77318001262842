import {
  setRequestType,
  setTabActive,
  setTypeFilter,
} from '../../../../redux/slices/roomManagement/Dashboard'

export const scrollToMonthlyPlanTicketList = (bookingRoomRef) => (dispatch) => {
  dispatch(setTypeFilter('roomRequest'))
  dispatch(setRequestType('MONTHLY_PLAN'))
  dispatch(setTabActive({ tabActive: 2, actionFromMonthlyPlanCard: true }))
  bookingRoomRef.current.scrollIntoView({
    behavior: 'smooth',
  })
}
