import React, { useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import Add from '@mui/icons-material/Add'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import { COURSE_TYPE } from '../../../../../../constants/eLearning'
import { MINI_ROW_PAGE_PER_LIST } from '../../../../../../constants/table'
import { setOpenDrawer } from '../../../../../../redux/slices/eLearning/courseForm'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange } from '../../events'
import AddDrawer from './AddDrawer'
import { StyledNoDataBox } from './Styled'
import { assigneeType } from './model'

const Assignee = () => {
  const dispatch = useDispatch()
  const { courseType, assignees, onGoing } = useSelector(
    (state) => ({
      courseType: getStateValue('courseType'),
      assignees: getStateValue('setting.assignees', []),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    MINI_ROW_PAGE_PER_LIST[0].value,
  )
  const assineeRows = useMemo(
    () =>
      rowsPerPage > 0
        ? assignees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : assignees,
    [assignees, rowsPerPage, page],
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 0, px: 0, pb: 0 }}>
      <Box sx={{ display: 'flex', px: 3, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="h6">
            ผู้รับผิดชอบ{' '}
            {courseType === COURSE_TYPE.OIC && (
              <Typography variant="span" color="error">
                *
              </Typography>
            )}
          </Typography>
          <Typography variant="body2" color="error">
            {_.get(errors, 'setting.assignees', '')}
          </Typography>
        </Box>
        <Button
          variant="contained"
          data-testid="btn-add"
          startIcon={<Add />}
          onClick={() => dispatch(setOpenDrawer(true))}
          disabled={onGoing}
        >
          จัดการผู้รับผิดชอบ
        </Button>
      </Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={368} sx={{ pl: 5 }}>
              ชื่อ
            </TableCell>
            <TableCell>หน้าที่</TableCell>
          </TableRow>
        </TableHead>
        {assignees.length > 0 && (
          <>
            <TableBody>
              {assineeRows.map((assignee, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" sx={{ pl: 5 }}>
                    {assignee.label}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {assigneeType[assignee.type]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
                  labelRowsPerPage={
                    <Typography variant="caption" color="text.secondary">
                      แถวต่อหน้า{'\xA0'} :
                    </Typography>
                  }
                  rowsPerPageOptions={MINI_ROW_PAGE_PER_LIST}
                  colSpan={3}
                  count={assignees.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10))
                    setPage(0)
                  }}
                />
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {assignees.length === 0 && <NoDataBox />}
      <AddDrawer
        onSubmit={(values) => {
          const speaker = _.get(values, 'speaker', [])
          const admin = _.get(values, 'admin', [])
          dispatch(handleChange('setting.assignees', _.concat(speaker, admin)))
        }}
      />
    </StyledFormCard>
  )
}

export default Assignee

export const NoDataBox = () => {
  return (
    <StyledNoDataBox>
      <InboxTwoTone />
      <Typography variant="body2" color="text.lightGray">
        ไม่พบข้อมูล
      </Typography>
    </StyledNoDataBox>
  )
}
