import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledTrainingContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  gap: 54,
  margin: '40px 100px 0',
  paddingBottom: theme.spacing(7.5),
  [theme.breakpoints.down('lg')]: {
    width: 765,
    margin: '40px auto 0',
    gap: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  [theme.breakpoints.down('sm')]: {
    width: 376,
    marginTop: '15px',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}))

export const StyledSquareBg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 170,
  left: -65,
  zIndex: -1,
  [theme.breakpoints.down('lg')]: { width: 220, top: 110, left: 0 },
  [theme.breakpoints.down('sm')]: { top: 0 },
}))

export const StyledContentHeader = styled(Box)(({ theme }) => ({
  width: 377,
  textAlign: 'right',
  [theme.breakpoints.down('lg')]: {
    width: 205,
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    width: '100%',
    marginTop: '46px',
  },
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'start',
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 20px',
  },
}))

export const StyledDisplayTraining = styled(Box)(({ theme, ml }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: ml,
  maxWidth: 763 - ml,
  minHeight: 88,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  background: theme.palette?.info?.background,
  cursor: 'pointer',
  '& > .MuiTypography-root#title': {
    whiteSpace: 'pre',
    overflow: 'hidden',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
  },
  ':hover': { '& .MuiTypography-root': { color: theme?.palette?.text?.gray } },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    maxWidth: 440 - ml,
    minHeight: 60,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginLeft: 0,
    padding: `${theme.spacing(1)} 14px`,
  },
}))

export const StyledCaption = styled(Typography)(({ theme, ml }) => ({
  maxWidth: 763 - ml,
  marginLeft: 0,
  overflow: 'hidden',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 440 - ml,
    WebkitLineClamp: '1',
  },
}))
