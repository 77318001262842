import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import ReactPlayer from 'react-player'
import { StyledColumn, StyledRow } from '../../Styled'

export const StyledContainerStation = styled(StyledRow)(({ theme }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(5),
  alignItems: 'start',
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(2) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(5) },
}))

export const StyledBoxStation = styled(StyledColumn)(
  ({ theme, status, error }) => ({
    width: 'calc(50% - 20px)',
    minHeight: 394,
    border: '1px solid',
    borderColor: error
      ? theme.palette?.error?.main
      : theme.palette?.other?.outlineBorder,
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    backgroundColor: status
      ? 'transparent'
      : theme.palette?.action?.disabledBackground,
    [theme.breakpoints.down('lg')]: {
      width: 'calc(50% - 8px)',
      minHeight: 600,
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: { width: '100%' },
  })
)

export const StyledStationMedia = styled(Box)(() => ({
  width: 170,
  minWidth: 170,
  height: 170,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
}))

export const StyledTypography = styled(Typography)(({ theme, label }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  p: { margin: 0 },
  [theme.breakpoints.down('lg')]: {
    WebkitLineClamp: label == 'Description' ? '3' : '2',
  },
}))

export const StyledStationBottom = styled(StyledRow)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette?.other?.divider,
  paddingTop: theme.spacing(2.5),
  justifyContent: 'end',
  alignItems: 'center',
}))

export const StyledVideoPreview = styled((props) => (
  <ReactPlayer
    controls
    config={{ vimeo: { playerOptions: { responsive: true } } }}
    {...props}
  />
))(() => ({
  width: '100% !important',
  height: '100% !important',
}))

export const StyledContainContent = styled(StyledRow)(({ theme }) => ({
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'start',
  },
}))
