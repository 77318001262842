import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbListCreate, breadcrumbListEdit } from '../../model'
import { checkScrollWidth, handleScrollToElement } from './events'
import { BoxButton, BoxHeader, BoxLabelTitle } from './Styled'
import { MODULE_STATUS } from '../../../../constants/learningPoint'
import Typography from '@mui/material/Typography'

const Header = () => {
  const { id: uuid } = useParams()
  const [isScrolled, setScroll] = useState(false)
  const { isDraft, body, uploadError } = useSelector(
    (state) => ({
      isDraft: state.catalogForm.isDraft,
      body: state.catalogForm.body,
      uploadError: state.catalogForm.uploadError,
    }),
    shallowEqual,
  )
  const { setValues, submitForm, errors, isValid } = useFormikContext()
  const checkedIsDraft = _.isEmpty(uuid) || isDraft
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])
  useEffect(() => {
    if (!isValid) {
      handleScrollToElement(errors)
    }
  }, [errors])
  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle isEdit={uuid} isDraft={checkedIsDraft}>
        <Typography variant="h4">{uuid ? 'แก้ไข' : 'สร้าง'} Catalog</Typography>
        {!isScrolled && (
          <Breadcrumbs
            menuList={uuid ? breadcrumbListEdit : breadcrumbListCreate}
          />
        )}
      </BoxLabelTitle>
      <BoxButton>
        {checkedIsDraft && (
          <Button
            type="submit"
            data-testid="btn-draft"
            variant="outlined"
            size="m"
            disabled={!_.isEmpty(uploadError)}
            onClick={async () => {
              await setValues({
                ...body,
                status: MODULE_STATUS.DRAFT,
                isDraft: true,
              })
              submitForm()
            }}
          >
            บันทึกฉบับร่าง
          </Button>
        )}
        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={!_.isEmpty(uploadError)}
          onClick={async () => {
            await setValues({ ...body, isDraft: false })
            submitForm()
          }}
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}

export default Header
