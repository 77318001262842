import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useLocalStorage from '@rehooks/local-storage'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/system/useTheme'
import { validatePermission } from '../../../../utils/lib'
import { defaultHeadFieldWork } from '../../MasterData'
import _ from 'lodash'
const FilterDrawer = ({
  open,
  onClose,
  onFilterClick,
  state,
  setState,
  courseSettingList,
  staffList,
  options,
  calendarType,
  isLoading,
}) => {
  const [user] = useLocalStorage('user')
  const theme = useTheme()
  const [filter, setFilter] = useState({})
  const [optionWorkType, setOptionWorkType] = useState([])
  const [filterMemo, setFilterMemo] = useState()
  const { work } = options
  useEffect(() => {
    if (!isLoading) {
      initialFilterState(
        filterMemo,
        setFilter,
        courseSettingList,
        setOptionWorkType,
        work,
        user,
        options,
        filter,
        staffList,
        calendarType,
        state.handleType
      )
    }
  }, [open, isLoading, state.handleType])

  useEffect(() => {
    setFilterMemo(undefined)
  }, [calendarType])

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'sticky',
          top: 0,
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            my: 2,
            ml: 3,
            mr: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            minWidth: 340,
          }}
        >
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            sx={{ p: 0 }}
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </Box>
      <Box
        sx={{
          ml: 3,
          mt: 2,
        }}
      >
        <Typography variant="h6">ประเภทตารางงาน</Typography>
        {optionWorkType.length <= 0 && (
          <Typography variant="body2">ไม่มีประเภทตารางงาน</Typography>
        )}
      </Box>
      {optionWorkType.length > 0 &&
        listCheckbox({
          arrayCheckbox: optionWorkType,
          stateFilter: filter,
          key: 'workSchedule',
          setFilter,
          theme,
        })}

      <Box
        sx={{
          ml: 3,
          mt: 2,
        }}
      >
        <Typography variant="h6">ปฎิทินของทีม</Typography>
        {staffList.length <= 0 && (
          <Typography variant="body2">ไม่มีปฎิทินของทีม</Typography>
        )}
      </Box>

      {staffList.length > 0 &&
        listCheckbox({
          arrayCheckbox: staffList,
          stateFilter: filter,
          key: 'teamCalendar',
          setFilter,
          theme,
        })}

      <Box
        sx={{
          ml: 3,
          mt: 2,
        }}
      >
        <Typography variant="h6">หลักสูตรอบรม</Typography>
        {courseSettingList.length <= 0 && (
          <Typography variant="body2">ไม่มีหลักสูตร</Typography>
        )}
      </Box>

      {courseSettingList.length > 0 &&
        listCheckbox({
          arrayCheckbox: courseSettingList,
          stateFilter: filter,
          key: 'course',
          setFilter,
          theme,
        })}

      <Box
        sx={{
          mt: 3,
          mb: 3,
          mx: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          position: 'sticky',
          bottom: 0,
          zIndex: 2,
          backgroundColor: 'background.paper',
        }}
      >
        <Button
          sx={{ width: '45%' }}
          variant="outlined"
          onClick={() =>
            onClearClick(
              courseSettingList,
              setFilter,
              filter,
              state,
              work,
              staffList
            )
          }
        >
          ล้าง
        </Button>
        <Button
          sx={{ width: '45%' }}
          onClick={() => {
            onClose()
            onFilterClick(filter, setState, calendarType)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </Box>
    </Drawer>
  )
}
export default FilterDrawer

const GroupBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(4),
}))

export const mapListWorkType = (listWorkType) => {
  let result = []
  if (!listWorkType) return result

  result = listWorkType.map((item) => {
    let isTraining = false
    let typeColor = ''
    if (item.value === 'Training' || item.value === 'Activity Support') {
      typeColor = 'training'
      isTraining = true
    } else if (item.value === 'Academy Activity') {
      typeColor = 'academyActivity'
    } else if (item.value === 'Work Office') {
      typeColor = 'academyActivity'
    } else if (item.value === 'Holiday') {
      typeColor = 'holidayBorder'
      isTraining = true
    } else if (item.value === 'Leave') {
      typeColor = 'oceanBlue'
    }

    return {
      label: item.value,
      value: item.uuid,
      typeColor: typeColor,
      isTraining: isTraining,
    }
  })
  return result
}

export const findType = (
  list,
  key,
  isWorkType,
  defaultWorkType = [],
  calendarType
) => {
  let result = {}

  if (list.length) {
    list.forEach((item) => {
      let value = isWorkType
      if (defaultWorkType.length) {
        defaultWorkType.forEach((itemWorkType) => {
          if (itemWorkType === item[key] || calendarType === 'PERSONAL') {
            value = true
          }
        })
      }
      result = {
        ...result,
        [item[key]]: value,
      }
    })
  }
  return result
}

export const handleChange = ({
  key,
  value,
  isKey,
  code,
  filter,
  setFilter,
}) => {
  let objState = {}
  if (isKey === 'course') {
    objState = {
      ...filter,
      [isKey]: {
        ...filter[isKey],
        [key]: {
          ...filter[isKey][key],
          code: code,
          status: value,
        },
      },
    }
  } else {
    objState = {
      ...filter,
      [isKey]: {
        ...filter[isKey],
        [key]: value,
      },
    }
  }

  setFilter(objState)
}

export const onClearClick = (
  courseSettingList,
  setFilter,
  filter,
  state,
  work,
  staffList
) => {
  let listCourse = {}
  if (courseSettingList.length) {
    courseSettingList.filter((item) => {
      listCourse = {
        course: {
          ...listCourse.course,
          [item.value]: {
            status: false,
            code: item.code,
            uuid: item.value,
          },
        },
      }
    })
  }

  const defaultFilter = {
    ...filter,
    workSchedule: findType(work, 'uuid', false, state?.filter?.workTypes),
    teamCalendar: findType(staffList, 'value', false),
    ...listCourse,
  }
  setFilter(defaultFilter)
}

export const initialFilterState = (
  filterMemo,
  setFilter,
  courseSettingList,
  setOptionWorkType,
  work,
  user,
  options,
  filter,
  staffList,
  calendarType,
  handleType
) => {
  if ((filterMemo && handleType === 'filter') || handleType === 'actions') {
    if (filterMemo != undefined) {
      setFilter(filterMemo)
      return
    }
  }

  let listCourse = {}
  if (courseSettingList.length) {
    courseSettingList.map((item) => {
      listCourse = {
        ...listCourse,
        [item.value]: {
          status: true,
          code: item.code,
        },
        course: {
          ...listCourse.course,
          [item.value]: {
            status: true,
            uuid: item.value,
          },
        },
      }
    })
  }
  let defaultWorkTypes = []
  const hasPermissionHF = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_MP'],
  })
  if (hasPermissionHF) {
    const filterWork = options.work.filter((item) =>
      defaultHeadFieldWork.find((work) => work === item.workType)
    )
    defaultWorkTypes = filterWork.map((item) => {
      return item.uuid
    })
  }
  const defaultFilter = {
    ...filter,
    workSchedule: findType(
      work,
      'uuid',
      !hasPermissionHF,
      defaultWorkTypes,
      calendarType
    ),
    teamCalendar: findType(staffList, 'value', true),
    course: listCourse,
  }
  setFilter(defaultFilter)
  setOptionWorkType(mapListWorkType(work))
}

export const listCheckbox = ({
  arrayCheckbox,
  stateFilter,
  key,
  setFilter,
  theme,
}) => {
  return arrayCheckbox.map((item, index) => {
    let check = false
    const listData = _.get(stateFilter, key, '')
    if (!_.isEmpty(listData)) {
      if (key === 'course') {
        check = _.get(stateFilter[key][item.value], 'status', true)
      } else {
        check = _.isUndefined(stateFilter[key][item.value])
          ? true
          : stateFilter[key][item.value]
      }
    }
    return (
      <GroupBox
        key={index}
        sx={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}
      >
        <FormGroup sx={{ width: '100%', maxWidth: 340 }}>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={
              <Checkbox
                name={item.value}
                value={item.code}
                defaultChecked
                checked={check}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    code: e.target.value,
                    isKey: key,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
                typeColor={item.typeColor ? item.typeColor : ''}
              />
            }
            label={
              !item.isInfo ? (
                <Typography sx={{ margin: 'auto 0' }}>{item.label}</Typography>
              ) : (
                <Box sx={{ display: 'flex', pt: 0.8 }}>
                  <Box>{item.label}</Box>

                  <Box sx={{ ml: 1 }}>
                    <Tooltip
                      arrow
                      title={
                        <Typography variant="tooltip">
                          ไม่รวม Work Office
                        </Typography>
                      }
                      placement="right"
                      sx={{
                        background: `${theme?.palette?.primary?.main}`,
                      }}
                      typeColor="primary"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: `${theme?.palette?.primary?.main}`,
                          pt: 0.5,
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
          />
        </FormGroup>
      </GroupBox>
    )
  })
}
