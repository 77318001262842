import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  StyledCard,
  LoadingCircular,
} from './Styled'
import Table from '../../../../../components/redux/Table'
import { toggleRankingDrawer } from '../../handler/handleClickOpenDrawer'
import { Row } from '../../components/Styled'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { drawerSubTitle, drawerTitle, drawerUnit } from '../../models/ranking'
import { fetchData } from './events'

const RankingDrawer = () => {
  const { open, examType, dataType, countTotal } = useSelector(
    (state) => ({
      open: state.crud.eExamDashboard?.rankingDrawer?.isOpen,
      examType: state.crud.eExamDashboard?.rankingDrawer?.examType,
      dataType: state.crud.eExamDashboard?.rankingDrawer?.dataType,
      countTotal: state.crud.eExamDashboard?.rankingDrawer?.countTotal,
    }),
    shallowEqual,
  )
  const theme = useTheme()
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { limit, page, isLoading } = table
  useEffect(() => {
    open &&
      dispatch(
        fetchData({
          table,
          examType,
          dataType,
        }),
      )
  }, [open, page, limit])

  return (
    <StyledDrawer
      open={open}
      customWidth={'50%'}
      onClose={() => toggleRankingDrawer(false)}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">
            {drawerTitle?.[examType] ?? ''} : {drawerSubTitle?.[dataType] ?? ''}
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleRankingDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box m={`${theme.spacing(0.5)} ${theme.spacing(2.75)}`}>
        <Row>
          <Box
            sx={{
              p: '4px 16px',
              borderRadius: 5,
              background: '#F1F4FA',
              maxWidth: 'max-content',
              my: 1,
            }}
          >
            <Typography variant="body2b" color={theme.palette.primary.main}>
              จำนวน{dataType === 'schedule' ? 'รอบ' : 'ผู้สมัคร'}ทั้งหมด{' '}
              {countTotal ?? 0} {drawerUnit?.[dataType] ?? ''}
            </Typography>
          </Box>
        </Row>
      </Box>
      <StyledCard isLoading={isLoading}>
        <Table />
      </StyledCard>
      <StyledFooter>
        <Button onClick={() => toggleRankingDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default RankingDrawer
