import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { optionsConfig, setDataConfig } from '../model'
import Footer from '../Footer'
import Other from '../Other'

import { ChartBar } from '../Styled'
import { draftLecturer } from '../../../../../../redux/slices/eEvaluationForm/feedback'

export const Staff = ({ sequence, indexStaff }) => {
  const { itemStaff, nextQuestions } = useSelector(
    (state) => ({
      itemStaff: state.eEvaluationFeedback.lecturerAssessments.staff.find(
        (item) => item.sequence === sequence
      ),
      nextQuestions:
        state.eEvaluationFeedback.nextQuestions.lecturerAssessments.find(
          (item) => item.sequence === sequence
        ),
    }),
    shallowEqual
  )

  const listDataChart = _.get(itemStaff, 'questions', []).find(
    (item) => item.sequence === nextQuestions.value
  )
  const suggestionAnswer = _.get(itemStaff, 'suggestionAnswer', [])
  const isPersonal = _.get(itemStaff, 'isPersonal', false)
  const highestScore = _.get(itemStaff, 'highestScore', 0)

  const dataConfig = setDataConfig(
    _.get(listDataChart, 'listLabel', []),
    _.get(listDataChart, 'listData', [])
  )

  const firstNameTH = _.get(itemStaff, 'firstNameTH', '-')
  const lastNameTH = _.get(itemStaff, 'lastNameTH', '-')

  const propsFooter = {
    type: 'lecturerAssessments',
    sequence,
    nextQuestions: nextQuestions.value,
    totalQuestion: _.get(itemStaff, 'questions', []).length,
    titleQuestion: _.get(listDataChart, 'title', '-'),
  }

  let labelTextHeader = 'แบบประเมินวิทยากร'
  if (isPersonal)
    labelTextHeader = ` วิทยากรคนที่ ${
      indexStaff + 1
    } : ${firstNameTH} ${lastNameTH}`

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <Box sx={{ ml: 5, mt: 3 }}>
          <Typography variant="h6">{labelTextHeader}</Typography>
        </Box>
        <Box sx={{ width: '100%', overflow: 'auto' }}>
          <ChartBar
            data={dataConfig}
            length={
              _.get(listDataChart, 'listLabel', []).length <= 4
                ? 300
                : _.get(listDataChart, 'listLabel', []).length <= 10
                ? 500
                : 1000
            }
            options={optionsConfig(highestScore)}
          />
        </Box>

        <Footer propsFooter={propsFooter} />

        {suggestionAnswer.length > 0 && (
          <Box sx={{ mx: 3, mb: 3, mt: 3 }}>
            <Typography variant="h6" sx={{ ml: 3, mb: 3 }}>
              ข้อเสนอแนะ / ความคิดเห็นเพิ่มเติม
            </Typography>
            <Other list={suggestionAnswer} />
          </Box>
        )}
      </Card>
    </>
  )
}

const Index = () => {
  const listQuestion = useSelector(draftLecturer, shallowEqual)

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4">ตอนที่ 2</Typography>
        <Typography variant="h5" sx={{ mt: -1 }}>
          แบบประเมินวิทยากร
        </Typography>
      </Box>
      {listQuestion.length > 0 &&
        listQuestion.map((item, index) => (
          <>
            <Staff id={item} key={item} sequence={item} indexStaff={index} />
          </>
        ))}
    </>
  )
}

export default Index
