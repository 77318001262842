import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

import { StyledColumn, StyledRow } from '../Styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('sm')]: { width: 351 },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: 576,
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledContentBox = styled(StyledColumn)(({ theme }) => ({
  width: 'auto',
  margin: theme.spacing(3),
  maxWidth: 576,
}))

export const StyledDateCard = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette?.blue?.blueLine,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledDateHeader = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  background: theme.palette?.blue?.blueLine,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledSpeakerImg = styled('img')(() => ({
  width: 36,
  height: 36,
  borderRadius: '100%',
}))

export const StyledCircle = styled(Box)(({ theme }) => ({
  width: 14,
  height: 14,
  borderRadius: '100%',
  backgroundColor: theme.palette?.monthlyPlan?.sickLeaveBorder,
}))

export const StyledBubble = styled(Box)(({ theme }) => ({
  padding: '0px 12px',
  border: `1px solid ${theme.palette?.other?.outlineBorder}`,
  borderRadius: '16px',
}))

export const BoxBubble = styled(Box)(({ maxHeight }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '360px',
  maxHeight: maxHeight,
  gap: '10px',
  overflow: 'hidden',
}))

export const PrivateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette?.warning?.main}`,
  borderRadius: '4px',
  height: '44px',
  color: `${theme.palette?.warning?.main}`,
  gap: '14px',
}))

export const StyledTypography = styled(Typography)(() => ({
  maxWidth: 540,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))

export const StyledLabel = styled(StyledRow)(({ theme }) => ({
  gap: theme.spacing(3),
  height: 24,
  [theme.breakpoints.down('sm')]: { justifyContent: 'space-between' },
}))

export const StyledResponsbility = styled(Typography)(({ theme }) => ({
  width: 210,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.down('sm')]: { width: 113 },
}))

export const StyledRespRow = styled(StyledRow)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: { flexDirection: 'column', gap: 0 },
}))
