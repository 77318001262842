import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BasicTable from '../../../../components/redux/Table/BasicTable'
import { fetchETestEEvaList, handleSuccess } from './events'
import { StyledCard } from './Styled'

const ETestEEvaList = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { isSuccess, isCancel } = useSelector((state) => ({
    isSuccess: state.manageClassDetail.isSuccess,
    isCancel: state.manageClassDetail.isCancel,
  }))

  useEffect(() => {
    dispatch(fetchETestEEvaList(uuid, isCancel))
  }, [isCancel])

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <StyledCard>
      <BasicTable />
    </StyledCard>
  )
}

export default ETestEEvaList
