import _ from 'lodash'
import {
  setQuantity,
  updateCart,
} from '../../../../../redux/slices/manage/agentProfile'

export const getQty = (cart, uuid) => {
  const result = _.find(cart, (ele) => ele.uuid === uuid)
  if (result) {
    return result.quantity
  } else {
    return 0
  }
}

export const handleClickButton =
  ({ cart, row, action }) =>
  (dispatch) => {
    let quantity = getQty(cart, row.uuid)
    quantity = action === 'plus' ? +quantity + 1 : +quantity - 1
    dispatch(handleChange({ cart, row, quantity }))
  }

export const handleChange =
  ({ cart, row, quantity }) =>
  (dispatch) => {
    const newCart = [...cart]
    const index = _.findIndex(newCart, (ele) => ele.uuid === row.uuid)
    let newCost = 0
    if (quantity > 0) {
      if (index >= 0) {
        dispatch(setQuantity({ index, quantity }))
        return
      } else {
        newCart.push({
          uuid: row.uuid,
          quantity,
          point: row.point,
          name: row.name,
          image: row.image,
        })
        newCost = quantity * row.point
      }
    } else {
      if (index >= 0) {
        const item = newCart.splice(index, 1)[0]
        newCost = -(item.quantity * item.point)
      } else {
        return
      }
    }
    dispatch(updateCart({ cost: newCost, cart: newCart }))
  }
