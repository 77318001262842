import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'
import _ from 'lodash'

export const handleAddAsset = async () => {
  const { formData } = store.getState().crud
  const assets = _.get(formData, 'assets', [])
  store.dispatch(
    setFieldValue({
      key: 'formData.assets',
      value: [
        ...assets,
        {
          label: '',
          value: '',
          assetCode: '',
          assetCodeOption: [],
          qty: '',
          availableQty: '',
          totalQty: '',
          status: '',
        },
      ],
    }),
  )
}
