import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'
import Button from '@mui/material/Button'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 732,
    height: '100vh',
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(3),
}))

export const StyledFooterDrawer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  gap: theme.spacing(1),
}))

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="m" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))
