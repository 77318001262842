import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'

import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'

import Multiple from './Multiple'

import { BoxIcon, BoxFooter } from './Styled'
import {
  handleDeleteQuestions,
  mapLabelSwitch,
  handleChangeSwitch,
  mapKeySwitch,
} from './event'
import { TooltipForm } from '../../../Styled'

const Index = ({ props }) => {
  const {
    sequence,
    itemQuestion,
    isSubQuestions,
    subQuestionSequence,
    disableForm,
  } = props
  const dispatch = useDispatch()

  const { questionType } = itemQuestion
  const valueChecked = _.get(itemQuestion, mapKeySwitch(questionType), false)

  return (
    <Box sx={{ mt: 3 }}>
      <Divider />
      {questionType === 'MULTIPLE' && valueChecked && (
        <Multiple props={props} />
      )}

      <BoxFooter isRating={questionType === 'RATING'}>
        {((questionType !== 'FREE_TEXT' && !isSubQuestions) ||
          (isSubQuestions &&
            (questionType === 'MULTIPLE' || questionType === 'RATING'))) && (
          <Box sx={{ gap: 2 }}>
            <Typography variant="body11">
              {mapLabelSwitch(questionType)}
            </Typography>
            <Switch
              disabled={disableForm}
              data-testid="switch-footer-other"
              checked={valueChecked}
              onChange={(e) => {
                !disableForm &&
                  dispatch(
                    handleChangeSwitch({
                      ...props,
                      questionType,
                      value: _.get(e, 'target.checked', false),
                    })
                  )
              }}
            />
          </Box>
        )}
        <BoxIcon>
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">ลบ</Typography>}
            onClick={() =>
              !disableForm &&
              dispatch(
                handleDeleteQuestions({
                  sequence,
                  isSubQuestions,
                  subQuestionSequence,
                })
              )
            }
          >
            <IconButton data-testid="icon-delete-question">
              <DeleteOutlineOutlined color={'action'} />
            </IconButton>
          </TooltipForm>
        </BoxIcon>
      </BoxFooter>
    </Box>
  )
}

export default Index
