import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import { BoxNotification, BoxSwitch } from './Styled'
import { handleChange } from '../events'

const Index = () => {
  const dispatch = useDispatch()
  const { notification, disableSetting } = useSelector(
    (state) => ({
      notification: state.eEvaluationForm.setting.notification,
      disableSetting:
        state.eEvaluationForm.disableSetting ||
        state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  return (
    <Card sx={{ mt: 2 }}>
      <BoxNotification>
        <Typography variant="h6">การแจ้งเตือน</Typography>
        <BoxSwitch>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1">
              แจ้งเตือนการทำแบบประเมินกับผู้เรียน
              เมื่อถึงเวลาเปิดให้เข้าทำแบบประเมิน
            </Typography>
          </Box>
          <Switch
            disabled={disableSetting}
            data-testid="switch-setting"
            checked={notification}
            onChange={(e) =>
              dispatch(
                handleChange({
                  value: _.get(e, 'target.checked', false),
                  key: 'notification',
                })
              )
            }
          />
        </BoxSwitch>
      </BoxNotification>
    </Card>
  )
}

export default Index
