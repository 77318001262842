import { breadcrumb } from './model/breadcrumb'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { useDispatch, useSelector } from 'react-redux'
import { crudConfig, crudConfig as initialCrudConfig } from '../crudConfig'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { CPageViewContainer } from '../../../../components/CRUD/components/PageViewContainer'
import { ActionComponent } from './components/ViewHeaderAction'
import { ViewContent } from './Content'
import CHistoryDrawer from '../../../../components/CRUD/components/History'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { getBodyMutation } from '../Form/mutation/get'
import { clearFieldValue } from '../../../../redux/slices/crud'

export const EContentView = () => {
  const [user] = useLocalStorage('user')
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const { formData, isLoading } = useSelector((state) => state.crud)

  useEffect(() => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig) && !_.isEmpty(crudConfig))
      fetchDetail(uuid, getBodyMutation, user).then()

    return () => dispatch(clearFieldValue())
  }, [crudConfig])

  if (_.isUndefined(crudConfig || isLoading)) {
    return <div />
  }

  return (
    <>
      <CPageViewContainer
        isLoading={isLoading}
        header={
          <CHeader
            title={`รายละเอียด ${crudConfig.modulesTitleShort}`}
            breadcrumbs={breadcrumb}
            action={<ActionComponent formData={formData} uuid={uuid} />}
          />
        }
        content={<ViewContent />}
      />

      <CHistoryDrawer />
    </>
  )
}
