import { CalendarCard } from './CalendarCard/index'
import { RoomDetailsCard } from './RoomDetailsCard'

export const Content = () => {
  return (
    <>
      <RoomDetailsCard />
      <CalendarCard />
    </>
  )
}
