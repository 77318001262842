import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Button from '@mui/material/Button'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { breadcrumbCreate, breadcrumbEdit } from '../../model'
import { checkScrollWidth, handlePreview, handleSubmitForm } from './events'
import { BoxButton, BoxHeader, BoxLabelTitle, StyledViewTitle } from './Styled'
import { getStateValue } from '../events'

const Header = () => {
  const { id: uuid } = useParams()
  const [, setELearningPreview] = useLocalStorage('eLearningPreview')
  const [isScrolled, setScroll] = useState(false)
  const { isDisableButton, name } = useSelector(
    (state) => ({
      isDisableButton:
        state.eLearningModuleForm.isDisableButton ||
        state.eLearningModuleForm.isVideoLoading,
      name: getStateValue(state, 'name'),
    }),
    shallowEqual,
  )
  const { setValues } = useFormikContext()

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  return (
    <BoxHeader scrolled={isScrolled}>
      <BoxLabelTitle isEdit={uuid}>
        <StyledViewTitle variant="h4">
          {uuid ? `แก้ไข Module: ${name}` : 'สร้าง Module'}
        </StyledViewTitle>
        {!isScrolled && (
          <Breadcrumbs menuList={uuid ? breadcrumbEdit : breadcrumbCreate} />
        )}
      </BoxLabelTitle>
      <BoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          size="m"
          startIcon={<RemoveRedEye />}
          disabled={isDisableButton}
          onClick={() => handlePreview(setELearningPreview)}
        >
          ดูตัวอย่าง
        </Button>

        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={isDisableButton}
          onClick={() => handleSubmitForm(setValues)}
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}
export default Header
