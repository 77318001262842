import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useDispatch } from 'react-redux'
import { openErrorDialog } from '../../redux/slices/dialog'
import _ from 'lodash'

export const licenseStatusText = (licenseStatus) => {
  switch (licenseStatus) {
    case 'รอตรวจสอบ':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <HourglassBottomTwoTone color="warning" />
          <Typography variant="body2" color="warning.main">
            รอตรวจสอบ
          </Typography>
        </Box>
      )
    case 'ให้':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Check color="success" />
          <Typography variant="body2">ให้</Typography>
        </Box>
      )
    case 'ไม่ให้':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Close color="error" />
          <Typography variant="body2">ไม่ให้</Typography>
        </Box>
      )
    default:
      return 'ปรับสถานะครบแล้ว'
  }
}

export const rcmsStatusText = (rcmsStatus, error) => {
  const dispatch = useDispatch()

  switch (rcmsStatus) {
    case 'รอส่ง':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <HourglassBottomTwoTone color="info" />
          <Typography variant="body2">รอส่ง</Typography>
        </Box>
      )
    case 'สำเร็จ':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Check color="success" />
          <Typography variant="body2">สำเร็จ</Typography>
        </Box>
      )
    case 'ไม่ต้องส่ง':
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Close sx={{ color: 'gray' }} />
          <Typography variant="body2" color="text.gray">
            ไม่ต้องส่ง
          </Typography>
        </Box>
      )
    case 'ไม่สำเร็จ':
      return (
        <Button
          disableRipple
          sx={{ display: 'flex', gap: 1, p: 0 }}
          disabled={_.isEmpty(error)}
          data-testid="btn-fail"
          onClick={() =>
            dispatch(
              openErrorDialog({
                title: 'ไม่สำเร็จ',
                sx: {
                  '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                      '& .MuiTypography-root': {
                        marginRight: '0px',
                      },
                    },
                  },
                },
                message: (
                  <>
                    <Typography variant="body1">
                      ส่งข้อมูลไป RCMS ไม่สำเร็จ
                    </Typography>
                    <Typography variant="body1">{`Error Code : ${error}`}</Typography>
                    <br />
                    <Typography variant="body1">
                      กรุณาดาวน์โหลดรายการที่ไม่สำเร็จ
                      เพื่อแก้ไขและดำเนินการนำเข้าข้อมูลด้วย
                    </Typography>
                    <Typography variant="body1">
                      Admin Process ใหม่อีกครั้ง
                    </Typography>
                  </>
                ),
              })
            )
          }
        >
          <WarningAmberIcon color="error" />
          <Typography
            variant="body2"
            color={_.isEmpty(error) ? 'text.primary' : 'error'}
            sx={{ textDecoration: _.isEmpty(error) ? '' : 'underline' }}
          >
            ไม่สำเร็จ
          </Typography>
        </Button>
      )
    default:
      return '-'
  }
}
