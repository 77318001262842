import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledHome = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  margin: '0 auto',
  marginTop: '-16px',
  background: '#fff',
}))

export const StyledDotBg = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 580,
  left: 72,
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    width: 102,
    bottom: 377,
    left: 20,
  },
  [theme.breakpoints.down('sm')]: { width: 102, bottom: 634 },
}))
