import React from 'react'
import * as Component from './Styled'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import * as event from './event'
const Index = () => {
  const { filter, totalAllClass } = useSelector((state) => ({
    filter: state.table.filter,
    totalAllClass: state.manageClass.totalAllClass,
  }))
  const dispatch = useDispatch()
  const highClassChecked = _.get(filter, 'other.highClass.checked', false)
  const myJobChecked = _.get(filter, 'other.myJob.checked', false)
  const isAll = !highClassChecked && !myJobChecked

  return (
    <Component.Container>
      <Component.CardAll
        onClick={() => dispatch(event.handleRadioChange(filter))}
      >
        <Typography variant="body1b">คลาสทั้งหมด</Typography>
        <Component.RadioAll checked={isAll} />
        <Typography>{totalAllClass} คลาส</Typography>
      </Component.CardAll>
      <Component.CardVirtual
        onClick={() => dispatch(event.handleRadioChange(filter, 'highClass'))}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Typography variant="body1b">Virtual Classroom</Typography>
          <Component.BoxHighLevel>ระดับสูง</Component.BoxHighLevel>
        </Box>
        <Component.RadioVirtual checked={highClassChecked} />
      </Component.CardVirtual>
      <Component.CardMyTask
        onClick={() => dispatch(event.handleRadioChange(filter, 'myJob'))}
      >
        <Typography variant="body1b">งานของฉัน</Typography>
        <Component.RadioMyTask checked={myJobChecked} />
      </Component.CardMyTask>
    </Component.Container>
  )
}
export default Index
