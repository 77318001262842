import { useDispatch } from 'react-redux'
import { contentTypeEnum } from '../enum/contentTypEnum'
import UploadFile from '../../../../../components/UploadFile'
import { SIZE_TYPE } from '../../../../../constants/eConfig'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { UPLOAD_TYPE } from '../../../../../constants/eLearning'
import Link from '../components/contentTab/DragDropContent/Link'
import KB from '../components/contentTab/DragDropContent/KB'
import Text from '../components/contentTab/DragDropContent/Text'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { useInputField } from '../../../../../components/CRUD/hooks/useInputField'
import { useMemo } from 'react'

export const RenderContent = ({ content, index }) => {
  const dispatch = useDispatch()
  const { value, isError, message } = useInputField(
    `contents[${index}].content.files`,
  )

  return useMemo(() => {
    switch (content.type) {
      case contentTypeEnum.FILE.key:
        return (
          <Box
            tab-name="tab"
            content-tab="content"
            id={`e-content-contents[${index}].content.files`}
          >
            <UploadFile
              value={value}
              maxFileSize={{
                size: 1,
                sizeType: SIZE_TYPE.GB,
              }}
              isMultiple={true}
              onChange={(files) => {
                dispatch(
                  setFieldValue({
                    key: `formData.contents[${index}].content.files`,
                    value: files,
                  }),
                )
              }}
              uploadType={UPLOAD_TYPE.GENERAL}
              accept={{
                'application/msword': ['.doc'],
                'application/pdf': ['.pdf'],
                'application/vnd.ms-powerpoint': ['.ppt'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  ['.docx'],
                'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                  ['.pptx'],
              }}
              acceptLabel=".ppt, .pptx, .pdf, .doc, .docx"
              fieldName={`file-${index}`}
              isShowDownloadable={true}
            />
            {isError && (
              <Box>
                {message && message !== '' && (
                  <Typography variant="body2" color="error">
                    {message}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )
      case contentTypeEnum.LINK.key:
        return <Link content={content} index={index} />
      case contentTypeEnum.KB.key:
        return <KB content={content} index={index} />
      case contentTypeEnum.IMAGE.key:
        return (
          <Box
            tab-name="tab"
            content-tab="content"
            id={`e-content-contents[${index}].content.files`}
          >
            <UploadFile
              value={value}
              maxFileSize={{
                size: 2,
                sizeType: SIZE_TYPE.MB,
              }}
              maxLength={4}
              isMultiple={true}
              isForImage={true}
              folder="e-content"
              onChange={(files) => {
                dispatch(
                  setFieldValue({
                    key: `formData.contents[${index}].content.files`,
                    value: files,
                  }),
                )
              }}
              uploadType={UPLOAD_TYPE.GENERAL}
              accept={{
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
                'image/png': ['.png'],
              }}
              acceptLabel=".jpg, .jpeg, .png"
              fieldName={`file-${index}`}
            />
            {isError && (
              <Box>
                {message && message !== '' && (
                  <Typography variant="body2" color="error">
                    {message}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )
      case contentTypeEnum.TEXT.key:
        return <Text content={content} index={index} />
      default:
        return null
    }
  }, [content.type, index, value, isError, message, dispatch])
}
