import * as yup from 'yup'
import dayjs from 'dayjs'
import _ from 'lodash'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { checkEndRegisterDateTime, checkStartRegisterDateTime } from './events'

dayjs.extend(customParseFormat)
export const schema = (classType, expectNumber) =>
  yup.object({
    isDraft: yup.boolean(),
    assignNee: yup.string().required('กรุณาเลือก'),
    endRegisterTime: yup
      .string()
      .test(
        'endRegisterTime',
        'กรุณากรอกวันเวลาให้น้อยกว่าวันเวลาเริ่มอบรม',
        (value, { parent }) => {
          const {
            endRegisterDateTime,
            startRegisterDateTime,
            monthlyPlan,
            publicClass,
            isDraft,
          } = parent
          const now = dayjs().subtract(10, 'year').startOf('year')
          const startDate = dayjs(startRegisterDateTime, 'DD/MM/YYYY HH:mm')
          const endDate = dayjs(endRegisterDateTime, 'DD/MM/YYYY HH:mm')
          const startTime = _.get(monthlyPlan, 'plan.[0].startTime', '')
          const monthlyPlanStartDate = dayjs(
            `${monthlyPlan.startDate} ${startTime}`,
          )
          const monthlyPlanStartDateMs = monthlyPlanStartDate.diff(now, 'ms')
          const startDateMs = startDate.diff(now, 'ms')
          const endDateMs = endDate.diff(now, 'ms')
          if (isDraft || !publicClass) return true

          //วันเวลาเปิดรับสมัคร มากกว่า วันเวลาปิดรับสมัคร
          if (startDateMs > endDateMs) return false
          //วันเวลาเริ่มอบรม มากกว่าหรือเท่ากับ วันเวลาปิดรับสมัคร
          return monthlyPlanStartDateMs >= endDateMs
        },
      ),
    startRegisterDateTime: yup
      .string()
      .test('startRegisterDateTime', 'กรุณาเลือก', (value, { parent }) => {
        const { publicClass, isDraft } = parent
        if (isDraft || !publicClass) return true
        return dayjs(value, 'DD/MM/YYYY').isValid()
      })
      .test(
        'startRegisterDateTime-error',
        'กรุณากรอกวันที่ให้ถูกต้อง',
        (value, { parent }) => {
          const { publicClass, isDraft } = parent
          if (isDraft || !publicClass) return true
          return checkStartRegisterDateTime(value, parent)
        },
      ),
    endRegisterDateTime: yup
      .string()
      .test('endRegisterDateTime', 'กรุณาเลือก', (value, { parent }) => {
        const { publicClass, isDraft } = parent
        if (isDraft || !publicClass) return true
        return dayjs(value, 'DD/MM/YYYY').isValid()
      })
      .test(
        'endRegisterDateTime-error',
        'กรุณากรอกวันที่ให้ถูกต้อง',
        (value, { parent }) => {
          const { publicClass, isDraft } = parent
          if (isDraft || !publicClass) return true
          return checkEndRegisterDateTime(value, parent)
        },
      ),
    monthlyPlan: yup.object().shape({
      expectNumberPeople: yup
        .number()
        .test('check-require', 'กรุณากรอก', (value, { from }) => {
          const isDraft = _.get(from, '[1].value.isDraft', false)
          return isDraft || value
        }),
      dates: yup.array().of(
        yup.object().shape({
          externalSpeakerPlan: yup.array().of(
            yup.object().shape({
              staff: yup.object().shape({
                uuid: yup
                  .string()
                  .test('check-require', 'กรุณาเลือก', (value, { from }) => {
                    const isDraft = _.get(from, '[4].value.isDraft', false)
                    return isDraft || value
                  }),
              }),
              responsibility: yup.object().shape({
                uuid: yup
                  .string()
                  .test('check-require', 'กรุณาเลือก', (value, { from }) => {
                    const isDraft = _.get(from, '[4].value.isDraft', false)
                    return isDraft || value
                  }),
              }),
              startTime: yup
                .string()
                .test('check-require', 'กรุณากรอก', (value, { from }) => {
                  const isDraft = _.get(from, '[3].value.isDraft', false)
                  return isDraft || value
                }),
              endTime: yup
                .string()
                .test('check-require', 'กรุณากรอก', (value, { from }) => {
                  const isDraft = _.get(from, '[3].value.isDraft', false)
                  return isDraft || value
                }),
            }),
          ),
          onlineRoom: yup
            .array()
            .test('check-learnerNumber', 'required', (value, { from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              return isDraft || classType !== 'Online' || value.length > 0
            })
            .test('check-learnerNumber', 'expectNumber', (value, { from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              let total = 0
              value.forEach((item) => (total += item.learnerNumber))
              return (
                isDraft ||
                classType !== 'Online' ||
                value.length === 0 ||
                total === expectNumber
              )
            })
            .of(
              yup.object().shape({
                link: yup
                  .string()
                  .test('check-require', 'กรุณากรอก', (value, { from }) => {
                    const isDraft = _.get(from, '[3].value.isDraft', false)
                    return isDraft || value
                  }),
                learnerNumber: yup
                  .number()
                  .test('check-require', 'กรุณากรอก', (value, { from }) => {
                    const isDraft = _.get(from, '[3].value.isDraft', false)
                    return isDraft || !_.isNil(value)
                  }),
              }),
            ),
        }),
      ),
    }),
    roomManagement: yup.object().shape({
      roomManagementMeetingRoom: yup.array().of(
        yup.object().shape({
          meetingRoomUuid: yup
            .object()
            .nullable()
            .test(
              'check-meetingRoomUuid',
              'กรุณากรอกห้องอบรม',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const meetingRoom = _.get(parent, 'meetingRoom')
                return isDraft || !_.isEmpty(meetingRoom)
              },
            ),
          meetingRoomOther: yup
            .string()
            .nullable()
            .test(
              'check-other',
              'กรุณากรอกชื่อสถานที่',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const isOther = _.get(parent, 'meetingRoom.value') === 'OTHERS'
                if (!isOther) {
                  return true
                }

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return isDraft || !isOther || newValue
              },
            )
            .test(
              'check-other-length',
              'กรอกได้ไม่เกิน 255 ตัวอักษร',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const isOther = _.get(parent, 'meetingRoom.value') === 'OTHERS'

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return (
                  isDraft ||
                  !isOther ||
                  (newValue && newValue <= 255 && newValue > 0)
                )
              },
            ),
          numberOfAttendees: yup
            .string()
            .nullable()
            .test(
              'check-other-length',
              'กรอกได้ไม่เกิน 4 ตัวอักษร',
              (value, { from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)

                const newValue = !_.isEmpty(value) ? value.trim().length : value

                if (_.isNil(newValue)) {
                  return true
                }
                return isDraft || newValue <= 5
              },
            ),
          numberOfGroup: yup
            .string()
            .nullable()
            .test(
              'check-numberOfGroup',
              'กรุณากรอกจำนวนกลุ่ม (กลุ่ม)',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const roomLayout = _.get(parent, 'roomLayout') === 'GROUP'

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return isDraft || !roomLayout || newValue
              },
            )
            .test(
              'check-numberOfGroup-length',
              'กรอกได้ไม่เกิน 4 ตัวอักษร',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const roomLayout = _.get(parent, 'roomLayout') === 'GROUP'

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return (
                  isDraft ||
                  !roomLayout ||
                  (newValue && newValue <= 5 && newValue > 0)
                )
              },
            ),
          attendeeInGroup: yup
            .string()
            .nullable()
            .test(
              'check-attendeeInGroup',
              'กรุณากรอกกลุ่มละ (คน)',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const roomLayout = _.get(parent, 'roomLayout') === 'GROUP'

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return isDraft || !roomLayout || newValue
              },
            )
            .test(
              'check-attendeeInGroup-length',
              'กรอกได้ไม่เกิน 4 ตัวอักษร',
              (value, { parent, from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)
                const roomLayout = _.get(parent, 'roomLayout') === 'GROUP'

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return (
                  isDraft ||
                  !roomLayout ||
                  (newValue && newValue <= 5 && newValue > 0)
                )
              },
            ),
          additionalEquipment: yup
            .string()
            .nullable()
            .test(
              'check-additionalEquipment-length',
              'กรอกได้ไม่เกิน 3000 ตัวอักษร',
              (value, { from }) => {
                const isDraft = _.get(from, '[2].value.isDraft', false)

                const newValue = !_.isEmpty(value) ? value.trim().length : value
                return isDraft || !newValue || newValue <= 3000
              },
            ),
        }),
      ),
    }),
  })

export const schemaClosed = () =>
  yup.object({
    monthlyPlan: yup.object().shape({
      expectNumberPeople: yup.number().required('กรุณากรอก'),
    }),
  })

export const schemaActivitySupport = () =>
  yup.object({
    uuid: yup.string().required('กรุณาเลือก'),
  })

export const schemaOnline = (classType, expectNumber) =>
  yup.object({
    isDraft: yup.boolean(),
    assignNee: yup.string().required('กรุณาเลือก'),
    endRegisterTime: yup
      .string()
      .test(
        'endRegisterTime',
        'กรุณากรอกวันเวลาให้น้อยกว่าวันเวลาเริ่มอบรม',
        (value, { parent }) => {
          const {
            endRegisterDateTime,
            startRegisterDateTime,
            monthlyPlan,
            publicClass,
            isDraft,
          } = parent
          const now = dayjs().subtract(10, 'year').startOf('year')
          const startDate = dayjs(startRegisterDateTime, 'DD/MM/YYYY HH:mm')
          const endDate = dayjs(endRegisterDateTime, 'DD/MM/YYYY HH:mm')
          const startTime = _.get(monthlyPlan, 'plan.[0].startTime', '')
          const monthlyPlanStartDate = dayjs(
            `${monthlyPlan.startDate} ${startTime}`,
          )
          const monthlyPlanStartDateMs = monthlyPlanStartDate.diff(now, 'ms')
          const startDateMs = startDate.diff(now, 'ms')
          const endDateMs = endDate.diff(now, 'ms')
          if (isDraft || !publicClass) return true

          //วันเวลาเปิดรับสมัคร มากกว่า วันเวลาปิดรับสมัคร
          if (startDateMs > endDateMs) return false
          //วันเวลาเริ่มอบรม มากกว่าหรือเท่ากับ วันเวลาปิดรับสมัคร
          return monthlyPlanStartDateMs >= endDateMs
        },
      ),
    startRegisterDateTime: yup
      .string()
      .test('startRegisterDateTime', 'กรุณาเลือก', (value, { parent }) => {
        const { publicClass, isDraft } = parent
        if (isDraft || !publicClass) return true
        return dayjs(value, 'DD/MM/YYYY').isValid()
      })
      .test(
        'startRegisterDateTime-error',
        'กรุณากรอกวันที่ให้ถูกต้อง',
        (value, { parent }) => {
          const { publicClass, isDraft } = parent
          if (isDraft || !publicClass) return true
          return checkStartRegisterDateTime(value, parent)
        },
      ),
    endRegisterDateTime: yup
      .string()
      .test('endRegisterDateTime', 'กรุณาเลือก', (value, { parent }) => {
        const { publicClass, isDraft } = parent
        if (isDraft || !publicClass) return true
        return dayjs(value, 'DD/MM/YYYY').isValid()
      })
      .test(
        'endRegisterDateTime-error',
        'กรุณากรอกวันที่ให้ถูกต้อง',
        (value, { parent }) => {
          const { publicClass, isDraft } = parent
          if (isDraft || !publicClass) return true
          return checkEndRegisterDateTime(value, parent)
        },
      ),
    monthlyPlan: yup.object().shape({
      expectNumberPeople: yup
        .number()
        .test('check-require', 'กรุณากรอก', (value, { from }) => {
          const isDraft = _.get(from, '[1].value.isDraft', false)
          return isDraft || value
        }),
      dates: yup.array().of(
        yup.object().shape({
          meetingRoomOther: yup
            .string()
            .test('check-other', 'กรุณากรอก', (value, { parent, from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              const isOther = parent.meetingRooms.some(
                (item) => item.value === 'OTHERS',
              )
              const newValue = !_.isEmpty(value) ? value.trim().length : value
              return isDraft || !isOther || newValue
            }),
          meetingRooms: yup
            .array()
            .test('check-meeting', 'กรุณาเลือกห้องอบรม', (value, { from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              return (
                isDraft || classType === 'Online' || (value && value.length > 0)
              )
            }),
          externalSpeakerPlan: yup.array().of(
            yup.object().shape({
              staff: yup.object().shape({
                uuid: yup
                  .string()
                  .test('check-require', 'กรุณาเลือก', (value, { from }) => {
                    const isDraft = _.get(from, '[4].value.isDraft', false)
                    return isDraft || value
                  }),
              }),
              responsibility: yup.object().shape({
                uuid: yup
                  .string()
                  .test('check-require', 'กรุณาเลือก', (value, { from }) => {
                    const isDraft = _.get(from, '[4].value.isDraft', false)
                    return isDraft || value
                  }),
              }),
              startTime: yup
                .string()
                .test('check-require', 'กรุณากรอก', (value, { from }) => {
                  const isDraft = _.get(from, '[3].value.isDraft', false)
                  return isDraft || value
                }),
              endTime: yup
                .string()
                .test('check-require', 'กรุณากรอก', (value, { from }) => {
                  const isDraft = _.get(from, '[3].value.isDraft', false)
                  return isDraft || value
                }),
            }),
          ),
          onlineRoom: yup
            .array()
            .test('check-learnerNumber', 'required', (value, { from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              return isDraft || classType !== 'Online' || value.length > 0
            })
            .test('check-learnerNumber', 'expectNumber', (value, { from }) => {
              const isDraft = _.get(from, '[2].value.isDraft', false)
              let total = 0
              value.forEach((item) => (total += item.learnerNumber))
              return (
                isDraft ||
                classType !== 'Online' ||
                value.length === 0 ||
                total === expectNumber
              )
            })
            .of(
              yup.object().shape({
                link: yup
                  .string()
                  .test('check-require', 'กรุณากรอก', (value, { from }) => {
                    const isDraft = _.get(from, '[3].value.isDraft', false)
                    return isDraft || value
                  }),
                learnerNumber: yup
                  .number()
                  .test('check-require', 'กรุณากรอก', (value, { from }) => {
                    const isDraft = _.get(from, '[3].value.isDraft', false)
                    return isDraft || !_.isNil(value)
                  }),
              }),
            ),
        }),
      ),
    }),
  })
