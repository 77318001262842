import { store } from '../../../../App'
import {
  clearFilter,
  resetPage,
  resetTablePage,
  setSearchText,
} from '../../../../redux/slices/table'
import { fetchBudgetManagementList } from './fetchBudgetManagementList'

export const handleQuickSearch = (table, keyword, mutatePropToBody) => {
  store.dispatch(setSearchText(keyword))
  store.dispatch(clearFilter())
  store.dispatch(resetTablePage())
  if (table.page === 1) {
    fetchBudgetManagementList(mutatePropToBody)
  } else {
    store.dispatch(resetPage())
  }
}
