import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  StyledCard,
  LoadingCircular,
} from './Styled'
import { closeCourseDrawer } from '../../../../../redux/slices/eLearning/dashboard'
import Table from '../../../../../components/redux/Table'
import { fetchData } from './events'
import EnrollDrawer from '../EnrollDrawer'
import {
  resetRowsPerPage,
  setSearchText,
} from '../../../../../redux/slices/table'

const CourseDrawer = () => {
  const dispatch = useDispatch()
  const {
    open,
    header,
    drawerType,
    courseType,
    startDate,
    endDate,
    type,
    status,
  } = useSelector(
    (state) => ({
      open: state.eLearningDashboard.courseDrawer.isOpen,
      header: state.eLearningDashboard.courseDrawer.header,
      drawerType: state.eLearningDashboard.courseDrawer.drawerType,
      courseType: state.eLearningDashboard.courseDrawer.courseType,
      startDate: state.eLearningDashboard.filter.startDate,
      endDate: state.eLearningDashboard.filter.endDate,
      type: state.eLearningDashboard.filter.courseType,
      status: state.eLearningDashboard.filter.status,
    }),
    shallowEqual,
  )
  const { table, search } = useSelector((state) => state.table)
  const { limit, page, isLoading } = table
  const { searchText } = search
  useEffect(() => {
    open &&
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: searchText,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    if (!open) {
      dispatch(setSearchText(''))
      dispatch(resetRowsPerPage())
    }
  }, [open, page, limit])

  return (
    <StyledDrawer open={open} onClose={() => dispatch(closeCourseDrawer())}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">{header}</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(closeCourseDrawer())}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledCard isLoading={isLoading}>
        <Table />
      </StyledCard>
      <StyledFooter>
        <Button
          onClick={() => dispatch(closeCourseDrawer())}
          variant="contained"
        >
          ปิด
        </Button>
      </StyledFooter>
      <EnrollDrawer />
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default CourseDrawer
