import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  setChangeInformation,
  startLoading,
  stopLoading,
  setPayloadKey,
} from '../../../redux/slices/manage/prospectProfile'

import { prospectProfileView } from '../../../utils/apiPath'

export const fetchData =
  (uuid, courseSearch, examSearch, type) => async (dispatch) => {
    dispatch(startLoading())

    const body = {
      prospectUuid: uuid,
      courselimit: 100,
      coursePage: 1,
      courseSearch: _.defaultTo(courseSearch, ''),
      examLimit: 100,
      examPage: 1,
      examSearch: _.defaultTo(examSearch, ''),
      type: type,
    }
    try {
      const response = await axios.post(prospectProfileView, body)
      const result = response?.data.result
      dispatch(setPayloadKey({ key: 'contact', data: result?.contact }))
      dispatch(setPayloadKey({ key: 'educational', data: result?.educational }))
      dispatch(setPayloadKey({ key: 'info', data: result?.info }))
      dispatch(setPayloadKey({ key: 'info2', data: result?.info2 }))
      dispatch(setPayloadKey({ key: 'updated', data: result?.updated }))
      dispatch(setPayloadKey({ key: 'work', data: result?.work }))
      dispatch(setPayloadKey({ key: 'institution', data: result?.institution }))
      dispatch(
        setPayloadKey({
          key: 'qualificationName',
          data: result?.qualificationName,
        }),
      )
      dispatch(setPayloadKey({ key: 'between', data: result?.between }))
      dispatch(setPayloadKey({ key: 'branch', data: result?.branch }))
      dispatch(setPayloadKey({ key: 'gpa', data: result?.gpa }))
    } catch (err) {
      console.log('err', err)
    }

    dispatch(stopLoading())
  }

export const formatDateDisplay = (date) => {
  if (_.isEmpty(date)) return '-'
  return dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeInformation(props))
}

export const formatData = (field) => {
  let text = ''
  switch (field) {
    case 'ACTIVE':
      text = 'Active'
      break
    case 'INACTIVE':
      text = 'Inactive'
      break
    case 'DELETED':
      text = 'Deleted'
      break
    case 'STEAL':
      text = 'โอนย้าย'
      break
    case 'TERMINATED':
      text = 'Terminated'
      break
    default:
      text = '-'
      break
  }
  return text
}
