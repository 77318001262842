import { shallowEqual, useSelector } from 'react-redux'
import { StyledDrawer } from './Styled'
import { Divider } from '@mui/material'
import { Header } from './components/Header'
import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { handleCloseDrawer } from '../handler/handleCloseDrawer'

export const ExpenseDrawer = () => {
  const { isOpenDrawer } = useSelector(
    (state) => ({
      isOpenDrawer: state.crud.isOpenDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      open={isOpenDrawer}
      anchor="right"
      onClose={() => handleCloseDrawer()}
    >
      <Header />
      <Divider />
      <Content />
      <Footer />
    </StyledDrawer>
  )
}
