import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: 275,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}))

export const StyledSwitchButton = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
}))

export const Row = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}))

export const Column = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))
