import { StyledRow } from '../Styled'
import { AttendeeInGroup } from './AttendeeInGroup'
import { NumberOfGroup } from './NumberOfGroup'
import _ from 'lodash'

export const GroupSection = ({
  roomLayout,
  meetingRoomDetail,
  roomDetailIndex,
  errors,
  bookingDate,
  validateIndex,
}) => {
  if (roomLayout !== 'GROUP') return null
  return (
    <StyledRow sx={{ gap: 2, pl: 10, alignItems: 'stretch' }}>
      <NumberOfGroup
        numberOfGroup={_.get(meetingRoomDetail, 'numberOfGroup')}
        roomDetailIndex={roomDetailIndex}
        errors={errors}
        bookingDate={bookingDate}
        validateIndex={validateIndex}
      />
      <AttendeeInGroup
        attendeeInGroup={_.get(meetingRoomDetail, 'attendeeInGroup')}
        roomDetailIndex={roomDetailIndex}
        errors={errors}
        bookingDate={bookingDate}
        validateIndex={validateIndex}
      />
    </StyledRow>
  )
}
