import _ from 'lodash'
import { approveStatus } from '../../../constants/monthlyPlan/speaker'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'

export const handleDeleteSavedData = (props) => (dispatch) => {
  const { setStateForm, field, rowIndex, data } = props
  dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบรายการนี้หรือไม่',
      handleConfirm: () => {
        setStateForm((prev) => handleChangeStatus(prev, field, rowIndex, data))
        dispatch(closeDialog())
      },
    })
  )
}

export const handleChangeStatus = (prev, field, rowIndex, data) => {
  const mainData = _.get(prev, field, {})
  const subField = field === 'training' ? 'dates' : 'expandDate'
  const newDates = _.get(mainData, subField, []).map((item) => {
    if (data.startDate !== item.date) return item
    const speakerBorrow = _.get(item, 'speakerBorrow', [])
    const newSpeakerBorrow = _.get(speakerBorrow, rowIndex, {})
    const status = approveStatus.DELETED
    const speakerBorrowResponsibility = _.get(
      newSpeakerBorrow,
      'speakerBorrowResponsibility',
      []
    ).map((speaker) => ({ ...speaker, status }))
    speakerBorrow[rowIndex] = {
      ...newSpeakerBorrow,
      status,
      speakerBorrowResponsibility,
    }
    return { ...item, speakerBorrow }
  })
  return { ...prev, [field]: { ...mainData, [subField]: newDates } }
}
