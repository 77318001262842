import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  eLearningDashboard,
  eLearningDashboardAllCourse,
  eLearningDashboardCourseLearner,
} from '../../../utils/apiPath'

export const filterELearningDashboard = createAsyncThunk(
  'eLearningDashboard/filterELearningDashboard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningDashboard, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getELearningDashboardAllCourse = createAsyncThunk(
  'eLearningDashboard/getELearningDashboardAllCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningDashboardAllCourse, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getELearningDashboardCourseLearner = createAsyncThunk(
  'eLearningDashboard/getELearningDashboardCourseLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningDashboardCourseLearner, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
