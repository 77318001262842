import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledTrainerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: `${theme.spacing(10.5)} ${theme.spacing(10)}`,
  '& #slash': {
    position: 'absolute',
    bottom: 20,
    right: 80,
    zIndex: -2,
    [theme.breakpoints.down('lg')]: {
      width: 18,
      right: 25,
      bottom: 30,
    },
    [theme.breakpoints.down('sm')]: { right: 20, bottom: 40 },
  },
  '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 1440 },
  [theme.breakpoints.down('lg')]: {
    padding: `42px ${theme.spacing(2.5)}`,
    '& [aria-label="gallery-teacher"] .carousel-slider': {
      height: '290px !important',
    },
    '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 768 },
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(7)} ${theme.spacing(2.5)} 30px`,
    '& [aria-label="gallery-teacher"] .carousel-slider': {
      height: '560px !important',
    },
    '& [aria-label="gallery-teacher"] .slider-wrapper': { maxWidth: 375 },
  },
}))

export const StyledTrainerHeader = styled(Typography)(({ theme }) => ({
  span: { color: theme?.palette?.primary?.main },
}))

export const StyledDisplayTrainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 260,
  img: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: { marginBottom: '14px' },
  },
  [theme.breakpoints.down('lg')]: { width: 145 },
}))

export const StyledDivItem = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(10),
  justifyContent: 'center',
  marginTop: theme.spacing(5.5),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(5.5),
    flexWrap: 'wrap',
    marginTop: theme.spacing(4),
  },
}))

export const StyledTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))
