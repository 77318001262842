import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledETestingForm = styled(Box)(({ theme }) => ({
  width: '100%',
  '& > .MuiBox-root#box-title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
  '& > .MuiBox-root#box-content': {
    border: '1px solid',
    borderColor: theme?.palette?.other?.divider,
    borderRadius: 1,
    padding: theme.spacing(2),
    '& p > span': {
      color: theme?.palette?.error?.main,
    },
  },
}))
