import React, { useState } from 'react'
import _ from 'lodash'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import UploadFileIcon from '@mui/icons-material/UploadFileRounded'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'

import TextInput from '../../../../components/Input/TextInput'
import SwitchInput from '../../../../components/Input/SwitchInput'
import DialogImage from '../../../ManageClass/Learner/Document/Content/DialogImage'

import * as Styled from './Styled'
import {
  onChangeUpload,
  handleChange,
  handleDeleteDocument,
  checkViewDocument,
  onDownload,
} from './events'

export const ButtonUpload = ({ htmlFor, onUpload }) => {
  return (
    <label htmlFor={htmlFor}>
      <Button variant="contained" size="m" component="span" htmlFor={htmlFor}>
        <Styled.InputUpload
          accept=".word, .excel, .pdf, .png, .jpg, .jpeg, .xlsx, .xls, .csv, .pptx, .txt, .docx"
          type="file"
          id={htmlFor}
          onChange={onUpload}
        />
        <Box sx={{ display: 'flex' }}>
          <UploadFileIcon sx={{ ml: -0.5, mt: 0.7 }} />
          <Typography variant="button" sx={{ pl: 1, mt: 0.5 }}>
            อัปโหลดไฟล์
          </Typography>
        </Box>
      </Button>
    </label>
  )
}

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const [isOpen, setIsOpen] = useState({
    status: false,
    title: '',
    url: '',
  })

  const information = useSelector(
    (state) => state.masterDataLicenseForm.initialState.information,
    shallowEqual
  )

  const documents = _.get(information, 'documents', [])

  return (
    <Card sx={{ mt: 3 }}>
      <Box sx={{ m: 3 }}>
        {uuid && (
          <TextInput
            required
            id="id"
            name="id"
            labelText="ID"
            type="basic"
            value={_.get(information, 'licenseId', '')}
            disabled={true}
          />
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            required
            id="description"
            name="description"
            labelText="ชื่อใบอนุญาต"
            type="basic"
            onChange={(e) => {
              const value = _.get(e, 'target.value', '')
              if (String(value).trim().length > 0) {
                dispatch(
                  handleChange({
                    key: 'description',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              } else {
                dispatch(
                  handleChange({
                    key: 'description',
                    value: '',
                  })
                )
              }
            }}
            value={_.get(information, 'description', '')}
            textError={_.get(errors, 'information.description', '')}
          />
          <TextInput
            required
            id="category"
            name="category"
            labelText="ประเภทใบอนุญาต"
            type="basic"
            onChange={(e) => {
              const value = _.get(e, 'target.value', '')
              if (String(value).trim().length > 0) {
                dispatch(
                  handleChange({
                    key: 'category',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              } else {
                dispatch(
                  handleChange({
                    key: 'category',
                    value: '',
                  })
                )
              }
            }}
            value={_.get(information, 'category', '')}
            textError={_.get(errors, 'information.category', '')}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            id="remark"
            name="remark"
            labelText="หมายเหตุ"
            type="basic"
            multiline={true}
            rows={2}
            onChange={(e) =>
              dispatch(
                handleChange({
                  key: 'remark',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            value={_.get(information, 'remark', '')}
            textError={_.get(errors, 'information.remark', '')}
          />
          <SwitchInput
            boxSx={{ mt: 0.2 }}
            id="status"
            name="status"
            labelText="สถานะ"
            defaultChecked
            placeholder="ค้นหาระดับของผู้เรียน"
            value={_.get(information, 'status', 'ACTIVE') === 'ACTIVE'}
            onChange={(e) =>
              dispatch(
                handleChange({
                  key: 'status',
                  value: _.get(e, 'target.checked', false)
                    ? 'ACTIVE'
                    : 'INACTIVE',
                })
              )
            }
            required
            inputText="การเปิดใช้งาน"
          />
        </Box>

        <Divider sx={{ mt: 2, mb: 3 }} />

        <Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Reference Document
          </Typography>

          {!!documents.length && (
            <>
              <Styled.BoxDocuments>
                <Styled.BoxBorder>
                  <Styled.BoxDocumentHeader>
                    <Typography variant="tableHeader">รายการ</Typography>
                    <Typography variant="tableHeader" sx={{ width: 116 }}>
                      คำสั่ง
                    </Typography>
                  </Styled.BoxDocumentHeader>
                </Styled.BoxBorder>
                {documents.map((item, index) => {
                  const newUrl =
                    window.__env__.REACT_APP_API_URL +
                    '/file' +
                    _.get(item, 'documentKey', '-')

                  const titleFile = _.get(item, 'title', '')
                  const typeFile = titleFile.split('.').pop()
                  const checkType = checkViewDocument(typeFile)

                  return (
                    <>
                      <Styled.BoxBorder key={index}>
                        <Styled.BoxDocumentContent>
                          <Typography variant="body2">
                            {_.get(item, 'title', '-')}
                          </Typography>
                          <Styled.BoxIconActions>
                            {!checkType && (
                              <IconButton
                                data-testid={`icon-${index}`}
                                onClick={() =>
                                  setIsOpen({
                                    status: true,
                                    title: titleFile,
                                    url: newUrl,
                                  })
                                }
                              >
                                <RemoveRedEyeTwoTone
                                  sx={{ width: 20, height: 20 }}
                                />
                              </IconButton>
                            )}
                            <Box sx={{ pl: checkType ? '45px' : 0 }}></Box>

                            <IconButton
                              onClick={() =>
                                dispatch(onDownload(newUrl, titleFile))
                              }
                            >
                              <FileDownloadOutlined
                                sx={{ width: 20, height: 20 }}
                              />
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                dispatch(handleDeleteDocument(documents, index))
                              }
                            >
                              <DeleteTwoTone sx={{ width: 20, height: 20 }} />
                            </IconButton>
                          </Styled.BoxIconActions>
                        </Styled.BoxDocumentContent>
                      </Styled.BoxBorder>
                    </>
                  )
                })}
              </Styled.BoxDocuments>
            </>
          )}

          <ButtonUpload
            htmlFor="btn-upload"
            testId="btn-upload"
            onUpload={(files) => dispatch(onChangeUpload(files))}
          />
        </Box>
      </Box>

      <DialogImage
        isOpen={isOpen.status}
        handleClose={() =>
          setIsOpen({
            status: false,
            title: '',
            url: '',
          })
        }
        url={isOpen.url}
        idImage={'image-document' + isOpen.title}
        title={isOpen.title}
        type={isOpen.title.split('.').pop().toUpperCase()}
      />
    </Card>
  )
}

export default Index
