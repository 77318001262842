import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import _ from 'lodash'

export const StyledEditorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textError',
})(({ theme, textError, disabled }) => {
  const borderColor = !_.isEmpty(textError) && theme?.palette?.error?.main
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .tox-tinymce': { borderColor },
    '& .quill': {
      height: 270,
      opacity: disabled ? 0.6 : 1,
      '& .ql-toolbar': {
        '& *': { pointerEvents: disabled ? 'none' : 'auto' },
        borderTopColor: borderColor,
        borderLeftColor: borderColor,
        borderRightColor: borderColor,
      },
      '& .ql-container': {
        height: 'calc(100% - 48px) !important',
        borderColor,
      },
    },
  }
})
