export const headCells = [
  {
    id: 'status',
    label: 'สถานะ',
    isCustom: true,
    minWidth: '40px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'ticket.id',
    label: 'รายการเบิกปัจจุบัน',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'dropdownAction',
    label: 'คำสั่ง',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
  },
]

export const headCellsAsset = [
  {
    id: 'status',
    label: 'สถานะ',
    isCustom: true,
    minWidth: '40px',
    hideSortIcon: true,
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'assetNo',
    label: 'Item Name',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'holder',
    label: 'ผู้ถือครอง',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'contractRemain',
    label: 'สัญญาที่เหลือ',
    isCustom: true,
    minWidth: '100%',
  },
  {
    id: 'dropdownAction',
    label: 'คำสั่ง',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
  },
]
