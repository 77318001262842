import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxSearch = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    maxHeight: 40,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))