export const headCells = [
  {
    id: 'eTestingName',
    label: 'แบบทดสอบ',
    disablePadding: false,
    minWidth: 400,
  },
  {
    id: 'waitingToCheckExamCount',
    label: 'รอตรวจ',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
    minWidth: 30,
    width: 30,
  },
  {
    id: 'setChecked',
    label: 'ตรวจแล้ว',
    disablePadding: false,
    hideSortIcon: true,
    minWidth: 30,
    width: 30,
  },
  {
    id: 'className',
    label: 'คลาส',
    disablePadding: false,
    minWidth: 400,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มอบรม',
    disablePadding: false,
    minWidth: 30,
    width: 30,
  },
  {
    id: 'finishDate',
    label: 'วันสิ้นสุดอบรม',
    disablePadding: false,
    minWidth: 30,
    width: 30,
  },
  {
    id: 'actionCheckExamList',
    label: 'คำสั่ง',
    minWidth: 40,
    hideSortIcon: true,
    editPath: '/manage-class/check-exam',
  },
]

export const headCellStandalone = [
  {
    id: 'codeId',
    label: 'ID',
    disablePadding: false,
    width: '100px',
    minWidth: '100px',
  },
  {
    id: 'name',
    label: 'แบบทดสอบ',
    disablePadding: false,
  },
  {
    id: 'numberOfVersions',
    label: 'จำนวนเวอร์ชั่น',
    disablePadding: false,
    width: '100px',
    minWidth: '100px',
  },
  {
    id: 'checkingState',
    label: 'รอตรวจ',
    disablePadding: false,
    hideSortIcon: true,
    width: '100px',
    minWidth: '100px',
  },
  {
    id: 'actionStandaloneVersion',
    label: 'คำสั่ง',
    minWidth: 40,
    width: '50px',
    hideSortIcon: true,
  },
]

export const headCellVersion = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'version',
    label: 'เวอร์ชั่น',
    disablePadding: false,
  },
  {
    id: 'name',
    label: 'ชื่อแบบทดสอบ',
    disablePadding: false,
  },
  {
    id: 'shareDate',
    label: 'วันเริ่มต้นเปิดให้ทำ',
    disablePadding: false,
  },
  {
    id: 'checkingState',
    label: 'รอตรวจ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'submitNumber',
    label: 'ตรวจแล้ว',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'actionStandaloneVersion',
    label: 'คำสั่ง',
    minWidth: 40,
    hideSortIcon: true,
  },
]

export const breadcrumbList = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'ตรวจข้อสอบ', link: '/', pointer: false },
]

export const breadcrumbStandaloneList = [
  { title: 'E-Testing', link: '/', pointer: false },
  { title: 'ตรวจข้อสอบ', link: '/', pointer: false },
]

export const breadcrumbVersionList = [
  { title: 'ตรวจข้อสอบ', link: '/e-testing/check-exam/list', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const arrayCheckbox = [
  {
    label: 'ชื่อแบบทดสอบ',
    nameCheckBox: 'eTestingNameChecked',
    nameInput: 'eTestingName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'คลาส',
    nameCheckBox: 'classNameChecked',
    nameInput: 'className',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'วันเริ่มอบรม',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    isDate: true,
  },
  {
    label: 'วันสิ้นสุดอบรม',
    nameCheckBox: 'endDateChecked',
    nameInput: 'endDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    isDate: true,
  },
]

export const arrayStandaloneCheckbox = [
  {
    label: 'ID',
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    placeholder: 'ID',
    type: 'text',
  },
  {
    label: 'ชื่อแบบทดสอบ',
    nameCheckBox: 'eTestingNameChecked',
    nameInput: 'eTestingName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ชื่อผู้สร้าง',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ชื่อผู้แก้ไขล่าสุด',
    type: 'text',
  },
  {
    label: 'วันที่สร้าง',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    isDate: true,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]

export const arrayVersionCheckbox = [
  {
    label: 'เวอร์ชั่น',
    nameCheckBox: 'versionChecked',
    nameInput: 'version',
    placeholder: 'เวอร์ชั่น',
    type: 'text',
  },
  {
    label: 'ชื่อแบบทดสอบ',
    nameCheckBox: 'eTestingNameChecked',
    nameInput: 'eTestingName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ชื่อผู้สร้าง',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ชื่อผู้แก้ไขล่าสุด',
    type: 'text',
  },
  {
    label: 'วันที่สร้าง',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    isDate: true,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]

export const arrayStatus = [
  {
    label: 'รอตรวจ',
    name: 'waitingChecked',
  },
  {
    label: 'ตรวจแล้ว',
    name: 'doneChecked',
  },
]

export const defaultFilter = {
  eTestingNameChecked: false,
  eTestingName: '',
  classNameChecked: false,
  className: '',
  startDateChecked: false,
  startDate: new Date(),
  toStartDate: new Date(),
  displayStartDate: false,

  endDateChecked: false,
  endDate: new Date(),
  toEndDate: new Date(),
  displayEndDate: false,
}

export const standaloneFilter = {
  codeIdChecked: false,
  codeId: '',
  eTestingNameChecked: false,
  eTestingName: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  startDateChecked: false,
  startDate: new Date(),
  toStartDate: new Date(),
  displayStartDate: false,
  statusChecked: false,
  status: {
    waitingChecked: false,
    doneChecked: false,
  },
}

export const versionFilter = {
  versionChecked: false,
  version: '',
  eTestingNameChecked: false,
  eTestingName: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  startDateChecked: false,
  startDate: new Date(),
  toStartDate: new Date(),
  displayStartDate: false,
  statusChecked: false,
  status: {
    waitingChecked: false,
    doneChecked: false,
  },
}

export const breadcrumbCheckExam = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'ตรวจข้อสอบ', link: '/manage-class/check-exam/list', pointer: true },
  { title: 'แบบทดสอบ', link: '/', pointer: false },
]

export const breadcrumbCheckStandalone = [
  { title: 'E-Testing', link: '/', pointer: false },
  { title: 'ตรวจข้อสอบ', link: '/e-testing/check-exam/list', pointer: true },
  { title: 'แบบทดสอบ', link: '/', pointer: false },
]