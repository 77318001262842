import * as yup from 'yup'
import { getPlainText } from '../../../../../../utils/lib'
import { validateVideo } from './events'

export const schema = yup.object({
  mediaType: yup.string().default('IMAGE'),
  image: yup
    .string()
    .test(
      'media-validate',
      'กรุณาอัปโหลดรูปภาพ',
      (value, { parent }) => parent.mediaType != 'IMAGE' || value
    ),
  mobileImg: yup
    .string()
    .test(
      'media-validate',
      'กรุณาอัปโหลดรูปภาพ',
      (value, { parent }) => parent.mediaType != 'IMAGE' || value
    ),
  videoURL: yup
    .string()
    .test(
      'media-validate',
      'กรุณากรอก',
      (value, { parent }) => parent.mediaType != 'VIDEO' || value
    )
    .test(
      'link-validate',
      'Link ไม่ตรงกับ Format Link วิดีโอต้องเป็น Youtube หรือ Vimeo เท่านั้น',
      (value, { parent }) => parent.mediaType != 'VIDEO' || validateVideo(value)
    ),
  videoImg: yup
    .string()
    .test(
      'media-validate',
      'กรุณาอัปโหลดรูปภาพ',
      (value, { parent }) => parent.mediaType != 'VIDEO' || value
    ),
  subtitle: yup
    .string()
    .test('text-length-validate', 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร', (value) => {
      const plainText = getPlainText(value)
      return plainText.length <= 255
    }),
  title: yup
    .string()
    .test('text-length-validate', 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร', (value) => {
      const plainText = getPlainText(value)
      return plainText.length <= 255
    }),
  description: yup
    .string()
    .test('text-length-validate', 'ระบุข้อความความยาวไม่เกิน 230 ตัวอักษร', (value) => {
      const plainText = getPlainText(value)
      return plainText.length <= 230
    }),
  primaryStatus: yup.boolean(),
  primaryButtonTitle: yup
    .string()
    .test(
      'button-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.primaryStatus || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  primaryButtonURL: yup
    .string()
    .test(
      'button-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.primaryStatus || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  secondaryStatus: yup.boolean(),
  secondaryButtonTitle: yup
    .string()
    .test(
      'button-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.secondaryStatus || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  secondaryButtonURL: yup
    .string()
    .test(
      'button-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.secondaryStatus || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  distributionChannels: yup
    .array()
    .test(
      'distributionChannels-validate',
      'กรุณาเลือก',
      (value) => value.length > 0
    ),
})
