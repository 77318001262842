import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import _ from 'lodash'

export const onUploadTemplate = (dataImport) => {
  store.dispatch(setFieldValue({ key: 'isLoadingUploadBtn', value: true }))

  const importFile = {
    fileName: _.get(dataImport, 'target.files[0].name', 'No File Chosen'),
    file: dataImport.target.files[0],
    uploadResult: [],
  }
  store.dispatch(
    setFieldValue({ key: 'formData.importFile', value: importFile }),
  )

  setTimeout(() => {
    store.dispatch(setFieldValue({ key: 'isLoadingUploadBtn', value: false }))
  }, 500)
}
