import { DetailsBookingCard } from './DetailsBookingCard'
import { StyledContent } from '../Styled'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
// import { handleConfirmBooking } from '../PreviewBooking/event'
import { handleDeleteBookingDialog } from './event'
import { ButtonFooterCard } from '../ButtonFooterCard'
import { InformationBookingCard } from './components/InformationBookingCard'
import { InformationOperatorCard } from './components/InformationOperatorCard'
import { useEffect } from 'react'
import { initialDrawerForm } from '../event'
import {
  nextForm,
  setChangeBookingInformation,
} from '../../../../redux/slices/roomManagement/Drawer'
import { Loading } from '../../components/Loading'
import _ from 'lodash'
import dayjs from 'dayjs'

export const FormBooking = () => {
  const dispatch = useDispatch()
  const { bookingUuid, disabledBtnNext, isLoadingDrawer, formError } =
    useSelector(
      (state) => ({
        bookingUuid: state.roomManagementDrawer.bookingInformation.bookingUuid,
        disabledBtnNext: state.roomManagementDrawer.disabledBtnNext,
        isLoadingDrawer: state.roomManagementDrawer.isLoadingDrawer,
        formError: state.roomManagementDrawer.formError,
      }),
      shallowEqual,
    )

  useEffect(() => {
    initialDrawerForm(bookingUuid)
  }, [])

  if (isLoadingDrawer) {
    return <Loading isLoading={isLoadingDrawer} />
  }

  return (
    <StyledContent>
      <InformationBookingCard />
      <DetailsBookingCard />
      <InformationOperatorCard />
      <ButtonFooterCard boxSx={{ gap: 2, display: 'flex' }}>
        {!_.isEmpty(bookingUuid) && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(handleDeleteBookingDialog(bookingUuid))}
          >
            ลบ
          </Button>
        )}
        <Button
          type="submit"
          disabled={disabledBtnNext || !_.isEmpty(formError)}
          variant="contained"
          onClick={() => {
            if (_.isEmpty(bookingUuid)) {
              dispatch(
                setChangeBookingInformation({
                  key: 'createdAt',
                  value: dayjs().format('DD/MM/YYYY HH:mm'),
                }),
              )
            }
            dispatch(setChangeBookingInformation({ key: 'step', value: 1 }))
            dispatch(nextForm(true))
          }}
        >
          ถัดไป
        </Button>
      </ButtonFooterCard>
    </StyledContent>
  )
}
