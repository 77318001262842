import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'

export const handleHasPeriodFlagReturn = (event, value) => {
  if (!value) {
    const { formData } = store.getState().crud
    store.dispatch(
      setFieldValue({
        key: 'formData',
        value: {
          ...formData,
          refundYear: null,
          refundMonth: null,
          refundDay: null,
        },
      }),
    )
  }
}
