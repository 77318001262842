import * as yup from 'yup'
import dayjs from 'dayjs'
import { paymentMethodEnum } from '../../../../../modules/Inventory/Transfer/Form/enum/paymentMethod'

export const drawerFormValidationSchemaSKU = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
})

export const drawerFormValidationSchemaAsset = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  assetNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ Item Name'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDateForm', (contractStartDateForm, schema) => {
      if (contractStartDateForm && dayjs(contractStartDateForm).isValid()) {
        const dayAfter = new Date(contractStartDateForm.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDateForm', (contractStartDateForm, schema) => {
      if (contractStartDateForm && dayjs(contractStartDateForm).isValid()) {
        const startDateDate = new Date(contractStartDateForm)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ต่อระยะให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holderForm: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
})

export const drawerFormValidationSchemaLicense = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDateForm', (contractStartDateForm, schema) => {
      if (contractStartDateForm && dayjs(contractStartDateForm).isValid()) {
        const dayAfter = new Date(contractStartDateForm.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDateForm', (contractStartDateForm, schema) => {
      if (contractStartDateForm && dayjs(contractStartDateForm).isValid()) {
        const startDateDate = new Date(contractStartDateForm)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ต่อระยะให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holderForm: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  payerForm: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  paymentTypeForm: yup
    .object({
      value: yup.string().nullable(),
    })
    .nullable(),
  paymentDescForm: yup
    .string()
    .nullable()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .when('paymentTypeForm', (paymentTypeForm, schema) => {
      if ([paymentMethodEnum.CREDIT_CARD].includes(paymentTypeForm?.value)) {
        return schema.required('กรุณากรอกชื่อบนบัตร')
      } else if ([paymentMethodEnum.OTHER].includes(paymentTypeForm?.value)) {
        return schema.required('กรุณากรอกรายละเอียด')
      }
      return schema.nullable()
    }),
  paymentFileForm: yup
    .object()
    .nullable()
    .when('paymentTypeForm', (paymentTypeForm, schema) => {
      if ([paymentMethodEnum.CASH_INVOICE].includes(paymentTypeForm?.value)) {
        return schema.required('กรุณาอัปโหลดรูปภาพ')
      }
      return schema.nullable()
    }),
})
