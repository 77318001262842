import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RefreshTwoTone from '@mui/icons-material/RefreshTwoTone'
import Autocomplete from '../../../../../../../components/Input/Autocomplete'
import { DragHolder } from '../../../../../components/DragDropContent'
import {
  getStateValue,
  handleChange,
  handleGetCourseList,
} from '../../../events'
import {
  StyledFlexRow,
  StyledCourseCard,
  StyledCourseHeader,
  StyledMiniIconBtn,
} from '../Styled'
import { handleDeleteItem } from '../events'
import { StyledGroupHeader, StyledGroupItems } from './Styled'

const CourseForm = ({ provided, index, pathCourseKey }) => {
  const [isExpanded, setExpanded] = useState(true)
  const courseKey = `${pathCourseKey}[${index}]`
  const isRequiredKey = `${courseKey}.isRequired`
  const isRequired = useSelector(
    () => getStateValue(isRequiredKey, []),
    shallowEqual,
  )
  const dispatch = useDispatch()

  return (
    <StyledCourseCard {...provided.draggableProps} ref={provided.innerRef}>
      <StyledCourseHeader>
        <StyledFlexRow sx={{ alignItems: 'center' }}>
          <DragHolder provided={provided} />
          <Typography variant="body1b">หลักสูตรที่ {index + 1}</Typography>
          <StyledMiniIconBtn onClick={() => dispatch(handleGetCourseList())}>
            <RefreshTwoTone color="primary" />
          </StyledMiniIconBtn>
        </StyledFlexRow>
        <StyledMiniIconBtn onClick={() => setExpanded(!isExpanded)}>
          {isExpanded ? (
            <KeyboardArrowUpTwoTone />
          ) : (
            <KeyboardArrowDownTwoTone />
          )}
        </StyledMiniIconBtn>
      </StyledCourseHeader>
      {isExpanded && (
        <>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <AutocompleteCourse courseKey={courseKey} />

            {index !== 0 && (
              <IconButton
                sx={{ mt: 3 }}
                onClick={() => dispatch(handleDeleteItem(index, pathCourseKey))}
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <Switch
              checked={isRequired}
              onChange={(e) =>
                dispatch(handleChange(isRequiredKey, e.target.checked))
              }
            />
            <Typography>บังคับเรียนใหม่เพื่อผ่าน Learning path นี้</Typography>
          </Box>
        </>
      )}
    </StyledCourseCard>
  )
}

export default CourseForm

export const AutocompleteCourse = ({ courseKey }) => {
  const selectedCourseKey = `${courseKey}.course`
  const uuidField = `${selectedCourseKey}.uuid`
  const { uuid, options } = useSelector(
    (state) => ({
      uuid: getStateValue(uuidField),
      options: [...state.eLearningPathForm.coursesOption],
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  const dispatch = useDispatch()

  return (
    <Autocomplete
      required
      isHideSort
      errorSx={{ mb: 0 }}
      labelText="ชื่อหลักสูตร"
      options={_.sortBy(options, 'type')}
      groupBy={(option) => option.type}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="ชื่อหลักสูตร"
          inputProps={{ ...params.inputProps, 'data-testid': 'select' }}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <StyledGroupHeader>{params.group}</StyledGroupHeader>
          <StyledGroupItems>{params.children}</StyledGroupItems>
        </li>
      )}
      textError={_.get(errors, uuidField, '')}
      showTextError={_.get(errors, uuidField, '')}
      defaultValue={
        options.find((item) => item.value === uuid) || { label: '', value: '' }
      }
      handleChange={(e, value) => {
        dispatch(handleChange(`${courseKey}.type`, value.type))
        dispatch(handleChange(selectedCourseKey, value))
      }}
    />
  )
}
