import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'
import { handleResetState } from './event'

const reportComp = createSlice({
  name: 'reportComp',
  initialState,
  reducers: {
    setReduxValue: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setInitialReport: (state, { payload }) => {
      const {
        columns,
        filterColumnOptions,
        onDownload,
        defaultColumns,
        columnName,
        columnNameTH,
        defaultFilters = [],
        hasSortedColumns,
      } = payload
      state.columns = columns
      state.filterColumnOptions = filterColumnOptions
      state.onDownload = onDownload
      state.defaultColumns = defaultColumns
      state.columnName = columnName
      state.columnNameTH = columnNameTH
      state.filters = defaultFilters
      state.bodyFilters = []
      state.displayFilters = defaultFilters
      state.reportsData = []
      state.tablePage = 0
      state.page = -1
      state.rowsPerPage = { label: '100', value: 100 }
      state.allCount = 0
      state.columnSelected = null
      state.value = ''
      state.isLoading = false
      state.isLoadingDrawer = false
      state.isOpenFilterDrawer = false
      state.isOpenManageColumnDrawer = false
      state.tabActiveColDrawer = 0
      state.tabActiveColCard = 0
      state.columnSelected = null
      state.error = ''
      state.isFilter = false
      state.disabledAddBtn = true
      state.newColumnName = null
      state.firstColumnSelected = null
      state.secondaryColumnSelected = null
      state.hasSortedColumns = hasSortedColumns
      state.options = []
      state.isInitial = true
    },
    setReportConfig: (state, { payload }) => {
      _.set(state, 'reportConfig', payload.reportConfig)
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenManageColumnDrawer: (state, { payload }) => {
      state.isOpenManageColumnDrawer = payload
    },
    setColumns: (state, { payload }) => {
      state.columns = payload
    },
    setColumnSelected: (state, { payload }) => {
      state.columnSelected = payload
    },
    setIsFilter: (state, { payload }) => {
      state.isFilter = payload
    },
    setFiltersData: (state, { payload }) => {
      state.filters = payload
    },
    setValue: (state, { payload }) => {
      state.value = payload
    },
    resetState: (state) => {
      handleResetState(state)
    },
    setDownloadName: (state, { payload }) => {
      _.set(state, 'reportConfig.downloadName', payload)
    },
  },
})

export const {
  setReduxValue,
  setInitialReport,
  setReportConfig,
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setColumns,
  setColumnSelected,
  setIsFilter,
  setFiltersData,
  setValue,
  resetState,
  setDownloadName,
} = reportComp.actions

export default reportComp.reducer
