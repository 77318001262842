import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  courseDetail,
  eLearningDetail,
  trainingPlanByCourseVersion,
} from '../../../utils/apiPath'

export const getELearningDetail = createAsyncThunk(
  'eLearningPathDetail/getELearningDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseDetail = createAsyncThunk(
  'eLearningPathDetail/getCourseDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${courseDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCourseClassList = createAsyncThunk(
  'eLearningPathDetail/postCourseClassList',
  async ({ uuid, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${trainingPlanByCourseVersion}/${uuid}`,
        body,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
