import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { Typography } from '@mui/material'

const BarChart = ({
  dataSet,
  labels,
  height = '100%',
  eleId = 'barChart',
  showXAxisLabel = false,
  showYAxisLabel = false,
  xAxisLabel = '',
  yAxisLabel = '',
  showPercent = false,
}) => {
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }

    const data = _.get(dataSet, 'data', [])
    const total = _.sum(data)
    const maxData = Math.max(...data)
    const hasZero = _.some(data, (val) => val === 0)
    const adjustedDataSet = {
      ...dataSet,
      data: _.map(data, (value) => {
        if (value === 0) {
          return 1
        } else if (hasZero) {
          return (value * 100) / maxData
        } else {
          return value
        }
      }),
    }

    const ctx = document.getElementById(eleId)
    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            borderRadius: 5,
            barPercentage: 1,
            categoryPercentage: 0.95,
            ...adjustedDataSet,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 50,
          },
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                weight: 'bold',
              },
            },
          },
          yAxis: {
            display: false,
            min: 0,
            max: hasZero ? 100 : maxData,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value, context) => {
              const percentage =
                data[context.dataIndex] > 0
                  ? ((data[context.dataIndex] / total) * 100).toFixed(2)
                  : 0
              const percentageTxt = showPercent ? `(${percentage}%)` : ''
              return `${data[context.dataIndex]} ${percentageTxt}`
            },
            font: {
              weight: 'bold',
            },
          },
        },
      },
    })
    setChart(newChart)
  }, [
    dataSet,
    labels,
    showXAxisLabel,
    showYAxisLabel,
    showPercent,
    xAxisLabel,
    yAxisLabel,
  ])

  return (
    <Box display="flex" height="100%" alignItems="end">
      {(showXAxisLabel || showYAxisLabel) && (
        <Box
          sx={{
            height: height,
            width: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}
        >
          {showYAxisLabel && (
            <Typography variant="body3b" textAlign="center">
              {yAxisLabel ?? ''}
            </Typography>
          )}
          <Box
            sx={{
              height: `90%`,
              minHeight: '50px',
              width: '1px',
              background: '#DBE4F1',
              margin: 'auto',
            }}
          ></Box>
          {showXAxisLabel && (
            <Typography variant="body3b" textAlign="center">
              {xAxisLabel ?? ''}
            </Typography>
          )}
        </Box>
      )}
      <Box sx={{ height: height, width: '100%' }}>
        <canvas id={eleId} />
      </Box>
    </Box>
  )
}

export default BarChart
