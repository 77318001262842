import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useSelector, shallowEqual } from 'react-redux'
import { viewTypeEnum } from '../../enum/viewTypeEnum'
import { openStockDrawer } from '../../handler/openStockDrawer'
import _ from 'lodash'
import { validatePermission } from '../../../../../../utils/lib'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { statusValueEnum } from '../../enum/statusEnum'
import { handleCancelNotReturn } from '../../handler/handlerCancelNotReturn'

const DropdownButton = ({ item, itemIndex }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { viewType } = useSelector(
    (state) => ({
      viewType: state.crud.viewType,
    }),
    shallowEqual,
  )
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const assetCode = _.get(item, 'assetCode', null)
  const statusFlagReturn = _.get(item, 'product.statusFlagReturn', 'INACTIVE')
  const isNotReturnActive = assetCode || statusFlagReturn === 'ACTIVE'
  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions.map((option, index) => {
          let hidden = false
          if (
            option.value === 'not_return' &&
            (viewType !== viewTypeEnum.RETURN ||
              (viewType === viewTypeEnum.RETURN &&
                item?.withdrawStatus === statusValueEnum.NOT_RETURN))
          ) {
            hidden = true
          }
          if (
            option.value === 'cancel_not_return' &&
            (viewType !== viewTypeEnum.RETURN ||
              (viewType === viewTypeEnum.RETURN &&
                item?.withdrawStatus !== statusValueEnum.NOT_RETURN))
          ) {
            hidden = true
          }
          if (
            option.value === 'view_not_return' &&
            item?.withdrawStatus !== statusValueEnum.NOT_RETURN
          ) {
            hidden = true
          }
          let disabled = false
          if (option.value === 'view' && !hasPermission) {
            disabled = true
          }
          if (!isNotReturnActive && !['view'].includes(option.value)) {
            hidden = true
          }
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                if (option.value === 'not_return') {
                  openStockDrawer(true, item, itemIndex, false)
                } else if (option.value === 'cancel_not_return') {
                  handleCancelNotReturn(itemIndex)
                } else if (option.value === 'view_not_return') {
                  openStockDrawer(true, item, itemIndex, true)
                } else if (option.value === 'view') {
                  let productType = _.get(item, 'productType', '')
                  const url = `${productPath?.[productType]}/${item?.value}`
                  const newWindow = window.open(
                    url,
                    '_blank',
                    'noopener,noreferrer',
                  )
                  if (newWindow) newWindow.opener = null
                }
                setAnchorEl(null)
              }}
              sx={{ display: hidden ? 'none' : '' }}
              disabled={disabled}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [
  { text: 'รายละเอียดทรัพย์สิน', value: 'view' },
  { text: 'ทำรายการไม่คืนทรัพย์สิน', value: 'not_return' },
  { text: 'ยกเลิกทำรายการไม่คืนทรัพย์สิน', value: 'cancel_not_return' },
  { text: 'รายละเอียดการไม่คืนทรัพย์สิน', value: 'view_not_return' },
]

export const productPath = {
  STOCK: '/inventory/product/stock/view',
  STOCK_ASSET: '/inventory/product/stock/view',
  ASSET: '/inventory/product/asset/view',
  LICENSE: '/inventory/product/license/view',
  WAREHOUSE: '/inventory/stock/detail',
  TICKET: '/inventory/transfer/form',
}
