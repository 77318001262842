import { headerCells } from './model/headerCells'
import { inventoryTicketDetailFilter } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_TICKET_DETAIL',
  moduleSlug: 'inventory-ticket-detail',
  moduleTitleFull: 'รายการโอน',
  modulesTitleShort: 'รายการโอน',
  modulePath: '/inventory/transfer',
  filterPath: inventoryTicketDetailFilter,
  headerCells: headerCells,
}
