export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: `ปี Budget`,
    nameCheckBox: 'yearBudgetChecked',
    nameInput: 'yearBudget',
    placeholder: `กรอกเลขปี`,
    type: 'text',
  },
  {
    label: `Cost center`,
    nameCheckBox: 'costCenterChecked',
    nameInput: 'costCenter',
    placeholder: `กรอก Cost center`,
    type: 'text',
  },
  {
    label: `Cost center name`,
    nameCheckBox: 'costCenterNameChecked',
    nameInput: 'costCenterName',
    placeholder: `กรอก Cost center name`,
    type: 'text',
  },
  {
    label: `Account code (Old)`,
    nameCheckBox: 'oldAccountCodeChecked',
    nameInput: 'oldAccountCode',
    placeholder: `กรอก Account code (Old)`,
    type: 'text',
  },
  {
    label: `Account code (New)`,
    nameCheckBox: 'newAccountCodeChecked',
    nameInput: 'newAccountCode',
    placeholder: `กรอก Account code (New)`,
    type: 'text',
  },
  {
    label: `Categories`,
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    placeholder: `กรอก Categories`,
    type: 'text',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createdAtChecked',
    nameInput: 'createdAt',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createdAtStartDate',
    dateStateFinish: 'createdAtEndDate',
    dateStateDisplay: 'createdAtDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: `กรอกรายชื่อพนักงาน`,
    type: 'text',
  },
  {
    label: `วันที่แก้ไขล่าสุด`,
    nameCheckBox: 'updatedAtChecked',
    nameInput: 'updatedAt',
    placeholder: `กรุณาใส่ วันที่แก้ไขล่าสุด`,
    dateStateStart: 'updatedAtStartDate',
    dateStateFinish: 'updatedAtEndDate',
    dateStateDisplay: 'updatedAtDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: `กรอกรายชื่อพนักงาน`,
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
