import { store } from 'src/App'
import {
  setIsOpenStaffDrawer,
  setReduxDashboardByKey,
} from '../../../../redux/slices/monthlyPlan'

export const handleOpenStaffDrawer = (staffType = '') => {
  store.dispatch(setReduxDashboardByKey({ key: 'staffType', value: staffType }))
  store.dispatch(setIsOpenStaffDrawer(true))
}
