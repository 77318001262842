import { Information } from './Information'
import { BudgetDetails } from './BudgetDetails'
import { StyledContent } from '../../Styled'

export const Content = () => {
  return (
    <StyledContent>
      <Information />
      <BudgetDetails />
    </StyledContent>
  )
}
