import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import TextField from '@mui/material/TextField'
import Select from '../../../../../components/Input/Select'
import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import { BoxContainer, InputSectionContainer } from '../Styled'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { handleChange } from './events'

const Index = () => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual,
  )
  const dispatch = useDispatch()
  return (
    <>
      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ประสบการณ์</Typography>
          <BoxContainer noGap sx={{ mt: '10px' }}>
            <InputSectionContainer width="20%">
              <TextFieldInput
                id="institution"
                name="name"
                labelText="สถานศึกษา (มหาวิทยาลัย)"
                isShowTextError={false}
                value={_.get(data, 'institution', '')}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      value: _.get(e, 'target.value', ''),
                      where: 'institution',
                    }),
                  )
                }
              />
            </InputSectionContainer>
            <InputSectionContainer width="10%">
              <TextFieldInput
                id="between"
                name="name"
                labelText="ระหว่าง"
                isShowTextError={false}
                placeholder="25xx - ปัจจุบัน"
                value={_.get(data, 'between', '')}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      value: _.get(e, 'target.value', ''),
                      where: 'between',
                    }),
                  )
                }
              />
            </InputSectionContainer>
            <Select
              name="qualification"
              boxSx={{ width: '30%' }}
              labelText="วุฒิการศึกษา"
              options={_.get(data, 'qualificationOption', [])}
              placeholder="กรุณาเลือก"
              renderInput={(params) => (
                <TextField
                  placeholder="กรุณาเลือก"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-main-admin',
                  }}
                />
              )}
              value={_.get(data, 'qualification')}
              handleChange={(e) => {
                dispatch(
                  handleChange({
                    value: _.get(e, 'target.value', ''),
                    where: 'qualification',
                  }),
                )
              }}
            />
            <InputSectionContainer width="20%">
              <TextFieldInput
                id="branch"
                name="name"
                labelText="สาขา"
                isShowTextError={false}
                value={_.get(data, 'branch', '')}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      value: _.get(e, 'target.value', ''),
                      where: 'branch',
                    }),
                  )
                }
              />
            </InputSectionContainer>
            <InputSectionContainer width="10%">
              <TextFieldInput
                id="gpa"
                name="name"
                labelText="เกรดเฉลี่ย"
                isShowTextError={false}
                placeholder="4.00"
                value={_.get(data, 'gpa', '')}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      value: _.get(e, 'target.value', ''),
                      where: 'gpa',
                    }),
                  )
                }
              />
            </InputSectionContainer>
          </BoxContainer>
        </Box>
      </Card>
    </>
  )
}

export default Index
