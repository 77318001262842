import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enum/enum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'

export const handleFiltering = (filter) => (dispatch) => {
  const { defaultStation, defaultStationName, hasPermissionALL } =
    store.getState().crud.roomManagementDashboard
  const isFilterDate = filter.dateChecked && filter.startDate && filter.endDate
  const isFilterStation = filter.stationChecked && filter.station.length > 0
  dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        startDate: isFilterDate
          ? dayjs(filter.startDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: isFilterDate
          ? dayjs(filter.endDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        station: isFilterStation ? filter.station : defaultStation,
        stationName: isFilterStation ? filter.stationName : defaultStationName,
        filterTotal: hasPermissionALL ? 2 : 1,
        isFilterDate: isFilterDate,
        isFilterStation: isFilterStation,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  uuid,
  name,
  filter,
  setFilter,
  listKey,
  subListKey,
  isCheck,
}) => {
  const { defaultStation, defaultStationName } =
    store.getState().crud.roomManagementDashboard
  let objState = {}
  if (!_.isNil(listKey)) {
    const arrayNameData = [...filter[subListKey]]
    const arrayData = [...filter[listKey]]
    if (value) {
      arrayData.push(uuid)
      arrayNameData.push(name)
    } else {
      const index = arrayData.findIndex((e) => e === uuid)
      arrayData.splice(index, 1)
      const nameIndex = arrayNameData.findIndex((e) => e === name)
      arrayNameData.splice(nameIndex, 1)
    }
    objState = {
      ...filter,
      [listKey]: arrayData,
      [subListKey]: arrayNameData,
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: {
        ...defaultFilter,
        station: defaultStation,
        stationName: defaultStationName,
      }[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  const { roomManagementDashboard } = store.getState().crud
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: true,
      startDate: defaultFilter.startDate,
      endDate: defaultFilter.endDate,
      displayDate: true,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: true,
      station: roomManagementDashboard?.defaultStation || [],
      stationName: roomManagementDashboard?.defaultStationName || [],
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
