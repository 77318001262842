import Typography from '@mui/material/Typography'
import _ from 'lodash'
import React from 'react'
import { formatData } from './events'
import { StyledDot } from './Styled'

const ActionEvaStatusList = ({ row }) => {
  const data = _.get(row, 'status', '')

  return (
    <Typography
      variant="body2"
      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
    >
      <StyledDot data={data} />
      {formatData(data, false)}
    </Typography>
  )
}

export default ActionEvaStatusList
