import _ from 'lodash'
import { handleChange } from '../events'
import { timeSpanTypeOptions } from './model'

export const createOption = (label) => ({ label, value: label })

export const handleKeyDown =
  (event, inputValue, setInputValue, tags) => (dispatch) => {
    if (!inputValue) return

    const newTags = [...tags, createOption(inputValue)]
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        dispatch(handleChange('formData.setting.tag', newTags))
        setInputValue('')
        event.preventDefault()
    }
  }

export const handleDefaultList = (option, list) => {
  return _.map(list, (item) => {
    const listObj = option.find(
      (select) => select.value === item.uuid || select.value === item.value,
    )
    return listObj || { label: '', uuid: '' }
  })
}

export const handleDefaultSelect = (option, value) => {
  const objValue = option.find((item) => item.value === value)
  if (_.isEmpty(objValue)) return timeSpanTypeOptions[1].value
  return objValue.value
}

export const handleTimeSpanOptions = (type) => {
  const amount = timeSpanTypeOptions.find(
    (option) => option.value === type,
  ).amount
  return Array(amount)
    .fill()
    .map((_, i) => ({ label: i + 1, value: i + 1 }))
}

const regex = /^[0-9,]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  if (e.target.value[0] === '0') e.target.value = Number(e.target.value)
  return e.target.value
}
