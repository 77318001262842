import { store } from '../../../../App'
import { overviewStatusTxt } from '../enums/overviewEnum'
import { openCourseDrawer } from '../../../../redux/slices/eLearning/dashboard'

export const handleClickClassDrawer = (status = null) => {
  const statusTxt = overviewStatusTxt?.[status] ?? ''
  const title = !status ? 'จำนวนคลาสทั้งหมด' : `รายละเอียด ${statusTxt}`
  store.dispatch(openCourseDrawer({
    header: title, drawerType: status, courseType: status
  }))
}