import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import {
  setInitialCreateForm,
  setFieldValue,
  setAddressList,
  setUserDetail,
  setLoading,
} from '../../../redux/slices/eExaminer/form'
import { examinationStudentDetail } from '../../../utils/apiPath'
import { callAPI, checkIdCard } from '../../../utils/lib'
import {
  setExaminerDetail,
  startLoading,
  stopLoading,
} from '../../../redux/slices/eExaminer/form'
import { setRefreshExaminerList } from '../../../redux/slices/eExamination/list'
import { getExaminerFormDetail } from '../../../services/eExamination/form'
import {
  getUserProfile,
  getUserAgent,
} from '../../../services/manageClass/form'
import dayjs from 'dayjs'
import { EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS } from '../../../constants/eExamination'
import { setFieldUploadValue } from '../../../redux/slices/uploadFile'
import { uniqId } from '../../../services/util'

export const handleFiltering = () => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    toDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const resetFormAll = (formik) => (dispatch) => {
  dispatch(setExaminerDetail(null))
  dispatch(setUserDetail(null))
  dispatch(handleChangeKeyValue(formik, 'idCardPhoto', ''))
  dispatch(handleChangeKeyValue(formik, 'examinerType', ''))
  dispatch(handleChangeKeyValue(formik, 'code', ''))
  dispatch(handleChangeKeyValue(formik, 'zipCode', ''))
  dispatch(handleChangeKeyValue(formik, 'address', ''))
  dispatch(handleChangeKeyValue(formik, 'unitName', ''))
  dispatch(handleChangeKeyValue(formik, 'schedule', ''))
  dispatch(handleChangeKeyValue(formik, 'idCardImageKey', {}))
  dispatch(resetFormUserNotFound(formik))
}

export const fetchExaminerData = (props) => async (dispatch) => {
  const { selectedExaminer, genderList, qualificationList } = props
  const initialCreate = {
    genderList: genderList,
    qualificationList: qualificationList,
  }
  if (_.isEmpty(selectedExaminer)) {
    await dispatch(setInitialCreateForm(initialCreate))
  } else {
    dispatch(startLoading())
    await dispatch(setInitialCreateForm(initialCreate))
    const response = await dispatch(
      getExaminerFormDetail({ uuid: selectedExaminer }),
    )
    const results = _.get(response, 'payload.data', [])
    await dispatch(setExaminerDetail(results))
    dispatch(stopLoading())
  }
}

export const handleChangeKeyValue = (formik, key, value) => (dispatch) => {
  if (key === 'code') {
    if (!value) value = { label: '', value: '' }
    const codeVal = value.value
    dispatch(setFieldValue({ key, value: codeVal }))
    formik.setFieldValue(key, codeVal)
  } else {
    dispatch(setFieldValue({ key, value }))
    formik.setFieldValue(key, value)
  }
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}

export const getStateValue = (state, key, defaultValue = '') => {
  return _.get(state.eExaminerForm.body, key, defaultValue)
}

export const resetFormUserNotFound = (formik) => (dispatch) => {
  const inputArr = [
    'dateOfBirth',
    'email',
    'idCardNo',
    'unitNumber',
    'firstName',
    'lastName',
    'mobilePhone',
    'prefix',
    'userProfile',
    'gender',
    'qualification',
    'zipCode',
    'tambon',
    'amphure',
    'province',
    'institution',
    'between',
    'branch',
    'gpa',
    'unitName',
    'address',
  ]
  inputArr.map((item) => dispatch(handleChangeKeyValue(formik, item, '')))
}

export const resetAddressList = (props) => (dispatch) => {
  const { data, setTambonOption, setAmphureOption, setProvinceOption, formik } =
    props
  const resetAddr = props.resetAddr === false ? false : true
  dispatch(setAddressList(data))
  setTambonOption([])
  setAmphureOption([])
  setProvinceOption([])
  dispatch(handleChangeKeyValue(formik, 'tambon', ''))
  dispatch(handleChangeKeyValue(formik, 'amphure', ''))
  dispatch(handleChangeKeyValue(formik, 'province', ''))
  if (resetAddr) dispatch(handleChangeKeyValue(formik, 'address', ''))
}

export const submitExaminerField = async ({
  scheduleUuid,
  values,
  isEditForm,
  setDialog,
  setIsLoading,
  selectedExaminer,
  handleCloseExaminerFilterDrawyer,
  dispatch,
  formik,
}) => {
  let otherDocsValues = []
  if (values.otherDocs && values.otherDocs.length > 0) {
    otherDocsValues = values.otherDocs.map((item) => {
      return {
        ...item,
      }
    })
  }
  let body = {
    userCode: null,
    agentType: null,
    userProfile: null,
    schedule: scheduleUuid,
    qualification: values.qualification,
    gender: values.gender,
    province: values.province,
    amphure: values.amphure,
    tambon: values.tambon,
    address: values.address,
    idCardImageKey: values.idCardImageKey,
    prefix: values.prefix,
    dateOfBirth: dayjs(values.dateOfBirth).format('DD/MM/YYYY'),
    mobilePhone: values.mobilePhone,
    institution: values.institution,
    between: values.between,
    branch: values.branch,
    gpa: values.gpa,
    otherDocuments: otherDocsValues,
    idCardNo: values.idCardNo,
    firstName: values.firstName,
    lastName: values.lastName,
  }

  if (values.examinerType === 'A' || values.examinerType === 'L') {
    if (values.userProfile) {
      body = {
        ...body,
        userProfile: values.userProfile,
      }
    } else {
      body = {
        ...body,
        userCode: values.code,
        agentType: values.examinerType,
      }
    }
  } else {
    body = {
      ...body,
      userProfile: values.userProfile,
    }
  }
  const methodType = isEditForm ? 'put' : 'post'
  if (isEditForm)
    body = {
      ...body,
      uuid: selectedExaminer,
    }
  setDialog({ open: true, isLoading: true })
  formik.setErrors([])
  callAPI({
    method: methodType,
    url: examinationStudentDetail,
    body: body,
    onSuccess: () => {
      setDialog({
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        open: true,
        variant: 'success',
        isLoading: false,
        onConfirmClick: () => {
          setDialog({ variant: 'success', open: false })
          handleCloseExaminerFilterDrawyer()
          dispatch(setRefreshExaminerList(true))
        },
      })
    },
    onError: (er) => {
      setDialog({ open: false, isLoading: false })
      let errors = { ...formik.errors }
      _.get(er.response.data, 'message', []).map((item) => {
        if (item?.property === 'StudentDuplicateMobileNo') {
          errors = { ...errors, mobilePhone: item?.constraints?.value }
        }
        if (item?.property === 'StudentDuplicateIdCardNo') {
          errors = { ...errors, idCardNo: item?.constraints?.value }
        }
      })
      formik.setErrors(errors)
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const setExaminerForm = (props) => (dispatch) => {
  const {
    eExaminerDetail,
    formik,
    handleSelectedAddress,
    setDefaultUser /*otherDocConfig*/,
  } = props
  if (
    eExaminerDetail.idCardImageKey &&
    !_.isEmpty(eExaminerDetail.idCardImageKey)
  ) {
    dispatch(
      handleChangeKeyValue(
        formik,
        'idCardImageKey',
        eExaminerDetail.idCardImageKey,
      ),
    )
    dispatch(
      setFieldUploadValue({
        key: `formData.examinationIdCard`,
        value: {
          files: [
            {
              key: eExaminerDetail.idCardImageKey?.key,
              displayName: eExaminerDetail.idCardImageKey?.fileName,
              fileSize: eExaminerDetail.idCardImageKey?.fileSize,
              fileType: eExaminerDetail.idCardImageKey?.fileType,
              percent: 100,
            },
          ],
          isUploading: false,
        },
      }),
    )
  }
  let userCode = ''
  let selectedUserOption = { label: '', value: '' }
  if (eExaminerDetail.userProfile) {
    const userType = eExaminerDetail.userProfile.userType
    const agentType = eExaminerDetail.userProfile.agentType
    userCode = eExaminerDetail.userProfile.userCode
    const userProfile = eExaminerDetail.userProfile
    if (agentType) {
      dispatch(handleChangeKeyValue(formik, 'examinerType', agentType))
      selectedUserOption = {
        label: `${userCode} - ${userProfile.firstNameTH} ${userProfile.lastNameTH}`,
        value: userCode,
      }
    } else {
      dispatch(handleChangeKeyValue(formik, 'examinerType', userType))
      selectedUserOption = {
        label: `${userCode} - ${userProfile.firstNameTH} ${userProfile.lastNameTH}`,
        value: userCode,
      }
    }

    dispatch(handleChangeKeyValue(formik, 'email', userProfile.email))
    dispatch(handleChangeKeyValue(formik, 'unitName', userProfile.unitName))
    dispatch(handleChangeKeyValue(formik, 'unitNumber', userProfile.unitNumber))
    dispatch(handleChangeKeyValue(formik, 'userProfile', userProfile.uuid))
  } else {
    dispatch(handleChangeKeyValue(formik, 'examinerType', ''))
  }

  dispatch(
    handleChangeKeyValue(formik, 'dateOfBirth', eExaminerDetail.dateOfBirth),
  )
  dispatch(handleChangeKeyValue(formik, 'idCardNo', eExaminerDetail.idCardNo))
  dispatch(handleChangeKeyValue(formik, 'prefix', eExaminerDetail.prefixTH))
  dispatch(
    handleChangeKeyValue(formik, 'firstName', eExaminerDetail.firstNameTH),
  )
  dispatch(handleChangeKeyValue(formik, 'lastName', eExaminerDetail.lastNameTH))
  const mobileNo = eExaminerDetail.mobileNo
    ? eExaminerDetail.mobileNo.replace(/-/g, '')
    : ''
  dispatch(handleChangeKeyValue(formik, 'mobilePhone', mobileNo))

  const gender = eExaminerDetail.gender?.uuid
  const qualification = eExaminerDetail.qualification?.uuid
  const address = eExaminerDetail.address
  dispatch(handleChangeKeyValue(formik, 'code', selectedUserOption))
  setDefaultUser(selectedUserOption)
  dispatch(handleChangeKeyValue(formik, 'gender', gender))
  dispatch(handleChangeKeyValue(formik, 'qualification', qualification))
  dispatch(
    handleChangeKeyValue(
      formik,
      'institution',
      eExaminerDetail.institution ?? '',
    ),
  )
  dispatch(
    handleChangeKeyValue(formik, 'between', eExaminerDetail.between ?? ''),
  )
  dispatch(handleChangeKeyValue(formik, 'branch', eExaminerDetail.branch ?? ''))
  dispatch(handleChangeKeyValue(formik, 'gpa', eExaminerDetail.gpa ?? ''))
  dispatch(handleChangeKeyValue(formik, 'address', address))
  let province = eExaminerDetail.province
  let amphure = eExaminerDetail.amphure
  let tambon = eExaminerDetail.tambon
  let zipCode = eExaminerDetail.tambon?.zipCode
  dispatch(handleChangeKeyValue(formik, 'zipCode', zipCode))
  amphure = {
    ...amphure,
    provinces: province,
  }
  tambon = {
    ...tambon,
    amphure,
  }
  handleSelectedAddress('', tambon)
  let otherDocArr = []
  _.get(eExaminerDetail, 'otherDocuments', []).map((item, index) => {
    otherDocArr.push({
      ...item,
      displayName: item.fileName,
      percent: 100,
      _id: uniqId(),
      id: index,
    })
  })
  dispatch(
    setFieldUploadValue({
      key: `formData.examinationOtherDocs`,
      value: { files: otherDocArr, isUploading: false },
    }),
  )
}

export const handleCodeInput = (e) => {
  if (_.startsWith(e.target.value, '0') || e.nativeEvent.data == '.') {
    e.target.value = null
  }

  var max_chars = 6
  if (e.target.value.length > max_chars) {
    e.target.value = e.target.value.substr(0, max_chars)
  }

  return e.target.value
}

export const setInputDisabled = (props) => {
  const {
    eExaminerFormBody,
    setDisableInputList,
    disableInputList,
    profile,
    isEditForm,
    hasDcPermission,
    eExaminerDetail,
  } = props
  let idCardNo = true
  let prefix = true
  let firstName = true
  let lastName = true
  let gender = true
  let dateOfBirth = true
  let mobilePhone = true
  let qualification = true
  let branch = true,
    between = true,
    institution = true,
    gpa = true
  if (isEditForm) {
    idCardNo =
      !hasDcPermission &&
      eExaminerDetail?.idCardNo &&
      eExaminerDetail?.idCardNo?.length >= 13
    prefix = !hasDcPermission && eExaminerDetail?.prefixTH
    firstName = !hasDcPermission && eExaminerDetail?.firstNameTH
    lastName = !hasDcPermission && eExaminerDetail?.lastNameTH
    dateOfBirth = !hasDcPermission && eExaminerDetail?.dateOfBirth
    mobilePhone = !hasDcPermission && eExaminerDetail?.mobileNo
    qualification = !hasDcPermission && eExaminerDetail?.qualification
    branch = !hasDcPermission && eExaminerDetail?.branch
    between = !hasDcPermission && eExaminerDetail?.between
    institution = !hasDcPermission && eExaminerDetail?.institution
    gpa = !hasDcPermission && eExaminerDetail?.gpa
  } else if (
    ['STAFF', 'NON_ACADEMY'].includes(eExaminerFormBody.examinerType)
  ) {
    idCardNo = !(
      !profile?.user?.idCardNo || profile?.user?.idCardNo?.length < 13
    )
    prefix = !!profile?.user?.prefixTH
    firstName = !!profile?.user?.firstNameTH
    lastName = !!profile?.user?.lastNameTH
    dateOfBirth = !!profile?.user?.dateOfBirth
    mobilePhone = !(
      !profile?.user?.mobileNo || profile?.user?.mobileNo?.length != 10
    )
    qualification = !!profile?.user?.qualification
    branch = !!profile?.user?.branch
    between = !!profile?.user?.between
    institution = !!profile?.user?.institution
    gpa = !!profile?.user?.gpa
  } else {
    idCardNo = !(!profile?.idCardNo || profile?.idCardNo?.length < 13)
    prefix = !!profile?.prefixTH
    firstName = !!profile?.firstNameTH
    lastName = !!profile?.lastNameTH
    dateOfBirth = !!profile?.dateOfBirth
    mobilePhone = !(!profile?.mobileNo || profile?.mobileNo?.length != 10)
    qualification = !!profile?.qualification
    branch = !!profile?.branch
    between = !!profile?.between
    institution = !!profile?.institution
    gpa = !!profile?.gpa
  }
  setDisableInputList({
    ...disableInputList,
    prefix: prefix,
    firstName: firstName,
    lastName: lastName,
    gender: gender,
    dateOfBirth: dateOfBirth,
    qualification: qualification,
    mobilePhone: mobilePhone,
    branch: branch,
    between: between,
    institution: institution,
    gpa: gpa,
    idCardNo: idCardNo,
  })
}

export const fetchUserOptions = (props) => async (dispatch) => {
  const {
    userType,
    userCode,
    setUserProfileOption,
    setDefaultUser,
    defaultUser,
    isEditForm,
  } = props
  let userArr = []
  if (['A', 'L'].includes(userType)) {
    if (userCode.length >= 3 && userCode.length <= 6) {
      const body = { userCode: userCode, agentType: userType }
      dispatch(setLoading(true))
      const response = await dispatch(getUserAgent(body))
      dispatch(setLoading(false))
      if (!response?.error) {
        const result = _.get(response, 'payload.data', {})
        userArr = result.result.map((user) => {
          return {
            label: `${user.agentCode} - ${user.firstName} ${user.lastName}`,
            value: user.agentCode,
          }
        })
      }
    }
  } else {
    dispatch(setLoading(true))
    const response = await dispatch(getUserProfile(userType))
    dispatch(setLoading(false))
    if (!response?.error) {
      const result = _.get(response, 'payload.data', {})
      userArr = result.result
        .map((user) => {
          if (
            (['STAFF', 'NON_ACADEMY'].includes(userType) && user?.user) ||
            !['STAFF', 'NON_ACADEMY'].includes(userType)
          ) {
            const userCode = ['STAFF', 'NON_ACADEMY'].includes(userType)
              ? user?.user?.userCode
              : user.userCode
            return {
              label: `${userCode} - ${user.firstNameTH} ${user.lastNameTH}`,
              value: userCode,
            }
          }
        })
        .filter(Boolean)
    }
  }
  setUserProfileOption(_.uniqBy(userArr, 'value'))
  if (isEditForm) setDefaultUser(defaultUser)
}

export const handleNumberInput = (e) => {
  const numVal = Math.abs(e.target.value).toFixed()
  // if (_.startsWith(e.target.value, '0') || e.nativeEvent.data == '.') {
  //   e.target.value = null
  // }
  e.target.value = numVal >= 0 ? e.target.value : null // min 0
  // e.target.value = numVal <= 9999 ? e.target.value : 9999 // max 9999
  return e.target.value
}

export const checkValues = (props) => {
  const { formik, otherDocConfig } = props

  return (
    formik.values.gender &&
    formik.values.dateOfBirth &&
    formik.values.qualification &&
    formik.values.zipCode &&
    !validateValueLength(formik.values.zipCode, 5, true) &&
    formik.values.province &&
    formik.values.amphure &&
    formik.values.tambon &&
    formik.values.address &&
    !validateValueLength(formik.values.address, 255, true) &&
    formik.values.idCardImageKey &&
    !_.isEmpty(formik.values.idCardImageKey) &&
    formik.values.institution &&
    !validateValueLength(formik.values.institution, 255, true) &&
    formik.values.branch &&
    !validateValueLength(formik.values.branch, 255, true) &&
    !validateValueLength(formik.values.between, 255, true) &&
    !validateValueLength(formik.values.gpa, 255, true) &&
    formik.values.prefix &&
    formik.values.firstName &&
    !validateValueLength(formik.values.firstName, 255, true) &&
    formik.values.lastName &&
    !validateValueLength(formik.values.lastName, 255, true) &&
    formik.values.mobilePhone &&
    formik.values.mobilePhone.length === 10 &&
    formik.values.idCardNo &&
    checkIdCard(formik.values.idCardNo) &&
    (otherDocConfig?.config?.status !==
      EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE ||
      (otherDocConfig?.config?.status ===
        EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE &&
        formik.values.otherDocs.length > 0 &&
        formik.values.otherDocs.length <= 5))
  )
}

export const validateValueLength = (value, maxLength, returnBool = false) => {
  let error_text = ''
  if (String(value).length > maxLength) {
    error_text = `กรอกได้ไม่เกิน ${maxLength} ตัวอักษร`
  }
  if (returnBool) {
    return String(value).length > maxLength
  }
  return error_text
}
