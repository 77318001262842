import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  width: '100%',
  cursor: 'pointer',
}))

export const BoxHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(2),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}))

export const BoxDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: theme.spacing(2),
}))

export const LabelDetails = styled(Typography)(() => ({
  width: 300,
  flexWrap: 'wrap',
}))
