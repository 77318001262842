import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { memo, useCallback, useEffect, useState } from 'react'
import TextInput from '../../Input/TextInput'
import { useInputField } from '../hooks/useInputField'
import { createDebouncedSetField } from '../handler/createDebouncedSetField'

const CustomTextArea = memo(
  ({ required = false, disabled, fieldName, labelName, placeholder }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)
    const [inputValue, setInputValue] = useState(value)
    const { crudConfig } = useSelector((state) => state.crud, shallowEqual)

    const debouncedSetField = useCallback(
      createDebouncedSetField(dispatch, fieldName),
      [dispatch],
    )

    function handleChange(event) {
      setInputValue(event.target.value)
      debouncedSetField(event.target.value)
    }

    useEffect(() => {
      setInputValue(value)
    }, [value])

    return (
      <TextInput
        multiline={true}
        rows={3}
        required={required}
        disabled={disabled}
        name={fieldName}
        type="basic"
        labelText={labelName}
        placeholder={`${
          placeholder
            ? placeholder
            : required
            ? `กรุณากรอก${labelName}`
            : labelName
        }`}
        id={`${crudConfig.moduleSlug}-${fieldName}`}
        value={inputValue ?? ''}
        textError={message}
        isShowTextError={isError}
        onChange={handleChange}
      />
    )
  },
)
CustomTextArea.displayName = 'CustomTextArea'
export { CustomTextArea }
