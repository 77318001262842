import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { Draggable } from 'react-beautiful-dnd'

import Dot from '../../components/Table/Dot'

const RowItem = ({ orderList, headCells }) => {
  return orderList.map((item, index) => {
    return (
      <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
        {(provided) => {
          return (
            <TableRow
              sx={{ backgroundColor: '#ffffff' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              {headCells?.map((cell) => {
                if (cell.id === 'sort') {
                  return (
                    <TableCell key={`table-col-${cell.id}`}>
                      <img
                        {...provided.dragHandleProps}
                        src="/icon/ic_dragdrop.svg"
                      />
                    </TableCell>
                  )
                } else if (cell.id === 'status') {
                  return (
                    <TableCell
                      sx={{ width: cell.width }}
                      key={`table-col-${cell.id}`}
                    >
                      <Dot status={item[cell.id]} />
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell key={`table-col-${cell.id}`}>
                      <Typography sx={{ width: cell.width }} variant="body1">
                        {item[cell.id]}
                      </Typography>
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          )
        }}
      </Draggable>
    )
  })
}

export default RowItem
