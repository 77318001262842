import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { themeColor } from '../../utils/themeData'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  getToDoListNotificationFilter,
  clearNotification,
} from '../../services/toDolist'
import {
  setNotification,
  clearNotificationCount,
} from '../../redux/slices/toDoList'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ToDoListBox from '../../components/ToDoListBox'
import _ from 'lodash'

const ToDoListNotificationView = () => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isInit, setIsInit] = useState(false)

  const { notification } = useSelector((state) => state.toDoList, shallowEqual)

  useEffect(async () => {
    const list = await dispatch(getToDoListNotificationFilter())
    dispatch(setNotification(list.payload))
    setIsInit(true)
  }, [])

  useEffect(async () => {
    if (anchorEl) {
      await dispatch(clearNotification())
      dispatch(clearNotificationCount(0))
    } else {
      if (isInit) {
        dispatch(
          setNotification({
            totalCount: 0,
            result: [],
          }),
        )
      }
    }
  }, [anchorEl])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickViewAll = () => {
    window.location.href = `/to-do-list`
  }

  const open = Boolean(anchorEl)
  const id = open ? 'noti-popover' : undefined

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        aria-describedby={id}
        sx={{ position: 'relative', minWidth: 29 }}
        onClick={handleClick}
      >
        <NotificationsIcon sx={{ width: 30, height: 30 }} />
        {notification.totalCount > 0 && (
          <Box
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              width: 15,
              height: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: themeColor.other.ratingActive,
              borderRadius: 50,
            }}
          >
            <Typography variant="tooltip" sx={{ lineHeight: 0 }}>
              {notification.totalCount}
            </Typography>
          </Box>
        )}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 0, horizontal: 285 }}
      >
        <Box sx={{ minWidth: 329, px: 2, pt: 2, pb: 1 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            รายการแจ้งเตือน
          </Typography>

          {_.get(notification, `result`, []).length > 0 ? (
            _.get(notification, `result`, []).map((item, index) => {
              return <ToDoListBox key={index} list={item} size={'sm'} />
            })
          ) : (
            <Typography
              variant="h5"
              sx={{
                my: 5,
                color: '#7F7F7F',
                textAlign: 'center',
                fontSize: 24,
              }}
            >
              ไม่มีรายการ
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 1,
              cursor: 'pointer',
            }}
            onClick={() => handleClickViewAll()}
          >
            <Typography
              variant="tooltipb"
              sx={{
                fontWeight: 700,
                color: themeColor.primary.main,
              }}
            >
              ดูทั้งหมด
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
export default ToDoListNotificationView
