import { breadcrumb } from './model/breadcrumb'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { useSelector } from 'react-redux'
import { crudConfig, crudConfig as initialCrudConfig } from './crudConfig'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { CPageViewContainer } from '../../../../components/CRUD/components/PageViewContainer'
import { ActionComponent } from './components/ViewHeaderAction'
import { ViewContent } from './Content'
// import CHistoryDrawer from '../../../../components/CRUD/components/History'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { getBodyMutation } from './mutation/get'
import { ViewUpdateAt } from './components/ViewUpdatedAt'
import { TicketDetailList } from '../TicketDetailList'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import HistoryDrawer from './components/HistoryDrawer'
import { openHistoryDrawer } from './handler/openHistoryDrawer'
import { store } from '../../../../App'
import { startLoading, stopLoading } from '../../../../redux/slices/table'
import { callAPI } from '../../../../utils/lib'
import { historyEvent } from '../../../../components/CRUD/enum/historyEvent'
import DetailDrawer from '../TicketDetailList/Drawers/DetailDrawer'

export const InventoryTransferView = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const { formData, isLoading, isShowHistoryDrawer } = useSelector(
    (state) => state.crud,
  )
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(0)
  const [allCount, setAllCount] = useState(0)

  useEffect(() => {
    if (isShowHistoryDrawer) {
      fetchHistory()
    }
  }, [limit, page, isShowHistoryDrawer])

  useEffect(() => {
    initialConfig(initialCrudConfig)
    dispatch(setFieldValue({ key: 'isFetchTicketDetail', value: false }))
    if (uuid && !_.isUndefined(crudConfig))
      fetchDetail(uuid, getBodyMutation).then(() => {
        dispatch(setFieldValue({ key: 'isFetchTicketDetail', value: true }))
      })
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  const fetchHistory = async () => {
    store.dispatch(startLoading())
    const realPage = page === 0 ? 1 : page + 1
    const url = `/crud/history/${uuid}?limit=${limit}&page=${realPage}`
    const headers = { 'x-type': 'INVENTORY_TICKET' }
    const response = await callAPI({
      method: 'get',
      url,
      headers,
    })

    if (response) {
      const rows = response.result.map((row) => ({
        ...row,
        event: historyEvent[row.event] || '',
        createdBy: _.get(row, 'author', ''),
      }))
      setHistoryList(rows)
      setAllCount(_.get(response, 'totalCount', 0))
    }
    store.dispatch(stopLoading())
  }

  return (
    <>
      <CPageViewContainer
        isLoading={isLoading}
        header={
          <CHeader
            title={`รายละเอียด${crudConfig.moduleTitleFull}`}
            breadcrumbs={breadcrumb}
            action={<ActionComponent formData={formData} uuid={uuid} />}
          />
        }
        content={<ViewContent />}
      />
      <TicketDetailList ticketUuid={uuid} />
      <ViewUpdateAt />
      {/* <CHistoryDrawer /> */}
      <HistoryDrawer
        open={isShowHistoryDrawer}
        onClose={() => openHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        allCount={allCount}
      />
      <DetailDrawer />
    </>
  )
}
