import _ from 'lodash'
import {
  startLessonLoading,
  stopLessonLoading,
} from '../../../../../../redux/slices/eLearning/preview'
import { getFormDetail } from '../../../../../../services/eTesting/form'

export const handleFetchETesting = (uuid, setETesting) => async (dispatch) => {
  const latestETestingState = localStorage.getItem(uuid)
  if (!_.isNil(latestETestingState)) {
    localStorage.removeItem(uuid)
    return
  }

  dispatch(startLessonLoading())
  const response = await dispatch(getFormDetail(uuid))
  if (response.payload) setETesting(response.payload)
  dispatch(stopLessonLoading())
}
