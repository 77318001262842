import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import TextInput from '../../../../../components/Input/TextInput'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import { StyledFormCard } from '../../../Styled'
import { getStateValue, handleChangeEvent } from '../events'
import { moduleTypeOption } from './model'
import { handleSelectType } from './events'

const Info = () => {
  const dispatch = useDispatch()
  const {
    isUploadLoading,
    moduleCode,
    moduleType,
    name,
    description,
    onGoing,
  } = useSelector(
    (state) => ({
      isUploadLoading: state.eLearningModuleForm.isUploadLoading,
      moduleCode: getStateValue(state, 'moduleCode') || '',
      moduleType: getStateValue(state, 'moduleType'),
      name: getStateValue(state, 'name'),
      description: getStateValue(state, 'description'),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">รายละเอียดโมดูล</Typography>
      <Box sx={{ display: 'flex', gap: 3 }}>
        {!_.isEmpty(moduleCode) && (
          <TextInput
            disabled
            isShowTextError={false}
            name="moduleCode"
            type="basic"
            id="input-moduleCode"
            labelText="รหัสโมดูล"
            value={moduleCode}
          />
        )}
        <Autocomplete
          name="moduleType"
          labelText="รูปแบบโมดูล"
          options={moduleTypeOption}
          renderInput={(params) => (
            <TextField
              placeholder="เลือกรูปแบบโมดูล"
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'select-module-type',
              }}
            />
          )}
          textError={_.get(errors, 'moduleType', '')}
          showTextError={_.get(errors, 'moduleType', '')}
          defaultValue={
            moduleTypeOption.find((item) => item.value === moduleType) || {
              label: '',
              value: '',
            }
          }
          handleChange={(e) => dispatch(handleSelectType(e))}
          disabled={onGoing}
          isHideSort
          required
        />
      </Box>
      <TextInput
        required
        name="name"
        type="basic"
        labelText="ชื่อโมดูล"
        placeholder="ชื่อโมดูล"
        id="input-name"
        textError={_.get(errors, 'name', '')}
        isShowTextError={_.get(errors, 'name', '')}
        value={name}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        multiline
        rows={3}
        name="description"
        type="basic"
        labelText="รายละเอียดเพิ่มเติม"
        placeholder="รายละเอียดเพิ่มเติม"
        id="input-description"
        textError={_.get(errors, 'description', '')}
        isShowTextError={_.get(errors, 'description', '')}
        value={description}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default Info
