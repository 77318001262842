import React from 'react'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import {
  StyledColumnBox,
  StyledFooterBox,
  StyledRowBox,
} from '../../../HomePage/Form/Footer/Styled'

const Footer = () => {
  const { createdAt, createdBy, updatedAt, updatedBy } = useSelector(
    (state) => ({
      createdAt: state.manageContentLogin.createdAt,
      createdBy: state.manageContentLogin.createdBy,
      updatedAt: state.manageContentLogin.updatedAt,
      updatedBy: state.manageContentLogin.updatedBy,
    }),
    shallowEqual
  )

  return (
    <StyledFooterBox>
      <StyledColumnBox>
        <StyledRowBox>
          <Typography width={150}>วันที่สร้าง</Typography>
          <Typography color="text.lightGray">{createdAt}</Typography>
        </StyledRowBox>
        <StyledRowBox>
          <Typography width={150}>วันที่แก้ไขล่าสุด</Typography>
          <Typography color="text.lightGray">{updatedAt}</Typography>
        </StyledRowBox>
      </StyledColumnBox>
      <StyledColumnBox>
        <StyledRowBox>
          <Typography width={150}>สร้างโดย</Typography>
          <Typography color="text.lightGray">{createdBy}</Typography>
        </StyledRowBox>
        <StyledRowBox>
          <Typography width={150}>แก้ไขล่าสุดโดย</Typography>
          <Typography color="text.lightGray">{updatedBy}</Typography>
        </StyledRowBox>
      </StyledColumnBox>
    </StyledFooterBox>
  )
}

export default Footer
