import _ from 'lodash'

export const handleProgressChange = (lessons) => {
  const logs = []
  _.forEach(lessons, (lesson) => {
    _.forEach(lesson.modules, (module) => {
      logs.push(module.progress)
    })
  })
  const totalComplete = logs.filter((progress) => progress === 100).length
  const progressPercent = (totalComplete / logs.length) * 100
  return _.isNaN(progressPercent) ? 0 : progressPercent.toFixed()
}
