import React, { useState, createContext } from 'react'

const value = {
  dialog: {
    open: false,
    handleClose: null,
    title: '',
    content: '',
    variant: 'save',
    onConfirmClick: null,
    onCancelClick: null,
    isLoading: false,
    maxWidth: undefined,
    agreeText: undefined,
  },
}

export const DialogContext = createContext({
  context: value,
  setContext: null,
  setCloseDialog: null,
})

export const DialogContextProvider = (props) => {
  const setContext = (value) => {
    setState((prev) => ({
      ...state,
      context: {
        dialog: { ...prev.context.dialog, ...value },
      },
    }))
  }

  const setCloseDialog = () => {
    setState({
      ...state,
      context: {
        dialog: {
          ...state.context.dialog,
          open: false,
          variant: '',
          title: '',
          content: '',
        },
      },
    })
  }

  const initState = {
    context: value,
    setContext,
    setCloseDialog,
  }

  const [state, setState] = useState(initState)

  return (
    <DialogContext.Provider value={state}>
      {props.children}
    </DialogContext.Provider>
  )
}
