import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { isMediaWidthMoreThan } from './../../../utils/lib'

export const StyledHeaderContainer = styled(Box)(
  ({ theme, align = 'center' }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 122,
    marginTop: '-14px',
    paddingLeft: theme.spacing(1),
    background: theme?.palette?.other?.learnerHeader,
    wordBreak: 'break-word',
    '& > .MuiBox-root': {
      width: 896,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: align,
    },
    [theme.breakpoints.down('md')]: {
      height: 90,
      '& > .MuiBox-root': {
        width: 672, // 768 - 24 - 24 - 48(left menu)
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiBox-root': {
        width: 335, // 375 - 20 - 20
      },
    },
  })
)

export const responsiveText = (lg, md, sm) => {
  const isMediaLg = isMediaWidthMoreThan('md')
  const isMediaMd = isMediaWidthMoreThan('sm')
  if (isMediaLg) return lg
  else {
    if (isMediaMd) return md
    else return sm
  }
}
