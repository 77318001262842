import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/manageContent/elearning'
import {

  getAllDistributionChannel,
  getAllElearningCourse,
  getManageContentELearningHome,
} from '../../../../services/manageContent'



export const handleCourseData = (activeTab, value) => {
  const courses = _.get(activeTab, `[${value - 1}].courses`, [])
  return courses
    .filter((item) => item.status)
    .map((course) => ({
      ...course,
      uuid: _.get(course, 'course.uuid', ''),
      trainingPlatform: _.get(
        course.trainingPlatform,
        '[0].trainingPlatform',
        ''
      ),
    }))
}

export const fetchOptionCourse = () => (dispatch) => {
  dispatch(getAllElearningCourse())
}


export const fetchManageContent = (onEdit, setOnEdit) => async (dispatch) => {
  if (onEdit) return
  dispatch(startLoading())
  await dispatch(getAllElearningCourse())
  await dispatch(getManageContentELearningHome())
  await dispatch(getAllDistributionChannel())
  setOnEdit(true)
  dispatch(stopLoading())
}