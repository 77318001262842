import _ from 'lodash'
import { initialDocumentation, initialDocumentationTrue } from '../DefaultValue'

export const handleInitialForm = (initialValues, setDocumentation, formik) => {
  const requireDocument = _.get(initialValues, 'requireDocument', false)
  const documentList = _.get(initialValues, 'documentList', [])

  const documents = {
    requireDocument,
    documentList,
  }

  setDocumentation(documents)

  formik.setFieldValue('requireDocument', requireDocument)
  formik.setFieldValue('documentList', documentList)
}

export const handleSettingRadioChange = (e, setInitialValues) => {
  const value = _.get(e, 'target.value', 'no')
  const requireDocument = value == 'yes'
  const newDocument = requireDocument
    ? initialDocumentationTrue
    : initialDocumentation

  setInitialValues((prev) => ({
    ...prev,
    ...newDocument,
  }))
}
