import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { UploadCropper } from '../../../../../../../components/UploadCropper'
import { getStateValue, initialUploadCropper } from '../../../events'
import { handleSetCoverImage } from './events'

const UploadImage = () => {
  const dispatch = useDispatch()
  const { uploadCropper, data } = useSelector(
    (state) => ({
      uploadCropper: {
        coverImage: state.uploadCropper.coverImage,
        fileName: state.uploadCropper.fileName,
      },
      data: {
        coverImage: getStateValue('coverImage'),
        fileName: getStateValue('fileName'),
      },
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  useEffect(() => {
    dispatch(initialUploadCropper(data))
  }, [])

  useEffect(() => {
    dispatch(handleSetCoverImage(uploadCropper))
  }, [uploadCropper])

  return (
    <UploadCropper
      folder="e-learning-learning-path"
      accept={{ 'image/png': ['.png', '.jpg', '.jpeg'] }}
      isError={_.get(errors, 'coverImage', '')}
    />
  )
}

export default UploadImage
