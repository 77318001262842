import React, { useState } from 'react'

import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { handelChangVideo } from './event'
import TextInput from '../../../../../components/Input/TextInput'

const Index = ({ type, sequence, isSubQuestions, subQuestionSequence }) => {
  const dispatch = useDispatch()
  const [textVideo, setTextVideo] = useState('')

  const errors = useSelector(
    (state) => state.eEvaluationForm.errors,
    shallowEqual
  )

  return (
    <>
      <Box sx={{ mx: 2 }}>
        <Typography variant="body1">
          กรุณาวาง Embed URL ของวิดีโอ ที่นี่
        </Typography>
        <Box>
          <TextInput
            id="title-video"
            name="video"
            labelText={`\xA0`}
            type="basic"
            placeholder="https://"
            defaultValue={textVideo}
            onBlur={(e) =>
              dispatch(
                handelChangVideo({
                  value: _.get(e, 'target.value', ''),
                  sequence,
                  type,
                  isSubQuestions,
                  subQuestionSequence,
                  setTextVideo,
                })
              )
            }
            textError={_.get(errors, 'video', '')}
          />
        </Box>
      </Box>
    </>
  )
}
export default Index
