import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from './Styled'
import { handleClearFilter } from '../handler/handleClearFilter'

export const FilterBox = () => {
  const { manageClassDashboard } = useSelector(
    (state) => ({
      manageClassDashboard: state.crud.manageClassDashboard,
    }),
    shallowEqual,
  )
  const stationFilter = manageClassDashboard?.filters?.station ?? []
  const startDateFilter = manageClassDashboard?.filters?.startDate ?? ''
  const endDateFilter = manageClassDashboard?.filters?.endDate ?? ''
  const hasFilter =
    stationFilter?.length > 0 || (startDateFilter && endDateFilter)
  const isFilterStation = manageClassDashboard?.filters?.isFilterStation ?? ''
  const isFilterDate = manageClassDashboard?.filters?.isFilterDate ?? ''
  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}

          {stationFilter?.length > 0 && (
            <FilterStyledBox>
              Station:{' '}
              <strong>
                {stationFilter.map((item) => item.label).join(', ')}
              </strong>
              {isFilterStation && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterStation')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
