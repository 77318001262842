export const breadcrumbApplicationDocuments = (uuid) => [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'คลาสทั้งหมด', link: '/manage-class', pointer: true },
  {
    title: 'รายละเอียดคลาส',
    link: `/manage-class/detail/${uuid}`,
    pointer: true,
  },
  {
    title: 'ผู้เรียน',
    link: `/manage-class/learner/${uuid}`,
    pointer: true,
  },
  { title: 'เอกสารการสมัคร', link: '/', pointer: false },
]
