import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleFileChange = (value, file) => {
  const image = {
    fileName: store.getState().uploadCropper.fileName,
    fileSize: (file.size / 1000000).toFixed(2),
    key: value,
  }
  store.dispatch(setFieldValue({ key: 'formData.coverImage', value: image }))
}
