import React from 'react'
import Period from './Period'
import Configuration from './Configuration'

const SettingTab = () => {
  return (
    <>
      <Period />
      <Configuration />
    </>
  )
}
export default SettingTab
