import React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { StyledWrapper, StyledNoContentBox } from './Styled'

const NoContentBox = ({ tabTitle }) => {
  return (
    <StyledWrapper>
      <Typography variant="h4">{tabTitle}</Typography>
      <StyledNoContentBox>
        <img width={95} height={78} src="/icon/ic_sleeping.svg" />
        <Typography variant="h5">ไม่พบหลักสูตรสำหรับคุณ</Typography>
        <Button variant="contained">ดูหลักสูตรทั้งหมด</Button>
      </StyledNoContentBox>
    </StyledWrapper>
  )
}

export default NoContentBox
