import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'
import HowToRegOutlined from '@mui/icons-material/HowToRegOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import UploadFileIcon from '@mui/icons-material/UploadFile'

export const StyledFullWidth = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  width: '100%',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))

export const StyledTable = styled((props) => (
  <Table aria-labelledby="tableTitle" {...props} />
))(() => ({}))

export const StyledTableRow = styled((props) => (
  <TableRow
    {...props}
    color="primary"
    type="hover"
    tabIndex={-1}
    role="checkbox"
  />
))(({ theme }) => ({
  '&.Mui-selected:hover': { background: 'rgba(73, 118, 186, 0.12)' },
  '&.Mui-selected': {
    background: theme.palette?.primary?.outlinedHoverBackground,
  },
  '& .MuiTableCell-root': {
    padding: `0 ${theme.spacing(2)}`,
    height: 52,
    borderColor: theme?.palette?.action?.disabledBackground,
  },
}))

export const StyledCheckBoxCell = styled(TableCell)(() => ({
  padding: '0 13px !important',
  width: 24,
  '& .MuiCheckbox-root': { padding: 0 },
}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'column',
  padding: `20px 20px 24px 16px !important`,
  [theme.breakpoints.down('sm')]: {
    padding: '16px !important',
  },
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'unset',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

export const StyledToolbarButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(2),
  margin: 0,
}))

export const StyledDownloadButton = styled((props) => (
  <Button
    data-testid={'btn-download'}
    variant="text"
    startIcon={<FileDownloadOutlined />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledUploadButton = styled((props) => (
  <Button
    data-testid={'btn-upload'}
    variant="text"
    startIcon={<UploadFileIcon />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledDeletedButton = styled((props) => (
  <Button
    data-testid={'btn-delete'}
    variant="text"
    startIcon={<DeleteTwoTone />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledSendEmailButton = styled((props) => (
  <Button
    data-testid={'btn-send-email'}
    variant="text"
    startIcon={<img src="/icon/ic_megaphone_primary.svg" />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledChangeStatusButton = styled((props) => (
  <Button
    data-testid={'btn-on-change-status'}
    variant="text"
    startIcon={<HowToRegOutlined />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledEligibleExamButton = styled((props) => (
  <Button
    data-testid={'btn-on-eligible-examiner'}
    variant="text"
    startIcon={<ClearOutlinedIcon />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledFilterButton = styled((props) => (
  <Button
    data-testid={'btn-filter'}
    variant="text"
    startIcon={<FilterListOutlined />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})(({ width, minWidth }) => ({
  width: width ?? 'unset',
  minWidth: minWidth ?? 'unset',
}))

export const StyledTableSortLabel = styled(TableSortLabel)(({ headcell }) => ({
  margin: headcell.marginLabel ?? 0,
  cursor: !headcell.hideSortIcon ? 'pointer' : 'unset',
}))

export const StyledTableHeadText = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(1)}`,
  minWidth: 600,
  borderBottom: '0px',
}))

export const ImageContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '8px 0',
  justifyContent: 'center',
}))

export const StyledImage = styled('img')(() => ({
  width: 56,
  height: 56,
  objectFit: 'cover',
  border: '2px solid #DBE4F1',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033',
}))

export const StyledTableRowHeadText = styled(TableRow)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))
