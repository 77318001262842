export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterTypeOfETestingItems = [
  {
    label: 'Online Test',
    name: 'onlineTestChecked',
  },
  {
    label: 'Assessment',
    name: 'assessmentChecked',
  },
  {
    label: 'Standalone',
    name: 'standAloneChecked',
  },
]

export const filterItems = [
  {
    label: `ID`,
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    placeholder: `ค้นหา ID`,
    type: 'text',
  },
  {
    label: `ชื่อแบบทดสอบ`,
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: 'ประเภท',
    nameCheckBox: 'typeOfETestingChecked',
    nameInput: 'typeOfETesting',
    type: 'checkbox',
    list: filterTypeOfETestingItems,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
