import { COURSE_TYPE, MODULE_STATUS } from '../../../constants/eLearning'
import dayjs from 'dayjs'

export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayType = [
  {
    label: 'ทั่วไป',
    name: 'generalChecked',
  },
  {
    label: 'ระดับสูง',
    name: 'oicChecked',
  },
]

export const defaultFilter = {
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  displayDate: true,
  typeChecked: false,
  type: {
    generalChecked: false,
    oicChecked: false,
  },
  statusChecked: true,
  status: {
    activeChecked: true,
    inActiveChecked: true,
    deletedChecked: false,
  },
}

export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'ประเภทหลักสูตร',
    nameCheckBox: 'typeChecked',
    nameInput: 'type',
    type: 'checkbox',
    list: arrayType,
  },
  {
    label: 'สถานะหลักสูตร',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const DEFUALT_MODULE_STATUS = [
  MODULE_STATUS.ACTIVE,
  MODULE_STATUS.INACTIVE,
  MODULE_STATUS.DELETED,
]

export const DEFUALT_COURSE_TYPE = [COURSE_TYPE.GENERAL, COURSE_TYPE.OIC]

export const typeTabList = ['AGENCY', 'BANCA', 'KTB', 'OTHER']

export const typeTab = {
  AGENCY: {
    label: 'Agency',
    key: 'Agency',
    mainColor: '#32A287',
    secondaryColor: '#EBF6F3',
  },
  BANCA: {
    label: 'BANCA',
    key: 'Banc',
    mainColor: '#FFE66D',
    secondaryColor: '#FFF4C0',
    nameColor: '#999999',
  },
  KTB: {
    label: 'KTB',
    key: 'KTB',
    mainColor: '#4976BA',
    secondaryColor: '#BED0ED',
  },
  OTHER: {
    label: 'Other',
    key: 'Other',
    mainColor: '#B49CE4',
    secondaryColor: '#E9DEFF',
  },
}

export const TypeChartTab = {
  course: 'course',
  enroll: 'enroll',
}

export const moduleArray = [
  {
    name: 'E_TESTING',
    label: 'E-Testing / แบบทดสอบ',
    background: '#DDFDE1',
    dotColor: '#7EE29C',
    unit: 'Module',
  },
  {
    name: 'E_EVALUATION',
    label: 'E-Evaluation / แบบประเมิน',
    background: '#FFF4D9',
    dotColor: '#FFDC89',
    unit: 'Module',
  },
  {
    name: 'EMBED_CONTENT',
    label: 'Embed Content / ฝังเนื้อหา',
    background: '#D4F0FC',
    dotColor: '#93DFFF',
    unit: 'Module',
  },
  {
    name: 'LINK_TO_ANOTHER_WEBSITE',
    label: 'Link to another website / ลิงก์ไปยังเว็บไซต์อื่น',
    background: '#FFDDDD',
    dotColor: '#C91432',
    unit: 'Module',
  },
  {
    name: 'UPLOAD_FILE',
    label: 'Upload file / อัปโหลดไฟล์',
    background: '#D4E5FF',
    dotColor: '#99C2FF',
    unit: 'Module',
  },
  {
    name: 'SCORM',
    label: 'Scorm file',
    background: '#FFDACA',
    dotColor: '#F07662',
    unit: 'Module',
  },
]
