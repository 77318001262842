import { CPageContainer } from '../../../../components/CRUD/components/PageContainer'
import { Content } from './Content'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  fetchDetail,
  fetchMasterDataDropdown,
} from '../../../../components/CRUD/handler/fetchDetail'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { getBodyMutation } from './mutation/get'
import Header from './Header'
import AssetList from './components/AssetList'
import { setInitalValues } from './handler/setInitialValues'
import {
  fetchAssetData,
  handleFetchEditAssetData,
} from './handler/fetchAssetData'
import { fetchEditAsset } from './handler/fetchEditAsset'
import { handleTypeChange } from './handler/handleTypeChange'
import { getSkuNo } from './utils'
import { getQueryParams } from '../../../../utils/lib'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { assetTypeEnum } from './enum/assetTypEnum'

export const InventoryTransferForm = () => {
  const { uuid } = useParams()
  const { crudConfig, assetData, assetEdit, type } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      assetData: state.crud.assetData,
      assetEdit: state.crud.assetEdit,
      type: state.crud.formData.type,
    }),
    shallowEqual,
  )
  const [isInitial, setIsInital] = useState(true)
  const { table, search } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { searchText } = search

  const initializeCrud = async () => {
    initialConfig(initialCrudConfig)
    setInitalValues()
    await fetchMasterDataDropdown().then()
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation).then(() => {
        handleFetchEditAssetData()
        getSkuNo()
      })
    } else {
      const defaultProductType = getQueryParams('productType')
      if (defaultProductType) {
        store.dispatch(
          setFieldValue({
            key: 'formData.type',
            value: assetTypeEnum?.[defaultProductType] ?? '',
          }),
        )
      }
    }
  }

  useEffect(initializeCrud, [crudConfig])
  useEffect(() => {
    if (!uuid) {
      handleTypeChange()
    }
  }, [type])

  useEffect(() => {
    fetchAssetData({
      isInitial,
      search: searchText,
      table,
      page: 1,
    })
    setIsInital(false)
  }, [assetData])

  useEffect(() => {
    if (!isInitial) {
      fetchAssetData({
        isInitial,
        search: searchText,
        table,
        page,
      })
    }
  }, [limit, order, page, sort])

  useEffect(() => {
    if (!isInitial && assetEdit) {
      fetchEditAsset({ assetEdit })
    }
  }, [assetEdit])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageContainer
        gap="3"
        header={<Header uuid={uuid} crudConfig={crudConfig} />}
        content={<Content />}
      />
      <AssetList />
    </>
  )
}
