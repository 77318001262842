import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import { setPreviousQuestion } from './../../../../../../redux/slices/eTestingPreview'
import { checkDisplayOneAndNow } from './../../../../../../redux/slices/eTestingPreview/events'
import BackToMainBtn from '../BackToMainBtn'
import SubmitBtn from '../SubmitBtn'
import { responsiveButton } from '../Styled'
import { handleNextQuestion } from './events'

const ButtonForOne = ({ isModule }) => {
  const buttonSize = responsiveButton()
  const dispatch = useDispatch()
  const { testing, setting, page, isLoading, isButtonLoading, isReview } =
    useSelector(
      (state) => ({
        testing: state.eTestingPreview.testing,
        setting: state.eTestingPreview.setting,
        page: state.eTestingPreview.page,
        isLoading: state.eTestingPreview.isLoading,
        isButtonLoading: state.eTestingPreview.isButtonLoading,
        isReview: state.eTestingPreview.isReview,
      }),
      shallowEqual
    )
  const isNow = checkDisplayOneAndNow(setting)
  const isShowBack = !isNow || isReview
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      {page > 1 && isShowBack && (
        <Button
          variant="contained"
          size={buttonSize}
          data-testid="btn-back"
          onClick={() => dispatch(setPreviousQuestion())}
          startIcon={<ArrowBack />}
          disabled={isLoading}
        >
          กลับ
        </Button>
      )}
      {page < testing.length && (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          onClick={() => dispatch(handleNextQuestion({ isNow, isReview }))}
          data-testid="btn-next"
          endIcon={<ArrowForward />}
          loading={isButtonLoading}
          loadingPosition="end"
          disabled={isLoading}
        >
          ถัดไป
        </LoadingButton>
      )}
      {page == testing.length && !isReview && <SubmitBtn testing={testing} />}
      {isReview && <BackToMainBtn isModule={isModule} />}
    </Box>
  )
}

export default ButtonForOne
