import React from 'react'
import ReactPlayer from 'react-player'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Videocam from '@mui/icons-material/Videocam'
import { CancelVideo, BoxVideo } from './Styled'
import { handelDeleteVideo } from './event'

const Index = ({
  type,
  sequence,
  isSubQuestions,
  subQuestionSequence,
  url,
  disabled,
}) => {
  const dispatch = useDispatch()
  return (
    <BoxVideo>
      {!disabled && (
        <CancelVideo
          data-testid="cancel-upload"
          onClick={() =>
            dispatch(
              handelDeleteVideo({
                type,
                sequence,
                isSubQuestions,
                subQuestionSequence,
                value: '',
                key: 'url',
              })
            )
          }
        >
          <Close sx={{ fontSize: '16px' }} />
        </CancelVideo>
      )}
      <ReactPlayer
        volume={false}
        playing={false}
        light
        Controls
        width="150px"
        height="100px"
        style={{
          pointerEvents: 'none',
        }}
        url={url}
      />

      <Box sx={{ position: 'relative', top: '-24px' }}>
        <Videocam style={{ color: '#FFFFFF' }} />
      </Box>
    </BoxVideo>
  )
}

export default Index
