import {
  loadingDialog,
  openDialog,
  closeDialog,
  stopLoadingDialog,
} from '../../../../../redux/slices/dialog'

import { announceCourseBatchLearner } from '../../../../../services/eLearning/course/form'

export const handleAnnounceLearner =
  (courseBatchUuid, courseBatchLearnerUuid) => (dispatch) => {
    const body = {
      courseBatchUuid: courseBatchUuid,
      courseLearner: [courseBatchLearnerUuid],
    }
    dispatch(
      openDialog({
        title: 'ประกาศผู้มีสิทธิ์เข้าเรียน',
        message:
          'ระบบจะอัปเดตสถานะพร้อมส่งอีเมลยืนยันแจ้งกับผู้เรียนที่มีสิทธิ์ทราบต้องการดำเนินการเลยหรือไม่',
        handleConfirm: async () => {
          dispatch(loadingDialog())
          await dispatch(announceCourseBatchLearner(body))
          dispatch(stopLoadingDialog())
          dispatch(closeDialog())
          window.location.reload()
        },
      }),
    )
  }
