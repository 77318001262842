import { enumRequestType } from '../modules/RoomManagement/View/Content/CalendarCard/constanst'

export const ROOMMANAGEMENT_SETTING = 'ROOMMANAGEMENT_SETTING'

export const monthList = [
  { label: 'มกราคม', value: 1 },
  { label: 'กุมภาพันธ์', value: 2 },
  { label: 'มีนาคม', value: 3 },
  { label: 'เมษายน', value: 4 },
  { label: 'พฤษภาคม', value: 5 },
  { label: 'มิถุนายน', value: 6 },
  { label: 'กรกฎาคม', value: 7 },
  { label: 'สิงหาคม', value: 8 },
  { label: 'กันยายน', value: 9 },
  { label: 'ตุลาคม', value: 10 },
  { label: 'พฤศจิกายน', value: 11 },
  { label: 'ธันวาคม', value: 12 },
]

export const dayList = [
  { day: 'จันทร์' },
  { day: 'อังคาร' },
  { day: 'พุธ' },
  { day: 'พฤหัสฯ' },
  { day: 'ศุกร์' },
  { day: 'เสาร์' },
  { day: 'อาทิตย์' },
]

export const BOOKING_PERIOD_TYPE = {
  SAME_TIME: 'SAME_TIME', // เหมือนกันทุกวัน
  CUSTOM: 'CUSTOM', // กำหนดเอง
}

export const PERIOD_TYPE = {
  MORNING: 'MORNING', // ครึ่งเช้า
  AFTERNOON: 'AFTERNOON', // ครึ่งบ่าย
  MORNING_TO_EVENING: 'MORNING_TO_EVENING', // เช้า-เย็น
  FULL_DAY: 'FULL_DAY', // เต็มวัน
  OTHER: 'OTHER', // อื่น ๆ
}

export const OVERTIME = {
  AT_TRAINING_TIME: 'AT_TRAINING_TIME',
  HALF_HOUR: 'HALF_HOUR',
  ONE_HOUR: 'ONE_HOUR',
  ONE_HALF_HOURS: 'ONE_HALF_HOURS',
  TWO_HOURS: 'TWO_HOURS',
}

export const ROOM_AVAILABLE = {
  ALL_AVAILABLE: 'ALL_AVAILABLE', // ว่าง
  NOT_AVAILABLE: 'NOT_AVAILABLE', // ไม่ว่าง
  SOME_TIME_AVAILABLE: 'SOME_TIME_AVAILABLE', // ว่างบางช่วงเวลา
}

export const ROOM_LAYOUT = {
  CLASSROOM: 'Classroom',
  GROUP: 'Group',
  THEATER: 'Theater',
  USHAPE: 'U Shape',
}

export const ACADEMY_TYPE = {
  ACADEMY: 'Academy',
  NON_ACADEMY: 'Non Academy',
}

export const REQUEST_TYPE = {
  E_BOOKING: 'E-Booking',
  MONTHLY_PLAN: 'Monthly Plan',
  MANAGE_CLASS: 'Manage Class',
}

export const STATUS_TYPE = {
  CANCEL: 'CANCEL',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  CONFIRM: 'CONFIRM',
}

export const academyOptions = [
  { label: 'Academy', value: 'ACADEMY' },
  { label: 'Non Academy', value: 'NON_ACADEMY' },
]

export const bookingPeriodType = [
  {
    label: 'เหมือนกันทุกวัน',
    value: BOOKING_PERIOD_TYPE.SAME_TIME,
  },
  {
    label: 'กำหนดเอง',
    value: BOOKING_PERIOD_TYPE.CUSTOM,
  },
]

export const bookingPeriodTypeMonthlyPlan = [
  {
    label: 'กำหนดช่วงเวลาอบรม',
    value: BOOKING_PERIOD_TYPE.CUSTOM,
  },
  {
    label: 'อบรมเหมือนกันทุกวัน',
    value: BOOKING_PERIOD_TYPE.SAME_TIME,
  },
]

export const periodOptions = [
  {
    label: 'ทั้งวัน',
    value: PERIOD_TYPE.FULL_DAY,
    startTimeTraining: '00:00',
    endTimeTraining: '23:59',
  },
  {
    label: 'ครึ่งเช้า',
    value: PERIOD_TYPE.MORNING,
    startTimeTraining: '08:30',
    endTimeTraining: '12:00',
  },
  {
    label: 'ครึ่งบ่าย',
    value: PERIOD_TYPE.AFTERNOON,
    startTimeTraining: '13:00',
    endTimeTraining: '17:00',
  },
  {
    label: 'เช้า - บ่าย',
    value: PERIOD_TYPE.MORNING_TO_EVENING,
    startTimeTraining: '08:30',
    endTimeTraining: '17:00',
  },
  { label: 'อื่นๆ', value: PERIOD_TYPE.OTHER },
]

export const overTimeOptions = [
  {
    label: 'ภายในเวลาอบรม',
    value: OVERTIME.AT_TRAINING_TIME,
    durationInMinute: 0,
  },
  { label: '30 นาที', value: OVERTIME.HALF_HOUR, durationInMinute: 30 },
  { label: '1 ชั่วโมง', value: OVERTIME.ONE_HOUR, durationInMinute: 60 },
  {
    label: '1 ชั่วโมง 30 นาที',
    value: OVERTIME.ONE_HALF_HOURS,
    durationInMinute: 90,
  },
  { label: '2 ชั่วโมง', value: OVERTIME.TWO_HOURS, durationInMinute: 120 },
]

export const roomAvailableList = [
  {
    label: 'ว่าง',
    status: ROOM_AVAILABLE.ALL_AVAILABLE,
    bgColor: 'rgba(85, 211, 122, 0.30)',
    textColor: '#04872A',
  },
  {
    label: 'ไม่ว่าง',
    status: ROOM_AVAILABLE.NOT_AVAILABLE,
    bgColor: 'rgba(211, 85, 85, 0.30)',
    textColor: '#D93025',
  },
  {
    label: 'ว่างบางช่วงเวลา',
    status: ROOM_AVAILABLE.SOME_TIME_AVAILABLE,
    bgColor: 'rgba(112, 48, 160, 0.1)',
    textColor: 'monthlyPlan.birthdayLeaveBorder',
  },
]

export const roomLayoutList = [
  {
    keyField: 'capacityClassroom',
    value: 'CLASSROOM',
    label: 'Classroom',
  },
  {
    keyField: 'capacityGroup',
    value: 'GROUP',
    label: 'Group',
  },
  {
    keyField: 'capacityUShape',
    value: 'USHAPE',
    label: 'U Shape',
  },
  {
    keyField: 'capacityTheater',
    value: 'THEATER',
    label: 'Theater',
  },
]

export const roomRequestTypeList = [
  {
    value: 'ALL',
    label: 'ALL',
  },
  {
    value: enumRequestType.MONTHLY_PLAN,
    label: 'MONTHLY PLAN',
  },
  {
    value: enumRequestType.E_BOOKING,
    label: 'E-BOOKING',
  },
]

export const statusList = [
  {
    label: 'Cancel',
    status: STATUS_TYPE.CANCEL,
    labelColor: 'white.main',
    bgColor: 'error.main',
  },
  {
    label: 'Completed',
    status: STATUS_TYPE.COMPLETED,
    labelColor: 'success.main',
  },
  {
    label: 'In Progress',
    status: STATUS_TYPE.IN_PROGRESS,
    labelColor: 'warning.main',
  },
  {
    label: 'Confirm',
    status: STATUS_TYPE.CONFIRM,
    labelColor: 'none',
  },
]

export const RoomTimeText = {
  ALL_DAY: 'ทั้งวัน',
  AVAILABLE_UNTIL: 'ว่างจนถึง',
  AVAILABLE_WHEN: 'จะว่างตอน',
}
