import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Add from '@mui/icons-material/Add'
import EditorForDragDrop from '../../../../../../components/EditorForDragDrop'
import { alphabets } from '../../../../../../constants/eTesting'
import Score from '../../Score'
import Question from '../../Question'
import { getErrorMessage } from '../../event'
import * as StyledForm from '../../Styled'
import AddBlank from '../AddBlank'
import { handleAddAnswer, handleEditorChange } from '../events'
import BlankInput from './BlankInput'
import { StyledDialogBox } from '../Styled'
import { StyledHead } from './Styled'

export const blankComp = (blankNums) =>
  `<span class="blank" contenteditable="false"><em>${alphabets[blankNums]}</em></span>`

const FillInBlankDrag = ({ keyQuestion, indexQuestion }) => {
  const dispatch = useDispatch()
  const [blankNums, setBlankNums] = useState(0)

  const { question, errorSchemaMessage, disableForm, isLoading } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      isLoading: state.eTestingForm.isLoading,
    }),
    shallowEqual
  )
  const { questionType, subTitle, answers } = question
  const editorId = `fill-in-blank-drag-${keyQuestion}`
  const subTitleErrorMessage = getErrorMessage({
    field: 'SUB_TITLE',
    errorSchemaMessage,
    indexQuestion,
  })

  useEffect(() => {
    const realAnswer = answers.filter((ans) => ans.target)
    setBlankNums(realAnswer.length)
  }, [keyQuestion])

  return (
    <Card>
      <StyledForm.BoxForm>
        <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />

        {!isLoading && (
          <Box sx={{ pt: 2 }}>
            <StyledDialogBox>
              <EditorForDragDrop
                id={editorId}
                value={subTitle}
                disabled={disableForm}
                onChange={(newValue) => {
                  const props = { answers, subTitle, setBlankNums, keyQuestion }
                  dispatch(handleEditorChange(newValue, props))
                }}
                textError={subTitleErrorMessage}
              />
              <Button
                variant="outlined"
                data-testid="btn-add-blank"
                startIcon={<Add />}
                size="l"
                sx={{ minWidth: 133 }}
                disabled={disableForm}
                onClick={() =>
                  window?.tinymce
                    ?.get(editorId)
                    .execCommand(
                      'mceInsertContent',
                      false,
                      blankComp(blankNums)
                    )
                }
              >
                แทรกช่องว่าง
              </Button>
            </StyledDialogBox>
            {answers.length > 0 && (
              <>
                <StyledHead>
                  <Typography variant="body1b">
                    คำตอบ{' '}
                    <Typography variant="span" color="error.main">
                      *
                    </Typography>
                  </Typography>
                </StyledHead>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {answers.map((data, index) => (
                    <BlankInput
                      key={index}
                      data={data}
                      index={index}
                      keyQuestion={keyQuestion}
                      indexQuestion={indexQuestion}
                    />
                  ))}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <StyledHead sx={{ mt: 2 }}>
                  <Typography variant="body1b">ตัวเลือกหลอก</Typography>
                </StyledHead>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {answers.map((data, index) => (
                    <BlankInput
                      key={index}
                      data={data}
                      index={index}
                      keyQuestion={keyQuestion}
                      indexQuestion={indexQuestion}
                      isTrick
                    />
                  ))}
                </Box>
                <AddBlank
                  keyQuestion={keyQuestion}
                  onClick={() =>
                    !disableForm &&
                    dispatch(handleAddAnswer(keyQuestion, answers))
                  }
                  isTrick
                />
              </>
            )}
          </Box>
        )}
      </StyledForm.BoxForm>

      <Score keyQuestion={keyQuestion} questionType={questionType} />
    </Card>
  )
}
export default FillInBlankDrag
