import dayjs from 'dayjs'
import _ from 'lodash'
import {
  getManageClassByUuid,
  getManageClassHolidayByUuid,
} from '../../../../../services/manageClass/form'
import { convertFormatDateTime } from '../../../../../utils/lib'

export const fetchManageClassData = (isOpen, isHoliday, uuid) => (dispatch) => {
  if (isOpen && isHoliday) dispatch(getManageClassHolidayByUuid(uuid))
  if (isOpen && !isHoliday) dispatch(getManageClassByUuid(uuid))
}

export const handleDateTimeFormat = (value) => {
  if (value == '' || _.isNil(value)) return false
  return (
    dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_SHOW) +
    ' ' +
    dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_TIME)
  )
}

export const handleCreateUpdate = (manageClassData) => {
  const createdAt = _.get(manageClassData, 'createdAt', null)
  const updatedAt = _.get(manageClassData, 'updatedAt', null)
  const createdDate = convertFormatDateTime({ value: createdAt, type: 'date' })
  const updatedDate = convertFormatDateTime({ value: updatedAt, type: 'date' })
  return {
    createdAt: createdAt ? createdDate : '-',
    updatedAt: updatedAt ? updatedDate : '-',
  }
}

export const filterDataHoliday = (manageClassData) => {
  const startDate = _.get(manageClassData, 'startDate', null)
  const finishDate = _.get(manageClassData, 'finishDate', null)

  const createdAt = _.get(manageClassData, 'createdAt', null)
  const updatedAt = _.get(manageClassData, 'updatedAt', null)

  return {
    title: _.get(manageClassData, 'title', '-'),
    data:
      convertFormatDateTime({ value: startDate, type: 'date' }) +
      ' - ' +
      convertFormatDateTime({ value: finishDate, type: 'date' }),
    subWorkType: _.get(manageClassData, 'subWorkType.subWorkType', '-'),
    remark: _.get(manageClassData, 'remark', ''),
    createdAt: convertFormatDateTime({ value: createdAt, type: 'date' }),
    updatedAt: convertFormatDateTime({ value: updatedAt, type: 'date' }),
    createdBy: _.get(manageClassData, 'createdBy', '-'),
    updatedBy: _.get(manageClassData, 'updatedBy', '-'),
  }
}

export const handleCreateRoomManagementText = (meetingRoomManagement) => {
  let meetingRoomArr = []
  for (const room of meetingRoomManagement) {
    if (
      !_.isEmpty(room.meetingRoom) &&
      _.get(room, 'meetingRoom.value') !== 'OTHERS'
    ) {
      const roomName =
        _.get(room, 'meetingRoom.roomName', '') +
        ' (ชั้น' +
        _.get(room, 'meetingRoom.floor', '') +
        ')'
      meetingRoomArr.push(roomName)
    } else {
      const roomNameOther = _.get(room, 'meetingRoomOther', '')
      if (!_.isEmpty(roomNameOther)) {
        meetingRoomArr.push(roomNameOther)
      }
    }
  }
  meetingRoomArr = _.uniq(meetingRoomArr)
  return meetingRoomArr.join(', ')
}
