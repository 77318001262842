import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Template from '../../../../../components/Template'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import { Row, StyledTypography } from '../Styled'
import {
  StyledHeaderImage,
  StyledTextBtn,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledTooltip,
} from './Styled'

const Header = () => {
  const data = useSelector(
    (state) => state.eLearningPathPreview.data,
    shallowEqual,
  )
  const coverImage = _.get(data, 'coverImage', '')
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isNotSm) {
    return <TabletDesktopHeader data={data} coverImage={coverImage} />
  }

  return (
    <StyledHeaderWrapper>
      <Row sx={{ height: 43.5, position: 'relative', justifyContent: 'center' }}>
        <StyledTextBtn>กลับ</StyledTextBtn>
        <Typography variant="body1b">เส้นทางการเรียนรู้</Typography>
      </Row>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
        />
      )}
      <StyledTypography variant="h6">
        {_.get(data, 'nameForBanner', '')}
      </StyledTypography>
    </StyledHeaderWrapper>
  )
}

export default Header

export const TabletDesktopHeader = ({ data, coverImage }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const name = _.get(data, 'nameForBanner', '')
  return (
    <StyledHeaderWrapper>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
        />
      )}
      <Template noMinHeight>
        <StyledTextBtn>กลับ</StyledTextBtn>

        <StyledHeaderContainer>
          <Box>
            <Typography variant="h6" color="text.alabaster">
              เส้นทางการเรียนรู้
            </Typography>
            <StyledTooltip title={name}>
              <StyledTypography variant={isLg ? 'h2' : 'h4'} color="text.white">
                {name}
              </StyledTypography>
            </StyledTooltip>
          </Box>
        </StyledHeaderContainer>
      </Template>
    </StyledHeaderWrapper>
  )
}
