import { store } from '../../../../App'
import { uploadFileBudgetManagement } from '../../../../services/budgetManagement'
import _ from 'lodash'
import { loadingDialog, openDialog } from '../../../../redux/slices/dialog'
import { setFieldValue } from '../../../../redux/slices/crud'
import { CheckDialog } from '../components/ImportTemplateDialog/CheckDialog'
import { handleClearFileSelected } from './handleClearFileSelected'

export const handleSubmitUpload = async () => {
  const { importFile } = store.getState().crud.formData
  const file = _.get(importFile, 'file')
  store.dispatch(loadingDialog())
  const body = new FormData()
  body.append('file', file)

  const response = await store.dispatch(uploadFileBudgetManagement(body))
  const payload = _.get(response, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    const results = _.get(response, 'payload.data.results', {})
    const importFile = {
      file: null,
      fileName: 'No File Chosen',
      uploadResult: results,
    }
    store.dispatch(
      setFieldValue({ key: 'formData.importFile', value: importFile }),
    )
    store.dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'xl',
        disableButton: true,
        content: <CheckDialog />,
      }),
    )
  } else {
    handleClearFileSelected()
  }
}
