import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import RankingCard from '../ModuleCard/RankingCard'
import { shallowEqual, useSelector } from 'react-redux'
import { handleClickTopRank } from '../../handler/handleClickTopRanking'

const AboutLicenseRanking = () => {
  const { LATEST_LICENSES, TOP_LICENSES } = useSelector(
    (state) => ({
      LATEST_LICENSES:
        state.crud?.apiRcmsDashboard?.overview?.LATEST_LICENSES,
      TOP_LICENSES:
        state.crud?.apiRcmsDashboard?.overview?.TOP_LICENSES,
    }),
    shallowEqual,
  )

  return (
    <Box>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">เกี่ยวกับ License</Typography>
          </Box>

          <Box display="grid" gap={2} mt={3} gridTemplateColumns="1fr 1fr">
            <Box>
              <RankingCard
                title="Top 5 License ที่ส่งล่าสุด"
                type="latestLicense"
                list={LATEST_LICENSES}
                header1="หลักสูตร"
                header2=""
                handleClickButton={() => handleClickTopRank('latestLicense')}
              />
            </Box>
            <Box>
              <RankingCard
                title="Top 5 License ที่ส่งข้อมูลมากที่สุด"
                type="topLicenese"
                list={TOP_LICENSES}
                header1="หลักสูตร"
                header2="จำนวน (Transactions)"
                handleClickButton={() => handleClickTopRank('topLicense')}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AboutLicenseRanking
