import _ from 'lodash'

export const getModuleTitle = (uuid, title) => {
  return `${_.isUndefined(uuid) ? 'สร้าง' : 'แก้ไข'} ${title}`
}

export const formattedNumber = (value) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}
