import ErrorOutline from '@mui/icons-material/ErrorOutline'
import _ from 'lodash'
import React from 'react'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassCheckExam'
import { postSetExamNotPass } from '../../../../../services/manageClass/check'
import { loadingDialog, openDialog } from '../../../../../redux/slices/dialog'
import { downloadFile } from '../../../../../services/util'
import { store } from '../../../../../App'
import { manageClassCheckExam } from '../../../../../utils/apiPath'
import SetFailDialog from '../SetFailDialog'

export const handleOnClickMenu =
  (option, setOpen, idProps) => async (dispatch) => {
    if (option == 'history') {
      setOpen(true)
    } else if (option === 'download') {
      dispatch(onDownload(idProps))
    } else {
      const { id, courseId } = idProps
      const body = {
        trainingPlanUuid: id,
        courseTestAndEvaUuid: courseId,
        isCheck: true,
      }

      const result = await dispatch(postSetExamNotPass(body))
      const data = _.get(result.payload, 'data', undefined)
      if (data) {
        const waitingToCheckExam = _.get(data, 'waitingToCheckExam', 0)
        dispatch(
          openDialog({
            type: 'content',
            title: 'ปรับสถานะผู้เรียนที่เหลือทั้งหมดเป็นไม่ผ่าน',
            icon: <ErrorOutline color="error" />,
            content: <SetFailDialog notChecked={waitingToCheckExam} />,
            disableButton: true,
            handleConfirm: () => dispatch(handleConfirmNotPass(body)),
          }),
        )
      }
    }
  }

export const handleConfirmNotPass = (body) => async (dispatch) => {
  dispatch(loadingDialog())
  body.isCheck = false
  const response = await dispatch(postSetExamNotPass(body))
  if (_.isNil(response.error)) window.location.reload()
}

export const onDownload = ({ id, courseId }) => async (dispatch) => {
  const { learners } = store.getState().manageClassCheckExam
  const list = learners.learnerAll.map((learner) => learner.learnerUuid)
  const body = { order: 'DESC', sort: 'updatedAt', type: 'ALL', list }

  dispatch(startLoading())
  await dispatch(
    downloadFile({
      url: `${manageClassCheckExam}/${id}/${courseId}/download`,
      body: body,
      fileName: `รายการตรวจข้อสอบ.xlsx`,
    }),
  )
  dispatch(stopLoading())
}
