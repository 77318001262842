import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { StyledViewTitle } from '../../../../ETesting/Version/View/Styled'
import { StyledContainer, StyledContent } from '../../../Styled'
import { breadcrumbVersionViewDashboard } from '../../../model'
import { fetchEEvaluation } from './events'
import LeftInfo from './components/LeftInfo'
import { initialize } from './handler/initilize'
import { toggleFiltersDrawer } from './handler/handleClickOpenDrawer'
import { Button, Typography } from '@mui/material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FilterDrawer from './Drawers/FilterDrawer'
import CourseEva from './components/CourseEva'
import TrainerEva from './components/TrainerEva'
import CourseManagementEva from './components/CourseManagementEva'
import OtherEva from './components/OtherEva'
import EvaluationDrawer from './Drawers/EvaluationDrawer'
import LoadingPageCircular from '../../../../../components/CRUD/components/LoadingPageCircular'
import { StyledDownloadButton } from '../../../../../components/redux/Table/Styled'
import { useReactToPrint } from 'react-to-print'
import './pdf.css'
import { getQueryParams } from 'src/utils/lib'
import { clearSelectDropdown } from './Drawers/FilterDrawer/event'
import { arrayCheckbox } from './Drawers/FilterDrawer/model'

const View = ({ isManageClass = false }) => {
  const { id: uuid } = useParams()
  const { filterTotal, result, isLoading, isLoadingDashboard, overview } =
    useSelector(
      (state) => ({
        filterTotal:
          state.crud.eEvaluationDashboard?.filtersDrawer?.filterTotal,
        result: state.eEvaluationDetail?.result,
        isLoading: state.eEvaluationDetail?.isLoading,
        isLoadingDashboard: state.crud?.isLoading,
        overview: state.crud.eEvaluationDashboard?.overview,
      }),
      shallowEqual,
    )
  const dispatch = useDispatch()
  const combinedRefs = useRef()

  const [isPrinting, setIsPrinting] = useState(false)
  const handlePrint = () => {
    setIsPrinting(true)
    setTimeout(printPdf, 1000)
  }

  const printPdf = useReactToPrint({
    content: () => combinedRefs.current,
    documentTitle: `E-Evaluation Dashboard`,
    // onBeforePrint: () => {
    //   setIsPrinting(true)
    // },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
    // removeAfterPrint: false,
  })

  const trainingPlanUuid = getQueryParams('trainingPlanUuid')
  const startDate = getQueryParams('startDate')
  const finishDate = getQueryParams('finishDate')
  useEffect(() => {
    clearSelectDropdown({ arrayCheckbox: arrayCheckbox })
    dispatch(fetchEEvaluation(uuid))
    initialize(uuid, isManageClass, startDate, finishDate, trainingPlanUuid)
  }, [])

  const breadcrumbVersionViewDashboardList = breadcrumbVersionViewDashboard(
    _.get(result, 'codeId', 0),
    isManageClass,
    trainingPlanUuid,
  )

  const checkPart1 = _.defaultTo(overview?.courseEva?.drawerData, []).length
  const checkPart2 = _.defaultTo(overview?.checkTrainerEvaQuestion, true)
  const checkPart3 = _.defaultTo(
    overview?.courseManageEva?.drawerData,
    [],
  ).length
  const checkPart4 = _.defaultTo(overview?.part4Eva, []).length

  return (
    <Box>
      <StyledContainer ref={combinedRefs}>
        <StyledContent isLoading={isLoading || isLoadingDashboard}>
          <Box
            sx={{
              paddingTop: isPrinting ? '20px' : 'inherit',
            }}
          >
            <StyledViewTitle variant={isPrinting ? 'h5' : 'h4'}>
              รายละเอียดแบบประเมิน : {_.get(result, 'information.name', '')}
            </StyledViewTitle>
          </Box>
          <Box className={`${isPrinting ? 'hide-pdf' : ''}`}>
            <Breadcrumbs menuList={breadcrumbVersionViewDashboardList} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            mt={isPrinting ? 1 : 3}
          >
            <LeftInfo uuid={uuid} isPrinting={isPrinting} />
            <Box className={`${isPrinting ? 'hide-pdf' : ''}`}>
              <StyledDownloadButton
                data-testid="btn-download"
                disabled={false}
                onClick={() => {
                  handlePrint()
                }}
                sx={{ width: '150px' }}
              >
                ดาวน์โหลด
              </StyledDownloadButton>
              {!isManageClass && (
                <Button
                  data-testid={'btn-filter'}
                  sx={{ height: 40, py: 1, px: 2 }}
                  variant="text"
                  startIcon={<FilterListOutlinedIcon />}
                  onClick={() => toggleFiltersDrawer(true)}
                >
                  <Typography variant="text">
                    ตัวกรอง {filterTotal ? `(${filterTotal})` : ''}
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box mt={isPrinting ? 1 : 3}>
            {checkPart1 > 0 && (
              <>
                <CourseEva isPrinting={isPrinting} />
                <div className="pagebreak"> </div>
              </>
            )}
            {checkPart2 && (
              <>
                <TrainerEva isPrinting={isPrinting} />
                <div className="pagebreak"> </div>
              </>
            )}
            {checkPart3 > 0 && (
              <>
                <CourseManagementEva isPrinting={isPrinting} />
                <div className="pagebreak"> </div>
              </>
            )}
            {checkPart4 > 0 && (
              <>
                <OtherEva isPrinting={isPrinting} />
              </>
            )}
          </Box>
        </StyledContent>
        {!isManageClass && <FilterDrawer eEvaluationVersionUuid={uuid} />}
        <EvaluationDrawer />
      </StyledContainer>
      <LoadingPageCircular
        isLoading={isLoading || isLoadingDashboard || isPrinting}
      />
    </Box>
  )
}

export default View
