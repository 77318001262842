import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Dot from '../../../../../../../components/redux/Dot'
import { ContentRow } from '../../../../../components/View'
import { StyledCardContent } from '../../../../../components/View/Styled'
import { versionStatus } from '../../../../../../../constants/eTesting'
import { themeColor } from '../../../../../../../utils/themeData'

const Point = () => {
  const detail = useSelector((state) => state.eLearningCourseDetail.result)
  const color = {
    ACTIVE: themeColor?.success?.main,
    INACTIVE: themeColor?.error?.main,
    DELETED: themeColor?.text?.white,
    DRAFT: themeColor?.text?.silver,
  }
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        คะแนนหลักสูตร
      </Typography>
      <ContentRow title="สถานะการเปิดใช้งานคะแนน">
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Dot
            color={color[detail.pointStatus] || themeColor?.error?.main}
            status={detail.pointStatus}
            sx={{ mt: 1.5 }}
          />
          {versionStatus[detail.pointStatus]}
        </Box>
      </ContentRow>
      <ContentRow
        title="จำนวนคะแนน"
        content={detail?.point || detail?.learningPoint?.point || 0}
      />
      <ContentRow
        title="กำหนดรูปแบบคะแนน"
        content={detail.pointType == 'LINK' ? 'ผูกคะแนน' : 'กำหนดเอง'}
      />
    </StyledCardContent>
  )
}

export default Point
