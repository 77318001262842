export const headCellsClick = [
  { id: 'date', label: 'วันที่สุ่ม' },
  { id: 'time', label: 'เวลาที่สุ่ม' },
  { id: 'result', label: 'ผ่าน' },
  { id: 'ip', label: 'IP' },
  { id: 'browser', label: 'Device / Browser' },
]

export const headCellsAttend = [
  { id: 'date', label: 'วันที่เรียน' },
  { id: 'duration', label: 'เวลาที่เรียน' },
  { id: 'ip', label: 'IP' },
  { id: 'browser', label: 'Device / Browser' },
]

export const headCellsModule = [
  { id: 'moduleAndProgress', label: 'ชื่อโมดูล' },
  { id: 'moduleType', label: 'รูปแบบโมดูล' },
  { id: 'result', label: 'ผล' },
  // {
  //   id: 'action',
  //   label: 'คำสั่ง',
  //   width: '90px',
  //   hideSortIcon: true,
  //   hideEdit: true,
  //   hideDelete: true,
  //   viewPath: `/view`,
  // },
]

export const intitialTable = {
  isLoading: false,
  isShowPagination: true,
  rows: [],
  headCells: [],
  allCount: 0,
  page: -1,
  tablePage: 0,
  limit: '5',
  rowsPerPage: { label: '5', value: 5 },
}

export const initialState = {
  error: null,
  isLoading: false,
  result: null,
  tab: 'attendance',
  expandedRound: '',
  learningRound: [],
  enrollData: null,
  learnerRekognition: null,
  tables: {
    courseLogLearnerPerLessons: {
      ...intitialTable,
      headCells: headCellsModule,
    },
    courseLogLearners: { ...intitialTable, headCells: headCellsAttend },
    randomClickPerLessons: { ...intitialTable, headCells: headCellsClick },
  },
}
