import axios from 'axios'
import localforage from 'localforage'
import { eEvaluationDownloadTemplate } from './../../../../utils/apiPath'

export const handleDownloadTemplate = () => {
  const fetchProps = {
    url: eEvaluationDownloadTemplate,
    method: 'get',
    responseType: 'blob',
  }
  axios(fetchProps)
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Template_E_Evaluation.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => console.log(err))
}

export const onClickAdd = (option, history) => {
  localforage.removeItem('persist:root')
  history.push(option.link)
}
