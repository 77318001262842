import _ from 'lodash'
import { store } from '../../../App'
import {
  loadingDialog,
  openDialog,
  openErrorDialog,
} from '../../../redux/slices/dialog'
import {
  setLevelDistribution,
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageContent/elearning'
import {
  getAllDistributionChannel,
  getAllElearningCollection,
  getAllElearningCourse,
  getAllLearningPath,
  getManageContentELearningHome,
  putManageContentELearningHome,
} from '../../../services/manageContent'
import ErrorCourseDialog from './ErrorCourseDialog'

export const getContentState = (keyField) => {
  const state = store.getState().manageContentELearningHome
  return _.get(state, keyField, '')
}

export const fetchManageContent = (onEdit, setOnEdit) => async (dispatch) => {
  if (onEdit) return

  dispatch(startLoading())
  dispatch(getAllDistributionChannel())
  dispatch(getAllElearningCourse())
  dispatch(getAllLearningPath())
  dispatch(getAllElearningCollection())
  await dispatch(getManageContentELearningHome())

  const courseKey = 'coursesInformation'
  dispatch(handleSetDistribution(courseKey))

  const pathKey = 'learningPathInformation'
  dispatch(handleSetDistribution(pathKey))

  const collectionKey = 'collectionInformation'
  dispatch(handleSetDistribution(collectionKey))

  setOnEdit(true)
  dispatch(stopLoading())
}

export const handleSetDistribution = (key) => (dispatch) => {
  const data = getContentState(`data.${key}`)
  dispatch(setLevelDistribution({ key, value: _.get(data, '[0].uuid', '') }))
}

export const handleSubmitForm = (value) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกและเผยแพร่',
      message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirm(value)),
    }),
  )
}

export const handleConfirm = (value) => async (dispatch) => {
  dispatch(loadingDialog())
  const errorList = []
  const response = await dispatch(putManageContentELearningHome(value))
  if (!_.isNil(response.error)) errorList.push('error')
  if (errorList.length == 0) dispatch(handleSuccess())
  else {
    dispatch(
      openErrorDialog({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        message: '',
        content: <ErrorCourseDialog errorList={errorList} />,
      }),
    )
  }
}

export const handleSuccess = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      handleConfirm: () => window.location.reload(),
    }),
  )
}

export const handleError = (error) => (dispatch) => {
  if (error) {
    dispatch(
      openErrorDialog({
        title: 'ผิดพลาด',
        message:
          'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ หรือไม่ตรงตามเงื่อนไขที่กำหนด',
      }),
    )
  }
}
