import _ from 'lodash'
import { openDialog, loadingDialog } from '../../../../redux/slices/dialog'
import { setDrawer } from '../../../../redux/slices/manageContent/login'
import { putManageContentLogin } from '../../../../services/manageContent'

export const handleSubmitData = (props) => async (dispatch) => {
  dispatch(
    openDialog({
      type: 'confirm',
      title: 'ยืนยันบันทึกและเผยแพร่',
      message: 'ยืนยันบันทึกและเผยแพร่',
      handleConfirm: () => {
        dispatch(loadingDialog())
        dispatch(handleConfrim(props))
      },
    })
  )
}
export const checkColorIndrawer = (list) => {
  return list.map((item) => {
    return {
      ...item,
      colorTitle: _.isNull(item.colorTitle) ? '#FFFFFF' : item.colorTitle,
      colorDescription: _.isNull(item.colorDescription)
        ? '#FFFFFF'
        : item.colorDescription,
    }
  })
}

export const handleConfrim = (props) => async (dispatch) => {
  let result = {}
  const body = { ...props, loginPages: checkColorIndrawer(props.loginPages) }
  dispatch(setDrawer({ isClose: true }))
  result = await dispatch(putManageContentLogin(body))

  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'บันทึกและเผยแพร่เรียบร้อยแล้ว',
        handleConfirm: () => {
          dispatch(loadingDialog())
          dispatch(setDrawer({ isClose: true }))
          window.location.href = '/manage-content/login'
        },
      })
    )
  }
}
