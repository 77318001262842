import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import { requisitionType } from '../model/requisitionType'
import useTheme from '@mui/system/useTheme'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleChangeHeadCells } from '../handler/handleChangeHeadCells'
import { validatePermission } from '../../../../../utils/lib'
import { tabTypeEnum } from '../enum/tabTypeEnum'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import { NotificationDot } from './Styled'

export const TabsSelectType = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { tabTypeActive } = useSelector(
    (state) => ({
      tabTypeActive: state.crud.tabTypeActive,
      countRequisitionMonthly: state.crud.countRequisitionMonthly,
      countRequisitionOther: state.crud.countRequisitionOther,
      requisitionCount: state.crud.requisitionCount,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setFieldValue({ key: 'tabTypeActive', value: newValue }))
    dispatch(setFieldValue({ key: 'tabActive', value: 0 }))
    handleChangeHeadCells(0, newValue)
  }

  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionMonthly = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.MP],
  })
  const hasPermissionInventory = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [
      STOCK_PERMISSION.ADMIN,
      STOCK_PERMISSION.MP,
      STOCK_PERMISSION.CREATE,
    ],
  })

  return (
    <Box
      display="flex"
      borderBottom={`1px solid ${theme.palette?.blue?.blueLine}`}
    >
      <Tabs value={tabTypeActive} onChange={handleChangeTab}>
        {_.map(requisitionType, (tab, idx) => {
          let hidden = false
          if (
            !hasPermissionMonthly &&
            [tabTypeEnum.MONTHLY].includes(tab.value)
          )
            hidden = true
          return (
            <Tab
              sx={{
                minWidth: 0,
                width: 'auto',
                textTransform: 'none',
                color: theme.palette?.primary?.main,
                px: 2,
                '&.Mui-selected': {
                  borderBottom: `${theme.spacing(0.5)} solid`,
                  pt: `calc(${theme.spacing(2)} - 1px)`,
                  fontWeight: 700,
                },
                display: hidden ? 'none' : '',
              }}
              key={idx}
              label={
                <>
                  {tab.label}
                  {hasPermissionInventory && <NotificationDot />}
                </>
              }
            />
          )
        })}
      </Tabs>
    </Box>
  )
}
