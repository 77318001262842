import React from 'react'
import styled from '@mui/system/styled'
import { Typography } from '@mui/material'
import {
  RequisitionStatusColor,
} from '../../../../../constants/stock'

export const StyledStatusText = styled((props) => <Typography {...props} />)(
  ({ requisitionStatus }) => ({
    color: requisitionStatus ? RequisitionStatusColor?.[requisitionStatus] : '',
  }),
)
