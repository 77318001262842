import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../../components/FilterDrawer/Styled'
import { arrayALLCheckbox, arrayPICCheckbox, defaultFilter } from './model'
import * as events from './event'
import _ from 'lodash'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const ListCheckBox = ({
  listArray,
  stateFilter,
  setFilter,
  station,
}) => {
  return listArray.map((item, index) => (
    <StyledGroupBox key={index}>
      <FormGroup>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              inputProps={{
                'data-testid': `check-${item.nameCheckBox}`,
              }}
              name={item.nameCheckBox}
              checked={stateFilter[item.nameCheckBox]}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter: stateFilter,
                  setFilter,
                })
              }}
            />
          }
          sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
        />

        {stateFilter[item.nameCheckBox] && (
          <>
            {item.type == 'checkbox' && (
              <StyledList sx={{ mx: 2 }}>
                <ListResult
                  filter={stateFilter}
                  setFilter={setFilter}
                  arrayResult={station}
                  listKeys={item.nameInput}
                />
              </StyledList>
            )}
            {item.type === 'daterange' && (
              <DateRangeInput
                placeholder={item.placeholder}
                dateState={{
                  startDate: stateFilter.startDate || new Date(),
                  endDate: stateFilter.endDate || new Date(),
                  display: stateFilter.displayDate,
                  key: 'selection',
                }}
                onChange={(selectedDates) => {
                  events.handleSelectDate(selectedDates, stateFilter, setFilter)
                }}
              />
            )}
          </>
        )}
      </FormGroup>
    </StyledGroupBox>
  ))
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={
              filter[listKeys].find((ele) => ele === item.value) !== undefined
            }
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                uuid: item.value,
                name: item.label,
                key: e.target.name,
                listKey: listKeys,
                subListKey: 'stationName',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = () => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'DASHBOARD_REPORT' &&
      item.module === 'REPORT_ROOM_MANAGEMENT'
    )
  })
  const {
    isClearFilter,
    open,
    stationList,
    defaultStation,
    defaultStationName,
  } = useSelector(
    (state) => ({
      isClearFilter:
        state.crud?.roomManagementDashboard?.filtersDrawer?.isClearFilter,
      open: state.crud.roomManagementDashboard?.filtersDrawer?.isOpen,
      stationList: state.crud.roomManagementDashboard?.stationList,
      defaultStation: state.crud.roomManagementDashboard?.defaultStation,
      defaultStationName:
        state.crud.roomManagementDashboard?.defaultStationName,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter({
      ...defaultFilter,
      station: defaultStation,
      stationName: defaultStationName,
    })
  }, [open])

  useEffect(() => {
    if (isClearFilter) {
      events.clearFilter(isClearFilter, filter, setFilter, setFilterMemo)
      dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.filtersDrawer.isClearFilter',
          value: '',
        }),
      )
    }
  }, [isClearFilter])

  return (
    <StyledDrawer open={open} onClose={() => toggleFiltersDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleFiltersDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckBox
        listArray={hasPermissionALL ? arrayALLCheckbox : arrayPICCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
        station={stationList}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={async () => {
            await events.handleChange({
              value: false,
              key: 'dateChecked',
              filter,
              setFilter,
              isCheck: true,
            })
            await events.handleChange({
              value: false,
              key: 'stationChecked',
              filter,
              setFilter,
              isCheck: true,
            })
            setFilter({
              ...defaultFilter,
              station: defaultStation,
              stationName: defaultStationName,
            })
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(filter))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
