import { MODULE_STATUS } from '../../../../constants/learningPoint'

export const initialValue = {
  id: '',
  name: '',
  point: '',
  course: [
    { uuid: { value: '', label: '' }, courseType: { value: '', label: '' } },
  ],
  badgeName: '',
  badgeDescription: '',
  badgeRemark: '',
  badgeImage: {},
  badgeFileName: '',
  badgeStatus: MODULE_STATUS.INACTIVE,
  remark: '',
  isDueDate: false,
  startDate: '',
  endDate: '',
  status: MODULE_STATUS.ACTIVE,
  isActive: true,
}

export const initialState = {
  body: initialValue,
  view: initialValue,
  isLoading: false,
  courseOptions: { ELEARNING: [], COURSE: [] },
}
