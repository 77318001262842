import React from 'react'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'

import { BoxAddChoice } from '../Styled'
import { handleAddAnswer } from './event'

const Index = ({ keyQuestion, label }) => {
  const dispatch = useDispatch()

  const { question, disableForm } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )
  const { answers, questionType } = question
  const textColor = disableForm ? 'text.gray' : 'primary'

  return (
    <>
      <BoxAddChoice
        lastMediaUrl={
          _.get(answers, `[${answers.length - 1}].loadingProgress`, 0)
            ? true
            : false
        }
        data-testid="add-answer-single"
        onClick={() =>
          !disableForm &&
          dispatch(handleAddAnswer(keyQuestion, answers, questionType))
        }
      >
        <Add color={textColor} />
        <Typography variant="buttons" color={textColor}>
          {label || 'เพิ่มตัวเลือก'}
        </Typography>
      </BoxAddChoice>
    </>
  )
}
export default Index
