import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data) => {
  return {
    id: _.get(data, 'id', ''),
    productType: _.get(data, 'productType', 'Stock'),
    productName: _.get(data, 'productName', ''),
    category: _.get(data, 'category', ''),
    subCategory: _.get(data, 'subCategory', ''),
    quantityAlert: _.get(data, 'quantityAlert', ''),
    description: _.get(data, 'description', ''),
    image: _.get(data, 'image', []),
    productImage: _.get(data, 'productImage', ''),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    isSku: _.get(data, 'isSku', enumStatus.INACTIVE),
    costCenter: _.get(data, 'costCenter', ''),
    statusFlagReturn: _.get(data, 'statusFlagReturn', enumStatus.INACTIVE),
    hasPeriodFlagReturn: _.get(data, 'hasPeriodFlagReturn', false),
    refundYear: _.get(data, 'refundYear', null),
    refundMonth: _.get(data, 'refundMonth', null),
    refundDay: _.get(data, 'refundDay', null),
    remark: _.get(data, 'remark', ''),
    uuid: _.get(data, 'uuid', undefined),
    createdAt: _.get(data, 'createdAt', null),
    createdBy: _.get(data, 'createdBy', null),
    updatedAt: _.get(data, 'updatedAt', null),
    updatedBy: _.get(data, 'updatedBy', null),
    vendor: _.get(data, 'vendor', '-'),
    unitPrice: _.get(data, 'unitPrice', ''),
    stockValue: _.get(data, 'stockValue', ''),
    allStockValue: _.get(data, 'allStockValue', ''),
    averageUnitPrice: _.get(data, 'averageUnitPrice', ''),
  }
}
