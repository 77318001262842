import { Box } from '@mui/system'
import { Typography } from '@mui/material'

export const FreeTextAnswer = ({ data }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      {data?.testResult.map((testResult, i) => {
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
              key={i}
            >
              <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
                A{i + 1}.
              </Typography>
              <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
                {testResult?.freetextAnswer}
              </Typography>
            </Box>
          </>
        )
      })}
    </Box>
  )
}
