import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.spacing(2),
}))

export const ResponseContainer = styled(Box)(({ theme, isHide }) => ({
  display: isHide ? 'none' : 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
}))

export const StyledDivider = styled(Divider)(() => ({
  width: 'calc(100% - 58px)',
}))
