import * as yup from 'yup'

import _ from 'lodash'
import { validateVideo } from '../../../../ELearning/Module/Form/TypeContent/EmbedContent/events'
import { store } from '../../../../../App'
import dayjs from 'dayjs'

export const schemaSubmit = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อ')
    .max(255, 'ชื่อกรอกได้ไม่เกิน 255 ตัวอักษร'),
  category: yup
    .object()
    .nullable(false)
    .typeError('กรุณากรอกหมวดหมู่')
    .required('กรุณากรอกหมวดหมู่'),
  subCategory: yup
    .object()
    .nullable(false)
    .typeError('กรุณากรอกหมวดหมู่ย่อย')
    .required('กรุณากรอกหมวดหมู่ย่อย'),
  coverImage: yup.object({
    key: yup.string().required('กรุณาอัพโหลดรูปภาพหน้าปก'),
    fileName: yup.string(),
    fileSize: yup.string(),
  }),
  contents: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Type is required.'),
      content: yup
        .object()
        .when('type', {
          is: 'KB',
          then: yup.object().shape({
            question: yup.string().required('กรุณากรอกคำถาม'),
            answer: yup.string().required('กรุณากรอกคำตอบ'),
            files: yup.array(),
          }),
        })
        .when('type', {
          is: 'LINK',
          then: yup.object().shape({
            link: yup
              .string()
              .test(
                'validate-link',
                'Link สำหรับวิดีโอตัวอย่างต้องมากจาก YouTube หรือ Vimeo เท่านั้น',
                (value) => _.isEmpty(value) || validateVideo(value),
              )
              .max(
                3000,
                'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 3000 ตัวอักษร',
              )
              .required('กรุณากรอก URL'),
            detail: yup.string().when('isHasDetail', {
              is: 'ACTIVE',
              then: yup
                .string()
                .required('กรุณากรอกคำอธิบาย ภายใน 255 ตัวอักษร')
                .max(255, 'คำอธิบาย กรอกได้ไม่เกิน 255 ตัวอักษร'),
              otherwise: yup.string(),
            }),
          }),
        })

        .when('type', {
          is: 'IMAGE',
          then: yup.object().shape({
            files: yup.array().min(1, 'กรุณาอัปโหลดไฟล์'),
          }),
        })
        .when('type', {
          is: 'FILE',
          then: yup.object().shape({
            files: yup.array().min(1, 'กรุณาอัปโหลดไฟล์'),
          }),
        })
        .when('type', {
          is: 'TEXT',
          then: yup.object().shape({
            text: yup.string().required('กรุณากรอกข้อความ'),
          }),
        }),
    }),
  ),
  distributionCh: yup
    .array()
    .nullable(false)
    .typeError('กรุณาเลือกช่องทางการจำหน่าย')
    .min(1, 'กรุณาเลือกช่องทางการจำหน่าย '),
  levelOfLearner: yup
    .array()
    .nullable(false)
    .min(1, 'กรุณาเลือกระดับของผู้เรียน')
    .typeError('กรุณาเลือกระดับของผู้เรียน'),
  eContentTag: yup
    .array()
    .nullable(false)
    .min(1, 'กรุณาเลือก Tag')
    .typeError('กรุณาเลือก Tag'),
  startPublishDate: yup
    .date()
    .typeError('โปรดกรอกวันที่ให้ถูกต้อง')
    .required('กรุณากรอกวันเริ่มต้น')
    .test(
      'test pastdate',
      'วันเริ่มต้นเผยแพร่ ต้องไม่เป็นวันในอดีต',
      function (date) {
        if (
          Date.parse(
            dayjs(store.getState().crud.formData.oldStartPublishDate)
              .utc(true)
              .toDate(),
          ) === Date.parse(dayjs(date).utc(true).toDate())
        )
          return true
        return Date.parse(date) >= new Date().setHours(0, 0, 0, 0)
      },
    ),
  endPublishDate: yup.date().when('isEndPublishDate', {
    is: 'ACTIVE',
    then: yup
      .date()
      .typeError('โปรดกรอกวันที่ให้ถูกต้อง')
      .required('กรุณากรอกวันสิ้นสุด')
      .test(
        'isEndBeforeStartPublishDate',
        'วันสิ้นสุดต้องเท่ากับหรือหลังวันเริ่มต้น',
        function (endDate) {
          return this.parent.startPublishDate <= endDate
        },
      ),
    otherwise: yup.date().nullable(),
  }),

  remark: yup.string().max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
})
