import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ratingColor } from '../../../../../../../../constants/eEvaluation'
import { setAnsweredRating } from '../../../../../../../../redux/slices/eEvaluationPreview'
import { handleSelector } from './events'
import { StyledRatingData, StyledTitleData } from './Styled'

const QuestionRow = ({ keyProps, keyQuestion, isTitle }) => {
  const payload = { ...keyProps, keyQuestion }
  const { choices, title, answer } = useSelector(
    (state) => handleSelector(state, payload),
    shallowEqual
  )
  const dispatch = useDispatch()

  if (isTitle) {
    return (
      <StyledTitleData>
        <Typography>{title}</Typography>
      </StyledTitleData>
    )
  } else {
    return (
      <RatingRadio
        answer={answer}
        choices={choices}
        title={title}
        onChange={(e) =>
          dispatch(setAnsweredRating({ ...payload, value: e.target.value }))
        }
      />
    )
  }
}

export default QuestionRow

export const RatingRadio = ({ answer, choices, onChange, title }) => {
  return (
    <RadioGroup
      style={{ width: '100%', flexDirection: 'row', flexWrap: 'nowrap' }}
      onChange={onChange}
    >
      {choices.map((item) => (
        <StyledRatingData
          key={item.sequence}
          color={ratingColor[item.sequence] || '#ff88b3'}
          boxHeight={title.length}
        >
          <FormControlLabel
            control={<Radio />}
            value={item.sequence}
            checked={item.sequence == answer}
            sx={{ margin: 0 }}
            data-testid={`choice-${item.sequence}`}
          />
        </StyledRatingData>
      ))}
    </RadioGroup>
  )
}
