import { Box, Radio, Typography } from '@mui/material'
import { StyledCourseSelection } from './Styled'
import { StyledColumn, StyledRow } from '../../Styled'
import useTheme from '@mui/system/useTheme'
import {
  CollectionsBookmarkOutlined,
  GroupOutlined,
} from '@mui/icons-material/'
import { StyledContainedChip } from '../ListCourseSection/Styled'
import { COURSE_FOR } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { handleSetSelectedCourseFor } from './event'

const CourseSelectionRadioGroup = ({
  label,
  isShowCourseFor = false,
  courseForLabel,
  color = '',
  bgColor = '',
  courseFor = COURSE_FOR.ALL,
  count = 0,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { selectedCourseFor } = useSelector(
    (state) => ({
      selectedCourseFor: state.reportCourseSettingDashboard.selectedCourseFor,
    }),
    shallowEqual,
  )

  return (
    <StyledCourseSelection isSelected={selectedCourseFor === courseFor}>
      <Box
        sx={{
          minWidth: 125,
          minHeight: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          pt: 1,
          pl: 1,
          backgroundImage: `url('/image/report-card-bg.svg')`,
          backgroundRepeat: 'no-repeat',
          zIndex: 0,
        }}
      />
      <StyledRow
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          '& .MuiSvgIcon-root': {
            color: theme?.palette?.text?.white,
            width: 40,
            height: 40,
            zIndex: 999,
          },
        }}
      >
        <StyledColumn>
          <Typography variant="body2b">{label}</Typography>
          {isShowCourseFor && (
            <StyledContainedChip
              sx={{
                color: color,
                backgroundColor: bgColor,
                fontWeight: 700,
                p: 0.5,
                fontSize: 18,
                '& .MuiChip-label': { px: 0 },
              }}
              label={courseForLabel}
            />
          )}
        </StyledColumn>
        {isShowCourseFor ? <GroupOutlined /> : <CollectionsBookmarkOutlined />}
      </StyledRow>
      <StyledRow sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography variant="h4">{count}</Typography>
        <Radio
          sx={{
            color: theme?.palette?.text?.white,
            width: 20,
            height: 20,
            pr: 2,
            '&.Mui-checked': {
              color: theme?.palette?.text?.white,
            },
          }}
          checked={selectedCourseFor === courseFor}
          value={courseFor}
          onChange={(e) => dispatch(handleSetSelectedCourseFor(e))}
        />
      </StyledRow>
    </StyledCourseSelection>
  )
}

export default CourseSelectionRadioGroup
