import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { StyledHeaderContainer } from '../../Styled'
import { onClickCreateModule } from './events'

const Header = () => {
  const history = useHistory()
  return (
    <StyledHeaderContainer>
      <Button
        data-testid="btn-create"
        variant="contained"
        size="m"
        startIcon={<Add />}
        onClick={() => onClickCreateModule(history)}
      >
        สร้าง Learning Path
      </Button>
    </StyledHeaderContainer>
  )
}
export default Header
