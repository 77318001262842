import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'
import { StyledDragHolder } from '../Styled'

const DragDropContent = ({ children, id, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              width: '100%',
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragDropContent

export const DragHolder = ({ provided, mt, disabled }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: mt || 0,
      }}
      {...provided.dragHandleProps}
    >
      <StyledDragHolder disabled={disabled} />
    </Box>
  )
}
