import {
  setChangeQuestionType,
  setChangeQuestionByKey,
} from '../../../../../redux/slices/eTestingForm'

export const handleChangeQuestion = (props) => (dispatch) => {
  dispatch(setChangeQuestionByKey(props))
}

export const handleChangeTypeQuestion = (props) => (dispatch) => {
  dispatch(setChangeQuestionType(props))
}

export const handelDeleteImage = (props) => async (dispatch) => {
  dispatch(setChangeQuestionByKey(props))
}
