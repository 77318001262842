import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import { StyledDrawer, StyledHeadLabel } from './Styled'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PreviewContent } from './PreviewContent'
import { FormBooking } from './FormBooking'
import { closeDrawer } from '../../../redux/slices/roomManagement/Drawer'
import { ProgressLoading } from './components/ProgressLoading'

const BookingDrawer = () => {
  const dispatch = useDispatch()

  const { isShowPreview, isOpenDrawer, isShowForm } = useSelector(
    (state) => ({
      isShowPreview: state.roomManagementDrawer.isShowPreview,
      isOpenDrawer: state.roomManagementDrawer.isOpenDrawer,
      isShowForm: state.roomManagementDrawer.isShowForm,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      PaperProps={{
        id: 'BookingDrawer',
      }}
      open={isOpenDrawer}
      isShowPreview={isShowPreview}
      onClose={() => {
        dispatch(closeDrawer())
      }}
    >
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ข้อมูลการจอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            component="span"
            onClick={() => {
              dispatch(closeDrawer())
            }}
          >
            <Close color="action.active" />
          </IconButton>
        </Box>
        <ProgressLoading />
        <Divider />
      </StyledHeadLabel>
      {isShowPreview && <PreviewContent />}
      {isShowForm && <FormBooking />}
    </StyledDrawer>
  )
}

export default BookingDrawer
