import { store } from '../../../../App'
import { toggleAgencyDrawer } from './handleClickOpenDrawer'

export const handleClickButton = (ele) => {
  const { manageProfileDashboard } = store.getState().crud
  const start_date = manageProfileDashboard?.filtersDrawer?.startDate
  const end_date = manageProfileDashboard?.filtersDrawer?.endDate
  const params = `start_date=${start_date}&end_date=${end_date}`
  if (ele.type === 'Agency' && ele.value === 'ALL') {
    toggleAgencyDrawer(true)
  } else {
    const url = `/manage/report-manage-profile`
    if (['Agency'].includes(ele.type)) {
      let additional_params = ''
      if (ele.value === 'MANAGER') {
        additional_params = '&agent_role=manager'
      } else if (ele.value === 'AGENT') {
        additional_params = '&agent_role=agent'
      } else if (ele.value === 'TERMINATE') {
        additional_params = '&agent_role=agent,manager&status=inactive'
      }
      window.open(
        `${url}/agent?${params}&distribution=agency,cs${additional_params}`,
        '_blank',
      )
    } else if (['Banc'].includes(ele.type)) {
      let additional_params = ''
      if (ele.value === 'ALL') {
        additional_params = '&level=FP,LB,BSO,BSM'
      } else if (ele.value === 'KTB_FP') {
        additional_params = '&level=FP'
      } else if (ele.value === 'KTB_LB') {
        additional_params = '&level=LB'
      } else if (ele.value === 'BSO') {
        additional_params = '&level=BSO,BSM'
      } else if (ele.value === 'TERMINATE') {
        additional_params = '&level=FP,LB,BSO,BSM&status=inactive'
      }
      window.open(`${url}/agent?${params}${additional_params}`, '_blank')
    } else if (['Prospect'].includes(ele.type)) {
      let additional_params = ''
      if (ele.value === 'ACTIVE') {
        additional_params = '&status=active'
      } else if (ele.value === 'INACTIVE') {
        additional_params = '&status=inactive'
      } else if (ele.value === 'IS_REF') {
        additional_params = '&status_prospect=true'
      }
      window.open(`${url}/prospect?${params}${additional_params}`, '_blank')
    }
  }
}
