import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'

export const handleUploadFile = async (files) => {
  if (files?.files?.length === 0){
    store.dispatch(
      setFieldValue({
        key: 'formData.productImage',
        value: "",
      }),
    )
    return
  }
  const { key, displayName, fileType, fileSize } = files.files[0]
  store.dispatch(
    setFieldValue({
      key: 'formData.productImage',
      value: {
        key: key,
        fileName: displayName,
        fileType: fileType,
        fileSize: fileSize,
      },
    }),
  )
}
