import { store } from '../../App'
import {
  setReduxValue,
  startLoading,
  stopLoading,
} from '../../redux/slices/reportCourseSetting/Dashboard'
import { getReportCourseSettingDashboard } from '../../services/reportCourseSetting'
import _ from 'lodash'
import {
  COURSE_FOR,
  SHOW_COURSE_TYPE,
} from '../../constants/reportCourseSetting/reportCourseSetting'
import dayjs from 'dayjs'

export const fetchReportCourseSettingDashboard = () => async (dispatch) => {
  dispatch(startLoading())
  const { selectedCourseFor, courseForList, courseShowList, dateRange } =
    store.getState().reportCourseSettingDashboard
  let bodyDateRange = dateRange
  if (_.isEmpty(dateRange)) {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    bodyDateRange = {
      startDate: dayjs(firstDayOfMonth).format('YYYY-MM-DD'),
      endDate: dayjs(today).format('YYYY-MM-DD'),
    }
  }
  const { payload: response } = await dispatch(
    getReportCourseSettingDashboard({
      courseFor: selectedCourseFor,
      dateRange: bodyDateRange,
    }),
  )
  const countCourseAll = _.get(response, 'countAll', 0)
  const countCourseForAgency = _.get(response, 'countCourseForAgency', 0)
  const countCourseForBA = _.get(response, 'countCourseForBA', 0)
  const countCourseForKTB = _.get(response, 'countCourseForKTB', 0)
  const countCourseForOther = _.get(response, 'countCourseForOther', 0)
  const coursesOpenEnrollList = _.get(response, 'coursesOpenEnrollList', [])
  const courseSortedByUpdatedList = _.get(
    response,
    'courseSortedByUpdatedList',
    [],
  )
  const coursesOpenTrainingList = _.get(response, 'coursesOpenTrainingList', [])
  await dispatch(
    setReduxValue({ key: 'countCourseAll', value: countCourseAll }),
  )
  const newCourseForList = _.map(courseForList, (course) => {
    let count
    const courseFor = _.get(course, 'courseFor', '')
    switch (courseFor) {
      case COURSE_FOR.AGENCY:
        count = countCourseForAgency
        break
      case COURSE_FOR.BA:
        count = countCourseForBA
        break
      case COURSE_FOR.KTB:
        count = countCourseForKTB
        break
      case COURSE_FOR.OTHER:
        count = countCourseForOther
        break
    }
    return { ...course, count: count }
  })
  dispatch(setReduxValue({ key: 'courseForList', value: newCourseForList }))
  const newCourseShowList = _.map(courseShowList, (item) => {
    let courseList
    const showCourseType = _.get(item, 'showCourseType', '')
    switch (showCourseType) {
      case SHOW_COURSE_TYPE.COURSE_ENROLL:
        courseList = coursesOpenEnrollList
        break
      case SHOW_COURSE_TYPE.SORTED_BY_UPDATED:
        courseList = courseSortedByUpdatedList
        break
      case SHOW_COURSE_TYPE.COURSE_OPENED:
        courseList = coursesOpenTrainingList
        break
    }
    return { ...item, courseList: courseList }
  })
  dispatch(setReduxValue({ key: 'courseShowList', value: newCourseShowList }))

  dispatch(stopLoading())
}
