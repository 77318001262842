import _ from 'lodash'
import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
  TYPE_OF_VALUE,
} from '../../../constants/report/monthlyPlan'
import { fetchDepartmentList } from './fetchDepartmentList'

export const getDefaultFiltersFromQueryString = async (urlParams) => {
  const departmentOptions = await fetchDepartmentList()
  const queryStringFilters = []
  let i = 1
  urlParams.forEach((value, key) => {
    let itemFilter = {}

    if (
      _.includes(['startDate', 'endDate'], key) &&
      !_.find(
        queryStringFilters,
        (filter) => filter.key === COLUMN_NAME.TRAINING_DATE,
      )
    ) {
      const startDate = urlParams.get('startDate')
      const endDate = urlParams.get('endDate')

      itemFilter = {
        id: i,
        key: COLUMN_NAME.TRAINING_DATE,
        title: COLUMN_NAME_TH.TRAINING_DATE,
        value: {
          startDate: startDate,
          endDate: endDate,
        },
        typeOfValue: TYPE_OF_VALUE.DATE_RANGE,
        label: urlParams.get(`dateLabel`),
      }
      queryStringFilters.push(itemFilter)
      i++
    } else if (
      !_.includes(['startDate', 'endDate'], key) &&
      !_.includes(key, 'Label') &&
      value
    ) {
      const parsedValue = JSON.parse(value)
      const keyColumn = Object.keys(COLUMN_NAME).find(
        (k) => COLUMN_NAME[k] === key,
      )

      let label = urlParams.get(`${key}Label`)

      if (key === COLUMN_NAME.DEPARTMENT) {
        label = _.filter(departmentOptions, (item) =>
          _.includes(parsedValue, item.value),
        )
          .map((item) => item.label)
          .join(', ')
      }

      itemFilter = {
        id: i,
        key: key,
        title: COLUMN_NAME_TH[keyColumn],
        value: parsedValue,
        typeOfValue: TYPE_OF_VALUE.DROPDOWN_MULTI,
        label: label,
      }
      queryStringFilters.push(itemFilter)
      i++
    }
  })

  return queryStringFilters
}
