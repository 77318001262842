import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import { useSelector, shallowEqual } from 'react-redux'
import Score from '../Score'
import Question from '../Question'
import DragDropAnswer from '../DragDropAnswer'
import AddAnswer from '../AddAnswer'
import { getErrorMessage } from '../event'

import AlertErrorBox from '../AlertErrorBox'

import * as StyledForm from '../Styled'
import { StyledHead } from './Styled'

const TrueFalse = ({ keyQuestion, indexQuestion }) => {
  const { question, errorSchemaMessage } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  const { questionType } = question
  const correctErrorMessage = getErrorMessage({
    field: 'CORRECT',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer: errorSchemaMessage.answerNo,
  })

  return (
    <>
      <Card>
        <StyledForm.BoxForm>
          <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
          {correctErrorMessage !== '' && (
            <Box pb={3}>
              <AlertErrorBox />
            </Box>
          )}

          <Box sx={{ pt: 2 }}>
            <StyledHead>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  minWidth: '70%',
                  justifyContent: 'end',
                }}
              >
                <Typography color="text.secondary" variant="body1b">
                  คำตอบ{' '}
                  <Typography color="error.main" variant="span">
                    *
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 3, minWidth: '30%' }}>
                <Typography variant="body1b">ใช่</Typography>
                <Typography variant="body1b">ไม่ใช่</Typography>
              </Box>
            </StyledHead>

            <DragDropAnswer
              keyQuestion={keyQuestion}
              indexQuestion={indexQuestion}
            />

            <AddAnswer label="เพิ่มคำถาม" keyQuestion={keyQuestion} />
          </Box>
        </StyledForm.BoxForm>

        <Score keyQuestion={keyQuestion} questionType={questionType} />
      </Card>
    </>
  )
}
export default TrueFalse
