import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import { BoxContent, BoxLeft, BoxRight, StyledDot } from './Styled.js'

export const BoxLabel = ({ labelLeft, labelRight, status }) => {
  return (
    <BoxContent>
      <BoxLeft>
        <Typography variant="body1b">{labelLeft}</Typography>
      </BoxLeft>
      <BoxRight>
        {status && <StyledDot status={status} />}
        <Typography variant="body1">{labelRight}</Typography>
      </BoxRight>
    </BoxContent>
  )
}

const Index = () => {
  const information = useSelector(
    (state) => state.masterDataLicenseForm.initialState.information,
    shallowEqual
  )

  return (
    <>
      <Box sx={{ m: 3 }}>
        <BoxLabel
          labelLeft="ID"
          labelRight={_.get(information, 'licenseId', '-')}
        />
        <BoxLabel
          labelLeft="ชื่อใบอนุญาต"
          labelRight={_.get(information, 'description', '-')}
        />
        <BoxLabel
          labelLeft="ประเภทใบอนุญาต"
          labelRight={_.get(information, 'category', '-')}
        />
        <BoxLabel
          labelLeft="หมายเหตุ"
          labelRight={_.get(information, 'remark', '-')}
        />
      </Box>
    </>
  )
}

export default Index
