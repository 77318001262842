import { FilterListOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setIsOpenFilterDrawer } from 'src/redux/slices/monthlyPlan'

const Header = () => {
  const dispatch = useDispatch()
  const classPage = window.location.pathname.includes('class')

  const { filterTotal } = useSelector(
    (state) => ({
      filterTotal: state.monthlyPlan.dashboard.filters.filterTotal,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4">
        Monthly plan : {classPage ? 'Class' : 'Workload'} Dashboard
      </Typography>
      <Button
        data-testid={'btn-filter'}
        sx={{ height: 40, p: 1 }}
        variant="text"
        startIcon={<FilterListOutlined />}
        onClick={() => dispatch(setIsOpenFilterDrawer(true))}
      >
        ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
      </Button>
    </Box>
  )
}

export default Header
