import React, { useState } from 'react'
import { Divider, Grid, Typography } from '@mui/material'

import { AnswerBox, FactBox, StyledUL } from './styled'
import { ImageDialog } from '../ImageDialog'

const FactCard = ({ data, CardIndex }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState()

  const handleClickOpen = (e) => {
    setIsOpen(true)
    setSelectedValue(e.target.src)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <FactBox>
      <Typography variant="body1" fontWeight="bold">
        {CardIndex + 1}. {data.content.question}
      </Typography>
      <AnswerBox>
        <StyledUL>
          <li>
            <Typography variant="body1" fontWeight="bold">
              คำตอบ
            </Typography>
          </li>
        </StyledUL>
        <Typography>{data.content.answer}</Typography>
      </AnswerBox>
      {data.content.files.length > 0 && (
        <Grid container spacing={1}>
          {data.content.files.map((item, index) => (
            <Grid item key={index} xs={6} sm={3}>
              <img
                src={`${window.__env__.REACT_APP_API_URL}/file/get${item.key}`}
                alt="image"
                width={'100%'}
                onClick={(e) => handleClickOpen(e)}
              ></img>
            </Grid>
          ))}
        </Grid>
      )}
      <Divider />
      <ImageDialog
        selectedValue={selectedValue}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </FactBox>
  )
}

export default FactCard
