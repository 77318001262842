import React from 'react'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { handleFetchDataCreate } from './Form/event'
import { useDispatch } from 'react-redux'

const ButtonAddMeetingRoom = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const url = '/master-data/meeting-room/form'
  const user = localStorage.getItem('user')
  const objUser = JSON.parse(user)
  const stationUuid = _.get(objUser, 'stationUuid', '')

  return (
    <Button
      data-testid="btn-add-meeting-room"
      variant="contained"
      size="m"
      startIcon={<Add />}
      onClick={async () => {
        await dispatch(handleFetchDataCreate(stationUuid))
        history.push(url)
      }}
    >
      เพิ่มห้องประชุม
    </Button>
  )
}

export default ButtonAddMeetingRoom
