import React from 'react'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import { fieldSelector } from '../../../../../../redux/slices/eLearning/courseForm'
import { StyledFormCard } from '../../../../Styled'
import { CircularContent } from '../..'
import DragDropContent from './DragDropContent'
import LessonForm from './LessonForm'
import { StyledSmallButton } from './Styled'
import { handleAddItem, handleDragEnd } from './events'

const Lesson = () => {
  const lessonsKey = 'eLearningCourseLesson'
  const dispatch = useDispatch()
  const { isOptionLoading, onGoing } = useSelector(
    (state) => ({
      isOptionLoading: state.eLearningCourseForm.isOptionLoading,
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )

  return (
    <StyledFormCard sx={{ gap: 3, alignItems: 'flex-start' }}>
      {isOptionLoading && <CircularContent />}
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h6">สร้างบทเรียนในหลักสูตร </Typography>
        <Typography variant="h6" color="error.main">
          *
        </Typography>
      </Box>

      <DragDropLessonList lessonsKey={lessonsKey} />

      <StyledSmallButton
        variant="outlined"
        startIcon={<Add />}
        onClick={() => dispatch(handleAddItem(lessonsKey))}
        disabled={onGoing}
      >
        เพิ่มบทเรียน
      </StyledSmallButton>
    </StyledFormCard>
  )
}

export default Lesson

export const DragDropLessonList = ({ lessonsKey }) => {
  const lessons = useSelector(fieldSelector(lessonsKey), shallowEqual)
  const onGoing = useSelector(
    (state) => state.eLearningCourseForm.onGoing,
    shallowEqual,
  )
  const dispatch = useDispatch()
  const { setErrors } = useFormikContext()

  return (
    <DragDropContent
      id="lesson-list"
      onDragEnd={(result) =>
        dispatch(handleDragEnd(result, lessonsKey, setErrors))
      }
    >
      {lessons.map((id, index) => (
        <Draggable
          index={index}
          key={`lesson-${id}`}
          draggableId={`lesson-${id}`}
          isDragDisabled={onGoing}
        >
          {(provided) => (
            <LessonForm
              provided={provided}
              index={index}
              lessonsKey={lessonsKey}
            />
          )}
        </Draggable>
      ))}
    </DragDropContent>
  )
}
