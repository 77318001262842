import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import { Row, StyledFormCard, StyledRadioGroup } from '../Styled'
import DatePicker from '../../../../../components/Input/DatePicker'
import { FormControlLabel, Radio } from '@mui/material'
import { handleChange } from '../events'

const TimeRange = () => {
  const dispatch = useDispatch()
  const { isUploadLoading, startDate, endDate, isDueDate } = useSelector(
    (state) => ({
      isUploadLoading: state.settingPoint.isUploadLoading,
      startDate: state.settingPoint.body.startDate,
      endDate: state.settingPoint.body.endDate,
      isDueDate: state.settingPoint.body.isDueDate,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">ระยะเวลาที่จะได้รับคะแนน</Typography>
      <StyledRadioGroup
        name="isDueDate"
        value={isDueDate}
        onChange={(e) =>
          dispatch(handleChange('isDueDate', e.target.value === 'true'))
        }
      >
        <FormControlLabel value={false} control={<Radio />} label="ไม่จำกัด" />
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="จำกัด (*โปรดระบุวันสิ้นสุด)"
        />
      </StyledRadioGroup>
      <Row sx={{ gap: 3 }}>
        <DatePicker
          id="input-startDate"
          name="startDate"
          labelText="กำหนดวันเริ่มต้น"
          placeholder="เลือกวันที่"
          required
          value={startDate}
          onChange={(e) => dispatch(handleChange('startDate', e || ''))}
          textError={_.get(errors, 'startDate', '')}
          hideTextError={_.get(errors, 'startDate', '') ? false : true}
        />
        <DatePicker
          id="input-endDate"
          name="endDate"
          labelText="วันสิ้นสุด"
          placeholder="เลือกวันที่"
          required={isDueDate}
          value={endDate}
          onChange={(e) => dispatch(handleChange('endDate', e || ''))}
          textError={_.get(errors, 'endDate', '')}
          hideTextError={_.get(errors, 'endDate', '') ? false : true}
          disabledInput={!isDueDate}
        />
      </Row>
    </StyledFormCard>
  )
}

export default TimeRange
