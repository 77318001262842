import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledHome = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  margin: '0 0',
  marginTop: '-13px',
  backgroundColor: theme.palette?.background?.blue,
}))

export const StyledDotBg = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 580,
  left: 72,
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    width: 102,
    bottom: 377,
    left: 20,
  },
  [theme.breakpoints.down('sm')]: { width: 102, bottom: 634 },
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))
