import React from 'react'
import Divider from '@mui/material/Divider'
import _ from 'lodash'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

import { useDispatch } from 'react-redux'

import * as Styled from '../../Styled'
import { handleChange } from '../../event'

import TextInput from '../../../../../../components/Input/TextInput'
import { handleNumberInput, handlePercentChange } from './events'

const TestingCriteria = ({ setting, disableSetting }) => {
  const { testCriteriaType, criteriaPercent } = setting
  const dispatch = useDispatch()
  return (
    <>
      <Divider sx={{ m: 3 }} />
      <Styled.BoxForm isGap sx={{ mt: 0 }}>
        <Typography variant="h6">เกณฑ์คะแนนผ่าน</Typography>

        <RadioGroup
          sx={{ pl: 3, gap: 0.5 }}
          aria-label="approval"
          name="testCriteriaType"
          id="testCriteriaType"
          data-testid="radio-testCriteriaType"
          defaultValue={testCriteriaType || 'REQUIRED'}
          value={testCriteriaType || 'REQUIRED'}
          onChange={(e) => {
            const value = _.get(e, 'target.value', '')
            dispatch(handleChange({ value, key: 'testCriteriaType' }))
            if (value === 'REQUIRED') {
              dispatch(handleChange({ value: null, key: 'criteriaPercent' }))
            }
          }}
        >
          <FormControlLabel
            disabled={disableSetting}
            value={'REQUIRED'}
            control={<Radio />}
            label="ต้องทำ"
          />
          <FormControlLabel
            disabled={disableSetting}
            value={'REQUIRED_PERCENT'}
            control={<Radio />}
            label="ต้องทำ และผ่านตามเกณฑ์ (% ของคะแนนทั้งหมด)"
          />
          {testCriteriaType === 'REQUIRED_PERCENT' && (
            <TextInput
              sx={{ width: 258, ml: 3 }}
              id="criteriaPercent"
              name="criteriaPercent"
              type="basic"
              placeholder="%"
              disabled={disableSetting}
              value={criteriaPercent}
              inputProps={{
                type: 'number',
                min: '0',
                max: '100',
                onInput: handleNumberInput,
              }}
              onChange={(e) => dispatch(handlePercentChange(e))}
            />
          )}
        </RadioGroup>
      </Styled.BoxForm>
    </>
  )
}

export default TestingCriteria
