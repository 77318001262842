import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { MODULE_STATUS } from '../../../../constants/learningPoint'
import { handleChange } from '../events'
import { StyledBoxContent } from './Styled'

const StatusSwitch = () => {
  const dispatch = useDispatch()
  const { status } = useSelector(
    (state) => ({ status: state.catalogForm.body.status }),
    shallowEqual,
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <StyledBoxContent>
        <Typography variant="body1b">สถานะ เปิดการใช้งาน</Typography>

        <Switch
          name="status"
          checked={status === MODULE_STATUS.ACTIVE}
          onChange={(e) => {
            dispatch(
              handleChange(
                'status',
                e.target.checked
                  ? MODULE_STATUS.ACTIVE
                  : MODULE_STATUS.INACTIVE,
              ),
            )
            dispatch(handleChange('isActive', e.target.checked))
          }}
        />
      </StyledBoxContent>
    </Box>
  )
}

export default StatusSwitch
