import { typeOfETesting } from '../../constants/eTesting'

export const breadcrumbList = [
  { title: 'แบบทดสอบทั้งหมด', link: '/', pointer: false },
]

export const breadcrumbVersion = [
  { title: 'E-Testing', link: '/e-testing', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const breadcrumbVersionView = (codeId) => {
  return [
    { title: 'E-Testing', link: '/e-testing', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: '/e-testing/version/' + codeId,
      pointer: true,
    },
    { title: 'รายละเอียดแบบทดสอบ', link: '/e-testing/version', pointer: false },
  ]
}

export const breadcrumbForm = (type) => {
  return [
    { title: 'E-Testing', link: '/e-testing', pointer: true },
    {
      title: `สร้างแบบทดสอบ (${typeOfETesting[type] || ''})`,
      link: '/',
      pointer: false,
    },
  ]
}

export const breadcrumbFormEdit = (type, codeId, titleLabel) => {
  return [
    { title: 'E-Testing', link: '/e-testing', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: '/e-testing/version/' + codeId,
      pointer: true,
    },
    {
      title: `${titleLabel} (${typeOfETesting[type]})`,
      link: '/',
      pointer: false,
    },
  ]
}
