import { fetchCalendar } from '../../../../../modules/RoomManagement/BookingRoomSection/event'
import { setReduxValue } from '../../../../../redux/slices/roomManagement/Dashboard'
import { fetchRoomDetailCalendar } from '../../event'
import { enumRequestType } from './constanst'
// import { store } from '../../../../../App'

export const convertDate = ({ date }) =>
  `${date}`.length === 1 ? `0${date}` : date

export const handleDateCalendar =
  ({ calendarComponentRef, type = 0, page }) =>
  (dispatch) => {
    // const state = store.getState()
    // const { viewCalendar } = state.roomManagement
    const calendarApi = calendarComponentRef.current.getApi()

    if (type === 'prev') {
      calendarApi.prev()
      // const dataPrev = dayjs(
      //   `${year}-${convertDate({ date: month + 1 })}-01`
      // ).add(-1, 'month')
      //
      // month = dayjs(dataPrev).month()
      // year = dayjs(dataPrev).year()
    } else if (type === 'next') {
      calendarApi.next()
      // const dataNext = dayjs(
      //   `${year}-${convertDate({ date: month + 1 })}-01`
      // ).add(1, 'month')
      //
      // month = dayjs(dataNext).month()
      // year = dayjs(dataNext).year()
    }
    // else if (type === 'month') {
    //   month = value
    // } else if (type === 'year') {
    //   year = value
    // }
    // const calendar = `${year}-${convertDate({ date: month + 1 })}-01`

    // if (calendarComponentRef.current) {
    //   const calendarApi = calendarComponentRef.current.getApi()
    //   calendarApi.gotoDate(calendar)
    // }
    if (page == 'dashboard') {
      dispatch(fetchCalendar())
    } else {
      dispatch(fetchRoomDetailCalendar())
    }
  }

export const handleViewCalendar =
  (calendarComponentRef, view, page) => (dispatch) => {
    calendarComponentRef.current.getApi().changeView(view)
    dispatch(setReduxValue({ key: 'viewCalendar', value: view }))
    if (page == 'dashboard') {
      dispatch(fetchCalendar())
    } else {
      dispatch(fetchRoomDetailCalendar())
    }
  }

export const handleClickPopup = (uuid, type) => {
  if (type === enumRequestType.MANAGE_CLASS) {
    window.open(`/manage-class/detail/${uuid}`).focus()
  } else {
    window.open(`/room-management?id=${uuid}&drawer=1`).focus()
  }
}
