import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import useTheme from '@mui/system/useTheme'
import { ROW_PAGE_PER_LIST } from '../../../../constants/table'

export const handleChangePage = (newPage, setPage) => {
  setPage(newPage)
}

export const handleChangeRowsPerPage = (
  event,
  setRowsPerPage,
  setPage,
  setLimit
) => {
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1
  setRowsPerPage({ label: '' + rowsPerPageChange, value: rowsPerPageChange })
  setLimit(parseInt(event.target.value, 10))
  setPage(0)
}

const HistoryDrawer = (props) => {
  const { open, onClose, historyList, page, setPage, setLimit, allCount } =
    props
  const theme = useTheme()
  const [rowsPerPage, setRowsPerPage] = useState({ label: '100', value: 100 })
  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          my: 2,
          ml: 3,
          mr: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          width: 576,
          flexDirection: 'row',
        }}
      >
        <Typography variant="h5">ประวัติการแก้ไข</Typography>
        <IconButton
          sx={{ p: 0 }}
          data-testid={`btn-close-drawer`}
          color="primary"
          component="span"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      <Box
        sx={{
          mx: theme.spacing(3),
          mt: theme.spacing(3),
        }}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{
              maxWidth: 576,
              minWidth: 250,
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ maxWidth: 250 }}>
              <TableRow>
                <TableCell sx={{ width: '140px' }}>ผู้แก้ไข</TableCell>
                <TableCell>เหตุผล</TableCell>
                <TableCell sx={{ width: '131px' }}>วันเวลาที่แก้ไข</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.author}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.data}
                  </TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={allCount}
          page={page}
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          onPageChange={(event, tablePage) =>
            handleChangePage(tablePage, setPage)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, setRowsPerPage, setPage, setLimit)
          }
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า :{' '}
            </Typography>
          }
          labelDisplayedRows={() => {
            const realPage = page <= 0 ? 1 : page + 1
            let minRowsShowed = page < 0 ? 1 : rowsPerPage.value * page + 1
            let maxRowsShowed =
              rowsPerPage.value * realPage > allCount
                ? allCount
                : rowsPerPage.value * realPage
            if (maxRowsShowed == 0) {
              minRowsShowed = 0
            } else if (maxRowsShowed < 0) {
              maxRowsShowed = allCount
            }
            return (
              <Typography variant="caption">
                {minRowsShowed}-{maxRowsShowed} of {allCount}
              </Typography>
            )
          }}
        />
      </Box>
    </Drawer>
  )
}
export default HistoryDrawer
