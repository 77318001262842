import { store } from '../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    category: _.get(filterProp, 'category', ''),
    subCategory: _.get(filterProp, 'subCategory', ''),
    tagName: _.get(filterProp, 'tagName', ''),
    contentType: 'KB',
    updateStartDate: _.isNil(filterProp?.updateStartDate)
      ? undefined
      : dayjs(filterProp.updateStartDate).utc(true).toDate(),
    updateFinishDate: _.isNil(filterProp?.updateFinishDate)
      ? undefined
      : dayjs(filterProp.updateFinishDate).utc(true).toDate(),
    startPublishDate: _.isNil(filterProp?.startPublishDate)
      ? undefined
      : dayjs(filterProp.startPublishDate).utc(true).toDate(),
    endPublishDate: _.isNil(filterProp?.endPublishDate)
      ? undefined
      : dayjs(filterProp.endPublishDate).utc(true).toDate(),
    publishDate: _.get(filterProp, 'publishDate', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    createdBy: _.get(filterProp, 'createdBy', ''),
    status: _.get(filterProp, 'status', []),
    isActive: _.get(filterProp, 'isActive', ''),
  }
}
