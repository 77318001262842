import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledRightContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ theme, isExpanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: isExpanded ? 421 : 0,
  height: '100vh',
  boxShadow:
    '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
  zIndex: 3,
  transition: 'width ease 0.05s',
  background: theme.palette?.background?.paper,
  [theme.breakpoints.down('lg')]: { position: 'absolute', right: 0 },
  [theme.breakpoints.down('sm')]: { width: isExpanded ? '100%' : 0 },
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `29px ${theme.spacing(2)} 0`,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const StyledHeaderText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 170px)',
}))

export const StyledDoubleDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 7,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  marginTop: '18px',
  marginBottom: '18px',
  [theme.breakpoints.down('sm')]: { marginBottom: 0 },
}))

export const StyledListContainer = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}))

export const StyledListColumn = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledOneLineText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const StyledProgressPercent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 50,
  minWidth: 50,
  height: 50,
  background: theme.palette?.primary?.main,
  borderRadius: 65,
  alignItems: 'center',
  justifyContent: 'center',
}))
