export const initialState = {
  error: null,
  isLoading: false,
  isLoadmoreLoading: false,
  summaryCount: {
    toDayCount: 0,
    allToDoCount: 0,
    onWorkingCount: 0,
    toDoCount: 0,
    allNotification: 0,
    allToDoCardCount: 0,
  },
  data: {
    totalCount: 0,
    listCount: 0,
    result: [],
  },
  notification: {
    totalCount: 0,
    result: [],
  },
}
