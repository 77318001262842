import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import React from 'react'
import { StyledAlertBox, BoxWarning, BoxIconInfo } from './Styled'

const Index = () => {
  return (
    <StyledAlertBox>
      <Box>
        <BoxWarning>
          <BoxIconInfo color="info" />
          <Typography variant="alertTitle">
            แบบทดสอบประเภท Assessment มีการให้คะแนนและจัดสอบโดยวิทยากร
          </Typography>
        </BoxWarning>
        <Typography variant="body2" sx={{ pl: 4 }}>
          วิทยากรทำการทดสอบผู้เข้าอบรมทีละคน เช่น ถามตอบ (Verbal Test)
          แล้วบันทึกคะแนนที่ได้เข้าระบบ
        </Typography>
      </Box>
    </StyledAlertBox>
  )
}

export default Index
