import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
// import _ from 'lodash'
import Search from './Search'

const CustomEnhancedTableToolbar = () => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <Typography variant="h6">จำนวนผู้ได้คะแนน</Typography>
      <Search />
    </Box>
  )
}

export default CustomEnhancedTableToolbar
