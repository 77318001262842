export const agentTypeOptions = [
  { label: 'Agent', value: 'A' },
  { label: 'LB', value: 'L' },
]

export const csTypeOptions = [{ label: 'Agent', value: 'A' }]

export const UPLOAD_STATUS = {
  SUCCESS: 'สำเร็จ',
  NOTFOUND: 'ไม่พบรายชื่ออยู่ในระบบ',
  TERMINATE: 'ผู้ใช้งานนี้มีสถานะ Terminate',
  EVENT_NOT_VALID: 'การจัดการคะแนนไม่ถูกต้อง',
  POINT_NOT_VALID: 'คะแนนที่กรอกไม่ถูกต้อง',
  POINT_EXCEED: 'คะแนนระบุได้ไม่เกิน 4 ตัวอักษร',
  BELOW_ZERO: 'ไม่สามารถลดคะแนนน้อยกว่า 0 ได้',
}
