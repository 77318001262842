import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  margin: `${theme.spacing(-1.5)} 0px auto`,

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    margin: `${theme.spacing(11)} auto 0px auto`,
  },
}))

export const StyledCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: '2px',
  border: `1px solid ${theme.palette?.text?.mercury}`,
  justifyContent: 'space-between',
  width: '440px',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const StyledCardLeftContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '73%',
  gap: '12px',

  [theme.breakpoints.down('sm')]: {
    width: '60%',
  },
}))

export const TextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
}))

export const StyledCardRightContainer = styled(Box)(({ theme, content }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  width: '27%',
  justifyContent: content,

  [theme.breakpoints.down('sm')]: {
    width: '40%',
  },
}))

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 'auto',
}))

export const StyledStaffContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '440px',
  cursor: 'pointer',
  marginTop: '20px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const RightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: `89px ${theme.spacing(10)}`,
  [theme.breakpoints.down('lg')]: {
    padding: `60px ${theme.spacing(10)}`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  },
}))

export const LeftContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'block',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    display: 'none',
  },
}))
