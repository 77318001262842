import { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Row } from '../../../../components/Layout/Styled'
import { StyledTab } from '../Styled'
import { tabList } from '../enum/enum'
import MeetingRoomList from './MeetingRoomList'
import MeetingRoomStat from './MeetingRoomStat'
import { setFieldValue } from '../../../../redux/slices/crud'

const MostUsed = () => {
  const dispatch = useDispatch()
  const { tab } = useSelector(
    (state) => ({
      tab: state.crud.roomManagementDashboard?.tab,
    }),
    shallowEqual,
  )
  useEffect(() => {
    dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.tab',
        value: tabList.MEETING_ROOM,
      }),
    )
  }, [])
  return (
    <Card sx={{ width: '100%', mt: 2, padding: '8px 16px 16px 16px' }}>
      <Typography variant="h6">
        ห้องประชุมที่ถูกใช้งานมากที่สุด (ทุกประเภท)
      </Typography>
      <Row pl={3}>
        <StyledTab
          value={tab || tabList.MEETING_ROOM}
          indicatorColor="primary"
          onChange={(_, value) =>
            dispatch(
              setFieldValue({
                key: 'roomManagementDashboard.tab',
                value: value,
              }),
            )
          }
        >
          <Tab
            value={tabList.MEETING_ROOM}
            label={<Typography variant="body1b">รายชื่อห้องประชุม</Typography>}
          />
          <Tab
            value={tabList.STATISTIC}
            label={<Typography variant="body1b">สถิติการใช้ห้อง</Typography>}
          />
        </StyledTab>
      </Row>
      {tab === tabList.MEETING_ROOM && <MeetingRoomList />}
      {tab === tabList.STATISTIC && <MeetingRoomStat />}
    </Card>
  )
}

export default MostUsed
