import { Button } from '@mui/material'
import _ from 'lodash'
import { StyledColumn } from '../../../../Styled'
import TextInput from '../../../../../../components/Input/TextInput'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import SelectMergeColumn from './SelectMergeColumn'
import { handleAddColumn } from '../../../../handler/handleAddColumn'
import { handleChange } from '../../../../handler/handleChange'

const AddNewMergeColumn = () => {
  const dispatch = useDispatch()
  const { error, newColumnName, disabledAddBtn } = useSelector(
    (state) => ({
      error: state.reportComp.error,
      newColumnName: state.reportComp.newColumnName,
      disabledAddBtn: state.reportComp.disabledAddBtn,
    }),
    shallowEqual,
  )

  const displayValue = _.isNull(newColumnName) ? '' : newColumnName

  return (
    <StyledColumn>
      <TextInput
        boxSx={{ mb: 3 }}
        type="basic"
        name="value"
        labelText={'ชื่อคอลัมน์'}
        placeholder={'กำหนดชื่อ'}
        textError={error}
        isShowTextError={!_.isNull(error)}
        value={displayValue}
        onChange={(e) => {
          const value = _.get(e, 'target.value', null)
          handleChange({
            key: 'newColumnName',
            value: !_.isEmpty(value) ? value : null,
          })
        }}
      />
      <SelectMergeColumn />
      <Button
        sx={{ maxWidth: 105 }}
        variant="contained"
        size="s"
        disabled={disabledAddBtn}
        onClick={() => dispatch(handleAddColumn({ type: 'custom' }))}
      >
        เพิ่มในรายการ
      </Button>
    </StyledColumn>
  )
}

export default AddNewMergeColumn
