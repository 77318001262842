import { TicketStatus } from '../../../../../constants/stock'
import { store } from '../../../../../App'
import { getFormatDate } from '../../../../../utils/lib'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  const formatDate = 'MM/DD/YYYY'
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.draftChecked) {
      status.push(TicketStatus.DRAFT)
    }
    if (filterState.status.waitingApproveChecked) {
      status.push(TicketStatus.WAITING_APPROVE)
    }
    if (filterState.status.approvedChecked) {
      status.push(TicketStatus.APPROVED)
    }
    if (filterState.status.rejectedChecked) {
      status.push(TicketStatus.REJECTED)
    }
    if (filterState.status.canceledChecked) {
      status.push(TicketStatus.CANCELED)
    }
  } else {
    status = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    from: filterState.fromChecked ? filterState.from : '',
    to: filterState.toChecked ? filterState.to : '',
    qtyFrom: filterState.qtyChecked ? filterState.qtyFrom : '',
    qtyTo: filterState.qtyChecked ? filterState.qtyTo : '',
    category: filterState.categoryChecked ? filterState.category : '',
    subCategory: filterState.subCategoryChecked ? filterState.subCategory : '',
    createDateStartDate: filterState.createDateChecked
      ? getFormatDate(filterState.createDateStartDate, formatDate)
      : '',
    createDateEndDate: filterState.createDateChecked
      ? getFormatDate(filterState.createDateEndDate, formatDate)
      : '',
    status: status,
  }
}
