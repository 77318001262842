import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { setChangeTestingInfo } from '../../../../../redux/slices/eTestingForm'

import * as reducer from '../../../../../redux/slices/eTestingForm'
import * as service from '../../../../../services/eTesting/form'
import { checkTypeFile, dialogMaxSizeImage } from '../event'
import { InputImageUpload } from '../Styled'

export const UploadImage = ({
  htmlFor,
  testId,
  labelText,
  onUpload,
  disabled,
}) => {
  return (
    <label htmlFor={htmlFor}>
      <Button
        sx={{ mr: 2, minWidth: 110 }}
        data-testid={testId}
        disabled={disabled}
        variant="contained"
        size="s"
        component="span"
        htmlFor={htmlFor}
      >
        <InputImageUpload
          accept="image/jpg, image/jpeg, image/png"
          type="file"
          id={testId}
          onChange={onUpload}
          disabled={disabled}
        />
        {labelText && <Typography variant="buttons">{labelText}</Typography>}
      </Button>
    </label>
  )
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeTestingInfo(props))
}

export const handleDeleteImageInfo = () => (dispatch) => {
  dispatch(setChangeTestingInfo({ value: '', key: 'coverImage' }))
  dispatch(setChangeTestingInfo({ value: null, key: 'loadingProgress' }))
  dispatch(setChangeTestingInfo({ value: null, key: 'uploadError' }))
}

export const handleUpload = (e, keyValue, id) => async (dispatch) => {
  let body = new FormData()
  let elementId = document.getElementById(id)
  if (e.target.files.length === 0) {
    if (elementId) elementId.value = null
    return
  }
  body.append('file', e.target.files[0])
  const getNameImage = _.get(e, 'target.files[0].name', '')

  if (getNameImage) {
    const getExtensionImage = getNameImage.split('.').pop()
    const sizeFile = _.get(e, 'target.files[0].size', 0)

    if (!checkTypeFile(getExtensionImage.toLowerCase())) {
      if (elementId) elementId.value = null
      return dispatch(dialogMaxSizeImage())
    }
    if (sizeFile > 2000000) {
      if (elementId) elementId.value = null
      return dispatch(dialogMaxSizeImage(true))
    }
  }
  // service upload
  const payload = { body, keyValue }
  const dataUpload = await dispatch(service.uploadFileETesting(payload))
  if (elementId) elementId.value = null

  dispatch(
    reducer.setChangeTestingInfo({
      key: keyValue,
      value: _.get(dataUpload, 'payload.data.key', ''),
    })
  )
}
