import React from 'react'

import ConditionQuestion from './ConditionQuestion'
import TestingInfo from './TestingInfo'

const Index = () => {
  return (
    <>
      <TestingInfo />
      <ConditionQuestion />
    </>
  )
}

export default Index
