import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setReportConfig } from '../../redux/slices/reportComp'
import Report from '../../components/Report'
import { reportConfig } from './reportConfig'

const Index = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setReportConfig({ reportConfig: reportConfig }))
  }, [])

  return (
    <>
      <Report />
    </>
  )
}
export default Index
