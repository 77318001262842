import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../../../../redux/slices/table'
import { onFilterClick } from '../event'
import { callAPI } from '../../../../../../utils/lib'
import { examinationRegionList } from '../../../../../../utils/apiPath'

export const handleFiltering = (table, filter, region, manageProps, examType) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(table, filter, region, manageProps, examType))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleFetchRegion = async (setRegion) => {
  await callAPI({
    method: 'post',
    url: examinationRegionList,
    body: {
      regionName: '',
      createdBy: '',
      updatedBy: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'regionName',
      status: ['ACTIVE'],
    },
    onSuccess: (data) => {
      const regionList = _.get(data, 'result', []).map((item) => ({
        label: item.regionName,
        name: _.lowerCase(item.regionName).replace(' ', '') + 'Checked',
        uuid: item.uuid,
      }))
      setRegion(regionList)
    },
  })
}