import axios from 'axios'
import _ from 'lodash'
import { store } from '../../../App'
import { openErrorDialog } from '../../../redux/slices/dialog'
import { dispatchUploadValue } from './dispatchUploadValue'
import { uploadFileKey } from '../../../utils/apiPath'
import { uniqId } from '../../../services/util'

const getReduxState = () => store.getState().uploadFile

export const handleFilesDirectUpload = ({
  files,
  fileRejections,
  fieldName,
  folder,
  onChange,
  maxLength,
}) => {
  if (fileRejections.length) {
    store.dispatch(
      openErrorDialog({
        message:
          'กรุณาตรวจสอบว่าขนาดไฟล์, จำนวนไฟล์, หรือประเภทไฟล์ของคุณว่าเป็นไปตามที่ระบบกำหนดหรือไม่',
        title: 'ไม่สามารถดำเนินการได้',
        isCloseDialog: false,
      }),
    )
    return
  }

  dispatchUploadValue(`formData[${fieldName}].isUploading`, true)

  if (files.length === 0) return

  const { formData } = getReduxState()
  const uploadedFiles = _.get(formData, `[${fieldName}].files`, [])
  let totalFiles = _.concat(uploadedFiles, files)
  if (totalFiles.length > maxLength) {
    totalFiles.slice(maxLength * -1)
  }

  _.forEach(totalFiles, async (file, index) => {
    if (_.isEmpty(file) || file.fileType) {
      return
    }

    const _id = uniqId()
    const displayName = _.get(file, 'name', '') || ''
    const fileType = displayName.split('.').pop()
    const fileSize = _.get(file, 'size', 0)
    const fileValue = {
      displayName,
      fileType,
      id: index + 1,
      _id,
      downloadable: false,
      fileSize: (fileSize / 1000000).toFixed(2),
    }
    dispatchUploadValue(`formData[${fieldName}].files[${index}]`, fileValue)

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted =
          (progressEvent.loaded / progressEvent.total) * 100
        dispatchUploadValue(
          `formData[${fieldName}].files[${index}].percent`,
          percentCompleted,
        )
      },
    }

    const body = new FormData()
    body.append('file', file)
    try {
      const response = await axios.post(
        `${uploadFileKey}/${folder}`,
        body,
        config,
      )
      const key = _.get(response, 'data.key', {})
      dispatchUploadValue(`formData[${fieldName}].files[${index}].key`, key)
      onChange(getReduxState().formData[fieldName].files)
    } catch (err) {
      console.log(err)
      dispatchUploadValue(
        `formData[${fieldName}].files[${index}].error`,
        'ERROR_UPLOAD_FILE',
      )
      dispatchUploadValue(`formData[${fieldName}].isUploading`, false)
    } finally {
      dispatchUploadValue(`formData[${fieldName}].isUploading`, false)
    }
  })
}
