import _ from 'lodash'

export const handleInitialForm = (initialValues, setOicExam, formik) => {
  const requireOicExam = _.get(initialValues, 'requireOicExam', false)
  const agentConfirmation = _.get(initialValues, 'agentConfirmation', false)

  const documents = {
    requireOicExam,
    agentConfirmation,
  }

  setOicExam(documents)
  formik.setFieldValue('requireOicExam', requireOicExam)
  formik.setFieldValue('agentConfirmation', agentConfirmation)
}

export const handleSettingRadioChange = (e, setInitialValues) => {
  const value = _.get(e, 'target.value', 'no')
  const requireOicExam = value == 'yes'

  setInitialValues((prev) => ({
    ...prev,
    requireOicExam: requireOicExam,
  }))
}

export const handleSettingAgentConfirmationChange = (e, setInitialValues) => {
  const agentConfirmation = _.get(e, 'target.checked', false)

  setInitialValues((prev) => ({
    ...prev,
    agentConfirmation: agentConfirmation,
  }))
}
