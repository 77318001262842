import React from 'react'
import { useDispatch /*useSelector*/ } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table'
import {
  INVENTORY_SETTING,
  RequisitionStatus,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import { checkRejectAndApproveButtons, onCancel } from './events'
import { validatePermission } from '../../../../../utils/lib'
import _ from 'lodash'

const ActionRequisition = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const { onDelete } = useSelector((state) => state.table)
  const viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`

  const user = JSON.parse(localStorage.getItem('user'))
  const hasAdminPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const hasCreatePermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.CREATE],
  })
  const hasMpPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.MP],
  })
  const isSameUser = user.uuid === _.get(row, 'createdBy.uuid')
  const isShowEditAndDelete =
    hasAdminPermission ||
    (isSameUser && (hasCreatePermission || hasMpPermission)) ||
    (isSameUser && !row?.monthlyPlan)
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const toStationUuid = _.get(row, 'to.station.uuid', '')
  const checkStaffStationMatched = staffStationUuid === toStationUuid
  const checkButtonsPermission = checkRejectAndApproveButtons({
    row,
    isSameUser,
    hasAdminPermission,
    hasMpPermission,
    checkStaffStationMatched,
    hasCreatePermission,
  })

  return (
    <StyledBox minWidth={minWidth}>
      {![RequisitionStatus.DRAFT].includes(row.status) && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}

      {[
        RequisitionStatus.DRAFT,
        RequisitionStatus.WAITING_APPROVE,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
        RequisitionStatus.WAITING_RETURN_APPROVE,
      ].includes(row.status) &&
        isShowEditAndDelete && (
          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => {
              if (row.status === RequisitionStatus.WAITING_RETURN_APPROVE) {
                dispatch(
                  setOnView({
                    history,
                    viewUrl: `${viewUrl}?isReturnForm=true`,
                  }),
                )
              } else {
                dispatch(setOnEdit({ history, editUrl }))
              }
            }}
          >
            <EditTwoTone color="action" />
          </IconButton>
        )}
      {[
        RequisitionStatus.DRAFT,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
      ].includes(row.status) &&
        isShowEditAndDelete && (
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => onCancel(row, RequisitionStatus.CANCELED)}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        )}

      {[
        RequisitionStatus.WAITING_APPROVE,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
        RequisitionStatus.WAITING_RETURN_APPROVE,
      ].includes(row.status) &&
        checkButtonsPermission && (
          <>
            <>
              <IconButton
                data-testid={`btn-delete-${rowIndex}`}
                color="primary"
                component="span"
                onClick={() => onCancel(row, RequisitionStatus.REJECTED)}
              >
                <CloseIcon color="action" />
              </IconButton>
            </>
          </>
        )}
    </StyledBox>
  )
}

export default ActionRequisition
