import { store } from '../../../App'
import _ from 'lodash'
import { dispatchUploadValue } from './dispatchUploadValue'

export const handleDragEnd = (result, onChange, fieldName) => {
  if (!result.destination) return

  const { uploadFile } = store.getState()
  const list = _.get(uploadFile, `formData[${fieldName}].files`, [])
  const reorderedList = reorder(
    [...list],
    result.source.index,
    result.destination.index,
  )
  dispatchUploadValue(`formData[${fieldName}].files`, reorderedList)
  onChange(reorderedList)
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
