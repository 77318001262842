import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { LoadingPage } from '../../ManageClass/Styled'
import Header from './Header'
import Content from './Content'

import { handleFetchDataEdit } from '../Form/events'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.masterDataLicenseForm.isLoading,
    shallowEqual
  )

  useEffect(() => {
    if (uuid) dispatch(handleFetchDataEdit(uuid, true))
  }, [uuid])

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Header uuid={uuid} />
          <Content />
        </Box>

        <LoadingPage isLoading={isLoading} />
      </Box>
    </>
  )
}

export default Index
