import React from 'react'
import {
  StyledLoading,
  StyledLoadingBox,
  StyledLoadingProgress,
} from './Styled'

const UploadLoading = ({ keyField, id, sx }) => {
  return (
    <StyledLoadingBox keyField={keyField} sx={sx}>
      <img
        width={keyField === 'img' ? 120 : '100%'}
        src={'/image/no-image.svg'}
      />
      <StyledLoadingProgress keyField={keyField}>
        <StyledLoading id={id} />
      </StyledLoadingProgress>
    </StyledLoadingBox>
  )
}

export default UploadLoading
