import React, { useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './Calendar.css'

const Calendar = ({
  state,
  isTest,
  handleClick,
  handleSelect,
  handleClickEdit,
  calendarComponentRef,
}) => {
  useEffect(() => {
    let {
      data: { month, year },
    } = state

    const calendar = `${year}-${convertDate({ date: month + 1 })}-01`
    const calendarApi = calendarComponentRef.current.getApi()
    if (!isTest) calendarApi.gotoDate(calendar)
  }, [state.calendarWeekends])

  const handleEventClick = (info) => {
    const publicId = info.event._def.extendedProps.publicId || ''
    handleClickEdit(publicId)
  }

  const handleDateClick = (arg) => {
    const date = arg?.date
    handleClick(date)
  }
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: 'sticky',
          top: '80px',
          left: 0,
          zIndex: 11,
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            direction: 'row',
            border: '1px solid',
            borderColor: '#ddd',
            borderBottom: 0,
          }}
        >
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Mon</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Tue</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Wed</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Thu</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Fri</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Sat</Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: 'right', pr: 0.5 }}>
            <Typography variant="body1b">Sun</Typography>
          </Grid>
        </Box>
      </Box>
      <FullCalendar
        timeZone={'GMT+7'}
        defaultView="dayGridMonth"
        firstDay={1}
        eventLimit={2}
        header={{
          left: false,
          center: false,
          right: false,
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        ref={calendarComponentRef}
        eventRender={renderEvent}
        weekends={state.calendarWeekends}
        events={state.calendarEvents}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        selectable={true}
        select={handleSelect}
        showNonCurrentDates={false}
        fixedWeekCount={false}
        dayMaxEventRows={true}
      />
    </>
  )
}

export default Calendar

const renderEvent = (info) => {
  const { status, statusApprove, classLeave, uuid } =
    info.event._def.extendedProps
  const statusBorrow = _.get(info, 'event._def.extendedProps.statusBorrow', '')
  const box = document.createElement('div')
  const borrow = _.get(info, 'event._def.extendedProps.borrow', false)

  box.setAttribute('monthly-plan-uuid', uuid)

  if (borrow) {
    box.className = 'event-box-type-academy-activity-borrow'
  } else {
    const className = classLeave
      ? `-leave-${info.event._def.extendedProps.classLeave}`
      : ''
    box.className = `event-box-type-${info.event._def.extendedProps.class}${className}`
  }

  const boxFlex = document.createElement('div')
  boxFlex.className = 'event-box-flex'
  const isStatusBorrow = statusBorrow === 'WAITING_APPROVE_BORROW'
  const realStatus = isStatusBorrow ? statusBorrow : status

  switch (realStatus) {
    case 'REQUEST_EDIT':
      boxFlex.className = boxFlex.className + ' event-box-request-edit'
      break
    case 'REQUEST_CREATE':
      boxFlex.className = boxFlex.className + ' event-box-request-create'
      break
    case 'REQUEST_DELETE':
      boxFlex.className = boxFlex.className + ' event-box-request-delete'
      break
    case 'WAITING_APPROVE_BORROW':
      boxFlex.className =
        boxFlex.className + ' event-box-waiting-approve-borrow'
      break
    case 'DELETED': {
      if (borrow) box.className = 'event-box-deleted'
      else boxFlex.className = boxFlex.className + ' event-box-deleted'
      break
    }
    case 'CANCEL':
      boxFlex.className = boxFlex.className + ' event-box-deleted'
      break
  }
  const boxLeft = document.createElement('div')
  const boxTitle = document.createElement('div')
  if (status === 'DELETED' || status === 'CANCEL') {
    boxTitle.className = 'event-box-left-deleted'
    const imgIcon = document.createElement('img')
    imgIcon.src = '/icon/ic_calendar_deleted.svg'
    imgIcon.className = 'event-box-icon-left'
    boxLeft.append(imgIcon)
  }

  boxLeft.className = 'event-box-left'
  boxTitle.append(info.event.title)
  boxLeft.append(boxTitle)

  const boxRight = document.createElement('div')
  boxRight.className = 'event-box-right'

  if (info.event._def.extendedProps.sum === 1) {
    const img = document.createElement('img')
    img.src = info.event._def.extendedProps.image[0]
    img.className = 'event-box-img-1'

    boxRight.append(img)
  } else if (info.event._def.extendedProps.sum === 2) {
    const img1 = document.createElement('img')
    img1.src = info.event._def.extendedProps.image[0]
    img1.className = 'event-box-img-left'

    const img2 = document.createElement('img')
    img2.src = info.event._def.extendedProps.image[1]
    img2.className = 'event-box-img-2'

    const boxFlexImg = document.createElement('div')
    boxFlexImg.className = 'event-box-img'

    boxFlexImg.append(img1)
    boxFlexImg.append(img2)
    boxRight.append(boxFlexImg)
  } else if (info.event._def.extendedProps.sum >= 3) {
    const img1 = document.createElement('img')
    img1.src = info.event._def.extendedProps.image[0]
    img1.className = 'event-box-img-left'

    const img2 = document.createElement('div')
    img2.className = 'event-box-div'
    img2.append(`+${info.event._def.extendedProps.sum - 1}`)

    const boxFlexImg = document.createElement('div')
    boxFlexImg.className = 'event-box-img'

    boxFlexImg.append(img1)
    boxFlexImg.append(img2)
    boxRight.append(boxFlexImg)
  }

  if (statusApprove === 'REJECTED') {
    const boxReject = document.createElement('div')
    boxReject.className = 'event-box-reject'
    boxFlex.append(boxReject)
  }

  boxFlex.append(boxLeft)
  boxFlex.append(boxRight)

  box.append(boxFlex)

  return box
}
export const convertDate = ({ date }) =>
  `${date}`.length === 1 ? `0${date}` : date
