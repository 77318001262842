import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import Edit from '@mui/icons-material/Edit'
import Carousel from '../../../../../components/Carousel'
import { StyledColumn, StyledRow } from '../../Styled'
import ActiveSwitch from '../../../components/ActiveSwitch'
import { SECTION_KEY, SECTION_OBJ } from '../../model'
import { handleSelector, handleSwitchBody } from '../events'
import { StyledSectionWithCarousel } from '../Styled'
import {
  StyledBannerBottom,
  StyledBannerMedia,
  StyledBoxBanner,
  StyledContainerBanner,
  StyledTypography,
  StyledVideoPreview,
} from './Styled'
import BannerDrawer from './BannerDrawer'
import { getDefaultValue, getMediaType } from './events'
import { useEffect } from 'react'

const Banner = () => {
  const [touch, setTouch] = useState(false)
  const { sectionNo, title, keyInfo } = SECTION_OBJ[SECTION_KEY.BANNER]
  const bannerSection = useSelector(
    (state) => handleSelector(state, keyInfo),
    shallowEqual,
  )
  const bannerList = _.chunk(bannerSection, 2)

  return (
    <StyledSectionWithCarousel>
      <Typography px={3} variant="h6">
        Section {sectionNo} | {title}
      </Typography>
      <Carousel autoPlay={false} emulateTouch={!touch}>
        {bannerList.map((banner, key) => (
          <StyledContainerBanner key={key}>
            {banner.map((item, index) => (
              <BoxBanner key={index} data={item} setTouch={setTouch} />
            ))}
          </StyledContainerBanner>
        ))}
      </Carousel>
    </StyledSectionWithCarousel>
  )
}

export default Banner

export const BoxBanner = ({ data, setTouch }) => {
  const { keyInfo } = SECTION_OBJ[SECTION_KEY.BANNER]
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const no = _.get(data, 'no', 1)
  const image = _.get(data, 'image', '')
  const imageName = image.split('/manageContent/').pop()
  const videoUrl = _.get(data, 'videoURL', '')
  const mediaType = getMediaType(data)
  const mobileImg =
    mediaType === 'IMAGE'
      ? _.get(data, 'mobileImg', '')
      : _.get(data, 'videoImg', '')
  const mobileImgName = mobileImg.split('/manageContent/').pop()
  const isButtonPrimary = _.get(data, 'primaryStatus', false)
  const isButtonSecondary = _.get(data, 'secondaryStatus', false)
  const { errors } = useFormikContext()
  const bannerError = _.get(errors, 'bannerSection', {})

  useEffect(() => {
    setTouch(isOpen)
  }, [isOpen])

  return (
    <StyledBoxBanner>
      <StyledColumn gap={2}>
        <StyledRow justifyContent="space-between" alignItems="center">
          <Typography variant="body1b">Banner {no}</Typography>
          <IconButton
            data-testid={`btn-edit-${no}`}
            onClick={() => setOpen(true)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </StyledRow>
        <Typography variant="body2b">Desktop Image</Typography>
        <StyledBannerMedia>
          {videoUrl != '' && <StyledVideoPreview url={videoUrl} />}
          {image != '' && (
            <img src={`${window.__env__.REACT_APP_API_URL}/file${image}`} />
          )}
          {image == '' && videoUrl == '' && <img src={'/image/no-image.svg'} />}
        </StyledBannerMedia>
        <MediaLabel label={`DESKTOP ${mediaType}`}>
          {image != '' ? imageName : videoUrl != '' ? videoUrl : '-'}
        </MediaLabel>
        <Typography variant="body2b">Mobile Image</Typography>
        <StyledBannerMedia>
          {mobileImg != '' && (
            <img src={`${window.__env__.REACT_APP_API_URL}/file${mobileImg}`} />
          )}
          {mobileImg == '' && <img src={'/image/no-image.svg'} />}
        </StyledBannerMedia>
        <MediaLabel label="MOBILE IMAGE">
          {mobileImg != '' ? mobileImgName : '-'}
        </MediaLabel>
        <StyledColumn gap={2} paddingBottom={1}>
          <BannerContent
            label="Subtitle"
            data={getDefaultValue(data, 'subtitle')}
          />
          <BannerContent label="Title" data={getDefaultValue(data, 'title')} />
          <BannerContent
            label="Description"
            data={getDefaultValue(data, 'description')}
          />
        </StyledColumn>
      </StyledColumn>
      <StyledColumn>
        <StyledBannerBottom>
          <StyledRow sx={{ width: 'auto' }}>
            {isButtonPrimary && (
              <Button variant="preview" color="primary">
                {_.get(data, 'primaryButtonTitle', '')}
              </Button>
            )}
            {isButtonSecondary && (
              <Button variant="preview" color="secondary">
                {_.get(data, 'secondaryButtonTitle', '')}
              </Button>
            )}
          </StyledRow>
          <ActiveSwitch
            name="status"
            checked={_.get(data, 'status', false)}
            onChange={(e) =>
              dispatch(handleSwitchBody(e, `${keyInfo}[${no - 1}]`))
            }
            dataTestId={`active-banner-${no}`}
          />
        </StyledBannerBottom>
        {typeof bannerError == 'string' && (
          <Typography variant="body2" color="error">
            {bannerError}
          </Typography>
        )}
        <BannerDrawer
          data={data}
          isOpen={isOpen}
          onCloseDrawer={() => setOpen(false)}
        />
      </StyledColumn>
    </StyledBoxBanner>
  )
}

export const BannerContent = ({ label, data, isNotReplace }) => {
  return (
    <StyledColumn>
      <Typography variant="body2b">{label}</Typography>
      <StyledTypography
        isNotReplace={isNotReplace}
        label={label}
        variant="body1"
        color="text.gray"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </StyledColumn>
  )
}

export const MediaLabel = ({ label, children }) => {
  return (
    <StyledRow sx={{ gap: 1, alignItems: 'center' }}>
      <Typography color="secondary" variant="overline">
        {label}:
      </Typography>
      <Typography
        sx={{
          maxWidth: 'calc(100% - 115px)',
          whiteSpace: 'pre',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        color="secondary"
        variant="caption"
      >
        {children}
      </Typography>
    </StyledRow>
  )
}
