export const breadcrumbList = [
    { title: 'Configuration', link: '/', pointer: false },
]

export const configTabs = [
    { label: 'หลักสูตรทั่วไป', key: 'GENERAL', },
    { label: 'หลักสูตรออนไลน์ (E-Learning)', key: 'E_LEARNING', },
    { label: 'E-Exam', key: 'EEXAM', },
    { label: 'เงื่อนไขและข้อตกลง', key: 'TERMS', },
]

export const FileTypes = [
    { label: 'MB', value: 'MB' },
    { label: 'GB', value: 'GB' },
]