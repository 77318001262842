// import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../../crudConfig'

export const headerCells = () => {
  //const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['DELETE'],
  // })

  return [
    {
      id: 'status',
      label: '',
      disablePadding: false,
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'version',
      label: 'เวอร์ชัน',
      disablePadding: false,
    },
    {
      id: 'name',
      label: 'ชื่อแบบทดสอบ',
      disablePadding: false,
    },
    {
      id: 'startDate',
      label: 'วันเริ่มต้นใช้งาน',
      disablePadding: false,
    },
    {
      id: 'updateByName',
      label: 'แก้ไขล่าสุดโดย',
      disablePadding: false,
    },
    {
      id: 'versionStatus',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `${crudConfig.modulePath}/view`,
    },
  ]
}
