import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import { setIsOpenHistoryFilterDrawer } from '../../../redux/slices/eExamination/list'
import { StyledHeadLabel } from '../../../components/FilterDrawer/Styled'
import Drawer from '@mui/material/Drawer'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { fetchStudentHistory } from './events'
import { headCells } from './events'
import NormalTable from '../../../components/Table/NormalTable/NormalTable'
import { fetchHistory } from '../Form/event'

const HistoryFilterDrawer = (props) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { open, historyList, page, isForm } = props
  const theme = useTheme()

  useEffect(() => {
    if (open && !isForm) {
      dispatch(fetchStudentHistory(uuid, page))
    }
  }, [open])

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor="right"
      open={open}
      onClose={() => dispatch(setIsOpenHistoryFilterDrawer(false))}
    >
      <Box
        sx={{
          width: 576,
        }}
      >
        <StyledHeadLabel>
          <Box>
            <Typography variant="h5">ประวัติการแก้ไข</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={() => dispatch(setIsOpenHistoryFilterDrawer(false))}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>

        <Box
          sx={{
            mx: theme.spacing(3),
            mt: theme.spacing(3),
          }}
        >
          <NormalTable
            headCells={headCells}
            listRows={_.get(historyList, 'result', [])}
            totalRows={_.get(historyList, 'totalCount', 0)}
            showPagination
            minWidth={300}
            handlePagination={(events) => {
              if (!isForm) {
                dispatch(fetchStudentHistory(uuid, page, events))
              } else {
                dispatch(fetchHistory(events.limit, page, uuid))
              }
            }}
          />
        </Box>
      </Box>
    </Drawer>
  )
}
export default HistoryFilterDrawer
