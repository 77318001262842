import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import { StyledBox } from '../Styled'

import { setOnView } from '../../../../../redux/slices/table'
import { setChangeStatusLicense } from '../../../../../redux/slices/apiToRcms'

const ActionRcmsDetail = ({ row, rowIndex, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const viewUrl = `${condition.viewPath}/${id}/${row.agentUuid}`
  return (
    <StyledBox>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => dispatch(setOnView({ history, viewUrl }))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>
      {row.rcmsStatus !== 'สำเร็จ' && row.rcmsStatus !== 'ไม่สำเร็จ' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setChangeStatusLicense([row.uuid]))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionRcmsDetail
