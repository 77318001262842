import { LoadingPage, StyledCard, StyledColumn, StyledRow } from '../Styled'
import { MonthlyPlanCard } from './MonthlyPlanCard'
import { StatusRoomCard } from './StatusRoomCard'
import { BookingStatistics } from './BookingStatistics'
import { SelectStation } from './SelectStaion'
import { useEffect } from 'react'
import { fetchDataRoomManagement } from './event'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  setMonth,
  setYear,
} from '../../../redux/slices/roomManagement/Dashboard'
import { WeeklyBookingsCard } from './WeeklyBookingsCard'
import useTheme from '@mui/system/useTheme'
import _ from 'lodash'

const DashboardSection = ({ bookingRoomRef }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isLoading, stationUuid } = useSelector(
    (state) => ({
      isLoading: state.roomManagement.isLoading,
      stationUuid: state.roomManagement.stationUuid,
    }),
    shallowEqual,
  )

  const dateNow = dayjs(new Date()).format('YYYY-MM-DD')
  const arrayDate = dateNow.split('-').map(Number)

  useEffect(() => {
    dispatch(setYear(arrayDate[0]))
    dispatch(setMonth(arrayDate[1]))
  }, [])

  useEffect(() => {
    if (_.isEmpty(stationUuid)) return

    dispatch(fetchDataRoomManagement())
  }, [])

  return (
    <>
      {!isLoading ? (
        <StyledRow
          sx={{
            gap: 1.75,
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <StyledColumn
            sx={{ gap: 2, flexGrow: 1.75, width: { sm: 'unset', xs: '100%' } }}
          >
            <StatusRoomCard />
            <MonthlyPlanCard bookingRoomRef={bookingRoomRef} />
            <BookingStatistics />
          </StyledColumn>
          <StyledColumn sx={{ gap: 1.25, width: { sm: 'unset', xs: '100%' } }}>
            <SelectStation />
            <WeeklyBookingsCard />
          </StyledColumn>
        </StyledRow>
      ) : (
        <StyledCard
          sx={{
            height: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingPage isLoading={isLoading} size={24} />
        </StyledCard>
      )}
    </>
  )
}
export default DashboardSection
