import {
  setAddQuestionNotOther,
  setChange,
} from '../../../../../../../redux/slices/eEvaluationForm'

export const handleAddQuestion = (props) => (dispatch) => {
  dispatch(setAddQuestionNotOther(props))
}

export const handleSuggestion = (props) => (dispatch) => {
  dispatch(setChange(props))
}
