import React from 'react'
import { StyledRow } from '../../Styled'
import { Button } from '@mui/material'
import { roomRequestTypeList } from '../../../../constants/roomManagement'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import useTheme from '@mui/system/useTheme'
import { setRequestType } from '../../../../redux/slices/roomManagement/Dashboard'
import { useDispatch } from 'react-redux'

export default function ButtonRequestType() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { requestType } = useSelector(
    (state) => ({
      requestType: state.roomManagement.requestType,
    }),
    shallowEqual,
  )

  return (
    <StyledRow>
      {_.map(roomRequestTypeList, (rqType, idx) => (
        <Button
          key={idx}
          sx={{
            color:
              requestType === rqType.value
                ? theme.palette?.common?.white
                : theme.palette?.text?.gray,
            borderRadius: 2,
          }}
          variant={requestType === rqType.value ? 'contained' : ''}
          onClick={() => dispatch(setRequestType(rqType.value))}
        >
          {rqType.label}
        </Button>
      ))}
    </StyledRow>
  )
}
