import _ from 'lodash'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import { handleAnswerNumber } from '../../../../modules/ETesting/Preview/Testing/Footer/events'
import { handleAnswerAmount } from '../../../../modules/EEvaluation/Preview/Evaluate/Footer/events'
import { initialVideo } from './model'

export const handleInitialCourse = (payload) => {
  return _.map(payload.eLearningCourseLesson, (lesson, index) => ({
    ...lesson,
    eLearningLessonRefModule: undefined,
    modules: _.map(lesson.eLearningLessonRefModule, (item, mIndex) =>
      handleInitialModule(item.eLearningModule, mIndex, index),
    ),
  }))
}

export const handleInitialModule = (item, mIndex, index, isModule) => {
  const moduleType = _.get(item, 'moduleType', '')
  const mainKey = isModule ? _.camelCase(moduleType) : 'moduleDetail'
  const isFirstModule = index === 0 && mIndex === 0
  let module = {
    lessonNo: index + 1,
    no: mIndex + 1,
    uuid: _.get(item, 'uuid', ''),
    lessonName: _.get(item, 'name', ''),
    type: moduleType,
    moduleType,
    progress: 0,
  }
  switch (moduleType) {
    case MODULE_TYPE.E_TESTING: {
      const eTestingUuid = _.get(item, `${mainKey}.uuid`, '')
      localStorage.removeItem(eTestingUuid)
      module = {
        ...module,
        eTestingUuid,
        eTestingType: _.get(item, `${mainKey}.type`, ''),
        criteriaType: _.get(item, `${mainKey}.criteriaType`, ''),
        criteriaPercent: _.get(item, `${mainKey}.criteriaPercent`, ''),
        isInProgress: false,
      }
      break
    }
    case MODULE_TYPE.E_EVALUATION: {
      const eEvaluationUuid = _.get(item, `${mainKey}.uuid`, '')
      localStorage.removeItem(eEvaluationUuid)
      module = {
        ...module,
        eEvaluationUuid,
        eEvaluationType: _.get(item, `${mainKey}.type`, ''),
        criteriaType: _.get(item, `${mainKey}.criteriaType`, ''),
        isInProgress: false,
      }
      break
    }
    case MODULE_TYPE.EMBED_CONTENT:
      module = {
        ...module,
        type: _.get(item, `${mainKey}.type`, ''),
        url: _.get(item, `${mainKey}.value`, ''),
        duration: _.get(item, `${mainKey}.duration`, 0),
        videoState: initialVideo,
      }
      break
    case MODULE_TYPE.LINK_TO:
      module = {
        ...module,
        link: _.get(item, `${mainKey}.link`, ''),
        linkDescription: _.get(item, `${mainKey}.linkDescription`, ''),
      }
      break
    case MODULE_TYPE.UPLOAD_FILE:
      {
        const fileListKey = isModule ? mainKey : 'eLearningModuleFileUpload'
        const fileList = _.get(item, fileListKey, [])
          .filter((file) => _.isEmpty(file.error))
          .map((file, fIndex) => ({
            ...file,
            isFinished: isFirstModule && fIndex === 0,
          }))
        module = {
          ...module,
          progress: isFirstModule ? (1 / fileList.length) * 100 : 0,
          selectedFile: 0,
          fileList,
        }
      }
      break
    case MODULE_TYPE.SCORM:
      module = {
        ...module,
        ..._.get(item, mainKey, {}),
        eLearningModuleFileUpload: item.eLearningModuleFileUpload,
        type: moduleType,
        progress: 0,
      }
      break
    default:
      break
  }

  return module
}

export const handleSetLessonState = (state, module) => {
  state.selectedLesson = module
  if (state.isCourse) {
    const lessonIndex = _.get(state.selectedLesson, 'lessonNo', 1) - 1
    const moduleIndex = _.get(state.selectedLesson, 'no', 1) - 1
    _.set(state.lessons, `[${lessonIndex}].modules[${moduleIndex}]`, module)
  } else {
    _.set(state.lessons, '[0]', module)
  }
}

export const handleEmbedContentProgress = (payload) => {
  const played = _.get(payload, 'played', 0)
  return (played * 100).toFixed()
}

export const handleUploadFileProgress = (selectedLesson) => {
  const fileList = _.get(selectedLesson, 'fileList', [])
  const seletedFiles = fileList.filter((item) => item.isFinished)
  const progress = (seletedFiles.length / fileList.length) * 100
  return progress.toFixed()
}

export const handleETestingProgress = (eTestingProps) => {
  if (eTestingProps.section === 'RESULT' || eTestingProps.isReview) {
    return 100
  }

  const {
    setting: { displayQuestion },
    testing,
    page,
  } = eTestingProps
  const answerAmount = Number(
    handleAnswerNumber(displayQuestion, testing, page),
  )
  if (_.isNaN(answerAmount)) return 0
  return (answerAmount / testing.length) * 100
}

export const handleEEvaluationProgress = (eEvaluationProps) => {
  if (eEvaluationProps.section === 'RESULT') return 100
  const answerAmount = Number(handleAnswerAmount(eEvaluationProps))
  return _.isNaN(answerAmount) ? 0 : answerAmount
}

export const getLatestETestEEva = (listenerApi, key) => {
  const { eLearningPreview } = listenerApi.getState()
  const selected = _.get(eLearningPreview, 'selectedLesson', {})
  const uuid = _.get(selected, key, '')
  const latestState = JSON.parse(localStorage.getItem(uuid))
  return latestState
}

export const setLatestETestEEva = (listenerApi, key) => {
  const eTestEEvaState = listenerApi.getState()[`${key}Preview`]
  const oldELearningPreview = listenerApi.getOriginalState().eLearningPreview
  const oldSelected = _.get(oldELearningPreview, 'selectedLesson', {})
  const uuid = _.get(oldSelected, `${key}Uuid`, '')

  if (eTestEEvaState.uuid === uuid) {
    const eLearningETestEEvaState = JSON.stringify(eTestEEvaState)
    localStorage.setItem(uuid, eLearningETestEEvaState)
  }
}
