import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'

import Score from '../Score'
import Question from '../Question'
import DragDropAnswer from '../DragDropAnswer'
import AddAnswer from '../AddAnswer'
import AlertErrorBox from '../AlertErrorBox'

import * as StyledForm from '../Styled'
import { StyledHeader } from './Styled'
import { getErrorMessage } from '../event'

const Matching = ({ keyQuestion, indexQuestion }) => {
  const errorSchemaMessage = useSelector(
    (state) => state.eTestingForm.errorSchemaMessage,
    shallowEqual
  )
  const answerErrorMessage = getErrorMessage({
    field: 'CHOICE_NUM',
    errorSchemaMessage,
    indexQuestion,
  })

  return (
    <Card>
      <StyledForm.BoxForm>
        <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
        {answerErrorMessage !== '' && (
          <Box mb={3}>
            <AlertErrorBox text={answerErrorMessage} />
          </Box>
        )}

        <Box>
          <StyledHeader>
            <Box sx={{ display: 'flex', minWidth: '50%' }}>
              <Typography color="text.secondary" variant="body1b">
                คำถาม{' '}
              </Typography>
              <Typography color="text.secondary" variant="body1">
                (สามารถเว้นว่างไว้ได้ เพื่อเป็นข้อหลอก)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography color="text.secondary" variant="body1b">
                คำตอบ{' '}
              </Typography>
              <Typography color="error.main" variant="span">
                *
              </Typography>
            </Box>
          </StyledHeader>

          <DragDropAnswer
            keyQuestion={keyQuestion}
            indexQuestion={indexQuestion}
          />
        </Box>

        <Box sx={{ ml: { sm: 0, md: 9 } }}>
          <AddAnswer label="เพิ่มคู่" keyQuestion={keyQuestion} />
        </Box>
      </StyledForm.BoxForm>

      <Score keyQuestion={keyQuestion} questionType="MATCHING" />
    </Card>
  )
}

export default Matching
