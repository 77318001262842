import { StyledViewCard } from '../../../../modules/Styled'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'

export const ViewUpdateAt = () => {
  return (
    <StyledViewCard isUploadLoading={false}>
      <UpdatedComponent />
    </StyledViewCard>
  )
}
