import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Column, StyledTypography } from '../../Styled'
import { StyledCarouselArrow, StyledCourseCard } from './Styled'
import './style.css'
import { handleSelectCourse } from './events'

const CourseList = () => {
  const dispatch = useDispatch()
  const data = useSelector(
    (state) => state.eLearningPathPreview.data,
    shallowEqual,
  )
  const courses = _.get(data, 'eLearningLearningPathCourses', [])
  return (
    <Column gap={1}>
      <Typography variant="h5" color="primary">
        รายการหลักสูตร ({courses.length})
      </Typography>
      <Box>
        <Carousel
          className="learning-path-course-list"
          swipeable
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          onClickItem={(_, item) => dispatch(handleSelectCourse(item))}
          renderArrowPrev={(onClickHandler) => (
            <CustomArrow onClick={onClickHandler} />
          )}
          renderArrowNext={(onClickHandler) => (
            <CustomArrow onClick={onClickHandler} isNext />
          )}
        >
          {courses.map((course, index) => (
            <CourseCard key={index} data={course} />
          ))}
        </Carousel>
      </Box>
    </Column>
  )
}

export default CourseList

export const CustomArrow = ({ onClick, isNext }) => {
  return (
    <StyledCarouselArrow isNext={isNext} color="primary" onClick={onClick}>
      {isNext ? <ChevronRight /> : <ChevronLeft />}
    </StyledCarouselArrow>
  )
}

export const CourseCard = ({ data }) => {
  const selectedCourse = useSelector(
    (state) => state.eLearningPathPreview.selectedCourse,
    shallowEqual,
  )
  const course = _.get(data, 'course', {})
  const coverImage = _.get(course, 'coverImage', course.imageKey)
  return (
    <StyledCourseCard
      data-testid={`course-${_.get(data, 'sequence', 1)}`}
      type={data.type}
      selected={selectedCourse === course.uuid}
    >
      <img src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`} />
      <Box p={2}>
        <StyledTypography variant="body1b" sx={{ lineHeight: '24px' }}>
          {_.get(course, 'nameForLearner', '') ||
            _.get(course, 'name', '') ||
            _.get(course, 'englishName', '')}
        </StyledTypography>
      </Box>
    </StyledCourseCard>
  )
}
