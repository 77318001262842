import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CheckCircle, ErrorOutline } from '@mui/icons-material'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../components/redux/PopUpDialog/Styled'
import _ from 'lodash'
import { closeDialog, setEnableButton } from '../../../../../../redux/slices/dialog'
import {
  TableCell,
  TableRow,
  TableBody,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Table,
  TableHead,
} from '@mui/material'
import { headerUpload } from '../../../model/headerUpload'

import { useTheme } from '@mui/system'
import { handleSubmitImport } from '../../../handler/handleSubmitImport'
import { handleEnableButton } from '../../../handler/handleEnableButton'
import { TableContent } from './TableContent'

export const CheckDialog = () => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))

  const { importFile } = useSelector(
    (state) => ({
      importFile: state.crud.formData.importFile,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const theme = useTheme()
  const results = _.get(importFile, 'uploadResult', [])
  const successCount = useMemo(() => {
    const successList = results.filter(
      (item) => item.statusUpload === 'SUCCESS',
    )
    if (_.size(successList) == _.size(results)) {
      dispatch(setEnableButton())
    }

    return successList.length
  }, [results])
  const uploadSuccess = successCount === results.length

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <Box display="flex" flexDirection="column" gap={2} mx={3} my={1}>
        {!uploadSuccess && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              สำเร็จ{' '}
              <Typography component="span" color="text.success">
                {successCount} รายการ
              </Typography>
              {' , '}
              ไม่สำเร็จ{' '}
              <Typography component="span" color="error">
                {_.size(results) - successCount} รายการ
              </Typography>
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            borderRadius: 2,
            overflow: 'auto',
            border: `1px solid ${theme.palette?.blue?.blueLine}`,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {_.map(headerUpload, (header, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      minWidth: 130,
                      p: 1,
                    }}
                  >
                    <Typography>{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableContent results={results} />
            </TableBody>
          </Table>
        </Box>

        {successCount > 0 && successCount != _.size(results) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => handleEnableButton(e)}
                />
              }
              label="เพิ่มรายการที่สามารถเพิ่มได้"
            />
          </Box>
        )}
      </Box>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-cancel"
            onClick={() => dispatch(closeDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-save"
            loading={isLoading}
            onClick={() => handleSubmitImport()}
            color="primary"
            disabled={disableButton}
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">สามารถเพิ่มตารางสอบได้ทั้งหมด</Typography>
    </Box>
  )
}
