import React, { useMemo } from 'react'
import _ from 'lodash'
import Switch from '@mui/material/Switch'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import { checkETestEEvaInTime, handleToggleETestEEva } from './events'
import Remove from '@mui/icons-material/Remove'
import { hasPermissionDefaultAndMonthlyPlanArea } from '../../../../../utils/lib'

const ETestEEvaSwitch = ({ row }) => {
  const { id: trainingPlanUuid } = useParams()
  const isInTime = checkETestEEvaInTime(row)
  const type = _.get(row, 'type', 'Pre Test')
  const testAndEvaluateType = _.get(row, 'testAndEvaluateType', 'E_TESTING')
  const expiredDate = _.get(row, 'expiredDate', null)
  const { isCancel, manageClassData } = useSelector(
    (state) => ({
      isCancel: state.manageClassDetail.isCancel,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual
  )
  const [user] = useLocalStorage('user')
  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: manageClassData,
  })
  const isOpen = useMemo(() => {
    if (testAndEvaluateType == 'E_TESTING') {
      return _.get(row, 'isETestingOpen', false)
    } else {
      return _.get(row, 'isEEvaluationOpen', false)
    }
  }, [row])
  const isStart = expiredDate ? isInTime : isOpen
  const toggleProps = { row, trainingPlanUuid }
  const isClosed = _.get(manageClassData, 'status', 'UNASSIGN') == 'CLOSED'
  const dispatch = useDispatch()

  if (type == 'Assessment') {
    return <Remove sx={{ color: 'text.silver', ml: 2, mt: 1 }} fontSize="18" />
  }

  return (
    <Switch
      inputProps={{ 'data-testid': 'switch-status' }}
      disabled={
        (isInTime && expiredDate) || !hasPermissionEdit || isCancel || isClosed
      }
      checked={isStart}
      onChange={(e) => dispatch(handleToggleETestEEva({ ...toggleProps, e }))}
    />
  )
}

export default ETestEEvaSwitch
