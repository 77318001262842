import axios from 'axios'
import _ from 'lodash'
import {
  setPayloadKey,
  startLoading2,
  stopLoading2,
} from '../../../../../redux/slices/manage/prospectProfile'
import { agentProfileETetsingView } from '../../../../../utils/apiPath'
import { typeOfETesting } from '../../../../..//constants/eTesting'
import { convertFormatDateTime } from '../../../../..//utils/lib'

export const fetchETesting = (body) => async (dispatch) => {
  dispatch(startLoading2())
  const response = await axios.post(agentProfileETetsingView, body)
  const result = _.get(response.data, 'result', []).map((item) => ({
    testResultTH: item.testResultTH,
    updatedAt: convertFormatDateTime({
      value: item.endTestDateTime,
      type: 'date',
    }),
    testName: _.get(item, 'reportEtesting.testName', ''),
    uuid: _.get(item, 'reportEtesting.eTestingVersion.uuid', ''),
    version: _.get(item, 'reportEtesting.eTestingVersion.version', ''),
    typeOfETesting: typeOfETesting[item.reportEtesting?.typeOfETesting] || '-',
  }))
  const totalCount = _.get(response.data, 'totalCount', 0)
  dispatch(setPayloadKey({ key: 'eTesting', data: { result, totalCount } }))
  dispatch(stopLoading2())
}
