import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { overviewEnum } from '../enum/enum'
import { getEContentDashboard } from '../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { eContentDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      eContentDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      eContentDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(getEContentDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'eContentDashboard.overview',
          value: { ...overviewEnum, ...data },
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
