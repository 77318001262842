import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import appHistory from '../../../../../appHistory'
import { crudConfig } from '../../crudConfig'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/system'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { useDispatch } from 'react-redux'

export function ActionComponent({ uuid, formData }) {
  const [user] = useLocalStorage('user')
  const hasMasterData = validatePermission({
    user,
    moduleType: 'MASTER_DATA',
    permission: ['CREATE', 'DELETE'],
  })
  const dispatch = useDispatch()

  return (
    <Box display="flex" flexDirection="row" gap={3}>
      <Button
        startIcon={<RestoreTwoToneIcon />}
        variant="outlined"
        onClick={() =>
          dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: true }))
        }
      >
        ประวัติการแก้ไข
      </Button>
      {formData.status !== 'DELETED' && hasMasterData && (
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          onClick={() =>
            appHistory.push(crudConfig.modulePath + '/form/' + uuid)
          }
          data-testid="btn-edit"
        >
          แก้ไข
        </Button>
      )}
    </Box>
  )
}
