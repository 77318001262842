import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  transactions: {
    totalTransactions: 0,
    totalRecords: 0,
    manual: {
      transactions: 0,
      records: 0,
    },
    admin: {
      transactions: 0,
      records: 0,
    },
    auto: {
      transactions: 0,
      records: 0,
    },
    failed: {
      transactions: 0,
      records: 0,
    },
  },
  LATEST_COURSES: [],
  TOP_COURSES: [],
  LATEST_LICENSES: [],
  TOP_LICENSES: [],
}

export const topDrawerEnum = {
  isOpen: false,
  drawerType: '',
  countTotal: 0,
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  filterTotal: 0,
  courseUuid: '',
  platformLearning: '',
}
