import _ from 'lodash'
import { store } from '../../../../../../App'
import { fetchDataForm } from '../../../../../../modules/EEvaluation/Form/event'
import {
  startLessonLoading,
  stopLessonLoading,
} from '../../../../../../redux/slices/eLearning/preview'

export const handleFetchEEvaluation = (uuid, setEEva) => async (dispatch) => {
  const latestEEvaState = localStorage.getItem(uuid)
  if (!_.isNil(latestEEvaState)) {
    localStorage.removeItem(uuid)
    return
  }

  dispatch(startLessonLoading())
  await dispatch(fetchDataForm({ uuid, isDraft: false, setOnEdit: () => {} }))

  const stateEvaluation = store.getState().eEvaluationForm
  if (stateEvaluation) setEEva(stateEvaluation)
  dispatch(stopLessonLoading())
}
