import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import styled from '@mui/system/styled'

export const BoxLabel = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}))

export const BoxSelect = styled(Box)(() => ({
  flexDirection: 'column',
  width: '100%',
}))

export const StyleSelect = styled(Select)(({ theme }) => ({
  '& > .MuiSvgIcon-root': {
    color: theme?.palette?.primary?.main,
  },
}))
