import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter, Loading } from '../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenLearnerDrawer } from '../../../../../../../redux/slices/eLearning/dashboardByCourse'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { E_LEARNING_LEARNER_TYPE_LABEL } from '../../../../../../../constants/eLearning'
import Table from '../../../../../../../components/redux/Table'
import { useTheme } from '@mui/system'
import CustomChipLabel from './CustomChipLabel'
import StatusCheckBox from './StatusCheckBox'

const LearnerDrawer = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const {
    isOpenLearnerDrawer,
    learnerTypeSelected,
    allCount,
    isLoadingDrawer,
  } = useSelector(
    (state) => ({
      isOpenLearnerDrawer: state.eLearningDashboardByCourse.isOpenLearnerDrawer,
      learnerTypeSelected: state.eLearningDashboardByCourse.learnerTypeSelected,
      allCount: state.table.table.allCount,
      isLoadingDrawer: state.eLearningDashboardByCourse.isLoadingDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{
        '& .MuiPaper-root': {
          width: '95vw',
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      open={isOpenLearnerDrawer}
      onClose={() => dispatch(setIsOpenLearnerDrawer(false))}
    >
      <StyledHeadLabel sx={{ mb: 1.25 }}>
        <Box>
          <Typography variant="h5">
            รายชื่อผู้เรียน Enroll (
            {E_LEARNING_LEARNER_TYPE_LABEL[learnerTypeSelected]})
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenLearnerDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <Box
        sx={{
          opacity: isLoadingDrawer ? 0.4 : 'unset',
          pointerEvents: isLoadingDrawer ? 'none' : 'unset',
        }}
        display="flex"
        flexDirection="column"
        mx={3}
        gap={1}
      >
        <CustomChipLabel
          boxSx={{ display: 'inline-flex' }}
          label={`รายชื่อทั้งหมด ${allCount} คน`}
        />
        <StatusCheckBox />
        <Box
          sx={{
            border: `1px solid ${theme.palette.blue.blueLine}`,
            borderRadius: theme.spacing(2),
          }}
        >
          <Table />
        </Box>
      </Box>
      <Loading isLoading={isLoadingDrawer} />

      <StyledFooter>
        <Button
          sx={{ ml: 'auto' }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => dispatch(setIsOpenLearnerDrawer(false))}
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default LearnerDrawer
