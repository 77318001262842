import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import { prospectProfileUpdate } from '../../../../utils/apiPath'
import { openDialog, closeDialog } from '../../../../redux/slices/dialog'
import {
  startLoading,
  stopLoading,
  setErrors,
} from '../../../../redux/slices/manage/prospectProfile'
import { formatDateDisplay } from '../events'
import { checkIdCard } from '../../../../utils/lib'

export function handleScroll(setHeaderStyle) {
  return function () {
    if (window.scrollY > 30) {
      setHeaderStyle(true)
    } else {
      setHeaderStyle(false)
      closeDialog()
    }
  }
}

export const filterEmptyObjects = (arr) =>
  arr.filter((obj) => Object.values(obj).some((val) => val !== ''))

export const handleSubmit = (data, uuid, type) => (dispatch) => {
  const firstNameTH = _.get(data, 'info.firstNameTH', '')
  const lastNameTH = _.get(data, 'info.lastNameTH', '')
  const mobileNo = _.get(data, 'contact.mobileNo', '')
  const email = _.get(data, 'contact.email', '')
  const image = _.get(data, 'info.image', '')
  const prefixTH = _.get(data, 'info.prefixTH', '')
  const dateOfBirth = _.get(data, 'info2.dateOfBirth', '')
  const idCardNo = _.get(data, 'info2.idCardNo', '')
  const educational = _.get(data, 'educational', [])
  const division = _.get(data, 'division', '')
  const prefix = _.get(data, 'info.prefix', '')
  const firstName = _.get(data, 'info.firstName', '')
  const lastName = _.get(data, 'info.lastName', '')
  const institution = _.get(data, 'institution', '')
  const qualification = _.get(data, 'qualification', '')
  const between = _.get(data, 'between', '')
  const branch = _.get(data, 'branch', '')
  const gpa = _.get(data, 'gpa', '')

  const result = filterEmptyObjects(educational)

  const body = {
    prospectUuid: uuid,
    image: image,
    prefixTH: prefixTH,
    firstNameTH: firstNameTH,
    lastNameTH: lastNameTH,
    mobileNo: mobileNo,
    dateOfBirth: formatDateDisplay(dateOfBirth),
    idCardNo: idCardNo,
    educational: result,
    division: division,
    prefix: prefix,
    firstName: firstName,
    lastName: lastName,
    email: email,
    institution: institution,
    qualification: qualification,
    between: between,
    branch: branch,
    gpa: gpa,
  }

  const isValidateCS =
    (_.isEmpty(idCardNo) ||
      !dayjs(dateOfBirth).isValid() ||
      mobileNo.length !== 10) &&
    type !== 'CS'

  const isIdCardNo =
    !checkIdCard(idCardNo) && (type !== 'CS' || !_.isEmpty(idCardNo))

  /* eslint-disable no-useless-escape */
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const isEmail = regex.test(email)

  if (
    _.isEmpty(firstNameTH) ||
    _.isEmpty(lastNameTH) ||
    _.isEmpty(firstName) ||
    _.isEmpty(lastName)
  ) {
    return
  }

  if (_.isEmpty(email) || isValidateCS || isIdCardNo || !isEmail) {
    let errors = {}
    if(_.isEmpty(email) || !isEmail) errors = { ...errors, email: 'กรุณากรอกข้อมูลให้ถูกต้อง'}
    if(isIdCardNo) errors = { ...errors, idCardNo: 'กรุณากรอกข้อมูลให้ถูกต้อง'}
    dispatch(setErrors(errors))
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ผิดพลาด',
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      }),
    )
  } else {
    dispatch(
      openDialog({
        open: false,
        variant: 'save',
        title: 'ยืนยันบันทึกข้อมูล',
        icon: '',
        message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        handleConfirm: () => {
          dispatch(handleSendData(body, uuid, type))
          dispatch(closeDialog())
        },
      }),
    )
  }
}

export const handleSendData = (body, uuid, type) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(setErrors({}))
  await axios
    .put(prospectProfileUpdate, body)
    .then((res) => {
      if(res?.data?.errors?.length > 0){
        let errors = {}
        _.get(res?.data, 'errors', []).map((item) => {
          if(item?.property === 'DuplicateMobileNo'){
            errors = { ...errors, mobileNo: item?.constraints?.value}
          }
          if(item?.property === 'DuplicateIdCardNo'){
            errors = { ...errors, idCardNo: item?.constraints?.value}
          }
          if(item?.property === 'DuplicateEmail'){
            errors = { ...errors, email: item?.constraints?.value}
          }
        })
        dispatch(setErrors(errors))
        dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ไม่สามารถบันทึกได้ เนื่องจากมีข้อมูลในระบบแล้ว',
            handleConfirm: () => {
              dispatch(closeDialog())
            },
          }),
        )
      }else{
        dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              dispatch(closeDialog())
              handleView(uuid, type)
            },
          }),
        )
      }
    })
    .catch(() => {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
          handleConfirm: () => {
            dispatch(closeDialog())
            handleView(uuid, type)
          },
        }),
      )
    })
  dispatch(stopLoading())
}

export const handleView = (uuid, type) => {
  if (type === 'CS') {
    window.location.href = `/manage/cs-user-profile/view/${uuid}`
  } else {
    window.location.href = `/manage/prospect-profile/view/${uuid}`
  }
}
