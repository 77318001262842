import { Box, TableCell, TableRow, Typography } from '@mui/material'
import TextInput from '../../../../../../components/Input/TextInput'
import { handleChangeInput } from '../../../handler/handleChangeInput'
import _ from 'lodash'
import { useTheme } from '@mui/system'
import { shallowEqual, useSelector } from 'react-redux'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { formattedNumber } from '../../../../../../modules/BudgetManagement/utils'

export const RowItem = ({
  required = false,
  label,
  idx,
  idxBudget,
  type,
  keyChange,
  isCurrentMonth,
}) => {
  const theme = useTheme()
  const { formData, error } = useSelector(
    (state) => ({
      formData: state.crud.formData,
      error: _.find(state.crud.formErrors, {
        path: `budgetDetails[${idxBudget}].data[${idx}].value`,
      }),
    }),
    shallowEqual,
  )
  const value = _.get(
    formData,
    `budgetDetails[${idxBudget}].data[${idx}].value`,
    '',
  )

  return (
    <TableRow>
      <TableCell>
        <Box display="flex">
          <Typography
            variant="body1"
            color={isCurrentMonth ? 'text.primary' : 'primary'}
          >
            {label}
          </Typography>
          {required && (
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>
        {type === 'edit' ? (
          <TextInput
            sx={{
              border: !_.isEmpty(error)
                ? `1px solid ${theme?.palette?.error?.main}`
                : `1px solid ${theme?.palette?.text?.silver}`,
            }}
            boxSx={{ maxWidth: 100 }}
            sxTextError={{ mb: 0 }}
            type="basic"
            id={`row-${idx + 1}`}
            name={`row-${idx + 1}`}
            placeholder="ระบุ"
            isShowTextError={false}
            value={value}
            inputProps={{
              'data-testid': 'input-budget',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
            onChange={(e) => {
              handleChangeInput(e, idx, idxBudget, keyChange)
            }}
          />
        ) : (
          <Box minWidth={100}>
            <Typography>
              {!_.isNull(value) ? formattedNumber(value) : '-'}
            </Typography>
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}
