import React from 'react'
import { Box, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledCard } from './Styled'
import UploadFileComp from '../../../../components/UploadFile'

const Index = () => {
  const { meetingRoomImages } = useSelector(
    (state) => ({
      meetingRoomImages:
        state.masterDataMeetingRoomForm.information.meetingRoomImages,
    }),
    shallowEqual,
  )

  return (
    <StyledCard>
      <Box>
        <Typography variant="h6">รูปภาพห้องประชุม</Typography>
        <Typography variant="body2">ขนาดแนะนำ 1200 x 900 px</Typography>
      </Box>
      <UploadFileComp
        sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
        isMultiple
        isShowDownloadable={false}
        isForImage
        folder="meeting-room"
        maxFileSize={{ size: 2, sizeType: 'MB' }}
        maxLength={10}
        accept={{
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
        }}
        acceptLabel=".jpg, .jpeg, .png"
        fieldName="meetingRoomImages"
        value={meetingRoomImages}
      />
    </StyledCard>
  )
}

export default Index
