import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import HourglassBottom from '@mui/icons-material/HourglassBottomTwoTone'

import dayjs from 'dayjs'
import {
  postManageClassExamList,
  postStandaloneExamList,
} from '../../services/manageClass/check'
import * as slicesTable from '../../redux/slices/table'
import { headCellStandalone, headCells } from './model'
import { store } from '../../App'

export const fetchDataList = (props) => async (dispatch) => {
  dispatch(slicesTable.startLoading())

  const { method, page, keyword, setInitial, isStandalone } = props
  const { table, filterProp } = store.getState().table
  const isInitial = method == 'initial'
  const tableProps = table
  const sortBy = tableProps.sort
  const realPage = page <= 0 ? 1 : +page
  const filter = isInitial ? null : filterProp
  const eTestingName = isInitial ? '' : _.get(filter, 'eTestingName', keyword)

  let result = null
  if (isStandalone) {
    const body = {
      codeId: _.get(filter, 'codeId', ''),
      name: method == 'filter' ? keyword : eTestingName,
      createdBy: _.get(filter, 'createdBy', ''),
      latestCheckedBy: _.get(filter, 'latestCheckedBy', ''),
      createdDate: _.get(filter, 'createdDate', ''),
      toCreatedDate: _.get(filter, 'toCreatedDate', ''),
      status: _.get(filter, 'status', []),
      page: realPage,
      limit: tableProps.limit,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy === 'updatedAt' ? 'default' : sortBy,
    }

    result = await dispatch(postStandaloneExamList(body))
  } else {
    const body = {
      page: realPage,
      limit: tableProps.limit,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy === 'updatedAt' ? 'finishDate' : sortBy,
      eTestingName: method == 'filter' ? keyword : eTestingName,
      className: _.get(filter, 'className', ''),
      startDate: _.get(filter, 'startDate', ''),
      toStartDate: _.get(filter, 'toStartDate', ''),
      endDate: _.get(filter, 'endDate', ''),
      toEndDate: _.get(filter, 'toEndDate', ''),
    }

    result = await dispatch(postManageClassExamList(body))
  }
  const meta = _.get(result, 'meta', {})
  const isCancel =
    meta.requestStatus === 'rejected' && _.isUndefined(meta.response)
  dispatch(
    slicesTable.setInitialTable({
      rows: mapDataTable(_.get(result, 'payload.data.result', [])),
      allCount: _.get(result, 'payload.data.totalCount', 0),
      headCells: isStandalone ? headCellStandalone : headCells,
      placeholder: 'ค้นหาชื่อแบบทดสอบ',
      handleSearch: (text) =>
        dispatch(handleQuickSearch(tableProps, text, isStandalone)),
      onDownload: 'hidden',
      searchKey: 'eTestingName',
      isCheckBox: false,
    }),
  )

  setInitial && setInitial(false)
  if (!isCancel) dispatch(slicesTable.stopLoading())
}

export const onSubmitFilter = (table, filter, isStandalone) => (dispatch) => {
  dispatch(slicesTable.setSearchText(''))
  let filterProp = {
    eTestingName: filter.eTestingNameChecked ? filter.eTestingName : '',
    className: filter.classNameChecked ? filter.className : '',
    startDate: filter.startDateChecked
      ? dayjs(filter.startDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    toStartDate: filter.startDateChecked
      ? dayjs(filter.toStartDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',

    endDate: filter.endDateChecked
      ? dayjs(filter.endDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    toEndDate: filter.endDateChecked
      ? dayjs(filter.toEndDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
  }
  if (isStandalone) {
    const status = []
    if (filter.statusChecked) {
      if (filter.status?.waitingChecked) status.push('WAITING')
      if (filter.status?.doneChecked) status.push('DONE')
    }
    filterProp = {
      codeId: filter.codeIdChecked ? filter.codeId : '',
      eTestingName: filter.eTestingNameChecked ? filter.eTestingName : '',
      createdBy: filter.createdByChecked ? filter.createdBy : '',
      latestCheckedBy: filter.updatedByChecked ? filter.updatedBy : '',
      createdDate: filter.startDateChecked
        ? dayjs(filter.startDate).format(window.__env__.REACT_APP_DATE_DB)
        : '',
      toCreatedDate: filter.startDateChecked
        ? dayjs(filter.toStartDate).format(window.__env__.REACT_APP_DATE_DB)
        : '',
      status,
    }
  }

  dispatch(slicesTable.setFilterProp(filterProp))
  dispatch(slicesTable.resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      filterProp,
      keyword: filterProp.eTestingName,
      isStandalone,
    }),
  )
  dispatch(slicesTable.setToggleFilterDrawer(false))
}

export const handleQuickSearch = (table, text, isStandalone) => (dispatch) => {
  dispatch(slicesTable.setSearchText(text))
  dispatch(slicesTable.setFilterProp(null))
  dispatch(slicesTable.resetTablePage())

  dispatch(
    fetchDataList({
      method: 'search',
      table,
      page: 1,
      filterProp: null,
      keyword: text,
      isStandalone,
    }),
  )
}

export const mapDataTable = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    const eTestingType = _.get(item, 'eTestingType', '')
    return {
      ...item,
      setChecked:
        eTestingType === 'ASSESSMENT'
          ? '-'
          : `${item.checkedExamCount}/${item.submitCount}`,
      waitingToCheckExamCount: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HourglassBottom
            fontSize="20px"
            sx={{
              color:
                item.waitingToCheckExamCount > 0
                  ? 'warning.main'
                  : 'silver.primary',
            }}
          />
          <Typography variant="body2" sx={{ mt: 0.5, ml: 1 }}>
            {item.waitingToCheckExamCount}
          </Typography>
        </Box>
      ),
    }
  })
}
