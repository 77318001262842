export const arrayStatus = [
  {
    label: 'Unassigned',
    name: 'unassignChecked',
  },
  {
    label: 'To Do',
    name: 'todoChecked',
  },
  {
    label: 'In Progress',
    name: 'inProgressChecked',
  },
  {
    label: 'Closed',
    name: 'closedChecked',
  },
  {
    label: 'Cancelled',
    name: 'cancelChecked',
  },
]

export const bottomArrayCheckbox = [
  {
    label: 'Admin หลักและรอง',
    nameCheckBox: 'assignNeeChecked',
    nameInput: 'assignNee',
    placeholder: 'ค้นหา',
  },
]

export const arrayCheckbox = [
  {
    label: 'รหัสหลักสูตร',
    nameCheckBox: 'courseCodeChecked',
    nameInput: 'courseCode',
    placeholder: 'ค้นหา',
  },
  {
    label: 'ชื่อหลักสูตร (EN)',
    nameCheckBox: 'courseChecked',
    nameInput: 'course',
    placeholder: 'ค้นหา',
  },
  {
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    nameCheckBox: 'nameForLearnerChecked',
    nameInput: 'nameForLearner',
    placeholder: 'ค้นหา',
  },
  {
    label: 'วันที่เปิดรับสมัคร',
    nameCheckBox: 'startRegisterChecked',
    nameInput: 'startRegister',
    dateStateStart: 'startRegister',
    dateStateFinish: 'finishRegister',
    dateStateDisplay: 'displayDateRegister',
    placeholder: 'ค้นหา',
    isDate: true,
  },
  {
    label: 'วันที่จัดอบรม',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    dateStateStart: 'startDate',
    dateStateFinish: 'finishDate',
    dateStateDisplay: 'displayDate',
    placeholder: 'ค้นหา',
    isDate: true,
  },
]

export const defaultFilter = {
  statusChecked: false,
  status: {
    unassignChecked: false,
    todoChecked: false,
    inProgressChecked: false,
    closedChecked: false,
    cancelChecked: false,
  },
  waitingExam: false,
  checkDocuments: false,
  myJob: false,
  highClass: false,
  courseCodeChecked: false,
  courseCode: '',
  courseChecked: false,
  course: '',
  nameForLearnerChecked: false,
  nameForLearner: '',
  startRegisterChecked: false,
  startRegister: '',
  finishRegister: '',
  displayDateRegister: false,
  startDateChecked: false,
  startDate: '',
  finishDate: '',
  displayDate: false,
  trainingPlatFormChecked: false,
  trainingPlatForm: {},
  assignNeeChecked: false,
  assignNee: '',
}
