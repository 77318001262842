import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledProgressMilestone = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: 30,
  width: 260,
  border: `1px solid ${theme?.palette?.grey?.grey100}`,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme?.palette?.other?.milestoneGrey,
  '& > .MuiTypography-root': {
    position: 'absolute',
    zIndex: 99,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

export const StyledProgress = styled(Box)(({ theme, value }) => ({
  position: 'absolute',
  width: `${value}%`,
  height: '100%',
  borderRadius: value == 100 ? theme.spacing(0.5) : '4px 0 0 4px',
  backgroundColor: theme?.palette?.success?.light,
}))
