import React, { Fragment } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import {
  StyledImagePreview,
  StyledChoiceContainer,
  StyledInfoAlert,
} from '../../../../../../../components/Preview/QuestionCard/Styled'
import TextInput from '../../../../../../../components/Input/TextInput'
import DragAndDrop from './DragAndDrop'
import {
  handleMultipleChange,
  handleFreeTextChange,
  handleSingleChange,
  shouldDisableCheckbox,
} from './events'
import { StyledChoice } from './Styled'
import FillChoice from './FillChoice'
import Matching from './Matching'
import Sequence from './Sequence'
import TrueFalse from './TrueFalse'

export const FreeTextInfoAlert = () => {
  return (
    <StyledInfoAlert>
      <InfoOutlined color="warning" />
      <Typography variant="body1b">รอการตรวจจากวิทยากร</Typography>
    </StyledInfoAlert>
  )
}

const Choice = ({ keyQuestion }) => {
  const { question, isSubmit, isLoading, questionResult } = useSelector(
    (state) => ({
      question: state.eTestingPreview.testing.find(
        (item) => item.key == keyQuestion
      ),
      isSubmit: state.eTestingPreview.isSubmit,
      isLoading: state.eTestingPreview.isLoading,
      questionResult: state.eTestingPreview.setting.questionResult,
    }),
    shallowEqual
  )

  const { choices, answer, type } = question
  const resultProps = { isSubmit, isLoading, questionResult }
  const dispatch = useDispatch()

  if (type === 'TRUE_FALSE') {
    return (
      <StyledChoiceContainer>
        <TrueFalse
          keyQuestion={keyQuestion}
          question={question}
          isSubmit={isSubmit}
          questionResult={questionResult}
        />
      </StyledChoiceContainer>
    )
  }

  if (type === 'SEQUENCE') {
    return (
      <StyledChoiceContainer>
        <Sequence
          keyQuestion={keyQuestion}
          answer={answer}
          isSubmit={isSubmit}
          questionResult={questionResult}
        />
      </StyledChoiceContainer>
    )
  }

  if (type === 'MATCHING') {
    return (
      <StyledChoiceContainer>
        <Matching
          keyQuestion={keyQuestion}
          question={question}
          isSubmit={isSubmit}
          questionResult={questionResult}
        />
      </StyledChoiceContainer>
    )
  }

  if (type === 'FILL_IN_BLANK_DRAG') {
    return (
      <StyledChoiceContainer>
        <DragAndDrop
          keyQuestion={keyQuestion}
          question={question}
          isSubmit={isSubmit}
          questionResult={questionResult}
        />
      </StyledChoiceContainer>
    )
  }

  if (type === 'FILL_IN_BLANK_CHOICE') {
    return (
      <StyledChoiceContainer>
        <FillChoice
          keyQuestion={keyQuestion}
          question={question}
          isSubmit={isSubmit}
          questionResult={questionResult}
        />
      </StyledChoiceContainer>
    )
  }

  return (
    <StyledChoiceContainer>
      {type == 'FREE_TEXT' || choices.length == 0 ? (
        <>
          <TextInput
            type="basic"
            multiline
            rows={2}
            placeholder="ตอบ"
            isShowTextError={false}
            readOnly={isSubmit || isLoading}
            defaultValue={answer[0]}
            id={`free-text-${keyQuestion}`}
            onBlur={(e) => dispatch(handleFreeTextChange(e, keyQuestion))}
          />
          {isSubmit && (
            <FreeTextInfoAlert data-testid={`alert-text-${keyQuestion}`} />
          )}
        </>
      ) : (
        <RadioGroup
          onChange={(e) => dispatch(handleSingleChange(e, keyQuestion))}
        >
          {choices.map((choice, index) => (
            <Fragment key={index}>
              {type === 'SINGLE' && (
                <StyledChoice
                  control={<Radio />}
                  value={choice.uuid}
                  label={choice.title}
                  checked={answer[0] == choice.uuid}
                  resultProps={{
                    ...resultProps,
                    isAnswer: choice.isCorrect,
                  }}
                  data-testid={`choice-${keyQuestion}-${index}`}
                />
              )}

              {type === 'MULTIPLE' && (
                <StyledChoice
                  control={
                    <Checkbox
                      disabled={shouldDisableCheckbox(question, choice.uuid)}
                      onChange={(e) =>
                        dispatch(handleMultipleChange(e, index, keyQuestion))
                      }
                      inputProps={{
                        'data-testid': `choice-${keyQuestion}-${index}`,
                      }}
                    />
                  }
                  label={choice.title}
                  checked={answer[index].isChecked}
                  resultProps={{
                    ...resultProps,
                    isAnswer: choice.isCorrect,
                  }}
                />
              )}

              {type === 'TRUE_FALSE' && (
                <StyledChoice
                  control={
                    <Checkbox
                      disabled={shouldDisableCheckbox(question, choice.uuid)}
                      onChange={(e) =>
                        dispatch(handleMultipleChange(e, index, keyQuestion))
                      }
                      inputProps={{
                        'data-testid': `choice-${keyQuestion}-${index}`,
                      }}
                    />
                  }
                  label={choice.title}
                  checked={answer[index].isChecked}
                  resultProps={{
                    ...resultProps,
                    isAnswer: choice.isCorrect,
                  }}
                />
              )}

              {choice.mediaUrl && (
                <StyledImagePreview
                  choice
                  src={`${window.__env__.REACT_APP_API_URL}/file${choice.mediaUrl}`}
                />
              )}
            </Fragment>
          ))}
        </RadioGroup>
      )}
    </StyledChoiceContainer>
  )
}

export default Choice
