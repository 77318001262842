import React from 'react'
import { ChipBox, StyledChip, TagBox } from './styled'
import { Typography } from '@mui/material'

const BottomTagLine = ({ Tag }) => {
  return (
    <TagBox>
      <Typography>Tag</Typography>
      <ChipBox>
        {Tag?.map((item, index) => (
          <StyledChip key={index}>
            <Typography
              fontSize="18px"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '0px 6px',
              }}
            >
              {item.label}
            </Typography>
          </StyledChip>
        ))}
      </ChipBox>
    </TagBox>
  )
}

export default BottomTagLine
