import {
  setChangeQuestionByKey,
  setDeleteQuestion,
} from '../../../../../../redux/slices/eTestingForm'

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeQuestionByKey(props))
}

export const handleChangeScore = (props) => (dispatch) => {
  let score = props.value
  const regex = /^[0-9]*(\.[0-9]{0,2})?$/
  const checkRegex = regex.test(score)

  if (score > 100) score = 100
  if (!checkRegex) score = score.substring(0, score.length - 1)

  if (score === '') score = 0
  if (score.length >= 2 && score !== '0.') {
    const firstCheck = score.substring(0, score.length - 1)
    if (firstCheck === '0') {
      const lastCheck = score.substring(1, score.length)
      score = lastCheck
    }
  }

  dispatch(
    setChangeQuestionByKey({
      ...props,
      value: score,
    })
  )
}

export const handleDelete = (props) => (dispatch) => {
  dispatch(setDeleteQuestion(props))
}
