import { crudConfig } from '../../crudConfig'
import { getModuleTitle } from '../../utils'

export const breadcrumb = (uuid) => [
  { title: 'Expense', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: getModuleTitle(uuid, crudConfig.moduleTitleFull),
    link: `${crudConfig.modulePath}/form`,
    pointer: false,
  },
]
