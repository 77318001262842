import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportMixModuleConst = {
  COLUMN_NAME: {
    NO: 'no',
    PREFIX: 'prefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    LICENSE_NO: 'licenseNo',
    REG_RENEWAL_DATE: 'regRenewalDate',
    ID_CARD: 'idCard',
    UNIT_CODE: 'unitCode',
    UNIT_NAME: 'unitName',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    GA: 'ga',
    STATUS_AGENT: 'statusAgent',
    POSITION: 'position',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    AGENT_TYPE: 'agentType',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    DISTRIBUTION: 'distribution',
    CLASSROOM_NOT_START: 'classroomNotStart',
    CLASSROOM_IN_PROGRESS: 'classroomInProgress',
    CLASSROOM_COMPLETE: 'classroomComplete',
    ONLINE_NOT_START: 'onlineNotStart',
    ONLINE_IN_PROGRESS: 'onlineInProgress',
    ONLINE_COMPLETE: 'onlineComplete',
    ELEARNING_NOT_START: 'eLearningNotStart',
    ELEARNING_IN_PROGRESS: 'eLearningInProgress',
    ELEARNING_COMPLETE: 'eLearningComplete',
    START_CLASS_DATE_TIME: 'startClassDateTime',
    END_CLASS_DATE_TIME: 'endClassDateTime',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    TRAINING_PLATFORM: 'trainingPlatform',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    ACQUIRED_SKILL: 'acquiredSkill',
    COURSE_CODE: 'courseCode',
    COURSE_VERSION: 'courseVersion',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_FOR_LEANRER: 'courseNameForLearner',
    STATUS_CLASS: 'statusClass',
    TRAINER_ID_LIST: 'trainerIdList',
    CREATED_BY: 'createdBy',
    UPDATED_BY: 'updatedBy',
    BANK_CODE: 'bankCode',
    STATUS_COURSE: 'statusCourse',
    PERIOD_START_COURSE: 'periodStartCourse',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    PREFIX: 'Title Name EN',
    FIRST_NAME: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    LICENSE_NO: 'License No.',
    REG_RENEWAL_DATE: 'Reg. Renewal Date',
    ID_CARD: 'เลขบัตรประชาชน',
    UNIT_CODE: 'Unit Code',
    UNIT_NAME: 'ชื่อ Unit',
    RAM_CODE: 'RAM Code',
    RAM_NAME: 'RAM Name',
    GA: 'GA',
    STATUS_AGENT: 'สถานะการทำงาน',
    POSITION: 'Position',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    AGENT_TYPE: 'ประเภท Agent',
    FULL_AGENT_CODE: 'รหัส Agent แบบเต็ม',
    AGENT_CODE: 'รหัส Agent',
    DISTRIBUTION: 'ช่องทางการจัดจำหน่าย',
    CLASSROOM_NOT_START: 'Classroom Not Start',
    CLASSROOM_IN_PROGRESS: 'Classroom In Progress',
    CLASSROOM_COMPLETE: 'Classroom Complete',
    ONLINE_NOT_START: 'Online Not Start',
    ONLINE_IN_PROGRESS: 'Online In Progress',
    ONLINE_COMPLETE: 'Online Complete',
    ELEARNING_NOT_START: 'E-Learning Not Start',
    ELEARNING_IN_PROGRESS: 'E-Learning In Progress',
    ELEARNING_COMPLETE: 'E-Learning Complete',
    START_CLASS_DATE_TIME: 'วันเริ่มต้น (Training Date)',
    END_CLASS_DATE_TIME: 'วันสิ้นสุด (Training Date)',
    CREATED_AT: 'วันที่สร้าง',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    TRAINING_PLATFORM: 'รูปแบบการสอน',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อช่องทางการจัดจำหน่าย',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับผู้เรียนของผู้เรียนช่องทางการจัดจำหน่ายอื่น',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_VERSION: 'เวอร์ชั่น',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_FOR_LEANRER: 'ชื่อหลักสูตร (สำหรับ Learner)',
    STATUS_CLASS: 'สถานะคอร์ส',
    TRAINER_ID_LIST: 'แอดมินประจำคลาส',
    CREATED_BY: 'สร้างโดย',
    UPDATED_BY: 'แก้ไขล่าสุดโดย',
    BANK_CODE: 'Bank Code',
    STATUS_COURSE: 'การเปิดใช้งาน',
    PERIOD_START_COURSE: 'วันที่เริ่มต้นหลักสูตร',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  agentTypeOptions: [
    { label: 'A', value: 'A' },
    { label: 'L', value: 'L' },
  ],
  trainingPlatformOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Virtual Classroom', value: 'Virtual Classroom' },
    { label: 'E-Learning', value: 'E-Learning' },
  ],
  statusCourseOptions: [
    { label: 'เปิดใช้งาน', value: 'ACTIVE' },
    { label: 'ปิดใช้งาน', value: 'INACTIVE' },
  ],
  statusAgentOptions: [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ],
}

export const reportMixModuleDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportMixModuleConst.COLUMN_NAME.NO,
      value: reportMixModuleConst.COLUMN_NAME.NO,
      label: reportMixModuleConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportMixModuleConst.COLUMN_NAME.PREFIX_TH,
      value: reportMixModuleConst.COLUMN_NAME.PREFIX_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportMixModuleConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportMixModuleConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportMixModuleConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportMixModuleConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportMixModuleConst.COLUMN_NAME.PREFIX,
      value: reportMixModuleConst.COLUMN_NAME.PREFIX,
      label: reportMixModuleConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportMixModuleConst.COLUMN_NAME.FIRST_NAME,
      value: reportMixModuleConst.COLUMN_NAME.FIRST_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportMixModuleConst.COLUMN_NAME.LAST_NAME,
      value: reportMixModuleConst.COLUMN_NAME.LAST_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportMixModuleConst.COLUMN_NAME.LICENSE_NO,
      value: reportMixModuleConst.COLUMN_NAME.LICENSE_NO,
      label: reportMixModuleConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportMixModuleConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportMixModuleConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportMixModuleConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportMixModuleConst.COLUMN_NAME.ID_CARD,
      value: reportMixModuleConst.COLUMN_NAME.ID_CARD,
      label: reportMixModuleConst.COLUMN_NAME_TH.ID_CARD,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportMixModuleConst.COLUMN_NAME.UNIT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.UNIT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportMixModuleConst.COLUMN_NAME.UNIT_NAME,
      value: reportMixModuleConst.COLUMN_NAME.UNIT_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportMixModuleConst.COLUMN_NAME.RAM_CODE,
      value: reportMixModuleConst.COLUMN_NAME.RAM_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportMixModuleConst.COLUMN_NAME.RAM_NAME,
      value: reportMixModuleConst.COLUMN_NAME.RAM_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportMixModuleConst.COLUMN_NAME.GA,
      value: reportMixModuleConst.COLUMN_NAME.GA,
      label: reportMixModuleConst.COLUMN_NAME_TH.GA,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportMixModuleConst.COLUMN_NAME.STATUS_AGENT,
      value: reportMixModuleConst.COLUMN_NAME.STATUS_AGENT,
      label: reportMixModuleConst.COLUMN_NAME_TH.STATUS_AGENT,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportMixModuleConst.COLUMN_NAME.POSITION,
      value: reportMixModuleConst.COLUMN_NAME.POSITION,
      label: reportMixModuleConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportMixModuleConst.COLUMN_NAME.MOBILE_NO,
      value: reportMixModuleConst.COLUMN_NAME.MOBILE_NO,
      label: reportMixModuleConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportMixModuleConst.COLUMN_NAME.EMAIL,
      value: reportMixModuleConst.COLUMN_NAME.EMAIL,
      label: reportMixModuleConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportMixModuleConst.COLUMN_NAME.AGENT_TYPE,
      value: reportMixModuleConst.COLUMN_NAME.AGENT_TYPE,
      label: reportMixModuleConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportMixModuleConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportMixModuleConst.COLUMN_NAME.AGENT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.AGENT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      value: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      label: reportMixModuleConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportMixModuleConst.COLUMN_NAME.CLASSROOM_NOT_START,
      value: reportMixModuleConst.COLUMN_NAME.CLASSROOM_NOT_START,
      label: reportMixModuleConst.COLUMN_NAME_TH.CLASSROOM_NOT_START,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportMixModuleConst.COLUMN_NAME.CLASSROOM_IN_PROGRESS,
      value: reportMixModuleConst.COLUMN_NAME.CLASSROOM_IN_PROGRESS,
      label: reportMixModuleConst.COLUMN_NAME_TH.CLASSROOM_IN_PROGRESS,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportMixModuleConst.COLUMN_NAME.CLASSROOM_COMPLETE,
      value: reportMixModuleConst.COLUMN_NAME.CLASSROOM_COMPLETE,
      label: reportMixModuleConst.COLUMN_NAME_TH.CLASSROOM_COMPLETE,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportMixModuleConst.COLUMN_NAME.ONLINE_NOT_START,
      value: reportMixModuleConst.COLUMN_NAME.ONLINE_NOT_START,
      label: reportMixModuleConst.COLUMN_NAME_TH.ONLINE_NOT_START,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportMixModuleConst.COLUMN_NAME.ONLINE_IN_PROGRESS,
      value: reportMixModuleConst.COLUMN_NAME.ONLINE_IN_PROGRESS,
      label: reportMixModuleConst.COLUMN_NAME_TH.ONLINE_IN_PROGRESS,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportMixModuleConst.COLUMN_NAME.ONLINE_COMPLETE,
      value: reportMixModuleConst.COLUMN_NAME.ONLINE_COMPLETE,
      label: reportMixModuleConst.COLUMN_NAME_TH.ONLINE_COMPLETE,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportMixModuleConst.COLUMN_NAME.ELEARNING_NOT_START,
      value: reportMixModuleConst.COLUMN_NAME.ELEARNING_NOT_START,
      label: reportMixModuleConst.COLUMN_NAME_TH.ELEARNING_NOT_START,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportMixModuleConst.COLUMN_NAME.ELEARNING_IN_PROGRESS,
      value: reportMixModuleConst.COLUMN_NAME.ELEARNING_IN_PROGRESS,
      label: reportMixModuleConst.COLUMN_NAME_TH.ELEARNING_IN_PROGRESS,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportMixModuleConst.COLUMN_NAME.ELEARNING_COMPLETE,
      value: reportMixModuleConst.COLUMN_NAME.ELEARNING_COMPLETE,
      label: reportMixModuleConst.COLUMN_NAME_TH.ELEARNING_COMPLETE,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportMixModuleConst.COLUMN_NAME.CREATED_AT,
      value: reportMixModuleConst.COLUMN_NAME.CREATED_AT,
      label: reportMixModuleConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 2,
      name: reportMixModuleConst.COLUMN_NAME.UPDATED_AT,
      value: reportMixModuleConst.COLUMN_NAME.UPDATED_AT,
      label: reportMixModuleConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 3,
      name: reportMixModuleConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportMixModuleConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportMixModuleConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportMixModuleConst.trainingPlatformOptions,
    },
    {
      id: 4,
      name: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      value: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      label: reportMixModuleConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 5,
      name: reportMixModuleConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      value: reportMixModuleConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      label: reportMixModuleConst.COLUMN_NAME_TH.ABBREVIATION_DISTRIBUTION,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 6,
      name: reportMixModuleConst.COLUMN_NAME.ROADMAP,
      value: reportMixModuleConst.COLUMN_NAME.ROADMAP,
      label: reportMixModuleConst.COLUMN_NAME_TH.ROADMAP,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 7,
      name: reportMixModuleConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      value: reportMixModuleConst.COLUMN_NAME.ABBREVIATION_ROADMAP,
      label: reportMixModuleConst.COLUMN_NAME_TH.ABBREVIATION_ROADMAP,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.ROADMAP,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 8,
      name: reportMixModuleConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      value: reportMixModuleConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      label: reportMixModuleConst.COLUMN_NAME_TH.LEVEL_OF_LEARNER,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportMixModuleConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      value: reportMixModuleConst.COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
      label: reportMixModuleConst.COLUMN_NAME_TH.OTHER_LEVEL_OF_LEARNER,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.LEVEL_OF_LEARNER,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 10,
      name: reportMixModuleConst.COLUMN_NAME.ACQUIRED_SKILL,
      value: reportMixModuleConst.COLUMN_NAME.ACQUIRED_SKILL,
      label: reportMixModuleConst.COLUMN_NAME_TH.ACQUIRED_SKILL,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleConst.COLUMN_NAME.ACQUIRED_SKILL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 11,
      name: reportMixModuleConst.COLUMN_NAME.AGENT_TYPE,
      value: reportMixModuleConst.COLUMN_NAME.AGENT_TYPE,
      label: reportMixModuleConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportMixModuleConst.agentTypeOptions,
    },
    {
      id: 12,
      name: reportMixModuleConst.COLUMN_NAME.COURSE_CODE,
      value: reportMixModuleConst.COLUMN_NAME.COURSE_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 13,
      name: reportMixModuleConst.COLUMN_NAME.COURSE_VERSION,
      value: reportMixModuleConst.COLUMN_NAME.COURSE_VERSION,
      label: reportMixModuleConst.COLUMN_NAME_TH.COURSE_VERSION,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 14,
      name: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportMixModuleConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      isOptionDisabled: 15,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      isOptionDisabled: 15,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_FOR_LEANRER,
      value: reportMixModuleConst.COLUMN_NAME.COURSE_NAME_FOR_LEANRER,
      label: reportMixModuleConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEANRER,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      isOptionDisabled: 15,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportMixModuleConst.COLUMN_NAME.PREFIX,
      value: reportMixModuleConst.COLUMN_NAME.PREFIX,
      label: reportMixModuleConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportMixModuleConst.COLUMN_NAME.FIRST_NAME,
      value: reportMixModuleConst.COLUMN_NAME.FIRST_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportMixModuleConst.COLUMN_NAME.LAST_NAME,
      value: reportMixModuleConst.COLUMN_NAME.LAST_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportMixModuleConst.COLUMN_NAME.PREFIX_TH,
      value: reportMixModuleConst.COLUMN_NAME.PREFIX_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportMixModuleConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportMixModuleConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 22,
      name: reportMixModuleConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportMixModuleConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportMixModuleConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportMixModuleConst.COLUMN_NAME.LICENSE_NO,
      value: reportMixModuleConst.COLUMN_NAME.LICENSE_NO,
      label: reportMixModuleConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 24,
      name: reportMixModuleConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportMixModuleConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportMixModuleConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 25,
      name: reportMixModuleConst.COLUMN_NAME.ID_CARD,
      value: reportMixModuleConst.COLUMN_NAME.ID_CARD,
      label: reportMixModuleConst.COLUMN_NAME_TH.ID_CARD,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 26,
      name: reportMixModuleConst.COLUMN_NAME.UNIT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.UNIT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 27,
      name: reportMixModuleConst.COLUMN_NAME.UNIT_NAME,
      value: reportMixModuleConst.COLUMN_NAME.UNIT_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 28,
      name: reportMixModuleConst.COLUMN_NAME.RAM_CODE,
      value: reportMixModuleConst.COLUMN_NAME.RAM_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportMixModuleConst.COLUMN_NAME.RAM_NAME,
      value: reportMixModuleConst.COLUMN_NAME.RAM_NAME,
      label: reportMixModuleConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 30,
      name: reportMixModuleConst.COLUMN_NAME.GA,
      value: reportMixModuleConst.COLUMN_NAME.GA,
      label: reportMixModuleConst.COLUMN_NAME_TH.GA,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportMixModuleConst.COLUMN_NAME.STATUS_AGENT,
      value: reportMixModuleConst.COLUMN_NAME.STATUS_AGENT,
      label: reportMixModuleConst.COLUMN_NAME_TH.STATUS_AGENT,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportMixModuleConst.statusAgentOptions,
    },
    {
      id: 32,
      name: reportMixModuleConst.COLUMN_NAME.POSITION,
      value: reportMixModuleConst.COLUMN_NAME.POSITION,
      label: reportMixModuleConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 33,
      name: reportMixModuleConst.COLUMN_NAME.MOBILE_NO,
      value: reportMixModuleConst.COLUMN_NAME.MOBILE_NO,
      label: reportMixModuleConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 34,
      name: reportMixModuleConst.COLUMN_NAME.EMAIL,
      value: reportMixModuleConst.COLUMN_NAME.EMAIL,
      label: reportMixModuleConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 35,
      name: reportMixModuleConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 36,
      name: reportMixModuleConst.COLUMN_NAME.AGENT_CODE,
      value: reportMixModuleConst.COLUMN_NAME.AGENT_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 37,
      name: reportMixModuleConst.COLUMN_NAME.STATUS_CLASS,
      value: reportMixModuleConst.COLUMN_NAME.STATUS_CLASS,
      label: reportMixModuleConst.COLUMN_NAME_TH.STATUS_CLASS,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 38,
      name: reportMixModuleConst.COLUMN_NAME.TRAINER_ID_LIST,
      value: reportMixModuleConst.COLUMN_NAME.TRAINER_ID_LIST,
      label: reportMixModuleConst.COLUMN_NAME_TH.TRAINER_ID_LIST,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      masterDataName: reportMixModuleConst.COLUMN_NAME.TRAINER_ID_LIST,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 39,
      name: reportMixModuleConst.COLUMN_NAME.CREATED_BY,
      value: reportMixModuleConst.COLUMN_NAME.CREATED_BY,
      label: reportMixModuleConst.COLUMN_NAME_TH.CREATED_BY,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      masterDataName: reportMixModuleConst.COLUMN_NAME.TRAINER_ID_LIST,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 40,
      name: reportMixModuleConst.COLUMN_NAME.UPDATED_BY,
      value: reportMixModuleConst.COLUMN_NAME.UPDATED_BY,
      label: reportMixModuleConst.COLUMN_NAME_TH.UPDATED_BY,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      masterDataName: reportMixModuleConst.COLUMN_NAME.TRAINER_ID_LIST,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 41,
      name: reportMixModuleConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      value: reportMixModuleConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      label: reportMixModuleConst.COLUMN_NAME_TH.START_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 42,
      name: reportMixModuleConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      value: reportMixModuleConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      label: reportMixModuleConst.COLUMN_NAME_TH.END_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 43,
      name: reportMixModuleConst.COLUMN_NAME.BANK_CODE,
      value: reportMixModuleConst.COLUMN_NAME.BANK_CODE,
      label: reportMixModuleConst.COLUMN_NAME_TH.BANK_CODE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 42,
      name: reportMixModuleConst.COLUMN_NAME.STATUS_COURSE,
      value: reportMixModuleConst.COLUMN_NAME.STATUS_COURSE,
      label: reportMixModuleConst.COLUMN_NAME_TH.STATUS_COURSE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportMixModuleConst.statusCourseOptions,
    },
    {
      id: 43,
      name: reportMixModuleConst.COLUMN_NAME.PERIOD_START_COURSE,
      value: reportMixModuleConst.COLUMN_NAME.PERIOD_START_COURSE,
      label: reportMixModuleConst.COLUMN_NAME_TH.PERIOD_START_COURSE,
      minWidth: 160,
      type: reportMixModuleConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
