import _ from 'lodash'
import { setChangeBody } from '../../../../../../redux/slices/manageContent/home'

export const handleEditorBlur = (ref, key, setValue) => {
  if (!ref.current) return
  setValue((prev) => ({ ...prev, [key]: ref.current.getContent() }))
}

export const handleInputChange = (e, setValue) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  setValue((prev) => ({ ...prev, [key]: value }))
}

export const handleSubmitData = (value, no) => (dispatch) => {
  const newValue = {
    ...value,
    primaryButtonTitle: _.get(value, 'primaryButtonTitle', '').trim(),
    primaryButtonURL: _.get(value, 'primaryButtonURL', '').trim(),
    secondaryButtonTitle: _.get(value, 'secondaryButtonTitle', '').trim(),
    secondaryButtonURL: _.get(value, 'secondaryButtonURL', '').trim(),
  }
  const payload = { value: newValue, key: `bannerSection[${no - 1}]` }
  dispatch(setChangeBody(payload))
}

export const validateVideo = (url) => {
  if (!url) return false
  const value = url.slice(8).split('/')[0]
  return (
    value === 'vimeo.com' ||
    value === 'www.youtube.com' ||
    value === 'youtu.be' ||
    value === 'player.vimeo.com'
  )
}
