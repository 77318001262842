import _ from 'lodash'
import { getManageClassLearnerDocument } from '../../../../services/manageClass/document'
import * as slices from '../../../../redux/slices/manageClassDocument'

export const fetchDataList = (uuid) => async (dispatch) => {
  dispatch(slices.startLoading())
  const result = await dispatch(getManageClassLearnerDocument(uuid))
  dispatch(slices.setFetchDataList(_.get(result, 'payload.data', {})))
  dispatch(slices.stopLoading())
}
