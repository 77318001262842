import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as Styleds from '../../../../../../../modules/CourseSetting/ViewConditions/Styled'
import { handleDisplayPrerequisite } from './events'

const Prerequisite = ({ prerequisite }) => {
  return (
    <Styleds.StyledSectionContainer
      sx={{ gap: 1.5, '& > .MuiBox-root': { gap: 3 } }}
    >
      {prerequisite.map((item, index) => (
        <Box
          sx={{
            width: '100%',
            flexDirection: 'row !important',
            '& > #title': { width: 300, minWidth: 300 },
          }}
          key={index}
        >
          <Typography variant="body1b" color="text.secondary" id="title">
            {`${index !== 0 ? 'หรือ' : ''} เงื่อนไข ${index + 1}`}
          </Typography>

          <ContentFieldConditions key={index} data={item} />
        </Box>
      ))}
    </Styleds.StyledSectionContainer>
  )
}

export default Prerequisite

export const Contents = ({ condition, label }) => {
  return (
    <Styleds.Row>
      <Styleds.Column gap={0.5}>
        {condition && (
          <Styleds.StyledDivider textAlign="left">
            <Typography>{condition}</Typography>
          </Styleds.StyledDivider>
        )}
        <Styleds.Row gap={0.5}>
          <Styleds.StyledTypography
            sx={{
              width: '100%',
              '& > span.condition': { color: '#333' },
              '& > span.label-type': { fontWeight: 700, color: '#00008F' },
            }}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Styleds.Row>
      </Styleds.Column>
    </Styleds.Row>
  )
}

export const ContentFieldConditions = ({ data }) => {
  const prerequisiteList = handleDisplayPrerequisite(data)
  return (
    <Styleds.Column gap={0.5}>
      {prerequisiteList.map((andCondition) =>
        andCondition.map((item, key) => (
          <Contents key={key} condition={item.condition} label={item.label} />
        )),
      )}
    </Styleds.Column>
  )
}
