import React from 'react'
import Box from '@mui/material/Box'
import Launch from '@mui/icons-material/Launch'
import { StyledSmallButton } from '../AddCollectionDragDrop/Styled'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { shallowEqual, useDispatch } from 'react-redux'
import { closeDialog, openDialog } from '../../../../../../redux/slices/dialog'
import { useSelector } from 'react-redux'
import Select from '../../../../../../components/Input/Select'

import { courseTypeOption } from '../../../../../../modules/ELearning/LearningPath/Form/LearningPathTab/Course/CreateCourseButton/model'

const CreateCourseButton = () => {
  const pathCreateCourse = useSelector(
    (state) => state.crud.pathCreateCourse,
    shallowEqual,
  )
  const dispatch = useDispatch()

  return (
    <div>
      <StyledSmallButton
        id="StyledSmallButton"
        variant="outlined"
        aria-haspopup="true"
        aria-controls={open ? 'menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        startIcon={<Launch />}
        onClick={() =>
          dispatch(
            openDialog({
              title: 'สร้างหลักสูตร',
              type: 'content',
              content: <SelectCourseTypeDialog />,
              handleConfirm: () => {
                window.open(pathCreateCourse, '_blank')
                dispatch(closeDialog())
              },
            }),
          )
        }
      >
        สร้างหลักสูตร
      </StyledSmallButton>
    </div>
  )
}

export default CreateCourseButton

export const SelectCourseTypeDialog = () => {
  const pathCreateCourse = useSelector(
    (state) => state.crud.pathCreateCourse,
    shallowEqual,
  )
  const dispatch = useDispatch()

  return (
    <Box sx={{ px: 2, pb: 1 }}>
      <Select
        type="basic"
        dataTestid="select-course-type"
        id="courseType"
        name="courseType"
        showTextError={false}
        options={courseTypeOption}
        value={pathCreateCourse}
        handleChange={(e) => {
          dispatch(
            setFieldValue({ key: 'pathCreateCourse', value: e.target.value }),
          )
        }}
      />
    </Box>
  )
}
