import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import { filterAcquiredSkill } from '../../../../../utils/apiPath'

export const fetchAcquireSkill = async () => {
  const body = {
    acquiredSkill: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  try {
    const response = await callAxios.post(filterAcquiredSkill, body)
    store.dispatch(
      setFieldValue({
        key: 'masterData.acquiredSkill',
        value: {
          isLoading: false,
          options: response.data.result.map((item) => ({
            value: item.uuid,
            label: item.acquiredSkill,
            skillType: item.skillType,
          })),
        },
      }),
    )
  } catch (e) {
    handleFetchError(e)
  }
}
