import React from 'react'
import _ from 'lodash'
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const CheckAnnouncement = ({ data }) => {
  const announcement = _.get(data, 'announcement')
  if (announcement) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <HourglassBottomTwoTone
          sx={{ width: 16, height: 16, color: 'warning.main' }}
        />
      </Box>
    )
  }
  return <Typography variant="body2"></Typography>
}

export default CheckAnnouncement
