import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import Switch from '@mui/material/Switch'
import Edit from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import Footer from './Footer'
import Drawer from './Drawer'
import { StyledFormContainer } from '../../HomePage/Form/Styled'
import * as Styled from './Styled'
import _ from 'lodash'
import { handleDrawer, handleChage, handleChageExpand } from './events'
import { getDefaultValue } from '../../HomePage/Form/Banner/events'
import { BannerContent } from '../../HomePage/Form/Banner'

const CardBanner = () => {
  const dispatch = useDispatch()
  const loginPages = useSelector(
    (state) => state.manageContentLogin.loginPages,
    shallowEqual
  )
  return (
    <StyledFormContainer>
      {loginPages.map((item, index) => {
        const expand = _.get(item, 'expand', false)
        const image = _.get(item, 'image', '')
        const status = _.get(item, 'status', 'INACTIVE')
        const isDefault = _.isEmpty(image)
        const urlImg = `${window.__env__.REACT_APP_API_URL}/file${image}`
        return (
          <Styled.BoxCardBanner key={item.sequence}>
            {/* header */}
            <Styled.BoxBetween>
              <Typography variant="h6">Banner {item.sequence}/6</Typography>
              {expand && (
                <IconButton
                  onClick={() => dispatch(handleChageExpand(index, false))}
                >
                  <KeyboardArrowUp cursor="pointer" />
                </IconButton>
              )}
              {!expand && (
                <IconButton
                  onClick={() => dispatch(handleChageExpand(index, true))}
                >
                  <KeyboardArrowDown cursor="pointer" />
                </IconButton>
              )}
            </Styled.BoxBetween>
            <Styled.BoxSwitch>
              <Switch
                diabled={_.isEmpty(_.get(item, 'image', ''))}
                checked={status === 'ACTIVE'}
                onChange={(e) =>
                  dispatch(
                    handleChage({
                      indexData: index,
                      key: 'status',
                      value: _.get(e, 'target.checked', false)
                        ? 'ACTIVE'
                        : 'INACTIVE',
                    })
                  )
                }
              />
              <Typography variant="body1">การเปิดใช้งาน</Typography>
            </Styled.BoxSwitch>

            {/* Content */}
            {expand && (
              <Styled.BoxCardContent isDefault={isDefault}>
                <Box>
                  <Styled.BoxBetween>
                    <Typography variant="body1b">Background</Typography>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          handleDrawer({
                            isOpen: true,
                            index,
                          })
                        )
                      }
                    >
                      <Edit color="action" />
                    </IconButton>
                  </Styled.BoxBetween>
                  <Styled.BoxImgContent pt={2} isDefault={isDefault}>
                    <img src={isDefault ? '/image/no-image.svg' : urlImg} />
                  </Styled.BoxImgContent>
                </Box>
                {/* wording */}
                <Box display="flex" flexDirection="column">
                  <BannerContent
                    isNotReplace={true}
                    label="Title"
                    data={getDefaultValue(item, 'title')}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <BannerContent
                    isNotReplace={true}
                    label="Description"
                    data={getDefaultValue(item, 'description')}
                  />
                </Box>
              </Styled.BoxCardContent>
            )}
          </Styled.BoxCardBanner>
        )
      })}

      <Footer />
      <Drawer />
    </StyledFormContainer>
  )
}
export default CardBanner
