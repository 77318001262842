import * as API from '../MonthlyPlan/api/Index'
import _ from 'lodash'
import dayjs from 'dayjs'
import { WORK_WORK_JSON } from '../MonthlyPlan/Text'
import Box from '@mui/material/Box'
import Select from '../../components/Input/Select'
import React from 'react'
import { validatePermission, getStaffStationOption } from '../../utils/lib'

export const handleConfirm = ({ setIsDrawer, setDialog }) => {
  setDialog((prevState) => ({
    ...prevState,
    open: false,
  }))
  setIsDrawer((prevState) => ({
    ...prevState,
    status: true,
    type: 'add',
  }))
}

export const fetchDataAll = async ({
  state,
  setState,
  setOptions,
  setIsLoading,
  user,
  trainingPlan,
  uuid,
  objOnLink,
}) => {
  setIsLoading(true)
  try {
    const dataApi = await fetchOptionsAll({
      state,
      setOptions,
      setIsLoading,
      user,
      trainingPlan,
      uuid,
      objOnLink,
    })

    setState((prevState) => ({
      ...prevState,
      ...dataApi.data,
    }))
  } catch (error) {
    setIsLoading(false)
    console.log('fetchDataAll error => ', error)
  }
}

export const filterWorkType = (listWorkTypes) => {
  if (!listWorkTypes.length) return []
  return listWorkTypes.filter((item) => {
    if (
      item.workType === 'Training' ||
      item.workType === 'Activity Support' ||
      item.workType === 'Holiday'
    )
      return item
  })
}

const getDefaultAreaByUser = (user) => {
  return user.area.find((item) => item.default === true)
}

export const fetchOptionsAll = async ({
  state,
  setOptions,
  user,
  trainingPlan,
  uuid,
  objOnLink,
}) => {
  let areaId = _.get(getDefaultAreaByUser(user), 'id', '')
  let areaUuId = _.get(getDefaultAreaByUser(user), 'uuid', '')
  try {
    const dataApi = await Promise.all([
      API.fetchWorkType(),
      API.fetchArea(user),
      API.getStaffInAreaOther(
        _.get(getDefaultAreaByUser(user), 'uuid', ''),
        state.data.year,
        state.data.month
      ),
      API.getMeetingRoom(),
      API.fetchTrainingPlanArea(),
      API.fetchCourseSettingList({
        isYear: state.data.year,
        isMonth: state.data.month + 1,
        area: areaUuId,
      }),
      API.fetchStaffList({
        isYear: state.data.year,
        isMonth: state.data.month + 1,
        area: areaUuId,
        type: 'ALL',
      }),
      API.fetchAcademyActivity(),
      API.fetchStaffList({
        isYear: state.data.year,
        isMonth: state.data.month + 1,
        area: areaUuId,
        type: 'PERSONAL',
      }),
      API.getMonthlyPlanFilterStaff({
        area: areaUuId,
      }),
    ])

    const work = _.get(dataApi, '[0]', [])
    const staffs = getStaffStationOption(dataApi[2])
    const monthlyPlanStaff = _.get(dataApi, '[9]', [])
    const testsubWorkType = work.find(
      (item) => item.label === 'Academy Activity'
    )

    const subWorkType = _.get(testsubWorkType, 'subWorkType', []).map(
      (item) => {
        return {
          id: item.id,
          subWorkType: item.subWorkType,
          uuid: item.uuid,
          value: item.uuid,
          label: item.subWorkType,
        }
      }
    )
    setOptions({
      work: work,
      activity: subWorkType,
      area: dataApi[1],
      leave: [],
      staffProfile: staffs,
      meetingRoom: dataApi[3],
      areaTrainingPlan: dataApi[4],
      staffWorkload: dataApi[8],
      monthlyPlanStaff,
    })
    const fetchCourseList = dataApi[5]
    const fetchStaffList = dataApi[6]
    const fetchStaffWorkloadList = dataApi[8]
    let listWorkTypes = dataApi[0]
    const workTypesUUIDList = work.map((d) => {
      return d.uuid
    })
    const hasPermissionHF = validatePermission({
      user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['APPROVE_MP'],
    })
    if ((trainingPlan || hasPermissionHF) && listWorkTypes.length) {
      listWorkTypes = filterWorkType(listWorkTypes)
    }

    const workTypes = listWorkTypes.map((d) => {
      return d.uuid
    })

    let uuidStation = _.get(getDefaultAreaByUser(user), 'station.uuid', '')
    let uuidDepartment = _.get(
      getDefaultAreaByUser(user),
      'department.uuid',
      ''
    )
    let checkArea = undefined
    if (dataApi[2].length) {
      checkArea = dataApi[1].find((item) => {
        return item.areaUuid === _.get(objOnLink, 'areaUuid', '')
      })
      if (
        (!checkArea && _.get(objOnLink, 'drawer', 0) == 1) ||
        (!checkArea && _.get(objOnLink, 'view', 0) == 1)
      ) {
        checkArea = _.find(_.get(dataApi, [1], '[]'), (item) => {
          return item.uuid === _.get(objOnLink, 'departmentUuid', '')
        })
      }
    }

    if (uuid && !!checkArea) {
      areaId = _.get(checkArea, 'id', '')
      uuidDepartment = _.get(checkArea, 'valueArea', '')
      if (
        _.get(objOnLink, 'drawer', 0) == 1 ||
        _.get(objOnLink, 'view', 0) == 1
      ) {
        uuidStation = _.get(objOnLink, 'stationUuid', '')
        areaUuId = _.get(objOnLink, 'areaUuid', '')
      } else {
        uuidStation = _.get(checkArea, 'valueStation', '')
        areaUuId = _.get(checkArea, 'value', '')
      }
    }

    return {
      work: work,
      data: {
        data: {
          month: !checkArea
            ? dayjs().month()
            : Number(_.get(objOnLink, 'month', '')) - 1,
          year: !checkArea
            ? dayjs().year()
            : Number(_.get(objOnLink, 'year', '')),
        },
        work: { value: _.get(work, '[0].value', '') },
        activity: { value: _.get(subWorkType, '[0].value', '') },
        area: {
          ...getDefaultAreaByUser(user),
          id: areaId,
          uuid: areaUuId,
          value: areaUuId,
          valueArea: uuidDepartment,
          valueStation: uuidStation,
          valueAreaList: 'all',
          valueStationList: 'all',
        },
        filter: {
          courses: fetchCourseList.map((item) => item.courseCode),
          staffs: fetchStaffList.map((item) => item.value),
          workTypes,
        },
        filterWorkload: {
          courses: fetchCourseList.map((item) => item.courseCode),
          staffs: fetchStaffWorkloadList.map((item) => item.value),
          workTypes: workTypesUUIDList,
        },
      },
    }
  } catch (error) {
    console.log('fetchOptionsAll error => ', error)
  }
}

export const fetchEvents = async ({
  state,
  isAllPlan,
  options,
  courses,
  staffs,
  calendarType,
}) => {
  let calendarEvents = []
  try {
    if (!_.get(state, 'area.value', '')) return calendarEvents

    let resultGet = []
    resultGet = await API.fetchMonthlyPlan({
      state,
      isAllPlan,
      options,
      courses,
      staffs,
      calendarType,
    })
    if (resultGet.length) {
      resultGet.map((d) => {
        const found = options.work.find((w) => w.id === d.workType) || {
          label: '',
        }
        let finishDate = d.finishDate
        const formatTime = d.createdAt.substring(11, 19)
        const staffs = _.get(d, 'staffs', [])
        let newTitle = d.title
        const workPlace = _.get(d, 'workPlace', '')
        if (workPlace === 'OFFICE') newTitle = 'Work from office'
        if (workPlace === 'HOME') newTitle = 'Smart working'
        calendarEvents = [
          ...calendarEvents,
          {
            ...d,
            title: newTitle,
            sort: new Date(`${d.startDate} ${formatTime}`),
            start: `${d.startDate} ${formatTime}`,
            end: `${finishDate} ${formatTime}`,
            publicId: d.uuid,
            workType: found.label,
            class: WORK_WORK_JSON[found.label].class || '',
            classLeave:
              found.label === 'Leave'
                ? WORK_WORK_JSON[found.label].classLeave[d.title].class
                : '',
            sum: staffs.length ? _.unionBy(staffs, 'uuid').length : 0,
            image: d.staffs.map((staff) =>
              staff.image
                ? `${window.__env__.REACT_APP_API_URL}/file/${staff.image}`
                : '/logo/account.svg'
            ),
            academySubworkType: d.academySubworkType,
          },
        ]
        if (found.label === 'Holiday') {
          calendarEvents.push({
            sort: new Date(`${d.startDate} ${formatTime}`),
            start: `${d.startDate} ${formatTime}`,
            end: `${finishDate} ${formatTime}`,
            rendering: 'background',
            color: '#f9fff4',
          })
        }
      })
    }
  } catch (error) {
    console.log('fetchEvents error => ', error)
  }
  return _.orderBy(calendarEvents, ['sort'], ['asc'])
}

export const SelectWork = ({
  setState,
  state,
  optionsWork,
  optionsActivity,
}) => {
  if (optionsWork.length === 1) {
    state.work.value = optionsWork[0].value
  }
  return (
    <>
      <Box sx={{ mx: 3 }}>
        <Select
          dataTestid="select-work"
          id="work"
          name="work"
          type="basic"
          options={optionsWork}
          placeholder="กรุณาเลือก"
          handleChange={(e) =>
            setState((prevState) => ({
              ...prevState,
              work: { value: e.target.value },
            }))
          }
          value={state?.work.value || ''}
        />
        {state?.work.value === 'Academy Activity' && (
          <Select
            dataTestid="select-activity"
            id="activity"
            name="activity"
            type="basic"
            options={optionsActivity}
            placeholder="กรุณาเลือก"
            handleChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                activity: { value: e.target.value },
              }))
            }
            value={state?.activity.value || ''}
          />
        )}
      </Box>
    </>
  )
}

export const getApproveList = async ({
  state,
  approveDrawer,
  setApproveDrawer,
  setState,
}) => {
  const fetch = await API.fetchApproveList({
    state,
    approveDrawer,
  })
  setApproveDrawer((prev) => ({
    ...prev,
    count: _.get(fetch, 'totalCount', 0),
  }))
  setState((prev) => ({ ...prev, approveList: _.get(fetch, 'result', []) }))
  return fetch
}
