import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { validateEPermission } from './../../../../utils/lib'
import { StyledViewTitle } from './../../../ETesting/Version/View/Styled'
import { StyledContainer, StyledContent } from './../../Styled'
import { breadcrumbVersionView } from './../../model'
import Header from './Header'
import Content from './Content'
import { fetchEEvaluation } from './events'

const View = () => {
  const { id: uuid } = useParams()
  const { result, isLoading } = useSelector((state) => state.eEvaluationDetail)
  const dispatch = useDispatch()
  const getPermissionEEvaluationEdit = validateEPermission({
    module: 'E_EVALUATION_SETTING', // E_EVALUATION_SETTING
    permission: 'EDIT',
    data: _.get(result, 'setting', {}),
  })

  useEffect(() => {
    dispatch(fetchEEvaluation(uuid))
  }, [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <StyledViewTitle variant="h4">
            รายละเอียดแบบประเมิน : {_.get(result, 'information.name', '')}
          </StyledViewTitle>
        </Box>
        <Breadcrumbs
          menuList={breadcrumbVersionView(_.get(result, 'codeId', 0))}
        />
        {getPermissionEEvaluationEdit && <Header />}
        {result && <Content detail={result} />}
      </StyledContent>
    </StyledContainer>
  )
}

export default View
