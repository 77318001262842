import _ from 'lodash'
import dayjs from 'dayjs'
import * as slicesCalendar from '../../../../redux/slices/manageClassCalendar'
import * as slicesManageClass from '../../../../redux/slices/manageClass'
import * as services from '../../../../services/manageClass/calendar'

export const fetchDataCalendar = (props) => async (dispatch) => {
  const { filterCalendar, month, year, uuid, type } = props

  dispatch(slicesManageClass.startLoading())

  let filter = {}
  if (type === 'fetch') {
    filter = await dispatch(fetchDataDrawerCalendar({ uuid, month, year }))
  } else {
    filter = filterCalendar
  }
  const addBody = filterBoyCalendar({ type, filter })
  const body = {
    station: uuid,
    month: month,
    year: year,
    ...addBody,
  }
  const dataListCalendar = await dispatch(services.getManageClassCalendar(body))
  if (type === 'fetch') {
    dispatch(
      slicesCalendar.setFetchCalendar({
        listCalendar: mapDataCalendar(_.get(dataListCalendar, `payload`, [])),
        scheduleType: filterScheduleType(_.get(filter, 'scheduleType', [])),
        teamCalendar: filterTeamCalendar(_.get(filter, 'teamCalendar', [])),
        trainingCourse: filterTrainingCourse(
          _.get(filter, 'trainingCourse', [])
        ),
      })
    )
  } else {
    dispatch(
      slicesCalendar.setDataCalendar(
        mapDataCalendar(_.get(dataListCalendar, `payload`, []))
      )
    )
  }

  dispatch(slicesManageClass.stopLoading())
}

export const mapDataCalendar = (data) => {
  if (!data.length) return []

  return data.map((item) => {
    return {
      title: _.get(item, 'title', ''),
      class: _.get(item, 'workType', 4) === 4 ? 'holiday' : 'training-other',

      finishDate: _.get(item, 'finishDate', ''),
      end: _.get(item, 'finishDate', ''),

      startDate: _.get(item, 'startDate', ''),
      start: _.get(item, 'startDate', ''),
      image: filterUrlImageStaff(_.get(item, 'staffs', [])),
      sum: _.get(item, 'staffs', []).length,
      status: _.get(item, 'status', ''),
      statusApprove: _.get(item, 'statusApprove', ''),
      statusBorrow: _.get(item, 'statusBorrow', ''),
      uuid: _.get(item, 'uuid', ''),
      trainingPlanUuid: _.get(item, 'trainingPlanUuid', ''),
      workType: _.get(item, 'workType', ''),
    }
  })
}

export const filterUrlImageStaff = (list) => {
  if (!list.length) return []
  let result = []
  list.map((item) => {
    result.push(`${window.__env__.REACT_APP_API_URL}/file${item.image}`)
  })
  return result
}

export const filterScheduleType = (list, isFetch) => {
  if (!list.length) return []
  let result = []
  list.filter((item) => {
    let workType = item.workType

    if (workType === 'Activity Support') workType = 'Activity Support'
    if (workType !== 'Academy Activity' && workType !== 'Leave') {
      if (isFetch) {
        result.push(item.uuid)
      } else {
        result.push({
          label: workType,
          name: _.camelCase(workType),
          checked: true,
          value: item.uuid,
          typeColor: workType === 'Holiday' ? 'holidayBorder' : 'training',
        })
      }
    }
  })

  return result
}

export const filterTeamCalendar = (list, isFetch) => {
  if (!list.length) return []
  let result = []
  list.filter((item) => {
    if (isFetch) {
      result.push(item.uuid)
    } else {
      result.push({
        label: `${item.title} (${item.department})`,
        name: _.camelCase(item.title),
        checked: true,
        value: item.uuid,
      })
    }
  })
  return result
}

export const filterTrainingCourse = (list, isFetch) => {
  if (!list.length) return []
  let result = []
  list.filter((item) => {
    if (isFetch) {
      result.push(item.courseCode)
    } else {
      result.push({
        label: item.englishName,
        name: _.camelCase(item.englishName),
        checked: true,
        value: item.courseCode,
      })
    }
  })
  return result
}

export const filterBoyCalendar = ({ type, filter }) => {
  let result = {}

  if (type === 'fetch') {
    result = {
      workTypes: filterScheduleType(_.get(filter, 'scheduleType', []), true),
      staffs: filterTeamCalendar(_.get(filter, 'teamCalendar', []), true),
      courses: filterTrainingCourse(_.get(filter, 'trainingCourse', []), true),
    }
  } else {
    result = filterBodyChecked(filter)
  }

  return result
}

export const fetchDataDrawerCalendar =
  ({ uuid, month, year }) =>
  async (dispatch) => {
    const startDate = dayjs(`${year}-${month}-01`)
      .startOf('month')
      .format(window.__env__.REACT_APP_DATE_SHOW)
    const finishDate = dayjs(`${year}-${month}-01`)
      .endOf('month')
      .format(window.__env__.REACT_APP_DATE_SHOW)

    const bodyCourse = {
      calendar: true,
      station: uuid,
      version: 1,
      englishName: '',
      courseCode: '',
      startDate,
      finishDate,
      updatedBy: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE', 'WAITING_FOR_ACTIVE'],
      createdBy: '',
      assignee: '',
    }

    const url = `?month=${month}&year=${year}&station=${uuid}`

    const bodyWorkType = {
      workType: '',
      limit: 100,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE'],
    }

    const result = await Promise.all([
      dispatch(services.getCalendarWorkType(bodyWorkType)),
      dispatch(services.getCalendarStaff(url)),
      dispatch(services.postCalendarCourse(bodyCourse)),
    ])

    return {
      scheduleType: _.get(result, `[0].payload.result`, []),
      teamCalendar: _.get(result, `[1].payload`, []),
      trainingCourse: _.get(result, `[2].payload.result`, []),
    }
  }

export const filterBodyChecked = (itemFilter) => {
  return {
    workTypes: filterIsChecked(_.get(itemFilter, 'scheduleType', [])),
    staffs: filterIsChecked(_.get(itemFilter, 'teamCalendar', [])),
    courses: filterIsChecked(_.get(itemFilter, 'trainingCourse', [])),
  }
}

export const filterIsChecked = (list) => {
  if (!list.length) return []
  let result = []
  list.filter((item) => {
    if (item.checked) result.push(item.value)
  })
  return result
}

export const convertMonth = (month) =>
  `${month}`.length === 1 ? `0${month}` : month
