import { Box } from '@mui/material'
import { closeDialog, openDialog } from '../../../../../../redux/slices/dialog'
import { store } from '../../../../../../App'
import CloseIcon from '@mui/icons-material/Close'

export const handleReadMore = (text, title = 'คำตอบ') => {
  store.dispatch(
    openDialog({
      type: 'confirm',
      title: (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>{title}</Box>
          <CloseIcon
            onClick={() => {
              store.dispatch(closeDialog())
            }}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
            }}
          />
        </Box>
      ),
      message: text,
      agreeText: 'ตกลง',
      hideCancel: true,
      maxWidth: '500px',
      handleConfirm: () => {
        store.dispatch(closeDialog())
      },
    }),
  )
}