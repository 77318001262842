import { TableCell, TableHead, TableRow, Box, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'

const TableHeader = () => {
  const theme = useTheme()
  const { headCellsReport } = useSelector(
    (state) => ({
      headCellsReport: state.monthlyPlan.report.headCellsReport,
    }),
    shallowEqual,
  )

  return (
    <TableHead>
      <TableRow>
        {_.map(headCellsReport, (header, idx) => {
          return _.startsWith(header.id, ['staff']) ? (
            <TableCell
              sx={{
                minWidth: header?.minWidth,
              }}
              key={idx}
              align={header.align}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '.MuiTypography-root': {
                    whiteSpace: 'nowrap',
                    fontSize: theme.typography.tableHeader.fontSize,
                    fontWeight: theme.typography.tableHeader.fontWeight,
                    fontStyle: theme.typography.tableHeader.fontStyle,
                  },
                }}
              >
                <Typography>{header.agentCode}</Typography>
                <Typography>{header.label}</Typography>
                <Typography>{header.jobTitle}</Typography>
              </Box>
            </TableCell>
          ) : (
            <TableCell
              sx={{ minWidth: header?.minWidth }}
              key={idx}
              align={header.align}
            >
              {header.label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
