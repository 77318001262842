import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'

import { BoxAddChoice } from './Styled'
import { handleAddChoiceRating } from './event'

const Index = ({ props }) => {
  const dispatch = useDispatch()

  const choices = _.get(props, 'itemQuestion.choices', []).length >= 10
  const disabled = props.type === 'choices' ? choices : false

  return (
    <>
      <BoxAddChoice
        data-testid="add-choice-rating"
        disabled={props.type === 'choices' ? disabled : false}
        onClick={() =>
          !props.disableForm &&
          !disabled &&
          dispatch(handleAddChoiceRating(props))
        }
      >
        <Add
          sx={{
            color:
              !props.disableForm && !disabled
                ? 'primary.main'
                : 'action.disabled',
          }}
        />
        <Typography
          variant="buttons"
          color={
            !props.disableForm && !disabled ? 'primary' : 'action.disabled'
          }
        >
          {props.type === 'questions' ? 'เพิ่มคำถาม' : 'เพิ่มตัวเลือก'}
        </Typography>
      </BoxAddChoice>
    </>
  )
}
export default Index
