export const headerTopFive = [
  {
    id: 'no',
    label: '#',
    grid: 1,
  },
  {
    id: 'staffName',
    label: 'ชื่อ',
    grid: 4,
  },
  {
    id: 'station',
    label: 'Station',
    grid: 3,
  },
  {
    id: 'time',
    label: 'ระยะเวลา',
    grid: 4,
  },
]

export const headerStaff = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '20px',
  },
  {
    id: 'staffName',
    label: 'ชื่อ',
  },
  {
    id: 'station',
    label: 'Station',
  },
  {
    id: 'time',
    label: 'ระยะเวลา',
  },
]

export const tabsOverviewList = [
  {
    label: 'การจัดอันดับ',
  },
  {
    label: 'Summary workload',
  },
]

export const tabsSummaryWorkloadList = [
  {
    label: 'ภาพรวม',
  },
  {
    label: 'รายบุคคล Main Category',
  },
  {
    label: 'รายบุคคล Sub Category',
  },
]

export const headCellsDetails = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'staffName',
    label: 'ชื่อ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'training',
    label: 'Training Hours',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'activitySupport',
    label: 'Activity Support',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'officeWork',
    label: 'Office Work',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'selfDevelopment',
    label: 'Self Development',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'holiday',
    label: 'Holidays',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const listWorkTypeData = [
  { key: 'training', label: 'Training Hours', value: null },
  { key: 'activitySupport', label: 'Activity Support', value: null },
  { key: 'officeWork', label: 'Office work', value: null },
  { key: 'selfDevelopment', label: 'Self Development', value: null },
  { key: 'holiday', label: 'Holidays', value: null },
]

export const headCellsSecondView = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'staffName',
    label: 'ชื่อ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'agency',
    label: 'Agency',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'bancKTB',
    label: 'Banc/KTB',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fSACS',
    label: 'FSA/CS',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'activitySupport',
    label: 'Activity Support',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'officeWork',
    label: 'Office Work',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'travelling',
    label: 'เดินทาง',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'development',
    label: 'Development',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'meetingOtherActivity',
    label: 'T_Meeting/T_Other',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'publicHoliday',
    label: 'Holidays',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'holiday',
    label: 'Day Off',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const listWorkTypeDataSecondView = [
  { key: 'agency', label: 'Agency', value: null },
  { key: 'bancKTB', label: 'Banc/KTB', value: null },
  { key: 'fSACS', label: 'FSA/CS', value: null },
  { key: 'activitySupport', label: 'Activity Support', value: null },
  { key: 'officeWork', label: 'Office Work', value: null },
  { key: 'travelling', label: 'เดินทาง', value: null },
  { key: 'development', label: 'Development', value: null },
  { key: 'meetingOtherActivity', label: 'T_Meeting/T_Other', value: null },
  { key: 'publicHoliday', label: 'Holidays', value: null },
  { key: 'holiday', label: 'Day Off', value: null },
]

export const filtersListWorkloadDashboard = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'dateRange',
  },
  {
    label: 'Department',
    nameCheckBox: 'departmentChecked',
    nameInput: 'department',
    type: 'select',
    placeholder: 'เลือก Department',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'checkbox',
  },
  {
    label: 'Course code',
    nameCheckBox: 'courseCodeChecked',
    nameInput: 'courseCode',
    type: 'select',
    placeholder: 'เลือก Course code',
  },
  {
    label: 'ประเภทพนักงาน',
    nameCheckBox: 'positionChecked',
    nameInput: 'position',
    type: 'select',
    placeholder: 'เลือก ประเภทพนักงาน',
  },
]
