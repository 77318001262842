import React, { useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

import TextInput from '../../../components/Input/TextInput'
import * as dialog from '../../../redux/slices/dialog'
import { validateRemark } from './events'
export const Index = ({ labelHeader, labelInput, submitDialog }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    remark: '',
    isError: false,
  })

  return (
    <Box sx={{ mx: 3, my: 2 }}>
      <Typography variant="h6">{labelHeader}</Typography>
      <Box sx={{ ml: 1, mt: 1 }}>
        <TextInput
          required
          type="basic"
          name="remark"
          rows={3}
          labelText={labelInput}
          placeholder=""
          multiline={true}
          value={state.remark}
          textError={state.isError ? 'ระบุได้ไม่เกิน 3000 ตัวอักษร' : ''}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              remark: _.get(e, 'target.value', ''),
            }))
          }
        />
      </Box>

      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          sx={{ width: 80 }}
          data-testid="btn-disagree"
          onClick={() => dispatch(dialog.closeDialog())}
          variant="outlined"
          endIcon=""
        >
          ยกเลิก
        </Button>
        <Button
          sx={{ width: 80 }}
          data-testid="btn-agree"
          variant="contained"
          endIcon=""
          disabled={!state.remark}
          onClick={() => {
            dispatch(validateRemark(state, setState, submitDialog))
          }}
        >
          บันทึก
        </Button>
      </Box>
    </Box>
  )
}

export default Index
