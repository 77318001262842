import _ from 'lodash'
import { setInitialApproveList } from '../../../redux/slices/speaker'
import { initialApproveObj } from '../../../redux/slices/speaker/model'

export const handleInitialList = (speakerType, borrowList) => (dispatch) => {
  if (speakerType === 'REQUESTER') {
    const approveList = []
    borrowList.forEach((approve) =>
      approveList.push({
        ...initialApproveObj,
        uuid: _.get(approve, 'uuid', ''),
      })
    )
    dispatch(setInitialApproveList(approveList))
  }
}
