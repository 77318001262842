import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import _ from 'lodash'
import Button from '@mui/material/Button'

import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import { handleExpand, handleSubmitDialog } from './Handle'
import TimePicker from '../../../../components/TimePicker/TimePicker'
import { approveStatus } from '../../../../constants/monthlyPlan/speaker'

import { convertTime } from '../View/Index'
import DialogForm from '../../Dialog/DialogForm'
import { cancelSpeakerBorrow } from '../../api/Index'
// import { DEFAULT_DIALOG_VALUE } from '../../Text'
import FormListBorrow from '../../Dialog/FormListBorrow'
import AlertDialog from '../../../../components/AlertDialog'
import Autocomplete from '../../../../components/Input/Autocomplete'
import { addOptionStaff } from '../Training/Form'
import { convertFormatDateTime } from '../../../../utils/lib'
import BorrowDrawer from '../BorrowDrawer'

export const EventExpandDate = (props) => {
  const {
    state,
    stateForm,
    setStateForm,
    onChangeForm,
    options,
    validateForm,
    setValidateForm,
    staff,
    responsibility,
    loadingDrawer,
    setLoadingDrawer,
  } = props

  const [openDialog, setOpenDialog] = useState(false)
  const [openBorrowDialog, setOpenBorrowDialog] = useState(false)

  const [stateFormDialog, setStateFormDialog] = useState({})
  const [typeDialog, setTypeDialog] = useState(null)
  const [stateRowIndex, setStateRowIndex] = useState(0)

  const [dialog, setDialog] = useState({
    variant: 'delete',
    title: 'ยืนยันลบข้อมูล',
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    open: false,
  })

  const meetingType = _.get(stateForm, 'academyActivity.meetingType', 'online')
  const listExpandDate = _.get(stateForm, 'academyActivity.expandDate', [])
  const listMeetingRoom = _.get(options, 'meetingRoom', [])

  const handleClickOpen = (value, type, rowIndex) => {
    setStateFormDialog(value)
    setTypeDialog(type)
    setStateRowIndex(rowIndex)

    if (type === 'cancel') {
      setDialog({
        ...dialog,
        title: 'ยืนยันยกเลิกคำขอ',
        content: 'คุณต้องการยกเลิกคำขอยืมวิทยากรนี้หรือไม่',
        agreeText: 'บันทึก',
        open: true,
        onConfirmClick: () => handleCancelBorrow(value),
      })
    } else if (type === 'delete') {
      setDialog({
        ...dialog,
        open: true,
        onConfirmClick: () => handleDelete(value, rowIndex),
      })
    } else {
      setOpenDialog(true)
    }
  }
  const handleClose = () => {
    setOpenDialog(false)
    setDialog({ ...dialog, open: false })
  }

  const handleSubmitForm = (value) => {
    handleSubmitDialog(
      listExpandDate,
      value,
      typeDialog,
      stateRowIndex,
      setStateForm,
    )
    handleClose()
  }

  const handleDelete = (value, rowIndex) => {
    handleSubmitDialog(listExpandDate, value, 'delete', rowIndex, setStateForm)
    handleClose()
  }

  const handleCancelBorrow = async (value) => {
    setLoadingDrawer(true)
    handleSubmitDialog(
      listExpandDate,
      value,
      'CANCEL',
      stateRowIndex,
      setStateForm,
    )
    await cancelSpeakerBorrow(value.uuid || '')
    handleClose()
    setLoadingDrawer(false)
  }

  return (
    <>
      {listExpandDate.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <Box sx={{ width: '26%' }}>
              <Typography variant="body1">วันที่</Typography>
            </Box>
            <Box sx={{ width: '32%' }}>
              <Typography variant="body1" sx={{ display: 'flex' }}>
                เวลาเริ่ม
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ width: '31%' }}>
              <Typography variant="body1" sx={{ display: 'flex' }}>
                เวลาสิ้นสุด
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Typography>
            </Box>

            <Box sx={{ width: '100%' }}>
              {meetingType === 'online' && (
                <Typography variant="body1" sx={{ display: 'flex' }}>
                  {'Link ห้องประชุมออนไลน์'}
                </Typography>
              )}
            </Box>
          </Box>
          {listExpandDate.map((item, index) => {
            const objExpandDate = listExpandDate.find((itemMeetingRoom) => {
              return (
                itemMeetingRoom.date ===
                dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB)
              )
            })

            const findRequireFile = _.get(
              validateForm,
              'academyActivity.expandDate',
              [],
            ).find((itemExpand) => {
              return (
                itemExpand.date ===
                dayjs(item.date).format(window.__env__.REACT_APP_DATE_DB)
              )
            })

            const borrowData = {
              startDate: _.get(item, 'date', ''),
              finishDate: _.get(item, 'date', ''),
              startTime: convertTime(_.get(item, 'startTime', '')),
              endTime: convertTime(_.get(item, 'endTime', '')),
            }
            const speakerBorrows = _.get(item, 'speakerBorrow', []).filter(
              (speaker) => speaker.status !== approveStatus.DELETED,
            )

            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 3,
                      marginTop: index === 0 ? 0 : 2,
                    }}
                  >
                    <Box sx={{ width: '13%' }}>
                      <Typography variant="body1">
                        {convertFormatDateTime({
                          value: _.get(item, 'date', null),
                          type: 'date',
                        })}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '16%' }}>
                      <TimePicker
                        id="startTime"
                        onChange={(event) =>
                          onChangeForm(
                            event,
                            'startTime',
                            false,
                            dayjs(item.date).format(
                              window.__env__.REACT_APP_DATE_DB,
                            ),
                            _.get(stateForm, 'academyActivity.expandDate', []),
                          )
                        }
                        value={
                          _.get(objExpandDate, 'startTime', '')
                            ? convertTime(_.get(objExpandDate, 'startTime', ''))
                            : ''
                        }
                        disabledStartTime={
                          _.get(objExpandDate, 'startTime', '')
                            ? convertTime(_.get(objExpandDate, 'startTime', ''))
                            : ''
                        }
                        disabledEndTime={
                          _.get(objExpandDate, 'endTime', '')
                            ? convertTime(_.get(objExpandDate, 'endTime', ''))
                            : ''
                        }
                        helperText={_.get(findRequireFile, 'startTime', '')}
                      />
                    </Box>
                    <Box sx={{ width: '16%' }}>
                      <TimePicker
                        id="endTime"
                        onChange={(event) =>
                          onChangeForm(
                            event,
                            'endTime',
                            false,
                            dayjs(item.date).format(
                              window.__env__.REACT_APP_DATE_DB,
                            ),
                            _.get(stateForm, 'academyActivity.expandDate', []),
                          )
                        }
                        value={
                          _.get(objExpandDate, 'endTime', '')
                            ? convertTime(_.get(objExpandDate, 'endTime', ''))
                            : ''
                        }
                        disabledStartTime={
                          _.get(objExpandDate, 'startTime', '')
                            ? convertTime(_.get(objExpandDate, 'startTime', ''))
                            : ''
                        }
                        disabledEndTime={
                          _.get(objExpandDate, 'endTime', '')
                            ? convertTime(_.get(objExpandDate, 'endTime', ''))
                            : ''
                        }
                        helperText={_.get(findRequireFile, 'endTime', '')}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                      }}
                    >
                      {meetingType === 'online' && (
                        <TextInput
                          id="onlineLink"
                          name="onlineLink"
                          type="basic"
                          placeholder={'URL'}
                          onChange={(value) =>
                            onChangeForm(
                              value,
                              'onlineLink',
                              false,
                              dayjs(item.date).format(
                                window.__env__.REACT_APP_DATE_DB,
                              ),
                              _.get(
                                stateForm,
                                'academyActivity.expandDate',
                                [],
                              ),
                            )
                          }
                          textError={_.get(findRequireFile, 'link', '')}
                          value={_.get(objExpandDate, 'link', '')}
                        />
                      )}
                    </Box>
                  </Box>
                  <FormSpeaker
                    validateForm={_.get(
                      validateForm,
                      `academyActivity.expandDate[${index}]`,
                      [],
                    )}
                    setValidateForm={setValidateForm}
                    stateForm={stateForm}
                    setStateForm={setStateForm}
                    indexExpand={index}
                    date={item.date}
                    optionSpeaker={listMeetingRoom}
                    speakers={_.get(objExpandDate, 'speakers', [])}
                    onChangeForm={onChangeForm}
                    staff={addOptionStaff(staff, listExpandDate, 'listStaff')}
                    responsibility={responsibility}
                    loadingDrawer={loadingDrawer}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      paddingLeft: '15%',
                      marginLeft: '3px',
                    }}
                  >
                    <Button
                      sx={{ minWidth: 100, height: '30px' }}
                      variant="contained"
                      onClick={() => {
                        handleExpand({
                          type: 'add',
                          indexExpand: index,
                          date: item.date,
                          stateForm,
                          setStateForm,
                        })
                      }}
                      color={'primary'}
                    >
                      เพิ่มวิทยากร
                    </Button>
                    <Button
                      sx={{ minWidth: 100, height: '30px' }}
                      variant="outlined"
                      color={'primary'}
                      onClick={() => setOpenBorrowDialog(true)}
                    >
                      ขอยืมวิทยากรโซนอื่น
                    </Button>
                  </Box>
                  <BorrowDrawer
                    field="academyActivity"
                    open={openBorrowDialog}
                    onClose={() => setOpenBorrowDialog(false)}
                    data={borrowData}
                    areaUuid={_.get(state, 'area.value', '')}
                    listResponsibility={_.get(options, 'responsibility', [])}
                    setStateForm={setStateForm}
                    speakerBorrows={speakerBorrows}
                  />

                  {speakerBorrows.length > 0 && (
                    <>
                      <Typography variant="body2b">
                        รายการขอยืมวิทยากรโซนอื่น
                      </Typography>

                      <FormListBorrow
                        stateForm={stateForm}
                        listSpeakerBorrow={item.speakerBorrow}
                        borrowData={borrowData}
                        handleClickOpen={handleClickOpen}
                        areaUuid={_.get(state, 'area.value', '')}
                        listResponsibility={_.get(
                          options,
                          'responsibility',
                          [],
                        )}
                        setStateForm={setStateForm}
                        field="academyActivity"
                        speakerBorrows={speakerBorrows}
                      />
                    </>
                  )}
                </Box>
              </>
            )
          })}

          <DialogForm
            state={state}
            open={openDialog}
            handleClose={handleClose}
            setOpenDialog={setOpenDialog}
            stateFormDialog={stateFormDialog}
            handleSubmitDialog={handleSubmitForm}
            listResponsibility={responsibility}
          />

          <AlertDialog
            open={dialog.open}
            setOpen={setDialog}
            handleClose={() => setDialog({ ...dialog, open: false })}
            title={dialog.title}
            variant={dialog.variant}
            onCancelClick={() => setDialog({ ...dialog, open: false })}
            onConfirmClick={dialog.onConfirmClick}
            content={dialog.content}
            agreeText={dialog.agreeText}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export const findUuid = (list, value) => {
  const result = list.find((item) => {
    return item.value === value
  })

  return result
}

export const findBorrowStaff = (listStaff) => {
  return {
    id: _.get(listStaff, 'id'),
    label:
      _.get(listStaff, 'area[0].station.station', '') +
      ' ' +
      _.get(listStaff, 'firstNameTH', '') +
      ' ' +
      _.get(listStaff, 'lastNameTH', ''),
    value: _.get(listStaff, 'uuid', ''),
  }
}

export const FormSpeaker = ({
  validateForm,
  stateForm,
  setStateForm,
  speakers,
  date,
  indexExpand,
  onChangeForm,
  staff,
  responsibility,
  loadingDrawer,
}) => {
  return (
    <>
      {!loadingDrawer &&
        speakers.map((item, indexSpeaker) => {
          const errorStaff = _.get(
            validateForm,
            `speakers[${indexSpeaker}].staff`,
            '',
          )
          const erorrRole = _.get(
            validateForm,
            `speakers[${indexSpeaker}].speakerRole`,
            '',
          )
          const errorStartTime = _.get(
            validateForm,
            `speakers[${indexSpeaker}].startTime`,
            '',
          )
          const errorEndTime = _.get(
            validateForm,
            `speakers[${indexSpeaker}].endTime`,
            '',
          )
          const isBorrow = _.get(item, 'listStaff.speakerBorrow', false)
          const defaultValue = isBorrow
            ? findBorrowStaff(_.get(item, 'listStaff', {}))
            : findUuid(staff, _.get(item, 'staff', ''))

          return (
            <Box
              key={indexSpeaker}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
              }}
            >
              <Box sx={{ width: speakers.length > 1 ? '52%' : '48%' }}></Box>
              <Box sx={{ width: '100%' }}>
                <Autocomplete
                  id="staff"
                  name="staff"
                  required
                  labelText="วิทยากร"
                  placeholder="เลือกวิทยากร"
                  type="basic"
                  options={staff}
                  disabled={isBorrow}
                  defaultValue={defaultValue}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="กรุณาเลือก"
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-staff',
                      }}
                    />
                  )}
                  handleChange={(event, value) => {
                    onChangeForm(
                      _.get(value, 'uuid', ''),
                      'staff',
                      true,
                      dayjs(date).format(window.__env__.REACT_APP_DATE_DB),
                      _.get(stateForm, 'academyActivity.expandDate', []),
                      indexSpeaker,
                    )
                  }}
                  value={_.get(item, 'staff', '')}
                  textError={errorStaff}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Select
                  dataTestid="select-speakerRole"
                  required
                  labelText="หน้าที่"
                  placeholder="เลือกหน้าที่"
                  id="speakerRole"
                  name="speakerRole"
                  type="basic"
                  options={responsibility}
                  disabled={_.get(item, 'listStaff.speakerBorrow', false)}
                  handleChange={(event) => {
                    onChangeForm(
                      event,
                      'speakerRole',
                      false,
                      dayjs(date).format(window.__env__.REACT_APP_DATE_DB),
                      _.get(stateForm, 'academyActivity.expandDate', []),
                      indexSpeaker,
                    )
                  }}
                  value={_.get(item, 'speakerRole', '')}
                  textError={erorrRole}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    mt: checkMarginTime(errorStaff, erorrRole, errorStartTime),
                  }}
                >
                  <Box>
                    <Typography variant="body1" sx={{ display: 'flex' }}>
                      เวลาเริ่ม
                      <Typography
                        sx={{ lineHeight: 1, ml: 0.5 }}
                        color={'error'}
                      >
                        *
                      </Typography>
                    </Typography>
                  </Box>

                  <TimePicker
                    id="startTimeSK"
                    disabled={_.get(item, 'listStaff.speakerBorrow', false)}
                    onChange={(event) =>
                      onChangeForm(
                        event,
                        'startTimeSK',
                        false,
                        dayjs(date).format(window.__env__.REACT_APP_DATE_DB),
                        _.get(stateForm, 'academyActivity.expandDate', []),
                        indexSpeaker,
                      )
                    }
                    value={
                      _.get(item, 'startTime', '')
                        ? convertTime(_.get(item, 'startTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(item, 'startTime', '')
                        ? convertTime(_.get(item, 'startTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(item, 'endTime', '')
                        ? convertTime(_.get(item, 'endTime', ''))
                        : ''
                    }
                    helperText={_.get(
                      validateForm,
                      `speakers[${indexSpeaker}].startTime`,
                      '',
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    mt: checkMarginTime(errorStaff, erorrRole, errorEndTime),
                  }}
                >
                  <Box>
                    <Typography variant="body1" sx={{ display: 'flex' }}>
                      เวลาสิ้นสุด
                      <Typography
                        sx={{ lineHeight: 1, ml: 0.5 }}
                        color={'error'}
                      >
                        *
                      </Typography>
                    </Typography>
                  </Box>
                  <TimePicker
                    id="endTimeSK"
                    disabled={_.get(item, 'listStaff.speakerBorrow', false)}
                    onChange={(event) =>
                      onChangeForm(
                        event,
                        'endTimeSK',
                        false,
                        dayjs(date).format(window.__env__.REACT_APP_DATE_DB),
                        _.get(stateForm, 'academyActivity.expandDate', []),
                        indexSpeaker,
                      )
                    }
                    value={
                      _.get(item, 'endTime', '')
                        ? convertTime(_.get(item, 'endTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(item, 'startTime', '')
                        ? convertTime(_.get(item, 'startTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(item, 'endTime', '')
                        ? convertTime(_.get(item, 'endTime', ''))
                        : ''
                    }
                    helperText={_.get(
                      validateForm,
                      `speakers[${indexSpeaker}].endTime`,
                      '',
                    )}
                  />
                </Box>
                {!_.get(item, 'listStaff.speakerBorrow', false) ? (
                  <Box sx={{ mt: errorStaff || erorrRole ? -2 : 1 }}>
                    <DeleteTwoToneIcon
                      onClick={() =>
                        handleExpand({
                          type: 'delete',
                          indexExpand,
                          indexSpeaker,
                          date,
                          stateForm,
                          setStateForm,
                        })
                      }
                    />
                  </Box>
                ) : (
                  <Box sx={{ mr: 3 }}></Box>
                )}
              </Box>
            </Box>
          )
        })}
    </>
  )
}

export const checkMarginTime = (errorStaff, erorrRole, error) => {
  let result = '-28px'
  if ((!_.isEmpty(errorStaff) || !_.isEmpty(erorrRole)) && _.isEmpty(error))
    result = '-52px'
  if (_.isEmpty(errorStaff) && _.isEmpty(erorrRole) && !_.isEmpty(error))
    result = '-3px'
  return result
}
