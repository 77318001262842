import _ from 'lodash'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  resetPage,
} from '../../../../../../redux/slices/table'
import { downloadFile } from '../../../../../../services/util'
import { initialState } from '../../../../../../redux/slices/table/model'
import callAxios from '../../../../../../utils/baseService'
import { headCellsLearner } from '../../../../../../constants/eLearning'
import {
  eLearningCourseLearnerDownload,
  eLearningCourseLearnerFilter,
} from '../../../../../../utils/apiPath'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected.map((item) => _.get(item?.split('?'), '0', '')),
  }

  await dispatch(
    downloadFile({
      url: eLearningCourseLearnerDownload,
      body: body,
      fileName: 'รายการผู้เรียนE-Learning.xlsx',
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList = (props) => async (dispatch) => {
  const { method, table, page, status, filterProp, keyword, setInitial } = props
  const courseUuid = window.location.pathname.split('/').pop()
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const title = isInitial ? '' : _.get(filter, 'name', keyword)
  const body = {
    limit: _.get(tableProps, 'limit', '20'),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    trainingResult: [],
    status: isInitial ? [] : status,
    courseUuid,
    codeId: _.get(filter, 'codeId', ''),
    name: method === 'filter' ? keyword : title,
    attendance: null,
    startDate: '',
    finishDate: '',
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  dispatch(startLoading())
  const isCancel = await callAxios
    .post(eLearningCourseLearnerFilter, body)
    .then((res) => {
      const rows = _.get(res, 'data.result', []).map((item) => ({
        ...item.userProfile,
        ...item,
        uuid: `${item.uuid}?course=${courseUuid}`,
      }))
      dispatch(
        setInitialTable({
          rows,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCellsLearner,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
        }),
      )
    })
    .catch((e) => {
      dispatch(
        setInitialTable({ rows: [], allCount: 0, headCells: headCellsLearner }),
      )
      return e.message?.includes('method')
    })

  setInitial && setInitial(false)
  if (!isCancel) dispatch(stopLoading())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  const filterProp = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      status: [],
      filterProp,
      keyword: filterProp.name,
    }),
  )
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({ method: 'search', table, page: 1, status: [], keyword }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter([]))
  }
}
