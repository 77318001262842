import React from 'react'
import { Typography, Box } from '@mui/material'
import { breadcrumb } from './model/breadcrumb'
import { getModuleTitle } from '../utils'
import Button from '@mui/material/Button'
import Breadcrumbs from '../../../../components/Breadcrumbs'
// import { handleSubmitForm } from './handler/handleSubmitForm'
import { BoxButton, BoxHeader } from './Styled'
import { formValidationSchema } from './schema/schema'
import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../utils/lib'
import { handleSubmitClick } from '../../../../components/CRUD/handler/handleSubmitClick'
import { postBodyMutation } from './mutation/post'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../constants/stock'

const Header = (props) => {
  const { uuid, crudConfig } = props

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })
  return (
    <BoxHeader>
      <Box>
        <Typography variant="h4">
          {getModuleTitle(uuid, crudConfig.modulesTitleShort)}
        </Typography>
        <Breadcrumbs menuList={breadcrumb(uuid)} />
      </Box>
      <BoxButton>
        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={!hasPermissionCreate}
          // onClick={() => handleSubmitForm({validationSchema: formValidationSchema})}
          onClick={() =>
            handleSubmitClick(uuid, postBodyMutation, formValidationSchema)
          }
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}

export default Header
