import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Container, Dot } from './Styled'
import { Row, Column } from '../../Styled'
import { StyledButton } from '../OverviewCourseSection/Styled'

const ModuleCard = ({
  name,
  label,
  background,
  dotColor,
  value,
  unit,
  percent,
}) => {
  return (
    <Container background={background}>
      <Typography variant="body2b" sx={{ position: 'relative', mb: 1 }}>
        <Dot background={dotColor} />
        {label}
      </Typography>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Column>
          <Row sx={{ gap: 1 }}>
            <Typography
              variant="h4"
              sx={{
                lineHeight: '20px',
                mt: 1,
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{
                lineHeight: '18px',
                mt: 'auto',
              }}
            >
              {unit}
            </Typography>
          </Row>
          <Typography variant="body2" color="text.lightGray">
            {percent}
          </Typography>
        </Column>
        <Column>
          <StyledButton
            color="text.lightGray"
            size="small"
            onClick={() =>
              window.open(`/e-learning/module?moduleType=${name}`, '_blank')
            }
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        </Column>
      </Row>
    </Container>
  )
}

export default ModuleCard
