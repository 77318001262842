import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 48px)',
  alignItems: 'center',
  marginLeft: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    paddingTop: theme.spacing(2),
  },
}))

export const BoxNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  '& > hr': { display: 'none' },
  '& > .MuiTypography-root': { marginTop: theme.spacing(1.5), minWidth: 60 },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '& > hr': { display: 'block' },
    '& > .MuiTypography-root': { marginTop: 0, minWidth: 0 },
  },
}))

export const BoxInput = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 0,
  },
}))

export const ContentInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  minWidth: '50%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const BoxIcon = styled(Box)(({ theme, isTop }) => ({
  display: isTop ? 'none' : 'flex',
  gap: theme.spacing(3),
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: isTop ? 'block' : 'none',
    width: 26,
    height: 20,
    '& > button': { width: 26, height: 24 },
  },
}))
