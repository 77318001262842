export const defaultFilter = {
  nameChecked: false,
  name: '',
  stockChecked: false,
  stock: '',
  stationChecked: false,
  station: '',
  availableChecked: false,
  availableFrom: '',
  availableTo: '',
  totalChecked: false,
  totalFrom: '',
  totalTo: '',
  stockValueChecked: false,
  stockValueFrom: '',
  stockValueTo: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  statusWarehouse: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
