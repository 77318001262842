import _ from 'lodash'
import dayjs from 'dayjs'

import { callAPI } from '../../../utils/lib'
import {
  classroomFilter,
  course,
  distributionChannelFilter,
  filterMeetingRoom,
  filterTrainingPlatform,
  monthlyPlanCostApproveStatus,
  responsibilityFilter,
  trainingPlanAdminStaff,
  trainingPlanExternalSpeaker,
  staffProfileArea,
  staffProfileInAreaDetail,
} from '../../../utils/apiPath'

// ? วิทยากร
export const fetchStaff = async ({ area, date }) => {
  let data = []
  try {
    const result = await callAPI({
      url: `${staffProfileInAreaDetail}/${area}/${date.month}/${date.year}`,
      method: 'get',
      onError: (er) => {
        console.log('fetch Staff : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          id: d.id || '',
          label:
            `${_.get(d, 'area[0].station.station', '')} ${_.get(
              d,
              'firstNameTH',
              ''
            )}  ${_.get(d, 'lastNameTH', '')}` || '',
          value: d.uuid,
          uuid: d.uuid,
        },
      ]
    })
  } catch (error) {
    console.log('fetchStaff : error => ', error)
  }

  return data
}
// ? หน้าที่
export const fetchResponsibility = async () => {
  let data = []

  const body = {
    responsibility: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  try {
    const result = await callAPI({
      url: responsibilityFilter,
      method: 'post',
      body: body,
      onError: (er) => {
        console.log('fetch Responsibility : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          id: d.id || '',
          label: d.responsibility || '',
          value: d.uuid,
          uuid: d.uuid,
        },
      ]
    })
  } catch (error) {
    console.log('map Responsibility : error => ', error)
  }
  return data
}
// ? รูปแบบการจัดห้องเรียน
export const fetchClassroom = async () => {
  let data = []

  const body = {
    classroom: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  try {
    const result = await callAPI({
      url: classroomFilter,
      method: 'post',
      body: body,
      onError: (er) => {
        console.log('fetch Classroom : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          label: d.classroom || '',
          value: d.uuid,
          id: d.id,
        },
      ]
    })
  } catch (error) {
    console.log('map Classroom : error => ', error)
  }
  return data
}
// ? ช่องทางการจำหน่าย
export const fetchDistributionChannel = async () => {
  let data = []

  const body = {
    abbreviation: '',
    distribution: '',
    level: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  try {
    const result = await callAPI({
      url: distributionChannelFilter,
      method: 'post',
      body: body,
      onError: (er) => {
        console.log('fetch distribution : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          label: d.distribution || '',
          title: d.distribution || '',
          value: d.uuid || '',
          uuid: d.uuid || '',
          id: d.id || '',
        },
      ]
    })
  } catch (error) {
    console.log('map distribution : error => ', error)
  }
  return data
}

// ? รุปแบบการสอน
export const fetchTrainingPlatform = async () => {
  let data = []

  const body = {
    trainingPlatform: '',
    abbreviation: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  try {
    const result = await callAPI({
      url: filterTrainingPlatform,
      method: 'post',
      body: body,
      onError: (er) => {
        console.log('fetch distribution : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          ...d,
          label: d.trainingPlatform || '',
          title: d.trainingPlatform || '',
          value: d.uuid || '',
          uuid: d.uuid || '',
          id: d.id || '',
        },
      ]
    })
  } catch (error) {
    console.log('map distribution : error => ', error)
  }
  return data
}

// ? หลักสูตร
export const fetchCourse = async ({
  setCourse,
  setLoading,
  setStateForm,
  startDate,
  finishDate,
  keyStateForm = 'Training',
}) => {
  setLoading && setLoading(true)

  let data = []
  try {
    if (startDate === null || finishDate === null) {
      setLoading && setLoading(false)
      return []
    }
    const convertStartDate = dayjs(startDate).format(
      window.__env__.REACT_APP_DATE_DB
    )
    const convertFinishDate = dayjs(finishDate).format(
      window.__env__.REACT_APP_DATE_DB
    )
    const result = await callAPI({
      url: `${course}/ongoing?start=${convertStartDate}&finish=${convertFinishDate}`,
      method: 'get',
      onError: (er) => {
        console.log('fetch Course : error => ', er)
      },
    })
    result.forEach((d) => {
      data = [
        ...data,
        {
          ...d,
          id: d.id,
          label: `${d.courseCode} ${d.englishName}` || '',
          value: d.uuid,
          trainingPlatform: d.trainingPlatform,
          breakMealBudget: d.breakMealBudget,
        },
      ]
    })
  } catch (error) {
    console.log('map Course : error => ', error)
  }

  setCourse(data)
  setStateForm &&
    setStateForm((prevState) => ({
      ...prevState,
      [keyStateForm]: {
        ...prevState[keyStateForm],
        course: {
          label: '',
          value: '',
        },
      },
    }))
  setLoading && setLoading(false)

  return data
}
export const createTrainingAll = async ({ url, body }) => {
  let result = {
    status: true,
    value: [],
    text: 'วิทยากรและผู้เข้าร่วมประชุม',
  }

  if (!_.isEmpty(body)) {
    const startDate = dayjs(_.get(body, 'startDate', null))
    const finishDate = dayjs(_.get(body, 'finishDate', null))
    body = {
      ...body,
      startDate: dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB),
      finishDate: dayjs(finishDate).format(window.__env__.REACT_APP_DATE_DB),
    }

    const { typeDate, dates } = body

    if (!_.isNil(dates) && dates.length > 1 && typeDate === 'DUPLICATE') {
      body = {
        ...body,
        dates: dates.map((item) => {
          return { ...item, typeTime: dates[0].typeTime }
        }),
      }
    }
  }
  await callAPI({
    url,
    body: body,
    method: 'post',
    onSuccess: (res) => {
      result = {
        ...result,
        value: res,
      }
    },
    onError: (error) => {
      result = {
        ...result,
        status: false,
        value: _.get(error, 'response.data', {}),
      }
    },
  })

  return result
}

export const submitApproveAction = ({ body, value, onSuccess }) => {
  body.rejectReason = value.reason
  if (body.status === 'APPROVED') {
    body.rejectReason = ''
  }
  callAPI({
    method: 'put',
    body: body,
    url: monthlyPlanCostApproveStatus,
    onSuccess: onSuccess,
  })
}

// ? วิทยากรภายนอก
export const fetchTrainingPlanExternalSpeaker = async () => {
  let result = []
  try {
    const getResult = await callAPI({
      url: trainingPlanExternalSpeaker,
      method: 'get',
    })

    result = getResult.length
      ? getResult.map((item) => {
          return {
            ...item,
            label:
              _.get(item, 'firstNameTH', '') +
              ' ' +
              _.get(item, 'lastNameTH', ''),
            value: _.get(item, 'uuid', ''),
          }
        })
      : []
  } catch (error) {
    console.log(' create Training : error => ', error)
    result = _.get(error, 'response.data', {})
  }

  return result
}

// ? admin
export const fetchTrainingPlanAdminStaff = async ({ id }) => {
  let result
  try {
    result = await callAPI({
      url: trainingPlanAdminStaff + '/' + id,
      method: 'get',
    })
  } catch (error) {
    console.log(' create Training : error => ', error)
    result = _.get(error, 'response.data', {})
  }

  return result
}

// ? ห้องเรียน
export const fetchMeetingRoom = async () => {
  let data = []
  const body = {
    stationUuid: [],
    roomName: '',
    roomFunction: [],
    roomType: [],
    roomLayout: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  try {
    const resultValue = await callAPI({
      url: filterMeetingRoom,
      body: body,
      method: 'post',
    })
    _.get(resultValue, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          ...d,
          id: d.id || '',
          label: d.station.station + ' ' + d.roomName,
          value: d.uuid,
          uuid: d.uuid,
        },
      ]
    })
  } catch (error) {
    console.log(' create Training : error => ', error)
    data = {
      ...data,
      status: false,
      value: _.get(error, 'response.data', {}),
    }
  }

  return data
}

export const submitTrainingBudgetAction = ({
  uuid,
  path,
  body,
  onSuccess,
  onError,
}) => {
  callAPI({
    method: 'put',
    body: body,
    url: `${path}/${uuid}`,
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}

// ? วิทยากร area
export const fetchStaffArea = async () => {
  let data = []

  try {
    const result = await callAPI({
      url: `${staffProfileArea}`,
      method: 'get',
      onError: (er) => {
        console.log('fetch Staff : error => ', er)
      },
    })

    _.get(result, 'result', []).forEach((d) => {
      data = [
        ...data,
        {
          id: d.id || '',
          label:
            `${_.get(d, 'area[0].station.station', '')} ${_.get(
              d,
              'firstNameTH',
              ''
            )}  ${_.get(d, 'lastNameTH', '')}` || '',
          value: d.uuid,
          uuid: d.uuid,
        },
      ]
    })
  } catch (error) {
    console.log('fetchStaff : error => ', error)
  }

  return data.length ? _.sortBy(data, ['label']) : []
}
