import React from 'react'
import TableCell from '@mui/material/TableCell'
import _ from 'lodash'

import { checkConditionETestEEva } from '../../../../modules/ManageClass/Detail/ETestEEvaList/events'
import Dot from '../../Dot'
import Truncate from '../Truncate'
import Action from '../Action'

export const checkConditionView = (headCells, row, rowIndex, cellIndex) => {
  const columnKey = headCells[cellIndex].id
  const minWidth = columnKey == 'name' ? 380 : 100
  const sx = GetSxStyle(row, minWidth)

  const isCustom = _.get(headCells, `[${cellIndex}].custom`, false)
  const customWidth = _.get(headCells, `[${cellIndex}].customWidth`, 150)

  if (isCustom) {
    return (
      <TableCell key={columnKey} sx={{ minWidth: customWidth }}>
        {row[columnKey]}
      </TableCell>
    )
  }

  if (columnKey === 'status') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={row[columnKey]} />
      </TableCell>
    )
  }

  if (columnKey === 'action') {
    return (
      <TableCell key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  }

  return (
    <TableCell key={columnKey} sx={sx}>
      <Truncate>{'' + _.get(row, `${columnKey}`, '-') || '-'}</Truncate>
    </TableCell>
  )
}

export const GetSxStyle = (row, minWidth) => {
  const status = _.get(row, 'status', '')
  if (status === 'DELETED') {
    return { minWidth, color: 'text.gray' }
  } else {
    return { minWidth }
  }
}

export const handleRowsCondition = (headCells, row, rowIndex) => {
  if (headCells) {
    return headCells.map((item, cellIndex) => {
      if (item.isETestEEva) {
        return checkConditionETestEEva(row, rowIndex, cellIndex)
      }

      return checkConditionView(headCells, row, rowIndex, cellIndex)
    })
  }
}
