import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eTestingVersion } from './../../utils/apiPath'

export const getDetail = createAsyncThunk(
  'eTestingDetail/getDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eTestingVersion}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
