import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import { fetchOptionsReportByKey } from './fetchOptionsReportByKey'
import { fetchMasterDataOptions } from './fetchMasterDataOptions'

export const fetchOptionsDashboardFilter = async (
  filterItems,
  uuid,
  courseCode,
) => {
  let options = {}
  for (const data of filterItems) {
    try {
      let response = []
      if (data.fetchOptions === 'ByKey') {
        response = await fetchOptionsReportByKey(
          data.nameInput,
          uuid,
          courseCode,
        )
      } else if (data.fetchOptions === 'masterData') {
        response = await fetchMasterDataOptions(data.nameInput)
      } else response = undefined
      if (data.nameInput === 'agentCode')
        for (const obj of response) {
          obj.value = obj.label
        }
      options = { ...options, [data.nameInput]: response }
    } catch (e) {
      handleFetchError(e)
    }
  }
  store.dispatch(
    setFieldValue({
      key: `filterList`,
      value: options,
    }),
  )
}
