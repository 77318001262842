import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useSelector, shallowEqual } from 'react-redux'

import Score from '../Score'
import Question from '../Question'

import * as StyledForm from '../Styled'
import * as Styled from './Styled'

const Index = ({ keyQuestion, indexQuestion }) => {
  const question = useSelector(
    (state) =>
      state.eTestingForm.questions.find((item) => item.key === keyQuestion),
    shallowEqual
  )
  const { loadingProgress } = question

  return (
    <>
      <Card>
        <StyledForm.BoxForm>
          <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />

          <Styled.BoxTextAlert loadingProgress={loadingProgress}>
            <InfoOutlinedIcon color="warning" />
            <Typography variant="body1b" color="warning.main">
              ตรวจคำตอบและให้คะแนนโดยวิทยากร
            </Typography>
          </Styled.BoxTextAlert>
        </StyledForm.BoxForm>

        <Score keyQuestion={keyQuestion} />
      </Card>
    </>
  )
}
export default Index
