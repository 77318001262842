import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { styledImageBoxProps } from '../../Styled'

export const StyledImageBox = styled(Box)(
  ({ theme, isLoading, isError, isInfo }) => ({
    width: isInfo ? '280px' : 'auto',
    maxWidth: '280px',
    height: '105px',
    borderRadius: theme.spacing(1),
    ...styledImageBoxProps(theme, isLoading, isError),
  })
)

export const StyledImageInfo = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  maxWidth: '280px',
  height: '105px',
  borderRadius: '8px',
}))
