import { COURSE_STATUS, MODULE_STATUS } from '../../../constants/eLearning'

export const breadcrumbList = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Learning path', link: '/', pointer: false },
]

export const breadcrumbCreate = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Learning path', link: '/e-learning/learning-path', pointer: true },
  {
    title: 'สร้าง Learning path (เส้นทางการเรียนรู้)',
    link: '/',
    pointer: false,
  },
]

export const breadcrumbEdit = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Learning path', link: '/e-learning/learning-path', pointer: true },
  {
    title: 'แก้ไข Learning path (เส้นทางการเรียนรู้)',
    link: '/',
    pointer: false,
  },
]

export const breadcrumbView = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Learning path', link: '/e-learning/learning-path', pointer: true },
  { title: 'รายละเอียด Learning path', link: '/', pointer: false },
]

export const DEFUALT_PATH_STATUS = [
  MODULE_STATUS.ACTIVE,
  MODULE_STATUS.INACTIVE,
  MODULE_STATUS.DELETED,
  COURSE_STATUS.DRAFT,
]
export const DEFUALT_COURSE_STATUS = [
  COURSE_STATUS.WAITING,
  COURSE_STATUS.INPROGRESS,
  COURSE_STATUS.SUCCESS,
  COURSE_STATUS.CANCEL,
  COURSE_STATUS.DRAFT,
]
