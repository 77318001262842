import {
  setChangeQuestionByKey,
  setChangeSubQuestionByKey,
} from '../../../../../redux/slices/eEvaluationForm'

export const handelDeleteVideo = (props) => async (dispatch) => {
  props.isSubQuestions
    ? dispatch(setChangeSubQuestionByKey(props))
    : dispatch(setChangeQuestionByKey(props))
}
