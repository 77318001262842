import React from 'react'
import Box from '@mui/material/Box'
import Content from '../components/Content'
import LearningPathTitle from '../components/LearningPathTitle'
import CourseTitle from '../components/CourseTitle'
import ChipContainer from '../components/ChipContainer'
import { CourseAssignee } from '../components/CourseAssignee'
import { StyledContentBox, StyledTitleBox } from '../Styled'

const MobileContent = ({ course, isPath }) => {
  return (
    <Box>
      <StyledContentBox id="content">
        <StyledTitleBox id="title">
          {isPath ? (
            <LearningPathTitle course={course} />
          ) : (
            <>
              <CourseTitle course={course} />
              <CourseAssignee course={course} />
            </>
          )}

          <ChipContainer course={course} />

          <Content course={course} />
        </StyledTitleBox>
      </StyledContentBox>
    </Box>
  )
}

export default MobileContent
