import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import { responsiveButton } from '../Styled'
import { handleSubmit } from './event'

const SubmitBtn = ({ isDisplayAll }) => {
  const dispatch = useDispatch()
  const { setting, isLoading, isButtonLoading } = useSelector(
    (state) => ({
      setting: state.eTestingPreview.setting,
      isLoading: state.eTestingPreview.isLoading,
      isButtonLoading: state.eTestingPreview.isButtonLoading,
    }),
    shallowEqual
  )
  const buttonSize = responsiveButton()

  return (
    <>
      {isDisplayAll ? (
        <Button
          type="submit"
          variant="contained"
          size={buttonSize}
          data-testid="btn-submit"
          disabled={isLoading}
          onClick={() => {
            dispatch(handleSubmit())
          }}
        >
          ส่งคำตอบ
        </Button>
      ) : (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          loading={isButtonLoading}
          loadingPosition="center" // end ? center ?
          data-testid="btn-submit-loading"
          disabled={isLoading}
          onClick={() => dispatch(handleSubmit(setting))}
        >
          ส่งคำตอบ
        </LoadingButton>
      )}
    </>
  )
}

export default SubmitBtn
