import React from 'react'
import { Column } from '../../../Styled'
import { CustomTextArea } from '../../../../../../../components/CRUD/components/CustomTextArea'
import Divider from '@mui/material/Divider'
import { useDispatch } from 'react-redux'
import UploadFile from '../../../../../../../components/UploadFile'
import { SIZE_TYPE } from '../../../../../../../constants/eConfig'
import { setFieldValue } from '../../../../../../../redux/slices/crud'
import { UPLOAD_TYPE } from '../../../../../../../constants/eLearning'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useInputField } from '../../../../../../../components/CRUD/hooks/useInputField'

const KB = ({ index }) => {
  const dispatch = useDispatch()

  const { value, isError, message } = useInputField(
    `contents[${index}].content.files`,
  )

  return (
    <Column sx={{ gap: 2 }}>
      <div>
        <Typography variant="body1b">คำถาม</Typography>
        <CustomTextArea
          content-tab="content"
          fieldName={`contents[${index}].content.question`}
          labelName=""
          hideLabel={true}
        />
      </div>

      <Divider />
      <div>
        <Typography variant="body1b">คำตอบ</Typography>
        <CustomTextArea
          content-tab="content"
          fieldName={`contents[${index}].content.answer`}
          labelName=""
          hideLabel={true}
        />
      </div>

      <div>
        <Typography variant="body1b">รูปภาพ</Typography>
        <UploadFile
          value={value}
          label=""
          maxFileSize={{
            size: 2,
            sizeType: SIZE_TYPE.MB,
          }}
          maxLength={4}
          isMultiple={true}
          isForImage={true}
          folder="e-content"
          onChange={(files) => {
            dispatch(
              setFieldValue({
                key: `formData.contents[${index}].content.files`,
                value: files,
              }),
            )
          }}
          uploadType={UPLOAD_TYPE.GENERAL}
          accept={{
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
          }}
          acceptLabel=".jpg, .jpeg, .png"
          fieldName={`file-${index}`}
        />
        {isError && (
          <Box>
            {message && message !== '' && (
              <Typography variant="body2" color="error">
                {message}
              </Typography>
            )}
          </Box>
        )}
      </div>
    </Column>
  )
}

export default KB
