import { shallowEqual, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Header from './components/Header'
import Content from './components/Content'
import { useEffect } from 'react'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { initialize } from './handler/initialize'
import FilterDrawer from './Drawers/FilterDrawer'
import AgencyDrawer from './Drawers/AgencyDrawer'
// import _ from 'lodash'

const Index = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  useEffect(initialize, [])
  return (
    <Box px={2}>
      <Box>
        <Header />
      </Box>
      <Box my={3}>
        <Content />
      </Box>

      <FilterDrawer />
      <AgencyDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default Index
