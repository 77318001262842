import React from 'react'
import { useDispatch } from 'react-redux'
import Divider from '@mui/material/Divider'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { handleChangeEvent } from '../events'
import ChipForm from './ChipForm'
import RadioCheckboxForm from './RadioCheckboxForm'
import { useInputField } from '../../../../../../components/CRUD/hooks/useInputField'

const Configuration = () => {
  const dispatch = useDispatch()
  const {
    value: settingRemarkValue,
    isError: isSettingRemarkError,
    message: settingRemarkMessage,
  } = useInputField('setting.remark')

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <ChipForm />

      <RadioCheckboxForm />

      <Divider sx={{ mx: 0.5 }} />

      <TextInput
        multiline
        rows={3}
        name="setting.remark"
        type="basic"
        labelText="Remark"
        id="setting.remark"
        textError={settingRemarkMessage}
        isShowTextError={isSettingRemarkError}
        value={settingRemarkValue}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default Configuration
