import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import EditSharp from '@mui/icons-material/EditSharp'
import HistoryDrawer from '../../HistoryDrawer'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import DropdownButton from './DropdownButton'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledBoxButton, StyledHeader } from './Styled'

const breadcrumbMeetingRoomView = [
  { title: 'Master Data', link: '/', pointer: false },
  {
    title: 'ห้องประชุม',
    link: '/master-data/meeting-room',
    pointer: true,
  },
  {
    title: 'รายละเอียดห้องประชุม',
    link: '/',
    pointer: false,
  },
]

const Index = ({ uuid, componentRef }) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { status, roomName } = useSelector(
    (state) => ({
      status: state.masterDataMeetingRoomForm.information.status,
      roomName: state.masterDataMeetingRoomForm.information.roomName,
    }),
    shallowEqual
  )
  const isDeleted = status === 'DELETED'

  return (
    <StyledHeader>
      <Box>
        <Typography variant="h4">{`รายละเอียดห้องประชุม`}</Typography>
        <Breadcrumbs menuList={breadcrumbMeetingRoomView} />
      </Box>
      <StyledBoxButton>
        <Button
          data-testid="btn-history"
          startIcon={<HistoryTwoTone color="primary.main" />}
          variant="outlined"
          size="m"
          onClick={() => setIsOpen(true)}
        >
          ประวัติการแก้ไข
        </Button>
        {!isDeleted && (
          <Button
            data-testid="btn-edit"
            startIcon={<EditSharp color="primary.contrast" />}
            variant="contained"
            size="m"
            onClick={() =>
              history.push(`/master-data/meeting-room/edit/${uuid}`)
            }
          >
            แก้ไข
          </Button>
        )}
        <DropdownButton componentRef={componentRef} roomName={roomName} />
      </StyledBoxButton>

      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </StyledHeader>
  )
}

export default Index
