import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'
import { setHeadCells } from '../../../../../redux/slices/table'

export const handleCancelEdit = () => {
  const { rows, headCells } = store.getState().table.table
  store.dispatch(
    setFieldValue({
      key: 'isHideEditAndApprove',
      value: true,
    }),
  )
  const modifiedHeadCells = _.defaultTo(headCells, []).map((item) => {
    let label = item?.label
    if (item.id === 'transferEditInput') label = ' '
    if (item.id === 'transferEditRemark') label = ' '
    return {
      ...item,
      label,
    }
  })
  store.dispatch(setHeadCells(modifiedHeadCells))
  if (rows?.length > 0) {
    rows.map((item, index) => {
      store.dispatch(
        setFieldValue({
          key: `formData.products[${index}]`,
          value: item,
        }),
      )
    })
  }
}
