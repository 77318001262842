import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import {
  backgroundStatus,
  defaultValue,
} from '../../../constants/eCertification'

const eCertificateForm = createSlice({
  name: 'eCertificateForm',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setLayouts: (state, { payload }) => {
      state.layouts = payload.layouts
    },
    setFetchData: (state, { payload }) => {
      state.codeId = payload.codeId
      state.uuid = payload.uuid
      state.name = payload.name
      state.imageKey = payload.imageKey
      state.status = payload.status
      state.isDelete = payload.isDelete
      state.isLoading = payload.isLoading
      state.createdBy = payload.createdBy
      state.createdAt = payload.createdAt
      state.updatedBy = payload.updatedBy
      state.updatedAt = payload.updatedAt
      state.layouts = payload.layouts
    },
    setFetchDataUuid: (state, { payload }) => {
      state.activeDate = payload.activeDate
      state.formName = payload.name
      state.formCodeId = payload.codeId
      state.formVersion = payload.version
      state.formLayout = payload.layout
      state.courseVersion = payload.courseVersion
      state.formBackground = {
        codeId: payload.background.codeId,
        uuid: payload.background.uuid,
        imageKey: payload.background.imageKey,
        name: payload.background.name,
      }
      state.status = payload.status
      state.listAssignee = payload.listAssignee
      state.createdBy = payload.createdBy
      state.createdAt = payload.createdAt
      state.updatedBy = payload.updatedBy
      state.updatedAt = payload.updatedAt
      state.assignees = payload.assignees
      state.startDate = payload.startDate
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `${key}`, value)
    },
    setClearData: (state) => {
      state.codeId = ''
      state.uuid = ''
      state.name = ''
      state.imageKey = ''
      state.activeDate = ''
      state.status = backgroundStatus.ACTIVE
      state.isDelete = false
      state.isLoading = false
      state.createdBy = ''
      state.createdAt = ''
      state.updatedBy = ''
      state.updatedAt = ''
      state.startDate = ''
      state.layouts = {
        totalCount: 0,
        result: [],
      }
      state.backgrounds = {
        totalCount: 0,
        result: [],
      }
      state.assignees = []
      state.listAssignee = []
      state.defaultTap = defaultValue.defaultTap.certificate
      state.formName = ''
      state.formCodeId = ''
      state.formVersion = ''
      state.formReasonEdit = ''
      state.formLayout = {
        id: 0,
        name: '',
        layouts: [],
      }
      state.formBackground = {
        uuid: '',
        name: '',
        codeId: '',
        imageKey: '',
      }
      state.errors = {
        name: '',
        layout: '',
        background: '',
        reason: '',
      }
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setFetchData,
  setFetchDataUuid,
  setChange,
  setClearData,
  setLayouts,
} = eCertificateForm.actions

export default eCertificateForm.reducer
