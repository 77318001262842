import { Box, Typography, Button } from '@mui/material'
import { FilterBox } from '../components/filterBox'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import dayjs from 'dayjs'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { handleSetIsOpenFilterDrawer } from '../handler/handleFilterDrawer'

const index = () => {
  const { filterTotal, latestUpdated } = useSelector(
    (state) => ({
      filterTotal: state.crud.manageClassDashboard?.filters?.filterTotal,
      latestUpdated: state.crud.manageClassDashboard?.latestUpdated,
    }),
    shallowEqual,
  )
  return (
    <Box display="flex" justifyContent="space-between" alignItems="start">
      <Box>
        <Typography variant="h4">Manage class Dashboard</Typography>
        <FilterBox />
        <Typography variant="body2">
          ข้อมูลเมื่อวันที่{' '}
          {latestUpdated ?? dayjs().format('DD/MM/YYYY 01:30')}
        </Typography>
      </Box>

      <Box>
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, py: 1, px: 2 }}
          variant="text"
          startIcon={<FilterListOutlinedIcon />}
          onClick={() => handleSetIsOpenFilterDrawer(true)}
        >
          <Typography variant="text">
            ตัวกรอง {filterTotal ? `(${filterTotal})` : ''}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default index
