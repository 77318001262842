import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import TimePicker from '../../../../components/TimePicker/TimePicker'

import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import { StyledCard, StyledFormWrapper } from '../../Styled'
import { 
    handleNumberInput,
    handleNumberKeyDown,
    handlePasteFormat,
} from '../../../../utils/lib'
import { FileTypes } from '../../model'
import dayjs from 'dayjs'
import { clearLeadingZeros } from '../event'

const ELearningForm = ({
    formik,
    body,
    setInputValue,
    isHide,
}) => {
    return (
        <StyledFormWrapper isHide={isHide}>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        การสร้างโมดูล
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                ขนาดของไฟล์ที่อนุญาตให้อัปโหลด
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_UPLOAD_MAX_FILE_SIZE"
                                name="EL_UPLOAD_MAX_FILE_SIZE"
                                labelText="ขนาดต้องไม่เกิน"
                                placeholder={body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'GB' ? "Ex. 1" :"Ex. 0.1 - 1023"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_UPLOAD_MAX_FILE_SIZE',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { 
                                        if(body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'GB')
                                            handleNumberKeyDown(e, ['.'])
                                        else (body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'MB')
                                            handleNumberKeyDown(e)
                                    },
                                    onPaste: handlePasteFormat,
                                    maxLength: 4,
                                    min: 0.1,
                                    max: 1023,
                                }}
                                textError={formik?.errors?.EL_UPLOAD_MAX_FILE_SIZE?.size}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_UPLOAD_MAX_FILE_SIZE.size}
                                onChange={(e) => {
                                    if(body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'GB' && e.target.value > 1){
                                        e.target.value = 1
                                    }
                                    if(body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'MB' && (e.target.value > 1023) ){
                                        if(e.target.value > 1023){
                                            e.target.value = body.EL_UPLOAD_MAX_FILE_SIZE.size
                                        }
                                        e.target.value = body.EL_UPLOAD_MAX_FILE_SIZE.size
                                    }
                                    const value = e.target.value
                                    e.target.value = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value
                                    setInputValue('EL_UPLOAD_MAX_FILE_SIZE.size', clearLeadingZeros(e.target.value))
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Select
                                dataTestid="select-EL_UPLOAD_MAX_FILE_SIZE_TYPE"
                                id="EL_UPLOAD_MAX_FILE_SIZE_TYPE"
                                name="EL_UPLOAD_MAX_FILE_SIZE_TYPE"
                                labelText=" "
                                type="basic"
                                placeholder={'เลือก'}
                                boxSx={{ mx: 3 }}
                                options={FileTypes}
                                value={body.EL_UPLOAD_MAX_FILE_SIZE.sizeType}
                                handleChange={(e) => {
                                    setInputValue('EL_UPLOAD_MAX_FILE_SIZE.size', 1)
                                    setInputValue('EL_UPLOAD_MAX_FILE_SIZE.sizeType', e.target.value)
                                }}
                                textError={formik?.errors?.EL_UPLOAD_MAX_FILE_SIZE?.sizeType}
                                disabled={(body.EL_UPLOAD_MAX_FILE_SIZE.size > 1) && body.EL_UPLOAD_MAX_FILE_SIZE.sizeType === 'MB'}
                            />
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        การสร้างหลักสูตร
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                การตั้งค่าวันที่เริ่มวันอบรมหลังจากวันที่ปิดรับสมัครของหลักสูตรระดับสูง OIC
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_BATCH_START_AFTER_CLOSE_REGISTER"
                                name="EL_BATCH_START_AFTER_CLOSE_REGISTER"
                                labelText="วันที่หลังจากปิดรับสมัครต้องมากกว่าหรือเท่ากับ (นับจากวันที่เริ่มอบรม)"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_BATCH_START_AFTER_CLOSE_REGISTER',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EL_BATCH_START_AFTER_CLOSE_REGISTER}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_BATCH_START_AFTER_CLOSE_REGISTER}
                                onChange={(e) => {
                                    handleInputlength(e, 'EL_BATCH_START_AFTER_CLOSE_REGISTER', setInputValue, 2)
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                วัน
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        ข้อมูลผู้เรียนหลักสูตรระดับสูง OIC
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                            จำนวนเปอร์เซนต์ความถูกต้องของการตรวจสอบเพื่อยืนยันตัวตนผู้เรียน (face detection)
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_FACE_DETECTION_PERCENT"
                                name="EL_FACE_DETECTION_PERCENT"
                                labelText="เปอร์เซนต์"
                                placeholder={"Ex. 0 - 100"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_FACE_DETECTION_PERCENT',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EL_FACE_DETECTION_PERCENT}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_FACE_DETECTION_PERCENT}
                                onChange={(e) => {
                                    if(e.target.value > 100){
                                        e.target.value = body.EL_FACE_DETECTION_PERCENT
                                    }
                                    setInputValue('EL_FACE_DETECTION_PERCENT', clearLeadingZeros(e.target.value))
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                %
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                            จำนวนครั้งการสุ่มตรวจสอบเพื่อยืนยันตัวตนผู้เรียนระหว่างเรียนทุกๆ 30 นาที
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING"
                                name="EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING"
                                labelText="จำนวนครั้งที่ต้องการสุ่ม"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING}
                                onChange={(e) => {
                                    handleInputlength(e, 'EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING', setInputValue, 2)
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                ครั้ง
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_COUNTDOWN_FACE_DETECTION"
                                name="EL_COUNTDOWN_FACE_DETECTION"
                                labelText="ระยะเวลานับถอยหลังระหว่างตรวจสอบใบหน้าผู้เรียน"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_COUNTDOWN_FACE_DETECTION',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EL_COUNTDOWN_FACE_DETECTION}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_COUNTDOWN_FACE_DETECTION}
                                onChange={(e) => {
                                    handleInputlength(e, 'EL_COUNTDOWN_FACE_DETECTION', setInputValue, 2)
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                นาที
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EL_COUNTDOWN_CLICK"
                                name="EL_COUNTDOWN_CLICK"
                                labelText="ระยะเวลานับถอยหลังระหว่างผู้เรียนคลิกยืนยันระหว่างเรียน"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EL_COUNTDOWN_CLICK',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EL_COUNTDOWN_CLICK}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EL_COUNTDOWN_CLICK}
                                onChange={(e) => {
                                    handleInputlength(e, 'EL_COUNTDOWN_CLICK', setInputValue, 2)
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                นาที
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                            ระยะเวลาการเก็บข้อมูลผู้เรียน
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '45px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_FACE_IMAGE_RETENTION_PERIOD_YEAR"
                                    name="EL_FACE_IMAGE_RETENTION_PERIOD_YEAR"
                                    labelText="ไฟล์รูปภาพหน้าตรง"
                                    placeholder={"Ex. 0 - 99"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_FACE_IMAGE_RETENTION_PERIOD_YEAR',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_FACE_IMAGE_RETENTION_PERIOD?.year}
                                    required
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_FACE_IMAGE_RETENTION_PERIOD.year}
                                    onChange={(e) => {
                                        handleInputYear(e, 'EL_FACE_IMAGE_RETENTION_PERIOD.year', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    ปี
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_FACE_IMAGE_RETENTION_PERIOD_MONTH"
                                    name="EL_FACE_IMAGE_RETENTION_PERIOD_MONTH"
                                    labelText=" "
                                    placeholder={"Ex. 0 - 11"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_FACE_IMAGE_RETENTION_PERIOD_MONTH',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_FACE_IMAGE_RETENTION_PERIOD?.month}
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_FACE_IMAGE_RETENTION_PERIOD.month}
                                    onChange={(e) => {
                                        handleInputMonth(e, 'EL_FACE_IMAGE_RETENTION_PERIOD.month', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    เดือน
                                </Typography>
                            </Box>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '45px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_ID_CARD_RETENTION_PERION_YEAR"
                                    name="EL_ID_CARD_RETENTION_PERION_YEAR"
                                    labelText="ไฟล์บัตรประชาชน"
                                    placeholder={"Ex. 0 - 99"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_ID_CARD_RETENTION_PERION_YEAR',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_ID_CARD_RETENTION_PERION?.year}
                                    required
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_ID_CARD_RETENTION_PERION.year}
                                    onChange={(e) => {
                                        handleInputYear(e, 'EL_ID_CARD_RETENTION_PERION.year', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    ปี
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_ID_CARD_RETENTION_PERION_MONTH"
                                    name="EL_ID_CARD_RETENTION_PERION_MONTH"
                                    labelText=" "
                                    placeholder={"Ex. 0 - 11"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_ID_CARD_RETENTION_PERION_MONTH',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_ID_CARD_RETENTION_PERION?.month}
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_ID_CARD_RETENTION_PERION?.month}
                                    onChange={(e) => {
                                        handleInputMonth(e, 'EL_ID_CARD_RETENTION_PERION.month', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    เดือน
                                </Typography>
                            </Box>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '45px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_TRAINING_HISTORY_RETENTION_PERIOD_YEAR"
                                    name="EL_TRAINING_HISTORY_RETENTION_PERIOD_YEAR"
                                    labelText="ประวัติการเข้าเรียน"
                                    placeholder={"Ex. 0 - 99"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_TRAINING_HISTORY_RETENTION_PERIOD_YEAR',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_TRAINING_HISTORY_RETENTION_PERIOD?.year}
                                    required
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_TRAINING_HISTORY_RETENTION_PERIOD.year}
                                    onChange={(e) => {
                                        handleInputYear(e, 'EL_TRAINING_HISTORY_RETENTION_PERIOD.year', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    ปี
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    alignItems: 'center', 
                                    gap: '12px',
                                    width: '50%',
                                }}
                            >
                                <TextInput
                                    id="EL_TRAINING_HISTORY_RETENTION_PERIOD_MONTH"
                                    name="EL_TRAINING_HISTORY_RETENTION_PERIOD_MONTH"
                                    labelText=" "
                                    placeholder={"Ex. 0 - 11"}
                                    type="basic"
                                    inputProps={{
                                        'data-testid': 'EL_TRAINING_HISTORY_RETENTION_PERIOD_MONTH',
                                        type: 'number',
                                        onInput: handleNumberInput,
                                        onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                        onPaste: handlePasteFormat,
                                        maxLength: 2,
                                    }}
                                    textError={formik?.errors?.EL_TRAINING_HISTORY_RETENTION_PERIOD?.month}
                                    // disabled={!hasPermissionCreate}
                                    value={body.EL_TRAINING_HISTORY_RETENTION_PERIOD.month}
                                    onChange={(e) => {
                                        handleInputMonth(e, 'EL_TRAINING_HISTORY_RETENTION_PERIOD.month', setInputValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                />
                                <Typography>
                                    เดือน
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
            <StyledCard sx={{display: 'none'}}>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        การแจ้งเตือน
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                ช่วงเวลาการแจ้งเตือนให้ Admin อนุมัติผู้เรียน
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography
                                color={'text.secondary'}
                            >
                                ทุกวันช่วงเวลา
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color='error'
                            >
                                *
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '50%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                >
                                <TimePicker
                                    id="EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION"
                                    name="EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION"
                                    disabledStartTime={''}
                                    disabledEndTime={''}
                                    onChange={(e) => {
                                        setInputValue('EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION', e.target.value)
                                    }}
                                    // placeholder={'เริ่มต้น'}
                                    value={body.EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION}
                                    helperText={formik?.errors?.EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION}
                                />
                            </Box>
                            <Typography>
                                น.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
        </StyledFormWrapper>

    )
}

export default ELearningForm

export const handleInputlength = (e, key, setInputValue, len) => {
    if(String(e.target.value).length >= len){
        e.target.value = e.target.value.substring(0, len)
    }
    setInputValue(key, clearLeadingZeros(e.target.value))
}

export const handleInputYear = (e, key, setInputValue) => {
    if(String(e.target.value).length >= 2){
        e.target.value = e.target.value.substring(0, 2)
    }
    setInputValue(key, clearLeadingZeros(e.target.value))
}

export const handleInputMonth = (e, key, setInputValue) => {
    if(e.target.value >= 11){
        e.target.value = 11
    }
    setInputValue(key, clearLeadingZeros(e.target.value))
}

export const handleTimepicker = (e, key, setInputValue) => {
    setInputValue(key, dayjs(e).format('HH:mm'))
}