import { Box, Typography } from '@mui/material'
import ViewString from '../../../../../components/CRUD/components/ViewString'
import { useInputField } from '../../../../../components/CRUD/hooks/useInputField'

export const LabelWithValue = ({ label, fieldName, boxSx }) => {
  const { value } = useInputField(fieldName)

  return (
    <Box sx={{ ...boxSx }} display="flex" flexDirection="column">
      <Typography variant="body2b">{label}</Typography>
      <ViewString value={value?.label || value || '-'} />
    </Box>
  )
}
