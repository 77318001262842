import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import Switch from '@mui/material/Switch'
import {
  LayoutIconPreview,
  StyledButtonUploadBox,
  StyledContainer,
  StyledGroupButtonLayoutBox,
  StyledGroupButtonUploadBox,
  StyledGroupLayoutLable,
  StyledPreviewBox,
  StyledPreviewImageBox,
  StyledSwitchButton,
  StyledUploadButton,
} from './Styled'

import TextInput from '../../../../../components/Input/TextInput'
import { useFormikContext } from 'formik'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import * as event from '../event'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CoverImage from '../CoverImage'
import { backgroundStatus } from '../../../../../constants/eCertification'

const UploadBackground = ({ isView = false }) => {
  const dispatch = useDispatch()
  const [imageError, setImageError] = useState('')
  const [isOpenLayout, setOpenLayout] = useState(false)
  const [isSelectLayout, setSelectLayout] = useState(0)
  const { imageKey, loadingProgress, uploadError, name, status, layouts } =
    useSelector(
      (state) => ({
        imageKey: state.eCertificateForm.imageKey,
        loadingProgress: state.eCertificateForm?.loadingProgress,
        uploadError: state.eCertificateForm?.uploadError,
        name: state.eCertificateForm.name,
        status: state.eCertificateForm.status,
        layouts: state.eCertificateForm.layouts,
      }),
      shallowEqual
    )
  const { errors } = useFormikContext()

  const componentRef = useRef()
  const componentRef2 = useRef()
  const { width } = event.useContainerDimensions(componentRef)
  const { width: w2 } = event.useContainerDimensions(componentRef2)

  useEffect(() => {
    setSelectLayout(0)
  }, [isOpenLayout])

  return (
    <StyledContainer container>
      <StyledPreviewBox item xs={12} md={7}>
        <StyledPreviewImageBox ref={componentRef}>
          {!_.isEmpty(loadingProgress) || imageKey ? (
            <CoverImage
              layouts={_.get(layouts, 'result', [])}
              isSelectLayout={isSelectLayout}
              isError={uploadError}
              loadingProgress={loadingProgress}
              url={imageKey}
              width={width}
            />
          ) : (
            <LayoutIconPreview>
              <RemoveRedEye />
              <Typography variant="body1b" color="text.sliver">
                ภาพตัวอย่าง
              </Typography>
            </LayoutIconPreview>
          )}
        </StyledPreviewImageBox>
        <Typography color="text.gray" variant="body2">
          ภาพตัวอย่าง
        </Typography>
      </StyledPreviewBox>
      <StyledButtonUploadBox xs={12} item md={5}>
        <StyledGroupButtonUploadBox>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '8px',
            }}
            variant="body2"
            color="text.secondary"
          >
            ภาพพื้นหลัง
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
              *
            </Typography>
          </Typography>
          <StyledUploadButton>
            <event.UploadImage
              htmlFor="btn-add-image"
              testId="btn-add-image"
              labelText="อัปโหลดรูปภาพ"
              disabled={isView}
              onUpload={(e) =>
                dispatch(event.handleUploadImage(e, 'imageKey', setImageError))
              }
            ></event.UploadImage>
            {imageKey && (
              <Typography noWrap variant="body2" color="text.primary">
                {event.fileName(imageKey)}
              </Typography>
            )}
          </StyledUploadButton>

          <Box sx={{ marginBottom: '24px' }}>
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
              variant="body2"
              color="text.secondary"
            >
              (ขนาดแนะนำ 3508x2480px, นามสกุลไฟล์ที่รองรับ .jpg, .jpeg .png)
            </Typography>
            {!_.isEmpty(imageError) && (
              <Typography sx={{ height: '25px' }} variant="body2" color="error">
                {imageError}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <TextInput
              id="name"
              name="name"
              labelText="ชื่อ"
              type="basic"
              disabled={isView}
              textError={
                String(name).length >= 255
                  ? 'ครบตามจำนวนที่กำหนด 255 ตัวอักษร'
                  : _.get(errors, 'name', '')
              }
              required
              defaultValue={name}
              maxlength={255}
              onBlur={(e) =>
                dispatch(
                  event.handleChange({
                    key: 'name',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
            />
          </Box>
          <StyledSwitchButton>
            <Switch
              data-testid="switch-status"
              disabled={isView}
              checked={status === backgroundStatus.ACTIVE}
              onChange={(e) =>
                dispatch(
                  event.handleChange({
                    key: 'status',
                    value: _.get(e, 'target.checked', '')
                      ? backgroundStatus.ACTIVE
                      : backgroundStatus.INACTIVE,
                  })
                )
              }
            />
            <Box>
              <Typography>เปิดใช้งาน</Typography>
            </Box>
          </StyledSwitchButton>
        </StyledGroupButtonUploadBox>
        <StyledGroupButtonLayoutBox>
          <StyledSwitchButton ref={componentRef2}>
            <Switch
              data-testid="switch-status"
              disabled={isView || _.isEmpty(imageKey)}
              checked={isOpenLayout}
              onChange={() => setOpenLayout((o) => !o)}
            />
            <Box>
              <Typography>ทดสอบกับ Layout</Typography>
            </Box>
          </StyledSwitchButton>
          {isOpenLayout && (
            <Grid container>
              <StyledGroupLayoutLable>
                <Typography variant="body2" color="text.secondary">
                  Layout ({_.get(layouts, 'totalCount', 0)})
                </Typography>
              </StyledGroupLayoutLable>
              <event.LayoutOption
                layouts={_.get(layouts, 'result', [])}
                setSelectLayout={setSelectLayout}
                isSelectLayout={isSelectLayout}
                width={w2}
              />
            </Grid>
          )}
        </StyledGroupButtonLayoutBox>
      </StyledButtonUploadBox>
    </StyledContainer>
  )
}

export default UploadBackground
