import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

import CircularProgress from '@mui/material/CircularProgress'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'unset' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledHeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 16,
  right: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    '&#view': {
      position: 'relative',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {},
  })
)

export const InputImageUpload = styled('input')({
  display: 'none',
})

export const StyledBoxText = styled(Box)(({ theme, isFrist }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(isFrist ? 3 : 0.5),
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  '& .title-text': {
    width: 336,
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const StyledCardFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}))
