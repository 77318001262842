import _ from 'lodash'

export const postBodyMutation = (data) => {
  return {
    monthlyPlanId: _.get(data, 'monthlyPlanId', 0),
    monthlyPlan: _.get(data, 'monthlyPlan', ''),
    costCenter: _.get(data, 'costCenter.value', ''),
    staffProfile: _.get(data, 'staffProfile.value', null),
    staffRole: _.get(data, 'staffRole.value', null),
    amountList: _.get(data, 'amountList', []),
    uuidListDelete: _.get(data, 'uuidListDelete', []),
  }
}
