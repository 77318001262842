import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import { LoadingButton } from '@mui/lab'

export const StyledDialog = styled((props) => (
  <Dialog
    data-testid="alert-dialog"
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
    {...props}
  />
))(({ theme }) => ({
  '& #dialog-header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    gap: theme.spacing(1),
  },
}))

export const StyledDialogMessage = styled((props) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
}))

export const StyledCancelButton = styled((props) => (
  <Button data-testid="btn-cancel-dialog" variant="outlined" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  minWidth: 100,
}))

export const StyledLoadingButton = styled((props) => (
  <LoadingButton
    data-testid="dialog-btn-confirm"
    variant="contained"
    loadingPosition="end"
    color="primary"
    {...props}
  />
))(() => ({
  width: '100px',
}))
