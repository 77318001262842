import { Box, Button, Card, CardContent } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DataCard } from '../ModuleCard/DataCard'
import { courseEvaPartModel } from '../../models/overview'
import BarChart from '../../../../../../../components/Chart/BarChart'
import { axisLabel, chartPart1Model, partTitle } from '../../models/partModel'
import { handleClickViewButton } from '../../handler/handleClickViewButton'
import { questionTypeText } from '../../constants/type'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const CourseEva = ({ isPrinting = false }) => {
  const { courseEva } = useSelector(
    (state) => ({
      courseEva: state.crud.eEvaluationDashboard?.overview?.courseEva,
    }),
    shallowEqual,
  )
  const [courseEvaPart, setCourseEvaPart] = useState(courseEvaPartModel())
  const chart = chartPart1Model('courseEva').map((item) => item.number) // [] data
  const labels = chartPart1Model('courseEva').map((item) => item.label)
  const bgColors = chartPart1Model('courseEva').map(
    (item) => item.backgroundColor,
  )

  useEffect(() => {
    setCourseEvaPart(courseEvaPartModel())
  }, [courseEva])
  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={partTitle.part1}
            type={questionTypeText.RATING}
            contentId="courseEva"
            alwaysOpen={isPrinting ? true : false}
            hideIcon={isPrinting ? true : false}
          >
            <Box className={`${isPrinting ? 'hide-pdf' : ''}`}>
              <Button
                data-testid="btn-preview"
                variant="outlined"
                size="m"
                endIcon={<ChevronRightIcon color="primary" />}
                onClick={() => {
                  handleClickViewButton('part1')
                }}
              >
                ดูรายละเอียดคำตอบ
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                dataList={courseEvaPart}
                totalModule={''}
                border={'none'}
                padding={0}
              />
            </Box>
            <Box
              minHeight="250px"
              sx={{
                width: '100%',
                maxWidth: isPrinting ? '650px' : '100%',
              }}
            >
              <BarChart
                dataSet={{
                  data: chart,
                  backgroundColor: bgColors,
                }}
                labels={labels}
                height={'300px'}
                showXAxisLabel={true}
                showYAxisLabel={true}
                xAxisLabel={axisLabel.x}
                yAxisLabel={axisLabel.y}
                showPercent={true}
                eleId={`part1-chart`}
              />
            </Box>
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CourseEva
