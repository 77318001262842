import React, { useEffect } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { StyledHeaderContainer } from '../../../Styled'
import MoreDropdownButton from './DropdownButton'
import { useFormikContext } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { onErrorValidate } from '../event'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../../../constants/eCertification'
import { validateEPermission } from '../../../../../utils/lib'

const Header = ({ isEditForm = false }) => {
  const dispatch = useDispatch()
  const { setValues, errors } = useFormikContext()
  const { eCertificateForm } = useSelector(
    (state) => ({
      eCertificateForm: state.eCertificateForm,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (!_.isEmpty(errors)) dispatch(onErrorValidate())
  }, [errors])

  const hasDeleteBackgroundPermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.DELETE_BG,
  })

  const hasCreatePermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.CREATE,
  })
  return (
    <StyledHeaderContainer>
      {hasCreatePermission && (
        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          onClick={() => {
            if (
              _.isEmpty(eCertificateForm?.name) ||
              _.isEmpty(eCertificateForm?.imageKey)
            ) {
              dispatch(onErrorValidate())
            }
            setValues({ ...eCertificateForm })
          }}
        >
          บันทึก
        </Button>
      )}

      {isEditForm && hasDeleteBackgroundPermission && <MoreDropdownButton />}
    </StyledHeaderContainer>
  )
}
export default Header
