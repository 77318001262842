import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import { setSendMail } from '../../../../redux/slices/manageClassForm'
import { StyledDialog } from './Styled'

export const ConfirmDialog = () => {
  const dispatch = useDispatch()
  const { manageClassStatus } = useSelector(
    (state) => ({
      manageClassStatus: state.manageClassForm.data.status,
    }),
    shallowEqual
  )

  return (
    <StyledDialog>
      <Typography variant="body1">
        คุณต้องการเปลี่ยนแปลง
        และบันทึกข้อมูลหลังจากมีการประกาศรายชื่อผู้สิทธิ์เข้าเรียนไปแล้วหรือไม่
      </Typography>
      {manageClassStatus !== 'CLOSED' && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-accept"
                defaultChecked={false}
                onChange={(e) => dispatch(setSendMail(e.target.checked))}
              />
            }
            label="ฉันต้องการส่งอีเมลอัปเดตให้ผู้เรียนทุกคนรับทราบ"
          />
        </Box>
      )}
    </StyledDialog>
  )
}

export default ConfirmDialog
