import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'

import { countScore } from './event'

const Index = ({ isAssessment }) => {
  const questions = useSelector(
    (state) => state.eTestingForm.questions,
    shallowEqual
  )
  return (
    <>
      <Box sx={{ my: 3, mb: isAssessment ? 0 : 3 }}>
        <Typography variant="h6" sx={{ display: 'flex' }}>
          คำถาม {questions.length} ข้อ
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ paddingLeft: 1 }}
          >
            ({countScore(questions)} คะแนน)
          </Typography>
        </Typography>
      </Box>
    </>
  )
}

export default Index
