export const initialState = {
  reportConfig: {
    moduleId: '',
    modulePath: '',
  },
  isLoading: false,
  isLoadingDrawer: false,
  isOpenFilterDrawer: false,
  isOpenManageColumnDrawer: false,
  tabActiveColDrawer: 0,
  tabActiveColCard: 0,
  filterColumnOptions: [],
  columns: [],
  filters: [],
  bodyFilters: [],
  onDownload: null,
  displayFilters: [],
  reportsData: [],
  tablePage: 0,
  page: -1,
  rowsPerPage: { label: '100', value: 100 },
  allCount: 0,
  columnSelected: null,
  value: '',
  error: '',
  isFilter: false,
  disabledAddBtn: true,
  newColumnName: null,
  firstColumnSelected: null,
  secondaryColumnSelected: null,
  defaultColumns: [],
  columnName: null,
  columnNameTH: null,
  hasSortedColumns: false,
  options: [],
  isInitial: true,
}
