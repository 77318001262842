import { Box, Typography } from '@mui/material'
import ViewString from '../../../../../components/CRUD/components/ViewString'
import { useInputField } from '../../../../../components/CRUD/hooks/useInputField'
import Dot from '../../../../../components/redux/Dot'
import { BGM_STATUS_LABEL } from '../../../../../constants/budgetManagement/budgetManagement'

export const LabelWithValue = ({
  label,
  fieldName,
  boxSx,
  flexDirection = 'row',
}) => {
  const { value } = useInputField(fieldName)

  return (
    <Box
      sx={{ ...boxSx }}
      display="flex"
      flexDirection={flexDirection}
      gap={flexDirection === 'row' ? 3 : 0}
    >
      <Box width={300}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
      </Box>
      <Box display="flex" gap={1}>
        {fieldName === 'status' && <Dot status={value} sx={{ mt: 1.5 }} />}
        <ViewString
          value={
            fieldName === 'status'
              ? BGM_STATUS_LABEL[value]
              : value?.label || value?.toString()
          }
        />
      </Box>
    </Box>
  )
}
