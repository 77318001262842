import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { reportConfig } from './reportConfig'
import { handleFilterReport } from './events'
import {
  setTabActiveReport,
  setInitialReportMP,
} from '../../../redux/slices/monthlyPlan'
import { Box, Card } from '@mui/material'
import FilterDrawer from '../../../components/Report/components/FilterDrawer'
import {
  setReportConfig,
  setInitialReport,
} from '../../../redux/slices/reportComp'
import TableReport from './TableReport'
import { useReportConstants } from '../../../components/Report/hooks/useReportConstants'
import Header from './Header'
import { LoadingPage } from './Styled'
import { defaultDataYearly } from './model'
import { getDefaultFiltersList } from '../handler/getDefaultFiltersList'
import { getDefaultFiltersFromQueryString } from '../handler/getDefaultFiltersFromQueryString'
import { getDefaultDataWorkload } from '../handler/getDefaultDataWorkload'

const Index = () => {
  const dispatch = useDispatch()
  const {
    tabActive,
    isLoading,
    filters,
    moduleId: moduleCurrent,
  } = useSelector(
    (state) => ({
      tabActive: state.monthlyPlan.report.tabActive,
      isLoading: state.monthlyPlan.report.isLoading,
      filters: state.reportComp.filters,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )

  const moduleId = tabActive === 1 ? 'MP_WORKLOAD' : 'MP_YEARLY'
  const { filterColumnOptions, defaultColumns, COLUMN_NAME } =
    useReportConstants(moduleId)
  const isWorkload = moduleId === 'MP_WORKLOAD'

  useEffect(async () => {
    const defaultData = isWorkload
      ? await getDefaultDataWorkload()
      : defaultDataYearly

    let filtersForDefault =
      moduleCurrent === 'MP_WORKLOAD' || moduleCurrent === 'MP_YEARLY'
        ? filters
        : []
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const hasQueryString = !urlParams.entries().next().done
    const defaultFilters = await getDefaultFiltersList()

    if (filters.length === 0) {
      if (hasQueryString) {
        filtersForDefault = await getDefaultFiltersFromQueryString(urlParams)
      } else {
        filtersForDefault = defaultFilters
      }
    }

    dispatch(
      setReportConfig({
        reportConfig: {
          ...reportConfig,
          moduleId: moduleId,
          modulePath: isWorkload ? '/monthly-plan' : '/monthly-plan/yearly',
        },
      }),
    )
    dispatch(
      setInitialReport({
        filterColumnOptions: filterColumnOptions,
        columnName: COLUMN_NAME,
        defaultColumns: defaultColumns,
        defaultFilters: filtersForDefault,
      }),
    )
    dispatch(
      setInitialReportMP({
        headCellsReport: defaultColumns,
        reportsData: defaultData,
      }),
    )
    handleFilterReport()
  }, [tabActive])

  useEffect(() => {
    dispatch(setTabActiveReport(0))
  }, [])

  return (
    <Box
      sx={{
        opacity: isLoading ? 0.4 : 'unset',
        pointerEvents: isLoading ? 'none' : 'unset',
      }}
      display="flex"
      flexDirection="column"
      gap={3}
      mx={3}
      pb={2}
    >
      <Header />
      <Card sx={{ p: 3, overflow: 'scroll' }}>
        <TableReport />
      </Card>
      <FilterDrawer handleFilter={handleFilterReport} />
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}
export default Index
