import _ from 'lodash'
import { store } from '../../../../../../App'
import { COURSE_TYPE } from '../../../../../../constants/eLearning'
import { setChange } from '../../../../../../redux/slices/eLearning/courseDetail'

export const handleChangeTab = (value) => (dispatch) => {
  if (_.isEmpty(value)) return
  dispatch(setChange({ key: 'tab', value }))
}

export const isOic = () => {
  const result = store.getState().eLearningCourseDetail.result
  const courseType = _.get(result, 'courseType', '')
  return courseType === COURSE_TYPE.OIC
}
