import { setAddQuestion } from '../../../../../redux/slices/eTestingForm'

export const handleAddQuestion = (value, question) => (dispatch) => {
  let title = ''
  switch (value) {
    case 'MATCHING':
      title = 'จงจับคู่ข้อความที่มีความสัมพันธ์กันมากที่สุด'
      break
    case 'TRUE_FALSE':
      title = 'จงอ่านข้อความต่อไปนี้แล้วพิจารณาว่าข้อความนั้นถูกหรือผิด'
      break
    case 'FILL_IN_BLANK_CHOICE':
    case 'FILL_IN_BLANK_DRAG':
      title = 'จงเติมคำตอบในช่องว่างให้ถูกต้อง'
      break
    default:
      break
  }
  const result = {
    questionType: value,
    mediaType: 'IMAGE',
    score: 0,
    sequence: question + 1,
    title,
    subTitle: '',
    url: '',
    answers: value?.includes('FILL_IN_BLANK')
      ? []
      : [
          {
            id: `id-1`,
            title: '',
            question: value === 'MATCHING' ? '' : undefined,
            sequence: 1,
            mediaUrl: '',
            isCorrect: value === 'SEQUENCE' || value === 'MATCHING', // true | false
            score: 0,
            uuid: '',
            questionType: value,
            booleanAns: null,
          },
        ],
    uuid: '',
  }

  dispatch(setAddQuestion({ value: result }))
}
