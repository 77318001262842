import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledDragDropContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}))

export const StyledKeywordContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  minHeight: 74,
  alignItems: 'center',
  background: theme.palette?.background?.paper,
  border: '1px solid',
  borderColor: theme.palette?.text?.mercury,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
}))

export const StyledDialogContent = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'start',
}))

export const StyledNumberBadge = styled(Typography)(({ theme, type }) => ({
  padding: '0.5px 8.22px',
  marginLeft: theme.spacing(1.5),
  borderRadius: '100%',
  backgroundColor:
    type === 'true'
      ? theme.palette?.success?.main
      : type === 'false'
      ? theme.palette?.error?.main
      : theme.palette?.action?.active,
}))