import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { draftTesting } from './../../../../../redux/slices/eTestingPreview'
import { StyledTestingContent } from './Styled'
import QuestionCard from './QuestionCard'

export const Questions = () => {
  const listQuestion = useSelector(draftTesting, shallowEqual)
  const { displayQuestion, page } = useSelector(
    (state) => ({
      displayQuestion: state.eTestingPreview.setting.displayQuestion,
      page: state.eTestingPreview.page,
    }),
    shallowEqual
  )

  if (!_.isUndefined(listQuestion[0])) {
    switch (displayQuestion) {
      case 'ALL':
        return (
          <StyledTestingContent>
            {listQuestion.map((item, index) => (
              <QuestionCard
                key={`question-no-${index + 1}`}
                keyQuestion={item}
              />
            ))}
          </StyledTestingContent>
        )
      case 'ONE':
        return (
          <StyledTestingContent>
            <QuestionCard
              key={`question-preview`}
              keyQuestion={listQuestion[page - 1]}
            />
          </StyledTestingContent>
        )
      default:
        return <></>
    }
  } else return <></>
}
export default Questions
