import { store } from '../../../App'
import { openErrorDialog } from '../../../redux/slices/dialog'

export const handleFetchError = ({ response }) => {
  if (response) {
    displayServerError()
  } else {
    displayClientError()
  }
}

export const displayServerError = () => {
  store.dispatch(
    openErrorDialog({
      message: 'ระบบขัดข้อง พบข้อผิดพลาดบนเซิฟเวอร์',
      title: 'ผิดพลาด',
      isCloseDialog: false,
    }),
  )
}

export const displayClientError = () => {
  store.dispatch(
    openErrorDialog({
      message: 'ระบบขัดข้อง พบข้อผิดพลาดบนเว็บไซต์',
      title: 'ผิดพลาด',
      isCloseDialog: false,
    }),
  )
}
