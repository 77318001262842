import { schemaPreview } from '../schema/schemaPreview'

export const checkPreviewButton = async (formData) => {
  try {
    await schemaPreview.validate(formData, { abortEarly: false })
    return false
  } catch {
    return true
  }
}
