import { CContainer } from '../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from './handler/handleFiltering'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import CustomEnhancedTableToolbar from './components/CustomEnhanceTableToolbar'
import { setTableProps } from './model/setTableProps'
import HistoryDrawer from './Drawer/History'
import AssetListDrawer from './Drawer/AssetList'
import AssetHistoryDrawer from './Drawer/AssetHistory'
import AssetDetailDrawer from './Drawer/AssetDetail'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { handleSetDataRows } from './handler/handleSetDataRows'
import { fetchMasterDataDropdown } from '../../../../components/CRUD/handler/fetchDetail'
import { paymentMethodType } from '../../Transfer/Form/enum/paymentMethod'
import { getBodyMutation } from '../View/mutation/get'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { crudConfig as initialCrudConfigView } from '../View/crudConfig'
import { getQueryParams } from '../../../../utils/lib'
import { fetchAndOpenAssetDetail } from './handler/fetchAndOpenAssetDetail'

export const ProductWarehouseList = ({ productUuid = null }) => {
  const {
    isLoading,
    page,
    sort,
    order,
    limit,
    isOpenHistoryDrawer,
    isOpenAssetDrawer,
    isOpenAssetHistoryDrawer,
    isRefreshTable,
    isOpenAssetDetailDrawer,
    isReloadAssetListDrawer,
  } = useSelector(
    (state) => ({
      isLoading: state.table.table.isLoading,
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
      isOpenHistoryDrawer: state.crud.isOpenHistoryDrawer,
      isOpenAssetDrawer: state.crud.isOpenAssetDrawer,
      isOpenAssetHistoryDrawer: state.crud.isOpenAssetHistoryDrawer,
      isRefreshTable: state.crud.isRefreshTable,
      isOpenAssetDetailDrawer: state.crud.isOpenAssetDetailDrawer,
      isReloadAssetListDrawer: state.crud.isReloadAssetListDrawer,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  const { isFetchProductDetail } = useSelector((state) => state.crud)
  const [isInit, setIsInit] = useState(true)

  useEffect(() => {
    if (isFetchProductDetail) {
      initialConfig({ ...initialCrudConfig, productUuid }, false)
      fetchMasterDataDropdown().then(() => {
        store.dispatch(
          setFieldValue({
            key: 'masterData.paymentTypeForm',
            value: { isLoading: false, options: paymentMethodType },
          }),
        )

        const productUuid = getQueryParams('product')
        const warehouseUuid = getQueryParams('warehouse')
        const skuUuid = getQueryParams('sku')
        if (isInit && productUuid && warehouseUuid && skuUuid) {
          fetchAndOpenAssetDetail(productUuid, warehouseUuid, skuUuid)
          setIsInit(false)
        }
      })
    }
  }, [isFetchProductDetail])

  useEffect(() => {
    if (!_.isUndefined(crudConfig) && isFetchProductDetail) {
      fetchDataList(mutateFilterPropToBody, setTableProps).then(() => {
        handleSetDataRows()
      })
    }
  }, [limit, order, page, sort, crudConfig, isFetchProductDetail])

  useEffect(() => {
    if (!isLoading) {
      handleSetDataRows()
    }
  }, [isLoading])

  useEffect(() => {
    if (isRefreshTable || isReloadAssetListDrawer) {
      initialConfig(initialCrudConfigView)
      store.dispatch(
        setFieldValue({ key: 'isFetchProductDetail', value: false }),
      )
      fetchDetail(productUuid, getBodyMutation).then(() => {
        store.dispatch(
          setFieldValue({ key: 'isFetchProductDetail', value: true }),
        )
        store.dispatch(
          setFieldValue({
            key: 'isRefreshTable',
            value: false,
          }),
        )
        store.dispatch(
          setFieldValue({
            key: 'isReloadAssetListDrawer',
            value: false,
          }),
        )
      })
    }
  }, [isRefreshTable, isReloadAssetListDrawer])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CContainer
        drawer={
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() =>
              handleFiltering(
                mutateFilterSateToProp,
                mutateFilterPropToBody,
                setTableProps,
              )
            }
            filterItems={filterItems}
            defaultFilter={defaultFilter}
          />
        }
        content={<CustomEnhancedTableToolbar />}
      />

      {isOpenHistoryDrawer && <HistoryDrawer open={isOpenHistoryDrawer} />}

      {isOpenAssetDrawer && <AssetListDrawer open={isOpenAssetDrawer} />}

      {isOpenAssetHistoryDrawer && (
        <AssetHistoryDrawer open={isOpenAssetHistoryDrawer} />
      )}

      {isOpenAssetDetailDrawer && (
        <AssetDetailDrawer open={isOpenAssetDetailDrawer} />
      )}
    </>
  )
}
