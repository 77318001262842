export const arrayResult = [
  { label: 'ไม่ส่ง', name: 'noSendChecked' },
  { label: 'ครั้งที่ 1', name: 'firstChecked' },
  { label: 'ครั้งที่ 2', name: 'secondChecked' },
]

export const arrayStatus = [
  { label: 'รอเอกสาร', name: 'waitingDocument' },
  { label: 'รอตรวจสอบ', name: 'waitingCheck' },
  { label: 'รอคอนเฟิร์มตัวแทน', name: 'waitingConfirm' },
  { label: 'เอกสารไม่ผ่าน', name: 'rejected' },
  { label: 'ผ่านการตรวจสอบ', name: 'passChecked' },
  { label: 'ไม่มีสิทธิ์เข้าเรียน', name: 'inEligible' },
  { label: 'ยกเลิกการสมัคร', name: 'cancled' },
]

export const arrayCheckbox = [
  {
    label: 'ID ผู้สมัคร',
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    placeholder: 'ค้นหา ID ผู้สมัคร',
    type: 'text',
  },
  {
    label: 'ชื่อผู้สมัคร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อผู้สมัคร',
    type: 'text',
  },
  {
    label: 'วันที่ส่งเอกสาร',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    type: 'date',
  },
  {
    label: 'ส่งเอกสาร',
    nameCheckBox: 'roundChecked',
    nameInput: 'round',
    type: 'checkbox',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]

export const defaultFilter = {
  statusChecked: false,
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
  dateChecked: false,
  roundChecked: false,
  startDate: new Date(),
  endDate: new Date(),
  status: [],
  round: [],
}
