import _ from 'lodash'
import {
  setAnswered,
  setChangeStateByKey,
} from './../../../../../../../../redux/slices/eTestingPreview'

export const handleRadioChange =
  (e, keyQuestion, choice, index) => (dispatch) => {
    const value = _.get(e.target, 'value', '')
    const answerObj = _.get(choice, 'subAnswers', []).find(
      (sub) => sub.uuid === Number(value)
    )

    dispatch(
      setChangeStateByKey({
        key: `answer[${index}]`,
        value: answerObj,
        keyQuestion,
      })
    )
    dispatch(setAnswered({ keyQuestion, value }))
  }

export const getPlainTextWithBlank = (value) => {
  const newValue = value
    .replaceAll('<span', '(<span')
    .replaceAll('</span>', '</span>)')
  const plainText = new DOMParser().parseFromString(newValue, 'text/html')
    .documentElement.textContent
  return plainText
}
