import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import { validationSchema } from './FormSchema'
import {
  roadMap,
  distributionFilter,
  uploadFileRoadmap,
} from '../../utils/apiPath'
import Breadcrumbs from '../../components/Breadcrumbs'
import { setMapListOptions, callAPI, checkUpload } from '../../utils/lib'
import { onView } from './RoadMapList'

export const breadcrumbList = (titleText) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'กลุ่มหลักสูตร',
      link: '/master-data/road-map',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

const RoadMapForm = ({ isEditForm }) => {
  const { id: uuid } = useParams()
  const titleText = isEditForm ? 'แก้ไขกลุ่มหลักสูตร' : 'เพิ่มกลุ่มหลักสูตร'
  const history = useHistory()
  const onSelectChange = (list) => {
    formik.setFieldValue('distribution', list)
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchDistributionList({
      setStateDistribution,
    })
    if (isEditForm) {
      fetchRoadMapById({
        uuid,
        setInitialValues,
        setDisplayTitle,
        formik,
      })
    }
    setIsPageLoading(false)
  }, [isEditForm])

  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [displayTitle, setDisplayTitle] = useState('')
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [stateDistribution, setStateDistribution] = useState([])
  const [initialValues, setInitialValues] = useState({
    title: '',
    abbreviation: '',
    distribution: [],
    remark: '',
    status: true,
    description: '',
    imgKey: '',
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitRoadMap({
            uuid,
            values,
            isEditForm,
            setDialog,
            history,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })
  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs
          sx={{ mx: 3, mb: 3 }}
          menuList={breadcrumbList(titleText)}
        />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={() => onCancelClickDialog(setDialog)}
          onSubmit={submitRoadMap}
          handleUploadFile={handleUploadFile}
          formik={formik}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          distribution={stateDistribution}
          onChange={onSelectChange}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default RoadMapForm

export const submitRoadMap = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  history,
}) => {
  setDialog({
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'
  let result = []
  if (values?.distribution?.length) {
    result = values?.distribution?.map((item) => {
      if (!item.label) {
        return { label: item.distribution, value: item.uuid }
      }
      return item
    })
  }
  const body = {
    ...values,
    distribution: result,
    status: values.status === true ? 'ACTIVE' : 'INACTIVE',
    uuid: uuid,
  }
  if (!isEditForm) delete body.uuid
  callAPI({
    method: methodType,
    url: roadMap,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res.uuid
      setDialog({
        open: true,
        isLoading: false,
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        variant: 'success',
        onConfirmClick: () => {
          setDialog({ open: false })
          onView({ uuid: uuidLink }, history)
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
  })
}

export const fetchDistributionList = async ({ setStateDistribution }) => {
  const body = {
    abbreviation: '',
    distribution: '',
    level: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }

  callAPI({
    method: 'post',
    url: distributionFilter,
    body: body,
    onSuccess: (res) => {
      const result = setMapListOptions(res.result, 'abbreviation')
      setStateDistribution(result)
    },
  })
}

export const handleUploadFile = async ({ e, formik, name, dispatch }) => {
  const checkFile = dispatch(
    checkUpload({
      events: e,
      type: 'image',
      id: `btn-add-image-${name}`,
    })
  )
  if (checkFile) return

  const data = new FormData()
  data.append('file', e.target.files[0])
  await callAPI({
    url: uploadFileRoadmap,
    method: 'POST',
    body: data,
    onSuccess: (res) => {
      onUploadSuccess({ res, formik })
    },
  })
}

export const onUploadSuccess = async ({ res, formik }) => {
  const key = res?.key
  formik.setFieldValue('imgKey', key)
}

export const fetchRoadMapById = async ({
  uuid,
  setInitialValues,
  setDisplayTitle,
  formik,
}) => {
  callAPI({
    method: 'get',
    url: roadMap + `/${uuid}`,
    onSuccess: (res) => {
      const {
        id,
        title,
        abbreviation,
        status,
        distribution,
        remark,
        description,
        imgKey,
      } = res
      setInitialValues({
        ...res,
        distribution: distribution.length
          ? distribution.map((item) => {
              return { ...item, title: item.abbreviation }
            })
          : [],
        status: status.toString().toUpperCase() === 'ACTIVE' || false,
      })
      setDisplayTitle(title)
      formik.setFieldValue('id', id)
      formik.setFieldValue('title', title)
      formik.setFieldValue('abbreviation', abbreviation)
      formik.setFieldValue('distribution', distribution)
      formik.setFieldValue('remark', remark)
      formik.setFieldValue('description', description)
      formik.setFieldValue('imgKey', imgKey)
      formik.setFieldValue('remark', remark)
      formik.setFieldValue(
        'status',
        status.toString().toUpperCase() === 'ACTIVE' || false
      )
    },
  })
}
