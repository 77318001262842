import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { StyledColumn } from '../../../Styled'
import { SECTION_KEY, SECTION_OBJ } from '../../../model'
import { handleSelector } from '../../events'
import CardInput from '../CardInput'
import TabHeader from '../TabHeader'
import TabInformation from '../TabInformation'
import ContainerScrollX from '../ContainerScrollX'
import { handleDefaultValue } from './events'

export const TabContent = ({ keySection, tabIndex, index }) => {
  const { keyInfo, keySub, inputTitle } = SECTION_OBJ[keySection]
  const keyField = `${keyInfo}[${tabIndex}].${keySub}[${index}]`
  const tab = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual,
  )

  const no = index + 1
  const title = _.get(tab, 'tabTitle', `Title ${no}`)
  const status = _.get(tab, 'status', false)
  const tabItem = _.get(tab, keySub, [])
  const headerProps = { keyField, title, status }
  const isPath = keySection === SECTION_KEY.LEARNING_PATH

  if (!status) {
    return <TabHeader {...headerProps} />
  }

  return (
    <StyledColumn gap={3}>
      <TabHeader {...headerProps} />

      <TabInformation keyField={keyField} />

      <Typography variant="body1b">
        {inputTitle} {!isPath && title}
      </Typography>
      <ContainerScrollX>
        {tabItem.map((item, index) => (
          <CardInputItem
            key={index}
            data={item}
            keySection={keySection}
            keyField={`${keyField}.${keySub}[${index}]`}
          />
        ))}
      </ContainerScrollX>
    </StyledColumn>
  )
}

export default TabContent

export const CardInputItem = ({ data, keySection, keyField }) => {
  const { keyInfo, keySub, keyValue, label } = SECTION_OBJ[keySection]
  const { InfoData, AllOptions, levelDistribution } = useSelector(
    (state) => ({
      InfoData: state.manageContentELearningHome.data[keyInfo],
      AllOptions: state.manageContentELearningHome.options[keySub],
      levelDistribution:
        state.manageContentELearningHome.levelDistribution[keyInfo],
    }),
    shallowEqual,
  )
  const defaultValue = useMemo(
    () => handleDefaultValue(_.get(data, keyValue, {}), AllOptions),
    [data],
  )
  const getTab = _.find(InfoData, (data) => {
    return _.get(data, 'uuid', '') === levelDistribution
  })
  const options = _.filter(AllOptions, (option) => {
    return (
      _.find(
        _.get(option, 'levelOfLearner', [{ uuid: '' }]),
        (level) =>
          (level.uuid || level.value) === _.get(getTab, 'level.uuid', ''),
      ) &&
      _.find(
        _.get(option, 'setting.distribution', [{ uuid: '' }]),
        (dist) =>
          (dist.value || dist.uuid) === _.get(getTab, 'distribution.uuid', ''),
      )
    )
  })

  const isPath = keySection === SECTION_KEY.LEARNING_PATH
  const isCollection = keySection === SECTION_KEY.COLLECTION

  return (
    <CardInput
      label={label}
      keyField={keyField}
      name={keyValue}
      item={data}
      defaultValue={defaultValue}
      option={options}
      isPath={isPath}
      isCollection={isCollection}
    />
  )
}
