import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { eventIcon } from '../../../../../constants/manageClass'
import { StyledCard, StyledColumn } from '../Styled'
import {
  StyledEventItem,
  StyledEventColumn,
  StyledTypography,
  StyledEventContainer,
} from './Styled'
import EventDrawer from './EventDrawer'
import { getQueryParams } from '../../../../../utils/lib'

const EventCard = () => {
  const [isOpen, setOpen] = useState(false)
  const manageClassData = useSelector(
    (state) => state.manageClassDetail.data,
    shallowEqual
  )
  const events = _.get(manageClassData, 'event', [])
  const status = _.get(manageClassData, 'status', [])
  const objOnLink = {
    history: Number(getQueryParams('history')),
  }

  useEffect(() => {
    if (_.get(objOnLink, 'history', 0) == 1) {
      setOpen(true)
    }
  }, [])

  return (
    <StyledEventContainer status={status}>
      <StyledCard>
        <Box>
          <Typography variant="body1b">กิจกรรมล่าสุด</Typography>
          <IconButton
            data-testid="btn-open"
            color="primary"
            onClick={() => setOpen(true)}
          >
            <ArrowForward />
          </IconButton>
        </Box>
        <StyledEventColumn>
          {events.map((item, index) => (
            <EventItem key={index} isActive={index == 0} data={item} />
          ))}
        </StyledEventColumn>
      </StyledCard>
      <EventDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </StyledEventContainer>
  )
}

export default EventCard

export const EventItem = ({ isActive, data }) => {
  const event = _.get(data, 'event', '-')
  const eventEng = _.get(data, 'eventEng', '-')
  const dateTime = _.get(data, 'dateTime', '-')
  return (
    <StyledEventItem active={isActive}>
      {_.get(eventIcon, eventEng, '')}
      <StyledColumn>
        <StyledTypography variant="body2">{event}</StyledTypography>
        <Typography color="text.secondary" variant="body2">
          {dateTime}
        </Typography>
      </StyledColumn>
    </StyledEventItem>
  )
}
