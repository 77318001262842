import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'INVENTORY_PRODUCT',
  //   permission: ['DELETE'],
  // })

  // console.log(hasPermissionDelete)

  return [
    {
      id: 'ticketStatus',
      label: 'สถานะ',
      hideSortIcon: true,
      minWidth: '40px',
      width: '115px',
    },
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'form.warehouseName',
      label: 'ต้นทาง',
      disablePadding: false,
    },
    {
      id: 'to.warehouseName',
      label: 'ปลายทาง',
      disablePadding: false,
    },
    {
      id: 'createdBy.name',
      label: 'สร้างโดย',
      disablePadding: false,
    },
    {
      id: 'createdAt',
      label: 'วันที่สร้าง',
      disablePadding: false,
      width: '100px',
    },
    {
      id: 'actionTransfer',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
      editPath: `${crudConfig.modulePath}/form`,
      // hideDelete: hasPermissionDelete ? false : true,
      isList: true,
    },
  ]
}
