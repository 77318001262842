export const ROW_PAGE_PER_LIST = [
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '300', value: 300 },
  { label: 'All', value: -1 },
]

export const MANAGE_CLASS_ROW_PAGE_PER_LIST = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: 'All', value: -1 },
]

export const MINI_ROW_PAGE_PER_LIST = [
  { label: '5', value: 5 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: 'All', value: -1 },
]
