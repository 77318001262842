import React from 'react'
import Banner from './Banner'
import Courses from './Courses'
import Footer from './Footer'
import RoadMap from './RoadMap'
import Trainer from './Trainer'
import TrainingPlatform from './TrainingPlatform'
import { StyledFormContainer } from './Styled'

const Form = () => {
  return (
    <StyledFormContainer>
      <Banner />
      <RoadMap />
      <Trainer />
      <TrainingPlatform />
      <Courses />

      <Footer />
    </StyledFormContainer>
  )
}

export default Form
