import React from 'react'
import _ from 'lodash'
import TextInput from '../../../../components/Input/TextInput'
import { StyledContainer } from './Styled'
import { handleNumberInput, handleTextInput } from './events'

const AttendanceForm = ({ attendance, formik, setInitialValues }) => {
  return (
    <StyledContainer>
      <TextInput
        name="attendanceCount"
        id="input-attend-attendanceCount"
        type="basic"
        required
        labelText="จำนวนการเช็คชื่อ (ครั้ง/วัน)"
        placeholder="ครั้ง"
        inputProps={{
          type: 'number',
          min: '1',
          onInput: handleNumberInput,
        }}
        value={_.get(attendance, 'attendanceCount', '')}
        textError={_.get(formik.errors, 'attendanceCount', '')}
        onChange={(e) => handleTextInput(e, setInitialValues)}
      />
      <TextInput
        name="attendanceCriteria"
        id="input-attend-pass"
        type="basic"
        required
        labelText="เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)"
        placeholder="ครั้ง"
        inputProps={{
          type: 'number',
          min: '1',
          onInput: handleNumberInput,
        }}
        value={_.get(attendance, 'attendanceCriteria', '')}
        textError={_.get(formik.errors, 'attendanceCriteria', '')}
        onChange={(e) => handleTextInput(e, setInitialValues)}
      />
      <TextInput
        name="qrCodeTime"
        id="input-qr-time"
        type="basic"
        required
        labelText="อายุของ QR Code (นาที)"
        placeholder="นาที"
        inputProps={{
          type: 'number',
          min: '1',
          onInput: handleNumberInput,
        }}
        value={_.get(attendance, 'qrCodeTime', '')}
        textError={_.get(formik.errors, 'qrCodeTime', '')}
        onChange={(e) => handleTextInput(e, setInitialValues)}
      />
    </StyledContainer>
  )
}

export default AttendanceForm
