import _ from 'lodash'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'
import { getQueryParams } from '../../../utils/lib'
import dayjs from 'dayjs'

export const fetchSearchParamsRoomManagement = (user) => async (dispatch) => {
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'DASHBOARD_REPORT' &&
      item.module === 'REPORT_ROOM_MANAGEMENT'
    )
  })
  let startDate = getQueryParams('start_date')
  let endDate = getQueryParams('end_date')
  let type = getQueryParams('type')
  let room_name = getQueryParams('room_name')
  const filter = []
  let id = 1

  if (startDate && endDate) {
    filter.push({
      id: id,
      key: 'bookingDate',
      label: `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(
        endDate,
      ).format('DD/MM/YYYY')}`,
      title: 'Date Use',
      typeOfValue: 'DATE_RANGE',
      value: { startDate, endDate },
    })
    id++
  }

  if (type) {
    const label = {
      MONTHLY_PLAN: 'Monthly Plan',
      MANAGE_CLASS: 'Manage Class',
      E_BOOKING: 'E-Booking',
    }
    filter.push({
      id: id,
      key: 'requestType',
      label: label[type],
      title: 'ประเภทการจองห้อง',
      typeOfValue: 'DROPDOWN_SINGLE',
      value: label[type],
    })
    id++
  }

  if (room_name) {
    const name = room_name.replace(/%20/g, ' ')
    filter.push({
      id: id,
      key: 'roomName',
      label: name,
      title: 'Room Name',
      typeOfValue: 'INPUT_TEXT',
      value: name,
    })
    id++
  }

  if (!hasPermissionALL) {
    let label = ''
    const station = []
    const defaultStation = _.get(user, 'area', []).find(
      (ele) => ele.station.uuid === _.get(user, 'stationUuid', ''),
    )
    station.push(defaultStation.station.station)
    label = defaultStation.station.station
    filter.push({
      id: id,
      key: 'stationName',
      label: label,
      title: 'Station',
      typeOfValue: 'DROPDOWN_MULTI',
      value: station,
      cantDel: true,
    })
  }

  if (filter.length > 0) {
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: filter,
      }),
    )
    dispatch(handleSaveFilters())
  }
}
