import { styled } from '@mui/system'

export const Image = styled('img')(() => ({
  width: '100%',
}))

export const StickyContainer = styled('div')(() => ({
  position: 'sticky',
  display: 'flex',
  gap: '16px',
  top: 0,
  width: '100%',
  background: '#f5f5f5',
  padding: '8px;',
  fontSize: '18px',
}))
