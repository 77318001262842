import { headerCells } from './List/model/headerCells'

export const crudConfig = {
  moduleId: 'BUDGET_MANAGEMENT',
  moduleSlug: 'budget-management',
  moduleTitleFull: 'Budget management',
  modulesTitleShort: 'Budget management',
  modulePath: '/budget-management',
  headerCells: headerCells,
}
