import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTheme from '@mui/styles/useTheme'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { StyledInventoryCard, StyledChip } from './Styled'
import { manageClassTheme } from '../../../../../constants/manageClass'
import { Row } from '../../../../../components/Layout/Styled'
import InventoryDrawer from './InventoryDrawer'

const InventoryCard = () => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { tickets, needAction } = useSelector(
    (state) => ({
      tickets: state.manageClassInventory.tickets,
      needAction: state.manageClassInventory.needAction,
    }),
    shallowEqual,
  )
  return (
    <StyledInventoryCard>
      <Divider orientation="vertical" />
      <Typography variant="body1b" sx={{ lineHeight: theme?.spacing(3) }}>
        การเบิกทรัพย์สิน
      </Typography>
      <Row sx={{ gap: 0.5 }}>
        <StyledChip label={`${tickets.length} รายการ`} />
        {needAction && (
          <ErrorIcon sx={{ fontSize: 20, color: theme.palette?.text?.error }} />
        )}
      </Row>
      <IconButton
        data-testid={`btn-inventory`}
        size="small"
        color={manageClassTheme.inventory.icon}
        onClick={() => setIsOpen(true)}
      >
        <ArrowForward />
      </IconButton>
      <InventoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </StyledInventoryCard>
  )
}

export default InventoryCard
