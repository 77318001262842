import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'

export const handleChangeInput = (e, idx, idxBudget, keyChange) => {
  const value = _.get(e, 'target.value', '')

  store.dispatch(
    setFieldValue({
      key: `formData.budgetDetails[${idxBudget}].data[${idx}].value`,
      value: value?.length > 0 ? value : null,
      keyChange: keyChange,
    }),
  )
}
