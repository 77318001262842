import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'
import Button from '@mui/material/Button'

export const StyledCardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}))

export const StyledFooterText = styled(Typography)({ textAlign: 'right' })

export const StyledContentRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& > .MuiTypography-root#label': { width: 200, minWidth: 200 },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const StyledFileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 210,
  minWidth: 210,
  gap: theme.spacing(2),
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  background: '#F6F9FF',
  borderRadius: theme.spacing(0.5),
}))

export const StyledTypography = styled(Typography)(() => ({
  maxWidth: 132,
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '18px',
  },
}))

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="s" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))
