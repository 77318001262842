import React from 'react'
import Typography from '@mui/material/Typography'

export const FormLabel = ({ title, variant, required }) => {
  return (
    <Typography variant={variant}>
      {title}
      {required && (
        <Typography variant="span" color="error">
          *
        </Typography>
      )}
    </Typography>
  )
}
