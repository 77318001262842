import TextInput from '../../../../../../components/Input/TextInput'
import { useDispatch } from 'react-redux'
import { handleInputRoomChange } from '../events'
import _ from 'lodash'

export const AdditionalEquipment = ({
  additionalEquipment,
  roomDetailIndex,
  errors,
  bookingDate,
  validateIndex,
}) => {
  const dispatch = useDispatch()
  return (
    <TextInput
      boxSx={{ pl: 10 }}
      type="basic"
      name="additionalEquipment"
      labelText={'รายละเอียดเพิ่มเติม'}
      placeholder="กรุณากรอกรายละเอียดเพิ่มเติม"
      rows={3}
      multiline={true}
      maxlength={3000}
      value={additionalEquipment}
      onChange={(e) => {
        dispatch(
          handleInputRoomChange(
            e,
            'additionalEquipment',
            roomDetailIndex,
            bookingDate,
          ),
        )
      }}
      isShowTextError={
        !_.isEmpty(
          errors,
          `roomManagement.roomManagementMeetingRoom[${validateIndex}].additionalEquipment`,
        )
      }
      textError={_.get(
        errors,
        `roomManagement.roomManagementMeetingRoom[${validateIndex}].additionalEquipment`,
        '',
      )}
    />
  )
}
