import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
  width: 'fit-content',
}))

export const StyledSmallButton = styled((props) => (
  <Button size="small" {...props} />
))(() => ({
  fontSize: '17px',
  lineHeight: '22px',
}))
