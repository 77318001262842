import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 16px',
  [theme.breakpoints.down('lg')]: {
    overflow: 'auto',
    paddingBottom: theme.spacing(1),
  },
}))

export const FilterBox = styled(Box)(({ clicked, theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.07)',
  borderRadius: '4px',
  padding: '0px 8px',
  margin: '0px 4px',
  cursor: 'pointer',
  backgroundColor: clicked
    ? theme?.palette?.primary?.main
    : theme?.palette?.info?.contrast,
  color: clicked
    ? theme?.palette?.info?.contrast
    : theme?.palette?.text?.primary,
  '&:hover': {
    backgroundColor: clicked
      ? theme?.palette?.primary?.main
      : 'rgba(73, 118, 186, 0.08)',
    borderColor: 'rgba(0, 0, 143, 0.5)',
    color: clicked
      ? theme?.palette?.info?.contrast
      : theme?.palette?.text?.primary,
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: 125,
  },
}))
