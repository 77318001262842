import { setChangeQuestionByKey } from '../../../../../../../../redux/slices/eEvaluationForm'

export const onDragEnd =
  ({ event, listData, sequence, type }) =>
  (dispatch) => {
    if (!event.destination) return
    if (event.destination.index === event.source.index) return
    const newList = reorder(
      listData,
      event.source.index,
      event.destination.index
    )

    dispatch(
      setChangeQuestionByKey({
        type: 'questions',
        sequence,
        key: type,
        value: newList,
      })
    )
  }
export const reorder = (listData, startIndex, endIndex) => {
  const listAnswer = Array.from(listData)
  const [removed] = listAnswer.splice(startIndex, 1)
  listAnswer.splice(endIndex, 0, removed)
  const result = listAnswer.filter((item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
      sequence: index + 1,
    }
  })
  return result
}
