import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { getQueryParams } from '../../../../../../utils/lib'
import { StyledDivider } from '../../../../components/View/Styled'
import TabMenu from '../../../../components/TabMenu'
import { viewOicTabs, viewTabs } from '../../../Form/model'
import { handleChangeTab, isOic } from './event'

const TabMenuContent = () => {
  const queryTab = getQueryParams('tab')
  const tab = useSelector(
    (state) => state.eLearningCourseDetail.tab,
    shallowEqual,
  )
  const isTypeOic = isOic()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleChangeTab(queryTab))
  }, [queryTab])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabMenu
        tab={tab}
        tabs={isTypeOic ? viewOicTabs : viewTabs}
        handleChange={handleChangeTab}
      />
      <StyledDivider />
    </Box>
  )
}
export default TabMenuContent
