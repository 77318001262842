import { defaultValue } from '../../../constants/eEvaluation'

export const initialState = {
  isLoading: false,
  section: defaultValue.defaultSection.evaluate,
  page: 1,
  information: {
    coverImage: '',
    coverText: '',
    description: '',
    descriptionPosition: defaultValue.position.left,
    name: '',
    title1: '',
    title1Position: defaultValue.position.left,
    title2: '',
    title2Position: defaultValue.position.left,
  },
  setting: {
    displayQuestion: defaultValue.displayQuestion.all,
  },
  questions: [],
  courses: [],
  lecturers: [],
  courseManagements: [],
  assessmentList: [],
  totalAssessments: 0,
  constantList: [],
}
