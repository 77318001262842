import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Tooltip from '@mui/material/Tooltip'
import useTheme from '@mui/system/useTheme'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleEnableButton,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead2,
} from './Styled'
import _ from 'lodash'
import { setEnableButton } from '../../../../../../redux/slices/dialog'
import { E_EXAM_UPLOAD_STATUS } from '../../../../../../constants/eExamination'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Truncate from '../../../../../../components/redux/Table/Truncate'
import { assetTypeEnum, fromTypeEnum } from '../../enum/assetTypEnum'

export const CheckDialog = () => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const { importData, type, from } = useSelector(
    (state) => ({
      importData: state.crud.importData,
      type: state.crud.formData.type,
      from: state.crud.formData.from,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const theme = useTheme()
  const result = _.get(importData, 'data', [])
  const status = _.get(importData, 'status', '')
  const successCount = useMemo(() => {
    const successList = result.filter((item) => item.status === 'SUCCESS')
    if (_.size(successList) == _.size(result)) {
      dispatch(setEnableButton())
    }

    return successList.length
  }, [result])
  const uploadSuccess = status === 'SUCCESS'
  const isImportProduct = from?.value === fromTypeEnum.IMPORT_PRODUCT

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <StyledDialog>
        {!uploadSuccess && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              สำเร็จ <span>{successCount} รายการ</span>
              {' , '}
              ไม่สำเร็จ <span>{_.size(result) - successCount} รายการ</span>{' '}
            </Typography>
          </Box>
        )}
        <StyledTable>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <StyledTableHead2>
                <TableRow>
                  <TableCell sx={{ width: '130px', minWidth: '9vw' }}>
                    <Typography>สถานะ</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '130px' }}>
                    <Typography>No.</Typography>
                  </TableCell>
                  {type === assetTypeEnum.STOCK && (
                    <>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>ชื่อทรัพย์สิน</Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>จำนวนทรัพย์สิน</Typography>
                      </TableCell>
                      {isImportProduct && (
                        <TableCell sx={{ minWidth: '150px' }}>
                          <Typography>มูลค่าต่อหน่วย (฿)</Typography>
                        </TableCell>
                      )}
                    </>
                  )}
                  {type === assetTypeEnum.SKU && (
                    <>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>ชื่อทรัพย์สิน</Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>รหัสทรัพย์สิน</Typography>
                      </TableCell>
                      {isImportProduct && (
                        <>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center Name</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>มูลค่าต่อหน่วย (฿)</Typography>
                          </TableCell>
                        </>
                      )}
                    </>
                  )}
                  {type === assetTypeEnum.ASSET && (
                    <>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>ชื่อทรัพย์สิน</Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>รหัสทรัพย์สิน</Typography>
                      </TableCell>
                      {isImportProduct && (
                        <>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center Name</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Item Name</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>มูลค่าต่อหน่วย (฿)</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันเริ่มต้นสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันสิ้นสุดสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันต่อสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>ชื่อผู้ถือครอง</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>สกุลผู้ถือครอง</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Email ผู้ถือครอง</Typography>
                          </TableCell>
                        </>
                      )}
                    </>
                  )}
                  {type === assetTypeEnum.LICENSE && (
                    <>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>ชื่อทรัพย์สิน</Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px' }}>
                        <Typography>รหัสทรัพย์สิน</Typography>
                      </TableCell>
                      {isImportProduct && (
                        <>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Cost Center Name</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>มูลค่าต่อหน่วย (฿)</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันเริ่มต้นสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันสิ้นสุดสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วันต่อสัญญา</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>ชื่อผู้ถือครอง</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>สกุลผู้ถือครอง</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Email ผู้ถือครอง</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>ชื่อผู้ชำระเงิน</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>สกุลผู้ชำระเงิน</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>Email ผู้ชำระเงิน</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>วิธีชำระเงิน</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '150px' }}>
                            <Typography>รายละเอียด/ชื่อบัตร</Typography>
                          </TableCell>
                        </>
                      )}
                    </>
                  )}
                </TableRow>
              </StyledTableHead2>
              <TableBody>
                {result.map((item) => (
                  <StyledTableData
                    status={item.status}
                    key={item.index}
                    sx={{}}
                  >
                    <TableCell>
                      {item.status === 'SUCCESS' ? (
                        <Typography>
                          {E_EXAM_UPLOAD_STATUS[item.status]}
                        </Typography>
                      ) : (
                        <Typography
                          color="error"
                          sx={{ display: 'flex', gap: 1 }}
                        >
                          <Typography>
                            {E_EXAM_UPLOAD_STATUS[item.status]}
                          </Typography>
                          <Tooltip
                            title={
                              <ul style={{ margin: 0, marginLeft: '-20px' }}>
                                {_.get(item, 'error', []).map((err) => {
                                  return (
                                    <li
                                      key={err}
                                      style={{ display: 'list-item', p: 0 }}
                                    >
                                      {err}
                                    </li>
                                  )
                                })}
                              </ul>
                            }
                            followCursor
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  background: theme?.palette?.blue?.azure,
                                  color: theme?.palette?.common?.white,
                                  boxShadow: theme?.palette?.boxShadow?.main,
                                  fontSize: 18,
                                },
                              },
                            }}
                          >
                            <ErrorOutline color="error" />
                          </Tooltip>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{'' + _.get(item, 'no', '-')}</TableCell>
                    {type === assetTypeEnum.STOCK && (
                      <>
                        <TableCell>
                          <Truncate>{'' + (item?.productName ?? '-')}</Truncate>
                        </TableCell>
                        <TableCell>
                          <Truncate>{'' + (item?.quantity ?? '-')}</Truncate>
                        </TableCell>
                        {isImportProduct && (
                          <TableCell>
                            <Truncate>{'' + (item?.unitPrice ?? '-')}</Truncate>
                          </TableCell>
                        )}
                      </>
                    )}
                    {type === assetTypeEnum.SKU && (
                      <>
                        <TableCell>
                          <Truncate>{'' + (item?.productName ?? '-')}</Truncate>
                        </TableCell>
                        <TableCell>
                          <Truncate>{'' + _.get(item, 'skuNo', '-')}</Truncate>
                        </TableCell>
                        {isImportProduct && (
                          <>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterId ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterName ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.unitPrice ?? '-')}
                              </Truncate>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {type === assetTypeEnum.ASSET && (
                      <>
                        <TableCell>
                          <Truncate>{'' + (item?.productName ?? '-')}</Truncate>
                        </TableCell>
                        <TableCell>
                          <Truncate>{'' + _.get(item, 'skuNo', '-')}</Truncate>
                        </TableCell>
                        {isImportProduct && (
                          <>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterId ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterName ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>{'' + (item?.assetNo ?? '-')}</Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.unitPrice ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.contractStartDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.contractEndDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.renewContractDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.firstNameHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.lastNameHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.emailHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {type === assetTypeEnum.LICENSE && (
                      <>
                        <TableCell>
                          <Truncate>{'' + (item?.productName ?? '-')}</Truncate>
                        </TableCell>
                        <TableCell>
                          <Truncate>{'' + _.get(item, 'skuNo', '-')}</Truncate>
                        </TableCell>
                        {isImportProduct && (
                          <>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterId ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.costCenterName ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.unitPrice ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.contractStartDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.contractEndDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.renewContractDate ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.firstNameHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.lastNameHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.emailHolder ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.firstNamePayer ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.lastNamePayer ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.emailPayer ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.paymentType ?? '-')}
                              </Truncate>
                            </TableCell>
                            <TableCell>
                              <Truncate>
                                {'' + (item?.creditCardName ?? '-')}
                              </Truncate>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                  </StyledTableData>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTable>
        {successCount > 0 && successCount != _.size(result) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label="เพิ่มรายการที่สามารถเพิ่มได้"
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={handleSubmitAdd}
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">สามารถเพิ่มรายการทรัพย์สินได้ทั้งหมด</Typography>
    </Box>
  )
}
