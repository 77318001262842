import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'
import _ from 'lodash'

export const StyledRow = styled(Box)(() => ({
  display: 'flex',
}))

export const StyledResposiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '85%',
  [theme.breakpoints.down('lg')]: {
    width: '75%',
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  border: '1px solid',
  borderColor: theme.palette?.text?.silver,
  backgroundColor: theme.palette?.text?.mercury,
  width: '10%',
  [theme.breakpoints.down('lg')]: {
    width: '18%',
  },
}))

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: 0,
  height: 5,
  left: 167,
  top: -10,
}))

export const StyledTextContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textError',
})(({ theme, textError }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: _.isEmpty(textError) ? theme.spacing(3) : 'unset',
}))
