import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import { StyledColumn } from '../../../Styled'
import { handleSelector } from '../../events'
import TabContent from '../TabContent'
import { StyledTab } from './Styled'

const CoursesTabs = ({ courseIndex }) => {
  const [value, setValue] = useState(0)
  const keyField = `coursesInformation[${courseIndex}]`
  const course = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const tabs = _.get(course, 'taps', [])

  useEffect(() => {
    setValue(0)
  }, [courseIndex])

  return (
    <StyledColumn gap={3}>
      <Tabs
        sx={{
          '& .MuiTabScrollButton-root': { display: 'none' },
          '& .MuiTabs-indicator': { height: 4 },
        }}
        variant="scrollable"
        value={value}
        onChange={(e, tab) => setValue(tab)}
      >
        {tabs.map((item, index) => (
          <StyledTab
            key={index}
            label={index + 1}
            value={index}
            data-testid={`tab-${index}`}
          />
        ))}
      </Tabs>

      <TabContent tabs={tabs} index={value} courseIndex={courseIndex} />
    </StyledColumn>
  )
}

export default CoursesTabs
