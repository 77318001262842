import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { shallowEqual, useSelector } from 'react-redux'
import { initialize } from './handler/initialize'
import Header from './components/Header'
import FilterDrawer from './Drawers/FilterDrawer'
import AllTypes from './components/AllTypes'
import EachType from './components/EachType'
import TopCourse from './components/TopCourse'
import DetailDrawer from './Drawers/DetailDrawer'

const MixModuleDashboard = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  useEffect(initialize, [])
  return (
    <Box px={2}>
      <Box>
        <Header />
      </Box>
      <Box mt={3}>
        <AllTypes />
      </Box>
      <Box mt={3}>
        <EachType />
      </Box>
      <Box mt={3}>
        <TopCourse />
      </Box>
      <FilterDrawer />
      <DetailDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}
export default MixModuleDashboard
