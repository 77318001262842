import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TableCell from '@mui/material/TableCell'
import styled from '@mui/system/styled'

export const LoadingPage = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'unset' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledHeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 16,
  right: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    '&#view': {
      position: 'relative',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  }),
)

export const InputImageUpload = styled('input')({
  display: 'none',
})

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))
