import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleOwnerChange = (e, value) => {
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerFName',
      value: _.get(value, 'name', ''),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerLName',
      value: _.get(value, 'lastname', ''),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerEmail',
      value: _.get(value, 'email', ''),
    }),
  )
}
