import {
  setSelectedCourseFor,
  setDateRange,
} from '../../../../redux/slices/reportCourseSetting/Dashboard'
import _ from 'lodash'

export const handleSetSelectedCourseFor = (event) => (dispatch) => {
  const value = _.get(event, 'target.value', '')
  dispatch(setSelectedCourseFor(value))
}

export const handleChangeDateRange = (value) => (dispatch) => {
  dispatch(setDateRange(value))
}
