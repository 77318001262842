import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from './Styled'
import { breadcrumbApplicationDocuments } from './model'
import FilterDrawer from './FilterDrawer'

const ApplicationDocuments = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const { table } = useSelector(
    (state) => ({
      table: state.table,
    }),
    shallowEqual
  )
  const { limit, order, page, sort, isLoading } = table.table
  const { filterProp, other, isFilterDrawer } = table.filter
  const manageProps = { uuid }
  const quickSeach = ''
  useEffect(() => {
    dispatch(fetchDataList('fetch', table, quickSeach, manageProps))
  }, [limit, order, page, sort, filterProp, other])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">
            {breadcrumbApplicationDocuments(uuid)[4].title}
          </Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbApplicationDocuments(uuid)} />

        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default ApplicationDocuments
