export const handleEventDrawer = (info, setState) => {
  setState({
    uuid:
      info.event._def.extendedProps.workType === 4
        ? info.event._def.extendedProps.uuid
        : info.event._def.extendedProps.trainingPlanUuid,
    isOpen: true,
    isHoliday: info.event._def.extendedProps.workType === 4 ? true : false,
  })
}
