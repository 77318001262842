import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

export default function Error404() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '45%',
        left: '45%',
      }}
    >
      <Typography variant="h4">404 Not Found</Typography>
      <Button
        sx={{ alignSelf: 'center' }}
        variant="contained"
        onClick={() => window.history.back()}
      >
        Back
      </Button>
    </Box>
  )
}
Error404.templateLayout = '404'
