import _ from 'lodash'
import { approveStatus } from '../../../../../constants/monthlyPlan/speaker'

export const handleFilterOption = (props) => {
  const { option, valueList, valueUuid, borrowListSameStation } = props
  return option.filter((item) => {
    const isSelectedItem = valueList
      .filter((item) => item.status !== approveStatus.DELETED)
      .some(
        (speaker) => _.get(speaker, 'responsibility.uuid', '') === item.uuid
      )
    const isBorrowed = borrowListSameStation.some((speaker) =>
      _.get(speaker, 'speakerBorrowResponsibility', []).some(
        (borrow) =>
          _.get(borrow, 'responsibility.uuid', '') === item.uuid &&
          conditionStatus.includes(_.get(borrow, 'status', ''))
      )
    )
    return (!isBorrowed && !isSelectedItem) || item.uuid === valueUuid
  })
}

export const conditionStatus = [
  approveStatus.APPROVE,
  approveStatus.WAITING,
  approveStatus.DRAFT,
]
