import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"

export const handleCheckEndDate = async (event, value) => {
    if(value){
        const { formData } = store.getState().crud
        store.dispatch(setFieldValue({
            key: 'formData.renewContractDateForm',
            value: formData.contractEndDateForm,
        }))
    }
}