import _ from 'lodash'
import localforage from 'localforage'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  setHandleError,
} from '../../../redux/slices/dialog'
import * as reducer from '../../../redux/slices/eTestingForm'
import * as service from '../../../services/eTesting/form'
import { eTestingVersion } from '../../../utils/apiPath'
import { defaultValue } from '../../../constants/eTesting'
import { schema, draftSchema } from './schema'
import { convertErrorMessage, handleErrorMessage } from './TestingForm/event'
import { initialState } from '../../../redux/slices/eTestingForm/model'

export const handlePersist = (props) => async (dispatch) => {
  const { uuid, isClone, onEdit, setOnEdit, codeId, createType } = props
  const persist = await localforage.getItem('persist:root')
  const objPersist = JSON.parse(persist)
  const persistUuid = _.get(objPersist, 'eTesting.uuid', '')
  const eTestingForm = _.get(objPersist, 'eTestingForm', `{}`)
  const objEtest = JSON.parse(eTestingForm)

  //if for validate persist uuid and real uuid from link
  if (uuid && (persist === null || persistUuid !== uuid) && !isClone) {
    //case edit, new window by preview, enter link
    dispatch(fetchDataForm({ uuid, isClone, onEdit, setOnEdit }))
  } else {
    if (
      (persist === null || persistUuid !== uuid) &&
      !isClone &&
      validateInitialData(objEtest)
    ) {
      //case create
      dispatch(getAssignee(codeId, createType))
    } else if (isClone) {
      //case clone
      dispatch(fetchDataForm({ uuid, isClone, onEdit, setOnEdit }))
    }
  }
}

export const fetchDataForm = (props) => async (dispatch) => {
  const { uuid, isClone, setOnEdit } = props
  dispatch(reducer.startLoading())
  const data = await Promise.all([
    dispatch(service.getFormDetail(uuid)),
    dispatch(service.getAssignees()),
    dispatch(service.getCurrentDateServer()),
  ])

  let detailData = convertFormData(_.get(data, '[0].payload', initialState))
  const type = _.get(detailData, 'type', '')
  let examiners = []
  if (type === defaultValue.typeOfETesting.standalone) {
    const res = await dispatch(service.getExaminers())
    examiners = _.get(res, 'payload.result', [])
  }
  if (isClone) {
    detailData = {
      ...detailData,
      uuid: '',
      setting: {
        ...detailData.setting,
        startDate: null,
        oldStartDate: _.get(detailData.setting, 'startDate', ''),
        status: 'DRAFT',
        createdAt: null,
        createdBy: '-',
        updatedAt: null,
        updatedBy: '-',
        courseRef: [],
        shareLink: undefined,
        shareDate: null,
        isShare: false,
      },
    }
  }
  dispatch(
    reducer.setFetchData({
      ...detailData,
      examiners,
      assignee: _.get(data, '[1].payload.result', []),
      dateServer: _.get(data, '[2].payload', null),
      isClone: !!isClone,
    }),
  )
  dispatch(reducer.stopLoading())
  setOnEdit(true)
}

export const getAssignee = (codeId, createType) => async (dispatch) => {
  dispatch(reducer.startLoading())
  dispatch(reducer.setClearData())
  dispatch(reducer.setETestingType(createType))

  const services = [
    dispatch(service.getAssignees()),
    dispatch(service.getCurrentDateServer()),
  ]
  if (createType === 'standalone') {
    services.push(dispatch(service.getExaminers()))
  }
  const data = await Promise.all(services)

  dispatch(
    reducer.setFetchAssignee({
      assignee: _.get(data, '[0].payload.result', []),
      examiners: _.get(data, '[2].payload.result', []),
      dateServer: _.get(data, '[1].payload', null),
      codeId: codeId || '',
    }),
  )
  dispatch(reducer.stopLoading())
}

export const fieldValidate = {
  name: {
    field: 'name',
    max: 255,
    messageEtest: 'กรุณากรอกชื่อแบบทดสอบ',
    messageEva: 'กรุณากรอกชื่อแบบประเมิน',
    maxMessage: 'ระบุได้ไม่เกิน 255 ตัวอักษร',
  },
  coverText: {
    field: 'coverText',
    max: 100,
    maxMessage: 'ข้อความหน้าปกระบุได้ไม่เกิน 100 ตัวอักษร',
  },
  title1: {
    field: 'title1',
    max: 100,
    maxMessage: 'หัวข้อ 1 ระบุได้ไม่เกิน 100 ตัวอักษร',
  },
  title2: {
    field: 'title2',
    max: 100,
    maxMessage: 'หัวข้อ 2 ระบุได้ไม่เกิน 100 ตัวอักษร',
  },
  description: {
    field: 'description',
    max: 3000,
    maxMessage: 'รายละเอียดระบุได้ไม่เกิน 3000 ตัวอักษร',
  },
}
export const validateName = (value, isEva) => {
  let result = {}
  const obj = fieldValidate.name
  if (_.isEmpty(value)) {
    result = {
      ...result,
      name: isEva ? obj.messageEva : obj.messageEtest,
    }
  } else if (value.length > obj.max) {
    result = {
      ...result,
      name: obj.maxMessage,
    }
  }
  return result
}

export const validateInfo = (info, isEva) => {
  if (_.isEmpty(info)) return {}
  let result = {}
  _.forOwn(info, (value, key) => {
    const obj = fieldValidate[key]
    const newValue = _.defaultTo(value, '')
    if (!_.isEmpty(obj)) {
      if (key === fieldValidate.name.field) {
        result = {
          ...result,
          ...validateName(newValue, isEva),
        }
      } else {
        if (!_.isEmpty(newValue) && newValue.length > obj.max) {
          result = {
            ...result,
            [key]: obj.maxMessage,
          }
        }
      }
    }
  })
  return result
}

export const onSubmitForm = (props) => async (dispatch) => {
  dispatch(
    reducer.setChange({
      key: 'errorsApi',
      value: false,
    }),
  )

  const errorInfo = validateInfo(props.stateETesting.information)
  if (!_.isEmpty(errorInfo)) {
    dispatch(
      handleErrorMessage({
        field: 'info',
      }),
    )
    props.setErrors({
      information: errorInfo,
    })
    return
  }
  const realSchema = props.isDraft ? draftSchema : schema
  await realSchema
    .validate(props.stateETesting)
    .then(() => {
      dispatch(reducer.setErrorSchemaMessage(convertErrorMessage('')))
      dispatch(
        openDialog({
          title: props.isDraft
            ? 'ยืนยันบันทึกฉบับร่าง'
            : 'ยืนยันบันทึกและเผยแพร่',
          message: props.isDraft
            ? 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่'
            : 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
          handleConfirm: () => dispatch(actionSubmit(props)),
        }),
      )
    })
    .catch((er) => {
      const messageObj = convertErrorMessage(er.message)
      let objError = {}
      if (messageObj.field === 'DATE') {
        objError = { setting: { startDate: messageObj.message } }
      } else if (messageObj.field === 'NAME') {
        objError = { information: { name: messageObj.message } }
      } else if (messageObj.field === 'DESCRIPTION') {
        objError = { information: { description: messageObj.message } }
      } else if (messageObj.field === 'TITLE1') {
        objError = { information: { title1: messageObj.message } }
      } else if (messageObj.field === 'TITLE2') {
        objError = { information: { title2: messageObj.message } }
      }
      dispatch(handleErrorMessage(messageObj))
      dispatch(
        openErrorDialog({
          title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          message: messageObj.message,
          handleError: () => {
            dispatch(reducer.setErrorSchemaMessage(messageObj))
            dispatch(
              reducer.setChange({
                key: 'errorsApi',
                value: true,
              }),
            )
            props.setErrors(objError)
          },
        }),
      )
    })
}

export const findQuestionType = (list) => {
  if (!list.length) return false
  const findFreeText = list.find((item) => item.questionType === 'FREE_TEXT')
  return !_.isEmpty(findFreeText)
}

export const actionSubmit = (props) => async (dispatch) => {
  const { stateETesting, isDraft, history } = props
  const startDate = _.get(stateETesting, 'setting.startDate', '')
  const testResult = _.get(stateETesting, 'setting.testResult', false)
  const typeOfETesting = _.get(stateETesting, 'typeOfETesting', 'ONLINE_TEST')
  let testCriteriaType = _.get(stateETesting, 'setting.testCriteriaType', null)
  if (typeOfETesting === 'STANDALONE') {
    testCriteriaType = _.isNil(testCriteriaType) ? 'REQUIRED' : testCriteriaType
  } else testCriteriaType = null
  const checkType = findQuestionType(_.get(stateETesting, 'questions', []))
  const body = {
    uuid: _.get(stateETesting, 'uuid', ''),
    codeId: _.get(stateETesting, 'codeId', ''),
    typeOfETesting,
    information: _.get(stateETesting, 'information', {}),
    setting: {
      ..._.get(stateETesting, 'setting', {}),
      testResult: checkType ? 'LATER' : testResult,
      startDate:
        startDate === null || startDate === 'Invalid Date' ? '' : startDate,
      testCriteriaType,
    },
    questions: convertScore(_.get(stateETesting, 'questions', [])),
    isDraft,
  }
  dispatch(reducer.startLoading())
  dispatch(loadingDialog())

  const result = await dispatch(service.submitForm(body))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled')
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        isCloseDialog: false,
        handleConfirm: () => {
          history.push(
            `${eTestingVersion}/${_.get(result, 'payload.data.codeId', '')}`,
          )
          dispatch(closeDialog())
          dispatch(reducer.setClearData())
        },
      }),
    )

  dispatch(errorsResultApi(result, props.setErrors))
  dispatch(reducer.stopLoading())
}

export const errorsResultApi = (result, setErrors) => (dispatch) => {
  const keyResult = _.get(result, 'meta.response.data.property', '')
  const errorMessage = _.get(result, 'meta.response.data.constraints.value', {})

  if (keyResult === 'CanNotSaveStartDate' || keyResult === 'CanNotSaveName') {
    let objError = { setting: { startDate: errorMessage } }
    if (keyResult === 'CanNotSaveName') {
      objError = {
        information: {
          name: 'ชื่อแบบทดสอบนี้ถูกใช้แล้ว กรุณาตั้งชื่อแบบทดสอบใหม่',
        },
      }
    }
    dispatch(
      setHandleError(() => {
        setErrors(objError)
        dispatch(
          reducer.setChange({
            key: 'errorsApi',
            value: true,
          }),
        )

        dispatch(
          reducer.setChange({
            key: 'defaultTap',
            value: keyResult === 'CanNotSaveName' ? 'testing' : 'setting',
          }),
        )
      }),
    )
  }
}

export const convertFormData = (etest) => {
  const newQuestion = etest.questions.map((question) => {
    return {
      ...question,
      answers: question.answers.map((answer) => {
        return { ...answer, questionType: question.questionType }
      }),
    }
  })

  return {
    ...etest,
    questions: newQuestion,
    setting: {
      ...etest.setting,
      courseRef: filterCourseRef(_.get(etest, 'setting.courseRef', [])),
    },
  }
}

export const filterCourseRef = (list) => {
  if (!list.length) return []
  let result = []
  list.forEach((item) => {
    const findIndex = _.findIndex(result, ['uuid', item.uuid])
    if (findIndex >= 0) {
      const before = _.get(result, `[${findIndex}].isOnTesting`, false)
      const after = _.get(item, 'isOnTesting', false)
      const isOnTesting = before || after ? true : false
      _.set(result, `[${findIndex}].isOnTesting`, isOnTesting)
    } else {
      result.push(item)
    }
  })

  return result
}

export const convertScore = (questions) => {
  if (questions.length <= 0) return []

  return questions.map((item) => {
    return {
      ...item,
      score: parseFloat(item.score),
      answers:
        item.answers.length <= 0
          ? []
          : item.answers.map((item) => {
              return {
                ...item,
                id: item.isDraft,
                isCorrect: item.isCorrect,
                mediaUrl: item.mediaUrl,
                sequence: item.sequence,
                title: item.title,
                uuid: item.uuid,
                score: parseFloat(item.score),
              }
            }),
    }
  })
}

export const validateInitialData = (objEva) => {
  const coverImage = _.get(objEva, 'information.coverImage', '')
  const coverText = _.get(objEva, 'information.coverText', '')
  const description = _.get(objEva, 'information.description', '')
  const name = _.get(objEva, 'information.name', '')
  const title1 = _.get(objEva, 'information.title1', '')
  const title2 = _.get(objEva, 'information.title2', '')
  const startDate = _.get(objEva, 'setting.startDate', null)
  const questions = _.get(objEva, 'questions', [])
  const hasAllCondition =
    questions.length === 0 &&
    coverImage === '' &&
    coverText === '' &&
    description === '' &&
    name === '' &&
    title1 === '' &&
    title2 === '' &&
    startDate === null

  return hasAllCondition
}

export const scrollSmoothHandler = (no, ref) => {
  const realIndex = no - 1
  if (no < 0 || _.isNaN(no) || _.isNil(ref.current[realIndex]?.current)) return
  ref.current[realIndex].current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}
