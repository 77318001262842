import _ from 'lodash'
import {
  manageClassLearnerDownload,
  manageClassLearnerFilter,
} from '../../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { setDateRageList } from '../../../redux/slices/manageClassLearner'
import { getManageClassDetailByUuid } from '../../../services/manageClass/detail'
import { downloadFile } from '../../../services/util'
import { headCellsLearner } from '../../../constants/manageClass'
import callAxios from '../../../utils/baseService'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'codeId' : sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: manageClassLearnerDownload,
      body: body,
      fileName: `รายการผู้เรียน.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, filterProp, keyword, uuid) => async (dispatch) => {
    dispatch(startLoading())
    const response = await dispatch(getManageClassDetailByUuid(uuid))
    const manageClass = _.get(response, 'payload.data', undefined)
    const trainingPlatform = _.get(
      manageClass,
      'trainingPlatForm[0].abbreviation',
      'CR',
    )
    const sortBy = table.sort === 'updatedAt' ? 'codeId' : table.sort
    const realPage = page <= 0 ? 1 : +page
    const title = _.get(filterProp, 'name', keyword)
    const attendance = _.get(filterProp, 'attendance', null)
    const body = {
      trainingPlan: uuid,
      codeId: _.get(filterProp, 'codeId', ''),
      name: method === 'filter' ? keyword : title,
      attendance: attendance ? Number(attendance) : null,
      trainingResult: _.get(filterProp, 'trainingResult', []),
      status: _.get(filterProp, 'status', []),
      room: _.get(filterProp, 'room', []),
      date: _.get(filterProp, 'date', ''),
      limit: table.limit,
      order: table.order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const isCancel = await callAxios
      .post(manageClassLearnerFilter, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => ({
          ...item,
          prefixTH: item.userProfile.prefixTH || '-',
          firstNameTH: item.userProfile.firstNameTH,
          lastNameTH: item.userProfile.lastNameTH,
          mobileNo: item.userProfile.mobileNo || '-',
          unitNumber: item.userProfile.unitNumber || '-',
          statusLearner: item.status,
          isLearner: true,
          onlineRoom: _.get(item.onlineRoom, '[0].no', '-'),
          classLearnerCourseStatus: handleLearnerResult(item),
        }))
        const dateRange = _.get(data, 'dateRange', []).map((item) => ({
          label: item,
          value: item,
        }))
        dispatch(setDateRageList(dateRange))
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: headCellsLearner[trainingPlatform],
            placeholder: 'ค้นหาชื่อ',
            searchKey: 'name',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(table, text, uuid)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCellsLearner[trainingPlatform],
          }),
        )
        return e.message?.includes('method')
      })

    if (!isCancel) dispatch(stopLoading())
  }

export const handleLearnerResult = (item) => {
  const isPass = _.get(item, 'classLearnerCourseStatus', null)
  if (_.isNull(isPass)) return '-'
  return isPass ? 'ผ่านการอบรม' : 'ไม่ผ่านการอบรม'
}

export const onFilterClick = (table, filter, uuid) => (dispatch) => {
  dispatch(setSearchText(''))

  const resultList = handleFilterResult(filter)
  const statusList = handleFilterStatus(filter)

  const filterText = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
    attendance: filter.attendanceChecked ? filter.attendance : null,
    trainingResult: resultList,
    room: filter.roomChecked
      ? filter.room.map((item) => Number(item.value))
      : [],
    date: filter.roomChecked ? filter.date : '',
    status: statusList,
  }

  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(fetchDataList('filter', table, 1, filterText, filterText.name, uuid))
}

export const handleQuickSearch = (table, text, manageProps) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, null, text, manageProps))
}

export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    eligible: theme?.palette?.success?.main,
    ineligible: theme?.palette?.error?.main,
    eligible_announce: theme?.palette?.success?.main,
    waiting_announce: theme?.palette?.warning?.light,
    waiting_document: theme?.palette?.text?.silver,
    waiting_check: theme?.palette?.text?.silver,
    waiting_confirm: theme?.palette?.text?.silver,
    rejected_document: theme?.palette?.error?.main,
    cancel: theme?.palette?.text?.white,
    canceled: theme?.palette?.text?.white,
    absent: theme?.palette?.error?.main,
  }
  return colorList[statusText]
}

export const handleFilterResult = (filter) => {
  const resultList = []
  if (filter.trainingResultChecked) {
    if (filter.trainingResult.passChecked) {
      resultList.push('PASS')
    }
    if (filter.trainingResult.failChecked) {
      resultList.push('FAIL')
    }
  }
  return resultList
}

export const handleFilterStatus = (filter) => {
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.eligibleChecked) {
      statusList.push('ELIGIBLE_ANNOUNCE')
    }
    if (filter.status.waitAnnounceChecked) {
      statusList.push('WAITING_ANNOUNCE')
    }
    if (filter.status.waitDocumentChecked) {
      statusList.push('WAITING_DOCUMENT')
    }
    if (filter.status.waitCheckChecked) {
      statusList.push('WAITING_CHECK')
    }
    if (filter.status.waitingConfirm) {
      statusList.push('WAITING_CONFIRM')
    }
    if (filter.status.rejectedChecked) {
      statusList.push('REJECTED_DOCUMENT')
    }
    if (filter.status.cancelChecked) {
      statusList.push('CANCEL')
    }
    if (filter.status.canceledChecked) {
      statusList.push('CANCELED')
    }
    if (filter.status.ineligibleChecked) {
      statusList.push('INELIGIBLE')
    }
    if (filter.status.cancelClassChecked) {
      statusList.push('CANCEL_CLASS')
    }
    if (filter.status.absentChecked) {
      statusList.push('ABSENT')
    }
  }
  return statusList
}
