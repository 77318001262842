import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import useTheme from '@mui/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { Column, Row } from '../../../../../components/Layout/Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  DetailContainer,
  StyledChip,
  DotStyle,
} from './Styled'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { handleClickCreate } from './events'
import { useParams } from 'react-router-dom'
import {
  RequisitionStatus,
  RequisitionStatusColor,
  RequisitionStatusText,
} from '../../../../../constants/stock'

const InventoryDrawer = ({ isOpen, onCloseDrawer }) => {
  const { id: uuid } = useParams()
  const { tickets, manageClassData } = useSelector(
    (state) => ({
      tickets: state.manageClassInventory.tickets,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual,
  )
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const startDate = _.get(monthlyPlan, 'startDate', '')
  const courseName = _.get(monthlyPlan, 'course.englishName', '')
  const station = _.get(monthlyPlan, 'area.station.station', '')
  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">รายการเบิกทรัพย์สิน</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <Column sx={{ p: 3, gap: 2 }}>
          <Button
            variant="contained"
            sx={{ width: 'fit-content' }}
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              handleClickCreate({
                date: startDate,
                course: `${courseName} (${station})`,
                uuid: uuid,
              })
            }
          >
            สร้างรายการเบิก
          </Button>
          <Typography
            sx={{ my: '3px' }}
          >{`${tickets.length} รายการ`}</Typography>
          {tickets.map((ticket, ind) => {
            return <Detail ticket={ticket} key={ind} />
          })}
        </Column>
      </StyledContent>
    </StyledDrawer>
  )
}

export default InventoryDrawer

const Detail = ({ ticket }) => {
  return (
    <DetailContainer>
      <Typography>{`Ticket ID : ${ticket?.id}`}</Typography>
      <Row>
        <Typography>ผู้รับผิดชอบ : </Typography>
        {ticket?.responsibility ? (
          <StyledChip
            label={`${ticket.responsibility.firstNameTH} ${ticket.responsibility.lastNameTH}`}
          />
        ) : (
          '-'
        )}
      </Row>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          width: '80%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >{`สร้างโดย ${ticket?.createdBy?.name}`}</Typography>
      <Typography variant="body2" color="text.secondary">
        {convertFormatDateTime({ value: ticket?.createdAt, type: 'dateTime' })}
      </Typography>
      <Status status={ticket?.status} remainDate={ticket?.remainDate} />
      <IconButton
        size="small"
        color="primary"
        width={18}
        onClick={() =>
          window.open(`/inventory/requisition/view/${ticket.uuid}`, '_blank')
        }
      >
        <ArrowForward />
      </IconButton>
    </DetailContainer>
  )
}

const Status = ({ status, remainDate }) => {
  const theme = useTheme()
  return (
    <Row sx={{ gap: 1 }}>
      <DotStyle color={RequisitionStatusColor[status]} />
      <Typography color={RequisitionStatusColor[status]}>
        {RequisitionStatusText?.[status] ?? ''}
      </Typography>
      {[
        RequisitionStatus.WAITING_RETURN,
        RequisitionStatus.WAITING_RETURN_APPROVE,
      ].includes(status) ? (
        <>
          {remainDate >= 0 && (
            <Typography color="warning.light">
              {`เหลือ (${remainDate} วัน)`}
            </Typography>
          )}

          {remainDate < 0 && (
            <Typography color="error.main">
              {`เกิน (${Math.abs(remainDate)} วัน)`}
            </Typography>
          )}
        </>
      ) : (
        ''
      )}
      {(status === RequisitionStatus.WAITING_REQUISITION_COMFIRM ||
        remainDate < 0) && (
        <ErrorIcon sx={{ fontSize: 20, color: theme.palette?.text?.error }} />
      )}
    </Row>
  )
}
