import React from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'

import Footer from '../Footer'
import Other from '../Other'
import { optionsConfig, setDataConfig } from '../model'
import { mapTextHeader } from '../events'

import { ChartBar } from '../Styled'

const Index = ({ type }) => {
  const { itemQuestions, nextQuestions } = useSelector(
    (state) => ({
      itemQuestions: _.get(state, `eEvaluationFeedback[${type}]`, {}),
      nextQuestions: _.get(
        state,
        `eEvaluationFeedback.nextQuestions[${type}][0].value`
      ),
    }),
    shallowEqual
  )

  const { no, header } = mapTextHeader(type)

  const questions = _.get(itemQuestions, 'questions', [])
  const suggestionAnswer = _.get(itemQuestions, 'suggestionAnswer', [])
  const highestScore = _.get(itemQuestions, 'highestScore', 0)
  const listDataChart = questions.find(
    (item) => item.sequence === nextQuestions
  )

  const dataConfig = setDataConfig(
    _.get(listDataChart, 'listLabel', []),
    _.get(listDataChart, 'listData', [])
  )

  const propsFooter = {
    type,
    sequence: 1,
    nextQuestions,
    totalQuestion: questions.length,
    titleQuestion: _.get(listDataChart, 'title', '-'),
  }

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4">ตอนที่ {no}</Typography>
        <Typography variant="h5" sx={{ mt: -1 }}>
          {header}
        </Typography>
      </Box>
      {questions.length > 0 && (
        <Card sx={{ mt: 3 }}>
          <Box sx={{ ml: 5, mt: 3 }}>
            <Typography variant="h6">{header}</Typography>
          </Box>
          <Box sx={{ width: '100%', overflow: 'auto' }}>
            <ChartBar
              data={dataConfig}
              length={
                _.get(listDataChart, 'listLabel', []).length <= 4
                  ? 300
                  : _.get(listDataChart, 'listLabel', []).length <= 10
                  ? 500
                  : 1000
              }
              options={optionsConfig(highestScore)}
            />
          </Box>

          <Footer propsFooter={propsFooter} />

          {suggestionAnswer.length > 0 && (
            <Box sx={{ mx: 3, mb: 3, mt: 3 }}>
              <Typography variant="h6" sx={{ ml: 3, mb: 2 }}>
                ข้อเสนอแนะ / ความคิดเห็นเพิ่มเติม
              </Typography>
              <Other list={suggestionAnswer} />
            </Box>
          )}
        </Card>
      )}
    </>
  )
}

export default Index
