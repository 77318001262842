import React from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { StyledBadge } from './Styled'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { Typography } from '@mui/material'
import { handleEnrollClass } from './events'

export const EnrollClassButton = (props) => {
  const { uuid, handleOpenQrDrawer, isClosed } = props
  const dispatch = useDispatch()
  const { statusQRCode } = useSelector(
    (state) => ({
      statusQRCode: state.manageClassDetail.qrEnrollProps.statusQRCode,
    }),
    shallowEqual
  )
  const status = statusQRCode ? 'เปิดใช้งาน' : 'ปิดใช้งาน'

  return (
    <Box sx={{ position: 'relative' }}>
      <Tooltip
        title={<Typography variant="tooltip">{status}</Typography>}
        arrow
        placement="top"
      >
        <StyledBadge
          sx={{ position: 'absolute', right: 0, top: 0 }}
          enable={statusQRCode && !isClosed}
          variant="dot"
        />
      </Tooltip>
      <Button
        disabled={isClosed}
        data-testid="btn-enable-qr"
        startIcon={<QrCode2Icon />}
        variant="contained"
        size="m"
        onClick={() => dispatch(handleEnrollClass(uuid, handleOpenQrDrawer))}
      >
        ลงทะเบียนเข้าอบรม
      </Button>
    </Box>
  )
}
