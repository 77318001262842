import { StyledRow } from '../../../Styled'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { RowLabels } from './RowLabels'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Edit, HistoryTwoTone } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useTheme } from '@mui/material/styles'
import {
  openEditFormDrawerBooking,
  setReduxValue,
} from '../../../../../redux/slices/roomManagement/Drawer'
import {
  requestType,
  enumRequestType,
} from '../../../View/Content/CalendarCard/constanst'
import { useLocalStorage } from '@rehooks/local-storage'

export const SummaryBooking = () => {
  const [user] = useLocalStorage('user')
  const permissionList = _.get(user, 'permission', [])
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    requestTypeData,
    bookingUuid,
    stationName,
    bookingTopic,
    isEditingForm,
    status,
    courseName,
    isUserStation,
  } = useSelector((state) => ({
    requestTypeData: state.roomManagementDrawer.bookingInformation.requestType,
    bookingUuid: state.roomManagementDrawer.bookingInformation.bookingUuid,
    stationName: state.roomManagementDrawer.bookingInformation.stationName,
    bookingTopic: state.roomManagementDrawer.bookingInformation.bookingTopic,
    isEditingForm: state.roomManagementDrawer.isEditingForm,
    status: state.roomManagementDrawer.bookingInformation.status,
    courseName: state.roomManagementDrawer.bookingInformation.courseName,
    isUserStation: state.roomManagementDrawer.bookingInformation.isUserStation,
  }))
  const isCanEdit =
    !_.isEmpty(bookingUuid) && !isEditingForm && status !== 'CANCEL'
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'EDIT' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'EDIT' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })

  return (
    <>
      <StyledRow sx={{ justifyContent: 'space-between' }}>
        <StyledRow sx={{ gap: 1.5 }}>
          <img
            alt={''}
            src="/icon/bi_ticket-detailed-fill.svg"
            width={24}
            height={24}
          />
          <Typography variant="h6">สรุปข้อมูลการจอง</Typography>
        </StyledRow>

        <StyledRow
          sx={{
            justifyContent: !_.isEmpty(bookingUuid)
              ? 'space-between'
              : 'flex-end',
            gap: 2,
          }}
        >
          {status === 'CANCEL' && (
            <Box
              sx={{
                background: theme.palette?.primary?.contrast,
                boxShadow: `0px 0px 0px 1px ${theme.palette?.grey?.grey300}`,
                padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
                borderRadius: 15,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body3b" color="error.dark">
                Cancel
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              background: requestType.find(
                (type) => type.value === requestTypeData,
              ).background,
              borderRadius: 2,
              px: 1,
            }}
          >
            <Typography
              sx={{
                color: requestType.find(
                  (type) => type.value === requestTypeData,
                ).color,
              }}
              variant="body3b"
            >
              {requestType.find((type) => type.value === requestTypeData).label}
            </Typography>
          </Box>
          {!_.isEmpty(bookingUuid) &&
            !isEditingForm &&
            (requestTypeData !== enumRequestType.MONTHLY_PLAN ||
              (requestTypeData === enumRequestType.MONTHLY_PLAN &&
                !isEditingForm)) && (
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  dispatch(
                    setReduxValue({ key: 'ishShowHistoryDrawer', value: true }),
                  )
                }}
              >
                <HistoryTwoTone color="action.active" />
              </IconButton>
            )}

          {isCanEdit &&
            (hasPermissionALL || (hasPermissionPIC && isUserStation)) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="m"
                  startIcon={<Edit />}
                  onClick={() => {
                    dispatch(openEditFormDrawerBooking({ bookingUuid }))
                  }}
                >
                  แก้ไข
                </Button>
              </>
            )}
        </StyledRow>
      </StyledRow>
      <RowLabels title={'Station'} label={stationName} />
      <RowLabels
        titleSx={{ minWidth: 80 }}
        title={'หัวข้อการจอง'}
        label={bookingTopic}
      />

      {requestTypeData === enumRequestType.MONTHLY_PLAN && (
        <RowLabels title={'หลักสูตรอบรม'} label={courseName} />
      )}
    </>
  )
}
