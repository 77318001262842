import dayjs from 'dayjs'
import {
  openDrawer,
  setChange,
} from '../../../../redux/slices/manageClassCalendar'

export const optionMonth = [
  { label: 'มกราคม', value: 1 },
  { label: 'กุมภาพันธ์', value: 2 },
  { label: 'มีนาคม', value: 3 },
  { label: 'เมษายน', value: 4 },
  { label: 'พฤษภาคม', value: 5 },
  { label: 'มิถุนายน', value: 6 },
  { label: 'กรกฎาคม', value: 7 },
  { label: 'สิงหาคม', value: 8 },
  { label: 'กันยายน', value: 9 },
  { label: 'ตุลาคม', value: 10 },
  { label: 'พฤศจิกายน', value: 11 },
  { label: 'ธันวาคม', value: 12 },
]

export const openFilterDrawer = () => (dispatch) => {
  dispatch(openDrawer())
}

export const optionYear = ({ start, end }) => {
  let options = []
  for (let index = start; index <= end; index++) {
    options = [...options, { label: `${index}`, value: index }]
  }
  return options
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChange(props))
}

export const handleChangeIcon = (props) => (dispatch) => {
  const { month, year, type } = props
  let newMonth = month
  let newYear = year
  if (type === 'back') {
    const dataBack = dayjs(`${year}-${convertMonth(newMonth)}-01`).add(
      -1,
      'month'
    )
    newMonth = dayjs(dataBack).month()
    newYear = dayjs(dataBack).year()
  } else if (type === 'next') {
    const dataNext = dayjs(`${year}-${convertMonth(newMonth)}-01`).add(
      1,
      'month'
    )
    newMonth = dayjs(dataNext).month()
    newYear = dayjs(dataNext).year()
  }
  dispatch(
    setChange({
      key: 'month',
      value: newMonth + 1,
    })
  )

  dispatch(
    setChange({
      key: 'year',
      value: newYear,
    })
  )
}

export const convertMonth = (month) => {
  return month.length <= 1 ? `0${month}` : month
}
