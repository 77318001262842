import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import UploadFileRounded from '@mui/icons-material/UploadFileRounded'
import { StyledInputHidden, StyledUploadContainer } from '../stlyed'
import _ from 'lodash'
import { handleMaxFileSize } from '../handler/handleMaxFileSize'
import { InitialUploadWording } from './InitialUploadWording'

const DragDropUploadFile = ({
  label,
  id,
  isLoading,
  handleDrop,
  isMultiple,
  maxFileSize,
  accept,
  acceptLabel,
  maxLength,
  disabled,
  currentFilesLength,
}) => {
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop: handleDrop,
    accept,
    multiple: isMultiple,
    maxSize: handleMaxFileSize(maxFileSize),
    disabled,
    maxFiles: maxLength - currentFilesLength,
  })

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  })

  return (
    <>
      {!_.isEmpty(label) && <Typography variant="h6">{label}</Typography>}
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledUploadContainer
            isDragReject={isDragReject}
            {...getRootProps()}
          >
            <InitialUploadWording
              maxFileSize={maxFileSize}
              acceptLabel={acceptLabel}
              maxLength={maxLength}
            />

            <label htmlFor={`${id}-upload`}>
              <Button
                variant="contained"
                startIcon={<UploadFileRounded />}
                disabled={disabled}
              >
                อัปโหลดไฟล์
              </Button>
              <StyledInputHidden
                disabled={disabled}
                data-testid="input-upload"
                id={`${id}-upload`}
                type="file"
                {...getInputProps()}
              />
            </label>
          </StyledUploadContainer>
        </Box>
      </>
    </>
  )
}

export default DragDropUploadFile
