import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { CustomCheckbox } from '../../../../../../components/CRUD/components/CustomCheckbox'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { useTheme } from '@emotion/react'
import _ from 'lodash'
import { handleHasPeriodFlagReturn } from '../handler/handleHasPeriodFlagReturn'

export const FlagReturnSection = () => {
  const theme = useTheme()
  const {
    formData: { statusFlagReturn, hasPeriodFlagReturn },
    crudConfig,
    refundError,
  } = useSelector(
    (state) => ({
      formData: state.crud.formData,
      crudConfig: state.crud.crudConfig,
      refundError: _.find(state.crud.formErrors, { path: 'validateRefund' }),
    }),
    shallowEqual,
  )

  return (
    <Box width="100%" display="flex" gap={3}>
      <Box
        width="50%"
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        gap={3}
      >
        <CustomSwitchInLine
          boxSx={{ width: '50%' }}
          fieldName="statusFlagReturn"
          title="Flag คืนทรัพย์สิน เพื่อแจ้งว่าเป็นทรัพย์สินที่ต้องคืน"
        />
        <Box>
          <Typography variant="body2" visibility={'hidden'}>
            {' '}
          </Typography>
          <CustomCheckbox
            disabled={statusFlagReturn === enumStatus.INACTIVE}
            fieldName="hasPeriodFlagReturn"
            labelText="กำหนดระยะเวลาคืนทรัพย์สิน"
            handleExtraChange={handleHasPeriodFlagReturn}
          />
        </Box>
      </Box>
      <Box
        id={`${crudConfig.moduleSlug}-validateRefund`}
        width="50%"
        border={
          !_.isEmpty(refundError) && `solid 1px ${theme.palette.error.main}`
        }
        p={!_.isEmpty(refundError) && 1}
        position="relative"
      >
        <Box width="100%" display="flex" alignItems="center" gap={3}>
          <CustomInput
            required
            disabled={
              statusFlagReturn === enumStatus.INACTIVE || !hasPeriodFlagReturn
            }
            fieldName="refundYear"
            labelName="คืนทรัพย์สินภายใน"
            placeholder="จำนวนปี"
            inputProps={{
              'data-testid': 'input-quantityAlert',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
            boxSx={{ height: '100px' }}
          />
          <Box height="100px">
            <Typography visibility={'hidden'}> </Typography>
            <Typography variant="body1" my="auto">
              ปี
            </Typography>
            <Typography visibility={'hidden'}> </Typography>
          </Box>
          <CustomInput
            disabled={
              statusFlagReturn === enumStatus.INACTIVE || !hasPeriodFlagReturn
            }
            labelName=" "
            fieldName="refundMonth"
            placeholder="จำนวนเดือน"
            inputProps={{
              'data-testid': 'input-quantityAlert',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
            boxSx={{ height: '100px' }}
          />
          <Box height="100px">
            <Typography visibility={'hidden'}> </Typography>
            <Typography variant="body1" my="auto">
              เดือน
            </Typography>
            <Typography visibility={'hidden'}> </Typography>
          </Box>
          <CustomInput
            disabled={
              statusFlagReturn === enumStatus.INACTIVE || !hasPeriodFlagReturn
            }
            labelName=" "
            fieldName="refundDay"
            placeholder="จำนวนวัน"
            inputProps={{
              'data-testid': 'input-quantityAlert',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
            boxSx={{ height: '100px' }}
          />
          <Box height="100px">
            <Typography visibility={'hidden'}> </Typography>
            <Typography variant="body1" my="auto">
              วัน
            </Typography>
            <Typography visibility={'hidden'}> </Typography>
          </Box>
        </Box>
        {!_.isEmpty(refundError) && (
          <Box>
            <Typography color={theme.palette.error.main} mt={'-20px'}>
              {_.get(refundError, 'message') ?? ''}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
