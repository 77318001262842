import Typography from '@mui/material/Typography'
import _ from 'lodash'
import React from 'react'
import { formatData } from './events'

const ActionApplicationDocuments = ({ row }) => {
  const data = _.get(row, 'documentStatus', '')

  return (
    <Typography variant="body2" color={formatData(data, true)}>
      {formatData(data, false)}
    </Typography>
  )
}

export default ActionApplicationDocuments
