import { store } from '../../../App'
import { openDialog, closeDialog } from '../../../redux/slices/dialog'

export const handleFilterMixModule = (isDownload) => async (dispatch) => {
  return new Promise((resolve) => {
    const { displayFilters, filters } = store.getState().reportComp
    const findFilter = (isDownload ? filters : displayFilters).length > 0
    if (!findFilter) {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'กรุณาเลือกตัวกรอง',
          message:
            'กรุณาเลือกตัวกรอง อย่างน้อย 1 อย่าง และเลือกหลักสูตรไม่เกิน 15 หลักสูตร',
          handleConfirm: () => dispatch(closeDialog()),
        }),
      )
      resolve(false)
    } else {
      resolve(true)
    }
  })
}
