import React from 'react'
import Box from '@mui/material/Box'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'

const ActionPermission = ({ row, rowIndex, onView, onDelete }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => onView(row, rowIndex)}
      >
        <RemoveRedEyeTwoToneIcon color="action" />
      </IconButton>

      <IconButton
        data-testid={`btn-cancel-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => onDelete(row, rowIndex)}
      >
        <CancelIcon color="action" />
      </IconButton>
    </Box>
  )
}

export default ActionPermission
