import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const BoxShare = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const BoxQRCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const BoxInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(3.5),
  },
}))

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(-0.5),
  },
}))

export const InputUrl = styled('input')(({ theme }) => ({
  textOverflow: 'ellipsis',
  height: 40,
  background: theme?.palette?.text?.wildSand,
  border: `1px solid ${theme?.palette?.text?.silver}`,
  color: theme?.palette?.text?.disabled,
  marginBottom: theme.spacing(2),
}))

export const IconAlertShare = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme?.palette?.warning?.main,
  cursor: 'pointer',
  marginRight: theme.spacing(1),
}))
