import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eTestingDetail'
import { getDetail } from '../../../../services/eTesting/detail'

export const fetchETesting = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getDetail(uuid))
  dispatch(stopLoading())
}
