import axios from 'axios'
import { prospectProfileExamHistory } from '../../../../../../utils/apiPath'
import _ from 'lodash'
import { EXAMINER_EVENT_HISTORY_STATUS_TH } from '../../../../../../constants/eExamination'
import {
  startLoading2,
  stopLoading2,
} from '../../../../../../redux/slices/manage/agentProfile'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'author',
    label: 'ผู้ทำรายการ',
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่สมัคร',
    hideSortIcon: true,
  },
]

export const fetchExamHistory =
  ({ uuid, setData, events }) =>
  async (dispatch) => {
    dispatch(startLoading2())
    const page = _.get(events, 'page', 0)
    const body = {
      prospectUuid: uuid,
      limit: _.get(events, 'limit', 20),
      page: page <= 0 ? 1 : page,
    }
    try {
      const response = await axios.post(prospectProfileExamHistory, body)
      const result = _.map(
        _.get(response, 'data.result', []) ?? [],
        (result) => {
          return {
            ...result,
            event: EXAMINER_EVENT_HISTORY_STATUS_TH[result?.event],
          }
        },
      )
      setData({ ...response?.data, result })
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(stopLoading2())
    }
  }
