import React, { useEffect, useState } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { LoadingPage } from '../Styled'
import Header from './Header'
import BoardContent from './BoardContent'
import { fetchManageClassExam, fetchManageClassLearnerExam } from './events'

const CheckETesting = ({ isStandalone }) => {
  const idProps = useParams()
  const [presentLearner] = useLocalStorage('presentLearner')
  const [isStateLoading, setStateLoading] = useState(false)
  const {
    isLoading,
    learners: { learnerAll },
  } = useSelector((state) => state.manageClassCheckExam, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchManageClassExam({ idProps, setStateLoading, isStandalone }))
  }, [])

  useEffect(() => {
    if (isStateLoading) {
      dispatch(
        fetchManageClassLearnerExam({
          idProps,
          learnerList: learnerAll,
          isStandalone,
          presentLearner,
        })
      )
    }
  }, [learnerAll, isStateLoading])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Header isStandalone={isStandalone} />

        <BoardContent isStandalone={isStandalone} />
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default CheckETesting
