import _ from 'lodash'
import { store } from '../../../../../../App'

export const axisLabel = {
  x: 'ลำดับ',
  y: ' จำนวนคำตอบ',
}

export const chartCount = [
  { label: 'ดีมาก', number: 50, backgroundColor: '#B2DFDB' },
  { label: 'ดี', number: 40, backgroundColor: '#C8E6C9' },
  { label: 'ปานกลาง', number: 40, backgroundColor: '#DCEDC8' },
  { label: 'พอใช้', number: 40, backgroundColor: '#F0F4C3' },
  { label: 'ควรปรับปรุง', number: 30, backgroundColor: '#FFF9C4' },
]

export const chartPart1Model = (key = null) => {
  const { eEvaluationDashboard } = store.getState().crud
  if (key) {
    const data = eEvaluationDashboard?.overview?.[`${key}`]
    const answers = _.get(data, 'answers', [])
    const countArray = chartCount.map((item) => {
      const findAnswer = answers.find((ans) => ans.title === item.label)
      return {
        ...item,
        number: findAnswer ? findAnswer?.count : 0,
      }
    })
    return countArray
  }

  return [...chartCount]
}

export const partTitle = {
  part1: 'ตอนที่ 1 แบบประเมินหลักสูตร',
  part2: 'ตอนที่ 2 แบบประเมินวิทยากร',
  part3: 'ตอนที่ 3 แบบประเมินบริหารจัดการหลักสูตร',
  part4: 'ตอนที่ 4 แบบประเมิน / แบบสอบถามอื่นๆ',
}
