import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const StyledCourseCard = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  background: theme.palette?.info?.background,
  '& .MuiInputBase-root': { background: theme.palette?.background?.paper },
}))

export const StyledCourseHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  svg: { cursor: 'pointer' },
}))

export const StyledFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'start',
}))

export const StyledSmallButton = styled((props) => (
  <Button size="small" {...props} />
))(() => ({
  fontSize: '17px',
  lineHeight: '22px',
}))

export const StyledMiniIconBtn = styled(IconButton)(() => ({
  width: 24,
  height: 24,
}))
