import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { contentTypeText } from '../model/contentTypeText'
import { scrollToLast } from './scrollToLast'

export const handleAddContentTypeText = () => {
  const state = store.getState().crud

  const contents = [...state.formData.contents]
  contents.push(contentTypeText)

  store.dispatch(setFieldValue({ key: 'formData.contents', value: contents }))
  scrollToLast()
}
