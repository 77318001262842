import React from 'react'
import Button from '@mui/material/Button'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { StyledHeaderContainer } from '../Styled'
import { handleInputFile } from '../event'
import { hasEditPermission, onDownloadTemplate } from './events'
import { useDispatch } from 'react-redux'
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom'

const Header = ({ fetchData }) => {
  const dispatch = useDispatch()
  return (
    <StyledHeaderContainer>
      <Button
        startIcon={<VerticalAlignBottom />}
        data-testid="btn-download-0"
        variant="outlined"
        onClick={() => dispatch(onDownloadTemplate())}
        size="m"
      >
        ดาวน์โหลด Template
      </Button>
      <Button
        startIcon={<UploadFileRoundedIcon />}
        variant="contained"
        size="m"
        component="label"
        disabled={hasEditPermission()}
      >
        นำเข้าผลสอบ
        <input
          type="file"
          hidden
          id="btn-add-file"
          accept=".xlsx"
          onChange={(e) => dispatch(handleInputFile(e, fetchData))}
        />
      </Button>
    </StyledHeaderContainer>
  )
}
export default Header
