import React from 'react'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const ListStaffImage = styled('img')({
  width: '30px',
  height: '30px',
  objectFit: 'cover',
  borderRadius: '50%',
  marginRight: '10px',
  marginLeft: '5px',
  marginTop: '5px',
})

export const BoxLeft = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}))

export const ImageOne = styled('img')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 26,
  height: 26,
  border: `1px solid ${theme?.palette?.primary?.contrast}`,
  borderRadius: '64px',
}))

export const BoxMoreImage = styled(Box)(() => ({
  width: 26,
  height: 26,
  borderRadius: '64px',
  background: '#BDBDBD',
}))

export const LabelMoreImage = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.primary?.contrast,
  paddingLeft: theme.spacing(0.8),
  paddingTop: theme.spacing(0.1),
}))
export const TooltipDialog = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'auto',
    wordBreak: 'break-word',
    backgroundColor: theme?.palette?.blue?.azure,
  },
}))

export const LabelTooltip = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.primary?.contrast,
}))

export const BoxHeaderDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}))
export const BoxImageDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))
