import React, { useEffect } from 'react'
import Header from './Header'
import List from './List'
import Calendar from './Calendar'

import { BoxContainer } from './Styled'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { fetchDataStation } from './events'
import { setInitialMore } from '../../redux/slices/table'
import { MANAGE_CLASS_ROW_PAGE_PER_LIST } from '../../constants/table'

const Index = () => {
  const dispatch = useDispatch()
  const defaultMenu = useSelector(
    (state) => state.manageClass.defaultMenu,
    shallowEqual,
  )

  useEffect(() => {
    dispatch(
      setInitialMore({
        defaultRowsPerPage: 10,
        limit: '10',
        rowsPerPage: MANAGE_CLASS_ROW_PAGE_PER_LIST[0],
      }),
    )
    dispatch(fetchDataStation())
  }, [])

  return (
    <BoxContainer>
      <Header />
      {defaultMenu === 'list' && <List />}
      {defaultMenu === 'calendar' && <Calendar />}
    </BoxContainer>
  )
}
export default Index
