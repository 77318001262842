import _ from 'lodash'
import { setFilter } from '../../../../../../../../redux/slices/eLearning/learningPathPreview'
import { convertFormatDateTime } from '../../../../../../../../utils/lib'

export const handleCheckboxChange = (e, filterBody) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const isChecked = _.get(e.target, 'checked', false)
  const value = _.get(e.target, 'value', '')
  let filterList = [...filterBody]

  if (isChecked) filterList.push(value)
  else filterList = filterList.filter((item) => item != value)

  const payload = { key: name, value: filterList }
  dispatch(setFilter(payload))
}

export const handleChangePeriod = (selectedDates) => (dispatch) => {
  const startDate = convertFormatDateTime({
    value: _.get(selectedDates, 'startDate', ''),
    type: 'dateDb',
  })
  const finishDate = convertFormatDateTime({
    value: _.get(selectedDates, 'endDate', ''),
    type: 'dateDb',
  })
  const payloadStart = { key: 'startDate', value: startDate }
  const payloadFinish = { key: 'finishDate', value: finishDate }
  dispatch(setFilter(payloadStart))
  dispatch(setFilter(payloadFinish))
}

export const handleResetPeriod = () => (dispatch) => {
  dispatch(setFilter({ key: 'startDate', value: '' }))
  dispatch(setFilter({ key: 'finishDate', value: '' }))
}
