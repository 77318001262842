import _ from 'lodash'
import { getLearnerAttend } from '../../../../../../services/eLearning/course/learner'
import { setAttendLoading } from '../../../../../../redux/slices/eLearning/courseBatchLearnerDetail'
import { isOic } from '../../events'

export const fetchAttend = (props) => async (dispatch) => {
  const { limit, page, uuid, expandedRound } = props
  const learnerUuid = isOic() ? uuid : expandedRound
  if (_.isEmpty(learnerUuid)) return

  const realPage = page <= 0 ? 1 : +page
  const url = `?limit=${limit}&page=${realPage}&uuid=${learnerUuid}`
  dispatch(setAttendLoading(true))
  await dispatch(getLearnerAttend(url))
  dispatch(setAttendLoading(false))
}
