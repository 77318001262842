import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { StyledBox } from '../Styled'
import { getViewUrl, getViewUrlETest } from './events'
import { setOnView } from '../../../../../redux/slices/table2'
import _ from 'lodash'

const ActionViewUserProfile = ({ row, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isETest = _.get(condition, 'isETest', undefined)
  return (
    <StyledBox minWidth={0}>
      <>
        <IconButton
          color="primary"
          component="span"
          onClick={() => {
            let viewUrl = getViewUrl(row)
            if (isETest) {
              viewUrl = getViewUrlETest(row)
            }
            dispatch(setOnView({ history, viewUrl }))
          }}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      </>
    </StyledBox>
  )
}

export default ActionViewUserProfile
