export const breadcrumbList = [
  { title: 'Inventory', link: '/', pointer: false },
  { title: 'คลังทรัพย์สิน', link: '/', pointer: true },
]

export const breadcrumbFormList = (props) => {
  const { uuid } = props
  const title = uuid ? 'แก้ไข' : 'สร้าง'
  return [
    { title: 'Inventory', link: '/', pointer: true },
    { title: 'คลังทรัพย์สิน', link: '/inventory/stock', pointer: true },
    {
      title: (title ?? 'สร้าง')+' คลังทรัพย์สิน',
      link: '/inventory/stock/form',
      pointer: false,
    },
  ]
}

export const breadcrumbDetail = [
  { title: 'Inventory', link: '/inventory/stock', pointer: true },
  { title: 'คลังทรัพย์สิน', link: `/inventory/stock`, pointer: true },
  {
    title: 'รายละเอียด คลังทรัพย์สิน',
    link: '/inventory/stock',
    pointer: false,
  },
]
