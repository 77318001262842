import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import TabContainer from './TabContainer'
import { handleRedirect } from './events'
import {
  StyledCourseContainer,
  StyledGrayBg,
  StyledHeaderBody,
  StyledHeaderText,
} from './Styled'

const Course = ({ coursesTitle, courses }) => {
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <StyledCourseContainer>
      <StyledGrayBg src="/image/background_grey.svg" />
      <StyledHeaderText>
        <Typography
          id="title"
          variant={isLg ? 'h3' : 'h5'}
          color="text.primary"
        >
          {_.get(coursesTitle, 'title', '')}
        </Typography>
        <StyledHeaderBody
          variant={isLg ? 'body1' : 'teacherSub'}
          color="text.secondary"
        >
          {_.get(coursesTitle, 'description', '')}
        </StyledHeaderBody>
      </StyledHeaderText>

      <TabContainer tabs={_.get(courses, '[0].taps', [])} />

      {coursesTitle.statusButton && (
        <Button
          data-testid="btn-course"
          onClick={() => handleRedirect(_.get(coursesTitle, 'URL', ''))}
          variant="outlined"
          size={isLg ? 'l' : 'm'}
        >
          {_.get(coursesTitle, 'button', '')}
        </Button>
      )}
    </StyledCourseContainer>
  )
}

export default Course
