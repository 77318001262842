import _ from 'lodash'
import { fetchArea } from '../api/Index'
import { checkPermissionHZ } from './checkPermissionHZ'

export const fetchDepartmentList = async () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const defaultStation = _.find(_.get(user, 'area', []), (a) => a.default)
  const departmentUuid = _.get(defaultStation, 'department.uuid')

  const isPermissionHZ = checkPermissionHZ(user)

  let area = await fetchArea(user)
  if (isPermissionHZ) {
    area = _.filter(area, (ar) => ar.uuid === departmentUuid)
  }

  return _.map(area, (item) => ({
    label: item.label,
    value: item.uuid,
  }))
}
