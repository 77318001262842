import _ from 'lodash'
import { ratingChoices } from './../../../../../../../constants/eEvaluation'

export const handleSelector = (state, keyProps) => {
  const { keyField, keyAssessment, keySub } = keyProps
  const assessment = state.eEvaluationPreview[keyField].find(
    (item) => item.key == keyAssessment
  )
  
  if (!keySub) {
    return {
      choices: _.get(assessment, 'choices', ratingChoices),
      isSuggestion: _.get(assessment, 'isSuggestion', false),
    }
  }
  
  const choice = assessment.choices.find(
    (item) => item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})
  return {
    choices: _.get(subQuestion, 'choices', ratingChoices),
    isSuggestion: _.get(subQuestion, 'isSuggestion', false),
  }
}
