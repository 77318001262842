import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setFieldValue } from '../../../redux/slices/crud'
import { useInputField } from '../hooks/useInputField'
import { enumStatus } from '../enum/status'
import SwitchInput from '../../Input/SwitchInput'

const CustomStatusSwitch = ({
  fieldName,
  labelName,
  labelText = 'สถานะ',
  disabled,
  required,
  boxSx,
  handleExtraChange,
}) => {
  const dispatch = useDispatch()
  const { value } = useInputField(fieldName)
  const { crudConfig } = useSelector((state) => state.crud, shallowEqual)

  const setStatus = (checked) =>
    checked ? enumStatus.ACTIVE : enumStatus.INACTIVE

  function handleOnChange(e) {
    dispatch(
      setFieldValue({
        key: `formData.${fieldName}`,
        value: setStatus(e.target.checked),
      }),
    )
  }

  function isStatusActive() {
    return value === enumStatus.ACTIVE
  }

  return (
    <SwitchInput
      id={`${crudConfig.moduleSlug}-${fieldName}`}
      name={fieldName}
      disabled={disabled}
      labelText={labelText}
      value={isStatusActive()}
      onChange={(e) => {
        handleOnChange(e)
        handleExtraChange && handleExtraChange(e)
      }}
      required={required}
      inputText={labelName}
      boxSx={boxSx}
    />
  )
}

export default CustomStatusSwitch
