import React from 'react'
import { openDialog } from '../../../../../redux/slices/dialog'
import DialogContent from './DialogContent'

export const onMaps = (title, img, map) => (dispatch) => {
  return dispatch(
    openDialog({
      isClose: true,
      maxWidth: '860',
      type: 'mini',
      content: <DialogContent title={title} img={img} map={map} />,
    })
  )
}

export const onPhoneCall = (number, setOpen) => {
  if (/Android|webOS|iPhone|iPad/i.test(navigator.userAgent)) {
    window?.open(`tel:${number}`)
  } else {
    setOpen(true)
    navigator?.clipboard?.writeText(number)
  }
}
