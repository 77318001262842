import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const StyledInputImport = styled('input')({ display: 'none' })

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}))

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  p: { span: { color: theme?.palette?.primary?.main } },
}))
