import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledSelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 0,
    '& > .MuiBox-root': {
      width: '100%',
    },
  },
}))
