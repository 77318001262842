import React from 'react'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'

import { setChangeSetting } from '../../../../redux/slices/eTestingForm'

import * as Styled from './Styled'

export const BoxSwitch = ({
  labelText,
  labelSwitch,
  value,
  handleChange,
  isBottom,
  disabled,
}) => {
  return (
    <Styled.BoxForm isGap isBottom={isBottom}>
      {labelText && <Typography variant="h6">{labelText}</Typography>}
      <Styled.BoxFlex isSwitch paddingLeft={3}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{labelSwitch}</Typography>
        </Box>
        <Switch
          disabled={disabled}
          data-testid="switch-setting"
          checked={value}
          onChange={handleChange}
        />
      </Styled.BoxFlex>
    </Styled.BoxForm>
  )
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeSetting(props))
}

export const handleChangeNumber = (props) => (dispatch) => {
  let newValue = props.value.replace(/[^\d]/g, '')
  if (newValue > props.max) newValue = props.max
  if (newValue === '0') newValue = 1
  dispatch(
    setChangeSetting({
      ...props,
      value: newValue > 0 ? Number(newValue) : newValue,
    }),
  )
}

export const handleBlurNumber = (props) => (dispatch) => {
  if (props.value !== '') return
  dispatch(
    setChangeSetting({
      ...props,
      value: 1,
    }),
  )
}
