import _ from 'lodash'
import { COURSE_TYPE } from '../../../../../../../constants/eLearning'
import * as yup from 'yup'

export const schema = yup.object().shape({
  courseType: yup.string().nullable(),
  speaker: yup
    .array()
    .test('required', 'กรุณาเลือกวิทยการ', (value, { parent }) => {
      if (parent.courseType === COURSE_TYPE.GENERAL) return true
      return !_.isEmpty(value)
    }),
  admin: yup
    .array()
    .test('required', 'กรุณาเลือก Admin', (value, { parent }) => {
      if (parent.courseType === COURSE_TYPE.GENERAL) return true
      return !_.isEmpty(value)
    }),
})
