import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledDialog,
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledRespRow,
} from '../../Styled'
import TextInput from '../../../../components/Input/TextInput'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleSubmitAnnounce, handleCloseDialog } from './event'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import { useParams } from 'react-router-dom'
import { setAnnouncementForm } from '../../../../redux/slices/eExamination/list'
import _ from 'lodash'
import { useFormik } from 'formik'
import { anounceExaminerValidationSchema } from '../../../../constants/eExamination'

const AnnounceExaminerDialog = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const {
    selected,
    rows,
    isLoading,
    scheduleName,
    annotation,
    announce,
    isEnableScheduleName,
    isEnableAnounce,
    announcement,
    scheduleNameForm,
    annotationForm,
  } = useSelector(
    (state) => ({
      selected: state.table.table.selected,
      rows: state.table.table.rows,
      isLoading: state.dialog.isLoading,
      scheduleName: state.eExamList.announcement.scheduleName,
      annotation: state.eExamList.announcement.annotation,
      announce: state.eExamList.announcement.announce,
      isEnableScheduleName: state.eExamList.announcement.isEnableScheduleName,
      isEnableAnounce: state.eExamList.announcement.isEnableAnounce,
      announcement: state.eExamList.announcement,
      scheduleNameForm: state.eExamList.announcement.scheduleNameForm,
      annotationForm: state.eExamList.announcement.annotationForm,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(
      setAnnouncementForm({
        scheduleNameForm: scheduleName,
        annotationForm: annotation,
      }),
    )

    formik.setFieldValue('scheduleName', _.defaultTo(scheduleName, ''))
    formik.setFieldValue('annotation', _.defaultTo(annotation, ''))
  }, [])

  const formik = useFormik({
    initialValues: {
      scheduleName: '',
      annotation: '',
    },
    validationSchema: anounceExaminerValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      dispatch(
        handleSubmitAnnounce(
          uuid,
          selected,
          rows,
          scheduleNameForm,
          annotationForm,
        ),
      )
    },
  })

  return (
    <StyledDialog sx={{ pb: 0 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h6">ประกาศผู้มีสิทธิ์สอบ</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">
            ระบบจะทำการประกาศรายชื่อผู้มีสิิทธิ์สอบรอบที่{' '}
            <span>{announce + 1}</span> ที่หน้าเว็บไซต์
            พร้อมส่งอีเมลยืนยันแจ้งให้กับผู้สอบที่มีสิทธิ์ทราบ
            ต้องการดำเนินการเลยหรือไม่
          </Typography>
        </Box>

        <StyledColumnFlex>
          <StyledColumnNoGap noGap>
            <StyledRespRow isInput>
              <TextInput
                id="scheduleName"
                name="scheduleName"
                type="basic"
                labelText="สถานที่สอบ"
                multiline={true}
                rows={3}
                disabled={!isEnableScheduleName}
                value={_.defaultTo(scheduleNameForm, '')}
                onChange={(e) => {
                  formik.setFieldValue('scheduleName', e.target.value)
                  dispatch(
                    setAnnouncementForm({
                      ...announcement,
                      scheduleNameForm: e.target.value,
                    }),
                  )
                }}
                textError={formik?.errors?.scheduleName}
                onBlur={formik.handleBlur}
              />
            </StyledRespRow>
          </StyledColumnNoGap>
        </StyledColumnFlex>

        <StyledColumnFlex>
          <StyledColumnNoGap noGap>
            <StyledRespRow isInput>
              <TextInput
                id="annotation"
                name="annotation"
                type="basic"
                labelText="รายละเอียด"
                multiline={true}
                rows={3}
                value={_.defaultTo(annotationForm, '')}
                onChange={(e) => {
                  formik.setFieldValue('annotation', e.target.value)
                  dispatch(
                    setAnnouncementForm({
                      ...announcement,
                      annotationForm: e.target.value,
                    }),
                  )
                }}
                textError={formik?.errors?.annotation}
                onBlur={formik.handleBlur}
              />
            </StyledRespRow>
          </StyledColumnNoGap>
        </StyledColumnFlex>

        <StyledDialogActions sx={{ px: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => dispatch(handleCloseDialog())}
              variant="outlined"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            type="submit"
            color="primary"
            endIcon=""
            disabled={!isEnableAnounce}
          >
            ประกาศ
          </StyledLoadingButton>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  )
}

export default AnnounceExaminerDialog
