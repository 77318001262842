import {
  setChangeQuestionByKey,
  setChangeSubQuestionByKey,
} from '../../../../../../../../redux/slices/eEvaluationForm'

export const listNumberOfAnswers = (listAnswer) => {
  if (!listAnswer.length) return []
  return listAnswer.map((item) => {
    return {
      label: item.sequence,
      value: item.sequence,
    }
  })
}

export const handleChange = (props) => (dispatch) => {
  const { isSubQuestions } = props
  if (!isSubQuestions) {
    dispatch(
      setChangeQuestionByKey({
        ...props,
        type: 'questions',
      })
    )
  } else {
    dispatch(setChangeSubQuestionByKey(props))
  }
}
