import _ from 'lodash'
import {
  setFetchData,
  setStationList,
  setStationName,
} from '../../../redux/slices/roomManagement/Dashboard'
import { postRoomManagementDashboard } from '../../../services/roomManagement'
import { getStationFilterDefault } from '../../../services/masterData/meetingRoom'
import { store } from '../../../App'

export const handleFetchStation = () => async (dispatch) => {
  const response = await dispatch(getStationFilterDefault())

  const stationList = _.get(response, 'payload', []).map((item) => ({
    label: item.station,
    name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
    value: item.uuid,
  }))

  dispatch(setStationList(stationList))
}

export const fetchDataRoomManagement = () => async (dispatch) => {
  const { stationUuid, year, month } = store.getState().roomManagement
  if (_.isNull(year) || _.isNull(month)) return

  const body = {
    stationUuid: stationUuid,
    monthYear: `${year}-${month}-01`,
    limit: 20,
    page: 1,
  }
  const response = await dispatch(postRoomManagementDashboard(body))
  const payload = _.get(response, 'payload', {})
  const data = { ...payload, stationUuid: stationUuid }
  dispatch(setFetchData(data))
}

export const handleStationName = () => (dispatch) => {
  const { stationList, stationUuid } = store.getState().roomManagement
  const stationName = _.chain(stationList)
    .find({ value: stationUuid })
    .get('label', '')
    .value()
  dispatch(setStationName(stationName))
}
