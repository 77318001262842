import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { getBudgetManagementById } from '../../../../services/budgetManagement'

export const fetchDetails = async (page, uuid) => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))

  const results = await store
    .dispatch(getBudgetManagementById({ page, uuid }))
    .then((response) => {
      const data = _.get(response, 'payload.data', {})
      const yearBudget = _.get(data, 'yearBudget', new Date().getFullYear())
      const oldAccountCode = _.get(data, 'accountCode.oldAccountCode', '')
      const newAccountCode = _.get(data, 'accountCode.newAccountCode', '')
      const accountCodeLabel = `${oldAccountCode} / ${newAccountCode}`

      return {
        ...data,
        yearBudget: { label: yearBudget.toString(), value: yearBudget },
        costCenter: {
          ...data.costCenter,
          value: _.get(data, 'costCenter.uuid', ''),
          label: _.get(data, 'costCenter.costCenter', ''),
        },
        accountCode: {
          ...data.accountCode,
          value: data.accountCode.uuid,
          label: accountCodeLabel,
        },
        costCenterId: _.get(data, 'costCenter.costCenter', '-'),
        costCenterName: _.get(data, 'costCenter.descriptionName', '-'),
        accountCodeLabel: accountCodeLabel,
      }
    })

  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: results,
    }),
  )

  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
