import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { StyledRow, StyledColumn } from '../Styled'
import TextInput from '../../../../../components/Input/TextInput'
import { handleChangeNumberInput } from '../event'
import {
  handleNumberInput,
  isMediaWidthMoreThan,
} from '../../../../../utils/lib'
import { formattedNumberToString } from '../../event'

const CapacityList = (props) => {
  const dispatch = useDispatch()
  const { keyField, type, required, value } = props
  const { errors } = useFormikContext()
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <StyledRow sx={{ alignItems: 'center' }}>
      <Typography minWidth={isNotSm ? 120 : 50} height={50} variant="body1">
        {type}
      </Typography>
      <StyledRow sx={{ alignItems: 'center' }}>
        <TextInput
          type="basic"
          name={keyField}
          placeholder="กรุณากรอก"
          value={formattedNumberToString(value) || value}
          inputProps={{ type: 'text', onInput: (e) => handleNumberInput(e) }}
          onChange={(e) => {
            dispatch(handleChangeNumberInput(e, keyField))
          }}
          sxTextError={!isNotSm ? { height: '52px' } : {}}
          textError={_.get(errors, `information.${keyField}`, '')}
        />
        <StyledRow sx={{ gap: 0 }}>
          <Typography variant="body2" color="text.secondary" height={50}>
            ความจุ (คน)
          </Typography>
          {required ? (
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
              *
            </Typography>
          ) : (
            <></>
          )}
        </StyledRow>
      </StyledRow>
    </StyledRow>
  )
}

const Index = () => {
  const { capacityClassroom, capacityGroup, capacityUShape, capacityTheater } =
    useSelector(
      (state) => state.masterDataMeetingRoomForm.information,
      shallowEqual,
    )

  const typeMeetingRoom = [
    {
      keyField: 'capacityClassroom',
      type: 'Classroom',
      required: true,
      value: !_.isNull(capacityClassroom) ? capacityClassroom : '',
    },
    {
      keyField: 'capacityGroup',
      type: 'Group',
      required: false,
      value: !_.isNull(capacityGroup) ? capacityGroup : '',
    },
    {
      keyField: 'capacityUShape',
      type: 'U Shape',
      required: false,
      value: !_.isNull(capacityUShape) ? capacityUShape : '',
    },
    {
      keyField: 'capacityTheater',
      type: 'Theater',
      required: false,
      value: !_.isNull(capacityTheater) ? capacityTheater : '',
    },
  ]

  return (
    <StyledColumn>
      <StyledRow sx={{ gap: 0 }}>
        <Typography variant="body2" color={'text.secondary'}>
          รูปแบบการจัดห้อง
        </Typography>
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </StyledRow>
      {typeMeetingRoom.map((type, idx) => {
        return <CapacityList key={idx} {...type} />
      })}
    </StyledColumn>
  )
}

export default Index
