import { startLoading, stopLoading } from '../../../redux/slices/table'
import { CRUDListDownloadPath } from '../../../utils/apiPath'
import { downloadFile } from '../../../services/util'
import { store } from '../../../App'

export const onDownload =
  (selected, sort, order, downloadName = '') =>
  async (dispatch) => {
    const { crudConfig } = store.getState().crud
    dispatch(startLoading())
    const body = {
      order: order.toUpperCase(),
      sort: sort,
      list: selected ?? [],
    }
    if (downloadName === '') downloadName = crudConfig.moduleTitleFull
    await dispatch(
      downloadFile({
        url: CRUDListDownloadPath,
        body: body,
        fileName: `รายการ${downloadName}.xlsx`,
        headers: { 'x-type': crudConfig.moduleId },
      }),
    )
    dispatch(stopLoading())
  }
