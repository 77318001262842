import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const CheckboxFilterRequestType = () => {
  const dispatch = useDispatch()
  const { isFilterBookingType } = useSelector(
    (state) => ({
      isFilterBookingType:
        state.roomManagement.drawerCalendarFilter.isFilterBookingType,
    }),
    shallowEqual,
  )
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isFilterBookingType}
          onChange={(e) => {
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'isFilterBookingType',
                value: e.target.checked,
              }),
            )
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'filterBookingType',
                value: [],
              }),
            )
          }}
        />
      }
      label="ประเภท"
    />
  )
}
