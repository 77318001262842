import { downloadFile } from '../../../../../services/util'
import { store } from '../../../../../App'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/reportComp'
import { reportDownload } from '../../../../../utils/apiPath'
import dayjs from 'dayjs'

export const columns = [
  {
    id: 1,
    name: 'no',
    value: 'no',
    label: 'ลำดับ',
    minWidth: 60,
    align: 'center',
  },
  {
    id: 2,
    name: 'courseCode',
    value: 'courseCode',
    label: 'รหัสหลักสูตร',
    minWidth: 160,
  },
  {
    id: 3,
    name: 'courseNameEN',
    value: 'courseNameEN',
    label: 'ชื่อหลักสูตร EN',
    minWidth: 160,
  },
  {
    id: 4,
    name: 'courseNameTH',
    value: 'courseNameTH',
    label: 'ชื่อหลักสูตร TH',
    minWidth: 160,
  },
  {
    id: 5,
    name: 'testId',
    value: 'testId',
    label: 'รหัสแบบทดสอบ',
    minWidth: 160,
  },
  {
    id: 6,
    name: 'testName',
    value: 'testName',
    label: 'ชื่อแบบทดสอบ',
    minWidth: 160,
  },
  {
    id: 34,
    name: 'typeOfETesting',
    value: 'typeOfETesting',
    label: 'ประเภทแบบทดสอบ',
    minWidth: 160,
  },
  {
    id: 23,
    name: 'courseVersion',
    value: 'courseVersion',
    label: 'เวอร์ชัน',
    minWidth: 160,
  },
  {
    id: 32,
    name: 'startTestDateTime',
    value: 'startTestDateTime',
    label: 'วันเวลาที่เริ่มทำ',
    minWidth: 160,
  },
  {
    id: 33,
    name: 'endTestDateTime',
    value: 'endTestDateTime',
    label: 'วันเวลาที่ทำเสร็จ',
    minWidth: 160,
  },
  {
    id: 8,
    name: 'startClassDateTime',
    value: 'startClassDateTime',
    label: 'วันเริ่มต้น (Training Date)',
    minWidth: 160,
  },
  {
    id: 30,
    name: 'endClassDateTime',
    value: 'endClassDateTime',
    label: 'วันสิ้นสุด (Training Date)',
    minWidth: 160,
  },
  {
    id: 9,
    name: 'station',
    value: 'station',
    label: 'Station',
    minWidth: 160,
  },
  {
    id: 10,
    name: 'trainerId',
    value: 'trainerId',
    label: 'Trainer ID',
    minWidth: 160,
  },
  {
    id: 11,
    name: 'trainerName',
    value: 'trainerName',
    label: 'Trainer Name - LastName',
    minWidth: 160,
  },
  {
    id: 12,
    name: 'countQuestion',
    value: 'countQuestion',
    label: 'จำนวนคำถาม',
    minWidth: 160,
  },
  {
    id: 14,
    name: 'testTime',
    value: 'testTime',
    label: 'Time',
    minWidth: 160,
  },
  {
    id: 15,
    name: 'testScore',
    value: 'testScore',
    label: 'คะแนนที่ได้รับ',
    minWidth: 160,
  },
  {
    id: 16,
    name: 'prefixTH',
    value: 'prefixTH',
    label: 'TitleName',
    minWidth: 160,
  },
  {
    id: 17,
    name: 'firstNameTH',
    value: 'firstNameTH',
    label: 'FirstName',
    minWidth: 160,
  },
  {
    id: 18,
    name: 'lastNameTH',
    value: 'lastNameTH',
    label: 'LastName',
    minWidth: 160,
  },
  {
    id: 19,
    name: 'agentCode',
    value: 'agentCode',
    label: 'รหัสตัวแทน 6 หลัก',
    minWidth: 160,
  },
  {
    id: 20,
    name: 'fullAgentCode',
    value: 'fullAgentCode',
    label: 'Full Agent code',
    minWidth: 160,
  },
  {
    id: 21,
    name: 'ramCode',
    value: 'ramCode',
    label: 'RAM',
    minWidth: 160,
  },
  {
    id: 22,
    name: 'sRam',
    value: 'sRam',
    label: 'SRam',
    minWidth: 160,
  },
  {
    id: 24,
    name: 'distribution',
    value: 'distribution',
    label: 'ช่องทางการจำหน่าย',
    minWidth: 160,
  },
  {
    id: 25,
    name: 'unitCode',
    value: 'unitCode',
    label: 'Unit Code',
    minWidth: 160,
  },
  {
    id: 26,
    name: 'unitName',
    value: 'unitName',
    label: 'สังกัดหน่วย',
    minWidth: 160,
  },
  {
    id: 7,
    name: 'testType',
    value: 'testType',
    label: 'รูปแบบการทดสอบ',
    minWidth: 160,
  },
]

export const onDownloadReport = async (selected) => {
  store.dispatch(startLoading())
  const { eTestName } = store.getState().crud

  const body = {
    list: selected,
    columns,
    filters: [
      {
        id: 1,
        key: 'testName',
        title: 'ชื่อแบบทดสอบ',
        typeOfValue: 'DROPDOWN_MULTI',
        value: [eTestName],
        label: eTestName,
      },
    ],
    hasSortedColumns: false,
    moduleId: 'REPORT_E_TESTING_RAW_DATA_BY_LEARNER',
  }
  const formattedDate = dayjs(new Date()).utc().format('DDMMYYYY')
  const downloadName = `Report Dashboard E-Testing ${formattedDate}.xlsx`
  await store.dispatch(
    downloadFile({
      url: `${reportDownload}/e-testing/report-raw-data-by-learner`,
      body: body,
      fileName: downloadName,
    }),
  )
  store.dispatch(stopLoading())
}
