import { StyledRow } from '../../../Styled'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import Divider from '@mui/material/Divider'

export const StyledContainerCourse = styled(StyledRow)(({ theme }) => ({
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: { width: '200%', justifyContent: 'center' },
}))

export const StyledCourseCard = styled(Box)(({ theme }) => ({
  width: 'calc(49% - 7px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'end',
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { width: 'calc(50% - 8px)' },
}))

export const StyledTabHeader = styled(StyledRow)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: 50,
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.text?.mercury,
  borderStyle: 'dashed',
  borderWidth: '1px',
}))

export const StyledInputHidden = styled('input')({
  display: 'none',
  width: '18%',
})

export const StyledImagePreview = styled(Box)(() => ({
  maxWidth: '15%',
  height: 150,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
}))

export const StyledButton = styled('Button')(() => ({
  maxWidth: '15%',
  height: 150,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
}))

export const ButtonColorPreview = styled(Box)(({ value }) => ({
  width: 32,
  height: 24,
  backgroundColor: value,
  border: 1,
  borderRadius: 1,
  borderColor: 'grey.500',
}))
