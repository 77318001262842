import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import TextInput from '../../../../components/Input/TextInput'
import { closeDialog } from '../../../../redux/slices/dialog'
import {
  handleEnableButton,
  handleRadioChange,
  handleSubmitAdd,
  handleSuccess,
} from './events'
import { StyledDialog, StyledLoading, StyledSwitchButton } from './Styled'
import ManualForm from './ManualForm'
import UploadForm from './UploadForm'
import _ from 'lodash'
import { setRemark } from '../../../../redux/slices/manageClassLearner'

export const AddLearnerDialog = () => {
  const manageClassDetail = useSelector(
    (state) => state.manageClassDetail.data,
    shallowEqual
  )
  const status = _.get(manageClassDetail, 'status', '')
  const [prerequisite, setPrerequisite] = useState(false)
  const { id: uuid } = useParams()
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const {
    isLoadingContent,
    formType,
    userType,
    userProfile,
    staffProfile,
    file,
    isSuccess,
    remark,
    processAgent,
    success,
    total,
  } = useSelector(
    (state) => ({
      isLoadingContent: state.manageClassLearner.isLoading,
      formType: state.manageClassLearner.formType,
      userType: state.manageClassLearner.userType,
      userProfile: state.manageClassLearner.userProfile,
      staffProfile: state.manageClassLearner.staffProfile,
      file: state.manageClassLearner.file,
      isSuccess: state.manageClassLearner.isSuccess,
      remark: state.manageClassLearner.remark,
      processAgent: state.manageClassLearner.processAgent,
      success: state.manageClassLearner.success,
      total: state.manageClassLearner.total,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const props = {
    formType,
    userType,
    userProfile,
    staffProfile,
    remark,
    status,
    file,
    prerequisite,
  }
  const submitProps = {
    uuid,
    userProfile,
    staffProfile,
    remark,
    formType,
    userType,
    file,
    prerequisite,
  }

  useEffect(() => {
    dispatch(handleEnableButton(props))
  }, [formType, userType, userProfile, staffProfile, file, remark])

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">เพิ่มผู้เรียน</Typography>
      </Box>
      <StyledDialog>
        <Box>
          <Typography variant="body2" color="text.secondary">
            เพิ่มผู้เรียน <span>*</span>
          </Typography>
          <RadioGroup
            sx={{ pl: 1.5, gap: 0.5 }}
            aria-label="setFormType"
            name="setFormType"
            value={formType}
            onChange={(e) => dispatch(handleRadioChange(e))}
          >
            <FormControlLabel
              value="manual"
              disabled={processAgent}
              control={<Radio />}
              label="เพิ่มทีละคน"
            />
            <FormControlLabel
              value="import"
              disabled={processAgent}
              control={
                <Radio inputProps={{ 'data-testid': 'radio-setFormType' }} />
              }
              label="Upload รายชื่อ"
            />
          </RadioGroup>
        </Box>
        {formType === 'manual' ? <ManualForm /> : <UploadForm />}
        {['STARTCLASS', 'ENDCLASS', 'CLOSED'].includes(status) && (
          <TextInput
            required
            id="reason"
            isShowTextError={false}
            type="basic"
            labelText="เหตุผลในการเพิ่ม"
            disabled={processAgent}
            onChange={(e) => dispatch(setRemark(e.target.value))}
          />
        )}
        <StyledSwitchButton>
          <Switch
            data-testid="switch-status"
            disabled={processAgent}
            checked={prerequisite}
            onChange={(e) => setPrerequisite(_.get(e, 'target.checked', ''))}
          />
          <Box>
            <Typography>
              ไม่ต้องตรวจสอบเงื่อนไขการเข้าอบรม สำหรับรายชื่อที่เพิ่มในครั้งนี้
            </Typography>
          </Box>
        </StyledSwitchButton>
        {processAgent && (
          <Box sx={{ mt: 3 }}>
            <LinearProgress
              variant="determinate"
              value={(success / total) * 100}
            />
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography>
                กำลังตรวจสอบและอัปเดตข้อมูล Agent ({userType})
              </Typography>
              <Typography
                sx={{ ml: 1.5, fontWeight: 'bold', color: 'primary.main' }}
              >
                {success}/{total}
              </Typography>
            </Box>
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(closeDialog())}
            color="primary"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          loading={isLoading}
          onClick={() => dispatch(handleSubmitAdd(submitProps))}
          color="primary"
          disabled={disableButton}
          endIcon={<></>}
        >
          บันทึก
        </StyledLoadingButton>
      </StyledDialogActions>
      <StyledLoading isLoading={isLoadingContent}>
        <CircularProgress />
      </StyledLoading>
    </>
  )
}

export default AddLearnerDialog
