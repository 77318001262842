import { initialCourse } from '../../../../../../redux/slices/eLearning/learningPathForm/model'
import { getStateValue, handleChange } from '../../events'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const handleDragEnd = (result, keyField, setErrors) => (dispatch) => {
  if (!result.destination) return

  const list = getStateValue(keyField, [])
  const reorderedList = reorder(
    [...list],
    result.source.index,
    result.destination.index
  )
  dispatch(handleChange(keyField, reorderedList))
  setErrors({})
}

export const handleAddItem = (keyField) => (dispatch) => {
  const list = getStateValue(keyField, [])
  const newList = [...list]
  const sequence = newList.length + 1
  newList.push({ ...initialCourse, id: `${sequence}`, sequence })
  dispatch(handleChange(keyField, newList))
}

export const handleDeleteItem = (index, keyField) => (dispatch) => {
  const list = getStateValue(keyField, [])
  let newList = [...list]
  newList.splice(index, 1)
  newList = newList.map((item, i) => {
    const sequence = i + 1
    return { ...item, id: `${sequence}`, sequence }
  })
  dispatch(handleChange(keyField, newList))
}
