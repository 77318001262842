import Box from '@mui/material/Box'
import {
  StyledViewCard,
  StyledViewWrapper,
} from '../../../../../modules/Styled'
import { UpdatedComponent } from '../../../../../components/CRUD/components/UpdatedComponent'

export const ViewUpdateAt = () => {
  return (
    <Box sx={{ mx: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <StyledViewWrapper>
        <StyledViewCard isUploadLoading={false}>
          <UpdatedComponent />
        </StyledViewCard>
      </StyledViewWrapper>
    </Box>
  )
}
