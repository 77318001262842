import { Box, List, ListItem, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'

const ActivityList = ({ title = '', count = 0, activityList }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.blue.blueLine}`,
        p: 2,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1b">{title}</Typography>
        <Box display="flex" alignItems="baseline" gap={1}>
          <Typography variant="h4">{count}</Typography>
          <Typography variant="body2" color="text.lightGray">
            ครั้ง
          </Typography>
        </Box>
      </Box>

      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {_.map(activityList, (act, idx) => {
          return (
            <ListItem
              key={idx}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#F1F4FA',
                borderRadius: 4,
                py: 0.5,
                ':first-of-type': {
                  background: 'none',
                  py: 0,
                  '& .MuiTypography-root': {
                    color: theme?.palette?.text?.lightGray,
                    fontWeight: theme.typography.tableHeader.fontWeight,
                    fontSize: theme.typography.tableHeader.fontSize,
                  },
                },
                ':last-of-type': {
                  backgroundColor: theme?.palette?.primary?.main,
                  '& .MuiTypography-root': {
                    color: theme?.palette?.text?.white,
                    fontWeight: theme.typography.body1b.fontWeight,
                    fontSize: theme.typography.body1b.fontSize,
                  },
                },
              }}
            >
              <Typography variant="body2" color="primary">
                {act.listName}
              </Typography>
              <Typography variant="body2b" color="primary">
                {act.count}
              </Typography>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default ActivityList
