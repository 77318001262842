import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ChevronRight } from '@mui/icons-material'
import { StyledButton } from '../Styled'
import TransactionCard from '../ModuleCard/TransactionCard'
import { transactionModel } from '../../models/overview'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import { handleClickOpenReport } from '../../handler/handleClickOpenReport'
// import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'

const index = () => {
  const { overviewTransaction } = useSelector(
    (state) => ({
      overviewTransaction: state.crud?.apiRcmsDashboard?.overview?.transactions,
    }),
    shallowEqual,
  )
  const transaction = transactionModel()
  const colorArray = _.defaultTo(transaction, []).map((item) => item.dotColor)
  const finalResult = [
    overviewTransaction?.manual?.transactions ?? 0,
    overviewTransaction?.admin?.transactions ?? 0,
    overviewTransaction?.auto?.transactions ?? 0,
    overviewTransaction?.failed?.transactions ?? 0,
  ]
  const totalModule = overviewTransaction?.totalTransactions
  const transactionData = [
    {
      label: 'Transactions',
      number: overviewTransaction?.totalTransactions ?? 0,
      unit: '',
      // percent: 'transactions %',
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '8px',
        marginRight: '8px',
      },
    },
    {
      label: 'Records',
      number: overviewTransaction?.totalRecords ?? 0,
      unit: '',
      // percent: 'Records %',
      sxBox: '',
    },
  ]
  return (
    <Box height={'100%'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">Transaction ทั้งหมด</Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              onClick={() => {handleClickOpenReport()}}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </Box>
          <Box>
            <TransactionCard
              label={''}
              labelVariant={'body2'}
              background={''}
              dotColor={''}
              border={'none'}
              // dataSx={{}}
              dataList={transactionData}
            />
          </Box>
          <Box display="flex" gap={2} mt={3} alignItems="center">
            <DoughnutChart
              dataSet={{
                data: finalResult,
                backgroundColor: colorArray,
              }}
              height={224}
              label="Transactions ทั้งหมด"
              value={totalModule}
              eleId={'scheduleDougnutChart'}
            />
            <Box display="flex" flexDirection="row" gap={1}>
              {transaction.map((item) => (
                <TransactionCard
                  key={item.value}
                  label={item.label}
                  labelVariant={item.labelVariant}
                  background={item.background}
                  dotColor={item.dotColor}
                  boxSx={{width: item.width}}
                  // dataSx={{}}
                  dataList={_.defaultTo(item.dataList, [])}
                  handleClickButton={() => {handleClickOpenReport(item.value)}}
                />
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default index
