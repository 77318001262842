import React from 'react'
import _ from 'lodash'
// import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import * as events from '../events'
import { BoxTable } from '../Styled'
import '../table.css'

const Index = ({ item }) => {
  const status = _.get(item, 'status', 'รอเอกสาร')
  const round = _.get(item, 'round', 0)
  return (
    <BoxTable>
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6">สถานะเอกสาร</Typography>
      </Box>
      <table id="table-document">
        <tr>
          <th id="title-document" className="th-border">
            <Typography variant="tableHeader">สถานะ</Typography>
          </th>
          <th id="title-document" className="th-border">
            <Typography
              variant="body2"
              color={events.mapColor(_.get(item, 'status', ''))}
            >
              {_.get(item, 'status', '')}
            </Typography>
          </th>
        </tr>
        <tr>
          <td className="td-border" id="title-document">
            <Typography variant="tableHeader">ส่งเอกสารครั้งที่</Typography>
          </td>
          <td className="td-border" id="title-document">
            <Typography variant="body2">{!round ? '-' : round}</Typography>
          </td>
        </tr>
        <tr>
          <td className="td-border" id="title-document">
            <Typography variant="tableHeader">วันที่ส่งเอกสาร</Typography>
          </td>
          <td className="td-border" id="title-document">
            <Typography variant="body2">
              {_.get(item, 'createdAt', '')}
            </Typography>
          </td>
        </tr>
        <tr>
          <td className="td-border" id="title-document">
            <Typography variant="tableHeader">วันที่ตรวจเอกสาร</Typography>
          </td>
          <td className="td-border" id="title-document">
            <Typography variant="body2">
              {_.get(item, 'checkedAt', '')}
            </Typography>
          </td>
        </tr>
        <tr>
          <td
            id="title-document"
            className={'td-border' + status === 'รอเอกสาร' ? 'last-item' : ''}
          >
            <Typography variant="tableHeader">ตรวจเอกสารโดย</Typography>
          </td>
          <td
            id="title-document"
            className={'td-border' + status === 'รอเอกสาร' ? 'last-item' : ''}
          >
            <Typography variant="body2">
              {_.get(item, 'checkedBy', '')}
            </Typography>
          </td>
        </tr>
        {status !== 'รอเอกสาร' && status !== 'ผ่านการตรวจสอบ' && (
          <tr>
            <td id="title-document" className="last-item td-border">
              <Typography variant="tableHeader">
                เหตุผลที่ไม่อนุมัติเอกสาร
              </Typography>
            </td>
            <td id="title-document" className="last-item td-border">
              <Typography variant="body2">
                {_.get(item, 'remark', '')}
              </Typography>
            </td>
          </tr>
        )}

        {status === 'ผ่านการตรวจสอบ' && (
          <>
            <tr>
              <td id="title-document" className="last-item td-border">
                <Typography variant="tableHeader">
                  เหตุผลที่ไม่อนุมัติเอกสาร
                </Typography>
              </td>
              <td id="title-document" className="last-item td-border">
                <Typography variant="body2">
                  {_.get(item, 'remark', '')}
                </Typography>
              </td>
            </tr>

            <tr>
              <td id="title-document" className="last-item ">
                <Typography variant="tableHeader">
                  เหตุผลที่ไม่มีสิทธิ์เรียน
                </Typography>
              </td>
              <td id="title-document" className="last-item ">
                <Typography variant="body2">
                  {_.get(item, 'remark', '')}
                </Typography>
              </td>
            </tr>
          </>
        )}
      </table>
    </BoxTable>
  )
}

export default Index
