import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { getBodyMutation } from '../mutation/get'
import { vendorType } from '../model/vendorType'
import { fetchStationList } from './fetchStationList'
import { fetchStaffProfileList } from './fetchStaffProfileList'

export const setInitalValues = async () => {
  startDialogLoading()
  store.dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
  const stationList = await fetchStationList()
  const staffProfileList = await fetchStaffProfileList()
  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {
        station: { isLoading: false, options: stationList },
        vendorType: { isLoading: false, options: vendorType },
        insideContact: { isLoading: false, options: staffProfileList },
      },
    }),
  )
  stopDialogLoading()
}

const startDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
}

const stopDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
