import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'

import Image from '../Image'
import TextInput from '../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import {
  handleIconUploadImage,
  IconUploadImage,
  getErrorMessage,
} from '../event'
import {
  handleChange,
  handleDelete,
  handleChangeScoreAnswer,
  handleNumberInput,
} from './event'
import { TooltipForm } from '../Styled'

const Index = ({
  indexQuestion,
  indexAnswer,
  answers,
  keyQuestion,
  questionType,
  disableForm,
}) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { title, mediaUrl, isCorrect, score } = answers[indexAnswer]

  const loadingProgress = _.get(answers[indexAnswer], 'loadingProgress', null)
  const propsEvent = { keyQuestion, indexAnswer, answers }
  const keyStateProps = { keyQuestion, key: `answers[${indexAnswer}].mediaUrl` }
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })

  return (
    <>
      <Styled.BoxAnswer type={questionType}>
        <Styled.ContentInput>
          {questionType === 'MULTIPLE' && (
            <Styled.BoxCheckbox
              color="primary"
              disabled={disableForm}
              checked={isCorrect}
              inputProps={{
                'aria-label': 'select all desserts',
                'data-testid': 'checkbox-answer',
              }}
              onChange={(e) =>
                dispatch(
                  handleChange({
                    ...propsEvent,
                    key: 'isCorrect',
                    value: _.get(e, 'target.checked', false),
                  })
                )
              }
            />
          )}

          {questionType === 'SINGLE' && (
            <Styled.RadioAnswer
              sx={{ mr: 1 }}
              disabled={disableForm}
              value={indexAnswer}
              control={<Radio />}
            />
          )}
          <TextInput
            id="title-answer"
            name="title"
            placeholder="ตัวเลือก"
            type="basic"
            boxSx={{ mt: 1 }}
            disabled={disableForm}
            value={title}
            onChange={(e) =>
              dispatch(
                handleChange({
                  ...propsEvent,
                  key: 'title',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            textError={answerErrorMessage}
            multiline
            maxRows={2}
          />
        </Styled.ContentInput>
        <Styled.ContentScoreAndButton type={questionType}>
          {questionType === 'MULTIPLE' && (
            <Styled.BoxScoreAnswer>
              <TextInput
                id="score"
                name="score"
                type="basic"
                boxSx={{ mt: 1 }}
                disabled={disableForm}
                endAdornmentText={'คะแนน'}
                value={score}
                inputProps={{ onInput: handleNumberInput }}
                onChange={(e) =>
                  dispatch(
                    handleChangeScoreAnswer({
                      ...propsEvent,
                      value: e.target.value,
                    })
                  )
                }
              />
            </Styled.BoxScoreAnswer>
          )}
          <Styled.BoxIcon type={questionType}>
            <IconUploadImage
              testId={`icon-answer-${keyQuestion}-${indexAnswer}`}
              disabled={disableForm}
              indexAnswer={indexAnswer}
              questionType={questionType}
              onUpload={(e) =>
                dispatch(
                  handleIconUploadImage({
                    data: e,
                    keyQuestion: propsEvent.keyQuestion,
                    keyValue: `answers[${indexAnswer}].mediaUrl`,
                    testId: `icon-answer-${keyQuestion}-${indexAnswer}`,
                  })
                )
              }
            />

            <TooltipForm
              placement="bottom"
              title={<Typography variant="tooltip">ลบ</Typography>}
            >
              <IconButton
                sx={{ mt: questionType === 'MULTIPLE' ? 1 : -2 }}
                onMouseLeave={() => setIsHover(false)}
                onMouseOver={() => setIsHover(true)}
                onClick={() =>
                  !disableForm && dispatch(handleDelete(propsEvent))
                }
                data-testid={`btn-delete-${indexAnswer}`}
              >
                <CloseOutlined color={isHover ? 'primary' : 'action'} />
              </IconButton>
            </TooltipForm>
          </Styled.BoxIcon>
        </Styled.ContentScoreAndButton>
      </Styled.BoxAnswer>
      {loadingProgress != null && (
        <Image
          isAnswer
          isMulti={questionType === 'MULTIPLE'}
          uploadState={answers[indexAnswer]}
          keyState={keyStateProps}
          value={mediaUrl}
        />
      )}
    </>
  )
}
export default Index
