import React, { useMemo } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { defaultValue } from './../../../../../constants/eTesting'
import Select from './../../../../../components/Input/Select'
import TextInput from './../../../../../components/Input/TextInput'
import Autocomplete from './../../../../../components/Input/Autocomplete'
import { handleSelectChange, handleSettingRadioChange } from '../events'
import {
  handleNumberInput,
  handleOtherInput,
  handlePercentInput,
} from './events'
import { StyledSelectContainer } from './Styled'

const ETestingForm = ({
  testAndEvaProps,
  setInitialValues,
  options,
  formik,
}) => {
  const { eTestingOption, eTestingTypeOption } = options
  const { testAndEvaArr, index } = testAndEvaProps
  const testAndEva = testAndEvaArr[index]
  const testCriteriaType = _.get(testAndEva, 'testCriteriaType', 'NOT_REQUIRED')
  const testAndEvaType = _.get(testAndEva, 'type', '')
  const isSetPassScore = useMemo(
    () => testCriteriaType == 'REQUIRED_PERCENT',
    [testAndEva.testCriteriaType]
  )
  const testAndEvaName = useMemo(() => {
    const uuid = testAndEva.testOrEvaluateUuid
    const selectedVal = eTestingOption.find((e) => e.value == uuid)
    return selectedVal || { label: '', value: '' }
  }, [eTestingOption, testAndEva.testOrEvaluateUuid])

  const filteredETestingOption = useMemo(() => {
    const type =
      testAndEvaType == 'Assessment'
        ? defaultValue.typeOfETesting.assessment
        : defaultValue.typeOfETesting.onlineTest
    return eTestingOption.filter((item) => item.type == type)
  }, [eTestingOption, testAndEvaType])

  return (
    <>
      <StyledSelectContainer>
        {!_.isEmpty(eTestingTypeOption) && (
          <Select
            boxSx={{ width: '50%' }}
            dataTestid="select-testAndEvaType"
            name="testAndEvaType"
            labelText="รูปแบบ"
            placeholder="กรุณาเลือก"
            type="basic"
            required
            options={eTestingTypeOption}
            value={testAndEvaType}
            textError={_.get(
              formik.errors,
              `testAndEvaluate[${index}].type`,
              ''
            )}
            handleChange={(e) =>
              handleSelectChange(
                e.target.value,
                'type',
                testAndEvaProps,
                setInitialValues
              )
            }
          />
        )}
        <Autocomplete
          boxSx={{ width: '50%' }}
          name="testAndEvaName"
          labelText="ชื่อ"
          options={filteredETestingOption}
          defaultValue={testAndEvaName}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              placeholder="กรุณาเลือก"
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'select-etest-name',
              }}
            />
          )}
          handleChange={(e) =>
            handleSelectChange(
              e.target.id,
              'testOrEvaluateUuid',
              testAndEvaProps,
              setInitialValues
            )
          }
          textError={_.get(
            formik.errors,
            `testAndEvaluate[${index}].testOrEvaluateUuid`,
            ''
          )}
          required
        />
      </StyledSelectContainer>
      {testAndEva.type == 'Other' && (
        <TextInput
          name="otherType"
          type="basic"
          required
          labelText="อื่นๆ"
          placeholder="กรุณากรอก"
          inputProps={{
            'data-testid': `input-other-${index}`,
          }}
          value={testAndEva.otherType}
          textError={_.get(
            formik.errors,
            `testAndEvaluate[${index}].otherType`,
            ''
          )}
          onChange={(e) =>
            handleOtherInput(e, testAndEvaProps, setInitialValues)
          }
        />
      )}
      <Box>
        <Typography variant="body2" color="text.secondary">
          เกณฑ์คะแนนผ่าน <span>*</span>
        </Typography>
        <RadioGroup
          data-testid="radio-testCriteriaType"
          sx={{ pl: 1.5, gap: 0.5 }}
          aria-label="testCriteriaType"
          name="testCriteriaType"
          value={testCriteriaType}
          onChange={(e) =>
            handleSettingRadioChange(e, testAndEvaProps, setInitialValues)
          }
        >
          <FormControlLabel
            value="NOT_REQUIRED"
            control={<Radio />}
            label="ไม่จำเป็น"
          />
          <FormControlLabel
            value="REQUIRED"
            control={<Radio />}
            label="ต้องทำ"
          />
          <FormControlLabel
            value="REQUIRED_PERCENT"
            control={<Radio />}
            label="ต้องทำ และผ่านตามเกณฑ์ (% ของคะแนนทั้งหมด)"
          />
          {isSetPassScore && (
            <TextInput
              boxSx={{ width: 258, ml: 3 }}
              id="input-criteria-percent"
              name="criteriaPercent"
              type="basic"
              placeholder="%"
              inputProps={{
                type: 'number',
                min: '0',
                max: '100',
                onInput: handleNumberInput,
              }}
              value={_.get(testAndEva, 'criteriaPercent', null)}
              textError={_.get(
                formik.errors,
                `testAndEvaluate[${index}].criteriaPercent`,
                ''
              )}
              onChange={(e) =>
                handlePercentInput(e, testAndEvaProps, setInitialValues)
              }
            />
          )}
        </RadioGroup>
      </Box>
    </>
  )
}

export default ETestingForm
