import { Box, CircularProgress, Drawer, InputBase } from '@mui/material'
import { styled } from '@mui/system'
import { StyledCard } from 'src/modules/Styled'

export const StyledSummaryCard = styled((props) => (
  <StyledCard
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      p: 2,
      mt: 0,
    }}
  />
))(() => {})

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 350,
  },
  height: '100vh',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(3),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(7)}`,
  },
}))

export const Loading = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const BoxSearch = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme?.palette?.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: 250,
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    maxHeight: 40,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: 40,
  width: 'calc(100% - 35px)',
  marginLeft: theme.spacing(1),
}))
