import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { checkScrollWidth } from './events'
import { StyledBoxButton, StyledBoxHeader, StyledBoxLabelTitle } from './Styled'

const Header = () => {
  const [isScrolled, setScroll] = useState(false)
  const data = useSelector(
    (state) => state.manageContentStation.data,
    shallowEqual
  )
  const { setValues } = useFormikContext()

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  return (
    <StyledBoxHeader scrolled={isScrolled}>
      <StyledBoxLabelTitle>
        <Typography variant="h4">Station</Typography>
      </StyledBoxLabelTitle>
      <StyledBoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          onClick={() => window.open('station/preview', '_blank')}
        >
          <RemoveRedEye sx={{ mr: 1 }} /> ดูตัวอย่าง
        </Button>
        <Button
          data-testid="btn-submit-form"
          type="submit"
          variant="contained"
          onClick={() => setValues(data)}
        >
          บันทึกและเผยแพร่
        </Button>
      </StyledBoxButton>
    </StyledBoxHeader>
  )
}
export default Header
