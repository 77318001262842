import React from 'react'
import { breadcrumb } from '../model/breadcrumb'
import { ListHeaderAction } from '../../../../../components/CRUD/components/Header/components/listHeaderAction'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { useHistory } from 'react-router-dom'
import { crudConfig } from '../../crudConfig'
import { TabsSelectType } from '../components/TabsSelectType'
import { Box } from '@mui/material'
import { handleOpenCreateDialog } from '../handler/handleOpenCreateDialog'

export const Header = () => {
  const history = useHistory()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box width="100%">
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadcrumb}
          action={
            <ListHeaderAction
              onClick={() => {
                handleOpenCreateDialog(history)
              }}
            />
          }
        />
      </Box>
      <TabsSelectType />
    </Box>
  )
}
