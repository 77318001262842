import React from 'react'
import { Box } from '@mui/system'
import { shallowEqual, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { handleDragEnd } from '../handler/handleDragEnd'
import { UploadItem } from './UploadItem'

const DragDropFile = ({
  onChange,
  fieldName,
  isForImage,
  isShowDownloadable,
  disabled,
  popupLabel,
  disabledRemove,
  uploadType,
}) => {
  const fileList = useSelector(
    (state) => state.uploadFile.formData[fieldName].files,
    shallowEqual,
  )

  return (
    <DragDropContext
      onDragEnd={(result) => handleDragEnd(result, onChange, fieldName)}
    >
      <Droppable droppableId="file-list">
        {(provided) => (
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            width="100%"
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
          >
            {fileList.map((item, index) => (
              <Draggable
                index={index}
                key={`file-${item.id}`}
                draggableId={`file-${item.id}`}
                isDragDisabled={!_.isEmpty(item.error) || disabled}
              >
                {(provided) => (
                  <UploadItem
                    isForImage={isForImage}
                    isShowDownloadable={isShowDownloadable}
                    onChange={onChange}
                    provided={provided}
                    key={index}
                    index={index}
                    fieldName={fieldName}
                    disabled={disabled}
                    disabledRemove={disabledRemove}
                    popupLabel={popupLabel}
                    uploadType={uploadType}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragDropFile
