import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  total: 0,
  eContent: {
    total: 0,
    active: 0,
    inactive: 0,
    agency: 0,
    banc: 0,
    ktb: 0,
    ktbother: 0,
    agencyPercent: 0,
    bancPercent: 0,
    ktbPercent: 0,
    ktbotherPercent: 0,
  },
  kb: {
    total: 0,
    active: 0,
    inactive: 0,
    agency: 0,
    banc: 0,
    ktb: 0,
    ktbother: 0,
    agencyPercent: 0,
    bancPercent: 0,
    ktbPercent: 0,
    ktbotherPercent: 0,
  },
  category: {
    tableData: [],
    dataList: [],
  },
  subCategory: {
    tableData: [],
    dataList: [],
  },
  pinnedData: {
    eContent: [],
    kb: [],
  },
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  filterTotal: 1,
}

export const contentType = {
  eContent: {
    label: 'eContent',
    path: '/e-content/content',
  },
  kb: {
    label: 'Knowledge Base',
    path: '/e-content/content?tab=kb',
  },
}

export const channelEnum = {
  agency: {
    label: 'Agency',
    value: 'agency',
    percent: 'agencyPercent',
    bgColor: '#EBF6F3',
    dotColor: '#32A287',
  },
  banc: {
    label: 'BANCA',
    value: 'banc',
    percent: 'bancPercent',
    bgColor: '#FFF4C0',
    dotColor: '#FFE66D',
  },
  ktb: {
    label: 'KTB',
    value: 'ktb',
    percent: 'ktbPercent',
    bgColor: '#BED0ED',
    dotColor: '#4976BA',
  },
  other: {
    label: 'Other',
    value: 'other',
    percent: 'otherPercent',
    bgColor: '#E9DEFF',
    dotColor: '#B49CE4',
  },
}

export const categoryEnum = {
  eContentCategory: {
    label: 'หมวดหมู่ eContent',
    path: '/master-data/e-content/category',
    chartColor: '#000072',
    chartId: 'eContentCatBarChart',
  },
  kbCategory: {
    label: 'หมวดหมู่ Knowledge Base',
    path: '/master-data/e-content/category',
    chartColor: '#F49889',
    chartId: 'kbCatBarChart',
  },
}
