import _ from 'lodash'

import { meetingRoomEquipmentList } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { handleChange } from '../../event'
import { setEquipmentSelected } from '../../../../../redux/slices/masterData/meetingRoomForm'
import { store } from '../../../../../App'

export const fetchEquipmentList = async (setEquipmentList) => {
  const body = {
    equipmentName: '',
    createdBy: '',
    status: ['ACTIVE'],
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    limit: -1,
  }

  await callAxios
    .post(meetingRoomEquipmentList, body)
    .then(({ data }) => {
      let result = []
      const response = _.get(data, 'result', [])

      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const equipmentName = _.get(item, 'equipmentName', '')
        const equipmentOption = {
          ...item,
          value: uuid,
          label: equipmentName,
        }
        result.push(equipmentOption)
      })
      setEquipmentList(result)
    })
    .catch((err) => {
      console.log(err)
    })
}

export const handleAddEquipmentList =
  ({ meetingRoomEquipment, meetingRoomId }) =>
  (dispatch) => {
    let newItem = {
      id: meetingRoomEquipment.length + 1,
      equipment: '',
      quantity: '',
      meetingRoom: meetingRoomId,
    }

    let newArray = [...meetingRoomEquipment]
    newArray.push(newItem)
    dispatch(
      handleChange({
        key: 'meetingRoomEquipment',
        value: newArray,
      }),
    )
  }

export const handleChangeEquipmentList = (props) => (dispatch) => {
  const {
    id,
    meetingRoomEquipment,
    meetingRoomId,
    value,
    key,
    equipmentSelected,
  } = props
  const newMeetingRoomEquipment = meetingRoomEquipment.map((equipment) => {
    if (id === equipment.id) {
      equipment = {
        ...equipment,
        [key]: value,
        meetingRoom: meetingRoomId,
      }
    }
    return equipment
  })
  dispatch(
    handleChange({
      key: 'meetingRoomEquipment',
      value: newMeetingRoomEquipment,
    }),
  )
  if (key === 'equipment') {
    dispatch(addEquipmentSelected(equipmentSelected, value))
  }
}

export const handleDeleteEquipment =
  (meetingRoomEquipment, id, equipment, equipmentSelected) => (dispatch) => {
    const newMeetingRoomEquipment = meetingRoomEquipment.filter((n) => {
      return n.id != id
    })

    const sortedMeetingRoomEquipment = newMeetingRoomEquipment.map(
      (equip, idx) => {
        return { ...equip, id: idx + 1 }
      },
    )

    dispatch(
      handleChange({
        key: 'meetingRoomEquipment',
        value: sortedMeetingRoomEquipment,
      }),
    )
    const newEquipmentSelected = equipmentSelected.filter(
      (item) => item !== equipment,
    )
    dispatch(setEquipmentSelected(newEquipmentSelected))
  }

export const addEquipmentSelected = (equipmentSelected, uuid) => (dispatch) => {
  let newEquipmentSelected = [...equipmentSelected]
  newEquipmentSelected.push(uuid)
  dispatch(setEquipmentSelected(newEquipmentSelected))
}

export const handleEquipmentSelected =
  (equipmentList, meetingRoomEquipment) => (dispatch) => {
    const equipmentSelected = equipmentList
      .filter((equipments) =>
        meetingRoomEquipment.some((equipment) => {
          if (equipments.uuid === equipment.equipment) return equipments.uuid
        }),
      )
      .map((equipments) => equipments.uuid)
    dispatch(setEquipmentSelected(equipmentSelected))
  }

export const handleClearEquipment = (idxReset) => (dispatch) => {
  const { meetingRoomEquipment } =
    store.getState().masterDataMeetingRoomForm.information

  const newMeetingRoomEquipment = _.map(meetingRoomEquipment, (equip, idx) => {
    if (idx === idxReset) {
      return { ...equip, equipment: '' }
    }
    return equip
  })

  dispatch(
    handleChange({
      key: 'meetingRoomEquipment',
      value: newMeetingRoomEquipment,
    }),
  )
}
