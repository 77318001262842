import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import * as slicesTable from '../../../../../redux/slices/tableLeanerDetail'

export const headCells = [
  {
    id: 'nameDetails',
    label: 'ชื่อ',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'type',
    label: 'รูปแบบ',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'resultDetails',
    label: 'ผล',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'statusETestAndEva',
    label: 'สถานะ',
    customWidth: '10%',
    custom: true,
    hideSortIcon: true,
    disablePadding: false,
  },
]

export const setDataTable = (listData) => (dispatch) => {
  dispatch(
    slicesTable.setInitialTable({
      rows: findData(listData),
      headCells: headCells,
      type: 'eTestAndEva',
    })
  )
}

export const findData = (listData) => {
  if (!listData.length) return []
  const result = listData.map((item) => {
    const status = _.get(item, 'status', '')
    return {
      ...item,
      nameDetails: _.get(item, 'name', '-'),
      resultDetails: item.result ?? '-',
      statusETestAndEva: (
        <Typography variant="body2" color={mapColorStatus(status)}>
          {status}
        </Typography>
      ),
    }
  })

  return result
}

export const mapColorStatus = (status) => {
  switch (status) {
    case 'ไม่ผ่าน':
      return 'error.main'
    case 'ยังไม่ได้ทำ':
      return 'text.lightGray'
    case 'ผ่าน':
      return 'success.main'
    case 'รอตรวจ':
      return 'warning.main'
    default:
      return 'text.primary'
  }
}
