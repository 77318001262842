import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

export const handleDeleteAmount = (idxDelete) => {
  const { formData } = store.getState().crud
  const amountList = _.get(formData, 'amountList', [])
  const uuidListDelete = _.get(formData, 'uuidListDelete', [])

  const newAmountList = _.filter(amountList, (amount, idx) => idx !== idxDelete)
  const amountDeleteUuid = _.find(
    amountList,
    (amount, idx) => idx === idxDelete,
  )?.uuid

  let newUuidListDelete = uuidListDelete
  if (amountDeleteUuid) {
    newUuidListDelete = [...newUuidListDelete, amountDeleteUuid]
  }

  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        ...formData,
        amountList: newAmountList,
        uuidListDelete: newUuidListDelete,
      },
    }),
  )
}
