import {
  setChange,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eEvaluationForm'

export const handleChangeTab = (value) => (dispatch) => {
  dispatch(startLoading())

  setTimeout(() => {
    dispatch(
      setChange({
        key: 'defaultTap',
        value,
      })
    )
    dispatch(stopLoading())
  }, 700)
}
