import * as yup from 'yup'

export const validationSchema = yup.object({
  productName: yup
    .string()
    .max(255, 'ชื่อซอฟต์แวร์กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุชื่อซอฟต์แวร์'),
  vendor: yup
    .string()
    .max(255, 'Vendor กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณาระบุ Vendor'),
  category: yup.object().nullable().required('กรุณาเลือกหมวดหมู่'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
})
