import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const roomManagement = createSlice({
  name: 'roomManagement',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDetails: (state) => {
      state.isLoadingDetails = true
    },
    stopLoadingDetails: (state) => {
      state.isLoadingDetails = false
    },
    startLoadingBooking: (state) => {
      state.isLoadingBooking = true
    },
    stopLoadingBooking: (state) => {
      state.isLoadingBooking = false
    },

    setIsShowPreview: (state, { payload }) => {
      state.isShowPreview = payload
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenHistoryDrawer: (state, { payload }) => {
      state.isOpenHistoryDrawer = payload
    },
    setFetchData: (state, { payload }) => {
      state.data = payload
    },
    setRoomDetails: (state, { payload }) => {
      state.roomDetails = payload
    },
    setStationList: (state, { payload }) => {
      state.stationList = payload
    },
    setStationName: (state, { payload }) => {
      state.stationName = payload
    },
    setStationUuid: (state, { payload }) => {
      state.stationUuid = payload
    },
    setYear: (state, { payload }) => {
      state.year = payload
    },
    setMonth: (state, { payload }) => {
      state.month = payload
    },
    setMondayDate: (state, { payload }) => {
      state.mondayDate = payload
    },
    setBookingRoomWeekly: (state, { payload }) => {
      state.bookingRoomWeekly = payload
    },
    setMeetingRoomList: (state, { payload }) => {
      state.meetingRoomList = payload
    },
    setRoomRequestList: (state, { payload }) => {
      state.roomRequestList = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload.key] = payload.value
    },
    setReduxObjectValue: (state, { payload }) => {
      state[payload['objectKey']][payload.key] = payload.value
    },
    changeMonth: (state, { payload }) => {
      state.detail.currentMonth = payload.currentMonth
      state.detail.currentYear = payload.currentYear
    },
    setRequestType: (state, { payload }) => {
      state.requestType = payload
    },
    setCurrentDate: (state, { payload }) => {
      state.currentDate = payload
    },
    setCurrentWeek: (state, { payload }) => {
      state.currentWeek = payload
    },
    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload
    },
    setDates: (state, { payload }) => {
      state.dates = payload
    },
    setBookingRoomList: (state, { payload }) => {
      state.bookingRoomList = payload
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload
    },
    setTabActive: (state, { payload }) => {
      const { tabActive } = payload
      state.tabActive = tabActive
    },
    setTypeFilter: (state, { payload }) => {
      state.typeFilter = payload
    },
    setTypeMemo: (state, { payload }) => {
      state.typeMemo = payload
    },
    setFilterMemo: (state, { payload }) => {
      state.filterMemo = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setStationUuidMemo: (state, { payload }) => {
      state.stationUuidMemo = payload
    },
    setListArrayFilter: (state, { payload }) => {
      state.listArrayFilter = payload
    },
    setInitialCalendar: (state) => {
      state.viewCalendar = 'dayGridMonth'
      state.roomDetails = {}
      state.roomDetailCalendar = []
      state.drawerCalendarFilter = {
        isFilterRoomName: false,
        filterRoomName: '',
        isFilterStation: false,
        filterStation: [],
        isFilterBookingType: false,
        filterBookingType: [],
        isFilterTopic: false,
        filterTopicQuery: '',
      }
    },
    setDateQueryStringCalendar: (state, { payload }) => {
      state.dateQueryStringCalendar = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingDetails,
  stopLoadingDetails,
  startLoadingBooking,
  stopLoadingBooking,
  setIsShowPreview,
  setIsOpenFilterDrawer,
  setIsOpenHistoryDrawer,
  setFetchData,
  setRoomDetails,
  setStationList,
  setStationName,
  setStationUuid,
  setYear,
  setMonth,
  setMondayDate,
  setBookingRoomWeekly,
  setMeetingRoomList,
  setRoomRequestList,
  setReduxValue,
  setReduxObjectValue,
  changeMonth,
  setRequestType,
  setCurrentDate,
  setCurrentWeek,
  setSelectedDate,
  setDates,
  setBookingRoomList,
  setRowsPerPage,
  setTabActive,
  setTypeFilter,
  setTypeMemo,
  setFilterMemo,
  setFilter,
  setStationUuidMemo,
  setListArrayFilter,
  setInitialCalendar,
  setDateQueryStringCalendar,
} = roomManagement.actions

export default roomManagement.reducer
