import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1.5)}`,
  gap: theme.spacing(2),
  p: { span: { color: theme?.palette?.success?.main } },
}))
