import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useParams } from 'react-router-dom'
import { getQueryParams } from '../../../../../utils/lib'
import HistoryDrawer from '../HistoryDrawer'
import { onClickAddTest } from './events'

const DropdownButton = () => {
  const { id: uuid } = useParams()
  const [isOpen, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { isCancel, manageClassData } = useSelector(
    (state) => ({
      isCancel: state.manageClassDetail.isCancel,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual
  )
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const course = _.get(monthlyPlan, 'trainingInfo[0].course', '')
  const requireDocument = _.get(course, 'requireDocument', false)
  const requireOicExam = _.get(course, 'requireOicExam', false)
  const isAttendance = _.get(course, 'isAttendance', false)
  const objOnLink = {
    drawer: Number(getQueryParams('drawer')),
  }

  useEffect(() => {
    if (_.get(objOnLink, 'drawer', 0) == 1) {
      setOpen(true)
    }
  }, [])

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="btn-more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isCancel}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'btn-more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions(uuid).map((option, index) => {
          if (!requireOicExam && !requireDocument && index == 0) return
          if (!isAttendance && index == 1) return
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => onClickAddTest(option, setOpen, setAnchorEl)}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </>
  )
}

export default DropdownButton

export const createOptions = (uuid) => [
  {
    text: 'เอกสารการสมัคร',
    link: `/manage-class/learner/application-documents/${uuid}`,
  },
  {
    text: 'เช็คชื่อ',
    link: `/manage-class/attendance/${uuid}`,
  },
  {
    text: 'ประวัติการเพิ่มผู้เรียน',
  },
]
