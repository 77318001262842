import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
  resetRowsPerPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import {
  eLearningDashboardAllCourseDownload,
  examinationDashboardSchedule,
} from '../../../../../utils/apiPath'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import dayjs from 'dayjs'
import callAxios from '../../../../../utils/baseService'
import {
  SCHEDULE_STATUS,
  SCHEDULE_STATUS_TEXT_TH_COLOR,
} from '../../../../../constants/eExamination'

export const scheduleStatuses = [
  {
    label: 'ระหว่างสอบ',
    fieldName: 'eExamDashboard.scheduleDrawer.EXAM_ONGOING',
    fontColor: '#00008F',
  },
  {
    label: 'เปิดรับสมัคร',
    fieldName: 'eExamDashboard.scheduleDrawer.OPEN_REGISTER',
    fontColor: '#494DF4',
  },
  {
    label: 'ยกเลิกการสอบ',
    fieldName: 'eExamDashboard.scheduleDrawer.CANCEL',
    fontColor: '#C91432',
  },
  {
    label: 'จบการสอบ',
    fieldName: 'eExamDashboard.scheduleDrawer.SUCCESS',
    fontColor: '#333333',
  },
  {
    label: 'รอเปิดรับสมัคร',
    fieldName: 'eExamDashboard.scheduleDrawer.IN_PROGRESS',
    fontColor: '#FFB547',
  },
  {
    label: 'ปิดรับสมัคร',
    fieldName: 'eExamDashboard.scheduleDrawer.CLOSED_REGISTER',
    fontColor: '#7F7F7F',
  },
  {
    label: 'เสร็จสมบูรณ์',
    fieldName: 'eExamDashboard.scheduleDrawer.COMPLETED',
    fontColor: '#1CC54E',
  },
]

export const examFieldTypes = [
  {
    label: 'สนามสอบบริษัท',
    fieldName: 'eExamDashboard.scheduleDrawer.isCompany',
    type: 'บริษัท',
  },
  {
    label: 'สนามสอบสมาคม',
    fieldName: 'eExamDashboard.scheduleDrawer.isAssociation',
    type: 'สมาคม',
  },
  {
    label: 'สนามสอบอื่นๆ',
    fieldName: 'eExamDashboard.scheduleDrawer.isOther',
    type: 'อื่นๆ',
  },
]

export const headCellsExamField = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examStatus',
    label: 'สถานะรอบสอบ',
    disablePadding: false,
    hideSortIcon: true,
    isCustomTextColor: true,
  },
  {
    id: 'registerDate',
    label: 'วันที่เปิด-ปิดรับสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examDate',
    label: 'วันที่-เวลาสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examField',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'other',
    label: 'อื่นๆ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countEnrollText',
    label: 'จำนวนการสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const handleCheckBox = (key, value, table) => {
  store.dispatch(
    setFieldValue({
      key: key,
      value: value,
    }),
  )
  store.dispatch(resetRowsPerPage())
  store.dispatch(
    fetchData({
      table,
    }),
  )
}

export const fetchData =
  ({ table }) =>
  async (dispatch) => {
    const { eExamDashboard } = store.getState().crud
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsExamField
    let type = []
    if (_.get(eExamDashboard, 'scheduleDrawer.isCompany')) {
      type = [...type, 'COMPANY']
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.isAssociation')) {
      type = [...type, 'ASSOCIATION']
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.isOther')) {
      type = [...type, 'OTHER']
    }

    let status = []
    if (_.get(eExamDashboard, 'scheduleDrawer.OPEN_REGISTER')) {
      status = [...status, SCHEDULE_STATUS.OPEN_REGISTER]
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.IN_PROGRESS')) {
      status = [...status, SCHEDULE_STATUS.IN_PROGRESS]
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.CANCEL')) {
      status = [...status, SCHEDULE_STATUS.CANCEL]
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.SUCCESS')) {
      status = [...status, SCHEDULE_STATUS.SUCCESS]
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.CLOSED_REGISTER')) {
      status = [...status, SCHEDULE_STATUS.CLOSED_REGISTER]
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.EXAM_ONGOING')) {
      status = [...status, 'EXAM_ONGOING']
    }
    if (_.get(eExamDashboard, 'scheduleDrawer.COMPLETED')) {
      status = [...status, SCHEDULE_STATUS.COMPLETED]
    }

    const station = _.defaultTo(eExamDashboard?.filtersDrawer?.station, []).map(
      (item) => item.uuid,
    )
    const associationRegion = _.defaultTo(
      eExamDashboard?.filtersDrawer?.associationRegion,
      [],
    ).map((item) => item.uuid)
    const associationProvince = _.defaultTo(
      eExamDashboard?.filtersDrawer?.associationProvince,
      [],
    ).map((item) => item.uuid)
    const otherRegion = _.defaultTo(
      eExamDashboard?.filtersDrawer?.otherRegion,
      [],
    ).map((item) => item.uuid)
    const otherProvince = _.defaultTo(
      eExamDashboard?.filtersDrawer?.otherProvince,
      [],
    ).map((item) => item.uuid)

    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        type,
        status,
        station: station,
        startDate: _.defaultTo(
          eExamDashboard?.filtersDrawer?.startDate,
          dayjs().startOf('month').format('YYYY-MM-DD'),
        ),
        endDate: _.defaultTo(
          eExamDashboard?.filtersDrawer?.endDate,
          dayjs().format('YYYY-MM-DD'),
        ),
        associationRegion: associationRegion,
        associationProvince: associationProvince,
        otherRegion: otherRegion,
        otherProvince: otherProvince,
      }
      await callAxios
        .post(examinationDashboardSchedule, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          const totalEnroll = _.get(data, 'totalCount', 0)
          const reportExamScheduleAll = _.get(data, 'reportExamScheduleAll', [])
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.scheduleDrawer.totalEnroll',
              value: totalEnroll,
            }),
          )
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.scheduleDrawer.countTypeArr',
              value: reportExamScheduleAll,
            }),
          )
          dispatch(
            setInitialTable({
              rows: prepareRow(results),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              hideFilter: true,
              isCheckBox: false,
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload =
  (selected, drawerType, courseType) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      type: drawerType ? 'COURSE' : 'LEARNER',
      courseType: courseType === 'all' ? '' : courseType,
      list: selected,
    }

    await dispatch(
      downloadFile({
        url: eLearningDashboardAllCourseDownload,
        body: body,
        fileName: `รายการหลักสูตร.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    const seatAvailableTxt = !ele.seatAvailable ? 'เต็ม' : ''
    const enrollColor = !ele.seatAvailable ? '#C91432' : '#1CC54E'
    return {
      ...ele,
      textColor: SCHEDULE_STATUS_TEXT_TH_COLOR?.[ele.examStatus],
      countEnrollText: `${ele.countEnroll}/${ele.numberOfPeople} ${seatAvailableTxt}`,
      enrollColor,
    }
  })
}
