export const BGM_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
}

export const BGM_UPLOAD_STATUS = {
  SUCCESS: 'สำเร็จ',
  ERROR: 'ไม่สำเร็จ',
}

export const BGM_STATUS_LABEL = {
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}
