import { setFieldValue } from '../../../../../../redux/slices/crud'
import { downloadFile } from '../../../../../../services/util'
import { eEvaluationDashboard as eEvaluationDashboardApi } from '../../../../../../utils/apiPath'
import _ from 'lodash'
import dayjs from 'dayjs'
import { store } from '../../../../../../App'

export const handleDownloadFreeText = async (part4) => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const { eEvaluationDashboard } = store.getState().crud
  const eEvaluationVersion = eEvaluationDashboard.uuid ?? ''
  const body = {
    questionUuid: part4?.uuid ?? '',
    eEvaluationVersion: eEvaluationVersion,
    startDate: eEvaluationDashboard?.isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterStartDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: eEvaluationDashboard?.isManageClass
      ? ''
      : eEvaluationDashboard?.filtersDrawer?.isFilterUserResponseDate
      ? eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ?? ''
      : eEvaluationDashboard?.filtersDrawer?.dateFilterFinishDate ??
        dayjs().startOf('month').format('YYYY-MM-DD'),
    userResponseDateStart: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateStart,
      dayjs().format('YYYY-MM-DD'),
    ),
    userResponseDateEnd: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.userResponseDateEnd,
      dayjs().format('YYYY-MM-DD'),
    ),
    courseName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseName,
      '',
    ),
    trainingDateStartDate: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainingDateStartDate,
      '',
    ),
    trainingDateFinishDate: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainingDateFinishDate,
      '',
    ),
    trainerName: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainerName,
      '',
    ),
    distribution: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.distribution,
      '',
    ),
    trainingPlanUuid: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.trainingPlanUuid,
      '',
    ),
    courseCode: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.courseCode,
      '',
    ),
    department: _.defaultTo(
      eEvaluationDashboard?.filtersDrawer?.department,
      '',
    ),
    station: _.defaultTo(eEvaluationDashboard?.filtersDrawer?.station, ''),
  }

  await store.dispatch(
    downloadFile({
      url: eEvaluationDashboardApi + '/download',
      body: body,
      fileName: `รายการคำตอบ.xlsx`,
    }),
  )

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
