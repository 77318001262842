import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import { setExpand } from '../../../../redux/slices/eLearning/preview'
import { handleHoverOnVideo } from '../LeftPanel/events'
import { StyledFloatButton, StyledFloatBg } from '../Styled'

const FloatingButtons = () => {
  const dispatch = useDispatch()
  const { isExpanded, type } = useSelector(
    (state) => ({
      isExpanded: state.eLearningPreview.isExpanded,
      type: _.get(state.eLearningPreview.selectedLesson, 'type', ''),
    }),
    shallowEqual,
  )
  const ref = useRef(null)
  const isFile = type === MODULE_TYPE.UPLOAD_FILE

  useEffect(() => {
    handleHoverOnVideo(ref)
  }, [])

  return (
    <Box
      ref={ref}
      sx={{ width: '100%', height: '100vh', position: 'absolute' }}
    >
      <StyledFloatButton
        isFile={isFile}
        sx={{ left: 40 }}
        size="small"
        startIcon={<ArrowBackIosNew />}
      >
        กลับไปหน้าหลัก
      </StyledFloatButton>
      <StyledFloatBg isFile={isFile} />
      {!isExpanded && (
        <StyledFloatButton
          isFile={isFile}
          size="small"
          variant="outlined"
          sx={{ right: 28 }}
          startIcon={<img src="/icon/player/expand_menu.svg" />}
          onClick={() => dispatch(setExpand(true))}
        >
          เปิดเมนู
        </StyledFloatButton>
      )}
    </Box>
  )
}

export default FloatingButtons
