import * as yup from 'yup'

export const validationSchema = yup.object({
  productType: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(80, 'ระบุได้ไม่เกิน 80 ตัวอักษร'),
  status: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})
