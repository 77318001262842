import React from 'react'
import Button from '@mui/material/Button'
import { path } from '../../../../constants/path'
import { StyledHeaderContainer } from '../../Styled'
import AddIcon from '@mui/icons-material/Add'

const Header = () => {
  return (
    <StyledHeaderContainer>
      <Button
        startIcon={<AddIcon />}
        data-testid="btn-create"
        variant="contained"
        onClick={() =>
          (window.location.href = `${path.eCertification}/background/form`)
        }
        size="m"
      >
        เพิ่มภาพพื้นหลัง
      </Button>
    </StyledHeaderContainer>
  )
}
export default Header
