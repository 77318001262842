import { store } from '../../../../App'
import { setEExamType } from './index'
import { openDialog } from '../../../../redux/slices/dialog'
import ImportTemplateDialog from '../../../../modules/EExamination/ImportTemplateDialog'
import ImportExaminerTemplateDialog from '../../../../modules/EExamination/ImportExaminerTemplateDialog'
import { setImportExamierType } from './index'

export const handleChangeExamType = (event, eExamTypeValue) => {
  store.dispatch(setEExamType(eExamTypeValue))
}

export const handleOpenImportTemplateDialog = () => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: false,
      content: <ImportTemplateDialog />,
    }),
  )
}

export const handleOpenImportExaminerTemplateDialog = (importExaminerType) => {
  store.dispatch(setImportExamierType(importExaminerType))
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: false,
      content: <ImportExaminerTemplateDialog />,
    }),
  )
}
