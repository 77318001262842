export const initialState = {
  isLoading: false,
  isUploadLoading: {},
  isSuccess: false,
  error: { all: null, upload: null },
  data: null,
  levelDistribution: {
    coursesInformation: '',
    learningPathInformation: '',
    collectionInformation: '',
  },
  options: {
    productTypes: [],
    distributionChannels: [],
    courses: [],
    learningPaths: [],
  },
}
