import { store } from '../../../../../App'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    typeOfETesting: _.get(filterProp, 'typeOfETesting', []),
    codeId: _.get(filterProp, 'codeId', ''),
    numberOfVersions: _.get(filterProp, 'numberOfVersions', -1),
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    status: _.get(filterProp, 'status', []),
  }
}
