import { store } from 'src/App'
import {
  setIsOpenClassDrawer,
  setReduxDashboardByKey,
} from '../../../../redux/slices/monthlyPlan'

export const handleOpenClassDrawer = (classType = '') => {
  store.dispatch(setReduxDashboardByKey({ key: 'classType', value: classType }))
  store.dispatch(setIsOpenClassDrawer(true))
}
