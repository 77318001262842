import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CalendarTodayTwoTone from '@mui/icons-material/CalendarTodayTwoTone'

import FilterDrawer from '../../../FilterDrawer'
import { setToggleFilterDrawer } from '../../../../../redux/slices/table'

import {
  FilterButton,
  BoxCard,
  BoxContent,
  BoxFilter,
  BoxLabel,
  BoxDateLabel,
} from './Styled'
const Index = () => {
  const dispatch = useDispatch()
  const { filter } = useSelector((state) => state.table)
  const { startDate, toDate, totalLearnerAnswer } = useSelector(
    (state) => ({
      startDate: state.eEvaluationFeedback.startDate,
      toDate: state.eEvaluationFeedback.toDate,
      totalLearnerAnswer: state.eEvaluationFeedback.totalLearnerAnswer,
    }),
    shallowEqual
  )

  const eEvaluationType = useSelector(
    (state) => state.eEvaluationForm.eEvaluationType,
    shallowEqual
  )

  return (
    <BoxCard>
      <BoxContent>
        <BoxLabel>
          <Typography variant="h5">{totalLearnerAnswer} การตอบรับ</Typography>
        </BoxLabel>
        <BoxFilter>
          <BoxDateLabel>
            <CalendarTodayTwoTone color="primary" />
            <Typography variant="body1" sx={{ ml: 2 }}>
              {startDate} - {toDate}
            </Typography>
          </BoxDateLabel>
          <FilterButton onClick={() => dispatch(setToggleFilterDrawer(true))}>
            <Typography variant="buttonm">ตัวกรอง</Typography>
          </FilterButton>
        </BoxFilter>
      </BoxContent>

      {eEvaluationType == 'CLASS' ? (
        <FilterDrawer
          open={_.get(filter, 'isFilterDrawer', false)}
          table={[]}
          drawerType="feedbackClass"
        />
      ) : (
        <FilterDrawer
          open={_.get(filter, 'isFilterDrawer', false)}
          table={[]}
          drawerType="feedbackStandalone"
        />
      )}
    </BoxCard>
  )
}

export default Index
