import {
  setChange,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eTestingForm'

export const handleChangeTab = (value) => (dispatch) => {
  dispatch(startLoading())
  dispatch(
    setChange({
      key: 'defaultTap',
      value,
    })
  )
  setTimeout(() => {
    dispatch(stopLoading())
  }, 700)
}
