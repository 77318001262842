import _ from 'lodash'
import {
  setIsOpenFilterDrawer,
  setDisplayFilter,
  setFiltersDashboard,
} from '../../../../redux/slices/monthlyPlan'
import { store } from '../../../../App'
import { getDefaultFiltersDashboard } from '../../handler/getDefaultFiltersDashboard'
import { initialState } from 'src/redux/slices/monthlyPlan/model'

export const handleChange = ({ key, value, listKey }) => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard

  let objState = {
    ...displayFilters,
    [key]: value,
  }

  if (!_.isNil(listKey)) {
    objState = {
      ...objState,
      [listKey]: {
        ...objState[listKey],
        [key]: value,
      },
    }
  }

  const keyWithOutChecked = key.replace('Checked', '')

  if (!value) {
    if (
      _.includes(key, 'Checked') &&
      key !== 'dateChecked' &&
      key !== 'stationChecked'
    ) {
      objState = {
        ...objState,
        [keyWithOutChecked]: _.get(
          initialState,
          `dashboard.filters.${keyWithOutChecked}`,
          [],
        ),
      }
    } else if (key === 'dateChecked') {
      objState = {
        ...objState,
        startDate: _.get(initialState, `dashboard.filters.startDate`, ''),
        endDate: _.get(initialState, `dashboard.filters.endDate`, ''),
        displayDate: _.get(initialState, `dashboard.filters.displayDate`, ''),
      }
    } else if (key === 'stationChecked') {
      const objFilter = _.get(displayFilters, `${keyWithOutChecked}`, {})
      objState = {
        ...objState,
        [keyWithOutChecked]: Object.keys(objFilter).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      }
    }
  }

  store.dispatch(setDisplayFilter(objState))
}

export const handleSelectDate = (selectedDates) => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard
  let objState = {
    ...displayFilters,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }

  store.dispatch(setDisplayFilter(objState))
}

export const onClearClick = async () => {
  const defaultFilters = await getDefaultFiltersDashboard()
  store.dispatch(setDisplayFilter(defaultFilters))
}

export const filterDashboard = async () => {
  const { displayFilters } = store.getState().monthlyPlan.dashboard

  store.dispatch(setIsOpenFilterDrawer(false))
  store.dispatch(setFiltersDashboard(displayFilters))
}
