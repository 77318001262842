import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { shouldForwardProp } from '../../../Styled'

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const BoxHeader = styled(
  Box,
  shouldForwardProp,
)(({ theme, isScrolled }) => ({
  width: '100%',
  position: 'fixed',
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 14,
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(8),
  marginTop: -14,
  backgroundColor: theme?.palette?.background?.blue,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    ...FixedHeaderStyleMd(theme, isScrolled),
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    paddingLeft: 0,
    ...FixedHeaderStyleSm(theme, isScrolled),
  },
}))

const FixedHeaderStyle = (theme) => ({
  top: 80,
  zIndex: 99,
  boxShadow: theme?.palette?.shadow?.bottom,
})

const FixedHeaderStyleMd = (theme, scrolled) =>
  scrolled
    ? {
        ...FixedHeaderStyle(theme),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        gap: theme.spacing(2),
      }
    : { gap: 24 }

const FixedHeaderStyleSm = (theme, scrolled) =>
  scrolled
    ? {
        ...FixedHeaderStyle(theme),
        position: 'fixed',
        padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
        '& > .MuiBox-root:first-of-type': { display: 'none' },
      }
    : { gap: 16 }

export const BoxLabelTitle = styled(
  Box,
  shouldForwardProp,
)(({ theme, isEdit, isDraft }) => {
  let editWidth = 'calc(100% - 350px)'
  if (isDraft) editWidth =  'calc(100% - 395px)'
  return {
    display: 'flex',
    flexDirection: 'column',
    width: isEdit ? editWidth : 'auto',
    [theme.breakpoints.down('md')]: { width: '100%' },
  }
})

export const StyledViewTitle = styled(Typography)(() => ({
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
