import { shallowEqual, useSelector } from 'react-redux'
import { roomBookingCountSelector } from '../../../../../../redux/slices/roomManagement/Drawer'
import { BookingFormItem } from './BookingFormItem'
import { BOOKING_PERIOD_TYPE } from '../../../../../../constants/roomManagement'

function handleDisplayItems(bookingPeriod, count) {
  return bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME && count > 0
    ? 1
    : count
}

export const RoomBookingList = ({ bookingPeriod }) => {
  const count = useSelector(
    roomBookingCountSelector(bookingPeriod),
    shallowEqual,
  )

  return Array.from({
    length: handleDisplayItems(bookingPeriod, count),
  }).map((x, i) => <BookingFormItem key={i} index={i} />)
}
