import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import {
  examinationDashboardTop,
  examinationDashboardTopDownload,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const headCellsExaminer = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fieldName',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'count',
    label: 'จำนวนผู้สมัครสอบ (คน)',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const headCellsSchedule = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fieldName',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'count',
    label: 'จำนวนสนามที่เปิด (รอบ)',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchData =
  ({ table, examType, dataType }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells =
      dataType === 'schedule' ? headCellsSchedule : headCellsExaminer
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        examType: examType,
        dataType: dataType,
      }
      await callAxios
        .post(examinationDashboardTop, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          const countAll = _.get(data, 'countAll', 0)
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.rankingDrawer.countTotal',
              value: countAll,
            }),
          )
          dispatch(
            setInitialTable({
              rows: prepareRow(
                results,
                _.get(table, 'limit', '100'),
                realPage,
                countAll,
              ),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              hideFilter: true,
              isCheckBox: true,
              onDownload: (selected) =>
                dispatch(onDownload(selected, examType, dataType)),
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload =
  (selected, examType, dataType) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      examType: examType,
      dataType: dataType,
      list: selected,
    }

    const typeName =
      dataType === 'schedule'
        ? 'สนามสอบเปิดรอบมากที่สุด'
        : 'สนามสอบผู้สมัครมากที่สุด'
    const examTypeName = examType === 'company' ? 'บริษัท' : 'สมาคม'

    await dispatch(
      downloadFile({
        url: examinationDashboardTopDownload,
        body: body,
        fileName: `รายการ${typeName}_${examTypeName}.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }

const prepareRow = (data, limit, page, countAll) => {
  return data.map((ele, index) => {
    const percent =
      ele.count > 0 ? ((ele.count / countAll) * 100).toFixed(2) : 0
    return {
      ...ele,
      count: `${ele.count} (${percent}%)`,
      uuid: ele.associationUuid ? ele.associationUuid : ele.companyUuid,
      no: (page - 1) * limit + (index + 1),
    }
  })
}
