export const initialValues = {
  idCardPhoto: '',
  examinerType: '',
  code: '',
  idCardNo: '',
  prefix: '',
  firstName: '',
  lastName: '',
  gender: '',
  dateOfBirth: '',
  qualification: '',
  mobilePhone: '',
  email: '',
  zipCode: '',
  province: '',
  amphure: '',
  tambon: '',
  address: '',
  unitName: '',
  unitNumber: '',
  userProfile: '',
  schedule: '',
  idCardImageKey: {},
  institution: '',
  between: '',
  branch: '',
  gpa: '',
  otherDocs: [],
}

export const initialState = {
  errors: [],
  formError: [],
  loading: false,
  loadingAddr: false,
  body: initialValues,
  genderList: [],
  qualificationList: [],
  addressList: [],
  userDetail: [],
  eExaminerDetail: null,
}
