export const initialState = {
  isLoading: false,
  isUploadLoading: {},
  isSuccess: false,
  error: {
    all: null,
    upload: null,
  },
  data: null,
  levelDistribution: '',
  options: {
    productTypes: [],
    distributionChannels: [],
    courses: [],
  }
}
