import { Box } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'

export const Loading = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        opacity: 0.4,
        pointerEvents: 'none',
      }}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
          mx: 4,
        }}
      />
    </Box>
  )
}
