import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import {
  handleInitialCourse,
  handleInitialModule,
  handleSetLessonState,
} from './events'

const eLearningPreview = createSlice({
  name: 'eLearningPreview',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLessonLoading: (state) => {
      state.isLessonLoading = true
    },
    stopLessonLoading: (state) => {
      state.isLessonLoading = false
    },
    startVideoLoading: (state) => {
      state.isVideoLoading = true
    },
    stopVideoLoading: (state) => {
      state.isVideoLoading = false
    },
    setExpand: (state, { payload }) => {
      state.isExpanded = payload
    },
    setInitialPreview: (state, { payload }) => {
      const eLearningCourseLesson = handleInitialCourse(payload)
      delete payload.eLearningCourseLesson
      state.isCourse = true
      state.course = payload
      state.lessons = [...eLearningCourseLesson]
      state.selectedLesson = _.get(eLearningCourseLesson, `[0].modules[0]`, {})
    },
    setInitialModulePreview: (state, { payload }) => {
      const module = handleInitialModule(payload, 0, 0, true)
      state.lessons = [module]
      state.selectedLesson = module
    },
    setSelectedLesson: (state, { payload }) => {
      state.isExpanded = false
      state.selectedLesson = payload
    },
    setSelectedFile: (state, { payload }) => {
      state.isExpanded = false
      state.selectedLesson.selectedFile = payload
      _.set(state.selectedLesson.fileList, `[${payload}].isFinished`, true)
    },
    setSetting: (state, { payload }) => {
      const { key, value } = payload
      state.setting[key] = value
    },
    setVideoState: (state, { payload }) => {
      const module = {
        ...state.selectedLesson,
        videoState: {
          ..._.get(state.selectedLesson, 'videoState', {}),
          ...payload,
        },
      }
      handleSetLessonState(state, module)
    },
    setLessonData: (state, { payload }) => {
      const { key, value } = payload
      const module = { ...state.selectedLesson, [key]: value }
      handleSetLessonState(state, module)
    },
    setModuleProgress: (state, { payload }) => {
      const module = { ...state.selectedLesson, progress: payload }
      handleSetLessonState(state, module)
    },
    setStartModule: (state) => {
      const module = {
        ...state.selectedLesson,
        isInProgress: true,
        progress: 0,
      }
      handleSetLessonState(state, module)
    },
    setStopModule: (state) => {
      const module = {
        ...state.selectedLesson,
        isInProgress: false,
        progress: 0,
      }
      localStorage.removeItem(module.eTestingUuid)
      handleSetLessonState(state, module)
    },
    setFullScreen: (state, { payload }) => {
      state.isFullScreen = _.isNil(payload) ? !state.isFullScreen : payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLessonLoading,
  stopLessonLoading,
  startVideoLoading,
  stopVideoLoading,
  setExpand,
  setInitialPreview,
  setInitialModulePreview,
  setSelectedLesson,
  setSelectedFile,
  setSetting,
  setVideoState,
  setLessonData,
  setModuleProgress,
  setStartModule,
  setStopModule,
  setFullScreen,
} = eLearningPreview.actions

export default eLearningPreview.reducer
