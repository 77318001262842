import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const postBodyMutation = (data) => {
  return {
    name: _.get(data, 'name', ''),
    oldAccountCode: _.get(data, 'oldAccountCode', ''),
    newAccountCode: _.get(data, 'newAccountCode', ''),
    category: _.get(data, 'category.value', ''),
    description: _.get(data, 'description', ''),
    definitionENG: _.get(data, 'definitionENG', ''),
    definitionTH: _.get(data, 'definitionTH', ''),
    remark: _.get(data, 'remark', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    uuid: _.get(data, 'uuid', undefined),
  }
}
