import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ChartContainer } from './Styled'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { TypeChartTab, typeTab } from '../../model'
import BarChart from '../../../../../components/Chart/BarChart'

const TypeChart = () => {
  const [tab, setTab] = useState(TypeChartTab.course)
  const { chart } = useSelector(
    (state) => ({
      chart: state.eLearningDashboard.overview.chartData[tab],
    }),
    shallowEqual,
  )
  return (
    <ChartContainer>
      <Tabs
        value={tab}
        textColor="primary"
        indicatorColor="primary"
        onChange={(_, value) => setTab(value)}
      >
        <Tab
          value={TypeChartTab.course}
          label={
            <Typography variant="body1b" color="primary">
              จำนวนหลักสูตร
            </Typography>
          }
        />
        <Tab
          value={TypeChartTab.enroll}
          label={
            <Typography variant="body1b" color="primary">
              จำนวนผู้เรียน Enroll
            </Typography>
          }
        />
      </Tabs>
      <BarChart
        dataSet={{
          data: chart,
          backgroundColor: [
            typeTab.AGENCY.mainColor,
            typeTab.BANCA.mainColor,
            typeTab.KTB.mainColor,
            typeTab.OTHER.mainColor,
          ],
        }}
        labels={[
          typeTab.AGENCY.label,
          typeTab.BANCA.label,
          typeTab.KTB.label,
          typeTab.OTHER.label,
        ]}
      />
    </ChartContainer>
  )
}

export default TypeChart
