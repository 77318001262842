import React from 'react'
import Slider from 'react-slick'
import {
  ArrowBackIosOutlined,
  ArrowForwardIos,
  Download,
} from '@mui/icons-material'
import './styled.css'
import {
  FlexCenterBox,
  ImageBox,
  DownloadText,
  DownloadTextSize,
} from './styled'

const File = ({ data }) => {
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  function NextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <ArrowForwardIos />
      </div>
    )
  }
  function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <ArrowBackIosOutlined />
      </div>
    )
  }
  return (
    <>
      {data.content.files.map((file) => {
        return (
          <>
            <Slider {...settings} className="noPrint document">
              {file.fileName?.map((item, index) => {
                return (
                  <ImageBox
                    src={`${window.__env__.REACT_APP_API_URL}/file${item}`}
                    key={index}
                    alt="image"
                  />
                )
              })}
            </Slider>
            {file.downloadable && (
              <FlexCenterBox>
                <Download />
                <DownloadText>ดาวโหลดเฉพาะเอกสารนี้</DownloadText>
                <DownloadTextSize>{`ขนาด ${file.fileSize} MB`}</DownloadTextSize>
              </FlexCenterBox>
            )}
          </>
        )
      })}
    </>
  )
}

export default File
