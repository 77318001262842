import { Tooltip, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'

export const TooltipLabel = ({ label }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={<Typography variant="tooltip">{label}</Typography>}
      followCursor
      componentsProps={{
        tooltip: {
          sx: {
            background: theme?.palette?.blue?.azure,
            color: theme?.palette?.common?.white,
            boxShadow: theme?.palette?.boxShadow?.main,
          },
        },
      }}
    >
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 300,
        }}
        variant="body2b"
      >
        {label}
      </Typography>
    </Tooltip>
  )
}
