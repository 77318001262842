import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Container, Tab, Dot, OuterDot } from './Styled'
import { Row } from '../../Styled'
import { typeTabList, typeTab } from '../../model'
import { setType } from '../../../../../redux/slices/eLearning/dashboard'

const TypeTab = () => {
  const dispatch = useDispatch()
  const { type } = useSelector(
    (state) => ({
      type: state.eLearningDashboard.type,
    }),
    shallowEqual,
  )
  return (
    <Container>
      <Typography variant="body2" color="text.lightGray">
        เลือกประเภทเพื่อดูรายละเอียด
      </Typography>
      <Row sx={{ gap: 1 }}>
        {typeTabList.map((tab, ind) => {
          const active = type === tab
          return (
            <Tab
              key={ind}
              background={
                active ? typeTab[tab].mainColor : typeTab[tab].secondaryColor
              }
              onClick={() => dispatch(setType(tab))}
            >
              {active ? (
                <OuterDot color="#FFFFFF">
                  <Dot background="#FFFFFF" />
                </OuterDot>
              ) : (
                <Dot background={typeTab[tab].mainColor} />
              )}
              <Typography
                variant="body1b"
                color={active ? 'text.white' : 'text.lightGray'}
              >
                {typeTab[tab].label}
              </Typography>
            </Tab>
          )
        })}
      </Row>
    </Container>
  )
}

export default TypeTab
