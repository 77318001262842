import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Header from './Header'
import MeetingRoomImages from './MeetingRoomImages'
import MeetingRoomDetails from './MeetingRoomDetails'
import { LoadingPage } from '../../ManageClass/Styled'
import { initialState } from '../../../redux/slices/masterData/meetingRoomForm/model'
import { validateSchema } from './validateSchema'
import { handleFetchDataCreate, handleSubmit } from './event'
import { fetchMeetingRoom } from '../View/events'

const Index = () => {
  const { id: uuid } = useParams()
  const user = localStorage.getItem('user')
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.masterDataMeetingRoomForm.isLoading,
    }),
    shallowEqual
  )
  const objUser = JSON.parse(user)
  const stationUuid = _.get(objUser, 'stationUuid', '')

  useEffect(() => {
    !_.isEmpty(uuid)
      ? dispatch(fetchMeetingRoom(uuid))
      : dispatch(handleFetchDataCreate(stationUuid))
  }, [uuid])

  return (
    <Box sx={{ m: 3 }}>
      {!isLoading && (
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Formik
            initialValues={{
              information: initialState.information,
            }}
            validationSchema={validateSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(value, { setErrors }) => {
              dispatch(handleSubmit(value, setErrors))
            }}
          >
            {() => {
              return (
                <Form>
                  <Header />
                  <MeetingRoomImages />
                  <MeetingRoomDetails />
                </Form>
              )
            }}
          </Formik>
        </Box>
      )}
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default Index
