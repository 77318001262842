import styled from '@mui/system/styled'
import { Column } from '../../../../../../components/Layout/Styled'

export const StyledWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(2),
  overflow: 'hidden',
  padding: `0 ${theme.spacing(10)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(5)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}))

export const StyledNoContentBox = styled(Column)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  border: 1,
  minWidth: 342,
  height: 214,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 12px 1px rgba(73, 118, 186, 0.35)',
  backgroundColor: theme.palette?.background?.paper,
}))
