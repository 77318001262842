import React from 'react'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { StyledBoxContent } from './Styled'

const StatusSwitch = ({ label, subLabel, checked, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">{label}</Typography>
      <StyledBoxContent>
        <Typography>{subLabel}</Typography>
        <Switch name="status" checked={checked} onChange={handleChange} />
      </StyledBoxContent>
    </Box>
  )
}

export default StatusSwitch
