import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import SelectStatic from '../../../../../../../components/redux/SelectStatic'
import { optionQuestionEva } from '../../../../../../../constants/eEvaluation'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'

import { handleAddQuestionOther } from './events'

const Index = () => {
  const dispatch = useDispatch()

  const { listQuestions, disableForm, onViewMode } = useSelector(
    (state) => ({
      listQuestions: state.eEvaluationForm.questions,
      disableForm: state.eEvaluationForm.disableForm,
      onViewMode: state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  if (onViewMode) return <Box />
  return (
    <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
      <SelectStatic
        size="m"
        listOption={optionQuestionEva}
        placement="top"
        iconLabel={
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            data-testid="btn-add-question"
          >
            <AddCircleOutlineOutlined />
            <Typography variant="body1">เพิ่มคำถาม</Typography>
          </Box>
        }
        disabled={disableForm}
        handleChange={(value) => {
          dispatch(handleAddQuestionOther(value, listQuestions.length + 1))
        }}
        handleChangeIcon={() => {
          dispatch(handleAddQuestionOther('SINGLE', listQuestions.length + 1))
        }}
      />
    </Box>
  )
}
export default Index
