import React from 'react'
import IconButton from '@mui/material/IconButton'
import { useDispatch } from 'react-redux'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

import { BoxFooter, LabelTitle } from './Styled'
import { handleChange } from './events'
const Index = ({ propsFooter }) => {
  const dispatch = useDispatch()

  const {
    type,
    totalQuestion,
    titleQuestion,
    sequence,
    sequenceSubQuestions,
    nextQuestions,
  } = propsFooter

  const disabledBack = nextQuestions === 1
  const disabledNext = nextQuestions === totalQuestion

  const propsEvent = {
    type,
    sequence,
    sequenceSubQuestions,
  }

  return (
    <BoxFooter>
      <IconButton
        data-testid={`${type}-back-question`}
        sx={{
          cursor: disabledBack ? 'not-allowed' : 'pointer',
        }}
        onClick={() =>
          !disabledBack &&
          dispatch(
            handleChange({
              ...propsEvent,
              value: nextQuestions - 1,
            })
          )
        }
      >
        <ArrowBackIosNew color="action" />
      </IconButton>
      <LabelTitle variant="body1">
        คำถามข้อที่ {nextQuestions} : {titleQuestion}
      </LabelTitle>
      <IconButton
        sx={{
          cursor: disabledNext ? 'not-allowed' : 'pointer',
        }}
        data-testid={`${type}-next-question`}
        onClick={() =>
          dispatch(
            !disabledNext &&
              handleChange({
                ...propsEvent,
                value: nextQuestions + 1,
              })
          )
        }
      >
        <ArrowForwardIos color="action" />
      </IconButton>
    </BoxFooter>
  )
}

export default Index
