import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Template from '../../../../../components/Template'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import { Row } from '../../Form/Styled'
import { StyledTypography } from './styled'
import { LikeIconButton } from '../components/LikeButton'
import {
  StyledHeaderImage,
  StyledTextBtn,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledTooltip,
} from './styled'

const Header = () => {
  const data = JSON.parse(localStorage.getItem('ELCollectionPreviewData'))
  const coverImage = _.get(data, 'coverImage', '')
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isNotSm) {
    return <TabletDesktopHeader data={data} coverImage={coverImage} />
  }

  return (
    <StyledHeaderWrapper>
      <Row sx={{ justifyContent: 'space-between' }}>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/collection')}
        >
          กลับ
        </StyledTextBtn>
        <Typography variant="body1b">Collection</Typography>
        <LikeIconButton />
      </Row>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
        />
      )}
      <StyledTypography variant="h6">
        {_.get(data, 'nameForBanner', '')}
      </StyledTypography>
    </StyledHeaderWrapper>
  )
}

export default Header

export const TabletDesktopHeader = ({ data, coverImage }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const name = _.get(data, 'nameForBanner', '')

  return (
    <StyledHeaderWrapper>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
        />
      )}
      <Template noMinHeight>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/collection')}
        >
          กลับ
        </StyledTextBtn>

        <StyledHeaderContainer>
          <Box>
            <Typography variant="h6" color="text.alabaster">
              Collection
            </Typography>
            <StyledTooltip title={name}>
              <StyledTypography variant={isLg ? 'h2' : 'h4'} color="text.white">
                {name}
              </StyledTypography>
            </StyledTooltip>
          </Box>
        </StyledHeaderContainer>
      </Template>
    </StyledHeaderWrapper>
  )
}
