import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxInputQuestion = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const BoxSelect = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
}))
