import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxFlexInput = styled(Box)(({ theme, paddingLeft }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(paddingLeft),
}))

export const BoxTextInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

export const BoxDesktop = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const BoxMobile = styled(Box)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
