import React from 'react'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const BoxDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: 1024,
    [theme.breakpoints.down('lg')]: { width: '90%' },
  },
}))
