import {
  setFieldValue,
  setStationList,
  setVendorList,
  setStockForm,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/inventory/stock/list'
import _ from 'lodash'
import { getStationFilterDefault } from '../../../../services/masterData/meetingRoom'
import {
  createStock,
  getStockDetail,
  updateStock,
} from '../../../../services/inventory'
import {
  loadingDialog,
  openDialog,
  closeDialog,
} from '../../../../redux/slices/dialog'
import { STOCK_STATUS } from '../../../../constants/stock'
import { getVendorFilterDefault } from '../../../../services/inventory'

export const fetchStationData = () => async (dispatch) => {
  const responseStation = await dispatch(getStationFilterDefault())
  const stationList = _.get(responseStation, 'payload', []).map((item) => ({
    label: item.station,
    uuid: item.uuid,
    value: item.uuid,
  }))
  dispatch(setStationList(stationList))
}

export const fetchVendorData = () => async (dispatch) => {
  const responseVendor = await dispatch(getVendorFilterDefault())
  const vendorList = _.get(responseVendor, 'payload', []).map((item) => ({
    label: item.name,
    uuid: item.uuid,
    value: item.uuid,
  }))
  dispatch(setVendorList(vendorList))
}

export const fetchStockDetail = (uuid, formik) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getStockDetail({ uuid }))
  const results = _.get(response, 'payload.data', {})
  const stockForm = {
    warehouseName: _.get(results, 'warehouseName', ''),
    station: _.get(results, 'station.uuid', ''),
    status: _.get(results, 'status', STOCK_STATUS.INACTIVE),
    warehouseType: _.get(results, 'warehouseType', ''),
    remark: _.get(results, 'remark', ''),
    vendor: _.get(results, 'vendor', ''),
  }
  formik.setFieldValue('warehouseName', stockForm.warehouseName)
  formik.setFieldValue('station', stockForm.station)
  formik.setFieldValue('status', stockForm.status)
  formik.setFieldValue('warehouseType', stockForm.warehouseType)
  formik.setFieldValue('remark', stockForm.remark)
  formik.setFieldValue('vendor', stockForm.vendor)
  dispatch(setStockForm(stockForm))
  dispatch(stopLoading())
}

export const resetStockData = (formik, warehouseType) => async (dispatch) => {
  dispatch(startLoading())
  const stockForm = {
    warehouseName: '',
    station: '',
    status: STOCK_STATUS.ACTIVE,
    warehouseType: warehouseType,
    remark: '',
    vendor: '',
  }
  formik.setFieldValue('warehouseName', stockForm.warehouseName)
  formik.setFieldValue('station', stockForm.station)
  formik.setFieldValue('status', stockForm.status)
  formik.setFieldValue('warehouseType', stockForm.warehouseType)
  formik.setFieldValue('remark', stockForm.remark)
  formik.setFieldValue('vendor', stockForm.vendor)
  dispatch(setStockForm(stockForm))
  dispatch(stopLoading())
}

export const handleChange = (key, value) => (dispatch) => {
  dispatch(setFieldValue({ key, value }))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}

export const getStateValue = (state, key, defaultValue = '') => {
  return _.get(state.eExamForm.body, key, defaultValue)
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const handleFormSubmit =
  (body, warehouseType, uuid, actions) => async (dispatch) => {
    dispatch(loadingDialog())
    let response
    if (uuid) {
      response = await dispatch(updateStock({ ...body, uuid }))
    } else {
      response = await dispatch(createStock({ ...body }))
    }
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
            window.location.href = `/inventory/stock`
          },
        }),
      )
    } else {
      let message = response?.meta?.response?.data?.message
      if (message === 'Error : คลังสินทรัพย์ซ้ำ') {
        message = 'ชื่อคลังทรัพย์สินนี้ถูกใช้แล้ว'
      }
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: message,
          handleError: () => {
            actions.setErrors({
              warehouseName:
                'ชื่อคลังทรัพย์สินนี้ถูกใช้แล้ว กรุณาตั้งชื่อคลังทรัพย์สินใหม่',
            })
            dispatch(closeDialog())
          },
        }),
      )
    }
  }
