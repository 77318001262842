import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect } from 'react'
import { TableCustom } from '../MaterialSettingForm/Styled'
import _ from 'lodash'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import {
  handleChangeIsDownload,
  handleChangeRemove,
  handleInitialForm,
} from './event'

const Index = ({ initialValues, setInitialValues, formik }) => {
  const [electronic, setElectronic] = useState([])
  useEffect(() => {
    handleInitialForm(initialValues, setElectronic, formik)
  }, [initialValues])

  return (
    <>
      {!_.isEmpty(electronic) && (
        <>
          <Typography variant="body1b" color="text.primary">
            Electronic file
          </Typography>
          <TableCustom aria-label="docs_electronic_table">
            <TableHead>
              <TableRow>
                <TableCell>รายการ</TableCell>
                <TableCell>ประเภท</TableCell>
                <TableCell align="center">อนุญาตผู้เรียนดาวน์โหลด</TableCell>
                <TableCell>คำสั่ง</TableCell>
              </TableRow>
            </TableHead>
            {_.map(electronic, (item, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                    variant="body2"
                    color={'text.primary'}
                  >
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={'text.primary'}>
                    {item.type}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    data-testid={`checkbox-${key}`}
                    checked={item.isDownload}
                    onClick={() =>
                      handleChangeIsDownload(
                        initialValues,
                        setInitialValues,
                        item.index
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    data-testid={`btn-view`}
                    color="primary"
                    component="span"
                    onClick={() => {
                      if (String(item.key).indexOf('/course') !== -1) {
                        window.open(
                          `${window.__env__.REACT_APP_API_URL}/file${item.key}`,
                          '_blank'
                        )
                      } else {
                        window.open(`${item.key}`, '_blank')
                      }
                    }}
                  >
                    <RemoveRedEyeTwoTone color="action" />
                  </IconButton>
                  <IconButton
                    data-testid={`btn-delete`}
                    color="primary"
                    component="span"
                    onClick={() =>
                      handleChangeRemove(
                        initialValues,
                        setInitialValues,
                        item.index
                      )
                    }
                  >
                    <DeleteTwoTone color="action" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableCustom>
        </>
      )}
    </>
  )
}

export default Index
