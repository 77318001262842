import { themeColor } from '../../../../../utils/themeData'

export const styleStatus = [
  {
    concatStatus: 'REQUEST_CREATE_WAITING',
    text: 'คลาสใหม่',
    style: {
      backgroundColor: themeColor.success.main,
      color: themeColor.common.white,
      border: `1px solid ${themeColor.success.main}`,
    },
    stlyeBackgroundBox: {
      backgroundImage: themeColor.monthlyPlan.successBackground,
    },
  },
  {
    concatStatus: 'REQUEST_EDIT_WAITING',
    text: 'มีการแก้ไข',
    style: {
      backgroundColor: themeColor.info.main,
      color: themeColor.common.white,
      border: `1px solid ${themeColor.info.main}`,
    },
    stlyeBackgroundBox: {
      backgroundImage: themeColor.monthlyPlan.infoBackground,
    },
  },
  {
    concatStatus: 'REQUEST_DELETE_WAITING',
    text: 'ขอยกเลิกคลาส',
    style: {
      backgroundColor: themeColor.error.main,
      color: themeColor.common.white,
      border: `1px solid ${themeColor.error.main}`,
    },
    stlyeBackgroundBox: {
      backgroundImage: themeColor.monthlyPlan.errorBackground,
    },
  },
  {
    concatStatus: 'ACTIVE_APPROVED',
    text: 'อนุมัติแล้ว',
    style: {
      backgroundColor: themeColor.common.white,
      color: themeColor.primary.main,
      border: `1px solid ${themeColor.primary.main}`,
    },
    stlyeBackgroundBox: {},
  },
  {
    concatStatus: 'WAITING_APPROVE_BORROW',
    text: 'รออนุมัติวิทยากร',
    style: {
      backgroundColor: themeColor.warning.main,
      color: themeColor.common.white,
      border: themeColor.warning.main,
    },
    stlyeBackgroundBox: {
      backgroundImage: themeColor.monthlyPlan.waitingApproveBackground,
    },
  },
]
