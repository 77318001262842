import { setSharePropValue } from '../../../../../redux/slices/manageClassDetail'

export const onCloseDrawer = (index) => (dispatch) => {
  const payload = { index, key: 'isOpen', value: false }
  dispatch(setSharePropValue(payload))
}

export const onClickShare = (e, index) => (dispatch) => {
  const payload = { index, key: 'isShare', value: e.target.checked }
  dispatch(setSharePropValue(payload))
}
