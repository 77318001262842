import React from 'react'

import CardQuestion from '../CardQuestion'
import Content from '../Content'

const Index = () => {
  return (
    <>
      <CardQuestion text="แบบประเมินเนื้อหาหลักสูตร" type="courseAssessments" />
      <Content type="courseAssessments" />
    </>
  )
}

export default Index
