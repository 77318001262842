import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import styled from '@mui/system/styled'

export const StyledMainTabs = styled(Tabs)(({ theme }) => ({
  height: 48,
  '& .MuiTabs-indicator': { height: 4 },
  '& .MuiTabScrollButton-root': {
    '&.Mui-disabled': { opacity: 1, color: theme.palette?.action?.disabled },
  },
}))

export const StyledMainTab = styled(Tab)(({ theme }) => ({
  width: 180,
  height: 48,
  padding: theme.spacing(1),
  lineHeight: '16px',
  color: theme?.palette?.text?.primary,
  span: {
    textTransform: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  '&.Mui-selected': { fontWeight: 700, color: theme?.palette?.text?.primary },
  '&:not(.Mui-selected)': { fontWeight: 400 },
}))
