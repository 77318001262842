import React from 'react'
import { StyledFormCard } from '../../Styled'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import { Typography } from '@mui/material'

const Permission = () => {
  return (
    <StyledFormCard>
      <Typography variant="h6">อนุญาตการดาวน์โหลดเนื้อหา</Typography>
      <CustomSwitchInLine title="" fieldName="isDownload" />
    </StyledFormCard>
  )
}
export default Permission
