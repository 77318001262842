import { setHandleChange } from '../../../../../redux/slices/table'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'
import _ from 'lodash'

export const handlePersistFilter = (
  defaultFilter,
  filterStateBelt,
  isOverview = false,
) => {
  const isDate = _.isDate(filterStateBelt.startDate)
  const isTestDate = _.isDate(filterStateBelt.startTestDate)
  const updatedPersistState = {
    ...defaultFilter,
    startDateChecked: isDate ? filterStateBelt.startDateChecked : false,
    startDateDisplayDateChecked: isDate,
    startDate: filterStateBelt.startDate,
    endDate: filterStateBelt.endDate,
    startTestDateChecked: isTestDate
      ? filterStateBelt.startTestDateChecked
      : false,
    startTestDateDisplayDateChecked: isTestDate,
    startTestDate: filterStateBelt.startTestDate,
    endTestDate: filterStateBelt.endTestDate,
  }
  let countFilter = 0
  if (isDate && filterStateBelt.startDateChecked) {
    countFilter += 1
  }
  if (isTestDate && filterStateBelt.startTestDateChecked && !isOverview) {
    countFilter += 1
  }
  if (
    filterStateBelt.startDateChecked === true ||
    filterStateBelt.startTestDateChecked === true
  ) {
    store.dispatch(
      setHandleChange({
        key: `filterState`,
        value: updatedPersistState,
      }),
    )
    if (filterStateBelt.startDateChecked === true) {
      store.dispatch(
        setHandleChange({
          key: `filter.filterProp.startDate`,
          value: filterStateBelt.startDate,
        }),
      )
      store.dispatch(
        setHandleChange({
          key: `filter.filterProp.endDate`,
          value: filterStateBelt.endDate,
        }),
      )
    }
    if (filterStateBelt.startTestDateChecked === true) {
      store.dispatch(
        setHandleChange({
          key: `filter.filterProp.startTestDate`,
          value: filterStateBelt.startTestDate,
        }),
      )
      store.dispatch(
        setHandleChange({
          key: `filter.filterProp.endTestDate`,
          value: filterStateBelt.endTestDate,
        }),
      )
    }
    store.dispatch(
      setFieldValue({
        key: 'filterStateBelt',
        value: updatedPersistState,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filter.filterTotal',
        value: countFilter,
      }),
    )
  } else {
    store.dispatch(setFieldValue({ key: 'filterStateBelt', value: {} }))
    store.dispatch(
      setHandleChange({ key: 'filterState', value: defaultFilter }),
    )
  }
}
