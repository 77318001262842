import { questionTypeString } from '../../../../../../constants/eTesting'

export const countScore = (questions) => {
  let result = 0
  if (questions.length) {
    questions.filter((item) => {
      if (
        item.questionType === questionTypeString.MULTIPLE ||
        item.questionType === questionTypeString.SINGLE ||
        item.questionType === questionTypeString.FREE_TEXT
      ) {
        result += Number(item.score)
      } else if (item.questionType === questionTypeString.TRUE_FALSE) {
        for (const ans of item.answers) {
          result += Number(ans.score)
        }
      } else if (
        item.questionType === questionTypeString.SEQUENCE ||
        item.questionType === questionTypeString.FILL_IN_BLANK_CHOICE
      ) {
        result += Number(item.score) * item.answers.length
      } else if (item.questionType === questionTypeString.MATCHING) {
        const findAns = item.answers.filter((ans) => ans.isCorrect === true)
        result += Number(item.score) * findAns.length
      } else if (item.questionType === questionTypeString.FILL_IN_BLANK_DRAG) {
        const findAns = item.answers.filter((ans) => ans['target'] !== null)
        result += Number(item.score) * findAns.length
      } else {
        result += Number(item.score)
      }
    })
  }

  return result.toFixed(2)
}
