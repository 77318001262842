import styled from '@mui/system/styled'
import Box from '@mui/material/Box'

export const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '77%',
  gap: theme.spacing(1.5),
}))
export const StyledImageContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '23%',
}))

export const StyledContentRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1.5),
  gap: theme.spacing(3),
  '& > .MuiTypography-root#label': { width: 300, minWidth: 300 },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
