import { Checkbox, FormControlLabel } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { handleChange } from './events'

const ListSubCheckbox = ({ listKey }) => {
  const { displayFilters, stationOptions } = useSelector(
    (state) => ({
      displayFilters: state.monthlyPlan.dashboard.displayFilters,
      stationOptions: state.monthlyPlan.dashboard.displayFilters.stationOptions,
    }),
    shallowEqual,
  )

  return _.map(stationOptions, (item, index) => (
    <FormControlLabel
      key={index}
      label={item.label}
      control={
        <Checkbox
          inputProps={{ 'data-testid': `check-${item.name}` }}
          name={item.name}
          checked={
            !_.isUndefined(displayFilters[listKey][item?.name])
              ? displayFilters[listKey][item?.name]
              : false
          }
          onChange={(e) => {
            handleChange({
              value: e.target.checked,
              key: e.target.name,
              listKey: listKey,
            })
          }}
        />
      }
    />
  ))
}
export default ListSubCheckbox
