import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const openStockDrawer = (
  value,
  item = null,
  assetsIndex = null,
  isView = false,
) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenFormDrawer',
      value: value,
    }),
  )
  if (item) {
    const remark = isView ? _.get(item, `remark`, '') : ''
    const img = isView ? _.get(item, `img`, '') : ''
    store.dispatch(
      setFieldValue({
        key: `formData.drawer`,
        value: {
          assetsIndex: assetsIndex,
          assetName: _.get(item, `label`, ''),
          assetCode: _.get(item?.assetCode, `label`, ''),
          remark: remark,
          img: img,
          reasonNotReturnFile: img,
          isView: isView,
        },
      }),
    )
  }
}
