import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../../../../components/redux/Table'
import { LoadingCircular, StyledCard } from '../../../../../../modules/ELearning/Styled'
import { fetchDataList } from './events'
import FilterDrawer from './FilterDrawer'

const Learner = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page,
        status,
        filterProp,
        keyword: searchText,
        setInitial,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page,
          status,
          filterProp,
          keyword: searchText,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <StyledCard sx={{ mt: 0, boxShadow: 'unset' }} id="listTable">
        <Table />
      </StyledCard>

      <FilterDrawer open={isFilterDrawer} table={table} />

      <LoadingCircular isLoading={isLoading} />
    </>
  )
}
export default Learner
