import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import Close from '@mui/icons-material/Close'

import { CancelUpload, ImageQuestion, BoxImageQuestionAnswer } from './Styled'
import { handelDelete } from './event'

const Index = (props) => {
  const { isAnswer, uploadState, keyState, value, isMulti, disableForm } = props
  const { loadingProgress, uploadError } = uploadState
  const isError = !_.isEmpty(uploadError)
  const dispatch = useDispatch()

  if (loadingProgress >= 0 && loadingProgress < 100 && !isError) {
    return (
      <BoxImageQuestionAnswer isAnswer={isAnswer} isLoading>
        <Box>
          <UploadFileOutlined />
          <LinearProgress variant="determinate" value={loadingProgress} />
        </Box>
      </BoxImageQuestionAnswer>
    )
  } else {
    return (
      <>
        <BoxImageQuestionAnswer
          isAnswer={isAnswer}
          isError={isError}
          isMulti={isMulti}
        >
          {!disableForm && (
            <CancelUpload
              data-testid="cancel-upload"
              onClick={() => dispatch(handelDelete({ ...keyState, value: '' }))}
            >
              <Close sx={{ fontSize: '16px' }} />
            </CancelUpload>
          )}
          {value && (
            <ImageQuestion
              src={`${window.__env__.REACT_APP_API_URL}/file${value}`}
            />
          )}
          {isError && (
            <Box>
              <UploadFileOutlined />
              <Typography variant="buttons">ผิดพลาด</Typography>
            </Box>
          )}
        </BoxImageQuestionAnswer>
        {isError && (
          <Typography
            sx={{ mt: 1, ml: 6.5, mb: isAnswer ? 2 : 0 }}
            color="error"
            variant="body2"
          >
            อัพโหลดรูปภาพไม่สำเร็จ ลองใหม่อีกครั้ง
          </Typography>
        )}
      </>
    )
  }
}
export default Index
