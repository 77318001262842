export const formatData = (field) => {
  let text = ''
  switch (field) {
    case 'DONE':
      text = 'ทำแล้ว'
      break
    case 'NOT_YET_DONE':
      text = 'ยังไม่ได้ทำ'
      break
  }
  return text
}
