import React from 'react'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import { fieldSelector } from '../../../../../../redux/slices/eLearning/learningPathForm'
import DragDropContent from '../../../../components/DragDropContent'
import CircularContent from '../../../../components/CircularContent'
import { StyledFormCard } from '../../../../Styled'
import CreateCourseButton from './CreateCourseButton'
import CourseForm from './CourseForm'
import { StyledSmallButton } from './Styled'
import { handleAddItem, handleDragEnd } from './events'

const Course = () => {
  const pathCourseKey = 'eLearningLearningPathCourses'
  const dispatch = useDispatch()
  const isOptionLoading = useSelector(
    (state) => state.eLearningPathForm.isOptionLoading,
    shallowEqual,
  )

  return (
    <StyledFormCard sx={{ gap: 3, alignItems: 'flex-start' }}>
      {isOptionLoading && <CircularContent />}
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h6">เพิ่มหลักสูตรใน Learning path </Typography>
        <Typography variant="h6" color="error.main">
          *
        </Typography>
      </Box>

      <DragDropLessonList pathCourseKey={pathCourseKey} />

      <Box sx={{ display: 'flex', gap: 3 }}>
        <StyledSmallButton
          variant="outlined"
          startIcon={<Add />}
          onClick={() => dispatch(handleAddItem(pathCourseKey))}
        >
          เพิ่มหลักสูตร
        </StyledSmallButton>

        <CreateCourseButton />
      </Box>
    </StyledFormCard>
  )
}

export default Course

export const DragDropLessonList = ({ pathCourseKey }) => {
  const pathCourses = useSelector(fieldSelector(pathCourseKey), shallowEqual)
  const dispatch = useDispatch()
  const { setErrors } = useFormikContext()

  return (
    <DragDropContent
      id="course-list"
      onDragEnd={(result) =>
        dispatch(handleDragEnd(result, pathCourseKey, setErrors))
      }
    >
      {pathCourses.map((id, index) => (
        <Draggable
          index={index}
          key={`course-${id}`}
          draggableId={`course-${id}`}
        >
          {(provided) => (
            <CourseForm
              provided={provided}
              index={index}
              pathCourseKey={pathCourseKey}
            />
          )}
        </Draggable>
      ))}
    </DragDropContent>
  )
}
