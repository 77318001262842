import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import { Box, Typography } from '@mui/material'
import InfoPreview from '../../../../../../../components/InfoPreview'
import BarChart from '../../../../../../../components/Chart/BarChart'
import { useTheme } from '@mui/system'
import { StyledDashboardCard } from '../Styled'
import { handleOpenLearnerDrawer } from '../events'
import { E_LEARNING_LEARNER_TYPE } from '../../../../../../../constants/eLearning'
import { validatePermission } from '../../../../../../../utils/lib'

const LearnerStatusChart = () => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const hasAdminPermission = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN'],
  })
  const { totalLearner, notStarted, inProgress, completed } = useSelector(
    (state) => ({
      totalLearner: state.eLearningDashboardByCourse.totalLearner,
      notStarted: state.eLearningDashboardByCourse.notStarted,
      inProgress: state.eLearningDashboardByCourse.inProgress,
      completed: state.eLearningDashboardByCourse.completed,
    }),
    shallowEqual,
  )

  const labels = ['Not started', 'In progress', 'Completed']
  const datasets = {
    data: [notStarted, inProgress, completed],
    backgroundColor: [
      theme.palette.graph.grey1,
      theme.palette.graph.blue3,
      theme.palette.graph.green2,
    ],
    categoryPercentage: 0.5,
  }

  return (
    <StyledDashboardCard
      sx={{
        width: '100%',
        height: 484,
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <Typography variant="h6">ผู้เรียน Enroll ทั้งหมด</Typography>
      <Box display="flex" justifyContent="space-between">
        <InfoPreview
          label="ทั้งหมด"
          count={totalLearner}
          unit="คน"
          bgButtonColor={'#4976BA14'}
          handleClick={() =>
            handleOpenLearnerDrawer(E_LEARNING_LEARNER_TYPE.ALL)
          }
          hideButton={!hasAdminPermission}
        />
        <img
          style={{
            position: 'absolute',
            right: 50,
            top: -80,
          }}
          width={222}
          height={280}
          src="/image/dashboard_decoration.svg"
        />
      </Box>

      <BarChart dataSet={datasets} labels={labels} />
    </StyledDashboardCard>
  )
}

export default LearnerStatusChart
