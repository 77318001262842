import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Check from '@mui/icons-material/Check'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import useLocalStorage from '@rehooks/local-storage'

import { setOnView } from '../../../../../redux/slices/table'
import {
  handleApproveLearner,
  handleNotApprove,
} from '../../../../../modules/ELearning/Course/Form/BatchTab/Learner/LearnerRekognition/RekognitionDialog/events'
import { StyledBox } from '../Styled'
import { handleDeleteDialog, handleIsCanDelete } from './events'
import { StyledTooltip } from './Styled'

const ActionBatchLearner = ({ row, rowIndex, condition }) => {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const history = useHistory()
  const viewPath = _.get(condition, 'viewPath', '')
  const viewUrl = `${viewPath}/${row.uuid}`
  const canDelete = handleIsCanDelete(row, user)
  const uuid = _.get(row, 'uuid', '')
  const isChecked = _.get(row, 'isChecked', '')
  const isWaitingAnnounce = _.get(row, 'status', '') === 'WAITING_ANNOUNCE'

  return (
    <StyledBox minWidth={160}>
      {row.canView && (
        <StyledTooltip title="ดูรายละเอียด">
          <IconButton
            data-testid={`btn-view-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(setOnView({ history, viewUrl }))}
          >
            <RemoveRedEyeTwoTone color="action" />
          </IconButton>
        </StyledTooltip>
      )}

      {row.canDelete && canDelete && (
        <StyledTooltip title="ลบ">
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(handleDeleteDialog(uuid))}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        </StyledTooltip>
      )}
      {isWaitingAnnounce && (
        <>
          <StyledTooltip title="ไม่อนุมัติ">
            <IconButton
              data-testid={`btn-delete-${rowIndex}`}
              color="primary"
              component="span"
              onClick={() => handleNotApprove(row)}
            >
              <Close color="action" />
            </IconButton>
          </StyledTooltip>

          {!isChecked && (
            <StyledTooltip title="อนุมัติ">
              <IconButton
                data-testid={`btn-delete-${rowIndex}`}
                color="primary"
                component="span"
                onClick={() => handleApproveLearner(uuid)}
              >
                <Check color="action" />
              </IconButton>
            </StyledTooltip>
          )}
        </>
      )}
    </StyledBox>
  )
}

export default ActionBatchLearner
