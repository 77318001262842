import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as apiPath from '../../utils/apiPath'
import { setChange } from '../../redux/slices/eCertificateForm'
import { mapDataOptions } from '../eTesting/form'

export const getFormDetailECertificate = createAsyncThunk(
  'eCertificateBackground/getFormDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiPath.eCertificationBackground}/${uuid}`
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getFormDetailVersion = createAsyncThunk(
  'eCertificateVersion/getFormDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiPath.eCertificationVersion}/${uuid}`
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLayoutBackgroundECertificateBackground = createAsyncThunk(
  'eCertificateBackground/getLayoutBackground',
  async (layoutOption, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiPath.eCertificationBackgroundLayout}?limit=${layoutOption.limit}&page=${layoutOption.page}`
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getListCertificateBackground = createAsyncThunk(
  'eCertificate/getList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiPath.eCertificationBackgroundFilter}`,
        body
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadFileCertificateBackground = createAsyncThunk(
  'eCertificateBackground/uploadFileImage',
  async (payload, { rejectWithValue, dispatch }) => {
    const { body, key } = payload

    try {
      const result = await axios.post(
        apiPath.eCertificationBackgroundUploadFile,
        body,
        {
          onUploadProgress: (progressEvent) =>
            dispatch(handleUploadFileProgress(progressEvent, key)),
        }
      )
      return result
    } catch (err) {
      const errorProps = {
        key: 'uploadError',
        value: err.response.status,
      }

      dispatch(setChange(errorProps))

      return rejectWithValue([], err)
    }
  }
)

export const handleUploadFileProgress = (progressEvent, key) => (dispatch) => {
  let totalLength = progressEvent.total
  if (!progressEvent.lengthComputable) {
    totalLength =
      progressEvent.target.getResponseHeader('content-length') ||
      progressEvent.target.getResponseHeader('x-decompressed-content-length')
  }

  if (totalLength !== null) {
    let loadingProgress = Math.round((progressEvent.loaded * 100) / totalLength)

    const propsValue = { key, value: loadingProgress }
    const propsError = { key: 'uploadError', value: undefined }

    dispatch(setChange(propsValue))
    dispatch(setChange(propsError))
  }
}

export const submitFormCertificateVersion = createAsyncThunk(
  'eCertificate/submitFormVersion',
  async (body, { rejectWithValue }) => {
    try {
      let result = {}
      if (body.uuid && !body.clone) {
        result = await axios.put(apiPath.eCertificationVersion, body)
      } else {
        result = await axios.post(apiPath.eCertificationVersion, body)
      }
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const submitFormCertificateBackground = createAsyncThunk(
  'eCertificate/submitForm',
  async (body, { rejectWithValue }) => {
    try {
      let result = {}
      if (body.uuid) {
        result = await axios.put(apiPath.eCertificationBackground, body)
      } else {
        result = await axios.post(apiPath.eCertificationBackground, body)
      }
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const deleteCertificateBackground = createAsyncThunk(
  'eCertificate/deleteCertificateBackground',
  async (uuid, { rejectWithValue }) => {
    try {
      const result = await axios.delete(
        apiPath.eCertificationBackground + `/${uuid}`
      )
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const deleteCertificateVersion = createAsyncThunk(
  'eCertificate/deleteCertificateVersion',
  async (uuid, { rejectWithValue }) => {
    try {
      const result = await axios.delete(
        apiPath.eCertificationVersion + `/${uuid}`
      )
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAssigneesECertification = createAsyncThunk(
  'eCertificate/assignees',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(apiPath.eCertificationAssignees)
      return {
        result: mapDataOptions(_.get(response, 'data.result', [])),
        totalCount: _.get(response, 'data.totalCount', 0),
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
