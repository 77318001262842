export const AGENT_PROFILE_SETTING = 'AGENT_PROFILE_SETTING'

export const PERMISSION = {
  VIEW: 'VIEW',
  MENU: 'MENU',
}

export const OWNER = {
  ALL: 'ALL',
}

export const permissionAgentProfileMenu = [
  {
    module: AGENT_PROFILE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.MENU],
  },
]

export const permissionAgentProfileView = [
  {
    module: AGENT_PROFILE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.VIEW],
  },
]
