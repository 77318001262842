import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { overviewEnum } from '../enum/enum'
import {
  getRoomManagementDashboard,
  getRoomManagementDashboardByTime,
} from '../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { roomManagementDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
    station: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.station,
      roomManagementDashboard?.defaultStation,
    ),
    order: 'desc',
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(getRoomManagementDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.overview',
          value: { ...overviewEnum, ...data },
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  await callAxios
    .post(getRoomManagementDashboardByTime, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.overview.meetingRoomList',
          value: data.meetingRoomList,
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.meetingRoomOrder',
          value: 'desc',
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
