import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import useTheme from '@mui/system/useTheme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StyledColumn } from '../Styled'
import TextInput from '../../../../../components/Input/TextInput'
import { handleChange } from '../../event'

const Index = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const { remark } = useSelector(
    (state) => state.masterDataMeetingRoomForm.information,
    shallowEqual
  )

  return (
    <StyledColumn sx={{ width: '100%', mt: theme.spacing(3) }}>
      <TextInput
        type="basic"
        name="remark"
        labelText={'Remark'}
        placeholder="กรุณากรอก"
        rows={3}
        multiline={true}
        value={remark}
        onChange={(e) => {
          const value = _.get(e, 'target.value', null)
          dispatch(
            handleChange({
              key: 'remark',
              value: !_.isEmpty(value) ? value : null,
            })
          )
        }}
        textError={_.get(errors, `information.remark`, '')}
      />
    </StyledColumn>
  )
}

export default Index
