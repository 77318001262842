import styled from '@mui/system/styled'

export const ButtonColorStyle = styled('button')(({ color, theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme?.palette?.other?.outlineBorder}`,
  minWidth: '60px',
  height: '40px',
  backgroundColor: `${color}`,
  cursor: 'pointer',
}))

export const ButtonPopperStyle = styled('div')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: `${theme?.palette?.primary?.main}`,
  cursor: 'pointer',
  zIndex: 1000,
}))
