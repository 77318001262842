import { TableCell } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import TableRowReport from './TableRowReport'
import { useTheme } from '@mui/system'
import { Fragment } from 'react'
import { COLUMN_NAME } from 'src/constants/report/monthlyPlan'

const DetailsReport = ({ details }) => {
  const theme = useTheme()
  const { headCellsReport } = useSelector(
    (state) => ({
      headCellsReport: state.monthlyPlan.report.headCellsReport,
    }),
    shallowEqual,
  )

  const isSummary = _.find(details, (dt) => _.includes(dt.type, ['Summary']))

  return _.map(details, (detail, idx) => {
    const key = _.get(detail, 'key', '')
    const isTotal = _.includes(key.toLowerCase(), 'total')
    const isSummaryTotal =
      key === COLUMN_NAME.SUMMARY_TOTAL ||
      key === COLUMN_NAME.SUMMARY_TOTAL_YEARLY
    const type = _.get(detail, 'type', undefined)
    const isSubTitle = !_.isUndefined(type) && type !== 'Summary Workload'

    return (
      <TableRowReport
        key={idx}
        sx={{
          '&:last-child': {
            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
          },
          '& .MuiTableCell-root': {
            backgroundColor: isSummaryTotal
              ? theme.palette.graph.yellow1Light
              : isTotal
              ? theme.palette.graph.grey1
              : isSubTitle
              ? theme.palette.graph.blue1Light
              : '',
          },
        }}
      >
        {_.map(headCellsReport, (header, idxH) => {
          const isFontBold =
            header.id === 'list' &&
            ((_.includes(key.toLowerCase(), 'sum') &&
              _.includes(
                [
                  'Training (Classroom / Online)',
                  'Academy Activity',
                  'Activity Support',
                  'Holiday',
                  'Leave',
                ],
                detail[header.id],
              )) ||
              (_.isUndefined(_.get(detail, 'key')) &&
                _.includes(['(Classroom)', '(Online)'], detail[header.id])))

          return (
            <Fragment key={idxH}>
              {detail[header.id] === 'Summary Workload' ? (
                <TableCell
                  sx={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    textAlign: 'center',
                    backgroundColor: theme.palette.blue.blueLine,
                    fontSize: theme.typography.body2b.fontSize,
                    fontWeight: theme.typography.body2b.fontWeight,
                  }}
                  rowSpan={details.length}
                >
                  {detail[header.id]}
                </TableCell>
              ) : (
                <TableCell
                  sx={{
                    py: 0,
                    px: 1,
                    display:
                      isSummary && _.isUndefined(detail[header.id])
                        ? 'none'
                        : 'table-cell',
                    fontSize:
                      isFontBold || isTotal
                        ? theme.typography.body2b.fontSize
                        : theme.typography.body2.fontSize,
                    fontWeight:
                      isFontBold || isTotal
                        ? theme.typography.body2b.fontWeight
                        : theme.typography.body2.fontWeight,
                  }}
                  key={idx}
                  align={header?.align}
                >
                  {detail[header.id]}
                </TableCell>
              )}
            </Fragment>
          )
        })}
      </TableRowReport>
    )
  })
}

export default DetailsReport
