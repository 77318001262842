import _ from 'lodash'
import { setChangeSetting } from '../../../../../redux/slices/eTestingForm'

export const checkEventDisplayQuestion = (questionResult) => {
  return _.get(questionResult, 'isOpen', false) &&
    _.get(questionResult, 'result', 'LATER') === 'NOW'
    ? true
    : false
}

export const checkEventTestResult = (checkTestResult) => (dispatch) => {
  if (!checkTestResult) return
  dispatch(
    setChangeSetting({
      key: 'testResult',
      value: 'LATER',
    })
  )
}
