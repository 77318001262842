import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  eTestingVersion,
  eTestingAssignees,
  eTestingUploadFile,
  eTestingDownloadTemplate,
  currentDate,
  eTestingHistory,
  eTestingDownloadResult,
  eTestingExaminers,
} from '../../utils/apiPath'
import {
  setChangeQuestionByKey,
  setChangeTestingInfo,
} from '../../redux/slices/eTestingForm'

export const getFormDetail = createAsyncThunk(
  'eTesting/getFormDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eTestingVersion}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAssignees = createAsyncThunk(
  'eTesting/assignees',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(eTestingAssignees)
      return {
        result: mapDataOptions(_.get(response, 'data.result', [])),
        totalCount: _.get(response, 'data.totalCount', 0),
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getExaminers = createAsyncThunk(
  'eTesting/examiners',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(eTestingExaminers)
      return {
        result: mapDataOptions(_.get(response, 'data.result', [])),
        totalCount: _.get(response, 'data.totalCount', 0),
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const mapDataOptions = (list) => {
  if (list.length <= 0) return []
  return list.map((item) => {
    return {
      ...item,
      label: `${item.firstNameTH} ${item.lastNameTH}`,
      value: item.uuid,
    }
  })
}

export const getCurrentDateServer = createAsyncThunk(
  'eTesting/currentDateServer',
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.get(currentDate)
      return _.get(response, 'data.today', null)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getHistory = createAsyncThunk(
  'eTesting/history',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(eTestingHistory + url)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const submitForm = createAsyncThunk(
  'eTesting/submitForm',
  async (body, { rejectWithValue }) => {
    try {
      let result = {}
      if (body.uuid) {
        result = await axios.put(eTestingVersion, body)
      } else {
        result = await axios.post(eTestingVersion, body)
      }
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadFileETesting = createAsyncThunk(
  'eTesting/uploadFileImage',
  async (payload, { rejectWithValue, dispatch }) => {
    const { body, keyQuestion, keyValue } = payload
    const keyLoading = handleKeyState(keyValue, 'loadingProgress')
    const keyError = handleKeyState(keyValue, 'uploadError')

    try {
      const keyProps = { keyQuestion, keyLoading, keyError }
      const resetProps = { keyQuestion, key: keyLoading, value: 1 }
      const resetErrorProps = { keyQuestion, key: keyError, value: undefined }
      // reset loading progress before upload
      if (keyQuestion != undefined) {
        dispatch(setChangeQuestionByKey(resetProps))
        dispatch(setChangeQuestionByKey(resetErrorProps))
      } else {
        dispatch(setChangeTestingInfo(resetProps))
        dispatch(setChangeTestingInfo(resetErrorProps))
      }

      const result = await axios.post(eTestingUploadFile, body, {
        onUploadProgress: (progressEvent) =>
          dispatch(handleUploadProgress(progressEvent, keyProps)),
      })
      return result
    } catch (err) {
      const errStatus = err.response?.status || 'network error'
      const errorProps = { keyQuestion, key: keyError, value: errStatus }
      if (!_.isUndefined(keyQuestion)) {
        dispatch(setChangeQuestionByKey(errorProps))
      } else {
        dispatch(setChangeTestingInfo(errorProps))
      }
      return rejectWithValue([], err)
    }
  }
)

export const handleKeyState = (keyValue, key) => {
  // question's keyValue = url
  // answer's keyValue ex. answer[3].mediaUrl
  const splitKey = keyValue.split('.')
  return splitKey.length > 1 ? `${splitKey[0]}.${key}` : key
}

export const handleUploadProgress = (progressEvent, keyProps) => (dispatch) => {
  const { keyQuestion, keyLoading, keyError } = keyProps
  let totalLength = progressEvent.total
  if (!progressEvent.lengthComputable) {
    totalLength =
      progressEvent.target.getResponseHeader('content-length') ||
      progressEvent.target.getResponseHeader('x-decompressed-content-length')
  }

  if (totalLength !== null) {
    let loading = Math.round((progressEvent.loaded * 100) / totalLength)
    const loadingProps = { keyQuestion, key: keyLoading, value: loading }
    const errorProps = { keyQuestion, key: keyError, value: undefined }

    if (keyQuestion != undefined) {
      dispatch(setChangeQuestionByKey(loadingProps))
      if (loading === 100) {
        dispatch(setChangeQuestionByKey(errorProps))
      }
    } else {
      dispatch(setChangeTestingInfo(loadingProps))
      if (loading === 100) {
        dispatch(setChangeTestingInfo(errorProps))
      }
    }
  }
}

export const downloadTemplate = createAsyncThunk(
  'eTesting/downloadTemplate',
  async (obj, { rejectWithValue }) => {
    try {
      const result = await axios({
        url: eTestingDownloadTemplate,
        method: 'get',
        responseType: 'blob', // Important
      })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Template_E_Testing.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          rejectWithValue([], err)
        })
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const deleteVersion = createAsyncThunk(
  'eTesting/deleteVersion',
  async (uuid, { rejectWithValue }) => {
    try {
      const result = await axios.delete(eTestingVersion + `/${uuid}`)
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const downloadResult = createAsyncThunk(
  'eTesting/downloadResult',
  async (uuid, { rejectWithValue }) => {
    try {
      const result = await axios({
        url: `${eTestingDownloadResult}/${uuid}`,
        method: 'get',
        responseType: 'blob', // Important
      })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `E_Testing_Result.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          rejectWithValue([], err)
        })
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
