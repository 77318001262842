import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxHeder = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const BoxGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}))

export const BoxSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))
