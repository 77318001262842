import { breadcrumbReportRoomManagement } from './model'
import dayjs from 'dayjs'

export const reportConfigAll = {
  moduleId: 'REPORT_ROOM_MANAGEMENT_ALL',
  modulePath: '/room-management',
  breadcrumb: breadcrumbReportRoomManagement(),
  downloadName: `Report ห้องประชุม ${dayjs().format('DDMMYYYY')}.xlsx`,
}

export const reportConfigPic = {
  moduleId: 'REPORT_ROOM_MANAGEMENT_PIC',
  modulePath: '/room-management',
  breadcrumb: breadcrumbReportRoomManagement(),
  downloadName: `Report ห้องประชุม ${dayjs().format('DDMMYYYY')}.xlsx`,
}
