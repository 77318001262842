import _ from 'lodash'
import * as reducerTable from '../../../../redux/slices/table'
import {
  setLearnerList,
  setStopLoading,
  setStartLoading,
} from '../../../../redux/slices/manageClassAttendance'
import {
  getLearnerList,
  postDataAttendance,
} from '../../../../services/attendance'
import { headCellsAttendanceList } from '../../../../constants/manageClass'
import { store } from '../../../../App'
import { getQueryParams, setMapListOptions } from '../../../../utils/lib'
import { revertDate } from '../Header/DropDownButton/ContentDialog/events'

export const fetchDataList = (props) => async (dispatch) => {
  const { page, table, uuid, filterProp } = props
  const date = getQueryParams('date')
  const round = getQueryParams('round')
  dispatch(setStartLoading())
  dispatch(reducerTable.startLoading())

  const { search } = store.getState().table
  const sortBy = table.sort
  const realPage = page <= 0 ? 1 : +page
  const body = {
    trainingPlanUuid: uuid,
    limit: Number(table.limit),
    page: realPage,
    order:
      sortBy === 'updatedAt' ? 'ASC' : table.order.toString().toUpperCase(),
    date: revertDate(date),
    round: Number(round),
    sort: sortBy === 'updatedAt' ? 'codeId' : sortBy,
    learnerName:
      _.get(filterProp, 'learnerName', '') || _.get(search, 'searchText', ''),
    status: _.get(filterProp, 'status', []),
  }

  const data = await Promise.all([
    dispatch(postDataAttendance(body)),
    dispatch(getLearnerList(uuid)),
  ])
  dispatch(
    reducerTable.setInitialTable({
      rows: mapDataTable(_.get(data, '[0].payload.result', [])),
      allCount: _.get(data, '[0].payload.totalCount', 0),
      headCells: headCellsAttendanceList,
      placeholder: 'ค้นหา',
      searchKey: 'name',
      isCheckBox: false,
      canFilter: true,
      handleSearch: (text) =>
        dispatch(
          handleQuickSearch({
            ...props,
            searchText: text,
          })
        ),
    })
  )

  dispatch(
    setLearnerList(
      setMapListOptions(_.get(data, '[1].payload.result', []), 'firstNameTH')
    )
  )
  dispatch(reducerTable.stopLoading())
  dispatch(setStopLoading())
}

export const handleQuickSearch = (props) => (dispatch) => {
  dispatch(reducerTable.setSearchText(props.searchText))
  dispatch(reducerTable.setFilterProp(null))
  dispatch(reducerTable.resetTablePage())
  dispatch(fetchDataList({ ...props, filterProp: {} }))
}

export const mapDataTable = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      uuid: _.get(item, 'learnerUuid', ''),
    }
  })
}

export const onFilterClick = (table, filter, uuid) => (dispatch) => {
  dispatch(reducerTable.setSearchText(''))

  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.attendChecked) {
      statusList.push('มาเรียน')
    }
    if (filter.status.notAttendChecked) {
      statusList.push('ไม่มาเรียน')
    }
    if (filter.status.waitCheckChecked) {
      statusList.push('รอเช็คชื่อ')
    }
    if (filter.status.absentChecked) {
      statusList.push('ขาดเรียน')
    }
  }

  const filterProp = {
    status: statusList,
    learnerName: filter.agentCodeChecked ? filter.agentCode : '',
  }
  dispatch(reducerTable.setFilterProp(filterProp))
  dispatch(reducerTable.setFilterTotal(filter))
  dispatch(reducerTable.resetTablePage())
  dispatch(fetchDataList({ page: 1, table, uuid, filterProp }))
}
