import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import * as Component from './Styled'
const QuickFilter = () => {
  const {
    onQuickFilter,
    filter: { quickFilter, quickFilterTitle },
  } = useSelector((state) => state.table2)
  return (
    <Component.Container>
      <Typography sx={{ minWidth: 90 }}>
        {quickFilterTitle ? quickFilterTitle : 'Quick Filters'}:
      </Typography>
      {quickFilter.map((item, index) => (
        <Component.FilterBox
          key={item.title}
          clicked={item.clicked}
          onClick={() => onQuickFilter(item, index)}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant="chip"
            sx={{ mr: item?.info ? '0px' : '12px', ml: '4px' }}
          >
            {item.title}
          </Typography>
          {item?.info && (
            <Tooltip
              title={<Typography variant="tooltip">{item.info}</Typography>}
              arrow
              placement="bottom"
            >
              <InfoOutlinedIcon
                sx={{
                  fontSize: 'inherit',
                  margin: '0px 3px',
                }}
              />
            </Tooltip>
          )}
          <Typography variant="badgeLabel">
            {item.count !== 0 ? item.count : ''}
          </Typography>
        </Component.FilterBox>
      ))}
    </Component.Container>
  )
}
export default QuickFilter
