import React, { useEffect, useState } from 'react'

import Breadcrumbs from '../../../../../../components/Breadcrumbs'
import { checkScrollWidth } from '../../handler/checkScrollWidth'
import { BoxHeader, BoxLabelTitle } from './Styled'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { breadcrumb } from '../../model/breadcrumb'

const Header = () => {
  const { uuid } = useParams()
  const [isScrolled, setScroll] = useState(false)
  const { eTestName, eTestVersion } = useSelector(
    (state) => ({
      eTestName: state.crud.eTestName,
      eTestVersion: state.crud.eTestVersion,
    }),
    shallowEqual,
  )
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
      setScroll(false)
    }
  }, [])

  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle>
        <Typography variant="h4">
          {uuid && `รายละเอียดแบบทดสอบ : ${eTestName}`}
        </Typography>
        {!isScrolled && <Breadcrumbs menuList={breadcrumb(eTestVersion)} />}
      </BoxLabelTitle>
    </BoxHeader>
  )
}

export default Header
