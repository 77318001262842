import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"
import _ from "lodash"

export const handleRemoveInsideContact = async (index) => {
    const { formData } = store.getState().crud
    const insideContacts = _.get(formData, 'insideContacts', [])
    const removedInsideContacts = insideContacts.filter((item, itemIndex) => index !== itemIndex)
    store.dispatch(setFieldValue({
        key: 'formData.insideContacts',
        value: removedInsideContacts,
    }))
}