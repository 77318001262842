import React, { useState } from 'react'
import _ from 'lodash'
import { isMediaWidthMoreThan } from '../../../../../../utils/lib'
import { Row } from '../ContentNoClass/Styled'
import MediaBox from './components/MediaBox'
import CourseTitle from './components/CourseTitle'
import CourseAssignee from './components/CourseAssignee'
import LearningPathTitle from './components/LearningPathTitle'
import CollectionTitle from './components/CollectionTitle'
import InProgressContent from './components/InProgressContent'
import ChipContainer from './components/ChipContainer'
import Content from './components/Content'
import {
  StyledWrapper,
  StyledCard,
  StyledContentBox,
  StyledTitleBox,
  StyledContainer,
} from './Styled'
import { SECTION_KEY } from './model'
import ClassroomTitle from './components/ClassroomTitle'

const ELearningCourseCard = ({ course, onGoing, isFullWidth, keySection }) => {
  const [isHover, setHover] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isInProgress = _.get(course, 'isInProgress', false)
  return (
    <StyledWrapper isFullWidth={isFullWidth}>
      {!isNotSm && <MediaBox course={course} />}
      <StyledCard
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <StyledContainer>
          {isNotSm && <MediaBox course={course} isHover={isHover} />}
          <StyledContentBox id="content">
            <StyledTitleBox id="title" isInProgress={isInProgress}>
              {keySection === SECTION_KEY.COURSE && (
                <>
                  <CourseTitle course={course} />
                  <CourseAssignee course={course} />
                </>
              )}
              {keySection === SECTION_KEY.LEARNING_PATH && (
                <LearningPathTitle course={course} />
              )}
              {keySection === SECTION_KEY.COLLECTION && (
                <CollectionTitle course={course} />
              )}

              {[
                SECTION_KEY.CLASSROOM,
                SECTION_KEY.VIRTUAL_CLASSROOM,
                SECTION_KEY.HYBRID_CLASS,
              ].includes(keySection) && (
                <ClassroomTitle course={course} keySection={keySection} />
              )}

              {isInProgress && onGoing ? (
                <Row id="progress">
                  <InProgressContent course={course} />
                </Row>
              ) : (
                <ChipContainer course={course} />
              )}

              <Content
                course={course}
                isOnGoing={onGoing}
                keySection={keySection}
              />
            </StyledTitleBox>
          </StyledContentBox>
        </StyledContainer>
      </StyledCard>
    </StyledWrapper>
  )
}

export default ELearningCourseCard
