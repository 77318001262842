import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const downloadFile = createAsyncThunk(
  'downloadFile',
  async ({ url, body, fileName, headers }, { rejectWithValue }) => {
    try {
      await axios
        .post(url, body, { responseType: 'blob', headers })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadGetFile = createAsyncThunk(
  'downloadGetFile',
  async ({ url, fileName }, { rejectWithValue }) => {
    try {
      await axios.get(url, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadFileTypeGet = createAsyncThunk(
  'downloadFile',
  async ({ url, fileName }, { rejectWithValue }) => {
    try {
      await axios.get(url, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uniqId = () => {
  const array = new Uint32Array(1)
  crypto.getRandomValues(array)
  const randomValue = array[0]

  return randomValue.toString(16)
}

export function addEclipseToFilename(filename) {
  if (filename.length <= 40) return filename
  const totalLength = 40
  const word = '...'
  let extension = ''
  let nameWithoutExtension = filename

  let indexOfOtherwiseLast = filename.lastIndexOf('.')
  if (indexOfOtherwiseLast > -1) {
    extension = filename.substring(indexOfOtherwiseLast)
    nameWithoutExtension = filename.substring(0, indexOfOtherwiseLast)
  }

  let remainingWouldBeHalfLength = totalLength - extension.length - word.length

  if (nameWithoutExtension.length > remainingWouldBeHalfLength) {
    nameWithoutExtension = nameWithoutExtension.substring(
      0,
      remainingWouldBeHalfLength,
    )
  }

  let middleIndex = Math.floor(nameWithoutExtension.length / 2)

  return (
    nameWithoutExtension.substring(0, middleIndex) +
    word +
    nameWithoutExtension.substring(middleIndex) +
    extension
  )
}
