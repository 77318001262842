import {
  StyledDrawer,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../components/FilterDrawer/Styled'
import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { requestType } from './constanst'
import { handleCloseDrawerFilterCalendar } from '../../event'
import { CheckboxFilterRequestType } from './CheckboxFilterRequestType'
import { CheckboxRequestType } from './CheckboxRequestType'
import { CheckboxFilterTopic } from './CheckboxFilterTopic'
import { TextFieldTopic } from './TextFieldTopic'
import { FilterDrawerFooter } from './FilterDrawerFooter'
import { CheckboxFilterRoomName } from './CheckboxFilterRoomName'
import { TextFieldRoomName } from './TextFieldRoomName'
import { CheckboxFilterStation } from './CheckboxFilterStation'
import { CheckboxStation } from './CheckboxStation'

export const FilterDrawer = () => {
  const dispatch = useDispatch()
  const {
    isDrawerCalendarFilterOpen,
    isFilterRoomName,
    isFilterStation,
    isFilterBookingType,
    isFilterTopic,
    calendarPage,
    stationList,
  } = useSelector(
    (state) => ({
      isDrawerCalendarFilterOpen:
        state.roomManagement.isDrawerCalendarFilterOpen,
      isFilterRoomName:
        state.roomManagement.drawerCalendarFilter.isFilterRoomName,
      isFilterStation:
        state.roomManagement.drawerCalendarFilter.isFilterStation,
      isFilterBookingType:
        state.roomManagement.drawerCalendarFilter.isFilterBookingType,
      isFilterTopic: state.roomManagement.drawerCalendarFilter.isFilterTopic,
      calendarPage: state.roomManagement.calendarPage,
      stationList: state.roomManagement.stationList,
    }),
    shallowEqual,
  )
  return (
    <StyledDrawer
      open={isDrawerCalendarFilterOpen}
      onBackdropClick={() => dispatch(handleCloseDrawerFilterCalendar())}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={() => dispatch(handleCloseDrawerFilterCalendar())}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      {calendarPage === 'dashboard' && (
        <>
          <StyledGroupBox sx={{ border: 'none' }}>
            <FormGroup sx={{ width: '100%' }}>
              <CheckboxFilterRoomName />
              {isFilterRoomName && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextFieldRoomName />
                </Box>
              )}
              <Divider />
            </FormGroup>
          </StyledGroupBox>
          <StyledGroupBox sx={{ border: 'none' }}>
            <FormGroup sx={{ width: '100%' }}>
              <CheckboxFilterStation />
              {isFilterStation && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {stationList.map((stationListItem, ind) => (
                    <CheckboxStation
                      key={ind}
                      stationListItem={stationListItem}
                    />
                  ))}
                </Box>
              )}
              <Divider />
            </FormGroup>
          </StyledGroupBox>
        </>
      )}
      <StyledGroupBox sx={{ border: 'none' }}>
        <FormGroup sx={{ width: '100%' }}>
          <CheckboxFilterRequestType />
          {isFilterBookingType && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {requestType.map(
                (requestTypeItem) =>
                  requestTypeItem.value !== 'HOLIDAY' && (
                    <CheckboxRequestType
                      key={requestTypeItem.value}
                      requestTypeItem={requestTypeItem}
                    />
                  ),
              )}
            </Box>
          )}
          <Divider />
        </FormGroup>
      </StyledGroupBox>
      {calendarPage !== 'dashboard' && (
        <StyledGroupBox sx={{ border: 'none' }}>
          <FormGroup sx={{ width: '100%' }}>
            <CheckboxFilterTopic />
            {isFilterTopic && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TextFieldTopic />
              </Box>
            )}
          </FormGroup>
        </StyledGroupBox>
      )}
      <FilterDrawerFooter />
    </StyledDrawer>
  )
}
