export const breadcrumbList = [
  { title: 'E-Examination', link: '/', pointer: false },
  { title: 'ตารางสอบ', link: '/', pointer: true },
]

export const breadcrumbFormList = ({ titleText }) => [
  { title: 'E-Examination', link: '/', pointer: true },
  { title: 'ตารางสอบ', link: '/e-examination/list', pointer: true },
  { title: titleText, link: '/form', pointer: false },
]

export const breadcrumbExaminerList = [
  { title: 'E-Examination', link: '/e-examination/list', pointer: true },
  { title: 'ตารางสอบ', link: '/e-examination/list', pointer: true },
  { title: 'รายชื่อผู้สอบ', link: '/form', pointer: false },
]

export const breadcrumbExaminerDetail = [
  { title: 'E-Examination', link: '/e-examination/list', pointer: true },
  { title: 'ตารางสอบ', link: `/e-examination/list`, pointer: true },
  { title: 'รายละเอียดผู้สอบ', link: '/form', pointer: false },
]

export const breadcrumbReport = [
  { title: 'E-Examination', link: '/', pointer: false },
  { title: 'รายงานหักบัญชี', link: '/', pointer: true },
]

export const breadcrumbExaminationView = [
  { title: 'E-Examination', link: '/e-examination/list', pointer: true },
  { title: 'ตารางสอบ', link: `/e-examination/list`, pointer: true },
  { title: 'รายละเอียดตารางสอบ', link: '/form', pointer: false },
]
