import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { productTypeList } from '../model/productTypeList'
import useTheme from '@mui/system/useTheme'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleChangeHeadCells } from '../handler/handleChangeHeadCells'

export const TabsSelectProductType = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { tabActive } = useSelector(
    (state) => ({
      tabActive: state.crud.tabActive,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setFieldValue({ key: 'tabActive', value: newValue }))
    handleChangeHeadCells(newValue)
  }

  return (
    <Box
      display="flex"
      mx={2}
      borderBottom={`1px solid ${theme.palette?.blue?.blueLine}`}
    >
      <Typography my="auto" variant="body1b">
        ประเภททรัพย์สิน :
      </Typography>
      <Tabs value={tabActive} onChange={handleChangeTab}>
        {_.map(productTypeList, (tab, idx) => (
          <Tab
            sx={{
              minWidth: 0,
              width: 'auto',
              textTransform: 'none',
              color: theme.palette?.primary?.main,
              px: 1,
              py: 0,
              '&.Mui-selected': {
                borderBottom: `${theme.spacing(0.5)} solid`,
                pt: 0.5,
                fontWeight: 700,
              },
            }}
            key={idx}
            label={tab.label}
          />
        ))}
      </Tabs>
    </Box>
  )
}
