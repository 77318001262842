import _ from 'lodash'
import { store } from '../../../../App'
import dayjs from 'dayjs'
import { callAPI, validateDate } from 'src/utils/lib'
import { monthlyPlanFilterSummarySecondView } from '../../../../utils/apiPath'
import { headCellsSecondView, listWorkTypeDataSecondView } from '../model'

export const fetchSummarySecondView = async () => {
  const {
    dashboard: { filters, table, workTypeData, searchText },
  } = store.getState().monthlyPlan

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
    courseCodeList,
    positionList,
  } = filters

  const url = monthlyPlanFilterSummarySecondView

  const body = {
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList?.length > 0 ? departmentList : defaultDepartment,
    station: stationList?.length > 0 ? stationList : defaultStation,
    courseCode: courseCodeList?.length > 0 ? courseCodeList : [],
    position: positionList?.length > 0 ? positionList : [],
    searchText,
  }

  const response = await callAPI({
    method: 'post',
    url,
    body,
  })

  const countMonth = _.get(response, 'countMonth', 1)

  const results = _.get(response, 'results', [])
  const filteredResults = _.filter(results, (res) => !_.isUndefined(res.no))

  const staffWorkTypeDataList = _.map(filteredResults, (res) => {
    const staffWorkTypeData = _.map(listWorkTypeDataSecondView, (li) => {
      return { ...li, value: res[li.key] }
    })
    return { title: res?.staffName, workTypeList: staffWorkTypeData }
  })

  const findAvgPerCountMonth = _.find(
    results,
    (res) => res.staffName === `AVG/${countMonth} Month`,
  )

  const workTypeListAVG = _.map(listWorkTypeDataSecondView, (li) => {
    return { ...li, value: findAvgPerCountMonth[li.key] }
  })

  return {
    table: {
      ...table,
      headCells: headCellsSecondView,
      details: _.get(response, 'results', []),
      allCount: filteredResults.length,
    },
    workTypeData: {
      ...workTypeData,
      workTypeList: listWorkTypeDataSecondView,
      workTypeListAVG,
      staffWorkTypeDataList,
    },
    countMonth,
  }
}
