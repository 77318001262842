import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
} from '@mui/material'
import { StyledList } from '../../../../components/FilterDrawer/Styled'
import DateRangeInput from '../../../../components/DateRangeInput'
import { setFilter } from '../../../../redux/slices/roomManagement/Dashboard'
import { ListResult } from './ListResult'
import { useDispatch } from 'react-redux'
import { handleChange, handleSelectDate } from '../event'
import _ from 'lodash'

export const ListCheckBox = ({ listArray, stateFilter, station }) => {
  const dispatch = useDispatch()
  return listArray.map((item, index) => {
    return (
      <>
        {item.type !== 'hidden' && (
          <Box
            sx={{ borderBottom: `1px solid rgba(204, 204, 204, 0.25)`, py: 1 }}
            key={index}
          >
            <FormGroup sx={{ px: 1 }}>
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    inputProps={{
                      'data-testid': `check-${item.nameCheckBox}`,
                    }}
                    name={item.nameCheckBox}
                    checked={stateFilter[item.nameCheckBox]}
                    onChange={(e) => {
                      dispatch(
                        handleChange({
                          value: e.target.checked,
                          key: e.target.name,
                          filter: stateFilter,
                        }),
                      )
                    }}
                  />
                }
                sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
              />
              {stateFilter[item.nameCheckBox] && (
                <>
                  {item.type == 'checkbox' && (
                    <StyledList sx={{ mx: 2 }}>
                      <ListResult
                        filter={stateFilter}
                        arrayResult={
                          item.nameInput == 'station' ? station : item.list
                        }
                        listKeys={item.nameInput}
                      />
                    </StyledList>
                  )}

                  {item.type == 'text' && (
                    <InputBase
                      sx={{ borderRadius: 2 }}
                      type="basic"
                      inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                      name={item.nameInput}
                      disabled={!stateFilter[item.nameCheckBox]}
                      placeholder={item.placeholder}
                      value={stateFilter[item.nameInput]}
                      onChange={(e) => {
                        dispatch(
                          handleChange({
                            key: e.target.name,
                            value: e.target.value,
                            filter: stateFilter,
                            setFilter,
                          }),
                        )
                      }}
                    />
                  )}

                  {item.type == 'dateRange' && (
                    <>
                      <DateRangeInput
                        placeholder={item.placeholder}
                        dateState={{
                          startDate: stateFilter[item.dateStateStart]
                            ? stateFilter[item.dateStateStart]
                            : new Date(),
                          endDate: stateFilter[item.dateStateFinish]
                            ? stateFilter[item.dateStateFinish]
                            : new Date(),
                          display: _.get(
                            stateFilter,
                            item.dateStateDisplay,
                            false,
                          ),
                          key: 'selection',
                        }}
                        onChange={(selectedDates) => {
                          dispatch(
                            handleSelectDate(selectedDates, stateFilter, item),
                          )
                        }}
                      />
                      {item?.list?.length > 0 && (
                        <StyledList sx={{ mx: 2 }}>
                          <ListResult
                            filter={stateFilter}
                            setFilter={setFilter}
                            arrayResult={
                              item.nameInput == 'station' ? station : item.list
                            }
                            listKeys={item.nameInput}
                          />
                        </StyledList>
                      )}
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </Box>
        )}
      </>
    )
  })
}
