import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'

import { BoxLabel } from '../Information'

const Index = () => {
  const information = useSelector(
    (state) => state.masterDataLicenseForm.initialState.information,
    shallowEqual
  )

  const status = _.get(information, 'status', '-')

  return (
    <>
      <Box sx={{ m: 3 }}>
        <BoxLabel
          labelLeft="สถานะ"
          labelRight={
            status == 'ACTIVE'
              ? 'เปิดใช้งาน'
              : status == 'DELETED'
              ? 'ถูกลบแล้ว'
              : 'ปิดใช้งาน'
          }
          status={status}
        />
        <BoxLabel
          labelLeft="วันที่สร้าง"
          labelRight={_.get(information, 'createdAt', '-')}
        />
        <BoxLabel
          labelLeft="สร้างโดย"
          labelRight={_.get(information, 'createdBy', '-')}
        />
        <BoxLabel
          labelLeft="วันที่แก้ไขล่าสุด"
          labelRight={_.get(information, 'updatedAt', '-')}
        />
        <BoxLabel
          labelLeft="แก้ไขล่าสุดโดย"
          labelRight={_.get(information, 'updatedBy', '-')}
        />
      </Box>
    </>
  )
}

export default Index
