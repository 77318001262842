import { Card } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: theme?.spacing(3),
  gap: theme?.spacing(3),
  marginTop: theme?.spacing(3),
  height: 'auto',
}))
