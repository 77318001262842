import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const eExaminerDetail = createSlice({
  name: 'eExaminerDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoading: (state) => {
      state.isLoadingDrawer = false
    },
    setExaminerDetail: (state, { payload }) => {
      state.eExaminerDetail = payload
    },
  },
})

export const { startLoading, stopLoading, setExaminerDetail } =
  eExaminerDetail.actions
export default eExaminerDetail.reducer
