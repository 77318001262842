import _ from 'lodash'
import { initialState } from '../../../redux/slices/monthlyPlan/model'
import { fetchDepartmentList } from './fetchDepartmentList'
import { fetchStationList } from './fetchStationList'
import { store } from '../../../App'
import { filtersListClassDashboard } from '../ClassDashboard/model'
import { filtersListWorkloadDashboard } from '../WorkloadDashboard/model'
import { fetchCourseCodeList } from './fetchCourseCodeList'
import { COLUMN_NAME } from 'src/constants/report/monthlyPlan'
import { checkPermissionHZ } from './checkPermissionHZ'

export const getDefaultFiltersDashboard = async () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const defaultStation = _.find(_.get(user, 'area', []), (a) => a.default)
  const departmentUuid = _.get(defaultStation, 'department.uuid')
  const departmentOptions = await fetchDepartmentList()
  const stations = await fetchStationList()
  const courseCodeOptions = await fetchCourseCodeList()

  const isPermissionHZ = checkPermissionHZ(user)

  const { name } = store.getState().monthlyPlan.dashboard

  let filtersList = []
  if (name === 'class') {
    filtersList = filtersListClassDashboard
  } else if (name === 'workload') {
    filtersList = filtersListWorkloadDashboard
  }

  if (isPermissionHZ) {
    filtersList = _.filter(
      filtersList,
      (filter) => filter.nameInput !== COLUMN_NAME.DEPARTMENT,
    )
  }

  return {
    ...initialState.dashboard.filters,
    defaultDepartment: [departmentUuid],
    departmentOptions,
    ...stations,
    courseCodeOptions,
    filtersList,
  }
}
