import {
  setChangeQuestionNotOtherByKey,
  setDeleteQuestionNotOther,
  setDragDropDefaultQuestion,
} from '../../../../../../../redux/slices/eEvaluationForm'

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeQuestionNotOtherByKey(props))
}

export const handleDelete = (props) => (dispatch) => {
  dispatch(setDeleteQuestionNotOther(props))
}

export const onDragEnd = (event, type) => (dispatch) => {
  const startIndex = event.source.index
  const endIndex = event.destination.index

  if (!event.destination) return
  if (endIndex === startIndex) return
  dispatch(
    setDragDropDefaultQuestion({
      startIndex,
      endIndex,
      type,
    })
  )
}
