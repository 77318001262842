import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleStartChange = (event, value) => {
  const { oldSelectedFrom } = store.getState().crud
  if (_.isNull(value)) {
    clearAssetData()
  } else {
    if (oldSelectedFrom === 'นำเข้าคลัง' && value?.label !== oldSelectedFrom) {
      // if import_module -> station -> clear
      clearAssetData()
    } else if (value?.label === 'นำเข้าคลัง') {
      // if station -> import_module -> clear
      clearAssetData()
    }
  }
  // fetchDestination(value.value)
}

const clearAssetData = () => {
  store.dispatch(
    setFieldValue({
      key: 'assetData',
      value: [],
    }),
  )
}
