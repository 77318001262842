import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import {
  getToDoListFilter,
  getToDoListSummary,
  getToDoListNotificationFilter,
  clearNotification,
} from './../../../services/toDolist'

const toDoList = createSlice({
  name: 'toDoList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadmoreLoading: (state) => {
      state.isLoadmoreLoading = true
    },
    stopLoadmoreLoading: (state) => {
      state.isLoadmoreLoading = false
    },
    setSummaryCount: (state, { payload }) => {
      state.summaryCount = payload
    },
    setDataList: (state, { payload }) => {
      state.data = payload
    },
    setNotification: (state, { payload }) => {
      state.notification = payload
    },
    clearNotificationCount: (state) => {
      state.notification.totalCount = 0
    },
    extraReducers: (builder) => {
      builder
        .addCase(getToDoListFilter.fulfilled, (state, action) => {
          state.data = _.get(action, 'payload', [])
          state.isLoading = false
        })
        .addCase(getToDoListFilter.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getToDoListFilter.rejected, (state, action) => {
          state.error = action.meta?.message
          state.isLoading = false
        })
        .addCase(getToDoListSummary.fulfilled, (state, { payload }) => {
          state.summaryCount = payload
        })
        .addCase(getToDoListSummary.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getToDoListSummary.rejected, (state, action) => {
          state.error = action.meta?.message
        })
        .addCase(
          getToDoListNotificationFilter.fulfilled,
          (state, { payload }) => {
            state.notification = payload
          }
        )
        .addCase(getToDoListNotificationFilter.rejected, (state, action) => {
          state.error = action.meta?.message
        })
        .addCase(clearNotification.fulfilled, (state) => {
          state.notification.totalCount = 0
        })
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadmoreLoading,
  stopLoadmoreLoading,
  setSummaryCount,
  setDataList,
  setNotification,
  clearNotificationCount,
} = toDoList.actions

export default toDoList.reducer
