import _ from 'lodash'
import axios from 'axios'
import {
  startLoading,
  stopLoading,
  setInitialTable,
} from '../../../../../../redux/slices/table'
import { postPointHistoryByUuid } from '../../../../../../utils/apiPath'
import {
  convertFormatDateTime,
  convertNumberWithComma,
} from '../../../../../../utils/lib'
import { USER_POINT_EVENT } from '../../../../../../constants/learningPoint'
import { historyHeadCells } from '../model'

export const fetchDataHistory = (props) => async (dispatch) => {
  const { uuid, table } = props
  dispatch(startLoading())
  const { page, limit } = table
  const newPage = page <= 0 ? 1 : page

  dispatch(
    setInitialTable({
      rows: [],
      allCount: 0,
      headCells: historyHeadCells,
      isCheckBox: false,
      isHideSearch: true,
    }),
  )

  const result = await axios.post(postPointHistoryByUuid, {
    userUuid: uuid,
    page: newPage,
    limit,
  })
  const data = _.get(result, 'data', undefined)
  if (data) {
    dispatch(
      setInitialTable({
        rows: prepareRowsData(data.results),
        allCount: data.totalCount,
        headCells: historyHeadCells,
        isCheckBox: false,
        isHideSearch: true,
      }),
    )
  }
  dispatch(stopLoading())
}

const prepareRowsData = (data) => {
  if (data.length <= 0) return []
  return data.map((item) => {
    let eventData = ''
    let pointData = convertNumberWithComma(item.point)
    if (item.event == USER_POINT_EVENT.INCREASE_FROM_ADMIN) {
      eventData = 'ผู้ดูแลระบบ'
      pointData = '+' + pointData
    } else if (item.event == USER_POINT_EVENT.DECREASE_FROM_ADMIN) {
      eventData = 'ผู้ดูแลระบบ'
      pointData = '-' + pointData
    } else if (item.event == USER_POINT_EVENT.INCREASE_FROM_COURSE) {
      eventData = item?.course?.englishName || item?.eLearningCourse?.name
      pointData = '+' + pointData
    } else {
      eventData = 'แลกรางวัล'
      pointData = '-' + pointData
    }
    return {
      event: eventData,
      historyPoint: pointData,
      updatedAt: convertFormatDateTime({
        value: item.updatedAt,
        type: 'dateTime',
      }),
      remark: item.remark || '-',
      point: item.point,
      learningPointRedeemHistory: item.learningPointRedeemHistory || null,
    }
  })
}
