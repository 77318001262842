import React from 'react'
import { breadcrumbReportMonthlyPlan, tabsList } from '../model'
import BasicTabs from '../../../../components/BasicTabs'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { handleChangeTab, onDownload } from '../events'
import { Box, Button, Typography } from '@mui/material'
import { FileDownloadOutlined, FilterListOutlined } from '@mui/icons-material'
import { setIsOpenFilterDrawer } from '../../../../redux/slices/reportComp'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

const Header = () => {
  const dispatch = useDispatch()
  const { tabActive, filters } = useSelector(
    (state) => ({
      tabActive: state.monthlyPlan.report.tabActive,
      filters: state.reportComp.filters,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">รายงาน Workload</Typography>
        <Box display="flex" gap={2}>
          <Button
            data-testid="btn-download"
            variant="text"
            startIcon={<FileDownloadOutlined />}
            onClick={() => onDownload()}
          >
            ดาวน์โหลด
          </Button>
          <Button
            data-testid={'btn-filter'}
            sx={{ height: 40, p: 1 }}
            variant="text"
            startIcon={<FilterListOutlined />}
            onClick={() => dispatch(setIsOpenFilterDrawer(true))}
          >
            ตัวกรอง {filters.length > 0 && `(${filters.length})`}
          </Button>
        </Box>
      </Box>
      <Breadcrumbs menuList={breadcrumbReportMonthlyPlan()} />
      <BasicTabs
        tabActive={tabActive}
        tabsList={tabsList}
        handleChangeTab={handleChangeTab}
      />
    </Box>
  )
}

export default Header
