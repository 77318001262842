import { crudConfig } from '../crudConfig'

export const headerCells = () => {
  return [
    {
      id: 'testerCode',
      label: 'รหัสผู้สอบ',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'ีuserProfileUuid',
      label: 'ชื่อผู้ทำแบบทดสอบ',
      hideSortIcon: true,
    },
    {
      id: 'score',
      label: 'คะแนน',
      disablePadding: false,
    },
    {
      id: 'result',
      label: 'ผลการสอบ',
      disablePadding: false,
    },
    {
      id: 'testTime',
      label: 'ช่วงเวลาทำแบบทดสอบ',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
    },
  ]
}
