import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const BoxTooltipAlert = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}))

export const IconInfo = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme?.palette?.warning?.main,
  cursor: 'pointer',
}))
