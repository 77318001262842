import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

import { StyledColumn, StyledRow } from '../Styled'

export const StyledFormContainer = styled(StyledColumn)(({ theme }) => ({
  paddingTop: theme.spacing(9.5),
  paddingBottom: theme.spacing(8),
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(17.5),
  },
}))

export const StyledSection = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    '& > .MuiBox-root:first-of-type': {
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
  },
}))

export const StyledSectionWithCarousel = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  gap: theme.spacing(3),
  '& .carousel-slider': { paddingBottom: theme.spacing(9) },
  '& .control-dots': { margin: '22px 0' },
  '& .carousel .slide iframe': { width: '100%', margin: 0 },
  '& .slide': {
    padding: `0 ${theme.spacing(3)}`,
    textAlign: 'left',
  },
  '& li.dot': {
    opacity: '1 !important',
    background: `${theme.palette?.text?.silver} !important`,
    boxShadow: 'unset !important',
  },
  '& li.dot.selected': {
    background: `${theme.palette?.blue?.darkIndigo} !important`,
  },
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .tox-statusbar': { display: 'none!important;' },
  '& .mce-content-body': { minHeight: 'unset' },
  '& .MuiDrawer-paper': {
    width: 1024,
    [theme.breakpoints.down('lg')]: { width: '100%' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
    [theme.breakpoints.down('sm')]: { width: 300 },
  },
}))

export const StyledContent = styled(Box)(({ theme, isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& p > span': { color: theme?.palette?.error?.main },
}))

export const StyledFooter = styled(StyledRow)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  background: theme?.palette?.background?.paper,
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  justifyContent: 'end',
  gap: theme.spacing(3),
  zIndex: 1000,
}))
