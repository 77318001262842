import _ from 'lodash'
import { getStateValue, handleChange } from '../../events'
import { timeSpanTypeOptions } from './model'

export const createOption = (label) => ({ label, value: label })

export const handleKeyDown =
  (event, inputValue, setInputValue) => (dispatch) => {
    if (!inputValue) return

    const tags = getStateValue('setting.tag', [])
    const newTags = [...tags, createOption(inputValue)]
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        dispatch(handleChange('setting.tag', newTags))
        setInputValue('')
        event.preventDefault()
    }
  }

export const handleDefaultList = (option, list) => {
  return _.map(list, (item) => {
    const listObj = option.find(
      (select) => select.value === item.uuid || select.value === item.value,
    )
    return listObj || { label: '', uuid: '' }
  })
}

export const handleDefaultSelect = (option, value) => {
  const objValue = option.find((item) => item.value === value)
  if (_.isEmpty(objValue)) return _.get(timeSpanTypeOptions, '[1].value', '')
  return objValue.value
}

export const handleTimeSpanOptions = (type) => {
  const amount = timeSpanTypeOptions.find(
    (option) => option.value === type,
  ).amount
  return Array(amount)
    .fill()
    .map((_, i) => ({ label: i + 1, value: i + 1 }))
}
