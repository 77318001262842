import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import { useTheme } from '@mui/system'
import { isMediaWidth } from '../../../../../../../utils/lib'
import { QRCodeTemplate } from '../../../../../../../components/QRCodeTemplate'

export const QRCodeContent = (course = { course: '' }) => {
  const theme = useTheme()
  const isMobile = isMediaWidth('xs')
  const { link, qrExpired } = useSelector(
    (state) => state.manageClassDetail.qrEnrollProps,
    shallowEqual,
  )

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: qrExpired ? 'white.main' : 'none',
      }}
    >
      <QRCodeTemplate
        id="show-qr-code"
        boxSx={{
          border: `1px solid ${theme?.palette?.silver?.primary}`,
        }}
        qrStyle={{ opacity: qrExpired ? 0.1 : 1 }}
        titleSx={{ mx: 3, mt: 1 }}
        size={isMobile ? 245 : 287}
        link={link}
        title="QR Code ลงทะเบียน"
        subtitle={course?.course}
      />
      {qrExpired && (
        <Typography
          sx={{
            position: 'absolute',
          }}
          variant="h4"
        >
          QR Code หมดอายุ
        </Typography>
      )}
    </Box>
  )
}
