import callAxios from '../../../../utils/baseService'
import { monthlyPlanFilterActivity } from '../../../../utils/apiPath'
import _ from 'lodash'
import { store } from '../../../../App'
import dayjs from 'dayjs'
import { validateDate } from 'src/utils/lib'

export const fetchActivity = async () => {
  const {
    dashboard: { academyActivityList, activitySupportList, filters },
  } = store.getState().monthlyPlan
  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
  } = filters

  const body = {
    station: stationList.length > 0 ? stationList : defaultStation,
    department: departmentList.length > 0 ? departmentList : defaultDepartment,
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  }
  return await callAxios
    .post(monthlyPlanFilterActivity, body)
    .then(({ data }) => {
      const {
        resultActivity = {},
        resultActivitySupport = [],
        totalActivities = 0,
        totalActivitySupport = 0,
        latestUpdated,
      } = data
      const updatedAcademyActivity = _.map(academyActivityList, (list) => {
        if (!_.isNil(resultActivity[`${list.key}`])) {
          return { ...list, count: resultActivity[`${list.key}`] }
        } else if (list.listName === 'Total') {
          return { ...list, count: totalActivities }
        }
        return list
      })

      if (!_.find(updatedAcademyActivity, (act) => act.listName === 'Total')) {
        updatedAcademyActivity.push({
          listName: 'Total',
          count: totalActivities,
        })
      }

      const updatedActivitySupport = _.filter(
        activitySupportList,
        (act, idx) => idx === 0,
      )

      const formattedActivitySupport = _.map(resultActivitySupport, (list) => {
        return { ...list, listName: _.get(list, 'department') }
      })

      formattedActivitySupport.push({
        listName: 'Total',
        count: totalActivitySupport,
      })

      return {
        academyActivityList: updatedAcademyActivity,
        activitySupportList: [
          ...updatedActivitySupport,
          ...formattedActivitySupport,
        ],
        totalAcademyActivity: totalActivities,
        totalActivitySupport,
        latestUpdated,
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
