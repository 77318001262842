import { setChangeQuestionByKey } from '../../../../../../../../redux/slices/eEvaluationForm'

export const onDragEnd = (props) => (dispatch) => {
  const { event, answers, sequence, isSubQuestions, subQuestionIndex } = props
  if (!event.destination) return
  if (event.destination.index === event.source.index) return
  const listAnswer = reorder(
    answers,
    event.source.index,
    event.destination.index
  )

  dispatch(
    setChangeQuestionByKey({
      type: !isSubQuestions ? 'questions' : 'subQuestions',
      sequence,
      key: !isSubQuestions ? 'answers' : `value[${subQuestionIndex}].answers`,
      value: listAnswer,
    })
  )
}
export const reorder = (list, startIndex, endIndex) => {
  const listAnswer = Array.from(list)
  const [removed] = listAnswer.splice(startIndex, 1)
  listAnswer.splice(endIndex, 0, removed)
  const result = listAnswer.filter((item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
      sequence: index + 1,
    }
  })
  return result
}
