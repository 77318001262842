import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import PrerequisiteCourseSetting from '../../../../../CourseSetting/Prerequisite'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange } from '../../events'

const Prerequisite = () => {
  const { licenseList, allCourseList, coursePrerequisite, onGoing } =
    useSelector(
      (state) => ({
        licenseList: state.eLearningCourseForm.licenseList,
        allCourseList: state.eLearningCourseForm.allCourseList,
        coursePrerequisite: getStateValue('coursePrerequisite', []),
        onGoing: state.eLearningCourseForm.onGoing,
      }),
      shallowEqual,
    )
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const formik = {
    values: { coursePrerequisite: coursePrerequisite.map((item) => [...item]) },
    errors,
    setFieldValue: (key, value) => dispatch(handleChange(key, value)),
  }

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">เงื่อนไขการเข้าอบรม</Typography>
      <PrerequisiteCourseSetting
        formik={formik}
        license={licenseList}
        allCourse={allCourseList}
        disabled={onGoing}
      />
    </StyledFormCard>
  )
}

export default Prerequisite
