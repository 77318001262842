import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Preview from '../../../../../../modules/EEvaluation/Preview'
import Evaluate from '../../../../../../modules/EEvaluation/Preview/Evaluate'
import { MediaBoxStyled } from '../Styled'
import { handleFetchEEvaluation } from './events'

const EEvaluation = ({ selectedLesson }) => {
  const [eEvaluation, setEEvaluation] = useLocalStorage('eEvaluation')
  const dispatch = useDispatch()
  const uuid = _.get(selectedLesson, 'eEvaluationUuid', '')
  const isInProgress = _.get(selectedLesson, 'isInProgress', false)
  const isLessonLoading = useSelector(
    (state) => state.eLearningPreview.isLessonLoading,
    shallowEqual,
  )

  useEffect(() => {
    dispatch(handleFetchEEvaluation(uuid, setEEvaluation))
  }, [uuid])

  if (!eEvaluation || isLessonLoading) return <MediaBoxStyled />

  if (isInProgress) return <Evaluate isModule />

  return <Preview isModule />
}

export default EEvaluation
