import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { fetchAgentUpdateDataUuidList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from './Styled'
import { breadcrumbAgentUpdateUuidProfile } from './model'
import { useParams } from 'react-router-dom'

const AgentUpdateProfileListUuid = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { id: uuid } = useParams()

  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchAgentUpdateDataUuidList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        setInitial,
        uuid,
      ),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchAgentUpdateDataUuidList(
          'fetch',
          table,
          page,
          status,
          filterProp,
          searchText,
          setInitial,
          uuid,
        ),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box>
            <Typography variant="h4">รายละเอียด รายการไม่สำเร็จ</Typography>
            <Breadcrumbs menuList={breadcrumbAgentUpdateUuidProfile()} />
          </Box>
        </StyledHeader>

        <StyledCard>
          <Table />
        </StyledCard>
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default AgentUpdateProfileListUuid
