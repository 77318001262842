import _ from 'lodash'
import { initialTestAndEvaluate } from '../DefaultValue'

export const handleInitialForm = (initialValues, setETestingArr, formik) => {
  const isClone = window.location.href.search('clone') != -1
  const uuid = _.get(initialValues, 'uuid', '')
  const testAndEvaluate = _.get(initialValues, 'testAndEvaluate', []).map(
    (item) => {
      if (isClone) {
        delete item.id
        delete item.uuid
        delete item.courseVersionUuid
      }
      return item
    }
  )
  const orderedArr = _.orderBy(testAndEvaluate, ['id'], ['asc'])
  const newTestAndEvaluate = orderedArr.map((item) => ({
    ...item,
    courseVersionUuid: uuid,
  }))
  setETestingArr(newTestAndEvaluate)
  formik.setFieldValue('testAndEvaluate', newTestAndEvaluate)
}

export const handleAddSet = (uuid, testAndEvaArr, setInitialValues, formik) => {
  const newTestAndEvaluate = {
    ...initialTestAndEvaluate,
    courseVersionUuid: uuid,
  }
  testAndEvaArr.push(newTestAndEvaluate)
  setInitialValues((prev) => ({ ...prev, testAndEvaluate: testAndEvaArr }))
  formik.setFieldValue('testAndEvaluate', testAndEvaArr)
}
