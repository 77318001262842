export const initialState = {
  isLoading: false,
  isAgentLoading: false,
  formType: 'manual', // manual || import
  isSuccess: false,
  error: null,
  userType: '',
  userProfileList: [],
  userAgentList: [],
  userProfile: [],
  staffProfile: [],
  remark: '',
  fileName: 'No File Chosen',
  file: null,
  uploadResult: null,
  dateRangeList: [],
  meetingRoomList: [],
}
