import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import useTheme from '@mui/system/useTheme'
import { useParams } from 'react-router-dom'

import Form from './Form'
import { validationSchema } from './FormSchema'
import Breadcrumbs from '../../components/Breadcrumbs'
import { skillType, filterAcquiredSkill } from '../../utils/apiPath'
import { callAPI, setMapListOptions } from '../../utils/lib'

const SkillTypeForm = ({ isEditForm }) => {
  const { id: uuid } = useParams()
  const theme = useTheme()

  const titleText = isEditForm ? 'แก้ไขประเภทของทักษะ' : 'เพิ่มประเภทของทักษะ'

  const fetchSkillTypeById = async (uuid) => {
    await callAPI({
      url: skillType + `/${uuid}`,
      method: 'GET',
      onSuccess: (res) => {
        const { id, skillType, status, acquiredSkills } = res
        setInitialValues({
          ...res,
          id: id,
          skillType: skillType,
          acquiredSkills:
            acquiredSkills && acquiredSkills.length
              ? acquiredSkills.map((item) => {
                  return { ...item, title: item.acquiredSkill }
                })
              : [],
          status: status.toString().toUpperCase() === 'ACTIVE' || false,
        })
        setDisplayTitle(skillType)
        formik.setFieldValue('id', id)
        formik.setFieldValue('skillType', skillType)
        formik.setFieldValue('acquiredSkills', acquiredSkills)
        formik.setFieldValue(
          'status',
          status.toString().toUpperCase() === 'ACTIVE' || false
        )
      },
    })
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchAcquiredSkillList(setStateAcquiredSkill)
    if (isEditForm) {
      fetchSkillTypeById(uuid)
    }
    setIsPageLoading(false)
  }, [isEditForm])

  const [dialog, setDialog] = useState({
    open: false,
    variant: 'save',
    title: '',
    icon: '',
    content: '',
    onConfirmClick: onCancelClick,
    onCancelClick: onCancelClick,
  })
  const [displayTitle, setDisplayTitle] = useState('')
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [stateAcquiredSkill, setStateAcquiredSkill] = useState([])
  const [initialValues, setInitialValues] = useState({
    skillType: '',
    acquiredSkills: [],
    status: true,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitSkillType(values, uuid, isEditForm, setDialog),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs
          sx={{ mx: 3, mb: 3 }}
          menuList={listBreadcrumbList(titleText)}
        />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          acquiredSkills={setMapListOptions(
            [...stateAcquiredSkill, ...initialValues.acquiredSkills],
            'acquiredSkill'
          )}
          onChange={(list) => onSelectChange(list, formik)}
          theme={theme}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default SkillTypeForm

export const onCancelClick = (setDialog) => {
  setDialog({ open: false })
}

export const onSelectChange = (list, formik) => {
  formik.setFieldValue('acquiredSkills', list)
}

export const submitSkillType = async (values, uuid, isEditForm, setDialog) => {
  setDialog({
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'

  let result = []
  if (values?.acquiredSkills?.length) {
    result = values?.acquiredSkills?.map((item) => {
      if (!item.label) {
        return { label: item.acquiredSkills, value: item.uuid }
      }
      return item
    })
  }

  const body = isEditForm
    ? {
        id: values.id,
        uuid: uuid,
        skillType: values.skillType,
        acquiredSkills: result,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }
    : {
        skillType: values.skillType,
        acquiredSkills: result,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }

  await callAPI({
    url: skillType,
    method: methodType,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res.uuid
      setDialog({
        open: true,
        isLoading: false,
        variant: 'success',
        onConfirmClick: () => {
          window.location.href = '/master-data/skill-type/' + uuidLink
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
  })
}

export const fetchAcquiredSkillList = async (setStateAcquiredSkill) => {
  const body = {
    acquiredSkill: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    isAvailable: true,
    status: ['ACTIVE'],
  }
  await callAPI({
    url: filterAcquiredSkill,
    method: 'POST',
    body: body,
    onSuccess: (res) => {
      setStateAcquiredSkill(res.result)
    },
  })
}

export const listBreadcrumbList = (titleText) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ประเภทของทักษะ',
      link: '/master-data/skill-type',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
}
