import { store } from '../../../../../App'
import _ from 'lodash'
import { transferByEnum } from '../enum/transferTypeEnum'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { tabActive } = store.getState().crud
  let transferBy = [transferByEnum.MY_TRANSFER]
  switch (tabActive) {
    case 0:
      transferBy = [transferByEnum.MY_TRANSFER]
      break
    case 1:
      transferBy = [transferByEnum.TRANSFER_OTHER]
      break
  }

  return {
    name: _.get(filterProp, 'name', ''),
    status: _.get(filterProp, 'status', []),
    search: _.get(filterProp, 'search', ''),
    transferType: ['TRANSFER'],
    station: _.get(filterProp, 'station', []),
    startCreatedDate: _.get(filterProp, 'startCreatedDate', ''),
    endCreatedDate: _.get(filterProp, 'endCreatedDate', ''),
    startUpdatedDate: _.get(filterProp, 'startUpdatedDate', ''),
    endUpdatedDate: _.get(filterProp, 'endUpdatedDate', ''),
    transferBy: transferBy,

    from: _.get(filterProp, 'from', ''),
    to: _.get(filterProp, 'to', ''),
    qtyFrom: _.get(filterProp, 'qtyFrom', ''),
    qtyTo: _.get(filterProp, 'qtyTo', ''),
    category: _.get(filterProp, 'category', ''),
    subCategory: _.get(filterProp, 'subCategory', ''),
    createDateStartDate: _.get(filterProp, 'createDateStartDate', ''),
    createDateEndDate: _.get(filterProp, 'createDateEndDate', ''),
  }
}
