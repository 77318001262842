import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import styled from '@mui/system/styled'

export const StyledTabBox = styled(Box)(({ theme }) => ({
  width: 1279,
  marginTop: theme.spacing(7.5),
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    width: 720,
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  border: 'solid 1px',
  borderColor: theme?.palette?.primary?.main,
  borderBottom: 'none',
  fontSize: '19px',
  fontWeight: 500,
  letterSpacing: '0.46px',
  padding: `${theme.spacing(1)} 22px`,
  '&.Mui-selected': {
    color: theme?.palette?.primary?.contrastText,
    backgroundColor: theme?.palette?.primary?.main,
  },
}))

export const StyledContainerCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
  margin: `${theme.spacing(5)} 0`,
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(4),
    margin: `${theme.spacing(4)} 13px ${theme.spacing(5.5)}`,
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    margin: `${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(5.5)}`,
  },
}))
