import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Row, Column } from '../../Styled'
import InfoCard from '../OverviewCourseSection/InfoCard'
import CourseList from '../OverviewCourseSection/CourseList'
import { typeTab } from '../../model'
import { openCourseDrawer } from '../../../../../redux/slices/eLearning/dashboard'

const Content = () => {
  const dispatch = useDispatch()
  const { overview, type } = useSelector(
    (state) => ({
      overview: state.eLearningDashboard.overview,
      type: state.eLearningDashboard.type,
    }),
    shallowEqual,
  )
  return (
    <Column>
      <Row sx={{ gap: 2, mb: 2 }}>
        <InfoCard
          label="ทั้งหมด"
          number={overview[type].totalCourse}
          unit="หลักสูตร"
          backgroundButton={typeTab[type].secondaryColor}
          onClick={() => {
            dispatch(
              openCourseDrawer({
                header: `หลักสูตรทั้งหมดของ ${typeTab[type].label}`,
                drawerType: 'course',
                courseType: typeTab[type].key,
              }),
            )
          }}
        />
        <InfoCard
          label={`ผู้เรียน Enroll (${typeTab[type].label})`}
          number={overview[type].totalEnroll}
          unit="คน"
          background={typeTab[type].secondaryColor}
          backgroundButton={typeTab[type].secondaryColor}
          onClick={() => {
            dispatch(
              openCourseDrawer({
                header: `รายชื่อผู้เรียน Enroll (${typeTab[type].label}) - รายการหลักสูตร`,
                drawerType: 'enroll',
                courseType: typeTab[type].key,
              }),
            )
          }}
        />
      </Row>
      <Row sx={{ gap: 2 }}>
        <CourseList
          label="หลักสูตรที่ถูกเพิ่มล่าสุด"
          list={overview[type].latestCreate}
          color={typeTab[type].mainColor}
          background={typeTab[type].secondaryColor}
          nameColor={typeTab[type].nameColor || ''}
        />
        <CourseList
          label="หลักสูตรที่ถูกแก้ไขล่าสุด"
          list={overview[type].latestUpdate}
          color={typeTab[type].mainColor}
          background={typeTab[type].secondaryColor}
          nameColor={typeTab[type].nameColor || ''}
        />
      </Row>
    </Column>
  )
}

export default Content
