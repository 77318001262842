import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { StyledFormCard } from '../../Styled'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { UploadCropper } from '../../../../../../components/UploadCropper'
import { handleCategoryChange } from '../../handler/handleCategoryChange'
import { handleFileChange } from '../../handler/handleFileChange'
import { shallowEqual, useSelector } from 'react-redux'
import { useInputField } from '../../../../../../components/CRUD/hooks/useInputField'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'

const BasicInfoForm = () => {
  const { value } = useInputField('coverImage')
  const { isError, message } = useInputField('coverImage.key')

  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )
  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <CustomInput
        tab-name="tab"
        content-tab="content"
        required
        labelName="ชื่อเรื่อง"
        fieldName="name"
      />
      <Box sx={{ display: 'flex', gap: 3 }}>
        <CustomDropdown
          content-tab="content"
          required
          labelName="หมวดหมู่"
          fieldName="category"
          handleExtraChange={handleCategoryChange}
        />
        <CustomDropdown
          content-tab="content"
          required
          labelName="หมวดหมู่ย่อย"
          fieldName="subCategory"
        />
      </Box>
      <Divider />
      <div>
        <UploadCropper
          folder="e-content"
          content-tab="content"
          accept={{
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg'],
          }}
          onChange={handleFileChange}
          value={value?.key}
          fileName={value?.fileName}
          id={`${crudConfig.moduleSlug}-coverImage`}
        />
        {isError && (
          <Box>
            {message && message !== '' && (
              <Typography variant="body2" color="error">
                {message}
              </Typography>
            )}
          </Box>
        )}
      </div>
      <div>
        <Typography variant="body1b">
          สถานะเปิด/ปิดการใช้งานเนื้อหาคลังความรู้
        </Typography>
        <CustomSwitchInLine
          content-tab="content"
          title=""
          fieldName="isActive"
        />
      </div>
    </StyledFormCard>
  )
}

export default BasicInfoForm
