export const handleMargin = (index, isNotSm) => {
  switch (index) {
    case 1:
      return isNotSm ? 32 : 17
    case 2:
      return isNotSm ? 64 : 35
    case 3:
      return isNotSm ? 96 : 53
    case 4:
      return isNotSm ? 76 : 42
    case 5:
      return isNotSm ? 40 : 22
    default:
      return 0
  }
}
