import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxUl = styled('ul')(() => ({
  margin: '0 0 0 0',
}))

export const BoxLi = styled('li')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: theme.spacing(2),
}))

export const BoxIconHistory = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}))
