import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import {
  setSelectedLesson,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/eLearning/preview'
import { SubTitleContent } from '../../../LearningPath/Preview/Content/ELearningContent/LeftContent/ContentTab/events'
import { mediaType } from '../../model'
import { StyledOneLineText } from '../Styled'
import SubLabelForFile from './components/SubLabelForFile'
import CircularProgressWithLabel from './components/CircularProgressWithLabel'
import { StyledBoxText, StyledModuleLabel } from './Styled'

const ModuleLabel = ({ data, index, mIndex, disabled }) => {
  const dispatch = useDispatch()
  const { isExpanded, lessonNo, no } = useSelector(
    (state) => ({
      isExpanded: state.eLearningPreview.isExpanded,
      lessonNo: state.eLearningPreview.selectedLesson.lessonNo,
      no: state.eLearningPreview.selectedLesson.no,
    }),
    shallowEqual,
  )
  const lessonName = _.get(data, 'lessonName', '')
  const type = _.get(data, 'type', '')
  const isSelected = index === lessonNo - 1 && mIndex === no - 1
  const isVideoType = type === mediaType.LINK || type === mediaType.HTML
  const isUploadFile = type === mediaType.UPLOAD_FILE
  const [isShow, setShow] = useState(index === 0 && mIndex === 0)

  if (!isExpanded) return <></>

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <StyledModuleLabel
      data-testid={`module-${index}-${mIndex}`}
        disabled={disabled}
        isSelected={isSelected}
        onClick={() => {
          if (isUploadFile) setShow(!isShow)
          else {
            dispatch(startLoading())
            setTimeout(() => {
              dispatch(setSelectedLesson(data))
              dispatch(stopLoading())
            }, 500)
          }
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 56px)',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CircularProgressWithLabel value={_.get(data, 'progress', 0)} />
          <StyledBoxText sx={{ width: 'calc(100% - 46px)' }}>
            <StyledOneLineText variant="body2b">{lessonName}</StyledOneLineText>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              {isVideoType && <img src="/icon/player/circle_play.svg" />}
              {isUploadFile && <img src="/icon/ic_file.svg" />}
              {type === mediaType.E_TESTING && (
                <img src="/icon/ic_e_testing.svg" />
              )}
              {type === mediaType.E_EVALUATION && (
                <img src="/icon/ic_e_evaluation.svg" />
              )}
              {type === mediaType.LINK_TO && <img src="/icon/ic_link.svg" />}
              <Typography variant="body2" color="text.lightGray">
                {SubTitleContent(data)}
              </Typography>
            </Box>
          </StyledBoxText>
        </Box>

        {isUploadFile && (
          <IconButton onClick={() => setShow(!isShow)}>
            {isShow ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </StyledModuleLabel>

      {isUploadFile &&
        isShow &&
        _.get(data, 'fileList', []).map((file, index) => (
          <SubLabelForFile key={index} index={index} data={data} file={file} />
        ))}
    </Box>
  )
}

export default ModuleLabel
