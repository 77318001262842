import Button from '@mui/material/Button'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setReduxValue } from '../../../../../redux/slices/roomManagement/Dashboard'
import { fetchRoomDetailCalendar } from '../../event'
import { StyledFooter } from '../../../../../components/FilterDrawer/Styled'
import { fetchCalendar } from '../../../../../modules/RoomManagement/BookingRoomSection/event'

export const FilterDrawerFooter = () => {
  const dispatch = useDispatch()
  const { calendarPage } = useSelector(
    (state) => ({
      calendarPage: state.roomManagement.calendarPage,
    }),
    shallowEqual,
  )
  return (
    <StyledFooter>
      <Button
        variant="outlined"
        onClick={() => {
          dispatch(
            setReduxValue({
              key: 'drawerCalendarFilter',
              value: {
                isFilterRoomName: false,
                filterRoomName: '',
                isFilterStation: false,
                filterStation: [],
                isFilterBookingType: false,
                filterBookingType: [],
                isFilterTopic: false,
                filterTopicQuery: '',
              },
            }),
          )
        }}
      >
        ล้าง
      </Button>
      <Button
        data-testid={`btn-confirm-filter`}
        onClick={() => {
          dispatch(
            setReduxValue({
              key: 'isDrawerCalendarFilterOpen',
              value: false,
            }),
          )
          if (calendarPage == 'dashboard') {
            dispatch(fetchCalendar())
          } else {
            dispatch(fetchRoomDetailCalendar())
          }
        }}
        variant="contained"
      >
        ยืนยัน
      </Button>
    </StyledFooter>
  )
}
