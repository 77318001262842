import React from 'react'
import { StyledCard, StyledDivider } from './Styled'
import Information from './Information'
import ScaleMeetingRoom from './Scale'
import CapacityMeetingRoom from './Capacity'
import Function from './RoomFunction'
import Equipment from './Equipment'
import TypeRoom from './RoomType'
import Remark from './Remark'
import Status from './Status'
import Footer from './Footer'

const Index = () => {
  return (
    <StyledCard>
      <Information />
      <ScaleMeetingRoom />
      <CapacityMeetingRoom />
      <StyledDivider />
      <Function />
      <StyledDivider />
      <Equipment />
      <StyledDivider />
      <TypeRoom />
      <StyledDivider />
      <Remark />
      <Status />
      <Footer />
    </StyledCard>
  )
}

export default Index
