import { Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { SummaryList } from './SummaryList'

export const SummaryByAccountCode = () => {
  const { summaryByAccountCode } = useSelector(
    (state) => ({
      summaryByAccountCode: state.crud.formData.summaryByAccountCode,
    }),
    shallowEqual,
  )

  return (
    <>
      {summaryByAccountCode?.length > 0 && (
        <>
          <Typography variant="body1b" color="gray">
            By Account code
          </Typography>
          <SummaryList summaryList={summaryByAccountCode} />
        </>
      )}
    </>
  )
}
