import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StyledColumn } from '../../Styled'
import Typography from '@mui/material/Typography'
import { ButtonFooterCard } from '../ButtonFooterCard'
import Button from '@mui/material/Button'
import {
  handleConfirmBooking,
  handleEditBookingDialog,
} from '../PreviewContent/event'
import { prevForm } from '../../../../redux/slices/roomManagement/Drawer'
import { STATUS_TYPE } from '../../../../constants/roomManagement'

export const FormFooter = () => {
  const dispatch = useDispatch()
  const { bookingUuid, bookingInformation, isEditingForm } = useSelector(
    (state) => ({
      bookingUuid: state.roomManagementDrawer.bookingInformation.bookingUuid,
      bookingInformation: state.roomManagementDrawer.bookingInformation,
      isEditingForm: state.roomManagementDrawer.isEditingForm,
    }),
    shallowEqual,
  )

  const createdBy = _.get(bookingInformation, 'createdBy', '')
  const createdAt = _.get(bookingInformation, 'createdAt', '')
  const updatedBy = _.get(bookingInformation, 'updatedBy', '')
  const updatedAt = _.get(bookingInformation, 'updatedAt', '')

  return (
    <>
      {!_.isEmpty(bookingUuid) && !isEditingForm ? (
        <StyledColumn sx={{ alignItems: 'flex-end' }}>
          <Typography variant="body3" color="text.lightGray">
            ดำเนินการโดย {createdBy} {createdAt}
          </Typography>
          <Typography variant="body3" color="text.lightGray">
            แก้ไขล่าสุดโดย {updatedBy} {updatedAt}
          </Typography>
        </StyledColumn>
      ) : (
        <ButtonFooterCard boxSx={{ gap: 3, display: 'flex' }}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(prevForm())
            }}
          >
            ย้อนกลับ
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              if (
                _.isNull(bookingUuid) ||
                bookingInformation.status === STATUS_TYPE.IN_PROGRESS
              ) {
                dispatch(handleConfirmBooking())
              } else {
                dispatch(handleEditBookingDialog())
              }
            }}
          >
            ยืนยันการจอง
          </Button>
        </ButtonFooterCard>
      )}
    </>
  )
}
