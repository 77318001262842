import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import useLocalStorage from '@rehooks/local-storage'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import TextInput from '../../components/Input/TextInput'
import Select from '../../components/Input/Select'
import DialogHeader from './Dialog/DialogHeader'
import HistoryDrawer from './HistoryDrawer'
import ApproveListDrawer from './ApproveListDrawer'
import {
  callAPI,
  getMasterPlanTextStatus,
  getColorMasterPlanStatus,
  validatePermission,
  validatePlanStatus,
  getDialogErrorMessage,
} from '../../utils/lib'
import {
  monthlyPlanMasterPlan,
  masterPlanApprove,
  masterPlanReject,
  masterPlanRecall,
} from '../../utils/apiPath'
import * as API from './api/Index'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'

export const breadcrumbList = [
  { title: 'แผนรายเดือน', link: '/monthly-plan/calendar', pointer: false },
  { title: 'จัดการแผนงาน', link: '/monthly-plan/calendar', pointer: false },
]

const Header = (props) => {
  const {
    isLoading,
    options,
    state,
    setState,
    setIsDrawer,
    confirmDialog,
    setConfirmDialog,
    handleCloseConfirmDialog,
    handleFetchEvents,
    approveDrawer,
    setApproveDrawer,
    historyDrawer,
    setHistoryDrawer,
    fetchApproveList,
  } = props
  const { approveList } = state
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const [openDialog, setOpenDialog] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const status = _.get(state, 'masterPlan.status', 'DRAFT')
  let history = useHistory()

  const [stateDepartment, setStateDepartment] = useState({})

  const permissionApproveMP = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_MP'],
  })
  const hasAssistsZone = !!user.permission.find(
    (item) =>
      item.module === 'MONTHLY_PLAN' && item.permission === 'ASSISTS_ZONE',
  )

  const hasSubmitPlan = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: [
      'CREATE_ALL',
      'CREATE_ALL_EXC_HOLIDAY',
      'APPROVE_ZONE',
      'ASSISTS_ZONE',
    ],
    data: state,
  })

  const hasApproveZone = validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_ZONE'],
    data: state,
  })

  const getWaitingApproveBtnText = permissionApproveMP
    ? 'รายการรออนุมัติ'
    : 'รายการที่ต้องขออนุมัติ'

  useEffect(() => {
    fetchApproveList()
    setStateDepartment(
      findAreaOption(_.get(state, 'area.valueArea', ''), options),
    )
  }, [openDialog, state?.area?.value, state?.data?.month, state?.data?.year])
  return (
    <>
      {isLoading && <></>}
      {!isLoading && (
        <>
          <Box
            sx={{
              p: 2.5,
              wordBreak: 'break-all',
              display: 'flex',
              position: 'sticky',
              flexDirection: 'column',
              zIndex: 1,
              pb: alertCanEditPreviousPlan(state).isShow ? 0 : 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: 'background.basic',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'end',
                display: 'flex',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  mt: 0,
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '70%' }}>
                  <Select
                    dataTestid="select-department"
                    boxSx={{ backgroundColor: theme?.palette?.common?.white }}
                    id="department"
                    name="department"
                    type="basic"
                    options={resetValueOption(
                      _.get(options, 'area', []),
                      'valueArea',
                    )}
                    showTextError={false}
                    handleChange={(e) =>
                      handleAreaChange(
                        e.target.value,
                        'department',
                        setState,
                        options,
                        stateDepartment,
                      )
                    }
                    value={_.get(state, 'area.valueArea', '')}
                  />
                </Box>

                <Box sx={{ width: '30%', ml: 3 }}>
                  <Select
                    dataTestid="select-station"
                    boxSx={{ backgroundColor: theme?.palette?.common?.white }}
                    id="station"
                    name="station"
                    type="basic"
                    options={mapOptionHeader({
                      option: _.get(options, 'area', []),
                      value: _.get(state, 'area.valueArea', ''),
                    })}
                    showTextError={false}
                    handleChange={(e) =>
                      handleAreaChange(
                        e.target.value,
                        'station',
                        setState,
                        options,
                        stateDepartment,
                      )
                    }
                    value={_.get(state, 'area.valueStation', '')}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  ml: 2,
                  right: 0,
                }}
              >
                {!state.isAllStation && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      flexDirection: 'row',
                      mr: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          mr: 3,
                        }}
                      >
                        {state?.masterPlan && (
                          <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{
                              color: `${getColorMasterPlanStatus({
                                status: state?.masterPlan?.status,
                              })}`,
                            }}
                          />
                        )}
                        <Typography variant="body1">
                          {getMasterPlanTextStatus(state)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          flexDirection: 'row',
                          mr: 3,
                          gap: 3,
                        }}
                      >
                        {validatePermission({
                          user: user,
                          moduleType: 'MONTHLY_PLAN',
                          permission: [
                            'CREATE_ALL',
                            'DELETE_ALL',
                            'CREATE_ALL_EXC_HOLIDAY',
                            'DELETE_ALL_EXC_HOLIDAY',
                            'APPROVE_MP',
                          ],
                          data: state,
                        }) && (
                          <Button
                            data-testid="btn-history-drawer"
                            sx={{
                              [theme.breakpoints.down('sm')]: {
                                mb: 2,
                              },
                            }}
                            size="m"
                            startIcon={<RestoreTwoToneIcon />}
                            variant="outlined"
                            onClick={() => handleHistory(setHistoryDrawer)}
                          >
                            ประวัติการจัดทำแผน
                          </Button>
                        )}
                        {validatePermission({
                          user: user,
                          moduleType: 'MONTHLY_PLAN',
                          permission: [
                            'CREATE_ALL',
                            'DELETE_ALL',
                            'CREATE_ALL_EXC_HOLIDAY',
                            'DELETE_ALL_EXC_HOLIDAY',
                            'APPROVE_MP',
                          ],
                          data: state,
                        }) &&
                          validatePlanStatus({
                            data: state,
                            btnType: 'APPROVE_LIST',
                            moduleType: 'MONTHLY_PLAN',
                            permission: [
                              'CREATE_ALL',
                              'CREATE_ALL_EXC_HOLIDAY',
                              'APPROVE_MP',
                            ],
                          }) && (
                            <Button
                              sx={{
                                [theme.breakpoints.down('sm')]: {
                                  mb: 2,
                                },
                              }}
                              size="m"
                              variant="contained"
                              onClick={() =>
                                handleApproveListClick(
                                  setApproveDrawer,
                                  fetchApproveList,
                                )
                              }
                            >
                              {getWaitingApproveBtnText} ({approveDrawer.count})
                            </Button>
                          )}
                        {hasSubmitPlan &&
                          validatePlanStatus({
                            data: state,
                            btnType: 'SUBMIT_MONTHLY_PLAN',
                            moduleType: 'MONTHLY_PLAN',
                            permission: [
                              'CREATE_ALL',
                              'CREATE_ALL_EXC_HOLIDAY',
                              'APPROVE_ZONE',
                            ],
                          }) && (
                            <Button
                              data-testid="btn-submit-monthlyPlan"
                              sx={{
                                color: 'text.white',
                                [theme.breakpoints.down('sm')]: {
                                  mb: 2,
                                },
                              }}
                              size="m"
                              variant="contained"
                              color="secondary"
                              disabled={hasAssistsZone && !hasApproveZone}
                              onClick={() =>
                                handleSubmitPlanClick(
                                  state,
                                  history,
                                  handleFetchEvents,
                                  setConfirmDialog,
                                  handleCloseConfirmDialog,
                                )
                              }
                            >
                              ส่งตรวจสอบแผนรายเดือนนี้
                            </Button>
                          )}
                        {hasSubmitPlan &&
                          validatePlanStatus({
                            data: state,
                            btnType: 'RECALL_MONTHLY_PLAN',
                            moduleType: 'MONTHLY_PLAN',
                            permission: [
                              'CREATE_ALL',
                              'CREATE_ALL_EXC_HOLIDAY',
                            ],
                          }) && (
                            <Button
                              sx={{
                                color: 'error.main',
                                [theme.breakpoints.down('sm')]: {
                                  mb: 2,
                                },
                              }}
                              size="m"
                              variant="outlined"
                              disabled={hasAssistsZone && !hasApproveZone}
                              color="error"
                              onClick={() =>
                                handleRecallPlanClick(
                                  state,
                                  setConfirmDialog,
                                  handleFetchEvents,
                                  handleCloseConfirmDialog,
                                )
                              }
                            >
                              ยกเลิกการส่งตรวจแผนรายเดือนนี้
                            </Button>
                          )}
                        <>
                          {validatePermission({
                            user: user,
                            moduleType: 'MONTHLY_PLAN',
                            permission: ['APPROVE_MP'],
                            data: state,
                          }) &&
                            validatePlanStatus({
                              data: state,
                              btnType: 'APPROVED_MONTHLY_PLAN',
                              moduleType: 'MONTHLY_PLAN',
                              permission: ['APPROVE_MP'],
                            }) && (
                              <Button
                                sx={{
                                  color: 'text.white',
                                  [theme.breakpoints.down('sm')]: {
                                    mb: 2,
                                  },
                                }}
                                size="m"
                                variant="contained"
                                color="success"
                                onClick={() =>
                                  handleActionPlanClick(
                                    'APPROVED',
                                    state,
                                    approveList,
                                    confirmDialog,
                                    permissionApproveMP,
                                    setConfirmDialog,
                                    setApproveDrawer,
                                    fetchApproveList,
                                    handleFetchEvents,
                                    handleCloseConfirmDialog,
                                  )
                                }
                              >
                                อนุมัติแผน
                              </Button>
                            )}
                          {validatePermission({
                            user: user,
                            moduleType: 'MONTHLY_PLAN',
                            permission: ['APPROVE_MP'],
                            data: state,
                          }) &&
                            validatePlanStatus({
                              data: state,
                              btnType: 'REJECTED_MONTHLY_PLAN',
                              moduleType: 'MONTHLY_PLAN',
                              permission: ['APPROVE_MP'],
                            }) && (
                              <Button
                                sx={{
                                  color: 'text.white',
                                  [theme.breakpoints.down('sm')]: {
                                    mb: 2,
                                  },
                                }}
                                size="m"
                                variant="contained"
                                color="error"
                                onClick={() =>
                                  handleActionPlanClick(
                                    'REJECTED',
                                    state,
                                    approveList,
                                    confirmDialog,
                                    permissionApproveMP,
                                    setConfirmDialog,
                                    setApproveDrawer,
                                    fetchApproveList,
                                    handleFetchEvents,
                                    handleCloseConfirmDialog,
                                  )
                                }
                              >
                                ไม่อนุมัติ
                              </Button>
                            )}
                        </>
                      </Box>
                    </Box>

                    {/* WIP for waiting dialog message
                    <Box>
                      <InfoOutlinedIcon
                        sx={{
                          color: `${theme?.palette?.primary?.main}`,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleClickOpen(setOpenDialog)}
                      />
                    </Box> */}
                  </Box>
                )}
              </Box>

              <DialogHeader
                open={openDialog}
                handleClose={() => handleClickOpen(setOpenDialog)}
              />

              <HistoryDrawer
                open={historyDrawer}
                onClose={() =>
                  setHistoryDrawer((prev) => ({ ...prev, open: false }))
                }
                historyList={historyList}
                historyDrawer={historyDrawer}
                setHistoryDrawer={setHistoryDrawer}
                onDownload={() => API.fetchDownload({ state, historyDrawer })}
                state={state}
                setHistoryList={setHistoryList}
              />
              <ApproveListDrawer
                open={approveDrawer}
                onClose={() => {
                  setApproveDrawer((prev) => ({ ...prev, open: false }))
                  fetchApproveList()
                }}
                state={state}
                approveDrawer={approveDrawer}
                setApproveDrawer={setApproveDrawer}
                setIsDrawer={setIsDrawer}
                optionWork={_.get(options, 'work', [])}
                getWaitingApproveBtnText={getWaitingApproveBtnText}
              />
            </Box>
            {validatePermission({
              user: user,
              moduleType: 'MONTHLY_PLAN',
              permission: ['CREATE_ALL', 'CREATE_ALL_EXC_HOLIDAY'],
            }) &&
              status !== '' &&
              status !== 'WAITING' &&
              status !== 'DRAFT' &&
              status !== 'RECALL' && (
                <Box sx={{ wordBreak: 'break-word' }}>
                  <Alert
                    severity={getColorStatus(state)}
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                    action={
                      <Button
                        sx={{ width: '160px' }}
                        data-testid="btn-history-plan"
                        variant="outlined"
                        color={getColorStatus(state)}
                        onClick={() => handleHistory(setHistoryDrawer)}
                      >
                        ดูประวัติการจัดทำแผน
                      </Button>
                    }
                  >
                    <AlertTitle sx={{ mb: 0 }}>
                      {getAlertTitle(state)}
                    </AlertTitle>
                    {status === 'REJECTED' &&
                      _.get(state, 'masterPlan.masterPlan.rejectReason', '')}
                  </Alert>
                </Box>
              )}
          </Box>
        </>
      )}
    </>
  )
}
export default Header

export const handleAreaChange = (
  uuid,
  key,
  setState,
  options,
  stateDepartment,
) => {
  let uuidArea = {}

  if (key === 'station' && _.get(stateDepartment, 'station').length) {
    const obj = _.get(stateDepartment, 'station').find((item) => {
      return item.value === uuid
    })

    if (obj)
      uuidArea = {
        ...obj,
        department: {
          createdAt: _.get(stateDepartment, 'createdAt', ''),
          createdBy: _.get(stateDepartment, 'createdBy', ''),
          department: _.get(stateDepartment, 'department', ''),
          id: _.get(stateDepartment, 'id', 0),
          initial: _.get(stateDepartment, 'initial', ''),
          status: _.get(stateDepartment, 'status', ''),
          updatedAt: _.get(stateDepartment, 'updatedAt', ''),
          updatedBy: _.get(stateDepartment, 'updatedBy', ''),
          uuid: _.get(stateDepartment, 'uuid', ''),
        },
        station: {
          createdAt: _.get(obj, 'createdAt', ''),
          createdBy: _.get(obj, 'createdBy', ''),
          id: _.get(obj, 'id', 0),
          initial: _.get(obj, 'initial', ''),
          station: _.get(obj, 'station', ''),
          status: _.get(obj, 'status', ''),
          updatedAt: _.get(obj, 'updatedAt', ''),
          updatedBy: _.get(obj, 'updatedBy', ''),
          uuid: _.get(obj, 'uuid', ''),
        },
      }
  }

  setState((prevState) => ({
    ...prevState,
    area:
      key === 'station'
        ? {
            ...prevState.area,
            id: _.get(uuidArea, 'id', ''),
            value: _.get(uuidArea, 'areaUuid', ''),
            department: _.get(uuidArea, 'department', {}),
            station: _.get(uuidArea, 'station', {}),
            valueStation: uuid,
            valueAreaList: 'all',
            valueStationList: 'all',
          }
        : findAreaOption(uuid, options),
  }))
}

export const findAreaOption = (uuid, options) => {
  let result = {}
  const findArea = options.area.find((item) => {
    return item.uuid === uuid
  })
  if (!_.isEmpty(findArea)) {
    result = {
      ...findArea,
      area: {
        id: _.get(findArea, 'areaId', ''),
        uuid: _.get(findArea, 'areaUuid', ''),
        value: _.get(findArea, 'areaUuid', ''),
      },
      label: `(${_.get(findArea, 'initial')}) ${_.get(findArea, 'department')}`,
      uuid: _.get(findArea, 'areaUuid', ''),
      value: _.get(findArea, 'areaUuid', ''),
      valueArea: _.get(findArea, 'uuid', ''),
      valueStation: _.get(findArea, 'station[0].uuid', ''),
      valueAreaList: 'all',
      id: _.get(findArea, 'areaId', ''),
      station: _.get(findArea, 'station', []).length
        ? _.get(findArea, 'station', []).map((itemStation) => {
            return {
              ...itemStation,
              label: _.get(itemStation, 'station'),
              value: _.get(itemStation, 'uuid', ''),
            }
          })
        : [],
    }
  }
  return result
}

export const clickRedirect = (history, content, handleCloseConfirmDialog) => {
  if (!content) {
    return
  }
  if (content.includes('ยังไม่อนุมัติ')) {
    history.push('/monthly-plan/speaker-approver')
  } else {
    handleCloseConfirmDialog()
  }
}

export const resetValueOption = (listOption, key) => {
  let result = []
  if (listOption.length) {
    result = listOption.map((item) => {
      return {
        ...item,
        value: item[key],
      }
    })
  }
  return result
}
export const handleClickOpen = (setOpenDialog) => {
  setOpenDialog(true)
}
export const handleHistory = (setHistoryDrawer) => {
  setHistoryDrawer((prevState) => ({
    ...prevState,
    open: true,
  }))
}

export const handleSubmitPlanClick = (
  state,
  history,
  handleFetchEvents,
  setConfirmDialog,
  handleCloseConfirmDialog,
) => {
  setConfirmDialog({
    variant: 'save',
    open: true,
    title: 'ยืนยันส่งแผนตรวจสอบรายเดือนนี้',
    content: 'คุณต้องการส่งแผนตรวจสอบรายเดือนนี้หรือไม่',
    onConfirmClick: () =>
      submitPlan(
        state,
        history,
        setConfirmDialog,
        handleFetchEvents,
        handleCloseConfirmDialog,
      ),
    onCancelClick: () => handleCloseConfirmDialog(),
  })
}

export const handleApproveListClick = (setApproveDrawer, fetchApproveList) => {
  setApproveDrawer((prev) => ({ ...prev, open: true }))
  fetchApproveList()
}

export const submitPlan = (
  state,
  history,
  setConfirmDialog,
  handleFetchEvents,
  handleCloseConfirmDialog,
) => {
  setConfirmDialog((prev) => ({ ...prev, isLoading: true }))
  const masterId = _.get(state, 'masterPlan.masterPlan.uuid', '')
  const body = {
    uuid: masterId,
    month: state.data.month + 1,
    year: state.data.year,
    area: {
      id: state.area.id,
      uuid: state.area.value,
    },
  }
  if (body.uuid === '') delete body.uuid

  callAPI({
    method: 'post',
    body: body,
    url: monthlyPlanMasterPlan,
    onSuccess: () => {
      handleFetchEvents()
      setConfirmDialog(() => ({
        variant: 'success',
        open: true,
        title: 'สำเร็จ',
        isLoading: false,
        content: 'ส่งแผนตรวจสอบรายเดือนนี้สำเร็จ',
        onConfirmClick: () => handleCloseConfirmDialog(),
      }))
    },
    onError: (error) => {
      const resError = _.get(error, 'response.data', {})
      const content = _.get(resError, 'constraints.value', '')
      setConfirmDialog({
        variant: 'fail',
        open: true,
        title: _.get(resError, 'constraints.title', ''),
        isLoading: false,
        content: _.get(resError, 'constraints.value', ''),
        onConfirmClick: () =>
          clickRedirect(history, content, handleCloseConfirmDialog),
        onCancelClick: () => handleCloseConfirmDialog(),
      })
    },
  })
}

export const handleRecallPlanClick = (
  state,
  setConfirmDialog,
  handleFetchEvents,
  handleCloseConfirmDialog,
) => {
  setConfirmDialog({
    variant: 'save',
    open: true,
    title: 'ยืนยันยกเลิกการส่งตรวจแผนรายเดือนนี้',
    content: 'คุณต้องการยกเลิกการส่งตรวจแผนรายเดือนนี้หรือไม่',
    onConfirmClick: () =>
      recallPlan(
        state,
        history,
        setConfirmDialog,
        handleFetchEvents,
        handleCloseConfirmDialog,
      ),
    onCancelClick: () => handleCloseConfirmDialog(),
  })
}

export const recallPlan = (
  state,
  history,
  setConfirmDialog,
  handleFetchEvents,
  handleCloseConfirmDialog,
) => {
  setConfirmDialog((prev) => ({ ...prev, isLoading: true }))
  const masterPlanId = _.get(state, 'masterPlan.masterPlan.uuid', '')
  if (masterPlanId === '') {
    return
  }
  callAPI({
    method: 'put',
    url: `${masterPlanRecall}/${masterPlanId}`,
    onSuccess: () => {
      handleFetchEvents()
      setConfirmDialog(() => ({
        variant: 'success',
        open: true,
        isLoading: false,
        title: 'สำเร็จ',
        content: 'ยกเลิกการส่งตรวจแผนรายเดือนนี้สำเร็จ',
        onConfirmClick: () => handleCloseConfirmDialog(),
      }))
    },
    onError: () => {
      setConfirmDialog({
        variant: 'fail',
        open: true,
        isLoading: false,
        title: 'ผิดพลาด',
        content: 'กรุณาตรวจสอบรายการยืมวิทยากรที่ยังไม่อนุมัติ',
        onConfirmClick: () =>
          clickRedirect(history, '', handleCloseConfirmDialog),
        onCancelClick: () => handleCloseConfirmDialog(),
      })
    },
  })
}

export const GetActionPlanContentDialog = ({
  status,
  value,
  error,
  setConfirmDialog,
}) => {
  return status === 'REJECTED' ? (
    <TextInput
      id="reason"
      name="reason"
      labelText="เหตุผล"
      type="basic"
      required
      textError={_.get(error, 'reason', '')}
      isShowTextError={true}
      onChange={(e) => {
        if (e.target.value !== '') {
          error.reason = ''
        }

        value.reason = e.target.value
        setConfirmDialog((prev) => ({
          ...prev,
          value: { reason: e.target.value },
        }))
      }}
      multiline={true}
      rows={2}
    />
  ) : (
    <>คุณยืนยันอนุมัติแผนรายเดือนนี้?</>
  )
}

export const handleActionPlanClick = (
  status,
  state,
  approveList,
  confirmDialog,
  permissionApproveMP,
  setConfirmDialog,
  setApproveDrawer,
  fetchApproveList,
  handleFetchEvents,
  handleCloseConfirmDialog,
) => {
  const error = { reason: null }
  let value = { reason: null }
  let title = status === 'REJECTED' ? 'ไม่อนุมัติแผน' : 'อนุมัติแผน'
  if (status === 'APPROVED' && approveList && approveList.length > 0) {
    let keyword = permissionApproveMP ? 'อนุมัติ' : 'ตรวจสอบ'
    setConfirmDialog({
      ...confirmDialog,
      open: true,
      variant: 'fail',
      title: `ยังเหลือรายการที่รอ${keyword}อยู่`,
      content: `ไม่สามารถอนุมัติแผนได้ เนื่องจากยังเหลือรายการที่ต้องตรวจสอบอยู่ โปรดตรวจสอบที่ปุ่ม 'รายการรอ${keyword}'`,
      onConfirmClick: () => {
        handleApproveListClick(setApproveDrawer, fetchApproveList)
        handleCloseConfirmDialog()
      },
    })
    return
  }
  if (status === 'REJECTED' && value.reason === '') {
    error.reason = 'กรุณากรอกข้อมูล'
  }
  if (
    status === 'REJECTED' &&
    value.reason != null &&
    value.reason.length > 3000
  ) {
    error.reason = 'กรุณากรอกข้อมูลไม่เกิน 3,000'
  }
  let body = {
    rejectReason: value.reason,
  }

  setConfirmDialog({
    open: true,
    variant: status === 'APPROVED' ? 'save' : 'delete',
    title: title,
    content: (
      <GetActionPlanContentDialog
        status={status}
        value={value}
        error={error}
        setConfirmDialog={setConfirmDialog}
      />
    ),
    value: value,
    agreeText: 'ยืนยัน',
    error: { reason: '' },
    onConfirmClick: () =>
      handleActionPlanDialog({
        status,
        body,
        error,
        value,
        setConfirmDialog,
        state,
        handleFetchEvents,
        handleCloseConfirmDialog,
      }),
  })
}

export const handleActionPlanDialog = ({
  status,
  body,
  error,
  value,
  setConfirmDialog,
  state,
  handleFetchEvents,
  handleCloseConfirmDialog,
}) => {
  if (
    status === 'REJECTED' &&
    ((error.reason !== '' &&
      error.reason !== 'กรุณากรอกข้อมูลไม่เกิน 3,000 ตัวอักษร') ||
      value.reason === null ||
      value.reason === '')
  ) {
    error.reason = 'กรุณากรอกข้อมูล'
    setConfirmDialog((prev) => ({
      ...prev,
      content: (
        <GetActionPlanContentDialog
          status={status}
          value={value}
          error={error}
          setConfirmDialog={setConfirmDialog}
        />
      ),
      error: error,
    }))
  } else if (status === 'REJECTED' && value.reason.length > 3000) {
    error.reason = 'กรุณากรอกข้อมูลไม่เกิน 3,000 ตัวอักษร'
    setConfirmDialog((prev) => ({
      ...prev,
      content: (
        <GetActionPlanContentDialog
          status={status}
          value={value}
          error={error}
          setConfirmDialog={setConfirmDialog}
        />
      ),
      error: error,
    }))
  } else {
    submitApproveAction(
      body,
      value,
      status,
      state,
      setConfirmDialog,
      handleFetchEvents,
      handleCloseConfirmDialog,
    )
  }
}

export const submitApproveAction = (
  body,
  value,
  status,
  state,
  setConfirmDialog,
  handleFetchEvents,
  handleCloseConfirmDialog,
) => {
  setConfirmDialog((prev) => ({ ...prev, isLoading: true }))
  body.rejectReason = value.reason
  const masterPlanId = _.get(state, 'masterPlan.masterPlan.uuid', '')
  if (masterPlanId === '') {
    return
  }
  const urlPath = status === 'REJECTED' ? masterPlanReject : masterPlanApprove
  if (status === 'APPROVED') {
    delete body.rejectReason
  }
  const content =
    status === 'REJECTED'
      ? 'ไม่อนุมัติแผนรายเดือนนี้สำเร็จ'
      : 'อนุมัติแผนรายเดือนนี้สำเร็จ'
  callAPI({
    method: 'put',
    url: `${urlPath}/${masterPlanId}`,
    body: body,
    onSuccess: () => {
      handleFetchEvents()
      setConfirmDialog(() => ({
        variant: 'success',
        open: true,
        isLoading: false,
        title: 'สำเร็จ',
        content: content,
        onConfirmClick: () => handleCloseConfirmDialog(),
      }))
    },
    onError: (er) => {
      if (er.message.includes('400')) {
        er['response']['data']['message'] =
          'ไม่สามารถอนุมัติแผนงานได้เนื่องจากมีการอนุมััติแผนงานเรียบร้อยแล้ว'
      }

      setConfirmDialog(getDialogErrorMessage(er, setConfirmDialog))
    },
  })
}

export const getColorStatus = (value) => {
  const masterStatus = _.get(value, 'masterPlan.status', '')
  const objStatus = {
    APPROVED: 'success',
    REJECTED: 'error',
    PATIAL_APPROVED: 'warning',
  }
  return objStatus[masterStatus]
}

export const getAlertTitle = (value) => {
  const masterStatus = _.get(value, 'masterPlan.status', '')
  const objStatus = {
    APPROVED: `HF อนุมัติแผนรายเดือนนี้แล้ว สามารถตรวจสอบรายละเอียด หรือสถานะการอนุมัติค่าใช้จ่ายได้ที่ 'ประวัติการทำแผน'`,
    REJECTED: `HF ไม่อนุมัติแผนรายเดือนนี้ กรุณาตรวจสอบและดำเนินการแก้ไข เพื่อส่งตรวจสอบอีกครั้ง`,
    PATIAL_APPROVED: `HF ไม่อนุมัติบางรายการ กรุณาตรวจสอบใน 'ประวัติการตรวจสอบ' และดำเนินการแก้ไข เพื่อส่งตรวจสอบอีกครั้ง`,
  }
  return objStatus[masterStatus]
}

export const mapOptionHeader = ({ option, value }) => {
  let result = []
  if (option.length <= 0) return result

  const findOption = option.find((item) => {
    return item.valueArea === value
  })

  if (findOption)
    result = resetValueOption(_.get(findOption, 'station', []), 'uuid')
  return result
}

export const alertCanEditPreviousPlan = (state) => {
  const canEditPreviousPlan = _.get(state, 'canEditPreviousPlan', '')
  return canEditPreviousPlan
}
