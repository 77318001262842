import { Box, Typography } from '@mui/material'
import { StyledCard } from '../../../Styled'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { LabelWithValue } from './LabelWithValue'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import CustomSwitchInLine from '../../../../../components/CRUD/components/CustomSwitchInLine'

export const Information = () => {
  return (
    <StyledCard>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <CustomDropdown
        boxSx={{ width: '50%' }}
        required
        labelName="ปี Budget"
        fieldName="yearBudget"
        placeholder="เลือกปี Budget"
      />
      <Box display="flex" gap={3}>
        <CustomDropdown
          boxSx={{ width: '50%' }}
          required
          labelName="Cost center"
          fieldName="costCenter"
          placeholder="เลือก Cost center"
        />
        <LabelWithValue
          label="Cost center Name"
          fieldName="costCenter.descriptionName"
        />
      </Box>
      <Box display="flex" gap={3} alignItems="center">
        <CustomDropdown
          boxSx={{ flexGrow: 1, width: 'auto' }}
          required
          labelName="Account code (Old Code / New Code)"
          fieldName="accountCode"
          placeholder="เลือก Account code"
        />
        <LabelWithValue
          boxSx={{ flexGrow: 1 }}
          label="Description"
          fieldName="accountCode.description"
        />
        <LabelWithValue
          boxSx={{ flexGrow: 1 }}
          label="Categories"
          fieldName="accountCode.category.name"
        />
      </Box>
      <LabelWithValue
        boxSx={{ flexGrow: 1 }}
        label="Definition TH"
        fieldName="accountCode.definitionTH"
      />
      <LabelWithValue
        boxSx={{ flexGrow: 1 }}
        label="Definition EN"
        fieldName="accountCode.definitionENG"
      />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />

      <CustomTextArea fieldName="remark" labelName="หมายเหตุ" />
    </StyledCard>
  )
}
