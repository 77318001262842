import React, { useState } from 'react'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { path } from '../../../../../constants/path'
import { COURSE_STATUS } from '../../../../../constants/eLearning'
import { validateEPermission } from '../../../../../utils/lib'
import { Header } from '../../../components/View'
import HistoryDrawer from '../History'
import { handleOnPreview } from './events'

const HeaderContent = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const [, setELearning] = useLocalStorage('eLearningPreview')
  const [isOpen, setOpen] = useState(false)
  const result = useSelector(
    (state) => state.eLearningModuleDetail.result,
    shallowEqual,
  )
  const isDeleted = _.get(result, 'status', '') === COURSE_STATUS.DELETED
  const isHeadAdmin = validateEPermission({
    module: 'E_LEARNING',
    permission: 'HEAD_ADMIN',
    data: result,
  })

  return (
    <>
      <Header
        isDeleted={isDeleted}
        onPreview={() => handleOnPreview(result, setELearning)}
        onOpenDrawer={() => setOpen(true)}
        onEdit={() => history.push(`${path.eLearning}/module/form/${uuid}`)}
        permission={{ hasEdit: isHeadAdmin }}
      />
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </>
  )
}

export default HeaderContent
