import { store } from '../../../../../../../App'
import { TESTRESULT } from '../model/testResult'

export const mutateFilterSateToProp = (versionUuid) => {
  const { filterState } = store.getState().table
  let testResult = []
  if (filterState.testResultChecked) {
    if (filterState.testResult.passChecked) {
      testResult.push(TESTRESULT.PASS)
    }
    if (filterState.testResult.failChecked) {
      testResult.push(TESTRESULT.NOT_PASS)
    }
    if (filterState.testResult.waitingChecked) {
      testResult.push(TESTRESULT.WAITING)
      testResult.push(TESTRESULT.NOT_SENT)
    }
  } else {
    testResult = []
  }

  return {
    eTestVersionUuid: versionUuid,
    distribution: filterState.distributionChecked
      ? filterState.distribution
      : '',
    startDate: filterState.startDateChecked ? filterState.startDate : '',
    endDate: filterState.startDateChecked ? filterState.endDate : '',
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    startTestDate: filterState.startTestDateChecked
      ? filterState.startTestDate
      : '',
    endTestDate: filterState.startTestDateChecked
      ? filterState.endTestDate
      : '',
    unitCode: filterState.unitCodeChecked ? filterState.unitCode : '',
    sRam: filterState.sRamChecked ? filterState.sRam : '',
    ramCode: filterState.ramCodeChecked ? filterState.ramCode : '',
    scoreFrom: filterState.scoreChecked ? filterState.scoreFrom : '',
    scoreTo: filterState.scoreChecked ? filterState.scoreTo : '',
    testResult: testResult,
  }
}
