export const borrowSteps = ['เลือก Department', 'เลือกหน้าที่']

export const initialResponsibility = {
  responsibility: { uuid: '' },
  numSpeaker: '',
  skillSpeaker: '',
  startTime: '',
  endTime: '',
  remark: '',
  status: 'DRAFT',
}

export const initialValues = {
  requester: {},
  approver: [],
  department: {},
  station: '',
  purpose: '',
  startDate: Date.now(),
  finishDate: Date.now(),
  status: 'DRAFT',
  speakerBorrowResponsibility: [],
}
