import { CPageContainer } from '../../../../components/CRUD/components/PageContainer'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { breadcrumb } from './model/breadcrumb'
import { Content } from './Content'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { setFieldValue } from '../../../../redux/slices/crud'
import { getBodyMutation } from './mutation/get'
import { getModuleTitle } from '../../../../modules/EContent/utils'

export const CostCenterForm = () => {
  const { uuid } = useParams()
  const { crudConfig } = useSelector(
    (state) => ({ crudConfig: state.crud.crudConfig }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const initializeCrud = () => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation).then()
    } else {
      dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
    }
  }

  useEffect(initializeCrud, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CPageContainer
      gap="3"
      header={
        <CHeader
          title={getModuleTitle(uuid, crudConfig.moduleTitleFull)}
          breadcrumbs={breadcrumb(uuid)}
        />
      }
      content={<Content />}
    />
  )
}
