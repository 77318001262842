import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Header = styled(Box)(({ theme, img }) => ({
  position: 'relative',
  width: '100%',
  height: '674px',
  backgroundImage: `url(${img})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  marginTop: '-14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: { height: '270px' },
}))

export const HeaderSection = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '674px',
  background: 'rgba(255, 255, 255, 0.45)',
  mixBlendMode: 'lighten',
}))

export const TopicSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1,
  maxWidth: 1146,
  textAlign: 'center',
}))
