import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  attendanceStartTimer,
  attendance,
  getAttendanceLearnerList,
  attendanceCheck,
  attendanceList,
} from '../../utils/apiPath'

export const getDataAttendance = createAsyncThunk(
  'manageClass/attendance',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${attendance}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const getLearnerList = createAsyncThunk(
  'manageClass/attendance/list/learner',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${getAttendanceLearnerList}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putAttendanceStartTimer = createAsyncThunk(
  'attendance/startTimer',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(attendanceStartTimer, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putAttendanceCheck = createAsyncThunk(
  'manageClass/attendance/check',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(attendanceCheck, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postDataAttendance = createAsyncThunk(
  'manageClass/attendance/list',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(attendanceList, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
