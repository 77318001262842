import _ from 'lodash'
import { COLLECTION_SECTION_KEY } from '../components/ELearningCourseCard/model'

export const handleCourseValue = (course) => {
  let courseData = {}
  const type = _.get(course, 'content.type', '')
    .toUpperCase()
    .replace(/ /g, '_')

  if (COLLECTION_SECTION_KEY[type] == COLLECTION_SECTION_KEY.ELEARNING) {
    courseData = {
      ...course,
      content: {
        ...course.content,
        hours: _.get(course, 'content.totalHour', 0),
        minutes: _.get(course, 'content.totalMinute', 0),
        lessonAmount: _.get(course, 'content.eLearningCourseLesson', 0).length,
      },
    }
  } else if (
    COLLECTION_SECTION_KEY[type] == COLLECTION_SECTION_KEY.LEARNING_PATH
  ) {
    courseData = {
      ...course,
      content: {
        ...course.content,
        hours: _.get(course, 'content.totalHour', 0),
        minutes: _.get(course, 'content.totalMinute', 0),
        courseCount: _.get(course, 'content.eLearningLearningPathCourses', 0)
          .length,
      },
    }
  } else {
    courseData = {
      ...course,
      content: {
        ...course.content,
        hasCertificate: _.get(course, 'eCertificationVersion', null) !== null,
      },
    }
  }

  return {
    courseData: courseData,
    sectionKey: COLLECTION_SECTION_KEY[type],
  }
}
