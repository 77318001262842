import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import * as Styled from './Styled'

import { StyledColumn } from '../../../../HomePage/Styled'
import { StyledInputHidden } from '../../../../HomePage/Form/Banner/BannerDrawer/ImageUploader/Styled'
import { handleUploadImage } from './events'
import UploadLoading from '../../../../UploadLoading'

const UploadImage = () => {
  const dispatch = useDispatch()
  const { values, errors, setValues } = useFormikContext()
  const { error, isUploadLoading } = useSelector(
    (state) => ({
      error: state.manageContentLogin.error,
      isUploadLoading: state.manageContentLogin.isUploadLoading,
    }),
    shallowEqual
  )
  const media = _.get(values, 'image', '')
  const errorText = _.get(error, 'upload', '') || _.get(errors, 'image', '')
  return (
    <Styled.BoxOutlinedContent error={!_.isEmpty(errorText)}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          Image <span>*</span>
        </Typography>
      </Box>
      <StyledColumn>
        <Styled.BoxContentImage>
          {isUploadLoading && <UploadLoading id="loading-image" />}
          {!isUploadLoading && media !== '' && (
            <Styled.StyledImage>
              <img src={`${window.__env__.REACT_APP_API_URL}/file${media}`} />
            </Styled.StyledImage>
          )}

          <Styled.BoxContentUpload media={media}>
            <Styled.BoxButtonUpload>
              <label htmlFor="input-upload">
                <Button variant="contained" size="s" component="span">
                  อัปโหลดรูปภาพ
                </Button>
                <StyledInputHidden
                  data-testid="input-upload"
                  id="input-upload"
                  accept=".jpg, .jpeg, .png"
                  type="file"
                  onChange={(e) => dispatch(handleUploadImage(e, setValues))}
                />
              </label>
              {media != '' && (
                <Button
                  data-testId="btn-delete"
                  variant="outlined"
                  size="s"
                  onClick={() =>
                    setValues((prev) => ({
                      ...prev,
                      image: '',
                    }))
                  }
                >
                  ลบรูปภาพ
                </Button>
              )}
            </Styled.BoxButtonUpload>
            <Box>
              <Typography variant="body2" color="text.secondary">
                ขนาดแนะนำ 1400 x 780 px, ไฟล์ไม่เกิน 2MB, นามสกุลไฟล์ที่รองรับ
                .jpg, .jpeg, .png
              </Typography>
            </Box>
          </Styled.BoxContentUpload>
        </Styled.BoxContentImage>
        {errorText !== '' && (
          <Typography mt={0.5} variant="body2" color="error">
            {errorText}
          </Typography>
        )}
      </StyledColumn>
    </Styled.BoxOutlinedContent>
  )
}
export default UploadImage
