import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import { validationSchema } from './FormSchema'
import { levelOfLearner } from '../../utils/apiPath'
import Breadcrumbs from '../../components/Breadcrumbs'
import { callAPI } from '../../utils/lib'
import { onView } from './LevelOfLearnerList'

const LevelOfLearnerForm = ({ isEditForm }) => {
  const titleText = isEditForm
    ? 'แก้ไขระดับของผู้เรียน'
    : 'เพิ่มระดับของผู้เรียน'

  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ระดับของผู้เรียน',
      link: '/master-data/level-of-learner',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
  const { id: uuid } = useParams()
  const {
    context,
    setContext: setDialog,
    setCloseDialog,
  } = useContext(DialogContext)
  const dialog = context.dialog
  const history = useHistory()
  const [displayTitle, setDisplayTitle] = useState('')
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: '',
    title: '',
    status: true,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        isLoading: false,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () => submitLevelOfLearner(values, onView),
        onCancelClick: () => setCloseDialog(),
      })
    },
  })

  const onCancelClick = () => {
    setDialog({ ...dialog, open: false })
  }

  const submitLevelOfLearner = async (values, onView) => {
    setDialog({
      ...dialog,
      open: true,
      title: 'ยืนยันบันทึกข้อมูล',
      content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      isLoading: true,
    })
    const methodType = isEditForm ? 'put' : 'post'
    const body = isEditForm
      ? {
          ...values,
          uuid: uuid,
          title: values.title,
          status: values.status === true ? 'ACTIVE' : 'INACTIVE',
        }
      : {
          title: values.title,
          status: values.status === true ? 'ACTIVE' : 'INACTIVE',
        }
    callAPI({
      method: methodType,
      url: levelOfLearner,
      body: body,
      onSuccess: (data) => {
        const uuidLink = isEditForm ? uuid : data?.uuid
        setDialog({
          open: true,
          isLoading: false,
          title: 'สำเร็จ',
          content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          variant: 'success',
          onConfirmClick: () => {
            setDialog({ open: false })
            onView({ uuid: uuidLink }, history)
          },
        })
      },
      onError: () => {
        setDialog({
          open: false,
          isLoading: false,
          variant: '',
        })
      },
    })
  }

  const fetchLevelOfLearnerById = async (uuid) => {
    callAPI({
      method: 'get',
      url: levelOfLearner + `/${uuid}`,
      onSuccess: (data) => {
        const { title, status, id } = data
        setInitialValues({
          ...data,
          id: id,
          title: title,
          status: status.toString().toUpperCase() === 'ACTIVE' || false,
        })
        setDisplayTitle(title)
        formik.setFieldValue('id', id)
        formik.setFieldValue('title', title)
        formik.setFieldValue(
          'status',
          status.toString().toUpperCase() === 'ACTIVE' || false
        )
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  useEffect(() => {
    if (isEditForm) {
      setIsPageLoading(true)
      fetchLevelOfLearnerById(uuid)
    }
  }, [isEditForm])

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={onCancelClick}
          onSubmit={submitLevelOfLearner}
          formik={formik}
          isLoading={isPageLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default LevelOfLearnerForm
