import React from 'react'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(2) },
    },
  })
)
