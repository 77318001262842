import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../modules/ELearning/components/LoadingPageCircular'
import { StyledFormWrapper } from '../Styled'
import { schema } from './schema'
import { fetchCatalogDetail, handleSubmit } from './events'
import Header from './Header'
import Info from './Info'
import Creator from './Creator'

const CatalogForm = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.catalogForm.isLoading,
    shallowEqual,
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])
  useEffect(() => {
    dispatch(fetchCatalogDetail({ uuid, onEdit, setOnEdit }))
  }, [])
  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, actions) =>
            dispatch(handleSubmit(values, history, actions))
          }
        >
          <Form>
            <Header />
            <StyledFormWrapper>
              <Info />
              <Creator />
            </StyledFormWrapper>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default CatalogForm
