import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Background from './../../../../components/Preview/Background'
import Header from './Header'
import Footer from './Footer'
import Questions from './Questions'
import { fetchTesting } from './events'
import Result from './Result'

const TestingForm = ({ isModule }) => {
  return (
    <>
      <Questions />
      <Footer isModule={isModule} />
    </>
  )
}

const Testing = ({ isModule }) => {
  const [eTesting] = useLocalStorage('eTesting')
  const dispatch = useDispatch()
  const section = useSelector(
    (state) => state.eTestingPreview.section,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchTesting(eTesting, isModule))
  }, [])

  return (
    <>
      <Header />
      <Background withHeader>
        {section == 'TESTING' && <TestingForm isModule={isModule} />}
        {section == 'RESULT' && <Result isModule={isModule} />}
      </Background>
    </>
  )
}

export default Testing
