export const defaultFilter = {
  versionChecked: false,
  version: '',
  nameChecked: false,
  name: '',
  startDateChecked: false,
  startDate: '',
  toDate: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    draftChecked: false,
    waitingChecked: false,
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
