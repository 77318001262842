import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}))

export const LabelTitle = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.text?.secondary,
}))
