import React, { useRef, useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'

import { handleClose, handleMoreMenu } from './events'
import { StyledPopper } from './Styled'
import { MORE_DETAIL_OPTIONS } from '../../../../../constants/apiToRcms'
import { handleInputFile } from '../events'
import { validateEPermission } from '../../../../../../src/utils/lib'
import {
  AGENT_LICENSE_SETTING,
  PERMISSION,
} from '../../../../../../src/constants/permission/apiToRcms'

export const moreOptions = MORE_DETAIL_OPTIONS

const DropdownButton = ({ onHistoryClick, fetchData, id }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const dispatch = useDispatch()

  const hasEditPermission = validateEPermission({
    module: AGENT_LICENSE_SETTING,
    permission: PERMISSION.EDIT,
  })

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        color="primary"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem>
                  {moreOptions.map((option, index) => (
                    <>
                      {option.method === 'import' && hasEditPermission ? (
                        <MenuItem
                          key={index}
                          component="label"
                          data-testid={`menu-${index}`}
                        >
                          <Typography variant="body1" component="span">
                            {option.text}
                          </Typography>
                          <input
                            type="file"
                            hidden
                            id="btn-add-file"
                            onChange={(e) =>
                              dispatch(handleInputFile(e, fetchData, id))
                            }
                          />
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key={index}
                          component="span"
                          data-testid={`menu-${index}`}
                          onClick={() => {
                            dispatch(
                              handleMoreMenu(option.method, onHistoryClick)
                            )
                          }}
                        >
                          <Typography variant="body1" component="span">
                            {option.text}
                          </Typography>
                        </MenuItem>
                      )}
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
