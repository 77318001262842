export const arrayProspectStatus = [
  {
    label: 'Inactive',
    name: 'inActiveChecked',
  },
  {
    label: 'Active',
    name: 'activeChecked',
  },
  {
    label: 'Deleted',
    name: 'deletedChecked',
  },
  {
    label: 'โอนย้าย',
    name: 'stealChecked',
  },
  {
    label: 'Terminated',
    name: 'terminatedChecked',
  },
]

export const arrayStatus = [
  {
    label: 'ยังไม่เป็นตัวแทน',
    name: 'activeChecked',
  },
  {
    label: 'เป็นตัวแทนแล้ว',
    name: 'inActiveChecked',
  },
]

export const arrayCsStatus = [
  {
    label: 'ยังเป็นพนักงานอยู่',
    name: 'activeChecked',
  },
  {
    label: 'ลาออกแล้ว',
    name: 'inActiveChecked',
  },
]

export const arrayProspectCheckbox = [
  {
    label: 'Temp Code',
    nameCheckBox: 'tempCodeChecked',
    nameInput: 'tempCode',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'เบอร์โทรศัพท์',
    nameCheckBox: 'mobileNoChecked',
    nameInput: 'mobileNo',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'อีเมล',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'Unit Number',
    nameCheckBox: 'unitNumberChecked',
    nameInput: 'unitNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'การเป็นตัวแทน',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'agentChecked',
    nameInput: 'agent',
    type: 'checkbox2',
  },
]

export const arrayCheckbox = [
  {
    label: 'Temp Code',
    nameCheckBox: 'tempCodeChecked',
    nameInput: 'tempCode',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'เบอร์โทรศัพท์',
    nameCheckBox: 'mobileNoChecked',
    nameInput: 'mobileNo',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'อีเมล',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'Unit Number',
    nameCheckBox: 'unitNumberChecked',
    nameInput: 'unitNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]

export const defaultFilter = {
  tempCodeChecked: false,
  nameChecked: false,
  lastNameChecked: false,
  mobileNoChecked: false,
  statusChecked: false,
  emailChecked: false,
  unitNumberChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
  },
  agentChecked: false,
  agent: {
    inActiveChecked: false,
    activeChecked: false,
    deletedChecked: false,
    stealChecked: false,
    terminatedChecked: false,
  },
}

export const arrayCheckboxCsUser = [
  {
    label: 'Temp Code',
    nameCheckBox: 'tempCodeChecked',
    nameInput: 'tempCode',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'เบอร์โทรศัพท์',
    nameCheckBox: 'mobileNoChecked',
    nameInput: 'mobileNo',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'อีเมล',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'Broker Number',
    nameCheckBox: 'unitNumberChecked',
    nameInput: 'unitNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะการทำงาน',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]
