export const historyEvent = {
  CREATED: 'สร้าง',
  UPDATED: 'แก้ไข',
  DELETED: 'ลบ',
  CANCELED: 'ยกเลิก',
  APPROVED_REQUISITION: 'อนุมัติการเบิก',
  REJECTED_REQUISITION: 'ปฏิเสธการเบิก',
  WAITING_RETURN: 'รออนุมัติคืน',
  REJECTED_RETURN: 'ปฏิเสธการคืน',
  COMPLETED: 'เสร็จสิ้น',
  APPROVED_TRANSFER: 'อนุมัติการโอน',
  EDITED_APPROVED_TRANSFER: 'แก้ไขและอนุมัติการโอน',
  REJECTED_TRANSFER: 'ปฏิเสธการโอน',
}
