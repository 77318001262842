import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { MODULE_STATUS } from '../../../../../../../constants/eLearning'
import { StyledBoxContent } from './Styled'

const StatusSwitch = () => {
  const { values, setFieldValue } = useFormikContext()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">สถานะเปิดปิดการรับสมัคร</Typography>
      <StyledBoxContent>
        <Typography>เปิดปิดการรับสมัคร</Typography>
        <Switch
          name="onShelfStatus"
          data-testId="switch-onShelfStatus"
          checked={_.get(values, 'onShelfStatus', '') === MODULE_STATUS.ACTIVE}
          onChange={(e) =>
            setFieldValue(
              'onShelfStatus',
              e.target.checked ? MODULE_STATUS.ACTIVE : MODULE_STATUS.INACTIVE,
            )
          }
        />
      </StyledBoxContent>
    </Box>
  )
}

export default StatusSwitch
