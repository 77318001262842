import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import {
  examinationDashboardTopDownload,
  staffProfileDashboardLoginHistory,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import dayjs from 'dayjs'
// import { setFieldValue } from '../../../../../redux/slices/crud'

export const headCellsLogin = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
  },
  {
    id: 'user.prefixTH',
    label: 'คำนำหน้า',
    disablePadding: false,
  },
  {
    id: 'fullName',
    label: 'ชื่อ-สกุล',
    disablePadding: false,
  },
  {
    id: 'dateTime',
    label: 'Last Login',
    disablePadding: false,
  },
  {
    id: 'device',
    label: 'Device',
    disablePadding: false,
  },
  {
    id: 'browser',
    label: 'Browser',
    disablePadding: false,
  },
]

export const fetchData =
  ({ table }) =>
  async (dispatch) => {
    const { manageProfileDashboard } = store.getState().crud
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsLogin
    const checkSortValue = _.find(headCells, (h) => {
      return h.id === _.get(table, 'sort')
    })
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        startDate: _.defaultTo(
          manageProfileDashboard?.filtersDrawer?.startDate,
          dayjs().startOf('month').format('YYYY-MM-DD'),
        ),
        endDate: _.defaultTo(
          manageProfileDashboard?.filtersDrawer?.endDate,
          dayjs().format('YYYY-MM-DD'),
        ),
        order: _.get(table, 'order', 'DESC').toString().toUpperCase(),
        sort: _.isEmpty(checkSortValue)
          ? 'dateTime'
          : _.get(table, 'sort', 'dateTime'),
      }
      await callAxios
        .post(staffProfileDashboardLoginHistory, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          dispatch(
            setInitialTable({
              rows: prepareRow(results, _.get(table, 'limit', '100')),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              hideFilter: true,
              isCheckBox: false,
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload =
  (selected, examType, dataType) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      examType: examType,
      dataType: dataType,
      list: selected,
    }

    const typeName =
      dataType === 'schedule'
        ? 'สนามสอบเปิดรอบมากที่สุด'
        : 'สนามสอบผู้สมัครมากที่สุด'
    const examTypeName = examType === 'company' ? 'บริษัท' : 'สมาคม'

    await dispatch(
      downloadFile({
        url: examinationDashboardTopDownload,
        body: body,
        fileName: `รายการ${typeName}_${examTypeName}.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
    }
  })
}
