import { CustomDateInput } from '../../../../../../components/CRUD/components/CustomDateInput'
import { CustomCheckbox } from '../../../../../../components/CRUD/components/CustomCheckbox'
import { handleCheckEndDate } from '../../handler/handleCheckEndDate'
import { handleEndDateChange } from '../../handler/handleEndDateChange'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, Typography } from '@mui/material'

export const InputDateGroup = () => {

  const { checkEndDate } = useSelector(
    (state) => ({
      checkEndDate: state.crud.formData.checkEndDate,
    }),
    shallowEqual,
  )

  return (
    <>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDateInput
          fieldName="startDate"
          labelName="วันที่เริ่มต้น"
          // handleExtraChange=""
          placeholder="เลือกวันที่"
          required={false}
        />
        <CustomDateInput
          fieldName="endDate"
          labelName="วันที่สิ้นสุด"
          handleExtraChange={handleEndDateChange}
          placeholder="เลือกวันที่"
          required={false}
        />
      </Box>
      <Box display="flex" gap={3} alignItems="center">
        <Box sx={{ width: '49%' }}>
          <CustomDateInput
            disabled={checkEndDate}
            fieldName="renewDate"
            labelName="วันที่ต่อสัญญา"
            // handleExtraChange=""
            placeholder="เลือกวันที่"
            required={false}
          />
          <Typography mt={'-20px'} variant="body2" color={'text.secondary'}>
            (ระบบจะส่ง E-mail แจ้งเตือนหากมีการระบุวันที่ต่อสัญญา)
          </Typography>
        </Box>

        <CustomCheckbox
          fieldName="checkEndDate"
          labelText="วันเดียวกับวันที่สิ้นสุด"
          handleExtraChange={handleCheckEndDate}
        />
      </Box>
    </>
  )
}
