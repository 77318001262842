import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/manageClassDetail'
import { getHistory } from '../../../../../../services/manageClass/form'
import { headCellsEvent } from '../../../../../../constants/manageClass'
import { setInitialTable } from '../../../../../../redux/slices/table'
import { StyledTable } from './Styled'

export const fetchManageClassEvent = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`

  dispatch(startLoading())
  const result = await dispatch(getHistory(url))
  const payload = _.get(result, 'payload', undefined)
  if (payload) {
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows: _.get(payload, 'data.result', []),
        allCount: _.get(payload, 'data.totalCount', 0),
        headCells: headCellsEvent,
        customStyle: StyledTable,
      })
    )
  }
  dispatch(stopLoading())
}
