import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialStateBasic } from './model'

const basicTableSlice = createSlice({
  name: 'basicTable',
  initialState: initialStateBasic,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialTable: (state, { payload }) => {
      state.isCheckBox = _.get(payload, 'isCheckBox', true)
      state.rows = _.get(payload, 'rows', [])
      state.allCount = _.get(payload, 'allCount', 0)
      state.headCells = _.get(payload, 'headCells', [])
      state.searchKey = _.get(payload, 'searchKey', '')
      state.isShowPagination = _.get(payload, 'isShowPagination', true)
      state.sxTable = _.get(payload, 'sxTable', {})
      state.tableHeadText = _.get(payload, 'tableHeadText', null)
    },
    setDefaultSort: (state, { payload }) => {
      state.defaultSort = payload
    },
    setOrder: (state, { payload }) => {
      state.order = payload.order
      state.sort = payload.sort
    },
    setChangeRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload.rowsPerPage
      state.limit = payload.limit
      state.tablePage = 0
      state.page = 1
    },
    setChangePage: (state, { payload }) => {
      state.page = payload.page
      state.tablePage = payload.tablePage
    },
    resetTablePage: (state) => {
      state.tablePage = 0
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialTable,
  setDefaultSort,
  setOrder,
  setChangeRowsPerPage,
  setChangePage,
  resetTablePage,
} = basicTableSlice.actions

export default basicTableSlice.reducer
