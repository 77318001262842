import _ from 'lodash'
import {
  setDateQueryStringCalendar,
  setReduxObjectValue,
  setReduxValue,
  setRoomDetails,
  startLoadingDetails,
  stopLoadingDetails,
} from '../../../redux/slices/roomManagement/Dashboard'
import {
  getMeetingRoomById,
  getRoomDetailCalendarById,
} from '../../../services/masterData/meetingRoom'
import { store } from '../../../App'
import { enumRequestType } from './Content/CalendarCard/constanst'
import * as dayjs from 'dayjs'

export const fetchRoomDetails = (uuid) => async (dispatch) => {
  dispatch(startLoadingDetails())
  const data = await dispatch(getMeetingRoomById(uuid))
  const result = _.get(data, 'payload', {})

  dispatch(setRoomDetails(result))
  dispatch(stopLoadingDetails())
}

export const fetchRoomDetailCalendar = () => async (dispatch) => {
  const state = store.getState()
  const { viewCalendar, mondayDate, monthYear } = state.roomManagement
  const { uuid } = state.roomManagement.detail
  const { filterBookingType, filterTopicQuery } =
    state.roomManagement.drawerCalendarFilter

  dispatch(setReduxValue({ key: 'isCalendarLoading', value: true }))
  const response = await dispatch(
    getRoomDetailCalendarById({
      uuid: uuid,
      monthOrWeek: viewCalendar === 'dayGridMonth' ? 'month' : 'week',
      monthYear,
      requestType: filterBookingType,
      courseOrTopic: filterTopicQuery,
      mondayDate,
    }),
  )
  const result = _.get(response, 'payload', {})

  dispatch(setReduxValue({ key: 'roomDetailCalendar', value: result.data }))
  dispatch(setReduxValue({ key: 'isCalendarLoading', value: false }))
}

export const handleCloseImageSlider = () => (dispatch) => {
  dispatch(
    setReduxValue({
      key: 'isDrawerImageSliderOpen',
      value: false,
    }),
  )
}

export const handleOpenImageSlider = (index) => (dispatch) => {
  dispatch(
    setReduxValue({
      key: 'imageSliderSelectedIndex',
      value: index,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'isDrawerImageSliderOpen',
      value: true,
    }),
  )
}

export const getRoomtypeText = ({
  capacityGroup,
  capacityTheater,
  capacityUShape,
}) => {
  const roomTypeList = getRoomtype({
    capacityGroup,
    capacityTheater,
    capacityUShape,
  })
  if (roomTypeList.length > 0) {
    return `+ ${roomTypeList.length}`
  }
  return ''
}
export const getRoomtype = ({
  capacityGroup,
  capacityTheater,
  capacityUShape,
}) => {
  const roomtypeList = []
  if (capacityGroup !== null) roomtypeList.push('Group')
  if (capacityTheater !== null) roomtypeList.push('Theater')
  if (capacityUShape !== null) roomtypeList.push('U Shape')
  return roomtypeList
}

export const mutateResultToEvent = (roomDetailCalendar) => {
  return _.flatten(
    roomDetailCalendar
      ?.filter((item) => item['timeAndTopic'].length > 0)
      ?.map((item) =>
        _.uniqBy(item['timeAndTopic'], 'uuid').map((topic) => ({
          start: `${item.date}T${topic.startTime}`,
          end: `${item.date}T${topic.endTime}`,
          title: topic.bookingTopic,
          requestType: topic.requestType,
          detail: topic,
        })),
      ),
  )
}

export const renderEvent = (info) => {
  switch (info.event._def.extendedProps.requestType) {
    case enumRequestType.E_BOOKING:
      info.el.classList.add('event-e-booking')
      break
    case enumRequestType.MANAGE_CLASS:
      info.el.classList.add('event-manage-class')
      break
    case enumRequestType.MONTHLY_PLAN:
      info.el.classList.add('event-monthly-plan')
      break
    case 'HOLIDAY':
      info.el.classList.add('event-holiday')
      break
    default:
      break
  }
  const startTime = _.get(info, 'event._def.extendedProps.detail.startTime', '')
  const endTime = _.get(info, 'event._def.extendedProps.detail.endTime', '')
  if (
    info.el.getElementsByClassName('fc-time').length &&
    startTime === '00:00' &&
    endTime === '23:59'
  ) {
    info.el.getElementsByClassName('fc-time')[0].innerHTML = 'ทั้งวัน'
  }
  return info.el
}

export const handleSelectRequestType = (checked, type) => (dispatch) => {
  const state = store.getState()
  const { filterBookingType } = state.roomManagement.drawerCalendarFilter
  dispatch(
    setReduxObjectValue({
      objectKey: 'drawerCalendarFilter',
      key: 'filterBookingType',
      value: checked
        ? [...filterBookingType, enumRequestType[type]]
        : filterBookingType.filter((item) => item !== enumRequestType[type]),
    }),
  )
}

export const handleSelectStation = (checked, stationUuid) => (dispatch) => {
  const state = store.getState()
  const { filterStation } = state.roomManagement.drawerCalendarFilter
  dispatch(
    setReduxObjectValue({
      objectKey: 'drawerCalendarFilter',
      key: 'filterStation',
      value: checked
        ? [...filterStation, stationUuid]
        : filterStation.filter((item) => item !== stationUuid),
    }),
  )
}

export const handleOpenDrawerFilterCalendar = () => (dispatch) => {
  const state = store.getState()
  const { drawerCalendarFilter } = state.roomManagement
  dispatch(
    setReduxValue({
      key: 'cacheDrawerCalendarFilter',
      value: drawerCalendarFilter,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'isDrawerCalendarFilterOpen',
      value: true,
    }),
  )
}

export const handleCloseDrawerFilterCalendar = () => (dispatch) => {
  const state = store.getState()
  const { cacheDrawerCalendarFilter } = state.roomManagement
  dispatch(
    setReduxValue({
      key: 'isDrawerCalendarFilterOpen',
      value: false,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'drawerCalendarFilter',
      value: cacheDrawerCalendarFilter,
    }),
  )
}

export const handleClickEvent = (info) => (dispatch) => {
  const bounding = info.el.getBoundingClientRect()
  dispatch(
    setReduxValue({
      key: 'anchorElTop',
      value: bounding.top,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'anchorElLeft',
      value: bounding.right,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'PopupEventSummary',
      value: info.event.extendedProps.detail,
    }),
  )
  dispatch(
    setReduxValue({
      key: 'isPopupEventSummary',
      value: true,
    }),
  )
}

export const handleDateRender = (arg) => (dispatch) => {
  if (arg.view['type'].toString() === 'timeGridWeek') {
    dispatch(
      setReduxValue({
        key: 'mondayDate',
        value: dayjs(arg.view.activeStart).toISOString(),
      }),
    )
    dispatch(
      setReduxValue({
        key: 'endDate',
        value: dayjs(arg.view.activeEnd).subtract(1, 'days').toISOString(),
      }),
    )
  } else {
    dispatch(
      setReduxValue({
        key: 'monthYear',
        value: dayjs(arg.view.currentStart).toISOString(),
      }),
    )
    dispatch(setDateQueryStringCalendar(''))
  }
}
