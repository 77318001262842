import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'

import Image from '../Image'
import Video from '../Video'
import TextInput from '../../../../../components/Input/TextInput'
import SelectIcon from '../../../../../components/redux/SelectIcon'
import { listChoice } from '../../../../../constants/eTesting'
import { closeDialog } from '../../../../../redux/slices/dialog'
import { getErrorMessage } from '../event'
import * as Styled from './Styled'
import * as event from './event'
import {
  handleIconUploadImage,
  IconUploadImage,
  handleIconVideo,
} from '../event'
import { TooltipForm } from '../Styled'

const Index = ({ keyQuestion, indexQuestion }) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)
  const {
    question,
    errors: errorsETesting,
    disableForm,
    errorSchemaMessage,
  } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errors: state.eTestingForm.errors,
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { title, url, questionType, loadingProgress, mediaType } = question
  const questionErrorMessage = getErrorMessage({
    field: 'QUESTION',
    errorSchemaMessage,
    indexQuestion,
  })

  useEffect(() => {
    if (!errorsETesting?.video) dispatch(closeDialog())
  }, [errorsETesting?.video, mediaType, url])

  return (
    <>
      <Styled.BoxInputQuestion>
        <Styled.BoxInput>
          <Typography variant="h6">{indexQuestion + 1}.</Typography>
          <TextInput
            id="title-question"
            name="title"
            labelText={`\xA0`}
            type="basic"
            maxRows={4}
            // sxTextError={!questionErrorMessage ? { height: '16px' } : {}}
            multiline={true}
            disabled={disableForm}
            placeholder="คำถาม"
            onBlur={(e) =>
              dispatch(
                event.handleChangeQuestion({
                  keyQuestion,
                  key: 'title',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            defaultValue={title}
            textError={questionErrorMessage}
          />
        </Styled.BoxInput>
        <Styled.BoxSelect>
          <IconUploadImage
            isQuestion={true}
            disabled={disableForm}
            testId={`icon-question-${keyQuestion}`}
            onUpload={(e) =>
              dispatch(
                handleIconUploadImage({
                  data: e,
                  keyQuestion,
                  keyValue: 'url',
                  testId: `icon-question-${keyQuestion}`,
                })
              )
            }
          />
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">วิดีโอ</Typography>}
          >
            <IconButton
              onMouseLeave={() => setIsHover(false)}
              onMouseOver={() => setIsHover(true)}
              onClick={() =>
                !disableForm && dispatch(handleIconVideo(keyQuestion))
              }
            >
              <VideocamOutlined color={isHover ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>

          <SelectIcon
            boxSx={{ mt: 1 }}
            labelText=""
            listOption={listChoice}
            disabled={disableForm}
            handleChange={(e) => {
              dispatch(
                event.handleChangeTypeQuestion({
                  keyQuestion,
                  questionType,
                  value: _.get(e, 'target.value', ''),
                })
              )
            }}
            value={questionType}
          />
        </Styled.BoxSelect>
      </Styled.BoxInputQuestion>

      {loadingProgress != null && mediaType === 'IMAGE' && (
        <Image
          uploadState={question}
          keyState={{ keyQuestion, key: 'url' }}
          value={url}
          disableForm={disableForm}
        />
      )}
      {mediaType === 'VIDEO' && url && (
        <Video url={url} keyQuestion={keyQuestion} disableForm={disableForm} />
      )}
    </>
  )
}
export default Index
