import { store } from '../../../App'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { fetchReports } from './fetchReports'

export const handleChangePage = (event, newPage) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  store.dispatch(setReduxValue({ key: 'page', value: newPage }))
  store.dispatch(setReduxValue({ key: 'tablePage', value: tablePage }))
  fetchReports()
}
