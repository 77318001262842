export const defaultFilter = {
  groupCodeChecked: false,
  groupCode: '',
  costCenterChecked: false,
  costCenter: '',
  nameChecked: false,
  name: '',
  createDateChecked: false,
  createStartDate: '',
  createFinishDate: '',
  updateDateChecked: false,
  updateStartDate: '',
  updateFinishDate: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
