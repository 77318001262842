import React from 'react'
import { CustomTextEditor } from '../../../../../../../components/CRUD/components/CustomTextEditor'

const Text = ({ index }) => {
  return (
    <>
      <CustomTextEditor fieldName={`contents[${index}].content.text`} />
    </>
  )
}

export default Text
