import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import Select from '../../components/Input/Select'
import { useLocalStorage } from '@rehooks/local-storage'
import { hasPermissionCreateMasterData, getOptionsKey } from '../../utils/lib'
import TextInput from '../../components/Input/TextInput'
import AlertDialog from '../../components/AlertDialog'
import SwitchInput from '../../components/Input/SwitchInput'

import _ from 'lodash'

const jobTitleForm = ({
  isEditForm,
  dialog,
  setDialog,
  handleClose,
  formik,
  isLoading,
  initialValues,
  setInitialValues,
  getLevel,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })

  const levelOptions =
    getLevel &&
    getOptionsKey({
      data: getLevel,
      label: 'level',
      key: 'uuid',
    })

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              boxSx={{ mx: 3 }}
              value={initialValues.id}
            ></TextInput>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Select
            dataTestid="select-level"
            id="level"
            name="level"
            labelText="Level"
            type="basic"
            required
            disabled={!hasPermissionCreate}
            placeholder={'เลือก'}
            boxSx={{ mx: 3 }}
            options={!_.isNil(levelOptions) ? levelOptions : []}
            value={initialValues.level.uuid}
            handleChange={(e) => {
              formik.values.level = e.target.value
              setInitialValues({
                ...initialValues,
                level: e.target.value,
              })
            }}
            textError={formik?.errors?.level}
          />
          <TextInput
            id="jobTitle"
            name="jobTitle"
            labelText="Job Title"
            type="basic"
            inputProps={{
              'data-testid': 'jobTitle',
            }}
            textError={formik?.errors?.jobTitle}
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            value={initialValues.jobTitle}
            onChange={(e) => {
              formik.values.jobTitle = e.target.value
              setInitialValues({
                ...initialValues,
                jobTitle: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="sequence"
            name="sequence"
            labelText="ลำดับ"
            type="basic"
            inputProps={{
              'data-testid': 'sequence',
              type: 'text',
            }}
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.sequence}
            required
            boxSx={{ mx: 3 }}
            maxlength={5}
            value={initialValues.sequence}
            onChange={(e) => {
              const value = e.target.value
              const replaceValue = value.replace(/[^\d]/g, '')
              formik.values.sequence = replaceValue
              setInitialValues({
                ...initialValues,
                sequence: replaceValue,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            value={initialValues.status}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
            boxSx={{ mx: 3 }}
          ></SwitchInput>
        </Box>

        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย{' '}
                {initialValues && initialValues.updatedBy
                  ? initialValues.updatedBy
                  : ''}
                <br />
                {initialValues && initialValues.updatedAt
                  ? initialValues.updatedAt
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={isLoading}
        />
      </form>
    </Card>
  )
}
export default jobTitleForm
