import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
  resetRowsPerPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import { examinationDashboardEnroll } from '../../../../../utils/apiPath'
import { TEST_RESULT_STATUS } from '../../../../../constants/eExamination'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'
import dayjs from 'dayjs'
import callAxios from '../../../../../utils/baseService'
import { examinerDrawerType } from '../../enums/drawerEnum'

export const scheduleStatuses = [
  {
    label: 'เข้าสอบ',
    fieldName: 'eExamDashboard.examinerDrawer.EXAMINEES',
    fontColor: '#005CE7',
    value: 'EXAMINEES',
  },
  {
    label: 'ขาดสอบ',
    fieldName: 'eExamDashboard.examinerDrawer.ABSENCE',
    fontColor: '#FF650E',
    value: TEST_RESULT_STATUS.ABSENCE,
  },
  {
    label: 'สอบผ่าน',
    fieldName: 'eExamDashboard.examinerDrawer.PASS',
    fontColor: '#03C83E',
    value: TEST_RESULT_STATUS.PASS,
  },
  {
    label: 'สอบไม่ผ่าน',
    fieldName: 'eExamDashboard.examinerDrawer.NOT_PASS',
    fontColor: '#D64F65',
    value: TEST_RESULT_STATUS.NOT_PASS,
  },
]

export const examFieldTypes = [
  {
    label: 'สนามสอบบริษัท',
    fieldName: 'eExamDashboard.examinerDrawer.isCompany',
    type: 'บริษัท',
  },
  {
    label: 'สนามสอบสมาคม',
    fieldName: 'eExamDashboard.examinerDrawer.isAssociation',
    type: 'สมาคม',
  },
  {
    label: 'สนามสอบอื่นๆ',
    fieldName: 'eExamDashboard.examinerDrawer.isOther',
    type: 'อื่นๆ',
  },
]

export const headCellsExamField = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examField',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'other',
    label: 'อื่นๆ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวนการสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countExaminees',
    label: 'เข้าสอบ',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#005CE7',
  },
  {
    id: 'countMissed',
    label: 'ขาดสอบ',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#FF650E',
  },
  {
    id: 'countPass',
    label: 'สอบผ่าน',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#03C83E',
  },
  {
    id: 'countFail',
    label: 'สอบไม่ผ่าน',
    disablePadding: false,
    hideSortIcon: true,
    headCellColor: '#D64F65',
  },
]

export const headCellsSram = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'examField',
    label: 'สนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'other',
    label: 'อื่นๆ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวนการสมัคร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'passCount',
    label: 'สอบผ่าน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countFail',
    label: 'สอบไม่ผ่าน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'countMissed',
    label: 'ขาดสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const handleCheckBox = (key, value, table, drawerType) => {
  store.dispatch(
    setFieldValue({
      key: key,
      value: value,
    }),
  )
  store.dispatch(resetRowsPerPage())
  store.dispatch(
    fetchData({
      table,
      drawerType,
    }),
  )
}

export const fetchData =
  ({ table, drawerType }) =>
  async (dispatch) => {
    const { eExamDashboard } = store.getState().crud
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsExamField
    let type = []
    if (_.get(eExamDashboard, 'examinerDrawer.isCompany')) {
      type = [...type, 'COMPANY']
    }
    if (_.get(eExamDashboard, 'examinerDrawer.isAssociation')) {
      type = [...type, 'ASSOCIATION']
    }
    if (_.get(eExamDashboard, 'examinerDrawer.isOther')) {
      type = [...type, 'OTHER']
    }

    let status = []
    if (_.get(eExamDashboard, 'examinerDrawer.PASS')) {
      status = [...status, 'ผ่าน']
    }
    if (_.get(eExamDashboard, 'examinerDrawer.NOT_PASS')) {
      status = [...status, 'ไม่ผ่าน']
    }
    if (_.get(eExamDashboard, 'examinerDrawer.ABSENCE')) {
      status = [...status, 'ขาดสอบ']
    }
    if (_.get(eExamDashboard, 'examinerDrawer.EXAMINEES')) {
      status = [...status, 'เข้าสอบ']
    }

    const station = _.defaultTo(eExamDashboard?.filtersDrawer?.station, []).map(
      (item) => item.uuid,
    )
    const associationRegion = _.defaultTo(
      eExamDashboard?.filtersDrawer?.associationRegion,
      [],
    ).map((item) => item.uuid)
    const associationProvince = _.defaultTo(
      eExamDashboard?.filtersDrawer?.associationProvince,
      [],
    ).map((item) => item.uuid)
    const otherRegion = _.defaultTo(
      eExamDashboard?.filtersDrawer?.otherRegion,
      [],
    ).map((item) => item.uuid)
    const otherProvince = _.defaultTo(
      eExamDashboard?.filtersDrawer?.otherProvince,
      [],
    ).map((item) => item.uuid)
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        type,
        status,
        station: station,
        startDate: _.defaultTo(
          eExamDashboard?.filtersDrawer?.startDate,
          dayjs().startOf('month').format('YYYY-MM-DD'),
        ),
        endDate: _.defaultTo(
          eExamDashboard?.filtersDrawer?.endDate,
          dayjs().format('YYYY-MM-DD'),
        ),
        associationRegion: associationRegion,
        associationProvince: associationProvince,
        otherRegion: otherRegion,
        otherProvince: otherProvince,
        drawerType: drawerType,
      }
      await callAxios
        .post(examinationDashboardEnroll, body)
        .then(({ data }) => {
          const totalEnroll = _.get(data, 'totalEnroll', 0)
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.examinerDrawer.totalEnroll',
              value: totalEnroll,
            }),
          )
          const reportExamScheduleAll = _.get(data, 'reportExamScheduleAll', [])
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.examinerDrawer.countTypeArr',
              value: reportExamScheduleAll,
            }),
          )
          if (drawerType === examinerDrawerType.EXAM) {
            const results = _.get(data, 'results', [])
            dispatch(
              setInitialTable({
                rows: prepareRow(results),
                allCount: _.get(data, 'totalCount', 0),
                headCells: headCells,
                hideFilter: true,
                onDownload: (selected) =>
                  dispatch(onDownload(selected, 'exam')),
              }),
            )
            dispatch(setSelected([]))
            const countStatus = results.reduce(
              (acc, curr) => {
                acc[TEST_RESULT_STATUS.PASS] =
                  (acc[TEST_RESULT_STATUS.PASS] || 0) +
                  _.get(curr, 'countPass', 0)
                acc[TEST_RESULT_STATUS.NOT_PASS] =
                  (acc[TEST_RESULT_STATUS.NOT_PASS] || 0) +
                  _.get(curr, 'countFail', 0)
                acc[TEST_RESULT_STATUS.ABSENCE] =
                  (acc[TEST_RESULT_STATUS.ABSENCE] || 0) +
                  _.get(curr, 'countMissed', 0)
                acc['EXAMINEES'] =
                  (acc['EXAMINEES'] || 0) + _.get(curr, 'countExaminees', 0)
                return acc
              },
              {
                [TEST_RESULT_STATUS.PASS]: 0,
                [TEST_RESULT_STATUS.NOT_PASS]: 0,
                [TEST_RESULT_STATUS.ABSENCE]: 0,
                EXAMINEES: 0,
              },
            )
            store.dispatch(
              setFieldValue({
                key: 'eExamDashboard.examinerDrawer.countStatus',
                value: countStatus,
              }),
            )
          } else {
            const sRam1Data = _.get(data, 'sRam1Data', [])
            const sRam2Data = _.get(data, 'sRam2Data', [])
            const sRam3Data = _.get(data, 'sRam3Data', [])
            const sRam4Data = _.get(data, 'sRam4Data', [])
            store.dispatch(
              setFieldValue({
                key: 'eExamDashboard.examinerDrawer.sRamData',
                value: {
                  sRam1Data: sRam1Data,
                  sRam2Data: sRam2Data,
                  sRam3Data: sRam3Data,
                  sRam4Data: sRam4Data,
                },
              }),
            )
            const mergeSRam = [
              ...sRam1Data,
              ...sRam2Data,
              ...sRam3Data,
              ...sRam4Data,
            ]
            const sRamTotalEnroll = _.sumBy(mergeSRam, 'totalEnroll')
            store.dispatch(
              setFieldValue({
                key: 'eExamDashboard.examinerDrawer.totalEnroll',
                value: sRamTotalEnroll,
              }),
            )
            const countStatus = mergeSRam.reduce(
              (acc, curr) => {
                acc[TEST_RESULT_STATUS.PASS] =
                  (acc[TEST_RESULT_STATUS.PASS] || 0) +
                  _.get(curr, 'countPass', 0)
                acc[TEST_RESULT_STATUS.NOT_PASS] =
                  (acc[TEST_RESULT_STATUS.NOT_PASS] || 0) +
                  _.get(curr, 'countFail', 0)
                acc[TEST_RESULT_STATUS.ABSENCE] =
                  (acc[TEST_RESULT_STATUS.ABSENCE] || 0) +
                  _.get(curr, 'countMissed', 0)
                acc['EXAMINEES'] =
                  (acc['EXAMINEES'] || 0) + _.get(curr, 'countExaminees', 0)
                return acc
              },
              {
                [TEST_RESULT_STATUS.PASS]: 0,
                [TEST_RESULT_STATUS.NOT_PASS]: 0,
                [TEST_RESULT_STATUS.ABSENCE]: 0,
                EXAMINEES: 0,
              },
            )
            store.dispatch(
              setFieldValue({
                key: 'eExamDashboard.examinerDrawer.countStatus',
                value: countStatus,
              }),
            )
            const sRamCountExamField = _.get(data, 'sRamCountExamField', {})
            store.dispatch(
              setFieldValue({
                key: 'eExamDashboard.examinerDrawer.countTypeArr',
                value: [
                  {
                    examinationField: 'สมาคม',
                    totalEnroll: _.get(sRamCountExamField, 'ASSOCIATION', 0),
                  },
                  {
                    examinationField: 'บริษัท',
                    totalEnroll: _.get(sRamCountExamField, 'COMPANY', 0),
                  },
                  {
                    examinationField: 'อื่นๆ',
                    totalEnroll: _.get(sRamCountExamField, 'OTHER', 0),
                  },
                ],
              }),
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload = (selected, dataType) => async (dispatch) => {
  dispatch(startLoading())
  let body = {
    dataType: dataType,
    list: selected,
  }
  if (dataType === 'SRAM') {
    const getBody = await getDownloadSRamBody()
    body = {
      ...body,
      ...getBody,
    }
    console.log(body)
  }

  await dispatch(
    downloadFile({
      url: examinationDashboardEnroll + '/download',
      body: body,
      fileName: `รายการผู้สมัครสอบ.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const getDownloadSRamBody = () => {
  const { eExamDashboard } = store.getState().crud
  let type = []
  if (_.get(eExamDashboard, 'examinerDrawer.isCompany')) {
    type = [...type, 'COMPANY']
  }
  if (_.get(eExamDashboard, 'examinerDrawer.isAssociation')) {
    type = [...type, 'ASSOCIATION']
  }
  if (_.get(eExamDashboard, 'examinerDrawer.isOther')) {
    type = [...type, 'OTHER']
  }

  let status = []
  if (_.get(eExamDashboard, 'examinerDrawer.PASS')) {
    status = [...status, 'ผ่าน']
  }
  if (_.get(eExamDashboard, 'examinerDrawer.NOT_PASS')) {
    status = [...status, 'ไม่ผ่าน']
  }
  if (_.get(eExamDashboard, 'examinerDrawer.ABSENCE')) {
    status = [...status, 'ขาดสอบ']
  }

  const station = _.defaultTo(eExamDashboard?.filtersDrawer?.station, []).map(
    (item) => item.uuid,
  )
  const associationRegion = _.defaultTo(
    eExamDashboard?.filtersDrawer?.associationRegion,
    [],
  ).map((item) => item.uuid)
  const associationProvince = _.defaultTo(
    eExamDashboard?.filtersDrawer?.associationProvince,
    [],
  ).map((item) => item.uuid)
  const otherRegion = _.defaultTo(
    eExamDashboard?.filtersDrawer?.otherRegion,
    [],
  ).map((item) => item.uuid)
  const otherProvince = _.defaultTo(
    eExamDashboard?.filtersDrawer?.otherProvince,
    [],
  ).map((item) => item.uuid)
  const body = {
    type,
    status,
    station: station,
    startDate: _.defaultTo(
      eExamDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      eExamDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
    associationRegion: associationRegion,
    associationProvince: associationProvince,
    otherRegion: otherRegion,
    otherProvince: otherProvince,
  }
  return body
}

const prepareRow = (data) => {
  return data.map((ele) => {
    const percentPass =
      ele.countPass > 0
        ? ((ele.countPass / ele.countExaminees) * 100).toFixed(2)
        : 0
    const percentFail =
      ele.countFail > 0
        ? ((ele.countFail / ele.countExaminees) * 100).toFixed(2)
        : 0
    const percentMissed =
      ele.countMissed > 0
        ? ((ele.countMissed / ele.countEnroll) * 100).toFixed(2)
        : 0
    const percentExaminees =
      ele.countExaminees > 0
        ? ((ele.countExaminees / ele.countEnroll) * 100).toFixed(2)
        : 0
    return {
      ...ele,
      numberOfPeople: `${ele.countEnroll}/${ele.numberOfPeople}`,
      countPass: `${ele.countPass}/${ele.numberOfPeople} (${percentPass}%)`,
      countFail: `${ele.countFail}/${ele.numberOfPeople} (${percentFail}%)`,
      countMissed: `${ele.countMissed}/${ele.numberOfPeople} (${percentMissed}%)`,
      countExaminees: `${ele.countExaminees}/${ele.numberOfPeople} (${percentExaminees}%)`,
    }
  })
}

export const changeDrawerType = (value) => (dispatch) => {
  dispatch(resetPage())
  dispatch(resetRowsPerPage())
  dispatch(
    setFieldValue({
      key: 'eExamDashboard.examinerDrawer.drawerType',
      value: value,
    }),
  )
}
