import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const manageClassLearnerDetail = createSlice({
  name: 'manageClassLearnerDetail ',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChange: (state, { payload }) => {
      _.set(state, payload.key, payload.value)
    },
    setFetchDataDetail: (state, { payload }) => {
      state.selectDate = _.get(payload, 'attendance[0].date', '')
      state.initialState = payload
    },
    setChangeStatus: (state, { payload }) => {
      const { date, round, type } = payload
      const attendance = _.get(state, 'initialState.attendance', [])
      const findAttendance = attendance.find((item) => item.date === date)
      const findRounds = _.get(findAttendance, 'rounds', []).find(
        (item) => item.round === round
      )
      const findIndex = _.get(findAttendance, 'rounds', []).findIndex(
        (item) => item.round === round
      )
      const result =
        type === 'STUDY'
          ? 'มาเรียน'
          : type === 'MISSING'
          ? 'ไม่มาเรียน'
          : 'รอเช็คชื่อ'

      _.set(findAttendance, `rounds[${findIndex}]`, {
        ...findRounds,
        result,
        status: type,
      })
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setFetchDataDetail,
  setChange,
  setChangeStatus,
} = manageClassLearnerDetail.actions

export default manageClassLearnerDetail.reducer
