import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  StyledCard,
  LoadingCircular,
} from './Styled'
import Table from '../../../../../components/redux/Table'
import { toggleExamDrawer } from '../../handler/handleClickOpenDrawer'
import { Row } from '../../components/Styled'
import { useTheme } from '@emotion/react'
import { CustomCheckbox } from '../../../../../components/CRUD/components/CustomCheckbox'
import { examFieldTypes, fetchData, handleCheckBox } from './events'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

const ExamFieldDrawer = () => {
  const { open, countTotal, overviewDataCount } = useSelector(
    (state) => ({
      open: state.crud.eExamDashboard?.examFieldDrawer?.isOpen,
      countTotal: state.crud.eExamDashboard?.examFieldDrawer?.countTotal,
      overviewDataCount: state.crud?.eExamDashboard?.overviewDataCount,
    }),
    shallowEqual,
  )
  const theme = useTheme()
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { limit, page, isLoading } = table
  useEffect(() => {
    open &&
      dispatch(
        fetchData({
          table,
        }),
      )
  }, [open, page, limit])

  return (
    <StyledDrawer open={open} onClose={() => toggleExamDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">รายละเอียดสนามสอบ</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleExamDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box m={`${theme.spacing(0.5)} ${theme.spacing(2.75)}`}>
        <Row
          sx={{
            p: '4px 16px',
            borderRadius: 5,
            background: '#F1F4FA',
            maxWidth: 'max-content',
          }}
        >
          <Typography variant="body2b" color={theme.palette.primary.main}>
            จำนวนสนามสอบทั้งหมด {countTotal ?? 0} สนาม
          </Typography>
        </Row>
        <Box display="flex" alignItems="center">
          <Typography variant="body1b" color={theme.palette.text.secondary}>
            ประเภทสนามสอบ
          </Typography>
          {examFieldTypes.map((item, index) => {
            return (
              <Box ml={2} key={index} display="flex" alignItems="center">
                <CustomCheckbox
                  fieldName={item.fieldName}
                  handleExtraChange={(e, v) => {
                    handleCheckBox(item.fieldName, v, table)
                  }}
                  labelText={item.label}
                />
                <Typography variant="body2b" color="primary.main">
                  ({_.get(overviewDataCount, item.type, 0)} สนาม)
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
      <StyledCard isLoading={isLoading}>
        <Table />
      </StyledCard>
      <StyledFooter>
        <Button onClick={() => toggleExamDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default ExamFieldDrawer
