import {
  setChangeQuestionByKey,
  setDeleteQuestionRating,
  setChangeSubQuestionByKey,
} from '../../../../../../../../redux/slices/eEvaluationForm'

export const handleChangeContent = (props) => (dispatch) => {
  const { type, indexTypeList, isSubQuestions } = props
  const key = `${type}[${indexTypeList}].title`
  if (isSubQuestions) {
    dispatch(
      setChangeSubQuestionByKey({
        ...props,
        key,
      })
    )
  } else {
    dispatch(
      setChangeQuestionByKey({
        ...props,
        type: 'questions',
        key,
      })
    )
  }
}

export const handleDeleteContent = (props) => (dispatch) => {
  dispatch(setDeleteQuestionRating(props))
}
