import { periodOptions } from '../../../../../../constants/roomManagement'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import Select from '../../../../../../components/Input/Select'
import {
  bookingItemSelector,
  setPeriodByDate,
} from '../../../../../../redux/slices/roomManagement/Drawer'

export const Period = ({ bookingDate }) => {
  const dispatch = useDispatch()

  const period = useSelector(
    bookingItemSelector({ bookingDate, key: 'period' }),
    shallowEqual,
  )

  const { requestType } = useSelector(
    (state) => ({
      requestType: state.roomManagementDrawer.bookingInformation.requestType,
    }),
    shallowEqual,
  )
  return (
    <Select
      boxSx={{ width: 270 }}
      dataTestid="select-period"
      id="period"
      name="period"
      required
      labelText={'ช่วงเวลา'}
      placeholder="กรุณาเลือกช่วงเวลา"
      textErrorSx={{ mb: 0 }}
      options={periodOptions}
      value={period}
      disabled={requestType === 'MONTHLY_PLAN'}
      handleChange={(e) => {
        const value = _.get(e, 'target.value', '')
        dispatch(
          setPeriodByDate({
            bookingDate: bookingDate,
            value,
          }),
        )
      }}
      // showTextError={
      //   !_.isEmpty(_.get(errorsRoomBookingList, `[${idx}].period`, ''))
      // }
      // textError={_.get(errorsRoomBookingList, `[${idx}].period`, '')}
    />
  )
}
