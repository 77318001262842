import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  crop: { x: 0, y: 0 },
  fileName: '',
  imageLocalSrc: '',
  isOpen: false,
  rotation: 0,
  percent: 0,
  uploadError: '',
  isUploading: false,
  coverImage: '',
  prepareFileName: '',
}

const UploadCropper = createSlice({
  name: 'uploadCropper',
  initialState,
  reducers: {
    setFieldUploadValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setInitialConfig: (state, { payload }) => {
      _.set(state, 'formData', {})
      _.set(state, 'formErrors', [])
      _.set(state, 'crudConfig', payload.crudConfig)
    },
    replaceFieldError: (state, { payload }) => {
      state.formErrors = payload
    },
    setFieldError: (state, { payload }) => {
      state.formErrors = [...state.formErrors, ...payload]
    },
    clearFieldUploadValue: (state) => {
      Object.assign(state, initialState)
    },
  },
})
export const { setFieldUploadValue, clearFieldUploadValue } =
  UploadCropper.actions

export default UploadCropper.reducer
