import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import { getQueryParams } from '../../../../utils/lib'
import LoadingPageCircular from '../../components/LoadingPageCircular'
import TabMenu from '../../components/TabMenu'
import { StyledFormWrapper } from '../../Styled'
import { schema } from './schema'
import { tabs } from './model'
import { fetchCourseDetail, handleChangeTab, handleSubmit } from './events'
import Header from './Header'
import LearningPathTab from './LearningPathTab'
import SettingTab from './SettingTab'

const LearningPathForm = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const { id: uuid } = useParams()
  const tab = getQueryParams('tab')
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.eLearningPathForm.isLoading,
    shallowEqual,
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(fetchCourseDetail({ uuid, onEdit, setOnEdit, tab }))
  }, [])

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => dispatch(handleSubmit(values, history))}
        >
          <Form>
            <Header />

            <TabMenuContent />

            <FormContent />
          </Form>
        </Formik>
      </Box>
    </>
  )
}
export default LearningPathForm

export const FormContent = () => {
  const tab = useSelector((state) => state.eLearningPathForm.tab, shallowEqual)
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'learningPath' && <LearningPathTab />}
      {tab === 'setting' && <SettingTab />}
    </StyledFormWrapper>
  )
}

export const TabMenuContent = () => {
  const tab = useSelector((state) => state.eLearningPathForm.tab, shallowEqual)
  return <TabMenu isForm tab={tab} tabs={tabs} handleChange={handleChangeTab} />
}
