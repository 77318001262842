import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../components/Input/TextInput'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import { handleInputBody, handleSelector } from '../events'
import ShowHideButton from '../ShowHideButton'
import ImageUploader from '../ImageUploader'

const MainBanner = () => {
  const [show, setShow] = useState(false)
  const keyField = 'mainBannerInformation'
  const mainBannerInformation = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const image = _.get(mainBannerInformation, 'image', '')
  const dispatch = useDispatch()
  const formik = useFormikContext()
  const { errors } = formik

  return (
    <StyledSection show={show}>
      <StyledRow sx={{ gap: 0 }} justifyContent="space-between">
        <Typography variant="h6">Section 1 | Main Banner</Typography>
        <ShowHideButton show={show} setShow={setShow} />
      </StyledRow>
      {show && (
        <StyledColumn sx={{ gap: 0 }}>
          <ImageUploader
            formik={{ ...formik, values: { [keyField]: { image } } }}
            keyField={`${keyField}.image`}
            info="ขนาดแนะนำ width 1,920px, height 675px, ไฟล์ไม่เกิน 2MB"
          />
          <TextInput
            name="title"
            type="basic"
            labelText="Title"
            id="input-title"
            placeholder="บรรดทัดที่ 2"
            value={_.get(mainBannerInformation, 'title', '')}
            textError={_.get(errors, `${keyField}.title`, '')}
            onChange={(e) => dispatch(handleInputBody(e, keyField))}
            textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
          />
          <TextInput
            name="subtitle"
            type="basic"
            labelText="Subtitle"
            id="input-subtitle"
            placeholder="บรรดทัดที่ 1"
            value={_.get(mainBannerInformation, 'subtitle', '')}
            textError={_.get(errors, `${keyField}.subtitle`, '')}
            onChange={(e) => dispatch(handleInputBody(e, keyField))}
            textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
          />
        </StyledColumn>
      )}
    </StyledSection>
  )
}

export default MainBanner
