import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../../../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenClassDrawer } from '../../../../../redux/slices/monthlyPlan'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Table from '../../../../../components/redux/Table'
import { useTheme } from '@mui/system'
import { TYPE_CLASS_LABEL } from 'src/constants/report/monthlyPlan'
import _ from 'lodash'

const ClassDrawer = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isOpenClassDrawer, classType } = useSelector(
    (state) => ({
      isOpenClassDrawer: state.monthlyPlan.dashboard.isOpenClassDrawer,
      classType: state.monthlyPlan.dashboard.classType,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{
        position: 'relative',
        '& .MuiPaper-root': {
          width: 630,
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      open={isOpenClassDrawer}
      onClose={() => dispatch(setIsOpenClassDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">
            {!_.isEmpty(classType)
              ? `รายละเอียด ${TYPE_CLASS_LABEL[classType]}`
              : 'จำนวนคลาสทั้งหมด'}
          </Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenClassDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <Box
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: 1,
        }}
        mx={3}
      >
        <Table />
      </Box>

      <StyledFooter>
        <Button
          sx={{ ml: 'auto', maxWidth: 40 }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => dispatch(setIsOpenClassDrawer(false))}
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default ClassDrawer
