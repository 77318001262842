import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'

import TestingForm from '../TestingForm'
import SettingForm from '../SettingForm'

const Index = () => {
  const defaultTap = useSelector(
    (state) => state.eTestingForm.defaultTap,
    shallowEqual
  )

  return (
    <>
      <Box>
        {defaultTap === 'testing' && <TestingForm />}
        {defaultTap === 'setting' && <SettingForm />}
      </Box>
    </>
  )
}
export default Index
