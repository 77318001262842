import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  BoxTable,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from './Styled'
import { checkNull } from './events'

const EducationSection = () => {
  const information = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual,
  )
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Typography variant="h6">ประสบการณ์</Typography>

        <BoxTable>
          <Typography variant="body1b" color="text.secondary">
            การศึกษาระดับสูงสุด
          </Typography>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>สถานศึกษา (มหาวิทยาลัย)</StyledTableCell>
                <StyledTableCell align="left">ระหว่าง</StyledTableCell>
                <StyledTableCell align="left">วุฒิการศึกษา</StyledTableCell>
                <StyledTableCell align="left">สาขา</StyledTableCell>
                <StyledTableCell align="left">เกรดเฉลี่ย</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell component="th" scope="row">
                  {checkNull(_.get(information, 'institution'))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {checkNull(_.get(information, 'between'))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {checkNull(_.get(information, 'qualificationName'))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {checkNull(_.get(information, 'branch'))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {checkNull(_.get(information, 'gpa'))}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </BoxTable>
      </Card>
    </>
  )
}

export default EducationSection
