import _ from 'lodash'
import {
  apiToRcmsDetailDownload,
  apiToRcmsDetailLicenseDownload,
  apiToRcmsDownload,
  apiToRcmsFilterDetail,
} from '../../../utils/apiPath'
import { headDetailCells } from '../../../constants/apiToRcms'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import {
  setChangeStatusLicense,
  setFetchRcmsData,
} from '../../../redux/slices/apiToRcms'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'

export const onDownload = (selected) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    list: selected,
  }
  await dispatch(
    downloadFile({
      url: apiToRcmsDownload,
      body: body,
      fileName: `Agent License.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const onDownloadDetail = (selected, id) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    rcmsUuid: id,
    list: selected,
    licenseStatus: [],
    rcmsStatus: [],
  }
  await dispatch(
    downloadFile({
      url: apiToRcmsDetailDownload,
      body: body,
      fileName: `ApiToRcmsDetail.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const onDownloadDetailLicense =
  (selected, order, sort) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      list: selected,
      order: order,
      sort: sort,
    }
    await dispatch(
      downloadFile({
        url: apiToRcmsDetailLicenseDownload,
        body: body,
        fileName: `รายละเอียด Agent License.xlsx`,
      })
    )
    dispatch(stopLoading())
  }

export const fetchDataList =
  (method, fetchProps, setInitial, permission) => async (dispatch) => {
    dispatch(startLoading())
    const { table, id } = fetchProps
    const page = _.get(fetchProps, 'page', 1)
    const filterProp = _.get(fetchProps, 'filterProp', null)
    const searchText = _.get(fetchProps, 'searchText', '')
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const fullAgentCode = _.get(filterProp, 'fullAgentCode', searchText)
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      rcmsUuid: id,
      fullAgentCode: method === 'filter' ? fullAgentCode : searchText,
      fullName: _.get(filterProp, 'fullName', ''),
      bankCode: _.get(filterProp, 'bankCode', ''),
      agentCategory: _.get(filterProp, 'agentCategory', ''),
      effectiveDateStartDate: _.get(filterProp, 'effectiveDateStartDate', ''),
      effectiveDateFinishDate: _.get(filterProp, 'effectiveDateFinishDate', ''),
      expiryDateStartDate: _.get(filterProp, 'expiryDateStartDate', ''),
      expiryDateFinishDate: _.get(filterProp, 'expiryDateFinishDate', ''),
      licenseRcmsStatus: _.get(filterProp, 'licenseRcmsStatus', []),
      rcmsStatus: _.get(filterProp, 'rcmsStatus', []),
    }

    const isCancel = await callAxios
      .post(apiToRcmsFilterDetail, body)
      .then(({ data }) => {
        dispatch(setFetchRcmsData(data))
        dispatch(
          setInitialTable({
            rows: data.agent_detail,
            allCount: data.license_detail.total,
            headCells: headDetailCells,
            placeholder: 'ค้นหา Agent Code',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text, id, permission)),
            onDownload: (selected) =>
              dispatch(
                onDownloadDetailLicense(
                  selected,
                  tableProps.order.toString().toUpperCase(),
                  sortBy
                )
              ),
            onChangeStatus: (selected) =>
              dispatch(setChangeStatusLicense(permission ? selected : [])),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headDetailCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter, id, permission) => (dispatch) => {
  dispatch(setSearchText(''))
  const LicenseStatus = []
  if (filter.licenseStatusChecked) {
    if (filter.licenseStatus.WAITING) {
      LicenseStatus.push('รอตรวจสอบ')
    }
    if (filter.licenseStatus.ACCESS) {
      LicenseStatus.push('ให้')
    }
    if (filter.licenseStatus.NOT_ACCESS) {
      LicenseStatus.push('ไม่ให้')
    }
  } else {
    LicenseStatus.push('รอตรวจสอบ', 'ให้', 'ไม่ให้')
  }
  const RcmsStatus = []
  if (filter.rcmsStatusChecked) {
    if (filter.rcmsStatus.NO_STATUS) {
      RcmsStatus.push('-')
    }
    if (filter.rcmsStatus.SUCCESS) {
      RcmsStatus.push('สำเร็จ')
    }
    if (filter.rcmsStatus.NOT_SUCCESS) {
      RcmsStatus.push('ไม่สำเร็จ')
    }
    if (filter.rcmsStatus.NOT_SEND) {
      RcmsStatus.push('ไม่ต้องส่ง')
    }
    if (filter.rcmsStatus.WAIT_SEND) {
      RcmsStatus.push('รอส่ง')
    }
  } else {
    RcmsStatus.push('-', 'สำเร็จ', 'ไม่สำเร็จ', 'ไม่ต้องส่ง', 'รอส่ง')
  }

  const filterProp = {
    fullAgentCode: filter.fullAgentCodeChecked ? filter.fullAgentCode : '',
    fullName: filter.fullNameChecked ? filter.fullName : '',
    bankCode: filter.bankCodeChecked ? filter.bankCode : '',
    agentCategory: filter.agentCategoryChecked ? filter.agentCategory : '',
    effectiveDateStartDate: filter.effectiveDateChecked
      ? filter.effectiveDateStartDate
      : '',
    effectiveDateFinishDate: filter.effectiveDateChecked
      ? filter.effectiveDateFinishDate
      : '',
    expiryDateStartDate: filter.expiryDateChecked
      ? filter.expiryDateStartDate
      : '',
    expiryDateFinishDate: filter.expiryDateChecked
      ? filter.expiryDateFinishDate
      : '',
    licenseRcmsStatus: filter.licenseStatusChecked ? LicenseStatus : [],
    rcmsStatus: filter.rcmsStatusChecked ? RcmsStatus : [],
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  const fetchProps = { table, filterProp, id }
  dispatch(fetchDataList('filter', fetchProps, null, permission))
}

export const handleQuickSearch =
  (table, searchText, id, permission) => (dispatch) => {
    dispatch(setSearchText(searchText))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    const fetchProps = { table, searchText, id }
    dispatch(fetchDataList('search', fetchProps, null, permission))
  }
