import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxForm = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxFlex = styled(Box)(({ theme, isColumn, isAlignItems }) => ({
  display: 'flex',
  alignItems: isAlignItems ? 'flex-start' : '',
  flexDirection: isColumn ? 'column' : 'row',
  width: '100%',
  gap: isColumn ? 0 : theme.spacing(3),
  marginBottom: isColumn ? theme.spacing(2) : 0,

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxFlexSelect = styled(Box)(({ theme }) => ({
  width: '35%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}))

export const BoxUpload = styled(Box)(({ theme, upload, isError }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: upload ? theme.spacing(1) : 0,
  marginTop: isError ? theme.spacing(-4) : 0,
  marginLeft: upload ? theme.spacing(4) : 0,
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 0,
    marginLeft: 0,
    marginTop: 0,
  },
}))

export const BoxInfoImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
