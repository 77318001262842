import { alphabets } from '../../constants/eTesting'
import { blankHtmlReg, blankReg } from '../../modules/ETesting/Preview/Testing/Questions/QuestionCard/Choice/DragAndDrop/model'
import _ from 'lodash'

export const getPlainTextWithBlank = (value) => {
  const newValue = value
    .replaceAll('<span', '[<span')
    .replaceAll('</span>', '</span>]')
  const plainText = new DOMParser().parseFromString(newValue, 'text/html')
    .documentElement.textContent
  return plainText
}

export const getBlankArr = (value) => {
  const plainText = getPlainTextWithBlank(value)
  return plainText
    .split(blankReg)
    .filter((val) => val.includes('['))
    .map((x) => x.replace('[', '').replace(']', ''))
}

export const handleEditorChange = (newValue, props) => {
  const {
    value,
    setValue,
    blankNums,
    setBlankNums,
    keywords,
    setKeywords,
  } = props
  const newBlanks = getBlankArr(newValue)
  const oldBlanks = getBlankArr(value)
  const delIndex = oldBlanks.findIndex((blank) => !newBlanks.includes(blank))
  const diffIndex = newBlanks.findIndex((blank) => !oldBlanks.includes(blank))

  // re-sequencing blank alphabet
  if (newBlanks.length !== blankNums) {
    let num = -1
    newValue = newValue.replace(blankHtmlReg, () => {
      num++
      return `<em>${alphabets[num]}</em>`
    })
  }
  setBlankNums(newBlanks.length)
  setValue(newValue)

  // re-sequencing keyword obj
  let newKeyword = [...keywords].filter((val) => newBlanks.includes(val.key))
  if (delIndex > -1) {
    // delete with keyboard
    newKeyword = newKeyword.map((kw, i) => {
      const obj = { key: alphabets[i], value: kw.value }
      if (i >= delIndex) {
        obj.value = _.get(keywords[i + 1], 'value', '')
      }
      return obj
    })
  } else {
    if (diffIndex === newKeyword.length) {
      // add new blank at the end
      newBlanks.forEach((blank) => {
        if (keywords.some((kw) => kw.key === blank)) return
        newKeyword.push({ key: blank, value: '' })
      })
    } else if (diffIndex !== -1) {
      // add new blank between the sentence
      newKeyword.splice(diffIndex, 0, {
        key: alphabets[diffIndex],
        value: '',
      })
    }
  }

  // re-sequencing keyword alphabet
  newKeyword = newKeyword.map((val, i) => ({
    ...val,
    key: alphabets[i],
  }))
  setKeywords(newKeyword)
}
