export const initialState = {
  isLoading: false,
  isUploadLoading: false,
  isUploadSuccess: false,
  uploadError: null,
  percent: null,
  cropper: {
    isOpen: false,
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    croppedImage: null,
  },
}
