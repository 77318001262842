import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { handleOwnerChange } from '../../handler/handleOwnerChange'
import { Box, Card, Typography } from '@mui/material'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'

export const OwnerInfo = () => {
  const { holder } = useSelector(
    (state) => ({
      holder: state.crud.formData.holder,
    }),
    shallowEqual,
  )

  return (
    <Box mt={3}>
      <Typography variant="body1b" mt={3}>
        ข้อมูลผู้ถือครอง
      </Typography>
      <Box display="column" alignItems="start">
        <CustomDropdown
          labelName="ชื่อผู้ถือครอง"
          fieldName="holder"
          placeholder="เลือกรายชื่อ"
          handleExtraChange={handleOwnerChange}
        />
      </Box>
      {holder?.label && (
        <>
          <Card sx={{ m: 3, p: 2 }}>
            <Box display="flex" justifyContent="start" mb={2}>
              <Typography variant="body2" mr={2} minWidth="120px">
                คำนำหน้าชื่อ
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {holder?.prefix}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                ชื่อ-สกุล
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {holder?.label}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="start">
              <Typography variant="body2" mr={2} minWidth="120px">
                เบอร์โทรศัพท์
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {holder?.tel}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                Email
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {holder?.email}
              </Typography>
            </Box>
          </Card>
        </>
      )}
    </Box>
  )
}
