import { headerCells } from './model/headerCells'
import { productWarehouseListFilter } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_PRODUCT_WAREHOUSE',
  moduleSlug: 'inventory-warproduct-warehouse',
  moduleTitleFull: 'ทรัพย์สิน',
  modulePath: '/inventory/product/stock',
  filterPath: productWarehouseListFilter,
  headerCells: headerCells,
  masterDataModuleId: [
    {
      key: 'costCenterForm',
      moduleId: 'COST_CENTER',
    },
    {
      key: 'payerForm',
      moduleId: 'INVENTORY_PAYER_HOLDER',
    },
    {
      key: 'holderForm',
      moduleId: 'INVENTORY_PAYER_HOLDER',
    },
    {
      key: 'payerForm',
      moduleId: 'INVENTORY_PAYER_HOLDER',
    },
  ],
}
