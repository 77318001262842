import { stationFilter } from '../../../../utils/apiPath'
import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { handleChange } from '../event'
import { store } from '../../../../App'

export const fetchStationList = async (setStationList) => {
  const table = store.getState().table
  const {
    table: { page, limit, order, sort },
    filter: { filterProp },
  } = table
  const createdBy = _.get(filterProp, 'createdBy', '')
  const updatedBy = _.get(filterProp, 'updatedBy', '')
  const realPage = page <= 0 ? 1 : +page
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: limit,
    order: order.toString().toUpperCase(),
    page: realPage,
    sort: sort === 'updatedAt' ? 'updatedAt' : sort,
    status: status,
    createdBy: createdBy,
    updatedBy: updatedBy,
  }

  await callAxios
    .post(stationFilter, body)
    .then(({ data }) => {
      let result = []
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const station = _.get(item, 'station', '')

        const stationOption = { ...item, value: uuid, label: station }
        result.push(stationOption)
      })
      setStationList(result)
    })
    .catch((err) => {
      console.log(err)
    })
}

export const handleChangeNumberInput = (e, key) => (dispatch) => {
  let value = _.get(e, 'target.value', '').replace(/[^0-9]/g, '')

  if (!isNaN(value) && !_.isEmpty(value)) {
    value = Number(value)
    if (value <= 0) {
      value = 1
    }
  }

  dispatch(
    handleChange({
      key: key,
      value: value,
    }),
  )
}
