import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import * as yup from 'yup'

export const validationSchema = yup
  .object({
    productName: yup
      .string()
      .max(255, 'ชื่อทรัพย์สินกรอกได้ไม่เกิน 255 ตัวอักษร')
      .required('กรุณาระบุชื่อทรัพย์สิน'),
    category: yup.object().nullable().required('กรุณาเลือกหมวดหมู่'),
    quantityAlert: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .test(
        'quantityAlert',
        'กรุณาระบุจำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย ภายใน 20 หลัก',
        (val) => (val || val === 0) && val.toString().length <= 20,
      )
      .required('กรุณาระบุจำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย ภายใน 20 หลัก'),
    description: yup
      .string()
      .nullable()
      .max(3000, 'กรุณาระบุภายใน 3,000 ตัวอักษร'),
    productImage: yup.object().nullable(),
    refundYear: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .test('validate-year', 'กรุณาระบุจำนวนปี', (value, { parent }) => {
        if (!parent.hasPeriodFlagReturn) return true
        if (value === 0) return true
        return value
      })
      .max(99, 'กรุณาระบุจำนวนปีระหว่าง 0-99 เท่านั้น'),
    refundMonth: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .test('validate-month', 'กรุณาระบุจำนวนเดือน', (value, { parent }) => {
        if (!parent.hasPeriodFlagReturn) return true
        if (value === 0) return true
        return value
      })
      .max(11, 'กรุณาระบุจำนวนเดือนระหว่าง 0-11 เท่านั้น'),
    refundDay: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .test('validate-date', 'กรุณาระบุจำนวนวัน', (value, { parent }) => {
        if (!parent.hasPeriodFlagReturn) return true
        if (value === 0) return true
        return value
      })
      .max(29, 'กรุณาระบุจำนวนวันระหว่าง 0-29 เท่านั้น'),
    isSku: yup.string().nullable(),
    costCenter: yup
      .object()
      .nullable()
      .when('isSku', (isSku, schema) => {
        if (isSku && isSku === enumStatus.INACTIVE) {
          return schema.required('กรุณาเลือก Cost center')
        }
        return schema
      }),
    remark: yup
      .string()
      .nullable()
      .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  })
  .transform((value, originalValue) => {
    return { ...originalValue, ...value }
  })
  .test('validateRefund', function (data) {
    return validateRefund(data, this)
  })

const validateRefund = (data, context) => {
  const { hasPeriodFlagReturn, refundDay, refundMonth, refundYear } = data
  if (!hasPeriodFlagReturn) return true
  if (refundDay === 0 && refundMonth === 0 && refundYear === 0) {
    return context.createError({
      path: `validateRefund`,
      message: 'กรุณาระบุวันเดือนปีมากกว่า 0 วัน',
    })
  }
  return true
}
