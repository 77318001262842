import React, { useState, useMemo } from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import Select from '../../../../components/Input/Select'

import Department from './Department'
import OfficeWork from './OfficeWork'
import Travelling from './Travelling'
import StaffTraining from './StaffTraining'
import { validatePermission } from '../../../../utils/lib'
import { SUB_WORK_TYPE_ACADEMY } from '../../Text'

export const optionsBookingMeeting = [
  {
    label: 'Academy Chiang Mai - สมศรี สุขใจ',
    value: 'AcademyChiangMai',
  },
  {
    label: 'Academy Bangkok - สมหมาย',
    value: 'AcademyBangkok',
  },
]

export default function AcademyActivity(props) {
  const {
    user,
    uuidStaff,
    type,
    state,
    options,
    setOptions,
    workType,
    validateForm,
    setValidateForm,
    stateForm,
    setStateForm,
    optionSubWorkType,
  } = props

  const findSubWorkType = optionSubWorkType.find((item) => {
    return item.uuid === _.get(stateForm, 'academyActivity.subWorkType', '')
  })

  const [loadingDrawer, setLoadingDrawer] = useState(false)
  const [statePermission, setStatePermission] = useState(false)

  const styleBox = { gap: 1, pb: 3, display: 'flex', flexDirection: 'column' }
  useMemo(() => {
    const staffProfile = _.get(options, 'staffProfile', [])
    if (staffProfile.length) {
      const permission = validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: [
          'APPROVE_ZONE',
          'ASSISTS_ZONE',
          'CREATE_ALL',
          'DELETE_ALL',
          'CREATE_ALL_EXC_HOLIDAY',
          'DELETE_ALL_EXC_HOLIDAY',
        ],
      })
      let uuid = uuidStaff
      if (!permission) uuid = _.get(user, 'uuid', '')
      let defaultStaff = staffProfile.find((item) => {
        return item.value === uuid
      })

      const staff = _.get(stateForm, 'academyActivity.attendees', [])
      let newStaff = [...staff]

      if (defaultStaff && staff.length <= 0) newStaff = [...staff, defaultStaff]
      if (!permission) setStatePermission(true)

      setStateForm((prevState) => ({
        ...prevState,
        academyActivity: {
          ...prevState.academyActivity,
          attendees: _.uniqBy(newStaff, 'value'),
        },
      }))
    }
  }, [])

  return (
    <Box
      sx={
        loadingDrawer
          ? { ...styleBox, opacity: 0.4, pointerEvents: 'none', pt: 9.5 }
          : { ...styleBox, pointerEvents: 'unset' }
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Select
          id="work"
          name="work"
          labelText="ประเภทตารางงาน"
          type="basic"
          textError={''}
          required
          options={options.work}
          disabled={true}
          placeholder="กรุณาเลือก"
          value={workType}
        />
        <Select
          id="activityType"
          name="activityType"
          labelText="กิจกรรม"
          type="basic"
          required
          options={options.activity}
          disabled={true}
          placeholder="กรุณาเลือก"
          value={_.get(findSubWorkType, 'uuid', '')}
        />
      </Box>

      {(_.get(findSubWorkType, 'subWorkType', '') ===
        SUB_WORK_TYPE_ACADEMY[6] ||
        _.get(findSubWorkType, 'subWorkType', '') ===
          SUB_WORK_TYPE_ACADEMY[5]) && (
        <Department
          state={state}
          stateForm={stateForm}
          setStateForm={setStateForm}
          options={options}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          type={type}
          statePermission={statePermission}
          loadingDrawer={loadingDrawer}
          setLoadingDrawer={setLoadingDrawer}
        />
      )}

      {_.get(findSubWorkType, 'subWorkType', '') ===
        SUB_WORK_TYPE_ACADEMY[0] && (
        <OfficeWork
          state={state}
          stateForm={stateForm}
          setStateForm={setStateForm}
          options={options}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          type={type}
          statePermission={statePermission}
        />
      )}

      {_.get(findSubWorkType, 'subWorkType', '') ===
        SUB_WORK_TYPE_ACADEMY[4] && (
        <Travelling
          state={state}
          stateForm={stateForm}
          setStateForm={setStateForm}
          options={options}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          type={type}
          statePermission={statePermission}
        />
      )}

      {(_.get(findSubWorkType, 'subWorkType', '') ===
        SUB_WORK_TYPE_ACADEMY[1] ||
        _.get(findSubWorkType, 'subWorkType', '') ===
          SUB_WORK_TYPE_ACADEMY[2] ||
        _.get(findSubWorkType, 'subWorkType', '') ===
          SUB_WORK_TYPE_ACADEMY[3]) && (
        <StaffTraining
          state={state}
          stateForm={stateForm}
          setStateForm={setStateForm}
          options={options}
          setOptions={setOptions}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          type={type}
          loadingDrawer={loadingDrawer}
          setLoadingDrawer={setLoadingDrawer}
          statePermission={statePermission}
          findSubWorkType={findSubWorkType}
        />
      )}
    </Box>
  )
}
