import * as service from '../../../../../services/manageClass'
import { fetchDataList } from '../../../../../modules/ManageClass/List/Table/event'
import { openDialog, closeDialog } from '../../../../../redux/slices/dialog'
import _ from 'lodash'

export const checkStyledLeft = (indexImage) => {
  switch (indexImage) {
    case 0:
      return 0
    case 1:
      return -6
    case 2:
      return -12
    default:
      return -18
  }
}
export const checkStyledZIndex = (indexImage) => {
  switch (indexImage) {
    case 0:
      return 100
    case 1:
      return 80
    case 2:
      return 60
    default:
      return 40
  }
}

export const onAdminChange =
  ({ newValue, row, dialog, table }) =>
  (dispatch) => {
    dispatch(
      openDialog({
        ...dialog,
        handleConfirm: async () =>
          dispatch(onAdminChangeSuccess(newValue, row, table)),
        disableButton: _.isEmpty(_.get(newValue, 'value', '')),
      })
    )
  }

export const onAdminChangeSuccess =
  (newValue, row, table) => async (dispatch) => {
    const result = await dispatch(
      service.updateAdmin({
        staffUuid: _.get(newValue, 'value', ''),
        trainingPlanUuid: row.uuid,
      })
    )
    const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
    if (requestStatus === 'fulfilled') {
      dispatch(
        openDialog({
          title: 'Success',
          message: 'เพิ่มแอดมินหลักสำเร็จ',
          type: 'success',
          handleConfirm: () => dispatch(closeDialog()),
        })
      )
      dispatch(fetchDataList(table, ''))
    }
  }
