import { store } from '../../../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = (versionUuid) => {
  const { filterProp } = store.getState().table.filter
  return {
    eTestVersionUuid: versionUuid,
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    courseCode: _.get(filterProp, 'courseCode', ''),
    classUuid: _.get(filterProp, 'classUuid', ''),
    distribution: _.get(filterProp, 'distribution', ''),
    agentCode: _.get(filterProp, 'agentCode', ''),
    unitCode: _.get(filterProp, 'unitCode', ''),
    ramCode: _.get(filterProp, 'ramCode', ''),
    sRam: _.get(filterProp, 'sRam', ''),
    trainer: _.get(filterProp, 'trainer', ''),
    testType: _.get(filterProp, 'testType', []),
  }
}
