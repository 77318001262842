import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/table'
import _ from 'lodash'
import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { handleQuickSearch } from './handleQuickSearch'
import { onDownload } from './onDownload'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import {
  typeOfETesting,
  versionStatus,
} from '../../../../../constants/eTesting'
import { sortByAgentCode } from '../../View/components/overviewTab/components/ClassDrawer/events'

export const fetchDataList = async (
  mutatePropToBody,
  props = {},
  downloadName,
  tableHeadText,
) => {
  const {
    setTableProps,
    codeId,
    hideDownload,
    quickSearchPlaceHolder,
    isCheckBox,
    defaultSort = 'updatedAt',
    isDashboardTesterListTab,
    method = '',
    methodSearch = '',
  } = props
  const { table, search, filter: filterState } = store.getState().table
  const { crudConfig } = store.getState().crud

  const headerCells = _.get(crudConfig, 'headerCells', () => {})
  const downloadUrl = _.get(crudConfig, 'downloadUrl', () => {})
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp
  let sort = _.get(tableProps, 'sort', defaultSort)
  let order = tableProps.order.toString().toUpperCase()
  let mutateBody = mutatePropToBody(codeId ?? undefined)

  if (method === 'search') {
    mutateBody = {
      ...mutateBody,
      name: searchText,
      status: ['ACTIVE', 'INACTIVE', 'DELETED'],
    }
  }

  if (method === 'searchVersion') {
    mutateBody = {
      ...mutateBody,
      name: searchText,
    }
  }

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: order,
    sort: sort,
    quickSearch: ['search', 'searchVersion'].includes(method)
      ? undefined
      : searchText,
    ...mutateBody,
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }
  body.codeId = _.isEmpty(codeId) ? body.codeId : codeId

  store.dispatch(startLoading())
  const isCancel = await callAxios
    .post(crudConfig.filterPath, body, {
      'x-type': crudConfig.moduleId,
    })
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => {
        return {
          ...item,
          createdAt: convertFormatDateTime({
            value: item.createdAt,
            type: 'date',
          }),

          typeOfETesting: setTypeOfETestingName(
            _.get(item, 'typeOfETesting', '-'),
          ),
          versionStatus: setDashboardVersionStatus(_.get(item, 'status', '-')),
          startDate: convertFormatDateTime({
            value: item.startDate,
            type: 'date',
          }),
          fullName:
            (_.get(item, 'firstNameTH') ?? '') +
            ' ' +
            (_.get(item, 'lastNameTH') ?? ''),
          startTestDateTime: convertFormatDateTime({
            value: _.get(item, 'startTestDateTime', ''),
            type: 'dateTime',
          }),
          testResult: handleTestResult(item),
          testResultTH: item.testResultTH,
          testScore:
            _.get(item, 'statusClassLeaner', '') === 'NOT_SENT' ||
            _.get(item, 'testResult', '') === 'WAITING'
              ? '-'
              : _.get(item, 'testScore', '-'),
          testerListTestType: setTesterListTestType(
            _.get(item, 'reportEtesting.testType', '-'),
            _.get(item, 'reportEtesting.typeOfETesting', '-'),
          ),
          testerListCourseName:
            _.get(item, 'reportEtesting.courseNameEN') ?? '-',
          endTestDateTime: convertFormatDateTime({
            value: item.endTestDateTime,
            type: 'dateTime',
          }),
        }
      })
      let prepareRows = row
      if (isDashboardTesterListTab === true) {
        if (sort === 'updatedAt') {
          prepareRows = []
          const AgencyAgentUser = row
            .filter((data) => {
              return (
                data.distribution === 'Agency' &&
                _.get(data, `userProfile.userType`, '') === 'AGENT'
              )
            })
            .sort((a, b) => sortByAgentCode(a, b))
          const AgencyProspectUser = row
            .filter((data) => {
              return (
                data.distribution === 'Agency' &&
                _.get(data, `userProfile.userType`, '') === 'PROSPECT'
              )
            })
            .sort((a, b) => sortByAgentCode(a, b))
          const BancaUser = row
            .filter((data) => {
              return data.distribution === 'Banca'
            })
            .sort((a, b) => sortByAgentCode(a, b))
          const KTBUser = row
            .filter((data) => {
              return data.distribution === 'KTB'
            })
            .sort((a, b) => sortByAgentCode(a, b))
          const OtherUser = row
            .filter((data) => {
              return !['Agency', 'Banca', 'KTB'].includes(data.distribution)
            })
            .sort((a, b) => {
              const aSort = a.agentCode
              const bSort = b.agentCode
              if (aSort < bSort) {
                return -1
              }
              if (aSort > bSort) {
                return 1
              }
              return 0
            })
          prepareRows.push(...AgencyAgentUser)
          prepareRows.push(...AgencyProspectUser)
          prepareRows.push(...BancaUser)
          prepareRows.push(...KTBUser)
          prepareRows.push(...OtherUser)
        } else if (sort === 'fullName') {
          if (order === 'ASC') {
            prepareRows.sort((a, b) => a.fullName.localeCompare(b.fullName))
          }
          if (order === 'DESC') {
            prepareRows.sort((a, b) => b.fullName.localeCompare(a.fullName))
          }
        } else if (sort === 'testScore') {
          const getScore = (score = 0) => {
            return score.substring(0, score.lastIndexOf('/'))
          }
          if (order === 'ASC') {
            prepareRows.sort(
              (a, b) => getScore(a.testScore) - getScore(b.testScore),
            )
          }
          if (order === 'DESC') {
            prepareRows.sort(
              (a, b) => getScore(b.testScore) - getScore(a.testScore),
            )
          }
        }
      }
      const header = headerCells()
      store.dispatch(
        setInitialTable({
          isCheckBox: isCheckBox,
          tableHeadText: tableHeadText,
          tableHeadVariant: 'body1b',
          sxTable: {},
          rows: prepareRows,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: header,
          placeholder: quickSearchPlaceHolder || 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) =>
            store.dispatch(
              handleQuickSearch(
                tableProps,
                text,
                mutatePropToBody,
                props,
                methodSearch,
                tableHeadText,
              ),
            ),
          onDownload:
            hideDownload === true
              ? 'hidden'
              : (selected, sort, order) =>
                  store.dispatch(
                    onDownload(
                      selected,
                      sort,
                      order,
                      downloadUrl,
                      downloadName,
                      crudConfig.moduleId,
                    ),
                  ),
          onDelete: () => {},
          ...setTableProps,
        }),
      )
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          tableHeadText: tableHeadText,
          rows: [],
          allCount: 0,
          headCells: headerCells(),
          onDownload: () => {},
          handleSearch: (text) => {
            store.dispatch(
              handleQuickSearch(
                tableProps,
                text,
                mutatePropToBody,
                props,
                methodSearch,
                tableHeadText,
              ),
            )
          },
          placeholder: quickSearchPlaceHolder || 'ค้นหา',
        }),
      )
      return e.message?.includes('method')
    })
  if (!isCancel) {
    store.dispatch(stopLoading())
  }
}

function setTypeOfETestingName(type) {
  switch (type) {
    case 'ONLINE_TEST':
      return typeOfETesting.ONLINE_TEST
    case 'ASSESSMENT':
      return typeOfETesting.ASSESSMENT
    case 'STANDALONE':
      return typeOfETesting.STANDALONE
    default:
      return type
  }
}

function setTesterListTestType(type, typeOfETesting) {
  switch (typeOfETesting) {
    case 'STANDALONE':
      return 'Other'
    default:
      return type
  }
}

function setDashboardVersionStatus(type) {
  switch (type) {
    case 'ACTIVE':
      return versionStatus.ACTIVE
    case 'INACTIVE':
      return versionStatus.INACTIVE
    case 'DELETED':
      return versionStatus.DELETED
    case 'WAITING':
      return versionStatus.WAITING
    case 'DRAFT':
      return versionStatus.DRAFT
    default:
      return type
  }
}

export const handleTestResult = (item) => {
  return _.get(item, 'testResult', '') === 'PASS'
    ? 'ผ่าน'
    : _.get(item, 'testResult', '') === 'NOT_PASS'
    ? 'ไม่ผ่าน'
    : _.get(item, 'statusClassLeaner', '') === 'NOT_SENT'
    ? 'ไม่ทำ'
    : _.get(item, 'testResult', '') === 'WAITING'
    ? 'ไม่ทำ'
    : '-'
}
