import _ from 'lodash'
import { openDialog, closeDialog } from '../../../../../redux/slices/dialog'
import { setDeleteStation } from '../../../../../redux/slices/manageContent/station'

export const getMediaType = (data) => {
  const image = _.get(data, 'image', '')
  const videoUrl = _.get(data, 'videoURL', '')
  return image != '' ? 'IMAGE' : videoUrl != '' ? 'VIDEO' : 'NO CONTENT'
}

export const getDefaultValue = (data, key) => {
  const value = _.get(data, key, '')
  return value == '' ? '-' : value
}

export const handleConfirmDelete = (index) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันการลบสถาบันฝึกอบรม',
      message: 'คุณต้องการลบข้อมูลนี้หรือไม่',
      handleConfirm: () => {
        dispatch(setDeleteStation(index))
        dispatch(closeDialog())
      },
    })
  )
}
