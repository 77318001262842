import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledCard,
  LoadingCircular,
} from './Styled'
import { closeCourseDrawer } from '../../../../../../../../redux/slices/ETestingDashboard'

import Table2 from '../../../../../../../../components/redux/Table2'
import { fetchData } from './events'
import {
  resetRowsPerPage,
  setSearchText,
} from '../../../../../../../../redux/slices/table2'

const ClassDrawer = () => {
  const dispatch = useDispatch()

  const { open, header, courseType } = useSelector(
    (state) => ({
      open: state.eTestingDashboard.courseDrawer.isOpen,
      header: state.eTestingDashboard.courseDrawer.header,
      courseType: state.eTestingDashboard.courseDrawer.courseType,
    }),
    shallowEqual,
  )
  const learner = useSelector((state) => state.crud.learner, shallowEqual)
  const table = useSelector((state) => state.table2.table, shallowEqual)
  const { limit, page, isLoading, allCount } = table
  //const { searchText } = search
  useEffect(() => {
    open && dispatch(fetchData(courseType, learner, courseType))
    if (!open) {
      dispatch(setSearchText(''))
      dispatch(resetRowsPerPage())
    }
  }, [open, page, limit])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(closeCourseDrawer())}
      sx={{ width: !open && '0px' }}
      courseType={courseType}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">{header}</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(closeCourseDrawer())}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box
        sx={{
          height: '34px',
          backgroundColor: '#F1F4FA',
          borderRadius: '16px',
          padding: '4px',
          width: `fit-content`,
          margin: `16px 0px 16px 22px`,
        }}
      >
        <Typography
          variant="body2b"
          fontSize="18px"
          sx={{ padding: '3px 6px', color: '#00008F' }}
        >
          ทั้งหมด {allCount || 0} คน
        </Typography>
      </Box>
      <StyledCard isLoading={isLoading}>
        <Table2 />
      </StyledCard>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default ClassDrawer
