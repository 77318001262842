import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxTextAlert = styled(Box)(({ theme, loadingProgress }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  marginTop: loadingProgress ? theme.spacing(2) : 0,
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}))
