import _ from 'lodash'

export const handlePercentInput = (e, testAndEvaProps, setInitialValues) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const eTesting = testAndEvaArr[index]
  eTesting.criteriaPercent = e.target.value
  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
}

export const handleNumberInput = (e) => {
  const numVal = Math.abs(e.target.value).toFixed()

  if (_.startsWith(e.target.value, '0') || e.nativeEvent.data == '.') {
    e.target.value = null
  }

  e.target.value = numVal >= 0 ? e.target.value : null // min 0
  e.target.value = numVal <= 100 ? e.target.value : 100 // max 100

  return e.target.value
}

export const handleOtherInput = (e, testAndEvaProps, setInitialValues) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const eTesting = testAndEvaArr[index]
  eTesting.otherType = e.target.value
  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
}
