import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'

import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import DateRangeInput from '../../../../components/DateRangeInput'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../components/FilterDrawer/Styled'
import { arrayCheckbox, defaultFilter } from './model'
import * as events from './events'
import { handleNumberInput } from '../../../../utils/lib'

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => (
    <StyledGroupBox key={index}>
      <FormGroup>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              inputProps={{
                'data-testid': `check-${item.nameCheckBox}`,
              }}
              name={item.nameCheckBox}
              checked={stateFilter[item.nameCheckBox]}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter: stateFilter,
                  setFilter,
                })
              }}
            />
          }
          sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
        />

        {stateFilter[item.nameCheckBox] && (
          <>
            {item.type === 'text' && (
              <InputBase
                sx={{ borderRadius: 0 }}
                type="basic"
                inputProps={
                  item.nameInput === 'attend'
                    ? {
                        'data-testid': `input-${item.nameInput}`,
                        type: 'number',
                        min: '0',
                        onInput: handleNumberInput,
                      }
                    : { 'data-testid': `input-${item.nameInput}` }
                }
                name={item.nameInput}
                disabled={!stateFilter[item.nameCheckBox]}
                placeholder={item.placeholder}
                value={stateFilter[item.nameInput]}
                onChange={(e) => {
                  events.handleChange({
                    key: e.target.name,
                    value: e.target.value,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            )}
            {item.type === 'date' && item.nameCheckBox === 'examDateChecked' && (
              <DateRangeInput
                placeholder={item.placeholder}
                dateState={{
                  startDate: stateFilter.startExamDate
                    ? stateFilter.startExamDate
                    : new Date(),
                  endDate: stateFilter.finishExamDate
                    ? stateFilter.finishExamDate
                    : new Date(),
                  display: stateFilter.displayDate1,
                  key: 'selection',
                }}
                nameCheckBox="examDateChecked"
                onChange={(selectedDates) => {
                  events.handleSelectDate(
                    selectedDates,
                    stateFilter,
                    setFilter,
                    item.nameCheckBox
                  )
                }}
              />
            )}
            {item.type === 'date' && item.nameCheckBox === 'importDateChecked' && (
              <DateRangeInput
                placeholder={item.placeholder}
                dateState={{
                  startDate: stateFilter.startImportDate
                    ? stateFilter.startImportDate
                    : new Date(),
                  endDate: stateFilter.finishImportDate
                    ? stateFilter.finishImportDate
                    : new Date(),
                  display: stateFilter.displayDate2,
                  key: 'selection',
                }}
                nameCheckBox="importDateChecked"
                onChange={(selectedDates) => {
                  events.handleSelectDate(
                    selectedDates,
                    stateFilter,
                    setFilter,
                    item.nameCheckBox
                  )
                }}
              />
            )}
          </>
        )}
      </FormGroup>
    </StyledGroupBox>
  ))
}

const FilterDrawer = ({ open, table }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
