export const paymentMethodEnum = {
  CASH_INVOICE: 'CASH_INVOICE',
  CREDIT_CARD: 'CREDIT_CARD',
  OTHER: 'OTHER',
}

export const paymentMethodType = [
  { label: 'Cash Invoice', value: 'CASH_INVOICE'},
  { label: 'Credit Card', value: 'CREDIT_CARD'},
  { label: 'Other', value: 'OTHER'},
]

export const paymentMethodTxt = {
  CASH_INVOICE: 'Cash Invoice',
  CREDIT_CARD: 'Credit Card',
  OTHER: 'Other',
}