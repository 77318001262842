import { LoadingPage, StyledCard } from '../../../Styled'
import { shallowEqual, useSelector } from 'react-redux'
import { Header } from './Header'
import { RoomDetails } from './RoomDetails'
import _ from 'lodash'

export const RoomDetailsCard = () => {
  const { isLoadingDetails, roomDetails } = useSelector(
    (state) => ({
      isLoadingDetails: state.roomManagement.isLoadingDetails,
      roomDetails: state.roomManagement.roomDetails,
    }),
    shallowEqual,
  )

  return (
    <StyledCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        mt: 2,
        minHeight: 500,
        position: 'relative',
      }}
    >
      {!_.isEmpty(roomDetails) ? (
        <>
          <Header {...roomDetails} />
          <RoomDetails {...roomDetails} />
        </>
      ) : (
        <LoadingPage isLoading={isLoadingDetails} />
      )}
    </StyledCard>
  )
}
