import { PRODUCT_TYPE } from '../../../../../constants/stock'
import { store } from '../../../../../App'
import _ from 'lodash'
import {
  drawerFormValidationSchemaAsset,
  drawerFormValidationSchemaLicense,
  drawerFormValidationSchemaSKU,
} from '../schema/schema'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'
import { handleProcessSubmitForm } from './handleProcessSubmitForm'
import {
  replaceFieldError,
  setFieldError,
} from '../../../../../redux/slices/crud'
import { openDialog } from '../../../../../redux/slices/dialog'

export const handleSubmitEditAsset = async (productType) => {
  const { formData } = store.getState().crud
  let body = {
    skuUuid: _.get(formData, 'skuUuid', ''),
    skuNo: _.get(formData, 'skuNoForm', ''),
    unitPrice: _.get(formData, 'unitPriceForm', ''),
    costCenterUuid: _.get(formData, 'costCenterForm.value', ''),
    skuStatus: _.get(formData, 'skuStatusForm', 'INACTIVE'),
  }

  let validationSchema = drawerFormValidationSchemaSKU
  if ([PRODUCT_TYPE.ASSET, PRODUCT_TYPE.LICENSE].includes(productType)) {
    validationSchema = drawerFormValidationSchemaAsset
    body = {
      ...body,
      assetNo: _.get(formData, 'assetNoForm', ''),
      contractStartDate: _.get(formData, 'contractStartDateForm', ''),
      contractEndDate: _.get(formData, 'contractEndDateForm', ''),
      checkEndDate: _.get(formData, 'checkEndDateForm', ''),
      renewContractDate: _.get(formData, 'renewContractDateForm', ''),
      holder: _.get(formData, 'holderForm', ''),
    }
  }

  if ([PRODUCT_TYPE.LICENSE].includes(productType)) {
    validationSchema = drawerFormValidationSchemaLicense
    body = {
      ...body,
      paymentType: _.get(formData, 'paymentTypeForm', ''),
      paymentDesc: _.get(formData, 'paymentDescForm', ''),
      paymentFile: _.get(formData, 'paymentFileForm', ''),
      paymentImg: _.get(formData, 'paymentImgForm', ''),
      payer: _.get(formData, 'payerForm', ''),
    }
  }

  const isValid = await validateFormData(formData, validationSchema)
  if (isValid) {
    confirmDialog(body)
  }
}

export async function validateFormData(formData, validationSchema) {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}

function confirmDialog(body) {
  store.dispatch(
    openDialog({
      title: 'ยืนยันการบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่',
      handleConfirm: () => handleProcessSubmitForm(body),
      isCloseDialog: false,
    }),
  )
}
