import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AvailableRoom from './AvailableRoom'
import RoomRequestOverview from './RoomRequestOverview'
import MostUsed from './MostUsed'

const Content = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <AvailableRoom />
        <RoomRequestOverview />
      </Grid>
      <MostUsed />
    </Box>
  )
}

export default Content
