import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledCardSub1 = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '16px',
}))

export const StyledCardHeader1 = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    minHeight: '66px',
    width: '45%',
    background:
      'linear-gradient(90.21deg, #E8F5E9 0%, rgba(255, 255, 255, 0) 100%)',
    border: `1px solid rgba(2, 113, 128, 0.2)`,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  })
)

export const StyledCardHeader2 = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    minHeight: '66px',
    width: '45%',
    border: `1px solid rgba(73, 77, 244, 0.2)`,
    background:
      'linear-gradient(90.38deg, #DBE4F1 0%, rgba(255, 255, 255, 0) 100%)',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  })
)

export const StyledCardHeader3 = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    minHeight: '66px',
    minWidth: '140px',
    width: '10%',
    background: '#494DF4',
    paddingLeft: '16px',
    paddingRight: '16px',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  })
)

export const TypographyTableHeader = styled(Box)(() => ({
  width: '250px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: '#FFF',
  paddingLeft: '12px',
  '& > .MuiTypography-root': {
    lineHeight: '24px',
    fontWeight: 500,
    fontSize: 18,
  },
}))

export const TypographyTableHeader2 = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  '& > .MuiTypography-root:first-of-type': {
    lineHeight: '18px',
  },
  '& > .MuiTypography-root': {
    lineHeight: '24px',
  },
}))

export const ButtonSubmit = styled('button')(({ theme, disabled }) => {
  let boxShadow = '0px 4px 12px 1px rgba(240, 118, 98, 0.35)'
  let backgroundColor = theme?.palette?.secondary?.main
  let color = theme?.palette?.primary?.contrast
  let cursor = 'pointer'
  if (disabled) {
    boxShadow = '0px'
    backgroundColor = theme?.palette?.background?.paper
    color = theme?.palette?.text?.disabled
    cursor = 'not-allowed'
  }
  return {
    minWidth: '47px',
    height: '40px',
    borderRadius: theme?.shape?.borderRadius,
    border: `0px`,
    outline: 'none',
    cursor: cursor,
    color: color,
    backgroundColor: backgroundColor,
    boxShadow: boxShadow,
  }
})

export const StyledCardSub2 = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
}))

export const Sub = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: theme.spacing(2),
}))

export const StyledCardSub3 = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme?.palette?.background?.paper,
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme?.palette?.text?.lightBlue}`,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))
