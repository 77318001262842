import React from 'react'
import { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import CancelIcon from '@mui/icons-material/Cancel'

const CreatableGroupChip = (props) => {
  const {
    boxSx,
    labelText,
    required,
    id,
    placeholder,
    disabled,
    textError,
    onChange,
    onInputChange,
    onKeyDown,
    inputValue,
    value,
    showTextError = true,
    variant,
  } = props
  const theme = useTheme()

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={variant ?? 'body2'}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <CreatableSelect
        components={{ DropdownIndicator: null, MultiValueRemove, SingleValue }}
        inputValue={inputValue}
        isClearable
        isMulti
        isDisabled={disabled}
        menuIsOpen={false}
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
        data-testid={id}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: theme?.palette?.text?.silver,
            margin: 0,
          }),
          input: (base) => ({ ...base, margin: 0 }),
          control: (base) => {
            const activeBorder =
              base.borderColor == '#2684FF'
                ? theme?.palette?.primary?.main
                : theme?.palette?.text?.silver
            const borderColor =
              textError && textError.length > 0
                ? theme?.palette?.error?.main
                : activeBorder
            return {
              ...base,
              borderRadius: 0,
              borderColor,
              boxShadow: 'unset',
              ':hover': { borderColor },
            }
          },
          multiValueRemove: (base) => ({
            ...base,
            borderRadius: 16,
            color: theme?.palette?.other?.outlineBorder,
            '&:hover': {
              backgroundColor: theme?.palette?.action?.hover,
              color: theme?.palette?.action?.disabled,
              cursor: 'pointer',
            },
          }),
          multiValue: (base) => ({
            ...base,
            border: `1px solid ${theme?.palette?.text?.silver}`,
            borderRadius: 16,
            backgroundColor: theme?.palette?.text?.whtie,
          }),
        }}
      />
      {showTextError && textError && textError.length > 0 && (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}

export default CreatableGroupChip

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>{props.children}</components.SingleValue>
  )
}

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CancelIcon />
    </components.MultiValueRemove>
  )
}
