import React from 'react'
import Box from '@mui/material/Box'

import Header from './Header'
import CardContent from './CardContent'

const Index = () => {
  return (
    <Box sx={{ mx: 3 }}>
      <Header label="เช็คชื่อ" />
      <CardContent />
    </Box>
  )
}

export default Index
