import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { handleChangeTab } from './events'
import { FORM_TAB, FORM_TAB_TH } from './model'
import { Box } from '@mui/material'
import { StyledTabView } from '../Styled'

const TabMenu = ({ tab }) => {
  const dispatch = useDispatch()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <StyledTabView
        value={tab}
        onChange={(_, value) => dispatch(handleChangeTab(value))}
      >
        <Tab
          value={FORM_TAB.content}
          label={
            <Typography variant="body1b" color="primary">
              {FORM_TAB_TH.content}
            </Typography>
          }
        />
        <Tab
          value={FORM_TAB.setting}
          label={
            <Typography variant="body1b" color="primary">
              {FORM_TAB_TH.setting}
            </Typography>
          }
        />
      </StyledTabView>
    </Box>
  )
}
export default TabMenu
