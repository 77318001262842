import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import _ from 'lodash'
import { totalUserAgencyModel, totalUserBancModel } from '../../models/overview'
import { DataCard } from '../ModuleCard/DataCard'
import { handleClickButton } from '../../handler/handleClickButton'

const TotalUser = () => {
  const totalUserAgency = totalUserAgencyModel()
  const totalUserBanc = totalUserBancModel()
  const finalResult = [
    _.get(totalUserAgency, '[0].number', 0),
    _.get(totalUserBanc, '[0].number', 0),
  ]
  const colorArray = [
    _.get(totalUserAgency, '[0].dotColor', ''),
    _.get(totalUserBanc, '[0].dotColor', ''),
  ]
  const totalModule = _.sum(finalResult)
  return (
    <Box height={'100%'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" gap={2}>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
            >
              <Typography variant="h6">ยอดรวม user ทุกช่องทาง</Typography>
              <Box display="flex" gap={2} mt={3} alignItems="center">
                <DoughnutChart
                  dataSet={{
                    data: finalResult,
                    backgroundColor: colorArray,
                  }}
                  height={224}
                  label="ทั้งหมด"
                  value={totalModule}
                  eleId={'allUserDougnutChart'}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                title={'Agency & CS'}
                dataList={totalUserAgency}
                totalModule={totalModule}
                handleClickButton={handleClickButton}
              />
              <DataCard
                title={'Banc & KTB'}
                dataList={totalUserBanc}
                totalModule={totalModule}
                handleClickButton={handleClickButton}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default TotalUser
