import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { store } from '../../../../../App'
import { CustomSelect } from '../../../../../components/CRUD/components/CustomSelect'
import { Box } from '@mui/material'

export const handleOpenCreateDialog = async (history) => {
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'สร้างรายการ',
      content: (
        <Box px={2} pb={2}>
          <CustomSelect
            fieldName="requisitionType"
            labelName="ประเภทรายการเบิก"
          />
        </Box>
      ),
      agreeText: 'ตกลง',
      disagreeText: 'ยกเลิิก',
      isCloseDialog: false,
      handleConfirm: () => {
        handleConfirmDialog(history)
      },
    }),
  )
}

const handleConfirmDialog = (history) => {
  const { requisitionType } = store.getState().crud.formData
  history.push(
    `/inventory/requisition/${requisitionType
      .toLowerCase()
      .replace('_', '-')}/form`,
  )
  store.dispatch(closeDialog())
}
