import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'

import { countQuestions, countTotal } from './event'

const Index = () => {
  const propsEvent = useSelector(
    (state) => ({
      courseAssessments: state.eEvaluationForm.courseAssessments,
      courseManagementAssessments:
        state.eEvaluationForm.courseManagementAssessments,
      lecturerAssessments: state.eEvaluationForm.lecturerAssessments,
      questions: state.eEvaluationForm.questions,
      toggleQuestions: state.eEvaluationForm.toggleQuestions,
      eEvaluationType: state.eEvaluationForm.eEvaluationType,
    }),
    shallowEqual
  )

  return (
    <>
      <Box sx={{ my: 3 }}>
        <Typography variant="h6">
          {propsEvent.eEvaluationType === 'CLASS'
            ? `คำถาม ${countQuestions(propsEvent.toggleQuestions)} ตอน `
            : ''}
          จำนวน {countTotal(propsEvent)} ข้อ
        </Typography>
      </Box>
    </>
  )
}

export default Index
