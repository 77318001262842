export const yearBudgetOptions = () => {
  let currentYear = 2020 //new Date().getFullYear() - 4
  let numberOfYears = 2100
  const yearList = []

  while (currentYear <= numberOfYears) {
    yearList.push({ label: currentYear.toString(), value: currentYear })
    currentYear++
  }

  return yearList
}
