import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import EditIcon from '@mui/icons-material/Edit'

import HistoryDrawer from '../../../HistoryDrawer'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { Container } from './Styled'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { validateEPermission } from '../../../../../../src/utils/lib'
import {
  CS_AGENT_PROFILE_SETTING,
  PERMISSION,
} from '../../../../../../src/constants/permission/csAgentProfile'

const listBreadcrumbCsAgent = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล CS Agent',
    link: '/manage/cs-agent-profile',
    pointer: true,
  },
  { title: 'รายละเอียดข้อมูล CS Agent', link: '/', pointer: false },
]

const CsHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { id: uuid } = useParams()
  const history = useHistory()

  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual
  )

  const fullName = _.get(data, 'info.fullNameTH', '')

  const hasEdit = validateEPermission({
    module: CS_AGENT_PROFILE_SETTING,
    permission: PERMISSION.EDIT,
  })
  return (
    <Container>
      <Box>
        <Typography variant="h4">
          {`รายละเอียดข้อมูล CS Agent : ${fullName}`}
        </Typography>
        <Breadcrumbs menuList={listBreadcrumbCsAgent} />
      </Box>
      <Box sx={{ gap: 3, display: 'flex' }}>
        <Button
          startIcon={<HistoryTwoTone color="primary.main" />}
          variant="outlined"
          size="m"
          data-testid="btn-history"
          onClick={() => setIsOpen(true)}
        >
          ประวัติการแก้ไข
        </Button>
        {hasEdit && (
          <Button
            startIcon={<EditIcon />}
            variant="contained"
            data-testid="btn-edit"
            size="m"
            onClick={() =>
              history.push(`/manage/cs-agent-profile/edit/${uuid}`)
            }
          >
            แก้ไข
          </Button>
        )}
      </Box>

      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </Container>
  )
}

export default CsHeader
