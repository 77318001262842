import * as yup from 'yup'
import _ from 'lodash'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { isValidationExcepted } from '../../events'
import { EMBED_TYPE } from './model'
import { handleHtmlLink, validateVideo } from './events'

export const embedContentSchema = yup.object().shape({
  type: yup
    .string()
    .test('required', 'กรุณาเลือกรูปแบบ Link', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.EMBED_CONTENT)) return true
      return value
    }),
  value: yup
    .string()
    .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร')
    .test('required', 'กรุณากรอกข้อมูล', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.EMBED_CONTENT)) return true
      return value
    })
    .test(
      'link-format',
      'Link ไม่ตรงกับ Format Link วิดีโอต้องเป็น Youtube หรือ Vimeo เท่านั้น',
      (value, { parent }) => {
        if (parent.type === EMBED_TYPE.HTML) return true
        return validateVideo(value) || _.isEmpty(value)
      },
    )
    .test(
      'html-format',
      'รูปแบบของ HTML ไม่ถูกต้อง',
      (value, { parent }) => {
        if (parent.type === EMBED_TYPE.LINK) return true
        const htmlLink = handleHtmlLink(parent.type, value)
        return validateVideo(htmlLink) || _.isEmpty(value)
      },
    ),
})
