import { headerCells } from './model/headerCells'
import { productListFilter } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_PRODUCT',
  moduleSlug: 'inventory-product',
  moduleTitleFull: 'ทรัพย์สิน',
  modulePath: '/inventory/product',
  filterPath: productListFilter,
  headerCells: headerCells,
}
