import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { getStaffInArea, getHistory } from '../../../services/monthlyPlan'

const monthlyPlan = createSlice({
  name: 'monthlyPlan',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    //report
    startLoadingRP: (state) => {
      state.report.isLoading = true
    },
    stopLoadingRP: (state) => {
      state.report.isLoading = false
    },
    setTabActiveReport: (state, { payload }) => {
      state.report.tabActive = payload
    },
    setInitialReportMP: (state, { payload }) => {
      const { headCellsReport, reportsData } = payload
      state.report.headCellsReport = headCellsReport
      state.report.reportsData = reportsData
    },
    setReduxReport: (state, { payload }) => {
      const { key, value } = payload
      state.report[key] = value
    },
    //class dashboard
    setIsOpenClassDrawer: (state, { payload }) => {
      state.dashboard.isOpenClassDrawer = payload
    },
    //dashboard
    startLoadingDashboard: (state) => {
      state.dashboard.isLoading = true
    },
    stopLoadingDashboard: (state) => {
      state.dashboard.isLoading = false
    },
    setReduxDashboard: (state, { payload }) => {
      state.dashboard = payload
    },
    setFiltersDashboard: (state, { payload }) => {
      state.dashboard.filters = payload
    },
    setReduxDashboardByKey: (state, { payload }) => {
      const { key, value } = payload
      state.dashboard[key] = value
    },
    setFiltersListDisplay: (state, { payload }) => {
      state.dashboard.filtersList = payload
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.dashboard.isOpenFilterDrawer = payload
    },
    setDisplayFilter: (state, { payload }) => {
      state.dashboard.displayFilters = payload
    },
    setWorkTypeDataByKey: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setIsOpenStaffDrawer: (state, { payload }) => {
      state.dashboard.isOpenStaffDrawer = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffInArea.fulfilled, (state, { payload }) => {
        state.staffList = _.get(payload, 'result', [])
      })
      .addCase(getStaffInArea.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        state.historyList = _.get(payload, 'data', [])
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingRP,
  stopLoadingRP,
  setTabActiveReport,
  setInitialReportMP,
  setReduxReport,
  startLoadingDashboard,
  stopLoadingDashboard,
  setFilterDisplay,
  setIsOpenClassDrawer,
  setReduxWorkloadDashboardByKey,
  setReduxDashboard,
  setFiltersDashboard,
  setReduxDashboardByKey,
  setFiltersListDisplay,
  setIsOpenFilterDrawer,
  setDisplayFilter,
  setWorkTypeDataByKey,
  setIsOpenStaffDrawer,
} = monthlyPlan.actions

export default monthlyPlan.reducer
