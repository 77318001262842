import React, { useState } from 'react'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  hasPermissionDefaultAndMonthlyPlanArea,
  validatePermission,
} from '../../../../../utils/lib'
import { handleOnClickMenu } from './events'

const DropdownButton = ({ id, row, rowIndex }) => {
  const { id: traningPlanUuid } = useParams()
  const { isCancel, data } = useSelector((state) => ({
    isCancel: state.manageClassDetail.isCancel,
    data: state.manageClassDetail.data,
  }))
  const type = _.get(row, 'testAndEvaluateType', 'E_TESTING')
  const uuid = _.get(row, 'testOrEvaluateUuid', '')
  const onlyUuid = _.get(row, 'uuid', '')
  const startDate = _.get(data, 'monthlyPlan.startDate', '')
  const finishDate = _.get(data, 'monthlyPlan.finishDate', '')

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const getPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: data,
    permissionStr: 'EDIT_EXAM',
  })
  const hasEvaDashboardPermission = validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['DASHBOARD_EVALUATION'],
  })

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid={`btn-${id}`}
        id={id}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isCancel}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': id }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions(
          uuid,
          traningPlanUuid,
          onlyUuid,
          startDate,
          finishDate,
        )[type].map((option, index) => {
          if (!getPermissionEdit && option.text === 'ตรวจข้อสอบ') return <></>
          if (!hasEvaDashboardPermission && option.text === 'ภาพรวม')
            return <></>
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                const props = { option, type, row, rowIndex, traningPlanUuid }
                dispatch(handleOnClickMenu(props))
                setAnchorEl(null)
              }}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = (
  uuid,
  traningPlanUuid,
  onlyUuid,
  startDate,
  finishDate,
) => ({
  E_TESTING: [
    { text: 'แชร์' },
    {
      text: 'ดูแบบทดสอบ',
      link: `/e-testing/view-mode/${uuid}`,
    },
    {
      text: 'ตรวจข้อสอบ',
      link: `/manage-class/check-exam/${traningPlanUuid}/${onlyUuid}`,
    },
  ],
  E_EVALUATION: [
    { text: 'แชร์' },
    {
      text: 'ดูแบบประเมิน',
      link: `/e-evaluation/view-mode/${uuid}`,
    },
    {
      text: 'สถานะทำแบบประเมิน',
      link: `/manage-class/detail/evaluation-status/${onlyUuid}?trainingPlanUuid=${traningPlanUuid}`,
    },
    {
      text: 'ภาพรวม',
      link: `/manage-class/detail/evaluation-dashboard/${uuid}?trainingPlanUuid=${traningPlanUuid}&startDate=${startDate}&finishDate=${finishDate}`,
    },
  ],
})
