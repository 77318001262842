import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextInput from '../../../../../../components/Input/TextInput'
import DatePicker from '../../../../../../components/Input/DatePicker'
import Select from '../../../../../../components/Input/Select'
import { minuteCountOptions } from '../../../../../../modules/CourseSetting/Form'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChangeEvent } from '../../events'
import { handleInputFormat, handlePasteFormat, onDateChange } from './events'

const Period = () => {
  const dispatch = useDispatch()
  const { periodStart, totalHour, totalMinute, onGoing } = useSelector(
    (state) => ({
      periodStart: getStateValue('periodStart', null),
      totalHour: getStateValue('totalHour'),
      totalMinute: getStateValue('totalMinute', 0),
      courseRef: getStateValue('courseRef', []),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">ระยะเวลาของหลักสูตร</Typography>
      <DatePicker
        id="startDate"
        labelText="วันเริ่มต้น"
        placeholder=""
        required
        disablePast
        hideDisableDateRange
        sxTextError={{ mb: 0 }}
        textError={_.get(errors, 'periodStart', '')}
        hideTextError={_.get(errors, 'periodStart', '') ? false : true}
        value={periodStart}
        onChange={(value) => dispatch(onDateChange(value, 'periodStart'))}
        disabled={onGoing}
        disabledInput={onGoing}
      />

      <Box sx={{ display: 'flex', gap: 3 }}>
        <TextInput
          required
          type="basic"
          id="input-hours"
          name="totalHour"
          labelText="จำนวนชั่วโมงของหลักสูตร (ไม่รวมเวลาพักเที่ยง)"
          maxlength={4}
          inputProps={{
            onInput: (e) => handleInputFormat(e),
            onPaste: (e) => handlePasteFormat(e),
          }}
          sxTextError={{ mb: 0 }}
          textError={_.get(errors, 'totalHour', '')}
          isShowTextError={_.get(errors, 'totalHour', '')}
          value={totalHour}
          onChange={(e) => dispatch(handleChangeEvent(e))}
          disabled={onGoing}
        />
        <Select
          required
          dataTestid="select-minutes"
          id="totalMinute"
          name="totalMinute"
          labelText="จำนวนนาที"
          type="basic"
          textErrorSx={{ mb: 0 }}
          textError={_.get(errors, 'totalMinute', '')}
          showTextError={_.get(errors, 'totalMinute', '')}
          value={totalMinute}
          options={minuteCountOptions}
          handleChange={(e) => dispatch(handleChangeEvent(e))}
          disabled={onGoing}
        />
      </Box>
    </StyledFormCard>
  )
}

export default Period
