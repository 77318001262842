import _ from 'lodash'
import {
  resetErrorUpload,
  setErrorUpload,
  startUploadLoading,
  stopUploadLoading,
} from '../../../../../../redux/slices/manageContent/login'
import { postUploadLoginBanner } from '../../../../../../services/manageContent'

export const handleUploadImage = (e, setValue) => async (dispatch) => {
  if (e.target.files.length === 0) return

  const imageName = _.get(e, 'target.files[0].name', undefined)
  if (!imageName) {
    dispatch(setErrorMeesage('file'))
    return
  }
  const getExtensionImage = imageName.split('.').pop()
  const sizeFile = _.get(e, 'target.files[0].size', 0)
  if (!checkTypeFile(getExtensionImage)) {
    dispatch(setErrorMeesage('file'))
    return
  }
  if (sizeFile > 2000000) {
    dispatch(setErrorMeesage('max'))
    return
  }
  const body = new FormData()
  body.append('file', e.target.files[0])

  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted =
        (progressEvent.loaded / progressEvent.total) * 100
      const upload = document.getElementById('loading-image')
      upload.style.width = `${percentCompleted}%`
    },
  }
  dispatch(startUploadLoading())
  const response = await dispatch(postUploadLoginBanner({ body, config }))
  const data = _.get(response, 'payload.data.key', undefined)
  const payload = _.get(response, 'meta.requestStatus', 'rejected')
  _.set(document.getElementById('input-upload'), 'value', null)
  dispatch(stopUploadLoading())
  if (payload !== 'rejected') {
    setValue((prev) => ({
      ...prev,
      image: data,
    }))
    dispatch(resetErrorUpload())
  } else {
    dispatch(setErrorMeesage('errorApi'))
  }
}

export const setErrorMeesage = (type) => (dispatch) => {
  let text = 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง'
  if (type === 'file') {
    text = 'นามสกุลไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง'
  } else if (type === 'max') {
    text = 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง'
  }
  _.set(document.getElementById('input-upload'), 'value', null)
  dispatch(setErrorUpload(text))
}

export const checkTypeFile = (type) => {
  return type === 'jpg' || type === 'jpeg' || type === 'png'
}
