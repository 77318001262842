import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxAddChoice = styled(Box)(({ theme }) => ({
  width: '100px',
  display: 'flex',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(6),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  },
}))
