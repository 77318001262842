import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import useTheme from '@mui/system/useTheme'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useAuth } from 'react-oidc-context'
import _ from 'lodash'
import { deleteFromStorage, useLocalStorage } from '@rehooks/local-storage'

import { isMediaWidthMoreThan } from '../../utils/lib'
import { SmallImg } from '../Preview/PreviewImg'

import ToDoListNotificationView from '../../../src/modules/ToDoList/ToDoListNotificationView'

export const handleLogout = (keycloak, expire = false) => {
  deleteFromStorage('user')
  deleteFromStorage('eTesting')
  deleteFromStorage('token')
  deleteFromStorage('refresh_token')
  if (expire) {
    if (window.__env__.ENV === 'DEV') {
      keycloak?.signinRedirect({
        redirect_uri: `${window.location.origin}/to-do-list`,
      })
    } else {
      keycloak?.signinRedirect({
        redirect_uri: `${window.location.origin}`,
      })
    }
  } else {
    if (window.__env__.ENV === 'DEV') {
      keycloak.signoutRedirect({
        post_logout_redirect_uri: window.__env__.REACT_APP_LOGOUT_URL,
      })
    } else {
      const url_logout = `${window.__env__.ONE_LOGOUT_URL}`
      const wt = window.open(
        url_logout,
        'staff',
        'width=10,height=1,left=5,top=3',
      )
      setTimeout(() => {
        wt?.close()
        keycloak?.signoutRedirect({
          post_logout_redirect_uri: `${window.__env__.REACT_APP_APP_URL}`,
        })
      }, 500)
      return false
    }
  }
}
export const handleClick = (event, setAnchorEl) => {
  setAnchorEl(event.currentTarget)
}
export const handleClose = (setAnchorEl) => {
  setAnchorEl(null)
}

export const handleProfile = (user, history) => {
  if (_.get(user, 'uuid', '') !== '')
    history.push('/manage/staff-profile/' + user.uuid)
}

export const handleChange = (open, setOpen) => {
  setOpen(!open)
}

export const redirectToLearner = () => {
  window.location.href = `${window.__env__.REACT_APP_LEARNER_URL}/switch-from-backend`
}

export default function Appbar({ open, setOpen }) {
  const theme = useTheme()
  const history = useHistory()
  const auth = useAuth()
  const [user] = useLocalStorage('user')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isOpen = Boolean(anchorEl)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isHaveImage = _.get(user, 'image', '') !== ''

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: theme?.palette?.primary?.contrast,
          boxShadow: theme?.palette?.shadow?.main,
          zIndex: 1198,
        }}
      >
        <Toolbar sx={{ boxShadow: theme?.palette?.boxShadow?.menu }}>
          <Box
            sx={{
              mx: 5,
              mt: 1,
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <img src="/logo/logo_app.png" alt="AMS" height="48px" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            }}
          >
            <IconButton
              sx={{ mr: 2 }}
              data-testid="btn-drawer-menu"
              onClick={() => handleChange(open, setOpen)}
            >
              {open ? (
                <CloseIcon color="primary" />
              ) : (
                <MenuIcon color="primary" />
              )}
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ pt: 1.5, pl: 1.5 }}>
              <img src="/logo/logo_app.png" alt="AMS" height="48px" />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', position: 'absolute', right: 32 }}>
            <ToDoListNotificationView />
            <Button
              sx={{ lineHeight: '34px', gap: 1, minWidth: 24 }}
              id="img-profile-btn"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event) => handleClick(event, setAnchorEl)}
            >
              {isHaveImage ? (
                <SmallImg
                  style={{ width: 24, height: 24 }}
                  src={`${window.__env__.REACT_APP_API_URL}/file/${user?.image}`}
                  data-testid="small-image"
                />
              ) : (
                <AccountCircleIcon />
              )}
              {isNotSm &&
                _.get(user, 'firstNameTH', '') +
                  ' ' +
                  _.get(user, 'lastNameTH', '')}
            </Button>
            <Menu
              data-testid="img-profile-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={() => handleClose(setAnchorEl)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                data-testid="profile-menu"
                onClick={() => handleProfile(user, history)}
              >
                Profile
              </MenuItem>
              <MenuItem
                data-testid="switch-to-learner-menu"
                onClick={() => redirectToLearner()}
              >
                Switch to Learner View
              </MenuItem>
              <MenuItem
                data-testid="logout-menu"
                onClick={() => handleLogout(auth)}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
