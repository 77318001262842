import React from 'react'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'

export const BoxContentQRCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  alignItems: 'center',
  position: 'relative',
}))

export const BoxFooterQRCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

export const BoxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme?.palette?.blue?.azure,
  },
}))
