import callAxios from '../../../utils/baseService'
import { checkStatusReportUpdated } from '../../../utils/apiPath'

export const handleCheckStatusReportUpdated = async (reportName) => {
  return await callAxios
    .post(checkStatusReportUpdated, { reportName })
    .then(({ data }) => {
      return data
    })
}
