import React from 'react'
import { Box, Button, Typography } from '@mui/material'

const CountdownText = ({ number, unit }) => {
  return (
    <>
      <Typography color={'error'}>&nbsp;{number}&nbsp;</Typography>
      {unit}
    </>
  )
}

export const CloseBeforeExpiryDateDialog = ({ time }) => {
  const { days, hrs, mins, secs } = time
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography>ขณะนี้เหลือเวลา </Typography>
        {days > 0 && <CountdownText number={days} unit={'วัน'} />}
        {hrs > 0 && <CountdownText number={hrs} unit={'ชั่วโมง'} />}
        {mins > 0 && <CountdownText number={mins} unit={'นาที'} />}
        <CountdownText number={secs} unit={'วินาที'} />
      </div>
      <Typography> คุณต้องการปิดการใช้งานก่อนกำหนดหรือไม่</Typography>
    </div>
  )
}

export const ContentDialog = (props) => {
  const { icon, title, message, btnText, onClick } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        gap: 1,
      }}
    >
      {icon}
      <Typography variant="h5" color={'text.secondary'}>
        {title}
      </Typography>
      {message && (
        <Typography variant="h6" color={'text.secondary'}>
          {message}
        </Typography>
      )}
      <Button onClick={onClick}>
        <Typography variant="h6" color={'primary'}>
          {btnText}
        </Typography>
      </Button>
    </Box>
  )
}
