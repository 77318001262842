import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChangeEvent } from '../../events'
import StatusSwitch from './StatusSwitch'
import UploadImage from './UploadFile'

const Info = () => {
  const { isUploadLoading, courseCode } = useSelector(
    (state) => ({
      isUploadLoading: state.eLearningCourseForm.isUploadLoading,
      courseCode: getStateValue('courseCode'),
    }),
    shallowEqual,
  )

  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">รายละเอียดหลักสูตร</Typography>
      {!_.isEmpty(courseCode) && (
        <TextInput
          disabled
          isShowTextError={false}
          name="courseCode"
          type="basic"
          id="input-courseCode"
          labelText="รหัสหลักสูตร"
          value={courseCode}
        />
      )}
      <InfoTextInput required keyField="name" label="ชื่อหลักสูตร" />
      <InfoTextInput
        keyField="nameForLearner"
        label="ชื่อหลักสูตร (สำหรับ Learner)"
      />
      <InfoTextInput keyField="subject" label="วิชา" />
      <InfoTextInput keyField="description" label="รายละเอียดเพิ่มเติม" />

      <UploadImage />

      <InfoTextInput
        multiline
        rows={3}
        keyField="linkExampleVideo"
        label="Link สำหรับวีดีโอตัวอย่าง"
        placeholder="กรอก Link Video ตัวอย่าง จาก YouTube หรือ Vimeo"
      />

      <Divider />

      <InfoTextInput
        multiline
        rows={3}
        keyField="remark"
        label="Remark"
        placeholder=" "
      />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default Info

export const InfoTextInput = ({
  keyField,
  label,
  placeholder,
  required,
  multiline,
  rows,
}) => {
  const dispatch = useDispatch()
  const { value, onGoing } = useSelector(
    (state) => ({
      value: getStateValue(keyField),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  return (
    <TextInput
      required={required}
      multiline={multiline}
      rows={rows}
      name={keyField}
      type="basic"
      labelText={label}
      placeholder={placeholder || label}
      id={`input-${keyField}`}
      textError={_.get(errors, keyField, '')}
      isShowTextError={_.get(errors, keyField, '')}
      value={value}
      onChange={(e) => dispatch(handleChangeEvent(e))}
      disabled={onGoing}
    />
  )
}
