import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { StyledColumn, StyledRow } from '../../Styled'

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minWidth: 275,
  display: 'flex',
  padding: `0 ${theme.spacing(3)}`,
  gap: theme.spacing(2),
}))

export const StyledColumnBox = styled(StyledColumn)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { width: 'calc(50% - 8px)' },
}))

export const StyledRowBox = styled(StyledRow)(({ theme }) => ({
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    '& > .MuiTypography-root:first-of-type': {
      width: 'auto',
      minWidth: 50,
    },
  },
}))
