import React, { useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import TextInput from '../../../../../components/Input/TextInput'
import { Row, StyledFormCard } from '../Styled'
import { MODULE_STATUS } from '../../../../../constants/learningPoint'
import { handleChange, handleChangeEvent } from '../events'
import UploadFile from '../../../../../components/UploadFile'

const BadgeInfo = () => {
  const dispatch = useDispatch()
  const {
    isUploadLoading,
    badgeStatus,
    badgeName,
    badgeDescription,
    badgeRemark,
    settingPointImages,
  } = useSelector(
    (state) => ({
      isUploadLoading: state.settingPoint.isUploadLoading,
      badgeStatus: state.settingPoint.body.badgeStatus,
      badgeName: state.settingPoint.body.badgeName,
      badgeDescription: state.settingPoint.body.badgeDescription,
      badgeRemark: state.settingPoint.body.badgeRemark,
      settingPointImages: state.uploadFile.formData.settingPointImages,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  useEffect(() => {
    if (!settingPointImages?.isUploading && settingPointImages) {
      let badgeImage = {}
      if (settingPointImages?.files?.length > 0) {
        const { displayName, fileSize, key } = settingPointImages.files[0]
        badgeImage = {
          key: key,
          fileName: displayName,
          fileSize: fileSize,
        }
      }
      dispatch(handleChange('badgeImage', badgeImage))
    }
  }, [settingPointImages])
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">ข้อมูลเหรียญรางวัล</Typography>
      <Row>
        <Switch
          name="badgeStatus"
          checked={badgeStatus === MODULE_STATUS.ACTIVE}
          onChange={(e) =>
            dispatch(
              handleChange(
                'badgeStatus',
                e.target.checked
                  ? MODULE_STATUS.ACTIVE
                  : MODULE_STATUS.INACTIVE,
              ),
            )
          }
        />
        <Typography variant="body1">เปิดการแสดงผลที่หน้า Learner</Typography>
      </Row>
      <TextInput
        required={badgeStatus === MODULE_STATUS.ACTIVE}
        name="badgeName"
        type="basic"
        id="input-badgeName"
        placeholder="ชื่อเหรียญรางวัล"
        labelText="ชื่อเหรียญรางวัล"
        textError={_.get(errors, 'badgeName', '')}
        isShowTextError={_.get(errors, 'badgeName', '')}
        value={badgeName}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        multiline
        rows={3}
        name="badgeDescription"
        type="basic"
        id="input-badgeDescription"
        placeholder="รายละเอียดข้อมูลเหรียญรางวัล"
        labelText="คำอธิบาย"
        textError={_.get(errors, 'badgeDescription', '')}
        isShowTextError={_.get(errors, 'badgeDescription', '')}
        value={badgeDescription}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" color="text.secondary">
            อัปโหลดรูปเหรียญรางวัล
          </Typography>
          {badgeStatus === MODULE_STATUS.ACTIVE && (
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          )}
        </Box>
        <UploadFile
          sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
          isForImage
          folder="setting-point"
          maxFileSize={{ size: 2, sizeType: 'MB' }}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
          }}
          acceptLabel=".jpg, .jpeg, .png"
          fieldName="settingPointImages"
          maxLength={1}
        />
        {_.get(errors, 'badgeImage.key', '') && (
          <Typography
            sx={{ height: 25 }}
            variant="body2"
            color="error"
            id="input-badgeImage"
          >
            {_.get(errors, 'badgeImage.key', '')}
          </Typography>
        )}
      </Box>
      <TextInput
        multiline
        rows={3}
        name="badgeRemark"
        type="basic"
        id="input-badgeRemark"
        placeholder="รายละเอียดเพิ่มเติม"
        labelText="หมายเหตุ"
        textError={_.get(errors, 'badgeRemark', '')}
        isShowTextError={_.get(errors, 'badgeRemark', '')}
        value={badgeRemark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default BadgeInfo
