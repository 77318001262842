export const defaultFilter = {
  nameChecked: false,
  name: '',
  vendorTypeChecked: false,
  vendorType: {},
  stationChecked: false,
  station: {},
  dateChecked: false,
  date: '',
  renewDateChecked: false,
  insideContactChecked: false,
  outsideContactChecked: false,
  createdAtChecked: false,
  updatedAtChecked: false,
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
