import { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { Row } from '../../Layout/Styled'
import { tabList } from '../../../constants/report/AuditLog'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { StyledAuditLogTab } from '../Styled'

const AuditLogTab = () => {
  const dispatch = useDispatch()
  const { tab } = useSelector(
    (state) => ({
      tab: state.reportComp.tab,
    }),
    shallowEqual,
  )
  useEffect(() => {
    dispatch(
      setReduxValue({
        key: 'tab',
        value: tabList.BACKEND,
      }),
    )
  }, [])
  return (
    <Row pl={3}>
      <StyledAuditLogTab
        value={tab || tabList.BACKEND}
        indicatorColor="primary"
        onChange={(_, value) =>
          dispatch(
            setReduxValue({
              key: 'tab',
              value: value,
            }),
          )
        }
      >
        <Tab
          value={tabList.BACKEND}
          label={
            <Typography variant="body1" color="primary.main">
              Backend
            </Typography>
          }
        />
        <Tab
          value={tabList.LEARNER}
          label={
            <Typography variant="body1" color="primary.main">
              Learner
            </Typography>
          }
        />
      </StyledAuditLogTab>
    </Row>
  )
}

export default AuditLogTab
