import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../../../redux/slices/eTestingForm'
import { blankComp } from '..'

export const handleDeleteForm = (props) => (dispatch) => {
  const { isTrick, question, index, keyQuestion } = props
  const subTitle = _.get(question, 'subTitle', '')
  const answers = _.get(question, 'answers', [])

  if (isTrick) {
    const newTrickAns = [...answers]
    newTrickAns.splice(index, 1)
    dispatch(
      setChangeQuestionByKey({
        keyQuestion,
        key: 'answers',
        value: newTrickAns,
      })
    )
  } else {
    dispatch(
      setChangeQuestionByKey({
        keyQuestion,
        key: 'subTitle',
        value: subTitle.replace(blankComp(index), ''),
      })
    )
  }
}
