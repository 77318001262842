import TimePicker from '../../../../../../components/TimePicker/TimePicker'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  bookingItemSelector,
  errorSelector,
  setTimeByDate,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import { PERIOD_TYPE } from '../../../../../../constants/roomManagement'
import _ from 'lodash'

export const CustomTimePicker = ({ bookingDate, field }) => {
  const value = useSelector(
    bookingItemSelector({ bookingDate, key: field }),
    shallowEqual,
  )
  const period = useSelector(
    bookingItemSelector({ bookingDate, key: 'period' }),
    shallowEqual,
  )
  const error = useSelector(errorSelector(field + bookingDate), shallowEqual)
  const { requestType } = useSelector((state) => ({
    requestType: state.roomManagementDrawer.bookingInformation.requestType,
  }))
  const dispatch = useDispatch()
  return (
    <TimePicker
      id={field}
      name={field}
      value={value}
      disabled={period !== PERIOD_TYPE.OTHER || requestType === 'MONTHLY_PLAN'}
      disabledStartTime={''}
      disabledEndTime={''}
      placeholder={'กรุณาเลือกเวลา'}
      onChange={(e) => {
        const value = _.get(e, 'target.value', '')
        dispatch(
          setTimeByDate({
            key: field,
            value: value,
            bookingDate: bookingDate,
          }),
        )
      }}
      helperText={_.get(error, `message[0]`, '')}
    />
  )
}
