import * as yup from 'yup'
import _ from 'lodash'

export const standAloneSchema = yup.object({
  questions: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .test(
          'check-questions-length',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            return context.parent.title.length <= 3000
          }
        )
        .test(
          'check-title-required',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            }`
          },
          (value) => {
            return value !== ''
          }
        ),
      questions: yup
        .array()
        .test('check-question', 'กรุณาเพิ่มคำถาม', (value) => {
          if (!value) return true
          return value.length > 0
        })
        .test(
          'check-questions-questions',
          (value) => {
            const index = value.value.findIndex((item) => item.title === '')
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } คำถามย่อยที่ ${+index + 1}`
          },
          (value, context) => {
            if (!context.parent.questions) return true
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            const findQuestions = context.parent.questions.find(
              (item) => item.title === '' && item.answerType !== 'OTHER'
            )

            return !findQuestions
          }
        )
        .test(
          'check-questions-length',
          (value) => {
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } คำถามข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'questions', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
      choices: yup
        .array()
        .test(
          'check-questions-choices',
          (value) => {
            const index = value.value.findIndex((item) => item.title === '')
            const listIndex = splitPath(value)

            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${index + 1}`
          },
          (value, context) => {
            if (!context.parent.choices) return true
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            const findChoices = context.parent.choices.find(
              (item) => item.title === '' && item.answerType !== 'OTHER'
            )
            return !findChoices
          }
        )
        .test(
          'check-choices-length',
          (value) => {
            const listIndex = splitPath(value)
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'choices', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
      answers: yup
        .array()
        .test(
          'check-answer',
          (value) => {
            const listIndex = splitPath(value)
            const answerIndex = value.originalValue.findIndex(
              (item) => item.title === ''
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${answerIndex + 1}  `
          },
          (value, context) => {
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            if (questionType === 'RATING') {
              return true
            } else {
              const findAnswers = context.parent.answers.find(
                (item) => item.title === '' && item.answerType !== 'OTHER'
              )

              return !findAnswers
            }
          }
        )
        .test(
          'check-answers-length',
          (value) => {
            const listIndex = splitPath(value)
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'answers', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
    })
  ),
  setting: yup.object().shape({
    startDate: yup
      .string('กรุณาระบุวันเริ่มต้นใช้งาน')
      .nullable()
      .required('กรุณาระบุวันเริ่มต้นใช้งาน'),
  }),
})

export const classSchema = yup.object({
  courseAssessments: yup
    .array()
    .test(
      'check-course-assessments',
      'กรุณากรอกคำถามแบบประเมินอย่างน้อย 1 ข้อ',
      (value, context) => {
        const {
          courseAssessments,
          courseManagementAssessments,
          questions,
          lecturerAssessments,
        } = context.parent

        const hasNoQuestions =
          courseAssessments.length === 0 &&
          courseManagementAssessments.length === 0 &&
          questions.length === 0 &&
          lecturerAssessments.length === 0
        return !hasNoQuestions
      }
    )
    .test(
      'check-course-assessments',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title === ''
        )
        return `กรุณากรอก แบบประเมินเนื้อหาหลักสูตร คำถามข้อที่ ${+index + 1} `
      },
      (value, context) => {
        if (context.parent.courseAssessments.length === 0) return true
        const findCourseAssessments =
          context.parent.courseAssessments[0].questions.find(
            (item) => item.title === ''
          )
        return !findCourseAssessments
      }
    )
    .test(
      'check-course-assessments-length',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title.length > 3000
        )
        return `กรุณากรอก แบบประเมินเนื้อหาหลักสูตร คำถามข้อที่ ${
          +index + 1
        } กรอกได้ไม่เกิน 3000 ตัวอักษร`
      },
      (value, context) => {
        if (context.parent.courseAssessments.length === 0) return true
        const findCourseAssessments =
          context.parent.courseAssessments[0].questions.find(
            (item) => item.title.length > 3000
          )
        return !findCourseAssessments
      }
    ),
  courseManagementAssessments: yup
    .array()
    .test(
      'check-course-management-assessments',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title === ''
        )
        return `กรุณากรอก แบบประเมินการบริหารจัดการหลักสูตร คำถามข้อที่ ${
          +index + 1
        } `
      },
      (value, context) => {
        if (context.parent.courseManagementAssessments.length === 0) return true
        const findCourseManagementAssessments =
          context.parent.courseManagementAssessments[0].questions.find(
            (item) => item.title === ''
          )
        return !findCourseManagementAssessments
      }
    )
    .test(
      'check-course-management-assessments-length',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title.length > 3000
        )
        return `กรุณากรอก แบบประเมินบริหารจัดการหลักสูตร คำถามข้อที่ ${
          +index + 1
        } กรอกได้ไม่เกิน 3000 ตัวอักษร`
      },
      (value, context) => {
        if (context.parent.courseManagementAssessments.length === 0) return true
        const findCourseManagementAssessments =
          context.parent.courseManagementAssessments[0].questions.find(
            (item) => item.title.length > 3000
          )
        return !findCourseManagementAssessments
      }
    ),
  lecturerAssessments: yup
    .array()
    .test(
      'check-lecturer-course-assessments',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title === ''
        )
        return `กรุณากรอก แบบประเมินวิทยากร คำถามข้อที่ ${+index + 1} `
      },
      (value, context) => {
        if (context.parent.lecturerAssessments.length === 0) return true
        const findLecturerAssessments =
          context.parent.lecturerAssessments[0].questions.find(
            (item) => item.title === ''
          )
        return !findLecturerAssessments
      }
    )
    .test(
      'check-lecturer-course-assessments-length',
      (value) => {
        const index = value.value[0].questions.findIndex(
          (item) => item.title.length > 3000
        )
        return `กรุณากรอก แบบประเมินวิทยากรไม่เกิน คำถามข้อที่ ${
          +index + 1
        } 3000 ตัวอักษร `
      },
      (value, context) => {
        if (context.parent.lecturerAssessments.length === 0) return true
        const findLecturerAssessments =
          context.parent.lecturerAssessments[0].questions.find(
            (item) => item.title.length > 3000
          )
        return !findLecturerAssessments
      }
    ),
  subQuestions: yup.array().of(
    yup.object().shape({
      value: yup
        .array()
        .test(
          'check-sub-question',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอกคำถามย่อยให้ครบ จากคำถามย่อยข้อที่ ${
              +listIndex[1] + 1
            } `
          },
          (value, context) => {
            const isCheck = context.parent.value.map((item) => {
              const findQuestion =
                item.title === '' && item.answersType !== 'OTHER'
              return !findQuestion
            })
            return isCheck[0]
          }
        )
        .test(
          'check-sub-answer',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอก ตัวเลือกย่อยให้ครบ จากคำถามย่อยข้อที่ ${
              +listIndex[1] + 1
            } `
          },
          (value, context) => {
            const isCheck = context.parent.value.map((item) => {
              const questionType = _.get(item, 'questionType', 'SINGLE')
              if (questionType === 'FREE_TEXT') return true
              if (questionType === 'RATING') {
                return true
              } else {
                const findAnswers = item.answers.find(
                  (item) => item.title === '' && item.answerType !== 'OTHER'
                )

                return !findAnswers
              }
            })
            return isCheck[0]
          }
        ),
    })
  ),
  questions: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .test(
          'check-questions-length',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            return context.parent.title.length <= 3000
          }
        )
        .test(
          'check-title-required',
          (value) => {
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            }`
          },
          (value) => {
            return value !== ''
          }
        ),
      questions: yup
        .array()
        .test('check-question', 'กรุณาเพิ่มคำถาม', (value) => {
          if (!value) return true
          return value.length > 0
        })
        .test(
          'check-questions-questions',
          (value) => {
            const index = value.value.findIndex((item) => item.title === '')
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } คำถามย่อยที่ ${+index + 1}`
          },
          (value, context) => {
            if (!context.parent.questions) return true
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            const findQuestions = context.parent.questions.find(
              (item) => item.title === '' && item.answerType !== 'OTHER'
            )

            return !findQuestions
          }
        )
        .test(
          'check-questions-length',
          (value) => {
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            const listIndex = splitPath(value)
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } คำถามข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'questions', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
      choices: yup
        .array()
        .test(
          'check-questions-choices',
          (value) => {
            const index = value.value.findIndex((item) => item.title === '')
            const listIndex = splitPath(value)

            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${index + 1}`
          },
          (value, context) => {
            if (!context.parent.choices) return true
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            const findChoices = context.parent.choices.find(
              (item) => item.title === '' && item.answerType !== 'OTHER'
            )
            return !findChoices
          }
        )
        .test(
          'check-choices-length',
          (value) => {
            const listIndex = splitPath(value)
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'choices', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
      answers: yup
        .array()
        .test(
          'check-answer',
          (value) => {
            const listIndex = splitPath(value)
            const answerIndex = value.originalValue.findIndex(
              (item) => item.title === ''
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${answerIndex + 1}  `
          },
          (value, context) => {
            const questionType = _.get(context, 'parent.questionType', 'SINGLE')
            if (questionType === 'FREE_TEXT') return true
            if (questionType === 'RATING') {
              return true
            } else {
              const findAnswers = context.parent.answers.find(
                (item) => item.title === '' && item.answerType !== 'OTHER'
              )

              return !findAnswers
            }
          }
        )
        .test(
          'check-answers-length',
          (value) => {
            const listIndex = splitPath(value)
            const index = value.value.findIndex(
              (item) => item.title.length > 3000
            )
            return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
              +listIndex[1] + 1
            } ตัวเลือกข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
          },
          (value, context) => {
            const body = { context, field: 'answers', length: 3000 }
            return validateQuestionSchemaLength(body)
          }
        ),
    })
  ),
  setting: yup.object().shape({
    startDate: yup
      .string('กรุณาระบุวันเริ่มต้นใช้งาน')
      .nullable()
      .required('กรุณาระบุวันเริ่มต้นใช้งาน'),
  }),
})

export const draftSchema = yup.object({
  questions: yup.array().of(
    yup.object().shape({
      title: yup.string().test(
        'check-questions-length',
        (value) => {
          const listIndex = splitPath(value)
          return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่ ${
            +listIndex[1] + 1
          } กรอกได้ไม่เกิน 3000 ตัวอักษร`
        },
        (value, context) => {
          return context.parent.title.length <= 3000
        }
      ),
      answers: yup.array().test(
        'check-answers-length',
        (value) => {
          const listIndex = splitPath(value)
          const index = value.value.findIndex(
            (item) => item.title.length > 3000
          )
          return `กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่ ${
            +listIndex[1] + 1
          } ตัวเลือกข้อที่ ${+index + 1} กรอกได้ไม่เกิน 3000 ตัวอักษร`
        },
        (value, context) => {
          const body = { context, field: 'answers', length: 3000 }
          return validateQuestionSchemaLength(body)
        }
      ),
    })
  ),
  setting: yup.object().shape({
    startDate: yup
      .string('กรุณาระบุวันเริ่มต้นใช้งาน')
      .nullable()
      .required('กรุณาระบุวันเริ่มต้นใช้งาน'),
  }),
})

export const splitPath = (value) => {
  const path = _.get(value, 'path', '')
  const splitValue = path ? path.split(/(\d+)/) : []
  return splitValue
}

export const validateQuestionType = (context) => {
  const getQuestionType = _.get(context, 'parent.questionType', '')
  if (getQuestionType === '') return true
  const isCheck =
    context.parent.questionType === 'FREE_TEXT' ||
    context.parent.questionType === 'RATING'
  if (isCheck) return true
  return false
}

export const validateQuestionSchemaLength = ({ context, field, length }) => {
  const fieldValue = _.get(context, `parent.${field}`, [])
  if (fieldValue.length === 0) return true
  const findQuestions = fieldValue.find((item) => item.title.length > length)
  return !findQuestions
}
