import { read } from 'xlsx'
import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
  stopLoadingDialog,
  closeDialog,
} from '../../../redux/slices/dialog'
import {
  setAgentType,
  setImportFile,
  setProcess,
  setProcessAgent,
  setUploadForm,
  startUploadLoading,
  stopUploadLoading,
  resetUploadForm,
  setDownloading,
} from '../../../redux/slices/manage/agentProfile/'
import { postAgentCheckPoint } from '../../../services/manage/agentProfile/agentProfileServices'
import { postAgentPoint } from '../../../services/manage/agentProfile/agentProfileServices'
import CheckDialog from './Check'
import { downloadFile } from '../../../services/util'
import { agentPointTemplate } from '../../../utils/apiPath'
import { convertNumberWithComma } from '../../../utils/lib'

export const handleAgentTypeChange = (e) => (dispatch) => {
  dispatch(setAgentType(e.target.value))
}

export const handleImportFile = (data) => (dispatch) => {
  dispatch(startUploadLoading())
  dispatch(setImportFile(data))

  setTimeout(() => {
    dispatch(stopUploadLoading())
  }, 500)
}

export const handleEnableButton =
  ({ agentType, file }) =>
  (dispatch) => {
    if (agentType !== '' && file) {
      dispatch(setEnableButton())
    } else {
      dispatch(setDisableButton())
    }
  }

export const handleSuccess = (isSuccess) => (dispatch) => {
  if (isSuccess)
    dispatch(
      openDialog({
        isCloseDialog: false,
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => window.location.reload(),
      }),
    )
}

export const handleSubmitAdd =
  ({ agentType, file }) =>
  async (dispatch) => {
    dispatch(loadingDialog())
    dispatch(handleAgent({ agentType, file }))
  }

export const handleAgent =
  ({ agentType, file }) =>
  async (dispatch) => {
    const data = await file?.arrayBuffer()
    const workbook = read(data)
    const sheetAgent = _.get(workbook.Sheets, 'Agent-List', undefined)
    const agentPreview = []
    let total = 0

    for (let i = 1; i <= 50; i++) {
      const agent_code = _.get(sheetAgent, `A${i + 1}.v`, null)
      if (!_.isEmpty(agent_code) || Number.isInteger(agent_code)) {
        total++
      }
    }

    dispatch(setProcessAgent({ processAgent: true, total: total }))
    let index = 1
    for (let i = 1; i <= 50; i++) {
      const agent_code = _.get(sheetAgent, `A${i + 1}.v`, '')
      const event = _.get(sheetAgent, `B${i + 1}.v`, '')
      const point = _.get(sheetAgent, `C${i + 1}.v`, '')
      const prevRow = _.filter(
        agentPreview,
        (item) => item.code == agent_code && item.status == 'SUCCESS',
      )
      let prevRowPoint = 0
      prevRow.map((row) => {
        if (row.event == 'เพิ่มคะแนน') prevRowPoint += +row.point
        else prevRowPoint -= +row.point
      })
      if (!_.isEmpty(agent_code) || Number.isInteger(agent_code)) {
        const body = {
          agentCode: [agent_code],
          event: String(event),
          point: String(point),
          agentType: agentType,
          prevRowPoint: prevRowPoint,
        }
        const responseAgent = await dispatch(postAgentCheckPoint(body))

        const result = _.get(responseAgent, 'payload.data.results[0]', {})
        agentPreview.push({ ...result, index: index })
        index++
        dispatch(setProcess(i))
      }
    }

    const STATUS = _.isEmpty(
      _.filter(agentPreview, (item) => item.status !== 'SUCCESS'),
    )
    dispatch(
      setUploadForm({
        status: STATUS ? 'SUCCESS' : 'ERROR',
        result: _.orderBy(agentPreview, ['index'], ['asc']),
        agentType: agentType,
      }),
    )

    dispatch(setProcessAgent({ processAgent: false, total: total }))

    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'md',
        disableButton: true,
        content: <CheckDialog />,
      }),
    )

    dispatch(stopLoadingDialog())
  }

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const handlePoint = (point, event, status) => {
  if (!String(point)) return '-'
  if (status == 'POINT_NOT_VALID') return point
  if (event == 'เพิ่มคะแนน') return `+ ${convertNumberWithComma(point)}`
  if (event == 'ลดคะแนน') return `- ${convertNumberWithComma(point)}`
  return point
}

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetUploadForm())
  dispatch(closeDialog())
}

export const handleEnableSubmitPointButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleSubmitPoint = (uploadResult) => async (dispatch) => {
  dispatch(loadingDialog())
  const results = _.get(uploadResult, 'result', [])
  const agentProfile = results.filter((item) => item.status === 'SUCCESS')

  await dispatch(postAgentPoint({ agentProfile }))
  dispatch(stopLoadingDialog())
}

export const handleDownloadTemplate = () => async (dispatch) => {
  dispatch(setDownloading(true))
  await dispatch(
    downloadFile({
      url: agentPointTemplate,
      body: {},
      fileName: `Agent_Point_Template.xlsx`,
    }),
  )
  dispatch(setDownloading(false))
}
