import { CContainer } from '../../../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../../../components/CRUD/handler/handleChange'
import { initialConfig } from '../../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { resetTable } from '../../../../../../redux/slices/table'
import { fetchDataList } from '../../../Listing/handler/fetchDataList'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { handleFiltering } from '../../../Listing/handler/handleFiltering'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { fetchOptionsDashboardFilter } from '../../handler/fetchOptionsDashboardFilter'
import { useDispatch } from 'react-redux'
import { handlePersistFilter } from '../../handler/handlePersistFilter'

export const TesterListTab = () => {
  const dispatch = useDispatch()
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
    }),
    shallowEqual,
  )
  const { crudConfig, EtestDashboardTab, filterStateBelt } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      EtestDashboardTab: state.crud.EtestDashboardTab,
      filterStateBelt: state.crud.filterStateBelt,
    }),
    shallowEqual,
  )
  const { uuid } = useParams()

  useEffect(() => {
    dispatch(resetTable())
    initialConfig(initialCrudConfig)
    fetchOptionsDashboardFilter(filterItems, uuid)
    handlePersistFilter(defaultFilter, filterStateBelt)
  }, [])

  useEffect(async () => {
    if (!_.isUndefined(crudConfig) && EtestDashboardTab === 'testerList') {
      dispatch(setFieldValue({ key: 'isLoading', value: true }))
      await fetchDataList(
        mutateFilterPropToBody,
        {
          codeId: uuid,
          isDashboardTesterListTab: true,
          quickSearchPlaceHolder: 'ค้นหาชื่อผู้ทำแบบทดสอบ',
          defaultFilter: defaultFilter,
        },
        undefined,
        'รายชื่อผู้ทำข้อสอบ',
      ).then()
      dispatch(setFieldValue({ key: 'isLoading', value: false }))
    }
  }, [limit, order, page, sort, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  console.log('render TesterListTab')

  return (
    <CContainer
      contentSx={{ mx: 0 }}
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleFiltering(
              mutateFilterSateToProp,
              mutateFilterPropToBody,
              {
                codeId: uuid,
                isDashboardTesterListTab: true,
                quickSearchPlaceHolder: 'ค้นหาชื่อผู้ทำแบบทดสอบ',
                defaultFilter: defaultFilter,
              },
              undefined,
              'รายชื่อผู้ทำข้อสอบ',
            )
          }
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
    />
  )
}
