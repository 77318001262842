import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import { handleChange } from '../../event'
import { ROOM_FUNCTION } from '../../../../../constants/MasterData/meetingRoom'

const Index = () => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const { roomFunction, roomFunctionOther } = useSelector(
    (state) => state.masterDataMeetingRoomForm.information,
    shallowEqual,
  )
  const isOther = roomFunction === 'OTHER'
  const roomFunctionOptions = [
    {
      value: 'SINGLE_ROOM',
      label: ROOM_FUNCTION.SINGLE_ROOM,
    },
    {
      value: 'CONNECTING_ROOM',
      label: ROOM_FUNCTION.CONNECTING_ROOM,
    },
    {
      value: 'OTHER',
      label: ROOM_FUNCTION.OTHER,
    },
  ]

  return (
    <>
      <Select
        boxSx={{ mt: 3 }}
        dataTestid="select-station"
        id="roomFunction"
        name="roomFunction"
        labelText="ฟังก์ชันห้อง"
        required
        placeholder="กรุณาเลือก"
        options={roomFunctionOptions}
        // showTextError={false}
        value={roomFunction}
        textError={_.get(errors, `information.roomFunction`, '')}
        handleChange={(e) =>
          dispatch(
            handleChange({
              key: 'roomFunction',
              value: _.get(e, 'target.value', ''),
            }),
          )
        }
      />
      {isOther && (
        <TextInput
          required
          type="basic"
          name="roomFunctionOther"
          labelText={'ระบุฟังก์ชันห้อง (Other)'}
          placeholder="กรุณากรอก"
          value={roomFunctionOther}
          onChange={(e) => {
            dispatch(
              handleChange({
                key: 'roomFunctionOther',
                value: _.get(e, 'target.value', ''),
              }),
            )
          }}
          textError={_.get(errors, `information.roomFunctionOther`, '')}
        />
      )}
    </>
  )
}

export default Index
