import React, { useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isMediaWidth, isMediaWidthMoreThan } from '../../../../../../utils/lib'
import ElearningCourseCard from '../../../../../../components/Preview/ElearningCourseCard'
import { handleSelector } from '../../../Form/events'
import { SECTION_KEY, SECTION_OBJ } from '../../../model'
import { fetchOptionCourse } from '../../events'
import { handleFindItem } from './events'
import {
  StyledContainer,
  StyledGrid,
  StyledMediaBox,
  StyledRowContainer,
  StyledWrapper,
} from './Styled'

const TabContainer = ({ keySection }) => {
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('lg')
  const { keyInfo, keySub, keyDistribution, keyValue } = SECTION_OBJ[keySection]
  const isPath = keySection === SECTION_KEY.LEARNING_PATH
  const isCollection = keySection === SECTION_KEY.COLLECTION
  const levelDistribution = localStorage.getItem(keyDistribution)
  const { tabs, allCourses } = useSelector((state) => {
    const information = handleSelector(state, keyInfo)
    const data = information.find((item) => item.uuid == levelDistribution)
    return {
      tabs: _.get(data, keySub, []),
      allCourses: state.manageContentELearningHome.options[keySub],
    }
  }, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchOptionCourse())
  }, [])

  return (
    <StyledContainer>
      {tabs.map((tab, key) => {
        if (!tab.status) return <></>
        const title = _.get(tab, 'tabTitle', '')
        const image = _.get(tab, 'image', '')
        const url = `${window.__env__.REACT_APP_API_URL}/file${image}`
        const backgroundImage = tab.imageStatus ? `url(${url})` : 'unset'
        const color =
          _.get(tab, 'whiteTitle', false) === 'true'
            ? 'text.white'
            : 'text.primary'
        const items = _.get(tab, keySub, [])
        return (
          <StyledMediaBox key={key} sx={{ backgroundImage }}>
            <StyledWrapper>
              <StyledRowContainer>
                <Typography variant={isLaptop ? 'h4' : 'h5'} color={color}>
                  {title}
                </Typography>
                <Button
                  endIcon={
                    <ArrowForwardIosIcon
                      color="primary"
                      sx={{ fontSize: '20px' }}
                    />
                  }
                >
                  ดูทั้งหมด
                </Button>
              </StyledRowContainer>
              {isLaptop ? (
                <StyledGrid container>
                  {items.map((data, index) => {
                    if (!data.status) return <></>

                    const displayCourse = handleFindItem(
                      _.get(data, keyValue, {}),
                      allCourses,
                    )
                    return (
                      <Grid item lg={3} key={index}>
                        <ElearningCourseCard
                          course={displayCourse}
                          isPath={isPath}
                          isCollection={isCollection}
                        />
                      </Grid>
                    )
                  })}
                </StyledGrid>
              ) : (
                <Carousel
                  showArrows={false}
                  showStatus={false}
                  showIndicators={true}
                  centerMode={true}
                  showThumbs={false}
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={20}
                  centerSlidePercentage={isMobile ? 90 : 45}
                  className="carousel-root-custom"
                >
                  {items.map((data, index) => {
                    if (!data.status) return <></>

                    const displayCourse = handleFindItem(
                      _.get(data, keyValue, {}),
                      allCourses,
                    )
                    return (
                      <Box key={index} sx={{ maxWidth: 296, height: '411px' }}>
                        <ElearningCourseCard
                          course={displayCourse}
                          isPath={isPath}
                          isCollection={isCollection}
                        />
                      </Box>
                    )
                  })}
                </Carousel>
              )}
            </StyledWrapper>
          </StyledMediaBox>
        )
      })}
    </StyledContainer>
  )
}

export default TabContainer
