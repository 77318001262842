import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { LessonList } from '..'
import { TAB_VALUE } from './model'
import { StyledTabWrapper } from './Styled'

const MobileTabDisplay = () => {
  const [tab, setTab] = useState(TAB_VALUE.LESSON)
  return (
    <>
      <StyledTabWrapper>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(e, tab) => setTab(tab)}
        >
          <Tab label="เนื้อหาหลักสูตร" value={TAB_VALUE.LESSON} />
        </Tabs>
      </StyledTabWrapper>
      {tab === TAB_VALUE.LESSON && <LessonList />}
    </>
  )
}

export default MobileTabDisplay
