import React from 'react'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import { BoxAddChoice } from '../../Styled'

const AddBlank = ({ sx, keyQuestion, onClick, isTrick }) => {
  const { question, disableForm } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )
  const { answers } = question
  const textColor = disableForm ? 'text.gray' : 'primary'

  return (
    <BoxAddChoice
      sx={{ width: 120, ml: 10.5, ...sx }}
      lastMediaUrl={
        _.get(answers, `[${answers.length - 1}].loadingProgress`, 0)
          ? true
          : false
      }
      data-testid="add-blank"
      onClick={onClick}
    >
      <Add color={textColor} />
      <Typography variant="buttons" color={textColor}>
        เพิ่มตัวเลือก{isTrick && 'หลอก'}
      </Typography>
    </BoxAddChoice>
  )
}
export default AddBlank
