import React, { useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import Tooltip from '@mui/material/Tooltip'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ViewImage from '../../../../../components/Upload/BasicUpload'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowForwardIosTwoTone from '@mui/icons-material/ArrowForwardIosTwoTone'
import InsertChartTwoTone from '@mui/icons-material/InsertChartTwoTone'

import TruncateMarkup from 'react-truncate-markup'
import { StyledTruncateBox } from './Styled'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

import { StyledIconHistory } from './Styled'

const TimelineHistory = ({ historyList }) => {
  const [expand, setExpand] = useState(false)
  const [isTruncated, setTruncate] = useState(false)

  if (!_.isEmpty(historyList)) {
    return historyList.map((item) => {
      return (
        <>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: -1,
              },
              mt: -1,
            }}
          >
            <TimelineContent>
              <Typography variant="h6">{item[0]}</Typography>
            </TimelineContent>
            {item[1].map((data) => (
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <Box sx={{ mt: 1 }}>
                      {data.event === 'SPEAKER_UPDATED' ? (
                        <img src="/icon/bi_update.svg" />
                      ) : (
                        <img src="/icon/bi_cancel.svg" />
                      )}
                    </Box>

                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography variant="body1b">
                        {data.event === 'SPEAKER_UPDATED'
                          ? 'เปลี่ยนแปลงวิทยากร'
                          : 'ยกเลิกวิทยากร'}{' '}
                      </Typography>
                      <Typography variant="body1">{data.time}</Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        border: '1px solid var(--blue-blue-border, #DBE4F1)',
                        background: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: blur('2px'),
                        mb: 1,
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 0,
                          mt: 2,
                          ml: '24px',
                        }}
                      >
                        <Typography>
                          {!_.isNil(data.beforeStaff.image) ? (
                            <ViewImage
                              value={data.beforeStaff.image}
                              isShowDescription={false}
                              isPreview={true}
                              isShowTextError={false}
                              isStopPropagation={true}
                              style={{
                                width: '36px',
                                height: '36px',
                                marginRight: '16px',
                                borderRadius: '50%',
                              }}
                            />
                          ) : (
                            <AccountCircleRoundedIcon
                              sx={{ width: 36, height: 36 }}
                            />
                          )}
                        </Typography>

                        {data.event === 'SPEAKER_CANCEL' ? (
                          <Box sx={{ width: 'calc(100% - 92px)' }}>
                            <Typography variant="body1b" sx={{ pl: '8px' }}>
                              {`${_.get(
                                data,
                                'beforeStaff.firstNameTH',
                                '',
                              )} ${_.get(data, 'beforeStaff.lastNameTH', '')}`}
                            </Typography>
                            <Box sx={{ display: 'flex', with: 378 }}>
                              <Typography
                                variant="body1"
                                sx={{ pl: 1, width: 154, mr: '24px' }}
                              >
                                {`${_.get(data, 'responsibility', '-')}`}
                              </Typography>
                              <Typography variant="body1" sx={{ width: 200 }}>
                                {_.get(data, 'workTime', '')}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Typography
                            variant="body1b"
                            sx={{
                              maxWidth: '133px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              mr: '22px',
                            }}
                          >
                            <Tooltip
                              title={
                                <Typography variant="tooltip">
                                  {`${_.get(
                                    data,
                                    'beforeStaff.firstNameTH',
                                    '',
                                  )} ${_.get(
                                    data,
                                    'beforeStaff.lastNameTH',
                                    '',
                                  )}`}
                                </Typography>
                              }
                              arrow
                              placement="top"
                            >
                              <Typography>
                                {`${_.get(
                                  data,
                                  'beforeStaff.firstNameTH',
                                  '',
                                )} ${_.get(
                                  data,
                                  'beforeStaff.lastNameTH',
                                  '',
                                )}`}
                              </Typography>
                            </Tooltip>
                          </Typography>
                        )}

                        {data.event === 'SPEAKER_UPDATED' && (
                          <>
                            {' '}
                            <Typography variant="body1b" sx={{ mr: '22px' }}>
                              <ArrowForwardIosTwoTone />
                            </Typography>
                            <Typography>
                              {!_.isNil(data.afterStaff.image) ? (
                                <ViewImage
                                  value={data.afterStaff.image}
                                  isShowDescription={false}
                                  isPreview={true}
                                  isShowTextError={false}
                                  isStopPropagation={true}
                                  style={{
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '8px',
                                    borderRadius: '50%',
                                  }}
                                />
                              ) : (
                                <AccountCircleRoundedIcon
                                  sx={{ width: 32, height: 32 }}
                                />
                              )}
                            </Typography>
                            <Typography
                              variant="body1b"
                              sx={{
                                maxWidth: '133px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              <Tooltip
                                title={
                                  <Typography variant="tooltip">
                                    {`${_.get(
                                      data,
                                      'afterStaff.firstNameTH',
                                      '',
                                    )} ${_.get(
                                      data,
                                      'afterStaff.lastNameTH',
                                      '',
                                    )}`}
                                  </Typography>
                                }
                                arrow
                                placement="top"
                              >
                                <Typography>
                                  {`${_.get(
                                    data,
                                    'afterStaff.firstNameTH',
                                    '',
                                  )} ${_.get(
                                    data,
                                    'afterStaff.lastNameTH',
                                    '',
                                  )}`}
                                </Typography>
                              </Tooltip>
                            </Typography>
                          </>
                        )}
                      </Box>
                      {data.isChangeStatistic && (
                        <Box sx={{ display: 'flex', gap: 1, m: 2 }}>
                          <InsertChartTwoTone />
                          {data.event === 'SPEAKER_UPDATED' ? (
                            <Typography>
                              {' '}
                              มีการโอนย้ายคะแนนประเมินวิทยากรรายบุคคล
                            </Typography>
                          ) : (
                            <Typography>
                              {' '}
                              มีการยกเลิกผลประเมินวิทยากรรายบุคคล
                            </Typography>
                          )}
                        </Box>
                      )}
                      <Box sx={{ m: '24px' }}>
                        <Typography variant="body1b">เหตุผล</Typography>
                        <Typography
                          sx={{ fontSize: '20px', color: 'secondary' }}
                        >
                          {!expand && (
                            <TextWithTruncate
                              width={430}
                              isTruncated={isTruncated}
                              setTruncate={setTruncate}
                              setExpand={setExpand}
                            >
                              {data.remark}
                            </TextWithTruncate>
                          )}
                          {expand && (
                            <StyledTruncateBox
                              data-testid="truncate-expand"
                              maxWidth={430}
                              isTruncated={isTruncated}
                              onClick={() => setExpand(false)}
                            >
                              {data.remark} {isTruncated && <KeyboardArrowUp />}
                            </StyledTruncateBox>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </>
            ))}
          </Timeline>
        </>
      )
    })
  } else {
    return (
      <Typography sx={{ textAlign: 'center' }}>
        <Box>
          <Typography>
            <StyledIconHistory></StyledIconHistory>
          </Typography>
          <Typography variant="body1b" color="#5F5F5F">
            ไม่มีประวัติการเปลี่ยนแปลง
          </Typography>
        </Box>
      </Typography>
    )
  }
}

export default TimelineHistory

export const TextWithTruncate = (props) => {
  const { width, isTruncated, setTruncate, setExpand, children } = props
  return (
    <TruncateMarkup
      lines={3}
      onTruncate={(isTrunC) => setTruncate(isTrunC)}
      ellipsis={
        <span style={{ fontWeight: 'bold', marginLeft: 1 }}> เพิ่มเติม</span>
      }
    >
      <StyledTruncateBox
        data-testid="truncate-not-expand"
        maxWidth={width}
        isTruncated={isTruncated}
        onClick={() => setExpand(true)}
      >
        {children}
      </StyledTruncateBox>
    </TruncateMarkup>
  )
}
