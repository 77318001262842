import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { onFilterClick } from '../event'
import { getStationFilterDefault } from '../../../services/masterData/meetingRoom'

export const handleFiltering = (table, filter, station, manageProps) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(table, filter, station, manageProps))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleFetchStation = (setStation) => async (dispatch) => {
    const response = await dispatch(getStationFilterDefault())
    const stationList = _.get(response, 'payload', []).map((item) => ({
      label: item.station,
      name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
      uuid: item.uuid,
    }))

    setStation(stationList)
  }