import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledNoDataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  alignItems: 'center',
  padding: `${theme.spacing(3)} 0`,
  svg: { fontSize: '35px', color: theme.palette?.text?.lightGray },
}))
