export const defaultFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  startTestDateChecked: false,
  startTestDate: '',
  endTestDate: '',
  courseCodeChecked: false,
  courseCode: '',
  classUuidChecked: false,
  classUuid: '',
  distributionChecked: false,
  distribution: '',
  agentCodeChecked: false,
  agentCode: '',
  unitCodeChecked: false,
  unitCode: '',
  sRamChecked: false,
  sRam: '',
  ramCodeChecked: false,
  ramCode: '',
  trainerChecked: false,
  trainer: '',
  testTypeChecked: false,
  testType: {
    preTestChecked: false,
    postTestChecked: false,
    otherChecked: false,
  },
}
