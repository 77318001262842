import React from 'react'

import _ from 'lodash'

import DepartMent from './DepartMent'
import OfficeWork from './OfficeWork'
import Travelling from './Travelling'
import StaffTraining from './StaffTraining'
import { ClassRoom, FormGroupAcademyDate } from '../FormView'

import { SUB_WORK_TYPE_ACADEMY } from '../../../Text'

export default function index({ convertTime, dataList, workType }) {
  const nameSubWorkType = _.get(dataList, 'subWorkType.subWorkType', '')

  if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[0]) {
    return (
      <>
        <FormGroupAcademyDate
          listDate={dataList.dates || []}
          convertTime={convertTime}
          disableType={false}
        />

        <OfficeWork dataList={dataList} />
      </>
    )
  } else if (
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[6] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[5]
  ) {
    return (
      <>
        <FormGroupAcademyDate
          listDate={dataList.dates || []}
          convertTime={convertTime}
        />

        <DepartMent dataList={dataList} />
      </>
    )
  } else if (nameSubWorkType === SUB_WORK_TYPE_ACADEMY[4]) {
    return (
      <>
        <Travelling dataList={dataList} />
      </>
    )
  } else if (
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[1] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[2] ||
    nameSubWorkType === SUB_WORK_TYPE_ACADEMY[3]
  ) {
    const isOnline =
      _.get(dataList, 'meetingType', 'ONLINE') === 'ONLINE' ? true : false
    return (
      <>
        <ClassRoom
          sx={{ my: 4 }}
          dataList={dataList}
          convertTime={convertTime}
          ignore={false}
          workType={workType}
          isOnline={isOnline}
        />
        <StaffTraining dataList={dataList} />
      </>
    )
  } else {
    return <></>
  }
}
