import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../redux/slices/eTestingForm'

export const onDragEnd = (event, answers, keyQuestion, index) => (dispatch) => {
  if (!event.destination) return
  if (event.destination.index === event.source.index) return
  const listAnswer = reorder(
    answers,
    event.source.index,
    event.destination.index
  )

  const key = _.isUndefined(index) ? 'answers' : `answers[${index}].subAnswers`
  dispatch(setChangeQuestionByKey({ keyQuestion, key, value: listAnswer }))
}

export const reorder = (list, startIndex, endIndex) => {
  const listAnswer = Array.from(list)
  const [removed] = listAnswer.splice(startIndex, 1)
  listAnswer.splice(endIndex, 0, removed)
  const result = listAnswer.map((item, index) => ({
    ...item,
    id: `id-${index + 1}`,
    sequence: index + 1,
  }))
  return result
}
