import { store } from '../../../../App'
import {
  E_EXAM_TYPE,
  SCHEDULE_STATUS,
} from '../../../../constants/eExamination'
import { overviewDataEnum } from '../enums/drawerEnum'
import _ from 'lodash'

export const scheduleCompany = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'เปิดรับสมัคร',
      labelVariant: '',
      background: '#BED0ED',
      dotColor: '#4976BA',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_STATION.OPEN, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.COMPANY,
      value: SCHEDULE_STATUS.OPEN_REGISTER,
    },
    {
      label: 'ปิดรับสมัคร',
      labelVariant: '',
      background: '#F5F5F5',
      dotColor: '#E0DFDF',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_STATION.CLOSE, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.COMPANY,
      value: SCHEDULE_STATUS.CLOSED_REGISTER,
    },
    {
      label: 'ยกเลิกรอบสอบ',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_STATION.CANCEL, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.COMPANY,
      value: SCHEDULE_STATUS.CANCEL,
    },
  ]
}

export const scheduleAssociation = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'เปิดรับสมัคร',
      labelVariant: '',
      background: '#BED0ED',
      dotColor: '#4976BA',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_ASSOCIATION.OPEN, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: SCHEDULE_STATUS.OPEN_REGISTER,
    },
    {
      label: 'ปิดรับสมัคร',
      labelVariant: '',
      background: '#F5F5F5',
      dotColor: '#E0DFDF',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_ASSOCIATION.CLOSE, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: SCHEDULE_STATUS.CLOSED_REGISTER,
    },
    {
      label: 'ยกเลิกรอบสอบ',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_ASSOCIATION.CANCEL, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: SCHEDULE_STATUS.CANCEL,
    },
  ]
} 

export const scheduleOtherModel = () => {
  const { eExamDashboard } = store.getState().crud 
  return [
    {
      label: 'เปิดรับสมัคร',
      labelVariant: '',
      background: '#BED0ED',
      dotColor: '#4976BA',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_OTHER.OPEN, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.OTHER,
      value: SCHEDULE_STATUS.OPEN_REGISTER,
    },
    {
      label: 'ปิดรับสมัคร',
      labelVariant: '',
      background: '#F5F5F5',
      dotColor: '#E0DFDF',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_OTHER.CLOSE, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.OTHER,
      value: SCHEDULE_STATUS.CLOSED_REGISTER,
    },
    {
      label: 'ยกเลิกรอบสอบ',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(eExamDashboard?.overviewDataCount, overviewDataEnum.SCHEDULE_OTHER.CANCEL, 0),
      unit: 'สนาม',
      type: E_EXAM_TYPE.OTHER,
      value: SCHEDULE_STATUS.CANCEL,
    },
  ]
}

