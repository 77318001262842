import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import {
  E_LEARNING_COURSE_STATUS_LABEL,
  E_LEARNING_COURSE_STATUS,
} from '../../../../../../../constants/eLearning'
import { StyledDot } from '../Styled'

const StatusInfoPreview = (props) => {
  const theme = useTheme()
  const { status, count, percent } = props
  const bgColor =
    status === E_LEARNING_COURSE_STATUS.NOT_STARTED
      ? theme.palette.graph.grey1Light
      : status === E_LEARNING_COURSE_STATUS.INPROGRESS
      ? theme.palette.graph.blue3Light
      : theme.palette.graph.green2Light
  const dotColor =
    status === E_LEARNING_COURSE_STATUS.NOT_STARTED
      ? theme.palette.graph.grey1
      : status === E_LEARNING_COURSE_STATUS.INPROGRESS
      ? theme.palette.graph.blue3
      : theme.palette.graph.green2

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gap={1}
      borderRadius={2}
      backgroundColor={`${bgColor}`}
      p={1.25}
    >
      <Box display="flex" gap={1} alignItems="baseline">
        <StyledDot color={dotColor} />
        <Typography variant="body2b">
          {E_LEARNING_COURSE_STATUS_LABEL[status]}
        </Typography>
      </Box>

      <Box display="flex" gap={1} ml={2} alignItems="baseline">
        <Typography variant="h5" color="text.secondary">
          {count}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          คน
        </Typography>
        <Typography pl={1} variant="h4">
          {percent % 1 === 0 ? percent : percent.toFixed(2)} %
        </Typography>
      </Box>
    </Box>
  )
}

export default StatusInfoPreview
