import {
  setChangeExperience,
  setAddExperience,
  setDeleteExperience,
} from '../../../../../../src/redux/slices/manage/prospectProfile'

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeExperience(props))
}

export const handleAddExperience = () => (dispatch) => {
  dispatch(setAddExperience(defaultEducational))
}

export const handleDeleteExperience = (index) => (dispatch) => {
  dispatch(setDeleteExperience(index))
}

export const defaultEducational = {
  GPA: '',
  between: '',
  branch: '',
  education: '',
  institution: '',
}
