import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Content from './Content/index'
import { fetchMeetingRoom } from './events'
import Header from './Header'
import { LoadingPage } from '../../ManageClass/Styled'
import _ from 'lodash'

const View = () => {
  const { id: uuid } = useParams()
  const componentRef = React.useRef()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.masterDataMeetingRoomForm.isLoading,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (!_.isEmpty(uuid)) dispatch(fetchMeetingRoom(uuid))
  }, [uuid])

  return (
    <Box sx={{ m: 3 }}>
      {!isLoading && (
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Header uuid={uuid} componentRef={componentRef} />
          <Content componentRef={componentRef} />
        </Box>
      )}

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default View
