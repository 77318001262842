import _ from 'lodash'
import { store } from '../../../App'
import { replaceFieldError, setFieldValue } from '../../../redux/slices/crud'
import { getExpenseInMonthlyPlanAll } from '../../../services/expense'

export const fetchExpense = async () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
  const { monthlyPlan } = store.getState().crud

  const { expense, expenseSummary, summaryByCostCenter, summaryByAccountCode } =
    await store
      .dispatch(
        getExpenseInMonthlyPlanAll({
          monthlyPlanId: monthlyPlan.id,
          monthlyPlanUuid: monthlyPlan.uuid,
        }),
      )
      .then((response) => {
        const summaryByCostCenter = _.get(
          response,
          'payload.data.summaryByCostCenter',
          [],
        )
        const summaryByAccountCode = _.get(
          response,
          'payload.data.summaryByAccountCode',
          [],
        )
        const expense = _.map(
          _.get(response, 'payload.data.expense', []),
          (exp) => {
            const firstNameTH = _.get(exp, 'staffProfile.firstNameTH', '-')
            const lastNameTH = _.get(exp, 'staffProfile.lastNameTH', '')
            const staffProfileLabel = `${firstNameTH} ${lastNameTH}`
            const responsibility = _.get(exp, 'staffRole.responsibility', '-')

            return {
              ...exp,
              costCenter: {
                ...exp.costCenter,
                value: exp.costCenter.uuid,
                label: exp.costCenter.descriptionName,
              },
              staffProfile: _.get(exp, 'staffProfile', undefined)
                ? {
                    ...exp?.staffProfile,
                    value: exp?.staffProfile?.uuid,
                    label: staffProfileLabel,
                  }
                : null,
              staffRole: _.get(exp, 'staffRole', undefined)
                ? {
                    ...exp?.staffRole,
                    value: exp?.staffRole?.uuid,
                    label: responsibility,
                  }
                : null,
              costCenterId: _.get(exp, 'costCenter.costCenter', '-'),
              costCenterName: _.get(exp, 'costCenter.descriptionName', '-'),
              staffProfileLabel: staffProfileLabel,
              staffRoleLabel: responsibility,
              showDetails: true,
            }
          },
        )

        return {
          expense,
          expenseSummary: _.get(response, 'payload.data.expenseSummary', 0),
          summaryByCostCenter,
          summaryByAccountCode,
        }
      })

  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: {
        expenseList: expense,
        expenseSummary,
        summaryByCostCenter,
        summaryByAccountCode,
      },
    }),
  )

  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
