import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import Launch from '@mui/icons-material/Launch'
import { StyledCardContent } from '../../../../../components/View/Styled'
import { ContentRow } from '../../../../../components/View'
import {
  StyledHeadCard,
  StyledItemCard,
} from '../../../../../components/Styled'

const Courses = () => {
  const detail = useSelector((state) => state.eLearningPathDetail.result)
  const eLearningLearningPathCourses = _.get(
    detail,
    'eLearningLearningPathCourses',
    [],
  )
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        หลักสูตรใน Learning path
      </Typography>
      <ContentRow
        title="จำนวนหลักสูตรทั้งหมด"
        content={`${eLearningLearningPathCourses.length} หลักสูตร`}
      />
      {_.map(eLearningLearningPathCourses, (course, index) => (
        <CourseCard key={index} index={index} course={course} />
      ))}
    </StyledCardContent>
  )
}

export default Courses

export const CourseCard = ({ course, index }) => {
  const [isExpanded, setExpand] = useState(true)
  const isELearning = course.type === 'E-Learning'
  const selectedCourse = course.course
  const coursePath = isELearning
    ? '/e-learning/course/view'
    : '/course-setting/version/preview'
  return (
    <StyledItemCard>
      <StyledHeadCard>
        <Typography variant="body1b" color="primary">
          หลักสูตรที่ {index + 1}
        </Typography>
        <IconButton
          sx={{ width: 30, height: 30 }}
          onClick={() => setExpand(!isExpanded)}
        >
          {isExpanded ? (
            <KeyboardArrowUpTwoTone />
          ) : (
            <KeyboardArrowDownTwoTone />
          )}
        </IconButton>
      </StyledHeadCard>
      {isExpanded && (
        <>
          <Divider
            sx={{ width: '100%', borderStyle: 'dashed', color: '#BDBDBD' }}
          />
          <ContentRow
            title="ชื่อหลักสูตร"
            content={_.get(
              selectedCourse,
              isELearning ? 'name' : 'englishName',
              '-',
            )}
          />
          <ContentRow
            title="บังคับเรียนใหม่เพื่อผ่าน Learning path นี้"
            content={course.isRequired ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
          />
          <Button
            variant="outlined"
            startIcon={<Launch />}
            size="s"
            onClick={() =>
              window.open(
                `${coursePath}/${_.get(selectedCourse, 'uuid', '')}`,
                '_blank',
              )
            }
          >
            รายละเอียดหลักสูตร
          </Button>
        </>
      )}
    </StyledItemCard>
  )
}
