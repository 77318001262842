import _ from 'lodash'
import { store } from '../../../App'
import dayjs from 'dayjs'

export const getModuleTitle = (uuid, title) => {
  return `${_.isUndefined(uuid) ? 'สร้าง' : 'แก้ไข'}${title}`
}

export const getProductType = () => {
  const { productType } = store.getState().crud.formData
  return productType
}

export const convertJoinDate = (start, end) => {
  let workStart = ''
  if (!start || !end) {
    return '-'
  }
  const startDate = dayjs(new Date(start))
  const endDate = dayjs(new Date(end))
  const years = endDate.diff(startDate, 'year')
  const months = endDate.diff(startDate, 'month') - years * 12
  const days = endDate.diff(
    startDate.add(years, 'year').add(months, 'month'),
    'day',
  )

  if (years > 0) {
    workStart += `${years} ปี `
  }
  if (months > 0) {
    workStart += `${months} เดือน `
  }
  if (days > 0) {
    workStart += `${days} วัน`
  }
  return workStart
}
