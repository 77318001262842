import _ from 'lodash'
import { checkChangeDate } from './Handle'
import { SUB_WORK_TYPE_ACADEMY } from '../Text'

export const requireFileArray = ({
  listArray,
  listMessage,
  meetingType,
  checkTrainingPF,
  subWorkType = null,
  roomManagementMeetingRoom = [],
}) => {
  let result = []
  if (listArray.length) {
    listArray.map((item) => {
      let listRequire = {}

      const plansMeetingRooms =
        _.get(item, `meetingRooms`, []).length ||
        _.get(item, `location.classRoomOther`, '')
          ? true
          : false

      const plansMeetingRoomsOther = _.filter(
        roomManagementMeetingRoom,
        (room) => {
          return (
            room.bookingDate === _.get(item, `date`) &&
            !_.isEmpty(room.meetingRoomOther)
          )
        },
      )
      for (let keyExpand in item) {
        let value = _.isEmpty(item[keyExpand])

        if (keyExpand === 'startTime' || keyExpand === 'endTime') {
          value =
            _.isEmpty(String(item[keyExpand])) ||
            String(item[keyExpand]).split('_').length > 1
        }

        const findMessage = listMessage.find((item) => {
          return item.value === keyExpand
        })

        if (value && keyExpand !== 'date' && keyExpand !== 'typeTime') {
          let message = _.get(findMessage, 'message', '')
          if (meetingType === 'online' && keyExpand === 'meetingRoom') {
            message = ''
          }
          if (keyExpand === 'link') {
            message = ''
          }

          if (meetingType === 'onsite' && keyExpand === 'meetingRoom') {
            if (
              subWorkType === SUB_WORK_TYPE_ACADEMY[1] ||
              subWorkType === SUB_WORK_TYPE_ACADEMY[2] ||
              subWorkType === SUB_WORK_TYPE_ACADEMY[3] ||
              subWorkType === SUB_WORK_TYPE_ACADEMY[5] ||
              subWorkType === SUB_WORK_TYPE_ACADEMY[6]
            ) {
              message = ''
            }
          }

          listRequire = {
            ...listRequire,
            date: item.date,
            [keyExpand]: message,
          }
        }

        if (keyExpand === 'speakers') {
          let resultSpeaker = []
          let message = {}
          const speakers = item[keyExpand] || []

          speakers.map((itemSpeaker, index) => {
            for (let keySpeaker in itemSpeaker) {
              const findMessageSpeaker = listMessage.find((item) => {
                let newKey = keySpeaker
                if (newKey === 'startTime' || newKey === 'endTime')
                  newKey = 'time'

                return item.value === newKey
              })
              let newMessage = _.get(findMessageSpeaker, 'message', '')
              let valueSpeaker = _.isEmpty(itemSpeaker[keySpeaker])

              if (keySpeaker === 'startTime' || keySpeaker === 'endTime') {
                valueSpeaker =
                  _.isEmpty(
                    itemSpeaker[keySpeaker] !== 0
                      ? String(itemSpeaker[keySpeaker])
                      : itemSpeaker[keySpeaker],
                  ) || String(itemSpeaker[keySpeaker]).split('_').length > 1
              }

              if (
                keySpeaker === 'staff' &&
                _.isObject(_.get(itemSpeaker, 'staff', ''))
              ) {
                valueSpeaker = _.isEmpty(_.get(itemSpeaker, 'staff.uuid', ''))
              }

              if (valueSpeaker) {
                message = {
                  ...message,
                  [keySpeaker]: newMessage,
                }
              } else {
                message = {
                  ...message,
                  [keySpeaker]: '',
                }
              }
            }
            if (Object.keys(message).length) {
              resultSpeaker.push({ ...message, indexSK: index })
            }
          })
          let checkSK = true
          resultSpeaker.length &&
            resultSpeaker.forEach((itemRS) => {
              for (let keyCheck in itemRS) {
                if (keyCheck !== 'indexSK') {
                  if (!_.isEmpty(itemRS[keyCheck])) {
                    checkSK = false
                    break
                  }
                }
              }
            })

          listRequire = {
            ...listRequire,
            date: item.date,
            [keyExpand]: !checkSK ? resultSpeaker : [],
          }
        }

        if (keyExpand === 'location' && checkTrainingPF) {
          const location = _.get(item, `[${keyExpand}]`, '')
          let mainText = ''
          let classRoomOtherText = ''
          if (
            !_.get(location, 'online', false) &&
            !_.get(location, 'onsite', false)
          ) {
            mainText = 'กรุณาเลือก'
          }
          if (
            _.get(location, 'onsite', false) &&
            _.get(location, 'other', false) &&
            _.isEmpty(_.get(location, 'classRoomOther', '')) &&
            !plansMeetingRooms
          ) {
            if (_.size(_.defaultTo(plansMeetingRoomsOther, [])) > 0) {
              classRoomOtherText = 'กรุณาระบุห้องอบรม'
            }
          }

          listRequire = {
            ...listRequire,
            date: item.date,
            [keyExpand]:
              _.isEmpty(classRoomOtherText) && _.isEmpty(mainText)
                ? {}
                : {
                    classRoomOther: classRoomOtherText,
                    mainText: mainText,
                  },
          }
        }
      }
      result.push(listRequire)
    })
  }
  return result
}

export const requireFile = (
  obj,
  listMessage,
  ignoredKey,
  ignoredExpand = false,
  subWorkType = null,
) => {
  let result = {}
  let status = true
  let checkTrainingPF = false

  // check trainingPlatform || course
  if (_.get(obj, 'course', {}) || _.get(obj, 'trainingPlatform', []).length)
    checkTrainingPF = true
  //  end check trainingPlatform

  if (Object.keys(obj).length) {
    for (let key in obj) {
      let message = ''
      let value = _.isEmpty(obj[key])

      const checkIgnored = ignoredKey.find((item) => {
        return item === key
      })
      if (key !== 'remark' && !checkIgnored) {
        const findMessage = listMessage.find((item) => {
          return item.value === key
        })

        // if(key['workType'] === "Leave" && sWorkType ==='Other Leave' && key === 'remarkLeave' && _.isEmpty(obj[key]))

        if (key === 'cost' || key === 'expectNumberPeople') {
          let checkValue = `${obj[key]}` ? obj[key] : ''
          value =
            !_.isEmpty(checkValue) && Number(checkValue) === 0
              ? false
              : _.isEmpty(String(checkValue))
        }

        if (key === 'cost' && _.get(obj, 'cost', 0) <= 0) {
          message = _.get(findMessage, 'message', '')
        }

        if (
          !checkChangeDate(obj['startDate'], obj['finishDate']) &&
          (key === 'startDate' || key === 'finishDate')
        ) {
          message = _.get(findMessage, 'message', '')
        } else if (
          (key === 'staff' || key === 'attendees') &&
          obj[key].length <= 0
        ) {
          message = _.get(findMessage, 'message', '')
        } else if (key === 'remarkCost' && value) {
          const cost = _.get(obj, 'cost', 0)
          const breakMealBudget = _.get(obj, 'course.breakMealBudget', 0)
          if (cost > breakMealBudget) {
            message = _.get(findMessage, 'message', '')
          }
        } else {
          if (value && key !== 'startDate' && key !== 'finishDate') {
            message = _.get(findMessage, 'message', '')
          }
        }

        if (!value && String(obj[key]).length > 3000) {
          message = 'ระบุข้อความได้ไม่เกิน 3,000 ตัวอักษร'
        }

        if (key === 'dayOff' || key === 'title') {
          if (!value && String(obj[key]).length > 80) {
            message = 'ระบุข้อความได้ไม่เกิน 80 ตัวอักษร'
          }
        }
        if (
          key === 'subject' &&
          String(obj[key]) !== '' &&
          String(obj[key]).length > 255
        ) {
          message = 'ระบุข้อความได้ไม่เกิน 255 ตัวอักษร'
        }
      }
      if (key === 'remarkLeave' && !value && String(obj[key]).length > 3000) {
        message = 'ระบุข้อความได้ไม่เกิน 3,000 ตัวอักษร'
      }
      if (key === 'remark' || key === 'courseOther') {
        message = ''
        if (!value && String(obj[key]).length > 3000) {
          message = 'ระบุข้อความได้ไม่เกิน 3,000 ตัวอักษร'
        }
      }

      if (key === 'expandDate' || key === 'dates') {
        message = !ignoredExpand
          ? requireFileArray({
              listArray: obj[key],
              listMessage: listMessage,
              meetingType: _.get(obj, 'meetingType', 'online'),
              checkTrainingPF: checkTrainingPF,
              subWorkType,
              roomManagementMeetingRoom: _.get(
                obj,
                'roomManagementMeetingRoom',
                [],
              ),
            })
          : []
      }

      if (
        _.get(obj, 'meetingType', 'online') === 'online' &&
        key === 'toMeetingRoom'
      ) {
        message = ''
      }

      result = {
        ...result,
        [key]: message,
      }
    }
    for (let key in result) {
      const value = _.isEmpty(result[key])

      if (!value && key !== 'expandDate' && key !== 'dates') {
        status = false
        break
      }

      if (
        !value &&
        !ignoredExpand &&
        (key === 'expandDate' || key === 'dates')
      ) {
        status = true
        result[key].map((item) => {
          for (let keyExpand in item) {
            const check = _.isEmpty(item[keyExpand])
            if (
              !check &&
              keyExpand !== 'date' &&
              keyExpand !== 'typeTime' &&
              keyExpand !== 'speakers' &&
              keyExpand !== 'location'
            ) {
              status = false
              break
            }

            if (keyExpand === 'location') {
              const valueObj = item[keyExpand] || {}
              if (!_.isEmpty(valueObj)) {
                status = false
                break
              }
            }
            if (keyExpand === 'speakers') {
              const valueObj = item[keyExpand] || []
              if (valueObj && valueObj.length > 0) {
                status = false
                break
              }
            }
          }
        })
      }
    }
  }
  return { result, status }
}

export const checkSpeaker = ({ listValueDate, isTrining }) => {
  let result = false
  if (listValueDate.length) {
    listValueDate.map((item) => {
      const listSpeakerBorrow = _.get(item, 'speakerBorrow', [])
      const listSpeakers = _.get(item, 'speakers', [])
      let valueSB = true
      let valueSK = true

      // check  SpeakerBorrow
      if (listSpeakerBorrow.length) {
        const objSB = listSpeakerBorrow.find((itemSB) => {
          const status = _.get(itemSB, 'status', '')
          if (
            status !== 'REJECT' &&
            status !== 'CANCEL' &&
            status !== 'DRAFT'
          ) {
            return _.get(itemSB, 'speakerBorrowResponsibility', []).length > 0
          }
        })
        valueSB = _.isEmpty(objSB)
      }

      // check  speaker
      if (listSpeakers.length) {
        const objSK = listSpeakers.find((itemSB) => {
          const valueFindSB = isTrining
            ? _.get(itemSB, 'responsibility.uuid', '')
            : _.get(itemSB, 'speakerRole', '')
          return valueFindSB
        })
        valueSK = _.isEmpty(objSK)
      }
      if (!result && valueSB && valueSK) {
        result = true
        return true
      }
    })
  }
  return result
}
