import _ from 'lodash'
import {
  putManageClassExamScore,
  putStandaloneGiveScore,
} from '../../../../../../services/manageClass/check'
import {
  loadingDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../../../redux/slices/dialog'
import { setBody } from '../../../../../../redux/slices/manageClassCheckExam'

export const handleRemarkChange = (e, body) => (dispatch) => {
  const value = _.get(e.target, 'value', '')
  if (value !== '') dispatch(setEnableButton())
  else dispatch(setDisableButton())

  const payload = { ...body, reasonToChange: value }
  dispatch(setBody(payload))
}

export const handleSubmitChange = (body, isStandalone) => async (dispatch) => {
  dispatch(loadingDialog())
  if (isStandalone) await dispatch(putStandaloneGiveScore(body))
  else await dispatch(putManageClassExamScore(body))
}
