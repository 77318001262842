import _ from 'lodash'
import { getFormatDate } from '../../../utils/lib'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'

export const fetchSearchParams =
  (urlParams, statusName) => async (dispatch) => {
    let valueArray = []

    if (!_.isEmpty(urlParams.get('Station'))) {
      valueArray.push({
        id: 1,
        key: 'station',
        title: 'Station',
        value: [urlParams.get('Station')],
        typeOfValue: 'DROPDOWN_MULTI',
        label: urlParams.get('Station'),
      })
    }

    if (!_.isEmpty(urlParams.get('STATUS'))) {
      valueArray.push({
        id: 2,
        key: 'statusClass',
        title: 'Status Class',
        value: [urlParams.get('STATUS')],
        typeOfValue: 'DROPDOWN_MULTI',
        label: statusName,
      })
    }

    if (
      !_.isEmpty(urlParams.get('START')) &&
      !_.isEmpty(urlParams.get('END'))
    ) {
      valueArray.push({
        id: 3,
        key: 'startClassDateTime',
        title: 'วันเริ่มต้นคลาส',
        value: {
          startDate: urlParams.get('START'),
          endDate: urlParams.get('END'),
        },
        typeOfValue: 'DATE_RANGE',
        label:
          getFormatDate(urlParams.get('START')) +
          ' - ' +
          getFormatDate(urlParams.get('END')),
      })
      valueArray.push({
        id: 4,
        key: 'endClassDateTime',
        title: 'วันสิ้นสุดคลาส',
        value: {
          startDate: urlParams.get('START'),
          endDate: urlParams.get('END'),
        },
        typeOfValue: 'DATE_RANGE',
        label:
          getFormatDate(urlParams.get('START')) +
          ' - ' +
          getFormatDate(urlParams.get('END')),
      })
    }
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: valueArray,
      }),
    )
    dispatch(handleSaveFilters())
  }
