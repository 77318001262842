import { Box, Button, Typography } from '@mui/material'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbExaminationView } from '../../model'
import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import appHistory from '../../../../appHistory'
import { path } from '../../../../constants/path'
import { useDispatch } from 'react-redux'
import { setIsOpenHistoryFilterDrawer } from '../../../../redux/slices/eExamination/list'

const Header = ({ uuid }) => {
  const dispatch = useDispatch()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box>
        <Typography variant="h4">รายละเอียดตารางสอบ</Typography>
        <Breadcrumbs menuList={breadcrumbExaminationView} />
      </Box>
      <Box display="flex" gap={2}>
        <Button
          startIcon={<RestoreTwoToneIcon />}
          variant="outlined"
          onClick={() => {
            dispatch(setIsOpenHistoryFilterDrawer(true))
          }}
        >
          ประวัติการแก้ไข
        </Button>
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          onClick={() => {
            appHistory.push(`${path.eExamination}/form/${uuid}`)
          }}
          data-testid="btn-edit"
        >
          แก้ไข
        </Button>
      </Box>
    </Box>
  )
}

export default Header
