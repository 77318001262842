import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useState, useEffect } from 'react'
import { breadcrumbOicView } from '../../model'
import {
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledCard,
  StyledCardFooter,
  StyledBoxText,
} from '../Styled'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import Divider from '@mui/material/Divider'
import { manageClassOicView } from '../../../../utils/apiPath'
import { callAPI, convertFormatDateTime } from '../../../../utils/lib'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
export const BoxLableText = ({ title, value, isFrist }) => {
  return (
    <StyledBoxText isFrist={isFrist}>
      <Box className="title-text">
        <Typography variant="body1b">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="body1">{value}</Typography>
      </Box>
    </StyledBoxText>
  )
}
const View = () => {
  const { uuid } = useParams()
  const [detail, setDetail] = useState({})
  const [isPageLoading, setIsPageLoading] = useState(true)
  const fetchOicExamById = async () => {
    await callAPI({
      url: manageClassOicView + `/${uuid}`,
      method: 'GET',
      onSuccess: (res) => {
        setDetail(res)
        setIsPageLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchOicExamById()
  }, [uuid])

  return (
    <StyledContainer>
      <StyledContent isLoading={isPageLoading}>
        <Box>
          <Typography variant="h4">{`${breadcrumbOicView[2].title}:  ${_.get(
            detail,
            'FNAME',
            '-'
          )} ${_.get(detail, 'LNAME', '-')}`}</Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbOicView} />
        <StyledCard>
          <BoxLableText
            isFrist={true}
            title="คำนำหน้า"
            value={_.get(detail, 'TITLE', '-') ?? '-'}
          />
          <BoxLableText
            title="ชื่อ"
            value={_.get(detail, 'FNAME', '-') ?? '-'}
          />
          <BoxLableText
            title="นามสกุล"
            value={_.get(detail, 'LNAME', '-') ?? '-'}
          />
          <BoxLableText
            title="วันที่เข้าสอบ"
            value={_.get(detail, 'examDate', '-') ?? '-'}
          />
          <BoxLableText title="CID" value={_.get(detail, 'CID', '-') ?? '-'} />
          <BoxLableText
            title="ประเภท"
            value={_.get(detail, 'TYPE', '-') ?? '-'}
          />
          <BoxLableText
            title="EXAMT"
            value={_.get(detail, 'EXAMT', '-') ?? '-'}
          />
          <BoxLableText
            title="รหัสสถานที่สอบ"
            value={_.get(detail, 'EXAMP', '-') ?? '-'}
          />
          <BoxLableText
            title="รหัสจังหวัด"
            value={_.get(detail, 'EXAMPV', '-') ?? '-'}
          />
          <BoxLableText
            title="ลำดับสอน"
            value={_.get(detail, 'REGID', '-') ?? '-'}
          />
          <BoxLableText
            title="ประเภทข้อสอบ"
            value={_.get(detail, 'EXAMTYPE', '-') ?? '-'}
          />
          <BoxLableText
            title="รหัสบริษัท"
            value={_.get(detail, 'ComCode', '-') ?? '-'}
          />
          <BoxLableText
            title="รหัสหน่วย"
            value={_.get(detail, 'UnitCode', '-') ?? '-'}
          />
          <StyledCardFooter>
            <Divider />
            <Typography
              variant="caption"
              color="text.lightGray"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {`นำเข้าโดย ${_.get(
                detail,
                'createdBy.name',
                '-'
              )} ${convertFormatDateTime({
                value: _.get(detail, 'createdAt'),
                type: 'dateTime',
              })}`}
            </Typography>
          </StyledCardFooter>
        </StyledCard>
      </StyledContent>

      <LoadingPage isLoading={isPageLoading} />
    </StyledContainer>
  )
}

export default View
