import _ from 'lodash'
import { StyledRow } from '../../../../Styled'
import { shallowEqual, useSelector } from 'react-redux'
import { handleChange } from '../../../../handler/handleChange'
import { handleSortColumns } from '../../../../../../redux/slices/reportComp/event'
import ColumnSelect from '../../../../components/ColumnSelect'

const SelectMergeColumn = () => {
  const {
    firstColumnSelected,
    secondaryColumnSelected,
    defaultColumns,
    columnName,
  } = useSelector(
    (state) => ({
      firstColumnSelected: state.reportComp.firstColumnSelected,
      secondaryColumnSelected: state.reportComp.secondaryColumnSelected,
      defaultColumns: state.reportComp.defaultColumns,
      columnName: state.reportComp.columnName,
    }),
    shallowEqual,
  )

  const filteredColumns = _.filter(
    defaultColumns,
    (col) => col.name !== _.get(columnName, 'NO', ''),
  )

  const sortedColumns = handleSortColumns(filteredColumns)

  return (
    <StyledRow sx={{ gap: 3 }}>
      <ColumnSelect
        hasTwoBoxes={true}
        columnSelected={firstColumnSelected}
        options={sortedColumns}
        handleChange={(e) => {
          let value = _.get(e, 'target.id', '')
          if (value.length === 0) {
            value = null
          }
          handleChange({ key: 'firstColumnSelected', value: value })
        }}
      />
      และ
      <ColumnSelect
        hasTwoBoxes={true}
        columnSelected={secondaryColumnSelected}
        options={sortedColumns}
        handleChange={(e) => {
          let value = _.get(e, 'target.id', '')
          if (value.length === 0) {
            value = null
          }
          handleChange({ key: 'secondaryColumnSelected', value: value })
        }}
      />
    </StyledRow>
  )
}

export default SelectMergeColumn
