import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import DefaultChoice from '../DefaultChoice'
import FreeTextChoice from '../FreeTextChoice'
import RatingChoice from '../RatingChoice'

const MapQuestionType = ({ sequence, indexQuestion, length }) => {
  const { itemQuestion, disableForm } = useSelector(
    (state) => ({
      itemQuestion: state.eEvaluationForm.questions.find(
        (item) => item.sequence === sequence
      ),
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  const props = {
    sequence,
    indexQuestion,
    itemQuestion,
    disableForm,
    length,
  }

  switch (itemQuestion?.questionType) {
    case 'SINGLE':
    case 'MULTIPLE':
    case 'RANKING':
      return <DefaultChoice props={props} />
    case 'FREE_TEXT':
      return <FreeTextChoice props={props} />
    case 'RATING':
      return <RatingChoice props={props} />
    default:
      return <></>
  }
}
export default MapQuestionType
