import { Card } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledCourseSelection = styled(Card)(({ theme, isSelected }) => ({
  width: 200,
  height: 120,
  borderRadius: theme.spacing(1.5),
  background: theme.palette?.background?.paper,
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette?.blue?.brightBlue}`,
  position: 'relative',
  boxShadow: isSelected
    ? '0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.2)'
    : 'none',
}))
