import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import _ from 'lodash'
import { totalProspectModel } from '../../models/overview'
import { DataCard } from '../ModuleCard/DataCard'
import { handleClickButton } from '../../handler/handleClickButton'

const TotalProspect = () => {
  const totalProspect = totalProspectModel()
  const finalResult = [_.get(totalProspect, '[0].number', 0)]
  const totalModule = _.sum(finalResult)
  return (
    <Box height={'100%'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Typography variant="h6">ยอดรวม Prospect</Typography>
          <Box
            display="flex"
            flexDirection={'column'}
            gap={2}
            alignItems={'start'}
            width={'100%'}
          >
            <DataCard
              dataList={totalProspect}
              totalModule={totalModule}
              border={'none'}
              padding={0}
              handleClickButton={handleClickButton}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default TotalProspect
