import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { handleSelectStation } from '../../event'

export const CheckboxStation = ({ stationListItem }) => {
  const dispatch = useDispatch()
  const { filterStation } = useSelector(
    (state) => ({
      filterStation: state.roomManagement.drawerCalendarFilter.filterStation,
    }),
    shallowEqual,
  )
  return (
    <FormControlLabel
      label={stationListItem.label}
      control={
        <Checkbox
          checked={filterStation.includes(stationListItem.value)}
          onChange={(e) => {
            dispatch(
              handleSelectStation(e.target.checked, stationListItem.value),
            )
          }}
        />
      }
    />
  )
}
