import _ from 'lodash'
import dayjs from 'dayjs'

import * as reducer from '../../../../redux/slices/eEvaluationForm'
import { filterStateEvaluation } from '../../../../services/eEvaluation/form'

import {
  setFetchDataFeedback,
  setFilterFeedback,
} from '../../../../redux/slices/eEvaluationForm/feedback'

export const fetchDataFeedback = (uuid, feedbackFilter) => async (dispatch) => {
  dispatch(reducer.startLoading())
  const payload = { uuid, body: feedbackFilter }
  const response = await dispatch(filterStateEvaluation(payload))
  dispatch(setFetchDataFeedback(_.get(response, 'payload', {})))
  dispatch(reducer.stopLoading())
}

export const onFilterClick = (filter) => (dispatch) => {
  let startDate = ''
  let toDate = ''
  if (filter.startDateChecked) {
    if (filter.displayDate) {
      startDate = dayjs(new Date(filter.startDate)).format(
        window.__env__.REACT_APP_DATE_DB
      )
      toDate = dayjs(new Date(filter.toDate)).format(
        window.__env__.REACT_APP_DATE_DB
      )
    }
  }

  let staffUuid = []
  if (filter.staffChecked) staffUuid = filter.staff.map((item) => item.value)

  const filterText = {
    courseUuid: filter.courseChecked ? filter.course : '',
    departmentUuid: filter.departmentChecked
      ? filter.department.map((item) => item.value)
      : [],
    staffUuid,
    startDate,
    toDate,
  }

  dispatch(setFilterFeedback(filterText))
}
