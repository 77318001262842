import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 296,
  minWidth: '214px',
  [theme.breakpoints.down('lg')]: { height: '100%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: 296,
  minHeight: 140,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 12px 1px rgba(73, 118, 186, 0.35)',
  backgroundColor: theme.palette?.background?.paper,
  '& #progress': {
    '& #in-progress': { display: 'flex' },
    '& #chip': { display: 'none' },
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    width: '90%',
    minWidth: 270,
    height: 306,
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
  },
}))

export const StyledContentBox = styled(Box)(({ theme }) => ({
  height: 324,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '8px',
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  gap: '8px',
  [theme.breakpoints.down('lg')]: { height: 152 },
}))

export const StyledIconLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette?.text?.secondary,
  '& .MuiSvgIcon-root': { fontSize: '16px' },
  '& .MuiTypography-root': { fontSize: '20px', fontWeight: 700 },
}))

export const StyledFootBox = styled(Box)(() => ({
  position: 'absolute',
  padding: '0 16px 12px',
  bottom: 5,
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
}))
