import React from 'react'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'
import { StyledDivider } from '../../../../components/View/Styled'
import TabMenu from '../../../../components/TabMenu'
import { tabs } from '../../../Form/model'
import { handleChangeTab } from './event'

const TabMenuContent = () => {
  const tab = useSelector(
    (state) => state.eLearningPathDetail.tab,
    shallowEqual,
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabMenu tab={tab} tabs={tabs} handleChange={handleChangeTab} />
      <StyledDivider />
    </Box>
  )
}
export default TabMenuContent
