import { CS_AGENT_PROFILE_SETTING } from '../permission/csAgentProfile'

export const agentProfileCells = [
  {
    id: 'agentCode',
    disablePadding: false,
    label: 'รหัส Agent',
    width: '120px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'agentCategory',
    label: 'ประเภท Agent',
    width: '120px',
  },
  {
    id: 'unitNumber',
    label: 'Unit Number',
    width: '120px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/agent-profile/view`,
  },
]

export const agentProfilePointCells = [
  {
    id: 'agentCode',
    disablePadding: false,
    label: 'รหัส Agent',
    width: '120px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'agentCategory',
    label: 'ประเภท Agent',
    width: '120px',
  },
  {
    id: 'unitNumber',
    label: 'Unit Number',
    width: '120px',
  },
  {
    id: 'point',
    label: 'คะแนนสะสม',
    type: 'number',
    width: '120px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/agent-profile/view`,
  },
]

export const agentProfileETesting = [
  {
    id: 'testName',
    disablePadding: false,
    label: 'ชื่อแบบทดสอบ',
  },
  {
    id: 'typeOfETesting',
    label: 'ประเภท',
  },
  {
    id: 'version',
    label: 'เวอร์ชั่น',
  },
  {
    id: 'updatedAt',
    label: 'วันที่',
  },
  {
    id: 'testResultTH',
    label: 'สถานะ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: '/e-testing/dashboard/view',
  },
]

export const agentProfileCourse = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
  },
  {
    id: 'englishName',
    label: 'หลักสูตร',
    width: 300,
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    width: 300,
  },
  {
    id: 'registerDate',
    label: 'วันที่สมัคร',
  },
  {
    id: 'betweenDate',
    label: 'วันที่จัดอบรม',
  },
  {
    id: 'hours',
    label: 'จำนวนชั่วโมง',
  },
  {
    id: 'result',
    label: 'สถานะคลาส',
  },
  {
    id: 'certificateExpiryDate',
    label: 'ประกาศนียบัตรหมดอายุ',
  },
  {
    id: 'action',
    disablePadding: false,
    hideSortIcon: true,
    label: 'คำสั่ง',
    width: '90px',
    viewPath: `/manage-class/learner/detail`,
  },
]

export const agentProfileCourse2 = [
  {
    id: 'examType',
    label: 'ประเภทสนามสอบ',
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    width: 300,
  },
  {
    id: 'region',
    label: 'ภูมิภาค',
  },
  {
    id: 'registerDateTime',
    label: 'วันที่สมัคร',
  },
  {
    id: 'examDateTime',
    label: 'วันที่และเวลาที่สอบ',
  },
  {
    id: 'examStatus',
    label: 'สถานะ',
  },
  {
    id: 'examResult',
    label: 'ผลการสอบ',
  },
  {
    id: 'action',
    disablePadding: false,
    hideSortIcon: true,
    label: 'คำสั่ง',
    width: '90px',
    viewPath: `/e-examination/examiner/detail`,
  },
]

export const agentProfileLicense = [
  {
    id: 'description',
    label: 'ชื่อใบอนุญาต',
    width: '120px',
  },
  {
    id: 'category',
    label: 'ประเภทใบอนุญาต',
  },
  {
    id: 'licenseNumber',
    label: 'เลขที่ใบอนุญาต',
  },
  {
    id: 'effectiveDate',
    label: 'วันมีผลบังคับ',
  },
  {
    id: 'expiryDate',
    label: 'วันหมดอายุ',
  },
  {
    id: 'exemptionFlag',
    label: 'Exemption Flag',
  },
  {
    id: 'remark',
    label: 'หมายเหตุ',
  },
]

export const csAgentProfileCells = [
  {
    id: 'agentCode',
    disablePadding: false,
    label: 'รหัส Agent',
    width: '120px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'firstName',
    label: 'First Name',
  },
  {
    id: 'lastName',
    label: 'Last Name',
  },
  {
    id: 'unitNumber',
    label: 'Broker Number',
    width: '120px',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/cs-agent-profile/view`,
    editPath: `/manage/cs-agent-profile/edit`,
    module: CS_AGENT_PROFILE_SETTING,
  },
]

export const csAgentProfilePointCells = [
  {
    id: 'agentCode',
    disablePadding: false,
    label: 'รหัส Agent',
    width: '120px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'firstName',
    label: 'First Name',
  },
  {
    id: 'lastName',
    label: 'Last Name',
  },
  {
    id: 'unitNumber',
    label: 'Broker Number',
    width: '120px',
  },
  {
    id: 'point',
    label: 'คะแนนสะสม',
    type: 'number',
    width: '120px',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/cs-agent-profile/view`,
    editPath: `/manage/cs-agent-profile/edit`,
    module: CS_AGENT_PROFILE_SETTING,
  },
]

export const agentProfileCourseDefault = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
  },
  {
    id: 'englishName',
    label: 'หลักสูตร',
    width: 300,
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    width: 300,
  },
  {
    id: 'betweenDate',
    label: 'วันที่จัดอบรม',
  },
  {
    id: 'hours',
    label: 'จำนวนชั่วโมง',
  },
  {
    id: 'result',
    label: 'สถานะคลาส',
  },
  {
    id: 'remark',
    label: 'หมายเหตุ',
  },
]
