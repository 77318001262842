import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { ErrorOutline } from '@mui/icons-material'

const type = [
  {
    name: 'select',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ตกลง',
    color: 'primary',
  },
  {
    name: 'save',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'บันทึก',
    color: 'primary',
  },
  {
    name: 'saveExam',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
  {
    name: 'delete',
    icon: '',
    title: 'ยืนยันลบข้อมูล',
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ลบ',
    color: 'error',
  },
  {
    name: 'success',
    icon: <CheckCircleOutlinedIcon color="primary" />,
    title: 'สำเร็จ',
    content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'primary',
  },
  {
    name: 'fail',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'ผิดพลาด',
    content: 'ระบบขัดข้อง',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
  },
  {
    name: 'fail overlapping',
    icon: <ErrorOutline color="error" sx={{ fontSize: '32px' }} />,
    title: 'ผิดพลาด',
    content: 'ระบบขัดข้อง',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
  },
  {
    name: 'timeFail',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'ผิดพลาด',
    content: 'เวลาผิดพลาด',
    disagreeText: '',
    agreeText: 'ตกลง',
    color: 'error',
  },
  {
    name: 'reject',
    icon: '',
    title: 'ไม่อนุมัติ',
    content: 'คุณต้องการไม่อนุมัติรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
  {
    name: 'approve',
    icon: '',
    title: 'อนุมัติ',
    content: 'คุณต้องการอนุมัติรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
  {
    name: 'cancel',
    icon: '',
    title: 'ยืนยันยกเลิกคำขอ',
    content: 'คุณต้องการยกเลิกคำขอรายการนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'บันทึก',
    color: 'error',
  },
  {
    name: '',
    icon: '',
    title: '',
    content: '',
    disagreeText: 'ยกเลิก',
    agreeText: 'ยืนยัน',
    color: 'primary',
  },
]

export const handleOnClose = (dialogType, handleClose) => {
  if (!(dialogType.name === 'fail' || dialogType.name === 'success'))
    handleClose && handleClose()
}
const AlertDialog = (props) => {
  const {
    open,
    handleClose,
    title,
    content,
    variant,
    onConfirmClick,
    onCancelClick,
    isLoading,
    maxWidth,
    agreeText,
  } = props
  const dialogType = type.find((item) => item.name === variant)
  return (
    <Dialog
      data-testid="alert-dialog"
      fullWidth={true}
      maxWidth={!maxWidth ? 'xs' : maxWidth}
      open={open}
      onClose={() => handleOnClose(dialogType, handleClose)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mx: 2,
          mt: 2,
          mb: 1,
        }}
      >
        <Box sx={{ lineHeight: 2.5 }}>
          <div>{dialogType.icon !== '' && dialogType.icon}</div>
        </Box>
        <Typography sx={{ ml: 1 }} variant="h6">
          {title ? title : dialogType.title}
        </Typography>
      </Box>

      {dialogType.name === 'select' ? (
        content
      ) : (
        <Typography sx={{ mx: 3 }} variant="body1">
          {content && <>{content}</>}
          {!content && <>{dialogType.content}</>}
        </Typography>
      )}
      <DialogActions>
        {dialogType.disagreeText && (
          <Button
            data-testid="btn-cancel-dialog"
            sx={{ mx: 1, mb: 1, minWidth: 100 }}
            variant="outlined"
            onClick={onCancelClick}
            color={dialogType.color}
          >
            {dialogType.disagreeText}
          </Button>
        )}
        <LoadingButton
          data-testid="btn-confirm-dialog"
          loading={isLoading}
          sx={{
            mx: variant === 'fail overlapping' ? '16px' : 1,
            mb: 1,
            minWidth: 100,
          }}
          variant="contained"
          onClick={onConfirmClick}
          loadingPosition="end"
          color={dialogType.color}
          endIcon={<></>}
        >
          {agreeText ? agreeText : dialogType.agreeText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
export default AlertDialog
