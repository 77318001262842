import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

import Select from '../../../../../../../../components/Input/Select'
import { listTypeOfAnswers } from '../../../../../../../../constants/eEvaluation'

import { listNumberOfAnswers, handleChange } from './events'

import { BoxContent } from './Styled'

const Index = ({ props }) => {
  const dispatch = useDispatch()

  const { typeOfAnswers, numberOfAnswers, answers } = props.itemQuestion

  return (
    <BoxContent>
      <Box sx={{ display: 'flex', width: 135, mt: 1 }}>
        <Typography variant="body1">กำหนดจำนวนเลือกตอบ</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ width: 115 }}>
          <Select
            disabled={props.disableForm}
            dataTestid="select-typeOfAnswers"
            id="typeOfAnswers"
            name="typeOfAnswers"
            labelText=""
            type="basic"
            options={listTypeOfAnswers}
            handleChange={(event) =>
              dispatch(
                handleChange({
                  ...props,
                  key: 'typeOfAnswers',
                  value: _.get(event, 'target.value', ''),
                })
              )
            }
            value={typeOfAnswers}
          />
        </Box>

        <Box sx={{ width: 80 }}>
          <Select
            disabled={props.disableForm}
            dataTestid="select-numberOfAnswers"
            id="numberOfAnswers"
            name="numberOfAnswers"
            labelText=""
            type="basic"
            options={listNumberOfAnswers(answers)}
            handleChange={(event) =>
              dispatch(
                handleChange({
                  ...props,
                  key: 'numberOfAnswers',
                  value: Number(_.get(event, 'target.value', 0)),
                })
              )
            }
            value={numberOfAnswers}
          />
        </Box>
      </Box>
    </BoxContent>
  )
}

export default Index
