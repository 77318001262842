import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { breadcrumbStation } from '../../Module/Lib/constanst'
import { StyledHeader, StyledHeaderContainer } from '../../Module/Lib/CSS/Styled'
import ButtonAddStation from './ButtonAddStation'

const Index = () => {
  return (
    <StyledHeader>
      <Box>
        <Typography variant="h4">สเตชั่น (Station)</Typography>
        <Breadcrumbs menuList={breadcrumbStation()} />
      </Box>
      <StyledHeaderContainer>
        <ButtonAddStation />
      </StyledHeaderContainer>
    </StyledHeader>
  )
}

export default Index
