export const MODULE_TYPE_EN = {
  E_TESTING: 'E-Testing',
  E_EVALUATION: 'E-Evaluation',
  EMBED_CONTENT: 'Embed content',
  LINK_TO_ANOTHER_WEBSITE: 'Link to another website',
  UPLOAD_FILE: 'Upload file',
}

export const MODULE_TYPE_TH = {
  E_TESTING: 'แบบทดสอบ',
  E_EVALUATION: 'แบบประเมิน',
  EMBED_CONTENT: 'ฝังเนื้อหา',
  LINK_TO_ANOTHER_WEBSITE: 'ลิงก์ไปยังอีกเว็บไซต์อื่น',
  UPLOAD_FILE: 'อัปโหลดไฟล์',
  SCORM: 'Scorm',
}
