import React, { useState, useRef, useEffect } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TextWithTruncate from './TextWithTruncate'

const Truncate = ({ children, align }) => {
  const [expand, setExpand] = useState(false)
  const [isTruncated, setTruncate] = useState(false)
  const [flag, setFlag] = useState(false)
  const [width, setWidth] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [width])

  useEffect(() => {
    setExpand(false)
    setFlag((prev) => !prev)
  }, [children])

  return (
    <div ref={ref}>
      {!expand && flag && (
        <TextWithTruncate
          align={align}
          width={width}
          isTruncated={isTruncated}
          setTruncate={setTruncate}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {!expand && !flag && (
        <TextWithTruncate
          align={align}
          width={width}
          isTruncated={isTruncated}
          setTruncate={setTruncate}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {expand && (
        <div
          onClick={() => setExpand(false)}
          style={{
            maxWidth: width,
            display: 'flex',
            flexDirection: 'row',
            cursor: isTruncated ? 'pointer' : 'default',
            wordBreak: 'break-all',
            justifyContent: align ? align : 'flex-start',
          }}
        >
          {children} {isTruncated && <KeyboardArrowUpIcon />}
        </div>
      )}
    </div>
  )
}

export default Truncate
