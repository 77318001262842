import React from 'react'
import _ from 'lodash'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import Carousel from '../../../../../components/Carousel'
import {
  StyledHeaderContent,
  StyledHomeHeader,
  StyledImagePreview,
  StyledOverlayBg,
  StyledSecondaryButton,
  StyledPrimaryButton,
  StyledTypography,
  StyledVideoPreview,
} from './Styled'
import { handleButtonSize, handleClickBtn } from './events'
import { Box } from '@mui/material'
import { Row } from '../../../../../components/Layout/Styled'

const HomeHeader = ({ bannerSection }) => {
  const bannerList = bannerSection.filter((item) => item.status)
  return (
    <StyledHomeHeader>
      <Carousel>
        {bannerList.length > 0 &&
          bannerList.map((item, index) => (
            <BannerMedia key={index} data={item} />
          ))}
      </Carousel>
    </StyledHomeHeader>
  )
}

export default HomeHeader

export const BannerMedia = ({ data }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const mediaType = _.get(data, 'mediaType', '')
  const isVideo = mediaType == 'VIDEO'
  const image = _.get(data, 'image', '')
  const mobileImage = isVideo
    ? _.get(data, 'videoImg', '')
    : _.get(data, 'mobileImg', '')
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file${
    isNotSm ? image : mobileImage
  }`
  return (
    <Box sx={{ height: isNotSm ? 500 : 360, width: '100%' }}>
      <StyledOverlayBg />
      {isVideo && isNotSm ? (
        <StyledVideoPreview url={_.get(data, 'videoURL', '')} />
      ) : (
        <StyledImagePreview src={imageSrc} />
      )}
      {<HeaderContent data={data} />}
    </Box>
  )
}

export const HeaderContent = ({ data }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const btnSize = handleButtonSize()
  return (
    <StyledHeaderContent className="testing content">
      <Box>
        <StyledTypography
          variant={isLg ? 'h6' : 'body2b'}
          sx={{ letterSpacing: 0.15, lineHeight: 1.2 }}
          color={'text.white'}
          dangerouslySetInnerHTML={{ __html: _.get(data, 'subtitle', '') }}
        />
        <StyledTypography
          variant={isLg ? 'h2' : 'h4'}
          sx={{ letterSpacing: -0.5, lineHeight: 1 }}
          color={'text.white'}
          dangerouslySetInnerHTML={{ __html: _.get(data, 'title', '') }}
        />
      </Box>
      <StyledTypography
        id="content"
        color={'text.white'}
        sx={{ letterSpacing: 0.15, lineHeight: 1.2 }}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'description', '') }}
      />
      <Row sx={{ justifyContent: !isNotSm && 'center' }}>
        {data?.primaryStatus && (
          <StyledPrimaryButton
            size={btnSize}
            sx={{ width: isNotSm ? 200 : 'auto' }}
            data-testid="btn-primary"
            variant="contained"
            color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
            onClick={() => handleClickBtn(_.get(data, 'primaryButtonURL', ''))}
          >
            {_.get(data, 'primaryButtonTitle', '')}
          </StyledPrimaryButton>
        )}
        {data?.secondaryStatus && (
          <StyledSecondaryButton
            size={btnSize}
            data-testid="btn-secondary"
            variant="outlined"
            onClick={() =>
              handleClickBtn(_.get(data, 'secondaryButtonURL', ''))
            }
          >
            {_.get(data, 'secondaryButtonTitle', '')}
          </StyledSecondaryButton>
        )}
      </Row>
    </StyledHeaderContent>
  )
}
