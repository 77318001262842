import dayjs from 'dayjs'
import _ from 'lodash'
import { findDepartmentAndStation } from '../../../../../utils/lib'
import { fetchHeadZone } from '../../../api/Index'

export const formatDate = (values, key) => {
  return dayjs(_.get(values, key, '')).format(
    window.__env__.REACT_APP_DATE_SHOW
  )
}

export const handleDepartmentUuid = (values) => {
  const departmentAndStation = findDepartmentAndStation(
    _.get(values, 'approver.roleAreaInfo.area', []),
    _.get(values, 'station', '') || _.get(values, 'approver.station.uuid', ''),
    false
  )
  return (
    _.get(values, 'department.value', '') ||
    _.get(departmentAndStation, 'department.uuid', '')
  )
}

export const findOptionStation = (listDepartment, departmentUuid) => {
  let result = []
  if (listDepartment.length) {
    const findDepartment = listDepartment.find(
      (item) => item.uuid === departmentUuid
    )
    result = findDepartment ? _.get(findDepartment, 'station', []) : []
  }
  return result
}

export const handleApproverShow = (values) => {
  const approver = _.get(values, 'approver', []) || []

  if (approver.length === 0) return '-'

  const approverText = _.join(
    approver.map(
      (item) =>
        `${_.get(item, 'firstNameTH', '')} ${_.get(item, 'lastNameTH', '')}`
    ),
    ', '
  )
  return approverText
}

export const handleFetchHeadZone = async (props) => {
  const { station, optionStation, setValues } = props
  if (!_.isEmpty(station)) {
    const stationObj = optionStation.find((item) => item.value === station)
    const approverList = await fetchHeadZone(_.get(stationObj, 'area', ''))
    setValues((prev) => ({ ...prev, approver: approverList }))
  }
}
