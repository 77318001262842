import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import Background from './../../../components/Preview/Background'
import Content from './../../../components/Preview/Content'
import { path } from '../../../constants/path'
import {
  StyledNoPreviewImage,
  StyledPreviewContainer,
  StyledPreviewContent,
  StyledPreviewImage,
} from './../../../components/Preview/Styled'

const Preview = ({ isModule }) => {
  const { id: uuid } = useParams()
  const [eEvaluation] = useLocalStorage('eEvaluation')
  const information = _.get(eEvaluation, 'information', undefined)
  const coverImage = _.get(information, 'coverImage', undefined)
  const coverText = _.get(information, 'coverText', undefined)
  const pathEdit = `${path.eEvaluation}/version/edit/${uuid}`
  const pathTest = `${path.eEvaluation}/preview/evaluate/${uuid || 'on-create'}`

  useEffect(() => {
    if (_.isNil(eEvaluation)) window.location.href = pathEdit
  }, [])

  return (
    <Background>
      {eEvaluation && (
        <StyledPreviewContainer>
          <StyledPreviewContent>
            <Box sx={{ position: 'relative' }}>
              {coverText && (
                <Typography variant="coverText" color="primary">
                  {coverText}
                </Typography>
              )}
              {coverImage && (
                <StyledPreviewImage
                  src={`${window.__env__.REACT_APP_API_URL}/file${coverImage}`}
                />
              )}
              {coverText && !coverImage && <StyledNoPreviewImage />}
            </Box>
            <Content
              information={information}
              url={pathTest}
              isModule={isModule}
              buttonText="เริ่มทำแบบประเมิน"
            />
          </StyledPreviewContent>
        </StyledPreviewContainer>
      )}
    </Background>
  )
}

export default Preview
