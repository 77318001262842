import { IconButton } from '@mui/material'
import { DeleteTwoTone } from '@mui/icons-material'
import Equipment from './Equipment'
import { AdditionalEquipment } from './AdditionalEquipment'
import { NumberOfAttendees } from './NumberOfAttendees'
import { RoomLayout } from './RoomLayout'
import { MeetingRoomSelect } from './MeetingRoomSelect'
import { MeetingRoomOther } from './MeetingRoomOther'
import { GroupSection } from './GroupSection'
import { memo } from 'react'
import { StyledRow, StyledColumn } from '../Styled'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleDeleteRoom } from '../events'
import Box from '@mui/material/Box'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'

export const DetailsRoom = memo((props) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const { idx, cardIndex, roomSelected, meetingRoomByDateDate, bookingDate } =
    props

  const { monthlyPlan, roomManagement } = useSelector(
    (state) => ({
      monthlyPlan: state.manageClassForm.data.monthlyPlan,
      roomManagement: state.manageClassForm.data.roomManagement,
    }),
    shallowEqual,
  )
  const isOther = _.get(roomSelected, 'uuid') === 'OTHERS'
  const roomLayout = _.get(
    meetingRoomByDateDate,
    'roomLayout',
    '',
  ).toUpperCase()
  const additionalEquipment = _.get(
    meetingRoomByDateDate,
    'additionalEquipment',
    '',
  )
  const validateIndex = _.findIndex(
    _.get(roomManagement, 'roomManagementMeetingRoom', []),
    (room) => {
      return room === meetingRoomByDateDate
    },
  )

  return (
    <StyledColumn sx={{ gap: 1, mb: 3, width: '100%' }}>
      <StyledRow
        sx={{
          alignItems: 'stretch',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'end',
            pr: idx !== 0 ? 5 : 0.5,
          }}
          onClick={() => {
            window.open(
              `/room-management?date=${bookingDate}&calendar=1`,
              '_blank',
            )
          }}
        >
          <OpenInNewIcon
            color="primary"
            sx={{
              width: '18px',
              height: '18px',
              mr: 0.5,
            }}
          />
          <Typography variant={'body2'} color={'primary.main'}>
            ดูเพิ่มเติม
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <MeetingRoomSelect
            roomIndex={idx}
            roomAvailableOptions={_.get(
              monthlyPlan,
              `dates[${cardIndex}].roomAvailableOptions`,
              [],
            )}
            meetingRoomDetail={meetingRoomByDateDate}
            roomDetailIndex={idx}
            bookingDate={bookingDate}
            cardIndex={cardIndex}
            validateIndex={validateIndex}
            errors={errors}
          />
          {idx !== 0 && (
            <IconButton
              onClick={() => dispatch(handleDeleteRoom(idx, bookingDate))}
            >
              <DeleteTwoTone color="action.active" />
            </IconButton>
          )}
        </Box>
      </StyledRow>
      {isOther && (
        <MeetingRoomOther
          meetingRoomOther={_.get(meetingRoomByDateDate, 'meetingRoomOther')}
          roomDetailIndex={idx}
          errors={errors}
          bookingDate={bookingDate}
          validateIndex={validateIndex}
        />
      )}

      <StyledRow sx={{ gap: 2, pl: 10, alignItems: 'stretch' }}>
        <NumberOfAttendees
          numberOfAttendees={_.get(meetingRoomByDateDate, 'numberOfAttendees')}
          roomDetailIndex={idx}
          errors={errors}
          bookingDate={bookingDate}
          validateIndex={validateIndex}
        />
        <RoomLayout
          roomLayout={roomLayout}
          roomDetailIndex={idx}
          errors={errors}
          meetingRoomDetail={meetingRoomByDateDate}
          bookingDate={bookingDate}
        />
      </StyledRow>
      <GroupSection
        roomLayout={roomLayout}
        meetingRoomDetail={meetingRoomByDateDate}
        roomDetailIndex={idx}
        errors={errors}
        bookingDate={bookingDate}
        validateIndex={validateIndex}
      />
      <Equipment
        roomEquipmentDetail={_.get(meetingRoomByDateDate, 'roomEquipments', {})}
        roomDetailIndex={idx}
      />
      <AdditionalEquipment
        additionalEquipment={additionalEquipment}
        roomDetailIndex={idx}
        errors={errors}
        bookingDate={bookingDate}
        validateIndex={validateIndex}
      />
    </StyledColumn>
  )
})
DetailsRoom.displayName = 'DetailsRoom'
