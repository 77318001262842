import { createSlice } from '@reduxjs/toolkit'
import { initialApproveObj, initialState } from './model'
import { handleValidateForm } from './events'

const speaker = createSlice({
  name: 'speaker',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setSpeakerType: (state, { payload }) => {
      state.speakerType = payload
    },
    setInitialData: (state, { payload }) => {
      state.data = payload
    },
    setStaffList: (state, { payload }) => {
      state.staffList = payload
    },
    setInitialApproveList: (state, { payload }) => {
      state.approveList = payload
    },
    resetInitialData: (state) => {
      state.approveList = initialState.approveList
      state.data = initialState.data
      state.error = initialState.error
      state.errors = initialState.errors
      state.isLoading = initialState.isLoading
      state.isSubmit = initialState.isSubmit
      state.speakerType = initialState.speakerType
      state.staffList = initialState.staffList
    },
    setApproveObj: (state, { payload }) => {
      const { index, key, value } = payload
      state.approveList[index][key] = value

      if (key === 'status') {
        state.approveList[index].remark = ''
        state.approveList[index].staffs = []
        state.errors = {}
        state.isSubmit = false
      }
    },
    resetApproveObj: (state) => {
      state.approveList = state.approveList.map((item) => ({
        ...initialApproveObj,
        uuid: item.uuid,
      }))
      state.isSubmit = false
    },
    setValidate: (state) => {
      state.errors = handleValidateForm(state)
      state.isSubmit = true
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setSpeakerType,
  setInitialData,
  setStaffList,
  setInitialApproveList,
  resetInitialData,
  setApproveObj,
  resetApproveObj,
  setValidate,
} = speaker.actions

export default speaker.reducer
