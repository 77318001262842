import React from 'react'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { StyledCircularBg } from '../stlyed'
import { Box } from '@mui/system'

export const ConvertProgressCircular = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ position: 'relative', width: 22, height: 22 }}>
        <StyledCircularBg size={22} />
        <CircularProgress size={22} sx={{ position: 'absolute', left: 0 }} />
      </Box>
      <Typography variant="body3b">กำลังแปลงไฟล์ ...</Typography>
    </Box>
  )
}
