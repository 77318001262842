import React from 'react'
import _ from 'lodash'
import { openDialog } from '../../../../redux/slices/dialog'
import { startLoading, stopLoading } from '../../../../redux/slices/table'
import * as service from '../../../../services/apiToRcms/index'
import CheckDialog from '../CheckDialog'
import { checkUpload, formatAgentCode } from '../../../../utils/lib'

export const handleInputFile = (e, fetchData) => async (dispatch) => {
  dispatch(startLoading())
  const body = new FormData()
  body.append('file', e.target.files[0])
  const checkFile = dispatch(
    checkUpload({
      events: e,
      type: 'excel',
      id: 'btn-add-file',
    })
  )

  if (checkFile) {
    dispatch(stopLoading())
    return
  }

  const response = await dispatch(service.uploadFile(body))
  if (!response?.error) {
    const uploadResult = _.get(response, 'payload.data', {})
    const objResult = []
    const result = uploadResult?.result ?? []
    for (const item of result) {
      const res = await dispatch(service.uploadCheck({ RcmsDetail: item }))
      const resultCheck = _.get(res, 'payload.data', {})
      const FormatAgentCode = formatAgentCode(resultCheck?.agentCode)

      const isDuplicate = _.filter(
        objResult,
        (item) => item.agentCode === FormatAgentCode
      )
      if (!_.isEmpty(isDuplicate)) {
        objResult.push({ ...resultCheck, status: 'DUPLICATE' })
        continue
      }
      objResult.push(resultCheck)
    }

    const STATUS = _.isEmpty(
      _.filter(objResult, (item) => item.status !== 'SUCCESS')
    )

    const upload = {
      status: _.isEmpty(objResult) || !STATUS ? 'ERROR' : 'SUCCESS',
      result: objResult,
      totalCount: objResult.length,
    }

    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'md',
        disableButton: true,
        content: <CheckDialog uploadResult={upload} fetchData={fetchData} />,
      })
    )
  }
  _.set(document.getElementById('btn-add-file'), 'value', null)
  dispatch(stopLoading())
}
