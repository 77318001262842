import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledViewTitle = styled(Typography)(({ theme }) => ({
  width: 'calc(100% - 340px)',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('lg')]: { width: '100%' },
}))
