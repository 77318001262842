import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'ACCOUNT_CODE',
  moduleSlug: 'account-code',
  moduleTitleFull: 'Account Code',
  modulesTitleShort: 'Account Code',
  modulePath: '/master-data/account-code',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
