import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { StyledCircularBox } from "../Styled"

const CircularContent = () => {
  return (
    <StyledCircularBox>
      <CircularProgress />
    </StyledCircularBox>
  )
}

export default CircularContent
