import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { path } from '../../../constants/path'
import Background from './../../../components/Preview/Background'
import {
  StyledNoPreviewImage,
  StyledPreviewContainer,
  StyledPreviewContent,
  StyledPreviewImage,
} from './../../../components/Preview/Styled'
import Content from './../../../components/Preview/Content'

const Preview = ({ isModule }) => {
  const { id: uuid } = useParams()
  const [eTesting] = useLocalStorage('eTesting')
  const { information } = eTesting
  const coverImage = _.get(information, 'coverImage', undefined)
  const coverText = _.get(information, 'coverText', undefined)
  const pathEdit = `${path.eTesting}/version/edit/${uuid}`
  const pathTest = `${path.eTesting}/preview/testing/${uuid || 'on-create'}`

  useEffect(() => {
    if (_.isNil(eTesting)) window.location.href = pathEdit
  }, [])

  return (
    <Background>
      {eTesting && (
        <StyledPreviewContainer>
          <StyledPreviewContent>
            <Box sx={{ position: 'relative' }}>
              {coverText && (
                <Typography variant="coverText" color="primary">
                  {coverText}
                </Typography>
              )}
              {coverImage && (
                <StyledPreviewImage
                  src={`${window.__env__.REACT_APP_API_URL}/file${coverImage}`}
                />
              )}
              {coverText && !coverImage && <StyledNoPreviewImage />}
            </Box>
            <Content
              information={information}
              url={pathTest}
              isModule={isModule}
              buttonText="เริ่มทำแบบทดสอบ"
            />
          </StyledPreviewContent>
        </StyledPreviewContainer>
      )}
    </Background>
  )
}

export default Preview
