import React from 'react'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'

const ButtonAddStation = () => {
  const history = useHistory()
  const url = '/master-data/station/form'

  return (
    <Button
      data-testid="btn-add-station"
      variant="contained"
      size="m"
      startIcon={<Add />}
      onClick={async () => {
        history.push(url)
      }}
    >
      เพิ่มสเตชั่น
    </Button>
  )
}

export default ButtonAddStation
