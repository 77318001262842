import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'
import { getQueryParams } from '../../../utils/lib'
import dayjs from 'dayjs'

export const fetchSearchParamsAgentProfile = () => async (dispatch) => {
  let startDate = getQueryParams('start_date')
  let endDate = getQueryParams('end_date')
  let agentRole = getQueryParams('agent_role')
  let distribution = getQueryParams('distribution')
  let status = getQueryParams('status')
  const level = getQueryParams('level')
  const filter = []
  let id = 1
  if (startDate && endDate) {
    filter.push({
      id: id,
      key: 'lastLogin',
      label: `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(
        endDate,
      ).format('DD/MM/YYYY')}`,
      title: 'เข้าสู่ระบบล่าสุด',
      typeOfValue: 'DATE_RANGE',
      value: { startDate, endDate },
    })
    id++
  }
  if (agentRole) {
    agentRole = agentRole.replace('agent', 'Agent')
    agentRole = agentRole.replace('manager', 'Manager')
    const agentRoleData = agentRole.split(',')
    filter.push({
      id: id,
      key: 'agentRole',
      label: agentRole,
      title: 'Agent Role',
      typeOfValue: 'DROPDOWN_MULTI',
      value: agentRoleData,
    })
    id++
  }
  if (distribution) {
    distribution = distribution.replace('agency', 'Agency')
    distribution = distribution.replace('cs', 'Corporate Solutions')
    const distributionData = distribution.split(',')
    filter.push({
      id: id,
      key: 'distribution',
      label: distribution,
      title: 'ช่องทางการจัดจำหน่าย',
      typeOfValue: 'DROPDOWN_MULTI',
      value: distributionData,
    })
    id++
  }
  if (status) {
    status = status.toUpperCase()
    filter.push({
      id: id,
      key: 'agentStatus',
      label: status.replace('INACTIVE', 'Terminate'),
      title: 'สถานะการทำงาน',
      typeOfValue: 'INPUT_TEXT',
      value: status,
    })
    id++
  }
  if (level) {
    const levelData = level.split(',')
    filter.push({
      id: id,
      key: 'level',
      label: level,
      title: 'ระดับผู้เรียน',
      typeOfValue: 'INPUT_TEXT',
      value: levelData,
    })
    id++
  }

  if (filter.length > 0) {
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: filter,
      }),
    )
    dispatch(handleSaveFilters())
  }
}
