import Tabs from '@mui/material/Tabs'
import styled from '@mui/system/styled'

export const StyledTab = styled(Tabs)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    '& .MuiTab-root': {
      width: '50%',
    },
  },
}))
