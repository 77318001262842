import axios from 'axios'
import { eCertificationDownloadProfile } from '../../../../../../src/utils/apiPath'

export const onDownload =
  (uuid, startLoading, stopLoading) => async (dispatch) => {
    dispatch(startLoading())
    await axios
      .get(`${eCertificationDownloadProfile}/${uuid}`, { responseType: 'blob' })
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `e-certification.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => dispatch(stopLoading()))
      .finally(() => dispatch(stopLoading()))
  }
