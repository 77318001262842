import _ from 'lodash'

export const handleDetailCount = (eLearningCourseLesson) => {
  let moduleCount = 0
  _.forEach(eLearningCourseLesson, (item) => {
    const eLearningLessonRefModule = _.get(item, 'eLearningLessonRefModule', [])
    moduleCount = moduleCount + eLearningLessonRefModule.length
  })

  return {
    lessonCount: eLearningCourseLesson.length,
    moduleCount,
  }
}
