import { crudConfig } from '../crudConfig'

export const breadcrumb = [
  { title: 'Inventory', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: '/',
    pointer: false,
  },
]
