import {
  startLoading,
  stopLoading,
  setImportExamierFile,
  resetExamierFile,
  setExamierFileUploadResult,
} from '../../../redux/slices/eExamination/list'
import { loadingDialog, openDialog } from '../../../redux/slices/dialog'
import _ from 'lodash'
import {
  uploadExaminationStudent,
  updateStudentResult,
} from '../../../services/eExamination'
import CheckDialog from './CheckDialog'
import { IMPORT_EXAMINER_TYPE } from '../../../constants/eExamination'
import { downloadFile } from '../../../services/util'
import { examinationStudentResultTemplateDownload } from '../../../utils/apiPath'

export const onUploadTemplate = (dataImport) => (dispatch) => {
  dispatch(startLoading())
  dispatch(setImportExamierFile(dataImport))

  setTimeout(() => {
    dispatch(stopLoading())
  }, 500)
}

export const handleSubmitAdd =
  (uuid, file, importExaminerType) => async (dispatch) => {
    dispatch(loadingDialog())
    const formData = new FormData()
    formData.append('file', file)
    formData.append('uuid', uuid)
    let response
    if (importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT) {
      response = await dispatch(uploadExaminationStudent(formData))
    } else {
      response = await dispatch(updateStudentResult(formData))
    }
    const results = _.get(response, 'payload.data', {})
    dispatch(setExamierFileUploadResult(results))
    dispatch(resetExamierFile())
    if (_.isNil(response.error)) {
      dispatch(
        openDialog({
          type: 'mini',
          maxWidth:
            importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT
              ? 'xl'
              : 'md',
          disableButton: true,
          content: <CheckDialog />,
        }),
      )
    }
  }

export const handleDownloadStudentResultTemplate =
  (uuid) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      uuid,
    }
    await dispatch(
      downloadFile({
        url: examinationStudentResultTemplateDownload,
        body: body,
        fileName: `StudentResultTemplateDownload.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }
