import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Table from '../../../../components/redux/Table'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from '../../Styled'
import FilterDrawer from '../../FilterDrawer'
import { breadcrumbVersionDashboard } from '../../model'

const Version = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter, handleSearch } = useSelector(
    (state) => state.table,
  )
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const { id: codeId } = useParams()

  useEffect(() => {
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        codeId,
        handleSearch,
        setInitial,
      ),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList(
          'fetch',
          table,
          page,
          status,
          filterProp,
          searchText,
          codeId,
          handleSearch,
        ),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">
            {breadcrumbVersionDashboard[2].title}
          </Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbVersionDashboard} />

        <StyledCard id="versionTable">
          <Table />
        </StyledCard>

        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="versions"
          isDashboard={true}
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Version
