import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  addRoom,
  changeBookingOperator,
  changeToAcademy,
  changeToNonAcademy,
  setChangeBookingInformation,
  setOverTimeOption,
  setPeriodByDate,
  setReduxValue,
  setRoom,
  setRoomBookingList,
  setRoomBookingListByDate,
  setTimeByDate,
  setBookingInformation,
} from '.'
import {
  buildDefaultBookingItem,
  getBookingListByPeriod,
  handleEditTrainingTime,
  reBuildDefaultBookingItem,
  shouldSetRoomTime,
  validatePeriodDate,
  validateTimeTraining,
} from '../../../../modules/RoomManagement/BookingDrawer/event'
import {
  overTimeOptions,
  PERIOD_TYPE,
} from '../../../../constants/roomManagement'
import { meetingRoom } from '../../../../utils/apiPath'
import axios from 'axios'
import { FetchMasterRoomsByDay } from './event'
import _ from 'lodash'
import { array, number, object, string } from 'yup'
import { saveDebounce } from '../../../../utils/lib'

export const listenerMiddleware1 = createListenerMiddleware()

async function handleNextButton(currentState, originalState, listenerApi) {
  let validateSchema = object().shape({
    bookingTopic: string().required(),
    bookForUuid: string().when('academy', {
      is: (val) => val === 'ACADEMY',
      then: (schema) => schema.required(),
    }),
    bookForOther: string()
      .nullable()
      .when('academy', {
        is: (val) => val === 'NON_ACADEMY',
        then: (schema) => schema.required(),
      }),
    mobileNoOther: string().required(),
    emailOther: string().required(),
    startDate: string().required(),
    endDate: string().required(),
    remark: string()
      .nullable()
      .test('len', 'กรอกได้ไม่เกิน 3,000 ตัวอักษร', (val) => {
        if (_.isEmpty(val)) return true
        return val.toString().length <= 3000
      }),
    roomBookingList: array().of(
      object().shape({
        rooms: array().of(
          object().shape({
            meetingRoomUuid: string().required(),
            meetingRoomOther: string()
              .nullable()
              .when('meetingRoomUuid', {
                is: (val) => val === 'OTHER',
                then: (schema) => schema.required(),
              }),
            numberOfGroup: number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .when('roomLayout', {
                is: (val) => val === 'GROUP',
                then: (schema) => schema.required().min(1),
              }),
            attendeeInGroup: number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .when('roomLayout', {
                is: (val) => val === 'GROUP',
                then: (schema) => schema.required().min(1),
              }),
          }),
        ),
      }),
    ),
  })

  try {
    await validateSchema.validate(currentState.bookingInformation)
    if (originalState.disabledBtnNext) {
      saveDebounce(() =>
        listenerApi.dispatch(
          setReduxValue({ key: 'disabledBtnNext', value: false }),
        ),
      )
    }
  } catch (err) {
    console.log(err)
    if (!originalState.disabledBtnNext) {
      saveDebounce(() => {
        listenerApi.dispatch(
          setReduxValue({ key: 'disabledBtnNext', value: true }),
        )
      })
    }
  }
}

async function handleFormInput(currentState, originalState, listenerApi) {
  let validateInputSchema = object().shape({
    bookingTopic: string()
      .nullable()
      .test('len', 'กรอกได้ไม่เกิน 255 ตัวอักษร', (val) => {
        if (_.isEmpty(val)) return true
        return val.toString().length <= 255
      }),
    remark: string()
      .nullable()
      .test('len', 'กรอกได้ไม่เกิน 3,000 ตัวอักษร', (val) => {
        if (_.isEmpty(val)) return true
        return val.toString().length <= 3000
      }),
    roomBookingList: array().of(
      object().shape({
        rooms: array().of(
          object().shape({
            additionalEquipment: string()
              .nullable()
              .test('len', 'กรอกได้ไม่เกิน 3,000 ตัวอักษร', (val) => {
                if (_.isEmpty(val)) return true
                return val.toString().length <= 3000
              }),
            numberOfAttendees: string()
              .nullable()
              .test('len', 'ไม่สามารถกรอกข้อมูลได้', (val) => {
                if (_.isEmpty(val)) return true
                return val >= 1
              })
              .test('len', 'กรอกได้ไม่เกิน 4 ตัวอักษร', (val) => {
                if (_.isEmpty(val)) return true
                return val.toString().length <= 4
              }),
            numberOfGroup: number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .when('roomLayout', {
                is: (val) => val === 'GROUP',
                then: (schema) =>
                  schema
                    .min(1, 'ไม่สามารถกรอกข้อมูลได้')
                    .test('len', 'กรอกได้ไม่เกิน 4 ตัวอักษร', (val) => {
                      if (!val) return true
                      return String(val).length <= 4
                    }),
              }),
            attendeeInGroup: number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .when('roomLayout', {
                is: (val) => val === 'GROUP',
                then: (schema) =>
                  schema
                    .min(1, 'ไม่สามารถกรอกข้อมูลได้')
                    .test('len', 'กรอกได้ไม่เกิน 4 ตัวอักษร', (val) => {
                      if (!val) return true
                      return String(val).length <= 4
                    }),
              }),
            meetingRoomOther: string()
              .nullable()
              .when('meetingRoomUuid', {
                is: (val) => val === 'OTHER',
                then: (schema) =>
                  schema.test('len', 'กรอกได้ไม่เกิน 255 ตัวอักษร', (val) => {
                    if (_.isEmpty(val)) return true
                    return val.toString().length <= 255
                  }),
              }),
          }),
        ),
      }),
    ),
    bookForOther: string()
      .nullable()
      .when('academy', {
        is: (val) => val === 'NON_ACADEMY',
        then: (schema) =>
          schema.test('len', 'กรอกได้ไม่เกิน 255 ตัวอักษร', (val) => {
            if (_.isEmpty(val)) return true
            return val.toString().length <= 255
          }),
      }),
    mobileNoOther: string()
      .nullable()
      .when('academy', {
        is: (val) => val === 'NON_ACADEMY',
        then: (schema) =>
          schema.test('len', 'กรอกได้ไม่เกิน 20 ตัวอักษร', (val) => {
            if (_.isEmpty(val)) return true
            return val.toString().length <= 20
          }),
      }),
    emailOther: string()
      .nullable()
      .when('academy', {
        is: (val) => val === 'NON_ACADEMY',
        then: (schema) =>
          schema.test('len', 'กรอกได้ไม่เกิน 255 ตัวอักษร', (val) => {
            if (_.isEmpty(val)) return true
            return val.toString().length <= 255
          }),
      }),
  })

  const validaResults = await validateInputSchema
    .validate(currentState.bookingInformation, { abortEarly: false })
    .catch((e) => e)

  if (_.isEmpty(validaResults.inner)) {
    if (!_.isEmpty(originalState.formError)) {
      listenerApi.dispatch(setReduxValue({ key: 'formError', value: [] }))
    }
  } else {
    listenerApi.dispatch(
      setReduxValue({ key: 'formError', value: validaResults.inner }),
    )
  }
}

listenerMiddleware1.startListening({
  matcher: isAnyOf(
    setChangeBookingInformation,
    changeBookingOperator,
    setRoom,
    addRoom,
    changeToAcademy,
    changeToNonAcademy,
    setBookingInformation,
  ),

  effect: async (action, listenerApi) => {
    const currentState = listenerApi.getState().roomManagementDrawer
    const originalState = listenerApi.getOriginalState().roomManagementDrawer

    await handleNextButton(currentState, originalState, listenerApi)
    await handleFormInput(currentState, originalState, listenerApi)
  },
})

/*
 *
 *  1 "startDate" and "EndDate" selected
 *  2 "bookingPeriod" changed
 *
 * */
listenerMiddleware1.startListening({
  matcher: isAnyOf(setChangeBookingInformation, setReduxValue),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState().roomManagementDrawer

    const changeKey = _.get(action.payload, 'key', null)

    if (
      changeKey === 'startDate' ||
      changeKey === 'endDate' ||
      changeKey === 'bookingInformation'
    ) {
      validatePeriodDate(state, listenerApi)
      const { bookingInformation } = state
      const bookingList = await buildDefaultBookingItem({ bookingInformation })
      listenerApi.dispatch(setRoomBookingList(bookingList))
    }

    if (changeKey === 'bookingPeriod') {
      const bookingList = await reBuildDefaultBookingItem(
        state,
        action.payload.value,
      )
      listenerApi.dispatch(setRoomBookingList(bookingList))
    }
    const currentState = listenerApi.getState().roomManagementDrawer
    const originalState = listenerApi.getOriginalState().roomManagementDrawer
    await handleNextButton(currentState, originalState, listenerApi)
  },
})

/*
 *
 *  Open drawer with bookingUuid
 *
 * */
// listenerMiddleware1.startListening({
//   actionCreator: openEditFormDrawerBooking,
//   effect: async (action, listenerApi) => {
//     const state = listenerApi.getState().roomManagementDrawer
//     const bookingList = await reBuildDefaultBookingItem(
//       state,
//       state.bookingInformation.bookingPeriod,
//     )
//     if (bookingList.length) {
//       listenerApi.dispatch(setRoomBookingList(bookingList))
//     }
//   },
// })

listenerMiddleware1.startListening({
  actionCreator: setRoom,
  effect: async (action, listenerApi) => {
    const { bookingDate, roomIndex, key, value } = action.payload
    if (key === 'meetingRoomUuid') {
      listenerApi.dispatch(
        setRoom({
          key: 'isLoading',
          value: true,
          roomIndex,
          bookingDate,
        }),
      )
      let response = null
      if (value !== 'OTHER') {
        response = await axios
          .get(`${meetingRoom}/${value}`)
          .then((res) => res.data)
      }

      listenerApi.dispatch(
        setRoom({
          key: 'meetingRoom',
          value: response,
          roomIndex,
          bookingDate,
        }),
      )
      listenerApi.dispatch(
        setRoom({
          key: 'roomLayout',
          value: '',
          roomIndex,
          bookingDate,
        }),
      )
      listenerApi.dispatch(
        setRoom({
          key: 'isLoading',
          value: false,
          roomIndex,
          bookingDate,
        }),
      )
    }
  },
})

listenerMiddleware1.startListening({
  actionCreator: setTimeByDate,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState()

    const { bookingPeriod, roomBookingList } =
      state.roomManagementDrawer.bookingInformation

    const { bookingDate, key } = action.payload

    const idxChange = roomBookingList.findIndex(
      (item) => item.bookingDate === bookingDate,
    )
    const roomBookingItem = roomBookingList[idxChange]

    const { startTimeTraining, endTimeTraining } = roomBookingItem

    handleEditTrainingTime(roomBookingItem, key, listenerApi)

    shouldSetRoomTime(
      state.roomManagementDrawer,
      startTimeTraining,
      endTimeTraining,
      roomBookingItem,
      listenerApi,
    )
    const bookingList = getBookingListByPeriod({
      bookingPeriod,
      roomBookingList,
      idxChange,
    })
    for (const idx of bookingList) {
      if (key == 'startTimeTraining' || key == 'endTimeTraining') {
        validateTimeTraining(state, listenerApi, idx)
      }
    }
  },
})

listenerMiddleware1.startListening({
  actionCreator: setPeriodByDate,
  effect: async (action, listenerApi) => {
    const { stationUuid } = listenerApi.getState().roomManagement
    const state = listenerApi.getState().roomManagementDrawer
    const booking = state.bookingInformation
    const { bookingDate } = action.payload
    const idxChange = booking.roomBookingList.findIndex(
      (item) => item.bookingDate === bookingDate,
    )
    const roomBookingItem = booking.roomBookingList[idxChange]
    if (
      [
        PERIOD_TYPE.MORNING,
        PERIOD_TYPE.AFTERNOON,
        PERIOD_TYPE.MORNING_TO_EVENING,
      ].includes(roomBookingItem.period)
    ) {
      listenerApi.dispatch(
        setOverTimeOption({
          bookingDate,
          overTimeAfterOptions: overTimeOptions,
          overTimeBeforeOptions: overTimeOptions,
        }),
      )
    }

    if (
      [PERIOD_TYPE.FULL_DAY, PERIOD_TYPE.OTHER].includes(roomBookingItem.period)
    ) {
      listenerApi.dispatch(
        setOverTimeOption({
          bookingDate,
          overTimeAfterOptions: [overTimeOptions[0]],
          overTimeBeforeOptions: [overTimeOptions[0]],
        }),
      )
    }

    if (![PERIOD_TYPE.OTHER].includes(roomBookingItem.period)) {
      const resultMasterRoom = await FetchMasterRoomsByDay({
        bookingInformation: state.bookingInformation,
        stationUuid,
        roomBookingItem: roomBookingItem,
        startTimeRoom: roomBookingItem.startTimeRoom,
        endTimeRoom: roomBookingItem.endTimeRoom,
      })

      listenerApi.dispatch(
        setRoomBookingListByDate({
          bookingDate: roomBookingItem.bookingDate,
          key: 'masterRooms',
          value: resultMasterRoom,
        }),
      )
    }
  },
})
