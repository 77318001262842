import { setChangeQuestionByKey } from '../../../../../redux/slices/eTestingForm'

export const handleAddAnswer =
  (keyQuestion, answers, questionType) => (dispatch) => {
    const valueObj = {
      id: `id-${answers.length + 1}`,
      title: '',
      booleanAns: null,
      question: questionType === 'MATCHING' ? '' : undefined,
      sequence: answers.length + 1,
      mediaUrl: '',
      isCorrect:
        questionType === 'SEQUENCE' ||
        (questionType === 'MATCHING' && answers.length === 0), // true | false
      score: 0,
      questionType: questionType,
    }
    const result = {
      keyQuestion,
      key: 'answers',
      value: [...answers, valueObj],
    }

    dispatch(setChangeQuestionByKey(result))
  }
