import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Table from '../../../../components/redux/Table'
import { StyledCard } from './Styled'
import * as event from './event'
import { initialState } from '../../../../redux/slices/table/model'
import _ from 'lodash'
import { getQueryParams } from '../../../../utils/lib'
import { MANAGE_CLASS_ROW_PAGE_PER_LIST } from '../../../../constants/table'

const Index = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, manageClass } = useSelector(
    (state) => ({
      table: state.table,
      manageClass: state.manageClass,
    }),
    shallowEqual,
  )
  const { limit, order, page, sort } = table.table
  const { filterProp, other } = table.filter
  const { station } = manageClass
  const highClassChcked = _.isNil(other) ? false : other.highClass.checked
  const myJobChcked = _.isNil(other) ? false : other.myJob.checked
  const objOnLink = {
    virtual: Number(getQueryParams('virtual')),
  }

  //useEffect after filter change
  useEffect(() => {
    if (!isInitial) dispatch(event.fetchDataList(table, station))
  }, [
    limit,
    order,
    page,
    sort,
    filterProp,
    highClassChcked,
    myJobChcked,
    station,
  ])

  //first time useEffect
  useEffect(() => {
    dispatch(
      event.fetchDataList(
        initialState,
        station,
        setInitial,
        _.get(objOnLink, 'virtual', 0),
      ),
    )
  }, [])

  return (
    <>
      <StyledCard id="listTable">
        <Table rowsPerPageOptions={MANAGE_CLASS_ROW_PAGE_PER_LIST} />
      </StyledCard>
    </>
  )
}
export default Index
