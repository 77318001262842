import { StyledRow } from '../../../../Styled'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  bookingPeriodType,
  bookingPeriodTypeMonthlyPlan,
} from '../../../../../../constants/roomManagement'
import Radio from '@mui/material/Radio'
import { setChangeBookingInformation } from '../../../../../../redux/slices/roomManagement/Drawer'
import _ from 'lodash'
import { enumRequestType } from '../../../../../../modules/RoomManagement/View/Content/CalendarCard/constanst'

export const BookingPeriodType = () => {
  const dispatch = useDispatch()
  const { bookingPeriod, requestType } = useSelector(
    (state) => ({
      bookingPeriod:
        state.roomManagementDrawer.bookingInformation.bookingPeriod,
      requestType: state.roomManagementDrawer.bookingInformation.requestType,
    }),
    shallowEqual,
  )
  const isMonthlyPlan = requestType === enumRequestType.MONTHLY_PLAN
  const periodType = isMonthlyPlan
    ? bookingPeriodTypeMonthlyPlan
    : bookingPeriodType

  return (
    <StyledRow
      sx={{
        gap: 5,
        px: 2,
        py: 0.5,
        background: '#F3F8FF',
        borderRadius: 2,
      }}
    >
      {!isMonthlyPlan && (
        <Typography variant="body2b">ช่วงเวลาการใช้ห้อง</Typography>
      )}
      <RadioGroup
        row
        disabled={isMonthlyPlan}
        data-testid="radio-bookingPeriod"
        sx={{ gap: 5 }}
        aria-label="bookingPeriod"
        name="bookingPeriod"
        defaultValue={bookingPeriod}
        value={bookingPeriod}
        onChange={(e) => {
          const value = _.get(e, 'target.value', null)
          dispatch(
            setChangeBookingInformation({
              key: 'bookingPeriod',
              value: !_.isEmpty(value) ? value : null,
            }),
          )
        }}
      >
        {periodType.map((type, idx) => (
          <FormControlLabel
            key={idx}
            value={type.value}
            control={<Radio />}
            label={type.label}
            disabled={isMonthlyPlan}
          />
        ))}
      </RadioGroup>
    </StyledRow>
  )
}
