import { EXPIRY_TYPE } from '../../../constants/manageClass/qrCodeForEnroll'

export const initialState = {
  isSuccess: false,
  isCancel: false,
  isLoading: false,
  data: null,
  eTestEEvaList: [],
  shareProps: [],
  error: null,
  eventList: [],
  checkClose: {
    checkAttendance: [],
    checked: {
      quiz: false,
      quizAndMail: false,
      nameCheck: false,
      assessment: false,
      acceptAll: false,
    },
  },
  qrEnrollProps: {
    expiryDate: null,
    expiryTime: null,
    link: null,
    isLoadingBtn: false,
    isLoading: false,
    isLoadingResetQR: false,
    isEnable: false,
    expiryType: EXPIRY_TYPE.NO_EXPIRY,
    iserror: false,
    errorMessage: null,
    qrExpired: false,
    statusQRCode: false,
    isOpenDatePicker: false,
  },
  isMobile: false,
}
