import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './shepherd.css'
import { Root } from './App'
import reportWebVitals from './reportWebVitals'
// require('dotenv').config()

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  // <React.StrictMode>
  <Root />,
  // </React.StrictMode>,
  document.getElementById('root'),
)
reportWebVitals()
