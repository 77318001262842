import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
// import { Row } from 'src/components/Layout/Styled'

const CatCard = ({ type }) => {
  const { tableData } = useSelector(
    (state) => ({
      tableData: state.crud?.eContentDashboard?.overview?.[type]?.tableData,
    }),
    shallowEqual,
  )
  return (
    <Card sx={{ padding: '8px 16px 16px 16px' }}>
      <Typography variant="h6">สถิติจำนวน Content</Typography>
      <Box
        mt={1}
        sx={{
          borderRadius: '8px',
          border: '1px solid #DBE4F199',
        }}
      >
        <Grid container py={1} px={2}>
          <Grid item xs={2}>
            <Typography varaint="body1b">ลำดับ</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography varaint="body1b">จำนวน Content</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography varaint="body1b">%</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography varaint="body1b">ชื่อหมวดหมู่</Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            maxHeight: 260,
            overflow: 'auto',
          }}
        >
          {tableData?.map((ele, ind) => {
            return (
              <Grid
                container
                key={ind}
                sx={{
                  borderRadius: '8px',
                  borderTop: '1px solid #DBE4F199',
                }}
                py={1}
                px={2}
              >
                <Grid item xs={2}>
                  {ele.no}
                </Grid>
                <Grid item xs={3}>
                  {ele.total}
                </Grid>
                <Grid item xs={2}>
                  {ele.percent}
                </Grid>
                <Grid item xs={5}>
                  {ele.name}
                </Grid>
              </Grid>
            )
          })}
        </Box>
      </Box>
    </Card>
  )
}

export default CatCard
