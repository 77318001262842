import _ from 'lodash'
import { setLevelDistribution } from '../../../../../redux/slices/manageContent/home'
import {
  getAllCourse,
  getAllElearningCourse,
} from '../../../../../services/manageContent'

export const fetchOptionCourse = (onEdit, courses) => (dispatch) => {
  if (onEdit) return
  dispatch(getAllCourse())
  dispatch(getAllElearningCourse())
  dispatch(setLevelDistribution(_.get(courses, '[0].uuid', '')))
}
