import React from 'react'
import _ from 'lodash'
import Carousel from '../../../../../components/Carousel'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import {
  StyledDisplayTrainer,
  StyledDivItem,
  StyledTrainerContainer,
  StyledTrainerHeader,
  StyledTypography,
} from './Styled'
import './style.css'

const Trainer = ({ trainer }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const trainerList = _.get(trainer, 'trainers', [])
  const listTab = _.chunk(trainerList, 4)
  return (
    <StyledTrainerContainer>
      <img src="/image/home_slash.svg" id="slash" />
      <StyledTrainerHeader variant={isLg ? 'h2' : 'h5'}>
        {_.get(trainer, 'title1', '')}{' '}
        <span>{_.get(trainer, 'title2', '')}</span>{' '}
        {_.get(trainer, 'title3', '')}
      </StyledTrainerHeader>
      <Carousel ariaLabel="gallery-teacher">
        {listTab.map((trainerList, key) => (
          <StyledDivItem key={key}>
            {trainerList.map((trainer, index) => (
              <DisplayTrainer key={index} trainer={trainer} />
            ))}
          </StyledDivItem>
        ))}
      </Carousel>
    </StyledTrainerContainer>
  )
}

export default Trainer

export const DisplayTrainer = ({ trainer }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const position = _.get(trainer, 'position', '-')
  const image = _.get(trainer, 'imageFull', '')
  return (
    <StyledDisplayTrainer>
      <img src={`${window.__env__.REACT_APP_API_URL}/file/${image}`} />
      <StyledTypography variant={isLg ? 'h5' : 'body2b'}>
        {_.get(trainer, 'prefixTH', '-')} {_.get(trainer, 'firstNameTH', '')}{' '}
        {_.get(trainer, 'lastNameTH', '') || ''}
      </StyledTypography>
      <StyledTypography variant={isLg ? 'body1' : 'teacherSub'}>
        {position}
      </StyledTypography>
    </StyledDisplayTrainer>
  )
}
