import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import _, { get } from 'lodash'
import { setFieldError } from '../../../redux/slices/crud'
import scrollToError from './handleScrollToError'
import appHistory from '../../../appHistory'
import { store } from '../../../App'
import { callAPI } from '../../../utils/lib'
import { displayClientError, displayServerError } from './handlerFetchError'

export const handleProcessSubmitForm = async (uuid, postBodyMutation) => {
  startDialogLoading()

  try {
    const response = await submitFormData(uuid, postBodyMutation)

    handleSuccessfulSubmission(response)
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const submitFormData = async (uuid, postBodyMutation) => {
  const { formData, crudConfig } = store.getState().crud
  const url = `/crud`
  const headers = { 'x-type': crudConfig.moduleId }

  return await callAPI({
    method: _.isEmpty(uuid) ? 'post' : 'put',
    url,
    body: postBodyMutation(formData),
    headers,
  })
}

const handleSuccessfulSubmission = (response) => {
  const uuid = get(response, 'uuid', '')

  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => navigateToViewPath(uuid),
    }),
  )
}

export const handleFormSubmissionError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      handleBadRequestError(error.response)
    } else {
      displayServerError()
    }
  } else {
    console.log(error)
    displayClientError()
  }
}

const handleBadRequestError = (response) => {
  const errorData = get(response, 'data.message[0]', '')

  store.dispatch(
    openErrorDialog({
      message: errorData.constraints.value,
      title: 'ผิดพลาด',
      isCloseDialog: false,
      handleError: () => {
        store.dispatch(
          setFieldError([
            {
              path: errorData.property,
              message: errorData.constraints.value,
            },
          ]),
        )

        scrollToError(errorData.property)
      },
    }),
  )
}

const navigateToViewPath = (uuid) => {
  const { crudConfig } = store.getState().crud
  const viewPath = `${crudConfig.modulePath}/view/${uuid}`
  appHistory.push(viewPath)
  store.dispatch(closeDialog())
}
