import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'

import DropDownButton from './DropDownButton'
import Breadcrumbs from '../../../../../components/Breadcrumbs'

import { listBreadcrumb } from './events'
import { BoxContent, BoxHeader } from './Styled'

const Index = () => {
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState
  )
  const label = `${_.get(initialState, 'firstNameTH', '')} ${
    _.get(initialState, 'lastNameTH', '') || ''
  }`

  return (
    <BoxContent>
      <BoxHeader>
        <Typography variant="h4">รายละเอียดผู้เรียน: {label}</Typography>
        <Breadcrumbs
          menuList={listBreadcrumb(_.get(initialState, 'trainingPlanUuid', ''))}
        />
      </BoxHeader>
      <DropDownButton
        trainingPlanUuid={_.get(initialState, 'trainingPlanUuid', '')}
      />
    </BoxContent>
  )
}

export default Index
