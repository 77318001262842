import { downloadFile } from '../../../services/util'
import { startLoading, stopLoading } from '../../../redux/slices/table'
import { examinationTemplateReportDownload } from '../../../utils/apiPath'
import { monthNames } from '../../../constants/eExamination'

export const GetReport = (year, month) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(
    downloadFile({
      url: examinationTemplateReportDownload,
      body: { year, month },
      fileName: `รายงานหักบัญชีประจำเดือน ${monthNames[month - 1]} ${
        year + 543
      }.xlsx`,
    }),
  )
  dispatch(stopLoading())
}
