import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import {
  getAllCourse,
  getAllDistributionChannel,
  getAllProductType,
  getManageContentHome,
  getAllElearningCourse,
} from '../../../../services/manageContent'

const manageContentHome = createSlice({
  name: 'manageContentHome',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startUploadLoading: (state, { payload }) => {
      state.isUploadLoading = {
        ...state.isUploadLoading,
        [payload]: true,
      }
    },
    stopUploadLoading: (state, { payload }) => {
      state.isUploadLoading = {
        ...state.isUploadLoading,
        [payload]: false,
      }
    },
    setErrorUpload: (state, { payload }) => {
      state.error.upload = payload
    },
    resetErrorUpload: (state) => {
      state.error.upload = null
    },
    setChangeBody: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.data, key, value)
    },
    setLevelDistribution: (state, { payload }) => {
      state.levelDistribution = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageContentHome.fulfilled, (state, { payload }) => {
        state.data = _.get(payload, 'data', null)
      })
      .addCase(getManageContentHome.rejected, (state) => {
        state.error.upload = 'อัปโหลดรูปภาพล้มเหลว ลองใหม่อีกครั้ง'
      })
      .addCase(getAllProductType.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', null)
        const result = _.get(data, 'result', [])
        state.options.productTypes = result.map((item) => ({
          ...item,
          label: item.productType,
          value: item.uuid,
        }))
      })
      .addCase(getAllProductType.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
      .addCase(getAllDistributionChannel.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', null)
        const result = _.get(data, 'result', [])
        state.options.distributionChannels = result.map((item) => ({
          ...item,
          label: item.distribution,
          value: item.uuid,
        }))
      })
      .addCase(getAllDistributionChannel.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
      .addCase(getAllCourse.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', null)
        state.options.courses = _.get(data, 'result', []).map((item) => ({
          ...item,
          label: `${item.courseCode} ${item.englishName}`,
          value: item.uuid,
        }))
      })
      .addCase(getAllCourse.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
      .addCase(getAllElearningCourse.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', null)
        state.options.coursesEL = _.get(data, 'result', []).map((item) => ({
          ...item,
          label: `${item.courseCode} ${item.name}`,
          value: item.uuid,
        }))
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startUploadLoading,
  stopUploadLoading,
  setErrorUpload,
  resetErrorUpload,
  setChangeBody,
  setLevelDistribution,
} = manageContentHome.actions

export default manageContentHome.reducer
