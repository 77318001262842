import { useSelector, useDispatch } from 'react-redux'
import { breadcrumbReport } from '../model'
import {
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  StyledCard,
} from '../Styled'
import { Box, Button, Typography } from '@mui/material'
import Select from '../../../components/Input/Select'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { monthNames } from '../../../constants/eExamination'
import { useState } from 'react'
import _ from 'lodash'
import { FileDownloadOutlined } from '@mui/icons-material'
import { GetReport } from './event'

const getYearRangeArray = (yearsBeforeAndAfter = 15) => {
  const currentYear = new Date().getFullYear()
  const yearArray = Array.from(
    { length: 2 * yearsBeforeAndAfter + 1 },
    (_, i) => {
      const year = currentYear - yearsBeforeAndAfter + i
      return { value: year, label: year.toString() }
    },
  )
  return yearArray
}

const getMonthRangeArray = () => {
  return _.map(monthNames, (item, index) => {
    return { label: item, value: index + 1 }
  })
}
const Index = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { isLoading } = table
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">รายงานหักบัญชี</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbReport} />
          </Box>
        </StyledHeader>

        <StyledCard id="listTable">
          <Box sx={{ m: 3 }}>
            <Typography variant="h6">สร้างไฟล์รายงาน</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
                mb: '24px',
              }}
            >
              <Select
                dataTestid="select-year"
                id="year"
                name="year"
                labelText="ปี"
                type="basic"
                textErrorSx={{ mb: 0 }}
                value={year}
                options={getYearRangeArray()}
                handleChange={(e) => setYear(e.target.value)}
              />
              <Select
                dataTestid="select-month"
                id="month"
                name="month"
                labelText="เดือน"
                type="basic"
                textErrorSx={{ mb: 0 }}
                value={month}
                options={getMonthRangeArray()}
                handleChange={(e) => setMonth(e.target.value)}
              />
            </Box>
            <Button
              endIcon={<FileDownloadOutlined />}
              variant="contained"
              onClick={() => dispatch(GetReport(year, month))}
            >
              สร้างไฟล์และดาวน์โหลด
            </Button>
          </Box>
        </StyledCard>
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Index
