import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  eLearningCourseLearnerApprove,
  eLearningCourseLearnerAttend,
  eLearningCourseLearnerDetail,
  eLearningCourseLearnerIneligible,
  eLearningCourseLearnerNotVerify,
  eLearningCourseLearnerRandomClick,
  eLearningCourseLearnerRekog,
} from '../../../utils/apiPath'
import { eLearningCourseLearnerRound } from '../../../utils/apiPath'

export const getLearnerDetail = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/getLearnerDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseLearnerDetail}/${uuid}`,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearnerRekog = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/getLearnerRekog',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseLearnerRekog}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearnerRandomClick = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/getLearnerRandomClick',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseLearnerRandomClick}${url}`,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearnerAttend = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/getLearnerAttend',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseLearnerAttend}${url}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearnerDetailRound = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/getLearnerDetailRound',
  async (payload, { rejectWithValue }) => {
    try {
      const { codeId, eLearningCourseUuid } = payload
      const response = await axios.get(
        `${eLearningCourseLearnerRound}/${codeId}/${eLearningCourseUuid}`,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putApproveLearner = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/putApproveLearner',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseLearnerApprove, payload)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putNotApproveLearner = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/putNotApproveLearner',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseLearnerNotVerify, payload)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postIneligibleLearner = createAsyncThunk(
  'eLearningCourseBatchLearnerDetail/postIneligibleLearner',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        eLearningCourseLearnerIneligible,
        payload,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
