import { useLocalStorage } from '@rehooks/local-storage'
import { downloadTemplate } from '../event'

export const hasEditPermission = () => {
  const [user] = useLocalStorage('user')
  const hasPermission = user.permission.find(
    (item) =>
      item.module === 'E_MANAGECLASS_SETTING' &&
      item.owner === 'ALL' &&
      item.permission === 'EDIT_CHECK_OIC'
  )
  return !hasPermission
}

export const onDownloadTemplate = () => async (dispatch) => {
  await dispatch(downloadTemplate())
}
