import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import {
  fetchRegionList,
  fetchExaminationCompanyList,
  fetchExaminationAssociationList,
  getExaminationScheduleDetail,
  getExaminationScheduleEvent,
  getExaminationTime,
} from '../../../../services/eExamination/form'
import _ from 'lodash'
import { EXAMINATION_EVENT_STATUS } from '../../../../constants/eExamination'
import { convertFormatDateTime } from '../../../../utils/lib'

const eExamForm = createSlice({
  name: 'eExamForm',
  initialState,
  reducers: {
    setInitialCreateForm: () => initialState,
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setEExamType: (state, { payload }) => {
      state.eExamType = payload
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)
    },
    setResult: (state, { payload }) => {
      state.result = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegionList.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRegionList.fulfilled, (state, action) => {
        state.regionList = action.payload.result
        state.loading = false
      })
      .addCase(fetchRegionList.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchExaminationCompanyList.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchExaminationCompanyList.fulfilled, (state, action) => {
        const result = _.get(action.payload, 'result', []).filter((item) => {
          return item?.academyStationName && item?.academyStationName != ''
        })
        state.examinationCompanyList = result
        state.loading = false
      })
      .addCase(fetchExaminationCompanyList.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchExaminationAssociationList.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchExaminationAssociationList.fulfilled, (state, action) => {
        state.examinationAssociationList = action.payload.result
        state.loading = false
      })
      .addCase(fetchExaminationAssociationList.rejected, (state) => {
        state.loading = false
      })
      .addCase(getExaminationScheduleDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getExaminationScheduleDetail.fulfilled, (state, { payload }) => {
        state.result = payload.data
        state.loading = false
      })
      .addCase(getExaminationScheduleDetail.rejected, (state) => {
        state.loading = false
      })
      .addCase(getExaminationScheduleEvent.fulfilled, (state, { payload }) => {
        const totalCount = payload.data.totalCount
        let result = payload.data.result
        result = _.map(result, (item) => {
          return {
            ...item,
            event: EXAMINATION_EVENT_STATUS[item.event],
            createdAt: convertFormatDateTime({
              value: item.createdAt,
              type: 'dateTime',
            }),
          }
        })
        state.historyList = { totalCount: totalCount, result: result }
        state.historyCount = totalCount
      })
      .addCase(getExaminationTime.fulfilled, (state, { payload }) => {
        state.timeList = payload.data.result
      })
  },
})

export const {
  setInitialCreateForm,
  startLoadingDrawer,
  stopLoadingDrawer,
  setEExamType,
  setBody,
  setFieldValue,
  setResult,
  setLoading,
} = eExamForm.actions
export default eExamForm.reducer
