import _ from 'lodash'
import {
  MODULE_TYPE,
  MODULE_TYPE_TH,
} from '../../../../../../../../constants/eLearning'

export const formatDurationToMinute = (duration) => {
  if (_.isNil(duration)) return '00:00'
  const date = new Date(duration.toFixed() * 1000)

  const hours = date.getUTCHours()
  const minutes = padTo2Digits(date.getUTCMinutes())
  const seconds = padTo2Digits(date.getUTCSeconds())

  if (hours) {
    return `${hours}:${minutes}:${seconds}`
  } else return `${minutes}:${seconds}`
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export const SubTitleContent = (data) => {
  const type = _.get(data, 'moduleType', '') || _.get(data, 'type', '')
  switch (type) {
    case MODULE_TYPE.E_TESTING:
    case MODULE_TYPE.E_EVALUATION:
    case MODULE_TYPE.LINK_TO:
    case MODULE_TYPE.SCORM:
      return MODULE_TYPE_TH[type]
    case MODULE_TYPE.EMBED_CONTENT:
    case MODULE_TYPE.LINK:
    case MODULE_TYPE.HTML: {
      const duration =
        _.get(data, 'duration', null) || _.get(data, 'moduleDetail.duration', 0)
      return formatDurationToMinute(duration)
    }
    case MODULE_TYPE.UPLOAD_FILE: {
      const fileList =
        _.get(data, 'fileList', null) ||
        _.get(data, 'eLearningModuleFileUpload', [])
      return `${fileList.length} ไฟล์`
    }
    default:
      return ''
  }
}
