export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    cancel: theme?.palette?.error?.main,
    completed: theme?.palette?.primary?.main,
    closeregistration: theme?.palette?.primary?.main,
  }
  return colorList[statusText] || theme?.palette?.text?.primary
}
