import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { shallowEqual, useSelector } from 'react-redux'
import { useState } from 'react'
import { StyledBoxButton } from '../../../Styled'
import useTheme from '@mui/system/useTheme'
import { downloadQRCode } from '../../../../../../../utils/lib'

export const ButtonGroup = () => {
  const theme = useTheme()
  const [showTooltip, setShowTooltip] = useState(false)
  const {
    qrEnrollProps: { link, qrExpired },
  } = useSelector((state) => state.manageClassDetail, shallowEqual)

  useEffect(() => {
    if (!showTooltip) return
    setTimeout(() => {
      setShowTooltip(false)
    }, 3000)
  }, [showTooltip])

  return (
    <StyledBoxButton
      sx={{
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          gap: theme.spacing(1),
        },
      }}
    >
      <Tooltip
        open={showTooltip}
        title="คัดลอกแล้ว!"
        arrow
        placement="bottom"
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '18px',
            },
          },
        }}
      >
        <Button
          sx={{
            width: 120,
            '&.Mui-disabled': {
              borderColor: (theme) => theme.palette?.action?.disabled,
            },
          }}
          data-testid="btn-copy"
          variant="outlined"
          size="m"
          disabled={qrExpired}
          startIcon={<ContentCopyOutlined />}
          onClick={() => {
            navigator?.clipboard?.writeText(link)
            setShowTooltip(true)
          }}
        >
          <Typography variant="body2">คัดลอกลิงก์</Typography>
        </Button>
      </Tooltip>
      <Button
        sx={{ width: 120 }}
        data-testid="btn-download"
        variant="contained"
        size="m"
        disabled={qrExpired}
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => downloadQRCode('share-qr-code')}
      >
        <Typography variant="buttonm">ดาวน์โหลด</Typography>
      </Button>
    </StyledBoxButton>
  )
}
