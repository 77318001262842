import { convertFormatDateTime } from '../../../utils/lib'
import { Box, useTheme } from '@mui/system'
import { StyledColumn, StyledRow } from '../../../modules/Styled'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'

const getAlignmentStyle = (mediaQuery) =>
  mediaQuery ? 'flex-end' : 'flex-start'

const createLabel = (user, time) => {
  if (!user) return '-'
  return (
    user +
    ' ' +
    convertFormatDateTime({
      value: time,
      type: 'dateTime',
    })
  )
}

const Label = ({ text, label }) => (
  <Typography variant="body3" color="text.lightGray">
    {text} {label}
  </Typography>
)

export function UpdatedComponent() {
  const { formData } = useSelector((state) => state.crud)
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const rowStyle = {
    justifyContent: 'flex-end',
    flexDirection: isSmUp ? 'row' : 'column',
  }
  const columnStyle = { alignItems: getAlignmentStyle(isSmUp) }

  return (
    <Box>
      <StyledRow sx={rowStyle}>
        <StyledColumn sx={columnStyle}>
          <Label
            text="สร้างโดย"
            label={createLabel(formData.createdBy, formData.createdAt)}
          />
          <Label
            text="แก้ไขล่าสุดโดย"
            label={createLabel(formData.updatedBy, formData.updatedAt)}
          />
        </StyledColumn>
      </StyledRow>
    </Box>
  )
}
