import _ from 'lodash'
import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'

export const handleOptionChoosed = async () => {
  let courseOptions = store.getState().crud.masterData.course.allOptions
  let learningPathOptions =
    store.getState().crud.masterData.learningPath.allOptions
  let selectedContents = store.getState().crud.formData.contents
  let newCourseOptions = [...courseOptions]
  let newLearningPathOptions = [...learningPathOptions]

  _.forEach(selectedContents, (option) => {
    if (option.type === 'COURSE' && !_.isEmpty(option.content)) {
      const index = _.findIndex(
        newCourseOptions,
        (data) => data.value === option.content.value,
      )
      newCourseOptions.splice(index, 1)
    }
    if (option.type === 'LEARNING_PATH' && !_.isEmpty(option.content)) {
      const index = _.findIndex(
        newLearningPathOptions,
        (data) => data.value === option.content.value,
      )
      newLearningPathOptions.splice(index, 1)
    }
  })

  store.dispatch(
    setFieldValue({
      key: `masterData.learningPath.options`,
      value: newLearningPathOptions,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: `masterData.course.options`,
      value: newCourseOptions,
    }),
  )
}
