import _ from 'lodash'

export const handleTextInput = (e, setInitialValues, documentation, uuid) => {
  const target = e.target
  const valueStr = target.value == '' ? '' : target.value
  const documentList = _.get(documentation, 'documentList')
  const DocumentListInput = _.map(documentList, (item) => {
    if (item?.uuid === uuid) {
      return { ...item, name: valueStr, isError: _.isEmpty(valueStr) }
    }
    return item
  })
  setInitialValues((prev) => ({
    ...prev,
    documentList: DocumentListInput,
  }))
}

export const handleRemoveInput = (setInitialValues, documentation, uuid) => {
  const documentList = _.get(documentation, 'documentList')
  const document = _.filter(documentList, (item) => item.uuid !== uuid)
  setInitialValues((prev) => ({
    ...prev,
    documentList: document,
  }))
}

export const handleAddTextInput = (setInitialValues, documentation) => {
  const documentList = _.get(documentation, 'documentList', [])
  const document = [
    ...documentList,
    {
      uuid: documentList.length + 1,
      name: '',
      isSelect: true,
      isDefault: false,
      isError: false,
    },
  ]
  setInitialValues((prev) => ({
    ...prev,
    documentList: document,
  }))
}

export const handleDefaultIsSelete = (
  setInitialValues,
  documentation,
  uuid
) => {
  const documentList = _.get(documentation, 'documentList', [])
  const DocumentListInput = _.map(documentList, (item) => {
    if (item?.uuid === uuid) {
      return { ...item, isSelect: !item.isSelect }
    }
    return item
  })
  setInitialValues((prev) => ({
    ...prev,
    documentList: DocumentListInput,
  }))
}
