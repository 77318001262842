import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'

import { Draggable } from 'react-beautiful-dnd'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'

import TextInput from '../../../../../../components/Input/TextInput'

import * as Styled from './Styled'
import { TooltipForm } from '../../Styled'
import { getErrorMessage } from '../../event'

import { handleChange, handleDelete, handleChangeScore } from './event'
import { handleNumberInput } from '../../Answer/event'

const Index = ({ keyQuestion, indexQuestion }) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { itemQuestion, disableForm, errorSchemaMessage } = useSelector(
    (state) => ({
      itemQuestion: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const questionErrorMessage = getErrorMessage({
    field: 'QUESTION',
    errorSchemaMessage,
    indexQuestion,
  })

  return (
    <Draggable
      key={itemQuestion.id}
      draggableId={itemQuestion.id}
      index={indexQuestion}
    >
      {(provided) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{ display: 'flex', width: '100%' }}
        >
          <Styled.BoxContent indexQuestion={indexQuestion}>
            <Styled.ContentLeft>
              <Styled.DragIndicator {...provided.dragHandleProps}>
                <DragIndicatorTwoTone />
              </Styled.DragIndicator>
              <Typography
                variant="h6"
                sx={{
                  position: 'relative',
                  left: '-16px',
                  pr: 1,
                  top: '-13px',
                }}
              >
                {indexQuestion + 1}.
              </Typography>

              <TextInput
                id="title"
                name="title"
                placeholder="คำถาม"
                type="basic"
                disabled={disableForm}
                value={itemQuestion.title}
                multiline={true}
                maxRows={2}
                onChange={(e) => {
                  dispatch(
                    handleChange({
                      keyQuestion,
                      key: 'title',
                      value: _.get(e, 'target.value', ''),
                    })
                  )
                }}
                textError={questionErrorMessage}
              />
            </Styled.ContentLeft>
            <Styled.ContentRight>
              <Styled.BoxScore>
                <TextInput
                  id="score-assessment"
                  name="scoreAssessment"
                  type="basic"
                  disabled={disableForm}
                  endAdornmentText={'คะแนน'}
                  value={itemQuestion.score}
                  inputProps={{ onInput: handleNumberInput }}
                  onChange={(e) =>
                    dispatch(
                      handleChangeScore({
                        keyQuestion,
                        key: 'score',
                        value: _.get(e, 'target.value', ''),
                      })
                    )
                  }
                />
              </Styled.BoxScore>
              <TooltipForm
                placement="bottom"
                title={<Typography variant="tooltip">ลบ</Typography>}
                onClick={() =>
                  !disableForm &&
                  dispatch(
                    handleDelete({
                      keyQuestion: itemQuestion.key,
                    })
                  )
                }
              >
                <IconButton
                  data-testid={`btn-delete-`}
                  sx={{ width: 40, height: 40 }}
                  onMouseLeave={() => setIsHover(false)}
                  onMouseOver={() => setIsHover(true)}
                >
                  <DeleteOutlineOutlined
                    color={isHover ? 'primary' : 'action'}
                  />
                </IconButton>
              </TooltipForm>
            </Styled.ContentRight>
          </Styled.BoxContent>
        </Box>
      )}
    </Draggable>
  )
}
export default Index
