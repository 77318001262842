import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import { validationSchema } from './FormSchema'
import { level as levelApi, filterLevelOfLearner } from '../../utils/apiPath'
import Breadcrumbs from '../../components/Breadcrumbs'
import { callAPI } from '../../utils/lib'
import { onView } from './LevelList'

const LevelForm = ({ isEditForm }) => {
  const levelText = isEditForm ? 'แก้ไข Level' : 'เพิ่ม Level'

  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'Level',
      link: '/master-data/level',
      pointer: true,
    },
    { title: levelText, link: '/', pointer: false },
  ]
  const { id: uuid } = useParams()
  const {
    context,
    setContext: setDialog,
    setCloseDialog,
  } = useContext(DialogContext)
  const dialog = context.dialog
  const history = useHistory()
  const [displayLevel, setDisplayTitle] = useState('')
  const [levelOfLearner, setLevelOfLearner] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: '',
    title: '',
    sequence: '',
    status: true,
    levelOfLearner: null,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        isLoading: false,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () => submitLevel(values, onView, history),
        onCancelClick: () => setCloseDialog(),
      })
    },
  })

  const onCancelClick = () => {
    setDialog({ ...dialog, open: false })
  }

  const submitLevel = async (values, onView, history) => {
    setDialog({
      ...dialog,
      open: true,
      title: 'ยืนยันบันทึกข้อมูล',
      content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      isLoading: true,
    })
    const methodType = isEditForm ? 'put' : 'post'
    const sequence = parseInt(values.sequence)
    const body = {
      level: values.level,
      position: values.position,
      sequence: sequence,
      status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      levelOfLearner: {
        uuid: values.levelOfLearner,
      },
    }
    if (isEditForm) body.uuid = uuid
    callAPI({
      method: methodType,
      url: levelApi,
      body: body,
      onSuccess: (data) => {
        const uuidLink = isEditForm ? uuid : data?.uuid
        setDialog({
          open: true,
          isLoading: false,
          title: 'สำเร็จ',
          content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          variant: 'success',
          onConfirmClick: () => {
            setDialog({
              open: false,
            })
            onView({ uuid: uuidLink }, history)
          },
        })
      },
      onError: () => {
        setDialog({
          open: false,
          isLoading: false,
          variant: '',
        })
      },
    })
  }

  const fetchLevelById = async (uuid) => {
    setIsPageLoading(true)
    callAPI({
      method: 'get',
      url: levelApi + `/${uuid}`,
      onSuccess: (data) => {
        const { level, status, id, position, sequence } = data
        const objLol = _.get(data, 'levelOfLearner', {})
        setInitialValues({
          ...data,
          levelOfLearner: _.get(objLol, 'uuid', null),
          defaultLevelOfLearner: {
            label: _.get(objLol, 'title', ''),
            value: _.get(objLol, 'uuid', ''),
          },
          status: status.toString().toUpperCase() === 'ACTIVE' || false,
        })
        setDisplayTitle(level)
        formik.setFieldValue('id', id)
        formik.setFieldValue('sequence', sequence)
        formik.setFieldValue('level', level)
        formik.setFieldValue('position', position)
        formik.setFieldValue('levelOfLearner', _.get(objLol, 'uuid', null))
        formik.setFieldValue(
          'status',
          status.toString().toUpperCase() === 'ACTIVE' || false
        )
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  useEffect(() => {
    if (isEditForm) {
      fetchLevelById(uuid)
    }
    fetchLevelOfLearner(setIsPageLoading, setLevelOfLearner)
  }, [isEditForm])

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {levelText}
            {isEditForm && ` : ${displayLevel}`}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={onCancelClick}
          formik={formik}
          isLoading={isPageLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          levelOfLearner={levelOfLearner}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default LevelForm

export const fetchLevelOfLearner = async (
  setIsPageLoading,
  setLevelOfLearner
) => {
  setIsPageLoading(true)
  const body = {
    limit: -1,
    page: 1,
    order: 'ASC',
    sort: 'id',
    title: '',
    status: ['ACTIVE'],
    listOfLevel: true,
  }

  await callAPI({
    url: filterLevelOfLearner,
    method: 'POST',
    body: body,
    onSuccess: (data) => {
      const result = filterListLevelOfLearner(_.get(data, 'result', []))
      setLevelOfLearner(result)
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const filterListLevelOfLearner = (list) => {
  if (!list.length) return []
  let result = []
  list.forEach((item) => {
    if (_.isNull(_.get(item, 'levelTable', null))) {
      result.push({
        label: _.get(item, 'title', ''),
        value: _.get(item, 'uuid', ''),
      })
    }
  })
  return result
}
