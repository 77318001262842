import _ from 'lodash'
import { headCellsClass } from '../model'
import { manageClassDashboardList } from 'src/utils/apiPath'
import callAxios from 'src/utils/baseService'
import { setInitialTable, setSelected } from 'src/redux/slices/table'
import { store } from 'src/App'
import { setReduxDashboardByKey } from 'src/redux/slices/monthlyPlan'
import dayjs from 'dayjs'
import { validateDate } from 'src/utils/lib'

export const fetchClassList = async () => {
  const { table } = store.getState().table
  const realPage = table.page <= 0 ? 1 : +table.page

  store.dispatch(
    setReduxDashboardByKey({ key: 'isLoadingDrawer', value: true }),
  )
  try {
    const {
      classType,
      filters: {
        stationList,
        defaultStation,
        departmentList,
        defaultDepartment,
        startDate,
        endDate,
      },
    } = store.getState().monthlyPlan.dashboard

    const body = {
      limit: _.get(table, 'limit', '100'),
      page: realPage - 1,
      status: classType,
      station: stationList.length > 0 ? stationList : defaultStation,
      department:
        departmentList.length > 0 ? departmentList : defaultDepartment,
      startDate: validateDate(startDate)
        ? dayjs(startDate).format('YYYY-MM-DD')
        : dayjs().startOf('month').format('YYYY-MM-DD'),
      endDate: validateDate(endDate)
        ? dayjs(endDate).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    }

    await callAxios
      .post(manageClassDashboardList, body)
      .then(({ data }) => {
        const results = _.get(data, 'results', [])
        store.dispatch(
          setInitialTable({
            rows: results,
            allCount: _.get(data, 'totalCount', 0),
            headCells: headCellsClass,
            searchKey: 'name',
            hideFilter: true,
            isCheckBox: false,
          }),
        )
        store.dispatch(setSelected([]))
      })
      .catch((err) => {
        console.log(err)
      })
  } catch (e) {
    store.dispatch(
      setInitialTable({ rows: [], allCount: 0, headCells: headCellsClass }),
    )
    return e.message?.includes('method')
  }
  store.dispatch(
    setReduxDashboardByKey({ key: 'isLoadingDrawer', value: false }),
  )
}
