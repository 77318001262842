import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { handleSelectRequestType } from '../../event'

export const CheckboxRequestType = ({ requestTypeItem }) => {
  const dispatch = useDispatch()
  const { filterBookingType } = useSelector(
    (state) => ({
      filterBookingType:
        state.roomManagement.drawerCalendarFilter.filterBookingType,
    }),
    shallowEqual
  )
  return (
    <FormControlLabel
      label={requestTypeItem.label}
      control={
        <Checkbox
          checked={filterBookingType.includes(requestTypeItem.value)}
          onChange={(e) => {
            dispatch(
              handleSelectRequestType(e.target.checked, requestTypeItem.value)
            )
          }}
        />
      }
    />
  )
}
