import React from 'react'
import ReactPlayer from 'react-player'
import _ from 'lodash'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import { StyledMediaBox } from './Styled'
import './style.css'

const MediaBox = ({ course, isHover }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const videoUrl = _.get(course, 'linkExampleVideo', '')
  const coverImage = _.get(course, 'coverImage', '')
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`
  const isShowVideo = isHover && isLg && !_.isEmpty(videoUrl)
  const playerRef = React.useRef()
  const onPause = React.useCallback(() => {
    playerRef.current.seekTo(0, 'seconds')
  }, [playerRef.current])

  return (
    <StyledMediaBox id="media">
      {videoUrl != '' && !isShowVideo && isLg && (
        <PlayArrow
          color="white"
          sx={{
            position: 'absolute',
            margin: 'auto',
            fontSize: '140px',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            textAlign: 'center',
          }}
        />
      )}
      <ReactPlayer
        ref={playerRef}
        muted
        onPause={onPause}
        playing={isShowVideo}
        loop={true}
        url={videoUrl}
        config={{ youtube: { playerVars: { disablekb: 1 } } }}
        style={{ display: isShowVideo ? 'block' : 'none' }}
      />
      <Box sx={{ display: isShowVideo ? 'none' : 'block' }}>
        {_.isEmpty(coverImage) ? (
          <ReactPlayer
            muted
            light
            url={videoUrl}
            ref={playerRef}
            onPause={onPause}
            playing={isShowVideo}
            loop={true}
            config={{ youtube: { playerVars: { disablekb: 1 } } }}
          />
        ) : (
          <CardMedia component="img" image={imageSrc} alt="preview" />
        )}
      </Box>
    </StyledMediaBox>
  )
}

export default MediaBox
