import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column } from '../../../../Styled'

export const StyledTabContainer = styled(Column)(({ theme }) => ({
  minWidth: 662,
  width: 662,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  borderRadius: theme.spacing(0.5),
  [theme.breakpoints.down('lg')]: { width: '100%', minWidth: '100%' },
}))

export const StyledTabWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette?.background?.paper,
  position: 'relative',
  boxShadow: `0px -4px 0px 0px ${theme.palette?.blue?.blueBorder} inset`,
  '& .MuiTabs-indicator': { height: 4 },
  '& .MuiTab-root[aria-selected=true]': { fontSize: '20px', fontWeight: 700 },
  '& .MuiTab-root[aria-selected=false]': {
    fontSize: '20px',
    color: theme.palette?.primary?.main,
  },
}))
