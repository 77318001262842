import { CContainer } from '../../../components/CRUD/components/Container'
import { CHeader } from '../../../components/CRUD/components/Header'
import { breadcrumb } from './model/breadcrumb'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { useEffect } from 'react'
import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useSelector } from 'react-redux'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../components/CRUD/handler/handleChange'
import { handleFiltering } from './handler/handleFiltering'
import _ from 'lodash'
import { fetchBudgetManagementList } from './handler/fetchBudgetManagementList'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import CFilterDrawer from '../../../components/CRUD/components/FilterDrawer'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { ActionComponent } from './components/ActionComponent'

export const BudgetManagementList = () => {
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      fetchBudgetManagementList(mutateFilterPropToBody).then()
    }
  }, [limit, order, page, sort, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleFiltering(mutateFilterSateToProp, mutateFilterPropToBody)
          }
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadcrumb}
          action={<ActionComponent />}
        />
      }
    />
  )
}
