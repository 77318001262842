import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'E_CONTENT',
  moduleSlug: 'e-content',
  moduleTitleFull: 'คลังความรู้',
  modulesTitleShort: ' คลังความรู้',
  modulePath: '/e-content/content',
  filterPath: '/crud/filter',
  headerCells: headerCells,
}
