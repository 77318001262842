import React from 'react'
import Table from '../../../../../../components/redux/Table'
import { StyledCard } from './Styled'
import { Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'

const List = () => {
  const theme = useTheme()
  const { isAssetError, isAssetDuplicate } = useSelector((state) => state.crud)
  return (
    <>
      <StyledCard
        id="listTable"
        sx={{
          border:
            isAssetError || isAssetDuplicate
              ? `solid 1px ${theme.palette?.error?.main}`
              : '',
        }}
      >
        <Table />
      </StyledCard>
      {isAssetError && (
        <Typography color={theme.palette?.error?.main} mt={'-20px'}>
          กรุณาเพิ่มอย่างน้อย 1 รายการ
        </Typography>
      )}
      {isAssetDuplicate && (
        <Typography color={theme.palette?.error?.main} mt={'-20px'}>
          มีรหัสทรัพย์สิน ถูกใช้งานแล้ว
        </Typography>
      )}
    </>
  )
}

export default List
