import { setFieldValue } from '../../../../../redux/slices/crud'
import { getTrainingPlan } from '../../../../../services/inventory'
import { store } from '../../../../../App'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import _ from 'lodash'
import { validatePermission } from '../../../../../utils/lib'

export const handleFetchTrainingPlan = async () => {
  startDialogLoading()
  const user = JSON.parse(localStorage.getItem('user'))
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  let trainingPlans = await store
    .dispatch(getTrainingPlan())
    .then((res) => res.payload)

  if (!hasPermissionAdmin) {
    trainingPlans = _.defaultTo(trainingPlans, []).filter((item) => {
      return staffStationUuid === item?.stationUuid
    })
  }

  store.dispatch(
    setFieldValue({
      key: 'masterData.filteredCourses.options',
      value: trainingPlans,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'masterData.courses.options',
      value: trainingPlans,
    }),
  )
  stopDialogLoading()
  return trainingPlans
}

const startDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
}

const stopDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
