export const initialState = {
  isLoading: false,
  isFetch: false,
  filter: {
    page: 0,
    startDate: '',
    endDate: '',
  },
  hasMore: false,
  history: [],
}
