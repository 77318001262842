import LoadingPageCircular from '../LoadingPageCircular'
import Box from '@mui/material/Box'
import { StyledFormCard, StyledFormWrapper } from '../../../../modules/Styled'
import { shallowEqual, useSelector } from 'react-redux'

export const CPageContainer = ({ header, content, gap }) => {
  const isLoading = useSelector((state) => state.crud.isLoading, shallowEqual)

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box display="flex" flexDirection="column" gap={3} sx={{ mx: 3 }}>
        {header}
        <StyledFormWrapper>
          <StyledFormCard isUploadLoading={false} sx={{ gap: gap }}>
            {content}
          </StyledFormCard>
        </StyledFormWrapper>
      </Box>
    </>
  )
}
