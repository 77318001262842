import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'

import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleEnableButton,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableBodyCell,
  StyledTableData,
  StyledTableHead,
} from './Styled'
import _ from 'lodash'
import { rcmsDetailStatus } from '../../../../constants/apiToRcms'

export const CheckDialog = ({ uploadResult, fetchData, id }) => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const dispatch = useDispatch()
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const successCount = useMemo(() => {
    const successList = result.filter((item) => item.status === 'SUCCESS')
    return successList.length
  }, [result])
  const errorCount = useMemo(() => {
    const errorList = result.filter((item) => item.status !== 'SUCCESS')
    return errorList.length
  }, [result])
  const uploadSuccess = status === 'SUCCESS'

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <StyledDialog>
        {uploadSuccess ? (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              เพิ่มได้ทั้งหมด <span>{successCount} รายการ</span>
            </Typography>
          </Box>
        ) : (
          <>
            {successCount > 0 ? (
              <Box sx={{ ml: 3, px: 1.5, display: 'flex' }}>
                <Typography sx={{ mr: 0.5 }}>
                  เพิ่มได้ <span>{successCount} รายการ </span> ,
                </Typography>
                <Typography>
                  เพิ่มไม่ได้ <span id="error">{errorCount} รายการ</span>
                </Typography>
              </Box>
            ) : (
              <Box sx={{ ml: 3, display: 'flex' }}>
                <Typography sx={{ mr: 0.5 }}>เพิ่มได้ 0 รายการ</Typography>
              </Box>
            )}
          </>
        )}
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">สถานะ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 50 }}>
                <Typography color="text.primary">No.</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 160 }}>
                <Typography color="text.primary">รหัส Agent</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 220, maxWidth: 220 }}>
                <Typography color="text.primary">ชื่อ - นามสกุล</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">ประเภทใบอนุญาต</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">ชื่อใบอนุญาต</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">License Number</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>
                <Typography color="text.primary">วันมีผลบังคับ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 100 }}>
                <Typography color="text.primary">วันหมดอายุ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">Exemption Flag</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">หมายเหตุ</Typography>
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {!_.isEmpty(result) ? (
              result.map((item) => (
                <StyledTableData status={item.status} key={item.index}>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography id="status">
                      {rcmsDetailStatus[item.status]}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 50 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.no}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 160 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.agentCode ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 220, maxWidth: 220 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.fullName ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.licenseCategory ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.licenseType ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.licenseNumber ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 110 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.licenseEffectiveDate ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.licenseExpiryDate ?? ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.exemptionFlag ?? '-'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography color="text.primary" variant="body2">
                      {item.remark ?? '-'}
                    </Typography>
                  </TableCell>
                </StyledTableData>
              ))
            ) : (
              <TableRow>
                <StyledTableBodyCell colSpan={12}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
        {successCount > 0 && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label="เพิ่มรายการที่สามารถเพิ่มได้"
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(handleSubmitAdd(uploadResult, fetchData, id))
            }
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            ตกลง
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">นำเข้ารายการสถานะใบอนุญาตได้ทั้งหมด</Typography>
    </Box>
  )
}
