import * as yup from 'yup'

export const schema = yup.object({
  event: yup.string().required('กรุณาเลือกการจัดการคะแนน'),
  point: yup
    .string()
    .required('กรุณากรอกคะแนน')
    .test('point-test', 'กรุณากรอกคะแนนเป็นตัวเลข', (val) => {
      return /^[0-9]+$/.test(val)
    })
    .test('point-length-test', 'คะแนนกรอกได้ไม่เกิน 4 หลัก', (val) => {
      return val?.length <= 4
    }),
  remark: yup.string().max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3000 ตัวอักษร'),
})
