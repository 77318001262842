import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'

import AssessmentForm from '../AssessmentForm'
import AssessmentSetting from '../AssessmentSetting'
import Feedback from '../Feedback'

const Index = () => {
  const defaultTap = useSelector(
    (state) => state.eEvaluationForm.defaultTap,
    shallowEqual
  )

  return (
    <>
      <Box>
        {defaultTap === 'assessment' && <AssessmentForm />}
        {defaultTap === 'setting' && <AssessmentSetting />}
        {defaultTap === 'feedback' && <Feedback />}
      </Box>
    </>
  )
}
export default Index
