import {
  setChangeInformation2,
  setChangeContact,
} from '../../../../../../src/redux/slices/manage/prospectProfile'

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeInformation2(props))
}

export const handleChangeContact = (props) => (dispatch) => {
  dispatch(setChangeContact(props))
}