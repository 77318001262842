import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const reportCourseSettingDashboard = createSlice({
  name: 'reportCourseSettingDashboard',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setSelectedCourseFor: (state, { payload }) => {
      state.selectedCourseFor = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload.key] = payload.value
    },
    setDateRange: (state, { payload }) => {
      state.dateRange = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setSelectedCourseFor,
  setReduxValue,
  setDateRange,
} = reportCourseSettingDashboard.actions

export default reportCourseSettingDashboard.reducer
