import { store } from '../../../../App'
import { fetchStaffTopList } from './fetchStaffTopList'
import { fetchSummaryFirstView } from './fetchSummaryFirstView'
import { fetchSummarySecondView } from './fetchSummarySecondView'
import { fetchSummaryWorkload } from './fetchSummaryWorkload'

export const fetchOverviewWorkload = async () => {
  const {
    dashboard,
    dashboard: { tabOverviewActive, tabSumWorkloadActive },
  } = store.getState().monthlyPlan

  let updatedDashboard = { ...dashboard }
  if (tabOverviewActive === 0) {
    const staffTopList = await fetchStaffTopList()
    updatedDashboard = { ...updatedDashboard, ...staffTopList }
  } else if (tabOverviewActive === 1) {
    if (tabSumWorkloadActive === 0) {
      const summaryWorkload = await fetchSummaryWorkload()
      updatedDashboard = { ...updatedDashboard, ...summaryWorkload }
    } else if (tabSumWorkloadActive === 1) {
      const summaryWorkload = await fetchSummaryFirstView()
      updatedDashboard = { ...updatedDashboard, ...summaryWorkload }
    } else if (tabSumWorkloadActive === 2) {
      const summaryWorkload = await fetchSummarySecondView()
      updatedDashboard = { ...updatedDashboard, ...summaryWorkload }
    }
  }

  return updatedDashboard
}
