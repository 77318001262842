import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"
import _ from "lodash"

export const handleRemoveOutsideContact = async (index) => {
    const { formData } = store.getState().crud
    const outsideContacts = _.get(formData, 'outsideContacts', [])
    const removedOutsideContacts = outsideContacts.filter((item, itemIndex) => index !== itemIndex)
    store.dispatch(setFieldValue({
        key: 'formData.outsideContacts',
        value: removedOutsideContacts,
    }))
}