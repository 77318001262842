import _ from 'lodash'
import { licenseDownload, licenseFilter, license } from '../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../redux/slices/table'
import { downloadFile } from '../../services/util'
import { licenseCells } from '../../../src/constants/MasterData/headerCell'
import { openDialog, closeDialog } from '../../../src/redux/slices/dialog'
import { callAPI } from '../../../src/utils/lib'
import callAxios from '../../../src/utils/baseService'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'id' : sort,
    type: 'CUSTOM',
    licenseUuidList: selected,
  }

  await dispatch(
    downloadFile({
      url: licenseDownload,
      body: body,
      fileName: `รายการใบอนุญาต.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const onDelete =
  (selected, table, filterProp, history) => async (dispatch) => {
    dispatch(startLoading())
    await callAPI({
      method: 'delete',
      url: `${license}/${selected.uuid}`,
    })

    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ถูกลบแล้ว',
        isCloseDialog: false,
        handleConfirm: () => {
          dispatch(
            fetchDataList({
              method: 'fetch',
              table,
              quickSeach: '',
              manageProps: filterProp,
              history,
            })
          )
          dispatch(closeDialog())
        },
      })
    )

    dispatch(stopLoading())
  }

export const fetchDataList =
  ({ method, table, quickSeach, manageProps, setInitial, history }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const {
      table: { page, limit, order, sort },
      filter: { filterProp },
    } = table
    const licenseId = _.get(filterProp, 'licenseId', '')
    const category = _.get(filterProp, 'category', '')
    const description = _.get(filterProp, 'description', '')
    const createdBy = _.get(filterProp, 'createdBy', '')
    const updatedBy = _.get(filterProp, 'updatedBy', '')
    const realPage = page <= 0 ? 1 : +page
    const status = _.get(filterProp, 'status', [])
    const body = {
      category: category,
      description: method == 'search' ? quickSeach : description,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sort === 'updatedAt' ? 'updatedAt' : sort,
      status: status,
      licenseId: licenseId,
      createdBy: createdBy,
      updatedBy: updatedBy,
    }

    const isCancel = await callAxios
      .post(licenseFilter, body)
      .then(({ data }) => {
        const rows = data.result
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: licenseCells,
            placeholder: 'ค้นหาชื่อใบอนุญาต',
            searchKey: 'category',
            handleSearch: (text) =>
              dispatch(
                handleQuickSearch({ table, text, manageProps, history })
              ),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            onDelete: (selected) =>
              dispatch(
                openDialog({
                  title: 'ยืนยันลบข้อมูล',
                  message: 'คุณต้องการลบรายการนี้หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  handleConfirm: () =>
                    dispatch(onDelete(selected, table, filterProp, history)),
                })
              ),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: licenseCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status?.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status?.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status?.deletedChecked) {
      statusList.push('DELETED')
    }
  }

  const filterText = {
    licenseId: filter.codeIdChecked ? filter.codeId : '',
    category: filter.typeChecked ? filter.type : '',
    description: filter.descriptionChecked ? filter.description : '',
    status: filter.statusChecked ? statusList : [],
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.editedByChecked ? filter.editedBy : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
}

export const handleQuickSearch =
  ({ table, text, manageProps, history }) =>
  (dispatch) => {
    dispatch(setSearchText(text))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        quickSeach: text,
        manageProps,
        history,
      })
    )
  }
