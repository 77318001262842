import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import StaffUpload from '../../../components/Upload/StaffUpload'
import TextInput from '../../../components/Input/TextInput'
import Select from '../../../components/Input/Select'

export const handleDeleteFile = (formik, imageType) => {
  const field = imageType === 'full' ? 'imageFull' : 'image'
  formik.setFieldValue(field, '')
}

const General = (props) => {
  const {
    formik,
    handleUploadFile,
    onSelectChange,
    checkPermission,
    setDialog,
    IsNonAcademy,
    // setIsNonAcademy,
  } = props

  const prefixTH = [
    { label: 'นาย', value: 'นาย' },
    { label: 'นางสาว', value: 'นางสาว' },
    { label: 'นาง', value: 'นาง' },
  ]
  const prefixEN = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Mrs', value: 'Mrs' },
  ]

  // const userPermissions = [
  //   { label: 'STAFF', value: false },
  //   { label: 'NON ACADEMY', value: true },
  // ]
  return (
    <>
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 275,
          pt: 2,
          px: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          {!IsNonAcademy && (
            <Box sx={{ width: '50%' }}>
              <StaffUpload
                handleUploadFile={handleUploadFile}
                value={formik.values.imageFull}
                textError={formik?.errors?.imageFull}
                imageType={'full'}
                name={'full'}
                style={{ width: 120 }}
                onDelete={handleDeleteFile}
                formik={formik}
                setDialog={setDialog}
              />
            </Box>
          )}

          <Box sx={{ width: '50%' }}>
            <StaffUpload
              handleUploadFile={handleUploadFile}
              value={formik.values.image}
              textError={formik?.errors?.image}
              imageType={'small'}
              name={'small'}
              style={{ width: 100, height: 100, borderRadius: '50%' }}
              onDelete={handleDeleteFile}
              formik={formik}
              setDialog={setDialog}
            />
          </Box>
        </Box>

        {/* <Box
          sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '50%' }}
        >
          <Select
            dataTestid="select-user-permission"
            id="user-permission"
            name="user-permission"
            labelText="ประเภทสิทธิ์ผู้ใช้งาน"
            type="basic"
            disabled={!checkPermission ? true : false}
            boxSx={{ width: '50%' }}
            options={userPermissions}
            handleChange={() => setIsNonAcademy(!IsNonAcademy)}
            value={IsNonAcademy}
            placeholder={'เลือก'}
          ></Select>
        </Box> */}

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            id="agentCode"
            name="agentCode"
            labelText="รหัสพนักงาน"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.agentCode}
            required
            value={formik.values.agentCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '50%' }}
          >
            <Select
              dataTestid="select-prefix"
              id="prefix"
              name="prefix"
              labelText="Title Name"
              type="basic"
              disabled={!checkPermission ? true : false}
              boxSx={{ width: '50%' }}
              textError={formik?.errors?.prefix}
              required
              options={prefixEN}
              handleChange={(e) => onSelectChange(e, 'prefix')}
              value={formik.values.prefix}
              placeholder={'เลือก'}
            ></Select>
            <TextInput
              id="firstName"
              name="firstName"
              labelText="First Name"
              type="basic"
              disabled={!checkPermission ? true : false}
              textError={formik?.errors?.firstName}
              required
              boxSx={{ width: '50%' }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <TextInput
            id="lastName"
            name="lastName"
            labelText="Last Name"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.lastName}
            boxSx={{ width: '50%' }}
            required
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '50%' }}
          >
            <Select
              dataTestid="select-prefix-th"
              id="prefixTH"
              name="prefixTH"
              labelText="คำนำหน้า"
              type="basic"
              disabled={!checkPermission ? true : false}
              textError={formik?.errors?.prefixTH}
              required
              boxSx={{ width: '50%' }}
              options={prefixTH}
              handleChange={(e) => onSelectChange(e, 'prefixTH')}
              value={formik.values.prefixTH}
              placeholder={'เลือก'}
            ></Select>
            <TextInput
              id="firstNameTH"
              name="firstNameTH"
              labelText="ชื่อ"
              type="basic"
              disabled={!checkPermission ? true : false}
              textError={formik?.errors?.firstNameTH}
              required
              boxSx={{ width: '50%' }}
              value={formik.values.firstNameTH}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <TextInput
            id="lastNameTH"
            name="lastNameTH"
            labelText="นามสกุล"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.lastNameTH}
            required
            boxSx={{ width: '50%' }}
            value={formik.values.lastNameTH}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
      </Card>
    </>
  )
}
export default General
