import _ from 'lodash'

export const handleInputNumber = (e, format) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value

  if (!format.test(key)) e.target.value = e.target.value.replace(/[^\d]/g, '')

  return e.target.value
}
