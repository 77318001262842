import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import TextInput from '../../../../../../../components/Input/TextInput'
import { setChangeQuestionByKey } from '../../../../../../../redux/slices/eTestingForm'
import { getErrorMessage } from '../../../event'
import { handleDeleteForm } from './events'
import { StyledBlankContent } from './Styled'

const BlankInput = (props) => {
  const { data, index, keyQuestion, indexQuestion, isTrick } = props
  const dispatch = useDispatch()
  const { question, errorSchemaMessage, disableForm } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )
  const target = _.get(data, 'target', null)
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer: index,
  })

  if (_.isNil(target) && !isTrick) return <></>
  if (!_.isNil(target) && isTrick) return <></>

  return (
    <StyledBlankContent>
      <Typography variant="body1b" color="text.secondary">
        {!isTrick && `ช่องว่าง ${target}`}
      </Typography>
      <Box>
        <TextInput
          id="blank-answer"
          name="blank"
          placeholder="คำตอบ"
          type="basic"
          disabled={disableForm}
          value={_.get(data, 'title', '')}
          onChange={(e) =>
            dispatch(
              setChangeQuestionByKey({
                keyQuestion,
                key: `answers[${index}].title`,
                value: e.target.value,
              })
            )
          }
          textError={answerErrorMessage}
          multiline
          maxRows={2}
        />
        <IconButton
          size="m"
          onClick={() =>
            dispatch(handleDeleteForm({ ...props, question, keyQuestion }))
          }
        >
          <Close />
        </IconButton>
      </Box>
    </StyledBlankContent>
  )
}

export default BlankInput
