import { store } from '../../../../../App'
import _ from 'lodash'
import { enumProductType } from '../../../../../modules/Inventory/constant'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { crudConfig } = store.getState().crud
  return {
    warehouseUuid: _.get(crudConfig, 'warehouseUuid', ''),
    productUuid: _.get(crudConfig, 'productUuid', ''),
    warehouseStatus: _.get(filterProp, 'statusWarehouse', [
      'ACTIVE',
      'INACTIVE',
      'DELETED',
    ]),
    productStatus: _.get(filterProp, 'status', [
      'ACTIVE',
      'INACTIVE',
      'DELETED',
    ]),
    search: _.get(filterProp, 'search', ''),
    startCreatedDate: _.get(filterProp, 'startCreatedDate', ''),
    endCreatedDate: _.get(filterProp, 'endCreatedDate', ''),
    startUpdatedDate: _.get(filterProp, 'startUpdatedDate', ''),
    endUpdatedDate: _.get(filterProp, 'endUpdatedDate', ''),
    productType: _.get(filterProp, 'productType', [
      enumProductType.STOCK,
      enumProductType.STOCK_ASSET,
      enumProductType.ASSET,
      enumProductType.LICENSE,
    ]),
    stock: _.get(filterProp, 'stock', ''),
    station: _.get(filterProp, 'station', ''),
    totalFrom: _.get(filterProp, 'totalFrom', ''),
    totalTo: _.get(filterProp, 'totalTo', ''),
    availableFrom: _.get(filterProp, 'availableFrom', ''),
    availableTo: _.get(filterProp, 'availableTo', ''),
    stockValueFrom: _.get(filterProp, 'stockValueFrom', ''),
    stockValueTo: _.get(filterProp, 'stockValueTo', ''),
  }
}
