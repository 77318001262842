import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import { StyledBox } from '../Styled'

import { setOnView } from '../../../../../redux/slices/table2'
import _ from 'lodash'

const ActionEvaStatusDetail = ({ row, rowIndex, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const viewUrl = `${_.get(condition, 'viewPath', '')}/${_.get(
    row,
    'learnerUuid',
    '',
  )}`
  return (
    <StyledBox>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => dispatch(setOnView({ history, viewUrl }))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>
    </StyledBox>
  )
}

export default ActionEvaStatusDetail
