import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const masterDataLicenseForm = createSlice({
  name: 'masterDataLicenseForm',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setFetchData: (state, { payload }) => {
      state.initialState = payload
    },
    setFetchDataOption: (state, { payload }) => {
      state.initialState.information = initialState.initialState.information
      state.initialState.licenseConfig = []
      state.initialState.listDistribution = _.get(
        payload,
        'listDistribution',
        [],
      )
      state.initialState.listDistributionELearning = _.get(
        payload,
        'listDistributionELearning',
        [],
      )
      state.initialState.listType = _.get(payload, 'listType', [])
      state.initialState.licenseList = _.get(payload, 'licenseList', [])
    },
    // Information
    setChangeInformation: (state, { payload }) => {
      const { key, value } = payload
      state.initialState.information[key] = value
    },
    setChangeFile: (state, { payload }) => {
      const { title, documentKey } = payload
      const value = [
        ...state.initialState.information.documents,
        {
          title,
          documentKey,
        },
      ]
      _.set(state, 'initialState.information.documents', value)
    },
    // licenseConfig
    setAddConfigGroups: (state, { payload }) => {
      const licenseConfig = state.initialState.licenseConfig
      _.set(state, 'initialState.licenseConfig', [...licenseConfig, payload])
    },
    setDeleteConfigGroups: (state, { payload }) => {
      const licenseConfig = [...state.initialState.licenseConfig]
      licenseConfig.splice(payload, 1)
      _.set(state, 'initialState.licenseConfig', licenseConfig)
    },
    setChangeConfigGroups: (state, { payload }) => {
      const { indexConfig, key, value } = payload
      const licenseConfig = state.initialState.licenseConfig
      const findLicenseConfig = licenseConfig.find(
        (i, index) => index === indexConfig,
      )

      if (key == 'distributionChannelUuid') {
        _.set(licenseConfig, `[${indexConfig}]`, {
          configGroup: 0,
          distributionChannelUuid: value,
          flag: 'N',
          groups: [
            {
              group: 0,
              subGroups: [
                {
                  contents: [
                    {
                      subSequence: 0,
                      type: '',
                      courseOrLicenseUuid: '',
                    },
                  ],
                },
              ],
            },
          ],
        })
      } else {
        _.set(findLicenseConfig, key, value)
      }
    },
    // content
    setChangeContents: (state, { payload }) => {
      const {
        indexConfig,
        indexGroup,
        indexSubGroup,
        indexContent,
        key,
        value,
      } = payload
      const licenseConfig = state.initialState.licenseConfig
      const itemContent = _.get(
        licenseConfig,
        `[${indexConfig}].groups[${indexGroup}].subGroups[${indexSubGroup}].contents[${indexContent}]`,
        {},
      )
      if (key === 'type') _.set(itemContent, 'courseOrLicenseUuid', '')
      // COURSE LICENSE
      _.set(itemContent, key, value)
    },
    setDeleteContents: (state, { payload }) => {
      const { indexConfig, indexGroup, indexSubGroup, indexContent } = payload
      const licenseConfig = state.initialState.licenseConfig
      const key = `[${indexConfig}].groups[${indexGroup}].subGroups[${indexSubGroup}].contents`
      const itemContent = _.get(licenseConfig, key, [])

      const keySubGroup = `[${indexConfig}].groups[${indexGroup}].subGroups`
      const itemSubGroup = _.get(licenseConfig, keySubGroup, [])

      if (itemContent.length == 1) {
        if (itemSubGroup.length == 1) {
          const keyGroup = `[${indexConfig}].groups`
          const itemGroup = _.get(licenseConfig, keyGroup, [])
          itemGroup.splice(indexGroup, 1)
        } else {
          itemSubGroup.splice(indexSubGroup, 1)
        }
      } else {
        itemContent.splice(indexContent, 1)
        _.set(licenseConfig, key, itemContent)
      }
    },
    setAddContents: (state, { payload }) => {
      const { indexConfig, indexGroup, indexSubGroup, indexContent, valueObj } =
        payload
      const licenseConfig = state.initialState.licenseConfig
      const key = `[${indexConfig}].groups[${indexGroup}].subGroups[${indexSubGroup}].contents`
      const itemContent = _.get(licenseConfig, key, [])
      itemContent.splice(indexContent + 1, 0, valueObj)
      _.set(licenseConfig, key, itemContent)
    },
    // subGroups
    setAddSubGroups: (state, { payload }) => {
      const { indexConfig, indexGroup, valueObj } = payload
      const licenseConfig = state.initialState.licenseConfig
      const key = `[${indexConfig}].groups[${indexGroup}].subGroups`
      const itemSubGroup = _.get(licenseConfig, key, [])
      _.set(licenseConfig, key, [...itemSubGroup, valueObj])
    },
    // Groups
    setAddGroups: (state, { payload }) => {
      const { indexConfig, valueObj } = payload
      const licenseConfig = state.initialState.licenseConfig
      const key = `[${indexConfig}].groups`
      const itemGroup = _.get(licenseConfig, key, [])
      _.set(licenseConfig, key, [...itemGroup, valueObj])
    },
    setDeleteGroups: (state, { payload }) => {
      const { indexConfig, indexGroup } = payload
      const licenseConfig = state.initialState.licenseConfig
      const key = `[${indexConfig}].groups`
      const itemGroup = _.get(licenseConfig, key, [])
      itemGroup.splice(indexGroup, 1)
      _.set(licenseConfig, key, itemGroup)
    },
  },
})

export const {
  startLoading,
  stopLoading,

  setFetchData,
  setFetchDataOption,
  // Information
  setChangeFile,
  setChangeInformation,
  // licenseConfig
  setAddConfigGroups,
  setDeleteConfigGroups,
  setChangeConfigGroups,
  // content
  setChangeContents,
  setDeleteContents,
  setAddContents,
  // subGroups
  setAddSubGroups,
  // Groups
  setAddGroups,
  setDeleteGroups,
} = masterDataLicenseForm.actions

export default masterDataLicenseForm.reducer
