import { MODULE_STATUS } from '../../../../constants/learningPoint'

export const initialValues = {
  id: '',
  name: '',
  description: '',
  point: '',
  qty: '',
  image: {},
  fileName: '',
  remark: '',
  status: MODULE_STATUS.ACTIVE,
  isActive: true,
}
export const initialState = {
  isLoading: false,
  body: initialValues,
  preview: initialValues,
  view: initialValues,
  isDraft: false,
}
