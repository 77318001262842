import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  toDoListFilter,
  toDoListSummary,
  toDoListNotificationFilter,
  toDoListClearNotification,
  toDoListUpdateStatus,
} from '../../utils/apiPath'

export const getToDoListSummary = createAsyncThunk(
  'toDoList/getToDoListSummary',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(toDoListSummary, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getToDoListFilter = createAsyncThunk(
  'toDoList/getToDoListFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(toDoListFilter, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getToDoListNotificationFilter = createAsyncThunk(
  'toDoList/getToDoListNotificationFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.get(toDoListNotificationFilter, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const clearNotification = createAsyncThunk(
  'toDoList/toDoListClearNotification',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.get(toDoListClearNotification, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const updateStatusToDoList = createAsyncThunk(
  'toDoList/toDoListUpdateStatus',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(toDoListUpdateStatus, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
