import { Grid, Typography } from '@mui/material'
import { formattedNumber } from '../../../../../../modules/BudgetManagement/utils'

export const FinancialItem = ({ label, value = '' }) => {
  return (
    <Grid container spacing={2} pl={2.5}>
      <Grid item xs={5}>
        <Typography variant="body1b">{label}</Typography>
      </Grid>
      <Grid item xs={5} textAlign={'right'}>
        <Typography>{formattedNumber(value)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>บาท</Typography>
      </Grid>
    </Grid>
  )
}
