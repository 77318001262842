import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import { tableTypeList } from '../model/tableTypeList'
import useTheme from '@mui/system/useTheme'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleChangeHeadCells } from '../handler/handleChangeHeadCells'
import { NotiBox } from './Styled'
import { validatePermission } from '../../../../../utils/lib'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import { tableTypeEnum } from '../enum/tableTypeEnum'

export const TabsSelectTableType = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { tabActive, requisitionCount, tabTypeActive } = useSelector(
    (state) => ({
      tabActive: state.crud.tabActive,
      tabTypeActive: state.crud.tabTypeActive,
      requisitionCount: state.crud.requisitionCount,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setFieldValue({ key: 'tabActive', value: newValue }))
    handleChangeHeadCells(newValue, tabTypeActive)
  }

  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionInventory = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [
      STOCK_PERMISSION.ADMIN,
      STOCK_PERMISSION.MP,
      STOCK_PERMISSION.CREATE,
    ],
  })

  return (
    <Box
      display="flex"
      mx={2}
      borderBottom={`1px solid ${theme.palette?.blue?.blueLine}`}
    >
      <Tabs value={tabActive} onChange={handleChangeTab}>
        {_.map(tableTypeList, (tab, idx) => {
          let hidden = false
          if (
            !hasPermissionInventory &&
            [tableTypeEnum.APPROVE].includes(tab.value)
          )
            hidden = true
          return (
            <Tab
              sx={{
                minWidth: 0,
                width: 'auto',
                textTransform: 'none',
                color: theme.palette?.primary?.main,
                px: 1,
                '&.Mui-selected': {
                  borderBottom: `${theme.spacing(0.5)} solid`,
                  pt: `calc(${theme.spacing(2)} - 1px)`,
                  fontWeight: 700,
                },
                display: hidden ? 'none' : '',
              }}
              key={idx}
              label={
                <Box display="flex" gap={1}>
                  {tab.label}
                  {tab.value === tableTypeEnum.APPROVE && (
                    <NotiBox>{requisitionCount}</NotiBox>
                  )}
                </Box>
              }
            />
          )
        })}
      </Tabs>
    </Box>
  )
}
