import { defaultValue } from '../../../constants/eEvaluation'
export const initialState = {
  codeId: '',
  uuid: '',
  defaultTap: defaultValue.defaultTap.assessment,
  eEvaluationType: defaultValue.eEvaluationType.class,
  isLoading: false,
  isDraft: true,
  isClone: false,
  onTesting: false,
  onViewMode: false,
  disableForm: false,
  disableSetting: false,
  version: 0,
  totalLearnerAnswer: 0,
  information: {
    coverImage: '',
    coverText: '',
    description: '',
    descriptionPosition: defaultValue.position.left,
    name: '',
    title1: '',
    title1Position: defaultValue.position.left,
    title2: '',
    title2Position: defaultValue.position.left,
  },
  toggleQuestions: {
    courseAssessments: false,
    lecturerAssessments: false,
    courseManagementAssessments: false,
    questions: false,
  },
  courseAssessments: [],
  lecturerAssessments: [],
  courseManagementAssessments: [],
  questions: [],
  subQuestions: [],
  setting: {
    // get
    status: 'DRAFT',
    createdAt: null,
    createdBy: '-',
    updatedAt: null,
    updatedBy: '-',
    courseRef: [],
    // end get
    startDate: null,
    assignees: [],
    displayQuestion: defaultValue.displayQuestion.all, // ALL | ONE
    notification: true, // true | false
    isShare: false,
    shareLink: '',
  },
  listAssignee: [],
  dateServer: null,
  errors: {
    video: '',
  },
}

export const initialStateFeedback = {
  eEvaluationType: defaultValue.eEvaluationType.class,
  filter: {
    courseUuid: '',
    staffUuid: [],
    startDate: '',
    toDate: '',
    departmentUuid: [],
  },
  totalLearnerAnswer: 0,
  startDate: '',
  toDate: '',
  nextQuestions: {
    courseAssessments: [
      {
        sequence: 1,
        value: 1,
      },
    ],
    lecturerAssessments: [],
    courseManagementAssessments: [
      {
        sequence: 1,
        value: 1,
      },
    ],
    questions: [],
  },
  courseAssessments: {},
  lecturerAssessments: {},
  courseManagementAssessments: {},
  questions: [],
  subQuestions: [],
}
