import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import {
  StyledContainer,
  StyledCard,
  StyledCardLeftContainer,
  StyledCardRightContainer,
  TextContainer,
  StyledStaffContainer,
  RightContainer,
  LeftContainer,
} from './Styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LoginSlider from './LoginSlider'
import Footer from '../../../../components/Footer'

const Login = () => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const headSize = isNotSm ? 'h3' : 'h4'
  const titleSize = isNotSm ? 'h4' : 'h5'
  const descSize = isNotSm ? 'body1' : 'body2'

  return (
    <>
      <StyledContainer>
        <LeftContainer>
          <LoginSlider />
        </LeftContainer>
        <RightContainer>
          <Box>
            <Typography variant={headSize} color="primary.main">
              เข้าสู่ระบบ
            </Typography>
            <Typography variant={headSize} color="primary.main">
              KTAXA Academy (AMS)
            </Typography>
          </Box>
          <StyledCard sx={{ marginTop: '20px' }}>
            <StyledCardLeftContainer>
              <img
                alt="prospect-icon"
                src="/icon/prospect-icon.svg"
                style={{ height: '50px', width: 'auto' }}
              />
              <TextContainer>
                <Typography variant={titleSize} color="primary.main">
                  สำหรับตัวแทนรอรหัส
                </Typography>
              </TextContainer>
            </StyledCardLeftContainer>
            <StyledCardRightContainer content="space-between">
              <Link target="_blank" variant="body1" color="primary.main">
                ลืมรหัสผ่าน
              </Link>

              <Button
                size="s"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                sx={{ padding: '8px 16px', width: '111px', height: '40px' }}
              >
                <Typography variant="button" color="text.white">
                  เข้าสู่ระบบ
                </Typography>
              </Button>
            </StyledCardRightContainer>
          </StyledCard>

          <StyledCard>
            <StyledCardLeftContainer>
              <img
                alt="agent-icon"
                src="/icon/agent-icon.svg"
                style={{ height: '50px', width: 'auto' }}
              />
              <TextContainer>
                <Typography variant={titleSize} color="primary.main">
                  สำหรับตัวแทน KTAXA
                </Typography>
                <Box>
                  <Typography variant={descSize} color="primary.main">
                    ทุกช่องทางการจัดจำหน่าย
                  </Typography>
                  <Typography variant={descSize} color="primary.main">
                    (ใช้ username / password ของ AZ)
                  </Typography>
                </Box>
              </TextContainer>
            </StyledCardLeftContainer>
            <StyledCardRightContainer content="flex-end">
              <Button
                size="s"
                variant="outlined"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                sx={{ padding: '8px 16px', width: 113, height: '40px' }}
              >
                <Typography variant="button">เข้าสู่ระบบ</Typography>
              </Button>
            </StyledCardRightContainer>
          </StyledCard>
          <StyledStaffContainer>
            <Link variant="body1" color="primary.main">
              STAFF คลิกที่นี่
            </Link>
          </StyledStaffContainer>
        </RightContainer>
      </StyledContainer>
      <Footer />
    </>
  )
}

Login.templateLayout = 'login'

export default Login
