import React, { useEffect, useState } from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import PushPinIcon from '@mui/icons-material/PushPin'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { crudConfig } from '../../crudConfig'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/system'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { useDispatch, useSelector } from 'react-redux'
import { setPin } from '../events'
import { checkPreviewButton } from '../../Form/handler/checkPreviewButton'
import { handlePreview } from '../../Form/handler/handlePreview'
import { contentTypeKey } from '../model/contentType'

export function ActionComponent({ uuid, formData }) {
  const [user] = useLocalStorage('user')
  const [, setEContentPreview] = useLocalStorage('eContentPreview')
  const [previewDisabled, setPreviewDisabled] = useState(true)
  const hasPermissionEdit = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission:
      formData.contentType == contentTypeKey.E_CONTENT
        ? ['EDIT', 'EDIT_ECONTENT']
        : ['EDIT', 'EDIT_KB'],
  })
  const dispatch = useDispatch()
  const { isPinLoading } = useSelector((state) => ({
    isPinLoading: state.contentForm.isPinLoading,
  }))
  useEffect(async () => {
    setPreviewDisabled(await checkPreviewButton(formData))
  }, [formData])
  return (
    <Box display="flex" flexDirection="row" gap={3}>
      {(formData.status === 'ACTIVE' || formData.status === 'READY') &&
        formData.isActive === 'ACTIVE' && (
          <Button
            startIcon={
              isPinLoading ? (
                <CircularProgress size={20} />
              ) : formData.isPinned ? (
                <PushPinIcon sx={{ fontSize: 20 }} />
              ) : (
                ''
              )
            }
            variant="outlined"
            onClick={() => !isPinLoading && dispatch(setPin(formData.uuid))}
            disabled={!formData.isPinned && formData.totalPinned >= 6}
          >
            {`${formData.isPinned ? 'PINNED' : 'PIN'} (${
              formData.totalPinned || 0
            }/6)`}
          </Button>
        )}
      {formData.status !== 'DELETED' && (
        <Button
          data-testid="btn-preview"
          variant="outlined"
          size="m"
          startIcon={<RemoveRedEye />}
          disabled={previewDisabled}
          onClick={() => handlePreview(setEContentPreview)}
        >
          ดูตัวอย่าง
        </Button>
      )}
      <Button
        startIcon={<RestoreTwoToneIcon />}
        variant="outlined"
        onClick={() =>
          dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: true }))
        }
      >
        ประวัติการแก้ไข
      </Button>
      {formData.status !== 'DELETED' && hasPermissionEdit && (
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          onClick={() => {
            window.location.href = crudConfig.modulePath + '/form/' + uuid
          }}
          data-testid="btn-edit"
        >
          แก้ไข
        </Button>
      )}
    </Box>
  )
}
