import React, { useRef } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import Editor from '../../../../components/Editor/BasicEditor'

import { StyledCard, StyledFormWrapper } from '../../Styled'

const TermsForm = ({
    formik,
    body,
    setInputValue,
    isHide
}) => {
    const privacyEnEditorRef = useRef(null)
    const privacyThEditorRef = useRef(null)
    const termsEnEditorRef = useRef(null)
    const termsThEditorRef = useRef(null)

    return (
        <StyledFormWrapper isHide={isHide}>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        เงื่อนไขและข้อตกลง
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                นโยบายคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice)
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box>
                            <Editor
                                id="consentBodyTextEN"
                                name="consentBodyTextEN"
                                labelText="EN"
                                required
                                value={body.consentBodyTextEN}
                                editorRef={privacyEnEditorRef}
                                onBlur={() => {
                                    setInputValue('consentBodyTextEN', privacyEnEditorRef.current.getContent())
                                }}
                                textError={formik?.errors?.consentBodyTextEN}
                            />

                            <Editor
                                id="consentBodyTextTH"
                                name="consentBodyTextTH"
                                labelText="TH"
                                required
                                value={body.consentBodyTextTH}
                                editorRef={privacyThEditorRef}
                                onBlur={() => {
                                    setInputValue('consentBodyTextTH', privacyThEditorRef.current.getContent())
                                }}
                                textError={formik?.errors?.consentBodyTextTH}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                ข้อตกลงและเงื่อนไขการใช้งาน (เฉพาะ E-Learning)
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box>
                            <Editor
                                id="TERMS_AND_CONDITIONS_EN"
                                name="TERMS_AND_CONDITIONS_EN"
                                labelText="EN"
                                required
                                value={body.TERMS_AND_CONDITIONS_EN}
                                editorRef={termsEnEditorRef}
                                onBlur={() => {
                                    setInputValue('TERMS_AND_CONDITIONS_EN', termsEnEditorRef.current.getContent())
                                }}
                                textError={formik?.errors?.TERMS_AND_CONDITIONS_EN}
                            />

                            <Editor
                                id="TERMS_AND_CONDITIONS_TH"
                                name="TERMS_AND_CONDITIONS_TH"
                                labelText="TH"
                                required
                                value={body.TERMS_AND_CONDITIONS_TH}
                                editorRef={termsThEditorRef}
                                onBlur={() => {
                                    setInputValue('TERMS_AND_CONDITIONS_TH', termsThEditorRef.current.getContent())
                                }}
                                textError={formik?.errors?.TERMS_AND_CONDITIONS_TH}
                            />
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
        </StyledFormWrapper>

    )
}

export default TermsForm