import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Add from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { BoxAddChoiceQuestion } from './Styled'
import { handleAddAnswer } from './event'

const Index = ({ props }) => {
  const {
    sequence,
    itemQuestion,
    isSubQuestions,
    subQuestionSequence,
    disableForm,
  } = props
  const dispatch = useDispatch()

  const answers = _.get(itemQuestion, 'answers', [])
  const questionType = _.get(itemQuestion, 'questionType', 'SINGLE')
  const setNextQuestion = _.get(itemQuestion, 'setNextQuestion', false)
  const checkTypeOther = answers.find((item) => item.answerType === 'OTHER')
  const textColor = disableForm ? 'text.gray' : 'primary'
  const propsEvent = {
    setNextQuestion,
    sequence,
    questionType,
    answers,
    isSubQuestions,
    subQuestionSequence,
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <BoxAddChoiceQuestion
        lastMediaUrl={
          _.get(answers, `[${answers.length - 1}].loadingProgress`, 0)
            ? true
            : false
        }
        data-testid="add-answer-single"
        onClick={() => !disableForm && dispatch(handleAddAnswer(propsEvent))}
      >
        <Add color={textColor} />
        <Typography variant="buttons" color={textColor}>
          เพิ่มตัวเลือก
        </Typography>
      </BoxAddChoiceQuestion>

      {(questionType === 'SINGLE' || questionType === 'MULTIPLE') && (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={{ ml: 1, mr: 1.5 }}>
            หรือ
          </Typography>
          <Typography
            variant="buttons"
            color={checkTypeOther ? 'action.disabled' : textColor}
            sx={{ cursor: !checkTypeOther ? 'pointer' : 'default' }}
            disabled={true}
            onClick={() =>
              !disableForm &&
              !checkTypeOther &&
              dispatch(
                handleAddAnswer({
                  ...propsEvent,
                  isOther: true,
                })
              )
            }
          >
            เพิ่ม “อื่นๆ”
          </Typography>
        </Box>
      )}
    </Box>
  )
}
export default Index
