import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Grid)(() => ({
  display: 'flex',
}))

export const StyledPreviewBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledPreviewImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: theme?.palette?.background?.default,
  border: `1px solid ${theme.palette?.other?.outlineBorder}`,
  color: theme?.palette?.silver,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '508px',
}))

export const StyledButtonUploadBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    borderTop: `1px solid ${theme.palette?.other?.divider}`,
  },
  [theme.breakpoints.up('md')]: {
    borderLeft: `1px solid ${theme.palette?.other?.divider}`,
  },
}))

export const StyledGroupButtonUploadBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
}))

export const StyledSwitchButton = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing(0.5),
}))

export const ImagePreview = styled('img')({
  objectFit: 'contain',
  width: '100%',
  maxWidth: '2480px',
  height: '100%',
  borderRadius: '4px',
  pointerEvents: 'none',
})

export const StyledUploadButton = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  marginBottom: '8px',
}))

export const StyledContainerFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  marginTop: theme.spacing(3),
}))

export const StyledGroupFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '50%',
}))

export const StyledSubGroupFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '20%',
  },
  minWidth: '50%',
  gap: '10px 80px',
}))

export const StyledHistoryFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'right',
  alignItems: 'flex-end',
}))

export const StyledGroupButtonLayoutBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}))

export const StyledGroupLayoutLable = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
}))

export const StyledGroupLayoutBox = styled(Grid)(({ theme, height }) => ({
  width: '100%',
  height: `${height}px`,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  border: `1px solid ${theme.palette?.other?.divider}`,
  padding: '8px',
  overflow: 'auto',
  gap: '8px',
}))

export const StyledLayoutBox = styled(Grid)(({ theme, active, width }) => ({
  width: '3508px',
  height: '2480px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  outline: active
    ? `50px solid ${theme?.palette?.secondary?.main}`
    : `1px solid ${theme.palette?.other?.outlineBorder ?? ''}`,
  zoom: `calc(1 + (${width / 3.2} - 3508) / 3508)`,
}))

export const StyledBackgroundBox = styled(Grid)(
  ({ theme, active, width, url }) => ({
    width: '3508px',
    height: '2480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    outline: active
      ? `50px solid ${theme?.palette?.secondary?.main}`
      : `1px solid ${theme.palette?.other?.outlineBorder ?? ''}`,
    zoom: `calc(1 + (${width / 3.2} - 3508) / 3508)`,
  })
)

export const StyledBackgroundBlankBox = styled(Grid)(
  ({ theme, active, width }) => ({
    width: '3508px',
    height: '2480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    outline: active
      ? `50px solid ${theme?.palette?.secondary?.main}`
      : `1px solid ${theme.palette?.other?.outlineBorder ?? ''}`,
    zoom: `calc(1 + (${width / 3.2} - 3508) / 3508)`,
  })
)

export const StyledBlankLine45Rotate = styled('div')(({ theme }) => ({
  transform: 'rotate(-35deg)',
  height: '25px',
  backgroundColor: `${theme?.palette?.secondary?.main}`,
  width: '123%',
  marginTop: '35%',
}))

export const StyledLayoutBoxAll = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const HeaderLayout = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
}))

export const LayoutIconPreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  color: theme?.palette?.text?.silver,
}))
