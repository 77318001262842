import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import * as reducer from '../../../../redux/slices/eEvaluationForm'
import { read } from 'xlsx'
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'

import {
  openDialog,
  loadingDialog,
  closeDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const onErrorValidate = () => async (dispatch) => {
  dispatch(
    openErrorDialog({
      title: 'กรอกข้อมูลไม่ครบ',
      message: (
        <>
          <Typography variant="body1">
            ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ
          </Typography>
          <Typography variant="body1">หรือไม่ตรงตามเงื่อนไขที่กำหนด</Typography>
        </>
      ),
    })
  )
}

export const objQuestionType = {
  single_choice: 'SINGLE',
  multiple_choice: 'MULTIPLE',
  free_text: 'FREE_TEXT',
  rating: 'RATING',
}

export const handleImportTemplate = (props) => async (dispatch) => {
  const { dataImport, questions } = props
  dispatch(
    importDialog({
      status: !questions?.length ? 'questionEmpty' : 'questionNoEmpty',
      dataImport,
      questions,
    })
  )
}

export const submitImport = (dataImport, defaultQuestion) => (dispatch) => {
  dispatch(loadingDialog())

  const file = dataImport.target.files[0]
  const getNameFile = _.get(dataImport, 'target.files[0].name', '')

  if (file.length === 0) {
    removeValueImport()
    return dispatch(
      errorOutLoopImport({ status: 'fileInvalid', defaultQuestion })
    )
  }

  if (getNameFile) {
    const getExtensionFile = getNameFile.split('.').pop()
    const sizeFile = _.get(file, 'size', 0)
    if (sizeFile > 2000000) {
      removeValueImport()
      return dispatch(
        errorOutLoopImport({ status: 'fileOverSize', defaultQuestion })
      )
    }
    if (!checkTypeFileImport(getExtensionFile.toLowerCase())) {
      removeValueImport()
      return dispatch(
        errorOutLoopImport({ status: 'fileExtension', defaultQuestion })
      )
    }
  }
  setTimeout(async () => {
    const data = await file?.arrayBuffer()
    const workbook = read(data)
    const sheetEvaluation = _.get(workbook.Sheets, 'E-Evaluation', undefined)
    if (!sheetEvaluation) {
      removeValueImport()
      return dispatch(
        errorOutLoopImport({ status: 'fileInvalid', defaultQuestion })
      )
    }
    const { result, status } = dispatch(
      convertSheetToQuestions(sheetEvaluation, defaultQuestion)
    )
    removeValueImport()
    dispatch(
      reducer.setChange({
        key: 'questions',
        value: _.sortBy(result, ['key']),
      })
    )

    setTimeout(() => {
      if (!status) {
        dispatch(openDialog(dispatch(checkErrorMessage({ status: 'success' }))))

        dispatch(
          reducer.setChange({
            key: 'subQuestions',
            value: [],
          })
        )
        dispatch(
          reducer.setChange({
            key: 'toggleQuestions.questions',
            value: [],
          })
        )
      }

      dispatch(stopLoadingDialog())
    }, 500)
  }, 500)
}

export const convertSheetToQuestions =
  (sheet, defaultQuestion) => (dispatch) => {
    const { allCells, questionCells } = getDataCells(sheet)
    let itemQuestion = {
      result: [],
      status: false,
    }

    for (let index = 0; index < questionCells.length; index++) {
      const questionNumber = questionCells[index]

      const typeQuestion = _.snakeCase(
        _.get(sheet, `[C${questionNumber}].v`, undefined)
      )
      const titleQuestion = _.get(sheet[`B${questionNumber}`], 'v', '')
      const questionType = objQuestionType[typeQuestion]

      if (!typeQuestion || !titleQuestion) {
        itemQuestion = dispatch(errorInLoopImport(defaultQuestion))
        break
      }

      let listQuestion = {
        title: titleQuestion,
        questionType,
        sequence: index + 1,
        url: '',
        mediaType: 'IMAGE',
        setNextQuestion: false, // SINGLE
        setTypeOfAnswers: false, // MULTIPLE
        typeOfAnswers: 'EQUAL', // MULTIPLE
        numberOfAnswers: 0, // MULTIPLE
        isSuggestion: false, // RATING
      }

      if (questionType === 'SINGLE') {
        listQuestion = {
          ...listQuestion,
          answers: [],
          subQuestions: [],
        }
      }
      if (questionType === 'MULTIPLE') {
        listQuestion = {
          ...listQuestion,
          answers: [],
        }
      }

      if (questionType === 'RATING') {
        listQuestion = {
          ...listQuestion,
          questions: [],
          choices: [],
        }
      }

      const nextQuestion = questionCells[index + 1]
      let sequence = 0

      for (let indexAnswer = 0; indexAnswer < allCells.length; indexAnswer++) {
        const answerNumber = allCells[indexAnswer]
        if (
          answerNumber > questionNumber &&
          isLessThanNextQuestion(answerNumber, nextQuestion)
        ) {
          sequence++

          let itemValue = {
            id: `id-${sequence}`,
            sequence,
            title: _.get(sheet[`B${answerNumber}`], 'v', ''),
          }

          if (questionType === 'SINGLE') {
            itemValue = {
              ...itemValue,
              nextQuestionType: 'NONE',
              nextQuestionSequence: 0,
              nextSubQuestionSequence: 0,
            }
          }
          if (questionType === 'SINGLE' || questionType === 'MULTIPLE') {
            listQuestion.answers.push({
              ...itemValue,
              mediaUrl: '',
              answerType: 'CHOICE',
            })
          } else if (questionType === 'RATING') {
            listQuestion.questions.push(itemValue)
            listQuestion.choices = mapListChoices(
              _.get(sheet[`D${questionNumber}`], 'v', '')
            )
          }
        }
      }

      if (
        (questionType === 'SINGLE' || questionType === 'MULTIPLE') &&
        listQuestion.answers.length <= 0
      ) {
        itemQuestion = dispatch(errorInLoopImport(defaultQuestion))
        break
      }

      if (
        questionType === 'RATING' &&
        (listQuestion.questions.length <= 0 ||
          listQuestion.choices.length <= 0 ||
          listQuestion.choices.length > 10)
      ) {
        itemQuestion = dispatch(errorInLoopImport(defaultQuestion))

        break
      }

      itemQuestion.result.push(listQuestion)
    }

    return itemQuestion
  }

export const getDataCells = (sheet) => {
  const allCells = [] // all data
  const questionCells = [] // number of verses
  const check = 1
  for (const cell in sheet) {
    if (cell === '!ref' || cell === '!margins') continue
    let num = cell.match(/\d+/g)
    num = Number(num[0])
    if (num != check) {
      if (cell[0] === 'A') {
        questionCells.push(num)
      }
      allCells.push(num)
    }
  }

  return { allCells: _.uniq(allCells), questionCells }
}

export const isLessThanNextQuestion = (answerNumber, nextQuestion) => {
  return _.isUndefined(nextQuestion) || answerNumber < nextQuestion
}

export const checkTypeFileImport = (type) => {
  if (type === 'xlsx' || type === 'xls' || type === 'csv') return true
  return false
}

export const importDialog = (props) => (dispatch) => {
  const objDialog = dispatch(checkErrorMessage(props))
  dispatch(openDialog(objDialog))
}

export const checkErrorMessage = (props) => (dispatch) => {
  const { status, dataImport, questions } = props
  switch (status) {
    case 'fileInvalid':
      return {
        title: 'รูปแบบไฟล์ไม่ถูกต้อง',
        type: 'fail',
        message:
          'ไฟล์มีรูปแบบของเนื้อหาไม่ถูกต้อง กรุณาดาวน์โหลด Template ที่มีให้ และใช้งานตามที่กำหนดไว้',

        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'fileOverSize':
      return {
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        type: 'fail',
        message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'fileExtension':
      return {
        title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        type: 'fail',
        message:
          'ไม่สามารถ Import file ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้อง กรุณาดาวน์โหลด Template ที่มีให้ และใช้งานตามที่กำหนดไว้',
        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'questionEmpty':
      return {
        title: 'นำเข้า Template',
        type: 'confirm',
        message: 'คุณต้องการนำเข้า Template?',
        handleConfirm: () => dispatch(submitImport(dataImport, questions)),
        handleCancel: () => {
          removeValueImport()
          dispatch(closeDialog())
        },
      }
    case 'questionNoEmpty':
      return {
        title: 'นำเข้าทับคำถามปัจจุบัน',
        type: 'confirm',
        icon: <ErrorOutlineOutlined color="error" />,
        message:
          'คำถามปัจจุบัน จะถูกทับด้วย Template ที่คุณกำลังจะนำเข้าใหม่ ต้องการนำเข้า Template อยู่หรือไม่?',
        handleConfirm: () => dispatch(submitImport(dataImport, questions)),
        handleCancel: () => {
          removeValueImport()
          dispatch(closeDialog())
        },
      }
    case 'success':
      return {
        type: 'success',
        title: 'Import a template',
        message: 'นำเข้าข้อมูลสำเร็จ',
        agreeText: 'เปิดดู',
        handleConfirm: () => dispatch(closeDialog()),
      }
  }
}

export const errorInLoopImport = (defaultQuestion) => (dispatch) => {
  dispatch(importDialog({ status: 'fileInvalid', questions: defaultQuestion }))
  return {
    result: defaultQuestion,
    status: true,
  }
}

export const errorOutLoopImport =
  ({ status, defaultQuestion }) =>
  (dispatch) => {
    dispatch(importDialog({ status, questions: defaultQuestion }))
    removeValueImport()
  }

export const handleDisplayQuestion = (props) => (dispatch) => {
  dispatch(reducer.setChangeSetting(props))
}

export const removeValueImport = () => {
  const idInput = document.getElementById('btn-import-template-eva')
  if (idInput) idInput.value = null
}

export const mapListChoices = (text) => {
  const listText = text.split('/')
  let result = []
  if (listText.length <= 1 && listText[0] === '') return result
  listText.map((item, index) => {
    result.push({
      id: `id-${index + 1}`,
      sequence: index + 1,
      title: item,
    })
  })
  return result
}
