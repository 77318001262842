import { Box, styled, Typography } from '@mui/material'

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  maxHeight: 316,
  overflowY: 'auto',
  marginLeft: '8px',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledTableHead = styled(Box)(({ theme }) => ({
  height: 56,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  '& > .MuiTypography-root:first-of-type': { width: 38 },
  '& > .MuiTypography-root': {
    width: '40%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(Box)(({ theme }) => {
  let color = theme?.palette?.error?.main
  return {
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid',
    borderColor: theme.palette?.blue?.blueLine,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    background: 'transparent',
    whiteSpace: 'nowrap',
    '& > .MuiTypography-root:first-of-type': { width: 38 },
    '& > .MuiTypography-root': {
      width: '40%',
      fontSize: 18,
      lineHeight: '20px',
      wordBreak: 'break-all',
    },
    '& > #status': { color },
  }
})

export const StyledTableCell = styled(Typography)(() => ({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
}))
