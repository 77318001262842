import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const TextFieldRoomName = () => {
  const dispatch = useDispatch()
  const { filterRoomName } = useSelector(
    (state) => state.roomManagement.drawerCalendarFilter,
    shallowEqual,
  )
  return (
    <TextFieldInput
      type="string"
      value={filterRoomName}
      placeholder="ค้นหาชื่อห้อง"
      onChange={(e) =>
        dispatch(
          setReduxObjectValue({
            objectKey: 'drawerCalendarFilter',
            key: 'filterRoomName',
            value: e.target.value,
          }),
        )
      }
    />
  )
}
