import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const CheckboxFilterRoomName = () => {
  const dispatch = useDispatch()
  const { isFilterRoomName } = useSelector(
    (state) => ({
      isFilterRoomName:
        state.roomManagement.drawerCalendarFilter.isFilterRoomName,
    }),
    shallowEqual,
  )
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isFilterRoomName}
          onChange={(e) => {
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'isFilterRoomName',
                value: e.target.checked,
              }),
            )
            dispatch(
              setReduxObjectValue({
                objectKey: 'drawerCalendarFilter',
                key: 'filterRoomName',
                value: '',
              }),
            )
          }}
        />
      }
      label="ชื่อห้อง"
    />
  )
}
