import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"
import _ from "lodash"

export const handleAddOutsideContact = async () => {
    const { formData } = store.getState().crud
    const outsideContacts = _.get(formData, 'outsideContacts', [])
    store.dispatch(setFieldValue({
        key: 'formData.outsideContacts',
        value: [...outsideContacts, {
            name: '',
            phone: '',
            email: '',
        }],
    }))
}