import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../../../components/FilterDrawer/Styled'

const defaultFilter = {
  levelChecked: false,
  levelText: '',
  levelOfLearnerChecked: false,
  levelOfLearnerText: '',
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  statusChecked: false,
}
const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  const handleOnLevelTextChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    })
  }

  const handleChangeStatus = (event) => {
    if (event.target.name === 'activeChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: event.target.checked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'inActiveChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: event.target.checked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'deletedChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: event.target.checked,
        },
      })
    }
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-level-title"
                name="levelChecked"
                defaultChecked
                checked={filter.levelChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'levelChecked',
                  })
                }}
              />
            }
            label="Level"
            sx={!filter.levelChecked ? { marginBottom: -1 } : {}}
          />
          {filter.levelChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.levelChecked}
              placeholder="ค้นหา Level"
              value={filter.levelText}
              onChange={(e) =>
                handleOnLevelTextChange('levelText', e.target.value)
              }
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-levelOfLearner"
                name="levelOfLearnerChecked"
                defaultChecked
                checked={filter.levelOfLearnerChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'levelOfLearnerChecked',
                  })
                }}
              />
            }
            label="ระดับของผู้เรียน"
            sx={!filter.levelOfLearnerChecked ? { marginBottom: -1 } : {}}
          />
          {filter.levelOfLearnerChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.levelOfLearnerChecked}
              placeholder="ค้นหาระดับของผู้เรียน"
              value={filter.levelOfLearnerText}
              onChange={(e) =>
                handleOnLevelTextChange('levelOfLearnerText', e.target.value)
              }
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              data-testid="checkbox-level-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="เปิดใช้งาน"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="activeChecked"
                  checked={filter.status.activeChecked}
                  onChange={handleChangeStatus}
                />
              }
            />
            <FormControlLabel
              label="ปิดใช้งาน"
              control={
                <Checkbox
                  data-testid="checkbox-status-inactive"
                  name="inActiveChecked"
                  checked={filter.status.inActiveChecked}
                  onChange={handleChangeStatus}
                />
              }
            />
            <FormControlLabel
              label="ถูกลบแล้ว"
              control={
                <Checkbox
                  data-testid="checkbox-status-deleted"
                  name="deletedChecked"
                  checked={filter.status.deletedChecked}
                  onChange={handleChangeStatus}
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button sx={{ width: '45%' }} variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          sx={{ width: '45%' }}
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
