import { downloadFile } from '../../../../services/util'
import { store } from '../../../../App'
import { monthlyPlanDownloadSummary } from '../../../../utils/apiPath'
import { validateDate } from 'src/utils/lib'
import dayjs from 'dayjs'
import {
  startLoadingDashboard,
  stopLoadingDashboard,
} from 'src/redux/slices/monthlyPlan'
import { TYPE_DOWNLOAD_SUMMARY } from 'src/constants/monthlyPlan/dashboard'
import { tabsSummaryWorkloadList } from '../model'

export const downloadSummaryWorkload = async () => {
  store.dispatch(startLoadingDashboard())
  const { tabSumWorkloadActive, filters } =
    store.getState().monthlyPlan.dashboard

  let typeDownload = ''
  const downloadFileName = `Workload ${tabsSummaryWorkloadList[tabSumWorkloadActive].label}`
  switch (tabSumWorkloadActive) {
    case 0:
      typeDownload = TYPE_DOWNLOAD_SUMMARY.SUMMARY
      break
    case 1:
      typeDownload = TYPE_DOWNLOAD_SUMMARY.FIRST_VIEW
      break
    case 2:
      typeDownload = TYPE_DOWNLOAD_SUMMARY.SECOND_VIEW
      break
  }

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
    courseCodeList,
    positionList,
  } = filters

  const body = {
    type: typeDownload,
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList?.length > 0 ? departmentList : defaultDepartment,
    station: stationList?.length > 0 ? stationList : defaultStation,
    courseCode: courseCodeList?.length > 0 ? courseCodeList : [],
    position: positionList?.length > 0 ? positionList : [],
  }

  await store.dispatch(
    downloadFile({
      url: monthlyPlanDownloadSummary,
      body: body,
      fileName: `${downloadFileName}.xlsx`,
    }),
  )
  store.dispatch(stopLoadingDashboard())
}
