import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  eLearningLearningPathCourses,
  eLearningLearningPathFile,
  eLearningLearningPath,
  eLearningLearningPathDraft,
} from '../../../utils/apiPath'

export const getLearningPathCourses = createAsyncThunk(
  'eLearningPathForm/getLearningPathCourses',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningLearningPathCourses, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateLearningPath = createAsyncThunk(
  'eLearningPathForm/postCreateLearningPath',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLearningPath, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putEditLearningPath = createAsyncThunk(
  'eLearningPathForm/putEditLearningPath',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningLearningPath, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateLearningPathDraft = createAsyncThunk(
  'eLearningPathForm/postCreateLearningPathDraft',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLearningPathDraft, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadCoverImage = createAsyncThunk(
  'eLearningPathForm/postUploadCoverImage',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLearningPathFile, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteLearningPath = createAsyncThunk(
  'eLearningPathForm/deleteLearningPath',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${eLearningLearningPath}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
