import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const postBodyMutation = (data) => {
  return {
    descriptionName: _.get(data, 'descriptionName', ''),
    costCenter: _.get(data, 'costCenter', ''),
    groupCode: _.get(data, 'groupCode', ''),
    remark: _.get(data, 'remark', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    uuid: _.get(data, 'uuid', undefined),
  }
}
