import React, { useCallback, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'
import { handleLogout } from '../../components/Appbar/Appbar'

export default function Index() {
  const auth = useAuth()

  const login = useCallback(() => handleLogout(auth, true), [auth])

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (auth.isAuthenticated && user && window.location.pathname === '/') {
      window.location.href = '/manage/staff-profile'
    }
  }, [auth.isAuthenticated])

  return (
    <div>
      <Main />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', width: '300px', margin: 'auto' }}
      >
        <img src="/logo/logo_app.png" alt="AMS" height="50px" />
        <h2>
          AMS Backend {window.__env__.ENV === 'PROD' ? '' : window.__env__.ENV}
        </h2>
        <Button fullWidth variant="contained" onClick={login}>
          เข้าสู่ระบบ
        </Button>
      </Grid>
    </div>
  )
}
Index.templateLayout = 'login'

const Main = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  opacity: 0.1;
  background-color: #e5e5f7;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 40px
    ),
    repeating-linear-gradient(#444cf755, #444cf7);
`
