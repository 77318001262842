import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  responsiveText,
  StyledHeaderContainer,
} from './../../../../../components/Preview/Header/Styled'
import Round from './Round'
import { StyledHeaderTitle } from './Styled'

const Header = () => {
  const { information, section } = useSelector(
    (state) => ({
      information: state.eEvaluationPreview.information,
      section: state.eEvaluationPreview.section,
    }),
    shallowEqual
  )
  return (
    <StyledHeaderContainer align="end">
      <Box>
        <StyledHeaderTitle section={section}>
          <Typography variant={responsiveText('h4', 'h5', 'body2b')}>
            {_.get(information, 'title1', '')}
          </Typography>
          <Typography variant={responsiveText('h6', 'body1b', 'subtitle2')}>
            {_.get(information, 'title2', '')}
          </Typography>
        </StyledHeaderTitle>
        {section == 'RESULT' && <Round />}
      </Box>
    </StyledHeaderContainer>
  )
}

export default Header
