export const filterTestTypeItems = [
  {
    label: 'Pre-test',
    name: 'preTestChecked',
  },
  {
    label: 'Post-test',
    name: 'postTestChecked',
  },
  {
    label: 'Other',
    name: 'otherChecked',
  },
]

export const filterItems = (typeOfETesting) => {
  const filterItemsArray = [
    {
      label: `วันที่อบรม`,
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: `เลือกช่วงเวลา`,
      dateStateStart: 'startDate',
      dateStateFinish: 'endDate',
      dateStateDisplay: 'startDateDisplayDateChecked',
      type: 'daterange',
    },
    {
      label: `หลักสูตร`,
      nameCheckBox: 'courseCodeChecked',
      nameInput: 'courseCode',
      placeholder: `เลือกหลักสูตร`,
      type: 'select_dropdown_with_sub_dropdown',
      label2: `คลาส / รอบอบรม`,
      nameCheckBox2: 'classUuidChecked',
      nameInput2: 'classUuid',
      placeholder2: `เลือก คลาส / รอบอบรม`,
      fetchOptions: 'ByKey',
    },
    {
      label: `ช่องทางการจัดจำหน่าย`,
      nameCheckBox: 'distributionChecked',
      nameInput: 'distribution',
      placeholder: `เลือก ช่องทางการจัดจำหน่าย`,
      type: 'select_dropdown',
      fetchOptions: 'masterData',
      isReadOnly: true,
    },
    {
      label: `Unit Code`,
      nameCheckBox: 'unitCodeChecked',
      nameInput: 'unitCode',
      placeholder: `เลือก Unit Code`,
      type: 'select_dropdown',
      fetchOptions: 'ByKey',
      isReadOnly: true,
    },
    {
      label: `SRAM`,
      nameCheckBox: 'sRamChecked',
      nameInput: 'sRam',
      placeholder: `เลือก SRAM`,
      type: 'select_dropdown',
      fetchOptions: 'ByKey',
      isReadOnly: true,
    },
    {
      label: `RAM`,
      nameCheckBox: 'ramCodeChecked',
      nameInput: 'ramCode',
      placeholder: `เลือก RAM`,
      type: 'select_dropdown',
      fetchOptions: 'ByKey',
      isReadOnly: true,
    },
    {
      label: `Trainer`,
      nameCheckBox: 'trainerChecked',
      nameInput: 'trainer',
      placeholder: `เลือก Trainer`,
      type: 'select_dropdown',
      fetchOptions: 'masterData',
    },
    {
      label: `Agent Code`,
      nameCheckBox: 'agentCodeChecked',
      nameInput: 'agentCode',
      placeholder: `เลือก Agent Code`,
      type: 'select_dropdown',
      fetchOptions: 'ByKey',
    },
  ]
  if (typeOfETesting === 'ONLINE_TEST') {
    filterItemsArray.push({
      label: 'รูปแบบการทดสอบ',
      nameCheckBox: 'testTypeChecked',
      nameInput: 'testType',
      type: 'checkbox',
      list: filterTestTypeItems,
    })
  }
  return filterItemsArray
}
