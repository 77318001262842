import {
  startLoading,
  stopLoading,
  setInitialCreateForm,
  setEditForm,
  setFieldValue,
  setChange,
  setCourse,
} from '../../../../redux/slices/learningPoint/settingPoint'
import _ from 'lodash'
import {
  getDetail,
  getCourse,
  postCreateSettingPoint,
  putUpdateSettingPoint,
} from '../../../../services/learningPoint/settingPoint'
import { headerConfig } from '../model'
import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import { path } from '../../../../constants/path'
import { MODULE_STATUS } from '../../../../constants/learningPoint'
import { store } from '../../../../App'
import { courseType } from './model'
import { handleOnlyNumberValue } from '../../../../utils/lib'
import { setFieldUploadValue } from '../../../../redux/slices/uploadFile'
import { uniqId } from '../../../../services/util'

export const fetchSettingPointDetail = (props) => async (dispatch) => {
  const { uuid, onEdit, setOnEdit } = props
  if (onEdit) return
  dispatch(startLoading())

  const { payload: course } = await dispatch(getCourse())
  dispatch(setChange({ key: 'courseOptions', value: course }))

  if (_.isEmpty(uuid)) {
    dispatch(setInitialCreateForm())
  } else {
    const { payload } = await dispatch(getDetail({ uuid, headerConfig }))
    dispatch(
      setEditForm({
        ...payload,
        course:
          payload.course.length > 0
            ? payload.course
            : [
                {
                  uuid: { value: '', label: '' },
                  courseType: { value: '', label: '' },
                },
              ],
        badgeName: payload.badgeName || '',
        badgeDescription: payload.badgeDescription || '',
        badgeRemark: payload.badgeRemark || '',
        badgeImage: payload.badgeImage || {},
        badgeFileName: payload.badgeFileName || '',
        remark: payload.remark || '',
        startDate: payload.startDate || '',
        endDate: payload.endDate || '',
        status:
          payload.status === MODULE_STATUS.DRAFT
            ? payload.isActive
              ? MODULE_STATUS.ACTIVE
              : MODULE_STATUS.INACTIVE
            : payload.status,
      }),
    )
    if (!_.isEmpty(payload.badgeImage)) {
      const file = [
        {
          displayName: payload.badgeImage.fileName,
          downloadable: false,
          fileSize: payload.badgeImage.fileSize,
          fileType: payload.badgeImage.fileName.split('.').pop(),
          id: 1,
          key: payload.badgeImage.key,
          percent: 100,
          _id: uniqId(),
        },
      ]
      dispatch(
        setFieldUploadValue({
          key: 'formData.settingPointImages',
          value: { files: file, isUploading: false },
        }),
      )
    }
    dispatch(
      setChange({
        key: 'isDraft',
        value: payload.status === MODULE_STATUS.DRAFT,
      }),
    )
  }

  setOnEdit(true)
  dispatch(stopLoading())
}

export const handleChange = (key, value, isRoot) => (dispatch) => {
  const obj = { key, value }
  if (isRoot) dispatch(setChange(obj))
  else dispatch(setFieldValue(obj))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  let value = _.get(e.target, 'value', '')
  value = key === 'point' ? handleOnlyNumberValue(value) || '' : value
  dispatch(setFieldValue({ key, value }))
}

export const handleSubmit = (values, history, actions) => (dispatch) => {
  if (values.status == MODULE_STATUS.DRAFT) {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกฉบับร่าง',
        message: 'คุณต้องการบันทึกฉบับร่างนี้หรือไม่',
        handleConfirm: () =>
          dispatch(handleConfirmSubmit(values, history, actions)),
      }),
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
        handleConfirm: () =>
          dispatch(handleConfirmSubmit(values, history, actions)),
      }),
    )
  }
}

export const handleConfirmSubmit =
  (values, history, actions) => async (dispatch) => {
    dispatch(closeDialog())
    dispatch(startLoading())
    const courseUuid = []
    const elCourseUuid = []
    values.course
      .filter(
        (ele) => ele.courseType.value == courseType.COURSE && ele.uuid.value,
      )
      .map((ele) => {
        courseUuid.push(ele.uuid.value)
      })
    values.course
      .filter(
        (ele) => ele.courseType.value == courseType.ELEARNING && ele.uuid.value,
      )
      .map((ele) => {
        elCourseUuid.push(ele.uuid.value)
      })
    let response = {}
    let body = {
      ...values,
      badgeName: values.badgeName || null,
      badgeDescription: values.badgeDescription || null,
      badgeRemark: values.badgeRemark || null,
      badgeImage: !_.isEmpty(values.badgeImage) ? values.badgeImage : null,
      badgeFileName: values.badgeFileName || null,
      remark: values.remark || null,
      startDate: values.startDate || null,
      endDate: values.endDate || null,
      course: courseUuid,
      eLearningCourse: elCourseUuid,
      point: parseInt(values.point),
    }
    const isCreate = _.isEmpty(values.uuid)
    if (isCreate) {
      response = await dispatch(postCreateSettingPoint({ body }))
    } else {
      response = await dispatch(putUpdateSettingPoint({ body }))
    }
    dispatch(stopLoading())

    if (_.isNil(response.error)) {
      dispatch(
        openDialog({
          isCloseDialog: false,
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            history.push(`${path.settingPoint}/view/${response.payload.uuid}`)
            dispatch(closeDialog())
          },
        }),
      )
    } else {
      const key = _.get(
        response,
        'meta.response.data.message[0].constraints.key',
        '',
      )
      if (key == 'LearningPointNameExist') {
        const property = _.get(
          response,
          'meta.response.data.message[0].property',
          '',
        )
        const message =
          property === 'name'
            ? 'ชื่อนี้ถูกใช้ไปแล้ว กรุณาตั้งชื่อคะแนนใหม่'
            : property === 'badgeName'
            ? 'ชื่อนี้ถูกใช้ไปแล้ว กรุณาตั้งชื่อเหรียญรางวัลใหม่'
            : ''
        const error = {}
        response.meta.response.data.message.map((ele) => {
          error[ele.property] = ele.constraints.value
        })
        dispatch(
          openDialog({
            type: 'fail',
            isCloseDialog: false,
            title: 'ผิดพลาด',
            message: message,
            handleError: () => {
              actions.setErrors(error)
              dispatch(closeDialog())
            },
          }),
        )
      }
    }
  }

export const handleSelectType = (index, element) => async (dispatch) => {
  const settingPoint = _.get(store.getState(), 'settingPoint', {})
  let course = [..._.get(settingPoint, 'body.course', [])]

  if (element) {
    dispatch(
      setCourse({
        index: index,
        value: { ...course[index], courseType: element },
      }),
    )
  } else {
    course[index] = {
      uuid: { value: '', label: '' },
      courseType: { value: '', label: '' },
    }
    dispatch(setFieldValue({ key: 'course', value: course }))
  }
}

export const handleSelectCourse = (index, element) => async (dispatch) => {
  const settingPoint = _.get(store.getState(), 'settingPoint', {})
  let course = [..._.get(settingPoint, 'body.course', [])]

  dispatch(
    setCourse({
      index: index,
      value: { ...course[index], uuid: element || { value: '', label: '' } },
    }),
  )
}
