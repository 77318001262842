import React from 'react'
import MapsHomeWorkOutlined from '@mui/icons-material/MapsHomeWorkOutlined'
import BusinessOutlined from '@mui/icons-material/BusinessOutlined'
import * as yup from 'yup'

export const breadcrumbExamination = () => [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'สนามสอบ', link: '/', pointer: false },
]

export const breadcrumbExaminationForm = ({titleText}) => [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'สนามสอบ', link: '/master-data/examination', pointer: true },
  { title: titleText, link: '/', pointer: false },
]

export const ExaminationOption = [
  {
    text: 'สนามสอบสมาคม',
    icon: <MapsHomeWorkOutlined />,
    link: '/master-data/examination/form',
  },
  {
    text: 'สนามสอบบริษัท',
    icon: <BusinessOutlined />,
    link: '/master-data/station',
  },
]

export const ExaminationRegionOption = [
  { label: 'กรุงเทพมหานคร', value: 'กรุงเทพมหานคร', },
  { label: 'ภาคกลาง', value: 'ภาคกลาง', },
  { label: 'ภาคใต้', value: 'ภาคใต้', },
  { label: 'ภาคตะวันตก', value: 'ภาคตะวันตก', },
  { label: 'ภาคตะวันออก', value: 'ภาคตะวันออก', },
  { label: 'ภาคตะวันออกเฉียงเหนือ', value: 'ภาคตะวันออกเฉียงเหนือ', },
  { label: 'ภาคเหนือ', value: 'ภาค', },
]

export const breadcrumbStation = () => [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'สเตชั่น', link: '/', pointer: false },
]

export const breadcrumbStationForm = ({titleText}) => [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'สเตชั่น', link: '/master-data/station', pointer: true },
  { title: titleText, link: '/', pointer: false },
]

export const validationSchema = yup.object({
  region: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
  provinceName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable(),
  locationCode: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(4, 'กรอกได้ไม่เกิน 4 ตัวอักษร')
    .nullable(),
  description: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
  annotation: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
})

export const validationSchemaStation = yup.object({
  stationName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
  stationNameEN: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable(),
  locationCode: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(4, 'กรอกได้ไม่เกิน 4 ตัวอักษร')
    .nullable(),
  description: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
  annotation: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
})

export const onView = (row, history, isStation = false) => {
  if(isStation){
    history.push('/master-data/station/view/' + row.uuid)
  }else{
    history.push('/master-data/examination/view/' + row.uuid)
  }
}

export const historyEvent = {
  'CREATED': 'สร้าง',
  'UPDATED': 'แก้ไข',
  'DELETED': 'ลบ',
}