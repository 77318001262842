import {
  examinationListFilter,
  examinationRegionList,
  examinationTemplateDownload,
  examinationStudentFilter,
  examinationStudentDetail,
  examinationSchedule,
  examinationUploadSchedule,
  examinationTime,
  examinationAssociationFilter,
  examinationCompanyFilter,
  examinationUploadStudent,
  examinationUpdateStudentStatus,
  examinationStudentEvent,
  examinationnUploadResult,
  examinationnUpdateResult,
  examinationCheckOtherDocConfig,
  examinationUploadOtherDocs,
} from '../../utils/apiPath'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { handleDownload } from '../../utils/lib'
import _ from 'lodash'

export const getExaminationListFilter = createAsyncThunk(
  'eExamination/getExaminationListFilter',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationListFilter, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationRegionFilter = createAsyncThunk(
  'eExamination/getExaminationRegionFilter',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationRegionList, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminerListFilter = createAsyncThunk(
  'eExamination/getExaminerListFilter',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationStudentFilter, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadTemplate = createAsyncThunk(
  'eExamination/downloadTemplate',
  async (bodyProps, { rejectWithValue }) => {
    try {
      await handleDownload({
        url: examinationTemplateDownload,
        body: bodyProps,
        fileName: `ตารางสนามสอบ.xlsx`,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminerListDetail = createAsyncThunk(
  'eExamination/getExaminerListDetail',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationStudentDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteExaminationSchedule = createAsyncThunk(
  'eExamination/deleteExaminationSchedule',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${examinationSchedule}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteExaminer = createAsyncThunk(
  'eExamination/deleteExaminer',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${examinationStudentDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadExaminationSchedule = createAsyncThunk(
  'eExamination/uploadExaminationSchedule',
  async ({ formData, eExamType }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${examinationUploadSchedule}/${eExamType}`,
        formData,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadExaminationStudent = createAsyncThunk(
  'eExamination/uploadExaminationStudent',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationUploadStudent, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const createScheduleExamination = createAsyncThunk(
  'eExamination/createScheduleExamination',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationSchedule, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationTime = createAsyncThunk(
  'eExamination/getExaminationTime',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.get(examinationTime)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchAllExaminationCompanyList = createAsyncThunk(
  'eExamination/fetchAllExaminationCompanyList',
  async (payload, { rejectWithValue }) => {
    try {
      const body = {
        search: '',
        searchStation: '',
        station: '',
        locationCode: '',
        createdBy: '',
        updatedBy: '',
        status: ['ACTIVE'],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'updatedAt',
      }
      const response = await axios.post(examinationCompanyFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchAllExaminationAssociationList = createAsyncThunk(
  'eExamination/fetchAllExaminationAssociationList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        search: '',
        region: [],
        provinceName: '',
        locationCode: '',
        createdBy: '',
        updatedBy: '',
        status: ['ACTIVE'],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'updatedAt',
      }
      const response = await axios.post(examinationAssociationFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateStudentStatus = createAsyncThunk(
  'eExamination/updateStudentStatus',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        examinationUpdateStudentStatus,
        bodyProps,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getStudentEvent = createAsyncThunk(
  'eExamination/updateStudentStatus',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationStudentEvent, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateStudentResult = createAsyncThunk(
  'eExamination/updateStudentResult',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationnUploadResult, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateStudentResultStatus = createAsyncThunk(
  'eExamination/updateStudentResultStatus',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.patch(examinationnUpdateResult, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const checkOtherDocConfig = createAsyncThunk(
  'eExamination/checkOtherDocConfig',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(examinationCheckOtherDocConfig)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadOtherDoc = createAsyncThunk(
  'eExamination/uploadOtherDoc',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(examinationUploadOtherDocs, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
