import { useState, useEffect } from 'react'
import axios from 'axios'

const useFetchList = (list, setLoading) => {
  const [data, setData] = useState(null)
  useEffect(async () => {
    await Promise.all(
      list.map((item) => {
        return axios({
          method: item.method,
          url: item.url,
          data: item?.body,
          headers: item?.headers,
        })
      }),
    )
      .then((res) => {
        setData(
          res.map((item) => {
            return item.data
          }) || [],
        )
      })
      .catch((er) => {
        console.log(er)
      })
      .finally(() => {
        setLoading && setLoading(false)
      })
  }, [])
  return data
}
export default useFetchList
