import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'COST_CENTER',
  moduleSlug: 'cost-center',
  moduleTitleFull: 'Cost Center',
  modulesTitleShort: 'Cost Center',
  modulePath: '/master-data/cost-center',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
