import React, { useState } from 'react'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { path } from '../../../../../constants/path'
import { COURSE_STATUS } from '../../../../../constants/eLearning'
import { Header } from '../../../components/View'
import HistoryDrawer from '../History'
import { handleOnPreview } from './events'

const HeaderContent = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const [, setLearningPath] = useLocalStorage('learningPathPreview')
  const [isOpen, setOpen] = useState(false)
  const result = useSelector(
    (state) => state.eLearningPathDetail.result,
    shallowEqual,
  )
  const isDeleted = _.get(result, 'status', '') === COURSE_STATUS.DELETED

  return (
    <>
      <Header
        isDeleted={isDeleted}
        onPreview={() => handleOnPreview(result, setLearningPath)}
        onOpenDrawer={() => setOpen(true)}
        onEdit={() =>
          history.push(`${path.eLearningLearningPath}/edit/${uuid}`)
        }
        permission={{ hasEdit: !isDeleted }}
      />
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </>
  )
}

export default HeaderContent
