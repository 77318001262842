import { formattedMonth } from '../../../DashboardSection/WeeklyBookingsCard/event'
import * as dayjs from 'dayjs'

export const LabelCurrentWeek = ({ mondayDate, endDate }) => {
  const duration = `${dayjs(mondayDate).date()} - ${dayjs(endDate).date()}`
  const month = formattedMonth(dayjs(endDate).month() + 1)
  const year = dayjs(endDate).year()
  return (
    <div>
      {duration} {month} {year}
    </div>
  )
}
