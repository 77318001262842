import * as reducerEva from '../../../../../../../redux/slices/eEvaluationForm'

export const handleDeleteQuestions = (props) => (dispatch) => {
  dispatch(reducerEva.startLoading())
  setTimeout(() => {
    props.isSubQuestions
      ? dispatch(reducerEva.setDeleteSubQuestions(props))
      : dispatch(reducerEva.setDeleteQuestionOther(props))
    dispatch(reducerEva.stopLoading())
  }, 700)
}

export const mapLabelSwitch = (questionType) => {
  switch (questionType) {
    case 'MULTIPLE':
      return 'กำหนดจำนวนเลือกตอบ'
    case 'RATING':
      return 'กล่อง ข้อเสนอแนะ/ความคิดเห็นเพิ่มเติม'
    default:
      return 'กำหนดคำถามต่อไปที่ตัวเลือก'
  }
}

export const handleChangeSwitch = (props) => (dispatch) => {
  const { questionType, isSubQuestions, sequence, value } = props

  if (!isSubQuestions) {
    dispatch(
      reducerEva.setChangeQuestionByKey({
        ...props,
        type: 'questions',
        key: mapKeySwitch(questionType),
      })
    )
    if (questionType === 'MULTIPLE') {
      dispatch(
        reducerEva.setChangeQuestionByKey({
          sequence,
          type: 'questions',
          key: 'numberOfAnswers',
          value: value ? 1 : 0,
        })
      )
    }
  } else {
    dispatch(
      reducerEva.setChangeSubQuestionByKey({
        ...props,
        key: mapKeySwitch(questionType),
      })
    )
    if (questionType === 'MULTIPLE') {
      dispatch(
        reducerEva.setChangeSubQuestionByKey({
          ...props,
          key: 'numberOfAnswers',
          value: value ? 1 : 0,
        })
      )
    }
  }
}

export const mapKeySwitch = (questionType) => {
  return questionType === 'SINGLE'
    ? 'setNextQuestion'
    : questionType === 'MULTIPLE'
    ? 'setTypeOfAnswers'
    : 'isSuggestion'
}
