import _ from 'lodash'
import axios from 'axios'
import {
  eEvaluationList,
  eTestingList,
  eTestingTypeList,
} from './../../../../utils/apiPath'
import { initialTestAndEvaluate } from './../../DefaultValue'

export const fetchETestingTypeList = (setETestingTypeOption) => {
  axios
    .get(eTestingTypeList)
    .then((value) => {
      const optionList = value.data.map((item) => ({
        label: item.title,
        value: item.title,
      }))
      setETestingTypeOption(optionList)
    })
    .catch((err) => console.log(err))
}

export const fetchETestingList = (testAndEva, setETestOptionProps) => {
  axios
    .get(eTestingList)
    .then((value) => {
      const options = handleFetchOption(value.data, testAndEva)
      setETestOptionProps(options) // { activeOptions, inactiveOptions }
    })
    .catch((err) => console.log(err))
}

export const fetchEEvaluationList = (testAndEva, setEEvaOptionProps) => {
  axios
    .get(eEvaluationList)
    .then((value) => {
      const options = handleFetchOption(value.data, testAndEva)
      setEEvaOptionProps(options)
    })
    .catch((err) => console.log(err))
}

export const handleFetchOption = (data, testOrEvaluate) => {
  const { testOrEvaluateName, testOrEvaluateUuid } = testOrEvaluate
  const activeOption = data.map((item) => {
    const optionObj = { label: item.name, value: item.uuid }
    if (item.typeOfETesting) optionObj.type = item.typeOfETesting
    return optionObj
  })
  let inactiveOption = []

  if (testOrEvaluateUuid != '') {
    const isOption = activeOption.some(
      (item) => item.value == testOrEvaluateUuid
    )
    if (!isOption) {
      const inactived = {
        label: `[ปิดใช้งาน] ${testOrEvaluateName}`,
        value: testOrEvaluateUuid,
      }
      inactiveOption.push(inactived)
    }
  }

  return { activeOption, inactiveOption }
}

export const handleInactiveOption = (
  testAndEva,
  optionProps,
  setOptionProps
) => {
  const { testAndEvaluateType } = testAndEva
  const { eTestOptionProps, eEvaOptionProps } = optionProps
  const { setETestOptionProps, setEEvaOptionProps } = setOptionProps

  if (testAndEvaluateType == 'E_TESTING') {
    setTestOrEvaluate(testAndEva, eTestOptionProps, setETestOptionProps)
  } else {
    setTestOrEvaluate(testAndEva, eEvaOptionProps, setEEvaOptionProps)
  }
}

export const setTestOrEvaluate = (testAndEva, optionProps, setOptionProps) => {
  const { testOrEvaluateUuid, testOrEvaluateName } = testAndEva
  const { activeOption, inactiveOption } = optionProps

  if (testOrEvaluateName && activeOption.length > 0) {
    const isOption = activeOption.some(
      (item) => item.value == testOrEvaluateUuid
    )

    if (!isOption && testOrEvaluateUuid != '') {
      const inactived = {
        label: `[ปิดใช้งาน] ${testOrEvaluateName}`,
        value: testOrEvaluateUuid,
      }
      setOptionProps((props) => ({
        ...props,
        inactiveOption: [inactived],
      }))
    } else {
      const inactiveLabel =
        inactiveOption.length > 0
          ? inactiveOption[0].label.replace('[ปิดใช้งาน] ', '')
          : ''

      if (testOrEvaluateName == inactiveLabel) {
        setOptionProps((props) => ({
          ...props,
          toggle: !props.toggle,
        }))
      } else {
        setOptionProps((props) => ({
          ...props,
          inactiveOption: [],
          toggle: !props.toggle,
        }))
      }
    }
  } else {
    setOptionProps((props) => ({
      ...props,
      inactiveOption: [],
      toggle: !props.toggle,
    }))
  }
}

export const handleModifyOption = (
  testAndEvaProps,
  optionProps,
  setOptionState
) => {
  const { activeOption, inactiveOption } = optionProps
  const { testAndEvaArr, index } = testAndEvaProps
  const selectedArr = testAndEvaArr
    .map((item) => ({
      label: item.testOrEvaluateName,
      value: item.testOrEvaluateUuid,
      type: item.type,
    }))
    .filter((item, i) => i != index && item.type == testAndEvaArr[index].type)
  const newOption = _.differenceBy(activeOption, selectedArr, 'value')

  let finalOption = []
  if (inactiveOption.length > 0) finalOption.push(...inactiveOption)
  finalOption.push(...newOption)
  setOptionState(finalOption)
}

export const handleDeleteSet = (testAndEvaProps, setInitialValues, formik) => {
  const { testAndEvaArr, index } = testAndEvaProps
  _.remove(testAndEvaArr, (_, i) => i == index)
  formik.setFieldValue('testAndEvaluate', testAndEvaArr)
  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
}

export const handleTypeRadioChange = (e, testAndEvaProps, setStateProps) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const { setInitialValues, setTestAndEvaType } = setStateProps
  let resetTestAndEvaArr = {
    ...initialTestAndEvaluate,
    testAndEvaluateType: e.target.value,
  }
  if (e.target.value == 'E_EVALUATION') {
    resetTestAndEvaArr = { ...resetTestAndEvaArr, testCriteriaType: 'REQUIRED' }
  }
  testAndEvaArr[index] = resetTestAndEvaArr

  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
  setTestAndEvaType(e.target.value)
}

export const handleSelectChange = (
  value,
  selectType,
  testAndEvaProps,
  setInitialValues
) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const testAndEva = testAndEvaArr[index]
  testAndEva[selectType] = value.includes('mui-') ? '' : value

  if (selectType == 'type') {
    testAndEva.testOrEvaluateUuid = ''
    testAndEva.otherType = ''
  }

  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
}

export const handleSettingRadioChange = (
  e,
  testAndEvaProps,
  setInitialValues
) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const testAndEva = testAndEvaArr[index]
  const { value, name } = e.target
  const isRequired = value == 'REQUIRED_PERCENT'

  if (!isRequired) testAndEva.criteriaPercent = null

  testAndEva[name] = value

  setInitialValues((prev) => ({
    ...prev,
    testAndEvaluate: testAndEvaArr,
  }))
}
