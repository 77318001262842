import { closeDialog, loadingDialog, openDialog } from '../../../../redux/slices/dialog'
import _ from 'lodash'
import { updateStudentStatus } from '../../../../services/eExamination'
import { fetchExaminerListFilter } from '../../ExaminerList/event'
import { store } from '../../../../App'
import { STUDENT_STATUS } from '../../../../constants/eExamination'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
}

export const resetFilter = (uuid) => (dispatch) => {
  const { table } = store.getState().table
  dispatch(
    fetchExaminerListFilter({
      uuid,
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      filterText: null,
      keyword: '',
    }),
  )
  dispatch(closeDialog())
}

export const handleSubmitAnnounce =
  (uuid, selected, rows, scheduleName, annotation) => async (dispatch) => {
    dispatch(loadingDialog())

    const mapSelectedList = selected.map((item) => {
      return { uuid: item }
    })
    const intersecSelected = _.intersectionBy(rows, mapSelectedList, 'uuid')
    const filterWaitingAnnounceStatus = intersecSelected.filter(
      (item) => item.statusStudent !== STUDENT_STATUS.WAITING_ANNOUNCEMENT,
    )
    const uuidWaitingAnnounceList = filterWaitingAnnounceStatus.map((item) => {
      return item.uuid
    })

    const body = {
      list: uuidWaitingAnnounceList,
      status: STUDENT_STATUS.ANNOUNCEMENT,
      schedule: uuid,
      scheduleName,
      annotation,
    }

    const response = await dispatch(updateStudentStatus(body))
    if (!response?.error) {
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ประกาศผู้มีสิทธิ์เข้าสอบเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(resetFilter(uuid))
          },
        }),
      )
    } else {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message:
            'ไม่สามารถประกาศผู้มีสิทธิ์เข้าสอบได้ เนื่องจากระบบขัดข้อง โปรดลองใหม่อีกครั้ง',
          handleConfirm: () => {
            dispatch(resetFilter(uuid))
          },
        }),
      )
    }
  }
