import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Cancel from '@mui/icons-material/Cancel'
import ActiveSwitch from '../../modules/ManageContent/components/ActiveSwitch'
import {
  StyledButton,
  StyledIconButton,
  StyledResposiveBox,
  StyledTextContainer,
} from './Styled'

const FileInput = ({
  boxSx,
  labelText,
  labelVariant = 'body2',
  required,
  disabled,
  switchName,
  switchonChanged,
  switchChecked,
  onClick,
  textError,
  textInfo,
  value,
  isShowTextError = true,
  isImage,
  showMaxLength = 0,
  sxTextError,
  children,
  isUploadLoading,
  UploadLoading,
  StyledImagePreview,
  onDelete,
}) => {
  const isError = !_.isEmpty(textError)
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      {UploadLoading && UploadLoading}
      {!isUploadLoading &&
        (!isImage ? (
          <>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant={labelVariant}
                color={disabled ? 'text.gray' : 'text.secondary'}
              >
                {labelText}
              </Typography>
              {required && (
                <Typography
                  sx={{ lineHeight: 1, ml: 0.5 }}
                  color={disabled ? 'text.gray' : 'error'}
                >
                  *
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <StyledResposiveBox>
                <StyledButton
                  data-testid="btn-preview"
                  onClick={onClick}
                  component="label"
                >
                  <Typography color="#000">เลือกไฟล์</Typography>
                  {children}
                </StyledButton>
                <Box sx={{ flex: 1, border: '1px solid #CCCCCC' }}>
                  <Typography
                    color="text.silver"
                    sx={{ px: 2, py: 1, width: `50%` }}
                  >
                    ยังไม่เลือกไฟล์
                  </Typography>
                </Box>
              </StyledResposiveBox>

              <ActiveSwitch
                name={switchName}
                checked={switchChecked}
                onChange={switchonChanged}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <StyledImagePreview>
              <img src={`${window.__env__.REACT_APP_API_URL}/file${isImage}`} />
            </StyledImagePreview>
            <StyledIconButton onClick={onDelete} size="small">
              <Cancel color="error" />
            </StyledIconButton>
            <ActiveSwitch
              sx={{ marginLeft: 'auto' }}
              name={switchName}
              checked={switchChecked}
              onChange={switchonChanged}
            />
          </Box>
        ))}

      {isShowTextError && (
        <StyledTextContainer textError={textError}>
          {!isImage && textInfo && !isError && (
            <Typography height={25} variant="body2" color="text.secondary">
              {textInfo}
            </Typography>
          )}
          {isError && (
            <Typography
              sx={sxTextError ? sxTextError : { height: '25px' }}
              variant="body2"
              color="error"
            >
              {textError}
            </Typography>
          )}
          {showMaxLength > 0 && (
            <Typography
              sx={{
                height: '25px',
                textAlign: 'end',
                color: value?.length > showMaxLength ? 'error.main' : 'unset',
              }}
              variant="body2"
            >
              {`${value?.length}/${showMaxLength}`}
            </Typography>
          )}
        </StyledTextContainer>
      )}
    </Box>
  )
}

export default FileInput
