import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddLocation from '@mui/icons-material/AddLocation'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Edit from '@mui/icons-material/Edit'
import { setAddStation } from '../../../../../redux/slices/manageContent/station'
import { formatPhoneNo } from '../../../../../utils/lib'
import ActiveSwitch from '../../../components/ActiveSwitch'
import { StyledColumn, StyledRow } from '../../Styled'
import ShowHideButton from '../ShowHideButton'
import { handleSelector, handleSwitchBody } from '../events'
import { StyledSection } from '../Styled'
import {
  StyledStationBottom,
  StyledStationMedia,
  StyledBoxStation,
  StyledContainerStation,
  StyledTypography,
  StyledContainContent,
} from './Styled'
import StationDrawer from './StationDrawer'
import { getDefaultValue, handleConfirmDelete } from './events'

const Station = () => {
  const [show, setShow] = useState(false)
  const keyField = 'stationInformation'
  const stationInformation = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <StyledSection>
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">Section 3 | Station</Typography>
        <ShowHideButton show={show} setShow={setShow} />
      </StyledRow>
      {show && (
        <>
          <StyledRow justifyContent="end">
            <Button
              size="m"
              variant="outlined"
              startIcon={<AddLocation />}
              onClick={() => dispatch(setAddStation())}
            >
              เพิ่มสถาบันฝึกอบรม
            </Button>
          </StyledRow>
          <StyledContainerStation>
            {stationInformation.map((station, index) => (
              <BoxStation
                key={index}
                data={station}
                index={index}
                keyField={keyField}
                onDelete={() => dispatch(handleConfirmDelete(index))}
              />
            ))}
          </StyledContainerStation>
        </>
      )}
    </StyledSection>
  )
}

export default Station

export const BoxStation = ({ data, index, onDelete }) => {
  const { errors } = useFormikContext()
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const no = index + 1
  const image = _.get(data, 'img', '')
  const stationError = _.get(errors, `stationInformation[${index}]`, undefined)
  const keyField = 'stationInformation'
  const status = _.get(data, 'status', false)
  return (
    <StyledBoxStation status={status} error={!_.isUndefined(stationError)}>
      <StyledColumn gap={2}>
        <StyledRow justifyContent="space-between" alignItems="center">
          <Typography variant="body1b">Station</Typography>
          <StyledRow sx={{ width: 'auto', gap: 3 }}>
            <IconButton
              data-testid={`btn-edit-${no}`}
              onClick={() => setOpen(true)}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton data-testid={`btn-delete-${no}`} onClick={onDelete}>
              <DeleteTwoTone fontSize="small" />
            </IconButton>
          </StyledRow>
        </StyledRow>
        <StyledContainContent>
          <StyledStationMedia>
            {image != '' && (
              <img src={`${window.__env__.REACT_APP_API_URL}/file/${image}`} />
            )}
            {image == '' && <img src={'/image/no-image.svg'} />}
          </StyledStationMedia>
          <StyledColumn gap={2} paddingBottom={1}>
            <StationContent
              label="Title"
              data={getDefaultValue(data, 'title')}
            />
            <StationContent
              label="Description"
              data={getDefaultValue(data, 'description')}
            />
            <StationContent
              label="Phone Number"
              data={formatPhoneNo(_.get(data, 'phoneNumber', '-'))}
            />
          </StyledColumn>
        </StyledContainContent>
      </StyledColumn>
      <StyledColumn>
        <StyledStationBottom>
          <ActiveSwitch
            name="status"
            checked={status}
            onChange={(e) =>
              dispatch(handleSwitchBody(e, `${keyField}[${index}]`))
            }
            dataTestId={`active-banner-${no}`}
          />
        </StyledStationBottom>
        <StationDrawer
          data={data}
          index={index}
          isOpen={isOpen}
          onCloseDrawer={() => setOpen(false)}
        />
      </StyledColumn>
    </StyledBoxStation>
  )
}

export const StationContent = ({ label, data }) => {
  return (
    <StyledColumn>
      <Typography variant="body2b">{label}</Typography>
      <StyledTypography
        label={label}
        variant="body1"
        color="text.gray"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </StyledColumn>
  )
}
