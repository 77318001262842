import React from 'react'
import { LoadingCircular, StyledLoadingBg } from '../Styled'

const LoadingPageCircular = ({ isLoading }) => {
  return (
    <StyledLoadingBg isLoading={isLoading}>
      <LoadingCircular isLoading />
    </StyledLoadingBg>
  )
}

export default LoadingPageCircular
