import React, { useCallback, useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import ReactPlayerLazy from 'react-player/lazy'
import { startVideoLoading } from '../../../../../../redux/slices/eLearning/preview'
import CircularContent from '../../../components/CircularContent'
import CustomControl from './CustomControl'
import {
  handleWindowBlur,
  handleVideoReady,
  progressHandler,
  handleEnded,
} from './events'
import {
  ReactPlayerStyle,
  ResponsiveWrapper,
  reactPlayerConfig,
} from './Styled'

const ReactPlayer = ({ url }) => {
  const wrapperRef = useRef(null)
  const videoPlayerRef = useRef(null)
  const [playerSecond, setSecond] = useLocalStorage('playerSecond')
  const dispatch = useDispatch()
  const { selectedLesson, isVideoLoading } = useSelector(
    (state) => ({
      selectedLesson: state.eLearningPreview.selectedLesson,
      isVideoLoading: state.eLearningPreview.isVideoLoading,
    }),
    shallowEqual,
  )
  const videoState = _.get(selectedLesson, 'videoState', {})
  const { playing, playbackRate, muted, volume, isFinished } = videoState
  const videoProps = { videoState, setSecond }

  const onReady = useCallback(
    () => dispatch(handleVideoReady(playerSecond, videoPlayerRef)),
    [videoPlayerRef.current],
  )

  useEffect(() => {
    dispatch(startVideoLoading())
  }, [])

  useEffect(() => {
    dispatch(handleWindowBlur())
  }, [videoState])

  useEffect(() => {
    if (isFinished) setSecond(null)
  }, [isFinished])

  if (videoState.isForceDisconnect) {
    return <ResponsiveWrapper />
  }

  return (
    <ResponsiveWrapper ref={wrapperRef}>
      <CircularContent color="#fff" isLoading={isVideoLoading} />

      <CustomControl
        url={url}
        wrapperRef={wrapperRef}
        videoPlayerRef={videoPlayerRef}
      />
      <ReactPlayerLazy
        playsinline
        width="100%"
        height="100%"
        ref={videoPlayerRef}
        url={url}
        playing={playing}
        playbackRate={playbackRate}
        muted={muted}
        volume={volume}
        style={ReactPlayerStyle}
        config={reactPlayerConfig}
        onReady={onReady}
        onEnded={() => dispatch(handleEnded(setSecond))}
        onProgress={(state) => dispatch(progressHandler(state, videoProps))}
      />
    </ResponsiveWrapper>
  )
}

export default ReactPlayer
