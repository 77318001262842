import { Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
// import { store } from '../../../../../../../App'
// import { setFieldValue } from '../../../../../../../redux/slices/crud'

const ViewString = ({
  value = '',
  limit = 180,
  customSx = {},
  type,
  buttonSx,
  openAll,
}) => {
  const renderValue = () => {
    if (_.isEmpty(value)) {
      return '-'
    }
    if (value.length <= limit) {
      return value
    }
    return `${value.substring(0, limit)}...`
  }
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(openAll)
  }, [openAll])

  return (
    <>
      {open ? (
        <Typography
          variant="body1"
          color=""
          sx={{
            alignContent: type === 'FREE_TEXT' ? 'unset ' : 'center',
            lineBreak: 'anywhere',
            letterSpacing: '0.15px',
            ...customSx,
          }}
        >
          {value}
          {value.length > limit && (
            <Button
              color="primary"
              variant="text"
              sx={{ ml: 1, ...buttonSx }}
              onClick={() => {
                setOpen(false)
              }}
            >
              ย่อลง
            </Button>
          )}
        </Typography>
      ) : (
        <Typography
          variant="body1"
          color=""
          sx={{
            alignContent: type === 'FREE_TEXT' ? 'unset ' : 'center',
            lineBreak: 'anywhere',
            letterSpacing: '0.15px',
            WebkitLineClamp: '2',
            textOverflow: 'ellipsis',
            height: '4em',
            ...customSx,
          }}
        >
          {renderValue()}
          {value.length > limit && (
            <Button
              color="primary"
              variant="text"
              sx={{ ml: 1, ...buttonSx }}
              onClick={() => {
                setOpen(true)
              }}
            >
              ดูเพิ่มเติม
            </Button>
          )}
        </Typography>
      )}
    </>
  )
}

export default ViewString
