import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  openErrorDialog,
} from '../../../redux/slices/dialog'
import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageContent/home'
import {
  getAllDistributionChannel,
  getManageContentHome,
  putManageContentHome,
  putManageContentInfo,
} from '../../../services/manageContent'
import ErrorCourseDialog from './ErrorCourseDialog'

export const fetchManageContent = (onEdit, setOnEdit) => async (dispatch) => {
  if (onEdit) return
  dispatch(startLoading())
  await dispatch(getManageContentHome())
  await dispatch(getAllDistributionChannel())
  setOnEdit(true)
  dispatch(stopLoading())
}

export const handleSubmitForm = (value) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกและเผยแพร่',
      message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirm(value)),
    })
  )
}

export const handleConfirm = (value) => async (dispatch) => {
  const coursesInformation = _.get(value, 'coursesInformation', {})
  delete value.coursesInformation

  dispatch(loadingDialog())

  dispatch(putManageContentHome(value))
    .then(async () => {
      const errorList = []

      for (const index in coursesInformation) {
        const course = coursesInformation[index]
        const response = await dispatch(putManageContentInfo(course))
        if (!_.isNil(response.error)) errorList.push(course.uuid)
      }
      
      if (errorList.length == 0) dispatch(handleSuccess())
      else {
        dispatch(
          openErrorDialog({
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            message: '',
            content: <ErrorCourseDialog errorList={errorList} />,
          })
        )
      }
    })
    .catch((err) => console.log(err))
}

export const handleSuccess = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      handleConfirm: () => window.location.reload(),
    })
  )
}

export const handleError = (error) => (dispatch) => {
  if (error) {
    dispatch(
      openErrorDialog({
        title: 'ผิดพลาด',
        message:
          'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ หรือไม่ตรงตามเงื่อนไขที่กำหนด',
      })
    )
  }
}
