export const defaultFilter = {
  nameChecked: false,
  name: '',
  categoryChecked: false,
  category: '',
  subCategoryChecked: false,
  subCategory: '',
  tagNameChecked: false,
  tagName: '',
  publishDateChecked: false,
  startPublishDate: '',
  endPublishDate: '',
  updateDateChecked: false,
  updateStartDate: '',
  updateFinishDate: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    draftChecked: false,
    deletedChecked: false,
  },
}
