import React, { useState } from 'react'
import { CContainer } from '../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { TabsSelectTableType } from './components/TabsSelectTableType'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { Header } from './components/Header'
import { requisitionType } from './model/requisitionType'
import { validatePermission } from '../../../../utils/lib'
import {
  STOCK_PERMISSION,
  INVENTORY_SETTING,
} from '../../../../constants/stock'
import { Box } from '@mui/material'
import { tabTypeEnum } from './enum/tabTypeEnum'
import callAxios from '../../../../utils/baseService'
import { stationFilter } from '../../../../utils/apiPath'
import { countWaitingApprove } from './handler/countOtherWaitingApprove'
import { handleChangeHeadCells } from './handler/handleChangeHeadCells'

export const InventoryRequisition = () => {
  const [isInitial, setIsInitial] = useState(true)
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
      tabTypeActive: state.crud.tabTypeActive,
      tabActive: state.crud.tabActive,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionMonthly = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.MP],
  })

  const initialize = async () => {
    initialConfig(initialCrudConfig)
    if (hasPermissionMonthly) {
      store.dispatch(setFieldValue({ key: 'tabTypeActive', value: 0 }))
    } else {
      store.dispatch(setFieldValue({ key: 'tabTypeActive', value: 1 }))
    }
    store.dispatch(setFieldValue({ key: 'tabActive', value: 0 }))
    store.dispatch(
      setFieldValue({
        key: 'masterData',
        value: {
          requisitionType: {
            isLoading: false,
            options: requisitionType.filter((item) => {
              if (
                !hasPermissionMonthly &&
                item?.value === tabTypeEnum.MONTHLY
              ) {
                return false
              } else {
                return item
              }
            }),
          },
        },
      }),
    )
    store.dispatch(
      setFieldValue({
        key: `formData.requisitionType`,
        value: hasPermissionMonthly
          ? requisitionType[0].value
          : requisitionType[1].value,
      }),
    )
    await handleChangeHeadCells(0, hasPermissionMonthly ? 0 : 1)
    fetchStationList()
    setIsInitial(false)
  }

  useEffect(() => {
    if (isInitial) {
      initialize()
    } else {
      if (!_.isUndefined(crudConfig)) {
        fetchDataList(mutateFilterPropToBody).then(() => {
          countWaitingApprove()
        })
      }
    }
  }, [limit, order, page, sort])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <Box mx={3}>
      <CContainer
        drawer={
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() =>
              handleFiltering(mutateFilterSateToProp, mutateFilterPropToBody)
            }
            filterItems={filterItems()}
            defaultFilter={defaultFilter}
          />
        }
        header={<Header />}
        content={<TabsSelectTableType />}
      />
    </Box>
  )
}

export const fetchStationList = async () => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }

  let result = []
  await callAxios
    .post(stationFilter, body)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const station = _.get(item, 'station', '')
        result.push({ label: station, name: uuid })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList',
      value: {
        station: result,
      },
    }),
  )
}
