export const mapTextHeader = (type) => {
  switch (type) {
    case 'courseAssessments':
      return {
        no: 1,
        header: 'แบบประเมินเนื้อหาหลักสูตร',
      }
    case 'lecturerAssessments':
      return {
        no: 2,
        header: 'แบบประเมินวิทยากร',
      }
    case 'courseManagementAssessments':
      return {
        no: 3,
        header: 'แบบประเมินการบริหารจัดการหลักสูตร',
      }
    case 'questions':
      return {
        no: 4,
        header: 'แบบประเมิน / แบบสอบถามอื่นๆ',
      }
  }
}
