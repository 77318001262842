import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
const manageClassDocs = createSlice({
  name: 'manageClassDocs',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    docsAdd: (state, { payload }) => {
      state.docs = [...state.docs, ...payload]
    },
    docsSetData: (state, { payload }) => {
      state.docs = payload
    },
  },
})

export const { startLoading, stopLoading, docsAdd, docsSetData } =
  manageClassDocs.actions

export default manageClassDocs.reducer
