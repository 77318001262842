import { MODULE_STATUS } from "../../../../constants/eLearning"

export const initialValues = {
  moduleCode: null,
  moduleType: null,
  name: '',
  description: '',
  status: MODULE_STATUS.ACTIVE,
  isInProgress: false,
}

export const initialState = {
  isLoading: false,
  isOptionLoading: false,
  isUploadLoading: false,
  isUploadSuccess: false,
  isVideoLoading: false,
  isDisableButton: true,
  onGoing: false,
  uploadError: {},
  body: initialValues,
  option: [],
}
