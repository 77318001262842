import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  meetingRoom,
  meetingRoomHistory,
  meetingRoomImageUpload,
  roomManagementFilterCalendar,
  stationFilter,
  roomManagementDashboardCalendar,
} from '../../utils/apiPath'

export const postMasterDataMeetingRoom = createAsyncThunk(
  'masterData/postMasterDataMeetingRoom',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(meetingRoom, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getStationFilterDefault = createAsyncThunk(
  'masterData/stationFilterDefault',
  async (n, { rejectWithValue }) => {
    try {
      const body = {
        station: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'updatedAt',
        status: ['ACTIVE'],
      }
      const response = await axios.post(stationFilter, body)
      return _.get(response, 'data.result', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putMasterDataMeetingRoom = createAsyncThunk(
  'masterData/putMasterDataMeetingRoom',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(meetingRoom, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadMeetingRoomImage = createAsyncThunk(
  'masterData/postUploadMeetingRoomImage',
  async ({ body, key, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(meetingRoomImageUpload, body, config)
      return response
    } catch (err) {
      return rejectWithValue(key, err)
    }
  },
)

export const getMeetingRoomById = createAsyncThunk(
  'masterData/getMeetingRoomById',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${meetingRoom}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getRoomDetailCalendarById = createAsyncThunk(
  'masterData/getRoomDetailCalendarById',
  async (
    { uuid, monthYear, monthOrWeek, requestType, courseOrTopic, mondayDate },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(`${roomManagementFilterCalendar}`, {
        meetingRoomUuid: uuid,
        monthOrWeek: monthOrWeek,
        monthYear: monthYear,
        requestType,
        courseOrTopic,
        mondayDate,
      })
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCalendarByStationId = createAsyncThunk(
  'masterData/getCalendarByStationId',
  async (
    { uuid, monthYear, monthOrWeek, requestType, roomName, mondayDate },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(`${roomManagementDashboardCalendar}`, {
        stationUuid: uuid,
        monthOrWeek: monthOrWeek,
        monthYear: monthYear,
        requestType,
        roomName,
        mondayDate,
      })
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getMeetingRoomHistory = createAsyncThunk(
  'masterData/getMeetingRoomHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${meetingRoomHistory}${url}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
