import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import SelectIcon from '../../../../../../../components/redux/SelectIcon'
import { listChoiceEva } from '../../../../../../../constants/eEvaluation'
import { closeDialog } from '../../../../../../../redux/slices/dialog'

import Image from '../../../Image'
import Video from '../../../Video'

import * as Styled from './Styled'
import { TooltipForm } from '../../../Styled'

import {
  handleIconUploadImage,
  IconUploadImage,
  handleIconVideo,
} from '../../../event'

const Index = ({ type }) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { itemQuestion, disableForm, dialog } = useSelector(
    (state) => ({
      itemQuestion: _.get(state, `eEvaluationForm[${type}][0]`, {
        url: '',
        mediaType: '',
        sequence: '',
      }),
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
      dialog: state.dialog,
    }),
    shallowEqual
  )
  const { url, mediaType, sequence } = itemQuestion

  const loadingProgress = itemQuestion.loadingProgress

  const closeVideo = _.get(itemQuestion, 'closeVideo', false)

  useEffect(() => {
    if (closeVideo && _.get(dialog, 'title', '') === 'เพิ่มวิดีโอ') {
      dispatch(closeDialog())
    }
  }, [closeVideo])
  return (
    <>
      <Styled.BoxContent>
        <Styled.LabelHeader variant="body1b">คำถาม</Styled.LabelHeader>

        <Styled.BoxRight>
          <IconUploadImage
            isQuestion={true}
            disabled={disableForm}
            testId={`icon-question-${type}`}
            onUpload={(data) =>
              dispatch(
                handleIconUploadImage({
                  sequence,
                  data,
                  type,
                  testId: `icon-question-${type}`,
                })
              )
            }
          />
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">วิดีโอ</Typography>}
          >
            <IconButton
              onMouseLeave={() => setIsHover(false)}
              onMouseOver={() => setIsHover(true)}
              onClick={() =>
                !disableForm &&
                dispatch(
                  handleIconVideo({ type, sequence: itemQuestion.sequence })
                )
              }
            >
              <VideocamOutlined color={isHover ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>

          <Styled.BoxSelect>
            <SelectIcon
              labelText=""
              listOption={listChoiceEva}
              disabled={true}
              value={'RATING'}
            />
          </Styled.BoxSelect>
        </Styled.BoxRight>
      </Styled.BoxContent>
      <Box sx={{ ml: 0, mb: 2 }}>
        {((loadingProgress != null && mediaType === 'IMAGE') ||
          (mediaType === 'IMAGE' && url)) && (
          <Image
            isDefault
            disabled={disableForm}
            uploadState={itemQuestion}
            keyState={{ type, key: 'url', keyValue: 'url' }}
            value={url}
          />
        )}
        {mediaType === 'VIDEO' && url && (
          <Video
            url={url}
            type={type}
            sequence={sequence}
            disabled={disableForm}
          />
        )}
      </Box>
    </>
  )
}

export default Index
