import { InfoOutlined } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

export const LabelWithValue = ({
  label,
  value,
  unit,
  info,
  labelSx = {},
  variant = 'body1',
}) => {
  return (
    <Box display="flex" gap={3}>
      <Box width={200}>
        <Typography sx={{ ...labelSx }} variant={variant} color="gray">
          {label}
        </Typography>
      </Box>
      <Box width={unit ? 100 : 'auto'}>
        <Typography variant="body1">{value?.toLocaleString()}</Typography>
      </Box>
      {info && (
        <Tooltip
          arrow
          title={<Typography variant="tooltip">{info}</Typography>}
          background="primary"
        >
          <InfoOutlined color="primary" />
        </Tooltip>
      )}
      {unit && <Typography variant="body1">{unit}</Typography>}
    </Box>
  )
}
