import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Typography from '@mui/material/Typography'
import React from 'react'
import { StyledAlertBox } from './Styled'

const AlertErrorBox = ({ text = 'กรุณาเลือกคำตอบ', sx }) => {
  return (
    <StyledAlertBox sx={{ ...sx }}>
      <ErrorOutline color="error" />
      <Typography>{text}</Typography>
    </StyledAlertBox>
  )
}

export default AlertErrorBox
