export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'ID',
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    placeholder: 'ค้นหา ID',
    type: 'text',
  },
  {
    label: 'ชื่อใบอนุญาต',
    nameCheckBox: 'descriptionChecked',
    nameInput: 'description',
    placeholder: 'ค้นหาชื่อใบอนุญาต',
    type: 'text',
  },
  {
    label: 'ประเภทใบอนุญาต',
    nameCheckBox: 'typeChecked',
    nameInput: 'type',
    placeholder: 'ค้นหา Category',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหาชื่อ',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'editedByChecked',
    nameInput: 'editedBy',
    placeholder: 'ค้นหาชื่อ',
    type: 'text',
  },
]

export const defaultFilter = {
  codeIdChecked: false,
  descriptionChecked: false,
  typeChecked: false,
  statusChecked: false,
  createdByChecked: false,
  editedByChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
