import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
    1
  )} ${theme.spacing(6)}`,
  gap: theme.spacing(2),
  p: { span: { color: theme?.palette?.success?.main } },
}))

export const StyledErrorContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
}))

export const StyledErrorRow = styled(Box)(({ theme, header }) => ({
  padding: theme.spacing(2),
  boxShadow: header
    ? 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)'
    : 'inset 0px -1px 0px rgba(0, 0, 0, 0.04)',
}))
