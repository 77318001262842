import InputText from '../../../../../components/Input/TextInput'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect, useMemo } from 'react'
import { InputImageUpload, TableCustom } from '../Styled'
import _ from 'lodash'
import {
  handleChangeName,
  handleChangeNameLink,
  handleRemoveUpload,
  handleSubmitLink,
  handleSubmitUpload,
  handleUpload,
} from './event'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { checkHttpUrl } from '../../../../../utils/lib'

export const UPLOAD_FILE = ({
  handleClose,
  initialValues,
  setInitialValues,
}) => {
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(true)
  const { docs } = useSelector(
    (state) => ({
      docs: state.manageClassDocs.docs,
    }),
    shallowEqual
  )
  const electronic = _.get(initialValues, 'electronic', [])
  const UploadFile = async (e) => {
    const initDocs = [...electronic, ...docs]
    if (electronic.length === 0 && docs.length === 0) {
      await dispatch(handleUpload(e, 0, initDocs))
    } else if (electronic.length > 0 && docs.length === 0) {
      const docsLength = electronic.length
      const lastIndex = electronic[docsLength - 1]?.index ?? 0
      await dispatch(handleUpload(e, lastIndex + 1, initDocs))
    } else {
      const docsLength = docs.length
      const lastIndex = docs[docsLength - 1]?.index ?? 0
      await dispatch(handleUpload(e, lastIndex + 1, initDocs))
    }
  }
  const RemoveFile = async (key) => dispatch(handleRemoveUpload(key, docs))
  useEffect(() => {
    const filter = _.filter(docs, (item) => item.status === 'SUCCESS')
    const isDuplicate = _.filter(docs, (item) => item.isDuplicate === true)
    setDisable(_.isEmpty(filter) || !_.isEmpty(isDuplicate))
  }, [docs])

  const handleClickClose = () => {
    dispatch(handleRemoveUpload(null, docs))
    handleClose()
  }

  const debouncedChangeName = useMemo(
    () =>
      _.debounce(
        (e, index) => dispatch(handleChangeName(e, index, initialValues, docs)),
        1000
      ),
    [docs]
  )

  return (
    <Box>
      <Typography variant="body2" color={'text.secondary'}>
        เอกสารประกอบการเรียน
      </Typography>
      <label htmlFor="docs-button-file">
        <Button
          variant="contained"
          size="s"
          component="span"
          htmlFor="docs-button-file"
        >
          <InputImageUpload
            id={`docs-button-file`}
            type="file"
            onChange={(e) => UploadFile(e)}
            multiple
          />
          <Typography color="text.primary.contrast" sx={{ cursor: 'pointer' }}>
            {disable ? 'อัปโหลดเอกสาร' : 'อัปโหลดเอกสารเพิ่มเติม'}
          </Typography>
        </Button>
      </label>
      {!_.isEmpty(docs) && (
        <TableCustom aria-label="docs_table">
          <TableHead>
            <TableRow>
              <TableCell width="42.5%">ชื่อเอกสาร</TableCell>
              <TableCell width="42.5%">สถานะ</TableCell>
              <TableCell align="center" width="15%">
                คำสั่ง
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(docs, (item, key) => (
              <TableRow key={key}>
                <TableCell>
                  <InputText
                    id={`NameFile-${key}`}
                    name={`NameFile-${key}`}
                    type="basic"
                    onChange={(e) => debouncedChangeName(e, item.index)}
                    defaultValue={item.name}
                    textError={item.isDuplicate ? 'ชื่อเอกสารซ้ำ' : ''}
                    isShowTextError={item.isDuplicate}
                  />
                </TableCell>
                <TableCell>
                  {item.status === 'SUCCESS' ? (
                    <Typography variant="body2" color={'success.main'}>
                      อัปโหลดสำเร็จ
                    </Typography>
                  ) : (
                    <Typography variant="body2" color={'error.main'}>
                      {_.get(item, 'textError', 'อัปโหลดล้มเหลว')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    data-testid={`btn-delete`}
                    color="primary"
                    component="span"
                    onClick={() => RemoveFile(key)}
                  >
                    <DeleteTwoTone color="action" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableCustom>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: 4,
          marginTop: 2,
        }}
      >
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => handleClickClose()}
        >
          ยกเลิก
        </Button>
        <Button
          data-testid="btn-submit"
          disabled={disable}
          onClick={() => {
            handleSubmitUpload(initialValues, setInitialValues, docs)
            handleClickClose()
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </Box>
    </Box>
  )
}

export const LINK = ({ handleClose, initialValues, setInitialValues }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [link, setLink] = useState('')

  const [disable, setDisable] = useState(true)

  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isUrlValidate, setIsUrlValidate] = useState(false)

  useEffect(() => {
    if (
      !_.isEmpty(String(name)) &&
      !_.isEmpty(String(link)) &&
      !isDuplicate &&
      !isUrlValidate
    ) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [name, link])

  const handleClickClose = () => {
    handleClose()
    setLink('')
    setName('')
  }

  const debouncedChangeName = useMemo(
    () =>
      _.debounce(
        (e) =>
          dispatch(
            handleChangeNameLink(e, initialValues, setIsDuplicate, setName)
          ),
        1000
      ),
    [initialValues]
  )

  const ChangeUrl = (url) => {
    const isValidate = checkHttpUrl(url)
    setIsUrlValidate(!isValidate)
    setLink(url)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <InputText
          id="name"
          name="name"
          type="basic"
          labelText="ชื่อเอกสาร"
          placeholder="ชื่อเอกสาร"
          required={true}
          onChange={(e) => debouncedChangeName(e)}
          defaultValue={name}
          textError={isDuplicate ? 'ชื่อเอกสารซ้ำ' : ''}
          isShowTextError={isDuplicate}
        />
        <InputText
          id="link"
          name="link"
          type="basic"
          labelText="Link"
          required={true}
          placeholder="Link"
          value={link}
          onChange={(e) => ChangeUrl(e.target.value)}
          textError={isUrlValidate ? 'URL ไม่ถูกต้อง' : ''}
          isShowTextError={isUrlValidate}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: 4,
          marginTop: 2,
        }}
      >
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => handleClickClose()}
        >
          ยกเลิก
        </Button>
        <Button
          disabled={disable}
          data-testid="btn-submit"
          onClick={() => {
            handleSubmitLink(initialValues, setInitialValues, name, link)
            handleClickClose()
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </Box>
    </Box>
  )
}
