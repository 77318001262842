import {
  setChangeAddSubQuestions,
  setChangeQuestionByKey,
} from '../../../../../../../../../redux/slices/eEvaluationForm'

export const listNextQuestionType = (length, indexQuestion) => {
  let defaultOptions = [
    {
      label: 'ไปคำถามย่อย',
      value: 'NEXT_SUB',
    },
  ]

  let listQuestions = []
  for (let index = 0; index < length; index++) {
    if (index !== indexQuestion && index > indexQuestion) {
      listQuestions.push({
        label: `ไปคำถาม ${index + 1}.`,
        value: index + 1,
      })
    }
  }

  if (length > 1) {
    defaultOptions = [
      {
        label: 'ไปคำถามถัดไป',
        value: 'NEXT',
      },
      ...defaultOptions,
    ]
  }

  return [
    ...defaultOptions,
    ...listQuestions,
    {
      label: 'จบแบบสอบถาม',
      value: 'SUMMARY',
    },
  ]
}

export const listSubQuestionSequence = (lengthSubQuestions, sequence) => {
  let listQuestions = []
  for (let index = 0; index < lengthSubQuestions; index++) {
    listQuestions.push({
      label: `${sequence}.${index + 1}`,
      value: `${sequence}.${index + 1}`,
    })
  }

  return [
    ...listQuestions,
    {
      label: 'สร้างคำถามย่อย',
      value: 'ADD',
    },
  ]
}

export const handleChangeNextQuestion = (props) => (dispatch) => {
  const { sequence, indexAnswer, answers, value } = props

  if (value === 'ADD') {
    dispatch(setChangeAddSubQuestions({ sequence, indexAnswer }))
  } else {
    let result = []
    const nextQuestionSequence =
      value === 'NONE' ? 0 : Number(value.split('.')[0])
    const nextSubQuestionSequence =
      value === 'NONE' ? 0 : Number(value.split('.')[1])

    if (answers.length) {
      result = answers.map((itemAnswers, index) => {
        return {
          ...itemAnswers,
          nextQuestionSequence:
            index === indexAnswer
              ? nextQuestionSequence
              : itemAnswers.nextQuestionSequence,
          nextSubQuestionSequence:
            index === indexAnswer
              ? nextSubQuestionSequence
              : itemAnswers.nextSubQuestionSequence,
        }
      })
    }

    dispatch(
      setChangeQuestionByKey({
        type: 'questions',
        sequence,
        key: `answers`,
        value: result,
      })
    )
  }
}
