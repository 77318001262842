import Typography from '@mui/material/Typography'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  backgroundStatus,
  LINE_OPTION,
} from '../../../constants/eCertification'
import * as reducer from '../../../redux/slices/eCertificateForm'
import * as service from '../../../services/eCertiticate/form'
import { eCertificationVersion } from '../../../utils/apiPath'
import {
  closeDialog,
  loadingDialog,
  openDialog,
} from '../../../redux/slices/dialog'
import {
  StyledBackgroundBlankBox,
  StyledBackgroundBox,
  StyledBlankLine45Rotate,
  StyledGroupLayoutBox,
  StyledLayoutBox,
  StyledLayoutBoxAll,
} from '../Background/Form/UploadBackground/Styled'

export const onSubmitFormECertificationVersion =
  (props) => async (dispatch) => {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
        handleConfirm: () => dispatch(actionSubmitForm(props)),
      })
    )
  }

export const actionSubmitForm = (props) => async (dispatch) => {
  const body = {
    name: _.get(props, 'name', ''),
    layout: _.get(props, 'layout', ''),
    background: _.get(props, 'background', null),
    status: backgroundStatus.ACTIVE,
  }

  const isClone = !!_.get(props, 'isClone', false)

  if (!_.isEmpty(_.get(props, 'codeId', ''))) {
    body['codeId'] = _.get(props, 'codeId', '')
  }

  if (isClone) {
    body['clone'] = isClone
  }

  dispatch(reducer.startLoading())
  dispatch(loadingDialog())
  const result = await dispatch(service.submitFormCertificateVersion(body))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        handleConfirm: () => {
          props.history.push(
            `${eCertificationVersion}/${_.get(result, 'payload.data', '')}`
          )
          dispatch(closeDialog())
          dispatch(reducer.setClearData())
        },
      })
    )
  } else {
    props.setActiveStep(0)
    dispatch(
      reducer.setChange({
        key: 'errors',
        value: { name: 'ชื่อนี้ถูกใช้แล้ว กรุณาตั้งชื่อใหม่' },
      })
    )
  }
  dispatch(reducer.stopLoading())
}

export const useContainerDimensions = (myRef, next) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef, next])

  return dimensions
}

export const fetchDataForm =
  ({ uuid }) =>
  async (dispatch) => {
    const layoutOption = {
      limit: -1,
      page: 1,
    }

    const backgroundOption = {
      codeId: '',
      name: '',
      createdBy: '',
      updatedBy: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'codeId',
      status: ['ACTIVE'],
    }
    dispatch(reducer.startLoading())
    const data = await Promise.all([
      dispatch(service.getListCertificateBackground(backgroundOption)),
      dispatch(service.getLayoutBackgroundECertificateBackground(layoutOption)),
    ])

    let tempLayout = []

    if (!_.isEmpty(uuid)) {
      const initVersionUuid = await Promise.all([
        dispatch(service.getFormDetailVersion(uuid)),
        dispatch(service.getAssigneesECertification()),
      ])
      const initData = _.get(initVersionUuid, '[0].payload', [])
      tempLayout = _.get(initData, 'layout', [])
      dispatch(
        reducer.setFetchDataUuid({
          name: _.get(initData, 'name', ''),
          codeId: _.get(initData, 'codeId', ''),
          version: _.get(initData, 'version', ''),
          layout: tempLayout,
          activeDate: _.get(initData, 'startDate', ''),
          status: _.get(initData, 'status', ''),
          courseVersion: _.get(initData, 'courseVersion', []),
          background: {
            codeId: _.get(initData, 'eCertificationBackground.codeId', 'BG0'),
            uuid: _.get(initData, 'eCertificationBackground.uuid', ''),
            imageKey: _.get(initData, 'eCertificationBackground.imageKey', ''),
            name: _.get(
              initData,
              'eCertificationBackground.name',
              'ไม่ใส่ภาพพื้นหลัง'
            ),
          },
          startDate: _.get(initData, 'startDate', ''),
          createdBy: _.get(initData, 'createdBy', ''),
          createdAt: _.get(initData, 'createdAt', ''),
          updatedBy: _.get(initData, 'updatedBy', ''),
          updatedAt: _.get(initData, 'updatedAt', ''),
          listAssignee: _.get(initVersionUuid, '[1].payload.result', []),
          assignees: _.get(initData, 'assignees', []),
        })
      )
    }
    const backgroundData = _.get(data, '[0].payload', [])
    const layoutData = _.get(data, '[1].payload', [])

    const initBackground = {
      totalCount: 1,
      result: {
        codeId: 'BG0',
        name: 'ไม่ใส่ภาพพื้นหลัง',
        uuid: '',
      },
    }

    dispatch(
      reducer.setChange({
        key: 'backgrounds',
        value: {
          totalCount: backgroundData.totalCount + initBackground.totalCount,
          result: [initBackground.result, ...backgroundData.result],
        },
      })
    )
    if (!_.isEmpty(tempLayout)) {
      const list = [
        ..._.filter(layoutData.result, (i) => i.id !== tempLayout.id),
        tempLayout,
      ]
      dispatch(
        reducer.setChange({
          key: 'layouts',
          value: {
            totalCount: layoutData.totalCount,
            result: _.orderBy(list, ['id'], ['asc']),
          },
        })
      )
    } else {
      dispatch(
        reducer.setChange({
          key: 'layouts',
          value: layoutData,
        })
      )
    }

    dispatch(reducer.stopLoading())
  }

export const LayoutOption = ({
  setSelectLayout,
  isSelectLayout,
  layouts,
  width,
}) => {
  return (
    <StyledGroupLayoutBox container height={width / 3}>
      {_.map(layouts, (item) => (
        <StyledLayoutBoxAll item key={`layout_${item.uuid}`}>
          <StyledLayoutBox
            width={width}
            onClick={() => setSelectLayout(item.id)}
            active={isSelectLayout === item.id ? 1 : undefined}
          >
            {_.map(item.layout, (l, key) => {
              if (l.type === LINE_OPTION.MULTILINE) {
                return (
                  <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
                    {_.map(l.layout, (li, n) => (
                      <Typography
                        key={`layout_box_n_${n}`}
                        sx={{ ...l.style, ...li.style }}
                      >
                        {li.text}
                      </Typography>
                    ))}
                  </div>
                )
              }
              return (
                <Typography key={`layout_list_${key}-${item.id}`} sx={l.style}>
                  {l.text}
                </Typography>
              )
            })}
          </StyledLayoutBox>
          <Typography variant="caption" color="text.secondary">
            {item.name}
          </Typography>
        </StyledLayoutBoxAll>
      ))}
    </StyledGroupLayoutBox>
  )
}

export const BackgroundOption = ({
  setSelectLayout,
  IsSelectBackground,
  layouts,
  width,
}) => {
  return (
    <StyledGroupLayoutBox container height={width / 3}>
      {_.map(layouts, (item) => {
        if (item.codeId === 'BG0') {
          return (
            <StyledLayoutBoxAll item key={`layout_${item.uuid}`}>
              <StyledBackgroundBlankBox
                width={width}
                url={`${window.__env__.REACT_APP_API_URL}/file${item.imageKey}`}
                onClick={() => setSelectLayout(item.codeId)}
                active={IsSelectBackground === item.codeId ? 1 : undefined}
              >
                <StyledBlankLine45Rotate />
              </StyledBackgroundBlankBox>
              <Typography variant="caption" color="text.secondary">
                {item.name}
              </Typography>
            </StyledLayoutBoxAll>
          )
        }
        return (
          <StyledLayoutBoxAll item key={`layout_${item.uuid}`}>
            <StyledBackgroundBox
              width={width}
              url={`${window.__env__.REACT_APP_API_URL}/file${item.imageKey}`}
              onClick={() => setSelectLayout(item.codeId)}
              active={IsSelectBackground === item.codeId ? 1 : undefined}
            />
            <Typography variant="caption" color="text.secondary">
              {item.name}
            </Typography>
          </StyledLayoutBoxAll>
        )
      })}
    </StyledGroupLayoutBox>
  )
}

export const handleNumberInput = (e) => {
  const numVal = Math.abs(e.target.value).toFixed()

  if (_.startsWith(e.target.value, '0') || e.nativeEvent.data == '.') {
    e.target.value = null
  }

  e.target.value = numVal >= 0 ? e.target.value : null // min 0
  e.target.value = numVal <= 1000 ? e.target.value : 1000 // max 100

  return e.target.value
}

export const changeFormName = (e) => async (dispatch) => {
  dispatch(reducer.setChange({ key: 'formName', value: e.target.value }))
}

export const changeFormReasonEdit = (e) => async (dispatch) => {
  dispatch(reducer.setChange({ key: 'formReasonEdit', value: e.target.value }))
}

export const ChangeLayout =
  ({ list, text, type, key, subKey }) =>
  (dispatch) => {
    if (type === LINE_OPTION.MULTILINE) {
      let arr = [...list.layout]
      let subArr = [...arr[key].layout]
      subArr[subKey] = {
        ...list.layout[key].layout[subKey],
        text: text,
      }
      arr[key] = { ...list.layout[key], layout: subArr }
      let layout = {
        ...list,
        layout: arr,
      }
      dispatch(reducer.setChange({ key: 'formLayout', value: layout }))
      return layout
    }

    let arr = [...list.layout]
    arr[key] = { ...list.layout[key], text: text }
    let layout = {
      ...list,
      layout: arr,
    }
    dispatch(reducer.setChange({ key: 'formLayout', value: layout }))
    return layout
  }

export const ChangeStyleLayout =
  ({ list, style, key }) =>
  async (dispatch) => {
    let arr = [...list.layout]
    arr[key] = { ...list.layout[key], style: style }
    let layout = {
      ...list,
      layout: arr,
    }
    dispatch(reducer.setChange({ key: 'formLayout', value: layout }))
    return null
  }

export const changeBackgroundEvent =
  ({ id, setSelectBackground, backgrounds }) =>
  async (dispatch) => {
    setSelectBackground(id)
    const backgroundSelect = _.filter(
      _.get(backgrounds, 'result', []),
      (i) => i.codeId === id
    )[0]
    dispatch(
      reducer.setChange({
        key: 'formBackground',
        value: {
          codeId: id,
          uuid: backgroundSelect?.uuid,
          name: backgroundSelect?.name,
          imageKey: backgroundSelect?.imageKey,
        },
      })
    )
  }
export const changeLayoutEvent =
  ({ id, setSelectLayout, layouts }) =>
  async (dispatch) => {
    setSelectLayout(id)
    const layoutSelect = _.filter(
      _.get(layouts, 'result', []),
      (i) => i.id === id
    )[0]
    dispatch(
      reducer.setChange({
        key: 'formLayout',
        value: {
          id: id,
          uuid: layoutSelect.uuid,
          name: layoutSelect.name,
          layout: layoutSelect.layout,
        },
      })
    )
  }

export const mapAssignee = (listAssignee) => {
  let result = []
  if (listAssignee.length) {
    result = listAssignee.map((item) => {
      return {
        ...item,
        label: item.label || item.name,
        value: item.uuid,
      }
    })
  }

  return result
}
