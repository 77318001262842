import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"
import { productWarehouseListFilter } from "../../../../../utils/apiPath"
import callAxios from "../../../../../utils/baseService"
import _ from "lodash"
import { openAssetDetailDrawer } from "../components/DropdownButton/events"

export const fetchAndOpenAssetDetail = async (productUuid, warehouseUuid, skuUuid) => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
  const body = {
    limit: -1,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    status: [],
    warehouseUuid: warehouseUuid,
    productUuid: productUuid,
    search:'',
    quickSearch: '', 
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    createdBy: '',
    updatedBy: '',
    warehouseStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
    productStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
  }
  const isCancel = await callAxios
    .post(productWarehouseListFilter, body)
    .then((res) => {
     const result = _.get(res, 'data.result', [])
     if(result.length > 0){
      const skuItem = result.find((sku) => sku.uuid === skuUuid)
      if(skuItem) openAssetDetailDrawer(skuItem)
     }
    })
    .catch((e) => {
      console.log(e)
    })
  if (!isCancel) store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
    store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}