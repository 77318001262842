import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../components/CRUD/components/CustomSwitchInLine'
import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import { Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

export const BasicInfo = () => {
  // const { uuid } = useParams()

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <CustomInput
        required
        labelName="ชื่อบริษัท (Vendor name)"
        fieldName="name"
        placeholder="ระบุชื่อบริษัท"
        disabled={!hasPermissionCreate}
      />
      <CustomDropdown
        required
        labelName="Station"
        fieldName="station"
        placeholder="เลือก Station"
      />
      <Divider />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
      <CustomTextArea
        disabled={!hasPermissionCreate}
        fieldName="annotation"
        labelName="หมายเหตุ"
        placeholder="กรอกหมายเหตุ"
      />
    </>
  )
}
