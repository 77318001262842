import { BOOKING_PERIOD_TYPE } from '../../../../constants/roomManagement'
import { enumRequestType } from '../../../../modules/RoomManagement/View/Content/CalendarCard/constanst'

export const initialState = {
  errors: [],
  formError: [],
  isLoadingDrawer: false,
  bookingUuid: null,
  isOpenDrawer: false,
  isShowPreview: false,
  isShowForm: false,
  isEditingForm: false,
  ishShowHistoryDrawer: false,
  ishLoadingHistoryDrawer: false,
  bookingInformation: {
    stationName: '',
    stationUuid: '',
    bookingUuid: '',
    bookingTopic: '',
    status: '',
    canceledReason: '',
    requestType: enumRequestType.E_BOOKING,
    courseName: '',
    startDate: null,
    endDate: null,
    bookingPeriod: BOOKING_PERIOD_TYPE.SAME_TIME,
    academy: 'ACADEMY',
    bookForUuid: '',
    isDeleted: false,
    reason: '',
    bookFor: null,
    roomBookingList: [],
    createdAt: '',
    createdBy: '',
    updatedBy: '',
    bookForName: '',
    bookForOther: '',
    mobileNo: '',
    email: '',
    bookByName: '',
    remark: '',
    step: 0,
  },
  roomSelected: [],
  staffList: [],
  disabledBtnNext: true,
}
