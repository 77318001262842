import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'

export const fetchLearningPath = async () => {
  store.dispatch(
    setFieldValue({
      key: 'masterData.learningPath',
      value: {
        isLoading: true,
        options: [],
        allOptions: [],
      },
    }),
  )
  const body = {
    limit: -1,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    status: ['ACTIVE'],
    id: '',
    name: '',
    nameCourse: '',
    periodStart: '',
    periodEnd: '',
    createdBy: '',
    updatedBy: '',
  }

  try {
    const response = await callAxios.post(
      'e-learning/learningPath/filter',
      body,
    )
    store.dispatch(
      setFieldValue({
        key: 'masterData.learningPath',
        value: {
          isLoading: false,
          options: response.data.result.map((item) => ({
            ...item,
            value: item.uuid,
            label: item.name,
          })),
          allOptions: response.data.result.map((item) => ({
            ...item,
            value: item.uuid,
            label: item.name,
          })),
        },
      }),
    )
  } catch (e) {
    handleFetchError(e)
  }
}
