import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { MODULE_STATUS } from '../../../../../../constants/eLearning'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import StatusSwitch from '../../../../components/StatusSwitch'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import UploadImage from './UploadFile'

const Info = () => {
  const dispatch = useDispatch()
  const {
    isUploadLoading,
    name,
    nameForBanner,
    detailForBanner,
    description,
    linkExampleVideo,
    remark,
  } = useSelector(
    (state) => ({
      isUploadLoading: state.eLearningPathForm.isUploadLoading,
      name: getStateValue('name'),
      nameForBanner: getStateValue('nameForBanner'),
      detailForBanner: getStateValue('detailForBanner'),
      description: getStateValue('description'),
      linkExampleVideo: getStateValue('linkExampleVideo'),
      remark: getStateValue('remark'),
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">รายละเอียดพื้นฐาน</Typography>
      <TextInput
        required
        name="name"
        type="basic"
        labelText="ชื่อ Learning path"
        placeholder="ชื่อ Learning path"
        id="input-name"
        textError={_.get(errors, 'name', '')}
        isShowTextError={_.get(errors, 'name', '')}
        value={name}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        required
        name="nameForBanner"
        type="basic"
        labelText="ชื่อหัวข้อบน Banner"
        placeholder="ชื่อหัวข้อบน Banner"
        id="input-nameForBanner"
        textInfo="แนะนำระบุข้อความความยาวไม่เกิน 60 ตัวอักษร"
        textError={_.get(errors, 'nameForBanner', '')}
        isShowTextError={_.get(errors, 'nameForBanner', '')}
        value={nameForBanner}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        name="detailForBanner"
        type="basic"
        labelText="รายละเอียดบน Banner"
        placeholder="รายละเอียดบน Banner"
        id="input-detailForBanner"
        textError={_.get(errors, 'detailForBanner', '')}
        isShowTextError={_.get(errors, 'detailForBanner', '')}
        value={detailForBanner}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        multiline
        rows={3}
        name="description"
        type="basic"
        labelText="รายละเอียดเพิ่มเติม"
        placeholder="รายละเอียดเพิ่มเติม"
        id="input-description"
        textError={_.get(errors, 'description', '')}
        isShowTextError={_.get(errors, 'description', '')}
        value={description}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />

      <UploadImage />

      <TextInput
        multiline
        rows={3}
        name="linkExampleVideo"
        type="basic"
        labelText="Link สำหรับวีดีโอตัวอย่าง"
        placeholder="กรอก Link Video ตัวอย่าง จาก YouTube หรือ Vimeo"
        id="input-linkExampleVideo"
        textError={_.get(errors, 'linkExampleVideo', '')}
        isShowTextError={_.get(errors, 'linkExampleVideo', '')}
        value={linkExampleVideo}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <Divider />
      <TextInput
        multiline
        rows={3}
        name="remark"
        type="basic"
        labelText="Remark"
        id="input-remark"
        textError={_.get(errors, 'remark', '')}
        isShowTextError={_.get(errors, 'remark', '')}
        value={remark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <StatusSwitchContent />
    </StyledFormCard>
  )
}

export default Info

export const StatusSwitchContent = () => {
  const dispatch = useDispatch()
  const status = useSelector(
    () => getStateValue('status', MODULE_STATUS.ACTIVE),
    shallowEqual,
  )
  return (
    <StatusSwitch
      label="สถานะเปิดปิดการใช้งาน"
      subLabel="เปิดปิดการใช้งาน Learning path"
      checked={status === MODULE_STATUS.ACTIVE}
      handleChange={(e) =>
        dispatch(
          handleChange(
            'status',
            e.target.checked ? MODULE_STATUS.ACTIVE : MODULE_STATUS.INACTIVE,
          ),
        )
      }
    />
  )
}
