import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledImagePreview,
  StyledQuestionCard,
  StyledVideoPreview,
} from './../../../../../../components/Preview/QuestionCard/Styled'
import Choice from './Choice'

const QuestionCard = ({ keyQuestion }) => {
  const question = useSelector(
    (state) =>
      state.eTestingPreview.testing.find((item) => item.key == keyQuestion),
    shallowEqual,
  )
  const { mediaType, url } = question
  return (
    <StyledQuestionCard variant="question">
      <Question question={question} />
      {url &&
        (mediaType == 'VIDEO' ? (
          <StyledVideoPreview url={_.get(url.split('&'), 0, '')} />
        ) : (
          <StyledImagePreview
            src={`${window.__env__.REACT_APP_API_URL}/file${url}`}
          />
        ))}
      <Choice keyQuestion={keyQuestion} />
    </StyledQuestionCard>
  )
}

export default QuestionCard

export const Question = ({ question }) => {
  const { sequence, title, type, choices } = question
  const answerNum = choices.filter((e) => e.isCorrect).length
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
      <Typography variant="body1b">{sequence}.</Typography>
      <Typography sx={{ wordBreak: 'break-word' }} variant="body1b">
        {title}{' '}
        {type == 'MULTIPLE' && (
          <span style={{ fontWeight: 400, whiteSpace: 'pre' }}>
            (เลือกตอบ {answerNum} ข้อ)
          </span>
        )}
      </Typography>
    </Box>
  )
}
