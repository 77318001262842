import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import * as events from './events'

import { getQueryParams } from '../../../../../../utils/lib'

import TextInput from '../../../../../../components/Input/TextInput'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'

const Index = () => {
  const dispatch = useDispatch()
  const date = getQueryParams('date')
  const round = getQueryParams('round')

  const { id: trainingPlanUuid } = useParams()

  useEffect(() => {
    const props = {
      trainingPlanUuid,
      date: events.revertDate(date),
      round: Number(round),
    }
    dispatch(events.handleChangeBodyDialog(props))
  })

  const bodyDialog = useSelector(
    (state) => state.attendanceDialog.bodyDialog,
    shallowEqual
  )

  useEffect(() => {
    dispatch(events.handleDisabledDialog(bodyDialog.remark))
  }, [bodyDialog.remark])

  const learnerList = useSelector(
    (state) => state.attendanceDialog.learnerList,
    shallowEqual
  )

  return (
    <Box sx={{ my: 2 }}>
      <Typography sx={{ mx: 2 }} variant="h6">
        เช็คชื่อ (ครั้งที่ {round})
      </Typography>

      <Box sx={{ mx: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body2">วันที่เรียน</Typography>
          <Typography variant="body1">{date}</Typography>
        </Box>
        <Box sx={{ pt: 2 }}>
          <RadioGroup
            sx={{ ml: 1, gap: 1 }}
            row
            aria-label="approval"
            defaultValue={bodyDialog.type}
            name="type"
            id="type"
            value={bodyDialog.type}
            onChange={(event) =>
              dispatch(
                events.handleChangeRadio(
                  _.get(event, 'target.value', 'CHECK_STUDY_ALL')
                )
              )
            }
          >
            <FormControlLabel
              value="CHECK_STUDY_ALL"
              control={<Radio />}
              label="มาเรียนครบ"
            />
            <FormControlLabel
              value="CHECK_MISSING"
              control={<Radio />}
              label="เช็คชื่อคนขาดเรียน"
            />
            <FormControlLabel
              value="CHECK_STUDY"
              control={<Radio />}
              label="เช็คชื่อคนมาเรียน"
            />
          </RadioGroup>

          {bodyDialog.type !== 'CHECK_STUDY_ALL' && (
            <Box sx={{ mt: 2, ml: -1.5 }}>
              <SelectGroupChip
                id="classLearner"
                name="classLearner"
                placeholder=""
                maxMenuHeight={200}
                labelText={
                  bodyDialog.type === 'CHECK_MISSING'
                    ? 'เช็คชื่อคนขาดเรียน'
                    : 'เช็คชื่อคนมาเรียน'
                }
                options={learnerList}
                onChange={(list) =>
                  dispatch(
                    events.handleChangeBodyDialog({
                      classLearnerUuid: list,
                    })
                  )
                }
                value={bodyDialog.classLearnerUuid}
              />
              <Box sx={{ ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="checkbox-check-other"
                      checked={bodyDialog.isCheckOtherToOpposite}
                      onChange={(event) =>
                        dispatch(
                          events.handleChangeBodyDialog({
                            isCheckOtherToOpposite: _.get(
                              event,
                              'target.checked',
                              false
                            ),
                          })
                        )
                      }
                    />
                  }
                  label={
                    bodyDialog.type === 'CHECK_MISSING'
                      ? 'เช็ครายชื่ออื่นทั้งหมดเป็นมาเรียน'
                      : 'เช็ครายชื่ออื่นทั้งหมดเป็นขาดเรียน'
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 2, ml: -1.5 }}>
          <TextInput
            id="remark"
            name="remark"
            type="basic"
            labelText="เหตุผล"
            placeholder=""
            multiline={true}
            required
            maxRows={3}
            maxlength={255}
            onChange={(event) =>
              dispatch(
                events.handleChangeBodyDialog({
                  remark: _.get(event, 'target.value', ''),
                })
              )
            }
            value={bodyDialog.remark}
          />
        </Box>

        <Box
          sx={{
            gap: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ width: 80 }}
            data-testid="btn-download-qr-code"
            variant="outlined"
            size="m"
            onClick={() => dispatch(events.handleCloseDialog())}
          >
            <Typography variant="button">ยกเลิก</Typography>
          </Button>
          <Button
            sx={{ width: 80 }}
            data-testid="btn-download-qr-code"
            variant="contained"
            size="m"
            disabled={bodyDialog.remark ? false : true}
            onClick={() => dispatch(events.handleSubmitCheck(bodyDialog))}
          >
            <Typography variant="button">บันทึก</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Index
