import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/redux/Table'
import { setChangeRowsPerPage } from '../../../redux/slices/table'
import { MINI_ROW_PAGE_PER_LIST } from '../../../constants/table'
import { StyledCard } from './Styled'
import { handleEquipmentTable } from './events'

const Equipment = () => {
  const dispatch = useDispatch()
  const {
    table: { isLoading, limit, order, page, sort },
  } = useSelector((state) => state.table)

  useEffect(() => {
    dispatch(
      setChangeRowsPerPage({
        defaultRowsPerPage: 20,
        limit: 20,
        rowsPerPage: { label: '20', value: 20 },
      }),
    )
  }, [])
  useEffect(() => {
    dispatch(handleEquipmentTable())
  }, [page, sort, limit, order])

  return (
    <StyledCard isLoading={isLoading}>
      <Table rowsPerPageOptions={MINI_ROW_PAGE_PER_LIST} />
    </StyledCard>
  )
}

export default Equipment
