import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'

import { SelectBox } from '..'
import * as Styled from '../Styled'
import * as events from '../events'

const Index = ({ props }) => {
  const { itemConfig, indexConfig, listDistribution, valueDistribution } = props
  const dispatch = useDispatch()
  const { errors, setErrors } = useFormikContext()

  return (
    <>
      <Styled.BoxHeader>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6" sx={{ width: 115 }}>
            เงื่อนไข กลุ่ม {indexConfig + 1}
          </Typography>

          <SelectBox
            type="distributionChannel"
            indexContent={indexConfig}
            isHeader={true}
            label=""
            listOptions={listDistribution}
            value={valueDistribution}
            onChangeSelect={(e) => {
              events.deleteValidateHeader({ indexConfig, errors, setErrors })
              dispatch(
                events.handleChangeConfigGroup({
                  indexConfig,
                  key: 'distributionChannelUuid',
                  value: _.get(e, 'target.id', null),
                })
              )
            }}
            textError={_.get(
              errors,
              `licenseConfig[${indexConfig}].distributionChannelUuid`,
              ''
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Switch
            data-testid="switch-status"
            checked={_.get(itemConfig, 'flag', 'N') == 'Y'}
            onChange={(e) =>
              dispatch(
                events.handleChangeConfigGroup({
                  indexConfig,
                  key: 'flag',
                  value: _.get(e, 'target.checked', false) ? 'Y' : 'N',
                })
              )
            }
          />
          <Typography variant="body1" sx={{ mt: 0.5 }}>
            Auto ส่งข้อมูลไป RCMS
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ height: 45, mt: '-2px' }}
          />
          <IconButton
            sx={{ width: 35, height: 35 }}
            data-testid={`btn-delete-${indexConfig}`}
            onClick={() => {
              events.deleteValidateHeader({ indexConfig, errors, setErrors })
              dispatch(events.handleDeleteConfigGroup(indexConfig))
            }}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        </Box>
      </Styled.BoxHeader>
    </>
  )
}

export default Index
