import { isOic } from './events'

export const LEARNER_TAB = {
  detail: 'detail',
  attendance: 'attendance',
}

export const LEARNER_TAB_TH = {
  detail: 'ข้อมูลผู้เรียน',
  attendance: 'การเข้าเรียน',
}

export const TABLE_KEY = {
  attendance: 'courseLogLearners',
  module: 'courseLogLearnerPerLessons',
  face: 'learnerRekognitions',
  click: 'randomClickPerLessons',
}

export const tabs = [
  {label: LEARNER_TAB_TH.attendance, value: LEARNER_TAB.attendance},
  {label: LEARNER_TAB_TH.detail, value: LEARNER_TAB.detail},
]

export const breadcrumbLearnerView = (name, uuid, courseUuid) => {
  let linkView = `/e-learning/course/view/${courseUuid}`
  let linkList = `/e-learning/course/view/${courseUuid}?tab=learner`
  if (isOic()) {
    linkView = `/e-learning/course/batch/view/${uuid}`
    linkList = `/e-learning/course/batch/view/learner/${uuid}`
  }
  return [
    { title: 'E-Learning', link: '/', pointer: false },
    { title: '...', link: linkView, pointer: true },
    { title: 'รายชื่อผู้เรียน', link: linkList, pointer: true },
    { title: name, link: '/', pointer: false },
  ]
}
