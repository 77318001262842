import { StyledCard, StyledContent } from '../Styled'
import { StyledColumn } from '../../Styled'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { useEffect } from 'react'
import HistoryDrawer from './HistoryDrawer'
import { initialPreviewForm } from '../event'
import { SummaryBooking } from './components/SummaryBooking'
import { BookerSummary } from './components/BookerSummary'
import { RoomBookingList } from './components/RoomBookingList'
import { Loading } from '../../components/Loading'
import { FormFooter } from '../components/FormFooter'
import { BOOKING_PERIOD_TYPE } from '../../../../constants/roomManagement'
import { setReduxValue } from '../../../../redux/slices/roomManagement/Drawer'
import { RowLabels } from './components/RowLabels'
import useTheme from '@mui/system/useTheme'

export const PreviewContent = () => {
  const theme = useTheme()
  const {
    bookingInformation,
    isLoadingDrawer,
    roomBookingList,
    bookingUuid,
    status,
    canceledReason,
    isEditingForm,
    ishShowHistoryDrawer,
  } = useSelector(
    (state) => ({
      bookingInformation: state.roomManagementDrawer.bookingInformation,
      isLoadingDrawer: state.roomManagementDrawer.isLoadingDrawer,
      roomBookingList:
        state.roomManagementDrawer.bookingInformation.roomBookingList,
      bookingUuid: state.roomManagementDrawer.bookingInformation.bookingUuid,
      status: state.roomManagementDrawer.bookingInformation.status,
      canceledReason:
        state.roomManagementDrawer.bookingInformation.canceledReason,
      isEditingForm: state.roomManagementDrawer.isEditingForm,
      ishShowHistoryDrawer: state.roomManagementDrawer.ishShowHistoryDrawer,
    }),
    shallowEqual,
  )

  const bookingPeriod = _.get(bookingInformation, 'bookingPeriod', '')

  useEffect(() => {
    if (!_.isEmpty(bookingUuid) && !isEditingForm) {
      initialPreviewForm({ bookingUuid })
    }
  }, [])

  return (
    <>
      {!isLoadingDrawer && (
        <StyledContent sx={{ height: 'auto', flex: 1 }}>
          <>
            <StyledCard sx={{ p: 3 }}>
              <StyledColumn sx={{ gap: 2 }}>
                <SummaryBooking />
                {bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME ? (
                  <RoomBookingList index={0} />
                ) : (
                  _.map(roomBookingList, (room, index) => {
                    return <RoomBookingList index={index} />
                  })
                )}

                {status === 'CANCEL' && (
                  <RowLabels
                    titleSx={{ minWidth: 100 }}
                    title={'สาเหตุการยกเลิก'}
                    label={canceledReason}
                    labelSx={{ color: theme.palette?.error?.main }}
                  />
                )}
                <BookerSummary />
              </StyledColumn>
            </StyledCard>
            <FormFooter />
          </>
        </StyledContent>
      )}
      <Loading isLoading={isLoadingDrawer} />
      <HistoryDrawer
        isOpen={ishShowHistoryDrawer}
        onCloseDrawer={() =>
          setReduxValue({ key: 'ishShowHistoryDrawer', value: false })
        }
      />
    </>
  )
}
