import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import IconButton from '@mui/material/IconButton'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table'
import { StyledBox } from '../Styled'

const ActionELearning = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { onDelete } = useSelector((state) => state.table)
  const viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`

  return (
    <StyledBox minWidth={minWidth}>
      {row.canView && !condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
      {row.canEdit && !condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {row.canDelete && !condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionELearning
