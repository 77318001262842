import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const StyledBoxHeader = styled(Box)(({ theme, scrolled }) => ({
  width: '100%',
  position: 'fixed',
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(8),
  marginTop: -14,
  backgroundColor: theme?.palette?.background?.blue,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2.5),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    ...FixedHeaderStyleMd(theme, scrolled),
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    gap: 16,
    ...FixedHeaderStyleSm(theme, scrolled),
  },
}))

const FixedHeaderStyle = (theme) => ({
  top: 80,
  zIndex: 8,
  boxShadow: theme?.palette?.shadow?.bottom,
})

export const FixedHeaderStyleMd = (theme, scrolled) =>
  scrolled
    ? {
        ...FixedHeaderStyle(theme),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        gap: theme.spacing(2),
      }
    : { gap: theme.spacing(2) }

export const FixedHeaderStyleSm = (theme, scrolled) =>
  scrolled
    ? {
        ...FixedHeaderStyle(theme),
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }
    : {}

export const StyledBoxLabelTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledInputImport = styled('input')({ display: 'none' })
