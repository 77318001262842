import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  totalUserAgency: {
    ALL: 0,
    MANAGER: 0,
    AGENT: 0,
    TERMINATE: 0,
  },
  totalUserBanc: {
    ALL: 0,
    KTB_FP: 0,
    KTB_LB: 0,
    BSO: 0,
    TERMINATE: 0,
  },
  totalProspect: {
    ALL: 0,
    ACTIVE: 0,
    INACTIVE: 0,
    IS_REF: 0,
  },
  userAccessAgency: {
    ALL: 0,
    MANAGER: 0,
    AGENT: 0,
    TERMINATE: 0,
  },
  userAccessBanc: {
    ALL: 0,
    KTB_FP: 0,
    KTB_LB: 0,
    BSO: 0,
    TERMINATE: 0,
  },
  userAccessProspect: {
    ALL: 0,
    ACTIVE: 0,
    INACTIVE: 0,
    IS_REF: 0,
  },
}

export const agencyDrawerEnum = {
  isOpen: false,
  countTotal: 0,
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  filterTotal: 0,
}