import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import Table from '../../../components/redux/Table'
import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { handleOperatorList } from './events'
import { StyledColumn, StyledRow } from '../Styled'
import { formattedMonth } from '../DashboardSection/WeeklyBookingsCard/event'
import DropdownButton from './DropdownButton'

const OperatorDrawer = ({ isOpen, onCloseDrawer }) => {
  const table = useSelector((state) => state.table.table, shallowEqual)
  const { page, limit } = table
  const dispatch = useDispatch()

  const { operator, totalOperator, month, year } = useSelector(
    (state) => ({
      operator: state.roomManagement.data.operator,
      totalOperator: state.roomManagement.data.totalOperator,
      month: state.roomManagement.month,
      year: state.roomManagement.year,
    }),
    shallowEqual,
  )
  useEffect(() => {
    if (isOpen) dispatch(handleOperatorList({ table, operator, totalOperator }))
  }, [isOpen, page, limit])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ผู้ดำเนินการ</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={onCloseDrawer}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledContent>
        <StyledColumn sx={{ gap: 2, mt: 2, mx: 3 }}>
          <StyledRow sx={{ justifyContent: 'space-between' }}>
            <Typography variant="body1b">ทั้งหมด {totalOperator} คน</Typography>
            <StyledRow sx={{ gap: 2 }}>
              <Typography variant="body1b">ปี {year}</Typography>
              <Typography variant="body1b">
                เดือน {formattedMonth(month)}
              </Typography>
              <DropdownButton />
            </StyledRow>
          </StyledRow>
          <Table />
        </StyledColumn>
      </StyledContent>
    </StyledDrawer>
  )
}

export default OperatorDrawer
