import { createSlice } from '@reduxjs/toolkit'
import { getDetailQuestion } from '../../../../services/eLearning/question/detail'
import { initialState } from './model'

const eLearningQuestion = createSlice({
  name: 'eLearningQuestion',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setOpenDrawer: (state, { payload }) => {
      state.isDrawerOpen = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetailQuestion.fulfilled, (state, { payload }) => {
        state.result = payload.data
      })
      .addCase(getDetailQuestion.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading, setChange, setOpenDrawer } =
  eLearningQuestion.actions

export default eLearningQuestion.reducer
