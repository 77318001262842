import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import {
  ContentRow,
  CoverImageContent,
  StatusContent,
} from '../../../../../components/View'
import { StyledCardContent } from '../../../../../components/View/Styled'
import PreviewVideoContent from '../../../../../components/PreviewVideoContent'

const Detail = () => {
  const detail = useSelector((state) => state.eLearningCourseDetail.result)
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        รายละเอียดหลักสูตร
      </Typography>
      <ContentRow
        title="รหัสหลักสูตร"
        content={_.get(detail, 'courseCode', '-')}
      />
      <ContentRow title="ชื่อหลักสูตร" content={_.get(detail, 'name', '-')} />
      <ContentRow
        title="ชื่อหลักสูตร (สำหรับ Learner)"
        content={_.get(detail, 'nameForLearner', '-') || '-'}
      />
      <ContentRow title="วิชา" content={_.get(detail, 'subject', '-') || '-'} />
      <ContentRow
        title="รายละเอียดหลักสูตร"
        content={_.get(detail, 'description', '-') || '-'}
      />

      <CoverImageContent detail={detail} />

      <PreviewVideoContent url={_.get(detail, 'linkExampleVideo', '')} />

      <ContentRow
        title="Remark"
        content={_.get(detail, 'remark', '-') || '-'}
      />

      <StatusContent status={_.get(detail, 'status', false)} />
    </StyledCardContent>
  )
}

export default Detail
