import React, { Fragment } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import TextInput from '../../../../../../../components/Input/TextInput'
import {
  StyledImagePreview,
  StyledChoiceContainer,
} from '../../../../../../../components/Preview/QuestionCard/Styled'
import {
  handleMultipleChange,
  handleFreeTextChange,
  handleSingleChange,
  isOtherInputShow,
  handleDisableCheckbox,
  handleSelector,
  handleOtherChange,
} from './events'

const Choice = (props) => {
  const { keyQuestion } = props
  const question = useSelector(
    (state) => handleSelector(state, props),
    shallowEqual
  )
  const { choices, answer, type } = question
  const dispatch = useDispatch()

  return (
    <StyledChoiceContainer>
      {type == 'FREE_TEXT' ? (
        <TextInput
          type="basic"
          multiline
          rows={2}
          isShowTextError={false}
          defaultValue={answer[0]}
          id={`free-text-${keyQuestion}`}
          onBlur={(e) => dispatch(handleFreeTextChange(e, props))}
        />
      ) : (
        <RadioGroup
          onChange={(e) => dispatch(handleSingleChange(e, props, choices))}
        >
          {choices.map((choice, index) => (
            <Fragment key={index}>
              {type == 'SINGLE' && (
                <FormControlLabel
                  sx={{ mb: 1, wordBreak: 'break-word' }}
                  control={<Radio />}
                  value={choice.uuid}
                  label={choice.title}
                  checked={choice.uuid == answer[0]}
                  data-testid={`choice-${keyQuestion}-${index}`}
                />
              )}

              {type == 'MULTIPLE' && (
                <FormControlLabel
                  sx={{ mb: 1, wordBreak: 'break-word' }}
                  control={
                    <Checkbox
                      disabled={handleDisableCheckbox(question, choice.uuid)}
                      onChange={(e) =>
                        dispatch(handleMultipleChange(e, index, props))
                      }
                      inputProps={{
                        'data-testid': `choice-${keyQuestion}-${index}`,
                      }}
                    />
                  }
                  label={choice.title}
                  checked={answer[index].isChecked}
                />
              )}

              {isOtherInputShow(choice, type, answer, index) && (
                <TextInput
                  type="basic"
                  multiline
                  rows={2}
                  placeholder="โปรดระบุ"
                  isShowTextError={false}
                  id={`other-${keyQuestion}`}
                  boxSx={{ ml: 4, mt: '-10px' }}
                  onBlur={(e) =>
                    dispatch(handleOtherChange(e, choice, keyQuestion))
                  }
                />
              )}

              {choice.mediaUrl && (
                <StyledImagePreview
                  choice
                  src={`${window.__env__.REACT_APP_API_URL}/file${choice.mediaUrl}`}
                />
              )}
            </Fragment>
          ))}
        </RadioGroup>
      )}
    </StyledChoiceContainer>
  )
}

export default Choice
