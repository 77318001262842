import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import CustomChipLabel from './CustomChipLabel'
import { handleChange } from './events'

const StatusCheckBox = () => {
  const { statusLearnerDrawer } = useSelector(
    (state) => ({
      statusLearnerDrawer: state.eLearningDashboardByCourse.statusLearnerDrawer,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body1b" color="text.secondary">
        สถานะการเรียนที่แสดง
      </Typography>
      <FormGroup
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {_.map(statusLearnerDrawer, (status, idx) => {
          return (
            <Box key={idx} display="flex" alignItems="center">
              <FormControlLabel
                sx={{ mx: 1 }}
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ 'data-testid': `check-${status?.status}` }}
                    name={status?.status}
                    checked={status?.checked}
                    onChange={(e) => {
                      handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                      })
                    }}
                  />
                }
                label={status?.label}
              />
              <CustomChipLabel
                boxSx={{ py: 0.5, height: 24 }}
                label={`(${status?.count} คน)`}
              />
            </Box>
          )
        })}
      </FormGroup>
    </Box>
  )
}

export default StatusCheckBox
