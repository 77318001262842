import { StyledListItem } from '../../../../Styled'
import { Box, IconButton, Typography } from '@mui/material'
import { DragIndicator, Close } from '@mui/icons-material'
import React from 'react'
import { handleDeleteColumn } from '../../../../handler/handleDeleteColumn'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useTheme from '@mui/system/useTheme'

const ColumnListItem = ({ provided, col }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { displayColumns } = useSelector(
    (state) => ({
      displayColumns: state.reportComp.displayColumns,
    }),
    shallowEqual,
  )

  return (
    <StyledListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      isDragDisabled={displayColumns?.length === 1}
    >
      <Box
        {...provided.dragHandleProps}
        sx={{ display: 'flex', alignItem: 'center' }}
      >
        <DragIndicator />
      </Box>
      <Typography
        sx={{
          my: 'auto',
          ml: 4,
          fontWeight: theme.typography.body2b.fontWeight,
          fontSize: theme.typography.body2b.fontSize,
        }}
      >
        {col.label}
      </Typography>
      {displayColumns?.length > 1 && (
        <IconButton
          color="primary"
          component="span"
          onClick={() => dispatch(handleDeleteColumn(col.id))}
          sx={{ marginLeft: 'auto' }}
        >
          <Close />
        </IconButton>
      )}
    </StyledListItem>
  )
}

export default ColumnListItem
