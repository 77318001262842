import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { writeStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import TextInput from '../../../../../../components/Input/TextInput'
import { isMediaWidthMoreThan } from '../../../../../../utils/lib'
import { StyledColumn, StyledRow } from '../../Styled'
import {
  handleInputChange,
  handleNumberInput,
  handleResetChange,
  handleSubmit,
  handleEditSuccess,
} from './events'
import {
  StyleScoreContainer,
  StyledCancelButton,
  StyledLabel,
  StyledScoreForm,
  StyledSubmitButton,
} from './Styled'

const QuestionCard = ({ index, isStandalone }) => {
  const idParams = useParams()
  const [isEdit, setEdit] = useState(false)
  const editProps = { isEdit, setEdit }
  const { isSuccess, isLoading, learnerExam } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const dispatch = useDispatch()
  const eTestingType = _.get(learnerExam, 'eTestingType', '')
  const learnerAnswers = _.get(learnerExam, 'learnerAnswers', [])
  const data = useMemo(
    () => _.get(learnerAnswers, `[${index}]`, null),
    [learnerExam]
  )
  const isChecked = _.get(data, 'isChecked', false)
  const isDisable = isChecked && !isEdit
  const answerScore = _.get(data, 'answerScore', '')
  const defaultScore = !answerScore && !isChecked ? '' : answerScore
  const maxScore = _.get(data, 'maxScore', '')
  const isAnnounce = _.get(data, 'isAnnounce', false)
  const idProps = { ...idParams, uuid: _.get(learnerExam, 'uuid', '') }
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    dispatch(
      handleEditSuccess({
        isSuccess,
        editProps,
        learnerExam,
        history,
        writeStorage,
      })
    )
  }, [isSuccess])

  return (
    <Card sx={{ position: 'relative', p: 2 }}>
      <StyledColumn gap={2}>
        <StyledRow gap={1}>
          <Typography variant="body1b">
            Q{_.get(data, 'sequence', '-')}.
          </Typography>
          <Typography variant="body1b">{_.get(data, 'title', '-')}</Typography>
        </StyledRow>
        {eTestingType !== 'ASSESSMENT' && (
          <StyledRow gap={1}>
            <Typography variant="body1b">ตอบ</Typography>
            <Typography>{_.get(data, 'freetextAnswer', '-')}</Typography>
          </StyledRow>
        )}
        <TextInput
          id="input-suggest"
          name="freetextSuggestion"
          placeholder="เฉลย, แสดงความคิดเห็น หรือให้ข้อเสนอแนะ"
          type="basic"
          multiline
          rows={2}
          disabled={isDisable}
          isShowTextError={false}
          value={_.get(data, 'freetextSuggestion', '') || ''}
          onChange={(e) => dispatch(handleInputChange(e, index))}
        />
        <StyledRow justifyContent="end">
          <StyleScoreContainer>
            <StyledLabel>
              <Typography variant="h6">ให้คะแนน</Typography>
              <Typography color="text.gray">
                (คะแนนสูงสุด {maxScore} คะแนน)
              </Typography>
            </StyledLabel>
            <StyledScoreForm>
              <TextInput
                id="input-score"
                name="answerScore"
                type="basic"
                isShowTextError={false}
                boxSx={{ width: isNotSm ? 'auto' : '100%' }}
                inputProps={{
                  'data-testid': 'input-score',
                  min: '0',
                  onInput: (e) => handleNumberInput(e, maxScore),
                }}
                sx={{ height: 50, input: { textAlign: 'center' } }}
                disabled={isDisable}
                value={defaultScore}
                onChange={(e) => dispatch(handleInputChange(e, index))}
              />

              {!isAnnounce && (
                <>
                  {isDisable ? (
                    <Button
                      size="l"
                      color="inherit"
                      data-testid="btn-edit"
                      onClick={() => setEdit(true)}
                    >
                      <EditTwoTone />
                    </Button>
                  ) : (
                    <StyledSubmitButton
                      data-testid="btn-submit"
                      disabled={!answerScore}
                      loading={isLoading}
                      onClick={() =>
                        dispatch(
                          handleSubmit({ idProps, data, isEdit, isStandalone })
                        )
                      }
                    >
                      ยืนยัน
                    </StyledSubmitButton>
                  )}
                  {isChecked && isEdit && (
                    <StyledCancelButton
                      data-testid="btn-cancel"
                      onClick={() =>
                        dispatch(handleResetChange(index, setEdit))
                      }
                    >
                      ยกเลิก
                    </StyledCancelButton>
                  )}
                </>
              )}
            </StyledScoreForm>
          </StyleScoreContainer>
        </StyledRow>
      </StyledColumn>
    </Card>
  )
}

export default QuestionCard
