import React from 'react'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'
import { Column } from '../../../../components/Layout/Styled'
import RoomStatBarChart from './RoomStatBarChart'

const MeetingRoomStat = () => {
  const { roomStat } = useSelector(
    (state) => ({
      roomStat: state.crud.roomManagementDashboard?.overview?.roomStat,
    }),
    shallowEqual,
  )
  return (
    <Column mt={5} gap={6}>
      {roomStat?.length > 0 ? (
        roomStat?.map((ele, ind) => {
          const sumData = []
          let hasZero = false
          for (let ind = 0; ind < 7; ind++) {
            sumData.push(ele?.dataSets?.MONTHLY_PLAN[ind])
            sumData.push(ele?.dataSets?.MANAGE_CLASS[ind])
            sumData.push(ele?.dataSets?.E_BOOKING[ind])
            if (
              ele?.dataSets?.MONTHLY_PLAN[ind] == 0 ||
              ele?.dataSets?.MANAGE_CLASS[ind] == 0 ||
              ele?.dataSets?.E_BOOKING[ind] == 0
            ) {
              hasZero = true
            }
          }
          const maxData = Math.max(...sumData)
          return (
            <RoomStatBarChart
              key={ind}
              dataSet={[
                {
                  label: 'Monthly Plan',
                  data: ele?.dataSets?.MONTHLY_PLAN,
                  inLineLabel: ele?.label?.MONTHLY_PLAN,
                  backgroundColor: new Array(7).fill('#4976BA'),
                },
                {
                  label: 'Manage Class',
                  data: ele?.dataSets?.MANAGE_CLASS,
                  inLineLabel: ele?.label?.MANAGE_CLASS,
                  backgroundColor: new Array(7).fill('#E99E7D'),
                },
                {
                  label: 'E-Booking',
                  data: ele?.dataSets?.E_BOOKING,
                  inLineLabel: ele?.label?.E_BOOKING,
                  backgroundColor: new Array(7).fill('#32A287'),
                },
              ]}
              labels={[
                'จันทร์',
                'อังคาร',
                'พุธ',
                'พฤหัสบดี',
                'ศุกร์',
                'เสาร์',
                'อาทิตย์',
              ]}
              height={'430px'}
              showXAxisLabel={true}
              showYAxisLabel={true}
              xAxisLabel="วัน"
              yAxisLabel="ยอดเวลาการจอง"
              eleId={`room-stacked-chart-${ind}`}
              maxData={maxData}
              hasZero={hasZero}
              name={ele.name}
            />
          )
        })
      ) : (
        <Typography variant="body2" color="text.lightGray" mx="auto" my={30}>
          ไม่พบข้อมูล
        </Typography>
      )}
    </Column>
  )
}

export default MeetingRoomStat
