import axios from 'axios'
import { trainingPlanSaveQRForEnroll } from '../../../../../utils/apiPath'
import {
  setQREnrollProps,
  setQrExpired,
} from '../../../../../redux/slices/manageClassDetail'
import {
  openDialog,
  closeDialog,
} from '../../../../../../src/redux/slices/dialog'
import { ContentDialog } from './DialogContents'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { EXPIRY_TYPE } from '../../../../../constants/manageClass/qrCodeForEnroll'
import _ from 'lodash'
import dayjs from 'dayjs'
import { prepareDataForEnroll } from '../events'
import { store } from '../../../../../App'
dayjs.extend(customParseFormat)

export const saveQRForEnroll = (props) => async (dispatch) => {
  const { uuid, expiryType, expiryDate, expiryTime, isEnable } = props
  const expDate = formatDateTime(expiryDate, expiryTime)
  const body = {
    expiryType: expiryType,
    uuid: uuid,
    expiryDate: hasExpiry(expiryType) ? expDate : null,
  }
  await axios
    .post(trainingPlanSaveQRForEnroll, body)
    .then(({ data }) => {
      const { link, expiryDate, expiryType } = data
      const enrollProps = prepareDataForEnroll(link, expiryDate, expiryType)
      dispatch(setQREnrollProps(enrollProps))
      if (_.isNull(expiryDate) && _.isNull(link)) {
        dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ปิดการใช้งาน QR Code เรียบร้อยแล้ว',
            handleConfirm: () => {
              dispatch(closeDialog())
            },
          }),
        )
      }
    })
    .catch((e) => {
      const constraints = _.get(e, 'response.data.constraints', '')
      const { key, title } = constraints
      if (key === 'IsNotPresentTime') {
        dispatch(
          openDialog({
            type: 'content',
            content: (
              <ContentDialog
                icon={
                  <img
                    src="/icon/calendar-x-duotone.svg"
                    width={75}
                    height={75}
                  />
                }
                title={title}
                btnText={'กรุณาลองอีกครั้ง'}
                onClick={() => dispatch(closeDialog())}
              />
            ),
            showButton: false,
            sx: {
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  maxWidth: 438,
                },
              },
            },
          }),
        )
      } else if (key === 'InternalServerError') {
        if (isEnable) {
          dispatch(
            openDialog({
              type: 'content',
              content: (
                <ContentDialog
                  icon={<img src="/icon/qr-code-off.svg"></img>}
                  title={'พบปัญหาบางอย่าง'}
                  message={'ไม่สามารถสร้าง QR Code ได้'}
                  btnText={'กรุณาลองอีกครั้ง'}
                  onClick={() => dispatch(closeDialog())}
                />
              ),
              showButton: false,
              sx: {
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    maxWidth: 438,
                  },
                },
              },
            }),
          )
        } else {
          dispatch(
            openDialog({
              type: 'fail',
              title: title,
              message:
                'ไม่สามารถปิดการใช้งานได้ เนื่องจากระบบขัดข้อง โปรดลองใหม่อีกครั้ง',
              handleConfirm: () => {
                dispatch(closeDialog())
              },
            }),
          )
        }
      }
    })
}

export const formatDateTime = (date, time) => {
  return dayjs(`${date} ${time}`, `YYYY-MM-DD HH:mm`).toDate()
}

export const formatDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatTime = (date) => {
  return dayjs(date).format('HH:mm')
}

export const countdownTime =
  (expiryDate, expiryTime, timer, setTime) => (dispatch) => {
    const { qrExpired } = store.getState().manageClassDetail.qrEnrollProps
    const expDate = formatDateTime(expiryDate, expiryTime)
    const currentDate = new Date()
    const totalSec = Math.ceil((expDate - currentDate) / 1000)
    let timerCountdown = {
      days: 0,
      hrs: 0,
      mins: 0,
      secs: 0,
    }
    if (totalSec > 0) {
      timerCountdown = calCountdownTimer(totalSec)
      setTime(timerCountdown)
    }
    const { days, hrs, mins, secs } = timerCountdown
    if (qrExpired) return
    if (days === 0 && hrs === 0 && mins === 0 && secs === 0) {
      dispatch(setQrExpired(true))
      clearInterval(timer)
      setTime(timerCountdown)
    } else if (hrs === 0 && mins === 0 && secs === 0) {
      setTime({
        days: days - 1,
        hrs: 23,
        mins: 59,
        secs: 59,
      })
    } else if (mins === 0 && secs === 0) {
      setTime({
        days: days,
        hrs: hrs - 1,
        mins: 59,
        secs: 59,
      })
    } else if (secs === 0) {
      setTime({
        days: days,
        hrs: hrs,
        mins: mins - 1,
        secs: 59,
      })
    } else {
      setTime({
        days: days,
        hrs: hrs,
        mins: mins,
        secs: secs - 1,
      })
    }
  }

export const hasExpiry = (expType) => {
  return expType === EXPIRY_TYPE.HAS_EXPIRY
}

export const noExpiry = (expType) => {
  return expType === EXPIRY_TYPE.NO_EXPIRY
}

export const calCountdownTimer = (totalSeconds) => {
  return {
    days: Math.floor(totalSeconds / 3600 / 24),
    hrs: Math.floor(totalSeconds / 3600) % 24,
    mins: Math.floor(totalSeconds / 60) % 60,
    secs: Math.floor(totalSeconds % 60),
  }
}
