import { useDispatch } from 'react-redux'
import Select from '../../../../../../components/Input/Select'
import {
  getAllLayout,
  handleRoomLayoutOptions,
} from '../../../../../../modules/RoomManagement/BookingDrawer/FormBooking/DetailsBookingCard/event'
import { handleInputRoomChange } from '../events'
import _ from 'lodash'

export const RoomLayout = ({
  roomLayout,
  roomDetailIndex,
  meetingRoomDetail,
  bookingDate,
}) => {
  const dispatch = useDispatch()
  let layoutOption = getAllLayout()
  if (
    !_.isEmpty(_.get(meetingRoomDetail, 'meetingRoom.uuid')) &&
    _.get(meetingRoomDetail, 'meetingRoom.uuid') !== 'OTHERS'
  ) {
    layoutOption = handleRoomLayoutOptions(
      _.get(meetingRoomDetail, 'meetingRoom', {}),
    )
  }

  return (
    <Select
      dataTestid="select-roomLayout"
      id="roomLayout"
      name="roomLayout"
      labelText={'รูปแบบการจัดห้อง'}
      placeholder="กรุณาเลือก"
      options={layoutOption}
      value={roomLayout}
      handleChange={(e) => {
        dispatch(
          handleInputRoomChange(e, 'roomLayout', roomDetailIndex, bookingDate),
        )
      }}
    />
  )
}
