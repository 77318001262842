import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../components/Input/TextInput'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import { handleInputBody, handleSelector } from '../events'
import ShowHideButton from '../ShowHideButton'

const Title = () => {
  const [show, setShow] = useState(false)
  const keyField = 'titleInformation'
  const titleInformation = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { errors } = useFormikContext()

  return (
    <StyledSection show={show} >
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">Section 2 | Title Section</Typography>
        <ShowHideButton show={show} setShow={setShow} />
      </StyledRow>
      {show && (
        <StyledColumn sx={{ gap: 0 }}>
          <TextInput
            name="title"
            type="basic"
            labelText="Title"
            id="input-title"
            placeholder="บรรดทัดที่ 2"
            value={_.get(titleInformation, 'title', '')}
            textError={_.get(errors, `${keyField}.title`, '')}
            onChange={(e) => dispatch(handleInputBody(e, keyField))}
            textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
          />
          <TextInput
            name="subtitle"
            type="basic"
            labelText="Subtitle"
            id="input-subtitle"
            placeholder="บรรดทัดที่ 1"
            value={_.get(titleInformation, 'subtitle', '')}
            textError={_.get(errors, `${keyField}.subtitle`, '')}
            onChange={(e) => dispatch(handleInputBody(e, keyField))}
            textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
          />
        </StyledColumn>
      )}
    </StyledSection>
  )
}

export default Title