import _ from 'lodash'

import { agentProfileDownload, agentProfileFilter } from '../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../redux/slices/table'
import { downloadFile } from '../../services/util'
import {
  agentProfileCells,
  agentProfilePointCells,
  csAgentProfileCells,
  csAgentProfilePointCells,
} from '../../constants/manage/agentProfile'
import { initialState } from '../../redux/slices/table/model'
import callAxios from '../../utils/baseService'
import { openDialog } from '../../redux/slices/dialog'
import { resetUploadForm } from '../../redux/slices/manage/agentProfile'
import UploadPointDialog from './UploadPointDialog'

export const onDownload = (selected, sort, order, type) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    list: selected,
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'updatedAt' : sort,
    type: type,
  }
  await dispatch(
    downloadFile({
      url: agentProfileDownload,
      body: body,
      fileName: `รายการ ${type === 'CS' ? 'CS ' : ''}Agent Profile.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (
    method,
    table,
    quickSeach,
    manageProps,
    setInitial,
    agentType,
    hasPermission,
  ) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState : table
    const filterProp = _.get(tableProps, 'filter.filterProp', null)
    const agentCode = _.get(filterProp, 'agentCode', '')
    const firstName = _.get(filterProp, 'firstName', '')
    const lastName = _.get(filterProp, 'lastName', '')
    const firstNameTH = _.get(filterProp, 'firstNameTH', '')
    const lastNameTH = _.get(filterProp, 'lastNameTH', '')
    const unitNumber = _.get(filterProp, 'unitNumber', '')

    const page = _.get(tableProps, 'table.page', 1)
    const realPage = page <= 0 ? 1 : +page
    const status = _.get(filterProp, 'status', [])
    const agentCategory = _.get(filterProp, 'agentCategory', [])
    const point = _.get(filterProp, 'point', '')
    const limit = _.get(tableProps, 'table.limit', 20)
    const order = _.get(tableProps, 'table.order', 'desc')
    const sort = _.get(tableProps, 'table.sort', 'updatedAt')
    const type = _.defaultTo(agentType, 'AGENT')

    const body = {
      agentCode: agentCode,
      firstName: method === 'search' ? quickSeach : firstName,
      lastName: lastName,
      firstNameTH: firstNameTH,
      lastNameTH: lastNameTH,
      unitNumber: unitNumber,
      limit: Number(limit),
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sort === 'updatedAt' ? 'updatedAt' : sort,
      agentCategory: agentCategory,
      point: point,
      status: status,
      type: type,
    }
    let headCells = []
    if (hasPermission) {
      headCells =
        type === 'CS' ? csAgentProfilePointCells : agentProfilePointCells
    } else {
      headCells = type === 'CS' ? csAgentProfileCells : agentProfileCells
    }
    const isCancel = await callAxios
      .post(agentProfileFilter, body)
      .then(({ data }) => {
        const rows = data.result
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: headCells,
            placeholder: 'ค้นหาชื่อ',
            searchKey: 'firstName',
            handleSearch: (text) =>
              dispatch(
                handleQuickSearch(
                  tableProps,
                  text,
                  manageProps,
                  setInitial,
                  agentType,
                ),
              ),
            onDownload: (selected, oSort, oOrder) =>
              dispatch(onDownload(selected, oSort, oOrder, type)),
            onUpload: hasPermission ? () => dispatch(onUpload()) : '',
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: agentProfileCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  const agentList = []

  if (filter.statusChecked) {
    if (filter.status?.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status?.inActiveChecked) {
      statusList.push('INACTIVE')
    }
  }

  if (filter.agentCategoryChecked) {
    if (filter.agentCategory?.aChecked) {
      agentList.push('A')
    }
    if (filter.agentCategory?.lChecked) {
      agentList.push('L')
    }
  }

  const filterText = {
    agentCode: filter.agentCodeChecked ? filter.agentCode : '',
    firstName: filter.firstNameChecked ? filter.firstName : '',
    lastName: filter.lastNameChecked ? filter.lastName : '',
    firstNameTH: filter.firstNameTHChecked ? filter.firstNameTH : '',
    lastNameTH: filter.lastNameTHChecked ? filter.lastNameTH : '',
    agentCategory: filter.agentCategoryChecked ? agentList : [],
    unitNumber: filter.unitNumberChecked ? filter.unitNumber : '',
    point: filter.pointChecked ? filter.point : '',
    status: filter.statusChecked ? statusList : [],
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
}

export const handleQuickSearch =
  (table, text, manageProps, setInitial, agentType) => (dispatch) => {
    dispatch(setSearchText(text))
    dispatch(setFilterProp(null))
    dispatch(setFilterTotal(null))
    dispatch(resetTablePage())
    dispatch(
      fetchDataList('search', table, text, manageProps, setInitial, agentType),
    )
  }

export const onUpload = () => (dispatch) => {
  dispatch(resetUploadForm())
  dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <UploadPointDialog />,
    }),
  )
}
