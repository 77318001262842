import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { BadgeImage } from './Styled'

const Badge = () => {
  const badges = useSelector(
    (state) => state.agentProfile.initialState.badge,
    shallowEqual,
  )
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">เหรียญรางวัล</Typography>
      {badges.length > 0 ? (
        <Grid container spacing={1} sx={{ p: 1 }}>
          {badges.map((badge, ind) => {
            return (
              <Grid
                key={ind}
                item
                md={4}
                sm={6}
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <BadgeImage
                  src={`${window.__env__.REACT_APP_API_URL}/file/get${badge.learningPoint.badgeImage?.key}`}
                  $disable={badge.learningPoint.badgeStatus === 'INACTIVE'}
                />
                <Typography
                  varaint="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {badge.learningPoint.badgeName}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography varaint="body2" sx={{ textAlign: 'center', mt: 5 }}>
          ไม่พบข้อมูล
        </Typography>
      )}
    </Card>
  )
}

export default Badge
