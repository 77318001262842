import { defaultValue } from '../../../constants/eTesting'
export const initialState = {
  codeId: '',
  uuid: '',
  defaultTap: defaultValue.defaultTap.testing,
  isLoading: false,
  typeOfETesting: defaultValue.typeOfETesting.onlineTest,
  isDraft: true,
  isClone: false,
  onGoing: false,
  onViewMode: false,
  errorsApi: false,
  disableForm: false,
  disableSetting: false,
  version: 0,
  information: {
    coverImage: '',
    coverText: '',
    description: '',
    descriptionPosition: defaultValue.position.left,
    name: '',
    title1: '',
    title1Position: defaultValue.position.left,
    title2: '',
    title2Position: defaultValue.position.left,
  },
  questions: [],
  setting: {
    // get
    status: 'DRAFT',
    createdAt: null,
    createdBy: '-',
    updatedAt: null,
    updatedBy: '-',
    courseRef: [],
    // end get
    startDate: null,
    limitTime: {
      isOpen: false, // true | false
      minute: 30,
    },
    assignees: [],
    examiners: [],
    displayQuestion: defaultValue.displayQuestion.all, // ALL | ONE
    randomQuestion: false, // true | false
    randomAnswer: false, // true | false
    testResult: defaultValue.testResult.now,
    questionResult: {
      isOpen: false, // true | false
      result: defaultValue.testResult.later,
      resultType: defaultValue.resultType.showFalseOnly,
    },
    testRound: {
      roundUnlimit: true, // true | false
      round: 1,
    },
    notification: true, // true | false
    testCriteriaType: null,
    criteriaPercent: null,
    isShare: false,
  },
  listAssignee: [],
  dateServer: null,
  swapQuestion: {
    position: 'BEFORE',
    keyQuestion: 0,
    indexSelect: 0,
  },
  errors: {
    video: '',
  },
  totalSubmit: 0,
  totalWaitingToCheck: 0,
  totalWaitingToAnnounce: 0,
  listExaminer: [],
}

export const editStatus = {
  ENABLE_ALL: 'ENABLE_ALL', // แก้ไขได้ทั้งหมด
  DISABLE_SETTING: 'DISABLE_SETTING', // แก้เฉพาะ tab แบบทดสอบ
  DISABLE_ALL: 'DISABLE_ALL', // ปิดการแก้ไขทั้งหมด
}
