import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import DoughnutGraph from '../../../../../components/DoughnutGraph/DoughnutGraph'
import { StyledCard } from './Styled'

export const GraphCard = ({ status }) => {
  const manageClassGraph = useSelector(
    (state) => state.manageClassDetail.graph,
    shallowEqual
  )

  return (
    <StyledCard>
      <Typography variant="body1b">ผลการอบรม</Typography>
      <Box sx={{ marginLeft: '16px' }}>
        <DoughnutGraph
          showPercentage
          graphData={manageClassGraph}
          haveWaiting={status === 'ENDCLASS' ? true : false}
        />
      </Box>
    </StyledCard>
  )
}
