export const initialState = {
  isLoading: false,
  isLoadingDrawer: false,
  isLoadingButton: false,
  openDialogUpload: false,
  disableSaveButton: true,
  information: {
    station: '',
    roomName: '',
    floor: '',
    meetingRoomEquipment: [],
    roomFunction: '',
    roomType: '',
    status: 'ACTIVE',
    meetingRoomImages: [],
    meetingRoomImagesUploadList: [],
    imageCanUploadList: [],
  },
  equipmentSelected: [],
  imagesErrorList: [],
}
