import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useDispatch } from 'react-redux'
import { getQueryParams } from '../../../utils/lib'
import FloatingButtons from './components/FloatingButtons'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import { StyledContainer } from './Styled'
import { handleInitialPreview } from './events'

const Preview = () => {
  const dispatch = useDispatch()
  const uuid = getQueryParams('md')
  const [eLearning, setELearning] = useLocalStorage('eLearningPreview')

  useEffect(() => {
    dispatch(handleInitialPreview({ eLearning, setELearning, uuid }))
  }, [])

  return (
    <StyledContainer>
      <FloatingButtons />

      <LeftPanel />

      <RightPanel />
    </StyledContainer>
  )
}

export default Preview
