import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import Close from '@mui/icons-material/Close'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import {
  closePointDialog,
  setPointDialog,
} from '../../../../../../redux/slices/manage/agentProfile'
import TabMenu from './TabMenu'
import { HeadLabel, Content } from './Styled'
import { DIALOG_TAB } from '../model'
import PointForm from './PointForm'
import RedeemForm from './RedeemForm'
import Confirmation from './Confirmation'
import { Row } from './Styled'
import { LoadingPage } from '../../../../../../modules/AgentProfile/Styled'

const PointDialog = () => {
  const dispatch = useDispatch()
  const { isOpen, tab, pointLoading } = useSelector(
    (state) => ({
      isOpen: state.agentProfile.pointDialog.open,
      tab: state.agentProfile.pointDialog.tab,
      pointLoading: state.agentProfile.pointDialog.loading,
    }),
    shallowEqual,
  )
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          minWidth: 750,
          maxWidth: 750,
        },
      }}
      open={isOpen}
      onClose={() => dispatch(closePointDialog())}
    >
      <Content isLoading={pointLoading}>
        <HeadLabel>
          <Box>
            <Row>
              {tab === DIALOG_TAB.CONFIRMATION && (
                <IconButton
                  data-testid="btn-close-history"
                  color="primary"
                  component="span"
                  onClick={() =>
                    dispatch(
                      setPointDialog({
                        key: 'tab',
                        value: DIALOG_TAB.REDEEM,
                      }),
                    )
                  }
                >
                  <ChevronLeftIcon />
                </IconButton>
              )}
              <Typography variant="h5">
                {tab === DIALOG_TAB.CONFIRMATION
                  ? 'ยืนยันการแลกคะแนน'
                  : 'จัดการคะแนนสะสม'}
              </Typography>
            </Row>
            <IconButton
              data-testid="btn-close-history"
              color="primary"
              component="span"
              onClick={() => dispatch(closePointDialog())}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          {tab !== DIALOG_TAB.CONFIRMATION && (
            <>
              <TabMenu />
              <Divider />
            </>
          )}
        </HeadLabel>
        {tab === DIALOG_TAB.MANAGE && <PointForm />}
        {tab === DIALOG_TAB.REDEEM && <RedeemForm />}
        {tab === DIALOG_TAB.CONFIRMATION && <Confirmation />}
      </Content>
      <LoadingPage isLoading={pointLoading} />
    </Dialog>
  )
}
export default PointDialog
