import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { LoadingPage } from '../../ManageClass/Styled'
import Header from './Header'
import Content from './Content'
import { fetchData } from './events'
import { setClearSearch } from '../../../redux/slices/manage/prospectProfile'

const ProspectProfileForm = ({ type }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const [checkClick, setCheckClick] = useState(false)

  const { isLoading, isLoading2, isLoading3 } = useSelector(
    (state) => ({
      isLoading: state.prospectProfile.isLoading,
      isLoading2: state.prospectProfile.isLoading2,
      isLoading3: state.prospectProfile.isLoading3,
    }),
    shallowEqual
  )
  const { courseSearch, examSearch } = useSelector(
    (state) => state.prospectProfile.search
  )
  useEffect(() => {
    dispatch(setClearSearch())
  }, [])

  useEffect(() => {
    dispatch(fetchData(uuid, courseSearch, examSearch, type))
  }, [])

  const isLoadingAll = isLoading || isLoading2 || isLoading3

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Box
          sx={{
            opacity: isLoadingAll ? 0.4 : 'unset',
            pointerEvents: isLoadingAll ? 'none' : 'unset',
          }}
        >
          <Header
            uuid={uuid}
            type={type}
            setCheckClick={setCheckClick}
          />
          <Content
            type={type}
            checkClick={checkClick}
          />
        </Box>

        <LoadingPage isLoading={isLoadingAll} />
      </Box>
    </>
  )
}

export default ProspectProfileForm
