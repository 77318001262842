export const ROOM_FUNCTION = {
  SINGLE_ROOM: 'Single Room',
  CONNECTING_ROOM: 'Connecting Room',
  OTHER: 'Other',
}

export const ROOM_TYPE = {
  CLASSROOM: 'Classroom',
  COMPUTER_ROOM: 'Computer Room',
  MEETING_ROOM: 'Meeting Room',
  OTHER: 'Other',
}
