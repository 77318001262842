import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenFilterDrawer } from '../../../../redux/slices/monthlyPlan'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { ListCheckbox } from './ListCheckbox'
import { onClearClick, filterDashboard } from './events'

const FilterDrawer = () => {
  const dispatch = useDispatch()
  const { isOpenFilterDrawer } = useSelector(
    (state) => ({
      isOpenFilterDrawer: state.monthlyPlan.dashboard.isOpenFilterDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{ position: 'relative' }}
      open={isOpenFilterDrawer}
      onClose={() => dispatch(setIsOpenFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => onClearClick()}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          variant="contained"
          onClick={() => {
            filterDashboard()
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default FilterDrawer
