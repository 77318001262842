import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Table from '../../../../components/redux/Table'
import HeaderList from './Header'
import { fetchStockListFilter } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from '../Styled'
import FilterDrawer from '../FilterDrawer'
import { breadcrumbList } from '../model'
import { fetchRegionsFilter } from '../FilterDrawer/event'

const Index = () => {
  const dispatch = useDispatch()
  const { table, search, filter } = useSelector((state) => state.table)
  const { initialStockList } = useSelector(
    (state) => ({
      initialStockList: state.stockList.initialStockList,
    }),
    shallowEqual,
  )

  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer, warehouseType } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchStockListFilter({
        method: 'initial',
        table,
        page,
        status,
        warehouseTypeList: warehouseType,
        filterProp,
        searchText,
      }),
    )
    dispatch(fetchRegionsFilter())
  }, [])

  useEffect(() => {
    if (!initialStockList) {
      dispatch(
        fetchStockListFilter({
          method: 'fetch',
          table,
          page,
          statusList: status,
          warehouseTypeList: warehouseType,
          filterProp,
          keyword: searchText,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">คลังทรัพย์สิน</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>
          <HeaderList />
        </StyledHeader>

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
