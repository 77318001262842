import React, { useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { getErrorMessage } from '../../event'
import DatePicker from '../../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../../components/SelectGroupChip'
import History from './History'
import * as Styled from './Styled'
import { mapStatusEva, handleChangeInfo, BoxInfo, mapAssignee } from './event'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const {
    setting,
    dateServer,
    listAssignee,
    isClone,
    disableSetting,
    errorSchemaMessage,
  } = useSelector(
    (state) => ({
      setting: state.eEvaluationForm.setting,
      dateServer: state.eEvaluationForm.dateServer,
      listAssignee: state.eEvaluationForm.listAssignee,
      isClone: state.eEvaluationForm.isClone,
      disableSetting:
        state.eEvaluationForm.disableSetting ||
        state.eEvaluationForm.onViewMode,
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  const [expand, setExpand] = useState({
    courseRef: false,
    history: false,
  })

  const status = _.get(setting, 'status', 'DRAFT')
  const dateErrorMessage = getErrorMessage({
    field: 'DATE',
    errorSchemaMessage,
  })
  return (
    <>
      <Styled.BoxCard sx={{ zIndex: -1 }}>
        <Styled.BoxForm>
          <Styled.BoxFlex>
            <DatePicker
              variant={'body1'}
              id="startDate"
              name="startDate"
              labelText="วันเริ่มต้นใช้งาน"
              placeholder=""
              required
              disabledInput={disableSetting && !isClone ? true : false}
              value={_.get(setting, 'startDate', null)}
              onChange={(e) =>
                dispatch(
                  handleChangeInfo({
                    value: e,
                    key: 'startDate',
                  })
                )
              }
              hideTextError={dateErrorMessage ? false : true}
              textError={dateErrorMessage}
              disabledStartDate={null}
              disabledEndDate={null}
              currentDate={
                uuid && !isClone && status !== 'WAITING' && status !== 'DRAFT'
                  ? _.get(setting, 'defaultDate', null)
                  : dateServer
              }
            />
          </Styled.BoxFlex>
          <Styled.BoxFlex isSwitch>
            <Typography variant="body1">สถานะของแบบประเมิน</Typography>
            <Typography
              variant="body1"
              color={status == 'ACTIVE' ? 'primary' : 'unset'}
            >
              {mapStatusEva(status)}
            </Typography>
          </Styled.BoxFlex>
          <Box>
            <Styled.BoxFlex isSwitch>
              <Typography variant="body1">
                หลักสูตรที่นำไปใช้ ({_.get(setting, 'courseRef', []).length})
              </Typography>
              <IconButton
                data-testid="expand-corseRef"
                onClick={() =>
                  setExpand((prevExpand) => ({
                    ...prevExpand,
                    courseRef: !prevExpand.courseRef,
                  }))
                }
              >
                {expand.courseRef && <ExpandLess color="primary" />}
                {!expand.courseRef && <ExpandMoreOutlined color="primary" />}
              </IconButton>
            </Styled.BoxFlex>
            {expand.courseRef && _.get(setting, 'courseRef', []).length > 0 && (
              <Styled.BoxUl>
                {_.get(setting, 'courseRef', []).map((item) => {
                  return (
                    <>
                      <li>
                        <Styled.BoxLi>
                          <Typography variant="body1">
                            {item.courseName}{' '}
                            <Typography variant="span" color="primary">
                              {item.nameForLearner}
                            </Typography>{' '}
                            เวอร์ชั่น {item.version}
                          </Typography>
                          <Typography variant="body1" color="text.silver">
                            {item.isOnTesting && '(On testing)'}
                          </Typography>
                        </Styled.BoxLi>
                      </li>
                    </>
                  )
                })}
              </Styled.BoxUl>
            )}

            <Divider sx={{ mt: 2 }} />
          </Box>
          <Styled.BoxFlex>
            <SelectGroupChip
              variant={'body1'}
              id="assignee"
              name="assignee"
              labelText="ผู้ร่วมจัดทำ"
              placeholder=""
              options={listAssignee}
              showTextError={false}
              isMenuPosition={true}
              value={mapAssignee(_.get(setting, 'assignees', []))}
              disabled={disableSetting}
              onChange={(list) =>
                dispatch(
                  handleChangeInfo({
                    value: list,
                    key: 'assignees',
                  })
                )
              }
            />
          </Styled.BoxFlex>

          <Styled.BoxFlex>
            <BoxInfo
              labelText="วันที่สร้าง"
              labelValue={_.get(setting, 'createdAt', '-')}
            />
            <BoxInfo
              labelText="สร้างโดย"
              labelValue={_.get(setting, 'createdBy.name', '-')}
            />
          </Styled.BoxFlex>
          <Styled.BoxFlex>
            <BoxInfo
              labelText="วันที่แก้ไขล่าสุด"
              labelValue={_.get(setting, 'updatedAt', '-')}
            />
            <BoxInfo
              labelText="แก้ไขล่าสุดโดย"
              labelValue={_.get(setting, 'updatedBy.name', '-')}
              // isIcon={uuid && !isClone ? true : false}
              isIcon={false}
              onChangeHistory={() =>
                setExpand((prevExpand) => ({
                  ...prevExpand,
                  history: !prevExpand.history,
                }))
              }
            />
          </Styled.BoxFlex>
        </Styled.BoxForm>
      </Styled.BoxCard>

      <History
        isOpen={expand.history}
        onCloseDrawer={() =>
          setExpand((prevExpand) => ({
            ...prevExpand,
            history: !prevExpand.history,
          }))
        }
      />
    </>
  )
}

export default Index
