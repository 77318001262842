import { path } from '../../../../../../constants/path'

export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'dateRange',
  },
  {
    label: 'รอบอบรม',
    nameCheckBox: 'courseBatchChecked',
    nameInput: 'courseBatch',
    type: 'multiDropdown',
  },
]

export const headCellsModule = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '30px',
    align: 'center',
    disablePadding: false,
  },
  {
    id: 'moduleCode',
    label: 'รหัสโมดูล',
    disablePadding: false,
  },
  {
    id: 'moduleName',
    label: 'ชื่อโมดูล',
    disablePadding: false,
  },
  {
    id: 'moduleType',
    label: 'รูปแบบโมดูล',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eLearningModule}/view`,
  },
]

export const headCellsLearner = [
  {
    id: 'codeId',
    label: 'รหัสผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'nameTH',
    label: 'ชื่อผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'userType',
    label: 'ประเภท',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'userLevel',
    label: 'ระดับผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'email',
    label: 'E-MAIL',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'tel',
    label: 'เบอร์โทร',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'learnerStatus',
    label: 'สถานะการเรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'completedAt',
    label: 'วันที่จบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    minWidth: '30px',
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
  },
]
