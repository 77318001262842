import { createExpense, updateExpense } from '../../../services/expense'
import { loadingDialog, stopLoadingDialog } from '../../../redux/slices/dialog'
import { store } from '../../../App'
import { handleSuccessfulSubmission } from './handleSuccessfulSubmission'
import { handleFormSubmissionError } from '../../../components/CRUD/handler/handleProcessSubmitForm'
import { postBodyMutation } from '../mutation/post'
import _ from 'lodash'

export const handleProcessSubmitForm = async () => {
  store.dispatch(loadingDialog())

  const { formData, monthlyPlan } = store.getState().crud
  try {
    let response
    const body = postBodyMutation(formData)

    const uuid = _.get(formData, 'uuid', '')
    if (uuid) {
      response = await store.dispatch(
        updateExpense({
          ...body,
          uuid,
          monthlyPlanId: monthlyPlan.id,
          monthlyPlan: monthlyPlan.uuid,
        }),
      )
    } else {
      response = await store.dispatch(
        createExpense({
          ...body,
          monthlyPlanId: monthlyPlan.id,
          monthlyPlan: monthlyPlan.uuid,
        }),
      )
    }

    if (_.get(response, 'meta.requestStatus', 'rejected') === 'fulfilled') {
      handleSuccessfulSubmission()
    }
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    store.dispatch(stopLoadingDialog())
  }
}
