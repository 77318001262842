import _ from 'lodash'
import { store } from '../../../../../App'
import { statusValueEnum } from '../enum/statusEnum'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleCancelNotReturn = (itemIndex) => {
  const { formData } = store.getState().crud
  const asset = _.get(formData, `assets[${itemIndex}]`, null)
  store.dispatch(
    setFieldValue({
      key: 'isOpenFormDrawer',
      value: false,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: `formData.assets[${itemIndex}]`,
      value: {
        ...asset,
        withdrawStatus: statusValueEnum.WAITING_RETURN,
        returnCheckbox: true,
        remark: '',
        img: '',
      },
    }),
  )
}
