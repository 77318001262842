import Select from '../../../../../../components/Input/Select'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  bookingItemSelector,
  roomSelector,
  setRoom,
  setRoomBookingListByDate,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import { handleDisableRoom } from '../event'

export const MeetingRoomSelect = ({ bookingDate, roomIndex, isOnline }) => {
  const dispatch = useDispatch()
  const meetingRoomUuid = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoomUuid',
    }),
    shallowEqual,
  )

  const isLoadingMasterRooms = useSelector(
    bookingItemSelector({
      bookingDate,
      key: 'isLoadingMasterRooms',
    }),
    shallowEqual,
  )

  const masterRooms = useSelector(
    bookingItemSelector({
      bookingDate,
      key: 'masterRooms',
    }),
    shallowEqual,
  )

  return (
    <Select
      boxSx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}
      labelSx={{
        textWrap: 'nowrap',
        fontWeight: 700,
        pl: 2,
      }}
      textErrorSx={{ mb: 0 }}
      selectSx={{ width: '100%' }}
      dataTestid="select-meetingRoom"
      id="meetingRoomUuid"
      name="meetingRoomUuid"
      required
      labelText={`ห้องที่ ${roomIndex + 1}`}
      placeholder="กรุณาเลือกห้อง"
      disabled={isLoadingMasterRooms || !masterRooms.length || isOnline}
      value={meetingRoomUuid}
      options={masterRooms}
      isSelected={['online']}
      handleChange={(e) => {
        const value = _.get(e, 'target.value', '')
        dispatch(
          setRoom({
            key: 'meetingRoomUuid',
            value,
            bookingDate,
            roomIndex: roomIndex,
          }),
        )
        dispatch(
          setRoomBookingListByDate({
            bookingDate: bookingDate,
            key: 'masterRooms',
            value: handleDisableRoom(masterRooms, value, meetingRoomUuid),
          }),
        )
      }}
    />
  )
}
