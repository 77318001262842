import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { getDetail } from './../../../services/eTesting/detail'
import { filterCourseRef } from '../../../modules/ETesting/Form/event'
export const initialState = {
  isLoading: false,
  result: null,
  error: null,
}

const eTestingDetail = createSlice({
  name: 'eTestingDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        state.result = {
          ...payload.data,
          setting: {
            ...payload.data.setting,
            courseRef: filterCourseRef(
              _.get(payload, 'data.setting.courseRef', [])
            ),
          },
        }
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading } = eTestingDetail.actions

export default eTestingDetail.reducer
