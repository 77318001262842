import React, { useState } from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import History from '@mui/icons-material/History'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { StyledColumnFlex, StyledRowFlex } from '../Styled'
import HistoryDrawer from './History'
import { StyledFooterBox } from './Styled'

const FooterContent = ({ data }) => {
  const [isOpen, setOpen] = useState(false)
  const createdDate = _.get(data, 'createdAt', '')
  const createdAt =
    createdDate != ''
      ? convertFormatDateTime({
          value: createdDate,
          type: 'date',
        })
      : '-'
  const updatedDate = _.get(data, 'updatedAt', '')
  const isFirst = updatedDate == ''
  const updatedAt =
    updatedDate != ''
      ? convertFormatDateTime({
          value: updatedDate,
          type: 'date',
        })
      : '-'
  const updatedBy = _.get(data, 'updatedBy', '')

  return (
    <StyledFooterBox>
      <StyledColumnFlex>
        <StyledRowFlex sx={{ gap: 1 }}>
          <Typography width={150}>วันที่สร้าง</Typography>
          <Typography color="text.lightGray">{createdAt}</Typography>
        </StyledRowFlex>
        <StyledRowFlex sx={{ gap: 1 }}>
          <Typography width={150}>วันที่แก้ไขล่าสุด</Typography>
          <Typography color="text.lightGray">{updatedAt}</Typography>
        </StyledRowFlex>
      </StyledColumnFlex>
      <StyledColumnFlex>
        <StyledRowFlex sx={{ gap: 1 }}>
          <Typography width={150}>สร้างโดย</Typography>
          <Typography color="text.lightGray">
            {_.get(data, 'createdBy', '')}
          </Typography>
        </StyledRowFlex>
        <StyledRowFlex sx={{ gap: 1 }}>
          <Typography width={150}>แก้ไขล่าสุดโดย</Typography>
          <Typography color="text.lightGray">
            {updatedBy != '' ? updatedBy : '-'}
          </Typography>
        </StyledRowFlex>
      </StyledColumnFlex>
      {!isFirst && (
        <IconButton
          data-testId="btn-history"
          sx={{ position: 'absolute', right: 24, bottom: 0 }}
          onClick={() => setOpen(true)}
        >
          <History />
        </IconButton>
      )}
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </StyledFooterBox>
  )
}

export default FooterContent
