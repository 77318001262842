import { fetchManageClassDashboard } from './fetchManageClassDashboard'
import { fetchActivity } from './fetchActivity'

export const fetchOverviewClass = async () => {
  const updatedClassDashboard = await fetchManageClassDashboard()
  const activities = await fetchActivity()

  return {
    ...updatedClassDashboard,
    ...activities,
  }
}
