import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Preview from '../../../../../../modules/ETesting/Preview'
import Testing from '../../../../../../modules/ETesting/Preview/Testing'
import { MediaBoxStyled } from '../Styled'
import { handleFetchETesting } from './events'

const ETesting = ({ selectedLesson }) => {
  const [eTesting, setETesting] = useLocalStorage('eTesting')
  const dispatch = useDispatch()
  const uuid = _.get(selectedLesson, 'eTestingUuid', '')
  const isInProgress = _.get(selectedLesson, 'isInProgress', false)
  const isLessonLoading = useSelector(
    (state) => state.eLearningPreview.isLessonLoading,
    shallowEqual,
  )

  useEffect(() => {
    dispatch(handleFetchETesting(uuid, setETesting))
  }, [uuid])

  if (!eTesting || isLessonLoading) return <MediaBoxStyled />

  if (isInProgress) return <Testing isModule />

  return <Preview isModule />
}

export default ETesting
