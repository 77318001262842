import { CRUDListFilterPath } from '../../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'STOCK_CATEGORY',
  moduleSlug: 'stock-category',
  moduleTitleFull: 'หมวดหมู่หลัก Inventory',
  modulesTitleShort: 'Category',
  modulePath: '/master-data/inventory/category',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
