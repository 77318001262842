import React from 'react'
import _ from 'lodash'

import dayjs from 'dayjs'
import Box from '@mui/material/Box'

import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'

export const defaultForm = {
  work: {},
}

export const onChangeForm = ({
  event,
  key,
  isDate,
  setStateForm,
  setValidateForm,
}) => {
  let value = null
  if (isDate) {
    value = dayjs(event).format(window.__env__.REACT_APP_DATE_DB)
  } else {
    value = event.target.value || ''
  }

  let objValidate = { [key]: '' }
  if (key === 'startDate' || key === 'finishDate')
    objValidate = {
      startDate: '',
      finishDate: '',
    }

  setStateForm((prevState) => ({
    ...prevState,
    holiday: {
      ...prevState.holiday,
      [key]: value,
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    holiday: {
      ...prevState.holiday,
      ...objValidate,
    },
  }))
}
export default function Holiday({
  type,
  optionsWork,
  validateForm,
  setValidateForm,
  stateForm,
  setStateForm,
  optionSubWorkType,
  state,
}) {
  const workType =
    _.get(stateForm, 'holiday.workType.workType', '') === ''
      ? _.get(stateForm, 'holiday.workType', '')
      : _.get(stateForm, 'holiday.workType.workType', '')

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Select
            id="work"
            name="work"
            labelText="ประเภทตารางงาน"
            type="basic"
            textError={''}
            required
            disabled={true}
            options={optionsWork}
            placeholder="กรุณาเลือก"
            value={workType}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Select
            dataTestid="select-holidayType"
            id="holidayType"
            name="holidayType"
            labelText="ประเภทวันหยุด"
            type="basic"
            textError={_.get(validateForm, 'holiday.holidayType', '')}
            required
            options={optionSubWorkType}
            placeholder="กรุณาเลือก"
            handleChange={(event) =>
              onChangeForm({
                event,
                key: 'holidayType',
                setStateForm,
                setValidateForm,
              })
            }
            value={_.get(stateForm, 'holiday.holidayType', '')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            isShouldDisableDate={true}
            onChange={(event) =>
              onChangeForm({
                event,
                key: 'startDate',
                isDate: true,
                setStateForm,
                setValidateForm,
              })
            }
            value={
              _.get(stateForm, 'holiday.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.startDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'holiday.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'holiday.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.finishDate', null)
            }
            textError={_.get(validateForm, 'holiday.startDate', '')}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <DatePicker
            id="finishDate"
            name="finishDate"
            labelText="ถึงวันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            isShouldDisableDate={true}
            onChange={(event) =>
              onChangeForm({
                event,
                key: 'finishDate',
                isDate: true,
                setStateForm,
                setValidateForm,
              })
            }
            value={
              _.get(stateForm, 'holiday.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.finishDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'holiday.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'holiday.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'holiday.finishDate', null)
            }
            textError={_.get(validateForm, 'holiday.finishDate', '')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <TextInput
            id="dayOff"
            name="dayOff"
            labelText="วันหยุด"
            type="basic"
            required
            textError={_.get(validateForm, 'holiday.dayOff', '')}
            value={_.get(stateForm, 'holiday.dayOff', '')}
            onChange={(event) =>
              onChangeForm({
                event,
                key: 'dayOff',
                setStateForm,
                setValidateForm,
              })
            }
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <TextInput
            id="remark"
            name="remark"
            labelText="หมายเหตุ"
            type="basic"
            textError={_.get(validateForm, 'holiday.remark', '')}
            onChange={(event) =>
              onChangeForm({
                event,
                key: 'remark',
                setStateForm,
                setValidateForm,
              })
            }
            value={_.get(stateForm, 'holiday.remark', '')}
            multiline={true}
            rows={2}
          />
        </Box>
      </Box>
    </>
  )
}
