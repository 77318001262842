import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import { handleOpenDrawer } from './handleOpenDrawer'

export const handleEditExpense = (idx) => {
  const { expenseList } = store.getState().crud.formData

  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: { ...expenseList[idx], expenseList: expenseList },
    }),
  )
  handleOpenDrawer()
}
