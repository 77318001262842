import { Box } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))

export const StyledRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
