import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  manageClass,
  manageClassAdminStaff,
  manageClassExternalSpeaker,
  manageClassHistory,
  manageClassLearner,
  manageClassLearnerAgentCheck,
  manageClassLearnerDelete,
  manageClassLearnerDownloadTemplate,
  manageClassLearnerUpload,
  manageClassMeetingRoom,
  manageClassOicCreate,
  manageClassOicDelete,
  manageClassUserAgent,
  manageClassUserAgentDetail,
  manageClassUserProfile,
  meetingRoom,
  monthlyPlanHoliday,
  responsibilityFilter,
  roomManagementAvailable,
} from '../../utils/apiPath'

export const getManageClassByUuid = createAsyncThunk(
  'manageClassForm/getManageClassByUuid',
  async (uuid, { rejectWithValue }) => {
    try {
      return await axios.get(`${manageClass}/${uuid}`)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getMeetingRoomById = createAsyncThunk(
  'manageClassForm/getMeetingRoomById',
  async (uuid, { rejectWithValue }) => {
    try {
      return await axios.get(`${meetingRoom}/${uuid}`)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getManageClassRoomAvailable = createAsyncThunk(
  'manageClassForm/getManageClassRoomAvailable',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(roomManagementAvailable, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getManageClassHolidayByUuid = createAsyncThunk(
  'manageClassForm/getManageClassHolidayByUuid',
  async (uuid, { rejectWithValue }) => {
    try {
      return await axios.get(`${monthlyPlanHoliday}/${uuid}`)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAdminStaff = createAsyncThunk(
  'manageClassForm/getAdminStaff',
  async (props, { rejectWithValue }) => {
    try {
      const station = _.get(props, 'station', '')
      const hasPermissionEditAll = _.get(props, 'hasPermissionEditAll', false)
      const response = await axios.get(manageClassAdminStaff)
      return { response, station, hasPermissionEditAll }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getMeetingRoom = createAsyncThunk(
  'manageClassForm/getMeetingRoom',
  async (station, { rejectWithValue }) => {
    try {
      const body = {
        stationUuid: [],
        roomName: '',
        roomFunction: [],
        roomType: [],
        roomLayout: [],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      const response = await axios.post(manageClassMeetingRoom, body)
      return { response, station }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExternalSpeaker = createAsyncThunk(
  'manageClassForm/getExternalSpeaker',
  async (_, { rejectWithValue }) => {
    try {
      return await axios.get(manageClassExternalSpeaker)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getResponsibility = createAsyncThunk(
  'manageClassForm/getResponsibility',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        responsibility: '',
        limit: 100,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      return await axios.post(responsibilityFilter, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageClass = createAsyncThunk(
  'manageClassForm/putManageClass',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.put(manageClass, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getHistory = createAsyncThunk(
  'manageClassForm/history',
  async (url, { rejectWithValue }) => {
    try {
      return await axios.get(`${manageClassHistory}${url}`)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getUserProfile = createAsyncThunk(
  'manageClassLearner/getUserProfile',
  async (userType, { rejectWithValue }) => {
    try {
      const body = {
        userType,
        username: '',
        email: '',
        userCode: '',
        prefix: '',
        firstName: '',
        lastName: '',
        mobileNo: '',
        unitNumber: '',
        createdBy: '',
        updatedBy: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: userType === 'STAFF' ? ['ACTIVE'] : [],
      }

      if (userType === 'NON_ACADEMY') {
        body['userType'] = 'STAFF'
        body['staffType'] = 'NON_ACADEMY'
        body['status'] = ['ACTIVE']
      }

      return await axios.post(manageClassUserProfile, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getUserAgent = createAsyncThunk(
  'manageClassLearner/getUserAgent',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassUserAgent, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getUserAgentDetail = createAsyncThunk(
  'manageClassLearner/getUserAgentDetail',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassUserAgentDetail, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postAddLearner = createAsyncThunk(
  'manageClassLearner/postAddLearner',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassLearner, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postAgentCheckLearner = createAsyncThunk(
  'manageClassLearner/postAgentCheckLearner',
  async (formData, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassLearnerAgentCheck, formData)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadLearner = createAsyncThunk(
  'manageClassLearner/postUploadLearner',
  async (formData, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassLearnerUpload, formData)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteLearner = createAsyncThunk(
  'manageClassLearner/deleteLearner',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body } = payload
      return await axios.delete(`${manageClassLearnerDelete}/${uuid}`, {
        data: body,
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadTemplate = createAsyncThunk(
  'manageClassLearner/downloadTemplate',
  async (_, { rejectWithValue }) => {
    try {
      return await axios
        .get(manageClassLearnerDownloadTemplate, { responseType: 'blob' })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Template_Learner.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          rejectWithValue([], err)
        })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateOicExam = createAsyncThunk(
  'manageClass/postCreateOicExam',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(manageClassOicCreate, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postDeletedOicExam = createAsyncThunk(
  'manageClass/postDeletedOicExam',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.delete(manageClassOicDelete, { data: body })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
