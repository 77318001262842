import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import { StyledHeadLabel } from './Styled'
import { openAssetDetailDrawer } from '../../handler/openHistoryDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import { ActionButtons } from './components/ActionButtons'
import _ from 'lodash'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'
import SkuDetail from './components/SKU'
import AssetDetail from './components/Asset'
import LicenseDetail from './components/License'
import { setIsShowEditForm } from '../../handler/handleClickEditAsset'
import { handleSubmitEditAsset } from '../../handler/handleSubmitEditAsset'

const index = ({ open }) => {
  const { assetDetailDrawer, isShowEditForm } = useSelector(
    (state) => ({
      assetDetailDrawer: state.crud.assetDetailDrawer,
      isShowEditForm: state.crud.isShowEditForm,
    }),
    shallowEqual,
  )

  let productType = _.get(assetDetailDrawer, 'product.productType', '')
  if (assetDetailDrawer?.productWarehouse) {
    productType = _.get(
      assetDetailDrawer,
      'productWarehouse.product.productType',
      '',
    )
  }
  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={open}
        onClose={() => openAssetDetailDrawer(false)}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: '100%' },
            maxWidth: '100%',
            minWidth: '60vw',
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">
                {isShowEditForm ? 'แก้ไขข้อมูล' : 'รายละเอียด'} รหัสทรัพย์สิน
              </Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openAssetDetailDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3, mb: 2 }}>
            <ActionButtons />
            {productType === PRODUCT_TYPE.STOCK_ASSET && (
              <SkuDetail item={assetDetailDrawer} productType={productType} />
            )}
            {productType === PRODUCT_TYPE.ASSET && (
              <AssetDetail item={assetDetailDrawer} productType={productType} />
            )}
            {productType === PRODUCT_TYPE.LICENSE && (
              <LicenseDetail item={assetDetailDrawer} productType={productType}  />
            )}

            {isShowEditForm && (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  gap={2}
                >
                  <Button
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={() => setIsShowEditForm(false)}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleSubmitEditAsset(productType)}
                    data-testid="btn-edit"
                  >
                    บันทึก
                  </Button>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default index
