import { path } from '../../../constants/path'

export const headCells = [
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'productType',
    label: 'ประเภททรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'skuNo',
    label: 'SKU/ID',
    disablePadding: false,
  },
  {
    id: 'warehouseName',
    label: 'คลังสินค้าปัจจุบัน',
    disablePadding: false,
  },
  {
    id: 'updatedAt',
    label: 'วันที่รับอุปกรณ์',
    disablePadding: false,
  },
  {
    id: 'actionSku',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '70px',
    minWidth: '70px',
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
    viewPath: `${path.inventory}/product`,
  },
]
