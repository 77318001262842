export const headCells = [
  {
    id: 'createdAt',
    label: 'วันเวลา',
    isCustom: true,
    minWidth: '200px',
  },
  {
    id: 'movementType',
    label: 'รายการ',
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
  },  
  {
    id: 'stockValue',
    label: 'มูลค่ารวม',
  },
  {
    id: 'total',
    label: 'คงเหลือ',
  },
  {
    id: 'form.warehouseName',
    label: 'จาก',
  },
  {
    id: 'to.warehouseName',
    label: 'ไป',
  },
  {
    id: 'ticket.id',
    label: 'Ticket ID',
  },
  {
    id: 'dropdownAction',
    label: 'คำสั่ง',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
  },
]