import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledDialog,
  StyledInputImport,
  StyledFormContainer,
} from '../../Styled'
import Button from '@mui/material/Button'
import _ from 'lodash'
import { closeDialog } from '../../../../../redux/slices/dialog'
import {
  StyledDialogActions,
  StyledCancelButton,
  StyledLoadingButton,
} from '../../../../../components/redux/PopUpDialog/Styled'
import { onDownloadTemplate } from '../../handler/onDownloadTemplate'
import { onUploadTemplate } from '../../handler/onUploadTemplate'
import { handleSubmitUpload } from '../../handler/handleSubmitUpload'
import { handleClearFileSelected } from '../../handler/handleClearFileSelected'

const ImportTemplateDialog = () => {
  const dispatch = useDispatch()
  const { importFile, isLoading, isLoadingUploadBtn, disableButton } =
    useSelector(
      (state) => ({
        importFile: state.crud.formData.importFile,
        isLoading: state.dialog.isLoading,
        isLoadingUploadBtn: state.crud.isLoadingUploadBtn,
        disableButton: state.dialog.disableButton,
      }),
      shallowEqual,
    )

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">นำเข้า TEMPLATE</Typography>
      </Box>
      <StyledDialog sx={{ p: 0 }}>
        <StyledFormContainer>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              data-testid="btn-download-template"
              variant="contained"
              size="s"
              onClick={() => {
                onDownloadTemplate()
              }}
            >
              ดาวน์โหลด Template
            </Button>
            <Typography variant="body2" color="text.secondary">
              เพิ่มขนาดไฟล์ที่แนะนำไม่เกิน 2 MB
            </Typography>
          </Box>
        </StyledFormContainer>
        <Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            อัปโหลด
          </Typography>
          <label
            style={{ display: 'flex', gap: 16, alignItems: 'center' }}
            htmlFor="import-template"
          >
            <Button
              variant="contained"
              size="s"
              component="span"
              disabled={isLoadingUploadBtn}
            >
              เลือกไฟล์
            </Button>
            <StyledInputImport
              data-testid="import-template"
              id="import-template"
              accept=".xlsx, .xls, .csv"
              type="file"
              onChange={(e) => onUploadTemplate(e)}
            />
            <Typography variant="body2" color="text.secondary">
              {_.get(importFile, 'fileName')}
            </Typography>
          </label>
        </Box>
        <StyledDialogActions sx={{ p: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => {
                dispatch(closeDialog())
                handleClearFileSelected()
              }}
              color="primary"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() => handleSubmitUpload()}
            color="primary"
            disabled={disableButton}
            endIcon={<></>}
          >
            บันทึก
          </StyledLoadingButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}
export default ImportTemplateDialog
