import { TicketStatus } from '../../../../../constants/stock'
import { crudConfig } from '../../crudConfig'
import _ from 'lodash'
import { transferByEnum } from '../enum/transferTypeEnum'
import callAxios from '../../../../../utils/baseService'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const countOtherWaitingApprove = async () => {
  const body = {
    limit: 10,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    quickSearch: '',
    ...mutateFilterPropToBody(),
    createdBy: '',
    updatedBy: '',
    search: '',
    status: [TicketStatus.WAITING_APPROVE],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    transferType: ['TRANSFER'],
    transferBy: [transferByEnum.TRANSFER_OTHER],
  }
  await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': crudConfig.moduleId })
    .then((res) => {
      const requestCount = _.get(res, 'data.requestCount', 0)
      store.dispatch(
        setFieldValue({
          key: 'requestCount',
          value: requestCount,
        }),
      )
    })
}
