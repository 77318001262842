import _ from 'lodash'
import {
  closeDialog,
  openDialog,
  loadingDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import { setOpenDrawer } from '../../../../redux/slices/eLearning/question'
import { putAnswerQuestion } from '../../../../services/eLearning/question/detail'
import { store } from '../../../../App'
import { fetchDataList } from './../events'

export const handleInitialData = (detailQuestion) => {
  return {
    question: _.get(detailQuestion, 'question', ''),
    questionBy:
      _.get(detailQuestion, 'questionBy.userProfile.firstNameTH', '-') +
        ' ' +
        _.get(detailQuestion, 'questionBy.userProfile.lastNameTH', '-') || '-',
    uuid: _.get(detailQuestion, 'uuid', ''),
    answer: _.get(detailQuestion, 'answer', ''),
    status: _.get(detailQuestion, 'status', ''),
  }
}

export const handleSubmit = (values) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึก',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirmSubmit(values)),
    }),
  )
}

export const handleConfirmSubmit = (values) => async (dispatch) => {
  const body = {
    uuid: values.uuid || undefined,
    answer: _.get(values, 'answer', ''),
  }

  dispatch(handleCloseDrawer())
  dispatch(loadingDialog())
  await dispatch(putAnswerQuestion(body))
  dispatch(
    fetchDataList({
      method: 'initial',
      page: 1,
    }),
  )
  // window.location.reload()
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}

export const handleCloseDrawer = () => (dispatch) => {
  dispatch(setOpenDrawer(false))
}

export const handleGetAssignees = () => {
  const isOnEditPage = window.location.pathname.includes('edit')
  const {
    eLearningCourseBatchDetail: { result },
    eLearningCourseForm: { body },
  } = store.getState()

  if (isOnEditPage) return _.get(body, 'setting.assignees', [])
  return _.get(result, 'eLearningCourse.setting.assignees', [])
}
