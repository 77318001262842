import Dot from '../../Table/Dot'
import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import { getStatusText } from '../../../utils/lib'

export function StatusComponent(formData) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Dot sx={{ mt: 1.5, mr: 1.5 }} status={formData.status} />
      <Typography variant="body1">{getStatusText(formData.status)}</Typography>
    </Box>
  )
}
