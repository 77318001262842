import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import TextInput from '../../../../../../components/Input/TextInput'
import Select from '../../../../../../components/Input/Select'

import { Column, Row, StyledCard, StyledSwitchButton } from './Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'

import { handleSettingRadioChange } from './events'

const Ponit = () => {
  const dispatch = useDispatch()
  const {
    learningPointList,
    pointStatus,
    pointType,
    point,
    learningPointUuid,
    onGoing,
  } = useSelector(
    (state) => ({
      learningPointList: state.eLearningCourseForm.learningPointList,
      pointStatus: getStateValue('pointStatus'),
      pointType: getStateValue('pointType'),
      point: getStateValue('point'),
      learningPointUuid: getStateValue('learningPointUuid'),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledCard>
      <Typography variant="h6">คะแนนหลักสูตร</Typography>
      <StyledSwitchButton>
        <Switch
          checked={pointStatus === 'ACTIVE'}
          onChange={(e) =>
            dispatch(
              handleChange(
                'pointStatus',
                _.get(e, 'target.checked', false) ? 'ACTIVE' : 'INACTIVE',
              ),
            )
          }
          disabled={onGoing}
        />
        <Box>
          <Typography>เปิดการได้รับคะแนน</Typography>
        </Box>
      </StyledSwitchButton>
      <Column>
        <Row>
          <Typography variant="body2" color={'text.secondary'}>
            กำหนดรูปแบบคะแนน
          </Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        </Row>
        <RadioGroup
          sx={{ pl: 1.5, gap: 0.5, mb: 1 }}
          name="pointType"
          value={pointType}
          onChange={(e) => dispatch(handleSettingRadioChange(e))}
        >
          <FormControlLabel
            value="CUSTOM"
            control={<Radio />}
            label="กำหนดเอง"
            disabled={onGoing}
          />
          <FormControlLabel
            value="LINK"
            control={<Radio />}
            label="ผูกคะแนน"
            disabled={onGoing}
          />
        </RadioGroup>
        {pointType == 'CUSTOM' ? (
          <TextInput
            id="point"
            name="point"
            labelText="จำนวนคะแนน"
            type="basic"
            textError={_.get(errors, 'point', '')}
            isShowTextError={_.get(errors, 'point', '')}
            required
            onChange={(e) =>
              dispatch(
                handleChange('point', e.target.value.replace(/[^0-9]/g, '')),
              )
            }
            value={point}
            maxlength={4}
            disabled={onGoing}
          />
        ) : (
          <Select
            id="learningPointUuid"
            name="learningPointUuid"
            labelText="เลือกชื่อคะแนน"
            type="basic"
            textError={_.get(errors, 'learningPointUuid', '')}
            showTextError={_.get(errors, 'learningPointUuid', '')}
            required
            options={learningPointList}
            handleChange={(e) => dispatch(handleChangeEvent(e))}
            value={learningPointUuid}
            placeholder="กรุณาเลือก"
            disabled={onGoing}
          />
        )}
      </Column>
    </StyledCard>
  )
}

export default Ponit
