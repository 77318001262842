import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import { onFilterClick } from '../event'

export const handleFiltering =
  (table, filter, id, permission) => (dispatch) => {
    dispatch(setToggleFilterDrawer(false))
    dispatch(onFilterClick(table, filter, id, permission))
  }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectEffectiveDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    effectiveDateStartDate: selectedDates.startDate,
    effectiveDateFinishDate: selectedDates.endDate,
    displayEffectiveDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleSelectExpiryDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    expiryDateStartDate: selectedDates.startDate,
    expiryDateFinishDate: selectedDates.endDate,
    displayExpiryDate: selectedDates.display,
  }
  setFilter(objState)
}
