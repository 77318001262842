import React from 'react'
import _ from 'lodash'
import { DialogContentDelete } from '.'
import {
  deleteVersion,
  downloadResult,
} from '../../../../../services/eTesting/form'
import {
  startLoading,
  stopLoading,
  setChange,
} from '../../../../../redux/slices/eTestingForm'
import {
  openDialog,
  setDisableButton,
  setEnableButton,
  loadingDialog,
  closeDialog,
} from '../../../../../redux/slices/dialog'
import { validateEPermission } from '../../../../../utils/lib'

import { eTestingVersion } from '../../../../../utils/apiPath'
import {
  defaultValue,
  questionTypeString,
} from '../../../../../constants/eTesting'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
    return
  }
  setOpen(false)
}

export const handleMoreMenu =
  (method, uuid, history, eTestingType) => (dispatch) => {
    switch (method) {
      case 'clone':
        dispatch(
          setChange({
            key: 'defaultTap',
            value: 'testing',
          })
        )
        history.push(`${eTestingVersion}/clone/${uuid}`)
        break
      case 'delete':
        dispatch(handleDeleteVersion(uuid, eTestingType))
        break
      case 'download':
        dispatch(downloadResult(uuid))
        break
      case 'check':
        history.push(`/e-testing/check-exam/${uuid}`)
        break
      default:
        break
    }
  }

export const handleDeleteVersion = (uuid, type) => (dispatch) => {
  if (type === 'STANDALONE') {
    dispatch(
      openDialog({
        title: 'ยืนยันลบข้อมูล',
        message: 'คุณต้องการลบข้อมูลใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        handleConfirm: () => dispatch(submitDeleteVersion(uuid)),
      })
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันลบข้อมูล',
        type: 'content',
        content: <DialogContentDelete />,
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        disableButton: true,
        handleConfirm: () => dispatch(submitDeleteVersion(uuid)),
      })
    )
  }
}

export const handleToggleDelete = (event, setToggleDelete) => (dispatch) => {
  setToggleDelete((prevToggle) => !prevToggle)
  const checked = event.target.checked
  !checked ? dispatch(setDisableButton()) : dispatch(setEnableButton())
}

export const submitDeleteVersion = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())

  const result = await dispatch(deleteVersion(uuid))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  dispatch(stopLoading())
  dispatch(closeDialog())
  if (payload === 'fulfilled')
    window.location.href = `${eTestingVersion}/${_.get(
      result,
      'payload.data.codeId',
      ''
    )}`
}

export const getMoreOptionsByPermission = (
  setting,
  questions,
  disabled,
  type
) => {
  let moreOptions = [
    {
      text: 'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
      method: 'clone',
    },
    {
      text: 'นำเข้า Template',
      method: 'import',
    },
    {
      text: 'ตรวจข้อสอบ',
      method: 'check',
    },
    {
      text: 'ดาวน์โหลดผลสอบ',
      method: 'download',
    },
    {
      text: 'ลบ',
      method: 'delete',
    },
  ]

  const getPermissionDelete = validateEPermission({
    module: 'E_TESTING_SETTING',
    permission: 'DELETE',
    data: setting,
  })
  if (disabled) {
    moreOptions = moreOptions.filter((item) => item.method != 'import')
  }

  if (type !== defaultValue.typeOfETesting.standalone) {
    moreOptions = moreOptions.filter(
      (item) => item.method !== 'check' && item.method !== 'download'
    )
  }
  const haveFreeText = questions.some(
    (question) => question.questionType === questionTypeString.FREE_TEXT
  )
  if (!haveFreeText) {
    moreOptions = moreOptions.filter((item) => item.method !== 'check')
  }
  if (getPermissionDelete) return moreOptions
  return moreOptions.filter((item) => item.method != 'delete')
}
