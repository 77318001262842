import _ from 'lodash'
import {
  setHandleChange,
  startLoading,
  stopLoading,
} from '../../redux/slices/report'
import { getCourseList, getELearningList } from '../../services/report'
import { reportRawData } from '../../utils/apiPath'
import { downloadFile } from '../../services/util'

export const handleChange = (props) => (dispatch) => {
  dispatch(setHandleChange(props))
}

export const fetchManageClassCourseList = (type) => async (dispatch) => {
  dispatch(startLoading())
  if (type === 'COURSE') {
    await dispatch(getCourseList())
  } else {
    await dispatch(getELearningList())
  }
  dispatch(stopLoading())
}

export const findCourseCode = (courseList, courseCode) => {
  if (_.isEmpty(courseCode) || _.isEmpty(courseList)) return null
  if (courseCode === 'ALL') return { label: 'ALL', value: 'ALL' }
  return courseList.find((item) => item.value === courseCode)
}

export const fetchManageClassRawData = (manageReport) => async (dispatch) => {
  dispatch(startLoading())
  let courseCode = [manageReport.courseCode]
  if (courseCode.includes('ALL')) {
    courseCode = _.get(manageReport, 'courseList', []).map((c) => c.value)
  }
  const body = {
    startDate: manageReport.startDate,
    finishDate: manageReport.finishDate,
    courseType: manageReport.courseType,
    courseCode: courseCode,
  }
  const fileName =
    manageReport.courseType === 'COURSE' ? 'Manage Class' : 'E-Learning'
  await dispatch(
    downloadFile({
      url: reportRawData,
      body,
      fileName: `${fileName} Raw Data.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const disableButton = (manageReport) => {
  const { courseCode, startDate, finishDate } = manageReport
  if (_.isEmpty(courseCode) || _.isEmpty(startDate) || _.isEmpty(finishDate)) {
    return true
  }
  return false
}
