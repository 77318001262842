import { validatePermission } from '../../../../utils/lib'

export const hasApproved = ({ status, statusInfo, user, masterStatus }) =>
  (status === 'REQUEST_CREATE' ||
    status === 'REQUEST_EDIT' ||
    status === 'ACTIVE') &&
  (statusInfo === 'WAITING_APPROVE_BUDGET' ||
    statusInfo === 'REQUEST_EDIT' ||
    statusInfo === 'REQUEST_CREATE') &&
  validatePermission({
    user: user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_MP'],
  }) &&
  (masterStatus === 'WAITING' ||
    masterStatus === 'APPROVED' ||
    masterStatus === 'PATIAL_APPROVED')
