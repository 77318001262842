import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { setSectionResult } from '../../../../../../redux/slices/eEvaluationPreview'
import { responsiveButton } from '../Styled'

const SubmitBtn = () => {
  const dispatch = useDispatch()
  const buttonSize = responsiveButton()

  return (
    <Button
      type="submit"
      variant="contained"
      size={buttonSize}
      data-testid="btn-submit"
      onClick={() => dispatch(setSectionResult())}
    >
      ส่งแบบประเมิน
    </Button>
  )
}

export default SubmitBtn
