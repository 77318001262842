import { setFieldUploadValue } from '../../../redux/slices/uploadCropper'
import { store } from '../../../App'

export const HandleClearImage = (e, onChange) => {
  store.dispatch(setFieldUploadValue({ key: 'fileName', value: '' }))
  store.dispatch(setFieldUploadValue({ key: 'coverImage', value: '' }))
  store.dispatch(
    setFieldUploadValue({ key: 'isUploadSuccess', value: undefined }),
  )
  onChange('')
  e.stopPropagation()
}
