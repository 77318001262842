import Tab from '@mui/material/Tab'
import styled from '@mui/system/styled'
export const StyledTab = styled(Tab)(({ theme }) => ({
  width: '12.5%',
  '&.Mui-selected': { fontWeight: 700 },
  '&:not(.Mui-selected)': {
    fontWeight: 400,
    color: theme?.palette?.primary?.main,
  },
}))
