import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { MODULE_STATUS } from '../../../../../../../constants/eLearning'
import { getStateValue, handleChange } from '../../../events'
import { StyledBoxContent } from './Styled'

const StatusSwitch = () => {
  const dispatch = useDispatch()
  const { status, onGoing } = useSelector(
    (state) => ({
      status: getStateValue('status'),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">สถานะเปิดปิดการใช้งานหลักสูตร</Typography>
      <StyledBoxContent>
        <Typography>เปิดปิดการใช้งานหลักสูตร</Typography>

        <Switch
          name="status"
          checked={status === MODULE_STATUS.ACTIVE}
          onChange={(e) =>
            dispatch(
              handleChange(
                'status',
                e.target.checked
                  ? MODULE_STATUS.ACTIVE
                  : MODULE_STATUS.INACTIVE,
              ),
            )
          }
          disabled={onGoing}
        />
      </StyledBoxContent>
    </Box>
  )
}

export default StatusSwitch
