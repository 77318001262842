import React from 'react'
import { ViewRow } from '../../../../../../../../components/CRUD/components/StyledViewBox'
import { Box, Card, Typography } from '@mui/material'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import _ from 'lodash'
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_VIEW_TEXT,
} from '../../../../../../../../constants/stock'
import {
  convertFloatWithComma,
  getStatusText,
} from '../../../../../../../../utils/lib'
import { StatusComponent } from '../../../../../../../../components/CRUD/components/StatusComponent'
import { shallowEqual, useSelector } from 'react-redux'
import SkuForm from './form'
import { StyledBox } from './Styled'

const index = (props) => {
  const { item, productType } = props
  const { isShowEditForm } = useSelector(
    (state) => ({
      isShowEditForm: state.crud.isShowEditForm,
    }),
    shallowEqual,
  )
  const productImage = _.get(item, 'productWarehouse.product.productImage', '-')
  let refundText = ''
  if (_.get(item, 'productWarehouse.product.refundYear', false))
    refundText += ` ${_.get(
      item,
      'productWarehouse.product.refundYear',
      '',
    )} ปี`
  if (_.get(item, 'productWarehouse.product.refundMonth', false))
    refundText += ` ${_.get(
      item,
      'productWarehouse.product.refundMonth',
      '',
    )} เดือน`
  if (_.get(item, 'productWarehouse.product.refundDay', false))
    refundText += ` ${_.get(
      item,
      'productWarehouse.product.refundDay',
      '',
    )} วัน`
  return (
    <>
      <Box
        sx={{ mt: 2, display: 'flex', gap: 2, flexDirection: 'column', px: 2 }}
      >
        <ViewRow
          title="ประเภททรัพย์สิน"
          variant="variant"
          value={PRODUCT_TYPE_VIEW_TEXT?.[productType] ?? '-'}
        />

        <ViewRow
          title="รหัสทรัพย์สิน"
          variant="variant"
          value={_.get(item, 'skuNo', '')}
        />

        <ViewRow
          title="มูลค่าต่อหน่วย (฿) รหัสทรัพย์สิน"
          variant="variant"
          value={`${convertFloatWithComma(_.get(item, 'unitPrice', ''))} บาท`}
        />

        <ViewRow
          title="Cost center"
          variant="variant"
          value={`${_.get(item, 'costCenter.descriptionName', '-')}`}
        />

        <ViewRow
          title="ชื่อทรัพย์สิน"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.productName', '-')}`}
        />

        {/* <ViewRow title="ID" variant="variant" value={`ID`} /> */}

        <ViewRow
          title="หมวดหมู่"
          variant="variant"
          value={`${_.get(
            item,
            'productWarehouse.product.category.name',
            '-',
          )}`}
        />

        <ViewRow
          title="หมวดหมู่ย่อย"
          variant="variant"
          value={`${_.get(
            item,
            'productWarehouse.product.subCategory.name',
            '-',
          )}`}
        />

        <ViewRow
          boxSx={{ display: 'flex', flexDirection: 'column' }}
          title="คำอธิบาย"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.description', '-')}`}
        />

        <ViewRow
          title="รูปประกอบ"
          variant="variant"
          value={
            productImage?.key ? (
              <img
                srcSet={`${window.__env__.REACT_APP_API_URL}/file/get${productImage?.key}`}
                src={
                  !_.isEmpty(productImage)
                    ? `${window.__env__.REACT_APP_API_URL}/file/get${productImage?.key}`
                    : ''
                }
                // alt={item.title}
                loading="lazy"
                width={150}
              />
            ) : (
              '-'
            )
          }
        />

        <ViewRow
          title="ระบบ รหัสทรัพย์สิน"
          variant="variant"
          value={getStatusText(
            productType === PRODUCT_TYPE.STOCK_ASSET ? 'ACTIVE' : 'INACTIVE',
          )}
        />

        <ViewRow
          title="จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย"
          variant="variant"
          value={`${convertFloatWithComma(
            _.get(item, 'productWarehouse.product.quantityAlert', '-'),
          )}`}
        />

        <ViewRow
          title="Flag คืนทรัพย์สิน"
          variant="variant"
          value={`${getStatusText(
            _.get(item, 'productWarehouse.product.statusFlagReturn', '-'),
          )}`}
        />

        <ViewRow
          title="คืนทรัพย์สินภายใน"
          variant="variant"
          value={refundText}
        />

        <ViewRow
          title="สถานะทรัพย์สิน"
          variant="variant"
          value={StatusComponent(item?.productWarehouse?.product)}
        />

        <ViewRow
          boxSx={{ display: 'flex', flexDirection: 'column' }}
          title="หมายเหตุ"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.remark', '-')}`}
        />

        {!isShowEditForm && (
          <>
            <ViewRow
              title="สถานะ รหัสทรัพย์สิน"
              variant="variant"
              valueComponent={StatusComponent(item)}
            />

            <ViewRow
              boxSx={{ display: 'flex', flexDirection: 'column' }}
              title="เหตุผลการลบทรัพย์สิน"
              variant="variant"
              value={`${item?.remark ?? '-'}`}
            />
          </>
        )}
      </Box>
      {isShowEditForm && <SkuForm />}
      {item?.holderInfo && (
        <>
          <Box>
            <Typography variant="body1b">ข้อมูลผู้ยืม</Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              px: 2,
            }}
          >
            <ViewRow
              title="Ticket ID"
              variant="variant"
              value={item?.holderInfo ? _.get(item, 'ticket.id', '-') : '-'}
            />
          </Box>
          <Box>
            <Card sx={{ m: 3, p: 2 }}>
              {item?.holderInfo ? (
                <>
                  <Box display="flex" justifyContent="start" mb={2}>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      คำนำหน้าชื่อ
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      {_.get(item, 'holderInfo.prefixTH', '-')}
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      ชื่อ-สกุล
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      {!_.get(item, `holderInfo.firstNameTH`, '') &&
                      !_.get(item, `holderInfo.firstNameTH`, '') ? (
                        '-'
                      ) : (
                        <>
                          {_.get(item, `holderInfo.firstNameTH`, '')}{' '}
                          {_.get(item, `holderInfo.lastNameTH`, '')}
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="start">
                    <Typography variant="body2" mr={2} minWidth="120px">
                      เบอร์โทรศัพท์
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      {_.get(item, 'holderInfo.tel', '-')}
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      Email
                    </Typography>
                    <Typography variant="body2" mr={2} minWidth="120px">
                      {_.get(item, 'holderInfo.email', '-')}
                    </Typography>
                  </Box>
                </>
              ) : (
                <StyledBox>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledBox>
              )}
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default index
