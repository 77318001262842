import styled from '@mui/system/styled'
import { StyledRow } from '../../Styled'

export const StyledRowForm = styled(StyledRow)(({ theme }) => ({
  alignItems: 'start',
  '& > .MuiBox-root': { width: '33%' },
  '& > .MuiBox-root:not(:first-of-type)': { marginTop: 25 },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    flexDirection: 'column',
    gap: 0,
    '& > .MuiBox-root': { width: '100%' },
    '& > .MuiBox-root:not(:first-of-type)': { marginTop: 0 },
  },
}))
