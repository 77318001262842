import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from './Styled'
import { handleClearFilter } from '../handler/handleClearFilter'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import _ from 'lodash'

export const FilterBox = () => {
  const { filterTotal, filterState } = useSelector(
    (state) => ({
      filterTotal: state.table.filter.filterTotal,
      filterState: state.table.filterState,
    }),
    shallowEqual,
  )

  const {
    isFilterDate,
    filterList,
    filterStateBelt,
    EtestDashboardTab,
    classUuidLabel,
  } = useSelector(
    (state) => ({
      isFilterDate: state.crud.isFilterDate,
      filterList: state.crud.filterList,
      filterStateBelt: state.crud.filterStateBelt,
      EtestDashboardTab: state.crud.EtestDashboardTab,
      classUuidLabel: state.crud.classUuidLabel,
    }),
    shallowEqual,
  )
  const { uuid } = useParams()

  const startDateFilter =
    filterStateBelt?.startDateChecked && _.isDate(filterStateBelt?.startDate)
      ? filterStateBelt?.startDate
      : new Date(dayjs().startOf('month'))
  const endDateFilter =
    filterStateBelt?.startDateChecked && _.isDate(filterStateBelt?.endDate)
      ? filterStateBelt?.endDate
      : new Date(dayjs().utc())
  const courseCodeFilter =
    filterStateBelt?.courseCodeChecked === true
      ? filterStateBelt?.courseCode
      : undefined
  const classUuidFilter =
    filterStateBelt?.courseCodeChecked === true &&
    filterStateBelt?.classUuidChecked === true
      ? classUuidLabel
      : undefined
  const distributionFilter =
    filterStateBelt?.distributionChecked === true
      ? filterStateBelt?.distribution
      : undefined
  const unitCodeFilter =
    filterStateBelt?.unitCodeChecked === true
      ? filterStateBelt?.unitCode
      : undefined
  const sRamFilter =
    filterStateBelt?.sRamChecked === true ? filterStateBelt?.sRam : undefined
  const ramCodeFilter =
    filterStateBelt?.ramCodeChecked === true
      ? filterStateBelt?.ramCode
      : undefined
  const trainerFilter =
    filterStateBelt?.trainerChecked === true
      ? filterStateBelt?.trainer
      : undefined
  const agentCodeFilter =
    filterStateBelt?.agentCodeChecked === true
      ? filterStateBelt?.agentCode
      : undefined

  const testerFilter =
    filterStateBelt?.userProfileUuidChecked === true
      ? filterStateBelt?.userProfileUuid
      : undefined
  const testTypeFilter =
    filterStateBelt?.testTypeChecked === true
      ? filterStateBelt?.testType
      : undefined
  const testTypeText = []
  if (filterStateBelt?.testTypeChecked === true) {
    if (filterStateBelt?.testType.preTestChecked === true) {
      testTypeText.push('Pre-test')
    }
    if (filterStateBelt?.testType.postTestChecked === true) {
      testTypeText.push('Post-test')
    }
    if (filterStateBelt?.testType.otherChecked === true) {
      testTypeText.push('Other')
    }
  }
  const isClassUuid = filterStateBelt?.classUuid

  return (
    <>
      {
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่อบรม:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => {
                    handleClearFilter(
                      filterState,
                      'filterDate',
                      uuid,
                      filterTotal,
                      EtestDashboardTab,
                    )
                  }}
                />
              )}
            </FilterStyledBox>
          )}
          {testerFilter && (
            <FilterStyledBox>
              ผู้ทำแบบทดสอบ:{' '}
              <strong>
                {
                  filterList.userProfileUuid.find(
                    (data) => data.value === testerFilter,
                  ).label
                }
              </strong>
              {
                <StyledClearIcon
                  onClick={() => {
                    handleClearFilter(
                      filterState,
                      'testerFilter',
                      uuid,
                      filterTotal,
                    )
                  }}
                />
              }
            </FilterStyledBox>
          )}
          {courseCodeFilter && (
            <FilterStyledBox>
              หลักสูตร: <strong>{courseCodeFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'courseCodeFilter',
                    uuid,
                    filterTotal,
                    isClassUuid,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {classUuidFilter && (
            <FilterStyledBox>
              คลาส / รอบอบรม: <strong>{classUuidFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'classUuidFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {distributionFilter && (
            <FilterStyledBox>
              ช่องทางการจัดจำหน่าย:{' '}
              <strong>
                {
                  filterList.distribution.find(
                    (data) => data.value === distributionFilter,
                  ).label
                }
              </strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'distributionFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {unitCodeFilter && (
            <FilterStyledBox>
              Unit Code: <strong>{unitCodeFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'unitCodeFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {sRamFilter && (
            <FilterStyledBox>
              SRAM: <strong>{sRamFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'sRamFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {ramCodeFilter && (
            <FilterStyledBox>
              RAM:{' '}
              <strong>
                {
                  filterList.ramCode.find(
                    (data) => data.value === ramCodeFilter,
                  ).label
                }
              </strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'ramCodeFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {trainerFilter && (
            <FilterStyledBox>
              Trainer: <strong>{trainerFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'trainerFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {agentCodeFilter && (
            <FilterStyledBox>
              Agent Code: <strong>{agentCodeFilter}</strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'agentCodeFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
          {testTypeFilter && (
            <FilterStyledBox>
              รูปแบบการทดสอบ:{' '}
              <strong>
                {testTypeText.map((data, i) => {
                  if (i === 0) {
                    return data
                  } else return ', ' + data
                })}
              </strong>
              <StyledClearIcon
                onClick={() => {
                  handleClearFilter(
                    filterState,
                    'testTypeFilter',
                    uuid,
                    filterTotal,
                  )
                }}
              />
            </FilterStyledBox>
          )}
        </BoxWrapper>
      }
    </>
  )
}
