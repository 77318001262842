import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledLoadingBox = styled(Box)(({ keyField }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: keyField === 'img' ? 120 : 269,
  height: keyField === 'img' ? 120 : 150,
  backgroundColor: '#f4f4f4',
}))

export const StyledLoadingProgress = styled(Box)(({ theme, keyField }) => ({
  position: 'absolute',
  bottom: theme.spacing(3),
  width: keyField === 'img' ? 72 : 221,
  height: 5,
  backgroundColor: theme?.palette?.text?.silver,
}))

export const StyledLoading = styled(Box)(({ theme }) => ({
  width: 0,
  height: 5,
  backgroundColor: theme?.palette?.primary?.main,
  transition: 'width 0.4s ease',
}))
