import { store } from 'src/App'
import {
  setReduxDashboardByKey,
  setWorkTypeDataByKey,
} from 'src/redux/slices/monthlyPlan'
import _ from 'lodash'

export const handleChangeWorkTypeData = ({ key, value }) => {
  let newErrors = {}
  const { errors } = store.getState().monthlyPlan.dashboard
  if (_.includes(key, 'title') && value?.length > 25) {
    _.set(newErrors, key, 'กรอกได้ไม่เกิน 25 ตัวอักษร')
  } else if (_.includes(key, 'workTypeList') && value?.length > 2) {
    _.set(newErrors, key, 'กรอกได้ไม่เกิน 2 หลัก')
  }
  store.dispatch(
    setReduxDashboardByKey({
      key: 'errors',
      value: newErrors,
    }),
  )

  if (_.isEmpty(newErrors) && _.isEmpty(_.get(errors, key, {})))
    store.dispatch(setWorkTypeDataByKey({ key, value }))
}
