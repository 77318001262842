export const defaultFilter = {
  nameChecked: false,
  name: '',
  fromChecked: false,
  from: '',
  toChecked: false,
  to: '',
  qtyFrom: '',
  qtyTo: '',
  categoryChecked: false,
  category: '',
  subCategoryChecked: false,
  subCategory: '',
  createDateChecked: false,
  statusChecked: false,
  status: {
    draftChecked: false,
    waitingApproveChecked: false,
    approvedChecked: false,
    rejectedChecked: false,
    canceledChecked: false,
  },
}
