import _ from 'lodash'
import {
  getLicenseConfigType,
  getLicenseDistributionList,
  getLicenseDistributionELearningList,
  postMasterDataLicense,
  getLicenseById,
  putMasterDataLicense,
  getLicenseList,
} from '../../../services/masterData/license'

import { openDialog } from '../../../redux/slices/dialog'

import {
  startLoading,
  stopLoading,
  setFetchDataOption,
  setFetchData,
} from '../../../redux/slices/masterData/licenseForm'

export const handleFetchDataCreate = () => async (dispatch) => {
  dispatch(startLoading())
  const data = await Promise.all([
    dispatch(getLicenseConfigType()),
    dispatch(getLicenseDistributionList()),
    dispatch(getLicenseList()),
    dispatch(getLicenseDistributionELearningList()),
  ])
  dispatch(
    setFetchDataOption({
      listType: mapDataConfigType(_.get(data, '[0].payload', [])),
      listDistribution: mapDataDistribution(
        _.get(data, '[1].payload', []),
        false,
      ),
      licenseList: mapDataLicenseList(_.get(data, '[2].payload', [])),
      listDistributionELearning: mapDataDistribution(
        _.get(data, '[3].payload', []),
        false,
      ),
    }),
  )
  dispatch(stopLoading())
}

export const handleFetchDataEdit = (uuid, isEdit) => async (dispatch) => {
  dispatch(startLoading())
  const data = await Promise.all([
    dispatch(getLicenseConfigType()),
    dispatch(getLicenseDistributionList()),
    dispatch(getLicenseById(uuid)),
    dispatch(getLicenseList()),
    dispatch(getLicenseDistributionELearningList()),
  ])

  const information = _.get(data, '[2].payload', {})

  dispatch(
    setFetchData({
      listType: mapDataConfigType(_.get(data, '[0].payload', [])),
      listDistribution: mapDataDistribution(
        _.get(data, '[1].payload', []),
        isEdit,
      ),
      listDistributionELearning: mapDataDistribution(
        _.get(data, '[4].payload', []),
        false,
      ),
      information: {
        ...information,
        uuid: _.get(information, 'uuid', ''),
        remark: _.isNull(information.remark)
          ? ''
          : _.get(information, 'remark', ''),
        licenseId: _.get(information, 'licenseId', ''),
        createdAt: _.get(information, 'createdAt', ''),
        updatedAt: _.get(information, 'updatedAt', ''),
        createdBy: _.get(information, 'createdBy', ''),
        updatedBy: _.get(information, 'updatedBy', ''),
        documents: _.get(information, 'document', []),
      },
      licenseConfig: _.get(data, '[2].payload.licenseConfig', []),
      licenseList: mapDataLicenseList(_.get(data, '[3].payload', [])),
      defaultListDistribution: mapDataDistribution(
        _.get(data, '[1].payload', []),
        true,
      ),
      defaultListDistributionELearning: mapDataDistribution(
        _.get(data, '[4].payload', []),
        true,
      ),
    }),
  )

  dispatch(stopLoading())
}

export const mapDataConfigType = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      label: _.get(item, 'description', ''),
      value: _.get(item, 'title', ''),
    }
  })
}

export const mapDataLicenseList = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      label: _.get(item, 'description', ''),
      value: _.get(item, 'uuid', ''),
      status: _.get(item, 'status', 'INACTIVE'),
    }
  })
}

export const mapDataDistribution = (list, isEdit) => {
  if (!list.length) return []
  return list.map((item) => {
    let listCourse = _.get(item, 'course', []).map((itemCourse) => {
      return {
        label: _.get(itemCourse, 'englishName', itemCourse.name),
        value: _.get(itemCourse, 'uuid', ''),
        status: _.get(itemCourse, 'status', 'INACTIVE'),
      }
    })
    if (!isEdit)
      listCourse = listCourse.filter(
        (itemFilter) => itemFilter.status === 'ACTIVE',
      )

    return {
      label: _.get(item, 'distribution', ''),
      value: _.get(item, 'uuid', ''),
      course: listCourse,
    }
  })
}

export const handleSubmit = (value) => (dispatch) => {
  const information = _.get(value, 'information', {})
  const licenseConfig = _.get(value, 'licenseConfig', {})

  const uuid = _.get(information, 'uuid', '')

  let body = {
    description: _.get(information, 'description', ''),
    category: _.get(information, 'category', ''),
    remark: _.get(information, 'remark', ''),
    status: _.get(information, 'status', ''),
    document: _.get(information, 'documents', []),
    licenseConfig: filterDataLicenseConfigs(licenseConfig),
  }

  if (!_.isEmpty(uuid)) {
    body = {
      ...body,
      uuid,
    }
  }

  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(actionSubmit(body)),
    }),
  )
}

export const filterDataLicenseConfigs = (licenseConfig) => {
  if (!licenseConfig.length) return []

  return licenseConfig.map((itemConfig, indexConfig) => {
    const groups = _.get(itemConfig, 'groups', [])
    return {
      ...itemConfig,
      configGroup: indexConfig + 1,
      groups: groups.map((itemGroup, indexGroup) => {
        const subGroups = _.get(itemGroup, 'subGroups', [])
        return {
          ...itemGroup,
          group: indexGroup + 1,
          subGroups: subGroups.map((itemSubGroup, indexSubGroup) => {
            const contents = _.get(itemSubGroup, 'contents', [])
            return {
              subGroup: indexSubGroup + 1,
              contents: contents.map((itemContent, indexContent) => {
                const uuidContent = _.get(itemContent, 'uuid', '')
                let item = {
                  ...itemContent,
                  subSequence: indexContent + 1,
                }
                if (!_.isEmpty(uuidContent)) {
                  item = {
                    ...item,
                    uuid: uuidContent,
                  }
                }

                return item
              }),
            }
          }),
        }
      }),
    }
  })
}

export const actionSubmit = (body) => async (dispatch) => {
  const uuid = _.get(body, 'uuid', '')
  const result = !_.isEmpty(uuid)
    ? await dispatch(putMasterDataLicense(body))
    : await dispatch(postMasterDataLicense(body))

  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        isCloseDialog: false,
        handleConfirm: () => {
          window.location.href = `/master-data/license/view/${_.get(
            result,
            'payload.uuid',
            '',
          )}`
        },
      }),
    )
  }
}
