import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { fetchCourse } from './fetchCourse'
import { fetchLearningPath } from './fetchLearningPath'
import { handleOptionChoosed } from '../CollectionTab/handler/handleOptionChoosed'
import { fetchDistributionCh } from './fetchDistributionCh'
import { fetchAcquireSkill } from './fetchAcquiredSkill'
import { fetchProductType } from './fetchProductType'

export const initialForm = async () => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {
        course: { isLoading: false, options: [], allOptions: [] },
        learningPath: { isLoading: false, options: [], allOptions: [] },
        productType: { isLoading: false, options: [] },
        distributionCh: { isLoading: false, options: [] },
        levelOfLearner: { isLoading: false, options: [] },
        acquiredSkill: { isLoading: false, options: [] },
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'tabs',
      value: {
        content: [
          'name',
          'nameForBanner',
          'detailForBanner',
          'description',
          'linkExampleVideo',
          'coverImage',
          'contents',
          'remark',
        ],
        setting: [
          'distributionCh',
          'levelOfLearner',
          'periodStart',
          'setting',
          'acquiredSkill',
          'productType',
          'dueDateType',
          'dueDate',
        ],
      },
    }),
  )
  await fetchCourse()
  await fetchLearningPath()
  await handleOptionChoosed()
  await fetchDistributionCh()
  await fetchAcquireSkill()
  await fetchProductType()

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )

  return true
}
