import { postRoomHistoryAll } from '../../../../services/roomManagement'
import {
  setHasMore,
  startLoading,
  stopFetch,
  stopLoading,
  setHistory,
  concatHistory,
} from '../../../../redux/slices/roomManagementHistory'
import _ from 'lodash'

export const fetchRoomHistoryAll = (props) => async (dispatch) => {
  const { stationUuid, page, startDate, endDate, currentLength } = props
  if (page == 1) {
    dispatch(startLoading())
  }
  const body = {
    stationUuid: stationUuid, // ถ้าเป็น super admin จะเป็น ""
    page: page,
    startDate: startDate,
    endDate: endDate,
  }
  let result = await dispatch(postRoomHistoryAll(body))
  result.payload.result.map((e) => {
    let roomGroupByDate = {}
    roomGroupByDate = _.groupBy(e.roomDateTime, (room) => {
      return room.date
    })
    e.roomGroupByDate = roomGroupByDate
  })
  if (page == 1) {
    dispatch(
      setHasMore(result.payload.result.length < result.payload.totalCount),
    )
    dispatch(setHistory(result.payload.result))
  } else {
    dispatch(
      setHasMore(
        currentLength + result.payload.result.length <
          result.payload.totalCount,
      ),
    )
    dispatch(concatHistory(result.payload.result))
  }
  dispatch(stopLoading())
  dispatch(stopFetch())
}
