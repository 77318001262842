import React from 'react'
import { StyledRow, StyledColumn } from '../../Styled'
import useTheme from '@mui/system/useTheme'
import { Typography, Divider, Grid, Tooltip } from '@mui/material'
import { convertFormatDateTime } from '../../../../utils/lib'

export default function BookingDetail({ booking }) {
  const theme = useTheme()
  return (
    <StyledColumn sx={{ gap: 1 }}>
      <StyledColumn>
        {Object.keys(booking.roomGroupByDate).map((key, ind) => {
          return (
            <StyledRow
              sx={{ columnGap: 1, alignItems: 'flex-start' }}
              key={ind}
            >
              <Typography variant="body1b" color={theme.palette?.primary?.main}>
                {convertFormatDateTime({
                  value: key,
                  type: 'date',
                })}
              </Typography>
              <StyledColumn>
                {booking.roomGroupByDate[key].map((room, idx) => {
                  return (
                    <StyledRow key={idx} sx={{ columnGap: 1 }}>
                      <Typography variant="body1b">{room.room}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {room.time}
                      </Typography>
                    </StyledRow>
                  )
                })}
              </StyledColumn>
            </StyledRow>
          )
        })}
      </StyledColumn>
      <Divider sx={{ borderColor: theme.palette?.blue?.blueLine }} />
      <ContentRow
        columnLeft={
          <RowLabels
            titleSx={{ color: theme.palette?.text?.gray }}
            title={'จองโดย'}
            label={booking.bookByName}
            left={true}
          />
        }
        columnRight={
          <RowLabels
            titleSx={{ color: theme.palette?.text?.gray }}
            title={'จองให้'}
            label={booking.bookForName}
          />
        }
      />
      <ContentRow
        columnLeft={
          <RowLabels
            titleSx={{ color: theme.palette?.text?.gray }}
            title={'เบอร์โทร'}
            label={booking.mobileNo || '-'}
            left={true}
          />
        }
      />
      <ContentRow
        columnLeft={
          <RowLabels
            titleSx={{ color: theme.palette?.text?.gray }}
            title={'อีเมล'}
            label={booking.email || '-'}
            left={true}
          />
        }
      />
    </StyledColumn>
  )
}

const RowLabels = ({ title, label, titleSx, left }) => {
  return (
    <StyledRow sx={{ gap: 1.5 }}>
      <Typography
        sx={{ ...titleSx, textWrap: 'noWrap', minWidth: left && '42px' }}
        variant="body2"
      >
        {title}
      </Typography>
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: left ? 160 : 175,
        }}
        variant="body2"
      >
        <Tooltip
          title={<Typography variant="body2">{label}</Typography>}
          componentsProps={{
            tooltip: {
              sx: {
                background: '#3032C1',
              },
            },
          }}
        >
          {label}
        </Tooltip>
      </Typography>
    </StyledRow>
  )
}
const ContentRow = ({ columnLeft, columnRight }) => {
  return (
    <Grid container columns={8}>
      <Grid item xs={columnRight ? 4 : 8}>
        {columnLeft}
      </Grid>
      {columnRight && (
        <Grid item xs={4}>
          {columnRight}
        </Grid>
      )}
    </Grid>
  )
}
