import React, { useState } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import { validatePermission } from '../../../../utils/lib'

const DropDownMenu = ({ setShow, setType, checkUserArea }) => {
  const [user] = useLocalStorage('user')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const hasMonthlyPlanCreate = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['CREATE_ALL_EXC_HOLIDAY'],
  })
  const hasMonthlyPlanDelete = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['DELETE_ALL_EXC_HOLIDAY'],
  })
  const hasMonthlyPlanView = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['VIEW_ALL'],
  })
  const hasMonthlyPlanApprove = validatePermission({
    user,
    moduleType: 'MONTHLY_PLAN',
    permission: ['APPROVE_ZONE'],
  })
  const hasHeadZonePermission =
    hasMonthlyPlanCreate &&
    hasMonthlyPlanDelete &&
    hasMonthlyPlanView &&
    hasMonthlyPlanApprove

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={!hasHeadZonePermission}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {checkUserArea ? (
          <>
            {createOptionsInArea.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  data-testid={`menu-${index}`}
                  onClick={() => {
                    if (option.value === 'update') {
                      setType('update')
                    } else {
                      setType('cancel')
                    }
                    setShow(true)
                    setAnchorEl(null)
                  }}
                  sx={{ gap: 1 }}
                >
                  {option.icon}
                  {option.text}
                </MenuItem>
              )
            })}
          </>
        ) : (
          <>
            {createOptionsBorrow.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  data-testid={`menu-${index}`}
                  onClick={() => {
                    if (option.value === 'update') {
                      setType('update')
                    } else {
                      setType('cancel')
                    }
                    setShow(true)
                    setAnchorEl(null)
                  }}
                  sx={{ gap: 1 }}
                >
                  {option.icon}
                  {option.text}
                </MenuItem>
              )
            })}
          </>
        )}
      </Menu>
    </>
  )
}

export default DropDownMenu

export let createOptionsInArea = [
  { text: 'เปลี่ยนแปลงวิทยากร', value: 'update', icon: <EditTwoTone /> },
  { text: 'ยกเลิกวิทยากร', value: 'cancel', icon: <DeleteTwoTone /> },
]

export let createOptionsBorrow = [
  { text: 'ยกเลิกวิทยากร', value: 'cancel', icon: <DeleteTwoTone /> },
]
