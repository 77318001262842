import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
]

export const defaultFilter = {
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  displayDate: true,
}
