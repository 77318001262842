import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}))

export const BoxContentHeader = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  display: 'flex',
}))

export const BoxContentLeft = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const BoxSubContentLeft = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}))

export const BoxLine = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme?.palette?.other?.divider}`,
  width: '100%',
  position: 'relative',
  marginLeft: theme.spacing(2),
  top: theme.spacing(2),
}))
