import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  manageClassCalendar,
  manageClassCourseVersionFilter,
  manageClassStaffFilter,
  workType,
} from '../../utils/apiPath'

export const getManageClassCalendar = createAsyncThunk(
  'manageClass/calendar',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageClassCalendar, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const getCalendarStaff = createAsyncThunk(
  'manageClass/calendar/staff',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassStaffFilter}${url}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const getCalendarWorkType = createAsyncThunk(
  'manageClass/calendar/workType',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(workType, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const postCalendarCourse = createAsyncThunk(
  'manageClass/calendar/course',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageClassCourseVersionFilter, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
