import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'

import HistoryDrawer from '../../components/Drawer/History'
import Dot from '../../components/Table/Dot'
import { callAPI, getStatusText } from '../../utils/lib'
import UserLevelList from '../../components/Table/UserLevelList'
import Breadcrumbs from '../../components/Breadcrumbs'
import { distributionChannel, distributionHistory } from '../../utils/apiPath'

export const filterLevelName = ({ listLevel }) => {
  let result = []
  if (listLevel.length) {
    listLevel.filter((item) => {
      result.push(item.title || '')
    })
  }
  return result
}

const DistributionChannelView = () => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const hisory = useHistory()
  const [distribution, setDistribution] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ช่องทางการจำหน่าย',
      link: '/master-data/distribution-channel',
      pointer: true,
    },
    { title: 'รายละเอียดช่องทางการจำหน่าย', link: '/', pointer: false },
  ]

  const fetchDistributionChannelById = async (uuid) => {
    await callAPI({
      url: distributionChannel + `/${uuid}`,
      method: 'GET',
      onSuccess: (res) => {
        let result = res || {}
        if (Object.keys(result).length) {
          result = {
            ...result,
            userLevel: filterLevelName({ listLevel: result.level || [] }),
          }
        }
        setDistribution(result)
      },
    })
  }

  const fetchHistory = async (uuid) => {
    const realPage = page === 0 ? 1 : page + 1
    const url = `${distributionHistory}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }
  useEffect(() => {
    setIsPageLoading(true)
    fetchDistributionChannelById(uuid)
    setIsPageLoading(false)
  }, [])

  useEffect(() => {
    fetchHistory(uuid)
  }, [limit, page])

  const [isPageLoading, setIsPageLoading] = useState(false)

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            ml: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
            }}
          >
            รายละเอียดช่องทางการจำหน่าย : {distribution.distribution}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Box
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            sx={distribution.status !== 'DELETED' ? { mr: 3 } : {}}
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={onHistoryClick}
          >
            ประวัติการแก้ไข
          </Button>
          {distribution.status !== 'DELETED' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                hisory.push(
                  '/master-data/distribution-channel/edit/' + distribution.uuid
                )
              }}
            >
              แก้ไข
            </Button>
          )}
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ID
          </Typography>
          <Typography variant="body1">{distribution.id}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ช่องทางการจำหน่าย
          </Typography>
          <Typography variant="body1">{distribution.distribution}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ชื่อย่อ
          </Typography>
          <Typography variant="body1">{distribution.abbreviation}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ระดับของผู้เรียน
          </Typography>
          <Typography variant="body1">{distribution.title}</Typography>

          <UserLevelList levelList={distribution.userLevel} />
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            หมายเหตุ
          </Typography>
          <Typography variant="body1">{distribution.remark || '-'}</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            สถานะ
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {' '}
            <Dot sx={{ mt: 1.5, mr: 1.5 }} status={distribution.status} />
            <Typography variant="body1">
              {getStatusText(distribution.status)}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mx: 3 }} />
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'right',
          }}
        >
          <Typography variant="caption" color="text.lightGray">
            แก้ไขล่าสุดโดย{' '}
            {historyList && historyList.length > 0 && historyList[0].author}{' '}
            {historyList && historyList.length > 0 && historyList[0].createdAt}
          </Typography>
        </Box>
      </Card>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default DistributionChannelView
