import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'
import { PRODUCT_TYPE } from '../../../constants/stock'

export const crudConfig = {
  moduleId: 'INVENTORY_TRANSFER',
  moduleSlug: 'inventory-transfer',
  moduleTitleFull: 'การโอนย้าย',
  modulesTitleShort: 'การโอนย้าย',
  modulePath: '/inventory/transfer',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
  masterDataModuleId: [
    {
      key: 'from',
      moduleId: 'INVENTORY_WAREHOUSE',
    },
    {
      key: 'to',
      moduleId: 'INVENTORY_WAREHOUSE',
    },
    {
      key: 'product',
      moduleId: 'INVENTORY_PRODUCT_ALL',
      body: {
        productType: [PRODUCT_TYPE.STOCK],
      },
    },
    {
      key: 'productStockAsset',
      moduleId: 'INVENTORY_PRODUCT_ALL',
      body: {
        productType: [PRODUCT_TYPE.STOCK_ASSET],
      },
    },
    {
      key: 'productAsset',
      moduleId: 'INVENTORY_PRODUCT_ALL',
      body: {
        productType: [PRODUCT_TYPE.ASSET],
      },
    },
    {
      key: 'productLicense',
      moduleId: 'INVENTORY_PRODUCT_ALL',
      body: {
        productType: [PRODUCT_TYPE.LICENSE],
      },
    },
    {
      key: 'costCenter',
      moduleId: 'COST_CENTER',
    },
    {
      key: 'payer',
      moduleId: 'INVENTORY_PAYER_HOLDER',
    },
    {
      key: 'holder',
      moduleId: 'INVENTORY_PAYER_HOLDER',
    },
  ],
}
