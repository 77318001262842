import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

import ReactPlayer from 'react-player'
import { StyledColumn, StyledRow } from '../../Styled'

export const StyledContainerBanner = styled(StyledRow)(({ theme }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(5),
  alignItems: 'start',
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(2) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(5) },
}))

export const StyledBoxBanner = styled(StyledColumn)(({ theme }) => ({
  width: 'calc(50% - 20px)',
  minHeight: 622,
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    width: 'calc(50% - 8px)',
    minHeight: 600,
    paddingBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledBannerMedia = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 205,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
  [theme.breakpoints.down('lg')]: { height: 127 },
  [theme.breakpoints.down('sm')]: { height: 108 },
}))

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isNotReplace',
})(({ theme, label, isNotReplace }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  p: { margin: 0 },
  [theme.breakpoints.down('lg')]: {
    WebkitLineClamp: label == 'Description' ? '3' : '2',
  },
  '& span': isNotReplace && { color: '#7F7F7F !important' },
  '& p': isNotReplace && { color: '#7F7F7F !important' },
}))

export const StyledBannerBottom = styled(StyledRow)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette?.other?.divider,
  paddingTop: theme.spacing(2.5),
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'start',
    height: 105,
  },
}))

export const StyledVideoPreview = styled((props) => (
  <ReactPlayer
    controls
    config={{ vimeo: { playerOptions: { responsive: true } } }}
    {...props}
  />
))(() => ({
  width: '100% !important',
  height: '100% !important',
}))
