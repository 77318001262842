import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { COURSE_TYPE_TH } from '../../../../../../../constants/eLearning'
import { isMediaWidthMoreThan } from '../../../../../../../utils/lib'
import { StyledLikeIcon, StyledTypography } from './Styled'

const CourseTitle = ({ course }) => {
  const isLg = isMediaWidthMoreThan('md')
  const isLike = _.get(course, 'isLike', false)
  const assignees = _.join(
    _.get(course, 'assignees', []).map((item) => item.name),
    ', ',
  )
  return (
    <Box sx={{ textAlign: 'start', position: 'relative' }}>
      {isLike && <StyledLikeIcon src="/icon/ic_heart_fill.svg" />}
      <StyledTypography
        id="course-type"
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: !isLg && 14 }}
      >
        {COURSE_TYPE_TH[_.get(course, 'courseType', '')] || ''}
      </StyledTypography>
      <StyledTypography variant={isLg ? 'h5' : 'h6'} color="text.primary">
        {_.get(course, 'name', '')}
      </StyledTypography>
      <StyledTypography id="assignee" variant="body2" sx={{ display: 'none' }}>
        {assignees}
      </StyledTypography>
    </Box>
  )
}

export default CourseTitle
