import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setFieldValue } from '../../../redux/slices/crud'
import { useInputField } from '../hooks/useInputField'
import { enumStatus } from '../enum/status'
import { Box, Switch, Typography } from '@mui/material'

const CustomSwitchInLine = ({
  fieldName,
  title = 'สถานะ',
  disabled,
  required,
  boxSx = {},
  hideLabelText = false,
  handleExtraChange,
}) => {
  const dispatch = useDispatch()
  const { value } = useInputField(fieldName)
  const { crudConfig } = useSelector((state) => state.crud, shallowEqual)

  const setStatus = (checked) =>
    checked ? enumStatus.ACTIVE : enumStatus.INACTIVE

  function handleOnChange(e) {
    dispatch(
      setFieldValue({
        key: `formData.${fieldName}`,
        value: setStatus(e.target.checked),
      }),
    )
    if (handleExtraChange) {
      handleExtraChange(e, e.target.checked)
    }
  }

  function isStatusActive() {
    return value === enumStatus.ACTIVE
  }

  function getLabelText() {
    return value === enumStatus.ACTIVE
      ? 'เปิดการใช้งาน (Active)'
      : 'ปิดการใช้งาน (Inactive)'
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
        {required && (
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
            *
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Switch
          id={`${crudConfig.moduleSlug}-${fieldName}`}
          name={fieldName}
          required={required}
          checked={isStatusActive()}
          disabled={disabled}
          onChange={handleOnChange}
          inputProps={{
            'data-testid': 'status',
          }}
        />
        {!hideLabelText && (
          <Typography variant="body1" sx={{ lineHeight: 2 }}>
            {getLabelText(value)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default CustomSwitchInLine
