import TextInput from '../../../../../../components/Input/TextInput'
import {
  roomSelector,
  setRoom,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/system'
import _ from 'lodash'
import { getErrorMessage, getIndexByBookingDate } from '../../../event'

export const MeetingRoomOther = ({ bookingDate, roomIndex }) => {
  const dispatch = useDispatch()

  const meetingRoomUuid = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoomUuid',
    }),
    shallowEqual,
  )

  const meetingRoomOther = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'meetingRoomOther',
    }),
    shallowEqual,
  )

  const { formError } = useSelector(
    (state) => ({
      formError: state.roomManagementDrawer.formError,
    }),
    shallowEqual,
  )

  if (meetingRoomUuid !== 'OTHER') {
    return null
  }

  return (
    <Box sx={{ pl: 10 }}>
      <TextInput
        type="basic"
        id="meetingRoomOther"
        name="meetingRoomOther"
        required
        labelText={'กรอกชื่อสถานที่'}
        placeholder="กรุณากรอกชื่อสถานที่"
        value={meetingRoomOther}
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          dispatch(
            setRoom({
              key: 'meetingRoomOther',
              value: value,

              bookingDate,
              roomIndex,
            }),
          )
        }}
        isShowTextError={
          !_.isEmpty(
            getErrorMessage(
              formError,

              `roomBookingList[${getIndexByBookingDate(
                bookingDate,
              )}].rooms[${roomIndex}].meetingRoomOther`,
            ),
          )
        }
        textError={getErrorMessage(
          formError,

          `roomBookingList[${getIndexByBookingDate(
            bookingDate,
          )}].rooms[${roomIndex}].meetingRoomOther`,
        )}
      />
    </Box>
  )
}
