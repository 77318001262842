import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.blue?.stTropaz,
  width: '300px',
  height: '172px',
  borderRadius: 8,
  color: theme?.palette?.text?.white,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: 16,
  backgroundImage: 'url(/image/background/bg_dashboard_graph.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))
