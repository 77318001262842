import _ from 'lodash'

import { MonthlyFinancialItem } from './MonthlyFinancialItem'
import { shallowEqual, useSelector } from 'react-redux'
import { Box } from '@mui/material'

export const MonthlyFinancialList = ({ type }) => {
  const { budgetDetails } = useSelector(
    (state) => ({ budgetDetails: state.crud.formData.budgetDetails }),
    shallowEqual,
  )

  return (
    <Box display="flex" gap={3} flexWrap="wrap">
      {_.map(budgetDetails, (mb, idx) => (
        <MonthlyFinancialItem key={idx} {...mb} idxBudget={idx} type={type} />
      ))}
    </Box>
  )
}
