import React from 'react'
import Card from '@mui/material/Card'
import { useSelector, shallowEqual } from 'react-redux'

import Header from './Header'
import Question from './Question'
import Footer from './Footer'

const Index = ({ type }) => {
  const toggleQuestions = useSelector(
    (state) => state.eEvaluationForm.toggleQuestions[type],
    shallowEqual
  )

  return toggleQuestions ? (
    <Card sx={{ mb: 3 }}>
      <Header type={type} />
      <Question type={type} />
      <Footer type={type} />
    </Card>
  ) : (
    <></>
  )
}

export default Index
