import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setSearchText,
  setToggleFilterDrawer,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { fetchDataList } from './fetchDataList'
import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleListTableFiltering =
  (
    mutateFilterSateToProp,
    mutateFilterPropToBody,
    props,
    downloadName,
    tableHeadText,
  ) =>
  async (dispatch) => {
    const { filterState } = store.getState().table
    const isFilterDate =
      filterState.startDateChecked &&
      filterState.startDate != '' &&
      filterState.endDate != ''
    dispatch(setFieldValue({ key: 'isFilterDate', value: isFilterDate }))
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))
    const filterProp = mutateFilterSateToProp(props?.codeId ?? props?.uuid)
    if (props?.isDashboardListPage === true) {
      if (_.isEmpty(filterProp.status) === true) {
        filterProp.status = ['ACTIVE', 'INACTIVE']
      }
      if (_.isEmpty(filterProp.typeOfETesting) === true) {
        filterProp.typeOfETesting = ['ONLINE_TEST', 'ASSESSMENT', 'STANDALONE']
      }
    }
    dispatch(setFilterProp(filterProp))
    dispatch(
      setFilterTotal({
        ...filterState,
        startDateCheck: undefined,
        startDateStateChecked: filterState?.startDateState,
      }),
    )
    dispatch(resetTablePage())
    fetchDataList(
      mutateFilterPropToBody,
      { ...props },
      downloadName,
      tableHeadText,
    ).then()
  }
