import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxSection = styled(Box)(({ width = '100%' }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: width,
  gap: '4px',
}))

export const BoxContainer = styled(Box)(({ theme, noGap }) => ({
  display: 'flex',
  gap: '24px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: noGap ? '0px' : '24px',
  },
}))

export const StyledDot = styled(Box)(({ theme, status }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor:
    status == 'ACTIVE'
      ? theme?.palette?.success?.main
      : theme?.palette?.action?.disabled,
}))

export const InputSectionContainer = styled(Box)(({ theme, width }) => ({
  width: width,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
