import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import {
  RADIO_TYPE_ENUM,
  RADIO_TYPE_LABEL,
} from '../../../../../../../constants/eLearning'
import DatePicker from '../../../../../../../components/Input/DatePicker'
import HeadLabel from '../../../../../components/HeadLabel'
import { handleChange, handleChangeEvent } from '../../events'
import { onDateChange } from '../../Period/events'
import { StyledRadioGroup } from '../../Styled'
import { useInputField } from '../../../../../../../components/CRUD/hooks/useInputField'

const RadioCheckboxForm = () => {
  const dueDateKey = 'formData.dueDate'
  const dispatch = useDispatch()
  const { dueDateType, dueDate } = useSelector(
    (state) => ({
      dueDateType: state.crud.formData.dueDateType,
      dueDate: state.crud.formData.dueDate,
    }),
    shallowEqual,
  )

  const { setFieldError } = useFormikContext()
  const { isError: isDueDateError, message: dueDateErrorMessage } =
    useInputField('dueDate')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <HeadLabel text="ระยะเวลาแสดงบนเว็บไซต์" required />
      <StyledRadioGroup
        name="dueDateType"
        defaultValue={RADIO_TYPE_ENUM.NONE}
        value={dueDateType}
        onChange={(e) => {
          dispatch(handleChangeEvent(e))
          dispatch(handleChange(dueDateKey, null))
          if (!isDueDateError) setFieldError(dueDateKey, undefined)
        }}
      >
        <FormControlLabel
          value={RADIO_TYPE_ENUM.NONE}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.NONE}
        />
        <FormControlLabel
          value={RADIO_TYPE_ENUM.SPECIFIC_DATE}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.SPECIFIC_DATE}
        />
      </StyledRadioGroup>
      {dueDateType === RADIO_TYPE_ENUM.SPECIFIC_DATE && (
        <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
          <HeadLabel
            text="แสดง Collection นี้จนถึง"
            variant="body1"
            required
            sx={{ mt: 1 }}
          />
          <DatePicker
            boxSx={{ width: 'auto', minWidth: 180 }}
            sxTextError={{ mb: 0 }}
            id="dueDate"
            name="dueDate"
            disablePast
            hideDisableDateRange
            textError={dueDateErrorMessage}
            hideTextError={!isDueDateError}
            value={dueDate}
            onChange={(value) => dispatch(onDateChange(value, dueDateKey))}
          />
        </Box>
      )}
    </Box>
  )
}

export default RadioCheckboxForm
