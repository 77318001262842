import { CPageContainer } from '../../../../components/CRUD/components/PageContainer'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { getBodyMutation } from './mutation/get'
import Header from './Header'
import { setInitalValues } from './handler/setInitialValues'
import { BasicInfo } from './components/ฺBasicInfo'
import { VendorType } from './components/VendorType'
import { ServiceDescription } from './components/ServiceDescription'
import { ServicePeriod } from './components/ServicePeriod'
import InsideContact from './components/InsideContact'
import OutsideContact from './components/OutsideContact'
import { fetchDetail } from './handler/fetchDetail'

export const VendorManagementForm = () => {
  const { uuid } = useParams()
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  const initializeCrud = async () => {
    initialConfig(initialCrudConfig)
    await setInitalValues()
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation)
    }
  }

  useEffect(initializeCrud, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageContainer
        gap="3"
        header={<Header uuid={uuid} crudConfig={crudConfig} />}
        content={<BasicInfo />}
      />
      <CPageContainer gap="3" content={<VendorType />} />
      <CPageContainer gap="3" content={<ServiceDescription />} />
      <CPageContainer gap="3" content={<ServicePeriod />} />
      <CPageContainer gap="3" content={<InsideContact />} />
      <CPageContainer gap="3" content={<OutsideContact />} />
    </>
  )
}
