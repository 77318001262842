import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import UploadFileSharp from '@mui/icons-material/UploadFileSharp'

import { findSubQuestions } from '../../../../redux/slices/eEvaluationForm'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbForm, breadcrumbFormEdit } from '../../model'
import * as Styles from './Styled'
import MoreDropdownButton from './DropdownButton'
import _ from 'lodash'

import {
  checkScrollWidth,
  handleDisplayQuestion,
  handleImportTemplate,
} from './event'
import { path } from '../../../../constants/path'
import { onErrorValidate } from '../../../ETesting/Form/Header/event'
const Index = () => {
  const dispatch = useDispatch()

  const { id: uuid, createType } = useParams()
  const [isScrolled, setScroll] = useState(false)
  const [, setEEvaluation] = useLocalStorage('eEvaluation')
  const { setValues, errors } = useFormikContext()
  const [title, setTitle] = useState('')
  const {
    name,
    codeId,
    stateEEvaluation,
    isClone,
    displayQuestion,
    status,
    eEvaluationType,
    disable,
  } = useSelector(
    (state) => ({
      name: state.eEvaluationForm.information.name,
      codeId: state.eEvaluationForm.codeId,
      isClone: state.eEvaluationForm.isClone,
      stateEEvaluation: state.eEvaluationForm,
      displayQuestion: state.eEvaluationForm.setting.displayQuestion,
      status: state.eEvaluationForm.setting.status,
      eEvaluationType: state.eEvaluationForm.eEvaluationType,
      disable:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  const countSubQuestions = useSelector(findSubQuestions, shallowEqual)

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(errors))
      dispatch(onErrorValidate(!_.isEmpty(_.get(errors, 'information', {}))))
  }, [errors])

  useEffect(() => {
    if (!title) setTitle(name)
  }, [name])
  useEffect(() => {
    dispatch(
      handleDisplayQuestion({
        value: countSubQuestions ? 'ONE' : displayQuestion,
        key: 'displayQuestion',
      })
    )
  }, [stateEEvaluation])

  const titleLabelEva = `${
    uuid && !isClone
      ? 'แก้ไขแบบประเมิน'
      : createType && !uuid && !codeId
      ? 'สร้างแบบประเมิน'
      : 'สร้างเวอร์ชั่นใหม่'
  }`

  const cloneCondition =
    isClone || (status !== 'ACTIVE' && status !== 'WAITING')

  return (
    <>
      <Styles.BoxHeader scrolled={isScrolled}>
        <Styles.BoxLabelTitle
          isClone={cloneCondition}
          isEdit={uuid && !isClone}
        >
          <Styles.StyledViewTitle variant="h4">
            {uuid && !isClone ? `${titleLabelEva}: ${name}` : titleLabelEva}
          </Styles.StyledViewTitle>
          {!isScrolled && (
            <Breadcrumbs
              menuList={
                uuid || !!codeId
                  ? breadcrumbFormEdit(codeId, eEvaluationType, titleLabelEva)
                  : breadcrumbForm(eEvaluationType)
              }
            />
          )}
        </Styles.BoxLabelTitle>
        <Styles.BoxButton>
          <Button
            data-testid="btn-preview"
            variant="outlined"
            size="m"
            startIcon={<RemoveRedEye color="primary" />}
            onClick={() => {
              setEEvaluation(stateEEvaluation)
              const url = `${path.eEvaluation}/preview${uuid ? '/' + uuid : ''}`
              window.open(url, '_blank')
            }}
          >
            ดูตัวอย่าง
          </Button>

          {(!uuid || isClone) && (
            <>
              <label htmlFor="btn-import-template-eva">
                <Button
                  data-testid="btn-import-template-eva"
                  variant="outlined"
                  size="m"
                  component="span"
                  startIcon={<UploadFileSharp color="primary" />}
                >
                  นำเข้า template
                </Button>
                <Styles.InputImportEva
                  data-testid="btn-import-template-eva"
                  id="btn-import-template-eva"
                  accept=".xlsx, .xls, .csv"
                  type="file"
                  onChange={(dataImport) =>
                    dispatch(
                      handleImportTemplate({
                        dataImport,
                        questions: stateEEvaluation.questions,
                      })
                    )
                  }
                />
              </label>
            </>
          )}
          {cloneCondition && (
            <Button
              type="submit"
              data-testid="btn-draft"
              variant="outlined"
              size="m"
              disabled={disable}
              onClick={() => {
                setValues({ ...stateEEvaluation, isDraft: true })
              }}
            >
              บันทึกฉบับร่าง
            </Button>
          )}
          <Button
            type="submit"
            data-testid="btn-submit"
            variant="contained"
            size="m"
            disabled={disable}
            onClick={() => {
              setValues({ ...stateEEvaluation, isDraft: false })
            }}
          >
            บันทึกและเผยแพร่
          </Button>
          {uuid && !isClone && <MoreDropdownButton disabled={disable} />}
        </Styles.BoxButton>
      </Styles.BoxHeader>
    </>
  )
}
export default Index
