import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getDetail } from '../../../../services/eLearning/module/detail'
import {
  getCheckUploadStatus,
  postModuleCompleteMultiPart,
} from '../../../../services/eLearning/module/form'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import { initialState } from './model'
import { handleSetModuleBody, handleValidateButton } from './events'

const eLearningModuleForm = createSlice({
  name: 'eLearningModuleForm',
  initialState,
  reducers: {
    setInitialCreateForm: () => initialState,
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startOptionLoading: (state) => {
      state.isOptionLoading = true
    },
    stopOptionLoading: (state) => {
      state.isOptionLoading = false
    },
    startUploadLoading: (state) => {
      state.isUploadLoading = true
    },
    stopUploadLoading: (state) => {
      state.isUploadLoading = false
    },
    startVideoLoading: (state) => {
      state.isVideoLoading = true
    },
    stopVideoLoading: (state) => {
      state.isVideoLoading = false
    },
    setOption: (state, { payload }) => {
      state.option = payload
    },
    setDisableButton: (state, { payload }) => {
      state.isDisableButton = payload
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
    setTypeValue: (state, { payload }) => {
      const { key, value } = payload
      const oldObj = state.body[key]
      state.body[key] = { ...oldObj, ...value }
      handleValidateButton(state)
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)
      handleValidateButton(state)
    },
    setUploadValue: (state, { payload }) => {
      const { index, key, value } = payload

      if (_.isUndefined(key)) {
        if (state.body.uploadFile.length === 0) {
          state.body.uploadFile = [{ ...value }]
        } else {
          state.body.uploadFile[index] = value
        }
      } else {
        if (_.isNil(state.body.uploadFile[index])) {
          state.body.uploadFile[index] = { [key]: value }
        } else {
          state.body.uploadFile[index][key] = value
        }
      }
      handleValidateButton(state)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        handleSetModuleBody(state, payload.data)
        state.onGoing = _.get(payload.data, 'onGoing', false)
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postModuleCompleteMultiPart.fulfilled, (state, { payload }) => {
        const index = payload.index
        const data = _.get(payload, 'data', {})
        const obj = {
          uuid: _.get(data, 'uuid', ''),
          status: _.get(data, 'status', ''),
          percent: 100,
        }
        if (!_.isUndefined(index)) {
          const fileKey = `uploadFile[${index}]`
          const fileUploaded = _.get(state.body, fileKey, {})
          _.set(state.body, fileKey, { ...fileUploaded, ...obj })
        }
        state.uploadError = undefined
        state.isUploadSuccess = true
      })
      .addCase(postModuleCompleteMultiPart.rejected, (state, { payload }) => {
        const key = 'ERROR_UPLOAD_FILE'
        if (_.isUndefined(payload)) {
          _.set(state.body, 'uploadFile.error', key)
          state.isUploadSuccess = true
        } else _.set(state.body, `uploadFile[${payload}].error`, key)
      })
      .addCase(getCheckUploadStatus.fulfilled, (state, { payload }) => {
        const moduleType = _.get(state.body, 'moduleType', '')
        const status = _.get(payload, 'status', '')

        if (status !== 'SUCCESS') return

        if (moduleType === MODULE_TYPE.UPLOAD_FILE) {
          const uuid = _.get(payload, 'uuid', '')
          const uploadFile = _.get(state.body, 'uploadFile', [])
          const index = uploadFile.findIndex((item) => item.uuid === uuid)
          if (index !== -1) {
            const obj = {
              uuid,
              status,
              fileName: _.get(payload, 'fileName', []),
              file: _.get(payload, 'file', ''),
            }
            const fileKey = `uploadFile[${index}]`
            _.set(state.body, fileKey, { ...uploadFile[index], ...obj })
          }
        } else {
          const key = 'scorm'
          const scorm = _.get(state.body, key, '')
          _.set(state.body, key, { ...scorm, status })
          _.set(state.body, 'eLearningModuleFileUpload', [payload])
        }
      })
      .addCase(getCheckUploadStatus.rejected, (state, { payload }) => {
        state.error = payload
      })
  },
})

export const {
  setInitialCreateForm,
  startLoading,
  stopLoading,
  startOptionLoading,
  stopOptionLoading,
  startUploadLoading,
  stopUploadLoading,
  startVideoLoading,
  stopVideoLoading,
  setOption,
  setDisableButton,
  setBody,
  setTypeValue,
  setFieldValue,
  setUploadValue,
} = eLearningModuleForm.actions

export default eLearningModuleForm.reducer
