export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return [r, g, b]
}

export const rgbToHex = (r, g, b) => {
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  )
}

// Helper function to interpolate between two colors
export const interpolateColor = (color1, color2, factor) => {
  if (typeof factor === 'undefined') {
    factor = 0.5
  }
  const result = color1.slice()
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
  }
  return result
}

// Function to generate the gradient from predefined colors
export const generateGradientColors = (arrayLength) => {
  // Predefined set of colors matching your example
  const predefinedColors = [
    '#B2DFDB',
    '#C8E6C9',
    '#DCEDC8',
    '#F0F4C3',
    '#FFF9C4',
    '#FFECB3',
    '#FFE0B2',
    '#FFCCBC',
    '#FFCDD2',
    '#F8BBD0',
  ]

  const colorArray = []
  const stepFactor = (predefinedColors.length - 1) / (arrayLength - 1)

  for (let i = 0; i < arrayLength; i++) {
    const idx = Math.floor(i * stepFactor)
    const remainder = (i * stepFactor) % 1
    const color1 = hexToRgb(predefinedColors[idx])
    const color2 = hexToRgb(
      predefinedColors[Math.min(idx + 1, predefinedColors.length - 1)],
    )
    const interpolatedColor = rgbToHex(
      ...interpolateColor(color1, color2, remainder),
    )
    colorArray.push(interpolatedColor)
  }

  return colorArray
}
