import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import {
  fetchETestingList,
  fetchETestingTypeList,
  fetchEEvaluationList,
  handleDeleteSet,
  handleTypeRadioChange,
  handleModifyOption,
  handleInactiveOption,
} from './events'
import { StyledETestingForm } from './Styled'
import ETestingForm from './ETestingForm'
import EEvaluationForm from './EEvaluationForm'

const TestAndEvaluateForm = ({
  index,
  testAndEvaArr,
  setInitialValues,
  formik,
}) => {
  const [testAndEvaType, setTestAndEvaType] = useState('E_TESTING')
  const [eTestingTypeOption, setETestingTypeOption] = useState([])
  const [eTestOptionProps, setETestOptionProps] = useState({
    activeOption: [],
    inactiveOption: [],
    toggle: false,
  })
  const [eEvaOptionProps, setEEvaOptionProps] = useState({
    activeOption: [],
    inactiveOption: [],
    toggle: false,
  })
  const [eTestOptionState, setETestOptionState] = useState([])
  const [eEvaOptionState, setEEvaOptionState] = useState([])
  const setStateProps = { setInitialValues, setTestAndEvaType }
  const testAndEvaProps = { testAndEvaArr, index }
  const testAndEva = testAndEvaArr[index]
  const optionProps = { eTestOptionProps, eEvaOptionProps }
  const setOptionProps = { setETestOptionProps, setEEvaOptionProps }

  useEffect(() => {
    fetchETestingTypeList(setETestingTypeOption)
    fetchETestingList(testAndEva, setETestOptionProps)
    fetchEEvaluationList(testAndEva, setEEvaOptionProps)
  }, [])

  useEffect(() => {
    setTestAndEvaType(
      _.get(testAndEvaArr[index], 'testAndEvaluateType', 'E_TESTING')
    )
    handleInactiveOption(testAndEva, optionProps, setOptionProps)
  }, [testAndEvaArr])

  useEffect(() => {
    handleModifyOption(testAndEvaProps, eTestOptionProps, setETestOptionState)
  }, [eTestOptionProps])

  useEffect(() => {
    handleModifyOption(testAndEvaProps, eEvaOptionProps, setEEvaOptionState)
  }, [eEvaOptionProps])

  return (
    <StyledETestingForm>
      <Box id="box-title">
        <Typography variant="body1b">ชุดที่ {index + 1}</Typography>
        <IconButton
          data-testid="btn-delete"
          onClick={() =>
            handleDeleteSet(testAndEvaProps, setInitialValues, formik)
          }
        >
          <DeleteTwoTone />
        </IconButton>
      </Box>
      <Box id="box-content">
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.secondary">
            ประเภท <span>*</span>
          </Typography>
          <RadioGroup
            data-testid="radio-setTestAndEvaType"
            sx={{ pl: 1.5, gap: 0.5 }}
            aria-label="setTestAndEvaType"
            name="setTestAndEvaType"
            value={_.get(testAndEva, 'testAndEvaluateType', 'E_TESTING')}
            onChange={(e) =>
              handleTypeRadioChange(e, testAndEvaProps, setStateProps)
            }
          >
            <FormControlLabel
              value="E_TESTING"
              control={<Radio />}
              label="แบบทดสอบ"
            />
            <FormControlLabel
              value="E_EVALUATION"
              control={<Radio />}
              label="แบบประเมิน"
            />
          </RadioGroup>
        </Box>
        {testAndEvaType === 'E_TESTING' ? (
          <ETestingForm
            testAndEvaProps={testAndEvaProps}
            setInitialValues={setInitialValues}
            options={{
              eTestingOption: eTestOptionState,
              eTestingTypeOption,
            }}
            formik={formik}
          />
        ) : (
          <EEvaluationForm
            testAndEvaProps={testAndEvaProps}
            setInitialValues={setInitialValues}
            options={eEvaOptionState}
            formik={formik}
          />
        )}
      </Box>
    </StyledETestingForm>
  )
}

export default TestAndEvaluateForm
