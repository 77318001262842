import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const StyledDateItemBox = styled(Box)(({ theme, isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: isSelected ? theme.palette?.common?.white : 'none',
  borderRadius: isSelected ? theme.spacing(1) : 'unset',
  padding: `${theme.spacing(1.5)}`,
  paddingTop: `${theme.spacing(0.5)}`,
  [theme.breakpoints.down('460')]: {
    padding: `${theme.spacing(1)}`,
    paddingTop: `${theme.spacing(0.5)}`,
  },
}))
