import dayjs from 'dayjs'
import _ from 'lodash'
import { RESPONSIBLE_TYPE } from '../../../../../../redux/slices/eLearning/courseForm/model'
import { handleStaffLabelValue } from '../../../../../../redux/slices/eLearning/courseForm/events'
import {
  closeDialog,
  openDialog,
  loadingDialog,
  stopLoadingDialog,
} from '../../../../../../redux/slices/dialog'
import { startLoading } from '../../../../../../redux/slices/table'
import {
  startLoading as startLoadingBatch,
  stopLoading,
} from '../../../../../../redux/slices/eLearning/courseForm'
import {
  setBatchData,
  setOpenDrawer,
} from '../../../../../../redux/slices/eLearning/courseForm'
import {
  postCreateBatch,
  postGetAllCourseBatch,
  putEditBatch,
} from '../../../../../../services/eLearning/course/form'
import { COURSE_STATUS } from '../../../../../../constants/eLearning'
import { store } from '../../../../../../App'
import { getConfigState } from '../../../../events'
import { fetchELearningCourseBatch } from '../../BatchTab/View/events'
import { getTableState, handleBatchTable, resetTabelState } from '../events'

export const fetchBatchList = (uuid, isDrawerOpen) => async (dispatch) => {
  if (!isDrawerOpen) return

  dispatch(startLoadingBatch())
  await dispatch(postGetAllCourseBatch(uuid))
  dispatch(stopLoading())
}

export const getStartAfter = () => {
  const startAfter = getConfigState('EL_BATCH_START_AFTER_CLOSE_REGISTER') || 0
  return Number(startAfter)
}

export const handleInitialData = (batchData, id, uuid) => {
  const isEdit = !_.isEmpty(batchData)
  let eLearningCourse = { id, uuid }
  if (isEdit) {
    eLearningCourse = {
      id: _.get(batchData, 'eLearningCourse.id', ''),
      uuid: _.get(batchData, 'eLearningCourse.uuid', ''),
    }
  }
  return {
    name: _.get(batchData, 'name', ''),
    eLearningCourse: eLearningCourse,
    uuid: isEdit ? _.get(batchData, 'uuid', '') : undefined,
    periodStart: new Date(_.get(batchData, 'periodStart', new Date())),
    periodEnd: new Date(_.get(batchData, 'periodEnd', new Date())),
    openRegisterDate: _.get(batchData, 'openRegisterDate', ''),
    endRegisterDate: _.get(batchData, 'endRegisterDate', ''),
    openRegisterTime: _.get(batchData, 'openRegisterTime', ''),
    endRegisterTime: _.get(batchData, 'endRegisterTime', ''),
    display: isEdit,
    onShelfStatus: _.get(batchData, 'onShelfStatus', 'ACTIVE'),
    eLearningCourseBatchResponsible: handleDefaultChipValue(),
    speaker: handleDefaultChipValue(RESPONSIBLE_TYPE.TRAINER),
    admin: handleDefaultChipValue(RESPONSIBLE_TYPE.ADMIN),
    trainerQuestion: handleDefaultChipValue(RESPONSIBLE_TYPE.TRAINERQUESTION),
    status: _.get(batchData, 'status', ''),
  }
}

export const handleSubmit = (values) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึก',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirmSubmit(values)),
    }),
  )
}

export const handleConfirmSubmit = (values) => async (dispatch) => {
  const tableState = getTableState()
  const sort = _.get(tableState.table, 'sort', 'updatedAt')
  const admin = handleBatchResponsible(_.get(values, 'admin', []))
  const speaker = handleBatchResponsible(_.get(values, 'speaker', []))
  const trainerQuestion = handleBatchResponsible(
    _.get(values, 'trainerQuestion', []),
  )
  const periodStart = handleSubmitDate(_.get(values, 'periodStart', ''))
  const periodEnd = handleSubmitDate(_.get(values, 'periodEnd', ''))
  const openRegisterDate = _.get(values, 'openRegisterDate', '')
  const openRegisterTime = _.get(values, 'openRegisterTime', '')
  const endRegisterDate = _.get(values, 'endRegisterDate', '')
  const endRegisterTime = _.get(values, 'endRegisterTime', '')
  const isDraft = values.status === COURSE_STATUS.DRAFT
  const status = isDraft ? COURSE_STATUS.DRAFT : handleStatus(values)

  const body = {
    uuid: values.uuid || undefined,
    name: _.get(values, 'name', ''),
    periodStart,
    periodEnd,
    openRegisterDate,
    openRegisterTime,
    endRegisterDate,
    endRegisterTime,
    onShelfStatus: _.get(values, 'onShelfStatus', ''),
    eLearningCourse: _.get(values, 'eLearningCourse', {}),
    eLearningCourseBatchResponsible: _.concat(admin, speaker, trainerQuestion),
    status,
  }

  if (window.location.href.includes('edit')) {
    dispatch(handleCloseDrawer())
    dispatch(closeDialog())
    dispatch(startLoading())
    if (values.uuid) {
      await dispatch(putEditBatch(body))
    } else {
      body.status = COURSE_STATUS.DRAFT
      await dispatch(postCreateBatch(body))
    }
    dispatch(resetTabelState())
    if (sort === 'updatedAt') dispatch(handleBatchTable())
  } else {
    dispatch(handleCloseDrawer())
    dispatch(loadingDialog())
    await dispatch(putEditBatch(body))
    await dispatch(fetchELearningCourseBatch(body.uuid))
    dispatch(stopLoadingDialog())
    dispatch(closeDialog())
  }
}

export const handleStatus = (values) => {
  const periodStart = handleSubmitDate(_.get(values, 'periodStart', ''))
  const periodEnd = handleSubmitDate(_.get(values, 'periodEnd', ''))
  const openRegisterDate = _.get(values, 'openRegisterDate', '')
  const openRegisterTime = _.get(values, 'openRegisterTime', '')
  const endRegisterDate = _.get(values, 'endRegisterDate', '')
  const endRegisterTime = _.get(values, 'endRegisterTime', '')

  const currentDateTime = dayjs(new Date())
  const openDateTime = dayjs(`${openRegisterDate} ${openRegisterTime}`)
  if (openDateTime.isSameOrBefore(currentDateTime)) {
    return COURSE_STATUS.OPENREGISTRATION
  }

  const endDateTime = dayjs(`${endRegisterDate} ${endRegisterTime}`)
  if (currentDateTime.isAfter(endDateTime)) {
    return COURSE_STATUS.CLOSEREGISTRATION
  }

  const now = handleSubmitDate()
  if (now >= periodStart && now <= periodEnd) {
    return COURSE_STATUS.INPROGRESS
  }

  return values.status
}

export const handleSubmitDate = (dateStr) => {
  const date = _.isNil(dateStr) ? new Date() : new Date(dateStr)
  return date.toJSON().slice(0, 10)
}

export const handleBatchResponsible = (list) => {
  return _.map(list, (item) => ({
    uuid: item.assigneeUuid,
    type:
      item.type === RESPONSIBLE_TYPE.SPEAKER
        ? RESPONSIBLE_TYPE.TRAINER
        : item.type,
    staff: { uuid: item.value },
  }))
}

export const handleDefaultChipValue = (type) => {
  const state = store.getState()
  const batchData = _.get(state, 'eLearningCourseForm.batchData', {})
  const adminStaffList = _.get(state, 'eLearningCourseForm.adminStaffList', [])
  const speakerList = _.get(state, 'eLearningCourseForm.speakerList', [])
  const trainerQuestionList = _.get(
    state,
    'eLearningCourseForm.trainerQuestionList',
    [],
  )

  const courseBatchResponsible = _.get(
    batchData,
    'eLearningCourseBatchResponsible',
    [],
  )

  if (_.isNil(type)) return courseBatchResponsible
  return courseBatchResponsible
    .filter((item) => item.type === type)
    .map((item) => {
      let option = {}
      if (type === RESPONSIBLE_TYPE.ADMIN) {
        option = handleStaffObj(adminStaffList, item)
      } else if (type === RESPONSIBLE_TYPE.TRAINERQUESTION) {
        option = handleStaffObj(trainerQuestionList, item)
      } else {
        option = handleStaffObj(speakerList, item)
      }
      return { ...option, assigneeUuid: item.uuid, type }
    })
}

export const handleStaffObj = (list, item) => {
  const admin = list.find((option) => option.value === item.staff.uuid)
  if (admin) return admin
  else return handleStaffLabelValue(item.staff)
}

export const handleCloseDrawer = () => (dispatch) => {
  dispatch(setOpenDrawer(false))
  const isBatchViewPath = window.location.href.includes('/batch/view')
  if (!isBatchViewPath) dispatch(setBatchData({}))
}

export const handleGetAssignees = () => {
  const isOnEditPage = window.location.pathname.includes('edit')
  const {
    eLearningCourseBatchDetail: { result },
    eLearningCourseForm: { body },
  } = store.getState()

  if (isOnEditPage) return _.get(body, 'setting.assignees', [])
  return _.get(result, 'eLearningCourse.setting.assignees', [])
}
