import React, { useEffect, useRef } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import { resetErrorUpload } from '../../../../../../redux/slices/manageContent/elearning'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import Editor from '../../../../../../components/Editor/BasicEditor'
import { StyledColumn } from '../../../Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledFooter,
} from '../../Styled'
import { handleEditorBlur, handleSubmitData } from './events'
import ButtonForm from './ButtonForm'
import BannerMediaForm from './BannerMediaForm'
import { Form, Formik } from 'formik'
import { schema } from './schema'

const BannerDrawer = ({ data, isOpen, onCloseDrawer }) => {
  const subEditorRef = useRef(null)
  const titleEditorRef = useRef(null)
  const describeEditorRef = useRef(null)
  const distributionChannels = useSelector(
    (state) => state.manageContentELearningHome.options.distributionChannels,
    shallowEqual,
  )
  const no = _.get(data, 'no', 1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetErrorUpload())
  }, [])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Formik
        initialValues={data}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          onCloseDrawer()
          dispatch(handleSubmitData(values, no))
        }}
      >
        {({ values, errors, setValues }) => (
          <Form>
            <StyledContent>
              <StyledHeadLabel>
                <Box id="header">
                  <Typography variant="h5">Banner {no}</Typography>
                </Box>
                <Divider />
              </StyledHeadLabel>
              <StyledColumn sx={{ width: 'auto', m: 3 }}>
                <BannerMediaForm />

                <BannerEditor
                  label="Subtitle"
                  textInfo="แนะนำระบุข้อความความยาวไม่เกิน 40 ตัวอักษร"
                  editorRef={subEditorRef}
                  value={_.get(values, 'subtitle', '')}
                  onBlur={() =>
                    handleEditorBlur(subEditorRef, 'subtitle', setValues)
                  }
                  textError={_.get(errors, 'subtitle', '')}
                  showMaxLength={255}
                />
                <BannerEditor
                  label="Title"
                  textInfo="แนะนำระบุข้อความความยาวไม่เกิน 40 ตัวอักษร"
                  editorRef={titleEditorRef}
                  value={_.get(values, 'title', '')}
                  onBlur={() =>
                    handleEditorBlur(titleEditorRef, 'title', setValues)
                  }
                  textError={_.get(errors, 'title', '')}
                  showMaxLength={255}
                />
                <BannerEditor
                  label="Description"
                  textInfo="ระบุข้อความความยาวไม่เกิน 160 ตัวอักษร"
                  editorRef={describeEditorRef}
                  value={_.get(values, 'description', '')}
                  onBlur={() =>
                    handleEditorBlur(
                      describeEditorRef,
                      'description',
                      setValues,
                    )
                  }
                  textError={_.get(errors, 'description', '')}
                  showMaxLength={230}
                />

                <ButtonForm />

                <SelectGroupChip
                  required
                  name="distributionChannels"
                  labelText="Distribution Channel"
                  placeholder="กรุณาเลือก"
                  options={distributionChannels}
                  value={_.get(values, 'distributionChannels', [])}
                  textError={_.get(errors, 'distributionChannels', '')}
                  onChange={(distributionChannels) =>
                    setValues((val) => ({ ...val, distributionChannels }))
                  }
                />
              </StyledColumn>
              <StyledFooter>
                <Button
                  data-testId="btn-cancel"
                  variant="outlined"
                  onClick={() => {
                    onCloseDrawer()
                    setValues(data)
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  data-testId="btn-submit"
                  variant="contained"
                  type="submit"
                >
                  ตกลง
                </Button>
              </StyledFooter>
            </StyledContent>
          </Form>
        )}
      </Formik>
    </StyledDrawer>
  )
}

export default BannerDrawer

export const BannerEditor = (props) => {
  const {
    label,
    textInfo,
    editorRef,
    textError,
    onBlur,
    onChange,
    value,
    showMaxLength,
    isTextLefe,
    isChange,
    isPopperColor,
    onChangeColor,
    colorValue,
    isPasteAsText = true,
  } = props
  return (
    <Editor
      labelText={label}
      id={`editor-${label}`}
      name={label}
      editorRef={editorRef}
      toolbar="undo redo"
      height={label == 'Description' ? 100 : 78}
      textInfo={textInfo}
      textError={textError}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      showMaxLength={showMaxLength}
      isTextLefe={isTextLefe}
      isChange={isChange}
      isPopperColor={isPopperColor}
      colorValue={colorValue}
      onChangeColor={onChangeColor}
      isPasteAsText={isPasteAsText}
    />
  )
}
