import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledDialog, StyledErrorContainer, StyledErrorRow } from './Styled'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

const ErrorCourseDialog = ({ errorList }) => {
  const coursesInformation = useSelector(
    (state) => _.get(state.manageContentELearningHome.data, 'coursesInformation', []),
    shallowEqual
  )
  const errorTexts = coursesInformation
    .filter((course) => errorList.some((error) => course.uuid == error))
    .map(
      (course) => `${course.level.title} : ${course.distribution.abbreviation}`
    )

  return (
    <StyledDialog>
      <Typography sx={{ pl: 1.5 }}>
        เกิดข้อผิดพลาดในการบันทึกข้อมูลดังนี้ กรุณาตรวจสอบอีกครั้ง
      </Typography>
      <StyledErrorContainer>
        <StyledErrorRow header>
          <Typography variant="tableHeader">Section 5 | Course</Typography>
        </StyledErrorRow>
        {errorTexts.map((errorText, index) => (
          <StyledErrorRow key={index}>{errorText}</StyledErrorRow>
        ))}
      </StyledErrorContainer>
    </StyledDialog>
  )
}

export default ErrorCourseDialog
