import React, { useEffect, useState } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import Button from '@mui/material/Button'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import Breadcrumbs from '../../../../../../components/Breadcrumbs'
import { checkScrollWidth } from '../../handler/checkScrollWidth'
import { BoxButton, BoxHeader, BoxLabelTitle } from './Styled'
import Typography from '@mui/material/Typography'
import { handleSubmitClick } from '../../../../../../components/CRUD/handler/handleSubmitClick'
import { postBodyMutation } from '../../mutation/post'
import { schemaDraft } from '../../schema/schemaDraft'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createContentBreadcrumbList } from '../../model/breadcrumb'
import { schemaSubmit } from '../../schema/schemaSubmit'
import { useParams } from 'react-router-dom'
import { postSubmitBodyMutation } from '../../mutation/postSubmit'
import { checkPreviewButton } from '../../handler/checkPreviewButton'
import { handlePreview } from '../../handler/handlePreview'

const Header = () => {
  const { uuid } = useParams()
  const [, setEContentPreview] = useLocalStorage('eContentPreview')
  const dispatch = useDispatch()
  const [isScrolled, setScroll] = useState(false)
  const [previewDisabled, setPreviewDisabled] = useState(true)
  const formData = useSelector((state) => state.crud.formData, shallowEqual)
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
      setScroll(false)
    }
  }, [])
  useEffect(async () => {
    setPreviewDisabled(await checkPreviewButton(formData))
  }, [formData])
  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle>
        <Typography variant="h4">
          {uuid ? 'แก้ไข คลังความรู้' : 'สร้าง คลังความรู้'}
        </Typography>
        {!isScrolled && (
          <Breadcrumbs menuList={createContentBreadcrumbList(uuid)} />
        )}
      </BoxLabelTitle>
      <BoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          size="m"
          startIcon={<RemoveRedEye />}
          disabled={previewDisabled}
          onClick={() => handlePreview(setEContentPreview)}
        >
          ดูตัวอย่าง
        </Button>

        {formData.status !== 'READY' &&
          formData.status !== 'READYINACTIVE' &&
          formData.status !== 'DELETED' && (
            <Button
              type="button"
              data-testid="btn-draft"
              variant="outlined"
              size="m"
              onClick={async () => {
                dispatch(
                  setFieldValue({ key: 'formData.status', value: 'DRAFT' }),
                )
                handleSubmitClick(
                  null,
                  postBodyMutation,
                  schemaDraft,
                  'ยืนยันบันทึกฉบับร่าง',
                  'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่',
                )
              }}
            >
              บันทึกฉบับร่าง
            </Button>
          )}

        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          onClick={async () => {
            handleSubmitClick(
              uuid,
              postSubmitBodyMutation,
              schemaSubmit,
              'ยืนยันบันทึกและเผยแพร่',
              'คุณต้องการบันทึกและเผยแพร่หรือไม่',
            )
          }}
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}

export default Header
