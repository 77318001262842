import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { fetchCategory } from '../../../SubCategory/Form/handler/fetchCategory'
import { fetchAllDistributionCh } from './fetchAllDistributionCh'
import { fetchAssignees } from './fetchAssignees'
import { validatePermission } from '../../../../../utils/lib'

export const initialForm = async (user) => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const options = getContentTypeOption(user)
  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {
        category: { isLoading: false, options: [] },
        assignees: { isLoading: false, options: [] },
        subCategory: { isLoading: false, options: [] },
        distributionCh: { isLoading: false, options: [] },
        levelOfLearner: { isLoading: false, options: [] },
        eContentTag: { isLoading: false, options: [] },
        contentType: {
          isLoading: false,
          options: options,
        },
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'tabs',
      value: {
        content: ['name', 'category', 'subCategory', 'coverImage', 'contents'],
        setting: [
          'distributionCh',
          'levelOfLearner',
          'eContentTag',
          'startPublishDate',
          'endPublishDate',
        ],
      },
    }),
  )
  await fetchCategory()
  await fetchAssignees()
  await fetchAllDistributionCh()
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )

  return true
}

const getContentTypeOption = (user) => {
  const hasPermissionAll = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission: ['CREATE', 'EDIT'],
  })
  const hasPermissionEContent = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission: ['CREATE_ECONTENT', 'EDIT_ECONTENT'],
  })
  const hasPermissionKB = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission: ['CREATE_KB', 'EDIT_KB'],
  })
  const options = []
  if (hasPermissionAll || hasPermissionEContent) {
    options.push({
      label: 'บทความ',
      value: 'E_CONTENT',
    })
  }
  if (hasPermissionAll || hasPermissionKB) {
    options.push({
      label: 'สาระน่ารู้',
      value: 'KB',
    })
  }
  return options
}
