import React, { useState } from 'react'
import _ from 'lodash'
import * as service from '../../../../../services/eCertiticate/form'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/eCertificateForm'
import {
  setDisableButton,
  setEnableButton,
  loadingDialog,
  closeDialog,
  openDialog,
} from '../../../../../redux/slices/dialog'
import { useDispatch } from 'react-redux'

import { DialogDelete } from './Styled'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import { eCertificationVersion } from '../../../../../utils/apiPath'
import * as reducer from '../../../../../redux/slices/eCertificateForm'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
    return
  }
  setOpen(false)
}

export const handleMoreMenu = (method, uuid, history) => (dispatch) => {
  switch (method) {
    case 'delete':
      dispatch(handleDeleteVersion(uuid))
      break
    case 'clone':
      history.push(`${eCertificationVersion}/clone/${uuid}`)
      break
    default:
      break
  }
}

export const handleDeleteVersion = (uuid) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบข้อมูล',
      type: 'content',
      content: <DialogContentDelete />,
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      disableButton: true,
      handleConfirm: () => dispatch(submitDeleteVersion(uuid)),
    })
  )
}

export const handleEditVersion = (props) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'บันทึกและเผยแพร่การแก้ไข',
      type: 'content',
      content: <DialogContentEdit />,
      disableButton: true,
      handleConfirm: () => dispatch(submitEditVersion(props)),
    })
  )
}

export const submitEditVersion = (props) => async (dispatch) => {
  const {
    uuid,
    formReasonEdit,
    formCodeId,
    formName,
    formLayout,
    formBackground,
    assignees,
  } = props

  const body = {
    uuid: uuid,
    name: formName,
    layout: formLayout,
    eCertificationBackground: formBackground?.uuid ?? null,
    editReason: formReasonEdit,
    codeId: formCodeId,
    assignees: assignees ?? [],
  }

  dispatch(startLoading())
  dispatch(loadingDialog())
  const result = await dispatch(service.submitFormCertificateVersion(body))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        handleConfirm: () => {
          window.location.href = `${eCertificationVersion}/${_.get(
            result,
            'payload.data',
            ''
          )}`
          dispatch(closeDialog())
          dispatch(reducer.setClearData())
        },
      })
    )
  }
  dispatch(stopLoading())
}

export const DialogContentEdit = () => {
  const dispatch = useDispatch()
  const [toggleEdit, setToggleEdit] = useState(false)

  return (
    <DialogDelete>
      <Typography variant="body1">
        คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="primary"
              checked={toggleEdit}
              onChange={(e) => dispatch(handleToggleEdit(e, setToggleEdit))}
            />
          }
          label="มีผลย้อนหลังกับผู้ที่เคยได้รับประกาศนียบัตรนี้ไปแล้ว"
        />
      </Box>
    </DialogDelete>
  )
}

export const DialogContentDelete = () => {
  const dispatch = useDispatch()
  const [toggleDelete, setToggleDelete] = useState(false)

  return (
    <DialogDelete>
      <Typography variant="body1">
        การลบข้อมูล จะ
        <span style={{ color: 'red' }}>ไม่มีผลย้อนหลัง </span>
        กับหลักสูตร หรือคลาสที่เลือกไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
        ยังคงสามารถใช้งานต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง ที่หน้าจัดการหลักสูตร
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="error"
              checked={toggleDelete}
              onChange={(e) => dispatch(handleToggleDelete(e, setToggleDelete))}
            />
          }
          label="ฉันรับทราบเงื่อนไขการลบข้อมูลแล้ว"
        />
      </Box>
    </DialogDelete>
  )
}

export const handleToggleEdit = (event, setToggleDelete) => (dispatch) => {
  setToggleDelete((prevToggle) => !prevToggle)
  const checked = event.target.checked
  !checked ? dispatch(setDisableButton()) : dispatch(setEnableButton())
}

export const handleToggleDelete = (event, setToggleDelete) => (dispatch) => {
  setToggleDelete((prevToggle) => !prevToggle)
  const checked = event.target.checked
  !checked ? dispatch(setDisableButton()) : dispatch(setEnableButton())
}

export const submitDeleteVersion = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())

  const result = await dispatch(service.deleteCertificateVersion(uuid))
  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  dispatch(stopLoading())
  dispatch(closeDialog())
  if (payload === 'fulfilled') {
    window.location.href = `${eCertificationVersion}/${_.get(
      result,
      'payload.data',
      ''
    )}`
  }
}
