import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'
import { StatusComponent } from '../../../../components/CRUD/components/StatusComponent'
import { Typography, Box } from '@mui/material'
import _ from 'lodash'
import { convertFormatDateTime } from '../../../../utils/lib'
import { InsideContactInfo } from './components/InsideContactInfo'
import { OutsideContactInfo } from './components/OutsideContactInfo'
import dayjs from 'dayjs'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  const startDate = convertFormatDateTime({
    value: _.get(formData, 'startDate', '-'),
    type: 'date',
  })
  const endDate = convertFormatDateTime({
    value: _.get(formData, 'endDate', '-'),
    type: 'date',
  })
  const renewDate = convertFormatDateTime({
    value: _.get(formData, 'renewDate', '-'),
    type: 'date',
  })
  const insideContacts = _.get(formData, 'insideContacts', [])
  const outsideContacts = _.get(formData, 'outsideContacts', [])

  const convertJoinDate = (start, end) => {
    let workStart = ''
    const startDate = dayjs(new Date(start))
    const endDate = dayjs(new Date(end))
    const years = endDate.diff(startDate, 'year')
    const months = endDate.diff(startDate, 'month') - years * 12
    const days = endDate.diff(
      startDate.add(years, 'year').add(months, 'month'),
      'day',
    )

    if (years > 0) {
      workStart += `${years} ปี `
    }
    if (months > 0) {
      workStart += `${months} เดือน `
    }
    if (days > 0) {
      workStart += `${days} วัน`
    }
    return workStart
  }

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <Box pl={3}>
        <ViewRow
          variant="body1"
          title="ชื่อ Vendor"
          value={_.get(formData, 'name', '-')}
        />
        <ViewRow
          variant="body1"
          title="ID"
          value={_.get(formData, 'id', '-')}
        />
        <ViewRow
          variant="body1"
          title="Station"
          value={_.get(formData?.station, 'station', '-')}
        />
        <ViewRow
          variant="body1"
          title="สถานะ"
          valueComponent={StatusComponent(formData)}
        />
        <Box display="flex" flexDirection="column">
          <Typography sx={{ minWidth: 330 }} variant="body1">
            หมายเหตุ
          </Typography>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            {formData?.annotation ? formData?.annotation : '-'}
          </Typography>
        </Box>
      </Box>

      <Typography variant="h6">
        รายละเอียดของบริการ (Service Description)
      </Typography>
      <Box
        pl={3}
        sx={{
          minWidth: 330,
          '& *': { whiteSpace: 'normal', mt: 0, mb: '5px' },
        }}
        whiteSpace={'normal'}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: _.get(formData, 'serviceDescription', '-'),
          }}
        />
      </Box>

      <Typography variant="h6">ระยะสัญญาการบริการ</Typography>
      <Box pl={3}>
        <ViewRow
          variant="body1"
          title="วันที่เริ่มต้น"
          value={startDate ?? '-'}
        />
        <ViewRow variant="body1" title="วันที่สิ้นสุด" value={endDate ?? '-'} />
        <ViewRow
          variant="body1"
          title="วันที่ต่อสัญญา"
          value={renewDate ?? '-'}
        />
        <Divider sx={{ mt: 2 }}></Divider>
        <ViewRow
          variant="body1b"
          title="สรุประยะเวลา"
          value={convertJoinDate(formData?.startDate, formData?.endDate)}
          boxSx={{ mt: 2 }}
        />
      </Box>

      <Typography variant="h6">การติดต่อภายใน</Typography>
      {insideContacts &&
        insideContacts.map((item, index) => {
          return <InsideContactInfo key={index} item={item} index={index} />
        })}

      {outsideContacts && outsideContacts?.length > 0 && (
        <>
          <Typography variant="h6">การติดต่อภายนอก</Typography>
          {outsideContacts &&
            outsideContacts.map((item, index) => {
              return (
                <OutsideContactInfo key={index} item={item} index={index} />
              )
            })}
        </>
      )}

      <Divider />
      <UpdatedComponent />
    </>
  )
}
