export const breadcrumbMeetingRoom = () => [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ห้องประชุม', link: '/', pointer: false },
]

export const breadcrumbMeetingRoomView = () => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ห้องประชุม',
      link: '/',
      pointer: true,
    },
    {
      title: 'รายละเอียดห้องประชุม',
      link: '/',
      pointer: false,
    },
  ]
}