export const countTotal = (props) => {
  const {
    courseAssessments,
    courseManagementAssessments,
    lecturerAssessments,
    questions,
  } = props

  const totalCourse = courseAssessments.length
    ? courseAssessments[0].questions.length
    : 0
  const totalManagement = courseManagementAssessments.length
    ? courseManagementAssessments[0].questions.length
    : 0
  const totalLecturer = lecturerAssessments.length
    ? lecturerAssessments[0].questions.length
    : 0
  const totalQuestions = questions.length ? questions.length : 0

  return totalCourse + totalManagement + totalLecturer + totalQuestions
}

export const countQuestions = (item) => {
  let result = 0
  for (const keyItem in item) {
    if (item[keyItem]) result = result + 1
  }
  return result
}
