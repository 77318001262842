import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import _ from 'lodash'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const Footer = () => {
  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual
  )
  return (
    <>
      <Card
        sx={{ padding: '24px', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Typography variant="caption" color="text.lightGray">
          {_.get(data, 'updated', '')}
        </Typography>
      </Card>
    </>
  )
}

export default Footer
