import {
  setFieldValue,
  setChange,
  startLoading,
  stopLoading,
  setInitialCreateForm,
  setEditForm,
} from '../../../redux/slices/learningPoint/catalog'
import _ from 'lodash'
import {
  getDetail,
  postCreateCatalog,
  putUpdateCatalog,
} from '../../../services/learningPoint/catalog'
import { headerConfig } from '../model'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import { path } from '../../../constants/path'
import { MODULE_STATUS } from '../../../constants/learningPoint'
import { handleOnlyNumberValue } from '../../../utils/lib'
import { setFieldUploadValue } from '../../../redux/slices/uploadFile'
import { uniqId } from '../../../services/util'

export const fetchCatalogDetail = (props) => async (dispatch) => {
  const { uuid, onEdit, setOnEdit } = props
  if (onEdit) return
  dispatch(startLoading())

  if (_.isEmpty(uuid)) {
    dispatch(setInitialCreateForm())
  } else {
    const { payload } = await dispatch(getDetail({ uuid, headerConfig }))
    dispatch(
      setEditForm({
        ...payload,
        status:
          payload.status === MODULE_STATUS.DRAFT
            ? payload.isActive
              ? MODULE_STATUS.ACTIVE
              : MODULE_STATUS.INACTIVE
            : payload.status,
      }),
    )
    if (!_.isEmpty(payload.image)) {
      const file = [
        {
          displayName: payload.image.fileName,
          downloadable: false,
          fileSize: payload.image.fileSize,
          fileType: payload.image.fileName.split('.').pop(),
          id: 1,
          key: payload.image.key,
          percent: 100,
          _id: uniqId(),
        },
      ]
      dispatch(
        setFieldUploadValue({
          key: 'formData.catalogImages',
          value: { files: file, isUploading: false },
        }),
      )
    }
    dispatch(
      setChange({
        key: 'isDraft',
        value: payload.status === MODULE_STATUS.DRAFT,
      }),
    )
  }

  setOnEdit(true)
  dispatch(stopLoading())
}

export const handleChange = (key, value, isRoot) => (dispatch) => {
  const obj = { key, value }
  if (isRoot) dispatch(setChange(obj))
  else dispatch(setFieldValue(obj))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  let value = _.get(e.target, 'value', '')
  value = ['point', 'qty'].includes(key)
    ? handleOnlyNumberValue(value) || ''
    : value
  dispatch(setFieldValue({ key, value }))
}

export const handleSubmit = (values, history, actions) => (dispatch) => {
  if (values.status == MODULE_STATUS.DRAFT) {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกฉบับร่าง',
        message: 'คุณต้องการบันทึกฉบับร่างนี้หรือไม่',
        handleConfirm: () =>
          dispatch(handleConfirmSubmit(values, history, actions)),
      }),
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
        handleConfirm: () =>
          dispatch(handleConfirmSubmit(values, history, actions)),
      }),
    )
  }
}

export const handleConfirmSubmit =
  (values, history, actions) => async (dispatch) => {
    dispatch(closeDialog())
    dispatch(startLoading())
    let response = {}
    let body = {
      ...values,
      point: parseInt(values.point || 0),
      qty: parseInt(values.qty || 0),
    }
    const isCreate = _.isEmpty(values.uuid)
    if (isCreate) {
      response = await dispatch(postCreateCatalog({ body, headerConfig }))
    } else {
      response = await dispatch(putUpdateCatalog({ body, headerConfig }))
    }
    dispatch(stopLoading())

    if (_.isNil(response.error)) {
      dispatch(
        openDialog({
          isCloseDialog: false,
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            history.push(`${path.catalog}/view/${response.payload.uuid}`)
            dispatch(closeDialog())
          },
        }),
      )
    } else {
      const key = _.get(
        response,
        'meta.response.data.message[0].constraints.key',
        '',
      )
      if (key == 'LearningPointCatalogNameExist') {
        const error = {}
        response.meta.response.data.message.map((ele) => {
          error[ele.property] = ele.constraints.value
        })
        dispatch(
          openDialog({
            type: 'fail',
            isCloseDialog: false,
            title: 'ผิดพลาด',
            message: 'ชื่อนี้ถูกใช้ไปแล้ว กรุณาตั้งชื่อของรางวัลใหม่',
            handleError: () => {
              actions.setErrors(error)
              dispatch(closeDialog())
            },
          }),
        )
      }
    }
  }
