import React from 'react'

import CardQuestion from '../CardQuestion'

const Index = () => {
  return (
    <>
      <CardQuestion text="แบบประเมิน / แบบสอบถามอื่นๆ" type="questions" />
    </>
  )
}

export default Index
