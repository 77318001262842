import { listAPIFetchMasterDataOptions } from '../../../../../constants/report/listAPIFetchMasterDataOptions'
import _ from 'lodash'
import axios from 'axios'
import { getOptionsKey } from '../../../../../utils/lib'

export const fetchMasterDataOptions = async (columnSelected) => {
  const {
    method,
    url,
    body,
    headers = {},
  } = _.find(
    listAPIFetchMasterDataOptions,
    (item) => _.get(item, 'name') === columnSelected,
  )

  return axios({ method: method, url: url, data: body, headers: headers }).then(
    (res) =>
      getOptionsKey({
        data: _.get(res, 'data.result', []),
        label:
          columnSelected === 'trainer'
            ? 'fullnameTHManageClass'
            : columnSelected,
        key: 'uuid',
      }),
  )
}
