import React from 'react'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TextInput from '../../../../../../components/Input/TextInput'
import InputMask from '../../../../../../components/Input/InputMask'
import { StyledColumn } from '../../../Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledFooter,
} from '../../Styled'
import ImageUploader from '../../ImageUploader'
import {
  handleInputChange,
  handleInputNumberChange,
  handleSubmitData,
} from './events'
import { schema } from './schema'

const StationDrawer = ({ data, index, isOpen, onCloseDrawer }) => {
  const dispatch = useDispatch()
  const no = index + 1
  const infoText = 'ขนาดแนะนำ width 240px, height 240px, ไฟล์ไม่เกิน 2MB,'

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Formik
        initialValues={data}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          onCloseDrawer()
          dispatch(handleSubmitData(values, index))
        }}
      >
        {(formik) => {
          const { values, errors, setValues } = formik
          return (
            <Form>
              <StyledContent>
                <StyledHeadLabel>
                  <Box id="header">
                    <Typography variant="h5">Station {no}</Typography>
                  </Box>
                  <Divider />
                </StyledHeadLabel>
                <StyledColumn sx={{ width: 'auto', m: 3 }}>
                  <Typography variant="h6" mb={3}>
                    Station Icon {no}
                  </Typography>

                  <ImageUploader
                    formik={formik}
                    keyField="img"
                    info={infoText}
                  />

                  <TextInput
                    sx={{ height: 50 }}
                    name="title"
                    type="basic"
                    labelText="Title"
                    id="input-title"
                    placeholder="ชื่อสถานที่"
                    value={_.get(values, 'title', '')}
                    textError={_.get(errors, 'title', '')}
                    onChange={(e) => dispatch(handleInputChange(e, setValues))}
                    textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
                  />
                  <TextInput
                    id="description"
                    name="description"
                    inputProps={{ 'data-testid': 'description' }}
                    labelText="รายละเอียด"
                    placeholder="ข้อความเกี่ยวกับสถานที่"
                    type="basic"
                    textError={_.get(errors, 'description', '')}
                    value={_.get(values, 'description', '')}
                    showMaxLength={3000}
                    onChange={(e) => dispatch(handleInputChange(e, setValues))}
                    multiline={true}
                    rows={3}
                  />
                  <InputMask
                    sx={{ height: 50 }}
                    id="phoneNumber"
                    name="phoneNumber"
                    labelText="เบอร์โทรศัพท์"
                    placeholder="012 345 6789"
                    maskChar={null}
                    mask="999 999 9999"
                    required
                    onChange={(e) =>
                      dispatch(handleInputNumberChange(e, setValues))
                    }
                    value={_.get(values, 'phoneNumber', '')}
                    textError={_.get(errors, 'phoneNumber', '')}
                  />

                  <Divider sx={{ borderStyle: 'dashed', mb: 3 }} />

                  <Typography variant="h6" mb={3}>
                    Station Map {no}
                  </Typography>

                  <ImageUploader
                    formik={formik}
                    keyField="imgMap"
                    info={infoText}
                  />

                  <TextInput
                    sx={{ height: 50 }}
                    required
                    name="googleMap"
                    type="basic"
                    labelText="Google Map Link"
                    id="input-googleMap"
                    placeholder="https://goo.gl/maps/YT1RmS4ABskW3eP37"
                    value={_.get(values, 'googleMap', '')}
                    textError={_.get(errors, 'googleMap', '')}
                    onChange={(e) => dispatch(handleInputChange(e, setValues))}
                  />
                  <TextInput
                    id="remark"
                    name="remark"
                    inputProps={{ 'data-testid': 'remark' }}
                    labelText="Remark"
                    placeholder="บันทึกหมายเหตุ"
                    type="basic"
                    textError={_.get(errors, 'remark', '')}
                    value={_.get(values, 'remark', '')}
                    onChange={(e) => dispatch(handleInputChange(e, setValues))}
                    textInfo="ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร"
                    multiline={true}
                    rows={3}
                    sx={{ minHeight: 98, maxHeight: '100%', height: 'auto' }}
                  />
                </StyledColumn>
                <StyledFooter>
                  <Button
                    data-testId="btn-cancel"
                    variant="outlined"
                    onClick={() => {
                      onCloseDrawer()
                      setValues(data)
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    data-testId="btn-submit"
                    variant="contained"
                    type="submit"
                  >
                    ตกลง
                  </Button>
                </StyledFooter>
              </StyledContent>
            </Form>
          )
        }}
      </Formik>
    </StyledDrawer>
  )
}

export default StationDrawer
