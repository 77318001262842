import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'

import { LoadingButton } from '@mui/lab'
import {
  setNextQuestion,
  setPreviousQuestion,
} from '../../../../../../redux/slices/eEvaluationPreview'
import SubmitBtn from '../SubmitBtn'
import { responsiveButton } from '../Styled'

const ButtonForOne = ({ totalQuestion }) => {
  const buttonSize = responsiveButton()
  const dispatch = useDispatch()
  const page = useSelector(
    (state) => state.eEvaluationPreview.page,
    shallowEqual
  )
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      {page > 1 && (
        <Button
          variant="contained"
          size={buttonSize}
          data-testid="btn-back"
          onClick={() => dispatch(setPreviousQuestion())}
          startIcon={<ArrowBack />}
        >
          กลับ
        </Button>
      )}
      {page < totalQuestion && (
        <LoadingButton
          variant="contained"
          size={buttonSize}
          onClick={() => dispatch(setNextQuestion())}
          data-testid="btn-next"
          endIcon={<ArrowForward />}
          loadingPosition="end"
        >
          ถัดไป
        </LoadingButton>
      )}
      {page == totalQuestion && <SubmitBtn />}
    </Box>
  )
}

export default ButtonForOne
