import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDateInput = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  svg: {
    position: 'absolute',
    right: 10,
    bottom: 7,
    color: theme.palette?.text?.silver,
  },
}))
