export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: `คลังทรัพย์สินปัจจุบัน`,
    nameCheckBox: 'stockChecked',
    nameInput: 'stock',
    placeholder: `กรุณาใส่ คลังทรัพย์สินปัจจุบัน`,
    type: 'text',
  },
  {
    label: `Station`,
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: `กรุณาใส่ station`,
    type: 'text',
  },
  {
    label: `คงเหลือ`,
    nameCheckBox: 'totalChecked',
    nameInput: 'total',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'totalFrom',
    inputTo: 'totalTo',
    type: 'number_between',
  },
  {
    label: `พร้อมใช้งาน`,
    nameCheckBox: 'availableChecked',
    nameInput: 'available',
    placeholder: ``,
    inputFrom: 'availableFrom',
    inputTo: 'availableTo',
    type: 'number_between',
  },
  {
    label: `มูลค่ารวม (฿)`,
    nameCheckBox: 'stockValueChecked',
    nameInput: 'stockValue',
    placeholder: ``,
    inputFrom: 'stockValueFrom',
    inputTo: 'stockValueTo',
    type: 'float_between',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusWarehouseChecked',
    nameInput: 'statusWarehouse',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
