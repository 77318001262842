import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 732,
    height: '100vh',
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}))

export const StyledFooterDrawer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottome: theme.spacing(1.5),
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(1.5),
  padding: `${theme.spacing(4)} ${theme.spacing(2)} 0`,
}))

export const StyledColumnNoGap = styled(Box)(({ theme, noGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: { gap: noGap ? 0 : theme.spacing(3) },
}))

export const StyledRowFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& span#sub': { color: theme?.palette?.text?.secondary },
}))

export const StyledRespRow = styled(StyledRowFlex)(({ theme, isInput }) => ({
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: isInput ? 0 : theme.spacing(3),
  },
}))

export const BoxLabel = styled(Box)(() => ({
  width: '100%',
}))
