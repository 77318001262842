import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/manageClassDetail'
import { headCellsLearnerCheckHistory } from '../../../../../../constants/manageClass'
import { setInitialTable } from '../../../../../../redux/slices/table'
import { StyledTable } from './Styled'
import {
  getLearnerCheckHistory,
  getStandaloneUserHistory,
} from '../../../../../../services/manageClass/check'

export const fetchLearnerCheckHistory = (props) => async (dispatch) => {
  const { table, idProps, isStandalone } = props
  const { uuid } = idProps
  const realPage = table.page <= 0 ? 1 : +table.page
  const body = { limit: Number(table.limit), page: realPage }

  dispatch(startLoading())
  let result = null
  if (isStandalone) {
    const { eTestingVersionUuid } = idProps
    const payload = {
      eTestingVersionUuid,
      userUuid: uuid,
      body,
    }
    result = await dispatch(getStandaloneUserHistory(payload))
  } else {
    const { id, courseId } = idProps
    const payload = {
      trainingPlanUuid: id,
      courseTestAndEvaUuid: courseId,
      learnerUuid: uuid,
      body,
    }
    result = await dispatch(getLearnerCheckHistory(payload))
  }
  const data = _.get(result, 'payload.data.result', [])
  const rows = data.map((item) => ({
    ...item,
    score: `${item.oldScore} → ${item.newScore}`,
  }))

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows,
      allCount: _.get(result, 'payload.data.totalCount', 0),
      headCells: headCellsLearnerCheckHistory,
      customStyle: StyledTable,
    })
  )
  dispatch(stopLoading())
}
