import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function findKeyByValue(object, searchValue) {
  for (let key in object) {
    const value = object[key]
    if (Array.isArray(value) && value.includes(searchValue)) {
      return key
    }
  }
  return null
}

export default async function handleScrollToError(key) {
  const { crudConfig } = store.getState().crud
  const state = store.getState().crud
  if (_.get(state, 'tabs', undefined)) {
    const tab = findKeyByValue(store.getState().crud.tabs, key)
    if (tab) {
      store.dispatch(setFieldValue({ key: 'tab', value: tab }))
    }
  }
  await sleep(100)
  const elementId = `${crudConfig.moduleSlug}-${key}`
  const errorElement = document.getElementById(elementId)

  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
