import {
  startLoading,
  stopLoading,
  docsAdd,
  docsSetData,
} from '../../../../../redux/slices/manageClassDocs'
import _ from 'lodash'
import * as service from '../../../../../services/manageClass/index'

export const handleUpload = (e, lastIndex, initDocs) => async (dispatch) => {
  dispatch(startLoading())
  const docs = []
  let index = lastIndex
  for (const file of e.target.files) {
    const getNameImage = String(_.get(file, 'name', '')).trim()
    const isDuplicate = handleDuplicate(getNameImage, initDocs)
    if (getNameImage) {
      const sizeFile = _.get(file, 'size', 0)
      if (sizeFile > 2000000) {
        docs.push({
          index: index,
          name: getNameImage,
          key: '',
          type: 'UploadFile',
          isDuplicate: isDuplicate,
          isDownload: false,
          status: 'ERROR',
          textError: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
        })
        index++
      } else {
        const body = new FormData()
        body.append('file', file)
        const response = await dispatch(service.uploadFileDocs(body))

        if (!response.error) {
          const uploadData = _.get(response, 'payload.data', {})
          docs.push({
            index: index,
            name: getNameImage,
            key: uploadData.key,
            type: 'UploadFile',
            isDuplicate: isDuplicate,
            isDownload: false,
            status: 'SUCCESS',
          })
          index++
        } else {
          docs.push({
            index: index,
            name: getNameImage,
            key: '',
            type: 'UploadFile',
            isDuplicate: isDuplicate,
            isDownload: false,
            status: 'ERROR',
          })
          index++
        }
      }
    }
  }
  _.set(document.getElementById('docs-button-file'), 'value', null)
  dispatch(stopLoading())
  dispatch(docsAdd(docs))
}

export const handleChangeName =
  (e, Index, initialValues, Docs = []) =>
  async (dispatch) => {
    const Name = String(e.target.value).trim()
    const electronic = _.get(initialValues, 'electronic', [])
    const filterElectronic = _.filter(
      [...electronic, ...Docs],
      (item) => item.name === Name && item.index !== Index
    )
    const MapElectronic = _.map(Docs, (item) => {
      if (item.index === Index) {
        return {
          ...item,
          isDuplicate: !_.isEmpty(filterElectronic),
          name: Name,
        }
      }
      return { ...item }
    })
    dispatch(docsSetData(_.compact(MapElectronic)))
  }

export const handleChangeNameLink = (
  e,
  initialValues,
  setIsDuplicate,
  setName
) => {
  const Name = String(e.target.value).trim()
  const electronic = _.get(initialValues, 'electronic', [])
  const filterElectronic = _.filter(electronic, (item) => item.name === Name)
  setIsDuplicate(!_.isEmpty(filterElectronic))
  setName(Name)
}

export const handleDuplicate = (name, initDocs) => {
  const filter = _.filter(initDocs, (item) => item.name === name)
  return !_.isEmpty(filter)
}

export const handleSubmitUpload = (initialValues, setInitialValues, docs) => {
  const electronic = _.get(initialValues, 'electronic', [])

  const Docs = _.map(docs, (item) => _.omit(item, ['isDuplicate']))

  setInitialValues((pre) => ({
    ...pre,
    electronic: [...electronic, ...Docs],
  }))
}

export const handleRemoveUpload = (key, docs) => async (dispatch) => {
  dispatch(startLoading())

  if (key === null) {
    dispatch(docsSetData([]))
  } else {
    const filter = _.map(docs, (item, index) => {
      if (index === key) {
        return null
      }
      return item
    })
    dispatch(docsSetData(_.compact(filter)))
  }

  dispatch(stopLoading())
}

export const handleSubmitLink = (
  initialValues,
  setInitialValues,
  name,
  link
) => {
  const electronic = _.get(initialValues, 'electronic', [])

  if (electronic.length > 0) {
    const electronicLength = electronic[electronic.length - 1]?.index
    const linkDoc = {
      index: electronicLength + 1,
      name: name,
      key: link,
      isDownload: false,
      type: 'Link',
    }
    setInitialValues((pre) => ({
      ...pre,
      electronic: [...electronic, linkDoc],
    }))
  } else {
    const electronicLength = 0
    const linkDoc = {
      index: electronicLength + 1,
      name: name,
      key: link,
      isDownload: false,
      type: 'Link',
    }
    setInitialValues((pre) => ({
      ...pre,
      electronic: [...electronic, linkDoc],
    }))
  }
}
