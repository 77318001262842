export const BADGE_MODEL = {
  certificate: {
    th: 'มีประกาศนียบัตร',
    icon: 'ic_certificate_line.svg',
  },
  time: {
    th: 'ชั่วโมงเรียน',
    icon: 'time-linear.svg',
  },
}
