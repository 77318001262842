import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
//import { crudConfig } from '../../crudConfig'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import { eContentAssignees } from '../../../../../utils/apiPath'

export const fetchAssignees = async () => {
  const body = {
    abbreviation: '',
    distribution: '',
    level: [],
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  try {
    const response = await callAxios.post(eContentAssignees, body)
    store.dispatch(
      setFieldValue({
        key: 'masterData.assignees',
        value: {
          isLoading: false,
          options: response.data.result.map((item) => ({
            value: item.uuid,
            label: `${item.firstNameTH} ${item.lastNameTH}`,
          })),
        },
      }),
    )
  } catch (e) {
    console.log(e)
    handleFetchError(e)
  }
}
