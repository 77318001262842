import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'

import Contents from '../Contents'
import { onDragEnd } from './event'
import { BoxDragDrop } from './Styled'

const Index = ({ props }) => {
  const { sequence, type, itemQuestion, disableForm } = props

  const dispatch = useDispatch()

  const listData = itemQuestion[type] || []

  const propsContent = {
    ...props,
    disableForm,
    listData,
  }

  return (
    <Box sx={{ pr: 2, mt: 2 }}>
      <Typography variant="body1b" sx={{ ml: 3 }}>
        {type == 'questions'
          ? 'คำถาม'
          : 'ตัวเลือก (กรุณาเรียงจาก พึงพอใจมากไปน้อย)'}
      </Typography>
      <DragDropContext
        onDragEnd={(event) =>
          dispatch(onDragEnd({ event, listData, sequence, type }))
        }
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {listData.map((item, indexTypeList) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={indexTypeList}
                >
                  {(provided) => (
                    <Box
                      sx={{ display: 'flex', width: '100%' }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      {!disableForm ? (
                        <BoxDragDrop {...provided.dragHandleProps}>
                          <DragIndicatorTwoTone color="action" />
                        </BoxDragDrop>
                      ) : (
                        <BoxDragDrop>
                          <DragIndicatorTwoTone color="action" />
                        </BoxDragDrop>
                      )}

                      <Contents
                        props={{
                          ...propsContent,
                          indexTypeList,
                        }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}
export default Index
