import React from 'react'
import AlertErrorBox from '.'

export const handleAlertErrorBox = (errors, index) => {
  if (errors?.questions && errors.questions.length > index) {
    try {
      if (errors.questions[index].answers) {
        return (
          !Array.isArray(errors.questions[index].answers) && <AlertErrorBox />
        )
      }
    } catch (error) {
      return null
    }
  }
}

export const handleAlertErrorAssessment = (errors, index) => {
  if (errors?.questions && errors.questions.length > index) {
    try {
      if (errors.questions[index]) {
        return (
          !Array.isArray(errors.questions[index]) && (
            <AlertErrorBox text="กรุณากรอกคำถาม" />
          )
        )
      }
    } catch (error) {
      return null
    }
  }
}
