import dayjs from 'dayjs'
import { defaultFilter } from '../../../modules/ELearning/Dashboard/model'
import { MODULE_STATUS } from '../../../constants/eLearning'

export const initialState = {
  isLoading: false,
  isOpenFilter: false,
  error: '',
  filterMemo: defaultFilter,
  filter: {
    startDate: dayjs(new Date()).startOf('Month').format('YYYY-MM-DD'),
    endDate: dayjs(new Date()).format('YYYY-MM-DD'),
    courseType: [],
    status: [MODULE_STATUS.ACTIVE, MODULE_STATUS.INACTIVE],
  },
  overview: {
    totalCourse: 0,
    totalEnroll: 0,
    latestCreate: [],
    latestUpdate: [],
    AGENCY: {
      totalCourse: 0,
      totalEnroll: 0,
      latestCreate: [],
      lastestUpdate: [],
    },
    BANCA: {
      totalCourse: 0,
      totalEnroll: 0,
      latestCreate: [],
      lastestUpdate: [],
    },
    KTB: {
      totalCourse: 0,
      totalEnroll: 0,
      latestCreate: [],
      lastestUpdate: [],
    },
    OTHER: {
      totalCourse: 0,
      totalEnroll: 0,
      latestCreate: [],
      lastestUpdate: [],
    },
    module: {
      E_TESTING: 0,
      E_EVALUATION: 0,
      EMBED_CONTENT: 0,
      LINK_TO_ANOTHER_WEBSITE: 0,
      UPLOAD_FILE: 0,
      SCORM: 0,
    },
    chartData: {
      course: [],
      enroll: [],
      module: [],
      totalModule: 0,
    },
  },
  type: 'AGENCY',
  courseDrawer: {
    isOpen: false,
    header: '',
    drawerType: '',
    courseType: '',
  },
  enrollDrawer: {
    isOpen: false,
    header: '',
    uuid: '',
  },
}
