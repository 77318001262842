import _ from 'lodash'
import {
  setDrawer,
  setChangeData,
  setChangeDrawer,
} from '../../../../redux/slices/manageContent/login'

export const palette = {
  red: '#ff0000',
  blue: '#0000ff',
  green: '#00ff00',
  yellow: '#FFFF00',
  cyan: '#00ffff',
  lime: '#32cd32',
  gray: '#7f7f7f',
  orange: '#ffa500',
  purple: '#a020f0',
  black: '#000000',
  white: '#ffffff',
  pink: '#fcc0cb',
  darkblue: '00008b',
}

export const matchColor = (value) => {
  if (!_.isEmpty(_.get(palette, value, ''))) return _.get(palette, value, '')
  return value
}

export const handleDrawer = (props) => (dispatch) => {
  dispatch(setDrawer(props))
}

export const handleChageExpand = (indexData, value) => (dispatch) => {
  dispatch(
    setChangeData({
      indexData,
      key: 'expand',
      value,
    })
  )
}

export const handleChage = (props) => (dispatch) => {
  dispatch(setChangeData(props))
}

export const handleSunmitDrawer = (props) => (dispatch) => {
  dispatch(setDrawer({ isClose: true }))
  dispatch(setChangeDrawer(props))
}

export const checkErrorRemark = (error, value) => {
  if (_.isEmpty(error)) return { color: '', msg: `${value?.length}/3000` }
  return {
    color: 'error',
    msg: `${value?.length}/3000`,
  }
}
