import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTimerContainer = styled(Box)(({ theme }) => ({
  minWidth: 120,
  height: 64,
  border: '1px solid',
  borderColor: theme?.palette?.text?.white,
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
