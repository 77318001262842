import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import useLocalStorage from '@rehooks/local-storage'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { LoadingPage } from './Styled'
import Header from './Header'
import ContentForm from './Form'
import { fetchManageStation, handleError, handleSubmitForm } from './events'
import { schema } from './schema'

const StationPage = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const history = useHistory()
  const { data, isLoading, error } = useSelector(
    (state) => ({
      isLoading: state.manageContentStation.isLoading,
      isSuccess: state.manageContentStation.isSuccess,
      data: state.manageContentStation.data,
      error: state.manageContentStation.error.all,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(fetchManageStation(onEdit, setOnEdit))
  }, [])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        {data && (
          <Formik
            initialValues={data}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => dispatch(handleSubmitForm(values))}
          >
            <Form>
              <Header />

              <ContentForm />
            </Form>
          </Formik>
        )}
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default StationPage
