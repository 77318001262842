import { defaultLoginPage } from './events'

export const initialState = {
  isLoading: false,
  isSuccess: false,
  isUploadLoading: false,
  drawer: {
    isOpen: false,
    index: 0,
  },
  error: {
    all: null,
    upload: '',
  },
  uuid: '',
  loginPages: defaultLoginPage(),
  createdAt: '-',
  createdBy: '-',
  updatedAt: '-',
  updatedBy: '-',
}
