import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { StyledRow } from '../../Styled'

export const StyledImagePreview = styled(Box)(({ theme, keyField }) => {
  const width = keyField === 'img' ? 120 : 300
  const height = keyField === 'img' ? 120 : 170
  return {
    width,
    height,
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      [theme.breakpoints.down('lg')]: { minWidth: width },
      [theme.breakpoints.down('sm')]: { minWidth: '100%' },
    },
    [theme.breakpoints.down('sm')]: {
      width: keyField === 'img' ? 120 : '100%',
    },
  }
})

export const StyledInputHidden = styled('input')({ display: 'none' })

export const StyledLoadingBox = styled(Box)(({ keyField }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: keyField === 'img' ? 1 : 0,
  width: keyField === 'img' ? 120 : 300,
  height: keyField === 'img' ? 120 : 170,
  backgroundColor: '#f4f4f4',
}))

export const StyledLoadingProgress = styled(Box)(({ theme, keyField }) => ({
  width: keyField === 'img' ? 72 : 252,
  height: 5,
  backgroundColor: theme?.palette?.text?.silver,
}))

export const StyledLoading = styled(Box)(({ theme }) => ({
  width: 0,
  height: 5,
  backgroundColor: theme?.palette?.primary?.main,
  transition: 'width 0.4s ease',
}))

export const StyledResponsiveRow = styled(StyledRow)(({ theme }) => ({
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))
