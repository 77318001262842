export const breadcrumbLearner = (uuid, courseUuid) => {
  return [
    { title: 'E-Learning', link: '/', pointer: false },
    { title: 'Course', link: '/e-learning/course', pointer: true },
    {
      title: 'รอบอบรม',
      link: `/e-learning/course/edit/${courseUuid}?tab=batch`,
      pointer: true,
    },
    {
      title: 'รายละเอียดรอบอบรม',
      link: `/e-learning/course/batch/view/${uuid}`,
      pointer: true,
    },
    { title: 'รายชื่อผู้เรียน', link: '/', pointer: false },
  ]
}

export const breadcrumbLearnerView = (name, uuid) => [
  { title: 'E-Learning', link: '/', pointer: false },
  {
    title: '...',
    link: `/e-learning/course/batch/view/${uuid}`,
    pointer: true,
  },
  {
    title: 'รายชื่อผู้เรียน',
    link: `/e-learning/course/batch/view/learner/${uuid}`,
    pointer: true,
  },
  { title: `${name}`, link: '/', pointer: false },
]
