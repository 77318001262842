import TextInput from '../../../../../components/Input/TextInput'
import { Box, Button } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { closeDialog } from '../../../../../redux/slices/dialog'
import { submitEditBooking } from '../event'
import { handleChange } from '../../event'
import { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

export const EditBooking = () => {
  const dispatch = useDispatch()
  const { reason, bookingInformation, stationUuid, isLoading } = useSelector(
    (state) => ({
      reason: state.roomManagementDrawer.bookingInformation.reason,
      bookingInformation: state.roomManagementDrawer.bookingInformation,
      stationUuid: state.roomManagement.stationUuid,
      isLoading: state.dialog.isLoading,
    }),
    shallowEqual,
  )
  const [error, setErrors] = useState('')

  useEffect(() => {
    if (reason?.length > 3000) {
      setErrors('กรอกได้ไม่เกิน 3,000 ตัวอักษร')
    } else {
      setErrors('')
    }
  }, [reason])

  return (
    <Box sx={{ px: 2 }}>
      <TextInput
        type="basic"
        name="reason"
        labelText={'กรุณากรอกสาเหตุการเปลี่ยนแปลง'}
        placeholder="กรุณากรอก"
        rows={3}
        multiline={true}
        value={reason}
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          dispatch(
            handleChange({
              key: 'reason',
              value: value,
            }),
          )
        }}
        isShowTextError={!_.isEmpty(error)}
        textError={error}
      />
      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          sx={{ width: 80 }}
          data-testid="btn-disagree"
          onClick={() => dispatch(closeDialog())}
          variant="outlined"
          color="error"
          endIcon=""
        >
          ยกเลิก
        </Button>
        <LoadingButton
          loading={isLoading}
          loadingPosition="end"
          sx={{ width: 80 }}
          data-testid="btn-agree"
          variant="contained"
          color="error"
          endIcon=""
          disabled={!reason}
          onClick={() => {
            if (_.isEmpty(error))
              dispatch(submitEditBooking(bookingInformation, stationUuid))
          }}
        >
          ยืนยัน
        </LoadingButton>
      </Box>
    </Box>
  )
}
