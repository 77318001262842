import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Content from './Content'
import { 
    StyledContainer,
    StyledContent,
} from './Styled'


const EConfiguration = () => {
    const { isLoading } = useSelector(
        (state) => ({
            isLoading: state.eConfiguration.isLoading,
        }),
        shallowEqual,
    )

    return (
        <StyledContainer>
            <StyledContent isLoading={isLoading}>
                <Content />
            </StyledContent>
        </StyledContainer>
    )
}

export default EConfiguration