import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: 275,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}))
