import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography, Box, Grid } from '@mui/material'

import { useDispatch } from 'react-redux'
import { setOtherDocs } from '../../../../redux/slices/eExaminer/form'
import UploadFileComp from '../../../../components/UploadFile'
import {
  StyledColumnNoGap,
  StyledRespRow,
  StyledDashedLine,
} from '../../Styled'
import { EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS } from '../../../../constants/eExamination'

const UploadOtherDocs = (props) => {
  const { isEditForm } = props
  const dispatch = useDispatch()
  const { otherDocConfig, otherDocs, examinationOtherDocs } = useSelector(
    (state) => ({
      otherDocConfig: state.eExamList.otherDocConfig,
      otherDocs: state.eExaminerForm.body.otherDocs,
      examinationOtherDocs: state.uploadFile.formData.examinationOtherDocs,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (examinationOtherDocs && !examinationOtherDocs.isUploading) {
      if (examinationOtherDocs && examinationOtherDocs?.files?.length > 0) {
        let otherDocArr = []
        for (const file of examinationOtherDocs.files) {
          const key = file.key
          const displayName = file.displayName
          const fileSize = file.fileSize
          const fileType = file.fileType
          otherDocArr.push({
            cardImage: key,
            imageName: displayName,
            documentName:
              otherDocConfig?.config?.label ?? 'อัพโหลดเอกสารเพิ่มเติม',
            key,
            fileName: displayName,
            fileSize,
            fileType,
          })
        }
        dispatch(setOtherDocs(otherDocArr))
      } else {
        dispatch(setOtherDocs([]))
      }
    }
  }, [examinationOtherDocs])

  const isShow =
    (otherDocConfig &&
      otherDocConfig?.config?.status ===
        EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE) ||
    (isEditForm && otherDocs.length > 0)

  return (
    <>
      {/* {((otherDocConfig &&
        otherDocConfig?.config?.status ===
          EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE) ||
        (isEditForm && otherDocs.length > 0)) && ( */}
      <Box display={isShow ? 'block' : 'none'} width={'100%'}>
        <StyledDashedLine />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2">เอกสารเพิ่มเติม</Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
            *
          </Typography>
        </Box>
        <StyledColumnNoGap noGap>
          <StyledRespRow isInput>
            <Grid item sm={12} xs={12} width={'100%'}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="body2" color="text.secondary">
                  {otherDocConfig?.config?.label ?? 'อัพโหลดเอกสารเพิ่มเติม'}
                </Typography>
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
                  *
                </Typography>
              </Box>
              <UploadFileComp
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                  width: '100%',
                  p: 0,
                  display: isShow ? 'block' : 'none',
                }}
                isMultiple={true}
                isShowDownloadable={false}
                isForImage={true}
                folder="examination-idcard"
                maxFileSize={{ size: 2, sizeType: 'MB' }}
                maxLength={5}
                accept={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg'],
                  'image/jpg': ['.jpg'],
                  'application/pdf': ['.pdf'],
                }}
                acceptLabel=".jpg, .jpeg, .png, .pdf"
                fieldName="examinationOtherDocs"
                // value={idCardImageKey}
                label=""
                popupLabel={
                  otherDocConfig?.config?.label ?? 'อัพโหลดเอกสารเพิ่มเติม'
                }
                disabled={
                  !(
                    otherDocConfig?.config?.status ===
                    EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE
                  )
                }
                disabledRemove={!isShow}
                hideUpload={
                  !(
                    otherDocConfig?.config?.status ===
                    EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE
                  )
                }
              />
            </Grid>
          </StyledRespRow>
        </StyledColumnNoGap>
      </Box>
      {/* )} */}
    </>
  )
}

export default UploadOtherDocs
