import React from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import FileCopy from '@mui/icons-material/FileCopy'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { path } from './../../../../../constants/path'
import { StyledHeaderContainer } from './../../../Styled'
import { StyledButton } from './Styled'

const Header = ({ getPermissionETestingCreate }) => {
  const { id: uuid } = useParams()
  const [, setETesting] = useLocalStorage('eTesting')
  const { result } = useSelector((state) => state.eTestingDetail)

  return (
    <StyledHeaderContainer id="view">
      <StyledButton
        data-testid="btn-preview"
        onClick={() => {
          setETesting(result)
          window.open(`${path.eTesting}/preview/${uuid}`, '_blank')
        }}
      >
        <RemoveRedEye />
        ดูตัวอย่าง
      </StyledButton>
      {getPermissionETestingCreate && (
        <StyledButton
          data-testid="btn-copy"
          onClick={() =>
            (window.location.href = `/e-testing/version/clone/${uuid}`)
          }
        >
          <FileCopy />
          คัดลอกเพื่อสร้างใหม่
        </StyledButton>
      )}
    </StyledHeaderContainer>
  )
}

export default Header
