import { eCertificationDownload } from '../../../../utils/apiPath'
import { callAPI } from '../../../../utils/lib'
import * as reducer from '../../../../redux/slices/eCertificateForm'

export const onDownload = (uuid) => (dispatch) => {
  dispatch(reducer.startLoading())
  callAPI({
    method: 'get',
    url: eCertificationDownload + '/' + uuid,
    responseType: 'blob',
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `e-certification.pdf`)
      document.body.appendChild(link)
      link.click()
    },
    onFinally: () => {
      dispatch(reducer.stopLoading())
    },
  })
}
