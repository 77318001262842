import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data) => {
  let contents = []
  let courseData = _.get(data, 'eLearningCollectionCourses', []).map(
    (course) => {
      if (course.type === 'ELearning')
        return {
          content: {
            ...course.eLearningCourse,
            label:
              _.get(course, `eLearningCourse.name`, '') ||
              _.get(course, `eLearningCourse.englishName`, ''),
            value: _.get(course, `eLearningCourse.uuid`, ''),
            type: 'ELearning',
            collectionCourseId: _.get(course, `id`, undefined),
            collectionCourseUuid: _.get(course, `uuid`, undefined),
          },
          type: 'COURSE',
          sequence: course.sequence,
        }
      else if (course.type === 'LEARNING_PATH')
        return {
          content: {
            ...course.eLearningLearningPath,
            label:
              _.get(course, `eLearningLearningPath.name`, '') ||
              _.get(course, `eLearningLearningPath.englishName`, ''),
            value: _.get(course, `eLearningLearningPath.uuid`, ''),
            type: 'LEARNING_PATH',
            collectionCourseId: _.get(course, `id`, undefined),
            collectionCourseUuid: _.get(course, `uuid`, undefined),
          },
          type: 'LEARNING_PATH',
          sequence: course.sequence,
        }
      else
        return {
          content: {
            ...course.courseVersion,
            label:
              _.get(course, `courseVersion.name`, '') ||
              _.get(course, `courseVersion.englishName`, ''),
            value: _.get(course, `courseVersion.uuid`, ''),
            type: _.get(course, `type`, 'courseVersion'),
            collectionCourseId: _.get(course, `id`, undefined),
            collectionCourseUuid: _.get(course, `uuid`, undefined),
          },
          type: 'COURSE',
          sequence: course.sequence,
        }
    },
  )
  contents = _.sortBy(contents.concat(courseData), (obj) => {
    return _.toNumber(obj.sequence)
  })

  return {
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    remark: _.get(data, 'remark', ''),
    contents: contents,
    nameForBanner: _.get(data, 'nameForBanner', ''),
    detailForBanner: _.get(data, 'detailForBanner', ''),
    description: _.get(data, 'description', ''),
    fileName: _.get(data, 'fileName', ''),
    coverImage: _.get(data, 'coverImage', ''),
    linkExampleVideo: _.get(data, 'linkExampleVideo', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    pathCount: _.get(data, 'pathCount', 0),
    coursesCount: _.get(data, 'courseCount', 0),

    //setting page
    periodStart: _.get(data, 'periodStart', ''),
    oldPeriodStart: _.get(data, 'periodStart', ''),
    acquiredSkill: _.get(data, 'acquiredSkill', []),
    productType: _.get(data, 'productType', []),
    levelOfLearner: _.get(data, 'levelOfLearner', []),
    dueDateType: _.get(data, 'dueDateType', 'NONE'),
    dueDate: _.get(data, 'dueDate', null),
    setting: _.get(data, 'setting', { tag: [], distribution: [], remark: '' }),

    createdAt: _.get(data, 'createdAt', ''),
    createdBy: _.get(data, 'createdBy', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    uuid: _.get(data, 'uuid', undefined),
  }
}
