import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { BoxCard } from '../Styled'
import { BoxSwitch, handleChange } from '../event'

const Index = () => {
  const dispatch = useDispatch()
  const { notification, disableSetting } = useSelector(
    (state) => ({
      notification: state.eTestingForm.setting.notification,
      disableSetting:
        state.eTestingForm.disableSetting || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )

  return (
    <BoxCard>
      <BoxSwitch
        disabled={disableSetting}
        labelText="การแจ้งเตือน"
        labelSwitch="แจ้งเตือนการทำข้อสอบกับผู้เรียน เมื่อถึงเวลาเปิดให้เข้าทำข้อสอบ"
        value={notification}
        handleChange={(e) =>
          dispatch(
            handleChange({
              value: _.get(e, 'target.checked', false),
              key: 'notification',
            })
          )
        }
      />
    </BoxCard>
  )
}

export default Index
