import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import Typography from '@mui/material/Typography'
import { StyledEditorBox, contentStyle } from './Styled'
import { blankReg } from './model'

const EditorForDragDrop = ({ id, value, onChange, textError, disabled }) => {
  const editorRef = useRef()

  return (
    <StyledEditorBox textError={textError}>
      <Editor
        id={id}
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey={window.__env__.TINY_KEY}
        disabled={disabled}
        value={value}
        onEditorChange={onChange}
        init={{
          menubar: false,
          contextmenu: false,
          toolbar: '',
          content_style: contentStyle,
          plugins: 'autoresize paste',
          paste_preprocess: (editor, args) => {
            args.content = args.content
              .replaceAll('<span', '[<span')
              .replaceAll('</span>', '</span>]')
              .replace(blankReg, '')
          },
          autoresize_bottom_margin: 0,
        }}
      />
      {textError && textError.length > 0 && (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </StyledEditorBox>
  )
}

export default EditorForDragDrop