import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { visuallyHidden } from '@mui/utils'
import { useDispatch, useSelector } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import {
  StyledBox,
  StyledChangeStatusButton,
  StyledCheckBoxCell,
  StyledDeletedButton,
  StyledDownloadButton,
  StyledEligibleExamButton,
  StyledFilterButton,
  StyledFullWidth,
  StyledSendEmailButton,
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableHeadText,
  StyledTableRow,
  StyledTableRowHeadText,
  StyledTableSortLabel,
  StyledToolbar,
  StyledToolbarButtonBox,
  StyledUploadButton,
} from './Styled'
import Search from './Search'
import DateSearch from './DateSearch'
import QuickFilter from './QuickFilter'
import * as events from './events'
import { ROW_PAGE_PER_LIST } from '../../../constants/table'

export const EnhancedTableToolbar = () => {
  const dispatch = useDispatch()
  const {
    onDownload,
    onDeleted,
    handleSearch,
    handleDateSearch,
    table,
    onSendEmail,
    showSendEmail,
    customToolbar,
    customToolbarSx,
    onChangeStatus,
    onEligibleExam,
    onUpload,
    filter: { filterTotal, canFilter, hideFilter },
    table: {
      tableHeadText,
      tableHeadVariant,
      buttonHeadText,
      buttonProps,
      headCells,
    },
  } = useSelector((state) => state.table)
  const { selected, sort, order } = table

  if (_.isNil(onDownload) && !canFilter && (handleSearch || handleDateSearch)) {
    return <TableToolbarWithSearchOnly />
  }

  return (
    <StyledToolbar>
      {tableHeadText && (
        <StyledTableRowHeadText key="headText">
          <StyledTableHeadText
            sx={{ width: '100%', pt: 0, pb: 1.5, pr: 0.5, pl: 1 }}
            colSpan={headCells.length}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant={tableHeadVariant}>
                {tableHeadText}
              </Typography>
              {buttonHeadText && (
                <Button variant="contained" {...buttonProps}>
                  {buttonHeadText}
                </Button>
              )}
            </Box>
          </StyledTableHeadText>
        </StyledTableRowHeadText>
      )}
      <StyledBox>
        {(handleSearch || handleDateSearch) && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {handleSearch && <Search />}
            {handleDateSearch && <DateSearch />}
          </Box>
        )}
        {onDownload && (
          <StyledToolbarButtonBox sx={customToolbarSx}>
            {customToolbar}
            {onDeleted && (
              <StyledDeletedButton
                data-testid="btn-delete"
                disabled={!selected.length > 0}
                onClick={() => onDeleted(selected, sort, order)}
              >
                ลบรายชื่อ
              </StyledDeletedButton>
            )}
            {onUpload && (
              <StyledUploadButton
                data-testid="btn-upload"
                onClick={() => onUpload()}
              >
                อัปโหลดคะแนน
              </StyledUploadButton>
            )}
            {showSendEmail && (
              <StyledSendEmailButton
                data-testid="btn-send-email"
                disabled={!selected.length > 0}
                onClick={() => onSendEmail(selected)}
              >
                คอนเฟิร์มตัวแทน
              </StyledSendEmailButton>
            )}
            {onChangeStatus && (
              <StyledChangeStatusButton
                data-testid="btn-change-status"
                disabled={!selected.length > 0}
                onClick={() => onChangeStatus(selected)}
              >
                ปรับสถานะใบอนุญาต
              </StyledChangeStatusButton>
            )}
            {onEligibleExam && (
              <StyledEligibleExamButton
                data-testid="btn-eligible-exam"
                disabled={!selected.length > 0}
                onClick={() => onEligibleExam(selected)}
              >
                ไม่มีสิทธิ์สอบ
              </StyledEligibleExamButton>
            )}
            {onDownload !== 'hidden' && (
              <StyledDownloadButton
                data-testid="btn-download"
                disabled={!selected.length > 0}
                onClick={() => onDownload(selected, sort, order)}
              >
                ดาวน์โหลด
              </StyledDownloadButton>
            )}
            {!hideFilter && (
              <StyledFilterButton
                data-testid="btn-filter"
                onClick={() => dispatch(setToggleFilterDrawer(true))}
              >
                ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
              </StyledFilterButton>
            )}
          </StyledToolbarButtonBox>
        )}
        {_.isNil(onDownload) && canFilter && (
          <StyledToolbarButtonBox>
            <StyledFilterButton
              data-testid="btn-filter"
              onClick={() => dispatch(setToggleFilterDrawer(true))}
            >
              ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
            </StyledFilterButton>
          </StyledToolbarButtonBox>
        )}
      </StyledBox>
    </StyledToolbar>
  )
}

export const TableToolbarWithSearchOnly = () => {
  const {
    handleSearch,
    handleDateSearch,
    table: { tableHeadText, tableHeadVariant, headCells },
  } = useSelector((state) => state.table)

  return (
    <StyledToolbar>
      <StyledTableRowHeadText key="headText">
        <StyledTableHeadText
          sx={{ width: '100%', p: 0 }}
          colSpan={headCells.length}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {tableHeadText && (
              <Typography variant={tableHeadVariant}>
                {tableHeadText}
              </Typography>
            )}
            {handleSearch && <Search />}
            {handleDateSearch && <DateSearch />}
          </Box>
        </StyledTableHeadText>
      </StyledTableRowHeadText>
    </StyledToolbar>
  )
}

export const EnhancedTableHead = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { totalPinned } = useSelector((state) => state.contentForm)
  const { rows, isCheckBox, selected, headCells, defaultSort, order, sort } =
    table
  const rowCount = rows.length
  const selectedCount = selected.length

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow
        key="head"
        sx={{ '& .MuiTableCell-root': { borderColor: '#F5F5F5' } }}
      >
        {isCheckBox && (
          <StyledCheckBoxCell>
            <Checkbox
              data-testid="select-all-element"
              color="primary"
              indeterminate={selectedCount > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selectedCount === rowCount}
              onChange={(event) =>
                dispatch(events.handleSelectAllClick(event, rows))
              }
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </StyledCheckBoxCell>
        )}
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }

            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={sort === valueId ? order : false}
                width={headCell.width}
                minWidth={headCell.minWidth}
                sx={{ color: headCell.headCellColor }}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    dispatch(events.handleRequestSort(valueId, table, headCell))
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.id == 'isPinned'
                    ? `Pin (${totalPinned}/6)`
                    : headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = ({ rowsPerPageOptions = ROW_PAGE_PER_LIST }) => {
  const dispatch = useDispatch()
  const {
    table,
    customStyle,
    customContainerStyle,
    onDownload,
    handleSearch,
    filter,
    handleDateSearch,
  } = useSelector((state) => state.table)

  const {
    rows,
    selected,
    isCheckBox,
    headCells,
    isShowPagination,
    allCount,
    rowsPerPage,
    tablePage,
    sxTable,
  } = table
  const { quickFilter } = filter

  return (
    <StyledFullWidth sx={customStyle}>
      {(onDownload || handleSearch || handleDateSearch) && (
        <EnhancedTableToolbar />
      )}
      {!_.isNil(quickFilter) && quickFilter.length > 0 && <QuickFilter />}
      <TableContainer sx={customContainerStyle}>
        <StyledTable sx={sxTable}>
          <EnhancedTableHead />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => {
                const isItemSelected = events.isSelected(selected, row.uuid)
                const labelId = `enhanced-table-checkbox-${rowIndex}`
                return (
                  <StyledTableRow
                    key={rowIndex}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {isCheckBox && (
                      <StyledCheckBoxCell>
                        <Checkbox
                          data-testid={`select-${rowIndex}-element`}
                          onClick={() =>
                            dispatch(events.handleClick(selected, row.uuid))
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledCheckBoxCell>
                    )}
                    {headCells &&
                      headCells.map((i, cellIndex) =>
                        events.checkConditionView(
                          headCells,
                          row,
                          rowIndex,
                          cellIndex,
                        ),
                      )}
                  </StyledTableRow>
                )
              })}
            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length + 1}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => events.handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(e, tPage) => dispatch(events.handleChangePage(tPage))}
          onRowsPerPageChange={(event) =>
            dispatch(events.handleChangeRowsPerPage(event))
          }
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      )}
    </StyledFullWidth>
  )
}

export default EnhancedTable
