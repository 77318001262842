import * as yup from 'yup'

export const validationSchema = yup.object({
  name: yup
    .string()
    .max(255, 'ชื่อหมวดหมู่หลักกรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  active: yup.boolean(),
})
