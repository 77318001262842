import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../../../../../../context/dialog-context'
import Table from '../../../../../../../components/Table'
import FilterDrawer from './FilterDrawer'
import { handleFilterTotal } from '../../../../../../../redux/slices/table/events'
import _ from 'lodash'
import { headCells, headCellsAsset, headCellsStockSku } from './events'
import { Card, Typography } from '@mui/material'
import DropdownButton from '../../../components/DropdownButton'
import { PRODUCT_TYPE } from '../../../../../../../constants/stock'
import { store } from '../../../../../../../App'
import callAxios from '../../../../../../../utils/baseService'
import { productWarehouseListFilter } from '../../../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../../../redux/slices/crud'

export const TableHistory = ({
  data,
  headCells,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  sort,
  setSort,
  allCount,
  handleQuickSearch,
  setIsFilterDrawer,
  searchText,
  setSearchText,
  tablePage,
  setTablePage,
  text,
  setText,
  filterTotal,
}) => {
  return (
    <Card>
      <Table
        data={data}
        isCheckBox={false}
        headCells={headCells}
        placeholder="ค้นหาด้วยคีย์เวิร์ด"
        searchKey={'title'}
        setLimit={setLimit}
        order={order}
        setOrder={setOrder}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        allCount={allCount}
        handleSearch={handleQuickSearch}
        onDownload={'hidden'}
        setIsFilterDrawer={setIsFilterDrawer}
        searchText={searchText}
        setSearchText={setSearchText}
        tablePage={tablePage}
        setTablePage={setTablePage}
        text={text}
        setText={setText}
        filterTotal={filterTotal}
        onView={undefined}
        onEdit={undefined}
        onDelete={undefined}
        hideFilter={true}
        toolBarBoxSx={{ flexDirection: 'row-reverse !important' }}
        customToolbar={
          <Typography variant="h6">รายการรหัสทรัพย์สิน</Typography>
        }
      />
    </Card>
  )
}
const Index = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(100)
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)

  const { assetInfo, isReloadAssetListDrawer } = props
  let product = _.get(assetInfo, 'product', '')
  let warehouse = _.get(assetInfo, 'warehouse', '')
  if (assetInfo?.productWarehouse) {
    product = _.get(assetInfo, 'productWarehouse.product', '')
    warehouse = _.get(assetInfo, 'productWarehouse.warehouse', '')
  }

  useEffect(() => {
    if (isReloadAssetListDrawer) {
      fetchDataList('fetch', status, limit, order, page, sort)
      store.dispatch(
        setFieldValue({
          key: 'isReloadAssetListDrawer',
          value: false,
        }),
      )
    }
  }, [isReloadAssetListDrawer])

  useEffect(() => {
    fetchDataList('fetch', status, limit, order, page, sort)
  }, [limit, order, sort, page])

  const onFilterClick = async (filter) => {
    setSearchText('')
    setText('')
    const statusList = []
    if (filter.statusChecked) {
      if (filter.status.activeChecked) {
        statusList.push('ACTIVE')
      }
      if (filter.status.inActiveChecked) {
        statusList.push('INACTIVE')
      }
      if (filter.status.deletedChecked) {
        statusList.push('DELETED')
      }
    }
    setStatus(statusList)
    const levelText = filter?.levelChecked ? filter.levelText : ''
    setSearchText(levelText)
    setTablePage(0)
    fetchDataList('filter', statusList, limit, order, 1, sort, levelText)
    setFilterTotal(handleFilterTotal(filter))
  }

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    const statusList = []
    fetchDataList('search', statusList, limit, order, 1, sort, text)
  }

  const fetchDataList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    // levelOfLearnerText,
  ) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      status: statusList,
      // levelOfLearner: method === 'filter' ? levelOfLearnerText : '',

      warehouseUuid: _.get(warehouse, 'uuid', undefined),
      productUuid: _.get(product, 'uuid', undefined),
      search: search ?? '',
      startCreatedDate: '',
      endCreatedDate: '',
      startUpdatedDate: '',
      endUpdatedDate: '',
      createdBy: '',
      updatedBy: '',
      warehouseStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
      productStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
      checkLogSku: true,
    }
    const isCancel = await callAxios
      .post(productWarehouseListFilter, body)
      .then((res) => {
        const data = res.data
        let result = _.get(data, 'result', [])
        let year = _.get(data, 'productWarehouse.product.refundYear', '')
        let month = _.get(data, 'productWarehouse.product.refundMonth', '')
        let day = _.get(data, 'productWarehouse.product.refundDay', '')
        let refundDayText = ''
        if (day || month || year) {
          if (year !== null) {
            refundDayText += `${year} ปี`
          }
          if (month !== null) {
            refundDayText += `${month} เดือน`
          }
          if (day !== null) {
            refundDayText += `${day} วัน`
          }
        } else {
          refundDayText = '-'
        }
        result = result.map((item) => {
          return {
            ...item,
            days: refundDayText,
            dropdownAction: (
              <DropdownButton
                isAsset={true}
                ticketId={item?.ticket?.uuid}
                requisitionId={item?.requisitionId}
                item={item}
              />
            ),
          }
        })
        setDataList(filterDataList(result))
        setAllCount(_.get(data, 'totalCount', 0))
      })
      .catch((e) => {
        console.log(e)
      })
    if (!isCancel) setIsLoading(false)
    setIsLoading(false)
  }

  const { formData } = store.getState().crud
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  // const productType = _.get(assetInfo, 'product.productType', '')
  let headCellData = headCells
  if ([PRODUCT_TYPE.ASSET, PRODUCT_TYPE.LICENSE].includes(productType))
    headCellData = headCellsAsset.filter((item) => {
      return !(productType === PRODUCT_TYPE.LICENSE && item.id === 'assetNo')
    })
  if ([PRODUCT_TYPE.STOCK_ASSET].includes(productType))
    headCellData = headCellsStockSku

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <TableHistory
          data={dataList}
          headCells={headCellData}
          setLimit={setLimit}
          order={order}
          setOrder={setOrder}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          allCount={allCount}
          handleQuickSearch={handleQuickSearch}
          onDownload={'hidden'}
          setIsFilterDrawer={setIsFilterDrawer}
          searchText={searchText}
          setSearchText={setSearchText}
          tablePage={tablePage}
          setTablePage={setTablePage}
          text={text}
          setText={setText}
          setIsLoading={setIsLoading}
          setDialog={setDialog}
          setDataList={setDataList}
          setAllCount={setAllCount}
          filterTotal={filterTotal}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
      />
    </Box>
  )
}
export default Index

export const filterDataList = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      levelOfLearner: _.get(item, 'levelOfLearner.title', '-'),
    }
  })
}
