import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '../../../components/redux/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { breadcrumbOicList } from '../model'
import { LoadingPage } from '../Styled'
import { fetchDataList } from './event'
import { StyledCard, StyledContainer, StyledContent } from './Styled'
import Breadcrumbs from '../../../components/Breadcrumbs'
import FilterDrawer from './FilterDrawer'
import Header from './Header'

const List = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList('initial', table, page, filterProp, searchText, setInitial)
    )
  }, [])

  useEffect(() => {
    if (!isInitial) fetchData()
  }, [limit, order, page, sort])

  const fetchData = () =>
    dispatch(fetchDataList('fetch', table, page, filterProp, searchText))
  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">{breadcrumbOicList[1].title}</Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbOicList} />

        <Header fetchData={fetchData} />

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="oicExamResult"
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default List
