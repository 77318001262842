import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getETestingAndEEvaluation,
  getManageClassDetailByUuid,
  getManageClassDownloadFiles,
  getManageClassEvent,
  getManageClassGraph,
  manageClassCheckClose,
  putStartETestEEva,
} from '../../../services/manageClass/detail'
import { initialState } from './model'

const manageClassDetail = createSlice({
  name: 'manageClassDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setSharePropValue: (state, { payload }) => {
      const { index, key, value } = payload
      _.set(state.shareProps, `[${index}].${key}`, value)
    },
    setCheckCloseChecked: (state, { payload }) => {
      state.checkClose.checked = payload
    },
    setQREnrollProps: (state, { payload }) => {
      state.qrEnrollProps = { ...state.qrEnrollProps, ...payload }
    },
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload
    },
    setExpiryDate: (state, { payload }) => {
      state.qrEnrollProps.expiryDate = payload
    },
    setExpiryTime: (state, { payload }) => {
      state.qrEnrollProps.expiryTime = payload
    },
    setLink: (state, { payload }) => {
      state.qrEnrollProps.link = payload
    },
    startLoadingSaveQr: (state) => {
      state.qrEnrollProps.isLoadingBtn = true
    },
    stopLoadingSaveQr: (state) => {
      state.qrEnrollProps.isLoadingBtn = false
    },
    startLoadingQRDrawer: (state) => {
      state.qrEnrollProps.isLoading = true
    },
    stopLoadingQRDrawer: (state) => {
      state.qrEnrollProps.isLoading = false
    },
    startLoadingResetQR: (state) => {
      state.qrEnrollProps.isLoadingResetQR = true
    },
    stopLoadingResetQR: (state) => {
      state.qrEnrollProps.isLoadingResetQR = false
    },
    setIsEnable: (state, { payload }) => {
      state.qrEnrollProps.isEnable = payload
    },
    setExpiryType: (state, { payload }) => {
      state.qrEnrollProps.expiryType = payload
    },
    setQrExpired: (state, { payload }) => {
      state.qrEnrollProps.qrExpired = payload
    },
    setStatusQRCode: (state, { payload }) => {
      state.qrEnrollProps.statusQRCode = payload
    },
    setError: (state, { payload }) => {
      state.qrEnrollProps.iserror = payload.iserror
      state.qrEnrollProps.errorMessage = payload.message
    },
    openDatePicker: (state) => {
      state.qrEnrollProps.isOpenDatePicker = true
    },
    closeDatePicker: (state) => {
      state.qrEnrollProps.isOpenDatePicker = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageClassDetailByUuid.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        const status = _.get(data, 'status', 'CANCELED')
        state.data = data
        state.isCancel = status === 'CANCELED'
      })
      .addCase(getManageClassDetailByUuid.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getETestingAndEEvaluation.fulfilled, (state, { payload }) => {
        state.eTestEEvaList = _.get(payload, 'data', '')
        state.shareProps = {
          isOpen: false,
          shareLink: '',
          type: '',
        }
      })
      .addCase(getETestingAndEEvaluation.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(putStartETestEEva.fulfilled, (state, { payload }) => {
        const isCheck = _.get(payload, 'isCheckExist', true)
        state.isSuccess = !isCheck
      })
      .addCase(putStartETestEEva.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getManageClassEvent.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        state.eventList = _.get(data, 'result', [])
      })
      .addCase(getManageClassEvent.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getManageClassDownloadFiles.fulfilled, (state, { payload }) => {
        state.files = _.get(payload, 'data', '')
      })
      .addCase(getManageClassDownloadFiles.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(manageClassCheckClose.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        state.checkClose = {
          ...data,
          checked: {
            quiz: false,
            quizAndMail: false,
            nameCheck: false,
            assessment: false,
            acceptAll: false,
          },
        }
      })
      .addCase(manageClassCheckClose.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getManageClassGraph.fulfilled, (state, { payload }) => {
        state.graph = _.get(payload, 'data', '')
      })
      .addCase(getManageClassGraph.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setSharePropValue,
  setCheckCloseChecked,
  setQREnrollProps,
  setIsMobile,
  setExpiryDate,
  setExpiryTime,
  setLink,
  startLoadingSaveQr,
  stopLoadingSaveQr,
  startLoadingQRDrawer,
  stopLoadingQRDrawer,
  startLoadingResetQR,
  stopLoadingResetQR,
  setIsEnable,
  setExpiryType,
  setQrExpired,
  setStatusQRCode,
  setError,
  openDatePicker,
  closeDatePicker,
} = manageClassDetail.actions

export default manageClassDetail.reducer
