import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  manageClassStation,
  manageClassFilter,
  manageClassAssignee,
  manageClassDocumentFilter,
  manageClassOicUpload,
  manageDocsUpload,
  filterTrainingPlatform,
  uploadFileLearnerDocument,
  manageClassPutLearnerDocument,
} from '../../utils/apiPath'
import callAxios from '../../utils/baseService'
import axios from 'axios'

export const getDataStation = createAsyncThunk(
  'manageClass/station',
  async (obj, { rejectWithValue }) => {
    try {
      const response = await callAxios.get(manageClassStation)
      return mapLabelStation(_.get(response, 'data', []))
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const mapLabelStation = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    const labelText = `(${item.initial}) ${item.station}`
    return { label: labelText, value: item.uuid }
  })
}

export const fetchDataFilter = createAsyncThunk(
  'manageClass/filter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await callAxios.post(manageClassFilter, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchDataApplicationDocumentsFilter = createAsyncThunk(
  'manageClass/filter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await callAxios.post(manageClassDocumentFilter, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchAdminList = createAsyncThunk(
  'manageClass/getAdmin',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await callAxios.get(`${manageClassAssignee}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchTrainingPlatform = createAsyncThunk(
  'manageClass/fetchTrainingPlatform',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: 'ASC',
        sort: 'id',
        status: ['ACTIVE'],
        trainingPlatform: '',
        abbreviation: '',
      }
      const response = await callAxios.post(filterTrainingPlatform, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateAdmin = createAsyncThunk(
  'manageClass/updateAdmin',
  async (body, { rejectWithValue }) => {
    try {
      const response = await callAxios.put(`${manageClassAssignee}`, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadFileOicExam = createAsyncThunk(
  'manageClassOicExam/uploadFile',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(manageClassOicUpload, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadFileDocs = createAsyncThunk(
  'uploadFileDocs/uploadFile',
  async (body, { rejectWithValue }) => {
    try {
      return await callAxios.post(manageDocsUpload, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadLearnerDocument = createAsyncThunk(
  'classDetail/uploadLearnerDocument',
  async ({ body, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(uploadFileLearnerDocument, body)
      return { ..._.get(response, 'data', {}), name }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putLearnerDocument = createAsyncThunk(
  'classDetail/putLearnerDocument',
  async ({ body, name }, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassPutLearnerDocument, body)
      return { ..._.get(response, 'data', {}), name }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
