import React from 'react'
import { FormLabel } from './components/FormLabel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/system'
import { StyledInputHidden, StyledUploadContainer } from './styled'
import { useDropzone } from 'react-dropzone'
import { InitialUploadWording } from './components/InitialUploadWording'
import Button from '@mui/material/Button'
import UploadFileRounded from '@mui/icons-material/UploadFileRounded'
import { CropperDialog } from './components/CropperDialog'
import handleOpenCropperDialog from './handler/handleOpenCropperDialog'
import { CoverImage } from './components/CoverImage'
import { useEffect, useState } from 'react'
import { Uploading } from './components/Uploading'
import { UploadError } from './components/UploadError'
import {
  clearFieldUploadValue,
  setFieldUploadValue,
} from '../../redux/slices/uploadCropper'
import { SIZE_TYPE_VALUE } from '../../constants/eConfig'

export const UploadCropper = ({
  folder = '',
  accept,
  multiple = false,
  onChange = () => {},
  value,
  fileName = '',
  isError,
  disabled,
  maxSize = 2 * SIZE_TYPE_VALUE.MB,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [imageLocalSrc, setImageLocalSrc] = useState(undefined)
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    disabled,
    maxSize,
    onDrop: (files, fileRejections) => {
      setImageLocalSrc(files[0])
      handleOpenCropperDialog({ files, fileRejections })
    },
  })
  const isOpen = useSelector(
    (state) => state.uploadCropper.isOpen,
    shallowEqual,
  )

  useEffect(() => {
    if (value) {
      dispatch(
        setFieldUploadValue({ key: 'fileName', value: fileName || value }),
      )
      dispatch(setFieldUploadValue({ key: 'coverImage', value: value }))
    }
  }, [value])

  useEffect(() => {
    return () => dispatch(clearFieldUploadValue())
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap={3} {...rest}>
      <FormLabel required title="อัปโหลดรูปภาพหน้าปก" variant="body1b" />
      <Box display="flex" justifyContent="center" position="relative">
        <Box display="flex" flexDirection="column" width="100%">
          <StyledUploadContainer isError={isError} {...getRootProps()}>
            <InitialUploadWording />
            <Uploading />
            <CoverImage onChange={onChange} disabled={disabled} />
            <UploadError />
            <label htmlFor="image-upload">
              <Button
                variant="contained"
                startIcon={<UploadFileRounded />}
                disabled={disabled}
              >
                อัปโหลดรูป
              </Button>
              <StyledInputHidden
                disabled={disabled}
                data-testid="input-upload"
                id="image-upload"
                type="file"
                {...getInputProps()}
              />
            </label>
          </StyledUploadContainer>
        </Box>
      </Box>
      {isOpen && (
        <CropperDialog
          folder={folder}
          imageLocalSrc={imageLocalSrc}
          onChange={onChange}
        />
      )}
    </Box>
  )
}
