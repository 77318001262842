import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ChevronRight } from '@mui/icons-material'
import { StyledButton } from '../Styled'
import {
  scheduleAssociation,
  scheduleCompany,
  scheduleOtherModel,
} from '../../models/schedule'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import _ from 'lodash'
import { SCHEDULE_STATUS } from '../../../../../constants/eExamination'
import ScheduleInfoCard from '../ModuleCard/ScheduleInfoCard'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'

const index = () => {
  const scheduleComp = scheduleCompany()
  const colorArray = _.defaultTo(scheduleComp, []).map(
    (item) => item.dotColor,
  )
  const scheduleAssoc = scheduleAssociation()
  const scheduleOther = scheduleOtherModel()
  const allSchedules = [...scheduleComp, ...scheduleAssoc, ...scheduleOther]
  const result = allSchedules.reduce((acc, curr) => {
    acc[curr.value] = (acc[curr.value] || 0) + curr.number
    return acc
  }, {})
  const finalResult = [
    result[SCHEDULE_STATUS.OPEN_REGISTER],
    result[SCHEDULE_STATUS.CLOSED_REGISTER],
    result[SCHEDULE_STATUS.CANCEL],
  ]
  const totalModule = _.sum(finalResult)
  return (
    <Box height={'100%'}>
      <Card sx={{height: '100%'}}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">รอบสอบ</Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              onClick={() => handleClickOpenDrawer('schedule')}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </Box>
          <Box display="flex" gap={2} mt={3} alignItems="center">
            <DoughnutChart
              dataSet={{
                data: finalResult,
                backgroundColor: colorArray,
              }}
              height={224}
              label="ทั้งหมด"
              value={totalModule}
              eleId={'scheduleDougnutChart'}
            />
            <Box display="flex" flexDirection="row" gap={1}>
              <ScheduleInfoCard dataList={_.defaultTo(scheduleComp, [])} title="สนามสอบบริษัท" totalModule={totalModule} />
              <ScheduleInfoCard dataList={_.defaultTo(scheduleAssoc, [])} title="สนามสอบสมาคม" totalModule={totalModule} />
              <ScheduleInfoCard dataList={_.defaultTo(scheduleOther, [])} title="สนามสอบอื่นๆ" totalModule={totalModule} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default index
