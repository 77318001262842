import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Close from '@mui/icons-material/Close'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import QrCodeOutlined from '@mui/icons-material/QrCodeOutlined'
import { eTestEEvaText } from '../../../../../constants/manageClass'
import { StyledColumn, StyledRow } from '../../Dashboard/Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledBoxInput,
  StyledInputUrl,
  StyledBoxButton,
  StyledShareContent,
} from './Styled'
import { onClickShare, onCloseDrawer } from './events'
import { QRCodeTemplate } from '../../../../../components/QRCodeTemplate'
import { useTheme } from '@mui/system'
import { downloadQRCode } from '../../../../../utils/lib'

const ShareDrawer = ({ index }) => {
  const { shareProps, eTestEEvaDetails, course } = useSelector(
    (state) => ({
      shareProps: _.get(state.manageClassDetail.shareProps, index, {}),
      eTestEEvaDetails: state.manageClassDetail.eTestEEvaList.result[index],
      course: _.get(state.manageClassDetail.data, 'course', ''),
    }),
    shallowEqual,
  )
  const isOpen = _.get(shareProps, 'isOpen', false)
  const type = _.get(shareProps, 'type', 'E_TESTING')
  const shareLink = _.get(shareProps, 'shareLink', '-')
  const isShare = _.get(shareProps, 'isShare', false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const typeEtest = _.get(eTestEEvaDetails, 'type', '')
  const titleQR = `QR Code ${
    type === 'E_TESTING' ? `แบบทดสอบ ${typeEtest}` : `แบบประเมิน`
  } `

  return (
    <StyledDrawer open={isOpen} onClose={() => dispatch(onCloseDrawer(index))}>
      <StyledContent>
        <QRCodeTemplate
          id="share-qr-code"
          isHidden={true}
          link={shareLink}
          title={titleQR}
          subtitle={course}
          titleSx={{ mt: 0 }}
        />
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">แชร์{eTestEEvaText[type]}</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={() => dispatch(onCloseDrawer(index))}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <StyledColumn
          sx={{
            width: 'auto',
            height: '100vh',
            p: 3,
            gap: 2,
            backgroundColor: theme?.palette?.common?.white,
          }}
        >
          <StyledRow sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">เปิดแชร์</Typography>
            <Switch
              inputProps={{ 'data-testid': 'switch-share' }}
              checked={isShare}
              onChange={(e) => dispatch(onClickShare(e, index))}
            />
          </StyledRow>
          {isShare && (
            <StyledShareContent>
              <QRCodeTemplate
                id="show-qr-code"
                boxSx={{
                  border: `1px solid ${theme?.palette?.silver?.primary}`,
                }}
                size={106}
                link={shareLink}
                variantTitle={'body1b'}
                title={titleQR}
                subtitle={course}
                titleSx={{ fontSize: 14, mx: 1 }}
              />
              <StyledBoxInput>
                <StyledInputUrl disabled value={'  ' + shareLink} />

                <StyledBoxButton>
                  <Button
                    sx={{ width: 182 }}
                    data-testid="btn-download"
                    variant="outlined"
                    size="m"
                    startIcon={<QrCodeOutlined />}
                    onClick={() =>
                      downloadQRCode(
                        'share-qr-code',
                        'QR Code แบบประเมิน ' + course,
                      )
                    }
                  >
                    <Typography variant="buttonm">ดาวน์โหลด QR Code</Typography>
                  </Button>
                  <Button
                    sx={{ width: 124 }}
                    data-testid="btn-copy"
                    variant="outlined"
                    size="m"
                    startIcon={<ContentCopyOutlined />}
                    onClick={() => navigator?.clipboard?.writeText(shareLink)}
                  >
                    <Typography variant="buttonm">คัดลอกลิงก์</Typography>
                  </Button>
                </StyledBoxButton>
              </StyledBoxInput>
            </StyledShareContent>
          )}
        </StyledColumn>
      </StyledContent>
    </StyledDrawer>
  )
}

export default ShareDrawer
