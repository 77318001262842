import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Column } from '../../../../../../components/Preview/Layout/Styled'
import BasicTable from '../../BasicTable'
import { TABLE_KEY } from '../../model'
import { fetchRandomClick } from './events'

const ClickHistory = () => {
  const { id: uuid } = useParams()
  const table = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail.tables[TABLE_KEY.click],
    shallowEqual,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRandomClick({ ...table, uuid }))
  }, [table.limit, table.page])

  return (
    <Column sx={{ gap: 1 }}>
      <Typography variant="h6">ประวัติการสุ่มคลิก</Typography>
      <BasicTable keyTable={TABLE_KEY.click} />
    </Column>
  )
}

export default ClickHistory
