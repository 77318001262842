import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import TableAttendance from '../../../../components/redux/Table/TableAttendance'

import { fetchDataCheckName } from './events'
import { useParams } from 'react-router-dom'

const Index = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()

  useEffect(() => {
    dispatch(fetchDataCheckName(uuid))
  }, [])

  return (
    <Box sx={{ mt: 3 }}>
      <TableAttendance id="checkNameTable" />
    </Box>
  )
}

export default Index
