import * as yup from 'yup'

export const schema = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อคะแนน')
    .max(255, 'ชื่อคะแนนกรอกได้ไม่เกิน 255 ตัวอักษร'),
  point: yup.string().when('isDraft', {
    is: false,
    then: yup
      .string()
      .required('กรุณากรอกจำนวนคะแนน')
      .test('point-test', 'กรุณากรอกจำนวนคะแนนเป็นตัวเลข', (val) => {
        return /^[0-9]+$/.test(val)
      })
      .test('point-test', 'จำนวนคะแนนกรอกได้ไม่เกิน 4 หลัก', (val) => {
        return val?.length <= 4
      }),
  }),
  course: yup.array().when('isDraft', {
    is: false,
    then: yup.array().of(
      yup.object({
        courseType: yup.object({
          value: yup.string().required('กรุณาเลือกรูปแบบหลักสูตร'),
          label: yup.string(),
        }),
        uuid: yup.object({
          value: yup.string().required('กรุณาเลือกหลักสูตร'),
          label: yup.string(),
        }),
      }),
    ),
  }),
  remark: yup.string().when('isDraft', {
    is: false,
    then: yup.string().max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  badgeName: yup.string().when(['isDraft', 'badgeStatus'], {
    is: (isDraft, badgeStatus) => !isDraft && badgeStatus == 'ACTIVE',
    then: yup
      .string()
      .required('กรุณากรอกชื่อเหรียญรางวัล')
      .max(255, 'ชื่อเหรียญรางวัลกรอกได้ไม่เกิน 255 ตัวอักษร'),
  }),
  badgeDescription: yup.string().when(['isDraft', 'badgeStatus'], {
    is: (isDraft, badgeStatus) => !isDraft && badgeStatus == 'ACTIVE',
    then: yup.string().max(3000, 'คำอธิบายกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  badgeImage: yup.object().when(['isDraft', 'badgeStatus'], {
    is: (isDraft, badgeStatus) => !isDraft && badgeStatus == 'ACTIVE',
    then: yup.object({
      key: yup.string().required('กรุณาอัปโหลดรูปเหรียญรางวัล'),
      fileName: yup.string(),
      fileSize: yup.string(),
    }),
  }),
  badgeRemark: yup.string().when(['isDraft', 'badgeStatus'], {
    is: (isDraft, badgeStatus) => !isDraft && badgeStatus == 'ACTIVE',
    then: yup
      .string()
      .max(3000, 'หมายเหตุเหรียญรางวัลกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  startDate: yup.string().when('isDraft', {
    is: false,
    then: yup
      .string()
      .required('กรุณากำหนดวันเริ่มต้น')
      .test('startDate-test', 'กรุณากรอกวันเริ่มต้นให้ถูกต้อง', (val) => {
        return val !== 'Invalid Date'
      }),
  }),
  endDate: yup.string().when(['isDraft', 'isDueDate'], {
    is: (isDraft, isDueDate) => !isDraft && isDueDate,
    then: yup
      .string()
      .required('กรุณากำหนดวันสิ้นสุด')
      .test('endDate-test', 'กรุณากรอกวันสิ้นสุดให้ถูกต้อง', (val) => {
        return val !== 'Invalid Date'
      })
      .test(
        'date-range-test',
        'ไม่สามารถกรอกวันสิ้นสุดน้อยกว่าวันเริ่มต้นได้',
        (val, context) => {
          return val >= context.parent.startDate
        },
      ),
  }),
})
