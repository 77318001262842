import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../../../../redux/slices/eEvaluationForm'

export const handleChange = (props) => (dispatch) => {
  const {
    sequence,
    indexAnswer,
    key,
    value,
    answers,
    isSubQuestions,
    subQuestionIndex,
  } = props

  let result = []
  if (answers.length) {
    result = answers.map((item, indexMap) => {
      if (indexMap === indexAnswer) {
        if (_.isNumber(value)) {
          item = {
            ...item,
            nextQuestionType: 'NEXT_TO',
            nextQuestionSequence: value,
          }
        } else {
          item = {
            ...item,
            [key]: value,
          }
        }
      }
      return item
    })
  }

  dispatch(
    setChangeQuestionByKey({
      type: !isSubQuestions ? 'questions' : 'subQuestions',
      sequence,
      key: !isSubQuestions ? 'answers' : `value[${subQuestionIndex}].answers`,
      value: result,
    })
  )
}

export const handleDelete = (props) => (dispatch) => {
  const {
    sequence,
    indexAnswer,
    answers,
    isSubQuestions,
    subQuestionIndex,
    numberOfAnswers,
  } = props
  let result = []
  if (answers.length) {
    let sequence = 0
    answers.filter((item, indexFilter) => {
      if (indexFilter !== indexAnswer) {
        sequence++
        result.push({ ...item, sequence })
      }
    })
  }
  const newType = !isSubQuestions ? 'questions' : 'subQuestions'

  dispatch(
    setChangeQuestionByKey({
      type: newType,
      sequence,
      key: !isSubQuestions ? 'answers' : `value[${subQuestionIndex}].answers`,
      value: result,
    })
  )

  // set numberOfAnswers  = max length answers
  if (result.length < numberOfAnswers) {
    dispatch(
      setChangeQuestionByKey({
        type: newType,
        sequence,
        key: !isSubQuestions
          ? 'numberOfAnswers'
          : `value[${subQuestionIndex}].numberOfAnswers`,
        value: result.length,
      })
    )
  }
}
