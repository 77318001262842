import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
import ReactPlayer from 'react-player'

export const StyledQuestionCard = styled(Card)(({ theme }) => ({
  width: 896,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: 672, // 768 - 24 - 24 - 48(left menu)
  },
  [theme.breakpoints.down('sm')]: {
    width: 343, // 375 - 20 - 20
  },
}))

export const StyledVideoPreview = styled((props) => (
  <ReactPlayer controls {...props} />
))(({ theme }) => ({
  width: 450,
  height: 300,
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme?.palette?.other?.outlineBorder,
  marginLeft: 0,
  marginBottom: 0,
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    height: '207px !important',
  },
}))

export const StyledImagePreview = styled('img')(({ theme, choice }) => ({
  width: choice ? 300 : 450,
  height: choice ? 200 : 300,
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme?.palette?.other?.outlineBorder,
  marginLeft: choice ? theme.spacing(3.5) : 0,
  marginBottom: choice ? theme.spacing(1) : 0,
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    width: choice ? 261 : '100%',
    height: choice ? 174 : 207,
  },
}))

export const StyledChoiceContainer = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}))

export const StyledInfoAlert = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  color: theme?.palette?.warning?.main,
}))
