import React, { useEffect, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useLocalStorage } from '@rehooks/local-storage'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import FilterDrawer from './FilterDrawer'
import {
  productType,
  downloadProductType,
  filterProductType,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'productType',
    label: 'ทักษะที่ได้จากหลักสูตร',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ทักษะที่ได้จากหลักสูตร', link: '/', pointer: false },
]

export const onView = (row, history) => {
  history.push('/master-data/product-type/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/product-type/edit/' + row.uuid)
}

export const onDelete = async ({
  row,
  setDialog,
  setIsLoading,
  handleDelete,
}) => {
  setIsLoading(false)
  setDialog({
    open: true,
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    onConfirmClick: () => handleDelete(row),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const mainHandleDelete = async (row, setIsLoading, setDialog) => {
  const path = productType + `/${row.uuid}`
  setIsLoading && setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading && setIsLoading(false)
    },
  })
}

export const onFilterClick = async ({
  limit,
  order,
  sort,
  filter,
  setSearchText,
  setText,
  setStatus,
  setTablePage,
  fetchProductTypeList,
  setFilterTotal,
}) => {
  setSearchText('')
  setText('')

  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)

  const productTypeText = filter?.productTypeChecked
    ? filter.productTypeText
    : ''
  setSearchText(productTypeText)
  setTablePage(0)

  fetchProductTypeList({
    method: 'filter',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: productTypeText,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const handleQuickSearch = async ({
  limit,
  order,
  sort,
  valueText,
  setTablePage,
  fetchProductTypeList,
}) => {
  setTablePage(0)
  const statusList = []
  fetchProductTypeList({
    method: 'search',
    statusList: statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: valueText,
  })
}

export const handleAddClick = (history) => {
  history.push('/master-data/product-type/form')
}

const ProductTypeList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [productList, setProductList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)

  useEffect(() => {
    setIsPageLoading(true)

    fetchProductTypeList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
    })
  }, [limit, order, sort, page])

  const onDownload = async (selected) => {
    setIsLoading(true)
    const body = {
      productType: '',
      order: order.toUpperCase(),
      sort: sort === '' ? 'updatedAt' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
    }
    await handleDownload({
      url: downloadProductType,
      body: body,
      fileName: `รายการทักษะที่ได้จากหลักสูตร.xlsx`,
    })
    setIsLoading(false)
  }

  const fetchProductTypeList = async ({
    method,
    statusList,
    valueLimit,
    valueOrder,
    valuePage,
    valueSort,
    search,
  }) => {
    const sortBy = valueSort === '' ? 'updatedAt' : valueSort
    const realPage = valuePage <= 0 ? 1 : +valuePage
    setIsPageLoading(true)

    const body = {
      limit: valueLimit,
      page: realPage,
      order: valueOrder.toString().toUpperCase(),
      sort: sortBy,
      productType: method !== 'fetch' ? search : searchText,
      status: statusList,
    }

    callAPI({
      url: filterProductType,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setProductList(res.result)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            ทักษะที่ได้จากหลักสูตร (Product Type)
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />

        <Button
          data-testid={`btn-add-product-type`}
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          disabled={!hasPermissionCreate}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleAddClick(history)}
        >
          เพิ่มทักษะที่ได้จากหลักสูตร
        </Button>

        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={productList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            onDelete={(row) => {
              onDelete({
                row,
                setIsLoading,
                setDialog,
                handleDelete: () =>
                  mainHandleDelete(row, setIsLoading, setDialog),
              })
            }}
            placeholder="ค้นหาทักษะที่ได้จากหลักสูตร"
            searchKey={'productType'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(valueText) =>
              handleQuickSearch({
                limit,
                order,
                sort,
                valueText,
                setTablePage,
                fetchProductTypeList,
              })
            }
            onDownload={onDownload}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick({
            limit,
            order,
            sort,
            filter,
            setSearchText,
            setText,
            setStatus,
            setTablePage,
            fetchProductTypeList,
            setFilterTotal,
          })
        }
      />
    </Box>
  )
}
export default ProductTypeList
