import {
  setDeleteStaff,
  setEditStaff,
} from '../../../../../../redux/slices/manageClassForm'

export const convertTime = (time) => {
  const hoursMinutes = String(time)
  return hoursMinutes
}

export const handleInputChange = (e, props) => (dispatch) => {
  const { sIndex, dIndex } = props
  const value = { uuid: e.target.value }
  const keyField = e.target.name
  dispatch(
    setEditStaff({
      sIndex,
      dIndex,
      keyField,
      value,
    })
  )
}

export const handleTimeInputChange = (e, keyField, props) => (dispatch) => {
  const { sIndex, dIndex } = props
  const value = e.target.value
  dispatch(
    setEditStaff({
      sIndex,
      dIndex,
      keyField,
      value,
    })
  )
}

export const handleDelete = (props) => (dispatch) => {
  const { sIndex, setNewSpeakerList } = props
  setNewSpeakerList((staffVal) => {
    const newStaffs = [...staffVal]
    newStaffs.splice(sIndex, 1)
    return newStaffs
  })
  dispatch(setDeleteStaff(props))
}
