import React from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowLeftTwoTone from '@mui/icons-material/ArrowLeftTwoTone'
import ArrowRightTwoTone from '@mui/icons-material/ArrowRightTwoTone'
import { StyledMainTab, StyledMainTabs } from './Styled'

const MainTabs = ({ tabs, value, onChange }) => {
  return (
    <StyledMainTabs
      indicatorColor="secondary"
      variant="scrollable"
      scrollButtons
      selectionFollowsFocus
      ScrollButtonComponent={handleScrollButton}
      value={value}
      onChange={onChange}
    >
      {tabs.map((item, index) => (
        <StyledMainTab
          key={index}
          label={<Typography variant="span">{item.label}</Typography>}
          value={item.value}
          data-testid={`main-tab-${index}`}
        />
      ))}
    </StyledMainTabs>
  )
}

export default MainTabs

export const handleScrollButton = (props) => {
  if (props.direction == 'left') {
    return (
      <IconButton sx={{ width: 48, borderRadius: 0 }} {...props}>
        <ArrowLeftTwoTone />
      </IconButton>
    )
  } else {
    return (
      <IconButton sx={{ width: 48, borderRadius: 0 }} {...props}>
        <ArrowRightTwoTone />
      </IconButton>
    )
  }
}
