import React from 'react'
import _ from 'lodash'
import Template from '../../../../components/Template'
import Header from './Header'
import Content from './Content'

import { StyledWrapper } from './styled'
import List from './List'

export const Preview = () => {
  const data = JSON.parse(localStorage.getItem('ELCollectionPreviewData'))
  const eLearningCollectionCourses = _.get(data, 'contents', [])
  return (
    <StyledWrapper>
      {data ? (
        <>
          <Header />
          <Template noMinHeight maxWidth={1280}>
            <Content />
          </Template>
          <List courses={eLearningCollectionCourses} />
        </>
      ) : (
        <Template />
      )}
    </StyledWrapper>
  )
}
