import { crudConfig } from '../../crudConfig'

export const breadcrumb = () => [
  { title: 'Expense', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: `รายละเอียด ${crudConfig.moduleTitleFull}`,
    pointer: false,
  },
]
