import React from 'react'
import { StyledColumnTextBox } from '../styled'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

export const InitialUploadWording = () => {
  const { coverImage, isUploading, uploadError } = useSelector(
    (state) => ({
      coverImage: state.uploadCropper.coverImage,
      isUploading: state.uploadCropper.isUploading,
      uploadError: state.uploadCropper.uploadError,
    }),
    shallowEqual,
  )

  if (
    (!_.isUndefined(coverImage) && !_.isEmpty(coverImage)) ||
    isUploading ||
    !_.isEmpty(uploadError)
  )
    return null
  return (
    <>
      <img src="/icon/upload_amico.svg" alt="" />

      <StyledColumnTextBox>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1b">วางไฟล์ที่นี่ </Typography>
          <Typography>(จำกัด 1 ไฟล์/รูป เท่านั้น)</Typography>
        </Box>
        <Typography color="text.secondary">.jpeg / .jpg / .png</Typography>
        <Typography variant="body2" color="text.secondary">
          ขนาดแนะนำ 900x600 px หรือไม่เกิน 2 MB
        </Typography>
      </StyledColumnTextBox>

      <Typography variant="body1b">หรือ</Typography>
    </>
  )
}
