import { StyledCardDrawer } from '../../../Styled'
import TabsAddColumn from './TabsAddColumn'

const ManageColumnCard = () => {
  return (
    <StyledCardDrawer sx={{ gap: 1 }}>
      <TabsAddColumn />
    </StyledCardDrawer>
  )
}
export default ManageColumnCard
