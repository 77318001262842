import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import CustomSwitchInLine from '../../../../../components/CRUD/components/CustomSwitchInLine'
import { FlagReturnSection } from './components/FlagReturnSection'
import { Box, Typography } from '@mui/material'
import { ViewRow } from '../../../../../components/CRUD/components/StyledViewBox'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { getProductType } from '../../utils'
import { handleInputNumber } from '../../../../../components/CRUD/handler/handleInputNumber'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import { regex } from '../../../../../components/CRUD/enum/regex'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { useEffect, useState } from 'react'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { PRODUCT_TYPE_VIEW_TEXT } from '../../../../../constants/stock'
import UploadFile from '../../../../../components/UploadFile'
import { handleUploadFile } from './handler/handleUploadFile'

export const Content = ({ uuid }) => {
  const {
    isSku,
    category,
    subCategoryMaster,
    productImage,
    productImageError,
    crudConfig,
  } = useSelector(
    (state) => ({
      isSku: state.crud.formData.isSku,
      category: state.crud.formData.category,
      subCategoryMaster: state.crud.masterData.subCategory,
      crudConfig: state.crud.crudConfig,
      productImage: state.uploadFile.formData.productImage,
      productImageError: _.find(state.crud.formErrors, {
        path: 'productImage',
      }),
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (isSku === enumStatus.ACTIVE) {
      store.dispatch(
        setFieldValue({
          key: 'formData.costCenter',
          value: null,
        }),
      )
    }
  }, [isSku])

  const [subCategoryOptions, setSubCategoryOptions] = useState({
    isLoading: false,
    options: [],
  })

  useEffect(() => {
    let filterdSubCategory = []
    if (category?.value && !_.isEmpty(subCategoryMaster?.options)) {
      filterdSubCategory = subCategoryMaster?.options.filter(
        (item) => item.categoryUuid === category?.value,
      )
    }
    setSubCategoryOptions({ isLoading: false, options: filterdSubCategory })
  }, [category])

  useEffect(() => {
    if (!productImage?.isUploading && productImage) {
      handleUploadFile(productImage)
    }
  }, [productImage])

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <ViewRow
        boxSx={{ display: 'flex', gap: 3 }}
        titleSx={{ minWidth: 'auto' }}
        title="ประเภททรัพย์สิน"
        value={
          PRODUCT_TYPE_VIEW_TEXT?.[getProductType()] ??
          PRODUCT_TYPE_VIEW_TEXT.STOCK
        }
      />
      <Box display="flex" gap={3}>
        <CustomInput
          required
          fieldName="productName"
          labelName="ชื่อทรัพย์สิน (Item name)"
          placeholder="ระบุชื่อทรัพย์สิน"
        />
        <CustomDropdown
          required
          labelName="หมวดหมู่ (Category)"
          fieldName="category"
          placeholder="เลือก หมวดหมู่"
          handleExtraChange={() => {
            store.dispatch(
              setFieldValue({
                key: 'formData.subCategory',
                value: '',
              }),
            )
          }}
        />
      </Box>

      <Box display="flex" gap={3}>
        <CustomDropdown
          labelName="หมวดหมู่ย่อย (Sub-Category)"
          fieldName="subCategory"
          isCustomOption={true}
          customOptions={subCategoryOptions}
          placeholder="เลือก หมวดหมู่ย่อย"
          noOptionsText={
            category?.value ? 'ไม่มีรายการ' : 'กรุณาเลือก หมวดหมู่'
          }
        />
        <CustomInput
          required
          fieldName="quantityAlert"
          labelName="จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย"
          placeholder="ระบุจำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย"
          textInfo="ระบุตัวเลขได้ไม่เกิน 20 หลัก"
          inputProps={{
            'data-testid': 'input-quantityAlert',
            onInput: (e) => {
              handleInputNumber(e, regex.NUMBER)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
          }}
        />
      </Box>
      <CustomTextArea
        fieldName="description"
        labelName="คำอธิบาย"
        placeholder="กรอกคำอธิบาย"
      />
      <Box id={`${crudConfig.moduleSlug}-productImage`}>
        <Box display="flex">
          <Typography variant="body2" sx={{ mb: 1 }}>
            รูปประกอบ
          </Typography>
        </Box>
        <UploadFile
          sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
          folder="productImage"
          maxFileSize={{ size: 2, sizeType: 'MB' }}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
          }}
          acceptLabel=".jpg, .jpeg, .png"
          fieldName="productImage"
          isMultiple={false}
          maxLength={1}
          isForImage
        />
        {!_.isEmpty(productImageError) && (
          <Box>
            <Typography color={(theme) => theme.palette.error.main}>
              {_.get(productImageError, 'message') ?? ''}
            </Typography>
          </Box>
        )}
      </Box>
      <CustomSwitchInLine fieldName="status" title="สถานะทรัพย์สิน" />
      <CustomSwitchInLine
        fieldName="isSku"
        disabled={!_.isNil(uuid)}
        title="ระบบ รหัสทรัพย์สิน (ไม่สามารถเปลี่ยนแปลงได้)"
      />
      <Box display="flex" gap={3} alignItems="center">
        <CustomDropdown
          boxSx={{ width: '50%', height: '100px' }}
          required
          labelName="Cost center"
          fieldName="costCenter"
          placeholder="เลือก Cost center"
          noOptionsText="ไม่มีรายการ"
          disabled={isSku === enumStatus.ACTIVE}
        />
        <Box height="90px">
          <Typography variant="body2" color="error" visibility={'hidden'}>
            {' '}
          </Typography>
          <Typography variant="body1" my="auto">
            (หากเปิดใช้งาน “ระบบรหัสทรัพย์สิน” ข้อมูล Cost center
            จะผูกกับแต่ละรหัสทรัพย์สินแทน)
          </Typography>
          <Typography variant="body2" color="error" visibility={'hidden'}>
            {' '}
          </Typography>
        </Box>
      </Box>

      <FlagReturnSection />
      <CustomTextArea
        fieldName="remark"
        labelName="หมายเหตุ"
        placeholder="กรอกหมายเหตุ"
      />
    </>
  )
}
