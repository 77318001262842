import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'

export const resetDrawerCheckbox = (key, objValue) => {
  store.dispatch(
    setFieldValue({
      key: `formData.roomManagementDashboard.${key}`,
      value: objValue,
    }),
  )
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `roomManagementDashboard.filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const toggleRoomRequestDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `roomManagementDashboard.roomRequestDrawer.isOpen`,
      value: value,
    }),
  )
}
