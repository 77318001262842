import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Typography, IconButton, Divider } from '@mui/material'
import Close from '@mui/icons-material/Close'
import Table from '../../../../../components/redux/Table'
import { handleCloseHistoryDrawer } from '../../handler/handleCloseHistoryDrawer'
import { StyledDrawer, StyledHeadLabel, StyledContent } from '../../Styled'
import { fetchBudgetManagementHistory } from '../../handler/fetchBudgetManagementHistory'
import { useTheme } from '@mui/system'

export const HistoryDrawer = () => {
  const { uuid } = useParams()
  const theme = useTheme()
  const { table } = useSelector((state) => state.table, shallowEqual)
  const { isShowHistoryDrawer: isOpen } = useSelector(
    (state) => state.crud,
    shallowEqual,
  )
  const { page, sort, limit } = table

  useEffect(() => {
    if (isOpen) {
      fetchBudgetManagementHistory({ table, uuid }).then()
    }
  }, [isOpen, page, sort, limit])

  return (
    <StyledDrawer open={isOpen} onClose={handleCloseHistoryDrawer}>
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ประวัติการแก้ไข</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={handleCloseHistoryDrawer}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledContent>
        <Box sx={{ m: 3, gap: 4 }}>
          <Table
            sxTable={{
              background: theme.palette?.common?.white,
              border: `1px solid ${theme.palette?.text?.lightBlue}`,
              borderRadius: 2,
            }}
          />
        </Box>
      </StyledContent>
    </StyledDrawer>
  )
}
