import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { LoadingButton } from '@mui/lab'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  p: { span: { color: theme?.palette?.warning?.main } },
}))

export const StyledInputImport = styled('input')({ display: 'none' })

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
}))

export const StyledCancelButton = styled((props) => (
  <Button data-testid="btn-cancel-dialog" variant="outlined" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  minWidth: 100,
}))

export const StyledLoadingButton = styled((props) => (
  <LoadingButton
    data-testid="dialog-btn-confirm"
    variant="contained"
    loadingPosition="end"
    color="primary"
    {...props}
  />
))(() => ({
  width: '100px',
}))

export const StyledLoading = styled(Box)(({ theme, isLoading }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: isLoading ? 'flex' : 'none',
  background: theme.palette?.background?.blur,
}))

export const StyledCheckDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  p: { span: { color: theme?.palette?.success?.main } },
}))

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  marginLeft: theme.spacing(3),
  maxHeight: 576,
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledTableHead = styled(Box)(({ theme }) => ({
  height: 56,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  '& > .MuiTypography-root:first-of-type': { width: 38 },
  '& > .MuiTypography-root': {
    width: '40%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(Box)(({ theme, status }) => {
  let color = theme?.palette?.error?.main
  if (isSuccess(status)) color = theme?.palette?.success?.main
  else if (status == 'NOSEAT') color = theme?.palette?.warning?.main
  return {
    height: 52,
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid',
    borderColor: theme.palette?.blue?.blueLine,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    background: isSuccess(status)
      ? theme?.palette?.success?.outlinedHoverBackground
      : 'transparent',
    '& > .MuiTypography-root:first-of-type': { width: 38 },
    '& > .MuiTypography-root': {
      width: '40%',
      fontSize: 18,
      lineHeight: '20px',
    },
    '& > #status': { color },
  }
})

export const isSuccess = (status) => status == 'SUCCESS'
