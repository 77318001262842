export const breadcrumbList = [
  { title: 'E-Certificate', link: '/', pointer: false },
]

export const breadcrumbBackgroundList = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  { title: 'จัดการภาพพื้นหลัง', link: '/', pointer: false },
]

export const breadcrumbBackgroundForm = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  {
    title: 'จัดการภาพพื้นหลัง',
    link: '/e-certification/background',
    pointer: true,
  },
  { title: 'เพิ่มภาพพื้นหลัง', link: '/', pointer: false },
]

export const breadcrumbBackgroundEdit = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  {
    title: 'จัดการภาพพื้นหลัง',
    link: '/e-certification/background',
    pointer: true,
  },
  { title: 'แก้ไขภาพพื้นหลัง', link: '/', pointer: false },
]

export const breadcrumbBackgroundView = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  {
    title: 'จัดการภาพพื้นหลัง',
    link: '/e-certification/background',
    pointer: true,
  },
  { title: 'ภาพพื้นหลัง', link: '/', pointer: false },
]

export const breadcrumbVersion = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const breadcrumbVersionEdit = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  { title: 'เวอร์ชั่น', link: '/e-certification/version', pointer: true },
  { title: 'แก้ไขประกาศนียบัตร', link: '/', pointer: false },
]

export const breadcrumbCreateVersion = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  { title: 'สร้างประกาศนียบัตร', link: '/', pointer: false },
]

export const breadcrumbViewVersion = [
  { title: 'E-Certificate', link: '/e-certification', pointer: true },
  { title: 'รายละเอียดประกาศนียบัตร', link: '/', pointer: false },
]