import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/manageContent/elearning'
import { setInitialTable } from '../../../../redux/slices/table'
import { getHistory } from '../../../../services/manageContent'
import { HISTORY_EVENT } from './view/model'
import { StyledTable } from './view/Styled'

export const headCellsHistory = [
  {
    id: 'createdBy',
    label: 'ผู้ดำเนินการ',
    disablePadding: false,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
  },
  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
  },
]

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`
  dispatch(startLoading())

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: [],
      allCount: 0,
      headCells: headCellsHistory,
    }),
  )
  const result = await dispatch(getHistory(url))
  const payload = _.get(result, 'payload', undefined)

  if (payload) {
    const rows = _.get(result, 'payload.data.result', []).map((row) => ({
      ...row,
      event: HISTORY_EVENT[row.event] || '',
      createdBy: _.get(row, 'createdBy.name', ''),
    }))
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows,
        allCount: _.get(result, 'payload.data.totalCount', 0),
        headCells: headCellsHistory,
        customStyle: StyledTable,
      }),
    )
  }
  dispatch(stopLoading())
}

export const handleSubmitData = (data) => ({
  ...data,
  coursesInformation: _.get(data, 'coursesInformation', []).map((course) => ({
    uuid: _.get(course, 'uuid', ''),
    courses: _.get(course, 'courses', []).map((courses) => ({
      ...courses,
      courses: _.get(courses, 'courses', []).map((obj) => ({
        status: _.get(obj, 'status', false),
        course: {
          courseCode: _.get(obj, 'course.courseCode', ''),
        },
      })),
    })),
    createdAt: _.get(course, 'createdAt', ''),
    distribution: _.get(course, 'distribution', ''),
    level: _.get(course, 'level', ''),
  })),
})
