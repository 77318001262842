import { store } from '../../../../../App'
import { openDialog } from '../../../../../redux/slices/dialog'
import CreateStockDialog from '../../../../../modules/Inventory/Stock/List/CreateStockDialog'

export const handleOpenCreateStockDialog = () => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: false,
      content: <CreateStockDialog />,
    }),
  )
}
