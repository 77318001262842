import { store } from '../../../../../../App'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    stockCategoryName: filterState.stockCategoryChecked
      ? filterState.stockCategoryName
      : '',
    updateStartDate: filterState.updateDateChecked
      ? filterState.updateStartDate
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? filterState.updateFinishDate
      : '',
    createStartDate: filterState.createDateChecked
      ? filterState.createStartDate
      : '',
    createFinishDate: filterState.createDateChecked
      ? filterState.createFinishDate
      : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,
  }
}
