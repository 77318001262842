import React from 'react'
import { StyledContainerBg } from './Styled'

const Background = ({ children, withHeader = false }) => {
  return (
    <StyledContainerBg withHeader={withHeader}>{children}</StyledContainerBg>
  )
}

export default Background
