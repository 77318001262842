/* eslint-disable no-useless-escape */
export const regex = /#(?:[0-9a-fA-F]{3}){1,2}/g
export const regexTagP = /<p[^>]*>/g

export const defaultLoginPage = () => {
  let result = []
  for (let i = 0; i < 6; i++) {
    result.push({
      sequence: i + 1,
      status: 'INACTIVE',
      expand: false,
      image: '',
      title: '',
      description: '',
      remark: '',
      colorTitle: '#FFFFFF',
      colorDescription: '#FFFFFF',
    })
  }
  return result
}

export const addFiledLoginPages = (list) => {
  if (!list.length) return defaultLoginPage()
  return list.map((item) => {
    return {
      ...item,
      expand: false,
    }
  })
}
