import _ from 'lodash'
import {
  setOption,
  startOptionLoading,
  stopOptionLoading,
} from '../../../../../../redux/slices/eLearning/moduleForm'
import { eEvaluationVersionList } from '../../../../../../utils/apiPath'
import callAxios from '../../../../../../utils/baseService'
import { E_EVALUATION_TYPE } from './model'
import { handleChange, handleChangeEvent } from '../../events'

export const handleInitialFetch = (moduleUuid, keyType) => (dispatch) => {
  if (_.isEmpty(moduleUuid)) {
    dispatch(handleChange(keyType, E_EVALUATION_TYPE.CLASS))
    dispatch(fetchEEvaluationList(E_EVALUATION_TYPE.CLASS))
  }
}

export const handleRadioChange = (e, typeField) => async (dispatch) => {
  const keyUuid = `${typeField}.uuid`
  const keyCriteriaType = `${typeField}.criteriaType`

  dispatch(handleChangeEvent(e))
  dispatch(handleChange(keyUuid, ''))
  dispatch(handleChange(keyCriteriaType, ''))
  await dispatch(fetchEEvaluationList(e.target.value))
}

export const fetchEEvaluationList = (typeOfEEvaluation) => async (dispatch) => {
  dispatch(startOptionLoading())
  const response = await callAxios.get(
    `${eEvaluationVersionList}/${typeOfEEvaluation}`
  )
  dispatch(stopOptionLoading())
  if (response.data) {
    const list = _.get(response, 'data', [])
    const eLearningOption = list.map((item) => ({
      label: item.name,
      value: item.uuid,
    }))
    dispatch(setOption(eLearningOption))
  }
}
