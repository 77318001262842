import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxFilterMobile = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

export const BoxHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme?.palette?.background?.paper,
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  height: theme.spacing(10),
  zIndex: 11,
  position: 'sticky',
  top: 0,
  left: 0,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    flexDirection: 'column',
  },
}))

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}))

export const BoxSelect = styled(Box)(({ theme, isFirst }) => ({
  width: 105,
  marginLeft: isFirst ? theme.spacing(2) : theme.spacing(2),
  marginRight: theme.spacing(1),
}))

export const BoxFilter = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
