import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../redux/slices/eTestingForm'

export const handleChange = (props) => (dispatch) => {
  const { keyQuestion, indexAnswer, key, value, answers } = props
  let result = []
  let totalScore = 0
  if (answers.length) {
    result = answers.map((item, indexMap) => {
      if (indexMap === indexAnswer) {
        if (item.questionType === 'TRUE_FALSE') {
          item = { ...item, isCorrect: true }
        }
        
        if (item.questionType === 'MATCHING' && key === 'question') {
          item = { ...item, isCorrect: !_.isEmpty(value) }
        }

        item = { ...item, [key]: value }
      }
      if (item.isCorrect) totalScore += parseFloat(item.score)

      return item
    })
  }
  if (key === 'isCorrect') {
    dispatch(
      setChangeQuestionByKey({
        keyQuestion,
        key: 'score',
        value: totalScore,
      })
    )
  }
  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: 'answers',
      value: result,
    })
  )
}

export const handleDelete = (props) => (dispatch) => {
  const { keyQuestion, indexAnswer, answers } = props
  let result = []
  if (answers.length) {
    let sequence = 0
    answers.filter((item, indexFilter) => {
      if (indexFilter !== indexAnswer) {
        sequence++
        result.push({ ...item, sequence, id: `id-${sequence}` })
      }
    })
  }

  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: 'answers',
      value: result,
    })
  )
}

export const handleNumberInput = (e) => {
  const target = e.target
  const numVal = Math.abs(target.value)
  const isDot = e.nativeEvent.data === '.'
  const isZero = e.nativeEvent.data === '0'

  if (_.get(target.value, '0', '') === '0' && target.value.length === 2) {
    if (isZero) target.value = '0'
    else if (!isDot) target.value = numVal
  }
  
  target.value = numVal <= 100 ? target.value : 100
  if (isDot && numVal === 100) target.value = 100

  const splitNum = target.value.split('.')
  if (splitNum.length === 2 && !_.isEmpty(splitNum[1]) && !isZero) {
    target.value = _.floor(Number(target.value), 2).toString()
  }

  return target.value
}

export const handleChangeScoreAnswer = (props) => (dispatch) => {
  const { keyQuestion, indexAnswer, answers, value } = props
  let result = []
  let totalScore = 0

  if (answers.length) {
    result = answers.map((item, indexMap) => {
      let newScore = indexMap === indexAnswer ? value : item.score

      const regex = /^[0-9]*(\.[0-9]{0,2})?$/
      const checkRegex = regex.test(newScore)

      if (newScore > 100) newScore = 100
      if (!checkRegex) newScore = newScore.substring(0, newScore.length - 1)

      if (newScore === '') newScore = 0
      if (newScore.length >= 2 && newScore !== '0.') {
        const firstCheck = newScore.substring(0, newScore.length - 1)
        if (firstCheck === '0') {
          const lastCheck = newScore.substring(1, newScore.length)
          newScore = lastCheck
        }
      }

      if (item.isCorrect) totalScore += parseFloat(newScore)

      return { ...item, score: newScore }
    })
  }
  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: 'answers',
      value: result,
    })
  )
  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: 'score',
      value: totalScore,
    })
  )
}
