import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { TEST_RESULT_STATUS_TEXT } from '../../../../../constants/eExamination'

const ActionResultExam = ({ row }) => {
  const value = _.get(TEST_RESULT_STATUS_TEXT, row.examResult, '-')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography>{!_.isEmpty(value) ? value : '-'}</Typography>
    </Box>
  )
}

export default ActionResultExam
