import React from 'react'
import CardQuestion from '../CardQuestion'
import Content from '../Content'

const Index = () => {
  return (
    <>
      <CardQuestion text="แบบประเมินวิทยากร" type="lecturerAssessments" />
      <Content type="lecturerAssessments" />
    </>
  )
}

export default Index
