import handleOpenCropperDialog from '../UploadCropper/handler/handleOpenCropperDialog'

export const handleInsertImage = (setImageLocalSrc) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()
  input.onchange = async () => {
    const files = input.files
    const file = files[0]
    if (/^image\//.test(file.type)) {
      setImageLocalSrc(file)
      handleOpenCropperDialog({ files })
    }
  }
}
