import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { vendorTypeEnum } from '../enum/vendorTypeEnum'
import { useSelector, shallowEqual } from 'react-redux'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import { regex } from '../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../components/CRUD/handler/handleInputNumber'
import { handleVendorTypeChange } from '../handler/handleVendorTypeChange'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

export const VendorType = () => {
  // const { uuid } = useParams()
  const { vendorType } = useSelector(
    (state) => ({
      vendorType: state.crud.formData.vendorType,
    }),
    shallowEqual,
  )

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6" color={'text.primary'}>
          ประเภท Vendor (Vendor Type)
        </Typography>
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Box>
      <CustomDropdown
        required={false}
        disabled={!hasPermissionCreate}
        fieldName="vendorType"
        placeholder="เลือกประเภท Vendor (Vendor Type)"
        handleExtraChange={handleVendorTypeChange}
      />
      {vendorType?.value === vendorTypeEnum.ASSET && (
        <>
          <CustomInput
            required
            disabled={!hasPermissionCreate}
            fieldName="qtyType"
            labelName="จำนวน License หรือ Software"
            placeholder="ระบุจำนวน"
            inputProps={{
              'data-testid': 'input-qty',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
          <Typography mt={'-15px'} variant="body2" color={'text.secondary'}>
            ระบุได้ไม่เกิน 5 หลัก
          </Typography>
        </>
      )}
      {vendorType?.value === vendorTypeEnum.OTHER && (
        <>
          <CustomInput
            required
            disabled={!hasPermissionCreate}
            fieldName="descriptionType"
            labelName="รายละเอียด"
            placeholder="กรอกประเภทของบริการ"
          />
        </>
      )}
    </>
  )
}
