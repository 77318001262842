import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportRcmsConst = {
  COLUMN_NAME: {
    NO: 'no',
    COURSE_CODE: 'courseCode',
    COURSE_EN: 'courseEN',
    PREFIX: 'prefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PRFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    LICENSE_NO: 'licenseNo',
    REG_RENEWAL_DATE: 'regRenewalDate',
    ID_CARD_NO: 'idCardNo',
    UNIT_NUMBER: 'unitNumber',
    UNIT_NAME: 'unitName',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    GA_CODE: 'gaCode',
    AGENT_STATUS: 'agentStatus',
    POSITION: 'position',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    BANK_CODE: 'bankCode',
    AGENT_TYPE: 'agentType',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    LICENSE_DESCRIPTION: 'licenseDescription',
    LICENSE_CATEGORY: 'licenseCategory',
    LICENSE_NUMBER: 'licenseNumber',
    LICENSE_EFFECTIVE_DATE: 'licenseEffectiveDate',
    LICENSE_EXPIRY_DATE: 'licenseExpiryDate',
    EXEMPTION_FLAG: 'exemptionFlag',
    REMARK: 'remark',
    DATE_TIME_SENDING: 'dateTimeSending',
    STATUS_API: 'statusApi',
    PLATFORM: 'platform',
    RCMS_CREATED_AT: 'rcmsCreatedAt',
    NAME_FOR_LEARNER: 'nameForLearner',
    LICENSE_STATUS: 'licenseStatus',
    RCMS_FLAG: 'rcmsFlag',
    CHANNEL: 'channel',
    AGENT_FULL_NAME: 'agentFullName',
    // LB_CODE: 'lbCode',
    CREATED_BY: 'rcmsCreatedBy',
    // GA_NAME: 'gaName',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_EN: 'ชื่อหลักสูตร EN',
    PREFIX: 'Title Name EN',
    FIRST_NAME: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PRFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    LICENSE_NO: 'License No.',
    REG_RENEWAL_DATE: 'Reg. Renewal Date',
    ID_CARD_NO: 'เลขบัตรประชาชน',
    UNIT_NUMBER: 'Unit Number',
    UNIT_NAME: 'ชื่อ Unit',
    RAM_CODE: 'RAM Code',
    RAM_NAME: 'Ram Name',
    GA_CODE: 'GA Code',
    AGENT_STATUS: 'สถานะการทำงาน',
    POSITION: 'Position',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    BANK_CODE: 'Bank Code',
    AGENT_TYPE: 'ประเภท Agent',
    FULL_AGENT_CODE: 'Full Agent Code',
    AGENT_CODE: 'รหัส Agent',
    LICENSE_DESCRIPTION: 'ชื่อใบอนุญาต',
    LICENSE_CATEGORY: 'ประเภทใบอนุญาต',
    LICENSE_NUMBER: 'เลขใบอนุญาต',
    LICENSE_EFFECTIVE_DATE: 'วันมีผลบังคับ',
    LICENSE_EXPIRY_DATE: 'วันหมดอายุ',
    EXEMPTION_FLAG: 'Exemption Flag',
    REMARK: 'หมายเหตุ',
    DATE_TIME_SENDING: 'วันเวลาที่ส่ง',
    STATUS_API: 'สถานะการส่ง',
    PLATFORM: 'Platform',
    RCMS_CREATED_AT: 'วันที่สร้าง',
    NAME_FOR_LEARNER: 'ชื่อหลักูตร Learner',
    LICENSE_STATUS: 'สถานะใบอนุญาต',
    RCMS_FLAG: 'Flag',
    CHANNEL: 'Channel',
    AGENT_FULL_NAME: 'ชื่อ-นามสกุล',
    // LB_CODE: '',
    CREATED_BY: 'สร้างโดย',
    // GA_NAME: '',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  licenseStatusOptions: [
    { label: 'รอตรวจสอบ', value: 'รอตรวจสอบ' },
    { label: 'ปรับสถานะครบแล้ว', value: 'ปรับสถานะครบแล้ว' },
    { label: 'ให้', value: 'ให้' },
  ],
  rcmsFlagOptions: [
    { label: 'Manual', value: 'Manual' },
    { label: 'Auto', value: 'Auto' },
    { label: 'Admin', value: 'Admin' },
  ],
  ramNameOptions: [
    { label: 'Broker Channel', value: 'Broker Channel' },
    { label: 'Krisana Arjtharit', value: 'Krisana Arjtharit' },
    { label: 'Krisanapong Saramoon', value: 'Krisanapong Saramoon' },
    { label: 'Adul Srivaree', value: 'Adul Srivaree' },
    { label: 'Sutuch Sompong', value: 'Sutuch Sompong' },
    { label: 'Siraphob Bearkra', value: 'Siraphob Bearkra' },
    { label: 'Pattarapon Inchan', value: 'Pattarapon Inchan' },
    { label: 'Pachara Tavakup', value: 'Pachara Tavakup' },
    { label: 'Ruttanin Siradechasawas', value: 'Ruttanin Siradechasawas' },
    { label: 'Sathaporn Rangsiropas', value: 'Sathaporn Rangsiropas' },
    { label: 'Atipong Apiromyanont', value: 'Atipong Apiromyanont' },
    { label: 'Direct Agency', value: 'Direct Agency' },
  ],
  platformOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Virtual Classroom', value: 'Virtual Classroom' },
    { label: 'E-learning', value: 'E-learning' },
  ],
  agentTypeOptions: [
    { label: 'A', value: 'A' },
    { label: 'L', value: 'L' },
  ],
  statusApiOptions: [
    { label: 'สำเร็จ', value: 'สำเร็จ' },
    { label: 'ไม่สำเร็จ', value: 'ไม่สำเร็จ' },
    { label: 'รอส่ง', value: 'รอส่ง' },
  ],
}

export const reportRcmsDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportRcmsConst.COLUMN_NAME.NO,
      value: reportRcmsConst.COLUMN_NAME.NO,
      label: reportRcmsConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportRcmsConst.COLUMN_NAME.COURSE_CODE,
      value: reportRcmsConst.COLUMN_NAME.COURSE_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportRcmsConst.COLUMN_NAME.COURSE_EN,
      value: reportRcmsConst.COLUMN_NAME.COURSE_EN,
      label: reportRcmsConst.COLUMN_NAME_TH.COURSE_EN,
      minWidth: 210,
    },
    {
      id: 4,
      name: reportRcmsConst.COLUMN_NAME.PREFIX,
      value: reportRcmsConst.COLUMN_NAME.PREFIX,
      label: reportRcmsConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportRcmsConst.COLUMN_NAME.FIRST_NAME,
      value: reportRcmsConst.COLUMN_NAME.FIRST_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportRcmsConst.COLUMN_NAME.LAST_NAME,
      value: reportRcmsConst.COLUMN_NAME.LAST_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportRcmsConst.COLUMN_NAME.PRFIX_TH,
      value: reportRcmsConst.COLUMN_NAME.PRFIX_TH,
      label: reportRcmsConst.COLUMN_NAME_TH.PRFIX_TH,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportRcmsConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportRcmsConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportRcmsConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportRcmsConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportRcmsConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportRcmsConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_NO,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_NO,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_NO,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportRcmsConst.COLUMN_NAME.REG_RENEWAL_DATE,
      value: reportRcmsConst.COLUMN_NAME.REG_RENEWAL_DATE,
      label: reportRcmsConst.COLUMN_NAME_TH.REG_RENEWAL_DATE,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportRcmsConst.COLUMN_NAME.ID_CARD_NO,
      value: reportRcmsConst.COLUMN_NAME.ID_CARD_NO,
      label: reportRcmsConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportRcmsConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportRcmsConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportRcmsConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportRcmsConst.COLUMN_NAME.UNIT_NAME,
      value: reportRcmsConst.COLUMN_NAME.UNIT_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportRcmsConst.COLUMN_NAME.RAM_CODE,
      value: reportRcmsConst.COLUMN_NAME.RAM_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportRcmsConst.COLUMN_NAME.RAM_NAME,
      value: reportRcmsConst.COLUMN_NAME.RAM_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportRcmsConst.COLUMN_NAME.GA_CODE,
      value: reportRcmsConst.COLUMN_NAME.GA_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportRcmsConst.COLUMN_NAME.AGENT_STATUS,
      value: reportRcmsConst.COLUMN_NAME.AGENT_STATUS,
      label: reportRcmsConst.COLUMN_NAME_TH.AGENT_STATUS,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportRcmsConst.COLUMN_NAME.POSITION,
      value: reportRcmsConst.COLUMN_NAME.POSITION,
      label: reportRcmsConst.COLUMN_NAME_TH.POSITION,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportRcmsConst.COLUMN_NAME.MOBILE_NO,
      value: reportRcmsConst.COLUMN_NAME.MOBILE_NO,
      label: reportRcmsConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportRcmsConst.COLUMN_NAME.EMAIL,
      value: reportRcmsConst.COLUMN_NAME.EMAIL,
      label: reportRcmsConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportRcmsConst.COLUMN_NAME.BANK_CODE,
      value: reportRcmsConst.COLUMN_NAME.BANK_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.BANK_CODE,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportRcmsConst.COLUMN_NAME.AGENT_TYPE,
      value: reportRcmsConst.COLUMN_NAME.AGENT_TYPE,
      label: reportRcmsConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportRcmsConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportRcmsConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportRcmsConst.COLUMN_NAME.AGENT_CODE,
      value: reportRcmsConst.COLUMN_NAME.AGENT_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_DESCRIPTION,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_DESCRIPTION,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_DESCRIPTION,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_CATEGORY,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_CATEGORY,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_CATEGORY,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_NUMBER,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_NUMBER,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_NUMBER,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_EFFECTIVE_DATE,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_EFFECTIVE_DATE,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_EFFECTIVE_DATE,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_EXPIRY_DATE,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_EXPIRY_DATE,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_EXPIRY_DATE,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportRcmsConst.COLUMN_NAME.EXEMPTION_FLAG,
      value: reportRcmsConst.COLUMN_NAME.EXEMPTION_FLAG,
      label: reportRcmsConst.COLUMN_NAME_TH.EXEMPTION_FLAG,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportRcmsConst.COLUMN_NAME.REMARK,
      value: reportRcmsConst.COLUMN_NAME.REMARK,
      label: reportRcmsConst.COLUMN_NAME_TH.REMARK,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportRcmsConst.COLUMN_NAME.DATE_TIME_SENDING,
      value: reportRcmsConst.COLUMN_NAME.DATE_TIME_SENDING,
      label: reportRcmsConst.COLUMN_NAME_TH.DATE_TIME_SENDING,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportRcmsConst.COLUMN_NAME.STATUS_API,
      value: reportRcmsConst.COLUMN_NAME.STATUS_API,
      label: reportRcmsConst.COLUMN_NAME_TH.STATUS_API,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportRcmsConst.COLUMN_NAME.PLATFORM,
      value: reportRcmsConst.COLUMN_NAME.PLATFORM,
      label: reportRcmsConst.COLUMN_NAME_TH.PLATFORM,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 3,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_DESCRIPTION,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_DESCRIPTION,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_DESCRIPTION,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRcmsConst.COLUMN_NAME.LICENSE_DESCRIPTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 4,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_CATEGORY,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_CATEGORY,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_CATEGORY,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      masterDataName: reportRcmsConst.COLUMN_NAME.LICENSE_CATEGORY,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 5,
      name: reportRcmsConst.COLUMN_NAME.COURSE_CODE,
      value: reportRcmsConst.COLUMN_NAME.COURSE_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportRcmsConst.COLUMN_NAME.COURSE_EN,
      value: reportRcmsConst.COLUMN_NAME.COURSE_EN,
      label: reportRcmsConst.COLUMN_NAME_TH.COURSE_EN,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportRcmsConst.COLUMN_NAME.NAME_FOR_LEARNER,
      value: reportRcmsConst.COLUMN_NAME.NAME_FOR_LEARNER,
      label: reportRcmsConst.COLUMN_NAME_TH.NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportRcmsConst.COLUMN_NAME.LICENSE_STATUS,
      value: reportRcmsConst.COLUMN_NAME.LICENSE_STATUS,
      label: reportRcmsConst.COLUMN_NAME_TH.LICENSE_STATUS,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRcmsConst.licenseStatusOptions,
    },
    {
      id: 9,
      name: reportRcmsConst.COLUMN_NAME.RCMS_FLAG,
      value: reportRcmsConst.COLUMN_NAME.RCMS_FLAG,
      label: reportRcmsConst.COLUMN_NAME_TH.RCMS_FLAG,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportRcmsConst.rcmsFlagOptions,
    },
    {
      id: 10,
      name: reportRcmsConst.COLUMN_NAME.CHANNEL,
      value: reportRcmsConst.COLUMN_NAME.CHANNEL,
      label: reportRcmsConst.COLUMN_NAME_TH.CHANNEL,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportRcmsConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportRcmsConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportRcmsConst.COLUMN_NAME.AGENT_FULL_NAME,
      value: reportRcmsConst.COLUMN_NAME.AGENT_FULL_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.AGENT_FULL_NAME,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    // {
    //     id: 13,
    //     name: reportRcmsConst.COLUMN_NAME.LB_CODE,
    //     value: reportRcmsConst.COLUMN_NAME.LB_CODE,
    //     label: reportRcmsConst.COLUMN_NAME_TH.LB_CODE,
    //     minWidth: 160,
    //     type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
    //     fetchOptions: () => fetchOptionsReportByKey(),
    // },
    {
      id: 14,
      name: reportRcmsConst.COLUMN_NAME.CREATED_BY,
      value: reportRcmsConst.COLUMN_NAME.CREATED_BY,
      label: reportRcmsConst.COLUMN_NAME_TH.CREATED_BY,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportRcmsConst.COLUMN_NAME.RAM_NAME,
      value: reportRcmsConst.COLUMN_NAME.RAM_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportRcmsConst.ramNameOptions,
    },
    {
      id: 16,
      name: reportRcmsConst.COLUMN_NAME.PLATFORM,
      value: reportRcmsConst.COLUMN_NAME.PLATFORM,
      label: reportRcmsConst.COLUMN_NAME_TH.PLATFORM,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRcmsConst.platformOptions,
    },
    {
      id: 17,
      name: reportRcmsConst.COLUMN_NAME.GA_CODE,
      value: reportRcmsConst.COLUMN_NAME.GA_CODE,
      label: reportRcmsConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    // {
    //   id: 18,
    //   name: reportRcmsConst.COLUMN_NAME.GA_NAME,
    //   value: reportRcmsConst.COLUMN_NAME.GA_NAME,
    //   label: reportRcmsConst.COLUMN_NAME_TH.GA_NAME,
    //   minWidth: 160,
    //   type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
    //   fetchOptions: () => fetchOptionsReportByKey(),
    // },
    {
      id: 19,
      name: reportRcmsConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportRcmsConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportRcmsConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportRcmsConst.COLUMN_NAME.UNIT_NAME,
      value: reportRcmsConst.COLUMN_NAME.UNIT_NAME,
      label: reportRcmsConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportRcmsConst.COLUMN_NAME.STATUS_API,
      value: reportRcmsConst.COLUMN_NAME.STATUS_API,
      label: reportRcmsConst.COLUMN_NAME_TH.STATUS_API,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRcmsConst.statusApiOptions,
    },
    {
      id: 22,
      name: reportRcmsConst.COLUMN_NAME.AGENT_TYPE,
      value: reportRcmsConst.COLUMN_NAME.AGENT_TYPE,
      label: reportRcmsConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRcmsConst.agentTypeOptions,
    },
    {
      id: 23,
      name: reportRcmsConst.COLUMN_NAME.RCMS_CREATED_AT,
      value: reportRcmsConst.COLUMN_NAME.RCMS_CREATED_AT,
      label: reportRcmsConst.COLUMN_NAME_TH.RCMS_CREATED_AT,
      minWidth: 160,
      type: reportRcmsConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
