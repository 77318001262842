import React, { useMemo, useState } from 'react'
import TruncateMarkup from 'react-truncate-markup'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import { ContentRow } from '../../../../../components/View'
import {
  StyledHeadCard,
  StyledItemCard,
} from '../../../../../components/Styled'
import {
  StyledCardContent,
  StyledUl,
} from '../../../../../components/View/Styled'
import { MODULE_TYPE_EN, MODULE_TYPE_TH } from '../../model'
import { handleDetailCount } from './events'

const Lesson = () => {
  const detail = useSelector((state) => state.eLearningCourseDetail.result)
  const eLearningCourseLesson = _.get(detail, 'eLearningCourseLesson', [])
  const countObj = useMemo(
    () => handleDetailCount(eLearningCourseLesson),
    [detail],
  )

  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        บทเรียนในหลักสูตร
      </Typography>
      <ContentRow
        title="จำนวนบทเรียนทั้งหมด"
        content={`${_.get(countObj, 'lessonCount', 0)} บทเรียน`}
      />
      <ContentRow
        title="จำนวนโมดูลทั้งหมด"
        content={`${_.get(countObj, 'moduleCount', 0)} โมดูล`}
      />
      {_.map(eLearningCourseLesson, (lessons, index) => (
        <LessonCard key={index} index={index} lessons={lessons} />
      ))}
    </StyledCardContent>
  )
}

export default Lesson

export const LessonCard = ({ lessons, index }) => {
  const [isExpanded, setExpand] = useState(true)
  const eLearningModules = _.get(lessons, 'eLearningLessonRefModule', [])
  return (
    <StyledItemCard>
      <StyledHeadCard>
        <Typography variant="body1b" color="primary">
          บทเรียนที่ {index + 1}
        </Typography>
        <IconButton
          sx={{ width: 30, height: 30 }}
          onClick={() => setExpand(!isExpanded)}
        >
          {isExpanded ? (
            <KeyboardArrowUpTwoTone />
          ) : (
            <KeyboardArrowDownTwoTone />
          )}
        </IconButton>
      </StyledHeadCard>
      {isExpanded && (
        <>
          <Divider sx={{ borderStyle: 'dashed', color: '#BDBDBD' }} />
          <ContentRow
            title="ชื่อบทเรียน"
            content={_.get(lessons, 'name', '-')}
          />
          <ContentRow
            title="จำนวนโมดูล"
            content={`${eLearningModules.length} โมดูล`}
          />
          <ContentRow title="ชื่อโมดูล">
            <StyledUl>
              {_.map(eLearningModules, (module, key) => (
                <li key={key}>
                  <TruncateMarkup lines={2}>
                    <Typography variant="span">
                      {_.get(module, 'eLearningModule.name', '-')}{' '}
                    </Typography>
                  </TruncateMarkup>
                  <ModuleTypeText module={module} />
                </li>
              ))}
            </StyledUl>
          </ContentRow>
        </>
      )}
    </StyledItemCard>
  )
}

export const ModuleTypeText = ({ module }) => {
  const moduleType = _.get(module, 'moduleType', '')
  return (
    <Typography variant="span" color="text.gray">
      {' '}
      ( {MODULE_TYPE_EN[moduleType]} / {MODULE_TYPE_TH[moduleType]} )
    </Typography>
  )
}
