import React from 'react'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { handleClickButton } from './event'
import { useDispatch } from 'react-redux'
import { TypographyIcon } from './Styled'
import CheckIcon from '@mui/icons-material/Check'

const CloseClass = ({ uuid, status, manageClassClose, hasPermissionEdit }) => {
  const dispatch = useDispatch()
  return (
    <>
      <Button
        data-testid="btn-canceled"
        variant="canceled"
        disabled={!hasPermissionEdit}
        onClick={() =>
          dispatch(handleClickButton(status, manageClassClose, uuid))
        }
      >
        <PowerSettingsNewIcon />
        <Typography>ปิดคลาส</Typography>
      </Button>
    </>
  )
}

export const StatusComplete = ({ label }) => {
  return (
    <>
      <TypographyIcon variant="body1" color="success.main">
        <CheckIcon sx={{ fontSize: 'inherit' }} />
        {label}
      </TypographyIcon>
    </>
  )
}

export default CloseClass
