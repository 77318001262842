import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  examinerDrawerEnum,
  filtersDrawerEnum,
  overviewDataCountEnum,
  rankingDrawerEnum,
  scheduleDrawerEnum,
} from '../enums/drawerEnum'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.examFieldDrawer',
      value: {
        isOpen: false,
        isCompany: false,
        isAssociation: false,
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.scheduleDrawer',
      value: scheduleDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.rankingDrawer',
      value: rankingDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.examinerDrawer',
      value: examinerDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.filtersDrawer',
      value: filtersDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.overviewDataCount',
      value: overviewDataCountEnum,
    }),
  )
  await fetchOverview()
}
