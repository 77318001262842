import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'

import { Row, Column } from '../components/ContentNoClass/Styled'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import { Container } from './styled'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import LikeButton from '../components/LikeButton'

const Content = () => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const data = JSON.parse(localStorage.getItem('ELCollectionPreviewData'))
  return (
    <Container>
      <Row sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography variant={isNotSm ? 'h5' : 'body1b'} color="primary">
          {data.name}
        </Typography>

        {isNotSm && <LikeButton />}
      </Row>
      <Row sx={{ width: '100%', gap: 3, mb: 5 }}>
        <EllipsisDetail data={data.description} />
      </Row>
    </Container>
  )
}

export default Content

const EllipsisDetail = ({ data }) => {
  const [isEllipsis, setIsEllipsis] = useState(false)
  const [expand, setExpand] = useState(false)
  const ellipsisText = document.getElementById('ellipsis-text')
  useEffect(() => {
    if (ellipsisText?.offsetHeight < ellipsisText?.scrollHeight) {
      setIsEllipsis(true)
    }
  }, [ellipsisText])

  return (
    <Column>
      <Typography
        id="ellipsis-text"
        sx={
          !expand && {
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }
        }
      >
        {data}
      </Typography>
      {isEllipsis &&
        (!expand ? (
          <Typography
            color="primary.main"
            sx={{
              textDecoration: 'underline 1px !important',
              cursor: 'pointer',
              WebkitTextDecorationStyle: 'solid !important',
              WebkitTextDecorationThickness: '1px !important',
              WebkitTextDecorationLine: 'underline !important',
            }}
            onClick={() => setExpand(true)}
          >
            ดูเพิ่ม
          </Typography>
        ) : (
          <Typography
            color="primary.main"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'underline 1px !important',
              cursor: 'pointer',
              WebkitTextDecorationStyle: 'solid !important',
              WebkitTextDecorationThickness: '1px !important',
              WebkitTextDecorationLine: 'underline !important',
            }}
            onClick={() => setExpand(false)}
          >
            <KeyboardArrowUp sx={{ fontSize: 20 }} />
            ย่อลง
          </Typography>
        ))}
    </Column>
  )
}
