import { crudConfig } from '../../crudConfig'
import _ from 'lodash'
import callAxios from '../../../../../utils/baseService'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { tableTypeEnum } from '../enum/tableTypeEnum'
import { tabTypeEnum } from '../enum/tabTypeEnum'

export const countWaitingApprove = async () => {
  const { tabActive, tabTypeActive } = store.getState().crud
  let transferBy = [tableTypeEnum.ALL, tableTypeEnum.APPROVE]
  switch (tabActive) {
    case 0:
      transferBy = [tableTypeEnum.ALL]
      break
    case 1:
      transferBy = [tableTypeEnum.APPROVE]
      break
  }

  let requisitionType = [tabTypeEnum.MONTHLY, tabTypeEnum.OTHER]
  switch (tabTypeActive) {
    case 0:
      requisitionType = [tabTypeEnum.MONTHLY]
      break
    case 1:
      requisitionType = [tabTypeEnum.OTHER]
      break
  }

  const body = {
    limit: 10,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    quickSearch: '',
    ...mutateFilterPropToBody(),
    createdBy: '',
    updatedBy: '',
    search: '',
    status: [],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    requisitionType,
    transferBy,
    menu: true,
  }

  await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': crudConfig.moduleId })
    .then((res) => {
      const countRequisitionMonthly = _.get(
        res,
        'data.countRequisitionMonthly',
        0,
      )
      const countRequisitionOther = _.get(res, 'data.countRequisitionOther', 0)
      const countRequest = tabTypeActive
        ? countRequisitionOther
        : countRequisitionMonthly
      store.dispatch(
        setFieldValue({
          key: 'requisitionCount',
          value: countRequest,
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'countRequisitionMonthly',
          value: countRequisitionMonthly,
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'countRequisitionOther',
          value: countRequisitionOther,
        }),
      )
    })
}
