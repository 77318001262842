import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import TimerOutlined from '@mui/icons-material/TimerOutlined'
import {
  StyledCard,
  StyledCardFooter,
  StyledContentBox,
  StyledIconLabel,
  StyledMediaBox,
  StyledTypography,
} from './Styled'
import { handleCourseTime } from './events'

const CourseCard = ({ course, size }) => {
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file/${_.get(
    course,
    'imageKey',
    ''
  )}`
  const trainingPlatform = _.get(course, 'trainingPlatform', '-')
  const courseLink = `${window.__env__.REACT_APP_LEARNER_URL}/course/${_.get(
    course,
    'uuid',
    ''
  )}`
  return (
    <StyledCard size={size} elevation={0}>
      <StyledMediaBox size={size}>
        <CardMedia component="img" image={imageSrc} alt="preview" />
      </StyledMediaBox>
      <StyledContentBox>
        <CardContent>
          <Box sx={{ textAlign: 'start' }}>
            <StyledTypography variant="overline" color="secondary.main">
              {trainingPlatform}
            </StyledTypography>
            <StyledTypography variant="h5" color="text.primary">
              {_.get(course, 'englishName', '')}
            </StyledTypography>
          </Box>
          <DefaultCardFooter data={course} />
        </CardContent>
        <CardActions>
          <Link
            href={courseLink}
            target="_blank"
            variant="body1"
            underline="none"
          >
            ดูคลาสเรียนในหลักสูตร
          </Link>
        </CardActions>
      </StyledContentBox>
    </StyledCard>
  )
}

export default CourseCard

export const DefaultCardFooter = ({ data }) => {
  const time = handleCourseTime(data)
  return (
    <StyledCardFooter>
      <StyledIconLabel>
        <TimerOutlined />
        <Typography variant="body1" color="text.secondary">
          {time}
        </Typography>
      </StyledIconLabel>
    </StyledCardFooter>
  )
}
