import React from 'react'
import Typography from '@mui/material/Typography'

const HeadLabel = ({ text, variant = 'h6', required, sx }) => {
  return (
    <Typography variant={variant} sx={sx}>
      {text}{' '}
      {required && (
        <Typography variant="span" color="error">
          *
        </Typography>
      )}
    </Typography>
  )
}

export default HeadLabel
