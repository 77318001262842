import * as yup from 'yup'

export const validationSchema = yup.object({
  trainingPlatform: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(80, 'ระบุได้ไม่เกิน 80 ตัวอักษร'),
  abbreviation: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(2, 'ระบุได้ไม่เกิน 2 ตัวอักษร'),
  status: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})
