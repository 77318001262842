import { Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { SummaryList } from './SummaryList'

export const SummaryByCostCenter = () => {
  const { summaryByCostCenter } = useSelector(
    (state) => ({
      summaryByCostCenter: state.crud.formData.summaryByCostCenter,
    }),
    shallowEqual,
  )

  return (
    <>
      {summaryByCostCenter?.length > 0 && (
        <>
          <Typography variant="body1b" color="gray">
            By Cost center
          </Typography>
          <SummaryList summaryList={summaryByCostCenter} />
        </>
      )}
    </>
  )
}
