import TextInput from '../../../../../../components/Input/TextInput'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  roomSelector,
  setRoom,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import _ from 'lodash'
import { handleNumberInput } from '../../../../../../utils/lib'
import { getErrorMessage, getIndexByBookingDate } from '../../../event'

export const NumberOfAttendees = ({ bookingDate, roomIndex }) => {
  const dispatch = useDispatch()
  const numberOfAttendees = useSelector(
    roomSelector({
      bookingDate,
      roomIndex,
      key: 'numberOfAttendees',
    }),
    shallowEqual,
  )

  const formError = useSelector(
    (state) => state.roomManagementDrawer.formError,
    shallowEqual,
  )

  return (
    <TextInput
      type="basic"
      id="numberOfAttendees"
      name="numberOfAttendees"
      labelText={'จำนวนผู้เข้าร่วม'}
      placeholder="กรุณากรอกจำนวนผู้เข้าร่วม"
      // disabled={disabledAll}
      value={numberOfAttendees || ''}
      inputProps={{
        type: 'text',
        onInput: handleNumberInput,
      }}
      onChange={(e) => {
        const value = _.get(e, 'target.value', '').replace(/[^0-9]/g, '')
        dispatch(
          setRoom({
            key: 'numberOfAttendees',
            value: value,
            bookingDate,
            roomIndex,
          }),
        )
      }}
      textError={getErrorMessage(
        formError,
        `roomBookingList[${getIndexByBookingDate(
          bookingDate,
        )}].rooms[${roomIndex}].numberOfAttendees`,
      )}
      isShowTextError={
        !_.isEmpty(
          getErrorMessage(
            formError,
            `roomBookingList[${getIndexByBookingDate(
              bookingDate,
            )}].rooms[${roomIndex}].numberOfAttendees`,
          ),
        )
      }
    />
  )
}
