import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const Column = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const Row = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette?.background?.blue,
  gap: theme.spacing(5),
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(4) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(2) },
}))

export const StyledTypography = styled(Typography)(({ variant }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: variant === 'overline' ? '1' : '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
}))
