import { Divider, Typography } from '@mui/material'

import {
  StyledRow,
  StyledColumn,
  StyledCard,
  StyledImgStatusCard,
} from '../../Styled'
import { shallowEqual, useSelector } from 'react-redux'

const StatusItem = (props) => {
  const { countRoom, textRoomAvailable, color, icon, boxSx } = props
  return (
    <StyledRow sx={{ ...boxSx, gap: 2, py: 1, pr: 1 }}>
      <img src={`/icon/${icon}-timer-countdown-clock.svg`} />
      <StyledColumn>
        <StyledRow sx={{ gap: 1, height: 42, alignItems: 'baseline' }}>
          <Typography variant="h4">{countRoom}</Typography>
          <Typography variant="h6" color="text.lightGray">
            ห้อง
          </Typography>
        </StyledRow>
        <Typography variant="body1b" color={color}>
          {textRoomAvailable}
        </Typography>
      </StyledColumn>
    </StyledRow>
  )
}

export const StatusRoomCard = () => {
  const { allAvailable, someTimeAvailable } = useSelector(
    (state) => ({
      allAvailable: state.roomManagement.data.allAvailable,
      someTimeAvailable: state.roomManagement.data.someTimeAvailable,
    }),
    shallowEqual,
  )

  return (
    <StyledCard
      sx={{
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <StyledColumn>
        <Typography variant="h5">สถานะห้องอบรมวันนี้</Typography>
        <StyledRow sx={{ gap: 1 }}>
          <StatusItem
            boxSx={{ pl: 1, width: 210 }}
            icon={'stop-watch'}
            countRoom={someTimeAvailable}
            textRoomAvailable={'ว่างบางช่วงเวลา'}
            color={'monthlyPlan.birthdayLeaveBorder'}
          />
          <Divider orientation="vertical" flexItem />
          <StatusItem
            boxSx={{ pl: 2 }}
            icon={'alternate'}
            countRoom={allAvailable}
            textRoomAvailable={'ว่างทั้งวัน'}
            color={'monthlyPlan.holidayBorder'}
          />
        </StyledRow>
      </StyledColumn>

      <StyledImgStatusCard src={`/image/time-management.svg`} />
    </StyledCard>
  )
}
