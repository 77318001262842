import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledCard = styled(Card)(({ theme, size = 'm' }) => {
  const sizeL = size == 'l' ? 405 : 200
  const width = size == 'm' ? 302 : sizeL
  return {
    width,
    minWidth: 302,
    borderRadius: 0,
    backgroundColor: theme.palette?.background?.blue,
    transition: 'all 0.5s ease',
    ':hover': {
      backgroundColor: theme.palette?.blue?.blueLine,
      '.MuiCardMedia-root': { transform: 'scale(1.1)' },
    },
    [theme.breakpoints.down('lg')]: {
      width: 335,
    },
  }
})

export const StyledMediaBox = styled(Box)(({ size }) => {
  const height = size == 'l' ? 270 : 201
  return {
    height,
    overflow: 'hidden',
    '& .MuiCardMedia-root': { transition: 'transform .5s ease' },
    img: { height: '100%', objectFit: 'cover' },
  }
})

export const StyledContentBox = styled(Box)(({ theme }) => ({
  height: 229,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& .MuiCardContent-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  '& .MuiCardActions-root': {
    display: 'flex',
    justifyContent: 'center',
    borderTop: 'solid 1px',
    borderColor: theme.palette?.other?.divider,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
  },
}))

export const StyledTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}))

export const StyledCardFooter = styled(Box)(({ theme }) => ({
  width: '270px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.spacing(0.5),
}))

export const StyledIconLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  '& .MuiSvgIcon-root': {
    color: theme?.palette?.text?.secondary,
    fontSize: '1.25rem',
  },
}))
