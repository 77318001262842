import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTemplate = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 1170,
  height: '100%',
  margin: 'auto',
  minHeight: 'calc(100vh - 385px)',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    minHeight: 'calc(100vh - 350px)',
  },
  [theme.breakpoints.down('sm')]: { minHeight: 'calc(100vh - 434px)' },
}))
