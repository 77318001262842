import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import CustomSwitchInLine from '../../../../../components/CRUD/components/CustomSwitchInLine'
import { Box, Typography } from '@mui/material'
import { ViewRow } from '../../../../../components/CRUD/components/StyledViewBox'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { getProductType } from '../../utils'
import { store } from '../../../../../App'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { PRODUCT_TYPE_VIEW_TEXT } from '../../../../../constants/stock'

export const Content = () => {
  const { category, subCategoryMaster } = useSelector(
    (state) => ({
      category: state.crud.formData.category,
      subCategoryMaster: state.crud.masterData.subCategory,
    }),
    shallowEqual,
  )
  const [subCategoryOptions, setSubCategoryOptions] = useState({
    isLoading: false,
    options: [],
  })

  useEffect(() => {
    let filterdSubCategory = []
    if (category?.value && !_.isEmpty(subCategoryMaster?.options)) {
      filterdSubCategory = subCategoryMaster?.options.filter(
        (item) => item.categoryUuid === category?.value,
      )
    }
    setSubCategoryOptions({ isLoading: false, options: filterdSubCategory })
  }, [category])

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <ViewRow
        boxSx={{ display: 'flex', gap: 3 }}
        titleSx={{ minWidth: 'auto' }}
        title="ประเภททรัพย์สิน"
        value={PRODUCT_TYPE_VIEW_TEXT?.[getProductType()] ?? PRODUCT_TYPE_VIEW_TEXT.LICENSE}
      />
      <Box display="flex" gap={3}>
        <CustomInput
          required
          fieldName="productName"
          labelName="ชื่อซอฟต์แวร์ (Software name)"
          placeholder="ระบุชื่อซอฟต์แวร์ (Software name)"
        />

        <CustomInput required fieldName="vendor" labelName="Vendor" />
      </Box>

      <Box display="flex" gap={3}>
        <CustomDropdown
          required
          labelName="หมวดหมู่ (Category)"
          fieldName="category"
          placeholder="เลือก หมวดหมู่"
          handleExtraChange={() => {
            store.dispatch(
              setFieldValue({
                key: 'formData.subCategory',
                value: '',
              }),
            )
          }}
        />
        <CustomDropdown
          labelName="หมวดหมู่ย่อย (Sub-Category)"
          fieldName="subCategory"
          isCustomOption={true}
          customOptions={subCategoryOptions}
          placeholder="เลือก หมวดหมู่ย่อย"
          noOptionsText={
            category?.value ? 'ไม่มีรายการ' : 'กรุณาเลือก หมวดหมู่'
          }
        />
      </Box>

      <CustomSwitchInLine fieldName="status" title="สถานะทรัพย์สิน" />

      <CustomTextArea
        fieldName="remark"
        labelName="หมายเหตุ"
        placeholder="กรอกหมายเหตุ"
      />
    </>
  )
}
