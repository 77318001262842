export const viewTypeEnum = {
  TICKET: 'TICKET', //แรกสร้าง
  WAITING_REQUISITION_APPROVE: 'WAITING_REQUISITION_APPROVE', //การอนุมัติเบิกทรัพย์สิน
  WAITING_REQUISITION_COMFIRM: 'WAITING_REQUISITION_COMFIRM', //การอนุมัติเบิกทรัพย์สิน
  APPROVED: 'APPROVED', //ได้รับอนุมัติ
  RETURN: 'RETURN', //คืนของ (เปิด form ด้านล่าง)
  WAITING_RETURN_APPROVE: 'WAITING_RETURN_APPROVE', //รอได้รับอนุมัติคืน
  RETURN_APPROVED: 'RETURN_APPROVED', //ได้รับอนุมัติคืน
  WAITING_RETURN: 'WAITING_RETURN', //รอคืน
  WAITING_APPROVE: 'WAITING_APPROVE',
}