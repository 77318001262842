export const initialState = {
  isComplete: false,
  isSuccess: false,
  isLoading: false,
  tab: 'ALL',
  page: 1,
  courseNameAndType: '',
  allowToAnnounce: true,
  eTestingType: 'ASSESSMENT',
  learners: {
    learnerAll: [],
    learnerWait: [],
    learnerPass: [],
    learnerFail: [],
    learnerNotSent: [],
  },
  learnerExam: null,
  learnerAnswers: [], // constant state
  error: null,
  body: null,
}
