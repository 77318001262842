import { mutateFilterPropToBody } from '../mutation/filterPropToBody'

export const headerCells = () => {
  return [
    {
      id: 'id',
      label: 'ลำดับ',
      width: '20px',
    },
    {
      id: 'score',
      label: 'คะแนน',
      disablePadding: false,
    },
    {
      id: 'count',
      label: 'จำนวนผู้ได้คะแนน (คน)',
      disablePadding: false,
    },
    {
      id: 'actionETestDashboard',
      disablePadding: false,
      label: '',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewDashboardAction: true,
      viewDashboardActionType: 'จำนวนผู้ได้คะแนน',
      mutateFilterPropToBody: mutateFilterPropToBody,
    },
  ]
}
