import React, { useEffect, useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import _ from 'lodash'
import { setMobileProps } from '../../../../../../../../redux/slices/eTestingPreview'
import { alphabets } from '../../../../../../../../constants/eTesting'
import Draggable from './Draggable'
import Droppable from './Droppable'
import {
  getPlainTextWithBlank,
  handleDragDropChange,
  handleDragEnd,
  handleMobileSelect,
} from './events'
import { blankReg, blankSimbol } from './model'
import {
  StyledDialogContent,
  StyledDragDropContainer,
  StyledKeywordContainer,
} from './Styled'

const DragAndDrop = ({ keyQuestion, question, isSubmit, questionResult }) => {
  const dispatch = useDispatch()
  const selectedItem = useSelector(
    (state) => state.eTestingPreview.mobileProps.selectedItem,
    shallowEqual
  )
  const dialog = getPlainTextWithBlank(_.get(question, 'subTitle', ''))
  const keywords = _.get(question, 'choices', [])
  const answer = _.get(question, 'answer', [])
  const [dragDropArr, setDragDropArr] = useState([])
  const [shuffledKeywords, setKeyword] = useState([])
  const handleProps = { dragDropArr, setDragDropArr, selectedItem }

  useEffect(() => {
    const userDevice = navigator.userAgent
    const deviceType =
      userDevice.match(/Android/i) ||
      userDevice.match(/webOS/i) ||
      userDevice.match(/iPhone/i) ||
      userDevice.match(/iPad/i) ||
      userDevice.match(/iPod/i) ||
      userDevice.match(/BlackBerry/i) ||
      userDevice.match(/Windows Phone/i)
    dispatch(setMobileProps({ isMobile: !!deviceType }))
  }, [])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (selectedItem && !e.target.id?.includes('drag-drop')) {
        dispatch(setMobileProps({ selectedItem: null }))
      }
    })
  }, [selectedItem])

  useEffect(() => {
    setKeyword(_.shuffle(keywords))
  }, [keywords])

  useEffect(() => {
    !isSubmit && dispatch(handleDragDropChange(dragDropArr, keyQuestion))
  }, [dragDropArr])

  useEffect(() => {
    isSubmit && setDragDropArr(answer)
  }, [isSubmit])

  return (
    <DndContext
      onDragEnd={(props) => {
        const newArr = handleDragEnd(props, dragDropArr)
        setDragDropArr(newArr)
      }}
    >
      <StyledDragDropContainer>
        <StyledKeywordContainer>
          {shuffledKeywords
            .filter(
              (item) =>
                !dragDropArr.some(
                  (data) => _.get(data, 'uuid', '') === _.get(item, 'uuid', '')
                ) || isSubmit
            )
            .map((data, index) => (
              <Draggable
                key={index}
                index={index}
                data={data}
                isSubmit={isSubmit}
                isSelected={
                  (isSubmit &&
                    answer.some(
                      (ans) =>
                        _.get(ans, 'uuid', '') === _.get(data, 'uuid', '')
                    )) ||
                  _.get(selectedItem, 'uuid', '') === _.get(data, 'uuid', '')
                }
              />
            ))}
        </StyledKeywordContainer>
        <StyledDialogContent>
          <Typography lineHeight={2.2}>
            {DroppableContent({
              dialog,
              dragDropArr,
              keywords: shuffledKeywords,
              isSubmit,
              questionResult,
              onSelect: (index) =>
                dispatch(handleMobileSelect(index, handleProps)),
            })}
          </Typography>
        </StyledDialogContent>
      </StyledDragDropContainer>
    </DndContext>
  )
}
export default DragAndDrop

export const DroppableContent = (props) => {
  const { dialog, dragDropArr, keywords, isSubmit, questionResult, onSelect } =
    props
  let index = -1
  const convertedDiaglog = dialog.replace(blankReg, blankSimbol)
  return reactStringReplace(convertedDiaglog, blankSimbol, () => {
    index++
    const correctIndex = keywords.findIndex(
      (ans) => ans.target === _.get(alphabets, index, '')
    )
    return (
      <Droppable
        index={index}
        data={dragDropArr[index]}
        isSubmit={isSubmit}
        questionResult={questionResult}
        correctNo={correctIndex + 1}
        onSelect={onSelect}
      />
    )
  })
}
