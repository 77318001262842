import React from 'react'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'

import Info from './Info'
import DisplayQuestion from './DisplayQuestion'
import Notification from './Notification'
import ShareAssessment from './ShareAssessment'

const Index = () => {
  const eEvaluationType = useSelector(
    (state) => state.eEvaluationForm.eEvaluationType,
    shallowEqual
  )

  return (
    <Box sx={{ my: 3 }}>
      <Info />
      <DisplayQuestion />
      <Notification />
      {eEvaluationType === 'STANDALONE' && <ShareAssessment />}
    </Box>
  )
}

export default Index
