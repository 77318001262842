import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'

const stockList = createSlice({
  name: 'stockList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenHistoryFilterDrawer: (state, { payload }) => {
      state.isOpenHistoryFilterDrawer = payload
    },
    setStockType: (state, { payload }) => {
      state.warehouseType = payload
    },
    setStationList: (state, { payload }) => {
      state.stationList = payload
    },
    setVendorList: (state, { payload }) => {
      state.vendorList = payload
    },
    setStockList: (state, { payload }) => {
      state.stockList = payload
    },
    setInitialStockList: (state, { payload }) => {
      state.initialStockList = payload
    },
    setStockDetail: (state, { payload }) => {
      state.stockDetail = payload
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.stockForm, key, value)
    },
    setStockHistoryList: (state, { payload }) => {
      state.historyList = payload
    },
    setStockForm: (state, { payload }) => {
      state.stockForm = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setIsOpenFilterDrawer,
  setIsOpenHistoryFilterDrawer,
  setStockType,
  setStationList,
  setVendorList,
  setStockList,
  setInitialStockList,
  setStockDetail,
  setFieldValue,
  setStockHistoryList,
  setStockForm,
} = stockList.actions
export default stockList.reducer
