import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import { ChevronRight } from '@mui/icons-material'
import { StyledDot } from './Styled'

const InfoPreview = ({
  label = '',
  count = '-',
  unit = '',
  handleClick = () => {},
  bgButtonColor,
  bgColor,
  color,
  hideButton = false,
  boxSx = {},
  hasDot = false,
  chipLabel,
  fontColor,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        borderRadius: '8px',
        px: 2,
        py: 1.5,
        width: 194,
        backgroundColor: bgColor,
        border: `1px solid ${theme.palette?.blue?.blueLine}}`,
        ...boxSx,
      }}
    >
      {hasDot ? (
        <Box display="flex" gap={1} alignItems="baseline">
          <StyledDot color={color} />
          <Typography variant="body2b">{label}</Typography>
        </Box>
      ) : (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      )}

      {chipLabel && (
        <Box
          sx={{
            backgroundColor: color,
            px: 1.25,
            py: 0.5,
            borderRadius: 4,
            width: 'fit-content',
            height: 20,
            display: 'flex',
            alignItems: 'center',
            ml: hasDot ? 2 : 0,
          }}
        >
          <Typography variant="body3b" lineHeight={0.5} color={fontColor}>
            {chipLabel}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" ml={hasDot ? 2 : 0}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h4" lineHeight={1}>
            {count}
          </Typography>
          <Typography variant="body2" color="text.lightGray" lineHeight={1}>
            {unit}
          </Typography>
        </Box>
        {!hideButton && (
          <IconButton
            sx={{ backgroundColor: bgButtonColor }}
            onClick={handleClick}
          >
            <ChevronRight sx={{ color: theme.palette.text.lightGray }} />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default InfoPreview
