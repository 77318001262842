import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ModuleCard from './ModuleCard'
import { handleClickClassDrawer } from '../../handler/handleClickClassDrawer'
import { overviewEnum } from '../../enums/overviewEnum'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

const index = () => {
  const { overviewsData } = useSelector(
    (state) => ({
      overviewsData: state.crud?.manageClassDashboard?.overviewsData,
    }),
    shallowEqual,
  )
  return (
    <Box display="flex">
      <Card sx={{ minWidth: 275 }}>
        <CardContent sx={{ position: 'relative' }}>
          <Typography variant="h6">สรุปคลาสรวม</Typography>
          <Box display="flex" gap={2} mt={3}>
            {_.defaultTo(overviewsData, []).map((ele, index) => {
              return (
                <ModuleCard
                  key={index}
                  label={ele.label}
                  labelVariant={'body2'}
                  background={ele.background}
                  dotColor={ele.dotColor}
                  value={ele.number}
                  unit={ele.unit}
                  percent={``}
                  handleClickButton={
                    ele.value === overviewEnum.ALL
                      ? () => {
                          handleClickClassDrawer()
                        }
                      : ''
                  }
                />
              )
            })}
          </Box>
        </CardContent>
      </Card>
      <img width={200} height={200} src="/image/pie_chart_dashbord.svg" />
    </Box>
  )
}

export default index
