import React from 'react'
import { useSelector } from 'react-redux'
import { StyledNewCard } from '../../../components/View/Styled'
import { FORM_TAB } from '../../Form/model'
import TabMenuContent from './TabMenuContent'
import LearningPath from './LearningPath'
import Setting from './Setting'

const Content = () => {
  const tab = useSelector((state) => state.eLearningPathDetail.tab)
  return (
    <StyledNewCard id="view">
      <TabMenuContent />
      {tab === FORM_TAB.learningPath && <LearningPath />}
      {tab === FORM_TAB.setting && <Setting />}
    </StyledNewCard>
  )
}

export default Content
