import React from 'react'
import _ from 'lodash'

import { ActionIcon } from './'
import ContentDialog from './ContentDialog'

import * as slicesTable from '../../../../../redux/slices/tableLeanerDetail'
import * as slicesDialog from '../../../../../redux/slices/dialog'
import { revertDateToApi } from '../../../../../utils/lib'

import {
  setChange,
  setChangeStatus,
} from '../../../../../redux/slices/manageClassLearnerDetail'
import {
  putManageClassAttendanceStatus,
  passTraining,
  notPassTraining,
  updateLearnerAbsentCourse,
} from '../../../../../services/manageClass/learnerDetail'

const headCells = [
  {
    id: 'date',
    label: 'วันที่เรียน',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'round',
    label: 'ครั้งที่',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },
  {
    id: 'result',
    label: 'ผล',
    width: '30%',
    hideSortIcon: true,
    disablePadding: false,
  },

  {
    id: 'actionAttendance',
    label: 'มา/ไม่มา',
    customWidth: '10%',
    custom: true,
    hideSortIcon: true,
    disablePadding: false,
  },
]

export const filterData = (props) => (dispatch) => {
  const resultRows = findData({
    list: props.attendance,
    selectDate: props.selectDate,
  })
  dispatch(
    slicesTable.setInitialTable({
      rows: resultRows,
      headCells: headCells,
      type: 'details',
    })
  )
}

export const findData = ({ list, selectDate }) => {
  if (!list.length || !selectDate) return []

  const resFind = list.find((item) => item.date === selectDate)
  const result = _.get(resFind, 'rounds', []).map((item) => {
    return {
      date: selectDate,
      round: _.get(item, 'round', ''),
      result: _.get(item, 'result', ''),
      actionAttendance: <ActionIcon item={{ ...item, date: selectDate }} />,
    }
  })

  return result
}

export const filterListDate = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      label: item.date,
      value: item.date,
    }
  })
}

export const handleChange = (value) => (dispatch) => {
  dispatch(setChange(value))
}

export const handleDialogAction = (body) => (dispatch) => {
  dispatch(
    slicesDialog.openDialog({
      type: 'mini',
      maxWidth: 'sm',
      disableButton: true,
      content: (
        <ContentDialog
          body={body}
          title={'รายละเอียดการเช็คชื่อ'}
          contentText={'รายละเอียดการเช็คชื่อ'}
          dialogType="JOIN"
        />
      ),
      handleCancel: () => dispatch(clearRemark()),
    })
  )
}

export const handleSubmitAction = (body, dialogType) => async (dispatch) => {
  dispatch(slicesDialog.loadingDialog())
  let result = null
  if (dialogType === 'JOIN') {
    result = await dispatch(
      putManageClassAttendanceStatus({
        ...body,
        date: revertDateToApi(body.date),
      })
    )
  } else if (dialogType === 'PASS_TRAINING') {
    result = await dispatch(passTraining(body))
  } else if (dialogType === 'NOT_PASS_TRAINING') {
    result = await dispatch(notPassTraining(body))
  } else if (dialogType === 'ABSENT') {
    result = await dispatch(updateLearnerAbsentCourse(body))
  }

  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled') {
    if (dialogType === 'JOIN') {
      dispatch(setChangeStatus(body))
    } else if (
      dialogType === 'PASS_TRAINING' ||
      dialogType === 'NOT_PASS_TRAINING' ||
      dialogType === 'ABSENT'
    ) {
      window.location.reload()
    }
  }

  setTimeout(() => {
    dispatch(clearRemark())
    dispatch(slicesDialog.closeDialog())
  })
}

export const handleDialogConfirm =
  ({ body, dialogType }) =>
  async (dispatch) => {
    dispatch(
      slicesDialog.openDialog({
        type: 'confirm',
        title: 'ยืนยันบันทึก',
        message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        handleCancel: () => dispatch(clearRemark()),
        handleConfirm: () => dispatch(handleSubmitAction(body, dialogType)),
      })
    )
  }

export const dialogActionSuccess = () => (dispatch) => {
  dispatch(slicesDialog.stopLoadingDialog())
  dispatch(
    slicesDialog.openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      isCloseDialog: false,
      handleConfirm: () => {
        window.location.reload()
      },
    })
  )
}

export const clearRemark = () => (dispatch) => {
  dispatch(
    setChange({
      key: 'remark',
      value: '',
    }),
  )
}

export const handleSwitchChange = (e, uuid) => (dispatch) => {
  dispatch(
    slicesDialog.openDialog({
      type: 'mini',
      maxWidth: 'sm',
      disableButton: true,
      content: (
        <ContentDialog
          body={{ uuid, isCheckAbsent: e.target.checked }}
          title="ยืนยันการปรับสถานะ"
          dialogType="ABSENT"
        />
      ),
      handleCancel: () => dispatch(clearRemark()),
    }),
  )
}
