import _ from 'lodash'
import {
  setEditData,
  setEditMonthlyPlan,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/manageClassForm'
import { convertFormatDateTime } from '../../../../utils/lib'
import dayjs from 'dayjs'
import {
  getAdminStaff,
  getExternalSpeaker,
  getMeetingRoom,
  getResponsibility,
} from '../../../../services/manageClass/form'

import { handleMultiSelectChange } from '../Form/DateCard/events'
export const checkAssignNee = (uuid, list, assignNee) => {
  const findValue = list.find((item) => item.value === uuid)
  if (_.isEmpty(findValue)) {
    const area = _.get(assignNee, 'area', [])
    const defaultArea = _.get(assignNee, 'defaultArea', '')
    if (_.isEmpty(defaultArea)) return { label: '', value: '' }
    const findArea = area.find((item) => item.uuid === defaultArea)
    const station = _.get(findArea, 'station.station', '')
    const name = `${_.get(assignNee, 'firstNameTH', '')} ${_.get(
      assignNee,
      'lastNameTH',
      ''
    )}`
    return {
      ...assignNee,
      label: `${station} - ${name}`,
      value: uuid,
    }
  } else {
    return findValue
  }
}

export const fetchOptions =
  (station, hasPermissionEditAll) => async (dispatch) => {
    if (station) {
      dispatch(startLoading())
      dispatch(getAdminStaff({ station, hasPermissionEditAll }))
      dispatch(getMeetingRoom(station))
      dispatch(getExternalSpeaker())
      await dispatch(getResponsibility())
      dispatch(stopLoading())
    }
  }

export const handleCourseName = (data) => {
  const course = `${_.get(data, 'courseCode', '')} ${_.get(data, 'course', '')}`
  return course.trim()
}

export const handleTrainingDate = (monthlyPlan, isOnlyDate = false) => {
  const startDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'startDate', ''),
    type: 'date',
  })
  const startTime = _.get(monthlyPlan, 'dates[0].startTime', '')
  const finishDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'finishDate', ''),
    type: 'date',
  })
  const finishTime = _.get(monthlyPlan, 'dates[0].endTime', '')

  if(isOnlyDate){
    if(startDate == finishDate){
      return startDate
    }
    return `${startDate} - ${finishDate}`
  }
  return `${startDate} ${startTime} - ${finishDate} ${finishTime}`
}

export const handleRegister =
  (name, registerDate, registerTime) => (dispatch) => {
    if (!_.isEmpty(registerDate) && !_.isEmpty(registerTime)) {
      const value = dayjs(`${registerDate} ${registerTime}`).format(
        'DD/MM/YYYY HH:mm'
      )
      dispatch(setEditData({ keyField: name, value: value }))
    } else {
      dispatch(setEditData({ keyField: name, value: '' }))
    }
  }

export const handleAdminTel = (adminStaffOption, assignNee, assignNeeObj) => {
  const admin = adminStaffOption.find(
    (item) => item.value == _.get(assignNee, 'uuid', assignNee)
  )
  if (_.isEmpty(admin)) return _.get(assignNeeObj, 'tel', '-')
  return _.get(admin, 'tel', '-')
}

export const handleNumberInput = (e) => {
  const target = e.target
  const numVal = Math.abs(target.value)
  const isDot = e.nativeEvent.data == '.'

  if (!isDot) {
    target.value = numVal == 0 ? '' : target.value // min 1
  } else target.value = ''

  return target.value
}

export const handleInputChange = (e, isSwitch) => (dispatch) => {
  const keyField = _.get(e, 'target.name', '')
  let value = _.get(e, 'target.value', '')
  if (isSwitch) value = _.get(e, 'target.checked', false)
  dispatch(setEditData({ keyField, value }))
}

export const handleSelectChange = (props) => (dispatch) => {
  if (props.keyField === 'assignNee') {
    const listData = props.dates
    listData.forEach((item, index) => {
      const adminStaffPlan = _.get(item, 'adminStaffPlan', [])
      const filter = adminStaffPlan.filter(
        (itemStaff) =>
          itemStaff.value !== props.value &&
          _.get(itemStaff, 'staff.uuid', '') !== props.value
      )
      dispatch(handleMultiSelectChange(filter, 'adminStaffPlan', index))
    })
  }
  dispatch(setEditData(props))
}

export const handleAmountChange = (e) => (dispatch) => {
  const keyField = _.get(e, 'target.name', '')
  const strValue = _.get(e, 'target.value', '')
  const value = strValue == '' ? '' : Number(strValue)
  dispatch(setEditMonthlyPlan({ keyField, value }))
}

export const handleEditorChange = (value) => (dispatch) => {
  dispatch(setEditData({ keyField: 'detail', value }))
}
