import { store } from '../../../../../../App'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = (codeId) => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
    if (filterState.status.waitingChecked) {
      status.push(STATUS.WAITING)
    }
    if (filterState.status.draftChecked) {
      status.push(STATUS.DRAFT)
    }
  } else {
    status = []
  }

  return {
    codeId: codeId,
    version: filterState.versionChecked
      ? parseFloat(filterState.version) || -1
      : -1,
    name: filterState.nameChecked ? filterState.name : '',
    startDate: filterState.startDateChecked ? filterState.startDate : '',
    toDate: filterState.startDateChecked ? filterState.toDate : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    createdBy: filterState.createdByChecked ? filterState.createdBy : '',
    status: status,
  }
}
