import { downloadFile } from '../../../../services/util'
import { store } from '../../../../App'
import { monthlyPlanDownloadStaff } from '../../../../utils/apiPath'
import { validateDate } from 'src/utils/lib'
import dayjs from 'dayjs'
import { startLoading, stopLoading } from 'src/redux/slices/table'
import {
  TOP_LIST_TYPE,
  TOP_LIST_TYPE_LABEL,
} from 'src/constants/monthlyPlan/dashboard'

export const downloadStaffList = async () => {
  store.dispatch(startLoading())
  const { filters, staffType } = store.getState().monthlyPlan.dashboard

  const downloadFileName = `รายชื่อ ${
    staffType === TOP_LIST_TYPE.SPEAKER
      ? 'หน้าที่'
      : staffType === TOP_LIST_TYPE.WORKLOAD
      ? 'ที่มี'
      : ''
  } ${TOP_LIST_TYPE_LABEL[staffType]} ${
    staffType !== TOP_LIST_TYPE.SPEAKER ? 'สูงสุด (ทุกประเภท)' : 'มากที่สุด'
  }`

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
    courseCodeList,
    positionList,
  } = filters

  const body = {
    type: staffType,
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList?.length > 0 ? departmentList : defaultDepartment,
    station: stationList?.length > 0 ? stationList : defaultStation,
    courseCode: courseCodeList?.length > 0 ? courseCodeList : [],
    position: positionList?.length > 0 ? positionList : [],
    limit: -1,
    page: 1,
  }

  await store.dispatch(
    downloadFile({
      url: monthlyPlanDownloadStaff,
      body: body,
      fileName: `${downloadFileName}.xlsx`,
    }),
  )
  store.dispatch(stopLoading())
}
