import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"
import _ from "lodash"

export const handleAddInsideContact = async () => {
    const { formData } = store.getState().crud
    const insideContacts = _.get(formData, 'insideContacts', [])
    store.dispatch(setFieldValue({
        key: 'formData.insideContacts',
        value: [...insideContacts, {name: '',}],
    }))
}