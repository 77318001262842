import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { getPermission, getStaffList } from '../../../services/permission'

const permission = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startPageLoading: (state) => {
      state.isPageLoading = true
    },
    stopPageLoading: (state) => {
      state.isPageLoading = false
    },
    setInitialForm: (state, { payload }) => {
      state.data = payload
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.data, `${key}`, value)
    },
    setAddStaff: (state, { payload }) => {
      const findStaff = state.staffList.find((item) => item.uuid === payload)
      state.data.staff.push(findStaff)
    },
    setDeleteStaff: (state, { payload }) => {
      state.data.staff.splice(payload, 1)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermission.fulfilled, (state, { payload }) => {
        state.permissionList = payload
      })
      .addCase(getPermission.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getStaffList.fulfilled, (state, { payload }) => {
        state.staffList = _.get(payload, 'result', [])
      })
      .addCase(getStaffList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startPageLoading,
  stopPageLoading,
  setInitialForm,
  setChange,
  setAddStaff,
  setDeleteStaff,
} = permission.actions

export default permission.reducer
