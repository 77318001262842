import { Box, Card, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Box)(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  opacity: isLoading ? 0.5 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

export const StyledCard = styled(Card)(({ theme, isLoading }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  opacity: isLoading ? 0.5 : 1,
}))

export const LoadingPage = styled(CircularProgress)(({ theme, isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: theme.palette?.primary?.main,
}))
