import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'

import Autocomplete from '../../components/Input/Autocomplete'
import TextInput from '../../components/Input/TextInput'
import Select from '../../components/Input/Select'
import SwitchInput from '../../components/Input/SwitchInput'
import AlertDialog from '../../components/AlertDialog'
import { hasPermissionCreateMasterData } from '../../utils/lib'

const LevelForm = (props) => {
  const {
    isEditForm,
    dialog,
    setDialog,
    handleClose,
    formik,
    initialValues,
    setInitialValues,
    levelOfLearner,
  } = props
  const staffTypeOptions = [
    { label: 'Trainer', value: 'Trainer' },
    { label: 'Staff', value: 'Staff' },
  ]
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })

  const onChangePosition = async (value) => {
    const filterlLevel = staffTypeOptions.filter((item) => {
      return item.value === value
    })
    if (filterlLevel) {
      formik.setFieldValue('position', value)
    }
  }

  const listOptions = !isEditForm
    ? levelOfLearner
    : [...levelOfLearner, initialValues.defaultLevelOfLearner]

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 3,
              mx: 3,
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              value={initialValues.id}
            ></TextInput>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3,
            mx: 3,
          }}
        >
          <TextInput
            id="level"
            name="level"
            labelText="Level"
            type="basic"
            textError={formik?.errors?.level}
            required
            disabled={!hasPermissionCreate}
            value={initialValues.level}
            onChange={(e) => {
              formik.values.level = e.target.value
              setInitialValues({ ...initialValues, level: e.target.value })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
          <Select
            dataTestid="select-position"
            id="position"
            name="position"
            labelText="ประเภทของพนักงาน"
            type="basic"
            disabled={!hasPermissionCreate}
            required
            options={staffTypeOptions}
            handleChange={(e) => onChangePosition(e.target.value)}
            value={initialValues.position}
            textError={_.get(formik, 'errors.position', '')}
            placeholder={'เลือก'}
          ></Select>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3,
            mx: 3,
          }}
        >
          <Autocomplete
            id="selectOnsite"
            name="selectOnsite"
            labelText="ระดับของผู้เรียน"
            type="basic"
            required
            options={listOptions}
            defaultValue={findValueLevel(
              listOptions,
              initialValues.levelOfLearner
            )}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="เลือก"
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'select-selectOnsite',
                }}
              />
            )}
            handleChange={(e, obj) => {
              const value = _.get(obj, 'value', null)
              formik.values.levelOfLearner = value
              setInitialValues({
                ...initialValues,
                levelOfLearner: value,
              })
            }}
            textError={_.get(formik, 'errors.levelOfLearner', '')}
          />

          <TextInput
            id="sequence"
            name="sequence"
            labelText="ลำดับ"
            type="basic"
            inputProps={{
              'data-testid': 'sequence',
              type: 'text',
            }}
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.sequence}
            required
            maxlength={5}
            value={initialValues.sequence}
            onChange={(e) => {
              const value = e.target.value
              const replaceValue = value.replace(/[^\d]/g, '')
              formik.values.sequence = replaceValue
              setInitialValues({
                ...initialValues,
                sequence: replaceValue,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3,
            mx: 3,
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            defaultChecked
            placeholder="ค้นหาระดับของผู้เรียน"
            value={initialValues.status}
            disabled={!hasPermissionCreate}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
          ></SwitchInput>
        </Box>
        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย{' '}
                {initialValues && initialValues.updatedBy
                  ? initialValues.updatedBy
                  : ''}
                <br />
                {initialValues && initialValues.updatedAt
                  ? initialValues.updatedAt
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </Card>
  )
}
export default LevelForm

export const findValueLevel = (list, value) => {
  if (!list.length) return null
  const findValue = list.find((item) => item?.value === value)
  return _.isEmpty(findValue) ? null : findValue
}
