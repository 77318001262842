import _ from 'lodash'
import {
  setLoadMore,
  startLoadMore,
  stopLoadMore,
  startTrainingPlanLoading,
  stopTrainingPlanLoading,
} from '../../../../../../../../redux/slices/eLearning/learningPathPreview'
import { postCourseClassList } from '../../../../../../../../services/eLearning/learningPath/preview'

export const handleCourseClassList = (uuid, filterBody) => async (dispatch) => {
  const payload = { uuid, body: filterBody }
  dispatch(startTrainingPlanLoading())
  await dispatch(postCourseClassList(payload))
  dispatch(stopTrainingPlanLoading())
}

export const handleLoadMore = () => (dispatch) => {
  if (isScrolledToBottom()) dispatch(setLoadMore())
}

export const isScrolledToBottom = () => {
  const element = document.documentElement
  const scrollPosition = Math.abs(
    element.scrollHeight - element.clientHeight - element.scrollTop,
  )
  return scrollPosition <= 0
}

export const handleSeeMore = (uuid, filterBody) => async (dispatch) => {
  if (filterBody.page === 1) return

  const payload = { uuid, body: filterBody }
  dispatch(startLoadMore())
  await dispatch(postCourseClassList(payload))
  dispatch(stopLoadMore())
}

export const handleSeatText = (data) => {
  const canRegister = _.get(data, 'canRegister', false)
  const availableSeats = _.get(data, 'availableSeats', 0)

  if (availableSeats === 0) return { text: 'เต็ม', color: 'error' }

  if (!canRegister) return { text: availableSeats }
  else return { text: '-' }
}
