import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import styled from '@mui/system/styled'

export const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}))

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginLeft: '21px',
  gap: theme.spacing(1),
}))
