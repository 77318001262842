import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import { UpdatedComponent } from '../../../../components/CRUD/components/UpdatedComponent'
import { StatusComponent } from '../../../../components/CRUD/components/StatusComponent'
import _ from 'lodash'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  console.log(formData)
  return (
    <>
      <ViewRow title="ID" value={formData.id} />
      <ViewRow title="Old Account Code" value={formData.oldAccountCode} />
      <ViewRow title="New Account Code" value={formData.newAccountCode} />
      <ViewRow
        title="Categories"
        value={_.get(formData, 'category.name', '')}
      />
      <ViewRow title="Description" value={formData.description} />
      <ViewRow title="Definition ENG" value={formData.definitionENG} />
      <ViewRow title="Definition TH" value={formData.definitionTH} />
      <ViewRow title="สถานะ" valueComponent={StatusComponent(formData)} />
      <ViewRow title="หมายเหตุ" value={formData.remark} />
      <Divider />
      <UpdatedComponent />
    </>
  )
}
