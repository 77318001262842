import _ from 'lodash'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'

export const downloadChart = (elementId, btnElementId) => {
  const chartBoxElement = document.getElementById(elementId)

  if (_.isEmpty(chartBoxElement) || _.isEmpty(chartBoxElement.style)) return

  chartBoxElement.style.display = 'flex'
  chartBoxElement.style.flexDirection = 'column'
  chartBoxElement.style.alignItems = 'center'
  const btnDownloadChartElement = document.getElementById(btnElementId)

  btnDownloadChartElement.style.display = 'none'

  htmlToImage
    .toPng(chartBoxElement, { width: 416, height: 400 })
    .then(function (dataUrl) {
      download(dataUrl, 'Chart.png')
      btnDownloadChartElement.style.display = 'flex'
    })
}
