//import dayjs from 'dayjs'
import { store } from '../../../../../../../App'
import { TESTTYPE } from '../model/testType'

export const mutateFilterSateToProp = (versionUuid) => {
  const { filterState } = store.getState().table
  let testType = []
  if (filterState.testTypeChecked) {
    if (filterState.testType.preTestChecked) {
      testType.push(TESTTYPE.PRETEST)
    }
    if (filterState.testType.postTestChecked) {
      testType.push(TESTTYPE.POSTTEST)
    }
    if (filterState.testType.otherChecked) {
      testType.push(TESTTYPE.OTHER)
    }
  } else {
    testType = []
  }
  return {
    eTestVersionUuid: versionUuid,
    startDate: filterState.startDateChecked ? filterState.startDate : '',
    endDate: filterState.startDateChecked ? filterState.endDate : '',
    courseCode: filterState.courseCodeChecked ? filterState.courseCode : '',
    classUuid: filterState.classUuidChecked ? filterState.classUuid : '',
    distribution: filterState.distributionChecked
      ? filterState.distribution
      : '',
    agentCode: filterState.agentCodeChecked ? filterState.agentCode : '',
    unitCode: filterState.unitCodeChecked ? filterState.unitCode : '',
    sRam: filterState.sRamChecked ? filterState.sRam : '',
    ramCode: filterState.ramCodeChecked ? filterState.ramCode : '',
    trainer: filterState.trainerChecked ? filterState.trainer : '',
    testType: testType,
  }
}
