import { memo, useCallback, useEffect, useState } from 'react'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Chip, createFilterOptions, TextField, Typography } from '@mui/material'
import Autocomplete from '../../../components/Input/Autocomplete'
import { fetchTag } from '../../../modules/EContent/Content/Form/handler/fetchTag'

const CustomMultiAutoComplete = memo(
  ({
    required,
    disabled,
    fieldName,
    labelName,
    handleExtraChange,
    boxSx = {},
    sx = { width: '100%' },
    placeholder = 'กรุณาเลือก',
    masterDataName,
  }) => {
    const dispatch = useDispatch()
    const [isTyping, setIsTyping] = useState(false)
    const { value, isError, message } = useInputField(fieldName)

    const { masterCategory: master } = useSelector(
      (state) => ({
        masterCategory:
          state.crud.masterData[masterDataName ? masterDataName : fieldName],
      }),
      shallowEqual,
    )

    const { formData } = useSelector(
      (state) => ({
        formData: state.crud.formData,
      }),
      shallowEqual,
    )

    const filter = createFilterOptions()

    const debouncedSetField = useCallback(
      createDebouncedSetField(dispatch, 'tagInput'),
      [dispatch],
    )

    function createDebouncedSetField(dispatch, FIELD_NAME) {
      return _.debounce((inputValue) => {
        dispatch(
          setFieldValue({ key: `formData.${FIELD_NAME}`, value: inputValue }),
        )
      }, 300)
    }

    function handleValueChange(event, newValue) {
      const dataValue = newValue.map((data) => {
        if (typeof data === 'string') {
          return { label: data }
        } else if (newValue && data.inputValue) {
          return { label: data.inputValue }
        } else return data
      })
      dispatch(
        setFieldValue({ key: `formData.${fieldName}`, value: dataValue }),
      )
      if (handleExtraChange) {
        handleExtraChange(event, newValue)
      }
    }

    const checkInputValue = (option) => {
      if (typeof option === 'string') {
        return option
      }
      if (option.inputValue) {
        return option.inputValue
      }
      return option.label
    }

    useEffect(() => {
      fetchTag(formData.tagInput, setIsTyping)
    }, [formData.tagInput])

    if (_.isUndefined(master)) {
      return <div />
    }

    return (
      <>
        <Autocomplete
          isLoading={isTyping}
          multiple={true}
          freeSolo={true}
          filterSelectedOptions={true}
          isHideSort={true}
          sx={sx}
          boxSx={{ ...boxSx }}
          required={required}
          disabled={disabled}
          id={`groupChip-${labelName}`}
          labelText={labelName}
          placeholder={placeholder}
          defaultValue={_.isEmpty(value) || _.isNil(value) ? [] : value}
          textError={message}
          showTextError={isError}
          options={master.options}
          getOptionLabel={(option) => {
            return checkInputValue(option)
          }}
          handleInputChange={(e) => {
            setIsTyping(true)
            dispatch(
              setFieldValue({
                key: 'masterData.eContentTag',
                value: {
                  isLoading: false,
                  options: [],
                },
              }),
            )
            debouncedSetField(e.target.value)
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            const { inputValue } = params

            const isExisting = options.some(
              (option) => inputValue === option.label,
            )
            if (
              inputValue !== '' &&
              !isExisting &&
              !value.some((item) => item.label === inputValue)
            ) {
              filtered.push({
                inputValue,
                label: `เพิ่ม "${inputValue}"`,
              })
            }
            return filtered
          }}
          handleChange={(e, newValue) => {
            handleValueChange(e, newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder:
                  _.isEmpty(value) || _.isNil(value) ? placeholder : '',
                style: {
                  paddingLeft: '8px',
                },
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              option = checkInputValue(option)
              return (
                <Chip
                  label={<Typography>{option}</Typography>}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    border: 1,
                    borderColor: 'text.disabled',
                  }}
                  key={option}
                />
              )
            })
          }
        />
      </>
    )
  },
)

CustomMultiAutoComplete.displayName = 'CustomMultiAutoCompleteComponent'

export { CustomMultiAutoComplete }
