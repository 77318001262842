import _ from 'lodash'
import * as slices from '../../../../redux/slices/masterData/licenseForm'
import { openErrorDialog, closeDialog } from '../../../../redux/slices/dialog'
import { postUploadDocument } from '../../../../services/masterData/license'

import { downloadFileTypeGet } from '../../../../services/util'

export const listTypeView = {
  pdf: 'pdf',
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
}

export const listType = {
  ...listTypeView,
  word: 'word',
  excel: 'excel',
  xlsx: 'xlsx',
  xls: 'xls',
  csv: 'csv',
  txt: 'txt',
  pptx: 'pptx',
  docx: 'docx',
}

export const onChangeUpload = (event) => async (dispatch) => {
  const files = _.get(event, 'target.files[0]', [])
  let body = new FormData()
  if (files.length == 0) return
  body.append('file', files)
  const getNameImage = _.get(event, 'target.files[0].name', '')
  const idInput = document.getElementById('btn-upload')

  if (getNameImage) {
    const typeFile = getNameImage.split('.').pop()
    const sizeFile = _.get(event, 'target.files[0].size', 0)
    if (!listType[typeFile.toLowerCase()]) {
      if (idInput) idInput.value = null
      return dispatch(checkErrorDialogUpload(false))
    }
    if (sizeFile > 2000000) {
      if (idInput) idInput.value = null
      return dispatch(checkErrorDialogUpload(true))
    }
  }

  const result = await dispatch(postUploadDocument(body))
  dispatch(
    slices.setChangeFile({
      title: _.get(result, 'payload.fileName', ''),
      documentKey: _.get(result, 'payload.key', ''),
    })
  )
  if (idInput) idInput.value = null
}

export const handleChange = (props) => (dispatch) => {
  dispatch(slices.setChangeInformation(props))
}

export const checkErrorDialogUpload = (isSize) => (dispatch) => {
  let obj = {
    title: 'ผิดพลาด',
    message: 'นามสกุลไฟล์ไม่ถูกต้อง',
  }
  if (isSize) {
    obj = {
      title: 'ขนาดไฟล์ใหญ่เกินไป',
      message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
    }
  }
  dispatch(
    openErrorDialog({
      title: obj.title,
      message: obj.message,
      handleError: () => dispatch(closeDialog()),
    })
  )
}

export const handleDeleteDocument = (listDocument, index) => (dispatch) => {
  if (!listDocument.length) return []
  const newListDocument = [...listDocument]
  newListDocument.splice(index, 1)
  dispatch(
    slices.setChangeInformation({
      key: 'documents',
      value: newListDocument,
    })
  )
}

export const onDownload = (urlDownload, titleFile) => async (dispatch) => {
  await dispatch(
    downloadFileTypeGet({
      url: urlDownload,
      fileName: titleFile,
    })
  )
}

export const checkViewDocument = (type) => {
  return !listTypeView[type.toLowerCase()]
}
