import _ from 'lodash'
import { store } from '../../../../../App'
import { STATUS } from '../model/status'
import { vendorTypeEnum } from '../model/vendorTypeEnum'
import { getFormatDate } from '../../../../../utils/lib'
import { formatDate } from '../constants/formatDate'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }
  let vendorType = []
  if (filterState.vendorTypeChecked) {
    if (filterState.vendorType.vendor) {
      vendorType.push(vendorTypeEnum.VENDOR)
    }
    if (filterState.vendorType.asset) {
      vendorType.push(vendorTypeEnum.ASSET)
    }
    if (filterState.vendorType.other) {
      vendorType.push(vendorTypeEnum.OTHER)
    }
  } else {
    vendorType = []
  }

  let station = []
  if (filterState.stationChecked) {
    if (!_.isEmpty(filterState.station)) {
      const stationList = filterState.station
      Object.entries(stationList).forEach(([key, value]) => {
        if (value) station.push(key)
      })
    }
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    vendorType: vendorType,
    dateStartDate: filterState.dateChecked
      ? getFormatDate(filterState.dateStartDate ?? '', formatDate)
      : '',
    dateFinishDate: filterState.dateChecked
      ? getFormatDate(filterState.dateFinishDate ?? '', formatDate)
      : '',
    renewDateStartDate: filterState.renewDateChecked
      ? getFormatDate(filterState.renewDateStartDate ?? '', formatDate)
      : '',
    renewDateFinishDate: filterState.renewDateChecked
      ? getFormatDate(filterState.renewDateFinishDate ?? '', formatDate)
      : '',
    createStartDate: filterState.createDateChecked
      ? getFormatDate(filterState.createStartDate ?? '', formatDate)
      : '',
    createFinishDate: filterState.createDateChecked
      ? getFormatDate(filterState.createFinishDate ?? '', formatDate)
      : '',
    updateStartDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateStartDate ?? '', formatDate)
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? getFormatDate(filterState.updateFinishDate ?? '', formatDate)
      : '',
    insideContact: filterState.insideContactChecked
      ? filterState.insideContact
      : '',
    outsideContact: filterState.outsideContactChecked
      ? filterState.outsideContact
      : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,
    station: station,
  }
}
