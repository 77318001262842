import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 16,
  right: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    '&#view': {
      position: 'relative',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
  }),
)

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
  gap: theme.spacing(2),
}))

export const StyledCircularBox = styled(Box)(() => ({
  background: 'rgba(255, 255, 255, 0.4)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  left: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
