import React from 'react'
import Modal from '@mui/material/Modal'
import ReactPlayer from 'react-player'
import { StyledVideoWrapper } from './Styled'

const PreviewVideoModal = ({ open, onClose, link }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledVideoWrapper>
        <ReactPlayer url={link} playing controls />
      </StyledVideoWrapper>
    </Modal>
  )
}

export default PreviewVideoModal
