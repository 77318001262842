export const filterStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterStatusItems = [
  {
    label: 'ปกติ',
    name: 'normalChecked',
  },
  {
    label: 'คงเหลือน้อย',
    name: 'lowChecked',
  },
]

export const filterItems = [
  {
    label: `คงเหลือ`,
    nameCheckBox: 'totalChecked',
    nameInput: 'total',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'totalFrom',
    inputTo: 'totalTo',
    type: 'number_between',
  },
  {
    label: `พร้อมใช้งาน`,
    nameCheckBox: 'availableChecked',
    nameInput: 'available',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'availableFrom',
    inputTo: 'availableTo',
    type: 'number_between',
  },
  {
    label: `มูลค่าต่อหน่วย (฿)`,
    nameCheckBox: 'unitPriceChecked',
    nameInput: 'unitPrice',
    placeholder: `ระบุจำนวน`,
    inputFrom: 'unitPriceFrom',
    inputTo: 'unitPriceTo',
    type: 'float_between',
  },
  {
    label: `ระยะสัญญาที่เหลือ`,
    nameCheckBox: 'refundChecked',
    nameInput: 'refund',
    placeholder: ``,
    inputYear: 'refundYear',
    inputMonth: 'refundMonth',
    inputDay: 'refundDay',
    type: 'number_day',
  },
  {
    label: 'ระยะสัญญา',
    placeholder: 'เลือก ระยะสัญญา',
    nameCheckBox: 'contractDateChecked',
    nameInput: 'contractDate',
    dateStateStart: 'contractStartDate',
    dateStateFinish: 'contractEndDate',
    dateStateDisplay: 'contractDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'วันที่ต่อสัญญา',
    placeholder: 'เลือก วันที่ต่อสัญญา',
    nameCheckBox: 'renewContractDateChecked',
    nameInput: 'renewContractDate',
    dateStateStart: 'renewContractStartDate',
    dateStateFinish: 'renewContractEndDate',
    dateStateDisplay: 'renewContractDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'วันที่เคลื่อนไหวล่าสุด',
    placeholder: 'เลือก วันที่เคลื่อนไหวล่าสุด',
    nameCheckBox: 'updatedDateChecked',
    nameInput: 'updatedDate',
    dateStateStart: 'startUpdatedDate',
    dateStateFinish: 'endUpdatedDate',
    dateStateDisplay: 'updatedDateDisplayDate',
    type: 'daterange',
  },
  {
    label: `ผู้ถือครองปัจจุบัน`,
    nameCheckBox: 'holderChecked',
    nameInput: 'holder',
    placeholder: `กรุณาใส่ชื่อ`,
    type: 'text',
  },
  {
    label: 'วันที่สร้าง',
    placeholder: 'เลือก วันที่สร้าง',
    nameCheckBox: 'createdDateChecked',
    nameInput: 'createdDate',
    dateStateStart: 'startCreatedDate',
    dateStateFinish: 'endCreatedDate',
    dateStateDisplay: 'createdDateDisplayDate',
    type: 'daterange',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: `กรุณาใส่ชื่อ`,
    type: 'text',
  },
  {
    label: 'สถานะคงเหลือ',
    nameCheckBox: 'statusItemsChecked',
    nameInput: 'statusItems',
    type: 'checkbox',
    list: filterStatusItems,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatus,
  },
]
