import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React from 'react'
import { StyledImageBox, StyledImageInfo } from './Styled'

const CoverImage = ({ loadingProgress, isError, url, isInfo }) => {
  if (loadingProgress > 0 && loadingProgress < 100 && !isError)
    return (
      <StyledImageBox isLoading isInfo>
        <Box>
          <UploadFileOutlined />
          <LinearProgress variant="determinate" value={loadingProgress} />
        </Box>
      </StyledImageBox>
    )
  else
    return (
      <Box sx={{ width: 280 }}>
        <StyledImageBox isError={isError} isInfo={isInfo}>
          {url && (
            <StyledImageInfo
              src={`${window.__env__.REACT_APP_API_URL}/file${url}`}
            />
          )}
          {isError && (
            <Box>
              <UploadFileOutlined />
              <Typography variant="buttons">ผิดพลาด</Typography>
            </Box>
          )}
        </StyledImageBox>
        {isError && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            อัพโหลดรูปภาพไม่สำเร็จ ลองใหม่อีกครั้ง
          </Typography>
        )}
      </Box>
    )
}

export default CoverImage
