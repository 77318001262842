import { getLearnerRandomClick } from '../../../../../../services/eLearning/course/learner'
import { setClickLoading } from '../../../../../../redux/slices/eLearning/courseBatchLearnerDetail'

export const fetchRandomClick = (props) => async (dispatch) => {
  const { limit, page, uuid } = props
  const realPage = page <= 0 ? 1 : +page
  const url = `?limit=${limit}&page=${realPage}&uuid=${uuid}`
  dispatch(setClickLoading(true))
  await dispatch(getLearnerRandomClick(url))
  dispatch(setClickLoading(false))
}
