import { crudConfig } from '../../crudConfig'

export const breadcrumb = [
  { title: 'Inventory', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: `รายละเอียด ${crudConfig.modulesTitleShort}`,
    link: `${crudConfig.modulePath}/view`,
    pointer: false,
  },
]
