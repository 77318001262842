import _ from 'lodash'
import { convertFormatDateTime } from '../../utils/lib'

export const handleBatchDate = (data) => {
  const periodStart = convertFormatDateTime({
    value: _.get(data, 'periodStart', ''),
    type: 'date',
  })
  const periodEnd = convertFormatDateTime({
    value: _.get(data, 'periodEnd', ''),
    type: 'date',
  })

  if (periodStart == periodEnd) {
    return periodStart
  }
  return `${periodStart} - ${periodEnd}`
}
