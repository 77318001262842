import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import * as reducer from '../../../../redux/slices/eTestingForm'
import { submitForm } from '../../../../services/eTesting/form'
import { read } from 'xlsx'
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'

import {
  openDialog,
  loadingDialog,
  closeDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const onSubmitForm = (props) => async (dispatch) => {
  const { isDraft } = props
  dispatch(
    openDialog({
      title: isDraft ? 'ยืนยันบันทึกฉบับร่าง' : 'ยืนยันบันทึกและเผยแพร่',
      message: isDraft
        ? 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่'
        : 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(actionSubmit(props)),
    })
  )
}

export const actionSubmit = (props) => async (dispatch) => {
  const { stateETesting, isDraft, history } = props
  const body = {
    uuid: _.get(stateETesting, 'uuid', ''),
    codeId: _.get(stateETesting, 'codeId', ''),
    typeOfETesting: _.get(stateETesting, 'typeOfETesting', 'ONLINE_TEST'),
    information: _.get(stateETesting, 'information', {}),
    setting: _.get(stateETesting, 'setting', {}),
    questions: _.get(stateETesting, 'questions', []),
    isDraft,
  }

  dispatch(reducer.startLoading())
  dispatch(loadingDialog())
  const result = await dispatch(submitForm(body))

  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      handleConfirm: () => {
        history.push(
          `/e-testing/version/${_.get(result, 'payload.data.codeId', '')}`
        )
        dispatch(closeDialog())
      },
    })
  )
  dispatch(reducer.setClearData())
  dispatch(reducer.stopLoading())
}

export const onErrorValidate = (isInfo) => async (dispatch) => {
  if (isInfo) window.scrollTo(0, 130)
  dispatch(
    openErrorDialog({
      title: 'กรอกข้อมูลไม่ครบ',
      message: (
        <>
          <Typography variant="body1">
            ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ
          </Typography>
          <Typography variant="body1">หรือไม่ตรงตามเงื่อนไขที่กำหนด</Typography>
        </>
      ),
    })
  )
}

export const objQuestionType = {
  single_choice: 'SINGLE',
  multiple_choice: 'MULTIPLE',
  free_text: 'FREE_TEXT',
}

export const handleImportTemplate = (props) => async (dispatch) => {
  const { dataImport, questions } = props

  dispatch(
    importDialog({
      status: !questions?.length ? 'questionEmpty' : 'questionNoEmpty',
      dataImport,
      questions,
    })
  )
}

export const submitImport = (dataImport, defaultQuestion) => (dispatch) => {
  dispatch(loadingDialog())

  const file = dataImport.target.files[0]
  const getNameFile = _.get(dataImport, 'target.files[0].name', '')

  if (file.length === 0) {
    removeValueImportETest()
    return dispatch(
      errorOutLoopImport({ status: 'fileInvalid', defaultQuestion })
    )
  }

  if (getNameFile) {
    const getExtensionFile = getNameFile.split('.').pop()
    const sizeFile = _.get(file, 'size', 0)
    if (sizeFile > 2000000) {
      removeValueImportETest()
      return dispatch(
        errorOutLoopImport({ status: 'fileOverSize', defaultQuestion })
      )
    }

    if (!checkTypeFileImport(getExtensionFile.toLowerCase())) {
      removeValueImportETest()
      return dispatch(
        errorOutLoopImport({ status: 'fileExtension', defaultQuestion })
      )
    }
  }
  setTimeout(async () => {
    const data = await file?.arrayBuffer()
    const workbook = read(data)
    const sheetETesting = _.get(workbook.Sheets, 'E-Testing', undefined)
    if (!sheetETesting) {
      removeValueImportETest()
      return dispatch(
        errorOutLoopImport({ status: 'fileInvalid', defaultQuestion })
      )
    }

    const { result, status } = dispatch(
      convertSheetToQuestions(sheetETesting, defaultQuestion)
    )
    removeValueImportETest()
    dispatch(
      reducer.setChange({
        key: 'questions',
        value: _.sortBy(result, ['key']),
      })
    )

    setTimeout(() => {
      if (!status) {
        dispatch(openDialog(dispatch(checkErrorMessage({ status: 'success' }))))
      }

      dispatch(stopLoadingDialog())
    }, 500)
  }, 500)
}

export const convertSheetToQuestions =
  (sheet, defaultQuestion) => (dispatch) => {
    const { allCells, questionCells } = getDataCells(sheet)
    let listQuestion = {
      result: [],
      status: false,
    }

    for (let index = 0; index < questionCells.length; index++) {
      const questionNumber = questionCells[index]

      const typeQuestion = _.snakeCase(
        _.get(sheet, `[D${questionNumber}].v`, undefined)
      )

      const titleQuestion = _.get(sheet[`C${questionNumber}`], 'v', '')
      const questionType = objQuestionType[typeQuestion] || null

      if (!typeQuestion || !titleQuestion) {
        listQuestion = dispatch(errorInLoopImport(defaultQuestion))
        break
      }

      let question = {
        key: index,
        mediaType: 'IMAGE',
        questionType,
        score: 0,
        sequence: _.get(sheet[`A${questionNumber}`], 'v', 1),
        title: titleQuestion,
        url: '',
        uuid: '',
        answers: [],
      }
      const nextQuestion = questionCells[index + 1]
      let sequence = 0
      let totalScore = 0

      for (let indexAnswer = 0; indexAnswer < allCells.length; indexAnswer++) {
        const answerNumber = allCells[indexAnswer]
        if (
          answerNumber > questionNumber &&
          isLessThanNextQuestion(answerNumber, nextQuestion)
        ) {
          const regex = /^[0-9]*(\.[0-9]{0,2})?$/
          const score = _.get(sheet[`B${answerNumber}`], 'v', 0)

          if (!regex.test(score) || Number(score) > 100) {
            listQuestion = dispatch(errorInLoopImport(defaultQuestion))
            break
          }

          totalScore += score
          sequence++

          // render answers
          question.answers.push({
            id: `id-${indexAnswer + 1}`,
            isCorrect: !_.isUndefined(sheet[`B${answerNumber}`]),
            mediaUrl: '',
            score: typeQuestion === 'multiple_choice' ? score : 0,
            sequence,
            title: _.get(sheet[`C${answerNumber}`], 'v', ''),
            uuid: '',
            questionType,
          })
        }
      }

      if (typeQuestion !== 'free_text' && question.answers.length <= 0) {
        listQuestion = dispatch(errorInLoopImport(defaultQuestion))
        break
      }

      question.score = totalScore
      listQuestion.result.push(question)
    }
    return listQuestion
  }

export const getDataCells = (sheet) => {
  const allCells = [] // all data
  const questionCells = [] // number of verses
  const check = 1
  for (const cell in sheet) {
    if (cell === '!ref' || cell === '!margins') continue
    let num = cell.match(/\d+/g)
    num = Number(num[0])
    if (num != check) {
      if (cell[0] === 'A') {
        questionCells.push(num)
      }
      allCells.push(num)
    }
  }

  return { allCells: _.uniq(allCells), questionCells }
}

export const isLessThanNextQuestion = (answerNumber, nextQuestion) => {
  return _.isUndefined(nextQuestion) || answerNumber < nextQuestion
}

export const checkTypeFileImport = (type) => {
  if (type === 'xlsx' || type === 'xls' || type === 'csv') return true
  return false
}

export const importDialog = (props) => (dispatch) => {
  const objDialog = dispatch(checkErrorMessage(props))
  dispatch(openDialog(objDialog))
}

export const checkErrorMessage = (props) => (dispatch) => {
  const { status, dataImport, questions } = props
  switch (status) {
    case 'fileInvalid':
      return {
        title: 'รูปแบบไฟล์ไม่ถูกต้อง',
        type: 'fail',
        message:
          'ไฟล์มีรูปแบบของเนื้อหาไม่ถูกต้อง กรุณาดาวน์โหลด Template ที่มีให้ และใช้งานตามที่กำหนดไว้',

        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'fileOverSize':
      return {
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        type: 'fail',
        message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'fileExtension':
      return {
        title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        type: 'fail',
        message:
          'ไม่สามารถ Import file ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้อง กรุณาดาวน์โหลด Template ที่มีให้ และใช้งานตามที่กำหนดไว้',
        handleError: () => {
          dispatch(
            reducer.setChange({
              key: 'questions',
              value: questions,
            })
          )
          dispatch(closeDialog())
        },
      }
    case 'questionEmpty':
      return {
        title: 'นำเข้า Template',
        type: 'confirm',
        message: 'คุณต้องการนำเข้า Template?',
        handleConfirm: () => dispatch(submitImport(dataImport, questions)),
        handleCancel: () => {
          removeValueImportETest()
          dispatch(closeDialog())
        },
      }
    case 'questionNoEmpty':
      return {
        title: 'นำเข้าทับคำถามปัจจุบัน',
        type: 'confirm',
        icon: <ErrorOutlineOutlined color="error" />,
        message:
          'คำถามปัจจุบัน จะถูกทับด้วย Template ที่คุณกำลังจะนำเข้าใหม่ ต้องการนำเข้า Template อยู่หรือไม่?',
        handleConfirm: () => dispatch(submitImport(dataImport, questions)),
        handleCancel: () => {
          removeValueImportETest()
          dispatch(closeDialog())
        },
      }
    case 'success':
      return {
        type: 'success',
        title: 'Import a template',
        message: 'นำเข้าข้อมูลสำเร็จ',
        agreeText: 'เปิดดู',
        handleConfirm: () => dispatch(closeDialog()),
      }
  }
}

export const errorInLoopImport = (defaultQuestion) => (dispatch) => {
  dispatch(importDialog({ status: 'fileInvalid', questions: defaultQuestion }))
  return {
    result: defaultQuestion,
    status: true,
  }
}

export const errorOutLoopImport =
  ({ status, defaultQuestion }) =>
  (dispatch) => {
    dispatch(importDialog({ status, questions: defaultQuestion }))
    removeValueImportETest()
  }

export const removeValueImportETest = () => {
  const idInput = document.getElementById('btn-import-template')
  if (idInput) idInput.value = null
}
