import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'
import { styledImageBoxProps } from '../Styled'

export const ImageQuestion = styled('img')({
  objectFit: 'contain',
  width: '100%',
  maxWidth: '150px',
  height: '100px',
  borderRadius: '4px',
  pointerEvents: 'none',
})

export const CancelUpload = styled(IconButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: theme?.palette?.error?.main,
  color: theme?.palette?.primary?.contrast,
  position: 'absolute',
  top: '-10px',
  right: -10,
  '&:hover': {
    backgroundColor: theme?.palette?.error?.main,
  },
}))

export const BoxImageQuestionAnswer = styled(Box)(
  ({ theme, isAnswer, isLoading, isError, isMulti }) => ({
    width: 150,
    height: '100px',
    position: 'relative',
    marginLeft: theme.spacing(6.5),
    marginBottom: isAnswer && !isError ? theme.spacing(2) : theme.spacing(0),
    borderRadius: theme.spacing(0.5),
    ...styledImageBoxProps(theme, isLoading, isError),
    [theme.breakpoints.down('sm')]: {
      marginLeft: isAnswer ? theme.spacing(6.5) : theme.spacing(0),
      marginTop: isAnswer && !isMulti ? theme.spacing(-1) : theme.spacing(1.5),
    },
  })
)
