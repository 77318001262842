import _ from 'lodash'
import {
  loadingDialog,
  closeDialog,
  openDialog,
} from '../../../redux/slices/dialog'
import {
  setChangeInformation,
  setFetchData,
  startLoading,
  stopLoading,
} from '../../../redux/slices/masterData/meetingRoomForm'
import {
  postMasterDataMeetingRoom,
  putMasterDataMeetingRoom,
} from '../../../services/masterData/meetingRoom'
import { store } from '../../../App'

export const handleFetchDataCreate = (stationUuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(
    setFetchData({
      information: {
        stationUuid: stationUuid,
        meetingRoomEquipment: [],
        status: 'ACTIVE',
        meetingRoomImages: [],
        meetingRoomImagesUploadList: [],
      },
    }),
  )
  dispatch(stopLoading())
}

export const handleSubmit = (value, setErrors) => (dispatch) => {
  const information = _.get(value, 'information', {})
  const { meetingRoomImages } = store.getState().uploadFile.formData
  const formattedMeetingRoomImages = _.map(
    _.get(meetingRoomImages, 'files', []),
    (img, idx) => {
      return {
        ...img,
        title: _.get(img, 'displayName', ''),
        imageKey: _.get(img, 'key', ''),
        sequence: idx + 1,
        imageFileSize: _.get(img, 'fileSize', ''),
      }
    },
  )
  const uuid = _.get(information, 'uuid', '')
  let body = {
    stationUuid: _.get(information, 'stationUuid', ''),
    roomName: _.get(information, 'roomName', ''),
    floor: Number(_.get(information, 'floor', 0)),
    sqm: Number(_.get(information, 'sqm', 0)),
    image: formattedMeetingRoomImages,
    meetingRoomEquipment: _.get(information, 'meetingRoomEquipment', []),
    capacityClassroom: Number(_.get(information, 'capacityClassroom', null)),
    roomFunction: _.get(information, 'roomFunction', ''),
    roomType: _.get(information, 'roomType', ''),
    remark: _.get(information, 'remark', null),
    status: _.get(information, 'status', ''),
  }
  const width = _.get(information, 'width', null)
  const length = _.get(information, 'length', null)
  const height = _.get(information, 'height', null)
  const capacityTheater = _.get(information, 'capacityTheater', null)
  const capacityGroup = _.get(information, 'capacityGroup', null)
  const capacityUShape = _.get(information, 'capacityUShape', null)
  const roomFunction = _.get(information, 'roomFunction', null)
  const roomType = _.get(information, 'roomType', null)

  body = {
    ...body,
    width: !_.isNull(width) ? Number(width) : null,
    length: !_.isNull(length) ? Number(length) : null,
    height: !_.isNull(height) ? Number(height) : null,
    capacityTheater: !_.isNull(capacityTheater)
      ? Number(capacityTheater)
      : null,
    capacityGroup: !_.isNull(capacityGroup) ? Number(capacityGroup) : null,
    capacityUShape: !_.isNull(capacityUShape) ? Number(capacityUShape) : null,
  }
  if (roomFunction === 'OTHER') {
    body = {
      ...body,
      roomFunctionOther: _.get(information, 'roomFunctionOther', ''),
    }
  }
  if (roomType === 'OTHER') {
    body = {
      ...body,
      roomTypeOther: _.get(information, 'roomTypeOther', ''),
    }
  }
  if (!_.isEmpty(uuid)) {
    body = {
      ...body,
      uuid,
    }
  }

  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(actionSubmit(body, setErrors)),
    }),
  )
}

export const actionSubmit = (body, setErrors) => async (dispatch) => {
  const uuid = _.get(body, 'uuid', '')
  dispatch(loadingDialog())
  const result = !_.isEmpty(uuid)
    ? await dispatch(putMasterDataMeetingRoom(body))
    : await dispatch(postMasterDataMeetingRoom(body))

  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        isCloseDialog: false,
        handleConfirm: () => {
          window.location.href = `/master-data/meeting-room/view/${_.get(
            result,
            'payload.uuid',
            '',
          )}`
        },
      }),
    )
  } else {
    dispatch(closeDialog())
    const resError = _.get(result, 'meta.response.data', {})
    const message = _.get(resError, 'message', {})
    const property = _.get(message, '[0].property', {})
    const value = _.get(message, '[0].constraints.value', {})
    setErrors({
      information: {
        [property]: value,
      },
    })
  }
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeInformation(props))
}

export const formattedNumberToString = (value) => {
  return !_.isNaN(value) && !_.isUndefined(value) ? value?.toLocaleString() : ''
}
