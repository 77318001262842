import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  agentProfileAgentCheck,
  agentProfileLicenseHistory,
  agentPointUpdate,
} from '../../../utils/apiPath'

export const getLicenseHistory = createAsyncThunk(
  'manage/getLicenseHistory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(agentProfileLicenseHistory, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postAgentCheckPoint = createAsyncThunk(
  'manage/postAgentCheckPoint',
  async (formData, { rejectWithValue }) => {
    try {
      return await axios.post(agentProfileAgentCheck, formData)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postAgentPoint = createAsyncThunk(
  'manage/postAgentPoint',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(agentPointUpdate, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
