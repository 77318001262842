import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'

export const handleClearFileSelected = async () => {
  const importFile = {
    file: null,
    fileName: 'No File Chosen',
    uploadResult: [],
  }
  store.dispatch(
    setFieldValue({ key: 'formData.importFile', value: importFile }),
  )
}
