import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledCard } from '../../../Styled'
import { Row, Column } from '../../Styled'
import Content from './Content'
import TypeTab from './TypeTab'
import TypeChart from './TypeChart'

const OverviewByTypeSection = () => {
  return (
    <StyledCard sx={{ mt: 2, p: 2, maxWidth: '100%', minWidth: '100%' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        สรุปรายประเภท
      </Typography>
      <Row sx={{ alignItems: 'stretch', gap: 3 }}>
        <Column sx={{ width: 'calc(60% - 12px)' }}>
          <TypeTab />
          <Content />
        </Column>
        <Column sx={{ width: '40%', height: 557 }}>
          <TypeChart />
        </Column>
      </Row>
    </StyledCard>
  )
}

export default OverviewByTypeSection
