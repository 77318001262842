import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Table from '../../../../../../components/redux/Table'
import { fetchDataHistory } from './events'

const History = () => {
  const dispatch = useDispatch()
  const { uuid, isOpen } = useSelector(
    (state) => ({
      uuid: state.agentProfile.initialState.info.uuid,
      isOpen: state.agentProfile.pointDrawerOpen,
    }),
    shallowEqual,
  )
  const { table } = useSelector((state) => state.table)

  const { page, limit } = table
  useEffect(() => {
    isOpen && uuid && dispatch(fetchDataHistory({ uuid, table }))
  }, [isOpen, page, limit])

  return (
    <Card sx={{ m: 3 }}>
      <Table />
    </Card>
  )
}

export default History
