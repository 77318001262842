import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxAddChoiceQuestion = styled(Box)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(5),
  cursor: 'pointer',
  // [theme.breakpoints.down('sm')]: {
  //   marginTop: theme.spacing(2.5),
  // },
}))
