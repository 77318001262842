import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Drawer from '@mui/material/Drawer'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'

import dayjs from 'dayjs'
import useTheme from '@mui/system/useTheme'
import DatePicker from '../../components/Input/DatePicker'
import Truncate from '../../components/redux/Table/Truncate'
import * as API from './api/Index'
import { ROW_PAGE_PER_LIST } from '../../constants/table'

export const handleChangePage = (event, newPage, setHistoryDrawer) => {
  setHistoryDrawer((prev) => ({
    ...prev,
    page: newPage,
  }))
}

export const handleChangeRowsPerPage = (
  event,
  setRowsPerPage,
  setHistoryDrawer,
) => {
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1
  setRowsPerPage({ label: '' + rowsPerPageChange, value: rowsPerPageChange })
  setHistoryDrawer((prev) => ({
    ...prev,
    page: 0,
    limit: parseInt(event.target.value, 10),
  }))
}

const HistoryDrawer = (props) => {
  const {
    onClose,
    historyList,
    historyDrawer,
    setHistoryDrawer,
    onDownload,
    state,
    setHistoryList,
  } = props
  const theme = useTheme()
  const [rowsPerPage, setRowsPerPage] = useState({ label: '100', value: 100 })
  const onDateChange = (event, key) => {
    setHistoryDrawer((prev) => ({
      ...prev,
      [key]: dayjs(event).format(window.__env__.REACT_APP_DATE_DB),
    }))
  }

  useEffect(() => {
    if (historyDrawer.open) {
      const startDateDb = _.get(historyDrawer, 'startDate', '')
      const finishDateDb = _.get(historyDrawer, 'finishDate', '')
      if (startDateDb.length === 10 && finishDateDb.length === 10) {
        API.fetchHistory({
          state,
          historyDrawer,
          setHistoryList,
          setHistoryDrawer,
        })
      }
    }
  }, [
    historyDrawer.open,
    historyDrawer.startDate,
    historyDrawer.finishDate,
    historyDrawer.page,
    historyDrawer.limit,
  ])

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor={'right'}
      open={historyDrawer.open}
      onClose={onClose}
    >
      {historyDrawer.open && (
        <>
          <Box
            sx={{
              my: 2,
              ml: 3,
              mr: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography variant="h5">ประวัติการจัดทำแผน</Typography>
            <IconButton
              sx={{ p: 0 }}
              data-testid={`btn-close-drawer`}
              color="primary"
              component="span"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />

          <Box
            sx={{
              mx: theme.spacing(3),
              mt: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  labelText="วันที่เริ่มทำรายการ"
                  boxSx={{ width: '200px' }}
                  onChange={(value) => onDateChange(value, 'startDate')}
                  value={_.get(historyDrawer, 'startDate', null)}
                  disabledStartDate={_.get(historyDrawer, 'startDate', null)}
                  disabledEndDate={_.get(historyDrawer, 'finishDate', null)}
                  hideDisableDateRange={true}
                />
                <DatePicker
                  id="finishDate"
                  name="finishDate"
                  labelText="วันที่สิ้นสุดทำรายการ"
                  boxSx={{ width: '200px' }}
                  value={_.get(historyDrawer, 'finishDate', null)}
                  onChange={(value) => onDateChange(value, 'finishDate')}
                  disabledStartDate={_.get(historyDrawer, 'startDate', null)}
                  disabledEndDate={_.get(historyDrawer, 'finishDate', null)}
                  hideDisableDateRange={true}
                />
              </Box>
              <Button
                data-testid={`btn-download`}
                sx={{ m: 3 }}
                size="m"
                variant="outlined"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={() => onDownload()}
              >
                ดาวน์โหลด
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 250,
                }}
                aria-label="simple table"
              >
                <TableHead sx={{ maxWidth: 250 }}>
                  <TableRow>
                    <TableCell>รายการ</TableCell>
                    <TableCell>คอร์ส</TableCell>
                    <TableCell>โดย</TableCell>
                    <TableCell>วันเวลา</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyList.map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell scope="row">
                        {row?.statusText || '-'}
                      </TableCell>
                      <TableCell>
                        <Truncate>{row?.detail || '-'}</Truncate>
                      </TableCell>
                      <TableCell>{row?.author || '-'}</TableCell>
                      <TableCell>{row?.updatedAt || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={historyDrawer.count}
              page={historyDrawer.page}
              rowsPerPageOptions={ROW_PAGE_PER_LIST}
              onPageChange={(event, tablePage) =>
                handleChangePage(event, tablePage, setHistoryDrawer)
              }
              onRowsPerPageChange={(event) =>
                handleChangeRowsPerPage(event, setRowsPerPage, setHistoryDrawer)
              }
              rowsPerPage={rowsPerPage.value}
              labelRowsPerPage={
                <Typography variant="caption" color="text.secondary">
                  แถวต่อหน้า :{' '}
                </Typography>
              }
              labelDisplayedRows={() => {
                const realPage =
                  historyDrawer.page <= 0 ? 1 : historyDrawer.page + 1
                let minRowsShowed =
                  historyDrawer.page < 0
                    ? 1
                    : rowsPerPage.value * historyDrawer.page + 1
                let maxRowsShowed =
                  rowsPerPage.value * realPage > historyDrawer.count
                    ? historyDrawer.count
                    : rowsPerPage.value * realPage

                if (maxRowsShowed < 0) {
                  maxRowsShowed = historyDrawer.count
                }
                if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

                return (
                  <Typography variant="caption">
                    {minRowsShowed}-{maxRowsShowed} of {historyDrawer.count}
                  </Typography>
                )
              }}
            />
          </Box>
        </>
      )}
    </Drawer>
  )
}
export default HistoryDrawer
