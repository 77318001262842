import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledModuleLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected, disabled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  gap: theme.spacing(2),
  background: isSelected ? '#F1F1F1' : 'transparent',
  cursor: disabled ? 'default' : 'pointer',
  color: disabled
    ? theme.palette?.text?.lightGray
    : theme.palette?.text?.primary,
  pointerEvents: disabled ? 'none' : 'auto',
  '& .MuiTypography-root': { lineHeight: '20px' },
  '&:hover': {
    background: disabled ? 'transparent' : '#F1F1F1',
  },
}))

export const StyledBoxText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

export const StyledProgressCircular = styled(CircularProgress)(
  ({ theme, value }) => ({
    position: 'absolute',
    left: 0,
    color:
      value === 100
        ? theme.palette?.success?.main
        : theme.palette?.primary?.main,
  }),
)
export const StyledProgressNumber = styled(Typography)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  fontSize: '12px',
}))
