import React from 'react'
import { StyledFormCard, StyledFormWrapper } from '../../../../../Styled'
import Header from './Header'
import List from './List'
import TransferStockDrawer from '../../Drawer/TransferStock'
import TransferStockSKUDrawer from '../../Drawer/TransferStockSKU'
import TransferStockAssetDrawer from '../../Drawer/TransferStockAsset'
import TransferStockLicenseDrawer from '../../Drawer/TransferStockLicense'
import TransferSkuNotImport from '../../Drawer/TransferSkuNotImport'
import { shallowEqual, useSelector } from 'react-redux'
import { assetTypeEnum, fromTypeEnum } from '../../enum/assetTypEnum'

const index = () => {
  const { isOpenFormDrawer, type, from, crudConfig } = useSelector(
    (state) => ({
      isOpenFormDrawer: state.crud.isOpenFormDrawer,
      type: state.crud.formData.type,
      from: state.crud.formData.from,
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  return (
    <>
      <StyledFormWrapper sx={{ mx: 3 }} id={`${crudConfig.moduleSlug}-assets`}>
        <StyledFormCard isUploadLoading={false} sx={{ gap: 3 }}>
          <Header />
          <List />
        </StyledFormCard>
      </StyledFormWrapper>

      {isOpenFormDrawer && type === assetTypeEnum.STOCK && (
        <TransferStockDrawer />
      )}

      {isOpenFormDrawer &&
        type === assetTypeEnum.SKU &&
        from?.value === fromTypeEnum.IMPORT_PRODUCT && (
          <TransferStockSKUDrawer />
        )}

      {isOpenFormDrawer &&
        type === assetTypeEnum.ASSET &&
        from?.value === fromTypeEnum.IMPORT_PRODUCT && (
          <TransferStockAssetDrawer />
        )}

      {isOpenFormDrawer &&
        type === assetTypeEnum.LICENSE &&
        from?.value === fromTypeEnum.IMPORT_PRODUCT && (
          <TransferStockLicenseDrawer />
        )}

      {isOpenFormDrawer &&
        [
          assetTypeEnum.SKU,
          assetTypeEnum.ASSET,
          assetTypeEnum.LICENSE,
        ].includes(type) &&
        from?.value !== fromTypeEnum.IMPORT_PRODUCT && (
          <TransferSkuNotImport />
        )}
    </>
  )
}

export default index
