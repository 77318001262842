import _ from 'lodash'
import { store } from '../../../../App'
import {
  ABBREVIATION_DISTRIBUTION,
  COURSE_FOR,
} from '../../../../constants/reportCourseSetting/reportCourseSetting'

export const getColorByDistribution = (abbreviationDistribution) => {
  const { courseForList } = store.getState().reportCourseSettingDashboard

  const keyDistribution = _.findKey(
    ABBREVIATION_DISTRIBUTION,
    (dis) => dis === abbreviationDistribution,
  )

  const result = _.find(
    courseForList,
    (course) => course.courseFor === keyDistribution,
  )

  const courseForOther = _.find(
    courseForList,
    (course) => course.courseFor === COURSE_FOR.OTHER,
  )

  return {
    color: result?.color ? result?.color : courseForOther?.color,
    bgColor: result?.bgColor ? result?.bgColor : courseForOther?.bgColor,
  }
}
