import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { vendorTypeEnum } from '../enum/vendorTypeEnum'
import dayjs from 'dayjs'

export const postBodyMutation = (data) => {
  const status = _.get(data, 'status', enumStatus.ACTIVE)
  let startDate = formatDate(_.get(data, 'startDate', null))
  let endDate = formatDate(_.get(data, 'endDate', null))
  let renewDate = formatDate(_.get(data, 'renewDate', null))

  return {
    uuid: _.get(data, 'uuid', undefined),
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    station: _.get(data, 'station.value', undefined),
    status: status && status != '' ? status : enumStatus.INACTIVE,
    annotation: _.get(data, 'annotation', '')  ?? '',
    vendorType: _.get(
      data,
      'vendorType.value',
      vendorTypeEnum.VENDOR,
    ).toUpperCase(),
    qtyType: _.get(data, 'qtyType', '') ?? '',
    descriptionType: _.get(data, 'descriptionType', '') ?? '',
    serviceDescription: _.get(data, 'serviceDescription', '') ?? '',
    startDate: startDate,
    endDate: endDate,
    renewDate: renewDate,
    checkEndDate: _.get(data, 'checkEndDate', false),
    insideContacts: _.get(data, 'insideContacts', [{ name: '' }]),
    outsideContacts: _.get(data, 'outsideContacts', []),
  }
}


const formatDate = (value) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value
  if (dateValue && dateValue !='')
    dateValue = dayjs(new Date(dateValue)).format(format)
  else 
    dateValue = null
  return dateValue
}