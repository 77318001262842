import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionDelete = validatePermission({
    user: user,
    moduleType: 'MASTER_DATA',
    permission: ['DELETE'],
  })

  return [
    {
      id: 'status',
      label: '',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'groupCode',
      label: 'Group Code',
      disablePadding: false,
    },
    {
      id: 'costCenter',
      label: 'Cost Center ID',
      disablePadding: false,
    },
    {
      id: 'descriptionName',
      label: 'Cost Center Name',
      disablePadding: false,
    },

    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
      editPath: `${crudConfig.modulePath}/form`,
      hideDelete: hasPermissionDelete ? false : true,
    },
  ]
}
