import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../components/Breadcrumbs'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import { validationSchema } from './FormSchema'
import { acquiredSkill } from '../../utils/apiPath'
import { callAPI } from '../../utils/lib'

const AcquiredSkill = ({ isEditForm }) => {
  const titleText = isEditForm ? 'แก้ไขระดับหลักสูตร' : 'เพิ่มระดับหลักสูตร'

  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ระดับหลักสูตร',
      link: '/master-data/acquired-skill',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
  const { id: uuid } = useParams()
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [displayTitle, setDisplayTitle] = useState('')
  const [initialValues, setInitialValues] = useState({
    id: '',
    acquiredSkill: '',
    status: true,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitAcquiredSkill(
            values,
            uuid,
            isEditForm,
            setDialog,
            setIsPageLoading
          ),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  useEffect(() => {
    if (isEditForm) {
      setIsPageLoading(true)
      fetchAcquiredSkillById(
        uuid,
        formik,
        setDialog,
        setIsPageLoading,
        setInitialValues,
        setDisplayTitle
      )
    }
  }, [isEditForm])

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
          isLoading={isPageLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default AcquiredSkill

export const submitAcquiredSkill = async (
  values,
  uuid,
  isEditForm,
  setDialog,
  setIsPageLoading
) => {
  setDialog({
    open: true,
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'
  const body = isEditForm
    ? {
        ...values,
        uuid: uuid,
        acquiredSkill: values.acquiredSkill,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }
    : {
        acquiredSkill: values.acquiredSkill,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }

  await callAPI({
    url: acquiredSkill,
    method: methodType,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res.uuid
      setDialog({
        open: true,
        isLoading: false,
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        variant: 'success',
        onConfirmClick: () => {
          window.location.href = '/master-data/acquired-skill/' + uuidLink
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const fetchAcquiredSkillById = async (
  uuid,
  formik,
  setDialog,
  setIsPageLoading,
  setInitialValues,
  setDisplayTitle
) => {
  await callAPI({
    url: acquiredSkill + `/${uuid}`,
    method: 'GET',
    onSuccess: (res) => {
      const acquiredSkills = _.get(res, 'acquiredSkill', '')
      const status = _.get(res, 'status', 'INACTIVE')
      const id = _.get(res, 'id', 0)
      setInitialValues({
        ...res,
        id: id,
        acquiredSkill: acquiredSkills,
        status: status.toString().toUpperCase() === 'ACTIVE' || false,
      })
      setDisplayTitle(acquiredSkills)
      formik.setFieldValue('id', id)
      formik.setFieldValue('acquiredSkill', acquiredSkills)
      formik.setFieldValue(
        'status',
        status.toString().toUpperCase() === 'ACTIVE' || false
      )
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}
