import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledHeadLabel } from './Styled'
import { openAssetDrawer } from '../../handler/openHistoryDrawer'
import { ViewRow } from '../../../../../../components/CRUD/components/StyledViewBox'
import { store } from '../../../../../../App'
import Table from './Table'
import _ from 'lodash'

const index = ({ open }) => {
  const { assetDrawerInfo, isRefreshTable } = store.getState().crud
  let product = _.get(assetDrawerInfo, 'product', '')
  if (assetDrawerInfo?.productWarehouse) {
    product = _.get(assetDrawerInfo, 'productWarehouse.product', '')
  }
  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={open}
        onClose={() => openAssetDrawer(false)}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: 'auto' },
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">รายการรหัสทรัพย์สินในคลัง</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openAssetDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              title="ชื่อคลังทรัพย์สิน"
              value={_.get(assetDrawerInfo, 'warehouseName', '-')}
              required
            />
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              title="ชื่อทรัพย์สิน"
              value={_.get(product, 'productName', '-')}
              boxSx={{ pb: 2 }}
            />
            <Divider />
          </StyledHeadLabel>
          <Box mx={3} maxWidth="1200px">
            <Table
              assetInfo={assetDrawerInfo}
              isRefreshTable={isRefreshTable}
            />
          </Box>
          {/* <LoadingExaminer isLoading={isLoadingDrawer} /> */}
        </Box>
      </Drawer>
    </>
  )
}

export default index
