import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import BackToMainBtn from '../BackToMainBtn'
import SubmitBtn from '../SubmitBtn'

const ButtonForAll = ({ isModule }) => {
  const { isSubmit, testing } = useSelector(
    (state) => ({
      isSubmit: state.eTestingPreview.isSubmit,
      testing: state.eTestingPreview.testing,
    }),
    shallowEqual
  )

  if (isSubmit) return <BackToMainBtn isModule={isModule} />

  return <SubmitBtn testing={testing} isDisplayAll />
}

export default ButtonForAll
