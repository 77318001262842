import React from 'react'
import _ from 'lodash'
import { Switch, Typography } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { handleChange } from '../../event'
import { StyledColumn, StyledRow } from '../Styled'

const Index = () => {
  const dispatch = useDispatch()
  const { status } = useSelector(
    (state) => state.masterDataMeetingRoomForm.information,
    shallowEqual
  )
  const isActive = status === 'ACTIVE'

  return (
    <StyledColumn sx={{ width: '100%' }}>
      <StyledRow sx={{ gap: 0 }}>
        <Typography variant="body2" color="text.secondary">
          สถานะ
        </Typography>
      </StyledRow>

      <StyledRow sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2">การเปิดใช้งาน</Typography>
        <Switch
          inputProps={{
            'data-testid': 'switch-enable',
          }}
          checked={isActive}
          onChange={(e) => {
            const checked = _.get(e, 'target.checked', '')
            const newStatus = checked ? 'ACTIVE' : 'INACTIVE'
            dispatch(
              handleChange({
                key: 'status',
                value: newStatus,
              })
            )
          }}
        />
      </StyledRow>
    </StyledColumn>
  )
}

export default Index
