export const breadcrumbList = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'คลาสทั้งหมด', link: '/', pointer: false },
]

export const breadcrumbDetail = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'คลาสทั้งหมด', link: '/manage-class', pointer: true },
  { title: 'รายละเอียดคลาส', link: '/', pointer: false },
]

export const breadcrumbForm = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'คลาสทั้งหมด', link: '/manage-class', pointer: true },
  { title: 'ตั้งค่า', link: '/', pointer: false },
]

export const breadcrumbEdit = (uuid) => [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'คลาสทั้งหมด', link: '/manage-class', pointer: true },
  {
    title: 'รายละเอียดคลาส',
    link: `/manage-class/detail/${uuid}`,
    pointer: true,
  },
  { title: 'ตั้งค่า', link: '/', pointer: false },
]

export const breadcrumbOicList = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'ผลสอบใบอนุญาตตัวแทน', link: '/', pointer: false },
]

export const breadcrumbOicView = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'ผลสอบใบอนุญาตตัวแทน', link: '/manage-class/oic', pointer: true },
  { title: 'รายละเอียดผลสอบ', link: '/', pointer: false },
]

export const breadcrumbCheckExam = [
  { title: 'จัดการคลาส', link: '/', pointer: false },
  { title: 'ตรวจข้อสอบ', link: '/manage-class/check-exam/list', pointer: true },
  { title: 'แบบทดสอบ', link: '/', pointer: false },
]
