import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import EditSharp from '@mui/icons-material/EditSharp'

import HistoryDrawer from '../../HistoryDrawer'
import Breadcrumbs from '../../../../components/Breadcrumbs'

const listBreadcrumbEdit = [
  { title: 'Master Data', link: '/', pointer: false },
  {
    title: 'ใบอนุญาต',
    link: '/master-data/license',
    pointer: true,
  },
  { title: 'รายละเอียดใบอนุญาต', link: '/', pointer: false },
]

const Index = ({ uuid }) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { description, status } = useSelector(
    (state) => ({
      description:
        state.masterDataLicenseForm.initialState.information.description,
      status: state.masterDataLicenseForm.initialState.information.status,
    }),
    shallowEqual
  )
  const isDeleted = status === 'DELETED'

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h4">
          {`รายละเอียดใบอนุญาต : ${description}`}
        </Typography>
        <Breadcrumbs menuList={listBreadcrumbEdit} />
      </Box>
      <Box sx={{ gap: 3, display: 'flex' }}>
        <Button
          data-testid="btn-history"
          startIcon={<HistoryTwoTone color="primary.main" />}
          variant="outlined"
          size="m"
          onClick={() => setIsOpen(true)}
        >
          ประวัติการแก้ไข
        </Button>
        {!isDeleted && (
          <Button
            data-testid="btn-edit"
            startIcon={<EditSharp color="primary.contrast" />}
            variant="contained"
            size="m"
            onClick={() => history.push(`/master-data/license/edit/${uuid}`)}
          >
            แก้ไข
          </Button>
        )}
      </Box>

      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </Box>
  )
}

export default Index
