import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import { StyledCountDown, StyledShowCountdown } from '../../Styled'
import { StyledRow } from '../../../../Dashboard/Styled'
import { shallowEqual, useSelector } from 'react-redux'

const ShowCountdown = (props) => {
  const { number, unit, isLastIndex, isMobile } = props

  return (
    <StyledShowCountdown>
      <StyledRow>
        <Typography variant={isMobile ? 'h5' : 'h4'}>{number}</Typography>
        {!isLastIndex && (
          <Typography variant={isMobile ? 'h5' : 'h4'}>:</Typography>
        )}
      </StyledRow>
      <Typography variant={isMobile ? 'body2' : 'body1'}>{unit}</Typography>
    </StyledShowCountdown>
  )
}

export const CountDownTimerSection = ({ countdownTimer }) => {
  const {
    isMobile,
    qrEnrollProps: { expiryDate, expiryTime },
  } = useSelector((state) => state.manageClassDetail, shallowEqual)

  return (
    <Box
      sx={{
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledCountDown>
        {countdownTimer.map((cd, idx) => (
          <ShowCountdown
            key={idx}
            number={cd.number.toString().padStart(2, '0')}
            unit={cd.unit}
            isLastIndex={idx === countdownTimer.length - 1}
            isMobile={isMobile}
          />
        ))}
      </StyledCountDown>
      <Typography variant={isMobile ? 'body2' : 'body1'}>
        QR Code ใช้งานได้ถึง {dayjs(expiryDate).format('DD/MM/YYYY')}{' '}
        {expiryTime}
      </Typography>
    </Box>
  )
}
