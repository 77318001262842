import { eTestingDownload, eTestingFilter } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'ETESTING_DASHBOARD',
  moduleSlug: 'e-testing-dashboard',
  moduleTitleFull: 'Dashboard',
  modulesTitleShort: 'Dashboard',
  modulePath: '/e-testing/dashboard',
  filterPath: eTestingFilter,
  headerCells: headerCells,
  downloadUrl: eTestingDownload,
}
