import { Box, Typography } from '@mui/material'
import { StyledFormCard } from '../../Styled'
import CustomStatusSwitch from '../../../../../../components/CRUD/components/CustomStatusSwitch'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CustomDateInput } from '../../../../../../components/CRUD/components/CustomDateInput'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { fetchLevelOfLearner } from '../../handler/fetchLevelOfLearner'
import { CustomMultiAutoComplete } from '../../../../../../components/CRUD/components/CustomMultiAutoComplete'
import { CustomTextArea } from '../../../../../../components/CRUD/components/CustomTextArea'
import { setFieldValue } from '../../../../../../redux/slices/crud'

const SettingTab = () => {
  const dispatch = useDispatch()
  const { formData } = useSelector(
    (state) => ({
      formData: state.crud.formData,
    }),
    shallowEqual,
  )

  return (
    <>
      <StyledFormCard sx={{ gap: 3 }}>
        <Typography variant="h6">การเผยแพร่</Typography>
        <CustomStatusSwitch
          labelText=""
          labelName="กำหนดวันสิ้นสุด"
          fieldName="isEndPublishDate"
          boxSx={{ width: 'fit-content' }}
          handleExtraChange={(e) => {
            if (!e.target.checked) {
              dispatch(
                setFieldValue({ key: 'formData.endPublishDate', value: null }),
              )
            }
          }}
        />
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <CustomDateInput
            disablePast
            required
            tab-name="tab"
            content-tab="setting"
            labelName="วันเริ่มต้น"
            fieldName="startPublishDate"
            placeholder="กรุณาเลือกวันที่"
          />
          <CustomDateInput
            disablePast
            required={formData.isEndPublishDate === 'ACTIVE'}
            tab-name="tab"
            content-tab="setting"
            labelName="วันสิ้นสุด"
            fieldName="endPublishDate"
            disabled={formData.isEndPublishDate === 'INACTIVE'}
          />
        </Box>
      </StyledFormCard>
      <StyledFormCard sx={{ gap: 3 }}>
        <Typography variant="h6">การตั้งค่าบทความ</Typography>
        <CustomDropdown
          disableCloseOnSelect={false}
          tab-name="tab"
          content-tab="setting"
          multiple
          fieldName="assignees"
          labelName="ผู้ร่วมจัดทำ"
        />
        <CustomDropdown
          disableCloseOnSelect={false}
          tab-name="tab"
          content-tab="setting"
          required
          multiple
          addAllOption
          fieldName="distributionCh"
          labelName="ช่องทางการจำหน่าย (Distribution Channel)"
          handleExtraChange={(e, value) => {
            fetchLevelOfLearner(value)
          }}
        />
        <CustomDropdown
          disableCloseOnSelect={false}
          tab-name="tab"
          content-tab="setting"
          required
          multiple
          addAllOption
          fieldName="levelOfLearner"
          labelName="ระดับของผู้เรียน"
        />
        <CustomMultiAutoComplete
          disableCloseOnSelect={false}
          required
          multiple
          tab-name="tab"
          content-tab="setting"
          fieldName="eContentTag"
          masterDataName="eContentTag"
          labelName="Tag"
        />
        <CustomTextArea labelName="หมายเหตุ" fieldName="remark" />
      </StyledFormCard>
    </>
  )
}
export default SettingTab
