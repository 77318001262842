import appHistory from '../../../../appHistory'
import { crudConfig } from '../../crudConfig'
import { Add, UploadFileRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { handleOpenImportTemplateDialog } from '../handler/handleOpenImportTemplateDialog'
export function ActionComponent() {
  return (
    <Box display="flex" gap={3}>
      <Button
        startIcon={<UploadFileRounded />}
        variant="outlined"
        onClick={() => handleOpenImportTemplateDialog()}
      >
        อัปโหลดไฟล์
      </Button>
      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => appHistory.push(`${crudConfig.modulePath}/form`)}
      >
        สร้าง Budget
      </Button>
    </Box>
  )
}
