import _ from 'lodash'

export const findExpand = (expands, date) => {
  if (!expands.length) return false
  const result = expands.find((item) => item.date === date)
  return _.get(result, 'expand', false)
}

export const handleExpand = (expands, setIsExpand, date) => {
  if (!expands.length) return false
  const result = expands.map((item) => {
    const status = item.date === date ? !item.expand : item.expand
    return {
      ...item,
      expand: status,
    }
  })
  setIsExpand(result)
}

export const expandState = (classroomDetail) => {
  if (!classroomDetail) return []
  let result = []
  for (let key in classroomDetail) {
    result.push({ date: key, expand: false })
  }
  return result
}
