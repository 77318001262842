import { Button, Divider, Typography } from '@mui/material'
import { StyledColumn, StyledCard } from '../../Styled'
import { shallowEqual, useSelector } from 'react-redux'
import useTheme from '@mui/system/useTheme'
import { scrollToMonthlyPlanTicketList } from './event'
import { useDispatch } from 'react-redux'

export const MonthlyPlanCard = ({ bookingRoomRef }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { monthlyPlanInProgress } = useSelector(
    (state) => ({
      monthlyPlanInProgress: state.roomManagement.data.monthlyPlanInProgress,
    }),
    shallowEqual,
  )

  return (
    <StyledCard
      sx={{
        pt: 1,
      }}
    >
      <Typography variant="h6">Monthly Plan</Typography>
      <StyledColumn sx={{ gap: 1 }}>
        <Typography variant="body1" color="text.gray">
          Ticket ที่มาจาก Academy Activity และ Activity Support
        </Typography>
        <Divider />
        <Button
          sx={{
            py: 0,
            borderRadius: 22,
            color: theme.palette?.warning?.main,
            backgroundColor: theme.palette?.warning?.outlinedHoverBackground,
            '&:hover': {
              backgroundColor: theme.palette?.warning?.outlinedHoverBackground,
            },
          }}
          variant="contained"
          onClick={() => {
            dispatch(scrollToMonthlyPlanTicketList(bookingRoomRef))
          }}
        >
          รอดำเนินการ {monthlyPlanInProgress} รายการ
        </Button>
      </StyledColumn>
    </StyledCard>
  )
}
