import React from 'react'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { BoxContent, BoxContainer, BoxColor, BoxLabel } from './Styled'

const Index = ({ list }) => {
  return (
    <BoxColor>
      <BoxContainer>
        {list.map((item, index) => {
          const isChecked = item.slice(8).split('\n')

          return (
            <>
              <BoxContent key={index}>
                <BoxLabel isFirst={index === 0}>
                  {isChecked.length > 1 ? (
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: 'pre' }}
                      dangerouslySetInnerHTML={{ __html: `${item}` }}
                    />
                  ) : (
                    <Typography variant="body2">{item}</Typography>
                  )}
                </BoxLabel>
                {list.length !== index + 1 && (
                  <Divider sx={{ ml: 2, mt: 1, zIndex: 100 }} />
                )}
              </BoxContent>
            </>
          )
        })}
      </BoxContainer>
    </BoxColor>
  )
}

export default Index
