import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from './Styled'
import { breadcrumbCsUser } from './model'
import FilterDrawer from './FilterDrawer'

const CsUserProfile = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)

  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        setInitial,
        'CS'
      )
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList(
          'fetch',
          table,
          page,
          status,
          filterProp,
          searchText,
          setInitial,
          'CS'
        )
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box>
            <Typography variant="h4">
              ข้อมูล CS User (CS User Profile)
            </Typography>
            <Breadcrumbs menuList={breadcrumbCsUser(uuid)} />
          </Box>
        </StyledHeader>

        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer open={isFilterDrawer} table={table} type="CS" />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default CsUserProfile
