import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { StyledButton } from '../Styled'
import DoughnutChart from '../../../../components/Chart/DoughnutChart'
import { Column, Row } from 'src/components/Layout/Styled'
import RoomRowData from './RoomRowData'
import { toggleRoomRequestDrawer } from '../handler/handleClickOpenDrawer'

const RoomRequestOverview = () => {
  const { reportByType } = useSelector(
    (state) => ({
      reportByType: state.crud?.roomManagementDashboard?.overview?.reportByType,
    }),
    shallowEqual,
  )

  return (
    <Grid item xs={6}>
      <Card sx={{ padding: '8px 16px 16px 16px', height: 284 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h6">ประเภทการจองห้องอบรม</Typography>
          <StyledButton
            color="text.lightGray"
            size="small"
            background="#4976BA14"
            onClick={() => toggleRoomRequestDrawer(true)}
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        </Box>
        <Box display="flex" gap={4} mt={1.5}>
          <DoughnutChart
            dataSet={{
              data: [
                reportByType?.monthlyPlanCount,
                reportByType?.manageClassCount,
                reportByType?.eBookingCount,
              ],
              backgroundColor: ['#4976BA', '#E99E7D', '#32A287'],
            }}
            height={176}
            label="ทั้งหมด"
            value={reportByType?.total}
            eleId="RoomRequestChart"
            cutout={60}
          />
          <Column>
            <Row pl={3} pr={1} sx={{ justifyContent: 'space-between' }}>
              <Row>
                <Typography
                  variant="body2"
                  color="text.lightGray"
                  sx={{ minWidth: 163 }}
                >
                  ชื่อประเภท
                </Typography>
                <Typography variant="body2" color="text.lightGray">
                  จำนวน
                </Typography>
              </Row>
              <Typography variant="body2" color="text.lightGray">
                จำนวน
              </Typography>
            </Row>
            <RoomRowData
              color="#BED0ED"
              dotColor="#4976BA"
              label="Monthly Plan"
              middleData={`${reportByType?.monthlyPlanCount} ครั้ง`}
              data={`${reportByType?.monthlyPlanAttendee} คน`}
            />
            <RoomRowData
              color="#FFDACA"
              dotColor="#E99E7D"
              label="Manage Class"
              middleData={`${reportByType?.manageClassCount} ครั้ง`}
              data={`${reportByType?.manageClassAttendee} คน`}
            />
            <RoomRowData
              color="#EBF6F3"
              dotColor="#32A287"
              label="E-Booking"
              middleData={`${reportByType?.eBookingCount} ครั้ง`}
              data={`${reportByType?.eBookingAttendee} คน`}
            />
          </Column>
        </Box>
        <Typography
          variant="body2"
          sx={{ width: 'fit-content', mt: 0.75, ml: 'auto', mr: 2 }}
        >
          Monthly Plan คือ Ticket ที่มาจาก Academy Activity และ Activity Support
        </Typography>
      </Card>
    </Grid>
  )
}

export default RoomRequestOverview
