import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxSelect = styled(Box)(({ theme, roundUnlimit }) => ({
  display: 'flex',
  width: roundUnlimit ? '220px' : '320px',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const BoxInput = styled(Box)(({ theme }) => ({
  width: '145px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
