import axios from 'axios'

export const downloadFile = (uuid, fileName) => {
  axios({
    url: `file${uuid}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const split = uuid.split('.')
      const fileNameSplit = fileName.split('.')
      const href = URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `${fileNameSplit[0]}.${split[split.length - 1]}`
      )
      document.body.appendChild(link)
      link.click()
    })
    .catch((e) => {
      console.log('catch downloadFile', e)
    })
}

export const downloadAllFiles = async (data) => {
  const filteredData = await data.filter((item) => item.type === 'UploadFile')
  for (const element of filteredData) {
    downloadFile(element.key, element.name)
  }
}
