import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import { StyledHeadLabel } from './../Styled'

export const Header = ({ onClose }) => {
  return (
    <StyledHeadLabel>
      <Box id="header">
        <Typography variant="h5">QR Code ลงทะเบียน</Typography>
        <IconButton
          data-testid="btn-close-drawer"
          color="primary"
          component="span"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Divider />
    </StyledHeadLabel>
  )
}
