import React, { useEffect, useMemo } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import localforage from 'localforage'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Launch from '@mui/icons-material/Launch'
import RefreshTwoTone from '@mui/icons-material/RefreshTwoTone'
import Autocomplete from '../../../../../../components/Input/Autocomplete'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { StyledFormCard, StyledFormColumn } from '../../../../Styled'
import { getStateValue, handleChange } from '../../events'
import StatusSwitch from '../StatusSwitch'
import { TextLabel } from '..'
import { handleAutocompleteDefault } from '../events'
import {
  fetchEEvaluationList,
  handleInitialFetch,
  handleRadioChange,
} from './events'
import { E_EVALUATION_TYPE } from './model'

const EEvaluation = () => {
  const { id: moduleUuid } = useParams()
  const typeField = _.camelCase(MODULE_TYPE.E_EVALUATION)
  const keyType = `${typeField}.type`
  const keyUuid = `${typeField}.uuid`
  const dispatch = useDispatch()
  const { isOptionLoading, option, type, uuid, onGoing } = useSelector(
    (state) => ({
      isOptionLoading: state.eLearningModuleForm.isOptionLoading,
      option: state.eLearningModuleForm.option,
      type: getStateValue(state, keyType),
      uuid: getStateValue(state, keyUuid),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual
  )
  const name = useMemo(
    () => handleAutocompleteDefault(option, uuid),
    [option, uuid]
  )
  const { errors } = useFormikContext()

  useEffect(() => {
    dispatch(handleInitialFetch(moduleUuid, keyType))
  }, [])

  return (
    <StyledFormCard isLoading={isOptionLoading}>
      <Typography variant="h6">E-Evaluation</Typography>
      <StyledFormColumn>
        <FormControl>
          <TextLabel label="เลือกประเภทแบบประเมิน" />
          <RadioGroup
            row
            name={keyType}
            sx={{ ml: 1.5, gap: 5 }}
            value={type}
            onChange={(e) => dispatch(handleRadioChange(e, typeField))}
          >
            <FormControlLabel
              value={E_EVALUATION_TYPE.CLASS}
              control={<Radio />}
              label="Class"
              disabled={onGoing}
            />
            <FormControlLabel
              value={E_EVALUATION_TYPE.STANDALONE}
              control={<Radio />}
              label="Standalone"
              disabled={onGoing}
            />
          </RadioGroup>
        </FormControl>
        {!_.isEmpty(type) && (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'start' }}>
            <Autocomplete
              name={keyUuid}
              labelText="แบบประเมิน"
              options={[...option]}
              errorSx={{ mb: 0 }}
              renderInput={(params) => (
                <TextField
                  placeholder="กรุณาเลือกแบบประเมิน"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-e-evaluation',
                  }}
                />
              )}
              textError={_.get(errors, keyUuid, '')}
              showTextError={_.get(errors, keyUuid, '')}
              defaultValue={name}
              handleChange={(e) => dispatch(handleChange(keyUuid, e.target.id))}
              disabled={onGoing}
              required
            />
            <IconButton
              sx={{ mt: 3 }}
              onClick={() => dispatch(fetchEEvaluationList(type))}
            >
              <RefreshTwoTone color="primary" />
            </IconButton>
            <Button
              variant="outlined"
              startIcon={<Launch />}
              sx={{ width: 173, minWidth: 173, mt: 3 }}
              onClick={() => {
                localforage.removeItem('persist:root')
                window.open(`/e-evaluation/form/${_.kebabCase(type)}`, '_blank')
              }}
            >
              สร้างแบบประเมิน
            </Button>
          </Box>
        )}
      </StyledFormColumn>

      <Divider />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default EEvaluation
