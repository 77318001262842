import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { TableHeader, RequestType, TableBody, TableBodyTypo } from './Styled'
import Truncate from '../../../../../components/redux/Table/Truncate'

const Table = () => {
  const { meetingRoomList } = useSelector(
    (state) => ({
      meetingRoomList:
        state.crud.roomManagementDashboard?.roomRequestDrawer?.meetingRoomList,
    }),
    shallowEqual,
  )

  return (
    <>
      <Grid container py={1} pr={2}>
        <HeaderCell xs={0.75} data="ลำดับ" />
        <Grid item xs={3} sx={{ alignContent: 'center' }}>
          <Typography sx={{ fontWeight: 500 }} variant="body2">
            ชื่อห้อง
          </Typography>
        </Grid>
        <TableHeader item xs={1.75}>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              height: '75%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '5px solid #DBE4F199',
              fontWeight: 500,
            }}
          >
            ประเภทการจอง
          </Typography>
        </TableHeader>
        <Grid container item xs={6.5}>
          <HeaderCell data="Completed" />
          <HeaderCell data="จำนวนผู้ใช้ห้อง (Completed)" />
          <HeaderCell data="Cancelled" />
          <HeaderCell data="จำนวนผู้ใช้ห้อง (Cancelled)" />
          <HeaderCell data="รวมทุกสถานะ" />
          <HeaderCell data="จำนวนผู้ใช้ห้อง (รวมทุกสถานะ)" />
        </Grid>
      </Grid>
      <Divider />
      <Box
        sx={{
          height: 'calc(100% - 215px)',
          overflowY: meetingRoomList?.length > 0 ? 'scroll' : 'auto',
        }}
      >
        {meetingRoomList?.length > 0 ? (
          meetingRoomList?.map((ele, ind) => {
            return (
              <Grid
                container
                key={ind}
                sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
              >
                <TableHeader item xs={0.75}>
                  <Typography variant="body2">{+ind + 1}</Typography>
                </TableHeader>
                <TableBody item xs={3}>
                  <Truncate>
                    <Typography variant="body2">{ele.name}</Typography>
                  </Truncate>
                </TableBody>
                <Grid item xs={1.75}>
                  <RequestTypeCell bgColor="#D4E5FF" data="Monthly Plan" />
                  <RequestTypeCell bgColor="#FFDACA" data="Manage Class" />
                  <RequestTypeCell bgColor="#EBF6F3" data="E-Booking" />
                </Grid>
                <Grid container item xs={6.5}>
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.completedCount}
                    mcData={ele.MANAGE_CLASS.completedCount}
                    ebData={ele.E_BOOKING.completedCount}
                  />
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.completedAttendee}
                    mcData={ele.MANAGE_CLASS.completedAttendee}
                    ebData={ele.E_BOOKING.completedAttendee}
                  />
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.cancelledCount}
                    mcData={ele.MANAGE_CLASS.cancelledCount}
                    ebData={ele.E_BOOKING.cancelledCount}
                  />
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.cancelledAttendee}
                    mcData={ele.MANAGE_CLASS.cancelledAttendee}
                    ebData={ele.E_BOOKING.cancelledAttendee}
                  />
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.totalCount}
                    mcData={ele.MANAGE_CLASS.totalCount}
                    ebData={ele.E_BOOKING.totalCount}
                  />
                  <BodyCell
                    mpData={ele.MONTHLY_PLAN.totalAttendee}
                    mcData={ele.MANAGE_CLASS.totalAttendee}
                    ebData={ele.E_BOOKING.totalAttendee}
                  />
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ textAlign: 'center' }}
            my={20}
          >
            ไม่พบข้อมูล
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Table

const HeaderCell = ({ data, xs = 2 }) => {
  return (
    <TableHeader item xs={xs}>
      {data.split(/ /g).map((ele, ind) => (
        <Typography sx={{ fontWeight: 500 }} variant="body2" key={ind}>
          {ele}
        </Typography>
      ))}
    </TableHeader>
  )
}

const RequestTypeCell = ({ data, bgColor }) => {
  return (
    <RequestType variant="body2" background={bgColor}>
      {data}
    </RequestType>
  )
}

const BodyCell = ({ mpData, mcData, ebData }) => {
  return (
    <TableBody item xs={2}>
      <TableBodyTypo variant="body2" background="#D4E5FF">
        {mpData}
      </TableBodyTypo>
      <TableBodyTypo variant="body2" background="#FFDACA">
        {mcData}
      </TableBodyTypo>
      <TableBodyTypo variant="body2" background="#EBF6F3">
        {ebData}
      </TableBodyTypo>
    </TableBody>
  )
}
