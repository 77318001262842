import React from 'react'
import { StyledFormCard } from '../Styled'
import { CustomRadioInput } from '../../../../../components/CRUD/components/CustomRadioInput'
import { Typography } from '@mui/material'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { useDispatch } from 'react-redux'

const ContentType = () => {
  const dispatch = useDispatch()
  return (
    <StyledFormCard>
      <Typography variant="h6">ประเภทเนื้อหา</Typography>
      <CustomRadioInput
        required={false}
        labelName=""
        fieldName="contentType"
        isRow
        onChange={() => {
          dispatch(setFieldValue({ key: 'formData.contents', value: [] }))
        }}
      />
    </StyledFormCard>
  )
}
export default ContentType
