import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { StyledLabelPercent } from '../styled'

export const UploadLoadingCircular = ({ percent }) => {
  percent = percent || 0
  return (
    <Box sx={{ position: 'relative', width: 90, height: 90 }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={90}
        thickness={3}
        sx={{ color: 'rgba(229, 229, 229, 1)' }}
      />
      <CircularProgress
        variant="determinate"
        value={percent}
        size={90}
        thickness={3}
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
      />
      <StyledLabelPercent variant="h6">{percent.toFixed()}%</StyledLabelPercent>
    </Box>
  )
}
