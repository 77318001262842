import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { FORM_TAB, FORM_TAB_TH } from './model'
import { StyledTab } from './Styled'
import { useDispatch } from 'react-redux'
import { handleChangeTab } from '../../handler/handleChangeTab'

const TabMenu = ({ tab }) => {
  const dispatch = useDispatch()

  return (
    <StyledTab
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, value) => dispatch(handleChangeTab(value))}
    >
      <Tab
        value={FORM_TAB.content}
        label={
          <Typography variant="body1b" color="primary">
            {FORM_TAB_TH.content}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.setting}
        label={
          <Typography variant="body1b" color="primary">
            {FORM_TAB_TH.setting}
          </Typography>
        }
      />
    </StyledTab>
  )
}
export default TabMenu
