import React from 'react'
import { openDialog, closeDialog } from '../../../../../../redux/slices/dialog'
import {
  setIsEnable,
  startLoadingResetQR,
  stopLoadingResetQR,
} from '../../../../../../redux/slices/manageClassDetail'
import { CloseBeforeExpiryDateDialog } from '../DialogContents'
import _ from 'lodash'
import { hasExpiry, saveQRForEnroll } from '../events'
import { Typography } from '@mui/material'

const qrPropsDefault = {
  expiryType: null,
  expiryDate: null,
  expiryTime: null,
  isEnable: false,
  qrExpired: false,
}

export const handleEnableQRCode = (props) => (dispatch) => {
  const { enable, time, uuid, link, expiryType } = props
  if (!enable) {
    dispatch(
      openDialog({
        title: 'ปิดการใช้งาน QR Code',
        message:
          !_.isNull(link) && hasExpiry(expiryType) ? (
            <CloseBeforeExpiryDateDialog time={time} />
          ) : (
            <Typography>คุณต้องการปิดการใช้งานหรือไม่</Typography>
          ),
        handleConfirm: () => {
          dispatch(resetQrCode(uuid))
          dispatch(setIsEnable(enable))
          dispatch(closeDialog())
        },
        handleCancel: () => {
          dispatch(closeDialog())
        },
        agreeText: 'ใช่',
        disagreeText: 'ไม่ใช่',
      })
    )
  } else {
    dispatch(setIsEnable(enable))
  }
}

export const resetQrCode = (uuid) => async (dispatch) => {
  dispatch(startLoadingResetQR())
  await dispatch(saveQRForEnroll({ ...qrPropsDefault, uuid: uuid }))
  dispatch(stopLoadingResetQR())
}
