import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { StyledLayoutBoxAll } from '../../../../modules/ECertification/Background/Form/UploadBackground/Styled'
import { LINE_OPTION } from '../../../../../src/constants/eCertification'
import Typography from '@mui/material/Typography'
import { StyledGroupLayoutBox, StyledLayoutBox } from './Styled'

export const LayoutOption = ({
  setSelectLayout,
  isSelectLayout,
  layouts,
  width,
}) => {
  return (
    <StyledGroupLayoutBox container height={width / 4}>
      {_.map(layouts ?? [], (item) => (
        <StyledLayoutBoxAll item key={`layout_${item.uuid}`}>
          <StyledLayoutBox
            width={width}
            url={`${window.__env__.REACT_APP_API_URL}/file${item.eCertificationBackground?.imageKey}`}
            onClick={() => {
              if (isSelectLayout === item.uuid) {
                setSelectLayout(0)
              } else {
                setSelectLayout(item.uuid)
              }
            }}
            active={isSelectLayout === item.uuid ? 1 : undefined}
          >
            {_.map(item?.layout?.layout ?? [], (l, key) => {
              if (l.type === LINE_OPTION.MULTILINE) {
                return (
                  <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
                    {_.map(l.layout, (li, n) => (
                      <Typography
                        key={`layout_box_n_${n}`}
                        sx={{ ...l.style, ...li.style }}
                      >
                        {li.text}
                      </Typography>
                    ))}
                  </div>
                )
              }
              return (
                <Typography key={`layout_list_${key}-${item.id}`} sx={l.style}>
                  {l.text}
                </Typography>
              )
            })}
          </StyledLayoutBox>
          <Typography variant="caption" color="text.secondary">
            {item.name}
          </Typography>
        </StyledLayoutBoxAll>
      ))}
    </StyledGroupLayoutBox>
  )
}

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}
