import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'

export const handleShowSummaryOther = async () => {
  const { showSummaryOther } = store.getState().crud.formData
  store.dispatch(
    setFieldValue({
      key: 'formData.showSummaryOther',
      value: !showSummaryOther,
    }),
  )
}
