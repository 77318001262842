import {
  loadingDialog,
  openDialog,
  openErrorDialog,
} from '../../../redux/slices/dialog'
import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageContent/station'
import {
  getManageContentStation,
  putManageContentStation,
} from '../../../services/manageContent'

export const fetchManageStation = (onEdit, setOnEdit) => async (dispatch) => {
  if (onEdit) return
  dispatch(startLoading())
  await dispatch(getManageContentStation())
  setOnEdit(true)
  dispatch(stopLoading())
}

export const handleSubmitForm = (value) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกและเผยแพร่',
      message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirm(value)),
    })
  )
}

export const handleConfirm = (value) => async (dispatch) => {
  dispatch(loadingDialog())
  const response = await dispatch(putManageContentStation(value))
  if (response?.type?.includes('fulfilled')) dispatch(handleSuccess())
}

export const handleSuccess = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      handleConfirm: () => window.location.reload(),
    })
  )
}

export const handleError = (error) => (dispatch) => {
  if (error) {
    dispatch(
      openErrorDialog({
        title: 'ผิดพลาด',
        message:
          'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากกรอกข้อมูลไม่ครบ หรือไม่ตรงตามเงื่อนไขที่กำหนด',
      })
    )
  }
}
