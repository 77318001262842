import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxSpaceBetween = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const BoxContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const BoxLeft = styled(Box)(() => ({
  width: 300,
  display: 'flex',
  alignItems: 'flex-start',
}))

export const BoxRight = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(1),
}))
