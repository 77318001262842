import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { shouldForwardProp } from '../../../../../modules/ELearning/Styled'

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
  gap: theme.spacing(2),
}))

export const StyledFormCard = styled(
  Card,
  shouldForwardProp,
)(({ theme, isLoading, isUploadLoading }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading || isUploadLoading ? 'none' : 'auto',
}))

export const StyledFormColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

export const StyledCardQuestion = styled(
  (props) => <Card {...props} />,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  minWidth: 250,
  marginTop: theme.spacing(3),
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  [theme.breakpoints.down('lg')]: {
    '&#view': { marginTop: theme.spacing(5) },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(#versionTable)': { marginTop: theme.spacing(5) },
  },
}))

export const Row = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))
export const Column = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))
