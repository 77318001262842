import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import Place from '@mui/icons-material/Place'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../../../../../redux/slices/dialog'
import {
  StyledButton,
  StyledContent,
  StyledContentImage,
  StyledDialogActions,
  StyledHeader,
} from './Styled'
import { onDownloadImage, onGoogleMaps } from './events'

const DialogContent = ({ title, img, map }) => {
  const dispatch = useDispatch()
  return (
    <Box>
      <StyledHeader>
        <Close
          sx={{ mx: 3, mt: 2, cursor: 'pointer' }}
          data-testid="btn-close"
          color="action"
          onClick={() => dispatch(closeDialog())}
        />
      </StyledHeader>
      <StyledContent>
        <Typography variant="h5" color="primary.main" sx={{ mt: 0 }}>
          {title}
        </Typography>
      </StyledContent>
      <StyledContentImage>
        <img src={img} style={{ width: '100%' }} />
      </StyledContentImage>
      <StyledDialogActions>
        <StyledButton
          data-testid="btn-disagree"
          size="l"
          variant="outlined"
          onClick={() => onDownloadImage(title, img)}
        >
          <Typography variant="button">บันทึกรูปภาพ</Typography>
        </StyledButton>
        <StyledButton
          data-testid="btn-agree"
          size="l"
          variant="contained"
          onClick={() => onGoogleMaps(map)}
          endIcon={<Place />}
        >
          <Typography variant="button">ขอเส้นทาง</Typography>
        </StyledButton>
      </StyledDialogActions>
    </Box>
  )
}

export default DialogContent
