import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import { StyledLikeIcon, StyledTypography } from './Styled'

const CollectionTitle = ({ course }) => {
  const isLg = isMediaWidthMoreThan('md')
  const isLike = _.get(course, 'isLike', false)
  return (
    <Box sx={{ textAlign: 'start', position: 'relative' }}>
      {isLike && <StyledLikeIcon src="/icon/ic_heart_fill.svg" />}
      <StyledTypography
        id="course-type"
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: !isLg && 14 }}
      >
        Collection
      </StyledTypography>
      <StyledTypography variant={isLg ? 'h5' : 'h6'} color="text.primary">
        {_.get(course, 'name', '')}
      </StyledTypography>
    </Box>
  )
}

export default CollectionTitle
