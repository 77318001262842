import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

import { StyledCard, StyledSwitchButton } from './Styled'
import {
  handleInitialForm,
  handleSettingAgentConfirmationChange,
  handleSettingRadioChange,
} from './events'
import { initialOicExam } from '../DefaultValue'

const OicExamSetting = ({ initialValues, setInitialValues, formik }) => {
  const [oixExam, setOicExam] = useState(initialOicExam)
  const requireOicExam = useMemo(
    () => _.get(oixExam, 'requireOicExam', false),
    [oixExam]
  )

  const agentConfirmation = useMemo(
    () => _.get(oixExam, 'agentConfirmation', false),
    [oixExam]
  )

  useEffect(() => {
    handleInitialForm(initialValues, setOicExam, formik)
  }, [initialValues])

  return (
    <StyledCard>
      <Typography variant="h6">ผลสอบใบอนุญาตตัวแทน</Typography>
      <RadioGroup
        sx={{ pl: 1.5, gap: 0.5, marginTop: '20px' }}
        aria-label="setOicExam"
        name="setOicExam"
        value={requireOicExam ? 'yes' : 'no'}
        onChange={(e) => handleSettingRadioChange(e, setInitialValues)}
      >
        <FormControlLabel value="no" control={<Radio />} label="ไม่ต้องการ" />
        <FormControlLabel value="yes" control={<Radio />} label="ต้องการ" />
      </RadioGroup>
      <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
        <Divider />
      </Box>
      <StyledSwitchButton>
        <Switch
          checked={agentConfirmation}
          onChange={(e) =>
            handleSettingAgentConfirmationChange(e, setInitialValues)
          }
        />
        <Box>
          <Typography>เพิ่มขั้นตอนการคอนเฟิร์มตัวแทน</Typography>
        </Box>
      </StyledSwitchButton>
    </StyledCard>
  )
}

export default OicExamSetting
