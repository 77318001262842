import React, { useState, useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import {
  Frame,
  TypographyIcon,
  UnderList,
  FormControlLabelStyled,
} from '../Styled'
import { DialogContent } from './Styled'
import { setCheckCloseChecked } from '../../../../../../redux/slices/manageClassDetail'
import {
  setDisableButton,
  setEnableButton,
} from '../../../../../../redux/slices/dialog'
import DoughnutGraph from '../../../../../../components/DoughnutGraph/DoughnutGraph'
import _ from 'lodash'
import { StatusComplete } from '../index'
import { Box } from '@mui/system'

export const DialogCheckClose = () => {
  const dispatch = useDispatch()
  const [toggleProps, setToggleProps] = useState({
    quiz: false,
    quizAndMail: false,
    nameCheck: false,
    assessment: false,
    acceptAll: false,
  })

  const { manageClassClose, manageClassGraph } = useSelector(
    (state) => ({
      manageClassGraph: state.manageClassDetail.graph,
      manageClassClose: state.manageClassDetail.checkClose,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(setCheckCloseChecked(toggleProps))
    if (!toggleProps.nameCheck || !toggleProps.acceptAll) {
      dispatch(setDisableButton())
    } else if (!toggleProps.quiz || !toggleProps.acceptAll) {
      dispatch(setDisableButton())
    } else if (!toggleProps.quizAndMail || !toggleProps.acceptAll) {
      dispatch(setDisableButton())
    } else if (!toggleProps.assessment || !toggleProps.acceptAll) {
      dispatch(setDisableButton())
    } else if (toggleProps.acceptAll) {
      dispatch(setEnableButton())
    }
  }, [toggleProps])

  const getCheckAttendance = _.get(manageClassClose, 'checkAttendance', [])

  const getNotSentEEva = _.get(
    manageClassClose,
    'checkEValuation.getNotSentEEva',
    []
  )
  const getNotSentETesting = _.get(
    manageClassClose,
    'checkETesting.getNotSentETesting',
    []
  )
  const getNotAnnounceETesting = _.get(
    manageClassClose,
    'checkETesting.getNotAnnounceETesting',
    []
  )

  const haveETestingToDo = _.get(manageClassClose, 'haveETestingToDo', false)

  const haveEEvaluationToDo = _.get(
    manageClassClose,
    'haveEEvaluationToDo',
    false
  )

  return (
    <>
      <DialogContent>
        <Typography variant="body1">
          โปรดตรวจสอบกิจกรรมทั้งหมด ‘ที่มีผลต่อเกณฑ์การผ่านอบรม’
          ของผู้เรียนอีกครั้งว่า ถูกต้องและครบถ้วน
          หรือเลือกปรับสถานะตามรายการด้านล่าง เพื่อปิดคลาส
        </Typography>
        <Frame>
          <Typography variant="body1b">เช็คชื่อ</Typography>
          {getCheckAttendance.length > 0 ? (
            <>
              <Typography sx={{ span: { color: 'error.main' } }}>
                พบผู้เรียนเช็คชื่อไม่ครบ
                <span color="error.main"> {_.size(getCheckAttendance)} คน</span>
              </Typography>
              <FormControlLabelStyled
                control={
                  <Checkbox
                    data-testid="checkbox-name-check"
                    defaultChecked={false}
                    checked={toggleProps.nameCheck}
                    onChange={(e) =>
                      setToggleProps((val) => ({
                        ...val,
                        nameCheck: e.target.checked,
                      }))
                    }
                    color="error"
                  />
                }
                label={
                  <TypographyIcon>
                    ปรับสถานะผู้เรียนที่ไม่เช็คชื่อเป็น
                    <Typography color="error.main">ไม่มาเรียน</Typography>
                  </TypographyIcon>
                }
              />
            </>
          ) : (
            <StatusComplete label="ครบ" />
          )}
        </Frame>
        {haveETestingToDo && (
          <Frame>
            <Typography variant="body1b">แบบทดสอบ</Typography>
            {getNotSentETesting.length > 0 ||
            getNotAnnounceETesting.length > 0 ? (
              <>
                {getNotSentETesting.length > 0 && (
                  <>
                    <Typography variant="body1">
                      พบผู้เรียนไม่ส่งแบบทดสอบ ดังนี้
                    </Typography>
                    <UnderList>
                      {getNotSentETesting.map((item, index) => (
                        <>
                          <li key={index}>
                            {`(${item.type}) ${item.name}: `}
                            <span>{_.size(item.learnerNotSubmit)} คน</span>
                          </li>
                        </>
                      ))}
                    </UnderList>
                    <FormControlLabelStyled
                      control={
                        <Checkbox
                          data-testid="checkbox-quiz"
                          defaultChecked={false}
                          checked={toggleProps.quiz}
                          onChange={(e) =>
                            setToggleProps((val) => ({
                              ...val,
                              quiz: e.target.checked,
                            }))
                          }
                          color="error"
                        />
                      }
                      label={
                        <TypographyIcon variant="body1">
                          ปรับสถานะผู้เรียนที่ไม่ส่งเป็น
                          <Typography color="error.main">
                            ไม่ผ่านการทดสอบ
                          </Typography>
                        </TypographyIcon>
                      }
                    />
                  </>
                )}

                {getNotAnnounceETesting.length > 0 && (
                  <>
                    <TypographyIcon variant="body1">
                      พบแบบทดสอบยังไม่ประกาศผล
                      <Typography color="error.main">
                        {getNotAnnounceETesting.length} รายการ
                      </Typography>
                    </TypographyIcon>
                    <UnderList>
                      {getNotAnnounceETesting.map((item, index) => (
                        <li key={index}>{`(${item.type}) ${item.name}`}</li>
                      ))}
                    </UnderList>
                    <FormControlLabelStyled
                      control={
                        <Checkbox
                          data-testid="checkbox-quiz-send-mail"
                          defaultChecked={false}
                          checked={toggleProps.quizAndMail}
                          onChange={(e) =>
                            setToggleProps((val) => ({
                              ...val,
                              quizAndMail: e.target.checked,
                            }))
                          }
                          color="error"
                        />
                      }
                      label={
                        <Typography variant="body1">
                          ประกาศผลแบบทดสอบที่ยังไม่ประกาศทั้งหมด
                          พร้อมส่งอีเมลแจ้งผู้เรียน
                        </Typography>
                      }
                    />
                  </>
                )}
              </>
            ) : (
              <StatusComplete label="ครบและประกาศผลเรียบร้อย" />
            )}
          </Frame>
        )}

        {haveEEvaluationToDo && (
          <Frame>
            <Typography variant="body1b">แบบประเมิน</Typography>
            {getNotSentEEva.length > 0 ? (
              <>
                <Typography>
                  พบผู้เรียนไม่ได้ทำแบบประเมินที่ระบุเกณฑ์ ‘ต้องทำ’ ดังนี้
                </Typography>
                <UnderList>
                  {getNotSentEEva.map((item, index) => (
                    <>
                      <li key={index}>
                        {`(${item.type}) ${item.name}: `}
                        <span>{_.size(item.learnerNotSubmit)} คน</span>
                      </li>
                    </>
                  ))}
                </UnderList>
                <FormControlLabelStyled
                  color="error.main"
                  control={
                    <Checkbox
                      data-testid="checkbox-assesment"
                      defaultChecked={false}
                      checked={toggleProps.assessment}
                      onChange={(e) =>
                        setToggleProps((val) => ({
                          ...val,
                          assessment: e.target.checked,
                        }))
                      }
                      color="error"
                    />
                  }
                  label={
                    <TypographyIcon>
                      ปรับสถานะผู้เรียนที่ไม่ทำแบบประเมินเป็น
                      <Typography color="error.main">ไม่ผ่าน</Typography>
                    </TypographyIcon>
                  }
                />
              </>
            ) : (
              <StatusComplete label="ครบ" />
            )}
          </Frame>
        )}

        <Frame>
          <Typography variant="body1b">สรุปผลการอบรม</Typography>
          <Box sx={{ marginBottom: '10px' }}>
            <DoughnutGraph size="60px" graphData={manageClassGraph} />
          </Box>
        </Frame>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              onChange={(e) =>
                dispatch(handleCheckboxChange(e, setToggleProps))
              }
              checked={toggleProps.acceptAll}
            />
          }
          label="ฉันรับทราบ เมื่อปิดคลาสจะไม่สามารถแก้ผลการอบรมนี้ได้อีก"
        />
      </DialogContent>
    </>
  )
}

export const handleCheckboxChange = (e, setToggleProps) => (dispatch) => {
  const checked = e.target.checked
  setToggleProps((val) => ({
    ...val,
    acceptAll: checked,
    assessment: checked,
    nameCheck: checked,
    quiz: checked,
    quizAndMail: checked,
  }))
  if (checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}
