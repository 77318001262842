import { createSlice } from '@reduxjs/toolkit'
import { getBatchDetail } from '../../../../services/eLearning/course/form'
import {
  getUserAgent,
  getUserAgentDetail,
  getUserProfile,
} from './../../../../services/manageClass/form'
import { initialState } from './model'
import _ from 'lodash'

const eLearningCourseBatchDetail = createSlice({
  name: 'eLearningCourseBatchDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setUserProfile: (state, { payload }) => {
      const userProfile = state.userProfileList.find(
        (item) => item.uuid === payload,
      )
      state.userProfile[0] = userProfile
    },
    setStaffProfile: (state, { payload }) => {
      const staffProfile = state.userProfileList.find(
        (item) => item.uuid === payload,
      )
      state.staffProfile[0] = staffProfile
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchDetail.fulfilled, (state, { payload }) => {
        state.result = payload
      })
      .addCase(getBatchDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userProfileList = data
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getUserAgent.pending, (state, { meta }) => {
        state.isAgentLoading = true
        state.userAgentList = [{ agentCode: _.get(meta.arg, 'userCode', '') }]
      })
      .addCase(getUserAgent.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userAgentList = data
        state.isAgentLoading = false
      })
      .addCase(getUserAgent.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isAgentLoading = false
      })
      .addCase(getUserAgentDetail.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data.result', [])
        state.userProfile = data.map((user) => ({
          ...user,
          uuid: user.userCode,
          firstNameTH: user.firstName,
          lastNameTH: user.lastName,
          mobileNo: user.mobilePhone,
        }))
      })
      .addCase(getUserAgentDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setChange,
  setUserProfile,
  setStaffProfile,
} = eLearningCourseBatchDetail.actions

export default eLearningCourseBatchDetail.reducer
