import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledRatingRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  minWidth: 584,
  width: '100%',
}))

export const StyledRatingData = styled(Box)(({ theme, color, boxHeight }) => ({
  width: '100%',
  minHeight: boxHeight >= 54 ? 56 : 42,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color,
  [theme.breakpoints.down('md')]: {
    minHeight: boxHeight >= 43 ? 56 : 42,
    minWidth: 80,
  },
}))

export const StyledTitleData = styled(Box)(({ theme, header }) => ({
  backgroundColor: header ? 'transparent' : theme?.palette?.background?.default,
  width: 'auto',
  maxHeight: 56,
  minHeight: header ? 40 : 42,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  flexWrap: 'wrap',
  overflowY: 'auto',
  '& .MuiTypography-root': { wordBreak: 'break-word' },
  [theme.breakpoints.down('sm')]: { width: 180, maxWidth: 180, minWidth: 180 },
}))
