import _ from 'lodash'
import * as yup from 'yup'

export const roadMapSchema = yup.object().shape({
  status: yup.boolean(),
  subtitle: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  title: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})

export const trainerSchema = yup.object().shape({
  status: yup.boolean(),
  title1: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  title2: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  title3: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})

export const trainingPlatformSchema = yup.object().shape({
  status: yup.boolean(),
  title: yup
    .string()
    .test(
      'text-validate',
      'กรุณากรอก',
      (value, { parent }) => !parent.status || value
    )
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})

export const coursesSchema = yup.array().of(
  yup.object().shape({
    status: yup.boolean(),
    course: yup
      .object()
      .test('course-validate', 'กรุณาเลือก', (value, context) => {
        const { parent, from } = context
        const tabStatus = _.get(from, '[2].value.status', false)
        const sectionStatus = _.get(
          from,
          '[4].value.coursesTitle.status',
          false
        )
        const isSelected = !_.isEmpty(value) && !_.isNil(value.uuid)
        return !sectionStatus || !tabStatus || !parent.status || isSelected
      }),
  })
)

export const tabCourseSchema = yup.array().of(
  yup.object().shape({
    taps: yup.array().of(
      yup.object().shape({
        status: yup.boolean(),
        tabTitle: yup
          .string()
          .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร')
          .test('text-validate', 'กรุณากรอก', (value, { parent, from }) => {
            const sectionStatus = _.get(
              from,
              '[2].value.coursesTitle.status',
              false
            )
            return !sectionStatus || !parent.status || value
          }),
        courses: coursesSchema,
      })
    ),
  })
)

export const schema = yup.object({
  bannerSection: yup
    .array()
    .test(
      'banner-validate',
      'ส่วนนี้จำเป็นต้องเปิดการใช้งานอย่างน้อย 1 แบนเนอร์',
      (value) => value.some((item) => item.status)
    ),
  roadMapInformation: roadMapSchema,
  trainerInformation: trainerSchema,
  trainingPlatformInformation: trainingPlatformSchema,
  coursesTitle: yup.object().shape({
    status: yup.boolean(),
    title: yup
      .string()
      .test(
        'text-validate',
        'กรุณากรอก',
        (value, { parent }) => !parent.status || value
      )
      .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
    description: yup
      .string()
      .test(
        'text-validate',
        'กรุณากรอก',
        (value, { parent }) => !parent.status || value
      )
      .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
    button: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
    URL: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  }),
  coursesInformation: tabCourseSchema,
})
