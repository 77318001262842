import { loadingDialog, stopLoadingDialog } from '../../../redux/slices/dialog'
import { store } from '../../../App'
import { handleSuccessfulDelete } from './handleSuccessfulDelete'
import { handleFormSubmissionError } from '../../../components/CRUD/handler/handleProcessSubmitForm'
import { deleteExpense } from '../../../services/expense'

export const handleProcessDelete = async (uuidExpList) => {
  store.dispatch(loadingDialog())

  try {
    const response = await store.dispatch(deleteExpense({ uuidExpList }))

    if (response) {
      handleSuccessfulDelete()
    }
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    store.dispatch(stopLoadingDialog())
  }
}
