import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Table from '../../components/redux/Table'
import Header from './HeaderList'
import FilterDrawer from './FilterDrawerList'
import { fetchDataList } from './events'
import { LoadingPage } from './Styled'
import {
  MANAGE_CLASS_ROW_PAGE_PER_LIST,
  ROW_PAGE_PER_LIST,
} from '../../constants/table'
import { setInitialMore } from '../../redux/slices/table'
import _ from 'lodash'

const CheckExam = ({ isStandalone }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [isInitial, setInitial] = useState(true)

  const { table, search, filter } = useSelector(
    (state) => state.table,
    shallowEqual,
  )

  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const isManageClassPage = _.includes('/manage-class', pathname)

  useEffect(() => {
    let defaultRowsPerPage = 100
    let limit = '100'
    let rowsPerPage = ROW_PAGE_PER_LIST[0]
    if (isManageClassPage) {
      defaultRowsPerPage = 10
      limit = '10'
      rowsPerPage = MANAGE_CLASS_ROW_PAGE_PER_LIST[0]
    }
    dispatch(
      setInitialMore({
        defaultRowsPerPage: defaultRowsPerPage,
        limit: limit,
        rowsPerPage: rowsPerPage,
      }),
    )
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page: page,
        filterProp,
        keyword: searchText,
        setInitial,
        isStandalone,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page: page,
          filterProp,
          keyword: searchText,
          isStandalone,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <Box sx={{ mx: 3 }}>
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Header isStandalone={isStandalone} />

          <Card sx={{ mt: 3 }}>
            <Table
              rowsPerPageOptions={
                isManageClassPage
                  ? _.filter(
                      MANAGE_CLASS_ROW_PAGE_PER_LIST,
                      (item) => item.value !== -1,
                    )
                  : ROW_PAGE_PER_LIST
              }
            />
          </Card>

          <FilterDrawer
            isStandalone={isStandalone}
            open={isFilterDrawer}
            table={table}
          />
        </Box>
        <LoadingPage isLoading={isLoading} />
      </Box>
    </>
  )
}

export default CheckExam
