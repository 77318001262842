import { store } from '../../../App'
import { dispatchUploadValue } from './dispatchUploadValue'

export const handleChange = (index, value, onChange, fieldName) => {
  dispatchUploadValue(
    `formData[${fieldName}].files[${index}]downloadable`,
    value,
  )

  const { uploadFile } = store.getState()
  onChange(uploadFile.formData[fieldName].files)
}
