import { WebStorageStateStore } from 'oidc-client-ts'

const Keycloak = {
  authority: `${window.__env__.REACT_APP_KEY_URL}/realms/${window.__env__.REACT_APP_KEY_REALM}`,
  client_id: window.__env__.REACT_APP_KEY_CLIENT_ID,
  client_secret: window.__env__.REACT_APP_KEY_CLIENT_SECRET,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email profile offline_access',
}
export default Keycloak
