import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minWidth: 275,
  display: 'flex',
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))
