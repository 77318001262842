import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  CRUDDeletedPath,
  CRUDGetByIdPath,
  CRUDGetHistoryPath,
  CRUDPostCreatePath,
  CRUDPutUpdatePath,
  uploadFileCatalog,
} from '../../../utils/apiPath'
import _ from 'lodash'

export const deleteCatalog = createAsyncThunk(
  'catalog/deleteCatalog',
  async ({ uuid, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${CRUDDeletedPath}/${uuid}`,
        headerConfig,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
export const postUploadImage = createAsyncThunk(
  'catalog/postUploadImage',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(uploadFileCatalog, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getDetail = createAsyncThunk(
  'catalog/getDetail',
  async ({ uuid, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${CRUDGetByIdPath}/${uuid}`,
        headerConfig,
      )
      let data = _.get(response, 'data', {})
      data = {
        ...data,
      }
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateCatalog = createAsyncThunk(
  'catalog/postCatalog',
  async ({ body, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.post(CRUDPostCreatePath, body, headerConfig)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putUpdateCatalog = createAsyncThunk(
  'catalog/putCatalog',
  async ({ body, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.put(CRUDPutUpdatePath, body, headerConfig)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCatalogHistory = createAsyncThunk(
  'catalog/getCatalogHistory',
  async ({ url, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${CRUDGetHistoryPath}/${url}`,
        headerConfig,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
