import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import { validateEPermission } from '../../../utils/lib'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingCircular,
  StyledHeader,
} from '../Styled'
import FilterDrawer from './FilterDrawer'
import Header from './Header'
import { fetchDataList } from './events'
import { breadcrumbList } from './model'

const Course = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, isFilterDrawer } = filter
  const { searchText } = search
  const hasCreatePermission = validateEPermission({
    module: 'E_LEARNING',
    permission: 'HEAD_ADMIN',
  })

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'initial',
        page,
        status,
        keyword: searchText,
        setInitial,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({ method: 'fetch', page, status, keyword: searchText }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">Course</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>

          {hasCreatePermission && <Header />}
        </StyledHeader>

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer open={isFilterDrawer} />
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Course
