import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBlankContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'start',
  '& > .MuiTypography-root': {
    minWidth: 72,
    marginTop: theme.spacing(0.5),
  },
  '& > .MuiBox-root': {
    display: 'flex',
    width: '100%',
    alignItems: 'start',
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > .MuiTypography-root': { marginTop: 0 },
  },
}))
