import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
  })
)

export const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
