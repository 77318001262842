import { store } from '../../../App'
import _ from 'lodash'
import { handleCheckUploadStatus } from '../service/handleCheckUploadStatus'
import { dispatchUploadValue } from './dispatchUploadValue'

const getReduxState = () => store.getState().uploadFile

export const handleCheckForUploadStatus = async (onChange, fieldName) => {
  const state = getReduxState()
  const uploadFile = _.get(state, `formData[${fieldName}].files`, [])

  const inProgressFiles = uploadFile.filter(
    (file) => file.status === 'INPROGRESS' && _.isEmpty(file.error),
  )

  if (_.isEmpty(inProgressFiles)) return

  for (const file of inProgressFiles) {
    const inProgressIndex = uploadFile.findIndex((d) => d.uuid === file.uuid)

    try {
      const result = await handleCheckUploadStatus({
        uuid: file.uuid,
        index: inProgressIndex,
        fieldName,
      })

      if (result.status === 'SUCCESS') {
       onChange(getReduxState().formData[fieldName].files)
      }
    } catch (e) {
      dispatchUploadValue(
        `formData[${fieldName}].files[${inProgressIndex}].error`,
        'ERROR_UPLOAD_FILE',
      )
      dispatchUploadValue(`formData[${fieldName}].isLoading`, false)
    }
  }
}
