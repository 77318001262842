export const BADGE_MODEL = {
  certificate: {
    th: 'มีประกาศนียบัตร',
    icon: 'ic_certificate_line.svg',
  },
  time: {
    th: 'ชั่วโมงเรียน',
    icon: 'time-linear.svg',
  },
}

export const SECTION_KEY = {
  COURSE: 'COURSE',
  LEARNING_PATH: 'LEARNING_PATH',
  COLLECTION: 'COLLECTION',
  HYBRID_CLASS: 'HYBRID_CLASS',
  CLASSROOM: 'CLASSROOM',
  VIRTUAL_CLASSROOM: 'VIRTUAL_CLASSROOM',
}

export const COLLECTION_SECTION_KEY = {
  ELEARNING: SECTION_KEY.COURSE,
  LEARNING_PATH: SECTION_KEY.LEARNING_PATH,
  COLLECTION: SECTION_KEY.COLLECTION,
  HYBRID_CLASS: SECTION_KEY.HYBRID_CLASS,
  CLASSROOM: SECTION_KEY.CLASSROOM,
  VIRTUAL_CLASSROOM: SECTION_KEY.VIRTUAL_CLASSROOM,
}

export const CLASSROOM_TITLE = {
  HYBRID_CLASS: 'Classroom & Virtual Classroom',
  CLASSROOM: 'Classroom',
  VIRTUAL_CLASSROOM: 'Virtual Classroom',
}

export const SECTION_OBJ = {
  [SECTION_KEY.COURSE]: {
    unit: 'บทเรียน',
    keyCount: 'lessonAmount',
    link: '/e-learning/course',
  },
  [SECTION_KEY.LEARNING_PATH]: {
    unit: 'หลักสูตร',
    keyCount: 'courseCount',
    link: '/e-learning/learning-path',
  },
  [SECTION_KEY.COLLECTION]: {
    unit: 'หลักสูตร',
    keyCount: 'courseCount',
    link: '/e-learning/collection',
  },
  [SECTION_KEY.CLASSROOM]: {
    unit: '',
    keyCount: '',
    link: '/course',
  },
  [SECTION_KEY.VIRTUAL_CLASSROOM]: {
    unit: '',
    keyCount: '',
    link: '/course',
  },
  [SECTION_KEY.HYBRID_CLASS]: {
    unit: '',
    keyCount: '',
    link: '/course',
  },
}
