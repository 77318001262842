import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Row } from '../../Styled'
import { Container, StyledButton } from './Styled'

const InfoCard = ({
  label,
  number,
  unit,
  background,
  backgroundButton,
  onClick,
}) => {
  return (
    <Container background={background} width={194}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Row sx={{ gap: 1 }}>
        <Typography variant="h4">{number}</Typography>
        <Typography variant="body2" color="text.lightGray">
          {unit}
        </Typography>
        <StyledButton
          color="text.lightGray"
          size="small"
          background={backgroundButton}
          onClick={() => onClick()}
        >
          <ChevronRight fontSize="small" />
        </StyledButton>
      </Row>
    </Container>
  )
}

export default InfoCard
