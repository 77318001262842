import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const ProfileImg = styled(Box)(({ url }) => ({
  width: '100px',
  height: '100px',
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '50%',
}))

export const ImgSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  margin: '50px 0px',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const Input = styled('input')({
  display: 'none',
})

export const InputSectionContainer = styled(Box)(({ theme, width }) => ({
  width: width,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
