import _ from 'lodash'
import * as slices from '../../../../redux/slices/masterData/licenseForm'

export const handleAddConfigGroup = () => (dispatch) => {
  const value = {
    configGroup: 0,
    distributionChannelUuid: '',
    flag: 'N',
    groups: [
      {
        group: 0,
        subGroups: [
          {
            contents: [
              {
                subSequence: 0,
                type: '',
                courseOrLicenseUuid: '',
              },
            ],
          },
        ],
      },
    ],
  }
  dispatch(slices.setAddConfigGroups(value))
}

export const deleteValidateHeader = ({ indexConfig, errors, setErrors }) => {
  const resErrors = errors
  if (resErrors && !_.isEmpty(resErrors.licenseConfig)) {
    if (resErrors.licenseConfig.length == 1) {
      delete resErrors['licenseConfig']
    } else {
      resErrors.licenseConfig.splice(indexConfig, 1)
    }
    setErrors(resErrors)
  }
}

export const handleDeleteConfigGroup = (indexConfig) => (dispatch) => {
  dispatch(slices.setDeleteConfigGroups(indexConfig))
}

export const handleChangeConfigGroup = (props) => (dispatch) => {
  deleteValidateHeader({})
  dispatch(slices.setChangeConfigGroups(props))
}

export const findListCourse = ({ listDistribution, uuid }) => {
  if (!_.defaultTo(listDistribution, []).length || !uuid) return []
  const findCourse = listDistribution.find((item) => item.value === uuid)
  const listCourse = _.get(findCourse, 'course', [])
  return listCourse ? listCourse : []
}

export const findValueSelected = ({ list, value }) => {
  if (!list.length || !value) return null
  const objValue = list.find((item) => item.value === value)
  return objValue ? objValue : null
}

export const handleChangeContent = (props) => (dispatch) => {
  dispatch(slices.setChangeContents(props))
}

export const handleDeleteContent = (props) => (dispatch) => {
  const {
    indexConfig,
    indexGroup,
    indexSubGroup,
    indexContent,
    errors,
    setErrors,
  } = props
  const resErrors = errors

  if (resErrors && !_.isEmpty(resErrors.licenseConfig)) {
    const key = `licenseConfig[${indexConfig}].groups[${indexGroup}].subGroups[${indexSubGroup}].contents`
    const listContent = _.get(resErrors, key, [])

    if (listContent.length == 1) {
      delete listContent['contents']
    } else {
      listContent.splice(indexContent, 1)
    }
    setErrors(resErrors)
  }

  dispatch(
    slices.setDeleteContents({
      indexConfig,
      indexGroup,
      indexSubGroup,
      indexContent,
    })
  )
}

export const handleAddContent = (props) => (dispatch) => {
  dispatch(
    slices.setAddContents({
      ...props,
      valueObj: {
        subSequence: 0,
        type: '',
        courseOrLicenseUuid: '',
      },
    })
  )
}
export const handleAddSubGroup = (props) => (dispatch) => {
  dispatch(
    slices.setAddSubGroups({
      ...props,
      valueObj: {
        contents: [
          {
            subSequence: 0,
            type: '',
            courseOrLicenseUuid: '',
          },
        ],
      },
    })
  )
}

export const handleAddGroup = (indexConfig) => (dispatch) => {
  dispatch(
    slices.setAddGroups({
      indexConfig,
      valueObj: {
        group: 0,
        subGroups: [
          {
            contents: [
              {
                subSequence: 0,
                type: '',
                courseOrLicenseUuid: '',
              },
            ],
          },
        ],
      },
    })
  )
}

export const handleDeleteGroup = (props) => (dispatch) => {
  const { indexConfig, indexGroup, errors, setErrors } = props
  const resErrors = errors

  if (resErrors && !_.isEmpty(resErrors.licenseConfig)) {
    const key = `licenseConfig[${indexConfig}].groups[${indexGroup}].subGroups`
    const listSubGroups = _.get(resErrors, key, [])

    if (listSubGroups.length == 1) {
      delete listSubGroups['subGroups']
    } else {
      listSubGroups.splice(listSubGroups, 1)
    }
    setErrors(resErrors)
  }

  dispatch(
    slices.setDeleteGroups({
      indexConfig,
      indexGroup,
    })
  )
}

export const checkValueEmpty = (item, key) => {
  return !_.isEmpty(_.get(item, key, '')) ? _.get(item, key, '') : null
}

export const mapKeyError = (props) => {
  const { indexConfig, indexGroup, indexSubGroup, indexContent, key } = props
  return `licenseConfig[${indexConfig}].groups[${indexGroup}].subGroups[${indexSubGroup}].contents[${indexContent}].${key}`
}

export const findDisabled = (listContent) => {
  if (!listContent.length) return true
  const findType = listContent.find((item) => item.type == '')
  const findCourse = listContent.find((item) => item.courseOrLicenseUuid == '')

  if (findType || findCourse) return true
  return false
}

export const filterDataActive = (licenseList) => {
  if (!licenseList.length) return []
  return licenseList.filter((item) => item.status === 'ACTIVE')
}

export const addOptionCourse = ({
  defaultOption,
  listOptions,
  defaultUuid,
  courseOrLicenseUuid,
  valueSelect,
}) => {
  if (_.isEmpty(defaultUuid)) {
    return {
      valueSelect,
      listOptions,
    }
  }
  if (!defaultOption.length || _.isEmpty(defaultUuid)) return []
  const findValue = defaultOption.find((item) => item.value === defaultUuid)

  const newListOption = _.uniqBy([findValue, ...listOptions], 'value')
  return {
    valueSelect: courseOrLicenseUuid !== defaultUuid ? valueSelect : findValue,
    listOptions: newListOption.sort((a, b) => -b.label.localeCompare(a.label)),
  }
}
