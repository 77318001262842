import React from 'react'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import styled from '@mui/system/styled'

export const StyledButton = styled(Button)(() => ({
  paddingLeft: 22,
  paddingRight: 22,
}))

export const StyledPopper = styled((props) => (
  <Popper role={undefined} transition disablePortal {...props} />
))(() => ({
  zIndex: 99,
  transform: 'translate(-30px, 40px) !important',
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: 240,
    '& .MuiList-root': { width: 240 },
  },
}))
