import React from 'react'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import CarouselLogin from '../../../../../components/Carousel/CarouselLogin'
import { CarouselDot, SliderContainer, TextContainer, Overlay } from './Styled'
import { RenderTextEditor } from '../../../../../components/Editor/BasicEditor'
const LoginSlider = () => {
  const loginPages = useSelector(
    (state) => state.manageContentLogin.loginPages,
    shallowEqual
  )
  const listData = loginPages.filter((item) => item.status === 'ACTIVE')
  const customStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  }
  if (listData.length == 0) return <></>
  return (
    <>
      <CarouselLogin
        countList={listData.length}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          return (
            <>
              <CarouselDot
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                isSelected={isSelected}
                index={index}
                aria-label={`${label} ${index + 1}`}
                countContent={listData.length}
              ></CarouselDot>
            </>
          )
        }}
      >
        {listData.map((login, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
            }}
          >
            <SliderContainer
              url={`${window.__env__.REACT_APP_API_URL}/file${login.image}`}
            >
              <Overlay />
              <TextContainer>
                <RenderTextEditor
                  variant="h4"
                  labelText={_.get(login, 'title', '')}
                  color={_.get(login, 'colorTitle', '#FFFFFF')}
                  style={{
                    '& .MuiTypography-root': {
                      ...customStyle,
                      '-webkit-line-clamp': '1 !important',
                    },
                  }}
                />
                <RenderTextEditor
                  variant="body1b"
                  labelText={_.get(login, 'description', '')}
                  color={_.get(login, 'colorDescription', '#FFFFFF')}
                  style={{
                    '& .MuiTypography-root': {
                      ...customStyle,
                      '-webkit-line-clamp': '3 !important',
                    },
                  }}
                />
              </TextContainer>
            </SliderContainer>
          </Box>
        ))}
      </CarouselLogin>
    </>
  )
}

export default LoginSlider
