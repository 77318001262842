import _ from 'lodash'
import { store } from '../../../../App'
import { path } from '../../../../constants/path'
import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import {
  setBody,
  startLoading,
  stopLoading,
  setInitialCreateForm,
  startOptionLoading,
  stopOptionLoading,
  setFieldValue,
  setChange,
  startECertLoading,
  stopECertLoading,
} from '../../../../redux/slices/eLearning/learningPathForm'
import { getDetail } from '../../../../services/eLearning/learningPath/detail'
import {
  getLearningPathCourses,
  postCreateLearningPath,
  postCreateLearningPathDraft,
  putEditLearningPath,
} from '../../../../services/eLearning/learningPath/form'
import {
  getAllDistributionChannel,
  getAllProductType,
} from '../../../../services/manageContent'
import {
  getAcquiredSkillList,
  getECertificationList,
} from '../../../../services/eLearning/course/form'
import { COURSE_STATUS } from '../../../../constants/eLearning'
import { setFieldUploadValue } from '../../../../redux/slices/uploadCropper'
import { FORM_TAB } from './model'

export const handleChangeTab = (value) => (dispatch) => {
  dispatch(setChange({ key: 'tab', value }))
}

export const fetchCourseDetail = (props) => async (dispatch) => {
  const { uuid, onEdit, setOnEdit, tab } = props
  if (onEdit) return

  dispatch(startLoading())
  dispatch(handleChangeTab(FORM_TAB.learningPath))

  if (_.isEmpty(uuid)) {
    dispatch(setBody(null))
    dispatch(initialUploadCropper(null))
    dispatch(setInitialCreateForm())
    await dispatch(fetchOptions())
  } else {
    dispatch(setBody(null))
    dispatch(fetchOptions())
    const response = await dispatch(getDetail(uuid))
    dispatch(initialUploadCropper(_.get(response, 'payload.data', {})))
    if (tab) dispatch(handleChangeTab(tab))
  }

  setOnEdit(true)
  dispatch(stopLoading())
}

export const initialUploadCropper = (data) => (dispatch) => {
  dispatch(
    setFieldUploadValue({
      key: 'fileName',
      value: _.get(data, 'fileName', ''),
    }),
  )
  dispatch(
    setFieldUploadValue({
      key: 'coverImage',
      value: _.get(data, 'coverImage', ''),
    }),
  )
}

export const handleGetCourseList = () => async (dispatch) => {
  dispatch(startOptionLoading())
  await dispatch(getLearningPathCourses())
  dispatch(stopOptionLoading())
}

export const handleGetECertificationList = () => async (dispatch) => {
  dispatch(startECertLoading())
  await dispatch(getECertificationList())
  dispatch(stopECertLoading())
}

export const fetchOptions = () => async (dispatch) => {
  dispatch(getAllDistributionChannel())
  dispatch(getAllProductType())
  dispatch(handleGetCourseList())
  dispatch(handleGetECertificationList())
  await dispatch(getAcquiredSkillList())
}

export const handleSubmit = (values, history) => (dispatch) => {
  if (values.isDraft) {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกฉบับร่าง',
        message: 'คุณต้องการบันทึกฉบับร่างนี้หรือไม่',
        handleConfirm: () => dispatch(handleConfirmSubmit(values, history)),
      }),
    )
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันบันทึกและเผยแพร่',
        message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
        handleConfirm: () => dispatch(handleConfirmSubmit(values, history)),
      }),
    )
  }
}

export const handleConfirmSubmit = (values, history) => async (dispatch) => {
  dispatch(closeDialog())

  const eLearningLearningPathCourses = _.map(
    values.eLearningLearningPathCourses.filter(
      (course) => !_.isEmpty(course.type),
    ),
    (course, index) => {
      const payload = { ...course, sequence: index + 1 }
      const isELearning = course.type === 'E-Learning'
      const courseKey = isELearning ? 'eLearningCourse' : 'courseVersion'
      const courseObj = {
        id: _.get(course.course, 'id', ''),
        uuid: _.get(course.course, 'uuid', ''),
      }
      _.set(payload, courseKey, courseObj)
      delete payload.course
      return payload
    },
  )
  const distribution = _.get(values.setting, 'distribution', [])
  const setting = {
    ...values.setting,
    distribution: distribution.map((item) => ({
      label: item.label,
      value: item.value,
    })),
  }
  const eLearningLearningPathRefCertificate = _.get(
    values,
    'eLearningLearningPathRefCertificate',
    [],
  )
    .filter((cert) => !_.isEmpty(cert.eCertificationVersion))
    .map((cert) => {
      const eCerVerField =
        typeof cert.eCertificationVersion === 'string'
          ? 'eCertificationVersion'
          : 'eCertificationVersion.uuid'
      return {
        ...cert,
        eCertificationVersion: { uuid: _.get(cert, eCerVerField, null) },
      }
    })
  const body = {
    ...values,
    setting,
    eLearningLearningPathCourses,
    eLearningLearningPathRefCertificate,
    continuousNotifications: values.continuousNotifications || 0,
    advanceNotification: values.advanceNotification || 0,
  }

  dispatch(startLoading())
  let response = {}
  const isCreate = _.isEmpty(values.uuid)
  const isDraft = values.isDraft

  if (!isDraft) {
    if (body.status === COURSE_STATUS.DRAFT) {
      body.status = COURSE_STATUS.INACTIVE
    }
    if (isCreate) {
      response = await dispatch(postCreateLearningPath(body))
    } else {
      response = await dispatch(putEditLearningPath(body))
    }
  } else {
    response = await dispatch(postCreateLearningPathDraft(body))
  }
  dispatch(stopLoading())

  if (_.isNil(response.error)) {
    dispatch(
      openDialog({
        isCloseDialog: false,
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        agreeText: 'ตกลง',
        handleConfirm: () => {
          const uuid = _.get(response.payload, 'uuid', '')
          history.push(`${path.eLearning}/learning-path/view/${uuid}`)
          dispatch(closeDialog())
        },
      }),
    )
  }
}

export const handleStringToNumber = (value) => {
  if (_.isNil(value)) return 0
  return _.isNumber(value) ? value : Number(value.replaceAll(',', ''))
}

export const getPathFormState = () => {
  const state = store.getState()
  return _.get(state, 'eLearningPathForm.body', {})
}

export const getStateValue = (key, defaultValue = '') => {
  const pathFormState = getPathFormState()
  return _.get(pathFormState, key, defaultValue)
}

export const handleChange = (key, value, isRoot) => (dispatch) => {
  const obj = { key, value }
  if (isRoot) dispatch(setChange(obj))
  else dispatch(setFieldValue(obj))
}

export const handleChangeEvent = (e, isCheck) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = isCheck
    ? _.get(e.target, 'checked', false)
    : _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}

export const handleInclude = (list, option) => {
  return list.some((item) => item.value === option.value)
}
