import _ from 'lodash'
import { setSharePropValue } from '../../../../../redux/slices/manageClassDetail'

export const handleOnClickMenu = (props) => (dispatch) => {
  const { option, type, row, rowIndex, traningPlanUuid } = props
  const testEvauuid = _.get(row, 'testOrEvaluateUuid', '')
  const shareTestEvaList = localStorage.getItem('shareTestEvaList')
    ? JSON.parse(localStorage.getItem('shareTestEvaList'))
    : []
  const findShare = _.find(
    shareTestEvaList,
    (item) => item === `${traningPlanUuid}_${testEvauuid}`
  )

  if (option.link) window.open(option.link, '_blank')
  else {
    const uuid = _.get(row, 'uuid', '')
    const eTestLink = `${window.__env__.REACT_APP_LEARNER_URL}/e-testing/${uuid}?class=${traningPlanUuid}`
    const eEvaLink = `${window.__env__.REACT_APP_LEARNER_URL}/e-evaluation/${testEvauuid}?class=${traningPlanUuid}&eva=${uuid}`
    const shareLink = type == 'E_TESTING' ? eTestLink : eEvaLink
    dispatch(setSharePropValue({ index: rowIndex, key: 'isOpen', value: true }))
    dispatch(
      setSharePropValue({
        index: rowIndex,
        key: 'isShare',
        value: !_.isUndefined(findShare),
      })
    )
    dispatch(
      setSharePropValue({ index: rowIndex, key: 'uuid', value: testEvauuid })
    )
    dispatch(setSharePropValue({ index: rowIndex, key: 'type', value: type }))
    dispatch(
      setSharePropValue({ index: rowIndex, key: 'shareLink', value: shareLink })
    )
  }
}
