import _ from 'lodash'
import { setInitialTable } from '../../../../../redux/slices/table'
import { getRoomHistory } from '../../../../../services/roomManagement'
import { setReduxValue } from '../../../../../redux/slices/roomManagement/Drawer'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'author',
    disablePadding: false,
    label: 'ผู้ดำเนินการ',
    hideSortIcon: true,
  },
  {
    id: 'event',
    disablePadding: false,
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'reason',
    disablePadding: false,
    label: 'เหตุผล',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchRoomHistory = (props) => async (dispatch) => {
  dispatch(setReduxValue({ key: 'ishLoadingHistoryDrawer', value: true }))
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`

  const result = await dispatch(getRoomHistory(url))
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: _.get(result, 'payload.result', []),
      allCount: _.get(result, 'payload.totalCount', 0),
      headCells: headCells,
      customStyle: StyledTable,
    }),
  )
  dispatch(setReduxValue({ key: 'ishLoadingHistoryDrawer', value: false }))
}
