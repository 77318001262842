import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const DialogContent = styled(Box)(({ theme }) => ({
  margin: `0px ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(6)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: { marginLeft: theme.spacing(2) },
}))
