import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { productWarehouseListFilter } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'

export const checkAvailableProduct = async (event, value) => {
  //from, product uuid
  const { from } = store.getState().crud.formData
  const productUuid = value?.value ?? ''
  if (productUuid && from?.value !== 'IMPORT_PRODUCT') {
    const warehouseUuid = from?.value
    const body = {
      limit: -1,
      page: 1,
      order: 'DESC',
      sort: 'updatedAt',
      status: ['ACTIVE'],
      warehouseUuid: warehouseUuid,
      productUuid: productUuid,
      search: '',
      startCreatedDate: '',
      endCreatedDate: '',
      startUpdatedDate: '',
      endUpdatedDate: '',
      createdBy: '',
      updatedBy: '',
      warehouseStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
      productStatus: ['ACTIVE', 'INACTIVE', 'DELETED'],
      totalFrom: '',
      totalTo: '',
    }

    let available = 0
    await callAxios
      .post(productWarehouseListFilter, body)
      .then((res) => {
        const data = res.data
        const result = _.get(data, 'result[0]', '')
        if (result) {
          available = _.sumBy(_.get(data, 'result'), (item) => {
            return item.available
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })

    store.dispatch(
      setFieldValue({
        key: 'formData.productAvailable',
        value: available,
      }),
    )
  } else {
    store.dispatch(
      setFieldValue({
        key: 'formData.productAvailable',
        value: '',
      }),
    )
  }
}
