import React from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import FileCopy from '@mui/icons-material/FileCopy'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { useParams } from 'react-router-dom'
import { path } from './../../../../../constants/path'
import { StyledHeaderContainer } from './../../../Styled'
import { StyledButton } from './Styled'

const Header = () => {
  const { id: uuid } = useParams()
  const [, setEEvaluation] = useLocalStorage('eEvaluation')
  const stateEEvaluation = useSelector((state) => state.eEvaluationForm)
  return (
    <StyledHeaderContainer id="view">
      <StyledButton
        data-testid="btn-preview"
        onClick={() => {
          setEEvaluation(stateEEvaluation)
          window.open(`${path.eEvaluation}/preview/${uuid}`, '_blank')
        }}
      >
        <RemoveRedEye />
        ดูตัวอย่าง
      </StyledButton>
      <StyledButton
        data-testid="btn-copy"
        onClick={() =>
          (window.location.href = `${path.eEvaluation}/version/clone/${uuid}`)
        }
      >
        <FileCopy />
        คัดลอกเพื่อสร้างใหม่
      </StyledButton>
    </StyledHeaderContainer>
  )
}

export default Header
