import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'

const WhiteCard = ({ label, value, unit = 'Content' }) => {
  return (
    <Box
      sx={{
        padding: '4px 12px 0 12px',
        borderRadius: '8px',
        border: '1px solid #DBE4F199',
      }}
    >
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Row>
        <Typography variant="h4" mr={1}>
          {value}
        </Typography>
        <Typography variant="body2" color="text.lightGray">
          {unit}
        </Typography>
      </Row>
    </Box>
  )
}

export default WhiteCard
