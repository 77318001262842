export const arrayFlagStatus = [
  {
    label: 'Auto',
    name: 'AutoChecked',
  },
  {
    label: 'Manual',
    name: 'ManualChecked',
  },
  {
    label: 'Admin',
    name: 'AdminChecked',
  },
]

export const arrayLicenseStatus = [
  {
    label: 'รอตรวจสอบ',
    name: 'WAITING',
  },
  {
    label: 'ให้',
    name: 'ACCESS',
  },
  {
    label: 'ไม่ให้',
    name: 'NOT_ACCESS',
  },
]

export const arrayRcmsStatus = [
  {
    label: 'ยังไม่มีสถานะ',
    name: 'NO_STATUS',
  },
  {
    label: 'สำเร็จ',
    name: 'SUCCESS',
  },
  {
    label: 'ไม่สำเร็จ',
    name: 'NOT_SUCCESS',
  },
  {
    label: 'ไม่ต้องส่ง',
    name: 'NOT_SEND',
  },
  {
    label: 'รอส่ง',
    name: 'WAIT_SEND',
  },
]

export const arrayCheckbox = {
  api_to_rcms_detail: [
    {
      label: 'รหัส Agent',
      nameCheckBox: 'fullAgentCodeChecked',
      nameInput: 'fullAgentCode',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อ - นามสกุล',
      nameCheckBox: 'fullNameChecked',
      nameInput: 'fullName',
      placeholder: 'ค้นหา',
    },
    {
      label: 'Bank Code',
      nameCheckBox: 'bankCodeChecked',
      nameInput: 'bankCode',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ประเภท Agent',
      nameCheckBox: 'agentCategoryChecked',
      nameInput: 'agentCategory',
      placeholder: 'ค้นหา',
    },
    {
      label: 'วันมีผลบังคับ',
      nameCheckBox: 'effectiveDateChecked',
      nameInput: 'effectiveDateStartDate',
      placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
      isDate: true,
    },
    {
      label: 'วันหมดอายุ',
      nameCheckBox: 'expiryDateChecked',
      nameInput: 'expiryDateStartDate',
      placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
      isDate2: true,
    },
  ],
}

export const defaultFilter = {
  api_to_rcms_detail: {
    fullAgentCodeChecked: false,
    fullAgentCode: '',
    fullNameChecked: false,
    fullName: '',
    bankCodeChecked: false,
    bankCode: '',
    agentCategoryChecked: false,
    agentCategory: '',
    effectiveDateChecked: false,
    effectiveDateStartDate: '',
    effectiveDateFinishDate: '',
    expiryDateChecked: false,
    expiryDateStartDate: '',
    expiryDateFinishDate: '',
    licenseStatusChecked: false,
    licenseStatus: {
      WAITING: false,
      ACCESS: false,
      NOT_ACCESS: false,
    },
    rcmsStatusChecked: false,
    rcmsStatus: {
      NO_STATUS: false,
      SUCCESS: false,
      NOT_SUCCESS: false,
      NOT_SEND: false,
      WAIT_SEND: false,
    },
  },
}
