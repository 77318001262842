import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { StyledBox } from '../Styled'
import RedeemDialog from './RedeemDialog'

const ActionViewPointHistory = ({ row }) => {
  const [open, setOpen] = useState(false)
  return (
    <StyledBox minWidth={0}>
      {row.event === 'แลกรางวัล' && (
        <>
          <IconButton
            color="primary"
            component="span"
            onClick={() => setOpen(true)}
          >
            <RemoveRedEyeTwoTone color="action" />
          </IconButton>
          <RedeemDialog row={row} open={open} setOpen={setOpen} />
        </>
      )}
    </StyledBox>
  )
}

export default ActionViewPointHistory
