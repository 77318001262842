import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { TopicSection, Container, CardSection } from './Styled'
import CardLocation from './CardLocation'

const BodyLocation = () => {
  const data = useSelector(
    (state) => state.manageContentStation.data,
    shallowEqual
  )
  const titleInformation = _.get(data, 'titleInformation', {})
  const stationList = _.get(data, 'stationInformation', []).filter(
    (station) => station.status
  )
  const subtitle = _.get(titleInformation, 'subtitle', '')
  const title = _.get(titleInformation, 'title', '')
  const noTitle = _.isEmpty(title) && _.isEmpty(subtitle)

  return (
    <Container>
      <TopicSection pb={noTitle ? 0 : 6}>
        <Typography
          variant="h6"
          sx={{ typography: { sm: 'h5', lg: 'h5' } }}
          color="primary"
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h5"
          sx={{ typography: { sm: 'h4', lg: 'h3' } }}
          color="primary"
        >
          {title}
        </Typography>
      </TopicSection>
      <CardSection>
        {stationList.map((item, itemIndex) => {
          const image = `${window.__env__.REACT_APP_API_URL}/api/file${item.img}`
          const imageMap = `${window.__env__.REACT_APP_API_URL}/api/file${item.imgMap}`
          return (
            <CardLocation
              key={itemIndex}
              image={image}
              imageMap={imageMap}
              item={item}
            />
          )
        })}
      </CardSection>
    </Container>
  )
}

export default BodyLocation
