import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  draftIndexSubQuestions,
  draftSubQuestions,
} from '../../../../../../../../redux/slices/eEvaluationForm'

import DefaultChoice from '../../DefaultChoice'
import FreeTextChoice from '../../FreeTextChoice'
import RatingChoice from '../../RatingChoice'

export const ListSubQuestion = ({
  sequence,
  indexQuestion,
  indexSubQuestion,
  subQuestionSequence,
  subQuestionIndex,
}) => {
  const { itemQuestion, disableForm } = useSelector(
    (state) => ({
      itemQuestion: state.eEvaluationForm.subQuestions.find(
        (item) => item.sequence === sequence
      ).value[subQuestionIndex],
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  const props = {
    sequence,
    indexQuestion,
    itemQuestion,
    isSubQuestions: true,
    indexSubQuestion,
    subQuestionIndex,
    subQuestionSequence,
    disableForm,
  }

  switch (itemQuestion?.questionType) {
    case 'SINGLE':
    case 'MULTIPLE':
    case 'RANKING':
      return <DefaultChoice props={props} />
    case 'FREE_TEXT':
      return <FreeTextChoice props={props} />
    case 'RATING':
      return <RatingChoice props={props} />
    default:
      return <></>
  }
}

const Index = ({ sequence, indexQuestion }) => {
  const listSubQuestion = useSelector(draftSubQuestions(sequence), shallowEqual)
  const indexSubQuestion = useSelector(
    draftIndexSubQuestions(sequence),
    shallowEqual
  )

  return listSubQuestion && listSubQuestion.length > 0 ? (
    <>
      {listSubQuestion.map((item, index) => (
        <>
          <ListSubQuestion
            id={item}
            key={item}
            sequence={sequence}
            indexQuestion={indexQuestion}
            indexSubQuestion={indexSubQuestion}
            subQuestionSequence={item}
            subQuestionIndex={index}
          />
        </>
      ))}
    </>
  ) : (
    <></>
  )
}
export default Index
