import React from 'react'
import { StyledContainer } from '../Styled'
import ClassList from './ClassList'
import RightContent from './RightContent'

const CourseContent = () => {
  return (
    <StyledContainer>
      <ClassList />
      <RightContent />
    </StyledContainer>
  )
}

export default CourseContent
