import dayjs from 'dayjs'
import { assetTypeEnum } from './enum/assetTypEnum'
import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { inventoryCheckSkuNo } from '../../../../utils/apiPath'
import callAxios from '../../../../utils/baseService'

export const convertJoinDate = (start, end) => {
  let workStart = ''
  if (!start || !end) {
    return '-'
  }
  const startDate = dayjs(new Date(start))
  const endDate = dayjs(new Date(end))
  const years = endDate.diff(startDate, 'year')
  const months = endDate.diff(startDate, 'month') - years * 12
  const days = endDate.diff(
    startDate.add(years, 'year').add(months, 'month'),
    'day',
  )

  if (years > 0) {
    workStart += `${years} ปี `
  }
  if (months > 0) {
    workStart += `${months} เดือน `
  }
  if (days > 0) {
    workStart += `${days} วัน`
  }
  return workStart
}

export const getSkuNo = async () => {
  const { type, productStockAsset, productAsset, productLicense } =
    store.getState().crud.formData

  const product =
    type === assetTypeEnum.SKU && !_.isEmpty(productStockAsset)
      ? productStockAsset
      : type === assetTypeEnum.ASSET && !_.isEmpty(productAsset)
      ? productAsset
      : type === assetTypeEnum.LICENSE && !_.isEmpty(productLicense)
      ? productLicense
      : null

  if (product) {
    const body = {}
    await callAxios
    .post(inventoryCheckSkuNo, body)
    .then((res) => {
      const data = res.data
      const result = _.get(data, 'result', [])
      store.dispatch(setFieldValue({
        key: 'skuNoList',
        value: result,
      }))
    })
    .catch((e) => {
      console.log(e)
    })
  }else{
    store.dispatch(setFieldValue({
      key: 'skuNoList',
      value: [],
    }))
  }
}
