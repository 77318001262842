import { downloadTemplate } from '../../../services/eExamination'
import {
  startLoading,
  stopLoading,
  setImportFile,
  resetFile,
  setFileUploadResult,
} from '../../../redux/slices/eExamination/list'
import { loadingDialog, openDialog } from '../../../redux/slices/dialog'
import _ from 'lodash'
import { uploadExaminationSchedule } from '../../../services/eExamination'
import CheckDialog from './CheckDialog'

export const onDownloadTemplate = (eExamType) => async (dispatch) => {
  const body = {
    list: ['fbe72f99-f316-4bab-b934-c1189c6a2658'],
    type: 'ALL',
    order: 'DESC',
    sort: 'updatedAt',
    schedule: eExamType,
  }
  await dispatch(downloadTemplate(body))
}

export const onUploadTemplate = (dataImport) => (dispatch) => {
  dispatch(startLoading())
  dispatch(setImportFile(dataImport))

  setTimeout(() => {
    dispatch(stopLoading())
  }, 500)
}

export const handleSubmitAdd = (file, eExamType) => async (dispatch) => {
  dispatch(loadingDialog())
  const formData = new FormData()
  formData.append('file', file)
  const response = await dispatch(
    uploadExaminationSchedule({ formData, eExamType }),
  )
  const results = _.get(response, 'payload.data', {})
  dispatch(setFileUploadResult(results))
  dispatch(resetFile())
  if (_.isNil(response.error)) {
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'xl',
        disableButton: true,
        content: <CheckDialog />,
      }),
    )
  }
}
