import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledInputCard = styled(Box)(({ theme }) => ({
  width: 'calc(49% - 7px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'end',
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { width: 'calc(50% - 8px)' },
}))
