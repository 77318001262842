import React, { useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextInput from '../../../components/Input/TextInput'
import { StyledFormCard } from '../Styled'
import { handleChangeEvent, handleChange } from './events'
import StatusSwitch from './StatusSwitch'
import UploadFile from '../../../components/UploadFile'

const Info = () => {
  const dispatch = useDispatch()
  const {
    isUploadLoading,
    name,
    description,
    point,
    qty,
    remark,
    catalogImages,
  } = useSelector(
    (state) => ({
      isUploadLoading: state.catalogForm.isUploadLoading,
      name: state.catalogForm.body.name,
      description: state.catalogForm.body.description,
      point: state.catalogForm.body.point,
      qty: state.catalogForm.body.qty,
      remark: state.catalogForm.body.remark,
      catalogImages: state.uploadFile.formData.catalogImages,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  useEffect(() => {
    if (!catalogImages?.isUploading && catalogImages) {
      let badgeImage = {}
      if (catalogImages?.files?.length > 0) {
        const { displayName, fileSize, key } = catalogImages.files[0]
        badgeImage = {
          key: key,
          fileName: displayName,
          fileSize: fileSize,
        }
      }
      dispatch(handleChange('image', badgeImage))
    }
  }, [catalogImages])
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">ข้อมูลของรางวัล</Typography>
      <TextInput
        required
        name="name"
        type="basic"
        labelText="ชื่อของรางวัล"
        placeholder="ชื่อของรางวัล"
        id="input-name"
        textError={_.get(errors, 'name', '')}
        isShowTextError={_.get(errors, 'name', '')}
        value={name}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        multiline
        rows={3}
        name="description"
        type="basic"
        labelText="คำอธิบาย"
        placeholder="คำอธิบาย"
        id="input-description"
        textError={_.get(errors, 'description', '')}
        isShowTextError={_.get(errors, 'description', '')}
        value={description}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        required
        name="point"
        type="basic"
        labelText="คะแนนที่ใช้แลก"
        placeholder="คะแนนที่ใช้แลก"
        id="input-point"
        textError={_.get(errors, 'point', '')}
        isShowTextError={_.get(errors, 'point', '')}
        value={point}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <TextInput
        required
        name="qty"
        type="basic"
        labelText="จำนวนของรางวัลในสต๊อก"
        placeholder="จำนวนของรางวัลในสต๊อก"
        id="input-qty"
        textError={_.get(errors, 'qty', '')}
        isShowTextError={_.get(errors, 'qty', '')}
        value={qty}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" color="text.secondary">
            อัปโหลดรูปของรางวัล
          </Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
            *
          </Typography>
        </Box>
        <UploadFile
          sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
          isForImage
          folder="catalog"
          maxFileSize={{ size: 2, sizeType: 'MB' }}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
          }}
          acceptLabel=".jpg, .jpeg, .png"
          fieldName="catalogImages"
          maxLength={1}
        />
        {_.get(errors, 'image.key', '') && (
          <Typography
            sx={{ height: 25 }}
            variant="body2"
            color="error"
            id="input-image"
          >
            {_.get(errors, 'image.key', '')}
          </Typography>
        )}
      </Box>
      <TextInput
        multiline
        rows={3}
        name="remark"
        type="basic"
        labelText="หมายเหตุ"
        placeholder="หมายเหตุ"
        id="input-remark"
        textError={_.get(errors, 'remark', '')}
        isShowTextError={_.get(errors, 'remark', '')}
        value={remark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default Info
