import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { styledImageBoxProps } from '../../Styled'

export const BoxImage = styled(Box)(({ theme, isLoading, isError }) => ({
  width: '280px',
  maxWidth: '280px',
  height: '105px',
  borderRadius: theme.spacing(1),
  ...styledImageBoxProps(theme, isLoading, isError),
}))

export const InfoImage = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  maxWidth: '280px',
  height: '105px',
  borderRadius: '8px',
}))

export const BoxContentImage = styled(Box)(({ theme }) => ({
  width: 280,
  marginRight: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}))
