import React, { useMemo } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../../../../../components/Input/Autocomplete'
import TextInput from '../../../../../../components/Input/TextInput'
import SubInfoInput from '../../../../../../components/SubInfoInput'
import ActiveSwitch from '../../../../components/ActiveSwitch'
import { StyledColumn } from '../../../Styled'
import { handleSelector } from '../../events'
import {
  StyledContainerCourse,
  StyledCourseCard,
  StyledTabHeader,
} from './Styled'
import {
  handleCourseOption,
  handleDefaultCourse,
  handleInputTabBody,
  handleSelectTabBody,
  handleSwitchCourseBody,
  handleSwitchTabBody,
} from './events'

export const TabContent = ({ courseIndex, tabs, index }) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const keyField = `coursesInformation[${courseIndex}].taps[${index}]`
  const tab = _.get(tabs, index, null)
  const no = index + 1
  const courses = _.get(tab, 'courses', [])

  return (
    <StyledColumn gap={3}>
      <StyledColumn gap={3}>
        <StyledTabHeader>
          <Typography variant="h6">Tab {no}</Typography>
          <ActiveSwitch
            name="status"
            dataTestId={`active-tab-${no}`}
            checked={_.get(tab, 'status', false)}
            onChange={(e) => dispatch(handleSwitchTabBody(e, keyField))}
          />
        </StyledTabHeader>

        <StyledColumn>
          <TextInput
            required
            name="tabTitle"
            labelText={`ชื่อ TAB ${no}`}
            type="basic"
            id="input-tab-title"
            placeholder="ชื่อ TAB"
            value={_.get(tab, 'tabTitle', '')}
            textError={_.get(errors, `${keyField}.tabTitle`, '')}
            textInfo="ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร"
            onChange={(e) => dispatch(handleInputTabBody(e, keyField))}
          />
          <Typography variant="body1b">หลักสูตรใน Tab {no}</Typography>
        </StyledColumn>

        <Box
          sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' } }}
        >
          <StyledContainerCourse>
            {Array(8)
              .fill()
              .map((item, index) => (
                <CourseCard
                  key={index}
                  data={_.get(courses, index, {})}
                  cIndex={index}
                  keyField={keyField}
                />
              ))}
          </StyledContainerCourse>
        </Box>
      </StyledColumn>
    </StyledColumn>
  )
}

export default TabContent

export const CourseCard = ({ data, keyField, cIndex }) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const keyCourse = `${keyField}.courses[${cIndex}]`
  const courseCardProps = useSelector(
    (state) => ({
      levelDistribution: state.manageContentHome.levelDistribution,
      courses: handleSelector(state, 'coursesInformation'),
      optionCourses: state.manageContentHome.options.courses,
      optionCoursesEL: state.manageContentHome.options.coursesEL,
    }),
    shallowEqual,
  )
  const { levelDistribution, optionCourses, optionCoursesEL } = courseCardProps
  const levelDistributionCourseOption = useMemo(
    () => handleCourseOption(courseCardProps),
    [levelDistribution],
  )

  const defaultCourse = useMemo(() => handleDefaultCourse(data), [data])
  const nameForLearner = useMemo(() => {
    const courseObj = levelDistributionCourseOption.find(
      (item) => item.uuid === defaultCourse.value,
    )
    return _.get(courseObj, 'nameForLearner', '')
  }, [defaultCourse])

  return (
    <StyledCourseCard>
      <Autocomplete
        type="basic"
        labelText="รหัสหลักสูตร"
        options={levelDistributionCourseOption}
        defaultValue={defaultCourse}
        renderInput={(params) => (
          <TextField
            placeholder="เลือกรหัสหลักสูตร"
            {...params}
            inputProps={{
              ...params.inputProps,
              name: 'course',
              'data-testid': `select-course-${cIndex}`,
            }}
          />
        )}
        textError={_.get(errors, `${keyCourse}.course`, '')}
        handleChange={(e) =>
          dispatch(
            handleSelectTabBody(e, keyCourse, [
              ...optionCourses,
              ...optionCoursesEL,
            ]),
          )
        }
        subComponent={<SubInfoInput label={nameForLearner} />}
      />
      <ActiveSwitch
        name="status"
        checked={_.get(data, 'status', false)}
        onChange={(e) => dispatch(handleSwitchCourseBody(e, keyCourse))}
        dataTestId={`active-course-${cIndex}`}
      />
    </StyledCourseCard>
  )
}
