import { Box, Typography } from '@mui/material'
import useLocalStorage from '@rehooks/local-storage'
import { StyledDashboardCard, StyledDot } from '../Styled'
import { E_LEARNING_LEARNER_TYPE_LABEL } from '../../../../../../../constants/eLearning'
import InfoPreview from '../../../../../../../components/InfoPreview'
import PieChart from '../../../../../../../components/Chart/PieChart'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import StatusInfoPreview from './StatusInfoPreview'
import { InboxTwoTone } from '@mui/icons-material'
import { handleOpenLearnerDrawer } from '../events'
import { validatePermission } from '../../../../../../../utils/lib'

const EnrollOverviewCard = (props) => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const hasAdminPermission = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN'],
  })
  const { type, totalCount, idx, progress, color, secondaryColor } = props
  const datasets = {
    data: _.map(progress, (pg) => _.get(pg, 'percent', 0)),
    backgroundColor: [
      theme.palette.graph.grey1,
      theme.palette.graph.blue3,
      theme.palette.graph.green2,
    ],
  }

  return (
    <StyledDashboardCard sx={{ width: '100%', maxWidth: 323 }}>
      <Box display="flex" gap={1} alignItems="baseline">
        <StyledDot color={color} />
        <Typography variant="h6">
          ผู้เรียน Enroll ({E_LEARNING_LEARNER_TYPE_LABEL[type]})
        </Typography>
      </Box>

      <InfoPreview
        label="ทั้งหมด"
        count={totalCount}
        unit="คน"
        bgButtonColor={secondaryColor}
        handleClick={() => handleOpenLearnerDrawer(type)}
        hideButton={!hasAdminPermission}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="256px"
      >
        {totalCount > 0 ? (
          <PieChart dataSet={datasets} idx={idx} />
        ) : (
          <Box display="flex" flexDirection="column">
            <InboxTwoTone
              sx={{
                mx: 'auto',
                color: theme?.palette?.text?.silver,
              }}
            />
            <Typography variant="body2" color="text.lightGray">
              ไม่พบข้อมูล
            </Typography>
          </Box>
        )}
      </Box>
      {_.map(progress, (pg, id) => {
        return <StatusInfoPreview {...pg} key={id} />
      })}
    </StyledDashboardCard>
  )
}

export default EnrollOverviewCard
