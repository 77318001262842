import {
  Table,
  TableBody,
  TableCell,
  Box,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { RowItem } from './RowItem'
import { shallowEqual, useSelector } from 'react-redux'

export const MonthlyFinancialItem = ({
  month,
  monthLabel,
  data,
  idxBudget,
  type,
}) => {
  const theme = useTheme()
  const { errorBudget, errorRevised, yearBudget } = useSelector(
    (state) => ({
      errorBudget: _.find(state.crud.formErrors, {
        path: `budgetDetails[${idxBudget}].data[0].value`,
      }),
      errorRevised: _.find(state.crud.formErrors, {
        path: `budgetDetails[${idxBudget}].data[1].value`,
      }),
      yearBudget: state.crud.formData.yearBudget,
    }),
    shallowEqual,
  )
  const today = new Date()
  const currentMonth = today.getMonth() + 1
  const currentYear = today.getFullYear()
  let isActiveMonth = false
  if (type === 'view') {
    if (yearBudget?.value === currentYear) {
      isActiveMonth = month === currentMonth
    } else if (yearBudget?.value < currentYear) {
      isActiveMonth = month === 12
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          maxWidth: 300,
          border: `1px solid ${theme.palette?.blue?.blueLine}`,
        }}
      >
        <Table>
          <TableHead
            sx={{
              borderBottom: `1px solid ${theme.palette?.blue?.blueLine}`,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  padding: 0,
                  background: isActiveMonth
                    ? 'rgba(240, 118, 98, 0.20)'
                    : theme.palette?.common?.white,
                }}
                colSpan={2}
              >
                <Typography fontSize={18} fontWeight={500} mx={1} my={2}>
                  {monthLabel}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              '& .MuiTableRow-root:last-of-type .MuiTableCell-root': {
                borderBottom: 'none',
              },
              '& .MuiTableCell-root:first-of-type': {
                pr: 1.5,
                background: isActiveMonth
                  ? 'rgba(240, 118, 98, 0.50)'
                  : theme.palette?.info?.background,
              },
              '& .MuiTableCell-root:last-of-type': {
                px: 1,
                background: isActiveMonth
                  ? 'rgba(240, 118, 98, 0.20)'
                  : theme.palette?.common?.white,
              },
            }}
          >
            {_.map(data, (item, idx) => (
              <RowItem
                key={idx}
                {...item}
                idx={idx}
                idxBudget={idxBudget}
                type={type}
                isActiveMonth={isActiveMonth}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      {(!_.isEmpty(errorBudget) || !_.isEmpty(errorRevised)) && (
        <Typography variant="body2" color="error">
          {errorBudget?.message || errorRevised?.message}
        </Typography>
      )}
    </Box>
  )
}
