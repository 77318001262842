import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import MuiDrawer from '@mui/material/Drawer'

const drawerWidth = 300

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme?.transitions?.create('width', {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.enteringScreen,
  }),
  overflowX: 'hidden',
  '::-webkit-scrollbar': { display: 'none' },
})

const closedMixin = (theme) => ({
  transition: theme?.transitions?.create('width', {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
})
const closedMixinMobile = (theme) => ({
  transition: theme?.transitions?.create('width', {
    easing: theme?.transitions?.easing?.sharp,
    duration: theme?.transitions?.duration?.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 0),
  ...theme?.mixins?.toolbar,
}))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'pre',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const DrawerMobile = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'pre',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixinMobile(theme),
    '& .MuiDrawer-paper': closedMixinMobile(theme),
  }),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledBackG = styled(Box)(({ open }) => ({
  position: 'fixed',
  display: open ? 'flex' : 'contents',
  justifyContent: 'center',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1199,
}))
