import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from '../../../../../components/Input/Select'
import { learnerTypeOption } from '../model'
import { StyledFormContainer } from '../Styled'
import {
  handleDownloadTemplate,
  handleImportFile,
  handleUserTypeChange,
} from './events'
import { StyledInputImport } from './Styled'

const UploadForm = () => {
  const { userType, fileName, processAgent } = useSelector(
    (state) => ({
      userType: state.manageClassLearner.userType,
      fileName: state.manageClassLearner.fileName,
      processAgent: state.manageClassLearner.processAgent,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <>
      <StyledFormContainer>
        <Select
          dataTestid="select-learnerType"
          labelText="ประเภทของผู้เรียน"
          placeholder="เลือกประเภทผู้เรียน"
          type="basic"
          required
          disabled={processAgent}
          showTextError={false}
          options={learnerTypeOption}
          value={userType}
          handleChange={(e) => dispatch(handleUserTypeChange(e))}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            data-testid="btn-download-template"
            variant="contained"
            size="s"
            disabled={userType === ''}
            onClick={() => dispatch(handleDownloadTemplate(userType))}
          >
            ดาวน์โหลด Template
          </Button>
          <Typography variant="body2" color="text.secondary">
            เพิ่มได้ไม่เกิน 50 รายชื่อ/ครั้ง
          </Typography>
        </Box>
      </StyledFormContainer>
      <Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          รายชื่อผู้เรียน
        </Typography>
        <label
          style={{ display: 'flex', gap: 16, alignItems: 'center' }}
          htmlFor="import-template"
        >
          <Button
            variant="contained"
            size="s"
            component="span"
            disabled={processAgent}
          >
            เลือกไฟล์
          </Button>
          <StyledInputImport
            disabled={processAgent}
            data-testid="import-template"
            id="import-template"
            accept=".xlsx, .xls, .csv"
            type="file"
            onChange={(e) => dispatch(handleImportFile(e))}
          />
          <Typography variant="body2" color="text.secondary">
            {fileName}
          </Typography>
        </label>
      </Box>
    </>
  )
}

export default UploadForm
