import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'

export const handleDeleteContentItem = (index) => {
  const state = store.getState().crud

  const contents = _.cloneDeep(state.formData.contents).filter(
    (_, i) => i !== index,
  )

  store.dispatch(
    setFieldValue({
      key: `formData.contents`,
      value: contents,
    }),
  )
}
