import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Select from '../../../components/Input/Select'
import { closeDialog } from '../../../redux/slices/dialog'
import {
  StyledCancelButton,
  StyledDialog,
  StyledDialogActions,
  StyledInputImport,
  StyledLoading,
  StyledLoadingButton,
} from './Styled'
import { agentTypeOptions, csTypeOptions } from './model'
import {
  handleAgentTypeChange,
  handleDownloadTemplate,
  handleEnableButton,
  handleImportFile,
  handleSubmitAdd,
  handleSuccess,
} from './events'

const UploadPointDialog = () => {
  const dispatch = useDispatch()
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const {
    isLoadingContent,
    agentType,
    file,
    fileName,
    processAgent,
    success,
    total,
    isSuccess,
    downloading,
    agentPageType,
  } = useSelector(
    (state) => ({
      isLoadingContent: state.agentProfile.uploadDialog.isLoading,
      agentType: state.agentProfile.uploadDialog.agentType,
      file: state.agentProfile.uploadDialog.file,
      fileName: state.agentProfile.uploadDialog.fileName,
      processAgent: state.agentProfile.uploadDialog.processAgent,
      success: state.agentProfile.uploadDialog.success,
      total: state.agentProfile.uploadDialog.total,
      isSuccess: state.agentProfile.uploadDialog.isSuccess,
      downloading: state.agentProfile.uploadDialog.downloading,
      agentPageType: state.agentProfile.agentPageType,
    }),
    shallowEqual,
  )
  useEffect(() => {
    dispatch(handleEnableButton({ agentType, file }))
  }, [agentType, file])
  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">อัปโหลดคะแนน</Typography>
      </Box>
      <StyledDialog>
        <Select
          labelText="ประเภทของ Agent"
          placeholder="เลือกประเภท Agent"
          type="basic"
          required
          disabled={processAgent}
          showTextError={false}
          options={agentPageType === 'AGENT' ? agentTypeOptions : csTypeOptions}
          value={agentType}
          handleChange={(e) => dispatch(handleAgentTypeChange(e))}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LoadingButton
            loading={downloading}
            variant="contained"
            size="s"
            onClick={() => dispatch(handleDownloadTemplate())}
          >
            ดาวน์โหลด Template
          </LoadingButton>
          <Typography variant="body2" color="text.secondary">
            เพิ่มได้ไม่เกิน 50 รายชื่อ/ครั้ง
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            รายชื่อ Agent
          </Typography>
          <label
            style={{ display: 'flex', gap: 16, alignItems: 'center' }}
            htmlFor="import-template"
          >
            <Button
              variant="contained"
              size="s"
              component="span"
              disabled={processAgent}
            >
              เลือกไฟล์
            </Button>
            <StyledInputImport
              disabled={processAgent}
              id="import-template"
              accept=".xlsx, .xls, .csv"
              type="file"
              onChange={(e) => dispatch(handleImportFile(e))}
            />
            <Typography variant="body2" color="text.secondary">
              {fileName}
            </Typography>
          </label>
        </Box>
        {processAgent && (
          <Box sx={{ mt: 3 }}>
            <LinearProgress
              variant="determinate"
              value={(success / total) * 100}
            />
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography>
                กำลังตรวจสอบและอัปเดตข้อมูล Agent ({agentType})
              </Typography>
              <Typography
                sx={{ ml: 1.5, fontWeight: 'bold', color: 'primary.main' }}
              >
                {success}/{total}
              </Typography>
            </Box>
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions>
        {!isLoading && (
          <StyledCancelButton
            onClick={() => dispatch(closeDialog())}
            color="primary"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          loading={isLoading}
          onClick={() => dispatch(handleSubmitAdd({ agentType, file }))}
          color="primary"
          disabled={disableButton}
          endIcon={<></>}
        >
          บันทึก
        </StyledLoadingButton>
      </StyledDialogActions>
      <StyledLoading isLoading={isLoadingContent}>
        <CircularProgress />
      </StyledLoading>
    </>
  )
}

export default UploadPointDialog
