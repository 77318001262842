import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    paddingRight: '25px',
  },
}))
