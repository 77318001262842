import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import _ from 'lodash'
import Videocam from '@mui/icons-material/Videocam'
import { ContentRow } from '../View'
import { StyledBoxVideo } from '../Styled'
import PreviewVideoModal from './PreviewVideoModal'

const PreviewVideoContent = ({ url }) => {
  const [isOpen, setOpen] = useState(false)
  if (_.isEmpty(url)) return <></>
  return (
    <ContentRow title="วีดีโอตัวอย่าง">
      <StyledBoxVideo
        sx={{ my: 1, cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <ReactPlayer
          light
          volume={0}
          playing={false}
          width="150px"
          height="100px"
          style={{ pointerEvents: 'none' }}
          url={url}
        />
        <Videocam />
      </StyledBoxVideo>
      <PreviewVideoModal
        open={isOpen}
        link={url}
        onClose={() => setOpen(false)}
      />
    </ContentRow>
  )
}

export default PreviewVideoContent
