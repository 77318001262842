import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import SwapVertOutlined from '@mui/icons-material/SwapVertOutlined'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'

import TextInput from '../../../../../components/Input/TextInput'

import { BoxIcon, BoxScore, BoxInput, BoxScoreInput } from './Styled'
import {
  handleChangeScore,
  handleDeleteQuestion,
  handleSwapQuestion,
} from './event'
import { TooltipForm } from '../Styled'
import { handleNumberInput } from '../Answer/event'

const Index = ({ keyQuestion, questionType }) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState({
    swap: false,
    delete: false,
  })

  const { question, disableForm, indexQuestion } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      indexQuestion: _.findIndex(state.eTestingForm.questions, [
        'key',
        keyQuestion,
      ]),
    }),
    shallowEqual
  )
  const { score } = question

  return (
    <>
      <Divider />
      <BoxScore
        isMultiple={
          questionType !== 'MULTIPLE' && questionType !== 'TRUE_FALSE'
            ? true
            : false
        }
      >
        {questionType !== 'MULTIPLE' && questionType !== 'TRUE_FALSE' && (
          <BoxScoreInput>
            <BoxInput>
              <TextInput
                id="input-score"
                name="score"
                type="basic"
                endAdornmentText={'คะแนน'}
                disabled={disableForm}
                value={score}
                inputProps={{ onInput: handleNumberInput }}
                onChange={(e) =>
                  dispatch(
                    handleChangeScore({
                      keyQuestion,
                      key: `score`,
                      value: e.target.value,
                    })
                  )
                }
              />
            </BoxInput>
            {questionType?.includes('FILL_IN_BLANK') && (
              <Typography mt={1}>ต่อคำตอบที่ถูกต้อง</Typography>
            )}
            {questionType?.includes('MATCHING') && (
              <Typography mt={1}>ต่อคู่ที่ถูกต้อง</Typography>
            )}
            {questionType?.includes('SEQUENCE') && (
              <Typography mt={1}>ต่อคำตอบที่ถูกต้อง</Typography>
            )}
          </BoxScoreInput>
        )}

        <BoxIcon>
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">ย้ายไป</Typography>}
            onClick={() =>
              !disableForm &&
              dispatch(handleSwapQuestion(keyQuestion, indexQuestion))
            }
          >
            <IconButton
              onMouseLeave={() =>
                setIsHover({
                  ...isHover,
                  swap: false,
                })
              }
              onMouseOver={() =>
                setIsHover({
                  ...isHover,
                  swap: true,
                })
              }
            >
              <SwapVertOutlined color={isHover.swap ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>

          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">ลบ</Typography>}
          >
            <IconButton
              onMouseLeave={() =>
                setIsHover({
                  ...isHover,
                  delete: false,
                })
              }
              onMouseOver={() =>
                setIsHover({
                  ...isHover,
                  delete: true,
                })
              }
              onClick={() =>
                !disableForm && dispatch(handleDeleteQuestion({ keyQuestion }))
              }
              data-testid="icon-delete-question"
            >
              <DeleteOutlineOutlined
                color={isHover.delete ? 'primary' : 'action'}
              />
            </IconButton>
          </TooltipForm>
        </BoxIcon>
      </BoxScore>
    </>
  )
}

export default Index
