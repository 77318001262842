import React from 'react'
import { useHistory } from 'react-router-dom'
import { StyledHeaderContainer } from '../Styled'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { path } from '../../../../constants/path'

const Header = () => {
  const history = useHistory()

  return (
    <StyledHeaderContainer>
      <Button
        startIcon={<AddIcon />}
        dataTestid="btn-create"
        variant="contained"
        onClick={() => history.push(`${path.settingPoint}/form`)}
        size="m"
      >
        สร้างคะแนน
      </Button>
    </StyledHeaderContainer>
  )
}

export default Header
