import * as yup from 'yup'
import _ from 'lodash'
import { validateVideo } from '../../../../ELearning/Module/Form/TypeContent/EmbedContent/events'

export const schemaPreview = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อ')
    .max(255, 'ชื่อกรอกได้ไม่เกิน 255 ตัวอักษร'),
  contents: yup
    .array()
    .nullable(false)
    .min(1, 'กรุณาเพิ่มเนื้อหาอย่างน้อย 1 อย่าง')
    .typeError('กรุณาเพิ่มเนื้อหาอย่างน้อย 1 อย่าง')
    .of(
      yup.object().shape({
        type: yup.string().required('Type is required.'),
        content: yup
          .object()
          .when('type', {
            is: 'KB',
            then: yup.object().shape({
              question: yup.string().required('กรุณากรอกคำถาม'),
              answer: yup.string().required('กรุณากรอกคำตอบ'),
              files: yup.array().min(1, 'กรุณาอัปโหลดไฟล์'),
            }),
          })
          .when('type', {
            is: 'LINK',
            then: yup.object().shape({
              link: yup
                .string()
                .test(
                  'validate-link',
                  'Link สำหรับวิดีโอตัวอย่างต้องมากจาก YouTube หรือ Vimeo เท่านั้น',
                  (value) => _.isEmpty(value) || validateVideo(value),
                )
                .max(
                  3000,
                  'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 3000 ตัวอักษร',
                )
                .required('กรุณากรอก URL'),
              detail: yup.string().when('isHasDetail', {
                is: 'ACTIVE',
                then: yup
                  .string()
                  .required('กรุณากรอกคำอธิบาย ภายใน 255 ตัวอักษร')
                  .max(255, 'คำอธิบาย กรอกได้ไม่เกิน 255 ตัวอักษร'),
                otherwise: yup.string(),
              }),
            }),
          })

          .when('type', {
            is: 'IMAGE',
            then: yup.object().shape({
              files: yup.array().min(1, 'กรุณาอัปโหลดไฟล์'),
            }),
          })
          .when('type', {
            is: 'FILE',
            then: yup.object().shape({
              files: yup.array().min(1, 'กรุณาอัปโหลดไฟล์'),
            }),
          })
          .when('type', {
            is: 'TEXT',
            then: yup.object().shape({
              text: yup.string().required('กรุณากรอกข้อความ'),
            }),
          }),
      }),
    ),
  eContentTag: yup
    .array()
    .nullable(false)
    .min(1, 'กรุณาเลือก Tag')
    .typeError('กรุณาเลือก Tag'),
  startPublishDate: yup.string().required('กรุณากรอกวันเริ่มต้น'),
})
