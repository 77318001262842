import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import { agentProfileETesting } from '../../../../../constants/manage/agentProfile'
import _ from 'lodash'
import SearchCustom from '../Search'
import {
  startLoading,
  stopLoading,
  setPagination,
  setSearch,
} from '../../../../../redux/slices/manage/agentProfile'
import { BoxHeder } from '../Course/Styled'
import { onSort } from '../../../../../utils/lib'
import { fetchETesting } from './events'
import { Row } from '../../../../../components/Layout/Styled'
import { ShowTotal } from '../../../../../components/redux/Table2'

const ETestingSection = ({ userId }) => {
  const dispatch = useDispatch()
  const [head, setHead] = useState([])

  const { search, pagination, data } = useSelector(
    (state) => ({
      search: state.agentProfile.search,
      pagination: state.agentProfile.pagination,
      data: state.agentProfile.initialState,
    }),
    shallowEqual,
  )
  const totalCount = _.get(data, 'eTesting.totalCount', 0)

  const {
    handleETestingSearch,
    eTestingSearch,
    orderETestingBy,
    sortETestingBy,
  } = search
  const { eTestingLimit, eTestingPage } = pagination

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(
      setSearch({
        key: 'orderETestingBy',
        value: orderBy,
      }),
    )
    dispatch(
      setSearch({
        key: 'sortETestingBy',
        value: sort,
      }),
    )
    dispatch(
      fetchETesting({
        limit: eTestingLimit,
        page: eTestingPage,
        order: orderBy,
        sort: sort,
        userProfileUuid: userId,
        keyword: _.defaultTo(eTestingSearch, ''),
      }),
    )
    setHead(head)
  }

  useEffect(() => {
    dispatch(
      fetchETesting({
        limit: eTestingLimit,
        page: eTestingPage,
        order: orderETestingBy ?? 'DESC',
        sort: sortETestingBy ?? 'updatedAt',
        userProfileUuid: userId,
        keyword: _.defaultTo(eTestingSearch, ''),
      }),
    )
  }, [pagination, handleETestingSearch, userId])

  useEffect(() => {
    setHead(agentProfileETesting)
  }, [])

  return (
    <Card sx={{ pb: 3 }}>
      <BoxHeder>
        <Row gap={2}>
          <Typography variant="h6">ประวัติการทำแบบทดสอบ</Typography>
          <ShowTotal allCount={totalCount} />
        </Row>

        <Box sx={{ display: 'flex', gap: '24px' }}>
          <SearchCustom searchKey="eTestingSearch" />
        </Box>
      </BoxHeder>
      <NormalTable
        headCells={head}
        listRows={_.get(data, 'eTesting.result', [])}
        totalRows={totalCount}
        showPagination
        isHiddenOptions
        startLoading={startLoading}
        stopLoading={stopLoading}
        onRequestSort={onRequestSort}
        handlePagination={(events) =>
          dispatch(
            setPagination({
              key: 'eTestingPage',
              value: events.page,
            }),
          )
        }
      />
    </Card>
  )
}

export default ETestingSection
