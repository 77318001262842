import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
export const BoxLabel = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const BoxMenu = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'auto',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(3),
    width: '100% !important',
  },
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))
export const BoxMenuSelect = styled(Box)(() => ({
  width: 240,
}))
