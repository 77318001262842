import React, { useContext, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledRespRow,
} from '../Styled'
import { breadcrumbFormList } from '../model'
import Select from '../../../../../src/components/Input/Select'
import TextInput from '../../../../../src/components/Input/TextInput'
import TextField from '@mui/material/TextField'
import { DialogContext } from '../../../../context/dialog-context'
import _ from 'lodash'
import { initialValues } from '../../../../redux/slices/eExamination/form/model'
import {
  handleChangeEvent,
  onCancelClickDialog,
  fetchStationData,
  handleChange,
  handleFormSubmit,
  fetchStockDetail,
  resetStockData,
} from './event'
import HeaderList from './Header'
import AlertDialog from '../../../../components/AlertDialog'
import Switch from '@mui/material/Switch'
import {
  STOCK_TYPE_TEXT,
  STOCK_STATUS,
  STOCK_TYPE,
} from '../../../../constants/stock'
import {
  stockValidationSchema,
  stockValidationProviderSchema,
} from '../../../../constants/stock'
import { useParams } from 'react-router-dom'
import { openDialog } from '../../../../redux/slices/dialog'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const { warehouseType, isLoading, stationList, stockForm } = useSelector(
    (state) => ({
      warehouseType: state.stockList.warehouseType,
      isLoading: state.stockList.isLoading,
      stationList: state.stockList.stationList,
      stockForm: state.stockList.stockForm,
    }),
    shallowEqual,
  )

  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog

  const validationSchema =
    warehouseType === STOCK_TYPE.PROVIDER ||
    _.get(stockForm, 'warehouseType') === STOCK_TYPE.PROVIDER
      ? stockValidationProviderSchema
      : stockValidationSchema

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      dispatch(
        openDialog({
          open: false,
          variant: 'save',
          title: 'ยืนยันบันทึกข้อมูล',
          icon: '',
          message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
          handleConfirm: () => {
            dispatch(handleFormSubmit(stockForm, warehouseType, uuid, actions))
          },
        }),
      )
    },
  })

  useEffect(() => {
    dispatch(fetchStationData())
    if (uuid) {
      dispatch(fetchStockDetail(uuid, formik))
    } else {
      dispatch(resetStockData(formik, warehouseType))
    }
  }, [])

  const title = uuid ? 'แก้ไข' : 'สร้าง'
  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">{title} คลังทรัพย์สิน</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbFormList({ uuid })} />
          </Box>
          <HeaderList onClickSubmit={() => formik.handleSubmit} />
        </StyledHeader>

        <StyledCard sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            ข้อมูลพื้นฐาน
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Typography variant="body1b">ประเภท คลังทรัพย์สิน </Typography>
            <Typography>
              {
                STOCK_TYPE_TEXT[
                  !_.isEmpty(stockForm.warehouseType) &&
                  !_.isNil(stockForm.warehouseType)
                    ? stockForm.warehouseType
                    : warehouseType
                ]
              }
            </Typography>
          </Box>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  id="warehouseName"
                  name="warehouseName"
                  labelText="ชื่อคลังทรัพย์สิน"
                  placeholder="ระบุชื่อ"
                  type="basic"
                  textError={formik?.errors?.warehouseName}
                  required
                  value={_.get(stockForm, 'warehouseName')}
                  onChange={(e) => {
                    formik.setFieldValue('warehouseName', e.target.value)
                    dispatch(handleChangeEvent(e))
                  }}
                  onBlur={formik.handleBlur}
                />
                <Select
                  name="station"
                  labelText="Station ประจำ"
                  required
                  options={stationList}
                  getOptionLabel={(option) => option.label}
                  placeholder="เลือก Station"
                  value={_.get(stockForm, 'station')}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-stock-station',
                      }}
                    />
                  )}
                  handleChange={(e) => {
                    formik.setFieldValue('station', e.target.value)
                    dispatch(handleChangeEvent(e))
                  }}
                  textError={formik?.errors?.station}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          {(warehouseType === STOCK_TYPE.PROVIDER ||
            _.get(stockForm, 'warehouseType') === STOCK_TYPE.PROVIDER) && (
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow sx={{ width: '50%', pr: 1 }} isInput>
                  <TextInput
                    id="vendor"
                    name="vendor"
                    labelText="Vendor"
                    placeholder="ระบุชื่อ Vendor"
                    type="basic"
                    textError={formik?.errors?.vendor}
                    required
                    value={_.get(stockForm, 'vendor')}
                    onChange={(e) => {
                      formik.setFieldValue('vendor', e.target.value)
                      dispatch(handleChangeEvent(e))
                    }}
                    onBlur={formik.handleBlur}
                  />
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              สถานะ
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Switch
                id="status"
                name="status"
                defaultChecked
                checked={
                  _.get(stockForm, 'status') === STOCK_STATUS.ACTIVE
                    ? true
                    : false
                }
                onChange={(e) => {
                  const stockStatus = e.target.checked
                    ? STOCK_STATUS.ACTIVE
                    : STOCK_STATUS.INACTIVE
                  formik.setFieldValue('status', stockStatus)
                  dispatch(handleChange('status', stockStatus))
                }}
                inputProps={{
                  'data-testid': 'status',
                }}
              />
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ lineHeight: 2 }}
              >
                <>
                  {_.get(stockForm, 'status') === STOCK_STATUS.ACTIVE
                    ? 'เปิดการใช้งาน (Active)'
                    : 'ปิดการใช้งาน (Inactive)'}
                </>
              </Typography>
            </Box>
          </Box>
          <StyledColumnFlex sx={{ mb: 3 }}>
            <StyledColumnNoGap noGap></StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex sx={{ mb: 3 }}>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  id="remark"
                  name="remark"
                  type="basic"
                  labelText="หมายเหตุ"
                  multiline={true}
                  rows={3}
                  value={_.get(stockForm, 'remark')}
                  onChange={(e) => {
                    formik.setFieldValue('remark', e.target.value)
                    dispatch(handleChangeEvent(e))
                  }}
                  onBlur={formik.handleBlur}
                  textError={formik?.errors?.remark}
                  placeholder={'กรอกหมายเหตุ'}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
        </StyledCard>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={onCancelClickDialog}
          title={dialog.title}
          content={dialog.content}
          variant={'saveExam'}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={isLoading}
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Index
