import _ from 'lodash'
import { store } from '../../../../App'
import { overviewEnum } from '../enums/drawerEnum'

const prepareDataList = (
  transactionNumber = '',
  recordNumber = '',
  totalTransactions = 0,
) => {
  const percent =
    transactionNumber > 0
      ? ((transactionNumber / totalTransactions) * 100).toFixed(2)
      : 0
  return [
    {
      label: 'Transactions',
      number: transactionNumber,
      unit: '',
      percent: `${percent} %`,
      sxBox: {
        borderRight: 'solid 1px #CCCCCC',
        paddingRight: '8px',
        marginRight: '8px',
      },
    },
    {
      label: 'Records',
      number: recordNumber,
      unit: '',
      percent: ' ',
      sxBox: '',
    },
  ]
}
export const transactionModel = () => {
  const { apiRcmsDashboard } = store.getState().crud
  const overview = apiRcmsDashboard?.overview || overviewEnum
  return [
    {
      label: 'Manual',
      labelVariant: 'body2b',
      background: '#EBF6F3',
      dotColor: '#32A287',
      value: 'Manual',
      width: '259px',
      dataList: prepareDataList(
        _.get(overview?.transactions?.manual, 'transactions', 0),
        _.get(overview?.transactions?.manual, 'records', 0),
        _.get(overview?.transactions, 'totalTransactions', 0),
      ),
    },
    {
      label: 'Admin',
      labelVariant: 'body2b',
      background: '#BED0ED',
      dotColor: '#4976BA',
      value: 'Admin',
      width: '259px',
      dataList: prepareDataList(
        _.get(overview?.transactions?.admin, 'transactions', 0),
        _.get(overview?.transactions?.admin, 'records', 0),
        _.get(overview?.transactions, 'totalTransactions', 0),
      ),
    },
    {
      label: 'Auto',
      labelVariant: 'body2b',
      background: '#FFDACA',
      dotColor: '#E99E7D',
      value: 'Auto',
      width: '259px',
      dataList: prepareDataList(
        _.get(overview?.transactions?.auto, 'transactions', 0),
        _.get(overview?.transactions?.auto, 'records', 0),
        _.get(overview?.transactions, 'totalTransactions', 0),
      ),
    },
    {
      label: 'Failed',
      labelVariant: 'body2b',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      value: 'Failed',
      width: '259px',
      dataList: prepareDataList(
        _.get(overview?.transactions?.failed, 'transactions', 0),
        _.get(overview?.transactions?.failed, 'records', 0),
        _.get(overview?.transactions, 'totalTransactions', 0),
      ),
    },
  ]
}
