import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../components/Input/TextInput'
import { StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import ActiveSwitch from '../../../components/ActiveSwitch'
import { handleInputBody, handleSelector, handleSwitchBody } from '../events'
import { StyledRowForm } from './Styled'

const Trainer = () => {
  const keyField = 'trainerInformation'
  const trainerInformation = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { errors } = useFormikContext()

  return (
    <StyledSection sx={{ pb: 0 }}>
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">Section 3 | Trainer Information</Typography>
        <ActiveSwitch
          name="status"
          dataTestId={`active-section-3`}
          checked={_.get(trainerInformation, 'status', false)}
          onChange={(e) => dispatch(handleSwitchBody(e, keyField))}
        />
      </StyledRow>
      <StyledRowForm>
        <TextInput
          required
          name="title1"
          type="basic"
          labelText="Title"
          id="input-title1"
          placeholder="วรรคที่ 1"
          value={_.get(trainerInformation, 'title1', '')}
          textError={_.get(errors, `${keyField}.title1`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />
        <TextInput
          name="title2"
          type="basic"
          id="input-title2"
          placeholder="วรรคที่ 2"
          value={_.get(trainerInformation, 'title2', '')}
          textError={_.get(errors, `${keyField}.title2`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />
        <TextInput
          name="title3"
          type="basic"
          id="input-title3"
          placeholder="วรรคที่ 3"
          value={_.get(trainerInformation, 'title3', '')}
          textError={_.get(errors, `${keyField}.title3`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />
      </StyledRowForm>
    </StyledSection>
  )
}

export default Trainer
