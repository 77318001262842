import _ from 'lodash'
import { getManageClassLearnerDetail } from '../../../../services/manageClass/learnerDetail'
import * as slices from '../../../../redux/slices/manageClassLearnerDetail'
import { setFetchDataList } from '../../../../redux/slices/manageClassDocument'
import { getManageClassLearnerDocument } from '../../../../services/manageClass/document'

export const fetchDataDetail = (uuid) => async (dispatch) => {
  dispatch(slices.startLoading())

  const result = await dispatch(getManageClassLearnerDetail(uuid))
  dispatch(slices.setFetchDataDetail(_.get(result, 'payload.data', {})))

  const resultDocument = await dispatch(getManageClassLearnerDocument(uuid))
  dispatch(setFetchDataList(_.get(resultDocument, 'payload.data', {})))

  dispatch(slices.stopLoading())
}
