import { handleKeyState } from '../../../../../services/eTesting/form'
import { setChangeQuestionByKey } from '../../../../../redux/slices/eTestingForm'

// dispatch

export const handelDelete = (props) => (dispatch) => {
  const { keyQuestion, key } = props

  if (key === 'url') {
    dispatch(setChangeQuestionByKey(props))
    dispatch(setChangeQuestionByKey({ ...props, key: 'mediaType' }))
  } else {
    dispatch(setChangeQuestionByKey(props))
  }

  const resetProps = { keyQuestion, value: null }

  dispatch(
    setChangeQuestionByKey({
      ...resetProps,
      key: handleKeyState(key, 'loadingProgress'),
    })
  )
  dispatch(
    setChangeQuestionByKey({
      ...resetProps,
      key: handleKeyState(key, 'uploadError'),
    })
  )
}
