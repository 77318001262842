import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { onFilterClick } from '../List/event'
import { onFilterClick as onFilterClickVersion } from '../Background/event'
import { onFilterClick as onFilterClickVersionType } from '../Version/event'

export const handleFiltering =
  (table, filter, drawerType, id) => (dispatch) => {
    dispatch(setToggleFilterDrawer(false))

    if (drawerType == 'background')
      dispatch(onFilterClickVersion(table, filter, id))
    else if (drawerType == 'versions') {
      dispatch(onFilterClickVersionType(table, filter, id))
    } else dispatch(onFilterClick(table, filter))
  }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    toDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}
