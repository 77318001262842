import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import HeaderList from './Header'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from '../Styled'
import FilterDrawer from '../FilterDrawer'
import { breadcrumbList } from '../model'
import { validateEPermission } from '../../../utils/lib'

const Index = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const hasCreatePermission = validateEPermission({
    module: 'E_TESTING_SETTING',
    permission: 'CREATE',
  })

  useEffect(() => {
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        setInitial
      )
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList('fetch', table, page, status, filterProp, searchText)
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">E-Testing</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>

          {hasCreatePermission && <HeaderList />}
        </StyledHeader>

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="etesting"
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
