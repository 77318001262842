import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  SliderItem,
  StyledColumn,
  StyledGrid,
  StyledImg,
  StyledRow,
} from '../../../Styled'
import useTheme from '@mui/system/useTheme'
import _ from 'lodash'
import {
  ROOM_FUNCTION,
  ROOM_TYPE,
} from '../../../../../constants/MasterData/meetingRoom'
import { Close } from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  getRoomtype,
  getRoomtypeText,
  handleCloseImageSlider,
  handleOpenImageSlider,
} from '../../event'
import CustomCarousel from '../../../../../components/Carousel'

const ColumnLabels = ({ title, label, tooltipValue = '' }) => {
  return (
    <StyledColumn sx={{ minWidth: 92, alignItems: 'flex-start' }}>
      <Typography variant="body2" color="text.gray">
        {title}
      </Typography>
      <Tooltip title={tooltipValue} typeColor="primary" arrow placement="right">
        <Typography variant="body1b">{label}</Typography>
      </Tooltip>
    </StyledColumn>
  )
}

export const RoomDetails = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
    floor,
    sqm,
    image,
    roomFunction,
    roomType,
    remark,
    meetingRoomEquipment,
    capacityClassroom,
    capacityGroup,
    capacityTheater,
    capacityUShape,
  } = props

  const { isDrawerImageSliderOpen, imageSliderSelectedIndex } = useSelector(
    (state) => ({
      isDrawerImageSliderOpen: state.roomManagement.isDrawerImageSliderOpen,
      imageSliderSelectedIndex: state.roomManagement.imageSliderSelectedIndex,
    }),
    shallowEqual,
  )

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} md={6}>
        {image?.length === 0 && (
          <StyledImg
            sx={{ width: '100%', height: { xs: 150, sm: 327 } }}
            src="/image/default_room_image.svg"
          />
        )}
        {image?.length > 0 && (
          <StyledImg
            onClick={() => dispatch(handleOpenImageSlider(0))}
            sx={{ width: '100%', height: { xs: 150, sm: 327 } }}
            src={`${window.__env__.REACT_APP_API_URL}/file${image[0]?.imageKey}`}
          />
        )}

        <StyledGrid>
          {_.map(image.slice(1, 4), (img, idx) => {
            if (idx === 2 && image.length > 4) {
              return (
                <Box
                  sx={{ flex: 1 }}
                  onClick={() => dispatch(handleOpenImageSlider(idx + 1))}
                >
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography color="text.white" variant="h2">
                        + {image.length - 4}
                      </Typography>
                    </div>

                    <StyledImg
                      sx={{
                        maxWidth: '100%',
                        width: '100%',
                        display: 'flex',
                        height: { xs: 80, sm: 141 },
                      }}
                      key={idx}
                      src={`${window.__env__.REACT_APP_API_URL}/file${img?.imageKey}`}
                    />
                  </div>
                </Box>
              )
            }
            return (
              <Box sx={{ flex: '0 0 32.5%' }}>
                <StyledImg
                  onClick={() => dispatch(handleOpenImageSlider(idx + 1))}
                  sx={{
                    maxWidth: '100%',
                    width: '100%',
                    display: 'flex',
                    height: { xs: 80, sm: 141 },
                  }}
                  key={idx}
                  src={`${window.__env__.REACT_APP_API_URL}/file${img?.imageKey}`}
                />
              </Box>
            )
          })}
        </StyledGrid>
        <Dialog
          open={isDrawerImageSliderOpen}
          onClose={() => dispatch(handleCloseImageSlider())}
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            รูปภาพห้องประชุม
            <IconButton
              sx={{ color: theme?.palette?.common?.black }}
              data-testid="btn-close-drawer"
              component="span"
              onClick={() => dispatch(handleCloseImageSlider())}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <CustomCarousel
              autoPlay={false}
              selectedItem={imageSliderSelectedIndex}
              showArrows={true}
            >
              {_.map(image, (img, idx) => (
                <SliderItem
                  key={idx}
                  src={`${window.__env__.REACT_APP_API_URL}/file${img.imageKey}`}
                />
              ))}
            </CustomCarousel>
          </DialogContent>
        </Dialog>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            border: `1px solid ${theme.palette?.blue?.blueLine}`,
            borderRadius: 3,
            px: 3,
            py: 2,
          }}
        >
          <Typography variant="h6">รายละเอียดห้อง</Typography>
          <Grid sx={{ mb: { sm: 2 } }} columnSpacing={2} container>
            <Grid xs={12} sm={4} item>
              <ColumnLabels title={'ชั้นห้องอบรม'} label={floor} />
            </Grid>
            <Grid xs={12} sm={4} item>
              <ColumnLabels
                title={'รองรับได้'}
                label={`${Number(capacityClassroom).toLocaleString()} คน`}
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <ColumnLabels
                title={'ฟังก์ชันห้อง'}
                label={ROOM_FUNCTION[roomFunction]}
              />
            </Grid>
          </Grid>
          <Grid columnSpacing={2} container>
            <Grid xs={12} sm={4} item>
              <ColumnLabels
                title={'ขนาดห้อง'}
                label={`${Number(sqm).toLocaleString()} ตรม.`}
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <ColumnLabels
                tooltipValue={
                  getRoomtype({
                    capacityGroup,
                    capacityTheater,
                    capacityUShape,
                  }).length > 0 ? (
                    <ul style={{ padding: 0, margin: 0, paddingLeft: 16 }}>
                      {getRoomtype({
                        capacityGroup,
                        capacityTheater,
                        capacityUShape,
                      }).map((item, index) => (
                        <li key={index}>
                          <Typography variant="body2">{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ''
                  )
                }
                title={'รูปแบบการจัดห้อง'}
                label={`Classroom ${getRoomtypeText({
                  capacityGroup,
                  capacityTheater,
                  capacityUShape,
                })}`}
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <ColumnLabels
                title={'ประเภทห้อง'}
                label={
                  !_.isEmpty(ROOM_TYPE[roomType]) ? ROOM_TYPE[roomType] : '-'
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: theme.palette?.blue?.blueLine, my: 2 }} />
          <StyledColumn>
            <Typography variant="h6">อุปกรณ์ภายในห้อง</Typography>
            {!_.isEmpty(meetingRoomEquipment) ? (
              <StyledRow sx={{ flexWrap: 'wrap', gap: 1 }}>
                {_.map(meetingRoomEquipment, (equip, idx) => {
                  return (
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: '#F8FBFF',
                        borderRadius: 1,
                      }}
                      key={idx}
                    >
                      <StyledRow sx={{ gap: 1 }}>
                        <Typography variant="body1b">
                          {equip.equipment?.equipmentName}
                        </Typography>
                        <Typography variant="body1b" color="primary">
                          {equip.quantity}
                        </Typography>
                      </StyledRow>
                    </Box>
                  )
                })}
              </StyledRow>
            ) : (
              <>-</>
            )}
          </StyledColumn>
          <Divider sx={{ borderColor: theme.palette?.blue?.blueLine, my: 2 }} />
          <StyledColumn sx={{ gap: 1 }}>
            <Typography variant="h6">หมายเหตุ</Typography>
            <Typography variant="body1" color="text.secondary">
              {!_.isNull(remark) ? remark : '-'}
            </Typography>
          </StyledColumn>
        </Box>
      </Grid>
    </Grid>
  )
}
