import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

import Breadcrumbs from '../../../../components/Breadcrumbs/index'
import { Container } from './Styled'
import CsHeader from './CsHeader'
import EditableHeader from './EditableHeader'

const listBreadcrumb = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล Agent',
    link: '/manage/agent-profile',
    pointer: true,
  },
  { title: 'รายละเอียดข้อมูล Agent', link: '/', pointer: false },
]

const Index = ({ type, editable }) => {
  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual
  )

  return (
    <>
      {type === 'CS' ? (
        <Box>
          <>{editable && <EditableHeader />}</>
          <>{!editable && <CsHeader />}</>
        </Box>
      ) : (
        <Container>
          <Box>
            <Typography variant="h4">
              {`รายละเอียดข้อมูล Agent : ${_.get(data, 'info.fullNameTH', '')}`}
            </Typography>
            <Breadcrumbs menuList={listBreadcrumb} />
          </Box>
        </Container>
      )}
    </>
  )
}

export default Index
