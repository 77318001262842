import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/system'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  BoxLabelText,
} from '../Styled'
import { breadcrumbDetail } from '../model'
import _ from 'lodash'
import Dot from '../../../../components/redux/Dot'
import { StyledHeaderContainer } from '../Styled'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import HistoryFilterDrawer from '../HistoryFilterDrawer'
import { setIsOpenHistoryFilterDrawer } from '../../../../redux/slices/inventory/stock/list'
import { fetchStockDetail } from './event'
import {
  STOCK_TYPE_TEXT,
  STOCK_STATUS_TEXT_TH,
  STOCK_TYPE,
} from '../../../../constants/stock'
import {
  StyledViewWrapper,
  StyledViewCard,
  StyledColumn,
  StyledRow,
} from '../../../../modules/Styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { convertFormatDateTime } from '../../../../utils/lib'
import { ProductList } from '../ProductList'
import { validatePermission } from '../../../../utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../constants/stock'
import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'

export const BoxLabel = ({ labelLeft, labelRight, isIcon }) => {
  return (
    <BoxLabelText>
      <Box sx={{ width: '25%' }}>
        <Typography variant="body1">{labelLeft}</Typography>
      </Box>
      {isIcon ? (
        labelRight
      ) : (
        <Typography variant="body1">{labelRight}</Typography>
      )}
    </BoxLabelText>
  )
}

const ContentField = (props) => {
  const { field, content, limitWidth, status } = props
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
      <Typography sx={{ alignSelf: 'center', width: '25%', ml: 3 }}>
        {field}
      </Typography>
      <Typography
        sx={{
          alignSelf: 'center',
          width: '75%',
          maxWidth: limitWidth ? limitWidth : 'unset',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {field === 'สถานะ' && <Dot sx={{ mt: 0, mr: 1 }} status={status} />}
          {content !== '' ? content : 'ไม่ได้ระบุ'}
        </Box>
      </Typography>
    </Box>
  )
}
const LabelField = (props) => {
  const { text } = props
  return (
    <Typography sx={{ pb: 1.5 }} variant="h6">
      {text}
    </Typography>
  )
}

const getAlignmentStyle = (mediaQuery) =>
  mediaQuery ? 'flex-end' : 'flex-start'

const createLabel = (user, time) => {
  if (!user) return '-'
  return (
    user +
    ' ' +
    convertFormatDateTime({
      value: time,
      type: 'dateTime',
    })
  )
}

const Label = ({ text, label }) => (
  <Typography variant="body3" color="text.lightGray">
    {text} {label}
  </Typography>
)

const Index = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoading, stockDetail, isOpenHistoryFilterDrawer, historyList } =
    useSelector(
      (state) => ({
        isLoading: state.stockList.isLoading,
        stockDetail: state.stockList.stockDetail,
        isOpenHistoryFilterDrawer: state.stockList.isOpenHistoryFilterDrawer,
        historyList: state.stockList.historyList,
      }),
      shallowEqual,
    )
  const userProfile = _.get(stockDetail, 'userProfile')

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const rowStyle = {
    justifyContent: 'flex-end',
    flexDirection: isSmUp ? 'row' : 'column',
  }
  const columnStyle = { alignItems: getAlignmentStyle(isSmUp) }

  useEffect(() => {
    dispatch(fetchStockDetail(uuid))
  }, [])

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })

  return (
    <>
      <StyledContainer sx={{ pb: 0 }}>
        <StyledContent isLoading={isLoading}>
          <StyledHeader>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <Typography variant="h4">{`รายละเอียด คลังทรัพย์สิน`}</Typography>
              </Box>
              <Breadcrumbs menuList={breadcrumbDetail} />
            </Box>
          </StyledHeader>

          <StyledCard sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <LabelField text="ข้อมูลพื้นฐาน" />
                <StyledHeaderContainer>
                  <Button
                    startIcon={<RestoreTwoToneIcon />}
                    variant="outlined"
                    onClick={() => {
                      dispatch(setIsOpenHistoryFilterDrawer(true))
                    }}
                  >
                    ประวัติการแก้ไข
                  </Button>
                  {stockDetail.status !== 'DELETED' && hasPermissionCreate && (
                    <Button
                      disabled={!hasPermissionCreate}
                      data-testid="btn-download-0"
                      variant="contained"
                      size="m"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        history.push(`/inventory/stock/form/${uuid}`)
                      }}
                    >
                      แก้ไข
                    </Button>
                  )}
                </StyledHeaderContainer>
              </Box>
              <ContentField
                field="ประเภท คลังทรัพย์สิน"
                content={STOCK_TYPE_TEXT[_.get(stockDetail, 'warehouseType')]}
              />
              <ContentField
                field="ชื่อคลังทรัพย์สิน"
                content={`${_.get(stockDetail, 'warehouseName', '-')} ${_.get(
                  userProfile,
                  'lastNameTH',
                  '',
                )}`}
              />
              <ContentField
                field="ID"
                content={_.get(stockDetail, 'id', '-')}
              />
              <ContentField
                field="Station ประจำ"
                content={_.get(stockDetail, 'station.station', '-')}
              />

              {_.get(stockDetail, 'warehouseType') === STOCK_TYPE.PROVIDER && (
                <ContentField
                  field="Vendor"
                  content={_.get(stockDetail, 'vendor', '-')}
                />
              )}
              <ContentField
                field="สถานะ"
                content={
                  STOCK_STATUS_TEXT_TH[_.get(stockDetail, 'status', '-')]
                }
                status={_.get(stockDetail, 'status')}
              />
              <ViewRow
                boxSx={{ display: 'flex', flexDirection: 'column', ml: 3 }}
                title="หมายเหตุ"
                variant="variant"
                value={_.get(stockDetail, 'remark', '-')}
              />
            </Box>
          </StyledCard>
        </StyledContent>

        <LoadingPage isLoading={isLoading} />

        <HistoryFilterDrawer
          open={isOpenHistoryFilterDrawer}
          historyList={historyList}
        />
      </StyledContainer>
      <ProductList sx={{ mx: 0 }} warehouseUuid={uuid} />

      <StyledContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <StyledViewWrapper>
            <StyledViewCard>
              <Box>
                <StyledRow sx={rowStyle}>
                  <StyledColumn sx={columnStyle}>
                    <Label
                      text="สร้างโดย"
                      label={createLabel(
                        _.get(stockDetail, 'createdBy.name'),
                        _.get(stockDetail, 'createdAt'),
                      )}
                    />
                    <Label
                      text="แก้ไขล่าสุดโดย"
                      label={createLabel(
                        _.get(stockDetail, 'updatedBy.name'),
                        _.get(stockDetail, 'updatedAt'),
                      )}
                    />
                  </StyledColumn>
                </StyledRow>
              </Box>
            </StyledViewCard>
          </StyledViewWrapper>
        </Box>
      </StyledContainer>
    </>
  )
}
export default Index
