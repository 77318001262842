import { TicketStatus } from '../../../../../constants/stock'
import { handleChangeStatus } from './handleChangeStatus'
import { store } from '../../../../../App'
import {
  replaceFieldError,
  setFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import _ from 'lodash'
import { setHeadCells } from '../../../../../redux/slices/table'
import { EditApprovedValidation } from '../schema/schema'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'

export const handleClickApproveDropdown = async (uuid, status) => {
  const { isHideEditAndApprove, formData } = store.getState().crud
  const { headCells } = store.getState().table.table
  if (status === TicketStatus.EDITED_APPROVED) {
    if (isHideEditAndApprove) {
      store.dispatch(
        setFieldValue({
          key: 'isHideEditAndApprove',
          value: false,
        }),
      )
      const modifiedHeadCells = _.defaultTo(headCells, []).map((item) => {
        let label = item?.label
        if (item.id === 'transferEditInput') label = 'แก้ไข'
        if (item.id === 'transferEditRemark') label = 'สาเหตุ'
        return {
          ...item,
          label,
        }
      })
      store.dispatch(setHeadCells(modifiedHeadCells))
    } else {
      const isValid = await validateFormData(formData, EditApprovedValidation)
      if (isValid) {
        handleChangeStatus(uuid, status, formData)
      }
    }
  } else {
    handleChangeStatus(uuid, status)
  }
}

export async function validateFormData(formData, validationSchema) {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}
