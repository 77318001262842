import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChangeEvent } from '../../events'
import ChipForm from './ChipForm'
import RadioCheckboxForm from './RadioCheckboxForm'

const Configuration = () => {
  const remarkKey = 'setting.remark'
  const dispatch = useDispatch()
  const remark = useSelector(() => getStateValue(remarkKey, null), shallowEqual)
  const { errors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <ChipForm />

      <RadioCheckboxForm />

      <Divider sx={{ mx: 0.5 }} />

      <TextInput
        multiline
        rows={3}
        name="setting.remark"
        type="basic"
        labelText="Remark"
        id="input-setting-remark"
        textError={_.get(errors, remarkKey, '')}
        isShowTextError={_.get(errors, remarkKey, '')}
        value={remark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default Configuration
