import Card from '@mui/material/Card'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { BoxLabel } from '../Information'
import { ProfileImg } from './Styled'
import _ from 'lodash'
import { Button } from '@mui/material'

const MainInformation = ({ SyncData = null }) => {
  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual,
  )

  const userId = _.get(data, 'info.uuid', '-')
  const fullAgentCode = _.get(data, 'info.fullAgentCode', '-')
  const fullNameTH = _.get(data, 'info.fullNameTH', '-')
  const agentCode = _.get(data, 'info.agentCode', '-')
  const image = _.get(data, 'info.image', '')
  const agentType = _.get(data, 'work.agentType', '')
  const lastLogin = _.get(data, 'lastLogin', '-')
  const lastSyncDate = _.get(data, 'lastSyncDate', '-')

  return (
    <>
      <Card sx={{ p: 3 }}>
        <ProfileImg
          url={
            image
              ? `${window.__env__.REACT_APP_API_URL}/api/file${image}`
              : '/logo/staff_profile_small.png'
          }
        />
        <BoxLabel labelLeft="User ID" labelRight={userId} />
        {agentType === 'A: Agent' && (
          <BoxLabel labelLeft="รหัส Agent แบบเต็ม" labelRight={fullAgentCode} />
        )}

        <BoxLabel labelLeft="รหัส Agent" labelRight={agentCode} />
        <BoxLabel labelLeft="ชื่อ - นามสกุล" labelRight={fullNameTH} />
        <BoxLabel labelLeft="เข้าสู่ระบบล่าสุด" labelRight={lastLogin} />
        <BoxLabel
          labelLeft="ข้อมูล Agent ถูกอัพเดทล่าสุด"
          labelRight={lastSyncDate}
          button={
            <Button
              onClick={() => SyncData(agentCode, agentType)}
              variant="outlined"
            >
              SYNC ข้อมูล
            </Button>
          }
        />
      </Card>
    </>
  )
}

export default MainInformation
