import * as yup from 'yup'

export const schema = yup.object({
  img: yup
    .string()
    .test('media-validate', 'กรุณาอัปโหลดรูปภาพ', (value) => value),
  imgMap: yup
    .string()
    .test('media-validate', 'กรุณาอัปโหลดรูปภาพ', (value) => value),
  title: yup
    .string()
    .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  description: yup
    .string()
    .max(3000, 'ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร'),
  phoneNumber: yup
    .string()
    .required('กรุณาระบุข้อมูล')
    .max(10, 'ระบุข้อความความยาวไม่เกิน 10 ตัวอักษร'),
  googleMap: yup
    .string()
    .required('กรุณาระบุข้อมูล')
    .test(
      'google-link-validate',
      'ระบุ Link จาก Google Map ได้เท่านั้น',
      (value) => value?.includes('https://goo.gl/maps/')
    ),
  remark: yup.string().max(3000, 'ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร'),
})
