import { ViewRow } from '../../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import { UpdatedComponent } from '../../../../../components/CRUD/components/UpdatedComponent'
import { StatusComponent } from '../../../../../components/CRUD/components/StatusComponent'
import _ from 'lodash'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  return (
    <>
      <ViewRow title="ID" value={formData.id} />
      <ViewRow title="ชื่อหมวดหมู่ย่อย" value={formData.name} />
      <ViewRow
        title="หมวดหมู่หลัก"
        value={_.get(formData, 'stockCategory.name')}
      />
      <ViewRow title="รายละเอียด" value={formData.remark} />
      <ViewRow title="สถานะ" valueComponent={StatusComponent(formData)} />
      <Divider />
      <UpdatedComponent />
    </>
  )
}
