import * as yup from 'yup'

export const validationSchema = yup.object({
  title: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(80, 'ระบุได้ไม่เกิน 80 ตัวอักษร'),
  abbreviation: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(4, 'ระบุได้ไม่เกิน 4 ตัวอักษร'),
  description: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(60, 'ระบุได้ไม่เกิน 60 ตัวอักษร'),
  distribution: yup
    .array()
    .test('test-count', 'กรุณากรอกข้อมูลในช่องนี้', (value) => {
      if (value.length > 0) {
        return true
      }
      return false
    })
    .of(
      yup.object().shape({
        staff: yup
          .string()
          .test('test-uuid', 'กรุณากรอกข้อมูลให้ถูกต้อง', function (value) {
            if (+value) {
              return false
            }
            return true
          }),
      })
    )
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  remark: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .max(255, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
  status: yup
    .string('กรุณากรอกข้อมูลให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})
