import _ from 'lodash'

export const getOptionsExpenseCategory = (data) => {
  return _.map(data, (item) => {
    return {
      ...item,
      label: item['name'],
      value: item['uuid'],
    }
  })
}
