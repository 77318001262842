import React from 'react'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  LoadingCircular,
} from './Styled'
import { toggleAgencyDrawer } from '../../handler/handleClickOpenDrawer'
import { Row } from '../../components/Styled'
import TableSRAM from './TableSRAM'
import { useTheme } from '@emotion/react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledDownloadButton } from '../../../../../components/redux/Table/Styled'
import { onDownload } from './events'
import { useDispatch } from 'react-redux'

const AgencyDrawer = () => {
  const dispatch = useDispatch()
  const { open, countTotal, isLoading, sRamData } = useSelector(
    (state) => ({
      open: state.crud.manageProfileDashboard?.agencyDrawer?.isOpen,
      countTotal: state.crud.manageProfileDashboard?.sRamTotal,
      isLoading: state.crud.isLoading,
      sRamData: state.crud.manageProfileDashboard?.sRamData,
    }),
    shallowEqual,
  )
  const theme = useTheme()
  return (
    <StyledDrawer
      open={open}
      customWidth={'90%'}
      onClose={() => toggleAgencyDrawer(false)}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">Agency ทั้งหมด</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleAgencyDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box m={`${theme.spacing(0.5)} ${theme.spacing(2.75)}`}>
        <Row>
          <Box
            sx={{
              p: '4px 16px',
              borderRadius: 5,
              background: '#F1F4FA',
              maxWidth: 'max-content',
            }}
          >
            <Typography variant="body2b" color={theme.palette.primary.main}>
              ทั้งหมด {countTotal ?? 0} คน
            </Typography>
          </Box>
          <StyledDownloadButton
            data-testid="btn-download"
            disabled={false}
            onClick={() => dispatch(onDownload([], 'SRAM'))}
            sx={{ width: '150px', ml: 'auto' }}
          >
            ดาวน์โหลด
          </StyledDownloadButton>
        </Row>
      </Box>
      <Box>
        <TableSRAM sramName={'SRAM01'} data={sRamData?.sRam1Data ?? []} />
        <TableSRAM sramName={'SRAM02'} data={sRamData?.sRam2Data ?? []} />
        <TableSRAM sramName={'SRAM03'} data={sRamData?.sRam3Data ?? []} />
        <TableSRAM sramName={'SRAM04'} data={sRamData?.sRam4Data ?? []} />
        <TableSRAM sramName={'SRAM XX'} data={sRamData?.sRam5Data ?? []} />
      </Box>
      <StyledFooter>
        <Button onClick={() => toggleAgencyDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default AgencyDrawer
