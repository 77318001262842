import React from 'react'
import { FieldArray } from 'formik'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'

import Select from '../../../components/Input/Select'
import TextInput from '../../../components/Input/TextInput'
import _ from 'lodash'

const Educational = ({ formik, onChange, qualificationOption }) => {
  if (typeof _.get(formik, 'values.user.qualification') === 'object') {
    formik['values']['user']['qualification'] = _.get(
      formik,
      'values.user.qualification.uuid',
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}
    >
      <TextInput
        id="institution"
        name={`institution`}
        labelText="สถานศึกษา (มหาวิทยาลัย)"
        type="basic"
        boxSx={{ width: '20%' }}
        textError={_.get(formik, `errors.institution`, '')}
        value={_.get(formik, 'values.user.institution', '')}
        onChange={(e) => onChange(e, 'institution')}
      />
      <TextInput
        id="between"
        name={`between`}
        labelText="ระหว่าง"
        type="basic"
        boxSx={{ width: '10%' }}
        textError={_.get(formik, `errors.between`, '')}
        value={_.get(formik, 'values.user.between')}
        onChange={(e) => onChange(e, 'between')}
      />
      <Select
        name="qualification"
        boxSx={{ width: '30%' }}
        labelText="วุฒิการศึกษา"
        options={qualificationOption}
        placeholder="กรุณาเลือก"
        renderInput={(params) => (
          <TextField
            placeholder="กรุณาเลือก"
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'select-main-admin',
            }}
          />
        )}
        value={_.get(formik, 'values.user.qualification')}
        handleChange={(e) => {
          onChange(e, 'qualification')
        }}
        onBlur={formik.handleBlur}
      />
      <TextInput
        id="branch"
        name={`branch`}
        labelText="สาขา"
        type="basic"
        boxSx={{ width: '20%' }}
        textError={_.get(formik, `errors.branch`, '')}
        value={_.get(formik, 'values.user.branch')}
        onChange={(e) => onChange(e, 'branch')}
      />
      <TextInput
        id="GPA"
        name={`gpa`}
        labelText="เกรดเฉลี่ย"
        type="basic"
        boxSx={{ width: '10%' }}
        textError={_.get(formik, `errors.gpa`, '')}
        value={_.get(formik, 'values.user.gpa')}
        onChange={(e) => onChange(e, 'gpa')}
      />
    </Box>
  )
}
const WorkHistory = ({ item, index, formik, onChange, handleDeleteClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}
    >
      <TextInput
        id="organization"
        name={`workHistory[${index}].organization`}
        labelText="ชื่อองค์กร"
        type="basic"
        boxSx={{ width: '30%' }}
        textError={_.get(
          formik,
          `errors.workHistory[${index}].organization`,
          '',
        )}
        value={item.organization}
        onChange={(e) => onChange(e, index, 'organization')}
      />
      <TextInput
        id="position"
        name={`workHistory[${index}].position`}
        labelText="ตำแหน่ง (ฝ่าย)"
        type="basic"
        boxSx={{ width: '30%' }}
        textError={_.get(formik, `errors.workHistory[${index}].position`, '')}
        value={item.position}
        onChange={(e) => onChange(e, index, 'position')}
      />
      <TextInput
        id="business"
        name={`workHistory[${index}].business`}
        labelText="ลักษณะธุรกิจ"
        type="basic"
        boxSx={{ width: '30%' }}
        textError={_.get(formik, `errors.workHistory[${index}].business`, '')}
        value={item.business}
        onChange={(e) => onChange(e, index, 'business')}
      />
      <TextInput
        id="between"
        name={`workHistory[${index}].between`}
        labelText="ระหว่าง"
        type="basic"
        boxSx={{ width: '10%' }}
        textError={_.get(formik, `errors.workHistory[${index}].between`, '')}
        value={item.between}
        onChange={(e) => onChange(e, index, 'between')}
      />
      <CloseButton index={index} handleClick={handleDeleteClick} />
    </Box>
  )
}
const Experienced = ({
  item,
  index,
  formik,
  onChange,
  typeInput,
  institute,
  formList,
  experiencedOptions,
  handleDeleteClick,
}) => {
  const selectedExperiencedOther = 'cb13bd07-9d4d-41a3-9d84-d3be2df74e4e'
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}
    >
      <Select
        dataTestid="select-experiencedUuid"
        id="experiencedUuid"
        name={`experienced[${index}].experiencedUuid`}
        labelText="ประสบการณ์"
        type="basic"
        boxSx={
          item.experiencedUuid === selectedExperiencedOther
            ? { width: '20%' }
            : { width: '80%' }
        }
        textError={_.get(
          formik,
          `errors.experienced[${index}].experiencedUuid`,
          '',
        )}
        options={experiencedOptions}
        handleChange={(e) =>
          onSelectChange({
            e,
            index,
            key: 'experiencedUuid',
            institute,
            formList,
            formik,
            typeInput,
          })
        }
        value={item.experiencedUuid}
        placeholder={'เลือก'}
      ></Select>
      {item.experiencedUuid === selectedExperiencedOther && (
        <TextInput
          id="detail"
          name="`experienced[${index}].detail`"
          labelText="โปรดระบุ"
          type="basic"
          boxSx={{ width: '60%' }}
          textError={_.get(formik, `errors.experienced[${index}].detail`, '')}
          value={item.detail}
          onChange={(e) => onChange(e, index, 'detail')}
        />
      )}
      <TextInput
        id="amount"
        name={`experienced[${index}].amount`}
        labelText="จำนวน"
        type="basic"
        boxSx={{ width: '10%' }}
        textError={_.get(formik, `errors.experienced[${index}].amount`, '')}
        value={item.amount}
        onChange={(e) => onChange(e, index, 'amount')}
      />
      <Select
        dataTestid="select-unit"
        id="unit"
        name={`experienced[${index}].unit`}
        labelText="หน่วย"
        type="basic"
        boxSx={{ width: '10%' }}
        textError={_.get(formik, `errors.experienced[${index}].unit`, '')}
        options={unitOptions}
        handleChange={(e) =>
          onSelectChange({
            e,
            index,
            key: 'unit',
            institute,
            formList,
            formik,
            typeInput,
          })
        }
        value={item.unit}
        placeholder={'เลือก'}
      ></Select>
      <CloseButton index={index} handleClick={handleDeleteClick} />
    </Box>
  )
}
const Training = ({
  item,
  index,
  formik,
  onChange,
  handleDeleteClick,
  handleDeleteCourse,
  typeInput,
  formList,
  onChangeCourse,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <TextInput
          id="institution"
          name={`training[${index}].institution`}
          labelText="สถาบัน"
          type="basic"
          boxSx={{ width: '90%' }}
          textError={_.get(formik, `errors.training[${index}].institution`, '')}
          value={item.institution}
          onChange={(e) => onChange(e, index, 'institution')}
        />
        <TextInput
          id="year"
          name={`training[${index}].year`}
          labelText="ปีที่อบรม"
          type="basic"
          boxSx={{ width: '10%' }}
          textError={_.get(formik, `errors.training[${index}].year`, '')}
          value={item.year}
          onChange={(e) => onChange(e, index, 'year')}
        />
        <CloseButton index={index} handleClick={handleDeleteClick} />
      </Box>
      {item.course.map((courseItem, courseIndex) => {
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <TextInput
              id="course"
              name={`training[${index}].course`}
              labelText="หลักสูตร"
              type="basic"
              boxSx={{ width: '90%', mx: 6 }}
              textError={_.get(
                formik,
                `errors.training[${index}].course[${courseIndex}]`,
                '',
              )}
              value={_.isEmpty(courseItem) ? '' : courseItem}
              onChange={(e) => onChangeCourse(e, index, 'course', courseIndex)}
            />
            <CloseButton
              index={index}
              handleClick={handleDeleteCourse}
              courseIndex={courseIndex}
            />
          </Box>
        )
      })}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Button
          id={`btn-add-sub-${typeInput}`}
          name={`btn-add-sub-${typeInput}`}
          data-testid={`btn-add-sub-${typeInput}`}
          sx={{ mb: 2, mx: 6 }}
          startIcon={<AddIcon />}
          onClick={() =>
            handleAddInput({
              course: true,
              index,
              formList,
              formik,
              typeInput,
            })
          }
        >
          เพิ่มหลักสูตร
        </Button>
      </Box>
    </Box>
  )
}
const Lecturer = ({
  item,
  index,
  formik,
  onChange,
  handleDeleteClick,
  onChangeCourse,
  typeInput,
  formList,
  handleDeleteCourse,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <TextInput
          id="institution"
          name={`lecturer[${index}].institution`}
          labelText="สถาบัน"
          type="basic"
          boxSx={{ width: '90%' }}
          textError={_.get(formik, `errors.lecturer[${index}].institution`, '')}
          value={item.institution}
          onChange={(e) => onChange(e, index, 'institution')}
        />
        <TextInput
          id="year"
          name={`lecturer[${index}].year`}
          labelText="ปีที่อบรม"
          type="basic"
          boxSx={{ width: '10%' }}
          textError={_.get(formik, `errors.lecturer[${index}].institution`, '')}
          value={item.year}
          onChange={(e) => onChange(e, index, 'year')}
        />
        <CloseButton index={index} handleClick={handleDeleteClick} />
      </Box>
      {item.course.map((courseItem, courseIndex) => {
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <TextInput
              id="course"
              name="course"
              labelText="หลักสูตร"
              type="basic"
              boxSx={{ width: '90%', mx: 6 }}
              textError={_.get(
                formik,
                `errors.lecturer[${index}].course[${courseIndex}]`,
                '',
              )}
              value={_.isEmpty(courseItem) ? '' : courseItem}
              onChange={(e) => onChangeCourse(e, index, 'course', courseIndex)}
            />
            <CloseButton
              index={index}
              handleClick={handleDeleteCourse}
              courseIndex={courseIndex}
            />
          </Box>
        )
      })}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Button
          id={`btn-add-sub-${typeInput}`}
          name={`btn-add-sub-${typeInput}`}
          data-testid={`btn-add-sub-${typeInput}`}
          sx={{ mb: 2, mx: 6 }}
          startIcon={<AddIcon />}
          onClick={() =>
            handleAddInput({
              course: true,
              index,
              formList,
              formik,
              typeInput,
            })
          }
        >
          เพิ่มหลักสูตร
        </Button>
      </Box>
    </Box>
  )
}
const Institute = ({
  item,
  index,
  formik,
  onChange,
  handleDeleteClick,
  formList,
  instituteOptions,
  institute,
  typeInput,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}
    >
      <Select
        dataTestid="select-profession"
        id="profession"
        name={`institute[${index}].profession`}
        labelText="คุณวุฒิวิชาชีพ และใบอนุญาต"
        type="basic"
        boxSx={{ width: '40%' }}
        textError={_.get(formik, `errors.institute[${index}].profession`, '')}
        options={instituteOptions}
        handleChange={(e) =>
          onSelectChange({
            e,
            index,
            key: 'profession',
            institute,
            formList,
            formik,
            typeInput,
          })
        }
        value={item.profession}
        placeholder={'เลือก'}
      ></Select>
      <TextInput
        id="qualification"
        name={`institute[${index}].qualification`}
        labelText="สถาบัน"
        type="basic"
        boxSx={{ width: '40%' }}
        disabled
        value={item.institute}
      />
      <TextInput
        id="license"
        name={`institute[${index}].license`}
        labelText="เลขที่ทะเบียน / ใบอนุญาต"
        type="basic"
        boxSx={{ width: '20%' }}
        textError={_.get(formik, `errors.institute[${index}].license`, '')}
        value={item.license}
        onChange={(e) => onChange(e, index, 'license')}
      />
      <CloseButton index={index} handleClick={handleDeleteClick} />
    </Box>
  )
}
const FormList = ({
  formList,
  typeInput,
  formik,
  btnText,
  onChange,
  onChangeCourse,
  handleDeleteClick,
  handleDeleteCourse,
  experiencedOptions,
  institute,
  instituteOptions,
  qualificationOption,
}) => {
  return (
    <FieldArray
      name={typeInput}
      render={() => (
        <>
          {formList.length > 0 &&
            formList.map((item, index) => {
              return (
                <Box key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {typeInput === 'workHistory' && (
                      <WorkHistory
                        item={item}
                        index={index}
                        formik={formik}
                        onChange={onChange}
                        handleDeleteClick={handleDeleteClick}
                      />
                    )}
                    {typeInput === 'experienced' && (
                      <Experienced
                        item={item}
                        index={index}
                        formik={formik}
                        onChange={onChange}
                        handleDeleteClick={handleDeleteClick}
                        experiencedOptions={experiencedOptions}
                        institute={institute}
                        typeInput={typeInput}
                        formList={formList}
                      />
                    )}
                    {typeInput === 'training' && (
                      <Training
                        item={item}
                        index={index}
                        formik={formik}
                        onChange={onChange}
                        handleDeleteClick={handleDeleteClick}
                        typeInput={typeInput}
                        formList={formList}
                        onChangeCourse={onChangeCourse}
                        handleDeleteCourse={handleDeleteCourse}
                      />
                    )}
                    {typeInput === 'educational' && (
                      <Educational
                        formik={formik}
                        onChange={onChange}
                        qualificationOption={qualificationOption}
                      />
                    )}
                    {typeInput === 'lecturer' && (
                      <Lecturer
                        item={item}
                        index={index}
                        formik={formik}
                        onChange={onChange}
                        handleDeleteClick={handleDeleteClick}
                        typeInput={typeInput}
                        formList={formList}
                        onChangeCourse={onChangeCourse}
                        handleDeleteCourse={handleDeleteCourse}
                      />
                    )}
                    {typeInput === 'institute' && (
                      <Institute
                        item={item}
                        index={index}
                        formik={formik}
                        onChange={onChange}
                        handleDeleteClick={handleDeleteClick}
                        typeInput={typeInput}
                        formList={formList}
                        onChangeCourse={onChangeCourse}
                        instituteOptions={instituteOptions}
                        institute={institute}
                      />
                    )}
                  </Box>
                </Box>
              )
            })}
          {typeInput !== 'educational' && (
            <AddButton
              typeInput={typeInput}
              course={false}
              formList={formList}
              formik={formik}
              text={btnText}
            />
          )}
        </>
      )}
    />
  )
}
const CloseButton = ({ index, handleClick, courseIndex }) => {
  return (
    <IconButton
      data-testid={`btn-delete-condition-${index}`}
      sx={{ mt: 3, height: '40px' }}
      onClick={(e) =>
        courseIndex ? handleClick(e, index, courseIndex) : handleClick(e, index)
      }
    >
      <DeleteTwoToneIcon color="action" />
    </IconButton>
  )
}

const AddButton = ({ typeInput, course, formList, formik, text }) => {
  return (
    <Button
      id={`btn-add-${typeInput}`}
      name={`btn-add-${typeInput}`}
      data-testid={`btn-add-${typeInput}`}
      sx={{ width: '150px' }}
      variant="contained"
      onClick={() =>
        handleAddInput({
          course: course,
          formList,
          formik,
          typeInput,
        })
      }
    >
      {text}
    </Button>
  )
}
export const onSelectChange = ({
  e,
  index,
  key,
  institute,
  formList,
  formik,
  typeInput,
}) => {
  if (key === 'profession') {
    const findUniversity = institute.find(
      (item) => item.uuid === e.target.value,
    )
    formList[index].institute = findUniversity?.institute || ''
  }
  formList[index][key] = e.target.value
  formik.setFieldValue(typeInput, formList)
}

export const handleAddInput = ({
  course,
  index,
  formList,
  formik,
  typeInput,
}) => {
  if (course) {
    formList[index]?.course.push({})
    formik.setFieldValue(typeInput, formList)
  } else {
    let newList = []
    if (typeInput === 'educational') {
      newList = [
        ...formList,
        {
          institution: '',
          between: '',
          education: '',
          branch: '',
          GPA: '',
        },
      ]
    } else if (typeInput === 'workHistory') {
      newList = [
        ...formList,
        {
          organization: '',
          position: '',
          business: '',
          between: '',
        },
      ]
    } else if (typeInput === 'experienced') {
      newList = [
        ...formList,
        {
          experiencedUuid: '',
          amount: '',
          unit: '',
        },
      ]
    } else if (typeInput === 'training') {
      newList = [
        ...formList,
        {
          institution: '',
          year: '',
          course: [{}],
        },
      ]
    } else if (typeInput === 'lecturer') {
      newList = [
        ...formList,
        {
          institution: '',
          year: '',
          course: [{}],
        },
      ]
    } else if (typeInput === 'institute') {
      newList = [
        ...formList,
        {
          profession: '',
          qualification: '',
          license: '',
        },
      ]
    }
    formik.setFieldValue(typeInput, newList)
  }
}

const defaultList = {
  educational: {
    institution: '',
    between: '',
    education: '',
    branch: '',
    GPA: '',
  },
  workHistory: {
    organization: '',
    position: '',
    business: '',
    between: '',
  },
  experienced: {
    experiencedUuid: '',
    amount: '',
    unit: '',
  },
  training: {
    institution: '',
    year: '',
    course: [{}],
  },
  lecturer: {
    institution: '',
    year: '',
    course: [{}],
  },
  institute: {
    profession: '',
    qualification: '',
    license: '',
  },
}
const unitOptions = [
  { label: 'วัน', value: 'วัน' },
  { label: 'เดือน', value: 'เดือน' },
  { label: 'ปี', value: 'ปี' },
]

const CloneInput = (props) => {
  const {
    formik,
    typeInput,
    experiencedOptions,
    instituteOptions,
    institute,
    qualificationOption,
  } = props
  const formList = formik.values[typeInput]
  const handleDeleteCondition = (e, index) => {
    if (index === 0) {
      formik.setFieldValue(typeInput, [defaultList[typeInput]])
      return
    } else {
      formList.splice(index, 1)
      formik.setFieldValue(typeInput, formList)
    }
  }
  const handleDeleteCourse = (e, index, courseIndex) => {
    if (courseIndex === 0) {
      return
    } else {
      formList[index].course.splice(courseIndex, 1)
      formik.setFieldValue(typeInput, formList)
    }
  }

  const onChange = (e, index, key) => {
    formList[index][key] = e.target.value
    formik.setFieldValue(typeInput, formList)
  }
  const onChangeCourse = (e, index, key, courseIndex) => {
    formList[index][key][courseIndex] = e.target.value
    formik.setFieldValue(typeInput, formList)
  }

  const onChangeEducation = (e, key) => {
    formik.setFieldValue(`user.${key}`, e.target.value)
  }

  if (typeInput === 'educational') {
    return (
      <FormList
        typeInput={typeInput}
        formik={formik}
        formList={formList}
        btnText={'เพิ่มสถานศึกษา'}
        onChange={onChangeEducation}
        qualificationOption={qualificationOption}
      />
    )
  } else if (typeInput === 'workHistory') {
    return (
      <FormList
        typeInput={typeInput}
        formList={formList}
        formik={formik}
        btnText={'เพิ่มองค์กร'}
        onChange={onChange}
        handleDeleteClick={handleDeleteCondition}
      />
    )
  } else if (typeInput === 'experienced') {
    return (
      <FormList
        typeInput={typeInput}
        formList={formList}
        formik={formik}
        btnText={'เพิ่มประสบการณ์'}
        onChange={onChange}
        handleDeleteClick={handleDeleteCondition}
        experiencedOptions={experiencedOptions}
      />
    )
  } else if (typeInput === 'training') {
    return (
      <FormList
        typeInput={typeInput}
        formList={formList}
        formik={formik}
        btnText={'เพิ่มสถาบัน'}
        onChange={onChange}
        handleDeleteClick={handleDeleteCondition}
        onChangeCourse={onChangeCourse}
        handleDeleteCourse={handleDeleteCourse}
      />
    )
  } else if (typeInput === 'lecturer') {
    return (
      <FormList
        typeInput={typeInput}
        formList={formList}
        formik={formik}
        btnText={'เพิ่มสถาบัน'}
        onChange={onChange}
        onChangeCourse={onChangeCourse}
        handleDeleteClick={handleDeleteCondition}
        handleDeleteCourse={handleDeleteCourse}
      />
    )
  } else if (typeInput === 'institute') {
    return (
      <FormList
        typeInput={typeInput}
        formList={formList}
        formik={formik}
        btnText={'เพิ่มสถานศึกษา'}
        onChange={onChange}
        onChangeCourse={onChangeCourse}
        handleDeleteClick={handleDeleteCondition}
        instituteOptions={instituteOptions}
        institute={institute}
      />
    )
  }
}
export default CloneInput
