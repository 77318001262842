import {
  setSectionResult,
  setSubmit,
} from './../../../../../../redux/slices/eTestingPreview'
import { checkDisplayOneAndNow } from './../../../../../../redux/slices/eTestingPreview/events'

export const handleSubmit = (setting) => (dispatch) => {
  const isOneAndNow = checkDisplayOneAndNow(setting)
  if (isOneAndNow) {
    dispatch(setSubmit())
    setTimeout(() => {
      dispatch(setSectionResult())
    }, 3000)
  } else {
    dispatch(setSubmit())
    dispatch(setSectionResult())
  }
}

export const handleSubmitForAll = () => (dispatch) => {
  dispatch(setSubmit())
  dispatch(setSectionResult())
}
