import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { getQueryParams } from '../../../../utils/lib'
import LoadingPageCircular from '../../components/LoadingPageCircular'
import { StyledFormWrapper } from '../../Styled'
import { schema } from './schema'
import { fetchCourseDetail, handleSubmit } from './events'
import Header from './Header'
import TabMenu from './TabMenu'
import CourseTab from './CourseTab'
import SettingTab from './SettingTab'
import BatchTab from './BatchTab'
import { StyledCircularBox } from './Styled'

const CourseForm = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const { id: uuid, createType } = useParams()
  const tab = getQueryParams('tab')
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoading, onGoing, canEdit } = useSelector(
    (state) => ({
      isLoading: state.eLearningCourseForm.isLoading,
      onGoing: state.eLearningCourseForm.onGoing,
      canEdit: state.eLearningCourseForm.body?.canEdit,
    }),
    shallowEqual,
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(fetchCourseDetail({ uuid, createType, onEdit, setOnEdit, tab }))
  }, [])

  if (canEdit === false) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '45%',
          left: '45%',
        }}
      >
        <Typography variant="h4">403 Forbidden</Typography>
        <Button
          sx={{ alignSelf: 'center' }}
          variant="contained"
          onClick={() => window.history.back()}
        >
          Back
        </Button>
      </Box>
    )
  }

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => dispatch(handleSubmit(values, history))}
        >
          <Form>
            <Header />

            <TabMenu />

            {onGoing && (
              <Alert variant="alert" severity="warning" sx={{ mt: 2 }}>
                ไม่อนุญาตให้แก้ไข เนื่องจากมีการใช้งานโมดูลนี้อยู่
              </Alert>
            )}

            <FormContent />
          </Form>
        </Formik>
      </Box>
    </>
  )
}
export default CourseForm

export const FormContent = () => {
  const tab = useSelector(
    (state) => state.eLearningCourseForm.tab,
    shallowEqual,
  )

  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'course' && <CourseTab />}
      {tab === 'setting' && <SettingTab />}
      {tab === 'batch' && <BatchTab />}
    </StyledFormWrapper>
  )
}

export const CircularContent = () => {
  return (
    <StyledCircularBox>
      <CircularProgress />
    </StyledCircularBox>
  )
}
