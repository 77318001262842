import { Button } from '@mui/material'
import React from 'react'

export function CancelButton(props) {
  return (
    <Button variant="outlined" size="m" onClick={props.onClick}>
      ยกเลิก
    </Button>
  )
}
