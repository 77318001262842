import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import { BoxContent, BoxLeft, BoxRight, StyledDot } from './Styled.js'
import { formatPhoneNo, formatIdCard } from '../../../../../utils/lib'
import CsInfo from './CsInfo.js'
import AgentInfo from './AgentInfo.js'

export const BoxLabel = ({
  labelLeft,
  labelRight,
  status,
  isHalf,
  button = null,
}) => {
  const sxBoxRight = !_.isEmpty(button) ? { flexDirection: 'column' } : {}
  const sxBoxLeft = !_.isEmpty(button) ? { alignItems: 'center' } : {}
  return (
    <BoxContent isHalf={isHalf}>
      <BoxLeft isHalf={isHalf} sx={sxBoxLeft}>
        <Typography variant="body1" color="text.secondary">
          {labelLeft}
        </Typography>
      </BoxLeft>
      <BoxRight isHalf={isHalf} status={status} sx={sxBoxRight}>
        {status && <StyledDot status={status} />}
        <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
          {_.defaultTo(labelRight, '-')}
        </Typography>
        {!_.isEmpty(button) && <>{button}</>}
      </BoxRight>
    </BoxContent>
  )
}

const Index = ({ type, editable }) => {
  const information = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual,
  )

  const status =
    _.startCase(_.camelCase(_.get(information, 'work.status', '-'))) ===
    'Active'
      ? 'Active'
      : 'Terminate'

  return (
    <>
      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลการทำงาน</Typography>
          {type === 'CS' && <CsInfo editable={editable} status={status} />}
          {type != 'CS' && <AgentInfo status={status} />}
        </Box>
      </Card>

      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลติดต่อ</Typography>
          <BoxLabel
            labelLeft="เบอร์โทรศัพท์"
            labelRight={formatPhoneNo(
              _.get(information, 'contact.mobileNo', '-'),
            )}
          />
          <BoxLabel
            labelLeft="อีเมล"
            labelRight={_.get(information, 'contact.email', '-')}
          />
        </Box>
      </Card>

      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
          <BoxLabel
            labelLeft="วัน/เดือน/ปี เกิด"
            labelRight={_.get(information, 'info2.dateOfBirth', '-')}
          />
          <BoxLabel
            labelLeft="เลขบัตรประชาชน"
            labelRight={formatIdCard(_.get(information, 'info2.idCardNo', '-'))}
          />
          <BoxLabel
            labelLeft="เพศ"
            labelRight={_.get(information, 'info2.gender', '-')}
          />
          <BoxLabel
            labelLeft="รหัสไปรษณีย์"
            labelRight={_.get(information, 'info2.zipCode', '-')}
          />
          <BoxLabel
            labelLeft="จังหวัด"
            labelRight={_.get(information, 'info2.province', '-')}
          />
          <BoxLabel
            labelLeft="อำเภอ/เขต"
            labelRight={_.get(information, 'info2.amphure', '-')}
          />
          <BoxLabel
            labelLeft="แขวง/ตำบล"
            labelRight={_.get(information, 'info2.tambon', '-')}
          />
          <BoxLabel
            labelLeft="รายละเอียดที่อยู่"
            labelRight={_.get(information, 'info2.address', '-')}
          />
        </Box>
      </Card>
    </>
  )
}

export default Index
