import React from 'react'
import Info from './Info'
import Course from './Course'

const LearningPathTab = () => {
  return (
    <>
      <Info />
      <Course />
    </>
  )
}
export default LearningPathTab
