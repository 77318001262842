import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column, Row } from '../Styled'

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  background: theme.palette?.background?.paper,
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: `0 ${theme.spacing(2)}`,
  },
}))

export const StyledColumn = styled(Column)(({ theme }) => ({
  gap: theme.spacing(5),
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(3) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(2) },
}))

export const StyledContainer = styled(Row)(({ theme }) => ({
  gap: '17px',
  alignItems: 'start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
