import React from 'react'
import _ from 'lodash'
import TextInput from '../../../../../components/Input/TextInput'

export const handleTrainingActionClick = ({
  data,
  setDialog,
  setIsDrawer,
  optionSubWorkType,
  handleFetchEvents,
  handleDelete,
}) => {
  const error = { reason: null }
  let value = { reason: null }
  const title = 'ยืนยันลบข้อมูล'

  if (error.reason === '') {
    error.reason = 'กรุณากรอกข้อมูล'
  }
  setDialog({
    open: true,
    variant: 'reject',
    title: title,
    content: (
      <GetActionPlanContentDialog
        value={value}
        error={error}
        setDialog={setDialog}
      />
    ),
    value: value,
    error: { reason: '' },
    onConfirmClick: () =>
      handleTrainingActionDialog({
        error,
        value,
        setDialog,
        data,
        setIsDrawer,
        optionSubWorkType,
        handleFetchEvents,
        handleDelete,
      }),
  })
}

export const handleTrainingActionDialog = ({
  error,
  value,
  setDialog,
  data,
  setIsDrawer,
  optionSubWorkType,
  handleFetchEvents,
  handleDelete,
}) => {
  if (value.reason === null || (value.reason !== null && value.reason === '')) {
    error.reason = 'กรุณากรอกเหตุผล'
  }
  if (value.reason !== null && value.reason.length > 3000) {
    error.reason = 'กรุณากรอกเหตุผลให้น้อยกว่า 3000 ตัวอักษร'
  }
  if (error.reason === null || (error.reason !== null && error.reason !== '')) {
    console.log('if1', error)
    setDialog((prev) => ({
      ...prev,
      content: (
        <GetActionPlanContentDialog
          value={value}
          error={error}
          setDialog={setDialog}
        />
      ),
      error: error,
    }))
  } else {
    data = { ...data, remarkRequest: value.reason }
    handleDelete({
      setDialog,
      data,
      setIsDrawer,
      handleFetchEvents,
      optionSubWorkType,
    })
  }
}

export const GetActionPlanContentDialog = ({ value, error, setDialog }) => {
  return (
    <>
      <TextInput
        id="remarkRequest"
        name="remarkRequest"
        labelText="เหตุผล"
        type="basic"
        required
        textError={_.get(error, 'reason', '')}
        isShowTextError={true}
        onChange={(e) => {
          if (e.target.value !== '') {
            error.reason = ''
          }

          value.reason = e.target.value
          setDialog((prev) => ({
            ...prev,
            value: { ...prev.value, reason: e.target.value },
          }))
        }}
        multiline={true}
        rows={2}
      />
    </>
  )
}
