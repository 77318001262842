import { store } from '../../../../../App'

export const filterStatusItems = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รอยืนยันเบิก',
    name: 'waitingRequisitionConfirmChecked',
  },
  {
    label: 'รออนุมัติเบิก',
    name: 'waitingRequisitionApproveChecked',
  },
  {
    label: 'รอคืน',
    name: 'waitingReturnChecked',
  },
  {
    label: 'รออนุมัติคืน',
    name: 'waitingReturnApproveChecked',
  },
  {
    label: 'เสร็จสิ้น',
    name: 'completedChecked',
  },
  { label: 'ปฏิเสธ', name: 'rejectedChecked' },
  {
    label: 'ยกเลิก',
    name: 'canceledChecked',
  },
]

export const filterItems = () => {
  const { tabActive } = store.getState().crud

  return [
    {
      label: `ชื่อทรัพย์สินในรายการเบิก`,
      nameCheckBox: 'productChecked',
      nameInput: 'product',
      placeholder: `กรุณาใส่ชื่อทรัพย์สินในรายการเบิก`,
      type: 'text',
    },
    {
      label: 'Station',
      nameCheckBox: 'stationChecked',
      nameInput: 'station',
      type: 'checkbox',
      list: [],
    },
    {
      label: `จำนวนที่เบิก`,
      nameCheckBox: 'qtyChecked',
      nameInput: 'qty',
      placeholder: `ระบุจำนวน`,
      inputFrom: 'qtyFrom',
      inputTo: 'qtyTo',
      type: 'number_between',
    },
    {
      label: `หมวดหมู่ ในรายการทรัพย์สิน`,
      nameCheckBox: 'categoryChecked',
      nameInput: 'category',
      placeholder: ``,
      type: 'text',
    },
    {
      label: `หมวดหมู่ย่อย ในรายการทรัพย์สิน`,
      nameCheckBox: 'subCategoryChecked',
      nameInput: 'subCategory',
      placeholder: ``,
      type: 'text',
    },
    {
      label: `วันที่สร้าง`,
      nameCheckBox: 'createDateChecked',
      nameInput: 'createDate',
      placeholder: `กรุณาใส่ วันที่สร้าง`,
      dateStateStart: 'createStartDate',
      dateStateFinish: 'createFinishDate',
      dateStateDisplay: 'createDisplayDate',
      type: 'daterange',
    },
    {
      label: `สร้างโดย`,
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: `กรุณาใส่ชื่อ`,
      type: 'text',
    },
    {
      label: `วันที่แก้ไขล่าสุด`,
      nameCheckBox: 'updateDateChecked',
      nameInput: 'updateDate',
      placeholder: `กรุณาใส่ วันที่แก้ไข`,
      dateStateStart: 'updateStartDate',
      dateStateFinish: 'updateFinishDate',
      dateStateDisplay: 'updateDisplayDate',
      type: 'daterange',
    },
    {
      label: `แก้ไขล่าสุดโดย`,
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: `กรุณาใส่ชื่อ`,
      type: 'text',
    },
    {
      label: 'สถานะ',
      nameCheckBox: 'statusChecked',
      nameInput: 'status',
      type: 'checkbox',
      list: filterStatusItems.filter((item) => {
        return (
          !tabActive ||
          (tabActive &&
            [
              'waitingRequisitionConfirmChecked',
              'waitingRequisitionApproveChecked',
              'waitingReturnApproveChecked',
            ].includes(item.name))
        )
      }),
    },
  ]
}
