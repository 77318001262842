import _ from 'lodash'
import { store } from '../../../../App'
import { path } from '../../../../constants/path'
import { setFieldUploadValue } from '../../../../redux/slices/uploadFile'
import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import {
  startLoading,
  stopLoading,
  setInitialCreateForm,
  setFieldValue,
  setBody,
  setTypeValue,
} from '../../../../redux/slices/eLearning/moduleForm'
import {
  postCreateModule,
  putCreateModule,
} from '../../../../services/eLearning/module/form'
import { getDetail } from '../../../../services/eLearning/module/detail'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import { fetchETestingList } from './TypeContent/ETesting/events'
import { fetchEEvaluationList } from './TypeContent/EEvaluation/events'

export const fetchModuleDetail = (props) => async (dispatch) => {
  const { uuid, onEdit, setOnEdit } = props
  if (onEdit) return

  if (_.isEmpty(uuid)) {
    dispatch(setInitialCreateForm())
    return
  } else {
    dispatch(setBody(null))
  }

  dispatch(startLoading())
  const response = await dispatch(getDetail(uuid))
  if (_.isNil(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const moduleType = _.get(data, 'moduleType', '')
    const type = _.get(data, 'moduleDetail.type', '')

    if (
      moduleType === MODULE_TYPE.UPLOAD_FILE ||
      moduleType === MODULE_TYPE.SCORM
    ) {
      const files = _.get(data, 'eLearningModuleFileUpload', []).map(
        (file) => ({ ...file, percent: 100 }),
      )
      const value = { files, isUploading: false }
      dispatch(setFieldUploadValue({ key: 'formData.eLearningModule', value }))
    }

    if (moduleType === MODULE_TYPE.E_TESTING) {
      await dispatch(fetchETestingList(type))
    }

    if (moduleType === MODULE_TYPE.E_EVALUATION) {
      await dispatch(fetchEEvaluationList(type))
    }
  }
  setOnEdit(true)
  dispatch(stopLoading())
}

export const isValidationExcepted = (context, field) => {
  const { from } = context
  const moduleType = _.get(from, '[1].value.moduleType', '')
  if (moduleType !== field) return true
  return false
}

export const handleSubmit = (values, history) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกและเผยแพร่',
      message: 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirmSumit(values, history)),
    }),
  )
}

export const handleConfirmSumit = (values, history) => async (dispatch) => {
  dispatch(closeDialog())

  const typeField = _.camelCase(values.moduleType)
  const body = {
    ...values,
    moduleDetail: values[typeField],
    isInProgress: false,
  }
  if (values.moduleType === MODULE_TYPE.UPLOAD_FILE) {
    const uploadedFiles = _.get(values, typeField, [])
    body.moduleDetail = {}
    body.eLearningModuleFileUpload = uploadedFiles.map((value, index) => ({
      ...value,
      sequence: index + 1,
    }))
    body.isInProgress = uploadedFiles.some(
      (item) => item.status === 'INPROGRESS',
    )
  }

  dispatch(startLoading())
  let response = {}
  if (values.uuid) {
    response = await dispatch(putCreateModule(body))
  } else {
    response = await dispatch(postCreateModule(body))
  }
  dispatch(stopLoading())

  if (_.isNil(response.error)) {
    dispatch(
      openDialog({
        isCloseDialog: false,
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        agreeText: 'ตกลง',
        handleConfirm: () => {
          const uuid = _.get(response.payload, 'uuid', '')
          history.push(`${path.eLearning}/module/view/${uuid}`)
          dispatch(closeDialog())
        },
      }),
    )
  }
}

export const getModuleFormState = () => {
  const state = store.getState()
  return _.get(state, 'eLearningModuleForm.body', {})
}

export const getStateValue = (state, key, defaultValue = '') => {
  return _.get(state.eLearningModuleForm.body, key, defaultValue)
}

export const handleChange = (key, value) => (dispatch) => {
  dispatch(setFieldValue({ key, value }))
}

export const handleTypeChange = (value) => (dispatch) => {
  const body = getModuleFormState()
  const moduleType = _.get(body, 'moduleType', '')
  const typeField = _.camelCase(moduleType)
  dispatch(setTypeValue({ key: typeField, value }))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}
