import dayjs from 'dayjs'

export const getDisabledDates = (dates) => {
  const arr = []

  if (dates && dates.length > 0) {
    dates.map((disable) => {
      const { startDate, finishDate } = disable
      for (
        const date = new Date(startDate);
        date <= new Date(finishDate);
        date.setDate(date.getDate() + 1)
      ) {
        arr.push(new Date(date))
      }
    })
  }

  return arr
}

export const formatDateDisplay = (date) =>
  dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
