import {
  E_EXAM_TYPE,
  IMPORT_EXAMINER_TYPE,
} from '../../../../constants/eExamination'

export const initialState = {
  errors: [],
  formError: [],
  isLoading: false,
  isLoadingDrawer: false,
  isOpenFilterDrawer: false,
  isOpenExaminerFilterDrawer: false,
  isOpenHistoryFilterDrawer: false,
  eExamType: E_EXAM_TYPE.ASSOCIATION,
  initialExamList: true,
  examList: [],
  initialExaminerList: true,
  refreshExaminerList: false,
  examinerList: [],
  associationList: [],
  companyList: [],
  otherExamList: [],
  importTemplateDialog: {
    isOpen: false,
  },
  regionsList: [],
  importFile: {
    fileName: 'No File Chosen',
    file: null,
    uploadResult: [],
  },
  importExaminerType: IMPORT_EXAMINER_TYPE.ANNOUNCEMENT,
  importExaminerFile: {
    fileName: 'นามสกุลไฟล์ที่รองรับ .xlsx ขนาดไฟล์ที่แนะนำไม่เกิน 2 MB',
    file: null,
    uploadResult: [],
  },
  timeList: [],
  selectedExaminer: '',
  studentHistoryList: {
    result: [],
    totalCount: 0,
  },
  studentHistoryTotal: 0,
  announcement: {
    scheduleName: '',
    annotation: '',
    scheduleNameForm: '',
    annotationForm: '',
    announce: 0,
    isEnableScheduleName: true,
    isEnableAnounce: false,
    scheduleStatus: '',
  },
  otherDocConfig: {},
  examinationPermissions: {},
  examinerPermissions: {},
}
