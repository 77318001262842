import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledOutlinedBox } from '../Styled'
import {
  StyledImagePreview,
  StyledInputHidden,
  StyledResponsiveRow,
} from './Styled'
import { handleDeleteImage, handleUpload } from './events'
import UploadLoading from '../../../UploadLoading'

const ImageUploader = ({ formik, keyField, info }) => {
  const dispatch = useDispatch()
  const { isUploadLoading, error } = useSelector(
    (state) => state.manageContentStation,
    shallowEqual
  )
  const { values, errors, setValues } = formik
  const image = _.get(values, keyField, '')
  const imageUrl = `${window.__env__.REACT_APP_API_URL}/file/${image}`
  const errorText =
    _.get(error.upload, keyField, null) || _.get(errors, keyField, '')
  return (
    <StyledOutlinedBox error={!_.isEmpty(errorText)}>
      <Typography variant="body2" color="text.secondary">
        Image{' '}
        <Typography variant="span" color="error.main">
          *
        </Typography>
      </Typography>
      <StyledColumn>
        <StyledResponsiveRow>
          {isUploadLoading[keyField] ? (
            <UploadLoading keyField={keyField} id={`${keyField}-upload`} />
          ) : (
            image != '' && (
              <StyledImagePreview keyField={keyField}>
                <img src={imageUrl} />
              </StyledImagePreview>
            )
          )}

          <StyledRow sx={{ width: 'auto' }} flexWrap="wrap" alignItems="center">
            <label htmlFor={`${keyField}-upload`}>
              <Button variant="contained" size="s" component="span">
                อัปโหลดรูปภาพ
              </Button>
              <StyledInputHidden
                data-testid="input-upload"
                id={`${keyField}-upload`}
                accept=".jpg, .jpeg, .png"
                type="file"
                onChange={(e) => dispatch(handleUpload(e, keyField, setValues))}
              />
            </label>
            {image != '' && (
              <Button
                data-testId="btn-delete"
                variant="outlined"
                size="s"
                onClick={() => dispatch(handleDeleteImage(keyField, setValues))}
              >
                ลบรูปภาพ
              </Button>
            )}
            <Typography variant="body2" color="text.secondary">
              {info}
              {image != '' && <br />}
              นามสกุลไฟล์ที่รองรับ .jpg, .jpeg, .png
            </Typography>
          </StyledRow>
        </StyledResponsiveRow>
        <Typography sx={{ mt: 0.5 }} variant="body2" color="error">
          {errorText}
        </Typography>
      </StyledColumn>
    </StyledOutlinedBox>
  )
}

export default ImageUploader
