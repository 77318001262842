
export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'checkbox',
    list: [],
  },
]

export const defaultFilter = {
  stationChecked: false,
  dateChecked: false,
  startDate: '',
  endDate: '',
}