import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import RankingCard from '../ModuleCard/RankingCard'
import { shallowEqual, useSelector } from 'react-redux'

const CompanyRanking = () => {
  const { TOP_SCHEDULE_STATION, TOP_ENROLL_STATION } = useSelector(
    (state) => ({
      TOP_SCHEDULE_STATION:
        state.crud.eExamDashboard?.overviewDataCount?.TOP_SCHEDULE_STATION,
      TOP_ENROLL_STATION:
        state.crud.eExamDashboard?.overviewDataCount?.TOP_ENROLL_STATION,
    }),
    shallowEqual,
  )

  return (
    <Box>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">อันดับสนามสอบบริษัท</Typography>
          </Box>

          <Box display="grid" gap={2} mt={3} gridTemplateColumns="1fr 1fr">
            <Box>
              <RankingCard
                title="Top 5 สนามสอบเปิดรอบมากที่สุด"
                type="rankingScheduleCompany"
                list={TOP_SCHEDULE_STATION}
                header1="สนามสอบบริษัท"
                header2="จำนวนรอบ"
              />
            </Box>
            <Box>
              <RankingCard
                title="Top 5 สนามสอบผู้สมัครมากที่สุด"
                type="rankingExaminerCompany"
                list={TOP_ENROLL_STATION}
                header1="สนามสอบบริษัท"
                header2="จำนวนผู้สมัครสอบ"
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CompanyRanking
