import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import { filterProductType } from '../../../../../utils/apiPath'

export const fetchProductType = async () => {
  const body = {
    limit: -1,
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    productType: '',
    status: ['ACTIVE'],
  }
  try {
    const response = await callAxios.post(filterProductType, body)
    store.dispatch(
      setFieldValue({
        key: 'masterData.productType',
        value: {
          isLoading: false,
          options: response.data.result.map((item) => ({
            value: item.uuid,
            label: item.productType,
          })),
        },
      }),
    )
  } catch (e) {
    handleFetchError(e)
  }
}
