import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme, indexQuestion }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginTop: !indexQuestion ? theme.spacing(3) : theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'unset',
  },
}))

export const ContentLeft = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
})

export const ContentRight = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  width: 'auto',
  '& > .MuiBox-root:last-child': { marginLeft: 0 },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    paddingLeft: theme.spacing(4),
    justifyContent: 'space-between',
  },
}))

export const DragIndicator = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: '-7px',
  left: theme.spacing(-3),
  color: theme?.palette?.action?.active,
}))

export const BoxScore = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 120,
  marginLeft: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}))
