import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { openDialog } from '../../../../../../redux/slices/dialog'
import { handleCallApiCloseClass } from '../event'
import { DialogCheckClose } from '.'

export const handleCheckClose = (manageClassClose, uuid) => (dispatch) => {
  const body = {
    manageClassUuid: `${uuid}`,
    remarkClosed: ``,
    checkNoCondition: false,
    checkPassCourse: true,
  }
  dispatch(
    openDialog({
      title: 'ปิดคลาส',
      type: 'content',
      disableButton: true,
      content: <DialogCheckClose />,
      icon: <ErrorOutlineIcon color="error" />,
      agreeText: 'ปิดคลาส',
      maxWidth: 'sm',
      handleConfirm: () => {
        dispatch(handleCallApiCloseClass(uuid, body))
      },
    })
  )
}
