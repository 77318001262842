import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledImagePreview = styled(Box)(() => ({
  minWidth: 269,
  height: 150,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
}))

export const StyledInputHidden = styled('input')({ display: 'none' })
