import _ from 'lodash'
import { store } from '../../../../App'
import { overviewEnum } from '../enums/drawerEnum'

export const totalUserAgencyModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2b',
      background: '#EBF6F3',
      dotColor: '#32A287',
      number: _.get(overview?.totalUserAgency, 'ALL', 0),
      unit: 'คน',
      value: 'ALL',
      type: 'Agency',
      width: '170px',
    },
    {
      label: 'Manager ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserAgency, 'MANAGER', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'MANAGER',
      type: 'Agency',
      width: '293px',
    },
    {
      label: 'Agent ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserAgency, 'AGENT', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'AGENT',
      type: 'Agency',
      width: '293px',
    },
    {
      label: 'Terminate ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserAgency, 'TERMINATE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'TERMINATE',
      type: 'Agency',
      width: '293px',
    },
  ]
}

export const totalUserBancModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2b',
      background: '#FFF4C0',
      dotColor: '#FFE66D',
      number: _.get(overview?.totalUserBanc, 'ALL', 0),
      unit: 'คน',
      value: 'ALL',
      type: 'Banc',
      width: '170px',
    },
    {
      label: 'KTB (FP)',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserBanc, 'KTB_FP', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'KTB_FP',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'KTB (LB)',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserBanc, 'KTB_LB', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'KTB_LB',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'BSO, BSM',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserBanc, 'BSO', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'BSO',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'Terminate ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.totalUserBanc, 'TERMINATE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'TERMINATE',
      type: 'Banc',
      width: '215px',
    },
  ]
}

export const totalProspectModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.totalProspect, 'ALL', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      border: 'none',
      hideClick: true,
    },
    {
      label: 'Active',
      labelVariant: 'body2',
      number: _.get(overview?.totalProspect, 'ACTIVE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'ACTIVE',
      type: 'Prospect',
      width: '215px',
    },
    {
      label: 'Inactive',
      labelVariant: 'body2',
      number: _.get(overview?.totalProspect, 'INACTIVE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'INACTIVE',
      type: 'Prospect',
      width: '215px',
    },
    {
      label: 'เป็นตัวแทนแล้ว',
      labelVariant: 'body2',
      number: _.get(overview?.totalProspect, 'IS_REF', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'IS_REF',
      type: 'Prospect',
      width: '215px',
    },
  ]
}

export const userAccessAgencyModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2b',
      background: '#EBF6F3',
      dotColor: '#32A287',
      number: _.get(overview?.userAccessAgency, 'ALL', 0),
      unit: 'คน',
      value: 'ALL',
      type: 'AccessAgency',
      width: '170px',
    },
    {
      label: 'Manager ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessAgency, 'MANAGER', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'MANAGER',
      type: 'AccessAgency',
      width: '293px',
    },
    {
      label: 'Agent ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessAgency, 'AGENT', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'AGENT',
      type: 'AccessAgency',
      width: '293px',
    },
    {
      label: 'Terminate ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessAgency, 'TERMINATE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'TERMINATE',
      type: 'AccessAgency',
      width: '293px',
    },
  ]
}

export const userAccessBancModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2b',
      background: '#FFF4C0',
      dotColor: '#FFE66D',
      number: _.get(overview?.userAccessBanc, 'ALL', 0),
      unit: 'คน',
      value: 'ALL',
      type: 'Banc',
      width: '170px',
    },
    {
      label: 'KTB (FP)',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessBanc, 'KTB_FP', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'KTB_FP',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'KTB (LB)',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessBanc, 'KTB_LB', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'KTB_LB',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'BSO, BSM',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessBanc, 'BSO', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'BSO',
      type: 'Banc',
      width: '215px',
    },
    {
      label: 'Terminate ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessBanc, 'TERMINATE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'TERMINATE',
      type: 'Banc',
      width: '215px',
    },
  ]
}

export const userAccessProspectModel = () => {
  const { manageProfileDashboard } = store.getState().crud
  const overview = manageProfileDashboard?.overview || overviewEnum
  return [
    {
      label: 'ทั้งหมด',
      labelVariant: 'body2b',
      background: '#FFDACA',
      dotColor: '#FFA944',
      number: _.get(overview?.userAccessProspect, 'ALL', 0),
      unit: 'คน',
      value: 'ALL',
      type: 'Prospect',
      width: '170px',
      border: 'none',
      hideClick: true,
    },
    {
      label: 'Active',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessProspect, 'ACTIVE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'ACTIVE',
      type: 'Prospect',
      width: '293px',
    },
    {
      label: 'Inactive',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessProspect, 'INACTIVE', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'INACTIVE',
      type: 'Prospect',
      width: '293px',
    },
    {
      label: 'เป็นตัวแทนแล้ว',
      labelVariant: 'body2',
      number: _.get(overview?.userAccessProspect, 'IS_REF', 0),
      hidePercent: true,
      unit: 'คน',
      value: 'IS_REF',
      type: 'Prospect',
      width: '293px',
    },
  ]
}
