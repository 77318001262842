import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

export function createDebouncedSetField(dispatch, FIELD_NAME) {
  return _.debounce((inputValue) => {
    dispatch(
      setFieldValue({ key: `formData.${FIELD_NAME}`, value: inputValue }),
    )
  }, 300)
}
