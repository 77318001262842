import { handleCheckForUploadStatus } from './handleCheckForUploadStatus'
import { componentConfig } from '../component.config'

export const setupStatusCheckInterval = (onChange, fieldName) => {
  const intervalId = setInterval(
    () => handleCheckForUploadStatus(onChange, fieldName),
    componentConfig.FETCH_INTERVAL,
  )
  return () => clearInterval(intervalId)
}
