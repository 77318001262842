import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingCircular,
  StyledHeader,
} from '../Styled'
import FilterDrawer from './FilterDrawer'
import Header from './Header'
import { fetchDataList } from './events'
import { breadcrumbList } from './model'

const LearningPath = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const fetchProps = { table, page, status, filterProp, keyword: searchText }

  useEffect(() => {
    dispatch(fetchDataList({ ...fetchProps, method: 'initial', setInitial }))
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(fetchDataList({ ...fetchProps, method: 'fetch' }))
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">Learning path</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>

          <Header />
        </StyledHeader>

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
export default LearningPath
