import React from 'react'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import TextInput from '../../../../../../components/Input/TextInput'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { StyledFormCard, StyledFormColumn } from '../../../../Styled'
import { getStateValue, handleChangeEvent } from '../../events'
import StatusSwitch from '../StatusSwitch'

const LinkToWebsite = () => {
  const typeField = _.camelCase(MODULE_TYPE.LINK_TO)
  const keyWebName = `${typeField}.webName`
  const keyLink = `${typeField}.link`
  const keyLinkDescription = `${typeField}.linkDescription`
  const dispatch = useDispatch()
  const { webName, link, linkDescription, onGoing } = useSelector(
    (state) => ({
      webName: getStateValue(state, keyWebName),
      link: getStateValue(state, keyLink),
      linkDescription: getStateValue(state, keyLinkDescription),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard>
      <Typography variant="h6">Link to another website</Typography>
      <StyledFormColumn>
        <TextInput
          id="input-web-name"
          name={keyWebName}
          type="basic"
          labelText="ชื่อเว็บไซต์"
          placeholder="ชื่อเว็บไซต์"
          value={webName}
          textError={_.get(errors, keyWebName, '')}
          isShowTextError={_.get(errors, keyWebName, '')}
          onChange={(e) => dispatch(handleChangeEvent(e))}
          disabled={onGoing}
        />
        <TextInput
          required
          multiline
          rows={3}
          name={keyLink}
          type="basic"
          labelText="Link"
          placeholder="เช่น https://google.com"
          id="input-link"
          textError={_.get(errors, keyLink, '')}
          isShowTextError={_.get(errors, keyLink, '')}
          value={link}
          onChange={(e) => dispatch(handleChangeEvent(e))}
          disabled={onGoing}
        />
        <TextInput
          required
          multiline
          rows={3}
          name={keyLinkDescription}
          type="basic"
          labelText="คำอธิบาย Link"
          placeholder="คำอธิบาย Link"
          id="input-link-description"
          textError={_.get(errors, keyLinkDescription, '')}
          isShowTextError={_.get(errors, keyLinkDescription, '')}
          value={linkDescription}
          onChange={(e) => dispatch(handleChangeEvent(e))}
          disabled={onGoing}
        />
      </StyledFormColumn>
      <Divider />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default LinkToWebsite
