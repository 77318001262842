import React from 'react'
import Popper from '@mui/material/Popper'
import styled from '@mui/system/styled'

export const StyledPopper = styled((props) => (
  <Popper role={undefined} transition disablePortal {...props} />
))(({ theme }) => ({
  zIndex: 99,
  '& .MuiPaper-root': {
    borderRadius: 0,
    '#download-dropdown': { width: 234 },
    '#create-dropdown': { width: 193 },
    '& .MuiDivider-root': {
      margin: '4px 0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      '#download-dropdown': { width: 226 },
      '#create-dropdown': { width: 189 },
    },
  },
}))
