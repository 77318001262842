import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import { StyledDialog } from './Styled'

export const Dialog = ({ children, onChange }) => {
  return (
    <StyledDialog>
      <Typography variant="body1">{children}</Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              onChange={onChange}
            />
          }
          label="ฉันรับทราบเงื่อนไขการปิดใช้งานแล้ว"
        />
      </Box>
    </StyledDialog>
  )
}

export default Dialog
