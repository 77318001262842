import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  CRUDGetByIdPath,
  CRUDGetHistoryPath,
  uploadFileSettingPoint,
  learningPointCreate,
  learningPointGetCourse,
  learningPointUpdate,
  learningPointDelete,
  CRUDListFilterPath,
} from '../../../utils/apiPath'
import _ from 'lodash'

export const deleteSettingPoint = createAsyncThunk(
  'settingPoint/deleteSettingPoint',
  async ({ uuid, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${learningPointDelete}/${uuid}`,
        headerConfig,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
export const postUploadImage = createAsyncThunk(
  'settingPoint/postUploadImage',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(uploadFileSettingPoint, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getDetail = createAsyncThunk(
  'settingPoint/getDetail',
  async ({ uuid, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${CRUDGetByIdPath}/${uuid}`,
        headerConfig,
      )
      let data = _.get(response, 'data', {})
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateSettingPoint = createAsyncThunk(
  'settingPoint/postSettingPoint',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(learningPointCreate, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putUpdateSettingPoint = createAsyncThunk(
  'settingPoint/putSettingPoint',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(learningPointUpdate, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getSettingPointHistory = createAsyncThunk(
  'settingPoint/getSettingPointHistory',
  async ({ url, headerConfig }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${CRUDGetHistoryPath}/${url}`,
        headerConfig,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourse = createAsyncThunk(
  'settingPoint/getCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.get(learningPointGetCourse)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLearningPointList = createAsyncThunk(
  'settingPoint/getList',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: 'ASC',
        sort: 'id',
        point: '',
        badgeName: '',
        name: '',
        status: ['ACTIVE'],
      }
      const headerConfig = {
        headers: {
          'x-type': 'LEARNING_POINT',
        },
      }
      const response = await axios.post(CRUDListFilterPath, body, headerConfig)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
