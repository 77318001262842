import { getWeek } from 'date-fns'
import dayjs from 'dayjs'

export const initialState = {
  isLoading: false,
  isLoadingDetails: false,
  isLoadingBooking: false,
  isOpenFilterDrawer: false,
  isOpenHistoryDrawer: false,
  roomDetails: {},
  data: {},
  stationList: [],
  year: null,
  month: null,
  meetingRoomList: [],
  roomRequestList: [],
  allCount: 0,
  stationUuid: '',
  tabActive: 0,
  typeFilter: 'meetingRoom',
  typeMemo: '',
  filterMemo: {},
  filter: {},
  stationUuidMemo: '',
  listArrayFilter: [],
  tablePage: 0,
  page: -1,
  rowsPerPage: { label: '100', value: 100 },

  // weekly booking card
  bookingRoomWeekly: [],
  currentDate: new Date(),
  currentWeek: getWeek(new Date()),
  selectedDate: null,
  dates: [],
  bookingRoomList: [],

  staffList: [],
  requestType: 'ALL',
  isDrawerImageSliderOpen: false,
  imageSliderSelectedIndex: 0,
  roomDetailCalendar: [],
  isCalendarLoading: false,
  isDrawerCalendarFilterOpen: false,
  viewCalendar: 'dayGridMonth',
  detail: {
    uuid: '',
  },
  cacheDrawerCalendarFilter: {
    isFilterRoomName: false,
    filterRoomName: '',
    isFilterStation: false,
    filterStation: [],
    isFilterBookingType: false,
    filterBookingType: [],
    isFilterTopic: false,
    filterTopicQuery: '',
  },
  drawerCalendarFilter: {
    isFilterRoomName: false,
    filterRoomName: '',
    isFilterStation: false,
    filterStation: [],
    isFilterBookingType: false,
    filterBookingType: [],
    isFilterTopic: false,
    filterTopicQuery: '',
  },
  anchorElTop: 0,
  anchorElLeft: 0,
  isPopupEventSummary: false,
  PopupEventSummary: null,
  mondayDate: '',
  endDate: '',
  monthYear: dayjs().startOf('month').hour(7).toISOString(),
  calendarPage: 'dashboard',
  dateQueryStringCalendar: '',
}
