import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  agencyDrawerEnum,
  filtersDrawerEnum,
  overviewEnum,
} from '../enums/drawerEnum'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  store.dispatch(
    setFieldValue({
      key: 'manageProfileDashboard.filtersDrawer',
      value: filtersDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'manageProfileDashboard.overview',
      value: overviewEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'manageProfileDashboard.agencyDrawer',
      value: agencyDrawerEnum,
    }),
  )
  await fetchOverview()
}
