import { store } from '../../../../../App'
import _ from 'lodash'
import {
  replaceFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'

export const handleDragEnd = (result) => {
  console.log(result)
  if (!result.destination) return

  const { crud } = store.getState()
  const list = _.get(crud, `formData.contents`, [])

  let errorArr = store.getState().crud.formErrors
  errorArr = _.filter(errorArr, (obj) => {
    return !obj.path.includes(`content`)
  })
  store.dispatch(replaceFieldError(errorArr))

  const reorderedList = reorder(
    [..._.cloneDeep(list)],
    result.source.index,
    result.destination.index,
  )

  console.log('list', reorderedList)
  store.dispatch(
    setFieldValue({
      key: `formData.contents`,
      value: reorderedList,
    }),
  )
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
