import React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import ActiveSwitch from '../../../../components/ActiveSwitch'
import { StyledTabHeader } from './Styled'
import { handleSwitchTabBody } from './events'

const TabHeader = ({ keyField, title, status }) => {
  const dispatch = useDispatch()
  return (
    <StyledTabHeader>
      <Typography variant="h4" align="justify">
        {title}
      </Typography>
      <ActiveSwitch
        name="status"
        dataTestId="active-tab"
        checked={status}
        onChange={(e) => dispatch(handleSwitchTabBody(e, keyField))}
      />
    </StyledTabHeader>
  )
}

export default TabHeader
