import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import styled from '@mui/system/styled'
import { isNotfoundOrError } from '../../List/CheckDialog/Styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  p: { span: { color: theme?.palette?.success?.main } },
  'p > #error': { color: theme?.palette?.error?.main },
  maxWidth: 840,
}))

export const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  marginLeft: theme.spacing(3),
  maxHeight: 590,
  overflowY: 'auto',
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  height: 56,
  width: '100%',
  display: 'flex',
  borderColor: theme.palette?.blue?.blueLine,
  gap: theme.spacing(2),
  '& > tr > th > .MuiTypography-root': {
    width: '100%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(TableRow)(({ theme, status }) => {
  let color = theme?.palette?.error?.main
  if (isSuccess(status)) color = theme?.palette?.success?.main
  const textDecoration = isNotfoundOrError(status) ? 'underline' : 'none'
  const cursor = isNotfoundOrError(status) ? 'pointer' : 'auto'
  return {
    width: '1540px',
    display: 'flex',
    borderColor: theme.palette?.blue?.blueLine,
    background: isSuccess(status)
      ? theme?.palette?.success?.outlinedHoverBackground
      : 'transparent',
    '& > td > .MuiTypography-root': {
      fontSize: 18,
      lineHeight: '20px',
    },
    '& > td > #status': { color, textDecoration, cursor },
  }
})

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '800px',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const isSuccess = (status) => status === 'SUCCESS'
