import React from 'react'
import Button from '@mui/material/Button'
import { StyledHeaderContainer } from '../../Styled'
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp'
import MoreDropdownButton from './DropdownButton'
import { useDispatch } from 'react-redux'
import { handleInputFile } from './events'
import { validateEPermission } from '../../../../utils/lib'
import {
  AGENT_LICENSE_SETTING,
  PERMISSION,
} from '../../../../constants/permission/apiToRcms'

const Header = ({ onHistoryClick, fetchData }) => {
  const dispatch = useDispatch()
  const hasCreatePermission = validateEPermission({
    module: AGENT_LICENSE_SETTING,
    permission: PERMISSION.CREATE,
  })
  return (
    <StyledHeaderContainer>
      {hasCreatePermission && (
        <Button
          startIcon={<UploadFileSharpIcon />}
          data-testid="btn-create"
          variant="contained"
          component="label"
          size="m"
        >
          นำเข้าข้อมูล
          <input
            type="file"
            hidden
            id="btn-add-file"
            onChange={(e) => dispatch(handleInputFile(e, fetchData))}
          />
        </Button>
      )}

      <MoreDropdownButton onHistoryClick={onHistoryClick} />
    </StyledHeaderContainer>
  )
}
export default Header
