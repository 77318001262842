import { setChangeQuestionByKey } from '../../../../../../../../redux/slices/eEvaluationForm'

export const handleAddAnswer = (props) => (dispatch) => {
  const {
    setNextQuestion,
    sequence,
    questionType,
    answers,
    isOther,
    isSubQuestions,
    subQuestionSequence,
  } = props

  let valueObj = {
    id: `id-${answers.length + 1}`,
    title: '',
    sequence: answers.length + 1,
    mediaUrl: '',
    answerType: isOther ? 'OTHER' : 'CHOICE',
  }

  if (!isSubQuestions && questionType === 'SINGLE') {
    valueObj = {
      ...valueObj,
      nextQuestionType: setNextQuestion ? 'NEXT_SUB' : 'NONE',
      nextQuestionSequence: 0,
      nextSubQuestionSequence: 0,
    }
  }
  const newAnswer = answers.map((item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
      questionType: questionType,
    }
  })

  const result = {
    type: !isSubQuestions ? 'questions' : 'subQuestions',
    sequence,
    key: !isSubQuestions
      ? 'answers'
      : `value[${subQuestionSequence - 1}].answers`,
    value: [...newAnswer, valueObj],
  }

  dispatch(setChangeQuestionByKey(result))
}
