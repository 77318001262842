import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../components/LoadingPageCircular'
import { StyledFormWrapper } from '../../Styled'
import Header from './Header'
import Info from './Info'
import TypeContent from './TypeContent'
import { schema } from './schema'
import { fetchModuleDetail, handleSubmit } from './events'

const ModuleForm = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoading, onGoing } = useSelector(
    (state) => ({
      isLoading: state.eLearningModuleForm.isLoading,
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(fetchModuleDetail({ uuid, onEdit, setOnEdit }))
  }, [])

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values) => dispatch(handleSubmit(values, history))}
        >
          <Form>
            <Header />

            <StyledFormWrapper>
              {onGoing && (
                <Alert variant="alert" severity="warning">
                  ไม่อนุญาติให้แก้ไขข้อมูลบางส่วน
                  เนื่องจากมีการใช้งานโมดูลนี้อยู่
                </Alert>
              )}

              <Info />

              <TypeContent />
            </StyledFormWrapper>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default ModuleForm
