import { Typography } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { BOOKING_PERIOD_TYPE } from '../../../../constants/roomManagement'
import { roomManagementAvailable } from '../../../../utils/apiPath'
import { validateHhMm } from '../../../../modules/RoomManagement/BookingDrawer/event'
import { store } from '../../../../App'
import { setRoomBookingListByDate } from './index'

export const FetchMasterRoomsByDay = async (state) => {
  const bookingInformation = _.get(state, 'bookingInformation', {})
  const stationUuid = _.get(state, 'stationUuid', {})
  const roomBookingItem = _.get(state, 'roomBookingItem', {})
  const { startDate, endDate, bookingPeriod } = bookingInformation

  if (!validateHhMm(state.startTimeRoom) || !validateHhMm(state.endTimeRoom)) {
    return []
  }

  let body
  if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
    body = {
      stationUuid: stationUuid,
      startDate: startDate,
      endDate: endDate,
      startTime: state.startTimeRoom,
      endTime: state.endTimeRoom,
    }
  } else {
    body = {
      stationUuid: stationUuid,
      startDate: roomBookingItem.bookingDate,
      endDate: roomBookingItem.bookingDate,
      startTime: roomBookingItem.startTimeRoom,
      endTime: roomBookingItem.endTimeRoom,
    }
  }
  store.dispatch(
    setRoomBookingListByDate({
      bookingDate: roomBookingItem.bookingDate,
      key: 'isLoadingMasterRooms',
      value: true,
    }),
  )
  const resultMasterRooms = await axios
    .post(roomManagementAvailable, body)
    .then((res) => res.data.roomResult)

  store.dispatch(
    setRoomBookingListByDate({
      bookingDate: roomBookingItem.bookingDate,
      key: 'isLoadingMasterRooms',
      value: false,
    }),
  )

  return optionsTemplateMasterRooms(resultMasterRooms)
}

export const optionsTemplateMasterRooms = (roomResult) => {
  let result = []
  roomResult.forEach((item) => {
    const roomUuid = _.get(item, 'roomUuid', '')
    const station = _.get(item, 'station', '')
    const roomName = _.get(item, 'roomName', '')
    const floor = _.get(item, 'floor', '')
    const available = _.get(item, 'available', false)
    const textAvailable = available ? (
      <Typography variant="body1" color="success.main" component="span">
        ว่าง
      </Typography>
    ) : (
      <Typography variant="body1" component="span">
        ไม่ว่าง
      </Typography>
    )

    const roomOption = {
      ...item,
      value: roomUuid,
      uuid: roomUuid,
      labelText: (
        <Typography variant="body1b">
          {station} - {roomName} (ชั้น{floor}) {textAvailable}
        </Typography>
      ),
      label: `${station} - ${roomName} (ชั้น${floor})`,
      disabled: !available,
    }

    result.push(roomOption)
  })
  result.push({
    value: 'OTHER',
    uuid: 'other',
    labelText: <Typography variant="body1b">Other</Typography>,
    label: 'Other',
  })
  return result
}
