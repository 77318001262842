import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { StyledTab } from './Styled'

const TabMenu = ({ isForm, tab, tabs, handleChange }) => {
  const dispatch = useDispatch()

  return (
    <StyledTab
      isForm={isForm}
      data-testid="tap-menu-form"
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      aria-label="primary tabs"
      onChange={(_, value) => dispatch(handleChange(value))}
    >
      {tabs.map((item, index) => (
        <Tab
          key={index}
          value={item.value}
          label={
            <Typography variant="body1b" color="primary">
              {item.label}
            </Typography>
          }
        />
      ))}
    </StyledTab>
  )
}
export default TabMenu
