import { Box, Card, CardContent } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import { DataCard } from '../ModuleCard/DataCard'
import { overallData } from '../../models/overall'
import _ from 'lodash'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'
import { shallowEqual, useSelector } from 'react-redux'

const EachType = () => {
  const { overview } = useSelector(
    (state) => ({
      overview: state.crud.mixModuleDashboard?.overview,
    }),
    shallowEqual,
  )

  const preapareData = (type) => {
    const typeLower = _.lowerCase(type)
    return {
      allClass: _.get(overview, `${type}.${typeLower}ClassCount`, 0),
      enroll: _.get(overview, `${type}.${typeLower}LearnerEnroll`, 0),
      attendee: _.get(overview, `${type}.${typeLower}Attendee`, 0),
      budget: _.get(overview, `${type}.${typeLower}ExpenseSummary`, 0),
    }
  }

  const dataAgency = preapareData('Agency')
  const agencyData = overallData({
    data: dataAgency,
    border: 'solid 1px #32A287',
    iconColor: '#32A287',
    width: '295px',
  })
  const totalModuleAgency = _.get(agencyData[1], 'number', 0)

  const dataBanc = preapareData('Banca')
  const bancData = overallData({
    data: dataBanc,
    border: 'solid 1px #FFCD92',
    iconColor: '#FFCD92',
    width: '295px',
  })
  const totalModuleBanc = _.get(dataBanc[1], 'number', 0)

  const dataKtb = preapareData('Ktb')
  const ktbData = overallData({
    data: dataKtb,
    border: 'solid 1px #4976BA',
    iconColor: '#4976BA',
    width: '295px',
  })
  const totalModuleKtb = _.get(dataKtb[1], 'number', 0)

  const dataOther = preapareData('Other')
  const otherData = overallData({
    data: dataOther,
    border: 'solid 1px #B49CE4',
    iconColor: '#B49CE4',
    width: '295px',
  })
  const totalModuleOther = _.get(dataOther[1], 'number', 0)

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={'สถิติแยกประเภท'}
            type={''}
            contentId="eachType"
            defaultToggle={true}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              flexWrap="wrap"
            >
              <DataCard
                title="Agency"
                titleDotColor="#32A287"
                dataList={agencyData}
                totalModule={totalModuleAgency}
                border={'solid 1px #32A287'}
                handleClickButton={(ele) => {
                  handleClickOpenDrawer('classDrawerAgency', ele)
                }}
                maxWidth={{
                  sm: '100%',
                  md: '49%',
                  lg: '49%',
                }}
              />
              <DataCard
                title="BANCA"
                titleDotColor="#FFCD92"
                dataList={bancData}
                totalModule={totalModuleBanc}
                border={'solid 1px #FFCD92'}
                handleClickButton={(ele) => {
                  handleClickOpenDrawer('classDrawerBanca', ele)
                }}
                maxWidth={{
                  sm: '100%',
                  md: '49%',
                  lg: '49%',
                }}
              />
              <DataCard
                title="KTB"
                titleDotColor="#4976BA"
                dataList={ktbData}
                totalModule={totalModuleKtb}
                border={'solid 1px #4976BA'}
                handleClickButton={(ele) => {
                  handleClickOpenDrawer('classDrawerKtb', ele)
                }}
                maxWidth={{
                  sm: '100%',
                  md: '49%',
                  lg: '49%',
                }}
              />
              <DataCard
                title="Other"
                titleDotColor="#B49CE4"
                dataList={otherData}
                totalModule={totalModuleOther}
                border={'solid 1px #B49CE4'}
                handleClickButton={(ele) => {
                  handleClickOpenDrawer('classDrawerOther', ele)
                }}
                maxWidth={{
                  sm: '100%',
                  md: '49%',
                  lg: '49%',
                }}
              />
            </Box>
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

export default EachType
