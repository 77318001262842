import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import UploadFileSharp from '@mui/icons-material/UploadFileSharp'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import { breadcrumbForm, breadcrumbFormEdit } from '../../model'
import * as Styles from './Styled'
import MoreDropdownButton from './DropdownButton'
import { path } from './../../../../constants/path'
import { validateEPermission } from './../../../../utils/lib'

import {
  checkScrollWidth,
  onErrorValidate,
  handleImportTemplate,
} from './event'

const Index = () => {
  const { id: uuid, createType } = useParams()

  const dispatch = useDispatch()
  const [isScrolled, setScroll] = useState(false)
  const [, setETesting] = useLocalStorage('eTesting')
  const { setValues, errors } = useFormikContext()
  const [title, setTitle] = useState('')
  const {
    codeId,
    name,
    typeOfETesting,
    stateETesting,
    isClone,
    status,
    errorsApi,
    onViewMode,
    disableForm,
  } = useSelector(
    (state) => ({
      codeId: state.eTestingForm.codeId,
      name: state.eTestingForm.information.name,
      typeOfETesting: state.eTestingForm.typeOfETesting,
      isClone: state.eTestingForm.isClone,
      stateETesting: state.eTestingForm,
      status: state.eTestingForm.setting.status,
      errorsApi: state.eTestingForm.errorsApi,
      onViewMode: state.eTestingForm.onViewMode,
      disableForm: state.eTestingForm.disableForm,
    }),
    shallowEqual
  )
  const disableSubmit = onViewMode || disableForm

  useEffect(() => {
    if (!title) setTitle(name)
  }, [name])
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(errors) && !errorsApi)
      dispatch(onErrorValidate(!_.isEmpty(_.get(errors, 'information', {}))))
  }, [errors])

  const getPermissionView = validateEPermission({
    module: 'E_TESTING_SETTING',
    permission: 'VIEW',
  })

  const titleLabel = `${
    uuid && !isClone
      ? 'แก้ไขแบบทดสอบ'
      : createType && !uuid && !codeId
      ? 'สร้างแบบทดสอบ'
      : 'สร้างเวอร์ชั่นใหม่'
  }`

  const cloneCondition =
    isClone || (status !== 'ACTIVE' && status !== 'WAITING')

  return (
    <Styles.BoxHeader scrolled={isScrolled}>
      <Styles.BoxLabelTitle isClone={cloneCondition} isEdit={uuid && !isClone}>
        <Styles.StyledViewTitle variant="h4">
          {uuid && !isClone ? `${titleLabel}: ${name}` : titleLabel}
        </Styles.StyledViewTitle>
        {!isScrolled && (
          <Breadcrumbs
            menuList={
              uuid || codeId
                ? breadcrumbFormEdit(typeOfETesting, codeId, titleLabel)
                : breadcrumbForm(typeOfETesting)
            }
          />
        )}
      </Styles.BoxLabelTitle>
      <Styles.BoxButton>
        {getPermissionView && (
          <Button
            data-testid="btn-preview"
            variant="outlined"
            size="m"
            startIcon={<RemoveRedEye color="primary" />}
            onClick={() => {
              setETesting(stateETesting)
              const url = `${path.eTesting}/preview${uuid ? '/' + uuid : ''}`
              window.open(url, '_blank')
            }}
          >
            ดูตัวอย่าง
          </Button>
        )}

        {(!uuid || isClone) && (
          <>
            {typeOfETesting !== 'ASSESSMENT' && (
              <label htmlFor="btn-import-template">
                <Button
                  data-testid="btn-import-template"
                  variant="outlined"
                  size="m"
                  component="span"
                  startIcon={<UploadFileSharp color="primary" />}
                >
                  นำเข้า template
                </Button>
                <Styles.InputImport
                  data-testid="btn-import-template"
                  id="btn-import-template"
                  accept=".xlsx, .xls, .csv"
                  type="file"
                  onChange={(dataImport) =>
                    dispatch(
                      handleImportTemplate({
                        dataImport,
                        questions: stateETesting.questions,
                      })
                    )
                  }
                />
              </label>
            )}
          </>
        )}
        {cloneCondition && (
          <Button
            type="submit"
            data-testid="btn-draft"
            variant="outlined"
            size="m"
            disabled={disableSubmit}
            onClick={() => {
              setValues({ ...stateETesting, isDraft: true })
            }}
          >
            บันทึกฉบับร่าง
          </Button>
        )}

        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={onViewMode}
          onClick={() => {
            setValues({ ...stateETesting, isDraft: false })
          }}
        >
          บันทึกและเผยแพร่
        </Button>

        {uuid && !isClone && (
          <MoreDropdownButton typeOfETesting={typeOfETesting} />
        )}
      </Styles.BoxButton>
    </Styles.BoxHeader>
  )
}
export default Index
