import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import ELearningCourseCard from '../components/ELearningCourseCard'
import { StyledRowHeader } from './styled'
import _ from 'lodash'
import './styled.css'
import { Container, StyledButton, StyledNoContentBox } from './styled'
import { isMediaWidth, isMediaWidthMoreThan } from '../../../../../utils/lib'
import Template from '../../../../../components/Template'
import { handleCourseValue } from './events'

const List = ({ courses }) => {
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('lg')
  return (
    <Container>
      <Template noMinHeight maxWidth={1280}>
        <Box sx={{ width: '100%', minHeight: '325px', mt: 2 }}>
          <Box
            sx={{
              minWidth: 342,
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px',
            }}
          >
            <StyledRowHeader
              variant={isMobile ? 'h6' : 'h5'}
              color="primary.main"
            >
              รายการหลักสูตร ({_.get(courses, 'length', 0)})
            </StyledRowHeader>
          </Box>
          {_.get(courses, 'length', 0) > 0 ? (
            isLaptop ? (
              <Box
                sx={{
                  minWidth: 342,
                  minHeight: isMobile ? 225 : 550,
                  paddingTop: '10px',
                }}
              >
                <Grid container spacing={4} sx={{ height: 530, width: 'auto' }}>
                  {courses.map((course, index) => {
                    const { courseData, sectionKey } = handleCourseValue(course)
                    return (
                      <Grid item key={index} md={6} lg={3}>
                        <ELearningCourseCard
                          course={_.get(courseData, 'content', {})}
                          keySection={sectionKey}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            ) : (
              <Box sx={{ minWidth: 342, minHeight: isMobile ? 220 : 530 }}>
                <Carousel
                  showArrows={false}
                  showStatus={false}
                  showIndicators={true}
                  centerMode={true}
                  showThumbs={false}
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={20}
                  centerSlidePercentage={isMobile ? 82 : 45}
                  className={
                    isMobile ? 'carousel-root-mobile' : 'carousel-root-laptop'
                  }
                >
                  {courses.map((course, index) => {
                    const { courseData, sectionKey } = handleCourseValue(course)
                    return (
                      <Box key={index} sx={{ maxWidth: 296, height: '411px' }}>
                        <ELearningCourseCard
                          course={_.get(courseData, 'content', {})}
                          keySection={sectionKey}
                        />
                      </Box>
                    )
                  })}
                </Carousel>
              </Box>
            )
          ) : (
            <StyledNoContentBox sx={{}}>
              <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <img width={95} height={78} src="/icon/ic_sleeping.svg" />
                <Typography
                  variant="h5"
                  sx={{
                    mx: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  ไม่พบข้อมูล
                </Typography>
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    window.location.href = `/e-learning/course/`
                  }}
                  sx={{ mx: 'auto' }}
                >
                  <Typography color="white" sx={{}}>
                    ดูทั้งหมด{' '}
                  </Typography>
                </StyledButton>
              </Box>
            </StyledNoContentBox>
          )}
        </Box>
      </Template>
    </Container>
  )
}

export default List
