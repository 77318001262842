import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  eCertificationFilter,
  eLearningCourse,
  eLearningCourseDraft,
  eLearningCourseBatch,
  eLearningCourseBatchFilter,
  eLearningCourseFilter,
  eLearningCourseUploadFile,
  eLearningModuleFilter,
  filterAcquiredSkill,
  filterLicense,
  eLearningAdminList,
  eLearningTrainerList,
  eLearningCourseBatchLearner,
  eLearningCourseBatchLearnerCancel,
  eLearningCourseBatchLearnerUpload,
  eLearningCourseBatchAnnounceLearner,
  eLearningCourseBatchLearnerAgentCheck,
  eLearningTrainerQuestion,
} from '../../../utils/apiPath'
import {
  DEFUALT_FILE_TYPE,
  DEFUALT_MODULE_TYPE,
} from '../../../modules/ELearning/Module/model'
import { COURSE_STATUS, MODULE_STATUS } from '../../../constants/eLearning'

export const getModuleList = createAsyncThunk(
  'eLearningCourseForm/getModuleList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        status: [MODULE_STATUS.ACTIVE],
        moduleType: DEFUALT_MODULE_TYPE,
        fileType: DEFUALT_FILE_TYPE,
        name: '',
        moduleCode: '',
        createdBy: '',
        updatedBy: '',
      }
      const response = await axios.post(eLearningModuleFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getELearningList = createAsyncThunk(
  'eLearningCourseForm/getELearningList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: 'ASC',
        sort: 'updatedAt',
        status: [MODULE_STATUS.ACTIVE],
        courseType: [],
        courseCode: '',
        name: '',
        nameForLearner: '',
        createdBy: '',
        updatedBy: '',
      }
      const response = await axios.post(eLearningCourseFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseList = createAsyncThunk(
  'eLearningCourseForm/getLicenseList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        category: '',
        description: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
        licenseId: '',
        createdBy: '',
        updatedBy: '',
      }
      const response = await axios.post(filterLicense, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getECertificationList = createAsyncThunk(
  'eLearningCourseForm/getECertificationList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        codeId: '',
        name: '',
        createdBy: '',
        updatedBy: '',
        limit: 20,
        order: 'ASC',
        page: -1,
        sort: 'codeId',
        status: ['ACTIVE'],
      }
      const response = await axios.post(eCertificationFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAcquiredSkillList = createAsyncThunk(
  'eLearningCourseForm/getAcquiredSkillList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        acquiredSkill: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      const response = await axios.post(filterAcquiredSkill, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadCoverImage = createAsyncThunk(
  'eLearningCourseForm/postUploadCoverImage',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseUploadFile, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateCourse = createAsyncThunk(
  'eLearningCourseForm/postCreateCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourse, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCreateCourse = createAsyncThunk(
  'eLearningCourseForm/putCreateCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourse, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateCourseDraft = createAsyncThunk(
  'eLearningCourseForm/postCreateCourseDraft',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseDraft, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCreateCourseDraft = createAsyncThunk(
  'eLearningCourseForm/putCreateCourseDraft',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseDraft, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postGetCourseBatch = createAsyncThunk(
  'eLearningCourseForm/postGetCourseBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseBatchFilter, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postGetAllCourseBatch = createAsyncThunk(
  'eLearningCourseForm/postGetAllCourseBatch',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        uuid,
        limit: -1,
        page: 1,
        order: 'DESC',
        sort: 'name',
        status: [
          COURSE_STATUS.DRAFT,
          COURSE_STATUS.WAITING,
          COURSE_STATUS.INPROGRESS,
          COURSE_STATUS.SUCCESS,
          COURSE_STATUS.COMPLETED,
          COURSE_STATUS.OPENREGISTRATION,
          COURSE_STATUS.CLOSEREGISTRATION,
        ],
        name: '',
        nameSpearker: '',
        periodStart: '',
        periodEnd: '',
      }
      const response = await axios.post(eLearningCourseBatchFilter, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCreateBatch = createAsyncThunk(
  'eLearningCourseForm/postCreateBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseBatch, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putEditBatch = createAsyncThunk(
  'eLearningCourseForm/putEditBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseBatch, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getBatchDetail = createAsyncThunk(
  'eLearningCourseForm/getBatchDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatch}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getBatchLearnerDetail = createAsyncThunk(
  'eLearningCourseForm/getBatchLearnerDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchLearner}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postAddCourseBatchLearner = createAsyncThunk(
  'eLearningCourseForm/postAddLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseBatchLearner, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
export const postUploadCourseBatchLearner = createAsyncThunk(
  'eLearningCourseForm/postUploadLearner',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        eLearningCourseBatchLearnerUpload,
        formData,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postCourseBatchLearnerAgentCheck = createAsyncThunk(
  'eLearningCourseForm/postCourseBatchLearnerAgentCheck',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        eLearningCourseBatchLearnerAgentCheck,
        formData,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCourseBatchLearnerCancel = createAsyncThunk(
  'eLearningCourseForm/putCourseBatchLearnerCancel',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseBatchLearnerCancel, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteCourseBatch = createAsyncThunk(
  'eLearningCourseForm/deleteCourseBatch',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${eLearningCourseBatch}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAdminList = createAsyncThunk(
  'eLearningCourseForm/getAdminList',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningAdminList}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getTrainerList = createAsyncThunk(
  'eLearningCourseForm/getTrainerList',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningTrainerList}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getTrainerQuestion = createAsyncThunk(
  'eLearningCourseForm/getTrainerQuestion',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningTrainerQuestion)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteCourse = createAsyncThunk(
  'eLearningCourseForm/deleteCourse',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${eLearningCourse}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const announceCourseBatchLearner = createAsyncThunk(
  'eLearningCourseForm/announceCourseBatchLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        eLearningCourseBatchAnnounceLearner,
        body,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
