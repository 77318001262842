import _ from 'lodash'
import {
  setFilterTotal,
  setToggleFilterDrawer,
} from '../../../../redux/slices/table'
import { onFilterClick } from '../Table/event'
import { defaultFilter } from './model'

export const handleFiltering = (props) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(props))
}

export const handleInitialFilter = (tableFilter, filterMemo) => (dispatch) => {
  const filterProp = _.get(tableFilter, 'filterProp', {})
  const statusList = _.get(filterProp, 'status', [])

  let newFilter = { ...defaultFilter }
  if (filterMemo && filterProp?.status) newFilter = { ...filterMemo }
  newFilter.statusChecked = statusList.length > 0
  newFilter.status = {
    unassignChecked: checkHaveStatus(statusList, 'UNASSIGN'),
    todoChecked: checkHaveStatus(statusList, 'TODO'),
    inProgressChecked: checkHaveStatus(statusList, 'INPROGRESS'),
    closedChecked: checkHaveStatus(statusList, 'CLOSED'),
    cancelChecked: checkHaveStatus(statusList, 'CANCELED'),
  }

  const tableQuickFilter = _.get(tableFilter, 'quickFilter', [])
  const quickFilter = tableQuickFilter === null ? [] : tableQuickFilter
  newFilter.checkDocuments = !!quickFilter.find(
    (item) => item.field === 'checkDocuments' && item.clicked === true
  )
  newFilter.waitingExam = !!quickFilter.find(
    (item) => item.field === 'waitingExam' && item.clicked === true
  )

  dispatch(setFilterTotal(newFilter))
  return newFilter
}

export const checkHaveStatus = (statusList, status) => {
  return statusList.some((item) => item == status)
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter, item) => {
  const objState = {
    ...filter,
    [item.dateStateStart]: selectedDates.startDate,
    [item.dateStateFinish]: selectedDates.endDate,
    [item.dateStateDisplay]: selectedDates.display,
  }
  setFilter(objState)
}
