export const initialState = {
  isLoading: false,
  isLoadingDrawer: false,
  initialState: {
    information: {
      description: '',
      category: '',
      remark: '',
      status: 'ACTIVE',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: '',
      documents: [],
    },
    licenseConfig: [],
    listDistribution: [],
    listDistributionELearning: [],
    defualtListDistribution: [],
    listType: [],
    licenseList: [],
  },
}
