import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  licenseConfigType,
  licenseDistributionList,
  licenseDistributionELearningList,
  license,
  licenseDocument,
  licenseHistory,
  licenseList,
} from '../../utils/apiPath'

export const getLicenseConfigType = createAsyncThunk(
  'masterData/licenseConfigType',
  async (n, { rejectWithValue }) => {
    try {
      const response = await axios.get(licenseConfigType)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseDistributionList = createAsyncThunk(
  'masterData/licenseDistributionList',
  async (n, { rejectWithValue }) => {
    try {
      const response = await axios.get(licenseDistributionList)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseDistributionELearningList = createAsyncThunk(
  'masterData/licenseDistributionELearningList',
  async (n, { rejectWithValue }) => {
    try {
      const response = await axios.get(licenseDistributionELearningList)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postMasterDataLicense = createAsyncThunk(
  'masterData/postMasterDataLicense',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(license, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putMasterDataLicense = createAsyncThunk(
  'masterData/postMasterDataLicense',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(license, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadDocument = createAsyncThunk(
  'masterData/postUploadDocument',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(licenseDocument, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseById = createAsyncThunk(
  'masterData/getLicenseById',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${license}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseHistory = createAsyncThunk(
  'masterData/getLicenseHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${licenseHistory}${url}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getLicenseList = createAsyncThunk(
  'masterData/licenseList',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(licenseList)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
