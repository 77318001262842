import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'

export const StyledLessonCard = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  background: theme.palette?.info?.background,
  '& .MuiInputBase-root': { background: theme.palette?.background?.paper },
}))

export const StyledLessonHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  svg: { cursor: 'pointer' },
}))

export const StyledFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'start',
}))

export const StyledDragHolder = styled(DragIndicatorTwoTone)(({ theme }) => ({
  color: theme.palette?.grey?.grey400,
  cursor: 'pointer',
}))

export const StyledMiniIconBtn = styled(IconButton)(() => ({
  width: 24,
  height: 24,
}))

export const StyledImage = styled('img')(() => ({
  width: 150,
  height: 100,
  borderRadius: 4,
  border: '1px solid #0000003B',
}))
