import * as yup from 'yup'

export const validationSchema = yup.object({
  name: yup
    .string()
    .max(255, 'ชื่อ Categories กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  description: yup
    .string()
    .nullable()
    .max(3000, 'คำอธิบายกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  active: yup.boolean(),
})
