import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useTheme from '@mui/system/useTheme'

const Index = ({
  boxSx,
  sx,
  type,
  placeholder,
  labelText,
  labelVariant = 'body2',
  required,
  disabled,
  readOnly,
  id,
  name,
  onChange,
  onBlur,
  onClick,
  textError,
  textInfo,
  value,
  defaultValue,
  multiline = false,
  rows,
  startAdornment,
  endAdornmentText,
  inputProps,
  isShowTextError = true,
  dialogRight,
  inputAdd,
  maxRows,
  maxlength = 'unset',
  showMaxLength = 0,
  sxTextError,
  onWheel,
}) => {
  const theme = useTheme()
  const isError = textError && textError !== ''
  const isShowLength = showMaxLength > 0

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...boxSx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={labelVariant}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
        {dialogRight && (
          <Box sx={{ ml: 'auto', display: 'flex' }}>
            <InfoOutlinedIcon
              sx={{
                color: `${theme?.palette?.warning?.main}`,
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{ ml: 1 }}
              variant="body2"
              color={dialogRight.color}
            >
              {dialogRight.text}
            </Typography>
          </Box>
        )}
      </Box>
      <InputBase
        id={id}
        name={name}
        disabled={disabled}
        sx={{
          ...sx,
          borderRadius: 0,
          borderColor:
            textError &&
            textError !== '' &&
            `${theme?.palette?.error?.main} !important`,
        }}
        type={type}
        placeholder={placeholder}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        key={defaultValue}
        defaultValue={defaultValue}
        multiline={multiline}
        inputProps={{
          ...inputProps,
          'data-testid': id,
          maxLength: maxlength,
        }}
        rows={rows}
        maxRows={maxRows}
        inputadd={inputAdd}
        readOnly={readOnly}
        startAdornment={startAdornment}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            {endAdornmentText}
          </InputAdornment>
        }
        onWheel={onWheel}
      />
      {(isShowTextError || textInfo || isShowLength) && (
        <Box
          variant="body2"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: textInfo || isShowLength || isError ? 'unset' : 3,
          }}
        >
          {textInfo && !isError && (
            <Typography height={25} variant="body2" color="text.secondary">
              {textInfo}
            </Typography>
          )}
          {isError && (
            <Typography
              sx={sxTextError ? sxTextError : { height: '25px' }}
              variant="body2"
              color="error"
            >
              {textError}
            </Typography>
          )}
          {isShowLength && (
            <Typography
              sx={{
                height: '25px',
                textAlign: 'end',
                color: value?.length > showMaxLength ? 'error.main' : 'unset',
              }}
              variant="body2"
            >
              {`${value?.length}/${showMaxLength} adsf`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
export default Index
