import React from 'react'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'

const Header = () => {
  const history = useHistory()
  const url = '/master-data/license/form'
  return (
    <>
      <Button
        data-testid="btn-add-learner"
        variant="contained"
        size="m"
        startIcon={<Add />}
        onClick={() => history.push(url)}
      >
        เพิ่มใบอนุญาต
      </Button>
    </>
  )
}

export default Header
