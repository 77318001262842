import React, { useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { overrideBudget, originalBudget } from '../../../../../utils/apiPath'
import { checkDialogValue } from '../../../../../utils/lib'
import TextInput from '../../../../../components/Input/TextInput'
import * as API from '../../../api/Training'

export const handleTrainingActionClick = (
  status,
  setDialog,
  dataList,
  setIsDrawer,
  fetchEvent
) => {
  const error = { reason: null, cost: null }
  let value = { reason: null, cost: null }
  let title =
    status === 'EDIT'
      ? 'แก้ไขค่าใช้จ่าย'
      : 'ไม่อนุมัติค่าใช้จ่ายที่ขอมาใหม่ และให้ใช้งบเดิม'

  if (error.reason === '') {
    error.reason = 'กรุณากรอกข้อมูล'
  }
  if (status === 'EDIT' && error.cost === '') {
    error.cost = 'กรุณากรอกข้อมูลให้'
  }
  let body = {
    reviewedReason: value.reason,
    cost: value.reason,
  }
  setDialog({
    open: true,
    variant: 'reject',
    title: title,
    content: (
      <GetActionPlanContentDialog
        status={status}
        value={value}
        error={error}
        dataList={dataList}
        setDialog={setDialog}
      />
    ),
    value: value,
    error: { reason: '', cost: '' },
    onConfirmClick: () =>
      handleTrainingActionDialog({
        status,
        body,
        error,
        value,
        setDialog,
        dataList,
        setIsDrawer,
        fetchEvent,
      }),
  })
}

export const handleTrainingActionDialog = ({
  status,
  body,
  error,
  value,
  setDialog,
  dataList,
  setIsDrawer,
  fetchEvent,
}) => {
  if (
    (status === 'OLD_COST' || status === 'EDIT') &&
    checkDialogValue(value, error, 'reason')
  ) {
    error.reason = 'กรุณากรอกข้อมูล'
  }
  if (status === 'EDIT' && checkDialogValue(value, error, 'cost')) {
    error.cost = 'กรุณากรอกข้อมูลให้ถูกต้อง'
  } else if (value.reason !== null && value.reason.length > 3000) {
    error.reason = 'กรุณากรอกเหตุผลให้น้อยกว่า 3000 ตัวอักษร'
  }
  if (
    error.reason !== '' ||
    (status === 'EDIT' && checkDialogValue(value, error, 'cost'))
  ) {
    setDialog((prev) => ({
      ...prev,
      content: (
        <GetActionPlanContentDialog
          status={status}
          value={value}
          error={error}
          dataList={dataList}
          setDialog={setDialog}
        />
      ),
      error: error,
    }))
  } else {
    body.reviewedReason = value.reason
    body.cost = +value.cost
    if (status === 'OLD_COST') {
      delete body.cost
    }

    const path = status === 'EDIT' ? overrideBudget : originalBudget
    const uuid = dataList.uuid
    API.submitTrainingBudgetAction({
      uuid: uuid,
      path: path,
      body: body,
      onSuccess: () => onSuccess(setDialog, fetchEvent),
      onError: () => onError(setDialog, setIsDrawer, fetchEvent),
    })
  }
}

export const onError = (setDialog, setIsDrawer, fetchEvent) => {
  setDialog({
    open: true,
    variant: 'fail',
    title: 'ผิดพลาด',
    onConfirmClick: () => {
      fetchEvent()
      setIsDrawer((prev) => ({ ...prev, status: false }))
      setDialog((prev) => ({ ...prev, open: false }))
    },
  })
}

export const onSuccess = (setDialog, fetchEvent) => {
  fetchEvent()
  setDialog({
    open: true,
    variant: 'success',
    title: 'สำเร็จ',
    onConfirmClick: () => {
      setDialog((prev) => ({ ...prev, open: false }))
    },
  })
}

export const GetActionPlanContentDialog = ({
  status,
  value,
  error,
  dataList,
  setDialog,
}) => {
  const [valueCost, setValueCost] = useState(_.get(value, 'cost', ''))

  const contentText =
    status === 'EDIT'
      ? 'กรุณากรอกจำนวนค่าใช้จ่ายที่ต้องการอนุมัติสำหรับคลาสนี้'
      : 'กรุณาให้เหตุผลที่ไม่อนุมัติค่าใช้จ่ายที่ขอมา'
  const breakMealBudget = _.get(dataList, 'course.breakMealBudget', 0)
  const cost = _.get(dataList, 'cost', 0)

  return (
    <>
      <Typography>{contentText}</Typography>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Typography sx={{ width: 70 }}>งบเดิม</Typography>
        <Typography> {Number(breakMealBudget).toFixed(2)} บาท</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 3, pb: 2 }}>
        <Typography
          sx={{ width: 70, fontWeight: status === 'EDIT' ? 'bold' : 'unset' }}
        >
          งบที่ขอ
        </Typography>
        <Typography sx={{ fontWeight: status === 'EDIT' ? 'bold' : 'unset' }}>
          {Number(cost).toFixed(2)} บาท
        </Typography>
      </Box>

      {status === 'EDIT' && (
        <TextInput
          id="cost"
          name="cost"
          placeholder="ระบุจำนวน"
          labelText="ค่าใช้จ่ายต่อหัว ที่ต้องการอนุมัติให้"
          required
          maxlength={6}
          type="basic"
          inputAdd={'adorned'}
          endAdornmentText={'บาท'}
          textError={_.get(error, 'cost', '')}
          value={valueCost}
          isShowTextError={true}
          onChange={(e) => {
            const newValue = e.target.value.replace(/[^\d]/g, '')
            if (newValue !== '') {
              error.cost = ''
            }
            value.cost = newValue
            setValueCost(newValue)
            setDialog((prev) => ({
              ...prev,
              value: { ...prev.value, cost: newValue },
            }))
          }}
        ></TextInput>
      )}
      <TextInput
        id="reason"
        name="reason"
        labelText="เหตุผล"
        type="basic"
        required
        textError={_.get(error, 'reason', '')}
        isShowTextError={true}
        onChange={(e) => {
          if (e.target.value !== '') {
            error.reason = ''
          }

          value.reason = e.target.value
          setDialog((prev) => ({
            ...prev,
            value: { ...prev.value, reason: e.target.value },
          }))
        }}
        multiline={true}
        rows={2}
      />
    </>
  )
}
