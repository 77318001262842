import { handleProcessSubmitForm } from './handleProcessSubmitForm'
import { openDialog } from '../../../redux/slices/dialog'
import { store } from '../../../App'
import { replaceFieldError, setFieldError } from '../../../redux/slices/crud'
import handleScrollToError from './handleScrollToError'

export const validateFormData = async (formData, validationSchema) => {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}

function confirmDialog(
  uuid,
  postBodyMutation,
  DIALOG_TITLE = 'ยืนยันบันทึก',
  DIALOG_MESSAGE = 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
) {
  store.dispatch(
    openDialog({
      title: DIALOG_TITLE,
      message: DIALOG_MESSAGE,
      handleConfirm: () => handleProcessSubmitForm(uuid, postBodyMutation),
      isCloseDialog: false,
    }),
  )
}

export const handleSubmitClick = async (
  uuid,
  postBodyMutation,
  validationSchema,
  DIALOG_TITLE,
  DIALOG_MESSAGE,
) => {
  const { formData } = store.getState().crud
  const isValid = await validateFormData(formData, validationSchema)
  if (isValid) {
    confirmDialog(uuid, postBodyMutation, DIALOG_TITLE, DIALOG_MESSAGE)
  }
}
