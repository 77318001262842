import _ from 'lodash'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/table'
import { HISTORY_EVENT } from './model'
import { StyledTable } from './Styled'
import { headerConfig } from '../model'
import { getSettingPointHistory } from '../../../../services/learningPoint/settingPoint'

export const headCellsHistory = [
  {
    id: 'createdBy',
    label: 'ผู้แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `${uuid}?page=${realPage}&limit=${table.limit}`
  dispatch(startLoading())

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: [],
      allCount: 0,
      headCells: headCellsHistory,
    }),
  )
  const result = await dispatch(getSettingPointHistory({ url, headerConfig }))
  const payload = _.get(result, 'payload', undefined)

  if (payload) {
    const rows = _.get(result, 'payload.result', []).map((row) => ({
      ...row,
      event: HISTORY_EVENT[row.event] || '',
      createdBy: _.get(row, 'createdBy', ''),
    }))
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows,
        allCount: _.get(result, 'payload.totalCount', 0),
        headCells: headCellsHistory,
        customStyle: StyledTable,
      }),
    )
  }
  dispatch(stopLoading())
}
