import { PRODUCT_TYPE } from '../../../../../constants/stock'
import * as yup from 'yup'

export const notReturnValidation = yup.object({
  drawer: yup
    .object({
      remark: yup
        .string()
        .max(3000, 'กรอกได้ไม่เกิน 3,000 ตัวอักษร')
        .nullable()
        .required('กรุณาระบุ สาเหตุการไม่คืนทรัพย์สิน'),
    })
    .nullable(),
})

const assetSchema = yup
  .object()
  .shape({
    productType: yup.string().nullable(),
    qty: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    returnQty: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
  })
  .test('asset-object', 'รายการไม่ถูกต้อง', function (value) {
    const {
      value: valueField,
      productType,
      qty,
      returnQty,
      withdrawStatus,
    } = value
    const index = this.options.index
    if (valueField) {
      if (
        productType === PRODUCT_TYPE.STOCK &&
        withdrawStatus !== 'NOT_RETURN'
      ) {
        if (!returnQty && returnQty !== 0) {
          return this.createError({
            path: `assets[${index}].returnQty`,
            message: 'กรุณากรอกจำนวน',
          })
        } else if (returnQty > qty) {
          return this.createError({
            path: `assets[${index}].returnQty`,
            message: 'กรุณาระบุข้อมูลให้ถูกต้อง',
          })
        }
      }
    }
    return true
  })

export const ReturnValidation = yup.object({
  assets: yup.array().of(assetSchema),
})

export const rejectValidationSchema = yup.object({
  rejectReason: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุภายใน 3,000 ตัวอักษร')
    .required('กรุณาระบุเหตุผลการปฏิเสธคืน'),
})
