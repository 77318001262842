import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'

import Breadcrumbs from '../../../../components/Breadcrumbs'

export const listBreadcrumb = (uuid) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ใบอนุญาต',
      link: '/master-data/license',
      pointer: true,
    },
    {
      title: uuid ? 'แก้ไขใบอนุญาต' : 'เพิ่มใบอนุญาต',
      link: '/',
      pointer: false,
    },
  ]
}

const Index = () => {
  const { id: uuid } = useParams()
  const [labelText, setLabelText] = useState('')

  const description = useSelector(
    (state) => state.masterDataLicenseForm.initialState.information.description
  )

  useEffect(() => {
    _.isEmpty(labelText) && setLabelText(description)
  }, [description])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h4">
          {uuid ? `แก้ไขใบอนุญาต : ${labelText}` : 'เพิ่มใบอนุญาต'}
        </Typography>
        <Breadcrumbs menuList={listBreadcrumb(uuid)} />
      </Box>
    </Box>
  )
}

export default Index
