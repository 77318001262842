export const tabsDrawerList = [
  {
    label: 'รายการคอลัมน์',
  },
  {
    label: 'เพิ่มคอลัมน์',
  },
]

export const tabsCardList = [
  {
    label: 'มีอยู่แล้ว',
  },
  {
    label: 'สร้างคอลัมน์รวม',
  },
]
