/* eslint-disable no-misleading-character-class */
/* eslint-disable no-useless-escape */
import * as yup from 'yup'
import _ from 'lodash'
import { checkIdCard } from '../../utils/lib'

export const validationSchema = yup.object({
  agentCode: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร'),
  prefix: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  firstName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาอังกฤษเท่านั้น',
      function (value) {
        const regex = /^[a-zA-Z0-9 '";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  lastName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาอังกฤษเท่านั้น',
      function (value) {
        const regex = /^[a-zA-Z0-9 '";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  prefixTH: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  firstNameTH: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
      function (value) {
        if (!value || value === '') return true
        const regex = /^[0-9 ก-๏\s \][{}~`<>|,?'";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  lastNameTH: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
      function (value) {
        if (!value || value === '') return true
        const regex = /^[0-9 ก-๏\s \][{}~`<>|,?'";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  area: yup
    .array()
    .test('test-count', 'กรุณากรอกข้อมูลในช่องนี้ให้ครบทุกช่อง', (value) => {
      if (value && value.length > 0) {
        return true
      }
      return false
    })
    .of(
      yup.object().shape({
        uuid: yup
          .string()
          .test(
            'test-uuid',
            'กรุณากรอกข้อมูลในช่องนี้ให้ครบทุกช่อง',
            function (value) {
              if (!value || value === '') {
                return false
              }
              return true
            },
          ),
      }),
    )
    .required('กรุณากรอกข้อมูลในช่องนี้ให้ครบทุกช่อง'),
  position: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  level: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  jobTitles: yup
    .object()
    .nullable()
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .test('jobTitles', 'กรุณากรอกข้อมูลในช่องนี้', (value) => {
      return !_.isEmpty(_.get(value, 'uuid', ''))
    }),
  joinDate: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  status: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  costCenter: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  reportTo: yup
    .object()
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .test('reportTo', 'กรุณากรอกข้อมูลในช่องนี้', (value) => {
      return !_.isEmpty(_.get(value, 'value', ''))
    }),
  tel: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .length(10, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
    .nullable()
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .test('tel', 'เบอร์โทรศัพท์ไม่ถูกต้อง', (value) => {
      const customValue = _.isNull(value) || _.isUndefined(value) ? '' : value
      return customValue.replace(/-/g, '').replace(/\s/g, '').length == 10
    }),
  idCardNo: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .test('idCardNo', 'เลขบัตรประจำตัวประชาชนไม่ถูกต้อง', (value) => {
      if (!value) return true
      else return checkIdCard(value)
    }),
  email: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  deskNumber: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  image: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})

export const validationNonAcademySchema = yup.object({
  agentCode: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร'),
  prefix: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  firstName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาอังกฤษเท่านั้น',
      function (value) {
        const regex = /^[a-zA-Z0-9 '";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  lastName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาอังกฤษเท่านั้น',
      function (value) {
        const regex = /^[a-zA-Z0-9 '";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  prefixTH: yup.string().required('กรุณากรอกข้อมูลในช่องนี้').nullable(),
  firstNameTH: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
      function (value) {
        if (!value || value === '') return true
        const regex = /^[0-9 ก-๏\s \][{}~`<>|,?'";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  lastNameTH: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
      function (value) {
        if (!value || value === '') return true
        const regex = /^[0-9 ก-๏\s \][{}~`<>|,?'";:!@#\$%\^\&*\)\(+=._-]+$/g
        const test = regex.test(value)
        return test
      },
    ),
  tel: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .length(10, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
    .nullable()
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .test('tel', 'เบอร์โทรศัพท์ไม่ถูกต้อง', (value) => {
      const customValue = _.isNull(value) || _.isUndefined(value) ? '' : value
      return customValue.replace(/-/g, '').replace(/\s/g, '').length == 10
    }),
  idCardNo: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .test('idCardNo', 'เลขบัตรประจำตัวประชาชนไม่ถูกต้อง', (value) => {
      return checkIdCard(value)
    }),
  email: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  birthOfDate: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
})
