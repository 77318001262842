import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const tabList = {
  BACKEND: 'backend',
  LEARNER: 'learner',
}

export const auditLogConst = {
  COLUMN_NAME: {
    MENU: 'menu',
    EVENT: 'event',
    DATA: 'data',
    EVENT_CREATED_BY: 'event_createdBy',
    EVENT_CREATED_AT: 'event_createdAt',
  },
  COLUMN_NAME_TH: {
    MENU: 'เมนู',
    EVENT: 'รายการ',
    DATA: 'รายละเอียด',
    EVENT_CREATED_BY: 'ผู้ดำเนินการ',
    EVENT_CREATED_AT: 'วันที่ทำรายการ',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT_BOX: 'INPUT_TEXT_BOX',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  eventOptions: [
    { label: 'ดาวน์โหลด', value: 'ดาวน์โหลด' },
    { label: 'เปิดดู', value: 'เปิดดู' },
    { label: 'สร้าง', value: 'สร้าง' },
    { label: 'แก้ไข', value: 'แก้ไข' },
    { label: 'ลบ', value: 'ลบ' },
    { label: 'อัปเดต', value: 'อัปเดต' },
  ],
}

export const auditLogDefaultColumn = {
  defaultColumns: [
    {
      id: 2,
      name: auditLogConst.COLUMN_NAME.MENU,
      value: auditLogConst.COLUMN_NAME.MENU,
      label: auditLogConst.COLUMN_NAME_TH.MENU,
      minWidth: 210,
    },
    {
      id: 3,
      name: auditLogConst.COLUMN_NAME.EVENT,
      value: auditLogConst.COLUMN_NAME.EVENT,
      label: auditLogConst.COLUMN_NAME_TH.EVENT,
      minWidth: 160,
    },
    {
      id: 4,
      name: auditLogConst.COLUMN_NAME.DATA,
      value: auditLogConst.COLUMN_NAME.DATA,
      label: auditLogConst.COLUMN_NAME_TH.DATA,
      minWidth: 210,
    },
    {
      id: 5,
      name: auditLogConst.COLUMN_NAME.EVENT_CREATED_BY,
      value: auditLogConst.COLUMN_NAME.EVENT_CREATED_BY,
      label: auditLogConst.COLUMN_NAME_TH.EVENT_CREATED_BY,
      minWidth: 160,
    },
    {
      id: 6,
      name: auditLogConst.COLUMN_NAME.EVENT_CREATED_AT,
      value: auditLogConst.COLUMN_NAME.EVENT_CREATED_AT,
      label: auditLogConst.COLUMN_NAME_TH.EVENT_CREATED_AT,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: auditLogConst.COLUMN_NAME.MENU,
      value: auditLogConst.COLUMN_NAME.MENU,
      label: auditLogConst.COLUMN_NAME_TH.MENU,
      minWidth: 160,
      type: auditLogConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: auditLogConst.COLUMN_NAME.EVENT,
      value: auditLogConst.COLUMN_NAME.EVENT,
      label: auditLogConst.COLUMN_NAME_TH.EVENT,
      minWidth: 160,
      type: auditLogConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: auditLogConst.eventOptions,
    },
    {
      id: 3,
      name: auditLogConst.COLUMN_NAME.EVENT_CREATED_BY,
      value: auditLogConst.COLUMN_NAME.EVENT_CREATED_BY,
      label: auditLogConst.COLUMN_NAME_TH.EVENT_CREATED_BY,
      minWidth: 160,
      type: auditLogConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: auditLogConst.COLUMN_NAME.EVENT_CREATED_AT,
      value: auditLogConst.COLUMN_NAME.EVENT_CREATED_AT,
      label: auditLogConst.COLUMN_NAME_TH.EVENT_CREATED_AT,
      minWidth: 160,
      type: auditLogConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
