import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageClassCheckExam'
import {
  getManageClassExamList,
  getManageClassLearnerExam,
  getStandaloneExamList,
  getStandaloneUser,
} from '../../../services/manageClass/check'

export const fetchManageClassExam = (props) => async (dispatch) => {
  const { idProps, setStateLoading, isStandalone } = props
  dispatch(startLoading())
  if (isStandalone) {
    const { eTestingVersionUuid } = idProps
    await dispatch(getStandaloneExamList(eTestingVersionUuid))
  } else {
    const { id, courseId } = idProps
    const payload = {
      trainingPlanUuid: id,
      courseTestAndEvaUuid: courseId,
    }
    await dispatch(getManageClassExamList(payload))
  }
  setStateLoading(true)
}

export const fetchManageClassLearnerExam = (props) => async (dispatch) => {
  const { learnerList, isStandalone } = props
  dispatch(startLoading())
  if (learnerList.length > 0) {
    if (isStandalone) dispatch(handleGetStandaloneUser(props))
    else dispatch(handleGetLearnerExam(props))
  }
  dispatch(stopLoading())
}

export const handleGetStandaloneUser = (props) => async (dispatch) => {
  const { idProps, learnerList, presentLearner } = props
  let userUuid = _.get(learnerList, '[0].userUuid', '')
  if (presentLearner) {
    const learner = learnerList.find(
      (learner) => learner.userUuid === presentLearner.uuid
    )
    userUuid = _.isEmpty(learner) ? userUuid : _.get(learner, 'userUuid', '')
  }
  const { eTestingVersionUuid } = idProps
  const payload = { eTestingVersionUuid, userUuid }
  await dispatch(getStandaloneUser(payload))
}

export const handleGetLearnerExam = (props) => async (dispatch) => {
  const { idProps, learnerList, presentLearner } = props
  const { id, courseId } = idProps
  let learnerUuid = _.get(learnerList, '[0].learnerUuid', '')
  if (presentLearner) {
    const learner = learnerList.find(
      (learner) => learner.learnerUuid === presentLearner.uuid
    )
    learnerUuid = _.isEmpty(learner)
      ? learnerUuid
      : _.get(learner, 'learnerUuid', '')
  }
  const payload = {
    trainingPlanUuid: id,
    courseTestAndEvaUuid: courseId,
    learnerUuid,
  }
  await dispatch(getManageClassLearnerExam(payload))
}
