import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './style.css'
import { StyledCarousel } from './Styled'

const CarouselLogin = (props) => {
  const {
    children,
    countList,
    ariaLabel,
    infiniteLoop = true,
    autoPlay = true,
    swipeable = true,
    emulateTouch = true,
    showArrows = false,
    showStatus = false,
    showThumbs = false,
    stopOnHover = false,
    interval = 5000,
    swipeScroll = 50,
    renderIndicator,
  } = props
  return (
    <StyledCarousel
      countList={countList}
      ariaLabel={ariaLabel}
      infiniteLoop={infiniteLoop}
      autoPlay={autoPlay}
      swipeable={swipeable}
      emulateTouch={emulateTouch}
      showArrows={showArrows}
      showStatus={showStatus}
      showThumbs={showThumbs}
      stopOnHover={stopOnHover}
      interval={interval}
      swipeScrollTolerance={swipeScroll}
      preventMovementUntilSwipeScrollTolerance
      renderIndicator={renderIndicator}
    >
      {children}
    </StyledCarousel>
  )
}

export default CarouselLogin
