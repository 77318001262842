import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { shallowEqual, useSelector } from 'react-redux'

export function ListHeaderAction(props) {
  const { crudConfig } = useSelector((state) => state.crud, shallowEqual)
  return (
    <Button startIcon={<AddIcon />} variant="contained" onClick={props.onClick}>
      สร้าง {crudConfig.modulesTitleShort}
    </Button>
  )
}
