import { Box, Typography } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragDropContent from './DragDropContent'
import { shallowEqual, useSelector } from 'react-redux'
import { Row, StyledFormCard } from '../Styled'
import AddIcon from '@mui/icons-material/Add'
import LaunchIcon from '@mui/icons-material/Launch'
import { handleAddContentTypeLearningPath } from '../handler/handleAddCotentTypeLearningPath'
import { StyledSmallButton } from './Styled'
import { handleAddContentTypeCourse } from '../handler/handleAddCotentTypeCourse'
import CreateCourseButton from '../CreateCourseButton'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import { handleDragEnd } from './events'
import { CircularContent } from '../../../../../../modules/ELearning/Course/Form'
import { useInputField } from '../../../../../../components/CRUD/hooks/useInputField'

export const AddCollectionDragDrop = () => {
  const dispatch = useDispatch()
  const { contents, isCourseOptionLoading, isLearningPathOptionLoading } =
    useSelector(
      (state) => ({
        contents: state.crud.formData.contents,
        isCourseOptionLoading: state.crud.masterData.course.isLoading,
        isLearningPathOptionLoading:
          state.crud.masterData.learningPath.isLoading,
      }),
      shallowEqual,
    )
  const { isError, message } = useInputField('contents')
  const { setErrors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      {(isCourseOptionLoading || isLearningPathOptionLoading) && (
        <CircularContent />
      )}
      <Typography variant="h6">เพิ่มรายการใน Collection</Typography>
      {isError && (
        <Box>
          {message && message !== '' && (
            <Typography variant="body2" color="error">
              {message}
            </Typography>
          )}
        </Box>
      )}
      <DragDropContext
        onDragEnd={(result) => {
          dispatch(
            handleDragEnd(result, `formData.contents`, setErrors, contents),
          )
        }}
      >
        <Droppable droppableId={'content-list'}>
          {(provided) => (
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
              width="100%"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {contents?.map((content, index) => {
                return (
                  <Draggable
                    index={index}
                    key={`content-${index}`}
                    draggableId={`content-${index}`}
                  >
                    {(provided) => (
                      <DragDropContent
                        content={content}
                        provided={provided}
                        index={index}
                      />
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Row sx={{ gap: 3 }}>
        <StyledSmallButton
          onClick={handleAddContentTypeCourse}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          เพิ่มหลักสูตร
        </StyledSmallButton>
        <CreateCourseButton />
        <StyledSmallButton
          onClick={handleAddContentTypeLearningPath}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ ml: '24px' }}
        >
          เพิ่ม Learning Path
        </StyledSmallButton>
        <StyledSmallButton
          variant="outlined"
          startIcon={<LaunchIcon />}
          onClick={() =>
            window.open('/e-learning/learning-path/form', '_blank')
          }
        >
          สร้าง Learning Path
        </StyledSmallButton>
      </Row>
    </StyledFormCard>
  )
}
