import _ from 'lodash'
import * as yup from 'yup'

export const mainBannerSchema = yup.object().shape({
  image: yup
    .string()
    .test('text-validate', 'กรุณาอัปโหลดรูปภาพ', (value) => value),
  subtitle: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  title: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})

export const titleSchema = yup.object().shape({
  subtitle: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
  title: yup.string().max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})

export const stationSchema = yup.object({
  status: yup.boolean(),
  img: yup
    .string()
    .test('require', '', (value, { parent }) => !parent.status || value),
  imgMap: yup
    .string()
    .test('require', '', (value, { parent }) => !parent.status || value),
  title: yup.string(),
  description: yup.string(),
  phoneNumber: yup
    .string()
    .test('require', '', (value, { parent }) => !parent.status || value),
  googleMap: yup
    .string()
    .test('require', '', (value, { parent }) => !parent.status || value),
  remark: yup
    .string()
    .test(
      'max',
      '',
      (value, { parent }) =>
        !parent.status || _.isEmpty(value) || value.length <= 3000
    ),
})

export const schema = yup.object({
  mainBannerInformation: mainBannerSchema,
  titleInformation: titleSchema,
  stationInformation: yup.array().of(stationSchema),
})
