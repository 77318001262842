import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { BoxLine } from '../Styled'
import { mapLabelCourse, mapType } from '../events'

const Index = (props) => {
  const {
    listContents,
    listDistribution,
    distribution,
    licenseList,
    lastSubGroup,
    listDistributionELearning,
  } = props

  return listContents.map((item, index) => {
    const uuid = _.get(item, 'courseOrLicenseUuid', '')
    const type = _.get(item, 'type', '')
    const labelCourse = mapLabelCourse({
      listDistribution,
      listDistributionELearning,
      distribution,
      uuid,
      licenseList,
      type,
    })

    const labelType = `ผ่าน ${mapType(type)}`
    let condition = 'หรือ'
    if (!lastSubGroup && index + 1 == listContents.length) condition = 'และ'
    else if (lastSubGroup && listContents.length == index + 1) condition = ''
    return (
      <Box key={uuid}>
        <Box sx={{ ml: 4, display: 'flex' }}>
          <Typography sx={{ ml: 1 }} variant="body1" color="text.secondary">
            {labelType}
          </Typography>
          <Box>
            <Box sx={{ display: 'flex', gap: 1, pl: 1 }}>
              <Typography variant="body1">{labelCourse}</Typography>
              <Typography variant="body1" color="text.lightGray">
                {condition == 'และ' ? '' : condition}
              </Typography>
            </Box>
          </Box>
        </Box>
        {condition == 'และ' && (
          <Box sx={{ display: 'flex', px: 5 }}>
            <Typography variant="body1" color="text.lightGray">
              {condition}
            </Typography>
            <BoxLine />
          </Box>
        )}
      </Box>
    )
  })
}

export default Index
