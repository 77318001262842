import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { StyledCard } from './Styled'
import Add from '@mui/icons-material/Add'
import MaterialSettingForm from './MaterialSettingForm'
import MaterialSettingView from './MaterialSettingView'

const MaterialSetting = ({ initialValues, setInitialValues, formik }) => {
  const [openModal, setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  return (
    <StyledCard>
      <Typography variant="h6" sx={{ display: 'flex' }}>
        เอกสารประกอบการเรียนสำหรับผู้อบรม
      </Typography>
      <MaterialSettingView
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        formik={formik}
      />
      <Button
        data-testid="btn-add"
        variant="contained"
        size="l"
        startIcon={<Add />}
        sx={{ mt: 1 }}
        onClick={() => handleOpen()}
      >
        เพิ่มเอกสาร
      </Button>
      <MaterialSettingForm
        openModal={openModal}
        handleClose={handleClose}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
      />
    </StyledCard>
  )
}

export default MaterialSetting
