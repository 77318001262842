import styled from '@mui/system/styled'
import Box from '@mui/material/Box'

export const Container = styled(Box)(({ theme, background }) => ({
  padding: `${theme.spacing(1.25)} ${theme.spacing(3.25)}`,
  background: background && background,
  borderRadius: theme.spacing(1),
}))

export const Dot = styled(Box)(({ background }) => ({
  position: 'absolute',
  top: 'calc(50% - 4px)',
  left: '-16px',
  width: 8,
  height: 8,
  background: background,
  borderRadius: '50%',
}))
