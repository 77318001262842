import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import { CustomDropdown } from '../../../../components/CRUD/components/CustomDropdown'
import { CustomRadioInput } from '../../../../components/CRUD/components/CustomRadioInput'
import useLocalStorage from '@rehooks/local-storage'
import { getQueryParams, validatePermission } from '../../../../utils/lib'
import { Box, Typography } from '@mui/material'
import { handleStartChange } from './handler/handleStartChange'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  STOCK_TYPE,
} from '../../../../constants/stock'
import { fetchAssetData } from './handler/fetchAssetData'
import { handleOnFocus } from './handler/handleOnFocus'
import { getSkuNo } from './utils'
import { fromTypeEnum } from './enum/assetTypEnum'

export const Content = () => {
  const { formData, from, to, fromMasterData, toMasterData } = useSelector(
    (state) => ({
      formData: state.crud.formData,
      from: state.crud.formData.from,
      to: state.crud.formData.to,
      fromMasterData: state.crud.masterData.from,
      toMasterData: state.crud.masterData.to,
    }),
    shallowEqual,
  )
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })

  const [fromOptions, setFromOptions] = useState({
    isLoading: false,
    options: [],
  })
  useEffect(() => {
    if (fromMasterData) {
      const selectedTo = to
      if (selectedTo?.value) {
        let filteredFromOptions = fromMasterData?.options.filter((item) => {
          return selectedTo?.value != item?.value
        })

        if(selectedTo?.warehouseType === STOCK_TYPE.PROVIDER){
          filteredFromOptions = filteredFromOptions.filter((item) => {
            return item?.value === fromTypeEnum.IMPORT_PRODUCT
          })
        }
        setFromOptions({ isLoading: false, options: filteredFromOptions })
      } else {
        setFromOptions(fromMasterData)
      }
    }
  }, [to])

  const [toOptions, setToOptions] = useState({
    isLoading: false,
    options: [],
  })
  useEffect(() => {
    if (toMasterData) {
      const selectedFrom = from
      if (selectedFrom?.value) {
        let filteredToOptions = toMasterData?.options.filter((item) => {
          return selectedFrom?.value != item?.value
        })

        if (
          !hasPermissionAdmin &&
          selectedFrom?.value === fromTypeEnum.IMPORT_PRODUCT
        ) {
          const staffStationUuid = _.get(user, 'stationUuid', '')
          filteredToOptions = filteredToOptions.filter((item) => {
            return (
              item.warehouseType === STOCK_TYPE.ACADEMY &&
              item.stationUuid === staffStationUuid
            )
          })
          if (to && to.stationUuid !== staffStationUuid) {
            store.dispatch(
              setFieldValue({
                key: 'formData.to',
                value: '',
              }),
            )
          }
        }
        if (selectedFrom?.value !== fromTypeEnum.IMPORT_PRODUCT) {
          filteredToOptions = filteredToOptions.filter((item) => {
            return item.warehouseType === STOCK_TYPE.ACADEMY
          })
        }
        setToOptions({ isLoading: false, options: filteredToOptions })
      } else {
        setToOptions(toMasterData)
      }
      //render table
      fetchAssetData({
        search: '',
      })
    }
  }, [from])

  useEffect(() => {
    const paramWarehouse = getQueryParams('warehouse')
    if (fromMasterData) {
      const selectedFrom = fromMasterData?.options.find(
        (item) => item?.value === paramWarehouse,
      )
      store.dispatch(
        setFieldValue({
          key: 'formData.from',
          value: selectedFrom ?? '',
        }),
      )
    }
  }, [fromMasterData])

  useEffect(() => {
    const paramTo = getQueryParams('to')
    if (toMasterData) {
      const selectedTo = toMasterData?.options.find(
        (item) => item?.value === paramTo,
      )
      store.dispatch(
        setFieldValue({
          key: 'formData.to',
          value: selectedTo ?? '',
        }),
      )
    }
  }, [toMasterData])

  useEffect(() => {
    if (toMasterData && toMasterData) {
      setFromOptions(fromMasterData)
      setToOptions(toMasterData)
    }
  }, [fromMasterData, toMasterData])

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
        <CustomDropdown
          required
          labelName="ต้นทาง"
          fieldName="from"
          handleExtraChange={handleStartChange}
          placeholder="เลือกต้นทาง"
          isCustomOption={true}
          customOptions={fromOptions}
          disabled={!hasPermissionCreate}
          onFocus={handleOnFocus}
        />
        <CustomDropdown
          required
          labelName="ปลายทาง"
          fieldName="to"
          disabled={!hasPermissionCreate}
          placeholder="เลือกปลายทาง"
          isCustomOption={true}
          customOptions={toOptions}
          handleExtraChange={getSkuNo}
        />
      </Box>
      <CustomRadioInput
        required
        labelName="ประเภททรัพย์สิน"
        fieldName="type"
        isRow={true}
        disabled={!_.isNil(formData.uuid)}
      />
      <CustomTextArea
        disabled={!hasPermissionCreate}
        fieldName="remark"
        labelName="หมายเหตุ"
        placeholder="กรอกหมายเหตุ"
      />
    </>
  )
}
