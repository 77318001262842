import _ from 'lodash'
import { setChangeBody } from '../../../../../../redux/slices/manageContent/home'

export const handleInputChange = (e, setValue) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  setValue((prev) => ({ ...prev, [key]: value }))
}

export const handleSubmitData = (value, no) => (dispatch) => {
  const payload = {
    value,
    key: `trainingPlatformInformation.platforms[${no - 1}]`,
  }
  dispatch(setChangeBody(payload))
}

export const handleSwitchChange = (e, setValue) => {
  const key = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  setValue((prev) => ({ ...prev, [key]: checked }))
}
