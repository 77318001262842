import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { cancelSpeakerBorrow, statusSpeakerBorrow } from '../../utils/apiPath'

export const cancelBorrow = createAsyncThunk(
  'monthlyPlan/cancelBorrow',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${cancelSpeakerBorrow}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putApproveBorrow = createAsyncThunk(
  'monthlyPlan/putApproveBorrow',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(statusSpeakerBorrow, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
