import { StyledColumn } from '../Styled'
import { List, TablePagination, Typography } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { handleChangePage, handleChangeRowsPerPage } from './event'
import dayjs from 'dayjs'
import ButtonRequestType from './components/ButtonRequestType'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { RoomRequestItem } from './components/RoomRequestItem'
import { ROW_PAGE_PER_LIST } from '../../../constants/table'

dayjs.extend(isSameOrBefore)

export const RoomRequest = () => {
  const { roomRequestList, rowsPerPage, allCount, tablePage, page } =
    useSelector(
      (state) => ({
        roomRequestList: state.roomManagement.roomRequestList,
        rowsPerPage: state.roomManagement.rowsPerPage,
        allCount: state.roomManagement.allCount,
        tablePage: state.roomManagement.tablePage,
        page: state.roomManagement.page,
      }),
      shallowEqual,
    )
  const dispatch = useDispatch()

  const groupedByDate = _.groupBy(
    roomRequestList,
    (roomRequest) => roomRequest?.createdAt,
  )

  return (
    <>
      {!_.isEmpty(groupedByDate) ? (
        <StyledColumn sx={{ my: 2 }}>
          <ButtonRequestType />
          <List sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
            {_.map(groupedByDate, (roomRequestList, idx) => {
              return (
                <StyledColumn key={idx} sx={{ gap: 2 }}>
                  <Typography sx={{ mt: 2 }} variant="body1b">
                    {idx}
                  </Typography>
                  {_.map(roomRequestList, (roomRequest, index) => {
                    return <RoomRequestItem key={index} {...roomRequest} />
                  })}
                </StyledColumn>
              )
            })}
            <TablePagination
              component="div"
              count={allCount}
              page={tablePage < 0 ? 1 : +tablePage}
              rowsPerPageOptions={_.filter(
                ROW_PAGE_PER_LIST,
                (item) => item.value !== -1,
              )}
              onPageChange={(e, newPage) => {
                dispatch(handleChangePage(e, newPage))
              }}
              onRowsPerPageChange={(e) => dispatch(handleChangeRowsPerPage(e))}
              rowsPerPage={rowsPerPage.value}
              labelRowsPerPage={
                <Typography variant="caption" color="text.secondary">
                  แถวต่อหน้า :{' '}
                </Typography>
              }
              labelDisplayedRows={() => {
                const realPage = tablePage <= 0 ? 1 : tablePage + 1
                let minRowsShowed =
                  page < 0 ? 1 : rowsPerPage.value * tablePage + 1
                let maxRowsShowed =
                  rowsPerPage.value * realPage > allCount
                    ? allCount
                    : rowsPerPage.value * realPage

                if (maxRowsShowed < 0) {
                  maxRowsShowed = allCount
                }
                if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

                return (
                  <Typography variant="caption">
                    {minRowsShowed}-{maxRowsShowed} of {allCount}
                  </Typography>
                )
              }}
            />
          </List>
        </StyledColumn>
      ) : (
        <StyledColumn sx={{ my: 2 }}>
          <ButtonRequestType />
          <StyledColumn
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              minHeight: 500,
            }}
          >
            <img src={`/image/no-room-request.svg`} width={180} height={180} />
            <Typography
              sx={{ textAlign: 'center' }}
              variant="body1"
              color="text.lightGray"
            >
              ไม่มีรายการจองห้อง ณ ขณะนี้
            </Typography>
          </StyledColumn>
        </StyledColumn>
      )}
    </>
  )
}
