import _ from 'lodash'
import { MODULE_STATUS, headCellsCatalog } from '../../constants/learningPoint'
import {
  resetPage,
  setFilterProp,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
} from '../../redux/slices/table'
import { initialState } from '../../redux/slices/table/model'
import { CRUDListDownloadPath, CRUDListFilterPath } from '../../utils/apiPath'
import { DEFUALT_MODULE_STATUS, headerConfig } from './model'
import axios from 'axios'
import { downloadFile } from '../../services/util'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../redux/slices/dialog'
import { deleteCatalog } from '../../services/learningPoint/catalog'
import { getFormatDate } from '../../utils/lib'

export const fetchDataList = (props) => async (dispatch) => {
  const { method, table, page, status, filterProp, keyword, setInitial } = props
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial
    ? { point: '', qty: '' }
    : filterProp || { point: '', qty: '' }
  const title = isInitial ? '' : _.get(filter, 'name', keyword)
  const body = {
    limit: _.get(tableProps, 'limit', '100'),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    ...filter,
    name: method === 'filter' ? keyword : title,
    status: isInitial ? DEFUALT_MODULE_STATUS : status,
    id: _.get(filterProp, 'id', ''),
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    createStartDate: _.get(filterProp, 'createStartDate', ''),
    createFinishDate: _.get(filterProp, 'createFinishDate', ''),
    updateStartDate: _.get(filterProp, 'updateStartDate', ''),
    updateFinishDate: _.get(filterProp, 'updateFinishDate', ''),
  }
  dispatch(startLoading())
  await axios
    .post(CRUDListFilterPath, body, headerConfig)
    .then((res) => {
      dispatch(
        setInitialTable({
          rows: _.get(res, 'data.result', []),
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCellsCatalog,
          placeholder: 'ค้นหาชื่อของรางวัล',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
          onDelete: (row) => dispatch(handleDeleteModule(row, tableProps)),
        }),
      )
      dispatch(setSelected([]))
    })
    .catch((e) => {
      dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: headCellsCatalog,
        }),
      )
      dispatch(setSelected([]))
      return e.message?.includes('method')
    })
  setInitial && setInitial(false)
  dispatch(stopLoading())
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        page: 1,
        status: DEFUALT_MODULE_STATUS,
        keyword,
      }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter(DEFUALT_MODULE_STATUS))
  }
}

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(
    downloadFile({
      url: CRUDListDownloadPath,
      body: { uuidList: selected, sort, order: order.toString().toUpperCase() },
      fileName: `รายการ Catalog.xlsx`,
      ...headerConfig,
    }),
  )
  dispatch(stopLoading())
}

export const handleDeleteModule = (row, table) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบ ',
      message: 'คุณต้องการลบของรางวัลใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid, table)),
    }),
  )
}

export const handleConfirmDelete = (uuid, table) => async (dispatch) => {
  dispatch(loadingDialog())
  const response = await dispatch(deleteCatalog({ uuid, headerConfig }))
  dispatch(stopLoadingDialog())

  if (_.isNil(response.error)) {
    dispatch(
      openDialog({
        isCloseDialog: false,
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: async () => {
          dispatch(closeDialog())
          dispatch(startLoading())
          await dispatch(
            fetchDataList({
              method: 'initial',
              table,
              page: 1,
              status: [],
              keyword: '',
            }),
          )
          dispatch(stopLoading())
        },
      }),
    )
  } else {
    dispatch(closeDialog())
  }
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(MODULE_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      status.push(MODULE_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      status.push(MODULE_STATUS.DELETED)
    }
    if (filter.status.draftChecked) {
      status.push(MODULE_STATUS.DRAFT)
    }
  } else {
    status = DEFUALT_MODULE_STATUS
  }
  dispatch(setStatusFilter(status))

  const filterProp = {
    name: filter.nameChecked ? filter.name : '',
    point: filter.pointChecked ? filter.point : '',
    qty: filter.qtyChecked ? filter.qty : '',
    status: status,
    id: filter.idChecked ? filter.id : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    createStartDate: filter.createDateChecked
      ? getFormatDate(filter.createStartDate ?? '', 'YYYY-MM-DD')
      : '',
    createFinishDate: filter.createDateChecked
      ? getFormatDate(filter.createFinishDate ?? '', 'YYYY-MM-DD')
      : '',
    updateStartDate: filter.updateDateChecked
      ? getFormatDate(filter.updateStartDate ?? '', 'YYYY-MM-DD')
      : '',
    updateFinishDate: filter.updateDateChecked
      ? getFormatDate(filter.updateFinishDate ?? '', 'YYYY-MM-DD')
      : '',
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      status,
      filterProp,
      keyword: filterProp.name,
    }),
  )
}
