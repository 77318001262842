import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import _ from 'lodash'
import { COURSE_TYPE } from '../../../../../../../../constants/eLearning'
import ContentTab from '../ContentTab'
import GetReadyTab from '../GetReadyTab'
import { StyledTabContainer, StyledTabWrapper } from './Styled'
import { TABS } from './model'

const Tabs = () => {
  const [tab, setTab] = useState(TABS.CONTENT)
  const courseType = useSelector(
    (state) =>  _.get(state.eLearningPathPreview.course, 'courseType', ''),
    shallowEqual,
  )
  return (
    <StyledTabContainer>
      <StyledTabWrapper>
        <MuiTabs
          variant="fullWidth"
          value={tab}
          onChange={(e, tab) => setTab(tab)}
        >
          <Tab label="เนื้อหาหลักสูตร" value={TABS.CONTENT} />
          {courseType === COURSE_TYPE.OIC && (
            <Tab label="เตรียมพร้อมก่อนอบรม" value={TABS.GET_READY} />
          )}
        </MuiTabs>
      </StyledTabWrapper>
      {tab === TABS.CONTENT && <ContentTab />}
      {tab === TABS.GET_READY && <GetReadyTab />}
    </StyledTabContainer>
  )
}

export default Tabs
