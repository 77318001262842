export const filterItems = [
  {
    label: `วันที่อบรม`,
    nameCheckBox: 'startDateChecked',
    nameInput: 'date',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'startDateDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `วันที่ทำแบบทดสอบ`,
    nameCheckBox: 'startTestDateChecked',
    nameInput: 'startTestDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'startTestDate',
    dateStateFinish: 'endTestDate',
    dateStateDisplay: 'startTestDateDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: `ผู้ทำแบบทดสอบ`,
    nameCheckBox: 'userProfileUuidChecked',
    nameInput: 'userProfileUuid',
    placeholder: `เลือก รายชื่อ`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
  },

  {
    label: `หลักสูตร`,
    nameCheckBox: 'courseCodeChecked',
    nameInput: 'courseCode',
    placeholder: `เลือกหลักสูตร`,
    type: 'select_dropdown_with_sub_dropdown',
    label2: `คลาส / รอบอบรม`,
    nameCheckBox2: 'classUuidChecked',
    nameInput2: 'classUuid',
    placeholder2: `เลือก คลาส / รอบอบรม`,
    fetchOptions: 'ByKey',
  },
  {
    label: `ช่องทางการจัดจำหน่าย`,
    nameCheckBox: 'distributionChecked',
    nameInput: 'distribution',
    placeholder: `เลือก ช่องทางการจัดจำหน่าย`,
    type: 'select_dropdown',
    fetchOptions: 'masterData',
    isReadOnly: true,
  },
  {
    label: `RAM`,
    nameCheckBox: 'ramCodeChecked',
    nameInput: 'ramCode',
    placeholder: `เลือก RAM`,
    type: 'select_dropdown',
    fetchOptions: 'ByKey',
    isReadOnly: true,
  },
  {
    label: `Trainer`,
    nameCheckBox: 'trainerChecked',
    nameInput: 'trainer',
    placeholder: `เลือก Trainer`,
    type: 'select_dropdown',
    fetchOptions: 'masterData',
  },
]
