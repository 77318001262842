import _ from 'lodash'

export const getOptionsStaffProfile = (data) => {
  return _.map(data, (item) => {
    return {
      ...item,
      label: `${item['firstNameTH']} ${item['lastNameTH']}`,
      value: item['uuid'],
    }
  })
}
