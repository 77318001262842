import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'

import React, { useRef, useState } from 'react'
import { handleClose } from './events'
import { StyledButton, StyledPopper } from './Styled'

const DropdownButton = ({ label, id, variant = 'contained', children }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  return (
    <>
      <ButtonGroup variant={variant} ref={anchorRef} aria-label="dropdown">
        <StyledButton
          data-testid={`btn-${id}`}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          size="m"
        >
          {label}
        </StyledButton>
        <Button
          data-testid={`btn-icon-${id}`}
          size="m"
          aria-controls={open ? 'create-dropdown' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="create-dropdown"
          aria-haspopup="menu"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <StyledPopper open={open} anchorEl={anchorRef.current}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement }}>
            <Paper id={id}>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList id={id} autoFocusItem>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
