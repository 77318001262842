export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const handleScrollToElement = (errors) => {
  let eleId = `input-${Object.keys(errors)[0]}`
  const element = document.getElementById(eleId)
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  })
}
