import React from 'react'
import _ from 'lodash'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Box from '@mui/material/Box'

import { findSubQuestions } from '../../../../../redux/slices/eEvaluationForm'

import { BoxContent, AlertTooltip, IconAlert } from './Styled'
import { TooltipForm } from '../../AssessmentForm/Styled'
import { handleChange } from '../events'

const Index = () => {
  const dispatch = useDispatch()

  const { displayQuestion, disableSetting } = useSelector(
    (state) => ({
      displayQuestion: state.eEvaluationForm.setting.displayQuestion,
      disableSetting:
        state.eEvaluationForm.disableSetting ||
        state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  const countSubQuestions = useSelector(findSubQuestions, shallowEqual)

  return (
    <Card sx={{ mt: 2 }}>
      <BoxContent>
        <Typography variant="h6">การแสดงคำถาม</Typography>

        <RadioGroup
          sx={{ pl: 3, gap: 0.5 }}
          aria-label="approval"
          name="displayQuestion"
          id="displayQuestion"
          data-testid="radio-displayQuestion"
          defaultValue={displayQuestion}
          value={displayQuestion}
          onChange={(e) => {
            dispatch(
              handleChange({
                value: _.get(e, 'target.value', 'ALL'),
                key: 'displayQuestion',
              })
            )
          }}
        >
          <FormControlLabel
            disabled={disableSetting || countSubQuestions}
            value={'ALL'}
            control={<Radio />}
            label="แสดงทั้งหมด"
          />
          <FormControlLabel
            disabled={disableSetting || countSubQuestions}
            value={'ONE'}
            control={<Radio />}
            label={
              <>
                {countSubQuestions ? (
                  <AlertTooltip>
                    <Typography variant="body1">แสดงทีละข้อ</Typography>
                    <TooltipForm
                      maxWidth={180}
                      title={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="tooltip">
                            มีการตั้งค่าแบบประเมินแบบเป็นเงื่อนไข
                            (กำหนดคำถามต่อไปที่ตัวเลือก)
                          </Typography>
                          <Typography variant="tooltip">
                            จึงไม่สามารถเลือกแสดงทั้งหมดได้
                          </Typography>
                        </Box>
                      }
                      arrow
                      placement="right"
                    >
                      <IconAlert />
                    </TooltipForm>
                  </AlertTooltip>
                ) : (
                  <Typography variant="body1">แสดงทีละข้อ</Typography>
                )}
              </>
            }
          />
        </RadioGroup>
      </BoxContent>
    </Card>
  )
}

export default Index
