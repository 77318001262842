import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Image from '../../../Image'
import Video from '../../../Video'
import TextInput from '../../../../../../../components/Input/TextInput'
import SelectIcon from '../../../../../../../components/redux/SelectIcon'
import { listChoiceEva } from '../../../../../../../constants/eEvaluation'
import { closeDialog } from '../../../../../../../redux/slices/dialog'
import { getErrorMessage } from '../../../../event'

import * as Styled from './Styled'
import * as event from './event'
import {
  handleIconUploadImage,
  IconUploadImage,
  handleIconVideo,
} from '../../../event'
import { TooltipForm } from '../../../Styled'

const Index = ({ props }) => {
  const {
    sequence,
    indexQuestion,
    itemQuestion,
    isSubQuestions,
    indexSubQuestion,
    subQuestionSequence,
    subQuestionIndex,
    disableForm,
  } = props

  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { errorSchemaMessage, dialog } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
      dialog: state.dialog,
    }),
    shallowEqual
  )
  const questionErrorMessage = getErrorMessage({
    field: 'QUESTION',
    errorSchemaMessage,
    indexQuestion,
    getErrorMessage: 'questions',
  })
  const title = _.get(itemQuestion, 'title', '')
  const url = _.get(itemQuestion, 'url', '')
  const closeVideo = _.get(itemQuestion, 'closeVideo', false)
  const questionType = _.get(itemQuestion, 'questionType', 'SINGLE')
  const loadingProgress = _.get(itemQuestion, 'loadingProgress', null)
  const mediaType = _.get(itemQuestion, 'mediaType', 'IMAGE')

  useEffect(() => {
    if (closeVideo && _.get(dialog, 'title', '') === 'เพิ่มวิดีโอ') {
      dispatch(closeDialog())
    }
  }, [closeVideo])

  const propsEvent = {
    sequence,
    indexQuestion,
    isSubQuestions,
    indexSubQuestion,
    subQuestionSequence,
    subQuestionIndex,
  }

  const sequenceNumber = isSubQuestions
    ? `${indexQuestion + 1}.${subQuestionIndex + 1}`
    : `${indexQuestion + 1}.`

  const newType = isSubQuestions ? 'subQuestions' : 'questions'
  const testIdImage = `icon-${newType}-${sequenceNumber}`

  return (
    <Box sx={{ pt: 3 }}>
      <Styled.BoxInputQuestion>
        <Styled.BoxInput>
          <Typography variant="h6">{sequenceNumber}</Typography>
          <TextInput
            id="title-question"
            name="title"
            type="basic"
            maxRows={2}
            multiline={true}
            disabled={disableForm}
            placeholder={
              questionType === 'RATING' ? 'หัวข้อการประเมิน' : 'คำถาม'
            }
            onBlur={(e) =>
              dispatch(
                event.handleChangeQuestion({
                  ...propsEvent,
                  type: newType,
                  key: 'title',
                  value: _.get(e, 'target.value', ''),
                })
              )
            }
            defaultValue={title}
            isShowTextError={questionErrorMessage ? true : false}
            textError={questionErrorMessage}
          />
        </Styled.BoxInput>
        <Styled.BoxSelect>
          <IconUploadImage
            isQuestion={true}
            disabled={disableForm}
            testId={testIdImage}
            subQuestionIndex={subQuestionIndex}
            onUpload={(e) =>
              dispatch(
                handleIconUploadImage({
                  ...propsEvent,
                  data: e,
                  type: isSubQuestions ? 'subQuestions' : 'questions',
                  testId: testIdImage,
                })
              )
            }
          />
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">วิดีโอ</Typography>}
          >
            <IconButton
              onMouseLeave={() => setIsHover(false)}
              onMouseOver={() => setIsHover(true)}
              onClick={() =>
                !disableForm &&
                dispatch(handleIconVideo({ ...propsEvent, type: newType }))
              }
            >
              <VideocamOutlined color={isHover ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>
          <Box sx={{ width: 240 }}>
            <SelectIcon
              labelText=""
              listOption={listChoiceEva}
              disabled={disableForm}
              handleChange={(e) => {
                dispatch(
                  event.handleChangeTypeQuestion({
                    ...propsEvent,
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }}
              value={questionType}
            />
          </Box>
        </Styled.BoxSelect>
      </Styled.BoxInputQuestion>
      <Box sx={{ mb: 2 }}>
        {((loadingProgress != null && mediaType === 'IMAGE') ||
          (mediaType === 'IMAGE' && url)) && (
          <Image
            isQuestion
            uploadState={itemQuestion}
            keyState={{
              ...propsEvent,
              type: newType,
              keyValue: 'url',
            }}
            value={url}
          />
        )}
        {mediaType === 'VIDEO' && url && (
          <Video
            url={url}
            sequence={sequence}
            isSubQuestions={isSubQuestions}
            subQuestionSequence={subQuestionSequence}
            disabled={disableForm}
            type={newType}
          />
        )}
      </Box>
    </Box>
  )
}
export default Index
