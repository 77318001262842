import _ from 'lodash'
import { callAPI } from '../../../../../utils/lib'
import { filterVersion, stationFilter } from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enums/drawerEnum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'
import { store } from '../../../../../App'

export const handleFiltering = (filter) => (dispatch) => {
  const isFilterDate = filter.dateChecked && filter.startDate && filter.endDate
  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  const isFilterCourse = filter.courseChecked && filter.course
  if (isFilterCourse) ++countFilterTotal
  const isFilterPlatformLearning =
    filter.platformLearningChecked && filter.platformLearning
  if (isFilterPlatformLearning) ++countFilterTotal

  dispatch(
    setFieldValue({
      key: 'apiRcmsDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        startDate: isFilterDate
          ? dayjs(filter.startDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: isFilterDate
          ? dayjs(filter.endDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        filterTotal: countFilterTotal,
        courseUuid: isFilterCourse ? filter.course : '',
        platformLearning: isFilterPlatformLearning
          ? filter.platformLearning
          : '',
        isFilterDate: isFilterDate,
        isFilterCourse: isFilterCourse,
        isFilterPlatformLearning: isFilterPlatformLearning,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const fetchCourse = async () => {
  const body = {
    calendar: false,
    version: -1,
    englishName: '',
    courseCode: '',
    startDate: '',
    updatedBy: '',
    limit: 100,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE', 'WAITING_FOR_ACTIVE', 'INACTIVE'],
    createdBy: '',
    assignee: '',
  }

  await callAPI({
    url: filterVersion,
    method: 'POST',
    body: body,
    onSuccess: (data) => {
      const courseList = _.get(data, 'result', []).map((item) => ({
        label: `${item.englishName}`,
        name: _.lowerCase(item.englishName).replace(' ', '') + 'Checked',
        uuid: item.uuid,
        value: item.uuid,
        courseCode: item.courseCode,
      }))
      store.dispatch(
        setFieldValue({
          key: 'filterList.course',
          value: courseList,
        }),
      )
    },
  })
}

export const fetchPlatformLearner = async () => {
  const platformLearningList = [
    { label: 'Classroom', value: 'classroom' },
    { label: 'Online', value: 'online' },
    { label: 'E-learning', value: 'elearning' },
  ]
  store.dispatch(
    setFieldValue({
      key: 'filterList.platformLearning',
      value: platformLearningList,
    }),
  )
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: `${item.academyStationName} (${item.locationCode})`,
        name: _.lowerCase(item.academyStationName).replace(' ', '') + 'Checked',
        uuid: item.uuid,
        value: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleSelectMultiple = (list, filter, setFilter, nameInput) => {
  let objState = {
    ...filter,
    [`${nameInput}List`]: list,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: true,
      startDate: defaultFilter.startDate,
      endDate: defaultFilter.endDate,
      displayDate: true,
    }
  } else if (key === 'course') {
    objState = {
      ...objState,
      courseChecked: false,
      course: undefined,
    }
  } else if (key === 'platformLearning') {
    objState = {
      ...objState,
      platformLearningChecked: false,
      platformLearning: undefined,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
