import _ from 'lodash'
import dayjs from 'dayjs'
import { convertFormatDateTime } from '../../../utils/lib'
import { postStandaloneExamVersionList } from '../../../services/manageClass/check'
import { initialState } from '../../../redux/slices/table/model'
import * as slicesTable from '../../../redux/slices/table'
import { headCellVersion } from '../model'

export const fetchDataList = (props) => async (dispatch) => {
  dispatch(slicesTable.startLoading())

  const { method, table, page, filterProp, keyword, setInitial, codeId } = props
  const isInitial = method == 'initial'
  const tableProps = isInitial ? initialState.table : table
  const sortBy = tableProps.sort
  const realPage = page <= 0 ? 1 : +page
  const filter = isInitial ? null : filterProp
  const eTestingName = isInitial ? '' : _.get(filter, 'eTestingName', keyword)

  const body = {
    codeId,
    name: method == 'filter' ? keyword : eTestingName,
    version: Number(_.get(filter, 'version', -1)),
    createdBy: _.get(filter, 'createdBy', ''),
    latestCheckedBy: _.get(filter, 'latestCheckedBy', ''),
    createdDate: _.get(filter, 'createdDate', ''),
    toCreatedDate: _.get(filter, 'toCreatedDate', ''),
    status: _.get(filter, 'status', []),
    page: realPage,
    limit: tableProps.limit,
    order: tableProps.order.toString().toUpperCase(),
    sort: sortBy === 'updatedAt' ? 'version' : sortBy,
  }

  const result = await dispatch(postStandaloneExamVersionList(body))
  const meta = _.get(result, 'meta', {})
  const isCancel =
    meta.requestStatus === 'rejected' && _.isUndefined(meta.response)
  dispatch(
    slicesTable.setInitialTable({
      rows: mapDataTable(_.get(result, 'payload.data.result', [])),
      allCount: _.get(result, 'payload.data.totalCount', 0),
      headCells: headCellVersion,
      placeholder: 'ค้นหาชื่อแบบทดสอบ',
      handleSearch: (text) =>
        dispatch(handleQuickSearch(tableProps, text, codeId)),
      onDownload: 'hidden',
      searchKey: 'eTestingName',
      isCheckBox: false,
    })
  )

  setInitial && setInitial(false)
  if (!isCancel) dispatch(slicesTable.stopLoading())
}

export const onSubmitVersionFilter = (table, filter, codeId) => (dispatch) => {
  dispatch(slicesTable.setSearchText(''))

  const status = []
  if (filter.statusChecked) {
    if (filter.status?.waitingChecked) status.push('WAITING')
    if (filter.status?.doneChecked) status.push('DONE')
  }
  const filterProp = {
    version: filter.versionChecked ? filter.version : -1,
    eTestingName: filter.eTestingNameChecked ? filter.eTestingName : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    latestCheckedBy: filter.updatedByChecked ? filter.updatedBy : '',
    createdDate: filter.startDateChecked
      ? dayjs(filter.startDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    toCreatedDate: filter.startDateChecked
      ? dayjs(filter.toStartDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    status,
  }

  dispatch(slicesTable.setFilterProp(filterProp))
  dispatch(slicesTable.resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      filterProp,
      keyword: filterProp.eTestingName,
      codeId,
    })
  )
  dispatch(slicesTable.setToggleFilterDrawer(false))
}

export const handleQuickSearch = (table, text, codeId) => (dispatch) => {
  dispatch(slicesTable.setSearchText(text))
  dispatch(slicesTable.setFilterProp(null))
  dispatch(slicesTable.resetTablePage())

  dispatch(
    fetchDataList({
      method: 'search',
      table,
      page: 1,
      filterProp: null,
      keyword: text,
      codeId,
    })
  )
}

export const mapDataTable = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    const totalSubmit = Number(_.get(item, 'totalSubmit', 0))
    return {
      ...item,
      shareDate: _.isEmpty(item.shareDate)
        ? '-'
        : convertFormatDateTime({ value: item.shareDate, type: 'date' }),
      submitNumber:
        totalSubmit === 0
          ? '-'
          : `${item.totalWaitingToAnnounce}/${item.totalSubmit}`,
    }
  })
}
