import React from 'react'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBox = styled((props) => (
  <Box key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))