import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from '../Styled'
import FilterDrawer from './FilterDrawer'
import { breadcrumbList } from '../model'
import HeaderList from './Header'
import { fetchDataList } from './event'
import HistoryDrawer from './HistoryDrawer'
import { callAPI } from '../../../../src/utils/lib'
import { apiToRcmsHistoryImport } from '../../../../src/utils/apiPath'

const List = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList('initial', table, page, filterProp, searchText, setInitial)
    )
  }, [])

  useEffect(() => {
    if (!isInitial) fetchData()
  }, [limit, order, page, sort])

  const fetchData = () =>
    dispatch(fetchDataList('fetch', table, page, filterProp, searchText))

  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limitHistory, setLimitHistory] = useState(100)
  const [pageHistory, setPageHistory] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  const fetchHistory = async () => {
    const realPage = pageHistory === 0 ? 1 : pageHistory + 1
    const body = {
      limit: limitHistory,
      page: realPage,
    }
    const url = `${apiToRcmsHistoryImport}`
    await callAPI({
      url: url,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  useEffect(() => {
    fetchHistory()
  }, [limitHistory, pageHistory])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">{breadcrumbList[1].title}</Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbList} />

        <HeaderList onHistoryClick={onHistoryClick} fetchData={fetchData} />

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="api_to_rcms"
        />
      </StyledContent>

      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={pageHistory}
        setPage={setPageHistory}
        setLimit={setLimitHistory}
        allCount={allCount}
      />
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default List
