import { Tooltip, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import _ from 'lodash'

export const TooltipBox = ({ tooltipTitle }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={_.map(tooltipTitle, (title) => {
        return (
          <li>
            <Typography variant="tooltip">{title}</Typography>
          </li>
        )
      })}
      followCursor
      componentsProps={{
        tooltip: {
          sx: {
            background: theme?.palette?.blue?.azure,
            color: theme?.palette?.common?.white,
            boxShadow: theme?.palette?.boxShadow?.main,
          },
        },
      }}
    >
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 300,
        }}
        variant="body2b"
      >
        +{tooltipTitle?.length - 1}
      </Typography>
    </Tooltip>
  )
}
