import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, Divider, IconButton } from '@mui/material'
import * as events from './event'
import { StyledDrawer, StyledFooter, StyledHeadLabel } from './Styled'
import { StyledColumn } from '../Styled'
import {
  setFilter,
  setFilterMemo,
  setIsOpenFilterDrawer,
  setStationUuidMemo,
  setTypeMemo,
} from '../../../redux/slices/roomManagement/Dashboard'
import { defaultFilterMeetingRoom } from './model'
import { ListCheckBox } from './components/ListCheckBox'

const FilterDrawer = ({ table, manageProps }) => {
  const dispatch = useDispatch()
  const {
    stationUuid,
    typeFilter,
    stationList,
    filter,
    listArrayFilter,
    isOpenFilterDrawer,
  } = useSelector(
    (state) => ({
      stationUuid: state.roomManagement.stationUuid,
      typeFilter: state.roomManagement.typeFilter,
      stationList: state.roomManagement.stationList,
      filter: state.roomManagement.filter,
      listArrayFilter: state.roomManagement.listArrayFilter,
      isOpenFilterDrawer: state.roomManagement.isOpenFilterDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      open={isOpenFilterDrawer}
      onClose={() => dispatch(setIsOpenFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledColumn sx={{ position: 'relative' }}>
        <ListCheckBox
          listArray={listArrayFilter}
          stateFilter={filter}
          station={stationList}
        />
      </StyledColumn>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => dispatch(setFilter(defaultFilterMeetingRoom))}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(
              events.handleFiltering({
                table,
                filter,
                station: stationList,
                manageProps,
                typeFilter,
                stationUuid,
              }),
            )
            dispatch(setStationUuidMemo(stationUuid))
            dispatch(setFilterMemo(filter))
            dispatch(setTypeMemo(typeFilter))
            dispatch(setIsOpenFilterDrawer(false))
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
