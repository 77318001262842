import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledFooter,
  StyledCard,
  LoadingCircular,
} from './Styled'
import Table from '../../../../../components/redux/Table'
import { toggleExaminerDrawer } from '../../handler/handleClickOpenDrawer'
import { Row } from '../../components/Styled'
import { useTheme } from '@emotion/react'
import { CustomCheckbox } from '../../../../../components/CRUD/components/CustomCheckbox'
import {
  changeDrawerType,
  examFieldTypes,
  fetchData,
  handleCheckBox,
  onDownload,
  scheduleStatuses,
} from './events'
import { useDispatch } from 'react-redux'
import { examinerDrawerType } from '../../enums/drawerEnum'
import TableSRAM from './TableSRAM'
import _ from 'lodash'
import { StyledDownloadButton } from '../../../../../components/redux/Table/Styled'

const ExaminerDrawer = () => {
  const { open, drawerType, totalEnroll, countTypeArr, sRamData, countStatus } =
    useSelector(
      (state) => ({
        open: state.crud.eExamDashboard?.examinerDrawer?.isOpen,
        drawerType: state.crud.eExamDashboard?.examinerDrawer?.drawerType,
        totalEnroll: state.crud.eExamDashboard?.examinerDrawer?.totalEnroll,
        countTypeArr: state.crud.eExamDashboard?.examinerDrawer?.countTypeArr,
        sRamData: state.crud.eExamDashboard?.examinerDrawer?.sRamData,
        countStatus: state.crud.eExamDashboard?.examinerDrawer?.countStatus,
      }),
      shallowEqual,
    )
  const theme = useTheme()
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { limit, page, isLoading } = table
  useEffect(() => {
    open &&
      dispatch(
        fetchData({
          table,
          drawerType,
        }),
      )
  }, [open, page, limit, drawerType])

  return (
    <StyledDrawer
      open={open}
      customWidth={'90%'}
      onClose={() => toggleExaminerDrawer(false)}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">รายละเอียดผู้สมัครสอบ</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleExaminerDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box m={`${theme.spacing(0.5)} ${theme.spacing(2.75)}`}>
        <Row>
          <Box
            sx={{
              p: '4px 16px',
              borderRadius: 5,
              background: '#F1F4FA',
              maxWidth: 'max-content',
            }}
          >
            <Typography variant="body2b" color={theme.palette.primary.main}>
              จำนวนผู้สมัครทั้งหมด {totalEnroll ?? 0} คน
            </Typography>
          </Box>
          {scheduleStatuses.map((item, index) => {
            const count = _.get(countStatus, item.value, 0)
            const total = ['PASS', 'NOT_PASS'].includes(item.value)
              ? _.get(countStatus, 'EXAMINEES', 0)
              : totalEnroll

            const percent = count > 0 ? ((count / total) * 100).toFixed(2) : 0
            return (
              <Box ml={2} key={index}>
                <CustomCheckbox
                  fieldName={item.fieldName}
                  handleExtraChange={(e, v) => {
                    handleCheckBox(item.fieldName, v, table, drawerType)
                  }}
                  labelText={item.label}
                  sx={{
                    color: item.fontColor,
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 'bold',
                    },
                  }}
                />
                <Typography variant="body2b">
                  ({`${count} `}คน{` ${percent}`}%)
                </Typography>
              </Box>
            )
          })}
        </Row>
        <Box display="flex" alignItems="center">
          <Typography variant="body1b" color={theme.palette.text.secondary}>
            ประเภทสนามสอบ
          </Typography>
          {examFieldTypes.map((item, index) => {
            const countType = _.defaultTo(countTypeArr, []).find(
              (ct) => ct.examinationField === item.type,
            )
            return (
              <Box ml={2} key={index}>
                <CustomCheckbox
                  fieldName={item.fieldName}
                  handleExtraChange={(e, v) => {
                    handleCheckBox(item.fieldName, v, table, drawerType)
                  }}
                  labelText={item.label}
                />
                <Typography variant="body2b" color="primary.main">
                  ({_.get(countType, 'totalEnroll', 0)} คน)
                </Typography>
              </Box>
            )
          })}
        </Box>
        <Box display="flex" alignItems="center">
          <DrawerTypeTab drawerType={drawerType} dispatch={dispatch} />
          {drawerType === examinerDrawerType.SRAM && (
            <StyledDownloadButton
              data-testid="btn-download"
              disabled={false}
              onClick={() => dispatch(onDownload([], drawerType))}
              sx={{ width: '150px', ml: 'auto' }}
            >
              ดาวน์โหลด
            </StyledDownloadButton>
          )}
        </Box>
      </Box>
      {drawerType === examinerDrawerType.EXAM ? (
        <StyledCard isLoading={isLoading}>
          <Table />
        </StyledCard>
      ) : (
        <>
          <TableSRAM sramName={'SRAM01'} data={sRamData?.sRam1Data ?? []} />
          <TableSRAM sramName={'SRAM02'} data={sRamData?.sRam2Data ?? []} />
          <TableSRAM sramName={'SRAM03'} data={sRamData?.sRam3Data ?? []} />
          <TableSRAM sramName={'SRAM04'} data={sRamData?.sRam4Data ?? []} />
        </>
      )}
      <StyledFooter>
        <Button onClick={() => toggleExaminerDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export const DrawerTypeTab = ({ drawerType, dispatch }) => {
  return (
    <Box>
      <Button
        sx={{ height: 40, p: 1 }}
        onClick={() => {
          dispatch(changeDrawerType(examinerDrawerType.EXAM))
        }}
      >
        <Typography
          variant={drawerType === examinerDrawerType.EXAM ? 'body1b' : 'body1'}
          sx={{
            borderBottom: drawerType === examinerDrawerType.EXAM ? 4 : '',
            px: 2,
          }}
        >{`สนามสอบ`}</Typography>
      </Button>
      <Button
        sx={{ height: 40, p: 1 }}
        onClick={() => {
          dispatch(changeDrawerType(examinerDrawerType.SRAM))
        }}
      >
        <Typography
          variant={drawerType === examinerDrawerType.SRAM ? 'body1b' : 'body1'}
          sx={{
            borderBottom: drawerType === examinerDrawerType.SRAM ? 4 : '',
            px: 2,
          }}
        >{`SRAM/RAM`}</Typography>
      </Button>
    </Box>
  )
}

export default ExaminerDrawer
