import { path } from './../path'
export const headCells = [
  {
    id: 'iconStatus',
    width: '12px',
  },
  {
    id: 'classStatus',
    label: 'สถานะ',
    width: '20px',
  },
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    minWidth: '155px',
    disablePadding: false,
  },
  {
    id: 'course',
    label: 'หลักสูตร',
    minWidth: '250px',
    disablePadding: false,
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    minWidth: '250px',
    disablePadding: false,
  },
  {
    id: 'monthlyplanDate',
    label: 'วันที่จัดอบรม',
    minWidth: '150px',
    disablePadding: false,
  },
  {
    id: 'station',
    label: 'Station',
    minWidth: '115px',
    disablePadding: false,
  },
  {
    id: 'myTrainingPlatForm',
    label: 'รูปแบบการสอน',
    minWidth: '180px',
    disablePadding: false,
  },
  {
    id: 'actionAdminManageClass',
    label: 'แอดมิน',
    disablePadding: false,
  },
  {
    id: 'actionManageClass',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.manageClass}/detail`,
    editPath: `${path.manageClass}/setting`,
  },
]
export const otherFilter = {
  highClass: { checked: false, count: 0 },
  myJob: { checked: false, count: 0 },
}
export const defaultQuickFilter = [
  {
    title: 'Unassigned',
    count: 0,
    field: 'unassign',
    clicked: false,
  },
  {
    title: 'To Do',
    count: 0,
    field: 'todo',
    clicked: false,
  },
  {
    title: 'In Progress',
    count: 0,
    field: 'inprogress',
    clicked: false,
  },
  {
    title: 'Closed',
    count: 0,
    field: 'closed',
    clicked: false,
  },
  {
    title: 'รอตรวจเอกสาร',
    count: 0,
    field: 'checkDocuments',
    clicked: false,
  },
  {
    title: 'รอตรวจข้อสอบ',
    count: 0,
    field: 'waitingExam',
    clicked: false,
  },
  {
    title: 'Cancelled',
    count: 0,
    field: 'canceled',
    clicked: false,
  },
]

export const filterBody = {
  courseCode: '',
  course: '',
  assignNee: '',
  startDate: '',
  finishDate: '',
  startRegister: '',
  finishRegister: '',
  checkDocuments: false,
  waitingExam: false,
  highClass: false,
  myJob: false,
  station: [],
  trainingPlatForm: [],
  status: [],
}

export const headOicCells = [
  {
    id: 'TITLE',
    width: '60px',
    label: 'คำนำหน้า',
    disablePadding: false,
  },
  {
    id: 'FNAME',
    label: 'ชื่อ',
    disablePadding: false,
    minWidth: '300px',
  },
  {
    id: 'LNAME',
    label: 'นามสกุล',
    disablePadding: false,
    minWidth: '300px',
  },
  {
    id: 'examDate',
    width: '60px',
    label: 'วันที่เข้าสอบ',
    disablePadding: false,
  },
  {
    id: 'CID',
    label: 'CID',
    disablePadding: false,
  },
  {
    id: 'TYPE',
    label: 'ประเภท',
    disablePadding: false,
  },
  {
    id: 'actionOic',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `${path.manageClass}/oic`,
  },
]

export const RESULT_TEXT = {
  F: 'ไม่ผ่าน',
  P: 'ผ่าน',
  M: 'ไม่เข้าสอบ',
}
