import { Box, Typography } from '@mui/material'
import { ROOM_AVAILABLE, roomAvailableList } from '../../constants/roomManagement'
import _ from 'lodash'

export const StatusLabel = (props) => {
  const { roomAvailable } = props

  const roomAvailableActive = _.find(roomAvailableList, (roomAvb) => {
    return roomAvb.status === ROOM_AVAILABLE[roomAvailable]
  })

  const { label, bgColor, textColor } = roomAvailableActive

  return (
    <Box
      sx={{
        background: bgColor,
        borderRadius: 6.5,
        px: 1,
        minWidth: 36,
      }}
    >
      <Typography
        sx={{ color: textColor, typography: { sm: 'body2b', xs: 'body3b' } }}
      >
        {label}
      </Typography>
    </Box>
  )
}
