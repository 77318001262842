import React from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { StyledHeaderContainer } from '../../Styled'
import { createOptions } from '../model'
import { onClickAdd } from './events'
import DropdownButton from './DropdownButton'

const Header = () => {
  const history = useHistory()
  return (
    <StyledHeaderContainer>
      <DropdownButton label="สร้าง Course" id="create-dropdown-eva">
        {createOptions.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`menu-create-${index}`}
            onClick={() => onClickAdd(option.link, history)}
          >
            <Box sx={{ mr: 1, display: 'flex' }}>
              {option.icon && <>{option.icon}</>}
            </Box>
            <Box>
              <Typography variant="body1">{option.text}</Typography>
            </Box>
          </MenuItem>
        ))}
      </DropdownButton>
    </StyledHeaderContainer>
  )
}
export default Header
