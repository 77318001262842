import axios from 'axios'
import React from 'react'
import {
  closeDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../redux/slices/dialog'
import { eEvaluation } from '../../../../utils/apiPath'
import { fetchDataList } from '../event'
import { DialogContent } from '.'

export const handleToggleButton = (event) => (dispatch) => {
  if (event.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleSwitch = (event, uuid, tableProps) => (dispatch) => {
  if (!event.target.checked) {
    dispatch(
      openDialog({
        title: 'ปิดใช้งานแบบประเมิน',
        type: 'content',
        disableButton: true,
        content: <DialogContent />,
        handleConfirm: () => {
          dispatch(handleToggleStatus(tableProps, uuid))
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(handleToggleStatus(tableProps, uuid))
  }
}

export const handleToggleStatus = (tableProps, uuid) => (dispatch) => {
  const { table, page, status, filterProp, searchText, codeId } = tableProps
  axios
    .put(`${eEvaluation}/${uuid}`)
    .then(() =>
      dispatch(
        fetchDataList(
          'fetch',
          table,
          page,
          status,
          filterProp,
          searchText,
          codeId
        )
      )
    )
}
