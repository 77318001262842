import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eContentSetPin } from '../../utils/apiPath'

export const pinContent = createAsyncThunk(
  'eContent/setPinContent',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eContentSetPin, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
