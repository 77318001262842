import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
import Card from '@mui/material/Card'
import { TheaterComedySharp } from '@mui/icons-material'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}))

export const StyledColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledSection = styled(Card)(({ theme, show }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: TheaterComedySharp.spacing(3),
  paddingBottom: show ? 0 : theme.spacing(3),
  gap: theme.spacing(3),
}))
