import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { height } from '../../Styled'

export const MediaBoxStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height,
  background: theme.palette?.background?.paper,
}))
