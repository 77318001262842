import { Box } from '@mui/material'
import styled from '@mui/system/styled'

export const NotiBox = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  backgroundColor: theme.palette?.secondary?.main,
  color: theme.palette?.secondary?.contrast,
  borderRadius: 50,
  fontSize: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const NotificationDot = styled(Box)(({ theme }) => ({
  width: 7,
  height: 7,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrast,
  borderRadius: 50,
  position: 'absolute',
  right: 0,
  top: 13,
}))