import TextInput from '../../../../../../components/Input/TextInput'
import { useDispatch } from 'react-redux'
import { handleInputRoomChange } from '../events'
import {
  handleInputFormat,
  handleDefaultValue,
} from './event'
import { handlePasteFormat } from '../../../../../../utils/lib'
import _ from 'lodash'

export const NumberOfGroup = ({
  numberOfGroup,
  roomDetailIndex,
  errors,
  bookingDate,
  validateIndex,
}) => {
  const dispatch = useDispatch()

  return (
    <TextInput
      type="basic"
      id="numberOfGroup"
      name="numberOfGroup"
      required
      labelText={'จำนวนกลุ่ม (กลุ่ม)'}
      placeholder="กรุณากรอกจำนวนกลุ่ม"
      value={handleDefaultValue(numberOfGroup || null)}
      inputProps={{
        type: 'text',
        onInput: (e) => handleInputFormat(e),
        onPaste: handlePasteFormat,
      }}
      onChange={(e) => {
        dispatch(
          handleInputRoomChange(
            e,
            'numberOfGroup',
            roomDetailIndex,
            bookingDate,
          ),
        )
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      isShowTextError={
        !_.isEmpty(
          errors,
          `roomManagement.roomManagementMeetingRoom[${validateIndex}].numberOfGroup`,
        )
      }
      textError={_.get(
        errors,
        `roomManagement.roomManagementMeetingRoom[${validateIndex}].numberOfGroup`,
        '',
      )}
    />
  )
}
