import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../../handler/handleClearFilter'

export const FilterBox = () => {
  const { manageProfileDashboard } = useSelector(
    (state) => ({
      manageProfileDashboard: state.crud.manageProfileDashboard,
    }),
    shallowEqual,
  )
  const startDateFilter = manageProfileDashboard?.filtersDrawer?.startDate ?? ''
  const endDateFilter = manageProfileDashboard?.filtersDrawer?.endDate ?? ''
  const hasFilter = startDateFilter && endDateFilter
  const isFilterDate = manageProfileDashboard?.filtersDrawer?.isFilterDate ?? ''
  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
