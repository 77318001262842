import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { draftAssessmentQuestion } from '../../../../../../../redux/slices/eEvaluationPreview/selectors'
import TextInput from '../../../../../../../components/Input/TextInput'
import {
  StyledContainer,
  StyledRatingColumn,
  StyledRatingHead,
  StyledTitleColumn,
} from './Styled'
import { StyledTitleData } from './QuestionRow/Styled'
import QuestionRow from './QuestionRow'
import { handleSelector } from './events'

const RatingContent = ({ keyProps }) => {
  const ref = useRef(null)
  const [ratingWidth, setRatingWidth] = useState(80)
  const { choices, isSuggestion } = useSelector(
    (state) => handleSelector(state, keyProps),
    shallowEqual
  )
  const listQuestion = useSelector(
    draftAssessmentQuestion(keyProps),
    shallowEqual
  )

  useEffect(() => {
    setRatingWidth(ref.current ? ref.current.offsetWidth : 80)
  }, [ref.current])

  return (
    <>
      <StyledContainer>
        <StyledTitleColumn>
          <StyledTitleData header />
          {listQuestion.map((item, index) => (
            <QuestionRow
              key={index}
              keyProps={keyProps}
              keyQuestion={item}
              isTitle
            />
          ))}
        </StyledTitleColumn>
        <StyledRatingColumn>
          <Box id="header">
            {choices.map((item) => (
              <StyledRatingHead
                ref={ref}
                ratingWidth={ratingWidth}
                key={item.sequence}
              >
                <Typography variant="body1b">{item.title}</Typography>
              </StyledRatingHead>
            ))}
          </Box>
          {listQuestion.map((item, index) => (
            <QuestionRow key={index} keyProps={keyProps} keyQuestion={item} />
          ))}
        </StyledRatingColumn>
      </StyledContainer>
      {isSuggestion && (
        <TextInput
          labelText="ข้อเสนอแนะ / ความคิดเห็นเพิ่มเติม"
          labelVariant="body2b"
          type="basic"
          multiline
          rows={2}
          isShowTextError={false}
          id={`comment`}
        />
      )}
    </>
  )
}

export default RatingContent
