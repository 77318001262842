import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

export default function HorizontalLinearStepper({ activeStep, isEditForm }) {
  const steps = [
    'เลือกรูปแบบ',
    'แก้ไขประกาศนียบัตร',
    isEditForm ? 'เหตุผลการแก้ไข' : 'ตรวจสอบและยืนยัน',
  ]
  return (
    <Box sx={{ width: '100%', my: '32px' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {}
          const labelProps = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
