import React from 'react'
import { useFormikContext } from 'formik'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { handleDisableNext, handleStatusChange } from './events'
import { StyledWrapper } from './Styled'

const ButtonForm = (props) => {
  const { onClose, activeStep, setActiveStep, setPurposeValidate } = props
  const { values, setFieldValue, resetForm } = useFormikContext()

  return (
    <StyledWrapper>
      {activeStep === 0 ? (
        <Button
          variant="outlined"
          onClick={() => {
            resetForm()
            onClose()
          }}
        >
          ยกเลิก
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          ก่อนหน้า
        </Button>
      )}
      <Box sx={{ display: 'flex', gap: 3 }}>
        {activeStep === 1 && (
          <Button
            variant="outlined"
            type="submit"
            onClick={() => handleStatusChange(setFieldValue, values, 'DRAFT')}
          >
            บันทึกฉบับร่าง
          </Button>
        )}

        <Button
          sx={{ display: activeStep === 1 ? 'inline-flex' : 'none' }}
          variant="contained"
          type="submit"
          onClick={() => handleStatusChange(setFieldValue, values, 'WAITING')}
        >
          ส่งคำขอ
        </Button>

        {activeStep === 0 && (
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            disabled={handleDisableNext(values)}
            onClick={() => {
              const purpose = values.purpose
              const moreThan3000 = purpose.length > 3000
              setPurposeValidate(
                moreThan3000 ? 'ระบุได้ไม่เกิน 3,000 ตัวอักษร' : ''
              )
              if (!moreThan3000) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
              }
            }}
          >
            ถัดไป
          </Button>
        )}
      </Box>
    </StyledWrapper>
  )
}

export default ButtonForm
