const { createSlice } = require('@reduxjs/toolkit')
import { initialState } from './model'

const settingPoint = createSlice({
  name: 'settingPoint',
  initialState,
  reducers: {
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      state.body[key] = value
    },
    setView: (state, { payload }) => {
      state.view = { ...state.view, ...payload }
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialCreateForm: (state) => {
      state.isLoading = false
      state.isUploadLoading = false
      state.isUploadSuccess = false
      state.uploadError = null
      state.body = initialState.body
    },
    setEditForm: (state, { payload }) => {
      state.body = { ...initialState.body, ...payload }
    },
    addCourse: (state) => {
      state.body.course.push({
        uuid: { value: '', label: '' },
        courseType: { value: '', label: '' },
      })
    },
    removeCourse: (state, { payload }) => {
      state.body.course.splice(payload, 1)
    },
    setCourse: (state, { payload }) => {
      const { index, value } = payload
      state.body.course[index] = value
    },
  },
})

export const {
  setChange,
  setFieldValue,
  setView,
  startLoading,
  stopLoading,
  setInitialCreateForm,
  setEditForm,
  addCourse,
  removeCourse,
  setCourse,
} = settingPoint.actions

export default settingPoint.reducer
