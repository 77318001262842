import { Box, Card, Typography } from '@mui/material'
import TopFiveList from './TopFiveList'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'

const RankingOverview = () => {
  const { topListType, dashboard } = useSelector(
    (state) => ({
      topListType: state.monthlyPlan.dashboard.topListType,
      dashboard: state.monthlyPlan.dashboard,
    }),
    shallowEqual,
  )

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Typography variant="h6">การจัดอันดับ</Typography>
      <Box display="flex" gap={2}>
        {_.map(topListType, (type, idx) => {
          return (
            <TopFiveList
              key={idx}
              type={type}
              list={_.slice(dashboard[type], 0, 5)}
            />
          )
        })}
      </Box>
    </Card>
  )
}

export default RankingOverview
