// import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
// import { clearDrawerInput } from './clearDrawerInput'

export const openHistoryDrawer = (value)  => {
  // const { formData } = store.getState().crud
  store.dispatch(setFieldValue({
      key: 'isOpenHistoryDrawer',
      value: value,
  }))
  //render table
}

export const openAssetDrawer = (value)  => {
  // const { formData } = store.getState().crud
  store.dispatch(setFieldValue({
      key: 'isOpenAssetDrawer',
      value: value,
  }))
  //render table
}
