import { CContainer } from '../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadcrumb } from './model/breadcrumb'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { fetchDataList } from './handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { store } from '../../../../constants/report/ReportETestingRawDataByLearner'
import { resetTable } from '../../../../redux/slices/table'
import { handleListTableFiltering } from './handler/handleListTableFiltering'

export const ETestingDashboard = () => {
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    store.dispatch(resetTable())
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      fetchDataList(mutateFilterPropToBody, {
        hideDownload: true,
        quickSearchPlaceHolder: 'ค้นหาชื่อแบบทดสอบ',
        isCheckBox: false,
        isDashboardListPage: true,
        methodSearch: 'search',
      }).then()
    }
  }, [limit, order, page, sort, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  console.log('render ETestingDashboard')

  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleListTableFiltering(
              mutateFilterSateToProp,
              mutateFilterPropToBody,
              {
                hideDownload: true,
                quickSearchPlaceHolder: 'ค้นหาชื่อแบบทดสอบ',
                isDashboardListPage: true,
                isCheckBox: false,
              },
            )
          }
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader title={crudConfig.moduleTitleFull} breadcrumbs={breadcrumb} />
      }
    />
  )
}
