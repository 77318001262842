import _ from 'lodash'
import { store } from '../../../App'

export const getBodyMutation = (data) => {
  const { monthlyPlan } = store.getState().crud

  return {
    monthlyPlanId: _.get(monthlyPlan, 'id', 0),
    monthlyPlan: _.get(monthlyPlan, 'uuid', ''),
    costCenter: _.get(data, 'costCenter.value', ''),
    staffProfile: _.get(data, 'staffProfile.value', null),
    staffRole: _.get(data, 'staffRole.value', null),
    amountList: _.get(data, 'amountList', []),
    showSummaryOther: _.get(data, 'showSummaryOther', false),
  }
}
