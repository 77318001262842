import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import { breadcrumbCheckExam, breadcrumbCheckStandalone } from '../../model'
import { checkScrollWidth, handleAnnouncing } from './events'
import { StyledBoxButton, StyledBoxHeader, StyledBoxLabelTitle } from './Styled'
import DropdownButton from './DropdownButton'
import _ from 'lodash'

const Header = ({ isStandalone }) => {
  const history = useHistory()
  const idParams = useParams()
  const id = _.get(idParams, 'id', '')
  const url = `/manage-class/detail/${id}`
  const [isScrolled, setScroll] = useState(false)
  const { eTestingName, allowToAnnounce, learnerList } = useSelector(
    (state) => ({
      eTestingName:
        state.manageClassCheckExam.courseNameAndType ||
        state.manageClassCheckExam.eTestingVersionName,
      allowToAnnounce: state.manageClassCheckExam.allowToAnnounce,
      learnerList: state.manageClassCheckExam.learners.learnerAll,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const ref = useRef()
  const [headerHeight, setHeaderHight] = useState(147)
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    window.addEventListener('scroll', () =>
      checkScrollWidth(setScroll, headerHeight)
    )
    return () => {
      window.removeEventListener('scroll', () =>
        checkScrollWidth(setScroll, headerHeight)
      )
    }
  }, [])

  useEffect(() => {
    const offsetHeight = ref.current?.offsetHeight
    const height = offsetHeight > 147 ? offsetHeight : 147
    setHeaderHight(height)
  }, [ref, eTestingName])

  return (
    <>
      {isScrolled && !isNotSm && <Box sx={{ height: headerHeight }} />}
      <StyledBoxHeader ref={ref} scrolled={isScrolled}>
        <StyledBoxLabelTitle>
          <Typography variant="h4">{eTestingName}</Typography>
          {!isScrolled && (
            <Breadcrumbs
              menuList={
                isStandalone ? breadcrumbCheckStandalone : breadcrumbCheckExam
              }
            />
          )}
        </StyledBoxLabelTitle>
        <StyledBoxButton>
          <Button
            data-testid="btn-announce"
            size="m"
            variant="contained"
            startIcon={<img src="/icon/ic_megaphone_white.svg" />}
            disabled={learnerList.length == 0 || !allowToAnnounce}
            onClick={() =>
              dispatch(handleAnnouncing({ idParams, isStandalone }))
            }
          >
            ประกาศผลสอบ
          </Button>
          {!isStandalone && (
            <Button
              data-testid="btn-class"
              size="m"
              variant="outlined"
              endIcon={<ArrowForward />}
              onClick={() => history.push(url)}
            >
              ไปหน้าคลาส
            </Button>
          )}
          <DropdownButton isStandalone={isStandalone} />
        </StyledBoxButton>
      </StyledBoxHeader>
    </>
  )
}
export default Header
