import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { handleAnnouncing } from './events'
import useLocalStorage from '@rehooks/local-storage'
import { StyledIconCell } from '../WaitToCheck/Styled'
import { hasPermissionDefaultAndMonthlyPlanArea } from '../../../../../utils/lib'

const WaitToAnnounce = ({ row }) => {
  const { id: uuid } = useParams()
  const courseETestUuid = _.get(row, 'uuid', '')
  const type = _.get(row, 'testAndEvaluateType', 'E_TESTING')
  const value = _.get(row, 'waitingToAnnounce', 0)
  const { isCancel, manageClassData } = useSelector(
    (state) => ({
      isCancel: state.manageClassDetail.isCancel,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual
  )
  const [user] = useLocalStorage('user')
  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: manageClassData,
  })
  const isDisable = value == 0 || !hasPermissionEdit || isCancel
  const dispatch = useDispatch()

  return (
    <StyledIconCell sx={{ justifyContent: 'space-between' }}>
      <Typography variant="body2" color="text.primary">
        {value}
      </Typography>
      {type == 'E_TESTING' && (
        <Button
          data-testid="btn-announce"
          sx={{ mr: 1 }}
          disabled={isDisable}
          variant="contained"
          onClick={() => dispatch(handleAnnouncing(uuid, courseETestUuid))}
        >
          <img
            src={
              isDisable
                ? '/icon/ic_megaphone_gray.svg'
                : '/icon/ic_megaphone_white.svg'
            }
          />
        </Button>
      )}
    </StyledIconCell>
  )
}

export default WaitToAnnounce
