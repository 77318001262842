import { breadcrumbReportExportByLearner } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'EXPORT_BY_LEARNER',
  modulePath: '/manage-class/report-export-by-learner',
  breadcrumb: breadcrumbReportExportByLearner(),
  downloadName: `Report Manage Class By Learner ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
