import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { StyledFooter } from './Styled'
import ButtonForOne from './ButtonForOne'
import ProgressMilestone from './ProgressMilestone'
import { handleAnswerAmount } from './events'
import SubmitBtn from './SubmitBtn'

const Footer = () => {
  const evaluation = useSelector(
    (state) => state.eEvaluationPreview,
    shallowEqual
  )
  const {
    setting: { displayQuestion },
    page,
    totalAssessments,
  } = evaluation
  const answerAmount = useMemo(
    () => handleAnswerAmount(evaluation),
    [evaluation, page]
  )

  return (
    <StyledFooter>
      <Box>
        <ProgressMilestone value={answerAmount} />
        {displayQuestion == 'ALL' ? (
          <SubmitBtn />
        ) : (
          <ButtonForOne totalQuestion={totalAssessments} />
        )}
      </Box>
    </StyledFooter>
  )
}

export default Footer
