import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

import _ from 'lodash'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'

import TextInput from '../../../components/Input/TextInput'
import Select from '../../../components/Input/Select'
import DatePicker from '../../../components/Input/DatePicker'
import AlertDialog from '../../../components/AlertDialog'
import { MESSAGE_FROM_DIALOG, DEFAULT_DIALOG_VALUE } from '../Text'
import { fetchHeadZone, getDepartment } from '../api/Index'
import { convertTime, convertTimeToApi } from '../Drawer/View/Index'
import TimePicker from '../../../components/TimePicker/TimePicker'
import { findDepartmentAndStation } from '../../../utils/lib'

const BoxDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}))

const BoxDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 3, pb: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BoxDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export const findOptionStation = (listDepartment, value) => {
  let result = []
  if (listDepartment.length) {
    const findDepartment = listDepartment.find((item) => {
      return item.uuid === value
    })
    result = findDepartment ? _.get(findDepartment, 'station', []) : []
  }
  return result.map((item) => ({
    ...item,
    value: item.area,
    station: item,
  }))
}

export const onChangeForm = (
  value,
  key,
  listResponsibility,
  setInitialValues,
  setValidateFormDialog
) => {
  if (key === 'numSpeaker') value = Number(value)

  if (key === 'responsibility') {
    const findValue = listResponsibility.find((item) => {
      return item.value === value
    })
    value = {
      ...findValue,
      id: _.get(findValue, 'id', ''),
      uuid: _.get(findValue, 'value', ''),
    }
  }

  if (key === 'startTime' || key === 'endTime') {
    value = convertTimeToApi(value)
  }

  setInitialValues((prevState) => ({
    ...prevState,
    [key]: value || '',
  }))

  setValidateFormDialog((prevState) => ({
    ...prevState,
    [key]: '',
  }))
}

export const handleRequireFile = (values) => {
  let status = true
  let resultMessage = {}
  for (let key in values) {
    const messages = MESSAGE_FROM_DIALOG.find((item) => {
      return item.value === key
    })

    if (
      key === 'numSpeaker' &&
      (values[key] <= 0 || _.isEmpty(String(values[key])) || values[key] >= 6)
    ) {
      let text = _.get(messages, 'message', '')
      if (values[key] >= 6) {
        text = 'จำนวนวิทยากรที่ต้องการได้ไม่เกิน 5'
      }

      resultMessage = {
        ...resultMessage,
        [key]: text,
      }
    }

    if (key !== 'numSpeaker' && _.isEmpty(String(values[key]))) {
      resultMessage = {
        ...resultMessage,
        [key]: _.get(messages, 'message', ''),
      }
    }

    if (
      (key === 'startTime' || key === 'endTime') &&
      String(values[key]).split('_').length > 1
    ) {
      resultMessage = {
        ...resultMessage,
        [key]: _.get(messages, 'message', ''),
      }
    }

    if (values[key] && values[key].length > 255 && key !== 'remark') {
      resultMessage = {
        ...resultMessage,
        [key]: 'ระบุข้อความได้ไม่เกิน 255 ตัวอักษร',
      }
    }
    if (values[key] && values[key].length > 3000 && key === 'remark') {
      resultMessage = {
        ...resultMessage,
        [key]: 'ระบุข้อความได้ไม่เกิน 3,000 ตัวอักษร',
      }
    }
  }

  for (let key in resultMessage) {
    if (
      key === 'numSpeaker' &&
      (resultMessage[key] <= 0 || !_.isEmpty(String(resultMessage[key])))
    ) {
      status = false
      break
    }

    if (key !== 'numSpeaker' && !_.isEmpty(String(resultMessage[key]))) {
      status = false
      break
    }
  }

  return { status, resultMessage }
}

export const handleSubmit = (
  values,
  setValidateFormDialog,
  statusType,
  setInitialValues,
  setDialog
) => {
  const { status, resultMessage } = handleRequireFile(values)
  setValidateFormDialog(resultMessage)

  if (status) {
    setInitialValues((prevState) => ({
      ...prevState,
      status: statusType,
    }))
    setDialog((prevState) => ({
      ...prevState,
      open: true,
    }))
  }
}

export const onChangeStation = async (
  value,
  key,
  setIsLoading,
  initialValues,
  setInitialValues,
  optionDepartment
) => {
  setIsLoading(true)

  let approverList = []
  let stationUuid = null

  if (key === 'station') {
    approverList = await fetchHeadZone(value)
    stationUuid = _.get(
      initialValues.department.station.find((item) => item.area === value),
      'uuid',
      null
    )
  }

  const checkApi = _.get(initialValues, 'apiType', null)

  setInitialValues((prevState) => ({
    ...prevState,
    department: {
      ...prevState.department,
      ...optionDepartment.find((item) => {
        return item.uuid === value
      }),
      area: key === 'department' ? null : value,
    },
    approver:
      checkApi === null || key !== 'station'
        ? approverList
        : key === 'station'
        ? _.get(initialValues, 'approver', approverList)
        : {},
    station: key === 'station' ? stationUuid : null,
  }))

  setIsLoading(false)
}

export const handleSubmitForm = (
  values,
  setIsLoading,
  handleSubmitDialog,
  setDialog
) => {
  setIsLoading(true)
  handleSubmitDialog(values)
  setDialog((prevState) => ({
    ...prevState,
    open: false,
  }))
}

export default function Index({
  state,
  open,
  handleClose,
  setOpenDialog,
  stateFormDialog,
  handleSubmitDialog,
  listResponsibility,
}) {
  const [initialValues, setInitialValues] = useState(DEFAULT_DIALOG_VALUE)
  const [optionDepartment, setOptionDepartment] = useState([])

  const [validateFormDialog, setValidateFormDialog] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const approver = _.get(initialValues, 'approver', []) || []

  const getApproverList = () => {
    if (_.get(initialValues, 'station', '') === '' || approver.length === 0)
      return '-'
    const a = initialValues.approver.map((item) => {
      return (
        _.get(item, 'firstNameTH', '') + ' ' + _.get(item, 'lastNameTH', '')
      )
    })
    let text = ''
    a.forEach((item, index) => {
      text += `${item}`
      if (index !== a.length - 1) text += `, `
    })
    return text
  }
  useEffect(() => {
    if (stateFormDialog) {
      setInitialValues({
        ...stateFormDialog,
        startDate: dayjs(_.get(stateFormDialog, 'startDate', null)).format(
          window.__env__.REACT_APP_DATE_DB
        ),
        finishDate: dayjs(_.get(stateFormDialog, 'finishDate', null)).format(
          window.__env__.REACT_APP_DATE_DB
        ),
      })
    }

    fetchDepartment()
  }, [open, stateFormDialog])

  const [dialog, setDialog] = useState({
    variant: 'save',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    open: false,
  })

  const handleCloseForm = () => {
    setInitialValues(DEFAULT_DIALOG_VALUE)
    setValidateFormDialog({})
    handleClose()
    setOpenDialog(false)
  }

  const fetchDepartment = async () => {
    setIsLoading(true)
    const resultGet = await getDepartment()
    const uuidArea = _.get(state, 'area.value', '')
    const result = resultGet.length
      ? resultGet.filter((item) => {
          return item.area !== uuidArea
        })
      : []
    setOptionDepartment(result)
    setIsLoading(false)
  }

  return (
    <>
      <BoxDialog
        onClose={handleCloseForm}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <BoxDialogTitle id="customized-dialog-title" onClose={handleCloseForm}>
          <Typography variant="h6">ยืมวิทยากรโซนอื่น</Typography>
        </BoxDialogTitle>
        <DialogContent>
          <Box
            sx={
              isLoading
                ? { opacity: 0.4, pointerEvents: 'none', pt: 9.5 }
                : { pointerEvents: 'unset' }
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 3,
              }}
            >
              <Select
                dataTestid="select-department"
                required
                labelText="Department"
                placeholder="เลือก Department"
                id="department"
                name="department"
                type="basic"
                options={optionDepartment}
                textError={_.get(validateFormDialog, 'department', '')}
                value={
                  _.get(initialValues, 'department.value', '') ||
                  _.get(
                    findDepartmentAndStation(
                      _.get(initialValues, 'approver.roleAreaInfo.area', []),
                      _.get(initialValues, 'station', '') ||
                        _.get(initialValues, 'approver.station.uuid', ''),
                      false
                    ),
                    'department.uuid',
                    ''
                  )
                }
                handleChange={(e) =>
                  onChangeStation(
                    e.target.value,
                    'department',
                    setIsLoading,
                    initialValues,
                    setInitialValues,
                    optionDepartment
                  )
                }
              />
              <Select
                dataTestid="select-station"
                required
                labelText="Station"
                placeholder="เลือก Station"
                id="station"
                name="station"
                type="basic"
                disabled={
                  _.get(initialValues, 'department.station', []).length ||
                  _.get(initialValues, 'approver.station.uuid', '')
                    ? false
                    : true
                }
                options={findOptionStation(
                  optionDepartment,
                  _.get(initialValues, 'department.value', '') ||
                    _.get(
                      findDepartmentAndStation(
                        _.get(initialValues, 'approver.roleAreaInfo.area', []),
                        _.get(initialValues, 'approver.station.uuid', ''),
                        false
                      ),
                      'department.uuid',
                      ''
                    )
                )}
                value={_.get(initialValues, 'department.area', '')}
                textError={
                  (_.get(initialValues, 'department.value', '') ||
                    _.get(
                      findDepartmentAndStation(
                        _.get(initialValues, 'approver.roleAreaInfo.area', []),
                        _.get(initialValues, 'station', '') ||
                          _.get(initialValues, 'approver.station.uuid', ''),
                        false
                      ),
                      'department.uuid',
                      ''
                    )) &&
                  _.get(validateFormDialog, 'station', '')
                }
                handleChange={(e) =>
                  onChangeStation(
                    e.target.value,
                    'station',
                    setIsLoading,
                    initialValues,
                    setInitialValues,
                    optionDepartment
                  )
                }
              />
            </Box>

            <Box sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body2b">ผู้อนุมัติ</Typography>
              <Typography variant="body1">{getApproverList()}</Typography>
            </Box>
            <TextInput
              required
              id="purpose"
              name="purpose"
              type="basic"
              labelText="จุดประสงค์ของการยืม"
              multiline={true}
              rows={3}
              textError={_.get(validateFormDialog, 'purpose', '')}
              value={initialValues.purpose}
              onChange={(e) =>
                onChangeForm(
                  e.target.value,
                  'purpose',
                  listResponsibility,
                  setInitialValues,
                  setValidateFormDialog
                )
              }
            />
            <Select
              dataTestid="select-responsibility"
              required
              labelText="หน้าที่"
              placeholder="เลือกหน้าที่"
              id="responsibility"
              name="responsibility"
              type="basic"
              options={listResponsibility}
              textError={_.get(validateFormDialog, 'responsibility', '')}
              value={_.get(initialValues, 'responsibility.uuid', '')}
              handleChange={(e) =>
                onChangeForm(
                  e.target.value,
                  'responsibility',
                  listResponsibility,
                  setInitialValues,
                  setValidateFormDialog
                )
              }
            />
            <TextInput
              required
              sx={{ width: '100%' }}
              id="numSpeaker"
              labelText="จำนวนวิทยากรที่ต้องการ"
              placeholder="ระบุจำนวน"
              type="basic"
              textError={_.get(validateFormDialog, 'numSpeaker', '')}
              value={initialValues.numSpeaker}
              onChange={(e) =>
                onChangeForm(
                  e.target.value.replace(/[^\d]/g, ''),
                  'numSpeaker',
                  listResponsibility,
                  setInitialValues,
                  setValidateFormDialog
                )
              }
            />

            <TextInput
              required
              id="skillSpeaker"
              name="skillSpeaker"
              type="basic"
              labelText="Skill ที่ต้องการ"
              multiline={true}
              rows={3}
              textError={_.get(validateFormDialog, 'skillSpeaker', '')}
              value={initialValues.skillSpeaker}
              onChange={(e) =>
                onChangeForm(
                  e.target.value,
                  'skillSpeaker',
                  listResponsibility,
                  setInitialValues,
                  setValidateFormDialog
                )
              }
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 3,
              }}
            >
              <DatePicker
                id="startDate"
                name="startDate"
                labelText="ตั้งแต่วันที่"
                required
                disabledInput={true}
                value={dayjs(initialValues.startDate)}
                disabledStartDate={dayjs(initialValues.startDate)}
                disabledEndDate={dayjs(initialValues.finishDate)}
              />
              <DatePicker
                id="finishDate"
                name="finishDate"
                labelText="ถึงวันที่"
                required
                disabledInput={true}
                value={dayjs(initialValues.finishDate)}
                disabledStartDate={dayjs(initialValues.startDate)}
                disabledEndDate={dayjs(initialValues.finishDate)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 3,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="body2">เวลาเริ่ม</Typography>
                  <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                    *
                  </Typography>
                </Box>

                <TimePicker
                  id="startTime"
                  name="startTime"
                  helperText={_.get(validateFormDialog, 'startTime', '')}
                  value={convertTime(initialValues.startTime)}
                  onChange={(e) =>
                    onChangeForm(
                      e.target.value,
                      'startTime',
                      listResponsibility,
                      setInitialValues,
                      setValidateFormDialog
                    )
                  }
                  disabledStartTime={convertTime(initialValues.startTime)}
                  disabledEndTime={convertTime(initialValues.endTime)}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="body2">เวลาสิ้นสุด</Typography>
                  <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                    *
                  </Typography>
                </Box>

                <TimePicker
                  id="endTime"
                  name="endTime"
                  helperText={_.get(validateFormDialog, 'endTime', '')}
                  value={convertTime(initialValues.endTime)}
                  onChange={(e) =>
                    onChangeForm(
                      e.target.value,
                      'endTime',
                      listResponsibility,
                      setInitialValues,
                      setValidateFormDialog
                    )
                  }
                  disabledStartTime={convertTime(initialValues.startTime)}
                  disabledEndTime={convertTime(initialValues.endTime)}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              <TextInput
                id="remark"
                name="remark"
                type="basic"
                labelText="หมายเหตุ"
                placeholder="เช่น ระบุชื่อวิทยากรที่ต้องการ"
                multiline={true}
                rows={3}
                textError={_.get(validateFormDialog, 'remark', '')}
                value={initialValues.remark}
                onChange={(e) =>
                  onChangeForm(
                    e.target.value,
                    'remark',
                    listResponsibility,
                    setInitialValues,
                    setValidateFormDialog
                  )
                }
              />
            </Box>
            <Box
              sx={{
                mt: 'auto',
                display: 'flex',
                flexDirection: 'row',
                position: 'sticky',
                bottom: 0,
                zIndex: 2,
                backgroundColor: 'background.paper',
              }}
            >
              <Button
                data-testid="btn-cancel"
                variant="outlined"
                size="m"
                sx={{
                  ml: -1,
                  width: 85,
                }}
                onClick={() => handleCloseForm()}
              >
                ยกเลิก
              </Button>
              <Box
                sx={{
                  mr: -1,
                  ml: 'auto',
                }}
              >
                <Button
                  sx={{ width: 120 }}
                  data-testid="btn-cancel"
                  variant="outlined"
                  size="m"
                  disabled={!approver.length}
                  onClick={() =>
                    handleSubmit(
                      initialValues,
                      setValidateFormDialog,
                      'DRAFT',
                      setInitialValues,
                      setDialog
                    )
                  }
                >
                  บันทึกฉบับร่าง
                </Button>
                <Button
                  sx={{
                    ml: 3,
                    width: 85,
                  }}
                  data-testid="btn-save"
                  variant="contained"
                  name="submit"
                  type="submit"
                  size="m"
                  disabled={!approver.length}
                  onClick={() =>
                    handleSubmit(
                      initialValues,
                      setValidateFormDialog,
                      'WAITING',
                      setInitialValues,
                      setDialog
                    )
                  }
                >
                  ส่งคำขอ
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </BoxDialog>
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => setDialog({ ...dialog, open: false })}
        title={dialog.title}
        variant={dialog.variant}
        onCancelClick={() => setDialog({ ...dialog, open: false })}
        onConfirmClick={() =>
          handleSubmitForm(
            initialValues,
            setIsLoading,
            handleSubmitDialog,
            setDialog
          )
        }
        content={dialog.content}
        isLoading={isLoading}
      />
    </>
  )
}
