import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { initialize } from './handler/initialize'
import Header from './components/Header'
import FilterDrawer from './components/FilterDrawer'
import RoomRequestDrawer from './components/RoomRequestDrawer'
import Content from './components/Content'

const Index = () => {
  const [user] = useLocalStorage('user')
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  useEffect(() => initialize(user), [])
  return (
    <Box px={2}>
      <Box>
        <Header />
      </Box>
      <Box my={3}>
        <Content />
      </Box>

      <FilterDrawer />
      <RoomRequestDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}
export default Index
