import axios from 'axios'
import React from 'react'
import {
  closeDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../redux/slices/dialog'
import { eTesting } from '../../../../utils/apiPath'
import { fetchDataList } from '../event'
import { DialogContent } from '.'

export const handleToggleButton = (event) => (dispatch) => {
  if (event.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleSwitch = (event, uuid, tableProps) => (dispatch) => {
  const { table, page, status, filterProp, searchText, codeId } = tableProps

  if (!event.target.checked) {
    dispatch(
      openDialog({
        title: 'ปิดใช้งาน',
        type: 'content',
        disableButton: true,
        content: <DialogContent />,
        handleConfirm: () => {
          // set status to ปิดใช้งาน
          axios
            .put(`${eTesting}/${uuid}`)
            .then(() =>
              dispatch(
                fetchDataList(
                  'fetch',
                  table,
                  page,
                  status,
                  filterProp,
                  searchText,
                  codeId
                )
              )
            )
          dispatch(closeDialog())
        },
      })
    )
  } else {
    // set status to เปิดใช้งาน
    axios
      .put(`${eTesting}/${uuid}`)
      .then(() =>
        dispatch(
          fetchDataList(
            'fetch',
            table,
            page,
            status,
            filterProp,
            searchText,
            codeId
          )
        )
      )
  }
}
