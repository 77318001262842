import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import HeaderForm from './Header'
import TabMenu from './TabMenu'
import Menu from './Menu'

import * as StylesMain from '../Styled'
import * as event from './event'
import { initialState } from '../../../redux/slices/eEvaluationForm/model'
import { setChange, setOnViewMode } from '../../../redux/slices/eEvaluationForm'
import { getQueryParams } from '../../../utils/lib'

const Index = ({ isClone }) => {
  const { id: uuid, codeId, createType } = useParams()
  const { pathname } = useLocation()
  const isViewMode = pathname.includes('view-mode')
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.eEvaluationForm.isLoading,
    }),
    shallowEqual,
  )

  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const props = { uuid, isClone, onEdit, setOnEdit, codeId, createType }

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    const defaultTab = getQueryParams('tab')
    dispatch(event.handlePersist(props))
    dispatch(
      setChange({ key: 'defaultTap', value: defaultTab ?? 'assessment' }),
    )
    dispatch(setOnViewMode(isViewMode))
  }, [])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Formik
          initialValues={initialState}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(value, { setErrors }) => {
            const propSubmit = {
              stateEvaluation: value,
              history,
              isDraft: value.isDraft,
              setErrors,
            }
            dispatch(event.onSubmitFormEValuation(propSubmit))
          }}
        >
          <Form>
            <HeaderForm />
            <TabMenu uuid={uuid} />
            {isViewMode && (
              <Alert variant="alert" severity="warning" sx={{ mt: 4 }}>
                View Mode
              </Alert>
            )}
            <Menu />
          </Form>
        </Formik>
      </Box>

      <StylesMain.LoadingPage isLoading={isLoading} />
    </Box>
  )
}
export default Index
