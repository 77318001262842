import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { StyledRowFlex } from '../Styled'

export const StyledColumnFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette?.text?.mercury,
  borderStyle: 'dashed',
}))

export const StyledStaffDisplay = styled(StyledRowFlex)(({ theme }) => ({
  rowGap: theme.spacing(0.5),
  '& .MuiTypography-root': { width: '33%' },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    '& .MuiTypography-root:first-of-type': { width: '100%' },
    '& .MuiTypography-root:not(:first-of-type)': { width: 'calc(50% - 12px)' },
  },
}))

export const StyledRespRow = styled(StyledRowFlex)(({ theme, isInput }) => ({
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: isInput ? 0 : theme.spacing(3),
  },
}))

export const StyledColumnNoGap = styled(Box)(({ theme, noGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: { gap: noGap ? 0 : theme.spacing(3) },
}))


export const StyledRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

