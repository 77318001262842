import React, { useEffect, useState } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ArrowLeftTwoTone from '@mui/icons-material/ArrowLeftTwoTone'
import ArrowRightTwoTone from '@mui/icons-material/ArrowRightTwoTone'
import Edit from '@mui/icons-material/Edit'

import TextInput from '../../../../../components/Input/TextInput'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import { handleInputBody, handleSelector, handleSwitchBody } from '../events'
import ActiveSwitch from '../../../components/ActiveSwitch'
import TrainingDrawer from './TrainingDrawer'
import {
  StyledArrowButton,
  StyledChip,
  StyledContainerPlatform,
  StyledFooter,
  StyledPlatformBox,
  StyledTypography,
} from './Styled'
import { fetchOptionProductType, handleDefault } from './events'

const TrainingPlatform = () => {
  const [onEdit] = useLocalStorage('onEdit')
  const keyField = 'trainingPlatformInformation'
  const trainingPlatform = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const platforms = _.get(trainingPlatform, 'platforms', [])
  const dispatch = useDispatch()
  const { errors } = useFormikContext()

  useEffect(() => {
    dispatch(fetchOptionProductType(onEdit))
  }, [])

  return (
    <StyledSection>
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">
          Section 4 | Training Platform Information
        </Typography>
        <ActiveSwitch
          name="status"
          dataTestId={`active-section-4`}
          checked={_.get(trainingPlatform, 'status', false)}
          onChange={(e) => dispatch(handleSwitchBody(e, keyField))}
        />
      </StyledRow>
      <StyledColumn>
        <TextInput
          required
          name="title"
          type="basic"
          labelText="Title"
          id="input-title"
          placeholder="กรุณากรอก"
          value={_.get(trainingPlatform, 'title', '')}
          textError={_.get(errors, `${keyField}.title`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />

        <Box
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <StyledContainerPlatform>
            {platforms.map((item, index) => (
              <PlatformBox key={index} index={index} data={item} />
            ))}
          </StyledContainerPlatform>
        </Box>
      </StyledColumn>
    </StyledSection>
  )
}

export default TrainingPlatform

export const LabelData = ({ label, data }) => {
  return (
    <StyledColumn>
      <Typography variant="body2b">{label}</Typography>
      <StyledTypography label={label} variant="body1" color="text.gray">
        {data}
      </StyledTypography>
    </StyledColumn>
  )
}

export const PlatformBox = ({ data, index }) => {
  const [isOpen, setOpen] = useState(false)
  const productTypes = _.get(data, 'productTypes', [])

  return (
    <StyledPlatformBox>
      <StyledColumn gap={2}>
        <StyledRow justifyContent="space-between" alignItems="center">
          <Typography variant="body1b">
            Platform {_.get(data, 'no', 1)}
          </Typography>
          <IconButton
            data-testid={`btn-edit-${index}`}
            onClick={() => setOpen(true)}
          >
            <Edit />
          </IconButton>
        </StyledRow>
        <LabelData label="Title" data={handleDefault(data, 'title')} />
        <LabelData
          label="Description"
          data={handleDefault(data, 'description')}
        />
      </StyledColumn>
      <StyledFooter
        id={`tabs-${index}`}
        indicatorColor="secondary"
        variant="scrollable"
        value={false}
        scrollButtons
        allowScrollButtonsMobile
        selectionFollowsFocus
        ScrollButtonComponent={handleScrollButton}
      >
        {productTypes.map((item, index) => (
          <StyledChip key={index} label={item.productType} />
        ))}
      </StyledFooter>
      <TrainingDrawer
        data={data}
        isOpen={isOpen}
        onCloseDrawer={() => setOpen(false)}
      />
    </StyledPlatformBox>
  )
}

export const handleScrollButton = (props) => {
  if (props.direction == 'left') {
    return (
      <StyledArrowButton {...props}>
        <ArrowLeftTwoTone />
      </StyledArrowButton>
    )
  } else {
    return (
      <StyledArrowButton {...props}>
        <ArrowRightTwoTone />
      </StyledArrowButton>
    )
  }
}
