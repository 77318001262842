import React from 'react'
import { SECTION_KEY } from '../model'
import SectionCard from './components/SectionCard'
import Banner from './Banner'
import Footer from './Footer'
import { StyledFormContainer } from './Styled'

const Form = () => {
  return (
    <StyledFormContainer>
      <Banner />

      {/* course */}
      <SectionCard keySection={SECTION_KEY.COURSE} />

      {/* learning path */}
      <SectionCard keySection={SECTION_KEY.LEARNING_PATH} />

      {/* Collection */}
      <SectionCard keySection={SECTION_KEY.COLLECTION} />

      <Footer />
    </StyledFormContainer>
  )
}

export default Form
