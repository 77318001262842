import React from 'react'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../Styled'
import { togglDetailDrawer } from '../../handler/handleClickOpenDrawer'
import { shallowEqual, useSelector } from 'react-redux'
// import { Part1Content } from './components/Part1Content'
// import { Part2Content } from './components/Part2Content'
// import { Part3Content } from './components/Part3Content'
// import { Part4Content } from './components/Part4Content'
// import { questionTypeText } from '../../constants/type'
// import { StyledDownloadButton } from '../../../../../../../components/redux/Table/Styled'
// import { handleDownloadFreeText } from '../../handler/handleDownloadFreeText'
import TruncatedText from '../../components/TruncatedText'

const DetailDrawer = () => {
  const { open, title } = useSelector(
    (state) => ({
      open: state.crud.mixModuleDashboard?.detailDrawer?.isOpen,
      title: state.crud.mixModuleDashboard?.detailDrawer?.title,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer open={open} onClose={() => togglDetailDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <TruncatedText
            text={title ?? ''}
            readMoreTxt={'ดูเพิ่มเติม'}
            maxWidth={'72vw'}
            titleVariant={'h5'}
            maxLines={3}
            dialogTitle={'คำถาม'}
          />
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => togglDetailDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      {/* <Box>{drawerType === 'part1' && <Part1Content />}</Box>
      <Box>{drawerType === 'part2' && <Part2Content />}</Box>
      <Box>{drawerType === 'part3' && <Part3Content />}</Box>
      <Box>{drawerType === 'part4' && <Part4Content />}</Box> */}
      <StyledFooter>
        <Button onClick={() => togglDetailDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default DetailDrawer
