import _ from 'lodash'
import { getLearnerRoom } from '../../../../../../../services/manageClass/learnerDetail'
import { setToggleFilterDrawer } from '../../../../../../../redux/slices/table'
import { onFilterClick } from '../event'

export const handleFiltering = (table, filter, uuid) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(table, filter, uuid))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleDateChange = (props) => (dispatch) => {
  const { id, date, setFilter } = props
  const body = { trainingPlan: id, date }
  dispatch(getLearnerRoom(body))
  setFilter((prev) => ({ ...prev, date, room: [] }))
}

export const handleSelectDate = (selectedDates, filter, setFilter, item) => {
  const objState = {
    ...filter,
    [item.dateStateStart]: selectedDates.startDate,
    [item.dateStateFinish]: selectedDates.endDate,
    [item.dateStateDisplay]: selectedDates.display,
  }
  setFilter(objState)
}
