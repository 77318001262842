import { setFieldValue } from "../../../../../redux/slices/crud"
import { store } from "../../../../../App"

export const handleEndDateChange = async (event) => {
    const { formData } = store.getState().crud
    if(formData.checkEndDateForm){
        store.dispatch(setFieldValue({
            key: 'formData.renewContractDateForm',
            value: event,
        }))
    }
}