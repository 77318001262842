import axios from 'axios'
import _ from 'lodash'
import {
  getAssessmentPointer,
  getQuestionPointer,
} from './../../../../redux/slices/eEvaluationPreview/events'
import { mockLecturerName } from '../../../../constants/eEvaluation'
import {
  setInitialPreview,
  setInititialAssessment,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eEvaluationPreview'
import { store } from '../../../../App'
import { staffProfile } from '../../../../utils/apiPath'

export const fetchEvaluate = (eEvaluation, isModule) => async (dispatch) => {
  if (isModule) {
    const { eEvaluationUuid } = store.getState().eLearningPreview.selectedLesson
    if (eEvaluationUuid !== eEvaluation.uuid) return
  }

  dispatch(startLoading())
  const {
    information,
    setting,
    courseAssessments,
    lecturerAssessments,
    courseManagementAssessments,
    questions,
    subQuestions,
  } = eEvaluation

  let lecturers = []
  const isPersonal = _.get(lecturerAssessments, '[0].isPersonal', false)
  if (isPersonal) {
    const course = store.getState().eLearningPreview.course
    if (isModule && !_.isEmpty(course)) {
      const assignees = _.get(course, 'setting.assignees', []).filter(
        (assignee) => assignee.type === 'SPEAKER',
      )
      let index = 0
      for (const assignee of assignees) {
        const response = await axios.get(`${staffProfile}/${assignee.value}`)
        const staffName = `${response.data.firstNameTH} ${response.data.lastNameTH}`
        const lecturer = handleAssessmentList(
          lecturerAssessments,
          staffName,
          index,
        )
        index++
        lecturers = _.concat(lecturers, lecturer)
      }
    } else {
      // mock 2 lecturers for preview
      const lecturerMan = handleAssessmentList(
        lecturerAssessments,
        mockLecturerName.men,
      )
      const lecturerWomen = handleAssessmentList(
        lecturerAssessments,
        mockLecturerName.women,
      )
      lecturers = _.concat(lecturerMan, lecturerWomen)
    }
  } else {
    lecturers = handleAssessmentList(lecturerAssessments)
  }

  const courses = handleAssessmentList(courseAssessments)
  const courseManagements = handleAssessmentList(courseManagementAssessments)
  const questionList = handleQuestionList(questions, subQuestions)

  const pointerCourse = getAssessmentPointer(courses, 'courses')
  const pointerLecturer = getAssessmentPointer(lecturers, 'lecturers')
  const pointerCourseManagement = getAssessmentPointer(
    courseManagements,
    'courseManagements',
  )
  const pointerQuestion = getQuestionPointer(questionList)

  const initialPayload = {
    uuid: eEvaluation.uuid,
    information,
    setting,
    courses,
    lecturers,
    courseManagements,
    questions: questionList,
  }

  const initialAssesement = {
    pointerCourse,
    pointerLecturer,
    pointerCourseManagement,
    pointerQuestion,
  }
  dispatch(setInitialPreview(initialPayload))
  dispatch(setInititialAssessment(initialAssesement))
  dispatch(stopLoading())
}

export const handleAssessmentList = (assessments, name = null, index) => {
  return assessments.map((item) => {
    let obj = {
      ...item,
      key: name == mockLecturerName.women ? 1 : 0,
      isCompleted: false,
      questions: handleRatingQuestion(item.questions),
    }
    if (name) obj = { ...obj, name }
    if (index) obj = { ...obj, key: index }
    return obj
  })
}

export const handleAnswerList = ({ list, subList, isAnswer = false }) => {
  return list.map((choice, index) => {
    const type = choice.answerType
    const isOther = type == 'OTHER'
    let obj = {
      uuid: isOther ? type : Date.now() + index,
      title: isOther ? 'อื่นๆ' : choice.title,
    }

    if (isAnswer) return { ...obj, isChecked: false }

    if (subList) {
      if (choice.nextQuestionType == 'NEXT_SUB') {
        subList.value.map((sub) => {
          if (sub.sequence == choice.nextSubQuestionSequence) {
            const choiceArr =
              sub.questionType == 'RATING'
                ? _.get(sub, 'choices', [])
                : _.get(sub, 'answers', [])
            const choices = handleAnswerList({ list: choiceArr })

            let answer = []
            if (sub.questionType == 'MULTIPLE') {
              answer = handleAnswerList({ list: choiceArr, isAnswer: true })
            }
            obj = {
              ...obj,
              subQuestions: {
                uuid: sub.uuid,
                key: sub.sequence,
                sequence: subList.sequence,
                subSequence: sub.sequence,
                type: sub.questionType,
                mediaType: sub.mediaType,
                url: sub.url,
                title: sub.title,
                setTypeOfAnswers: sub.setTypeOfAnswers,
                typeOfAnswers: sub.typeOfAnswers,
                numberOfAnswers: sub.numberOfAnswers,
                isSuggestion: sub.isSuggestion,
                questions: handleRatingQuestion(sub.questions),
                choices,
                answer,
                isAnswered: false,
              },
            }
          }
        })
      }
    }

    return { ...choice, ...obj }
  })
}

export const handleQuestionList = (questions, subQuestionArr) => {
  return questions.map((question) => {
    const choiceArr =
      question.questionType == 'RATING'
        ? _.get(question, 'choices', [])
        : _.get(question, 'answers', [])
    const choices = handleAnswerList({
      list: choiceArr,
      subList:
        subQuestionArr &&
        subQuestionArr.find((item) => item.sequence == question.sequence),
    })

    let answer = []
    if (question.questionType == 'MULTIPLE') {
      answer = handleAnswerList({ list: choiceArr, isAnswer: true })
    }

    return {
      uuid: question.uuid,
      key: question.sequence,
      sequence: question.sequence,
      type: question.questionType,
      mediaType: question.mediaType,
      url: question.url,
      title: question.title,
      setNextQuestion: question.setNextQuestion,
      setTypeOfAnswers: question.setTypeOfAnswers,
      typeOfAnswers: question.typeOfAnswers,
      numberOfAnswers: question.numberOfAnswers,
      isSuggestion: question.isSuggestion,
      questions: handleRatingQuestion(question.questions),
      choices,
      answer,
      isAnswered: false,
    }
  })
}

export const handleRatingQuestion = (list) => {
  if (list) {
    return list.map((question, key) => ({
      ...question,
      isAnswered: false,
      key,
    }))
  } else return null
}
