import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import { oicStatus } from '../../../../constants/manageClass'
import {
  handleCloseDialog,
  handleEnableButton,
  handleNumber,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead,
  StyledHeader,
  BoxTextHeader,
} from './Styled'
import _ from 'lodash'

export const CheckDialog = ({ uploadResult, fetchData }) => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const dispatch = useDispatch()
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const successCount = useMemo(() => {
    const successList = result.filter((item) => item.status == 'ACTIVE')
    return successList.length
  }, [result])
  const uploadSuccess = status == 'ACTIVE'

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <StyledDialog>
        {!uploadSuccess && (
          <StyledHeader sx={{ ml: 3, px: 1.5 }}>
            <BoxTextHeader>
              <Typography>
                สามารถเพิ่มรายการผลสอบได้ <span>{successCount} รายการ</span>{' '}
                จากไฟล์ที่คุณเลือก
              </Typography>
            </BoxTextHeader>
            <BoxTextHeader isMobile>
              <Typography>
                สามารถเพิ่มรายการผลสอบได้ <span>{successCount} รายการ</span>{' '}
              </Typography>
              <Typography>จากไฟล์ที่คุณเลือก</Typography>
            </BoxTextHeader>
          </StyledHeader>
        )}
        <StyledTable>
          <StyledTableHead>
            <Typography>No.</Typography>
            <Typography>ชื่อ</Typography>
            <Typography>นามสกุล</Typography>
            <Typography>วันที่เข้าสอบ</Typography>
            <Typography>สถานะ</Typography>
          </StyledTableHead>
          {result.map((item) => (
            <StyledTableData status={item.status} key={item.index}>
              <Typography>{handleNumber(item.id)}</Typography>
              <Typography>{item.FNAME}</Typography>
              <Typography>{item.LNAME}</Typography>
              <Typography>{item.examDate}</Typography>
              <Typography id="status">{oicStatus[item.status]}</Typography>
            </StyledTableData>
          ))}
        </StyledTable>
        {successCount > 0 && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label="เพิ่มรายการที่สามารถเพิ่มได้"
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() => dispatch(handleSubmitAdd(uploadResult, fetchData))}
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            ตกลง
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">นำเข้ารายการผลสอบได้ทั้งหมด</Typography>
    </Box>
  )
}
