import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { 
  examinationRegionList,
  examinationCompanyFilter,
  examinationAssociationFilter,
  examinationSchedule,
  examinationScheduleEventFilter,
  examinationGender,
  examinationQualification,
  examinationAddress,
  manageClassUserProfile,
  manageClassUserAgentDetail,
  examinationIdCard,
  examinationStudentDetail,
  examinationTime,
 } from '../../utils/apiPath'

export const fetchRegionList = createAsyncThunk(
  'eExamination/fetchRegionList',
  async (payload, { rejectWithValue }) => {
    try {
      const body = {
        regionName: '',
        createdBy: '',
        updatedBy: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      const response = await axios.post(examinationRegionList, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const fetchExaminationCompanyList = createAsyncThunk(
  'eExamination/fetchExaminationCompanyList',
  async (payload, { rejectWithValue }) => {
    try {
      const body = {
        search: '',
        searchStation: '',
        station: '',
        locationCode: '',
        createdBy: '',
        updatedBy: '',
        status: ['ACTIVE'],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'updatedAt',
      }
      const response = await axios.post(examinationCompanyFilter, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const fetchExaminationAssociationList = createAsyncThunk(
  'eExamination/fetchExaminationAssociationList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        search: '',
        region: [uuid],
        provinceName: '',
        locationCode: '',
        createdBy: '',
        updatedBy: '',
        status: ['ACTIVE'],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'updatedAt',
      }
      const response = await axios.post(examinationAssociationFilter, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getExaminationScheduleDetail = createAsyncThunk(
  'eExamination/getExaminationScheduleDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationSchedule}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationScheduleEvent = createAsyncThunk(
  'eExamination/getExaminationScheduleEvent',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${examinationScheduleEventFilter}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationGender = createAsyncThunk(
  'eExamination/getExaminationGender',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationGender}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationQualification = createAsyncThunk(
  'eExamination/getExaminationQualification',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationQualification}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationAddress = createAsyncThunk(
  'eExamination/getExaminationAddress',
  async (zipcode, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationAddress}/${zipcode}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationUserProfile = createAsyncThunk(
  'eExamination/getExaminationUserProfile',
  async (payload, { rejectWithValue }) => {
    try {
      const { body, type } = payload
      let url = manageClassUserProfile
      if(type === 'L' || type === 'A'){
        url = manageClassUserAgentDetail
      }
      const response = await axios.post(`${url}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const sendExaminationIdCard = createAsyncThunk(
  'eExamination/sendExaminationIdCard',
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(`${examinationIdCard}`, formData, 
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-rapidapi-host": "file-upload8.p.rapidapi.com",
            "x-rapidapi-key": "your-rapidapi-key-here",
          },
        }
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminerFormDetail = createAsyncThunk(
  'eExamination/getExaminerFormDetail',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationStudentDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getExaminationTime = createAsyncThunk(
  'eExamination/getExaminationTime',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${examinationTime}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
