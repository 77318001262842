import {
  startLoading,
  stopLoading,
} from '../../../../../src/redux/slices/apiToRcms'
import { downloadFile } from '../../../../../src/services/util'
import { apiToRcmsDetailDownload } from '../../../../../src/utils/apiPath'
import { resetForm } from '../../../../redux/slices/manageClassLearner'
import { closeDialog } from '../../../../redux/slices/dialog'

export const onDownloadDetail = (body) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(
    downloadFile({
      url: apiToRcmsDetailDownload,
      body: body,
      fileName: `ApiToRcmsDetail.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(closeDialog())
}
