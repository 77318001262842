import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { store } from '../../../../../App'
import { CustomSelect } from '../../../../../components/CRUD/components/CustomSelect'
import { Box } from '@mui/material'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { productTypeList } from '../model/productTypeList'

export const handleOpenCreateDialog = async (history) => {
  const { productType } = store.getState().crud.masterData
  if(!productType){
    store.dispatch(
      setFieldValue({
        key: 'masterData.productType',
        value: { isLoading: false, options: productTypeList },
      }),
    )
  }
  
  store.dispatch(
    openDialog({
      type: 'content',
      title: 'สร้างทรัพย์สิน',
      content: (
        <Box px={2} pb={2}>
          <CustomSelect fieldName="productType" labelName="ประเภททรัพย์สิน" />
        </Box>
      ),
      agreeText: 'ตกลง',
      disagreeText: 'ยกเลิก',
      isCloseDialog: false,
      handleConfirm: () => {
        const { productType } = store.getState().crud.formData
        history.push(`/inventory/product/${productType}/form`)
        store.dispatch(closeDialog())
      },
    }),
  )
}
