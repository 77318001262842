import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'
import _ from 'lodash'
import {
  stockSKUOptions,
  historyOptions,
  AssetListAssetOptions,
  openDeleteDialog,
  openAssetHistoryDrawer,
  enabledAsset,
  openAssetDetailDrawer,
} from './events'
import { store } from '../../../../../../App'
import { useHistory } from 'react-router-dom'

const DropdownButton = (props) => {
  const { formData } = store.getState().crud
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  const { isHistory, ticketId, isAsset, item } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const history = useHistory()

  // let product = _.get(formData, 'product', '')
  // let productType = _.get(row, 'product.productType', '')
  // let warehouse = _.get(row, 'warehouse', '')
  // if (row?.productWarehouse) {
  //   productType = _.get(row, 'productWarehouse.product.productType', '')
  //   warehouse = _.get(row, 'productWarehouse.warehouse', '')
  //   product = _.get(row, 'productWarehouse.product', '')
  // }

  let menuOptions = createOptions
  if (
    [
      PRODUCT_TYPE.STOCK_ASSET,
      PRODUCT_TYPE.ASSET,
      PRODUCT_TYPE.LICENSE,
    ].includes(productType)
  ) {
    menuOptions = stockSKUOptions
  }
  if (isHistory) menuOptions = historyOptions
  if (
    isAsset &&
    [PRODUCT_TYPE.ASSET, PRODUCT_TYPE.LICENSE].includes(productType)
  ) {
    menuOptions = AssetListAssetOptions.filter(
      (option) => option.value !== 'view_ticket',
    )
  }
  if (isAsset && [PRODUCT_TYPE.STOCK_ASSET].includes(productType)) {
    menuOptions = AssetListAssetOptions
  }

  if (isAsset) {
    if (item?.status === 'ACTIVE') {
      menuOptions = [{ text: 'ปิดใช้งาน', value: 'disabled' }, ...menuOptions]
    } else {
      menuOptions = [{ text: 'เปิดใช้งาน', value: 'enabled' }, ...menuOptions]
    }
    if (item?.status === 'DELETED') {
      menuOptions = menuOptions.filter((option) =>
        ['view_asset', 'history'].includes(option.value),
      )
    }
  }

  if (!ticketId) {
    menuOptions = menuOptions.filter((option) => {
      return option.value !== 'view_ticket'
    })
  }

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {menuOptions.map((option, index) => {
          let hidden = false
          if (!ticketId && option.value === 'view_ticket') {
            hidden = true
          }
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                if (['ticket', 'add_ticket'].includes(option.value)) {
                  history.push(
                    `${productPath.TICKET}/?warehouse=IMPORT_PRODUCT`,
                  )
                } else if (option.value === 'view_ticket') {
                  if (item?.ticket?.transferType === 'REQUISITION') {
                    history.push(`${productPath.REQUISITION_VIEW}/${ticketId}`)
                  } else {
                    history.push(`${productPath.TICKET_VIEW}/${ticketId}`)
                  }
                } else if (['enabled', 'disabled'].includes(option.value)) {
                  enabledAsset(option.value, item)
                } else if (option.value === 'view_asset') {
                  openAssetDetailDrawer(item)
                } else if (option.value === 'history') {
                  openAssetHistoryDrawer(item)
                } else if (option.value === 'del_asset') {
                  openDeleteDialog(item)
                }
                setAnchorEl(null)
              }}
              style={{ visibility: hidden ? 'hidden' : '' }}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const createOptions = [{ text: 'นำเข้าคลัง', value: 'ticket' }]

export const productPath = {
  STOCK: '/inventory/product/stock/view',
  STOCK_ASSET: '/inventory/product/stock/view',
  ASSET: '/inventory/product/asset/view',
  LICENSE: '/inventory/product/license/view',
  WAREHOUSE: '/inventory/stock/detail',
  TICKET: '/inventory/transfer/form',
  TICKET_VIEW: '/inventory/transfer/view',
  REQUISITION_VIEW: '/inventory/requisition/view',
}
