import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  openErrorDialog,
  openSuccessDialog,
  setEnableButton,
} from '../../../../../redux/slices/dialog'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassDetail'
import { putManageClassAnnouncement } from '../../../../../services/manageClass/check'

export const handleAnnouncing = (id, courseId) => async (dispatch) => {
  const body = {
    trainingPlanUuid: id,
    courseTestAndEvaUuid: courseId,
    isCheck: true,
  }

  dispatch(startLoading())
  const response = await dispatch(putManageClassAnnouncement(body))
  dispatch(stopLoading())
  const data = _.get(response.payload, 'data', undefined)
  if (data) {
    const waiting = _.get(data, 'waitingToCheckExam', 0)
    if (waiting > 0) {
      dispatch(
        openErrorDialog({
          title: 'พบแบบทดสอบรอตรวจเหลืออยู่',
          message: `เหลือแบบทดสอบ ${waiting} รายการที่รอตรวจอยู่ กรุณาตรวจให้ครบก่อนทำการประกาศผล `,
        })
      )
    } else {
      body.isCheck = false
      dispatch(
        openDialog({
          title: 'ประกาศผลสอบ',
          message: 'คุณต้องการประกาศผลแบบทดสอบที่ทำการตรวจแล้ว และส่งอีเมลแจ้งผู้เรียนเลยหรือไม่',
          handleConfirm: () => dispatch(handleConfirmAnnoucing(body)),
        })
      )
    }
  }
}

export const handleConfirmAnnoucing = (body) => async (dispatch) => {
  dispatch(loadingDialog())
  const result = await dispatch(putManageClassAnnouncement(body))
  const data = _.get(result.payload, 'data', undefined)
  if (data) {
    dispatch(setEnableButton())
    dispatch(
      openSuccessDialog({
        message: _.get(data, 'message', '-'),
        handleConfirm: () => window.location.reload(),
      })
    )
  }
}
