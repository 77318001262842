import React from 'react'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

import { useDispatch } from 'react-redux'
import { DialogContent } from './Styled'
import { handleCheckboxChange } from './events'

export const DialogSendEmail = () => {
  const dispatch = useDispatch()

  return (
    <>
      <DialogContent>
        <Typography variant="body1">
          ระบบจะปรับสถานะให้ผู้ถูกเลือกทั้งหมดว่า
          <span> ผ่านการตรวจสอบ </span>
          <br />
          และส่ง Email แจ้งเตือนให้รายชื่อทั้งหมดที่ถูกเลือกถึงสิทธิ์ในการเรียน
        </Typography>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-status"
                defaultChecked={false}
                onChange={(e) => dispatch(handleCheckboxChange(e))}
              />
            }
            label="ฉันรับทราบเงื่อนไขและต้องการอนุมัติ"
          />
        </Box>
      </DialogContent>
    </>
  )
}
