import { store } from 'src/App'
import dayjs from 'dayjs'
import _ from 'lodash'

export const handleQueryStringFilters = () => {
  let queryStringFilters = ''
  const {
    filters: {
      dateChecked,
      startDate,
      endDate,
      defaultDepartment,
      departmentChecked,
      department,
      departmentList,
      stationChecked,
      station,
      stationList,
      courseCodeChecked,
      courseCode,
      courseCodeList,
      positionChecked,
      position,
      positionList,
    },
    filtersList,
  } = store.getState().monthlyPlan.dashboard

  let formattedStartDate = dayjs().startOf('month').format('YYYY-MM-DD')
  let formattedEndDate = dayjs().format('YYYY-MM-DD')

  if (dateChecked && startDate && endDate) {
    formattedStartDate = dayjs(startDate).format('YYYY-MM-DD')
    formattedEndDate = dayjs(endDate).format('YYYY-MM-DD')
  }

  queryStringFilters += `${
    queryStringFilters ? '&' : ''
  }startDate=${formattedStartDate}&endDate=${formattedEndDate}`

  queryStringFilters += `&dateLabel=${
    _.find(filtersList, (filter) => filter.name === 'date')?.value
  }`

  let selectedDepartment = defaultDepartment
  if (departmentChecked && department.length > 0) {
    selectedDepartment = departmentList
  }

  queryStringFilters += `${
    queryStringFilters ? '&' : ''
  }department=${JSON.stringify(selectedDepartment)}`

  if (stationChecked && Object.values(station).some((value) => value)) {
    queryStringFilters += `${
      queryStringFilters ? '&' : ''
    }station=${JSON.stringify(stationList)}`
    queryStringFilters += `&stationLabel=${
      _.find(filtersList, (filter) => filter.name === 'station')?.value
    }`
  }
  if (courseCodeChecked && courseCode.length > 0) {
    queryStringFilters += `${
      queryStringFilters ? '&' : ''
    }courseCode=${JSON.stringify(courseCodeList)}`
    queryStringFilters += `&courseCodeLabel=${
      _.find(filtersList, (filter) => filter.name === 'courseCode')?.value
    }`
  }
  if (positionChecked && position.length > 0) {
    queryStringFilters += `${
      queryStringFilters ? '&' : ''
    }position=${JSON.stringify(positionList)}`
    queryStringFilters += `&positionLabel=${
      _.find(filtersList, (filter) => filter.name === 'position')?.value
    }`
  }
  return queryStringFilters
}
