export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const vendorTypeItems = [
  { label: 'Vendor', name: 'vendor'},
  { label: 'Asset', name: 'asset'},
  { label: 'Other', name: 'other'},
]

export const filterItems = [
  {
    label: 'ประเภท Vendor',
    nameCheckBox: 'vendorTypeChecked',
    nameInput: 'vendorType',
    type: 'checkbox',
    list: vendorTypeItems,
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'ระยะสัญญาการบริการ',
    placeholder: 'เลือก ระยะสัญญาการบริการ',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'dateStartDate',
    dateStateFinish: 'dateFinishDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'วันที่ต่อสัญญา',
    placeholder: 'เลือก วันที่ต่อสัญญา',
    nameCheckBox: 'renewDateChecked',
    nameInput: 'renewDate',
    dateStateStart: 'renewDateStartDate',
    dateStateFinish: 'renewDateFinishDate',
    dateStateDisplay: 'renewDateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'ผู้ติดต่อจากภายใน',
    nameCheckBox: 'insideContactChecked',
    nameInput: 'insideContact',
    type: 'text',
  },
  {
    label: 'ผู้ติดต่อจากภายนอก',
    nameCheckBox: 'outsideContactChecked',
    nameInput: 'outsideContact',
    type: 'text',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createStartDate',
    dateStateFinish: 'createFinishDate',
    dateStateDisplay: 'createDisplayDate',
    type: 'daterange',
  },
  {
    label: `วันที่แก้ไขล่าสุด`,
    nameCheckBox: 'updateDateChecked',
    nameInput: 'updateDate',
    placeholder: `กรุณาใส่ วันที่แก้ไข`,
    dateStateStart: 'updateStartDate',
    dateStateFinish: 'updateFinishDate',
    dateStateDisplay: 'updateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
