import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextInput from '../../../../../../components/Input/TextInput'
import Select from '../../../../../../components/Input/Select'
import { closePointDialog } from '../../../../../../redux/slices/manage/agentProfile'
import { eventOptions } from '../model'
import { schema } from './schema'
import { StyledCard } from './Styled'
import { handleSubmit } from './events'
import { convertNumberWithComma } from '../../../../../../utils/lib'

const PointForm = () => {
  const dispatch = useDispatch()
  const { uuid, totalPoint, pointForm } = useSelector(
    (state) => ({
      uuid: state.agentProfile.initialState.info.uuid,
      totalPoint: state.agentProfile.initialState.point,
      pointForm: state.agentProfile.pointDialog.pointForm,
    }),
    shallowEqual,
  )

  return (
    <Formik
      initialValues={pointForm}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => dispatch(handleSubmit(values, uuid))}
    >
      {({ errors, handleChange, values }) => (
        <Form>
          <StyledCard>
            <TextInput
              name="totalPoint"
              type="basic"
              labelText="จำนวนคะแนนสะสม"
              id="input-totalPoint"
              disabled={true}
              value={convertNumberWithComma(totalPoint)}
            />
            <Select
              required
              id="event"
              name="event"
              labelText="จัดการคะแนน"
              placeholder="กรุณาเลือกการจัดการคะแนน"
              type="basic"
              textError={_.get(errors, 'event', '')}
              isShowTextError={_.get(errors, 'event', '')}
              options={eventOptions}
              handleChange={handleChange}
              value={values.event}
            />
            <TextInput
              required
              name="point"
              type="basic"
              labelText="คะแนน"
              placeholder="ใส่จำนวนคะแนน"
              id="input-point"
              textError={_.get(errors, 'point', '')}
              isShowTextError={_.get(errors, 'point', '')}
              value={values.point}
              onChange={handleChange}
            />
            <TextInput
              multiline
              rows={3}
              name="remark"
              type="basic"
              labelText="หมายเหตุ"
              placeholder="รายละเอียดเพิ่มเติม"
              id="input-remark"
              textError={_.get(errors, 'remark', '')}
              isShowTextError={_.get(errors, 'remark', '')}
              value={values.remark}
              onChange={handleChange}
            />
          </StyledCard>
          <Box
            sx={{ display: 'flex', gap: 3, my: 3, justifyContent: 'center' }}
          >
            <Button
              variant="outlined"
              size="m"
              sx={{ minWidth: 144 }}
              onClick={() => dispatch(closePointDialog())}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              size="m"
              sx={{ minWidth: 144 }}
              type="submit"
            >
              ยืนยัน
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default PointForm
