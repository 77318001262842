import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { useSelector } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import {
  StyledFullWidth,
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
} from '../Styled'

import * as events from '../events'

export const EnhancedTableHead = ({ type }) => {
  const { table } = useSelector((state) => state.tableLeanerDetail)

  const headCells = _.get(table, `headCells[${type}]`, [])

  return (
    <TableHead>
      <TableRow key="head">
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={'normal'}
                sortDirection={false}
                headcellwidth={headCell.width}
                sx={{
                  textAlign: 'left',
                }}
              >
                <Typography
                  variant="tableHeader"
                  data-testid={`sort-cell-${valueId}`}
                  active={false}
                >
                  {headCell.label}
                </Typography>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTable = ({ type }) => {
  const { table } = useSelector((state) => state.tableLeanerDetail)

  const rows = _.get(table, `rows[${type}]`, [])
  const headCells = _.get(table, `headCells[${type}]`, [])

  return (
    <StyledFullWidth>
      <TableContainer>
        <StyledTable sx={{ border: 'none' }}>
          <EnhancedTableHead type={type} />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    role="checkbox"
                    tabIndex={-1}
                    color="primary"
                    type="hover"
                  >
                    {headCells &&
                      headCells.map((i, cellIndex) =>
                        events.checkConditionView(
                          headCells,
                          row,
                          rowIndex,
                          cellIndex
                        )
                      )}
                  </TableRow>
                )
              })}
            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </StyledFullWidth>
  )
}

export default EnhancedTable
