import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { Row, StyledFormCard } from '../Styled'
import { MODULE_STATUS } from '../../../../../constants/learningPoint'
import { handleChange } from '../events'

const Status = () => {
  const dispatch = useDispatch()
  const { isUploadLoading, status } = useSelector(
    (state) => ({
      isUploadLoading: state.settingPoint.isUploadLoading,
      status: state.settingPoint.body.status,
    }),
    shallowEqual,
  )
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">สถานะการเปิดใช้งาน</Typography>
      <Row sx={{ gap: 1 }}>
        <Switch
          name="status"
          checked={status === MODULE_STATUS.ACTIVE}
          onChange={(e) => {
            dispatch(
              handleChange(
                'status',
                e.target.checked
                  ? MODULE_STATUS.ACTIVE
                  : MODULE_STATUS.INACTIVE,
              ),
            )
            dispatch(handleChange('isActive', e.target.checked))
          }}
        />
        <Typography variant="boydy1">เปิดการได้รับคะแนน</Typography>
      </Row>
    </StyledFormCard>
  )
}

export default Status
