import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
// import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../../../../redux/slices/table'
import {
  StyledFilterButton,
} from '../../../../../../components/redux/Table/Styled'
import Search from './Search'
import DropdownButton from '../DropdownButton'

const CustomEnhancedTableToolbar = () => {
  const dispatch = useDispatch()
  const {
    filter: { filterTotal },
  } = useSelector((state) => state.table)
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ padding: `20px 20px 24px 16px !important` }}
    >
      <Box width="100%">
        <Typography variant="h6">จำนวนในคลังทรัพย์สินต่างๆ</Typography>
      </Box>

      <Box width="100%" display="flex" justifyContent="end" gap={3} alignItems="center">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Search />
        </Box>
        <StyledFilterButton
          data-testid="btn-filter"
          onClick={() => dispatch(setToggleFilterDrawer(true))}
        >
          ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
        </StyledFilterButton>
        <DropdownButton />
      </Box>
    </Box>
  )
}

export default CustomEnhancedTableToolbar