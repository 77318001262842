import _ from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import { defaultValue, questionTypeString } from '../../../constants/eTesting'
import { addKeyArray, resetValueAnswer, validateVideo } from './event'
import { initialState, editStatus } from './model'

const eTestingForm = createSlice({
  name: 'eTestingForm',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setErrorSchemaMessage: (state, { payload }) => {
      state.errorSchemaMessage = {
        field: payload.field,
        message: payload.message,
        questionNo: payload.questionNo,
        answerNo: payload.answerNo,
        subAnswerNo: payload.subAnswerNo,
      }
    },
    resetErrorSchemaMessage: (state) => {
      state.errorSchemaMessage = {}
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `${key}`, value)
    },
    setChangeTestingInfo: (state, { payload }) => {
      const { value, key } = payload
      _.set(state, `information.${key}`, value)
    },
    // Question
    setChangeQuestionByKey: (state, { payload }) => {
      const { keyQuestion, key, value } = payload
      const itemQuestion = state.questions.find(
        (item) => item.key === keyQuestion
      )
      _.set(itemQuestion, `${key}`, value)
    },
    setChangeFillInBlank: (state, { payload }) => {
      const { keyQuestion, subTitle, answers } = payload
      const itemQuestion = state.questions.find(
        (item) => item.key === keyQuestion
      )
      _.set(itemQuestion, 'answers', answers)
      _.set(itemQuestion, 'subTitle', subTitle)
    },
    setChangeQuestionType: (state, { payload }) => {
      const { keyQuestion, questionType, value } = payload
      const itemQuestion = state.questions.find(
        (item) => item.key === keyQuestion
      )
      itemQuestion.questionType = value
      itemQuestion.score = 0
      itemQuestion.subTitle = value?.includes(questionTypeString.TYPE_FILL)
        ? itemQuestion?.subTitle
        : ''
      itemQuestion.answers = resetValueAnswer({
        itemQuestion,
        questionType,
        value,
      })
    },
    setDeleteQuestion: (state, { payload }) => {
      const { keyQuestion } = payload
      let sequence = 0
      let result = []
      state.questions.forEach((item) => {
        if (item.key !== keyQuestion) {
          sequence++
          result.push({ ...item, sequence })
        }
      })

      state.questions = result
    },
    setAddQuestion: (state, { payload }) => {
      const objMaxKey = _.maxBy(state.questions, 'key')
      const maxKey = _.get(objMaxKey, 'key', null)
      state.questions.push({
        ...payload.value,
        key: maxKey === null ? 0 : maxKey + 1,
      })
    },
    setFetchDataQuestion: (state, { payload }) => {
      state.questions = payload
    },
    setSwapOption: (state) => {
      const { keyQuestion, indexSelect, position } = state.swapQuestion
      const listQuestion = Array.from(state.questions)
      const indexQuestion = _.findIndex(listQuestion, ['key', keyQuestion])

      let newIndexSelect = indexSelect

      if (position === 'BEFORE' && listQuestion.length === indexSelect + 1) {
        newIndexSelect = newIndexSelect - 1
      }

      if (position === 'AFTER' && listQuestion.length === newIndexSelect + 1)
        newIndexSelect = indexSelect

      const [removed] = listQuestion.splice(Number(indexQuestion), 1) //ลบของเดิมในตำแหน่งนั้น
      listQuestion.splice(Number(newIndexSelect), 0, removed) // เพิ่มของใหม่ในตำแหน่งที่ต้องการ

      const result = listQuestion.filter((item, index) => {
        return {
          ...item,
          id: `id-${index + 1}`,
          sequence: index + 1,
        }
      })

      state.questions = result
    },
    setDragDropAssessment: (state, { payload }) => {
      const { startIndex, endIndex } = payload
      const listQuestion = Array.from(state.questions)
      const [removed] = listQuestion.splice(startIndex, 1)
      listQuestion.splice(endIndex, 0, removed)

      const result = listQuestion.filter((item, index) => {
        return {
          ...item,
          id: `id-${index + 1}`,
          sequence: index + 1,
        }
      })

      state.questions = result
    },
    setVideo: (state, { payload }) => {
      const itemQuestion = state.questions.find(
        (item) => item.key === payload.keyQuestion
      )
      const urlVideo = _.get(itemQuestion, 'urlVideo', '')
      const checkValidate = validateVideo(urlVideo)
      state.errors = {
        video: checkValidate,
      }
      if (!checkValidate) {
        itemQuestion.mediaType = 'VIDEO'
        itemQuestion.url = urlVideo
      }
    },
    // Setting
    setChangeSetting: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `setting[${key}]`, value)
    },
    setFetchData: (state, { payload }) => {
      state.codeId = payload.codeId
      state.uuid = payload.uuid
      state.version = payload.version
      state.typeOfETesting = payload.type
      state.information = payload.information
      state.questions = addKeyArray([...payload.questions])
      state.setting = {
        ...state.setting,
        ...payload.setting,
        defaultDate: payload.setting.startDate,
      }
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
      }
      state.listAssignee = payload.assignee
      state.dateServer = payload.dateServer
      state.isDraft = payload.isDraft
      state.isClone = payload.isClone
      state.editStatus = payload.editStatus
      state.disableForm =
        (payload.editStatus === editStatus.DISABLE_ALL && !payload.isClone) ||
        payload.setting.isShare
          ? true
          : false
      state.disableSetting =
        (payload.editStatus !== editStatus.ENABLE_ALL && !payload.isClone) ||
        payload.setting.isShare
          ? true
          : false
      state.totalSubmit = payload.totalSubmit
      state.totalWaitingToCheck = payload.totalWaitingToCheck
      state.totalWaitingToAnnounce = payload.totalWaitingToAnnounce
      state.listExaminer = payload.examiners
    },
    setClearData: (state) => {
      state.typeOfETesting = initialState.typeOfETesting
      state.codeId = ''
      state.uuid = ''
      state.version = 0
      state.isClone = false
      state.defaultTap = initialState.defaultTap
      state.isLoading = false
      state.isDraft = false
      state.information = initialState.information
      state.questions = []
      state.setting = initialState.setting
      state.listAssignee = []
      state.errors = initialState.errors
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
      }
      state.onViewMode = false
      state.onGoing = false
    },
    setFetchAssignee: (state, { payload }) => {
      state.listAssignee = payload.assignee
      state.listExaminer = payload.examiners
      state.dateServer = payload.dateServer
      state.codeId = payload.codeId
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
      }
      state.disableForm = false
      state.disableSetting = false
    },
    setOnViewMode: (state, { payload }) => {
      state.onViewMode = payload
    },
    setETestingType: (state, { payload }) => {
      state.typeOfETesting =
        defaultValue.typeOfETesting[payload] ||
        defaultValue.typeOfETesting.onlineTest
    },
  },
})

export const draftQuestion = createSelector(
  (state) => state.eTestingForm,
  (state) => state.questions.map((item) => item.key)
)

export const questionFreeText = createSelector(
  (state) => state.eTestingForm,
  (state) => state.questions.find((item) => item.questionType === 'FREE_TEXT')
)

export const draftQuestionAssessment = createSelector(
  (state) => state.eTestingForm,
  (state) => state.questions.map((item) => item.key)
)

export const {
  startLoading,
  stopLoading,
  setErrorSchemaMessage,
  resetErrorSchemaMessage,
  setChange,
  setFetchData,
  setChangeTestingInfo,
  // Question
  setChangeQuestionByKey,
  setChangeFillInBlank,
  setChangeQuestionType,
  setDeleteQuestion,
  setAddQuestion,
  setFetchDataQuestion,
  setDragDropAssessment,
  // Setting
  setChangeSetting,
  setClearData,
  setFetchAssignee,
  setSwapOption,
  setVideo,
  setOnViewMode,
  setETestingType,
} = eTestingForm.actions

export default eTestingForm.reducer
