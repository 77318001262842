import React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column, Row } from '../../../../Styled'

export const StyledTableColumn = styled(Column)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(1),
  '& div > svg': { color: theme.palette?.text?.secondary },
  [theme.breakpoints.down('lg')]: { paddingTop: 0 },
}))

export const StyledEnrollButton = styled((props) => (
  <Button
    {...props}
    size="s"
    color="secondary"
    variant="contained"
    data-testid="btn-enroll"
  />
))(({ theme }) => ({
  width: 125,
  minWidth: 103,
  padding: 6,
  [theme.breakpoints.down('lg')]: { marginRight: theme.spacing(3) },
}))

export const StyledTableRow = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'isHaveSubject',
})(({ theme, isHaveSubject }) => ({
  justifyContent: 'space-between',
  '& > .MuiTypography-root': { width: isHaveSubject ? '25%' : '33%' },
  '& > div': { width: isHaveSubject ? '25%' : '33%' },
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(3)}` },
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 149px)',
    flexDirection: 'column',
    alignItems: 'start',
    '&#header': { display: 'none' },
    '& > .MuiTypography-root': { width: '100%' },
    '& > div': { width: '100%' },
  },
}))

export const StyledTableData = styled(Row)(({ theme }) => ({
  gap: theme.spacing(0.5),
  alignItems: 'start',
  svg: { marginTop: theme.spacing(0.5) },
}))

export const StyledLoading = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'isLoadMore',
})(({ theme, isLoadMore }) => ({
  margin: 'auto',
  marginTop: theme.spacing(2),
  width: 24,
  height: 24,
  [theme.breakpoints.down('sm')]: { left: isLoadMore ? 0 : 140 },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: { width: '100%' },
}))

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'roomWidth',
})(({ theme, roomWidth }) => ({
  maxWidth: roomWidth - 16,
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.only('md')]: { maxWidth: '100%' },
}))
