import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxLabelText = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))
