import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { crudConfig, crudConfig as initialCrudConfig } from '../crudConfig'
import { getBodyMutation } from '../mutation/get'
import _ from 'lodash'
import { fetchDetail } from '../../../../../components/CRUD/handler/fetchDetail'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'

export const initializePage = (uuid) => {
  initialConfig(initialCrudConfig)
  store.dispatch(setFieldValue({ key: 'isFetchTicketDetail', value: false }))
  if (uuid && !_.isUndefined(crudConfig))
    fetchDetail(uuid, getBodyMutation).then(() => {
      store.dispatch(setFieldValue({ key: 'isFetchTicketDetail', value: true }))
    })
}
