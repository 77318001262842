import * as yup from 'yup'
import _ from 'lodash'

export const validateSchema = (data) =>
  yup.object({
    information: yup.object().shape({
      description: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .max(100, 'ระบุได้ไม่เกิน 100 ตัวอักษร'),
      category: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .max(3, 'ระบุได้ไม่เกิน 3 ตัวอักษร'),
      remark: yup
        .string('กรุณากรอก')
        .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
    }),
    licenseConfig: yup.array().of(
      yup.object().shape({
        distributionChannelUuid: yup
          .string('กรุณาเลือก')
          .required('กรุณาเลือก'),
        groups: yup.array().of(
          yup.object().shape({
            subGroups: yup.array().of(
              yup.object().shape({
                contents: yup.array().of(
                  yup.object().shape({
                    type: yup
                      .string()
                      .test('course-value', 'กรุณาเลือก', function (n, value) {
                        const path = _.get(value, 'path', '')
                        const itemValue = _.get(value, 'parent', '')
                        const listDate = path.split('.').shift()
                        const key = listDate.split('licenseConfig').pop()
                        const distributionChannelUuid = _.get(
                          data,
                          `${key}.distributionChannelUuid`,
                          ''
                        )
                        const type = _.get(itemValue, 'type', '')
                        if (!distributionChannelUuid) return true
                        return !type ? false : true
                      }),
                    courseOrLicenseUuid: yup
                      .string()
                      .test('course-value', 'กรุณาเลือก', function (n, value) {
                        const itemValue = _.get(value, 'parent', '')
                        const type = _.get(itemValue, 'type', '')
                        const uuid = _.get(itemValue, 'courseOrLicenseUuid', '')
                        if (!type) return true
                        const checkEmpty = uuid.substring(0, 14)
                        return !uuid || checkEmpty === 'select-course-'
                          ? false
                          : true
                      }),
                  })
                ),
              })
            ),
          })
        ),
      })
    ),
  })
