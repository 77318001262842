import { Box, Typography, Button } from '@mui/material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import React from 'react'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { FilterBox } from '../FilterBox'

const index = () => {
  const { filterTotal, latestUpdated } = useSelector(
    (state) => ({
      filterTotal: state.crud.manageProfileDashboard?.filtersDrawer?.filterTotal,
      latestUpdated: state.crud.manageProfileDashboard?.latestUpdated,
    }),
    shallowEqual,
  )
  return (
    <Box display="flex" justifyContent="space-between" alignItems="start">
      <Box>
        <Typography variant="h4">Manage profile dashboard</Typography>
        <FilterBox />
        <Typography variant="body2">
          ข้อมูลเมื่อวันที่{' '}
          {latestUpdated ?? dayjs().format('DD/MM/YYYY 02:00')}
        </Typography>
      </Box>

      <Box>
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, py: 1, px: 2 }}
          variant="text"
          startIcon={<FilterListOutlinedIcon />}
          onClick={() => toggleFiltersDrawer(true)}
        >
          <Typography variant="text">
            ตัวกรอง {filterTotal ? `(${filterTotal})` : ''}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default index
