import { openDialog } from '../../../../redux/slices/dialog'
import { store } from '../../../../App'
import { crudConfig } from '../../crudConfig'

export const handleSuccessfulSubmission = (uuid) => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => {
        window.location.href = `${crudConfig.modulePath}/view/${uuid}`
      },
    }),
  )
}
