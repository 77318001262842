import styled from '@mui/system/styled'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Search from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'

export const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  boxShadow: '0px 12px 40px 0px #4976BA1A',
}))

export const HeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const Content = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const PointBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  background: '#7698CB1A',
  border: '1px solid #DBE4F1',
  borderRadius: theme.spacing(1.5),
  gap: '10px',
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const BoxSearch = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme?.palette?.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    maxHeight: 40,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: 40,
  width: 'calc(100% - 35px)',
  marginLeft: theme.spacing(1),
}))

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  width: 20,
  height: 20,
  cursor: 'pointer',
  position: 'absolute',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    right: 30,
  },
}))

export const StyledImage = styled('img')(({ theme }) => ({
  width: 42,
  height: 42,
  marginRight: theme.spacing(1.5),
  objectFit: 'cover',
  border: '2px solid #DBE4F1',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033',
}))
