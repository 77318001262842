import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { StyledColumn, StyledRow } from '../../Styled'

export const StyledRowBox = styled(StyledRow)(({ theme }) => ({
  marginTop: theme.spacing(3),
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'end',
    gap: 0,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
    alignItems: 'start',
  },
}))

export const StyledRowForm = styled(StyledRow)(({ theme }) => ({
  alignItems: 'start',
  width: 'calc(100% - 160px)',
  '& > .MuiBox-root': { width: 'calc(50% - 8px)' },
  [theme.breakpoints.down('lg')]: { width: '100%' },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > .MuiBox-root': { width: '100%' },
  },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.text?.mercury,
  borderStyle: 'dashed',
}))

export const StyledBoxContent = styled(StyledColumn)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: `${theme.spacing(3)} 0`,
  gap: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette?.other?.outlineBorder,
}))
