import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const getPathFormState = () => {
  const state = store.getState()
  return _.get(state, 'formData.body', {})
}

export const getStateValue = (key, defaultValue = '') => {
  const pathFormState = getPathFormState()
  return _.get(pathFormState, key, defaultValue)
}

export const handleChange = (key, value, isRoot) => (dispatch) => {
  const obj = { key, value }
  if (isRoot) dispatch(setFieldValue(obj))
  else dispatch(setFieldValue(obj))
}

export const handleChangeEvent = (e, isCheck) => (dispatch) => {
  const key = `formData.${_.get(e.target, 'name', '')}`
  const value = isCheck
    ? _.get(e.target, 'checked', false)
    : _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}
