import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import TextInput from '../../../../../components/Input/TextInput'
import { CourseWrapper, Row, StyledFormCard } from '../Styled'
import {
  handleChangeEvent,
  handleSelectType,
  handleSelectCourse,
} from '../events'
import { courseTypeOptions } from '../model'
import {
  addCourse,
  removeCourse,
} from '../../../../../redux/slices/learningPoint/settingPoint'

const PointInfo = () => {
  const dispatch = useDispatch()
  const { name, point, course, remark } = useSelector(
    (state) => state.settingPoint.body,
    shallowEqual,
  )
  const { isUploadLoading, courseOptions } = useSelector(
    (state) => state.settingPoint,
    shallowEqual,
  )
  const { errors } = useFormikContext()
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Typography variant="h6">คะแนนหลักสูตร</Typography>
      <Row sx={{ gap: 3 }}>
        <TextInput
          required
          name="name"
          type="basic"
          id="input-name"
          placeholder="กรุณาระบุชื่อคะแนน"
          labelText="ชื่อคะแนน"
          textError={_.get(errors, 'name', '')}
          isShowTextError={_.get(errors, 'name', '')}
          value={name}
          onChange={(e) => dispatch(handleChangeEvent(e))}
        />
        <TextInput
          required
          name="point"
          type="basic"
          id="input-point"
          placeholder="จำนวนคะแนน"
          labelText="จำนวนคะแนน"
          textError={_.get(errors, 'point', '')}
          isShowTextError={_.get(errors, 'point', '')}
          value={point}
          onChange={(e) => dispatch(handleChangeEvent(e))}
        />
      </Row>
      <CourseWrapper>
        <Typography variant="body1b">เลือกหลักสูตร</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {course.map((ele, ind) => {
            return (
              <Box key={ind} sx={{ display: 'flex', gap: 3 }} id="input-course">
                <Autocomplete
                  boxSx={{ width: 240, minWidth: 240 }}
                  sx={{ background: '#FFFFFF' }}
                  required
                  name="courseType"
                  type="basic"
                  id={`input-courseType-${ind}`}
                  options={courseTypeOptions}
                  handleChange={(e, element) =>
                    dispatch(handleSelectType(ind, element))
                  }
                  defaultValue={ele.courseType}
                  isHideSort
                  renderInput={(params) => (
                    <TextField
                      placeholder="E-Learning"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  labelText="รูปแบบหลักสูตร"
                  showTextError={_.get(
                    errors,
                    `course[${ind}].courseType.value`,
                    '',
                  )}
                  textError={_.get(
                    errors,
                    `course[${ind}].courseType.value`,
                    '',
                  )}
                />
                <Autocomplete
                  sx={{ background: '#FFFFFF' }}
                  required
                  name="courseName"
                  type="basic"
                  id={`input-courseName-${ind}`}
                  options={
                    ele?.courseType?.value
                      ? courseOptions[ele.courseType.value]
                      : []
                  }
                  handleChange={(e, element) =>
                    dispatch(handleSelectCourse(ind, element))
                  }
                  defaultValue={ele.uuid}
                  isHideSort
                  renderInput={(params) => (
                    <TextField
                      placeholder="เลือกหลักสูตรจากทั้งหมด"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  labelText="ชื่อหลักสูตร EN"
                  noOptionsText="กรุณาเลือกรูปแบบหลักสูตร"
                  getOptionDisabled={(option) =>
                    !!course.find((ele) => ele.uuid.value == option.value)
                  }
                  showTextError={_.get(errors, `course[${ind}].uuid.value`, '')}
                  textError={_.get(errors, `course[${ind}].uuid.value`, '')}
                />
                {ind > 0 && (
                  <IconButton
                    sx={{ height: 39, marginTop: '1.5rem' }}
                    onClick={() => dispatch(removeCourse(ind))}
                  >
                    <DeleteTwoToneIcon />
                  </IconButton>
                )}
              </Box>
            )
          })}
          <Row>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => dispatch(addCourse())}
            >
              เพิ่มหลักสูตร
            </Button>
          </Row>
        </Box>
      </CourseWrapper>
      <TextInput
        multiline
        rows={3}
        name="remark"
        type="basic"
        id="input-remark"
        placeholder="รายละเอียดเพิ่มเติม"
        labelText="หมายเหตุ"
        textError={_.get(errors, 'remark', '')}
        isShowTextError={_.get(errors, 'remark', '')}
        value={remark}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      />
    </StyledFormCard>
  )
}

export default PointInfo
