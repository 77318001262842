import { StyledColumn, StyledImg, StyledRow } from '../Styled'
import { Box, Button, List, TablePagination, Typography } from '@mui/material'
import { PlaceTwoTone } from '@mui/icons-material'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { ROOM_FUNCTION } from '../../../constants/MasterData/meetingRoom'
import { useHistory } from 'react-router-dom'
import { StatusLabel } from '../StatusLabel'
import {
  handleBookingThisRoom,
  handleChangePage,
  handleChangeRowsPerPage,
} from './event'
import { ROOM_AVAILABLE } from '../../../constants/roomManagement'
import BookingDrawer from '../BookingDrawer'
import { useLocalStorage } from '@rehooks/local-storage'
import { ROW_PAGE_PER_LIST } from '../../../constants/table'

const BoxLabel = ({ label }) => {
  return (
    <Box sx={{ background: '#EDF4FF', borderRadius: 60, px: 1, py: 0.5 }}>
      <Typography sx={{ typography: { sm: 'body2', xs: 'body3' } }}>
        {label}
      </Typography>
    </Box>
  )
}
const MeetingRoomItem = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    uuid,
    roomName,
    station,
    roomFunction,
    roomAvailable,
    roomTime,
    capacityClassroom,
    sqm,
    image,
    bookingButton,
    latestRoomAvailable,
    hasPermissionALL,
    hasPermissionPIC,
  } = props

  const imageKey = _.get(image, '[0].imageKey', '')
  const imagePath = !_.isEmpty(imageKey)
    ? `${window.__env__.REACT_APP_API_URL}/file${imageKey}`
    : `/image/default_room_image.svg`

  return (
    <StyledRow
      sx={{
        p: 1,
        pb: 2,
        gap: 3,
        borderBottom: '1px solid #DBE4F1',
      }}
    >
      <StyledImg
        sx={{
          boxShadow: '1px 0px 5px 0px rgba(81, 81, 81, 0.30)',
          minWidth: { sm: 180, xs: 110 },
          maxWidth: { sm: 180, xs: 110 },
          height: 110,
        }}
        src={imagePath}
      />
      <StyledColumn sx={{ width: '100%', rowGap: '7px' }}>
        <StyledRow
          sx={{
            justifyContent: 'space-between',
            flex: 'auto',
            alignItems: 'flex-start',
          }}
        >
          <StyledColumn
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ typography: { sm: 'h5', xs: 'h6' } }}>
              {roomName}
            </Typography>
            <StyledRow sx={{ gap: 0.5 }}>
              <PlaceTwoTone sx={{ width: 14, height: 14 }} color="primary" />
              <Typography variant="body2" color="primary">
                {station.station}
              </Typography>
            </StyledRow>
          </StyledColumn>
          <StyledRow
            sx={{
              gap: 1,
              justifyContent: 'flex-end',
              marginTop: { sm: 0, xs: '10px' },
            }}
          >
            <StatusLabel roomAvailable={roomAvailable} />
            <Typography
              color="text.gray"
              sx={{ typography: { sm: 'body2', xs: 'body3' } }}
            >
              {roomTime}
            </Typography>
          </StyledRow>
        </StyledRow>

        <StyledRow
          sx={{
            justifyContent: 'space-between',
            alignItems: { sm: 'center', xs: 'unset' },
            flexDirection: { sm: 'row', xs: 'column' },
            rowGap: '8px',
          }}
        >
          <StyledRow sx={{ gap: 1 }}>
            <BoxLabel label={ROOM_FUNCTION[roomFunction]} />
            {!_.isNull(capacityClassroom) && (
              <BoxLabel
                label={`${Number(capacityClassroom)?.toLocaleString()} คน`}
              />
            )}
            {!_.isNull(sqm) && (
              <BoxLabel label={`${Number(sqm)?.toLocaleString()} ตรม.`} />
            )}
          </StyledRow>
          <StyledRow sx={{ gap: 1.75, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              size="m"
              onClick={() => history.push(`/room-management/view/${uuid}`)}
            >
              รายละเอียด
            </Button>
            {(hasPermissionALL || (hasPermissionPIC && bookingButton)) && (
              <Button
                variant="contained"
                size="m"
                disabled={roomAvailable === ROOM_AVAILABLE.NOT_AVAILABLE}
                onClick={() => {
                  dispatch(handleBookingThisRoom(latestRoomAvailable, uuid))
                }}
              >
                จองห้องนี้
              </Button>
            )}
          </StyledRow>
        </StyledRow>
      </StyledColumn>
    </StyledRow>
  )
}

export const MeetingRoom = () => {
  const [user] = useLocalStorage('user')
  const permissionList = _.get(user, 'permission', [])
  const { meetingRoomList, rowsPerPage, allCount, tablePage, page } =
    useSelector(
      (state) => ({
        meetingRoomList: state.roomManagement.meetingRoomList,
        rowsPerPage: state.roomManagement.rowsPerPage,
        allCount: state.roomManagement.allCount,
        tablePage: state.roomManagement.tablePage,
        page: state.roomManagement.page,
      }),
      shallowEqual,
    )
  const dispatch = useDispatch()

  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  return (
    <>
      {meetingRoomList?.length > 0 ? (
        <List sx={{ gap: 1, display: 'flex', flexDirection: 'column', p: 0 }}>
          {_.map(meetingRoomList, (meetingRoom, idx) => {
            return (
              <MeetingRoomItem
                key={idx}
                {...meetingRoom}
                sqm={_.get(meetingRoom, 'sqm', '')}
                capacityClassroom={_.get(meetingRoom, 'capacityClassroom', '')}
                hasPermissionALL={hasPermissionALL}
                hasPermissionPIC={hasPermissionPIC}
              />
            )
          })}
          <TablePagination
            component="div"
            count={allCount}
            page={tablePage < 0 ? 1 : +tablePage}
            rowsPerPageOptions={ROW_PAGE_PER_LIST}
            onPageChange={(e, newPage) => {
              dispatch(handleChangePage(e, newPage))
            }}
            onRowsPerPageChange={(e) => dispatch(handleChangeRowsPerPage(e))}
            rowsPerPage={rowsPerPage.value}
            labelRowsPerPage={
              <Typography variant="caption" color="text.secondary">
                แถวต่อหน้า :{' '}
              </Typography>
            }
            labelDisplayedRows={() => {
              const realPage = tablePage <= 0 ? 1 : tablePage + 1
              let minRowsShowed =
                page < 0 ? 1 : rowsPerPage.value * tablePage + 1
              let maxRowsShowed =
                rowsPerPage.value * realPage > allCount
                  ? allCount
                  : rowsPerPage.value * realPage

              if (maxRowsShowed < 0) {
                maxRowsShowed = allCount
              }
              if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

              return (
                <Typography variant="caption">
                  {minRowsShowed}-{maxRowsShowed} of {allCount}
                </Typography>
              )
            }}
          />
        </List>
      ) : (
        <StyledColumn
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            minHeight: 500,
          }}
        >
          <img
            alt=""
            src={`/image/no-meeting-room.svg`}
            width={180}
            height={180}
          />
          <Typography
            sx={{ textAlign: 'center' }}
            variant="body1"
            color="text.lightGray"
          >
            ไม่มีห้องอบรมที่เปิดใช้งานตอนนี้
            <br />
            {'เลือก Master > ห้องประชุม เพื่อเปิดใช้งานหรือเพิ่มห้องอบรม'}
          </Typography>
        </StyledColumn>
      )}
      <BookingDrawer />
    </>
  )
}
