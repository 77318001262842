import { createSlice, createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { checkDisplayOneAndNow } from './events'
import { initialState } from './model'

const eTestingPreview = createSlice({
  name: 'eTestingPreview',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialState: (state, { payload }) => {
      state = Object.assign(state, payload)
    },
    setInitialPreview: (state, { payload }) => {
      state.uuid = _.get(payload, 'uuid', undefined)
      state.information = _.get(
        payload,
        'information',
        initialState.information,
      )
      state.setting = _.get(payload, 'setting', initialState.setting)
      state.testing = _.get(payload, 'testing', initialState.testing)
      state.section = initialState.section
      state.page = initialState.page
      state.isReview = initialState.isReview
      state.isSubmit = initialState.isSubmit
      state.isTimeUp = initialState.isTimeUp
    },
    setChangeStateByKey: (state, { payload }) => {
      const { keyQuestion, key, value } = payload
      const itemState = state.testing.find((item) => item.key === keyQuestion)
      _.set(itemState, `${key}`, value)
    },
    setAnswered: (state, { payload }) => {
      const { keyQuestion, value } = payload
      const itemState = state.testing.find((item) => item.key === keyQuestion)

      let isAnswered = !_.isEqual(value, '')

      const answer = _.get(itemState, 'answer', [])
      if (itemState.type === 'MULTIPLE') {
        isAnswered = answer.some((item) => item.isChecked)
      } else if (itemState.type === 'FILL_IN_BLANK_CHOICE') {
        const choices = _.get(itemState, 'choices', [])
        isAnswered =
          choices.length === answer.filter((ans) => !_.isNil(ans)).length
      }

      _.set(itemState, `isAnswered`, isAnswered)
    },
    setSubmit: (state) => {
      if (checkDisplayOneAndNow(state.setting)) state.isButtonLoading = true

      state.isSubmit = true
    },
    // Display question one per page
    setNextQuestion: (state) => {
      if (checkDisplayOneAndNow(state.setting) && !state.isReview) {
        state.isSubmit = false
        state.isButtonLoading = false
      }

      state.page = state.page + 1
    },
    setPreviousQuestion: (state) => {
      state.page = state.page - 1
    },
    setReviewAnswer: (state) => {
      state.isReview = true
      state.section = 'TESTING'
      state.page = 1
    },
    setSectionTesting: (state) => {
      state.section = 'TESTING'
    },
    setSectionResult: (state) => {
      state.section = 'RESULT'
      state.isButtonLoading = false
    },
    setTimeUp: (state) => {
      state.isTimeUp = true
    },
    resetTimeUp: (state) => {
      state.isTimeUp = false
    },
    setMobileProps: (state, { payload }) => {
      state.mobileProps.isMobile =
        payload.isMobile || state.mobileProps.isMobile
      state.mobileProps.selectedItem = payload.selectedItem || null
    },
  },
})

export const draftTesting = createSelector(
  (state) => state.eTestingPreview,
  (state) => state.testing.map((item) => item.key),
)

export const {
  startLoading,
  stopLoading,
  setInitialState,
  setInitialPreview,
  setChangeStateByKey,
  setAnswered,
  setSubmit,
  setNextQuestion,
  setPreviousQuestion,
  setReviewAnswer,
  setSectionTesting,
  setSectionResult,
  setTimeUp,
  resetTimeUp,
  setMobileProps,
} = eTestingPreview.actions

export default eTestingPreview.reducer
