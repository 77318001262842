import { startLoading, stopLoading } from '../../../../redux/slices/table'
import { downloadFile } from '../../../../services/util'
import { store } from '../../../../App'
import { budgetManagementTemplateDownload } from '../../../../utils/apiPath'

export const onDownloadTemplate = async () => {
  store.dispatch(startLoading())
  const body = {}

  await store.dispatch(
    downloadFile({
      url: budgetManagementTemplateDownload,
      body: body,
      fileName: `Budget Management Template.xlsx`,
    }),
  )
  store.dispatch(stopLoading())
}
