import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  LoadingPage,
  StyledContainer,
  StyledContent,
} from './Module/Lib/CSS/Styled'
import HeaderExamination from './Module/Header'
import TableExamination from './Module/Table'

const ExaminationList = () => {
  const { table } = useSelector(
    (state) => ({
      table: state.table,
    }),
    shallowEqual,
  )
  const { isLoading: isLoading } = table.table

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <HeaderExamination />
        <TableExamination />
      </StyledContent>
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default ExaminationList
