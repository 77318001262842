import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { getMeetingRoomById } from '../../../../services/masterData/meetingRoom'

const masterDataMeetingRoomForm = createSlice({
  name: 'masterDataMeetingRoomForm',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingButton: (state) => {
      state.isLoadingButton = true
    },
    stopLoadingButton: (state) => {
      state.isLoadingButton = false
    },
    setOpenDialogUpload: (state, { payload }) => {
      state.openDialogUpload = payload
    },
    setDisableSaveButton: (state, { payload }) => {
      state.disableSaveButton = payload
    },
    setFetchData: (state, { payload }) => {
      state.information = payload.information
    },
    // Information
    setChangeInformation: (state, { payload }) => {
      const { key, value } = payload
      state.information[key] = value
    },
    setEquipmentSelected: (state, { payload }) => {
      state.equipmentSelected = payload
    },
    setImagesErrorList: (state, { payload }) => {
      state.imagesErrorList = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload.key] = payload.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeetingRoomById.fulfilled, (state, { payload }) => {
        state.result = {
          ...payload,
        }
      })
      .addCase(getMeetingRoomById.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingButton,
  stopLoadingButton,
  setOpenDialogUpload,
  setDisableSaveButton,
  setFetchData,
  // Information
  setChangeInformation,
  setEquipmentSelected,
  setImagesErrorList,
  setReduxValue,
} = masterDataMeetingRoomForm.actions

export default masterDataMeetingRoomForm.reducer
