import { getStationFilterDefault } from '../../../services/masterData/meetingRoom'
import { store } from '../../../App'
import _ from 'lodash'

export const fetchStationList = async () => {
  const response = await store.dispatch(getStationFilterDefault())

  const stationList = _.map(_.get(response, 'payload', []), (item) => ({
    label: item.station,
    name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
    value: item.uuid,
  }))

  const stationObj = _.reduce(
    stationList,
    (result, item) => {
      result[item.name] = false
      return result
    },
    {},
  )
  return { station: stationObj, stationOptions: stationList }
}
