import { store } from '../../../App'
import { openDialog } from '../../../redux/slices/dialog'
import { handleProcessDelete } from './handleProcessDelete'
import _ from 'lodash'

export const handleDeleteClick = async (idx) => {
  const { expenseList } = store.getState().crud.formData
  const amountList = _.get(expenseList, `[${idx}].amountList`, [])
  const uuidExpList = _.map(amountList, (amount) => amount.uuid)

  store.dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบรายการนี้ใช่หรือไม่',
      handleConfirm: () => {
        handleProcessDelete(uuidExpList).then()
      },
      isCloseDialog: false,
    }),
  )
}
