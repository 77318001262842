import _ from 'lodash'
import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { BoxLabel } from '.'
import { BoxFlex, SubTitle } from './Styled'
import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import { setChangeInformation } from '../../../../../redux/slices/manage/agentProfile'

const CsInfo = ({ editable, status }) => {
  const dispatch = useDispatch()
  const information = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual,
  )
  return (
    <>
      {status === 'Active' ? (
        <>
          <BoxFlex>
            <BoxLabel
              labelLeft="ประเภท Agent"
              labelRight={_.get(information, 'work.agentType', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Level"
              labelRight={_.get(information, 'work.level', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Position"
              labelRight={_.get(information, 'work.position', '-')}
              isHalf
            />
            {editable ? (
              <TextFieldInput
                id="division"
                name="division"
                labelText="แผนก/ฝ่าย"
                value={_.get(information, 'division', '-')}
                onChange={(e) => {
                  dispatch(
                    setChangeInformation({
                      key: 'division',
                      value: _.get(e, 'target.value', '')?.slice(0, 255),
                    }),
                  )
                }}
                boxSx={{ marginLeft: '20px' }}
              />
            ) : (
              <BoxLabel
                labelLeft="แผนก/ฝ่าย"
                labelRight={_.get(information, 'work.division', '-')}
                isHalf
              />
            )}
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="วันเริ่มงาน"
              labelRight={_.get(information, 'work.workStart', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="อายุงาน"
              labelRight={_.get(information, 'work.workDistance', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="License No."
              labelRight={_.get(information, 'work.licenseNo', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Reg. Effective Date"
              labelRight={_.get(information, 'work.regEffectiveDate', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Reg. Renewal Date"
              labelRight={_.get(information, 'work.regRenewalDate', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="สถานะการทำงาน"
              labelRight={status}
              status={_.get(information, 'work.status', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Broker Number"
              labelRight={_.get(information, 'work.brokerNumber', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="ชื่อบริษัทโบรกเกอร์"
              labelRight={_.get(information, 'work.broker', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="RAM Code"
              labelRight={_.get(information, 'work.RAMCode', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="GA Code"
              labelRight={_.get(information, 'work.gACode', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Dummy Agent Flag"
              labelRight={_.get(information, 'work.dummyAgentFlag', '-')}
              isHalf
            />
            <BoxLabel labelLeft="" labelRight="" isHalf />
          </BoxFlex>
        </>
      ) : (
        <>
          <BoxFlex>
            <BoxLabel
              labelLeft="ประเภท Agent"
              labelRight={_.get(information, 'work.agentType', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Level"
              labelRight={_.get(information, 'work.level', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Position"
              labelRight={_.get(information, 'work.position', '-')}
              isHalf
            />
            {editable ? (
              <TextFieldInput
                id="division"
                name="division"
                labelText="แผนก/ฝ่าย"
                value={_.get(information, 'division', '-')}
                onChange={(e) => {
                  dispatch(
                    setChangeInformation({
                      key: 'division',
                      value: _.get(e, 'target.value', '')?.slice(0, 255),
                    }),
                  )
                }}
                boxSx={{ marginLeft: '20px' }}
              />
            ) : (
              <BoxLabel
                labelLeft="แผนก/ฝ่าย"
                labelRight={_.get(information, 'work.division', '-')}
                isHalf
              />
            )}
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="วันเริ่มงาน"
              labelRight={_.get(information, 'work.workStart', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="อายุงาน"
              labelRight={_.get(information, 'work.workDistance', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="License No."
              labelRight={_.get(information, 'work.licenseNo', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Reg. Effective Date"
              labelRight={_.get(information, 'work.regEffectiveDate', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Reg. Renewal Date"
              labelRight={_.get(information, 'work.regRenewalDate', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="สถานะการทำงาน"
              labelRight={status}
              status={_.get(information, 'work.status', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Termination Code"
              labelRight={_.get(information, 'work.terminationCode', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Termination Date"
              labelRight={_.get(information, 'work.terminationDate', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="Termination Description"
              labelRight={_.get(
                information,
                'work.terminationDescription',
                '-',
              )}
              isHalf
            />
            <BoxLabel
              labelLeft="Broker Number"
              labelRight={_.get(information, 'work.brokerNumber', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="ชื่อบริษัทโบรกเกอร์"
              labelRight={_.get(information, 'work.agentUnitName', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="RAM Code"
              labelRight={_.get(information, 'work.RAMCode', '-')}
              isHalf
            />
          </BoxFlex>
          <BoxFlex>
            <BoxLabel
              labelLeft="GA Code"
              labelRight={_.get(information, 'work.gACode', '-')}
              isHalf
            />
            <BoxLabel
              labelLeft="Dummy Agent Flag"
              labelRight={_.get(information, 'work.dummyAgentFlag', '-')}
              isHalf
            />
          </BoxFlex>
        </>
      )}

      <BoxFlex>
        <SubTitle variant="body1b">Agent Unit</SubTitle>
      </BoxFlex>
      <BoxFlex>
        <BoxLabel
          labelLeft="Agency Code"
          labelRight={_.get(information, 'work.agencyCode', '-')}
          isHalf
        />

        <BoxLabel
          labelLeft="Agency Name"
          labelRight={_.get(information, 'work.agencyName', '-')}
          isHalf
        />
      </BoxFlex>
      <BoxFlex>
        <BoxLabel
          labelLeft="Assistant Branch Code"
          labelRight={_.get(information, 'work.assistantBranchCode', '-')}
          isHalf
        />

        <BoxLabel
          labelLeft="Territory Code"
          labelRight={_.get(information, 'work.territoryCode', '-')}
          isHalf
        />
      </BoxFlex>
    </>
  )
}

export default CsInfo
