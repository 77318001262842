import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledEventContainer = styled(Box)(({ theme, status }) => ({
  minWidth: 300,
  minHeight: status === 'ENDCLASS' || status === 'CLOSED' ? 237 : 400,
  [theme.breakpoints.down('lg')]: {
    minWidth: status === 'ENDCLASS' || status === 'CLOSED' ? 'calc(100% - 316px)' : '100%',
    height: status === 'ENDCLASS' || status === 'CLOSED' ? 172 : 200,
    minHeight: 'unset',
  },
  [theme.breakpoints.down('sm')]: { minHeight: 200 },
}))

export const StyledEventColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  height: 332,
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledEventItem = styled(Box)(({ theme, active }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(0.5),
  gap: theme.spacing(1.5),
  background: active
    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4976BA;'
    : 'transparent',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  svg: {
    width: 20,
    height: 20,
    color: theme.palette?.blue?.oceanBlue,
  },
  '& #label': {
    maxWidth: 204,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const StyledTypography = styled(Typography)(() => ({
  maxWidth: 217,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}))
