import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import { transferTicketStatus } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { fetchDataList } from '../../../../../components/CRUD/handler/fetchDataList'
import { mutateFilterPropToBody } from '../../../../../modules/Inventory/Transfer/Listing/mutation/filterPropToBody'
import { TicketStatus, TicketStatusText } from '../../../../../constants/stock'

export const onCancel = async (row, status) => {
  confirmDialog(row.uuid, status)
}

function confirmDialog(uuid, status) {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  if (TicketStatus.CANCELED === status) {
    DIALOG_TITLE = `ยืนยันการยกเลิก`
    agreeText = 'ยืนยัน'
    disagreeText = 'ปฏิเสธ'
  } else if (TicketStatus.REJECTED === status) {
    DIALOG_TITLE = `ยืนยันการปฏิเสธ`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
  }
  store.dispatch(
    openDialog({
      type: 'delete',
      title: DIALOG_TITLE,
      message: `คุณต้องการ${TicketStatusText?.[status]}รายการนี้ใช่หรือไม่`,
      agreeText: agreeText,
      disagreeText: disagreeText,
      handleConfirm: () => {
        changeTransferStatus(uuid, status)
      },
      isCloseDialog: false,
    }),
  )
}

const changeTransferStatus = async (uuid, status) => {
  const body = {
    uuid,
    status,
  }
  store.dispatch(loadingDialog())
  await callAxios
    .put(transferTicketStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            fetchDataList(mutateFilterPropToBody).then()
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}
