import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledCircularBox = styled(Box)(() => ({
  background: 'rgba(255, 255, 255, 0.4)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  left: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
