import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Add from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import Contents from '../Contents'

import * as Styled from '../Styled'
import * as events from '../events'

const Index = ({ props }) => {
  const {
    indexConfig,
    listGroups,
    distributionChannelUuid,
    listCourse,
    listCorseELarning,
    listType,
    licenseList,
    defaultCourse,
  } = props
  const dispatch = useDispatch()

  const { errors, setErrors } = useFormikContext()

  return (
    <>
      {listGroups.map((itemGroup, indexGroup) => {
        const listSubGroup = _.get(itemGroup, 'subGroups', [])
        const section = `${indexConfig + 1}.${indexGroup + 1}`
        return (
          <>
            <Styled.BoxGroups key={indexGroup}>
              <Box sx={{ m: 2 }}>
                <Box
                  key={indexGroup}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body1b">
                    {!indexGroup ? section : `หรือ ${section}`}
                  </Typography>
                  <IconButton
                    data-testid={`btn-delete-${indexGroup}`}
                    disabled={listGroups.length == 1}
                    onClick={() =>
                      dispatch(
                        events.handleDeleteGroup({
                          indexConfig,
                          indexGroup,
                          errors,
                          setErrors,
                        }),
                      )
                    }
                  >
                    <DeleteTwoTone />
                  </IconButton>
                </Box>
                {listSubGroup.map((itemSubGroup, indexSubGroup) => {
                  const listContent = _.get(itemSubGroup, 'contents', [])
                  const propsSubGroup = {
                    indexConfig,
                    indexGroup,
                    indexSubGroup,
                  }

                  return (
                    <>
                      <Box key={indexSubGroup}>
                        {indexSubGroup > 0 && (
                          <Typography
                            variant="h5"
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            และ
                          </Typography>
                        )}
                        <Contents
                          props={{
                            listContent,
                            listCourse,
                            listCorseELarning,
                            propsSubGroup,
                            distributionChannelUuid,
                            listType,
                            listSubGroup,
                            licenseList,
                            defaultCourse,
                          }}
                        />
                      </Box>
                      {indexSubGroup == listSubGroup.length - 1 && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            disabled={!distributionChannelUuid}
                            data-testid={`btn-and-${indexConfig}`}
                            id={`btn-and-${indexConfig}`}
                            name={`btn-and-${indexConfig}`}
                            variant="outlined"
                            size="m"
                            startIcon={<Add />}
                            onClick={() =>
                              dispatch(events.handleAddSubGroup(propsSubGroup))
                            }
                          >
                            <Typography variant="button">และ</Typography>
                          </Button>
                        </Box>
                      )}
                    </>
                  )
                })}
              </Box>
            </Styled.BoxGroups>
          </>
        )
      })}
    </>
  )
}

export default Index
