import React, { useState, useEffect, useContext } from 'react'
import Form from './Form'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { validationSchema } from './FormSchema'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import _ from 'lodash'
import { productType } from '../../utils/apiPath'
import { callAPI } from '../../utils/lib'
import Breadcrumbs from '../../components/Breadcrumbs'
import { DialogContext } from '../../context/dialog-context'
import { onView } from './ProductTypeList'
export const breadcrumbList = (titleText) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ทักษะที่ได้จากหลักสูตร',
      link: '/master-data/product-type',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({
    open: false,
  })
}

const ProductType = ({ isEditForm }) => {
  const { id: uuid } = useParams()
  const titleText = isEditForm
    ? 'แก้ไขทักษะที่ได้จากหลักสูตร'
    : 'เพิ่มทักษะที่ได้จากหลักสูตร'
  const history = useHistory()
  useEffect(() => {
    if (isEditForm) {
      setIsPageLoading(true)
      fetchProductTypeById({
        uuid,
        setInitialValues,
        setDisplayTitle,
        formik,
        setIsPageLoading,
      })
    }
  }, [isEditForm])
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [displayTitle, setDisplayTitle] = useState('')
  const [initialValues, setInitialValues] = useState({
    id: '',
    productType: '',
    status: true,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitProductType({
            uuid,
            values,
            isEditForm,
            setDialog,
            setIsPageLoading,
            history,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs
          sx={{ mx: 3, mb: 3 }}
          menuList={breadcrumbList(titleText)}
        />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={() => onCancelClickDialog(setDialog)}
          onSubmit={submitProductType}
          formik={formik}
          isLoading={isPageLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default ProductType

export const submitProductType = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  setIsPageLoading,
  history,
}) => {
  setDialog({
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'
  const body = isEditForm
    ? {
        ...values,
        uuid: uuid,
        productType: values.productType,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }
    : {
        productType: values.productType,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }

  callAPI({
    method: methodType,
    url: productType,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res?.uuid
      setDialog({
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        open: true,
        isLoading: false,
        variant: 'success',
        onConfirmClick: () => {
          setDialog({ variant: 'success', open: false, isLoading: false })
          onView({ uuid: uuidLink }, history)
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const fetchProductTypeById = async ({
  uuid,
  setInitialValues,
  setDisplayTitle,
  formik,
  setIsPageLoading,
}) => {
  callAPI({
    method: 'get',
    url: productType + `/${uuid}`,
    onSuccess: (res) => {
      const { status, id } = res

      const valueProductType = _.get(res, 'productType', '')

      setInitialValues({
        ...res,
        id: id,
        productType: valueProductType,
        status: status.toString().toUpperCase() === 'ACTIVE' || false,
      })
      setDisplayTitle(valueProductType)
      formik.setFieldValue('id', id)
      formik.setFieldValue('productType', valueProductType)
      formik.setFieldValue(
        'status',
        status.toString().toUpperCase() === 'ACTIVE' || false
      )
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}
