import { createSlice } from '@reduxjs/toolkit'
import { getDetail } from '../../../../services/eLearning/module/detail'
import { initialState } from './model'

const eLearningModuleDetail = createSlice({
  name: 'eLearningModuleDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        state.result = payload.data
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading } = eLearningModuleDetail.actions

export default eLearningModuleDetail.reducer
