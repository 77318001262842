import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
}))

export const StyledBoxGraph = styled(Box)(({ size }) => ({
  width: size ? size : '90px',
  height: size ? size : '90px',
  position: 'relative',
}))

export const StyledBoxPercentage = styled(Box)(() => ({
  position: 'absolute',
  margin: 'auto',
  left: 0,
  right: 0,
  top: '35%',
  textAlign: 'center',
}))

export const StyledLegendBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}))

export const StyledTextContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100px',
}))

export const StyledCircle = styled(Box)(({ color }) => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  background: color,
}))
