import { eLearningModuleCreateMultiPart } from '../../../utils/apiPath'
import callAxios from '../../../utils/baseService'

export const postModuleCreateMultiPart = async (body) => {
  try {
    return await callAxios.post(eLearningModuleCreateMultiPart, body)
  } catch (err) {
    throw new Error(err)
  }
}
