import _ from "lodash"

export const StyledPercentInput = (criteriaPercent) => {
  return {
    '&:after': {
      content: '"%"',
      opacity: _.isEmpty(criteriaPercent) ? 0 : 1,
      position: 'absolute',
      top: '50%',
      left: 8 + criteriaPercent.length * 9,
      transform: 'translateY(-50%)',
    },
  }
}
