import { store } from '../../../App'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { reorder } from './reorder'

export const onDragEnd = (event) => {
  if (!event.destination) return
  if (event.destination.index === event.source.index) return
  const results = reorder({
    startIndex: event.source.index,
    endIndex: event.destination.index,
  })
  store.dispatch(setReduxValue({ key: 'displayColumns', value: results }))
}
