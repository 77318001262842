import _ from 'lodash'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassDetail'
import { headCellsCheckHistory } from '../../../../../constants/manageClass'
import { setInitialTable } from '../../../../../redux/slices/table'
import {
  getManageClassCheckHistory,
  getStandaloneAnnouncementHistory,
} from '../../../../../services/manageClass/check'
import { StyledTable } from './Styled'

export const fetchManageClassCheckHistory = (props) => async (dispatch) => {
  const { table, idParams, isStandalone } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const body = { limit: Number(table.limit), page: realPage }

  dispatch(startLoading())
  let result = null
  if (isStandalone) {
    const { eTestingVersionUuid } = idParams
    const payload = {
      eTestingVersionUuid,
      body,
    }
    result = await dispatch(getStandaloneAnnouncementHistory(payload))
  } else {
    const { id, courseId } = idParams
    const payload = {
      trainingPlanUuid: id,
      courseTestAndEvaUuid: courseId,
      body,
    }
    result = await dispatch(getManageClassCheckHistory(payload))
  }
  const rows = _.get(result, 'payload.data.result', [])
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows,
      allCount: _.get(result, 'payload.data.totalCount', 0),
      headCells: headCellsCheckHistory,
      customStyle: StyledTable,
    })
  )
  dispatch(stopLoading())
}
