import _ from 'lodash'

export const handleValidateForm = (state) => {
  const errors = {}
  state.approveList.forEach((item, index) => {
    const status = item.status
    let errorObj = undefined
    switch (status) {
      case 'APPROVE':
        if (item.staffs.length === 0) {
          errorObj = { staffs: 'กรุณาเลือกวิทยากรในช่องนี้' }
        }
        break

      case 'REJECT':
        if (item.remark === '') {
          errorObj = { remark: 'กรุณากรอกเหตุผล' }
        }
        if (item.remark.length > 3000) {
          errorObj = { remark: 'ระบุได้ไม่เกิน 3000 ตัวอักษร' }
        }
        break

      default:
        break
    }
    errors[index] = errorObj
    if (_.isUndefined(errorObj)) delete errors[index]
  })

  return errors
}
