import { store } from '../../../../../App'
import {
  setCustomToolbar,
  setCustomToolbarSx,
  setHandleChange,
} from '../../../../../redux/slices/table'
import CustomEnhancedTableToolbar from '../components/overviewTab/components/CustomEnhanceTableToolbar'

export const handleUpdateTable = () => {
  store.dispatch(
    setHandleChange({
      key: 'customContainerStyle',
      value: { height: '250px', width: '100%' },
    }),
  )
  store.dispatch(setCustomToolbar(CustomEnhancedTableToolbar()))
  store.dispatch(setCustomToolbarSx({ width: '100%' }))
}
