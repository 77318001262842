import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import useTheme from '@mui/system/useTheme'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import Breadcrumbs from '../../components/Breadcrumbs'
import useFetchList from '../../hooks/useFetchList'
import { callAPI, checkUpload } from '../../utils/lib'
import {
  uploadFileProfile,
  staffProfile,
  filterArea,
  filterLevel,
  filterCostCenter,
  filterExperienced,
  filterInstitute,
  filterStaffProfile,
  filterProductType,
  listJobTitle,
  examinationQualification,
} from '../../utils/apiPath'

import Form from './Form'
import { validationNonAcademySchema, validationSchema } from './FormSchema'
import { defaultStaffProfileform } from './DefaultValue'

export const bodyLevel = {
  level: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyStaff = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  agentCode: '',
  firstNameTH: '',
  lastNameTH: '',
  jobTitle: '',
  department: '',
  station: '',
  reportTo: '',
  position: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyArea = {
  limit: -1,
  order: 'ASC',
  page: 1,
}
export const bodyCostCenter = {
  costCenter: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyExperienced = {
  experienced: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyInstitute = {
  institute: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}
export const bodyProductType = {
  productType: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyJobTitle = {
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'sequence',
}

export const submitStaffProfile = async ({
  values,
  isEditForm,
  uuid,
  setDialog,
  setIsLoading,
  formik = null,
  IsNonAcademy = null,
}) => {
  setIsLoading(true)
  setDialog({
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    open: true,
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'
  const area = _.get(values, 'area', [])
  values = {
    ...values,
    area: area.map((item, index) => {
      return { uuid: item.uuid, default: index === values.defaultAreaIndex }
    }),
    reportTo: values.reportTo.value,
    birthOfDate: values.birthOfDate === '-' || null ? '' : values.birthOfDate,
  }

  delete values.reportToObj
  delete values.areaUuid
  delete values.department

  const body = isEditForm
    ? {
        ...values,
        id: values.id,
      }
    : { ...values }

  if (IsNonAcademy) {
    body['type'] = 'NON_ACADEMY'
    if (_.isEmpty(body['level'])) {
      body['level'] = null
    }

    if (_.isEmpty(body['reportTo'])) {
      body['reportTo'] = null
    }
  } else {
    body['type'] = 'STAFF'
  }
  callAPI({
    method: methodType,
    url: staffProfile,
    body: body,
    onSuccess: (data) => {
      const uuidLink = isEditForm ? uuid : data.uuid
      setIsLoading(false)
      setDialog({
        open: true,
        title: 'สำเร็จ',
        variant: 'success',
        onConfirmClick: () => {
          const url = !IsNonAcademy ? 'staff-profile' : 'non-academy-profile'

          window.location.href = `/manage/${url}/` + uuidLink
        },
      })
    },
    onError: (er) => {
      const constraintsKey = _.get(er, 'response.data.constraints.key', '')
      if (constraintsKey === 'IsStaffIdCardNoDuplicate' && !_.isEmpty(formik)) {
        formik.setErrors({ idCardNo: 'เลขบัตรประจำตัวประชาชนซ้ำกับในระบบ' })
      }
      setIsLoading(false)
      setDialog({
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: false,
        isLoading: true,
      })
    },
  })
}

export const onFetchStaffByIdSuccess = async ({
  data,
  setDisplayTitle,
  formik,
  uuid,
  setInitialValues,
}) => {
  let groupList = []
  if (!_.isEmpty(data.permissionGroup) && data.permissionGroup.length > 0) {
    data.permissionGroup.map((item) => {
      groupList.push(item.groupName)
    })
  }
  setDisplayTitle(`${data.firstNameTH} ${data.lastNameTH}`)

  const defaultAreaIndex = data.area.findIndex((item) => item.default === true)
  const reportToObj = {
    label:
      _.get(data, 'reportTo.firstNameTH', '') +
      ' ' +
      _.get(data, 'reportTo.lastNameTH', ''),
    value: _.get(data, 'reportTo.uuid', ''),
    image: _.get(data, 'reportTo.image', ''),
  }
  const idCardNo = _.get(data, 'idCardNo', '')
  const tel = _.isNull(_.get(data, 'tel', '')) ? '' : _.get(data, 'tel', '')
  formik.setValues({
    ...data,
    level: _.get(data, 'level.uuid', ''),
    costCenter: _.get(data, 'costCenter.uuid', ''),
    areaUuid: _.get(data.area[defaultAreaIndex], 'uuid', ''),
    department: _.get(data.area[defaultAreaIndex], 'department.uuid', ''),
    defaultAreaIndex: defaultAreaIndex,
    reportTo: reportToObj,
    image: data.image !== null ? data.image : '',
    imageFull: data.imageFull !== null ? data.imageFull : '',
    birthOfDate: _.get(data, 'birthOfDate', null),
    idCardNo: idCardNo ?? '',
    tel: tel?.replace(/-/g, ''),
  })

  if (!_.isEmpty(_.get(data, 'user.idCardNo', null))) {
    formik.setFieldValue('idCardNo', _.get(data, 'user.idCardNo', ''))
  }

  if (_.isEmpty(data.educational)) {
    formik.setFieldValue('educational', [
      {
        institution: '',
        between: '',
        education: '',
        branch: '',
        GPA: '',
      },
    ])
  }
  if (_.isEmpty(data.workHistory)) {
    formik.setFieldValue('workHistory', [
      {
        organization: '',
        position: '',
        business: '',
        between: '',
      },
    ])
  }
  if (_.isEmpty(data.experienced)) {
    formik.setFieldValue('experienced', [
      {
        experiencedUuid: '',
        amount: '',
        unit: '',
      },
    ])
  }
  if (_.isEmpty(data.training)) {
    formik.setFieldValue('training', [
      {
        institution: '',
        year: '',
        course: [{}],
      },
    ])
  }
  if (_.isEmpty(data.lecturer)) {
    formik.setFieldValue('lecturer', [
      {
        institution: '',
        year: '',
        course: [{}],
      },
    ])
  }
  if (_.isEmpty(data.institute)) {
    formik.setFieldValue('institute', [
      {
        profession: '',
        qualification: '',
        license: '',
      },
    ])
  }
  formik.setFieldValue('uuid', uuid)
  setInitialValues({
    ...data,
    joinDate: _.get(data, 'joinDate', ''),
    status: _.get(data, 'status', ''),
    level: _.get(data, 'level.uuid', ''),
    jobTitles: _.get(data, 'jobTitles', {}),
    group: groupList.join(', '),
    area: _.get(data, 'area', []),
    reportTo: reportToObj,
    acquiredSkill: _.map(data?.acquiredSkill ?? [], (item) => {
      return {
        title: item.productType,
        label: item?.productType,
        value: item.uuid,
      }
    }),
    productType: _.map(data?.productType ?? []).map((item) => {
      return {
        title: item.productType,
        label: item?.productType,
        value: item.uuid,
      }
    }),
  })
}

export const onUploadSuccess = async ({ res, e, formik }) => {
  const key = res?.key
  const imageType = e.target.id === 'btn-add-image-full' ? 'imageFull' : 'image'
  formik.setFieldValue(imageType, key)
}

export const handleUploadFile = async ({ e, formik, name, dispatch }) => {
  const checkFile = dispatch(
    checkUpload({
      events: e,
      type: 'image',
      id: `btn-add-image-${name}`,
    }),
  )
  if (checkFile) return

  const data = new FormData()
  data.append('file', e.target.files[0])
  await callAPI({
    url: uploadFileProfile,
    method: 'POST',
    body: data,
    onSuccess: (res) => {
      onUploadSuccess({ res, e, formik, name })
      _.set(document.getElementById(`btn-add-image-${name}`), 'value', null)
    },
  })
}

const StaffProfileForm = ({ isEditForm, NonAcademy }) => {
  const [IsNonAcademy, setIsNonAcademy] = useState(!!NonAcademy)
  const url = !IsNonAcademy ? 'staff-profile' : 'non-academy-profile'
  const text = !IsNonAcademy ? 'ข้อมูลพนักงาน' : 'ข้อมูล Non Academy'
  const [displayTitle, setDisplayTitle] = useState('')

  const urlGetJobTitle =
    listJobTitle +
    `?limit=${bodyJobTitle.limit}&page=${bodyJobTitle.page}&order=${bodyJobTitle.order}&sort=${bodyJobTitle.sort}`

  const fetchOptions = useFetchList([
    {
      body: bodyLevel,
      url: filterLevel,
      method: 'post',
    },
    {
      body: bodyStaff,
      url: filterStaffProfile,
      method: 'post',
    },
    {
      body: bodyArea,
      url: filterArea,
      method: 'post',
    },
    {
      body: bodyCostCenter,
      url: filterCostCenter,
      method: 'post',
    },
    {
      body: bodyExperienced,
      url: filterExperienced,
      method: 'post',
    },
    {
      body: bodyInstitute,
      url: filterInstitute,
      method: 'post',
    },
    {
      body: bodyProductType,
      url: filterProductType,
      method: 'post',
    },
    {
      url: urlGetJobTitle,
      method: 'get',
    },
    { url: examinationQualification, method: 'get' },
  ])

  const level = _.get(fetchOptions, '[0].result', [])
  const staff = _.get(fetchOptions, '[1].result', [])
  const area = _.get(fetchOptions, '[2].result', [])
  const costCenter = _.get(fetchOptions, '[3].result', [])
  const experienced = _.get(fetchOptions, '[4].result', [])
  const institute = _.get(fetchOptions, '[5].result', [])
  const productType = _.get(fetchOptions, '[6].result', [])
  const jobTitle = _.get(fetchOptions, '[7].result', [])
  const qualificationList = _.get(fetchOptions, '[8].result', [])

  const { id: uuid } = useParams()
  const theme = useTheme()
  const history = useHistory()

  const titleText = isEditForm ? 'แก้ไข' : 'เพิ่ม'

  const breadcrumbList = [
    { title: 'Manage', link: '/', pointer: false },
    {
      title: text,
      link: `/manage/${url}`,
      pointer: true,
    },
    { title: `${titleText}${text}`, link: '/', pointer: false },
  ]

  const onConfirmClick = () => {
    setDialog({ ...dialog, open: false })
  }

  const onCancelClick = () => {
    setDialog({ ...dialog, open: false })
  }

  const fetchStaffProfileById = async (uuid) => {
    callAPI({
      method: 'get',
      url: staffProfile + `/${uuid}`,
      onSuccess: (data) =>
        onFetchStaffByIdSuccess({
          data,
          setDisplayTitle,
          formik,
          uuid,
          setInitialValues,
        }),
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  useEffect(() => {
    if (isEditForm) {
      setIsPageLoading(true)
      fetchStaffProfileById(uuid)
    }
  }, [isEditForm])

  const [dialog, setDialog] = useState({
    open: false,
    variant: 'save',
    title: '',
    icon: '',
    content: '',
    onConfirmClick: onConfirmClick,
    onCancelClick: onCancelClick,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(defaultStaffProfileform)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: !IsNonAcademy
      ? validationSchema
      : validationNonAcademySchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitStaffProfile({
            values,
            isEditForm,
            uuid,
            setDialog,
            setIsLoading,
            formik,
            IsNonAcademy,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })
  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            py: 1,
            backgroundColor: 'background.basic',
            justifyContent: 'space-between',
            display: 'flex',
            position: 'fixed',
            top: 66,
            height: '60px',
            width: '100%',
            zIndex: 3,
          }}
        >
          <Typography variant="h4">
            {`${titleText}${text}`}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              ml: 2,
              [theme.breakpoints.up('md')]: {
                position: 'fixed',
                top: 74,
              },
              right: 0,
            }}
          >
            <Button
              data-testid="btn-back"
              sx={{ mr: 3, width: '100px' }}
              variant="outlined"
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
            <Button
              sx={{ mr: 3, width: '100px' }}
              variant="contained"
              onClick={formik.submitForm}
            >
              บันทึก
            </Button>
          </Box>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3, pt: 7 }} menuList={breadcrumbList} />

        <Form
          setIsNonAcademy={setIsNonAcademy}
          IsNonAcademy={IsNonAcademy}
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={onCancelClick}
          onSubmit={submitStaffProfile}
          formik={formik}
          isLoading={isLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          level={level}
          jobTitle={jobTitle}
          staff={staff}
          area={area}
          costCenter={costCenter}
          experienced={experienced}
          institute={institute}
          productType={productType}
          theme={theme}
          handleUploadFile={handleUploadFile}
          isPageLoading={isPageLoading}
          setIsLoading={setIsLoading}
          qualificationList={qualificationList}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default StaffProfileForm
