import {
  roomSelector,
  setRoom,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import TextInput from '../../../../../../components/Input/TextInput'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getErrorMessage, getIndexByBookingDate } from '../../../event'
import _ from 'lodash'

export const AdditionalEquipment = ({ bookingDate, roomIndex }) => {
  const value = useSelector(
    roomSelector({ bookingDate, roomIndex, key: 'additionalEquipment' }),
    shallowEqual,
  )

  const formError = useSelector(
    (state) => state.roomManagementDrawer.formError,
    shallowEqual,
  )
  const dispatch = useDispatch()
  return (
    <TextInput
      boxSx={{ pl: 10 }}
      type="basic"
      name="additionalEquipment"
      labelText={'รายละเอียดเพิ่มเติม'}
      placeholder="กรุณากรอกรายละเอียดเพิ่มเติม"
      rows={3}
      multiline={true}
      value={value}
      onChange={(e) => {
        dispatch(
          setRoom({
            key: 'additionalEquipment',
            value: e.target.value,
            roomIndex,
            bookingDate: bookingDate,
          }),
        )
      }}
      textError={getErrorMessage(
        formError,
        `roomBookingList[${getIndexByBookingDate(
          bookingDate,
        )}].rooms[${roomIndex}].additionalEquipment`,
      )}
      isShowTextError={
        !_.isEmpty(
          getErrorMessage(
            formError,
            `roomBookingList[${getIndexByBookingDate(
              bookingDate,
            )}].rooms[${roomIndex}].additionalEquipment`,
          ),
        )
      }
    />
  )
}
