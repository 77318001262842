import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import { handlePasteFormat } from '../Period/events'
import { StyledFlexBox, StyledRadioGroup } from '../Styled'
import { handleDefaultValue, handleInputFormat } from './events'

const SellCourse = () => {
  const dispatch = useDispatch()
  const keySell = 'setting.sellCourse'
  const keyIsSell = `${keySell}.isSell`
  const keyCost = `${keySell}.cost`
  const keyDescription = `${keySell}.description`
  const { isSell, cost, description, onGoing } = useSelector(
    (state) => ({
      isSell: getStateValue(keyIsSell),
      cost: getStateValue(keyCost),
      description: getStateValue(keyDescription),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">ต้องการขายหลักสูตรนี้ใช่หรือไม่</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <StyledRadioGroup
          name={keyIsSell}
          value={isSell.toString()}
          onChange={(e) => {
            const isCollect = e.target.value === 'true'
            dispatch(handleChange(keyIsSell, isCollect))
            if (!isCollect) {
              dispatch(handleChange(keyCost, ''))
              dispatch(handleChange(keyDescription, ''))
            }
          }}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="ใช่"
            disabled={onGoing}
          />
          {isSell && (
            <>
              <StyledFlexBox sx={{ ml: 3, alignItems: 'start' }}>
                <Typography width={260} mt="11px">
                  ค่าธรรมเนียมสำหรับเส้นทางการเรียนรู้
                </Typography>
                <StyledFlexBox sx={{ gap: 2, alignItems: 'start' }}>
                  <TextInput
                    boxSx={{ width: 180 }}
                    sx={{ height: 50 }}
                    name={keyCost}
                    type="basic"
                    id="input-cost"
                    maxlength={7}
                    inputProps={{
                      onInput: (e) => handleInputFormat(e),
                      onPaste: (e) => handlePasteFormat(e),
                    }}
                    textError={_.get(errors, keyCost, '')}
                    isShowTextError={_.get(errors, keyCost, '')}
                    value={handleDefaultValue(cost)}
                    onChange={(e) => dispatch(handleChangeEvent(e))}
                    disabled={onGoing}
                  />
                  <Typography mt="11px">บาท</Typography>
                </StyledFlexBox>
              </StyledFlexBox>
              <TextInput
                boxSx={{ ml: 3, pr: 3 }}
                multiline
                rows={3}
                name={keyDescription}
                type="basic"
                labelText="รายละเอียดเพิ่มเติม"
                placeholder="รายละเอียดเพิ่มเติม"
                id="input-description"
                textError={_.get(errors, keyDescription, '')}
                isShowTextError={_.get(errors, keyDescription, '')}
                value={description}
                onChange={(e) => dispatch(handleChangeEvent(e))}
                disabled={onGoing}
              />
            </>
          )}
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="ไม่ใช่"
            disabled={onGoing}
          />
        </StyledRadioGroup>
      </Box>
    </StyledFormCard>
  )
}

export default SellCourse
