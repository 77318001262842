import { store } from '../../../../App'
import {
  setReduxValue,
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
} from '.'
import { filterLearnerByCourse } from '../../../../services/eLearning/course/dashboard'
import { setInitialTable, setSearchText } from '../../table'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  ABBREVIATION_DISTRIBUTION,
  ABBREVIATION_USER_TYPE,
  E_LEARNING_COURSE_STATUS,
  DISTRIBUTION,
} from '../../../../constants/eLearning'
import { headCellsLearner } from '../../../../modules/ELearning/Course/View/Content/Dashboard/model'
import { eLearningDashboardLearnerDownload } from '../../../../utils/apiPath'
import { downloadFile } from '../../../../services/util'
import { initialState } from './model'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const handleQuickSearch = async (searchText) => {
  store.dispatch(setSearchText(searchText))
  await fetchLearnerList()
}

export const fetchLearnerList = async () => {
  store.dispatch(startLoadingDrawer())

  const {
    statusLearnerDrawer,
    courseUuid,
    learnerTypeSelected,
    filter: { periodStart, periodEnd, courseBatchList },
  } = store.getState().eLearningDashboardByCourse

  if (_.isEmpty(courseUuid)) return
  const { table, search } = store.getState().table

  const status = _.map(
    _.filter(statusLearnerDrawer, (st) => st.checked),
    (s) => s.label,
  )

  const body = {
    courseUuid: courseUuid,
    limit: _.get(table, 'limit', 100),
    page: _.get(table, 'page', 1) < 0 ? 1 : _.get(table, 'page', 1),
    name: _.get(search, 'searchText', ''),
    status: status,
    userType:
      learnerTypeSelected === 'ALL'
        ? ''
        : ABBREVIATION_USER_TYPE[learnerTypeSelected],
    periodStart: dayjs(periodStart).format('YYYY-MM-DD'),
    periodEnd: dayjs(periodEnd).format('YYYY-MM-DD'),
    courseBatchList: courseBatchList,
  }
  const {
    payload: { results, totalLearner, notStart, inProgress, complete },
  } = await store.dispatch(filterLearnerByCourse(body))

  const learners = _.map(results, (res) => {
    const abbrDistribution = _.get(res, 'userType')
    const levelOfLearner = _.get(res, 'userLevel')

    const staffProfileUuid = _.get(res, 'userProfile.staffProfile.uuid', '')
    const agentProfileUuid = _.get(res, 'userProfile.agentProfile.uuid', '')
    const prospectProfileUuid = _.get(
      res,
      'userProfile.prospectProfile.uuid',
      '',
    )

    let viewPath = '/manage'
    switch (abbrDistribution) {
      case ABBREVIATION_DISTRIBUTION.AGC:
      case ABBREVIATION_DISTRIBUTION.KTB:
      case ABBREVIATION_DISTRIBUTION.BAC:
        if (levelOfLearner === 'Prospect') {
          // Prospect Profile
          viewPath += `/prospect-profile/view/${prospectProfileUuid}`
        } else {
          // Agent Profile
          viewPath += `/agent-profile/view/${agentProfileUuid}`
        }
        break
      case ABBREVIATION_DISTRIBUTION.CS:
        if (levelOfLearner === 'Prospect') {
          // CS User Profile
          viewPath += `/cs-user-profile/view/${prospectProfileUuid}`
        } else {
          // CS Agent Profile
          viewPath += `/cs-agent-profile/view/${agentProfileUuid}`
        }
        break
      case ABBREVIATION_DISTRIBUTION.SAC:
      case ABBREVIATION_DISTRIBUTION.SNA:
        // Staff Profile
        viewPath += `/staff-profile/${staffProfileUuid}`
        break
    }
    const firstNameTH = !_.isNil(_.get(res, 'firstNameTH', ''))
      ? _.get(res, 'firstNameTH', '')
      : ''
    const lastNameTH = !_.isNil(_.get(res, 'lastNameTH', ''))
      ? _.get(res, 'lastNameTH', '')
      : ''

    return {
      ...res,
      userType: DISTRIBUTION[_.get(res, 'userType')]
        ? DISTRIBUTION[_.get(res, 'userType')]
        : DISTRIBUTION.OTHER,
      nameTH: `${firstNameTH} ${lastNameTH}`,
      learnerStatus: _.get(res, 'status'),
      completedAt: _.isNil(_.get(res, 'completedAt'))
        ? '-'
        : dayjs(_.get(res, 'completedAt')).utc().format('DD/MM/YYYY'),
      viewPath: viewPath,
    }
  })

  const newStatusList = _.map(statusLearnerDrawer, (st) => {
    let count = 0
    switch (st.status) {
      case E_LEARNING_COURSE_STATUS.NOT_STARTED:
        count = notStart
        break
      case E_LEARNING_COURSE_STATUS.INPROGRESS:
        count = inProgress
        break
      case E_LEARNING_COURSE_STATUS.COMPLETED:
        count = complete
        break
    }
    return { ...st, count: count }
  })

  store.dispatch(
    setInitialTable({
      rows: learners,
      allCount: totalLearner,
      headCells: headCellsLearner,
      onDownload: (selected, sort, order) => onDownload(selected, sort, order),
      placeholder: 'ค้นหา',
      searchKey: 'codeId',
      handleSearch: (text) => handleQuickSearch(text),
      hideFilter: true,
    }),
  )
  store.dispatch(
    setReduxValue({
      key: 'statusLearnerDrawer',
      value: newStatusList,
    }),
  )
  store.dispatch(stopLoadingDrawer())
}

export const onDownload = async (selected, sort, order) => {
  store.dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await store.dispatch(
    downloadFile({
      url: eLearningDashboardLearnerDownload,
      body: body,
      fileName: `รายชื่อผู้เรียน.xlsx`,
    }),
  )
  store.dispatch(stopLoading())
}

export const resetLearnerDrawer = () => {
  store.dispatch(
    setReduxValue({
      key: 'statusLearnerDrawer',
      value: initialState.statusLearnerDrawer,
    }),
  )
}

export const summaryFilterTotal = () => {
  const {
    dateChecked,
    periodStart,
    periodEnd,
    courseBatchChecked,
    courseBatchList,
  } = store.getState().eLearningDashboardByCourse.filter

  let filterTotal = 0
  if (dateChecked && periodStart && periodEnd) {
    filterTotal++
  }
  if (courseBatchChecked && courseBatchList.length > 0) {
    filterTotal++
  }

  store.dispatch(setReduxValue({ key: 'filterTotal', value: filterTotal }))
}
