import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { useDispatch } from 'react-redux'
import { onMaps, onPhoneCall } from './events'
import { CardContainer, DetailContainer, ButtonContainer } from './Styled'

const CardLocation = ({ image, item, imageMap }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [open])

  return (
    <CardContainer>
      <img
        width={170}
        height={170}
        style={{ objectFit: 'cover' }}
        src={image}
      />
      <DetailContainer>
        <Typography variant="h4" color="primary">
          {item.title}
        </Typography>
        <Typography variant="body1" color="primary">
          {item.description}
        </Typography>
        <ButtonContainer>
          <Tooltip
            PopperProps={{ disablePortal: true }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<Typography variant="tooltip">คัดลอกแล้ว</Typography>}
            arrow
            placement="bottom"
          >
            <Button
              data-testid="btn-google-maps"
              variant="contained"
              endIcon={<PhoneEnabledIcon />}
              onClick={() => {
                onPhoneCall(item.phoneNumber, setOpen)
              }}
            >
              โทร
            </Button>
          </Tooltip>
          <Button
            data-testid="btn-maps"
            variant="outlined"
            endIcon={<MapOutlinedIcon />}
            onClick={() => {
              dispatch(onMaps(item.title, imageMap, item.googleMap))
            }}
          >
            ดูแผนที่
          </Button>
        </ButtonContainer>
      </DetailContainer>
    </CardContainer>
  )
}

export default CardLocation
