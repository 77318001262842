import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

export const Container = styled(Box)(
  ({ theme, background, width, minheight }) => ({
    width: width,
    minHeight: minheight,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    background: background && background,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.blue.blueBorder}`,
  }),
)

export const StyledButton = styled(IconButton)(({ background }) => ({
  marginLeft: 'auto',
  background: background,
  borderRadius: '50%',
}))

export const StyledImg = styled('img')(({ theme }) => ({
  maxWidth: 330,
  marginRight: theme.spacing(5),
  marginLeft: 'auto',
  marginTop: 'auto',
  marginBottom: `-${theme.spacing(2)}`,
}))

export const StyledNumber = styled(Typography)(({ theme, background }) => ({
  background: background,
  marginRight: theme.spacing(0.75),
  borderRadius: '50%',
  minWidth: 24,
  lineHeight: theme.spacing(3),
  textAlign: 'center',
}))

export const StyledName = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))
