import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageClassDetail'
import { getManageClassDetailByUuid } from '../../../services/manageClass/detail'
import { getQRForEnroll } from './Header/events'

export const fetchMangeClassDetail = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getManageClassDetailByUuid(uuid))
  await dispatch(getQRForEnroll(uuid))
  dispatch(stopLoading())
}
