import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import _ from 'lodash'

const TableSRAM = ({ sramName = '', data = [] }) => {
  const theme = useTheme()
  const dataList = data
  const totalEnroll = _.sumBy(data, 'totalEnroll')
  const totalExaminees = _.sumBy(data, 'countExaminees')
  const totalPass = _.sumBy(data, 'countPass')
  const totalFail = _.sumBy(data, 'countFail')
  const totalMissed = _.sumBy(data, 'countMissed')
  return (
    <StyledFullWidth
      sx={{
        mx: theme.spacing(3),
        mt: theme.spacing(3),
        // isLoading: isLoading,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 250,
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ maxWidth: 250 }}>
            <TableRow>
              <TableCell sx={{ width: '50px' }}>ลำดับ</TableCell>
              <TableCell sx={{ width: '100px' }}>
                {sramName ?? 'SRAM'}:
              </TableCell>
              <TableCell sx={{ width: '250px' }}>จำนวนการสมัคร</TableCell>
              <TableCell sx={{ color: '#005CE7', width: '150px' }}>
                เข้าสอบ
              </TableCell>
              <TableCell sx={{ color: '#FF650E', width: '150px' }}>
                ขาดสอบ
              </TableCell>
              <TableCell sx={{ color: '#03C83E', width: '150px' }}>
                สอบผ่าน
              </TableCell>
              <TableCell sx={{ color: '#D64F65', width: '150px' }}>
                สอบไม่ผ่าน
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, rowIndex) => {
              return (
                <TableRow
                  key={`sramName-${row.ramCode}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{rowIndex + 1}</TableCell>
                  <TableCell>{row.ramCode}</TableCell>
                  <TableCell>{row.totalEnroll}</TableCell>
                  <TableCell>
                    {row.countExaminees}/{row.totalEnroll} (
                    {row.countExaminees > 0
                      ? ((row.countExaminees / row.totalEnroll) * 100).toFixed(
                          2,
                        )
                      : 0}
                    %)
                  </TableCell>
                  <TableCell>
                    {row.countMissed}/{row.totalEnroll} (
                    {row.countMissed > 0
                      ? ((row.countMissed / row.totalEnroll) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                  <TableCell>
                    {row.countPass}/{row.countExaminees} (
                    {row.countPass > 0
                      ? ((row.countPass / row.countExaminees) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                  <TableCell>
                    {row.countFail}/{row.countExaminees} (
                    {row.countFail > 0
                      ? ((row.countFail / row.countExaminees) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                </TableRow>
              )
            })}
            {dataList.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={6}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell>
                <strong>{totalEnroll}</strong>
              </TableCell>
              <TableCell>
                <strong>
                  {totalExaminees}/{totalEnroll} (
                  {totalExaminees > 0
                    ? ((totalExaminees / totalEnroll) * 100).toFixed(2)
                    : 0}
                  %)
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {totalMissed}/{totalEnroll} (
                  {totalMissed > 0
                    ? ((totalMissed / totalEnroll) * 100).toFixed(2)
                    : 0}
                  %)
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {totalPass}/{totalExaminees} (
                  {totalPass > 0
                    ? ((totalPass / totalExaminees) * 100).toFixed(2)
                    : 0}
                  %)
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {totalFail}/{totalExaminees} (
                  {totalFail > 0
                    ? ((totalFail / totalExaminees) * 100).toFixed(2)
                    : 0}
                  %)
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledFullWidth>
  )
}

export default TableSRAM

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledFullWidth = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  width: 'auto',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
