import { Box, Divider, IconButton, Typography } from '@mui/material'
import { Close, Launch, PlaceTwoTone } from '@mui/icons-material'
import useTheme from '@mui/system/useTheme'
import { StyledCard, StyledColumn, StyledRow } from '../../../Styled'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { enumRequestType, requestType } from './constanst'
import { setReduxValue } from '../../../../../redux/slices/roomManagement/Dashboard'
import { PopUpRoomDetail } from './PopUpRoomDetail'
import { PopUpHoliday } from './PopUpHoliday'
import { handleClickPopup } from './event'

export const PopUp = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { item } = useSelector(
    (state) => ({
      item: state.roomManagement.PopupEventSummary,
    }),
    shallowEqual,
  )

  if (item === null) return <div />

  return (
    <StyledCard sx={{ minWidth: 450 }}>
      <StyledRow sx={{ justifyContent: 'end' }}>
        <Box
          sx={{
            background: requestType.find(
              (type) => type.value === item.requestType,
            ).background,
            borderRadius: 2,
            px: 1,
          }}
        >
          <Typography
            sx={{
              color: requestType.find((type) => type.value === item.requestType)
                .color,
            }}
            variant="body3b"
          >
            {requestType.find((type) => type.value === item.requestType).label}
          </Typography>
        </Box>
        {item.requestType != 'HOLIDAY' && (
          <IconButton
            onClick={() =>
              handleClickPopup(
                item.requestType === enumRequestType.MANAGE_CLASS
                  ? item.trainingPlanUuid
                  : item.uuid,
                item.requestType,
              )
            }
          >
            <Launch />
          </IconButton>
        )}
        <IconButton
          onClick={() =>
            dispatch(
              setReduxValue({
                key: 'isPopupEventSummary',
                value: false,
              }),
            )
          }
        >
          <Close />
        </IconButton>
      </StyledRow>
      <Typography variant="body1b">{item.bookingTopic}</Typography>
      {item.requestType === 'HOLIDAY' ? (
        <PopUpHoliday />
      ) : (
        <>
          <StyledRow sx={{ gap: 0.5, marginBottom: '15px' }}>
            <PlaceTwoTone sx={{ width: 18, height: 18 }} />
            <Typography variant="body1">{item.station}</Typography>
          </StyledRow>
          <StyledColumn sx={{ rowGap: 1 }}>
            <PopUpRoomDetail />
            <Divider sx={{ borderColor: theme.palette?.blue?.blueLine }} />
            <Detail label="วัน/เวลาที่จอง" value={item.bookingDate} />
            <Detail label="จองโดย" value={item['bookBy']} />
            <Detail label="จองให้" value={item.bookFor} />
            <Detail label="Email" value={item.email} />
            <Detail label="เบอร์โทร" value={item.mobileNo} />
            <Detail label="หมายเหตุ" value={item.remark} />
          </StyledColumn>
        </>
      )}
    </StyledCard>
  )
}
export const Detail = ({ label, value, labelColor = '', dot = false }) => {
  return (
    <StyledRow>
      {dot && (
        <div
          style={{
            width: 4,
            height: 4,
            backgroundColor: '#000',
            borderRadius: '50%',
            margin: 'auto 8px',
          }}
        />
      )}
      <Typography
        variant="body3b"
        color={labelColor}
        sx={{ minWidth: dot ? 80 : 100 }}
      >
        {label}
      </Typography>
      <Typography variant="body3">
        {value && value != '0' ? value : '-'}
      </Typography>
    </StyledRow>
  )
}
