import FormFooter from '../../../../components/CRUD/components/FormFooter/FormFooter'
import { CustomInput } from '../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import CustomStatusSwitch from '../../../../components/CRUD/components/CustomStatusSwitch'
import { handleSubmitClick } from '../../../../components/CRUD/handler/handleSubmitClick'
import { useParams } from 'react-router-dom'
import { postBodyMutation } from './mutation/post'
import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../utils/lib'
import { validationSchema } from './schema/schema'
import { Box } from '@mui/material'

export const Content = () => {
  const { uuid } = useParams()

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: 'MASTER_DATA',
    permission: ['CREATE'],
  })

  return (
    <>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="groupCode"
          labelName=" Group Code"
          sx={{ width: 'calc(50% - 12px)' }}
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="costCenter"
          labelName=" Cost Center ID"
          sx={{ width: 'calc(50% - 12px)' }}
        />
      </Box>
      <Box sx={{ width: 'calc(50% - 12px)' }}>
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="descriptionName"
          labelName=" Cost Center Name"
        />
      </Box>
      <CustomStatusSwitch
        disabled={!hasPermissionCreate}
        fieldName="status"
        labelName=" การเปิดใช้งาน"
      />
      <CustomTextArea
        disabled={!hasPermissionCreate}
        fieldName="remark"
        labelName=" หมายเหตุ"
      />
      <FormFooter
        disabled={!hasPermissionCreate}
        handleSubmitClick={() =>
          handleSubmitClick(uuid, postBodyMutation, validationSchema)
        }
      />
    </>
  )
}
