import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  manageClassLearnerDetail,
  manageClassAttendanceStatus,
  manageClassLearnerHistory,
  manageClassLearnerCancel,
  manageClassAnnounceLearner,
  manageClassPasscourse,
  manageClassLearnerRoom,
  manageClassLearnerListHistory,
  manageClassListAttendanceHistory,
  manageClassNotPasscourse,
  manageClassAbsentCourse,
} from '../../utils/apiPath'

export const getManageClassLearnerDetail = createAsyncThunk(
  'manageClassDetail/getManageClassLearnerDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassLearnerDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const getManageClassLearnerHistory = createAsyncThunk(
  'manageClassDetail/getManageClassLearnerHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassLearnerHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
export const putManageClassAttendanceStatus = createAsyncThunk(
  'manageClassDetail/putManageClassAttendanceStatus',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassAttendanceStatus, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const passTraining = createAsyncThunk(
  'manageClassDetail/manageClassPasscourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassPasscourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const notPassTraining = createAsyncThunk(
  'manageClassDetail/manageClassNotPassCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassNotPasscourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putManageClassLearnerCancel = createAsyncThunk(
  'manageClassDetail/putManageClassLearnerCancel',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassLearnerCancel, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const announceLearner = createAsyncThunk(
  'manageClassDetail/announceLearner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageClassAnnounceLearner, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLearnerRoom = createAsyncThunk(
  'manageClassLearner/getLearnerRoom',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageClassLearnerRoom, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLearnerHistory = createAsyncThunk(
  'manageClassLearner/getLearnerHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassLearnerListHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postManageClassListAttendanceHistory = createAsyncThunk(
  'manageClass/attendanceHistoryList',
  async ({ uuid, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${manageClassListAttendanceHistory}/${uuid}`,
        body
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateLearnerAbsentCourse = createAsyncThunk(
  'manageClassDetail/updateLearnerAbsentCourse',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassAbsentCourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
