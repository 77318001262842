import _ from 'lodash'
import {
  distributionChannelFilter,
  filterAcquiredSkill,
} from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enums/drawerEnum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'
import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { fetchStationList } from '../../../../../modules/Inventory/Product/List'
import { reportConfig } from '../../../../../modules/ReportMixModule/reportConfig'
import { getOptionsReportByKey } from '../../../../../services/reportComp'
import {
  bodyAcquiredSkill,
  bodyDistribution,
} from '../../../../../constants/report/listAPIFetchMasterDataOptions'

const checkIsFilter = (key, filter) => {
  if (['association', 'otherFilter'].includes(key)) {
    return (
      filter?.[`${key}Checked`] &&
      filter?.[`${key}RegionChecked`] &&
      filter?.[`${key}RegionList`].length > 0
    )
  } else if (['station', 'skill', 'ram', 'sram'].includes(key)) {
    return filter?.[`${key}Checked`] && filter?.[`${key}List`].length > 0
  }
}

export const handleFiltering = (filter) => (dispatch) => {
  const isFilterDate =
    filter.dateFilterChecked && filter.dateFilterStartDate != ''
  const isFilterStation = checkIsFilter('station', filter)
  const isFilterSkill = checkIsFilter('skill', filter)
  const isFilterRAM = checkIsFilter('ram', filter)
  const isFilterSRAM = checkIsFilter('sram', filter)

  const { filterList } = store.getState().crud
  const distribution = filterList.distribution ?? []
  const distributionList = []
  if (filter.distributionChecked) {
    for (const key in filter.distribution) {
      if (!filter.distribution[key]) continue
      const item = distribution.find((e) => e.name === key)
      distributionList.push(item)
    }
  }

  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  if (isFilterStation) ++countFilterTotal
  if (isFilterSkill) ++countFilterTotal
  if (distributionList.length > 0) ++countFilterTotal
  if (isFilterRAM) ++countFilterTotal
  if (isFilterSRAM) ++countFilterTotal
  dispatch(
    setFieldValue({
      key: 'mixModuleDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        dateFilterStartDate: isFilterDate
          ? dayjs(filter.dateFilterStartDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        dateFilterFinishDate: isFilterDate
          ? dayjs(filter.dateFilterFinishDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        isFilterDate: isFilterDate,
        ram: isFilterRAM ? filter.ramList.map((item) => item.label) : [],
        sram: isFilterSRAM ? filter.sramList.map((item) => item.value) : [],
        station: isFilterStation ? filter.stationList : [],
        skill: isFilterSkill ? filter.skillList.map((item) => item.label) : [],
        distribution: filter.distributionChecked
          ? distributionList.map((item) => item.value)
          : [],
        filterTotal: countFilterTotal,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const fetchRAM = async () => {
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: reportConfig.modulePath,
      body: {
        columnSelected: 'ramCode',
        moduleId: reportConfig.moduleId,
      },
    }),
  )
  const results = _.get(response, 'results', [])
  return results
}

export const fetchSram = async () => {
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: '/e-testing/dashboard',
      body: {
        eTestVersionUuid: '82382fd6-237c-4647-8c8b-20d7d33e8d5c',
        type: 'SRAM',
        courseCode: '',
      },
    }),
  )
  const results = _.get(response, 'results', [])
  return results
}

export const fetchFilterOptions = async () => {
  const stationList = (await fetchStationList()).map((item) => {
    return {
      ...item,
      value: item.name,
    }
  })
  store.dispatch(
    setFieldValue({
      key: 'filterList.station',
      value: stationList,
    }),
  )

  const ramList = await fetchRAM()
  store.dispatch(
    setFieldValue({
      key: 'filterList.ram',
      value: ramList,
    }),
  )

  const sRamList = await fetchSram()
  store.dispatch(
    setFieldValue({
      key: 'filterList.sram',
      value: sRamList,
    }),
  )

  const distribution = []
  await callAxios
    .post(distributionChannelFilter, bodyDistribution)
    .then(({ data }) => {
      _.get(data, 'result', []).forEach((item) => {
        distribution.push({
          label: `${item.distribution}`,
          name: _.lowerCase(item.distribution).replace(' ', '') + 'Checked',
          uuid: item.uuid,
          value: item.uuid,
        })
      })
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList.distribution',
      value: distribution,
    }),
  )

  const skill = []
  await callAxios
    .post(filterAcquiredSkill, bodyAcquiredSkill)
    .then(({ data }) => {
      _.get(data, 'result', []).forEach((item) => {
        skill.push({
          label: `${item.acquiredSkill}`,
          name: _.lowerCase(item.acquiredSkill).replace(' ', '') + 'Checked',
          uuid: item.uuid,
          value: item.uuid,
        })
      })
    })
  store.dispatch(
    setFieldValue({
      key: 'filterList.skill',
      value: skill,
    }),
  )
}

export const handleSelectDate = (selectedDates, filter, setFilter, item) => {
  const objState = {
    ...filter,
    [item.dateStateStart]: selectedDates.startDate,
    [item.dateStateFinish]: selectedDates.endDate,
    [item.dateStateDisplay]: selectedDates.display,
    [item.nameInput]: selectedDates.startDate,
  }
  setFilter(objState)
}

export const handleSelectMultiple = (list, filter, setFilter, nameInput) => {
  let objState = {
    ...filter,
    [`${nameInput}List`]: list,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateFilterChecked: defaultFilter.dateFilterChecked,
      dateFilterStartDate: defaultFilter.dateFilterStartDate,
      dateFilterFinishDate: defaultFilter.dateFilterEndDate,
      dateFilterDisplayDate: defaultFilter.dateFilterDisplayDate,
    }
  } else if (key === 'station') {
    objState = {
      ...objState,
      stationChecked: false,
      station: undefined,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}

export const clearSelectDropdown = async ({ arrayCheckbox }) => {
  await arrayCheckbox.map((item) => {
    if (['select_dropdown'].includes(item.type)) {
      store.dispatch(
        setFieldValue({
          key: `formData.${item.nameInput}-filter-dropdown`,
          value: '',
        }),
      )
    }
  })
}
