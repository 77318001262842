import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setReportConfig } from '../../redux/slices/reportComp'
import Report from '../../components/Report'
import { reportConfig } from './reportConfig'
import { handleCheckStatusReportUpdated } from '../../components/Report/handler/handleCheckStatusReportUpdated'
import { openDialog, closeDialog } from '../../redux/slices/dialog'

const Index = () => {
  const dispatch = useDispatch()
  useEffect(async () => {
    dispatch(
      setReportConfig({
        reportConfig: reportConfig,
      }),
    )
    const res = await handleCheckStatusReportUpdated('AUDIT_LOG')
    if (res?.status === 'INPROGRESS') {
      dispatch(
        openDialog({
          type: 'confirm',
          title: 'ระบบกำลังอัปเดตข้อมูล',
          message: 'ข้อมูลอาจไม่ถูกต้อง กรุณารอสักครู่',
          hideCancel: true,
          handleConfirm: () => dispatch(closeDialog()),
          handleCancel: () => dispatch(closeDialog()),
        }),
      )
    } else if (res?.status === 'FAILED') {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ระบบอัปเดตข้อมูลผิดพลาด',
          message: 'ข้อมูลอาจไม่ถูกต้อง กรุณาอัปเดตข้อมูลอีกครั้ง',
          hideCancel: true,
          handleConfirm: () => dispatch(closeDialog()),
          handleCancel: () => dispatch(closeDialog()),
        }),
      )
    }
  }, [])

  return (
    <>
      <Report />
    </>
  )
}
export default Index
