import Select from '../../../../../../components/Input/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  bookingItemSelector,
  setTimeByDate,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import _ from 'lodash'

export const OverTimeSelect = ({ bookingDate, field, labelText }) => {
  const dispatch = useDispatch()

  const value = useSelector(
    bookingItemSelector({ bookingDate, key: field }),
    shallowEqual,
  )
  const options = useSelector(
    bookingItemSelector({
      bookingDate,
      key:
        field === 'beforeTraining'
          ? 'overTimeBeforeOptions'
          : 'overTimeAfterOptions',
    }),
    shallowEqual,
  )

  return (
    <Select
      id={field}
      name={field}
      labelText={labelText}
      placeholder="กรุณาเลือก"
      // disabled={disabledAll}
      options={options}
      showTextError={false}
      value={value}
      handleChange={(e) => {
        const value = _.get(e, 'target.value', '')
        dispatch(
          setTimeByDate({
            key: field,
            value: value,
            bookingDate: bookingDate,
          }),
        )
      }}
    />
  )
}
