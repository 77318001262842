import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { Clear } from '@mui/icons-material'
import { handleDefaultFilterByKey } from '../handler/handleDefaultFilterByKey'
import { useSelector, shallowEqual } from 'react-redux'

const FiltersSummary = () => {
  const theme = useTheme()
  const { latestUpdated, filtersList } = useSelector(
    (state) => ({
      name: state.monthlyPlan.dashboard.name,
      latestUpdated: state.monthlyPlan.dashboard.latestUpdated,
      filtersList: state.monthlyPlan.dashboard.filtersList,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {filtersList?.length > 0 && (
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 2,
            border: `1px solid ${theme.palette.blue.blueLine}`,
            width: 'fit-content',
            py: 0.75,
            px: 1.125,
            display: 'flex',
            gap: 0.75,
          }}
        >
          {_.map(filtersList, (filter, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  backgroundColor: 'rgba(219, 228, 241, 0.60)',
                  borderRadius: 1,
                  width: 'fit-content',
                  px: 1.25,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2">
                  {_.get(filter, 'label')} :
                </Typography>
                <Typography variant="body2b">
                  {' '}
                  {_.get(filter, 'value')}
                </Typography>
                {_.get(filter, 'isFilter') && (
                  <IconButton
                    sx={{ ml: 1, p: 0 }}
                    onClick={() => handleDefaultFilterByKey(filter?.name)}
                  >
                    <Clear
                      sx={{
                        width: 16,
                        height: 16,
                        color: theme.palette.text.primary,
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )
          })}
        </Box>
      )}

      <Typography variant="body2" color="action.active">
        ข้อมูลเมื่อวันที่ {latestUpdated}
      </Typography>
    </Box>
  )
}

export default FiltersSummary
