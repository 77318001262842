import React from 'react'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { StyledBox } from '../Styled'
import { openDetailDrawer } from '../../../../../modules/Inventory/Transfer/TicketDetailList/handler/openDetailDrawer'

const ActionTransferView = ({ row }) => {
  return (
    <StyledBox minWidth={0} textAlign={'right'}>
      <>
        <IconButton
          color="primary"
          component="span"
          onClick={() => openDetailDrawer(true, row)}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      </>
    </StyledBox>
  )
}

export default ActionTransferView
