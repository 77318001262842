import _ from 'lodash'
import { COURSE_TYPE, MODULE_STATUS } from '../../../constants/eLearning'
import { DEFUALT_COURSE_TYPE, DEFUALT_MODULE_STATUS } from './model'
import { convertFormatDateTime } from '../../../utils/lib'
import {
  setFilter,
  startLoading,
  stopLoading,
} from '../../../redux/slices/eLearning/dashboard'
import { filterELearningDashboard } from '../../../services/eLearning/dashboard'

export const fetchDashboardData = (filter) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    periodStart: _.get(filter, 'startDate', ''),
    periodEnd: _.get(filter, 'endDate', ''),
    status: _.get(filter, 'status', []),
    courseType: _.get(filter, 'courseType', []),
  }
  await dispatch(filterELearningDashboard(body))
  dispatch(stopLoading())
}

export const onFilterClick = (filter) => (dispatch) => {
  let status = []
  let courseType = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(MODULE_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      status.push(MODULE_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      status.push(MODULE_STATUS.DELETED)
    }
  } else {
    status = DEFUALT_MODULE_STATUS
  }

  if (filter.typeChecked) {
    if (filter.type.generalChecked) {
      courseType.push(COURSE_TYPE.GENERAL)
    }
    if (filter.type.oicChecked) {
      courseType.push(COURSE_TYPE.OIC)
    }
  } else {
    courseType = DEFUALT_COURSE_TYPE
  }

  const startDate = filter.startDate
    ? convertFormatDateTime({
        value: _.get(filter, 'startDate', ''),
        type: 'dateDb',
      })
    : ''
  const endDate = filter.endDate
    ? convertFormatDateTime({
        value: _.get(filter, 'endDate', ''),
        type: 'dateDb',
      })
    : ''

  const filterProp = {
    startDate: filter.dateChecked ? startDate : '',
    endDate: filter.dateChecked ? endDate : '',
    courseType: courseType,
    status: status,
  }

  dispatch(setFilter(filterProp))
  dispatch(fetchDashboardData(filterProp))
}

export const getTotalFilter = (filter) => {
  let count = 0
  if (!filter) {
    return ''
  }
  if (
    filter.typeChecked &&
    (filter.type.generalChecked || filter.type.oicChecked)
  ) {
    count++
  }
  if (
    filter.statusChecked &&
    (filter.status.activeChecked ||
      filter.status.inActiveChecked ||
      filter.status.deletedChecked)
  ) {
    count++
  }
  if (filter.dateChecked && filter.startDate && filter.endDate) {
    count++
  }
  return count > 0 ? ` (${count})` : ''
}
