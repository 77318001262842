import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import EditIcon from '@mui/icons-material/Edit'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import _ from 'lodash'
import Dot from '../../components/Table/Dot'
import HistoryDrawer from '../../components/Drawer/History'
import { getStatusText, callAPI } from '../../utils/lib'
import Breadcrumbs from '../../components/Breadcrumbs'
import { level as levelApi, historyLevel } from '../../utils/apiPath'

export const StyledDetails = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const LevelOfLearnerView = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const theme = useTheme()
  const [level, setLevel] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'Level',
      link: '/master-data/level',
      pointer: true,
    },
    { title: 'รายละเอียด Level', link: '/', pointer: false },
  ]

  const fetchLevelOfLearnerById = async (uuid) => {
    callAPI({
      method: 'get',
      url: levelApi + `/${uuid}`,
      onSuccess: (data) => {
        setLevel(data)
      },
      onError: (er) => {
        console.log(er)
      },
    })
  }

  const fetchHistory = async (uuid) => {
    const realPage = page === 0 ? 1 : page + 1
    callAPI({
      method: 'get',
      url: `${historyLevel}?limit=${limit}&page=${realPage}&uuid=${uuid}`,
      onSuccess: (data) => {
        setHistoryList(data.result)
        setAllCount(data.totalCount)
      },
    })
  }

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }
  useEffect(() => {
    setIsPageLoading(true)
    fetchLevelOfLearnerById(uuid)
    setIsPageLoading(false)
  }, [])

  useEffect(() => {
    fetchHistory(uuid)
  }, [limit, page])

  const [isPageLoading, setIsPageLoading] = useState(false)

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
            }}
          >
            รายละเอียด Level : {level.level}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Box
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            sx={level.status !== 'DELETED' ? { mr: 3 } : {}}
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={onHistoryClick}
          >
            ประวัติการแก้ไข
          </Button>

          {level.status !== 'DELETED' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                history.push('/master-data/level/edit/' + level.uuid)
              }}
            >
              แก้ไข
            </Button>
          )}
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ID
          </Typography>
          <Typography variant="body1">{level.id}</Typography>
        </StyledDetails>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            Level
          </Typography>
          <Typography variant="body1">{level.level}</Typography>
        </StyledDetails>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ประเภทของพนักงาน
          </Typography>
          <Typography variant="body1">{level.position}</Typography>
        </StyledDetails>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ระดับของผู้เรียน
          </Typography>
          <Typography variant="body1">
            {_.get(level, 'levelOfLearner.title', '-')}
          </Typography>
        </StyledDetails>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ลำดับ
          </Typography>
          <Typography variant="body1">
            {_.isNull(level.sequence) ? '-' : _.get(level, 'sequence', '-')}
          </Typography>
        </StyledDetails>
        <StyledDetails>
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            สถานะ
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Dot sx={{ mt: 1.5, mr: 1.5 }} status={level.status} />
            <Typography variant="body1">
              {getStatusText(level.status)}
            </Typography>
          </Box>
        </StyledDetails>
        <Divider sx={{ mx: 3 }} />
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'right',
          }}
        >
          <Typography variant="caption" color="text.lightGray">
            แก้ไขล่าสุดโดย{' '}
            {historyList && historyList.length > 0 && historyList[0].author}{' '}
            {historyList && historyList.length > 0 && historyList[0].createdAt}
          </Typography>
        </Box>
      </Card>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default LevelOfLearnerView
