import {
  setChangeQuestionByKey,
  setChangeSubQuestionByKey,
} from '../../../../../redux/slices/eEvaluationForm'

export const handelChangVideo = (props) => (dispatch) => {
  props.setTextVideo(props.value)
  if (!props.isSubQuestions) {
    dispatch(
      setChangeQuestionByKey({
        ...props,
        key: 'urlVideo',
        value: props.value,
      })
    )
  } else {
    dispatch(
      setChangeSubQuestionByKey({
        ...props,
        key: 'urlVideo',
        value: props.value,
      })
    )
  }
}
