import { Button } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { handleShowSummaryOther } from '../../../../../modules/Expense/handler/handleShowSummaryOther'

export const ButtonShowSummaryOther = () => {
  const { showSummaryOther } = useSelector(
    (state) => ({
      showSummaryOther: state.crud.formData.showSummaryOther,
    }),
    shallowEqual,
  )
  const label = showSummaryOther ? 'ดูน้อยลง' : 'ดูรายละเอียด'

  return (
    <Button
      sx={{ width: 100, fontSize: 17 }}
      variant="outlined"
      size="small"
      onClick={() => handleShowSummaryOther()}
    >
      {label}
    </Button>
  )
}
