const DATE = new Date()
const MONTH = DATE.getMonth()
const YEAR = DATE.getFullYear()

export const initialState = {
  initialState: [],
  filterCalendar: {
    isOpen: false,
    scheduleType: [],
    teamCalendar: [],
    trainingCourse: [],
  },
  month: MONTH + 1,
  year: YEAR,
  isTest: false,
}
