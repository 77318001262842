import _ from 'lodash'
import { convertFormatDateTime } from '../../../../utils/lib'

export const handlePeriod = (detail) => {
  const periodStart = convertFormatDateTime({
    value: _.get(detail, 'periodStart', ''),
    type: 'date',
  })
  return periodStart
}
