import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { AmountItem } from './AmountItem'
import { Box, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { handleAddAmount } from '../../../../../modules/Expense/handler/handleAddAmount'

export const AmountList = () => {
  const { amountList } = useSelector(
    (state) => ({
      amountList: state.crud.formData.amountList,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {_.map(amountList, (amount, idx) => (
        <AmountItem
          amount={amount}
          idx={idx}
          disabledDeleteBtn={amountList?.length === 1 && idx === 0}
        />
      ))}
      <Button
        sx={{ width: 190 }}
        variant="outlined"
        startIcon={<Add />}
        onClick={() => handleAddAmount()}
      >
        เพิ่ม Account code
      </Button>
    </Box>
  )
}
