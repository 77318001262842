import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import * as Styled from './Styled'
import RowItem from './RowItem'
import { callAPI } from '../../utils/lib'

import { roadMapOrder, jobTitlSequence } from '../../utils/apiPath'

export const OBJSUBMIT = {
  roadMap: {
    filed: 'order',
    apiPath: roadMapOrder,
  },
  jobTitle: {
    filed: 'sequenc',
    apiPath: jobTitlSequence,
  },
}

export const onSubmitOrder = async ({ orderList, setDialog, type }) => {
  setDialog({
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    variant: 'save',
    open: true,
    onConfirmClick: () => {
      setDialog({
        variant: 'save',
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        open: true,
        isLoading: true,
      })

      const body = {
        list: orderList.map((item, index) => {
          return {
            uuid: item.uuid,
            [_.get(OBJSUBMIT, `${type}.filed`)]: index + 1,
          }
        }),
      }
      callAPI({
        url: _.get(OBJSUBMIT, `${type}.apiPath`),
        method: 'PUT',
        body: body,
        onSuccess: () => {
          setDialog({
            variant: 'success',
            title: 'สำเร็จ',
            content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            open: true,
            isLoading: false,
            onConfirmClick: () => window.location.reload(),
          })
        },
      })
    },
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

const TableOrder = (props) => {
  const {
    onCancel,
    orderList,
    setOrderList,
    headCellsOrder,
    setDialog,
    type,
  } = props

  const onDragEnd = (e) => {
    if (e.source === null || e.destination === null) return
    const [removed] = orderList.splice(e.source.index, 1)
    orderList.splice(e.destination.index, 0, removed)
    const convertOrders = orderList.map((item, index) => {
      return { ...item, order: index + 1 }
    })
    setOrderList(convertOrders)
  }

  return (
    <Styled.Contrainer>
      <Styled.Header>
        <Typography variant="h6">เรียงลำดับ</Typography>
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row' }}>
          <Button
            data-testId="btn-cancel"
            variant="outlined"
            color="warning"
            size="m"
            onClick={onCancel}
          >
            ยกเลิก
          </Button>
          <Button
            data-testId="btn-submit"
            variant="contained"
            color="warning"
            size="m"
            onClick={() => onSubmitOrder({ orderList, setDialog, type })}
          >
            บันทึก
          </Button>
        </Box>
      </Styled.Header>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
        <TableHead>
          <TableRow key="head">
            {headCellsOrder.map((item) => {
              return (
                <TableCell key={item.id} sx={{ width: item.width }}>
                  <Typography variant="body2b">{item.label}</Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`roadmap`}>
            {(provided) => (
              <TableBody
                sx={{ width: '100%' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <RowItem orderList={orderList} headCells={headCellsOrder} />
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
          {/* ))} */}
        </DragDropContext>
      </Table>
    </Styled.Contrainer>
  )
}

export default TableOrder
