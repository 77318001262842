import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import {
  apiToRcmsDashboardLicense,
  apiToRcmsDashboardLicenseDownload,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const headCellsLatestLicense = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'license',
    label: 'License',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const headCellsTopLicense = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'license',
    label: 'License',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'count',
    label: 'จำนวนครั้ง',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchData =
  ({ table, drawerType }) =>
  async (dispatch) => {
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells =
      drawerType === 'latestLicense'
        ? headCellsLatestLicense
        : headCellsTopLicense
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        dataType: drawerType,
      }

      await callAxios
        .post(apiToRcmsDashboardLicense, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          const countAll = _.get(data, 'countAll', 0)
          store.dispatch(
            setFieldValue({
              key: 'apiRcmsDashboard.topDrawer.countTotal',
              value: countAll,
            }),
          )
          dispatch(
            setInitialTable({
              rows: prepareRow(
                results,
                drawerType,
                _.get(table, 'limit', '100'),
                realPage,
                countAll,
              ),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              hideFilter: true,
              isCheckBox: true,
              onDownload: (selected) =>
                dispatch(onDownload(selected, drawerType)),
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload = (selected, drawerType) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    dataType: drawerType,
    list: selected,
  }

  const typeName =
    drawerType === 'latestLicense'
      ? 'License ที่ส่งล่าสุด'
      : 'License ที่ส่งข้อมูลมากที่สุด'

  await dispatch(
    downloadFile({
      url: apiToRcmsDashboardLicenseDownload,
      body: body,
      fileName: `รายการ${typeName}.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

const prepareRow = (data, dataType, limit, page, countAll) => {
  return data.map((ele, index) => {
    const uuid =
      dataType == 'latestLicense'
        ? _.get(ele, 'license_uuid')
        : dataType == 'topLicense'
        ? _.get(ele, 'license_uuid')
        : ele.uuid
    const license =
      dataType == 'latestLicense'
        ? _.get(ele, 'licenseDescription')
        : dataType == 'topLicense'
        ? _.get(ele, 'licensename')
        : ele.name
    const percent =
      ele.totaltransactions > 0
        ? ((ele.totaltransactions / countAll) * 100).toFixed(2)
        : 0
    return {
      ...ele,
      license,
      count: `${ele.totaltransactions} (${percent}%)`,
      uuid: uuid,
      no: (page - 1) * limit + (index + 1),
    }
  })
}
