import React from 'react'
import _ from 'lodash'
import Autocomplete from '../../../components/Input/Autocomplete'
import { TextField } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'

const ColumnSelect = ({
  columnSelected = null,
  placeholder = 'เลือกคอลัมน์',
  labelText = 'คอลัมน์',
  hasTwoBoxes = false,
  options = [],
  handleChange = () => {},
}) => {
  const { columnName, columnNameTH } = useSelector(
    (state) => ({
      columnName: state.reportComp.columnName,
      columnNameTH: state.reportComp.columnNameTH,
    }),
    shallowEqual,
  )
  const columnNameKey = _.findKey(columnName, (col) => col === columnSelected)
  const displayValue = !_.isNull(columnSelected) ? columnSelected : ''
  const displayLabel = !_.isNull(columnSelected)
    ? columnNameTH[columnNameKey]
    : ''

  return (
    <Autocomplete
      boxSx={{
        width: hasTwoBoxes ? '50%' : '100%',
      }}
      id="column-selected"
      name="column-selected"
      labelText={labelText}
      placeholder={placeholder}
      options={options}
      defaultValue={{
        value: displayValue,
        label: displayLabel,
      }}
      isHideSort={true}
      renderInput={(params) => (
        <TextField placeholder="กรุณาเลือก" {...params} />
      )}
      handleChange={handleChange}
    />
  )
}

export default ColumnSelect
