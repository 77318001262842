export const validateRemark = (state, setState, submitDialog) => (dispatch) => {
  const remark = state.remark
  let isError = false
  if (remark.length > 3000) isError = true

  setState((prev) => ({
    ...prev,
    isError,
  }))

  !isError && dispatch(submitDialog(remark))
}
