import React from 'react'
import Button from '@mui/material/Button'
import { StyledHeaderContainer } from '../../Styled'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'

const Header = ({ onHistoryClick }) => {
  return (
    <StyledHeaderContainer>
      <Button
        startIcon={<HistoryTwoTone />}
        data-testid="btn-history"
        variant="outlined"
        component="label"
        size="m"
        onClick={() => onHistoryClick()}
      >
        ประวัติการเปลี่ยนแปลง
      </Button>
    </StyledHeaderContainer>
  )
}
export default Header
