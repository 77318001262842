import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { StyledColumnBox, StyledFooterBox, StyledRowBox } from './Styled'

const FooterContent = () => {
  const data = useSelector(
    (state) => state.manageContentELearningHome.data,
    shallowEqual
  )
  const createdDate = _.get(data, 'createdAt', '')
  const createdAt =
    createdDate != ''
      ? convertFormatDateTime({
        value: createdDate,
        type: 'date',
      })
      : '-'
  const updatedDate = _.get(data, 'updatedAt', '')
  const updatedAt =
    updatedDate != ''
      ? convertFormatDateTime({
        value: updatedDate,
        type: 'date',
      })
      : '-'
  const updatedBy = _.get(data, 'updatedBy', {})
  const createdBy = _.get(data, 'createdBy', {})
  const createdName = _.get(updatedBy, 'name', '')
  const updatedName = _.get(createdBy, 'name', '')

  return (
    <StyledFooterBox>
      <StyledColumnBox>
        <StyledRowBox>
          <Typography width={150}>วันที่สร้าง</Typography>
          <Typography color="text.lightGray">{createdAt}</Typography>
        </StyledRowBox>
        <StyledRowBox>
          <Typography width={150}>วันที่แก้ไขล่าสุด</Typography>
          <Typography color="text.lightGray">{updatedAt}</Typography>
        </StyledRowBox>
      </StyledColumnBox>
      <StyledColumnBox>
        <StyledRowBox>
          <Typography width={150}>สร้างโดย</Typography>
          <Typography color="text.lightGray">
            {createdName != '' ? createdName : '-'}
          </Typography>
        </StyledRowBox>
        <StyledRowBox>
          <Typography width={150}>แก้ไขล่าสุดโดย</Typography>
          <Typography color="text.lightGray">
            {updatedName != '' ? updatedName : '-'}
          </Typography>
        </StyledRowBox>
      </StyledColumnBox>
    </StyledFooterBox>
  )
}

export default FooterContent
