import React from 'react'
import _ from 'lodash'
import { resetForm } from '../../../../../redux/slices/manageClassLearner'
import { loadingDialog, openDialog } from '../../../../../redux/slices/dialog'
import { handleSuccess } from '../../../../../modules/ELearning/Course/Form/BatchTab/Learner/ReasonDialog/events'
import { putCourseBatchLearnerCancel } from '../../../../../services/eLearning/course/form'
import ReasonDialog from '../../../../../modules/ELearning/Course/Form/BatchTab/Learner/ReasonDialog'

export const handleIsCanDelete = (row) => {
  const status = _.get(row, 'status', '')
  return status !== 'CANCEL' && status !== 'CANCELED'
}

export const handleDeleteDialog = (uuid) => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: (
        <ReasonDialog
          title="ลบผู้เรียนออกจากรายการ"
          onSubmit={(remark) => dispatch(handleDelete(uuid, remark))}
        />
      ),
    }),
  )
}

export const handleDelete = (uuid, remark) => async (dispatch) => {
  dispatch(loadingDialog())
  const body = { uuid, remark }
  await dispatch(putCourseBatchLearnerCancel(body))
  dispatch(handleSuccess(true))
}
