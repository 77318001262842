import _ from 'lodash'

export const handleCourseData = (activeTab) => {
  const courses = _.get(activeTab, `[0].courses`, [])
  return courses
    .filter((item) => item.status)
    .map((course) => ({
      ...course,
      uuid: _.get(course, 'course.uuid', ''),
      trainingPlatform: _.get(
        course.trainingPlatform,
        '[0].trainingPlatform',
        '',
      ),
    }))
}

export const handleFindItem = (data, options) => {
  if (_.isEmpty(data) || data.courseCode === '' || data.id === '') {
    return { label: '', value: '' }
  }

  const findItem = _.find(
    options,
    (option) =>
      _.get(option, 'courseCode', option.id) ===
      _.get(data, 'courseCode', data.id),
  )
  return findItem
}
