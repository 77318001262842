import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import htcRgl from '../../utils/fonts/db-helvethaica-x-v3.2-webfont.woff'
import htcRgl2 from '../../utils/fonts/db-helvethaica-x-v3.2-webfont.woff2'

export const StyledEditorBox = styled(Box)(({ theme, textError }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .tox-statusbar': { display: 'none!important;' },
  '& .tox-tinymce': {
    borderColor: textError && textError != '' && theme?.palette?.error?.main,
  },
}))

export const contentStyle = `@font-face {
  font-family: 'Helvethaica';
  src:local('Helvethaica'), local('Helvethaica-Regular'), url(${htcRgl2}) format('woff2'),
      url(${htcRgl}) format('woff');
  font-weight: 400;
  font-style: normal;
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
body{font-family:Helvethaica;font-size:20px;color:#333}
.blank{text-align:center;padding:4px 14px;background:#ccc;width:40px;height:24px;border-radius:4px;}
.blank > em{font-style:normal!important}`
