import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { setLessonData } from '../../../../../../redux/slices/eLearning/preview'
import { StyledLink, StyledLinkBox } from './Styled'

const LinkToWebsite = ({ selectedLesson }) => {
  const link = _.get(selectedLesson, 'link', '')
  const dispatch = useDispatch()
  return (
    <StyledLinkBox>
      <Typography variant="h6">
        {_.get(selectedLesson, 'linkDescription', '')}
      </Typography>
      <StyledLink
        variant="h6"
        target="_blank"
        data-testid="link"
        href={link}
        onClick={() => dispatch(setLessonData({ key: 'progress', value: 100 }))}
      >
        คลิกที่นี่
      </StyledLink>
    </StyledLinkBox>
  )
}

export default LinkToWebsite
