import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { path } from './../../../../../../constants/path'
import { setReviewAnswer } from './../../../../../../redux/slices/eTestingPreview'
import { setStopModule } from './../../../../../../redux/slices/eLearning/preview'
import { StyledResultContent } from './../../../../../../components/Preview/Result/Styled'
import {
  handleCalculatePercent,
  handleCalculateScore,
  handleCalculateTotal,
} from './events'

const BannerShowResult = ({ isModule }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const testingPath = `${path.eTesting}/preview/testing/${uuid}`
  const { testing, setting } = useSelector(
    (state) => ({
      testing: state.eTestingPreview.testing,
      setting: state.eTestingPreview.setting,
    }),
    shallowEqual,
  )
  const { questionResult } = setting
  const isShowResult = _.get(questionResult, 'isOpen', false)
  const showResult = _.get(questionResult, 'result', 'LATER')
  const totalScore = useMemo(() => handleCalculateTotal(testing), [testing])
  const correctScore = useMemo(() => handleCalculateScore(testing), [testing])
  const correctPercent = useMemo(
    () => handleCalculatePercent(correctScore, totalScore),
    [correctScore],
  )
  const criteria = 0 // เกณฑ์ผ่าน
  const isPass = correctPercent >= criteria

  return (
    <>
      <StyledResultContent>
        {isPass ? (
          <>
            <img src="/icon/main_success.svg" />
            <Typography variant="h3" color={'success.main'}>
              ผ่านการทดสอบ
            </Typography>
          </>
        ) : (
          <>
            <img src="/icon/main_fail.svg" />
            <Typography variant="h3" color={'error'}>
              ไม่ผ่านการทดสอบ
            </Typography>
          </>
        )}

        <Typography variant="h5" color="text.secondary">
          คุณได้คะแนน
        </Typography>
        <Typography variant="h2" color={isPass ? 'success.main' : 'error'}>
          {correctScore} / {totalScore}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          ( ได้คะแนน {correctPercent}% : เกณฑ์ผ่าน {criteria}% )
        </Typography>
      </StyledResultContent>

      {isPass ? (
        isShowResult &&
        showResult == 'LATER' && (
          <Button
            data-testid="btn-result"
            variant="contained"
            size="l"
            sx={{ mb: 0.5 }}
            onClick={() => dispatch(setReviewAnswer())}
          >
            ดูเฉลย
          </Button>
        )
      ) : (
        <Button
          data-testid="btn-test"
          variant="contained"
          size="l"
          onClick={() =>
            isModule
              ? dispatch(setStopModule())
              : (window.location.href = testingPath)
          }
        >
          สอบใหม่อีกครั้ง
        </Button>
      )}
    </>
  )
}

export default BannerShowResult
