import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme, isHalf }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: isHalf ? theme.spacing(0.5) : theme.spacing(1),
  marginBottom: isHalf ? theme.spacing(0.5) : theme.spacing(1),
  padding: '0px 20px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
}))

export const BoxLeft = styled(Box)(({ isHalf }) => ({
  width: isHalf ? 200 : 400,
  display: 'flex',
  alignItems: 'flex-start',
}))

export const BoxRight = styled(Box)(({ theme, isHalf, status }) => ({
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  width: isHalf ? '100%' : '60%',
  maxWidth: isHalf ? '300px' : '',
  minWidth: isHalf && 100,
  display: 'flex',
  alignItems: status ? 'center' : 'flex-start',
  flexWrap: 'wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}))

export const StyledDot = styled(Box)(({ theme, status }) => ({
  width: 8,
  height: 8,
  borderRadius: '100%',
  backgroundColor:
    status == 'ACTIVE'
      ? theme?.palette?.success?.main
      : theme?.palette?.action?.disabled,
}))

export const BoxFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    marginLeft: '20px',
  },
}))

export const SubTitle = styled(Typography)(({ theme }) => ({
  padding: '24px 0px 0px 20px',
  [theme.breakpoints.down('md')]: {
    padding: '0px',
  },
}))
