import Typography from '@mui/material/Typography'
import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  ButtonSubmit,
  StyledCardHeader1,
  StyledCardHeader2,
  StyledCardHeader3,
  StyledCardSub1,
  StyledCardSub2,
  StyledCardSub3,
  Sub,
  TypographyTableHeader,
  TypographyTableHeader2,
} from './Styled'
import { handleSubmit } from './events'
import { validateEPermission } from '../../../../utils/lib'
import {
  AGENT_LICENSE_SETTING,
  PERMISSION,
} from '../../../../constants/permission/apiToRcms'

const HeaderSub = ({ id, fetchData }) => {
  const dispatch = useDispatch()
  const { license_status, rcms_status, license_detail } = useSelector(
    (state) => ({
      license_status: state.apiToRcms.license_status,
      rcms_status: state.apiToRcms.rcms_status,
      license_detail: state.apiToRcms.license_detail,
    }),
    shallowEqual
  )

  const hasEditPermission = validateEPermission({
    module: AGENT_LICENSE_SETTING,
    permission: PERMISSION.EDIT,
  })
  return (
    <Sub>
      <StyledCardSub1>
        <StyledCardHeader1>
          <TypographyTableHeader
            sx={{
              backgroundColor: 'rgba(2, 113, 128, 1)',
            }}
          >
            <Typography>สถานะ</Typography>
            <Typography>ใบอนุญาต</Typography>
          </TypographyTableHeader>
          <TypographyTableHeader2>
            <Typography variant="caption" color="text.primary">
              ให้
            </Typography>
            <Typography variant="body1b" color="text.primary">
              {_.get(license_status, 'YES', 0)}
            </Typography>
          </TypographyTableHeader2>
          <TypographyTableHeader2>
            <Typography variant="caption" color="text.primary">
              ไม่ให้
            </Typography>
            <Typography variant="body1b" color="text.primary">
              {_.get(license_status, 'NO', 0)}
            </Typography>
          </TypographyTableHeader2>
          <TypographyTableHeader2>
            <Typography
              variant="caption"
              color={`${
                Number(_.get(license_status, 'WAIT_STATUS', 0)) > 0
                  ? 'warning.main'
                  : 'text.primary'
              }`}
            >
              รอตรวจสอบ
            </Typography>
            <Typography
              variant="body1b"
              color={`${
                Number(_.get(license_status, 'WAIT_STATUS', 0)) > 0
                  ? 'warning.main'
                  : 'text.primary'
              }`}
            >
              {_.get(license_status, 'WAIT_STATUS', 0)}
            </Typography>
          </TypographyTableHeader2>
        </StyledCardHeader1>

        <StyledCardHeader2>
          <TypographyTableHeader
            sx={{
              backgroundColor: 'rgba(73, 77, 244, 1)',
            }}
          >
            <Typography>ส่งข้อมูล</Typography>
            <Typography>ไป RCMS</Typography>
          </TypographyTableHeader>
          <TypographyTableHeader2>
            <Typography variant="caption" color="text.primary">
              สำเร็จ
            </Typography>
            <Typography variant="body1b" color="text.primary">
              {_.get(rcms_status, 'SUCCESS', 0)}
            </Typography>
          </TypographyTableHeader2>
          <TypographyTableHeader2>
            <Typography variant="caption" color="text.primary">
              ไม่สำเร็จ
            </Typography>
            <Typography variant="body1b" color="text.primary">
              {_.get(rcms_status, 'FAIL', 0)}
            </Typography>
          </TypographyTableHeader2>
          <TypographyTableHeader2>
            <Typography variant="caption" color="text.primary">
              ไม่ต้องส่ง
            </Typography>
            <Typography variant="body1b" color="text.primary">
              {_.get(rcms_status, 'NOT_SEND', 0)}
            </Typography>
          </TypographyTableHeader2>
        </StyledCardHeader2>

        <StyledCardHeader3>
          <TypographyTableHeader2 sx={{ textAlign: 'left' }}>
            <Typography variant="caption" color="text.white">
              รอส่ง
            </Typography>
            <Typography variant="body1b" color="text.white">
              {_.get(rcms_status, 'WAIT_SEND', 0)}
            </Typography>
          </TypographyTableHeader2>
          <ButtonSubmit
            data-testid="btn-submit"
            onClick={() => dispatch(handleSubmit(id, fetchData))}
            disabled={
              (Number(_.get(license_status, 'WAIT_STATUS', 0)) > 0 ||
                Number(_.get(rcms_status, 'WAIT_SEND', 0)) === 0) &&
              hasEditPermission
            }
          >
            <Typography variant="button">ส่ง</Typography>
          </ButtonSubmit>
        </StyledCardHeader3>
      </StyledCardSub1>

      <StyledCardSub3>
        <StyledCardSub2>
          <Typography sx={{ width: 150 }} variant="body1" color="text.gray">
            ผู้เรียนที่ผ่านเกณฑ์
          </Typography>
          <Typography variant="body1" color="text.primary">
            {_.get(license_detail, 'total', '')} คน
          </Typography>
        </StyledCardSub2>
        <StyledCardSub2>
          <Typography sx={{ width: 150 }} variant="body1" color="text.gray">
            ชื่อใบอนุญาต
          </Typography>
          <Typography variant="body1" color="text.primary">
            {_.get(license_detail, 'description', '')}
          </Typography>
        </StyledCardSub2>
        <StyledCardSub2>
          <Typography sx={{ width: 150 }} variant="body1" color="text.gray">
            ประเภทใบอนุญาต
          </Typography>
          <Typography variant="body1" color="text.primary">
            {_.get(license_detail, 'category', '')}
          </Typography>
        </StyledCardSub2>
      </StyledCardSub3>
    </Sub>
  )
}

export default HeaderSub
