import React from 'react'
import Card from '@mui/material/Card'

import Questions from '../Questions'
import Footer from '../Footer'
import DragDropContent from './DragDropContent'
import AddContent from './AddContent'
import { BoxContent, BoxQuestions, BoxSubContent } from './Styled'

const Index = ({ props }) => {
  return (
    <>
      <Card
        sx={{
          ml: props.isSubQuestions ? 2 : 0,
          mt: props.isSubQuestions || !props.indexQuestion ? 0 : 3,
        }}
      >
        <BoxQuestions>
          <Questions props={props} />
        </BoxQuestions>
        <BoxContent>
          <BoxSubContent>
            <DragDropContent props={{ ...props, type: 'questions' }} />
            <AddContent props={{ ...props, type: 'questions' }} />
          </BoxSubContent>
          <BoxSubContent>
            <DragDropContent props={{ ...props, type: 'choices' }} />
            <AddContent props={{ ...props, type: 'choices' }} />
          </BoxSubContent>
        </BoxContent>
        <Footer props={props} />
      </Card>
    </>
  )
}
export default Index
