import React from 'react'
import Typography from '@mui/material/Typography'
import { responsiveText } from '../../../../../../components/Preview/Header/Styled'
import { convertFormatDateTime } from './events'
import { StyledRoundBox } from './Styled'

export const Round = () => {
  const date = convertFormatDateTime(new Date())
  return (
    <StyledRoundBox>
      <Typography
        variant={responsiveText('body1b', 'body2b', 'caption')}
        color="text.white"
      >
        {date} น.
      </Typography>
    </StyledRoundBox>
  )
}

export default Round
