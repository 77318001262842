import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningCourseList,
  manageClassCourseList,
  reportRawData,
} from '../../utils/apiPath'

export const getCourseList = createAsyncThunk(
  'report/getCourseList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageClassCourseList)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getELearningList = createAsyncThunk(
  'report/getELearningList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningCourseList)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadManageClassRawData = createAsyncThunk(
  'report/downloadManageClassRawData',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(reportRawData, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
