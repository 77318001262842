import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportEExamResultConst = {
  COLUMN_NAME: {
    DATE: 'date',
    EXAMINATION_FIELD: 'examinationField',
    FILED_NAME: 'fieldName',
    COUNT_ENROLL: 'countEnroll',
    COUNT_EXAMINEES: 'countExaminees',
    COUNT_PASS: 'countPass',
    COUNT_FAIL: 'countFail',
    COUNT_MISSED: 'countMissed',
    PERCENT_OF_EXAMINEES: 'percentOfExaminees',
    PERCENT_OF_PASS: 'percentOfPass',
    PERCENT_OF_FAIL: 'percentOfFail',
    PERCENT_OF_MISSED: 'percentOfMissed',
    START_DATE_REGISTER: 'startDateRegister',
    END_DATE_REGISTER: 'endDateRegister',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    COMPANY_UUID: 'companyUuid',
    REGION_UUID: 'regionUuid',
    ASSOCIATION_UUID: 'associationUuid',
    OTHER: 'other',
    STATUS: 'status',
    TIME_NAME: 'timeName',
    SEAT_AVAILABLE: 'seatAvailable',
  },
  COLUMN_NAME_TH: {
    DATE: 'วันที่สอบ',
    EXAMINATION_FIELD: 'ประเภทสนามสอบ',
    FILED_NAME: 'สนามสอบ',
    COUNT_ENROLL: 'จำนวนผู้สมัครสอบ',
    COUNT_EXAMINEES: 'จำนวนผู้เข้าสอบ',
    COUNT_PASS: 'ผ่าน',
    COUNT_FAIL: 'ไม่ผ่าน',
    COUNT_MISSED: 'ขาดสอบ',
    PERCENT_OF_EXAMINEES: '% จำนวนผู้เข้าสอบ',
    PERCENT_OF_PASS: '%ผ่าน',
    PERCENT_OF_FAIL: '%ไม่ผ่าน',
    PERCENT_OF_MISSED: '% ขาดสอบ',
    START_DATE_REGISTER: 'วันเริ่มต้นรับสมัคร',
    END_DATE_REGISTER: 'วันสิ้นสุดรับสมัคร',
    CREATED_AT: 'วันที่สร้าง',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    OTHER: 'สถานที่สอบอื่นๆ',
    STATUS: 'สถานะรอบสอบ',
    TIME_NAME: 'รอบสอบ',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  examinationFieldOptions: [
    {
      label: 'สนามสอบบริษัท',
      value: 'บริษัท',
    },
    {
      label: 'สนามสอบสมาคม',
      value: 'สมาคม',
    },
    {
      label: 'สนามสอบอื่นๆ',
      value: 'อื่นๆ',
    },
  ],
  statusOptions: [
    {
      label: 'รอเปิดรับสมัคร',
      value: 'รอเปิดรับสมัคร',
    },
    {
      label: 'เปิดรับสมัคร',
      value: 'เปิดรับสมัคร',
    },
    {
      label: 'ปิดรับสมัคร',
      value: 'ปิดรับสมัคร',
    },
    {
      label: 'ระหว่างสอบ',
      value: 'ระหว่างสอบ',
    },
    {
      label: 'จบการสอบ',
      value: 'จบการสอบ',
    },
    {
      label: 'เสร็จสมบูรณ์',
      value: 'เสร็จสมบูรณ์',
    },
    {
      label: 'ยกเลิกการสอบ',
      value: 'ยกเลิกการสอบ',
    },
  ],
  timeNameOptions: [
    {
      label: 'เช้า',
      value: 'เช้า',
    },
    {
      label: 'สาย',
      value: 'สาย',
    },
    {
      label: 'บ่าย',
      value: 'บ่าย',
    },
    {
      label: 'เย็น',
      value: 'เย็น',
    },
    {
      label: 'ค่ำ',
      value: 'ค่ำ',
    },
  ],
  seatAvailableOptions: [
    { label: 'ว่าง', value: true },
    { label: 'เต็ม', value: false },
  ],
}

export const reportEExamResultDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportEExamResultConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportEExamResultConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportEExamResultConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
    },
    {
      id: 2,
      name: reportEExamResultConst.COLUMN_NAME.FILED_NAME,
      value: reportEExamResultConst.COLUMN_NAME.FILED_NAME,
      label: reportEExamResultConst.COLUMN_NAME_TH.FILED_NAME,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportEExamResultConst.COLUMN_NAME.COUNT_ENROLL,
      value: reportEExamResultConst.COLUMN_NAME.COUNT_ENROLL,
      label: reportEExamResultConst.COLUMN_NAME_TH.COUNT_ENROLL,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportEExamResultConst.COLUMN_NAME.COUNT_MISSED,
      value: reportEExamResultConst.COLUMN_NAME.COUNT_MISSED,
      label: reportEExamResultConst.COLUMN_NAME_TH.COUNT_MISSED,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_MISSED,
      value: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_MISSED,
      label: reportEExamResultConst.COLUMN_NAME_TH.PERCENT_OF_MISSED,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportEExamResultConst.COLUMN_NAME.COUNT_EXAMINEES,
      value: reportEExamResultConst.COLUMN_NAME.COUNT_EXAMINEES,
      label: reportEExamResultConst.COLUMN_NAME_TH.COUNT_EXAMINEES,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_EXAMINEES,
      value: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_EXAMINEES,
      label: reportEExamResultConst.COLUMN_NAME_TH.PERCENT_OF_EXAMINEES,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportEExamResultConst.COLUMN_NAME.COUNT_PASS,
      value: reportEExamResultConst.COLUMN_NAME.COUNT_PASS,
      label: reportEExamResultConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_PASS,
      value: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_PASS,
      label: reportEExamResultConst.COLUMN_NAME_TH.PERCENT_OF_PASS,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportEExamResultConst.COLUMN_NAME.COUNT_FAIL,
      value: reportEExamResultConst.COLUMN_NAME.COUNT_FAIL,
      label: reportEExamResultConst.COLUMN_NAME_TH.COUNT_FAIL,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_FAIL,
      value: reportEExamResultConst.COLUMN_NAME.PERCENT_OF_FAIL,
      label: reportEExamResultConst.COLUMN_NAME_TH.PERCENT_OF_FAIL,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportEExamResultConst.COLUMN_NAME.DATE,
      value: reportEExamResultConst.COLUMN_NAME.DATE,
      label: reportEExamResultConst.COLUMN_NAME_TH.DATE,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 2,
      name: reportEExamResultConst.COLUMN_NAME.START_DATE_REGISTER,
      value: reportEExamResultConst.COLUMN_NAME.START_DATE_REGISTER,
      label: reportEExamResultConst.COLUMN_NAME_TH.START_DATE_REGISTER,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 3,
      name: reportEExamResultConst.COLUMN_NAME.END_DATE_REGISTER,
      value: reportEExamResultConst.COLUMN_NAME.END_DATE_REGISTER,
      label: reportEExamResultConst.COLUMN_NAME_TH.END_DATE_REGISTER,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportEExamResultConst.COLUMN_NAME.CREATED_AT,
      value: reportEExamResultConst.COLUMN_NAME.CREATED_AT,
      label: reportEExamResultConst.COLUMN_NAME_TH.CREATED_AT,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 5,
      name: reportEExamResultConst.COLUMN_NAME.UPDATED_AT,
      value: reportEExamResultConst.COLUMN_NAME.UPDATED_AT,
      label: reportEExamResultConst.COLUMN_NAME_TH.UPDATED_AT,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DATE_RANGE,
    },

    {
      id: 6,
      name: reportEExamResultConst.COLUMN_NAME.EXAMINATION_FIELD,
      value: reportEExamResultConst.COLUMN_NAME.EXAMINATION_FIELD,
      label: reportEExamResultConst.COLUMN_NAME_TH.EXAMINATION_FIELD,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamResultConst.examinationFieldOptions,
    },
    {
      id: 7,
      name: reportEExamResultConst.COLUMN_NAME.COMPANY_UUID,
      value: reportEExamResultConst.COLUMN_NAME.COMPANY_UUID,
      label: 'สนามสอบ บริษัท',
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamResultConst.COLUMN_NAME.COMPANY_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 8,
      name: reportEExamResultConst.COLUMN_NAME.REGION_UUID,
      value: reportEExamResultConst.COLUMN_NAME.REGION_UUID,
      label: 'ภาค',
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamResultConst.COLUMN_NAME.REGION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 9,
      name: reportEExamResultConst.COLUMN_NAME.ASSOCIATION_UUID,
      value: reportEExamResultConst.COLUMN_NAME.ASSOCIATION_UUID,
      label: 'สนามสอบ สมาคม',
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEExamResultConst.COLUMN_NAME.ASSOCIATION_UUID,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 10,
      name: reportEExamResultConst.COLUMN_NAME.OTHER,
      value: reportEExamResultConst.COLUMN_NAME.OTHER,
      label: reportEExamResultConst.COLUMN_NAME_TH.OTHER,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },

    {
      id: 11,
      name: reportEExamResultConst.COLUMN_NAME.STATUS,
      value: reportEExamResultConst.COLUMN_NAME.STATUS,
      label: reportEExamResultConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamResultConst.statusOptions,
    },
    {
      id: 12,
      name: reportEExamResultConst.COLUMN_NAME.TIME_NAME,
      value: reportEExamResultConst.COLUMN_NAME.TIME_NAME,
      label: reportEExamResultConst.COLUMN_NAME_TH.TIME_NAME,
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamResultConst.timeNameOptions,
    },
    {
      id: 13,
      name: reportEExamResultConst.COLUMN_NAME.SEAT_AVAILABLE,
      value: reportEExamResultConst.COLUMN_NAME.SEAT_AVAILABLE,
      label: 'ที่นั่ง',
      minWidth: 160,
      type: reportEExamResultConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportEExamResultConst.seatAvailableOptions,
    },
  ],
}
