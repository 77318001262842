import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'
import { getQueryParams } from '../../../utils/lib'
import dayjs from 'dayjs'

export const fetchSearchParamsRcms = () => async (dispatch) => {
  let startDate = getQueryParams('start_date')
  let endDate = getQueryParams('end_date')
  let status = getQueryParams('status')
  let courseCode = getQueryParams('courseCode')
  let platformLearning = getQueryParams('platformLearning')
  const filter = []
  let id = 1
  if (startDate && endDate) {
    filter.push({
      id: id,
      key: 'rcmsCreatedAt',
      label: `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(
        endDate,
      ).format('DD/MM/YYYY')}`,
      title: 'วันที่สร้าง',
      typeOfValue: 'DATE_RANGE',
      value: { startDate, endDate },
    })
    id++
  }
  if (status) {
    if (status === 'Failed') {
      filter.push({
        id: id,
        key: 'statusApi',
        label: 'ไม่สำเร็จ',
        title: 'สถานะการส่ง',
        typeOfValue: 'INPUT_TEXT',
        value: 'ไม่สำเร็จ',
      })
    } else {
      filter.push({
        id: id,
        key: 'rcmsFlag',
        label: status,
        title: 'Flag',
        typeOfValue: 'INPUT_TEXT',
        value: status,
      })
    }
    id++
  }
  if (courseCode) {
    filter.push({
      id: id,
      key: 'courseCode',
      label: courseCode,
      title: 'รหัสหลักสูตร',
      typeOfValue: 'INPUT_TEXT',
      value: courseCode,
    })
    id++
  }
  if (platformLearning) {
    if (platformLearning === 'classroom') {
      filter.push({
        id: id,
        key: 'platform',
        label: 'Classroom',
        title: 'Platform',
        typeOfValue: 'DROPDOWN_SINGLE',
        value: 'Classroom',
      })
    } else if (platformLearning === 'online') {
      filter.push({
        id: id,
        key: 'platform',
        label: 'Virtual Classroom',
        title: 'Platform',
        typeOfValue: 'DROPDOWN_SINGLE',
        value: 'Virtual Classroom',
      })
    } else {
      filter.push({
        id: id,
        key: 'platform',
        label: 'E-learning',
        title: 'Platform',
        typeOfValue: 'DROPDOWN_SINGLE',
        value: 'E-learning',
      })
    }
    id++
  }

  if (filter.length > 0) {
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: filter,
      }),
    )
    dispatch(handleSaveFilters())
  }
}
