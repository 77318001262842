import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import History from '@mui/icons-material/History'
import Close from '@mui/icons-material/Close'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useHistory } from 'react-router-dom'

import { convertFormatDateTime } from '../../../../../utils/lib'
import { typeTime } from '../../../../../constants/MasterData/form'
import HistoryDrawer from '../../../Setting/Form/Footer/History'
import { handleCourseName } from '../../../Setting/Form/events'
import { StyledColumn, StyledRow } from '../Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  StyledDateCard,
  StyledDateHeader,
  StyledSpeakerImg,
  StyledCircle,
  StyledBubble,
  BoxBubble,
  PrivateBox,
  StyledContentBox,
  StyledTypography,
  StyledRespRow,
  StyledResponsbility,
  StyledLabel,
} from './Styled'
import {
  handleCreateUpdate,
  filterDataHoliday,
  fetchManageClassData,
  handleDateTimeFormat,
  handleCreateRoomManagementText,
} from './events'
import { RenderTextEditor } from '../../../../../components/Editor/BasicEditor'
import { Expense } from '../../../../../modules/Expense'
import useLocalStorage from '@rehooks/local-storage'
import { getPermissionExpense } from '../../../../../modules/MonthlyPlan/Drawer/View/Index'

const ClassDetailDrawer = ({ isOpen, uuid, onCloseDrawer, isHoliday }) => {
  const [user] = useLocalStorage('user')
  const [isHistoryOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const url = `/manage-class/setting/${uuid}`
  const { manageClassData, isLoading } = useSelector((state) => ({
    manageClassData: state.manageClassForm.data,
    isLoading: state.manageClassForm.isLoading,
  }))
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const area = _.get(monthlyPlan, 'area', '')
  const dates = _.get(monthlyPlan, 'dates', [])
  const roomManagement = _.get(manageClassData, 'roomManagement', {})

  const courseName = useMemo(
    () => handleCourseName(manageClassData),
    [monthlyPlan],
  )
  const createUpdateDate = useMemo(
    () => handleCreateUpdate(manageClassData),
    [monthlyPlan],
  )
  const startRegisterDateTime = handleDateTimeFormat(
    _.get(manageClassData, 'startRegisterDateTime', new Date()),
  )
  const endRegisterDateTime = handleDateTimeFormat(
    _.get(manageClassData, 'endRegisterDateTime', new Date()),
  )
  const startDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'startDate', new Date()),
    type: 'date',
  })
  const finishDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'finishDate', new Date()),
    type: 'date',
  })

  useEffect(() => {
    dispatch(fetchManageClassData(isOpen, isHoliday, uuid))
  }, [isOpen, isHoliday])
  const dataHoliday = filterDataHoliday(manageClassData)

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent isLoading={isLoading}>
        <StyledHeadLabel>
          <Box id="header">
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <StyledCircle />
              <Typography variant="body1b">
                {isHoliday ? 'Holiday' : 'Training'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
              <IconButton
                data-testid="btn-close-drawer"
                component="span"
                onClick={() => history.push(url)}
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>

              <IconButton
                data-testid="btn-close-drawer"
                component="span"
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </StyledHeadLabel>
        {isHoliday && (
          <StyledContentBox>
            <StyledColumn>
              <Typography variant="h5">
                {_.get(dataHoliday, 'title', '-')}
              </Typography>
              <Typography variant="body1">
                {_.get(dataHoliday, 'data', '-')}
              </Typography>
            </StyledColumn>

            <StyledColumn sx={{ mt: 3 }}>
              <LabelAndData
                label="ประเภทวันหยุด"
                data={_.get(dataHoliday, 'subWorkType', '-')}
              />
              <LabelAndData
                label="หมายเหตุ"
                data={_.get(dataHoliday, 'remark', '-')}
              />
            </StyledColumn>

            <StyledColumn sx={{ mt: 3 }}>
              <LabelAndData
                label="วันที่สร้าง"
                data={_.get(dataHoliday, 'createdAt', '-')}
              />
              <LabelAndData
                label="สร้างโดย"
                data={_.get(dataHoliday, 'createdBy', '-')}
              />
              <LabelAndData
                label="วันที่แก้ไข"
                data={_.get(dataHoliday, 'updatedAt', '-')}
              />
              <LabelAndData
                label="แก้ไขโดย"
                data={_.get(dataHoliday, 'updatedBy', '-')}
              />
            </StyledColumn>
          </StyledContentBox>
        )}
        {!isHoliday && (
          <StyledContentBox sx={{ gap: 4 }}>
            <StyledColumn sx={{ gap: 2 }}>
              <Typography variant="h5" sx={{ wordWrap: 'break-word' }}>
                {_.get(manageClassData, 'course', '-')}
              </Typography>
              {_.get(manageClassData, 'publicClass', true) ? (
                <StyledColumn>
                  <StyledRespRow>
                    <Typography width={200}>วันที่จัดอบรม</Typography>
                    <Typography color="text.secondary">
                      {startDate + ' - ' + finishDate}
                    </Typography>
                  </StyledRespRow>
                  <StyledRespRow>
                    <Typography width={200}>วันรับสมัคร</Typography>
                    <Typography color="text.secondary">
                      {startRegisterDateTime
                        ? startRegisterDateTime + ' - ' + endRegisterDateTime
                        : '-'}
                    </Typography>
                  </StyledRespRow>
                </StyledColumn>
              ) : (
                <PrivateBox>
                  <LockOutlinedIcon sx={{ fontSize: 'inherit' }} />
                  <Typography variant="body2">Private Class</Typography>
                </PrivateBox>
              )}
            </StyledColumn>
            <StyledColumn sx={{ gap: 2 }}>
              {dates.map((item, index) => (
                <DateCard
                  key={item.uuid || index}
                  data={item}
                  roomManagement={roomManagement}
                />
              ))}
            </StyledColumn>
            <StyledColumn sx={{ gap: 1 }}>
              <Typography variant="body1b">แอดมินหลัก</Typography>
              <LabelAndData
                label="แอดมินหลัก"
                data={`${_.get(
                  manageClassData,
                  'assignNeeObj.position',
                  '',
                )} - ${_.get(
                  manageClassData,
                  'assignNeeObj.firstNameTH',
                  '',
                )} ${_.get(manageClassData, 'assignNeeObj.lastNameTH', '')}`}
              />
              <LabelAndData
                label="เบอร์โทรติดต่อ Admin หลัก"
                data={_.get(manageClassData, 'assignNeeObj.tel', '-')}
              />
            </StyledColumn>

            <StyledColumn sx={{ gap: 1 }}>
              <Typography variant="body1b">รายละเอียดคลาส</Typography>
              <LabelAndData label="หลักสูตร" data={courseName} />
              <LabelAndData
                label="ชื่อหลักสูตร (สำหรับ Learner)"
                data={
                  _.get(
                    manageClassData,
                    'monthlyPlan.trainingInfo.[0].course.nameForLearner',
                    '',
                  ) || '-'
                }
              />
              <LabelAndBubble
                label="รูปแบบการสอน"
                data={_.get(manageClassData, 'trainingPlatForm', [])}
              />
              <LabelAndData
                label="จำนวนคนที่คาดหวัง"
                data={_.get(monthlyPlan, 'expectNumberPeople', 0)}
              />
              <LabelAndData
                label="รูปแบบการจัดห้องเรียน"
                data={_.get(monthlyPlan, 'classroom.classroom', '-')}
              />
              <LabelAndData
                label="Department"
                data={_.get(area, 'department.department', '-')}
              />
              <LabelAndData
                label="Station"
                data={_.get(area, 'station.station', '-')}
              />
              <LabelAndData
                label="การเตรียมอาหาร Break / Lunch"
                data={monthlyPlan.break ? 'ต้องการ' : 'ไม่ต้องการ'}
              />
              <LabelAndData
                label="ค่าใช้จ่ายต่อหัว"
                data={`${_.get(monthlyPlan, 'cost', 0)}.00 บาท`}
              />
            </StyledColumn>
            <StyledColumn sx={{ gap: 1 }}>
              <Typography variant="body1b">รายละเอียดเพิ่มเติม</Typography>
              <RenderTextEditor
                labelText={_.get(manageClassData, 'detail', '-')}
              />
            </StyledColumn>
            <StyledColumn sx={{ gap: 1 }}>
              <Typography variant="body1b">ช่องทางติดต่อ</Typography>
              <Typography variant="body1">
                {_.get(manageClassData, 'contact', '-')}
              </Typography>
            </StyledColumn>
            <StyledColumn sx={{ position: 'relative', gap: 1 }}>
              <LabelAndData
                label="วันที่สร้าง"
                data={createUpdateDate.createdAt}
              />
              <LabelAndData
                label="สร้างโดย"
                data={_.get(manageClassData, 'createdBy', '-')}
              />
              <LabelAndData
                label="วันที่แก้ไข"
                data={createUpdateDate.updatedAt}
              />
              <LabelAndData
                label="แก้ไขโดย"
                data={_.get(manageClassData, 'updatedBy', '-')}
              />
              <IconButton
                data-testid="btn-history"
                sx={{ position: 'absolute', right: 0, bottom: 0 }}
                onClick={() => setOpen(true)}
              >
                <History />
              </IconButton>
            </StyledColumn>
          </StyledContentBox>
        )}
        {getPermissionExpense(user, _.get(area, 'station.uuid', '')) && (
          <Expense boxSx={{ mx: 3 }} monthlyPlan={monthlyPlan} />
        )}
      </StyledContent>
      <HistoryDrawer
        isOpen={isHistoryOpen}
        mClassUuid={uuid}
        onCloseDrawer={() => setOpen(false)}
      />
      <Button
        variant="contained"
        sx={{ margin: '24px' }}
        onClick={() => history.push(url)}
      >
        ไปหน้าจัดการคลาส
      </Button>
    </StyledDrawer>
  )
}

export default ClassDetailDrawer

export const DateCard = ({ data, roomManagement = null }) => {
  const date = convertFormatDateTime({
    value: _.get(data, 'date', ''),
    type: 'date',
  })

  const time = typeTime[_.get(data, 'typeTime', 'FULL')]
  const onlineRoom = _.get(data, 'onlineRoom', [])
  let meetingRoom = _.get(data, 'meetingRooms', [])
  const speakers = _.get(data, 'speakers', [])
  const adminStaffPlan = _.get(data, 'adminStaffPlan', [])
  const location = _.get(data, 'location', [])

  const meetingRoomManagement = _.filter(
    _.get(roomManagement, `roomManagementMeetingRoom`, []),
    (item) => {
      return item.bookingDate === _.get(data, 'date')
    },
  )

  const text = 'ห้อง'
  if (_.size(meetingRoomManagement) > 0) {
    meetingRoom = handleCreateRoomManagementText(meetingRoomManagement)
  }

  return (
    <StyledDateCard>
      <StyledDateHeader>
        <Typography variant="body1b">
          {date} {time} {_.get(data, 'startTime', '')} -{' '}
          {_.get(data, 'endTime', '')}
        </Typography>
        {location?.online ? (
          <StyledTypography>
            Link ห้อง{' '}
            {onlineRoom.map((item, index) => (
              <span key={index}>
                {item.link} (ความจุห้อง : {item.learnerNumber})
                {index < onlineRoom.length - 1 && ', '}
              </span>
            ))}
          </StyledTypography>
        ) : (
          <StyledTypography>
            {_.size(meetingRoomManagement) > 0 ? (
              <>{`${text} ${meetingRoom}`}</>
            ) : (
              <>
                {_.get(meetingRoom, '[0].roomName', '').includes('ห้อง')
                  ? ''
                  : 'ห้อง '}
                {meetingRoom.map((item, index) => (
                  <span key={index}>
                    {item.value == 'OTHERS'
                      ? _.get(data, 'meetingRoomOther', '-')
                      : item.roomName}
                    {index < meetingRoom.length - 1 && ', '}
                  </span>
                ))}
              </>
            )}
          </StyledTypography>
        )}
      </StyledDateHeader>
      {speakers.map((item, index) => (
        <SpeakerItem key={index} data={item} />
      ))}
      {adminStaffPlan.length > 0 && (
        <>
          <Divider sx={{ mx: 1 }} />
          <Typography sx={{ mx: 1 }} variant="body1b">
            Admin:{' '}
            {adminStaffPlan.map((item, index) => (
              <span key={index} style={{ fontSize: 18, fontWeight: 400 }}>
                {item.staff.firstNameTH} {item.staff.lastNameTH}
                {index < adminStaffPlan.length - 1 && ', '}
              </span>
            ))}
          </Typography>
        </>
      )}
    </StyledDateCard>
  )
}

export const SpeakerItem = ({ data }) => {
  const startTime = _.get(data, 'startTime', '-')
  const endTime = _.get(data, 'endTime', '-')
  const image = _.get(data, 'staff.image', '')
  const imgSrc = `${window.__env__.REACT_APP_API_URL}/file${image}`
  return (
    <StyledRow sx={{ p: 1, gap: 2, alignItems: 'center' }}>
      <StyledSpeakerImg src={imgSrc} />
      <StyledColumn>
        <Typography sx={{ lineHeight: '24px' }} variant="body1b">
          {_.get(data, 'staff.firstNameTH', '-')}{' '}
          {_.get(data, 'staff.lastNameTH', '-')}
        </Typography>
        <StyledLabel>
          <StyledResponsbility>
            {_.get(data, 'responsibility.responsibility', '-')}
          </StyledResponsbility>
          <Typography minWidth={83}>
            {startTime} - {endTime}
          </Typography>
        </StyledLabel>
      </StyledColumn>
    </StyledRow>
  )
}

export const LabelAndData = ({ label, data }) => {
  return (
    <StyledRespRow sx={{ gap: 2 }}>
      <Typography width={200}>{label}</Typography>
      <Typography
        color="text.secondary"
        sx={{ maxWidth: '360px', wordWrap: 'break-word' }}
      >
        {data}
      </Typography>
    </StyledRespRow>
  )
}

export const LabelAndBubble = ({ label, data }) => {
  const [height, setHeight] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const ref = useRef(null)
  const boxHeight = ref.current

  useEffect(() => {
    if (boxHeight?.clientHeight >= 80) setShowButton(true)
  }, [boxHeight])

  return (
    <StyledRespRow sx={{ gap: 2 }}>
      <Typography width={200}>{label}</Typography>
      <Box ref={ref}>
        <BoxBubble maxHeight={height ? 'auto' : '80px'}>
          {data.map((item, index) => (
            <StyledBubble key={index}>{item.trainingPlatform}</StyledBubble>
          ))}
        </BoxBubble>
        {showButton && (
          <Button onClick={() => setHeight(!height)}>
            {height ? 'ดูน้อยลง' : 'ดูมากขึ้น'}
            <KeyboardArrowDownIcon
              sx={{
                transform: `rotateZ(${height ? 180 : 360}deg)`,
                transition: (theme) =>
                  theme?.transitions?.create('transform', {
                    duration: theme?.transitions?.duration?.shortest,
                  }),
              }}
            />
          </Button>
        )}
      </Box>
    </StyledRespRow>
  )
}
