import {
  closeDialog,
  loadingDialog,
  openDialog,
  setEnableButton,
  setDisableButton,
} from '../../../../redux/slices/dialog'
import _ from 'lodash'
import { updateStudentStatus } from '../../../../services/eExamination'
import { fetchExaminerListFilter } from '../../ExaminerList/event'
import { store } from '../../../../App'
import { STUDENT_STATUS } from '../../../../constants/eExamination'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const resetFilter = (uuid) => (dispatch) => {
  const { table } = store.getState().table
  dispatch(
    fetchExaminerListFilter({
      uuid,
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      filterText: null,
      keyword: '',
    }),
  )
  dispatch(closeDialog())
}

export const handleSubmitEligibleExaminer =
  (uuid, selected, rows, annotation) => async (dispatch) => {
    dispatch(loadingDialog())

    const mapSelectedList = selected.map((item) => {
      return { uuid: item }
    })
    const intersecSelected = _.intersectionBy(rows, mapSelectedList, 'uuid')
    const checkStatus = _.filter(intersecSelected, (item) => {
      return item.statusStudent !== STUDENT_STATUS.WAITING_CHECK
    })

    if (_.size(checkStatus) > 0) {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message:
            'ไม่สามารถปรับสถานะได้ เนื่องจากเปลี่ยนได้แค่สถานะรอตรวจสอบเท่านั้น',
          handleConfirm: () => {
            dispatch(resetFilter(uuid))
          },
        }),
      )
    } else {
      const filterWaitingAnnounceStatus = intersecSelected.filter(
        (item) => item.statusStudent === STUDENT_STATUS.WAITING_CHECK,
      )
      const uuidWaitingAnnounceList = filterWaitingAnnounceStatus.map(
        (item) => {
          return item.uuid
        },
      )

      const body = {
        list: uuidWaitingAnnounceList,
        status: STUDENT_STATUS.PERMISSION_DENIED,
        schedule: uuid,
        annotation,
      }

      const response = await dispatch(updateStudentStatus(body))
      if (!response?.error) {
        dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ปรับสถานะเป็นไม่มีสิทธิ์สอบและส่งอีเมลเรียบร้อยแล้ว',
            handleConfirm: () => {
              dispatch(resetFilter(uuid))
            },
          }),
        )
      } else {
        dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message:
              'ไม่สามารถปรับสถานะได้ เนื่องจากระบบขัดข้อง โปรดลองใหม่อีกครั้ง',
            handleConfirm: () => {
              dispatch(resetFilter(uuid))
            },
          }),
        )
      }
    }
  }
