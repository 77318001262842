import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import Questions from '../Questions'
import DragDropAnswer from './DragDropAnswer'
import AddAnswer from './AddAnswer'
import Footer from '../Footer'

import { BoxQuestion } from '../Styled'

const Index = ({ props }) => {
  return (
    <>
      <Card
        sx={{
          ml: props.isSubQuestions ? 2 : 0,
          mt: props.isSubQuestions || !props.indexQuestion ? 0 : 2,
        }}
      >
        <BoxQuestion>
          <Questions props={props} />
        </BoxQuestion>
        <Box sx={{ m: 1, mt: 0 }}>
          <Box sx={{ ml: 3, pb: 1 }}>
            <Typography variant="body1b">ตัวเลือก</Typography>
          </Box>

          <DragDropAnswer props={props} />

          <AddAnswer props={props} />
          <Footer props={props} />
        </Box>
      </Card>
    </>
  )
}
export default Index
