import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState, initialValuePointForm } from './model'
import { DIALOG_TAB } from '../../../../modules/AgentProfile/View/Content/Point/model'
import { postAgentPoint } from '../../../../services/manage/agentProfile/agentProfileServices'

const agentProfile = createSlice({
  name: 'agentProfile',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoading2: (state) => {
      state.isLoading2 = true
    },
    stopLoading2: (state) => {
      state.isLoading2 = false
    },
    startLoading3: (state) => {
      state.isLoading3 = true
    },
    stopLoading3: (state) => {
      state.isLoading3 = false
    },
    setFetchData: (state, { payload }) => {
      state.initialState = payload
    },
    setPayloadKey: (state, { payload }) => {
      state.initialState[payload?.key] = payload?.data
    },
    setSearch: (state, { payload }) => {
      const { key, value } = payload
      state.search[key] = value
    },
    setClearSearch: (state) => {
      state.search = {
        courseSearch: '',
        examSearch: '',
        handleCourseSearch: '',
        handleExamSearch: '',
      }
    },
    handleSearch: (state, { payload }) => {
      const { key, value } = payload
      state.search[key] = value
    },
    setChangeInformation: (state, { payload }) => {
      const { key, value } = payload
      state.initialState[key] = value
    },
    setPagination: (state, { payload }) => {
      const { key, value } = payload
      state.pagination[key] = value
    },
    setPointDrawerOpen: (state, { payload }) => {
      state.pointDrawerOpen = payload
    },
    setPointDialog: (state, { payload }) => {
      const { key, value } = payload
      state.pointDialog[key] = value
    },
    openPointDialog: (state) => {
      state.pointDialog.open = true
      state.pointDialog.tab = DIALOG_TAB.MANAGE
      state.pointDialog.pointForm = initialValuePointForm
      state.pointDialog.remaining = state.initialState.point
      state.pointDialog.cart = []
      state.pointDialog.cost = 0
      state.pointDialog.keyword = ''
      state.pointDialog.isConfirmation = false
    },
    closePointDialog: (state) => {
      state.pointDialog.open = false
    },
    setPointForm: (state, { payload }) => {
      const { key, value } = payload
      state.pointDialog.pointForm[key] = value
    },
    startPointLoading: (state) => {
      state.pointDialog.loading = true
    },
    stopPointLoading: (state) => {
      state.pointDialog.loading = false
    },
    setQuantity: (state, { payload }) => {
      const { index, quantity } = payload
      const oldCost =
        state.pointDialog.cart[index].point *
        state.pointDialog.cart[index].quantity
      const newCost = state.pointDialog.cart[index].point * quantity

      state.pointDialog.cost = state.pointDialog.cost - oldCost + newCost
      state.pointDialog.remaining =
        state.initialState.point - state.pointDialog.cost
      state.pointDialog.cart[index].quantity = quantity
    },
    updateCart: (state, { payload }) => {
      const { cost, cart } = payload

      state.pointDialog.cost = state.pointDialog.cost + cost
      state.pointDialog.remaining =
        state.initialState.point - state.pointDialog.cost
      state.pointDialog.cart = cart
    },
    setNewPointRemain: (state) => {
      state.pointDialog.remaining =
        state.initialState.point - state.pointDialog.cost
    },
    resetUploadForm: (state) => {
      state.uploadDialog.agentType = ''
      state.uploadDialog.agentList = []
      state.uploadDialog.fileName = 'No File Chosen'
      state.uploadDialog.file = null
      state.uploadDialog.isSuccess = false
    },
    setAgentType: (state, { payload }) => {
      state.uploadDialog.agentType = payload
      state.uploadDialog.agentList = []
    },
    startUploadLoading: (state) => {
      state.uploadDialog.isLoading = true
    },
    stopUploadLoading: (state) => {
      state.uploadDialog.isLoading = false
    },
    setImportFile: (state, { payload }) => {
      const file = payload.target.files[0]
      const fileName = _.get(payload, 'target.files[0].name', 'No File Chosen')
      state.uploadDialog.fileName = fileName
      state.uploadDialog.file = file
    },
    setProcessAgent: (state, { payload }) => {
      state.uploadDialog.success = 0
      state.uploadDialog.processAgent = _.get(payload, 'processAgent')
      state.uploadDialog.total = _.get(payload, 'total')
    },
    setProcess: (state, { payload }) => {
      state.uploadDialog.success = payload
    },
    setUploadForm: (state, { payload }) => {
      state.uploadDialog.uploadResult = payload
    },
    setDownloading: (state, { payload }) => {
      state.uploadDialog.downloading = payload
    },
    setAgentPageType: (state, { payload }) => {
      state.agentPageType = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAgentPoint.fulfilled, (state) => {
        state.uploadDialog.isSuccess = true
      })
      .addCase(postAgentPoint.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoading2,
  stopLoading2,
  startLoading3,
  stopLoading3,
  setFetchData,
  setSearch,
  setClearSearch,
  handleSearch,
  setChangeInformation,
  setPagination,
  setPayloadKey,
  setPointDrawerOpen,
  setPointDialog,
  openPointDialog,
  closePointDialog,
  setPointForm,
  startPointLoading,
  stopPointLoading,
  setQuantity,
  updateCart,
  setNewPointRemain,
  resetUploadForm,
  setAgentType,
  startUploadLoading,
  stopUploadLoading,
  setImportFile,
  setProcessAgent,
  setProcess,
  setUploadForm,
  setDownloading,
  setAgentPageType,
} = agentProfile.actions

export default agentProfile.reducer
