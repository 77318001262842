import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  examinerDrawerEnum,
  rankingDrawerEnum,
  scheduleDrawerEnum,
} from '../enums/drawerEnum'
import { resetRowsPerPage, setSearchText } from '../../../../redux/slices/table'

export const handleClickOpenDrawer = (key) => {
  if (key === 'examinationField') {
    toggleExamDrawer(true)
  } else if (key === 'schedule') {
    toggleScheduleDrawer(true)
  } else if (key === 'rankingScheduleCompany') {
    toggleRankingDrawer(true, 'company', 'schedule')
  } else if (key === 'rankingExaminerCompany') {
    toggleRankingDrawer(true, 'company', 'examiner')
  } else if (key === 'rankingScheduleAssociation') {
    toggleRankingDrawer(true, 'association', 'schedule')
  } else if (key === 'rankingExaminerAssociation') {
    toggleRankingDrawer(true, 'association', 'examiner')
  } else if (key === 'examiner') {
    toggleExaminerDrawer(true)
  }
}

export const toggleExaminerDrawer = (value) => {
  const objValue = {
    ...examinerDrawerEnum,
    isOpen: value,
  }
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.examinerDrawer',
      value: objValue,
    }),
  )
  resetDrawerCheckbox('examinerDrawer', objValue)
  if (!value) {
    clearDrawerTable()
  }
}

export const toggleRankingDrawer = (value, examType = '', dataType = '') => {
  const objValue = {
    ...rankingDrawerEnum,
    isOpen: value,
    examType: examType,
    dataType: dataType,
  }
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.rankingDrawer',
      value: objValue,
    }),
  )
  resetDrawerCheckbox('rankingDrawer', objValue)
  if (!value) {
    clearDrawerTable()
  }
}

export const toggleExamDrawer = (value) => {
  const objValue = {
    isOpen: value,
    isCompany: true,
    isAssociation: true,
  }
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.examFieldDrawer',
      value: objValue,
    }),
  )
  resetDrawerCheckbox('examFieldDrawer', objValue)
  if (!value) {
    clearDrawerTable()
  }
}

export const toggleScheduleDrawer = (value) => {
  const objValue = {
    ...scheduleDrawerEnum,
    isOpen: value,
  }
  store.dispatch(
    setFieldValue({
      key: 'eExamDashboard.scheduleDrawer',
      value: objValue,
    }),
  )
  resetDrawerCheckbox('scheduleDrawer', objValue)
  if (!value) {
    clearDrawerTable()
  }
}

export const resetDrawerCheckbox = (key, objValue) => {
  store.dispatch(
    setFieldValue({
      key: `formData.eExamDashboard.${key}`,
      value: objValue,
    }),
  )
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `eExamDashboard.filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const clearDrawerTable = () => {
  store.dispatch(setSearchText(''))
  store.dispatch(resetRowsPerPage())
}
