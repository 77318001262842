import React, { useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { shallowEqual, useSelector } from 'react-redux'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import { useParams, useHistory } from 'react-router-dom'

import HistoryDrawer from '../../HistoryDrawer'

const Index = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const { setValues } = useFormikContext()
  const [isOpen, setIsOpen] = useState(false)

  const initialState = useSelector(
    (state) => state.masterDataLicenseForm.initialState,
    shallowEqual
  )

  return (
    <>
      <Card sx={{ my: 3 }}>
        <Box sx={{ display: 'flex', m: 3, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              size="m"
              data-testid="btn-submit"
              type="submit"
              sx={{ width: 85 }}
              onClick={() =>
                setValues({
                  information: initialState.information,
                  licenseConfig: initialState.licenseConfig,
                })
              }
            >
              <Typography variant="button">บันทึก</Typography>
            </Button>
            <Button
              variant="outlined"
              size="m"
              sx={{ width: 85 }}
              onClick={() => history.push('/master-data/license')}
            >
              <Typography variant="button">ยกเลิก</Typography>
            </Button>
          </Box>
          {uuid && (
            <Box sx={{ display: 'flex', ml: 'auto' }}>
              <Box>
                <Box sx={{ display: 'flex', width: 'auto' }}>
                  <Typography variant="caption" sx={{ width: 80 }}>
                    วันที่สร้าง
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.lightGray"
                    sx={{ width: 132 }}
                  >
                    {_.get(initialState, 'information.createdAt', '-')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', width: 'auto' }}>
                  <Typography variant="caption" sx={{ width: 80 }}>
                    สร้างโดย
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.lightGray"
                    sx={{ width: 132 }}
                  >
                    {_.get(initialState, 'information.createdBy', '-')}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', width: 'auto' }}>
                  <Typography variant="caption" sx={{ width: 100 }}>
                    วันที่แก้ไขล่าสุด
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.lightGray"
                    sx={{ width: 132 }}
                  >
                    {_.get(initialState, 'information.updatedAt', '-')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', width: 'auto' }}>
                  <Typography variant="caption" sx={{ width: 100 }}>
                    แก้ไขล่าสุดโดย
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.lightGray"
                    sx={{ width: 132 }}
                  >
                    {_.get(initialState, 'information.updatedBy', '-')}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <HistoryTwoTone
                  onClick={() => setIsOpen(true)}
                  color="action"
                  sx={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
      </Card>
    </>
  )
}

export default Index
