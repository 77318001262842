import _ from 'lodash'

export const handleCourseData = (activeTab, value) => {
  const courses = _.get(activeTab, `[${value - 1}].courses`, [])
  return courses
    .filter((item) => item.status)
    .map((course) => ({
      ...course,
      uuid: _.get(course, 'course.uuid', ''),
      trainingPlatform: _.get(
        course.trainingPlatform,
        '[0].trainingPlatform',
        ''
      ),
    }))
}
