import { Table } from '@mui/material'
import TableHeader from './TableHeader'
import TableBodyReport from './TableBodyReport'
import { useTheme } from '@mui/system'

const TableReport = () => {
  const theme = useTheme()

  return (
    <Table
      sx={{
        width: 'fit-content',
        borderRadius: 2,
        borderCollapse: 'separate',
        border: `1px solid ${theme.palette.blue.blueLine}`,
        '& .MuiTableCell-root': {
          py: 0.5,
          borderColor: '#EDEDED',
        },
      }}
    >
      <TableHeader />
      <TableBodyReport />
    </Table>
  )
}

export default TableReport
