import _ from 'lodash'
import {
  setDeleteOnlineRoom,
  setEditOnlineRoom,
} from '../../../../../../redux/slices/manageClassForm'

export const handleDelete = (props) => (dispatch) => {
  const { oIndex, setNewOnlineRoom } = props
  setNewOnlineRoom((roomVal) => {
    const newRoom = [...roomVal]
    newRoom.splice(oIndex, 1)
    return newRoom
  })
  dispatch(setDeleteOnlineRoom(props))
}

export const handleDefaultAdmin = (data) => {
  const adminStaff = _.get(data, 'adminStaffPlan', [])
  return adminStaff.map((item) => ({
    ...item,
    label: item.staff
      ? `${item.staff.firstNameTH} ${item.staff.lastNameTH}`
      : item.label,
    value: _.get(item, 'uuid', ''),
  }))
}

export const handleInputChange = (e, props) => (dispatch) => {
  const { oIndex, dIndex } = props
  const value = e.target.value
  const keyField = e.target.name
  dispatch(
    setEditOnlineRoom({
      oIndex,
      dIndex,
      keyField,
      value,
    })
  )
}

export const handleNumberInput = (e) => {
  const target = e.target
  const numVal = Math.abs(target.value)
  const isDot = e.nativeEvent.data == '.'

  if (!isDot) {
    target.value = numVal == 0 ? '' : target.value // min 1
  } else target.value = ''

  return target.value
}

export const handleAmountChange = (e, props) => (dispatch) => {
  const { oIndex, dIndex } = props
  const keyField = _.get(e, 'target.name', '')
  const strValue = _.get(e, 'target.value', '')
  const value = strValue == '' ? '' : Number(strValue)
  dispatch(setEditOnlineRoom({ oIndex, dIndex, keyField, value }))
}

export const handleMultiSelectChange =
  (list, keyField, props) => (dispatch) => {
    const { oIndex, dIndex } = props
    const value = list.map((item) => ({ staff: item.staff.uuid }))
    dispatch(setEditOnlineRoom({ oIndex, dIndex, keyField, value }))
  }
