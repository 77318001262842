import React, { useState, useEffect, useRef } from 'react'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import { Row } from '../../../../components/Layout/Styled'
import { handleDownloadChart } from '../handler/handleDownloadChart'

const RoomStatBarChart = ({
  dataSet,
  labels,
  height = '100%',
  eleId = 'roomStatBarChart',
  showXAxisLabel = false,
  showYAxisLabel = false,
  xAxisLabel = '',
  yAxisLabel = '',
  showPercent = false,
  maxData,
  hasZero,
  name,
}) => {
  const componentRef = useRef()
  const [isPrint, setIsPrint] = useState(false)
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }

    const ctx = document.getElementById(eleId)
    const bdColorPlugin = {
      id: 'bgColorArea',
      beforeDraw(chart) {
        const {
          ctx,
          chartArea: { bottom },
          scales: { xAxis },
        } = chart
        const categoryWidth =
          xAxis.getPixelForTick(2) - xAxis.getPixelForTick(1)
        ctx.save()
        ctx.fillStyle = '#EDF1F8'
        ctx.fillRect(0, 0, categoryWidth * 1, bottom)
        ctx.fillRect(categoryWidth * 2, 0, categoryWidth, bottom)
        ctx.fillRect(categoryWidth * 4, 0, categoryWidth, bottom)
        ctx.fillRect(categoryWidth * 6, 0, categoryWidth, bottom)
        ctx.restore()
      },
    }

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: dataSet?.map((set) => {
          return {
            ...set,
            barPercentage: 0.9,
            categoryPercentage: 0.9,
            data: set.data?.map((value) => {
              if (value === 0) {
                return 1
              } else if (hasZero) {
                return (value * 100) / maxData
              } else {
                return value
              }
            }),
          }
        }),
      },
      plugins: [ChartDataLabels, bdColorPlugin],
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 50,
          },
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                weight: 'bold',
              },
            },
          },
          yAxis: {
            display: false,
            min: 0,
            max: hasZero ? 100 : maxData,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            align: 'top',
            anchor: 'end',
            textAlign: 'center',
            formatter: (value, context) => {
              return context.dataset.inLineLabel[context.dataIndex]
            },
          },
        },
      },
    })
    setChart(newChart)
  }, [
    dataSet,
    labels,
    showXAxisLabel,
    showYAxisLabel,
    showPercent,
    xAxisLabel,
    yAxisLabel,
  ])

  return (
    <Card
      sx={{
        mx: 'auto',
        p: 2,
        width: '100%',
      }}
      ref={componentRef}
    >
      <Row px={3} sx={{ justifyContent: 'space-between' }}>
        <Row>
          <Typography variant="body2" color="text.secondary">
            ชื่อห้อง:{' '}
          </Typography>
          <Typography variant="body2b" color="text.secondary">
            {name}
          </Typography>
        </Row>
        <Button
          sx={{ visibility: isPrint ? 'hidden' : 'visible' }}
          startIcon={<FileDownloadOutlined />}
          onClick={async () => {
            if (isPrint) return
            setIsPrint(true)
            handleDownloadChart(componentRef, `${name}-chart.png`, setIsPrint)
          }}
        >
          <Typography>ดาวน์โหลด</Typography>
        </Button>
      </Row>
      <Box display="flex" height="100%" alignItems="end">
        {(showXAxisLabel || showYAxisLabel) && (
          <Box
            sx={{
              height: height,
              width: '50px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            {showYAxisLabel && (
              <Typography variant="body3b" textAlign="center">
                {yAxisLabel ?? ''}
              </Typography>
            )}
            <Box
              sx={{
                height: `90%`,
                minHeight: '50px',
                width: '1px',
                background: '#DBE4F1',
                margin: 'auto',
              }}
            ></Box>
            {showXAxisLabel && (
              <Typography variant="body3b" textAlign="center">
                {xAxisLabel ?? ''}
              </Typography>
            )}
          </Box>
        )}
        <Box sx={{ height: height, width: '100%' }}>
          <canvas id={eleId} />
        </Box>
      </Box>
    </Card>
  )
}

export default RoomStatBarChart
