import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { LIST_TAB, LIST_TAB_TH } from './model'
import { StyledTab } from './Styled'

const TabMenu = ({ tab, setListTab }) => {
  return (
    <StyledTab
      value={tab}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, value) => setListTab(value)}
    >
      <Tab
        value={LIST_TAB.eContent}
        label={
          <Typography variant="body1b" color="primary">
            {LIST_TAB_TH.eContent} ({LIST_TAB.eContent})
          </Typography>
        }
      />
      <Tab
        value={LIST_TAB.knowledgeBase}
        label={
          <Typography variant="body1b" color="primary">
            {LIST_TAB_TH.knowledgeBase} ({LIST_TAB.knowledgeBase})
          </Typography>
        }
      />
    </StyledTab>
  )
}
export default TabMenu
