import _ from 'lodash'
import { getExaminerListDetail } from '../../../services/eExamination'
import {
  startLoading,
  stopLoading,
  setExaminerDetail,
} from '../../../redux/slices/eExaminer/detail'

export const fetchExaminerDetail = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getExaminerListDetail({ uuid }))
  const results = _.get(response, 'payload.data', [])
  dispatch(setExaminerDetail(results))
  dispatch(stopLoading())
}
