import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { AssessmentCard, QuestionAssessmentCard } from './DisplayCard'
import { StyledContainer } from './Styled'

export const Questions = () => {
  const { displayQuestion, page, assessmentList } = useSelector(
    (state) => ({
      totalAssessments: state.eEvaluationPreview.totalAssessments,
      displayQuestion: state.eEvaluationPreview.setting.displayQuestion,
      page: state.eEvaluationPreview.page,
      assessmentList: state.eEvaluationPreview.assessmentList,
    }),
    shallowEqual
  )

  if (assessmentList.length > 0) {
    switch (displayQuestion) {
      case 'ALL':
        return (
          <StyledContainer>
            {assessmentList.map((assessment, index) => {
              if (assessment.keyField == 'questions') {
                return (
                  <QuestionAssessmentCard
                    key={index}
                    assessmentKey={assessment}
                  />
                )
              } else {
                return <AssessmentCard assessmentKey={assessment} key={index} />
              }
            })}
          </StyledContainer>
        )

      case 'ONE': {
        const assessment = assessmentList[page - 1]
        if (assessment.keyField == 'questions') {
          return (
            <StyledContainer>
              <QuestionAssessmentCard assessmentKey={assessment} isOne />
            </StyledContainer>
          )
        } else {
          return (
            <StyledContainer>
              <AssessmentCard assessmentKey={assessment} isOne />
            </StyledContainer>
          )
        }
      }

      default:
        return <></>
    }
  } else return <></>
}

export default Questions
