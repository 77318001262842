import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { setSelectedFile } from '../../../../../../redux/slices/eLearning/preview'
import { setSelectedLesson } from '../../../../../../redux/slices/eLearning/preview'
import { mediaType } from '../../../model'
import {
  StyledBoxText,
  StyledModuleLabel,
} from '../Styled'
import { handleDownloadFile } from '../events'
import CircularProgressWithLabel from './CircularProgressWithLabel'

const SubLabelForFile = ({ data, file, index }) => {
  const dispatch = useDispatch()
  const { type, selectedFile } = useSelector(
    (state) => ({
      type: state.eLearningPreview.selectedLesson.type,
      selectedFile: state.eLearningPreview.selectedLesson.selectedFile,
    }),
    shallowEqual,
  )
  const isFinished = _.get(file, 'isFinished', false)

  return (
    <StyledModuleLabel
      key={index}
      sx={{ ml: '46px', justifyContent: 'space-between' }}
      isSelected={selectedFile === index}
      onClick={() => {
        if (type !== mediaType.UPLOAD_FILE) dispatch(setSelectedLesson(data))
        dispatch(setSelectedFile(index))
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <CircularProgressWithLabel value={isFinished ? 100 : 0} />
        <StyledBoxText>
          <Typography variant="body2b" sx={{ wordBreak: 'break-word' }}>
            {_.get(file, 'displayName', _.get(file, 'oldFileName', ''))}
          </Typography>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <img src="/icon/ic_file.svg" />
            <Typography variant="body2" color="text.lightGray">
              ไฟล์
            </Typography>
          </Box>
        </StyledBoxText>
      </Box>
      {file.downloadable && (
        <IconButton onClick={() => dispatch(handleDownloadFile(file))}>
          <img src="/icon/ic_download.svg" />
        </IconButton>
      )}
    </StyledModuleLabel>
  )
}

export default SubLabelForFile
