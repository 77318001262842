import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { dialogType } from './model'
import {
  StyledCancelButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogMessage,
  StyledLoadingButton,
} from './Styled'
import _ from 'lodash'
import { closeDialog } from '../../../redux/slices/dialog2'

export const handleClick =
  (type, handleError, handleConfirm, data) => (dispatch) => {
    if (type === 'fail') {
      handleError && handleError()
      dispatch(dispatch(closeDialog()))
    } else {
      handleConfirm(data)
    }
  }

const PopUpDialog2 = () => {
  const dialog = useSelector((state) => state.dialog2)
  const {
    type,
    isOpen,
    isLoading,
    content,
    maxWidth,
    disableButton,
    handleConfirm,
    handleError,
    handleCancel,
    isCloseDialog,
    fullWidth,
    sx,
    dataField,
  } = dialog
  const data = useSelector((state) => _.get(state, dataField, ''), shallowEqual)
  const dispatch = useDispatch()

  return (
    type && (
      <Box>
        <StyledDialog
          fullWidth={fullWidth}
          maxWidth={maxWidth ?? 'normal'}
          open={isOpen}
          sx={sx}
          onClose={() => {
            if (isCloseDialog) {
              handleCancel && handleCancel()
              dispatch(dispatch(closeDialog()))
            }
          }}
        >
          {type !== 'mini' ? (
            <>
              <Box id="dialog-header">
                {_.get(dialog, 'icon', _.get(dialogType[type], 'icon', ''))}
                <Typography variant="h6">
                  {_.get(dialog, 'title', dialogType[type].title)}
                </Typography>
              </Box>
              {(type === 'content' || content) && <>{content}</>}
              {type !== 'content' && (
                <StyledDialogMessage
                  sx={{
                    mx: !_.get(
                      dialog,
                      'icon',
                      _.get(dialogType[type], 'icon', ''),
                    )
                      ? 2
                      : 6,
                  }}
                >
                  {_.get(dialog, 'message', dialogType[type].message)}
                </StyledDialogMessage>
              )}

              <StyledDialogActions>
                {!isLoading && dialogType[type].disagreeText && (
                  <StyledCancelButton
                    data-testid="btn-disagree"
                    onClick={() => {
                      handleCancel && handleCancel()
                      dispatch(dispatch(closeDialog()))
                    }}
                    color={_.get(
                      dialog,
                      'colorDisagreeText',
                      dialogType[type].color,
                    )}
                  >
                    {dialogType[type].disagreeText}
                  </StyledCancelButton>
                )}
                <StyledLoadingButton
                  data-testid="btn-agree"
                  loading={isLoading}
                  onClick={() =>
                    dispatch(
                      handleClick(type, handleError, handleConfirm, data),
                    )
                  }
                  color={_.get(
                    dialog,
                    'colorAgreeText',
                    dialogType[type].color,
                  )}
                  endIcon={<></>}
                  disabled={disableButton}
                >
                  {_.get(dialog, 'agreeText', dialogType[type].agreeText)}
                </StyledLoadingButton>
              </StyledDialogActions>
            </>
          ) : (
            content
          )}
        </StyledDialog>
      </Box>
    )
  )
}

export default PopUpDialog2
