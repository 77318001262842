import { Box } from '@mui/system'
import ViewString from './ViewString'
import { Typography } from '@mui/material'

export const DuoAnswer = ({ data, type, seq, openAll }) => {
  let firstBarPercentage = '0%'
  let secondBarPercentage = '0%'
  let firstBar = 0
  let secondBar = 0
  let AnsTitle = data?.title
  let reverseColor = false
  if (type === 'SEQUENCE') {
    firstBar = data?.subAnswerCount?.countCorrect
    secondBar = data?.subAnswerCount?.countIncorrect
  } else if (type === 'TRUE_FALSE') {
    if (data.isCorrectAnswer === false) {
      reverseColor = true
    }
    firstBar = data.countTrueAns
    secondBar = data.countFalseAns
  } else if (type === 'FILL_IN_BLANK_DRAG' || type === 'FILL_IN_BLANK_CHOICE') {
    firstBar = data.countTrueAns
    secondBar = data.countFalseAns
  } else if (type === 'MULTIPLE') {
    AnsTitle = undefined
    firstBar = data.correctAnswer
    secondBar = data.totalAnswer - data.correctAnswer
  }

  const totalAnswer = firstBar + secondBar
  if (totalAnswer !== 0) {
    firstBarPercentage =
      ((firstBar / totalAnswer) * 100).toFixed(2).replace(/[.,]00$/, '') + '%'
    secondBarPercentage =
      ((secondBar / totalAnswer) * 100).toFixed(2).replace(/[.,]00$/, '') + '%'
  }

  return (
    <Box sx={{ display: 'grid', gap: '12px' }}>
      {AnsTitle && (
        <Box sx={{ display: 'flex' }}>
          {seq && (
            <Typography variant="body1" fontWeight="bold">
              {seq}.{' '}
            </Typography>
          )}
          <ViewString
            value={AnsTitle}
            customSx={{
              width: '100%',
              fontWeight: 'bold',
              height: 'fit-content',
            }}
            openAll={openAll}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          width: '100%',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
      >
        <Typography
          color="#5F5F5F"
          sx={{ minWidth: type === 'TRUE_FALSE' ? '24px' : 'unset' }}
        >
          {type === 'TRUE_FALSE' ? 'ใช่' : 'จำนวนคนตอบถูก'}
        </Typography>
        <Box
          sx={{
            height: '42px',
            alignItems: 'center',
            width: firstBarPercentage,
            backgroundColor: reverseColor ? '#C91432' : '#1CC54E',
            borderRadius: '4px',
            '@media print': {
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        />
        <Typography sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}>
          {firstBar} ({firstBarPercentage})
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          width: '100%',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
      >
        <Typography color="#5F5F5F">
          {type === 'TRUE_FALSE' ? 'ไม่ใช่' : 'จำนวนคนตอบผิด'}
        </Typography>
        <Box
          sx={{
            height: '42px',
            alignItems: 'center',
            width: secondBarPercentage,
            backgroundColor: reverseColor ? '#1CC54E' : '#C91432',
            borderRadius: '4px',
            '@media print': {
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        />
        <Typography sx={{ p: '4px', margin: 'auto 0' }}>
          {secondBar} ({secondBarPercentage})
        </Typography>
      </Box>
    </Box>
  )
}
