import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { initialState } from '../../redux/slices/table/model'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
  StyledBoxTitle,
} from './Styled'
import { breadcrumbMeetingRoom } from './model'
import FilterDrawer from './FilterDrawer'
import ButtonAddMeetingRoom from './ButtonAddMeetingRoom'
import { getQueryParams } from '../../utils/lib'
import { defaultFilter } from './FilterDrawer/model'
import { setFilterTotal } from 'src/redux/slices/table'

const Index = () => {
  const { id: uuid } = useParams()
  const [isInitial, setInitial] = useState(true)
  const dispatch = useDispatch()
  const { table } = useSelector(
    (state) => ({
      table: state.table,
    }),
    shallowEqual,
  )
  const statusParam = getQueryParams('status')

  const { limit, order, page, sort, isLoading } = table.table
  const { filterProp, isFilterDrawer } = table.filter
  const manageProps = { uuid }
  const quickSeach = ''

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'fetch',
        table: initialState,
        quickSeach,
        manageProps,
        setInitial,
        page: 1,
        statusParam,
      }),
    )
    if (statusParam)
      dispatch(
        setFilterTotal({
          ...defaultFilter,
          statusChecked: statusParam === 'ACTIVE',
          status: {
            ...defaultFilter.status,
            activeChecked: statusParam === 'ACTIVE',
          },
        }),
      )
  }, [])

  useEffect(() => {
    if (isInitial) return
    dispatch(
      fetchDataList({
        method: 'fetch',
        table,
        quickSeach,
        manageProps,
        filterProp,
        page,
      }),
    )
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledBoxTitle>
              <Typography variant="h4">ห้องประชุม </Typography>
              <Typography variant="h4">(Meeting Room)</Typography>
            </StyledBoxTitle>
            <Breadcrumbs menuList={breadcrumbMeetingRoom(uuid)} />
          </Box>
          <ButtonAddMeetingRoom />
        </StyledHeader>

        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          manageProps={manageProps}
        />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
