import * as yup from 'yup'

export const schema = (type) =>
  yup.object({
    remark: yup
      .string()
      .required('กรุณาระบุข้อมูล')
      .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร'),

    dates: yup.array().test('test', 'test', (value) => {
      if (type === 'update') {
        return value
      } else {
        return true
      }
    }),
  })
