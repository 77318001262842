import React from 'react'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import _ from 'lodash'

const ActionMcDashboard = ({ row, rowIndex, minWidth, condition }) => {
  const statusParam = row.status ? `&STATUS=${row.status}` : ''
  const startDateParam = row.filterStartDate
    ? `&START=${row.filterStartDate}`
    : ''
  const endDateParam = row.filterEndDate ? `&END=${row.filterEndDate}` : ''
  const queryParams = `?Station=${row.station}${statusParam}${startDateParam}${endDateParam}`
  const viewPath = _.get(condition, 'viewPath', undefined)
  const viewUrl = !_.isUndefined(viewPath) ? `${viewPath}${queryParams}` : ''
  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => {
            window.open(viewUrl, '_blank')
          }}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionMcDashboard
