import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'

import {
  BoxContent,
  BoxLeft,
  BoxRight,
  BoxRight2,
  StyledDot,
} from './Styled.js'
import { formatDateDisplay, formatData } from '../../events.js'
import { formatPhoneNo, formatIdCard } from '../../../../../utils/lib'

export const BoxLabel = ({ labelLeft, labelRight, status }) => {
  return (
    <BoxContent>
      <BoxLeft>
        <Typography variant="body1" color="text.secondary">
          {labelLeft}
        </Typography>
      </BoxLeft>
      <BoxRight>
        {status && <StyledDot status={status} />}
        <Typography variant="body1">{_.defaultTo(labelRight, '-')}</Typography>
      </BoxRight>
    </BoxContent>
  )
}

export const BoxLabel2 = ({ labelLeft, labelRight, status }) => {
  return (
    <BoxContent>
      <BoxLeft>
        <Typography variant="body1" color="text.secondary">
          {labelLeft}
        </Typography>
      </BoxLeft>
      <BoxRight2>
        {status && <StyledDot status={status} />}
        <Typography variant="body1">{_.defaultTo(labelRight, '-')}</Typography>
      </BoxRight2>
    </BoxContent>
  )
}

const Index = ({ type }) => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual,
  )
  const status =
    _.startCase(_.camelCase(_.get(data, 'work.statusProspect', '-'))) ===
    'Active'
      ? 'Active'
      : 'Terminate'
  return (
    <>
      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลการทำงาน</Typography>
          {type === 'CS' ? (
            <>
              <BoxLabel2
                labelLeft="แผนก/ฝ่าย"
                labelRight={_.get(data, 'work.division', '-')}
              />
              <BoxLabel
                labelLeft="Broker Number"
                labelRight={_.get(data, 'work.unitNumber', '-')}
              />
              <BoxLabel
                labelLeft="ชื่อบริษัทโบรกเกอร์"
                labelRight={_.get(data, 'work.unitName', '-')}
              />
              <BoxLabel
                labelLeft="สถานะการทำงาน"
                labelRight={status}
                status={_.get(data, 'work.statusProspect', '-')}
              />
              <BoxLabel
                labelLeft="การให้เข้าถึงข้อมูล"
                labelRight={
                  _.get(data, 'work.access', '')
                    ? `ยินยอม  (${_.get(data, 'work.access', '')})`
                    : '-'
                }
                status={_.get(data, 'work.access', '') ? 'ACTIVE' : ''}
              />
            </>
          ) : (
            <>
              <BoxLabel
                labelLeft="Unit Number"
                labelRight={_.get(data, 'work.unitNumber', '-')}
              />
              <BoxLabel
                labelLeft="ชื่อ Unit"
                labelRight={_.get(data, 'work.unitName', '-')}
              />
              <BoxLabel
                labelLeft="สถานะตัวแทน"
                labelRight={_.get(data, 'work.statusProspect', '-')}
              />
              <BoxLabel
                labelLeft="สถานะบัญชี"
                labelRight={
                  _.get(data, 'work.status', '')
                    ? formatData(`${_.get(data, 'work.status', '')}`)
                    : '-'
                }
                status={_.get(data, 'work.status', '')}
              />
              <BoxLabel
                labelLeft="การให้เข้าถึงข้อมูล"
                labelRight={
                  _.get(data, 'work.access', '')
                    ? `ยินยอม  (${_.get(data, 'work.access', '')})`
                    : '-'
                }
                status={_.get(data, 'work.access', '') ? 'ACTIVE' : ''}
              />
            </>
          )}
        </Box>
      </Card>

      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลติดต่อ</Typography>
          <BoxLabel
            labelLeft="เบอร์โทรศัพท์"
            labelRight={formatPhoneNo(_.get(data, 'contact.mobileNo', '-'))}
          />
          <BoxLabel
            labelLeft="อีเมล"
            labelRight={_.get(data, 'contact.email', '-')}
          />
        </Box>
      </Card>

      <Card>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
          <BoxLabel
            labelLeft="วัน/เดือน/ปี เกิด"
            labelRight={formatDateDisplay(
              _.get(data, 'info2.dateOfBirth', '-'),
            )}
          />
          <BoxLabel
            labelLeft="เลขบัตรประชาชน"
            labelRight={formatIdCard(_.get(data, 'info2.idCardNo', '-'))}
          />
          <BoxLabel
            labelLeft="เพศ"
            labelRight={_.get(data, 'info2.gender', '-')}
          />
          <BoxLabel
            labelLeft="รหัสไปรษณีย์"
            labelRight={_.get(data, 'info2.zipCode', '-')}
          />
          <BoxLabel
            labelLeft="จังหวัด"
            labelRight={_.get(data, 'info2.province', '-')}
          />
          <BoxLabel
            labelLeft="อำเภอ/เขต"
            labelRight={_.get(data, 'info2.amphure', '-')}
          />
          <BoxLabel
            labelLeft="แขวง/ตำบล"
            labelRight={_.get(data, 'info2.tambon', '-')}
          />
          <BoxLabel
            labelLeft="รายละเอียดที่อยู่"
            labelRight={_.get(data, 'info2.address', '-')}
          />
        </Box>
      </Card>
    </>
  )
}

export default Index
