import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'ชื่อหลักสูตร EN',
    nameCheckBox: 'courseChecked',
    nameInput: 'course',
    type: 'select_dropdown',
    placeholder: 'เลือกหลักสูตร',
  },
  {
    label: 'Platform Learning',
    nameCheckBox: 'platformLearningChecked',
    nameInput: 'platformLearning',
    type: 'select_dropdown',
    placeholder: 'เลือก Platform',
  },
]

export const defaultFilter = {
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  displayDate: true,
  courseChecked: false,
  course: '',
  platformLearningChecked: false,
  platformLearning: '',
}
