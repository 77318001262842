import { contentTypeEnum } from '../enum/contentTypEnum'

export const contentTypeLink = {
  content: {
    link: '',
    isHasDetail: 'INACTIVE',
    detail: '',
  },
  type: contentTypeEnum.LINK.key,
}
