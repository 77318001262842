import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const CancelVideo = styled(IconButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  backgroundColor: theme?.palette?.error?.main,
  color: theme?.palette?.primary?.contrast,
  position: 'absolute',
  top: '-10px',
  right: -10,
  '&:hover': {
    backgroundColor: theme?.palette?.error?.main,
  },
}))

export const BoxVideo = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  width: '150px',
  height: '100px',
  position: 'relative',
  background:
    'linear-gradient(39.84deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 47.09%)',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
}))
