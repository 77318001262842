import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../../handler/handleClearFilter'
import dayjs from 'dayjs'

export const FilterBox = () => {
  const { eEvaluationDashboard } = useSelector(
    (state) => ({
      eEvaluationDashboard: state.crud.eEvaluationDashboard,
    }),
    shallowEqual,
  )

  const filter = eEvaluationDashboard?.filtersDrawer
  const isFilterDate = filter?.isFilterDate ?? ''
  const isFilterUserResponseDate = filter?.isFilterUserResponseDate ?? ''

  const startDateFilter = isFilterDate
    ? dayjs(filter.dateFilterStartDate).format('YYYY-MM-DD')
    : isFilterUserResponseDate
    ? ''
    : dayjs().startOf('month').format('YYYY-MM-DD')
  const endDateFilter = isFilterDate
    ? dayjs(filter.dateFilterFinishDate).format('YYYY-MM-DD')
    : isFilterUserResponseDate
    ? ''
    : dayjs().format('YYYY-MM-DD')

  const userResponseDateStart = isFilterUserResponseDate
    ? dayjs(filter?.userResponseDateStart).format('YYYY-MM-DD')
    : ''
  const userResponseDateEnd = isFilterUserResponseDate
    ? dayjs(filter?.userResponseDateEnd).format('YYYY-MM-DD')
    : ''

  const hasFilter =
    isFilterDate ||
    isFilterUserResponseDate ||
    (startDateFilter && endDateFilter)

  return (
    <>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่เริ่มต้นหลักสูตร:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
          {userResponseDateStart && userResponseDateEnd && (
            <FilterStyledBox>
              วันที่ทำแบบประเมิน:{' '}
              <strong>
                {getFormatDate(userResponseDateStart)} -{' '}
                {getFormatDate(userResponseDateEnd)}
              </strong>
              {isFilterUserResponseDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('userResponseDate')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
