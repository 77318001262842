import _ from 'lodash'

export const handleTime = (detail) => {
  const totalHour = Number(_.get(detail, 'totalHour', 0))
  const totalMinute = Number(_.get(detail, 'totalMinute', 0))
  if (totalHour > 0 && totalMinute > 0) {
    return `${totalHour} ชั่วโมง ${totalMinute} นาที`
  }
  if (totalHour > 0) return `${totalHour} ชั่วโมง`
  if (totalMinute > 0) return `${totalMinute} นาที`
}

export const formatNumber = (value) => {
  return Number(value).toLocaleString()
}