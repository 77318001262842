import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setReduxObjectValue } from '../../../../../redux/slices/roomManagement/Dashboard'

export const TextFieldTopic = () => {
  const dispatch = useDispatch()
  const { filterTopicQuery } = useSelector(
    (state) => state.roomManagement.drawerCalendarFilter,
    shallowEqual,
  )
  return (
    <TextFieldInput
      type="string"
      value={filterTopicQuery}
      onChange={(e) =>
        dispatch(
          setReduxObjectValue({
            objectKey: 'drawerCalendarFilter',
            key: 'filterTopicQuery',
            value: e.target.value,
          }),
        )
      }
    />
  )
}
