import _ from 'lodash'

export const convertFormatTime = (data, isShort) => {
  const hours = _.get(data, 'totalHour', 0)
  const minutes = _.get(data, 'totalMinute', 0)
  const hourText = isShort ? 'ชม.' : 'ชั่วโมง'

  if (hours > 0 && minutes > 0) return `${hours} ${hourText} ${minutes} นาที`
  if (hours === 0) return `${minutes} นาที`
  if (minutes === 0) return `${hours} ${hourText}`
}
