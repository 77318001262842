import * as yup from 'yup'

export const schema = yup.object().shape({
  approve: yup.array(),
  department: yup.object().shape({
    value: yup.string().required(''),
  }),
  station: yup.string().required(''),
  purpose: yup.string().max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
  speakerBorrowResponsibility: yup.array().of(
    yup.object().shape({
      responsibility: yup.object().shape({
        uuid: yup.string().required('กรุณาเลือกหน้าที่ในช่องนี้'),
      }),
      numSpeaker: yup
        .number()
        .required('กรุณากรอกจำนวนวิทยากรที่ต้องการในช่องนี้'),
      skillSpeaker: yup
        .string()
        .required('กรุณากรอก Skill ที่ต้องการในช่องนี้')
        .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
      startTime: yup.string().required('กรุณาเลือกเวลาเริ่มในช่องนี้'),
      endTime: yup.string().required('กรุณาเลือกเวลาสิ้นสุดในช่องนี้'),
      remark: yup.string().max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
    })
  ),
})
