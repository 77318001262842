import _ from 'lodash'
import { setChangeQuestionByKey } from '../../../../../../../redux/slices/eTestingForm'
import { blankComp } from '../../Drag'

export const handleChange = (props) => (dispatch) => {
  const { keyQuestion, indexMainAnswer, indexAnswer, value } = props
  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: `answers[${indexMainAnswer}].subAnswers[${indexAnswer}].title`,
      value,
    })
  )
}

export const handleDeleteFillInBlank = (props) => (dispatch) => {
  const { question, keyQuestion, indexMainAnswer, indexAnswer, answers } = props
  const subTitle = _.get(question, 'subTitle', '')

  if (answers.length > 1) {
    const newSubAnswers = [...answers]
    newSubAnswers.splice(indexAnswer, 1)
    dispatch(
      setChangeQuestionByKey({
        keyQuestion,
        key: `answers[${indexMainAnswer}].subAnswers`,
        value: newSubAnswers,
      })
    )
  } else {
    dispatch(
      setChangeQuestionByKey({
        keyQuestion,
        key: 'subTitle',
        value: subTitle.replace(blankComp(indexMainAnswer), ''),
      })
    )
  }
}
