import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../../modules/ELearning/components/LoadingPageCircular'
import { StyledFormWrapper } from './Styled'
import Header from './Header'
import TabMenu from './TabMenu'
import { CollectionTab } from './CollectionTab'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import _ from 'lodash'
import { setFieldValue } from '../../../../redux/slices/crud'
import { getBodyMutation } from './mutation/get'
import { handleSubmitClick } from '../../../../components/CRUD/handler/handleSubmitClick'
import { postBodyMutation } from './mutation/post'
import { validationSchema } from './schema/schema'
import SettingTab from './SettingTab'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { initialForm } from './Handler/initialForm'
import { setupLevel } from './Handler/setupLevel'

export const ELearningCollectionForm = () => {
  const { uuid } = useParams()
  const { crudConfig, isLoading, tab } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
      tab: state.crud.tab,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const initializeCrud = async () => {
    await initialForm()
    if (uuid && !_.isUndefined(crudConfig)) {
      await fetchDetail(uuid, getBodyMutation)
      setupLevel()
    } else if (!uuid && !_.isUndefined(crudConfig)) {
      dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
    } else {
      initialConfig(initialCrudConfig)
    }
  }

  useEffect(initializeCrud, [crudConfig])

  useEffect(() => {
    if (tab !== 'collection' && tab !== 'setting') {
      dispatch(setFieldValue({ key: 'tab', value: 'collection' }))
    }
  }, [tab])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  const initialValues = {
    name: '',
    setting: [{ tag: [], distribution: [] }],
    productType: [],
    dueDate: null,
    contents: [],
  }

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={() =>
            handleSubmitClick(uuid, postBodyMutation, validationSchema)
          }
        >
          <Form>
            <Header />
            <TabMenu tab={tab} />
            <FormContent />
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export const FormContent = () => {
  let { tab } = useSelector(
    (state) => ({
      tab: state.crud.tab,
    }),
    shallowEqual,
  )

  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'collection' && <CollectionTab />}
      {tab === 'setting' && <SettingTab />}
    </StyledFormWrapper>
  )
}
