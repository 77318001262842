import _ from 'lodash'

export const handleGetLearners = (state, data) => {
  const learnerList = _.get(data, 'learnerList', _.get(data, 'userList', []))
  state.learners.learnerAll = learnerList
  state.learners.learnerWait = learnerList.filter(
    (item) => item.status === 'WAITING'
  )
  state.learners.learnerPass = learnerList.filter(
    (item) => item.learnerStatus === 'PASS' || item.userStatus === 'PASS'
  )
  state.learners.learnerFail = learnerList.filter(
    (item) => item.learnerStatus === 'NOT_PASS' || item.userStatus === 'NOT_PASS'
  )
  state.learners.learnerNotSent = learnerList.filter(
    (item) => item.status === 'NOT_SENT'
  )
}

export const getLearnerList = (state) => {
  let learnerList
  switch (state.tab) {
    case 'ALL':
      learnerList = state.learners.learnerAll
      break
    case 'WAITING':
      learnerList = state.learners.learnerWait
      break
    case 'PASS':
      learnerList = state.learners.learnerPass
      break
    case 'FAIL':
      learnerList = state.learners.learnerFail
      break
    case 'NOT_SENT':
      learnerList = state.learners.learnerNotSent
      break
    default:
      learnerList = state.learners.learnerAll
      break
  }

  return learnerList
}

export const handleUpdateSuccess = (state, payload) => {
  const data = _.get(payload, 'data', '')
  _.set(
    state.learnerExam,
    'learnerTotalScore',
    _.get(data, 'learnerTotalScore', 0)
  )
  _.set(
    state.learnerExam,
    'testingTotalScore',
    _.get(data, 'testingTotalScore', 0)
  )
  _.set(
    state.learnerExam,
    'testStatusText',
    _.get(data, 'testStatusText', null)
  )
  _.set(
    state.learnerExam,
    'isAnnounceText',
    _.get(data, 'isAnnounceText', null)
  )

  const notCheckedCount = _.get(data, 'notCheckedAnswerCount', 0)
  _.set(state, 'isComplete', notCheckedCount === 0)

  const learnerAnswers = _.get(state.learnerExam, 'learnerAnswers', [])
  const answerIndex = learnerAnswers.findIndex(
    (item) => item.questionUuid === _.get(data, 'questionUuid', '')
  )
  _.set(state.learnerExam, `learnerAnswers[${answerIndex}].isChecked`, true)
  _.set(state, 'learnerAnswers', learnerAnswers)
}
