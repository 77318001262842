import DatePicker from '../../components/Input/DatePicker'
import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../components/Input/Autocomplete'
import Select from '../../components/Input/Select'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import {
  disableButton,
  fetchManageClassCourseList,
  fetchManageClassRawData,
  findCourseCode,
  handleChange,
} from './events'
import { BoxCard, LoadingPage } from './Styled'
import { resetData } from '../../redux/slices/report'
import _ from 'lodash'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { courseTypeOption } from './model'

const ReportPage = () => {
  const manageReport = useSelector((state) => state.manageReport, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetData())
  }, [])

  const myDefaultOption = { label: 'ALL', value: 'ALL' }
  const options = [myDefaultOption, ...manageReport.courseList]
  const _filterOptions = createFilterOptions()
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state)

    if (!results.includes(myDefaultOption)) {
      results.unshift(myDefaultOption)
    }

    return results
  }

  return (
    <BoxCard>
      <Box sx={{ m: 3 }}>
        <Box
          sx={{
            opacity: manageReport.isLoading ? 0.4 : 'unset',
            pointerEvents: manageReport.isLoading ? 'none' : 'unset',
          }}
        >
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="วันเริ่มต้น"
            placeholder="เลือกวันที่"
            value={manageReport.startDate}
            onChange={(value) => {
              dispatch(
                handleChange({
                  key: 'startDate',
                  value: dayjs(value).isValid()
                    ? dayjs(value).format('YYYY-MM-DD')
                    : null,
                }),
              )
            }}
          />

          <DatePicker
            id="startDate"
            name="startDate"
            labelText="วันสิ้นสุด"
            placeholder="เลือกวันที่"
            disabledInput={_.isEmpty(manageReport.startDate)}
            value={manageReport.finishDate}
            onChange={(value) => {
              dispatch(
                handleChange({
                  key: 'finishDate',
                  value: dayjs(value).isValid()
                    ? dayjs(value).format('YYYY-MM-DD')
                    : null,
                }),
              )
            }}
          />

          <Select
            required
            dataTestid="select-minutes"
            id="courseType"
            name="courseType"
            labelText="ประเภทหลักสูตร"
            placeholder="กรุณาเลือก"
            type="basic"
            value={manageReport.courseType}
            options={courseTypeOption}
            disabled={
              _.isEmpty(manageReport.startDate) ||
              _.isEmpty(manageReport.finishDate)
            }
            handleChange={(e) => {
              const value = e.target.value
              dispatch(handleChange({ key: 'courseType', value }))
              dispatch(handleChange({ key: 'courseCode', value: '' }))
              dispatch(fetchManageClassCourseList(value))
            }}
          />

          <Autocomplete
            id="course"
            name="course"
            labelText="หลักสูตร"
            disabled={_.isEmpty(manageReport.courseType)}
            isHideSort
            filterOptions={filterOptions}
            options={options}
            defaultValue={findCourseCode(
              manageReport.courseList,
              manageReport.courseCode,
            )}
            renderInput={(params) => (
              <TextField placeholder={'กรุณาเลือก'} {...params} />
            )}
            handleChange={(e, element) =>
              dispatch(
                handleChange({
                  key: 'courseCode',
                  value: _.get(element, 'value', ''),
                }),
              )
            }
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-testid="btn-download"
              sx={{
                width: 120,
              }}
              variant="contained"
              onClick={() => {
                dispatch(fetchManageClassRawData(manageReport))
              }}
              disabled={disableButton(manageReport)}
            >
              Download
            </Button>
          </Box>
        </Box>
        <LoadingPage isLoading={manageReport.isLoading} />
      </Box>
    </BoxCard>
  )
}

export default ReportPage
