import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '../../../../components/Calendar/Calendar.css'
import { fetchDataCalendar, convertMonth } from './events'
import { BoxContentMian } from '../Styled'

const Calendar = ({ calendarRef, handleEventDrawer }) => {
  const dispatch = useDispatch()

  const uuidStation = useSelector((state) => state.manageClass.station)
  const { initialState, month, year, isTest } = useSelector(
    (state) => ({
      initialState: state.manageClassCalendar.initialState,
      // isLoading: state.manageClassCalendar.isLoading,
      month: state.manageClassCalendar.month,
      year: state.manageClassCalendar.year,
      isTest: state.manageClassCalendar.isTest,
    }),
    shallowEqual
  )
  // isLoading,

  useEffect(() => {
    const calendar = `${year}-${convertMonth(month)}-01`
    if (!isTest) {
      const calendarApi = calendarRef?.current?.getApi()
      calendarApi?.gotoDate(calendar)
    }
  }, [month, year])

  const props = {
    type: 'fetch',
    month: convertMonth(month),
    year,
    uuid: uuidStation,
  }

  useEffect(() => {
    if (month && year) {
      dispatch(fetchDataCalendar(props))
    }
  }, [month, year, uuidStation])

  return (
    <BoxContentMian>
      <FullCalendar
        timeZone={'GMT+7'}
        defaultView="dayGridMonth"
        firstDay={1}
        height={820}
        header={{
          left: false,
          center: false,
          right: false,
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        ref={calendarRef}
        eventRender={renderEvent}
        weekends={true}
        events={initialState}
        eventClick={handleEventDrawer}
        selectable={true}
        eventLimit={true} // for all non-TimeGrid views
        views={{
          timeGrid: {
            eventLimit: 5, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        }}
        showNonCurrentDates={false}
        fixedWeekCount={false}
        dayMaxEventRows={true}
      />
    </BoxContentMian>
  )
}

export default Calendar

const renderEvent = (info) => {
  const box = document.createElement('div')
  box.className = `event-box-type-${info.event._def.extendedProps.class}`

  const boxFlex = document.createElement('div')
  boxFlex.className = 'event-box-flex'

  const boxLeft = document.createElement('div')
  boxLeft.className = 'event-box-left'
  boxLeft.append(info.event.title)

  const boxRight = document.createElement('div')
  boxRight.className = 'event-box-right'

  if (info.event._def.extendedProps.sum === 1) {
    const img = document.createElement('img')
    img.src = info.event._def.extendedProps.image[0]
    img.className = 'event-box-img-1'

    boxRight.append(img)
  } else if (info.event._def.extendedProps.sum === 2) {
    const img1 = document.createElement('img')
    img1.src = info.event._def.extendedProps.image[0]
    img1.className = 'event-box-img-left'

    const img2 = document.createElement('img')
    img2.src = info.event._def.extendedProps.image[1]
    img2.className = 'event-box-img-2'

    const boxFlexImg = document.createElement('div')
    boxFlexImg.className = 'event-box-img'

    boxFlexImg.append(img1)
    boxFlexImg.append(img2)
    boxRight.append(boxFlexImg)
  } else if (info.event._def.extendedProps.sum >= 3) {
    const img1 = document.createElement('img')
    img1.src = info.event._def.extendedProps.image[0]
    img1.className = 'event-box-img-left'

    const img2 = document.createElement('div')
    img2.className = 'event-box-div'
    img2.append(`+${info.event._def.extendedProps.sum - 1}`)

    const boxFlexImg = document.createElement('div')
    boxFlexImg.className = 'event-box-img'

    boxFlexImg.append(img1)
    boxFlexImg.append(img2)
    boxRight.append(boxFlexImg)
  }

  boxFlex.append(boxLeft)
  boxFlex.append(boxRight)

  box.append(boxFlex)

  return box
}
