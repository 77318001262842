import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Add from '@mui/icons-material/Add'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import TimePicker from '../../../../../components/TimePicker/TimePicker'
import { approveStatus } from '../../../../../constants/monthlyPlan/speaker'
import { initialResponsibility } from '../model'
import { styledTextInput } from '../Styled'
import { StyledContainer, StyledDivider, ResponseContainer } from './Styled'
import { handleFilterOption } from './events'

const StepTwo = (props) => {
  const { speakerBorrows, optionResponsibility, startTime, endTime } = props
  const { values, errors, handleChange, setFieldValue } = useFormikContext()
  const speakerBorrowResponsibility = _.get(
    values,
    'speakerBorrowResponsibility',
    []
  )
  let number = 0
  const station = _.get(values, 'station', '')
  const borrowListSameStation = speakerBorrows.filter(
    (speaker) =>
      (speaker.station === station ||
        speaker.approverStation?.uuid === station) &&
      !['REJECT', 'CANCEL'].includes(speaker.status)
  )

  return (
    <StyledContainer>
      {speakerBorrowResponsibility.map((value, index) => {
        const responseField = `speakerBorrowResponsibility[${index}].responsibility`
        const numSpeakerField = `speakerBorrowResponsibility[${index}].numSpeaker`
        const skillSpeakerField = `speakerBorrowResponsibility[${index}].skillSpeaker`
        const remarkField = `speakerBorrowResponsibility[${index}].remark`
        const responseValue = _.get(value, 'responsibility.uuid', '')
        const filteredOption = handleFilterOption({
          option: optionResponsibility,
          valueList: speakerBorrowResponsibility,
          valueUuid: responseValue,
          borrowListSameStation,
        })
        const isDeleted = value.status === approveStatus.DELETED
        if (!isDeleted) number++
        return (
          <ResponseContainer isHide={isDeleted} key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Typography variant="body1b">หน้าที่ {number}</Typography>
              <StyledDivider />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 8.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'start', gap: 2 }}>
                <Select
                  boxSx={{ width: '50%' }}
                  dataTestid={`select-responsibility-${index}`}
                  type="basic"
                  name={responseField}
                  labelText="หน้าที่"
                  placeholder="เลือกหน้าที่"
                  required
                  options={filteredOption}
                  textError={_.get(errors, `${responseField}.uuid`, '')}
                  value={responseValue}
                  handleChange={(e) =>
                    setFieldValue(
                      responseField,
                      filteredOption.find(
                        (item) => item.uuid === e.target.value
                      )
                    )
                  }
                />
                <TextInput
                  boxSx={{ width: '50%' }}
                  type="basic"
                  id={`select-numSpeaker-${index}`}
                  labelText="จำนวนวิทยากรที่ต้องการ"
                  placeholder="ระบุจำนวน"
                  required
                  textError={_.get(errors, numSpeakerField, '')}
                  value={_.get(value, 'numSpeaker', '')}
                  onChange={(e) => {
                    let value = Number(e.target.value) || ''
                    if (e.target.value === '0') value = 1
                    if (value > 99) value = 99
                    setFieldValue(numSpeakerField, value)
                  }}
                />
                {index !== 0 && (
                  <IconButton
                    sx={{ mt: 3 }}
                    onClick={() => {
                      let newSpeakers = [...speakerBorrowResponsibility]
                      if (value.uuid) {
                        newSpeakers[index].status = approveStatus.DELETED
                      } else {
                        newSpeakers = newSpeakers.filter((e, i) => i !== index)
                      }
                      setFieldValue('speakerBorrowResponsibility', newSpeakers)
                    }}
                  >
                    <DeleteTwoTone />
                  </IconButton>
                )}
              </Box>
              <TextInput
                sx={{ minHeight: 98, maxHeight: '100%', height: 'auto' }}
                type="basic"
                id={`select-skillSpeaker-${index}`}
                name={skillSpeakerField}
                inputProps={{ 'data-testid': 'skillSpeaker' }}
                labelText="Skill ที่ต้องการ"
                rows={3}
                multiline
                required
                textError={_.get(errors, skillSpeakerField, '')}
                value={_.get(value, 'skillSpeaker', '')}
                onChange={handleChange}
              />
              <Box sx={{ display: 'flex', gap: 2, pb: 1 }}>
                <TimeInput
                  label="เวลาเริ่ม"
                  name="startTime"
                  value={value}
                  index={index}
                />
                <TimeInput
                  label="เวลาสิ้นสุด"
                  name="endTime"
                  value={value}
                  index={index}
                />
              </Box>
              <TextInput
                sx={styledTextInput}
                type="basic"
                id={`select-remark-${index}`}
                name={remarkField}
                labelText="หมายเหตุ"
                placeholder="เช่น ระบุชื่อวิทยากรที่ต้องการ"
                rows={3}
                multiline
                textError={_.get(errors, remarkField, '')}
                value={_.get(value, 'remark', '')}
                onChange={handleChange}
              />
            </Box>
          </ResponseContainer>
        )
      })}
      {speakerBorrowResponsibility.length < optionResponsibility.length && (
        <Button
          startIcon={<Add />}
          onClick={() =>
            setFieldValue('speakerBorrowResponsibility', [
              ...speakerBorrowResponsibility,
              { ...initialResponsibility, startTime, endTime },
            ])
          }
        >
          เพิ่มหน้าที่
        </Button>
      )}
    </StyledContainer>
  )
}

export default StepTwo

const TimeInput = (props) => {
  const { label, value, index, name } = props
  const { errors, setFieldValue } = useFormikContext()
  const nameKey = `speakerBorrowResponsibility[${index}].${name}`

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Box>

      <TimePicker
        id={`select-${name}-${index}`}
        name={nameKey}
        helperText={_.get(errors, nameKey, '')}
        value={String(value[name])}
        disabledStartTime={String(value.startTime)}
        disabledEndTime={String(value.endTime)}
        onChange={(e) => setFieldValue(nameKey, e.target.value)}
      />
    </Box>
  )
}
