import _ from 'lodash'

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = ({
  selectedDates,
  filter,
  setFilter,
  nameInput,
}) => {
  const keyEndDate = nameInput === 'startDate' ? 'toStartDate' : 'toEndDate'
  const displayEDate =
    nameInput === 'startDate' ? 'displayStartDate' : 'displayEndDate'

  let objState = {
    ...filter,
    [nameInput]: selectedDates.startDate,
    [keyEndDate]: selectedDates.endDate,
    [displayEDate]: selectedDates.display,
  }
  setFilter(objState)
}
