export const prepareTopList = (list = [], limit = 5) => {
  const modifiedList =
    list.length > 0
      ? [...list]
          .sort((a, b) => {
            if (b.size !== a.size) {
              return b.size - a.size
            }
            return a.name.localeCompare(b.name)
          })
          .slice(0, limit)
      : []
  return modifiedList
}
