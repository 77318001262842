import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Header from './Header'
import Information from './Information'
import TableDetail from './TableDetail'
import LearnerDetail from './LearnerDetail'
import ClassroomDetail from './ClassroomDetail'
import ETestAndEva from './ETestAndEva'

import { fetchDataDetail } from './events'
import { LoadingPage, BoxContainer } from '../../Styled'
import { StyledContent } from '../Styled'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.manageClassLearnerDetail.isLoading
  )

  useEffect(() => {
    dispatch(fetchDataDetail(uuid))
  }, [])

  return (
    <BoxContainer>
      <StyledContent isLoading={isLoading}>
        <Header />
        <Information />
        <TableDetail />
        <ClassroomDetail />
        <ETestAndEva />
        <LearnerDetail />
      </StyledContent>
      <LoadingPage isLoading={isLoading} />
    </BoxContainer>
  )
}

export default Index
