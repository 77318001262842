import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const masterDataExamination = createSlice({
  name: 'masterDataExamination',
  initialState,
  reducers: {
    setExamType: (state, { payload }) => {
      state.examType = payload
    },
  },
})

export const {
  setExamType,
} = masterDataExamination.actions

export default masterDataExamination.reducer
