import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { reportFilter } from '../../../../utils/apiPath'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { filtersDrawer } = store.getState().crud.mixModuleDashboard
  const body = {
    startDate:
      filtersDrawer?.dateFilterStartDate ??
      dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate:
      filtersDrawer?.dateFilterFinishDate ?? dayjs().format('YYYY-MM-DD'),
    ramCode: _.defaultTo(filtersDrawer?.ram, []),
    sRam: _.defaultTo(filtersDrawer?.sram, []),
    station: _.defaultTo(filtersDrawer?.station, []).map((item) => item.value),
    acquiredSkill: _.defaultTo(filtersDrawer?.skill, []),
    distribution: _.defaultTo(filtersDrawer?.distribution, []),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  await callAxios
    .post(`${reportFilter}/mix-module-dashboard`, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'mixModuleDashboard.latestUpdated',
          value: _.get(data, 'latestUpdated', undefined),
        }),
      )

      store.dispatch(
        setFieldValue({
          key: 'mixModuleDashboard.overview',
          value: {
            allClassCount: _.get(data, 'allClassCount', 0),
            allLearnerEnroll: _.get(data, 'allLearnerEnroll', 0),
            allAttendee: _.get(data, 'allAttendee', 0),
            allExpenseSummary: _.get(data, 'allExpenseSummary', 0),
            Agency: {
              agencyAttendee: _.get(data, 'Agency.agencyAttendee', 0),
              agencyClassCount: _.get(data, 'Agency.agencyClassCount', 0),
              agencyExpenseSummary: _.get(
                data,
                'Agency.agencyExpenseSummary',
                0,
              ),
              agencyLearnerEnroll: _.get(data, 'Agency.agencyLearnerEnroll', 0),
            },
            Banca: {
              bancaAttendee: _.get(data, 'Banca.bancaAttendee', 0),
              bancaClassCount: _.get(data, 'Banca.bancaClassCount', 0),
              bancaExpenseSummary: _.get(data, 'Banca.bancaExpenseSummary', 0),
              bancaLearnerEnroll: _.get(data, 'Banca.bancaLearnerEnroll', 0),
            },
            Ktb: {
              ktbAttendee: _.get(data, 'Ktb.ktbAttendee', 0),
              ktbClassCount: _.get(data, 'Ktb.ktbClassCount', 0),
              ktbExpenseSummary: _.get(data, 'Ktb.ktbExpenseSummary', 0),
              ktbLearnerEnroll: _.get(data, 'Ktb.ktbLearnerEnroll', 0),
            },
            Other: {
              otherAttendee: _.get(data, 'Other.otherAttendee', 0),
              otherClassCount: _.get(data, 'Other.otherClassCount', 0),
              otherExpenseSummary: _.get(data, 'Other.otherExpenseSummary', 0),
              otherLearnerEnroll: _.get(data, 'Other.otherLearnerEnroll', 0),
            },
            topListAllCourse: _.get(data, 'topListAllCourse', []).map(
              (item) => {
                return {
                  ...item,
                  name: item?.courseNameEN ?? '',
                }
              },
            ),
            topListExpense: _.get(data, 'topListExpense', []).map((item) => {
              return {
                ...item,
                name: item?.courseNameEN ?? '',
                size: item?.expenseSummary ?? 0,
              }
            }),
            topListAgencyCourse: _.get(data, 'topListAgencyCourse', []).map(
              (item) => {
                return {
                  ...item,
                  name: item?.courseNameEN ?? '',
                }
              },
            ),
            topListBancaCourse: _.get(data, 'topListBancaCourse', []).map(
              (item) => {
                return {
                  ...item,
                  name: item?.courseNameEN ?? '',
                }
              },
            ),
            topListKtbCourse: _.get(data, 'topListKtbCourse', []).map(
              (item) => {
                return {
                  ...item,
                  name: item?.courseNameEN ?? '',
                }
              },
            ),
            topListOtherCourse: _.get(data, 'topListOtherCourse', []).map(
              (item) => {
                return {
                  ...item,
                  name: item?.courseNameEN ?? '',
                }
              },
            ),
          },
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
