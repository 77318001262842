import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

const TextFieldInput = ({
  boxSx,
  sx,
  type,
  placeholder,
  labelText,
  required,
  disabled,
  id,
  name,
  onChange,
  onBlur,
  onClick,
  textError,
  value,
  multiline,
  rows,
  endAdornmentText,
  inputProps,
  onInput,
  isShowTextError = false,
}) => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <TextField
        id={id}
        name={name}
        disabled={disabled}
        sx={{
          ...sx,
          borderRadius: 0,
          border: isShowTextError && '1px solid red',
        }}
        type={type}
        placeholder={placeholder}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        multiline={multiline}
        inputProps={{ ...inputProps, 'data-testid': id }}
        onInput={onInput}
        rows={rows}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            {endAdornmentText}
          </InputAdornment>
        }
      />
      {isShowTextError && (
        <Typography sx={{ height: '25px' }} variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}
export default TextFieldInput
