import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { filtersDrawerEnum } from '../../enum/enum'
import { fetchOverview } from '../../handler/fetchOverview'
import { defaultFilter } from './model'

export const handleFiltering = (filter) => (dispatch) => {
  const isFilterDate = filter.dateChecked && filter.startDate && filter.endDate
  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  dispatch(
    setFieldValue({
      key: 'eContentDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        startDate: isFilterDate
          ? dayjs(filter.startDate).format('YYYY-MM-DD')
          : dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: isFilterDate
          ? dayjs(filter.endDate).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview()
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const clearFilter = (key, filter, setFilter, setFilterMemo) => {
  let objState = {
    ...filter,
  }
  if (key === 'date') {
    objState = {
      ...objState,
      dateChecked: true,
      startDate: defaultFilter.startDate,
      endDate: defaultFilter.endDate,
      displayDate: true,
    }
  }
  setFilter(objState)
  setFilterMemo(objState)
}
