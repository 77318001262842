import _ from 'lodash'

export const postBodyMutation = (body) => {
  const data = _.cloneDeep(body)

  return {
    name: _.get(data, 'name', ''),
    coverImage: !_.isEmpty(data?.coverImage) ? data.coverImage : null,
    contents: _.get(data, 'contents', []).map((d, i) => {
      delete d.id
      d['sequence'] = i + 1
      return d
    }),
    category: _.isNil(data.subCategory)
      ? null
      : _.get(data, 'category.value', { uuid: undefined }),
    subCategory: _.isNil(data.subCategory)
      ? null
      : _.get(data, 'subCategory.value', { uuid: undefined }),
    contentType: _.get(data, 'contentType', ''),
    isDownload: _.get(data, 'isDownload', '') === 'ACTIVE',
    isActive: _.get(data, 'isActive', 'ACTIVE') === 'ACTIVE',
    startPublishDate: _.get(data, 'startPublishDate', ''),
    endPublishDate: _.get(data, 'endPublishDate', null),
    remark: _.get(data, 'remark', ''),
    isEndPublishDate: _.get(data, 'isEndPublishDate', '') === 'ACTIVE',
    status: 'DRAFT',
    distributionChannel: _.get(data, 'distributionCh', []).map((obj) => {
      return { uuid: obj.value }
    }),
    levelOfLearner: _.get(data, 'levelOfLearner', []).map((obj) => {
      return { uuid: obj.value }
    }),
    assignees: _.get(data, 'assignees', []).map((obj) => {
      return { uuid: obj.value }
    }),
    eContentTag: _.get(data, 'eContentTag', []).map((obj) => {
      return { name: obj.label, uuid: obj.value }
    }),
    uuid: _.get(data, 'uuid', undefined),
    isPinned:
      _.get(data, 'isActive', '') !== 'ACTIVE'
        ? false
        : _.get(data, 'isPinned', false),
  }
}
