import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from '../Styled'
import FilterDrawer from './FilterDrawer'
import { breadcrumbDetailList } from '../model'
import HeaderList from './Header'
import { fetchDataList } from './event'
import HistoryDrawer from './HistoryDrawer'
import { callAPI, validateEPermission } from '../../../utils/lib'
import { apiToRcmsHistoryDetail } from '../../../utils/apiPath'
import { useParams } from 'react-router-dom'
import HeaderSub from './HeaderSub'
import _ from 'lodash'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import ChangeStatusDialog from './ChangeStatusDialog'
import { setChangeStatusLicense } from '../../../redux/slices/apiToRcms'
import { setSelected } from '../../../redux/slices/table'
import {
  AGENT_LICENSE_SETTING,
  PERMISSION,
} from '../../../constants/permission/apiToRcms'
import { handleCloseDialog } from './CheckDialog/events'

const List = () => {
  const { id } = useParams()
  const [isInitial, setInitial] = useState(true)
  const dispatch = useDispatch()
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const fetchProps = { table, page, filterProp, searchText, id }

  const hasEditPermission = validateEPermission({
    module: AGENT_LICENSE_SETTING,
    permission: PERMISSION.EDIT,
  })

  useEffect(() => {
    dispatch(
      fetchDataList('initial', fetchProps, setInitial, hasEditPermission)
    )
  }, [])

  useEffect(() => {
    if (isInitial) return
    fetchData()
  }, [limit, order, page, sort, id])

  const fetchData = () => {
    dispatch(fetchDataList('fetch', fetchProps, null, hasEditPermission))
  }

  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limitHistory, setLimitHistory] = useState(100)
  const [pageHistory, setPageHistory] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  const fetchHistory = async () => {
    const realPage = pageHistory === 0 ? 1 : pageHistory + 1
    const body = {
      rcmsUuid: id,
      agentProfileUuid: '',
      limit: limitHistory,
      page: realPage,
    }
    const url = `${apiToRcmsHistoryDetail}`
    await callAPI({
      url: url,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  useEffect(() => {
    fetchHistory()
  }, [limitHistory, pageHistory])

  const { englishName, statusList, agent_detail, license_detail, finishDate } =
    useSelector(
      (state) => ({
        englishName: state.apiToRcms.englishName,
        statusList: state.apiToRcms.statusList,
        agent_detail: state.apiToRcms.agent_detail,
        license_detail: state.apiToRcms.license_detail,
        finishDate: state.apiToRcms.finishDate,
      }),
      shallowEqual
    )

  const Submit = () => {
    fetchHistory()
    fetchData()
    dispatch(setSelected([]))
  }

  useEffect(() => {
    if (!_.isEmpty(statusList) && !_.isEmpty(agent_detail)) {
      const agent_details = []
      const not_success = []
      let licenseStatus = null
      let error = null
      for (const uuid of statusList) {
        if (error !== null) continue
        const fil = _.filter(agent_detail, (i) => i.uuid === uuid)[0]
        if (!licenseStatus) {
          licenseStatus = fil.licenseStatus
        }

        if (fil.rcmsStatus === 'สำเร็จ' || fil.rcmsStatus === 'ไม่สำเร็จ') {
          error = fil.licenseStatus
        }

        if (licenseStatus !== fil.licenseStatus) {
          error = fil.licenseStatus
        }

        if (fil.rcmsStatus === 'ไม่สำเร็จ') {
          not_success.push(fil.rcmsStatus)
        }
        agent_details.push(fil)
      }
      if (!error) {
        dispatch(
          openDialog({
            type: 'mini',
            maxWidth: 'sm',
            disableButton: true,
            sx: {
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  width: '100%',
                  maxWidth: '640px',
                },
              },
            },
            content: (
              <ChangeStatusDialog
                rcmsUuid={id}
                agent_detail={agent_details}
                license_detail={license_detail}
                finishDate={finishDate}
                fetchData={Submit}
              />
            ),
            handleCancel: () => {
              dispatch(setChangeStatusLicense([]))
              dispatch(handleCloseDialog())
            },
          })
        )
      } else {
        dispatch(setChangeStatusLicense([]))

        if (!_.isEmpty(not_success)) {
          dispatch(
            openDialog({
              type: 'fail',
              title: 'ไม่สำเร็จ',
              message: (
                <>
                  <Typography variant="body1">
                    กรุณาดาวน์โหลดรายการที่ไม่สำเร็จ
                    เพื่อแก้ไขและดำเนินการนำเข้าข้อมูลด้วย Admin Process
                    ใหม่อีกครั้ง
                  </Typography>
                </>
              ),
              handleConfirm: () => {
                dispatch(closeDialog())
              },
            })
          )
        } else {
          dispatch(
            openDialog({
              type: 'fail',
              title: 'โปรดเลือกผู้เรียนที่มีสถานะใบอนุญาตเหมือนกัน',
              message:
                'ไม่สามารถใช้ฟังก์ชัน ปรับสถานะใบอนุญาต นี้ได้ เนื่องจากคุณเลือกผู้เรียนที่มีสถานะใบอนุญาตต่างกัน',
              handleConfirm: () => {
                dispatch(closeDialog())
              },
            })
          )
        }
      }
    }
  }, [statusList])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">{englishName}</Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbDetailList} />

        <HeaderList
          onHistoryClick={onHistoryClick}
          fetchData={Submit}
          id={id}
        />
        <HeaderSub id={id} fetchData={Submit} />

        <StyledCard id="listTable">
          <Table />
        </StyledCard>

        <FilterDrawer
          open={isFilterDrawer}
          table={table}
          drawerType="api_to_rcms_detail"
          permission={hasEditPermission}
        />
      </StyledContent>

      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={pageHistory}
        setPage={setPageHistory}
        setLimit={setLimitHistory}
        allCount={allCount}
      />
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default List
