import React from 'react'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import Answers from '../Answers'
import { onDragEnd } from './event'

import { IconDragIndicator } from './Styled'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'

const Index = ({ props }) => {
  const { sequence, itemQuestion, isSubQuestions, subQuestionIndex } = props
  const dispatch = useDispatch()

  const answers = _.get(itemQuestion, 'answers', [])
  const questionType = _.get(itemQuestion, 'questionType', 'SINGLE')

  return (
    <DragDropContext
      onDragEnd={(event) =>
        dispatch(
          onDragEnd({
            event,
            answers,
            sequence,
            isSubQuestions,
            subQuestionIndex,
          })
        )
      }
    >
      <Droppable droppableId={`list-${questionType}`}>
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <ListAnswers props={props} />
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default Index

export const ListAnswers = ({ props }) => {
  const { itemQuestion, disableForm } = props
  const answers = _.get(itemQuestion, 'answers', [])
  const numberOfAnswers = _.get(itemQuestion, 'numberOfAnswers', 0)

  return answers.map((item, indexAnswer) => (
    <Draggable key={item.id} draggableId={item.id} index={indexAnswer}>
      {(provided) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          key={indexAnswer}
          sx={{ display: 'flex', width: '100%' }}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            {!disableForm ? (
              <IconDragIndicator {...provided.dragHandleProps}>
                <DragIndicatorTwoTone />
              </IconDragIndicator>
            ) : (
              <IconDragIndicator>
                <DragIndicatorTwoTone />
              </IconDragIndicator>
            )}

            <Answers
              props={{ ...props, answers, indexAnswer, numberOfAnswers }}
            />
          </Box>
        </Box>
      )}
    </Draggable>
  ))
}
