import React from 'react'
import { useLocalStorage } from '@rehooks/local-storage'

import { ListHeaderAction } from '../../../../../components/CRUD/components/Header/components/listHeaderAction'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../crudConfig'
import localforage from 'localforage'
import { breadcrumb } from '../../Listing/model/breadcrumb'
import { useHistory } from 'react-router-dom'

const Header = () => {
  const [user] = useLocalStorage('user')
  const history = useHistory()
  return (
    <CHeader
      title={crudConfig.moduleTitleFull}
      breadcrumbs={breadcrumb}
      action={
        validatePermission({
          user: user,
          moduleType: 'E_CONTENT_AND_KB',
          permission: ['CREATE', 'CREATE_ECONTENT', 'CREATE_KB'],
        }) ? (
          <ListHeaderAction
            onClick={() => {
              localforage.removeItem('persist:root').then()
              history.push(`${crudConfig.modulePath}/form`)
            }}
          />
        ) : (
          <div />
        )
      }
    />
  )
}

export default Header
