import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainerBg = styled(Box)(({ theme, withHeader }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: withHeader ? 'calc(100vh - 165px)' : 'calc(100vh - 151px)',
  marginTop: withHeader ? 0 : '-14px',
  [theme.breakpoints.down('md')]: {
    minHeight: withHeader ? 'calc(100vh - 210px)' : 'calc(100vh - 120px)',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: withHeader ? 'calc(100vh - 165px)' : 'calc(100vh - 120px)',
  },
}))
