import React from 'react'
import _ from 'lodash'
import { postFilterRcms } from '../../../../services/apiToRcms'
import { startLoading, stopLoading } from '../../../../redux/slices/table'
import { openDialog } from '../../../../redux/slices/dialog'
import CheckSubmitDialog from '../CheckSubmitDialog'

export const handleSubmit = (id, fetchData) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    limit: '100',
    page: -1,
    order: 'DESC',
    sort: 'updatedAt',
    rcmsUuid: id,
    fullAgentCode: '',
    fullName: '',
    agentCategory: '',
    effectiveDateStartDate: '',
    effectiveDateFinishDate: '',
    expiryDateStartDate: '',
    expiryDateFinishDate: '',
    licenseRcmsStatus: [],
    rcmsStatus: [],
  }
  const response = await dispatch(postFilterRcms(body))
  if (!response.error) {
    const payload = _.get(response, 'payload.data', {})
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'md',
        disableButton: true,
        content: (
          <CheckSubmitDialog
            uploadResult={payload}
            fetchData={fetchData}
            id={id}
          />
        ),
      })
    )
  }

  dispatch(stopLoading())
}
