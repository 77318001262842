import _ from 'lodash'
import {
  resetRedirectPage,
  setAnswered,
  setAssignSubQuestion,
  setChangeStateByKey,
  setNextQuestion,
  setRedirectPage,
  setSectionResult,
} from '../../../../../../../redux/slices/eEvaluationPreview'

export const handleSelector = (state, keyProps) => {
  const { keyQuestion, keySub } = keyProps
  const question = state.eEvaluationPreview.questions.find(
    (item) => item.key == keyQuestion
  )

  if (!keySub) return question

  const choice = question.choices.find(
    (item) => 
      item.nextQuestionType === 'NEXT_SUB' &&
      item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})

  return subQuestion
}

export const handleFreeTextChange = (e, key) => (dispatch) => {
  const { keyQuestion, keySub } = key
  const value = _.get(e, 'target.value', null)
  dispatch(
    setChangeStateByKey({ key: 'answer[0]', value, keyQuestion, keySub })
  )
  dispatch(setAnswered({ keyQuestion, value }))
}

export const handleSingleChange = (e, key, choices) => (dispatch) => {
  const { keyQuestion, keySub } = key
  const value = _.get(e, 'target.value', null)
  dispatch(
    setChangeStateByKey({
      key: 'answer[0]',
      value,
      keyQuestion,
      keySub,
    })
  )
  dispatch(setAnswered({ keyQuestion, value }))

  if (!keySub) dispatch(setAssignSubQuestion({ keyQuestion, value }))

  if (value != 'OTHER') {
    if (choices.some((item) => item.nextQuestionType == 'NEXT_TO')) {
      const choice = choices.find((item) => item.uuid == value)
      if (choice.nextQuestionType == 'NEXT_SUB') {
        setTimeout(() => {
          dispatch(setNextQuestion())
        }, 500)
      } else if (choice.nextQuestionType == 'SUMMARY') {
        setTimeout(() => {
          dispatch(setSectionResult())
        }, 500)
      } else if (choice.nextQuestionType == 'NEXT_TO') {
        setTimeout(() => {
          dispatch(
            setRedirectPage({
              from: keyQuestion,
              to: choice.nextQuestionSequence,
            })
          )
        }, 500)
      } else {
        dispatch(resetRedirectPage(keyQuestion))
      }
    }
  }
}

export const handleOtherChange = (e, choice, keyQuestion) => (dispatch) => {
  if (e.target.value != '') {
    if (choice.nextQuestionType == 'NEXT_SUB') {
      setTimeout(() => {
        dispatch(setNextQuestion())
      }, 500)
    }
    if (choice.nextQuestionType == 'SUMMARY') {
      setTimeout(() => {
        dispatch(setSectionResult())
      }, 500)
    }
    if (choice.nextQuestionType == 'NEXT_TO') {
      setTimeout(() => {
        dispatch(
          setRedirectPage({
            from: keyQuestion,
            to: choice.nextQuestionSequence,
          })
        )
      }, 500)
    }
  }
}

export const handleMultipleChange = (e, index, key) => (dispatch) => {
  const { keyQuestion, keySub } = key
  const value = _.get(e, 'target.checked', null)
  dispatch(
    setChangeStateByKey({
      key: `answer[${index}].isChecked`,
      value,
      keyQuestion,
      keySub,
    })
  )
  dispatch(setAnswered({ keyQuestion, value }))
}

export const isOtherInputShow = (choice, type, answer, index) => {
  if (choice.answerType != 'OTHER') return false

  if (type == 'MULTIPLE') return answer[index].isChecked

  return answer[0] == 'OTHER'
}

export const handleDisableCheckbox = (question, value) => {
  const { answer, setTypeOfAnswers, typeOfAnswers, numberOfAnswers } = question
  if (setTypeOfAnswers) {
    if (typeOfAnswers == 'EQUAL' || typeOfAnswers == 'MAXIMUM') {
      const checked = answer.filter((item) => item.isChecked)
      const index = checked.findIndex((item) => item.uuid == value)
      return checked.length >= numberOfAnswers && index == -1
    }
    return false
  } else return false
}
