import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { BoxContent } from '../index.js'
import { BoxContainer } from '../Styled.js'
import { BoxFlex } from './Styled.js'
import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import DatePicker from '../../../../../components/Input/DatePicker.js'
import { handleChange, handleChangeContact } from './events.js'
import _ from 'lodash'
import { checkIdCard, handleLength } from '../../../../../utils/lib.js'
import { setDivision } from '../../../../../redux/slices/manage/prospectProfile'
import dayjs from 'dayjs'

const Index = ({ type }) => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual
  )
  const errors = useSelector(
    (state) => state.prospectProfile.errors,
    shallowEqual
  )
  const dispatch = useDispatch()
  const status =
    _.startCase(_.camelCase(_.get(data, 'work.statusProspect', '-'))) ===
    'Active'
      ? 'Active'
      : 'Terminate'

  return (
    <>
      <Card>
        <BoxFlex>
          <Typography variant="h6">ข้อมูลการทำงาน</Typography>
          {type === 'CS' ? (
            <>
              <BoxContainer>
                <TextFieldInput
                  id="division"
                  name="division"
                  labelText="แผนก/ฝ่าย"
                  value={_.get(data, 'division', '')}
                  onChange={(e) =>
                    dispatch(
                      setDivision(_.get(e, 'target.value', '')?.slice(0, 255))
                    )
                  }
                />
                <BoxContent
                  labelLeft={'Broker Number'}
                  labelRight={_.get(data, 'work.unitNumber')}
                />
              </BoxContainer>
              <BoxContainer>
                <BoxContent
                  labelLeft={'ชื่อบริษัทโบรกเกอร์'}
                  labelRight={_.get(data, 'work.unitName')}
                />
                <BoxContent
                  labelLeft="สถานะการทำงาน"
                  labelRight={status}
                  status={_.get(data, 'work.statusProspect', '-')}
                />
              </BoxContainer>
              <BoxContainer>
                <BoxContent
                  labelLeft="การให้เข้าถึงข้อมูล"
                  labelRight={
                    _.get(data, 'work.access', '')
                      ? `ยินยอม  (${_.get(data, 'work.access', '')})`
                      : '-'
                  }
                  status={_.get(data, 'work.access', '') ? 'ACTIVE' : ''}
                />
                <BoxContent labelLeft="" labelRight="" />
              </BoxContainer>
            </>
          ) : (
            <>
              <BoxContainer>
                <BoxContent
                  labelLeft={'Unit Number'}
                  labelRight={_.get(data, 'work.unitNumber')}
                />
                <BoxContent
                  labelLeft={'ชื่อ Unit'}
                  labelRight={_.get(data, 'work.unitName')}
                />
              </BoxContainer>
              <BoxContainer>
                <BoxContent
                  labelLeft="สถานะตัวแทน"
                  labelRight={_.get(data, 'work.statusProspect')}
                />
                <BoxContent
                  labelLeft="การให้เข้าถึงข้อมูล"
                  labelRight={
                    _.get(data, 'work.access', '')
                      ? `ยินยอม  (${_.get(data, 'work.access', '')})`
                      : '-'
                  }
                  status={_.get(data, 'work.access', '') ? 'ACTIVE' : ''}
                />
              </BoxContainer>
            </>
          )}
        </BoxFlex>
      </Card>

      <Card>
        <BoxFlex>
          <Typography variant="h6">ข้อมูลติดต่อ</Typography>
          <BoxContainer>
            <TextFieldInput
              id="phoneNo"
              name="phoneNo"
              labelText="เบอร์โทรศัพท์"
              required={type !== 'CS'}
              type="number"
              isShowTextError={
                errors?.mobileNo || (_.get(data, 'contact.mobileNo', '').length !== 10 &&
                (type !== 'CS' ||
                  _.get(data, 'contact.mobileNo', '').length >= 1))
              }
              textError={
                errors?.mobileNo ? errors?.mobileNo
                : (type !== 'CS' || _.get(data, 'contact.mobileNo', '').length >= 1)
                ? 'กรุณากรอกเบอร์โทรศัพท์'
                : ''
              }
              value={_.get(data, 'contact.mobileNo', '')}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 10)
              }}
              onChange={(e) =>
                dispatch(
                  handleChangeContact({
                    key: 'mobileNo',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
            />

            <TextFieldInput
              id="email"
              name="email"
              labelText="อีเมล"
              required={true}
              type="string"
              isShowTextError={errors?.email || _.get(data, 'contact.email', '') === ''}
              textError={ errors?.email ?? 'กรุณากรอกอีเมล'}
              value={_.get(data, 'contact.email', '')}
              onInput={(e) => {
                e.target.value
              }}
              onChange={(e) =>
                dispatch(
                  handleChangeContact({
                    key: 'email',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
            />
          </BoxContainer>
        </BoxFlex>
      </Card>

      <Card>
        <BoxFlex>
          <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
          <BoxContainer noGap>
            <DatePicker
              labelText="วัน/เดือน/ปี เกิด"
              value={_.get(data, 'info2.dateOfBirth', '-')}
              required={type !== 'CS'}
              textError={
                _.get(data, 'info2.dateOfBirth', '') == null &&
                type !== 'CS' &&
                'กรุณากรอก'
              }
              onChange={(e) => {
                dispatch(
                  handleChange({
                    key: 'dateOfBirth',
                    value: dayjs(e).isValid() ? e : null,
                  })
                )
              }}
            />

            <TextFieldInput
              id="idcard"
              name="idcard"
              labelText="เลขบัตรประชาชน"
              required={type !== 'CS'}
              type="number"
              value={_.get(data, 'info2.idCardNo', '')}
              isShowTextError={
                errors?.idCardNo ? errors?.idCardNo :
                handleLength(_.get(data, 'info2.idCardNo', '')) === 13
                  ? !checkIdCard(_.get(data, 'info2.idCardNo', ''))
                  : handleLength(_.get(data, 'info2.idCardNo', '')) < 13
                  ? (type !== 'CS' ||
                      handleLength(_.get(data, 'info2.idCardNo', '')) > 0) &&
                    true
                  : false
              }
              textError={errors?.idCardNo ?? "เลขบัตรประชาชนไม่ถูกต้อง"}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 13)
              }}
              onChange={(e) =>
                dispatch(
                  handleChange({
                    key: 'idCardNo',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
            />
          </BoxContainer>
        </BoxFlex>
      </Card>
    </>
  )
}

export default Index
