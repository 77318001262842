import {
  setDragDropAssessment,
  setAddQuestion,
} from '../../../../../redux/slices/eTestingForm'

export const onDragEnd = (event) => (dispatch) => {
  if (!event.destination) return
  if (event.destination.index === event.source.index) return

  dispatch(
    setDragDropAssessment({
      startIndex: event.source.index,
      endIndex: event.destination.index,
    })
  )
}

export const handleAddAssessment = (question) => (dispatch) => {
  const result = {
    id: `id-${question + 1}`,
    score: 0,
    sequence: question + 1,
    title: '',
    answers: [],
    uuid: '',
  }

  dispatch(setAddQuestion({ value: result }))
}
