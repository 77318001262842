import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { contentTypeImage } from '../model/contentTypeImage'
import { scrollToLast } from './scrollToLast'

export const handleAddContentTypeImage = () => {
  const state = store.getState().crud

  const contents = [...state.formData.contents]
  contents.push(contentTypeImage)

  store.dispatch(setFieldValue({ key: 'formData.contents', value: contents }))
  scrollToLast()
}
