import React from 'react'
import LearnerList from '../../LearnerList'
import { StyledDrawer, StyledContent } from './Styled'

const LearnerDrawer = ({ isStandalone, isOpen, onCloseDrawer }) => {
  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <LearnerList isStandalone={isStandalone} listHeight="100%" />
      </StyledContent>
    </StyledDrawer>
  )
}

export default LearnerDrawer
