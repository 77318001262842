import _ from 'lodash'
import {
  getLearnerDetail,
  getLearnerRekog,
} from '../../../../services/eLearning/course/learner'
import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eLearning/courseBatchLearnerDetail'
import { getBatchLearnerDetail } from '../../../../services/eLearning/course/form'
import { setChange } from '../../../../redux/slices/eLearning/courseBatchLearnerDetail'
import { getLearnerDetailRound } from '../../../../services/eLearning/course/learner'

export const fetchCourseLearner = (uuid, courseUuid) => async (dispatch) => {
  dispatch(startLoading())

  const response = await dispatch(getBatchLearnerDetail(uuid))
  if (isOic()) {
    dispatch(getLearnerRekog(uuid))
    dispatch(getLearnerDetail(uuid))
  } else {
    const payload = {
      codeId: _.get(response, 'payload.codeId', ''),
      eLearningCourseUuid: courseUuid,
    }
    dispatch(getLearnerDetailRound(payload))
  }
  dispatch(stopLoading())
}

export const isOic = () => {
  return window.location.href.includes('batch')
}

export const handleSelectItem = (value) => (dispatch) => {
  dispatch(setChange({ key: 'expandedRound', value }))
}
