import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { isValidateDate } from '../../../../../../utils/lib'

export const Label = () => {
  const { startDate, endDate } = useSelector(
    (state) => ({
      startDate: state.roomManagementDrawer.bookingInformation.startDate,
      endDate: state.roomManagementDrawer.bookingInformation.endDate,
    }),
    shallowEqual
  )
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        textWrap: 'nowrap',
      }}
      component={'div'}
      variant="body2b"
    >
      {isValidateDate(startDate) && isValidateDate(endDate)
        ? 'วันใช้งานห้อง'
        : 'ระบุวันใช้งานห้อง'}
    </Typography>
  )
}
