import React from 'react'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'

export const StyledSwitchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 50,
  background: theme?.palette?.info?.background,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(3),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.info?.main,
      fontSize: 16,
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
  },
}))

export const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement="right"
    leaveDelay={3000}
    leaveTouchDelay={3000}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 192,
    height: 50,
    fontSize: '14px',
    lineHeight: '14px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    backgroundColor: theme?.palette?.blue?.azure,
    span: { color: theme?.palette?.blue?.azure },
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    [theme.breakpoints.down('sm')]: { marginLeft: '14px !important' },
  },
}))
