import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React from 'react'
import { BoxImage, InfoImage, BoxContentImage } from './Styled'

const CoverImage = ({ loadingProgress, isError, url }) => {
  if (loadingProgress > 0 && loadingProgress < 100)
    return (
      <BoxContentImage>
        <BoxImage isLoading>
          <Box>
            <UploadFileOutlined />
            <LinearProgress variant="determinate" value={loadingProgress} />
          </Box>
        </BoxImage>
      </BoxContentImage>
    )
  else
    return (
      <BoxContentImage>
        <BoxImage isError={isError}>
          {url && (
            <InfoImage src={`${window.__env__.REACT_APP_API_URL}/file${url}`} />
          )}
          {isError && (
            <Box>
              <UploadFileOutlined />
              <Typography variant="buttons">ผิดพลาด</Typography>
            </Box>
          )}
        </BoxImage>
        {isError && (
          <Typography color="error" variant="body2">
            อัพโหลดรูปภาพไม่สำเร็จ ลองใหม่อีกครั้ง
          </Typography>
        )}
      </BoxContentImage>
    )
}

export default CoverImage
