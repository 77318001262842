import _ from 'lodash'

export const handleInitialForm = (initialValues, formik) => {
  const eCertificationVersion = _.get(
    initialValues,
    'eCertificationVersion',
    null
  )
  const expireECertificationYear = _.get(
    initialValues,
    'expireECertificationYear',
    null
  )

  formik.setFieldValue('eCertificationVersion', eCertificationVersion)
  formik.setFieldValue('expireECertificationYear', expireECertificationYear)
}
