import React from 'react'
import FormatAlignLeftOutlined from '@mui/icons-material/FormatAlignLeftOutlined'
import FormatAlignCenterOutlined from '@mui/icons-material/FormatAlignCenterOutlined'
import FormatAlignRightOutlined from '@mui/icons-material/FormatAlignRightOutlined'
import RadioButtonCheckedOutlined from '@mui/icons-material/RadioButtonCheckedOutlined'
import CheckBoxRounded from '@mui/icons-material/CheckBoxRounded'
import ShortTextRounded from '@mui/icons-material/ShortTextRounded'
import StarHalf from '@mui/icons-material/StarHalf'
import FormatListNumbered from '@mui/icons-material/FormatListNumbered'
import GridView from '@mui/icons-material/GridView'
import Rule from '@mui/icons-material/Rule'
import HighlightAlt from '@mui/icons-material/HighlightAlt'
// import Rule from '@mui/icons-material/Rule'

import { BoxLabel } from './Styled'

export const FindIcon = ({ item }) => {
  const { label, value } = item
  switch (value) {
    case 'SINGLE':
      return (
        <BoxLabel>
          <RadioButtonCheckedOutlined color="action" /> {label}
        </BoxLabel>
      )
    case 'MULTIPLE':
      return (
        <BoxLabel>
          <CheckBoxRounded color="action" /> {label}
        </BoxLabel>
      )
    case 'FREE_TEXT':
      return (
        <BoxLabel>
          <ShortTextRounded color="action" /> {label}
        </BoxLabel>
      )
    case 'RATING':
      return (
        <BoxLabel>
          <StarHalf color="action" /> {label}
        </BoxLabel>
      )
    case 'LEFT':
      return (
        <BoxLabel>
          <FormatAlignLeftOutlined color="action" /> {label}
        </BoxLabel>
      )
    case 'CENTER':
      return (
        <BoxLabel>
          <FormatAlignCenterOutlined color="action" /> {label}
        </BoxLabel>
      )
    case 'RIGHT':
      return (
        <BoxLabel>
          <FormatAlignRightOutlined color="action" /> {label}
        </BoxLabel>
      )
    case 'RANKING':
      return (
        <BoxLabel>
          <FormatListNumbered color="action" /> {label}
        </BoxLabel>
      )
    case 'SEQUENCE':
      return (
        <BoxLabel>
          <FormatListNumbered color="action" /> {label}
        </BoxLabel>
      )
    case 'MATCHING':
      return (
        <BoxLabel>
          <GridView color="action" /> {label}
        </BoxLabel>
      )
    case 'TRUE_FALSE':
      return (
        <BoxLabel>
          <Rule color="action" /> {label}
        </BoxLabel>
      )
    case 'FILL_IN_BLANK_CHOICE':
    case 'FILL_IN_BLANK_DRAG':
      return (
        <BoxLabel>
          <HighlightAlt color="action" /> {label}
        </BoxLabel>
      )
    default:
      return <BoxLabel>{label}</BoxLabel>
  }
}
