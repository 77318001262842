import React, { useMemo, useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Add from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import SelectGroupChip from '../../../../../components/SelectGroupChip'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { typeTime } from '../../../../../constants/MasterData/form'
import {
  setAddStaff,
  setAddOnlineRoom,
} from '../../../../../redux/slices/manageClassForm'
import {
  initOnlineRoom,
  initSpeaker,
} from '../../../../../redux/slices/manageClassForm/model'
import { StyledCard, StyledRowFlex } from '../Styled'
import {
  StyledColumnFlex,
  StyledDivider,
  StyledStaffDisplay,
  StyledColumnNoGap,
  StyledRespRow,
} from './Styled'
import SpeakerForm from './SpeakerForm'
import {
  handleDefaultAdminStaff,
  handleMultiSelectChange,
  filterAdminStaffOption,
  handleChangeTrainingPeriodChange,
  handleFetchRoomAvailable,
  handleAddRoom,
  filterRoomManagementMeetingRoom,
  handleFetchRoomForm,
  fetchRoomAvailable,
} from './events'
import OnlineRoomForm from './OnlineRoomForm'
import TextField from '@mui/material/TextField'
import { overTimeOptions } from '../../../../../constants/roomManagement'
import Select from '../../../../../components/Input/Select'
import { getMeetingRoomById } from '../../../../../services/manageClass/form'
import { handleOverTimeOptions } from '../../../../RoomManagement/BookingDrawer/FormBooking/DetailsBookingCard/event'
import { DetailsRoom } from './DetailRoomForm'

const DateCard = ({ data, index, listValueAdmin }) => {
  const [newSpeakerList, setNewSpeakerList] = useState([])
  const [filterStartOverTimeOptions, setFilterStartOverTimeOptions] =
    useState(overTimeOptions)
  const [filterEndOverTimeOptions, setFilterEndOverTimeOptions] =
    useState(overTimeOptions)
  const [newOnlineRoom, setNewOnlineRoom] = useState(data.onlineRoom)
  const {
    adminStaffOption,
    isDisable,
    monthlyPlan,
    roomManagement,
    trainingPlatForm,
  } = useSelector(
    (state) => ({
      adminStaffOption: state.manageClassForm.adminStaffOption,
      isDisable: state.manageClassForm.isDisable,
      monthlyPlan: state.manageClassForm.data.monthlyPlan,
      roomManagement: state.manageClassForm.data.roomManagement,
      trainingPlatForm: state.manageClassForm.data.trainingPlatForm,
    }),
    shallowEqual,
  )
  const classType = _.get(monthlyPlan, 'classroom.classroom', 'Classroom')
  const dispatch = useDispatch()
  const adminStaffPlan = useMemo(
    () => handleDefaultAdminStaff(data, listValueAdmin),
    [listValueAdmin],
  )
  const { errors } = useFormikContext()
  const filterOption = filterAdminStaffOption(adminStaffOption, listValueAdmin)
  const date = _.get(monthlyPlan, `dates[${index}].date`, '')
  const startTime = _.get(monthlyPlan, `dates[${index}].startTime`, '')
  const endTime = _.get(monthlyPlan, `dates[${index}].endTime`, '')
  const startDateFormat = new Date(`${date} 00:00`)
  const endDateFormat = new Date(`${date} 23:59`)
  const startTimeFormat = new Date(`${date} ${startTime}`)
  const endTimeFormat = new Date(`${date} ${endTime}`)
  let meetingRoomByDateCard = handleFetchRoomForm(index)

  useEffect(() => {
    handleFilterOverTimeOptions()
  }, [])

  useEffect(async () => {
    dispatch(fetchRoomAvailable(index, date))
  }, [
    monthlyPlan.dates[index].beforeTrainingTime,
    monthlyPlan.dates[index].afterTrainingTime,
  ])

  useEffect(() => {
    if (_.isEmpty(roomManagement?.roomManagementMeetingRoom)) {
      dispatch(handleAddRoom(monthlyPlan, index, true))
    }
    dispatch(
      filterRoomManagementMeetingRoom(roomManagement, monthlyPlan, index),
    )
  }, [
    roomManagement,
    monthlyPlan.dates[index].beforeTrainingTime,
    monthlyPlan.dates[index].afterTrainingTime,
  ])

  useEffect(async () => {
    await fetchRoomEquipment()
  }, [monthlyPlan.dates[index].meetingRooms])

  const handleFilterOverTimeOptions = () => {
    setFilterStartOverTimeOptions(
      handleOverTimeOptions(startDateFormat, startTimeFormat),
    )
    setFilterEndOverTimeOptions(
      handleOverTimeOptions(endDateFormat, endTimeFormat),
    )
  }

  const fetchRoomEquipment = async () => {
    let meetingRoomEquipmentResult = []
    await Promise.all(
      _.map(
        _.get(monthlyPlan, `dates[${index}].meetingRooms`, []),
        async (room) => {
          const value = _.get(room, 'value')
          if (value && value !== 'OTHERS') {
            const respone = await dispatch(getMeetingRoomById(value))
            const result = _.get(respone, 'payload.data')

            meetingRoomEquipmentResult.push(result)
          }
        },
      ),
    )
    dispatch(
      handleFetchRoomAvailable(
        meetingRoomEquipmentResult,
        'roomEquipments',
        index,
      ),
    )
  }

  return (
    <StyledCard>
      <HeaderDate data={data} />
      <StyledColumnFlex sx={{ position: 'relative' }}>
        {_.get(trainingPlatForm, '[0].trainingPlatform', '-') !==
          'Virtual Classroom' && (
          <>
            <Typography variant="body1b" color="text.secondary">
              ระยะเวลาการจองห้อง
            </Typography>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <Select
                  name="beforeTrainingTime"
                  labelText="ใช้งานห้องก่อนอบรม"
                  options={filterStartOverTimeOptions}
                  defaultValue={_.get(
                    monthlyPlan,
                    `dates[${index}].beforeTraining`,
                    overTimeOptions[0].value,
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  handleChange={(e) => {
                    dispatch(
                      handleChangeTrainingPeriodChange(
                        e,
                        'beforeTrainingTime',
                        index,
                        data.startTime,
                        data.date,
                        date,
                      ),
                    )
                  }}
                  textError={_.get(errors, 'beforeTrainingTime', '')}
                />
                <Select
                  name="afterTrainingTime"
                  labelText="ใช้งานห้องหลังอบรม"
                  options={filterEndOverTimeOptions}
                  defaultValue={_.get(
                    monthlyPlan,
                    `dates[${index}].afterTraining`,
                    overTimeOptions[0].value,
                  )}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  handleChange={(e) => {
                    dispatch(
                      handleChangeTrainingPeriodChange(
                        e,
                        'afterTrainingTime',
                        index,
                        data.endTime,
                        data.date,
                        date,
                      ),
                    )
                  }}
                  textError={_.get(errors, 'afterTrainingTime', '')}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </>
        )}
        <Typography variant="body1b" color="text.secondary">
          สถานที่จัดอบรม
        </Typography>
        {classType == 'Online' ? (
          <>
            {newOnlineRoom.map((item, oIndex) => (
              <OnlineRoomForm
                key={oIndex}
                onlineRoom={item}
                dIndex={index}
                oIndex={oIndex}
                setNewOnlineRoom={setNewOnlineRoom}
              />
            ))}
            <Button
              data-testId="btn-add-room"
              variant="outlined"
              size="s"
              startIcon={<Add />}
              sx={{ mt: 1, mb: 2 }}
              onClick={() => {
                const newRoom = [...newOnlineRoom]
                newRoom.push({
                  ...initOnlineRoom,
                  no: newOnlineRoom.length + 1,
                })
                setNewOnlineRoom(newRoom)
                dispatch(setAddOnlineRoom({ index }))
              }}
              disabled={isDisable}
            >
              ห้อง
            </Button>
          </>
        ) : (
          <>
            {meetingRoomByDateCard.map((roomSelected, idx) => (
              <DetailsRoom
                key={idx}
                idx={idx}
                cardIndex={index}
                bookingDate={date}
                roomSelected={_.get(roomSelected, 'meetingRoom')}
                meetingRoomByDateDate={roomSelected}
              />
            ))}
            <Button
              sx={{
                width: 90,
                px: 1.25,
                py: 0.5,
                ml: _.size(meetingRoomByDateCard) > 0 ? 10 : 12,
                mt: _.size(meetingRoomByDateCard) > 0 ? -3 : -5,
              }}
              variant="outlined"
              size="s"
              startIcon={<Add />}
              onClick={() => {
                dispatch(handleAddRoom(monthlyPlan, index))
              }}
            >
              เพิ่มห้อง
            </Button>
          </>
        )}
        <StyledDivider />
      </StyledColumnFlex>
      <StyledColumnFlex>
        <Typography variant="body1b" color="text.secondary">
          วิทยากร
        </Typography>
        {data.speakers?.map((item, index) => (
          <StaffDisplay key={index} speaker={item} />
        ))}
        {data.externalSpeakerPlan?.map((item, index) => (
          <StaffDisplay key={index} speaker={item} isExternal />
        ))}
        {data.externalSpeakerPlan?.map((item, sIndex) => (
          <SpeakerForm
            key={sIndex}
            speaker={item}
            dIndex={index}
            sIndex={sIndex}
            setNewSpeakerList={setNewSpeakerList}
          />
        ))}
        <Button
          data-testId="btn-add-speaker"
          variant="outlined"
          size="s"
          startIcon={<Add />}
          sx={{ mt: 1 }}
          onClick={() => {
            const newStaff = [...newSpeakerList]
            newStaff.push({ ...initSpeaker })
            setNewSpeakerList(newStaff)
            dispatch(setAddStaff({ index }))
          }}
          disabled={isDisable}
        >
          วิทยากรภายนอก
        </Button>
      </StyledColumnFlex>
      {classType != 'Online' && (
        <>
          <StyledDivider />
          <StyledRowFlex>
            <SelectGroupChip
              name="admin2"
              labelText="แอดมินรอง"
              isMenuPosition
              placeholder="กรุณาเลือก"
              options={filterOption}
              showTextError={false}
              value={adminStaffPlan}
              onChange={(list) =>
                dispatch(handleMultiSelectChange(list, 'adminStaffPlan', index))
              }
              disabled={isDisable}
            />
          </StyledRowFlex>
        </>
      )}
    </StyledCard>
  )
}

export default DateCard

export const HeaderDate = ({ data }) => {
  const date = convertFormatDateTime({
    value: _.get(data, 'date', ''),
    type: 'date',
  })
  return (
    <Typography variant="h6">
      {date} | {_.get(data, 'startTime', '')} - {_.get(data, 'endTime', '')} (
      {typeTime[_.get(data, 'typeTime', 'FULL')]})
    </Typography>
  )
}

export const StaffDisplay = ({ speaker, isExternal }) => {
  const staff = _.get(speaker, 'staff', '')
  const responsibility = _.get(
    speaker,
    'responsibility.responsibility',
    undefined,
  )
  const startTime = _.get(speaker, 'startTime', '')
  const endTime = _.get(speaker, 'endTime', '')

  if (_.isUndefined(responsibility)) return <></>

  return (
    <StyledStaffDisplay>
      <Typography>
        {_.get(staff, 'firstNameTH', '')} {_.get(staff, 'lastNameTH', '')}{' '}
        <span id="sub">{isExternal && '(โซนอื่น)'}</span>
      </Typography>
      <Typography>{responsibility}</Typography>
      <Typography>
        {startTime} - {endTime}
      </Typography>
    </StyledStaffDisplay>
  )
}
