import React, { useEffect, useRef, useState } from 'react'
import TruncateMarkup from 'react-truncate-markup'
import { StyledTruncateBox } from './Styled'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

const Truncate = ({ children }) => {
  const [expand, setExpand] = useState(false)
  const [isTruncated, setTruncate] = useState(false)
  const [flag, setFlag] = useState(false)
  const [width, setWidth] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [width])

  useEffect(() => {
    setExpand(false)
    setFlag((prev) => !prev)
  }, [children])

  if (children) {
    return (
      <div ref={ref}>
        {!expand && flag && (
          <TextWithTruncate
            width={width}
            isTruncated={isTruncated}
            setTruncate={setTruncate}
            setExpand={setExpand}
          >
            {children}
          </TextWithTruncate>
        )}
        {!expand && !flag && (
          <TextWithTruncate
            width={width}
            isTruncated={isTruncated}
            setTruncate={setTruncate}
            setExpand={setExpand}
          >
            {children}
          </TextWithTruncate>
        )}
        {expand && (
          <StyledTruncateBox
            data-testid="truncate-expand"
            maxWidth={width}
            isTruncated={isTruncated}
            onClick={() => setExpand(false)}
          >
            {children} {isTruncated && <KeyboardArrowUp />}
          </StyledTruncateBox>
        )}
      </div>
    )
  } else {
    return <div ref={ref} />
  }
}

export default Truncate

export const TextWithTruncate = (props) => {
  const { width, isTruncated, setTruncate, setExpand, children } = props
  return (
    <TruncateMarkup
      line="1"
      onTruncate={(isTrunC) => setTruncate(isTrunC)}
      ellipsis={<span>... </span>}
    >
      <StyledTruncateBox
        data-testid="truncate-not-expand"
        maxWidth={width}
        isTruncated={isTruncated}
        onClick={() => setExpand(true)}
      >
        {children}
      </StyledTruncateBox>
    </TruncateMarkup>
  )
}
