import React, { useEffect, useState } from 'react'
import TruncateMarkup from 'react-truncate-markup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import * as lib from '../../../../utils/lib'
import { breadcrumbEdit, breadcrumbForm } from '../../model'
import {
  checkScrollWidth,
  handleError,
  handleSuccess,
  handleValidate,
} from './events'
import { StyledBoxButton, StyledBoxHeader, StyledBoxLabelTitle } from './Styled'

const Header = () => {
  const { id: uuid } = useParams()
  const [user] = useLocalStorage('user')

  const [isScrolled, setScroll] = useState(false)
  const {
    isDraft,
    isFirst,
    isCancel,
    isDisable,
    manageClassData,
    isSuccess,
    error,
  } = useSelector(
    (state) => ({
      isDraft: state.manageClassForm.isDraft,
      isFirst: state.manageClassForm.isFirst,
      isCancel: state.manageClassForm.isCancel,
      isDisable: state.manageClassForm.isDisable,
      manageClassData: state.manageClassForm.data,
      isSuccess: state.manageClassForm.isSuccess,
      error: state.manageClassForm.error,
    }),
    shallowEqual
  )
  const hasPermissionEdit = lib.hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: manageClassData,
  })

  const hasPermissionAdminAll = lib.validatePermission({
    user,
    moduleType: 'E_MANAGECLASS_SETTING',
    permission: ['ADMIN_ALL'],
  })
  const dispatch = useDispatch()
  const formikProps = useFormikContext()
  const nameForLearner = _.get(
    manageClassData,
    'monthlyPlan.trainingInfo.[0].course.nameForLearner',
    ''
  )

  useEffect(() => {
    dispatch(handleSuccess(isSuccess, uuid))
  }, [isSuccess])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  return (
    <StyledBoxHeader scrolled={isScrolled}>
      <StyledBoxLabelTitle isDraft={isDraft}>
        <TruncateMarkup lines={1}>
          <Typography variant="h4">
            คลาส: {_.get(manageClassData, 'course', '')}
          </Typography>
        </TruncateMarkup>
        {!_.isEmpty(nameForLearner) && (
          <TruncateMarkup lines={1}>
            <Typography
              variant="body1b"
              sx={{ lineHeight: '20px', mb: '-6px', mt: '-10px' }}
            >
              ชื่อหลักสูตร (สำหรับ Learner): {nameForLearner}
            </Typography>
          </TruncateMarkup>
        )}
        {!isScrolled && (
          <Breadcrumbs
            menuList={isFirst ? breadcrumbForm : breadcrumbEdit(uuid)}
          />
        )}
      </StyledBoxLabelTitle>
      <StyledBoxButton>
        {isDraft && (
          <Button
            type="submit"
            data-testid="btn-draft"
            variant="outlined"
            disabled={!hasPermissionEdit || isDisable || isCancel}
            size="m"
            onClick={() => {
              const props = { ...manageClassData, isDraft: true }
              dispatch(handleValidate(props, formikProps))
            }}
          >
            บันทึกฉบับร่าง
          </Button>
        )}
        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
          disabled={
            (hasPermissionAdminAll ? false : !hasPermissionEdit || isDisable) ||
            isCancel
          }
          onClick={() => {
            const props = { ...manageClassData, isDraft: false }
            dispatch(handleValidate(props, formikProps))
          }}
        >
          บันทึกและเผยแพร่
        </Button>
      </StyledBoxButton>
    </StyledBoxHeader>
  )
}
export default Header
