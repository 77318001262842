import * as yup from 'yup'
import _ from 'lodash'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import { isValidationExcepted } from '../../events'
import { E_TESTING_TYPE } from './model'

export const eTestingSchema = yup
  .object()
  .nullable()
  .shape({
    type: yup.string().test('required', 'กรุณาเลือก', (value, context) => {
      if (isValidationExcepted(context, MODULE_TYPE.E_TESTING)) return true
      return value
    }),
    uuid: yup
      .string()
      .test('required', 'กรุณาเลือกแบบทดสอบ', (value, context) => {
        if (isValidationExcepted(context, MODULE_TYPE.E_TESTING)) return true
        return value
      }),
    criteriaType: yup
      .string()
      .test('required', 'กรุณาเลือกเกณฑ์คะแนนผ่าน', (value, context) => {
        if (isValidationExcepted(context, MODULE_TYPE.E_TESTING)) return true
        const type = _.get(context.parent, 'type', '')
        if (type === E_TESTING_TYPE.STANDALONE) return true
        return value
      }),
    criteriaPercent: yup
      .string()
      .nullable()
      .test('required', 'กรุณาใส่ % ของคะแนนทั้งหมด', (value, context) => {
        if (isValidationExcepted(context, MODULE_TYPE.E_TESTING)) return true
        const type = _.get(context.parent, 'type', '')
        const criteriaType = _.get(context.parent, 'criteriaType', '')
        if (type === E_TESTING_TYPE.STANDALONE || criteriaType === 'REQUIRED') return true
        return value
      }),
  })
