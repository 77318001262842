import _ from 'lodash'

export const checkPermissionHZ = (user) => {
  const permissionList = _.get(user, 'permission', [])
  const hasApproveZone = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'APPROVE_ZONE' &&
      item.module === 'MONTHLY_PLAN'
    )
  })
  const hasApproveMP = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'APPROVE_MP' &&
      item.module === 'MONTHLY_PLAN'
    )
  })
  const hasReportDashboard = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'REPORT_DASHBOARD' &&
      item.module === 'REPORT_MONTHLY_PLAN'
    )
  })

  return hasApproveZone && !hasApproveMP && !hasReportDashboard
}
