import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { setIsShowEditForm } from './handleClickEditAsset'

export const openHistoryDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenHistoryDrawer',
      value: value,
    }),
  )
}

export const openAssetDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetDrawer',
      value: value,
    }),
  )
}

export const openAssetHistoryDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetHistoryDrawer',
      value: value,
    }),
  )
}

export const openAssetDetailDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenAssetDetailDrawer',
      value: value,
    }),
  )
  if (!value) {
    store.dispatch(
      setFieldValue({
        key: 'assetDetailDrawer',
        value: '',
      }),
    )
    setIsShowEditForm(false)
  }
}
