import React, { useState, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { StyledCard } from './Styled'
import ECertificationView from './ECertificationView'
import ECertificationForm from './ECertificationForm'
import Add from '@mui/icons-material/Add'
import _ from 'lodash'
import { replaceNewLine, validateEPermission } from '../../../utils/lib'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../constants/eCertification'
import TextInput from '../../../components/Input/TextInput'
import { CircularContent } from '../../../modules/ELearning/Course/Form'
import { handleInitialForm } from './event'

const ECertificationSetting = ({
  eCertification,
  initialValues,
  setInitialValues,
  formik,
  isLoading,
  disabled,
}) => {
  const [open, setOpen] = useState(false)
  const [version, setVersion] = useState([])
  const [isSelectVersion, setisSelectVersion] = useState(0)
  const [initECertificationVersionUuid, setInitECertificationVersionUuid] =
    useState(_.get(initialValues, 'eCertificationVersion.uuid', ''))
  const eCertificationVersionUuid = _.get(
    initialValues,
    'eCertificationVersion',
    '',
  )
  const isCertificationPrefix = _.get(
    initialValues,
    'isCertificationPrefix',
    true,
  )

  const initialExpireECertificationYear = _.get(
    initialValues,
    'expireECertificationYear',
    0,
  )

  useEffect(() => {
    if (!_.isEmpty(eCertification)) {
      const GetVersion = _.map(eCertification, (item) => item.version)
      if (!_.isEmpty(initECertificationVersionUuid)) {
        setisSelectVersion(initECertificationVersionUuid)
        const find = _.filter(
          GetVersion,
          (f) => f.uuid === initECertificationVersionUuid,
        )
        if (_.isEmpty(find)) {
          setVersion([...GetVersion, eCertificationVersionUuid])
        } else {
          setVersion([...GetVersion])
        }
      } else {
        setVersion([...GetVersion])
      }
    }
  }, [eCertification])

  useEffect(() => {
    handleInitialForm(initialValues, formik)
  }, [initialValues])

  useEffect(() => {
    if (_.isEmpty(initECertificationVersionUuid)) {
      delete initialValues.expireECertificationYear
    }
  }, [])

  const hasViewPermission = !validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.VIEW,
  })

  const englishCertificateName = useMemo(() => {
    return replaceNewLine(formik.values.englishCertificateName)
  }, [formik.values.englishCertificateName])

  const certificateLanguage = useMemo(() => {
    return (
      _.get(formik.values, 'certificateLanguage', null) ||
      _.get(initialValues, 'certificateLanguage', null)
    )
  }, [formik.values.certificateLanguage])

  return (
    <StyledCard>
      {isLoading && <CircularContent />}
      <Typography variant="h6" sx={{ display: 'flex' }}>
        E-Certificate
        {hasViewPermission && (
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            * ไม่มีสิทธิ์เข้าถึง E-Certification
          </Typography>
        )}
      </Typography>
      <TextInput
        id="englishCertificateName"
        name="englishCertificateName"
        labelText="ชื่อ Certificate"
        type="basic"
        textError={formik?.errors?.englishCertificateName}
        boxSx={{ mt: 2 }}
        inputProps={{ sx: { textAlign: 'center' } }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.englishCertificateName}
        multiline
        rows={2}
        disabled={disabled}
      ></TextInput>

      {open ? (
        <>
          {_.isEmpty(eCertificationVersionUuid) ? (
            <ECertificationForm
              eCertification={version}
              setInitialValues={setInitialValues}
              formik={formik}
              setOpen={setOpen}
              isSelectVersion={isSelectVersion}
              setisSelectVersion={setisSelectVersion}
              disabled={hasViewPermission || disabled}
              initialExpireECertificationYear={initialExpireECertificationYear}
              initECertificationVersionUuid={initECertificationVersionUuid}
              setInitECertificationVersionUuid={
                setInitECertificationVersionUuid
              }
            />
          ) : (
            <ECertificationView
              eCertificationVersionUuid={eCertificationVersionUuid}
              eCertification={version}
              setInitialValues={setInitialValues}
              formik={formik}
              setOpen={setOpen}
              disabled={hasViewPermission || disabled}
              englishCertificateName={englishCertificateName}
              certificateLanguage={certificateLanguage}
              setInitECertificationVersionUuid={
                setInitECertificationVersionUuid
              }
              isCertificationPrefix={isCertificationPrefix}
            />
          )}
        </>
      ) : (
        <>
          {!_.isEmpty(initECertificationVersionUuid) && !_.isEmpty(version) ? (
            <ECertificationView
              eCertificationVersionUuid={initECertificationVersionUuid}
              eCertification={version}
              setInitialValues={setInitialValues}
              formik={formik}
              setOpen={setOpen}
              disabled={hasViewPermission || disabled}
              englishCertificateName={englishCertificateName}
              certificateLanguage={certificateLanguage}
              setInitECertificationVersionUuid={
                setInitECertificationVersionUuid
              }
            />
          ) : (
            <Button
              data-testid="btn-add"
              variant="contained"
              size="l"
              startIcon={<Add />}
              sx={{ mt: 1 }}
              onClick={() => setOpen(true)}
              disabled={hasViewPermission || disabled}
            >
              เพิ่มประกาศนียบัตร
            </Button>
          )}
        </>
      )}
    </StyledCard>
  )
}

export default ECertificationSetting
