import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { contentTypeFile } from '../model/contentTypeFile'
import { scrollToLast } from './scrollToLast'

export const handleAddContentTypeFile = () => {
  const state = store.getState().crud

  const contents = [...state.formData.contents]
  contents.push(contentTypeFile)

  store.dispatch(setFieldValue({ key: 'formData.contents', value: contents }))
  scrollToLast()
}
