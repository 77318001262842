import Tabs from '@mui/material/Tabs'
import styled from '@mui/system/styled'

export const StyledTabs = styled(Tabs)(({ theme, hasBorderBottom }) => ({
  width: '100%',
  borderBottom: hasBorderBottom
    ? `1px solid ${theme.palette?.blue?.blueLine}}`
    : 'none',
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))
