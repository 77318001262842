import { store } from '../../../App'
import _ from 'lodash'

export const reorder = (payload) => {
  const { startIndex, endIndex } = payload
  const { displayColumns } = store.getState().reportComp
  const listColumns = Array.from(displayColumns)
  const [removed] = listColumns.splice(startIndex, 1)
  listColumns.splice(endIndex, 0, removed)

  return _.filter(listColumns, (item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
      sequence: index + 1,
    }
  })
}
