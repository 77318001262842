import { store } from '../../../App'
import {
  setColumnSelected,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import _ from 'lodash'
import { handleDisabledColumnOptions } from '../../../redux/slices/reportComp/event'
import { validationColumn } from './validationColumn'

export const handleAddColumn =
  ({ type = 'default' }) =>
  async (dispatch) => {
    const {
      displayColumns,
      columnSelected,
      newColumnName,
      firstColumnSelected,
      secondaryColumnSelected,
      columnName,
      columnNameTH,
    } = store.getState().reportComp

    const maxIdColumn = _.maxBy(displayColumns, 'id')
    let newColumn = {
      id: maxIdColumn ? maxIdColumn.id + 1 : 1,
      type: type,
      minWidth: columnSelected === columnName.NO ? 60 : 160,
    }
    const key = _.findKey(columnName, (col) => col === columnSelected)
    if (type === 'default') {
      newColumn = {
        ...newColumn,
        name: columnSelected,
        label: columnNameTH[key],
      }
    } else if (type === 'custom') {
      newColumn = {
        ...newColumn,
        name: newColumnName,
        label: newColumnName,
        column: [firstColumnSelected, secondaryColumnSelected],
      }
    }

    const validationResults = await validationColumn(newColumn)

    if (
      (_.isEmpty(validationResults.inner) && type === 'custom') ||
      type === 'default'
    ) {
      const newColumns = [...displayColumns, newColumn]
      await dispatch(
        setReduxValue({ key: 'displayColumns', value: newColumns }),
      )
      await dispatch(handleDisabledColumnOptions())
      await dispatch(setReduxValue({ key: 'newColumnName', value: null }))
      await dispatch(setReduxValue({ key: 'firstColumnSelected', value: null }))
      await dispatch(
        setReduxValue({ key: 'secondaryColumnSelected', value: null }),
      )
      await dispatch(setColumnSelected(null))
    } else {
      dispatch(
        setReduxValue({ key: 'error', value: validationResults.message }),
      )
    }
  }
