import React, { useState } from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'

import ContentVideo from './ContentVideo'
import { InputImageUpload, TooltipForm, BoxTextTooltip } from './Styled'
import * as reducer from '../../../../redux/slices/eEvaluationForm'
import * as service from '../../../../services/eEvaluation/form'
import {
  openDialog,
  openErrorDialog,
  closeDialog,
} from '../../../../redux/slices/dialog'

export const IconUploadImage = ({ testId, onUpload, isQuestion, disabled }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <TooltipForm
      title={<CheckText isQuestion={isQuestion} />}
      placement="bottom"
    >
      <label htmlFor={testId}>
        <IconButton
          onMouseLeave={() => setIsHover(false)}
          onMouseOver={() => setIsHover(true)}
          component="span"
        >
          <ImageOutlined color={isHover ? 'primary' : 'action'} />
        </IconButton>
        {!disabled && (
          <InputImageUpload
            accept="image/jpg, image/jpeg, image/png"
            type="file"
            id={testId}
            onChange={onUpload}
          />
        )}
      </label>
    </TooltipForm>
  )
}

export const handleIconUploadImage = (props) => async (dispatch) => {
  const {
    data,
    type,
    indexQuestion,
    indexAnswer,
    indexSubQuestion,
    subQuestionIndex,
    testId,
  } = props

  let body = new FormData()
  let elementId = document.getElementById(testId)
  if (data.target.files.length === 0) {
    if (elementId) elementId.value = null
    return
  }
  body.append('file', data.target.files[0])
  const getNameImage = _.get(data, 'target.files[0].name', '')

  if (getNameImage) {
    const getExtensionImage = getNameImage.split('.').pop()
    const sizeFile = _.get(data, 'target.files[0].size', 0)

    if (!checkTypeFile(getExtensionImage.toLowerCase())) {
      if (elementId) elementId.value = null
      return dispatch(dialogSizeImage())
    }
    if (sizeFile > 2000000) {
      if (elementId) elementId.value = null
      return dispatch(dialogSizeImage(true))
    }
  }

  const key = mapKeyByType({
    type,
    indexQuestion,
    indexAnswer,
    indexSubQuestion,
    subQuestionIndex,
  })
  const payload = { body, key: `${key}`, type }

  const dataUpload = await dispatch(service.uploadFileEvaluation(payload))

  if (elementId) elementId.value = null

  dispatch(
    reducer.setChange({
      key:
        type === 'answers' || type === 'subQuestionAnswers'
          ? `${key}.mediaUrl`
          : `${key}.url`,
      value: _.get(dataUpload, 'payload.data.key', ''),
    })
  )

  if (type !== 'answers') {
    dispatch(
      reducer.setChange({
        key: `${key}.mediaType`,
        value: 'IMAGE',
      })
    )
  }
}

export const checkTypeFile = (type) => {
  if (type === 'jpg' || type === 'jpeg' || type === 'png') return true
  return false
}

export const CheckText = ({ isQuestion }) => {
  const text = !isQuestion
    ? 'รูปภาพ ขนาดแนะนำ 300x200px,'
    : 'รูปภาพ ขนาดแนะนำ 450x300px,'

  return (
    <BoxTextTooltip>
      <Typography variant="tooltip">{text}</Typography>
      <Typography variant="tooltip">ไฟล์ที่รองรับ .jpg, .jpeg .png</Typography>
    </BoxTextTooltip>
  )
}

export const handleIconVideo = (props) => async (dispatch) => {
  const { type, sequence, isSubQuestions, subQuestionSequence } = props

  if (!isSubQuestions) {
    dispatch(
      reducer.setChangeQuestionByKey({
        ...props,
        key: 'closeVideo',
        value: false,
      })
    )
  } else {
    dispatch(
      reducer.setChangeSubQuestionByKey({
        ...props,
        key: 'closeVideo',
        value: false,
      })
    )
  }

  dispatch(
    openDialog({
      maxWidth: 'sm',
      type: 'content',
      title: 'เพิ่มวิดีโอ',
      agreeText: 'เพิ่ม',
      icon: <VideocamOutlined color="primary" />,

      content: (
        <ContentVideo
          type={type}
          sequence={sequence}
          isSubQuestions={isSubQuestions}
          subQuestionSequence={subQuestionSequence}
        />
      ),
      handleConfirm: () => dispatch(submitDialogVideo(props)),
    })
  )
}

export const submitDialogVideo = (props) => (dispatch) => {
  dispatch(reducer.setVideo(props))
}

export const mapKeyByType = ({
  type,
  indexQuestion,
  indexAnswer,
  indexSubQuestion,
  subQuestionIndex,
}) => {
  switch (type) {
    case 'questions':
      return `${type}[${indexQuestion}]`
    case 'answers':
      return `questions[${indexQuestion}].answers[${indexAnswer}]`
    case 'subQuestions':
      return `subQuestions[${indexSubQuestion}]value[${subQuestionIndex}]`
    case 'subQuestionAnswers':
      return `subQuestions[${indexSubQuestion}]value[${subQuestionIndex}].answers[${indexAnswer}]`
    default:
      return `${type}[0]`
  }
}

export const dialogSizeImage = (isSize) => (dispatch) => {
  let obj = {
    title: 'นามสกุลไฟล์ไม่ถูกต้อง',
    message:
      'ไม่สามารถ Upload ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้องกรุณา Upload ตามที่กำหนดไว้',
  }
  if (isSize) {
    obj = {
      title: 'ขนาดไฟล์ใหญ่เกินไป',
      message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
    }
  }
  dispatch(
    openErrorDialog({
      title: obj.title,
      message: obj.message,
      handleError: () => dispatch(closeDialog()),
    })
  )
}
