export const initialState = {
  isLoading: false,
  bodyDialog: {
    trainingPlanUuid: '',
    date: '',
    round: 0,
    type: 'CHECK_STUDY_ALL',
    isCheckOtherToOpposite: false,
    remark: '',
    classLearnerUuid: [],
  },
  learnerList: [],
}
