import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import HistoryDrawer from '../HistoryDrawer'
import { Typography } from '@mui/material'
import { Row, StyledButton, StyledFormCard } from './Styled'

const Creator = () => {
  const { id: uuid } = useParams()
  const [isOpen, setOpen] = useState(false)
  const {
    isUploadLoading,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    isDraft,
  } = useSelector(
    (state) => ({
      isUploadLoading: state.settingPoint.isUploadLoading,
      createdBy: state.settingPoint.body.createdBy,
      createdAt: state.settingPoint.body.createdAt,
      updatedBy: state.settingPoint.body.updatedBy,
      updatedAt: state.settingPoint.body.updatedAt,
      isDraft: state.settingPoint.isDraft,
    }),
    shallowEqual,
  )
  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <Row>
        <Typography variant="h6">ข้อมูลผู้สร้าง</Typography>
        <StyledButton
          dataTestid="btn-history"
          sx={{ ml: 'auto' }}
          onClick={() => setOpen(true)}
        >
          <HistoryTwoTone />
        </StyledButton>
      </Row>
      <Grid container sx={{ rowGap: 2 }}>
        <Detail label="ผู้สร้าง" data={createdBy || '-'} />
        <Detail
          label="วันที่สร้าง"
          data={
            createdAt
              ? dayjs(createdAt).subtract(7, 'hours').format('DD/MM/YYYY')
              : '-'
          }
        />
        <Detail label="แก้ไขล่าสุดโดย" data={updatedBy || '-'} />
        <Detail
          label="วันที่แก้ไขล่าสุด"
          data={
            updatedAt
              ? dayjs(updatedAt).subtract(7, 'hours').format('DD/MM/YYYY')
              : '-'
          }
        />
        <Detail
          label="สถานะข้อมูล"
          data={isDraft || !uuid ? 'ฉบับร่าง' : 'เผยแพร่'}
          color="primary.main"
        />
      </Grid>
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </StyledFormCard>
  )
}

export default Creator

const Detail = ({ label, data, color = '' }) => {
  return (
    <Grid item xs={6}>
      <Row>
        <Typography varaint="body1" sx={{ width: 122 }}>
          {label}
        </Typography>
        <Typography varaint="body1" color={color || 'text.lightGray'}>
          {data}
        </Typography>
      </Row>
    </Grid>
  )
}
