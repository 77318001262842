import React, { useState, useEffect, useRef, useContext } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PrintIcon from '@mui/icons-material/Print'
import EditIcon from '@mui/icons-material/Edit'
import useTheme from '@mui/system/useTheme'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { useReactToPrint } from 'react-to-print'
import { DialogContext } from '../../context/dialog-context'
import _ from 'lodash'

import View from './View'
import AlertDialog from '../../components/AlertDialog'
import Breadcrumbs from '../../components/Breadcrumbs'
import { courseVersion, filterVersion } from '../../utils/apiPath'
import {
  callAPI,
  getDialogErrorFormMessage,
  getDialogErrorMessage,
  validatePermission,
} from '../../utils/lib'
import { defaultCoursePlatform } from './DefaultValue'

let menuMoreOptions = [
  'คัดลอกเพื่อสร้างหลักสูตรใหม่',
  'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
  'ลบ',
]
let menuDeleteStatusMoreOptions = [
  'คัดลอกเพื่อสร้างหลักสูตรใหม่',
  'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
]

const CourseSettingView = ({ isEditForm, newVersion, cloneBy }) => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(defaultCoursePlatform)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOption, setMenuOption] = useState(menuMoreOptions)

  const open = Boolean(anchorEl)
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const getPermissionCreate = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['CREATE'],
  })

  if (!getPermissionCreate) {
    menuMoreOptions = ['ลบ']
    menuDeleteStatusMoreOptions = []
  }

  const fetchVersion = async (courseCode, url, status = []) => {
    let versionTotalCount = 0
    const body = {
      calendar: false,
      version: -1,
      courseCode: courseCode,
      englishName: '',
      toDate: '',
      page: 1,
      limit: -1,
      order: 'DESC',
      sort: 'updatedAt',
      startDate: '',
      status: status,
      updatedBy: '',
      createdBy: '',
      assignee: '',
    }
    const resultValue = await callAPI({
      url,
      body: body,
      method: 'post',
    })
    versionTotalCount = _.get(resultValue, 'totalCount', 0)

    return versionTotalCount
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchCourseById(
      uuid,
      user,
      setInitialValues,
      setMenuOption,
      fetchVersion,
      setDialog,
      setIsPageLoading
    )
  }, [])
  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            หลักสูตร:{' '}
            {initialValues?.englishName && initialValues.englishName.length > 60
              ? initialValues.englishName.substring(0, 60) + '...'
              : initialValues.englishName}
          </Typography>
        </Box>
        <Breadcrumbs
          sx={{ mx: 3, mb: 3 }}
          menuList={breadcrumbList(initialValues?.courseCode)}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            ml: 2,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            [theme.breakpoints.down('md')]: {
              justifyContent: 'space-between',
              mx: 3,
              mb: 3,
            },
            right: 0,
          }}
        >
          {isPageLoading !== true && (
            <>
              {initialValues.status !== 'DELETED' && (
                <>
                  <Button
                    sx={{
                      mr: 3,
                      [theme.breakpoints.down('lg')]: {
                        display: 'none',
                      },
                    }}
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}
                  >
                    พิมพ์
                  </Button>

                  {validatePermission({
                    user: user,
                    moduleType: 'COURSE_SETTING',
                    permission: ['CREATE'],
                    data: initialValues,
                  }) && (
                    <Button
                      sx={{ mr: 3 }}
                      variant="contained"
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(uuid, history)}
                    >
                      แก้ไข
                    </Button>
                  )}
                </>
              )}
              {((!newVersion && !cloneBy) || isEditForm || !cloneBy) && (
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleMoreClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(e) =>
                      handleMoreClose(
                        e,
                        uuid,
                        initialValues,
                        setDialog,
                        setIsLoading,
                        setAnchorEl
                      )
                    }
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '26ch',
                      },
                    }}
                  >
                    {menuOption.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        name={option}
                        onClick={(e) =>
                          handleMoreClose(
                            e,
                            uuid,
                            initialValues,
                            setDialog,
                            setIsLoading,
                            setAnchorEl
                          )
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              )}
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 3, flexDirection: 'row', mx: 3 }}>
          <Card
            sx={{
              width: '100%',
            }}
          >
            {!isPageLoading && (
              <Box ref={componentRef}>
                <View initialValues={initialValues} />
              </Box>
            )}
          </Card>
        </Box>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </Box>
  )
}
export default CourseSettingView

export const handleDelete = (uuid, initialValues, setDialog, setIsLoading) => {
  setIsLoading(true)
  callAPI({
    url: `${courseVersion}/${uuid}`,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      })
      window.location.href = '/course-setting'
    },
    onError: (er) => {
      setDialog(getDialogErrorFormMessage('', er, initialValues, setDialog))
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const handleEdit = (uuid, history) => {
  history.push(`/course-setting/version/edit/${uuid}`)
}

export const handleMoreClose = (
  e,
  uuid,
  initialValues,
  setDialog,
  setIsLoading,
  setAnchorEl
) => {
  const text = e.target.outerText
  let link = ``
  if (text === 'คัดลอกเพื่อสร้างหลักสูตรใหม่') {
    link = `/course-setting/clone/course/${uuid}`
  } else if (text === 'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่') {
    link = `/course-setting/clone/version/${uuid}`
  } else if (text === 'ลบ') {
    setDialog({
      content: 'คุณต้องการลบรายการนี้หรือไม่',
      variant: 'delete',
      open: true,
      onConfirmClick: () =>
        handleDelete(uuid, initialValues, setDialog, setIsLoading),
      onCancelClick: () =>
        setDialog({
          variant: 'delete',
          content: 'คุณต้องการลบรายการนี้หรือไม่',
          open: false,
        }),
    })
  }
  if (link !== '') {
    window.location.href = link
  }
  setAnchorEl(null)
}

export const breadcrumbList = (courseCode) => {
  return [
    { title: 'จัดการหลักสูตร', link: '/course-setting', pointer: true },
    {
      title: 'เวอร์ชั่น',
      link: '/course-setting/version/' + courseCode,
      pointer: true,
    },
    { title: 'รายละเอียดหลักสูตร', link: '/', pointer: false },
  ]
}

export const checkVersion = async (courseCode, fetchVersion, setMenuOption) => {
  const bodyStatusAll = [
    'ACTIVE',
    'INACTIVE',
    'DELETED',
    'WAITING_FOR_ACTIVE',
    'DRAFT',
  ]
  const bodyStatusDelete = ['DELETED']

  const fetchVersions = await Promise.all([
    fetchVersion(courseCode, filterVersion, bodyStatusAll),
    fetchVersion(courseCode, filterVersion, bodyStatusDelete),
  ])

  const versionAll = _.get(fetchVersions, '[0]', 0)
  const versionDelete = _.get(fetchVersions, '[1]', 0)

  let menuMoreOptionsDeleted = []
  if (versionAll === versionDelete) {
    menuMoreOptionsDeleted = menuDeleteStatusMoreOptions.filter(
      (index) => index !== 'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่'
    )
    setMenuOption(menuMoreOptionsDeleted)
  }
}

export const setCourseData = (
  data,
  user,
  setInitialValues,
  setMenuOption,
  fetchVersion
) => {
  const resultLevel = data.levelOfLearner.map((item) => {
    return item.title
  })
  const resultOtherLevelOfLearner = data.otherLevelOfLearner.map((item) => {
    return item.title
  })
  const resultTrainingPlatform = data.trainingPlatform.map((item) => {
    return item?.trainingPlatform
  })
  const resultAcquiredSkill = data.acquiredSkill.map((item) => {
    return {
      acquiredSkill: _.get(item, 'acquiredSkill', ''),
      skillType: _.get(item, 'skillType.skillType', '-'),
    }
  })
  const resultProductType = data.productType.map((item) => {
    return item.productType
  })
  setInitialValues({
    ...data,
    levelOfLearner: resultLevel,
    otherLevelOfLearner: resultOtherLevelOfLearner,
    trainingPlatform: resultTrainingPlatform,
    acquiredSkill: resultAcquiredSkill,
    productType: resultProductType,
  })
  const getPermissionDelete = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['DELETE'],
    data: data,
  })

  if (!getPermissionDelete || data.status === 'DELETED') {
    setMenuOption(menuDeleteStatusMoreOptions)
  }
  // check version in course to all status delete?
  checkVersion(data.courseCode, fetchVersion, setMenuOption)
}

export const fetchCourseById = async (
  uuid,
  user,
  setInitialValues,
  setMenuOption,
  fetchVersion,
  setDialog,
  setIsPageLoading
) => {
  callAPI({
    url: courseVersion + `/${uuid}`,
    method: 'get',
    onSuccess: (data) => {
      setCourseData(data, user, setInitialValues, setMenuOption, fetchVersion)
    },
    onError: (er) => {
      setDialog(getDialogErrorMessage(er, setDialog))
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}
