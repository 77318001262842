import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import { Form, Formik } from 'formik'
import { initialState } from '../../../redux/slices/manageClassForm/model'
import { getManageClassByUuid } from '../../../services/manageClass/form'
import { LoadingPage } from '../Styled'
import Header from './Header'
import SettingForm from './Form'
import { handleSubmit, getSchema } from './events'
import {
  startLoading,
  setDisable,
  setClearData,
} from '../../..//redux/slices/manageClassForm'
import { hasPermissionDefaultAndMonthlyPlanArea } from '../../../utils/lib'

const Setting = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const { isFirst, isDraft, isLoading, expectNumberPeople, manageClassData } =
    useSelector(
      (state) => ({
        isFirst: state.manageClassForm.isFirst,
        isDraft: state.manageClassForm.isDraft,
        isLoading: state.manageClassForm.isLoading,
        expectNumberPeople:
          state.manageClassForm.data.monthlyPlan.expectNumberPeople,
        manageClassData: state.manageClassForm.data,
      }),
      shallowEqual
    )
  useEffect(() => {
    dispatch(startLoading())
    dispatch(setClearData())
    dispatch(getManageClassByUuid(uuid))
  }, [])

  useEffect(() => {
    if (manageClassData.uuid !== '') {
      const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
        user,
        manageClass: manageClassData,
      })
      if (!hasPermissionEdit) dispatch(setDisable(true))
    }
  }, [manageClassData])
  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Formik
          initialValues={initialState}
          validationSchema={getSchema(manageClassData, expectNumberPeople)}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(value) =>
            dispatch(handleSubmit(value, isFirst || isDraft))
          }
        >
          <Form>
            <Header />

            <SettingForm />
          </Form>
        </Formik>
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default Setting
