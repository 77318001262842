import React from 'react'
import Box from '@mui/material/Box'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const ActionSpeaker = ({ row, rowIndex, onView, onDelete, condition }) => {
  return (
    <Box sx={{ minWidth: 120, display: 'flex', alignItems: 'center' }}>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => onView(row, rowIndex)}
      >
        <RemoveRedEyeTwoToneIcon color="action" />
      </IconButton>
      {row.statusApprove === 'WAITING' && condition.typeRole === 'APPROVER' && (
        <Link
          data-testid={`btn-delete-${rowIndex}`}
          underline="none"
          onClick={() => onDelete(row, rowIndex)}
        >
          <Typography
            variant="body2b"
            sx={{
              color: 'error.main',
            }}
          >
            ยกเลิกคำขอ
          </Typography>
        </Link>
      )}
    </Box>
  )
}

export default ActionSpeaker
