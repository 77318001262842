import React, { useMemo } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Autocomplete from '../../../../../components/Input/Autocomplete'
import { handleSelectChange, handleSettingRadioChange } from '../events'

const EEvaluationForm = ({
  testAndEvaProps,
  setInitialValues,
  options = [],
  formik,
}) => {
  const { testAndEvaArr, index } = testAndEvaProps
  const testAndEva = testAndEvaArr[index]
  const testCriteriaType = _.get(testAndEva, 'testCriteriaType', 'REQUIRED')
  const testAndEvaName = useMemo(() => {
    const selectedVal = options.find(
      (e) => e.value == testAndEva.testOrEvaluateUuid
    )
    return selectedVal || { label: '', value: '' }
  }, [options, testAndEva.testOrEvaluateUuid])

  return (
    <>
      <Autocomplete
        boxSx={{ width: '100%' }}
        name="testAndEvaName"
        labelText="ชื่อ"
        options={options}
        defaultValue={testAndEvaName}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            placeholder="กรุณาเลือก"
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'select-eva-name',
            }}
          />
        )}
        handleChange={(e) =>
          handleSelectChange(
            e.target.id,
            'testOrEvaluateUuid',
            testAndEvaProps,
            setInitialValues
          )
        }
        textError={_.get(
          formik.errors,
          `testAndEvaluate[${index}].testOrEvaluateUuid`,
          ''
        )}
        required
      />
      <Box>
        <Typography variant="body2" color="text.secondary">
          การทำแบบประเมิน <span>*</span>
        </Typography>
        <RadioGroup
          data-testid="radio-testCriteriaType"
          sx={{ pl: 1.5, gap: 0.5 }}
          aria-label="testCriteriaType"
          name="testCriteriaType"
          value={testCriteriaType}
          onChange={(e) =>
            handleSettingRadioChange(e, testAndEvaProps, setInitialValues)
          }
        >
          <FormControlLabel
            value="REQUIRED"
            control={<Radio />}
            label="ต้องทำ"
          />
          <FormControlLabel
            value="NOT_REQUIRED"
            control={<Radio />}
            label="ไม่ต้องทำ"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default EEvaluationForm
