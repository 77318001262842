import React from 'react'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import { PRODUCT_TYPE_TEXT_HERF } from '../../../../../constants/stock'
import _ from 'lodash'

const ActionSku = ({ row, rowIndex, minWidth, condition }) => {
  const uuid = _.get(row, 'productWarehouse.product.uuid')
  const productType = _.get(row, 'productWarehouse.product.productType')
  const viewUrl = `${condition.viewPath}/${PRODUCT_TYPE_TEXT_HERF[productType]}/view/${uuid}`
  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => window.open(viewUrl, '_blank').focus()}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionSku
