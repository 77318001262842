import { path } from './path'

export const headCellsCatalog = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    label: 'ID',
    width: '70px',
    minWidth: '70px',
  },
  {
    id: 'image',
    label: 'รูปภาพ',
    width: '80px',
    minWidth: '80px',
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อของรางวัล',
  },
  {
    id: 'point',
    label: 'คะแนนที่ใช้แลก',
    width: '100px',
    minWidth: '100px',
    type: 'number',
  },
  {
    id: 'qty',
    label: 'จำนวนรางวัล',
    width: '100px',
    minWidth: '100px',
    type: 'number',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.catalog}/view`,
    editPath: `${path.catalog}/edit`,
  },
]

export const headCellsSettingPoint = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'name',
    label: 'ชื่อคะแนน',
    minWidth: '400px',
  },
  {
    id: 'point',
    label: 'จำนวนคะแนน',
    width: '100px',
    minWidth: '100px',
    type: 'number',
    defaultValue: '-',
  },
  {
    id: 'badgeName',
    label: 'ชื่อเหรียญรางวัล',
    minWidth: '350px',
    width: '350px',
  },
  {
    id: 'badgeImage',
    label: 'เหรียญรางวัล',
    width: '80px',
    minWidth: '80px',
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.settingPoint}/view`,
    editPath: `${path.settingPoint}/edit`,
  },
]

export const MODULE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  DRAFT: 'DRAFT',
}

export const versionStatus = {
  ACTIVE: 'เผยแพร่',
  INACTIVE: 'เผยแพร่',
  DELETED: 'ถูกลบแล้ว',
  DRAFT: 'ฉบับร่าง',
}

export const USER_POINT_EVENT = {
  INCREASE_FROM_ADMIN: 'INCREASE_FROM_ADMIN',
  DECREASE_FROM_ADMIN: 'DECREASE_FROM_ADMIN',
  INCREASE_FROM_COURSE: 'INCREASE_FROM_COURSE',
  REDEEMED: 'REDEEMED',
}
