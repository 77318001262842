import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getBatchLearnerDetail } from '../../../../services/eLearning/course/form'
import {
  getLearnerAttend,
  getLearnerDetail,
  getLearnerDetailRound,
  getLearnerRandomClick,
  getLearnerRekog,
} from '../../../../services/eLearning/course/learner'
import { initialState } from './model'
import { TABLE_KEY } from '../../../../modules/ELearning/Course/Learner/model'
import { MODULE_TYPE_TH } from '../../../../modules/ELearning/Course/View/Content/model'

const eLearningCourseBatchLearnerDetail = createSlice({
  name: 'eLearningCourseBatchLearnerDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setClickLoading: (state, { payload }) => {
      state.tables.randomClickPerLessons.isLoading = payload
    },
    setAttendLoading: (state, { payload }) => {
      state.tables.courseLogLearners.isLoading = payload
    },
    setModuleLoading: (state, { payload }) => {
      state.tables.courseLogLearnerPerLessons.isLoading = payload
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setChangeRowsPerPage: (state, { payload }) => {
      const key = _.get(payload, 'key', '')
      state.tables[key].rowsPerPage = payload.rowsPerPage
      state.tables[key].limit = payload.limit
      state.tables[key].tablePage = 0
      state.tables[key].page = 1
    },
    setChangePage: (state, { payload }) => {
      const key = _.get(payload, 'key', '')
      state.tables[key].page = payload.page
      state.tables[key].tablePage = payload.tablePage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerDetailRound.fulfilled, (state, { payload }) => {
        state.learningRound = payload
      })
      .addCase(getLearnerDetailRound.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLearnerDetail.fulfilled, (state, { payload }) => {
        const data = payload.data
        state.enrollData = _.get(data, 'learnerRekognition', '')
        state.tables.courseLogLearnerPerLessons.isShowPagination = false
        const modules = _.get(data, TABLE_KEY.module, []).map((log) => {
          const result = _.get(log, 'data.result', '')
          const eLearningModule = _.get(
            log,
            'eLearningLessonRefModule.eLearningModule',
            {},
          )
          const moduleType = _.get(eLearningModule, 'moduleType', '')
          return {
            ...log,
            result,
            eLearningModule,
            moduleType: MODULE_TYPE_TH[moduleType],
          }
        })
        state.tables.courseLogLearnerPerLessons.rows = modules
        state.tables.courseLogLearnerPerLessons.allCount = modules.length
      })
      .addCase(getLearnerDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLearnerRekog.fulfilled, (state, { payload }) => {
        state.learnerRekognition = payload.data
        state.learningRound = [payload.data]
      })
      .addCase(getLearnerRekog.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getBatchLearnerDetail.fulfilled, (state, { payload }) => {
        state.result = payload
      })
      .addCase(getBatchLearnerDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLearnerRandomClick.fulfilled, (state, { payload }) => {
        state.tables.randomClickPerLessons.rows = _.get(
          payload,
          'result',
          [],
        ).map((log) => {
          const dateTime = log.randomDateTime
          return { ...log, date: dateTime, time: dateTime }
        })
        state.tables.randomClickPerLessons.allCount = _.get(
          payload,
          'totalCount',
          0,
        )
      })
      .addCase(getLearnerRandomClick.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLearnerAttend.fulfilled, (state, { payload }) => {
        state.tables.courseLogLearners.rows = _.get(payload, 'result', []).map(
          (log) => ({ ...log, date: log.createdAt }),
        )
        state.tables.courseLogLearners.allCount = _.get(
          payload,
          'totalCount',
          0,
        )
      })
      .addCase(getLearnerAttend.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  setClickLoading,
  setAttendLoading,
  setModuleLoading,
  setChange,
  setChangeRowsPerPage,
  setChangePage,
} = eLearningCourseBatchLearnerDetail.actions

export default eLearningCourseBatchLearnerDetail.reducer
