import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useLocalStorage } from '@rehooks/local-storage'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import FilterDrawer from './FilterDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  skillType,
  downloadSkillType,
  filterSkillType,
  filterAcquiredSkill,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '30px',
    minWidth: '30px',
  },
  {
    id: 'skillType',
    label: 'ประเภทของทักษะ',
    disablePadding: false,
  },
  {
    id: 'acquiredSkills',
    label: 'ระดับหลักสูตร',
    disablePadding: false,
    isArray: true,
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ประเภทของทักษะ', link: '/', pointer: false },
]

const AcquiredSkillList = () => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const history = useHistory()
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [textFilter, setTextFilter] = useState({
    acquiredSkills: [],
  })
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [acquiredSkillList, setAcquiredSkillList] = useState([])
  const [filterTotal, setFilterTotal] = useState(0)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  useEffect(() => {
    setIsPageLoading(true)
    fetchSkillTypeList(
      'fetch',
      status,
      limit,
      order,
      page,
      sort,
      searchText,
      textFilter
    )
  }, [limit, order, sort, page])

  const fetchSkillTypeList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    filter
  ) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)

    const body = {
      skillType: method !== 'fetch' ? search : searchText,
      acquiredSkills: filter.acquiredSkills,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
    }
    await getAcquiredSkillList(setAcquiredSkillList)
    await callAPI({
      url: filterSkillType,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        let resultFilter = res.result || []
        if (resultFilter.length) {
          resultFilter = resultFilter.filter((item) => {
            item.acquiredSkills = filterAcquiredSkillName({
              listLevel: item.acquiredSkills || [],
            })
            return item
          })
        }
        setDataList(resultFilter)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">ประเภทของทักษะ (Skill Type)</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />

        <Button
          data-testid={`btn-add-skill-type`}
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          disabled={!hasPermissionCreate}
          onClick={() => history.push('/master-data/skill-type/form')}
          startIcon={<AddIcon />}
          variant="contained"
        >
          เพิ่มประเภทของทักษะ
        </Button>

        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={dataList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            onDelete={(row) => onDelete(row, dialog, setIsLoading, setDialog)}
            placeholder="ค้นหาประเภทของทักษะ"
            searchKey={'skillType'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(text) =>
              handleQuickSearch(
                text,
                limit,
                order,
                sort,
                setTablePage,
                setTextFilter,
                fetchSkillTypeList
              )
            }
            onDownload={(selected) =>
              onDownload(selected, order, sort, setIsLoading)
            }
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick(
            filter,
            limit,
            order,
            sort,
            setStatus,
            setText,
            setSearchText,
            setTextFilter,
            setTablePage,
            fetchSkillTypeList,
            setFilterTotal
          )
        }
        acquiredSkillList={acquiredSkillList}
      />
    </Box>
  )
}
export default AcquiredSkillList

export const onView = (row, history) => {
  history.push('/master-data/skill-type/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/skill-type/edit/' + row.uuid)
}

export const onDelete = async (row, dialog, setIsLoading, setDialog) => {
  setIsLoading(false)
  setDialog({
    ...dialog,
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    open: true,
    onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const handleDelete = async (row, setIsLoading, setDialog) => {
  const path = skillType + `/${row.uuid}`
  setIsLoading(true)
  await callAPI({
    url: path,
    method: 'DELETE',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const onFilterClick = async (
  filter,
  limit,
  order,
  sort,
  setStatus,
  setText,
  setSearchText,
  setTextFilter,
  setTablePage,
  fetchSkillTypeList,
  setFilterTotal
) => {
  setSearchText('')
  setText('')
  const statusList = []

  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }

  setStatus(statusList)

  const titleText = filter?.skillTypeChecked ? filter.skillTypeText : ''

  const objAcquiredSkill = filter?.acquiredSkill || {}
  let listAcquiredSkill = []

  if (filter.acquiredSkillChecked && Object.keys(objAcquiredSkill).length) {
    for (let keyIndex in objAcquiredSkill) {
      const value = objAcquiredSkill[keyIndex]
      if (value.status) {
        listAcquiredSkill.push(value.uuid)
      }
    }
  }
  const filterText = { acquiredSkills: listAcquiredSkill }
  setSearchText(titleText)
  setTextFilter(filterText)
  setTablePage(0)
  fetchSkillTypeList(
    'filter',
    statusList,
    limit,
    order,
    1,
    sort,
    titleText,
    filterText
  )
  setFilterTotal(handleFilterTotal(filter))
}

export const onDownload = async (selected, order, sort, setIsLoading) => {
  setIsLoading(true)
  const body = {
    skillType: '',
    acquiredSkills: [],
    order: order.toUpperCase(),
    sort: sort === '' ? 'updatedAt' : sort,
    status: [],
    type: 'CUSTOM',
    list: selected,
  }
  await handleDownload({
    url: downloadSkillType,
    body: body,
    fileName: `รายการประเภทของทักษะ.xlsx`,
  })
  setIsLoading(false)
}

export const handleQuickSearch = async (
  text,
  limit,
  order,
  sort,
  setTablePage,
  setTextFilter,
  fetchSkillTypeList
) => {
  setTablePage(0)
  const filterText = {
    acquiredSkills: [],
  }
  setTextFilter(filterText)
  const statusList = []
  fetchSkillTypeList(
    'search',
    statusList,
    limit,
    order,
    1,
    sort,
    text,
    filterText
  )
}

export const filterAcquiredSkillName = ({ listLevel }) => {
  let result = []
  if (listLevel.length) {
    listLevel.filter((item) => {
      result.push(item.acquiredSkill || '')
    })
  }
  return result
}

export const getAcquiredSkillList = async (setAcquiredSkillList) => {
  const body = {
    acquiredSkill: '',
    limit: -1,
    order: 'ASC',
    page: 1,
    sort: 'id',
    status: ['ACTIVE'],
  }
  await callAPI({
    url: filterAcquiredSkill,
    method: 'POST',
    body: body,
    onSuccess: (res) => {
      setAcquiredSkillList(res.result || [])
    },
  })
}
