import React from 'react'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column, Row } from '../Styled'

export const StyledDivider = styled((props) => (
  <Divider {...props} orientation="vertical" variant="middle" flexItem />
))(({ theme }) => ({ borderColor: theme.palette?.other?.divider }))

export const StyledLabelIcon = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(1),
  color: theme.palette?.text?.secondary,
}))

export const StyledRowContent = styled(Row)(({ theme }) => ({
  textAlign: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& #training': { display: 'none' },
  },
}))

export const StyledRowTwo = styled(Row)(({ theme }) => ({
  width: '50%',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: { width: '100%' },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& .MuiDivider-root': { display: 'none' },
  },
}))

export const StyledHalfColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: '100%' },
}))

export const StyledContainer = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'isOnly',
})(({ theme, isOnly }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginBottom: isOnly ? 0 : theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    gap: 10,
    marginBottom: isOnly ? 0 : theme.spacing(2),
    alignItems: 'start',
  },
}))

export const StyledDetailCard = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({
  width: fullWidth ? '100%' : 'calc(50% - 8px)',
  maxHeight: 82,
  minHeight: 82,
  background: theme.palette?.background?.paper,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(3),
  justifyContent: fullWidth ? 'center' : 'start',
  '& > .MuiTypography-root': { textAlign: 'center' },
  [theme.breakpoints.down('lg')]: {
    width: fullWidth ? '100%' : 'calc(33% - 9px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: fullWidth ? '100%' : 'calc(50% - 5px)',
    minHeight: 65,
    padding: `6px ${theme.spacing(1)}`,
    gap: 10,
    '&#timeSpan': { paddingRight: 0 },
    img: { width: 20, height: 20 },
    '& > .MuiTypography-root': { textAlign: 'start' },
  },
}))

export const StyledAssigneeWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(1),
  '& > .MuiBox-root': { display: 'flex', width: '100%', gap: theme.spacing(2) },
  [theme.breakpoints.between(768, 1280)]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > .MuiBox-root': { width: 'calc(50% - 4px)' },
  },
}))

export const StyledAssigneeImage = styled('img')(({ theme }) => ({
  width: 70,
  height: 70,
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: { width: 36, height: 36 },
}))
