import React from 'react'
import Box from '@mui/material/Box'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import IconButton from '@mui/material/IconButton'

const ActionStaff = ({ row, rowIndex, onView, onEdit, condition }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => onView(row, rowIndex)}
      >
        <RemoveRedEyeTwoToneIcon color="action" />
      </IconButton>

      {(condition.userId === row.uuid || condition.hideEdit) && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onEdit(row, rowIndex)}
        >
          <EditTwoToneIcon color="action" />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionStaff
