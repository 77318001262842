import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import TextInput from '../../../components/Input/TextInput'
import Select from '../../../components/Input/Select'

import { Column, Row, StyledCard, StyledSwitchButton } from './Styled'
import {
  handleInitialForm,
  handleSettingStatusChange,
  handleSettingRadioChange,
} from './events'
import { initailSettingPoint } from '../DefaultValue'

const PonitSetting = ({
  initialValues,
  setInitialValues,
  formik,
  pointOptions,
}) => {
  const [settingPoint, setSettingPoint] = useState(initailSettingPoint)
  const pointStatus = useMemo(
    () => _.get(settingPoint, 'pointStatus', 'ACTIVE'),
    [settingPoint],
  )

  const pointType = useMemo(
    () => _.get(settingPoint, 'pointType', 'CUSTOM'),
    [settingPoint],
  )

  useEffect(() => {
    handleInitialForm(initialValues, setSettingPoint, formik)
  }, [initialValues])

  return (
    <StyledCard>
      <Typography variant="h6">คะแนนหลักสูตร</Typography>
      <StyledSwitchButton>
        <Switch
          checked={pointStatus === 'ACTIVE'}
          onChange={(e) => handleSettingStatusChange(e, setInitialValues)}
        />
        <Box>
          <Typography>เปิดการได้รับคะแนน</Typography>
        </Box>
      </StyledSwitchButton>
      <Column>
        <Row>
          <Typography variant="body2" color={'text.secondary'}>
            กำหนดรูปแบบคะแนน
          </Typography>
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        </Row>
        <RadioGroup
          sx={{ pl: 1.5, gap: 0.5, mb: 1 }}
          name="pointType"
          value={pointType}
          onChange={(e) => handleSettingRadioChange(e, setInitialValues)}
        >
          <FormControlLabel
            value="CUSTOM"
            control={<Radio />}
            label="กำหนดเอง"
          />
          <FormControlLabel value="LINK" control={<Radio />} label="ผูกคะแนน" />
        </RadioGroup>
        {pointType == 'CUSTOM' ? (
          <TextInput
            id="point"
            name="point"
            labelText="จำนวนคะแนน"
            type="basic"
            textError={formik?.errors?.point}
            isShowTextError={formik?.errors?.point}
            required
            onChange={(e) =>
              setInitialValues((prev) => ({
                ...prev,
                point: e.target.value.replace(/[^0-9]/g, ''),
              }))
            }
            onBlur={formik.handleBlur}
            value={formik.values.point}
          />
        ) : (
          <Select
            id="learningPoint"
            name="learningPoint"
            labelText="เลือกชื่อคะแนน"
            type="basic"
            textError={formik?.errors?.learningPoint}
            showTextError={formik?.errors?.learningPoint}
            required
            options={pointOptions}
            handleChange={(e) =>
              setInitialValues((prev) => ({
                ...prev,
                learningPoint: e.target.value,
              }))
            }
            value={formik.values.learningPoint}
            placeholder="กรุณาเลือก"
          />
        )}
      </Column>
    </StyledCard>
  )
}

export default PonitSetting
