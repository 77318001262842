import React, { useState } from 'react'
import { convertFormatDateTime } from '../../utils/lib'
import _ from 'lodash'
import Drawer from '@mui/material/Drawer'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import { ROW_PAGE_PER_LIST } from '../../constants/table'

import useTheme from '@mui/system/useTheme'
import { statusText } from './MasterData'

export const handleChangePage = (event, newPage, setApproveDrawer) => {
  setApproveDrawer(() => ({ newPage }))
}

export const handleChangeRowsPerPage = (
  event,
  setRowsPerPage,
  setApproveDrawer,
) => {
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1
  setRowsPerPage({ label: '' + rowsPerPageChange, value: rowsPerPageChange })
  setApproveDrawer((prev) => ({
    ...prev,
    page: 0,
    limit: parseInt(event.target.value, 10),
  }))
}

const ApproveListDrawer = (props) => {
  const {
    onClose,
    state,
    approveDrawer,
    setApproveDrawer,
    setIsDrawer,
    optionWork,
    getWaitingApproveBtnText,
  } = props
  const theme = useTheme()
  const { approveList } = state
  const [rowsPerPage, setRowsPerPage] = useState({ label: '100', value: 100 })
  const masterPlanStatus = _.get(state, 'masterPlan.status', 'DRAFT')

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor={'right'}
      open={approveDrawer.open}
      onClose={onClose}
    >
      <Box
        sx={{
          my: 2,
          mx: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography variant="h5">{getWaitingApproveBtnText}</Typography>
        <IconButton
          sx={{ p: 0 }}
          data-testid={`btn-close-drawer`}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      <Box
        sx={{
          mx: theme.spacing(3),
          mt: theme.spacing(3),
        }}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 250,
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ maxWidth: 250 }}>
              <TableRow>
                <TableCell sx={{ width: '140px' }}>วันที่ทำรายการ</TableCell>
                <TableCell sx={{ width: '160px' }}>รายการ</TableCell>
                <TableCell sx={{ width: '450px' }}>หลักสูตร</TableCell>
                <TableCell sx={{ width: '180px' }}>วันที่อบรม</TableCell>
                <TableCell sx={{ width: '120px' }}>สถานะ</TableCell>
                <TableCell sx={{ width: '80px' }}>คำสั่ง</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approveList &&
                approveList.map((row, rowIndex) => {
                  const course = _.get(
                    row,
                    'course.englishName',
                    _.get(row, 'courseOther', ''),
                  )
                  const startDate = convertFormatDateTime({
                    value: _.get(row, 'monthlyPlan.startDate', null),
                    type: 'date',
                  })
                  const finishDate = convertFormatDateTime({
                    value: _.get(row, 'monthlyPlan.finishDate', null),
                    type: 'date',
                  })

                  return (
                    <TableRow
                      key={row?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell scope="row">
                        {row?.monthlyPlan?.createdAt || '-'}
                      </TableCell>
                      <TableCell>{row?.approvalEventList || '-'}</TableCell>
                      <TableCell>{course}</TableCell>
                      <TableCell>{`${startDate}-${finishDate}`}</TableCell>
                      <TableCell>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mr: 1 }}
                        >
                          <FiberManualRecordRoundedIcon
                            fontSize="small"
                            sx={{
                              color: `${
                                statusText(row.status, masterPlanStatus).color
                              }`,
                            }}
                          />
                          {statusText(row.status, masterPlanStatus).text}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          data-testid={`btn-view-${rowIndex}`}
                          color="primary"
                          component="span"
                          onClick={() =>
                            setIsDrawer({
                              status: true,
                              type: _.get(row, 'status', 'view').toLowerCase(),
                              publicId: _.get(row, 'monthlyPlan.uuid', ''),
                              work: {
                                id: _.get(row, 'monthlyPlan.uuid', ''),
                                workType: optionWork.find((item) => {
                                  return _.get(row, 'workType', '') === item.id
                                }),
                              },
                            })
                          }
                        >
                          <RemoveRedEyeTwoToneIcon color="action" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={approveDrawer.count}
          page={approveDrawer.page}
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          onPageChange={(event, tablePage) =>
            handleChangePage(event, tablePage, setApproveDrawer)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, setRowsPerPage, setApproveDrawer)
          }
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า :{' '}
            </Typography>
          }
          labelDisplayedRows={() => {
            const realPage =
              approveDrawer.page <= 0 ? 1 : approveDrawer.page + 1
            let minRowsShowed =
              approveDrawer.page < 0
                ? 1
                : rowsPerPage.value * approveDrawer.page + 1
            let maxRowsShowed =
              rowsPerPage.value * realPage > approveDrawer.count
                ? approveDrawer.count
                : rowsPerPage.value * realPage

            if (maxRowsShowed < 0) {
              maxRowsShowed = approveDrawer.count
            }
            if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

            return (
              <Typography variant="caption">
                {minRowsShowed}-{maxRowsShowed} of {approveDrawer.count}
              </Typography>
            )
          }}
        />
      </Box>
    </Drawer>
  )
}
export default ApproveListDrawer
