import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import Course from './Course'
import Lecturer from './Lecturer'
import Management from './Management'
import Other from './Other'
const Index = () => {
  const eEvaluationType = useSelector(
    (state) => state.eEvaluationForm.eEvaluationType,
    shallowEqual
  )
  return (
    <>
      {eEvaluationType === 'CLASS' && (
        <>
          <Course />
          <Lecturer />
          <Management />
        </>
      )}
      <Other />
    </>
  )
}

export default Index
