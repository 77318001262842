import { setHandleChange } from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import _ from 'lodash'

export const handleChange = ({ key, value, filter, listKey, type }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (type === 'select_multi_dropdown') {
    objState = {
      ...filter,
      [key]: [...filter.key, value],
    }
  }

  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  store.dispatch(setHandleChange({ key: 'filterState', value: objState }))
}
