import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { StyledCard } from '../../../Styled'
import { Row } from '../../Styled'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import { moduleArray } from '../../model'
import ModuleCard from './ModuleCard'

const OverviewModule = () => {
  const { module, totalModule, overviewModule } = useSelector(
    (state) => ({
      module: state.eLearningDashboard.overview.chartData.module,
      totalModule: state.eLearningDashboard.overview.chartData.totalModule,
      overviewModule: state.eLearningDashboard.overview.module,
    }),
    shallowEqual,
  )
  return (
    <StyledCard sx={{ mt: 2, p: 2, maxWidth: '100%', minWidth: '100%' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        สรุปรวม Module
      </Typography>
      <Row sx={{ gap: 4 }}>
        <DoughnutChart
          dataSet={{
            data: module,
            backgroundColor: [
              '#7EE29C',
              '#FFDC89',
              '#93DFFF',
              '#D64F65',
              '#99C2FF',
              '#E99E7D',
            ],
          }}
          height={224}
          label="ทั้งหมด"
          value={totalModule}
        />
        <Grid container spacing={0.5}>
          {moduleArray.map((ele, ind) => {
            return (
              <Grid key={ind} item xs={4}>
                <ModuleCard
                  name={ele.name}
                  label={ele.label}
                  background={ele.background}
                  dotColor={ele.dotColor}
                  value={overviewModule[ele.name]}
                  unit={ele.unit}
                  percent={`${(
                    (overviewModule[ele.name] * 100) /
                    totalModule
                  ).toFixed(2)}%`}
                />
              </Grid>
            )
          })}
        </Grid>
      </Row>
    </StyledCard>
  )
}

export default OverviewModule
