import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'

import Autocomplete from '../../../components/Input/Autocomplete'
import Select from '../../../components/Input/Select'
import DatePicker from '../../../components/Input/DatePicker'

export const getDefaultArea = (area) => {
  if (!area || area.length === 0) return ''
  const findDefaultArea = area.find((item) => item.default === true)
  return findDefaultArea.uuid
}
const Working = (props) => {
  const {
    formik,
    onChangeDepartment,
    onChangeStation,
    onChangeStaffType,
    onDateChange,
    initialValues,
    defaultStaffSelected,
    onSelectChange,
    levelOptions,
    staffOptions,
    departmentOptions,
    costCenterOptions,
    ageWork,
    checkPermission,
    isPageLoading,
    onClickStation,
    jobTitleOptions,
    onChangeJobTitle,
  } = props
  const staffTypeOptions = [
    { label: 'Trainer', value: 'Trainer' },
    { label: 'Staff', value: 'Staff' },
  ]

  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ]

  const getNotSelectedStation = (options) => {
    if (_.isNil(options)) return []
    return options
  }

  const addStationBox = () => {
    formik.setFieldValue(`area`, [
      ...formik.values.area,
      {
        id: 0,
        uuid: '',
        department: {
          uuid: '',
          department: '',
          initial: '',
          status: 'ACTIVE',
        },
        station: {
          uuid: '',
          station: '',
          initial: '',
          status: 'ACTIVE',
        },
        default: false,
        stationOptions: [],
      },
    ])
  }

  const removeStationBox = ({ index }) => {
    if (index === formik.values.defaultAreaIndex) {
      formik.setFieldValue('defaultAreaIndex', 0)
    } else if (index < formik.values.defaultAreaIndex) {
      formik.setFieldValue(
        'defaultAreaIndex',
        formik.values.defaultAreaIndex - 1,
      )
    }
    formik.setFieldValue('courseCondition', formik.values.area.splice(index, 1))
  }

  const errorsArea =
    _.isNull(_.get(formik, 'errors.area', [])) ||
    _.isUndefined(_.get(formik, 'errors.area', []))
      ? []
      : _.get(formik, 'errors.area', [])

  const isAreaError = _.find(
    errorsArea,
    (item) => item?.uuid === 'กรุณากรอกข้อมูลในช่องนี้ให้ครบทุกช่อง',
  )
  return (
    <>
      {!isPageLoading && (
        <Card
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            minWidth: 275,
            pt: 2,
            px: 3,
          }}
        >
          <Typography variant="h6">ข้อมูลการทำงาน</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
            <Select
              dataTestid="select-position"
              id="position"
              name="position"
              labelText="ประเภทของพนักงาน"
              type="basic"
              disabled={!checkPermission ? true : false}
              required
              options={staffTypeOptions}
              handleChange={(e) => onChangeStaffType(e.target.value)}
              value={_.get(formik, 'values.position', '')}
              textError={
                _.get(formik, 'errors.position', '') !== '' &&
                defaultStaffSelected === ''
                  ? 'กรุณากรอกข้อมูลในช่องนี้'
                  : ''
              }
              placeholder={'เลือก'}
            ></Select>
            <Box sx={{ width: '100%' }}>
              <Typography variant="body2">กลุ่มสิทธิ์การใช้งาน</Typography>
              <Typography>{initialValues?.group || '-'}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Select
              dataTestid="select-level"
              id="level"
              name="level"
              labelText="Level"
              type="basic"
              textError={_.get(formik, 'errors.level', '')}
              required
              options={levelOptions}
              handleChange={(e) => onSelectChange(e, 'level')}
              value={_.get(formik, 'values.level', '')}
              placeholder={'เลือก'}
            ></Select>
            <Select
              dataTestid="job-title"
              id="jobTitle"
              name="jobTitle"
              labelText="Job Title"
              type="basic"
              disabled={!checkPermission ? true : false}
              textError={_.get(formik, 'errors.jobTitles', '')}
              required
              options={jobTitleOptions}
              handleChange={(e) => onChangeJobTitle(e, 'jobTitles')}
              value={_.get(formik, 'values.jobTitles.uuid', '')}
              placeholder={'เลือก'}
            ></Select>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Select
              dataTestid="select-cost-center"
              id="costCenter"
              name="costCenter"
              labelText="Cost Center"
              type="basic"
              disabled={!checkPermission ? true : false}
              textError={_.get(formik, 'errors.costCenter', '')}
              required
              options={costCenterOptions}
              handleChange={(e) => onSelectChange(e, 'costCenter')}
              value={_.get(formik, 'values.costCenter', '')}
              placeholder={'เลือก'}
            ></Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              width: '100%',
            }}
          >
            <Typography
              sx={{ width: '5%' }}
              variant="body2"
              color={'text.secondary'}
            >
              Default
            </Typography>
            <Typography
              sx={{ width: '40%', display: 'flex', flexDirection: 'row' }}
              variant="body2"
              color={'text.secondary'}
            >
              Department
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
            <Typography
              sx={{ width: '10%' }}
              variant="body2"
              color={'text.secondary'}
            >
              ชื่อย่อ
            </Typography>
            <Typography
              sx={{ width: '40%', display: 'flex', flexDirection: 'row' }}
              variant="body2"
              color={'text.secondary'}
            >
              Station
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Typography>
            <Box sx={{ width: '5%' }} />
          </Box>
          <RadioGroup value={formik.values.defaultAreaIndex}>
            {formik.values.area.map((item, index) => {
              return (
                <Box
                  key={item.uuid}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 3,
                    width: '100%',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      width: '5%',
                      justifyContent: 'center',
                      height: '40px',
                    }}
                    value={index}
                    control={
                      <Radio
                        sx={{ verticalAlign: 'middle' }}
                        disabled={!checkPermission ? true : false}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'defaultAreaIndex',
                            +e.target.value,
                          )
                        }}
                      />
                    }
                  />
                  <Select
                    dataTestid="select-department"
                    id="department"
                    name="department"
                    type="basic"
                    boxSx={{ width: '40%' }}
                    disabled={!checkPermission ? true : false}
                    options={departmentOptions}
                    handleChange={(e) => onChangeDepartment(e, index)}
                    value={_.get(
                      formik,
                      `values.area[${index}].department.uuid`,
                      '',
                    )}
                    placeholder={'เลือก'}
                  />
                  <Typography
                    sx={{ width: '10%' }}
                    variant="body2"
                    color={'text.secondary'}
                  >
                    {item.department.initial}
                  </Typography>
                  <Select
                    dataTestid="select-area-uuid"
                    id="areaUuid"
                    name="areaUuid"
                    type="basic"
                    boxSx={{ width: '40%' }}
                    disabled={!checkPermission ? true : false}
                    textError={_.get(formik, 'errors.areaUuid', '')}
                    options={getNotSelectedStation(
                      formik.values.area[index].stationOptions,
                    )}
                    handleClick={(e) => onClickStation(e, index)}
                    handleChange={(e) => onChangeStation(e, index)}
                    value={_.get(formik, `values.area[${index}].uuid`, '')}
                    placeholder={'เลือก'}
                  ></Select>
                  <Box sx={{ width: '5%' }}>
                    {formik.values.area.length > 1 && (
                      <IconButton
                        disabled={!checkPermission ? true : false}
                        data-testid={`btn-delete-area`}
                        onClick={() => removeStationBox({ index })}
                      >
                        <DeleteOutlineIcon color="action" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              )
            })}
          </RadioGroup>
          {isAreaError && (
            <Typography variant="body2" color="error">
              {' '}
              {isAreaError?.uuid}
            </Typography>
          )}
          <Box sx={{ pb: 2 }}>
            <Button
              variant="contained"
              size="s"
              disabled={
                formik.values?.area[formik.values.area?.length - 1]?.uuid ===
                  '' || !checkPermission
                  ? true
                  : false
              }
              onClick={() => addStationBox()}
            >
              เพิ่ม
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <DatePicker
              id="joinDate"
              name="joinDate"
              labelText="วันเริ่มต้น"
              disabledInput={!checkPermission ? true : false}
              textError={_.get(formik, 'errors.joinDate', '')}
              required
              onChange={(value) => onDateChange(value, 'joinDate')}
              value={_.get(formik, 'values.joinDate', '')}
            />
            <Box sx={{ width: '100%' }}>
              <Typography variant="body2">อายุงาน</Typography>
              <Typography>{ageWork}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Select
              dataTestid="select-status"
              id="statusWorking"
              name="statusWorking"
              labelText="สถานะการทำงาน"
              type="basic"
              disabled={!checkPermission ? true : false}
              required
              options={statusOptions}
              handleChange={(e) => onSelectChange(e, 'status')}
              textError={_.get(formik, 'errors.status', '')}
              value={_.get(formik, 'values.status', '')}
              placeholder={'เลือก'}
              boxSx={{ width: '50%' }}
            ></Select>

            <Autocomplete
              id="reportTo"
              name="reportTo"
              labelText="Report to"
              options={staffOptions}
              defaultValue={_.get(formik, 'values.reportTo', {
                label: '',
                value: '',
              })}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  placeholder="ค้นหา หรือเลือก"
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-report-to',
                  }}
                  {...params}
                />
              )}
              handleChange={(e) => onSelectChange(e, 'reportTo')}
              textError={_.get(formik, 'errors.reportTo', '')}
              boxSx={{ width: '50%' }}
              disabled={!checkPermission ? true : false}
              required
            />
          </Box>
        </Card>
      )}
    </>
  )
}
export default Working
