export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const defaultFilter = {
  initialChecked: false,
  academyStationNameChecked: false,
  stationNameChecked: false,
  locationCodeChecked: false,
  createdByChecked: false,
  updatedByChecked: false,
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}

export const arrayCheckbox = [
  {
    label: 'ตัวย่อ',
    nameCheckBox: 'initialChecked',
    nameInput: 'initial',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อสเตชั่นสำหรับสนามสอบ',
    nameCheckBox: 'academyStationNameChecked',
    nameInput: 'academyStationName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อสเตชั่น EN สำหรับ Academy',
    nameCheckBox: 'stationNameChecked',
    nameInput: 'stationName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'รหัสสถานที่สอบ',
    nameCheckBox: 'locationCodeChecked',
    nameInput: 'locationCode',
    placeholder: 'ค้นหา',
    type: 'number',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

