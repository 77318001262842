import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Close from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import { ContentHistory, DrawerHistory, HeadLabelHistory } from './Styled'
import { setFieldValue } from '../../../../redux/slices/crud'
import Table from '../../../redux/Table'
import { fetchHistory } from '../../handler/fetchHistory'

const CHistoryDrawer = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { isShowHistoryDrawer: isOpen } = useSelector((state) => state.crud)
  const { page, sort, limit } = table
  const realPage = table.page <= 0 ? 1 : +table.page
  const params = `?limit=${limit}&page=${realPage}`

  useEffect(() => {
    if (isOpen) {
      fetchHistory({ uuid, params }).then()
    }
  }, [isOpen, page, sort, limit])

  return (
    <DrawerHistory
      anchor={'right'}
      open={isOpen}
      onClose={() =>
        dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: false }))
      }
    >
      <ContentHistory isLoading={table.isLoading}>
        <HeadLabelHistory>
          <Box>
            <Typography variant="h5">ประวัติการแก้ไข</Typography>
            <IconButton
              data-testid="btn-close-history"
              color="primary"
              component="span"
              onClick={() =>
                dispatch(
                  setFieldValue({ key: 'isShowHistoryDrawer', value: false }),
                )
              }
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ mx: 4, mt: 3 }}>
            <Table />
          </Box>
        </HeadLabelHistory>
      </ContentHistory>
    </DrawerHistory>
  )
}

export default CHistoryDrawer
