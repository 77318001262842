import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as apiPath from '../../utils/apiPath'

import { mapDataOptions } from '../eTesting/form'
import {
  setChange,
  setChangeEvaluationInfo,
} from '../../redux/slices/eEvaluationForm'

export const submitFormEvan = createAsyncThunk(
  'eEvaluation/submitForm',
  async (body, { rejectWithValue }) => {
    try {
      let result = {}
      if (body.uuid) {
        result = await axios.put(apiPath.eEvaluationVersion, body)
      } else {
        result = await axios.post(apiPath.eEvaluationVersion, body)
      }
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getFormDetailEva = createAsyncThunk(
  'eEvaluation/getFormDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiPath.eEvaluationVersion}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAssigneesEva = createAsyncThunk(
  'eEvaluation/assignees',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(apiPath.eEvaluationAssignees)
      return {
        result: mapDataOptions(_.get(response, 'data.result', [])),
        totalCount: _.get(response, 'data.totalCount', 0),
      }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getHistoryEva = createAsyncThunk(
  'eEvaluation/history',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(apiPath.eEvaluationHistory + url)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const deleteVersionEva = createAsyncThunk(
  'eEvaluation/deleteVersion',
  async (uuid, { rejectWithValue }) => {
    try {
      const result = await axios.delete(apiPath.eEvaluationVersion + `/${uuid}`)
      return result
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

/// upload image
export const uploadFileEvaluation = createAsyncThunk(
  'eEvaluation/uploadFileImage',
  async (payload, { rejectWithValue, dispatch }) => {
    const { body, key, type } = payload

    try {
      // reset loading progress before upload
      const newKey = type === 'information' ? key : `${key}.loadingProgress`
      if (type === 'information')
        dispatch(setChangeEvaluationInfo({ key: newKey, value: 1 }))
      else dispatch(setChange({ key: newKey, value: 1 }))

      const result = await axios.post(apiPath.eEvaluationUploadFile, body, {
        onUploadProgress: (progressEvent) =>
          dispatch(handleUploadFileProgress(progressEvent, type, newKey)),
      })
      return result
    } catch (err) {
      const errorProps = {
        key: type === 'information' ? 'uploadError' : `${key}.uploadError`,
        value: err.response.status,
      }

      if (type === 'information') dispatch(setChangeEvaluationInfo(errorProps))
      else dispatch(setChange(errorProps))

      return rejectWithValue([], err)
    }
  }
)

export const handleUploadFileProgress =
  (progressEvent, type, key) => (dispatch) => {
    let totalLength = progressEvent.total
    if (!progressEvent.lengthComputable) {
      totalLength =
        progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
    }

    if (totalLength !== null) {
      let loadingProgress = Math.round(
        (progressEvent.loaded * 100) / totalLength
      )

      const propsValue = { key, value: loadingProgress }
      const propsError = { key: 'uploadError', value: undefined }

      if (type === 'information') {
        dispatch(setChangeEvaluationInfo(propsValue))
        dispatch(setChangeEvaluationInfo(propsError))
      } else {
        dispatch(setChange(propsValue))
        dispatch(setChange(propsError))
      }
    }
  }

export const filterStateEvaluation = createAsyncThunk(
  'eEvaluation/stat',
  async (payload, { rejectWithValue }) => {
    const { uuid, body } = payload
    try {
      const response = await axios.post(
        `${apiPath.eEvaluationStat}/${uuid}`,
        body
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const fetchCourseAndStaff = createAsyncThunk(
  'eEvaluation/statFilter',
  async (payload, { rejectWithValue }) => {
    const { uuid, body } = payload
    try {
      const response = await axios.post(
        `${apiPath.eEvaluationStatFilter}/${uuid}`,
        body
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
