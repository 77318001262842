import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { path } from './../../../../../constants/path'
import { resetTimeUp } from './../../../../../redux/slices/eTestingPreview'
import { setStopModule } from './../../../../../redux/slices/eLearning/preview'
import {
  StyledContainer,
  StyledResultContent,
} from './../../../../../components/Preview/Result/Styled'
import BannerShowResult from './BannerShowResult'
import BannerFinish from './BannerFinish'

export const BannerTimeOut = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_error.svg" />
      <Typography variant="h3" color="error">
        หมดเวลาทำแบบทดสอบ
      </Typography>
      <Typography
        variant="h5"
        color="text.secondary"
        sx={{ width: 246, textAlign: 'center' }}
      >
        หมดเวลาในการทำแบบทดสอบ โปรดรอซักครู่
      </Typography>
    </StyledResultContent>
  )
}

const Result = ({ isModule }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { testResult, isTimeUp } = useSelector(
    (state) => ({
      testResult: state.eTestingPreview.setting.testResult,
      isTimeUp: state.eTestingPreview.isTimeUp,
    }),
    shallowEqual,
  )
  const backPath = `${path.eTesting}/preview/${uuid || 'on-create'}`

  useEffect(() => {
    setTimeout(() => {
      if (isTimeUp) dispatch(resetTimeUp())
    }, 3000)
  }, [])

  return (
    <StyledContainer>
      {isTimeUp ? (
        <BannerTimeOut />
      ) : testResult == 'LATER' ? (
        <BannerFinish />
      ) : (
        <BannerShowResult isModule={isModule} />
      )}
      {!isTimeUp && (
        <Link
          component="button"
          variant="subtitle2"
          onClick={() =>
            isModule
              ? dispatch(setStopModule())
              : (window.location.href = backPath)
          }
        >
          กลับหน้าหลัก
        </Link>
      )}
    </StyledContainer>
  )
}

export default Result
