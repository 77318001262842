import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledDialog } from '../../Styled'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { closeDialog } from '../../../../../redux/slices/dialog'
import {
  StyledDialogActions,
  StyledCancelButton,
  StyledLoadingButton,
} from '../../../../../components/redux/PopUpDialog/Styled'
import { STOCK_TYPE, STOCK_TYPE_TEXT } from '../../../../../constants/stock'
import { setStockType } from '../../../../../redux/slices/inventory/stock/list'
import { handleSubmitToForm } from './events'
import { useHistory } from 'react-router-dom'

const CreateStockDialog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoading, disableButton, warehouseType } = useSelector(
    (state) => ({
      isLoading: state.dialog.isLoading,
      disableButton: state.dialog.disableButton,
      warehouseType: state.stockList.warehouseType,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(setStockType(STOCK_TYPE.ACADEMY))
  }, [])

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">สร้างคลังทรัพย์สิน</Typography>
      </Box>
      <StyledDialog sx={{ p: 0, mt: 0, gap: 1, mx: 2 }}>
        <Typography variant="body2" color="text.secondary">
          ประเภทคลังทรัพย์สิน
        </Typography>
        <RadioGroup
          data-testid="radio-changeStockType"
          sx={{ pl: 1.5, gap: 0.5 }}
          aria-label="changeStockType"
          name="changeStockType"
          defaultValue={warehouseType}
          onChange={(e) => {
            const { value } = e.target
            dispatch(setStockType(value))
          }}
        >
          <FormControlLabel
            value={STOCK_TYPE.ACADEMY}
            control={<Radio />}
            label={STOCK_TYPE_TEXT.ACADEMY}
          />
          <FormControlLabel
            value={STOCK_TYPE.PROVIDER}
            control={<Radio />}
            label={STOCK_TYPE_TEXT.PROVIDER}
          />
        </RadioGroup>
        <StyledDialogActions sx={{ p: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => dispatch(closeDialog())}
              color="primary"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() => dispatch(handleSubmitToForm(history))}
            color="primary"
            disabled={disableButton}
            endIcon={<></>}
          >
            ตกลง
          </StyledLoadingButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}
export default CreateStockDialog
