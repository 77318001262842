import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'
import Breadcrumbs from '../../../../components/Breadcrumbs'

export const listBreadcrumb = (uuid) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ห้องประชุม',
      link: '/master-data/meeting-room',
      pointer: true,
    },
    {
      title: uuid ? 'แก้ไขห้องประชุม' : 'เพิ่มห้องประชุม',
      link: '/',
      pointer: false,
    },
  ]
}

const Index = () => {
  const { id: uuid } = useParams()
  const [labelText, setLabelText] = useState('')

  const { roomName } = useSelector(
    (state) => ({
      roomName: state.masterDataMeetingRoomForm.information.roomName,
    }),
    shallowEqual
  )

  useEffect(() => {
    _.isEmpty(labelText) && setLabelText(roomName)
  }, [roomName])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="h4">
          {uuid ? `แก้ไขห้องประชุม : ${labelText}` : 'เพิ่มห้องประชุม'}
        </Typography>
        <Breadcrumbs menuList={listBreadcrumb(uuid)} />
      </Box>
    </Box>
  )
}

export default Index
