import React from 'react'

import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormGroupLeaveStaff } from '../FormView'

export default function index({ dataList }) {
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
          ผู้รับผิดชอบงาน
        </Typography>

        <FormGroupLeaveStaff listStaff={_.get(dataList, 'staffs', [])} />
      </Box>

      <Box sx={{ display: 'flex', mt: 3 }}>
        <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
          กิจกรรม
        </Typography>
        <Typography variant="body1">
          {_.get(dataList, 'workPlace', 'OFFICE') === 'OFFICE'
            ? 'Work from office'
            : 'Smart working'}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Typography variant="body1" sx={{ width: 200, minWidth: 200, mr: 3 }}>
          รายละเอียดเพิ่มเติม
        </Typography>
        {_.get(dataList, 'description', '')}
      </Box>
    </>
  )
}
