import { getModuleTitle } from '../../../../../modules/EContent/utils'
import { crudConfig } from '../../crudConfig'

export const createContentBreadcrumbList = (uuid) => [
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: getModuleTitle(uuid, crudConfig.modulesTitleShort),
    link: `${crudConfig.modulePath}/view`,
    pointer: false,
  },
]
