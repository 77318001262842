import { StyledRow } from '../../Styled'
import { Typography } from '@mui/material'
import { TooltipBox } from './TooltipBox'
import { TooltipLabel } from './TooltipLabel'

export const RowLabels = ({
  title,
  label,
  titleSx,
  moreThanOne = false,
  showTooltipLabel = false,
  listAll = [],
}) => {
  return (
    <StyledRow sx={{ gap: 1 }}>
      <Typography sx={{ ...titleSx }} variant="body2" color="text.gray">
        {title}
      </Typography>

      {showTooltipLabel ? (
        <TooltipLabel label={label} />
      ) : (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 300,
          }}
          variant="body2b"
        >
          {label} {moreThanOne && <TooltipBox tooltipTitle={listAll} />}
        </Typography>
      )}
    </StyledRow>
  )
}
