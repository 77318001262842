export const vendorTypeEnum = {
  VENDOR: 'VENDOR',
  ASSET: 'ASSET',
  OTHER: 'OTHER',
}

export const vendorTypeTextEnum = {
  VENDER: 'Vendor',
  VENDOR: 'Vendor',
  ASSET: 'Asset',
  OTHER: 'Other',
}