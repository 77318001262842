import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { assessmentField } from './../../../constants/eEvaluation'
import {
  getBetweenRedirectPage,
  isHaveType,
  resetNextQuestionAnswer,
} from './events'
import { initialState } from './model'

const eEvaluationPreview = createSlice({
  name: 'eEvaluationPreview',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setInitialState: (state, { payload }) => {
      state = Object.assign(state, payload)
    },
    setInitialPreview: (state, { payload }) => {
      state.uuid = _.get(payload, 'uuid', undefined)
      state.information = _.get(
        payload,
        'information',
        initialState.information,
      )
      state.setting = _.get(payload, 'setting', initialState.setting)
      state.courses = _.get(payload, 'courses', [])
      state.lecturers = _.get(payload, 'lecturers', [])
      state.courseManagements = _.get(payload, 'courseManagements', [])
      state.questions = _.get(payload, 'questions', [])
      state.page = initialState.page
      state.section = initialState.section
    },
    setInititialAssessment: (state, { payload }) => {
      const {
        pointerCourse,
        pointerLecturer,
        pointerCourseManagement,
        pointerQuestion,
      } = payload
      const assessmentList = []

      // section 1
      if (isHaveType(pointerCourse)) {
        pointerCourse.forEach((item) => {
          const list = { ...item, section: 1 }
          assessmentList.push(list)
        })
      }

      // section 2
      if (isHaveType(pointerLecturer)) {
        let section = 2
        if (!isHaveType(pointerCourse)) section--

        pointerLecturer.forEach((item) => {
          const list = { ...item, section }
          assessmentList.push(list)
        })
      }

      // section 3
      if (isHaveType(pointerCourseManagement)) {
        let section = 3
        if (!isHaveType(pointerCourse)) section--
        if (!isHaveType(pointerLecturer)) section--

        pointerCourseManagement.forEach((item) => {
          const list = { ...item, section }
          assessmentList.push(list)
        })
      }

      // section 4
      if (isHaveType(pointerQuestion)) {
        let section = 4
        if (!isHaveType(pointerCourse)) section--
        if (!isHaveType(pointerLecturer)) section--
        if (!isHaveType(pointerCourseManagement)) section--
        if (section == 1) section = -1
        pointerQuestion.forEach((item) => {
          const list = { ...item, section }
          assessmentList.push(list)
        })
      }

      _.set(state, 'constantList', assessmentList)
      _.set(state, 'assessmentList', assessmentList)
      _.set(state, 'totalAssessments', assessmentList.length)
    },
    setAssignSubQuestion: (state, { payload }) => {
      const { keyQuestion, value } = payload
      const assessmentList = _.get(state, 'assessmentList', [])
      const question = state.questions.find((item) => item.key == keyQuestion)
      const index = assessmentList.findIndex(
        (item) => item.keyField == 'questions' && item.key == question.key,
      )
      const choice = question.choices.find((item) => item.uuid == value)
      const nextList = assessmentList[index + 1]
      const haveSubNext = !!nextList && !!_.get(nextList, 'keySub', undefined)
      const subQuestions = _.get(choice, 'subQuestions', null)

      if (subQuestions) {
        const subQuestionKey = {
          type: subQuestions.type,
          key: subQuestions.sequence,
          keySub: subQuestions.subSequence,
          keyField: assessmentField.question,
          uuid: subQuestions.uuid,
        }
        if (haveSubNext) {
          assessmentList[index + 1] = subQuestionKey
        } else {
          assessmentList.splice(index + 1, 0, subQuestionKey)
        }
      } else {
        if (haveSubNext) assessmentList.splice(index + 1, 1)
      }

      _.set(state, 'totalAssessments', assessmentList.length)
    },
    setAnsweredRating: (state, { payload }) => {
      const { keyField, keyAssessment, keyQuestion, keySub, value } = payload
      const assessmentState = state[keyField].find(
        (item) => item.key === keyAssessment,
      )
      if (!keySub) {
        const itemState = assessmentState.questions.find(
          (question) => question.key === keyQuestion,
        )
        _.set(itemState, 'answer', Number(value))
        _.set(itemState, 'isAnswered', true)

        const isCompleted = !assessmentState.questions.some(
          (item) => !item.isAnswered,
        )
        if (isCompleted) _.set(assessmentState, 'isCompleted', isCompleted)
      } else {
        const subState = assessmentState.choices.find(
          (item) => item.nextSubQuestionSequence == keySub,
        )
        const subQuestion = _.get(subState, 'subQuestions', {})
        const itemSubState = subQuestion.questions.find(
          (item) => item.key == keyQuestion,
        )
        _.set(itemSubState, 'answer', Number(value))
        _.set(itemSubState, 'isAnswered', true)

        const isCompleted = !subQuestion.questions.some(
          (item) => !item.isAnswered,
        )
        if (isCompleted)
          _.set(subState, 'subQuestions.isCompleted', isCompleted)
      }
    },
    setChangeStateByKey: (state, { payload }) => {
      const { keyQuestion, key, keySub, value } = payload
      const itemState = state.questions.find((item) => item.key === keyQuestion)
      if (!keySub) {
        _.set(itemState, key, value)
      } else {
        const itemSubState = itemState.choices.find(
          (item) => item.nextSubQuestionSequence == keySub,
        )
        _.set(itemSubState, `subQuestions.${key}`, value)
      }
    },
    setAnswered: (state, { payload }) => {
      const { keyQuestion, value } = payload
      const itemState = state.questions.find((item) => item.key === keyQuestion)

      let isAnswered = !_.isEqual(value, '')

      if (itemState.type == 'MULTIPLE') {
        isAnswered = itemState.answer.some((item) => item.isChecked)
      }

      _.set(itemState, 'isAnswered', isAnswered)
    },
    // Display question one per page
    setRedirectPage: (state, { payload }) => {
      const { from, to } = payload
      const fromIndex = state.assessmentList.findIndex(
        (item) => item.key == from,
      )
      const assessmentOld = state.assessmentList.slice(0, fromIndex)
      const assessmentNew = state.constantList.slice(from - 1)
      const assessmentFiltered = assessmentNew.filter(
        (item) => !getBetweenRedirectPage(payload, item),
      )
      const newAssessmentList = _.concat(assessmentOld, assessmentFiltered)
      const targetPage = newAssessmentList.findIndex((item) => item.key === to)
      _.set(state, 'assessmentList', newAssessmentList)
      _.set(state, 'totalAssessments', newAssessmentList.length)
      _.set(state, 'page', targetPage + 1)
      _.set(state, 'questions', resetNextQuestionAnswer(state, from))
    },
    resetRedirectPage: (state, { payload }) => {
      const fromIndex = state.assessmentList.findIndex(
        (item) => item.key == payload,
      )
      const assessmentOld = state.assessmentList.slice(0, fromIndex)
      const assessmentNew = state.constantList.slice(payload - 1)
      const newAssessmentList = _.concat(assessmentOld, assessmentNew)
      _.set(state, 'assessmentList', newAssessmentList)
      _.set(state, 'totalAssessments', newAssessmentList.length)
      _.set(state, 'questions', resetNextQuestionAnswer(state, payload))
    },
    setNextQuestion: (state) => {
      state.page = state.page + 1
    },
    setPreviousQuestion: (state) => {
      state.page = state.page - 1
    },
    setSectionEvaluate: (state) => {
      state.section = 'EVALUATE'
    },
    setSectionResult: (state) => {
      state.section = 'RESULT'
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setInitialState,
  setInitialPreview,
  setInititialAssessment,
  setAssignSubQuestion,
  setAnsweredRating,
  setChangeStateByKey,
  setAnswered,
  setRedirectPage,
  resetRedirectPage,
  setNextQuestion,
  setPreviousQuestion,
  setSectionEvaluate,
  setSectionResult,
} = eEvaluationPreview.actions

export default eEvaluationPreview.reducer
