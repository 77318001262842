import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'E_CONTENT_SUB_CATEGORY',
  moduleSlug: 'e-content-sub-category',
  moduleTitleFull: 'หมวดหมู่ย่อย คลังความรู้',
  modulesTitleShort: 'หมวดหมู่ย่อย',
  modulePath: '/master-data/e-content/sub-category',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
