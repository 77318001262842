import React from 'react'
import { StyledDivider } from '../../../../components/View/Styled'
import Detail from './Detail'
import Point from './Point'
import Lesson from './Lesson'

const Course = () => {
  return (
    <>
      <Detail />

      <StyledDivider />

      <Point />

      <StyledDivider />

      <Lesson />
    </>
  )
}

export default Course
