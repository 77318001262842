import { Box } from '@mui/system'
import ViewString from './ViewString'
import { Typography } from '@mui/material'

export const MatchAnswer = ({ data, seq, openAll }) => {
  const totalAns = data?.countTrueAns + data?.countFalseAns
  let correctPercentage = '0%'
  let incorrectPercentage = '0%'
  if (totalAns !== 0) {
    correctPercentage =
      ((data?.countTrueAns / totalAns) * 100)
        .toFixed(2)
        .replace(/[.,]00$/, '') + '%'
    incorrectPercentage =
      ((data?.countFalseAns / totalAns) * 100)
        .toFixed(2)
        .replace(/[.,]00$/, '') + '%'
  }
  return (
    <Box
      sx={{
        display: 'grid',
        gap: '12px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{ margin: '6px', alignContent: 'center', minWidth: '5%' }}
        >
          คู่ที่ {seq + 1}
        </Typography>
        <ViewString
          value={data?.question}
          limit={75}
          customSx={{
            fontWeight: 'bold',
            height: 'fit-content',
            margin: '6px',
          }}
          buttonSx={{ padding: 0 }}
          openAll={openAll}
        />
        <Typography
          variant="body1"
          color="#00008F"
          fontWeight="bold"
          sx={{ margin: '6px', alignContent: 'center', minWidth: '5%' }}
        >
          คู่กับ
        </Typography>
        <ViewString
          value={data?.title}
          limit={75}
          customSx={{
            fontWeight: 'bold',
            height: 'fit-content',
            margin: '6px',
          }}
          buttonSx={{ padding: 0 }}
          openAll={openAll}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          width: '100%',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
      >
        <Typography color="#5F5F5F">จำนวนคนตอบถูก</Typography>
        <Box
          sx={{
            height: '42px',
            width: correctPercentage,
            backgroundColor: '#1CC54E',
            borderRadius: '4px',
            '@media print': {
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        />
        <Typography sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}>
          {data?.countTrueAns} ({correctPercentage})
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          width: '100%',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
      >
        <Typography color="#5F5F5F">จำนวนคนตอบผิด</Typography>
        <Box
          sx={{
            height: '42px',
            alignItems: 'center',
            width: incorrectPercentage,
            backgroundColor: '#C91432',
            borderRadius: '4px',
            '@media print': {
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        />
        <Typography sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}>
          {data?.countFalseAns} ({incorrectPercentage})
        </Typography>
      </Box>
    </Box>
  )
}
