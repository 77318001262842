import { setFieldValue } from '../../../../../redux/slices/crud'
import {
  clearFilter,
  resetPage,
  resetTablePage,
  setFilterTotal,
  setHandleChange,
  //setHandleChange,
  setSearchText,
} from '../../../../../redux/slices/table'
import { fetchDataList } from './fetchDataList'

export const handleQuickSearch =
  (table, keyword, mutatePropToBody, props, methodSearch, tableHeadText = '') =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    if (props.defaultFilter) {
      dispatch(
        setHandleChange({ key: 'filterState', value: props.defaultFilter }),
      )
      dispatch(
        setHandleChange({
          key: 'filter.filterProp',
          value: { ...props.defaultFilter, testResult: [] },
        }),
      )
    } else {
      dispatch(clearFilter())
    }
    dispatch(
      setFieldValue({ key: 'filterStateBelt', value: props.defaultFilter }),
    )
    dispatch(setFilterTotal(0))
    if (methodSearch === 'search' || methodSearch === 'searchVersion') {
      dispatch(
        setHandleChange({ key: 'filter.filterProp', value: { name: keyword } }),
      )
    }
    dispatch(resetTablePage())
    if (table.page === 1) {
      fetchDataList(
        mutatePropToBody,
        { ...props, method: methodSearch },
        '',
        tableHeadText,
      )
    } else {
      dispatch(resetPage())
    }
  }
