import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'

import TextInput from '../../components/Input/TextInput'
import SwitchInput from '../../components/Input/SwitchInput'
import AlertDialog from '../../components/AlertDialog'
import { hasPermissionCreateMasterData } from '../../utils/lib'

const TrainingPlatformForm = ({
  isEditForm,
  dialog,
  setDialog,
  handleClose,
  formik,
  initialValues,
  setInitialValues,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              boxSx={{ mx: 3 }}
              value={initialValues.id}
            ></TextInput>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="trainingPlatform"
            name="trainingPlatform"
            labelText="รูปแบบการสอน"
            type="basic"
            inputProps={{
              'data-testid': 'trainingPlatform',
            }}
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.trainingPlatform}
            required
            boxSx={{ mx: 3 }}
            value={initialValues.trainingPlatform}
            onChange={(e) => {
              formik.values.trainingPlatform = e.target.value
              setInitialValues({
                ...initialValues,
                trainingPlatform: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
          <TextInput
            id="abbreviation"
            name="abbreviation"
            labelText="ชื่อย่อ"
            type="basic"
            inputProps={{
              'data-testid': 'abbreviation',
            }}
            textError={formik?.errors?.abbreviation}
            required
            boxSx={{ mx: 3 }}
            disabled={!hasPermissionCreate}
            value={initialValues.abbreviation}
            onChange={(e) => {
              formik.values.abbreviation = e.target.value
              setInitialValues({
                ...initialValues,
                abbreviation: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            defaultChecked
            value={initialValues.status}
            disabled={!hasPermissionCreate}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
            boxSx={{ mx: 3 }}
          ></SwitchInput>
        </Box>

        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย{' '}
                {initialValues && initialValues.updatedBy
                  ? initialValues.updatedBy
                  : ''}
                <br />
                {initialValues && initialValues.updatedAt
                  ? initialValues.updatedAt
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </Card>
  )
}
export default TrainingPlatformForm
