import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import React from 'react'
import { BoxContent } from '..'
import { BoxContainer } from '../Styled'
import { prefix, prefixTH } from './model'
import { ImgSection, Input, InputSectionContainer, ProfileImg } from './Styled'
import Select from '../../../../../components/Input/Select'
import TextFieldInput from '../../../../../components/Input/TextFieldInput'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  handleChangeTH,
  handleChange,
  handleUpload,
  handleDelete,
} from './events'

const MainInformation = ({ checkClick }) => {
  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual
  )
  const dispatch = useDispatch()
  const userId = _.get(data, 'info.uuid', '-')
  const tempCode = _.get(data, 'info.tempCode', '-')
  const image = _.get(data, 'info.image', '')

  const prefixError = checkClick ? _.get(data, 'info.prefix', '') === '' : false
  const firstNameError = checkClick
    ? _.get(data, 'info.firstName', '') === ''
    : false
  const lastNameError = checkClick
    ? _.get(data, 'info.lastName', '') === ''
    : false

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Typography variant="body2" color="text.secodary">
          รูปภาพ (สำหรับใช้บนเว็บไซต์)
        </Typography>
        <ImgSection>
          <ProfileImg
            url={
              image
                ? `${window.__env__.REACT_APP_API_URL}/api/file${image}`
                : '/logo/staff_profile_small.png'
            }
          />
          <Box>
            <Box sx={{ display: 'flex', mb: '20px' }}>
              <label htmlFor={`icon-button-file`}>
                <Button
                  sx={{ mr: 3 }}
                  variant="contained"
                  color="primary"
                  size="s"
                  component="span"
                >
                  <Input
                    data-testid={`btn-add-image`}
                    accept="image/*"
                    id={`icon-button-file`}
                    type="file"
                    onChange={(e) => dispatch(handleUpload(e))}
                  />
                  อัปโหลดรูปภาพ
                </Button>
              </label>
              {image !== '' && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="s"
                  onClick={() => dispatch(handleDelete())}
                >
                  ลบรูปภาพ
                </Button>
              )}
            </Box>
            <Typography variant="body2" color="text.secodary">
              ขนาดแนะนำ 200x200px,
              <br /> นามสกุลไฟล์ที่รองรับ .jpg, .jpeg, .png
            </Typography>
          </Box>
        </ImgSection>
        <BoxContainer>
          <BoxContent labelLeft="User ID" labelRight={userId} />
          <BoxContent labelLeft="Temp Code" labelRight={tempCode} />
        </BoxContainer>

        <BoxContainer sx={{ mt: '24px' }}>
          <InputSectionContainer width="12.5%">
            <Select
              dataTestid="select-prefix-th"
              id="prefixTH"
              name="prefixTH"
              labelText="คำนำหน้า"
              type="basic"
              required
              options={prefixTH}
              value={_.get(data, 'info.prefixTH', '')}
              handleChange={(e) =>
                dispatch(
                  handleChangeTH({
                    key: 'prefixTH',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              placeholder={'เลือก'}
            ></Select>
          </InputSectionContainer>
          <InputSectionContainer width="32.5%">
            <TextFieldInput
              id="firstNameTH"
              name="firstNameTH"
              labelText="ชื่อ"
              required
              isShowTextError={_.get(data, 'info.firstNameTH', '') === ''}
              textError="กรุณากรอกชื่อ"
              value={_.get(data, 'info.firstNameTH', '-')}
              onChange={(e) =>
                dispatch(
                  handleChangeTH({
                    key: 'firstNameTH',
                    value: _.get(e, 'target.value', '').trim(),
                  })
                )
              }
            />
          </InputSectionContainer>
          <InputSectionContainer width="45%">
            <TextFieldInput
              id="lastNameTH"
              name="lastNameTH"
              labelText="นามสกุล"
              required
              value={_.get(data, 'info.lastNameTH', '-')}
              isShowTextError={_.get(data, 'info.lastNameTH', '') === ''}
              textError="กรุณากรอกนามสกุล"
              onChange={(e) =>
                dispatch(
                  handleChangeTH({
                    key: 'lastNameTH',
                    value: _.get(e, 'target.value', '').trim(),
                  })
                )
              }
            />
          </InputSectionContainer>
        </BoxContainer>

        <BoxContainer sx={{ mt: '8px' }}>
          <InputSectionContainer width="12.5%">
            <Select
              dataTestid="select-prefix"
              id="prefix"
              name="prefix"
              labelText="Title"
              type="basic"
              required
              options={prefix}
              value={_.get(data, 'info.prefix', '')}
              showTextError={prefixError}
              textError={prefixError ? 'กรุณาเลือก' : ''}
              handleChange={(e) =>
                dispatch(
                  handleChange({
                    key: 'prefix',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              placeholder={'เลือก'}
            ></Select>
          </InputSectionContainer>
          <InputSectionContainer width="32.5%">
            <TextFieldInput
              id="firstName"
              name="firstName"
              labelText="First Name"
              required
              isShowTextError={firstNameError}
              placeholder="กรุณากรอก"
              textError={firstNameError ? 'กรุณากรอกชื่อภาษาอังกฤษ' : ''}
              value={_.get(data, 'info.firstName', '-')}
              onChange={(e) =>
                dispatch(
                  handleChange({
                    key: 'firstName',
                    value: _.get(e, 'target.value', '').trim(),
                  })
                )
              }
            />
          </InputSectionContainer>
          <InputSectionContainer width="45%">
            <TextFieldInput
              id="lastName"
              name="lastName"
              labelText="Last Name"
              required
              value={_.get(data, 'info.lastName', '-')}
              placeholder="กรุณากรอก"
              isShowTextError={lastNameError}
              textError={lastNameError ? 'กรุณากรอกนามสกุลภาษาอังกฤษ' : ''}
              onChange={(e) =>
                dispatch(
                  handleChange({
                    key: 'lastName',
                    value: _.get(e, 'target.value', '').trim(),
                  })
                )
              }
            />
          </InputSectionContainer>
        </BoxContainer>
      </Card>
    </>
  )
}

export default MainInformation
