import _ from 'lodash'
import {
  resetErrorUpload,
  setErrorUpload,
  startUploadLoading,
  stopUploadLoading,
} from '../../../../../../../redux/slices/manageContent/elearning'
import { postUploadImage } from '../../../../../../../services/manageContent'

export const handleUpload = (e, key, setValue) => async (dispatch) => {
  if (e.target.files.length === 0) return

  const imageName = _.get(e, 'target.files[0].name', undefined)

  if (!imageName) return

  const getExtensionImage = imageName.split('.').pop()
  const sizeFile = _.get(e, 'target.files[0].size', 0)

  if (!checkTypeFile(getExtensionImage)) {
    dispatch(
      setErrorUpload({ [key]: 'นามสกุลไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' }),
    )
    return
  }
  if (sizeFile > 2000000) {
    dispatch(
      setErrorUpload({
        [key]: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
      }),
    )
    return
  }

  const body = new FormData()
  body.append('file', e.target.files[0])
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted =
        (progressEvent.loaded / progressEvent.total) * 100
      const upload = document.getElementById(`${key}-upload`)
      upload.style.width = `${percentCompleted}%`
    },
  }
  dispatch(startUploadLoading(key))
  const response = await dispatch(postUploadImage({ body, config }))

  dispatch(stopUploadLoading(key))
  const data = _.get(response, 'payload.data.key', undefined)

  if (data) {
    setValue((prev) => ({ ...prev, [key]: data }))
    dispatch(resetErrorUpload())
  } else {
    dispatch(
      setErrorUpload({ [key]: 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง' }),
    )
  }
}

export const checkTypeFile = (type) => {
  return type === 'jpg' || type === 'jpeg' || type === 'png'
}
