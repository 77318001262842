import _ from 'lodash'
import {
  setAnswered,
  setChangeStateByKey,
  setMobileProps,
} from './../../../../../../../../redux/slices/eTestingPreview'

export const handleDragEnd = (props, dragDropArr) => {
  const { over, active } = props
  const newArr = [...dragDropArr]
  if (active && over) {
    const oldIndex = newArr.findIndex((data) => data?.uuid === active.id)
    if (oldIndex !== -1) newArr[oldIndex] = null
    newArr[over.id] = _.get(active.data, 'current', null)
  } else if (active) {
    const oldIndex = newArr.findIndex((data) => data?.uuid === active.id)
    newArr[oldIndex] = null
  }
  return newArr
}

export const handleMobileSelect = (index, props) => (dispatch) => {
  const { dragDropArr, setDragDropArr, selectedItem } = props
  const newArr = [...dragDropArr]
  newArr[index] = selectedItem
  setDragDropArr(newArr)
  dispatch(setMobileProps({ selectedItem: null }))
}

export const handleDragDropChange = (value, keyQuestion) => (dispatch) => {
  dispatch(setChangeStateByKey({ key: 'answer', value, keyQuestion }))
  dispatch(setAnswered({ keyQuestion, value: value.length > 0 ? value : '' }))
}

export const getPlainTextWithBlank = (value) => {
  const newValue = value
    .replaceAll('<span', '[<span')
    .replaceAll('</span>', '</span>]')
  const plainText = new DOMParser().parseFromString(newValue, 'text/html')
    .documentElement.textContent
  return plainText
}
