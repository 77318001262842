import { Box, Grid } from '@mui/material'
import React from 'react'
import ExaminationField from '../ExaminationField'
import Schedule from '../Schedule'
import Examiner from '../Examiner'
import CompanyRanking from '../CompanyRanking'
import AssociationRanking from '../AssociationRanking'

const index = () => {
  return (
    <Box>
      <Box>
        <ExaminationField />
      </Box>
      <Box mt={3} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item  xs={12} md={6}>
            <Schedule />
          </Grid>
          <Grid item  xs={12} md={6}>
            <Examiner />
          </Grid>
        </Grid>
      </Box>.
      <Box mt={3} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item  xs={12} md={6}>
            <CompanyRanking />
          </Grid>
          <Grid item  xs={12} md={6}>
            <AssociationRanking />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default index
