import { Box } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { ViewRow } from '../../../../../../../components/CRUD/components/StyledViewBox'
import _ from 'lodash'
import { fromTypeEnum } from '../../../../../../../modules/Inventory/Transfer/Form/enum/assetTypEnum'
import { paymentMethodTxt } from '../../../../../../../modules/Inventory/Transfer/Form/enum/paymentMethod'

export const License = () => {
  const { detailDrawer, formData } = useSelector(
    (state) => ({
      detailDrawer: state.crud?.detailDrawer,
      formData: state.crud?.formData,
    }),
    shallowEqual,
  )
  // const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  const from = _.get(formData, 'from', '')
  // const status = _.get(formData, 'status', '')
  const checkIfImportProduct =
    from?.value === fromTypeEnum.IMPORT_PRODUCT ? true : false
  const paymentType = _.get(detailDrawer, 'paymentType', '-')
  return (
    <>
      <Box sx={{ mt: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
        <ViewRow
          title="ชื่อทรัพย์สิน"
          variant="body1b"
          value={_.get(detailDrawer, 'product.productName', '-')}
        />
        <ViewRow
          title="รหัสทรัพย์สิน"
          variant="body1b"
          value={_.get(detailDrawer, 'skuNo', '-')}
        />
        {checkIfImportProduct && (
          <>
            <ViewRow
              title="Cost center"
              variant="body1b"
              value={_.get(detailDrawer, 'costCenter.descriptionName', '-')}
            />
            <ViewRow
              title="มูลค่าต่อหน่วย (฿)"
              variant="body1b"
              value={_.get(detailDrawer, 'unitPrice', '-')}
            />
            <ViewRow
              title="วันเริ่มต้นสัญญา"
              variant="body1b"
              value={_.get(detailDrawer, 'contractStartDate', '-')}
            />
            <ViewRow
              title="วันสิ้นสุดสัญญา"
              variant="body1b"
              value={_.get(detailDrawer, 'contractEndDate', '-')}
            />
            <ViewRow
              title="วันต่อสัญญา"
              variant="body1b"
              value={_.get(detailDrawer, 'renewContractDate', '-')}
            />
            <ViewRow
              title="ชื่อผู้ถือครอง"
              variant="body1b"
              value={_.get(detailDrawer, 'holder.firstNameTH', '-')}
            />
            <ViewRow
              title="สกุลผู้ถือครอง"
              variant="body1b"
              value={_.get(detailDrawer, 'holder.lastNameTH', '-')}
            />
            <ViewRow
              title="Email ผู้ถือครอง"
              variant="body1b"
              value={_.get(detailDrawer, 'holder.email', '-')}
            />
            <ViewRow
              title="ชื่อผู้ชำระเงิน"
              variant="body1b"
              value={_.get(detailDrawer, 'payer.firstNameTH', '-')}
            />
            <ViewRow
              title="สกุลผู้ชำระเงิน"
              variant="body1b"
              value={_.get(detailDrawer, 'payer.lastNameTH', '-')}
            />
            <ViewRow
              title="Email ผู้ชำระเงิน"
              variant="body1b"
              value={_.get(detailDrawer, 'payer.email', '-')}
            />
            <ViewRow
              title="วิธีชำระเงิน"
              variant="body1b"
              value={_.get(detailDrawer, 'paymentType', '-')}
            />
            {(paymentType == paymentMethodTxt.CREDIT_CARD ||
              paymentType == paymentMethodTxt.OTHER) && (
              <ViewRow
                variant="body1b"
                title={`รายละเอียด/ชื่อบัตร`}
                value={`${
                  paymentType == paymentMethodTxt.CREDIT_CARD
                    ? _.get(detailDrawer, 'creditCardName', '-')
                    : paymentType == paymentMethodTxt.OTHER
                    ? _.get(detailDrawer, 'creditCardDescription', '-')
                    : '-'
                }`}
              />
            )}
            {paymentType == paymentMethodTxt.CASH_INVOICE && (
              <ViewRow
                variant="body1b"
                title={`${'ไฟล์ Invoice/eContent'}`}
                valueComponent={
                  <>
                    <img
                      src={`${window.__env__.REACT_APP_API_URL}/file/get${_.get(
                        detailDrawer,
                        'paymentFile.key',
                        '-',
                      )}`}
                      height={150}
                      style={{ objectFit: 'cover', maxWidth: '300px' }}
                    />
                  </>
                }
              />
            )}
          </>
        )}
      </Box>
    </>
  )
}
