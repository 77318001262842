import callAxios from '../../../../utils/baseService'
import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { overviewEnum } from '../enums/drawerEnum'
import { apiToRcmsDashboard } from '../../../../utils/apiPath'
import dayjs from 'dayjs'

export const fetchOverview = async () => {
  const { apiRcmsDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      apiRcmsDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      apiRcmsDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
    courseUuid: _.defaultTo(apiRcmsDashboard?.filtersDrawer?.courseUuid, ''),
    platformLearning: _.defaultTo(
      apiRcmsDashboard?.filtersDrawer?.platformLearning,
      '',
    ),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(apiToRcmsDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'apiRcmsDashboard.latestUpdated',
          value: _.get(data, 'result.latestUpdated', undefined),
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'apiRcmsDashboard.overview',
          value: {
            ...overviewEnum,
            ...data.result,
            LATEST_COURSES: _.defaultTo(data.result.LATEST_COURSES, []).map(
              (item) => {
                return {
                  name: item?.course?.englishName ?? '-',
                  number: '',
                  unit: '',
                }
              },
            ),
            TOP_COURSES: _.defaultTo(data.result.TOP_COURSES, []).map(
              (item) => {
                return {
                  name: item?.course_englishName ?? '-',
                  number: item?.totaltransactions ?? 0,
                  unit: '',
                }
              },
            ),
            LATEST_LICENSES: _.defaultTo(data.result.LATEST_LICENSES, []).map(
              (item) => {
                return {
                  name: item?.licenseDescription ?? '-',
                  number: '',
                  unit: '',
                }
              },
            ),
            TOP_LICENSES: _.defaultTo(data.result.TOP_LICENSES, []).map(
              (item) => {
                return {
                  name: item?.licensename ?? '-',
                  number: item?.totaltransactions ?? 0,
                  unit: '',
                }
              },
            ),
          },
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
