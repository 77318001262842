import dayjs from 'dayjs'
import _ from 'lodash'
import { fetchCourse, fetchResponsibility } from '../../api/Training'
import { getStaffInAreaActivity } from '../../../MonthlyPlan/api/Index'
import { checkChangeDate } from '../Handle'

export const getExpandDate = (
  stateForm,
  setStateDate,
  setStateForm,
  defaultExpandDate,
  fixValue,
  setLoadingDrawer
) => {
  let dateArray = []
  let resultType = []
  const sDate = stateForm.academyActivity.startDate
  const fDate = fixValue
    ? stateForm.academyActivity.startDate
    : stateForm.academyActivity.finishDate
  const checkDate = checkChangeDate(sDate, fDate)
  if (!checkDate) {
    setStateDate(resultType)
    setStateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        expandDate: resultType,
      },
    }))
    return false
  }
  setLoadingDrawer && setLoadingDrawer(true)

  let startDate = dayjs(stateForm.academyActivity.startDate)
  let stopDate = dayjs(stateForm.academyActivity.finishDate)

  let currentDate = new Date(startDate)
  while (currentDate < stopDate) {
    dateArray = [
      ...dateArray,
      dayjs(currentDate).format(window.__env__.REACT_APP_DATE_DB),
    ]
    // currentDate.setDate(currentDate.getDate() + 1)
    currentDate = new Date(currentDate.getTime() + 86400000)
    stopDate = dayjs(stateForm.academyActivity.finishDate)
  }
  dateArray = [
    ...dateArray,
    dayjs(stopDate).format(window.__env__.REACT_APP_DATE_DB),
  ]

  const expandDate = _.get(stateForm, 'academyActivity.expandDate', [])

  dateArray = _.sortedUniq(dateArray)

  if (dateArray.length) {
    for (let i = 0; i < dateArray.length; i++) {
      const value = dateArray[i]
      const findDate = expandDate.find((item) => {
        return item.date === value
      })
      if (!_.isEmpty(findDate)) {
        resultType.push(findDate)
      } else {
        resultType.push({
          date: value === 'Invalid Date' ? null : value,
          ...defaultExpandDate,
        })
      }
    }
  }
  const firstDate = dayjs(stateForm.academyActivity.startDate).format(
    window.__env__.REACT_APP_DATE_DB
  )
  const endDate = dayjs(stateForm.academyActivity.finishDate).format(
    window.__env__.REACT_APP_DATE_DB
  )
  if (firstDate === 'Invalid Date' || endDate === 'Invalid Date') {
    resultType = []
  }

  if (resultType.length) {
    setStateDate(fixValue ? [resultType[0]] : resultType)
    setStateForm((prevState) => ({
      ...prevState,
      academyActivity: {
        ...prevState.academyActivity,
        expandDate: fixValue ? [resultType[0]] : resultType,
      },
    }))
  }

  setLoadingDrawer &&
    setTimeout(() => {
      setLoadingDrawer(false)
    })

  return true
}

export const handleExpand = ({
  type,
  indexExpand,
  indexSpeaker,
  stateForm,
  setStateForm,
}) => {
  let newExpandDates = []
  const expandDate = _.get(stateForm, 'academyActivity.expandDate', [])
  if (type === 'add') {
    if (expandDate.length) {
      newExpandDates = expandDate.filter((item, index) => {
        const defaultSpeaker = {
          staff: '',
          speakerRole: '',
          startTime: _.get(item, 'startTime', '')
            ? _.get(item, 'startTime', '')
            : '',
          endTime: _.get(item, 'endTime', '') ? _.get(item, 'endTime', '') : '',
        }
        if (index === indexExpand) {
          item.speakers = [...item.speakers, defaultSpeaker]
        }
        return item
      })
    }
  } else if (type === 'delete') {
    if (expandDate.length) {
      newExpandDates = expandDate.filter((item, index) => {
        const speakers = item?.speakers || []
        if (index === indexExpand && speakers.length) {
          speakers.splice(indexSpeaker, 1)
        }
        return item
      })
    }
  }

  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      expandDate: newExpandDates,
    },
  }))
}

export const fetchAllState = async ({
  area,
  setCourse,
  setStaff,
  setResponsibility,
  setLoading,
  startDate,
  finishDate,
  setOptions,
}) => {
  setLoading(true)
  const dataApi = await Promise.all([
    fetchCourse({
      setCourse,
      startDate,
      finishDate,
    }),
    getStaffInAreaActivity(area, startDate),
    fetchResponsibility(),
  ])
  setStaff(dataApi[1])
  setResponsibility(dataApi[2])

  setOptions((prevState) => ({
    ...prevState,
    course: dataApi[0],
    speakers: dataApi[1],
    responsibility: dataApi[2],
    staff: dataApi[1],
  }))

  setLoading(false)
  return dataApi
}

export const handleSubmitDialog = (
  expandDate,
  value,
  type,
  rowIndex,
  setStateForm
) => {
  const dateKey = value?.startDate || null
  let newExpandDates = []
  if (type === 'add') {
    newExpandDates = expandDate.filter((item) => {
      if (item.date === dateKey) {
        item.speakerBorrow = [...item.speakerBorrow, value]
      }
      return { ...item }
    })
  }
  if (type === 'editForm') {
    newExpandDates = expandDate.filter((item) => {
      if (item.date === dateKey) {
        const mapData = item.speakerBorrow.map((itemSB, index) => {
          if (index === rowIndex) {
            itemSB = value
          }
          return itemSB
        })
        item.speakerBorrow = mapData
      }
      return { ...item }
    })
  }

  if (type === 'delete') {
    newExpandDates = expandDate.filter((item) => {
      const speakerBorrow = item?.speakerBorrow || []
      if (item.date === dateKey && speakerBorrow.length) {
        speakerBorrow.splice(rowIndex, 1)
      }
      return { ...item }
    })
  }

  if (type === 'CANCEL') {
    newExpandDates = expandDate.filter((item) => {
      const mapData = item.speakerBorrow.map((itemSB, index) => {
        if (index === rowIndex) {
          itemSB = { ...value, status: 'CANCEL' }
        }
        return itemSB
      })
      item.speakerBorrow = mapData
      return { ...item }
    })
  }

  setStateForm((prevState) => ({
    ...prevState,
    academyActivity: {
      ...prevState.academyActivity,
      expandDate: newExpandDates,
    },
  }))
}
