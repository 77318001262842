import { StyledCardDrawer, StyledColumn } from '../../../../Styled'
import { Typography } from '@mui/material'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { onDragEnd } from '../../../../handler/onDragEnd'
import ColumnList from './ColumnList'

const DragDropColumnCard = () => {
  const { displayColumns, tabActiveColDrawer } = useSelector(
    (state) => ({
      displayColumns: state.reportComp.displayColumns,
      tabActiveColDrawer: state.reportComp.tabActiveColDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledCardDrawer>
      {tabActiveColDrawer === 1 && (
        <Typography variant="h6">รายการคอลัมน์</Typography>
      )}
      {displayColumns?.length > 0 && (
        <StyledColumn>
          <Typography variant="body2">จัดเรียง</Typography>
          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
            <Droppable droppableId="tabs">
              {(provided) => <ColumnList provided={provided} />}
            </Droppable>
          </DragDropContext>
        </StyledColumn>
      )}
    </StyledCardDrawer>
  )
}

export default DragDropColumnCard
