import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Box } from '@mui/system'
import { StyledUploadHeader } from '../stlyed'
import Typography from '@mui/material/Typography'
import DragDropFile from './DragDropFile'
import _ from 'lodash'

const UploadList = ({
  isForImage,
  isShowDownloadable,
  onChange,
  fieldName,
  disabled,
  popupLabel,
  disabledRemove,
  uploadType,
}) => {
  const fileList = useSelector(
    (state) => state.uploadFile.formData[fieldName].files,
    shallowEqual,
  )

  if (_.isEmpty(fileList) || fileList.filter((i) => i).length === 0) {
    return <></>
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <StyledUploadHeader>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography width={70}>สถานะ</Typography>
          <Typography>ชื่อไฟล์</Typography>
        </Box>
        {isShowDownloadable && (
          <Typography width={155}>อนุญาตดาวน์โหลดไฟล์</Typography>
        )}
      </StyledUploadHeader>

      <DragDropFile
        isForImage={isForImage}
        isShowDownloadable={isShowDownloadable}
        onChange={onChange}
        fieldName={fieldName}
        disabled={disabled}
        popupLabel={popupLabel}
        disabledRemove={disabledRemove}
        uploadType={uploadType}
      />
    </Box>
  )
}

export default UploadList
