import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import Information from './Information'
import Conditions from './Conditions'
import Footer from './Footer'

const Index = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <Card>
        <Information />
        <Conditions />
        <Footer />
      </Card>
    </Box>
  )
}

export default Index
