import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../../components/FilterDrawer/Styled'
import { arrayCheckbox, defaultFilter } from './model'
import * as events from './event'
import _ from 'lodash'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { useSelector } from 'react-redux'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
// import { setFieldValue } from '../../../../../redux/slices/crud'
import SelectGroupChip from '../../../../../components/SelectGroupChip'
import SelectIcon from '../../../../../components/redux/SelectIcon'
import { store } from '../../../../../App'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const ListCheckBox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => {
    const { filterList } = store.getState().crud
    let list = item?.list ?? []
    if (filterList?.[item.nameInput]) {
      list = filterList?.[item.nameInput]
    }
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type == 'checkbox' && (
                <StyledList>
                  <ListResult
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={item.list}
                    listKeys={item.nameInput}
                  />
                </StyledList>
              )}

              {item.type == 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}

              {item.type == 'number' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{
                    type: 'number',
                    onInput: handleNumberInput,
                    onKeyDown: (e) => {
                      handleNumberKeyDown(e, ['.'])
                    },
                    onPaste: handlePasteFormat,
                  }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}

              {item.type === 'daterange' && (
                <DateRangeInput
                  placeholder={item.placeholder}
                  dateState={{
                    startDate: stateFilter.startDate || new Date(),
                    endDate: stateFilter.endDate || new Date(),
                    display: stateFilter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter,
                    )
                  }}
                />
              )}

              {item.type === 'DROPDOWN_MULTI' && (
                <SelectGroupChip
                  boxSx={{ mb: 3 }}
                  id={item.nameInput}
                  name={item.nameInput}
                  labelText={''}
                  placeholder="กรุณาเลือก"
                  options={item.list}
                  value={stateFilter[`${item.nameInput}List`]}
                  onChange={(list) => {
                    events.handleSelectMultiple(
                      list,
                      stateFilter,
                      setFilter,
                      item.nameInput,
                    )
                  }}
                  // textError={error}
                />
              )}

              {item.type === 'select' && (
                <SelectIcon
                  dataTestId={`${item.nameInput}`}
                  name={`${item.nameInput}`}
                  listOption={list}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  handleChange={(e) => {
                    events.handleChange({
                      key: e.target.name ?? item.nameInput,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}

              {item.type === 'select_dropdown' && (
                <CustomDropdown
                  id={item.nameInput}
                  name={item.nameInput}
                  labelName=""
                  fieldName={`${item.nameInput}-filter-dropdown`}
                  placeholder={item.placeholder}
                  isCustomOption={true}
                  customOptions={{ isLoading: false, options: list }}
                  handleExtraChange={(event, value) => {
                    events.handleChange({
                      key: item.nameInput,
                      value: value?.value ?? '',
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(filter[listKeys], item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = () => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const dispatch = useDispatch()
  const { open, isClearFilter } = useSelector(
    (state) => ({
      open: state.crud.apiRcmsDashboard?.filtersDrawer?.isOpen,
      isClearFilter: state.crud.apiRcmsDashboard?.filtersDrawer?.isClearFilter,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  useEffect(() => {
    events.fetchCourse()
    events.fetchPlatformLearner()
  }, [])

  useEffect(() => {
    if (isClearFilter) {
      events.clearFilter(isClearFilter, filter, setFilter, setFilterMemo)
      dispatch(
        setFieldValue({
          key: 'apiRcmsDashboard.filtersDrawer.isClearFilter',
          value: '',
        }),
      )
    }
  }, [isClearFilter])

  return (
    <StyledDrawer open={open} onClose={() => toggleFiltersDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleFiltersDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckBox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={async () => {
            await events.handleChange({
              value: false,
              key: 'dateChecked',
              filter,
              setFilter,
              isCheck: true,
            })
            await arrayCheckbox.map((item) => {
              if (item.type === 'select_dropdown') {
                dispatch(
                  setFieldValue({
                    key: `formData.${item.nameInput}-filter-dropdown`,
                    value: '',
                  }),
                )
              }
            })
            setFilter(defaultFilter)
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(filter))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
