import _ from 'lodash'
import { initialState } from './model'

export const handleResetTable = (state) => {
  state.table = { ...initialState.table }
  state.filter = { ...initialState.filter }
  state.search = { ...initialState.search }
  state.filterState = null
  state.filterStateMemo = null
  state.handleSearch = null
  state.onDownload = null
  state.onQuickFilter = null
  state.onChangeStatus = null
  state.customToolbar = null
  state.customStyle = {}
}
export const handleFilterTotal = (filter) => {
  const keyChecked = []
  const keyValue = []
  let filterTotal = 0

  for (const key in filter) {
    if (key.includes('displayDate')) continue
    if (key.includes('displayExpiryDate')) continue
    if (key.includes('displayEffectiveDate')) continue
    if (key.toLowerCase().includes('displaydate')) continue

    if (typeof filter[key] == 'boolean' && filter[key] === true) {
      keyChecked.push(key.replace('Checked', ''))
    }
    if (!key.includes('Checked') || typeof filter[key] != 'boolean') {
      keyValue.push(key)
    }
  }

  keyChecked.forEach((key) => {
    const valueKey = keyValue.filter((val) =>
      _.toLower(val).includes(_.toLower(key)),
    )
    let value = filter[valueKey]
    if (valueKey.length > 1) {
      value = Object.keys(filter)
        .filter((key) => valueKey.includes(key))
        .reduce((result, key) => {
          if (
            filter[key] ||
            filter[key] == 0 ||
            (typeof filter[key] === 'string' && filter[key].length > 0)
          )
            result.push(filter[key])
          return result
        }, [])
    }
    const valueType = typeof value

    if (valueType === 'object' && valueKey.length === 1) {
      filterTotal = handleKeyValue(value, filterTotal, 0)
    }

    if ((valueType === 'boolean' && value) || _.defaultTo(value, []).length > 0)
      filterTotal++
  })

  return filterTotal
}

export const handleKeyValue = (obj, filterTotal, newTotal) => {
  let total = filterTotal

  if (obj.toString().includes('Time')) total++ // date object

  for (const key in obj) {
    if (typeof obj[key] == 'object' && newTotal == 0) {
      newTotal = handleKeyValue(obj[key], filterTotal, newTotal)
      total = newTotal > 0 ? total + 1 : total
    } else if (typeof obj[key] == 'boolean' && obj[key] && newTotal == 0) {
      total++
      break
    }
  }
  return total
}
