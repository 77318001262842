import _ from 'lodash'

export const changeText = (text) => {
  let textReturn = ''
  switch (text) {
    case 'COURSE':
      textReturn = 'ผ่านหลักสูตร '
      break
    case 'LICENSE':
      textReturn = 'มีใบอนุญาต '
      break
    case 'SERVICEYEAR':
      textReturn = 'มีอายุงาน '
      break
    case 'OTHER':
      textReturn = 'อื่นๆ '
      break
    default:
      break
  }
  return `<span class="label-type">${textReturn}</span>`
}

export const handleDisplayPrerequisite = (data) => {
  return data.map((dataItem, dataIndex) => {
    const result = []
    const groupData = _.groupBy(dataItem, 'type')
    let mainIndex = 0
    let newIndex = 0

    for (let type in groupData) {
      const value = groupData[type]
      let label = ''

      value.forEach((item, index) => {
        const orCondition = `<span class="condition"> หรือ </span><br/>`
        const labelText = handleLabelText(item)

        label = label + changeText(type) + labelText

        if (index < value.length - 1) label = label + orCondition
      })

      if (mainIndex == 0) {
        if (newIndex >= dataIndex) {
          newIndex = dataIndex
          result.push({
            condition: dataIndex == 0 ? '' : 'และ',
            label,
          })
        } else result.push({ condition: 'และ', label })
      } else result.push({ condition: 'หรือ', label })

      mainIndex++
    }

    return result
  })
}

export const handleLabelText = (item) => {
  const type = _.get(item, 'type', 'license')
  let labelText = 'ตัวแทนที่ใบอนุญาตผู้แนะนำการลงทุนหมดอายุในปีที่อบรมนั้น' // for อื่นๆ
  const eLearningName = _.get(item, 'eLearning.name', '')

  if (type == 'COURSE') {
    const nameForLearner = _.get(item, 'course.nameForLearner', '') || ''
    labelText = `${_.get(item, 'course.englishName', '')} <span class="nameForLearner">${nameForLearner}</span>`
    labelText = _.get(item, 'course.englishName', eLearningName)
  } else if (type == 'LICENSE') {
    labelText = _.get(item, 'license.description', '')
  } else if (type == 'SERVICEYEAR') {
    const year = _.get(item, 'year', '')
    const month = _.get(item, 'month', '')
    labelText = `${year} ปี ${month} เดือน`
  }

  return labelText
}
