import { store } from '../../../App'
import _ from 'lodash'

export const handleLabelCustomColumn = (column) => {
  const { columnName, columnNameTH } = store.getState().reportComp

  const columns = _.get(column, 'column', [])
  return _.reduce(
    columns,
    (acc, column, idx) => {
      const keyColumn = _.findKey(columnName, (col) => col === column)
      acc += columnNameTH[keyColumn]
      if (idx < columns.length - 1) acc += ' , '

      return acc
    },
    '',
  )
}
