import { MODULE_TYPE } from '../../../../../constants/eLearning'

export const moduleTypeOption = [
  { label: 'E-Testing / แบบทดสอบ', value: MODULE_TYPE.E_TESTING },
  { label: 'E-Evaluation / แบบประเมิน', value: MODULE_TYPE.E_EVALUATION },
  { label: 'Embed Content / ฝังเนื้อหา', value: MODULE_TYPE.EMBED_CONTENT },
  {
    label: 'Link to another website / ลิงก์ไปยังอีกเว็บไซต์อื่น',
    value: MODULE_TYPE.LINK_TO,
  },
  { label: 'Upload file / อัปโหลดไฟล์', value: MODULE_TYPE.UPLOAD_FILE },
  { label: 'Scorm file', value: MODULE_TYPE.SCORM },
]
