import React, { useState, useEffect } from 'react'
import Add from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { handleAddSet, handleInitialForm } from './events'
import { StyledCard } from './Styled'
import TestAndEvaluateForm from './TestAndEvaluateForm'
import _ from 'lodash'

const TestAndEvaluateSetting = ({
  initialValues,
  setInitialValues,
  formik,
}) => {
  const [testAndEvaArr, setTestAndEvaArr] = useState([])
  const uuid = _.get(initialValues, 'uuid', '')

  useEffect(() => {
    handleInitialForm(initialValues, setTestAndEvaArr, formik)
  }, [initialValues])

  return (
    <StyledCard>
      <Typography variant="h6">แบบทดสอบ/แบบประเมิน</Typography>
      {testAndEvaArr &&
        testAndEvaArr.map((_, index) => (
          <TestAndEvaluateForm
            key={`content-set-${index}`}
            index={index}
            testAndEvaArr={[...testAndEvaArr]}
            setInitialValues={setInitialValues}
            formik={formik}
          />
        ))}
      <Button
        data-testid="btn-add"
        variant="contained"
        size="l"
        startIcon={<Add />}
        sx={{ mt: 1 }}
        onClick={() =>
          handleAddSet(uuid, [...testAndEvaArr], setInitialValues, formik)
        }
      >
        เพิ่มแบบทดสอบ / แบบประเมิน
      </Button>
    </StyledCard>
  )
}

export default TestAndEvaluateSetting
