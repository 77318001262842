import { createListenerMiddleware } from '@reduxjs/toolkit'
import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const listenerMiddlewareInventProductStock = createListenerMiddleware()

listenerMiddlewareInventProductStock.startListening({
  actionCreator: setFieldValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key, value },
    } = action

    const fieldName = key.split('.')[1]
    if (fieldName !== 'statusFlagReturn') return

    if (value === enumStatus.INACTIVE) {
      const { formData } = listenerApi.getState().crud
      listenerApi.dispatch(
        setFieldValue({
          key: `formData`,
          value: {
            ...formData,
            hasPeriodFlagReturn: false,
            refundYear: null,
            refundMonth: null,
            refundDay: null,
          },
        }),
      )
    }
  },
})
