export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รอเปิดใช้งาน',
    name: 'pendingChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayType = [
  { label: 'Class', name: 'classChecked' },
  { label: 'Standalone', name: 'standaloneChecked' },
]

export const arrayCheckbox = {
  eevaluation: [
    {
      label: 'ID',
      nameCheckBox: 'codeIdChecked',
      nameInput: 'codeId',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'ชื่อแบบประเมิน',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'สร้างโดย',
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'แก้ไขล่าสุดโดย',
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: 'ค้นหา',
      type: 'text',
    },
  ],
  versions: [
    {
      label: 'เวอร์ชัน',
      nameCheckBox: 'versionChecked',
      nameInput: 'version',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'ชื่อแบบประเมิน',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'วันเริ่มต้นใช้งาน',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'ค้นหา',
      type: 'date',
    },
    {
      label: 'สร้างโดย',
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: 'ค้นหา',
      type: 'text',
    },
    {
      label: 'แก้ไขล่าสุดโดย',
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: 'ค้นหา',
      type: 'text',
    },
  ],
  feedbackClass: [
    {
      label: 'Course',
      nameCheckBox: 'courseChecked',
      nameInput: 'course',
      placeholder: 'Course',
      type: 'select',
    },
    {
      label: 'วิทยากร',
      nameCheckBox: 'staffChecked',
      nameInput: 'staff',
      placeholder: 'วิทยากร',
      type: 'selectMultiple',
    },
    {
      label: 'ช่วงเวลาทำแบบประเมิน',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'ค้นหา',
      type: 'date',
    },
  ],
  feedbackStandalone: [
    {
      label: 'Department',
      nameCheckBox: 'departmentChecked',
      nameInput: 'department',
      placeholder: 'สาขา',
      type: 'selectMultiple',
    },
    {
      label: 'ช่วงเวลาทำแบบประเมิน',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'ค้นหา',
      maxDate: true,
      type: 'date',
    },
  ],
}

export const defaultFilter = {
  eevaluation: {
    codeIdChecked: false,
    codeId: '',
    nameChecked: false,
    name: '',
    numberOfVersionsChecked: false,
    numberOfVersions: '',
    createdByChecked: false,
    createdBy: '',
    updatedByChecked: false,
    updatedBy: '',
    typeChecked: false,
    type: {
      classChecked: false,
      standaloneChecked: false,
    },
    statusChecked: false,
    status: {
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
  },
  versions: {
    versionChecked: false,
    version: '',
    nameChecked: false,
    name: '',
    startDateChecked: false,
    startDate: new Date(),
    toDate: new Date(),
    displayDate: false,
    createdByChecked: false,
    createdBy: '',
    updatedByChecked: false,
    updatedBy: '',
    statusChecked: false,
    status: {
      draftChecked: false,
      pendingChecked: false,
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
  },
  feedbackClass: {
    courseChecked: false,
    course: '',
    staffChecked: false,
    staff: [],
    startDateChecked: false,
    startDate: new Date(),
    toDate: new Date(),
  },
  feedbackStandalone: {
    departmentChecked: false,
    department: [],
    startDateChecked: false,
    startDate: new Date(),
    toDate: new Date(),
  },
}
