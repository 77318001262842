import _ from 'lodash'
import localforage from 'localforage'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
} from '../../../redux/slices/dialog'
import * as reducer from '../../../redux/slices/eEvaluationForm'
import * as service from '../../../services/eEvaluation/form'
import { filterCourseRef } from '../../ETesting/Form/event'
import {
  errorMessageField,
  assessmentField,
  errorMessageType,
} from '../../../constants/eEvaluation'
import { getCurrentDateServer } from '../../../services/eTesting/form'
import { standAloneSchema, classSchema, draftSchema } from './schema'
import { eEvaluationVersion } from '../../../utils/apiPath'
import { initialState } from '../../../redux/slices/eEvaluationForm/model'
import { validateInfo } from '../../ETesting/Form/event'

export const handlePersist = (props) => async (dispatch) => {
  const { uuid, isClone, onEdit, setOnEdit, codeId, createType } = props
  const persist = await localforage.getItem('persist:root')
  const objPersist = JSON.parse(persist)
  const persistUuid = _.get(objPersist, 'eEvaluationForm.uuid', '')
  const eEvaluationForm = _.get(objPersist, 'eEvaluationForm', `{}`)
  const objEva = JSON.parse(eEvaluationForm)

  //if for validate persist uuid and real uuid from link
  if (uuid && (persist === null || persistUuid !== uuid) && !isClone) {
    //case edit, new window by preview, enter link
    dispatch(fetchDataForm({ uuid, isClone, onEdit, setOnEdit }))
  } else {
    // console.log('else1', persist, persistUuid, isClone)
    if (
      (persist === null || persistUuid !== uuid) &&
      !isClone &&
      validateInitialData(objEva)
    ) {
      //case create
      dispatch(getAssignee(codeId, createType))
    } else if (isClone) {
      //case clone
      dispatch(fetchDataForm({ uuid, isClone, onEdit, setOnEdit }))
    }
  }
}

export const fetchDataForm = (props) => async (dispatch) => {
  const { uuid, isClone, setOnEdit } = props
  dispatch(reducer.startLoading())
  const data = await Promise.all([
    dispatch(service.getFormDetailEva(uuid)),
    dispatch(service.getAssigneesEva()),
    dispatch(getCurrentDateServer()),
  ])

  let detailData = _.get(data, '[0].payload', initialState)
  if (isClone) {
    detailData = {
      ...detailData,
      uuid: '',
      setting: {
        ...detailData.setting,
        startDate: null,
        status: 'DRAFT',
        createdAt: null,
        createdBy: '-',
        updatedAt: null,
        updatedBy: '-',
        courseRef: [],
        isShare: false,
        shareLink: '',
      },
    }
  }
  dispatch(
    reducer.setFetchData({
      ...detailData,
      setting: {
        ...detailData.setting,
        courseRef: filterCourseRef(_.get(detailData, 'setting.courseRef', [])),
      },
      assignee: _.get(data, '[1].payload.result', []),
      dateServer: _.get(data, '[2].payload', null),
      isClone: !!isClone,
    }),
  )
  dispatch(reducer.stopLoading())
  setOnEdit(true)
}

export const getAssignee = (codeId, createType) => async (dispatch) => {
  dispatch(reducer.startLoading())
  dispatch(reducer.setClearData())
  dispatch(reducer.setEEvaluationType(createType))

  const data = await Promise.all([
    dispatch(service.getAssigneesEva()),
    dispatch(getCurrentDateServer()),
  ])

  dispatch(
    reducer.setFetchAssignee({
      assignee: _.get(data, '[0].payload.result', []),
      dateServer: _.get(data, '[1].payload', null),
      codeId: codeId || '',
    }),
  )
  dispatch(reducer.stopLoading())
}

export const onSubmitFormEValuation = (props) => async (dispatch) => {
  const errorInfo = validateInfo(props.stateEvaluation.information, true)
  if (!_.isEmpty(errorInfo)) {
    dispatch(
      handleErrorMessage({
        field: 'info',
      }),
    )
    props.setErrors({
      information: errorInfo,
    })
    return
  }

  let realSchema =
    props.stateEvaluation.eEvaluationType === 'CLASS'
      ? classSchema
      : standAloneSchema
  if (props.isDraft) {
    realSchema = draftSchema
  }

  await realSchema
    .validate(props.stateEvaluation)
    .then(() => {
      dispatch(reducer.setErrorSchemaMessage(convertErrorMessage('')))
      dispatch(
        openDialog({
          title: props.isDraft
            ? 'ยืนยันบันทึกฉบับร่าง'
            : 'ยืนยันบันทึกและเผยแพร่',
          message: props.isDraft
            ? 'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่'
            : 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่',
          handleConfirm: () => dispatch(actionSubmitForm(props)),
        }),
      )
    })
    .catch((er) => {
      const messageObj = convertErrorMessage(er.message)
      dispatch(handleErrorMessage(messageObj))
      dispatch(
        openErrorDialog({
          title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          message: er.message,
          handleError: () =>
            dispatch(reducer.setErrorSchemaMessage(messageObj)),
        }),
      )
    })
}

export const actionSubmitForm = (props) => async (dispatch) => {
  const { stateEvaluation, isDraft, history } = props
  const uuid = _.get(stateEvaluation, 'uuid', '')
  const isClone = _.get(stateEvaluation, 'isClone', false)
  const body = {
    codeId: _.get(stateEvaluation, 'codeId', ''),
    eEvaluationType: _.get(stateEvaluation, 'eEvaluationType', 'CLASS'),
    isDraft,
    information: removeFieldImage(_.get(stateEvaluation, 'information', {})),
    setting: removeFieldSetting(_.get(stateEvaluation, 'setting', {})),
    courseAssessments: removeFieldQuestionDefault(
      _.get(stateEvaluation, 'courseAssessments[0]', {}),
      isClone,
    ),
    lecturerAssessments: removeFieldQuestionDefault(
      _.get(stateEvaluation, 'lecturerAssessments[0]', {}),
      isClone,
    ),
    courseManagementAssessments: removeFieldQuestionDefault(
      _.get(stateEvaluation, 'courseManagementAssessments[0]', {}),
      isClone,
    ),
    questions: removeFieldQuestions(
      _.get(stateEvaluation, 'questions', []),
      _.get(stateEvaluation, 'subQuestions', []),
      isClone,
    ),
  }

  dispatch(reducer.startLoading())
  dispatch(loadingDialog())
  const result = await dispatch(
    service.submitFormEvan(!uuid ? body : { ...body, uuid }),
  )
  const payload = _.get(result, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'บันทึกสำเร็จ',
        message: 'บันทึกสำเร็จ',
        isCloseDialog: false,
        handleConfirm: () => {
          history.push(
            `${eEvaluationVersion}/${_.get(result, 'payload.data.codeId', '')}`,
          )
          dispatch(closeDialog())
          dispatch(reducer.setClearData())
        },
      }),
    )
  } else {
    dispatch(apiSentError(result))
  }

  dispatch(reducer.stopLoading())
}

export const apiSentError = (error) => (dispatch) => {
  const resErrorKey = _.get(error, 'meta.response.data.constraints.key', '')
  const resErrorValue = _.get(error, 'meta.response.data.constraints.value', '')
  let result = {
    status: false,
    field: '',
    objMessage: {},
  }
  if (resErrorKey === 'CanNotSaveName') {
    result.status = true
    result.field = 'NAME'
    result.obj = {
      field: errorMessageField.NAME,
      message: errorMessageType.EMPTY_NAME,
      messageType: resErrorValue,
    }
  } else if (resErrorKey === 'CanNotSaveStartDate') {
    result.status = true
    result.field = 'DATE'
    result.obj = {
      field: errorMessageField.DATE,
      message: errorMessageType.EMPTY_DATE,
      messageType: resErrorValue,
    }
  }
  if (result.status) {
    dispatch(
      handleErrorMessage({
        field: result.field,
      }),
    )
    dispatch(reducer.setErrorSchemaMessage(result.obj))
  }
  return result
}

export const removeFieldImage = (item) => {
  const newItem = { ...item }
  if (newItem.loadingProgress || item.loadingProgress === null)
    delete newItem.loadingProgress
  if (newItem.uploadError || item.uploadError === null)
    delete newItem.uploadError
  if (newItem.urlVideo || item.urlVideo === null) delete newItem.urlVideo
  if (newItem.closeVideo) delete newItem.closeVideo
  return newItem
}

export const removeFieldQuestionDefault = (item, isClone) => {
  if (_.isEmpty(item)) return []
  const newItem = removeFieldImage(item)
  delete newItem.sequence
  if (newItem.choices) delete newItem.choices
  if ((isClone && newItem.uuid) || newItem.uuid === '') delete newItem.uuid
  if (newItem.uploadError || item.uploadError === null)
    delete newItem.uploadError
  if (newItem.loadingProgress || item.loadingProgress === null)
    delete newItem.loadingProgress
  return [
    {
      ...newItem,
      questions: removeId(item.questions, isClone),
    },
  ]
}

export const removeFieldSetting = (setting) => {
  const startDate = _.get(setting, 'startDate', '')
  return {
    startDate:
      startDate === null || startDate === 'Invalid Date' ? '' : startDate,
    assignees: _.get(setting, 'assignees', ''),
    displayQuestion: _.get(setting, 'displayQuestion', ''),
    notification: _.get(setting, 'notification', true),
    isShare: _.get(setting, 'isShare', false),
    shareLink: _.get(setting, 'shareLink', ''),
  }
}

export const removeFieldQuestions = (
  listQuestions,
  listSubQuestion,
  isClone,
) => {
  if (!listQuestions.length) return []

  return listQuestions.map((itemQuestion) => {
    const questionType = _.get(itemQuestion, 'questionType', 'SINGLE')
    return removeIdQuestions(
      itemQuestion,
      questionType,
      listSubQuestion,
      isClone,
    )
  })
}

export const removeIdQuestions = (
  item,
  questionType,
  listSubQuestion,
  isClone,
) => {
  const defaultField = {
    uuid: item.uuid || '',
    title: item.title,
    questionType: item.questionType,
    sequence: item.sequence,
    url: item.url,
    mediaType: item.mediaType,
  }
  if ((isClone && defaultField.uuid) || defaultField.uuid === '')
    delete defaultField.uuid

  switch (questionType) {
    case 'SINGLE':
      return {
        ...defaultField,
        setNextQuestion: item.setNextQuestion,
        answers: removeId(item.answers, isClone),
        subQuestions: findSubQuestions(listSubQuestion, item.sequence, isClone),
      }
    case 'MULTIPLE':
      return {
        ...defaultField,
        setTypeOfAnswers: item.setTypeOfAnswers,
        typeOfAnswers: item.typeOfAnswers,
        numberOfAnswers: item.numberOfAnswers,
        answers: removeId(item.answers, isClone),
      }
    case 'RATING':
      return {
        ...defaultField,
        isSuggestion: item.isSuggestion,
        choices: removeId(item.choices, isClone),
        questions: removeId(item.questions, isClone),
      }
    default:
      return defaultField
  }
}

export const removeId = (list, isClone) => {
  if (!list.length) return []
  return list.map((item) => {
    const newItem = removeFieldImage(item)
    delete newItem.id
    if ((isClone && newItem.uuid) || newItem.uuid === '') delete newItem.uuid
    if (newItem.questionType) delete newItem.questionType
    if (newItem.closeVideo) delete newItem.closeVideo

    return newItem
  })
}

export const findSubQuestions = (listSubQuestion, sequence, isClone) => {
  let result = []
  if (!listSubQuestion.length) return result
  const objSubQuestions = listSubQuestion.find(
    (item) => item.sequence === sequence,
  )
  const itemSubQuestions = _.get(objSubQuestions, 'value', [])
  const results = itemSubQuestions.map((item) => {
    const newItem = {
      uuid: item.uuid || '',
      title: item.title,
      questionType: item.questionType,
      sequence: item.sequence,
      url: item.url,
      mediaType: item.mediaType,
    }
    if ((isClone && newItem.uuid) || !newItem.uuid) delete newItem.uuid

    switch (item.questionType) {
      case 'SINGLE':
        return {
          ...newItem,
          answers: removeId(item.answers, isClone),
        }
      case 'MULTIPLE':
        return {
          ...newItem,
          setTypeOfAnswers: item.setTypeOfAnswers,
          typeOfAnswers: item.typeOfAnswers,
          numberOfAnswers: item.numberOfAnswers,
          answers: removeId(item.answers, isClone),
        }
      case 'RATING':
        return {
          ...newItem,
          isSuggestion: item.isSuggestion,
          choices: removeId(item.choices, isClone),
          questions: removeId(item.questions, isClone),
        }
      default:
        return newItem
    }
  })

  return results
}

export const getErrorMessage = ({
  field = '',
  errorSchemaMessage,
  indexQuestion = 0,
  indexAnswer = 0,
}) => {
  if (_.get(errorSchemaMessage, 'message', '') === '') return ''
  let errorMessage = ''
  if (field !== '') {
    //-1 Because get real index from message(message show No)
    const splitMessage = errorSchemaMessage.message.split(/(\d+)/)
    const indexQuestionMessage = Number(splitMessage[1]) - 1
    //if validate message for display text error to input position and scroll in to view
    if (
      (field === errorMessageField.DATE &&
        _.get(errorSchemaMessage, 'field', '') === errorMessageField.DATE) ||
      (field === errorMessageField.TITLE1 &&
        _.get(errorSchemaMessage, 'field', '') === errorMessageField.TITLE1) ||
      (field === errorMessageField.TITLE2 &&
        _.get(errorSchemaMessage, 'field', '') === errorMessageField.TITLE2) ||
      (field === errorMessageField.COVER_TEXT &&
        _.get(errorSchemaMessage, 'field', '') ===
          errorMessageField.COVER_TEXT) ||
      (field === errorMessageField.NAME &&
        _.get(errorSchemaMessage, 'field', '') === errorMessageField.NAME) ||
      (field === errorMessageField.DESCRIPTION &&
        _.get(errorSchemaMessage, 'field', '') ===
          errorMessageField.DESCRIPTION)
    ) {
      errorMessage = errorSchemaMessage.messageType
    } else if (
      field === errorMessageField.CHOICES &&
      _.get(errorSchemaMessage, 'field', '') === errorMessageField.CHOICES
    ) {
      const indexAnswerMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.QUESTION_QUESTION &&
      _.get(errorSchemaMessage, 'field', '') ===
        errorMessageField.QUESTION_QUESTION
    ) {
      const indexAnswerMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.QUESTION &&
      _.get(errorSchemaMessage, 'field', '') === errorMessageField.QUESTION &&
      errorSchemaMessage.sectionType === assessmentField.question
    ) {
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.MANAGE_ASSESSMENT &&
      _.get(errorSchemaMessage, 'field', '') ===
        errorMessageField.MANAGE_ASSESSMENT &&
      errorSchemaMessage.sectionType === assessmentField.courseManagement
    ) {
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.ANSWER &&
      _.get(errorSchemaMessage, 'field', '') === errorMessageField.ANSWER &&
      errorSchemaMessage.sectionType === assessmentField.question
    ) {
      const indexAnswerMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.ASSESSMENT &&
      _.get(errorSchemaMessage, 'field', '') === errorMessageField.ASSESSMENT &&
      errorSchemaMessage.sectionType === assessmentField.course
    ) {
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? errorSchemaMessage.messageType
          : ''
    } else if (
      field === errorMessageField.LECTURER_ASSESSMENT &&
      _.get(errorSchemaMessage, 'field', '') ===
        errorMessageField.LECTURER_ASSESSMENT &&
      errorSchemaMessage.sectionType === assessmentField.lecturer
    ) {
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? errorSchemaMessage.messageType
          : ''
    }
  }
  return errorMessage
}

export const convertErrorMessage = (errorMessage) => {
  const obj = {
    field: '',
    message: errorMessage,
    questionNo: -1,
    sectionType: '',
    messageType: '',
  }
  if (_.isNil(errorMessage) || errorMessage === '') return obj
  const { message } = obj
  //if validate for set error meesage
  if (message.includes('กรุณากรอกชื่อแบบประเมิน')) {
    obj.field = errorMessageField.NAME
    obj.messageType = errorMessageType.EMPTY_NAME
  } else if (message.includes('กรุณาระบุวันเริ่มต้นใช้งาน')) {
    obj.field = errorMessageField.DATE
    obj.messageType = errorMessageType.EMPTY_DATE
  } else if (message.includes('ข้อความหน้าปกระบุได้ไม่เกิน')) {
    obj.field = errorMessageField.COVER_TEXT
    obj.messageType = errorMessageType.OUT_LENGTH_100
    obj.messageType = errorMessageType.EMPTY
  } else if (message.includes('ชื่อแบบประเมินระบุได้ไม่เกิน')) {
    obj.field = errorMessageField.NAME
    obj.messageType = errorMessageType.OUT_LENGTH_255
  } else if (message.includes('หัวข้อ 1 ระบุได้ไม่เกิน')) {
    obj.field = errorMessageField.TITLE1
    obj.messageType = errorMessageType.OUT_LENGTH_100
  } else if (message.includes('หัวข้อ 2 ระบุได้ไม่เกิน')) {
    obj.field = errorMessageField.TITLE2
    obj.messageType = errorMessageType.OUT_LENGTH_100
  } else if (message.includes('รายละเอียดระบุได้ไม่เกิน')) {
    obj.field = errorMessageField.DESCRIPTION
    obj.messageType = errorMessageType.OUT_LENGTH_100
  } else if (
    message.includes('กรุณากรอก แบบประเมินเนื้อหาหลักสูตร คำถามข้อที่')
  ) {
    obj.field = errorMessageField.ASSESSMENT
    obj.sectionType = assessmentField.course
    obj.messageType = errorMessageType.EMPTY_QUESTION
  } else if (
    message.includes('กรุณากรอก แบบประเมินการบริหารจัดการหลักสูตร คำถามข้อที่')
  ) {
    obj.field = errorMessageField.MANAGE_ASSESSMENT
    obj.sectionType = assessmentField.courseManagement
    obj.messageType = errorMessageType.EMPTY_QUESTION
  } else if (message.includes('กรุณากรอก แบบประเมินวิทยากร คำถามข้อที่')) {
    obj.field = errorMessageField.LECTURER_ASSESSMENT
    obj.sectionType = assessmentField.lecturer
    obj.messageType = errorMessageType.EMPTY_QUESTION
  } else if (
    message.includes('กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่') &&
    message.includes('ตัวเลือกข้อที่')
  ) {
    obj.field = errorMessageField.CHOICES
    obj.sectionType = assessmentField.question
    obj.messageType = errorMessageType.EMPTY_ANSWER
  } else if (
    message.includes(
      'กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ หัวข้อการประเมินข้อที่',
    ) &&
    (message.includes('ตัวเลือกข้อที่') || message.includes('คำถามข้อที่'))
  ) {
    obj.field = message.includes('คำถามข้อที่')
      ? errorMessageField.QUESTION_QUESTION
      : errorMessageField.CHOICES
    obj.sectionType = assessmentField.question
  } else if (
    message.includes('กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่') &&
    message.includes('คำถามย่อยที่')
  ) {
    obj.field = errorMessageField.QUESTION_QUESTION
    obj.sectionType = assessmentField.question
    obj.messageType = errorMessageType.EMPTY_QUESTION
  } else if (
    message.includes('กรุณากรอก แบบประเมิน/แบบสอบถามอื่นๆ คำถามข้อที่')
  ) {
    obj.field = errorMessageField.QUESTION
    obj.sectionType = assessmentField.question
    obj.messageType = errorMessageType.EMPTY_QUESTION
  }

  if (message.includes('กรอกได้ไม่เกิน')) {
    obj.messageType = errorMessageType.OUT_LENGTH_3000
  }
  const splitMessage = errorMessage.split(/(\d+)/)
  obj.questionNo = Number(_.get(splitMessage, '[1]', 1))
  obj.answerNo = Number(_.get(splitMessage, '[3]', 1))
  return obj
}

export const handleErrorMessage = (obj) => async (dispatch) => {
  if (obj.field === '') return
  let value = ''

  if (obj.field === 'DATE') value = 'setting'
  else value = 'assessment'
  dispatch(
    reducer.setChange({
      key: 'defaultTap',
      value: value,
    }),
  )
}

export const validateInitialData = (objEva) => {
  const coverImage = _.get(objEva, 'information.coverImage', '')
  const coverText = _.get(objEva, 'information.coverText', '')
  const description = _.get(objEva, 'information.description', '')
  const name = _.get(objEva, 'information.name', '')
  const title1 = _.get(objEva, 'information.title1', '')
  const title2 = _.get(objEva, 'information.title2', '')
  const startDate = _.get(objEva, 'setting.startDate', null)
  const questions = _.get(objEva, 'questions', [])
  const courseAssessments = _.get(objEva, 'courseAssessments', [])
  const courseManagementAssessments = _.get(
    objEva,
    'courseManagementAssessments',
    [],
  )
  const lecturerAssessments = _.get(objEva, 'lecturerAssessments', [])

  const hasAllCondition =
    questions.length === 0 &&
    courseAssessments.length === 0 &&
    courseManagementAssessments.length === 0 &&
    lecturerAssessments.length === 0 &&
    coverImage === '' &&
    coverText === '' &&
    description === '' &&
    name === '' &&
    title1 === '' &&
    title2 === '' &&
    startDate === null

  return hasAllCondition
}

export const scrollSmoothHandler = (no, ref) => {
  const realIndex = no - 1
  console.log('ref', ref)
  if (no < 0 || _.isNaN(no) || _.isNull(ref.current[realIndex].current)) return
  ref.current[realIndex].current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}
