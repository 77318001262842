import { store } from '../../../../../App'
import { STATUS } from '../model/constant'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }
  let typeOfETestingArr = []
  if (filterState.typeOfETestingChecked) {
    if (filterState.typeOfETesting.onlineTestChecked) {
      typeOfETestingArr.push(`ONLINE_TEST`)
    }
    if (filterState.typeOfETesting.assessmentChecked) {
      typeOfETestingArr.push(`ASSESSMENT`)
    }
    if (filterState.typeOfETesting.standAloneChecked) {
      typeOfETestingArr.push(`STANDALONE`)
    }
  } else {
    typeOfETestingArr = []
  }

  return {
    codeId: filterState.codeIdChecked ? filterState.codeId : '',
    name: filterState.nameChecked ? filterState.name : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    createdBy: filterState.createdByChecked ? filterState.createdBy : '',
    status: status,
    typeOfETesting: typeOfETestingArr,
  }
}
