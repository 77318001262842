import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledItemCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(1.5),
  background: '#F6F6F6',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
}))

export const StyledHeadCard = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiBox-root': { cursor: 'pointer' },
}))

export const StyledBoxVideo = styled(Box)(({ theme }) => ({
  width: 150,
  height: 100,
  position: 'relative',
  border: '1px solid',
  borderColor: theme.palette?.other?.outlineBorder,
  borderRadius: theme.spacing(0.5),
  background:
    'linear-gradient(39.84deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 47.09%)',
  svg: {
    color: theme.palette?.text?.white,
    position: 'relative',
    bottom: 24,
    left: 4,
  },
}))