import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledRoundBox = styled(Box)(({ theme }) => ({
  minWidth: 130,
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: {
    minWidth: 100,
  },
}))
