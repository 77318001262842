import _ from 'lodash'
import { setInitialTable } from '../../../redux/slices/table'
import { getMeetingRoomHistory } from '../../../services/masterData/meetingRoom'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'event',
    disablePadding: false,
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'author',
    disablePadding: false,
    label: 'ผู้แก้ไข',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchMeetingRoomHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`
  const result = await dispatch(getMeetingRoomHistory(url))
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: _.get(result, 'payload.result', []),
      allCount: _.get(result, 'payload.totalCount', 0),
      headCells: headCells,
      customStyle: StyledTable,
    })
  )
}
