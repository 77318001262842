import * as yup from 'yup'

export const schema = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อของรางวัล')
    .max(255, 'ชื่อของรางวัลกรอกได้ไม่เกิน 255 ตัวอักษร'),
  description: yup.string().when('isDraft', {
    is: false,
    then: yup.string().max(3000, 'คำอธิบายกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  point: yup.string().when('isDraft', {
    is: false,
    then: yup
      .string()
      .required('กรุณากรอกคะแนนที่ใช้แลก')
      .test('point-test', 'กรุณากรอกคะแนนที่ใช้แลกเป็นตัวเลข', (val) => {
        return /^[0-9]+$/.test(val)
      })
      .test(
        'point-length-test',
        'คะแนนที่ใช้แลกกรอกได้ไม่เกิน 10 หลัก',
        (val) => {
          return val?.length <= 10
        },
      ),
  }),
  qty: yup.string().when('isDraft', {
    is: false,
    then: yup
      .string()
      .required('กรุณากรอกจำนวนของรางวัล')
      .test('point-test', 'กรุณากรอกจำนวนของรางวัลเป็นตัวเลข', (val) => {
        return /^[0-9]+$/.test(val)
      })
      .test('qty-test', 'จำนวนของรางวัลกรอกได้ไม่เกิน 10 หลัก', (val) => {
        return val?.length <= 10
      }),
  }),
  image: yup.object().when('isDraft', {
    is: false,
    then: yup.object({
      key: yup.string().required('กรุณาอัปโหลดรูปของรางวัล'),
      fileName: yup.string(),
      fileSize: yup.string(),
    }),
  }),
  remark: yup.string().when('isDraft', {
    is: false,
    then: yup.string().max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
})
