import React from 'react'
import { useDispatch } from 'react-redux'

import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import { getDetailQuestion } from '../../../../../services/eLearning/question/detail'
import { setOpenDrawer } from '../../../../../redux/slices/eLearning/question'

const ActionQuestion = ({ row, rowIndex, minWidth }) => {
  const dispatch = useDispatch()
  return (
    <StyledBox minWidth={minWidth}>
      <IconButton
        data-testid={`btn-edit-${rowIndex}`}
        color="primary"
        component="span"
        onClick={async () => {
          await dispatch(getDetailQuestion(row.uuid))
          dispatch(setOpenDrawer(true))
        }}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>
    </StyledBox>
  )
}

export default ActionQuestion
