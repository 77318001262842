import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ECertificationSetting from '../../../../../CourseSetting/ECertificationSetting'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import _ from 'lodash'

const ECertification = () => {
  const dispatch = useDispatch()
  const { eCertificateList, isECertLoading, body, onGoing } = useSelector(
    (state) => ({
      eCertificateList: state.eLearningCourseForm.eCertificateList,
      isECertLoading: state.eLearningCourseForm.isECertLoading,
      body: {
        englishCertificateName: getStateValue('englishCertificateName'),
        certificateLanguage: getStateValue('certificateLanguage'),
        eCertificationVersion: getStateValue('eCertificationVersion'),
        expireECertificationYear: getStateValue('expireECertificationYear'),
      },
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual
  )
  const eCertificationUuid = _.get(
    body,
    'eCertificationVersion.uuid',
    _.get(body, 'eCertificationVersion', '')
  )
  const eCertBody = {
    ...body,
    eCertificationVersion: _.isEmpty(eCertificationUuid)
      ? ''
      : { uuid: eCertificationUuid },
  }
  const [initialValues, setInitialValues] = useState({ ...eCertBody })
  const formik = {
    values: { ...eCertBody },
    setFieldValue: (key, value) => dispatch(handleChange(key, value)),
    handleChange: (e) => dispatch(handleChangeEvent(e)),
  }

  return (
    <ECertificationSetting
      eCertification={eCertificateList}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      formik={formik}
      isLoading={isECertLoading}
      disabled={onGoing}
    />
  )
}

export default ECertification
