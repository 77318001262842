import _ from 'lodash'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setSearchText } from '../../../../../../../../../redux/slices/table'
// import { onEnterSearch } from './events'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { handleQuickSearch } from '../../../../../handler/handleQuickSearch'

const SearchCustom = () => {
  const dispatch = useDispatch()
  const { table, search } = useSelector(
    (state) => ({ search: state.table.search, table: state.table.table }),
    shallowEqual,
  )
  const tableProps = table

  const handleSearch = () => {
    dispatch(
      handleQuickSearch({ ...tableProps }, _.get(search, 'searchText', ''), {
        placeholder: 'ค้นหาด้วยคะแนน',
      }),
    )
  }

  return (
    <BoxSearch sx={{ marginLeft: 'auto' }}>
      <SearchInput
        data-testid="search-input"
        placeholder={search.placeholder}
        onChange={(e) => dispatch(setSearchText(e.target.value))}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            handleSearch(e.target.value)
            dispatch(setSearchText(e.target.value))
          }
          // dispatch(onEnterSearch(e, handleSearch))
        }}
        value={_.get(search, 'searchText', '')}
        inputProps={{
          maxLength: search?.maxLength > 0 ? search.maxLength : 255,
        }}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={(e) => handleSearch(e.target.value)}
      />
    </BoxSearch>
  )
}

export default SearchCustom
