import Typography from '@mui/material/Typography'
import React from 'react'
import { StyledProgress, StyledProgressMilestone } from './Styled'

const ProgressMilestone = ({ value = 0 }) => {
  return (
    <StyledProgressMilestone>
      <Typography color="text.white">{value}%</Typography>
      <StyledProgress value={value} />
    </StyledProgressMilestone>
  )
}

export default ProgressMilestone
