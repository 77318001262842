import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  manageClassDetail,
  manageClassETestEEvaList,
  manageClassETestEEvaStart,
  manageClassHistory,
  courseVersion,
  manageClassClose,
  manageClassCheckPassUser,
  manageClassFile,
} from '../../utils/apiPath'

export const getManageClassDetailByUuid = createAsyncThunk(
  'manageClassDetail/getManageClassDetailByUuid',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassDetail}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageClassDownloadFiles = createAsyncThunk(
  'manageClassDetail/getManageClassDownloadFiles',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${courseVersion}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageClassGraph = createAsyncThunk(
  'manageClassDetail/getManageClassGraph',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassCheckPassUser}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const manageClassCheckClose = createAsyncThunk(
  'manageClassDetail/manageClassCheckClose',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageClassClose, uuid)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getETestingAndEEvaluation = createAsyncThunk(
  'manageClassDetail/getETestingAndEEvaluation',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageClassETestEEvaList}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putStartETestEEva = createAsyncThunk(
  'manageClassDetail/putStartETestEEva',
  async (body, { rejectWithValue }) => {
    try {
      const isCheckExist = _.get(body, 'isCheckExist', true)
      const response = await axios.put(manageClassETestEEvaStart, body)
      return { response, isCheckExist }
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageClassEvent = createAsyncThunk(
  'manageClassDetail/manageClassEvent',
  async (uuid, { rejectWithValue }) => {
    try {
      const url = `?limit=2000&page=1&uuid=${uuid}`
      const response = await axios.get(`${manageClassHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadEditor = createAsyncThunk(
  'manageClassDetail/uploadEditor',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageClassFile, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
