import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledHeader = styled(Box)(({ theme }) => ({
  margin: `0 0 ${theme.spacing(1)} ${theme.spacing(3)}`,
  paddingTop: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(3),
  width: 'calc(100% - 48px)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: 0,
    marginBottom: theme.spacing(2),
  },
}))
