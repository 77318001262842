import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBoxHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 14,
  marginTop: -14,
  backgroundColor: theme?.palette?.background?.blue,
  [theme.breakpoints.down('md')]: { alignItems: 'start' },
}))

export const StyledBoxLabelTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledInputImport = styled('input')({ display: 'none' })
