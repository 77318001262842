import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import Container from '@mui/material/Container'
import Header from './Header'
import BottomTagLine from './BottomTagLine'
// import { SeeAlsoBar } from '../SeeAlsoBar'
import Content from './Content'
import { setChange } from '../../../../redux/slices/eContent/content'

const Detail = () => {
  const dispatch = useDispatch()
  const [eContentPreview] = useLocalStorage('eContentPreview')
  const preview = useSelector(
    (state) => state.contentForm.preview,
    shallowEqual,
  )
  useEffect(() => {
    dispatch(setChange({ key: 'preview', value: eContentPreview }))
  }, [])
  return (
    <Container maxWidth="lg">
      <Header />
      <Content />
      <BottomTagLine Tag={preview.eContentTag} />
      {/* <SeeAlsoBar /> */}
    </Container>
  )
}

export default Detail
