import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import Questions from '../Questions'
import Footer from '../Footer'

import { BoxForm } from '../../../Styled'

const Index = ({ props }) => {
  return (
    <>
      <Card
        sx={{
          ml: props.isSubQuestions ? 2 : 0,
          mt: props.isSubQuestions || !props.indexQuestion ? 0 : 3,
        }}
      >
        <BoxForm>
          <Questions props={props} />
        </BoxForm>
        <Box sx={{ mx: 1 }}>
          <Footer props={props} />
        </Box>
      </Card>
    </>
  )
}
export default Index
