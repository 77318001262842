import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'
import { Row, Column } from '../Styled'
import { setIsOpenFilter } from '../../../../redux/slices/eLearning/dashboard'
import FilterDrawer from './FilterDrawer'
import { getTotalFilter } from '../events'

const Header = () => {
  const dispatch = useDispatch()
  const { filterMemo } = useSelector(
    (state) => ({
      filterMemo: state.eLearningDashboard.filterMemo,
    }),
    shallowEqual,
  )
  return (
    <Column>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h4">E-Learning Dashboard</Typography>
        <Button
          sx={{
            height: 40,
            padding: 1,
          }}
          variant="text"
          startIcon={<FilterListOutlined />}
          onClick={() => dispatch(setIsOpenFilter(true))}
        >
          {`ตัวกรอง${getTotalFilter(filterMemo)}`}
        </Button>
        <FilterDrawer />
      </Row>
      <Row>
        <Typography variant="body2" color="#0000008A">
          {`ข้อมูลเมื่อวันที่ ${dayjs(new Date()).format('DD/MM/YYYY HH:mm')}`}
        </Typography>
      </Row>
    </Column>
  )
}

export default Header
