import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const postBodyMutation = (data) => {
  return {
    name: _.get(data, 'name', ''),
    remark: _.get(data, 'remark', ''),
    status: _.get(data, 'status', enumStatus.ACTIVE),
    uuid: _.get(data, 'uuid', undefined),
  }
}
