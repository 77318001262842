import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { themeColor } from './themeData'
import _ from 'lodash'
import useTheme from '@mui/system/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

import { IgnorePath } from '../constants/path'
import 'dayjs/locale/th'
import { closeDialog, openDialog } from '../redux/slices/dialog'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import updateLocale from 'dayjs/plugin/updateLocale'
import { monthNames } from '../constants/eExamination'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'

export const callAPI = async ({
  url,
  method,
  body = {},
  headers = {},
  onSuccess,
  onError,
  onFinally,
  responseType = 'json',
  onUploadProgress,
}) => {
  return await axios({
    method,
    url,
    headers,
    responseType,
    data: body,
    onUploadProgress,
  })
    .then((res) => {
      onSuccess && onSuccess(res.data)
      return res.data
    })
    .catch((err) => {
      if (onError) {
        try {
          onError(err)
        } catch (exception) {
          console.log(err, exception)
        }
        return err
      } else {
        return Promise.reject(err)
      }
    })
    .finally(() => {
      onFinally && onFinally()
    })
}

export const getStatusText = (status) => {
  if (!status) {
    return 'ปิดใช้งาน'
  }
  const textList = [
    { status: 'ACTIVE', text: 'เปิดใช้งาน' },
    { status: 'INACTIVE', text: 'ปิดใช้งาน' },
    { status: 'DELETED', text: 'ถูกลบแล้ว' },
    { status: 'APPROVE', text: 'อนุมัติ' },
    { status: 'WAITING', text: 'รอการอนุมัติ' },
    { status: 'REJECT', text: 'ไม่อนุมัติ' },
    { status: 'DRAFT', text: 'ฉบับร่าง' },
    { status: 'CANCEL', text: 'ยกเลิกคำขอ' },
    { status: 'REQUEST', text: 'รอส่งคำขอ' },
    { status: 'READY', text: 'เผยแพร่' },
    { status: 'READYINACTIVE', text: 'เผยแพร่' },
  ]
  return textList.find((item) => item.status === status)?.text || status
}

export const setMapListOptions = (list, key = '') => {
  if (!list) {
    return []
  }
  return list.map((i) => {
    return { label: i[key], value: i.uuid }
  })
}

export const getOptionsKey = ({ data, label, key }) => {
  if (!data.length) return []
  return data.map((item) => {
    let labelText = item[label]
    if (label === 'abbreviation') {
      labelText = item[label].toUpperCase()
    } else if (label === 'productType') {
      labelText = item?.productType
    } else if (label === 'fullname') {
      labelText = `${item?.firstName} ${item?.lastName}`
    } else if (label === 'fullnameTH') {
      labelText = `${item?.firstNameTH} ${item?.lastNameTH}`
    } else if (label === 'fullnameTHManageClass') {
      labelText = `${item?.firstNameTH} ${item?.lastNameTH}`
      return { label: labelText, value: labelText, image: item.image }
    } else if (label === 'trainerStaffID' || label === 'agentCode') {
      labelText = item?.agentCode
      return { label: labelText, value: labelText, image: item.image }
    } else if (
      label === 'roadMap' ||
      label === 'levelOfLearner' ||
      label === 'otherLevelOfLearner'
    ) {
      labelText = item?.title
    } else if (label === 'companyUuid') {
      labelText = item.academyStationName
    } else if (label === 'regionUuid') {
      labelText = item.regionName
    } else if (label === 'associationUuid') {
      labelText = item.provinceName
    } else if (label === 'licenseCategory') {
      labelText = item.category
    } else if (label === 'licenseName' || label === 'licenseDescription') {
      labelText = item.description
    } else if (label === 'department') {
      labelText = item.department
    }
    return { label: labelText, value: item[key], image: item.image }
  })
}

export const convertValueToOptionValue = ({ data, label, key }) => {
  return { label: data[label], key: data[key] }
}

export const checkNumberValue = (value) => {
  return /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/.test(value)
}

export const getDialogErrorMessage = (er, setDialog) => {
  try {
    const message = er?.response?.data?.message
    return {
      open: true,
      variant: 'fail',
      content: message,
      isLoading: false,
      onConfirmClick: () =>
        setDialog({
          variant: 'fail',
          content: message,
          open: false,
        }),
    }
  } catch (ex) {
    return {
      open: true,
      variant: 'fail',
      content: '',
      isLoading: false,
      onConfirmClick: () =>
        setDialog({
          variant: 'fail',
          content: '',
          open: false,
        }),
    }
  }
}

export const getDialogErrorFormMessage = (
  formik,
  er,
  initialValues,
  setDialog,
) => {
  try {
    const messageList = er?.response?.data?.message
    let errorText = ''
    const objErrorKey = {}
    if (messageList && messageList.length > 0 && initialValues) {
      messageList.map((item) => {
        const findItem = Object.getOwnPropertyNames(initialValues).find(
          (value) => value === item.property,
        )
        errorText += item.constraints.value + ', '
        objErrorKey[`${findItem}`] = item.constraints.value
      })
      if (formik) {
        formik.setErrors(objErrorKey)
      }

      errorText = errorText.substring(0, errorText.length - 2)
    } else {
      errorText = messageList
    }

    return {
      open: true,
      title: _.get(messageList, '[0].title', 'เกิดข้อผิดพลาด'),
      variant: 'fail',
      content: errorText,
      onConfirmClick: () =>
        setDialog({
          variant: 'fail',
          content: errorText,
          open: false,
        }),
      isLoading: false,
    }
  } catch (ex) {
    return {
      open: true,
      variant: 'fail',
      content: '',
      onConfirmClick: () =>
        setDialog({
          variant: 'fail',
          content: '',
          open: false,
        }),
    }
  }
}

export const getPeriodTimeText = (time) => {
  let text
  const numStr = String(time)
  if (numStr.includes('.')) {
    const splitStr1 = numStr.split('.')[1]
    const str = splitStr1 === '5' ? '30' : '00'
    text = numStr.split('.')[0] + '.' + str
  } else {
    text = numStr + '.00'
  }
  return text ?? ''
}

export const validateCourseCondition = (values) => {
  const validateCondition = values.courseCondition.map((conditionList) => {
    return conditionList.filter((condition) => condition.uuid !== '')
  })
  return validateCondition.filter((conditionList) => conditionList.length !== 0)
}

export const checkMonthlyPlanPermission = (user, type) => {
  const permissions = [
    {
      type: 'btn_submit_plan',
      role: 'HZ',
    },
    {
      type: 'btn_approve_budget_plan',
      role: 'HF',
    },
    {
      type: 'btn_approve_plan',
      role: 'HF',
    },
  ]
  return (
    user?.roles &&
    user.roles.find((item) => {
      const findType = permissions.find(
        (permission) => permission.type === type,
      )
      return item.key === findType.role
    })
  )
}

export const getMasterPlanTitleStatus = (state) => {
  const role = state?.area?.role?.key
  const status = state?.masterPlan?.status
  const list = [
    { role: 'HF', status: 'APPROVE', text: 'ตรวจสอบแล้ว' },
    { role: 'HF', status: 'WAITING', text: 'อยู่ระหว่างตรวจสอบ 1st' },
    { role: 'HF', status: 'DRAFT', text: 'HZ ยังไม่ส่งตรวจสอบ' },
    { role: 'HF', status: 'REJECT', text: 'ตรวจสอบแล้ว' },
    { role: 'HF', status: '', text: 'HZ ยังไม่ส่งตรวจสอบ' },
    { role: 'HZ', status: 'APPROVE', text: 'ตรวจสอบแล้ว: อนุมัติทั้งแผน' },
    { role: 'HZ', status: 'WAITING', text: 'อยู่ระหว่างตรวจสอบ' },
    { role: 'HZ', status: 'DRAFT', text: 'ยังไม่ส่งตรวจสอบ' },
    { role: 'HZ', status: 'REJECT', text: 'ตรวจสอบแล้ว: ไม่อนุมัติทั้งแผน' },
    { role: 'TRAINER', status: 'APPROVE', text: '' },
    { role: 'TRAINER', status: 'WAITING', text: '' },
    { role: 'TRAINER', status: 'DRAFT', text: '' },
    {
      role: 'TRAINER',
      status: 'REJECT',
      text: '',
    },
  ]
  const findItem = list.find(
    (item) => item.role === role && item.status === status,
  )
  return findItem?.text || ''
}

export const getMasterPlanTextStatus = (state) => {
  const lastUpdate = state?.masterPlan?.masterPlan?.updatedAt
  const status = state?.masterPlan?.status
  if (_.isNil(status) || status === '') {
    return 'ยังไม่ได้ส่งตรวจสอบ'
  }
  const date = convertFormatDateTime({
    value: lastUpdate,
    type: 'date',
  })
  const statusList = {
    APPROVED: `ตรวจสอบล่าสุด ${date}`,
    WAITING: 'อยู่ระหว่างตรวจสอบ',
    REJECTED: `ตรวจสอบล่าสุด ${date}`,
    PATIAL_APPROVED: `ตรวจสอบล่าสุด ${date}`,
    RECALL: 'ยังไม่ได้ส่งตรวจสอบ',
    DRAFT: 'ยังไม่ได้ส่งตรวจสอบ',
  }
  return statusList[status]
}

export const getColorMasterPlanStatus = ({ status, isApproveList }) => {
  if (_.isNil(status) || status === '') {
    return themeColor.text.silver
  }
  if (isApproveList && status === 'REJECTED') {
    return themeColor.text.silver
  }
  const colorList = {
    APPROVED: themeColor.success.main,
    WAITING: themeColor.other.ratingActive,
    REJECTED: themeColor.success.main,
    PATIAL_APPROVED: themeColor.success.main,
    DRAFT: themeColor.text.silver,
    RECALLED: themeColor.text.silver,
    RECALL: themeColor.text.silver,
    REQUEST_EDIT: themeColor.text.silver,
    REQUEST_CREATE: themeColor.text.silver,
  }
  return colorList[status] || themeColor.text.silver
}

export const checkDialogValue = (value, error, field) => {
  return error[field] !== '' || value[field] === null || value[field] === ''
}

export const convertJoinDate = (value) => {
  let workStart = ''
  const today = new Date()
  const startDate = dayjs(value)
  const endDate = dayjs(today)

  const years = endDate.diff(startDate, 'year')
  const months = endDate.diff(startDate, 'month') - years * 12
  const days = endDate.diff(
    startDate.add(years, 'year').add(months, 'month'),
    'day',
  )

  if (years > 0) {
    workStart += `${years} ปี `
  }
  if (months > 0) {
    workStart += `${months} เดือน `
  }
  if (days > 0) {
    workStart += `${days} วัน`
  }
  return workStart
}

export const hasPermission = ({
  userPermissions = [],
  permissionList = [],
}) => {
  let isAccess = false
  permissionList.forEach((item) => {
    const findPermission = userPermissions.find((userPermission) => {
      return (
        //first condition with PIC
        (userPermission.module === item.module &&
          userPermission.owner === item.owner &&
          item.permission.find(
            (itemPermission) => userPermission.permission === itemPermission,
          ) &&
          _.isNil(item.isValidatePIC)) ||
        //or condition without PIC
        (userPermission.module === item.module &&
          userPermission.owner === item.owner &&
          item.permission.find(
            (itemPermission) => userPermission.permission === itemPermission,
          ) &&
          !_.isNil(item.isValidatePIC) &&
          item.isValidatePIC === true)
      )
    })

    if (findPermission) {
      isAccess = true
    }
  })
  return isAccess
}

export const hasPermissionPIC = ({ user, data, moduleType, permission }) => {
  if (_.isNil(data)) {
    return true
  }
  const assignee = _.get(data, 'assignee', [])
  const staffProfile = _.get(data, 'staffProfile', [])
  const areaUID = _.get(data, 'area.uuid', '')
  let hasFindPermission = false
  permission.forEach((perm) => {
    switch (`${moduleType}_${perm}`) {
      case 'COURSE_SETTING_CREATE':
        hasFindPermission = !!assignee.find(
          (item) => _.get(item, 'user.uuid', '') === _.get(user, 'uuid', ''),
        )
        break
      case 'COURSE_SETTING_DELETE':
        hasFindPermission = !!assignee.find(
          (item) => _.get(item, 'user.uuid', '') === _.get(user, 'uuid', ''),
        )
        break
      case 'MONTHLY_PLAN_APPROVE_ZONE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_ASSISTS_ZONE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_ALL':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_LEAVE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_ALL':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_LEAVE':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'MONTHLY_PLAN_DELETE_ALL_EXC_HOLIDAY':
        hasFindPermission = !!findAreaUID({ areaUID, user })
        break
      case 'TRAINING_PLAN_CREATE':
        hasFindPermission = !!findStaffId({ staffProfile, user })
        break
      case 'E_MANAGECLASS_SETTING_EDIT_ALL_CLASS':
        hasFindPermission = !!findStaffId({ staffProfile, user })
        break
      case 'E_LEARNING_ADMIN':
      case 'E_LEARNING_TRAINER':
        hasFindPermission = _.get(data, 'setting.assignees', []).some(
          (item) => _.get(item, 'value', '') === _.get(user, 'uuid', ''),
        )
        break
    }
  })
  return hasFindPermission
}

const findStaffId = ({ staffProfile, user }) => {
  return staffProfile.find((item) => item.uuid === user.uuid)
}

const findAreaUID = ({ areaUID, user }) => {
  const userAreaList = _.get(user, 'area', [])
  return userAreaList.find((item) => item.uuid === areaUID)
}

export const validatePermission = ({ user, moduleType, permission, data }) => {
  if (_.isNil(user)) {
    return false
  }
  return hasPermission({
    userPermissions: user.permission,
    permissionList: [
      {
        module: moduleType,
        permission: permission,
        owner: 'PIC',
        isValidatePIC: hasPermissionPIC({
          user,
          data: data,
          moduleType: moduleType,
          permission: permission,
        }),
      },
      {
        module: moduleType,
        permission: permission,
        owner: 'ALL',
      },
    ],
  })
}

export const validateLeavePermission = ({ user, data, action }) => {
  if (_.isNil(user)) {
    return false
  }
  const staffs = _.get(data, 'staffs', [])
  const findOtherCreate = user.permission.find(
    (item) =>
      item.module === 'MONTHLY_PLAN' &&
      (item.permission === `${action}_ALL_EXC_HOLIDAY` ||
        item.permission === `${action}_ALL`),
  )
  if (findOtherCreate) return true
  const getPermission = staffs.find((item) => user.uuid === item.uuid)
  return !!getPermission && staffs.length === 1
}

export const validatePlanStatus = ({
  data,
  btnType,
  moduleType,
  permission,
}) => {
  //** note for all btn type **
  // const btnTypeList = [
  //   'APPROVE_LIST',
  //   'REJECTED_MONTHLY_PLAN',
  //   'APPROVED_MONTHLY_PLAN',
  //   'SUBMIT_MONTHLY_PLAN',
  //   'RECALL_MONTHLY_PLAN',
  // ]

  const objStatusList = {
    WAITING: {
      MONTHLY_PLAN_APPROVE_MP: [
        'APPROVE_LIST',
        'APPROVED_MONTHLY_PLAN',
        'REJECTED_MONTHLY_PLAN',
      ],
      MONTHLY_PLAN_CREATE_ALL: [
        'APPROVE_LIST',
        'RECALL_PLAN',
        'RECALL_MONTHLY_PLAN',
      ],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: [
        'APPROVE_LIST',
        'RECALL_PLAN',
        'RECALL_MONTHLY_PLAN',
      ],
    },
    DRAFT: {
      MONTHLY_PLAN_APPROVE_MP: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL: ['APPROVE_LIST', 'SUBMIT_MONTHLY_PLAN'],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: [
        'APPROVE_LIST',
        'SUBMIT_MONTHLY_PLAN',
      ],
    },
    APPROVED: {
      MONTHLY_PLAN_APPROVE_MP: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: ['APPROVE_LIST'],
    },
    REJECTED: {
      MONTHLY_PLAN_APPROVE_MP: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL: ['APPROVE_LIST', 'SUBMIT_MONTHLY_PLAN'],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: [
        'APPROVE_LIST',
        'SUBMIT_MONTHLY_PLAN',
      ],
    },
    RECALL: {
      MONTHLY_PLAN_APPROVE_MP: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL: ['APPROVE_LIST', 'SUBMIT_MONTHLY_PLAN'],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: [
        'APPROVE_LIST',
        'SUBMIT_MONTHLY_PLAN',
      ],
    },
    PATIAL_APPROVED: {
      MONTHLY_PLAN_APPROVE_MP: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL: ['APPROVE_LIST'],
      MONTHLY_PLAN_CREATE_ALL_EXC_HOLIDAY: ['APPROVE_LIST'],
    },
  }
  const status = _.get(data, 'masterPlan.masterPlan.status', 'DRAFT')
  if (status === '') {
    return false
  }

  const getPermission = permission.map((item) => {
    const modulePermission = `${moduleType}_${item}`
    return objStatusList[status][modulePermission]
  })
  const findPermission = getPermission.find((item) => !_.isNil(item))
  if (!findPermission) {
    return false
  }
  const findBtnPermission = findPermission.find((item) => item === btnType)
  return !!findBtnPermission
}

export const getQueryParams = (field) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(field)
}

export const filterAcquiredSkillName = ({ listAcquiredSkill }) => {
  let result = []
  if (listAcquiredSkill.length) {
    listAcquiredSkill.filter((item) => {
      if (item.acquiredSkill) {
        result.push(item.acquiredSkill)
      }
    })
  }
  return result
}

export const convertFormatDateTime = ({ value, type }) => {
  if (_.isNil(value)) return '-'
  dayjs.extend(utc)
  let result = '-'
  if (type === 'date') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_SHOW)
  }

  if (type === 'dateTime') {
    result =
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_SHOW) +
      ' ' +
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'time') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'dayMonth') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_DAY_MONTH)
  }

  if (type === 'dateDb') {
    result = dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_DB)
  }

  return result
}

export const handleDownload = async ({ url, body, fileName }) => {
  await callAPI({
    url: url,
    method: 'POST',
    body: body,
    responseType: 'blob',
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
  })
}

export const findDepartmentAndStation = (listArea, valueStation, isLabel) => {
  let result = '-'
  if (listArea.length) {
    result = listArea.find((item) => {
      return _.get(item, 'station.uuid', '') === valueStation
    })

    if (isLabel) {
      result = `(${_.get(result, 'department.initial', '-')}) 
      ${_.get(result, 'department.department', '')}, 
      ${_.get(result, 'station.station', '')}`
    }
  }

  return result
}

export const isMediaWidthMoreThan = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const isMediaWidth = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint))
}

export const validateEPermission = ({ module, permission, data }) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  const creator = _.get(data, 'createdBy', undefined)
  const assignees = _.get(
    data,
    'assignees',
    _.get(data, 'setting.assignees', []),
  )

  if (_.isNil(user)) {
    return false
  }

  let hasPermission = false
  for (const obj of user.permission) {
    if (hasPermission) break
    if (obj.module === module) {
      if (obj.permission === permission) {
        hasPermission = checkPermissionOwner({
          owner: obj.owner,
          user,
          creator,
          assignees,
        })
      }
    }
  }

  return hasPermission
}

export const checkPermissionOwner = ({ owner, user, creator, assignees }) => {
  if (owner === 'ALL') return true

  let hasPermission = false

  if (creator) {
    hasPermission = creator.uuid === user.uuid
  }

  if (!hasPermission) {
    if (assignees) {
      hasPermission = assignees.some(
        (assignee) => _.get(assignee, 'uuid', assignee.value) === user.uuid,
      )
    }
  }

  return hasPermission
}

export const mapStepSizeChart = (isConfig, highestScore) => {
  switch (highestScore) {
    case highestScore <= 10:
      return isConfig ? 1 : 10
    case highestScore > 10 && highestScore <= 50:
      return isConfig ? 5 : 50
    case highestScore > 50 && highestScore <= 100:
      return isConfig ? 10 : 100
    case highestScore > 100 && highestScore <= 150:
      return isConfig ? 25 : 150
    default:
      return isConfig ? 100 : highestScore
  }
}

export const revertDateToApi = (date) => {
  const listDate = date.split('/')
  return `${listDate[2]}-${listDate[1]}-${listDate[0]}`
}

export const IgnoreAxiosDialog = (config) => {
  const filter = _.filter(
    IgnorePath,
    (cf) => cf.method === config.method && config.url.includes(cf.url),
  )

  return _.isEmpty(filter)
}

export const hasPermissionDefaultAndMonthlyPlanArea = ({
  user,
  manageClass,
  permissionStr,
}) => {
  const isPermissionEditExam = permissionStr === 'EDIT_EXAM'
  const monthlyPlan = _.get(manageClass, 'monthlyPlan', null)
  const uuidStationMonthlyPlan = _.get(monthlyPlan, 'area.station.uuid', '')
  if (uuidStationMonthlyPlan === '' && !isPermissionEditExam) return true

  const permission = permissionStr ? permissionStr : 'EDIT_ALL_CLASS'
  const moduleType = 'E_MANAGECLASS_SETTING'
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionEdit = permissionList.find((item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === permission &&
      item.module === moduleType
    )
  })
  //has edit owner = 'ALL' return true
  if (hasPermissionEdit) return hasPermissionEdit

  const hasPermissionCreatePic = !!permissionList.find((item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === permission &&
      item.module === moduleType
    )
  })
  //hasPermissionStation validate for login user is same area uuid with manage-class but is not change to validate login user are assignee
  const hasPermissionStation = !!hasPermissionManageStation({
    user,
    manageClass,
  })
  const hasPermissionAdmin = hasPermissionManageByAdmin({ user, manageClass })
  const hasPermissionVC = hasPermissionManageByVC({ manageClass })
  if (hasPermissionVC || isPermissionEditExam) {
    return hasPermissionCreatePic && hasPermissionStation && hasPermissionAdmin
  } else {
    return hasPermissionCreatePic && hasPermissionStation
  }
}

export const hasPermissionManageByAdmin = ({ user, manageClass }) => {
  const uuidUser = _.get(user, 'uuid', '')
  const assignees = _.get(manageClass, 'assignnee', [])
  const isAssignee = assignees.some((item) => item.uuid === uuidUser)
  if (isAssignee) return true

  let speakers = []
  _.forEach(
    _.get(manageClass, 'monthlyPlan.dates', []),
    (date) => (speakers = _.concat(speakers, date.speakers)),
  )
  return speakers.some((item) => item?.staff?.uuid === uuidUser)
}

export const hasPermissionManageByVC = ({ manageClass }) => {
  const trainingInfo = _.get(manageClass, 'monthlyPlan.trainingInfo', [])
  const isHighLevelCourse = trainingInfo.find(
    (item) => _.get(item, 'course.courseLevelControl', '') === 'ระดับสูง',
  )
  const findClassVC = _.get(manageClass, 'trainingPlatForm', []).find(
    (item) => item.trainingPlatform === 'Virtual Classroom',
  )
  return findClassVC && isHighLevelCourse
}

export const hasPermissionManageStation = ({ user, manageClass }) => {
  if (user === null) return false

  const monthlyPlan = _.get(manageClass, 'monthlyPlan', null)
  const uuidStationMonthlyPlan = _.get(monthlyPlan, 'area.station.uuid', '')

  const findStation = user.area.find(
    (item) => item.station.uuid === uuidStationMonthlyPlan,
  )
  if (findStation) return true

  return hasPermissionManageByAdmin({ user, manageClass })
}

export const hasPermissionManageByAdminAll = ({ user, manageClass }) => {
  const monthlyPlan = _.get(manageClass, 'monthlyPlan', null)
  const uuidStationMonthlyPlan = _.get(monthlyPlan, 'area.station.uuid', '')
  if (uuidStationMonthlyPlan === '') return true

  const permission = 'ADMIN_ALL'
  const moduleType = 'E_MANAGECLASS_SETTING'
  const permissionList = _.get(user, 'permission', [])
  return permissionList.some((item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === permission &&
      item.module === moduleType
    )
  })
}

export const hasPermissionCreateMasterData = ({ user }) => {
  return _.get(user, 'permission', []).find(
    (item) => item.module === 'MASTER_DATA' && item.permission === 'CREATE',
  )
}

export const checkHttpUrl = (string) => {
  try {
    const givenURL = new URL(string)
    return givenURL.protocol === 'http:' || givenURL.protocol === 'https:'
  } catch (error) {
    return false
  }
}

export const getStaffStationOption = (staffs) => {
  const newOption = staffs.map((item) => {
    const area = _.get(item, 'area', [])
    const value = {
      ...area.find((area) => area.uuid === item.defaultArea),
    }
    const station = _.get(value, 'station.station', '')
    return {
      ...item,
      defaultAreaValue: value,
      station: station,
      label: `${item.firstNameTH} ${item.lastNameTH}`,
    }
  })
  const result = newOption.map((item) => {
    return { ...item, label: `${item.station} ${item.label}` }
  })
  return result.sort((a, b) => -b.label.localeCompare(a.label))
}

export const getStaffAreaStationOption = (staffs) => {
  const newOption = staffs.map((item) => {
    const area = _.get(item, 'uuid', [])
    return {
      ...item,
      defaultAreaValue: area,
    }
  })
  const result = newOption.map((item) => {
    return { ...item, label: `${item.label}` }
  })
  return result.sort((a, b) => -b.label.localeCompare(a.label))
}

export const formatPhoneNo = (data) => {
  if (_.isEmpty(data)) return '-'
  let regExp = /(\d{3})(\d{3})(\d{4})/
  if (data.length === 9) regExp = /(\d{3})(\d{3})(\d{3})/
  if (data.length === 8) regExp = /(\d{3})(\d{3})(\d{2})/
  if (data.length === 7) regExp = /(\d{3})(\d{3})(\d{1})/
  if (data.length === 6) regExp = /(\d{3})(\d{3})/
  return _.defaultTo(data, '').replace(regExp, '$1 $2 $3')
}

export const formatPhoneNumber = (data) => {
  const check = data.split('-')
  if (check.length === 1) {
    const dataNo = data.split('')
    return `${dataNo[0]}${dataNo[1]}${dataNo[2]}-${dataNo[3]}${dataNo[4]}${dataNo[5]}-${dataNo[6]}${dataNo[7]}${dataNo[8]}${dataNo[9]}`
  } else {
    if (_.isEmpty(data)) return '-'
    let regExp = /(\d{3})(\d{3})(\d{4})/
    if (data.length === 9) regExp = /(\d{3})(\d{3})(\d{3})/
    if (data.length === 8) regExp = /(\d{3})(\d{3})(\d{2})/
    if (data.length === 7) regExp = /(\d{3})(\d{3})(\d{1})/
    if (data.length === 6) regExp = /(\d{3})(\d{3})/
    return _.defaultTo(data, '').replace(regExp, '$1 $2 $3')
  }
}

export const formatIdCard = (data) => {
  if (_.isEmpty(data)) return '-'
  return _.defaultTo(data, '').replace(
    /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
    '$1 $2 $3 $4 $5',
  )
}

export const checkIdCard = (id) => {
  if (_.isEmpty(id)) return false
  if (id.substring(0, 1) === 0) return false
  if (id.length !== 13) return false
  let sum = 0
  for (let i = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i)
  return (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12))
}

export const getPlainText = (value) => {
  return new DOMParser().parseFromString(value, 'text/html').documentElement
    .textContent
}

export const handleNumberKeyDown = (event, extraChars = []) => {
  if (
    ['e', 'E', '+', '-'].includes(event.key) ||
    extraChars.includes(event.key)
  ) {
    event.preventDefault()
  }
}

export const handlePasteFormat = (e) => {
  const regex = /^[0-9]+$/
  const key = e.clipboardData.getData('text')
  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}

export const handleNumberInput = (event) => {
  let result = null
  if (!!event.target.value && Math.abs(event.target.value) >= 0) {
    result = Math.abs(event.target.value)
  }
  return result
}

export const handleLength = (str) => {
  if (_.isEmpty(str)) return 0
  return str.length
}

export const getDateThai = (str, format = 'DD MMMM YYYY') => {
  return dayjs(str).locale('th').add(543, 'year').format(format)
}

export const thaiNumber = (str) => {
  const array = {
    1: '๑',
    2: '๒',
    3: '๓',
    4: '๔',
    5: '๕',
    6: '๖',
    7: '๗',
    8: '๘',
    9: '๙',
    0: '๐',
  }
  for (const val in array) {
    str = str.split(val).join(array[val])
  }
  return str
}

export const replaceAll = (str, obj) => {
  const mapObj = new RegExp(Object.keys(obj).join('|'), 'gi')
  return str.replace(mapObj, (matched) => obj[matched])
}

export const replaceNewLine = (text) => {
  if (_.isEmpty(text)) return null
  return text.replace(/\r\n|\r|\n/g, '\\n')
}

export const replaceTextAreaNewLine = (text) => {
  if (_.isEmpty(text)) return ''
  return text.replace(/(\\n)/g, '&#10;')
}

export const LISTIMAGE = {
  image: {
    jpg: 'jpg',
    jpeg: 'jpeg',
    png: 'png',
  },
  excel: {
    xlsx: 'xlsx',
    xls: 'xls',
    csv: 'csv',
  },
}

export const checkUpload = (props) => (dispatch) => {
  const { type, events, id } = props
  const files = _.get(events, 'target.files[0]', '')
  const name = _.get(files, 'name', '')
  const typeFile = name.split('.').pop().toLowerCase()
  const sizeFile = _.get(files, 'size', 0)

  let status = false
  let obj = {}

  if (
    files.length === 0 ||
    _.isEmpty(_.get(LISTIMAGE, `${type}.${typeFile}`, ''))
  ) {
    status = true
    obj = {
      title: 'นามสกุลไฟล์ไม่ถูกต้อง',
      message:
        'ไม่สามารถ Upload ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้องกรุณา Upload ตามที่กำหนดไว้',
    }
  } else if (sizeFile > 2000000) {
    status = true
    obj = {
      title: 'ขนาดไฟล์ใหญ่เกินไป',
      message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
    }
  }
  if (status) {
    dispatch(
      openDialog({
        ...obj,
        type: 'fail',
        isCloseDialog: false,
        handleError: () => {
          _.set(document.getElementById(`${id}`), 'value', null)
          dispatch(closeDialog())
        },
      }),
    )
  }
  return status
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const formatAgentCode = (str) => {
  const stStr = String(str).trim()
  if (stStr.length !== 14) return stStr
  return `${stStr.slice(0, 6)}-${stStr.slice(6, 8)}-${String(str).slice(8)}`
}

export const onSort = (valueId, headCell) => {
  const ASC = 'asc'
  const DESC = 'desc'
  let order = ''
  const headStack = []
  for (const value of headCell) {
    if (value?.id === valueId) {
      if (value?.order === ASC) {
        value['order'] = DESC
        order = DESC
      } else if (value?.order !== DESC) {
        value['order'] = ASC
        order = ASC
      } else {
        delete value['order']
        order = ''
      }
    } else {
      delete value['order']
    }
    headStack.push(value)
  }
  return { head: headStack, order }
}

export const isValidateDate = (date) => {
  if (date === '0') return false
  if (_.isEmpty(date)) return false
  if (typeof date === 'string' && date?.length != 10) return false
  return dayjs(date).isValid()
}

export const handleUrlError = (url) => {
  return ['/meetingroom', '/file/meeting-room'].includes(url)
}

export function getFormatDate(date, format = 'DD/MM/YYYY') {
  dayjs.extend(updateLocale)
  dayjs.extend(buddhistEra)
  if (_.isUndefined(date) || !dayjs(date).isValid()) return ''
  dayjs.updateLocale('en', {
    months: monthNames,
  })
  return dayjs(date).format(format)
}

export const isSameDate = (startDate, endDate) => {
  let formattedStartDate = new Date(startDate).getTime()
  let formattedEndDate = new Date(endDate).getTime()
  return formattedStartDate === formattedEndDate
}
let saveTimer
export const saveDebounce = (callback) => {
  let debounceTime = 500

  if (saveTimer) {
    clearTimeout(saveTimer)
  }

  saveTimer = setTimeout(() => {
    callback()
  }, debounceTime)
}

export const nl2br = (text) => {
  return text.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    )
  })
}

export const convertDurationToTime = (duration) => {
  if (_.isNil(duration)) return '00:00'
  const date = new Date(duration.toFixed() * 1000)

  const hours = date.getUTCHours()
  const minutes = padTo2Digits(date.getUTCMinutes())
  const seconds = padTo2Digits(date.getUTCSeconds())

  if (hours) {
    return `${hours}:${minutes}:${seconds}`
  } else return `${minutes}:${seconds}`
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export const convertFormatTime = (data, isShort) => {
  const hours = _.get(data, 'hours', 0)
  const minutes = _.get(data, 'minutes', 0)
  const hourText = isShort ? 'ชม.' : 'ชั่วโมง'

  if (hours > 0 && minutes > 0) return `${hours} ${hourText} ${minutes} นาที`
  if (hours === 0) return `${minutes} นาที`
  if (minutes === 0) return `${hours} ${hourText}`
}

export const convertDateRange = (dateRange) => {
  const startDay = _.get(dateRange, 'startDateDay', '')
  const startMonth = _.get(dateRange, 'startDateMonth', '')
  const startYear = _.get(dateRange, 'startDateYear', '')
  const finishDay = _.get(dateRange, 'finishDateDay', '')
  const finishMonth = _.get(dateRange, 'finishDateMonth', '')
  const finishYear = _.get(dateRange, 'finishDateYear', '')
  const startDateMonthYear = `${startDay} ${startMonth} ${startYear}`
  if (
    startDay === finishDay &&
    startMonth === finishMonth &&
    startYear === finishYear
  ) {
    return startDateMonthYear
  }
  return `${startDay} ${startMonth} ${startYear} - ${finishDay} ${finishMonth} ${finishYear}`
}

export const convertNumberWithComma = (value) => {
  return parseInt(value).toLocaleString()
}

export const convertFloatWithComma = (value) => {
  if (value === '-') return '-'
  return parseFloat(value).toLocaleString()
}

export const handleOnlyNumberValue = (value) => {
  return value.replace(/[^0-9]/g, '') || 0
}

export const formattedAbbreviateNumber = (number) => {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + 'B'
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + 'M'
  }
}

export const getValue = (value, key, def = null) => {
  const val = _.get(value, key, def)
  if (_.isNull(val)) return def
  if (_.isNaN(val)) return def
  if (_.isUndefined(val)) return def
  return val
}

export const eConfigHistoryEditPreview = (key, value) => {
  let valueObj
  switch (key) {
    case 'CAN_EDIT_PREVIOUS_PLAN':
      return value
    case 'NEW_CLASS_AFTER_APPROVE':
      valueObj = JSON.parse(value)
      return _.get(valueObj, 'value', '')
    case 'NEW_CLASS_AFTER_TRAINING':
      valueObj = JSON.parse(value)
      return _.get(valueObj, 'value', '')
    case 'LEARNER_REQ_CANCEL_REGISTER':
      valueObj = JSON.parse(value)
      return _.get(valueObj, 'value', '')
    case 'EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE':
      return value
    case 'EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE':
      return value
    case 'EDIT_TRAINING_PLAN_DATE_AHEAD':
      return value
    case 'consentBodyTextTH':
      return value
    case 'consentBodyTextEN':
      return value
    case 'EL_UPLOAD_MAX_FILE_SIZE':
      valueObj = JSON.parse(value)
      return `<strong>ขนาดต้องไม่เกิน: </strong> ${_.get(
        valueObj,
        'size',
        '',
      )} ${_.get(valueObj, 'sizeType', '')}`
    case 'EL_BATCH_START_AFTER_CLOSE_REGISTER':
      return value
    case 'EL_FACE_DETECTION_PERCENT':
      return `${value}%`
    case 'EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING':
      return value
    case 'EL_FACE_IMAGE_RETENTION_PERIOD':
      valueObj = JSON.parse(value)
      return `${_.get(valueObj, 'year', 0)}ปี ${_.get(
        valueObj,
        'month',
        0,
      )}เดือน`
    case 'EL_ID_CARD_RETENTION_PERION':
      valueObj = JSON.parse(value)
      return `${_.get(valueObj, 'year', 0)}ปี ${_.get(
        valueObj,
        'month',
        0,
      )}เดือน`
    case 'EL_TRAINING_HISTORY_RETENTION_PERIOD':
      valueObj = JSON.parse(value)
      return `${_.get(valueObj, 'year', 0)}ปี ${_.get(
        valueObj,
        'month',
        0,
      )}เดือน`
    case 'EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION':
      return value
    case 'CC_EMAIL_ANNOUNCEMENT_EXAMINATION':
      return value
    case 'EXAMINATION_APPLICATION_FEE':
      return value
    case 'TERMS_AND_CONDITIONS_TH':
      return value
    case 'TERMS_AND_CONDITIONS_EN':
      return value
    case 'EL_FACE_DETECTION_FAIL':
      return value
    case 'EL_FACE_DETECTION_FAIL_WAITING':
      return value
    case 'EL_COUNTDOWN_FACE_DETECTION':
      return value
    case 'EL_COUNTDOWN_CLICK':
      return value
    case 'EXAMINATION_UPLOAD_OTHER_DOCUMENT':
      valueObj = JSON.parse(value)
      return `<strong>เปิดเอกสารเพิ่มเติม: </strong> ${_.get(
        valueObj,
        'status',
        'INACTIVE',
      )} <br/><strong>ชื่อเอกสาร: </strong> ${_.get(valueObj, 'label', '-')}`
    case 'EL_FACE_DETECTION_PERCENT2':
      return value
    default:
      return '-'
  }
}

export const downloadQRCode = (elementId, fileName = 'QR Code') => {
  const qrCodeBoxElement = document.getElementById(elementId)

  if (_.isEmpty(qrCodeBoxElement) || _.isEmpty(qrCodeBoxElement.style)) return

  qrCodeBoxElement.style.display = 'flex'
  qrCodeBoxElement.style.flexDirection = 'column'
  qrCodeBoxElement.style.alignItems = 'center'

  htmlToImage
    .toPng(qrCodeBoxElement, { width: 500, height: 600 })
    .then(function (dataUrl) {
      download(dataUrl, fileName)
      qrCodeBoxElement.style.display = 'none'
    })
}

export const validateDate = (date) => {
  return date instanceof Date && !isNaN(date)
}
