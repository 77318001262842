import { store } from '../../../App'
import { handleSortColumns } from '../../../redux/slices/reportComp/event'
import { setReduxValue } from '../../../redux/slices/reportComp'

export const handleDeleteFilterColumnMixModule =
  (columnKey, valueLength) => async (dispatch) => {
    const { columnOptions } = store.getState().reportComp
    const newColumnOptions = columnOptions.map((column) => {
      if (
        ['courseNameEN', 'courseNameTH', 'courseNameForLearner'].includes(
          column?.name,
        )
      ) {
        if (columnKey === column?.name) {
          return {
            ...column,
            disabled: false,
            isOptionDisabled: column.isOptionDisabled + +valueLength,
          }
        } else {
          return {
            ...column,
            isOptionDisabled: column.isOptionDisabled + +valueLength,
          }
        }
      } else {
        return column
      }
    })

    const sortedColumns = handleSortColumns(newColumnOptions)
    dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
  }
