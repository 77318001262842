import React from 'react'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { DialogContent } from './Styled'
import {
  setDisableButton,
  setEnableButton,
} from '../../../../../../redux/slices/dialog'
import TextInput from '../../../../../../../src/components/Input/TextInput'
import { handleCloseClass } from './event'
import { useParams } from 'react-router-dom'

export const DialogCloseClass = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  return (
    <>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          เนื่องจากคลาสเรียนยังไม่ถึงวันปิดอบรม หากต้องการปิดคลาส กรุณาให้เหตุผล
          เพื่อปิดคลาสก่อนเวลา
        </Typography>
        <TextInput
          id="reason"
          data-testid="reason"
          name="reason"
          labelText="เหตุผล"
          type="basic"
          multiline={true}
          required={true}
          rows={2}
          onChange={(e) => dispatch(handleToggleDisabled(e, uuid))}
        />
      </DialogContent>
    </>
  )
}

export const handleToggleDisabled = (event, uuid) => (dispatch) => {
  const remarkText = String(event.target.value).trim()
  dispatch(handleCloseClass(uuid, remarkText))
  !remarkText ? dispatch(setDisableButton()) : dispatch(setEnableButton())
}
