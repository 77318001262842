export const getMoreOptions = () => {
  let moreOptions = [
    {
      text: 'ประวัติการเปลี่ยนแปลง',
      method: 'history',
    },
  ]

  return moreOptions
}
