import React from 'react'
import { ConvertProgressCircular } from './ConvertProgressCircular'
import { StyledImg } from '../stlyed'

export const IconUploadSuccessStatus = ({ isForImage, status }) => {
  if (isForImage || status === 'SUCCESS') {
    return <StyledImg src="/icon/circle_success.svg" />
  }
  return <ConvertProgressCircular />
}
