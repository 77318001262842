import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { StyledFooter } from '../../../components/FilterDrawer/Styled'
import {
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledRespRow,
  StyledDashedLine,
} from '../Styled'
import Select from '../../../../src/components/Input/Select'
import DatePicker from '../../../../src/components/Input/DatePicker'
import TextInput from '../../../../src/components/Input/TextInput'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import AlertDialog from '../../../components/AlertDialog'
import { examinerPrefix } from './model'
import { default as BaseAutocomplete } from '@mui/material/Autocomplete'
import _ from 'lodash'
import {
  checkIdCard,
  handleNumberInput,
  handleNumberKeyDown,
} from '../../../utils/lib'
import UploadOtherDocs from './UploadOtherDocs'
import { validateValueLength } from './events'
import UploadFileComp from '../../../components/UploadFile'

const Form = ({
  formik,
  isEditForm,
  dispatch,
  events,
  eExaminerFormBody,
  examinerTypeOption,
  isDisabled,
  qualificationOption,
  provinceOption,
  amphureOption,
  tambonOption,
  dialog,
  setDialog,
  genderOption,
  handleSelectedAddress,
  onCancelClickDialog,
  disableInputList,
  userProfileOption,
  defaultUser,
  handleSelectUser,
  zipCodeOption,
  defaultZipCode,
  loading,
  loadingAddr,
  // otherDocConfig,
  // otherDocs,
}) => {
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
          <Box mb={2}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="body2b" color="text.secondary" required>
                รูปบัตรประชาชน
              </Typography>
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Box>
            <UploadFileComp
              sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
              isMultiple={false}
              isShowDownloadable={false}
              isForImage={true}
              folder="examination-idcard"
              maxFileSize={{ size: 2, sizeType: 'MB' }}
              // maxLength={1}
              accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
                'image/jpg': ['.jpg'],
                'application/pdf': ['.pdf'],
              }}
              acceptLabel=".jpg, .jpeg, .png, .pdf"
              fieldName="examinationIdCard"
              // value={idCardImageKey}
              popupLabel="บัตรประชาชน"
            />
          </Box>

          <Box
            sx={{
              width: '50%',
              mb: 2,
            }}
          >
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <Select
                    required
                    name="examinerType"
                    labelText="ประเภทของผู้สอบ"
                    options={examinerTypeOption}
                    placeholder="กรุณาเลือก"
                    value={eExaminerFormBody.examinerType}
                    handleChange={(e) => {
                      dispatch(
                        events.handleChangeKeyValue(
                          formik,
                          'examinerType',
                          e.target.value,
                        ),
                      )
                    }}
                    textError={formik?.errors?.examinerType}
                    onBlur={formik.handleBlur}
                    disabled={isEditForm}
                  />
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
            <StyledColumnFlex>
              <StyledColumnNoGap noGap>
                <StyledRespRow isInput>
                  <AutocompleteCustom
                    // boxSx={{ width: '50%' }}
                    name="code"
                    id="code"
                    value={defaultUser}
                    onChange={(event, newValue) => {
                      handleSelectUser(formik, newValue)
                    }}
                    options={
                      loading
                        ? [
                            {
                              label: eExaminerFormBody.code,
                              value: eExaminerFormBody.code,
                            },
                          ]
                        : userProfileOption
                    }
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        placeholder="กรอกรหัส"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': 'select-code',
                          onInput: (e) =>
                            handleInputFormat(
                              e,
                              eExaminerFormBody.examinerType.toUpperCase(),
                            ),
                          onPaste: (e) =>
                            handlePasteFormat(
                              e,
                              eExaminerFormBody.examinerType.toUpperCase(),
                            ),
                        }}
                        onChange={(e) => {
                          dispatch(
                            handleInputChange(
                              e,
                              eExaminerFormBody.examinerType,
                              events,
                              formik,
                            ),
                          )
                        }}
                      />
                    )}
                    isLoading={loading}
                    required
                    noOptionsText={`กรุณาพิมพ์รหัส 6 หลัก`}
                    labelText={'รหัส 6 หลัก'}
                    showTextError={false}
                    disabled={!(isDisabled > 0) || isEditForm}
                  />
                </StyledRespRow>
              </StyledColumnNoGap>
            </StyledColumnFlex>
          </Box>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="idCardNo"
                  type="basic"
                  labelText="เลขบัตรประชาชน"
                  id="input-idCardNo"
                  placeholder="กรุณากรอก"
                  value={eExaminerFormBody.idCardNo}
                  inputProps={{
                    'data-testid': 'input-idCardNo',
                    type: 'number',
                    onInput: handleNumberInput,
                    onKeyDown: (e) => {
                      handleNumberKeyDown(e, ['.'])
                    },
                    onPaste: (e) => {
                      handlePasteFormat(e, 'IDCARD')
                    },
                  }}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'idCardNo',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.idCardNo
                      ? formik?.errors?.idCardNo
                      : String(eExaminerFormBody.idCardNo).length > 0 &&
                        !checkIdCard(eExaminerFormBody.idCardNo)
                      ? 'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง'
                      : validateValueLength(eExaminerFormBody.idCardNo, 13)
                  }
                  disabled={disableInputList.idCardNo}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                />
                <Select
                  required
                  name="prefix"
                  labelText="คำนำหน้า"
                  id="input-prefix"
                  options={examinerPrefix}
                  placeholder="กรุณาเลือก"
                  value={eExaminerFormBody.prefix}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'prefix',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.prefix}
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.prefix}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="firstName"
                  type="basic"
                  labelText="ชื่อ"
                  id="input-firstName"
                  placeholder="กรุณากรอก"
                  value={eExaminerFormBody.firstName}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'firstName',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.firstName
                      ? formik?.errors?.firstName
                      : validateValueLength(eExaminerFormBody.firstName, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.firstName}
                />
                <TextInput
                  required
                  name="lastName"
                  type="basic"
                  labelText="นามสกุล"
                  id="input-lastName"
                  placeholder="กรุณากรอก"
                  value={eExaminerFormBody.lastName}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'lastName',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.lastName
                      ? formik?.errors?.lastName
                      : validateValueLength(eExaminerFormBody.lastName, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.lastName}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <Select
                  required
                  name="gender"
                  labelText="เพศ"
                  options={genderOption}
                  placeholder="กรุณาเลือก"
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  value={eExaminerFormBody.gender}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'gender',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.gender}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
                <DatePicker
                  required
                  id="dateOfBirth"
                  name="dateOfBirth"
                  labelText="วันเดือนปีเกิด"
                  placeholder="เลือกวันที่"
                  hideTextError={false}
                  disabledStartDate={'1900-01-01'}
                  disabledEndDate={dayjs(new Date()).format(
                    window.__env__.REACT_APP_DATE_DB,
                  )}
                  hideDisableDateRange={true}
                  value={eExaminerFormBody.dateOfBirth}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(formik, 'dateOfBirth', e),
                    )
                  }}
                  textError={formik?.errors?.dateOfBirth}
                  onBlur={formik.handleBlur}
                  disabledInput={disableInputList.dateOfBirth}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="mobilePhone"
                  type="basic"
                  labelText="เบอร์โทรศัพท์"
                  id="input-mobilePhone"
                  placeholder="กรุณากรอก"
                  value={eExaminerFormBody.mobilePhone}
                  inputProps={{
                    onInput: (e) => {
                      handleInputFormat(e, 'MOBILE')
                    },
                    onPaste: (e) => handlePasteFormat(e, 'MOBILE'),
                    onKeyDown: (e) => {
                      handleNumberKeyDown(e, ['.'])
                    },
                  }}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/-/g, '')
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'mobilePhone',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.mobilePhone
                      ? formik?.errors?.mobilePhone
                      : String(eExaminerFormBody.mobilePhone).length > 0 &&
                        String(eExaminerFormBody.mobilePhone).length != 10
                      ? 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง'
                      : validateValueLength(eExaminerFormBody.mobilePhone, 10)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.mobilePhone}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="email"
                  type="basic"
                  labelText="อีเมล"
                  id="input-email"
                  placeholder="กรุณากรอก"
                  value={eExaminerFormBody.email}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'email',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.email}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body2">ที่อยู่ปัจจุบัน</Typography>
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          </Box>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <AutocompleteCustom
                  // boxSx={{ width: '50%' }}
                  name="zipCode"
                  id="zipCode"
                  value={defaultZipCode}
                  inputValue={eExaminerFormBody.zipCode}
                  onChange={(event, newValue) => {
                    if (newValue?.item) {
                      handleSelectedAddress(event, newValue.item)
                      dispatch(
                        events.handleChangeKeyValue(
                          formik,
                          'zipCode',
                          newValue?.item?.zipCode,
                        ),
                      )
                    }
                  }}
                  options={
                    loadingAddr
                      ? [
                          {
                            label: eExaminerFormBody.zipCode,
                            value: eExaminerFormBody.zipCode,
                          },
                        ]
                      : zipCodeOption
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรอกรหัส"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-zipCode',
                        onInput: (e) => {
                          formik.setErrors({ ...formik.errors, zipCode: '' })
                          handleInputFormat(e, 'ZIPCODE')
                        },
                        onPaste: (e) => handlePasteFormat(e, 'ZIPCODE'),
                      }}
                      onChange={(e) => {
                        dispatch(
                          handleInputChange(e, 'ZIPCODE', events, formik),
                        )
                      }}
                      // onBlur={() => dispatch(handleInputBlur(userProfile))}
                    />
                  )}
                  isLoading={loadingAddr}
                  required
                  noOptionsText={`กรุณาพิมพ์รหัสไปรษณีย์`}
                  labelText={'รหัสไปรษณีย์'}
                  showTextError={true}
                  textError={
                    formik?.errors?.zipCode
                      ? formik?.errors?.zipCode
                      : validateValueLength(eExaminerFormBody.zipCode, 5)
                  }
                  disabled={!(isDisabled > 1)}
                />

                <Select
                  required
                  name="province"
                  labelText="จังหวัด"
                  placeholder="กรุณาเลือก"
                  options={provinceOption}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  value={eExaminerFormBody.province}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'province',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.province}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
                <Select
                  required
                  name="amphure"
                  labelText="อำเภอ/เขต"
                  placeholder="กรุณาเลือก"
                  options={amphureOption}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  value={eExaminerFormBody.amphure}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'amphure',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.amphure}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
                <Select
                  required
                  name="tambon"
                  labelText="ตำบล/แขวง"
                  placeholder="กรุณาเลือก"
                  options={tambonOption}
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  value={eExaminerFormBody.tambon}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'tambon',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.tambon}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="address"
                  type="basic"
                  labelText="รายละเอียดที่อยู่"
                  placeholder="กรุณากรอก"
                  id="input-address"
                  value={eExaminerFormBody.address}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'address',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.address
                      ? formik?.errors?.address
                      : validateValueLength(eExaminerFormBody.address, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={!(isDisabled > 1)}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
          </StyledColumnFlex>
          <StyledColumnFlex>
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="unitName"
                  type="basic"
                  labelText="ชื่อหน่วย"
                  placeholder="กรุณากรอก"
                  id="input-unitName"
                  value={eExaminerFormBody.unitName}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'unitName',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.unitName}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
                <TextInput
                  required
                  name="unitNumber"
                  type="basic"
                  labelText="รหัสหน่วยงาน"
                  placeholder="กรุณากรอก"
                  id="input-unitNumber"
                  value={eExaminerFormBody.unitNumber}
                  onChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'unitNumber',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.unitNumber}
                  onBlur={formik.handleBlur}
                  disabled={true}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
            <StyledDashedLine />
            <StyledColumnNoGap noGap>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="institution"
                  type="basic"
                  labelText="สถานศึกษา (มหาวิทยาลัย)"
                  placeholder="กรุณากรอก"
                  id="input-institution"
                  value={eExaminerFormBody.institution}
                  onChange={(e) => {
                    formik.setErrors({ ...formik.errors, institution: '' })
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'institution',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.institution
                      ? formik?.errors?.institution
                      : validateValueLength(eExaminerFormBody.institution, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.institution}
                />
              </StyledRespRow>
              <StyledRespRow isInput>
                <TextInput
                  name="between"
                  type="basic"
                  labelText="ระหว่างปี"
                  placeholder="กรุณากรอก"
                  id="input-between"
                  value={eExaminerFormBody.between}
                  onChange={(e) => {
                    formik.setErrors({ ...formik.errors, between: '' })
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'between',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.between
                      ? formik?.errors?.between
                      : validateValueLength(eExaminerFormBody.between, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.between}
                />
                <Select
                  required
                  name="qualification"
                  labelText="วุฒิการศึกษา"
                  options={qualificationOption}
                  placeholder="กรุณาเลือก"
                  renderInput={(params) => (
                    <TextField
                      placeholder="กรุณาเลือก"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'select-main-admin',
                      }}
                    />
                  )}
                  value={eExaminerFormBody.qualification}
                  handleChange={(e) => {
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'qualification',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={formik?.errors?.qualification}
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.qualification}
                />
              </StyledRespRow>
              <StyledRespRow isInput>
                <TextInput
                  required
                  name="branch"
                  type="basic"
                  labelText="สาขา"
                  placeholder="กรุณากรอก"
                  id="input-branch"
                  value={eExaminerFormBody.branch}
                  onChange={(e) => {
                    formik.setErrors({ ...formik.errors, branch: '' })
                    formik.values.locationCode = e.target.value
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'branch',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.branch
                      ? formik?.errors?.branch
                      : validateValueLength(eExaminerFormBody.branch, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.branch}
                />
                <TextInput
                  name="gpa"
                  type="basic"
                  labelText="เกรดเฉลี่ย"
                  placeholder="กรุณากรอก"
                  id="input-gpa"
                  value={eExaminerFormBody.gpa}
                  onChange={(e) => {
                    formik.setErrors({ ...formik.errors, gpa: '' })
                    dispatch(
                      events.handleChangeKeyValue(
                        formik,
                        'gpa',
                        e.target.value,
                      ),
                    )
                  }}
                  textError={
                    formik?.errors?.gpa
                      ? formik?.errors?.gpa
                      : validateValueLength(eExaminerFormBody.gpa, 255)
                  }
                  onBlur={formik.handleBlur}
                  disabled={disableInputList.gpa}
                />
              </StyledRespRow>
            </StyledColumnNoGap>
            <UploadOtherDocs isEditForm={isEditForm} />
            {/* {((otherDocConfig &&
              otherDocConfig?.config?.status ===
                EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS.ACTIVE) ||
              (isEditForm && otherDocs.length > 0)) && (
              <>
                <StyledDashedLine />
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="body2">เอกสารเพิ่มเติม</Typography>
                  <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
                    *
                  </Typography>
                </Box>
                <StyledColumnNoGap noGap>
                  <StyledRespRow isInput>
                    <UploadOtherDocs />
                  </StyledRespRow>
                </StyledColumnNoGap>
              </>
            )} */}
          </StyledColumnFlex>
          {isEditForm && (
            <Typography mt={2}>
              *การแก้ไขข้อมูลผู้สอบ จะมีผลเฉพาะรอบสอบนี้เท่านั้น
              ขอให้แจ้งแก้ไขในฐานข้อมูลเพิ่มเติมที่ผู้ดูแลระบบ
            </Typography>
          )}
        </Box>
        <StyledFooter sx={{ justifyContent: 'end' }}>
          <Button
            data-testid="btn-submit"
            name="submit"
            type="submit"
            variant="contained"
            disabled={!(isDisabled > 2)}
          >
            บันทึก
          </Button>
        </StyledFooter>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={() => onCancelClickDialog(setDialog)}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </>
  )
}

export const regex = {
  PROSPECT: /^[\u0E00-\u0E7Fa-zA-Z0-9]+$/,
  STAFF: /^[\u0E00-\u0E7Fa-zA-Z0-9]+$/,
  A: /^[0-9]+$/,
  L: /^[0-9]+$/,
  ZIPCODE: /^[0-9]+$/,
  IDCARD: /^[0-9]+$/,
  MOBILE: /^[0-9]+$/,
}

export const handleInputFormat = (e, userType) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value

  if (!regex[userType].test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex[userType].test(e.target.value)) e.target.value = ''
  return e.target.value
}

export const handlePasteFormat = (e, userType) => {
  let key = e.clipboardData.getData('text')
  if (userType === 'MOBILE') {
    key = key.replace(/-/g, '')
  }
  if (!regex[userType].test(key)) {
    e.preventDefault()
    return false
  }
}

export const handleInputChange =
  (e, userType, events, formik) => (dispatch) => {
    if (['A', 'L'].includes(userType)) {
      const userCode = _.get(e.target, 'value', '').trim()
      dispatch(
        events.handleChangeKeyValue(formik, 'code', {
          label: userCode,
          value: userCode,
        }),
      )
    } else if (['ZIPCODE'].includes(userType)) {
      const zipCode = _.get(e.target, 'value', '').trim()
      dispatch(events.handleChangeKeyValue(formik, 'zipCode', zipCode))
    }
  }

export const AutocompleteCustom = (props) => {
  const {
    boxSx,
    labelText,
    id,
    defaultProps,
    inputValue,
    value,
    disabled,
    noOptionsText,
    renderInput,
    onChange,
    required,
    options,
    getOptionLabel,
    textError,
    showTextError = true,
    errorSx = { mb: 3 },
    isLoading,
  } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <BaseAutocomplete
        {...defaultProps}
        options={options}
        getOptionLabel={getOptionLabel}
        id={id}
        inputValue={inputValue}
        value={value}
        onChange={(event, newValue) => {
          onChange(event, newValue)
        }}
        renderInput={renderInput}
        renderOption={(propsOption, option) => {
          if (isLoading) return <LoadingOption />
          return (
            <Box {...propsOption} id={option.value}>
              <Typography variant="body1" id={option.value}>
                {option.label}
              </Typography>
            </Box>
          )
        }}
        required={required ? true : false}
        disabled={disabled}
        noOptionsText={noOptionsText}
      />
      {showTextError && (
        <Box sx={errorSx}>
          {textError && textError != '' && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Form

const LoadingOption = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 50,
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
