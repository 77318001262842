import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Footer from '../../../../components/Footer'
import HomeHeader from './HomeHeader'
import Content from './Content'
import { StyledHome, LoadingPage } from './Styled'

const Preview = () => {
  const { isLoading, data } = useSelector(
    (state) => ({
      isLoading: state.manageContentELearningHome.isLoading,
      data: state.manageContentELearningHome.data,
    }),
    shallowEqual,
  )

  return (
    <>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
          backgroundColor: '#F8FAFC',
          width: '100%',
          maxWidth: 1520,
          margin: 'auto',
          minWidth: '384px',
        }}
      >
        <StyledHome>
          <HomeHeader bannerSection={_.get(data, 'bannerSection', [])} />
          <Content />
        </StyledHome>
        <Footer />

        <LoadingPage isLoading={isLoading} />
      </Box>
    </>
  )
}

export default Preview
