import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/system/styled'

export const BoxAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'start',
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}))

export const ContentInput = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'start',
  gap: '12px'
})

export const ContentScoreAndButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 1.5,
  width: 'auto',
  marginTop: theme.spacing(3),
  '& > .MuiBox-root:last-child': { marginLeft: 0 },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    paddingLeft: 0,
    justifyContent: 'space-between',
  },
}))

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(2),
  gap: theme.spacing(3),
  justifyContent: 'flex-end',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    width: 'auto',
  },
}))


export const RadioAnswer = styled(FormControlLabel)(({ theme }) => ({
  margin: `${theme.spacing(1.5)} 0 0 ${theme.spacing(1.5)}`,
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1),
  },
}))
