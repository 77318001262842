import {
  startLoading,
  stopLoading,
} from '../../../../redux/slices/eLearning/learningPathDetail'
import { getDetail } from '../../../../services/eLearning/learningPath/detail'

export const fetchELearning = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getDetail(uuid))
  dispatch(stopLoading())
}
