import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxChoices = styled(Box)(({ theme, isOther }) => ({
  display: 'flex',
  width: '100%',
  paddingRight: isOther ? theme.spacing(1) : 0,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingRight: !isOther ? theme.spacing(1.5) : 0,
  },
}))

export const ContentInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingBottom: theme.spacing(2),
}))

export const BoxIcon = styled(Box)(({ theme, isLength, isOther }) => ({
  display: 'flex',
  position: 'relative',
  top: theme.spacing(0),
  height: theme.spacing(5),
  marginLeft: isOther ? '12px' : theme.spacing(2),
  gap: theme.spacing(2),
  justifyContent: isLength || isOther ? 'flex-end' : 'flex-start',
  width: '116px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: isOther ? theme.spacing(4) : theme.spacing(1),
    gap: theme.spacing(0.1),
    width: '70px',
  },
}))
