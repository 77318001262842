import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxAddQuestion = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}))
export const CardAddQuestion = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
}))

export const BoxIcon = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}))
