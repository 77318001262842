import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import Close from '@mui/icons-material/Close'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'

import {
  CancelUpload,
  ImageQuestion,
  BoxImageQuestionAnswer,
  BoxLabel,
} from './Styled'
import { handelDelete } from './event'

const Index = (props) => {
  const { isQuestion, isDefault, uploadState, keyState, value, disabled } =
    props
  const { loadingProgress, uploadError: isError } = uploadState
  const dispatch = useDispatch()

  if (loadingProgress >= 0 && loadingProgress < 100) {
    return (
      <BoxImageQuestionAnswer
        isQuestion={isQuestion}
        isDefault={isDefault}
        isLoading
      >
        <Box>
          <UploadFileOutlined />
          <LinearProgress variant="determinate" value={loadingProgress} />
        </Box>
      </BoxImageQuestionAnswer>
    )
  } else {
    return (
      <>
        <BoxImageQuestionAnswer
          isQuestion={isQuestion}
          isDefault={isDefault}
          isError={isError}
        >
          {!disabled && (
            <CancelUpload
              data-testid="cancel-upload"
              onClick={() => dispatch(handelDelete({ ...keyState, value: '' }))}
            >
              <Close sx={{ fontSize: '16px' }} />
            </CancelUpload>
          )}
          {value && (
            <ImageQuestion
              src={`${window.__env__.REACT_APP_API_URL}/file${value}`}
            />
          )}
          {isError && (
            <Box>
              <UploadFileOutlined />
              <Typography variant="buttons">ผิดพลาด</Typography>
            </Box>
          )}
        </BoxImageQuestionAnswer>
        {isError && (
          <BoxLabel isQuestion={isQuestion} color="error" variant="body2">
            อัพโหลดรูปภาพไม่สำเร็จ ลองใหม่อีกครั้ง
          </BoxLabel>
        )}
      </>
    )
  }
}
export default Index
