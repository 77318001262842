import React from 'react'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({ height: '100vh', '& .MuiDrawer-paper': { width: 858 } }))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}
