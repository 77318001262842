import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React from 'react'
import { LayoutBox } from '../event'
import { BoxImage, BoxTextImage, ImageBox } from './Styled'

const CoverImage = ({
  layouts,
  loadingProgress,
  isError,
  url,
  width,
  isSelectLayout,
  IsSelectBackground,
  formLayout,
}) => {
  if (loadingProgress > 0 && loadingProgress < 100)
    return (
      <BoxImage isLoading>
        <Box>
          <UploadFileOutlined />
          <LinearProgress variant="determinate" value={loadingProgress} />
        </Box>
      </BoxImage>
    )
  else
    return (
      <>
        <BoxImage isError={isError}>
          {(url || formLayout) && (
            <ImageBox
              IsSelectBackground={IsSelectBackground}
              url={
                url
                  ? `${window.__env__.REACT_APP_API_URL}/file${url ?? ''}`
                  : ''
              }
              width={width}
            >
              <BoxTextImage width={width}>
                <LayoutBox
                  layoutNumber={isSelectLayout}
                  layouts={layouts}
                  formLayout={formLayout}
                />
              </BoxTextImage>
            </ImageBox>
          )}
          {isError && (
            <Box>
              <UploadFileOutlined />
              <Typography variant="buttons" color="error">
                ผิดพลาด
              </Typography>
            </Box>
          )}
        </BoxImage>
        {isError && (
          <Typography color="error" variant="body2">
            อัพโหลดรูปภาพไม่สำเร็จ ลองใหม่อีกครั้ง
          </Typography>
        )}
      </>
    )
}

export default CoverImage
