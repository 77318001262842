import React from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { BoxSwitch, handleChange } from '../../event'
import { handleQuestionResult, handleSwitchIsOpen } from './event'

const Index = () => {
  const dispatch = useDispatch()
  const { questionResult, disableSetting } = useSelector(
    (state) => ({
      questionResult: state.eTestingForm.setting.questionResult,
      disableSetting:
        state.eTestingForm.disableSetting || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )

  const { isOpen, result, resultType } = questionResult

  return (
    <>
      <BoxSwitch
        isBottom={true}
        labelText="การเฉลยคำตอบ"
        labelSwitch="เฉลยคำตอบ"
        value={isOpen}
        disabled={disableSetting}
        handleChange={(e) =>
          dispatch(
            handleSwitchIsOpen({
              value: _.get(e, 'target.checked', false),
              key: 'questionResult.isOpen',
              result,
            })
          )
        }
      />

      {isOpen && (
        <Box sx={{ ml: 9 }}>
          <Box>
            <Typography variant="body1">ช่วงเวลาในการเฉลย</Typography>
            <RadioGroup
              data-testid="radio-result"
              sx={{ pl: 3, gap: 0.5 }}
              aria-label="approval"
              name="result"
              id="result"
              defaultValue={result}
              onChange={(e) => {
                dispatch(
                  handleQuestionResult({
                    value: _.get(e, 'target.value', false),
                    key: 'questionResult.result',
                  })
                )
              }}
            >
              <FormControlLabel
                disabled={disableSetting}
                value={'LATER'}
                control={<Radio />}
                label="หลังทำข้อสอบเสร็จ"
              />
              <FormControlLabel
                disabled={disableSetting}
                value={'NOW'}
                control={<Radio />}
                label="ระหว่างการทำข้อสอบ"
              />
            </RadioGroup>
          </Box>
          <Box>
            <Typography variant="body1">รูปแบบการเฉลย</Typography>
            <RadioGroup
              sx={{ pl: 3, gap: 0.5 }}
              data-testid="radio-resultType"
              aria-label="approval"
              name="resultType"
              id="resultType"
              defaultValue={resultType}
              onChange={(e) => {
                dispatch(
                  handleChange({
                    value: _.get(e, 'target.value', false),
                    key: 'questionResult.resultType',
                  })
                )
              }}
            >
              <FormControlLabel
                value={'SHOW_FALSE_ONLY'}
                disabled={disableSetting}
                control={<Radio />}
                label="เฉลยเฉพาะข้อที่ผู้เรียนเลือกตอบว่าถูกหรือผิด"
              />
              <FormControlLabel
                value={'SHOW_TRUE_FALSE'}
                disabled={disableSetting}
                control={<Radio />}
                label="เฉลยทั้งข้อถูกและข้อผิด"
              />
            </RadioGroup>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Index
