import { memo } from 'react'
import Select from '../../Input/Select'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

const CustomSelect = memo(
  ({
    required = false,
    disabled,
    fieldName,
    labelName,
    handleExtraChange = () => {},
    boxSx = {},
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)

    const { crudConfig } = useSelector(
      (state) => ({ crudConfig: state.crud.crudConfig }),
      shallowEqual,
    )

    const { masterCategory: master } = useSelector(
      (state) => ({
        masterCategory: state.crud.masterData[fieldName],
      }),
      shallowEqual,
    )

    function handleValueChange(event) {
      const value = _.get(event, 'target.value', '')
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: value }))
      if (handleExtraChange) {
        handleExtraChange(event, value)
      }
    }

    if (_.isUndefined(master)) {
      return <div />
    }

    return (
      <Select
        disabled={disabled}
        required={required}
        labelText={labelName}
        name={fieldName}
        defaultValue={!_.isEmpty(value) ? value : null}
        placeholder={`${required ? 'กรุณาเลือก ' : ''}${labelName}`}
        id={`${crudConfig.moduleSlug}-${fieldName}`}
        options={master.options}
        boxSx={{ ...boxSx }}
        textError={message}
        showTextError={isError}
        handleChange={handleValueChange}
      />
    )
  },
)

CustomSelect.displayName = 'CustomSelectComponent'

export { CustomSelect }
