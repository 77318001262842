import React from 'react'
import { Box } from '@mui/material'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import { drawerFormValidationSchemaSKU } from '../../schema/schema'
import {
  validatePermission,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { getSkuNo } from '../../utils'

const Form = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  return (
    <>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDropdown
          required
          labelName="ชื่อทรัพย์สินที่เพิ่ม รหัสทรัพย์สิน"
          fieldName="productStockAsset"
          placeholder="เลือกทรัพย์สิน"
          handleExtraChange={getSkuNo}
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="skuNo"
          labelName="รหัสทรัพย์สิน"
          placeholder="ระบุ รหัสทรัพย์สิน"
        />
      </Box>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDropdown
          required
          labelName="Cost center"
          fieldName="costCenter"
          placeholder="เลือก Cost center"
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="unitPrice"
          labelName="มูลค่าต่อหน่วย (฿)"
          placeholder="ระบุมูลค่าต่อหน่วย (฿)"
          inputProps={{
            'data-testid': 'input-unitPrice',
            onInput: (e) => {
              handleInputNumber(e, regex.FLOAT)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e)
            },
            onPaste: handlePasteFormat,
          }}
        />
      </Box>
      <Box display="flex" gap={3} justifyContent="end">
        <CancelButton onClick={() => openStockDrawer(false)} />
        <SubmitButton
          disabled={!hasPermissionCreate}
          handleSubmitClick={() =>
            handleSubmitDrawer(drawerFormValidationSchemaSKU)
          }
        />
      </Box>
    </>
  )
}

export default Form
