import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { handlePeriod } from '../../../../modules/RoomManagement/BookingDrawer/FormBooking/DetailsBookingCard/event'
import { getBookingListByPeriod } from '../../../../modules/RoomManagement/BookingDrawer/event'
import {
  BOOKING_PERIOD_TYPE,
  OVERTIME,
} from '../../../../constants/roomManagement'
import _ from 'lodash'

const roomManagementDrawer = createSlice({
  name: 'roomManagementDrawer',
  initialState,
  reducers: {
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setStationName: (state, { payload }) => {
      state.stationName = payload
    },
    setBookingInformation: (state, { payload }) => {
      state.bookingInformation = { ...state.bookingInformation, ...payload }
    },
    setChangeBookingInformation: (state, { payload }) => {
      const { key, value } = payload
      state.bookingInformation[key] = value
    },
    setBookingUuid: (state, { payload }) => {
      state.bookingUuid = payload
    },
    setRoomSelected: (state, { payload }) => {
      state.roomSelected = payload
    },
    setStaffList: (state, { payload }) => {
      state.staffList = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload.key] = payload.value
    },
    setReduxObjectValue: (state, { payload }) => {
      state[payload['objectKey']][payload.key] = payload.value
    },
    setRoomBookingList: (state, { payload }) => {
      state.bookingInformation.roomBookingList = payload
    },
    setRoomBookingListByDate: (state, { payload }) => {
      const { bookingDate, key, value } = payload
      const idxChange = state.bookingInformation.roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )
      const { bookingPeriod, roomBookingList } = state.bookingInformation

      const bookingList = getBookingListByPeriod({
        bookingPeriod,
        roomBookingList,
        idxChange,
      })
      for (const idx of bookingList) {
        state.bookingInformation.roomBookingList[idx][key] = value
      }
    },
    setTimeByDate: (state, { payload }) => {
      const { bookingDate, value, key } = payload

      const { bookingPeriod, roomBookingList } = state.bookingInformation

      const idxChange = roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )

      const bookingList = getBookingListByPeriod({
        bookingPeriod,
        roomBookingList,
        idxChange,
      })

      for (const idx of bookingList) {
        state.bookingInformation.roomBookingList[idx][key] = value
      }
    },

    setOverTimeOption: (state, { payload }) => {
      const { bookingDate, overTimeAfterOptions, overTimeBeforeOptions } =
        payload
      const { bookingPeriod, roomBookingList } = state.bookingInformation

      const idxChange = roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )
      const bookingList = getBookingListByPeriod({
        bookingPeriod,
        roomBookingList,
        idxChange,
      })

      for (const idx of bookingList) {
        roomBookingList[idx].overTimeBeforeOptions = overTimeBeforeOptions
        roomBookingList[idx].overTimeAfterOptions = overTimeAfterOptions
        roomBookingList[idx].beforeTraining = overTimeBeforeOptions.find(
          (item) => item.value === roomBookingList[idx].beforeTraining,
        )
          ? roomBookingList[idx].beforeTraining
          : OVERTIME.AT_TRAINING_TIME
        roomBookingList[idx].afterTraining = overTimeAfterOptions.find(
          (item) => item.value === roomBookingList[idx].afterTraining,
        )
          ? roomBookingList[idx].afterTraining
          : OVERTIME.AT_TRAINING_TIME
      }
    },
    setPeriodByDate: (state, { payload }) => {
      const { bookingDate, value } = payload
      const { bookingPeriod, roomBookingList } = state.bookingInformation

      const idxChange = roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )

      const bookingList = getBookingListByPeriod({
        bookingPeriod,
        roomBookingList,
        idxChange,
      })

      for (const idx of bookingList) {
        roomBookingList[idx].period = value
        const { startTimeTraining, endTimeTraining } = handlePeriod(
          {
            beforeTraining: roomBookingList[idx].beforeTraining,
            afterTraining: roomBookingList[idx].afterTraining,
          },
          value,
          bookingDate,
        )

        roomBookingList[idx].period = value
        roomBookingList[idx].startTimeTraining = startTimeTraining
        roomBookingList[idx].endTimeTraining = endTimeTraining
        roomBookingList[idx].startTimeRoom = startTimeTraining
        roomBookingList[idx].endTimeRoom = endTimeTraining
      }
    },
    addRoom: (state, { payload }) => {
      const { roomBookingList, bookingPeriod } = state.bookingInformation
      const { bookingDate } = payload

      const idxChange = roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )

      if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
        for (let [roomBookingIdx] of roomBookingList.entries()) {
          roomBookingList[roomBookingIdx].rooms.push({
            meetingRoomUuid: '',
            meetingRoomOther: null,
            numberOfAttendees: null,
            roomLayout: '',
            additionalEquipment: '',
            numberOfGroup: null,
            attendeeInGroup: null,
          })
        }
      }

      if (bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM) {
        roomBookingList[idxChange].rooms.push({
          meetingRoomUuid: '',
          meetingRoomOther: null,
          numberOfAttendees: null,
          roomLayout: '',
          additionalEquipment: '',
          numberOfGroup: null,
          attendeeInGroup: null,
        })
      }
    },
    setRoom: (state, { payload }) => {
      const { roomBookingList, bookingPeriod } = state.bookingInformation
      const { bookingDate, roomIndex, key, value } = payload

      const idxChange = roomBookingList.findIndex(
        (item) => item.bookingDate === bookingDate,
      )

      if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
        for (let [roomBookingIdx] of roomBookingList.entries()) {
          roomBookingList[roomBookingIdx].rooms[roomIndex][key] = value
        }
      }
      if (bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM) {
        roomBookingList[idxChange].rooms[roomIndex][key] = value
      }
    },
    setValidateError: (state, { payload }) => {
      const { key, message } = payload
      const idxChange = state.errors.findIndex((item) => item.key === key)
      if (idxChange > -1) {
        state.errors[idxChange]?.message.concat(message)
      } else {
        state.errors.push({ key, message: [message] })
      }
    },
    removeValidateError: (state, { payload }) => {
      const { key, message } = payload
      const idxChange = state.errors.findIndex((item) => item.key === key)

      if (idxChange > -1) {
        state.errors[idxChange] = {
          ...state.errors[idxChange],
          message: state.errors[idxChange].message.filter(
            (item) => item !== message,
          ),
        }
        if (state.errors[idxChange].message.length === 0) {
          state.errors = state.errors.filter((item) => item.key !== key)
        }
      }
    },
    openViewDrawerBooking: (state, { payload: { bookingUuid } }) => {
      state.bookingInformation.bookingUuid = bookingUuid
      state.isOpenDrawer = true
      state.isShowPreview = true
      state.isLoadingDrawer = true
    },
    openCreateFormDrawerBooking: (state, { payload }) => {
      state.bookingInformation.bookingUuid = null
      state.bookingInformation.stationName = payload.stationName
      state.bookingInformation.stationUuid = payload.stationUuid
      state.isOpenDrawer = true
      state.isShowForm = true
      state.isLoadingDrawer = true
    },
    openEditFormDrawerBooking: (state, { payload: { bookingUuid } }) => {
      state.bookingInformation.bookingUuid = bookingUuid
      state.isOpenDrawer = true
      state.isShowForm = true
      state.isShowPreview = false
      state.isLoadingDrawer = true
    },
    nextForm: (state, { payload }) => {
      if (payload) {
        state.isEditingForm = true
      }
      state.isShowPreview = true
      state.isShowForm = false
      document
        .getElementById('BookingDrawer')
        .scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    prevForm: (state) => {
      state.isShowPreview = false
      state.isShowForm = true
      document
        .getElementById('BookingDrawer')
        .scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    closeDrawer: () => {
      return initialState
    },
    changeBookingOperator: (state, { payload }) => {
      state.bookingInformation.bookForName = payload.bookForName
      state.bookingInformation.bookForUuid = payload.bookForUuid
      state.bookingInformation.mobileNoOther = payload.mobileNoOther
      state.bookingInformation.emailOther = payload.emailOther
    },
    setBookingBy: (state, { payload }) => {
      state.bookingInformation.bookByName = payload.bookByName
    },
    changeToNonAcademy: (state) => {
      state.bookingInformation.academy = 'NON_ACADEMY'
      state.bookingInformation.bookForName = ''
      state.bookingInformation.bookForUuid = ''
      state.bookingInformation.bookForOther = ''
      state.bookingInformation.emailOther = ''
      state.bookingInformation.mobileNoOther = ''
    },
    changeToAcademy: (state) => {
      state.bookingInformation.academy = 'ACADEMY'
      state.bookingInformation.bookForName = ''
      state.bookingInformation.bookForUuid = ''
      state.bookingInformation.bookForOther = ''
      state.bookingInformation.emailOther = ''
      state.bookingInformation.mobileNoOther = ''
    },
  },
})

export const {
  startLoadingDrawer,
  stopLoadingDrawer,
  setStationName,
  setBookingInformation,
  setChangeBookingInformation,
  setBookingUuid,
  setRoomSelected,
  setStaffList,
  setReduxValue,
  setReduxObjectValue,
  setRoomBookingList,
  setRoomBookingListByDate,
  setPeriodByDate,
  setOverTimeOption,
  setTimeByDate,
  setMasterRoom,
  setRoom,
  addRoom,
  setValidateError,
  removeValidateError,
  openViewDrawerBooking,
  closeDrawer,
  openCreateFormDrawerBooking,
  openEditFormDrawerBooking,
  nextForm,
  prevForm,
  changeBookingOperator,
  changeToNonAcademy,
  changeToAcademy,
  setBookingBy,
} = roomManagementDrawer.actions

export default roomManagementDrawer.reducer

export const roomSelector = ({ bookingDate, roomIndex, key }) =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      const item = state.roomBookingList.find(
        (item) => item.bookingDate === bookingDate,
      )
      return _.get(item.rooms[roomIndex], key, '')
    },
  )

export const bookingItemSelector = ({ bookingDate, key }) =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      const item = state.roomBookingList.find(
        (item) => item.bookingDate === bookingDate,
      )
      return _.get(item, key, '')
    },
  )

export const roomBookingCountSelector = () =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      return state.roomBookingList.length
    },
  )

export const roomCountSelector = ({ bookingDate }) =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      const item = state.roomBookingList.find(
        (item) => item.bookingDate === bookingDate,
      )
      return item.rooms.length
    },
  )

export const bookingItemSelectorByIndex = ({ index, key }) =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      return _.get(state.roomBookingList[index], key, '')
    },
  )
export const errorSelector = (key) =>
  createSelector(
    (state) => state.roomManagementDrawer.errors,
    (state) => {
      return state.find((item) => item.key === key)
    },
  )

export const bookingItemLoadingSelector = () =>
  createSelector(
    (state) => {
      return state.roomManagementDrawer.bookingInformation
    },
    (state) => {
      return (
        state.roomBookingList.filter((item) => item.isLoadingMasterRooms)
          .length > 0
      )
    },
  )

export const roomLoadingSelector = () =>
  createSelector(
    (state) => state.roomManagementDrawer.bookingInformation,
    (state) => {
      return (
        _.filter(state.roomBookingList, { rooms: [{ isLoading: true }] })
          .length > 0
      )
    },
  )
