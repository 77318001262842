import React from 'react'
import useTheme from '@mui/system/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import {
  STUDENT_STATUS_DOT_COLOR_TEXT,
  STUDENT_STATUS_TEXT,
} from '../../../../../constants/eExamination'

const ActionStatusExam = ({ row }) => {
  const theme = useTheme()

  const color = _.get(STUDENT_STATUS_DOT_COLOR_TEXT, row.examStatus, '-')
  const value = _.get(STUDENT_STATUS_TEXT, row.examStatus, '-')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography color={checkColorStatus(color, theme)}>
        {!_.isEmpty(value) ? value : '-'}
      </Typography>
    </Box>
  )
}

const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    deleted: theme?.palette?.error?.main,
    waiting: theme?.palette?.warning?.light,
    waiting_for_active: theme?.palette?.warning?.light,
    approve: theme?.palette?.success?.main,
    reject: theme?.palette?.error?.main,
    draft: theme?.palette?.silver?.primary,
    cancel: theme?.palette?.error?.main,
    in_progress: theme?.palette?.primary?.main,
  }
  return colorList[`${statusText}`]
}

export default ActionStatusExam
