import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../handler/handleClearFilter'

export const FilterBox = () => {
  const { roomManagementDashboard } = useSelector(
    (state) => ({
      roomManagementDashboard: state.crud.roomManagementDashboard,
    }),
    shallowEqual,
  )
  const startDateFilter =
    roomManagementDashboard?.filtersDrawer?.startDate ?? ''
  const endDateFilter = roomManagementDashboard?.filtersDrawer?.endDate ?? ''
  const hasFilterDate = startDateFilter && endDateFilter
  const isFilterDate =
    roomManagementDashboard?.filtersDrawer?.isFilterDate ?? ''
  const stationFilter = roomManagementDashboard?.filtersDrawer?.station ?? ''
  const hasFilterStation = stationFilter
  const isFilterStation =
    roomManagementDashboard?.filtersDrawer?.isFilterStation ?? ''
  const stationNameFilter =
    roomManagementDashboard?.filtersDrawer?.stationName ?? ''
  return (
    <>
      {hasFilterDate || hasFilterStation ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
          {stationFilter?.length > 0 && (
            <FilterStyledBox>
              Station:{' '}
              <strong>
                {stationNameFilter.map(
                  (ele, ind) => (ind > 0 ? ', ' : '') + ele,
                )}
              </strong>
              {isFilterStation && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterStation')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
    </>
  )
}
