import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import Other from '../../Other'
import Footer from '../../Footer'
import { ChartBar } from '../../../CardChart/Styled'
import { optionsConfig, setDataConfig } from '../../model'

const Index = ({ props }) => {
  const { type, sequence, itemContent, nextQuestions } = props

  const questionType = _.get(itemContent, 'questionType', 'SINGLE')
  const totalLearnerAnswerQuestion = _.get(
    itemContent,
    'totalLearnerAnswerQuestion',
    0
  )
  const sequenceSubQuestions = _.get(itemContent, 'sequence', 1)
  const highestScore = _.get(itemContent, 'highestScore', 9)

  const no =
    type === 'questions' ? sequence : `${sequence}.${sequenceSubQuestions}`

  const otherAnswer = _.get(itemContent, 'otherAnswer', [])
  const itemQuestionRatting = _.get(itemContent, 'questions', []).find(
    (item) => item.sequence === nextQuestions
  )

  const listLabel =
    questionType !== 'RATING'
      ? _.get(itemContent, 'listLabel', [])
      : _.get(itemQuestionRatting, 'listLabel', [])

  const listData =
    questionType !== 'RATING'
      ? _.get(itemContent, 'listData', [])
      : _.get(itemQuestionRatting, 'listData', [])

  const dataConfig = setDataConfig(listLabel, listData)

  const propsFooter = {
    type,
    sequence,
    sequenceSubQuestions,
    nextQuestions,
    totalQuestion: _.get(itemContent, 'questions', []).length,
    titleQuestion: _.get(itemQuestionRatting, 'title', '-'),
  }

  return (
    <>
      <Card sx={{ mt: 3, ml: type === 'subQuestions' ? 3 : 0 }}>
        <Box sx={{ ml: 3, mt: 3 }}>
          <Typography variant="h6">
            ข้อที่ {no} {_.get(itemContent, 'title', '-')}
          </Typography>
          <Typography variant="body1">
            {totalLearnerAnswerQuestion} คำตอบ
          </Typography>
        </Box>
        {questionType !== 'FREE_TEXT' && (
          <>
            <Box sx={{ width: '100%', overflow: 'auto' }}>
              <ChartBar
                data={dataConfig}
                length={
                  listLabel.length <= 4
                    ? 300
                    : listLabel.length <= 10
                    ? 500
                    : 1000
                }
                options={optionsConfig(highestScore)}
              />
            </Box>
            {otherAnswer.length > 0 && (
              <Box sx={{ mx: 3, mb: 2, mt: 3 }}>
                <Typography variant="h6" sx={{ ml: 3, mb: 2 }}>
                  {questionType !== 'RATING'
                    ? 'อื่นๆ'
                    : 'ข้อเสนอแนะ / ความคิดเห็นเพิ่มเติม'}
                </Typography>
                <Other list={otherAnswer} />
              </Box>
            )}
            {questionType === 'RATING' && (
              <Box sx={{ mt: 2 }}>
                <Footer propsFooter={propsFooter} />
              </Box>
            )}
          </>
        )}
        <Box sx={{ m: 3, ml: 3, mt: 3 }}>
          {questionType === 'FREE_TEXT' && otherAnswer.length > 0 && (
            <Other list={otherAnswer} />
          )}
        </Box>
      </Card>
    </>
  )
}

export default Index
