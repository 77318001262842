import { StyledColumn, StyledRow } from '../../../../Styled'
import { BOOKING_PERIOD_TYPE } from '../../../../../../constants/roomManagement'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  addRoom,
  bookingItemSelector,
  bookingItemSelectorByIndex,
} from '../../../../../../redux/slices/roomManagement/Drawer'
import { Add } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { Period } from './Period'
import { CustomTimePicker } from './CustomTimePicker'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useRef } from 'react'
import { OverTimeSelect } from './OverTimeSelect'
import { RoomItems } from './RoomItems'
import { chkRoomOnline } from '../../../event'

export const BookingFormItem = ({ index }) => {
  const dispatch = useDispatch()
  const ref = useRef(null)

  const { bookingPeriod } = useSelector(
    (state) => ({
      bookingPeriod:
        state.roomManagementDrawer.bookingInformation.bookingPeriod,
    }),
    shallowEqual,
  )

  const bookingDate = useSelector(
    bookingItemSelectorByIndex({ index, key: 'bookingDate' }),
    shallowEqual,
  )

  const isShowDate = bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM

  const scrollToLast = () => {
    const lastChildElement = ref.current?.lastElementChild
    lastChildElement?.scrollIntoView({ behavior: 'smooth' })
  }

  const rooms = useSelector(
    bookingItemSelector({
      bookingDate,
      key: 'rooms',
    }),
    shallowEqual,
  )
  const isOnline = chkRoomOnline(rooms)
  const isShowAddRoomBtn = !isOnline || (isOnline && rooms.length > 1)

  return (
    <>
      <StyledRow
        sx={{
          gap: 2,
          pl: isShowDate ? 0 : 10,
          alignItems: 'stretch',
        }}
      >
        {isShowDate && (
          <Typography
            sx={{ textWrap: 'noWrap', mt: 3, width: 92 }}
            variant="body1b"
            color="primary"
          >
            {dayjs(bookingDate.toString()).format('DD/MM/YY')}
          </Typography>
        )}

        <Period bookingDate={bookingDate} />
        <StyledColumn
          sx={{
            width: '50%',
          }}
        >
          <Typography
            variant="body2"
            sx={{ display: 'flex' }}
            color={'text.secondary'}
          >
            เวลาเริ่มต้น
            <Typography
              variant="span"
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={'error'}
            >
              *
            </Typography>
          </Typography>
          <CustomTimePicker
            bookingDate={bookingDate}
            field="startTimeTraining"
          />
        </StyledColumn>
        <StyledColumn
          sx={{
            width: '50%',
          }}
        >
          <Typography
            variant="body2"
            sx={{ display: 'flex' }}
            color={'text.secondary'}
          >
            เวลาสิ้นสุด
            <Typography
              variant="span"
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={'error'}
            >
              *
            </Typography>
          </Typography>
          <CustomTimePicker bookingDate={bookingDate} field="endTimeTraining" />
        </StyledColumn>
      </StyledRow>
      <StyledRow sx={{ gap: 2, pl: 10, alignItems: 'stretch' }}>
        <OverTimeSelect
          bookingDate={bookingDate}
          field="beforeTraining"
          labelText="ใช้งานห้องก่อนอบรม"
        />
        <OverTimeSelect
          bookingDate={bookingDate}
          field="afterTraining"
          labelText="ใช้งานห้องหลังอบรม"
        />
      </StyledRow>
      <RoomItems innerRef={ref} bookingDate={bookingDate} />

      {isShowAddRoomBtn && (
        <Button
          sx={{
            width: 90,
            px: 1.25,
            py: 0,
            ml: 10,
            mt: -3,
          }}
          variant="outlined"
          size="s"
          startIcon={<Add sx={{ width: 18, height: 18 }} />}
          onClick={() => {
            dispatch(addRoom({ bookingDate }))
            scrollToLast()
          }}
        >
          <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            เพิ่มห้อง
          </span>
        </Button>
      )}
    </>
  )
}
