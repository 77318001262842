import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../components/Input/TextInput'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import ActiveSwitch from '../../../components/ActiveSwitch'
import { handleInputBody, handleSelector, handleSwitchBody } from '../events'

const RoadMap = () => {
  const keyField = 'roadMapInformation'
  const roadMapInformation = useSelector(
    (state) => handleSelector(state, keyField),
    shallowEqual
  )
  const dispatch = useDispatch()
  const { errors } = useFormikContext()

  return (
    <StyledSection sx={{ pb: 0 }}>
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">Section 2 | Road Map Information</Typography>
        <ActiveSwitch
          name="status"
          dataTestId={`active-section-2`}
          checked={_.get(roadMapInformation, 'status', false)}
          onChange={(e) => dispatch(handleSwitchBody(e, keyField))}
        />
      </StyledRow>
      <StyledColumn sx={{ gap: 0 }}>
        <TextInput
          required
          name="subtitle"
          type="basic"
          labelText="Subtitle"
          id="input-subtitle"
          placeholder="บรรดทัดที่ 1"
          value={_.get(roadMapInformation, 'subtitle', '')}
          textError={_.get(errors, `${keyField}.subtitle`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />
        <TextInput
          required
          name="title"
          type="basic"
          labelText="Title"
          id="input-title"
          placeholder="บรรดทัดที่ 2"
          value={_.get(roadMapInformation, 'title', '')}
          textError={_.get(errors, `${keyField}.title`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
        />
      </StyledColumn>
    </StyledSection>
  )
}

export default RoadMap
