import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import EditIcon from '@mui/icons-material/Edit'

import HistoryDrawer from '../../HistoryDrawer'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { Container } from './Styled'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { validateEPermission } from '../../../../../src/utils/lib'
import {
  CS_USER_PROFILE_SETTING,
  PERMISSION,
} from '../../../../../src/constants/permission/csUserProfile'

import {
  PROSPECT_PROFILE_SETTING,
  PERMISSION as PROSPECT_PERMISSION,
} from '../../../../../src/constants/permission/prospectProfile'

const listBreadcrumbProspect = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล Prospect',
    link: '/manage/prospect-profile',
    pointer: true,
  },
  { title: 'รายละเอียดข้อมูล Prospect', link: '/', pointer: false },
]

const listBreadcrumbCsUser = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล CS User',
    link: '/manage/cs-user-profile',
    pointer: true,
  },
  { title: 'รายละเอียดข้อมูล CS User', link: '/', pointer: false },
]

const Index = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { id: uuid } = useParams()
  const history = useHistory()

  const data = useSelector(
    (state) => state.prospectProfile.initialState,
    shallowEqual
  )

  const fullName = `${_.get(data, 'info.firstNameTH', '')} ${_.get(
    data,
    'info.lastNameTH',
    ''
  )}`

  const hasCSUserEdit = validateEPermission({
    module: CS_USER_PROFILE_SETTING,
    permission: PERMISSION.EDIT,
  })

  const hasProspectEdit = validateEPermission({
    module: PROSPECT_PROFILE_SETTING,
    permission: PROSPECT_PERMISSION.EDIT,
  })
  return (
    <Container>
      <Box>
        <Typography variant="h4">
          {type === 'CS'
            ? `รายละเอียดข้อมูล CS User : ${fullName}`
            : `รายละเอียดข้อมูล Prospect : ${fullName}`}
        </Typography>
        <Breadcrumbs
          menuList={
            type === 'CS' ? listBreadcrumbCsUser : listBreadcrumbProspect
          }
        />
      </Box>
      <Box sx={{ gap: 3, display: 'flex' }}>
        <Button
          startIcon={<HistoryTwoTone color="primary.main" />}
          variant="outlined"
          size="m"
          onClick={() => setIsOpen(true)}
        >
          ประวัติการแก้ไข
        </Button>
        {hasCSUserEdit &&
          type === 'CS' &&
          _.get(data, 'work.status', '') === 'ACTIVE' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              size="m"
              onClick={() =>
                history.push(`/manage/cs-user-profile/edit/${uuid}`)
              }
            >
              แก้ไข
            </Button>
          )}
        {hasProspectEdit &&
          type !== 'CS' &&
          _.get(data, 'work.status', '') === 'ACTIVE' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              size="m"
              onClick={() =>
                history.push(`/manage/prospect-profile/edit/${uuid}`)
              }
            >
              แก้ไข
            </Button>
          )}
      </Box>

      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </Container>
  )
}

export default Index
