import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'

export const handleDeleteAsset = (index) => {
  const { formData } = store.getState().crud
  const assets = _.get(formData, 'assets', [])
  const removedAssets = assets.filter((item, itemIndex) => index !== itemIndex)
  store.dispatch(
    setFieldValue({
      key: 'formData.assets',
      value: removedAssets,
    }),
  )
}
