import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledLearnerMenu = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  width: '100%',
  height: 40,
  border: 'solid 1px',
  borderColor: theme?.palette?.text?.silver,
  background: theme?.palette?.background?.paper,
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(1),
}))

export const StyledCardLearner = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  img: {
    width: 20,
    heigth: 20,
    borderRadius: '100%',
  },
  svg: {
    position: 'absolute',
    right: 12,
    top: 16,
    fontSize: '16px',
  },
}))

export const StyledTypography = styled(Typography)(() => ({
  maxWidth: 240,
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))