import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { displayResult } from '../Matching/Styled'

export const StyledHeadContainer = styled(Box)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)} ${theme.spacing(1)}`,
  display: 'flex',
  gap: theme.spacing(7),
  justifyContent: 'end',
}))

export const StyledQuestionList = styled(Box)(({ theme, resultProps }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  gap: theme.spacing(6),
  width: '100%',
  background: displayResult(theme, resultProps),
  padding: resultProps.isSubmit ? theme.spacing(1.5) : 0,
  '&:not(:last-of-type)': { marginBottom: theme.spacing(1) },
}))

export const StyledQuestionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'start',
  alignItems: 'start',
}))

export const StyledRadioContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(5),
  minWidth: 124,
  marginRight: theme.spacing(1.5),
  '& > label': { margin: 0 },
}))
