export const questionType = {
  RATING: 'RATING',
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  FREE_TEXT: 'FREE_TEXT',
}

export const questionTypeText = {
  RATING: 'Rating',
  SINGLE: 'Single Choice',
  MULTIPLE: 'Multiple Choice',
  FREE_TEXT: 'Free Text',
}
