import React from 'react'
import { StyledLabelPercent } from '../stlyed'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'

export const UploadLoadingCircular = ({ percent }) => {
  return (
    <Box sx={{ position: 'relative', width: 46, height: 46, mx: 1.5 }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={46}
        thickness={3}
        sx={{ color: 'rgba(229, 229, 229, 1)' }}
      />
      <CircularProgress
        variant="determinate"
        value={percent}
        size={46}
        thickness={3}
        sx={{ animationDuration: '550ms', position: 'absolute', left: 0 }}
      />
      <StyledLabelPercent variant="body3b">
        {percent.toFixed()}%
      </StyledLabelPercent>
    </Box>
  )
}
