import _ from 'lodash'
import {
  eEvaluationDownload,
  eEvaluationFilter,
} from '../../../../utils/apiPath'
import { headCells } from '../../../../constants/eEvaluation'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/table'
import { downloadFile } from '../../../../services/util'
import { initialState } from '../../../../redux/slices/table/model'
import callAxios from '../../../../utils/baseService'
import { path } from '../../../../constants/path'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }
  await dispatch(
    downloadFile({
      url: eEvaluationDownload,
      body: body,
      fileName: `รายการE-Evaluation.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, statusList, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const sortBy = tableProps.sort
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      status: isInitial ? [] : statusList,
      type: _.get(filter, 'type', []),
      name: method == 'filter' ? keyword : title,
      codeId: _.get(filter, 'codeId', ''),
      numberOfVersions: _.get(filter, 'numberOfVersions', -1),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
    }

    const modifiedHeadCells = await headCells.map((item) => {
      const viewPath =
        item.id === 'actionE' ? `${path.eEvaluation}/dashboard` : undefined
      return {
        ...item,
        viewPath,
      }
    })

    // await axios
    const isCancel = await callAxios
      .post(eEvaluationFilter, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => ({
          ...item,
          updatedBy: item.updatedBy.name,
          eEvaluationType: _.capitalize(item.eEvaluationType),
        }))
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: modifiedHeadCells,
            placeholder: 'ค้นหาชื่อแบบประเมิน',
            searchKey: 'name',
            status: statusList,
            canFilter: true,
            isCheckBox: false,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  const typeList = []
  if (filter.typeChecked) {
    if (filter.type.classChecked) typeList.push('CLASS')
    if (filter.type.standaloneChecked) typeList.push('STANDALONE')
  }

  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  } else {
    statusList.push('ACTIVE', 'INACTIVE')
  }
  dispatch(setStatusFilter(statusList))

  const filterText = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    type: typeList,
    numberOfVersions: filter.numberOfVersionsChecked
      ? +filter.numberOfVersions
      : -1,
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList('filter', table, 1, statusList, filterText, filterText.name),
  )
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(fetchDataList('search', table, 1, statusList, null, text))
}
