import React, { useEffect, useMemo } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'

import { setLevelDistribution } from '../../../../../redux/slices/manageContent/home'
import TextInput from '../../../../../components/Input/TextInput'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledSection } from '../Styled'
import MainTabs from '../../../components/MainTabs'
import ActiveSwitch from '../../../components/ActiveSwitch'
import { handleInputBody, handleSelector, handleSwitchBody } from '../events'
import {
  StyledBoxContent,
  StyledDivider,
  StyledRowBox,
  StyledRowForm,
} from './Styled'
import { fetchOptionCourse } from './events'
import CoursesTabs from './Tabs'

const Courses = () => {
  const [onEdit] = useLocalStorage('onEdit')
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const keyTitle = 'coursesTitle'
  const keyInfo = 'coursesInformation'
  const { coursesTitle, courses, levelDistribution } = useSelector(
    (state) => ({
      coursesTitle: handleSelector(state, keyTitle),
      courses: handleSelector(state, keyInfo),
      levelDistribution: state.manageContentHome.levelDistribution,
    }),
    shallowEqual,
  )
  const tabsTitle = courses.map((item) => ({
    label: `${item.level?.title} : ${item.distribution?.abbreviation}`,
    value: item.uuid,
  }))
  const courseIndex = useMemo(
    () => courses.findIndex((item) => item.uuid == levelDistribution),
    [levelDistribution],
  )

  useEffect(() => {
    dispatch(fetchOptionCourse(onEdit, courses))
  }, [])

  return (
    <StyledSection>
      <StyledRow justifyContent="space-between">
        <Typography variant="h6">Section 5 | Courses</Typography>
        <ActiveSwitch
          name="status"
          dataTestId="active-section-5"
          checked={_.get(coursesTitle, 'status', false)}
          onChange={(e) => dispatch(handleSwitchBody(e, keyTitle))}
        />
      </StyledRow>
      <StyledColumn sx={{ gap: 0 }}>
        <TextInput
          required
          name="title"
          type="basic"
          labelText="Title"
          id="input-title"
          value={_.get(coursesTitle, 'title', '')}
          textError={_.get(errors, `${keyTitle}.title`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyTitle))}
        />
        <TextInput
          required
          name="description"
          type="basic"
          labelText="Description"
          id="input-description"
          value={_.get(coursesTitle, 'description', '')}
          textError={_.get(errors, `${keyTitle}.description`, '')}
          onChange={(e) => dispatch(handleInputBody(e, keyTitle))}
        />

        <StyledDivider />

        <StyledBoxContent>
          <MainTabs
            tabs={tabsTitle}
            value={levelDistribution}
            onChange={(_, tab) => dispatch(setLevelDistribution(tab))}
          />

          <Typography textAlign="center" color="text.secondary" variant="h4">
            {_.get(tabsTitle, `[${courseIndex}].label`, '')}
          </Typography>

          <StyledDivider />

          <CoursesTabs courseIndex={courseIndex} />
        </StyledBoxContent>

        <StyledDivider />

        <ButtonForm courses={coursesTitle} keyField={keyTitle} />
      </StyledColumn>
    </StyledSection>
  )
}

export default Courses

export const ButtonForm = ({ courses, keyField }) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const errorButton = _.get(errors, `${keyField}.button`, '')
  return (
    <StyledRowBox>
      <StyledRowForm>
        <StyledColumn sx={{ gap: 0.5 }}>
          <TextInput
            name="button"
            type="basic"
            labelText="Button"
            id="input-button"
            value={_.get(courses, 'button', '')}
            onChange={(e) => dispatch(handleInputBody(e, keyField))}
            textError={errorButton}
            isShowTextError={errorButton}
          />
          {!errorButton && (
            <Typography variant="body2" color="text.secondary">
              แนะนำให้ใช้ข้อความที่มีความยาวประมาณ 20 ตัวอักษร
            </Typography>
          )}
        </StyledColumn>
        <TextInput
          name="URL"
          type="basic"
          labelText="URL"
          id="input-url"
          value={_.get(courses, 'URL', '')}
          onChange={(e) => dispatch(handleInputBody(e, keyField))}
          textError={_.get(errors, `${keyField}.URL`, '')}
        />
      </StyledRowForm>
      <ActiveSwitch
        name="statusButton"
        dataTestId={`active-last-button`}
        checked={_.get(courses, 'statusButton', false)}
        onChange={(e) => dispatch(handleSwitchBody(e, keyField))}
      />
    </StyledRowBox>
  )
}
