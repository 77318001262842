import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/system/styled'
export const Frame = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.other?.divider}`,
  borderRadius: '4px',
  padding: '3px 12px',
  display: 'flex',
  flexDirection: 'column',
}))

export const TypographyIcon = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: { display: 'inline-block', gap: 0 },
}))

export const UnderList = styled('ul')(({ theme }) => ({
  margin: '0px',
  li: {
    span: {
      color: theme?.palette?.error?.main,
    },
  },
}))

export const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  marginLeft: '6px',
}))
