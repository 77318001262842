import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { contentTypeLearningPath } from '../model/contentTypeLearningPath'
import { scrollToLast } from './scrollToLast'

export const handleAddContentTypeLearningPath = () => {
  const state = store.getState().crud

  const contents = [...state.formData.contents]
  contents.push(contentTypeLearningPath(contents.length))

  store.dispatch(setFieldValue({ key: 'formData.contents', value: contents }))
  scrollToLast()
}
