import {
  StyledCard,
} from '../Lib/CSS/Styled'
import Table from '../../../../../components/redux/Table'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { fetchDataList } from './event'
import { useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import FilterDrawer from './FilterDrawer'
import { useParams } from 'react-router-dom'
import { setExamType } from '../../../../../redux/slices/masterData/examination'
import { 
  setSearchText,
  setFilterProp,
  resetTablePage,
  setStatusFilter,
  setDefaultSort,
  setSelected,
  setOrder,
} from '../../../../../redux/slices/table'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { status, filterProp, isFilterDrawer } = filter
  const manageProps = { uuid }
  const { searchText } = search
  const { examType } = useSelector(
    (state) => ({
      examType: state.masterDataExamination.examType
    }),
    shallowEqual,
  )
  
  const onTabClick = (type) => {
    resetTable()
    dispatch(setExamType(type))
  }

  const resetTable = () => {
    dispatch(setSearchText(''))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(setStatusFilter([]))
    dispatch(setSelected([]))
    dispatch(setDefaultSort({ state: 0, id: '', active: false }))
    dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  }

  useEffect(() => {
    resetTable()
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        examType,
        setInitial
      )
    )
  }, [examType])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList('fetch', table, page, status, filterProp, searchText, examType)
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <Box>
        <Button
          sx={{ height: 40, p: 1 }}
          onClick={() => {
            onTabClick('สนามสอบสมาคม')
          }}
        >
          <Typography
            variant="body1b"
            sx={
              examType === 'สนามสอบสมาคม'
                ? { borderBottom: 4, px: 2 }
                : { color: '#A9B8CD' }
            }
          >{`สนามสอบสมาคม`}</Typography>
        </Button>
        <Button
          sx={{ height: 40, p: 1 }}
          onClick={() => {
            onTabClick('สนามสอบบริษัท')
          }}
        >
          <Typography
            variant="body1b"
            sx={
              examType === 'สนามสอบบริษัท'
                ? { borderBottom: 4, px: 2 }
                : { color: '#A9B8CD' }
            }
          >{`สนามสอบบริษัท`}</Typography>
        </Button>
      </Box>
      <StyledCard>
        <Table />
      </StyledCard>
      <FilterDrawer
          open={isFilterDrawer}
          table={table}
          manageProps={manageProps}
          examType={examType}
        />
    </>
  )
}

export default Index
