import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import IconAdd from '@mui/icons-material/AddCircleOutlineOutlined'

import SelectStatic from '../../../../../components/redux/SelectStatic'
import { optionQuestion } from '../../../../../constants/eTesting'
import { CardAddQuestion, BoxAddQuestion, BoxIcon } from './Styled'
import { handleAddQuestion } from './event'

const Index = () => {
  const dispatch = useDispatch()

  const { questions, onViewMode, disableForm } = useSelector(
    (state) => ({
      questions: state.eTestingForm.questions,
      onViewMode: state.eTestingForm.onViewMode,
      disableForm: state.eTestingForm.disableForm,
    }),
    shallowEqual
  )

  if (onViewMode) return <Box sx={{ pb: 4 }}></Box>
  return (
    <Box sx={{ pb: 4 }}>
      <CardAddQuestion>
        <BoxAddQuestion>
          <SelectStatic
            size="m"
            listOption={optionQuestion}
            placement="top"
            iconLabel={
              <BoxIcon data-testid="btn-add-question">
                <IconAdd />
                <Typography variant="buttonm">เพิ่มคำถาม</Typography>
              </BoxIcon>
            }
            handleChange={(value) => {
              dispatch(handleAddQuestion(value, questions.length))
            }}
            handleChangeIcon={() => {
              dispatch(handleAddQuestion('SINGLE', questions.length))
            }}
            disabled={disableForm}
          />
        </BoxAddQuestion>
      </CardAddQuestion>
    </Box>
  )
}
export default Index
