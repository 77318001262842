import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledIconCell = styled(Box)(({ theme, value }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  svg: { width: 16, color: mapColorIcon(theme, value) },
  button: { padding: 0, minWidth: 34, height: 26 },
}))

export const mapColorIcon = (theme, value) => {
  if (value == 0) return theme?.palette?.text?.silver
  else return theme?.palette?.warning?.main
}
