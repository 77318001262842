import { store } from '../../../../App'
import { setInitialTable } from '../../../../redux/slices/table'
import { getBudgetManagementHistory } from '../../../../services/budgetManagement'
import _ from 'lodash'
import { headCells } from '../model/headCells'
import { sxTable } from '../model/sxTable'

export const fetchBudgetManagementHistory = async (props) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`
  const response = await store.dispatch(getBudgetManagementHistory(url))

  store.dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: _.get(response, 'payload.data.result', []),
      allCount: _.get(response, 'payload.data.totalCount', 0),
      headCells: headCells,
      customStyle: sxTable,
    }),
  )
}
