import {
  filterTrainingPlan,
  getStaffListOptions,
} from '../../../../../redux/slices/inventory/requisition/events'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleFilterFromManageClass = async (props) => {
  const { trainingPlans, courseUuid } = props
  const filteredTrainingPlans = await filterTrainingPlan(trainingPlans)
  store.dispatch(
    setFieldValue({
      key: 'masterData.filteredCourses.options',
      value: filteredTrainingPlans,
    }),
  )
  const staffList = await getStaffListOptions(courseUuid)
  store.dispatch(
    setFieldValue({
      key: 'masterData.responsibleUser.options',
      value: staffList,
    }),
  )
}
