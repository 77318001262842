import React, { useState } from 'react'
import { isMediaWidthMoreThan } from '../../../../utils/lib'
import { StyledColumn, StyledContainer } from './Styled'
import TabContainer from './TabContainer'
import LearnerList from './LearnerList'
import ExamContainer from './ExamContainer'

const BoardContent = ({ isStandalone }) => {
  const [listHeight, setListHeight] = useState(600)
  const isLg = isMediaWidthMoreThan('md')

  return (
    <StyledContainer>
      {isLg && (
        <LearnerList listHeight={listHeight} isStandalone={isStandalone} />
      )}
      <StyledColumn>
        <TabContainer isStandalone={isStandalone} />
        <ExamContainer
          isStandalone={isStandalone}
          setListHeight={setListHeight}
        />
      </StyledColumn>
    </StyledContainer>
  )
}

export default BoardContent
