import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FileDownloadOutlined } from '@mui/icons-material'
import {
  EndFlex,
  HeaderBox,
  StartFlex,
  StyledDownloadButton,
  TitleBox,
} from './styled'
import { ECONTENT_TYPE_TH_TEXT } from './model'
import { convertFormatDateTime } from './events'

const Header = () => {
  const { preview } = useSelector(
    (state) => ({
      preview: state.contentForm.preview,
    }),
    shallowEqual,
  )
  return (
    <HeaderBox>
      <StartFlex>
        <Typography variant="body1" color="secondary.main">
          {ECONTENT_TYPE_TH_TEXT[preview.contentType]}
        </Typography>
        <TitleBox>{preview?.name}</TitleBox>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Typography variant="body1" color="text.secondary">
            วันที่เผยแพร่
          </Typography>
          <Typography variant="body1b">
            {convertFormatDateTime({
              value: preview?.startPublishDate,
            })}
          </Typography>
        </Box>
      </StartFlex>
      {preview?.isDownload === 'ACTIVE' && (
        <EndFlex>
          <StyledDownloadButton
            variant="contained"
            startIcon={<FileDownloadOutlined />}
          >
            <Typography>ดาวโหลดหน้านี้</Typography>
          </StyledDownloadButton>
        </EndFlex>
      )}
    </HeaderBox>
  )
}

export default Header
