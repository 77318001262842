import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'

import TextInput from '../../../../components/Input/TextInput'
import { StyledCard, StyledFormWrapper } from '../../Styled'
import { 
    handleNumberInput,
    handleNumberKeyDown,
    handlePasteFormat,
} from '../../../../utils/lib'
import { clearLeadingZeros } from '../event'
import _ from 'lodash'

const GeneralForm = ({
    formik,
    body,
    setInputValue,
    isHide
}) => {
    return (
        <StyledFormWrapper isHide={isHide}>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        การสร้างคลาสใหม่
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                การสร้างคลาสใหม่หลังจาก Approve แผนล่วงหน้า
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="NEW_CLASS_AFTER_APPROVE"
                                name="NEW_CLASS_AFTER_APPROVE"
                                labelText="ไม่น้อยกว่าวันที่จัดอบรม"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'NEW_CLASS_AFTER_APPROVE',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.NEW_CLASS_AFTER_APPROVE}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.NEW_CLASS_AFTER_APPROVE}
                                onChange={(e) => {
                                    handleInputChange({key: 'NEW_CLASS_AFTER_APPROVE', e, setInputValue})
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                วัน
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
            <StyledCard>
                <Box m={3}>
                    <Typography variant='h5' mb={2}>
                        การแก้ไขแผนรายเดือน
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                อนุญาตให้แก้ไขแผนงานเดือนก่อนหน้าได้ไม่เกิน
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="CAN_EDIT_PREVIOUS_PLAN"
                                name="CAN_EDIT_PREVIOUS_PLAN"
                                labelText="วันที่"
                                placeholder={"Ex. 1 - 28"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'CAN_EDIT_PREVIOUS_PLAN',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.CAN_EDIT_PREVIOUS_PLAN}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.CAN_EDIT_PREVIOUS_PLAN}
                                onChange={(e) => {
                                    handleInputChange({key: 'CAN_EDIT_PREVIOUS_PLAN', e, setInputValue})
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                                การแก้ไขวันที่อบรมในแผนรายเดือน ประเภทตารางงาน Training หลังจากแผนรายเดือนอนุมัติแล้ว
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EDIT_TRAINING_PLAN_DATE_AHEAD"
                                name="EDIT_TRAINING_PLAN_DATE_AHEAD"
                                labelText="แก้ไขวันที่จัดอบรมต้องไม่น้อยกว่าวันปัจจุบัน"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EDIT_TRAINING_PLAN_DATE_AHEAD',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EDIT_TRAINING_PLAN_DATE_AHEAD}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EDIT_TRAINING_PLAN_DATE_AHEAD}
                                onChange={(e) => {
                                    handleInputChange({key: 'EDIT_TRAINING_PLAN_DATE_AHEAD', e, setInputValue})
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                วัน
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
                            <Typography variant='body1b'>
                               อนุญาตแก้ไขพื้นที่อบรมหลังจากอนุมัติแผนรายเดือน
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE"
                                name="EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE"
                                labelText="ก่อนถึงวันอบรม"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE}
                                onChange={(e) => {
                                    handleInputChange({key: 'EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE', e, setInputValue})
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                วัน
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={2}>
                            <Typography variant='body1b'>
                                อนุญาตให้แก้ไข Budget หลังจากอนุมัติแผนรายเดือน
                            </Typography>
                            <Typography
                                sx={{ lineHeight: 1, ml: 0.5 }}
                                color={'error'}
                            >
                                *
                            </Typography>
                        </Box>
                        <Box  
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                gap: '12px',
                                width: '40%',
                            }}
                            mb={3}
                        >
                            <TextInput
                                id="EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE"
                                name="EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE"
                                labelText="ก่อนถึงวันอบรม"
                                placeholder={"Ex. 0 - 99"}
                                type="basic"
                                inputProps={{
                                    'data-testid': 'EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE',
                                    type: 'number',
                                    onInput: handleNumberInput,
                                    onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
                                    onPaste: handlePasteFormat,
                                    maxLength: 2,
                                }}
                                textError={formik?.errors?.EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE}
                                required
                                // disabled={!hasPermissionCreate}
                                value={body.EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE}
                                onChange={(e) => {
                                    handleInputChange({key: 'EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE', e, setInputValue})
                                }}
                                onBlur={formik.handleBlur}
                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                            />
                            <Typography>
                                วัน
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledCard>
        </StyledFormWrapper>

    )
}

export default GeneralForm

export const handleInputChange = (props) => {
    const { key, e, setInputValue } = props
    if(key === 'CAN_EDIT_PREVIOUS_PLAN' && !_.isEmpty(e.target.value)){
      if(e.target.value > 28 || e.target.value <= 0){
        e.preventDefault()
        return
      }
    }
    if(String(e.target.value).length >= 2){
      e.target.value = e.target.value.substring(0, 2)
    }
    setInputValue(key, clearLeadingZeros(e.target.value))
}