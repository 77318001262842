export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const defaultFilter = {
  nameChecked: false,
  name: '',
  pointChecked: false,
  point: '',
  qtyChecked: false,
  qty: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  createdAtChecked: false,
  updatedAtChecked: false,
}

export const arrayCheckbox = [
  {
    label: 'ชื่อของราวัล',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อของรางวัล',
    type: 'text',
  },
  {
    label: 'คะแนนที่ใช้แลก',
    nameCheckBox: 'pointChecked',
    nameInput: 'point',
    placeholder: 'ค้นหาคะแนนที่ใช้แลก',
    type: 'text',
  },
  {
    label: 'จำนวนรางวัล',
    nameCheckBox: 'qtyChecked',
    nameInput: 'qty',
    placeholder: 'ค้นหาจำนวนรางวัล',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'ID',
    nameCheckBox: 'idChecked',
    nameInput: 'id',
    placeholder: 'ค้นหา ID',
    type: 'text',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createStartDate',
    dateStateFinish: 'createFinishDate',
    dateStateDisplay: 'createDisplayDate',
    type: 'daterange',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: `วันที่แก้ไขล่าสุด`,
    nameCheckBox: 'updateDateChecked',
    nameInput: 'updateDate',
    placeholder: `กรุณาใส่ วันที่แก้ไข`,
    dateStateStart: 'updateStartDate',
    dateStateFinish: 'updateFinishDate',
    dateStateDisplay: 'updateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหา',
    type: 'text',
  },
]
