import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { getBodyMutation } from './mutation/getBodyMutation'
import { Button } from '@mui/material'
import { Content } from './Content'
import { getModuleTitle } from '../utils'
import { breadcrumb } from './model/breadcrumb'
import { LoadingPage, StyledContainer } from '../Styled'
import { CHeader } from '../../../components/CRUD/components/Header'
import { useEffect } from 'react'
import { setFieldValue } from '../../../redux/slices/crud'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { fetchMasterDataOptions } from './handler/fetchMasterDataOptions'
import { handleSubmitClick } from './handler/handleSubmitClick'
import { fetchDetails } from './handler/fetchDetails'

export const BudgetManagementForm = () => {
  const { uuid } = useParams()
  const { crudConfig, isLoading } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const initializeCrud = () => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetails('form', uuid).then()
    } else {
      dispatch(setFieldValue({ key: 'formData', value: getBodyMutation() }))
    }
    fetchMasterDataOptions().then()
  }

  useEffect(initializeCrud, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <StyledContainer isLoading={isLoading}>
      <CHeader
        title={getModuleTitle(uuid, crudConfig.modulesTitleShort)}
        breadcrumbs={breadcrumb(uuid)}
        action={
          <Button variant="contained" onClick={() => handleSubmitClick()}>
            บันทึกและเผยแพร่
          </Button>
        }
      />
      <Content />
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
