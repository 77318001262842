import React from 'react'
import Box from '@mui/material/Box'
// import _ from 'lodash'
import StockDropdownButton from './DropdownButton'

const ActionStockDropown = ({ row, isInventoryStock }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <StockDropdownButton row={row} isInventoryStock={isInventoryStock} />
    </Box>
  )
}

export default ActionStockDropown
