import { InboxTwoTone } from '@mui/icons-material'
import { StyledTableBodyCell } from '../../Styled'
import { Typography, TableRow, Box } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'

const NotFoundData = () => {
  const { columns } = useSelector(
    (state) => ({
      columns: state.reportComp.columns,
    }),
    shallowEqual,
  )

  return (
    <TableRow>
      <StyledTableBodyCell colSpan={columns?.length}>
        <Box>
          <InboxTwoTone />
          <Typography variant="body2" color="text.lightGray">
            ไม่พบข้อมูล
          </Typography>
        </Box>
      </StyledTableBodyCell>
    </TableRow>
  )
}

export default NotFoundData
