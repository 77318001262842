import LoadingPageCircular from '../LoadingPageCircular'
import Box from '@mui/material/Box'
import { StyledViewCard, StyledViewWrapper } from '../../../../modules/Styled'
import { shallowEqual, useSelector } from 'react-redux'

export const CPageViewContainer = ({ header, content }) => {
  const isLoading = useSelector((state) => state.crud.isLoading, shallowEqual)

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
        {header}
        <StyledViewWrapper>
          <StyledViewCard isUploadLoading={false}>{content}</StyledViewCard>
        </StyledViewWrapper>
      </Box>
    </>
  )
}
