import axios from 'axios'
import _ from 'lodash'
import { prospectUploadImage } from '../../../../../utils/apiPath'
import {
  setChangeInformation,
  setProfileImage,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manage/prospectProfile'
import {
  openDialog,
  closeDialog,
} from '../../../../../redux/slices/dialog'

export const handleChangeTH = (event) => (dispatch) => {
  const regex = /^[0-9ก-๏\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
  const newValue = event.value
  if (regex.test(newValue) || _.isEmpty(newValue)) {
    dispatch(setChangeInformation(event))
  }
}

export const handleChange = (event) => (dispatch) => {
  const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
  const newValue = event.value
  if (regex.test(newValue) || _.isEmpty(newValue)) {
    dispatch(setChangeInformation(event))
  }
}

export const handleUpload = (e) => (dispatch) => {
  const file = e.target.files[0]
  const fileSizeKiloBytes = file?.size / 1024
  if (fileSizeKiloBytes > 2048) {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(startLoading())
    setProfileImage(URL.createObjectURL(file))
    dispatch(uploadImage(file))
    dispatch(stopLoading())
  }
}

export const handleDelete = () => (dispatch) => {
  dispatch(setProfileImage(''))
}

export const uploadImage = (file) => (dispatch) => {
  const formData = new FormData()
  formData.append('file', file)
  axios
    .post(prospectUploadImage, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      dispatch(setProfileImage(data.key))
    })
}
