import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { StyledColumn, StyledRow } from '../../../Dashboard/Styled'
import Switch from '@mui/material/Switch'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import { noExpiry } from '../events'
import { LoadingPage } from '../Styled'
import { SettingQRSection } from './SettingQRSection'
import { countdownTime } from '../events'
import { handleEnableQRCode } from './events'

export const Content = ({ uuid }) => {
  const {
    isMobile,
    qrEnrollProps: {
      link,
      isEnable,
      isLoadingResetQR,
      expiryType,
      expiryDate,
      expiryTime,
      isOpenDatePicker,
    },
  } = useSelector((state) => state.manageClassDetail, shallowEqual)

  const [time, setTime] = useState({
    days: 0,
    hrs: 0,
    mins: 0,
    secs: 0,
  })
  const dispatch = useDispatch()

  const enableQR = () => {
    return isEnable && !isLoadingResetQR
  }

  useEffect(() => {
    if (_.isNull(link) || noExpiry(expiryType)) return
    const timer = setInterval(() => {
      return dispatch(countdownTime(expiryDate, expiryTime, timer, setTime))
    }, 1000)
    return () => clearInterval(timer)
  }, [time, link, expiryDate, expiryTime])

  return (
    <StyledColumn
      sx={{
        width: 'auto',
        m: 3,
        gap: 2,
        height: '100vh',
        position: 'relative',
        minHeight: isOpenDatePicker ? 600 : 0,
      }}
    >
      <StyledRow sx={{ justifyContent: 'space-between' }}>
        <Typography variant={isMobile ? 'h6' : 'h4'}>
          ใช้งาน QR Code ลงทะเบียน
        </Typography>
        <Switch
          inputProps={{
            'data-testid': 'switch-enable',
          }}
          checked={isEnable}
          onChange={(e) => {
            const props = {
              enable: e.target.checked,
              time: time,
              uuid: uuid,
              link: link,
              expiryType: expiryType,
            }
            dispatch(handleEnableQRCode(props))
          }}
        />
      </StyledRow>
      {enableQR() && <SettingQRSection uuid={uuid} time={time} />}
      <LoadingPage isLoading={isLoadingResetQR} />
    </StyledColumn>
  )
}
