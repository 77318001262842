import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Tooltip from '@mui/material/Tooltip'
import useTheme from '@mui/system/useTheme'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleEnableButton,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead,
} from './Styled'
import _ from 'lodash'
import { setEnableButton } from '../../../../redux/slices/dialog'
import { E_EXAM_UPLOAD_STATUS } from '../../../../constants/eExamination'
import { E_EXAM_TYPE } from '../../../../constants/eExamination'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Truncate from '../../../../components/redux/Table/Truncate'
import { getValue } from '../../../../utils/lib'

export const CheckDialog = () => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const { importFile, timeList, eExamType, associationList, companyList } =
    useSelector(
      (state) => ({
        importFile: state.eExamList.importFile,
        timeList: state.eExamList.timeList,
        eExamType: state.eExamList.eExamType,
        associationList: state.eExamList.associationList,
        companyList: state.eExamList.companyList,
      }),
      shallowEqual,
    )
  const dispatch = useDispatch()
  const theme = useTheme()
  const uploadResult = _.get(importFile, 'uploadResult', [])
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const successCount = useMemo(() => {
    const successList = result.filter((item) => item.status === 'SUCCESS')
    if (_.size(successList) == _.size(result)) {
      dispatch(setEnableButton())
    }

    return successList.length
  }, [result])
  const uploadSuccess = status === 'SUCCESS'

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <StyledDialog>
        {!uploadSuccess && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              สำเร็จ <span>{successCount} รายการ</span>
              {' , '}
              ไม่สำเร็จ <span>{_.size(result) - successCount} รายการ</span>{' '}
            </Typography>
          </Box>
        )}
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>สถานะ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 80 }}>
                <Typography>No.</Typography>
              </TableCell>
              {eExamType === E_EXAM_TYPE.OTHER ||
                (eExamType === E_EXAM_TYPE.ASSOCIATION && (
                  <TableCell sx={{ minWidth: 130 }}>
                    <Typography>ภาค</Typography>
                  </TableCell>
                ))}
              {eExamType === E_EXAM_TYPE.OTHER && (
                <TableCell sx={{ minWidth: 130 }}>
                  <Typography>จังหวัด</Typography>
                </TableCell>
              )}
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>สถานที่สอบ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>วันที่สอบ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>ช่วงเวลา</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>เวลาเริ่มสอบ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>สอบถึงเวลา</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>จำนวนที่เปิดรับ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>วันที่เปิดรับสมัคร</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>เวลาที่เปิดรับสมัคร</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>วันที่ปิดรับสมัคร</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography>เวลาที่ปิดรับสมัคร</Typography>
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {result.map((item) => (
              <StyledTableData status={item.status} key={item.index}>
                <TableCell sx={{ minWidth: 130 }}>
                  {item.status === 'SUCCESS' ? (
                    <Typography>{E_EXAM_UPLOAD_STATUS[item.status]}</Typography>
                  ) : (
                    <Typography color="error" sx={{ display: 'flex', gap: 1 }}>
                      <Typography>
                        {E_EXAM_UPLOAD_STATUS[item.status]}
                      </Typography>
                      <Tooltip
                        title={
                          <ul style={{ margin: 0, marginLeft: '-20px' }}>
                            {_.get(item, 'error', []).map((err) => {
                              return (
                                <li
                                  key={err}
                                  style={{ display: 'list-item', p: 0 }}
                                >
                                  {err}
                                </li>
                              )
                            })}
                          </ul>
                        }
                        followCursor
                        componentsProps={{
                          tooltip: {
                            sx: {
                              background: theme?.palette?.blue?.azure,
                              color: theme?.palette?.common?.white,
                              boxShadow: theme?.palette?.boxShadow?.main,
                            },
                          },
                        }}
                      >
                        <ErrorOutline color="error" />
                      </Tooltip>
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <Truncate>{'' + getValue(item, 'id', '-')}</Truncate>
                </TableCell>
                {eExamType === E_EXAM_TYPE.OTHER ||
                  (eExamType === E_EXAM_TYPE.ASSOCIATION && (
                    <TableCell sx={{ minWidth: 130 }}>
                      <Truncate>{'' + getValue(item, 'region', '-')}</Truncate>
                    </TableCell>
                  ))}
                {eExamType === E_EXAM_TYPE.OTHER && (
                  <TableCell sx={{ minWidth: 130 }}>
                    <Truncate>
                      {'' + getValue(item, 'provinceName', '-')}
                    </Truncate>
                  </TableCell>
                )}
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {eExamType === E_EXAM_TYPE.OTHER
                      ? '' + getValue(item, 'otherName', '-')
                      : eExamType === E_EXAM_TYPE.ASSOCIATION
                      ? '' + getValue(item, 'provinceName', '-')
                      : '' + getValue(item, 'academyStationName', '-')}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>{'' + getValue(item, 'date', '-')}</Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>{'' + getValue(item, 'time', '-')}</Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>{'' + getValue(item, 'startTime', '-')}</Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>{'' + getValue(item, 'endTime', '-')}</Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {'' + getValue(item, 'numberOfPeople', '-')}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {'' + getValue(item, 'startDateRegister', '-')}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {'' + getValue(item, 'startTimeRegister', '-')}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {'' + getValue(item, 'endDateRegister', '-')}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Truncate>
                    {'' + getValue(item, 'endTimeRegister', '-')}
                  </Truncate>
                </TableCell>
              </StyledTableData>
            ))}
          </TableBody>
        </StyledTable>
        {successCount > 0 && successCount != _.size(result) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label="เพิ่มรายการที่สามารถเพิ่มได้"
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(
                handleSubmitAdd(
                  uploadResult,
                  timeList,
                  eExamType,
                  associationList,
                  companyList,
                ),
              )
            }
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายการ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">สามารถเพิ่มตารางสอบได้ทั้งหมด</Typography>
    </Box>
  )
}
