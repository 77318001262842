import axios from 'axios'

let axiosInstances = {}

const createInstance = () => {
  const controller = new AbortController()
  const customAxiosInstance = {
    controller,
    instance: axios.create({ signal: controller.signal }),
  }
  return customAxiosInstance
}

export const getInstance = (pathname) => {
  if (
    axiosInstances[pathname] &&
    !axiosInstances[pathname].controller.signal.aborted
  ) {
    return axiosInstances[pathname].instance
  } else {
    const customAxiosInstance = createInstance()
    axiosInstances[pathname] = customAxiosInstance
    return axiosInstances[pathname].instance
  }
}

export const api = async (method, url, data, headers) => {
  const instance = getInstance(window.location.pathname)

  const res = await instance({
    method,
    url,
    data,
    headers,
  })

  return res
}

const callAxios = {
  get: async (url) => await api('get', url),
  post: async (url, data, headers) => await api('post', url, data, headers),
  put: async (url, data) => await api('put', url, data),
  delete: async (url, data) => await api('delete', url, data),
}

export default callAxios

export const cancelPreviousPageRequests = (previousPath) => {
  axiosInstances[previousPath]?.controller?.abort()
}
