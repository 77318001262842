import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/system/styled'

export const BoxAnswer = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: type == 'MULTIPLE' ? 'column' : 'row',
  },
}))

export const ContentInput = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'start',
})

export const ContentScoreAndButton = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  gap: 1.5,
  width: 'auto',
  '& > .MuiBox-root:last-child': { marginLeft: 0 },
  [theme.breakpoints.down('sm')]: {
    width: type == 'MULTIPLE' ? '100%' : 'auto',
    paddingLeft: type == 'MULTIPLE' ? theme.spacing(5.5) : 0,
    justifyContent: 'space-between',
  },
}))

export const BoxIcon = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  marginLeft: theme.spacing(2),
  gap: theme.spacing(3),
  justifyContent: 'flex-end',
  width: '117px',
  alignItems: type === 'MULTIPLE' ? 'start' : 'center',
  marginTop: type === 'SINGLE' ? theme.spacing(2.5) : 0,
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    width: 'auto',
  },
}))

export const BoxCheckbox = styled(Checkbox)(({ theme }) => ({
  marginLeft: theme.spacing(-0.5),
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme?.palette?.action?.active,
  marginTop: theme.spacing(1),
  ':hover': {
    background: theme?.palette?.primary?.outlinedHoverBackground,
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(0.5),
  },
}))

export const BoxScoreAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: 100,
  maxWidth: 120,
  marginLeft: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}))

export const RadioAnswer = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(-0.5),
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1),
  },
}))
