import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'

import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector, shallowEqual } from 'react-redux'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Divider from '@mui/material/Divider'

import { draftQuestionAssessment } from '../../../../..//redux/slices/eTestingForm'
import { onDragEnd, handleAddAssessment } from './event'

import { BoxForm } from '../Styled'
import { BoxAddQuestion } from './Styled'

import Questions from './Questions'
import { handleAlertErrorAssessment } from '../AlertErrorBox/events'
import { useFormikContext } from 'formik'

const Index = () => {
  const dispatch = useDispatch()
  const listQuestion = useSelector(draftQuestionAssessment, shallowEqual)
  const onViewMode = useSelector(
    (state) => state.eTestingForm.onViewMode,
    shallowEqual
  )
  const { errors } = useFormikContext()

  return (
    <>
      <Card>
        {listQuestion.length > 0 && (
          <BoxForm>
            <DragDropContext onDragEnd={(e) => dispatch(onDragEnd(e))}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {listQuestion.map((item, indexQuestion) => (
                      <>
                        {handleAlertErrorAssessment(errors, indexQuestion)}
                        <Questions
                          key={item}
                          keyQuestion={item}
                          indexQuestion={indexQuestion}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Divider sx={{ mt: 2 }} />
          </BoxForm>
        )}
        <BoxAddQuestion isQuestion={!!listQuestion.length}>
          <Button
            data-testid="btn-add-assessment"
            variant="contained"
            size="m"
            startIcon={<AddCircleOutlineIcon />}
            disabled={onViewMode}
            onClick={() => dispatch(handleAddAssessment(listQuestion.length))}
          >
            เพิ่มคำถาม
          </Button>
        </BoxAddQuestion>
      </Card>
      <Box sx={{ paddingBottom: 1 }}></Box>
    </>
  )
}
export default Index
