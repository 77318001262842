import React, { useEffect } from 'react'
import Table from '../../../../../components/redux/Table'
import { LoadingCircular, StyledCard } from '../../../Styled'
import { useDispatch, useSelector } from 'react-redux'
import { handleBatchTable } from './events'
import AddDrawer from './AddDrawer'
import FilterDrawer from './FilterDrawer'

const BatchTab = ({ sx }) => {
  const dispatch = useDispatch()
  const {
    table: { isLoading, limit, order, page, sort },
  } = useSelector((state) => state.table)

  useEffect(() => {
    dispatch(handleBatchTable())
  }, [page, sort, limit, order])

  return (
    <>
      <StyledCard isLoading={isLoading} sx={{ ...sx, mt: 0 }}>
        <Table />
      </StyledCard>
      <LoadingCircular isLoading={isLoading} />
      <AddDrawer />
      <FilterDrawer />
    </>
  )
}

export default BatchTab
