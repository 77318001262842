import { Box, Divider, IconButton, Typography } from '@mui/material'
import { StyledCard, StyledColumn, StyledRow } from '../../Styled'

import { useEffect } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import _ from 'lodash'
import { changeWeekHandle, formattedMonth } from './event'
import { StyledDateItemBox } from '../Styled'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  setCurrentDate,
  setSelectedDate,
} from '../../../../redux/slices/roomManagement/Dashboard'

const DateItem = (props) => {
  const dispatch = useDispatch()
  const { date, day, isSelected, dayjsFormat } = props
  return (
    <StyledDateItemBox
      sx={{ cursor: 'pointer' }}
      isSelected={isSelected}
      onClick={() => dispatch(setSelectedDate(dayjsFormat))}
    >
      <Typography sx={{ typography: { sm: 'h5', xs: 'h6' } }}>
        {date}
      </Typography>
      <Typography sx={{ typography: { sm: 'body2', xs: 'body3' } }}>
        {day}
      </Typography>
    </StyledDateItemBox>
  )
}

const BookingItem = (props) => {
  const { roomName, station, bookingTopic, time } = props
  return (
    <StyledColumn sx={{ gap: 1 }}>
      <StyledColumn>
        <StyledRow sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body2b" color="primary.main">
            {roomName}
          </Typography>
          <Typography variant="body2b" color="text.primary">
            {station}
          </Typography>
        </StyledRow>
        <StyledRow sx={{ justifyContent: 'space-between' }}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 300,
            }}
            variant="body3"
            color="text.secondary"
          >
            {bookingTopic}
          </Typography>
          <Typography variant="body3" color="text.secondary">
            {time}
          </Typography>
        </StyledRow>
      </StyledColumn>
      <Divider />
    </StyledColumn>
  )
}

export const WeeklyBookingsCard = () => {
  const dispatch = useDispatch()
  const { currentDate, selectedDate, dates, bookingRoomList } = useSelector(
    (state) => ({
      currentDate: state.roomManagement.currentDate,
      selectedDate: state.roomManagement.selectedDate,
      dates: state.roomManagement.dates,
      bookingRoomList: state.roomManagement.bookingRoomList,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (_.isNull(selectedDate)) {
      const currentDate = new Date()
      dispatch(setCurrentDate(currentDate))
      dispatch(setSelectedDate(currentDate))
    }
  }, [])

  return (
    <StyledCard
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 558,
      }}
    >
      <Typography variant="h5">ห้องที่ใช้งานวันนี้</Typography>
      <Box
        sx={{ borderRadius: 4, backgroundColor: '#E7F8FB', px: 2, py: 1.25 }}
      >
        <StyledRow sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {formattedMonth(currentDate.getMonth() + 1)}
          </Typography>
          <StyledRow>
            <IconButton onClick={() => dispatch(changeWeekHandle('prev'))}>
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={() => dispatch(changeWeekHandle('next'))}>
              <KeyboardArrowRight />
            </IconButton>
          </StyledRow>
        </StyledRow>
        <StyledRow sx={{ justifyContent: 'space-between' }}>
          {_.map(dates, (date, idx) => {
            return <DateItem key={idx} {...date} />
          })}
        </StyledRow>
      </Box>

      {bookingRoomList?.length > 0 ? (
        <StyledColumn sx={{ overflow: 'scroll' }}>
          {_.map(bookingRoomList, (bk, idx) => (
            <BookingItem key={idx} {...bk} />
          ))}
        </StyledColumn>
      ) : (
        <StyledColumn
          sx={{
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <img src={`/image/notify.svg`} width={115} height={115} />
          <Typography
            sx={{ textAlign: 'center' }}
            variant="body2"
            color="text.lightGray"
          >
            ไม่มีห้องที่ใช้งานวันนี้
            <br />
            หากมีผู้จองแล้ว จะแสดงรายการจองที่นี่
          </Typography>
        </StyledColumn>
      )}
    </StyledCard>
  )
}
