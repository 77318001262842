import * as yup from 'yup'

const productSchema = yup
  .object()
  .shape({
    reason: yup.string().nullable().max(3000, 'กรอกได้ไม่เกิน 3,000 ตัวอักษร'),
    acceptReturnQuantity: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
  })
  .test('product-object', 'รายการไม่ถูกต้อง', function (value) {
    const { quantity, acceptReturnQuantity } = value
    const index = this.options.index
    const quantityNumber = Number(String(quantity).replace(/,/g, ''))
    if (acceptReturnQuantity > quantityNumber) {
      return this.createError({
        path: `products[${index}].acceptReturnQuantity`,
        message: 'กรุณาระบุจำนวน ในค่าที่น้อยกว่าจำนวน',
      })
    }
    return true
  })

export const EditApprovedValidation = yup.object({
  products: yup.array().of(productSchema),
})
