import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}))
