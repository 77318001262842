import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import DatePicker from '../../components/Input/DatePicker'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

const defaultFilter = {
  statusChecked: false,
  status: {
    //'WAITING', 'REJECT', 'APPROVE', 'CANCEL', 'DRAFT'
    waitingChecked: false,
    rejectChecked: false,
    approveChecked: false,
    cancelChecked: false,
    draftChecked: false,
  },
  startDateBorrowChecked: false,
  startDateBorrowText: null,
  finishDateBorrowChecked: false,
  finishDateBorrowText: null,
  staffRequestChecked: false,
  staffRequestText: '',
  staffApproveChecked: false,
  staffApproveText: '',
  startDateChecked: false,
  startDateText: null,
  finishDateChecked: false,
  finishDateText: null,
  responsibilityChecked: false,
  responsibility: {},
  departmentChecked: false,
  departmentText: '',
  stationChecked: false,
  stationText: '',
  courseThChecked: false,
  courseThText: '',
  courseEnChecked: false,
  courseEnText: '',
  nameForLearnerChecked: false,
  nameForLearnerText: '',
  trainingPlatformChecked: false,
  trainingPlatform: {},
}
const FilterDrawer = ({ open, onClose, onFilterClick, filterMasterData }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const handleOnTextChange = (e) => {
    const { name, value } = e.target
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleOnDateTextChange = (e, name) => {
    const date = dayjs(e).format(window.__env__.REACT_APP_DATE_DB)
    setFilter({
      ...filter,
      [name]: date,
    })
  }

  const handleChangeArrayByField = (e, field) => {
    const { name, checked } = e.target
    setFilter({
      ...filter,
      [field]: {
        ...filter[field],
        [name]: checked,
      },
    })
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-staff-request-title"
                name="staffRequestChecked"
                defaultChecked
                checked={filter.staffRequestChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'staffRequestChecked',
                  })
                }}
              />
            }
            label="ผู้ขอยืม"
            sx={!filter.staffRequestChecked ? { marginBottom: -1 } : {}}
          />
          {filter.staffRequestChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="staffRequestText"
              type="basic"
              disabled={!filter.staffRequestChecked}
              placeholder="ค้นหาผู้ขอยืม"
              value={filter.staffRequestText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-staff-approve-title"
                name="staffApproveChecked"
                defaultChecked
                checked={filter.staffApproveChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'staffApproveChecked',
                  })
                }}
              />
            }
            label="ผู้อนุมัติ"
            sx={!filter.staffApproveChecked ? { marginBottom: -1 } : {}}
          />
          {filter.staffApproveChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="staffApproveText"
              type="basic"
              disabled={!filter.staffApproveChecked}
              placeholder="ค้นหาผู้อนุมัติ"
              value={filter.staffApproveText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-department-title"
                name="departmentChecked"
                defaultChecked
                checked={filter.departmentChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'departmentChecked',
                  })
                }}
              />
            }
            label="Department"
            sx={!filter.departmentChecked ? { marginBottom: -1 } : {}}
          />
          {filter.departmentChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="departmentText"
              type="basic"
              disabled={!filter.departmentChecked}
              placeholder="ค้นหา Department"
              value={filter.departmentText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-station-title"
                name="stationChecked"
                defaultChecked
                checked={filter.stationChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'stationChecked',
                  })
                }}
              />
            }
            label="Station"
            sx={!filter.stationChecked ? { marginBottom: -1 } : {}}
          />
          {filter.stationChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="stationText"
              type="basic"
              disabled={!filter.stationChecked}
              placeholder="ค้นหา Station"
              value={filter.stationText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-course-th-title"
                name="courseThChecked"
                defaultChecked
                checked={filter.courseThChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'courseThChecked',
                  })
                }}
              />
            }
            label="ชื่อหลักสูตร TH"
            sx={!filter.courseThChecked ? { marginBottom: -1 } : {}}
          />
          {filter.courseThChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="courseThText"
              type="basic"
              disabled={!filter.courseThChecked}
              placeholder="ค้นหาหลักสูตร TH"
              value={filter.courseThText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-course-en-title"
                name="courseEnChecked"
                defaultChecked
                checked={filter.courseEnChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'courseEnChecked',
                  })
                }}
              />
            }
            label="ชื่อหลักสูตร EN"
            sx={!filter.courseEnChecked ? { marginBottom: -1 } : {}}
          />
          {filter.courseEnChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="courseEnText"
              type="basic"
              disabled={!filter.courseEnChecked}
              placeholder="ค้นหาหลักสูตร EN"
              value={filter.courseEnText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-name-for-learner"
                name="nameForLearnerChecked"
                defaultChecked
                checked={filter.nameForLearnerChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'nameForLearnerChecked',
                  })
                }}
              />
            }
            label="ชื่อหลักสูตร (สำหรับ Learner)"
            sx={!filter.nameForLearnerChecked ? { marginBottom: -1 } : {}}
          />
          {filter.nameForLearnerChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              name="nameForLearnerText"
              type="basic"
              disabled={!filter.nameForLearnerChecked}
              placeholder="ค้นหาหลักสูตร (สำหรับ Learner)"
              value={filter.nameForLearnerText}
              onChange={handleOnTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'startDateChecked',
                }}
                name="startDateChecked"
                checked={filter.startDateChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'startDateChecked',
                  })
                }}
              />
            }
            label="วันเริ่มต้นจัดอบรม"
            sx={!filter.startDateChecked ? { marginBottom: -1 } : {}}
          />
          {filter.startDateChecked && (
            <>
              <DatePicker
                sx={{ borderRadius: 0 }}
                disabled={!filter.startDateChecked}
                id={'startDateText'}
                name={'startDateText'}
                labelText={''}
                onChange={(e) => handleOnDateTextChange(e, 'startDateText')}
                value={filter.startDateText}
                hideTextError={true}
              />
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'finishDateChecked',
                }}
                name="finishDateChecked"
                checked={filter.finishDateChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'finishDateChecked',
                  })
                }}
              />
            }
            label="วันสิ้นสุดจัดอบรม"
            sx={!filter.startDateChecked ? { marginBottom: -1 } : {}}
          />
          {filter.finishDateChecked && (
            <>
              <DatePicker
                sx={{ borderRadius: 0 }}
                disabled={!filter.finishDateChecked}
                id={'finishDateText'}
                name={'finishDateText'}
                labelText={''}
                onChange={(e) => handleOnDateTextChange(e, 'finishDateText')}
                value={filter.finishDateText}
                hideTextError={true}
              />
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'startDateBorrowChecked',
                }}
                name="startDateBorrowChecked"
                checked={filter.startDateBorrowChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'startDateBorrowChecked',
                  })
                }}
              />
            }
            label="วันเริ่มต้นยืมวิทยากร"
            sx={!filter.startDateBorrowChecked ? { marginBottom: -1 } : {}}
          />
          {filter.startDateBorrowChecked && (
            <>
              <DatePicker
                sx={{ borderRadius: 0 }}
                disabled={!filter.startDateBorrowChecked}
                id={'startDateBorrowText'}
                name={'startDateBorrowText'}
                labelText={''}
                onChange={(e) =>
                  handleOnDateTextChange(e, 'startDateBorrowText')
                }
                value={filter.startDateBorrowText}
                hideTextError={true}
              />
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'finishDateBorrowChecked',
                }}
                name="finishDateBorrowChecked"
                checked={filter.finishDateBorrowChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'finishDateBorrowChecked',
                  })
                }}
              />
            }
            label="วันสิ้นสุดยืมวิทยากร"
            sx={!filter.startDateBorrowChecked ? { marginBottom: -1 } : {}}
          />
          {filter.finishDateBorrowChecked && (
            <>
              <DatePicker
                sx={{ borderRadius: 0 }}
                disabled={!filter.finishDateBorrowChecked}
                id={'finishDateBorrowText'}
                name={'finishDateBorrowText'}
                labelText={''}
                onChange={(e) =>
                  handleOnDateTextChange(e, 'finishDateBorrowText')
                }
                value={filter.finishDateBorrowText}
                hideTextError={true}
              />
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="หน้าที่"
          control={
            <Checkbox
              data-testid="checkbox-reponsibility"
              name="responsibilityChecked"
              checked={filter.responsibilityChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'responsibilityChecked',
                })
              }}
            />
          }
        />
        {filter.responsibilityChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            {filterMasterData.responsibility.map((item) => {
              return (
                <FormControlLabel
                  key={`checkbox-responsibility-${item.id}`}
                  label={item.label}
                  control={
                    <Checkbox
                      data-testid={`checkbox-responsibility-${item.id}`}
                      name={item.label}
                      checked={_.get(
                        filter,
                        `responsibility[${item.label}]`,
                        false
                      )}
                      onChange={(e) =>
                        handleChangeArrayByField(e, 'responsibility')
                      }
                    />
                  }
                />
              )
            })}
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="รูปแบบการสอน"
          control={
            <Checkbox
              data-testid="checkbox-training-platform"
              name="trainingPlatformChecked"
              checked={filter.trainingPlatformChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'trainingPlatformChecked',
                })
              }}
            />
          }
        />
        {filter.trainingPlatformChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            {filterMasterData.trainingPlatform.map((item) => {
              return (
                <FormControlLabel
                  key={`checkbox-training-platform-${item.id}`}
                  label={item.label}
                  control={
                    <Checkbox
                      data-testid={`checkbox-training-platform-${item.id}`}
                      name={item.label}
                      checked={_.get(
                        filter,
                        `trainingPlatform[${item.label}]`,
                        false
                      )}
                      onChange={(e) =>
                        handleChangeArrayByField(e, 'trainingPlatform')
                      }
                    />
                  }
                />
              )
            })}
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              data-testid="checkbox-speaker-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              label="รออนุมัติ"
              control={
                <Checkbox
                  data-testid="checkbox-status-waiting"
                  name="waitingChecked"
                  checked={filter.status.waitingChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="ไม่อนุมัติ"
              control={
                <Checkbox
                  data-testid="checkbox-status-reject"
                  name="rejectChecked"
                  checked={filter.status.rejectChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="อนุมัติ"
              control={
                <Checkbox
                  data-testid="checkbox-status-approve"
                  name="approveChecked"
                  checked={filter.status.approveChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="ยกเลิก"
              control={
                <Checkbox
                  data-testid="checkbox-status-cancel"
                  name="cancelChecked"
                  checked={filter.status.cancelChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
            <FormControlLabel
              label="ฉบับร่าง"
              control={
                <Checkbox
                  data-testid="checkbox-status-draft"
                  name="draftChecked"
                  checked={filter.status.draftChecked}
                  onChange={(e) => handleChangeArrayByField(e, 'status')}
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
