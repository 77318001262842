import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const InputUpload = styled('input')({
  display: 'none',
})

export const BoxDocuments = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

export const BoxBorder = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
}))

export const BoxDocumentHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(2),
}))

export const BoxDocumentContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${theme.spacing(3)} ${theme.spacing(2)}`,
}))

export const BoxIconActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 124,
  gap: theme.spacing(1),
}))
