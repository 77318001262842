import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import TableReportRow from './TableReportRow'
import NotFoundData from './NotFoundData'
import { ROW_PAGE_PER_LIST } from '../../../../constants/table'
import { TableCellHeader } from './TableCellHeader'
import { handleChangeRowsPerPage } from '../../handler/handleChangeRowsPerPage'
import { handleChangePage } from '../../handler/handleChangePage'

const TableReport = () => {
  const {
    columns,
    reportsData,
    tablePage,
    page,
    rowsPerPage,
    allCount,
    moduleId,
  } = useSelector(
    (state) => ({
      columns: state.reportComp.columns,
      reportsData: state.reportComp.reportsData,
      tablePage: state.reportComp.tablePage,
      page: state.reportComp.page,
      rowsPerPage: state.reportComp.rowsPerPage,
      allCount: state.reportComp.allCount,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader>
          <TableHead sx={{ whiteSpace: 'nowrap' }}>
            <TableRow>
              {_.map(columns, (column, idx) => (
                <TableCellHeader column={column} key={idx} />
              ))}
              {reportsData?.length > 0 && moduleId === 'AUDIT_LOG' && (
                <TableCellHeader
                  column={{
                    id: 1,
                    name: 'link_button',
                    label: '',
                    minWidth: 36,
                  }}
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsData?.length > 0 ? (
              _.map(reportsData, (report, idx) => (
                <TableReportRow report={report} key={idx} />
              ))
            ) : (
              <NotFoundData />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={_.filter(
          ROW_PAGE_PER_LIST,
          (item) => item.value !== -1,
        )}
        component="div"
        count={allCount}
        rowsPerPage={rowsPerPage.value}
        page={tablePage < 0 ? 1 : +tablePage}
        labelRowsPerPage={
          <Typography variant="caption" color="text.secondary">
            แถวต่อหน้า :{' '}
          </Typography>
        }
        labelDisplayedRows={() => {
          const realPage = tablePage <= 0 ? 1 : tablePage + 1
          let minRowsShowed = page < 0 ? 1 : rowsPerPage.value * tablePage + 1
          let maxRowsShowed =
            rowsPerPage.value * realPage > allCount
              ? allCount
              : rowsPerPage.value * realPage

          if (maxRowsShowed < 0) {
            maxRowsShowed = allCount
          }
          if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

          return (
            <Typography variant="caption">
              {minRowsShowed}-{maxRowsShowed} of {allCount}
            </Typography>
          )
        }}
        onPageChange={(e, newPage) => handleChangePage(e, newPage)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
      />
    </Paper>
  )
}

export default TableReport
