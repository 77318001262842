import _ from 'lodash'
import { store } from '../../../App'
import { getOptionsReportByKey } from '../../../services/reportComp'

export const fetchCourseCodeList = async () => {
  const { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      body: {
        columnSelected: 'courseCode',
      },
      modulePath: '/monthly-plan',
    }),
  )
  return _.get(response, 'results', [])
}
