import _ from 'lodash'
import { eLearningModuleDownloadFile } from '../../../../../utils/apiPath'
import { downloadGetFile } from '../../../../../services/util'

export const formatDurationToMinute = (duration) => {
  if (_.isNil(duration)) return '00:00'
  const date = new Date(duration.toFixed() * 1000)

  const hours = date.getUTCHours()
  const minutes = padTo2Digits(date.getUTCMinutes())
  const seconds = padTo2Digits(date.getUTCSeconds())

  if (hours) {
    return `${hours}:${minutes}:${seconds}`
  } else return `${minutes}:${seconds}`
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export const handleDownloadFile = (file) => (dispatch) => {
  const filePath = _.get(file, 'file', '').replace('/e-learning/module/', '')
  const fileName = _.get(file, 'displayName', _.get(file, 'oldFileName', ''))
  const url = `${eLearningModuleDownloadFile}/${filePath}`
  dispatch(downloadGetFile({ url, fileName }))
}
