import axios from 'axios'
import { apiToRcmsAgentDetail } from '../../../utils/apiPath'
import {
  setFetchAgentDetail,
  startLoading,
  stopLoading,
} from '../../../redux/slices/apiToRcms'

export const fetchDataList = (method, id) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    agentUuid: id,
  }

  await axios.post(apiToRcmsAgentDetail, body).then(({ data }) => {
    dispatch(setFetchAgentDetail(data))
  })

  dispatch(stopLoading())
}
