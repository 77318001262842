import React from 'react'
import DatePicker from './DatePicker'
import Box from '@mui/material/Box'
import _ from 'lodash'

export default function DatePickerForm({
  state,
  stateData,
  validate,
  handleChangeForm,
  setStateForm,
  type,
  setValidateForm,
  options,
}) {
  const allDate = _.get(state, 'dates', [])
  let findSpeakerBorrow = {}
  if (allDate.length > 0) {
    findSpeakerBorrow = allDate.find((item) => {
      const speakerBorrow = _.get(item, 'speakerBorrow', [])
      if (speakerBorrow.length > 0) {
        const findBorrow = speakerBorrow.find((borrow) => {
          return borrow?.status === 'WAITING_APPROVE_BORROW'
        })
        return findBorrow || {}
      }
    })
  }

  const checkBorrowExist = _.find(
    _.get(state, 'dates[0].speakers', []),
    (item) => {
      return _.get(item, 'staff.speakerBorrow', false)
    }
  )

  const borrowSpeaker = _.get(findSpeakerBorrow, 'speakerBorrow', [])
  const findBorrowApprove = _.filter(
    borrowSpeaker,
    (item) => item.status === 'APPROVE'
  )

  const isBorrowApproved =
    findSpeakerBorrow && findBorrowApprove.length > 0 && checkBorrowExist
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}
    >
      <DatePicker
        id="startDate"
        name="startDate"
        labelText="ตั้งแต่วันที่"
        disabledMonthlyPlan={
          type === 'edit' ? undefined : _.get(stateData, 'data', undefined)
        }
        required
        onChange={(value) =>
          handleChangeForm({
            key: 'startDate',
            value,
            stateForm: state,
            setStateForm,
            type,
            setValidateForm,
            options,
          })
        }
        value={
          _.get(state, 'startDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'startDate', null)
        }
        textError={_.get(validate, 'startDate', '')}
        isShouldDisableDate={true}
        disabledStartDate={
          _.get(state, 'startDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'startDate', null)
        }
        disabledEndDate={
          _.get(state, 'finishDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'finishDate', null)
        }
        disabledInput={isBorrowApproved}
        hideTextError={_.get(validate, 'startDate', '') ? false : true}
      />

      <DatePicker
        id="finishDate"
        name="finishDate"
        labelText="ถึงวันที่"
        disabledMonthlyPlan={
          type === 'edit' ? undefined : _.get(stateData, 'data', undefined)
        }
        required
        isShouldDisableDate={true}
        onChange={(value) =>
          handleChangeForm({
            key: 'finishDate',
            value,
            stateForm: state,
            setStateForm,
            type,
            setValidateForm,
            options,
          })
        }
        value={
          _.get(state, 'finishDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'finishDate', null)
        }
        textError={_.get(validate, 'finishDate', '')}
        disabledStartDate={
          _.get(state, 'startDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'startDate', null)
        }
        disabledEndDate={
          _.get(state, 'finishDate', null) === 'Invalid Date'
            ? null
            : _.get(state, 'finishDate', null)
        }
        disabledInput={isBorrowApproved}
        hideTextError={_.get(validate, 'finishDate', '') ? false : true}
      />
    </Box>
  )
}
