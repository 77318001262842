import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import TextInput from '../../components/Input/TextInput'
import SwitchInput from '../../components/Input/SwitchInput'
import { hasPermissionCreateMasterData } from '../../utils/lib'

const ProductTypeForm = (props) => {
  const { isEditForm, formik, initialValues, setInitialValues } = props
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const theme = useTheme()
  const history = useHistory()

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              boxSx={{ mx: 3 }}
              value={initialValues.id}
            ></TextInput>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',

            [theme.breakpoints.down('sm')]: {
              display: 'block',
              flexDirection: 'unset',
              justifyContent: 'unset',
            },
          }}
        >
          <TextInput
            id="acquiredSkill"
            name="acquiredSkill"
            labelText="ระดับหลักสูตร"
            type="basic"
            textError={formik?.errors?.acquiredSkill}
            required
            disabled={!hasPermissionCreate}
            boxSx={{
              mx: 3,
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            }}
            value={initialValues.acquiredSkill}
            onChange={(e) => {
              formik.values.acquiredSkill = e.target.value
              setInitialValues({
                ...initialValues,
                acquiredSkill: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            defaultChecked
            disabled={!hasPermissionCreate}
            value={initialValues.status}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
            boxSx={{
              mx: 3,
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            }}
          ></SwitchInput>
        </Box>
        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>
          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย{' '}
                {initialValues && initialValues.updatedBy
                  ? initialValues.updatedBy
                  : ''}
                <br />
                {initialValues && initialValues.updatedAt
                  ? initialValues.updatedAt
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
      </form>
    </Card>
  )
}
export default ProductTypeForm
