import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'

import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import DateRangeInput from '../../../../components/DateRangeInput'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../components/FilterDrawer/Styled'
import { handleNumberInput } from '../../../../utils/lib'

import {
  arrayStatus,
  arrayCheckbox,
  bottomArrayCheckbox,
  defaultFilter,
} from './model'
import * as events from './events'

export const ListCheckBox = ({ listArray, stateFilter, setFilter }) => {
  const getDateState = (item) => {
    return {
      startDate: stateFilter[item.dateStateStart]
        ? stateFilter[item.dateStateStart]
        : new Date(),
      endDate: stateFilter[item.dateStateFinish]
        ? stateFilter[item.dateStateFinish]
        : new Date(),
      display: _.get(stateFilter, item.dateStateDisplay, false),
      key: 'selection',
    }
  }
  return listArray.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {!item.isDate && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={
                    item.nameInput == 'version'
                      ? {
                          'data-testid': `input-${item.nameInput}`,
                          type: 'number',
                          min: '0',
                          onInput: handleNumberInput,
                        }
                      : { 'data-testid': `input-${item.nameInput}` }
                  }
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.isDate && (
                <DateRangeInput
                  dateState={getDateState(item)}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter,
                      item,
                    )
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListStatus = ({
  listKey,
  status,
  filter,
  setFilter,
  arrayList,
}) => {
  return arrayList.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(status, item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKey,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open }) => {
  const dispatch = useDispatch()
  const { tableFilter, listTrainingPlan } = useSelector(
    (state) => ({
      tableFilter: state.table.filter,
      listTrainingPlan: state.manageClass.listTrainingPlan,
    }),
    shallowEqual,
  )
  const [filter, setFilter] = useState(
    _.get(tableFilter, 'filterState', defaultFilter),
  )
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    const newFilter = dispatch(
      events.handleInitialFilter(tableFilter, filterMemo),
    )
    setFilter(newFilter)
    setFilterMemo(newFilter)
  }, [tableFilter.filterProp])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <StyledList>
            <ListStatus
              arrayList={arrayStatus}
              listKey="status"
              status={filter.status}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="รอตรวจเอกสาร"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-checkDocuments',
              }}
              name="checkDocuments"
              checked={filter.checkDocuments}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
      </StyledGroupBox>
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="รอตรวจข้อสอบ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-waitingExam',
              }}
              name="waitingExam"
              checked={filter.waitingExam}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
      </StyledGroupBox>
      <ListCheckBox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="รูปแบบการสอน"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-trainingPlatFormChecked',
              }}
              name="trainingPlatFormChecked"
              checked={filter.trainingPlatFormChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.trainingPlatFormChecked && (
          <StyledList>
            <ListStatus
              arrayList={listTrainingPlan}
              listKey="trainingPlatForm"
              status={filter.trainingPlatForm}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <ListCheckBox
        listArray={bottomArrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            const props = { filter, tableFilter, listTrainingPlan }
            setFilterMemo(filter)
            dispatch(events.handleFiltering(props))
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
