import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../components/Breadcrumbs'
import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import TebMenu from './TabMenu'
import * as event from './event'
import {
  breadcrumbCreateVersion,
  breadcrumbVersionEdit,
  breadcrumbViewVersion,
} from '../model'
import { useEffect } from 'react'
import {
  LoadingPage,
  StyledCard,
  StyledContainer,
  StyledContent,
} from '../Styled'
import Step from './Step'
import { useParams } from 'react-router-dom'
import Header from './Header/index'
import Menu from './Menu'
import { EditByDisplay } from './CertificateForm/Styled'
import _ from 'lodash'
import {
  E_CERTIFICATE_SETTING,
  PERMISSION,
} from '../../../constants/eCertification'
import { validateEPermission } from '../../../utils/lib'
import * as reducer from '../../../redux/slices/eCertificateForm'

function Index({ isEditForm, isClone, isView }) {
  const { id: uuid } = useParams()
  const [activeStep, setActiveStep] = React.useState(0)
  const dispatch = useDispatch()
  const { isLoading, formCodeId, updatedBy, updatedAt, formName } = useSelector(
    (state) => ({
      isLoading: state.eCertificateForm.isLoading,
      formCodeId: state.eCertificateForm.formCodeId,
      updatedBy: state.eCertificateForm.updatedBy,
      updatedAt: state.eCertificateForm.updatedAt,
      formName: state.eCertificateForm.formName,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(reducer.setClearData())
    dispatch(event.fetchDataForm({ uuid: uuid }))
  }, [])

  const hasCreatePermission = validateEPermission({
    module: E_CERTIFICATE_SETTING,
    permission: PERMISSION.CREATE,
  })

  useEffect(() => {
    if (!isEditForm) {
      dispatch(reducer.setClearData())
    }
  }, [])

  const getBreadcrumb = () => {
    if (isEditForm) {
      let tempBreadcrumbVersionEdit = [...breadcrumbVersionEdit]
      tempBreadcrumbVersionEdit[1] = {
        ...breadcrumbVersionEdit[1],
        link: `/e-certification/version/${formCodeId}`,
      }
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {tempBreadcrumbVersionEdit[2].title}
          </Typography>
          <Breadcrumbs menuList={tempBreadcrumbVersionEdit} />
        </Box>
      )
    } else if (isView) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{formName}</Typography>
          <Breadcrumbs menuList={breadcrumbViewVersion} />
        </Box>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {breadcrumbCreateVersion[1].title}
          </Typography>
          <Breadcrumbs menuList={breadcrumbCreateVersion} />
        </Box>
      )
    }
  }

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          {getBreadcrumb()}
          {(isEditForm || isView) && (
            <Header activeStep={activeStep} uuid={uuid} isView={isView} />
          )}
        </Box>
        {hasCreatePermission && isEditForm && <TebMenu />}
        {!isView && hasCreatePermission && (
          <Step
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            isEditForm={isEditForm}
          />
        )}

        <StyledCard>
          <Menu
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            isEditForm={isEditForm}
            isClone={isClone}
            isView={isView}
          />
          {isEditForm && !_.isEmpty(updatedBy) && (
            <EditByDisplay>
              <Typography color="text.gray" variant="caption">
                แก้ไขล่าสุดโดย {_.get(updatedBy, 'name', '-')} {updatedAt}
              </Typography>
            </EditByDisplay>
          )}
        </StyledCard>
      </StyledContent>
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Index
