import axios from 'axios'
import { trainingPlanGetQRForEnroll } from '../../../../utils/apiPath'
import {
  setError,
  setQREnrollProps,
  setStatusQRCode,
  startLoadingQRDrawer,
  stopLoadingQRDrawer,
} from '../../../../redux/slices/manageClassDetail'
import _ from 'lodash'
import { EXPIRY_TYPE } from '../../../../constants/manageClass/qrCodeForEnroll'
import { formatDate, formatTime } from './QREnrollDrawer/events'
import dayjs from 'dayjs'
import { store } from '../../../../App'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const getQRForEnroll = (uuid) => async (dispatch) => {
  dispatch(startLoadingQRDrawer())
  await axios
    .get(`${trainingPlanGetQRForEnroll}/${uuid}`)
    .then(({ data }) => {
      const { link, expiryDate, expiryType } = data

      const enrollProps = prepareDataForEnroll(link, expiryDate, expiryType)
      dispatch(setQREnrollProps(enrollProps))
      dispatch(handleStatusQR())
    })
    .catch((e) => {
      const constraints = _.get(e, 'response.data.constraints', '')
      const { key, title } = constraints
      if (key === 'InternalServerError' || key === 'TrainingPlanNotFound') {
        dispatch(setError({ iserror: true, message: title }))
      }
    })
    .finally(() => dispatch(stopLoadingQRDrawer()))
}

export const handleQrExpired = (expDate) => {
  const currentDateTime = dayjs().toDate()
  const expiryDate = dayjs(expDate).toDate()
  if (expiryDate.getTime() < currentDateTime.getTime()) {
    return true
  }
}

export const prepareDataForEnroll = (
  link,
  expiryDate,
  expiryType,
  isMobile
) => {
  return {
    link: link,
    expiryDate: !_.isNull(expiryDate) ? formatDate(expiryDate) : null,
    expiryTime: !_.isNull(expiryDate) ? formatTime(expiryDate) : null,
    isEnable: !_.isNull(link),
    expiryType: _.isNull(expiryType) ? EXPIRY_TYPE.NO_EXPIRY : expiryType,
    qrExpired: EXPIRY_TYPE.HAS_EXPIRY ? handleQrExpired(expiryDate) : false,
    isMobile: isMobile,
  }
}

export const handleCopyLink = (manageClassData, uuid) => {
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const trainingInfo = _.get(monthlyPlan, 'trainingInfo[0]', '')
  const course = _.get(trainingInfo, 'course', '')
  const courseUuid = _.get(course, 'uuid', '')
  const link = `${window.__env__.REACT_APP_LEARNER_URL}/course/${courseUuid}/class/${uuid}`
  navigator?.clipboard?.writeText(link)
}

export const handleEnrollClass = (uuid, handleOpenQrDrawer) => (dispatch) => {
  dispatch(getQRForEnroll(uuid))
  handleOpenQrDrawer((prevOpen) => !prevOpen)
}

export const handleStatusQR = () => (dispatch) => {
  const qrEnrollProps = store.getState().manageClassDetail.qrEnrollProps
  const { expiryType, isEnable, qrExpired, link } = qrEnrollProps
  if (!_.isNull(link)) {
    switch (expiryType) {
      case EXPIRY_TYPE.NO_EXPIRY:
        dispatch(setStatusQRCode(isEnable))
        break
      case EXPIRY_TYPE.HAS_EXPIRY:
        dispatch(setStatusQRCode(isEnable && !qrExpired))
        break
    }
  } else {
    dispatch(setStatusQRCode(false))
  }
}

export const handleCloseDrawer = (setOpenQREnrollDrawer) => (dispatch) => {
  dispatch(handleStatusQR())
  setOpenQREnrollDrawer((prevOpen) => !prevOpen)
}
