import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { setLevelDistribution } from '../../../../../../redux/slices/manageContent/elearning'
import MainTabs from '../../../../components/MainTabs'
import { StyledColumn } from '../../../Styled'
import { SECTION_OBJ } from '../../../model'
import { StyledSection, StyledBoxContent, StyledDivider } from '../../Styled'
import { handleSelector } from '../../events'
import TabContent from '../TabContent'

const SectionCard = ({ keySection }) => {
  const { sectionNo, title, keyInfo, keyDistribution } = SECTION_OBJ[keySection]
  const dispatch = useDispatch()
  const { data, levelDistribution } = useSelector(
    (state) => ({
      data: handleSelector(state, keyInfo),
      levelDistribution:
        state.manageContentELearningHome.levelDistribution[keyInfo],
    }),
    shallowEqual,
  )
  const tabsTitle = data.map((item) => ({
    label: `${item.level?.title} : ${item.distribution?.abbreviation}`,
    value: item.uuid,
  }))
  const tabIndex = useMemo(
    () => data.findIndex((item) => item.uuid == levelDistribution),
    [levelDistribution],
  )

  useEffect(() => {
    localStorage.setItem(keyDistribution, levelDistribution)
  }, [])
  if (_.isEmpty(levelDistribution)) return <></>

  return (
    <StyledSection>
      <Typography variant="h6">
        Section {sectionNo} | {title}
      </Typography>

      <StyledBoxContent>
        <MainTabs
          tabs={tabsTitle}
          value={levelDistribution}
          onChange={(_, tab) => {
            dispatch(setLevelDistribution({ key: keyInfo, value: tab }))
          }}
        />
        <Typography textAlign="center" color="text.secondary" variant="h4">
          {_.get(tabsTitle, `[${tabIndex}].label`, '')}
        </Typography>

        {[...Array(3)].map((item, index) => (
          <StyledColumn key={index} gap={3}>
            <StyledDivider />
            <TabContent
              keySection={keySection}
              tabIndex={tabIndex}
              keyTab={`${keyInfo}[${tabIndex}]`}
              index={index}
            />
          </StyledColumn>
        ))}
      </StyledBoxContent>
    </StyledSection>
  )
}

export default SectionCard
