import React from 'react'
import { StyledDivider } from '../../../../components/View/Styled'
import Detail from './Detail'
import Courses from './Courses'

const LearningPath = () => {
  return (
    <>
      <Detail />

      <StyledDivider />

      <Courses />
    </>
  )
}

export default LearningPath
