import {
  backgroundStatus,
  defaultValue,
} from '../../../constants/eCertification'

export const initialState = {
  codeId: '',
  uuid: '',
  name: '',
  imageKey: '',
  status: backgroundStatus.ACTIVE,
  isDelete: false,
  isLoading: false,
  createdBy: {
    uuid: '',
    name: '',
    email: '',
  },
  createdAt: '',
  defaultTap: defaultValue.defaultTap.certificate,
  updatedBy: {
    uuid: '',
    name: '',
    email: '',
  },
  updatedAt: '',
  layouts: {
    totalCount: 0,
    result: [],
  },
  assignees: [],
  listAssignee: [],
  activeDate: '',
  backgrounds: {
    totalCount: 0,
    result: [],
  },
  formName: '',
  formCodeId: '',
  formVersion: '',
  formReasonEdit: '',
  formLayout: {
    id: 0,
    name: '',
    layouts: [],
  },
  formBackground: {
    uuid: '',
    name: '',
    imageKey: '',
  },
  courseVersion: [],
  errors: {
    name: '',
    layout: '',
    background: '',
  },
}
