import React from 'react'
import Typography from '@mui/material/Typography'
import { convertFormatDateTime } from '../../../../../../modules/EEvaluation/Preview/Evaluate/Header/Round/events'
import { isMediaWidthMoreThan } from '../../../../../../utils/lib'
import { responsiveText, StyledRoundContainer } from './Styled'

export const Round = () => {
  const isMediaLg = isMediaWidthMoreThan('md')
  const textVariant = responsiveText(isMediaLg)
  const date = convertFormatDateTime(new Date())
  return (
    <StyledRoundContainer>
      <Typography variant={textVariant} color="text.white">
        สอบครั้งที่ -
      </Typography>
      <Typography variant={textVariant} color="text.white">
        {date} น.
      </Typography>
    </StyledRoundContainer>
  )
}

export default Round
