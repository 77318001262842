import _ from 'lodash'
import { approveStatus } from '../../../../../constants/monthlyPlan/speaker'

export const handleDisableNext = (values) => {
  return (
    _.isEmpty(values.department) ||
    _.isEmpty(values.station) ||
    _.isEmpty(values.purpose)
  )
}

export const handleStatusChange = (setFieldValue, values, status) => {
  const statusRespo = _.get(values, 'speakerBorrowResponsibility', []).map(
    (item) => {
      if (item.status === approveStatus.DELETED) return item
      return { ...item, status }
    }
  )
  setFieldValue('status', status)
  setFieldValue('speakerBorrowResponsibility', statusRespo)
}
