import _ from 'lodash'
import { store } from '../../../../../App'
import { RequisitionStatus } from '../../../../../constants/stock'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../../redux/slices/crud'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { viewTypeEnum } from '../enum/viewTypeEnum'

export const setAssets = async () => {
  const { formData, viewType } = store.getState().crud
  const assets = _.get(formData, 'assets', [])
  const monthlyPlan = _.get(formData, 'monthlyPlan', null)
  let isOverDueDate = false
  const modifiedAssets = await assets.map((item) => {
    let returnDate = ''
    let returnDateColor = ''
    const product = _.get(item, 'product')
    if (
      [
        RequisitionStatus.WAITING_RETURN,
        RequisitionStatus.WAITING_RETURN_APPROVE,
      ].includes(item?.withdrawStatus)
    ) {
      let returnDateDays = _.get(item, 'returnDateDays', null)
      const refundDay = _.get(product, 'refundDay', 0)
      const refundMonth = _.get(product, 'refundMonth', 0)
      const refundYear = _.get(product, 'refundYear', 0)
      if (
        !monthlyPlan &&
        (_.get(product, 'statusFlagReturn', '') === 'INACTIVE' ||
          !_.get(product, 'statusFlagReturn', '') ||
          (!refundDay && !refundMonth && !refundYear))
      ) {
        returnDate = ``
        returnDateColor = ''
      } else if (!returnDateDays?.isOverDue) {
        returnDate = `เหลือ (${String(returnDateDays?.days ?? '-')} วัน)`
        returnDateColor = '#FFB547'
      } else {
        returnDate = `เกิน (${String(returnDateDays?.days).replace(
          /-/g,
          '',
        )} วัน)`
        returnDateColor = '#C91432'
        isOverDueDate = true
      }
    }

    if (
      !monthlyPlan &&
      [RequisitionStatus.WAITING_RETURN].includes(item?.withdrawStatus) &&
      _.get(product, 'statusFlagReturn', '') === 'ACTIVE' &&
      !_.get(product, 'refundDay') &&
      !_.get(product, 'refundMonth') &&
      !_.get(product, 'refundYear')
    ) {
      isOverDueDate = true
    }

    let returnQty = _.get(item, 'returnQty', '')
    if (viewType === viewTypeEnum.RETURN) {
      returnQty =
        _.get(product, 'statusFlagReturn') === 'ACTIVE'
          ? _.get(item, 'qty', '')
          : _.get(item, 'returnQty', '')
    }
    return { ...item, returnDate, returnDateColor, returnQty }
  })
  store.dispatch(
    setFieldValue({
      key: 'formData.assets',
      value: modifiedAssets,
    }),
  )
  const sentMailAt = _.get(formData, 'sentMailAt')
  let hasSentMailToday = false
  if (isOverDueDate && sentMailAt) {
    //check if sent today
    const checkIfSentToday = calculateReturnDate(
      sentMailAt,
      null,
      0,
      0,
      0,
      true,
    )
    if (checkIfSentToday?.days >= 0) {
      isOverDueDate = false
      hasSentMailToday = true
    }
  }
  store.dispatch(
    setFieldValue({
      key: 'hasSentMailToday',
      value: hasSentMailToday,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'isDisabledEmailButton',
      value: !isOverDueDate,
    }),
  )
}

export const calculateReturnDate = (
  date,
  currentDate = null,
  day = 0,
  month = 0,
  year = 0,
  onlyDate = false,
) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const newDate = dayjs.utc(date).tz('Asia/Bangkok', true)
  let dueDate = newDate.add(day, 'day').add(month, 'month').add(year, 'year')
  let currentDate2 = currentDate
    ? dayjs.utc(currentDate).tz('Asia/Bangkok', true)
    : dayjs()
  if (onlyDate) {
    currentDate2 = currentDate2.startOf('day')
    dueDate = dueDate.startOf('day')
  }
  let differenceInDays = dueDate.diff(currentDate2, 'day')
  if (differenceInDays >= 0) {
    differenceInDays += 1
  }
  return { days: differenceInDays, isOverDue: currentDate2.isAfter(dueDate) }
}
