import * as yup from 'yup'
import { getPlainText } from '../../../../../utils/lib'

export const schema = yup.object({
  image: yup.string().required('กรุณาอัปโหลดรูปภาพ'),
  title: yup
    .string()
    .test(
      'title-validate',
      'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร',
      (value) => {
        const plainText = getPlainText(value)
        return plainText.length <= 255
      }
    ),
  description: yup
    .string()
    .test(
      'description-validate',
      'ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร',
      (value) => {
        const plainText = getPlainText(value)
        return plainText.length <= 3000
      }
    ),
  remark: yup.string().max(3000, 'ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร'),
})
