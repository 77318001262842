import { crudConfig } from '../../crudConfig'

export const breadcrumb = [
  { title: 'Expense', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: '/',
    pointer: true,
  },
]
