import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setExpiryType,
  setQREnrollProps,
  setIsEnable,
  setQrExpired,
  setSharePropValue,
} from '.'
import { handleStatusQR } from '../../../modules/ManageClass/Detail/Header/events'
import _ from 'lodash'

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: setExpiryType,
  effect: async (action, listenerApi) => {
    const { expiryType, isEnable } =
      listenerApi.getState().manageClassDetail.qrEnrollProps

    listenerApi.dispatch(
      setQREnrollProps({
        link: null,
        expiryDate: null,
        expiryTime: null,
        expiryType: expiryType,
        isEnable: isEnable,
        qrExpired: false,
      }),
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(setIsEnable, setQrExpired),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(handleStatusQR())
  },
})

listenerMiddleware.startListening({
  actionCreator: setSharePropValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action
    if (key !== 'isShare') return

    const { shareProps, eTestEEvaList, data } =
      listenerApi.getState().manageClassDetail

    let statusShare = localStorage.getItem('shareTestEvaList')
      ? JSON.parse(localStorage.getItem('shareTestEvaList'))
      : []

    const trainingPlanUuid = _.get(data, 'uuid')
    for (let i = 0; i < _.get(eTestEEvaList, 'totalCount'); i++) {
      let testEvaUuid = _.get(shareProps, `${i}.uuid`, undefined)
      const isShare = _.get(shareProps, `${i}.isShare`, undefined)
      if (!_.isUndefined(testEvaUuid)) {
        const isShareUuid = `${trainingPlanUuid}_${testEvaUuid}`
        const findShare = _.find(
          statusShare,
          (item) => item === `${trainingPlanUuid}_${testEvaUuid}`
        )
        if (_.isUndefined(findShare) && isShare) {
          statusShare.push(isShareUuid)
        } else if (!isShare) {
          statusShare = _.filter(
            statusShare,
            (item) => item !== `${trainingPlanUuid}_${testEvaUuid}`
          )
        }
      }
    }

    localStorage.setItem('shareTestEvaList', JSON.stringify(statusShare))
  },
})
