import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import Table from '../../../components/redux/Table'

import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { fetchMeetingRoomHistory } from './events'

const History = ({ isOpen, onCloseDrawer }) => {
  const { id: uuid } = useParams()
  const table = useSelector((state) => state.table.table, shallowEqual)
  const { page, limit } = table
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) dispatch(fetchMeetingRoomHistory({ table, uuid }))
  }, [isOpen, page, limit])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ประวัติการแก้ไข</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={onCloseDrawer}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledContent>
        <Box sx={{ m: 3, gap: 4 }}>
          <Table />
        </Box>
      </StyledContent>
    </StyledDrawer>
  )
}

export default History
