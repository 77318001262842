export const defaultFilter = {
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
  typeOfETestingChecked: false,
  typeOfETesting: {
    onlineTestChecked: false,
    assessmentChecked: false,
    StandaloneChecked: false,
  },
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
