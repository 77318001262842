import { createListenerMiddleware } from '@reduxjs/toolkit'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  filterTrainingPlan,
  getStaffListOptions,
  checkSelectedDates,
} from './events'
import _ from 'lodash'

export const listenerMiddlewareInventRequisition = createListenerMiddleware()

listenerMiddlewareInventRequisition.startListening({
  actionCreator: setFieldValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action

    if (key !== 'formData.trainingDate' && key !== 'formData.courseSelected')
      return

    const { masterData, formData } = listenerApi.getState().crud
    const courseSelected = _.get(formData, 'courseSelected', {})
    const trainingDate = _.get(formData, 'trainingDate', '')
    const trainingPlans = _.get(masterData, 'courses.options', [])

    if (key === 'formData.trainingDate' || key === 'formData.courseSelected') {
      listenerApi.dispatch(
        setFieldValue({
          key: 'formData.responsibleUser',
          value: '',
        }),
      )
      listenerApi.dispatch(
        setFieldValue({
          key: 'masterData.responsibleUser',
          value: { isLoading: true, options: [] },
        }),
      )
    }

    if (key === 'formData.trainingDate') {
      const filteredTrainingPlans = await filterTrainingPlan(trainingPlans)
      listenerApi.dispatch(
        setFieldValue({
          key: 'masterData.filteredCourses.options',
          value: filteredTrainingPlans,
        }),
      )
      if (
        (!_.isEmpty(courseSelected) &&
          !checkSelectedDates(trainingDate, courseSelected)) ||
        _.isEmpty(trainingDate) ||
        trainingDate === 'Invalid Date'
      ) {
        listenerApi.dispatch(
          setFieldValue({
            key: 'formData.courseSelected',
            value: '',
          }),
        )
      }
    } else if (key === 'formData.courseSelected') {
      const value = _.get(courseSelected, 'value')
      if (_.isUndefined(value)) return

      const staffList = await getStaffListOptions(value)

      listenerApi.dispatch(
        setFieldValue({
          key: 'masterData.responsibleUser.options',
          value: staffList,
        }),
      )
    }
  },
})
