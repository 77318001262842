export const defaultBudgetSummary = [
  {
    title: 'Budget',
    summaryList: [{ label: 'Total สรุปทั้งปี', value: '', key: 'budget' }],
  },
  {
    title: 'Revised',
    summaryList: [{ label: 'Total สรุปทั้งปี', value: '', key: 'revised' }],
  },
]
