import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setHandleChange,
  setSearchText,
  setToggleFilterDrawer,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { fetchDataList } from './fetchDataList'
import { fetchDashboardDetail } from '../../View/handler/fetchDashboardDetail'
import _ from 'lodash'

import {
  filterEtestingDashboardOverviewLearner,
  filterEtestingDashboardResult,
} from '../../../../../utils/apiPath'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleFiltering =
  (
    mutateFilterSateToProp,
    mutateFilterPropToBody,
    props,
    downloadName,
    tableHeadText,
  ) =>
  async (dispatch) => {
    const { filterState } = store.getState().table
    const isFilterDate =
      filterState.startDateChecked &&
      filterState.startDate != '' &&
      filterState.endDate != ''
    dispatch(setFieldValue({ key: 'isFilterDate', value: isFilterDate }))
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))
    const filterProp = mutateFilterSateToProp(props?.codeId ?? props?.uuid)
    if (props?.isDashboardListPage === true) {
      if (_.isEmpty(filterProp.status) === true) {
        filterProp.status = ['ACTIVE', 'INACTIVE']
      }
      if (_.isEmpty(filterProp.typeOfETesting) === true) {
        filterProp.typeOfETesting = ['ONLINE_TEST', 'ASSESSMENT', 'STANDALONE']
      }
    }

    dispatch(setFilterProp(filterProp))
    dispatch(
      setFilterTotal({
        ...filterState,
        startDateCheck: undefined,
        startDateStateChecked: filterState?.startDateState,
        startTestDateChecked:
          props?.isDashboardOverviewPage === true
            ? undefined
            : filterState?.startTestDateChecked,
      }),
    )
    store.dispatch(
      setHandleChange({
        key: 'filterStateMemo',
        value: filterState,
      }),
    )
    dispatch(resetTablePage())

    dispatch(setFieldValue({ key: 'isLoading', value: true }))
    if (props?.isDashboardTesterListTab === true) {
      fetchDataList(
        mutateFilterPropToBody,
        { ...props },
        downloadName,
        tableHeadText,
      ).then()
    } else {
      await fetchDashboardDetail(
        mutateFilterPropToBody(props?.uuid),
        props.url,
        props.fieldName,
      )

      if (props?.isDashboardOverviewPage === true) {
        await fetchDashboardDetail(
          mutateFilterPropToBody(props?.uuid),
          filterEtestingDashboardOverviewLearner,
          'learner',
        )
        await fetchDashboardDetail(
          mutateFilterPropToBody(props?.uuid),
          filterEtestingDashboardResult,
          'formData.qResult',
        )
      }
    }

    dispatch(setFieldValue({ key: 'isLoading', value: false }))
  }
