import { store } from '../../../../App'
import { openDialog } from '../../../../redux/slices/dialog'
import { handleProcessDelete } from './handleProcessDelete'

export const handleDeleteClick = async (uuid) => {
  store.dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบรายการนี้ใช่หรือไม่',
      handleConfirm: () => {
        handleProcessDelete(uuid).then()
      },
      isCloseDialog: false,
    }),
  )
}
