import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { StyledButton } from '../Styled'
import DoughnutChart from '../../../../components/Chart/DoughnutChart'
import { Column, Row } from 'src/components/Layout/Styled'
import RoomRowData from './RoomRowData'

const AvailableRoom = () => {
  const { meetingRoom } = useSelector(
    (state) => ({
      meetingRoom: state.crud?.roomManagementDashboard?.overview?.meetingRoom,
    }),
    shallowEqual,
  )

  return (
    <Grid item xs={6}>
      <Card sx={{ padding: '8px 16px 16px 16px', height: 284 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h6">ห้องที่สามารถใช้งานได้</Typography>
          <StyledButton
            color="text.lightGray"
            size="small"
            background="#4976BA14"
            onClick={() =>
              window.open('/master-data/meeting-room?status=ACTIVE', '_blank')
            }
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        </Box>
        <Box display="flex" gap={4} mt={2}>
          <DoughnutChart
            dataSet={{
              data: [
                meetingRoom?.single,
                meetingRoom?.connecting,
                meetingRoom?.other,
              ],
              backgroundColor: ['#B49CE4', '#99C2FF', '#FFDC89'],
            }}
            height={176}
            label="ทั้งหมด"
            value={meetingRoom?.total}
            eleId="availabelRoomChart"
            cutout={60}
          />
          <Column>
            <Row pl={3} pr={1} sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body2" color="text.lightGray">
                ฟังก์ชัน
              </Typography>
              <Typography variant="body2" color="text.lightGray">
                จำนวน
              </Typography>
            </Row>
            <RoomRowData
              color="#E9DEFF"
              dotColor="#B49CE4"
              label="Single Room"
              data={`${meetingRoom?.single} ห้อง`}
            />
            <RoomRowData
              color="#D4E5FF"
              dotColor="#99C2FF"
              label="Connecting Room"
              data={`${meetingRoom?.connecting} ห้อง`}
            />
            <RoomRowData
              color="#FFF4D9"
              dotColor="#FFA944"
              label="Other"
              data={`${meetingRoom?.other} ห้อง`}
            />
          </Column>
        </Box>
      </Card>
    </Grid>
  )
}

export default AvailableRoom
