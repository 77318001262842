// import useLocalStorage from '@rehooks/local-storage'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { InputDateGroup } from './InputDateGroup'

export const ServicePeriod = () => {
  // const { uuid } = useParams()

  // const [user] = useLocalStorage('user')
  // const hasPermissionCreate = validatePermission({
  //   user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['CREATE'],
  // })

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6" color={'text.primary'}>
          ระยะสัญญาการบริการ
        </Typography>
      </Box>
      <Box>
        <InputDateGroup />
      </Box>
    </>
  )
}
