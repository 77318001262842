import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import UploadList from './components/UploadList'
import { handleMultipleFilesUpload } from './handler/handleMultipleFilesUpload'
import { StyledFormCard } from '../../modules/Styled'
import { setupStatusCheckInterval } from './handler/setupStatusCheckInterval'
import DragDropUploadFile from './components/DragDropUploadFile'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import {
  deleteFieldUploadValue,
  setFieldUploadValue,
} from '../../redux/slices/uploadFile'
import _ from 'lodash'
import { handleFilesDirectUpload } from './handler/handleFilesDirectUpload'

const UploadFile = ({
  onChange = () => {},
  isMultiple = true,
  isForImage,
  folder = '',
  uploadType,
  maxFileSize,
  maxLength,
  accept,
  acceptLabel,
  fieldName,
  isShowDownloadable,
  label,
  sx,
  value,
  disabled,
  popupLabel,
  disabledRemove,
  hideUpload = false,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setFieldUploadValue({
        key: `formData[${fieldName}]`,
        value: { files: [], isUploading: false },
      }),
    )
    setupStatusCheckInterval(onChange, fieldName)
    if (value) {
      dispatch(
        setFieldUploadValue({
          key: `formData[${fieldName}].files`,
          value: value,
        }),
      )
    }
    return () => dispatch(deleteFieldUploadValue(fieldName))
  }, [fieldName, value])

  const { formData } = useSelector(
    (state) => ({
      formData: state.uploadFile.formData,
    }),
    shallowEqual,
  )

  if (!_.get(formData, fieldName, false)) {
    return <div />
  }

  return (
    <StyledFormCard
      isUploadLoading={formData[fieldName].isUploading}
      sx={{ gap: 3, ...sx }}
    >
      {(formData[fieldName].files.length === 0 ||
        maxLength > formData[fieldName].files.length  && !hideUpload) && (
        <DragDropUploadFile
          isMultiple={isMultiple}
          maxLength={maxLength}
          currentFilesLength={formData[fieldName].files.length}
          label={label}
          isLoading={formData[fieldName].isLoading}
          handleDrop={(files, fileRejections) => {
            if (isForImage) {
              handleFilesDirectUpload({
                files,
                fileRejections,
                fieldName,
                folder,
                onChange,
                maxLength,
              })
            } else {
              handleMultipleFilesUpload(
                files,
                isMultiple,
                fileRejections,
                uploadType,
                fieldName,
                maxLength,
                onChange,
              )
            }
          }}
          maxFileSize={maxFileSize}
          acceptLabel={acceptLabel}
          accept={accept}
          disabled={disabled}
        />
      )}
      {formData[fieldName].isUploading && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body1b" color="primary">
            Upload Queue
          </Typography>
          <Typography color="error">
            กำลังอัปโหลดไฟล์ กรุณาอย่าปิดหน้าจอนี้
          </Typography>
        </Box>
      )}
      <UploadList
        isForImage={isForImage}
        onChange={onChange}
        fieldName={fieldName}
        isShowDownloadable={isShowDownloadable}
        disabled={disabled}
        popupLabel={popupLabel}
        disabledRemove={disabledRemove}
        uploadType={uploadType}
      />
    </StyledFormCard>
  )
}

export default UploadFile
