import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxNotification = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}))

export const BoxSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  alignItems: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'display',
    flexDirection: 'unset',
    gap: theme.spacing(2),
    alignItems: 'none',
  },
}))
