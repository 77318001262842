import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxImage = styled(Box)(({ theme, isLoading, isError }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  ...styledImageBoxProps(theme, isLoading, isError),
}))

export const ImageBox = styled('div')(({ url, width, IsSelectBackground }) => ({
  backgroundColor: IsSelectBackground === 'BG0' ? 'white' : '',
  backgroundImage: `url('${url ?? ''}')`,
  objectFit: 'contain',
  borderRadius: '4px',
  width: '3508px',
  height: '2480px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zoom: `calc(1 + (${width} - 3508) / 3508)`,
  backgroundRepeat: 'no-repeat',
}))

export const styledImageBoxProps = (theme, isLoading, isError) => ({
  borderWidth: '1px',
  borderStyle: isLoading || isError ? 'dashed' : 'solid',
  borderColor: isError
    ? theme?.palette?.error?.main
    : theme?.palette?.other?.outlineBorder,
  display: 'flex',
  alignItems: 'center',
  '& .MuiBox-root': {
    width: 60,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: isLoading ? theme.spacing(1.5) : 0,
    alignItems: 'center',
    '& > .MuiSvgIcon-root': {
      color: isError
        ? theme?.palette?.error?.main
        : theme?.palette?.text?.silver,
    },
    '& > .MuiLinearProgress-root': { width: 60 },
  },
})

export const BoxTextImage = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
}))
