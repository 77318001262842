import axios from 'axios'
import _ from 'lodash'
import { setFieldUploadValue } from '../../../redux/slices/uploadCropper'
import { uploadFileKey } from '../../../utils/apiPath'
import { store } from '../../../App'

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',')
  const matched = arr[0].match(/:(.*?);/) // NOSONAR
  const mime = _.get(matched, 1, '')
  const bstr = window.atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const handleSubmitUpload =
  (folder, dataUrl, onChange) => async (dispatch) => {
    store.dispatch(setFieldUploadValue({ key: 'isOpen', value: false }))
    const file = dataURLtoFile(dataUrl)

    const uploadCropper = store.getState().uploadCropper
    store.dispatch(
      setFieldUploadValue({
        key: 'fileName',
        value: _.get(uploadCropper, 'prepareFileName', ''),
      }),
    )

    const body = new FormData()
    body.append('file', file)

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted =
          (progressEvent.loaded / progressEvent.total) * 100
        dispatch(
          store.dispatch(
            setFieldUploadValue({ key: 'percent', value: percentCompleted }),
          ),
        )
      },
    }

    dispatch(setFieldUploadValue({ key: 'isUploading', value: true }))

    try {
      const response = await axios.post(
        `${uploadFileKey}/${folder}`,
        body,
        config,
      )
      const data = _.get(response, 'data', undefined)

      dispatch(setFieldUploadValue({ key: 'coverImage', value: data.key }))
      dispatch(setFieldUploadValue({ key: 'uploadError', value: undefined }))
      onChange(data.key, file)
    } catch (err) {
      console.log(err)
      dispatch(
        setFieldUploadValue({ key: 'uploadError', value: 'ERROR_FILE_FAIL' }),
      )
    } finally {
      dispatch(setFieldUploadValue({ key: 'isUploading', value: false }))
    }
  }
