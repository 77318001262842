import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Row } from '../../../../components/Layout/Styled'
import Select from '../../../../components/Input/Select'
import { timeTableOrderOptions } from '../enum/enum'
import { setFieldValue } from '../../../../redux/slices/crud'
import LoadingPageCircular from '../../../../components/CRUD/components/LoadingPageCircular'
import { fetchMeetingRoom } from '../handler/fetchMeetingRoom'
import { StyledNumber, StyledText } from '../../../EContent/Dashboard/Styled'
import { StyledButton } from '../Styled'

const MeetingRoomList = () => {
  const dispatch = useDispatch()
  const { meetingRoomList, order, isLoading, startDate, endDate } = useSelector(
    (state) => ({
      meetingRoomList:
        state.crud.roomManagementDashboard?.overview?.meetingRoomList,
      order: state.crud.roomManagementDashboard?.meetingRoomOrder,
      isLoading: state.crud.roomManagementDashboard?.isLoading,
      startDate: state.crud.roomManagementDashboard?.filtersDrawer?.startDate,
      endDate: state.crud.roomManagementDashboard?.filtersDrawer?.endDate,
    }),
    shallowEqual,
  )
  return (
    <Box>
      <Row sx={{ justifyContent: 'end', p: '0 20px 24px 0' }}>
        <Typography variant="body1b" mr={2}>
          เรียงตาม
        </Typography>
        <Select
          boxSx={{ width: 260 }}
          selectSx={{
            background: '#FFFFFF',
            borderRadius: 1,
          }}
          id="meeting-room-order"
          name="meeting-room-order"
          placeholder="กรุณาเลือก"
          options={timeTableOrderOptions}
          showTextError={false}
          value={order || 'desc'}
          defaultValue=""
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            dispatch(
              setFieldValue({
                key: 'roomManagementDashboard.meetingRoomOrder',
                value: value,
              }),
            )
            fetchMeetingRoom()
          }}
        />
      </Row>
      <Grid container pr={meetingRoomList?.length > 10 ? 2 : 0}>
        <Grid item xs={3.25}>
          <Row>
            <Typography
              sx={{ fontWeight: 500, width: 24, textAlign: 'center' }}
              variant="body2"
              ml={1.5}
              mr={0.75}
            >
              #
            </Typography>
            <Typography sx={{ fontWeight: 500 }} variant="body2">
              ชื่อห้อง
            </Typography>
          </Row>
        </Grid>
        <HeaderCell data="รวมจำนวนชั่วโมงที่ใช้ห้อง" />
        <HeaderCell data="Monthly Plan" />
        <HeaderCell data="Manage Class" />
        <HeaderCell data="eBooking" />
        <HeaderCell data="% การว่าง" xs={0.75} />
      </Grid>
      <Box
        sx={{
          height: '500px',
          overflowY: 'auto',
          alignContent: meetingRoomList?.length > 0 ? 'start' : 'center',
        }}
      >
        {meetingRoomList?.length > 0 ? (
          meetingRoomList?.map((ele, ind) => {
            const isOdd = ind % 2 != 0
            return (
              <Grid
                container
                key={ind}
                sx={{
                  height: 50,
                  alignContent: 'center',
                  background: isOdd ? '#F1F4FA' : '#FFFFFF',
                  borderRadius: '16px',
                }}
              >
                <Grid item xs={3.25}>
                  <Row ml={1.5}>
                    <StyledNumber variant="caption" color="text.white">
                      {ind + 1}
                    </StyledNumber>
                    <StyledText variant="body2b" color="primary.main">
                      {ele.name}
                    </StyledText>
                  </Row>
                </Grid>
                <BodyCell
                  data={`${ele.time} (${ele.timePercent})`}
                  color="primary.main"
                  variant="body2b"
                />
                <BodyCell
                  data={`${ele.monthlyPlan.time} (${ele.monthlyPlan.timePercent})`}
                  type="MONTHLY_PLAN"
                  startDate={startDate}
                  endDate={endDate}
                  name={ele.roomName}
                />
                <BodyCell
                  data={`${ele.manageClass.time} (${ele.manageClass.timePercent})`}
                  type="MANAGE_CLASS"
                  startDate={startDate}
                  endDate={endDate}
                  name={ele.roomName}
                />
                <BodyCell
                  data={`${ele.eBooking.time} (${ele.eBooking.timePercent})`}
                  type="E_BOOKING"
                  startDate={startDate}
                  endDate={endDate}
                  name={ele.roomName}
                />
                <BodyCell xs={0.75} data={ele.availablePercent} />
              </Grid>
            )
          })
        ) : (
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ textAlign: 'center' }}
          >
            ไม่พบข้อมูล
          </Typography>
        )}
      </Box>
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default MeetingRoomList

export const HeaderCell = ({ data, xs = 2 }) => {
  return (
    <Grid item xs={xs}>
      <Typography sx={{ fontWeight: 500 }} variant="body2">
        {data}
      </Typography>
    </Grid>
  )
}

export const BodyCell = ({
  data,
  type = '',
  startDate = '',
  endDate = '',
  name = '',
  xs = 2,
  color = 'text.primary',
  variant = 'body2',
}) => {
  return (
    <Grid item xs={xs}>
      <Row>
        <Typography color={color} variant={variant} minWidth={148}>
          {data}
        </Typography>
        {type && (
          <StyledButton
            color="text.lightGray"
            size="small"
            background="#4976BA14"
            marginLeft={0}
            onClick={() =>
              window.open(
                `/room-management/report?start_date=${startDate}&end_date=${endDate}&type=${type}&room_name=${name}`,
                '_blank',
              )
            }
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        )}
      </Row>
    </Grid>
  )
}
