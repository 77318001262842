import React, { useState } from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'

import Video from './ContentVideo'
import { InputImageUpload, TooltipForm, BoxTextTooltip } from './Styled'
import { alphabets, errorMessageField } from '../../../../constants/eTesting'
import * as reducer from '../../../../redux/slices/eTestingForm'
import * as service from '../../../../services/eTesting/form'
import {
  openDialog,
  openErrorDialog,
  closeDialog,
} from '../../../../redux/slices/dialog'

export const IconUploadImage = ({
  testId,
  onUpload,
  isQuestion,
  disabled,
  indexAnswer,
  questionType,
}) => {
  const [isHover, setIsHover] = useState(false)

  let sxIcon = {}
  if (questionType === 'SINGLE') {
    sxIcon = !indexAnswer ? -2 : -2.5
  } else if (questionType === 'MULTIPLE') {
    sxIcon = 1
  }
  return (
    <TooltipForm
      title={<CheckText isQuestion={isQuestion} />}
      placement="bottom"
    >
      <label htmlFor={testId}>
        <IconButton
          onMouseLeave={() => setIsHover(false)}
          onMouseOver={() => setIsHover(true)}
          component="span"
          sx={{ mt: sxIcon }}
        >
          <ImageOutlined color={isHover ? 'primary' : 'action'} />
        </IconButton>
        {!disabled && (
          <InputImageUpload
            accept="image/jpg, image/jpeg, image/png"
            type="file"
            id={testId}
            onChange={onUpload}
          />
        )}
      </label>
    </TooltipForm>
  )
}

export const getErrorMessage = (props) => {
  const {
    field = '',
    errorSchemaMessage,
    indexAnswer = 0,
    indexQuestion,
    indexMainAnswer = -1,
  } = props
  if (_.get(errorSchemaMessage, 'message', '') === '') return ''
  const errorField = _.get(errorSchemaMessage, 'field', '')
  let errorMessage = ''
  if (field !== '') {
    //-1 Because get real index from message(message show No)
    const splitMessage = errorSchemaMessage.message.split(/(\d+)/)
    const indexAnswerMessage = Number(_.get(splitMessage, '[1]', 1) - 1)

    if (
      field === errorMessageField.ANSWER &&
      errorField === errorMessageField.ANSWER
    ) {
      const indexQuestionMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? Number(_.get(splitMessage, '[5]', 0)) === 3000 ||
            Number(_.get(splitMessage, '[5]', 0)) === 255
            ? `ระบุได้ไม่เกิน ${_.get(splitMessage, [5], 0)} ตัวอักษร`
            : 'กรุณากรอกตัวเลือก'
          : ''
    } else if (
      field === errorMessageField.QUESTION &&
      errorField === errorMessageField.QUESTION &&
      splitMessage[0] !== 'ตัวเลือกที่ '
    ) {
      const indexQuestionMessage = Number(splitMessage[1]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? Number(_.get(splitMessage, '[3]', 0)) === 3000
            ? 'ระบุได้ไม่เกิน 3000 ตัวอักษร'
            : 'กรุณากรอกคำถาม'
          : ''
    } else if (
      field === errorMessageField.ANSWER_QUESTION &&
      errorField === errorMessageField.ANSWER_QUESTION &&
      indexAnswer === indexAnswerMessage
    ) {
      const indexQuestionMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? Number(_.get(splitMessage, '[5]', 0)) === 255
            ? 'ระบุได้ไม่เกิน 255 ตัวอักษร'
            : 'กรุณากรอกคำถาม'
          : ''
    } else if (
      field === errorMessageField.SUB_TITLE &&
      errorField === errorMessageField.SUB_TITLE
    ) {
      const indexQuestionMessage = Number(_.get(splitMessage, '[1]', 1)) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? Number(_.get(splitMessage, '[3]', 0)) === 3000
            ? 'ระบุได้ไม่เกิน 3000 ตัวอักษร'
            : 'กรุณากรอกข้อความ'
          : ''
    } else if (
      field === errorMessageField.CORRECT &&
      errorField === errorMessageField.CORRECT
    ) {
      let indexQuestionMessage = Number(splitMessage[1]) - 1
      if (splitMessage.length > 4) {
        indexQuestionMessage = Number(splitMessage[3]) - 1
      }
      errorMessage =
        indexQuestionMessage === indexQuestion ? errorSchemaMessage.message : ''
    } else if (
      field === errorMessageField.SUB_QUESTION &&
      errorField === errorMessageField.SUB_QUESTION &&
      indexAnswer === indexAnswerMessage
    ) {
      const indexQuestionMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? Number(_.get(splitMessage, '[5]', 0)) === 3000
            ? `ระบุได้ไม่เกิน 3000 ตัวอักษร`
            : 'กรุณากรอกคำถาม'
          : ''
    } else if (
      field === errorMessageField.SUB_QUESTION &&
      errorField === errorMessageField.ANSWER &&
      indexAnswer === indexAnswerMessage
    ) {
      const indexQuestionMessage = Number(splitMessage[3]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer
          ? Number(_.get(splitMessage, '[5]', 0)) === 3000
            ? `ระบุได้ไม่เกิน 3000 ตัวอักษร`
            : 'กรุณากรอกคำถาม'
          : ''
    } else if (
      field === errorMessageField.QUESTION &&
      errorField === errorMessageField.QUESTION
    ) {
      const indexQuestionMessage = Number(splitMessage[1]) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? Number(_.get(splitMessage, '[3]', 0)) === 3000
            ? 'ระบุได้ไม่เกิน 3000 ตัวอักษร'
            : 'กรุณากรอกคำถาม'
          : ''
    }
    if (indexMainAnswer > -1) {
      const indexQuestionMessage = Number(splitMessage[3]) - 1
      const subAlphabet = _.get(splitMessage[2]?.split(' '), '2', '')
      const indexMainAnswerMessage = alphabets.findIndex(
        (alph) => alph === subAlphabet
      )
      errorMessage =
        indexQuestionMessage === indexQuestion &&
        indexAnswerMessage === indexAnswer &&
        indexMainAnswerMessage === indexMainAnswer
          ? Number(_.get(splitMessage, '[5]', 0)) === 255
            ? 'ระบุได้ไม่เกิน 255 ตัวอักษร'
            : 'กรุณากรอกตัวเลือก'
          : ''
    }

    if (
      field === errorMessageField.CHOICE_NUM &&
      errorField === errorMessageField.CHOICE_NUM
    ) {
      const indexQuestionMessage = Number(_.get(splitMessage, '[1]', 1)) - 1
      errorMessage =
        indexQuestionMessage === indexQuestion
          ? 'กรุณาสร้างตัวเลือกมากกว่า 1 ตัวเลือก'
          : ''
    }
  }
  return errorMessage
}

export const handleErrorMessage = (obj) => async (dispatch) => {
  let value = ''
  if (obj.field === 'DATE') {
    value = 'setting'
  } else {
    value = 'testing'
  }
  dispatch(
    reducer.setChange({
      key: 'defaultTap',
      value: value,
    })
  )
}
export const convertErrorMessage = (errorMessage) => {
  let obj = {
    field: '',
    message: errorMessage,
    questionNo: -1,
    answerNo: -1,
    subAnswerNo: -1,
  }

  if (_.isNil(errorMessage) || errorMessage === '') return obj
  const { message } = obj

  if (message.includes('กรุณากรอกคำถาม') && !message.includes('ย่อย')) {
    obj.field = errorMessageField.QUESTION
  } else if (message.includes('กรุณากรอกตัวเลือก')) {
    obj.field = errorMessageField.ANSWER
  } else if (message.includes('กรุณาเลือกคำตอบ')) {
    obj.field = errorMessageField.CORRECT
  } else if (message.includes('กรุณาระบุวันเริ่มต้นใช้งาน')) {
    obj.field = errorMessageField.DATE
  } else if (message.includes('คำถามข้อที่ ') && message.includes('3000')) {
    obj.field = errorMessageField.QUESTION
  } else if (message.includes('กรุณาสร้างตัวเลือกมากกว่า')) {
    obj.field = errorMessageField.CHOICE_NUM
  } else if (message.includes('กรุณากรอกข้อความ')) {
    obj.field = errorMessageField.SUB_TITLE
  } else if (message.includes('ข้อความข้อที่ ') && message.includes('3000')) {
    obj.field = errorMessageField.SUB_TITLE
  } else if (message.includes('กรุณากรอกคำถามย่อย')) {
    obj.field = errorMessageField.SUB_QUESTION
  }

  const splitMessage = errorMessage.split(/(\d+)/)
  obj.answerNo = 0
  if (
    (message.includes('ตัวเลือกที่ ') || message.includes('คำถามย่อยที่')) &&
    Number(splitMessage[1] !== '3000')
  ) {
    obj.questionNo = Number(splitMessage[3])
  } else if (message.includes('คำถามข้อที่ ') && message.includes('3000')) {
    obj.questionNo = Number(splitMessage[1])
  } else {
    obj.questionNo = Number(splitMessage[1])
  }
  if (
    (message.includes('ตัวเลือกที่ ') || message.includes('คำถามย่อยที่')) &&
    (message.includes('3000') || message.includes('255'))
  ) {
    obj.questionNo = Number(splitMessage[3])
    obj.answerNo = Number(splitMessage[1])
    obj.field = errorMessageField.ANSWER
  }
  if (message.includes('ช่องว่าง')) {
    obj.questionNo = Number(splitMessage[3])
    obj.answerNo = Number(splitMessage[1])
    const subAlphabet = _.get(splitMessage[2].split(' '), '2', '')
    obj.subAnswerNo = alphabets.findIndex((alph) => alph === subAlphabet) + 1
  }
  if (message.includes('คู่ที่')) {
    obj.questionNo = Number(splitMessage[3])
    obj.answerNo = Number(splitMessage[1])
    obj.field = errorMessageField.ANSWER_QUESTION
  }

  return obj
}

export const handleIconUploadImage = (props) => async (dispatch) => {
  const { data, keyValue, keyQuestion, testId } = props

  let body = new FormData()
  let elementId = document.getElementById(testId)
  if (data.target.files.length === 0) {
    if (elementId) elementId.value = null
    return
  }
  body.append('file', data.target.files[0])
  const getNameImage = _.get(data, 'target.files[0].name', '')

  if (getNameImage) {
    const getExtensionImage = getNameImage.split('.').pop()
    const sizeFile = _.get(data, 'target.files[0].size', 0)
    if (!checkTypeFile(getExtensionImage.toLowerCase())) {
      if (elementId) elementId.value = null
      return dispatch(dialogMaxSizeImage())
    }
    if (sizeFile > 2000000) {
      if (elementId) elementId.value = null
      return dispatch(dialogMaxSizeImage(true))
    }
  }
  // service upload
  const payload = { body, keyQuestion, keyValue }
  const dataUpload = await dispatch(service.uploadFileETesting(payload))
  if (elementId) elementId.value = null
  dispatch(
    reducer.setChangeQuestionByKey({
      keyQuestion,
      key: keyValue,
      value: _.get(dataUpload, 'payload.data.key', ''),
    })
  )
  if (keyValue == 'url') {
    dispatch(
      reducer.setChangeQuestionByKey({
        keyQuestion,
        key: 'mediaType',
        value: 'IMAGE',
      })
    )
  }
}

export const checkTypeFile = (type) => {
  if (type === 'jpg' || type === 'jpeg' || type === 'png') return true
  return false
}

export const CheckText = ({ isQuestion }) => {
  const text = !isQuestion
    ? 'รูปภาพ ขนาดแนะนำ 300x200px,'
    : 'รูปภาพ ขนาดแนะนำ 450x300px,'

  return (
    <BoxTextTooltip>
      <Typography variant="tooltip">{text}</Typography>
      <Typography variant="tooltip">ไฟล์ที่รองรับ .jpg, .jpeg .png</Typography>
    </BoxTextTooltip>
  )
}

export const handleIconVideo = (keyQuestion) => async (dispatch) => {
  dispatch(
    openDialog({
      maxWidth: 'sm',
      type: 'content',
      title: 'เพิ่มวิดีโอ',
      agreeText: 'เพิ่ม',
      icon: <VideocamOutlined color="primary" />,
      content: <Video keyQuestion={keyQuestion} />,
      handleConfirm: () => dispatch(submitDialogVideo(keyQuestion)),
    })
  )
}

export const submitDialogVideo = (keyQuestion) => (dispatch) => {
  dispatch(reducer.setVideo({ keyQuestion }))
}

export const dialogMaxSizeImage = (isSize) => (dispatch) => {
  if (isSize) {
    dispatch(
      openErrorDialog({
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
        handleError: () => dispatch(closeDialog()),
      })
    )
  } else {
    dispatch(
      openErrorDialog({
        title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        message:
          'ไม่สามารถ Upload ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้องกรุณา Upload ตามที่กำหนดไว้',
        handleError: () => dispatch(closeDialog()),
      })
    )
  }
}
