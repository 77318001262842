import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

export const StyledLayoutBox = styled(Grid)(
  ({ theme, active, width, url }) => ({
    width: '3508px',
    height: '2480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${url})`,
    outline: active
      ? `40px solid ${theme?.palette?.secondary?.main}`
      : `1px solid ${theme.palette?.other?.outlineBorder ?? ''}`,
    zoom: `calc(1 + (${width / 4.4} - 3508) / 3508)`,
    backgroundRepeat: 'no-repeat',
  })
)

export const StyledCard = styled(Box)(() => ({
  width: '100%',
}))

export const StyledGroupLayoutBox = styled(Grid)(({ theme, height }) => ({
  width: '100%',
  height: `${height}px`,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  border: `1px solid ${theme.palette?.other?.divider}`,
  padding: '16px',
  overflow: 'auto',
  gap: '16px',
}))
