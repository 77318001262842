import dayjs from 'dayjs'
import _ from 'lodash'
import { handleChange } from '../event'

export const handleSwitchChange = (e, staticShare, shareDate) => (dispatch) => {
  const value = _.get(e, 'target.checked', false)
  dispatch(handleChange({ value, key: 'isShare' }))

  if (value && !staticShare) {
    shareDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
  }
  dispatch(handleChange({ value: shareDate, key: 'shareDate' }))
}
