import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(3),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginLeft: 'auto',
}))

export const LabelHeader = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const BoxSelect = styled(Box)(({ theme }) => ({
  width: 235,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
