import { Box } from '@mui/material'

export const ContentRow = ({ columnLeft, columnRight }) => {
  return (
    <Box display="flex" gap={3}>
      <Box width="50%">{columnLeft}</Box>
      <Box width="50%">{columnRight}</Box>
    </Box>
  )
}
