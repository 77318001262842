import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}))

export const StyledColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))
