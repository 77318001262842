export const initialState = {
  isLoading: false,
  selectDate: '',
  remark: '',
  initialState: {
    attendance: [],
    codeId: '',
    course: '',
    courseCode: '',
    firstNameTH: '',
    image: '',
    lastNameTH: '',
    status: '',
    trainingPlanUuid: '',
  },
}
