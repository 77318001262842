import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Edit from '@mui/icons-material/Edit'
import { BoxSpaceBetween, BoxContent, BoxLeft, BoxRight } from './Styled'
import PointDrawer from './PointDrawer'
import PointDialog from './PointDialog'
import {
  openPointDialog,
  setPointDrawerOpen,
} from '../../../../../redux/slices/manage/agentProfile'
import { convertNumberWithComma } from '../../../../../utils/lib'

const Point = () => {
  const dispatch = useDispatch()
  const point = useSelector(
    (state) => state.agentProfile.initialState.point,
    shallowEqual,
  )
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 1.5 }}>
      <BoxSpaceBetween>
        <Typography variant="h6">คะแนนการเรียน</Typography>
        <Button
          data-testid="btn-open-drawer"
          variant="outlined"
          size="m"
          onClick={() => {
            dispatch(setPointDrawerOpen(true))
          }}
        >
          <HistoryTwoTone color="primary.main" />
        </Button>
      </BoxSpaceBetween>
      <BoxSpaceBetween>
        <BoxContent>
          <BoxLeft>
            <Typography variant="body1" color="text.secondary">
              จำนวนคะแนนสะสม
            </Typography>
          </BoxLeft>
          <BoxRight>
            <Typography variant="body1" sx={{ minWidth: 100 }}>
              {convertNumberWithComma(point)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </BoxRight>
        </BoxContent>
        <Button
          variant="contained"
          sx={{ px: 2, gap: 1, whiteSpace: 'nowrap' }}
          onClick={() => {
            dispatch(openPointDialog())
          }}
        >
          <Edit />
          MANAGE POINT
        </Button>
      </BoxSpaceBetween>
      <PointDrawer />
      <PointDialog />
    </Card>
  )
}

export default Point
