import { createListenerMiddleware } from '@reduxjs/toolkit'
import _ from 'lodash'
import { setFieldValue } from '../crud'

export const listenerMiddlewareBudgetManagement = createListenerMiddleware()

listenerMiddlewareBudgetManagement.startListening({
  actionCreator: setFieldValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action

    if (!_.includes(key, 'budgetDetails')) return

    const { budgetDetails, budgetSummary } =
      listenerApi.getState().crud.formData

    const { budgetTotal, revisedTotal } = _.reduce(
      budgetDetails,
      (acc, item) => {
        const budget = Number(_.get(item, 'data[0].value', 0))
        const revised = _.get(item, 'data[1].value', 0)

        let rvSummary = revised
        if (_.isNull(revised)) {
          rvSummary = budget
        } else {
          rvSummary = Number(revised)
        }

        return {
          budgetTotal: acc.budgetTotal + budget,
          revisedTotal: acc.revisedTotal + rvSummary,
        }
      },
      { budgetTotal: 0, revisedTotal: 0 },
    )

    const newBudgetSummary = _.map(budgetSummary, (smItem) => {
      const newSummaryList = _.map(smItem.summaryList, (item) => {
        switch (item.key) {
          case 'budget':
            return { ...item, value: budgetTotal }
          case 'revised':
            return { ...item, value: revisedTotal }
        }

        return item
      })
      return { ...smItem, summaryList: newSummaryList }
    })

    listenerApi.dispatch(
      setFieldValue({ key: `formData.budgetSummary`, value: newBudgetSummary }),
    )
  },
})
