import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const BoxDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BoxDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BoxDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs({ open, handleClose }) {
  return (
    <>
      <BoxDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BoxDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h6">การส่งตรวจสอบแผนรายเดือน</Typography>
        </BoxDialogTitle>
        <DialogContent sx={{ width: 450 }}>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac
            hendrerit lectus, ut scelerisque risus. Aliquam a sollicitudin
            tellus, sed pharetra sem. Proin convallis ac tellus vel elementum.
            In sit amet ex at libero lacinia ullamcorper quis eu massa. Nunc
            tincidunt, odio vel facilisis accumsan, dolor mi vehicula libero,
            quis lobortis nunc turpis ut augue. Phasellus pharetra nunc non nisl
            pellentesque, ut lacinia urna venenatis. Nam feugiat facilisis
            ornare. Aenean auctor, orci eget placerat porttitor, odio quam
            imperdiet nisi, vitae hendrerit velit mi ultrices metus. Sed et
            justo in sapien lobortis dapibus.
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            In sit amet fringilla nulla. Nam porta porttitor quam, at
            ullamcorper risus lacinia ac. Sed sed viverra turpis. Vestibulum ut
            tincidunt leo. Quisque volutpat tortor id tortor euismod, eu
            pellentesque ante mattis. Nunc blandit nibh sit amet mi dapibus,
            eget sodales enim efficitur. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Etiam tellus
            est, facilisis et rhoncus eu, ultricies vel massa.
          </Typography>
        </DialogContent>
      </BoxDialog>
    </>
  )
}
