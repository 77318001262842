import { Box, Button } from '@mui/material'
import { handleSubmitForm } from '../../handler/handleSubmitForm'
import { handleCloseDrawer } from '../../handler/handleCloseDrawer'

export const Footer = () => {
  return (
    <Box display="flex" px={3} pt={2} gap={3} justifyContent="flex-end">
      <Button variant="outlined" onClick={() => handleCloseDrawer()}>
        ยกเลิก
      </Button>
      <Button variant="contained" onClick={() => handleSubmitForm()}>
        บันทึก
      </Button>
    </Box>
  )
}
