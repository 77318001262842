import { E_CERTIFICATE_SETTING, OWNER, PERMISSION } from '../eCertification'

export const permissionECertificationMenu = [
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.MENU],
  },
]

export const permissionECertificationView = [
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.VIEW],
  },
]

export const permissionECertificationCreate = [
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.CREATE],
  },
]

export const permissionECertificationCreateBg = [
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.CREATE_BG],
  },
]

export const permissionECertificationEdit = [
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.ALL,
    permission: [PERMISSION.EDIT],
  },
  {
    module: E_CERTIFICATE_SETTING,
    owner: OWNER.PIC,
    permission: [PERMISSION.EDIT],
  },
]
