import React from 'react'
import { Box } from '@mui/material'
import _ from 'lodash'
import EnrollOverviewCard from './EnrollOverviewCard'
import { useSelector, shallowEqual } from 'react-redux'

const LearnerStatusChartByType = () => {
  const { learnerStatusByType } = useSelector(
    (state) => ({
      learnerStatusByType: state.eLearningDashboardByCourse.learnerStatusByType,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" gap={2} width="100%" flexWrap="wrap">
      {_.map(learnerStatusByType, (item, idx) => {
        return <EnrollOverviewCard {...item} idx={idx} key={idx} />
      })}
    </Box>
  )
}

export default LearnerStatusChartByType
