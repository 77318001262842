import _ from 'lodash'
export const mapLabelDistribution = (list, uuid) => {
  if (!list.length) return '-'
  const findItem = list.find((item) => item.value == uuid)
  return _.get(findItem, 'label', '-')
}

export const mapLabelCourse = ({
  listDistribution,
  listDistributionELearning,
  distribution,
  uuid,
  licenseList,
  type,
}) => {
  let listCourse = []
  if (type === 'COURSE') {
    const findItem = listDistribution.find((item) => item.value == distribution)
    listCourse = _.get(findItem, 'course', [])
  } else if (type === 'ELEARNING') {
    const findItem = listDistributionELearning.find(
      (item) => item.value == distribution,
    )
    listCourse = _.get(findItem, 'course', [])
  } else {
    listCourse = licenseList
  }

  const findItemCourse = listCourse.find((item) => item.value == uuid)
  return _.get(findItemCourse, 'label', '-')
}

export const mapType = (type) => {
  switch (type) {
    case 'COURSE':
      return 'หลักสูตร'
    case 'ELEARNING':
      return 'หลักสูตร'
    default:
      return 'ใบอนุญาต'
  }
}
