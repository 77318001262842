import React from 'react'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { StyledQRContent } from '../../Styled'
import { ButtonGroup } from './ButtonGroup'
import { QRCodeContent } from './QRCodeContent'
import { CountDownTimerSection } from './CountDownSection'
import { QRCodeTemplate } from '../../../../../../../components/QRCodeTemplate'

export const ShowQRCodeSection = ({ time }) => {
  const {
    isMobile,
    qrEnrollProps: { expiryDate, link },
    data,
  } = useSelector((state) => state.manageClassDetail, shallowEqual)
  const { days, hrs, mins, secs } = time

  const countdownTimer = [
    { number: days, unit: 'วัน' },
    { number: hrs, unit: 'ชั่วโมง' },
    { number: mins, unit: 'นาที' },
    { number: secs, unit: 'วินาที' },
  ]

  return (
    <StyledQRContent>
      <QRCodeTemplate
        id="share-qr-code"
        isHidden={true}
        link={link}
        title="QR Code ลงทะเบียน"
        titleSx={{ mt: 0 }}
        subtitle={_.get(data, 'course', '')}
        qrStyle={{ width: 450, height: 450 }}
      />
      <Typography variant={isMobile ? 'h6' : 'h4'}>
        Scan เพื่อลงทะเบียน
      </Typography>
      {!_.isNull(expiryDate) && (
        <CountDownTimerSection countdownTimer={countdownTimer} />
      )}
      <QRCodeContent course={_.get(data, 'course', '')} />
      <ButtonGroup />
    </StyledQRContent>
  )
}
