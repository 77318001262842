import { StyledRow } from '../../../../Styled'
import { AttendeeInGroup } from './AttendeeInGroup'
import { NumberOfGroup } from './NumberOfGroup'
import { shallowEqual, useSelector } from 'react-redux'
import { roomSelector } from '../../../../../../redux/slices/roomManagement/Drawer'

export const GroupSection = ({ bookingDate, roomIndex }) => {
  const roomLayout = useSelector(
    roomSelector({ bookingDate, roomIndex, key: 'roomLayout' }),
    shallowEqual,
  )

  if (roomLayout !== 'GROUP') return null
  return (
    <StyledRow sx={{ gap: 2, pl: 10, alignItems: 'stretch' }}>
      <NumberOfGroup bookingDate={bookingDate} roomIndex={roomIndex} />
      <AttendeeInGroup bookingDate={bookingDate} roomIndex={roomIndex} />
    </StyledRow>
  )
}
