import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../context/dialog-context'
import Form from './Form'
import { validationSchema } from './FormSchema'
import { onView } from './DistributionList'
import Breadcrumbs from '../../components/Breadcrumbs'
import { distributionChannel, filterLevelOfLearner } from '../../utils/apiPath'
import { callAPI, setMapListOptions } from '../../utils/lib'

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const submitDistribution = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  history,
}) => {
  setDialog({
    open: true,
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    isLoading: true,
  })
  const methodType = isEditForm ? 'put' : 'post'
  let result = []
  if (values?.level?.length) {
    result = values?.level?.map((item) => {
      if (!item.label) {
        return { label: item.title, value: item.uuid }
      }
      return item
    })
  }

  const body = isEditForm
    ? {
        uuid: uuid,
        id: values.id,
        distribution: values.distribution,
        abbreviation: values.abbreviation,
        level: result,
        remark: values.remark,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }
    : {
        distribution: values.distribution,
        abbreviation: values.abbreviation,
        level: result,
        remark: values.remark,
        status: values.status === true ? 'ACTIVE' : 'INACTIVE',
      }

  callAPI({
    method: methodType,
    url: distributionChannel,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res.uuid
      setDialog({
        open: true,
        isLoading: false,
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        variant: 'success',
        onConfirmClick: () => {
          setDialog({ open: false })
          onView({ uuid: uuidLink }, history)
        },
      })
    },
    onError: () => {
      setDialog({
        open: false,
        isLoading: false,
        variant: '',
      })
    },
  })
}

export const fetchDistributionChannelById = async ({
  uuid,
  setInitialValues,
  setDisplayTitle,
  formik,
  setIsPageLoading,
}) => {
  callAPI({
    method: 'get',
    url: distributionChannel + `/${uuid}`,
    onSuccess: (res) => {
      const {
        id,
        distribution,
        abbreviation,
        status,
        level,
        remark,
        updatedBy,
        updatedAt,
      } = res
      setInitialValues({
        ...res.data,
        id: id,
        distribution: distribution,
        abbreviation: abbreviation,
        level: level,
        remark: remark,
        status: status.toString().toUpperCase() === 'ACTIVE' || false,
        updatedBy: updatedBy,
        updatedAt: updatedAt,
      })
      setDisplayTitle(distribution)
      formik.setFieldValue('id', id)
      formik.setFieldValue('distribution', distribution)
      formik.setFieldValue('abbreviation', abbreviation)
      formik.setFieldValue('level', level)
      formik.setFieldValue('remark', remark)
      formik.setFieldValue(
        'status',
        status.toString().toUpperCase() === 'ACTIVE' || false
      )
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

const DistributionForm = ({ isEditForm }) => {
  const { id: uuid } = useParams()
  const titleText = isEditForm
    ? 'แก้ไขช่องทางการจำหน่าย'
    : 'เพิ่มช่องทางการจำหน่าย'

  const breadcrumbList = [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'ช่องทางการจำหน่าย',
      link: '/master-data/distribution-channel',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]

  const onSelectChange = (list) => {
    formik.setFieldValue('level', list)
  }

  const fetchLevelList = async () => {
    setIsLoading(true)
    const body = {
      limit: -1,
      page: 1,
      order: 'ASC',
      sort: 'id',
      title: '',
      status: ['ACTIVE'],
    }
    await callAPI({
      url: filterLevelOfLearner,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setLevel(res.result)
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchLevelList()
    if (isEditForm) {
      fetchDistributionChannelById({
        uuid,
        setInitialValues,
        setDisplayTitle,
        formik,
        setDialog,
        setIsPageLoading,
      })
    }
    setIsPageLoading(false)
  }, [isEditForm])
  const history = useHistory()
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [displayTitle, setDisplayTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [level, setLevel] = useState([])
  const [initialValues, setInitialValues] = useState({
    distribution: '',
    abbreviation: '',
    level: [],
    remark: '',
    status: true,
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitDistribution({
            uuid,
            values,
            isEditForm,
            setDialog,
            history,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={() => onCancelClickDialog(setDialog)}
          formik={formik}
          isLoading={isLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          level={setMapListOptions(level, 'title')}
          onChange={onSelectChange}
        ></Form>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default DistributionForm
