import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../redux/slices/dialog'
import {
  setInitialForm,
  startPageLoading,
  stopLoading,
  stopPageLoading,
} from '../../redux/slices/permission'
import { initialState } from '../../redux/slices/permission/model'
import {
  getPermission,
  getPermissionById,
  getStaffList,
  putPostPermissionGroup,
} from '../../services/permission'

export const handleFetchData = (props) => async (dispatch) => {
  const { onEdit, setOnEdit, isEditForm, uuid } = props
  if (onEdit) return

  dispatch(getPermission())
  dispatch(getStaffList())

  if (isEditForm) {
    dispatch(startPageLoading())
    await dispatch(fetchPermissionById(uuid))
  } else {
    dispatch(setInitialForm(initialState.data))
  }

  setOnEdit(true)
}

export const fetchPermissionById = (uuid) => async (dispatch) => {
  const response = await dispatch(getPermissionById(uuid))
  const resType = _.get(response, 'type', '')

  if (resType == 'permission/getPermissionById/fulfilled') {
    const data = _.get(response, 'payload', null)
    let convertPermission = []
    let arrayPermissionGroup = []
    let permissionGroup = ''
    const permission = _.get(data, 'permission', [])
    const status =
      _.get(data, 'status', '').toString().toUpperCase() === 'ACTIVE'

    if (permission.length) {
      permission.map((item) => {
        convertPermission.push(item.uuid)
        arrayPermissionGroup.push(item.id)
      })
    }

    if (arrayPermissionGroup.length) {
      arrayPermissionGroup.sort(function (a, b) {
        return a - b
      })
      permissionGroup = arrayPermissionGroup.join(',')
    }

    const initialValues = {
      ...data,
      title: _.get(data, 'groupName', ''),
      status,
      permission: convertPermission,
      permissionGroup: permissionGroup,
    }

    dispatch(setInitialForm(initialValues))
  }

  dispatch(stopPageLoading())
}

export const submitPermission = (values, isEditForm) => async (dispatch) => {
  const method = isEditForm ? 'put' : 'post'
  const staff = _.get(values, 'staff', []).map((item) => ({
    uuid: item.uuid,
  }))
  const body = isEditForm
    ? {
        uuid: values.uuid,
        groupName: values.groupName,
        permission: values.permission,
        staff,
      }
    : {
        groupName: values.groupName,
        permission: values.permission,
        staff,
      }

  const response = await dispatch(putPostPermissionGroup({ method, body }))
  dispatch(stopLoadingDialog())
  const resType = _.get(response, 'type', '')
  if (resType == 'permission/putPostPermissionGroup/fulfilled') {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'บันทึกสำเร็จ',
        handleConfirm: () => (window.location.href = '/manage/permission'),
      })
    )
  }
  dispatch(stopLoading())
}

export const handleSubmitForm = (values, isEditForm) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      handleConfirm: () => {
        dispatch(loadingDialog())
        dispatch(submitPermission(values, isEditForm))
      },
    })
  )
}
