import _ from 'lodash'

export const handleInitialForm = (initialValues, setSettingPoint, formik) => {
  const pointStatus = _.get(initialValues, 'pointStatus', 'INACTIVE')
  const pointType = _.get(initialValues, 'pointType', 'CUSTOM')
  const point = _.get(initialValues, 'point', '')
  const learningPoint = _.get(initialValues, 'learningPoint', '') || ''

  const documents = {
    pointStatus,
    pointType,
    point,
    learningPoint,
  }

  setSettingPoint(documents)
  formik.setFieldValue('pointStatus', pointStatus)
  formik.setFieldValue('pointType', pointType)
  formik.setFieldValue('point', point)
  formik.setFieldValue('learningPoint', learningPoint)
}

export const handleSettingStatusChange = (e, setInitialValues) => {
  const pointStatus = _.get(e, 'target.checked', false) ? 'ACTIVE' : 'INACTIVE'

  setInitialValues((prev) => ({
    ...prev,
    pointStatus: pointStatus,
  }))
}

export const handleSettingRadioChange = (e, setInitialValues) => {
  const pointType = _.get(e, 'target.value', 'CUSTOM')
  setInitialValues((prev) => ({
    ...prev,
    learningPoint: '',
    point: '',
    pointType: pointType,
  }))
}
