import _ from 'lodash'
import { PRODUCT_TYPE, ASSET_DATA_FIELD } from '../../../../../constants/stock'

export const getBodyMutation = (data) => {
  const productType = _.get(data, 'productType')
  let productSku = {
    ..._.get(data, 'ticketDetail[0].product', ''),
    label: _.get(data, 'ticketDetail[0].product.productName', '-'),
    value: _.get(data, 'ticketDetail[0].product.uuid', undefined),
  }

  let product =
    productType !== PRODUCT_TYPE.STOCK
      ? _.get(data, 'ticketDetail[0].product', '')
      : _.get(data, 'product', '')

  if (
    productType !== PRODUCT_TYPE.STOCK &&
    !_.get(data, 'ticketDetail[0].product')
  ) {
    const type = ASSET_DATA_FIELD[_.get(data, 'type')]
    product = _.get(data, type, '')
    productSku = _.get(data, type, '')
  }

  return {
    id: _.get(data, 'id', ''),
    from: _.get(data, 'from', ''),
    to: _.get(data, 'to', ''),
    type: _.get(data, 'type', 'stock'),
    remark: _.get(data, 'remark', ''),
    uuid: _.get(data, 'uuid', undefined),
    createdAt: _.get(data, 'createdAt', ''),
    createdBy: _.get(data, 'createdBy', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    product,
    productStockAsset: productSku,
    productAsset: productSku,
    productLicense: productSku,
    quantity: _.get(data, 'quantity', null),
    unitPrice: _.get(data, 'unitPrice', null),
    stockValue: _.get(data, 'stockValue', null),
    ticketDetail: _.get(data, 'ticketDetail', []),
    skuNo: _.get(data, 'skuNo', ''),
    costCenter: _.get(data, 'costCenter', ''),
    assetNo: _.get(data, 'assetNo', ''),
    contractStartDate: _.get(data, 'contractStartDate', null),
    contractEndDate: _.get(data, 'contractEndDate', null),
    checkEndDate: _.get(data, 'checkEndDate', false),
    renewContractDate: _.get(data, 'renewContractDate', null),
    holder: _.get(data, 'holder', ''),
    ownerFName: _.get(data, 'ownerFName', ''),
    ownerLName: _.get(data, 'ownerLName', ''),
    ownerEmail: _.get(data, 'ownerEmail', ''),
    payer: _.get(data, 'payer', ''),
    payerFName: _.get(data, 'payerFName', ''),
    payerLName: _.get(data, 'payerLName', ''),
    payerEmail: _.get(data, 'payerEmail', ''),
    paymentType: _.get(data, 'paymentType', ''),
    paymentDesc: _.get(data, 'paymentDesc', ''),
    status: _.get(data, 'status', ''),
  }
}
