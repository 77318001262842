import { Box, Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import { shallowEqual, useSelector } from 'react-redux'
import { LabelWithValue } from '../LabelWithValue'
import { SummaryByCostCenter } from './components/SummaryByCostCenter'
import { SummaryByAccountCode } from './components/SummaryByAccountCode'
import { ButtonShowSummaryOther } from './components/ButtonShowSummaryOther'

export const SummarySection = () => {
  const theme = useTheme()
  const { expenseSummary, expenseList, showSummaryOther } = useSelector(
    (state) => ({
      expenseSummary: state.crud.formData.expenseSummary,
      expenseList: state.crud.formData.expenseList,
      showSummaryOther: state.crud.formData.showSummaryOther,
    }),
    shallowEqual,
  )

  return (
    <>
      <Typography variant="h6">Expense & Reimburse</Typography>
      {expenseList?.length > 0 && (
        <Box
          sx={{ backgroundColor: theme.palette?.other?.filledInputBackground }}
          borderRadius={2}
          p={2}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <LabelWithValue
            variant="body1b"
            label="Expense summary"
            value={expenseSummary?.toLocaleString()}
            unit="บาท"
          />
          {showSummaryOther && (
            <Box display="flex" flexDirection="column" gap={1}>
              <SummaryByCostCenter />
              <Divider />
              <SummaryByAccountCode />
            </Box>
          )}
          <ButtonShowSummaryOther />
        </Box>
      )}
    </>
  )
}
