import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { fetchDataList } from './events'
import {
  LoadingCircular,
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from './Styled'
import { breadcrumbList } from './model'
import Header from './Header'
import FilterDrawer from './FilterDrawer'
import { handleFiltering } from './FilterDrawer/events'

const Catalog = () => {
  const dispatch = useDispatch()

  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(handleFiltering(table, filter))
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page,
        status,
        filterProp,
        keyword: searchText,
        setInitial,
      }),
    )
  }, [])
  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page,
          status,
          filterProp,
          keyword: searchText,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">Catalog Management</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>

          <Header />
        </StyledHeader>
        <StyledCard id="listTable">
          <Table />
        </StyledCard>
        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Catalog
