import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialogBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'start',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))
