import {
  FILE_TYPE,
  MODULE_STATUS,
  MODULE_TYPE,
} from '../../../constants/eLearning'

export const breadcrumbList = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Module', link: '/', pointer: false },
]

export const breadcrumbCreate = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Module', link: '/e-learning/module', pointer: true },
  { title: 'สร้าง Module', link: '/', pointer: false },
]

export const breadcrumbEdit = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Module', link: '/e-learning/module', pointer: true },
  { title: 'แก้ไข Module', link: '/', pointer: false },
]

export const breadcrumbView = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Module', link: '/e-learning/module', pointer: true },
  { title: 'รายละเอียด Module', link: '/', pointer: false },
]

export const DEFUALT_MODULE_TYPE = [
  MODULE_TYPE.E_TESTING,
  MODULE_TYPE.E_EVALUATION,
  MODULE_TYPE.EMBED_CONTENT,
  MODULE_TYPE.LINK_TO,
  MODULE_TYPE.UPLOAD_FILE,
  MODULE_TYPE.SCORM,
]
export const DEFUALT_MODULE_STATUS = [
  MODULE_STATUS.ACTIVE,
  MODULE_STATUS.INACTIVE,
  MODULE_STATUS.DELETED,
]
export const DEFUALT_FILE_TYPE = [
  FILE_TYPE.DOC,
  FILE_TYPE.DOCX,
  FILE_TYPE.PPT,
  FILE_TYPE.PPTX,
  FILE_TYPE.PDF,
  FILE_TYPE.ZIP,
]
