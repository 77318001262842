export const contentTypeEnum = {
  TEXT: {
    name: 'ข้อความ',
    key: 'TEXT',
  },
  FILE: {
    name: 'ไฟล์',
    key: 'FILE',
  },
  LINK: {
    name: 'ลิงก์มีเดีย',
    key: 'LINK',
  },
  IMAGE: {
    name: 'รูปภาพ',
    key: 'IMAGE',
  },
  KB: {
    name: 'เพิ่มตอบคำถาม',
    key: 'KB',
  },
}
