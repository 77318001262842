import React, { useState } from 'react'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Modal from '@mui/material/Modal'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import { LoadingPage, StyledContent, StyledModalHeader } from './Styled'
import { shallowEqual, useSelector } from 'react-redux'
import { LINK, UPLOAD_FILE } from './RadioForm'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '768px',
  backgroundColor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 3,
}

export const RADIO_TYPE = {
  PHYSICAL: 'physical',
  ELECTRONIC: 'electronic',
}

export const SUB_RADIO_TYPE = {
  E_CONTENT: 'E_CONTENT',
  UPLOAD_FILE: 'UPLOAD_FILE',
  LINK: 'LINK',
}

const MaterialSettingForm = ({
  openModal,
  handleClose,
  setInitialValues,
  initialValues,
}) => {
  const [radioType, setRadioType] = useState(RADIO_TYPE.ELECTRONIC)
  const [subRadioType, setSubRadioType] = useState(SUB_RADIO_TYPE.UPLOAD_FILE)
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.manageClassDocs.isLoading,
    }),
    shallowEqual
  )
  const CONTENT = ({ handleClose }) => {
    if (radioType === RADIO_TYPE.ELECTRONIC) {
      if (subRadioType === SUB_RADIO_TYPE.UPLOAD_FILE) {
        return (
          <UPLOAD_FILE
            handleClose={handleClose}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        )
      } else if (subRadioType === SUB_RADIO_TYPE.LINK) {
        return (
          <LINK
            handleClose={handleClose}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        )
      }
    }
    return null
  }
  return (
    <Modal open={openModal} disableEnforceFocus={true}>
      <Box sx={style}>
        <StyledContent isLoading={isLoading}>
          <StyledModalHeader>
            <Typography variant="h6" color="text.primary">
              เอกสารประกอบการเรียน
            </Typography>
          </StyledModalHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginBottom: 3,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="body2" color={'text.secondary'}>
                ประเภท
              </Typography>
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </Box>

            <RadioGroup
              sx={{ ml: 1 }}
              aria-label="radioType"
              defaultValue={radioType}
              name="radioType"
              id="radioType"
              value={radioType}
              onChange={(e) => {
                const { value } = e.target
                setRadioType(value)
              }}
            >
              {/* <FormControlLabel
                value={RADIO_TYPE.PHYSICAL}
                control={<Radio />}
                label="Physical material"
              /> */}
              <FormControlLabel
                value={RADIO_TYPE.ELECTRONIC}
                control={<Radio />}
                label="Electronic file / Link"
              />
            </RadioGroup>
            {radioType === RADIO_TYPE.ELECTRONIC && (
              <Box sx={{ marginLeft: 4 }}>
                <RadioGroup
                  sx={{ ml: 1 }}
                  aria-label="subRadioType"
                  defaultValue={subRadioType}
                  name="subRadioType"
                  id="subRadioType"
                  value={subRadioType}
                  onChange={(e) => {
                    const { value } = e.target
                    setSubRadioType(value)
                  }}
                >
                  {/* <FormControlLabel
                    value={SUB_RADIO_TYPE.E_CONTENT}
                    control={<Radio />}
                    label="เลือกจาก E-Content module"
                  /> */}
                  <FormControlLabel
                    value={SUB_RADIO_TYPE.UPLOAD_FILE}
                    control={<Radio />}
                    label="Upload file"
                  />
                  <FormControlLabel
                    value={SUB_RADIO_TYPE.LINK}
                    control={<Radio />}
                    label="Link"
                  />
                </RadioGroup>
              </Box>
            )}
          </Box>
          <CONTENT handleClose={handleClose} />
        </StyledContent>
        <LoadingPage isLoading={isLoading} />
      </Box>
    </Modal>
  )
}

export default MaterialSettingForm
