export const listBreadcrumb = (uuid) => {
  return [
    { title: 'จัดการคลาส', link: '/manage-class', pointer: true },
    {
      title: 'ผู้เรียน',
      link: `/manage-class/learner/${uuid}`,
      pointer: true,
    },
    { title: 'รายละเอียดผู้เรียน', link: '/', pointer: false },
  ]
}
