import dayjs from 'dayjs'

export const arrayCheckbox = [
  {
    label: 'วันที่สอบ',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'สนามสอบบริษัท',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'DROPDOWN_MULTI',
    list: [],
  },
  {
    label: 'สนามสอบสมาคม',
    nameCheckBox: 'associationChecked',
    nameRegCheckBox: 'associationRegionChecked',
    nameProvCheckBox: 'associationProvinceChecked',
    nameInput: 'association',
    nameInputReg: 'associationRegion',
    nameInputProv: 'associationProvince',
    type: 'REGION_PROVINCE',
  },
  {
    label: 'สนามสอบอื่นๆ',
    nameCheckBox: 'otherFilterChecked',
    nameRegCheckBox: 'otherFilterRegionChecked',
    nameProvCheckBox: 'otherFilterProvinceChecked',
    nameInput: 'otherFilter',
    nameInputReg: 'otherFilterRegion',
    nameInputProv: 'otherFilterProvince',
    type: 'REGION_PROVINCE',
  },
]

export const defaultFilter = {
  stationChecked: false,
  dateChecked: true,
  startDate: new Date(dayjs().startOf('Month').format('YYYY-MM-DD')),
  endDate: new Date(dayjs().format('YYYY-MM-DD')),
  displayDate: true,
  associationChecked: false,
  associationRegionChecked: false,
  associationProvinceChecked: false,
  associationRegionList: [],
  associationProvinceList: [],
  otherFilterChecked: false,
  otherFilterRegionChecked: false,
  otherFilterProvinceChecked: false,
  otherFilterRegionList: [],
  otherFilterProvinceList: [],
}
