import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import React from 'react'
import { useSelector } from 'react-redux'
import { shallowEqual } from 'react-redux'
import { StyledBox, StyledContainer, StyledSubBox, StyledCard } from './Styled'
import _ from 'lodash'
import { licenseStatusText, rcmsStatusText } from '../../event'

const Detail = () => {
  const { agent_detail, rcms_detail } = useSelector(
    (state) => ({
      agent_detail: state.apiToRcms.agent_detail,
      rcms_detail: state.apiToRcms.rcms_detail,
    }),
    shallowEqual
  )
  return (
    <>
      <StyledCard id="listTable">
        <StyledContainer>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ lineHeight: '32px' }}
          >
            ข้อมูลการทำงาน
          </Typography>
          <StyledBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.secondary">
                รหัส Agent
              </Typography>
            </StyledSubBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.primary">
                {agent_detail?.fullAgentCode ?? '-'}
              </Typography>
            </StyledSubBox>
          </StyledBox>
          <StyledBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.secondary">
                ชื่อ - นามสกุล
              </Typography>
            </StyledSubBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.primary">
                {`${agent_detail?.prefix ?? ''} ${
                  agent_detail?.fullName ?? ''
                }`}
              </Typography>
            </StyledSubBox>
          </StyledBox>
          <StyledBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.secondary">
                ประเภท Agent
              </Typography>
            </StyledSubBox>
            <StyledSubBox>
              <Typography variant="body1" color="text.primary">
                {agent_detail?.agentCategory ?? '-'}
              </Typography>
            </StyledSubBox>
          </StyledBox>
        </StyledContainer>
      </StyledCard>
      <StyledCard id="listTable">
        <StyledContainer>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ lineHeight: '32px' }}
          >
            ข้อมูลใบอนุญาต
          </Typography>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ชื่อใบอนุญาต</TableCell>
                <TableCell>ประเภทใบอนุญาต</TableCell>
                <TableCell>วันมีผลบังคับ</TableCell>
                <TableCell>วันหมดอายุ</TableCell>
                <TableCell>สถานะใบอนุญาต</TableCell>
                <TableCell>ส่งข้อมูลไป RCMS</TableCell>
                <TableCell>Exemption Flag</TableCell>
                <TableCell>หมายเหตุ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(rcms_detail, (item, key) => (
                <TableRow key={key}>
                  <TableCell>{item?.description ?? '-'}</TableCell>
                  <TableCell>{item?.category ?? '-'}</TableCell>
                  <TableCell>{item?.effectiveDate ?? '-'}</TableCell>
                  <TableCell>{item?.expiryDate ?? '-'}</TableCell>
                  <TableCell>
                    {licenseStatusText(item?.licenseStatus)}
                  </TableCell>
                  <TableCell>{rcmsStatusText(item?.rcmsStatus)}</TableCell>
                  <TableCell>{item?.exemptionFlag ?? '-'}</TableCell>
                  <TableCell>{item?.remark ?? '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledContainer>
      </StyledCard>
    </>
  )
}

export default Detail
