import { headerCells } from './model/headerCells'
import { productWarehouseListFilter } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_PRODUCT_WAREHOUSE',
  moduleSlug: 'inventory-warproduct-warehouse',
  moduleTitleFull: 'ทรัพย์สิน',
  modulePath: '/inventory/product/stock',
  filterPath: productWarehouseListFilter,
  headerCells: headerCells,
}
