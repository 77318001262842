import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  apiToRcmsChangeStatus,
  apiToRcmsCheck,
  apiToRcmsCreate,
  apiToRcmsDetail,
  apiToRcmsFilterDetail,
  apiToRcmsSubmit,
  apiToRcmsUpload,
  apiToRcmsUploadDetail,
} from '../../utils/apiPath'

export const uploadFile = createAsyncThunk(
  'apiToRcms/uploadFile',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsUpload, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadCheck = createAsyncThunk(
  'apiToRcms/checkRcms',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsCheck, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCreateRcmsByAdmin = createAsyncThunk(
  'apiToRcms/postCreateRcmsByAdmin',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsCreate, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postStatusRcmsChangeByAdmin = createAsyncThunk(
  'apiToRcms/postStatusRcmsChangeByAdmin',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsChangeStatus, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadFileDetail = createAsyncThunk(
  'apiToRcms/uploadFileDetail',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsUploadDetail, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postUpdateRcmsByAdmin = createAsyncThunk(
  'apiToRcms/postUpdateRcmsByAdmin',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsDetail, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postFilterRcms = createAsyncThunk(
  'apiToRcms/postFilterRcms',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsFilterDetail, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postRcmsSubmit = createAsyncThunk(
  'apiToRcms/postRcmsSubmit',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(apiToRcmsSubmit, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
