import { Box } from '@mui/material'
import { Header } from './components/Header'
import { useTheme } from '@mui/system'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { Content } from './components/Content'

export const ExpenseList = () => {
  const theme = useTheme()
  const { expenseList } = useSelector(
    (state) => ({
      expenseList: state.crud.formData.expenseList,
    }),
    shallowEqual,
  )

  return (
    <>
      {expenseList?.length > 0 && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          position="relative"
          minHeight={100}
        >
          {_.map(expenseList, (exp, idx) => {
            return (
              <Box
                sx={{
                  background: `${theme.palette?.info?.background}`,
                }}
                borderRadius={2}
                px={2}
                pb={2}
                pt={1.5}
              >
                <Header idx={idx} {...exp} />
                {_.get(exp, 'showDetails', true) && <Content {...exp} />}
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}
