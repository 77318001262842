import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledDrawerWrapper = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  minWidth: 1034,
  maxWidth: 1034,
}))

export const StyledDrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(3),
}))

export const BoxLabel = styled(Box)({ display: 'flex' })

export const LabelText = styled(Typography)(() => ({
  width: 200,
  minWidth: 200,
  marginRight: '24px',
}))

export const StyledWrapRow = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  wordWrap: 'break-word',
}))
