import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Dot from './Dot'
import UserLevelList from './UserLevelList'
import Action from './Action'
import ActionPermission from './Action/ActionPermission'
import ActionStaff from './Action/ActionStaff'
import ActionSpeaker from './Action/ActionSpeaker'
import ActionCourse from './Action/ActionCourse'
import Search from '../Search/Search'
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { visuallyHidden } from '@mui/utils'
import useTheme from '@mui/system/useTheme'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TruncateMarkup from 'react-truncate-markup'
import _ from 'lodash'
import { ROW_PAGE_PER_LIST } from '../../constants/table'
import ActionEvaDashboard from './NormalTable/Action/ActionEvaDashboard'

export const descendingComparator = (a, b, orderBy) => {
  let orderByType = 0
  if (b[orderBy] < a[orderBy]) {
    orderByType = -1
  } else if (b[orderBy] > a[orderBy]) {
    orderByType = 1
  }
  return orderByType
}

export const getComparator = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => descendingComparator(a, b, orderBy)
  } else {
    return (a, b) => -descendingComparator(a, b, orderBy)
  }
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const EnhancedTableHead = ({
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  isCheckBox,
  headCells,
  rows,
  setSelected,
  setSelectedProps,
  defaultSort,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow
        key="head"
        sx={{ '& .MuiTableCell-root': { borderColor: '#EDEDED' } }}
      >
        {isCheckBox && (
          <TableCell sx={{ py: 0, px: '13px !important', width: 24 }}>
            <Checkbox
              sx={{ p: 0 }}
              data-testid="select-all-element"
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) =>
                handleSelectAllClick(event, rows, setSelected, setSelectedProps)
              }
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            ></Checkbox>
          </TableCell>
        )}
        {headCells.map((headCell) => {
          const valueId = headCell.id === 'userLevel' ? 'level' : headCell.id
          let checkActive = false
          if (valueId === defaultSort.id) {
            checkActive = defaultSort.active
          }

          return (
            <TableCell
              key={valueId}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === valueId ? order : false}
              sx={{
                width: headCell.width ? headCell.width : 'unset',
                minWidth: headCell.minWidth ? headCell.minWidth : 'unset',
              }}
            >
              <TableSortLabel
                data-testid={`sort-cell-${valueId}`}
                active={checkActive}
                direction={orderBy === valueId ? order : 'asc'}
                onClick={!headCell.hideSortIcon && createSortHandler(valueId)}
                hideSortIcon={headCell.hideSortIcon}
                sx={{
                  m: headCell.marginLabel ? headCell.marginLabel : 0,
                  cursor: !headCell.hideSortIcon ? 'pointer' : 'unset',
                }}
              >
                {headCell.label}
                {orderBy === valueId && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export const EnhancedTableToolbar = (props) => {
  const {
    selected,
    placeholder,
    searchText,
    setSearchText,
    searchKey,
    setRows,
    data,
    handleSearch,
    onDownload,
    setIsFilterDrawer,
    setPage,
    text,
    setText,
    customToolbar,
    filterTotal = 0,
    hideFilter = false,
    toolBarBoxSx,
    tableTitle,
    searchBoxSx,
  } = props
  const theme = useTheme()
  return (
    <Toolbar
      sx={{
        pt: 0.5,
        pb: 3,
        pl: { sm: 2 },
        pr: { xs: 0, sm: 0 },
      }}
    >
      <Box
        sx={{
          mr: 2.5,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'unset',
          },
          ...toolBarBoxSx,
        }}
      >
        {!_.isNil(tableTitle) && (
          <Typography variant="h6">{tableTitle}</Typography>
        )}
        {searchKey.length > 0 && (
          <Search
            placeholder={placeholder}
            searchText={searchText}
            setSearchText={setSearchText}
            searchKey={searchKey}
            handleSearch={handleSearch}
            text={text}
            setText={setText}
            data={data}
            setRows={setRows}
            setPage={setPage}
            boxSx={searchBoxSx}
          />
        )}
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          {customToolbar && <>{customToolbar}</>}
          {onDownload !== 'hidden' && (
            <Button
              data-testid={'btn-download'}
              sx={{ height: 40, p: 1 }}
              variant="text"
              startIcon={<FileDownloadOutlinedIcon />}
              disabled={selected.length > 0 ? false : true}
              onClick={() => onDownload(selected)}
            >
              ดาวน์โหลด
            </Button>
          )}
          {!hideFilter && (
            <Button
              data-testid={'btn-filter'}
              sx={{ height: 40, p: 1 }}
              variant="text"
              startIcon={<FilterListOutlinedIcon />}
              onClick={() => setIsFilterDrawer(true)}
            >
              ตัวกรอง {filterTotal != 0 && `(${filterTotal})`}
            </Button>
          )}
        </Box>
      </Box>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}
const courseStatus = {
  DRAFT: 'ฉบับร่าง',
  WAITING_FOR_ACTIVE: 'รอเปิดใช้งาน',
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
  WAITING: 'รอการอนุมัติ',
  APPROVE: 'อนุมัติ',
  CANCEL: 'ยกเลิกคำขอ',
  REJECT: 'ไม่อนุมัติ',
}
export const checkConditionView = (
  headCells,
  row,
  rowIndex,
  cellIndex,
  onView,
  onEdit,
  onDelete,
) => {
  const columnKey = headCells[cellIndex].id
  if (headCells[cellIndex].id.toString().toLowerCase() === 'status') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={row[`${headCells[cellIndex].id}`]} />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'statusText') {
    return (
      <TableCell
        key={columnKey}
        sx={row?.statusVersion === 'DELETED' ? { color: 'text.silver' } : ''}
      >
        <div>{courseStatus[row.status]}</div>
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'statusCourse') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        {row.statusCourse === 'DELETED' && (
          <Dot status={row[`${headCells[cellIndex].id}`]} />
        )}
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'statusVersion') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        {row.statusVersion === 'DELETED' && (
          <Dot status={row[`${headCells[cellIndex].id}`]} />
        )}
      </TableCell>
    )
  } else if (headCells[cellIndex].isArray) {
    return (
      <TableCell key={columnKey} sx={{ maxWidth: 280 }}>
        <UserLevelList
          key={rowIndex}
          levelList={row[`${headCells[cellIndex].id}`]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'action') {
    return (
      <TableCell key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'id') {
    return (
      <TableCell
        key={columnKey}
        sx={
          row?.status === 'DELETED'
            ? { minWidth: 100, color: 'text.silver' }
            : { minWidth: 100 }
        }
      >
        {row[`${headCells[cellIndex].id}`]}
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'statusApprove') {
    return (
      <TableCell key={columnKey} align="center" sx={{ minWidth: 100 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Dot
            sx={{ mt: 1.5, mr: 1.5 }}
            status={row[`${headCells[cellIndex].id}`]}
          />
          <Typography sx={{ fontSize: '18px' }}>
            {courseStatus[row[`${headCells[cellIndex].id}`]]}
          </Typography>
        </Box>
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'actionPermission') {
    return (
      <TableCell key={columnKey}>
        <ActionPermission
          row={row}
          onView={onView}
          onDelete={onDelete}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'actionStaff') {
    return (
      <TableCell key={columnKey}>
        <ActionStaff
          row={row}
          rowIndex={rowIndex}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'actionSpeaker') {
    return (
      <TableCell key={columnKey}>
        <ActionSpeaker
          row={row}
          rowIndex={rowIndex}
          onView={onView}
          onDelete={onDelete}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].id === 'actionCourseVersion') {
    return (
      <TableCell key={columnKey}>
        <ActionCourse
          row={row}
          rowIndex={rowIndex}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (headCells[cellIndex].custom) {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 150 }}>
        {row[headCells[cellIndex].id]}
      </TableCell>
    )
  } else if (headCells[cellIndex].isTruncatedDialog) {
    return (
      <TableCell key={columnKey}>
        <ActionEvaDashboard row={row} condition={headCells[cellIndex]} />
      </TableCell>
    )
  }
  return (
    <TableCell
      key={columnKey}
      sx={
        row?.actionStaff === 'INACTIVE' ||
        row?.statusCourse === 'DELETED' ||
        row?.statusVersion === 'DELETED' ||
        row?.status === 'DELETED'
          ? { minWidth: 100, color: 'text.silver' }
          : { minWidth: 100 }
      }
    >
      <Truncate>{'' + _.get(row, `${headCells[cellIndex].id}`, '-')}</Truncate>
    </TableCell>
  )
}

const Truncate = ({ children }) => {
  const [expand, setExpand] = useState(false)
  const [isTruncated, setTruncate] = useState(false)
  const [flag, setFlag] = useState(false)
  const [width, setWidth] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [width])

  useEffect(() => {
    setExpand(false)
    setFlag((prev) => !prev)
  }, [children])

  return (
    <div ref={ref}>
      {!expand && flag && (
        <TextWithTruncate
          width={width}
          isTruncated={isTruncated}
          setTruncate={setTruncate}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {!expand && !flag && (
        <TextWithTruncate
          width={width}
          isTruncated={isTruncated}
          setTruncate={setTruncate}
          setExpand={setExpand}
        >
          {children}
        </TextWithTruncate>
      )}
      {expand && (
        <div
          onClick={() => setExpand(false)}
          style={{
            maxWidth: width,
            display: 'flex',
            flexDirection: 'row',
            cursor: isTruncated ? 'pointer' : 'default',
            wordBreak: 'break-all',
          }}
        >
          {children} {isTruncated && <KeyboardArrowUpIcon />}
        </div>
      )}
    </div>
  )
}

export const TextWithTruncate = (props) => {
  const { width, isTruncated, setTruncate, setExpand, children } = props
  return (
    <TruncateMarkup
      line="1"
      onTruncate={(isTrunC) => setTruncate(isTrunC)}
      ellipsis={<span>... </span>}
    >
      <div
        onClick={() => setExpand(true)}
        style={{
          maxWidth: width,
          display: 'flex',
          flexDirection: 'row',
          cursor: isTruncated ? 'pointer' : 'default',
          wordBreak: 'break-all',
          verticalAlign: 'middle',
        }}
      >
        {children}
      </div>
    </TruncateMarkup>
  )
}

export const handleSelectAllClick = (
  event,
  rows,
  setSelected,
  setSelectedProps,
) => {
  if (event?.target?.checked) {
    const newSelecteds = rows.map((n) => n.uuid)
    setSelected(newSelecteds)
    setSelectedProps && setSelectedProps(newSelecteds)
    return
  }
  setSelected([])
  setSelectedProps && setSelectedProps([])
}

export const handleChangePage = (newPage, setTablePage, setPage) => {
  const realPage = newPage < 0 ? 1 : +newPage
  setPage(newPage + 1)
  setTablePage(realPage)
}

export const handleChangeRowsPerPage = (
  event,
  setRowsPerPage,
  setPage,
  setLimit,
  resetPage,
) => {
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1
  setRowsPerPage({ label: '' + rowsPerPageChange, value: rowsPerPageChange })
  setLimit(parseInt(event.target.value, 10))
  setPage(0)
  resetPage(0)
}

const EnhancedTable = ({
  data,
  headCells,
  isCheckBox = true,
  onView,
  onEdit,
  onDelete,
  handleSearch,
  onDownload,
  placeholder = '',
  searchKey = '',
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  sort: orderBy,
  setSort: setOrderBy,
  allCount,
  setIsFilterDrawer,
  searchText,
  setSearchText,
  tablePage,
  setTablePage,
  text,
  setText,
  defaultSortId = 'updatedAt',
  isShowPagination = true,
  selectedProps = [],
  setSelectedProps = null,
  isLoading = false,
  customToolbar,
  filterTotal,
  hideFilter = false,
  toolBarBoxSx = {},
  tableTitle,
  searchBoxSx,
}) => {
  const theme = useTheme()
  const [rows, setRows] = useState([])
  const [selected, setSelected] = React.useState(selectedProps)
  const [rowsPerPage, setRowsPerPage] = useState({ label: '100', value: 100 })

  const [defaultSort, setDefaultSort] = useState({
    state: 0,
    id: '',
    active: false,
  })

  const handleRequestSort = (event, property) => {
    let isAsc = orderBy === property && order === 'asc'
    let stateSort = defaultSort.state + 1
    let activeSort = true

    if (defaultSort.state >= 2) {
      stateSort = 0
      activeSort = false
    }
    let valueSort = !defaultSort.id ? property : defaultSort.id
    if (!!defaultSort.id && defaultSort.id !== property) {
      stateSort = 1
      valueSort = property
      activeSort = true
    }

    setDefaultSort({
      state: stateSort,
      id: valueSort,
      active: activeSort,
    })
    isAsc = isAsc ? 'desc' : 'asc'
    if (!activeSort) {
      property = defaultSortId
      isAsc = 'desc'
    }
    setOrder(isAsc)
    setOrderBy(property)
  }

  useEffect(() => {
    if (selectedProps.length) {
      setSelected(selectedProps)
    }
    if (!isLoading) setRows(data)
  }, [data, selectedProps])
  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
    setSelectedProps && setSelectedProps(newSelected)
  }

  const isSelected = (name) => selected?.indexOf(name) !== -1

  return (
    <Box sx={{ width: '100%' }}>
      {(onDownload || setIsFilterDrawer || searchText) && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          placeholder={placeholder}
          searchText={searchText}
          setSearchText={setSearchText}
          searchKey={searchKey}
          setRows={setRows}
          data={data}
          handleSearch={handleSearch}
          onDownload={onDownload}
          setIsFilterDrawer={setIsFilterDrawer}
          setPage={setPage}
          text={text}
          setText={setText}
          customToolbar={customToolbar}
          filterTotal={filterTotal}
          hideFilter={hideFilter}
          toolBarBoxSx={toolBarBoxSx}
          tableTitle={tableTitle}
          searchBoxSx={searchBoxSx}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            isCheckBox={isCheckBox}
            headCells={headCells}
            rows={rows}
            setSelected={setSelected}
            setSelectedProps={setSelectedProps}
            defaultSort={defaultSort}
          />
          {!isLoading && (
            <TableBody>
              {rows.map((row, rowIndex) => {
                const isItemSelected = isSelected(row.uuid)
                const labelId = `enhanced-table-checkbox-${rowIndex}`
                return (
                  <TableRow
                    key={rowIndex}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    color="primary"
                    type="hover"
                    sx={{
                      '&.Mui-selected:hover': {
                        background: 'rgba(73, 118, 186, 0.12)',
                      },
                      '&.Mui-selected': {
                        background:
                          theme.palette?.primary?.outlinedHoverBackground,
                      },
                      '& .MuiTableCell-root': {
                        py: 0,
                        height: 52,
                        borderColor: theme?.palette?.action?.disabledBackground,
                      },
                    }}
                  >
                    {isCheckBox && (
                      <TableCell
                        sx={{ py: 0, px: '13px !important', width: 24 }}
                      >
                        <Checkbox
                          sx={{ p: 0 }}
                          data-testid={`select-${rowIndex}-element`}
                          onClick={(event) => handleClick(event, row.uuid)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {headCells.map((headItem, cellIndex) =>
                      checkConditionView(
                        headCells,
                        row,
                        rowIndex,
                        cellIndex,
                        onView,
                        onEdit,
                        onDelete,
                      ),
                    )}
                  </TableRow>
                )
              })}
              {rows.length === 0 && (
                <TableCell
                  key={'no-data'}
                  colSpan={headCells.length}
                  align="center"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <InboxTwoToneIcon
                      sx={{
                        color: theme?.palette?.text?.silver,
                        width: '27px',
                        height: '27px',
                      }}
                    />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </TableCell>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isShowPagination && !isLoading && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => {
            const realPage = tablePage <= 0 ? 1 : tablePage + 1
            let minRowsShowed = page < 0 ? 1 : rowsPerPage.value * tablePage + 1
            let maxRowsShowed = 0

            if (rowsPerPage.value * realPage > allCount) {
              maxRowsShowed = allCount
            } else {
              if (realPage === 1) {
                maxRowsShowed = rows.length + realPage - 1
              } else {
                maxRowsShowed = rows.length * realPage
              }
            }
            if (maxRowsShowed == 0) {
              minRowsShowed = 0
            } else if (maxRowsShowed < 0) {
              maxRowsShowed = allCount
            }
            return (
              <Typography variant="caption" color="text.secondary">
                {minRowsShowed}-{maxRowsShowed} of {allCount}
              </Typography>
            )
          }}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(e, tPage) =>
            handleChangePage(tPage, setTablePage, setPage)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(
              event,
              setRowsPerPage,
              setTablePage,
              setLimit,
              setPage,
            )
          }
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      )}
    </Box>
  )
}

export default EnhancedTable
