import React, { useState } from 'react'
import { isMediaWidthMoreThan } from '../../../utils/lib'
import MediaBox from './components/MediaBox'
import TabletDesktopContent from './TabletDesktopContent'
import MobileContent from './MobileContent'
import { StyledWrapper, StyledCard } from './Styled'

const ElearningCourseCard = ({ course, isPath, isCollection }) => {
  const [isHover, setHover] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isNotELearning = isPath || isCollection
  return (
    <StyledWrapper>
      {!isNotSm && <MediaBox course={course} isNotELearning={isNotELearning} />}
      <StyledCard
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isNotSm ? (
          <TabletDesktopContent
            course={course}
            isHover={isHover}
            isPath={isPath}
            isCollection={isCollection}
          />
        ) : (
          <MobileContent
            course={course}
            isPath={isPath}
            isCollection={isCollection}
          />
        )}
      </StyledCard>
    </StyledWrapper>
  )
}

export default ElearningCourseCard
