import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { onFilterClick } from '../List/event'
import { getExaminationRegionFilter } from '../../../services/eExamination'
import { setRegionsList } from '../../../redux/slices/eExamination/list'

export const fetchRegionsFilter = () => async (dispatch) => {
  const body = {
    regionName: '',
    status: [],
    createdBy: '',
    updatedBy: '',
    limit: 20,
    order: 'ASC',
    page: 1,
    sort: 'regionName',
  }
  const response = await dispatch(getExaminationRegionFilter(body))
  let results = _.get(response, 'payload.data.result', [])
  dispatch(setRegionsList(results))
}

export const handleResetFilter = (setFilter, defaultFilter) => {
  setFilter(defaultFilter)
}

export const handleFiltering = (table, filter) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))

  dispatch(onFilterClick(table, filter))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    if (listKey === 'region') {
      if (!value) {
        const newObjState = objState[listKey].filter((item) => item !== key)
        objState = {
          ...filter,
          [listKey]: newObjState,
        }
      } else {
        objState = {
          ...filter,
          [listKey]: [...filter[listKey], key],
        }
      }
    } else {
      objState = {
        ...filter,
        [listKey]: {
          ...filter[listKey],
          [key]: value,
        },
      }
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (
  nameInput,
  selectedDates,
  filter,
  setFilter,
) => {
  let objState = {
    ...filter,
    [nameInput]: {
      startDate: selectedDates.startDate,
      toDate: selectedDates.endDate,
      displayDate: selectedDates.display,
    },
  }
  setFilter(objState)
}
