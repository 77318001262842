import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import DatePicker from '../../../../components/Input/DatePicker'

import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import DateRangeInput from '../../../../components/DateRangeInput'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../components/FilterDrawer/Styled'
import {
  arrayCheckbox,
  arrayFlagStatus,
  arrayLicenseStatus,
  arrayRcmsStatus,
  defaultFilter,
} from './model'
import * as events from './events'
import { handleNumberInput } from '../../../../utils/lib'

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {!item.isDate && !item?.isDate2 && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={
                    item.nameInput === 'version'
                      ? {
                          'data-testid': `input-${item.nameInput}`,
                          type: 'number',
                          min: '0',
                          onInput: handleNumberInput,
                          maxLength:
                            item?.maxLength > 0 ? item?.maxLength : 255,
                        }
                      : {
                          'data-testid': `input-${item.nameInput}`,
                          maxLength:
                            item?.maxLength > 0 ? item?.maxLength : 255,
                        }
                  }
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.isDate && (
                <DateRangeInput
                  placeholder={item.placeholder}
                  dateState={{
                    startDate: stateFilter.startDate ? stateFilter.startDate : new Date(),
                    endDate: stateFilter.toDate ? stateFilter.toDate : new Date(),
                    display: stateFilter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter
                    )
                  }}
                />
              )}
              {item?.isDate2 && (
                <DatePicker
                  hideTextError
                  placeholder={item.placeholder}
                  value={stateFilter.createdAt}
                  onChange={(selectedDates) => {
                    events.handleSelectDate2(
                      selectedDates,
                      stateFilter,
                      setFilter
                    )
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListFlagStatus = ({ drawerType, status, filter, setFilter }) => {
  return arrayFlagStatus.map((item, index) => {
    if (drawerType === 'none' && index <= 1) {
      return
    }
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'flag',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListLicenseStatus = ({
  drawerType,
  status,
  filter,
  setFilter,
}) => {
  return arrayLicenseStatus.map((item, index) => {
    if (drawerType === 'none' && index <= 1) {
      return
    }
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'licenseStatus',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListRcmsStatus = ({ drawerType, status, filter, setFilter }) => {
  return arrayRcmsStatus.map((item, index) => {
    if (drawerType === 'none' && index <= 1) {
      return
    }
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'rcmsStatus',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table, drawerType }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter[drawerType])
  const [filterMemo, setFilterMemo] = useState()
  const { id } = useParams()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter[drawerType])
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={arrayCheckbox[drawerType]}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="Flag"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-flagChecked',
              }}
              name="flagChecked"
              checked={filter.flagChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.flagChecked && (
          <StyledList>
            <ListFlagStatus
              drawerType={drawerType}
              status={filter.flag}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะใบอนุญาต"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-licenseStatusChecked',
              }}
              name="licenseStatusChecked"
              checked={filter.licenseStatusChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.licenseStatusChecked && (
          <StyledList>
            <ListLicenseStatus
              drawerType={drawerType}
              status={filter.licenseStatus}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label="การส่งข้อมูลไป RCMS"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-rcmsStatusChecked',
              }}
              name="rcmsStatusChecked"
              checked={filter.rcmsStatusChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.rcmsStatusChecked && (
          <StyledList>
            <ListRcmsStatus
              drawerType={drawerType}
              status={filter.rcmsStatus}
              filter={filter}
              setFilter={setFilter}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <ListCheckbox
        listArray={arrayCheckbox['api_to_rcms_filter']}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <ListCheckbox
        listArray={arrayCheckbox['api_to_rcms_date']}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter[drawerType])}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter, drawerType, id))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
