import { store } from '../../../../App'
import dayjs from 'dayjs'
import { monthlyPlanFilterTopList } from 'src/utils/apiPath'
import { callAPI, validateDate } from 'src/utils/lib'

export const fetchStaffByType = async (type) => {
  const {
    dashboard: { filters, staffType, searchText },
  } = store.getState().monthlyPlan

  const {
    stationList,
    defaultStation,
    departmentList,
    defaultDepartment,
    startDate,
    endDate,
    courseCodeList,
    positionList,
  } = filters

  const url = monthlyPlanFilterTopList

  const body = {
    type: type ? type : staffType,
    startDate: validateDate(startDate)
      ? dayjs(startDate).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: validateDate(endDate)
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    department: departmentList?.length > 0 ? departmentList : defaultDepartment,
    station: stationList?.length > 0 ? stationList : defaultStation,
    courseCode: courseCodeList?.length > 0 ? courseCodeList : [],
    position: positionList?.length > 0 ? positionList : [],
    searchText: searchText,
    limit: -1,
    page: 1,
  }

  const response = await callAPI({
    method: 'post',
    url,
    body,
  })

  return response
}
