import React from 'react'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="m" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledButtonDelete = styled((props) => (
  <Button variant="outlined" size="m" color="secondary" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))
