import React from 'react'
import Grid from '@mui/material/Grid'

export default function Login() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      CENTER
    </Grid>
  )
}
Login.templateLayout = 'login'
