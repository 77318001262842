import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import { store } from '../../../../App'
import { fetchBudgetManagementList } from './fetchBudgetManagementList'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'

export const handleSuccessfulDelete = () => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(closeDialog())
        fetchBudgetManagementList(mutateFilterPropToBody)
      },
    }),
  )
}
