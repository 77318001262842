import _ from 'lodash'

export const breadcrumbList = [
  { title: 'E-Evaluation', link: '/', pointer: false },
]

export const breadcrumbVersion = [
  { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
  { title: 'เวอร์ชัน', link: '/', pointer: false },
]

export const breadcrumbVersionView = (codeId) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: 'เวอร์ชัน',
      link: '/e-evaluation/version/' + codeId,
      pointer: true,
    },
    {
      title: 'รายละเอียดแบบประเมิน',
      link: '/e-evaluation/version',
      pointer: false,
    },
  ]
}

export const breadcrumbForm = (type) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: `สร้างแบบประเมิน (${_.capitalize(type) || ''})`,
      link: '/',
      pointer: false,
    },
  ]
}

export const breadcrumbFormEdit = (codeId, eEvaluationType, titleLabelEva) => {
  return [
    { title: 'E-Evaluation', link: '/e-evaluation', pointer: true },
    {
      title: 'เวอร์ชัน',
      link: '/e-evaluation/version/' + codeId,
      pointer: true,
    },
    {
      title: `${titleLabelEva} (${
        eEvaluationType === 'CLASS' ? 'Class' : 'Standalone'
      })`,
      link: '/',
      pointer: false,
    },
  ]
}

export const breadcrumbListDashboard = [
  { title: 'E-Evaluation', link: '/', pointer: false },
  { title: 'Dashboard', link: '/', pointer: false },
]

export const breadcrumbVersionDashboard = [
  { title: 'E-Evaluation', link: '/', pointer: false },
  { title: 'Dashboard', link: '/e-evaluation/dashboard', pointer: true },
  { title: 'เวอร์ชัน', link: '/', pointer: false },
]

export const breadcrumbVersionViewDashboard = (
  codeId,
  isManageClass = false,
  trainingPlanUuid = '',
) => {
  let breadcrumArray = [
    { title: 'E-Evaluation', link: '/', pointer: true },
    { title: 'Dashboard', link: '/e-evaluation/dashboard', pointer: true },
    {
      title: 'เวอร์ชัน',
      link: '/e-evaluation/dashboard/version/' + codeId,
      pointer: true,
    },
    {
      title: 'รายละเอียดแบบประเมิน',
      link: '/e-evaluation/dashboard/version',
      pointer: false,
    },
  ]
  if (isManageClass) {
    breadcrumArray = [
      { title: 'จัดการคลาส', link: '/', pointer: false },
      { title: 'คลาสทั้งหมด', link: '/manage-class', pointer: true },
      {
        title: 'รายละเอียดคลาส',
        link: `/manage-class/detail/${trainingPlanUuid}`,
        pointer: true,
      },
      { title: 'ภาพรวม', link: '/', pointer: false },
    ]
  }
  return breadcrumArray
}
