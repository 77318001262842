import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import TextInput from '../../../../../../../../components/Input/TextInput'
import Image from '../../../../Image'
import * as Styled from './Styled'
import { handleIconUploadImage, IconUploadImage } from '../../../../event'
import { handleChange, handleDelete } from './event'
import { TooltipForm } from '../../../../Styled'
import { getErrorMessage } from '../../../../../event'
import MoreSingle from './MoreSingle'

const Index = ({ props }) => {
  const {
    answers,
    indexQuestion,
    indexAnswer,
    sequence,
    numberOfAnswers,
    isSubQuestions,
    indexSubQuestion,
    subQuestionIndex,
    disableForm,
  } = props
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const questionType = _.get(props.itemQuestion, 'questionType', 'SINGLE')
  const setNextQuestion = _.get(props.itemQuestion, 'setNextQuestion', false)

  const title = _.get(answers, `[${indexAnswer}].title`, '')
  const mediaUrl = _.get(answers, `[${indexAnswer}].mediaUrl`, '')
  const loadingProgress = _.get(
    answers,
    `[${indexAnswer}].loadingProgress`,
    undefined
  )
  const answerType = _.get(answers, `[${indexAnswer}].answerType`, 'CHOICE')
  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const choicesErrorMessage = getErrorMessage({
    field: 'CHOICES',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })
  const propsEvent = {
    sequence,
    indexQuestion,
    indexAnswer,
    isSubQuestions,
    indexSubQuestion,
    subQuestionIndex,
  }

  const testIdImage = `icon-answer-${
    isSubQuestions
      ? `subQuestions-${sequence}-${subQuestionIndex}-${indexAnswer}`
      : `questions-${sequence}-${indexAnswer}`
  }`

  return (
    <Box sx={{ width: '100%' }}>
      <Styled.BoxChoices isOther={answerType === 'OTHER'}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Styled.ContentInput>
            <TextInput
              id="title-answer"
              name="title"
              placeholder={
                answerType === 'OTHER' ? 'อื่นๆ โปรดระบุ...' : 'ตัวเลือก'
              }
              type="basic"
              disabled={disableForm || answerType === 'OTHER'}
              value={title}
              onChange={(e) =>
                dispatch(
                  handleChange({
                    ...propsEvent,
                    answers,
                    key: 'title',
                    value: _.get(e, 'target.value', ''),
                  })
                )
              }
              isShowTextError={
                choicesErrorMessage || answerErrorMessage ? true : false
              }
              textError={choicesErrorMessage || answerErrorMessage}
            />
          </Styled.ContentInput>

          <Styled.BoxIcon
            isLength={!answers.length}
            isOther={answerType === 'OTHER' ? true : false}
          >
            {answerType !== 'OTHER' && (
              <IconUploadImage
                testId={testIdImage}
                disabled={disableForm}
                onUpload={(e) =>
                  dispatch(
                    handleIconUploadImage({
                      ...propsEvent,
                      data: e,
                      type: isSubQuestions ? 'subQuestionAnswers' : 'answers',
                      testId: testIdImage,
                    })
                  )
                }
              />
            )}

            {answers.length > 1 && (
              <Box>
                <TooltipForm
                  placement="bottom"
                  title={<Typography variant="tooltip">ลบ</Typography>}
                >
                  <IconButton
                    onMouseLeave={() => setIsHover(false)}
                    onMouseOver={() => setIsHover(true)}
                    onClick={() =>
                      !disableForm &&
                      dispatch(
                        handleDelete({
                          ...propsEvent,
                          answers,
                          numberOfAnswers,
                        })
                      )
                    }
                    data-testid={`btn-delete-${indexAnswer}`}
                  >
                    <CloseOutlined color={isHover ? 'primary' : 'action'} />
                  </IconButton>
                </TooltipForm>
              </Box>
            )}
          </Styled.BoxIcon>
        </Box>
        {questionType === 'SINGLE' && setNextQuestion && (
          <MoreSingle props={{ ...props, answers, indexAnswer, answerType }} />
        )}
      </Styled.BoxChoices>
      <Box sx={{ mb: loadingProgress !== null && mediaUrl ? 2 : 0 }}>
        {loadingProgress !== null && mediaUrl && (
          <Image
            disabled={disableForm}
            uploadState={answers[indexAnswer]}
            keyState={{
              ...propsEvent,
              type: isSubQuestions ? 'subQuestionAnswers' : 'answers',
              keyValue: 'mediaUrl',
            }}
            value={mediaUrl}
          />
        )}
      </Box>
    </Box>
  )
}
export default Index
