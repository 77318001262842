import React from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dot from '../../components/Table/Dot'
import {
  getStatusText,
  convertFormatDateTime,
  findDepartmentAndStation,
} from '../../utils/lib'
import BorrowList from './BorrowList'
import DrawerButtons from './DrawerButtons'
import { BoxLabel, LabelText, StyledWrapRow } from './Styled'
import { findTextTrainingPlatform } from './events'

const View = ({ isSpeakerPage, stateForm, handleFetchData, onClose }) => {
  const data = useSelector((state) => state.speaker.data, shallowEqual)
  const [user] = useLocalStorage('user')
  let requester = _.isEmpty(_.get(data, 'requester.value', ''))
    ? _.get(data, 'requester.firstNameTH', '-') +
      ' ' +
      _.get(data, 'requester.lastNameTH', '-')
    : _.get(data, 'requester.value', '-')
  requester = _.isEmpty(data.requester)
    ? _.get(user, 'firstNameTH', '-') + ' ' + _.get(user, 'lastNameTH', '')
    : requester

  const area = _.isEmpty(data.requester)
    ? _.get(user, 'area', [])
    : _.get(data, 'requester.roleAreaInfo.area', [])
  const station = _.isEmpty(data.requester)
    ? _.get(user, 'stationUuid', '')
    : _.get(data, 'requester.station.uuid', '')
  const departmentAndStationRequester = findDepartmentAndStation(
    area,
    station,
    true
  )
  const areaRequester = findDepartmentAndStation(area, station, false)
  const approver = _.isEmpty(_.get(data, 'approver.value', ''))
    ? _.get(data, 'approver.firstNameTH', '-') +
      ' ' +
      _.get(data, 'approver.lastNameTH', '')
    : _.get(data, 'approver.value', '-')
  const departmentAndStationApprover = findDepartmentAndStation(
    _.get(data, 'approver.roleAreaInfo.area', []),
    _.get(data, 'approver.station.uuid', ''),
    true
  )
  const dateStartFinish = _.get(data, 'startDate', '')
    ? data.startDate === data.finishDate
      ? `${convertFormatDateTime({
          value: _.get(data, 'startDate', null),
          type: 'date',
        })}`
      : `${convertFormatDateTime({
          value: _.get(data, 'startDate', null),
          type: 'date',
        })} - ${convertFormatDateTime({
          value: _.get(data, 'finishDate', null),
          type: 'date',
        })}`
    : ''
  const course = _.isEmpty(_.get(stateForm, 'course', {}))
    ? _.get(stateForm, 'courseOther', '-')
    : _.get(stateForm, 'course.courseCode', '-') +
      ' ' +
      _.get(stateForm, 'course.englishName', '')
  const englishName = data.info
    ? `${_.get(data, 'info.course.courseCode', '')} ${
        _.get(data, 'info.course.englishName', '') ||
        _.get(data, 'info.courseOther', course)
      }`
    : `${_.get(
        stateForm,
        'course.courseCode',
        _.get(stateForm, 'courseCode', '')
      )} ${_.get(
        stateForm,
        'course.englishName',
        _.get(stateForm, 'englishName', '')
      )}`
  const nameForLearner = data.info
    ? _.get(data, 'info.course.nameForLearner', '')
    : _.get(stateForm, 'course.nameForLearner', '') ||
      _.get(stateForm, 'nameForLearner', '') ||
      '-'
  const trainingPlatform = _.get(stateForm, 'course.trainingPlatform', [])
    .length
    ? _.get(stateForm, 'course.trainingPlatform', [])
    : _.get(stateForm, 'trainingPlatform', [])
  const isApiType = !!data.apiType || isSpeakerPage
  const status =
    !isApiType && data.status === 'WAITING' ? 'REQUEST' : data.status
  return (
    <Box>
      <Box sx={{ m: 3 }}>
        <Box sx={{ my: 3 }}>
          <BoxLabel>
            <LabelText>สถานะ</LabelText>
            <Dot sx={{ mt: 1.5, mr: 1.5 }} status={!isApiType ? 'draft' : status} />
            <Typography>{getStatusText(status)}</Typography>
          </BoxLabel>
          {data.status === 'REJECT' && (
            <BoxLabel>
              <Typography sx={{ width: 200, mr: 3 }}></Typography>
              <Typography>{_.get(data, 'remark', '-')}</Typography>
            </BoxLabel>
          )}
          <BoxLabel>
            <LabelText>ส่งคำขอเมื่อ</LabelText>
            <Typography>
              {!_.get(data, 'createdAt', null)
                ? ''
                : `${convertFormatDateTime({
                    value: _.get(data, 'createdAt', null),
                    type: 'dateTime',
                  })}`}
            </Typography>
          </BoxLabel>
        </Box>
        <Box sx={{ my: 4 }}>
          <BoxLabel>
            <LabelText>ผู้ขอยืม</LabelText>
            <StyledWrapRow>
              <Typography sx={{ minWidth: 150, mr: 3 }}>{requester}</Typography>
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                {departmentAndStationRequester}
              </Typography>
            </StyledWrapRow>
          </BoxLabel>

          <BoxLabel>
            <LabelText>ผู้อนุมัติ</LabelText>
            <StyledWrapRow>
              <Typography sx={{ minWidth: 150, mr: 3 }}>{approver}</Typography>
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                {departmentAndStationApprover}
              </Typography>
            </StyledWrapRow>
          </BoxLabel>
        </Box>

        <Box sx={{ my: 4 }}>
          <BoxLabel>
            <LabelText>หลักสูตร</LabelText>
            <Typography sx={{ wordBreak: 'break-word' }}>{englishName.trim()}</Typography>
          </BoxLabel>
          <BoxLabel>
            <LabelText>ชื่อหลักสูตร (สำหรับ Learner)</LabelText>
            <Typography sx={{ wordBreak: 'break-word' }}>{`${nameForLearner || '-'}`.trim()}</Typography>
          </BoxLabel>
          <BoxLabel>
            <LabelText>รูปแบบการสอน</LabelText>
            <Typography>
              {stateForm
                ? findTextTrainingPlatform(trainingPlatform)
                : findTextTrainingPlatform(
                    _.get(data, 'info.course.trainingPlatform', []).length
                      ? _.get(data, 'info.course.trainingPlatform', [])
                      : _.get(data, 'info.trainingPlatform', [])
                  )}
            </Typography>
          </BoxLabel>
          <BoxLabel>
            <LabelText>วันที่อบรม</LabelText>
            <Typography>{dateStartFinish}</Typography>
          </BoxLabel>
          <BoxLabel>
            <LabelText sx={{ width: 200 }}>Department</LabelText>
            <Typography>
              {`(${_.get(areaRequester, 'department.initial', '-')}) ${_.get(
                areaRequester,
                'department.department',
                ''
              )}`}
            </Typography>
          </BoxLabel>
          <BoxLabel>
            <LabelText>Station</LabelText>
            <Typography>
              {_.get(areaRequester, 'station.station', '-')}
            </Typography>
          </BoxLabel>
        </Box>

        <BorrowList />

        {data.status !== 'APPROVE' &&
          data.status !== 'CANCEL' &&
          data.status !== 'REJECT' && (
            <DrawerButtons
              handleFetchData={handleFetchData}
              onClose={onClose}
            />
          )}
      </Box>
    </Box>
  )
}

export default View
