import BasicTabs from '../../../../components/BasicTabs'
import { tabsOverviewList } from '../model'
import { handleChangeTab } from '../events'
import { shallowEqual, useSelector } from 'react-redux'
import RankingOverview from '../RankingOverview'
import SummaryWorkloadOverview from '../SummaryWorkloadOverview'

const TabsOverview = () => {
  const { tabOverviewActive } = useSelector(
    (state) => ({
      tabOverviewActive: state.monthlyPlan.dashboard.tabOverviewActive,
    }),
    shallowEqual,
  )

  return (
    <>
      <BasicTabs
        tabsList={tabsOverviewList}
        tabActive={tabOverviewActive}
        handleChangeTab={(e, newValue) =>
          handleChangeTab(e, newValue, 'tabOverviewActive')
        }
      />
      {tabOverviewActive === 0 && <RankingOverview />}
      {tabOverviewActive === 1 && <SummaryWorkloadOverview />}
    </>
  )
}

export default TabsOverview
