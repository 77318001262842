export const defaultFilter = {
  productChecked: false,
  product: '',
  stationChecked: false,
  station: {},
  qtyChecked: false,
  qtyFrom: '',
  qtyTo: '',
  categoryChecked: false,
  category: '',
  subCategoryChecked: false,
  subCategory: '',
  createdByChecked: false,
  createdBy: '',
  createDateChecked: false,
  updateDateChecked: false,
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    draftChecked: false,
    waitingRequisitionApproveChecked: false,
    waitingRequisitionConfirmChecked: false,
    waitingReturnChecked: false,
    waitingReturnApproveChecked: false,
    completedChecked: false,
    canceledChecked: false,
  },
}
