import React from 'react'
import { Box } from '@mui/material'
import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../components/CRUD/components/CustomSwitchInLine'
import { regex } from '../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../components/CRUD/handler/handleInputNumber'
import { handlePasteFormat, handleNumberKeyDown } from '../../../../../utils/lib'
import { store } from '../../../../../App'
import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

const Action = ({ row, columnKey, headCells }) => {
  const { formData, isHideEditAndApprove } = store.getState().crud
  const quantity = row?.quantity ?? ''
  const index = row?.no ? row.no - 1 : ''
  const data = _.get(formData, `products[${index}]`)
  let checkDisabledRemark = false
  if (
    headCells?.inputType === 'number' &&
    (!data?.acceptReturnQuantity || data?.acceptReturnQuantity === '')
  ) {
    checkDisabledRemark = true
  } else if (
    headCells?.inputType === 'switch' &&
    data?.editStatus != enumStatus.ACTIVE
  ) {
    checkDisabledRemark = true
  }

  return (
    <Box display={ isHideEditAndApprove ? 'none' : '' }>
      {columnKey === 'transferEditRemark' && (
        <CustomInput
          disabled={checkDisabledRemark}
          fieldName={`products[${index}].reason`}
          labelName=""
          hideLabel={true}
          sxTextError={{height: 'auto'}}
        />
      )}

      {columnKey === 'transferEditInput' && headCells?.inputType === 'number' && (
        <CustomInput
          fieldName={`products[${index}].acceptReturnQuantity`}
          labelName=""
          hideLabel={true}
          placeholder={`${quantity}`}
          inputProps={{
            'data-testid': 'input-unitPrice',
            onInput: (e) => {
              handleInputNumber(e, regex.FLOAT)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e)
            },
            onPaste: handlePasteFormat,
            onChange: (e) => {
              if (!e.target.value || e.target.value === '') {
                store.dispatch(
                  setFieldValue({
                    key: `formData.products[${index}].reason`,
                    value: '',
                  }),
                )
              }
            },
          }}
          sxTextError={{height: 'auto'}}
        />
      )}
      {columnKey === 'transferEditInput' && headCells?.inputType === 'switch' && (
        <CustomSwitchInLine
          fieldName={`products[${index}].editStatus`}
          title=""
          hideLabelText={true}
          handleExtraChange={(e, value) => {
            if (value != enumStatus.ACTIVE) {
              store.dispatch(
                setFieldValue({
                  key: `formData.products[${index}].reason`,
                  value: '',
                }),
              )
            }
          }}
        />
      )}
    </Box>
  )
}

export default Action
