import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  p: { span: { color: theme?.palette?.success?.main } },
}))

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  marginLeft: theme.spacing(3),
  maxHeight: 576,
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledTableHead = styled(Box)(({ theme }) => ({
  height: 56,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  '& > .MuiTypography-root:first-of-type': { width: 38 },
  '& > .MuiTypography-root': {
    width: '40%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(Box)(({ theme, status }) => {
  let color = theme?.palette?.error?.main
  if (isSuccess(status)) color = theme?.palette?.success?.main
  else if (status == 'NOSEAT') color = theme?.palette?.warning?.main
  return {
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid',
    borderColor: theme.palette?.blue?.blueLine,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    background: isSuccess(status)
      ? theme?.palette?.success?.outlinedHoverBackground
      : 'transparent',
    '& > .MuiTypography-root:first-of-type': { width: 38 },
    '& > .MuiTypography-root': {
      width: '40%',
      fontSize: 18,
      lineHeight: '25px',
      wordBreak: 'break-all',
    },
    '& > #status': { color },
  }
})

export const isSuccess = (status) => status == 'SUCCESS'
