import React from 'react'
import _ from 'lodash'
import {
  openDialog,
  loadingDialog,
  closeDialog,
} from '../../../../../../redux/slices/dialog'
import { setClearBodyDialog } from '../../../../../../redux/slices/manageClassAttendance'
import { putManageClassLearnerCancel } from '../../../../../../services/manageClass/learnerDetail'

import ContentDialogRemark from '../../../../../../components/redux/ContentDialogRemark'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
    return
  }
  setOpen(false)
}

export const cancelDialog =
  ({ trainingPlanUuid, uuid }) =>
  (dispatch) => {
    dispatch(
      openDialog({
        type: 'mini',
        content: (
          <ContentDialogRemark
            labelHeader="เหตุผลที่ยกเลิกการสมัคร"
            labelInput="เหตุผล"
            submitDialog={(remark) =>
              dispatch(confirmDialog({ uuid, trainingPlanUuid, remark }))
            }
          />
        ),
        maxWidth: 'sm',
        handleCancel: () => dispatch(setClearBodyDialog()),
      })
    )
  }

export const confirmDialog = (props) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึก',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      isCloseDialog: false,
      handleConfirm: () => dispatch(actionSubmitForm(props)),
    })
  )
}

export const actionSubmitForm = (props) => async (dispatch) => {
  dispatch(loadingDialog())
  const result = await dispatch(
    putManageClassLearnerCancel({
      uuid: props.uuid,
      remark: props.remark,
    })
  )
  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(closeDialog())
    window.location.href = `/manage-class/learner/${props.trainingPlanUuid}`
  }
}
