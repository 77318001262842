import _ from 'lodash'
import {
  setAnswered,
  setChangeStateByKey,
} from './../../../../../../../redux/slices/eTestingPreview'

export const handleFreeTextChange = (e, keyQuestion) => (dispatch) => {
  const value = _.get(e, 'target.value', null)
  dispatch(setChangeStateByKey({ key: 'answer[0]', value, keyQuestion }))
  dispatch(setAnswered({ keyQuestion, value }))
}

export const handleSingleChange = (e, keyQuestion) => (dispatch) => {
  const value = _.get(e, 'target.value', null)
  dispatch(
    setChangeStateByKey({
      key: 'answer[0]',
      value,
      keyQuestion,
    })
  )
  dispatch(setAnswered({ keyQuestion, value }))
}

export const handleMultipleChange = (e, index, keyQuestion) => (dispatch) => {
  const value = _.get(e, 'target.checked', null)
  dispatch(
    setChangeStateByKey({
      key: `answer[${index}].isChecked`,
      value,
      keyQuestion,
    })
  )
  dispatch(setAnswered({ keyQuestion, value }))
}

export const shouldDisableCheckbox = (question, value) => {
  const { choices, answer } = question
  const maxAllowed = choices.filter((e) => e.isCorrect).length
  const checked = answer.filter((e) => e.isChecked)
  const index = checked.findIndex((e) => e.uuid == value)
  return checked.length >= maxAllowed && index == -1
}
