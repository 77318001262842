import { setNextQuestion, setSubmit } from './../../../../../../redux/slices/eTestingPreview'

export const handleNextQuestion =
  ({ isNow, isReview }) =>
  (dispatch) => {
    if (isNow && !isReview) {
      dispatch(setSubmit())
      setTimeout(() => {
        dispatch(setNextQuestion())
      }, 3000)
    } else dispatch(setNextQuestion())
  }
