export const breadcrumbAttendance = (uuid) => {
  return [
    { title: 'จัดการคลาส', link: '/manage-class', pointer: true },
    {
      title: 'รายละเอียดคลาส',
      link: `/manage-class/detail/${uuid}`,
      pointer: true,
    },
    { title: 'เช็คชื่อ', link: '/', pointer: false },
  ]
}

export const breadcrumbAttendanceList = (label, uuid) => {
  return [
    { title: 'จัดการคลาส', link: '/manage-class', pointer: true },
    {
      title: 'เช็คชื่อ',
      link: '/manage-class/attendance/' + uuid,
      pointer: true,
    },
    { title: label, link: '/', pointer: false },
  ]
}
