import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import _ from 'lodash'
import {
  userAccessAgencyModel,
  userAccessBancModel,
  userAccessProspectModel,
} from '../../models/overview'
import { DataCard } from '../ModuleCard/DataCard'

const UserAccess = () => {
  const userAccessAgency = userAccessAgencyModel()
  const userAccessBanc = userAccessBancModel()
  const userAccessProspect = userAccessProspectModel()
  const finalResult = [
    _.get(userAccessAgency, '[0].number', 0),
    _.get(userAccessBanc, '[0].number', 0),
    _.get(userAccessProspect, '[0].number', 0),
  ]
  const colorArray = [
    _.get(userAccessAgency, '[0].dotColor', ''),
    _.get(userAccessBanc, '[0].dotColor', ''),
    _.get(userAccessProspect, '[0].dotColor', ''),
  ]
  const totalModule = _.sum(finalResult)
  return (
    <Box height={'100%'}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" gap={2}>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
            >
              <Typography variant="h6">การเข้าใช้ (รายครั้ง)</Typography>
              <Box display="flex" gap={2} mt={3} alignItems="center">
                <DoughnutChart
                  dataSet={{
                    data: finalResult,
                    backgroundColor: colorArray,
                  }}
                  height={224}
                  label="ทั้งหมด"
                  value={totalModule}
                  eleId={'userAccessDougnutChart'}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                title={'Agency & CS'}
                dataList={userAccessAgency}
                totalModule={totalModule}
              />
              <DataCard
                title={'Banc & KTB'}
                dataList={userAccessBanc}
                totalModule={totalModule}
              />
              <DataCard
                title={'Prospect'}
                dataList={userAccessProspect}
                totalModule={totalModule}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default UserAccess
