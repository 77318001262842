import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { handleOpenImportExaminerTemplateDialog } from '../../../../redux/slices/eExamination/list/event'
import Add from '@mui/icons-material/Add'
import MoreDropdownButton from './DropdownButton'
import { useDispatch } from 'react-redux'
import { handleAnnounceExaminer } from './events'
import { StyledAnnouceButton } from '../../Styled'
import { IMPORT_EXAMINER_TYPE } from '../../../../constants/eExamination'
import { validateEPermission } from '../../../../utils/lib'
import {
  EXAMINATION_SETTING,
  PERMISSION,
} from '../../../../constants/examination'

const Index = ({ examUuid, isEnableAnounce, announce, scheduleStatus }) => {
  const dispatch = useDispatch()
  const hasDcPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.DC,
  })
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
      {hasDcPermission && (
        <StyledAnnouceButton
          data-testid="btn-download-0"
          variant="canceled"
          size="m"
          onClick={() => dispatch(handleAnnounceExaminer())}
          startIcon={<img src={'/icon/ic_megaphone_white.svg'} />}
          disabled={!isEnableAnounce}
        >
          ประกาศผู้มีสิทธิ์สอบ
        </StyledAnnouceButton>
      )}

      {hasDcPermission && (
        <Button
          data-testid="btn-download-0"
          variant="outlined"
          size="m"
          onClick={() => {
            handleOpenImportExaminerTemplateDialog(
              IMPORT_EXAMINER_TYPE.ANNOUNCEMENT,
            )
          }}
          startIcon={<Add />}
        >
          อัปโหลดรายชื่อผู้มีสิทธิ์สอบ
        </Button>
      )}

      <MoreDropdownButton
        examUuid={examUuid}
        announce={announce}
        scheduleStatus={scheduleStatus}
      />
    </Box>
  )
}
export default Index
