import React from 'react'
import { TableRow, TableCell, IconButton } from '@mui/material'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import Truncate from './Truncate'

const TableReportRow = ({ report }) => {
  const { columns, moduleId } = useSelector(
    (state) => ({
      columns: state.reportComp.columns,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )

  return (
    <TableRow tabIndex={-1} key={report.id}>
      {_.map(columns, (column) => {
        const value =
          !_.isNull(report[column.name]) &&
          report[column.name] !== undefined &&
          report[column.name] !== ''
            ? report[column.name]
            : '-'

        return (
          <TableCell
            key={column?.id}
            align={column?.align}
            style={{
              minWidth: column?.minWidth,
            }}
          >
            <Truncate align={column?.align}>{'' + value}</Truncate>
          </TableCell>
        )
      })}

      {moduleId === 'AUDIT_LOG' && (
        <TableCell
          style={{
            minWidth: 36,
            padding: '10px 16px',
          }}
        >
          {report?.link && (
            <IconButton
              color="primary"
              component="span"
              sx={{ width: 36, height: 36 }}
              onClick={() => window.open(report.link, '_blank')}
            >
              <RemoveRedEyeTwoTone color="action" />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  )
}

export default TableReportRow
