import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledDragButton = styled(Button)((props) => {
  const {
    theme,
    dropped,
    isDragging,
    isSelected,
    isSubmit,
    isMobile,
    maxWidth,
    minWidth,
  } = props
  return {
    minHeight: 40,
    minWidth,
    maxWidth: isDragging ? maxWidth : '100%',
    maxHeight: isDragging && 49.5,
    whiteSpace: isDragging && 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: isDragging ? 'block' : dropped && 'contents',
    wordBreak: 'break-word',
    textAlign: 'start',
    border: '1px solid',
    borderColor: theme.palette?.text?.mercury,
    borderRadius: '2px',
    background: isSelected
      ? theme.palette?.action?.disabled
      : 'linear-gradient(180deg, #FAFAFA 0%, #EEE 100%)',
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    pointerEvents: isSubmit && 'none',
    cursor: isSubmit && 'default',
    zIndex: 1,
    color: theme.palette?.text?.primary,
    '&:after': {
      background: theme.palette?.action?.hover,
      width: 0,
      height: 600,
      display: dropped ? 'none' : 'block',
    },
    '& > .MuiTouchRipple-root': { display: dropped && 'none' },
    ':hover': {
      color: theme.palette?.text?.primary,
      background: isMobile
        ? theme.palette?.action?.disabled
        : 'linear-gradient(180deg, #FAFAFA 0%, #EEE 100%)',
      boxShadow: 'none',
      '&:after': {
        opacity: 1,
        width: '150%',
        transition: 'width 0.3s ease 0s, opacity 0.3s ease 0s',
      },
    },
  }
})

export const handleMinWidth = (length) => {
  if (length > 22) return 200
  return 'auto'
}

export const handleMaxWidth = (length) => {
  if (length <= 22) return '100%'
  if (length > 22 && length <= 100) return '70%'
  if (length > 100) return '40%'
  return '100%'
}
