import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import UploadFile from '../../../../../../components/UploadFile'
import { UPLOAD_TYPE } from '../../../../../../constants/eLearning'
import { StyledFormCard } from '../../../../Styled'
import StatusSwitch from '../StatusSwitch'
import { getMaxFileSize } from '../events'
import { handleSetScorm } from './events'

const Scorm = () => {
  const dispatch = useDispatch()
  const { fileModule, maxFileSize, onGoing } = useSelector(
    (state) => ({
      fileModule: state.uploadFile.formData.eLearningModule,
      maxFileSize: getMaxFileSize(),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const isUploadLoading = _.get(fileModule, 'isUploading', false)
  const files = _.get(fileModule, 'files', [])

  useEffect(() => {
    dispatch(handleSetScorm(files))
  }, [files])

  return (
    <StyledFormCard isUploadLoading={isUploadLoading} sx={{ gap: 3 }}>
      <UploadFile
        isMultiple={false}
        isShowDownloadable={false}
        label="Upload Scorm"
        onChange={(files) => console.log(files)}
        uploadType={UPLOAD_TYPE.SCORM}
        maxFileSize={maxFileSize}
        accept={{ 'application/zip': ['.zip'] }}
        acceptLabel=".zip"
        fieldName="eLearningModule"
        sx={{ border: 'unset', boxShadow: 'unset', p: 0 }}
        disabled={onGoing}
      />

      <Divider />

      <StatusSwitch />
    </StyledFormCard>
  )
}

export default Scorm
