import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

import { StyledDialog, StyledLabel } from './Styled'
import { handleCheckboxChange } from './events'

export const SetFailDialog = ({ notChecked }) => {
  const dispatch = useDispatch()

  return (
    <StyledDialog>
      <Typography>
        คุณต้องการปรับสถานะของผู้เรียนที่ยังไม่ตรวจทั้งหมด จำนวน {notChecked} คน
        เป็นไม่ผ่านใช่หรือไม่
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-accept"
              defaultChecked={false}
              onChange={(e) => dispatch(handleCheckboxChange(e))}
            />
          }
          label={
            <StyledLabel>
              ปรับสถานะให้ผู้เรียนที่ยังไม่ตรวจ{' '}
              <span>ไม่ผ่านการทดสอบ {notChecked} คน</span>
            </StyledLabel>
          }
        />
      </Box>
    </StyledDialog>
  )
}

export default SetFailDialog
