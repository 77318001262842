import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'

const AllContent = () => {
  const {
    total,
    eContentActive,
    eContentActivePercent,
    kbActive,
    kbActivePercent,
  } = useSelector(
    (state) => ({
      total: state.crud?.eContentDashboard?.overview?.total,
      eContentActive: state.crud?.eContentDashboard?.overview?.eContent?.active,
      eContentActivePercent:
        state.crud?.eContentDashboard?.overview?.eContent?.activePercent,
      kbActive: state.crud?.eContentDashboard?.overview?.kb?.active,
      kbActivePercent:
        state.crud?.eContentDashboard?.overview?.kb?.activePercent,
    }),
    shallowEqual,
  )
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Card sx={{ minHeight: 102, padding: '8px 16px 12px 16px' }}>
          <Typography variant="h6">ยอดรวม Content (Active)</Typography>
          <Row ml={3}>
            <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
              {total}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '20px' }}
            >
              Content
            </Typography>
          </Row>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ minHeight: 102, padding: '8px 16px 12px 16px' }}>
          <Typography variant="h6">eContent (Active)</Typography>
          <Row ml={3}>
            <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
              {eContentActive}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '20px' }}
            >
              Content
            </Typography>
          </Row>
          <Row ml={3}>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '18px', mt: '2px' }}
            >
              {eContentActivePercent}
            </Typography>
          </Row>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ minHeight: 102, padding: '8px 16px 12px 16px' }}>
          <Typography variant="h6">Knowledge Base (Active)</Typography>
          <Row ml={3}>
            <Typography variant="h4" mr={0.5} sx={{ lineHeight: '20px' }}>
              {kbActive}
            </Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '20px' }}
            >
              Content
            </Typography>
          </Row>
          <Row ml={3}>
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ lineHeight: '18px', mt: '2px' }}
            >
              {kbActivePercent}
            </Typography>
          </Row>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AllContent
