import { getModuleFormState } from '../events'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const handleSubmitForm = (setValues) => {
  const body = getModuleFormState()
  setValues(body)
}

export const handlePreview = (setELearningPreview) => {
  const body = getModuleFormState()
  setELearningPreview(body)
  localStorage.removeItem('eTesting')
  localStorage.removeItem('eEvaluation')
  window.open(`/e-learning/preview`, '_blank')
}
