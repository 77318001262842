import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  roomManagement,
  roomManagementAvailable,
  roomManagementDashboard,
  roomManagementFilterCalendar,
  roomManagementHistory,
  roomManagementHistoryAll,
  roomManagementMeetingRoom,
  roomManagementRoomRequest,
  roomManagementStaffList,
  roomManagementWeek,
} from '../../utils/apiPath'

export const postRoomManagementDashboard = createAsyncThunk(
  'roomManagement/postRoomManagementDashboard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementDashboard, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postBookingRoomWeekly = createAsyncThunk(
  'roomManagement/postBookingRoomWeekly',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementWeek, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postRoomAvailable = createAsyncThunk(
  'roomManagement/postRoomAvailable',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementAvailable, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postRoomManagement = createAsyncThunk(
  'roomManagement/postRoomManagement',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagement, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putRoomManagement = createAsyncThunk(
  'roomManagement/putRoomManagement',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(roomManagement, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteRoomManagement = createAsyncThunk(
  'roomManagement/deleteRoomManagement',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.delete(roomManagement, { data: body })
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getMeetingRoomFilter = createAsyncThunk(
  'roomManagement/getMeetingRoom',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementMeetingRoom, bodyProps)
      return { response, ...bodyProps.station }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getRoomRequest = createAsyncThunk(
  'roomManagement/getRoomRequest',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementRoomRequest, bodyProps)
      return { response, ...bodyProps.station }
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getBookingRoomById = createAsyncThunk(
  'roomManagement/getBookingRoomById',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${roomManagement}/${uuid}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getStaffList = createAsyncThunk(
  'masterData/getStaffList',
  async (_n, { rejectWithValue }) => {
    try {
      const response = await axios.get(roomManagementStaffList)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const filterEventsCalendar = createAsyncThunk(
  'roomManagement/filterEventsCalendar',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementFilterCalendar, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getRoomHistory = createAsyncThunk(
  'roomManagement/getRoomHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${roomManagementHistory}${url}`)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postRoomHistoryAll = createAsyncThunk(
  'roomManagement/postHistoryAll',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(roomManagementHistoryAll, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
