import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

import * as Styled from './Styled'

import { expandState, handleExpand, findExpand } from './events'

export const ContentClassroomDetail = ({
  expands,
  setExpands,
  classroomDetail,
}) => {
  let result = []

  for (let keyDate in classroomDetail) {
    const details = _.get(classroomDetail, [keyDate], [])
    const isExpand = findExpand(expands, keyDate)

    result.push(
      <Styled.BoxContent
        key={keyDate}
        onClick={() => handleExpand(expands, setExpands, keyDate)}
      >
        <Styled.BoxHeader>
          {!isExpand ? (
            <ExpandMore color="primary" />
          ) : (
            <ExpandLess color="primary" />
          )}
          <Typography variant="tableHeader" sx={{ ml: 4 }}>
            {keyDate}
          </Typography>
        </Styled.BoxHeader>
        {isExpand && details.length > 0 && (
          <>
            {details.map((item, index) => {
              return (
                <Styled.BoxDetails key={index}>
                  <Styled.LabelDetails variant="body2" sx={{ width: 300 }}>
                    {_.get(item, 'time', '-')}
                  </Styled.LabelDetails>
                  <Styled.LabelDetails variant="body2" sx={{ width: 300 }}>
                    {_.get(item, 'onsiteOrOnline', '-')}
                  </Styled.LabelDetails>
                  <Styled.LabelDetails variant="body2" sx={{ width: 300 }}>
                    {_.get(item, 'classroom', '-')}
                  </Styled.LabelDetails>
                </Styled.BoxDetails>
              )
            })}
          </>
        )}
      </Styled.BoxContent>
    )
  }

  return result.length ? (
    result
  ) : (
    <Typography variant="body1" color="text.secondary">
      ไม่มีข้อมูลรายละเอียดห้องเรียน
    </Typography>
  )
}

const Index = () => {
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState
  )
  const classroomDetail = _.get(initialState, 'classroomDetail', [])

  const [expands, setExpands] = useState([])

  useEffect(() => {
    !expands.length && setExpands(expandState(classroomDetail))
  }, [classroomDetail])

  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ m: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          รายละเอียดห้องเรียน
        </Typography>
        {!!classroomDetail && (
          <ContentClassroomDetail
            classroomDetail={classroomDetail}
            expands={expands}
            setExpands={setExpands}
          />
        )}
      </Box>
    </Card>
  )
}

export default Index
