import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import { StyledColumn, StyledRow } from '../../../Styled'
import Typography from '@mui/material/Typography'
import { ContentRow } from './ContentRow'
import { RowLabels } from './RowLabels'
import { ACADEMY_TYPE } from '../../../../../constants/roomManagement'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { enumRequestType } from '../../../View/Content/CalendarCard/constanst'

export const BookerSummary = () => {
  const theme = useTheme()

  const {
    createdAt,
    academy,
    bookForName,
    mobileNoOther,
    emailOther,
    bookByName,
    remark,
    bookForOther,
    requestTypeData,
  } = useSelector((state) => ({
    createdAt: state.roomManagementDrawer.bookingInformation.createdAt,
    academy: state.roomManagementDrawer.bookingInformation.academy,
    bookForName: state.roomManagementDrawer.bookingInformation.bookForName,
    bookForOther: state.roomManagementDrawer.bookingInformation.bookForOther,
    mobileNoOther: state.roomManagementDrawer.bookingInformation.mobileNoOther,
    emailOther: state.roomManagementDrawer.bookingInformation.emailOther,
    bookByName: state.roomManagementDrawer.bookingInformation.bookByName,
    remark: state.roomManagementDrawer.bookingInformation.remark,
    requestTypeData: state.roomManagementDrawer.bookingInformation.requestType,
  }))

  return (
    <>
      <Divider
        sx={{
          borderStyle: 'dashed',
          borderColor: theme.palette?.other?.outlineBorder,
        }}
      />
      <StyledRow sx={{ gap: 1.5 }}>
        <img
          alt={''}
          src="/icon/bi_person-lines-fill.svg"
          width={24}
          height={24}
        />
        <Typography variant="h6">สรุปข้อมูลผู้จอง</Typography>
      </StyledRow>
      <ContentRow
        columnLeft={
          <RowLabels title={'วันที่จอง'} label={createdAt.split(' ')[0]} />
        }
        columnRight={
          <RowLabels title={'เวลาที่จอง'} label={createdAt.split(' ')[1]} />
        }
      />
      <ContentRow
        columnLeft={
          requestTypeData === enumRequestType.MONTHLY_PLAN ? (
            <RowLabels
              titleSx={{ minWidth: 46 }}
              title={'จองโดย'}
              label={bookByName}
            />
          ) : (
            <RowLabels title={'หน่วยงาน'} label={ACADEMY_TYPE[academy]} />
          )
        }
        columnRight={
          <RowLabels
            titleSx={{ minWidth: 36 }}
            title={'จองให้'}
            label={_.isEmpty(bookForName) ? bookForOther : bookForName}
          />
        }
      />
      <ContentRow
        columnLeft={<RowLabels title={'เบอร์โทร'} label={mobileNoOther} />}
        columnRight={<RowLabels title={'อีเมล'} label={emailOther} />}
      />
      {requestTypeData !== enumRequestType.MONTHLY_PLAN && (
        <ContentRow
          columnLeft={
            <RowLabels
              titleSx={{ minWidth: 46 }}
              title={'จองโดย'}
              label={bookByName}
            />
          }
        />
      )}
      <StyledColumn sx={{ gap: 0.5 }}>
        <Typography variant="body1b">หมายเหตุ</Typography>
        <Typography>{_.isEmpty(remark) ? '-' : remark}</Typography>
      </StyledColumn>
    </>
  )
}
