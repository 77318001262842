import React from 'react'
import styled from '@mui/system/styled'
import { Box, Button } from '@mui/material'

export const StyledButton = styled((props) => (
  <Button variant="outlined" size="m" {...props} />
))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const StyledBoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  '& .MuiButton-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    '& .MuiButton-root': {
      padding: theme.spacing(1),
    },
  },
}))
