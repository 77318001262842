import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleOwnerChange = (e, value) => {
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerFNameForm',
      value: _.get(value, 'name', ''),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerLNameForm',
      value: _.get(value, 'lastname', ''),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.ownerEmailForm',
      value: _.get(value, 'email', ''),
    }),
  )
}
