import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import { StyledBox } from '../../../Styled'
import { setOnEdit, setOnView } from '../../../../../../redux/slices/table2'

const ActionStandaloneVersion = ({ row, minWidth, rowIndex }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  if (!_.isNil(row.version)) {
    const editUrl = `/e-testing/check-exam/${row.uuid}`
    const canEdit = _.get(row, 'canEdit', false)
    if (!canEdit) return <></>
    return (
      <StyledBox minWidth={minWidth}>
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      </StyledBox>
    )
  }

  const viewUrl = `version/${row.codeId}`
  const canView = _.get(row, 'canView', false)
  if (!canView) return <></>
  return (
    <StyledBox minWidth={minWidth}>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        onClick={() => dispatch(setOnView({ history, viewUrl }))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>
    </StyledBox>
  )
}

export default ActionStandaloneVersion
