import { shallowEqual, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { initialize } from './handler/initialize'
import Header from './components/Header'
import FilterDrawer from './components/FilterDrawer'
import Content from './components/Content'

const Index = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  useEffect(initialize, [])

  return (
    <Box px={2}>
      <Box>
        <Header />
      </Box>
      <Box my={3}>
        <Content />
      </Box>

      <FilterDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default Index
