import _ from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import {
  addFieldQuestions,
  setFieldValueByQuestionType,
  validateVideo,
  addIdInDefaultQuestions,
} from './event'
import { initialState } from './model'
import { defaultValue } from '../../../constants/eEvaluation'
import { editStatus } from '../eTestingForm/model'

const eEvaluationForm = createSlice({
  name: 'eEvaluationForm',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setErrorSchemaMessage: (state, { payload }) => {
      state.errorSchemaMessage = {
        field: payload.field,
        message: payload.message,
        questionNo: payload.questionNo,
        answerNo: payload.answerNo,
        sectionType: _.get(payload, `sectionType`, ''),
        messageType: _.get(payload, `messageType`, ''),
      }
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `${key}`, value)
    },
    setChangeEvaluationInfo: (state, { payload }) => {
      const { value, key } = payload
      _.set(state, `information.${key}`, value)
    },
    // Question
    setDragDropDefaultQuestion: (state, { payload }) => {
      const { startIndex, endIndex, type } = payload
      const listQuestion = Array.from(
        _.get(state, `[${type}][0].questions`, [])
      )
      const [removed] = listQuestion.splice(startIndex, 1)
      listQuestion.splice(endIndex, 0, removed)
      const result = listQuestion.filter((item, index) => {
        return {
          ...item,
          id: `id-${index + 1}`,
          sequence: index + 1,
        }
      })

      _.set(state, `[${type}][0].questions`, result)
    },
    setAddQuestionNotOther: (state, { payload }) => {
      const { type } = payload
      const itemQuestion = _.get(state, `${type}[0].questions`)
      const newQuestion = itemQuestion.map((item, index) => {
        return {
          ...item,
          id: `id-${index + 1}`,
        }
      })

      const result = {
        id: `id-${itemQuestion.length + 1}`,
        sequence: itemQuestion.length + 1,
        title: '',
      }
      state[type][0].questions = [...newQuestion, result]
    },
    setChangeQuestionNotOtherByKey: (state, { payload }) => {
      const { sequence, key, value, type } = payload
      const questions = _.get(state, `${type}[0].questions`)
      const itemQuestion = questions.find((item) => item.sequence === sequence)
      _.set(itemQuestion, `${key}`, value)
    },
    setDeleteQuestionNotOther: (state, { payload }) => {
      const { type, sequence } = payload
      const questions = _.get(state, `${type}[0].questions`)
      let newSequence = 0
      let result = []
      questions.filter((item) => {
        if (item.sequence !== sequence) {
          newSequence++
          result.push({ ...item, sequence: newSequence })
          return item
        }
      })
      _.set(state, `${type}[0].questions`, result)
    },
    // Other
    setAddQuestionOther: (state, { payload }) => {
      const listQuestion = state.questions
      state.questions.push({
        ...payload.value,
        sequence: listQuestion.length + 1,
      })
    },
    setChangeQuestionTypeOther: (state, { payload }) => {
      const {
        sequence,
        value,
        isSubQuestions,
        indexQuestion,
        subQuestionSequence,
        subQuestionIndex,
      } = payload

      if (!isSubQuestions) {
        const itemQuestion = state.questions.find(
          (item) => item.sequence === sequence
        )
        // remove subQuestions
        if (itemQuestion.questionType === 'SINGLE') {
          let subQuestion = []
          state.subQuestions.forEach((item) => {
            if (item.sequence !== sequence) {
              subQuestion.push({ ...item })
            }
          })
          _.set(state, 'subQuestions', subQuestion)
        }

        const result = setFieldValueByQuestionType(itemQuestion, value)
        _.set(state, `questions[${indexQuestion}]`, result)
      } else {
        const objSubQuestion = state.subQuestions.find(
          (item) => item.sequence === sequence
        )

        const itemSubQuestion = _.get(objSubQuestion, 'value', []).find(
          (item) => item.sequence === subQuestionSequence
        )
        const result = setFieldValueByQuestionType(
          itemSubQuestion,
          value,
          isSubQuestions
        )

        _.set(objSubQuestion, `value[${subQuestionIndex}]`, result)
      }
    },
    setChangeQuestionByKey: (state, { payload }) => {
      const { type, sequence, key, value } = payload
      const itemQuestion = state[type].find(
        (item) => item.sequence === sequence
      )
      if (key === 'setNextQuestion') {
        // remove subSequence by sequence
        if (!value) {
          const itemSubQuestion = state.subQuestions.filter(
            (item) => item.sequence !== sequence
          )
          _.set(state, 'subQuestions', itemSubQuestion)
        }

        // set value nextQuestionType
        const answers = itemQuestion.answers
        const newAnswers = answers.map((item) => {
          return {
            ...item,
            nextQuestionType:
              state.questions.length <= 1
                ? 'NEXT_SUB'
                : value
                ? 'NEXT'
                : 'NONE',
            nextQuestionSequence: 0,
            nextSubQuestionSequence: 0,
          }
        })
        _.set(itemQuestion, 'answers', newAnswers)
      }

      _.set(itemQuestion, `${key}`, value)
    },
    setChangeAddQuestionOther: (state, { payload }) => {
      const { sequence, type, isSubQuestions, subQuestionSequence } = payload

      if (!isSubQuestions) {
        const itemQuestion = state.questions.find(
          (item) => item.sequence === sequence
        )
        const newArray = itemQuestion[type].map((item, index) => {
          return {
            ...item,
            id: `id-${index + 1}`,
            sequence: index + 1,
          }
        })
        const result = {
          id: `id-${newArray.length + 1}`,
          sequence: newArray.length + 1,
          title: '',
        }
        itemQuestion[type] = [...newArray, result]
      } else {
        const objSubQuestion = state.subQuestions.find(
          (item) => item.sequence === sequence
        )
        const itemSubQuestion = _.get(objSubQuestion, 'value', []).find(
          (item) => item.sequence === subQuestionSequence
        )
        const newArray = itemSubQuestion[type].map((item, index) => {
          return {
            ...item,
            id: `id-${index + 1}`,
            sequence: index + 1,
          }
        })
        const resultSubQuestion = {
          id: `id-${newArray.length + 1}`,
          sequence: newArray.length + 1,
          title: '',
        }

        itemSubQuestion[type] = [...newArray, resultSubQuestion]
      }
    },
    setDeleteQuestionOther: (state, { payload }) => {
      const { sequence } = payload
      let resultQuestion = []
      let resultSubQuestion = []
      let newSequence = 0
      let newSubSequence = 0

      state.questions.forEach((item) => {
        if (item.sequence !== sequence) {
          newSequence++

          resultQuestion.push({
            ...item,
            sequence: newSequence,
            answers: !_.get(item, 'answers', []).length
              ? []
              : item.answers.map((itemAnswer, indexAnswer) => {
                  const nextQType = itemAnswer.nextQuestionType
                  const nextQSequence = itemAnswer.nextQuestionSequence
                  let newType = nextQType
                  let newQSequence = newSequence

                  if (nextQType === 'NEXT_TO' && nextQSequence === sequence) {
                    newType = 'NEXT'
                    newQSequence = 0
                    if (state.questions.length == sequence) newType = 'NEXT_SUB'
                  }
                  return {
                    ...itemAnswer,
                    sequence: indexAnswer + 1,
                    nextQuestionType: newType,
                    nextQuestionSequence: newQSequence,
                  }
                }),
          })
        }
      })
      state.subQuestions.forEach((item) => {
        if (item.sequence !== sequence) {
          newSubSequence++
          resultSubQuestion.push({ ...item, sequence: newSubSequence })
        }
      })
      _.set(state, 'questions', resultQuestion)
      _.set(state, 'subQuestions', resultSubQuestion)
    },
    setDeleteQuestionRating: (state, { payload }) => {
      const {
        sequence,
        type,
        sequenceChoice,
        isSubQuestions,
        subQuestionSequence,
      } = payload

      let newSequence = 0
      let result = []

      if (!isSubQuestions) {
        const itemQuestion = state.questions.find(
          (item) => item.sequence === sequence
        )

        itemQuestion[type].filter((item) => {
          if (item.sequence !== sequenceChoice) {
            newSequence++
            result.push({ ...item, sequence: newSequence })
          }
        })
        _.set(itemQuestion, `${type}`, result)
      } else {
        const objSubQuestion = state.subQuestions.find(
          (item) => item.sequence === sequence
        )
        const itemSubQuestion = _.get(objSubQuestion, 'value', []).find(
          (item) => item.sequence === subQuestionSequence
        )
        itemSubQuestion[type].filter((item) => {
          if (item.sequence !== sequenceChoice) {
            newSequence++
            result.push({ ...item, sequence: newSequence })
          }
        })
        _.set(itemSubQuestion, `${type}`, result)
      }
    },
    // SubQuestion
    setChangeSubQuestionByKey: (state, { payload }) => {
      const { sequence, subQuestionSequence, key, value } = payload
      const itemSubQuestion = state.subQuestions.find(
        (item) => item.sequence === sequence
      )
      const listSubQuestion = _.get(itemSubQuestion, 'value', [])
      const result = listSubQuestion.find(
        (item) => item.sequence === subQuestionSequence
      )
      _.set(result, `${key}`, value)
    },
    setDeleteSubQuestions: (state, { payload }) => {
      const { sequence, subQuestionSequence } = payload

      // remove value subQuestions in questions
      const itemQuestion = state.questions.find(
        (item) => item.sequence === sequence
      )
      let newNextSubQuestion = 0
      const itemAnswer = _.get(itemQuestion, 'answers', []).map((item) => {
        const nextQuestion = item.nextQuestionSequence
        const nextSubQuestion = item.nextSubQuestionSequence

        let newItem = item
        if (
          nextQuestion === sequence &&
          nextSubQuestion === subQuestionSequence
        ) {
          newItem = {
            ...newItem,
            nextQuestionSequence: 0,
            nextSubQuestionSequence: 0,
          }
        } else {
          newNextSubQuestion++
          newItem = {
            ...newItem,
            nextSubQuestionSequence: newNextSubQuestion,
          }
        }

        return newItem
      })

      _.set(itemQuestion, `answers`, itemAnswer)

      //  remove value subQuestions
      const itemSubQuestion = state.subQuestions.find(
        (item) => item.sequence === sequence
      )
      const listSubQuestion = _.get(itemSubQuestion, 'value', [])
      let newSequence = 0
      let result = []
      listSubQuestion.filter((item) => {
        if (item.sequence !== subQuestionSequence) {
          newSequence++
          result.push({ ...item, sequence: newSequence })
        }
      })
      _.set(itemSubQuestion, `value`, result)

      if (!result.length) {
        state.subQuestions = state.subQuestions.filter(
          (item) => item.sequence !== sequence
        )
      }
    },
    setChangeAddSubQuestions: (state, { payload }) => {
      const { sequence, indexAnswer } = payload
      const itemQuestion = state.questions.find(
        (item) => item.sequence === sequence
      )

      const itemSubQuestion = state.subQuestions.find(
        (item) => item.sequence === sequence
      )
      const objAdd = {
        title: '',
        questionType: 'SINGLE',
        sequence: 1,
        mediaType: 'IMAGE',
        url: '',
        setNextQuestion: false, // SINGLE
        setTypeOfAnswers: false, // MULTIPLE
        typeOfAnswers: 'EQUAL', // MULTIPLE
        numberOfAnswers: 0, // MULTIPLE
        isSuggestion: false, // RATING
        answers: [
          {
            title: '',
            sequence: 1,
            mediaUrl: '',
            answerType: 'CHOICE',
          },
        ],
      }

      let subQuestionSequence = 1
      if (!itemSubQuestion) {
        // new subQuestions ex. subQuestions = []
        state.subQuestions.push({
          sequence,
          value: [objAdd],
        })
      } else {
        // add subQuestions  ex. subQuestions = [...{...}, objAdd]
        const newSubQuestion = itemSubQuestion.value.map((item, index) => {
          return {
            ...item,
            id: `id-${index + 1}`,
          }
        })

        subQuestionSequence = itemSubQuestion.value.length + 1

        _.set(itemSubQuestion, 'value', [
          ...newSubQuestion,
          { ...objAdd, sequence: subQuestionSequence },
        ])
      }

      // set value subQuestion in questions by sequence
      _.set(itemQuestion, `answers[${indexAnswer}]`, {
        ..._.get(itemQuestion, `answers[${indexAnswer}]`, {}),
        nextQuestionSequence: sequence,
        nextSubQuestionSequence: subQuestionSequence,
      })
    },
    // ----------------

    setFetchDataQuestion: (state, { payload }) => {
      state.questions = payload
    },

    setVideo: (state, { payload }) => {
      const { type, sequence, isSubQuestions, subQuestionSequence } = payload
      const itemQuestion = state[type].find(
        (item) => item.sequence === sequence
      )
      let checkValidate = ''

      // case if SubQuestions
      if (isSubQuestions) {
        const listSubQuestion = _.get(itemQuestion, 'value', [])
        const itemSubQuestion = listSubQuestion.find(
          (item) => item.sequence === subQuestionSequence
        )
        const urlVideoSub = _.get(itemSubQuestion, 'urlVideo', '')
        checkValidate = validateVideo(urlVideoSub)
        if (!checkValidate) {
          itemSubQuestion.mediaType = 'VIDEO'
          itemSubQuestion.url = urlVideoSub
          itemSubQuestion.closeVideo = true
        }
      } else {
        const urlVideo = _.get(itemQuestion, 'urlVideo', '')
        checkValidate = validateVideo(urlVideo)
        if (!checkValidate) {
          itemQuestion.mediaType = 'VIDEO'
          itemQuestion.url = urlVideo
          itemQuestion.closeVideo = true
        }
      }
      state.errors = {
        video: checkValidate,
      }
    },
    // Setting
    setChangeSetting: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `setting[${key}]`, value)
    },
    setFetchData: (state, { payload }) => {
      const {
        courseAssessments,
        courseManagementAssessments,
        lecturerAssessments,
        questions,
      } = payload

      state.codeId = payload.codeId
      state.uuid = payload.uuid
      state.version = payload.version
      state.totalLearnerAnswer = payload.totalLearnerAnswer || 0
      state.eEvaluationType =
        payload.eEvaluationType || defaultValue.eEvaluationType.class
      state.information = payload.information
      state.courseAssessments = addIdInDefaultQuestions(courseAssessments)
      state.courseManagementAssessments = addIdInDefaultQuestions(
        courseManagementAssessments
      )
      state.lecturerAssessments = addIdInDefaultQuestions(lecturerAssessments)
      state.questions = addFieldQuestions(questions).listQuestions || []
      state.subQuestions = addFieldQuestions(questions).listSubQuestions || []
      state.toggleQuestions = {
        courseAssessments: !!courseAssessments.length,
        courseManagementAssessments: !!courseManagementAssessments.length,
        lecturerAssessments: !!lecturerAssessments.length,
        questions: payload.isDraft ? true : !!questions.length,
      }
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
        sectionType: '',
        messageType: '',
      }
      state.setting = {
        ...state.setting,
        ...payload.setting,
        defaultDate: payload.setting.startDate,
      }
      state.listAssignee = payload.assignee
      state.dateServer = payload.dateServer
      state.isDraft = payload.isDraft
      state.isClone = payload.isClone
      state.editStatus = payload.editStatus
      state.disableForm =
        payload.editStatus === editStatus.DISABLE_ALL && !payload.isClone
          ? true
          : false
      state.disableSetting =
        payload.editStatus !== editStatus.ENABLE_ALL && !payload.isClone
          ? true
          : false
    },
    setClearData: (state) => {
      state.eEvaluationType = initialState.eEvaluationType
      state.codeId = ''
      state.uuid = ''
      state.version = 0
      state.totalLearnerAnswer = 0
      state.isClone = false
      state.defaultTap = initialState.defaultTap
      state.isLoading = false
      state.isDraft = false
      state.information = initialState.information
      state.courseAssessments = []
      state.lecturerAssessments = []
      state.courseManagementAssessments = []
      state.questions = []
      state.subQuestions = []
      state.setting = initialState.setting
      state.listAssignee = []
      state.errors = initialState.errors
      state.toggleQuestions = {
        courseAssessments: false,
        courseManagementAssessments: false,
        lecturerAssessments: false,
        questions: true,
      }
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
        sectionType: '',
        messageType: '',
      }
      state.onViewMode = false
      state.onTesting = false
    },
    setFetchAssignee: (state, { payload }) => {
      state.listAssignee = payload.assignee
      state.dateServer = payload.dateServer
      state.codeId = payload.codeId
      state.toggleQuestions = {
        courseAssessments: false,
        courseManagementAssessments: false,
        lecturerAssessments: false,
        questions: true,
      }
      state.errorSchemaMessage = {
        field: '',
        message: '',
        questionNo: -1,
        answerNo: -1,
        sectionType: '',
        messageType: '',
      }
      state.disableForm = false
      state.disableSetting = false

      if (payload.eEvaluationType === defaultValue.eEvaluationType.standalone) {
        state.toggleQuestions.questions = true
      }
    },
    setOnViewMode: (state, { payload }) => {
      state.onViewMode = payload
    },
    setEEvaluationType: (state, { payload }) => {
      state.eEvaluationType = defaultValue.eEvaluationType[payload]
    },
  },
})

export const draftQuestionNotOther = (type) =>
  createSelector(
    (state) => state.eEvaluationForm,
    (state) =>
      _.get(state, `${type}[0].questions`, []).map((item) => item.sequence)
  )

export const draftQuestionOther = createSelector(
  (state) => state.eEvaluationForm,
  (state) => state.questions.map((item) => item.sequence)
)

export const draftIndexSubQuestions = (sequence) =>
  createSelector(
    (state) => state.eEvaluationForm,
    (state) =>
      state.subQuestions.findIndex((item) => item.sequence === sequence)
  )

export const draftSubQuestions = (sequence) =>
  createSelector(
    (state) => state.eEvaluationForm,
    (state) => {
      const listSubQuestion = state.subQuestions
      if (!listSubQuestion.length) return []
      const itemSubQuestion = listSubQuestion.find(
        (item) => item.sequence === sequence
      )
      if (!itemSubQuestion) return []

      return itemSubQuestion.value.map((item) => item.sequence)
    }
  )

export const findSubQuestions = createSelector(
  (state) => state.eEvaluationForm,
  (state) => state.questions.find((item) => item.setNextQuestion)
)

export const {
  startLoading,
  stopLoading,
  setChange,
  setErrorSchemaMessage,
  setFetchData,
  setChangeEvaluationInfo,
  // Question
  setChangeQuestionByKey,
  setFetchDataQuestion,
  setDragDropDefaultQuestion,
  // NotOther
  setAddQuestionNotOther,
  setChangeQuestionNotOtherByKey,
  setDeleteQuestionNotOther,
  // Other
  setAddQuestionOther,
  setChangeQuestionTypeOther,
  setChangeAddQuestionOther,
  setDeleteQuestionRating,
  setDeleteQuestionOther,
  // SubQuestion
  setChangeSubQuestionByKey,
  setDeleteSubQuestions,
  setChangeAddSubQuestions,
  // Setting
  setChangeSetting,
  setClearData,
  setFetchAssignee,
  setVideo,
  setOnViewMode,
  setEEvaluationType,
} = eEvaluationForm.actions

export default eEvaluationForm.reducer
