import React, { useEffect } from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import { transferTypeList } from '../model/transferTypeList'
import useTheme from '@mui/system/useTheme'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleChangeHeadCells } from '../handler/handleChangeHeadCells'
import { NotiBox } from './Styled'

export const TabsSelectTransferType = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { tabActive, requestCount } = useSelector(
    (state) => ({
      tabActive: state.crud.tabActive,
      requestCount: state.crud.requestCount,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setFieldValue({ key: 'tabActive', value: newValue }))
  }

  useEffect(() => {
    handleChangeHeadCells()
  }, [tabActive])

  return (
    <Box
      display="flex"
      mx={2}
      borderBottom={`1px solid ${theme.palette?.blue?.blueLine}`}
    >
      <Tabs value={tabActive} onChange={handleChangeTab}>
        {_.map(transferTypeList, (tab, idx) => (
          <Tab
            sx={{
              minWidth: 0,
              width: 'auto',
              textTransform: 'none',
              color: theme.palette?.primary?.main,
              px: 1,
              '&.Mui-selected': {
                borderBottom: `${theme.spacing(0.5)} solid`,
                pt: `calc(${theme.spacing(2)} - 1px)`,
                fontWeight: 700,
              },
            }}
            key={idx}
            label={
              <Box display="flex" gap={1}>
                {tab.label}
                {tab.value === 'other_list' && (
                  <NotiBox>{requestCount}</NotiBox>
                )}
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  )
}
