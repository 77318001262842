import React from 'react'
import { Button, Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useSelector, shallowEqual } from 'react-redux'
// import _ from 'lodash'
import { InsideContactInfo } from './InsideContactInfo'
import { handleAddInsideContact } from '../handler/handleAddInsideContact'

const InsideContact = () => {
  const { insideContacts } = useSelector(
      (state) => ({
        insideContacts: state.crud.formData.insideContacts,
      }),
      shallowEqual,
    )

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6" color={'text.primary'}>
          การติดต่อจากภายใน
        </Typography>
      </Box>
      { insideContacts && insideContacts.map((item, index) => {
        return <InsideContactInfo key={index} item={item} index={index}/>
      })}
      <Box width="120px">
        <Button
            size="medium"
            data-testid={`btn-add-inside`}
            startIcon={<AddIcon />}
            variant="contained"
              onClick={() => handleAddInsideContact()}
        >
            เพิ่มผู้ติดต่อ
        </Button>
      </Box>
    </>
  )
}

export default InsideContact
