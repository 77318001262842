export const defaultBudgetDetails = [
  {
    month: 1,
    monthLabel: 'January มกราคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 2,
    monthLabel: 'Febuary กุมภาพันธ์',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 3,
    monthLabel: 'March มีนาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 4,
    monthLabel: 'April เมษายน',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 5,
    monthLabel: 'May พฤษภาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 6,
    monthLabel: 'June มิถุนายน',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 7,
    monthLabel: 'July กรกฎาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 8,
    monthLabel: 'August สิงหาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 9,
    monthLabel: 'September กันยายน',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 10,
    monthLabel: 'October ตุลาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 11,
    monthLabel: 'November พฤศจิกายน',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
  {
    month: 12,
    monthLabel: 'December ธันวาคม',
    data: [
      {
        label: 'Budget เริ่มต้น',
        value: null,
        required: true,
        keyChange: 'budget',
      },
      { label: 'Revised', value: null, keyChange: 'revised' },
    ],
  },
]
