import { Box, List, Typography } from '@mui/material'
import ListItemCourse from './ListItemCourse'
import _ from 'lodash'
import { StyledColumn } from '../../Styled'

const ListCourse = ({ title = '', color, courseList = [] }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ borderLeft: `4px solid ${color}`, px: 1 }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {courseList.length > 0 ? (
          _.map(courseList, (course, idx) => (
            <ListItemCourse key={idx} {...course} color={color} />
          ))
        ) : (
          <StyledColumn sx={{ alignItems: 'center' }}>
            <Typography variant="body1b" color="action.disabled">
              ไม่พบรายการหลักสูตร
            </Typography>
          </StyledColumn>
        )}
      </List>
    </Box>
  )
}

export default ListCourse
