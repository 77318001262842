import React, { useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FullscreenExit from '@mui/icons-material/FullscreenExit'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { isMediaWidthMoreThan } from '../../../../../../../utils/lib'
import {
  ControlBoxStyled,
  ControlContainerStyled,
  ControlWrapperStyled,
  IconButtonStyled,
  InnerControlStyled,
  ProgressBoxStyled,
  StyledFloadtingButton,
  VideoProgressStyled,
  VideoWrapper,
  VolumeStyled,
} from './Styled'
import {
  formatTime,
  handlePlayPause,
  handleFullscreen,
  handleSeekMouseDown,
  handleSeekEnd,
  handleSeeking,
  handleMute,
  handleVolumeChange,
  handleHoverOnVideo,
  handleKeyDown,
} from './events'

const CustomControl = ({ url, wrapperRef, videoPlayerRef }) => {
  const controlRef = useRef(null)
  const [, setSecond] = useLocalStorage('playerSecond')
  const dispatch = useDispatch()
  const { setting, selectedLesson, isFullScreen, isVideoLoading } = useSelector(
    (state) => ({
      setting: state.eLearningPreview.setting,
      isFullScreen: state.eLearningPreview.isFullScreen,
      selectedLesson: state.eLearningPreview.selectedLesson,
      isVideoLoading: state.eLearningPreview.isVideoLoading,
    }),
    shallowEqual,
  )
  const videoState = _.get(selectedLesson, 'videoState', 0)
  const { playing, muted, volume, played, isFinished } = videoState
  const current = _.get(videoState, 'playedSeconds', 0)
  const duration = _.get(selectedLesson, 'duration', 0) || 0
  const formatCurrentTime = useMemo(() => formatTime(current), [url, current])
  const formatDuration = useMemo(() => formatTime(duration), [url, duration])
  const videoProps = { videoState, setSecond }
  const timeProps = { videoPlayerRef, duration, current, setSecond }
  const groupProps = { videoPlayerRef, setting, setSecond }
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    handleHoverOnVideo(controlRef)
  }, [])

  useEffect(() => {
    dispatch(handleKeyDown({ videoProps, timeProps, volume, setting }))
  }, [videoState, setting])

  return (
    <>
      {!playing && !isVideoLoading && (
        <StyledFloadtingButton onClick={() => dispatch(handlePlayPause(videoProps))}>
          <PlayArrow />
        </StyledFloadtingButton>
      )}

      <ControlWrapperStyled ref={controlRef}>
        <VideoWrapper
          onDoubleClick={() => handleFullscreen(wrapperRef, isNotSm)}
          onClick={() => dispatch(handlePlayPause(videoProps))}
        />

        <ControlContainerStyled>
          {/* play / pause button */}
          <IconButtonStyled
            sx={{ width: 34, height: 34 }}
            onClick={() => dispatch(handlePlayPause(videoProps))}
          >
            {isFinished ? (
              <img src="/icon/player/replay.svg" />
            ) : playing ? (
              <img src="/icon/player/pause.svg" />
            ) : (
              <img src="/icon/player/play.svg" />
            )}
          </IconButtonStyled>

          {/* progress bar */}
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <InnerControlStyled>
              <Typography>{formatCurrentTime}</Typography>
            </InnerControlStyled>
            <ProgressBoxStyled>
              <VideoProgressStyled
                min={0}
                max={100}
                value={played * 100}
                onMouseDown={() => dispatch(handleSeekMouseDown(setting))}
                onChange={(e, value) => dispatch(handleSeeking(value, setting))}
                onChangeCommitted={(e, value) =>
                  dispatch(handleSeekEnd(value, groupProps))
                }
              />
            </ProgressBoxStyled>
            <InnerControlStyled>
              <Typography>{formatDuration}</Typography>
            </InnerControlStyled>
          </Box>

          <ControlBoxStyled>
            {/* mute button */}
            <IconButtonStyled onClick={() => dispatch(handleMute(videoState))}>
              {muted ? (
                <img src="/icon/player/mute.svg" />
              ) : (
                <img src="/icon/player/volume.svg" />
              )}
            </IconButtonStyled>
            {/* volume control */}
            <VolumeStyled
              value={volume * 100}
              onChange={(e, value) => dispatch(handleVolumeChange(value))}
              onChangeCommitted={(e, value) =>
                dispatch(handleVolumeChange(value))
              }
            />
          </ControlBoxStyled>

          {/* full screen button */}
          <IconButtonStyled
            onClick={() => dispatch(handleFullscreen(wrapperRef, isNotSm))}
          >
            {isFullScreen ? (
              <FullscreenExit />
            ) : (
              <img src="/icon/player/full_screen.svg" />
            )}
          </IconButtonStyled>
        </ControlContainerStyled>
      </ControlWrapperStyled>
    </>
  )
}

export default CustomControl
