import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { convertFormatDateTime } from '../../../../../../utils/lib'
import { handlePeriod } from '../../../../components/View/events'
import {
  StyledCardContent,
  StyledDashedDivider,
} from '../../../../components/View/Styled'
import {
  ContentRow,
  ChipContent,
  FooterContent,
  TypeContent,
} from '../../../../components/View'
import { timeSpanTypeOptions } from '../../../Form/SettingTab/Configuration/model'
import ECertificate from './ECertificate'

const Setting = () => {
  const detail = useSelector((state) => state.eLearningPathDetail.result)
  const period = useMemo(() => handlePeriod(detail), [detail])
  const setting = _.get(detail, 'setting', {})
  const dueDateType = _.get(detail, 'dueDateType', '')
  const courseAccessExpiration = _.get(detail, 'courseAccessExpiration', '')
  const refECert = _.get(detail, 'eLearningLearningPathRefCertificate', [])
  return (
    <>
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาหลักสูตร
        </Typography>
        <ContentRow title="วันเริ่มต้น" content={period} />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          การตั้งค่าหลักสูตร
        </Typography>
        <ChipContent title="Tag" list={_.get(setting, 'tag', [])} />
        <ChipContent
          title="ช่องการจัดจำหน่าย"
          list={_.get(setting, 'distribution', [])}
        />
        <ChipContent
          title="ระดับผู้เรียน"
          field="title"
          list={_.get(detail, 'levelOfLearner', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          Skill Mapping
        </Typography>
        <ChipContent
          title="ระดับหลักสูตร"
          field="acquiredSkill"
          list={_.get(detail, 'acquiredSkill', [])}
        />
        <ChipContent
          title="ทักษะที่ได้จากหลักสูตร"
          field="productType"
          list={_.get(detail, 'productType', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาแสดงบนเว็บไซต์
        </Typography>
        <TypeContent type={dueDateType}>
          ใช้งานคอร์สนี้ได้จนถึง{' '}
          {convertFormatDateTime({
            value: _.get(detail, 'dueDate', ''),
            type: 'date',
          })}
        </TypeContent>
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาการเข้าถึงหลักสูตร
        </Typography>
        <TypeContent type={courseAccessExpiration}>
          ระยะเวลา {_.get(detail, 'timeSpan', '')}{' '}
          {
            timeSpanTypeOptions.find(
              (item) => item.value === _.get(detail, 'timeSpanType', ''),
            )?.label
          }
        </TypeContent>
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระบบส่ง E-MAIL แจ้งเตือน
        </Typography>
        <ContentRow title="เตือนก่อนวันเริ่มอบรม">
          <Typography>
            {detail.advanceNotification
              ? `ระยะเวลา ${detail.advanceNotification} วัน`
              : 'ไม่มี'}
          </Typography>
        </ContentRow>
        <ContentRow title="ส่งแจ้งเตือนเป็นระยะ">
          <Typography>
            {detail.continuousNotifications
              ? `แจ้งทุก ๆ ${detail.continuousNotifications} วัน`
              : 'ไม่มี'}
          </Typography>
        </ContentRow>
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <ContentRow
          title="Remark"
          content={_.get(setting, 'remark', '-') || '-'}
        />
      </StyledCardContent>

      {!_.isEmpty(refECert) && <ECertificate />}

      <Divider />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <FooterContent title="สร้าง" field="created" detail={detail} />
        <FooterContent title="แก้ไขล่าสุด" field="updated" detail={detail} />
      </Box>
    </>
  )
}

export default Setting
