import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import styled from '@mui/system/styled'

import React from 'react'
export const StyledTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette?.grey?.grey200}`,
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette?.background?.blue,
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette?.grey?.grey200}`,
  [theme.breakpoints.down('sm')]: {
    minWidth: '240px',
  },
}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const BoxTable = styled(Box)(({ theme }) => ({
  padding: '0px 20px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0px',
    overflowX: 'auto',
  },
}))
