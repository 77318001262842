import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState, initialStation } from './model'
import {
  getManageContentStation,
  postUploadLocation,
  postUploadLocationBanner,
  putManageContentStation,
} from '../../../../services/manageContent'

const manageContentStation = createSlice({
  name: 'manageContentStation',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startUploadLoading: (state, { payload }) => {
      state.isUploadLoading = {
        ...state.isUploadLoading,
        [payload]: true,
      }
    },
    stopUploadLoading: (state, { payload }) => {
      state.isUploadLoading = {
        ...state.isUploadLoading,
        [payload]: false,
      }
    },
    setErrorUpload: (state, { payload }) => {
      state.error.upload = {
        ...state.error.upload,
        ...payload,
      }
    },
    resetErrorUpload: (state, { payload }) => {
      _.set(state.error.upload, payload, null)
    },
    setChangeBody: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.data, key, value)
    },
    setAddStation: (state) => {
      const stationInformation = [
        ..._.get(state.data, 'stationInformation', []),
      ]
      stationInformation.push(initialStation)
      _.set(state.data, 'stationInformation', stationInformation)
    },
    setDeleteStation: (state, { payload }) => {
      const stationInformation = [
        ..._.get(state.data, 'stationInformation', []),
      ]
      stationInformation.splice(payload, 1)
      _.set(state.data, 'stationInformation', stationInformation)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageContentStation.fulfilled, (state, { payload }) => {
        state.data = _.get(payload, 'data', null)
        state.isUploadLoading = {}
        state.error.upload = null
      })
      .addCase(getManageContentStation.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
      .addCase(postUploadLocation.rejected, (state, { payload }) => {
        state.error.upload = {
          ...state.error.upload,
          [payload]: 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง',
        }
      })
      .addCase(postUploadLocationBanner.rejected, (state) => {
        state.error.upload = {
          ...state.error.upload,
          image: 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง',
        }
      })
      .addCase(putManageContentStation.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(putManageContentStation.rejected, (state, action) => {
        state.error.all = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startUploadLoading,
  stopUploadLoading,
  setErrorUpload,
  resetErrorUpload,
  setChangeBody,
  setAddStation,
  setDeleteStation,
} = manageContentStation.actions

export default manageContentStation.reducer
