import { Chip } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledOutlinedChip = styled((props) => (
  <Chip variant="outlined" {...props} />
))(({ theme }) => ({
  fontSize: 17,
  height: 24,
  borderColor: theme?.palette?.blue?.oceanBlue,
  color: theme?.palette?.blue?.oceanBlue,
}))

export const StyledContainedChip = styled((props) => <Chip {...props} />)(
  () => ({
    height: 24,
  }),
)
