import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import CreatableGroupChip from '../../../../../../../components/CreatableGroupChip'
import SelectGroupChip from '../../../../../../../components/SelectGroupChip'
import HeadLabel from '../../../../../components/HeadLabel'
import { getStateValue, handleChange } from '../../../events'
import { handleDefaultList, handleKeyDown } from '../events'

const ChipForm = () => {
  const [inputValue, setInputValue] = useState('')
  const tagKey = 'setting.tag'
  const distributionKey = 'setting.distribution'
  const levelOfLearnerKey = 'levelOfLearner'
  const acquiredSkillKey = 'acquiredSkill'
  const productTypeKey = 'productType'
  const dispatch = useDispatch()
  const {
    distributionOption,
    levelOfLearnerOption,
    acquireSkillList,
    productTypeList,
    tag,
    distribution,
    levelOfLearner,
    acquiredSkill,
    productType,
  } = useSelector(
    (state) => ({
      distributionOption: state.eLearningPathForm.distributionOption,
      levelOfLearnerOption: state.eLearningPathForm.levelOfLearnerOption,
      acquireSkillList: state.eLearningPathForm.acquireSkillList,
      productTypeList: state.eLearningPathForm.productTypeList,
      tag: getStateValue(tagKey, []),
      distribution: getStateValue(distributionKey, []),
      levelOfLearner: getStateValue(levelOfLearnerKey, []),
      acquiredSkill: getStateValue(acquiredSkillKey, []),
      productType: getStateValue(productTypeKey, []),
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="การตั้งค่า Learning path" />
        <CreatableGroupChip
          id="tag"
          labelText="Tag"
          placeholder="กรุณาเพิ่ม Tag"
          value={tag}
          textError={_.get(errors, tagKey, '')}
          inputValue={inputValue}
          onChange={(value) => dispatch(handleChange(tagKey, value))}
          onInputChange={(value) => setInputValue(value)}
          onKeyDown={(e) =>
            dispatch(handleKeyDown(e, inputValue, setInputValue))
          }
        />
        <SelectGroupChip
          required
          id="distribution"
          name="distribution"
          labelText="ช่องทางการจัดจำหน่าย"
          placeholder="กรุณาเลือก"
          options={[...distributionOption]}
          textError={_.get(errors, distributionKey, '')}
          isShowTextError={_.get(errors, distributionKey, '')}
          value={distribution}
          onChange={(list) => dispatch(handleChange(distributionKey, list))}
        />
        <SelectGroupChip
          required
          id={levelOfLearnerKey}
          name={levelOfLearnerKey}
          labelText="ระดับของผู้เรียน"
          placeholder="กรุณาเลือก"
          options={[...levelOfLearnerOption]}
          textError={_.get(errors, levelOfLearnerKey, '')}
          isShowTextError={_.get(errors, levelOfLearnerKey, '')}
          value={handleDefaultList(levelOfLearnerOption, levelOfLearner)}
          onChange={(list) => dispatch(handleChange(levelOfLearnerKey, list))}
        />
      </Box>

      <Divider sx={{ mx: 0.5 }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <HeadLabel text="Skill Mapping" />
        <SelectGroupChip
          required
          id={acquiredSkillKey}
          name={acquiredSkillKey}
          labelText="ระดับหลักสูตร"
          placeholder="กรุณาเลือก"
          options={acquireSkillList}
          textError={_.get(errors, acquiredSkillKey, '')}
          isShowTextError={_.get(errors, acquiredSkillKey, '')}
          value={handleDefaultList(acquireSkillList, acquiredSkill)}
          onChange={(list) => dispatch(handleChange(acquiredSkillKey, list))}
        />
        <SelectGroupChip
          required
          id={productTypeKey}
          name={productTypeKey}
          labelText="ทักษะที่ได้จากหลักสูตร"
          placeholder="กรุณาเลือก"
          options={productTypeList}
          textError={_.get(errors, productTypeKey, '')}
          isShowTextError={_.get(errors, productTypeKey, '')}
          value={handleDefaultList(productTypeList, productType)}
          onChange={(list) => dispatch(handleChange(productTypeKey, list))}
        />
      </Box>
    </>
  )
}

export default ChipForm
