import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme?.palette?.background?.default,
  border: `1px solid ${theme?.palette?.other?.divider}`,
  borderRadius: theme.spacing(1),
}))

export const BoxHederGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const CardGroup = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
  boxShadow: 'unset',
}))

export const BoxCard = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  alignItems: 'start',
}))

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  alignItems: 'center',
  gap: theme.spacing(1),
}))
export const BoxButtonAdd = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
}))

export const TextLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}))

export const BoxContentService = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(3),
}))
