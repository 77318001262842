import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextInput from '../../../../../../components/Input/TextInput'
import { StyledFormCard } from '../../../../Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import { handleInputFormat, handlePasteFormat } from '../Period/events'
import { handleDefaultValue } from '../SellCourse/events'
import { StyledFlexBox, StyledRadioGroup } from '../Styled'

const CollectHours = () => {
  const dispatch = useDispatch()
  const keyCollect = 'setting.collectHours'
  const keyIsCollect = `${keyCollect}.isCollect`
  const keyHours = `${keyCollect}.hours`
  const keyDescription = `${keyCollect}.description`
  const { isCollect, hours, description, onGoing } = useSelector(
    (state) => ({
      isCollect: getStateValue(keyIsCollect),
      hours: getStateValue(keyHours),
      description: getStateValue(keyDescription),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">เก็บชั่วโมงหลักสูตรนี้ใช่หรือไม่</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <StyledRadioGroup
          name={keyIsCollect}
          value={isCollect.toString()}
          onChange={(e) => {
            const isCollect = e.target.value === 'true'
            dispatch(handleChange(keyIsCollect, isCollect))
            if (!isCollect) {
              dispatch(handleChange(keyHours, ''))
              dispatch(handleChange(keyDescription, ''))
            }
          }}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="ใช่"
            disabled={onGoing}
          />
          {isCollect && (
            <>
              <StyledFlexBox sx={{ ml: 3, alignItems: 'start' }}>
                <Typography width={120} mt="11px">
                  จำนวนชั่วโมง
                </Typography>
                <StyledFlexBox sx={{ gap: 2, alignItems: 'start' }}>
                  <TextInput
                    boxSx={{ width: 180 }}
                    sx={{ height: 50 }}
                    name={keyHours}
                    type="basic"
                    id="input-hours"
                    maxlength={4}
                    inputProps={{
                      onInput: (e) => handleInputFormat(e),
                      onPaste: (e) => handlePasteFormat(e),
                    }}
                    textError={_.get(errors, keyHours, '')}
                    isShowTextError={_.get(errors, keyHours, '')}
                    value={handleDefaultValue(hours)}
                    onChange={(e) => dispatch(handleChangeEvent(e))}
                    disabled={onGoing}
                  />
                  <Typography mt="11px">ชั่วโมง</Typography>
                </StyledFlexBox>
              </StyledFlexBox>
              <TextInput
                boxSx={{ ml: 3, pr: 3 }}
                multiline
                rows={3}
                name={keyDescription}
                type="basic"
                labelText="รายละเอียดเพิ่มเติม"
                placeholder="รายละเอียดเพิ่มเติม"
                id="input-description"
                textError={_.get(errors, keyDescription, '')}
                isShowTextError={_.get(errors, keyDescription, '')}
                value={description}
                onChange={(e) => dispatch(handleChangeEvent(e))}
                disabled={onGoing}
              />
            </>
          )}
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="ไม่ใช่"
            disabled={onGoing}
          />
        </StyledRadioGroup>
      </Box>
    </StyledFormCard>
  )
}

export default CollectHours
