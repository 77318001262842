import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column } from '../../../Styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette?.background?.blue,
}))

export const StyledContent = styled(Column)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  margin: 'auto',
  alignItems: 'start',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
}))
