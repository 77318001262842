import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import ButtonForm from './ButtonForm'
import { borrowSteps, initialValues } from './model'
import { schema } from './schema'
import {
  fetchDepartment,
  handleEditInitValue,
  handleInitValue,
  handleSubmit,
} from './events'
import {
  StyledStepper,
  StyledDrawer,
  StyledHeadLabel,
  StyledContainer,
} from './Styled'

const BorrowDrawer = (props) => {
  const {
    open,
    onClose,
    data,
    borrowData,
    areaUuid,
    listResponsibility,
    editIndex,
    speakerBorrows,
  } = props
  const [activeStep, setActiveStep] = useState(0)
  const [initialForm, setInitialForm] = useState(initialValues)
  const [optionDepartment, setOptionDepartment] = useState([])
  const [purposeValidate, setPurposeValidate] = useState('')
  const startTime = _.get(borrowData ? borrowData : data, 'startTime', '')
  const endTime = _.get(borrowData ? borrowData : data, 'endTime', '')
  const dispatch = useDispatch()

  useEffect(() => {
    setActiveStep(0)
    if (open) handleEditInitValue({ data, setInitialForm })
  }, [open])

  useEffect(() => {
    handleInitValue({ data, setInitialForm, editIndex })
  }, [data, editIndex])

  useEffect(() => {
    fetchDepartment(areaUuid, setOptionDepartment)
  }, [])

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ยืมวิทยากรโซนอื่น</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Formik
        initialValues={initialForm}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={(values) => dispatch(handleSubmit({ values, ...props }))}
      >
        {() => (
          <Form>
            <StyledContainer>
              <StyledStepper activeStep={activeStep} nonLinear alternativeLabel>
                {borrowSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </StyledStepper>

              {activeStep === 0 && (
                <StepOne
                  open={open}
                  optionDepartment={optionDepartment}
                  purposeValidate={purposeValidate}
                />
              )}
              {activeStep === 1 && (
                <StepTwo
                  speakerBorrows={speakerBorrows}
                  optionResponsibility={listResponsibility}
                  startTime={startTime}
                  endTime={endTime}
                />
              )}

              <Divider />
              <ButtonForm
                onClose={onClose}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setPurposeValidate={setPurposeValidate}
              />
            </StyledContainer>
          </Form>
        )}
      </Formik>
    </StyledDrawer>
  )
}

export default BorrowDrawer
