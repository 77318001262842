import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { StyledRow } from '../Styled'
import TextInput from '../../../../../components/Input/TextInput'
import Select from '../../../../../components/Input/Select'
import { fetchStationList, handleChangeNumberInput } from '../event'
import { Typography } from '@mui/material'
import { formattedNumberToString, handleChange } from '../../event'
import { handleNumberInput } from '../../../../../utils/lib'

const Index = () => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const [stationList, setStationList] = useState([])

  const { roomName, stationUuid, floor } = useSelector(
    (state) => ({
      roomName: state.masterDataMeetingRoomForm.information.roomName,
      stationUuid: state.masterDataMeetingRoomForm.information.stationUuid,
      floor: state.masterDataMeetingRoomForm.information.floor,
    }),
    shallowEqual,
  )

  useEffect(() => {
    fetchStationList(setStationList)
  }, [])

  return (
    <>
      <Typography variant="h6">รายละเอียดห้อง</Typography>
      <Select
        dataTestid="select-station"
        id="station"
        name="station"
        labelText="Station"
        required
        placeholder="กรุณาเลือก"
        options={stationList}
        showTextError={true}
        value={stationUuid}
        handleChange={(e) =>
          dispatch(
            handleChange({
              key: 'stationUuid',
              value: _.get(e, 'target.value', ''),
            }),
          )
        }
        textError={_.get(errors, `information.stationUuid`, '')}
      />
      <StyledRow>
        <TextInput
          required
          type="basic"
          name="roomName"
          labelText={'ชื่อห้อง'}
          placeholder="กรุณากรอก"
          value={roomName}
          onChange={(e) => {
            dispatch(
              handleChange({
                key: 'roomName',
                value: _.get(e, 'target.value', ''),
              }),
            )
          }}
          textError={_.get(errors, `information.roomName`, '')}
          sxTextError={{ mb: 3 }}
        />
        <TextInput
          required
          type="basic"
          name="floor"
          labelText={'ชั้น'}
          placeholder="กรุณากรอก"
          value={formattedNumberToString(floor) || floor}
          textError={_.get(errors, `information.floor`, '')}
          sxTextError={{ mb: 3 }}
          inputProps={{
            type: 'text',
            onInput: (e) => handleNumberInput(e),
          }}
          onChange={(e) => {
            dispatch(handleChangeNumberInput(e, 'floor'))
          }}
        />
      </StyledRow>
    </>
  )
}

export default Index
