import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { setChangeEvaluationInfo } from '../../../../../redux/slices/eEvaluationForm'

import * as reducer from '../../../../../redux/slices/eEvaluationForm'
import * as service from '../../../../../services/eEvaluation/form'
import { checkTypeFile, dialogSizeImage } from '../event'
import { InputImageUpload } from '../Styled'

export const UploadImage = ({
  htmlFor,
  testId,
  labelText,
  onUpload,
  disabled,
}) => {
  return (
    <label htmlFor={htmlFor}>
      <Button
        sx={{ mr: 1, minWidth: 119 }}
        data-testid={testId}
        disabled={disabled}
        variant="contained"
        size="s"
        component="span"
        htmlFor={htmlFor}
      >
        {!disabled && (
          <InputImageUpload
            accept="image/jpg, image/jpeg, image/png"
            type="file"
            id={testId}
            onChange={onUpload}
          />
        )}

        {labelText && <Typography variant="body1">{labelText}</Typography>}
      </Button>
    </label>
  )
}

export const handleChangeInformation = (props) => (dispatch) => {
  dispatch(setChangeEvaluationInfo(props))
}

export const handleDeleteImage = () => (dispatch) => {
  dispatch(setChangeEvaluationInfo({ value: '', key: 'coverImage' }))
  dispatch(setChangeEvaluationInfo({ value: null, key: 'loadingProgress' }))
  dispatch(setChangeEvaluationInfo({ value: null, key: 'uploadError' }))
}

export const handleUploadImage = (e, keyValue) => async (dispatch) => {
  let body = new FormData()
  if (e.target.files.length === 0) return
  body.append('file', e.target.files[0])
  const getNameImage = _.get(e, 'target.files[0].name', '')

  let elementId = document.getElementById('btn-add-image')

  if (getNameImage) {
    const getExtensionImage = getNameImage.split('.').pop()
    const sizeFile = _.get(e, 'target.files[0].size', 0)

    if (!checkTypeFile(getExtensionImage.toLowerCase())) {
      if (elementId) elementId.value = null
      return dispatch(dialogSizeImage())
    }

    if (sizeFile > 2000000) {
      if (elementId) elementId.value = null
      return dispatch(dialogSizeImage(true))
    }
  }
  // service upload
  const payload = {
    body,
    key: 'loadingProgress',
    type: 'information',
  }
  const dataUpload = await dispatch(service.uploadFileEvaluation(payload))
  dispatch(
    reducer.setChangeEvaluationInfo({
      key: keyValue,
      value: _.get(dataUpload, 'payload.data.key', ''),
    })
  )
  if (elementId) elementId.value = null
}
