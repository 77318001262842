import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { questionType, versionStatus } from '../../../../../constants/eTesting'
import Dot from '../../../../../components/redux/Dot'
import { StyledCard } from '../../../Styled'
import {
  StyledBtnMore,
  StyledCardContent,
  StyledContainerChip,
  StyledContentRow,
  StyledDivider,
  StyledFooterText,
  StyledOutlinedChip,
} from './Styled'
import {
  handleAssessmentAmount,
  handleQuestionTypeList,
  handleSectionList,
} from './events'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

export const ContentRow = ({ title, content, children }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      {children ? children : <Typography variant="body1">{content}</Typography>}
    </StyledContentRow>
  )
}

export const QuestionTypeContent = ({ detail }) => {
  const questionTypes = handleQuestionTypeList(detail)

  return (
    <ContentRow title="รูปแบบคำถาม">
      <StyledContainerChip>
        {questionTypes.map((type) => (
          <StyledOutlinedChip label={questionType[type]} key={type} />
        ))}
      </StyledContainerChip>
    </ContentRow>
  )
}

export const SectionContent = ({ detail }) => {
  const sectionList = handleSectionList(detail)

  return (
    <ContentRow title="จำนวนตอน">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>{sectionList.length} ตอน</label>
        <ul style={{ margin: 0, marginLeft: '-10px' }}>
          {sectionList.map((item, index) => (
            <li key={index}>
              <Typography
                variant="body1"
                sx={{ top: '-2px', position: 'relative' }}
              >
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </ContentRow>
  )
}

export const CourseContent = ({ detail }) => {
  const [limit, setLimit] = useState(5)
  const courses = _.get(detail, 'setting.courseRef', [])
  return (
    <ContentRow title="หลักสูตรที่นำไปใช้">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <label>{courses.length} หลักสูตร</label>
        <ul style={{ margin: 0, marginLeft: '-10px' }}>
          {courses.slice(0, limit).map((item, index) => (
            <li key={index}>
              <Typography
                variant="body1"
                sx={{ top: '-2px', position: 'relative' }}
              >
                {item.courseName ?? item}{' '}
                <Typography variant="span" color="primary">
                  {item.nameForLearner}
                </Typography>{' '}
                เวอร์ชั่น {item.version}
              </Typography>
            </li>
          ))}
          {courses.length > 5 && limit == 5 && (
            <StyledBtnMore onClick={() => setLimit(courses.length)}>
              แสดงทั้งหมด <KeyboardArrowDown />
            </StyledBtnMore>
          )}
          {courses.length > 5 && limit == courses.length && (
            <StyledBtnMore onClick={() => setLimit(5)}>
              ซ่อน <KeyboardArrowUp />
            </StyledBtnMore>
          )}
        </ul>
      </Box>
    </ContentRow>
  )
}

export const AssingeeContent = ({ detail }) => {
  const creator = _.get(detail, 'setting.createdBy.uuid', '-')
  const assignees = _.get(detail, 'setting.assignees', [])
  const onlyAssignees = assignees.filter((item) => item.uuid != creator)

  return (
    <ContentRow title="ผู้ร่วมจัดทำ">
      {onlyAssignees.length > 0 ? (
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {onlyAssignees.map((item, index) => (
            <Typography key={index}>
              {item.name}
              {onlyAssignees.length > 1 &&
                index < onlyAssignees.length - 1 &&
                ','}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography>-</Typography>
      )}
    </ContentRow>
  )
}

export const StatusContent = ({ detail }) => {
  const status = _.get(detail, 'setting.status', 'ACTIVE')
  return (
    <ContentRow title="สถานะ">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot status={status} sx={{ mt: 1.5 }} />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}

export const FooterContent = ({ detail }) => {
  const updatedBy = _.get(detail, 'setting.updatedBy.name', '')
  const updatedAt = _.get(detail, 'setting.viewUpdatedAt', '')

  return (
    <StyledFooterText variant="caption" color="text.lightGray">
      แก้ไขล่าสุดโดย {updatedBy} {updatedAt}
    </StyledFooterText>
  )
}

const Content = ({ detail }) => {
  const assessmentAmount = useMemo(
    () => handleAssessmentAmount(detail),
    [detail]
  )
  return (
    <StyledCard id="view">
      <StyledCardContent>
        <ContentRow title="ID" content={_.get(detail, 'codeId', 0)} />
        <ContentRow title="เวอร์ชั่น" content={_.get(detail, 'version', -1)} />
        <ContentRow
          title="ชื่อแบบประเมิน"
          content={_.get(detail, 'information.name', '-')}
        />
        <SectionContent detail={detail} />
        <ContentRow title="จำนวนคำถาม" content={`${assessmentAmount} ข้อ`} />
        <QuestionTypeContent detail={detail} />
        <CourseContent detail={detail} />
        <ContentRow
          title="ผู้สร้าง"
          content={_.get(detail, 'setting.createdBy.name', '-')}
        />
        <AssingeeContent detail={detail} />
        <StatusContent detail={detail} />
        <ContentRow
          title="วันเริ่มต้นใช้งาน"
          content={convertFormatDateTime({
            value: _.get(detail, 'setting.startDate', '-'),
            type: 'date',
          })}
        />

        <StyledDivider />
        <FooterContent detail={detail} />
      </StyledCardContent>
    </StyledCard>
  )
}

export default Content
