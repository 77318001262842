import { inventoryProduct } from '../../../../utils/apiPath'

export const crudConfig = {
  moduleId: 'INVENTORY_PRODUCT_LICENSE',
  moduleSlug: 'inventory-product-license',
  moduleTitleFull: 'ทรัพย์สิน',
  modulesTitleShort: 'ทรัพย์สิน',
  modulePath: '/inventory/product/license',
  filterPath: inventoryProduct,
  masterDataModuleId: [
    {
      key: 'category',
      moduleId: 'STOCK_CATEGORY',
    },
    {
      key: 'subCategory',
      moduleId: 'STOCK_SUB_CATEGORY',
    },
    {
      key: 'costCenter',
      moduleId: 'COST_CENTER',
    },
    {
      key: 'vendor',
      moduleId: 'VENDOR',
    },
  ],
}
