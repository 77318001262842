import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextInput from '../../../../../../components/Input/TextInput'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../components/redux/PopUpDialog/Styled'
import { closeDialog } from '../../../../../../redux/slices/dialog'
import { StyledDialog } from './Styled'
import { handleRemarkChange, handleSubmitChange } from './events'

export const EditRemarkDialog = ({ isStandalone }) => {
  const { isLoading, disableButton, body } = useSelector(
    (state) => ({
      body: state.manageClassCheckExam.body,
      isLoading: state.dialog.isLoading,
      disableButton: state.dialog.disableButton,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">แก้ไขคะแนน</Typography>
      </Box>
      <StyledDialog>
        <Typography>กรุณาให้เหตุผลในการแก้ไขคะแนนในครั้งนี้</Typography>
        <TextInput
          id="input-reason"
          type="basic"
          multiline
          rows={1}
          isShowTextError={false}
          maxlength={3000}
          onChange={(e) => dispatch(handleRemarkChange(e, body))}
        />
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(closeDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          color="primary"
          endIcon={<></>}
          loading={isLoading}
          disabled={disableButton}
          onClick={() => dispatch(handleSubmitChange(body, isStandalone))}
        >
          ยืนยัน
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}

export default EditRemarkDialog
