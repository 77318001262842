import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import useTheme from '@mui/system/useTheme'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { DialogContext } from '../../context/dialog-context'
import AlertDialog from '../../components/AlertDialog'
import { filterVersion, downloadVersion } from '../../utils/apiPath'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import FilterDrawerVersion from './FilterDrawerVersion'
import { handleFilterTotal } from '../../redux/slices/table/events'
import { callAPI, handleDownload, validatePermission } from '../../utils/lib'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const breadcrumbList = [
  { title: 'จัดการหลักสูตร', link: '/course-setting', pointer: true },
  { title: 'เวอร์ชั่น', link: '/', pointer: false },
]

export const headCells = [
  {
    id: 'statusVersion',
    label: '',
    width: '20px',
    minWidth: '20px',
    disablePadding: true,
  },
  {
    id: 'version',
    disablePadding: false,
    label: 'เวอร์ชั่น',
    width: '20px',
  },
  {
    id: 'englishName',
    label: 'ชื่อหลักสูตร EN',
    disablePadding: false,
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    disablePadding: false,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มต้น',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'statusText',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'actionCourseVersion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
  },
]

export const onView = (row, history) => {
  history.push(`/course-setting/version/preview/${row.uuid}`)
}

export const onEdit = (row, history) => {
  history.push('/course-setting/version/edit/' + row.uuid)
}

export const onFilterClick = async (
  filter,
  limit,
  order,
  sort,
  setSearchText,
  setText,
  setStatus,
  setDateStart,
  setDateFinish,
  setVersion,
  setTablePage,
  fetchVersionList,
  setFilterTotal
) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.draftChecked) {
      statusList.push('DRAFT')
    }
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.pendingChecked) {
      statusList.push('WAITING_FOR_ACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)
  const version =
    filter.versionChecked && filter.version !== '' ? filter.version : 0
  const versionText = version === 0 ? -1 : version
  const englishNameText = filter?.englishNameChecked ? filter.englishName : ''
  const nameForLearnerText = filter?.nameForLearnerChecked
    ? filter.nameForLearner
    : ''
  const startDateText = filter?.startDateChecked ? filter.startDate : ''
  const finishDateText = filter?.startDateChecked ? filter.toDate : ''
  const updatedByText = filter?.updatedByChecked ? filter.updatedBy : ''
  const createdByText = filter?.createdByChecked ? filter.createdBy : ''
  const assigneeText = filter?.assigneeChecked ? filter.assignee : ''
  setSearchText(englishNameText)
  setDateStart(startDateText)
  setDateFinish(finishDateText)
  setVersion(versionText)

  setTablePage(0)
  fetchVersionList({
    method: 'filter',
    statusList: statusList,
    limit: limit,
    order: order,
    page: 1,
    sort: sort,
    search: englishNameText,
    version: versionText,
    nameForLearnerText: nameForLearnerText,
    startDateText: startDateText,
    finishDateText: finishDateText,
    updatedByText: updatedByText,
    createdByText: createdByText,
    assigneeText: assigneeText,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const onDownload = async (
  selected,
  order,
  sort,
  setIsLoading,
  courseCode
) => {
  setIsLoading(true)
  const body = {
    version: -1,
    englishName: '',
    courseCode: courseCode,
    startDate: '',
    finishDate: '',
    updatedBy: '',
    order: order.toUpperCase(),
    sort: sort === '' ? 'version' : sort,
    status: [],
    type: 'CUSTOM',
    list: selected,
  }
  await handleDownload({
    url: downloadVersion,
    body: body,
    fileName: `รายการเวอร์ชั่น.xlsx`,
  })
  setIsLoading(false)
}

export const handleQuickSearch = async (
  text,
  limit,
  order,
  sort,
  version,
  setTablePage,
  fetchVersionList
) => {
  setTablePage(0)
  const statusList = [
    'DRAFT',
    'ACTIVE',
    'INACTIVE',
    'WAITING_FOR_ACTIVE',
    'DELETED',
  ]
  fetchVersionList({
    method: 'search',
    statusList: statusList,
    limit: limit,
    order: order,
    page: 1,
    sort: sort,
    search: text,
    version: version,
  })
}

const VersionList = (props) => {
  const { index } = props
  const { id: uuid } = useParams()
  const history = useHistory()
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const { id: courseCode } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [versionList, setVersionList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [hiddenButton, setHiddenButton] = useState(0)
  const [status, setStatus] = useState([
    'ACTIVE',
    'INACTIVE',
    'DELETED',
    'WAITING_FOR_ACTIVE',
    'DRAFT',
  ])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [version, setVersion] = useState(-1)
  const [dateStart, setDateStart] = useState('')
  const [dateFinish, setDateFinish] = useState('')
  const [filterTotal, setFilterTotal] = useState(0)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog

  const getPermissionCouseSettingCreate = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['CREATE'],
  })

  useEffect(() => {
    setIsPageLoading(true)

    fetchVersionList({
      method: 'fetch',
      statusList: status,
      limit: limit,
      order: order,
      page: page,
      sort: sort,
      startDateText: dateStart,
      finishDateText: dateFinish,
      search: searchText,
      version: version,
      createdByText: '',
      assigneeText: '',
    })
  }, [limit, order, sort, page])

  const handleClose = () => {
    setDialog({ ...dialog, open: false })
  }

  const fetchVersionList = async ({
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    version,
    nameForLearnerText,
    startDateText,
    finishDateText,
    updatedByText,
    createdByText,
    assigneeText,
  }) => {
    const newSort = sort === 'statusText' ? 'status' : sort
    const sortBy = sort === '' ? 'version' : newSort
    const realPage = page <= 0 ? 1 : +page
    setIsPageLoading(true)
    dayjs.extend(utc)
    const floatVersion = parseFloat(version)

    const body = {
      calendar: false,
      version: !_.isString(version) && floatVersion === 0 ? -1 : version,
      englishName: method !== 'fetch' ? search : searchText,
      nameForLearner: nameForLearnerText || '',
      courseCode: index ? 'index' : courseCode,
      startDate: startDateText
        ? dayjs(startDateText).utc().format('YYYY-MM-DDT00:00:00[Z]')
        : '',
      toDate: finishDateText
        ? dayjs(finishDateText).utc().format('YYYY-MM-DDT00:00:00[Z]')
        : '',
      updatedBy: updatedByText || '',
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
      createdBy: createdByText || '',
      assignee: assigneeText || '',
    }

    callAPI({
      method: 'post',
      url: filterVersion,
      body: body,
      onSuccess: (data) => {
        setVersionList(
          data.result.map((item) => {
            return {
              ...item,
              version: item.version === 0 ? '' : item.version,
              nameForLearner: item.nameForLearner || '-',
            }
          })
        )
        setAllCount(data.totalCount)
        let countStatus = 0
        if (data.result.length) {
          data.result.map((item) => {
            item.statusVersion = item.status
            if (item.status === 'DELETED' || item.status === 'DRAFT') {
              countStatus++
            }
          })
          setHiddenButton(countStatus)
        }
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">เวอร์ชั่น (Version)</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        {getPermissionCouseSettingCreate &&
          hiddenButton !== versionList.length && (
            <Button
              sx={{
                ml: 2,
                [theme.breakpoints.up('md')]: {
                  position: 'absolute',
                  mt: -10,
                  mr: 3,
                },
                right: 0,
              }}
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => {
                window.location.href = '/course-setting/version/form/' + uuid
              }}
            >
              สร้างเวอร์ชั่นใหม่
            </Button>
          )}
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={versionList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            placeholder="ค้นหาชื่อหลักสูตร EN"
            searchKey={'englishName'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(text) =>
              handleQuickSearch(
                text,
                limit,
                order,
                sort,
                version,
                setTablePage,
                fetchVersionList
              )
            }
            onDownload={(selected) =>
              onDownload(selected, order, sort, setIsLoading, courseCode)
            }
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawerVersion
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick(
            filter,
            limit,
            order,
            sort,
            setSearchText,
            setText,
            setStatus,
            setDateStart,
            setDateFinish,
            setVersion,
            setTablePage,
            fetchVersionList,
            setFilterTotal
          )
        }
        drawerType={'versions'}
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={handleClose}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </Box>
  )
}
export default VersionList
