import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const roomManagementHistory = createSlice({
  name: 'roomManagementHistory',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startFetch: (state) => {
      state.isFetch = true
      state.filter.page = 0
    },
    stopFetch: (state) => {
      state.isFetch = false
    },
    loadNextPage: (state) => {
      state.isFetch = true
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setHasMore: (state, { payload }) => {
      state.hasMore = payload
    },
    setHistory: (state, { payload }) => {
      state.history = payload
      state.filter.page = 1
    },
    concatHistory: (state, { payload }) => {
      state.history = [...state.history, ...payload]
      state.filter.page = state.filter.page + 1
    },
    clearState: (state) => {
      state.filter = {
        page: 0,
        startDate: '',
        endDate: '',
      }
      state.hasMore = false
      state.history = []
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startFetch,
  stopFetch,
  loadNextPage,
  setFilter,
  setHasMore,
  setHistory,
  concatHistory,
  clearState,
} = roomManagementHistory.actions

export default roomManagementHistory.reducer
