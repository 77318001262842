import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'E_CONTENT_CATEGORY',
  moduleSlug: 'e-content-category',
  moduleTitleFull: 'หมวดหมู่หลัก คลังความรู้',
  modulesTitleShort: 'หมวดหมู่หลัก',
  modulePath: '/master-data/e-content/category',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
