import React from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'

import { useDispatch, useSelector } from 'react-redux'

import Select from '../../../../components/Input/Select'

import FilterDrawer from '../FilterDrawer'
import * as Styled from './Styled'
import * as events from './events'

const Index = () => {
  const dispatch = useDispatch()

  const { month, year } = useSelector((state) => ({
    month: state.manageClassCalendar.month,
    year: state.manageClassCalendar.year,
  }))

  return (
    <>
      <Styled.BoxHeader>
        <Styled.BoxIcon>
          <IconButton
            data-testid="icon-back"
            sx={{ height: 36, width: 36 }}
            onClick={() =>
              dispatch(events.handleChangeIcon({ month, year, type: 'back' }))
            }
          >
            <ArrowBackIos
              fontSize="small"
              color="primary"
              sx={{ ml: 0.5, cursor: 'pointer' }}
            />
          </IconButton>

          <IconButton
            data-testid="icon-next"
            sx={{ height: 36, width: 36 }}
            onClick={() =>
              dispatch(events.handleChangeIcon({ month, year, type: 'next' }))
            }
          >
            <ArrowForwardIos
              fontSize="small"
              color="primary"
              sx={{ cursor: 'pointer' }}
            />
          </IconButton>

          <Styled.BoxSelect isFirst>
            <Select
              dataTestid="select-month"
              id="month"
              name="month"
              type="basic"
              showTextError={false}
              options={events.optionMonth}
              placeholder="เดือน"
              handleChange={(e) =>
                dispatch(
                  events.handleChange({
                    key: 'month',
                    value: _.get(e, 'target.value', 1),
                  })
                )
              }
              value={month}
            />
          </Styled.BoxSelect>

          <Styled.BoxSelect>
            <Select
              dataTestid="select-year"
              id="year"
              name="year"
              type="basic"
              showTextError={false}
              options={events.optionYear({
                start: year - 10,
                end: year + 10,
              })}
              placeholder="ปี"
              handleChange={(e) =>
                dispatch(
                  events.handleChange({
                    key: 'year',
                    value: _.get(e, 'target.value', 1),
                  })
                )
              }
              value={year}
            />
          </Styled.BoxSelect>

          <Styled.BoxFilter>
            <Button
              data-testid="btn-filter-drawer"
              sx={{ px: 3 }}
              variant="outlined"
              startIcon={<FilterListOutlined />}
              onClick={() => dispatch(events.openFilterDrawer())}
            >
              <Typography variant="buttonm">ตัวกรอง</Typography>
            </Button>
          </Styled.BoxFilter>
        </Styled.BoxIcon>
        <Styled.BoxFilterMobile>
          <Button
            data-testid="btn-filter-drawer"
            sx={{ px: 3 }}
            variant="outlined"
            startIcon={<FilterListOutlined />}
            onClick={() => dispatch(events.openFilterDrawer())}
          >
            <Typography variant="buttonm">ตัวกรอง</Typography>
          </Button>
        </Styled.BoxFilterMobile>
      </Styled.BoxHeader>

      <FilterDrawer />
    </>
  )
}

export default Index
