import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledHeaderTitle = styled(Box)(
  ({ theme, section, isSetTimer }) => ({
    width: section == 'RESULT' || isSetTimer ? 'calc(100% - 133px)' : '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiTypography-root': {
      color: theme.palette?.text?.white,
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
)
