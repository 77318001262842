import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ContentQRCode } from './'
import { openDialog } from '../../../../redux/slices/dialog'
import { putAttendanceStartTimer } from '../../../../services/attendance'

import {
  setChange,
  setChangeExpiredDate,
} from '../../../../redux/slices/tableAttendance'

export const checkExpiredQRCode = (props) => async (dispatch) => {
  const { body, isQRCode, indexRound, rowIndex } = props
  dispatch(setChange({ key: 'qrCodeBody', value: body }))

  if (!isQRCode) {
    dispatch(dialogCheckStartTimer({ isLoading: true, qrCodeTimer: 0 }))
  }

  const result = await dispatch(
    putAttendanceStartTimer({ ...body, isCheckExist: true }),
  )

  const status = _.get(result, 'payload.data.status', false)

  if (!status || !isQRCode) {
    dispatch(
      dialogCheckStartTimer({
        isLoading: false,
        qrCodeTimer: _.get(result, 'payload.data.qrCodeTimer', 0),
        indexRound,
        rowIndex,
      }),
    )
  } else {
    dispatch(dialogQRCode({ indexRound, rowIndex }))
  }
}

export const dialogCheckStartTimer =
  ({ isLoading, qrCodeTimer, indexRound, rowIndex }) =>
  (dispatch) => {
    dispatch(
      openDialog({
        type: 'content',
        title: 'อายุของ QR Code',
        isLoading,
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2, mb: 2 }}>
            <Typography variant="body1">
              เมื่อกดดาวน์โหลดจะเริ่มต้นนับอายุของ QR Code ที่ตั้งค่าไว้ทันที
            </Typography>
            <Typography variant="body1">
              คุณสามารถใช้งานได้{' '}
              <span style={{ color: 'red' }}>{qrCodeTimer} </span> นาที
              ต้องการใช้งานตอนนี้เลยหรือไม่?
            </Typography>
          </Box>
        ),
        handleConfirm: () =>
          dispatch(
            dialogQRCode({
              indexRound,
              rowIndex,
            }),
          ),
      }),
    )
  }

export const dialogQRCode = (props) => (dispatch) => {
  dispatch(
    openDialog({
      type: 'mini',
      content: <ContentQRCode props={props} />,
    }),
  )
}

export const countdownTime = (time, timer, setTime) => {
  const { hrs, mins, secs } = time
  if (hrs === 0 && mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (mins === 0 && secs === 0) {
    setTime([hrs - 1, 59, 59])
  } else if (secs === 0) {
    setTime([hrs, mins - 1, 59])
  } else {
    setTime([hrs, mins, secs - 1])
  }
}

export const startTimerQRCode = (props) => async (dispatch) => {
  const result = await dispatch(
    putAttendanceStartTimer({ ...props.body, isCheckExist: false }),
  )
  dispatch(
    setChange({ key: 'objQrCode', value: _.get(result, 'payload.data', {}) }),
  )
  dispatch(
    setChangeExpiredDate({
      indexRound: props.indexRound,
      rowIndex: props.rowIndex,
      value: _.get(result, 'payload.data.expiredDate', {}),
    }),
  )
}
