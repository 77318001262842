import React from "react"
import { Box, Typography } from "@mui/material"
import _ from "lodash"
import { isMediaWidthMoreThan } from "../../../../utils/lib"

export const CourseAssignee = ({course}) => {
    const AssigneeNames = _.get(course, 'assignees', [])
    const isLg = isMediaWidthMoreThan('lg')

    return(
        <Box>
            {AssigneeNames.slice(0, 2).map((data , index) => {
                return(
                    <Typography key={index} sx={{textAlign: 'start', fontSize: !isLg && 14}} variant='body2'>
                        {_.get(data, 'name', '')}
                    </Typography>
                )
            })}
        </Box>
    )
}