import { shallowEqual, useSelector } from 'react-redux'
import { isValidateDate } from '../../../../../../utils/lib'
import { RoomBookingList } from './RoomBookingList'
import { BookingPeriodType } from './BookingPeriodType'
import { isNotSameDate } from '../event'
import dayjs from 'dayjs'

export const BookingForm = () => {
  const { startDate, endDate, bookingPeriod } = useSelector(
    (state) => ({
      startDate: state.roomManagementDrawer.bookingInformation.startDate,
      endDate: state.roomManagementDrawer.bookingInformation.endDate,
      bookingPeriod:
        state.roomManagementDrawer.bookingInformation.bookingPeriod,
    }),
    shallowEqual,
  )

  if (!isValidateDate(startDate) || !isValidateDate(endDate)) {
    return <div />
  }

  return (
    <>
      {isNotSameDate(startDate, endDate) &&
        dayjs(startDate).isBefore(dayjs(endDate)) && <BookingPeriodType />}
      <RoomBookingList bookingPeriod={bookingPeriod} />
    </>
  )
}
