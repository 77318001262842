import _ from 'lodash'
import { setChange } from '../../../../../../redux/slices/eTestingForm'
import { optionSwap } from '../../../../../../constants/eTesting'

export const optionQuestionSwap = (
  questions,
  keyQuestion,
  swapQuestion,
  isTest
) => {
  let result = []
  if (!questions.length) return result

  const findIndex = questions.findIndex((item) => item.key === keyQuestion)

  let isMobile = false

  if (!isTest) isMobile = window?.matchMedia('(pointer:coarse)')?.matches

  questions.map((item, index) => {
    let title = item.title
    if (title.length > 35) {
      title = title.substring(0, isMobile ? 15 : 35) + ' ...'
    }
    if (item.key !== keyQuestion) {
      if (swapQuestion === 'BEFORE' && index !== findIndex + 1) {
        result.push({
          label: `${index + 1}. ${title}`,
          value: index,
        })
      } else if (swapQuestion === 'AFTER' && index !== findIndex - 1) {
        result.push({
          label: `${index + 1}. ${title}`,
          value: index,
        })
      }
    }
  })

  return result
}

export const handleSwap = (props) => (dispatch) => {
  const { questions, keyQuestion, indexSelect, isTest } = props

  dispatch(
    setChange({
      key: props.key,
      value: props.value,
    })
  )

  if (questions && questions.length) {
    const listOptions = optionQuestionSwap(
      questions,
      keyQuestion,
      props.value,
      isTest
    )
    const check = listOptions.find((item) => item.value === indexSelect)
    !check &&
      dispatch(
        setChange({
          key: 'swapQuestion.indexSelect',
          value: listOptions[0].value,
        })
      )
  }
}

export const mapOptionPosition = ({ questions, keyQuestion }) => {
  const findIndex = _.findIndex(questions, ['key', keyQuestion])
  if (findIndex == 0) return [optionSwap[1]]
  if (questions.length === findIndex + 1) return [optionSwap[0]]
  return optionSwap
}
