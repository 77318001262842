import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledType = styled(Box)(({ theme }) => ({
  // display: 'flex',
  background: '#F1F4FA',
  color: theme.palette?.primary.main,
  fontSize: 18,
  fontWeight: 700,
  borderRadius: '16px',
  padding: '5px 16px',
}))
