import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { StyledBox } from '../Styled'
import { handleDeleteDialog } from './events'

const ActionAgentUpdateProfile = ({ row, rowIndex }) => {
  const dispatch = useDispatch()
  return (
    <StyledBox>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() =>
          dispatch(handleDeleteDialog(_.get(row, 'errorData', '')))
        }
      >
        <InsertDriveFileIcon color="action" />
      </IconButton>
    </StyledBox>
  )
}

export default ActionAgentUpdateProfile
