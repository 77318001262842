import _ from 'lodash'
import {
  resetSuccess,
  setNextPage,
  setPreviousPage,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassCheckExam'
import {
  getManageClassLearnerExam,
  getStandaloneUser,
} from '../../../../../services/manageClass/check'

export const handleHeight = (ref, setListHeight) => {
  if (ref.current) {
    setListHeight(
      ref.current.offsetHeight < 680
        ? ref.current.offsetHeight
        : ref.current.offsetHeight + 70
    )
  }
}

export const getLearnerTotal = (learners, tab) => {
  const { learnerAll, learnerWait, learnerPass, learnerFail, learnerNotSent } =
    learners
  switch (tab) {
    case 'ALL':
      return learnerAll.length
    case 'WAITING':
      return learnerWait.length
    case 'PASS':
      return learnerPass.length
    case 'FAIL':
      return learnerFail.length
    case 'NOT_SENT':
      return learnerNotSent.length
    default:
      return learnerAll.length
  }
}

export const handleChangePage = (props, destination) => (dispatch) => {
  const { idProps, learnerList, page, isStandalone } = props
  const isNext = destination === 'next'
  const learner = _.get(learnerList, isNext ? page : page - 2, {})
  const uuidField = isStandalone ? 'userUuid' : 'learnerUuid'
  const learnerUuid = _.get(learner, uuidField, '')
  dispatch(fetchLearnerExam({ idProps, learnerUuid, isStandalone }))
  isNext ? dispatch(setNextPage()) : dispatch(setPreviousPage())
}

export const fetchLearnerExam = (props) => async (dispatch) => {
  const { idProps, learnerUuid, isStandalone } = props
  dispatch(startLoading())
  if (isStandalone) {
    const { eTestingVersionUuid } = idProps
    const payload = {
      eTestingVersionUuid,
      userUuid: learnerUuid,
    }
    await dispatch(getStandaloneUser(payload))
  } else {
    const { id, courseId } = idProps
    const payload = {
      trainingPlanUuid: id,
      courseTestAndEvaUuid: courseId,
      learnerUuid,
    }
    await dispatch(getManageClassLearnerExam(payload))
  }
  dispatch(stopLoading())
}

export const handleSuccess = (isSuccess) => (dispatch) => {
  if (isSuccess) dispatch(resetSuccess())
}

export const handleReloadPage = (props) => {
  const { isComplete, body, history, writeStorage, learnerExam } = props
  const isEdit = _.get(body, 'reasonToChange', '') !== ''
  if (isComplete && !isEdit) {
    writeStorage('presentLearner', learnerExam)
    history.go(0)
  }
}
