import styled from '@mui/system/styled'
import Box from '@mui/material/Box'

export const Container = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 12px 40px 0px #4976BA1A',
}))

export const Tab = styled(Box)(({ theme, background }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  background: background,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5),
  cursor: 'pointer',
}))

export const Dot = styled(Box)(({ background }) => ({
  width: 8,
  height: 8,
  margin: 'auto',
  background: background,
  borderRadius: '50%',
}))

export const OuterDot = styled(Box)(({ color }) => ({
  width: 16,
  height: 16,
  margin: 'auto',
  padding: '2px',
  border: `2px solid ${color}`,
  borderRadius: '50%',
}))

export const ChartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 12px 40px 0px #4976BA1A',
}))
