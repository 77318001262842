const { createSlice } = require('@reduxjs/toolkit')
import { initialState } from './model'

const catalogForm = createSlice({
  name: 'catalogForm',
  initialState,
  reducers: {
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      state.body[key] = value
    },
    setView: (state, { payload }) => {
      state.view = { ...state.view, ...payload }
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setEditForm: (state, { payload }) => {
      state.body = { ...initialState.body, ...payload }
    },
    setInitialCreateForm: (state) => {
      state.isLoading = false
      state.isUploadLoading = false
      state.isUploadSuccess = false
      state.uploadError = null
      state.body = initialState.body
    },
  },
})

export const {
  setChange,
  setFieldValue,
  setView,
  startLoading,
  stopLoading,
  setEditForm,
  setInitialCreateForm,
} = catalogForm.actions

export default catalogForm.reducer
