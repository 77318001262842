import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import _ from 'lodash'

export const handleOpenDrawer = () => {
  const { monthlyPlan, formData, masterData, calendarType } =
    store.getState().crud
  if (!_.get(formData, 'uuid', undefined)) {
    const costCenterOptions = _.get(masterData, 'costCenter.options', [])
    const staffProfileOptions = _.get(masterData, 'staffProfile.options', [])

    let costCenterUuid = null
    let staffProfileUuid = null
    if (calendarType === 'PERSONAL') {
      costCenterUuid = _.get(monthlyPlan, 'staffs[0].costCenter')
      staffProfileUuid = _.get(monthlyPlan, 'staffs[0].uuid', {})
    }

    const costCenter = _.find(
      costCenterOptions,
      (cost) => cost.uuid === costCenterUuid,
    )
    const staffProfile = _.find(
      staffProfileOptions,
      (cost) => cost.uuid === staffProfileUuid,
    )

    store.dispatch(
      setFieldValue({
        key: 'formData',
        value: {
          ...formData,
          staffProfile: staffProfile,
          costCenter: costCenter,
          amountList: [
            {
              accountCode: null,
              amount: null,
            },
          ],
        },
      }),
    )
  }

  store.dispatch(setFieldValue({ key: 'isOpenDrawer', value: true }))
}
