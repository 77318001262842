import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as Styleds from './Styled'
import { handleDisplayPrerequisite } from './events'

const Condition = ({ prerequisite }) => {
  return (
    <Styleds.StyledSectionContainer>
      {prerequisite.map((item, index) => (
        <Box key={index}>
          <Typography variant="body1b" color="text.secondary">
            {`${index !== 0 ? 'หรือ' : ''} เงื่อนไข ${index + 1}`}
          </Typography>

          <ContentFieldConditions key={index} data={item} />
        </Box>
      ))}
    </Styleds.StyledSectionContainer>
  )
}

export default Condition

export const Contents = ({ condition, label }) => {
  return (
    <Styleds.Row sx={{ pl: 2 }}>
      <Styleds.Column gap={0.5}>
        {condition && (
          <Styleds.StyledDivider textAlign="left">
            <Typography color="text.lightGray">{condition}</Typography>
          </Styleds.StyledDivider>
        )}
        <Styleds.Row gap={0.5}>
          <Styleds.StyledTypography
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Styleds.Row>
      </Styleds.Column>
    </Styleds.Row>
  )
}

export const ContentFieldConditions = ({ data }) => {
  const prerequisiteList = handleDisplayPrerequisite(data)
  return (
    <Styleds.Column gap={0.5}>
      {prerequisiteList.map((andCondition) =>
        andCondition.map((item, key) => (
          <Contents key={key} condition={item.condition} label={item.label} />
        ))
      )}
    </Styleds.Column>
  )
}
