import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { LoadingPage } from '../Styled'
import Header from './Header'
import Dashboard from './Dashboard'
import { fetchMangeClassDetail } from './events'
import ETestEEvaList from './ETestEEvaList'

const Detail = () => {
  const { id: uuid } = useParams()
  const { isLoading } = useSelector(
    (state) => state.manageClassDetail,
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMangeClassDetail(uuid))
    const shareTestEvaList = localStorage.getItem('shareTestEvaList') || ''
    localStorage.setItem('shareTestEvaList', shareTestEvaList)
  }, [])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Header />

        <Dashboard />

        <ETestEEvaList />
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default Detail
