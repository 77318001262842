import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'

export const handleAddAmount = () => {
  const { amountList } = store.getState().crud.formData
  const newAmountList = [...amountList, { accountCode: null, amount: null }]

  store.dispatch(
    setFieldValue({ key: 'formData.amountList', value: newAmountList }),
  )
}
