import { store } from '../../../../../App'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { crudConfig } = store.getState().crud
  return {
    ticketUuid: _.get(crudConfig, 'ticketUuid', ''),
    search: _.get(filterProp, 'search', ''),
  }
}
