import {
  resetStaffProfile,
  resetUserProfile,
  setImportFile,
  setUserType,
  startLoading,
  stopLoading,
} from '../../../../../../../../redux/slices/manageClassLearner'
import { downloadTemplate } from '../../../../../../../../services/manageClass/form'

export const handleUserTypeChange = (e) => (dispatch) => {
  dispatch(resetUserProfile())
  dispatch(resetStaffProfile())
  dispatch(setUserType(e.target.value))
}

export const handleDownloadTemplate = (userType) => async (dispatch) => {
  await dispatch(downloadTemplate(userType))
}

export const handleImportFile = (dataImport) => (dispatch) => {
  dispatch(startLoading())
  dispatch(setImportFile(dataImport))

  setTimeout(() => {
    dispatch(stopLoading())
  }, 500)
}
