export const topScheduleCompany = [
  {
    name: 'G Tower',
    number: 200,
    unit: 'รอบ',
  },
  {
    name: 'Chiangmai',
    number: 100,
    unit: 'รอบ',
  },
  {
    name: 'Khonkean',
    number: 80,
    unit: 'รอบ',
  },
  {
    name: 'Chonburi',
    number: 70,
    unit: 'รอบ',
  },
  {
    name: 'Surat SuratSuratSuratSurat',
    number: 60,
    unit: 'รอบ',
  },
]

export const topExaminerCompany = [
  {
    name: 'G Tower',
    number: 200,
    unit: 'คน',
  },
  {
    name: 'Chiangmai',
    number: 100,
    unit: 'คน',
  },
  {
    name: 'Khonkean',
    number: 80,
    unit: 'คน',
  },
  {
    name: 'Chonburi',
    number: 70,
    unit: 'คน',
  },
  {
    name: 'Surat',
    number: 60,
    unit: 'คน',
  },
]

export const drawerTitle = {
  company: 'อันดับสนามสอบบริษัท',
  association: 'อันดับสนามสอบสมาคม',
}

export const drawerSubTitle = {
  examiner: 'สนามสอบผู้สมัครมากที่สุด',
  schedule: 'สนามสอบเปิดรอบมากที่สุด',
}

export const drawerUnit = {
  examiner: 'คน',
  schedule: 'รอบ',
}