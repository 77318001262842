import styled from '@mui/system/styled'
import Box from '@mui/material/Box'

export const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  width: 310,
  height: 150,
  backgroundColor: '#f4f4f4',
}))

export const LoadingImageProgress = styled(Box)(() => ({
  width: '72px',
  height: '5px',
  backgroundColor: '#CCCCCC',
}))

export const Loading = styled(Box)(({ theme }) => ({
  width: 0,
  height: 5,
  backgroundColor: theme?.palette?.primary?.main,
  transition: 'width 0.4s ease',
}))

export const BoxOutlinedContent = styled(Box)(({ theme, error }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: error
    ? theme?.palette?.error?.main
    : theme?.palette?.text?.silver,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

export const BoxContentImage = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const BoxButtonUpload = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
}))

export const BoxContentUpload = styled(Box)(({ theme, media }) => ({
  display: 'flex',
  flexDirection: media != '' ? 'column' : 'row',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const StyledImage = styled(Box)(({ theme }) => ({
  width: 310,
  height: 150,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
  [theme.breakpoints.down('sm')]: {
    width: 255,
  },
}))
