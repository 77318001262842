import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'
//import { getEEVAReportQuestionCount } from '../../services/reportComp'

export const reportEEvaRawDataByTrainerConst = {
  COLUMN_NAME: {
    NO: 'no',
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    COURSE_NAME: 'courseName',
    OVERALL_PART1: 'overallPercentagePart1',
    OVERALL_PART2: 'overallPercentagePart2',
    OVERALL_PART3: 'overallPercentagePart3',
    OVERALL_PART4: 'overallPercentagePart4',
    RESPONDENT_COUNT: 'respondentCount',
    ATTENDEE_COUNT: 'attendeeCount',
    TAUGHT_CLASS_COUNT: 'taughtClassCount',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    CLASS_LEVEL: 'classLevel',
    COURSE_CODE: 'courseCode',
    COURSE_START_DATE: 'courseStartDate',
    EVA_ID: 'evaId',
    EVA_NAME: 'evaName',
    EVA_TYPE: 'evaType',
    TRAINING_DATE: 'trainingDate',
    STATION: 'station',
    TRAINER_ID: 'trainerId',
    TRAINER_NAME: 'trainerName',
    COUNT_QUESTION: 'countQuestion',
    COUNT_OPEN: 'countOpen',
    COUNT_ENROLL: 'countEnroll',
    COUNT_ATTENDANCE: 'countAttendance',
    PASS: 'pass',
    FAIL: 'fail',
    ABSENT: 'absent',
    MEAN_SCORE: 'meanScore',
    MIN_SCORE: 'minScore',
    MAX_SCORE: 'maxScore',
    ROADMAP: 'roadMap',
    ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
    LEVEL_OF_LEARNER: 'levelOfLearner',
    OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
    REQUIRED_DOCUMENT: 'requiredDocument',
    ACQUIRED_SKILL: 'acquiredSkill',
    TRAINER_STAFF_ID: 'trainerStaffID',
    VERSION_NUMBER: 'courseVersion',
    REQUIRE_DOCUMENT: 'requireDocument',
    VERSION: 'courseVersion',
    STATUS_CLASS: 'statusClass',
    END_TRAINING_DATE: 'endTrainingDate',
    START_TRAINING_DATE: 'startTrainingDate',
    TRAININGPLATFORM: 'trainingPlatform',
    AGENT_TYPE: 'agentType',
    COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
    DISTRIBUTION: 'distribution',
    DEPARTMENT: 'department',
    ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt',
    RAM_CODE: 'ramCode',
    RAM_NAME: 'ramName',
    UNIT_CODE: 'unitCode',
    UNIT_NAME: 'unitName',
    EVALUATION_VERSION: 'evaluationVersion',
    USER_RESPONSE_DATE: 'userResponseDate',
    DEPARTMENT_ALL: 'departmentAll',
  },

  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    FIRSTNAME: 'ชื่อ',
    LASTNAME: 'นามสกุล',
    COURSE_NAME: 'ชื่อหลักสูตร',
    OVERALL_PART1: 'ภาพรวม ตอนที่ 1',
    OVERALL_PART2: 'ภาพรวม ตอนที่ 2',
    OVERALL_PART3: 'ภาพรวม ตอนที่ 3',
    OVERALL_PART4: 'ภาพรวม ตอนที่ 4',
    RESPONDENT_COUNT: 'จำนวนผู้ตอบแบบประเมิน',
    ATTENDEE_COUNT: 'จำนวนผู้เข้าอบรม',
    TAUGHT_CLASS_COUNT: 'จำนวนคลาสที่สอน',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    CLASS_LEVEL: 'ระดับ Class',
    COURSE_CODE: 'รหัสหลักสูตร',
    EVA_ID: 'รหัสแบบประเมิน',
    EVA_NAME: 'ชื่อแบบประเมิน',
    EVA_TYPE: 'ประเภทแบบประเมิน',
    TRAINING_DATE: 'Training Date',
    COURSE_START_DATE: 'วันเริ่มต้นหลักสูตร',
    STATION: 'Station',
    TRAINER_ID: 'Trainer (Staff ID)',
    TRAINER_NAME: 'Trainer Name',
    COUNT_QUESTION: 'จำนวนคำถาม',
    COUNT_OPEN: 'จำนวนเปิดรับสมัคร',
    COUNT_ENROLL: 'จำนวนรับสมัคร',
    COUNT_ATTENDANCE: 'จำนวนเข้าเรียน',
    PASS: 'ผ่าน',
    FAIL: 'ไม่ผ่าน',
    ABSENT: 'ไม่ทำ',
    MEAN_SCORE: 'ค่าเฉลี่ยคะแนน',
    MIN_SCORE: 'คะแนน Min',
    MAX_SCORE: 'คะแนน Max',
    ROADMAP: 'กลุ่มหลักสูตร',
    ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
    LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
    OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนของทางการจำหน่ายอื่น',
    REQUIRED_DOCUMENT: 'เอกสารการสมัคร',
    ACQUIRED_SKILL: 'ระดับหลักสูตร',
    TRAINER_STAFF_ID: 'Trainer Staff ID',
    VERSION_NUMBER: 'เวอร์ชันหลักสูตร',
    TRAININGPLATFORM: 'รูปแบบการสอน',
    AGENT_TYPE: 'Agent Type',
    COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
    DISTRIBUTION: 'ช่องทางการจัดจำหน่าย',
    ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจัดจำหน่าย',
    UPDATED_AT: 'วันที่แก้ไขล่าสุด',
    CREATED_AT: 'วันที่สร้าง',
    E_TESTING_TESTNAME: 'ชื่อแบบทดสอบ',
    RAM_CODE: 'Ram Code',
    RAM_NAME: 'Ram Name',
    UNIT_CODE: 'Unit Code',
    UNIT_NAME: 'Unit Name',
    DEPARTMENT: 'Department',
    EVALUATION_VERSION: 'เวอร์ชันแบบประเมิน',
    USER_RESPONSE_DATE: 'วันที่ทำแบบประเมิน',
  },

  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },

  evaTypeOptions: [
    {
      value: 'CLASS',
      label: 'Class',
    },
    {
      value: 'STANDALONE',
      label: 'Standalone',
    },
  ],
}

export const reportEEvaRawDataByTrainerDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.NO,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.NO,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.NO,
    },
    {
      id: 2,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.TRAINER_NAME,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.STATION,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.STATION,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.STATION,
      minWidth: 100,
    },
    {
      id: 4,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 210,
    },
    {
      id: 5,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.EVA_NAME,
      minWidth: 210,
    },
    {
      id: 6,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVALUATION_VERSION,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVALUATION_VERSION,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.EVALUATION_VERSION,
    },
    {
      id: 7,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RESPONDENT_COUNT,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RESPONDENT_COUNT,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.RESPONDENT_COUNT,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.ATTENDEE_COUNT,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.ATTENDEE_COUNT,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.ATTENDEE_COUNT,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TAUGHT_CLASS_COUNT,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TAUGHT_CLASS_COUNT,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.TAUGHT_CLASS_COUNT,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART1,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART1,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.OVERALL_PART1,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART2,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART2,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.OVERALL_PART2,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART3,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART3,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.OVERALL_PART3,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART4,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.OVERALL_PART4,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.OVERALL_PART4,
      minWidth: 160,
    },
  ],

  filterColumnOptions: [
    {
      id: 1,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.STATION,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.STATION,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByTrainerConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 2,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 210,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_CODE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_CODE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_ID,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_ID,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.EVA_ID,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.VERSION,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.VERSION,
      label: 'เวอร์ชันหลักสูตร',
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 8,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.START_TRAINING_DATE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.START_TRAINING_DATE,
      label: 'วันเริ่มต้น (Training Date)',
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 9,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.END_TRAINING_DATE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.END_TRAINING_DATE,
      label: 'วันสิ้นสุด (Training Date)',
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 26,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_START_DATE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_START_DATE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_START_DATE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 10,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      value:
        reportEEvaRawDataByTrainerConst.COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
      label:
        reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.COURSE_NAME_FOR_LEARNER,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_ID,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_ID,
      label: 'Trainer (Staff ID)',
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 12,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.TRAINER_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.TRAINER_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 14,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DISTRIBUTION,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DISTRIBUTION,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.DISTRIBUTION,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 15,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME
        .ABBREVIATION_DISTRIBUTION,
      value:
        reportEEvaRawDataByTrainerConst.COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
      label:
        reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH
          .ABBREVIATION_DISTRIBUTION,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DISTRIBUTION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 22,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.EVA_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 27,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_TYPE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.EVA_TYPE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.EVA_TYPE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportEEvaRawDataByTrainerConst.evaTypeOptions,
    },
    {
      id: 28,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RAM_CODE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RAM_CODE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.RAM_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RAM_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.RAM_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.RAM_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 30,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.UNIT_CODE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.UNIT_CODE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.UNIT_CODE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.UNIT_NAME,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.UNIT_NAME,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 32,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DEPARTMENT,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.DEPARTMENT,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.DEPARTMENT,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportEEvaRawDataByTrainerConst.COLUMN_NAME.DEPARTMENT_ALL,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 33,
      name: reportEEvaRawDataByTrainerConst.COLUMN_NAME.USER_RESPONSE_DATE,
      value: reportEEvaRawDataByTrainerConst.COLUMN_NAME.USER_RESPONSE_DATE,
      label: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH.USER_RESPONSE_DATE,
      minWidth: 160,
      type: reportEEvaRawDataByTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
