import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'
import _ from 'lodash'

function itemExistsInRedux(item, eContentTag) {
  return !eContentTag.some((masterItem) => item.name === masterItem.label)
}

export const fetchTag = async (inputValue, setIsTyping) => {
  const { crudConfig } = store.getState().crud
  const eContentTag = store.getState().crud.formData.eContentTag

  if (!_.isEmpty(inputValue)) {
    const body = {
      limit: 100,
      page: 1,
      order: 'DESC',
      sort: 'updatedAt',
      name: inputValue,
      createdBy: '',
      updatedBy: '',
      status: [],
    }
    try {
      const response = await callAxios.post(crudConfig.filterPath, body, {
        'x-type': 'E_CONTENT_TAG',
      })

      const reduced = response.data.result.filter((item) =>
        itemExistsInRedux(item, eContentTag),
      )

      store.dispatch(
        setFieldValue({
          key: 'masterData.eContentTag',
          value: {
            isLoading: false,
            options: reduced.map((item) => ({
              value: item.uuid,
              label: item.name,
            })),
          },
        }),
      )
      setIsTyping(false)
    } catch (e) {
      handleFetchError(e)
    }
  }
}
