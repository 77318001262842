import _ from 'lodash'
import { handleLabelText } from '../../../../../../../modules/CourseSetting/ViewConditions/events'
import { PREREQUISITE_TH } from './model'

export const handleDisplayPrerequisite = (data) => {
  return data.map((dataItem, dataIndex) => {
    const result = []
    const groupData = _.groupBy(dataItem, 'type')
    let mainIndex = 0
    let newIndex = 0

    for (let type in groupData) {
      const value = groupData[type]
      let label = ''

      value.forEach((item, index) => {
        const orCondition = `<div class="MuiDivider-root MuiDivider-fullWidth MuiDivider-withChildren MuiDivider-textAlignLeft css-1rn96g4-MuiDivider-root" role="separator"><span class="MuiDivider-wrapper css-qywfm8-MuiDivider-wrapper"><p class="MuiTypography-root MuiTypography-body1 css-1xzqdwu-MuiTypography-root">หรือ</p></span></div>`
        const labelText = handleLabelText(item)

        label = label + changeText(type) + labelText

        if (index < value.length - 1) label = label + orCondition
      })

      if (mainIndex == 0) {
        if (newIndex >= dataIndex) {
          newIndex = dataIndex
          result.push({
            condition: dataIndex == 0 ? '' : 'และ',
            label,
          })
        } else result.push({ condition: 'และ', label })
      } else result.push({ condition: 'หรือ', label })

      mainIndex++
    }

    return result
  })
}

export const changeText = (type) => {
  return `<span class="label-type">${PREREQUISITE_TH[type]}</span>`
}
