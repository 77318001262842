import { setChange } from '../../../../../redux/slices/eEvaluationForm'

import { mapKeyByType } from '../event'

export const handelDelete = (props) => (dispatch) => {
  const key = mapKeyByType(props)

  dispatch(
    setChange({
      key: `${key}.loadingProgress`,
      value: null,
    })
  )
  dispatch(
    setChange({
      key: `${key}.uploadError`,
      value: null,
    })
  )

  dispatch(
    setChange({
      key: `${key}.${props.keyValue}`,
      value: '',
    })
  )
}
