import { store } from '../../../App'
import { getCroppedImg } from './getCropImage'
import _ from 'lodash'
import { handleSubmitUpload } from './handleSubmitUpload'

export const handleCropLocal = async ({ ref, folder, file, onChange }) => {
  const croppedData = ref.current?.getCropData()
  const areaPixels = _.get(croppedData, 'croppedAreaPixels', {})
  const croppedImage = await getCroppedImg(file, areaPixels, 0)
  store.dispatch(handleSubmitUpload(folder, croppedImage, onChange))
}
