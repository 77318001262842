import _ from 'lodash'
import {
  getManageClassLearnerExam,
  getStandaloneUser,
} from '../../../../../services/manageClass/check'
import {
  setLearnerExam,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassCheckExam'

export const handleSelectLearner = (selectProps) => async (dispatch) => {
  const { props, learner, index } = selectProps
  dispatch(startLoading())
  dispatch(setLearnerExam({ learner, page: index + 1 }))
  const isStandalone = _.get(props, 'isStandalone', false)
  if (isStandalone) {
    const { eTestingVersionUuid, learnerUuid } = props
    const payload = { eTestingVersionUuid, userUuid: learnerUuid }
    await dispatch(getStandaloneUser(payload))
  } else {
    const { id, courseId, learnerUuid } = props
    const payload = {
      trainingPlanUuid: id,
      courseTestAndEvaUuid: courseId,
      learnerUuid,
    }
    await dispatch(getManageClassLearnerExam(payload))
  }
  dispatch(stopLoading())
}
