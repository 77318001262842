import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledTemplate = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'noMinHeight' && prop !== 'minWidth',
})(({ theme, noMinHeight, maxWidth }) => ({
  position: 'relative',
  width: '100%',
  maxWidth,
  height: '100%',
  margin: 'auto',
  minHeight: noMinHeight ? 'auto' : 'calc(100vh - 385px)',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    minHeight: noMinHeight ? 'auto' : 'calc(100vh - 350px)',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: noMinHeight ? 'auto' : 'calc(100vh - 434px)',
  },
}))
