import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  filterStaffProfile,
  permission,
  permissionGroup,
} from '../../utils/apiPath'

export const getPermissionById = createAsyncThunk(
  'permission/getPermissionById',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${permission}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getPermission = createAsyncThunk(
  'permission/getPermission',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(permission)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getStaffList = createAsyncThunk(
  'permission/getStaffList',
  async (uuid, { rejectWithValue }) => {
    try {
      const body = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        agentCode: '',
        firstNameTH: '',
        lastNameTH: '',
        jobTitle: '',
        department: '',
        station: '',
        reportTo: '',
        position: '',
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      const response = await axios.post(filterStaffProfile, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putPostPermissionGroup = createAsyncThunk(
  'permission/putPostPermissionGroup',
  async (payload, { rejectWithValue }) => {
    try {
      const { method, body } = payload
      const response = await axios({ method, url: permissionGroup, data: body })
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
