import React, { useState, useRef } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Button from '@mui/material/Button'

import ArrowDropDown from '@mui/icons-material/ArrowDropDown'

import { handleToggle } from './event'
import { ListMenuItem, ButtonLabelText, BoxButton } from './Styled'

const Index = (props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const {
    size,
    listOption,
    labelText,
    iconLabel,
    handleChange,
    handleChangeIcon,
    placement,
    disabled,
  } = props

  return (
    <Box>
      <BoxButton disabled={disabled}>
        {iconLabel && (
          <Button
            variant="bgBasic"
            size={size}
            onClick={!disabled && handleChangeIcon}
            disabled={disabled}
          >
            {iconLabel}
          </Button>
        )}
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          sx={{ boxShadow: 'none' }}
          disabled={disabled}
        >
          <ButtonLabelText
            data-testid="btn-label-text"
            onClick={() => !disabled && handleToggle(setOpen)}
            size={size}
          >
            {labelText}
            <ArrowDropDown />
          </ButtonLabelText>
        </ButtonGroup>
      </BoxButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement={placement}
        variant="addQuestion"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ width: 220 }}>
              <ClickAwayListener
                onClickAway={() => !disabled && handleToggle(setOpen)}
              >
                <MenuList autoFocusItem sx={{ width: 'auto' }}>
                  {listOption.map((item, index) => [
                    // <Divider key={`divider-${index}`} />,
                    <ListMenuItem
                      key={index}
                      onClick={() => {
                        !disabled && handleChange(item.value)
                        handleToggle(setOpen)
                      }}
                    >
                      {item.icon}
                      <Typography variant="body1">{item.labelText}</Typography>
                    </ListMenuItem>,
                  ])}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
export default Index
