import React, { useEffect } from 'react'
import _ from 'lodash'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector, useDispatch } from 'react-redux'

import Table from '../../../../../components/redux/Table/LearnerDetail'
import { setDataTable } from './events'

const Index = () => {
  const dispatch = useDispatch()
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState
  )
  const eTestAndEva = _.get(initialState, 'eTestAndEva', [])
  useEffect(() => {
    dispatch(setDataTable(eTestAndEva))
  }, [eTestAndEva])

  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ m: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          แบบทดสอบ / แบบประเมิน
        </Typography>

        <Table type="eTestAndEva" />
      </Box>
    </Card>
  )
}

export default Index
