import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(20),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: 275,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  boxShadow: `0px 12px 40px ${theme.palette?.primary?.outlinedHoverBackground}`,
}))

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: 275,
  display: 'flex',
  padding: `0 ${theme.spacing(3)}`,
}))

export const StyledRowFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& span#sub': { color: theme?.palette?.text?.secondary },
}))

export const StyledRespRow = styled(StyledRowFlex)(({ theme, isInput }) => ({
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: isInput ? 0 : theme.spacing(3),
  },
}))

export const StyledColumnFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

export const StyledColumnNoGap = styled(Box)(({ theme, noGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: { gap: noGap ? 0 : theme.spacing(3) },
}))
