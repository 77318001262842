import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportRoomManagementConst = {
  COLUMN_NAME: {
    NO: 'no',
    TICKET_NO: 'ticketNo',
    REQUEST_TYPE: 'requestType',
    ROOM_FORMAT: 'roomFormat',
    MEETING_ROOM_OTHER: 'meetingRoomOther',
    ROOM_ID: 'roomId',
    ROOM_NAME: 'roomName',
    STATION_NAME: 'stationName',
    FLOOR: 'floor',
    SQM: 'sqm',
    WIDTH: 'width',
    LENGTH: 'length',
    HEIGHT: 'height',
    DIMENSION: 'dimension',
    CAPACITY_CLASSROOM: 'capacityClassroom',
    CAPACITY_GROUP: 'capacityGroup',
    CAPACITY_U_SHAPE: 'capacityUShape',
    CAPACITY_THEATER: 'capacityTheater',
    ROOM_LAYOUT: 'roomLayout',
    ROOM_FUNCTION: 'roomFunction',
    ROOM_TYPE: 'roomType',
    ROOM_STATUS: 'roomStatus',
    TRAINING_START_DATE: 'trainingStartDate',
    TRAINING_END_DATE: 'trainingEndDate',
    TRAINING_DATE: 'trainingDate',
    BOOKING_DATE: 'bookingDate',
    DAY: 'day',
    TIME_USE: 'timeUse',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    PLATFORM: 'platform',
    ADMIN: 'admin',
    SUB_ADMIN: 'subAdmin',
    EXPECT_NUMBER_PEOPLE: 'expectNumberPeople',
    ATTENDEE: 'attendee',
    BOOK_FOR: 'bookFor',
    EMAIL_BOOK_FOR: 'emailBookFor',
    MOBILE_NO_BOOK_FOR: 'mobileNoBookFor',
    STATUS: 'status',
    CREATE_DATE_TIME: 'createDateTime',
    CREATE_BY_ID: 'createById',
    UPDATE_DATE_TIME: 'updateDateTime',
    UPDATE_BY_ID: 'updateById',
    CANCEL_DATE_TIME: 'cancelDateTime',
    CANCEL_BY_ID: 'cancelById',
    CANCEL_REASON: 'cancelReason',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    TICKET_NO: 'Ticket No',
    REQUEST_TYPE: 'ประเภทการจองห้อง',
    ROOM_FORMAT: 'รูปแบบห้อง',
    MEETING_ROOM_OTHER: 'สถานที่ (Other)',
    ROOM_ID: 'Room ID',
    ROOM_NAME: 'Room Name',
    STATION_NAME: 'Station',
    FLOOR: 'Floor',
    SQM: 'ขนาดห้อง',
    WIDTH: 'กว้าง',
    LENGTH: 'ยาว',
    HEIGHT: 'สูง',
    DIMENSION: 'กว้าง x ยาว x สูง',
    CAPACITY_CLASSROOM: 'ความจุ (คน) Classroom',
    CAPACITY_GROUP: 'ความจุ (คน) Group',
    CAPACITY_U_SHAPE: 'ความจุ (คน) U Shape',
    CAPACITY_THEATER: 'ความจุ (คน) Theater',
    ROOM_LAYOUT: 'รูปแบบการจัดห้อง',
    ROOM_FUNCTION: 'ฟังก์ชันห้อง',
    ROOM_TYPE: 'ประเภทห้อง',
    ROOM_STATUS: 'สถานะห้อง',
    TRAINING_START_DATE: 'Training Start Date',
    TRAINING_END_DATE: 'Training End Date',
    TRAINING_DATE: 'Training Date',
    BOOKING_DATE: 'Date Use',
    DAY: 'Day',
    TIME_USE: 'Time Use',
    COURSE_CODE: 'Course Code',
    COURSE_NAME_EN: 'Course Name EN',
    PLATFORM: 'รูปแบบการสอน',
    ADMIN: 'แอดมินหลัก',
    SUB_ADMIN: 'แอดมินรอง',
    EXPECT_NUMBER_PEOPLE: 'จำนวนที่คาดหวัง',
    ATTENDEE: 'จำนวนเข้าร่วม',
    BOOK_FOR: 'จองให้',
    EMAIL_BOOK_FOR: 'Email คนใช้ห้อง',
    MOBILE_NO_BOOK_FOR: 'เบอร์โทร คนใช้ห้อง',
    STATUS: 'สถานะการจอง',
    CREATE_DATE_TIME: 'วันที่สร้าง',
    CREATE_BY_ID: 'สร้างโดย',
    UPDATE_DATE_TIME: 'วันที่แก้ไขล่าสุด',
    UPDATE_BY_ID: 'แก้ไขล่าสุดโดย',
    CANCEL_DATE_TIME: 'วันที่ยกเลิก',
    CANCEL_BY_ID: 'ยกเลิกโดย',
    CANCEL_REASON: 'สาเหตุการยกเลิก',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_TIME: 'INPUT_TIME',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_NUMBER_RANGE: 'INPUT_NUMBER_RANGE',
    INPUT_TEXT_BOX: 'INPUT_TEXT_BOX',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  requestTypeOptions: [
    { label: 'E-Booking', value: 'E-Booking' },
    { label: 'Monthly Plan', value: 'Monthly Plan' },
    { label: 'Manage Class', value: 'Manage Class' },
  ],
  roomLayoutOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Group', value: 'Group' },
    { label: 'U Shape', value: 'U Shape' },
    { label: 'Theater', value: 'Theater' },
  ],
  roomFunctionOptions: [
    { label: 'Single Room', value: 'Single Room' },
    { label: 'Connecting Room', value: 'Connecting Room' },
    { label: 'Other', value: 'Other' },
  ],
  roomTypeOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Computer Room', value: 'Computer Room' },
    { label: 'Meeting Room', value: 'Meeting Room' },
    { label: 'Other', value: 'Other' },
  ],
  roomFormatOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Other', value: 'Other' },
  ],
  statusOptions: [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Cancel', value: 'Cancel' },
  ],
  roomStatusOptions: [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
    { label: 'DELETED', value: 'DELETED' },
  ],
  dayOptions: [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
  ],
}

export const reportRoomManagementDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportRoomManagementConst.COLUMN_NAME.NO,
      value: reportRoomManagementConst.COLUMN_NAME.NO,
      label: reportRoomManagementConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportRoomManagementConst.COLUMN_NAME.TICKET_NO,
      value: reportRoomManagementConst.COLUMN_NAME.TICKET_NO,
      label: reportRoomManagementConst.COLUMN_NAME_TH.TICKET_NO,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportRoomManagementConst.COLUMN_NAME.REQUEST_TYPE,
      value: reportRoomManagementConst.COLUMN_NAME.REQUEST_TYPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.REQUEST_TYPE,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_FORMAT,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_FORMAT,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_FORMAT,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportRoomManagementConst.COLUMN_NAME.MEETING_ROOM_OTHER,
      value: reportRoomManagementConst.COLUMN_NAME.MEETING_ROOM_OTHER,
      label: reportRoomManagementConst.COLUMN_NAME_TH.MEETING_ROOM_OTHER,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_ID,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_ID,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_NAME,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_NAME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_NAME,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportRoomManagementConst.COLUMN_NAME.STATION_NAME,
      value: reportRoomManagementConst.COLUMN_NAME.STATION_NAME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.STATION_NAME,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportRoomManagementConst.COLUMN_NAME.FLOOR,
      value: reportRoomManagementConst.COLUMN_NAME.FLOOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.FLOOR,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportRoomManagementConst.COLUMN_NAME.SQM,
      value: reportRoomManagementConst.COLUMN_NAME.SQM,
      label: reportRoomManagementConst.COLUMN_NAME_TH.SQM,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportRoomManagementConst.COLUMN_NAME.DIMENSION,
      value: reportRoomManagementConst.COLUMN_NAME.DIMENSION,
      label: reportRoomManagementConst.COLUMN_NAME_TH.DIMENSION,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_CLASSROOM,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_CLASSROOM,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_CLASSROOM,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_GROUP,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_GROUP,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_GROUP,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_U_SHAPE,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_U_SHAPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_U_SHAPE,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_THEATER,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_THEATER,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_THEATER,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_LAYOUT,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_LAYOUT,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_LAYOUT,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_FUNCTION,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_FUNCTION,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_FUNCTION,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_TYPE,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_TYPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_TYPE,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_STATUS,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_STATUS,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_STATUS,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportRoomManagementConst.COLUMN_NAME.TRAINING_DATE,
      value: reportRoomManagementConst.COLUMN_NAME.TRAINING_DATE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.TRAINING_DATE,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportRoomManagementConst.COLUMN_NAME.BOOKING_DATE,
      value: reportRoomManagementConst.COLUMN_NAME.BOOKING_DATE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.BOOKING_DATE,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportRoomManagementConst.COLUMN_NAME.DAY,
      value: reportRoomManagementConst.COLUMN_NAME.DAY,
      label: reportRoomManagementConst.COLUMN_NAME_TH.DAY,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportRoomManagementConst.COLUMN_NAME.TIME_USE,
      value: reportRoomManagementConst.COLUMN_NAME.TIME_USE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.TIME_USE,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportRoomManagementConst.COLUMN_NAME.COURSE_CODE,
      value: reportRoomManagementConst.COLUMN_NAME.COURSE_CODE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportRoomManagementConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportRoomManagementConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportRoomManagementConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportRoomManagementConst.COLUMN_NAME.PLATFORM,
      value: reportRoomManagementConst.COLUMN_NAME.PLATFORM,
      label: reportRoomManagementConst.COLUMN_NAME_TH.PLATFORM,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportRoomManagementConst.COLUMN_NAME.ADMIN,
      value: reportRoomManagementConst.COLUMN_NAME.ADMIN,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ADMIN,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportRoomManagementConst.COLUMN_NAME.SUB_ADMIN,
      value: reportRoomManagementConst.COLUMN_NAME.SUB_ADMIN,
      label: reportRoomManagementConst.COLUMN_NAME_TH.SUB_ADMIN,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportRoomManagementConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportRoomManagementConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportRoomManagementConst.COLUMN_NAME.ATTENDEE,
      value: reportRoomManagementConst.COLUMN_NAME.ATTENDEE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ATTENDEE,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportRoomManagementConst.COLUMN_NAME.BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.BOOK_FOR,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportRoomManagementConst.COLUMN_NAME.EMAIL_BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.EMAIL_BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.EMAIL_BOOK_FOR,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportRoomManagementConst.COLUMN_NAME.MOBILE_NO_BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.MOBILE_NO_BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.MOBILE_NO_BOOK_FOR,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportRoomManagementConst.COLUMN_NAME.STATUS,
      value: reportRoomManagementConst.COLUMN_NAME.STATUS,
      label: reportRoomManagementConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportRoomManagementConst.COLUMN_NAME.CREATE_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.CREATE_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CREATE_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportRoomManagementConst.COLUMN_NAME.CREATE_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.CREATE_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CREATE_BY_ID,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportRoomManagementConst.COLUMN_NAME.UPDATE_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.UPDATE_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.UPDATE_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 38,
      name: reportRoomManagementConst.COLUMN_NAME.UPDATE_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.UPDATE_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.UPDATE_BY_ID,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportRoomManagementConst.COLUMN_NAME.CANCEL_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.CANCEL_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CANCEL_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportRoomManagementConst.COLUMN_NAME.CANCEL_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.CANCEL_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CANCEL_BY_ID,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportRoomManagementConst.COLUMN_NAME.CANCEL_REASON,
      value: reportRoomManagementConst.COLUMN_NAME.CANCEL_REASON,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CANCEL_REASON,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_ID,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_ID,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_NAME,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_NAME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_NAME,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportRoomManagementConst.COLUMN_NAME.STATION_NAME,
      value: reportRoomManagementConst.COLUMN_NAME.STATION_NAME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.STATION_NAME,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportRoomManagementConst.COLUMN_NAME.STATION_NAME,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 4,
      name: reportRoomManagementConst.COLUMN_NAME.FLOOR,
      value: reportRoomManagementConst.COLUMN_NAME.FLOOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.FLOOR,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportRoomManagementConst.COLUMN_NAME.SQM,
      value: reportRoomManagementConst.COLUMN_NAME.SQM,
      label: reportRoomManagementConst.COLUMN_NAME_TH.SQM,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_NUMBER_RANGE,
    },
    {
      id: 9,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_CLASSROOM,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_CLASSROOM,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_CLASSROOM,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 10,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_GROUP,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_GROUP,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_GROUP,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 11,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_U_SHAPE,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_U_SHAPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_U_SHAPE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 12,
      name: reportRoomManagementConst.COLUMN_NAME.CAPACITY_THEATER,
      value: reportRoomManagementConst.COLUMN_NAME.CAPACITY_THEATER,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CAPACITY_THEATER,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 13,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_LAYOUT,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_LAYOUT,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_LAYOUT,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportRoomManagementConst.roomLayoutOptions,
    },
    {
      id: 14,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_FUNCTION,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_FUNCTION,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_FUNCTION,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.roomFunctionOptions,
    },
    {
      id: 15,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_TYPE,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_TYPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_TYPE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportRoomManagementConst.roomTypeOptions,
    },
    {
      id: 16,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_STATUS,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_STATUS,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_STATUS,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.roomStatusOptions,
    },
    {
      id: 17,
      name: reportRoomManagementConst.COLUMN_NAME.BOOKING_DATE,
      value: reportRoomManagementConst.COLUMN_NAME.BOOKING_DATE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.BOOKING_DATE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 18,
      name: reportRoomManagementConst.COLUMN_NAME.DAY,
      value: reportRoomManagementConst.COLUMN_NAME.DAY,
      label: reportRoomManagementConst.COLUMN_NAME_TH.DAY,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.dayOptions,
    },
    {
      id: 19,
      name: reportRoomManagementConst.COLUMN_NAME.TIME_USE,
      value: reportRoomManagementConst.COLUMN_NAME.TIME_USE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.TIME_USE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TIME,
    },
    {
      id: 20,
      name: reportRoomManagementConst.COLUMN_NAME.COURSE_CODE,
      value: reportRoomManagementConst.COLUMN_NAME.COURSE_CODE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportRoomManagementConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportRoomManagementConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportRoomManagementConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 22,
      name: reportRoomManagementConst.COLUMN_NAME.ATTENDEE,
      value: reportRoomManagementConst.COLUMN_NAME.ATTENDEE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ATTENDEE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportRoomManagementConst.COLUMN_NAME.BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.BOOK_FOR,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 24,
      name: reportRoomManagementConst.COLUMN_NAME.EMAIL_BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.EMAIL_BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.EMAIL_BOOK_FOR,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 25,
      name: reportRoomManagementConst.COLUMN_NAME.MOBILE_NO_BOOK_FOR,
      value: reportRoomManagementConst.COLUMN_NAME.MOBILE_NO_BOOK_FOR,
      label: reportRoomManagementConst.COLUMN_NAME_TH.MOBILE_NO_BOOK_FOR,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 26,
      name: reportRoomManagementConst.COLUMN_NAME.STATUS,
      value: reportRoomManagementConst.COLUMN_NAME.STATUS,
      label: reportRoomManagementConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.statusOptions,
    },
    {
      id: 27,
      name: reportRoomManagementConst.COLUMN_NAME.CREATE_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.CREATE_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CREATE_DATE_TIME,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 28,
      name: reportRoomManagementConst.COLUMN_NAME.CREATE_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.CREATE_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CREATE_BY_ID,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 29,
      name: reportRoomManagementConst.COLUMN_NAME.UPDATE_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.UPDATE_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.UPDATE_DATE_TIME,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 30,
      name: reportRoomManagementConst.COLUMN_NAME.UPDATE_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.UPDATE_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.UPDATE_BY_ID,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 31,
      name: reportRoomManagementConst.COLUMN_NAME.CANCEL_DATE_TIME,
      value: reportRoomManagementConst.COLUMN_NAME.CANCEL_DATE_TIME,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CANCEL_DATE_TIME,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 32,
      name: reportRoomManagementConst.COLUMN_NAME.CANCEL_BY_ID,
      value: reportRoomManagementConst.COLUMN_NAME.CANCEL_BY_ID,
      label: reportRoomManagementConst.COLUMN_NAME_TH.CANCEL_BY_ID,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 33,
      name: reportRoomManagementConst.COLUMN_NAME.REQUEST_TYPE,
      value: reportRoomManagementConst.COLUMN_NAME.REQUEST_TYPE,
      label: reportRoomManagementConst.COLUMN_NAME_TH.REQUEST_TYPE,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.requestTypeOptions,
    },
    {
      id: 34,
      name: reportRoomManagementConst.COLUMN_NAME.ROOM_FORMAT,
      value: reportRoomManagementConst.COLUMN_NAME.ROOM_FORMAT,
      label: reportRoomManagementConst.COLUMN_NAME_TH.ROOM_FORMAT,
      minWidth: 160,
      type: reportRoomManagementConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportRoomManagementConst.roomFormatOptions,
    },
  ],
}
