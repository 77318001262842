import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledDialog, StyledInputImport, StyledFormContainer } from '../Styled'
import Button from '@mui/material/Button'
import { onDownloadTemplate, onUploadTemplate, handleSubmitAdd } from './events'
import _ from 'lodash'
import { closeDialog } from '../../../redux/slices/dialog'
import {
  StyledDialogActions,
  StyledCancelButton,
  StyledLoadingButton,
} from '../../../components/redux/PopUpDialog/Styled'
import { E_EXAM_TYPE } from '../../../constants/eExamination'

const ImportTemplateDialog = () => {
  const dispatch = useDispatch()
  const { importFile, isLoading, disableButton, eExamType } = useSelector(
    (state) => ({
      importFile: state.eExamList.importFile,
      isLoading: state.dialog.isLoading,
      disableButton: state.dialog.disableButton,
      eExamType: state.eExamList.eExamType,
    }),
    shallowEqual,
  )
  const importText =
    eExamType === E_EXAM_TYPE.ASSOCIATION
      ? 'นำเข้า TEMPLATE ตารางสอบสมาคม'
      : eExamType === E_EXAM_TYPE.COMPANY
      ? 'นำเข้า TEMPLATE ตารางสอบบริษัท'
      : 'นำเข้า TEMPLATE ตารางสอบอื่นๆ'

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">{importText}</Typography>
      </Box>
      <StyledDialog sx={{ p: 0 }}>
        <StyledFormContainer>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              data-testid="btn-download-template"
              variant="contained"
              size="s"
              onClick={() => {
                dispatch(onDownloadTemplate(eExamType))
              }}
            >
              ดาวน์โหลด Template
            </Button>
            <Typography variant="body2" color="text.secondary">
              เพิ่มขนาดไฟล์ที่แนะนำไม่เกิน 2 MB
            </Typography>
          </Box>
        </StyledFormContainer>
        <Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            สนามสอบ
          </Typography>
          <label
            style={{ display: 'flex', gap: 16, alignItems: 'center' }}
            htmlFor="import-template"
          >
            <Button variant="contained" size="s" component="span">
              เลือกไฟล์
            </Button>
            <StyledInputImport
              data-testid="import-template"
              id="import-template"
              accept=".xlsx, .xls, .csv"
              type="file"
              onChange={(e) => dispatch(onUploadTemplate(e))}
            />
            <Typography variant="body2" color="text.secondary">
              {_.get(importFile, 'fileName')}
            </Typography>
          </label>
        </Box>
        <StyledDialogActions sx={{ p: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => dispatch(closeDialog())}
              color="primary"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(handleSubmitAdd(_.get(importFile, 'file'), eExamType))
            }
            color="primary"
            disabled={disableButton}
            endIcon={<></>}
          >
            บันทึก
          </StyledLoadingButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}
export default ImportTemplateDialog
