import { createTheme } from '@mui/material/styles'

import htcBd from './fonts/db-helvethaica-x-it-v3.2-webfont.woff2'
import htcBd2 from './fonts/db-helvethaica-x-bd-v3.2-webfont.woff'
import htcIta from './fonts/db-helvethaica-x-it-v3.2-webfont.woff'
import htcIta2 from './fonts/db-helvethaica-x-it-v3.2-webfont.woff2'
import htcMd from './fonts/db-helvethaica-x-med-v3.2-webfont.woff'
import htcMd2 from './fonts/db-helvethaica-x-med-v3.2-webfont.woff2'
import htcRgl from './fonts/db-helvethaica-x-v3.2-webfont.woff'
import htcRgl2 from './fonts/db-helvethaica-x-v3.2-webfont.woff2'

import Baijam from './fonts/Baijam/Baijam.ttf'
import BaijamBold from './fonts/Baijam/BaijamBold.ttf'
import THSarabunNew from './fonts/Sarabun/THSarabunNew.ttf'
import THSarabunNewBold from './fonts/Sarabun/THSarabunNewBold.ttf'
import { themeColor, fontStyle } from './themeData'
export const theme = createTheme({
  spacing: 8,
  palette: themeColor,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { weight: '400' },
          style: { fontWeight: 500 },
        },
        {
          props: { weight: '500' },
          style: { fontWeight: 500 },
        },
        {
          props: { weight: '700' },
          style: { fontWeight: 700 },
        },
        {
          props: { size: '16' },
          style: { fontSize: 16 },
        },
        {
          props: { size: '24' },
          style: { fontSize: 24 },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        select: { padding: '6px', borderColor: themeColor?.text?.silver },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          '&.Mui-disabled': {
            backgroundColor: themeColor.text.wildSand,
            webkitTextFillColor: themeColor.text.silver,
          },
          '&:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: themeColor.text.silver,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
        },
        input: {
          borderColor: themeColor?.text?.silver,
          height: 24,
          padding: '8px',
          '&.Mui-disabled': {
            backgroundColor: themeColor.text.wildSand,
            webkitTextFillColor: themeColor.text.silver,
          },
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: {
            type: 'basic',
          },
          style: {
            outline: 'none',
            borderRadius: '4px',
            border: `1px solid ${themeColor.text.silver}`,
            paddingLeft: '8px',
            '&.Mui-disabled': {
              backgroundColor: themeColor.text.wildSand,
            },
            '&.Mui-focused, &:focus': {
              borderColor: themeColor.primary.main,
            },
          },
        },
        {
          props: {
            inputAdd: 'adorned',
          },
          style: {
            border: `1px solid ${themeColor.text.silver}`,
            paddingLeft: '8px',
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: { padding: '6px' },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: 's' },
          style: { height: 30, fontSize: 17 },
        },
        {
          props: { size: 'm' },
          style: { height: 40, fontSize: 18 },
        },
        {
          props: { size: 'l' },
          style: { height: 50, fontSize: 19 },
        },
        {
          props: { variant: 'outlined' },
          style: { border: `1px solid ` },
        },
        {
          props: { variant: 'canceled' },
          style: {
            backgroundColor: `${themeColor.secondary.main}`,
            borderRadius: '4px',
            color: `${themeColor.secondary.contrast}`,
            display: 'flex',
            gap: '10.5px',
            boxShadow: '0px 4px 12px 1px rgba(240, 118, 98, 0.35)',
            '&:hover': {
              backgroundColor: `${themeColor.secondary.main}`,
            },
          },
        },
        {
          props: { variant: 'upload' },
          style: {
            border: `1px dashed ${themeColor.other.outlineBorder}`,
            borderRadius: '4px',
            minHeight: '150px',
          },
        },
        {
          props: { variant: 'bgBasic' },
          style: { color: themeColor.background.basic },
        },
        {
          props: { variant: 'preview', color: 'primary' },
          style: {
            borderRadius: 0,
            background: themeColor.text.gray,
            color: themeColor.text.white,
            height: 30,
            fontSize: 17,
            ':hover': { background: themeColor.text.secondary },
          },
        },
        {
          props: { variant: 'preview', color: 'secondary' },
          style: {
            borderRadius: 0,
            border: `1px solid ${themeColor.text.gray}`,
            color: themeColor.text.gray,
            height: 30,
            fontSize: 17,
            ':hover': { borderColor: themeColor.text.secondary },
          },
        },
      ],
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: 18,
          weight: 400,
          style: 'normal',
        },
        selectIcon: {
          top: 3,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        paddingCheckbox: {
          padding: '0 0 0 0',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: themeColor.primary.outlinedHoverBackground,
          },
        },
        edgeEnd: {
          color: themeColor.primary.main,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { padding: '0px' },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        style: {
          borderRadius: '4px',
          border: `1px solid ${themeColor.text.silver}`,
          paddingLeft: '8px',
          '&.Mui-disabled': {
            backgroundColor: `${themeColor.text.wildSand}`,
          },
        },
      },
      variants: [
        {
          props: { type: 'hover' },
          style: {
            '&:hover': {
              backgroundColor: themeColor.action.hover,
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Helvethaica';
          src:local('Helvethaica'), local('Helvethaica-Regular'), url(${htcRgl2}) format('woff2'),
              url(${htcRgl}) format('woff');
          font-weight: 400;
          font-style: normal;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Bold'), url(${htcBd2}) format('woff2'),
              url(${htcBd}) format('woff');
          font-weight: 700;
          font-style: bold;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Medium'), url(${htcMd2}) format('woff2'),
              url(${htcMd}) format('woff');
          font-weight: 500;
          font-style: normal;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Helvethaica';
          src: local('Helvethaica'), local('Helvethaica-Italic'), url(${htcIta2}) format('woff2'),
              url(${htcIta}) format('woff');
          font-weight: 400;
          font-style: italic;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      
        @font-face {
          font-family: 'Baijam';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${Baijam}) format('woff2');
        }
        @font-face {
          font-family: 'Baijam';
          font-style: bold;
          font-display: swap;
          font-weight: 700;
          src: url(${BaijamBold}) format('woff2');
        }
         @font-face {
          font-family: 'Sarabun';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${THSarabunNew}) format('woff2');
        }
         @font-face {
          font-family: 'Sarabun';
          font-style: bold;
          font-display: swap;
          font-weight: 700;
          src: url(${THSarabunNewBold}) format('woff2');
        }
      `,
    },
    MuiCheckbox: {
      variants: [
        {
          props: { typeColor: 'training' },
          style: {
            color: themeColor.monthlyPlan.training,
            '&.Mui-checked': {
              color: themeColor.monthlyPlan.training,
            },
          },
        },
        {
          props: { typeColor: 'academyActivity' },
          style: {
            color: themeColor.monthlyPlan.academyActivity,
            '&.Mui-checked': {
              color: themeColor.monthlyPlan.academyActivity,
            },
          },
        },
        {
          props: { typeColor: 'holidayBorder' },
          style: {
            color: themeColor.monthlyPlan.holidayBorder,
            '&.Mui-checked': {
              color: themeColor.monthlyPlan.holidayBorder,
            },
          },
        },
        {
          props: { typeColor: 'oceanBlue' },
          style: {
            color: themeColor.blue.oceanBlue,
            '&.Mui-checked': {
              color: themeColor.blue.oceanBlue,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '18px',
        },
        tooltipArrow: {
          background: themeColor.primary.main,
        },
        arrow: {
          color: themeColor.primary.main,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          color: themeColor.error.main,
          ...fontStyle.body2,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid #DBE4F1`,
          borderRadius: '8px',
          boxShadow: `0px 12px 40px ${themeColor.blue.blueLine}`,
        },
      },
      variants: [
        {
          props: { variant: 'question' },
          style: {
            borderWidth: 0,
            boxShadow: '0px 4px 40px 1px rgba(73, 118, 186, 0.1)',
          },
        },
      ],
    },
    MuiDialog: {
      variants: [
        {
          props: { maxWidth: 'form' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: 576,
            },
          },
        },
        {
          props: { maxWidth: 'normal' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: 500,
            },
          },
        },
        {
          props: { maxWidth: 'mini' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: 250,
            },
          },
        },
        {
          props: { maxWidth: '860' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: 860,
            },
          },
        },
        {
          props: { maxWidth: 'table' },
          style: {
            '& .MuiPaper-root': {
              maxWidth: 650,
            },
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: 'alert', severity: 'warning' },
          style: {
            background: themeColor.warning.background,
            svg: { color: themeColor.warning.main },
            '& > .MuiAlert-icon': { paddingTop: 10 },
            '& > .MuiAlert-message': { ...fontStyle.alertTitle },
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': { background: themeColor.action.hover },
          ':active, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible':
            {
              color: themeColor.primary.main,
              background: themeColor.primary.outlinedHoverBackground,
              '& .MuiSvgIcon-root': { color: themeColor.primary.main },
            },
          '& .MuiTouchRipple-root': { display: 'none' },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-option.Mui-focused': {
            background: themeColor.action.hover,
          },
          '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option.Mui-focused[aria-selected="true"], & .MuiAutocomplete-option.Mui-focusVisible':
            {
              color: themeColor.primary.main,
              background: `${themeColor.primary.outlinedHoverBackground} !important`,
            },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Helvethaica',
    ...fontStyle,
  },
})
