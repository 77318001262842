import _ from 'lodash'
import {
  closeDrawer,
  setStaffList,
} from '../../../../redux/slices/roomManagement/Drawer'
import { isSameDate } from '../../../../utils/lib'
import { BOOKING_PERIOD_TYPE } from '../../../../constants/roomManagement'
import dayjs from 'dayjs'
import {
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import { DeleteBooking } from './components/DeleteBooking'
import { deleteRoomManagement, getStaffList } from '../../../../services/roomManagement'
import { handleSuccess } from '../event'

export const fetchStaffList = () => async (dispatch) => {
  const data = await dispatch(getStaffList())
  const result = _.get(data, 'payload.result', [])

  const results = result.reduce((acc, item) => {
    const { uuid, firstNameTH, lastNameTH, area } = item
    const stationName = _.get(area, '[0].station.station', '')

    return [
      ...acc,
      {
        ...item,
        value: uuid,
        label: `${stationName} - ${firstNameTH} ${lastNameTH}`,
      },
    ]
  }, [])
  dispatch(setStaffList(results))
}

export const handleRoomManagement = (bookingInformation, roomBookingList) => {
  const { startDate, endDate, bookingPeriod } = bookingInformation

  const currentDate = new Date(new Date(startDate))

  if (
    isSameDate(startDate, endDate) ||
    bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
  ) {
    return _.flatMap(roomBookingList)
  } else {
    let newRoomBookingList = { ...roomBookingList }
    while (currentDate <= new Date(endDate)) {
      const formattedCurrentDate = dayjs(currentDate).format('YYYY-MM-DD')
      const newRoomArray = _.map(roomBookingList[startDate], (item) => {
        return { ...item, bookingDate: formattedCurrentDate }
      })
      newRoomBookingList[formattedCurrentDate] = newRoomArray
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return _.flatMap(newRoomBookingList)
  }
}

export const handleDeleteBookingDialog = (bookingUuid) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ลบการจอง',
      content: <DeleteBooking bookingUuid={bookingUuid} />,
      showButton: false,
    }),
  )
}

export const handleDeleteBooking = (payload) => async (dispatch) => {
  dispatch(loadingDialog())
  const response = await dispatch(deleteRoomManagement(payload))
  dispatch(stopLoadingDialog())
  const requestStatus = _.get(response, 'meta.requestStatus', 'rejected')
  if (requestStatus === 'fulfilled') {
    dispatch(handleSuccess('ลบการจองเรียบร้อยแล้ว'))
    dispatch(closeDrawer())
  }
}

export const getOnlineRoomForDelete = (roomBookingList) => {
  let onlineList = []
  roomBookingList.map((ele) =>
    ele.rooms.map((room) => {
      if (room.isOnline) {
        onlineList.push(room.uuid)
      }
    }),
  )
  return _.uniq(onlineList)
}
