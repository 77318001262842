import { handleChange, handleChangeEvent } from '../../events'

export const refCertField = 'eLearningLearningPathRefCertificate[0]'

export const handleECertChange = (key, value) => (dispatch) => {
  dispatch(handleChange(`${refCertField}.${key}`, value))
}

export const handleECertChangeEvent = (e) => (dispatch) => {
  const event = {
    ...e,
    target: {
      ...e.target,
      name: `${refCertField}.${e.target.name}`,
    },
  }
  dispatch(handleChangeEvent(event))
}
