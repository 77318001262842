import _ from 'lodash'
import { dayList, monthList } from '../../../../constants/roomManagement'
import { postBookingRoomWeekly } from '../../../../services/roomManagement'
import dayjs from 'dayjs'
import {
  setBookingRoomList,
  setBookingRoomWeekly,
  setCurrentDate,
  setCurrentWeek,
  setDates,
} from '../../../../redux/slices/roomManagement/Dashboard'
import {
  addDays,
  format,
  isSameDay,
  addWeeks,
  getWeek,
  subWeeks,
} from 'date-fns'
import { store } from '../../../../App'

export const formattedMonth = (month) => {
  const monthTH = _.chain(monthList)
    .find({ value: month })
    .get('label', '')
    .value()
  return monthTH
}

export const handleDayList = (startDate) => (dispatch) => {
  const { selectedDate } = store.getState().roomManagement

  const dateFormat = 'd'
  let day = new Date(startDate)
  const dateList = _.reduce(
    dayList,
    (acc, d) => {
      const formattedDate = format(day, dateFormat)
      const isSelected = isSameDay(day, selectedDate)
      const date = {
        ...d,
        date: formattedDate,
        isSelected: isSelected,
        dayjsFormat: day,
      }
      day = addDays(day, 1)
      return [...acc, date]
    },
    [],
  )
  dispatch(setDates(dateList))
}

export const fetchBookingRoomWeekly = () => async (dispatch) => {
  const { stationUuid, mondayDate } = store.getState().roomManagement

  const body = {
    stationUuid: stationUuid,
    mondayDate: dayjs(mondayDate).format('YYYY-MM-DD'),
  }
  const response = await dispatch(postBookingRoomWeekly(body))
  const bookingRoomWeekly = _.get(response, 'payload.bookingRoomWeekly', [])

  dispatch(setBookingRoomWeekly(bookingRoomWeekly))
}

export const changeWeekHandle = (btnType) => (dispatch) => {
  const { currentDate } = store.getState().roomManagement
  if (btnType === 'prev') {
    dispatch(setCurrentDate(subWeeks(currentDate, 1)))
    dispatch(setCurrentWeek(getWeek(subWeeks(currentDate, 1))))
  }
  if (btnType === 'next') {
    dispatch(setCurrentDate(addWeeks(currentDate, 1)))
    dispatch(setCurrentWeek(getWeek(addWeeks(currentDate, 1))))
  }
}

export const handleBookingRoomList = () => (dispatch) => {
  const { selectedDate, bookingRoomWeekly } = store.getState().roomManagement
  const selectedDateFormatted = dayjs(selectedDate).format('YYYY-MM-DD')
  const bookingRoomArray = _.chain(bookingRoomWeekly)
    .find({ date: selectedDateFormatted })
    .get('bookingRooms', [])
    .value()

  dispatch(setBookingRoomList(bookingRoomArray))
}
