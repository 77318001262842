export const initialApproveObj = {
  uuid: '',
  status: '',
  staffs: [],
  remark: '',
}

export const initialState = {
  error: null,
  isLoading: false,
  speakerType: '',
  data: null,
  staffList: [],
  approveList: [],
  errors: {},
  isSubmit: false,
}
