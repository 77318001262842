import { Box, Divider } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { DataCard } from '../../../components/ModuleCard/DataCard'
import BarChart from '../../../../../../../../components/Chart/BarChart'
import { axisLabel, chartPart1Model } from '../../../models/partModel'
import _ from 'lodash'
import TruncatedText from '../../../components/TruncatedText'

export const Part2Content = () => {
  const { data } = useSelector(
    (state) => ({
      data: state.crud.eEvaluationDashboard?.evaluationDrawer?.part2?.data,
    }),
    shallowEqual,
  )

  const labels = chartPart1Model().map((item) => item.label)
  const bgColors = chartPart1Model().map((item) => item.backgroundColor)

  return (
    <>
      {_.defaultTo(data, []).map((item, index) => {
        const chart = item.data
        const moduleData = preparedModuleData(item)

        return (
          <Box key={index} px={3}>
            <TruncatedText
              text={item.question ?? ''}
              readMoreTxt={'ดูเพิ่มเติม'}
              maxWidth={'72vw'}
              titleVariant={'h6'}
              maxLines={3}
              dialogTitle={'คำถาม'}
            />
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                dataList={moduleData}
                totalModule={''}
                border={'none'}
                padding={0}
              />
            </Box>
            <Box minHeight="250px">
              <BarChart
                dataSet={{
                  data: chart,
                  backgroundColor: bgColors,
                }}
                labels={labels}
                height={'300px'}
                showXAxisLabel={true}
                showYAxisLabel={true}
                xAxisLabel={axisLabel.x}
                yAxisLabel={axisLabel.y}
                showPercent={true}
                eleId={`part2-${index}`}
              />
            </Box>
            <Divider sx={{ my: 3 }} />
          </Box>
        )
      })}
    </>
  )
}

const preparedModuleData = (item) => {
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: item?.attendeeCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: item?.respondentCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${item?.overallPercentage ?? 0} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}
