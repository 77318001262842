import _ from 'lodash'
import {
  setOption,
  startOptionLoading,
  stopOptionLoading,
} from '../../../../../../redux/slices/eLearning/moduleForm'
import { eTestingVersionList } from '../../../../../../utils/apiPath'
import callAxios from '../../../../../../utils/baseService'
import { handleChange, handleChangeEvent } from '../../events'
import { E_TESTING_TYPE } from './model'

export const handleInitialFetch = (moduleUuid, keyType) => (dispatch) => {
  if (_.isEmpty(moduleUuid)) {
    dispatch(handleChange(keyType, E_TESTING_TYPE.ONLINE_TEST))
    dispatch(fetchETestingList(E_TESTING_TYPE.ONLINE_TEST))
  }
}

export const handleRadioChange = (e, typeField) => async (dispatch) => {
  const keyUuid = `${typeField}.uuid`
  const keyCriteriaType = `${typeField}.criteriaType`
  const keyCriteriaPercent = `${typeField}.criteriaPercent`

  dispatch(handleChangeEvent(e))
  dispatch(handleChange(keyUuid, ''))
  dispatch(handleChange(keyCriteriaType, ''))
  dispatch(handleChange(keyCriteriaPercent, ''))
  await dispatch(fetchETestingList(e.target.value))
}

export const fetchETestingList = (typeOfETesting) => async (dispatch) => {
  dispatch(startOptionLoading())
  const response = await callAxios.get(
    `${eTestingVersionList}/${typeOfETesting}`
  )
  dispatch(stopOptionLoading())
  if (response.data) {
    const list = _.get(response, 'data', [])
    const eLearningOption = list.map((item) => ({
      label: item.name,
      value: item.uuid,
    }))
    dispatch(setOption(eLearningOption))
  }
}
