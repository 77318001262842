export const size = ['0.75em', false, '1.5em', '2.5em']
export const modulesProp = {
  toolbar: {
    container: [
      [{ size }],
      ['bold', 'italic', 'underline', { color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  },
  clipboard: { matchVisual: false },
  imageResize: { modules: ['Resize', 'DisplaySize'] },
}
