import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import { handleCloseDialog, onDownloadDetail } from './events'
import { StyledDialog } from './Styled'
import {
  setChangeStatus,
  setStatusClear,
} from '../../../../redux/slices/apiToRcms'
import { useParams } from 'react-router-dom'

export const DownloadDialog = () => {
  const { id } = useParams()
  const { isLoading } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const dispatch = useDispatch()
  const [select, setSelect] = useState('ทั้งหมด')
  const { YES, NO, WAIT_STATUS, SUCCESS, FAIL, NOT_SEND, WAIT_SEND } =
    useSelector(
      (state) => ({
        YES: state.apiToRcms.YES,
        NO: state.apiToRcms.NO,
        WAIT_STATUS: state.apiToRcms.WAIT_STATUS,
        SUCCESS: state.apiToRcms.SUCCESS,
        FAIL: state.apiToRcms.FAIL,
        NOT_SEND: state.apiToRcms.NOT_SEND,
        WAIT_SEND: state.apiToRcms.WAIT_SEND,
      }),
      shallowEqual
    )
  const onSelect = ({ name, value }) => {
    dispatch(
      setChangeStatus({
        name: name,
        value: !value,
      })
    )
  }
  const DownloadOption = () => {
    const licenseStatus = []
    const rcmsStatus = []
    if (select === 'เลือกจากสถานะใบอนุญาต') {
      if (YES) licenseStatus.push('ให้')
      if (NO) licenseStatus.push('ไม่ให้')
      if (WAIT_STATUS) licenseStatus.push('รอตรวจสอบ')
    } else if (select === 'RCMS') {
      if (SUCCESS) rcmsStatus.push('สำเร็จ')
      if (FAIL) rcmsStatus.push('ไม่สำเร็จ')
      if (NOT_SEND) rcmsStatus.push('ไม่ต้องส่ง')
      if (WAIT_SEND) rcmsStatus.push('รอส่ง')
    }
    const body = {
      rcmsUuid: id,
      list: [],
      licenseStatus: licenseStatus,
      rcmsStatus: rcmsStatus,
    }
    dispatch(onDownloadDetail(body))
  }
  return (
    <>
      <DialogHeader />
      <StyledDialog>
        <RadioGroup
          data-testid="radio-changeStatus"
          sx={{ pl: 1.5, gap: 0.5 }}
          aria-label="changeStatus"
          name="changeStatus"
          defaultValue={select}
          value={select}
          onChange={(e) => {
            const { value } = e.target
            setSelect(value)
          }}
        >
          <FormControlLabel
            value="ทั้งหมด"
            control={<Radio />}
            label="ทั้งหมด"
          />
          <FormControlLabel
            value="เลือกจากสถานะใบอนุญาต"
            control={<Radio />}
            label="เลือกจากสถานะใบอนุญาต"
          />
          {select === 'เลือกจากสถานะใบอนุญาต' && (
            <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                key={1}
                checked={WAIT_STATUS}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'WAIT_STATUS',
                        value: WAIT_STATUS,
                      })
                    }
                  />
                }
                label="รอตรวจสอบ"
              />
              <FormControlLabel
                key={2}
                checked={YES}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'YES',
                        value: YES,
                      })
                    }
                  />
                }
                label="ให้"
              />
              <FormControlLabel
                key={3}
                checked={NO}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'NO',
                        value: NO,
                      })
                    }
                  />
                }
                label="ไม่ให้"
              />
            </Box>
          )}
          <FormControlLabel
            value="RCMS"
            control={<Radio />}
            label="เลือกจากสถานะส่งข้อมูลไป RCMS"
          />
          {select === 'RCMS' && (
            <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                key={4}
                checked={SUCCESS}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'SUCCESS',
                        value: SUCCESS,
                      })
                    }
                  />
                }
                label="สำเร็จ"
              />
              <FormControlLabel
                key={5}
                checked={FAIL}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'FAIL',
                        value: FAIL,
                      })
                    }
                  />
                }
                label="ไม่สำเร็จ"
              />
              <FormControlLabel
                key={6}
                checked={NOT_SEND}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'NOT_SEND',
                        value: NOT_SEND,
                      })
                    }
                  />
                }
                label="ไม่ต้องส่ง"
              />
              <FormControlLabel
                key={6}
                checked={WAIT_SEND}
                control={
                  <Checkbox
                    onChange={() =>
                      onSelect({
                        name: 'WAIT_SEND',
                        value: WAIT_SEND,
                      })
                    }
                  />
                }
                label="รอส่ง"
              />
            </Box>
          )}
        </RadioGroup>
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => {
              dispatch(setStatusClear())
              dispatch(handleCloseDialog())
            }}
            variant="outlined"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          loading={isLoading}
          onClick={() => DownloadOption()}
          color="primary"
          endIcon=""
        >
          ดาวน์โหลด
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}

export default DownloadDialog

export const DialogHeader = () => {
  return (
    <Box id="dialog-header">
      <Typography variant="h6">ดาวน์โหลดข้อมูล</Typography>
    </Box>
  )
}
