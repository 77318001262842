import _ from 'lodash'
import { store } from '../../../../App'
import { createBudgetManagement } from '../../../../services/budgetManagement'
import { fetchBudgetManagementList } from './fetchBudgetManagementList'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { closeDialog, loadingDialog, openDialog } from '../../../../redux/slices/dialog'

export const handleSubmitImport = async () => {
  store.dispatch(loadingDialog())
  const { importFile } = store.getState().crud.formData
  const successFileList = _.filter(
    _.get(importFile, 'uploadResult', []),
    (item) => item.statusUpload === 'SUCCESS',
  )

  const response = await store.dispatch(createBudgetManagement(successFileList))
  const payload = _.get(response, 'meta.requestStatus', 'rejected')

  if (payload === 'fulfilled') {
    store.dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        agreeText: 'ตกลง',
        isCloseDialog: false,
        handleConfirm: () => {
          fetchBudgetManagementList(mutateFilterPropToBody)
          store.dispatch(closeDialog())
        },
      }),
    )
  }
}
