import _ from 'lodash'
import { setChangeBody } from '../../../../redux/slices/manageContent/station'

export const handleSelector = (state, keyField) => {
  return _.get(state.manageContentStation.data, keyField, [])
}

export const handleInputBody = (e, key) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  const payload = { value, key: `${key}.${name}` }
  dispatch(setChangeBody(payload))
}

export const handleSwitchBody = (e, key) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  const payload = { value: checked, key: `${key}.${name}` }
  dispatch(setChangeBody(payload))
}
