import _ from 'lodash'

export const handleNumberInput = (e) => {
  const target = _.get(e, 'target', {})
  const regex = /^[0-9]*$/

  if (!target?.value?.match(regex)) {
    target.value = e.target.value.slice(0, -1)
  }
  return target.value.replaceAll(',', '')
}
