export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterItems = [
  {
    label: `หมวดหมู่`,
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `หมวดหมู่ย่อย`,
    nameCheckBox: 'subCategoryChecked',
    nameInput: 'subCategory',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `Station`,
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: ``,
    type: 'checkbox',
    list: [],
  },
  {
    label: `คลังทรัพย์สิน`,
    nameCheckBox: 'warehouseChecked',
    nameInput: 'warehouse',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `Cost center`,
    nameCheckBox: 'costCenterChecked',
    nameInput: 'costCenter',
    placeholder: `กรุณาเลือก`,
    type: 'select',
  },
  {
    label: `คำอธิบาย`,
    nameCheckBox: 'descriptionChecked',
    nameInput: 'description',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `เปิดใช้งาน Flag คืนทรัพย์สิน`,
    nameCheckBox: 'returnChecked',
    nameInput: 'return',
    placeholder: ``,
    inputYear: 'returnYear',
    inputMonth: 'returnMonth',
    inputDay: 'returnDay',
    type: 'number_day',
  },
  {
    label: `จำนวนคงเหลือ`,
    nameCheckBox: 'totalChecked',
    nameInput: 'total',
    placeholder: ``,
    inputFrom: 'totalFrom',
    inputTo: 'totalTo',
    type: 'number_between',
  },
  {
    label: `จำนวนขั้นต่ำแจ้งเตือนคงเหลือน้อย`,
    nameCheckBox: 'quantityAlertChecked',
    nameInput: 'quantityAlert',
    placeholder: ``,
    inputFrom: 'quantityAlertFrom',
    inputTo: 'quantityAlertTo',
    type: 'number_between',
  },
  {
    label: `มูลค่าต่อหน่วย (฿)`,
    nameCheckBox: 'unitPriceChecked',
    nameInput: 'unitPrice',
    placeholder: ``,
    inputFrom: 'unitPriceFrom',
    inputTo: 'unitPriceTo',
    type: 'float_between',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createStartDate',
    dateStateFinish: 'createFinishDate',
    dateStateDisplay: 'createDisplayDate',
    type: 'daterange',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `วันที่แก้ไขล่าสุด`,
    nameCheckBox: 'updateDateChecked',
    nameInput: 'updateDate',
    placeholder: `กรุณาใส่ วันที่แก้ไข`,
    dateStateStart: 'updateStartDate',
    dateStateFinish: 'updateFinishDate',
    dateStateDisplay: 'updateDisplayDate',
    type: 'daterange',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: ``,
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]

export const filterItemsAsset = [
  {
    label: `หมวดหมู่`,
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `หมวดหมู่ย่อย`,
    nameCheckBox: 'subCategoryChecked',
    nameInput: 'subCategory',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `Vendor`,
    nameCheckBox: 'vendorChecked',
    nameInput: 'vendor',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `มูลค่ารวม (฿)`,
    nameCheckBox: 'stockValueChecked',
    nameInput: 'stockValue',
    placeholder: ``,
    inputFrom: 'stockValueFrom',
    inputTo: 'stockValueTo',
    type: 'float_between',
  },
  {
    label: `วันที่สร้าง`,
    nameCheckBox: 'createDateChecked',
    nameInput: 'createDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'createStartDate',
    dateStateFinish: 'createFinishDate',
    dateStateDisplay: 'createDisplayDate',
    type: 'daterange',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: ``,
    type: 'text',
  },
  {
    label: `วันที่แก้ไขล่าสุด`,
    nameCheckBox: 'updateDateChecked',
    nameInput: 'updateDate',
    placeholder: `กรุณาใส่ วันที่แก้ไข`,
    dateStateStart: 'updateStartDate',
    dateStateFinish: 'updateFinishDate',
    dateStateDisplay: 'updateDisplayDate',
    type: 'daterange',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: ``,
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
  {
    label: 'ผู้ถือครอง',
    nameCheckBox: 'holderChecked',
    nameInput: 'holder',
    type: 'select_dropdown',
    placeholder: `กรุณาเลือก`,
  },
]
