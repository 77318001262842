import { CircularProgress, Drawer } from '@mui/material'
import { styled } from '@mui/system'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: 900,
  },
}))

export const StyledLoading = styled(CircularProgress)(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'block' : 'none',
  color: 'primary.main',
}))
