import { handleGetCourseUuid } from './events'

export const breadcrumbView = () => {
  const uuid = handleGetCourseUuid()
  return [
    { title: 'E-Learning', link: '/', pointer: false },
    { title: 'Course', link: '/e-learning/course', pointer: true },
    {
      title: 'รอบอบรม',
      link: `/e-learning/course/edit/${uuid}?tab=batch`,
      pointer: true,
    },
    { title: 'รายละเอียดรอบอบรม', link: '/', pointer: false },
  ]
}
