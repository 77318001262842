import _ from 'lodash'

const regex = /^[0-9,]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  if (e.target.value[0] === '0') e.target.value = Number(e.target.value)
  return e.target.value
}

export const handleDefaultValue = (value) => {
  if (typeof value === 'number') return value.toLocaleString()
  if (_.isEmpty(value)) return ''
  return Number(value.replaceAll(',', '')).toLocaleString()
}
