import React from 'react'
import _ from 'lodash'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import DateRangeInput from '../../../../../../../../components/DateRangeInput'
import { courseStatusCheckbox } from '../../../../../../../../constants/manageClass'
import { Column } from '../../../../Styled'
import { StyledFilterColumn, StyledFilterHeader } from './Styled'
import {
  handleChangePeriod,
  handleCheckboxChange,
  handleResetPeriod,
} from './events'

const Filter = () => {
  const dispatch = useDispatch()
  const { totalClass, filterBody } = useSelector(
    (state) => ({
      totalClass: state.eLearningPathPreview.totalClass,
      filterBody: state.eLearningPathPreview.filterBody,
    }),
    shallowEqual,
  )
  const courseStatus = _.get(filterBody, 'courseStatus', [])
  const startDate = _.get(filterBody, 'startDate', '')
  const finishDate = _.get(filterBody, 'finishDate', '')
  const isStartDate = startDate !== ''
  const isFinishDate = finishDate !== ''
  const defaultStart = isStartDate ? new Date(startDate) : new Date()
  const defaultFinish = isFinishDate ? new Date(finishDate) : new Date()

  return (
    <StyledFilterColumn>
      <StyledFilterHeader>
        <InsertInvitationOutlined />
        <Typography variant="h4">คลาสเรียน ({totalClass})</Typography>
      </StyledFilterHeader>

      <Column gap={1}>
        <Typography variant="body1b">สถานะห้องเรียน</Typography>
        <FormGroup>
          {courseStatusCheckbox.map((item, index) => {
            const value = _.get(item, 'value', '')
            return (
              <FormControlLabel
                key={index}
                sx={{ ml: 0 }}
                control={
                  <Checkbox
                    value={value}
                    name="courseStatus"
                    onClick={(e) =>
                      dispatch(handleCheckboxChange(e, courseStatus))
                    }
                  />
                }
                label={_.get(item, 'label', '')}
                checked={courseStatus.some((status) => status == value)}
              />
            )
          })}
        </FormGroup>
      </Column>

      <Column gap={1}>
        <Typography variant="body1b">วันที่เริ่มอบรม</Typography>
        <DateRangeInput
          placeholder="dd/mm/yyyy - dd/mm/yyyy"
          dateState={{
            startDate: defaultStart,
            endDate: defaultFinish,
            display: isStartDate,
            key: 'selection',
          }}
          onChange={(selectedDates) =>
            dispatch(handleChangePeriod(selectedDates))
          }
          onReset={() => dispatch(handleResetPeriod())}
        />
      </Column>
    </StyledFilterColumn>
  )
}

export default Filter
