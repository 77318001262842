import { startLoading, stopLoading } from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import { onDownloadReport } from './onDownloadReport'

export const onDownload =
  (selected, sort, order, url, downloadName, moduleId) => async (dispatch) => {
    if (moduleId === 'ETESTING_DASHBOARD_TESTERLIST') {
      onDownloadReport(selected)
      return
    }

    dispatch(startLoading())
    const body = {
      order: order.toUpperCase(),
      sort: sort,
      type: 'CUSTOM',
      list: selected,
    }

    await dispatch(
      downloadFile({
        url: url,
        body: body,
        fileName: downloadName,
      }),
    )
    dispatch(stopLoading())
  }
