import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import ECertificationSetting from '../../../CourseSetting/ECertificationSetting'

const ECertification = ({
  eCertificateList,
  isECertLoading,
  body,
  handleChange,
  handleChangeEvent,
}) => {
  const dispatch = useDispatch()
  const eCertificationUuid = _.get(
    body,
    'eCertificationVersion.uuid',
    _.get(body, 'eCertificationVersion', ''),
  )
  const eCertBody = {
    ...body,
    eCertificationVersion: _.isEmpty(eCertificationUuid)
      ? ''
      : { uuid: eCertificationUuid },
  }
  const [initialValues, setInitialValues] = useState({ ...eCertBody })
  const formik = {
    values: { ...eCertBody },
    setFieldValue: (key, value) => dispatch(handleChange(key, value)),
    handleChange: (e) => dispatch(handleChangeEvent(e)),
  }

  return (
    <ECertificationSetting
      eCertification={eCertificateList}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      formik={formik}
      isLoading={isECertLoading}
    />
  )
}

export default ECertification
