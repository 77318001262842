import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledHeadLabel } from './Styled'
import Form from './form'
import { openStockDrawer } from '../../handler/openStockDrawer'

const index = () => {

  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={true}
        onClose={() => openStockDrawer(false)}
        PaperProps={{
          sx: {
            height:'100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: 1000 },
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">รายละเอียดการไม่คืนทรัพย์สิน</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openStockDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box mx={3}>
            <Form />
          </Box>
          {/* <LoadingExaminer isLoading={isLoadingDrawer} /> */}
        </Box>
      </Drawer>
    </>
  )
}

export default index
