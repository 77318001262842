import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { setStartModule } from '../../../redux/slices/eLearning/preview'
import {
  responsive,
  StyledButton,
  StyledDescriptionContent,
  StyledTitle,
} from './Styled'

const Content = ({ information, url, isModule, buttonText }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <Box sx={{ mt: 5, wordBreak: 'break-word' }}>
      <StyledTitle
        align={_.get(information, 'title1Position', 'LEFT')}
        variant={responsive('h3', 'h4', 'h5')}
        color="primary"
      >
        {_.get(information, 'title1', '')}
      </StyledTitle>
      <Box sx={{ textAlign: _.get(information, 'title2Position', 'LEFT') }}>
        <StyledTitle variant={responsive('h4', 'h5', 'body1b')} color="primary">
          {_.get(information, 'title2', '')}
        </StyledTitle>
      </Box>
      <StyledDescriptionContent
        align={_.get(information, 'descriptionPosition', 'LEFT')}
      >
        <Typography
          variant={responsive('h6', 'h6', 'body2b')}
          color="text.secondary"
        >
          {_.get(information, 'description', '')}
        </Typography>
        <StyledButton
          size={responsive('l', 'm', 's')}
          data-testid="btn-start"
          onClick={() =>
            isModule ? dispatch(setStartModule()) : history.push(url)
          }
        >
          {buttonText}
        </StyledButton>
      </StyledDescriptionContent>
    </Box>
  )
}

export default Content
