import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { StyledListItem } from '../../Styled'
import { Row, Column } from '../../../Layout/Styled'
import { handleDeleteFilter } from '../../handler/handleDeleteFilter'

const FilterListItem = ({ filter }) => {
  const dispatch = useDispatch()
  const id = _.get(filter, 'id', 1)
  const title = _.get(filter, 'title', '')
  const label = _.get(filter, 'label', '')
  const cantDel = _.get(filter, 'cantDel', false)

  return (
    <StyledListItem
      secondaryAction={
        cantDel ? (
          ''
        ) : (
          <IconButton
            color="primary"
            onClick={() => dispatch(handleDeleteFilter(id))}
          >
            <CloseIcon />
          </IconButton>
        )
      }
    >
      <ListItemText
        primary={
          <Row
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Typography variant="body1" component="span">
              {title} :{' '}
            </Typography>
            {String(label).split('&&').length > 1 ? (
              <Column>
                {String(label)
                  .split('&&')
                  .map((ele, ind) => (
                    <Typography
                      sx={{ wordBreak: 'break-word' }}
                      variant="body2b"
                      component="span"
                      key={ind}
                    >
                      {ele}
                    </Typography>
                  ))}
              </Column>
            ) : (
              <Typography
                sx={{ wordBreak: 'break-word' }}
                variant="body2b"
                component="span"
              >
                {label}
              </Typography>
            )}
          </Row>
        }
      />
    </StyledListItem>
  )
}

export default FilterListItem
