import { FORM_TAB } from '../../../../modules/ELearning/LearningPath/Form/model'
import { MODULE_STATUS } from '../../../../constants/eLearning'

export const initialCourse = {
  id: '1',
  sequence: 1,
  isRequired: false,
  type: '',
  course: { uuid: '' },
  eLearningCourse: null,
  courseVersion: null,
}

export const initialValues = {
  name: '',
  nameForBanner: '',
  detailForBanner: '',
  description: '',
  fileName: '',
  coverImage: '',
  linkExampleVideo: '',
  remark: '',
  status: MODULE_STATUS.ACTIVE,
  eLearningLearningPathCourses: [{ ...initialCourse }],
  periodStart: '',
  setting: {
    tag: [],
    distribution: [],
    remark: '',
  },
  levelOfLearner: [],
  acquiredSkill: [],
  productType: [],
  dueDate: null,
  dueDateType: 'NONE',
  courseAccessExpiration: 'NONE',
  timeSpan: null,
  timeSpanType: null,
  advanceNotificationCheck: false,
  advanceNotification: null,
  continuousNotificationsCheck: false,
  continuousNotifications: null,
  isDraft: false,
  eLearningLearningPathRefCertificate: [],
}

export const initialState = {
  isLoading: false,
  isOptionLoading: false,
  isECertLoading: false,
  isUploadLoading: false,
  isUploadSuccess: false,
  tab: FORM_TAB.learningPath,
  uploadError: null,
  percent: null,
  body: initialValues,
  oldPeriodStart: null,
  oldDueDate: null,
  cropper: {
    isOpen: false,
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    croppedImage: null,
  },
  coursesOption: [],
  distributionOption: [],
  levelOfLearnerOption: [],
  eCertificateList: [],
  acquireSkillList: [],
  productTypeList: [],
  pathCreateCourse: '/course-setting/form',
}
