import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onEnterSearch } from './events'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import {
  setSearch,
  handleSearch,
} from '../../../../../redux/slices/manage/agentProfile'
const SearchCustom = ({ searchKey }) => {
  const dispatch = useDispatch()
  const [searchTable, setSearchTable] = useState('')
  const key =
    searchKey === 'courseSearch'
      ? 'handleCourseSearch'
      : searchKey === 'examSearch'
      ? 'handleExamSearch'
      : 'handleETestingSearch'

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          setSearch({
            key: searchKey,
            value: searchTable,
          }),
        ),
      500,
    )
    return () => clearTimeout(timeOutId)
  }, [searchTable])

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder="ค้นหา"
        onChange={(e) => {
          const value = _.get(e, 'target.value', '')
          setSearchTable(value)
        }}
        onKeyPress={(e) => {
          dispatch(onEnterSearch(e, key))
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const value = _.get(e, 'target.value', '')
            dispatch(
              setSearch({
                key: searchKey,
                value: value,
              }),
            )
            dispatch(onEnterSearch(e, key))
          }
        }}
        value={searchTable}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => {
          dispatch(handleSearch({ key, value: searchTable }))
        }}
      />
    </BoxSearch>
  )
}

export default SearchCustom
