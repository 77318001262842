import React from 'react'
import useTheme from '@mui/system/useTheme'
import { DotStyle } from './Styled'
import { checkColorStatus } from './events'

const Dot = ({ sx, status, color }) => {
  const theme = useTheme()
  return (
    <DotStyle
      sx={sx}
      status={status}
      color={color || checkColorStatus(status, theme)}
    />
  )
}

export default Dot
