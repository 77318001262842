import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'
import MapsHomeWorkOutlined from '@mui/icons-material/MapsHomeWorkOutlined'
import PeopleOutline from '@mui/icons-material/PeopleOutline'
import VoiceChatOutlined from '@mui/icons-material/VoiceChatOutlined'
import {
  convertDateRange,
  isMediaWidthMoreThan,
} from '../../../../../../../../utils/lib'
import { Column, Row } from '../../../../Styled'
import {
  StyledDivider,
  StyledEnrollButton,
  StyledLoading,
  StyledTableColumn,
  StyledTableData,
  StyledTableRow,
  StyledTypography,
} from './Styled'
import {
  handleCourseClassList,
  handleLoadMore,
  handleSeatText,
  handleSeeMore,
} from './events'

const Table = () => {
  const {
    isTrainingPlanLoading,
    isLoadMore,
    classList,
    filterBody,
    selectedCourse,
  } = useSelector(
    (state) => ({
      isTrainingPlanLoading: state.eLearningPathPreview.isTrainingPlanLoading,
      isLoadMore: state.eLearningPathPreview.isLoadMore,
      classList: state.eLearningPathPreview.classList,
      filterBody: state.eLearningPathPreview.filterBody,
      selectedCourse: state.eLearningPathPreview.selectedCourse,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const isHaveSubject = classList.some((item) => item.subject !== '')

  useEffect(() => {
    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(handleCourseClassList(selectedCourse, filterBody))
  }, [filterBody.courseStatus, filterBody.startDate, filterBody.finishDate])

  useEffect(() => {
    dispatch(handleSeeMore(selectedCourse, filterBody))
  }, [filterBody.page])

  return (
    <StyledTableColumn>
      <TableHeader isHaveSubject={isHaveSubject} />
      {isTrainingPlanLoading ? (
        <StyledLoading />
      ) : (
        classList.map((item, index) => (
          <TableData key={index} data={item} isHaveSubject={isHaveSubject} />
        ))
      )}
      {isLoadMore && <StyledLoading isLoadMore />}
    </StyledTableColumn>
  )
}

export default Table

export const TableHeader = ({ isHaveSubject }) => {
  return (
    <>
      <StyledTableRow id="header">
        <Typography sx={{ minWidth: 180 }} variant="tableHeader">
          วันที่อบรม
        </Typography>
        {isHaveSubject && <Typography variant="tableHeader">หัวข้อ</Typography>}
        <Typography variant="tableHeader">สถานที่อบรม</Typography>
        <Typography variant="tableHeader">ที่นั่ง</Typography>
      </StyledTableRow>
      <StyledDivider />
    </>
  )
}

export const TableData = ({ data, isHaveSubject }) => {
  const isLg = isMediaWidthMoreThan('md')
  const trainingRoom = _.uniq(_.get(data, 'trainingRoom', []))
  const classDate = convertDateRange(_.get(data, 'dateRangeMonthlyPlan', null))
  const subject = _.get(data, 'subject', null)
  const seatText = handleSeatText(data)
  const [roomWidth, setRoomWidth] = useState(200)
  const ref = useRef()
  const subjectText = subject === '' ? '-' : subject

  useEffect(() => {
    if (ref.current) setRoomWidth(ref.current.clientWidth)
  }, [ref])

  return (
    <>
      <Row>
        <StyledTableRow isHaveSubject={isHaveSubject} sx={{ py: 1 }}>
          <Typography sx={{ minWidth: 180 }}>{classDate}</Typography>
          {isHaveSubject && (
            <StyledTableData>
              {isLg ? (
                <Typography>{subjectText}</Typography>
              ) : (
                subject !== '' && (
                  <>
                    <VoiceChatOutlined fontSize="small" />
                    <Typography>{subject}</Typography>
                  </>
                )
              )}
            </StyledTableData>
          )}
          <StyledTableData ref={ref}>
            {!isLg && <MapsHomeWorkOutlined fontSize="small" />}
            <Column>
              {trainingRoom.map((item, index) => (
                <StyledTypography roomWidth={roomWidth} key={index}>
                  {item}
                  {index < trainingRoom.length - 1 ? ',' : ''}
                </StyledTypography>
              ))}
            </Column>
          </StyledTableData>
          <Row sx={{ justifyContent: 'space-between' }}>
            <Row sx={{ width: 'auto', minWidth: 60, gap: 0.5 }}>
              <PeopleOutline fontSize="small" />
              <Typography color={_.get(seatText, 'color', 'text.primary')}>
                {_.get(seatText, 'text', '-')}
              </Typography>
            </Row>
            {isLg && <EnrollButton data={data} />}
          </Row>
        </StyledTableRow>
        {!isLg && <EnrollButton data={data} />}
      </Row>
      <StyledDivider />
    </>
  )
}

export const EnrollButton = ({ data }) => {
  const availableSeats = _.get(data, 'availableSeats', 0)
  const isFull = availableSeats === 0
  const canRegister = _.get(data, 'canRegister', false)
  const registerClose = !canRegister && !isFull
  return (
    <StyledEnrollButton
      endIcon={!registerClose && <ArrowForward />}
      disabled={isFull || !canRegister}
    >
      {registerClose ? 'ปิดรับสมัครแล้ว' : 'สมัครอบรม'}
    </StyledEnrollButton>
  )
}
