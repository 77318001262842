const {
  getTicketFromMonthlyPlan,
} = require('../../../../../services/inventory')

export const getTickets = (uuid) => async (dispatch) => {
  await dispatch(getTicketFromMonthlyPlan({ monthlyPlan: uuid }))
}

export const handleClickCreate = ({ date, course, uuid }) => {
  window.open(
    `/inventory/requisition/monthly-plan/form?course=${course}&date=${date}&uuid=${uuid}`,
    '_blank',
  )
}
