import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { scrollToLast } from './scrollToLast'
import { contentTypeKB } from '../model/contentTypeKB'

export const handleAddContentTypeKB = () => {
  const state = store.getState().crud

  const contents = [...state.formData.contents]
  contents.push(contentTypeKB)

  store.dispatch(setFieldValue({ key: 'formData.contents', value: contents }))
  scrollToLast()
}
