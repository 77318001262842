import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import { Box } from '@mui/material'
import _ from 'lodash'
import { CustomLabels } from './CustomLabels'
import { useTheme } from '@mui/system'

const RadarChart = ({ data = {}, labels, height = '100%', idx = 1 }) => {
  const theme = useTheme()
  const [chart, setChart] = useState()
  const [hiddenDatasets, setHiddenDatasets] = useState([])

  const toggleDatasetVisibility = (index) => {
    if (hiddenDatasets.includes(index)) {
      setHiddenDatasets(hiddenDatasets.filter((i) => i !== index))
    } else {
      setHiddenDatasets([...hiddenDatasets, index])
    }
  }

  useEffect(() => {
    if (chart) {
      chart.destroy()
    }
    const ctx = document.getElementById(`radarChart-${idx}`)
    const newChart = new Chart(ctx, {
      type: 'radar',
      data: {
        ...data,
        datasets: data.datasets.map((dataset, i) => ({
          ...dataset,
          hidden: hiddenDatasets.includes(i),
        })),
      },
      options: {
        elements: {
          line: {
            borderWidth: 1,
          },
          point: {
            radius: 2,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            suggestedMax: _.max(data) + 10,
            beginAtZero: true,
            ticks: {
              display: false,
              stepSize: (_.max(data) + 10) / 10,
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            usePointStyle: true,
            backgroundColor: theme.palette.common.white,
            titleColor: theme.palette.common.black,
            bodyColor: theme.palette.common.black,
            borderColor: theme.palette.grey.grey300,
            borderWidth: 1,
            callbacks: {
              title: function (tooltipItems) {
                return `${tooltipItems[0].label}`
              },
              label: function (tooltipItem) {
                const datasetLabel = tooltipItem.dataset.label || ''
                const value = tooltipItem.raw
                return `${datasetLabel} : ${value}`
              },
            },
            custom: function (tooltip) {
              if (!tooltip) return
              tooltip.displayColors = false
              tooltip.yAlign = 'bottom'
              tooltip.opacity = 0.9
            },
          },
        },
      },
    })
    setChart(newChart)
  }, [data, labels, hiddenDatasets])

  return (
    <Box
      sx={{
        height: height,
        position: 'relative',
      }}
    >
      <CustomLabels
        datasets={data.datasets}
        hiddenDatasets={hiddenDatasets}
        toggleDatasetVisibility={toggleDatasetVisibility}
      />
      <canvas id={`radarChart-${idx}`} />
    </Box>
  )
}

export default RadarChart
