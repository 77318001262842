import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import { convertFormatDateTime } from '../../../../utils/lib'
import { getStockDetailHistoryFilter } from '../../../../services/inventory'
import { STOCK_HISTORY_STATUS_TEXT } from '../../../../constants/stock'
import { setStockHistoryList } from '../../../../redux/slices/inventory/stock/list'

export const handleFiltering = () => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
}

export const headCells = [
  {
    id: 'event',
    label: 'รายการ',
    hideSortIcon: true,
  },
  {
    id: 'author',
    label: 'ผู้แก้ไข',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    hideSortIcon: true,
  },
]

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    toDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const fetchStockHistory = (uuid, page, events) => async (dispatch) => {
  if (uuid) {
    const pageBody = _.get(events, 'page', 1) < 1 ? 1 : _.get(events, 'page', 1)
    const body = {
      contentId: uuid,
      author: '',
      limit: _.get(events, 'limit', 100),
      order: 'DESC',
      page: pageBody,
      sort: 'createdAt',
    }
    const response = await dispatch(getStockDetailHistoryFilter(body))
    const totalCount = _.get(response, 'payload.data.totalCount', 0)
    let result = _.get(response, 'payload.data.result', [])
    result = _.map(result, (item) => {
      return {
        ...item,
        event: STOCK_HISTORY_STATUS_TEXT[item.event],
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'dateTime',
        }),
      }
    })

    dispatch(
      setStockHistoryList({
        result,
        totalCount,
      }),
    )
  }
}
