import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { validatePermission } from '../../../../../../utils/lib'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import { drawerFormValidationSkuNotImport } from '../../schema/schema'
import { assetTypeEnum } from '../../enum/assetTypEnum'
import { shallowEqual, useSelector } from 'react-redux'
import { callAPI } from '../../../../../../utils/lib'
import { store } from '../../../../../../App'
import { startLoading, stopLoading } from '../../../../../../redux/slices/table'
import _ from 'lodash'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { getSkuNo } from '../../utils'

const Form = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  const defaultOptions = { isLoading: false, options: [] }
  const [assetCodeOptions, setAssetCodeOptions] = useState(defaultOptions)
  const {
    type,
    productStockAsset,
    productAsset,
    productLicense,
    skuNo,
    assetData,
    from,
    productStockAssetMasterData,
    productAssetMasterData,
    productLicenseMasterData,
  } = useSelector(
    (state) => ({
      type: state.crud.formData.type,
      productStockAsset: state.crud.formData.productStockAsset,
      productAsset: state.crud.formData.productAsset,
      productLicense: state.crud.formData.productLicense,
      skuNo: state.crud.formData?.skuNo,
      from: state.crud.formData?.from,
      assetData: state.crud.assetData,
      productStockAssetMasterData: state.crud.masterData.productStockAsset,
      productAssetMasterData: state.crud.masterData.productAsset,
      productLicenseMasterData: state.crud.masterData.productLicense,
    }),
    shallowEqual,
  )

  let selectedProduct = null
  let productMasterOption = null
  if (type === assetTypeEnum.SKU) {
    selectedProduct = productStockAsset
    productMasterOption = productStockAssetMasterData
  } else if (type === assetTypeEnum.ASSET) {
    selectedProduct = productAsset
    productMasterOption = productAssetMasterData
  } else if (type === assetTypeEnum.LICENSE) {
    selectedProduct = productLicense
    productMasterOption = productLicenseMasterData
  }

  useEffect(() => {
    fetchAssetCode()
  }, [selectedProduct])

  const fetchAssetCode = async () => {
    store.dispatch(startLoading())
    setAssetCodeOptions(defaultOptions)
    if (selectedProduct?.value) {
      const url = `/crud/${selectedProduct?.value}`
      const headers = { 'x-type': 'INVENTORY_PRODUCT_STOCK' }
      const response = await callAPI({
        method: 'get',
        url,
        headers,
      })

      if (response) {
        const assetCodes = _.get(response, 'assetCodeOption', []).filter(
          (item) =>
            _.get(item, 'productWarehouse.warehouse.uuid') === from?.value &&
            _.get(item, 'status') === 'ACTIVE',
        )
        setAssetCodeOptions({ isLoading: false, options: assetCodes })

        if (skuNo) {
          const findSku = assetCodes.find((item) => item.skuNo === skuNo)
          store.dispatch(
            setFieldValue({
              key: 'formData.skuNoOption',
              value: findSku,
            }),
          )
        }

        if (assetData && assetData?.length > 0) {
          const selectedSku = assetData.map((item) => item?.skuNo)
          const filteredSkuOptions = assetCodes.filter((item) => {
            return !selectedSku.includes(item?.skuNo) || item?.skuNo === skuNo
          })
          setAssetCodeOptions({ isLoading: false, options: filteredSkuOptions })
        }
      }
    }
    store.dispatch(stopLoading())
  }

  let productOptions = { isLoading: false, options: [] }
  if (productMasterOption) {
    const productWarehouseOptions = productMasterOption?.options.filter(
      (item) => {
        let HasThisProduct = false
        if (item?.productWarehouse && item?.productWarehouse?.length > 0) {
          HasThisProduct = item?.productWarehouse?.find(
            (ph) => ph?.warehouse?.uuid === from?.value,
          )
        }
        return HasThisProduct
      },
    )
    productOptions = {
      isLoading: false,
      options: productWarehouseOptions,
    }
  }

  return (
    <>
      <Box display="flex" mb={2} gap={3} alignItems="start">
        {type === assetTypeEnum.SKU && (
          <CustomDropdown
            required
            labelName="ชื่อทรัพย์สิน"
            fieldName="productStockAsset"
            placeholder="เลือกทรัพย์สิน"
            isCustomOption={true}
            customOptions={productOptions}
            handleExtraChange={getSkuNo}
          />
        )}
        {type === assetTypeEnum.ASSET && (
          <CustomDropdown
            required
            labelName="ชื่อทรัพย์สิน"
            fieldName="productAsset"
            placeholder="เลือกทรัพย์สิน"
            isCustomOption={true}
            customOptions={productOptions}
            handleExtraChange={getSkuNo}
          />
        )}
        {type === assetTypeEnum.LICENSE && (
          <CustomDropdown
            required
            labelName="ชื่อทรัพย์สิน"
            fieldName="productLicense"
            placeholder="เลือกทรัพย์สิน"
            isCustomOption={true}
            customOptions={productOptions}
            handleExtraChange={getSkuNo}
          />
        )}
        <CustomDropdown
          required
          labelName="รหัสทรัพย์สิน"
          fieldName="skuNoOption"
          placeholder="เลือกรหัสทรัพย์สิน"
          isCustomOption={true}
          customOptions={assetCodeOptions}
        />
      </Box>
      <Box display="flex" gap={3} justifyContent="end" mt={3}>
        <CancelButton onClick={() => openStockDrawer(false)} />
        <SubmitButton
          disabled={!hasPermissionCreate}
          handleSubmitClick={() =>
            handleSubmitDrawer(drawerFormValidationSkuNotImport)
          }
        />
      </Box>
    </>
  )
}

export default Form
