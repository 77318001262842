import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'

import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../../components/FilterDrawer/Styled'
import { arrayCheckbox, defaultFilter } from './model'
import { handleChange, handleFiltering } from './events'
import { getQueryParams } from '../../../../utils/lib'
import { getInitailTypeList } from '../events'

const FilterDrawer = ({ open, table }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const { id } = useParams()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    const moduleType = getQueryParams('moduleType')
    let filter = { ...defaultFilter }
    if (moduleType) {
      const res = getInitailTypeList(moduleType)
      filter = {
        ...filter,
        moduleTypeChecked: true,
        moduleType: { ...filter.moduleType, ...res.checked },
      }
    }
    setFilter(filter)
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(handleFiltering(table, filter, id))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type === 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.type === 'checkbox' && (
                <StyledList>
                  <ListSubCheckbox
                    filter={stateFilter}
                    setFilter={setFilter}
                    list={item.list}
                    listKey={item.nameInput}
                  />
                </StyledList>
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListSubCheckbox = ({ filter, setFilter, list, listKey }) => {
  const isDisableSub = useMemo(
    () => Object.values(filter.fileType).some((item) => item),
    [filter.fileType],
  )
  const isDisableType = useMemo(() => {
    const obj = { ...filter[listKey] }
    if (obj.uploadFileChecked) delete obj.uploadFileChecked
    return Object.values(obj).some((item) => item)
  }, [filter[listKey]])
  const isDisableStatus = useMemo(() => {
    return filter.status.inProgressChecked
  }, [filter.status.inProgressChecked])

  return list.map((item, index) => {
    const disabledModule =
      listKey === 'moduleType' &&
      item.name !== 'uploadFileChecked' &&
      isDisableSub
    const disabledStatus =
      listKey === 'status' &&
      item.name !== 'inProgressChecked' &&
      isDisableStatus
    return (
      <>
        <FormControlLabel
          key={index}
          label={item.label}
          control={
            <Checkbox
              inputProps={{ 'data-testid': `check-${item.name}` }}
              disabled={disabledModule || disabledStatus}
              name={item.name}
              checked={filter[listKey][item.name]}
              onChange={(e) => {
                handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  listKey,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />

        {item.list && filter[listKey][item.name] && (
          <StyledList>
            {item.list.map((sub, sIndex) => (
              <FormControlLabel
                key={sIndex}
                label={sub.label}
                control={
                  <Checkbox
                    inputProps={{ 'data-testid': `check-${sub.name}` }}
                    disabled={isDisableType}
                    name={sub.name}
                    checked={filter.fileType[sub.name]}
                    onChange={(e) => {
                      handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                        listKey: 'fileType',
                        filter,
                        setFilter,
                      })
                    }}
                  />
                }
              />
            ))}
          </StyledList>
        )}
      </>
    )
  })
}
