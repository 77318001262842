import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import { setOnEdit } from '../../../../../redux/slices/table2'
import { StyledBox } from '../Styled'

const Index = ({ row, minWidth, rowIndex, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const editUrl = `${condition.editPath}/${row.trainingPlanUuid}/${row.courseTestAndEvaUuid}`
  return (
    <StyledBox minWidth={minWidth}>
      {row.canEdit && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Index
