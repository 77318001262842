import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { resetRowsPerPage, setSearchText } from '../../../../redux/slices/table'

// export const handleClickOpenDrawer = (key) => {
//   if (key === 'examinationField') {
//     toggleExamDrawer(true)
//   }
// }

export const resetDrawerCheckbox = (key, objValue) => {
  store.dispatch(
    setFieldValue({
      key: `formData.manageProfileDashboard.${key}`,
      value: objValue,
    }),
  )
}

export const toggleAgencyDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `manageProfileDashboard.agencyDrawer.isOpen`,
      value: value,
    }),
  )
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `manageProfileDashboard.filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const clearDrawerTable = () => {
  store.dispatch(setSearchText(''))
  store.dispatch(resetRowsPerPage())
}
