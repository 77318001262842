import { shallowEqual, useSelector } from 'react-redux'
import Select from '../../../../components/Input/Select'
import useTheme from '@mui/system/useTheme'
import { useDispatch } from 'react-redux'
import { setStationUuid } from '../../../../redux/slices/roomManagement/Dashboard'
import _ from 'lodash'

export const SelectStation = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { stationUuid, stationList } = useSelector(
    (state) => ({
      stationUuid: state.roomManagement.stationUuid,
      stationList: state.roomManagement.stationList,
    }),
    shallowEqual,
  )

  return (
    !_.isNil(stationUuid) && (
      <Select
        selectSx={{
          background: theme.palette?.common?.white,
          borderRadius: 1,
        }}
        dataTestid="select-station"
        id="station"
        name="station"
        placeholder="กรุณาเลือก"
        options={stationList}
        showTextError={false}
        value={stationUuid}
        defaultValue=""
        handleChange={(e) => {
          const value = _.get(e, 'target.value', '')
          dispatch(setStationUuid(value))
        }}
        // textError={_.get(errors, `information.stationUuid`, '')}
      />
    )
  )
}
