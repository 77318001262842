export const FORM_TAB = { learningPath: 'learningPath', setting: 'setting' }

export const FORM_TAB_TH = { learningPath: 'Learning path', setting: 'ตั้งค่า' }

export const tabs = [
  { label: FORM_TAB_TH.learningPath, value: FORM_TAB.learningPath },
  { label: FORM_TAB_TH.setting, value: FORM_TAB.setting },
]

export const courseTabFields = [
  'name',
  'nameForBanner',
  'coverImage',
  'eLearningLearningPathCourses',
]

export const settingTabFields = [
  'periodStart',
  'dueDate',
  'setting',
  'levelOfLearner',
  'acquiredSkill',
  'productType',
]
