import { Typography } from '@mui/material'
import { StyledCard } from '../../../Styled'
import { FinancialSummary } from './FinancialSummary'
import { MonthlyFinancialList } from './MonthlyFinancialList'
import { shallowEqual, useSelector } from 'react-redux'

export const BudgetDetails = ({ type = 'edit' }) => {
  const { yearBudget } = useSelector(
    (state) => ({
      yearBudget: state.crud.formData.yearBudget,
    }),
    shallowEqual,
  )

  return (
    <StyledCard>
      <Typography variant="h6">
        รายละเอียด Budget (ปี {yearBudget?.value})
      </Typography>
      <FinancialSummary />
      <MonthlyFinancialList type={type} />
    </StyledCard>
  )
}
