export const arrayStatus = [
  { label: 'มาเรียน', name: 'attendChecked' },
  { label: 'ไม่มาเรียน', name: 'notAttendChecked' },
  { label: 'รอเช็คชื่อ', name: 'waitCheckChecked' },
  { label: 'ขาดเรียน', name: 'absentChecked' },
]

export const arrayCheckbox = [
  {
    label: 'Agent Code',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: 'ค้นหา ID ผู้สมัคร',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const defaultFilter = {
  agentCodeChecked: false,
  agentCode: '',
  statusChecked: false,
  status: {
    attendChecked: false,
    notAttendChecked: false,
    waitCheckChecked: false,
    absentChecked: false,
  },
}
