import React from 'react'
import { Typography, TableCell, Tooltip } from '@mui/material'
import _ from 'lodash'
import useTheme from '@mui/system/useTheme'
import { handleLabelCustomColumn } from '../../handler/handleLabelCustomColumn'

export const TableCellHeader = ({ column }) => {
  const theme = useTheme()

  const type = _.get(column, 'type', 'default')
  const showTooltip = type === 'custom'
  const labelCustomColumn = handleLabelCustomColumn(column)

  return (
    <Tooltip
      title={
        showTooltip ? (
          <Typography variant="tooltip">{labelCustomColumn}</Typography>
        ) : null
      }
      followCursor
      componentsProps={{
        tooltip: {
          sx: {
            background: theme?.palette?.primary?.main,
            color: theme?.palette?.common?.white,
            boxShadow: theme?.palette?.boxShadow?.main,
          },
        },
      }}
    >
      <TableCell
        key={column?.id}
        align={column?.align}
        style={{
          minWidth: column?.minWidth,
        }}
      >
        {column?.label}
      </TableCell>
    </Tooltip>
  )
}
