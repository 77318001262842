import { downloadTemplate } from '../../../../../services/inventory'
import { store } from '../../../../../App'
import { PRODUCT_TYPE_TRANSFER } from '../../../../../constants/stock'
import _ from 'lodash'

export const onDownloadTemplate = async () => {
  const { formData } = store.getState().crud
  await store.dispatch(
    downloadTemplate({
      productType: PRODUCT_TYPE_TRANSFER[formData.type],
      from: _.get(formData, 'from.value'),
    }),
  )
}
