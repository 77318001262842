import { loadingDialog, stopLoadingDialog } from '../../../../redux/slices/dialog'
import { store } from '../../../../App'
import { handleSuccessfulDelete } from './handleSuccessfulDelete'
import { handleFormSubmissionError } from '../../../../components/CRUD/handler/handleProcessSubmitForm'
import { deleteBudgetManagement } from '../../../../services/budgetManagement'
import _ from 'lodash'

export const handleProcessDelete = async (uuid) => {
  store.dispatch(loadingDialog())

  try {
    const response = await store.dispatch(deleteBudgetManagement({ uuid }))
    const payload = _.get(response, 'meta.requestStatus', 'rejected')

    if (payload === 'fulfilled') {
      handleSuccessfulDelete()
    }
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    store.dispatch(stopLoadingDialog())
  }
}
