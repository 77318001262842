import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledUploadHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(7.5)}`,
}))

export const StyledCircularBg = styled((props) => (
  <CircularProgress {...props} variant="determinate" value={100} />
))(({ theme }) => ({
  color: theme.palette?.text?.mercury,
}))

export const StyledLabelPercent = styled(Typography)(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const StyledImg = styled('img')(({ theme }) => ({
  width: 46,
  height: 46,
  margin: `0 ${theme.spacing(1.5)}`,
}))

export const StyledIconBox = styled(Box)(({ theme }) => ({
  width: 46,
  height: 46,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette?.background?.paper,
  border: ' 1px solid #ECECEC',
  borderRadius: theme.spacing(0.5),
}))

export const StyledDragHolder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(0,-50%)',
  height: 24,
  svg: { color: theme.palette?.grey?.grey400, cursor: 'pointer' },
}))

export const shouldForwardProp = {
  shouldForwardProp: (props) => props !== 'isDragReject',
}

export const StyledUploadContainer = styled(
  Box,
  shouldForwardProp,
)(({ theme, isDragReject }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
  padding: theme.spacing(1),
  border: '2px dashed',
  borderColor: isDragReject ? 'red' : 'rgba(0, 0, 0, 0.12)',
  background: isDragReject ? 'rgba(255, 0, 0, 0.12)' : '#fff',
  borderRadius: theme.spacing(1),
}))

export const StyledColumnTextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}))

export const StyledInputHidden = styled('input')({ display: 'none' })

export const StyledDeleteImage = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  background: theme.palette?.error?.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  cursor: 'pointer',
  borderRadius: '100%',
  width: 20,
  height: 20,
  top: 30,
  left: 'calc(50% - 120px)',
  svg: { color: theme.palette?.background?.paper, fontSize: '12px' },
}))

export const StyledUploadItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})(({ theme, isError }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
  background: isError ? '#FFF3F3' : '#F6F9FF',
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(7.5)} ${theme.spacing(1)} 42px`,
}))

export const StyledImageDisplay = styled('img')(({ theme }) => ({
  width: 69,
  height: 46,
  borderRadius: theme.spacing(0.5),
  objectFit: 'cover',
  cursor: 'pointer',
  border: `1px solid #ccc`,
}))
