import React from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledCardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}))

export const StyledFooterText = styled(Typography)({ textAlign: 'right' })

export const StyledContentRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& > .MuiTypography-root#label': { width: 336, minWidth: 336 },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const StyledContainerChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  flexWrap: 'wrap',
}))

export const StyledOutlinedChip = styled((props) => (
  <Chip variant="outlined" {...props} />
))(({ theme }) => ({
  fontSize: '17px',
  height: 24,
  borderColor: theme?.palette?.other?.outlined,
  span: { padding: `0 10px` },
}))

export const StyledBtnMore = styled((props) => (
  <Link underline="none" {...props} />
))(() => ({ display: 'flex', alignItems: 'center', cursor: 'pointer' }))
