import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { filtersDrawerEnum, overviewEnum } from '../enum/enum'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  store.dispatch(
    setFieldValue({
      key: 'eContentDashboard.filtersDrawer',
      value: filtersDrawerEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eContentDashboard.overview',
      value: overviewEnum,
    }),
  )
  await fetchOverview()
}
