export const validateVideo = (url) => {
  if (!url) return false
  const value = url.slice(8).split('/')[0]
  return (
    value === 'vimeo.com' ||
    value === 'www.youtube.com' ||
    value === 'youtu.be' ||
    value === 'player.vimeo.com'
  )
}
