import * as yup from 'yup'
import _ from 'lodash'
import { alphabets, defaultValue } from '../../../constants/eTesting'
import { getPlainText } from '../../../utils/lib'

const fillInBlank = 'FILL_IN_BLANK'
const type = defaultValue.questionType

export const schema = yup.object({
  questions: yup
    .array()
    .test(
      'check-question',
      'กรุณาเพิ่มคำถาม',
      (value) => value && value.length > 0
    )
    .of(
      yup.object().shape({
        title: yup
          .string()
          .max(3000, (value) => {
            const listIndex = splitPath(value)
            return `คำถามข้อที่ ${
              +listIndex[1] + 1
            } ระบุได้ไม่เกิน 3000 ตัวอักษร`
          })
          .test(
            'check-title-required',
            (value) => {
              const listIndex = splitPath(value)
              return `กรุณากรอกคำถามข้อที่ ${+listIndex[1] + 1}`
            },
            (value) => {
              return value !== ''
            }
          ),
        subTitle: yup
          .string()
          .test(
            'max-length',
            (value) => {
              const listIndex = splitPath(value)
              return `ข้อความข้อที่ ${
                +listIndex[1] + 1
              } ระบุได้ไม่เกิน 3000 ตัวอักษร`
            },
            (value, context) => {
              const questionType = _.get(context, 'parent.questionType', '')
              return (
                !questionType?.includes(fillInBlank) ||
                getPlainText(value).length <= 3000
              )
            }
          )
          .test(
            'check-title-required',
            (value) => {
              const listIndex = splitPath(value)
              return `กรุณากรอกข้อความข้อที่ ${+listIndex[1] + 1}`
            },
            (value, context) => {
              const questionType = _.get(context, 'parent.questionType', '')
              return !questionType?.includes(fillInBlank) || value !== ''
            }
          ),
        answers: yup
          .array()
          .test('count-answer', 'กรุณาเพิ่มตัวเลือก', (value, context) => {
            if (validateQuestionType(context)) return true
            const questionType = _.get(context, 'parent.questionType', '')
            const subTitle = _.get(context, 'parent.subTitle', '')
            const fillInBlankCond =
              questionType?.includes(fillInBlank) &&
              (_.isEmpty(subTitle) || getPlainText(subTitle).length > 3000)
            return fillInBlankCond || (value && value.length > 0)
          })
          .test(
            'match-sequence-count-answer',
            (value) => {
              const listIndex = splitPath(value)
              return `คำถามข้อที่ ${
                +listIndex[1] + 1
              } กรุณาสร้างตัวเลือกมากกว่า 1 ตัวเลือก`
            },
            (value, context) => {
              const questionType = _.get(context, 'parent.questionType', '')
              const notMatchOrSequence =
                questionType !== type.sequence && questionType !== type.matching
              return notMatchOrSequence || (value && value.length > 1)
            }
          )
          .test(
            'check-answer',
            (value) => {
              const listIndex = splitPath(value)
              return `กรุณาเลือกคำตอบที่ถูกต้อง ให้คำถามข้อที่ ${
                +listIndex[1] + 1
              } `
            },
            (value, context) => {
              if (validateQuestionType(context)) return true
              const questionType = _.get(context, 'parent.questionType', '')
              return (
                questionType?.includes(fillInBlank) ||
                questionType === type.matching ||
                value.some((e) => e.isCorrect)
              )
            }
          )
          .of(
            yup.object().shape({
              title: yup
                .string()
                .test(
                  'check-answer-length-all',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `ตัวเลือกที่ ${+listIndex[3] + 1} ของคำถามข้อที่ ${
                      +listIndex[1] + 1
                    } ระบุได้ไม่เกิน 3000 ตัวอักษร`
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    return (
                      exceptType.includes(questionType) || value.length <= 3000
                    )
                  }
                )
                .test(
                  'check-answer-length-fill-in-blank',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `ตัวเลือกที่ ${+listIndex[3] + 1} ของคำถามข้อที่ ${
                      +listIndex[1] + 1
                    } ระบุได้ไม่เกิน 255 ตัวอักษร`
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    return (
                      !exceptType.includes(questionType) || value.length <= 255
                    )
                  }
                )
                .test(
                  'check-answer-required',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `กรุณากรอกตัวเลือกที่ ${
                      +listIndex[3] + 1
                    } ของคำถามข้อที่ ${+listIndex[1] + 1} `
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    if (
                      questionType === type.freeText ||
                      questionType === type.trueFalse
                    ) {
                      return true
                    }
                    return value !== ''
                  }
                )
                .test(
                  'check-answer-required-true-fasle',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `กรุณากรอกคำถามย่อยข้อที่ ${
                      +listIndex[3] + 1
                    } ของคำถามข้อที่ ${+listIndex[1] + 1} `
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    if (questionType !== type.trueFalse) return true
                    return value !== ''
                  }
                ),
              question: yup
                .string()
                .max(255, (value) => {
                  const listIndex = splitPath(value)
                  return `ช่องคำถามในคู่ที่ ${
                    +listIndex[3] + 1
                  } ของคำถามข้อที่ ${
                    +listIndex[1] + 1
                  } ระบุได้ไม่เกิน 255 ตัวอักษร`
                })
                .test(
                  'matching-require',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `กรุณากรอกคำถามคู่ที่ 1 ของคำถามข้อที่ ${
                      +listIndex[1] + 1
                    } `
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    const sequence = _.get(context, 'parent.sequence', 0)
                    if (questionType !== type.matching || sequence > 1) {
                      return true
                    }
                    return value !== ''
                  }
                ),
              subAnswers: yup
                .array()
                .test(
                  'check-answer',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `กรุณาเลือกคำตอบที่ถูกต้อง ให้คำถามข้อที่ ${
                      +listIndex[1] + 1
                    } `
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    return (
                      questionType !== type.fillInBlankChoice ||
                      value.some((e) => e.isCorrect)
                    )
                  }
                )
                .of(
                  yup.object().shape({
                    title: yup
                      .string()
                      .test(
                        'check-answer-length-fill-in-blank',
                        (value) => {
                          const listIndex = splitPath(value)
                          return `ตัวเลือกที่ ${
                            +listIndex[5] + 1
                          } ของช่องว่าง ${
                            alphabets[listIndex[3]]
                          } ของคำถามข้อที่ ${
                            +listIndex[1] + 1
                          } ระบุได้ไม่เกิน 255 ตัวอักษร`
                        },
                        (value) => value.length <= 255
                      )
                      .test(
                        'check-answer-required',
                        (value) => {
                          const listIndex = splitPath(value)
                          return `กรุณากรอกตัวเลือกที่ ${
                            +listIndex[5] + 1
                          } ของช่องว่าง ${
                            alphabets[listIndex[3]]
                          } ของคำถามข้อที่ ${+listIndex[1] + 1} `
                        },
                        (value) => value !== ''
                      ),
                  })
                ),
              booleanAns: yup
                .boolean()
                .nullable()
                .test(
                  'booleanAns-request-true-false',
                  (value) => {
                    const listIndex = splitPath(value)
                    return `กรุณาเลือกคำตอบข้อที่ ${
                      +listIndex[3] + 1
                    } ของคำถามข้อที่ ${+listIndex[1] + 1} `
                  },
                  (value, context) => {
                    const questionType = _.get(
                      context,
                      'parent.questionType',
                      ''
                    )
                    if (questionType !== type.trueFalse) {
                      return true
                    }
                    return !_.isNull(value)
                  }
                ),
            })
          ),
      })
    ),
  setting: yup.object().shape({
    startDate: yup
      .string('กรุณาระบุวันเริ่มต้นใช้งาน')
      .nullable()
      .required('กรุณาระบุวันเริ่มต้นใช้งาน'),
  }),
})

export const draftSchema = yup.object({
  questions: yup.array().of(
    yup.object().shape({
      title: yup.string().max(3000, (value) => {
        const listIndex = splitPath(value)
        return `คำถามข้อที่ ${+listIndex[1] + 1} ระบุได้ไม่เกิน 3000 ตัวอักษร`
      }),
      answers: yup.array().of(
        yup.object().shape({
          title: yup.string().max(3000, (value) => {
            const listIndex = splitPath(value)
            return `ตัวเลือกที่ ${+listIndex[3] + 1} ของคำถามข้อที่ ${
              +listIndex[1] + 1
            } ระบุได้ไม่เกิน 3000 ตัวอักษร`
          }),
        })
      ),
    })
  ),
  setting: yup.object().shape({
    startDate: yup
      .string('กรุณาระบุวันเริ่มต้นใช้งาน')
      .nullable()
      .required('กรุณาระบุวันเริ่มต้นใช้งาน'),
  }),
})

export const splitPath = (value) => {
  const path = _.get(value, 'path', '')
  const splitValue = path ? path.split(/(\d+)/) : []
  return splitValue
}

export const validateQuestionType = (context) => {
  const getQuestionType = _.get(context, 'parent.questionType', '')
  if (getQuestionType === '') return true
  const isCheck = context.parent.questionType === type.freeText
  if (isCheck) return true
  return false
}

export const exceptType = [
  type.fillInBlankChoice,
  type.fillInBlankDrag,
  type.sequence,
  type.matching,
]
