import {
  setIsOpenExaminerFilterDrawer,
  setIsOpenHistoryFilterDrawer,
} from '../../../../../redux/slices/eExamination/list'
import { handleOpenImportExaminerTemplateDialog } from '../../../../../redux/slices/eExamination/list/event'
import { IMPORT_EXAMINER_TYPE } from '../../../../../constants/eExamination'
import Add from '@mui/icons-material/Add'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import HistoryIcon from '@mui/icons-material/History'

export const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef.current && anchorRef.current.contains(event.target)) {
    return
  }

  setOpen(false)
}

export const getMoreOptions = () => {
  let moreOptions = [
    {
      text: 'เพิ่มรายชื่อผู้สอบ',
      method: 'add',
    },
    {
      text: 'อัปโหลดผลสอบ',
      method: 'import',
    },
    {
      text: 'ตั้งค่าตารางสอบ',
      method: 'setting',
    },
    {
      text: 'ประวัติการเปลี่ยนแปลง',
      method: 'history',
    },
  ]

  return moreOptions
}

export const getOptionsIcon = (method) => {
  switch (method) {
    case 'add':
      return <Add />
    case 'import':
      return <UploadFileOutlinedIcon />
    case 'setting':
      return <SettingsIcon />
    case 'history':
      return <HistoryIcon />
    default:
      return <></>
  }
}

export const onOptionClick =
  (method, examUuid = null) =>
  (dispatch) => {
    switch (method) {
      case 'add':
        dispatch(setIsOpenExaminerFilterDrawer(true))
        break
      case 'import':
        handleOpenImportExaminerTemplateDialog(IMPORT_EXAMINER_TYPE.TEST_RESULT)
        break
      case 'setting':
        window.open(`/e-examination/form/${examUuid}`, '_blank').focus()
        break
      case 'history':
        dispatch(setIsOpenHistoryFilterDrawer(true))
        break
      default:
        break
    }
  }
