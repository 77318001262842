import React, { useEffect } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import useLocalStorage from '@rehooks/local-storage'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { StyledHeaderContainer } from '../Styled'
import DropdownButton from './DropdownButton'
import { handleAddLearner, handleAnnounceLearner } from './events'
import {
  hasPermissionDefaultAndMonthlyPlanArea,
  hasPermissionManageByAdminAll,
} from '../../../../utils/lib'
import { handleSuccess } from '../AddLearnerDialog/events'
import { StyledButton } from './Styled'

const Header = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const [user] = useLocalStorage('user')
  const { selected, rows, isSuccess, isCancel, manageClassDetail } =
    useSelector(
      (state) => ({
        selected: state.table.table.selected,
        rows: state.table.table.rows,
        isSuccess: state.manageClassLearner.isSuccess,
        isCancel: state.manageClassDetail.isCancel,
        manageClassDetail: state.manageClassDetail.data,
      }),
      shallowEqual
    )
  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: manageClassDetail,
  })
  const hasPermissionAdminAll = hasPermissionManageByAdminAll({
    user,
    manageClass: manageClassDetail,
  })
  const isClosed = _.get(manageClassDetail, 'status', 'UNASSIGN') == 'CLOSED'
  const isHavePermission =
    (!isClosed && hasPermissionEdit) || hasPermissionAdminAll // only admin all can add learner to closed class
  const isDisable = !isHavePermission || isCancel
  const isAnnounceDisable = isDisable || selected.length === 0

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <StyledHeaderContainer>
      <StyledButton
        data-testid="btn-announce-learner"
        variant="canceled"
        onClick={() => dispatch(handleAnnounceLearner(uuid, selected, rows))}
        size="m"
        disabled={isAnnounceDisable}
        startIcon={
          <img
            src={
              isAnnounceDisable
                ? '/icon/ic_megaphone_gray.svg'
                : '/icon/ic_megaphone_white.svg'
            }
          />
        }
      >
        ประกาศผู้มีสิทธิ์เข้าเรียน
      </StyledButton>

      <Button
        data-testid="btn-add-learner"
        variant="contained"
        onClick={() => dispatch(handleAddLearner())}
        size="m"
        disabled={isDisable}
        startIcon={<Add />}
      >
        เพิ่มผู้เรียน
      </Button>

      <DropdownButton />
    </StyledHeaderContainer>
  )
}
export default Header
