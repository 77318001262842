import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: '0 0 5px 75px',
  marginBottom: theme.spacing(1),
}))
