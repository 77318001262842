import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'

import ContentOther from '../ContentOther'
import AddQuestionOther from './AddQuestionOther'

import * as Styled from './Styled'
import * as events from './events'
import { TooltipForm } from '../../Styled'

const Index = ({ text, type }) => {
  const dispatch = useDispatch()

  const { toggleQuestions, isPersonal, eEvaluationType, disableForm } =
    useSelector(
      (state) => ({
        toggleQuestions: state.eEvaluationForm.toggleQuestions[type],
        disableForm:
          state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
        isPersonal: _.get(
          state,
          `eEvaluationForm.${type}[0].isPersonal`,
          false,
        ),
        eEvaluationType: state.eEvaluationForm.eEvaluationType,
      }),
      shallowEqual,
    )

  return (
    <>
      <Styled.CardHeader isQuestion={toggleQuestions}>
        <Styled.BoxContent isLecturer={type == 'lecturerAssessments'}>
          <Typography
            variant="h6"
            color={toggleQuestions ? 'unset' : 'action.disabled'}
          >
            {text}
          </Typography>
          <Styled.BoxRight>
            {type === 'lecturerAssessments' && (
              <>
                <Checkbox
                  disabled={!toggleQuestions || disableForm}
                  name="lecturerChecked"
                  checked={isPersonal}
                  onClick={(e) =>
                    dispatch(
                      events.handleChange({
                        key: `${type}[0].isPersonal`,
                        value: _.get(e, 'target.checked', false),
                      }),
                    )
                  }
                />
                <Typography
                  variant="body1"
                  color={!toggleQuestions ? 'action.disabled' : 'unset'}
                  sx={{ mr: disableForm ? 0 : 3, mt: 0.5 }}
                >
                  ประเมินวิทยากรรายบุคคล
                </Typography>
              </>
            )}
            {!disableForm && (
              <>
                {eEvaluationType === 'CLASS' && (
                  <>
                    {!toggleQuestions ? (
                      <TooltipForm
                        placement="bottom"
                        title={<Typography variant="tooltip">เพิ่ม</Typography>}
                        onClick={() =>
                          !disableForm &&
                          dispatch(
                            events.handleAddQuestion(type, toggleQuestions),
                          )
                        }
                      >
                        <IconButton sx={{ ml: 'auto' }}>
                          <AddCircleOutlineOutlined color="primary" />
                        </IconButton>
                      </TooltipForm>
                    ) : (
                      <TooltipForm
                        placement="bottom"
                        title={<Typography variant="tooltip">ลบ</Typography>}
                        onClick={() =>
                          !disableForm &&
                          dispatch(
                            events.handleDeleteQuestion(type, toggleQuestions),
                          )
                        }
                      >
                        <IconButton sx={{ ml: 'auto' }}>
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </TooltipForm>
                    )}
                  </>
                )}
              </>
            )}
          </Styled.BoxRight>
        </Styled.BoxContent>
      </Styled.CardHeader>

      {type === 'questions' && toggleQuestions && (
        <>
          <ContentOther />
          <Styled.CardHeader>
            <AddQuestionOther />
          </Styled.CardHeader>
        </>
      )}
    </>
  )
}

export default Index
