import { crudConfig } from '../../crudConfig'

export const headerCells = () => {
  return [
    {
      id: 'status',
      label: '',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'isPinned',
      label: '',
      hideSortIcon: true,
    },
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'name',
      label: 'ชื่อเรื่อง',
      disablePadding: false,
    },
    {
      id: 'category.name',
      label: 'หมวดหมู่',
      disablePadding: false,
    },
    {
      id: 'subCategory.name',
      label: 'หมวดหมู่ย่อย',
      disablePadding: false,
    },
    {
      id: 'publishDate',
      label: 'วันที่เผยแพร่',
      disablePadding: false,
      width: '180px',
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
      editPath: `${crudConfig.modulePath}/form`,
    },
  ]
}
