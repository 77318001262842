import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleOnFocus = (event) => {
  store.dispatch(
    setFieldValue({
      key: 'oldSelectedFrom',
      value: event.target.value,
    }),
  )
}
