import React from 'react'
import CardQuestion from '../CardQuestion'
import Content from '../Content'

const Index = () => {
  return (
    <>
      <CardQuestion
        text="แบบประเมินการบริหารจัดการหลักสูตร"
        type="courseManagementAssessments"
      />
      <Content type="courseManagementAssessments" />
    </>
  )
}

export default Index
