import _ from 'lodash'
import { initialState } from './model'

const { createSlice } = require('@reduxjs/toolkit')

const dialogSlice2 = createSlice({
  name: 'dialog2',
  initialState,
  reducers: {
    openDialog: (state, { payload }) => {
      state.type = _.get(payload, 'type', 'confirm')
      state.isOpen = true
      state.isLoading = _.get(payload, 'isLoading', false)
      state.isConfirmation = true
      state.title = _.get(payload, 'title', '')
      state.icon = _.get(payload, 'icon', undefined)
      state.message = _.get(payload, 'message', '')
      state.content = _.get(payload, 'content', null)
      state.maxWidth = _.get(payload, 'maxWidth', null)
      state.agreeText = _.get(payload, 'agreeText', undefined)
      state.disableButton = _.get(payload, 'disableButton', false)
      state.handleConfirm = _.get(payload, 'handleConfirm', null)
      state.handleCancel = _.get(payload, 'handleCancel', null)
      state.handleError = _.get(payload, 'handleError', null)
      state.colorDisagreeText = _.get(payload, 'colorDisagreeText', undefined)
      state.colorAgreeText = _.get(payload, 'colorAgreeText', undefined)
      state.isCloseDialog = _.get(payload, 'isCloseDialog', true)
      state.fullWidth = _.get(payload, 'fullWidth', true)
      state.sx = _.get(payload, 'sx', {})
      state.dataField = _.get(payload, 'dataField', '')
    },
    openSuccessDialog: (state, { payload }) => {
      state.type = 'success'
      state.isOpen = true
      state.isConfirmation = false
      state.isLoading = false
      state.disableButton = false
      state.title = _.get(payload, 'title', 'สำเร็จ')
      state.content = _.get(payload, 'content', null)
      state.message = _.get(payload, 'message', 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว')
      state.handleConfirm = _.get(payload, 'handleConfirm', null)
      state.agreeText = _.get(payload, 'agreeText', undefined)
      state.maxWidth = _.get(payload, 'maxWidth', null)
    },
    openErrorDialog: (state, { payload }) => {
      state.type = _.get(payload, 'type', 'fail')
      state.isCloseDialog = false
      state.isOpen = true
      state.isConfirmation = false
      state.isLoading = false
      state.disableButton = false
      state.icon = _.get(payload, 'icon', undefined)
      state.title = _.get(payload, 'title', payload.name)
      state.message = _.get(payload, 'message', payload.data?.message)
      state.handleError = _.get(payload, 'handleError', null)
      state.maxWidth = _.get(payload, 'maxWidth', null)
      state.sx = _.get(payload, 'sx', {})
      state.content = _.get(payload, 'content', null)
    },
    closeDialog: (state) => {
      state.isOpen = false
      state.isLoading = false
    },
    loadingDialog: (state) => {
      state.isCloseDialog = false
      state.isLoading = true
    },
    stopLoadingDialog: (state) => {
      state.isCloseDialog = true
      state.isLoading = false
    },
    setEnableButton: (state) => {
      state.disableButton = false
    },
    setDisableButton: (state) => {
      state.disableButton = true
    },
    setHandleError: (state, { payload }) => {
      state.handleError = payload
    },
  },
})

export const {
  openDialog,
  openSuccessDialog,
  openErrorDialog,
  closeDialog,
  loadingDialog,
  stopLoadingDialog,
  setEnableButton,
  setDisableButton,
  setHandleError,
} = dialogSlice2.actions

export default dialogSlice2.reducer
