import { memo } from 'react'
import { Box, Typography } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

const CustomRadioInput = memo(
  ({
    disabled = false,
    fieldName,
    labelName,
    isRow,
    required = false,
    onChange,
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)

    const { crudConfig } = useSelector(
      (state) => ({ crudConfig: state.crud.crudConfig }),
      shallowEqual,
    )

    const { masterCategory: master } = useSelector(
      (state) => ({
        masterCategory: state.crud.masterData[fieldName],
      }),
      shallowEqual,
    )

    function handleValueChange(event, value) {
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: value }))
    }

    if (_.isUndefined(master)) {
      return <div />
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body2" color={'text.secondary'}>
              {labelName}
            </Typography>
            {required && (
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            )}
          </Box>
          <RadioGroup
            sx={{ ml: 1 }}
            aria-label={fieldName}
            defaultValue={!_.isEmpty(value) ? value : null}
            name={fieldName}
            id={`${crudConfig.moduleSlug}-${fieldName}`}
            value={!_.isEmpty(value) ? value : null}
            onChange={(e, value) => {
              if (onChange) {
                onChange(e, value)
              }
              handleValueChange(e, value)
            }}
            row={!!isRow}
          >
            {master.options.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item?.value}
                  control={<Radio />}
                  label={item?.label}
                  disabled={disabled}
                />
              )
            })}
          </RadioGroup>
          {isError && (
            <Box
              variant="body2"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mb: isError ? 'unset' : 3,
              }}
            >
              {isError && (
                <Typography
                  sx={{ height: '25px' }}
                  variant="body2"
                  color="error"
                >
                  {message}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </>
    )
  },
)

CustomRadioInput.displayName = 'CustomRadioInputComponent'

export { CustomRadioInput }
