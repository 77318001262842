import dayjs from 'dayjs'

export const examinerDrawerType = {
  EXAM: 'EXAM',
  SRAM: 'SRAM',
}

export const scheduleDrawerEnum = {
  isOpen: false,
  isCompany: true,
  isAssociation: true,
  isOther: true,
  OPEN_REGISTER: true,
  IN_PROGRESS: true,
  CANCEL: true,
  SUCCESS: true,
  CLOSED_REGISTER: true,
  EXAM_ONGOING: true,
  COMPLETED: true,
}

export const rankingDrawerEnum = {
  isOpen: false,
  examType: '',
  dataType: '',
}

export const examinerDrawerEnum = {
  isOpen: false,
  isCompany: true,
  isAssociation: true,
  isOther: true,
  EXAMINEES: true,
  PASS: true,
  NOT_PASS: true,
  ABSENCE: true,
  drawerType: examinerDrawerType.EXAM,
}

export const filtersDrawerEnum = {
  isOpen: false,
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  station: [],
  associationRegion: [],
  associationProvince: [],
  otherRegion: [],
  otherProvince: [],
  filterTotal: 1,
}

export const overviewDataEnum = {
  TOTAL_EXAM_FIELD: 'TOTAL_EXAM_FIELD',
  EXAM_ASSOCIATION: 'EXAM_ASSOCIATION',
  EXAM_STATION: 'EXAM_STATION',
  SCHEDULE_STATION: {
    OPEN: 'SCHEDULE_STATION.OPEN',
    CLOSE: 'SCHEDULE_STATION.CLOSE',
    CANCEL: 'SCHEDULE_STATION.CANCEL',
  },
  SCHEDULE_ASSOCIATION: {
    OPEN: 'SCHEDULE_ASSOCIATION.OPEN',
    CLOSE: 'SCHEDULE_ASSOCIATION.CLOSE',
    CANCEL: 'SCHEDULE_ASSOCIATION.CANCEL',
  },
  SCHEDULE_OTHER: {
    OPEN: 'SCHEDULE_OTHER.OPEN',
    CLOSE: 'SCHEDULE_OTHER.CLOSE',
    CANCEL: 'SCHEDULE_OTHER.CANCEL',
  },
  EXAMINER_STATION: {
    PASS: 'EXAMINER_STATION.PASS',
    NOT_PASS: 'EXAMINER_STATION.NOT_PASS',
    ABSENCE: 'EXAMINER_STATION.ABSENCE',
    EXAMINEES: 'EXAMINER_STATION.EXAMINEES',
    ENROLL: 'EXAMINER_STATION.ENROLL',
  },
  EXAMINER_ASSOCIATION: {
    PASS: 'EXAMINER_ASSOCIATION.PASS',
    NOT_PASS: 'EXAMINER_ASSOCIATION.NOT_PASS',
    ABSENCE: 'EXAMINER_ASSOCIATION.ABSENCE',
    EXAMINEES: 'EXAMINER_ASSOCIATION.EXAMINEES',
    ENROLL: 'EXAMINER_ASSOCIATION.ENROLL',
  },
  EXAMINER_OTHER: {
    PASS: 'EXAMINER_OTHER.PASS',
    NOT_PASS: 'EXAMINER_OTHER.NOT_PASS',
    ABSENCE: 'EXAMINER_OTHER.ABSENCE',
    EXAMINEES: 'EXAMINER_OTHER.EXAMINEES',
    ENROLL: 'EXAMINER_OTHER.ENROLL',
  },
  TOP_SCHEDULE_STATION: 'TOP_SCHEDULE_STATION',
  TOP_ENROLL_STATION: 'TOP_ENROLL_STATION',
  TOP_SCHEDULE_ASSOCIATION: 'TOP_SCHEDULE_ASSOCIATION',
  TOP_ENROLL_ASSOCIATION: 'TOP_ENROLL_ASSOCIATION',
}

export const overviewDataCountEnum = {
  TOTAL_EXAM_FIELD: 0,
  EXAM_ASSOCIATION: 0,
  EXAM_STATION: 0,
  SCHEDULE_STATION: {
    OPEN: 0,
    CLOSE: 0,
    CANCEL: 0,
  },
  SCHEDULE_ASSOCIATION: {
    OPEN: 0,
    CLOSE: 0,
    CANCEL: 0,
  },
  SCHEDULE_OTHER: {
    OPEN: 0,
    CLOSE: 0,
    CANCEL: 0,
  },
  EXAMINER_STATION: {
    PASS: 0,
    NOT_PASS: 0,
    ABSENCE: 0,
    EXAMINEES: 0,
    ENROLL: 0,
  },
  EXAMINER_ASSOCIATION: {
    PASS: 0,
    NOT_PASS: 0,
    ABSENCE: 0,
    EXAMINEES: 0,
    ENROLL: 0,
  },
  EXAMINER_OTHER: {
    PASS: 0,
    NOT_PASS: 0,
    ABSENCE: 0,
    EXAMINEES: 0,
    ENROLL: 0,
  },
  TOP_SCHEDULE_STATION: [],
  TOP_ENROLL_STATION: [],
  TOP_SCHEDULE_ASSOCIATION: [],
  TOP_ENROLL_ASSOCIATION: [],
}
