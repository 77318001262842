import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'

export const postBodyMutation = (data) => {
  return {
    productType: PRODUCT_TYPE.ASSET,
    productName: _.get(data, 'productName', ''),
    category: _.get(data, 'category.value', null),
    subCategory: _.get(data, 'subCategory.value', null),
    statusProduct: _.get(data, 'statusProduct', enumStatus.INACTIVE),
    remark: _.get(data, 'remark', ''),
    uuid: _.get(data, 'uuid', undefined),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    description: _.get(data, 'description', ''),
    unitPrice: _.get(data, 'unitPrice', 0),
    stockValue: _.get(data, 'stockValue', 0),
    total: _.get(data, 'total', 0),
    available: _.get(data, 'available', 0),
  }
}
