import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
// import { validateEPermission } from '../../../utils/lib'
import {
  StyledCardQuestion,
  StyledContainer,
  StyledContent,
  LoadingCircular,
  StyledHeader,
} from '../Styled'
import FilterDrawer from './FilterDrawer'
// import Header from './Header'
import { fetchDataList } from './events'
import { breadcrumbList } from './model'
import AnswerDrawer from './AnswerDrawer/index'

const Question = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search
  // const hasCreatePermission = validateEPermission({
  //   module: 'E_TESTING_SETTING', // mock
  //   permission: 'CREATE',
  // })

  useEffect(() => {
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page,
        status,
        filterProp,
        keyword: searchText,
        setInitial,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page,
          status,
          filterProp,
          keyword: searchText,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">ตอบคำถาม</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbList} />
          </Box>

          {
            // hasCreatePermission  &&
            // <Header />
          }
        </StyledHeader>

        <StyledCardQuestion id="listTable">
          <Table />
        </StyledCardQuestion>

        <FilterDrawer open={isFilterDrawer} table={table} />
        <AnswerDrawer />
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Question
