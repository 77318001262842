import _ from 'lodash'
import { postStatusRcmsChangeByAdmin } from '../../../../services/apiToRcms'
import {
  closeDialog,
  loadingDialog,
  openDialog,
} from '../../../../redux/slices/dialog'
import { resetForm } from '../../../../redux/slices/manageClassLearner'
import * as dayjs from 'dayjs'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(closeDialog())
}

export const handleSubmitAdd = (props) => (dispatch) => {
  const {
    rcmsUuid,
    agent_detail,
    isStatus,
    effectiveDate,
    expiryDate,
    licenseNumber,
    exemptionFlag,
    remark,
    fetchData,
  } = props
  dispatch(loadingDialog())
  const uuidList = _.map(agent_detail, (val) => val['uuid'])
  const body = {
    rcmsUuid: rcmsUuid,
    rcmsDetailUuid: uuidList,
    licenseRcmsStatus: isStatus,
    effectiveDate: null,
    expiryDate: null,
    licenseNumber: null,
    exemptionFlag: null,
    remark: remark,
  }

  if (isStatus === 'ให้') {
    body['effectiveDate'] = effectiveDate
    body['expiryDate'] = !dayjs(expiryDate).isValid() ? null : expiryDate
    body['licenseNumber'] = _.isEmpty(licenseNumber) ? null : licenseNumber
    body['exemptionFlag'] = _.isEmpty(exemptionFlag) ? null : licenseNumber
  }
  const response = dispatch(postStatusRcmsChangeByAdmin(body))
  if (!response.error) {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          fetchData()
          dispatch(closeDialog())
        },
        handleCancel: () => {
          fetchData()
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ผิดพลาด',
        message: 'ระบบขัดข้อง',
        handleConfirm: () => {
          dispatch(closeDialog())
        },
        handleCancel: () => {
          fetchData()
          dispatch(closeDialog())
        },
      })
    )
  }
}
