import { store } from '../../../App'
import {
  startLoadingDashboard,
  stopLoadingDashboard,
  setReduxDashboard,
} from 'src/redux/slices/monthlyPlan'
import { fetchOverviewClass } from '../ClassDashboard/handler/fetchOverviewClass'
import { fetchOverviewWorkload } from '../WorkloadDashboard/handler/fetchOverviewWorkload'

export const fetchOverview = async () => {
  store.dispatch(startLoadingDashboard())
  const {
    dashboard,
    dashboard: { name },
  } = store.getState().monthlyPlan

  let updatedDashboard = { ...dashboard }
  if (name === 'class') {
    const classDashboard = await fetchOverviewClass()
    updatedDashboard = { ...updatedDashboard, ...classDashboard }
  } else if (name === 'workload') {
    const workloadDashboard = await fetchOverviewWorkload()
    updatedDashboard = { ...updatedDashboard, ...workloadDashboard }
  }

  store.dispatch(setReduxDashboard(updatedDashboard))
  store.dispatch(stopLoadingDashboard())
}
