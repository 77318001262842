import _ from 'lodash'
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import { getFormDetail } from '../../../../services/eTesting/form'
import { getFormDetailEva } from '../../../../services/eEvaluation/form'
import {
  setInitialPreview,
  setInitialState,
  setNextQuestion,
  setPreviousQuestion,
  setSubmit,
  stopLoading,
} from '../../eTestingPreview'
import {
  setInititialAssessment,
  setInitialState as setInitialStateEva,
  setNextQuestion as setNextQuestionEva,
  setPreviousQuestion as setPreviousQuestionEva,
  stopLoading as stopLoadingEva,
  setSectionResult,
} from '../../eEvaluationPreview'
import {
  getLatestETestEEva,
  handleEEvaluationProgress,
  handleETestingProgress,
  handleEmbedContentProgress,
  handleUploadFileProgress,
  setLatestETestEEva,
} from './events'
import {
  setModuleProgress,
  setSelectedFile,
  setSelectedLesson,
  setStopModule,
  setVideoState,
  startLessonLoading,
  stopLessonLoading,
} from '.'

export const listenerMiddlewareELearning = createListenerMiddleware()

export const isProgressDiff = (listenerApi, progress) => {
  const { selectedLesson } = listenerApi.getOriginalState().eLearningPreview
  const previousProgress = _.get(selectedLesson, 'progress', '0')
  return Number(previousProgress) !== Number(progress)
}

// handle Embed Content progress
listenerMiddlewareELearning.startListening({
  actionCreator: setVideoState,
  effect: (action, listenerApi) => {
    const { type, payload } = action
    if (type.includes('setVideoState') && _.isNil(payload.played)) return

    const progress = handleEmbedContentProgress(payload)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(Number(progress)))
    }
  },
})

// handle Upload File progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(setSelectedLesson, setSelectedFile),
  effect: (action, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningPreview
    if (selectedLesson.moduleType !== MODULE_TYPE.UPLOAD_FILE) return

    const progress = handleUploadFileProgress(selectedLesson)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(Number(progress)))
    }
  },
})

// handle E-Testing progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(
    setInitialPreview,
    setNextQuestion,
    setPreviousQuestion,
    setSubmit,
  ),
  effect: (action, listenerApi) => {
    const { type } = action
    if (type.includes('setSubmit')) {
      listenerApi.dispatch(setModuleProgress(100))
      return
    }

    const eTestingPreview = listenerApi.getState().eTestingPreview
    const progress = handleETestingProgress(eTestingPreview)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(progress))
    }
  },
})

// handle E-Evaluation progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(
    setInititialAssessment,
    setNextQuestionEva,
    setPreviousQuestionEva,
    setSectionResult,
  ),
  effect: (action, listenerApi) => {
    const { type } = action
    if (type.includes('setSectionResult')) {
      listenerApi.dispatch(setModuleProgress(100))
      return
    }

    const eEvaluationPreview = listenerApi.getState().eEvaluationPreview
    const progress = handleEEvaluationProgress(eEvaluationPreview)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(progress))
    }
  },
})

// handle E-Testing/E-Eva State and Storage
listenerMiddlewareELearning.startListening({
  actionCreator: setSelectedLesson,
  effect: (action, listenerApi) => {
    const oldELearningPreview = listenerApi.getOriginalState().eLearningPreview
    const { eLearningPreview } = listenerApi.getState()
    const oldSelected = _.get(oldELearningPreview, 'selectedLesson', {})
    const selected = _.get(eLearningPreview, 'selectedLesson', {})
    const oldType = _.get(oldELearningPreview, 'selectedLesson.type', '')
    const type = _.get(selected, 'type', '')

    if (oldSelected.isInProgress) {
      if (oldType === MODULE_TYPE.E_TESTING) {
        setLatestETestEEva(listenerApi, 'eTesting')
      }
      if (oldType === MODULE_TYPE.E_EVALUATION) {
        setLatestETestEEva(listenerApi, 'eEvaluation')
      }
    }

    if (type === MODULE_TYPE.E_TESTING) {
      const latestETestState = getLatestETestEEva(listenerApi, 'eTestingUuid')
      if (!_.isEmpty(latestETestState)) {
        listenerApi.dispatch(setInitialState(latestETestState))
        listenerApi.dispatch(stopLoading())
      }
    }

    if (type === MODULE_TYPE.E_EVALUATION) {
      const latestEEvaState = getLatestETestEEva(listenerApi, 'eEvaluationUuid')
      if (!_.isEmpty(latestEEvaState)) {
        listenerApi.dispatch(setInitialStateEva(latestEEvaState))
        listenerApi.dispatch(stopLoadingEva())
      }
    }
  },
})

// handle E-Testing/E-Eva Stop and Call API
listenerMiddlewareELearning.startListening({
  actionCreator: setStopModule,
  effect: async (action, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningPreview
    const type = _.get(selectedLesson, 'moduleType', '')
    let storageKey = ''
    let response = {}

    listenerApi.dispatch(startLessonLoading())
    if (type === MODULE_TYPE.E_TESTING) {
      storageKey = 'eTesting'
      const eTestingUuid = _.get(selectedLesson, 'eTestingUuid', '')
      response = await listenerApi.dispatch(getFormDetail(eTestingUuid))
    }
    if (type === MODULE_TYPE.E_EVALUATION) {
      storageKey = 'eEvaluation'
      const eEvaluationUuid = _.get(selectedLesson, 'eEvaluationUuid', '')
      response = await listenerApi.dispatch(getFormDetailEva(eEvaluationUuid))
    }
    listenerApi.dispatch(stopLessonLoading())

    if (response.payload) {
      localStorage.setItem(storageKey, JSON.stringify(response.payload))
    }
  },
})
