import { setTab } from '../../../../../redux/slices/manageClassCheckExam'
import { fetchManageClassLearnerExam } from '../../events'
import { getLearnerList } from '../events'

export const handleChangeTab = (props) => (dispatch) => {
  const { idProps, learners, tab, isStandalone } = props
  const learnerList = getLearnerList(learners, tab)
  dispatch(setTab(tab))
  dispatch(fetchManageClassLearnerExam({ idProps, learnerList, isStandalone }))
}
