export const overviewEnum = {
  ALL: 'ALL_CLASS',
  CLASSROOM: 'CLASSROOM_CLASS',
  ONLINE: 'ONLINE_CLASS',
}

export const overviewStatusEnum = {
  STARTCLASS: 'STARTCLASS',
  ENDCLASS: 'ENDCLASS',
  CLOSED: 'CLOSED',
  INPROGRESS: 'INPROGRESS',
  OPENREGISTRATION: 'OPENREGISTRATION',
  CLOSEREGISTRATION: 'CLOSEREGISTRATION',
  CANCELED: 'CANCELED',
  TODO: 'TODO',
  UNASSIGN: 'UNASSIGN',
  OTHER: 'OTHER',
}

export const overviewStatusTxt = {
  OPENREGISTRATION: 'กำลังเปิดรับสมัคร',
  CLOSEREGISTRATION: 'รอการอบรม',
  STARTCLASS: 'กำลังอบรม',
  CANCELED: 'ยกเลิก',
  OTHER: 'อื่นๆ',
  ENDCLASS: 'End Class',
  CLOSED: 'Closed Class',
}
