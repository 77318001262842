import { TableRow } from '@mui/material'
const TableRowReport = ({ children, sx = {} }) => {
  return (
    <TableRow type="hover" sx={{ ...sx }}>
      {children}
    </TableRow>
  )
}

export default TableRowReport
