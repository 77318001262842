import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { contentType } from '../enum/enum'
import { StyledButton } from '../Styled'
import TypeCard from './TypeCard'

const ContentSummary = ({ type }) => {
  return (
    <Grid item xs="6">
      <Card sx={{ padding: '8px 16px 16px 16px' }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h6">{contentType[type].label}</Typography>
          <StyledButton
            color="text.lightGray"
            size="small"
            marginLeft=""
            background="#4976BA14"
            onClick={() => window.open(contentType[type].path, '_blank')}
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        </Box>
        <Box mt={2}>
          <Grid container spacing={0.5}>
            <Grid item xs={6}>
              <TypeCard type={type} channel="agency" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="banc" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="ktb" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="other" />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  )
}

export default ContentSummary
