import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ModuleCard from '../Overview/ModuleCard'
import { handleClickClassDrawer } from '../../handler/handleClickClassDrawer'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'

const index = () => {
  const { overviewsStatusData } = useSelector(
    (state) => ({
      overviewsStatusData:
        state.crud?.manageClassDashboard?.overviewsStatusData,
    }),
    shallowEqual,
  )
  const colorArray = _.defaultTo(overviewsStatusData, []).map(
    (item) => item.dotColor,
  )
  const module = _.defaultTo(overviewsStatusData, []).map((item) => {
    return item.number ? Number(item.number) : 0
  })
  const totalModule = _.sum(module)
  return (
    <Box display="inline-block">
      <Card>
        <CardContent>
          <Typography variant="h6">สรุปคลาสรายสถานะ</Typography>
          <Box display="flex" gap={2} mt={3}>
            <DoughnutChart
              dataSet={{
                data: module,
                backgroundColor: colorArray,
              }}
              height={224}
              label="ทั้งหมด"
              value={totalModule}
            />
            <Box display="flex" flexWrap="wrap" gap={1}>
              {_.defaultTo(overviewsStatusData, []).map((ele, index) => {
                const percent =
                  ele.percent > 0
                    ? ((ele.percent / totalModule) * 100).toFixed(2)
                    : 0
                return (
                  <Box key={'status-' + index} width={'264px'} minHeight={'96px'}>
                    <ModuleCard
                      key={index}
                      label={ele.label}
                      subLable={ele.subLable}
                      labelVariant="body2b"
                      background={ele.background}
                      dotColor={ele.dotColor}
                      value={ele.number}
                      unit={ele.unit}
                      percent={`${percent}%`}
                      handleClickButton={() => {
                        handleClickClassDrawer(ele.value)
                      }}
                      fontColor={ele.fontColor}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default index
