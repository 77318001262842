import { store } from '../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../redux/slices/crud'
import callAxios from '../../../../utils/baseService'
import { getRoomManagementDashboardByAttendee } from '../../../../utils/apiPath'

export const fetchRoomRequest = async () => {
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.roomRequestDrawer.isLoading',
      value: true,
    }),
  )
  const { roomManagementDashboard } = store.getState().crud
  const body = {
    startDate: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.startDate,
      dayjs().startOf('month').format('YYYY-MM-DD'),
    ),
    endDate: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.endDate,
      dayjs().format('YYYY-MM-DD'),
    ),
    station: _.defaultTo(
      roomManagementDashboard?.filtersDrawer?.station,
      roomManagementDashboard?.defaultStation,
    ),
    order: _.defaultTo(
      roomManagementDashboard?.roomRequestDrawer?.filter?.order,
      'desc',
    ),
    page: _.defaultTo(
      roomManagementDashboard?.roomRequestDrawer?.filter?.page,
      1,
    ),
    limit: _.defaultTo(
      roomManagementDashboard?.roomRequestDrawer?.filter?.rowsPerPage?.value,
      10,
    ),
  }

  await callAxios
    .post(getRoomManagementDashboardByAttendee, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.roomRequestDrawer.meetingRoomList',
          value: data.meetingRoomList,
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.roomRequestDrawer.filter.totalCount',
          value: data.totalCount,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.roomRequestDrawer.isLoading',
      value: false,
    }),
  )
}
