import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

export const defaultFilter = {
  trainingPlatformChecked: false,
  trainingPlatformText: '',
  abbreviationChecked: false,
  abbreviationText: '',
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  statusChecked: false,
}

export const handleOnTextChange = (e, setFilter, key) => {
  setFilter((prevState) => ({
    ...prevState,
    [key]: e.target.value,
  }))
}

export const onClearClick = (setFilter) => {
  setFilter({
    abbreviationChecked: false,
    abbreviationText: '',
    status: {
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
    statusChecked: false,
  })
}
export const handleChangeChecked = ({ checked, key, setFilter }) => {
  setFilter((prevState) => ({
    ...prevState,
    [key]: checked,
  }))
}

export const handleChangeStatus = (event, setFilter, filter) => {
  if (event.target.name === 'activeChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: event.target.checked,
        inActiveChecked: filter.status.inActiveChecked,
        deletedChecked: filter.status.deletedChecked,
      },
    }))
  } else if (event.target.name === 'inActiveChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: filter.status.activeChecked,
        inActiveChecked: event.target.checked,
        deletedChecked: filter.status.deletedChecked,
      },
    }))
  } else if (event.target.name === 'deletedChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: filter.status.activeChecked,
        inActiveChecked: filter.status.inActiveChecked,
        deletedChecked: event.target.checked,
      },
    }))
  }
}

const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'trainingPlatformChecked',
                }}
                name="trainingPlatformChecked"
                checked={filter.trainingPlatformChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'trainingPlatformChecked',
                    setFilter,
                  })
                }}
              />
            }
            label="รูปแบบการสอน"
            sx={!filter.trainingPlatformChecked ? { marginBottom: -1 } : {}}
          />
          {filter.trainingPlatformChecked && (
            <InputBase
              inputProps={{
                'data-testid': 'trainingPlatformText',
              }}
              type="basic"
              disabled={!filter.trainingPlatformChecked}
              placeholder="ค้นหารูปแบบการสอน"
              value={filter.trainingPlatformText}
              onChange={(e) =>
                handleOnTextChange(e, setFilter, 'trainingPlatformText')
              }
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>

      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'abbreviationChecked',
                }}
                name=""
                checked={filter.abbreviationChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'abbreviationChecked',
                    setFilter,
                  })
                }}
              />
            }
            label="ชื่อย่อ"
            sx={!filter.abbreviationChecked ? { marginBottom: -1 } : {}}
          />
          {filter.abbreviationChecked && (
            <InputBase
              inputProps={{
                'data-testid': 'abbreviationText',
              }}
              type="basic"
              disabled={!filter.abbreviationChecked}
              placeholder="ค้นหาชื่อย่อ"
              value={filter.abbreviationText}
              onChange={(e) =>
                handleOnTextChange(e, setFilter, 'abbreviationText')
              }
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>

      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="เปิดใช้งาน"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'activeChecked',
                  }}
                  name="activeChecked"
                  checked={filter.status.activeChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
            <FormControlLabel
              label="ปิดใช้งาน"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'inActiveChecked',
                  }}
                  name="inActiveChecked"
                  checked={filter.status.inActiveChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
            <FormControlLabel
              label="ถูกลบแล้ว"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'deletedChecked',
                  }}
                  name="deletedChecked"
                  checked={filter.status.deletedChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => onClearClick(setFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-confirm-filter"
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
