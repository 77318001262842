import React from 'react'
import _ from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Content from './Content'

import SubQuestions from './SubQuestions'

import { draftQuestion } from '../../../../../../redux/slices/eEvaluationForm/feedback'

const Questions = ({ sequence }) => {
  const { itemContent, nextQuestions } = useSelector(
    (state) => ({
      itemContent: state.eEvaluationFeedback.questions.find(
        (item) => item.sequence === sequence
      ),
      nextQuestions: state.eEvaluationFeedback.nextQuestions.questions.find(
        (item) => item.sequence === sequence
      ),
    }),
    shallowEqual
  )

  const propsContent = {
    type: 'questions',
    sequence,
    itemContent,
    nextQuestions: _.get(nextQuestions, 'value', 1),
  }

  return <Content props={propsContent} />
}

const Index = ({ eEvaluationType }) => {
  const listQuestion = useSelector(draftQuestion, shallowEqual)

  return (
    <>
      <Box sx={{ mt: 3, mb: !listQuestion.length ? 2 : 0 }}>
        {eEvaluationType == 'CLASS' && (
          <Typography variant="h4">ตอนที่ 4</Typography>
        )}
        <Typography variant="h5" sx={{ mt: -1 }}>
          แบบประเมิน / แบบสอบถามอื่นๆ
        </Typography>
      </Box>
      {listQuestion.length > 0 &&
        listQuestion.map((item, index) => (
          <>
            <Questions
              id={item}
              key={item}
              sequence={item}
              indexQuestion={index}
            />
            <SubQuestions sequence={item} />
          </>
        ))}
    </>
  )
}

export default Index
