import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'

const manageClassLearnerDocument = createSlice({
  name: 'manageClassLearnerDocument ',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setFetchDataList: (state, { payload }) => {
      state.dataList = payload
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setChangeBody: (state, { payload }) => {
      const { key, value } = payload
      state.documentBody = {
        ...state.documentBody,
        [key]: value,
      }
    },
    setChangeDocumentList: (state, { payload }) => {
      const { isChecked, uuid } = payload
      let learnerDocumentList = state.documentBody.learnerDocumentList
      if (!isChecked) {
        learnerDocumentList = learnerDocumentList.filter(
          (item) => item !== uuid,
        )
      } else {
        learnerDocumentList = [...learnerDocumentList, uuid]
      }
      state.documentBody.learnerDocumentList = learnerDocumentList
    },
  },
})

export const {
  setFieldValue,
  startLoading,
  stopLoading,
  setFetchDataList,
  setChangeBody,
  setChangeDocumentList,
} = manageClassLearnerDocument.actions

export default manageClassLearnerDocument.reducer
