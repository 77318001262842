// import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const openDetailDrawer = (value, item = null) => {
  store.dispatch(
    setFieldValue({
      key: 'isOpenDetailDrawer',
      value: value,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'detailDrawer',
      value: item,
    }),
  )
}
