export const breadcrumb = (versionCode) => [
  { title: 'E-Testing', link: '/', pointer: false },
  { title: 'Dashboard', link: '/e-testing/dashboard', pointer: true },
  {
    title: 'เวอร์ชัน',
    link: `/e-testing/dashboard/version/${versionCode}`,
    pointer: true,
  },
  {
    title: `รายละเอียดแบบทดสอบ`,
    link: `/`,
    pointer: false,
  },
]
