import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import { Column } from '../../../Layout/Styled'
import { StyledFootBox } from '../Styled'
import { convertFormatTime } from '../events'
import { StyledContentButton, StyledFlex } from './Styled'

const Content = ({ course, isPath, isCollection }) => {
  const time = convertFormatTime(course, true)
  const eTestingAmount = _.get(course, 'eTestingAmount', 0)
  return (
    <>
      <Column gap={2} id="hover-content">
        <StyledFlex sx={{ height: 76, flexDirection: 'column' }}>
          <FlexContent icon={<TimerOutlined color="primary" />}>
            <Typography variant="body2" color="text.secondary">
              {time}
            </Typography>
            <StyledFlex sx={{ ml: 1, gap: 2 }}>
              <Typography variant="body2" color="text.secondary">
                &#8226;
              </Typography>
              {isCollection ? (
                <Typography variant="body2" color="text.secondary">
                  {_.get(course, 'courseCount', 0)} หลักสูตร
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  {isPath
                    ? _.get(course, 'coursesCount', 0)
                    : _.get(course, 'lessonAmount', 1)}{' '}
                  {isPath ? 'หลักสูตร' : 'บทเรียน'}
                </Typography>
              )}
            </StyledFlex>
          </FlexContent>
          {!isPath && !isCollection && eTestingAmount > 0 && (
            <FlexContent
              icon={
                <img
                  src="/icon/ic_box_list.svg"
                  style={{ width: 18, height: 18 }}
                />
              }
              isText
            >
              แบบฝึกหัดทั้งหมด {eTestingAmount} แบบฝึกหัด
            </FlexContent>
          )}
        </StyledFlex>
      </Column>
      <StyledFootBox>
        <StyledContentButton>
          <Button variant="outlined">รายละเอียด</Button>
        </StyledContentButton>
      </StyledFootBox>
    </>
  )
}

export default Content

export const FlexContent = ({ children, icon, isText }) => {
  return (
    <StyledFlex
      sx={{
        alignItems: 'center',
        svg: { fontSize: '18px', width: 18, height: 18 },
      }}
    >
      {icon}
      {isText ? (
        <Typography variant="body2" color="text.secondary">
          {children}
        </Typography>
      ) : (
        children
      )}
    </StyledFlex>
  )
}
