import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Close from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import { DrawerHistory, HeadLabelHistory, ContentHistory } from './Styled'
import Table from '../../../../../../components/redux/Table'

import { fetchDataHistory } from './events'

const HistoryDrawer = ({ isOpen, onCloseDrawer }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { page, sort, limit } = table

  useEffect(() => {
    isOpen && dispatch(fetchDataHistory({ uuid, table }))
  }, [isOpen, page, sort, limit])

  return (
    <DrawerHistory anchor={'right'} open={isOpen} onClose={onCloseDrawer}>
      <ContentHistory isLoading={table.isLoading}>
        <HeadLabelHistory>
          <Box>
            <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
            <IconButton
              data-testid="btn-close-history"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ mx: 4, mt: 3 }}>
            <Table />
          </Box>
        </HeadLabelHistory>
      </ContentHistory>
    </DrawerHistory>
  )
}

export default HistoryDrawer
