import { crudConfig } from '../../crudConfig'

export const breadcrumb = [
  { title: 'Inventory', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: `รายละเอียด Ticket`,
    link: `/`,
    pointer: false,
  },
]

export const breadcrumbReturn = [
  { title: 'Inventory', link: '/', pointer: false },
  {
    title: crudConfig.moduleTitleFull,
    link: crudConfig.modulePath,
    pointer: true,
  },
  {
    title: `คืนทรัพย์สิน`,
    link: `/`,
    pointer: false,
  },
]