import {
  clearFilter,
  resetPage,
  resetTablePage,
  setSearchText,
} from '../../../../../../../redux/slices/table'
import { fetchDataList } from '../../../../../../../components/CRUD/handler/fetchDataList'
import { setTableProps } from '../../../model/setTableProps'

export const handleQuickSearch =
  (table, keyword, mutatePropToBody) => (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(clearFilter())
    dispatch(resetTablePage())
    if (table.page === 1) {
      // dispatch(fetchDataList(mutatePropToBody, setTableProps))
      fetchDataList(mutatePropToBody, setTableProps).then()
    } else {
      dispatch(resetPage())
    }
  }
