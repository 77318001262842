import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  LoadingPage,
  StyledContainer,
  StyledContent,
} from '../Module/Lib/CSS/Styled'
import HeaderStation from './Header'
import TableStation from './Table'

const Station = () => {
  const { table } = useSelector(
    (state) => ({
      table: state.table,
    }),
    shallowEqual,
  )
  const { isLoading: isLoading } = table.table

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <HeaderStation />
        <TableStation />
      </StyledContent>
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Station
