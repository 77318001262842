import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../../../../src/redux/slices/dialog'
import { DialogWaitingCheck } from './DialogWaitingCheck'
import axios from 'axios'
import { manageClassClose } from '../../../../../../src/utils/apiPath'
import { handleCloseClass } from './DialogCloseClass/event'
import {
  startLoading,
  stopLoading,
} from '../../../../../../src/redux/slices/manageClass'
import { fetchMangeClassDetail } from '../../events'
import { handleCheckClose } from './DialogCheckClose/event'
import _ from 'lodash'
export const handleCallApiCloseClass = (uuid, body) => async (dispatch) => {
  dispatch(loadingDialog())
  await axios
    .put(manageClassClose, body)
    .then(() => {
      dispatch(
        dispatch(startLoading()),
        dispatch(fetchMangeClassDetail(uuid)),
        dispatch(stopLoading()),

        dispatch(
          openDialog({
            isCloseDialog: false,
            type: 'success',
            title: 'สำเร็จ',
            message: 'ปิดคลาสเรียบร้อยแล้ว',
            handleConfirm: () => {
              dispatch(closeDialog())
              window.location.reload()
            },
          })
        )
      )
    })
    .catch(() => {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message:
            'ไม่สามารถปิดคลาสได้ เนื่องจากระบบขัดข้อง โปรดลองใหม่อีกครั้ง',
          handleConfirm: () => {
            dispatch(closeDialog())
          },
        })
      )
    })
  dispatch(stopLoadingDialog())
}

export const handlePassCriteria = (uuid) => (dispatch) => {
  const body = {
    manageClassUuid: `${uuid}`,
    remarkClosed: ``,
    checkAttendance: [],
    checkNoCondition: true,
    checkPassCourse: true,
  }
  dispatch(
    openDialog({
      title: 'ปิดคลาส',
      type: 'confirm',
      message: 'คุณต้องการปิดคลาสนี้เลยหรือไม่',
      icon: <ErrorOutlineIcon color="error" />,
      agreeText: 'ปิดคลาส',
      handleConfirm: () => {
        dispatch(handleCallApiCloseClass(uuid, body))
      },
    })
  )
}

export const handleWaitingCheck = () => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ปิดคลาสไม่ได้',
      type: 'success',
      content: <DialogWaitingCheck />,
      icon: <ErrorOutlineIcon color="error" />,
      agreeText: 'ตกลง',
      maxWidth: 'sm',
      handleConfirm: () => {
        dispatch(closeDialog({}))
      },
    })
  )
}

export const handleClickButton =
  (status, manageClassClose, uuid) => (dispatch) => {
    let newStatus = status
    const checkAttendance = _.get(manageClassClose, 'checkAttendance', [])
    const getNotSentEEva = _.get(
      manageClassClose,
      'checkEValuation.getNotSentEEva',
      []
    )
    const getNotSentETesting = _.get(
      manageClassClose,
      'checkETesting.getNotSentETesting',
      []
    )
    const getNotAnnounceETesting = _.get(
      manageClassClose,
      'checkETesting.getNotAnnounceETesting',
      []
    )
    const getNeverOpenETesting = _.get(
      manageClassClose,
      'checkETesting.getNeverOpenETesting',
      []
    )
    const getStillOpenETesting = _.get(
      manageClassClose,
      'checkETesting.getStillOpenETesting',
      []
    )
    const getWaitingToCheckETesting = _.get(
      manageClassClose,
      'checkETesting.getWaitingToCheckETesting',
      []
    )
    const getNeverOpenEEvaluation = _.get(
      manageClassClose,
      'checkEValuation.getNeverOpenEEvaluation',
      []
    )
    const getStillOpenEEvaluation = _.get(
      manageClassClose,
      'checkEValuation.getStillOpenEEvaluation',
      []
    )

    if (manageClassClose.checkNoCondition) {
      newStatus = 'NORMAL'
    } else if (
      getNeverOpenETesting.length > 0 ||
      getStillOpenETesting.length > 0 ||
      getWaitingToCheckETesting.length > 0 ||
      getNeverOpenEEvaluation.length > 0 ||
      getStillOpenEEvaluation.length > 0
    ) {
      newStatus = 'WAITINGCHECK'
    } else if (
      (checkAttendance.length > 0 ||
        getNotSentEEva.length > 0 ||
        getNotSentETesting.length > 0 ||
        getNotAnnounceETesting.length > 0) &&
      newStatus !== 'STARTCLASS'
    ) {
      newStatus = 'CHECKCLOSE'
    } else if (status === 'STARTCLASS') {
      newStatus = 'STARTCLASS'
    } else {
      newStatus = 'NORMAL'
    }

    switch (newStatus) {
      case 'NORMAL':
        dispatch(handlePassCriteria(uuid))
        break
      case 'STARTCLASS':
        dispatch(handleCloseClass())
        break
      case 'WAITINGCHECK':
        dispatch(handleWaitingCheck())
        break
      case 'CHECKCLOSE':
        dispatch(handleCheckClose(manageClassClose, uuid))
        break
      default:
        break
    }
  }
