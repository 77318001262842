import { Box, Typography } from '@mui/material'
import { StyledSummaryCard } from '../../Styled'
import InfoPreview from '../../../../components/InfoPreview'
import { useTheme } from '@mui/system'
import { useSelector, shallowEqual } from 'react-redux'
import { handleOpenClassDrawer } from '../handler/handleOpenClassDrawer'

const SummaryAllClassCard = () => {
  const theme = useTheme()
  const { allClass, classroomClass, onlineClass } = useSelector(
    (state) => ({
      allClass: state.monthlyPlan.dashboard.allClass,
      classroomClass: state.monthlyPlan.dashboard.classroomClass,
      onlineClass: state.monthlyPlan.dashboard.onlineClass,
    }),
    shallowEqual,
  )

  return (
    <StyledSummaryCard sx={{ width: '50%' }}>
      <Typography variant="h6">สรุปคลาสรวม</Typography>
      <Box display="flex" gap={2}>
        <InfoPreview
          label="จำนวนคลาสทั้งหมด"
          count={allClass}
          unit="คลาส"
          bgButtonColor={'#4976BA14'}
          handleClick={() => handleOpenClassDrawer()}
        />
        <InfoPreview
          boxSx={{ border: 'none' }}
          label="คลาส Classroom"
          count={classroomClass}
          unit="คลาส"
          bgColor={`${theme.palette.primary.outlinedHoverBackground}`}
          hideButton={true}
        />
        <InfoPreview
          boxSx={{ border: 'none' }}
          label="คลาส Online"
          count={onlineClass}
          unit="คลาส"
          bgColor={`${theme.palette.primary.outlinedHoverBackground}`}
          hideButton={true}
        />
      </Box>
    </StyledSummaryCard>
  )
}

export default SummaryAllClassCard
