import { setFieldValue } from '../../../../../../redux/slices/crud'

export const setSelected = (value) => (dispatch) => {
  dispatch(setFieldValue({ key: 'selected', value: value }))
}

export const calcAnswerAndPercentage = () => {
  return true
}

export const mockTestData = [
  {
    title:
      "Single - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'SINGLE',
    totalAnswer: 10,
    answers: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        answerCount: 1,
        answerPercentage: '10%',
        isCorrectAnswer: true,
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        answerCount: 3,
        answerPercentage: '30%',
        isCorrectAnswer: false,
      },
      {
        title:
          "c - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        answerCount: 6,
        answerPercentage: '60%',
        isCorrectAnswer: false,
        mostIncorrect: true,
      },
    ],
  },
  {
    title:
      "Multiple - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'MULTIPLE',
    totalAnswer: 10,
    answerSequence: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        isCorrectAnswer: true,
      },
      {
        title:
          "C - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        isCorrectAnswer: true,
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        isCorrectAnswer: false,
      },
    ],
    answersData: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        isCorrectAnswer: true,
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        isCorrectAnswer: true,
      },
      {
        title:
          "C - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        isCorrectAnswer: false,
      },
    ],
    answers: [
      {
        countCorrect: 1,
        correctPercentage: '10%',
        countIncorrect: 9,
        incorrectPercentage: '90%',
      },
    ],
  },
  {
    title:
      "Sequence - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'SEQUENCE',
    answerSequence: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      },
      {
        title:
          " C - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      },
    ],
    totalAnswer: 10,
    answers: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        answerCount: 0,
        countCorrect: 1,
        correctPercentage: '10%',
        countIncorrect: 9,
        incorrectPercentage: '90%',
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        answerCount: 0,
        countCorrect: 2,
        correctPercentage: '20%',
        countIncorrect: 8,
        incorrectPercentage: '80%',
      },
      {
        title:
          "C - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        answerCount: 0,
        countCorrect: 3,
        correctPercentage: '30%',
        countIncorrect: 7,
        incorrectPercentage: '70%',
      },
    ],
  },
  {
    title:
      "Matching - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'MATCHING',
    totalAnswer: 10,
    answers: [
      {
        matchNumber: 1,
        title1:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        title2:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        answerCount: 0,
        countCorrect: 2,
        countIncorrect: 8,
        correctPercentage: '20%',
        incorrectPercentage: '80%',
      },
      {
        matchNumber: 2,
        title1:
          "C - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        title2:
          "D - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        answerCount: 0,
        countCorrect: 2,
        countIncorrect: 8,
        correctPercentage: '20%',
        incorrectPercentage: '80%',
      },
    ],
  },
  {
    title:
      "True/False - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'TRUE_FALSE',
    totalAnswer: 10,
    answers: [
      {
        title:
          "A - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        answerCount: 0,
        countCorrect: 2,
        countIncorrect: 8,
        correctPercentage: '20%',
        incorrectPercentage: '80%',
      },
      {
        title:
          "B - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
        answerCount: 0,
        countCorrect: 3,
        countIncorrect: 7,
        correctPercentage: '30%',
        incorrectPercentage: '70%',
      },
    ],
  },
  {
    title:
      "Fill in Blank (Choice) - abcde1234 <>{}[]!@#$%^&*()_+= Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a1",
    questionType: 'FILL_IN_BLANK_CHOICE',
    totalAnswer: 10,
    answers: [
      {
        title: 'ช่องว่าง A',
        answerCount: 0,
        countCorrect: 2,
        countIncorrect: 8,
        correctPercentage: '20%',
        incorrectPercentage: '80%',
      },
      {
        title: 'ช่องว่าง B',
        answerCount: 0,
        countCorrect: 2,
        countIncorrect: 8,
        correctPercentage: '20%',
        incorrectPercentage: '80%',
      },
    ],
  },
]
