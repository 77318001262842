import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Header from './Header'
import CardDefault from './CardChart/Default'
import CardLecturer from './CardChart/Lecturer'
import CardQuestions from './CardChart/Questions'

import { useSelector, shallowEqual } from 'react-redux'
import { fetchDataFeedback } from './events'
const Index = () => {
  const { id: uuid } = useParams()

  const dispatch = useDispatch()
  const totalLearnerAnswer = useSelector(
    (state) => state.eEvaluationFeedback.totalLearnerAnswer,
    shallowEqual
  )

  const { eEvaluationType, isClone } = useSelector(
    (state) => ({
      eEvaluationType: state.eEvaluationForm.eEvaluationType,
      isClone: state.eEvaluationForm.isClone,
    }),
    shallowEqual
  )

  const feedBackFilter = useSelector(
    (state) => state.eEvaluationFeedback.filter
  )

  useEffect(() => {
    if (uuid && !isClone) dispatch(fetchDataFeedback(uuid, feedBackFilter))
  }, [feedBackFilter])

  return (
    <>
      <Header />
      {eEvaluationType === 'CLASS' && totalLearnerAnswer > 0 && (
        <>
          <CardDefault type="courseAssessments" />
          <CardLecturer />
          <CardDefault type="courseManagementAssessments" />
        </>
      )}

      {totalLearnerAnswer > 0 && (
        <CardQuestions eEvaluationType={eEvaluationType} />
      )}
    </>
  )
}

export default Index
