import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LayoutPreview } from '../../../CourseSetting/ECertificationSetting/ECertificationView/event'
import { LANGUAGE_TH } from './model'

const ECertificatePreview = ({ certificate }) => {
  const eCertificationVersion = _.get(certificate, 'eCertificationVersion', '')
  const englishCertificateName = _.get(
    certificate,
    'englishCertificateName',
    '',
  )
  const certificateLanguage = _.get(certificate, 'certificateLanguage', '')
  const expireYear = _.get(certificate, 'expireECertificationYear', '')
  const isCertificationPrefix = _.get(
    certificate,
    'isCertificationPrefix',
    true,
  )

  return (
    <Box sx={{ display: 'flex', gap: 10.5 }}>
      <Box sx={{ width: 238 }}>
        {!_.isEmpty(eCertificationVersion) && (
          <LayoutPreview
            version={eCertificationVersion}
            englishCertificateName={englishCertificateName}
            certificateLanguage={certificateLanguage}
            width={238}
            isCertificationPrefix={isCertificationPrefix}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ContentRow
          title="ชื่อ Certificate"
          content={englishCertificateName || '-'}
        />
        <ContentRow
          title="เทมเพลต"
          content={_.get(eCertificationVersion, 'name', '-')}
        />
        <ContentRow
          title="Layout"
          content={_.get(eCertificationVersion, 'layout.name', '-')}
        />
        <ContentRow
          title="อายุของประกาศนียบัตร (จำนวนปี) นับจากวันที่ปิดคลาส"
          content={_.isNil(expireYear) ? '-' : `${expireYear} ปี`}
        />
        <ContentRow
          title="ภาษาที่ใช้สำหรับชื่อ-นามสกุล"
          content={LANGUAGE_TH[certificateLanguage] || '-'}
        />
      </Box>
    </Box>
  )
}

export default ECertificatePreview

export const ContentRow = ({ title, content }) => {
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      <Typography variant="body1b" width={330} minWidth={330}>
        {title}
      </Typography>
      <Typography sx={{ wordBreak: 'break-word' }}>{content}</Typography>
    </Box>
  )
}
