import FormFooter from '../../../../../components/CRUD/components/FormFooter/FormFooter'
import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../../../components/CRUD/components/CustomTextArea'
import CustomStatusSwitch from '../../../../../components/CRUD/components/CustomStatusSwitch'
import { handleSubmitClick } from '../../../../../components/CRUD/handler/handleSubmitClick'
import { useParams } from 'react-router-dom'
import { postBodyMutation } from './mutation/post'
import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { validationSchema } from './schema/schema'

export const Content = () => {
  const { uuid } = useParams()

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: 'MASTER_DATA',
    permission: ['CREATE'],
  })

  return (
    <>
      <CustomInput
        required
        disabled={!hasPermissionCreate}
        fieldName="name"
        labelName="ชื่อหมวดหมู่ย่อย"
      />
      <CustomDropdown
        required
        disabled={!hasPermissionCreate}
        fieldName="stockCategory"
        labelName="หมวดหมู่หลัก"
      />
      <CustomTextArea
        disabled={!hasPermissionCreate}
        fieldName="remark"
        labelName="รายละเอียดเพิ่มเติม"
      />
      <CustomStatusSwitch
        disabled={!hasPermissionCreate}
        fieldName="status"
        labelName="การเปิดใช้งาน"
      />
      <FormFooter
        disabled={!hasPermissionCreate}
        handleSubmitClick={() =>
          handleSubmitClick(uuid, postBodyMutation, validationSchema)
        }
      />
    </>
  )
}
