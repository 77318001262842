import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'
import { theme } from '../../utils/theme'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 16,
  right: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    '&#view': {
      position: 'relative',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    position: 'relative',
  },
}))

export const StyledContainer = styled(
  Box,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(
  Box,
  shouldForwardProp,
)(({ isLoading }) => ({
  margin: '0px 24px 0 24px',
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled(
  (props) => <Card {...props} />,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  minWidth: 275,
  marginTop: theme.spacing(3),
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  [theme.breakpoints.down('lg')]: {
    '&#view': { marginTop: theme.spacing(5) },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(#versionTable)': { marginTop: theme.spacing(5) },
  },
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const StyledLoadingBg = styled(
  Box,
  shouldForwardProp,
)(({ isLoading }) => ({
  display: isLoading ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.3)',
  zIndex: 1200,
}))

export const LoadingCircular = styled(
  CircularProgress,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette?.primary?.main,
}))

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const StyledViewWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}))

export const StyledFormCard = styled(
  Card,
  shouldForwardProp,
)(({ theme, isLoading, isUploadLoading }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading || isUploadLoading ? 'none' : 'auto',
  marginBottom: theme.spacing(1),
}))

export const StyledViewCard = styled(
  Card,
  shouldForwardProp,
)(({ theme, isLoading, isUploadLoading }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  opacity: isLoading ? 0.5 : 1,
  pointerEvents: isLoading || isUploadLoading ? 'none' : 'auto',
}))

export const StyledFormColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

export const StyledCardQuestion = styled(
  (props) => <Card {...props} />,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  minWidth: 250,
  marginTop: theme.spacing(3),
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  [theme.breakpoints.down('lg')]: {
    '&#view': { marginTop: theme.spacing(5) },
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(#versionTable)': { marginTop: theme.spacing(5) },
  },
}))

export const StyledRow = styled(Box)(({ theme, isMobile }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: isMobile ? 0 : theme.spacing(3),
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
