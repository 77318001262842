import React from 'react'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  breadcrumbList,
  breadcrumbStandaloneList,
  breadcrumbVersionList,
} from '../model'
import { BoxLabel, BoxContent } from './Styled'

const Index = ({ isStandalone, isVersion }) => {
  return (
    <BoxContent>
      <BoxLabel>
        <Typography variant="h4">
          {isVersion ? 'เวอร์ชั่น' : 'ตรวจข้อสอบ'}
        </Typography>
        <Breadcrumbs
          menuList={
            isStandalone
              ? breadcrumbStandaloneList
              : isVersion
              ? breadcrumbVersionList
              : breadcrumbList
          }
        />
      </BoxLabel>
    </BoxContent>
  )
}
export default Index
