import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  budgetManagement,
  budgetManagementFilter,
  budgetManagementHistory,
  budgetManagementUpload,
} from '../../utils/apiPath'

export const filterBudgetManagement = createAsyncThunk(
  'budgetManagement/filterBudgetManagement',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(budgetManagementFilter, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getBudgetManagementById = createAsyncThunk(
  'budgetManagement/getBudgetManagementById',
  async ({ page, uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${budgetManagement}/${page}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const createBudgetManagement = createAsyncThunk(
  'budgetManagement/createBudgetManagement',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(budgetManagement, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateBudgetManagement = createAsyncThunk(
  'budgetManagement/updateBudgetManagement',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.put(budgetManagement, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteBudgetManagement = createAsyncThunk(
  'budgetManagement/deleteBudgetManagement',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${budgetManagement}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getBudgetManagementHistory = createAsyncThunk(
  'budgetManagement/getBudgetManagementHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${budgetManagementHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const uploadFileBudgetManagement = createAsyncThunk(
  'budgetManagement/uploadFileBudgetManagement',
  async (body, { rejectWithValue }) => {
    try {
      return await axios.post(budgetManagementUpload, body)
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
