import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'

export const StyledFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 90,
  backgroundColor: theme?.palette?.other?.standardInputLine,
  position: 'sticky',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  '& > .MuiBox-root': {
    width: 896,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    '& > .MuiBox-root': {
      width: 672, // 768 - 24 - 24 - 48(left menu)
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    '& > .MuiBox-root': {
      width: 335, // 375 - 20 - 20
      flexDirection: 'column',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
  },
}))

export const responsiveButton = () => {
  const isMediaLg = isMediaWidthMoreThan('md')
  const isMediaMd = isMediaWidthMoreThan('sm')
  if (isMediaLg) return 'l'
  else {
    if (isMediaMd) return 'm'
    else return 's'
  }
}
