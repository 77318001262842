import dayjs from 'dayjs'
import _ from 'lodash'
import { store } from '../../../App'
import { formatDateDisplay } from '../../../components/DateRangeInput/events'
import {
  PERIOD_TYPE,
  ROOM_AVAILABLE,
  periodOptions,
} from '../../../constants/roomManagement'
import {
  setMeetingRoomList,
  setReduxValue,
  setRoomRequestList,
  setTypeFilter,
  startLoadingBooking,
  stopLoadingBooking,
} from '../../../redux/slices/roomManagement/Dashboard'
import {
  openCreateFormDrawerBooking,
  setReduxValue as setReduxValueDrawer,
  setRoom,
} from '../../../redux/slices/roomManagement/Drawer'
import { FetchMasterRoomsByDay } from '../../../redux/slices/roomManagement/Drawer/event'
import { getCalendarByStationId } from '../../../services/masterData/meetingRoom'
import {
  getMeetingRoomFilter,
  getRoomRequest,
} from '../../../services/roomManagement'
import { isSameDate } from '../../../utils/lib'
import { enumRequestType } from '../View/Content/CalendarCard/constanst'

export const fetchMeetingRoomFilter = (obj) => async (dispatch) => {
  dispatch(startLoadingBooking())
  const { stationUuid, rowsPerPage, page } = store.getState().roomManagement
  const { filterProp } = store.getState().table.filter
  const body = {
    ...filterProp,
    stationUuid: obj?.station ?? filterProp?.station ?? [stationUuid],
    startDate: obj?.startDate ?? filterProp?.startDate ?? '',
    endDate: obj?.endDate ?? filterProp?.endDate ?? '',
    startTime: obj?.startTime ?? filterProp?.startTime ?? '',
    endTime: obj?.endTime ?? filterProp?.endTime ?? '',
    roomName: obj?.roomName ?? filterProp?.roomName ?? '',
    roomFunction: obj?.roomFunction ?? filterProp?.roomFunction ?? [],
    roomType: obj?.roomType ?? filterProp?.roomType ?? [],
    roomStatus: obj?.roomStatus ??
      filterProp?.roomStatus ?? [
        ROOM_AVAILABLE.ALL_AVAILABLE,
        ROOM_AVAILABLE.SOME_TIME_AVAILABLE,
        ROOM_AVAILABLE.NOT_AVAILABLE,
      ],
    limit: rowsPerPage.value,
    page: page <= 0 ? 1 : page,
  }
  const response = await dispatch(getMeetingRoomFilter(body))
  const results = _.get(response, 'payload.response.data.result', [])
  const allCount = _.get(response, 'payload.response.data.totalCount', 0)
  dispatch(setReduxValue({ key: 'allCount', value: allCount }))
  dispatch(setMeetingRoomList(results))
  dispatch(stopLoadingBooking())
}

export const fetchCalendar = () => async (dispatch) => {
  const state = store.getState()
  const { viewCalendar, mondayDate, monthYear, stationUuid } =
    state.roomManagement
  const { filterStation, filterRoomName, filterBookingType } =
    state.roomManagement.drawerCalendarFilter

  dispatch(setReduxValue({ key: 'isCalendarLoading', value: true }))
  const response = await dispatch(
    getCalendarByStationId({
      uuid: filterStation.length > 0 ? filterStation : [stationUuid],
      monthOrWeek: viewCalendar === 'dayGridMonth' ? 'month' : 'week',
      monthYear,
      requestType: filterBookingType,
      roomName: filterRoomName,
      mondayDate,
    }),
  )
  const result = _.get(response, 'payload', {})

  dispatch(setReduxValue({ key: 'roomDetailCalendar', value: result.data }))
  dispatch(setReduxValue({ key: 'isCalendarLoading', value: false }))
}

export const fetchRoomRequestFilter = (obj) => async (dispatch) => {
  dispatch(startLoadingBooking())
  const { requestType, stationUuid, rowsPerPage, page } =
    store.getState().roomManagement
  const { filterProp } = store.getState().table.filter

  let status = obj?.status ?? filterProp?.status ?? []
  if (requestType === enumRequestType.E_BOOKING) {
    status = status.filter((item) => item !== 'IN_PROGRESS')
  }
  const body = {
    ...filterProp,
    startDate: obj?.startDate ?? filterProp?.startDate ?? '',
    endDate: obj?.endDate ?? filterProp?.endDate ?? '',
    startTime: obj?.startTime ?? filterProp?.startTime ?? '',
    endTime: obj?.endTime ?? filterProp?.endTime ?? '',
    roomName: obj?.roomName ?? filterProp?.roomName ?? '',
    operator: obj?.operator ?? filterProp?.operator ?? '',
    limit: rowsPerPage?.value,
    page: page,
    status,
    requestType: requestType || 'ALL',
    stationUuid: stationUuid,
  }
  delete body.roomStatus
  delete body.roomType
  delete body.roomFunction

  const response = await dispatch(getRoomRequest(body))
  const results = _.get(response, 'payload.response.data.result', [])
  const allCount = _.get(response, 'payload.response.data.totalCount', 0)
  const roomRequestList = handleRoomRequestList(results)
  dispatch(setReduxValue({ key: 'allCount', value: allCount }))
  dispatch(setRoomRequestList(roomRequestList))
  dispatch(stopLoadingBooking())
}

export const handleRoomRequestList = (roomRequestList) => {
  return _.map(roomRequestList, (item) => {
    const date = item.createdAt.split('T')
    item.createdAtTime = date[0]
    item.createdAt = formatDateDisplay(date[0])
    return item
  })
}

export const handleLabelDateTime = (props) => {
  const { startDate, endDate, roomManagementMeetingRoom } = props
  const chkSameDate = isSameDate(startDate, endDate)

  const formattedStartDate = dayjs(startDate).format('DD/MM/YY')
  const formattedEndDate = dayjs(endDate).format('DD/MM/YY')
  const sortedRoom = _.orderBy(roomManagementMeetingRoom, 'id', 'asc')

  const period = _.get(sortedRoom, '[0].period', '')
  const startTimeRoom = _.get(sortedRoom, '[0].startTimeRoom', '')
  const startTimeTraining = _.get(sortedRoom, '[0].startTimeTraining', '')
  const endTimeRoom = _.get(sortedRoom, '[0].endTimeRoom', '')
  const endTimeTraining = _.get(sortedRoom, '[0].endTimeTraining', '')
  let labelDate = chkSameDate
    ? formattedStartDate
    : `${formattedStartDate} - ${formattedEndDate}`

  if (startTimeRoom === startTimeTraining && endTimeRoom === endTimeTraining) {
    labelDate += handleTime(period, startTimeRoom, endTimeRoom)
  } else {
    labelDate += ` ${startTimeRoom} - ${endTimeRoom}`
  }
  return labelDate
}

export const handleTime = (period, startTime, endTime) => {
  let labelDate = ''
  const periodLabel = _.chain(periodOptions)
    .find({ value: period })
    .get('label', '')
    .value()

  switch (period) {
    case PERIOD_TYPE.OTHER:
      labelDate += ` ${startTime} - ${endTime}`
      break
    default:
      labelDate += ` ${periodLabel}`
      break
  }
  return labelDate
}

export const handleChangePage = (event, newPage) => async (dispatch) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  await dispatch(setReduxValue({ key: 'page', value: newPage + 1 }))
  await dispatch(setReduxValue({ key: 'tablePage', value: tablePage }))
}

export const handleChangeRowsPerPage = (event) => async (dispatch) => {
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1

  await dispatch(
    setReduxValue({
      key: 'rowsPerPage',
      value: { label: '' + rowsPerPageChange, value: rowsPerPageChange },
    }),
  )
  await dispatch(setReduxValue({ key: 'page', value: -1 }))
  await dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
}

export const filteredRoomList = (sortedRoom) => {
  const meetingRoomList = _.filter(
    sortedRoom,
    (room) => !_.isNull(room?.meetingRoom),
  )

  const meetingRoomOtherList = _.filter(
    sortedRoom,
    (room) => !_.isNull(_.get(room, 'meetingRoomOther')),
  )

  const meetingRoomOnlineList = _.filter(sortedRoom, (room) => room?.isOnline)
  const filteredMeetingRoom = _.uniqBy(
    meetingRoomList,
    (room) => room?.meetingRoom?.uuid,
  )

  const filteredMeetingRoomOther = _.uniqBy(
    meetingRoomOtherList,
    (room) => room?.meetingRoomOther,
  )
  const filteredMeetingRoomOnline = _.uniqBy(
    meetingRoomOnlineList,
    (room) => room?.isOnline,
  )

  return _.orderBy(
    [
      ...filteredMeetingRoom,
      ...filteredMeetingRoomOther,
      ...filteredMeetingRoomOnline,
    ],
    (room) => room.id,
  )
}

export const fetchBookingRoom = () => (dispatch) => {
  const { tabActive } = store.getState().roomManagement
  switch (tabActive) {
    case 0:
      dispatch(fetchMeetingRoomFilter())
      dispatch(setTypeFilter('meetingRoom'))
      break
    case 1:
      dispatch(
        setReduxValue({
          key: 'monthYear',
          value: dayjs().startOf('month').hour(7).toISOString(),
        }),
      )
      dispatch(setReduxValue({ key: 'calendarPage', value: 'dashboard' }))
      dispatch(fetchCalendar())
      setTypeFilter('calendar')
      break
    case 2:
      dispatch(fetchRoomRequestFilter())
      dispatch(setTypeFilter('roomRequest'))
      break
    default:
      dispatch(fetchMeetingRoomFilter())
      break
  }
}

export const handleReloadBookingRoom = () => (dispatch) => {
  dispatch(fetchBookingRoom())
}

export const handleBookingThisRoom =
  (latestRoomAvailable, uuid) => async (dispatch) => {
    const { stationUuid, stationName, meetingRoomList } =
      store.getState().roomManagement
    const { bookingInformation } = store.getState().roomManagementDrawer

    const { startTimeRoom, endTimeRoom } = latestRoomAvailable
    const dateNow = dayjs(new Date()).format('YYYY-MM-DD')

    let newBookingInformation = {
      ...bookingInformation,
      startDate: dateNow,
      endDate: dateNow,
    }

    const resultMasterRoom = await FetchMasterRoomsByDay({
      bookingInformation: newBookingInformation,
      stationUuid,
      startTimeRoom: startTimeRoom,
      endTimeRoom: endTimeRoom,
    })

    const meetingRoom = _.find(meetingRoomList, (room) => room.uuid === uuid)

    const chkPeriod = _.find(
      periodOptions,
      (period) =>
        period.startTimeTraining === startTimeRoom &&
        period.endTimeTraining === endTimeRoom,
    )
    const period = _.get(chkPeriod, 'value', '')

    newBookingInformation = {
      ...newBookingInformation,
      roomBookingList: [
        {
          period: !chkPeriod ? PERIOD_TYPE.OTHER : period,
          startTimeRoom: startTimeRoom,
          endTimeRoom: endTimeRoom,
          startTimeTraining: startTimeRoom,
          endTimeTraining: endTimeRoom,
          bookingDate: dateNow,
          masterRooms: resultMasterRoom,
          isLoadingMasterRooms: false,
          rooms: [
            {
              meetingRoomUuid: uuid,
              meetingRoomOther: null,
              numberOfAttendees: null,
              roomLayout: '',
              additionalEquipment: '',
              numberOfGroup: null,
              attendeeInGroup: null,
              isLoading: false,
              meetingRoom: meetingRoom,
            },
          ],
        },
      ],
    }
    await dispatch(
      setReduxValueDrawer({
        key: 'bookingInformation',
        value: newBookingInformation,
      }),
    )
    await dispatch(
      openCreateFormDrawerBooking({
        stationName: stationName,
        stationUuid: stationUuid,
      }),
    )
    dispatch(
      setRoom({
        key: 'meetingRoomUuid',
        value: uuid,
        bookingDate: dateNow,
        roomIndex: 0,
      }),
    )
  }

export const handleRoomLabel = (room) => {
  const meetingRoom = _.get(room, 'meetingRoom', null)
  const meetingRoomOther = _.get(room, 'meetingRoomOther', null)
  const isOnline = _.get(room, 'isOnline', false)
  let roomLabel = 'Other'
  if (isOnline) roomLabel = 'Online'
  else {
    if (!_.isNull(meetingRoom)) {
      roomLabel = `${meetingRoom?.station?.station} - ${meetingRoom?.roomName} (ชั้น ${meetingRoom?.floor})`
    } else if (!_.isNull(meetingRoomOther)) {
      roomLabel += `- ${meetingRoomOther}`
    }
  }
  return roomLabel
}
