import _ from 'lodash'
import { setChangeBody } from '../../../../../../redux/slices/manageContent/elearning'

export const handleFilterOption = (optionProps) => {
  const { data, levelDistribution, option, keyInfo } = optionProps
  const levelDistributionObj = _.get(data, keyInfo, []).find(
    (item) => item.uuid == levelDistribution,
  )
  const distribution = _.get(levelDistributionObj, 'distribution.uuid', '')
  const filteredOption = option.filter((item) => {
    const distributionData = _.get(item, 'setting.distribution', [])
    const distributionArray = distributionData.map((item) => item.value)
    const isinDistribution = distributionArray.indexOf(distribution) > -1
    return isinDistribution
  })
  return filteredOption
}

export const handleSelectTabBody = (e, key, option) => (dispatch) => {
  const uuid = _.get(e.target, 'id', '')
  const value = option.find((item) => item.value == uuid)
  const payload = { value, key }
  dispatch(setChangeBody(payload))
}

export const handleSwitchBody = (e, keyField) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  const payload = { value: checked, key: `${keyField}.${name}` }
  dispatch(setChangeBody(payload))
}
