import React from 'react'
import { StyledHeaderContainer } from '../../Styled'
import MoreDropdownButton from './DropdownButton'

const Header = ({ onHistoryClick, fetchData, id }) => {
  return (
    <StyledHeaderContainer>
      <MoreDropdownButton
        onHistoryClick={onHistoryClick}
        fetchData={fetchData}
        id={id}
      />
    </StyledHeaderContainer>
  )
}
export default Header
