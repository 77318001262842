import React from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import _ from 'lodash'
import { themeColor } from '../../../../utils/themeData'

const StyledTable = styled('table')(() => ({
  borderSpacing: 0,
}))

const StyledRowHead = styled('tr')({
  width: '100%',
  display: 'flex',
  backgroundColor: `${themeColor.background.blue}`,
})

const StyledRow = styled('tr')(() => ({
  display: 'flex',
}))

const StyledCol = styled('td')(({ width }) => ({
  width: width,
  padding: '8px',
  border: `0.1px solid ${themeColor.blue.blueLine}`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}))

export const validateEmpText = (text) => {
  return text !== '' ? text : 'ไม่ได้ระบุ'
}

const Table = (props) => {
  const { list, headCells, title } = props
  return (
    <>
      {title && (
        <Typography variant="body1b" sx={{ ml: 3 }}>
          {title}
        </Typography>
      )}
      <StyledTable sx={{ ml: 3 }}>
        <StyledRowHead>
          {headCells.map((item, index) => {
            const width = _.get(item, 'width', '150px')
            return (
              <StyledCol width={width} key={index}>
                <Typography variant="body1b">{item.label}</Typography>
              </StyledCol>
            )
          })}
        </StyledRowHead>
        {list.length &&
          list.map((item, index) => {
            return (
              <StyledRow key={index}>
                {headCells.map((cell, index) => {
                  const width = _.get(cell, 'width', '150px')
                  return (
                    <StyledCol width={width} key={index}>
                      {index === 0 && item.default === true && (
                        <CheckCircleIcon
                          sx={{
                            width: '16px',
                            verticalAlign: 'middle',
                            mr: 0.5,
                          }}
                          color="primary"
                        />
                      )}
                      <Typography variant="body2">
                        {item[`${cell.id}`]}
                      </Typography>
                    </StyledCol>
                  )
                })}
              </StyledRow>
            )
          })}
      </StyledTable>
    </>
  )
}

export default Table
