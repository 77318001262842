import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'
import { 
  getExaminationGender,
  getExaminationQualification,
  getExaminationAddress,
  getExaminationUserProfile,
  sendExaminationIdCard,
} from '../../../../services/eExamination/form'
import { manageOtherDocsName } from './event'

const eExaminerForm = createSlice({
  name: 'eExaminerForm',
  initialState,
  reducers: {
    setInitialCreateForm: (state, { payload }) => {
      state.body = {
        ...initialState.body,
        ...payload,
      }
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)
    },
    setAddressList: (state, { payload }) => {
      state.addressList = payload
    },
    setExaminerDetail: (state, { payload }) => {
      state.eExaminerDetail = payload
    },
    startLoading: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoading: (state) => {
      state.isLoadingDrawer = false
    },
    setUserDetail: (state, {payload}) => {
      state.userDetail = payload
    },
    setLoading: (state, {payload}) => {
      state.loading = payload
    },
    setOtherDocs: (state, { payload }) => {
      state.body.otherDocs = payload
    },
    removeOtherDocs: (state, { payload }) => {
      const { imageIndex } = payload
      // state.body.otherDocs = state.body.otherDocs.filter((item, itemIndex) => itemIndex !== imageIndex)
      const otherDocs = manageOtherDocsName(
        state.body.otherDocs.filter((item, itemIndex) => itemIndex !== imageIndex), 
        null
      )
      state.body.otherDocs = otherDocs
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getExaminationGender.fulfilled, (state, {payload}) => {
      state.genderList = payload.data.result
    })
    .addCase(getExaminationQualification.fulfilled, (state, {payload}) => {
      state.qualificationList = payload.data.result
    })
    .addCase(getExaminationAddress.pending, (state) => {
      state.loadingAddr = true
    })
    .addCase(getExaminationAddress.fulfilled, (state, {payload}) => {
      state.addressList = payload.data.result
      state.loadingAddr = false
    })
    .addCase(getExaminationAddress.rejected, (state) => {
      state.loadingAddr = false
    })
    .addCase(getExaminationUserProfile.pending, (state) => {
      state.isLoadingDrawer = true
    })
    .addCase(getExaminationUserProfile.rejected, (state) => {
      state.isLoadingDrawer = false
      // state.error = action.meta?.message
    })
    .addCase(getExaminationUserProfile.fulfilled, (state, {payload}) => {
      state.userDetail = payload.data
      state.isLoadingDrawer = false
    })
    .addCase(sendExaminationIdCard.pending, (state) => {
      state.isLoadingDrawer = true
    })
    .addCase(sendExaminationIdCard.rejected, (state) => {
      state.isLoadingDrawer = false
    })
    .addCase(sendExaminationIdCard.fulfilled, (state, {payload}) => {
      _.set(state.body, 'idCardImageKey', payload.data.key)
      state.isLoadingDrawer = false
    })
  },
})

export const { 
  setInitialCreateForm,
  setBody,
  setFieldValue,
  setAddressList,
  setExaminerDetail,
  startLoading,
  stopLoading,
  setUserDetail,
  setLoading,
  setOtherDocs,
  removeOtherDocs,
} =
eExaminerForm.actions
export default eExaminerForm.reducer
