import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import QrCodeOutlined from '@mui/icons-material/QrCodeOutlined'
import * as Styled from './Styled'
import { handleChange } from '../events'
import { TooltipForm } from '../../AssessmentForm/Styled'
import { QRCodeTemplate } from '../../../../../components/QRCodeTemplate'
import { useTheme } from '@mui/system'
import { downloadQRCode } from '../../../../../utils/lib'

const Index = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isShare, shareLink, status, evaName } = useSelector(
    (state) => ({
      isShare: state.eEvaluationForm.setting.isShare,
      shareLink: state.eEvaluationForm.setting.shareLink,
      status: state.eEvaluationForm.setting.status,
      evaName: state.eEvaluationForm.information.name,
    }),
    shallowEqual,
  )

  return (
    <Card sx={{ mt: 2, position: 'relative' }}>
      <QRCodeTemplate
        id="share-qr-code"
        isHidden={true}
        link={shareLink}
        title="QR Code แบบประเมิน"
        subtitle={evaName}
        titleSx={{ mt: 0 }}
      />
      <Styled.BoxShare>
        <Typography variant="h6">แชร์แบบประเมิน</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {status !== 'ACTIVE' && (
            <TooltipForm
              maxWidth={180}
              title={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="tooltip">
                    สามารถเปิดแชร์ได้เมื่อแบบประเมินมี
                  </Typography>
                  <Typography variant="tooltip">สถานะเปิดใช้งาน</Typography>
                </Box>
              }
              arrow
              placement="bottom"
            >
              <Styled.IconAlertShare />
            </TooltipForm>
          )}
          <Switch
            disabled={false}
            data-testid="switch-setting"
            checked={isShare}
            onChange={(e) =>
              dispatch(
                handleChange({
                  value: _.get(e, 'target.checked', false),
                  key: 'isShare',
                }),
              )
            }
          />
        </Box>
      </Styled.BoxShare>
      {isShare && (
        <Styled.BoxQRCode>
          <QRCodeTemplate
            id="show-qr-code"
            boxSx={{
              border: `1px solid ${theme?.palette?.silver?.primary}`,
            }}
            size={106}
            link={shareLink}
            variantTitle={'body1b'}
            title="QR Code แบบประเมิน"
            subtitle={evaName}
            titleSx={{ fontSize: 14 }}
          />
          <Styled.BoxInput>
            <Styled.InputUrl disabled value={'  ' + shareLink} />

            <Styled.BoxButton>
              <Button
                sx={{
                  width: 182,
                }}
                data-testid="btn-download"
                variant="outlined"
                size="m"
                startIcon={<QrCodeOutlined />}
                onClick={() =>
                  downloadQRCode('share-qr-code', 'QR แบบประเมิน ' + evaName)
                }
              >
                <Typography variant="buttonm">ดาวน์โหลด QR Code</Typography>
              </Button>
              <Button
                sx={{
                  width: 124,
                }}
                data-testid="btn-copy-link"
                variant="outlined"
                size="m"
                startIcon={<ContentCopyOutlined />}
                onClick={() => navigator?.clipboard?.writeText(shareLink)}
              >
                <Typography variant="buttonm">คัดลอกลิงก์</Typography>
              </Button>
            </Styled.BoxButton>
          </Styled.BoxInput>
        </Styled.BoxQRCode>
      )}
    </Card>
  )
}

export default Index
