import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
export const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    deleted: theme?.palette?.text?.white,
    waiting: theme?.palette?.warning?.light,
    waiting_for_active: theme?.palette?.warning?.light,
    approve: theme?.palette?.success?.main,
    reject: theme?.palette?.error?.main,
    draft: theme?.palette?.silver?.primary,
    cancel: theme?.palette?.error?.main,
    ready: theme?.palette?.success?.main,
    readyinactive: theme?.palette?.text?.silver,
  }
  return colorList[`${statusText}`]
}
const Dot = ({ sx, status }) => {
  const theme = useTheme()
  return (
    <DotStyle
      sx={sx}
      status={status}
      color={checkColorStatus(status, theme)}
    ></DotStyle>
  )
}
export default Dot

const DotStyle = styled('div')(({ theme, status, color, sx }) => ({
  borderRadius: 5,
  border:
    status?.toString().toLowerCase() === 'deleted' ? '1px solid' : 'unset',
  backgroundColor: color,
  borderColor:
    status?.toString().toLowerCase() === 'deleted'
      ? theme?.palette?.text?.gray
      : 'unset',
  width: 8,
  height: 8,
  ...sx,
}))
