import _ from 'lodash'
import { store } from '../../../App'
import {
  resetTablePage,
  setDefaultSort,
  setFilterProp,
  setFilterTotal,
  setInitialTable,
  setOrder,
  setSearchText,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table2'
import callAxios from '../../../utils/baseService'
import { agentProfileETetsingView } from '../../../utils/apiPath'
import { convertFormatDateTime } from '../../../utils/lib'
import { typeOfETesting } from '../../../constants/eTesting'
import { agentProfileETesting } from '../../../constants/manage/agentProfile'

export const getTableState = () => {
  const state = store.getState()
  return _.get(state, 'table2', {})
}

export const handleETestingTable = (props) => async (dispatch) => {
  const tableState = getTableState()
  const { table, search } = tableState
  const userId = _.get(props, 'userId', '')
  const page = _.get(props, 'page', _.get(table, 'page', -1))
  const realPage = page <= 0 ? 1 : +page
  const sort = _.get(table, 'sort', 'updatedAt')
  const body = {
    limit: _.get(table, 'limit', 20),
    page: realPage,
    order: _.get(table, 'order', 'desc').toString().toUpperCase(),
    sort,
    userProfileUuid: userId,
    keyword: search.searchText || '',
  }

  dispatch(startLoading())
  let response = await callAxios.post(agentProfileETetsingView, body)
  dispatch(stopLoading())
  const initTable = dispatch(handleInitTable(response, userId))
  dispatch(setInitialTable(initTable))
}

export const handleInitTable = (response, userId) => (dispatch) => {
  const payload = _.get(response, 'data', {})
  const rows = _.map(_.get(payload, 'result', []), (item) => {
    return {
      testResultTH: item.testResultTH,
      updatedAt: convertFormatDateTime({
        value: item.endTestDateTime,
        type: 'date',
      }),
      testName: _.get(item, 'reportEtesting.testName', ''),
      uuid: _.get(item, 'reportEtesting.eTestingVersion.uuid', ''),
      version: _.get(item, 'reportEtesting.eTestingVersion.version', ''),
      typeOfETesting:
        typeOfETesting[item.reportEtesting?.typeOfETesting] || '-',
    }
  })
  return {
    isCheckBox: false,
    isShowTotal: true,
    rows,
    allCount: _.get(payload, 'totalCount', 0),
    headCells: agentProfileETesting,
    placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
    searchKey: 'keyword',
    tableHeadText: 'ประวัติการทำแบบทดสอบ',
    tableHeadVariant: 'h6',
    handleSearch: (text) => dispatch(handleQuickSearch(text, userId)),
  }
}

export const handleQuickSearch = (keyword, userId) => (dispatch) => {
  const tableState = getTableState()
  const sort = _.get(tableState.table, 'sort', 'updatedAt')
  dispatch(setSearchText(keyword))
  dispatch(resetTabelState())
  if (sort === 'updatedAt') dispatch(handleETestingTable({ page: 1, userId }))
}

export const resetTabelState = () => (dispatch) => {
  dispatch(setFilterProp(null))
  dispatch(setFilterTotal(0))
  dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  dispatch(resetTablePage())
  dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
}
