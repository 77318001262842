import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledInputBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.other?.divider,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
}))
