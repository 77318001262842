import _ from 'lodash'
import { defaultDataWorkload } from '../Report/model'
import { distributionFilter } from 'src/utils/apiPath'
import axios from 'axios'
import { COLUMN_NAME, COLUMN_NAME_TH } from 'src/constants/report/monthlyPlan'

const body = {
  abbreviation: '',
  distribution: '',
  level: [],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'distribution',
  status: ['ACTIVE'],
}

export const getDefaultDataWorkload = async () => {
  const distribution = await axios({
    method: 'post',
    url: distributionFilter,
    data: body,
  }).then((res) => _.get(res, 'data.result', []))

  const detailsDistribution = []
  const updatedDataWorkload = _.map(defaultDataWorkload, (data) => {
    if (_.get(data, 'workType', '') === 'Training') {
      const details = _.get(data, 'details', [])
      _.map(details, (dt) => {
        const trainingType = _.get(dt, 'trainingType', '')

        detailsDistribution.push(dt)
        _.map(distribution, (dis) => {
          detailsDistribution.push({
            list: _.get(dis, 'distribution', ''),
            key: `${trainingType}${_.get(dis, 'abbreviation', '')}`,
          })
        })
        detailsDistribution.push({
          list: COLUMN_NAME_TH.TOTAL,
          key:
            trainingType === 'classroom'
              ? COLUMN_NAME.CLASSROOM_TOTAL
              : COLUMN_NAME.ONLINE_TOTAL,
        })
      })
      return { ...data, details: detailsDistribution }
    }
    return data
  })

  return updatedDataWorkload
}
