import * as yup from 'yup'

export const schema = yup.object().shape({
  courseType: yup.string().nullable(),
  openRegisterDate: yup.string().required('กรุณาเลือกวันเปิดรับสมัคร'),
  openRegisterTime: yup.string().required('กรุณาเลือกเวลาเปิดรับสมัคร'),
  endRegisterDate: yup.string().required('กรุณาเลือกวันปิดรับสมัคร'),
  endRegisterTime: yup.string().required('กรุณาเลือกเวลาปิดรับสมัคร'),
  display: yup
    .boolean()
    .test('require-date', 'กรุณาเลือกช่วงเวลารอบอบรม', (value) => value),
  speaker: yup.array().min(1, 'กรุณาเลือกวิทยากรอย่างน้อย 1 ท่าน'),
  trainerQuestion: yup
    .array()
    .min(1, 'กรุณาเลือกวิทยากรตอบคำถามอย่างน้อย 1 ท่าน'),
  admin: yup.array().min(1, 'กรุณาเลือก Admin อย่างน้อย 1 ท่าน'),
})
