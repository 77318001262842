import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import Carousel from '../../../../../components/Carousel'
import {
  StyledHeaderContent,
  StyledHomeHeader,
  StyledImagePreview,
  StyledOverlayBg,
  StyledSecondaryButton,
  StyledTypography,
  StyledVideoPreview,
} from './Styled'
import { handleButtonSize, handleClickBtn } from './events'

const HomeHeader = ({ bannerSection }) => {
  const bannerList = bannerSection.filter((item) => item.status)
  return (
    <StyledHomeHeader>
      <Carousel>
        {bannerList.length > 0 &&
          bannerList.map((item, index) => (
            <BannerMedia key={index} data={item} />
          ))}
      </Carousel>
    </StyledHomeHeader>
  )
}

export default HomeHeader

export const BannerMedia = ({ data }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const mediaType = _.get(data, 'mediaType', '')
  const isVideo = mediaType == 'VIDEO'
  const image = _.get(data, 'image', '')
  const mobileImage = isVideo
    ? _.get(data, 'videoImg', '')
    : _.get(data, 'mobileImg', '')
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file/${
    isNotSm ? image : mobileImage
  }`
  return (
    <div>
      <StyledOverlayBg />
      {isVideo && isNotSm ? (
        <StyledVideoPreview url={_.get(data, 'videoURL', '')} />
      ) : (
        <StyledImagePreview src={imageSrc} />
      )}
      {isNotSm && <HeaderContent data={data} />}
    </div>
  )
}

export const HeaderContent = ({ data }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const btnSize = handleButtonSize()
  return (
    <StyledHeaderContent>
      <StyledTypography
        variant={isLg ? 'h6' : 'body2b'}
        color={isNotSm ? 'text.white' : 'text.secondary'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'subtitle', '') }}
      />
      <StyledTypography
        variant={isLg ? 'h2' : 'h4'}
        color={isNotSm ? 'text.white' : 'primary.main'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'title', '') }}
      />
      <StyledTypography
        id="content"
        color={isNotSm ? 'text.white' : 'text.secondary'}
        dangerouslySetInnerHTML={{ __html: _.get(data, 'description', '') }}
      />
      {data.primaryStatus && (
        <Button
          size={btnSize}
          sx={{ width: isNotSm ? 200 : 'auto' }}
          data-testid="btn-primary"
          variant="contained"
          color={_.toLower(_.get(data, 'colorButton', 'PRIMARY'))}
          onClick={() => handleClickBtn(_.get(data, 'primaryButtonURL', ''))}
        >
          {_.get(data, 'primaryButtonTitle', '')}
        </Button>
      )}
      {data.secondaryStatus && (
        <StyledSecondaryButton
          size={btnSize}
          data-testid="btn-secondary"
          variant="outlined"
          onClick={() => handleClickBtn(_.get(data, 'secondaryButtonURL', ''))}
        >
          {_.get(data, 'secondaryButtonTitle', '')}
        </StyledSecondaryButton>
      )}
    </StyledHeaderContent>
  )
}
