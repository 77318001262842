import React from 'react'
import { StyledCircularBox, StyledCircularLoader } from '../Styled'

const CircularContent = ({ isLoading, color = '#00008F' }) => {
  if (!isLoading) return <></>
  return (
    <StyledCircularBox id='test'>
      <StyledCircularLoader sx={{ color }} />
    </StyledCircularBox>
  )
}

export default CircularContent
