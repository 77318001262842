import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useLocalStorage } from '@rehooks/local-storage'
import { useHistory } from 'react-router-dom'
import Upload from './Upload'
import TextInput from '../../components/Input/TextInput'
import SwitchInput from '../../components/Input/SwitchInput'
import AlertDialog from '../../components/AlertDialog'
import SelectGroupChip from '../../components/SelectGroupChip'
import { hasPermissionCreateMasterData } from '../../utils/lib'

export const handleDeleteFile = (formik) => {
  formik.setFieldValue('imgKey', '')
}

const RoadMapForm = (props) => {
  const {
    isEditForm,
    dialog,
    setDialog,
    handleClose,
    formik,
    initialValues,
    setInitialValues,
    distribution,
    onChange,
    handleUploadFile,
  } = props

  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  return (
    <Card
      sx={{
        minWidth: 275,
        mx: 3,
        mt: 3,
        px: 3,
        pt: 2,
        pb: 1,
        overflow: 'unset',
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Upload
          handleUploadFile={handleUploadFile}
          value={formik.values.imgKey}
          textError={formik?.errors?.imgKey}
          style={{ width: 120, height: 120, background: '#3B3FD8' }}
          formik={formik}
          setDialog={setDialog}
          disabled={!hasPermissionCreate}
          onDelete={handleDeleteFile}
          name="logo"
        />

        {isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              labelText="ID"
              type="basic"
              disabled
              required
              value={initialValues.id}
            ></TextInput>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3,
          }}
        >
          <TextInput
            id="title"
            name="title"
            labelText="หัวข้อ"
            type="basic"
            inputProps={{
              'data-testid': 'title',
            }}
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.title}
            required
            value={initialValues.title}
            onChange={(e) => {
              formik.values.title = e.target.value
              setInitialValues({
                ...initialValues,
                title: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
          <TextInput
            id="abbreviation"
            name="abbreviation"
            labelText="ชื่อย่อ"
            type="basic"
            inputProps={{
              'data-testid': 'abbreviation',
            }}
            textError={formik?.errors?.abbreviation}
            required
            disabled={!hasPermissionCreate}
            value={initialValues.abbreviation}
            onChange={(e) => {
              formik.values.abbreviation = e.target.value
              setInitialValues({
                ...initialValues,
                abbreviation: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 2,
          }}
        >
          <SelectGroupChip
            id="distribution"
            name="distribution"
            required
            placeholder={'เลือกช่องทางการจำหน่าย'}
            labelText="ช่องทางการจำหน่าย"
            disabled={!hasPermissionCreate}
            options={distribution}
            onChange={onChange}
            value={initialValues.distribution}
            textError={formik?.errors?.distribution}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3,
          }}
        >
          <TextInput
            id="description"
            name="description"
            inputProps={{
              'data-testid': 'remark',
            }}
            labelText="รายละเอียด (สำหรับแสดงหน้า Learner)"
            type="basic"
            required
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.description}
            value={initialValues.description}
            showMaxLength={60}
            onChange={(e) => {
              formik.values.description = e.target.value
              setInitialValues({
                ...initialValues,
                description: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
            multiline={true}
            rows={2}
          ></TextInput>
          <TextInput
            id="remark"
            name="remark"
            inputProps={{
              'data-testid': 'remark',
            }}
            labelText="หมายเหตุ"
            type="basic"
            disabled={!hasPermissionCreate}
            textError={formik?.errors?.remark}
            value={initialValues.remark}
            onChange={(e) => {
              formik.values.remark = e.target.value
              setInitialValues({ ...initialValues, remark: e.target.value })
            }}
            onBlur={formik.handleBlur}
            multiline={true}
            rows={2}
          ></TextInput>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            defaultChecked
            placeholder=""
            disabled={!hasPermissionCreate}
            value={initialValues.status}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
          ></SwitchInput>
        </Box>
        <Box
          sx={{
            mt: 1,
            mb: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              disabled={!hasPermissionCreate}
              size="m"
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          {isEditForm && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                },
              }}
            >
              <Typography variant="caption" color="text.lightGray">
                แก้ไขล่าสุดโดย{' '}
                {initialValues && initialValues.updatedBy
                  ? initialValues.updatedBy
                  : ''}
                <br />
                {initialValues && initialValues.updatedAt
                  ? initialValues.updatedAt
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </Card>
  )
}
export default RoadMapForm
