import { store } from '../../../App'
import {
  setIsFilter,
  setFiltersData,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import { fetchReports } from './fetchReports'

export const handleSaveFilters = () => async (dispatch) => {
  const { displayFilters, rowsPerPage, page, columnOptions } =
    store.getState().reportComp
  await dispatch(setIsFilter(true))
  await dispatch(setFiltersData(displayFilters))
  await dispatch(
    setReduxValue({ key: 'filterColumnOptionsMemo', value: columnOptions }),
  )
  await fetchReports()
  await dispatch(setReduxValue({ key: 'page', value: page }))
  await dispatch(setReduxValue({ key: 'rowsPerPage', value: rowsPerPage }))
}
