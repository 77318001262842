import { Typography } from '@mui/material'
import React from 'react'

const Text = ({ data }) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        '& p': {
          margin: '0',
        },
        '& h1 , h2 , h3 , h4 , h5 , h6': {
          margin: '0',
        },
        '& ul , ol': {
          margin: '0px',
          lineHeight: '12px',
        },
      }}
      dangerouslySetInnerHTML={{
        __html: data.content.text,
      }}
    />
  )
}

export default Text
