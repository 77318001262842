import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { handleRadioChange } from './events'
import {
  StyledHeadContainer,
  StyledQuestionContainer,
  StyledQuestionList,
  StyledRadioContainer,
} from './Styled'

const TrueFalse = ({ keyQuestion, question, isSubmit, questionResult }) => {
  const choices = _.get(question, 'choices', [])
  const answer = _.get(question, 'answer', [])

  const dispatch = useDispatch()

  return (
    <>
      <StyledHeadContainer>
        <Typography color="text.secondary" variant="body1b">
          ใช่
        </Typography>
        <Typography color="text.secondary" variant="body1b">
          ไม่ใช่
        </Typography>
      </StyledHeadContainer>
      {choices.map((choice, index) => {
        const answerObj = answer.find((ans) => ans.questionUuid === choice.uuid)
        const value = _.get(answerObj, 'booleanAns', '')
        const isAnswer = _.get(answerObj, 'isCorrect', false)
        return (
          <StyledQuestionList
            key={index}
            resultProps={{ isSubmit, isAnswer, questionResult }}
          >
            <StyledQuestionContainer>
              <Typography variant="body1" sx={{ minWidth: 15 }}>
                {index + 1} )
              </Typography>
              <Typography sx={{ wordBreak: 'break-word' }} variant="body1">
                {choice.title}
              </Typography>
            </StyledQuestionContainer>
            <RadioGroup
              value={value}
              onClick={(e) =>
                dispatch(handleRadioChange(e, answer, choice, keyQuestion))
              }
            >
              <StyledRadioContainer>
                <FormControlLabel value={true} control={<Radio />} />
                <FormControlLabel value={false} control={<Radio />} />
              </StyledRadioContainer>
            </RadioGroup>
          </StyledQuestionList>
        )
      })}
    </>
  )
}

export default TrueFalse
