import _ from 'lodash'

export const handleInitialForm = (initialValues, setElectronic, formik) => {
  const electronic = _.get(initialValues, 'electronic', [])
  setElectronic(electronic)
  formik.setFieldValue('electronic', electronic)
}

export const handleChangeIsDownload = (
  initialValues,
  setInitialValues,
  index
) => {
  const electronic = _.get(initialValues, 'electronic', [])
  const list = _.map(electronic, (item) => {
    if (item.index === index) {
      return { ...item, isDownload: !item.isDownload }
    }
    return { ...item }
  })
  setInitialValues((pre) => ({ ...pre, electronic: list }))
}

export const handleChangeRemove = (initialValues, setInitialValues, index) => {
  const electronic = _.get(initialValues, 'electronic', [])
  const list = _.filter(electronic, (item) => item.index !== index)
  setInitialValues((pre) => ({ ...pre, electronic: list }))
}
