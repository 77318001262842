import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledEligibleDialog,
  StyledColumnFlex,
  StyledColumnNoGap,
  StyledRespRow,
} from '../../Styled'
import TextInput from '../../../../components/Input/TextInput'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleSubmitEligibleExaminer, handleCloseDialog } from './event'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import { useParams } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { handleEnableButton } from './event'
import _ from 'lodash'

const EligibleExaminerDialog = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const [annotation, setAnnotation] = useState('')
  const { selected, rows, isLoading, disableButton } = useSelector(
    (state) => ({
      selected: state.table.table.selected,
      rows: state.table.table.rows,
      isLoading: state.dialog.isLoading,
      disableButton: state.dialog.disableButton,
      announcement: state.eExamList.announcement,
    }),
    shallowEqual,
  )

  return (
    <StyledEligibleDialog sx={{ pb: 0 }}>
      <form>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h6">
            ปรับสถานะรอตรวจสอบเป็นไม่มีสิทธิ์สอบ
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">
            ระบบจะปรับสถานะให้ผู้ถูกเลือกทั้งหมดว่า <span>ไม่มีสิทธิ์สอบ</span>{' '}
          </Typography>
          <Typography variant="body1">
            และส่ง Email แจ้งเตือนให้รายชื่อผู้ถูกเลือกทั้งหมดว่าไม่มีสิทธิ์สอบ
          </Typography>
        </Box>

        <StyledColumnFlex>
          <StyledColumnNoGap noGap>
            <StyledRespRow isInput>
              <TextInput
                id="annotation"
                name="annotation"
                type="basic"
                labelText="หมายเหตุ"
                multiline={true}
                rows={3}
                maxlength={3000}
                value={_.defaultTo(annotation, '')}
                onChange={(e) => {
                  setAnnotation(e.target.value)
                }}
              />
            </StyledRespRow>
          </StyledColumnNoGap>
        </StyledColumnFlex>

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'data-testid': 'checkbox-status' }}
                defaultChecked={false}
                onChange={(e) => dispatch(handleEnableButton(e))}
              />
            }
            label={'ฉันรับทราบเงื่อนไขและต้องการปรับสถานะ'}
          />
        </Box>

        <StyledDialogActions sx={{ p: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => dispatch(handleCloseDialog())}
              variant="outlined"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(
                handleSubmitEligibleExaminer(uuid, selected, rows, annotation),
              )
            }
            color="primary"
            endIcon=""
            disabled={disableButton}
          >
            ตกลง
          </StyledLoadingButton>
        </StyledDialogActions>
      </form>
    </StyledEligibleDialog>
  )
}

export default EligibleExaminerDialog
