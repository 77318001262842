import _ from 'lodash'
import { MODULE_TYPE } from '../../../../constants/eLearning'
import {
  CRITERIA_TYPE,
  E_TESTING_TYPE,
} from '../../../../modules/ELearning/Module/Form/TypeContent/ETesting/model'

export const handleModuleBody = (data) => {
  const moduleType = _.get(data, 'moduleType', '')
  const typeField = _.camelCase(moduleType)
  const moduleDetail = { ..._.get(data, 'moduleDetail', {}) }
  const eLearningModuleFileUpload = _.get(data, 'eLearningModuleFileUpload', [])

  if (
    moduleType === MODULE_TYPE.E_TESTING ||
    moduleType === MODULE_TYPE.E_EVALUATION
  ) {
    moduleDetail.uuid = _.get(data, `${typeField}.uuid`, '')
  }

  return {
    ...data,
    description: data.description || '',
    [typeField]:
      moduleType === MODULE_TYPE.UPLOAD_FILE
        ? eLearningModuleFileUpload
        : moduleDetail,
  }
}

export const handleSetModuleBody = (state, data) => {
  const moduleType = _.get(data, 'moduleType', '')
  const body = handleModuleBody(data)
  _.set(state, 'body', body)
  _.set(state, 'isDisableButton', false)
  _.set(state, 'isVideoLoading', moduleType === MODULE_TYPE.EMBED_CONTENT)
}

export const handleValidateButton = (state) => {
  const body = _.get(state, 'body', {})

  if (!_.isEmpty(body.name) && !_.isEmpty(body.moduleType)) {
    const typeKey = _.camelCase(body.moduleType)
    const data = _.get(body, typeKey, {})
    let isDisable = true
    switch (body.moduleType) {
      case MODULE_TYPE.E_TESTING:
        isDisable = validateETesting(data, isDisable)
        break
      case MODULE_TYPE.E_EVALUATION:
        isDisable = validateEEvaluation(data)
        break
      case MODULE_TYPE.EMBED_CONTENT:
        isDisable = validateEmbedContent(data)
        break
      case MODULE_TYPE.LINK_TO:
        isDisable = validateLinkToWebsite(data)
        break
      case MODULE_TYPE.UPLOAD_FILE:
        isDisable = validateUploadFile(data)
        break
      case MODULE_TYPE.SCORM:
        isDisable = validateScorm(data)
        break
      default:
        isDisable = true
    }
    _.set(state, 'isDisableButton', isDisable)
  } else _.set(state, 'isDisableButton', true)
}

export const validateETesting = (data, isDisable) => {
  if (_.isEmpty(data.type) || _.isEmpty(data.uuid)) {
    isDisable = true
  } else {
    if (data.type === E_TESTING_TYPE.ONLINE_TEST) {
      const criteriaType = data.criteriaType
      isDisable = _.isEmpty(criteriaType)
      if (criteriaType === CRITERIA_TYPE.REQUIRED_PERCENT) {
        const criteriaPercent = data.criteriaPercent
        isDisable = _.isNil(criteriaPercent) || criteriaPercent === ''
      }
    } else isDisable = false
  }
  return isDisable
}

export const validateEEvaluation = (data) => {
  return _.isEmpty(data.type) || _.isEmpty(data.uuid)
}

export const validateEmbedContent = (data) => {
  return _.isEmpty(data.type) || _.isEmpty(data.value)
}

export const validateLinkToWebsite = (data) => {
  return _.isEmpty(data.link) || _.isEmpty(data.linkDescription)
}

export const validateUploadFile = (data) => {
  return (
    _.isEmpty(_.filter(data, (file) => file.uuid)) ||
    data.some((file) => file.error)
  )
}

export const validateScorm = (data) => {
  return _.isEmpty(data.uuid) || data.error
}
