import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { openDialog } from '../../../../redux/slices/dialog'
import { filtersDrawerEnum, overviewEnum } from '../enum/enum'
import { checkUpdateStatus } from './checkUpdateStatus'
import { fetchStation } from './fetchStation'
import { fetchOverview } from './fetchOverview'

export const initialize = async (user) => {
  await checkUpdateStatus()
  const { updateStatus } = store.getState().crud.roomManagementDashboard
  if (updateStatus === 'SUCCESS') {
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer',
        value: filtersDrawerEnum,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.overview',
        value: overviewEnum,
      }),
    )
    await fetchStation(user)
    await fetchOverview()
  } else if (updateStatus === 'INPROGRESS') {
    store.dispatch(
      setFieldValue({
        key: 'isLoading',
        value: false,
      }),
    )
    store.dispatch(
      openDialog({
        type: 'confirm',
        title: 'กำลังดำเนินการ',
        message: 'ระบบกำลังอัปเดตข้อมูล กรุณารอสักครู่',
        hideCancel: true,
        handleConfirm: () => window.location.reload(),
        handleCancel: () => window.location.reload(),
      }),
    )
  } else if (updateStatus === 'FAILED') {
    store.dispatch(
      setFieldValue({
        key: 'isLoading',
        value: false,
      }),
    )
    store.dispatch(
      openDialog({
        type: 'fail',
        title: 'ระบบอัปเดตข้อมูลผิดพลาด',
        message: 'กรุณาอัปเดตข้อมูลอีกครั้ง',
        hideCancel: true,
        handleConfirm: () => window.location.reload(),
        handleCancel: () => window.location.reload(),
      }),
    )
  }
}
