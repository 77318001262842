import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const CardHeader = styled(Card)(({ theme, isQuestion }) => ({
  borderTop: `4px solid ${
    !isQuestion ? theme?.palette?.text?.mercury : theme?.palette?.primary?.main
  }`,
  marginBottom: !isQuestion ? theme.spacing(3) : theme.spacing(0.5),
}))

export const BoxContent = styled(Box)(({ theme, isLecturer }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: isLecturer ? 'column' : 'row',
  },
}))

export const BoxRight = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))
