import styled from '@mui/system/styled'
import { Row } from '../../../../../../../../components/Layout/Styled'

export const StyledImgDashedBox = styled(Row)(({ theme, selected }) => ({
  padding: theme.spacing(4),
  border: '2px dashed',
  borderColor: theme.palette?.blue?.blueBorder,
  borderRadius: theme.spacing(1),
  width: selected ? 969 : 468,
  height: selected ? 544 : 304,
  justifyContent: 'center',
  cursor: selected ?'default': 'pointer',
  img: { height: '100%', objectFit: 'cover' },
}))
