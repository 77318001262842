import _ from 'lodash'
import { setIsOpenFilter } from '../../../../../redux/slices/eLearning/dashboard'
import { onFilterClick } from '../../events'
import { defaultFilter } from '../../model'

export const handleFiltering = (filter) => (dispatch) => {
  dispatch(setIsOpenFilter(false))
  dispatch(onFilterClick(filter))
}

export const handleChange = ({
  key,
  value,
  filter,
  setFilter,
  listKey,
  isCheck,
}) => {
  let objState = {}
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  } else if (isCheck) {
    const subKey = key.replace('Checked', '')
    objState = {
      ...filter,
      [key]: value,
      [subKey]: defaultFilter[subKey],
    }
  } else {
    objState = {
      ...filter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}
