import React from 'react'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { useHistory } from 'react-router-dom'

const ActionDetail = ({ condition, row }) => {
  let viewUrl = `${condition?.viewPath}/${row?.uuid}`
  const history = useHistory()

  if (row.type && row.type === 'EL') {
    if (row.isOIC) {
      viewUrl = `/e-learning/course/batch/learner/view/${row?.uuid}`
    } else {
      viewUrl = `/e-learning/course/learner/view/${row?.uuid}?course=${row?.eLearningCourseUuid}`
    }
  }

  let actionFile = `${window.__env__.REACT_APP_API_URL}/file${row?.url}`
  if (!row?.isDowload) actionFile = row?.url

  return (
    <>
      <IconButton
        data-testid="btn-view"
        onClick={() =>
          _.get(row, 'files', false)
            ? window.open(`${actionFile}`, '_blank')
            : history.push(viewUrl)
        }
      >
        <RemoveRedEyeTwoTone />
      </IconButton>
    </>
  )
}

export default ActionDetail
