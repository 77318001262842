import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import { headCellsETestEEva } from '../../../../constants/manageClass'
import { setInitialTable } from '../../../../redux/slices/table/basicTable'
import { getETestingAndEEvaluation } from '../../../../services/manageClass/detail'
import Truncate from '../../../../components/redux/Table/Truncate'
import { openSuccessDialog } from '../../../../redux/slices/dialog'
import DropdownButton from './DropdownButton'
import ETestEEvaSwitch from './ETestEEvaSwitch'
import WaitToCheck from './WaitToCheck'
import WaitToAnnounce from './WaitToAnnounce'
import ShareDrawer from './ShareDrawer'

export const fetchETestEEvaList = (uuid) => async (dispatch) => {
  const response = await dispatch(getETestingAndEEvaluation(uuid))
  const payload = _.get(response, 'payload', undefined)
  if (payload) {
    const data = _.get(response, 'payload.data.result', [])
    dispatch(
      setInitialTable({
        rows: data.map((item) => ({
          ...item,
          submitCount: item.type === 'Assessment' ? '-' : item.submitCount,
          type:
            item.testAndEvaluateType === 'E_EVALUATION'
              ? 'E-Evaluation'
              : item.type,
        })),
        allCount: _.get(response, 'payload.data.totalCount', 0),
        headCells: headCellsETestEEva,
        tableHeadText: 'แบบทดสอบ / แบบประเมิน',
        isShowPagination: false,
      })
    )
  }
}

export const checkConditionETestEEva = (row, rowIndex, cellIndex) => {
  const headCell = headCellsETestEEva[cellIndex]
  const columnKey = headCell.id
  const minWidth = headCell.minWidth ?? 100
  const sx = { minWidth }

  if (columnKey === 'status') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <ETestEEvaSwitch row={row} />
      </TableCell>
    )
  }
  if (columnKey === 'action') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <DropdownButton
          id={`action-dropdown-${rowIndex}`}
          row={row}
          rowIndex={rowIndex}
        />
        <ShareDrawer index={rowIndex} />
      </TableCell>
    )
  }
  if (columnKey === 'submitCount') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <Typography variant="body2" color={mapColorLabel(row, columnKey, 100)}>
          {_.get(row, columnKey, '-')}
        </Typography>
      </TableCell>
    )
  }
  if (columnKey === 'waitingToCheck') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <WaitToCheck row={row} />
      </TableCell>
    )
  }
  if (columnKey === 'waitingToAnnounce') {
    return (
      <TableCell key={columnKey} sx={{ ...sx, width: 115 }}>
        <WaitToAnnounce row={row} />
      </TableCell>
    )
  }
  if (columnKey === 'startDate') {
    return (
      <TableCell key={columnKey} sx={sx}>
        {handleStartEnd(row)}
      </TableCell>
    )
  }
  return (
    <TableCell key={columnKey} sx={sx}>
      <Truncate>{'' + _.get(row, columnKey, '-')}</Truncate>
    </TableCell>
  )
}

export const mapColorLabel = (row, columnKey, goal) => {
  const value = _.get(row, columnKey, 0)
  return value == goal ? 'success.main' : 'text.primary'
}

export const handleSuccess = (isSuccess) => (dispatch) => {
  if (isSuccess) {
    dispatch(
      openSuccessDialog({ handleConfirm: () => window.location.reload() })
    )
  }
}

export const handleStartEnd = (row) => {
  const startDateTime = _.get(row, 'startDate', '') || '-'
  const expiredDateTime = _.get(row, 'expiredDate', '') || ''
  const splitStart = startDateTime.split(' ')
  const splitExpired = expiredDateTime.split(' ')
  const startDate = splitStart[0]
  const expiredDate = splitExpired[0]
  const expiredTime = splitExpired[1]

  return (
    <Typography variant="body2">
      {startDateTime} {expiredDateTime == '' ? '' : '- '}
      {startDate == expiredDate ? expiredTime : expiredDateTime}
    </Typography>
  )
}
