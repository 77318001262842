import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ToggleContent } from '../ToggleContent'
import { axisLabel, chartPart1Model, partTitle } from '../../models/partModel'
import { DataCard } from '../ModuleCard/DataCard'
import BarChart from 'src/components/Chart/BarChart'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { handleClickViewButton } from '../../handler/handleClickViewButton'
import { questionTypeText } from '../../constants/type'
import { InboxTwoTone } from '@mui/icons-material'
import { StyledBox } from '../../../../../../../modules/Inventory/Product/WarehouseList/Drawer/AssetDetail/components/SKU/Styled'

const TrainerEva = ({ isPrinting = false }) => {
  const { trainerEva } = useSelector(
    (state) => ({
      trainerEva: state.crud?.eEvaluationDashboard?.overview?.trainerEva,
    }),
    shallowEqual,
  )
  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={partTitle.part2}
            type={questionTypeText.RATING}
            contentId="trainerEva"
            alwaysOpen={true}
            hideIcon={true}
          >
            {_.defaultTo(trainerEva, []).length <= 0 && (
              <>
                {' '}
                <StyledBox>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledBox>
              </>
            )}
            {_.defaultTo(trainerEva, []).map((item, index) => (
              <SubToggleContent
                key={'trainerEva-' + index}
                data={item}
                dataLength={trainerEva.length}
                index={index}
                isPrinting={isPrinting}
              />
            ))}
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

const SubToggleContent = ({ data, dataLength, index, isPrinting }) => {
  const labels = chartPart1Model().map((item) => item.label)
  const bgColors = chartPart1Model().map((item) => item.backgroundColor)
  const chart = data.data
  const moduleData = preparedModuleData(data)
  return (
    <Box key={`sub-toggle-$${data.title}-` + index}>
      { (isPrinting && index > 0) && <br/>}
      <ToggleContent
        title={data.title}
        // type={data.type}
        contentId={data.contentId}
        BoxSx={
          dataLength === index + 1
            ? ''
            : { borderBottom: 'solid 1px #00000012' }
        }
        alwaysOpen={isPrinting ? true : false}
        hideIcon={isPrinting ? true : false}
      >
        <Box>
          <Box className={`${isPrinting ? 'hide-pdf' : ''}`}>
            <Button
              data-testid="btn-preview"
              variant="outlined"
              size="m"
              endIcon={<ChevronRightIcon color="primary" />}
              onClick={() => {
                handleClickViewButton('part2', index)
              }}
            >
              ดูรายละเอียดคำตอบ
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            gap={2}
            alignItems={'start'}
            width={'100%'}
          >
            <DataCard
              dataList={moduleData}
              totalModule={''}
              border={'none'}
              padding={0}
            />
          </Box>
          <Box
            minHeight="250px"
            sx={{
              width: '100%',
              maxWidth: isPrinting ? '650px' : '100%',
            }}
          >
            <BarChart
              dataSet={{
                data: chart,
                backgroundColor: bgColors,
              }}
              labels={labels}
              height={'300px'}
              showXAxisLabel={true}
              showYAxisLabel={true}
              xAxisLabel={axisLabel.x}
              yAxisLabel={axisLabel.y}
              showPercent={true}
              eleId={`part2-${data.title}-${index}`}
            />
          </Box>
          {/* <Divider sx={{ my: 3 }} /> */}
        </Box>
      </ToggleContent>
      <div className="pagebreak-after"> </div>
    </Box>
  )
}

const preparedModuleData = (item) => {
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: item?.attendeeCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: item?.respondentCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${item?.overallPercentage ?? 0} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}

export default TrainerEva
