import { setChangeQuestionByKey } from '../../../../../redux/slices/eTestingForm'

export const handleRadioChange = (props) => (dispatch) => {
  const { answers, keyQuestion, value } = props
  let result = []
  if (answers.length) {
    result = answers.map((item, indexAnswer) => {
      return {
        ...item,
        isCorrect: indexAnswer === Number(value) ? true : false,
      }
    })
  }

  dispatch(
    setChangeQuestionByKey({
      keyQuestion,
      key: 'answers',
      value: result,
    })
  )
}

export const findValueRadio = (answers) => {
  let result = false

  if (answers.length) {
    result = answers.findIndex((item) => {
      return item.isCorrect
    })
  }

  return result
}
