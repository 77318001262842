import _ from 'lodash'
import { store } from '../../../../App'
import { fetchStaffByType } from './fetchStaffByType'

export const fetchStaffTopList = async () => {
  const {
    dashboard: { topListType },
  } = store.getState().monthlyPlan

  const staffTopList = {}
  let latestUpdated = ''
  await Promise.all(
    _.map(topListType, async (type) => {
      const response = await fetchStaffByType(type)

      staffTopList[`${type}`] = _.map(
        _.get(response, 'results', []),
        (res, i) => {
          return { ...res, no: i + 1 }
        },
      )
      latestUpdated = _.get(response, 'latestUpdated', '')
    }),
  )

  return { ...staffTopList, latestUpdated }
}
