import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { handleReadMore } from '../../handler/handleReadMore'

const TruncatedText = ({
  text,
  maxWidth = '500px',
  maxLines = 3,
  readMoreTxt = 'Read more',
  titleVariant,
  isPrinting = false,
  dialogTitle,
}) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const element = textRef.current
    if (element) {
      const lineHeight = parseInt(
        window.getComputedStyle(element).lineHeight,
        10,
      )
      const maxHeight = lineHeight * maxLines
      setIsTruncated(element.scrollHeight > maxHeight)
    }
  }, [text, maxLines])

  return (
    <Box sx={{ maxWidth, overflow: 'hidden' }}>
      <Typography
        ref={textRef}
        variant={titleVariant ?? 'body1'}
        component="div"
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isPrinting ? 'none' : maxLines,
          overflow: isPrinting ? 'visible' : 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: isPrinting ? 'normal' : 'initial',
        }}
      >
        {text}
      </Typography>
      {!isPrinting && isTruncated && (
        <Typography
          variant="body1"
          component="span"
          onClick={() => {
            handleReadMore(text, dialogTitle)
          }}
          sx={{
            fontWeight: 'bold',
            cursor: 'pointer',
            color: 'primary.main',
            marginLeft: '5px',
          }}
        >
          {readMoreTxt ?? 'read more'}
        </Typography>
      )}
    </Box>
  )
}

export default TruncatedText
