import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const BoxAddQuestion = styled(Box)(({ theme, isLength }) => ({
  width: '100px',
  display: 'flex',
  marginTop: !isLength ? 0 : theme.spacing(1.5),
  marginLeft: theme.spacing(5),
  cursor: 'pointer',
  marginBottom: theme.spacing(5),
}))

export const BoxLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))

export const BoxSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'unset',
    marginLeft: theme.spacing(3),
  },
}))

export const LabelFeedback = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '175px',
  },
}))
