import useLocalStorage from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { CustomTextEditor } from '../../../../../components/CRUD/components/CustomTextEditor'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

export const ServiceDescription = () => {
  // const { uuid } = useParams()

  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography variant="h6" color={'text.primary'}>
          รายละเอียดของบริการ (Service Description)
        </Typography>
      </Box>
      <CustomTextEditor
        disabled={!hasPermissionCreate}
        fieldName="serviceDescription"
        toolbar={
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help | '
        }
      />
    </>
  )
}
