export const initialState = {
  license_status: {
    YES: 0,
    NO: 0,
    WAIT_STATUS: 0,
  },
  rcms_status: {
    SUCCESS: 0,
    FAIL: 0,
    NOT_SEND: 0,
    WAIT_SEND: 0,
  },
  license_detail: {
    total: 0,
    description: '',
    category: '',
  },
  agent_detail: [],
  englishName: '',
  finishDate: '',
  statusList: [],
  YES: false,
  NO: false,
  WAIT_STATUS: false,
  SUCCESS: false,
  FAIL: false,
  NOT_SEND: false,
  WAIT_SEND: false,
  rcms_detail: [],
}
