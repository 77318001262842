import { store } from '../../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = (codeId) => {
  const { filterProp } = store.getState().table.filter
  return {
    name: _.get(filterProp, 'name', ''),
    codeId: codeId,
    version:
      _.isNumber(_.get(filterProp, 'version', '')) === true
        ? _.get(filterProp, 'version', '')
        : -1,
    startDate: _.get(filterProp, 'startDate', '')
      ? dayjs(_.get(filterProp, 'startDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        )
      : '',
    toDate: _.get(filterProp, 'toDate', '')
      ? dayjs(_.get(filterProp, 'toDate', '')).format(
          window.__env__.REACT_APP_DATE_DB,
        )
      : '',
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    status: _.get(filterProp, 'status', []),
  }
}
