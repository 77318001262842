import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'
import { Typography } from '@mui/material'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'customWidth' &&
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const BoxContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.background.paper,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  border: '1px solid #DBE4F1',
  width: 'fit-content',
}))

export const FilterStyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  background: '#DBE4F199',
  borderRadius: '4px',
  border: '1px solid #DBE4F1',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledButton = styled(IconButton)(({ background }) => ({
  marginLeft: 'auto',
  background: background,
  borderRadius: '50%',
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 3,
}))

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Container = styled(Box)(({ theme, background, padding }) => ({
  padding: !_.isUndefined(padding)
    ? padding
    : `${theme.spacing(2)} ${theme.spacing(2)}`,
  background: background && background,
  borderRadius: theme.spacing(1),
}))

export const Dot = styled(Box)(({ background }) => ({
  width: 8,
  height: 8,
  background: background,
  borderRadius: '50%',
}))

export const SubLableBox = styled(Box)(({ theme, background, fontColor }) => ({
  padding: `4px 10px 4px 10px`,
  background: background && background,
  borderRadius: theme.spacing(2),
  fontSize: 14,
  fontWeight: 700,
  width: 'max-content',
  marginLeft: theme.spacing(2),
  marginTop: '-8px',
  color: fontColor,
}))

export const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  width: '0.8em',
  marginLeft: theme.spacing(1),
  fontSize: '1em',
  cursor: 'pointer',
}))


export const StyledNumber = styled(Typography)(({ theme, background }) => ({
  background: background,
  marginRight: theme.spacing(0.75),
  borderRadius: '50%',
  minWidth: 24,
  lineHeight: theme.spacing(3),
  textAlign: 'center',
}))

export const StyledName = styled(
  (props) => <Typography {...props} />,
  shouldForwardProp,
)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))
