export const breadcrumbList = [
  { title: 'API to RCMS', link: '/', pointer: false },
  { title: 'Agent License', link: '/', pointer: false },
]

export const breadcrumbDetailList = [
  { title: 'API to RCMS', link: '/', pointer: false },
  { title: 'Agent License', link: '/api-to-rcms', pointer: true },
  { title: 'รายละเอียด Agent License', link: '/', pointer: false },
]

export const breadcrumbPreviewList = [
  { title: 'API to RCMS', link: '/', pointer: false },
  { title: 'Agent License', link: '/api-to-rcms', pointer: true },
  { title: 'รายละเอียด Agent License', link: '/api-to-rcms', pointer: true },
  { title: 'รายละเอียด Agent', link: '/', pointer: false },
]
