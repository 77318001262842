import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
export const LabelText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  alignItems: 'center',
  display: 'flex',
}))

export const BoxImage = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '30%',
}))

export const Image = styled('img')(() => ({
  maxWidth: 160,
  maxHeight: 160,
  objectFit: 'cover',
  borderRadius: '50%',
  border: '1px solid rgba(0, 0, 0, 0.23)',
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
}))

export const IconStatus = styled('div')(({ theme, color, isBorder }) => {
  return {
    borderRadius: '50%',
    border: isBorder ? `1px solid ${theme?.palette?.text?.lightGray}` : false,
    backgroundColor: _.get(theme, `palette.${color}`, ''),
    width: 13,
    height: 13,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
})
