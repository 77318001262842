import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Contrainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20px',
  paddingBottom: '16px',
}))

export const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '16px',
  paddingRight: '16px',
}))

export const TableHead = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: '100%',
}))
