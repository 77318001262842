import _ from 'lodash'
import { closeDialog, openDialog } from '../../../../redux/slices/dialog'
import { getDepartment } from '../../api/Index'
import { initialResponsibility } from './model'

export const handleInitValue = (props) => {
  const { editIndex, data, setInitialForm } = props

  if (!_.isNil(editIndex)) setInitialForm(data)
  else {
    const initValue = {
      ...data,
      speakerBorrowResponsibility: [
        {
          ...initialResponsibility,
          startTime: _.get(data, 'startTime', ''),
          endTime: _.get(data, 'endTime', ''),
        },
      ],
    }
    setInitialForm((prev) => ({ ...prev, ...initValue }))
  }
}

export const handleEditInitValue = (props) => {
  const { data, setInitialForm } = props

  if (_.isEmpty(data) || (data.department && data.station)) return

  if (data.approverDepartment && data.approverStation) {
    const newData = { ...data }
    const approverDepartment = _.get(newData, 'approverDepartment', {})
    const department = {
      ...approverDepartment,
      value: _.get(approverDepartment, 'uuid', ''),
    }
    _.set(newData, 'department', department)
    _.set(newData, 'station', _.get(newData, 'approverStation.uuid', ''))
    setInitialForm((prev) => ({ ...prev, ...newData }))
  }
}

export const fetchDepartment = async (areaUuid, setOptionDepartment) => {
  const resultGet = await getDepartment()
  const result = resultGet.length
    ? resultGet.filter((item) => item.area !== areaUuid)
    : []
  setOptionDepartment(result)
}

export const handleDisableNext = (values) => {
  return (
    _.isEmpty(values.department) ||
    _.isEmpty(values.station) ||
    _.isEmpty(values.purpose)
  )
}

export const handleSubmit = (props) => (dispatch) => {
  const { setStateForm, onClose } = props
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      agreeText: 'บันทึก',
      handleConfirm: () => {
        setStateForm((prev) => handleSpeakerBorrowData(prev, props))
        dispatch(closeDialog())
        onClose()
      },
    })
  )
}

export const handleSpeakerBorrowData = (prev, props) => {
  const { field, values, editIndex } = props
  const mainData = _.get(prev, field, {})
  const subField = field === 'training' ? 'dates' : 'expandDate'
  const newDates = _.get(mainData, subField, []).map((item) => {
    if (values.startDate !== item.date) return item

    let speakerBorrow = _.get(item, 'speakerBorrow', [])
    if (!_.isUndefined(editIndex)) {
      speakerBorrow[editIndex] = values
    } else {
      speakerBorrow = _.concat(speakerBorrow, values)
    }
    return {
      ...item,
      speakerBorrow: speakerBorrow.filter(
        (speaker) =>
          speaker.startDate === item.date || mainData.typeDate === 'DUPLICATE'
      ),
    }
  })
  return { ...prev, [field]: { ...mainData, [subField]: newDates } }
}
