import React from 'react'
import _ from 'lodash'
import { loadingDialog, openDialog } from '../../../../redux/slices/dialog'
import { resetForm } from '../../../../redux/slices/manageClassLearner'
import AddLearnerDialog from '../AddLearnerDialog'
import { announceLearner } from '../../../../services/manageClass/learnerDetail'

export const handleAddLearner = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <AddLearnerDialog />,
    })
  )
}

export const handleAnnounceLearner = (uuid, selected, rows) => (dispatch) => {
  const mapSelectedList = selected.map((item) => {
    return { uuid: item }
  })
  const intersecSelected = _.intersectionBy(rows, mapSelectedList, 'uuid')
  const filterWaitingAnnounceStatus = intersecSelected.filter(
    (item) => item.statusLearner === 'WAITING_ANNOUNCE'
  )
  const uuidWaitingAnnounceList = filterWaitingAnnounceStatus.map((item) => {
    return item.uuid
  })
  const body = {
    trainingPlan: uuid,
    classLearner: uuidWaitingAnnounceList,
  }
  dispatch(
    openDialog({
      title: 'ประกาศผู้มีสิทธิ์เข้าเรียน',
      message:
        'ระบบจะอัปเดตสถานะพร้อมส่งอีเมลยืนยันแจ้งกับผู้เรียนที่มีสิทธิ์ทราบต้องการดำเนินการเลยหรือไม่',
      handleConfirm: () => {
        dispatch(loadingDialog())
        dispatch(announceLearner(body))
      },
    })
  )
}
// manageClassAnnounceLearner
