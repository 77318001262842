import { postGetCourseBatch } from '../../../../../../services/eLearning/course/form'
import { store } from '../../../../../../App'
import {
  setReduxValue,
  startLoading,
  stopLoading,
  setIsOpenLearnerDrawer,
} from '../../../../../../redux/slices/eLearning/dashboardByCourse'
import { filterELearningDashboardByCourse } from '../../../../../../services/eLearning/course/dashboard'
import _ from 'lodash'
import dayjs from 'dayjs'
import { DEFUALT_COURSE_STATUS } from '../../../model'

export const fetchDashboardByCourse = async () => {
  const { courseUuid, filter } = store.getState().eLearningDashboardByCourse
  store.dispatch(startLoading())

  const body = {
    periodStart: dayjs(_.get(filter, 'periodStart', '')).format('YYYY-MM-DD'),
    periodEnd: dayjs(_.get(filter, 'periodEnd', '')).format('YYYY-MM-DD'),
    courseBatchList: _.get(filter, 'courseBatchList', []),
    courseUuid: courseUuid,
  }
  await store.dispatch(filterELearningDashboardByCourse(body))
  store.dispatch(stopLoading())
}

export const fetchCourseBatch = async () => {
  const { courseUuid } = store.getState().eLearningDashboardByCourse
  const body = {
    uuid: courseUuid,
    limit: -1,
    page: 1,
    order: 'ASC',
    sort: 'name',
    status: DEFUALT_COURSE_STATUS,
    name: '',
    nameSpearker: '',
    periodStart: '',
    periodEnd: '',
  }
  const response = await store.dispatch(postGetCourseBatch(body)).then((res) =>
    _.map(_.get(res, 'payload.result'), (item) => {
      return { ...item, label: _.get(item, 'name'), value: _.get(item, 'uuid') }
    }),
  )

  store.dispatch(setReduxValue({ key: 'courseBatchOptions', value: response }))
}

export const handleOpenLearnerDrawer = (learnerType) => {
  store.dispatch(
    setReduxValue({ key: 'learnerTypeSelected', value: learnerType }),
  )
  store.dispatch(setIsOpenLearnerDrawer(true))
}
