import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  p: { span: { color: theme?.palette?.warning?.main } },
}))

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}))

export const StyledLoading = styled(Box)(({ theme, isLoading }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: isLoading ? 'flex' : 'none',
  background: theme.palette?.background?.blur,
}))

export const StyledSwitchButton = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing(0.5),
}))
