import { Box, Grid } from '@mui/material'
import React from 'react'
import Transaction from '../Transaction'
import AboutCourseRanking from '../AboutCourseRanking'
import AboutLicenseRanking from '../AboutLicenseRanking'

const index = () => {
  return (
    <Box>
      <Box>
        <Transaction />
      </Box>
      <Box mt={3} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AboutCourseRanking />
          </Grid>
          <Grid item xs={12} md={6}>
            <AboutLicenseRanking />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default index
