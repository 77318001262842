import { setHandleChange } from '../../../redux/slices/table'
import { store } from '../../../App'
import _ from 'lodash'

export const handleChange = ({ key, value, filter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  store.dispatch(setHandleChange({ key: 'filterState', value: objState }))
}
