import React, { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { useHistory } from 'react-router'
import { writeStorage } from '@rehooks/local-storage'
import { examLabel, examStatus } from '../../../../../constants/manageClass'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import LearnerMenu from '../LearnerMenu'
import { StyledColumn, StyledRow } from '../Styled'
import { getLearnerList } from '../events'
import {
  StyledCardHeader,
  StyledCardStatus,
  StyledImageNone,
  StyledScoreRow,
} from './Styled'
import QuestionCard from './QuestionCard'
import DropdownButton from './DropdownButton'
import {
  getLearnerTotal,
  handleChangePage,
  handleHeight,
  handleReloadPage,
  handleSuccess,
} from './events'

const ExamContainer = ({ setListHeight, isStandalone }) => {
  const ref = useRef(null)
  const { isComplete, isSuccess, tab, learnerExam, body } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const learnerAnswers = _.get(learnerExam, 'learnerAnswers', []) || []
  const dispatch = useDispatch()
  const isLg = isMediaWidthMoreThan('md')
  const history = useHistory()

  useEffect(() => {
    handleHeight(ref, setListHeight)
  }, [tab])

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  useEffect(() => {
    handleReloadPage({ isComplete, body, history, writeStorage, learnerExam })
  }, [isComplete])

  return (
    <>
      {!isLg && <LearnerMenu isStandalone={isStandalone} />}
      <Box ref={ref}>
        <StyledColumn sx={{ py: 3, gap: 2 }}>
          {_.isNull(learnerExam) ? (
            <NoneCard />
          ) : (
            <>
              <Card>
                <StyledCardHeader>
                  <StyledRow gap={0.5} alignItems="center">
                    <DropdownButton isStandalone={isStandalone} />
                    <Typography variant="body1b">
                      {_.get(learnerExam, 'codeId', '-')}{' '}
                      {_.get(learnerExam, 'firstNameTH', '')}{' '}
                      {_.get(learnerExam, 'lastNameTH', '') || ''}
                    </Typography>
                  </StyledRow>
                  <Pagination isStandalone={isStandalone} />
                </StyledCardHeader>
                <ScoreContent data={learnerExam} />
              </Card>

              {learnerAnswers.length > 0 ? (
                learnerAnswers.map((item, index) => (
                  <QuestionCard
                    isStandalone={isStandalone}
                    index={index}
                    key={index}
                  />
                ))
              ) : (
                <NoneCard />
              )}

              <Card sx={{ position: 'relative', py: 1, px: 2.5 }}>
                <Pagination position="down" isStandalone={isStandalone} />
              </Card>
            </>
          )}
        </StyledColumn>
      </Box>
    </>
  )
}

export default ExamContainer

export const ScoreContent = ({ data }) => {
  const status = _.get(data, 'testStatusText', 'รอผู้เรียนส่งข้อสอบ')
  const learnerScore = _.get(data, 'learnerTotalScore', '')
  const totalScore = _.get(data, 'testingTotalScore', '')
  const latestScore = !_.isNull(learnerScore)
    ? `${learnerScore} / ${totalScore}`
    : '-'
  return (
    <StyledColumn sx={{ p: 2.5, pt: 2, gap: 1 }}>
      <StyledCardStatus
        color={_.get(examStatus, `${status}.color`, 'text.silver')}
      >
        {_.get(examStatus, `${status}.icon`, '')}
        <Typography variant="h6">{status}</Typography>
        <Typography color="text.primary">
          {_.get(data, 'isAnnounceText', '')}
        </Typography>
      </StyledCardStatus>
      <StyledScoreRow>
        <StyledRow gap={2} justifyContent="center">
          <Typography color="text.secondary">คะแนนล่าสุด</Typography>
          <Typography variant="body1b">{latestScore}</Typography>
        </StyledRow>
        <StyledRow gap={2} justifyContent="center">
          <Typography color="text.secondary">จำนวนครั้งที่สอบ</Typography>
          <Typography variant="body1b">
            {_.get(data, 'totalTestCount', '-') || '-'}
          </Typography>
        </StyledRow>
        <StyledRow gap={2} justifyContent="center">
          <Typography color="text.secondary">สอบล่าสุดเมื่อ</Typography>
          <Typography variant="body1b">
            {_.get(data, 'testingAt', '-') || '-'}
          </Typography>
        </StyledRow>
      </StyledScoreRow>
    </StyledColumn>
  )
}

export const NoneCard = () => {
  const { learnerExam, tab } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  return (
    <Card sx={{ height: 350, position: 'relative' }}>
      <StyledImageNone>
        <img src="/icon/ic_pending.svg" />
        {_.isNull(learnerExam) && (
          <Typography color="text.lightGray">
            ไม่พบข้อสอบที่{examLabel[tab]}
          </Typography>
        )}
      </StyledImageNone>
    </Card>
  )
}

export const Pagination = ({ position, isStandalone }) => {
  const idProps = useParams()
  const { tab, learners, page } = useSelector(
    (state) => state.manageClassCheckExam,
    shallowEqual
  )
  const learnerTotal = useMemo(
    () => getLearnerTotal(learners, tab),
    [learners, tab]
  )
  const learnerList = useMemo(
    () => getLearnerList(learners, tab),
    [learners, tab]
  )
  const dispatch = useDispatch()
  const isNotSm = isMediaWidthMoreThan('sm')
  const pageChangeProps = { idProps, learnerList, page, isStandalone }

  return (
    <StyledRow
      alignItems="center"
      justifyContent={isNotSm ? 'end' : 'space-between'}
      gap={2}
    >
      {isNotSm && (
        <Typography variant="caption">
          {examLabel[tab]} {page} / {learnerTotal}
        </Typography>
      )}
      <IconButton
        data-testid={`btn-previous-${position}`}
        disabled={page == 1}
        color="primary"
        onClick={() => dispatch(handleChangePage(pageChangeProps, 'back'))}
      >
        <ChevronLeft />
      </IconButton>
      {!isNotSm && (
        <Typography variant="caption">
          {examLabel[tab]} {page} / {learnerTotal}
        </Typography>
      )}
      <IconButton
        data-testid={`btn-next-${position}`}
        disabled={page == learnerTotal}
        color="primary"
        onClick={() => dispatch(handleChangePage(pageChangeProps, 'next'))}
      >
        <ChevronRight />
      </IconButton>
    </StyledRow>
  )
}
