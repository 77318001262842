import _ from 'lodash'
import {
  setCourseType,
  setInitialPreview,
  stopLoading,
  startCourseLoading,
  stopCourseLoading,
  setSelectedCourse,
} from '../../../../redux/slices/eLearning/learningPathPreview'
import {
  getCourseDetail,
  getELearningDetail,
} from '../../../../services/eLearning/learningPath/preview'
import { COURSE_TYPE } from './model'

export const handleInitialPreview = (data) => async (dispatch) => {
  dispatch(stopLoading())
  dispatch(setInitialPreview(data))

  const firstCourse = _.get(data, 'eLearningLearningPathCourses[0]', {})
  const type = _.get(firstCourse, 'type', '')
  const uuid = _.get(firstCourse, 'course.uuid', '')
  await dispatch(fetchCourseDetail({ type, uuid }))
}

export const fetchCourseDetail = (course) => async (dispatch) => {
  const { type, uuid } = course
  dispatch(setCourseType(type))
  dispatch(setSelectedCourse(uuid))

  dispatch(startCourseLoading())
  if (type === COURSE_TYPE.E_LEARNING) {
    await dispatch(getELearningDetail(uuid))
  } else {
    await dispatch(getCourseDetail(uuid))
  }
  dispatch(stopCourseLoading())
}
