import MenuBookIcon from '@mui/icons-material/MenuBookTwoTone'
import PersonIcon from '@mui/icons-material/PersonTwoTone'
import SurroundSoundIcon from '@mui/icons-material/SurroundSoundTwoTone'
import HowToRegIcon from '@mui/icons-material/HowToRegTwoTone'
import _ from 'lodash'

export const overallData = ({ data, border, iconColor, width }) => {
  const boxSx = {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    padding: '5px',
  }
  return [
    {
      label: 'จำนวน Class ทั้งหมด',
      labelVariant: 'body2',
      number: _.get(data, 'allClass', 0),
      hidePercent: true,
      unit: 'คลาส',
      hideClick: false,
      width: !_.isUndefined(width) ? width : '310px',
      border: border,
      icon: (
        <MenuBookIcon
          sx={{ width: '73px', height: '56px', color: iconColor }}
        />
      ),
      boxSx: boxSx,
    },
    {
      label: 'จำนวนผู้สมัคร Enroll',
      labelVariant: 'body2',
      number: _.get(data, 'enroll', 0),
      hidePercent: true,
      unit: 'คน',
      hideClick: true,
      width: !_.isUndefined(width) ? width : '310px',
      border: border,
      icon: (
        <PersonIcon sx={{ width: '73px', height: '56px', color: iconColor }} />
      ),
      boxSx: boxSx,
    },
    {
      label: 'ผู้เข้าเรียน (Attendee)',
      labelVariant: 'body2',
      number: _.get(data, 'attendee', 0),
      hidePercent: true,
      showSubPercent: true,
      unit: 'คน',
      hideClick: true,
      width: !_.isUndefined(width) ? width : '310px',
      border: border,
      icon: (
        <HowToRegIcon
          sx={{ width: '73px', height: '56px', color: iconColor }}
        />
      ),
      boxSx: boxSx,
    },
    {
      label: 'ค่าใช้จ่ายทั้งหมด (บาท)',
      labelVariant: 'body2',
      number: _.get(data, 'budget', 0),
      hidePercent: true,
      unit: 'บาท',
      hideClick: true,
      width: !_.isUndefined(width) ? width : '310px',
      border: border,
      icon: (
        <SurroundSoundIcon
          sx={{ width: '73px', height: '56px', color: iconColor }}
        />
      ),
      boxSx: boxSx,
    },
  ]
}
