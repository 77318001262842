import { store } from '../../../App'
import _ from 'lodash'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleDeleteFilterColumnMixModule } from './handleDeleteFilterColumnMixModule'

export const handleDeleteFilter = (id) => async (dispatch) => {
  const { displayFilters, reportConfig } = store.getState().reportComp
  const { moduleId } = reportConfig

  const newFilters = _.filter(displayFilters, (filter) => filter.id !== id)
  await dispatch(setReduxValue({ key: 'displayFilters', value: newFilters }))

  const findFilter = _.find(displayFilters, (ele) => ele.id === id)
  if (
    moduleId === 'REPORT_MIX_MODULE' &&
    ['courseNameEN', 'courseNameTH', 'courseNameForLearner'].includes(
      findFilter.key,
    )
  ) {
    await dispatch(
      handleDeleteFilterColumnMixModule(
        findFilter.key,
        findFilter.value.length,
      ),
    )
  }
}
