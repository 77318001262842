import callAxios from '../../../../../utils/baseService'
import _ from 'lodash'
import { stationFilter } from '../../../../../utils/apiPath'

export const fetchStationList = async () => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }

  let result = []
  await callAxios
    .post(stationFilter, body)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const station = _.get(item, 'station', '')
        result.push({ value: uuid, label: station })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}
