export const filterStatusItems = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รอเปิดใช้งาน',
    name: 'waitingChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const filterTypeOfETestingItems = [
  {
    label: 'Online Test',
    name: 'onlineTestChecked',
  },
  {
    label: 'Assessment',
    name: 'assessmentChecked',
  },
  {
    label: 'Standalone',
    name: 'standAloneChecked',
  },
]

export const filterItems = [
  {
    label: `เวอร์ชัน`,
    nameCheckBox: 'versionChecked',
    nameInput: 'version',
    placeholder: `ค้นหา เวอร์ชัน`,
    type: 'number',
  },
  {
    label: `ชื่อแบบทดสอบ`,
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: `วันเริ่มต้นใช้งาน`,
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: `เลือกช่วงเวลา`,
    dateStateStart: 'startDate',
    dateStateFinish: 'toDate',
    dateStateDisplay: 'displayStartDateChecked',
    type: 'daterange',
  },
  {
    label: `สร้างโดย`,
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: `แก้ไขล่าสุดโดย`,
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: `ค้นหา`,
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
