import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState, initOnlineRoom, initSpeaker } from './model'
import {
  getAdminStaff,
  getExternalSpeaker,
  getManageClassByUuid,
  getMeetingRoom,
  getResponsibility,
  putManageClass,
  getManageClassHolidayByUuid,
} from './../../../services/manageClass/form'
import { handleOnlineRoomNo } from './events'

const manageClassForm = createSlice({
  name: 'manageClassForm',
  initialState,
  reducers: {
    setClearData: (state) => {
      state.isFirst = false
      state.isDraft = true
      state.isDisable = false
      state.isCancel = false
      state.isLoading = false
      state.adminStaffOption = []
      state.meetingRoomOption = []
      state.externalSpeakerOption = []
      state.responsibilityOption = []
      state.error = null
      state.data = initialState.data
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setEditData: (state, { payload }) => {
      const { keyField, value } = payload
      state.data[keyField] = value
    },
    setEditMonthlyPlan: (state, { payload }) => {
      const { keyField, value } = payload
      state.data.monthlyPlan[keyField] = value
    },
    setEditDateDetail: (state, { payload }) => {
      const { keyField, index, value } = payload
      if (keyField == 'meetingRooms') {
        const isOther = value.some((item) => item.value == 'OTHERS')
        if (!isOther) state.data.monthlyPlan.dates[index].meetingRoomOther = ''
      }
      state.data.monthlyPlan.dates[index][keyField] = value
    },
    setEditRoomDetail: (state, { payload }) => {
      const { keyField, index, value } = payload
      state.data.roomManagement.roomManagementMeetingRoom[index][keyField] =
        value
    },
    setEditStaff: (state, { payload }) => {
      const { keyField, dIndex, sIndex, value } = payload
      state.data.monthlyPlan.dates[dIndex].externalSpeakerPlan[sIndex][
        keyField
      ] = value
    },
    setEditOnlineRoom: (state, { payload }) => {
      const { keyField, dIndex, oIndex, value } = payload
      state.data.monthlyPlan.dates[dIndex].onlineRoom[oIndex][keyField] = value
    },
    setAddStaff: (state, { payload }) => {
      const { index } = payload
      const date = state.data.monthlyPlan.dates[index]
      date.externalSpeakerPlan.push({ ...initSpeaker })
    },
    setAddOnlineRoom: (state, { payload }) => {
      const { index } = payload
      const date = state.data.monthlyPlan.dates[index]
      date.onlineRoom.push({
        ...initOnlineRoom,
        no: date.onlineRoom.length + 1,
      })
    },
    setDeleteStaff: (state, { payload }) => {
      const { dIndex, sIndex } = payload
      const date = state.data.monthlyPlan.dates[dIndex]
      date.externalSpeakerPlan.splice(sIndex, 1)
    },
    setDeleteOnlineRoom: (state, { payload }) => {
      const { dIndex, oIndex } = payload
      const date = state.data.monthlyPlan.dates[dIndex]
      date.onlineRoom.splice(oIndex, 1)
    },
    setDisable: (state, { payload }) => {
      state.isDisable = payload
    },
    setSendMail: (state, { payload }) => {
      state.data.sendMailUpdate = payload
    },
    addRoom: (state, { payload }) => {
      const { value } = payload
      if (_.isEmpty(state.data.roomManagement.roomManagementMeetingRoom)) {
        state.data.roomManagement = {
          ...state.data.roomManagement,
          roomManagementMeetingRoom: [value],
        }
      } else {
        state.data.roomManagement.roomManagementMeetingRoom.push(value)
      }
    },
    deleteRoom: (state, { payload }) => {
      const { roomManagementMeetingRoom } = payload
      state.data.roomManagement.roomManagementMeetingRoom =
        roomManagementMeetingRoom
    },
    setMeetingRoomByDate: (state, { payload }) => {
      const { value } = payload
      state.data.meetingRoomByDate = value
    },
    setEditRoomDetailSelect: (state, { payload }) => {
      const { index, value } = payload
      state.data.roomManagement.roomManagementMeetingRoom[index].meetingRoom =
        value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageClassByUuid.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        const assignNee = _.get(data, 'assignNee.uuid', '')
        const event = _.get(data, 'event', [])
        const status = _.get(data, 'status', 'INPROGRESS')
        state.isFirst = event.length <= 1
        state.isDraft = status == 'UNASSIGN' || status == 'TODO'
        state.isDisable = status == 'CLOSED'
        state.isCancel = status == 'CANCELED'
        const dates = handleOnlineRoomNo(data)
        state.data = {
          ...state.data,
          ...data,
          monthlyPlan: { ...data.monthlyPlan, dates },
        }
        state.data.assignNeeObj = _.get(data, 'assignNee', null)
        state.data.assignNee = assignNee
        state.data.startRegisterDateTime = data.startRegisterDateTime || ''
        state.data.endRegisterDateTime = data.endRegisterDateTime || ''
        state.isLoading = false
        state.isSubmit = false
        state.data.roomManagementMeetingRoomDefault = _.get(
          data,
          'roomManagement.roomManagementMeetingRoom',
          [],
        )
      })
      .addCase(getManageClassByUuid.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isSubmit = false
      })

      .addCase(getManageClassHolidayByUuid.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        state.data = data
      })
      .addCase(getManageClassHolidayByUuid.rejected, (state, action) => {
        state.error = action.meta?.message
      })

      .addCase(getAdminStaff.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'response.data', [])
        const station = payload.station || { uuid: '', station: '' }
        const hasPermissionEditAll = _.get(
          payload,
          'hasPermissionEditAll',
          false,
        )
        let result = data
        if (!hasPermissionEditAll) {
          result = data
            .filter((item) =>
              item.area.some((area) => {
                const uuid = _.get(area, 'station.uuid', '')
                return uuid == station.uuid
              }),
            )
            .map((item) => ({
              ...item,
              label: `${station.station} - ${item.firstNameTH} ${item.lastNameTH}`,
              value: item.uuid,
              tel: item.tel,
            }))
        } else {
          result = result.map((item) => {
            const area = item.area.find((area) => {
              const uuid = _.get(area, 'uuid', '')
              return uuid == _.get(item, 'defaultArea', '')
            })
            const name = `${item.firstNameTH} ${item.lastNameTH}`
            return {
              ...item,
              label: `${_.get(area, 'station.station', '')} - ${name}`,
              value: item.uuid,
              tel: item.tel,
            }
          })
        }

        state.adminStaffOption = result.sort(
          (a, b) => -b.label.localeCompare(a.label),
        )
      })
      .addCase(getAdminStaff.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getMeetingRoom.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'response.data.result', [])
        const station = _.get(payload, 'station', { uuid: '', station: '' })
        state.meetingRoomOption = data
          .filter((area) => {
            const uuid = _.get(area, 'station.uuid', '')
            return uuid == station.uuid
          })
          .map((item) => ({
            label: `${item.station?.station} : ${item.roomName} (ชั้น ${item.floor})`,
            value: item.uuid,
            ...item,
          }))
        state.meetingRoomOption.push({ label: 'Others', value: 'OTHERS' })
      })
      .addCase(getMeetingRoom.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getExternalSpeaker.fulfilled, (state, { payload }) => {
        state.externalSpeakerOption = payload.data?.map((item) => ({
          label: `${item.firstNameTH} ${item.lastNameTH}`,
          value: item.uuid,
        }))
      })
      .addCase(getExternalSpeaker.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getResponsibility.fulfilled, (state, { payload }) => {
        state.responsibilityOption = payload.data?.result?.map((item) => ({
          label: item.responsibility,
          value: item.uuid,
        }))
      })
      .addCase(getResponsibility.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(putManageClass.pending, (state) => {
        state.isLoading = true
        state.isSubmit = true
      })
      .addCase(putManageClass.fulfilled, (state) => {
        state.error = null
        state.isSuccess = true
        state.isLoading = false
        state.isSubmit = false
      })
      .addCase(putManageClass.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isLoading = false
        state.isSubmit = false
      })
  },
})

export const {
  setClearData,
  startLoading,
  stopLoading,
  setEditData,
  setEditMonthlyPlan,
  setEditDateDetail,
  setEditRoomDetail,
  setEditStaff,
  setEditOnlineRoom,
  setAddStaff,
  setAddOnlineRoom,
  setDeleteStaff,
  setDeleteOnlineRoom,
  setDisable,
  setSendMail,
  addRoom,
  deleteRoom,
  setMeetingRoomByDate,
  setEditRoomDetailSelect,
} = manageClassForm.actions

export default manageClassForm.reducer
