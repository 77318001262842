import React from 'react'
import _ from 'lodash'
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone'
import Remove from '@mui/icons-material/Remove'

import Typography from '@mui/material/Typography'
import { StyledIconCell } from './Styled'

const WaitToCheck = ({ row }) => {
  const type = _.get(row, 'testAndEvaluateType', 'E_TESTING')
  const value = _.get(row, 'waitingToCheck', 0)

  if (type == 'E_EVALUATION') {
    return <Remove sx={{ color: 'text.silver' }} fontSize="18" />
  }

  return (
    <StyledIconCell value={value}>
      <HourglassBottomTwoTone />
      <Typography variant="body2" color="text.primary">
        {value}
      </Typography>
    </StyledIconCell>
  )
}

export default WaitToCheck
