import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
  stopLoadingDialog,
} from '../../../../../redux/slices/dialog'
import _, { get } from 'lodash'
import appHistory from '../../../../../appHistory'
import { store } from '../../../../../App'
import { callAPI } from '../../../../../utils/lib'
import {
  displayClientError,
  displayServerError,
} from '../../../../../components/CRUD/handler/handlerFetchError'
import { transferTicket } from '../../../../../utils/apiPath'
import {
  PRODUCT_TYPE_TRANSFER,
  PRODUCT_TYPE,
  TicketStatus,
} from '../../../../../constants/stock'
import { fromTypeEnum } from '../enum/assetTypEnum'
import { paymentMethodEnum } from '../enum/paymentMethod'

export const handleProcessSubmitForm = async (
  uuid,
  postBodyMutation,
  isDraft,
) => {
  startDialogLoading()

  try {
    const response = await submitFormData(uuid, isDraft)

    handleSuccessfulSubmission(response, isDraft)
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const submitFormData = async (uuid, isDraft = false) => {
  const { formData, assetData } = store.getState().crud
  const url = transferTicket
  const productType = PRODUCT_TYPE_TRANSFER[formData.type]
  const product =
    productType === PRODUCT_TYPE.STOCK
      ? formData.product
      : productType === PRODUCT_TYPE.STOCK_ASSET
      ? formData.productStockAsset
      : productType === PRODUCT_TYPE.ASSET
      ? formData.productAsset
      : formData.productLicense

  let status = TicketStatus.WAITING_APPROVE
  if (isDraft) {
    status = TicketStatus.DRAFT
  } else if (formData?.from.value === fromTypeEnum.IMPORT_PRODUCT) {
    status = TicketStatus.APPROVED
  }
  return await callAPI({
    method: _.isEmpty(uuid) ? 'post' : 'put',
    url,
    body: {
      ...formData,
      status: status,
      uuid: _.isEmpty(uuid) ? undefined : uuid,
      product,
      productType,
      formUuid: _.get(formData, 'from.value'),
      toUuid: _.get(formData, 'to.value'),
      products: assetData.map((item) => {
        const creditCardName =
          _.get(item, 'paymentType.value') === paymentMethodEnum.CREDIT_CARD
            ? _.get(item, 'paymentDesc')
            : ''
        const creditCardDescription =
          _.get(item, 'paymentType.value') === paymentMethodEnum.OTHER
            ? _.get(item, 'paymentDesc')
            : ''
        const quantity = _.get(item, 'quantity')
          ? convertToNumber(_.get(item, 'quantity'))
          : null
        const stockValue = _.get(item, 'stockValue')
          ? convertToNumber(_.get(item, 'stockValue'))
          : null
        const unitPrice = _.get(item, 'unitPrice')
          ? convertToNumber(_.get(item, 'unitPrice'))
          : null
        return {
          ...item,
          creditCardName,
          creditCardDescription,
          quantity,
          stockValue,
          unitPrice,
        }
      }),
    },
  })
}

const handleSuccessfulSubmission = (response, isDraft) => {
  const uuid = get(response, '[0]', '')

  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => navigateToViewPath(uuid, isDraft),
    }),
  )
}

const convertToNumber = (value) => {
  if (!value || value === '-') return null
  return Number(String(value).replace(/,/g, ''))
}

export const handleFormSubmissionError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      handleBadRequestError(error.response)
    } else {
      displayServerError()
    }
  } else {
    displayClientError()
  }
}

const handleBadRequestError = (response) => {
  const errorData = get(response, 'data', '')

  store.dispatch(
    openErrorDialog({
      message: errorData.message.replace('Error :', '').trim(),
      title: 'ผิดพลาด',
      isCloseDialog: false,
    }),
  )
}

const navigateToViewPath = (uuid, isDraft) => {
  const { crudConfig } = store.getState().crud
  const viewPath = isDraft
    ? `${crudConfig.modulePath}`
    : `${crudConfig.modulePath}/view/${uuid}`
  appHistory.push(viewPath)
  store.dispatch(closeDialog())
}
