import * as yup from 'yup'

export const validationSchema = yup.object({
  level: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(80, 'ระบุได้ไม่เกิน 80 ตัวอักษร'),
  position: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(80, 'ระบุได้ไม่เกิน 80 ตัวอักษร'),
  sequence: yup.number().required('กรุณากรอกข้อมูลในช่องนี้').min(0),
  status: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  levelOfLearner: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .nullable()
    .required('กรุณากรอกเลือกในช่องนี้'),
})
