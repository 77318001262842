import { Button } from '@mui/material'
import React from 'react'

export function SubmitButton({ handleSubmitClick, disabled }) {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      size="m"
      type="button"
      onClick={handleSubmitClick}
    >
      บันทึก
    </Button>
  )
}
