import { store } from '../../../../../../../App'
import _ from 'lodash'
import dayjs from 'dayjs'

export const mutateFilterPropToBody = (versionUuid) => {
  const { filterProp } = store.getState().table.filter

  return {
    eTestVersionUuid: versionUuid,
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : '',
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : '',
    distribution: _.get(filterProp, 'distribution', ''),
    agentCode: _.get(filterProp, 'agentCode', ''),
    unitCode: _.get(filterProp, 'unitCode', ''),
    ramCode: _.get(filterProp, 'ramCode', ''),
    sRam: _.get(filterProp, 'sRam', ''),
    scoreFrom: _.get(filterProp, 'scoreFrom', ''),
    scoreTo: _.get(filterProp, 'scoreTo', ''),
    startTestDate: _.isDate(filterProp?.startTestDate)
      ? dayjs(filterProp?.startTestDate).utc(true).toDate()
      : '',
    endTestDate: _.isDate(filterProp?.endTestDate)
      ? dayjs(filterProp?.endTestDate).utc(true).toDate()
      : '',
    testResult: _.get(filterProp, 'testResult', []),
  }
}
