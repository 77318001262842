import { Card, Box } from '@mui/material'
import { tabsSummaryWorkloadList } from '../model'
import BasicTabs from '../../../../components/BasicTabs'
import { useSelector, shallowEqual } from 'react-redux'
import { handleChangeTab } from '../events'
import DetailsTable from '../DetailsTable'
import RadarChartBox from '../components/RadarChartBox'
import InputForCompareChart from '../components/InputForCompareChart'
import _ from 'lodash'

const SummaryWorkloadOverview = () => {
  const { tabSumWorkloadActive, workTypeData } = useSelector(
    (state) => ({
      tabSumWorkloadActive: state.monthlyPlan.dashboard.tabSumWorkloadActive,
      workTypeData: state.monthlyPlan.dashboard.workTypeData,
    }),
    shallowEqual,
  )

  return (
    <Card
      sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}
    >
      <BasicTabs
        tabsList={tabsSummaryWorkloadList}
        tabActive={tabSumWorkloadActive}
        handleChangeTab={(e, newValue) =>
          handleChangeTab(e, newValue, 'tabSumWorkloadActive')
        }
      />
      <DetailsTable />
      {tabSumWorkloadActive === 0 && (
        <Box display="flex" gap={2}>
          <RadarChartBox
            idx={1}
            workTypeData={workTypeData}
            workTypeListAVG={workTypeData.workTypeListAVG}
          />
          <InputForCompareChart />
        </Box>
      )}
      {tabSumWorkloadActive === 1 && (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {_.map(
            _.get(workTypeData, 'staffWorkTypeDataList', []),
            (item, idx) => {
              return (
                <RadarChartBox
                  key={idx}
                  workTypeData={item}
                  idx={idx + 1}
                  workTypeListAVG={workTypeData.workTypeListAVG}
                />
              )
            },
          )}
        </Box>
      )}
      {tabSumWorkloadActive === 2 && (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {_.map(
            _.get(workTypeData, 'staffWorkTypeDataList', []),
            (item, idx) => {
              return (
                <RadarChartBox
                  key={idx}
                  workTypeData={item}
                  idx={idx + 1}
                  workTypeListAVG={workTypeData.workTypeListAVG}
                />
              )
            },
          )}
        </Box>
      )}
    </Card>
  )
}
export default SummaryWorkloadOverview
