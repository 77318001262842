import { store } from 'src/App'
import { setFiltersDashboard } from '../../../redux/slices/monthlyPlan'
import _ from 'lodash'

export const handleDefaultFilterByKey = (name) => {
  const {
    displayFilters,
    displayFilters: { stationOptions },
  } = store.getState().monthlyPlan.dashboard

  const stationObj = _.reduce(
    stationOptions,
    (result, item) => {
      result[item.name] = false
      return result
    },
    {},
  )

  let updatedFilter = {}
  updatedFilter[`${name}Checked`] = false

  if (name === 'date') {
    updatedFilter['startDate'] = ''
    updatedFilter['endDate'] = ''
  } else if (name === 'department') {
    updatedFilter['department'] = []
  } else if (name === 'station') {
    updatedFilter['station'] = stationObj
  }

  store.dispatch(
    setFiltersDashboard({
      ...displayFilters,
      ...updatedFilter,
    }),
  )
}
