import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ToDoCard from '../../components/ToDoCard'
import ToDoListBox from '../../components/ToDoListBox'
import Button from '@mui/material/Button'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FilterDrawer from './FilterDrawer'
import Card from '@mui/material/Card'
import { themeColor } from '../../utils/themeData'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { getToDoListFilter, getToDoListSummary } from '../../services/toDolist'
import {
  setSummaryCount,
  setDataList,
  startLoading,
  stopLoading,
  startLoadmoreLoading,
  stopLoadmoreLoading,
} from '../../redux/slices/toDoList'
import {
  allFilterType,
  filterLabel,
  filterDrawerLabel,
  allTaskNameChecked,
  allWorkTypeChecked,
  allStatusChecked,
} from '../../constants/toDoList'
import _ from 'lodash'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import * as dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton'
import { useLocalStorage } from '@rehooks/local-storage'

const initalFilterMasterData = {
  workType: [],
  taskName: [],
  status: [],
}

const defaultFilter = {
  startDateChecked: false,
  workTypeChecked: false,
  taskNameChecked: false,
  statusChecked: false,
  status: {
    toDoChecked: false, //ยังไม่ได้ทำ
    onWorkingChecked: false, //กำลังทำ
    doneChecked: false, //เสร็จแล้ว
    cancelChecked: false, //ยกเลิก
  },
  taskName: {
    //HF
    approveMonthlyPlanChecked: false, //อนุมัติแผนรายเดือน
    approveMasterPlanChecked: false, //อนุมัติแผนงาน
    //HZ
    requestSpeakerBorrowChecked: false, //คำขออนุมัติวิทยากรสอนข้ามพื้นที่
    disapproveSpeakerBorrowChecked: false, //ไม่อนุมัติวิทยากรสอนข้ามพื้นที่
    disapproveBuggetChecked: false, //คำของบ Break/Lunch ถูกปฏิเสธ
    disapproveMasterPlanChecked: false, //ไม่อนุมัติแผนงาน
    disapproveMonthlyPlanChecked: false, //ไม่อนุมัติแผนรายเดือน
    //trainer
    checkExamChecked: false, //ตรวจข้อสอบ
    assignmentTestChecked: false, //ข้อสอบ Assessment
    closeClassChecked: false, //ปิดคลาส
    checkTestStandaloneChecked: false, //ตรวจข้อสอบ Standalone
    //admin
    requestMeetingRoomChecked: false, //คำร้องขอจองห้องประชุม
    announceStudentChecked: false, //ประกาศรายชื่อผู้มีสิทธิ์เรียน
    checkDocumentChecked: false, //ตรวจเอกสาร
    settingClassChecked: false, //ตั้งค่าคลาส
    adminCloseClassChecked: false, //แอดมินปิดคลาส
  },
  workType: {
    monthlyPlanChecked: false,
    manageClassChecked: false,
    eTestingChecked: false,
  },
  startDate: '',
  endDate: '',
  displayDate: false,
}

const ToDoListView = () => {
  const [user] = useLocalStorage('user')
  const permissionList = _.get(user, 'permission', [])
  const dispatch = useDispatch()
  const today = dayjs(new Date()).format(window.__env__.REACT_APP_DATE_SHOW)
  const todayFormat = dayjs(new Date()).format('YYYY-MM-DD')
  const yesterday = dayjs(new Date())
    .subtract(1, 'day')
    .format(window.__env__.REACT_APP_DATE_SHOW)

  const { isLoading, data, summaryCount, isLoadmoreLoading } = useSelector(
    (state) => state.toDoList,
    shallowEqual,
  )
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [filterMasterData, setFilterMasterData] = useState(
    initalFilterMasterData,
  )
  const [filterMasterDataLabel, setFilterMasterDataLabel] = useState(
    initalFilterMasterData,
  )
  const [param, setParam] = useState({
    type: 'TO_DO',
  })
  const [body, setBody] = useState({
    workType: [],
    taskName: [],
    limit: 10,
    order: 'DESC',
    status: [],
    type: ['TO_DO'],
    page: 1,
    sort: 'createdAt',
    startDate: '',
    endDate: '',
    isLoadMore: false,
  })
  const [filter, setFilter] = useState(defaultFilter)

  const hasPermissionAdminTO = permissionList.find((item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'EDIT_ALL_CLASS' &&
      item.module === 'E_MANAGECLASS_SETTING'
    )
  })
  const isTO = _.find(_.get(user, 'area', []), (item) => {
    return (
      _.get(item, 'department.department', '') ===
      'AXALFTH - Training Operations'
    )
  })

  useEffect(async () => {
    if (body.isLoadMore) {
      dispatch(startLoadmoreLoading())
    } else {
      dispatch(startLoading())
      dispatch(
        setDataList({
          totalCount: 0,
          result: [],
        }),
      )
    }
    const summary = await dispatch(getToDoListSummary(body))
    const list = await dispatch(getToDoListFilter(body))
    dispatch(setSummaryCount(summary.payload))
    dispatch(setDataList(list.payload))
    if (body.isLoadMore) {
      dispatch(stopLoadmoreLoading())
    } else {
      dispatch(stopLoading())
    }
  }, [body])

  const onFilterClick = (filterSelected) => {
    let filterData = {
      workType: [],
      taskName: [],
      status: [],
      startDate:
        filterSelected.startDate === ''
          ? filterSelected.startDate
          : dayjs(new Date(filterSelected.startDate)).format('YYYY-MM-DD'),
      endDate:
        filterSelected.endDate === ''
          ? filterSelected.endDate
          : dayjs(new Date(filterSelected.endDate)).format('YYYY-MM-DD'),
    }
    const filterDataLabel = {
      workType: [],
      taskName: [],
      status: [],
    }

    let filterToClear = filter
    if (!filterSelected.startDateChecked) {
      filterToClear = clearFilterByType('assignDate', filterToClear)
      filterData = {
        ...filterData,
        startDate: '',
        endDate: '',
      }
    }
    if (!filterSelected.taskNameChecked) {
      filterToClear = clearFilterByType('taskName', filterToClear)
    }

    if (!filterSelected.workTypeChecked) {
      filterToClear = clearFilterByType('workType', filterToClear)
    }

    if (!filterSelected.statusChecked) {
      filterToClear = clearFilterByType('status', filterToClear)
    }

    allFilterType.map((i) => {
      Object.keys(filterSelected[i]).map((key) => {
        if (filterSelected[i][key]) {
          filterDataLabel[i].push(filterDrawerLabel[key].label)
          if (_.isArray(_.get(filterDrawerLabel, `${key}.value`))) {
            _.get(filterDrawerLabel, `${key}.value`, []).map((item) => {
              filterData[i].push(item)
            })
          } else {
            filterData[i].push(filterDrawerLabel[key].value)
          }
        }
      })
    })

    setFilterMasterData(filterData)
    setFilterMasterDataLabel(filterDataLabel)

    setFilter({
      ...filterToClear,
    })

    setBody((prevState) => ({
      ...prevState,
      ...filterData,
      isLoadMore: false,
    }))
  }

  const clearFilterByType = (type, filterToClear) => {
    if (type === 'taskName') {
      filterToClear.taskNameChecked = false
      _.map(allTaskNameChecked, (item) => {
        filterToClear.taskName[item] = false
      })
    }
    if (type === 'workType') {
      filterToClear.workTypeChecked = false
      _.map(allWorkTypeChecked, (item) => {
        filterToClear.workType[item] = false
      })
    }
    if (type === 'status') {
      filterToClear.statusChecked = false
      _.map(allStatusChecked, (item) => {
        filterToClear.status[item] = false
      })
    }
    if (type === 'assignDate') {
      filterToClear.startDateChecked = false
      filterToClear.startDate = ''
      filterToClear.endDate = ''
      filterToClear.displayDate = false
    }
    return filterToClear
  }

  const onClickUnFilter = (type) => {
    let newFilterData
    const filterToClear = clearFilterByType(type, filter)
    if (type === 'assignDate') {
      newFilterData = {
        ...filterMasterData,
        startDate: '',
        endDate: '',
      }
    } else {
      newFilterData = {
        ...filterMasterData,
        [type]: [],
      }

      setFilterMasterDataLabel({
        ...filterMasterDataLabel,
        [type]: [],
      })
    }

    setFilter({
      ...filterToClear,
    })

    setFilterMasterData(newFilterData)

    setBody((prevState) => ({
      ...prevState,
      ...newFilterData,
      isLoadMore: false,
    }))
  }

  const onTabClick = (type) => {
    setParam((prevState) => ({
      ...prevState,
      type,
    }))

    if (type === 'NOTIFICATION') {
      const filterData = {
        workType: [],
        taskName: [],
        status: [],
      }

      setFilterMasterData(filterData)
      setFilterMasterDataLabel({
        ...filterData,
        startDate: '',
        endDate: '',
      })

      let filterToClear = filter
      filterToClear = clearFilterByType('assignDate', filterToClear)
      filterToClear = clearFilterByType('taskName', filterToClear)
      filterToClear = clearFilterByType('workType', filterToClear)
      filterToClear = clearFilterByType('status', filterToClear)

      setFilter({
        ...filterToClear,
      })

      setBody((prevState) => ({
        ...prevState,
        type: [type],
        order: 'DESC',
        ...filterData,
        startDate: '',
        endDate: '',
        isLoadMore: false,
        limit: 10,
      }))
    } else {
      setBody((prevState) => ({
        ...prevState,
        type: [type],
        isLoadMore: false,
        limit: 10,
      }))
    }
  }

  const onSortClick = (orderText) => {
    const order = orderText === 'DESC' ? 'ASC' : 'DESC'
    setBody((prevState) => ({
      ...prevState,
      order,
      isLoadMore: false,
    }))
  }

  const onloadMore = () => {
    const newBody = {
      ...body,
      limit: body.limit + 10,
      isLoadMore: true,
    }

    setBody(newBody)
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading ? { opacity: 0.4, pointerEvents: 'none' } : {}}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">ภาพรวมงานของคุณ</Typography>
        </Box>
        <Box
          sx={{
            m: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ToDoCard cardType="today" taskCount={summaryCount.toDayCount} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ToDoCard
              cardType="todo"
              taskCount={summaryCount.allToDoCardCount}
            />
            <ToDoCard
              cardType="onworking"
              taskCount={summaryCount.onWorkingCount}
            />
            <ToDoCard cardType="dontdo" taskCount={summaryCount.toDoCount} />
          </Box>
        </Box>
        <Box
          sx={{
            mx: 3,
            my: 3,
            display: 'flex',
          }}
        >
          <Typography variant="h4">งานทั้งหมด</Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            py: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Button
              sx={{ height: 40, p: 1 }}
              onClick={() => {
                onTabClick('TO_DO')
              }}
            >
              <Typography
                variant="body1b"
                sx={
                  param.type === 'TO_DO'
                    ? { borderBottom: 4, px: 2 }
                    : { color: '#A9B8CD' }
                }
              >{`สิ่งที่ต้องทำทั้งหมด(${summaryCount.allToDoCount})`}</Typography>
            </Button>
            <Button
              sx={{ height: 40, p: 1 }}
              onClick={() => {
                onTabClick('NOTIFICATION')
              }}
            >
              <Typography
                variant="body1b"
                sx={
                  param.type === 'NOTIFICATION'
                    ? { borderBottom: 4, px: 2 }
                    : { color: '#A9B8CD' }
                }
              >{`แจ้งเตือนทั้งหมด(${summaryCount.allNotification})`}</Typography>
            </Button>
          </Box>

          {body.type[0] === 'TO_DO' && (
            <Box>
              <Button
                data-testid={'btn-filter'}
                sx={{ height: 40, p: 1 }}
                startIcon={<img src="/icon/ic_sort.svg" />}
                onClick={() => {
                  onSortClick(body.order)
                }}
              >
                <Typography variant="text">
                  {body.order == 'DESC'
                    ? 'เรียงจากเก่าไปใหม่'
                    : 'เรียงจากใหม่ไปเก่า'}
                </Typography>
              </Button>
              <Button
                data-testid={'btn-filter'}
                sx={{ height: 40, py: 1, px: 2 }}
                variant="text"
                startIcon={<FilterListOutlinedIcon />}
                onClick={() => setIsFilterDrawer(true)}
              >
                <Typography variant="text">ตัวกรอง</Typography>
              </Button>
            </Box>
          )}
        </Box>

        {(filterMasterData.workType.length > 0 ||
          filterMasterData.taskName.length > 0 ||
          filterMasterData.status.length > 0 ||
          (body.startDate !== '' && body.endDate !== '')) && (
          <Box
            sx={{
              mx: 3,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <Card sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'row' }}>
              {allFilterType.map(
                (type, key) =>
                  filterMasterData[type].length > 0 && (
                    <Box
                      key={key}
                      sx={{
                        px: 2,
                        backgroundColor: '#DBE4F199',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        mx: 0.5,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          display: 'inline-block',
                          mr: 1,
                          color: themeColor.text.secondary,
                        }}
                      >
                        {filterLabel[type] + ' :'}
                      </Typography>
                      <Typography variant="body2b">
                        {filterMasterDataLabel[type].map((item, index) => {
                          return index < filterMasterDataLabel[type].length - 1
                            ? item + ', '
                            : item
                        })}
                      </Typography>
                      <CloseRoundedIcon
                        sx={{
                          width: 16,
                          height: 16,
                          alignItems: 'center',
                          cursor: 'pointer',
                          ml: 1,
                          pb: 0.1,
                        }}
                        onClick={() => {
                          onClickUnFilter(type)
                        }}
                      />
                    </Box>
                  ),
              )}
              {body.startDate !== '' && body.endDate !== '' && (
                <Box
                  sx={{
                    px: 2,
                    backgroundColor: '#DBE4F199',
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    mx: 0.5,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inline-block',
                      mr: 1,
                      color: themeColor.text.secondary,
                    }}
                  >
                    {filterLabel['assignDate'] + ' :'}
                  </Typography>
                  <Typography variant="body2b">
                    {`${dayjs(body.startDate).format(
                      window.__env__.REACT_APP_DATE_SHOW,
                    )} ถึง ${dayjs(body.endDate).format(
                      window.__env__.REACT_APP_DATE_SHOW,
                    )}`}
                  </Typography>
                  <CloseRoundedIcon
                    sx={{
                      width: 16,
                      height: 16,
                      alignItems: 'center',
                      cursor: 'pointer',
                      ml: 1,
                      pb: 0.1,
                    }}
                    onClick={() => {
                      onClickUnFilter('assignDate')
                    }}
                  />
                </Box>
              )}
            </Card>
          </Box>
        )}

        {Object.keys(_.get(data, 'result', [])).length > 0 ? (
          <>
            {Object.keys(_.get(data, 'result', [])).map((date) => {
              return (
                <>
                  <Box>
                    <Typography
                      variant="body2b"
                      sx={{ mx: 4, color: '#A9B8CD' }}
                    >
                      {date == today
                        ? 'วันนี้'
                        : date == yesterday
                        ? 'เมื่อวาน'
                        : date}
                    </Typography>
                  </Box>
                  {_.get(data, `result[${date}]`, []).map((item) => {
                    return (
                      <Box
                        key={item}
                        sx={{
                          mx: 4,
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 2,
                        }}
                      >
                        <ToDoListBox
                          list={item}
                          hasPermissionAdminTO={hasPermissionAdminTO}
                          isTO={isTO}
                          today={todayFormat}
                        />
                      </Box>
                    )
                  })}
                </>
              )
            })}
            {(data.totalCount > 10 || data.totalCount % 10 == 0) &&
              data.listCount < data.totalCount && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingButton
                    sx={{
                      height: 40,
                      p: 1,
                      border: 1,
                      borderColor: '#00008F',
                      color: '#00008F',
                    }}
                    loading={isLoadmoreLoading}
                    variant="body1b"
                    onClick={() => {
                      onloadMore()
                    }}
                    loadingPosition="end"
                    endIcon={<></>}
                  >
                    LOAD MORE
                  </LoadingButton>
                </Box>
              )}
          </>
        ) : (
          <Card
            sx={{
              minWidth: 275,
              minHeight: 205,
              mx: 3,
              mt: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {body.type[0] === 'TO_DO' && (
              <Box sx={{ textAlign: 'center' }}>
                <img src="/icon/ic_firework.svg" />
                <br />
                <Typography variant="text">ไม่มีงานในตอนนี้!</Typography>
                <br />
                <Typography variant="text">
                  ผ่อนคลายสักนิด ก่อนเริ่มงานถัดไป
                </Typography>
              </Box>
            )}

            {body.type[0] === 'NOTIFICATION' && (
              <Box sx={{ textAlign: 'center' }}>
                <img src="/icon/ic_smile_heart.svg" />
                <br />
                <Typography variant="text">ไม่มีแจ้งเตือนในตอนนี้!</Typography>
                <br />
                <Typography variant="text">
                  เราจะอัพเดททันทีเมื่อมีแจ้งเตือน
                </Typography>
              </Box>
            )}
          </Card>
        )}
      </Box>

      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />

      <FilterDrawer
        open={isFilterDrawer}
        filter={filter}
        defaultFilter={defaultFilter}
        setFilter={setFilter}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) => onFilterClick(filter)}
      />
    </Box>
  )
}
export default ToDoListView
