import _ from 'lodash'
import { getManageClassLearnerHistory } from '../../../../../../services/manageClass/learnerDetail'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'

export const headCellsHistory = [
  {
    id: 'author',
    label: 'ผู้ดำเนินการ',
    disablePadding: false,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
  },
  {
    id: 'remark',
    label: 'เหตุผล',
    disablePadding: false,
  },
  {
    id: 'dateTime',
    label: 'วันเวลา',
    disablePadding: false,
  },
]

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`
  dispatch(startLoading())

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: [],
      allCount: 0,
      headCells: headCellsHistory,
    })
  )

  const result = await dispatch(getManageClassLearnerHistory(url))
  const payload = _.get(result, 'payload', undefined)

  if (payload) {
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows: _.get(result, 'payload.data.result', []),
        allCount: _.get(result, 'payload.data.totalCount', 0),
        headCells: headCellsHistory,
      })
    )
  }
  dispatch(stopLoading())
}
