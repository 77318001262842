import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import Breadcrumbs from '../../../../../components/Breadcrumbs'

import { checkScrollWidth } from './events'
import { BoxButton, BoxHeader, BoxLabelTitle } from './Styled'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { validationDraftSchema } from '../schema/schemaDraft'
import { postBodyMutation } from '../mutation/post'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleSubmitClick } from '../../../../../components/CRUD/handler/handleSubmitClick'
import _ from 'lodash'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { breadcrumb } from '../model/breadcrumb'

const Header = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const [isDraft, setIsDraft] = useState(true)
  const { formData, name, nameForBanner, coverImage, periodStart, status } =
    useSelector(
      (state) => ({
        formData: state.crud.formData,
        name: state.crud.formData.name,
        nameForBanner: state.crud.formData.nameForBanner,
        coverImage: state.crud.formData.coverImage,
        periodStart: state.crud.formData.periodStart,
        status: state.crud.formData.status,
      }),
      shallowEqual,
    )

  const [isScrolled, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  useEffect(() => {
    if (_.isEmpty(name && nameForBanner && coverImage && periodStart)) {
      setIsDraft(true)
    } else {
      setIsDraft(false)
    }
  }, [name, nameForBanner, coverImage, periodStart])

  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle>
        <Typography variant="h4">
          {_.isEmpty(uuid) ? 'สร้าง Collection' : 'แก้ไข Collection'}
        </Typography>
        {!isScrolled && <Breadcrumbs menuList={breadcrumb(uuid)} />}
      </BoxLabelTitle>
      <BoxButton>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          size="m"
          startIcon={<RemoveRedEye />}
          onClick={() => {
            localStorage.setItem(
              'ELCollectionPreviewData',
              JSON.stringify(formData),
            )
            window.open(`/e-learning/collection/preview`, '_blank')
          }}
          disabled={isDraft}
        >
          ดูตัวอย่าง
        </Button>

        {status !== 'ACTIVE' && status !== 'DELETED' && (
          <Button
            type="button"
            data-testid="btn-draft"
            variant="outlined"
            size="m"
            onClick={async () => {
              dispatch(
                setFieldValue({ key: 'formData.status', value: 'DRAFT' }),
              )
              handleSubmitClick(
                null,
                postBodyMutation,
                validationDraftSchema,
                'ยืนยันบันทึกฉบับร่าง',
                'คุณต้องการบันทึกข้อมูลนี้เป็นฉบับร่างหรือไม่',
              )
            }}
          >
            บันทึกฉบับร่าง
          </Button>
        )}

        <Button
          type="submit"
          data-testid="btn-submit"
          variant="contained"
          size="m"
        >
          บันทึกและเผยแพร่
        </Button>
      </BoxButton>
    </BoxHeader>
  )
}

export default Header
