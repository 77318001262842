import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'E_LEARNING_COLLECTION',
  moduleSlug: 'e-learning-collection',
  moduleTitleFull: 'Collection',
  modulesTitleShort: 'Collection',
  modulePath: '/e-learning/collection',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
