import _ from 'lodash'
import { path } from '../../../../../constants/path'
import { COURSE_STATUS } from '../../../../../constants/eLearning'
import { FORM_TAB, courseTabFields, settingTabFields } from '../model'
import { schema, schemaDraft } from '../schema'
import { getPathFormState, handleChangeTab } from '../events'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const handlePreview = (setELearningPreview) => {
  const body = getPathFormState()
  setELearningPreview(body)
  window.open(`${path.eLearningLearningPath}/preview`, '_blank')
}

export const handleSubmitBodyDraft = (setValues) => async (dispatch) => {
  const body = getPathFormState()
  await setValues({ ...body, status: COURSE_STATUS.DRAFT, isDraft: true })
  dispatch(handleTabBaseOnValidate(body, schemaDraft))
}

export const handleSubmitBody = (setValues) => async (dispatch) => {
  const body = getPathFormState()
  await setValues({ ...body, isDraft: false })
  dispatch(handleTabBaseOnValidate(body, schema))
}

export const handleTabBaseOnValidate = (body, schema) => (dispatch) => {
  const errorFields = []
  for (const key in schema.fields) {
    if (_.isNil(body[key])) continue
    const isValid = schema.fields[key].isValidSync(body[key])
    if (!isValid) {
      errorFields.push(key)
    }
  }

  if (errorFields.length === 0) return

  const isCourseError = isIncludes(errorFields, courseTabFields)
  const isSettingError = isIncludes(errorFields, settingTabFields)
  let tab = FORM_TAB.learningPath
  if (!isCourseError && isSettingError) tab = FORM_TAB.setting
  dispatch(handleChangeTab(tab))
}

export const isIncludes = (list, checkList) => {
  return checkList.some((field) => list.includes(field))
}
