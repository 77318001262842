import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../ELearning/components/LoadingPageCircular'
import { StyledFormWrapper } from './Styled'
import { schema } from './schema'
import { fetchSettingPointDetail, handleSubmit } from './events'
import Header from './Header'
import PointInfo from './PointInfo'
import BadgeInfo from './BadgeInfo'
import TimeRange from './TimeRange'
import Creator from './Creator'
import Status from './Status'

const SettingPointForm = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.settingPoint.isLoading,
    }),
    shallowEqual,
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])
  useEffect(() => {
    dispatch(fetchSettingPointDetail({ uuid, onEdit, setOnEdit }))
  }, [])
  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      <Box sx={{ mx: 3 }}>
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, actions) =>
            dispatch(handleSubmit(values, history, actions))
          }
        >
          <Form>
            <Header />
            <StyledFormWrapper>
              <PointInfo />
              <Status />
              <TimeRange />
              <BadgeInfo />
              <Creator />
            </StyledFormWrapper>
          </Form>
        </Formik>
      </Box>
    </>
  )
}

export default SettingPointForm
