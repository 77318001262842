import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import styled from '@mui/system/styled'

import _ from 'lodash'
import {
  manageClassTheme,
  statusTheme,
} from '../../../../constants/manageClass'

export const StyledRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}))

export const StyledColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledRightBox = styled(Box)(({ theme, status }) => ({
  height: (status === 'ENDCLASS' || status === 'CLOSED') && '100px',
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    height: (status === 'ENDCLASS' || status === 'CLOSED') && '172px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto',
  },
}))

export const StyledContainer = styled(StyledRow)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0.5),
  },
}))

export const StyledDashboardRow = styled(StyledRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: theme.spacing(2),
  gap: theme.spacing(1.5),
  boxShadow: `0px 12px 40px ${theme.palette?.primary?.outlinedHoverBackground}`,
  '& > .MuiBox-root:first-of-type': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: { height: 200 },
}))

export const StyledStatusCard = styled(Box)(({ theme, field }) => ({
  position: 'relative',
  // Hide Inventory
  width: window.__env__.ENV !== 'PROD' ? '67%' : '100%',
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid transparent',
  borderRadius: theme.spacing(1),
  background: _.get(statusTheme[field], 'background', 'transparent'),
  img: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

export const StyledColoredCard = styled(Box)(({ theme, field }) => ({
  position: 'relative',
  width: '33%',
  minWidth: 248,
  display: 'flex',
  flexDirection: 'column',
  background: _.get(manageClassTheme[field], 'background', 'transparent'),
  border: '1px solid',
  borderColor: _.get(manageClassTheme[field], 'border', 'transparent'),
  borderRadius: theme.spacing(1),
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  hr: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderWidth: `0 0 0 ${theme.spacing(1)}`,
    borderColor: _.get(manageClassTheme[field], 'leftBorder', 'transparent'),
    borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
  },
  img: { marginBottom: theme.spacing(1) },
  button: {
    position: 'absolute',
    bottom: 12,
    right: 12,
  },
  [theme.breakpoints.down('md')]: { minWidth: 'unset' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: 105,
  height: 30,
  background: theme.palette?.other?.ratingActiveChip,
  span: {
    color: theme.palette?.warning?.main,
    fontSize: 16,
    fontWeight: 400,
  },
}))
