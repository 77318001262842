import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import { useSelector, shallowEqual } from 'react-redux'
import Score from '../Score'
import Question from '../Question'
import DragDropAnswer from '../DragDropAnswer'
import AddAnswer from '../AddAnswer'
import { getErrorMessage } from '../event'

import AlertErrorBox from '../AlertErrorBox'

import * as StyledForm from '../Styled'

const Index = ({ keyQuestion, indexQuestion }) => {
  const { question, errorSchemaMessage } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  const { questionType } = question
  const correctErrorMessage = getErrorMessage({
    field: 'CORRECT',
    errorSchemaMessage,
    indexQuestion,
  })

  return (
    <>
      <Card>
        <StyledForm.BoxForm>
          <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
          {correctErrorMessage !== '' && <AlertErrorBox />}

          <Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body1b">คำตอบ</Typography>
            </Box>

            <DragDropAnswer
              keyQuestion={keyQuestion}
              indexQuestion={indexQuestion}
            />

            <AddAnswer keyQuestion={keyQuestion} />
          </Box>
        </StyledForm.BoxForm>

        <Score keyQuestion={keyQuestion} questionType={questionType} />
      </Card>
    </>
  )
}
export default Index
