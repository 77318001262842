import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { getBodyMutation } from '../mutation/get'
import _ from 'lodash'

export const clearDrawerInput = () => {
  const { formData } = store.getState().crud
  const data = {
    status: _.get(formData, 'status', ''),
    id: _.get(formData, 'id', ''),
    from: _.get(formData, 'from', ''),
    to: _.get(formData, 'to', ''),
    type: _.get(formData, 'type', ''),
    remark: _.get(formData, 'remark', ''),
    uuid: _.get(formData, 'uuid', undefined),
    createdAt: _.get(formData, 'createdAt', ''),
    createdBy: _.get(formData, 'createdBy', ''),
    updatedAt: _.get(formData, 'updatedAt', ''),
    updatedBy: _.get(formData, 'updatedBy', ''),
  }
  store.dispatch(
    setFieldValue({
      key: 'formData',
      value: getBodyMutation(data),
    }),
  )

  store.dispatch(
    setFieldValue({
      key: 'assetEdit',
      value: null,
    }),
  )
}
