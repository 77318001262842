import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import DateRangeInput from '../../components/DateRangeInput'
import FormGroup from '@mui/material/FormGroup'
import dayjs from 'dayjs'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'
import TextFieldInput from '../../components/Input/TextFieldInput'

export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'รอเปิดใช้งาน',
    name: 'pendingChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

const FilterDrawer = ({ open, onClose, onFilterClick, drawerType }) => {
  const defaultFilter =
    drawerType === 'versions'
      ? {
          versionChecked: false,
          version: '',
          englishNameChecked: false,
          englishName: '',
          nameForLearnerChecked: false,
          nameForLearner: '',
          startDateChecked: false,
          startDate: '',
          toDateChecked: false,
          toDate: '',
          updatedByChecked: false,
          updatedBy: '',
          statusChecked: false,
          status: {
            draftChecked: false,
            pendingChecked: false,
            activeChecked: false,
            inActiveChecked: false,
            deletedChecked: false,
          },
          createdByChecked: false,
          createdBy: '',
          assigneeChecked: false,
          assignee: '',
        }
      : {
          courseCodeChecked: false,
          courseCode: '',
          englishNameChecked: false,
          englishName: '',
          nameForLearnerChecked: false,
          nameForLearner: '',
          startDateChecked: false,
          startDate: '',
          toDateChecked: false,
          toDate: '',
          numberOfVersionsChecked: false,
          numberOfVersions: '',
          updatedByChecked: false,
          updatedBy: '',
          statusChecked: false,
          status: {
            draftChecked: false,
            pendingChecked: false,
            activeChecked: false,
            inActiveChecked: false,
            deletedChecked: false,
          },
          createdByChecked: false,
          createdBy: '',
        }

  const arrayCheckbox =
    drawerType === 'versions'
      ? [
          {
            label: 'เวอร์ชั่น',
            nameCheckBox: 'versionChecked',
            nameInput: 'version',
            placeholder: 'ค้นหาเวอร์ชั่น',
            number: true,
          },
          {
            label: 'ชื่อหลักสูตร EN',
            nameCheckBox: 'englishNameChecked',
            nameInput: 'englishName',
            placeholder: 'ค้นหาชื่อหลักสูตร EN',
          },
          {
            label: 'ชื่อหลักสูตร (สำหรับ Learner)',
            nameCheckBox: 'nameForLearnerChecked',
            nameInput: 'nameForLearner',
            placeholder: 'ค้นหาชื่อหลักสูตร (สำหรับ Learner)',
          },
          {
            label: 'วันเริ่มต้น',
            nameCheckBox: 'startDateChecked',
            nameInput: 'startDate',
            placeholder: 'ค้นหาวันเริ่มต้น',
            isDate: true,
          },
          {
            label: 'แก้ไขล่าสุดโดย',
            nameCheckBox: 'updatedByChecked',
            nameInput: 'updatedBy',
            placeholder: 'ค้นแก้ไขล่าสุดโดย',
          },
          {
            label: 'สร้างโดย',
            nameCheckBox: 'createdByChecked',
            nameInput: 'createdBy',
            placeholder: 'ค้นหาสร้างโดย',
          },
          {
            label: 'ผู้รับผิดชอบ',
            nameCheckBox: 'assigneeChecked',
            nameInput: 'assignee',
            placeholder: 'ค้นหาผู้รับผิดชอบ',
          },
        ]
      : [
          {
            label: 'รหัสหลักสูตร',
            nameCheckBox: 'courseCodeChecked',
            nameInput: 'courseCode',
            placeholder: 'ค้นหารหัสหลักสูตร',
          },
          {
            label: 'ชื่อหลักสูตร EN',
            nameCheckBox: 'englishNameChecked',
            nameInput: 'englishName',
            placeholder: 'ค้นหาชื่อหลักสูตร EN',
          },
          {
            label: 'ชื่อหลักสูตร (สำหรับ Learner)',
            nameCheckBox: 'nameForLearnerChecked',
            nameInput: 'nameForLearner',
            placeholder: 'ค้นหาชื่อหลักสูตร (สำหรับ Learner)',
          },
          {
            label: 'จำนวนเวอร์ชั่น',
            nameCheckBox: 'numberOfVersionsChecked',
            nameInput: 'numberOfVersions',
            placeholder: 'จำนวนเวอร์ชั่น',
            number: true,
          },
          {
            label: 'แก้ไขล่าสุดโดย',
            nameCheckBox: 'updatedByChecked',
            nameInput: 'updatedBy',
            placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
          },
          {
            label: 'สร้างโดย',
            nameCheckBox: 'createdByChecked',
            nameInput: 'createdBy',
            placeholder: 'ค้นหาสร้างโดย',
          },
        ]
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        listArray={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <ListStatus
              arrayStatus={arrayStatus}
              status={filter.status}
              filter={filter}
              setFilter={setFilter}
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => onClearClick(setFilter, defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer

export const handleChange = ({
  key,
  value,
  isStatus = false,
  filter,
  setFilter,
}) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if ((key === 'numberOfVersions' || key === 'version') && Number(value) < 0) {
    objState = {
      ...filter,
      [key]: 0,
    }
  }
  if (key === 'startDate-toDate') {
    objState = {
      ...filter,
      startDate: dayjs(value.startDate).format('YYYY-MM-DD'),
      toDate: dayjs(value.endDate).format('YYYY-MM-DD'),
    }
  }

  if (isStatus) {
    objState = {
      ...filter,
      status: {
        ...filter.status,
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const onClearClick = (setFilter, defaultFilter) => {
  setFilter(defaultFilter)
}

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                defaultChecked
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {!item.isDate && !item.number && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{
                    'data-testid': `input-${item.nameInput}`,
                  }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {!item.isDate && item.number && (
                <TextFieldInput
                  sx={{ borderRadius: 0 }}
                  type="number"
                  inputProps={{
                    'data-testid': `input-${item.nameInput}`,
                  }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  isShowTextError={false}
                  onChange={(e) => {
                    handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.isDate && (
                <DateRangeInput
                  dateState={{
                    startDate:
                      stateFilter.startDate === ''
                        ? new Date()
                        : new Date(stateFilter.startDate),
                    endDate:
                      stateFilter.toDate === ''
                        ? new Date()
                        : new Date(stateFilter.toDate),
                    display: stateFilter.startDate === '' ? false : true,
                    key: 'selection',
                  }}
                  onChange={(e) => {
                    handleChange({
                      key: 'startDate-toDate',
                      value: e,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListStatus = ({ arrayStatus, status, filter, setFilter }) => {
  return arrayStatus.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{
              'data-testid': `check-${item.name}`,
            }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              handleChange({
                value: e.target.checked,
                key: e.target.name,
                isStatus: true,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}
