import React, { useState } from 'react'
import _ from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import { StyledImageDisplay, StyledIconBox } from '../stlyed'
import { checkTypeFile } from '../handler/checkFileType'

const PreviewImage = ({ file, popupLabel }) => {
  const [isOpen, setOpen] = useState(false)
  const imageKey = _.get(file, 'key', '')
  const imageSrc = `${window.__env__.REACT_APP_API_URL}/file/get${imageKey}`
  const fileType = _.toLower(_.get(file, 'fileType', ''))
  const isTypeValid = checkTypeFile(file.fileType)
  if (_.isEmpty(imageKey)) return <></>

  return (
    <>
      {['pdf'].includes(fileType) ? (
        <StyledIconBox sx={{ ml: 2 }} onClick={() => setOpen(true)}>
          <img src={`/icon/${isTypeValid ? fileType : 'file'}.svg`} alt="" />
        </StyledIconBox>
      ) : (
        <StyledImageDisplay src={imageSrc} onClick={() => setOpen(true)} />
      )}
      <PreviewImagePopup
        fileName={_.get(file, 'displayName', '')}
        src={imageSrc}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        fileType={fileType}
        popupLabel={popupLabel}
      />
    </>
  )
}

export default PreviewImage

export const PreviewImagePopup = ({
  fileName,
  src,
  isOpen,
  onClose,
  fileType,
  popupLabel,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">{ popupLabel ? popupLabel : fileName}</Typography>
        <IconButton
          data-testid="close-preview"
          color="primary"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 900 }}>
        {['pdf'].includes(fileType) ? (
          <iframe
            src={src + ''}
            height={500}
            width={400}
            style={{ maxWidth: '100%' }}
            scrolling={'yes'}
          />
        ) : (
          <img src={src} style={{ width: '100%', objectFit: 'cover' }} />
        )}
      </DialogContent>
    </Dialog>
  )
}
