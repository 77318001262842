import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import { StyledCard } from '../../../Styled'
import { Row } from '../../Styled'
import { StyledImg } from './Styled'
import InfoCard from './InfoCard'
import CourseList from './CourseList'
import { openCourseDrawer } from '../../../../../redux/slices/eLearning/dashboard'

const OverviewCourseSection = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { totalCourse, totalEnroll, latestCreate, latestUpdate } = useSelector(
    (state) => ({
      totalCourse: state.eLearningDashboard.overview.totalCourse,
      totalEnroll: state.eLearningDashboard.overview.totalEnroll,
      latestCreate: state.eLearningDashboard.overview.latestCreate,
      latestUpdate: state.eLearningDashboard.overview.latestUpdate,
    }),
    shallowEqual,
  )
  return (
    <Row>
      <StyledCard sx={{ p: 2, maxWidth: '60%', minWidth: '60%' }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          สรุปรวมหลักสูตร
        </Typography>
        <Row sx={{ gap: 2, mb: 2 }}>
          <InfoCard
            label="ทั้งหมด"
            number={totalCourse}
            unit="หลักสูตร"
            backgroundButton="#F1F4FA"
            onClick={() => {
              dispatch(
                openCourseDrawer({
                  header: 'หลักสูตรทั้งหมด',
                  drawerType: 'course',
                  courseType: 'all',
                }),
              )
            }}
          />
          <InfoCard
            label="ผู้เรียน Enroll (ทุกประเภท)"
            number={totalEnroll}
            unit="คน"
            background="#F1F4FA"
            backgroundButton="#F1F4FA"
            onClick={() => {
              dispatch(
                openCourseDrawer({
                  header: 'รายชื่อผู้เรียน Enroll (ทุกประเภท) - รายการหลักสูตร',
                  drawerType: 'enroll',
                  courseType: 'all',
                }),
              )
            }}
          />
        </Row>
        <Row sx={{ gap: 2 }}>
          <CourseList
            label="หลักสูตรที่ถูกเพิ่มล่าสุด"
            list={latestCreate}
            color={theme.palette.primary.main}
            background="#F1F4FA"
          />
          <CourseList
            label="หลักสูตรที่ถูกแก้ไขล่าสุด"
            list={latestUpdate}
            color={theme.palette.primary.main}
            background="#F1F4FA"
          />
        </Row>
      </StyledCard>
      <StyledImg src="/image/dashboard_decoration.svg" />
    </Row>
  )
}

export default OverviewCourseSection
