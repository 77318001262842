export const filtersListClassDashboard = [
  {
    label: 'วันที่',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'เลือกวันที่',
    type: 'dateRange',
  },
  {
    label: 'Department',
    nameCheckBox: 'departmentChecked',
    nameInput: 'department',
    type: 'select',
    placeholder: 'เลือก Department',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    type: 'checkbox',
  },
]

export const headCellsClass = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'station',
    label: 'Station',
    disablePadding: false,
    hideSortIcon: true,
    width: '200px',
  },
  {
    id: 'online',
    label: 'Online',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'classroom',
    label: 'Classroom',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'total',
    label: 'รวม',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'actionMcDashboard',
    label: '',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `/manage-class/report-export-by-class`,
    minWidth: '40px',
    isMcDashboard: true,
  },
]
