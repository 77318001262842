import { eTestingVersionFilter } from '../../../../../utils/apiPath'
import { headerCells } from './model/headerCells'

export const crudConfig = {
  moduleId: 'ETESTING_DASHBOARD_VERSION',
  moduleSlug: 'e-testing-dashboard-version',
  moduleTitleFull: 'Dashboard : เวอร์ชัน',
  modulesTitleShort: 'Dashboard : เวอร์ชัน',
  modulePath: '/e-testing/dashboard/version/:codeId',
  filterPath: eTestingVersionFilter,
  headerCells: headerCells,
}
