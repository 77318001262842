import _ from 'lodash'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const handleSubmitData = (data) => ({
  ...data,
  coursesInformation: _.get(data, 'coursesInformation', []).map((course) => ({
    uuid: _.get(course, 'uuid', ''),
    taps: _.get(course, 'taps', []).map((tab) => ({
      ...tab,
      courses: _.get(tab, 'courses', []).map((obj) => ({
        status: _.get(obj, 'status', false),
        course: {
          uuid: _.get(obj, 'course.uuid', ''),
          courseCode: _.get(obj, 'course.courseCode', ''),
        },
      })),
      tabTitle: _.get(tab, 'tabTitle', '').trim(),
    })),
  })),
})
