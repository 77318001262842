import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { TextField } from "@mui/material"

export const inputWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    border: 'solid 1px',
    borderColor: theme.palette?.silver?.primary,
}))

export const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-inputAdornedStart': {
        width: 'auto',
    },
}))