import { Box } from '@mui/system'
import ViewString from './ViewString'
import { Typography } from '@mui/material'

export const SingleAnswer = ({
  data,
  question,
  mostIncorrect,
  minWidth,
  openAll,
}) => {
  let isMostIncorrect = false
  if (mostIncorrect === data?.answerCount) {
    isMostIncorrect = true
  }
  let answerPercentage = '0%'

  if (question?.totalAnswer !== 0) {
    answerPercentage =
      ((data?.answerCount / question.totalAnswer) * 100)
        .toFixed(2)
        .replace(/[.,]00$/, '') + '%'
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
      }}
    >
      <ViewString
        value={data?.title}
        limit={140}
        customSx={{
          maxWidth: '40%',
          minWidth: `calc(min(${minWidth}, 40%))`,
          '@media print': {
            overflow: 'hidden',
            lineHeight: '1.5em',
            webkitLineClamp: '4',
            minHeight: data?.title?.length > 140 && '6em',
          },
        }}
        openAll={openAll}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            height: '42px',
            width: answerPercentage,
            backgroundColor: data?.isCorrectAnswer
              ? '#1CC54E'
              : isMostIncorrect
              ? '#C91432'
              : '#D64F65',
            borderRadius: '4px',
            '@media print': {
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        />
        <Typography sx={{ p: '4px', whiteSpace: 'nowrap', margin: 'auto 0' }}>
          {data?.answerCount} ({answerPercentage})
        </Typography>
      </Box>
    </Box>
  )
}
