import React, { useMemo } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import Autocomplete from '../../../../../../../components/Input/Autocomplete'
import { moduleTypeOption } from '../../../../../Module/Form/Info/model'
import { getStateValue, handleChange } from '../../../events'
import { StyledFlexRow } from '../Styled'
import { DragHolder } from '../DragDropContent'
import { handleDeleteItem } from '../events'
import { handlePreview } from './events'

const ModuleForm = ({ id, index, moduleKey }) => {
  const moduleTypeKey = `${moduleKey}[${index}].moduleType`
  const uuidKey = `${moduleKey}[${index}].eLearningModule.uuid`
  const moduleObjKey = `${moduleKey}[${index}].eLearningModule`
  const dispatch = useDispatch()
  const { moduleOption, moduleType, uuid, onGoing } = useSelector(
    (state) => ({
      moduleOption: _.get(state.eLearningCourseForm, 'moduleOption', []),
      moduleType: getStateValue(moduleTypeKey),
      uuid: getStateValue(uuidKey),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const moduleOptionFiltered = useMemo(
    () =>
      [...moduleOption].filter((course) => course.moduleType === moduleType),
    [moduleOption, moduleType],
  )

  return (
    <Draggable index={index} key={id} draggableId={id} isDragDisabled={onGoing}>
      {(provided) => (
        <StyledFlexRow {...provided.draggableProps} ref={provided.innerRef}>
          <DragHolder provided={provided} mt={1} disabled={onGoing} />
          <AutocompleteModule
            placeholder="ประเภทโมดูล"
            boxSx={{ minWidth: 273, width: 273 }}
            keyField={moduleTypeKey}
            uuidKey={uuidKey}
            options={moduleTypeOption}
            isHideSort
          />
          <AutocompleteModule
            placeholder="ชื่อโมดูล"
            keyField={uuidKey}
            moduleObjKey={moduleObjKey}
            options={moduleOptionFiltered}
            isHideSort={false}
          />
          {!_.isEmpty(uuid) && (
            <IconButton onClick={() => handlePreview(uuid)}>
              <RemoveRedEyeTwoTone />
            </IconButton>
          )}
          {index !== 0 && (
            <IconButton
              onClick={() => dispatch(handleDeleteItem(index, moduleKey))}
              disabled={onGoing}
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StyledFlexRow>
      )}
    </Draggable>
  )
}

export default ModuleForm

export const AutocompleteModule = (props) => {
  const {
    placeholder,
    keyField,
    uuidKey,
    moduleObjKey,
    options,
    boxSx,
    isHideSort,
  } = props
  const dispatch = useDispatch()
  const { values, onGoing } = useSelector(
    (state) => ({
      values: getStateValue(keyField),
      onGoing: state.eLearningCourseForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()

  return (
    <Autocomplete
      boxSx={boxSx}
      errorSx={{ mb: 0 }}
      name={keyField}
      options={options}
      isHideSort={isHideSort}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          inputProps={{ ...params.inputProps, 'data-testid': 'select' }}
        />
      )}
      textError={_.get(errors, keyField, '')}
      showTextError={_.get(errors, keyField, '')}
      defaultValue={
        options.find((item) => item.value === values) || {
          label: '',
          value: '',
        }
      }
      handleChange={(e) => {
        if (moduleObjKey) {
          const selectedObj = options.find((item) => item.value === e.target.id)
          dispatch(handleChange(moduleObjKey, selectedObj))
        } else {
          if (uuidKey) dispatch(handleChange(uuidKey, ''))
          dispatch(handleChange(keyField, e.target.id))
        }
      }}
      disabled={onGoing}
    />
  )
}
