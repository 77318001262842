import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'

export const StyledFileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 210,
  minWidth: 210,
  gap: theme.spacing(2),
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  background: '#F6F9FF',
  borderRadius: theme.spacing(0.5),
}))

export const StyledTypography = styled(Typography)(() => ({
  maxWidth: 132,
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '18px',
  },
}))

export const StyledIconBox = styled(Box)(({ theme }) => ({
  width: 46,
  height: 46,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette?.background?.paper,
  border: ' 1px solid #ECECEC',
  borderRadius: theme.spacing(0.5),
}))
