import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getStyleStatus } from './handle'

const AlertStatus = (props) => {
  const getStyle = getStyleStatus(props.data)
  return (
    <Box
      sx={{
        borderRadius: '24px',
        textAlign: 'center',
        height: 30,
        px: 1,
        ...getStyle.style,
      }}
    >
      <Typography variant="chip">{getStyle.text}</Typography>
    </Box>
  )
}
export default AlertStatus
