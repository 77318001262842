import React from 'react'
import Typography from '@mui/material/Typography'
import { Frame, TypographyIcon, UnderList } from '../Styled'
import { DialogContent } from './Styled'
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone'
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone'
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { StatusComplete } from '../index'

export const DialogWaitingCheck = () => {
  const { manageClassClose } = useSelector(
    (state) => ({
      manageClassClose: state.manageClassDetail.checkClose,
    }),
    shallowEqual
  )
  const getNeverOpenETesting = _.get(
    manageClassClose,
    'checkETesting.getNeverOpenETesting',
    []
  )
  const getStillOpenETesting = _.get(
    manageClassClose,
    'checkETesting.getStillOpenETesting',
    []
  )
  const getWaitingToCheckETesting = _.get(
    manageClassClose,
    'checkETesting.getWaitingToCheckETesting',
    []
  )
  const getNeverOpenEEvaluation = _.get(
    manageClassClose,
    'checkEValuation.getNeverOpenEEvaluation',
    []
  )
  const getStillOpenEEvaluation = _.get(
    manageClassClose,
    'checkEValuation.getStillOpenEEvaluation',
    []
  )

  const haveETestingToDo = _.get(manageClassClose, 'haveETestingToDo', false)

  const haveEEvaluationToDo = _.get(
    manageClassClose,
    'haveEEvaluationToDo',
    false
  )

  return (
    <>
      <DialogContent>
        <Typography variant="body1">
          โปรดตรวจสอบกิจกรรมทั้งหมด
          <Typography variant="body1b">
            {' '}
            ‘ที่มีผลต่อเกณฑ์การผ่านอบรม’{' '}
          </Typography>
          ของผู้เรียนอีกครั้งว่า ถูกต้องและครบถ้วน เพื่อปิดคลาส
        </Typography>
        {haveETestingToDo && (
          <Frame>
            <Typography variant="body1b">แบบทดสอบ</Typography>
            {getNeverOpenETesting.length > 0 ||
            getStillOpenETesting.length > 0 ||
            getWaitingToCheckETesting.length > 0 ? (
              <>
                {getNeverOpenETesting.length > 0 && (
                  <>
                    <TypographyIcon variant="body1" color="warning.main">
                      <ReportProblemTwoToneIcon sx={{ fontSize: 'inherit' }} />
                      พบแบบทดสอบไม่เคยเปิดให้ทำ {
                        getNeverOpenETesting.length
                      }{' '}
                      รายการ
                    </TypographyIcon>
                    <UnderList>
                      {getNeverOpenETesting.map((item, index) => (
                        <>
                          <li key={index}>{`(${item.type}) ${item.name}`}</li>
                        </>
                      ))}
                    </UnderList>
                  </>
                )}
                {getStillOpenETesting.length > 0 && (
                  <>
                    <TypographyIcon variant="body1" color="warning.main">
                      <TimerTwoToneIcon sx={{ fontSize: 'inherit' }} />
                      ยังมีแบบทดสอบเปิดให้ทำอยู่ {
                        getStillOpenETesting.length
                      }{' '}
                      รายการ
                    </TypographyIcon>
                    <UnderList>
                      {getStillOpenETesting.map((item, index) => (
                        <>
                          <li key={index}>{`(${item.type}) ${item.name}`}</li>
                        </>
                      ))}
                    </UnderList>
                  </>
                )}
                {getWaitingToCheckETesting.length > 0 && (
                  <>
                    <TypographyIcon variant="body1" color="warning.main">
                      <HourglassBottomTwoToneIcon
                        sx={{ fontSize: 'inherit' }}
                      />
                      เหลือแบบทดสอบรอตรวจอยู่ ดังนี้
                    </TypographyIcon>
                    <UnderList>
                      {getWaitingToCheckETesting.map((item, index) => (
                        <>
                          <li key={index}>{`(${item.type}) ${item.name}`}</li>
                        </>
                      ))}
                    </UnderList>
                  </>
                )}
              </>
            ) : (
              <StatusComplete label="ครบ" />
            )}
          </Frame>
        )}
        {haveEEvaluationToDo && (
          <Frame>
            <Typography variant="body1b">แบบประเมิน</Typography>
            {getNeverOpenEEvaluation.length > 0 ||
            getStillOpenEEvaluation.length > 0 ? (
              <>
                {getNeverOpenEEvaluation.length > 0 && (
                  <>
                    <TypographyIcon variant="body1" color="warning.main">
                      <ReportProblemTwoToneIcon sx={{ fontSize: 'inherit' }} />
                      พบแบบประเมินไม่เคยเปิดให้ทำ{' '}
                      {getNeverOpenEEvaluation.length} รายการ
                    </TypographyIcon>
                    <UnderList>
                      {getNeverOpenEEvaluation.map((item, index) => (
                        <>
                          <li key={index}>{`(${item.type}) ${item.name}`}</li>
                        </>
                      ))}
                    </UnderList>
                  </>
                )}
                {getStillOpenEEvaluation.length > 0 && (
                  <>
                    <TypographyIcon variant="body1" color="warning.main">
                      <TimerTwoToneIcon sx={{ fontSize: 'inherit' }} />
                      ยังมีแบบประเมินเปิดให้ทำอยู่{' '}
                      {getStillOpenEEvaluation.length} รายการ จำเป็นต้องปิดก่อน
                    </TypographyIcon>
                    <UnderList>
                      {getStillOpenEEvaluation.map((item, index) => (
                        <>
                          <li key={index}>{`(${item.type}) ${item.name}`}</li>
                        </>
                      ))}
                    </UnderList>
                  </>
                )}
              </>
            ) : (
              <StatusComplete label="ครบ" />
            )}
          </Frame>
        )}
      </DialogContent>
    </>
  )
}
