import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import { callAPI } from '../../../utils/lib'
import { handleFetchError } from './handlerFetchError'
import { fetchDataList } from './fetchDataList'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { setPin } from '../../../modules/EContent/Content/View/events'

export const handleProcessDelete = async (uuid, mutatePropToBody, isPinned) => {
  startDialogLoading()

  try {
    if (isPinned) {
      await store.dispatch(setPin(uuid))
    }
    await fetchData(uuid)
    handleSuccessfulDelete(mutatePropToBody)
  } catch (error) {
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const fetchData = async (uuid = '') => {
  const { crudConfig } = store.getState().crud
  const url = `/crud/${uuid}`
  const headers = { 'x-type': crudConfig.moduleId }

  const response = await callAPI({
    method: 'delete',
    url,
    headers,
  })

  store.dispatch(setFieldValue({ key: 'formData', value: response }))
}

const handleSuccessfulDelete = (mutatePropToBody) => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(closeDialog())
        fetchDataList(mutatePropToBody).then()
      },
    }),
  )
}
