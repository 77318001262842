import * as yup from 'yup'

export const validationSchema = yup.object({
  descriptionName: yup
    .string()
    .max(255, 'Cost Center Name กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  groupCode: yup
    .string()
    .max(255, 'Group Code กรอกได้ไม่เกิน 255 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  costCenter: yup
    .string()
    .max(20, 'Cost Center ID กรอกได้ไม่เกิน 20 ตัวอักษร')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'หมายเหตุกรอกได้ไม่เกิน 3,000 ตัวอักษร'),
  active: yup.boolean(),
})
