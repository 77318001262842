import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { alphabets } from '../../../../../../../../../constants/eTesting'
import Draggable from '../Draggable'
import { StyledNumberBadge } from '../Styled'
import { StyledDropBox } from './Styled'

const Droppable = (props) => {
  const { isMobile, selectedItem } = useSelector(
    (state) => state.eTestingPreview.mobileProps,
    shallowEqual
  )
  const { index, data, isSubmit, questionResult, correctNo, onSelect } = props
  const { active, setNodeRef } = useDroppable({ id: index })
  const targetId = _.get(alphabets, index, '')
  const resultType = _.get(questionResult, 'resultType', 'SHOW_TRUE_FALSE')
  const isCorrect = targetId === _.get(data, 'target', '')
  const isActive = active && data && active.id === data.uuid

  return (
    <>
      <StyledDropBox
        aria-label="Droppable-region"
        id={`drag-drop-area-${index}`}
        ref={setNodeRef}
        variant="span"
        value={data}
        isSubmit={isSubmit}
        isCorrect={isCorrect}
        isMobileSelect={!_.isNil(selectedItem)}
        isDragOut={isActive}
        onClick={() => isMobile && onSelect(index)}
      >
        {data ? <Draggable data={data} isDropped /> : BlankSpace}
      </StyledDropBox>
      {isSubmit && resultType === 'SHOW_TRUE_FALSE' && (
        <StyledNumberBadge
          variant="span"
          color="text.white"
          lineHeight="24px"
          sx={{ margin: '0 4px' }}
          type={isCorrect ? 'true' : 'false'}
        >
          {correctNo}
        </StyledNumberBadge>
      )}
    </>
  )
}

export default Droppable

export const BlankSpace = (
  <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
)
