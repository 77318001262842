import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const removeAssetData = (row) => {
  const { assetData } = store.getState().crud
  const rowIndex = _.get(row, 'rowIndex', null)
  const filteredAssetData = _.defaultTo(assetData, []).filter(
    (item) => item.rowIndex != rowIndex,
  )
  const resetRowIndex = filteredAssetData.map((item, index) => {
    return { ...item, rowIndex: index }
  })
  store.dispatch(
    setFieldValue({
      key: 'assetData',
      value: resetRowIndex,
    }),
  )
}
