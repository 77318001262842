import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'

import Header from './Header'
import CardBanner from './CardBanner'
import { LoadingPage } from '../HomePage/Styled'
import { setDrawer } from '../../../redux/slices/manageContent/login'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchManageContentLogin } from './events'

const Index = () => {
  const [onEdit, setOnEdit] = useLocalStorage('onEdit')
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.manageContentLogin.isLoading,
    shallowEqual
  )

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(setDrawer({ isClose: true }))
    dispatch(fetchManageContentLogin(onEdit, setOnEdit))
  }, [])

  return (
    <Box sx={{ mx: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Header />
        <CardBanner />
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}
export default Index
