import { store } from '../../../../../../App'
import { toggleEvaluationDrawer } from './handleClickOpenDrawer'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { partTitle } from '../models/partModel'
import _ from 'lodash'
import { questionTypeText } from '../constants/type'

export const handleClickViewButton = (type, index = null) => {
  toggleEvaluationDrawer(true)
  const { eEvaluationDashboard } = store.getState().crud
  if (type === 'part1') {
    setEvaDrawer('title', partTitle.part1)
    setEvaDrawer('drawerType', 'part1')
    setEvaDrawer('dataType', 'Rating')
    // fetch data
    const drawerData =
      eEvaluationDashboard?.overview?.courseEva?.drawerData ?? []
    setEvaDrawer('part1.data', drawerData)
  } else if (type === 'part2') {
    const { eEvaluationDashboard } = store.getState().crud
    const trainerData = _.get(
      eEvaluationDashboard,
      `overview.trainerEva.[${index}]`,
      null,
    )
    setEvaDrawer('title', `${partTitle.part2} : ${trainerData?.title ?? ''}`)
    setEvaDrawer('drawerType', 'part2')
    setEvaDrawer('dataType', 'Rating')
    //fetch data with trainer
    let data = []
    if (!_.isNil(trainerData)) {
      data = trainerData?.drawerData ?? []
    }
    setEvaDrawer('part2.data', data)
  } else if (type === 'part3') {
    setEvaDrawer('title', partTitle.part3)
    setEvaDrawer('drawerType', 'part3')
    setEvaDrawer('dataType', 'Rating')
    // fetch data
    const drawerData =
      eEvaluationDashboard?.overview?.courseManageEva?.drawerData ?? []
    setEvaDrawer('part3.data', drawerData)
  } else if (type === 'part4') {
    const { eEvaluationDashboard } = store.getState().crud
    const part4EvaData = _.get(
      eEvaluationDashboard,
      `overview.part4Eva.[${index}]`,
      null,
    )
    const type = part4EvaData?.type
    setEvaDrawer('title', part4EvaData?.title)
    setEvaDrawer('drawerType', 'part4')
    setEvaDrawer('dataType', type)
    const data = []
    if (!_.isNil(part4EvaData)) {
      if (type === questionTypeText.FREE_TEXT) {
        for (const answer of _.defaultTo(part4EvaData.answer, [])) {
          data.push({
            no: answer.no,
            title: `คำตอบที่ ${answer.no}`,
            answerTxt: answer.answer,
          })
        }
      } else if (type === questionTypeText.RATING) {
        const drawerQA = part4EvaData.drawerQA
        const reponseUsers = _.uniq(part4EvaData?.responseUserList ?? [])
        for (const qa of drawerQA) {
          data.push({
            no: qa.sequence,
            question: `ข้อที่ ${qa.sequence} ${qa.title}`,
            attendeeCount: part4EvaData.attendeeCount,
            respondentCount:
              reponseUsers?.length > 0 ? reponseUsers?.length : 0,
            overallPercentage: qa.percent ?? 0,
            data: qa.answers.map((item) => {
              return {
                ...item,
                no: item.no,
                sequence: item.sequence,
                count: item.count,
                percent: item.percent,
                answer: item.title,
              }
            }),
          })
        }
      }
    }
    setEvaDrawer('part4.selectedNo', 1)
    setEvaDrawer('part4.type', type)
    setEvaDrawer('part4.data', data)
    setEvaDrawer('part4.uuid', part4EvaData.uuid)
  }
}

export const setEvaDrawer = (key, value) => {
  store.dispatch(
    setFieldValue({
      key: `eEvaluationDashboard.evaluationDrawer.${key}`,
      value: value,
    }),
  )
}
