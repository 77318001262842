import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { StyledCard } from './Styled'
import AttendanceForm from './AttendanceForm'
import { handleInitialForm, handleSettingRadioChange } from './events'
import { initialAttendance } from '../DefaultValue'

const AttendanceSetting = ({ initialValues, setInitialValues, formik }) => {
  const [attendance, setAttendance] = useState(initialAttendance)
  const isAttendance = useMemo(
    () => _.get(attendance, 'isAttendance', false),
    [attendance]
  )

  useEffect(() => {
    handleInitialForm(initialValues, setAttendance, formik)
  }, [initialValues])

  return (
    <StyledCard>
      <Typography variant="h6">เกณฑ์การเข้าเรียน</Typography>
      <RadioGroup
        data-testid="radio-setCriteria"
        sx={{ pl: 1.5, gap: 0.5 }}
        aria-label="setCriteria"
        name="setCriteria"
        value={isAttendance ? 'yes' : 'no'}
        onChange={(e) => handleSettingRadioChange(e, setInitialValues)}
      >
        <FormControlLabel value="no" control={<Radio />} label="ไม่กำหนด" />
        <FormControlLabel value="yes" control={<Radio />} label="กำหนด" />
      </RadioGroup>
      {isAttendance && (
        <AttendanceForm
          attendance={attendance}
          formik={formik}
          setInitialValues={setInitialValues}
        />
      )}
    </StyledCard>
  )
}

export default AttendanceSetting
