import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Add } from '@mui/icons-material'
import { Button, Divider, RadioGroup } from '@mui/material'
import _ from 'lodash'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import EditorForDragDrop from '../../../../../../components/EditorForDragDrop'
import Score from '../../Score'
import Question from '../../Question'
import DragDropAnswer from '../../DragDropAnswer'
import AlertErrorBox from '../../AlertErrorBox'
import { getErrorMessage } from '../../event'

import { findValueRadio } from '../../SingleChoice/event'
import * as StyledForm from '../../Styled'
import { StyledDialogBox } from '../Styled'
import AddBlank from '../AddBlank'
import { blankComp } from '../Drag'
import { handleAddAnswer, handleEditorChange } from '../events'
import { handleRadioChange } from './events'

const FillInBlankChoice = ({ keyQuestion, indexQuestion }) => {
  const dispatch = useDispatch()
  const [blankNums, setBlankNums] = useState(0)

  const { question, errorSchemaMessage, disableForm, isLoading } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      isLoading: state.eTestingForm.isLoading,
    }),
    shallowEqual
  )
  const { questionType, subTitle, answers } = question
  const editorId = `fill-in-blank-choice-${keyQuestion}`
  const subTitleErrorMessage = getErrorMessage({
    field: 'SUB_TITLE',
    errorSchemaMessage,
    indexQuestion,
  })
  const correctErrorMessage = getErrorMessage({
    field: 'CORRECT',
    errorSchemaMessage,
    indexQuestion,
  })

  useEffect(() => {
    const realAnswer = answers.filter((ans) => ans.target)
    setBlankNums(realAnswer.length)
  }, [keyQuestion])

  return (
    <Card>
      <StyledForm.BoxForm>
        <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
        {correctErrorMessage !== '' && <AlertErrorBox sx={{ marginTop: 0 }} />}

        {!isLoading && (
          <Box sx={{ pt: 2 }}>
            <StyledDialogBox>
              <EditorForDragDrop
                id={editorId}
                disabled={disableForm}
                value={subTitle}
                onChange={(newValue) => {
                  const props = { answers, subTitle, setBlankNums, keyQuestion }
                  dispatch(handleEditorChange(newValue, props, true))
                }}
                textError={subTitleErrorMessage}
              />
              <Button
                variant="outlined"
                startIcon={<Add />}
                size="l"
                sx={{ minWidth: 133 }}
                disabled={disableForm}
                onClick={() =>
                  window.tinymce
                    .get(editorId)
                    .execCommand(
                      'mceInsertContent',
                      false,
                      blankComp(blankNums)
                    )
                }
              >
                แทรกช่องว่าง
              </Button>
            </StyledDialogBox>
            {answers.length > 0 && (
              <BlankInputRadioGroup
                keyQuestion={keyQuestion}
                indexQuestion={indexQuestion}
                answers={answers}
                disableForm={disableForm}
              />
            )}
          </Box>
        )}
      </StyledForm.BoxForm>

      <Score keyQuestion={keyQuestion} questionType={questionType} />
    </Card>
  )
}

export default FillInBlankChoice

export const BlankInputRadioGroup = (props) => {
  const { keyQuestion, indexQuestion, answers, disableForm } = props
  const dispatch = useDispatch()

  return answers.map((answer, indexMainAnswer) => {
    const target = _.get(answer, 'target', '')
    return (
      <>
        {indexMainAnswer > 0 && (
          <Divider sx={{ borderStyle: 'dashed', my: 2 }} />
        )}
        <Box sx={{ mb: 2, ml: 1 }}>
          <Typography variant="body1b">คำตอบ</Typography>
          <Typography variant="body1b" ml={1}>
            ช่องว่าง {_.get(answer, 'target', '')}{' '}
            <Typography variant="span" color="error.main">
              *
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <RadioGroup
            sx={{ mt: -1 }}
            aria-label="radioCheck"
            data-testid={`radio-${indexMainAnswer}`}
            name="radio-buttons-group"
            value={findValueRadio(_.get(answer, 'subAnswers', []))}
            onChange={(e) =>
              dispatch(
                handleRadioChange({
                  value: e.target.value,
                  keyQuestion,
                  answers,
                  indexMainAnswer,
                })
              )
            }
          >
            <DragDropAnswer
              keyQuestion={keyQuestion}
              indexQuestion={indexQuestion}
              indexMainAnswer={indexMainAnswer}
            />
          </RadioGroup>
        </Box>

        <AddBlank
          sx={{ marginLeft: 7 }}
          keyQuestion={keyQuestion}
          onClick={() =>
            !disableForm &&
            dispatch(handleAddAnswer(keyQuestion, answers, target))
          }
        />
      </>
    )
  })
}
