import { Box, Button, IconButton, Tabs, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { StyledCard } from '../Styled'
import { useEffect } from 'react'
import { StyledTab } from './Styled'
import { RefreshTwoTone, HistoryTwoTone, FilterList } from '@mui/icons-material'
import _ from 'lodash'
import { TabContent } from './TabContent'
import HistoryDrawer from './HistoryDrawer'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { LoadingPage } from '../Styled'
import {
  setIsOpenFilterDrawer,
  setTabActive,
  setReduxValue,
  setIsOpenHistoryDrawer,
  setDateQueryStringCalendar,
} from '../../../redux/slices/roomManagement/Dashboard'
import FilterDrawer from '../FilterDrawer'
import { fetchMeetingRoomFilter, handleReloadBookingRoom } from './event'
import { openViewDrawerBooking } from '../../../redux/slices/roomManagement/Drawer'

export const BookingRoomSection = ({ bookingRoomRef }) => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { isLoadingBooking, tabActive, stationUuid } = useSelector(
    (state) => ({
      isLoadingBooking: state.roomManagement.isLoadingBooking,
      tabActive: state.roomManagement.tabActive,
      stationUuid: state.roomManagement.stationUuid,
    }),
    shallowEqual,
  )

  const tabList = [
    {
      label: 'Meeting Room',
    },
    {
      label: 'Calendar',
    },
    {
      label: 'Room Request',
    },
  ]

  useEffect(() => {
    if (_.isEmpty(stationUuid)) return

    dispatch(fetchMeetingRoomFilter())
    return () => {
      dispatch(setReduxValue({ key: 'viewCalendar', value: 'dayGridMonth' }))
    }
  }, [])
  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    if (urlParams.get('drawer')) {
      dispatch(setTabActive({ tabActive: 2 }))
      dispatch(openViewDrawerBooking({ bookingUuid: urlParams.get('id') }))
    } else if (urlParams.get('calendar')) {
      dispatch(setDateQueryStringCalendar(urlParams.get('date')))
      dispatch(setTabActive({ tabActive: 1 }))
    }
  }, [search])

  const handleChangeTab = (e, newValue) => {
    dispatch(setTabActive({ tabActive: newValue }))
  }

  return (
    <>
      <StyledCard
        ref={bookingRoomRef}
        sx={{
          position: 'relative',
          minHeight: 500,
        }}
      >
        <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }}>
          Booking Room
        </Typography>
        <Tabs
          sx={{
            width: '100%',
            borderBottom: `1px solid #DBE4F1`,
            display: 'flex',
            alignItems: 'center',
            mb: 2,
          }}
          variant="scrollable"
          scrollButtons="auto"
          value={tabActive}
          onChange={handleChangeTab}
        >
          {_.map(tabList, (tab, idx) => (
            <StyledTab key={idx} label={tab.label} />
          ))}
          {tabActive !== 1 && (
            <Box sx={{ marginLeft: 'auto', gap: 2, display: 'flex' }}>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => dispatch(handleReloadBookingRoom())}
              >
                <RefreshTwoTone color="action.active" />
              </IconButton>
              {tabActive !== 2 && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => dispatch(setIsOpenHistoryDrawer(true))}
                >
                  <HistoryTwoTone color="action.active" />
                </IconButton>
              )}
              <Button
                startIcon={<FilterList />}
                onClick={() => dispatch(setIsOpenFilterDrawer(true))}
              >
                <Typography
                  variant="body1b"
                  sx={{
                    lineHeight: 1.75,
                    display: { sm: 'block', xs: 'none' },
                  }}
                >
                  ตัวกรอง
                </Typography>
              </Button>
            </Box>
          )}
        </Tabs>
        {!isLoadingBooking && (
          <>
            <TabContent />
          </>
        )}
        <LoadingPage isLoading={isLoadingBooking} size={24} />
      </StyledCard>
      <HistoryDrawer />
      <FilterDrawer />
    </>
  )
}
