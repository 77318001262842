import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined'
import EventBusy from '@mui/icons-material/EventBusy'

import { TooltipDialog } from './Styled'

export const getStatus = (item) => {
  if (_.get(item, 'status', '') === 'CANCELED') {
    return 'cancel'
  }
  if (
    _.get(item, 'alert', true) &&
    (_.get(item, 'monthlyPlan.status', '') === 'REQUEST_EDIT' ||
      _.get(item, 'monthlyPlan.status', '') === 'REQUEST_DELETED')
  ) {
    return 'warning'
  }
  if (_.get(item, 'alert', true)) {
    return 'error'
  }

  return ''
}

const Action = ({ row }) => {
  const status = getStatus(row)

  return (
    <Box sx={{ display: 'flex' }}>
      {status === 'cancel' && (
        <TooltipDialog
          title={<Typography variant="tooltip">ยกเลิกคลาส</Typography>}
          arrow
          placement="bottom"
        >
          <EventBusy
            sx={{
              color: 'text.gray',
              cursor: 'pointer',
            }}
          />
        </TooltipDialog>
      )}
      {status === 'error' && (
        <TooltipDialog
          title={
            <Typography variant="tooltip">รายการมีการเปลี่ยนแปลง</Typography>
          }
          arrow
          placement="bottom"
        >
          <PriorityHighOutlinedIcon
            sx={{
              color: 'error.main',
              cursor: 'pointer',
            }}
          />
        </TooltipDialog>
      )}
      {status === 'warning' && (
        <TooltipDialog
          title={
            <Typography variant="tooltip">
              รายการมีการเปลี่ยนแปลง (รออนุมัติ)
            </Typography>
          }
          arrow
          placement="bottom"
        >
          <HourglassTopOutlinedIcon
            sx={{
              color: 'warning.main',
              cursor: 'pointer',
            }}
          />
        </TooltipDialog>
      )}
    </Box>
  )
}

export default Action
