import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import ViewImage from '../../components/Upload/BasicUpload'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import styled from '@mui/system/styled'

export const TextName = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 100,
}))

const ContributorForm = (props) => {
  const { assignee, setDialog, staffOptions, formik, staff, isEditForm } = props
  const [user] = useLocalStorage('user')

  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
      <ContributorList
        assignee={assignee}
        setDialog={setDialog}
        staffOptions={staffOptions}
        formik={formik}
        staff={staff}
        user={user}
        isEditForm={isEditForm}
      />
    </Box>
  )
}
export const ContributorList = (props) => {
  const { assignee, setDialog, staffOptions, formik, staff, user, isEditForm } =
    props
  return (
    <Box sx={{ py: 1, gap: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'none',
        }}
      >
        {!isEditForm && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
            }}
          >
            {!_.isNil(user?.image) ? (
              <ViewImage
                value={user?.image}
                isShowDescription={false}
                isPreview={true}
                isShowTextError={false}
                isStopPropagation={true}
                style={{
                  width: '40px',
                  height: '40px',
                  marginRight: '8px',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <AccountCircleIcon sx={{ width: 40, height: 40 }} />
            )}
            <TextName variant="body1">{`${user?.firstNameTH} ${user?.lastNameTH}`}</TextName>
            <Typography variant="body1" color="text.silver">
              ผู้จัดทำ
            </Typography>
          </Box>
        )}
      </Box>

      {assignee &&
        assignee.map((item, index) => {
          const name = item.user.firstNameTH + ' ' + item.user.lastNameTH
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                justifyContent:
                  item.userRole !== 'CREATOR' ? 'space-between' : 'none',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                }}
              >
                {!_.isNil(item?.user?.image) ? (
                  <ViewImage
                    value={item?.user?.image}
                    isShowDescription={false}
                    isPreview={true}
                    isShowTextError={false}
                    isStopPropagation={true}
                    style={{
                      width: '40px',
                      height: '40px',
                      marginRight: '8px',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <AccountCircleRoundedIcon sx={{ height: 40, width: 40 }} />
                )}

                <TextName variant="body1">{name}</TextName>
              </Box>
              {item.userRole !== 'CREATOR' ? (
                <IconButton
                  data-testid="icon-delete"
                  sx={{
                    height: 40,
                    width: 40,
                  }}
                  onClick={() => onDelete(index, formik)}
                >
                  <DeleteTwoToneIcon sx={{}} />
                </IconButton>
              ) : (
                <Typography variant="body1" color="text.silver">
                  ผู้จัดทำ
                </Typography>
              )}
            </Box>
          )
        })}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
        }}
      >
        <IconButton
          data-testid="icon-add-assignee"
          sx={{
            backgroundColor: 'blue.blueLine',
            color: 'blue.oceanBlue',
            height: 40,
            width: 40,
          }}
          onClick={() =>
            onAddAssigneeClick(setDialog, staffOptions, formik, staff, user)
          }
        >
          <AddIcon />
        </IconButton>
        <Typography variant="body1">เพิ่มผู้รับผิดชอบ</Typography>
      </Box>
    </Box>
  )
}
export default ContributorForm

export const handleAddAssigneeClick = (value, formik, staff) => {
  const findStaff = staff.find((item) => item.uuid === value)
  formik.setFieldValue('assignee', [
    ...formik.values.assignee,
    { user: findStaff },
  ])
}

export const onDelete = (i, formik) => {
  formik.values.assignee.splice(i, 1)
  formik.setFieldValue('assignee', [])
  formik.setFieldValue('assignee', formik.values.assignee)
}

export const onAddAssigneeClick = (
  setDialog,
  staffOptions,
  formik,
  staff,
  user
) => {
  if (!user || user === null) return

  let value = ''
  const myFilterStaff = staffOptions.filter((item) => item.value !== user.uuid)
  const assignee = formik.values.assignee.map((item) => {
    return { ...item, value: item.user.uuid }
  })
  const diffStaffOptions = _.differenceBy(myFilterStaff, assignee, 'value')

  setDialog({
    open: true,
    variant: 'select',
    title: 'เพิ่มสมาชิก',
    icon: '',
    content: (
      <Box sx={{ mx: 3, mb: 2 }}>
        <Autocomplete
          options={diffStaffOptions.sort(
            (a, b) => -b.label.localeCompare(a.label)
          )}
          getOptionLabel={(option) => option.label}
          sx={{ width: '100%' }}
          renderOption={(props, option) => (
            <Box {...props} id={option.value}>
              {!_.isNil(option?.image) ? (
                <ViewImage
                  value={option?.image}
                  isShowDescription={false}
                  isPreview={true}
                  isShowTextError={false}
                  isStopPropagation={true}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <AccountCircleRoundedIcon
                  sx={{ width: 24, height: 24, mr: 1 }}
                />
              )}
              <Typography variant="body1" id={option.value}>
                {option.label}
              </Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="กรุณาเลือก"
              inputProps={{
                ...params.inputProps,
                'data-testid': 'select-staff',
              }}
            />
          )}
          onChange={(e) => (value = e.target.id)}
        />
      </Box>
    ),
    onConfirmClick: () => {
      if (value === '') {
        return
      }
      handleAddAssigneeClick(value, formik, staff)
      setDialog({ open: false })
    },
    onCancelClick: () => {
      setDialog({ open: false })
    },
  })
}
