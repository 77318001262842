import axios from 'axios'
import _ from 'lodash'
import { agentProfileHistory } from '../../../utils/apiPath'

import { setInitialTable } from '../../../redux/slices/table'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px',
}

export const headCells = [
  {
    id: 'author',
    disablePadding: false,
    label: 'ผู้แก้ไข',
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchHistory = (agentUuid, page, limit) => async (dispatch) => {
  const body = {
    agentUuid: agentUuid,
    limit: limit,
    page: page,
  }

  await axios
    .post(agentProfileHistory, body)
    .then(({ data }) => {
      dispatch(
        setInitialTable({
          isCheckBox: false,
          rows: _.get(data, 'result', []),
          allCount: _.get(data, 'totalCount', 0),
          headCells: headCells,
          customStyle: StyledTable,
        })
      )
    })
    .catch((err) => console.log(err))
}
