import _ from "lodash"
import { store } from "../../../../../App"
import { setFieldValue } from "../../../../../redux/slices/crud"

export const handleInputNumberChange = (e, key) => {
  const { formData } = store.getState().crud
  const value = e.target.value
  const quantity = _.get(formData, 'quantity', '')
  const unitPrice = _.get(formData, 'unitPrice', '')
  const stockValue = _.get(formData, 'stockValue', '')
  if(key === 'quantity'){
    // oncahnge จำนวน  set มูลค่าต่อหน่วย และ มูลค่ารวม
    let total = null
    if(value && unitPrice){
      total = value * unitPrice
    }else if(unitPrice){
      total = unitPrice
    }else{
      total = stockValue
    }
    setValue('stockValue', total)
  }else if(key === 'unitPrice'){
    // oncahnge มูลค่าต่อหน่วย set มูลค่ารวม
    let total = null
    if(value && quantity){
      total = value * quantity
    }else if(value && !quantity){
      total = value
       // setValue('quantity', 1)
    }else{
      total = stockValue
    }
    setValue('stockValue', total)
  }else if(key === 'stockValue'){
    // oncahnge มูลค่ารวม set มูลค่าต่อหน่วย
    if(quantity && value){
      const unitPriceVal = value / quantity
      setValue('unitPrice', unitPriceVal)
    }
  }
}

export const setValue = (key, value) => {
  store.dispatch(setFieldValue({
    key: `formData.${key}`,
    value: value,
  }))
}