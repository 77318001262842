import { store } from '../../../../../App'
import { setFieldValue, setFieldError } from '../../../../../redux/slices/crud'
import { assetTypeEnum } from '../enum/assetTypEnum'
import { validateFormData } from './handleSubmitForm'
import { openStockDrawer } from './openStockDrawer'
import { checkSkuNo } from './checkSkuNo'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'
import _ from 'lodash'

export const handleSubmitDrawer = async (validationSchema) => {
  let { formData, assetData } = store.getState().crud
  const isValid = await validateFormData(formData, validationSchema)
  // const skuNo = _.get(formData, 'skuNo.skuNo', false) ?? _.get(formData, 'skuNo', '')
  let skuNo = _.get(formData, 'skuNo', '')
  if (formData?.skuNoOption) {
    skuNo = formData?.skuNoOption?.skuNo
  }
  if (isValid) {
    if (formData.type != assetTypeEnum.STOCK) {
      const isDuplicateSkuNo = await checkSkuNo()
      if (isDuplicateSkuNo) {
        store.dispatch(
          setFieldError([
            {
              value: '',
              path: 'skuNo',
              type: 'required',
              name: 'ValidationError',
              message: 'รหัสทรัพย์สิน ถูกใช้งานแล้ว',
            },
            {
              value: '',
              path: 'skuNoOption',
              type: 'required',
              name: 'ValidationError',
              message: 'รหัสทรัพย์สิน ถูกใช้งานแล้ว',
            },
          ]),
        )
        handleScrollToError('skuNo')
        return false
      }
    }
    const product =
      formData.type === assetTypeEnum.STOCK
        ? _.get(formData, 'product', '')
        : formData.type === assetTypeEnum.SKU
        ? _.get(formData, 'productStockAsset', '')
        : formData.type === assetTypeEnum.ASSET
        ? _.get(formData, 'productAsset', '')
        : formData.type === assetTypeEnum.LICENSE
        ? _.get(formData, 'productLicense', '')
        : ''

    if (_.isNil(formData.rowIndex)) {
      const rowIndex = _.size(assetData)
      store.dispatch(
        setFieldValue({
          key: 'assetData',
          value: [
            ...assetData,
            {
              rowIndex,
              product,
              quantity: _.get(formData, 'quantity', null),
              unitPrice: _.get(formData, 'unitPrice', null),
              stockValue: _.get(
                formData,
                'stockValue',
                _.get(formData, 'quantity', 0) *
                  _.get(formData, 'unitPrice', 0),
              ),
              skuNo: skuNo,
              costCenter: _.get(formData, 'costCenter', ''),
              assetNo: _.get(formData, 'assetNo', ''),
              contractStartDate: _.get(formData, 'contractStartDate', null),
              contractEndDate: _.get(formData, 'contractEndDate', null),
              renewContractDate: _.get(formData, 'renewContractDate', null),
              checkEndDate: _.get(formData, 'checkEndDate', false),
              holder: _.get(formData, 'holder', ''),
              ownerFName: _.get(formData, 'ownerFName', ''),
              ownerLName: _.get(formData, 'ownerLName', ''),
              ownerEmail: _.get(formData, 'ownerEmail', ''),
              payer: _.get(formData, 'payer', ''),
              payerFName: _.get(formData, 'payerFName', ''),
              payerLName: _.get(formData, 'payerLName', ''),
              payerEmail: _.get(formData, 'payerEmail', ''),
              paymentType: _.get(formData, 'paymentType', ''),
              paymentFile: _.get(formData, 'paymentFile', ''),
              paymentDesc: _.get(formData, 'paymentDesc', ''),
            },
          ],
        }),
      )
    } else {
      const indexToEdit = _.findIndex(assetData, (item) => {
        return item.rowIndex === formData.rowIndex
      })

      const newAssetData = [...assetData]
      newAssetData[indexToEdit] = {
        rowIndex: formData.rowIndex,
        uuid: _.get(formData, 'uuid', undefined),
        product,
        quantity: _.get(formData, 'quantity', null),
        unitPrice: _.get(formData, 'unitPrice', null),
        stockValue: _.get(
          formData,
          'stockValue',
          _.get(formData, 'quantity', 0) * _.get(formData, 'unitPrice', 0),
        ),
        skuNo: skuNo,
        costCenter: _.get(formData, 'costCenter', ''),
        assetNo: _.get(formData, 'assetNo', ''),
        contractStartDate: _.get(formData, 'contractStartDate', null),
        contractEndDate: _.get(formData, 'contractEndDate', null),
        renewContractDate: _.get(formData, 'renewContractDate', null),
        checkEndDate: _.get(formData, 'checkEndDate', false),
        holder: _.get(formData, 'holder', ''),
        ownerFName: _.get(formData, 'ownerFName', ''),
        ownerLName: _.get(formData, 'ownerLName', ''),
        ownerEmail: _.get(formData, 'ownerEmail', ''),
        payer: _.get(formData, 'payer', ''),
        payerLName: _.get(formData, 'payerLName', ''),
        payerEmail: _.get(formData, 'payerEmail', ''),
        paymentType: _.get(formData, 'paymentType', ''),
        paymentFile: _.get(formData, 'paymentFile', ''),
        paymentDesc: _.get(formData, 'paymentDesc', ''),
        skuNoOption: _.get(formData, 'skuNoOption', ''),
      }
      store.dispatch(
        setFieldValue({
          key: 'assetData',
          value: newAssetData,
        }),
      )
    }
    openStockDrawer(false, true)
  }
}
