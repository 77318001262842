import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import {
  filtersDrawerEnum,
  overviewEnum,
  evaluationDrawerEnum,
} from '../enums/drawerEnum'
import { fetchOverview } from './fetchOverview'

export const initialize = async (
  uuid,
  isManageClass,
  startDate,
  finishDate,
  trainingPlanUuid,
) => {
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.uuid',
      value: uuid,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.isManageClass',
      value: isManageClass,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.filtersDrawer',
      value: {
        ...filtersDrawerEnum,
        trainingDateStartDate: isManageClass ? startDate : '',
        trainingDateFinishDate: isManageClass ? finishDate : '',
        trainingPlanUuid: isManageClass ? trainingPlanUuid : '',
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.overview',
      value: overviewEnum,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'eEvaluationDashboard.evaluationDrawer',
      value: evaluationDrawerEnum,
    }),
  )
  await fetchOverview(
    uuid,
    isManageClass,
    startDate,
    finishDate,
    trainingPlanUuid,
  )
}
