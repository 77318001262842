import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import {
  ContentRow,
  StatusContent,
} from '../../../../../../modules/ELearning/components/View'
import PreviewVideoContent from '../../../../../../modules/ELearning/components/PreviewVideoContent'
import { StyledCardContent } from '../../../../../../modules/ELearning/components/View/Styled'
import { Box } from '@mui/material'

export const Detail = () => {
  const { formData } = useSelector((state) => state.crud)
  return (
    <StyledCardContent>
      <Typography variant="h6" color="primary">
        รายละเอียด
      </Typography>
      <ContentRow
        title="รหัส Collection"
        content={_.get(formData, 'id', '-')}
      />
      <ContentRow
        title="ชื่อ Collection"
        content={_.get(formData, 'name', '-')}
      />
      <ContentRow
        title="ชื่อบน Banner"
        content={_.get(formData, 'nameForBanner', '-') || '-'}
      />
      <ContentRow
        title="รายละเอียดหลักสูตร"
        content={_.get(formData, 'description', '-') || '-'}
      />
      {!_.isEmpty(_.get(formData, 'coverImage', '')) && (
        <ContentRow title="รูปภาพหน้าปก">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" color="primary">
              {_.get(formData, 'fileName', '')}
            </Typography>

            <img
              src={`${window.__env__.REACT_APP_API_URL}/file/get${_.get(
                formData,
                'coverImage',
                '',
              )}`}
              width={125}
              height={82}
              style={{ objectFit: 'cover' }}
            />
          </Box>
        </ContentRow>
      )}

      <PreviewVideoContent url={_.get(formData, 'linkExampleVideo', '')} />

      <ContentRow
        title="Remark"
        content={_.get(formData, 'remark', '-') || '-'}
      />

      <StatusContent status={_.get(formData, 'status', false)} />
    </StyledCardContent>
  )
}
