import { Box, IconButton, Typography } from '@mui/material'
import { ModeEdit, DeleteTwoTone } from '@mui/icons-material'
import { handleEditExpense } from '../../../handler/handleEditExpense'
import { handleDeleteClick } from '../../../handler/handleDeleteClick'
import _ from 'lodash'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { handleShowDetails } from '../../../../../modules/Expense/handler/handleShowDetails'

export const Header = ({ idx, costCenter, showDetails }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="body1b">
        รายการที่ {idx + 1} ({_.get(costCenter, 'costCenter')})
      </Typography>
      <Box display="flex">
        <IconButton onClick={() => handleEditExpense(idx)}>
          <ModeEdit />
        </IconButton>
        <IconButton onClick={() => handleDeleteClick(idx)}>
          <DeleteTwoTone />
        </IconButton>
        <IconButton onClick={() => handleShowDetails(idx)}>
          {showDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
    </Box>
  )
}
