import { breadcrumbReportAgentProfileForTo } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_AGENT_PROFILE_TO',
  modulePath: '/manage-profile',
  breadcrumb: breadcrumbReportAgentProfileForTo(),
  downloadName: `Report Agent Profile for TO ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
