import React, { useEffect, useMemo } from 'react'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import DateRangeInput from '../../../../../../components/DateRangeInput'
import TimePicker from '../../../../../..//components/TimePicker/TimePicker'
import DatePicker from '../../../../../..//components/Input/DatePicker'
import { RESPONSIBLE_TYPE } from '../../../../../../redux/slices/eLearning/courseForm/model'
import { getEConfiguration } from '../../../../../../services/eConfiguration'
import { getStateValue } from '../../events'
import {
  StyledDrawer,
  StyledFooterDrawer,
  StyledWrapper,
  StyledColumnNoGap,
  StyledRespRow,
  BoxLabel,
} from './Styled'
import StatusSwitch from './StatusSwitch'
import { schema } from './schema'
import {
  fetchBatchList,
  getStartAfter,
  handleCloseDrawer,
  handleGetAssignees,
  handleInitialData,
  handleSubmit,
} from './events'
import { onGoingStatus, registerStatus } from './model'

const AddDrawer = () => {
  const dispatch = useDispatch()
  const {
    isDrawerOpen,
    batchDisableDate,
    id,
    uuid,
    assignees,
    batchData,
    trainerQuestionOption,
    startAfter,
  } = useSelector(
    (state) => ({
      isLoading: state.eLearningCourseForm.isLoading,
      isDrawerOpen: state.eLearningCourseForm.isDrawerOpen,
      batchDisableDate: state.eLearningCourseForm.batchDisableDate,
      id: getStateValue('id'),
      uuid: getStateValue('uuid'),
      assignees: handleGetAssignees(),
      batchData: state.eLearningCourseForm.batchData,
      trainerQuestionOption: state.eLearningCourseForm.trainerQuestionList,
      startAfter: getStartAfter(),
    }),
    shallowEqual,
  )

  const isEdit = !_.isEmpty(batchData)
  const initAssignees = useMemo(
    () => handleInitialData(batchData, id, uuid),
    [batchData, id, uuid],
  )

  useEffect(() => {
    dispatch(getEConfiguration())
  }, [])

  useEffect(() => {
    dispatch(fetchBatchList(uuid, isDrawerOpen))
  }, [isDrawerOpen])

  return (
    <StyledDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => dispatch(handleCloseDrawer())}
    >
      <Formik
        initialValues={initAssignees}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => dispatch(handleSubmit(values))}
      >
        {({ values, errors, setValues, setFieldValue, resetForm }) => {
          const speaker = _.get(values, 'speaker', [])
          const trainerQuestion = _.get(values, 'trainerQuestion', [])
          const admin = _.get(values, 'admin', [])
          const openRegisterDate = _.get(values, 'openRegisterDate', '')
          const openRegisterTime = _.get(values, 'openRegisterTime', '')
          const endRegisterDate = _.get(values, 'endRegisterDate', '')
          const endRegisterTime = _.get(values, 'endRegisterTime', '')
          const periodStart = _.get(values, 'periodStart', '')
          let minDate = new Date()
          if (!_.isEmpty(openRegisterDate)) {
            minDate = dayjs(openRegisterDate).add(startAfter + 1, 'day')
          }
          if (!_.isEmpty(endRegisterDate)) {
            minDate = dayjs(endRegisterDate).add(startAfter + 1, 'day')
          }
          const maxDate = _.isEmpty(periodStart)
            ? undefined
            : dayjs(periodStart).subtract(startAfter + 1, 'day')

          const speakerOption = assignees.filter(
            (item) => item.type === RESPONSIBLE_TYPE.SPEAKER,
          )
          const adminOption = assignees.filter(
            (item) => item.type === RESPONSIBLE_TYPE.ADMIN,
          )
          const onGoing = onGoingStatus.includes(values.status)
          const disabledStartRegistration =
            registerStatus.includes(values.status) || onGoing
          return (
            <Form>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">
                  {isEdit ? 'แก้ไขรอบอบรม' : 'เพิ่มรอบอบรม'}
                </Typography>
              </Box>

              <StyledWrapper>
                <Box>
                  <StyledColumnNoGap>
                    <StyledRespRow isInput>
                      <BoxLabel>
                        <DatePicker
                          id="openRegisterDate"
                          name="openRegisterDate"
                          labelText="วันเปิดรับสมัคร"
                          placeholder="เลือกวันที่"
                          required
                          disablePast
                          maxDate={maxDate}
                          value={openRegisterDate}
                          textError={_.get(errors, 'openRegisterDate', '')}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              openRegisterDate: dayjs(value).format(
                                window.__env__.REACT_APP_DATE_DB,
                              ),
                            })
                          }}
                          disabled={disabledStartRegistration}
                          disabledInput={disabledStartRegistration}
                        />
                      </BoxLabel>
                      <BoxLabel>
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                          เวลาเปิด
                          <Typography
                            sx={{ lineHeight: 1, ml: 0.5 }}
                            color={'error'}
                          >
                            *
                          </Typography>
                        </Typography>
                        <TimePicker
                          id="openRegisterTime"
                          name="openRegisterTime"
                          value={openRegisterTime}
                          disabled={disabledStartRegistration}
                          disabledStartTime={openRegisterTime}
                          disabledEndTime={''}
                          helperText={_.get(errors, 'openRegisterTime', '')}
                          onChange={(value) =>
                            setValues({
                              ...values,
                              openRegisterTime: value.target.value,
                            })
                          }
                        />
                      </BoxLabel>
                    </StyledRespRow>

                    <StyledRespRow isInput>
                      <BoxLabel>
                        <DatePicker
                          id="endRegisterDate"
                          name="endRegisterDate"
                          labelText="วันปิดรับสมัคร"
                          placeholder="เลือกวันที่"
                          required
                          disablePast
                          maxDate={maxDate}
                          disabled={_.isEmpty(openRegisterDate) || onGoing}
                          disabledInput={_.isEmpty(openRegisterDate) || onGoing}
                          value={endRegisterDate}
                          textError={_.get(errors, 'endRegisterDate', '')}
                          onChange={(value) => {
                            setValues({
                              ...values,
                              endRegisterDate: dayjs(value).format(
                                window.__env__.REACT_APP_DATE_DB,
                              ),
                            })
                          }}
                        />
                      </BoxLabel>
                      <BoxLabel>
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                          เวลาปิด
                          <Typography
                            sx={{ lineHeight: 1, ml: 0.5 }}
                            color={'error'}
                          >
                            *
                          </Typography>
                        </Typography>
                        <TimePicker
                          id="endRegisterTime"
                          name="endRegisterTime"
                          value={
                            endRegisterTime === null ? '' : endRegisterTime
                          }
                          disabledStartTime={''}
                          disabled={onGoing}
                          disabledEndTime={''}
                          helperText={_.get(errors, 'endRegisterTime', '')}
                          onChange={(value) =>
                            setValues({
                              ...values,
                              endRegisterTime: value.target.value,
                            })
                          }
                        />
                      </BoxLabel>
                    </StyledRespRow>
                  </StyledColumnNoGap>
                </Box>

                <DateRangeInput
                  required
                  minDate={new Date(minDate)}
                  labelText="ช่วงเวลารอบอบรม"
                  placeholder="เลือกช่วงเวลาอบรม"
                  textError={_.get(errors, 'display', '')}
                  isShowTextError={_.get(errors, 'display', '')}
                  disableDates={batchDisableDate}
                  dateState={{
                    startDate: values.periodStart,
                    endDate: values.periodEnd,
                    display: values.display,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) =>
                    setValues({
                      ...values,
                      periodStart: dayjs(selectedDates.startDate).format(
                        window.__env__.REACT_APP_DATE_DB,
                      ),
                      periodEnd: dayjs(selectedDates.endDate).format(
                        window.__env__.REACT_APP_DATE_DB,
                      ),
                      display: selectedDates.display,
                    })
                  }
                  disabled={onGoing}
                />

                <SelectGroupChip
                  required
                  id="speaker"
                  name="speaker"
                  labelText="วิทยากรบรรยาย"
                  placeholder="เลือกวิทยากรบรรยาย"
                  options={speakerOption}
                  textError={_.get(errors, 'speaker', '')}
                  isShowTextError={_.get(errors, 'speaker', '')}
                  value={speaker}
                  onChange={(list) => setFieldValue('speaker', list)}
                />
                <SelectGroupChip
                  required
                  id="trainerQuestion"
                  name="trainerQuestion"
                  labelText="วิทยากรตอบคำถาม"
                  placeholder="เลือกวิทยากรตอบคำถาม"
                  options={trainerQuestionOption}
                  textError={_.get(errors, 'trainerQuestion', '')}
                  isShowTextError={_.get(errors, 'trainerQuestion', '')}
                  value={trainerQuestion}
                  onChange={(list) => setFieldValue('trainerQuestion', list)}
                />
                <SelectGroupChip
                  required
                  id="admin"
                  name="admin"
                  labelText="Admin"
                  placeholder="เลือก Admin"
                  options={adminOption}
                  textError={_.get(errors, 'admin', '')}
                  isShowTextError={_.get(errors, 'admin', '')}
                  value={admin}
                  onChange={(list) => setFieldValue('admin', list)}
                />

                <Divider />
                <StatusSwitch />
              </StyledWrapper>

              <StyledFooterDrawer>
                <Button
                  variant="outlined"
                  size="m"
                  onClick={() => {
                    resetForm()
                    dispatch(handleCloseDrawer())
                  }}
                >
                  ยกเลิก
                </Button>
                <Button variant="contained" size="m" type="submit">
                  บันทึก
                </Button>
              </StyledFooterDrawer>
            </Form>
          )
        }}
      </Formik>
    </StyledDrawer>
  )
}

export default AddDrawer
