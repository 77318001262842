import _ from 'lodash'
import {
  apiToRcmsDetailDownload,
  apiToRcmsDownload,
  apiToRcmsFilter,
} from '../../../utils/apiPath'
import { headCells } from '../../../constants/apiToRcms'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'

export const onDownload = (selected) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    list: selected,
  }
  await dispatch(
    downloadFile({
      url: apiToRcmsDownload,
      body: body,
      fileName: `Agent License.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const onDownloadDetail = () => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    rcmsUuid: '',
    list: [],
    licenseStatus: [],
    rcmsStatus: [],
  }
  await dispatch(
    downloadFile({
      url: apiToRcmsDetailDownload,
      body: body,
      fileName: `ApiToRcms.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const search = isInitial ? '' : keyword
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      search: search,
      courseCode: _.get(filter, 'courseCode', ''),
      englishName: _.get(filter, 'englishName', ''),
      nameForLearner: _.get(filter, 'nameForLearner', ''),
      description: _.get(filter, 'description', ''),
      flag: _.get(filter, 'flag', []),
      licenseRcmsStatus: _.get(filter, 'licenseRcmsStatus', []),
      rcmsStatus: _.get(filter, 'rcmsStatus', []),
      startDate: _.get(filter, 'startDate', ''),
      finishDate: _.get(filter, 'finishDate', ''),
      createdBy: _.get(filter, 'createdBy', ''),
      createdAt: _.get(filter, 'createdAt', ''),
    }

    const isCancel = await callAxios
      .post(apiToRcmsFilter, body)
      .then(({ data }) => {
        dispatch(
          setInitialTable({
            rows: data.result,
            allCount: data.totalCount,
            headCells: headCells,
            placeholder: 'ค้นหา',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
            onDownload: (selected) => dispatch(onDownload(selected)),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const FlagStatus = []
  if (filter.flagChecked) {
    if (filter.flag.AutoChecked) {
      FlagStatus.push('Auto')
    }
    if (filter.flag.ManualChecked) {
      FlagStatus.push('Manual')
    }
    if (filter.flag.AdminChecked) {
      FlagStatus.push('Admin')
    }
  } else {
    FlagStatus.push('Auto', 'Manual', 'Admin')
  }
  const LicenseStatus = []
  if (filter.licenseStatusChecked) {
    if (filter.licenseStatus.WAITING) {
      LicenseStatus.push('รอตรวจสอบ')
    }
    if (filter.licenseStatus.STATUS_SUCCESS) {
      LicenseStatus.push('ปรับสถานะครบแล้ว')
    }
  } else {
    LicenseStatus.push('รอตรวจสอบ', 'ปรับสถานะครบแล้ว')
  }
  const RcmsStatus = []
  if (filter.rcmsStatusChecked) {
    if (filter.rcmsStatus.NO_STATUS) {
      RcmsStatus.push('-')
    }
    if (filter.rcmsStatus.SUCCESS) {
      RcmsStatus.push('สำเร็จ')
    }
    if (filter.rcmsStatus.NOT_SUCCESS) {
      RcmsStatus.push('ไม่สำเร็จ')
    }
    if (filter.rcmsStatus.NOT_SEND) {
      RcmsStatus.push('ไม่ต้องส่ง')
    }
    if (filter.rcmsStatus.WAIT_SEND) {
      RcmsStatus.push('รอส่ง')
    }
  } else {
    RcmsStatus.push('-', 'สำเร็จ', 'ไม่สำเร็จ', 'ไม่ต้องส่ง')
  }

  const filterText = {
    courseCode: filter.courseCodeChecked ? filter.courseCode : '',
    englishName: filter.courseEnglishNameChecked
      ? filter.courseEnglishName
      : '',
    nameForLearner: filter.nameForLearnerChecked ? filter.nameForLearner : '',
    description: filter.licenseDescriptionChecked
      ? filter.licenseDescription
      : '',
    licenseRcmsStatus: filter.licenseStatusChecked ? LicenseStatus : [],
    rcmsStatus: filter.rcmsStatusChecked ? RcmsStatus : [],
    flag: filter.flagChecked ? FlagStatus : [],
    startDate: filter.startDateChecked ? filter.startDate : '',
    finishDate: filter.startDateChecked ? filter.toDate : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    createdAt: filter.createdAtChecked ? filter.createdAt : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(fetchDataList('filter', table, 1, filterText, ''))
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, '', text))
}
