import { styleStatus } from './masterData'
import _ from 'lodash'

export const getStyleStatus = (work) => {
  const { data } = work
  const defaultStyle = {
    concatStatus: '',
    text: '',
    style: {},
  }
  if (_.isNil(data?.status) || _.isNil(data?.statusApprove)) return defaultStyle

  const isApproveStatus =
    _.get(data, 'statusBorrow', '') === 'WAITING_APPROVE_BORROW'
  const getApproveStatus = isApproveStatus
    ? 'WAITING_APPROVE_BORROW'
    : data?.statusApprove
  const getStatus = isApproveStatus ? '' : data?.status
  const concatStatus = `${getStatus}_${getApproveStatus}`

  const find = styleStatus.find((item) =>
    concatStatus.includes(item.concatStatus)
  )
  return find || defaultStyle
}
