import dayjs from 'dayjs'

export const checkAndConvertDate = (dateString) => {
  const dayjsDate = dayjs(dateString, 'DD/MM/YYYY', true)
  if (dayjsDate.isValid()) {
    return dayjsDate.format('YYYY-MM-DD')
  }
  const isoFormatDate = dayjs(dateString, 'YYYY-MM-DD', true)
  if (isoFormatDate.isValid()) {
    return dateString
  }
  return null
}

export const checkAndConvertDateShow = (dateString) => {
  const dayjsDate = dayjs(dateString, 'YYYY-MM-DD', true)
  if (dayjsDate.isValid()) {
    return dayjsDate.format('DD/MM/YYYY')
  }
  const isoFormatDate = dayjs(dateString, 'DD/MM/YYYY', true)
  if (isoFormatDate.isValid()) {
    return dateString
  }
  return null
}