import dayjs from 'dayjs'

export const overviewEnum = {
  latestUpdated: dayjs().startOf('month').format('YYYY-MM-DD HH:mm'),
  courseEva: {
    attendeeCount: 0,
    respondentCount: 0,
    overallPercentagePart: 0.0,
  },
  trainerEva: [],
  checkTrainerEvaQuestion: true,
  courseManageEva: {
    attendeeCount: 0,
    respondentCount: 0,
    overallPercentagePart: 0.0,
  },
  part4Eva: [],
}

export const evaluationDrawerEnum = {
  isOpen: false,
}

export const filtersDrawerEnum = {
  isOpen: false,
  dateFilterStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  dateFilterFinishDate: dayjs().format('YYYY-MM-DD'),
  courseName: '',
  trainingDateStartDate: '',
  trainingDateFinishDate: '',
  userResponseDateStart: '',
  userResponseDateEnd: '',
  trainerName: '',
  distribution: '',
  trainingPlanUuid: '',
  courseCode: '',
  department: '',
  station: '',
  filterTotal: 0,
}
