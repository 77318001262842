import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'
export const FilterButton = styled((props) => (
  <Button
    data-testid={'btn-filter-chart'}
    variant="text"
    startIcon={<FilterListOutlined sx={{ width: 25, height: 25 }} />}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  },
}))

export const BoxCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: theme.spacing(1),
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'unset',
  },
}))

export const BoxFilter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const BoxLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}))

export const BoxDateLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}))
