import { object, string } from 'yup'

export const validationColumn = (column) => {
  let validateSchema = object().shape({
    name: string()
      .required('กรุณากรอกชื่อ')
      .max(100, 'ระบุได้ไม่เกิน 100 ตัวอักษร')
      .nullable(),
  })

  return validateSchema.validate(column, { abortEarly: false }).catch((e) => e)
}
