import localforage from 'localforage'
import { downloadTemplate } from '../../../../services/eTesting/form'

export const onClickAddTest = (option, history) => {
  localforage.removeItem('persist:root')
  history.push(option.link)
}

export const onDownloadTemplate = () => async (dispatch) => {
  await dispatch(downloadTemplate())
}
