import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'

export const getBodyMutation = (data) => {
  const productType = _.get(data, 'productType', '')
  const isSku = productType === PRODUCT_TYPE.STOCK_ASSET
  return {
    id: _.get(data, 'id', ''),
    productType: _.get(data, 'productType', 'Stock'),
    productName: _.get(data, 'productName', ''),
    productImage: _.get(data, 'productImage', ''),
    category: _.get(data, 'category', ''),
    subCategory: _.get(data, 'subCategory', ''),
    quantityAlert: _.get(data, 'quantityAlert', ''),
    description: data?.description ?? '',
    status: _.get(data, 'status', enumStatus.ACTIVE),
    isSku: isSku ? enumStatus.ACTIVE : enumStatus.INACTIVE,
    costCenter: _.get(data, 'costCenter', ''),
    statusFlagReturn: _.get(data, 'statusFlagReturn', enumStatus.ACTIVE),
    hasPeriodFlagReturn: _.get(data, 'hasPeriodFlagReturn', false),
    refundYear: _.get(data, 'refundYear', null),
    refundMonth: _.get(data, 'refundMonth', null),
    refundDay: _.get(data, 'refundDay', null),
    remark: data?.remark ?? '',
    uuid: _.get(data, 'uuid', undefined),
    accountCode: _.get(data, 'accountCode', ''),
  }
}
