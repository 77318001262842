import _ from 'lodash'
import { stopVideoLoading } from '../../../../../../redux/slices/eLearning/moduleForm'
import { handleChange } from '../../events'
import { EMBED_TYPE } from './model'

export const handleInitialSet = (moduleUuid, keyType) => (dispatch) => {
  dispatch(stopVideoLoading())
  if (_.isEmpty(moduleUuid)) {
    dispatch(handleChange(keyType, EMBED_TYPE.LINK))
  }
}

export const handleHtmlLink = (type, value) => {
  if (type === EMBED_TYPE.HTML) {
    const strArr = value?.split(' ') || []
    const src = strArr.find((word) => word?.includes('src'))
    if (typeof src === 'string') {
      return src?.replace('src="', '').replace('"', '')
    } else return ''
  }
}

export const validateVideo = (url, isHtml) => {
  if (!url) return false

  if (isHtml) {
    const isVideoFormatValid = videoFormat.some((format) =>
      url.includes(format),
    )
    return url.includes('iframe') && isVideoFormatValid
  } else {
    const value = url.slice(8).split('/')[0]
    const isVideoFormatValid = videoFormat.some((format) => value === format)
    return isVideoFormatValid
  }
}

export const videoFormat = [
  'vimeo.com',
  'www.youtube.com',
  'youtu.be',
  'player.vimeo.com',
]

export const handleDisplayLink = (type, value) => {
  if (_.isEmpty(value)) return ''

  const isHtml = type === EMBED_TYPE.HTML
  const src = isHtml ? handleHtmlLink(type, value) : value
  return _.get(src.split('&'), 0, '')
}
