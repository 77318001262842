import React from 'react'
import useTheme from '@mui/system/useTheme'
import { Button, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { StyledColumn, StyledRow } from '../Styled'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import { convertValueToNumber } from './event'

const Index = () => {
  const theme = useTheme()
  const history = useHistory()
  const { setValues } = useFormikContext()

  const { information, createdBy, createdAt, updatedBy, updatedAt } =
    useSelector(
      (state) => ({
        information: state.masterDataMeetingRoomForm.information,
        createdBy: state.masterDataMeetingRoomForm.information.createdBy,
        createdAt: state.masterDataMeetingRoomForm.information.createdAt,
        updatedBy: state.masterDataMeetingRoomForm.information.updatedBy,
        updatedAt: state.masterDataMeetingRoomForm.information.updatedAt,
      }),
      shallowEqual,
    )
  const isNotSm = isMediaWidthMoreThan('sm')
  const createdLabel =
    _.isEmpty(createdBy) && _.isEmpty(createdAt)
      ? '-'
      : `${createdBy.trim()} ${createdAt}`
  const updatedLabel =
    _.isEmpty(updatedBy) && _.isEmpty(updatedAt)
      ? '-'
      : `${updatedBy.trim()} ${updatedAt}`

  return (
    <StyledRow
      sx={{
        width: '100%',
        mt: theme.spacing(3),
        justifyContent: 'space-between',
        flexDirection: isNotSm ? 'row' : 'column',
      }}
    >
      <StyledRow sx={{ gap: theme.spacing(3) }}>
        <Button
          variant="contained"
          size="m"
          type="submit"
          onClick={() => {
            convertValueToNumber(information, setValues)
          }}
        >
          บันทึก
        </Button>
        <Button variant="outlined" size="m" onClick={() => history.goBack()}>
          ยกเลิก
        </Button>
      </StyledRow>

      <StyledColumn
        sx={{
          minWidth: '40%',
          alignItems: isNotSm ? 'flex-end' : 'flex-start',
        }}
      >
        <Typography variant="body3" color="text.lightGray">
          สร้างโดย {createdLabel}
        </Typography>
        <Typography variant="body3" color="text.lightGray">
          แก้ไขล่าสุดโดย {updatedLabel}
        </Typography>
      </StyledColumn>
    </StyledRow>
  )
}

export default Index
