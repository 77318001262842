import appHistory from '../../../../appHistory'
import { crudConfig } from '../../crudConfig'
import { Edit, HistoryTwoTone } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { setFieldValue } from '../../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

export function ActionComponent({ uuid }) {
  const dispatch = useDispatch()
  const { formData } = useSelector(
    (state) => ({ formData: state.crud.formData }),
    shallowEqual,
  )

  return (
    <Box display="flex" gap={3}>
      <Button
        startIcon={<HistoryTwoTone />}
        variant="outlined"
        onClick={() =>
          dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: true }))
        }
      >
        ประวัติการแก้ไข
      </Button>
      {formData.status !== 'DELETED' && (
        <Button
          startIcon={<Edit />}
          variant="contained"
          onClick={() =>
            appHistory.push(`${crudConfig.modulePath}/form/${uuid}`)
          }
          data-testid="btn-edit"
        >
          แก้ไข
        </Button>
      )}
    </Box>
  )
}
