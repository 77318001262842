import { Divider, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import React, { useEffect } from 'react'
import { StyledColumn, StyledRow } from '../Styled'
import _ from 'lodash'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { getMeetingRoomById } from '../../../../../../services/manageClass/form'
import { setEditRoomDetail } from '../../../../../../redux/slices/manageClassForm'

export default function Equipment({ roomEquipmentDetail, roomDetailIndex }) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { meetingRoomByDate } = useSelector(
    (state) => ({
      meetingRoomByDate: state.manageClassForm.data.meetingRoomByDate,
    }),
    shallowEqual,
  )

  useEffect(async () => {
    await fetchRoomEquipment()
  }, [_.get(meetingRoomByDate, `[${roomDetailIndex}].meetingRoom.uuid`)])

  const fetchRoomEquipment = async () => {
    const meetingRoomForEquipment = _.get(
      meetingRoomByDate,
      `[${roomDetailIndex}].meetingRoom.uuid`,
    )

    if (meetingRoomForEquipment && meetingRoomForEquipment !== 'OTHERS') {
      const respone = await dispatch(
        getMeetingRoomById(meetingRoomForEquipment),
      )
      const value = _.get(respone, 'payload.data')
      dispatch(
        setEditRoomDetail({
          keyField: 'roomEquipments',
          index: roomDetailIndex,
          value,
        }),
      )
    }
  }

  if (_.get(roomEquipmentDetail, 'meetingRoomEquipment', []).length === 0) {
    return null
  }

  return (
    <StyledColumn sx={{ gap: 1, pl: 10 }}>
      <Typography variant="body2b">อุปกรณ์ภายในห้อง</Typography>
      <StyledRow sx={{ gap: 2 }}>
        <Typography variant="body2b" color="primary">
          {_.get(roomEquipmentDetail, 'roomName')}
        </Typography>
        <StyledRow
          sx={{
            borderRadius: 1,
            px: 2,
            py: 1,
            gap: 2,
            border: `1px solid ${theme.palette?.blue?.blueLine}`,
          }}
        >
          {_.map(
            _.get(roomEquipmentDetail, 'meetingRoomEquipment', []),
            (item, idx) => {
              return (
                <StyledRow sx={{ gap: 2 }} key={idx}>
                  <StyledRow>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      {_.get(item, 'equipment.equipmentName', '')}
                    </Typography>
                    <Typography variant="body1b">
                      {_.toInteger(_.get(item, 'quantity', ''))}
                    </Typography>
                  </StyledRow>
                  {idx !==
                    _.get(roomEquipmentDetail, 'meetingRoomEquipment', [])
                      .length -
                      1 && (
                    <Divider
                      sx={{
                        height: 'auto',
                        borderColor: theme.palette?.blue?.blueLine,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </StyledRow>
              )
            },
          )}
        </StyledRow>
      </StyledRow>
    </StyledColumn>
  )
}
