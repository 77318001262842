import { store } from '../../../../../App'
import _ from 'lodash'
import { TICKET_TYPE } from '../../../../../constants/stock'
import { requisitionType } from '../model/requisitionType'
import { tableTypeList } from '../model/tableTypeList'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { tabActive, tabTypeActive } = store.getState().crud

  return {
    name: _.get(filterProp, 'name', ''),
    search: _.get(filterProp, 'search', ''),
    status: _.get(filterProp, 'status', []),
    transferType: _.get(filterProp, 'transferType', [TICKET_TYPE.REQUISITION]),
    transferBy: [tableTypeList[tabActive].value],
    requisitionType: [requisitionType[tabTypeActive].value],
    station: _.get(filterProp, 'station', []),
    startCreatedDate: filterProp?.createStartDate || '',
    endCreatedDate: filterProp?.createFinishDate || '',
    startUpdatedDate: filterProp?.updateStartDate || '',
    endUpdatedDate: filterProp?.updateFinishDate || '',
    product: _.get(filterProp, 'product', ''),
    qtyFrom: _.get(filterProp, 'qtyFrom', ''),
    qtyTo: _.get(filterProp, 'qtyTo', ''),
    category: _.get(filterProp, 'category', ''),
    subCategory: _.get(filterProp, 'subCategory', ''),
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'createStartDate', ''),
  }
}
