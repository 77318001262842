import { store } from '../../../../../App'
import Typography from '@mui/material/Typography'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleLabelDisplay = () => {
  const { filter } =
    store.getState().crud.roomManagementDashboard.roomRequestDrawer

  let minRowsShowed =
    filter.rowsPerPage.value <= 0
      ? 1
      : filter.rowsPerPage.value * (filter.page - 1) + 1
  let maxRowsShowed = 0

  if (filter.rowsPerPage.value * filter.page > filter.totalCount) {
    maxRowsShowed = filter.totalCount
  } else {
    maxRowsShowed = filter.rowsPerPage.value * filter.page
  }

  if (maxRowsShowed === 0) {
    minRowsShowed = 0
  } else if (maxRowsShowed < 0) {
    maxRowsShowed = filter.totalCount
  }

  if (minRowsShowed > maxRowsShowed && filter.page > 1) minRowsShowed = 1 // fix bug - rows label

  return (
    <Typography variant="caption" color="text.secondary">
      {minRowsShowed}-{maxRowsShowed} of {filter.totalCount}
    </Typography>
  )
}

export const handleChangePage = (newPage) => {
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.roomRequestDrawer.filter.page',
      value: +newPage + 1,
    }),
  )
}

export const handleChangeRowsPerPage = (event) => {
  const value = parseInt(event.target.value, 10)
  const rowsPerPageChange = value > 0 ? value : -1
  const rowsPerPage = {
    label: '' + rowsPerPageChange > 0 ? rowsPerPageChange : 'All',
    value: rowsPerPageChange,
  }

  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.roomRequestDrawer.filter.rowsPerPage',
      value: rowsPerPage,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'roomManagementDashboard.roomRequestDrawer.filter.page',
      value: 1,
    }),
  )
}
