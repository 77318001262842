import _ from 'lodash'
import { store } from '../../../../../App'
import { fromTypeEnum } from '../enum/assetTypEnum'

export const checkSkuNo = async () => {
  let isDuplicate = false
  const { skuNoList, assetData, formData } = store.getState().crud
  let skuNo = _.get(formData, 'skuNo', '')
  if (formData?.skuNoOption) {
    skuNo = formData?.skuNoOption?.skuNo
  }
  const checkAssetData = _.defaultTo(assetData, [])
  for (const data of checkAssetData) {
    if (data?.skuNo === skuNo && data?.rowIndex !== formData?.rowIndex) {
      isDuplicate = true
      break
    }
  }
  //check when import product only
  if (formData?.from?.value === fromTypeEnum.IMPORT_PRODUCT)
    for (const ticket of _.defaultTo(skuNoList, [])) {
      if (formData?.uuid && ticket.uuid === formData?.uuid) continue
      if (ticket?.skuNo === skuNo) {
        isDuplicate = true
        break
      }
    }
  return isDuplicate
}

export const checkSkuNoForm = async () => {
  let isDuplicate = false
  const { skuNoList, assetData, formData } = store.getState().crud
  for (const ticket of _.defaultTo(skuNoList, [])) {
    if (formData?.uuid && ticket.uuid === formData?.uuid) continue
    const findAsset = _.defaultTo(assetData, []).find(
      (item) => item?.skuNo === ticket?.skuNo,
    )
    if (findAsset) {
      isDuplicate = true
      break
    }
  }

  return isDuplicate
}
