import { breadcrumbReportAgentProfileForTrainer } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_AGENT_PROFILE_TRAINER',
  modulePath: '/manage-profile',
  breadcrumb: breadcrumbReportAgentProfileForTrainer(),
  downloadName: `Report Agent Profile for Trainer ${dayjs().format(
    'DDMMYYYY',
  )}.xlsx`,
}
