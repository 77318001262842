import React from 'react'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Row } from '../../../../../../components/Layout/Styled'

export const StyledButton = styled((props) => (
  <Button {...props} variant="outlined" size="s" sx={{ m: 'auto' }} />
))(() => ({
  margin: 'auto',
}))

export const StyledEnrollContainer = styled(Row)(({ theme }) => ({
  justifyContent: 'center',
  gap: theme.spacing(4),
  img: { width: 100, height: 100, objectFit: 'cover' },
  '& .MuiBox-root': {
    width: 'auto',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))
