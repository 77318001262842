import { Box, styled } from '@mui/material'

export const FactBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '24px 16px 0 16px',
  gap: '24px',
}))

export const AnswerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '8px',
  padding: '8px 16px',
  gap: '16px',
  backgroundColor: '#F3F3FF',
}))

export const StyledUL = styled('ul')(() => ({
  margin: 0,
  paddingLeft: '30px',
}))
