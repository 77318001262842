import ExpandMore from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'

import React, { useState, useEffect, useMemo, useRef } from 'react'
import useTheme from '@mui/system/useTheme'
import { ButtonColorStyle, ButtonPopperStyle } from './Styled'
import { ColorBox } from 'mui-color'

import _ from 'lodash'

const useOutside = (ref, setClose) => {
  const handleClickOutside = (e) => {
    if (ref.current === null) return null
    if (!ref.current.contains(e.target)) {
      setClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })
}

const ButtonPopper = ({ colorValue, onChangeColor }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorColor, setAnchorColor] = useState(null)
  const [color, setColor] = useState(colorValue)

  const _refModal = useRef(null)
  const _refColor = useRef(null)

  const handleClick = (event) => {
    setAnchorColor(null)
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const handleColorClick = (event) => {
    setAnchorColor(anchorColor ? null : event.currentTarget)
  }

  const openColor = Boolean(anchorColor)
  const idColor = openColor ? 'color-popper' : undefined

  const palette = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: '#fff',
    pink: 'pink',
    darkblue: 'darkblue',
  }

  useEffect(() => {
    if (color !== colorValue) onChangeColor(color)
  }, [color])

  const debouncedColor = useMemo(
    () => _.debounce((e) => setColor(e), 100),
    [color]
  )

  const isRefColorCloseModal = () => {
    setAnchorColor(false)
  }

  const isRefPaperCloseModal = () => {
    if (!anchorColor) setAnchorEl(false)
  }

  useOutside(_refColor, isRefColorCloseModal)
  useOutside(_refModal, isRefPaperCloseModal)

  return (
    <>
      <ButtonPopperStyle theme={theme} onClick={handleClick}>
        <Typography
          sx={{ display: 'flex', userSelect: 'none' }}
          variant="subtitle2"
        >
          Aa
        </Typography>
        <ExpandMore />
      </ButtonPopperStyle>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{ zIndex: 9999 }}
          transition
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={4} sx={{ p: 1, width: 'auto' }} ref={_refModal}>
                <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                  <ButtonColorStyle
                    theme={theme}
                    color={color}
                    onClick={handleColorClick}
                  />
                  <Popper
                    id={idColor}
                    open={openColor}
                    anchorEl={anchorColor}
                    placement="bottom"
                    sx={{ zIndex: 9999 }}
                  >
                    <Paper elevation={4} sx={{ p: 1 }} ref={_refColor}>
                      <ColorBox
                        onChange={(e) => debouncedColor(e.css.backgroundColor)}
                        defaultValue={color}
                        hideTextfield
                        palette={palette}
                      />
                    </Paper>
                  </Popper>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  )
}

export default ButtonPopper
