import React from 'react'
import useLocalStorage from '@rehooks/local-storage'
import InfoPreview from '../../../../../../../components/InfoPreview'
import Truncate from '../../../../../../../components/redux/Table/Truncate'
import {
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
  IconButton,
  Table,
} from '@mui/material'
import { StyledDashboardCard } from '../Styled'
import { useSelector, shallowEqual } from 'react-redux'
import { headCellsModule } from '../model'
import {
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableRow,
} from '../../../../../../../components/redux/Table/Styled'
import { InboxTwoTone, RemoveRedEyeTwoTone } from '@mui/icons-material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { validatePermission } from '../../../../../../../utils/lib'

const ModuleList = () => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const hasAdminPermission = validatePermission({
    user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN'],
  })
  const history = useHistory()
  const { totalModule, resultModule } = useSelector(
    (state) => ({
      totalModule: state.eLearningDashboardByCourse.totalModule,
      resultModule: state.eLearningDashboardByCourse.resultModule,
    }),
    shallowEqual,
  )

  return (
    <StyledDashboardCard sx={{ width: '100%', height: 484 }}>
      <Typography variant="h6">เพิ่มเติม</Typography>
      <InfoPreview
        label="จำนวน Module ทั้งหมด"
        count={totalModule}
        unit="โมดูล"
        hideButton
      />
      <TableContainer
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: theme.spacing(2),
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ whiteSpace: 'nowrap' }}>
            <TableRow
              key="head"
              sx={{
                '& .MuiTableCell-root': {
                  borderColor: '#F5F5F5',
                },
              }}
            >
              {_.map(headCellsModule, (headCell, idx) => {
                return (
                  <TableCell
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      px: 2,
                    }}
                    key={idx}
                    align={'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    width={headCell.width}
                    minwidth={headCell.minWidth}
                  >
                    {headCell.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {resultModule.length > 0 ? (
              _.map(resultModule, (row, rowIndex) => {
                return (
                  <StyledTableRow key={rowIndex}>
                    {_.map(headCellsModule, (headCell) => {
                      return headCell.id !== 'action' ? (
                        <StyledTableCell
                          key={headCell?.id}
                          align={headCell?.align}
                          style={{
                            minWidth: headCell?.minWidth,
                          }}
                        >
                          <Truncate>
                            {'' + _.get(row, `[${headCell.id}]`, '-')}
                          </Truncate>
                        </StyledTableCell>
                      ) : (
                        <TableCell key={headCell?.id}>
                          {hasAdminPermission && (
                            <IconButton
                              data-testid={`btn-view-${rowIndex}`}
                              color="primary"
                              component="span"
                              onClick={() =>
                                history.push(`${headCell.viewPath}/${row.uuid}`)
                              }
                            >
                              <RemoveRedEyeTwoTone color="action" />
                            </IconButton>
                          )}
                        </TableCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })
            ) : (
              <TableRow>
                <StyledTableBodyCell colSpan={headCellsModule.length + 1}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledDashboardCard>
  )
}

export default ModuleList
