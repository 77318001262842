import _ from 'lodash'
import { postManageClassListAttendanceHistory } from '../../../../../../services/manageClass/learnerDetail'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table/basicTable'

export const headCellsHistory = [
  {
    id: 'createdBy',
    label: 'ผู้ดำเนินการ',
    disablePadding: false,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
    hideSortIcon: true,
    width: 200,
  },
  {
    id: 'remark',
    label: 'เหตุผล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
  },
]

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page

  const body = {
    limit: Number(table.limit),
    page: realPage,
    sort: table.sort === 'updatedAt' ? 'createdAt' : table.sort,
    order: table.order.toString().toUpperCase(),
  }

  dispatch(startLoading())
  const result = await dispatch(
    postManageClassListAttendanceHistory({
      uuid,
      body,
    })
  )
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: _.get(result, 'payload.data.result', []),
      allCount: _.get(result, 'payload.data.totalCount', 0),
      headCells: headCellsHistory,
    })
  )
  dispatch(stopLoading())
}
