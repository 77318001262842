import { store } from '../../../../App'
import {
  E_EXAM_TYPE,
  TEST_RESULT_STATUS,
} from '../../../../constants/eExamination'
import { overviewDataEnum } from '../enums/drawerEnum'
import _ from 'lodash'

export const examinerEnrollCompany = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สมัครสอบทั้งหมด',
      labelVariant: 'body2',
      background: '',
      dotColor: '',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_STATION.ENROLL,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.COMPANY,
      value: 'ENROLL',
      hidePercent: true,
    },
    {
      label: 'เข้าสอบ',
      labelVariant: '',
      background: '#E1EDFF',
      dotColor: '#005CE7',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_STATION.EXAMINEES,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.COMPANY,
      value: 'EXAMINEES',
    },
    {
      label: 'ขาดสอบ',
      labelVariant: '',
      background: '#FFE5D9',
      dotColor: '#FF650E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_STATION.ABSENCE,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.COMPANY,
      value: TEST_RESULT_STATUS.ABSENCE,
    },
  ]
}

export const examinerEnrollAssociation = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สมัครสอบทั้งหมด',
      labelVariant: 'body2',
      background: '',
      dotColor: '',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_ASSOCIATION.ENROLL,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: 'ENROLL',
      hidePercent: true,
    },
    {
      label: 'เข้าสอบ',
      labelVariant: '',
      background: '#E1EDFF',
      dotColor: '#005CE7',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_ASSOCIATION.EXAMINEES,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: 'EXAMINEES',
    },
    {
      label: 'ขาดสอบ',
      labelVariant: '',
      background: '#FFE5D9',
      dotColor: '#FF650E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_ASSOCIATION.ABSENCE,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: TEST_RESULT_STATUS.ABSENCE,
    },
  ]
}

export const examinerEnrollOtherModel = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สมัครสอบทั้งหมด',
      labelVariant: 'body2',
      background: '',
      dotColor: '',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_OTHER.ENROLL,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.OTHER,
      value: 'ENROLL',
      hidePercent: true,
    },
    {
      label: 'เข้าสอบ',
      labelVariant: '',
      background: '#E1EDFF',
      dotColor: '#005CE7',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_OTHER.EXAMINEES,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.OTHER,
      value: 'EXAMINEES',
    },
    {
      label: 'ขาดสอบ',
      labelVariant: '',
      background: '#FFE5D9',
      dotColor: '#FF650E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_OTHER.ABSENCE,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.OTHER,
      value: TEST_RESULT_STATUS.ABSENCE,
    },
  ]
}

export const examinerCompany = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สอบผ่าน',
      labelVariant: '',
      background: '#DDFDE1',
      dotColor: '#03C83E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_STATION.PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.COMPANY,
      value: TEST_RESULT_STATUS.PASS,
    },
    {
      label: 'สอบไม่ผ่าน',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_STATION.NOT_PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.COMPANY,
      value: TEST_RESULT_STATUS.NOT_PASS,
    },
  ]
}

export const examinerAssociation = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สอบผ่าน',
      labelVariant: '',
      background: '#DDFDE1',
      dotColor: '#03C83E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_ASSOCIATION.PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: TEST_RESULT_STATUS.PASS,
    },
    {
      label: 'สอบไม่ผ่าน',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_ASSOCIATION.NOT_PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.ASSOCIATION,
      value: TEST_RESULT_STATUS.NOT_PASS,
    },
  ]
}

export const examinerOtherModel = () => {
  const { eExamDashboard } = store.getState().crud
  return [
    {
      label: 'สอบผ่าน',
      labelVariant: '',
      background: '#DDFDE1',
      dotColor: '#03C83E',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_OTHER.PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.OTHER,
      value: TEST_RESULT_STATUS.PASS,
    },
    {
      label: 'สอบไม่ผ่าน',
      labelVariant: '',
      background: '#FFDDDD',
      dotColor: '#D64F65',
      number: _.get(
        eExamDashboard?.overviewDataCount,
        overviewDataEnum.EXAMINER_OTHER.NOT_PASS,
        0,
      ),
      unit: 'คน',
      type: E_EXAM_TYPE.OTHER,
      value: TEST_RESULT_STATUS.NOT_PASS,
    },
  ]
}
