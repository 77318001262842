import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledGroupHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
  lineHeight: '20px',
  fontSize: '18px',
  color: theme.palette?.text?.lightGray,
  background: theme.palette?.background?.paper,
}))

export const StyledGroupItems = styled('ul')({
  padding: 0,
})
