export const assetStockImportColumn = {
    product: 'ชื่อทรัพย์สิน',
    quantity: 'จำนวนทรัพย์สิน',
    unitPrice: 'มูลค่าต่อหน่วย (฿)',
}

export const assetSkuImportColumn = {
    skuNo: 'รหัสทรัพย์สิน',
    costCenter: 'Cost Center',
    unitPrice: 'มูลค่าต่อหน่วย (฿)',
}

export const assetAssetImportColumn = {
    skuNo: 'รหัสทรัพย์สิน',
    costCenter: 'Cost Center',
    assetNo: 'Item Name',
    unitPrice: 'มูลค่าต่อหน่วย (฿)',
    contractStartDate: 'วันเริ่มสัญญา',
    contractEndDate: 'วันสิ้นสุดสัญญา',
    renewContractDate: 'วันต่อสัญญา',
    holder: 'ชื่อผู้ถือครอง',
    ownerLName: 'สกุลผู้ถือครอง',
    ownerEmail: 'Email ผู้ถือครอง',
}

export const assetLicenseImportColumn = {
    skuNo: 'รหัสทรัพย์สิน',
    costCenter: 'Cost Center',
    unitPrice: 'มูลค่าต่อหน่วย (฿)',
    contractStartDate: 'วันเริ่มสัญญา',
    contractEndDate: 'วันสิ้นสุดสัญญา',
    renewContractDate: 'วันต่อสัญญา',
    holder: 'ชื่อผู้ถือครอง',
    ownerLName: 'สกุลผู้ถือครอง',
    ownerEmail: 'Email ผู้ถือครอง',
    payer: 'ชื่อผู้ชำระเงิน',
    payerLName: 'สกุลผู้ชำระเงิน',
    payerEmail: 'Email ผู้ชำระเงิน',
    paymentType: 'วิธีชำระเงิน',
}

export const IMPORT_STATUS = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}