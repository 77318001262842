import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import _ from 'lodash'
import Form from './Form'
import {
  getByUuidJobTitle,
  updateCreateJobTitle,
  filterLevel,
} from '../../utils/apiPath'
import { validationSchema } from './FormSchema'
import { callAPI } from '../../utils/lib'
import Breadcrumbs from '../../components/Breadcrumbs'
import { onView } from './JobTitleList'
import { DialogContext } from '../../context/dialog-context'
export const breadcrumbList = (titleText) => {
  return [
    { title: 'Master Data', link: '/', pointer: false },
    {
      title: 'Job Title',
      link: '/master-data/job-title',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]
}

const JobTitle = ({ isEditForm }) => {
  const { id: uuid } = useParams()
  const titleText = isEditForm ? 'แก้ไข Job Title' : 'เพิ่ม Job Title'

  useEffect(() => {
    fetchLevel({ setGetLevel })
    if (isEditForm) {
      setIsPageLoading(true)
      fetchJobTitleById({
        uuid,
        setInitialValues,
        setDisplayTitle,
        formik,
        setDialog,
        setIsPageLoading,
      })
    }
  }, [isEditForm])
  const history = useHistory()
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [displayTitle, setDisplayTitle] = useState('')
  const [getLevel, setGetLevel] = useState([])
  const [initialValues, setInitialValues] = useState({
    id: '',
    jobTitle: '',
    sequence: '',
    level: '',
    status: true,
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.status = initialValues.status
      setDialog({
        ...dialog,
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        onConfirmClick: () =>
          submitJobTitle({
            uuid,
            values,
            isEditForm,
            setDialog,
            setIsLoading,
            history,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${displayTitle}`}
          </Typography>
        </Box>
        <Breadcrumbs
          sx={{ mx: 3, mb: 3 }}
          menuList={breadcrumbList(titleText)}
        />
        <Form
          isEditForm={isEditForm}
          dialog={dialog}
          setDialog={setDialog}
          handleClose={() => onCancelClickDialog(setDialog)}
          formik={formik}
          isLoading={isLoading}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          getLevel={getLevel}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default JobTitle

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const submitJobTitle = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  setIsLoading,
  history,
}) => {
  const methodType = isEditForm ? 'put' : 'post'
  const sequence = parseInt(values.sequence)
  let body = {
    jobTitle: values.jobTitle,
    level: values.level,
    sequence: sequence,
    status: values.status ? 'ACTIVE' : 'INACTIVE',
  }
  if (isEditForm)
    body = {
      ...body,
      id: values.id,
      uuid: uuid,
    }

  callAPI({
    method: methodType,
    url: updateCreateJobTitle,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res
      setDialog({
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        open: true,
        variant: 'success',
        onConfirmClick: () => {
          setDialog({ variant: 'success', open: false })
          onView({ uuid: uuidLink }, history)
        },
      })
    },
    onError: () => {
      setDialog({ open: false })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const fetchJobTitleById = async ({
  uuid,
  setInitialValues,
  setDisplayTitle,
  formik,
  setDialog,
  setIsPageLoading,
}) => {
  callAPI({
    method: 'get',
    url: getByUuidJobTitle + `/${uuid}`,
    onSuccess: (res) => {
      const { sequence, id, level } = res
      const jobTitle = _.get(res, 'jobTitle', '')
      const status = _.get(res, 'status', true)
      setInitialValues({
        ...res,
        id: id,
        jobTitle: jobTitle,
        sequence: sequence,
        status: status === 'ACTIVE' ? true : false,
      })
      setDisplayTitle(jobTitle)
      formik.setFieldValue('id', id)
      formik.setFieldValue('jobTitle', jobTitle)
      formik.setFieldValue('sequence', sequence)
      formik.setFieldValue('level', level.uuid)
    },
    onError: (er) => {
      formik.setErrors({ jobTitle: er.response.data.message[0] })
      setDialog({
        open: true,
        variant: 'fail',
        content: er.response.data.message[0],
        onConfirmClick: () =>
          setDialog({
            variant: 'fail',
            content: er.response.data.message[0],
            open: false,
          }),
      })
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const fetchLevel = async ({ setGetLevel }) => {
  callAPI({
    method: 'post',
    url: filterLevel,
    body: {
      level: '',
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE'],
    },
    onSuccess: (data) => {
      setGetLevel(_.get(data, 'result', []))
    },
  })
}
