import { store } from '../../../../App'
import { fetchOverview } from './fetchOverview'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../redux/slices/crud'

export const handleClearFilter = (key) => {
  const { defaultStation, defaultStationName } =
    store.getState().crud.roomManagementDashboard
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.startDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.endDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.isClearFilter',
        value: 'date',
      }),
    )
  }
  if (key === 'filterStation') {
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.station',
        value: defaultStation,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.stationName',
        value: defaultStationName,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.isFilterStation',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.filtersDrawer.isClearFilter',
        value: 'station',
      }),
    )
  }
  fetchOverview()
}
