import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import {
  RADIO_TYPE_ENUM,
  RADIO_TYPE_LABEL,
} from '../../../../../../../constants/eLearning'
import DatePicker from '../../../../../../../components/Input/DatePicker'
import Select from '../../../../../../../components/Input/Select'
import TextInput from '../../../../../../../components/Input/TextInput'
import HeadLabel from '../../../../../components/HeadLabel'
import { getStateValue, handleChange, handleChangeEvent } from '../../../events'
import { onDateChange } from '../../Period/events'
import { StyledRadioGroup } from '../../Styled'
import { timeSpanTypeOptions } from '../model'
import {
  handleDefaultSelect,
  handleInputFormat,
  handleTimeSpanOptions,
} from '../events'

const RadioCheckboxForm = () => {
  const dueDateKey = 'dueDate'
  const timeSpanTypeKey = 'timeSpanType'
  const timeSpanKey = 'timeSpan'
  const advanceNotificationCheckKey = 'advanceNotificationCheck'
  const advanceNotificationKey = 'advanceNotification'
  const continuousNotificationsCheckKey = 'continuousNotificationsCheck'
  const continuousNotificationsKey = 'continuousNotifications'
  const dispatch = useDispatch()
  const {
    dueDateType,
    courseAccessExpiration,
    timeSpanTypeState,
    timeSpanState,
    dueDate,
    advanceNotificationCheck,
    advanceNotification,
    continuousNotificationsCheck,
    continuousNotifications,
  } = useSelector(
    () => ({
      dueDateType: getStateValue('dueDateType'),
      courseAccessExpiration: getStateValue('courseAccessExpiration'),
      timeSpanTypeState: getStateValue(timeSpanTypeKey),
      timeSpanState: getStateValue(timeSpanKey),
      dueDate: getStateValue(dueDateKey, null),
      advanceNotificationCheck: getStateValue(
        advanceNotificationCheckKey,
        null,
      ),
      advanceNotification: getStateValue(advanceNotificationKey, null),
      continuousNotificationsCheck: getStateValue(
        continuousNotificationsCheckKey,
        null,
      ),
      continuousNotifications: getStateValue(continuousNotificationsKey, null),
    }),
    shallowEqual,
  )
  const timeSpanType = useMemo(
    () => handleDefaultSelect(timeSpanTypeOptions, timeSpanTypeState),
    [timeSpanTypeOptions, timeSpanTypeState],
  )
  const timeSpanDynamicOption = useMemo(
    () => handleTimeSpanOptions(timeSpanType),
    [timeSpanType],
  )
  const timeSpan = useMemo(
    () => handleDefaultSelect(timeSpanDynamicOption, timeSpanState),
    [timeSpanDynamicOption, timeSpanState],
  )
  const { errors, setFieldError } = useFormikContext()
  const errorDueDate = _.get(errors, dueDateKey, '')
  const isDueDateError = !_.isEmpty(errorDueDate)
  const errorTimeSpanType = _.get(errors, timeSpanTypeKey, '')
  const errorTimeSpan = _.get(errors, timeSpanKey, '')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <HeadLabel text="ระยะเวลาแสดงบนเว็บไซต์" required />
      <StyledRadioGroup
        name="dueDateType"
        value={dueDateType}
        onChange={(e) => {
          dispatch(handleChangeEvent(e))
          dispatch(handleChange(dueDateKey, null))
          if (!isDueDateError) setFieldError(dueDateKey, undefined)
        }}
      >
        <FormControlLabel
          value={RADIO_TYPE_ENUM.NONE}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.NONE}
        />
        <FormControlLabel
          value={RADIO_TYPE_ENUM.SPECIFIC_DATE}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.SPECIFIC_DATE}
        />
      </StyledRadioGroup>
      {dueDateType === RADIO_TYPE_ENUM.SPECIFIC_DATE && (
        <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
          <HeadLabel
            text="แสดงหลักสูตรนี้จนถึง"
            variant="body1"
            required
            sx={{ mt: 1 }}
          />
          <DatePicker
            boxSx={{ width: 'auto', minWidth: 180 }}
            sxTextError={{ mb: 0 }}
            id="dueDate"
            name="dueDate"
            disablePast
            hideDisableDateRange
            textError={errorDueDate}
            hideTextError={!isDueDateError}
            value={dueDate || ''}
            onChange={(value) => dispatch(onDateChange(value, dueDateKey))}
          />
        </Box>
      )}

      <HeadLabel text="ระยะเวลาการเข้าถึงหลักสูตร" required />
      <StyledRadioGroup
        name="courseAccessExpiration"
        value={courseAccessExpiration}
        onChange={(e) => {
          dispatch(handleChangeEvent(e))
          if (e.target.value === RADIO_TYPE_ENUM.TIME_SPAN) {
            dispatch(handleChange(timeSpanTypeKey, 'MONTH'))
            dispatch(handleChange(timeSpanKey, 1))
          } else {
            dispatch(handleChange(timeSpanTypeKey, null))
            dispatch(handleChange(timeSpanKey, null))
          }
          if (!_.isEmpty(errorTimeSpanType) || !_.isEmpty(errorTimeSpan)) {
            setFieldError(timeSpanTypeKey, undefined)
            setFieldError(timeSpanKey, undefined)
          }
        }}
      >
        <FormControlLabel
          value={RADIO_TYPE_ENUM.NONE}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.NONE}
        />
        <FormControlLabel
          value={RADIO_TYPE_ENUM.TIME_SPAN}
          control={<Radio />}
          label={RADIO_TYPE_LABEL.TIME_SPAN}
        />
      </StyledRadioGroup>
      {courseAccessExpiration === 'TIME_SPAN' && (
        <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
          <HeadLabel
            text="ระยะเวลา"
            variant="body1"
            required
            sx={{ mt: 1.5 }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Select
              boxSx={{ width: 100 }}
              selectSx={{ height: 54 }}
              textErrorSx={{ mb: 0 }}
              type="basic"
              dataTestid="select-time-span-type"
              id="timeSpanType"
              name="timeSpanType"
              options={timeSpanTypeOptions}
              showTextError={errorTimeSpanType}
              textError={errorTimeSpanType}
              value={timeSpanType}
              handleChange={(e) => {
                dispatch(handleChangeEvent(e))
                dispatch(handleChange(timeSpanKey, null))
              }}
            />
            <Select
              boxSx={{ width: 100 }}
              selectSx={{ height: 54 }}
              textErrorSx={{ mb: 0 }}
              type="basic"
              dataTestid="select-time-span"
              id="timeSpan"
              name="timeSpan"
              options={timeSpanDynamicOption}
              showTextError={errorTimeSpan}
              textError={errorTimeSpan}
              value={timeSpan}
              handleChange={(e) => dispatch(handleChangeEvent(e))}
            />
          </Box>
        </Box>
      )}

      <HeadLabel text="ระบบส่ง E-MAIL แจ้งเตือน" required />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, ml: '21px' }}
      >
        <FormControlLabel
          label="เตือนก่อนวันเริ่มอบรม"
          name={advanceNotificationCheckKey}
          control={<Checkbox checked={advanceNotificationCheck} />}
          onChange={(e) => {
            dispatch(handleChangeEvent(e, true))
            dispatch(handleChange(advanceNotificationKey, null))
            setFieldError(advanceNotificationKey, undefined)
          }}
        />
        <SubInput
          label="ระยะเวลา"
          name={advanceNotificationKey}
          value={advanceNotification}
          disabled={!advanceNotificationCheck}
        />
        <FormControlLabel
          label="ส่งแจ้งเตือนเป็นระยะ"
          name={continuousNotificationsCheckKey}
          control={<Checkbox checked={continuousNotificationsCheck} />}
          onChange={(e) => {
            dispatch(handleChangeEvent(e, true))
            dispatch(handleChange(continuousNotificationsKey, null))
            setFieldError(continuousNotificationsKey, undefined)
          }}
        />
        <SubInput
          label="แจ้งทุก ๆ"
          name={continuousNotificationsKey}
          value={continuousNotifications}
          disabled={!continuousNotificationsCheck}
        />
      </Box>
    </Box>
  )
}

export default RadioCheckboxForm

export const SubInput = ({ label, name, value, disabled }) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  return (
    <Box sx={{ display: 'flex', ml: 6, gap: 3 }}>
      <HeadLabel text={label} variant="body1" required sx={{ mt: 1.5 }} />
      <TextInput
        boxSx={{ width: 100 }}
        sx={{ height: 54 }}
        type="basic"
        placeholder="ระบุจำนวน"
        id={`input-${name}`}
        name={name}
        textError={_.get(errors, name, '')}
        isShowTextError={_.get(errors, name, '')}
        disabled={disabled}
        value={value || ''}
        onChange={(e) => {
          const value = e.target.value
          dispatch(handleChange(name, value === '' ? null : Number(value)))
        }}
        inputProps={{ onInput: handleInputFormat }}
      />
      <Typography mt={1.5}>วัน</Typography>
    </Box>
  )
}
