import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import { StyledContainer, StyledContent, LoadingPage } from '../Styled'
import { breadcrumbPreviewList } from '../model'
import HeaderList from './Header'
import { fetchDataList } from './event'
import HistoryDrawer from './HistoryDrawer'
import { callAPI } from '../../../utils/lib'
import { apiToRcmsHistoryDetail } from '../../../utils/apiPath'
import { useParams } from 'react-router-dom'
import Detail from './Detail'

const List = () => {
  const { uuid, id } = useParams()
  const dispatch = useDispatch()
  const { isLoading, agent_detail } = useSelector(
    (state) => ({
      isLoading: state.apiToRcms.isLoading,
      agent_detail: state.apiToRcms.agent_detail,
    }),
    shallowEqual
  )

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = () => dispatch(fetchDataList('fetch', id))

  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [limitHistory, setLimitHistory] = useState(100)
  const [pageHistory, setPageHistory] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  const fetchHistory = async () => {
    const realPage = pageHistory === 0 ? 1 : pageHistory + 1
    const body = {
      rcmsUuid: '',
      agentProfileUuid: id,
      limit: limitHistory,
      page: realPage,
    }
    const url = `${apiToRcmsHistoryDetail}`
    await callAPI({
      url: url,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  useEffect(() => {
    fetchHistory()
  }, [limitHistory, pageHistory])

  const getBreadcrumb = () => {
    let BreadcrumbPreview = { ...breadcrumbPreviewList }
    BreadcrumbPreview[2].link = `/api-to-rcms/detail/${uuid}`
    return <Breadcrumbs menuList={breadcrumbPreviewList} />
  }

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">{`รายละเอียด Agent: ${agent_detail.fullName}`}</Typography>
        </Box>
        {getBreadcrumb()}

        <HeaderList onHistoryClick={onHistoryClick} fetchData={fetchData} />

        <Detail />
      </StyledContent>

      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={pageHistory}
        setPage={setPageHistory}
        setLimit={setLimitHistory}
        allCount={allCount}
      />
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default List
