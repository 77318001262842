import styled from '@mui/system/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const TableHeader = styled(Grid)(() => ({
  textAlign: 'center',
  alignContent: 'center',
}))

export const RequestType = styled(Typography)(({ background }) => ({
  height: 50,
  textAlign: 'center',
  alignContent: 'center',
  background: background,
  borderRight: '5px solid #0000003B',
}))

export const TableBodyTypo = styled(Typography)(({ background }) => ({
  height: 50,
  alignContent: 'center',
  background: background,
  paddingLeft: '16px',
}))

export const TableBody = styled(Grid)(() => ({
  alignContent: 'center',
}))
