import React, { createRef, useRef, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import _ from 'lodash'
import MapQuestionType from './MapQuestionType'
import { draftQuestionOther } from '../../../../../../redux/slices/eEvaluationForm'
import * as event from '../../../event'
import SubQuestionSingle from './DefaultChoice/SubQuestionSingle'

const Index = () => {
  const myRef = useRef({ current: [] })
  const listQuestion = useSelector(draftQuestionOther, shallowEqual)
  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eEvaluationForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (listQuestion.length > 0) {
      myRef.current = listQuestion.map(
        (_, i) => myRef.current[i] ?? createRef()
      )
    }
  }, [listQuestion])
  useEffect(() => {
    if (
      _.get(errorSchemaMessage, 'field', '') !== '' &&
      errorSchemaMessage.sectionType === 'questions'
    ) {
      event.scrollSmoothHandler(
        _.get(errorSchemaMessage, 'questionNo', -1),
        myRef
      )
    }
  }, [errorSchemaMessage])

  return listQuestion.length > 0 ? (
    listQuestion.map((item, index) => (
      <div key={item} ref={myRef?.current[index]}>
        <MapQuestionType
          id={item}
          key={item}
          sequence={item}
          indexQuestion={index}
          length={listQuestion.length}
        />
        <SubQuestionSingle sequence={item} indexQuestion={index} />
      </div>
    ))
  ) : (
    <></>
  )
}

export default Index
