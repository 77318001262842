import React from 'react'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import IconButton from '@mui/material/IconButton'

const ShowHideButton = ({ show, setShow }) => {
  return (
    <IconButton onClick={() => setShow(!show)}>
      {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  )
}

export default ShowHideButton
