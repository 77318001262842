import React, { useState } from 'react'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

export default function TimeChoosing(props) {
  const { setHours, setMins, hours, mins, Submit } = props

  const [activeHours, setActiveHours] = useState(hours)
  const [activeMins, setActiveMins] = useState(mins)

  const onClickHandleHours = (index) => {
    setActiveHours(index)
    setHours(index)
  }

  const onClickHandleMins = (index) => {
    setActiveMins(index)
    setMins(index)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <MainContainer>
          <ChoiceContainer>
            {Array(24)
              .fill('')
              .map((_, index) => (
                <Choice
                  data-testid={`choice-hours-${index}`}
                  className={activeHours === index && 'active'}
                  onClick={() => onClickHandleHours(index)}
                  key={index}
                  disabled
                >
                  {('0' + index).slice(-2)}
                </Choice>
              ))}
          </ChoiceContainer>
        </MainContainer>
        <MainContainer>
          <ChoiceContainer>
            <Choice
              data-testid={'choice-mins-0'}
              className={activeMins === 0 && 'active'}
              onClick={() => onClickHandleMins(0)}
            >
              00
            </Choice>
            <Choice
              data-testid={'choice-mins-10'}
              className={activeMins === 10 && 'active'}
              onClick={() => onClickHandleMins(10)}
            >
              10
            </Choice>
            <Choice
              data-testid={'choice-mins-20'}
              className={activeMins === 20 && 'active'}
              onClick={() => onClickHandleMins(20)}
            >
              20
            </Choice>
            <Choice
              data-testid={'choice-mins-30'}
              className={activeMins === 30 && 'active'}
              onClick={() => onClickHandleMins(30)}
            >
              30
            </Choice>
            <Choice
              data-testid={'choice-mins-40'}
              className={activeMins === 40 && 'active'}
              onClick={() => onClickHandleMins(40)}
            >
              40
            </Choice>
            <Choice
              data-testid={'choice-mins-50'}
              className={activeMins === 50 && 'active'}
              onClick={() => onClickHandleMins(50)}
            >
              50
            </Choice>
          </ChoiceContainer>
        </MainContainer>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}
      >
        <SubmitContainer>
          <SubmitButton onClick={Submit}>OK</SubmitButton>
        </SubmitContainer>
      </Box>
    </>
  )
}

const SubmitContainer = styled(Box)({
  height: 'auto',
  width: '65px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px',
})

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '20px',
  backgroundColor: theme?.palette?.primary?.dark,
  color: theme?.palette?.primary?.contrast,

  '&:hover': {
    backgroundColor: theme?.palette?.primary?.light,
    color: theme?.palette?.primary?.contrast,
  },
}))

const MainContainer = styled(Box)({
  height: 'auto',
  maxHeight: '270px',
  width: '70px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '8px',
})

const ChoiceContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    width: '0rem',
    height: '0px',
  },
})

const Choice = styled('div')(({ theme }) => ({
  width: '50px',
  height: '40px',
  borderRadius: '4px',
  backgroundColor: theme?.palette?.primary?.contrast,
  color: theme?.palette?.primary?.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  '&.active': {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.primary?.contrast,
  },

  '&:hover': {
    backgroundColor: theme?.palette?.primary?.light,
    color: theme?.palette?.primary?.contrast,
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
}))
