import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

export const draftCourse = createSelector(
  (state) => state.eEvaluationPreview,
  (state) => state.courses.map((item) => item.key)
)

export const draftLecturer = createSelector(
  (state) => state.eEvaluationPreview,
  (state) => state.lecturers.map((item) => item.key)
)

export const draftCourseManagement = createSelector(
  (state) => state.eEvaluationPreview,
  (state) => state.courseManagements.map((item) => item.key)
)

export const draftAssessmentQuestion = ({ keyField, keyAssessment, keySub }) =>
  createSelector(
    (state) => state.eEvaluationPreview,
    (state) => {
      const assessmentState = state[keyField].find(
        (item) => item.key == keyAssessment
      )
      if (!keySub) return assessmentState.questions.map((item) => item.key)

      const choice = assessmentState.choices.find(
        (item) => item.nextSubQuestionSequence == keySub
      )
      const subQuestion = _.get(choice, 'subQuestions', {})
      return subQuestion.questions.map((item) => item.key)
    }
  )

export const draftQuestion = createSelector(
  (state) => state.eEvaluationPreview,
  (state) => state.questions.map((item) => ({ key: item.key, type: item.type }))
)
