import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { setMobileProps } from '../../../../../../../../../redux/slices/eTestingPreview'
import { StyledNumberBadge } from '../Styled'
import { StyledDragButton, handleMaxWidth, handleMinWidth } from './Styled'

const Draggable = (props) => {
  const { data, index, isDropped, isSubmit, isSelected } = props
  const dispatch = useDispatch()
  const isMobile = useSelector(
    (state) => state.eTestingPreview.mobileProps.isMobile,
    shallowEqual
  )
  const dragProps = useDraggable({ id: _.get(data, 'uuid', ''), data })
  const { attributes, listeners, setNodeRef, transform, isDragging } = dragProps
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined
  const dragAttr = isMobile ? {} : { ...listeners, ...attributes }
  const title = _.get(data, 'title', '')
  const maxWidth = handleMaxWidth(title.length)
  const minWidth = handleMinWidth(title.length)

  return (
    <StyledDragButton
      id={`drag-drop-item-${index}`}
      ref={setNodeRef}
      sx={{ ...style }}
      dropped={isDropped && !isDragging}
      isDragging={isDragging}
      isSelected={isSelected}
      isSubmit={isSubmit}
      isMobile={isMobile}
      maxWidth={maxWidth}
      minWidth={minWidth}
      onClick={() =>
        isMobile && dispatch(setMobileProps({ selectedItem: data }))
      }
      {...dragAttr}
    >
      {title}
      {isSubmit && (
        <StyledNumberBadge color="text.white" lineHeight="24px">
          {index + 1}
        </StyledNumberBadge>
      )}
    </StyledDragButton>
  )
}

export default Draggable
