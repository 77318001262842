import _ from 'lodash'
import { Button, Grid, IconButton, Typography, Box } from '@mui/material'
import { Add, ArrowBackIosOutlined } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import { StatusLabel } from '../../../../RoomManagement/StatusLabel'
import { ROOM_AVAILABLE } from '../../../../../constants/roomManagement'
import { handleBookingThisRoom } from '../../../../../modules/RoomManagement/BookingRoomSection/event'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'

export const Header = (props) => {
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const { uuid, roomName, roomAvailable, latestRoomAvailable, station } = props

  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const isUserStation = !_.isEmpty(
    _.find(_.get(user, 'area', []), (item) => {
      return item.station.uuid === station.uuid
    }),
  )

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} sm={9}>
        <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIosOutlined />
          </IconButton>
          <Typography sx={{ typography: { sm: 'h4', xs: 'h6' } }}>
            {roomName}
          </Typography>
          <StatusLabel roomAvailable={ROOM_AVAILABLE[roomAvailable]} />
        </Box>
      </Grid>
      {(hasPermissionALL || (hasPermissionPIC && isUserStation)) && (
        <Grid item xs={12} sm={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'flex-end', xs: 'flex-start' },
            }}
          >
            <Button
              variant="contained"
              disabled={roomAvailable === ROOM_AVAILABLE.NOT_AVAILABLE}
              startIcon={<Add />}
              onClick={() =>
                dispatch(handleBookingThisRoom(latestRoomAvailable, uuid))
              }
            >
              จองห้อง
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
