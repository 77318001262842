import { Box, Card, CardContent, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { StyledCard } from '../Styled'
import Table from '../../../../../components/redux/Table'
import { fetchData } from './events'

const UserLoginHistory = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { limit, page, isLoading, order, sort } = table
  useEffect(() => {
    dispatch(
      fetchData({
        table,
      }),
    )
  }, [page, limit, order, sort])
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h6">ประวัติการ Login</Typography>
          <StyledCard isLoading={isLoading}>
            <Table />
          </StyledCard>
        </CardContent>
      </Card>
    </Box>
  )
}

export default UserLoginHistory
