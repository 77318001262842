import React, { useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RefreshTwoTone from '@mui/icons-material/RefreshTwoTone'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import Add from '@mui/icons-material/Add'
import Launch from '@mui/icons-material/Launch'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import TextInput from '../../../../../../../components/Input/TextInput'
import { fieldSelector } from '../../../../../../../redux/slices/eLearning/courseForm'
import {
  getStateValue,
  handleChangeEvent,
  handleGetModuleList,
} from '../../../events'
import ModuleForm from '../ModuleForm'
import DragDropContent, { DragHolder } from '../DragDropContent'
import {
  StyledSmallButton,
  StyledFlexRow,
  StyledLessonCard,
  StyledLessonHeader,
  StyledMiniIconBtn,
} from '../Styled'
import { handleAddItem, handleDeleteItem, handleDragEnd } from '../events'

const LessonForm = ({ provided, index, lessonsKey }) => {
  const [isExpanded, setExpanded] = useState(true)
  const dispatch = useDispatch()
  const lessonKey = `${lessonsKey}[${index}]`
  const moduleKey = `${lessonKey}.eLearningLessonRefModule`
  const nameKey = `${lessonKey}.name`
  const lessons = useSelector(fieldSelector(lessonsKey), shallowEqual)
  const modules = useSelector(fieldSelector(moduleKey), shallowEqual)
  const name = useSelector(() => getStateValue(nameKey, []), shallowEqual)
  const { errors, setErrors } = useFormikContext()
  const onGoing = useSelector(
    (state) => state.eLearningCourseForm.onGoing,
    shallowEqual,
  )

  return (
    <StyledLessonCard
      {...provided.draggableProps}
      ref={provided.innerRef}
      disabled={onGoing}
    >
      <StyledLessonHeader>
        <StyledFlexRow sx={{ alignItems: 'center' }}>
          {isExpanded && lessons.length > 1 && (
            <DragHolder provided={provided} />
          )}
          <Typography variant="body1b">บทเรียนที่ {index + 1}</Typography>
        </StyledFlexRow>
        <StyledMiniIconBtn onClick={() => setExpanded(!isExpanded)}>
          {isExpanded ? (
            <KeyboardArrowUpTwoTone />
          ) : (
            <KeyboardArrowDownTwoTone />
          )}
        </StyledMiniIconBtn>
      </StyledLessonHeader>
      {isExpanded && (
        <>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextInput
              required
              name={nameKey}
              type="basic"
              labelText={`ชื่อบทเรียนที่ ${index + 1}`}
              placeholder="ชื่อบทเรียน"
              id="input-name"
              textError={_.get(errors, nameKey, '')}
              isShowTextError={_.get(errors, nameKey, '')}
              value={name}
              onChange={(e) => dispatch(handleChangeEvent(e))}
              disabled={onGoing}
            />
            {index !== 0 && (
              <IconButton
                sx={{ mt: 3 }}
                onClick={() => dispatch(handleDeleteItem(index, lessonsKey))}
                disabled={onGoing}
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </Box>
          <StyledFlexRow sx={{ gap: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1b">เลือก Module </Typography>
              <Typography variant="body1b" color="error.main">
                *
              </Typography>
            </Box>
            <StyledMiniIconBtn onClick={() => dispatch(handleGetModuleList())}>
              <RefreshTwoTone color="primary" />
            </StyledMiniIconBtn>
          </StyledFlexRow>
          <DragDropContent
            id="module-list"
            onDragEnd={(result) =>
              dispatch(handleDragEnd(result, moduleKey, setErrors))
            }
          >
            {modules.map((id, index) => (
              <ModuleForm
                index={index}
                key={index}
                id={`module-${id}`}
                moduleKey={moduleKey}
              />
            ))}
          </DragDropContent>

          <StyledFlexRow sx={{ gap: '10px', ml: 4.5 }}>
            <StyledSmallButton
              variant="contained"
              startIcon={<Add />}
              onClick={() => dispatch(handleAddItem(moduleKey))}
              disabled={onGoing}
            >
              เพิ่ม Module
            </StyledSmallButton>
            <StyledSmallButton
              variant="outlined"
              startIcon={<Launch />}
              onClick={() => window.open('/e-learning/module/form', '_blank')}
            >
              สร้างโมดูล
            </StyledSmallButton>
          </StyledFlexRow>
        </>
      )}
    </StyledLessonCard>
  )
}

export default LessonForm
