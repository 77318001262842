import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'

import Switch from '../../../../components/Version/Switch'
import VersionDialog from '../../../../components/Version/Dialog'
import { tooltipText } from '../../../../constants/eTesting'

import { StyledHeaderContainer } from '../../Styled'
import { handleSwitch, handleToggleButton } from './events'

const Index = () => {
  const dispatch = useDispatch()
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isAvailable, isDisable, uuid } = table
  const { status, filterProp } = filter
  const { searchText } = search
  const { id: codeId } = useParams()
  const tableProps = {
    table,
    limit,
    order,
    page,
    sort,
    status,
    filterProp,
    searchText,
    codeId,
  }

  return (
    <>
      <StyledHeaderContainer>
        <Button
          data-testid="btn-create"
          variant="contained"
          onClick={() => {
            window.location.href = `/e-certification/create-version/${codeId}`
          }}
          size="m"
        >
          <Add />
          สร้างเวอร์ชั่นใหม่
        </Button>
      </StyledHeaderContainer>
      <Switch
        title={tooltipText}
        onChange={(e) => dispatch(handleSwitch(e, uuid, tableProps))}
        isDisable={isDisable}
        isAvailable={isAvailable}
      />
    </>
  )
}

export default Index

export const DialogContent = () => {
  const dispatch = useDispatch()
  return (
    <VersionDialog onChange={(e) => dispatch(handleToggleButton(e))}>
      การปิดใช้งาน จะ
      <span>ไม่มีผลย้อนหลัง</span> กับหลักสูตร
      หรือคลาสที่เลือกแบบประเมินนี้ไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
      ยังคงสามารถใช้งานแบบประเมินนี้ต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง
      ที่หน้าจัดการหลักสูตร
    </VersionDialog>
  )
}
