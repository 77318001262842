import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import InputBase from '@mui/material/InputBase'
import styled from '@mui/system/styled'

export const BoxGroupBox = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme?.palette?.text?.lightBlue}`,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  margin: theme.spacing(2),

  display: 'flex',
  flexDirection: 'row',
  marginBottom: 0,
  '& > .MuiFormGroup-root': {
    width: '100%',
  },
}))

export const BoxDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  height: '100vh',
}))

export const BoxHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const BoxFooter = styled(Box)(({ theme }) => ({
  margin: `auto ${theme.spacing(2)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: '45%' },
}))

export const StyledInputBase = styled(InputBase)(({ theme, isError }) => ({
  borderRadius: 0,
  borderColor: isError
    ? theme.palette?.error?.main
    : theme.palette?.text?.primary,
}))
