import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import { Header } from '../Header'
import { Content } from './Content'
import { fetchRoomDetailCalendar, fetchRoomDetails } from './event'
import _ from 'lodash'
import {
  setCurrentDate,
  setReduxObjectValue,
  setReduxValue,
  setStationUuid,
  setStationName,
} from '../../../redux/slices/roomManagement/Dashboard'

const View = () => {
  const { id: uuid } = useParams()

  const dispatch = useDispatch()
  const { roomDetails } = useSelector(
    (state) => ({
      roomDetails: state.roomManagement.roomDetails,
    }),
    shallowEqual,
  )

  useEffect(() => {
    const currentDate = new Date()
    dispatch(setCurrentDate(currentDate))

    if (!_.isEmpty(uuid)) {
      dispatch(
        setReduxObjectValue({ key: 'uuid', value: uuid, objectKey: 'detail' }),
      )
      dispatch(fetchRoomDetails(uuid))
      dispatch(fetchRoomDetailCalendar())
      dispatch(setReduxValue({ key: 'calendarPage', value: 'roomDetail' }))
    }
  }, [uuid])

  useEffect(() => {
    const stationUuid = _.get(roomDetails, 'station.uuid')
    const stationName = _.get(roomDetails, 'station.station')

    dispatch(setStationUuid(stationUuid))
    dispatch(setStationName(stationName))
  }, [roomDetails])

  return (
    <Box sx={{ m: 3 }}>
      <Header isShowButton={false} />
      <Content />
    </Box>
  )
}

export default View
