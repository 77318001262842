import React from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
// import { validatePermission } from '../../../../../utils/lib'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import appHistory from '../../../../../appHistory'
import { crudConfig } from '../crudConfig'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/system'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { shallowEqual, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { handleRejectedClick } from '../handler/handleRejectedClick'
import MenuItem from '@mui/material/MenuItem'
import DropdownButton from './DropdownButton'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  TicketStatus,
} from '../../../../../constants/stock'
import _ from 'lodash'
import { Typography } from '@mui/material'
import { handleClickApproveDropdown } from '../handler/handleClickApproveDropdown'
import { useSelector } from 'react-redux'
import { fromTypeEnum } from '../../Form/enum/assetTypEnum'
import { handleCancelEdit } from '../handler/handleCancelEdit'
import { validatePermission } from '../../../../../utils/lib'

export function ActionComponent({ uuid, formData }) {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const { from, to, isHideEditAndApprove } = useSelector(
    (state) => ({
      form: state.crud.formData.form,
      to: state.crud.formData.to,
      isHideEditAndApprove: state.crud.isHideEditAndApprove,
    }),
    shallowEqual,
  )
  const checkIfImportProduct =
    from?.value === fromTypeEnum.IMPORT_PRODUCT ? true : false
  const hasPermissionALL = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const hasPermissionPIC = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.CREATE],
  })
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const toStationUuid = _.get(to, 'station.uuid', '')
  const checkStaffStationMatched = staffStationUuid === toStationUuid

  const approveOptions = [
    {
      text: 'อนุมัติทันที',
      status: TicketStatus.APPROVED,
    },
    {
      text: 'แก้ไขจำนวนและอนุมัติ',
      status: TicketStatus.EDITED_APPROVED,
    },
  ]

  return (
    <Box display="flex" flexDirection="row" gap={3}>
      {isHideEditAndApprove && (
        <Button
          startIcon={<RestoreTwoToneIcon />}
          variant="outlined"
          onClick={() =>
            dispatch(setFieldValue({ key: 'isShowHistoryDrawer', value: true }))
          }
        >
          ประวัติ
        </Button>
      )}
      {isHideEditAndApprove &&
        (hasPermissionALL || hasPermissionPIC) &&
        [TicketStatus.DRAFT].includes(formData.status) && (
          <Button
            startIcon={<EditIcon />}
            variant="contained"
            onClick={() =>
              appHistory.push(crudConfig.modulePath + '/form/' + uuid)
            }
            data-testid="btn-edit"
          >
            แก้ไข
          </Button>
        )}
      {!checkIfImportProduct &&
        (hasPermissionALL || (hasPermissionPIC && checkStaffStationMatched)) &&
        isHideEditAndApprove &&
        [TicketStatus.WAITING_APPROVE].includes(formData.status) && (
          <Button
            startIcon={<CloseIcon />}
            variant="outlined"
            onClick={() => handleRejectedClick(uuid)}
          >
            ปฏิเสธรายการ
          </Button>
        )}

      {!checkIfImportProduct &&
        (hasPermissionALL || (hasPermissionPIC && checkStaffStationMatched)) &&
        isHideEditAndApprove &&
        [TicketStatus.WAITING_APPROVE].includes(formData.status) && (
          <Box position="relative">
            <DropdownButton
              label="อนุมัติรายการ"
              id="approve-dropdown-transfer"
            >
              {_.map(approveOptions, (option, index) => (
                <MenuItem
                  key={index}
                  data-testid={`menu-approve-${index}`}
                  onClick={() =>
                    handleClickApproveDropdown(uuid, option.status)
                  }
                >
                  <Box>
                    <Typography variant="body1">{option.text}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </DropdownButton>
          </Box>
        )}

      {!checkIfImportProduct &&
        (hasPermissionALL || (hasPermissionPIC && checkStaffStationMatched)) &&
        !isHideEditAndApprove &&
        [TicketStatus.WAITING_APPROVE].includes(formData.status) && (
          <>
            <Button
              variant="outlined"
              onClick={() => handleCancelEdit()}
              data-testid="btn-edit"
            >
              ยกเลิกการแก้ไข
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleClickApproveDropdown(uuid, TicketStatus.EDITED_APPROVED)
              }
              data-testid="btn-edit"
            >
              อนุมัติรายการ
            </Button>
          </>
        )}
    </Box>
  )
}
