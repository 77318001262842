import React from 'react'
import _ from 'lodash'
import { store } from '../../../../../../../../App'
import {
  loadingDialog,
  openDialog,
} from '../../../../../../../../redux/slices/dialog'
import {
  putApproveLearner,
  putNotApproveLearner,
} from '../../../../../../../../services/eLearning/course/learner'
import {
  startLoading,
  stopLoading,
} from '../../../../../../../../redux/slices/table'
import { handleSuccess } from '../../ReasonDialog/events'
import ReasonDialog from '../../ReasonDialog'
import { handleCallFetch } from '../../event'

export const handleNotApprove = (data) => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: (
        <ReasonDialog
          title="เหตุผลที่ไม่อนุมัติ"
          onSubmit={(remark) => handleSubmitNotApprove(data, remark)}
        />
      ),
    }),
  )
}

export const handleFetchList = async () => {
  const { table } = store.getState().table
  const body = localStorage.getItem('learnerBody')
  await store.dispatch(handleCallFetch(table, JSON.parse(body)))
  store.dispatch(stopLoading())
}

export const handleSubmitNotApprove = async (data, remark) => {
  store.dispatch(loadingDialog())
  const payload = { courseBatchLearnerUuid: _.get(data, 'uuid', ''), remark }
  await store.dispatch(putNotApproveLearner(payload))

  await handleFetchList()
  store.dispatch(handleSuccess(true))
}

export const handleApproveLearner = async (uuid, handleClose) => {
  store.dispatch(startLoading())
  const payload = { courseBatchLearnerUuid: uuid }
  await store.dispatch(putApproveLearner(payload))

  await handleFetchList()
  handleClose()
}
