import {
  startLoading,
  stopLoading,
} from '../../../redux/slices/manageContent/login'
import { getManageContentLogin } from '../../../services/manageContent'

export const fetchManageContentLogin =
  (onEdit, setOnEdit) => async (dispatch) => {
    if (onEdit) return
    dispatch(startLoading())
    await dispatch(getManageContentLogin())
    setOnEdit(true)
    dispatch(stopLoading())
  }
