import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { setDateRange, setSelectedCourseFor } from '.'
import { fetchReportCourseSettingDashboard } from '../../../../modules/ReportCourseSettingDashboard/event'

export const listenerMiddlewareReportCourseSettingDashboard =
  createListenerMiddleware()

listenerMiddlewareReportCourseSettingDashboard.startListening({
  matcher: isAnyOf(setSelectedCourseFor, setDateRange),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(fetchReportCourseSettingDashboard())
  },
})
