import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import { themeColor } from '../../utils/themeData'

const ToDoCard = ({ cardType = 'today', taskCount = 0 }) => {
  const getCardBgByType = () => {
    switch (cardType) {
      case 'today':
        return '/image/todo-list-today-bg.svg'
      case 'todo':
        return '/image/todo-list-todo-bg.svg'
      case 'onworking':
        return '/image/todo-list-onworking-bg.svg'
      case 'dontdo':
        return '/image/todo-list-dontdo-bg.svg'
      default:
        return '/image/todo-list-today-bg.svg'
    }
  }

  const getCardTextByType = () => {
    switch (cardType) {
      case 'today':
        return 'งานใหม่วันนี้'
      case 'todo':
        return 'งานที่ต้องทำทั้งหมด'
      case 'onworking':
        return 'งานที่กำลังทำ'
      case 'dontdo':
        return 'งานที่ยังไม่ได้ทำ'
      default:
        return 'งานใหม่วันนี้'
    }
  }

  return (
    <Box>
      <Card
        sx={{
          minWidth: 156,
          minHeight: 96,
          mx: 1,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            minWidth: 90,
            minHeight: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            pt: 1,
            pl: 1,
            backgroundImage: `url(${getCardBgByType()})`,
            backgroundRepeat: 'no-repeat',
            zIndex: 0
          }}
        >
          {cardType === 'today' ? (
            <img
              src="/image/todo-list-new-issue.svg"
              style={{ minWidth: 36, minHeight: 36 }}
            />
          ) : (
            <FactCheckOutlinedIcon
              style={{ color: 'white' }}
              sx={{ minWidth: 36, minHeight: 36 }}
            />
          )}
        </Box>
        <Box
          sx={{
            textAlign: 'end',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            px: 3,
            pb: 1,
            height: '100%',
            zIndex: 1
          }}
        >
          <Typography variant="h3">{taskCount}</Typography>
          <Typography
            variant="tooltip"
            sx={{ color: themeColor.text.gray, fontWeight: 700 }}
          >
            {getCardTextByType()}
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}

export default ToDoCard
