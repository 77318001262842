import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { setStopModule } from '../../../../../redux/slices/eLearning/preview'
import { path } from '../../../../../constants/path'
import {
  StyledContainer,
  StyledResultContent,
} from '../../../../../components/Preview/Result/Styled'

const Result = ({ isModule }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const backPath = `${path.eEvaluation}/preview/${uuid || 'on-create'}`

  return (
    <StyledContainer>
      <BannerFinish />
      <Link
        component="button"
        variant="subtitle2"
        onClick={() =>
          isModule
            ? dispatch(setStopModule())
            : (window.location.href = backPath)
        }
      >
        กลับหน้าหลัก
      </Link>
    </StyledContainer>
  )
}

export default Result

export const BannerFinish = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_success.svg" />
      <Typography variant="h3" color="primary">
        ทำแบบประเมินสำเร็จ
      </Typography>
      <Typography variant="h6" color="text.secondary">
        ขอบคุณที่ทำแบบประเมิน
      </Typography>
    </StyledResultContent>
  )
}
