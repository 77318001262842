import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from '../../../../../../../utils/lib'
import { Column, Row } from '../../../../../../../components/Layout/Styled'
import { StyledLinearProgress } from './Styled'

const InProgressContent = ({ course }) => {
  const progressPercent = _.get(course, 'progressPercent', 0)
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <Box sx={{ width: '100%' }}>
      <Column id="in-progress" gap={0.5}>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant={isLg ? 'body1b' : 'body2b'} color="primary">
            Course Progress
          </Typography>
          <Typography
            variant={isLg ? 'body1' : 'body2'}
            color="text.gray"
            sx={{ fontSize: !isLg && 14 }}
          >
            เรียนไปแล้ว {_.round(progressPercent)}%
          </Typography>
        </Row>
        <StyledLinearProgress variant="determinate" value={progressPercent} />
      </Column>
    </Box>
  )
}

export default InProgressContent
