import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import Tab from '@mui/material/Tab'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'

import CircularProgress from '@mui/material/CircularProgress'

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  borderBottom: '4px solid',
  borderColor: 'transparent',
  background: 'transparent',
  fontSize: '20px',
  fontWeight: 700,
  letterSpacing: '0.46px',
  padding: `${theme.spacing(1)} 22px`,
  '&.Mui-selected': { fontWeight: 700, borderBottom: '4px solid' },
  ':hover': { borderColor: theme?.palette?.primary?.main },
  '&:not(.Mui-selected)': {
    color: '#A9B8CD',
  },
}))

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const LoadingExaminer = styled('div')(({ isLoading }) => ({
  display: isLoading ? 'block' : 'none',
  position: 'absolute',
  background: '#FFF',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  opacity: '0.4',
}))

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: 0,
  right: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    '&#view': {
      position: 'relative',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    position: 'relative',
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    minWidth: 275,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      '&#view': { marginTop: theme.spacing(5) },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(#versionTable)': { marginTop: theme.spacing(5) },
    },
  }),
)

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  p: { span: { color: theme?.palette?.primary?.main } },
}))

export const StyledEligibleDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  p: { span: { color: theme?.palette?.error?.main } },
}))


export const StyledInputImport = styled('input')({ display: 'none' })

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  border: '1px solid',
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette?.other?.divider,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}))

export const StyledColumnNoGap = styled(Box)(({ theme, noGap }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: { gap: noGap ? 0 : theme.spacing(3) },
}))

export const StyledRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledColumnFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

export const StyledRowFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& span#sub': { color: theme?.palette?.text?.secondary },
}))

export const StyledRespRow = styled(StyledRowFlex)(({ theme, isInput }) => ({
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: isInput ? 0 : theme.spacing(3),
  },
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledBodyCell = styled(TableCell)(({ theme, isActive }) => {
  return ({
    backgroundColor: isActive ? '' : theme.palette?.grey?.grey100
  })
})

export const StyledIdcardPhoto = styled('div')({
  position: 'relative',
})

export const StyledAnnouceButton = styled(Button)(({ theme, disabled }) => ({
  background: disabled
    ? theme.palette?.action?.disabledBackground
    : theme.palette?.secondary?.main,
  boxShadow: disabled ? 'unset' : theme.palette?.boxShadow?.main,
}))

export const BoxLabelText = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const StyledDashedLine = styled(Box)(({ theme }) => ({
  width: 'calc(100% - '+theme.spacing(3)+')',
  height: '1px',
  background: 'repeating-linear-gradient(90deg, rgb(219, 228, 241) 0px, rgb(219, 228, 241) 7px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 12px)',
}))