import { getOptionsReportByKey } from '../../../../../services/reportComp'
import { store } from '../../../../../App'
import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const fetchOptionsReportByCourse = async (
  columnSelected,
  eTestVersionUuid,
  courseCode,
) => {
  let type = 'COURSE_DETAIL'
  const modulePath = '/e-testing/dashboard'
  const body = {
    eTestVersionUuid: eTestVersionUuid,
    type: type,
    courseCode: type === 'COURSE_DETAIL' ? courseCode : '',
  }
  store.dispatch(
    setFieldValue({
      key: `filterList.isLoading`,
      value: true,
    }),
  )
  let { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: modulePath,
      body: body,
    }),
  )

  if (type === 'COURSE_DETAIL') {
    store.dispatch(
      setFieldValue({
        key: `filterList[${columnSelected}]`,
        value: _.get(response, 'results', []),
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: `filterList.isLoading`,
      value: false,
    }),
  )
}
