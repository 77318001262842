import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import { getCourseList, getELearningList } from '../../../services/report'

const manageReport = createSlice({
  name: 'manageReport',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setHandleChange: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    resetData: (state) => {
      state.courseType = ''
      state.courseCode = ''
      state.startDate = ''
      state.finishDate = ''
      state.courseList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseList.fulfilled, (state, { payload }) => {
        state.courseList = _.get(payload, 'data', []).map((item) => {
          return {
            label: _.get(item, 'englishName', '-'),
            value: _.get(item, 'courseCode', '-'),
          }
        })
      })
      .addCase(getCourseList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningList.fulfilled, (state, { payload }) => {
        state.courseList = _.get(payload, 'data', []).map((item) => {
          return {
            label: _.get(item, 'name', '-'),
            value: _.get(item, 'courseCode', '-'),
          }
        })
      })
      .addCase(getELearningList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading, setHandleChange, resetData } =
  manageReport.actions

export default manageReport.reducer
