import {
  Card,
  Drawer,
  Box,
  TableCell,
  Tab,
  ListItem,
  Tabs,
} from '@mui/material'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  '& > .MuiBox-root:first-of-type': {
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const StyledRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledCard = styled((props) => <Card {...props} />)(
  ({ isLoading, theme }) => ({
    opacity: isLoading ? 0.4 : 'unset',
    pointerEvents: isLoading ? 'none' : 'unset',
    minWidth: 275,
    marginTop: theme.spacing(3),
  }),
)

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 576,
  },
  height: '100vh',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginLeft: 0,
  zIndex: 2,
  padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
      2,
    )} ${theme.spacing(0)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledContentDrawer = styled(Box)(({ isLoading, theme }) => ({
  position: 'relative',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
}))

export const StyledCardDrawer = styled(Card)(({ theme }) => ({
  maxWidth: 528,
  padding: theme.spacing(3),
  margin: theme.spacing(3),
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledListItem = styled(ListItem)(({ theme, isDragDisabled }) => ({
  background: isDragDisabled
    ? theme.palette?.background?.paper
    : theme.palette?.text?.lightBlue,
  border: isDragDisabled
    ? `1px solid ${theme.palette?.blue?.blueLine}`
    : 'none',
  borderRadius: 0,
  boxShadow: `inset 0px -1px 0px ${theme.palette?.action?.hover}`,
  '&:first-of-type': {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
    maxWidth: 1390,
  },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  color: theme.palette?.primary?.main,
  textTransform: 'none',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginLeft: theme.spacing(3),
  '&.Mui-selected': {
    borderBottom: `${theme.spacing(0.5)} solid`,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 18,
  },
}))

export const StyledAuditLogTab = styled(Tabs)(() => ({
  '& .MuiTab-root': {
    textTransform: 'none',
  },
  '& .MuiTab-root.Mui-selected .MuiTypography-root ': {
    fontWeight: 700,
  },
}))
