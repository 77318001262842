import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledVideoWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
}))
