import React from 'react'
import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { visuallyHidden } from '@mui/utils'
import { useDispatch, useSelector } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import { ROW_PAGE_PER_LIST } from '../../../../constants/table'
import {
  handleChangePage,
  handleChangeRowsPerPage,
  handleLabelDisplay,
  handleRequestSort,
} from '../events'
import {
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
  StyledTableSortLabel,
  StyledTableHeadText,
} from '../Styled'
import { StyledBasicTable } from './Styled'
import { handleRowsCondition } from './events'

const BasicTable = () => {
  const dispatch = useDispatch()
  const table = useSelector((state) => state.basicTable)

  const {
    rows,
    headCells,
    isShowPagination,
    allCount,
    rowsPerPage,
    tablePage,
    sxTable,
  } = table

  return (
    <StyledBasicTable>
      <TableContainer>
        <StyledTable sx={sxTable}>
          <BasicTableHead />
          <TableBody>
            {rows &&
              rows.map((row, rowIndex) => (
                <TableRow key={rowIndex} color="primary" type="hover">
                  {handleRowsCondition(headCells, row, rowIndex)}
                </TableRow>
              ))}

            {rows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isShowPagination ? (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(e, tPage) => dispatch(handleChangePage(tPage, true))}
          onRowsPerPageChange={(event) =>
            dispatch(handleChangeRowsPerPage(event, true))
          }
        />
      ) : (
        <caption> </caption>
      )}
    </StyledBasicTable>
  )
}

export default BasicTable

export const BasicTableHead = () => {
  const dispatch = useDispatch()
  const table = useSelector((state) => state.basicTable)
  const { tableHeadText, headCells, defaultSort, order, sort } = table

  return (
    <TableHead>
      {tableHeadText && (
        <TableRow key="headText">
          <StyledTableHeadText colSpan={headCells.length}>
            <Typography variant="body2b">{tableHeadText}</Typography>
          </StyledTableHeadText>
        </TableRow>
      )}
      <TableRow key="head">
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }

            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={sort === valueId ? order : false}
                headcellwidth={headCell.width}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    dispatch(handleRequestSort(valueId, table, headCell, true))
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}
