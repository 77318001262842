import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  formData: {},
}

const UploadCropper = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    setFieldUploadValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    deleteFieldUploadValue: (state, { payload }) => {
      const data = _.cloneDeep(state.formData)
      delete data[payload]
      state.formData = data
    },
    setInitialConfig: (state, { payload }) => {
      _.set(state, 'formData', {})
      _.set(state, 'formErrors', [])
      _.set(state, 'crudConfig', payload.crudConfig)
    },
    replaceFieldError: (state, { payload }) => {
      state.formErrors = payload
    },
    setFieldError: (state, { payload }) => {
      state.formErrors = [...state.formErrors, ...payload]
    },

    setCompleteMultiPart: (state, { payload }) => {
      const _id = payload._id
      const index = _.findIndex(state.formData[payload.fieldName].files, {
        _id,
      })
      const data = _.get(payload, 'data', {})
      const obj = {
        uuid: _.get(data, 'uuid', ''),
        status: _.get(data, 'status', ''),
        percent: 100,
      }
      if (!_.isUndefined(index)) {
        const fileUploaded = _.get(
          state,
          `formData[${payload.fieldName}].files[${index}]`,
          {},
        )

        _.set(state, `formData[${payload.fieldName}].files[${index}]`, {
          ...fileUploaded,
          ...obj,
        })
      }
      state.uploadError = undefined
      state.isUploadSuccess = true
    },
    setCheckUploadStatus: (state, { payload }) => {
      const uuid = _.get(payload, 'uuid', '')
      const obj = {
        uuid,
        status: _.get(payload, 'status', ''),
        fileName: _.get(payload, 'fileName', []),
        file: _.get(payload, 'file', ''),
      }
      const uploadFile = _.get(
        state,
        `formData[${payload.fieldName}].files`,
        [],
      )
      const index = uploadFile.findIndex((item) => item.uuid === uuid)

      if (index !== -1) {
        const fileKey = `formData[${payload.fieldName}].files[${index}]`
        _.set(state, fileKey, { ...uploadFile[index], ...obj })
      }
    },
  },
})
export const {
  setFieldUploadValue,
  setCheckUploadStatus,
  setCompleteMultiPart,
  deleteFieldUploadValue,
} = UploadCropper.actions

export default UploadCropper.reducer
