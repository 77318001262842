import styled from '@mui/system/styled'

import { StyledColumn } from '../../../../Styled'

export const StyledOutlinedBox = styled(StyledColumn)(({ theme, isError }) => ({
  border: '1px solid',
  borderColor: isError
    ? theme?.palette?.error?.main
    : theme?.palette?.text?.silver,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))
