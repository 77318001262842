import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxAnswer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 12px)',
  marginLeft: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: { gap: 0 },
}))

export const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 2,
  alignItems: 'start',
  '& > *': { marginTop: theme.spacing(1) },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    width: 'auto',
  },
}))
