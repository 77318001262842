import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import {
  StyledCardContent,
  StyledDashedDivider,
} from '../../../../../components/View/Styled'
import ECertificatePreview from '../../../../../components/ECertificatePreview'

const ECertificate = () => {
  const detail = useSelector((state) => state.eLearningPathDetail.result)
  const refCertificate = _.get(
    detail,
    'eLearningLearningPathRefCertificate[0]',
    '',
  )

  return (
    <>
      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          E-Certificate
        </Typography>
        <ECertificatePreview certificate={refCertificate} />
      </StyledCardContent>
    </>
  )
}

export default ECertificate
