import React from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import DvrOutlined from '@mui/icons-material/DvrOutlined'
import RecordVoiceOverOutlined from '@mui/icons-material/RecordVoiceOverOutlined'
import ConnectWithoutContact from '@mui/icons-material/ConnectWithoutContact'
import { useDispatch } from 'react-redux'
import * as events from './events'
import { StyledHeaderContainer } from '../../Styled'
import DropdownButton from './DropdownButton'

export const createOptions = [
  {
    text: 'Online test',
    icon: <DvrOutlined />,
    link: '/e-testing/form/online-test',
  },
  {
    text: 'Assessment',
    icon: <RecordVoiceOverOutlined />,
    link: '/e-testing/form/assessment',
  },
  {
    text: 'Standalone',
    icon: <ConnectWithoutContact />,
    link: '/e-testing/form/standalone',
  },
]

const Index = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <StyledHeaderContainer>
      <Button
        data-testid="btn-download-0"
        variant="outlined"
        onClick={() => dispatch(events.onDownloadTemplate())}
        size="m"
      >
        ดาวน์โหลด Template
      </Button>

      <DropdownButton label="สร้างแบบทดสอบ" id="create-dropdown">
        {createOptions.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`menu-create-${index}`}
            onClick={() => events.onClickAddTest(option, history)}
          >
            <Box sx={{ mr: 1, display: 'flex' }}>
              {option.icon && <>{option.icon}</>}
            </Box>
            <Box>
              <Typography variant="body1">{option.text}</Typography>
            </Box>
          </MenuItem>
        ))}
      </DropdownButton>
    </StyledHeaderContainer>
  )
}
export default Index
