import { Box, Typography } from '@mui/material'
import ModuleCard from './ModuleCard'
import _ from 'lodash'

const ScheduleInfoCard = (props) => {
  const { dataList, totalModule, title } = props
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body2">{title}</Typography>
      {_.defaultTo(dataList, []).map((ele, index) => {
        const percent =
          ele.number > 0 ? ((ele.number / totalModule) * 100).toFixed(2) : 0
        return (
          <Box
            key={`schedule-${ele.type}-${index}`}
            width={'133.33px'}
            minHeight={'92px'}
          >
            <ModuleCard
              key={index}
              label={ele.label}
              subLable={ele.subLable}
              labelVariant="body2b"
              background={ele.background}
              dotColor={ele.dotColor}
              value={ele.number}
              unit={ele.unit}
              percent={ele?.hidePercent ? ' ' : `${percent}%`}
              minWidth={'133.33px'}
              padding={'8px 8px'}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default ScheduleInfoCard
