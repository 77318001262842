import React from 'react'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import ImageIcon from '@mui/icons-material/Image'
import LinkIcon from '@mui/icons-material/Link'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Box from '@mui/material/Box'
import DragDropContent from './DragDropContent'

import { createSelector } from '@reduxjs/toolkit'
import { shallowEqual, useSelector } from 'react-redux'
import { handleDragEnd } from '../../handler/handleDragend'
import { Row, StyledFormCard } from '../../Styled'
import { handleAddContentTypeText } from '../../handler/handleAddContentTypeText'
import { handleAddContentTypeLink } from '../../handler/handleAddCotentTypeLink'
import { handleAddContentTypeImage } from '../../handler/handleAddContentTypeImage'
import { handleAddContentTypeFile } from '../../handler/handleAddContentTypeFile'
import { PlaylistAdd } from '@mui/icons-material'
import { handleAddContentTypeKB } from '../../handler/handleAddContentTypeKB'

const contentsSelector = createSelector(
  (state) => state.crud.formData,
  (state) => {
    return state.contents
      ? state.contents.map((content) => ({ id: content.id }))
      : []
  },
)

const Content = () => {
  const contents = useSelector(contentsSelector, (original, current) => {
    return original.length === current.length
  })

  const contentType = useSelector(
    (state) => state.crud.formData.contentType,
    shallowEqual,
  )

  return (
    <StyledFormCard sx={{ gap: 3 }}>
      <Typography variant="h6">เนื้อหา</Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={'content-list'}>
          {(provided) => (
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
              width="100%"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {contents.map((content, index) => (
                <Draggable
                  index={index}
                  key={index}
                  draggableId={`content-${index}`}
                >
                  {(provided) => (
                    <DragDropContent provided={provided} index={index} />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Divider />
      {contentType === 'E_CONTENT' && (
        <Row sx={{ gap: 3 }}>
          <Button
            onClick={handleAddContentTypeText}
            variant="contained"
            startIcon={<TextFieldsIcon />}
          >
            เพิ่มข้อความ
          </Button>
          <Button
            onClick={handleAddContentTypeImage}
            variant="contained"
            startIcon={<ImageIcon />}
          >
            เพิ่มรูป
          </Button>
          <Button
            onClick={handleAddContentTypeLink}
            variant="contained"
            startIcon={<LinkIcon />}
          >
            เพิ่มลิงก์มีเดีย
          </Button>
          <Button
            onClick={handleAddContentTypeFile}
            variant="contained"
            startIcon={<AttachFileIcon />}
          >
            เพิ่มไฟล์
          </Button>
        </Row>
      )}
      {contentType === 'KB' && (
        <Row sx={{ gap: 3 }}>
          <Button
            onClick={handleAddContentTypeKB}
            variant="contained"
            startIcon={<PlaylistAdd />}
          >
            เพิ่มตอบคำถาม
          </Button>
        </Row>
      )}
    </StyledFormCard>
  )
}
export default Content
