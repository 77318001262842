import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'

const tableLeanerDetail = createSlice({
  name: 'tableLeanerDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.table.isLoading = true
    },
    stopLoading: (state) => {
      state.table.isLoading = false
    },
    setInitialTable: (state, { payload }) => {
      state.table.rows = {
        ...state.table.rows,
        [payload.type]: _.get(payload, 'rows', []),
      }
      state.table.headCells = {
        ...state.table.headCells,
        [payload.type]: _.get(payload, 'headCells', []),
      }
    },
  },
})

export const { startLoading, stopLoading, setInitialTable } =
  tableLeanerDetail.actions

export default tableLeanerDetail.reducer
