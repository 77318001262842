import {
  LoadingCircular,
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from '../../../../modules/Styled'
import Table from '../../../redux/Table'
import { useSelector } from 'react-redux'

export const CContainer = ({
  header,
  drawer,
  content,
  cardSx = {},
  contentSx = { mx: 3 },
}) => {
  const { table } = useSelector((state) => state.table)
  const { isLoading } = table
  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading} sx={contentSx}>
        <StyledHeader>{header}</StyledHeader>
        <StyledCard id="list-table" sx={cardSx}>
          {content}
          <Table />
        </StyledCard>
        {drawer}
      </StyledContent>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
