import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getManageClassExamList,
  getManageClassLearnerExam,
  getStandaloneExamList,
  getStandaloneUser,
  putManageClassExamScore,
  putStandaloneGiveScore,
} from '../../../services/manageClass/check'
import {
  getLearnerList,
  handleGetLearners,
  handleUpdateSuccess,
} from './events'
import { initialState } from './model'

const manageClassCheckExam = createSlice({
  name: 'manageClassCheckExam',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    resetSuccess: (state) => {
      state.isSuccess = false
    },
    setTab: (state, { payload }) => {
      state.tab = payload
      state.page = 1
      const learnerList = getLearnerList(state)
      state.learnerExam = _.get(learnerList, '[0]', null)
    },
    setLearnerExam: (state, { payload }) => {
      const { learner, page } = payload
      state.learnerExam = learner
      state.page = page
    },
    setNextPage: (state) => {
      const learnerList = getLearnerList(state)
      state.learnerExam = learnerList.find((_, index) => index === state.page)
      state.page = state.page + 1
    },
    setPreviousPage: (state) => {
      const learnerList = getLearnerList(state)
      state.learnerExam = learnerList.find(
        (_, index) => index === state.page - 2
      )
      state.page = state.page - 1
    },
    setChangeState: (state, { payload }) => {
      const { index, name, value } = payload
      const learnerAnswers = _.get(state.learnerExam, 'learnerAnswers', [])
      _.set(learnerAnswers, `[${index}][${name}]`, value)
    },
    resetChangeState: (state, { payload }) => {
      const learnerAnswers = _.get(state.learnerExam, 'learnerAnswers', [])
      _.set(learnerAnswers, `[${payload}]`, state.learnerAnswers[payload])
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageClassExamList.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        state.courseNameAndType = _.get(data, 'courseNameAndType', '')
        state.allowToAnnounce = _.get(data, 'allowToAnnounce', '')
        state.eTestingType = _.get(data, 'eTestingType', '')
        handleGetLearners(state, data)
      })
      .addCase(getManageClassExamList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getStandaloneExamList.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', '')
        state.eTestingVersionName = _.get(data, 'eTestingVersionName', '')
        state.allowToAnnounce = _.get(data, 'allowToAnnounce', false)
        state.eTestingType = 'STANDALONE'
        handleGetLearners(state, data)
      })
      .addCase(getStandaloneExamList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getManageClassLearnerExam.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', {})
        state.learnerExam = data
        state.learnerAnswers = _.get(data, 'learnerAnswers', [])
        const learnerIndex = state.learners.learnerAll.findIndex(
          (learner) => _.get(learner, 'learnerUuid', '') === data.uuid
        )
        state.page = learnerIndex + 1
      })
      .addCase(getManageClassLearnerExam.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(putManageClassExamScore.fulfilled, (state, { payload }) => {
        handleUpdateSuccess(state, payload)
        state.isSuccess = true
      })
      .addCase(putManageClassExamScore.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getStandaloneUser.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', {})
        state.learnerExam = data
        state.learnerAnswers = _.get(data, 'learnerAnswers', [])
        const learnerIndex = state.learners.learnerAll.findIndex(
          (learner) => _.get(learner, 'userUuid', '') === data.uuid
        )
        state.page = learnerIndex + 1
      })
      .addCase(getStandaloneUser.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(putStandaloneGiveScore.fulfilled, (state, { payload }) => {
        handleUpdateSuccess(state, payload)
        state.isSuccess = true
      })
      .addCase(putStandaloneGiveScore.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  resetSuccess,
  setTab,
  setLearnerExam,
  setNextPage,
  setPreviousPage,
  setChangeState,
  resetChangeState,
  setBody,
} = manageClassCheckExam.actions

export default manageClassCheckExam.reducer
