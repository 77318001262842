import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledRoadMapBg = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette?.blue?.darkIndigo,
  display: 'flex',
  justifyContent: 'center',
}))

export const StyledRoadMapContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1146,
  display: 'flex',
  flexDirection: 'column',
  padding: '72px 0 100px',
  textAlign: 'center',
  alignItems: 'center',
  gap: theme.spacing(7),
  '& .carousel-root': {
    maxWidth: '100%',
    minWidth: '100%',
    '& .carousel-slider': { minWidth: '100%', paddingBottom: theme.spacing(4) },
    '& .control-dots': { margin: 0, padding: `0 ${theme.spacing(2)}` },
    '& .slider': { maxWidth: '100%', marginLeft: 0 },
    '& .slide': { width: '100%' },
    '& .dot': {
      background: `${theme?.palette?.text?.lightGray} !important`,
      transition: '.25s ease-in',
    },
    '& .dot.selected': {
      background: `${theme?.palette?.text?.white} !important`,
    },
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 768,
    padding: '40px 0 70px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4),
    minWidth: '100%',
    maxWidth: '100%',
    padding: `${theme.spacing(5)} 0`,
    '& .slide.selected.previous:first-of-type': { opacity: 0 },
  },
}))

export const StyledRoadMapHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  '& > .MuiTypography-root': {
    color: theme.palette?.text?.wildSand,
  },
}))

export const StyledRoadMapBox = styled(Box)(({ theme, length }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: length == 4 ? '210px' : '75px',
  rowGap: theme.spacing(8.5),
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(4),
  },
}))

export const StyledDisplayRoadMap = styled(Box)(({ theme }) => ({
  width: 230,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  gap: theme.spacing(3),
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  ':hover': { opacity: 0.8 },
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(2), width: 224 },
  [theme.breakpoints.down('sm')]: { width: 170, gap: theme.spacing(2) },
}))

export const StyledDisplayImg = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 120,
  height: 120,
  img: { width: '100%' },
  [theme.breakpoints.down('lg')]: {
    width: 88,
    height: 88,
  },
}))

export const StyledDisplayText = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette?.text?.wildSand,
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
}))

export const StyledMoreLessButton = styled((props) => (
  <Button {...props} variant="outlined" />
))(({ theme }) => ({
  color: theme?.palette?.text?.white,
  borderColor: theme?.palette?.text?.white,
  ':hover': {
    color: theme?.palette?.primary?.main,
    backgroundColor: theme?.palette?.text?.white,
  },
  '&:after': { background: theme?.palette?.text?.white },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
  },
}))
