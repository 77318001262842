import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import buddhistEra from 'dayjs/plugin/buddhistEra'

export const convertFormatDateTime = ({ value }) => {
  dayjs.extend(buddhistEra)
  dayjs.extend(utc)
  dayjs.locale('th')
  return dayjs(new Date(value)).utc().format('DD MMM BBBB')
}
