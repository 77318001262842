import { shallowEqual, useSelector } from 'react-redux'
import {
  bookingItemLoadingSelector,
  roomLoadingSelector,
} from '../../../../redux/slices/roomManagement/Drawer'
import LinearProgress from '@mui/material/LinearProgress'

export const ProgressLoading = () => {
  const isBookingItemLoading = useSelector(
    bookingItemLoadingSelector(),
    shallowEqual,
  )
  const isRoomLoading = useSelector(roomLoadingSelector(), shallowEqual)

  return (
    <>
      {(isBookingItemLoading === true || isRoomLoading === true) && (
        <LinearProgress />
      )}
    </>
  )
}
