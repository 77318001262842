import { openDialog } from '../../../redux/slices/dialog'
import { store } from '../../../App'
import { handleProcessDelete } from './handleProcessDelete'

const DIALOG_TITLE = 'ยืนยันลบข้อมูล'
const DIALOG_MESSAGE = 'คุณต้องการลบรายการนี้ใช่หรือไม่'

function confirmDialog(uuid, mutatePropToBody, isPinned) {
  store.dispatch(
    openDialog({
      type: 'delete',
      title: DIALOG_TITLE,
      message: DIALOG_MESSAGE,
      handleConfirm: () => {
        handleProcessDelete(uuid, mutatePropToBody, isPinned).then()
      },
      isCloseDialog: false,
    }),
  )
}

export const handleDeleteClick = async (uuid, mutatePropToBody, isPinned) => {
  confirmDialog(uuid, mutatePropToBody, isPinned)
}
