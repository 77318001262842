import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../../../../../components/Input/Autocomplete'
import ActiveSwitch from '../../../../components/ActiveSwitch'
import { SECTION_OBJ } from '../../../model'
import { StyledInputCard } from './Styled'
import {
  handleFilterOption,
  handleSelectTabBody,
  handleSwitchBody,
} from './events'

const CardInput = ({
  keyField,
  name,
  label,
  defaultValue,
  option,
  item,
  isPath,
  isCollection,
}) => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const keyInfo = isPath
    ? SECTION_OBJ.LEARNING_PATH.keyInfo
    : isCollection
    ? SECTION_OBJ.COLLECTION.keyInfo
    : SECTION_OBJ.COURSE.keyInfo
  const props = useSelector(
    (state) => ({
      levelDistribution:
        state.manageContentELearningHome.levelDistribution[keyInfo],
      data: state.manageContentELearningHome.data,
    }),
    shallowEqual,
  )
  const filteredOption = useMemo(
    () => handleFilterOption({ ...props, option, keyInfo }),
    [props.levelDistribution],
  )
  const keyInput = `${keyField}.${name}`

  return (
    <StyledInputCard>
      <Autocomplete
        type="basic"
        labelText={label}
        options={filteredOption.sort((a, b) => {
          if (isPath || isCollection) return -b.id.localeCompare(a.id)
          return -b.courseCode.localeCompare(a.courseCode)
        })}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            placeholder={`เลือก${label}`}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': `select-${name}`,
            }}
          />
        )}
        textError={_.get(errors, keyInput, '')}
        handleChange={(e) => dispatch(handleSelectTabBody(e, keyInput, option))}
      />
      <ActiveSwitch
        name="status"
        checked={_.get(item, 'status', false)}
        onChange={(e) => dispatch(handleSwitchBody(e, keyField))}
        dataTestId={`active-${name}`}
      />
    </StyledInputCard>
  )
}

export default CardInput
