import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const BoxDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  height: '100vh',
  '& > .MuiPaper-root': {
    width: 360,
  },
}))

export const BoxHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const BoxFooter = styled(Box)(({ theme }) => ({
  margin: `auto ${theme.spacing(2)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': {
    width: '45%',
  },
}))
