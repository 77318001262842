import { eLearningModuleCompleteMultiPart } from '../../../utils/apiPath'
import axios from 'axios'
import { store } from '../../../App'
import { setCompleteMultiPart } from '../../../redux/slices/uploadFile'

export const postModuleCompleteMultiPart = async ({ body, _id, fieldName }) => {
  try {
    const response = await axios.post(eLearningModuleCompleteMultiPart, body)
    store.dispatch(setCompleteMultiPart({ ...response, _id, fieldName }))
    return { ...response, _id }
  } catch (err) {
    throw new Error(err)
  }
}
