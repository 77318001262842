import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { crudConfig as initialCrudConfig } from './components/overviewTab/crudConfig'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import Header from './components/header'
import TabMenu from './components/tabMenu'
import { StyledFormWrapper, StyledLoadingBox } from '../Styled'
import { TesterListTab } from './components/testerListTab'
import { TestListTab } from './components/testTab'
import { OverviewTab } from './components/overviewTab'
import { LoadingCircular } from '../../../Styled'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { resetTable } from '../../../../redux/slices/table'
import { closeCourseDrawer } from '../../../../redux/slices/ETestingDashboard'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'

export const ETestingDashboardView = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(resetTable())
    dispatch(setFieldValue({ key: 'filterStateBelt', value: {} }))
    dispatch(setFieldValue({ key: 'isFilterDate', value: false }))
    dispatch(closeCourseDrawer())
  }, [location])

  const { isLoading, EtestDashboardTab, crudConfig } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
      EtestDashboardTab: state.crud.EtestDashboardTab,
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (
      EtestDashboardTab !== 'testerList' &&
      EtestDashboardTab !== 'testList' &&
      EtestDashboardTab !== 'overview'
    ) {
      dispatch(setFieldValue({ key: 'EtestDashboardTab', value: 'overview' }))
    }
  }, [EtestDashboardTab])

  useEffect(() => {
    dispatch(setFieldValue({ key: 'EtestDashboardTab', value: 'overview' }))
    initialConfig(initialCrudConfig)
  }, [])
  if (_.isEmpty(crudConfig)) {
    return <div />
  }

  return (
    <>
      {!_.isEmpty(crudConfig) && (
        <StyledLoadingBox isLoading={isLoading}>
          <div>
            <Header />
            <TabMenu />
            <FormContent />
          </div>
          <LoadingCircular isLoading={isLoading} />
        </StyledLoadingBox>
      )}
    </>
  )
}

export const FormContent = () => {
  const EtestDashboardTab = useSelector(
    (state) => state.crud.EtestDashboardTab,
    shallowEqual,
  )
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {EtestDashboardTab === 'overview' && <OverviewTab />}
      {EtestDashboardTab === 'testerList' && <TesterListTab />}
      {EtestDashboardTab === 'testList' && <TestListTab />}
    </StyledFormWrapper>
  )
}
