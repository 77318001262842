import React from 'react'
import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { UploadLoadingCircular } from './UploadLoadingCircular'

export const Uploading = () => {
  const { percent, fileName, isUploading } = useSelector(
    (state) => ({
      percent: state.uploadCropper.percent,
      fileName: state.uploadCropper.fileName,
      isUploading: state.uploadCropper.isUploading,
    }),
    shallowEqual,
  )

  if (!isUploading) return null
  return (
    <>
      <UploadLoadingCircular percent={percent} />
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body1b">กำลังโหลดไฟล์ ...</Typography>
        <Typography variant="h6" color="primary.main">
          {fileName}
        </Typography>
        <Typography variant="body2" color="error.main">
          กำลังอัปโหลดไฟล์ กรุณาอย่าปิดหน้าจอนี้
        </Typography>
      </Box>
    </>
  )
}
