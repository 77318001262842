import React from 'react'
import { Column, Row } from '../../Styled'
import { CustomDropdown } from '../../../../../../../components/CRUD/components/CustomDropdown'

import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import { handleDeleteContentItem } from '../../handler/handleDeleteContentItem'
import { StyledDeleteIconBtn } from './Styled'
import { handleOptionChoosed } from '../../handler/handleOptionChoosed'

const LearningPath = ({ index }) => {
  return (
    <Column sx={{ gap: 2 }}>
      <Row>
        <CustomDropdown
          required
          masterDataName={'learningPath'}
          fieldName={`contents[${index}].content`}
          labelName="ชื่อ Learning Path"
          placeholder="กรุณากรอก ชื่อ Learning Path"
          handleExtraChange={handleOptionChoosed}
        />
        <StyledDeleteIconBtn
          onClick={async () => {
            await handleDeleteContentItem(index)
            handleOptionChoosed()
          }}
        >
          <DeleteTwoTone />
        </StyledDeleteIconBtn>
      </Row>
    </Column>
  )
}

export default LearningPath
