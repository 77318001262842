import { Box, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { questionTypeText } from '../../../constants/type'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { setEvaDrawer } from '../../../handler/handleClickViewButton'
import SelectIcon from '../../../../../../../../components/redux/SelectIcon'
import { DataCard } from '../../../components/ModuleCard/DataCard'
import BarChart from '../../../../../../../../components/Chart/BarChart'
import { axisLabel } from '../../../models/partModel'
import _ from 'lodash'
import { generateGradientColors } from '../../../handler/handleSetColors'
import AnswerTable from '../../../components/AnswerTable'
import LoadingPageCircular from '../../../../../../../../components/CRUD/components/LoadingPageCircular'
import { InboxTwoTone } from '@mui/icons-material'
import { StyledBox } from '../../../../../../../../modules/Inventory/Product/WarehouseList/Drawer/AssetDetail/components/SKU/Styled'
import TruncatedText from '../../../components/TruncatedText'

export const Part4Content = () => {
  const { part4, data, isLoadingDashboard } = useSelector(
    (state) => ({
      part4: state.crud.eEvaluationDashboard?.evaluationDrawer?.part4,
      data: state.crud.eEvaluationDashboard?.evaluationDrawer?.part4?.data,
      isLoadingDashboard: state.crud?.isLoading,
    }),
    shallowEqual,
  )

  const handleChangeNo = (isAdd) => {
    const currentNo = part4?.selectedNo || 1
    let nextNo = isAdd ? currentNo + 1 : currentNo - 1
    if (nextNo <= 0 || nextNo > part4?.data?.length) return false
    setEvaDrawer('part4.selectedNo', nextNo)
  }

  const list = _.defaultTo(part4?.data, []).map((item) => {
    return {
      label: item?.no,
      value: item?.no,
    }
  })

  const dataInfo = _.defaultTo(data, []).find(
    (item) => item.no === part4?.selectedNo,
  )

  const answer = dataInfo?.data ?? []
  const moduleData = preparedModuleData(dataInfo ?? [])
  const labels = answer.map((item) => item.no)
  const bgColors =
    answer.length > 1
      ? generateGradientColors(answer.length ?? 0)
      : answer.length == 1
      ? ['#B2DFDB']
      : []
  const chart = answer.map((item) => item.count)
  return (
    <Box px={3}>
      {part4?.type === questionTypeText.FREE_TEXT ? (
        <>
          {_.defaultTo(part4?.data, []).length <= 0 && (
            <>
              {' '}
              <StyledBox>
                <Box>
                  <InboxTwoTone />
                  <Typography variant="body2" color="text.lightGray">
                    ไม่พบข้อมูล
                  </Typography>
                </Box>
              </StyledBox>
            </>
          )}
          {_.defaultTo(part4?.data, []).map((item, index) => {
            return (
              <Box
                key={`eva-free-text-${index}`}
                my={2}
                p={2}
                border="solid 1px #DBE4F1"
                borderRadius="8px"
              >
                <Typography variant="h6" mb={2}>
                  {item?.title}
                </Typography>
                <TruncatedText
                  text={item?.answerTxt ?? ''}
                  readMoreTxt={'ดูเพิ่มเติม'}
                  maxWidth={'72vw'}
                  titleVariant={'body1'}
                  maxLines={3}
                  dialogTitle={'คำตอบ'}
                />
              </Box>
            )
          })}
          {/* <Box textAlign="center">
            <CircularProgress size={40} sx={{ m: 'auto' }} />
          </Box> */}
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" gap={2} mt={3}>
            <Typography variant="body1">ทั้งหมด</Typography>
            <Typography variant="body1">
              <SelectIcon
                dataTestId={`select-eva-no`}
                name={`select-eva-drawer-no`}
                listOption={list}
                placeholder={'เลือกข้อ'}
                value={part4?.selectedNo || 1}
                handleChange={(e) => {
                  setEvaDrawer('part4.selectedNo', e.target.value)
                }}
              />
            </Typography>
            <Typography variant="body1">
              {' '}
              / {part4?.data?.length ?? 1} ข้อ
            </Typography>
            <ChevronLeftIcon
              sx={{
                cursor: part4?.selectedNo == 1 ? 'default' : 'pointer',
                color: part4?.selectedNo == 1 ? '#d9d9d9' : '#00008F',
              }}
              onClick={() => handleChangeNo(false)}
            />
            <ChevronRightIcon
              sx={{
                cursor:
                  part4?.selectedNo == list.length ? 'default' : 'pointer',
                color: part4?.selectedNo == list.length ? '#d9d9d9' : '#00008F',
              }}
              onClick={() => handleChangeNo(true)}
            />
          </Box>
          <Box mt={3}>
            <TruncatedText
              text={dataInfo.question ?? ''}
              readMoreTxt={'ดูเพิ่มเติม'}
              maxWidth={'72vw'}
              titleVariant={'h6'}
              maxLines={3}
              dialogTitle={'คำถาม'}
            />
            <Box
              display="flex"
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
              width={'100%'}
            >
              <DataCard
                dataList={moduleData}
                totalModule={''}
                border={'none'}
                padding={0}
              />
            </Box>
            <Box minHeight="250px">
              <BarChart
                dataSet={{
                  data: chart,
                  backgroundColor: bgColors,
                }}
                labels={labels}
                height={'300px'}
                showXAxisLabel={true}
                showYAxisLabel={true}
                xAxisLabel={axisLabel.x}
                yAxisLabel={axisLabel.y}
                showPercent={true}
                eleId={`part4-${dataInfo.question}`}
              />
            </Box>
            <Box>
              <AnswerTable answers={answer} />
            </Box>
          </Box>
        </>
      )}
      <LoadingPageCircular isLoading={isLoadingDashboard} />
    </Box>
  )
}

const preparedModuleData = (item) => {
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: item?.attendeeCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: item?.respondentCount ?? 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${item?.overallPercentage ?? 0} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}
