import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, Divider, IconButton } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import * as events from './event'
import { StyledColumn } from '../Styled'
import {
  arrayStatus,
  arrayCheckbox,
  defaultFilter,
  arrayTestResult,
} from './model'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../components/FilterDrawer/Styled'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../utils/lib'
import DateRangeInput from '../../../components/DateRangeInput'
import InputBase from '@mui/material/InputBase'

export const ListCheckbox = ({ listArray, stateFilter, setFilter }) => {
  return listArray.map((item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {!item.isDate && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={
                    item.nameInput == 'version'
                      ? {
                          'data-testid': `input-${item.nameInput}`,
                          type: 'number',
                          min: '0',
                          onInput: handleNumberInput,
                          onKeyDown: handleNumberKeyDown,
                          onPaste: handlePasteFormat,
                        }
                      : { 'data-testid': `input-${item.nameInput}` }
                  }
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {item.isDate && (
                <DateRangeInput
                  dateState={{
                    startDate: stateFilter.startDate,
                    endDate: stateFilter.toDate,
                    display: stateFilter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter,
                    )
                  }}
                  placeholder={'จากวันที่ - ถึงวันที่'}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListStatus = ({
  status,
  filter,
  setFilter,
  listKey = 'status',
  statusList,
}) => {
  return statusList.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const ExaminerListDrawer = ({ uuid, open, table, examinerPermissions }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <StyledColumn sx={{ position: 'relative' }}>
        <ListCheckbox
          listArray={arrayCheckbox}
          stateFilter={filter}
          setFilter={setFilter}
        />
      </StyledColumn>
      <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
        <FormControlLabel
          label="สถานะเข้าสอบ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <StyledList>
            <ListStatus
              status={filter.status}
              filter={filter}
              setFilter={setFilter}
              statusList={arrayStatus}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
        <FormControlLabel
          label="ผลการสอบ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'check-testResultChecked',
              }}
              name="testResultChecked"
              checked={filter.testResultChecked}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter,
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.testResultChecked && (
          <StyledList>
            <ListStatus
              status={filter.testResult}
              filter={filter}
              setFilter={setFilter}
              listKey="testResult"
              statusList={arrayTestResult}
            />
          </StyledList>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(
              events.handleFiltering(uuid, table, filter, examinerPermissions),
            )
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default ExaminerListDrawer
