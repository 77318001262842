import _ from 'lodash'

export const handleParamsNames = (options, status) => {
  if (status === 'OTHER') {
    status = 'Unassign,Todo,In progress'
  }
  return _.get(
    options.find((obj) => {
      if (obj.value === status) return obj
    }),
    'label',
    status,
  )
}
