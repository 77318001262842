import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextInput from '../../../components/Input/TextInput'
import SelectGroupChip from '../../../components/SelectGroupChip'
import List from '@mui/material/List'
import { ListItemText } from '@mui/material'

export default function Index({
  typeStatus,
  rowIndex,
  stateForm,
  setStateForm,
  validateForm,
  setValidateForm,
  type,
  course,
  options,
}) {
  const [checkDisable, setCheckDisable] = useState({
    online: false,
    onsite: false,
    other: false,
  })

  const [checkFirst, setCheckFirst] = useState(0)

  let trainingPlatform = []

  const plansLinks = _.get(stateForm, `plans[${rowIndex}].links`, []).length
    ? true
    : false
  const plansMeetingRooms = _.get(stateForm, `roomManagementMeetingRoom`, [])
    .length
    ? true
    : false

  if (type === 'Training') {
    const uuidCourse = _.get(stateForm, 'course.uuid', '')
    const findCourse = course.find((item) => {
      return item.uuid === uuidCourse
    })

    trainingPlatform = !_.isEmpty(findCourse)
      ? _.get(findCourse, 'trainingPlatform', [])
      : []
  }
  if (type === 'Activity Support') {
    trainingPlatform = _.get(stateForm, 'trainingPlatform', [])
  }

  const meetingRoom = _.filter(
    _.get(stateForm, `roomManagementMeetingRoom`, []),
    (item) => {
      return (
        item.bookingDate === _.get(stateForm, `dates[${rowIndex}].date`) &&
        _.isEmpty(item.meetingRoomOther) &&
        !_.isEmpty(item.meetingRoom)
      )
    },
  )

  const plansMeetingRoomsOther = _.filter(
    _.get(stateForm, `roomManagementMeetingRoom`, []),
    (item) => {
      return (
        item.bookingDate === _.get(stateForm, `dates[${rowIndex}].date`) &&
        !_.isEmpty(item.meetingRoomOther)
      )
    },
  )

  const isBookingRoom =
    _.size(meetingRoom) > 0 || _.size(plansMeetingRoomsOther) > 0

  useEffect(() => {
    checkLocation({
      stateForm,
      setCheckDisable,
      trainingPlatform,
      setStateForm,
      rowIndex,
      typeStatus,
      checkFirst,
      setCheckFirst,
    })
  }, [
    trainingPlatform,
    stateForm.course,
    stateForm.startDate,
    stateForm.finishDate,
  ])

  return (
    <Box
      sx={{
        flexDirection: 'row',
        gap: 2,
        paddingLeft: '16%',
        display: trainingPlatform.length ? 'block' : 'none',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <FormControlLabel
          label="แบบ Online"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'online-Checked',
              }}
              name="online"
              disabled={isBookingRoom}
              checked={_.get(
                stateForm,
                `dates[${rowIndex}].location.online`,
                false,
              )}
              onChange={(e) => {
                handleChangeChecked({
                  rowIndex,
                  stateForm,
                  setStateForm,
                  validateForm,
                  setValidateForm,
                  value: e.target.checked,
                  key: 'online',
                  typeStatus,
                })
              }}
            />
          }
        />
        {plansLinks &&
          _.get(stateForm, `dates[${rowIndex}].location.online`, false) && (
            <FromIsOnlineLink
              listValue={_.get(stateForm, `plans[${rowIndex}].links`, [])}
            />
          )}

        <FormControlLabel
          label="แบบ Onsite"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'onsite-Checked',
              }}
              name="onsite"
              disabled={isBookingRoom}
              checked={_.get(
                stateForm,
                `dates[${rowIndex}].location.onsite`,
                isBookingRoom,
              )}
              onChange={(e) => {
                handleChangeChecked({
                  rowIndex,
                  stateForm,
                  setStateForm,
                  validateForm,
                  setValidateForm,
                  value: e.target.checked,
                  key: 'onsite',
                  typeStatus,
                })
              }}
            />
          }
        />
        {!_.isEmpty(
          _.get(validateForm, `dates[${rowIndex}].location.mainText`, ''),
        ) && (
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="body2"
              sx={{ lineHeight: 0.5 }}
              color={'error'}
            >
              {_.get(validateForm, `dates[${rowIndex}].location.mainText`, '')}
            </Typography>
          </Box>
        )}

        {_.size(_.defaultTo(meetingRoom, [])) > 0 && (
          <Box sx={{ ml: 5 }}>
            <List sx={{ listStyleType: 'disc', ml: 1 }}>
              {_.map(_.defaultTo(meetingRoom, []), (room) => {
                return (
                  <ListItemText
                    sx={{ display: 'list-item', color: 'text.disabled' }}
                  >
                    <Typography variant="body1" color={'text.disabled'}>
                      {`${_.get(
                        room,
                        'meetingRoom.roomName',
                        '-',
                      )} (ชั้น ${_.get(room, 'meetingRoom.floor', '-')})`}
                    </Typography>
                  </ListItemText>
                )
              })}
            </List>
          </Box>
        )}

        {_.get(
          stateForm,
          `dates[${rowIndex}].location.onsite`,
          isBookingRoom,
        ) && (
          <Box sx={{ ml: 4 }}>
            <FormControlLabel
              label={
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography>ระบุห้องอบรม (Other)</Typography>

                  {_.get(
                    stateForm,
                    `dates[${rowIndex}].location.other`,
                    false,
                  ) && (
                    <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                      *
                    </Typography>
                  )}
                </Box>
              }
              control={
                <Checkbox
                  name="other"
                  inputProps={{
                    'data-testid': 'checkbox-other',
                  }}
                  disabled={
                    plansMeetingRooms
                      ? isBookingRoom
                      : typeStatus !== 'edit'
                      ? !_.get(checkDisable, 'other', isBookingRoom)
                      : false
                  }
                  checked={
                    isBookingRoom
                      ? true
                      : _.get(
                          stateForm,
                          `dates[${rowIndex}].location.other`,
                          false,
                        )
                  }
                  onChange={(e) => {
                    handleChangeChecked({
                      rowIndex,
                      stateForm,
                      setStateForm,
                      validateForm,
                      setValidateForm,
                      value: e.target.checked,
                      key: 'other',
                      typeStatus,
                    })
                  }}
                />
              }
            />

            {_.size(_.defaultTo(plansMeetingRoomsOther, [])) > 0 && (
              <Box sx={{ width: '96%', ml: 4 }}>
                <SelectGroupChip
                  id="meetingRooms"
                  name="meetingRooms"
                  labelText=""
                  type="basic"
                  placeholder="กรุณาเลือก"
                  disabled={true}
                  options={_.get(options, 'meetingRoom', [])}
                  value={plansMeetingRoomsOther.map((item) => {
                    return {
                      ...item,
                      label:
                        `${item.station?.station || ''}` +
                        ' ' +
                        item.meetingRoomOther,
                    }
                  })}
                />
              </Box>
            )}

            {_.size(_.defaultTo(meetingRoom, [])) === 0 &&
              _.size(_.defaultTo(plansMeetingRoomsOther, [])) === 0 &&
              _.get(stateForm, `dates[${rowIndex}].location.other`, false) && (
                <Box sx={{ width: '96%', ml: 4 }}>
                  <TextInput
                    id="classRoomOther"
                    name="classRoomOther"
                    type="basic"
                    placeholder="โปรดระบุ"
                    textError={_.get(
                      validateForm,
                      `dates[${rowIndex}].location.classRoomOther`,
                      '',
                    )}
                    value={_.get(
                      stateForm,
                      `dates[${rowIndex}].location.classRoomOther`,
                      '',
                    )}
                    onChange={(e) => {
                      const typeDate = _.get(stateForm, 'typeDate', 'DUPLICATE')
                      const listDate = _.get(stateForm, 'dates', [])
                      const listValidateForm = _.get(validateForm, 'dates', [])
                      let result = []
                      let resultValidate = []
                      if (typeDate === 'DUPLICATE') {
                        result = listDate.map((item) => {
                          return {
                            ...item,
                            location: {
                              ...item.location,
                              classRoomOther: e.target.value,
                            },
                          }
                        })
                        resultValidate = listValidateForm.map((item) => {
                          return {
                            ...item,
                            location: {
                              ...item.location,
                              classRoomOther: '',
                            },
                          }
                        })
                      } else {
                        result = listDate.filter((item, index) => {
                          if (index === rowIndex) {
                            item.location = {
                              ...item.location,
                              classRoomOther: e.target.value,
                            }
                          }
                          return item
                        })
                        resultValidate = listValidateForm.filter(
                          (item, index) => {
                            if (index === rowIndex) {
                              item.location = {
                                ...item.location,
                                classRoomOther: '',
                              }
                            }
                            return item
                          },
                        )
                      }

                      setValidateForm((prevState) => ({
                        ...prevState,
                        training: {
                          ...prevState.training,
                          dates: resultValidate,
                        },
                      }))

                      setStateForm((prevState) => ({
                        ...prevState,
                        training: {
                          ...prevState.training,
                          dates: result,
                        },
                      }))
                    }}
                  />
                </Box>
              )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export const checkLocation = ({
  stateForm,
  setCheckDisable,
  trainingPlatform,
  setStateForm,
  rowIndex,
  typeStatus,
  checkFirst,
  setCheckFirst,
}) => {
  let isHybrid = false
  let result = []
  let stateLocation = {
    online: false,
    onsite: false,
    other: false,
  }
  let location = {
    online: false,
    onsite: false,
    other: false,
  }

  if (trainingPlatform.length) {
    const checkTrue = trainingPlatform.find((item) => {
      return item.isOnline
    })
    const checkFalse = trainingPlatform.find((item) => {
      return !item.isOnline
    })

    if (
      trainingPlatform.length > 1 &&
      !_.isEmpty(checkTrue) &&
      !_.isEmpty(checkFalse)
    )
      isHybrid = true

    location = {
      online: isHybrid ? true : !_.isEmpty(checkTrue),
      onsite: isHybrid ? true : !_.isEmpty(checkFalse),
      other: isHybrid ? true : !_.isEmpty(checkFalse),
    }

    stateLocation = location

    let findLocation
    if (typeStatus === 'edit') {
      setCheckFirst(checkFirst + 1)
      let objLocation = _.get(stateForm, `dates[${rowIndex}].location`, {})
      if (_.isEmpty(objLocation)) {
        const date = _.get(stateForm, `dates[${rowIndex}].date`)
        findLocation = _.find(_.get(stateForm, `defaultDates`, []), (item) => {
          return item.date === date
        })
        objLocation = _.get(findLocation, `location`, {})
      }
      stateLocation = {
        online: _.get(objLocation, 'online', false),
        onsite: _.get(objLocation, 'onsite', false),
        other: _.get(objLocation, 'other', false),
      }
      if (checkFirst > 0) stateLocation = location
    }

    const typeDate = _.get(stateForm, 'typeDate', 'DUPLICATE')
    const listDate = _.get(stateForm, 'dates', [])

    if (typeDate === 'DUPLICATE') {
      result = listDate.map((item) => {
        return {
          ...item,
          location: {
            ...item.location,
            ...stateLocation,
          },
        }
      })
    } else {
      if (_.isEmpty(findLocation)) {
        result = listDate.filter((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              location: { ...item.location, ...stateLocation },
            }
          }
          return item
        })
      } else {
        result = listDate.map((item) => {
          if (item.date === findLocation.date) {
            return {
              ...item,
              location: _.get(findLocation, `location`, {}),
            }
          }
          return item
        })
      }
    }

    setCheckDisable(location)
    setStateForm((prevState) => ({
      ...prevState,
      training: {
        ...prevState.training,
        dates: result,
      },
    }))
  }
}
export const handleChangeChecked = ({
  rowIndex,
  stateForm,
  setStateForm,
  validateForm,
  setValidateForm,
  value,
  key,
}) => {
  const typeDate = _.get(stateForm, 'typeDate', 'DUPLICATE')
  const listDate = _.get(stateForm, 'dates', [])
  const listValidateForm = _.get(validateForm, 'dates', [])

  let newValue = {
    [key]: value,
  }
  if (key === 'other' && !value) {
    newValue = {
      ...newValue,
      classRoomOther: '',
    }
  }

  if (key === 'onsite' && !value) {
    newValue = {
      ...newValue,
      other: false,
      classRoomOther: '',
    }
  }
  let result = []
  let resultValidate = []
  if (typeDate === 'DUPLICATE') {
    result = listDate.map((item) => {
      return {
        ...item,
        location: {
          ...item.location,
          ...newValue,
        },
      }
    })
    resultValidate = listValidateForm.map((item) => {
      return {
        ...item,
        location: {
          ...item.location,
          mainText: '',
          [key]: '',
        },
      }
    })
  } else {
    result = listDate.filter((item, index) => {
      if (index === rowIndex) {
        const newLocation = { ...item.location, ...newValue }
        item.location = newLocation
      }
      return item
    })
    resultValidate = listValidateForm.filter((item, index) => {
      if (index === rowIndex) {
        const newLocation = { ...item.location, mainText: '', [key]: '' }
        item.location = newLocation
      }
      return item
    })
  }

  setValidateForm((prevState) => ({
    ...prevState,
    training: {
      ...prevState.training,
      dates: resultValidate,
    },
  }))

  setStateForm((prevState) => ({
    ...prevState,
    training: {
      ...prevState.training,
      dates: result,
    },
  }))
}

export const FromIsOnlineLink = ({ listValue }) => {
  if (listValue.length <= 0) return <></>

  return listValue.map((item, index) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '96%',
        ml: 4,
      }}
      key={index}
    >
      <Typography
        data-test-id="open-url"
        variant="body1"
        onClick={() => window.open(item.url)}
        sx={{ cursor: 'pointer' }}
      >
        <li>
          Link ห้อง {item.url} (ความจุห้อง : {item.capacity})
        </li>
      </Typography>
    </Box>
  ))
}
