import React, { useMemo, useState } from 'react'
// import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Column } from '../../../Styled'
import { CustomInput } from '../../../../../../../components/CRUD/components/CustomInput'
import { shallowEqual, useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import Videocam from '@mui/icons-material/Videocam'
import { StyledBoxVideo } from '../../../../../../ELearning/components/Styled'
import { validateVideo } from '../../../../../../ELearning/Module/Form/TypeContent/EmbedContent/events'
import CustomStatusSwitch from '../../../../../../../components/CRUD/components/CustomStatusSwitch'
import _ from 'lodash'

const Link = ({ index }) => {
  const { link, isHasDetail } = useSelector(
    (state) => ({
      link: state.crud.formData.contents[index].content.link,
      isHasDetail: state.crud.formData.contents[index].content.isHasDetail,
    }),
    shallowEqual,
  )
  const [invalidVdo, setInvalidVdo] = useState(false)
  const isFormatCorrect = useMemo(() => validateVideo(link), [link])

  return (
    <Column sx={{ gap: 2 }}>
      <Column>
        <CustomInput
          fieldName={`contents[${index}].content.link`}
          labelName="https://example.com"
          hideLabel={true}
        />

        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          วาง URL ของวิดิโอลงในกล่อง (เฉพาะ Youtube หรือ Vimeo เท่านั้น)
        </Typography>

        <ReactPlayer
          volume={0}
          playing
          muted
          style={{ display: 'none', pointerEvents: 'none' }}
          url={isFormatCorrect ? link : ''}
          onError={() => setInvalidVdo(true)}
          onReady={() => setInvalidVdo(false)}
        />

        {!_.isEmpty(link) && !invalidVdo && isFormatCorrect && (
          <StyledBoxVideo>
            <ReactPlayer
              light
              volume={0}
              playing={false}
              width="150px"
              height="100px"
              style={{ pointerEvents: 'none' }}
              url={isFormatCorrect ? link : ''}
            />
            <Videocam />
          </StyledBoxVideo>
        )}
      </Column>

      <Divider />
      <div>
        <CustomStatusSwitch
          labelName=""
          labelText="ใส่คำอธิบาย"
          fieldName={`contents[${index}].content.isHasDetail`}
        />
        {isHasDetail === 'ACTIVE' && (
          <Column>
            <CustomInput
              required
              labelName="คำอธิบาย"
              fieldName={`contents[${index}].content.detail`}
            />
            <Typography variant="body2" color="text.secondary">
              กรอกคำอธิบาย ภายใน 255 ตัวอักษร
            </Typography>
          </Column>
        )}
      </div>
    </Column>
  )
}

export default Link
