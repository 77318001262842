import {
  loadingDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import { store } from '../../../../App'
import { handleSuccessfulSubmission } from './handleSuccessfulSubmission'
import { postBodyMutation } from '../mutation/postBodyMutation'
import _ from 'lodash'
import {
  createBudgetManagement,
  updateBudgetManagement,
} from '../../../../services/budgetManagement'
import { handleFormSubmissionError } from '../../../../components/CRUD/handler/handleProcessSubmitForm'

export const handleProcessSubmitForm = async () => {
  store.dispatch(loadingDialog())

  const { formData } = store.getState().crud
  try {
    let response
    const body = postBodyMutation(formData)

    const uuid = _.get(formData, 'uuid', '')
    let bgmUuid = uuid

    if (uuid) {
      response = await store.dispatch(
        updateBudgetManagement({
          ...body,
          uuid,
        }),
      )
    } else {
      response = await store.dispatch(createBudgetManagement([{ ...body }]))
      bgmUuid = _.get(response, 'payload.data[0]', '')
    }

    const payload = _.get(response, 'meta.requestStatus', 'rejected')

    if (payload === 'fulfilled') {
      handleSuccessfulSubmission(bgmUuid)
    }
  } catch (error) {
    handleFormSubmissionError(error)
  } finally {
    store.dispatch(stopLoadingDialog())
  }
}
