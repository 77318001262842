import { store } from '../../../../App'
import { fetchOverview } from './fetchOverview'
import dayjs from 'dayjs'
import { setFieldValue } from '../../../../redux/slices/crud'
import _ from 'lodash'

export const handleClearFilter = (key) => {
  const { filters } = store.getState().crud.manageClassDashboard
  let countFilterTotal = 0
  if (filters?.isFilterDate) ++countFilterTotal
  if (filters?.isFilterStation) ++countFilterTotal
  if (key === 'filterDate') {
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.startDate',
        value: dayjs().startOf('month').format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.endDate',
        value: dayjs().format('YYYY-MM-DD'),
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.isClearFilter',
        value: 'date',
      }),
    )
  } else if (key === 'filterStation') {
    const user = JSON.parse(localStorage.getItem('user'))
    const userStation = _.get(user, 'area[0].station')
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.station',
        value: [
          {
            label: userStation.station,
            name: `${userStation.station.toLowerCase()}Checked`,
            uuid: userStation.uuid,
          },
        ],
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.isFilterStation',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageClassDashboard.filters.isClearFilter',
        value: 'station',
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'manageClassDashboard.filters.filterTotal',
      value: (countFilterTotal -1),
    }),
  )
  fetchOverview()
}
