import _ from 'lodash'
import { setOpenDrawer } from '../../../../../../../redux/slices/eLearning/courseForm'
import { handleChange } from '../../../events'

export const handleSubmit = (values) => (dispatch) => {
  const speaker = _.get(values, 'speaker', [])
  const admin = _.get(values, 'admin', [])
  dispatch(handleChange('setting.assignees', _.concat(speaker, admin)))
  dispatch(setOpenDrawer(false))
}
