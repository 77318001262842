import _ from 'lodash'
import * as yup from 'yup'
import { SECTION_KEY, SECTION_OBJ } from './model'

export const valueSchema = (keyValue) =>
  yup.array().of(
    yup.object().shape({
      status: yup.boolean(),
      [keyValue]: yup
        .object()
        .test('course-validate', 'กรุณาเลือก', async function (value, context) {
          const { from, parent } = context
          const tabStatus = _.get(from, '[2].value.status', false)
          const isSelected =
            !_.isEmpty(_.get(value, 'courseCode', '')) ||
            !_.isEmpty(_.get(value, 'id', ''))
          return !tabStatus || !parent.status || isSelected
        }),
    }),
  )

export const tabSchema = (keyObj) =>
  yup.array().of(
    yup.object().shape({
      [keyObj.keySub]: yup.array().of(
        yup.object().shape({
          status: yup.boolean(),
          tabTitle: yup
            .string()
            .max(255, 'ระบุข้อความความยาวไม่เกิน 255 ตัวอักษร')
            .test('text-validate', 'กรุณากรอก', (value, { parent }) => {
              return !parent.status || value
            }),
          [keyObj.keySub]: valueSchema(keyObj.keyValue),
        }),
      ),
    }),
  )

export const courseObj = SECTION_OBJ[SECTION_KEY.COURSE]
export const pathObj = SECTION_OBJ[SECTION_KEY.LEARNING_PATH]
export const collectionObj = SECTION_OBJ[SECTION_KEY.COLLECTION]

export const schema = yup.object({
  bannerSection: yup
    .array()
    .test(
      'banner-validate',
      'ส่วนนี้จำเป็นต้องเปิดการใช้งานอย่างน้อย 1 แบนเนอร์',
      (value) => value.some((item) => item.status),
    ),
  [courseObj.keyInfo]: tabSchema(courseObj),
  [pathObj.keyInfo]: tabSchema(pathObj),
  [collectionObj.keyInfo]: tabSchema(collectionObj),
})
