import React from 'react'
import { Column } from '../../../../../components/Preview/Layout/Styled'
import { SECTION_KEY } from '../../model'
import NoContentBox from './NoContentBox'
import TabContainer from './TabContainer'

const Content = () => {
  return (
    <Column>
      <Column pt={3} gap={2}>
        <NoContentBox tabTitle="" />
      </Column>
      <TabContainer keySection={SECTION_KEY.COURSE} />
      <TabContainer keySection={SECTION_KEY.LEARNING_PATH} />
      <TabContainer keySection={SECTION_KEY.COLLECTION} />
    </Column>
  )
}

export default Content
