import _ from 'lodash'

export const convertValueToNumber = (information, setValues) => {
  const {
    floor,
    width,
    length,
    height,
    sqm,
    capacityClassroom,
    capacityGroup,
    capacityUShape,
    capacityTheater,
  } = information
  let newInformation = { ...information }

  newInformation = {
    ...newInformation,
    floor: !_.isNaN(floor) && floor ? floor : null,
    width: !_.isNaN(width) && width ? width : null,
    length: !_.isNaN(length) && length ? length : null,
    height: !_.isNaN(height) && height ? height : null,
    sqm: !_.isNaN(sqm) && sqm ? sqm : null,
    capacityClassroom:
      !_.isNaN(capacityClassroom) && capacityClassroom
        ? capacityClassroom
        : null,
    capacityGroup:
      !_.isNaN(capacityGroup) && capacityGroup ? capacityGroup : null,
    capacityUShape:
      !_.isNaN(capacityUShape) && capacityUShape ? capacityUShape : null,
    capacityTheater:
      !_.isNaN(capacityTheater) && capacityTheater ? capacityTheater : null,
  }

  setValues({
    information: newInformation,
  })
}
