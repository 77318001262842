import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'

import Header from './Header'
import Information from './Information'
import Conditions from './Conditions'
import Footer from './Footer'

import { LoadingPage } from '../../ManageClass/Styled'
import { initialState } from '../../../redux/slices/masterData/licenseForm/model'
import { validateSchema } from './validateSchema'
import {
  handleFetchDataCreate,
  handleFetchDataEdit,
  handleSubmit,
} from './events'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const { isLoading, licenseConfig } = useSelector(
    (state) => ({
      isLoading: state.masterDataLicenseForm.isLoading,
      licenseConfig: state.masterDataLicenseForm.initialState.licenseConfig,
    }),
    shallowEqual
  )

  useEffect(() => {
    uuid
      ? dispatch(handleFetchDataEdit(uuid, false))
      : dispatch(handleFetchDataCreate())
  }, [uuid])

  return (
    <Box sx={{ m: 3 }}>
      <Box
        sx={{
          opacity: isLoading ? 0.4 : 'unset',
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Formik
          initialValues={{
            information: initialState.initialState.information,
            licenseConfig: initialState.initialState.licenseConfig,
          }}
          validationSchema={validateSchema(licenseConfig)}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(value) => dispatch(handleSubmit(value))}
        >
          <Form>
            <Header />
            <Information />
            <Conditions />
            <Footer />
          </Form>
        </Formik>
      </Box>

      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default Index
