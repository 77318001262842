import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import Breadcrumbs from '../../../../../components/Breadcrumbs'
import _ from 'lodash'
import { HeaderSection } from './Styled'
import { handleScroll, handleSubmit, handleView } from './events'
import { useParams } from 'react-router-dom'

const listBreadcrumbCsAgent = [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'ข้อมูล CS Agent',
    link: '/manage/cs-agent-profile',
    pointer: true,
  },
  { title: 'แก้ไขข้อมูล CS Agent', link: '/', pointer: false },
]

const EditableHeader = () => {
  const data = useSelector(
    (state) => state.agentProfile.initialState,
    shallowEqual
  )
  const { id: uuid } = useParams()

  const fullName = _.get(data, 'info.fullNameTH', '')

  const dispatch = useDispatch()

  const [headerStyle, setHeaderStyle] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll(setHeaderStyle))
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setHeaderStyle])

  return (
    <HeaderSection headerStyle={headerStyle}>
      <Box>
        <Typography variant="h4">
          {`แก้ไขข้อมูล CS Agent: ${fullName}`}
        </Typography>
        {!headerStyle && <Breadcrumbs menuList={listBreadcrumbCsAgent} />}
      </Box>

      <Box sx={{ gap: 3, display: 'flex' }}>
        <Button
          variant="outlined"
          size="m"
          onClick={() => handleView(uuid)}
          data-testid="btn-cancel"
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="m"
          onClick={() => dispatch(handleSubmit(data, uuid))}
          data-testid="btn-save"
        >
          บันทึก
        </Button>
      </Box>
    </HeaderSection>
  )
}

export default EditableHeader
