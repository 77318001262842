import React, { createRef, useRef, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import TotalScore from './TotalScore'
import MapQuestionType from './QuestionType'
import Assessment from '../Assessment'
import WarningAssessment from '../WarningAssessment'
import AddQuestion from '../AddQuestion'
import _ from 'lodash'

import { scrollSmoothHandler } from '../../event'
import { BoxQuestion } from './Styled'
import { draftQuestion } from '../../../../..//redux/slices/eTestingForm'

const Index = () => {
  const myRef = useRef({ current: [] })
  const listQuestion = useSelector(draftQuestion, shallowEqual)
  const { typeOfETesting, errorSchemaMessage } = useSelector(
    (state) => ({
      typeOfETesting: state.eTestingForm.typeOfETesting,
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (listQuestion.length > 0) {
      myRef.current = listQuestion.map(
        (_, i) => myRef.current[i] ?? createRef()
      )
    }
  }, [listQuestion])
  useEffect(() => {
    const field = _.get(errorSchemaMessage, 'field', '')
    if (field !== '' && !field.includes('TITLE'))
      scrollSmoothHandler(_.get(errorSchemaMessage, 'questionNo', -1), myRef)
  }, [errorSchemaMessage])

  return (
    <>
      {typeOfETesting !== 'ASSESSMENT' && (
        <>
          <TotalScore isAssessment={false} />
          {listQuestion.length > 0 && (
            <BoxQuestion>
              {listQuestion.map((item, index) => {
                return (
                  <div key={item} ref={myRef?.current[index]}>
                    <MapQuestionType
                      id={item}
                      key={item}
                      keyQuestion={item}
                      indexQuestion={index}
                    />
                  </div>
                )
              })}
            </BoxQuestion>
          )}
          <AddQuestion />
        </>
      )}
      {typeOfETesting === 'ASSESSMENT' && (
        <>
          <TotalScore isAssessment={true} />
          <BoxQuestion>
            <WarningAssessment />
            <Assessment />
          </BoxQuestion>
        </>
      )}
    </>
  )
}

export default Index
