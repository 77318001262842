import { setView } from '../../../../redux/slices/learningPoint/settingPoint'
import { getDetail } from '../../../../services/learningPoint/settingPoint'
import { headerConfig } from '../model'
import { startLoading, stopLoading } from '../../../../redux/slices/loading'

export const getViewData = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  let { payload } = await dispatch(getDetail({ uuid, headerConfig }))
  dispatch(setView(payload))
  dispatch(stopLoading())
}
