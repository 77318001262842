import React, { useRef, useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import _ from 'lodash'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { defaultValue } from '../../../../../constants/eTesting'
import {
  handleClose,
  handleMoreMenu,
  handleToggleDelete,
  getMoreOptionsByPermission,
} from './events'
import { StyledPopper, DialogDelete } from './Styled'
import { InputImport } from '../Styled'
import { handleImportTemplate } from '../event'

export const DialogContentDelete = () => {
  const dispatch = useDispatch()
  const [toggleDelete, setToggleDelete] = useState(false)

  return (
    <DialogDelete>
      <Typography variant="body1">
        การลบข้อมูล จะ
        <span style={{ color: 'red' }}>ไม่มีผลย้อนหลัง </span>
        กับหลักสูตร หรือคลาสที่เลือกไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
        ยังคงสามารถใช้งานต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง ที่หน้าจัดการหลักสูตร
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="error"
              checked={toggleDelete}
              onChange={(e) => dispatch(handleToggleDelete(e, setToggleDelete))}
            />
          }
          label="ฉันรับทราบเงื่อนไขการลบข้อมูลแล้ว"
        />
      </Box>
    </DialogDelete>
  )
}

const DropdownButton = ({ typeOfETesting }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    setting,
    questions,
    eTestingType,
    disable,
    totalSubmit,
    totalWaitingToCheck,
  } = useSelector(
    (state) => ({
      setting: state.eTestingForm.setting,
      questions: state.eTestingForm.questions,
      eTestingType: state.eTestingForm.typeOfETesting,
      disable: state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
      totalSubmit: _.get(state.eTestingForm, 'totalSubmit', 0),
      totalWaitingToCheck: _.get(state.eTestingForm, 'totalWaitingToCheck', 0),
    }),
    shallowEqual
  )
  const moreOptionsByPermission = getMoreOptionsByPermission(
    setting,
    questions,
    disable,
    typeOfETesting
  )
  const isStandalone = typeOfETesting === defaultValue.typeOfETesting.standalone

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        disabled={!isStandalone && disable}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem>
                  {moreOptionsByPermission.map((option, index) => {
                    const isImport = option.method === 'import'

                    // ต้องมีคนเข้าส่งแบบทดสอบนี้แล้ว และแบบทดสอบมี Freetext เท่านั้นที่กดตรวจได้
                    const disableCheck =
                      option.method === 'check' && totalSubmit === 0

                    // ต้องมีการตรวจแล้ว 1 คนขึ้นไป ปุ่มจะกดดาวน์โหลดได้
                    const canDownload =
                      totalSubmit > 0 && totalWaitingToCheck < totalSubmit
                    const disableDownload =
                      option.method === 'download' && !canDownload

                    const disableOnShareState =
                      disable &&
                      option.method !== 'check' &&
                      option.method !== 'download'

                    return (
                      (typeOfETesting != 'ASSESSMENT' || !isImport) && (
                        <label key={index} htmlFor={`menu-${option.method}`}>
                          <MenuItem
                            key={index}
                            component="span"
                            data-testid={`menu-${index}`}
                            disabled={
                              disableCheck ||
                              disableDownload ||
                              disableOnShareState
                            }
                            onClick={() =>
                              !isImport &&
                              dispatch(
                                handleMoreMenu(
                                  option.method,
                                  uuid,
                                  history,
                                  eTestingType
                                )
                              )
                            }
                          >
                            <Typography variant="body1" component="span">
                              {option.text}
                            </Typography>
                          </MenuItem>

                          {isImport && (
                            <InputImport
                              data-testid="menu-import"
                              id="menu-import"
                              accept=".xlsx, .xls, .csv"
                              type="file"
                              onChange={(dataImport) =>
                                dispatch(
                                  handleImportTemplate({
                                    dataImport,
                                    questions,
                                  })
                                )
                              }
                            />
                          )}
                        </label>
                      )
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
