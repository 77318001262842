import _ from 'lodash'
import { ROW_PAGE_PER_LIST } from '../../../../constants/table'
import { store } from '../../../../App'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../redux/slices/table'
import { filterBudgetManagement } from '../../../../services/budgetManagement'
import { handleQuickSearch } from './handleQuickSearch'
import { handleDeleteClick } from './handleDeleteClick'
import { onDownload } from './onDownload'

export const fetchBudgetManagementList = async (mutateFilterPropToBody) => {
  const { table, search, filter: filterState } = store.getState().table
  const { crudConfig } = store.getState().crud
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutateFilterPropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  store.dispatch(startLoading())
  const response = await store
    .dispatch(filterBudgetManagement(body))
    .then((res) => {
      const results = _.map(_.get(res, 'payload.data.result', []), (item) => {
        return {
          ...item,
          costCenter: _.get(item, 'costCenter.costCenter', ''),
          costCenterName: _.get(item, 'costCenter.descriptionName', ''),
          oldAccountCode: _.get(item, 'accountCode.oldAccountCode', ''),
          newAccountCode: _.get(item, 'accountCode.newAccountCode', ''),
          accCategory: _.get(item, 'accountCode.category.name', ''),
        }
      })

      store.dispatch(
        setInitialTable({
          rows: results,
          allCount: _.get(res, 'payload.data.totalCount', 0),
          headCells: crudConfig.headerCells(),
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) =>
            handleQuickSearch(tableProps, text, mutateFilterPropToBody),
          onDownload: (selected, sort, order) =>
            onDownload(selected, sort, order),
          onDelete: (selected) => {
            handleDeleteClick(selected.uuid)
          },
        }),
      )
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig.headerCells(),
        }),
      )
      return e.message?.includes('method')
    })

  if (!response) store.dispatch(stopLoading())
}
