export const initialSetting = {
  skipable: true,
  canForward: true,
  canBackward: true,
  selectable: true,
  canChangeSpeed: true,
  autoNextLesson: false,
  allowMultiScreen: null,
}

export const initialVideo = {
  playing: false,
  muted: false,
  volume: 0.5,
  played: 0,
  playedSeconds: 0,
  playbackRate: 1, // 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2
  seeking: false,
  isFinished: false,
}

export const initialState = {
  error: null,
  isLoading: false,
  isLessonLoading: false,
  isVideoLoading: false,
  isExpanded: true,
  isCourse: false, // true = course preview, false = module preview
  isFullScreen: false,
  course: null,
  lessons: [],
  lessonsTotal: 0,
  latestLesson: {},
  selectedLesson: {},
  setting: initialSetting,
}
