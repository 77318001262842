import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { useFormikContext } from 'formik'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import TextInput from '../../../../../components/Input/TextInput'
import SelectIcon from '../../../../../components/redux/SelectIcon'
import * as Styled from './Styled'
import * as event from './event'
import { listPositionEva } from '../../../../../constants/eEvaluation'
import CoverImage from './CoverImage'

const Index = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { errors } = useFormikContext()
  const { information, isClone, codeId, version, disableForm } = useSelector(
    (state) => ({
      information: state.eEvaluationForm.information,
      isClone: state.eEvaluationForm.isClone,
      codeId: state.eEvaluationForm.codeId,
      version: state.eEvaluationForm.version,
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )

  const {
    coverImage,
    name,
    coverText,
    title1,
    title2,
    description,
    title1Position,
    title2Position,
    descriptionPosition,
    loadingProgress,
    uploadError,
  } = information

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <Styled.BoxForm>
          <Styled.BoxFlex isColumn>
            <Typography variant="body2">ภาพหน้าปก</Typography>
            <Styled.BoxInfoImageEva>
              {(loadingProgress != null || coverImage) && (
                <CoverImage
                  loadingProgress={loadingProgress}
                  isError={uploadError}
                  url={coverImage}
                />
              )}
              <Styled.BoxUpload upload={loadingProgress > 0 || coverImage}>
                <event.UploadImage
                  htmlFor="btn-add-image"
                  testId="btn-add-image"
                  labelText="อัปโหลดรูปภาพ"
                  disabled={disableForm}
                  onUpload={(e) =>
                    dispatch(event.handleUploadImage(e, 'coverImage'))
                  }
                />
                {(coverImage || uploadError) && (
                  <Button
                    sx={{ mt: 0.3, minWidth: 84 }}
                    data-testid="btn-delete-image"
                    variant="outlined"
                    size="s"
                    disabled={disableForm}
                    onClick={() => dispatch(event.handleDeleteImage())}
                  >
                    ลบรูปภาพ
                  </Button>
                )}

                <Typography variant="body2" sx={{ mt: 0.1 }}>
                  ขนาดแนะนำ 1,240x465px, นามสกุลไฟล์ที่รองรับ .jpg, .jpeg .png
                </Typography>
              </Styled.BoxUpload>
            </Styled.BoxInfoImageEva>
          </Styled.BoxFlex>

          {!isClone && uuid && (
            <Styled.BoxFlex>
              <TextInput
                id="codeId"
                name="codeId"
                type="basic"
                labelText="ID"
                required
                disabled={true}
                value={codeId}
              />
              <TextInput
                id="version"
                name="version"
                type="basic"
                labelText="เวอร์ชั่น"
                required
                disabled={true}
                value={version == 0 ? '' : version}
              />
            </Styled.BoxFlex>
          )}
          <Styled.BoxFlex>
            <TextInput
              id="name"
              name="information.name"
              labelText="ชื่อแบบประเมิน"
              type="basic"
              required
              disabled={disableForm}
              onBlur={(e) =>
                dispatch(
                  event.handleChangeInformation({
                    value: _.get(e, 'target.value', ''),
                    key: 'name',
                  })
                )
              }
              defaultValue={name}
              textError={_.get(errors, 'information.name', '')}
            />
            <TextInput
              id="coverText"
              name="coverText"
              labelText="ข้อความหน้าปก"
              type="basic"
              disabled={disableForm}
              onBlur={(e) => {
                dispatch(
                  event.handleChangeInformation({
                    value: _.get(e, 'target.value', ''),
                    key: 'coverText',
                  })
                )
              }}
              defaultValue={coverText}
              textError={_.get(errors, 'information.coverText', '')}
            />
          </Styled.BoxFlex>

          <Styled.BoxFlex isAlignItems>
            <TextInput
              id="titleOne"
              name="titleOne"
              labelText="หัวข้อ 1"
              type="basic"
              disabled={disableForm}
              onBlur={(e) =>
                dispatch(
                  event.handleChangeInformation({
                    value: _.get(e, 'target.value', ''),
                    key: 'title1',
                  })
                )
              }
              defaultValue={title1}
              textError={_.get(errors, 'information.title1', '')}
            />
            <Styled.BoxFlexSelect>
              <SelectIcon
                dataTestId="select-positionOne"
                labelText="ตำแหน่ง"
                disabled={disableForm}
                listOption={listPositionEva}
                handleChange={(e) =>
                  dispatch(
                    event.handleChangeInformation({
                      value: _.get(e, 'target.value', ''),
                      key: 'title1Position',
                    })
                  )
                }
                value={title1Position}
              />
            </Styled.BoxFlexSelect>
          </Styled.BoxFlex>
          <Styled.BoxFlex>
            <TextInput
              id="titleTwo"
              name="titleTwo"
              labelText="หัวข้อ 2"
              type="basic"
              disabled={disableForm}
              onBlur={(e) =>
                dispatch(
                  event.handleChangeInformation({
                    value: _.get(e, 'target.value', ''),
                    key: 'title2',
                  })
                )
              }
              defaultValue={title2}
              textError={_.get(errors, 'information.title2', '')}
            />

            <Styled.BoxFlexSelect>
              <SelectIcon
                dataTestId="select-positionTwo"
                labelText="ตำแหน่ง"
                disabled={disableForm}
                listOption={listPositionEva}
                handleChange={(e) =>
                  dispatch(
                    event.handleChangeInformation({
                      value: _.get(e, 'target.value', ''),
                      key: 'title2Position',
                    })
                  )
                }
                value={title2Position}
              />
            </Styled.BoxFlexSelect>
          </Styled.BoxFlex>

          <Styled.BoxFlex>
            <TextInput
              id="description"
              name="description"
              labelText="รายละเอียด"
              type="basic"
              multiline={true}
              rows={2}
              disabled={disableForm}
              onBlur={(e) =>
                dispatch(
                  event.handleChangeInformation({
                    value: _.get(e, 'target.value', ''),
                    key: 'description',
                  })
                )
              }
              defaultValue={description}
              textError={_.get(errors, 'information.description', '')}
            />
            <Styled.BoxFlexSelect>
              <SelectIcon
                dataTestId="select-description"
                labelText="ตำแหน่ง"
                disabled={disableForm}
                listOption={listPositionEva}
                handleChange={(e) =>
                  dispatch(
                    event.handleChangeInformation({
                      value: _.get(e, 'target.value', ''),
                      key: 'descriptionPosition',
                    })
                  )
                }
                value={descriptionPosition}
              />
            </Styled.BoxFlexSelect>
          </Styled.BoxFlex>
        </Styled.BoxForm>
      </Card>
    </>
  )
}

export default Index
