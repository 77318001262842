import { Header } from './Header'
import { StyledContainer } from './Styled'
import { useState, useEffect, useRef } from 'react'
import DashboardSection from './DashboardSection'
import { BookingRoomSection } from './BookingRoomSection'
import { useDispatch } from 'react-redux'
import { setStationUuid } from '../../redux/slices/roomManagement/Dashboard'
import _ from 'lodash'

const RoomManagement = () => {
  const dispatch = useDispatch()
  const bookingRoomRef = useRef()
  const [isShowDashboardSection, setIsShowDashboardSection] = useState(true)

  useEffect(() => {
    const user = localStorage.getItem('user')
    const objUser = JSON.parse(user)
    const defaultStationUuid = _.get(objUser, 'stationUuid', '')
    dispatch(setStationUuid(defaultStationUuid))
  }, [])

  return (
    <StyledContainer>
      <Header
        isShowDashboardSection={isShowDashboardSection}
        setIsShowDashboardSection={setIsShowDashboardSection}
      />
      {isShowDashboardSection && (
        <DashboardSection bookingRoomRef={bookingRoomRef} />
      )}
      <BookingRoomSection bookingRoomRef={bookingRoomRef} />
    </StyledContainer>
  )
}
export default RoomManagement
