import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  staffProfileInArea,
  monthlyPlanChangeSpeaker,
  monthlyPlanChangeSpeakerHistory,
} from '../../utils/apiPath'

export const getStaffInArea = createAsyncThunk(
  'monthlyPlan/getStaffInArea',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${staffProfileInArea}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putMonthlyPlanChangeSpeaker = createAsyncThunk(
  'monthlyPlan/changeSpeaker',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(monthlyPlanChangeSpeaker, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getHistory = createAsyncThunk(
  'monthlyPlan/changeSpeakerHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${monthlyPlanChangeSpeakerHistory}${url}`,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
