import _ from 'lodash'

export const getLearnerList = (learners, tab, search) => {
  const learnerList = getLearnerTab(learners, tab)

  if (search === '') return learnerList

  return learnerList.filter(
    (user) =>
      isIncluded(user.firstNameTH, search) ||
      isIncluded(user.lastNameTH, search) ||
      isIncluded(user.userCode, search),
  )
}

export const isIncluded = (value, target) => {
  return _.lowerCase(value || '').includes(_.lowerCase(target))
}

export const getLearnerTab = (learners, tab) => {
  const { learnerAll, learnerWait, learnerPass, learnerFail, learnerNotSent } =
    learners
  switch (tab) {
    case 'ALL':
      return learnerAll
    case 'WAITING':
      return learnerWait
    case 'PASS':
      return learnerPass
    case 'FAIL':
      return learnerFail
    case 'NOT_SENT':
      return learnerNotSent
    default:
      return learnerAll
  }
}
