import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportAgentProfileForTrainerConst = {
  COLUMN_NAME: {
    NO: 'no',
    FULL_AGENT_CODE: 'fullAgentCode',
    AGENT_CODE: 'agentCode',
    PREFIX: 'prefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    AGENT_TYPE: 'agentType',
    LEVEL: 'level',
    WORK_START: 'workStart',
    UNIT_NUMBER: 'unitNumber',
    UNIT_NAME: 'unitName',
    GA_CODE: 'gaCode',
    TERRITORY_CODE: 'territoryCode',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    LICENSE_CATEGORY_1: 'licenseCategory_1',
    LICENSE_NAME_1: 'licenseName_1',
    EFFECTIVE_DATE_1: 'effectiveDate_1',
    EXPIRY_DATE_1: 'expiryDate_1',
    LICENSE_CATEGORY_2: 'licenseCategory_2',
    LICENSE_NAME_2: 'licenseName_2',
    EFFECTIVE_DATE_2: 'effectiveDate_2',
    EXPIRY_DATE_2: 'expiryDate_2',
    LICENSE_CATEGORY_3: 'licenseCategory_3',
    LICENSE_NAME_3: 'licenseName_3',
    EFFECTIVE_DATE_3: 'effectiveDate_3',
    EXPIRY_DATE_3: 'expiryDate_3',
    LICENSE_CATEGORY_4: 'licenseCategory_4',
    LICENSE_NAME_4: 'licenseName_4',
    EFFECTIVE_DATE_4: 'effectiveDate_4',
    EXPIRY_DATE_4: 'expiryDate_4',
    LICENSE_CATEGORY_5: 'licenseCategory_5',
    LICENSE_NAME_5: 'licenseName_5',
    EFFECTIVE_DATE_5: 'effectiveDate_5',
    EXPIRY_DATE_5: 'expiryDate_5',
    LICENSE_CATEGORY_6: 'licenseCategory_6',
    LICENSE_NAME_6: 'licenseName_6',
    EFFECTIVE_DATE_6: 'effectiveDate_6',
    EXPIRY_DATE_6: 'expiryDate_6',
    LICENSE_CATEGORY_7: 'licenseCategory_7',
    LICENSE_NAME_7: 'licenseName_7',
    EFFECTIVE_DATE_7: 'effectiveDate_7',
    EXPIRY_DATE_7: 'expiryDate_7',
    LICENSE_CATEGORY_8: 'licenseCategory_8',
    LICENSE_NAME_8: 'licenseName_8',
    EFFECTIVE_DATE_8: 'effectiveDate_8',
    EXPIRY_DATE_8: 'expiryDate_8',
    LICENSE_CATEGORY_9: 'licenseCategory_9',
    LICENSE_NAME_9: 'licenseName_9',
    EFFECTIVE_DATE_9: 'effectiveDate_9',
    EXPIRY_DATE_9: 'expiryDate_9',
    LICENSE_CATEGORY_10: 'licenseCategory_10',
    LICENSE_NAME_10: 'licenseName_10',
    EFFECTIVE_DATE_10: 'effectiveDate_10',
    EXPIRY_DATE_10: 'expiryDate_10',
    LICENSE_CATEGORY_11: 'licenseCategory_11',
    LICENSE_NAME_11: 'licenseName_11',
    EFFECTIVE_DATE_11: 'effectiveDate_11',
    EXPIRY_DATE_11: 'expiryDate_11',
    LICENSE_CATEGORY_12: 'licenseCategory_12',
    LICENSE_NAME_12: 'licenseName_12',
    EFFECTIVE_DATE_12: 'effectiveDate_12',
    EXPIRY_DATE_12: 'expiryDate_12',
    LICENSE_CATEGORY_13: 'licenseCategory_13',
    LICENSE_NAME_13: 'licenseName_13',
    EFFECTIVE_DATE_13: 'effectiveDate_13',
    EXPIRY_DATE_13: 'expiryDate_13',
    LICENSE_CATEGORY_14: 'licenseCategory_14',
    LICENSE_NAME_14: 'licenseName_14',
    EFFECTIVE_DATE_14: 'effectiveDate_14',
    EXPIRY_DATE_14: 'expiryDate_14',
    LICENSE_CATEGORY_15: 'licenseCategory_15',
    LICENSE_NAME_15: 'licenseName_15',
    EFFECTIVE_DATE_15: 'effectiveDate_15',
    EXPIRY_DATE_15: 'expiryDate_15',
    LICENSE_CATEGORY_16: 'licenseCategory_16',
    LICENSE_NAME_16: 'licenseName_16',
    EFFECTIVE_DATE_16: 'effectiveDate_16',
    EXPIRY_DATE_16: 'expiryDate_16',
    LICENSE_CATEGORY_17: 'licenseCategory_17',
    LICENSE_NAME_17: 'licenseName_17',
    EFFECTIVE_DATE_17: 'effectiveDate_17',
    EXPIRY_DATE_17: 'expiryDate_17',
    LICENSE_CATEGORY_18: 'licenseCategory_18',
    LICENSE_NAME_18: 'licenseName_18',
    EFFECTIVE_DATE_18: 'effectiveDate_18',
    EXPIRY_DATE_18: 'expiryDate_18',
    LICENSE_CATEGORY_19: 'licenseCategory_19',
    LICENSE_NAME_19: 'licenseName_19',
    EFFECTIVE_DATE_19: 'effectiveDate_19',
    EXPIRY_DATE_19: 'expiryDate_19',
    LICENSE_CATEGORY_20: 'licenseCategory_20',
    LICENSE_NAME_20: 'licenseName_20',
    EFFECTIVE_DATE_20: 'effectiveDate_20',
    EXPIRY_DATE_20: 'expiryDate_20',
    // filter
    SRAM: 'sram',
    LICENSE_CATEGORY: 'licenseCategory',
    LICENSE_NAME: 'licenseName',
    EFFECTIVE_DATE: 'effectiveDate',
    EXPIRY_DATE: 'expiryDate',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    FULL_AGENT_CODE: 'รหัส Agent แบบเต็ม',
    AGENT_CODE: 'รหัส Agent',
    PREFIX: 'Title Name EN',
    FIRST_NAME: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    AGENT_TYPE: 'ประเภท Agent',
    LEVEL: 'ระดับผู้เรียน',
    WORK_START: 'วันเริ่มงาน',
    UNIT_NUMBER: 'Unit Number',
    UNIT_NAME: 'ชื่อ Unit',
    GA_CODE: 'GA Code',
    TERRITORY_CODE: 'Territory Code',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    LICENSE_CATEGORY_1: 'ประเภทใบอนุญาต 1',
    LICENSE_NAME_1: 'ชื่่อใบอนุญาต 1',
    EFFECTIVE_DATE_1: 'วันมีผลบังคับ 1',
    EXPIRY_DATE_1: 'วันหมดอายุ 1',
    LICENSE_CATEGORY_2: 'ประเภทใบอนุญาต 2',
    LICENSE_NAME_2: 'ชื่่อใบอนุญาต 2',
    EFFECTIVE_DATE_2: 'วันมีผลบังคับ 2',
    EXPIRY_DATE_2: 'วันหมดอายุ 2',
    LICENSE_CATEGORY_3: 'ประเภทใบอนุญาต 3',
    LICENSE_NAME_3: 'ชื่่อใบอนุญาต 3',
    EFFECTIVE_DATE_3: 'วันมีผลบังคับ 3',
    EXPIRY_DATE_3: 'วันหมดอายุ 3',
    LICENSE_CATEGORY_4: 'ประเภทใบอนุญาต 4',
    LICENSE_NAME_4: 'ชื่่อใบอนุญาต 4',
    EFFECTIVE_DATE_4: 'วันมีผลบังคับ 4',
    EXPIRY_DATE_4: 'วันหมดอายุ 4',
    LICENSE_CATEGORY_5: 'ประเภทใบอนุญาต 5',
    LICENSE_NAME_5: 'ชื่่อใบอนุญาต 5',
    EFFECTIVE_DATE_5: 'วันมีผลบังคับ 5',
    EXPIRY_DATE_5: 'วันหมดอายุ 5',
    LICENSE_CATEGORY_6: 'ประเภทใบอนุญาต 6',
    LICENSE_NAME_6: 'ชื่่อใบอนุญาต 6',
    EFFECTIVE_DATE_6: 'วันมีผลบังคับ 6',
    EXPIRY_DATE_6: 'วันหมดอายุ 6',
    LICENSE_CATEGORY_7: 'ประเภทใบอนุญาต 7',
    LICENSE_NAME_7: 'ชื่่อใบอนุญาต 7',
    EFFECTIVE_DATE_7: 'วันมีผลบังคับ 7',
    EXPIRY_DATE_7: 'วันหมดอายุ 7',
    LICENSE_CATEGORY_8: 'ประเภทใบอนุญาต 8',
    LICENSE_NAME_8: 'ชื่่อใบอนุญาต 8',
    EFFECTIVE_DATE_8: 'วันมีผลบังคับ 8',
    EXPIRY_DATE_8: 'วันหมดอายุ 8',
    LICENSE_CATEGORY_9: 'ประเภทใบอนุญาต 9',
    LICENSE_NAME_9: 'ชื่่อใบอนุญาต 9',
    EFFECTIVE_DATE_9: 'วันมีผลบังคับ 9',
    EXPIRY_DATE_9: 'วันหมดอายุ 9',
    LICENSE_CATEGORY_10: 'ประเภทใบอนุญาต 10',
    LICENSE_NAME_10: 'ชื่่อใบอนุญาต 10',
    EFFECTIVE_DATE_10: 'วันมีผลบังคับ 10',
    EXPIRY_DATE_10: 'วันหมดอายุ 10',
    LICENSE_CATEGORY_11: 'ประเภทใบอนุญาต 11',
    LICENSE_NAME_11: 'ชื่่อใบอนุญาต 11',
    EFFECTIVE_DATE_11: 'วันมีผลบังคับ 11',
    EXPIRY_DATE_11: 'วันหมดอายุ 11',
    LICENSE_CATEGORY_12: 'ประเภทใบอนุญาต 12',
    LICENSE_NAME_12: 'ชื่่อใบอนุญาต 12',
    EFFECTIVE_DATE_12: 'วันมีผลบังคับ 12',
    EXPIRY_DATE_12: 'วันหมดอายุ 12',
    LICENSE_CATEGORY_13: 'ประเภทใบอนุญาต 13',
    LICENSE_NAME_13: 'ชื่่อใบอนุญาต 13',
    EFFECTIVE_DATE_13: 'วันมีผลบังคับ 13',
    EXPIRY_DATE_13: 'วันหมดอายุ 13',
    LICENSE_CATEGORY_14: 'ประเภทใบอนุญาต 14',
    LICENSE_NAME_14: 'ชื่่อใบอนุญาต 14',
    EFFECTIVE_DATE_14: 'วันมีผลบังคับ 14',
    EXPIRY_DATE_14: 'วันหมดอายุ 14',
    LICENSE_CATEGORY_15: 'ประเภทใบอนุญาต 15',
    LICENSE_NAME_15: 'ชื่่อใบอนุญาต 15',
    EFFECTIVE_DATE_15: 'วันมีผลบังคับ 15',
    EXPIRY_DATE_15: 'วันหมดอายุ 15',
    LICENSE_CATEGORY_16: 'ประเภทใบอนุญาต 16',
    LICENSE_NAME_16: 'ชื่่อใบอนุญาต 16',
    EFFECTIVE_DATE_16: 'วันมีผลบังคับ 16',
    EXPIRY_DATE_16: 'วันหมดอายุ 16',
    LICENSE_CATEGORY_17: 'ประเภทใบอนุญาต 17',
    LICENSE_NAME_17: 'ชื่่อใบอนุญาต 17',
    EFFECTIVE_DATE_17: 'วันมีผลบังคับ 17',
    EXPIRY_DATE_17: 'วันหมดอายุ 17',
    LICENSE_CATEGORY_18: 'ประเภทใบอนุญาต 18',
    LICENSE_NAME_18: 'ชื่่อใบอนุญาต 18',
    EFFECTIVE_DATE_18: 'วันมีผลบังคับ 18',
    EXPIRY_DATE_18: 'วันหมดอายุ 18',
    LICENSE_CATEGORY_19: 'ประเภทใบอนุญาต 19',
    LICENSE_NAME_19: 'ชื่่อใบอนุญาต 19',
    EFFECTIVE_DATE_19: 'วันมีผลบังคับ 19',
    EXPIRY_DATE_19: 'วันหมดอายุ 19',
    LICENSE_CATEGORY_20: 'ประเภทใบอนุญาต 20',
    LICENSE_NAME_20: 'ชื่่อใบอนุญาต 20',
    EFFECTIVE_DATE_20: 'วันมีผลบังคับ 20',
    EXPIRY_DATE_20: 'วันหมดอายุ 20',
    //filter column
    SRAM: 'SRAM',
    LICENSE_CATEGORY: 'ประเภทใบอนุญาต',
    LICENSE_NAME: 'ชื่่อใบอนุญาต',
    EFFECTIVE_DATE: 'วันมีผลบังคับ',
    EXPIRY_DATE: 'วันหมดอายุ',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  agentTypeOptions: [
    { label: 'A: Agent', value: 'A: Agent' },
    { label: 'L: License Broker', value: 'L: License Broker' },
  ],
}

export const reportAgentProfileForTrainerDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.NO,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.NO,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_TYPE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_TYPE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LEVEL,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LEVEL,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LEVEL,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.WORK_START,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.WORK_START,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.SRAM,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.SRAM,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.SRAM,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.GA_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.GA_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.TERRITORY_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.TERRITORY_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.TERRITORY_CODE,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.MOBILE_NO,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.MOBILE_NO,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EMAIL,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EMAIL,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_1,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_1,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_1,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_1,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_1,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_1,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_1,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_1,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_1,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_1,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_1,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_1,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_2,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_2,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_2,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_2,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_2,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_2,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_2,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_2,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_2,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_2,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_2,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_2,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_3,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_3,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_3,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_3,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_3,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_3,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_3,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_3,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_3,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_3,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_3,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_3,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_4,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_4,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_4,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_4,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_4,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_4,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_4,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_4,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_4,
      minWidth: 160,
    },
    {
      id: 38,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_4,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_4,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_4,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_5,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_5,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_5,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_5,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_5,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_5,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_5,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_5,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_5,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_5,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_5,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_5,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_6,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_6,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_6,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_6,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_6,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_6,
      minWidth: 160,
    },
    {
      id: 45,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_6,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_6,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_6,
      minWidth: 160,
    },
    {
      id: 46,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_6,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_6,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_6,
      minWidth: 160,
    },
    {
      id: 47,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_7,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_7,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_7,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_7,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_7,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_7,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_7,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_7,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_7,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_7,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_7,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_7,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_8,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_8,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_8,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_8,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_8,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_8,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_8,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_8,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_8,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_8,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_8,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_8,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_9,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_9,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_9,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_9,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_9,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_9,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_9,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_9,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_9,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_9,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_9,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_9,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_10,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_10,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_10,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_10,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_10,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_10,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_10,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_10,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_10,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_10,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_10,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_10,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_11,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_11,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_11,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_11,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_11,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_11,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_11,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_11,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_11,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_11,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_11,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_11,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_12,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_12,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_12,
      minWidth: 160,
    },
    {
      id: 68,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_12,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_12,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_12,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_12,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_12,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_12,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_12,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_12,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_12,
      minWidth: 160,
    },
    {
      id: 71,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_13,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_13,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_13,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_13,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_13,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_13,
      minWidth: 160,
    },
    {
      id: 73,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_13,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_13,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_13,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_13,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_13,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_13,
      minWidth: 160,
    },
    {
      id: 75,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_14,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_14,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_14,
      minWidth: 160,
    },
    {
      id: 76,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_14,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_14,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_14,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_14,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_14,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_14,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_14,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_14,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_14,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_15,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_15,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_15,
      minWidth: 160,
    },
    {
      id: 80,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_15,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_15,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_15,
      minWidth: 160,
    },
    {
      id: 81,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_15,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_15,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_15,
      minWidth: 160,
    },
    {
      id: 82,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_15,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_15,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_15,
      minWidth: 160,
    },
    {
      id: 83,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_16,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_16,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_16,
      minWidth: 160,
    },
    {
      id: 84,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_16,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_16,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_16,
      minWidth: 160,
    },
    {
      id: 85,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_16,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_16,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_16,
      minWidth: 160,
    },
    {
      id: 86,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_16,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_16,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_16,
      minWidth: 160,
    },
    {
      id: 87,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_17,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_17,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_17,
      minWidth: 160,
    },
    {
      id: 88,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_17,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_17,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_17,
      minWidth: 160,
    },
    {
      id: 89,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_17,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_17,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_17,
      minWidth: 160,
    },
    {
      id: 90,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_17,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_17,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_17,
      minWidth: 160,
    },
    {
      id: 91,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_18,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_18,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_18,
      minWidth: 160,
    },
    {
      id: 92,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_18,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_18,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_18,
      minWidth: 160,
    },
    {
      id: 93,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_18,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_18,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_18,
      minWidth: 160,
    },
    {
      id: 94,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_18,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_18,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_18,
      minWidth: 160,
    },
    {
      id: 95,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_19,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_19,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_19,
      minWidth: 160,
    },
    {
      id: 96,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_19,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_19,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_19,
      minWidth: 160,
    },
    {
      id: 97,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_19,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_19,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_19,
      minWidth: 160,
    },
    {
      id: 98,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_19,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_19,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_19,
      minWidth: 160,
    },
    {
      id: 99,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_20,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY_20,
      label:
        reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY_20,
      minWidth: 160,
    },
    {
      id: 100,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_20,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME_20,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME_20,
      minWidth: 160,
    },
    {
      id: 101,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_20,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE_20,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE_20,
      minWidth: 160,
    },
    {
      id: 102,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_20,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE_20,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE_20,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FULL_AGENT_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FULL_AGENT_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FULL_AGENT_CODE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.AGENT_CODE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.PREFIX_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_TYPE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.AGENT_TYPE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.AGENT_TYPE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportAgentProfileForTrainerConst.agentTypeOptions,
    },
    {
      id: 10,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LEVEL,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LEVEL,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LEVEL,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 11,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.WORK_START,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.WORK_START,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.WORK_START,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 15,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.UNIT_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 17,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.SRAM,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.SRAM,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.SRAM,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.GA_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.GA_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.GA_CODE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },

    {
      id: 19,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.TERRITORY_CODE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.TERRITORY_CODE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.TERRITORY_CODE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.MOBILE_NO,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.MOBILE_NO,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EMAIL,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EMAIL,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_CATEGORY,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_CATEGORY,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 24,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.LICENSE_NAME,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName:
        reportAgentProfileForTrainerConst.COLUMN_NAME.LICENSE_NAME,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 25,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EFFECTIVE_DATE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EFFECTIVE_DATE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 26,
      name: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE,
      value: reportAgentProfileForTrainerConst.COLUMN_NAME.EXPIRY_DATE,
      label: reportAgentProfileForTrainerConst.COLUMN_NAME_TH.EXPIRY_DATE,
      minWidth: 160,
      type: reportAgentProfileForTrainerConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
