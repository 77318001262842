import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import TruncateMarkup from 'react-truncate-markup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from '../../../../../../../utils/lib'
import { Column, Row } from '../../../Styled'
import Tabs from './Tabs'
import { StyledLeftWrapper } from './Styled'

const LeftContent = () => {
  const data = useSelector(
    (state) => state.eLearningPathPreview.course,
    shallowEqual,
  )
  const [isShow, setShow] = useState(false)
  const description = _.get(data, 'description', '-') || '-'
  const width = { lg: 662, md: '100%' }
  const isLike = _.get(data, 'isLike', false)
  const icon = isLike ? 'heart_fill' : 'heart'
  const isNotSm = isMediaWidthMoreThan('sm')
  const isLg = isMediaWidthMoreThan('lg')
  const fontVariant = isLg || !isNotSm ? 'body1' : 'avatarletter'
  return (
    <StyledLeftWrapper width={width}>
      <Column gap={1}>
        <Row sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography variant={isNotSm ? 'h5' : 'body1b'} color="primary">
            รายละเอียดหลักสูตร
          </Typography>

          {isNotSm && (
            <Button
              startIcon={
                <img src={`/icon/ic_${icon}.svg`} width={24} height={20} />
              }
            >
              รายการโปรด
            </Button>
          )}
        </Row>
        {isShow ? (
          <Typography variant={fontVariant} color="text.secondary">
            {description}
          </Typography>
        ) : (
          <TruncateMarkup
            lines={5}
            ellipsis={() => readMoreEllipsis(setShow, isLg)}
          >
            <Typography variant={fontVariant} color="text.secondary">
              {description}
            </Typography>
          </TruncateMarkup>
        )}
      </Column>

      <Tabs />
    </StyledLeftWrapper>
  )
}

export default LeftContent

export const readMoreEllipsis = (setShow, isLg) => (
  <span>
    ...{' '}
    <Typography
      variant={isLg ? 'body1b' : 'h6'}
      color="primary"
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => setShow(true)}
    >
      ดูเพิ่ม
    </Typography>
  </span>
)
