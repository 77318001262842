import React, { useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import { Column, Row } from '../../../../../../../components/Layout/Styled'
import { ImageDisplay } from '../../../../../../../modules/ELearning/Course/Learner/AttendanceTab/FaceHistory'
import { StyledImgBox } from './Styled'
import RekognitionDialog from './RekognitionDialog'

const LearnerRekognition = ({ row }) => {
  const [isOpen, setOpen] = useState(false)
  const learnerRekognition = _.get(row, 'learnerRekognition', null)
  const idCardImageKey = _.get(
    learnerRekognition,
    'eLearningIdCardEnroll.idCardImageKey',
    '',
  )
  const faceImageKey = _.get(learnerRekognition, 'faceImageKey', '')
  const isFaceNotMatch = _.get(learnerRekognition, 'isFaceNotMatch', false)
  const isChecked = _.get(row, 'isChecked', false)
  const color = isFaceNotMatch ? 'error.main' : 'success.main'

  if (_.isEmpty(learnerRekognition)) return <Typography>-</Typography>
  return (
    <>
      <Column sx={{ alignItems: 'center', gap: 1, py: 2 }}>
        <Row sx={{ gap: 1, cursor: 'pointer' }} onClick={() => setOpen(true)}>
          <StyledImgBox sx={{ borderColor: color }}>
            <ImageDisplay imageKey={idCardImageKey} />
          </StyledImgBox>
          <StyledImgBox sx={{ borderColor: color }} isFace>
            <ImageDisplay imageKey={faceImageKey} />
          </StyledImgBox>
        </Row>
        <Row sx={{ gap: 1, justifyContent: 'center' }}>
          {isChecked && <CheckCircleOutline color="success" />}
          <Typography color={isFaceNotMatch ? 'error.main' : 'success.main'}>
            {_.get(learnerRekognition, 'percent', '')}%
          </Typography>
        </Row>
      </Column>
      <RekognitionDialog
        isOpen={isOpen}
        handleClose={() => setOpen(false)}
        data={row}
      />
    </>
  )
}

export default LearnerRekognition
