import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Typography } from '@mui/material'

const ActiveSwitch = ({ sx, name, checked, onChange, dataTestId }) => {
  return (
    <FormControlLabel
      sx={{ mx: 0, ...sx }}
      control={
        <Switch
          name={name}
          checked={checked}
          onChange={onChange}
          data-testid={dataTestId}
        />
      }
      label={
        <Typography
          sx={{
            whiteSpace: `nowrap`,
            overflow: `hidden`,
            textOverflow: `ellipsis`,
          }}
        >
          การเปิดใช้งาน
        </Typography>
      }
    />
  )
}

export default ActiveSwitch
