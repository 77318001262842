import _ from 'lodash'
import screenfull from 'screenfull'
import {
  setFullScreen,
  setVideoState,
} from '../../../../../../../redux/slices/eLearning/preview'

export const formatTime = (time) => {
  if (isNaN(time)) return '0:00'

  const date = new Date(time.toFixed() * 1000)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`
  } else return `${minutes}:${seconds}`
}

export const handlePlayPause = (eventProps) => (dispatch) => {
  const { videoState, setSecond } = eventProps
  if (!videoState.seeking) {
    dispatch(setVideoState({ playing: !videoState.playing, isFinished: false }))

    const playedSeconds = _.get(videoState, 'playedSeconds', 0)
    setSecond(_.floor(playedSeconds))
  }
}

export const handleSeekMouseDown = (setting) => (dispatch) => {
  const { canForward, canBackward } = setting
  if (canForward && canBackward) {
    dispatch(setVideoState({ seeking: true }))
  }
}

export const handleSeeking = (value, setting) => (dispatch) => {
  const { canForward, canBackward } = setting
  if (canForward && canBackward) {
    dispatch(setVideoState({ played: parseFloat(value) / 100 }))
  }
}

export const handleSeekEnd = (value, groupProps) => (dispatch) => {
  const { setSecond, videoPlayerRef, duration, setting } = groupProps
  const { canForward, canBackward } = setting
  if (canForward && canBackward) {
    videoPlayerRef.current.seekTo(value / 100)
    const playedSeconds = _.floor((value / 100) * duration)
    dispatch(setVideoState({ seeking: false, playedSeconds }))
    setSecond(playedSeconds)
  }
}

export const handleFastForward = (props, addSec) => (dispatch) => {
  const { videoPlayerRef, current, duration, setSecond } = props
  const second = current + addSec
  const destinationSec = second > duration ? duration : second
  videoPlayerRef.current.seekTo(destinationSec)
  dispatch(setVideoState({ playing: true, playedSeconds: destinationSec }))
  setSecond(_.floor(destinationSec))
}

export const handleFastRewind = (props, subSec) => (dispatch) => {
  const { videoPlayerRef, current, setSecond } = props
  const second = current - subSec
  const destinationSec = second < 0 ? 0 : second
  videoPlayerRef.current.seekTo(destinationSec)
  dispatch(setVideoState({ playing: true, playedSeconds: destinationSec }))
  setSecond(_.floor(destinationSec))
}

export const handleVolumeChange = (value) => (dispatch) => {
  const newVolume = parseFloat(value) / 100
  // volume === 0 then muted
  dispatch(setVideoState({ volume: newVolume, muted: Number(newVolume) === 0 }))
}

export const handleMute = (videoState) => (dispatch) => {
  dispatch(setVideoState({ muted: !videoState.muted }))
}

export const handleFullscreen = (wrapperRef, isNotSm) => (dispatch) => {
  const elem = wrapperRef.current
  if (!elem) return

  dispatch(setFullScreen())
  if (isNotSm) screenfull.toggle(elem)
}

export const handleHoverOnVideo = (controlRef) => {
  let timeout = 0

  const displayOpa = () => {
    if (!controlRef.current) return
    controlRef.current.style.opacity = 1
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      controlRef.current.style.opacity = 0
    }, 1000)
  }

  controlRef.current.addEventListener('mousemove', displayOpa)
  return () => {
    controlRef.current.removeEventListener('mousemove', displayOpa)
  }
}

export const handleKeyDown = (props) => (dispatch) => {
  const { videoProps, timeProps, volume, setting } = props
  const { duration } = timeProps

  if (_.isNil(duration)) return

  const onKeyDown = (e) => {
    const keySpace = 32
    const keyLeft = 37
    const keyUp = 38
    const keyRight = 39
    const keyDown = 40
    if (
      (e.keyCode === keySpace ||
        e.keyCode === keyUp ||
        e.keyCode === keyDown) &&
      e.target === document.body
    ) {
      e.preventDefault()
    }

    if (e.keyCode === keySpace) dispatch(handlePlayPause(videoProps))

    if (setting.canBackward && setting.canForward) {
      if (e.keyCode === keyLeft) dispatch(handleFastRewind(timeProps, 5))
      if (e.keyCode === keyRight) dispatch(handleFastForward(timeProps, 5))
    }

    if (e.keyCode === keyUp) {
      const finalVol = volume * 100 + 5
      dispatch(handleVolumeChange(finalVol > 100 ? 100 : finalVol))
    }
    if (e.keyCode === keyDown) {
      const finalVol = volume * 100 - 5
      dispatch(handleVolumeChange(finalVol < 0 ? 0 : finalVol))
    }
  }

  document.addEventListener('keydown', onKeyDown)
  return () => {
    document.removeEventListener('keydown', onKeyDown)
  }
}
