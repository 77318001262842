import _ from 'lodash'
import { getConfigState } from '../../../../../modules/ELearning/events'

export const handleAutocompleteDefault = (option, uuid) => {
  const value = option.find((item) => item.value === uuid)
  if (_.isUndefined(value)) return { label: '', value: '' }
  return value
}

export const getMaxFileSize = () => {
  return getConfigState('EL_UPLOAD_MAX_FILE_SIZE')
}
