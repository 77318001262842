import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import SelectIcon from '../../../../../../components/redux/SelectIcon'
import { BoxSwap } from './Styled'

import { optionQuestionSwap, handleSwap, mapOptionPosition } from './event'

const Index = ({ keyQuestion, isTest }) => {
  const dispatch = useDispatch()
  const { questions, swapQuestion } = useSelector(
    (state) => ({
      questions: state.eTestingForm.questions,
      swapQuestion: state.eTestingForm.swapQuestion,
    }),
    shallowEqual
  )

  const optionPosition = mapOptionPosition({
    keyQuestion,
    questions,
  })

  useEffect(() => {
    dispatch(
      handleSwap({
        key: 'swapQuestion.position',
        value: _.get(optionPosition, '[0].value', 'BEFORE'),
      })
    )
  }, [optionPosition])

  const optionSwaps = optionQuestionSwap(
    questions,
    keyQuestion,
    _.get(swapQuestion, 'position', 'BEFORE'),
    isTest
  )

  return (
    <BoxSwap>
      <Box sx={{ width: '30%' }}>
        <SelectIcon
          value={_.get(swapQuestion, 'position', 'BEFORE')}
          labelText="ตำแหน่ง"
          listOption={optionPosition}
          handleChange={(event) =>
            dispatch(
              handleSwap({
                key: 'swapQuestion.position',
                value: _.get(event, 'target.value', 'BEFORE'),
                questions,
                keyQuestion,
                indexSelect: _.get(swapQuestion, 'indexSelect', 0),
              })
            )
          }
        />
      </Box>
      <SelectIcon
        value={_.get(swapQuestion, 'indexSelect', 0)}
        labelText="คำถาม"
        listOption={optionSwaps}
        handleChange={(event) =>
          dispatch(
            handleSwap({
              key: 'swapQuestion.indexSelect',
              value: _.get(event, 'target.value', 0),
            })
          )
        }
      />
    </BoxSwap>
  )
}

export default Index
