// import _ from 'lodash'
import { store } from '../../../../../App'
import { replaceFieldError, setFieldValue } from '../../../../../redux/slices/crud'
import { clearDrawerInput } from './clearDrawerInput'

export const openStockDrawer = async (value, isClear = false) => {
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'isOpenFormDrawer',
      value: value,
    }),
  )
  if (isClear) clearDrawerInput()
}
