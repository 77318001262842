import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { convertFormatDateTime } from '../../../../../../utils/lib'
import {
  COURSE_TYPE,
  RADIO_TRUE_FALSE_LABEL,
} from '../../../../../../constants/eLearning'
import { StyledCardContent, StyledDashedDivider, StyledUl } from '../../../../components/View/Styled'
import { assigneeType } from '../../../Form/SettingTab/Assignee/model'
import { timeSpanTypeOptions } from '../../../Form/SettingTab/Course/model'
import { handlePeriod } from '../../../../components/View/events'
import {
  ChipContent,
  ContentRow,
  FooterContent,
  TypeContent,
} from '../../../../components/View'
import Prerequisite from './Prerequisite'
import ECertificate from './ECertificate'
import { formatNumber, handleTime } from './events'

const Setting = () => {
  const detail = useSelector((state) => state.eLearningCourseDetail.result)
  const period = useMemo(() => handlePeriod(detail), [detail])
  const time = useMemo(() => handleTime(detail), [detail])
  const setting = _.get(detail, 'setting', {})
  const assignees = _.get(setting, 'assignees', [])
  const dueDateType = _.get(detail, 'dueDateType', '')
  const courseAccessExpiration = _.get(detail, 'courseAccessExpiration', '')
  const prerequisite = _.get(detail, 'coursePrerequisite', [])
  const eCertificationVersion = _.get(detail, 'eCertificationVersion', '')
  const isOIC = _.get(detail, 'courseType', '') === COURSE_TYPE.OIC
  const isCollect = _.get(setting, 'collectHours.isCollect', false)
  const isSell = _.get(setting, 'sellCourse.isSell', false)
  return (
    <>
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาหลักสูตร
        </Typography>
        <ContentRow title="วันเริ่มต้น" content={period} />
        <ContentRow
          title="เวลาเรียนของหลักสูตร (ไม่รวมเวลาพักเที่ยง)"
          content={time}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ผู้รับผิดชอบ
        </Typography>
        <ContentRow
          title="จำนวนผู้รับผิดชอบ"
          content={`${assignees.length} คน`}
        />
        <ContentRow title="ผู้รับผิดชอบ">
          <StyledUl>
            {_.map(assignees, (item, index) => (
              <li key={index}>
                {_.get(item, 'label', '')}{' '}
                <Typography variant="span" color="text.gray">
                  ({assigneeType[_.get(item, 'type', '')]})
                </Typography>
              </li>
            ))}
          </StyledUl>
        </ContentRow>
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          การตั้งค่าหลักสูตร
        </Typography>
        <ChipContent title="Tag" list={_.get(setting, 'tag', [])} />
        <ChipContent
          title="ช่องการจัดจำหน่าย"
          list={_.get(setting, 'distribution', [])}
        />
        <ChipContent
          title="ระดับผู้เรียน"
          field="title"
          list={_.get(detail, 'levelOfLearner', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          Skill Mapping
        </Typography>
        <ChipContent
          title="ระดับหลักสูตร"
          field="acquiredSkill"
          list={_.get(detail, 'acquiredSkill', [])}
        />
        <ChipContent
          title="ทักษะที่ได้จากหลักสูตร"
          field="productType"
          list={_.get(detail, 'productType', [])}
        />
      </StyledCardContent>

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          ระยะเวลาแสดงบนเว็บไซต์
        </Typography>
        <TypeContent type={dueDateType}>
          ใช้งานคอร์สนี้ได้จนถึง{' '}
          {convertFormatDateTime({
            value: _.get(detail, 'dueDate', ''),
            type: 'date',
          })}
        </TypeContent>
      </StyledCardContent>

      {!isOIC && (
        <>
          <StyledDashedDivider />
          <StyledCardContent>
            <Typography variant="h6" color="primary">
              ระยะเวลาการเข้าถึงหลักสูตร
            </Typography>
            <TypeContent type={courseAccessExpiration}>
              ระยะเวลา {_.get(detail, 'timeSpan', '')}{' '}
              {
                timeSpanTypeOptions.find(
                  (item) => item.value === _.get(detail, 'timeSpanType', ''),
                )?.label
              }
            </TypeContent>
          </StyledCardContent>
        </>
      )}

      <StyledDashedDivider />
      <StyledCardContent>
        <ContentRow
          title="Remark"
          content={_.get(setting, 'remark', '-') || '-'}
        />
      </StyledCardContent>

      {!_.isEmpty(prerequisite) && (
        <>
          <StyledDashedDivider />
          <StyledCardContent>
            <Typography variant="h6" color="primary">
              เงื่อนไขการเข้าอบรม
            </Typography>
            <Prerequisite prerequisite={prerequisite} />
          </StyledCardContent>
        </>
      )}

      {!_.isEmpty(eCertificationVersion) && <ECertificate />}

      <StyledDashedDivider />
      <StyledCardContent>
        <Typography variant="h6" color="primary">
          เก็บชั่วโมงหลักสูตร
        </Typography>
        <TrueFalseContent
          title="เก็บชั่วโมงหลักสูตรนี้ใช่หรือไม่ ?"
          value={isCollect}
        >
          จำนวนชั่วโมง {formatNumber(_.get(setting, 'collectHours.hours', ''))}{' '}
          ชั่วโมง
        </TrueFalseContent>
        {isCollect && (
          <ContentRow
            title="รายละเอียดเพิ่มเติม"
            content={_.get(setting, 'collectHours.description', '-') || '-'}
          />
        )}
      </StyledCardContent>

      {isOIC && (
        <>
          <StyledDashedDivider />
          <StyledCardContent>
            <Typography variant="h6" color="primary">
              การขายหลักสูตร
            </Typography>
            <TrueFalseContent
              title="ต้องการขายหลักสูตรนี้ใช่หรือไม่ ?"
              value={isSell}
            >
              ค่าธรรมเนียมสำหรับเส้นทางการเรียนรู้{' '}
              {formatNumber(_.get(setting, 'sellCourse.cost', ''))} บาท
            </TrueFalseContent>
            {isSell && (
              <ContentRow
                title="รายละเอียดเพิ่มเติม"
                content={_.get(setting, 'sellCourse.description', '-') || '-'}
              />
            )}
          </StyledCardContent>
        </>
      )}

      <Divider />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <FooterContent title="สร้าง" field="created" detail={detail} />
        <FooterContent title="แก้ไขล่าสุด" field="updated" detail={detail} />
      </Box>
    </>
  )
}

export default Setting

export const TrueFalseContent = ({ title, value, children }) => {
  return (
    <ContentRow title={title}>
      <Box>
        <Typography>{RADIO_TRUE_FALSE_LABEL[value.toString()]}</Typography>
        {value && (
          <StyledUl>
            <li>{children}</li>
          </StyledUl>
        )}
      </Box>
    </ContentRow>
  )
}
