import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { reportCourseSettingDashboard } from '../../utils/apiPath'

export const getReportCourseSettingDashboard = createAsyncThunk(
  'reportCourseSettingDashboard/getReportCourseSettingDashboard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(reportCourseSettingDashboard, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
