import { styled } from '@mui/system'

export const StyledDot = styled('div')(({ color, sx }) => ({
  borderRadius: 5,
  border: 'unset',
  backgroundColor: color,
  width: 8,
  height: 8,
  ...sx,
}))
