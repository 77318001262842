import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { hasPermissionCreateMasterData } from '../../../../utils/lib'
import TextInput from '../../../../components/Input/TextInput'
import AlertDialog from '../../../../components/AlertDialog'
import SwitchInput from '../../../../components/Input/SwitchInput'
import Divider from '@mui/material/Divider'
import {
  handleNumberKeyDown,
  handlePasteFormat,
  convertFormatDateTime,
} from '../../../../utils/lib'
import _ from 'lodash'

const StationForm = ({
  isEditForm,
  dialog,
  setDialog,
  handleClose,
  formik,
  initialValues,
  setInitialValues,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })

  useEffect(() => {
    if(
      (initialValues.stationName != '' && String(initialValues.stationName).length <= 255)
      && (initialValues.stationNameEN != '' && String(initialValues.stationNameEN).length <= 255)
      && (initialValues.locationCode != '' && String(initialValues.locationCode).length <= 4)
      && (initialValues.station != '' && String(initialValues.station).length <= 255)
      && (initialValues.initial != '' && String(initialValues.initial).length <= 255)
    ){
      setDisableSubmit(false)
    }else{
      setDisableSubmit(true)
    }
  }, [initialValues])

  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5' marginY={theme.spacing(2)} marginX={3}>
          รายละเอียดสเตชั่น
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="initial"
            name="initial"
            labelText="ตัวย่อ"
            type="basic"
            inputProps={{
              'data-testid': 'initial',
            }}
            textError={
              formik?.errors?.initial ? formik?.errors?.initial
              : String(initialValues.initial).length > 255
              ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
              : ''
            }
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3, width: '46.5%', }}
            value={initialValues.initial}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, initial: '' })
              formik.setFieldValue('initial', e.target.value)
              setInitialValues({
                ...initialValues,
                initial: e.target.value,
              })
            }}
          ></TextInput>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="stationName"
            name="stationName"
            labelText="ชื่อสเตชั่นสำหรับสนามสอบ"
            type="basic"
            inputProps={{
              'data-testid': 'stationName',
            }}
            textError={
              formik?.errors?.stationName ? formik?.errors?.stationName
              : String(initialValues.stationName).length > 255
              ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
              : ''
            }
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            value={initialValues.stationName}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, stationName: '' })
              formik.setFieldValue('stationName', e.target.value)
              setInitialValues({
                ...initialValues,
                stationName: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
          <TextInput
            id="station"
            name="station"
            labelText="ชื่อสเตชั่น EN สำหรับ Academy"
            type="basic"
            inputProps={{
              'data-testid': 'station',
            }}
            textError={
              formik?.errors?.station ? formik?.errors?.station
              : formik?.errors?.stationNameEN ? formik?.errors?.stationNameEN
              : String(initialValues.station).length > 255
              ? 'กรอกได้ไม่เกิน 255 ตัวอักษร'
              : ''
            }
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            value={initialValues.station}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, station: '' })
              formik.setFieldValue('station', e.target.value)
              formik.setFieldValue('stationNameEN', e.target.value)
              setInitialValues({
                ...initialValues,
                station: e.target.value,
                stationNameEN: e.target.value,
              })
            }}
          ></TextInput>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: '50%'
          }}
        >
          <TextInput
            id="locationCode"
            name="locationCode"
            labelText="รหัสสถานที่สอบ"
            type="basic"
            inputProps={{
              'data-testid': 'locationCode',
              onInput: handleInputFormat,
              onKeyDown: (e) => { handleNumberKeyDown(e, ['.']) },
              onPaste: handlePasteFormat,
            }}
            textError={
              formik?.errors?.locationCode ? formik?.errors?.locationCode
              : String(initialValues.locationCode).length >= 5
              ? 'กรอกได้ไม่เกิน 4 ตัวอักษร'
              : ''
            }
            required
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            value={initialValues.locationCode}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            onChange={(e) => {
              formik.setErrors({ ...formik.errors, locationCode: '' })
              formik.setFieldValue('locationCode', e.target.value)
              setInitialValues({
                ...initialValues,
                locationCode: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          ></TextInput>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            multiline
            rows={3}
            id="description"
            name="description"
            type="basic"
            labelText="Description"
            inputProps={{
              'data-testid': 'description',
            }}
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            textError={formik?.errors?.description}
            value={initialValues.description}
            onChange={(e) => {
              formik.values.description = e.target.value
              setInitialValues({
                ...initialValues,
                description: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Divider sx={{ mx: 3, mb: 3 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            multiline
            rows={3}
            id="annotation"
            name="annotation"
            type="basic"
            labelText="หมายเหตุ"
            inputProps={{
              'data-testid': 'annotation',
            }}
            disabled={!hasPermissionCreate}
            boxSx={{ mx: 3 }}
            textError={formik?.errors?.annotation}
            value={initialValues.annotation}
            onChange={(e) => {
              formik.values.annotation = e.target.value
              setInitialValues({
                ...initialValues,
                annotation: e.target.value,
              })
            }}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <SwitchInput
            id="status"
            name="status"
            labelText="สถานะ"
            value={initialValues.status}
            onChange={(e) => {
              formik.values.status = e.target.checked
              setInitialValues({ ...initialValues, status: e.target.checked })
            }}
            required
            inputText="การเปิดใช้งาน"
            boxSx={{ mx: 3 }}
          ></SwitchInput>
        </Box>

        <Box
          sx={{
            mt: 1,
            mb: 3,
            mx: 3,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              data-testid="btn-save"
              variant="contained"
              name="submit"
              type="submit"
              size="m"
              disabled={!hasPermissionCreate || disableSubmit}
            >
              บันทึก
            </Button>
            <Button
              data-testid="btn-cancel"
              variant="outlined"
              size="m"
              sx={{
                ml: 4,
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              ยกเลิก
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              marginLeft: 'auto',
              flexDirection: 'column',
              [theme.breakpoints.down('md')]: {
                mt: 2,
              },
            }}
          >
            <Typography variant="caption" color="text.lightGray">
              สร้างโดย{' '} { !isEditForm ? '-' : (
                <>
                  {initialValues && initialValues.createdBy
                    ? initialValues.createdBy
                    : ''}
                  {' '}
                  {initialValues && initialValues.createdAt
                    ? convertFormatDateTime({ value: initialValues.createdAt, type: 'dateTime' })
                    : ''}
                </>
              ) }
            </Typography>
            <Typography variant="caption" color="text.lightGray">
              แก้ไขล่าสุดโดย{' '} { !isEditForm ? '-' : (
                <>
                  {initialValues && initialValues.updatedBy
                    ? initialValues.updatedBy
                    : ''}
                  {' '}
                  {initialValues && initialValues.updatedAt
                    ? convertFormatDateTime({ value: initialValues.updatedAt, type: 'dateTime' })
                    : '-'}
                </>
              ) }
            </Typography>
          </Box>
        </Box>
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={handleClose}
          title={dialog.title}
          content={dialog.content}
          variant={dialog.variant}
          onConfirmClick={dialog.onConfirmClick}
          onCancelClick={dialog.onCancelClick}
          isLoading={dialog.isLoading}
        />
      </form>
    </Card>
  )
}

export default StationForm

export const regex = {
  NUMBER: /^[0-9]+$/,
}

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value

  if (!regex['NUMBER'].test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex['NUMBER'].test(e.target.value)) e.target.value = ''
  return e.target.value
}