import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { useParams } from 'react-router-dom'

import { breadcrumbAttendance, breadcrumbAttendanceList } from '../model'
import * as Styled from './Styled'
import DropDownButton from './DropDownButton'

const Index = ({ label }) => {
  const { id: uuid } = useParams()

  return (
    <Styled.BoxContent>
      <Styled.BoxHeader>
        <Typography variant="h4">{label}</Typography>
        <Breadcrumbs
          menuList={
            label === 'เช็คชื่อ'
              ? breadcrumbAttendance(uuid)
              : breadcrumbAttendanceList(label, uuid)
          }
        />
      </Styled.BoxHeader>

      {label !== 'เช็คชื่อ' && <DropDownButton />}
    </Styled.BoxContent>
  )
}

export default Index
