export const breadcrumbProspect = () => [
  { title: 'Manage', link: '/', pointer: false },
  { title: 'ข้อมูล Prospect', link: '/', pointer: false },
]

export const breadcrumbCsUser = () => [
  { title: 'Manage', link: '/', pointer: false },
  { title: 'ข้อมูล CS User', link: '/', pointer: false },
]

export const breadcrumbAgentUpdateProfile = () => [
  { title: 'Manage', link: '/', pointer: false },
  { title: 'อัพเดทข้อมูล', link: '/', pointer: true },
]

export const breadcrumbAgentUpdateUuidProfile = () => [
  { title: 'Manage', link: '/', pointer: false },
  {
    title: 'อัพเดทข้อมูล',
    link: '/manage/agent-update-profile',
    pointer: true,
  },
  { title: 'รายละเอียด รายการไม่สำเร็จ', link: '/', pointer: false },
]
