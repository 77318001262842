import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleEnableButton,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead,
} from './Styled'
import _ from 'lodash'

export const CheckSubmitDialog = ({ uploadResult, fetchData, id }) => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const dispatch = useDispatch()

  const license_detail = _.get(uploadResult, 'license_detail', '')
  const agent_detail = _.get(uploadResult, 'agent_detail', [])

  const wait_send = useMemo(() => {
    const agentList = agent_detail.filter((item) => item.rcmsStatus === 'รอส่ง')
    return agentList
  }, [agent_detail])

  return (
    <>
      <WarningDialogHeader />
      <StyledDialog>
        <Box sx={{ ml: 3, px: 1 }}>
          <Typography>
            กรุณาตรวจสอบรายการที่ต้องส่งทั้งหมดก่อนว่าถูกต้อง หากส่งข้อมูลไปยัง
            RCMS แล้วจะแก้ไขไม่ได้อีก
          </Typography>
        </Box>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 50 }}>
                <Typography color="text.primary">No.</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 160 }}>
                <Typography color="text.primary">รหัส Agent</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 120 }}>
                <Typography color="text.primary">ประเภท Agent</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">ประเภทใบอนุญาต</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>
                <Typography color="text.primary">ชื่อใบอนุญาต</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>
                <Typography color="text.primary">วันมีผลบังคับ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 100 }}>
                <Typography color="text.primary">วันหมดอายุ</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 130 }}>
                <Typography color="text.primary">หมายเหตุ</Typography>
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {wait_send.map((item, key) => (
              <StyledTableData key={key}>
                <TableCell sx={{ minWidth: 50 }}>
                  <Typography color="text.primary" variant="body2">
                    {key + 1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 160 }}>
                  <Typography color="text.primary" variant="body2">
                    {item.fullAgentCode ?? ''}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 120 }}>
                  <Typography color="text.primary" variant="body2">
                    {item.agentCategory ?? ''}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Typography color="text.primary" variant="body2">
                    {_.get(license_detail, 'category', '')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 110 }}>
                  <Typography color="text.primary" variant="body2">
                    {_.get(license_detail, 'description', '')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 110 }}>
                  <Typography color="text.primary" variant="body2">
                    {item.effectiveDate ?? ''}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Typography color="text.primary" variant="body2">
                    {item.expiryDate ?? ''}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 130 }}>
                  <Typography color="text.primary" variant="body2">
                    {item.remark ?? '-'}
                  </Typography>
                </TableCell>
              </StyledTableData>
            ))}
          </TableBody>
        </StyledTable>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'data-testid': 'checkbox-status' }}
                defaultChecked={false}
                onChange={(e) => dispatch(handleEnableButton(e))}
              />
            }
            label={`ส่งข้อมูลใบอนุญาตทั้งหมด ${wait_send.length} รายการ ไป RCMS`}
          />
        </Box>
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          loading={isLoading}
          onClick={() => dispatch(handleSubmitAdd(id, fetchData))}
          color="primary"
          disabled={disableButton}
          endIcon=""
        >
          ส่ง
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}

export default CheckSubmitDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">ส่งข้อมูลใบอนุญาตไป RCMS</Typography>
    </Box>
  )
}
