import { Box, Tooltip, Typography } from '@mui/material'
import _ from 'lodash'
import { LabelBox } from './LabelBox'

export const CustomLabels = ({
  datasets,
  hiddenDatasets,
  toggleDatasetVisibility,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        display: 'flex',
        gap: 2,
        mt: 0.75,
      }}
    >
      {_.map(datasets, (item, index) => {
        const showTooltip = item.label.length > 12

        return showTooltip ? (
          <Tooltip
            key={index}
            title={<Typography variant="tooltip">{item.label}</Typography>}
            arrow
            placement="top"
          >
            <div>
              <LabelBox
                {...item}
                index={index}
                toggleDatasetVisibility={toggleDatasetVisibility}
                isHidden={hiddenDatasets.includes(index)}
              />
            </div>
          </Tooltip>
        ) : (
          <LabelBox
            {...item}
            index={index}
            toggleDatasetVisibility={toggleDatasetVisibility}
            isHidden={hiddenDatasets.includes(index)}
          />
        )
      })}
    </Box>
  )
}
