import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import {
  StyledCaption,
  StyledContent,
  StyledContentHeader,
  StyledDisplayTraining,
  StyledTrainingContainer,
  StyledSquareBg,
} from './Styled'
import { handleMargin } from './events'

const TrainingPlatform = ({ training }) => {
  const isNotSm = isMediaWidthMoreThan('md')
  const platforms = _.get(training, 'platforms', []).filter(
    (item) => item.status
  )

  return (
    <StyledTrainingContainer>
      <StyledSquareBg src="/image/home_square_pattern.svg" />
      <ContentHeader training={training} />
      <StyledContent>
        {platforms.map((item, index) => (
          <DisplayTraining
            key={index}
            title={item.title}
            ml={handleMargin(index, isNotSm)}
          >
            {item.description}
          </DisplayTraining>
        ))}
      </StyledContent>
    </StyledTrainingContainer>
  )
}

export default TrainingPlatform

export const ContentHeader = ({ training }) => {
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <StyledContentHeader>
      <Typography variant={isLg ? 'h3' : 'h5'}>
        {_.get(training, 'title1', '')}
      </Typography>
      <Typography variant={isLg ? 'h2' : 'h4'} color="primary">
        {_.get(training, 'title2', '')}
      </Typography>
      <Typography variant={isLg ? 'h3' : 'h5'}>
        {_.get(training, 'title3', '')}
      </Typography>
    </StyledContentHeader>
  )
}

export const DisplayTraining = ({ title, ml, children }) => {
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <StyledDisplayTraining ml={ml}>
      <Typography id="title" variant={isLg ? 'h6' : 'body1b'}>
        {title}
      </Typography>
      <StyledCaption ml={ml} variant={isLg ? 'body1' : 'body2'}>
        {children}
      </StyledCaption>
    </StyledDisplayTraining>
  )
}
