import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import styled from '@mui/system/styled'

import React from 'react'
import TableSortLabel from '@mui/material/TableSortLabel'

export const StyledTableRow = styled(TableRow)(() => ({}))

export const StyledTableCell = styled(TableCell)(({ align }) => ({
  textAlign: align,
}))

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledTableSortLabel = styled(TableSortLabel)(({ headcell }) => ({
  margin: headcell.marginLabel ?? 0,
  cursor: !headcell.hideSortIcon ? 'pointer' : 'unset',
}))
