import { StyledRow } from '../Styled'
import _ from 'lodash'
import { Button, IconButton, Typography } from '@mui/material'
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import useTheme from '@mui/system/useTheme'
import BookingDrawer from '../BookingDrawer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { openCreateFormDrawerBooking } from '../../../redux/slices/roomManagement/Drawer'
import { useLocalStorage } from '@rehooks/local-storage'

export const Header = ({
  isShowDashboardSection,
  setIsShowDashboardSection,
  isShowButton = true,
}) => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()

  const { stationName, stationUuid } = useSelector(
    (state) => ({
      stationName: state.roomManagement.stationName,
      stationUuid: state.roomManagement.stationUuid,
    }),
    shallowEqual,
  )

  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'CREATE' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const isUserStation = !_.isEmpty(
    _.find(_.get(user, 'area', []), (item) => {
      return item.station.uuid === stationUuid
    }),
  )

  return (
    <>
      <StyledRow sx={{ justifyContent: 'space-between', pt: 1 }}>
        <Typography variant="h4">Room Management</Typography>
        {isShowButton &&
          (hasPermissionALL || (hasPermissionPIC && isUserStation)) && (
            <StyledRow sx={{ gap: theme.spacing(2), alignItems: 'center' }}>
              <Button
                sx={{
                  color: 'text.white',
                  width: { sm: 324, xs: 120 },
                }}
                size="m"
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => {
                  dispatch(
                    openCreateFormDrawerBooking({ stationName, stationUuid }),
                  )
                }}
              >
                จองห้อง
              </Button>
              <IconButton
                sx={{ p: 0 }}
                data-testid={`btn-close-drawer`}
                component="span"
                onClick={() => setIsShowDashboardSection((prev) => !prev)}
              >
                {isShowDashboardSection ? (
                  <KeyboardArrowUp color="action.active" />
                ) : (
                  <KeyboardArrowDown color="action.active" />
                )}
              </IconButton>
            </StyledRow>
          )}
      </StyledRow>
      <BookingDrawer />
    </>
  )
}
