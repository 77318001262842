import _ from 'lodash'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'customWidth' &&
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const Container = styled(
  (props) => <Box {...props} />,
  shouldForwardProp,
)(({ theme, background, padding }) => ({
  padding: padding ? padding : `${theme.spacing(2)} ${theme.spacing(2)}`,
  background: background && background,
  borderRadius: theme.spacing(1),
}))

export const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.background.paper,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  border: '1px #DBE4F1',
  width: 'fit-content',
}))

export const FilterStyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  background: '#DBE4F199',
  borderRadius: '4px',
  border: '1px #DBE4F1',
  display: 'flex',
  alignItems: 'center',
}))

export const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  width: '0.8em',
  marginLeft: theme.spacing(1),
  fontSize: '1em',
  cursor: 'pointer',
}))

export const StyledButton = styled(IconButton)(
  ({ background, marginLeft }) => ({
    marginLeft: !_.isUndefined(marginLeft) ? marginLeft : 'auto',
    background: background,
    borderRadius: '50%',
  }),
)

export const Dot = styled(Box)(({ background }) => ({
  position: 'absolute',
  top: 20,
  left: 10,
  width: 8,
  height: 8,
  background: background,
  borderRadius: '50%',
}))

export const StyledNumber = styled(Typography)(({ theme }) => ({
  background: '#00008F',
  marginRight: theme.spacing(0.75),
  borderRadius: '50%',
  minWidth: 24,
  lineHeight: theme.spacing(3),
  textAlign: 'center',
}))

export const StyledText = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))
