import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme, answerType }) => ({
  display: 'flex',
  paddingRight: answerType ? theme.spacing(1) : theme.spacing(2),
  paddingLeft: answerType ? theme.spacing(2.5) : theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingRight: answerType ? theme.spacing(1) : theme.spacing(0),
    paddingLeft: 0,
  },
}))

export const BoxQuestionType = styled(Box)(({ theme, isSub }) => ({
  width: isSub ? 138 : 240,
  [theme.breakpoints.down('sm')]: {
    width: isSub ? '100%' : '95%',
  },
}))

export const QuestionSequence = styled(Box)(({ theme }) => ({
  width: 85,
  [theme.breakpoints.down('sm')]: {
    width: 85,
  },
}))
