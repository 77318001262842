import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'unset' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const StyledContent = styled(Box)(({ isLoading }) => ({
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
