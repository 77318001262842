import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { Row } from '../../../../../../components/Layout/Styled'
import CircularProgressWithLabel from '../../../../Preview/RightPanel/ModuleLabel/components/CircularProgressWithLabel'

const ModuleAndProgress = ({ row }) => {
  const percent = _.get(row, 'data.percent', 0)
  return (
    <Row width={400} gap={2}>
      <CircularProgressWithLabel value={percent} />
      <Typography>{_.get(row, 'eLearningModule.name', '-')}</Typography>
    </Row>
  )
}

export default ModuleAndProgress
