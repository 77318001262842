import * as yup from 'yup'

export const validationDraftSchema = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อ Collection')
    .max(255, 'กรุณาระบุชื่อ Collection ภายใน 255 ตัวอักษร'),
  periodStart: yup
    .string()
    .required('กรุณากรอก วันเริ่มต้น')
    .test('test pastdate', 'วันเริ่มต้น ต้องไม่เป็นวันในอดีต', function (date) {
      return Date.parse(date) >= new Date().setHours(0, 0, 0, 0)
    }),
})
