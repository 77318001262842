import _ from 'lodash'
import { postCreateOicExam } from '../../../../services/manageClass/form'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../redux/slices/dialog'
import { resetForm } from '../../../../redux/slices/manageClassLearner'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const handleSubmitAdd = (uploadResult, fetchData) => (dispatch) => {
  dispatch(loadingDialog())
  const result = _.get(uploadResult, 'result', [])
  const body = result.filter((item) => item.status == 'ACTIVE')
  const response = dispatch(postCreateOicExam(body))
  if (!response.error) {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          fetchData()
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ผิดพลาด',
        message: 'ระบบขัดข้อง',
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      })
    )
  }
}
