import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import { DialogContentRejected } from './'

import * as slices from '../../../../../redux/slices/manageClassDocument'
import * as dialog from '../../../../../redux/slices/dialog'
import * as services from '../../../../../services/manageClass/document'

export const listBreadcrumb = (uuid) => {
  return [
    { title: 'จัดการคลาส', link: '/manage-class', pointer: true },
    {
      title: 'เอกสารการสมัคร',
      link: `/manage-class/learner/application-documents/${uuid}`,
      pointer: true,
    },
    { title: 'รายละเอียดเอกสารการสมัคร', link: '/', pointer: false },
  ]
}

export const onSubmitDocumentApprove = (props) => (dispatch) => {
  if (!props.isConfirm) {
    dispatch(submitDocumentApproveNotConFirm(props))
  } else {
    dispatch(submitDocumentApproveConFirm(props))
  }
}

export const submitDocumentApproveConFirm = (props) => (dispatch) => {
  if (props.statusText === 'ไม่มีสิทธิ์เข้าเรียน') {
    dispatch(
      dialog.openDialog({
        type: 'mini',
        disableButton: true,
        content: (
          <DialogContentRejected
            type="last"
            isConfirm={props.isConfirm}
            statusText={props.statusText}
          />
        ),
      }),
    )
  } else if (props.statusText !== 'รอคอนเฟิร์มตัวแทน') {
    dispatch(
      dialog.openDialog({
        isCloseDialog: false,
        title: 'ยืนยันอนุมัติเอกสาร',
        message: 'คุณต้องการยืนยันอนุมัติเอกสารนี้หรือไม่',
        handleConfirm: () => dispatch(actionSubmitDocument(props)),
      }),
    )
  } else {
    dispatch(
      dialog.openDialog({
        isCloseDialog: false,
        title: 'ยืนยันว่ามีสิทธิ์เรียน',
        message: 'คุณต้องการยืนยันว่าผู้เรียนมีสิทธิ์เรียนหรือไม่',
        handleConfirm: () => dispatch(actionSubmitDocument(props)),
      }),
    )
  }
}

export const submitDocumentApproveNotConFirm = (props) => (dispatch) => {
  if (
    props.document === 1 &&
    props.firstRound !== 1 &&
    props.statusText !== 'ไม่มีสิทธิ์เข้าเรียน'
  ) {
    dispatch(handleFirstApprove(props))
  } else if (props.statusText === 'ไม่มีสิทธิ์เข้าเรียน') {
    dispatch(
      dialog.openDialog({
        type: 'mini',
        disableButton: true,
        content: (
          <DialogContentRejected type="last" isConfirm={props.isConfirm} />
        ),
      }),
    )
  } else {
    dispatch(
      dialog.openDialog({
        isCloseDialog: false,
        title: 'ยืนยันอนุมัติเอกสารและมีสิทธิ์เรียน',
        message: (
          <>
            คุณต้องการยืนยันอนุมัติเอกสารและ
            <span style={{ color: '#1CC54E' }}>มีสิทธิ์เรียน</span>หรือไม่
          </>
        ),
        handleConfirm: () => dispatch(actionSubmitDocument(props)),
      }),
    )
  }
}

export const onSubmitDocumentReject = (status) => (dispatch) => {
  dispatch(
    dialog.openDialog({
      type: 'mini',
      disableButton: true,
      content: <DialogContentRejected statusText={status} />,
    }),
  )
}

export const actionSubmitDocument = (props) => async (dispatch) => {
  const { status, uuid, documentBody } = props

  dispatch(dialog.loadingDialog())
  dispatch(slices.startLoading())

  let result = {}
  if (status === 'approve') {
    result = await dispatch(services.putManageClassLearnerApprove(uuid))
  } else if (status === 'approveStatus') {
    result = await dispatch(
      services.postManageClassLearnerApproveStatus({
        remarkStatus: documentBody.remark,
        classLearnerUuid: uuid,
      }),
    )
  } else {
    result = await dispatch(
      services.putManageClassLearnerReject({
        ...documentBody,
        classLearnerUuid: uuid,
      }),
    )
  }

  const payload = _.get(result, 'meta.requestStatus', 'rejected')
  if (payload === 'fulfilled') {
    dispatch(
      slices.setChangeBody({
        key: 'remark',
        value: '',
      }),
    )
    dispatch(dialogSuccess())
  }

  dispatch(slices.stopLoading())
}

export const dialogSuccess = () => (dispatch) => {
  dispatch(dialog.stopLoadingDialog())
  dispatch(
    dialog.openDialog({
      type: 'success',
      title: 'บันทึกสำเร็จ',
      message: 'บันทึกสำเร็จ',
      isCloseDialog: false,
      handleConfirm: () => {
        window.location.reload()
      },
    }),
  )
}

export const handleFirstApprove = (props) => (dispatch) => {
  dispatch(
    dialog.openDialog({
      title: 'อนุมัติเอกสาร',
      type: 'content',
      content: <DialogContentFirstApprove />,
      agreeText: 'อนุมัติ',
      disableButton: true,
      handleConfirm: () => dispatch(actionSubmitDocument(props)),
    }),
  )
}

export const DialogContentFirstApprove = () => {
  const dispatch = useDispatch()
  const [toggle, setToggle] = useState(false)
  return (
    <Box sx={{ mx: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1">
          ถึงแม้ว่าทางผู้สมัครจะไม่ได้ส่งเอกสาร ก็ยัง
          <span style={{ color: '#1CC54E' }}>มีสิทธิ์เรียน</span>
          ในหลักสูตรนี้ได้
        </Typography>
        <Typography variant="body1">
          คุณต้องการอนุมัติการมีสิทธิ์เรียนนี้หรือไม่
        </Typography>
      </Box>
      <Box sx={{ mt: 1, ml: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="primary"
              checked={toggle}
              onChange={(e) => dispatch(handleToggle(e, setToggle))}
            />
          }
          label="ฉันรับทราบเงื่อนไขและต้องการอนุมัติ"
        />
      </Box>
    </Box>
  )
}

export const handleToggle = (event, setToggle) => (dispatch) => {
  setToggle((prevToggle) => !prevToggle)
  const checked = event.target.checked
  !checked
    ? dispatch(dialog.setDisableButton())
    : dispatch(dialog.setEnableButton())
}

export const handleChangeRemark = (value) => (dispatch) => {
  dispatch(
    slices.setChangeBody({
      key: 'remark',
      value,
    }),
  )
  !value
    ? dispatch(dialog.setDisableButton())
    : dispatch(dialog.setEnableButton())
}

export const mapTextHeader = (type, isConfirm, statusText) => {
  if (type == 'last') return 'ยืนยันอนุมัติเอกสารและมีสิทธิ์เรียน'
  if (isConfirm && statusText !== 'รอคอนเฟิร์มตัวแทน')
    return 'ยืนยันการปรับสถานะเพื่อให้มีสิทธิ์เรียนได้'
  if (statusText === 'รอคอนเฟิร์มตัวแทน') return 'ไม่มีสิทธิ์เรียน'
  return 'ไม่อนุมัติเอกสารการสมัคร'
}

export const mapStatusApprove = (type, isConfirm, statusText) => {
  if (isConfirm && type == 'last' && statusText === 'ไม่มีสิทธิ์เข้าเรียน')
    return 'approveStatus'
  if (isConfirm && type == 'last') return 'approve'
  if (type == 'last' && !isConfirm) return 'approveStatus'
  return 'reject'
}
