import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledHeader = styled(Box)(() => ({
  height: 60,
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const StyledDialogActions = styled(Box)(({ theme }) => ({
  padding: '24px 60px 60px 60px',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: 60,
  paddingRight: 60,
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const StyledContentImage = styled(Box)(() => ({
  maxWidth: 760,
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  margin: '0px auto',
  '@media (max-width: 576px)': { maxWidth: 300 },
}))

export const StyledButton = styled(Button)(() => ({ width: '100%' }))
