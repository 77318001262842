import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningCourse,
  eLearningCourseHistory,
  eLearningCourseBatchHistory,
  eLearningCourseBatchLearnerHistory,
  eLearningQuestionHistory,
  eLearningCourseBatchCompleted,
} from '../../../utils/apiPath'

export const getDetail = createAsyncThunk(
  'eLearningCourseDetail/getDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourse}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchLearnerHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchLearnerHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseBatchLearnerHistory}${url}`,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchQuestionHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningQuestionHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCompleteCourseBatch = createAsyncThunk(
  'eLearningCourseDetail/putCompleteCourseBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseBatchCompleted, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
