import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 360,
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(3),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(7)}`,
  },
}))

export const StyledGroupBox = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: '1px solid',
  borderColor: theme?.palette?.text?.lightBlue,
  margin: theme.spacing(3),
  marginTop: 0,
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  '& > .MuiFormGroup-root': { width: '100%', gap: theme.spacing(0.5) },
  '& .MuiFormControlLabel-root': { padding: 0, margin: '0 5px' },
  // text, select, date input
  '&  > .MuiFormGroup-root > .MuiInputBase-root,  > .MuiFormGroup-root > .MuiBox-root':
    {
      width: 'auto',
      margin: theme.spacing(2),
      marginTop: 0,
    },
  // checkbox style
  '& > .MuiBox-root': {
    margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: theme.spacing(1),
    '& .MuiCheckbox-root .MuiSvgIcon-root': { width: 20, height: 20 },
  },
}))

export const StyledList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}))
