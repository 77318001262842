import React, { useRef, useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { handleClose, handleMoreMenu } from './events'
import { StyledPopper } from './Styled'
import { MORE_OPTIONS_BG } from '../../../../../../constants/eCertification'

export const moreOptions = MORE_OPTIONS_BG

const DropdownButton = () => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem>
                  {moreOptions.map((option, index) => (
                    <>
                      <MenuItem
                        key={index}
                        component="span"
                        data-testid={`menu-${index}`}
                        onClick={() =>
                          option.method !== 'import' &&
                          dispatch(handleMoreMenu(option.method, uuid, history))
                        }
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ minWidth: '140px' }}
                        >
                          {option.text}
                        </Typography>
                      </MenuItem>
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
