import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchText } from '../../../../redux/slices/table2'
import { onEnterSearch } from './events'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'

const SearchCustom = () => {
  const dispatch = useDispatch()
  const { search, handleSearch } = useSelector((state) => state.table2)

  return (
    <BoxSearch>
      <SearchInput
        data-testid="search-input"
        placeholder={search.placeholder}
        onChange={(e) => dispatch(setSearchText(e.target.value))}
        onKeyPress={(e) => dispatch(onEnterSearch(e, handleSearch))}
        value={_.get(search, 'searchText', '')}
        inputProps={{
          maxLength: search?.maxLength > 0 ? search.maxLength : 255,
        }}
      />
      <StyledSearchIcon
        data-testid="search-icon"
        color="primary"
        onClick={() => handleSearch(search.searchText)}
      />
    </BoxSearch>
  )
}

export default SearchCustom
