// import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import _ from 'lodash'
import { StyledContent, StyledDrawer, StyledHeadLabel } from './Styled'
import Table from '../../../../../components/redux/Table'
import { useEffect } from 'react'
import { fetchRoomHistory } from './event'
import { setReduxValue } from '../../../../../redux/slices/roomManagement/Drawer'
import { Loading } from '../../../components/Loading'

const HistoryDrawer = ({ isOpen }) => {
  const table = useSelector((state) => state.table.table, shallowEqual)
  const { page, limit } = table
  const dispatch = useDispatch()
  const { bookingUuid, ishLoadingHistoryDrawer } = useSelector(
    (state) => ({
      bookingUuid: state.roomManagementDrawer.bookingInformation.bookingUuid,
      ishLoadingHistoryDrawer:
        state.roomManagementDrawer.ishLoadingHistoryDrawer,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (isOpen && !_.isEmpty(bookingUuid))
      dispatch(fetchRoomHistory({ table: table, uuid: bookingUuid }))
  }, [isOpen, page, limit])

  return (
    <StyledDrawer
      open={isOpen}
      onClose={() =>
        dispatch(setReduxValue({ key: 'ishShowHistoryDrawer', value: false }))
      }
    >
      <StyledHeadLabel>
        <Box id="header">
          <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() =>
              dispatch(
                setReduxValue({ key: 'ishShowHistoryDrawer', value: false }),
              )
            }
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledContent>
        {ishLoadingHistoryDrawer && (
          <Box sx={{ height: 200 }}>
            <Loading isLoading={ishLoadingHistoryDrawer} />
          </Box>
        )}
        {!ishLoadingHistoryDrawer && <Table />}
      </StyledContent>
    </StyledDrawer>
  )
}

export default HistoryDrawer
