import { Box, Divider, Typography } from '@mui/material'
import { StyledRow, StyledColumn, StyledCard } from '../../Styled'
import Select from '../../../../components/Input/Select'
import useTheme from '@mui/system/useTheme'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { optionsYear } from '../../../../modules/MonthlyPlan/HeaderCalendar'
import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import { useSelector } from 'react-redux'
import DropdownButton from './DropdownButton'
import { useState } from 'react'
import OperatorDrawer from '../../OperatorDrawer'
import {
  setMonth,
  setYear,
} from '../../../../redux/slices/roomManagement/Dashboard'
import { useDispatch } from 'react-redux'
import { isMediaWidthMoreThan } from '../../../../utils/lib'

ChartJS.register(ArcElement, Tooltip, Legend)

const StatisticItem = (props) => {
  const { color, label, count } = props
  return (
    <StyledColumn sx={{ alignItems: 'center' }}>
      <StyledRow sx={{ gap: 1 }}>
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: color,
            borderRadius: '50%',
          }}
        />
        <Typography variant="body2">{label}</Typography>
      </StyledRow>
      <Typography variant="h4">{count}</Typography>
    </StyledColumn>
  )
}
const TopOperatorItem = (props) => {
  const { firstNameTH, lastNameTH, image, idx, station, numberOfBooking } =
    props
  return (
    <StyledRow sx={{ gap: 2, alignItems: 'center' }}>
      <Box sx={{ position: 'relative', width: 40, height: 42 }}>
        <img
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 999 }}
          src={`icon/top-operator/top-operator-${idx + 1}.svg`}
        />
        <img
          style={{
            width: 38,
            height: 38,
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
          src={`${window.__env__.REACT_APP_API_URL}/file${image}`}
          data-testid="small-image"
        />
      </Box>

      <StyledColumn>
        <Typography variant="body2b">
          {firstNameTH} {lastNameTH}
        </Typography>
        <StyledRow sx={{ alignItems: 'center', gap: 1 }}>
          <Typography variant="body3" color="text.gray">
            {station}
          </Typography>
          <Divider
            sx={{ height: 16 }}
            orientation="vertical"
            flexItem
            variant="middle"
          />
          <Typography variant="body3" color="text.gray">
            ดำเนินการ{' '}
            <Typography variant="body3b" color="primary">
              {numberOfBooking}
            </Typography>{' '}
            รายการ
          </Typography>
        </StyledRow>
      </StyledColumn>
    </StyledRow>
  )
}

export const BookingStatistics = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const optionsMonth = [
    { label: 'มกราคม', value: 1 },
    { label: 'กุมภาพันธ์', value: 2 },
    { label: 'มีนาคม', value: 3 },
    { label: 'เมษายน', value: 4 },
    { label: 'พฤษภาคม', value: 5 },
    { label: 'มิถุนายน', value: 6 },
    { label: 'กรกฎาคม', value: 7 },
    { label: 'สิงหาคม', value: 8 },
    { label: 'กันยายน', value: 9 },
    { label: 'ตุลาคม', value: 10 },
    { label: 'พฤศจิกายน', value: 11 },
    { label: 'ธันวาคม', value: 12 },
  ]
  const [isOpenOperatorDrawer, setIsOpenOperatorDrawer] = useState(false)
  const {
    manageClassType,
    monthlyPlanType,
    eBookingType,
    operator,
    year,
    month,
  } = useSelector(
    (state) => ({
      manageClassType: state.roomManagement.data.manageClassType,
      monthlyPlanType: state.roomManagement.data.monthlyPlanType,
      eBookingType: state.roomManagement.data.eBookingType,
      operator: state.roomManagement.data.operator,
      year: state.roomManagement.year,
      month: state.roomManagement.month,
    }),
    shallowEqual,
  )

  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [manageClassType, monthlyPlanType, eBookingType],
        backgroundColor: [
          theme.palette?.monthlyPlan?.dayOffBorder,
          theme.palette?.warning?.main,
          theme.palette?.primary?.main,
        ],
        borderRadius: 4,
      },
    ],
  }

  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }
  const staticList = [
    {
      color: theme.palette?.monthlyPlan?.dayOffBorder,
      label: 'Manage Class',
      count: manageClassType,
    },
    {
      color: theme.palette?.warning?.main,
      label: 'Monthy Plan',
      count: monthlyPlanType,
    },
    {
      color: theme.palette?.primary?.main,
      label: 'E-Booking',
      count: eBookingType,
    },
  ]
  const allBooking =
    (_.isNil(manageClassType) ? 0 : manageClassType) +
    (_.isNil(monthlyPlanType) ? 0 : monthlyPlanType) +
    (_.isNil(eBookingType) ? 0 : eBookingType)

  const topOperator = operator?.slice(0, 3)
  const isNotLg = isMediaWidthMoreThan('lg')

  return (
    <>
      <StyledCard
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: isNotLg ? 'row' : 'column',
          minHeight: 279,
        }}
      >
        <StyledColumn sx={{ minWidth: { sm: 445, xs: 'unset' }, gap: 2 }}>
          <StyledRow
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="h6">สถิติการจองห้องอบรม</Typography>
            <StyledRow sx={{ gap: 1, height: 34 }}>
              {!_.isNil(year) && (
                <Select
                  id="select-year"
                  boxSx={{ width: 86 }}
                  selectSx={{ borderRadius: theme.spacing(1), height: 34 }}
                  placeholder={'ปี'}
                  options={optionsYear({ start: 2000, end: 2100 })}
                  type="basic"
                  value={year}
                  showTextError={false}
                  handleChange={(e) => {
                    const value = _.get(e, 'target.value', 0)
                    dispatch(setYear(value))
                  }}
                />
              )}
              {!_.isNil(month) && (
                <Select
                  id="select-month"
                  selectSx={{ borderRadius: theme.spacing(1), height: 34 }}
                  placeholder={'เดือน'}
                  options={optionsMonth}
                  type="basic"
                  value={month}
                  showTextError={false}
                  handleChange={(e) => {
                    const value = _.get(e, 'target.value', 0)
                    dispatch(setMonth(value))
                  }}
                />
              )}
            </StyledRow>
          </StyledRow>
          <StyledRow
            sx={{
              gap: 2,
              justifyContent: 'space-between',
              flexDirection: { sm: 'row', xs: 'column' },
            }}
          >
            <Box
              sx={{
                width: 140,
                height: 140,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  textAlign: 'center',
                  left: 0,
                  right: 0,
                }}
              >
                <StyledColumn>
                  <Typography
                    sx={{ lineHeight: 0.5 }}
                    variant="h4"
                    component="span"
                  >
                    {allBooking}
                  </Typography>
                  <Typography variant="body3" component="span">
                    ยอดจองเดือนนี้
                  </Typography>
                </StyledColumn>
              </Box>
              <Doughnut data={data} options={options} />
            </Box>
            <StyledRow sx={{ gap: 2, justifyContent: 'space-between' }}>
              {_.map(staticList, (st, idx) => {
                return <StatisticItem key={idx} {...st} />
              })}
            </StyledRow>
          </StyledRow>
        </StyledColumn>

        <Divider orientation={isNotLg ? 'vertical' : 'horizontal'} flexItem />
        <StyledColumn
          sx={{
            width: '100%',
          }}
        >
          <StyledRow
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34,
            }}
          >
            <Typography variant="body1b">ผู้ดำเนินการ</Typography>

            <DropdownButton setOpenOperatorDrawer={setIsOpenOperatorDrawer} />
          </StyledRow>
          {topOperator?.length > 0 ? (
            _.map(topOperator, (to, idx) => (
              <StyledColumn key={idx}>
                <TopOperatorItem idx={idx} {...to} />
                {idx !== topOperator.length - 1 && <Divider sx={{ my: 1 }} />}
              </StyledColumn>
            ))
          ) : (
            <Typography
              sx={{ display: 'flex', justifyContent: 'center' }}
              variant="body1"
              color="text.gray"
            >
              ยังไม่มีผู้ดำเนินการขณะนี้
            </Typography>
          )}
        </StyledColumn>
      </StyledCard>
      <OperatorDrawer
        isOpen={isOpenOperatorDrawer}
        onCloseDrawer={() => setIsOpenOperatorDrawer(false)}
      />
    </>
  )
}
