import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter, Loading } from '../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenFilterDrawer } from '../../../../../../../redux/slices/eLearning/dashboardByCourse'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { ListCheckbox } from './ListCheckbox'
import { onClearClick, filterDashboard } from './events'

const FilterDrawer = () => {
  const dispatch = useDispatch()
  const { isOpenFilterDrawer, isLoadingDrawer } = useSelector(
    (state) => ({
      isOpenFilterDrawer: state.eLearningDashboardByCourse.isOpenFilterDrawer,
      isLoadingDrawer: state.eLearningDashboardByCourse.isLoadingDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      sx={{ position: 'relative' }}
      open={isOpenFilterDrawer}
      onClose={() => dispatch(setIsOpenFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      {!isLoadingDrawer ? (
        <ListCheckbox />
      ) : (
        <Loading loading={isLoadingDrawer.toString()} />
      )}

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => onClearClick()}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          variant="contained"
          onClick={() => {
            filterDashboard()
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default FilterDrawer
