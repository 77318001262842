import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Column } from '../Layout/Styled'

const DoughnutChart = ({
  dataSet,
  height = '100%',
  label,
  value,
  eleId = 'doughnutChart',
  cutout = 75,
}) => {
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }
    const ctx = document.getElementById(eleId)
    const newChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            ...dataSet,
          },
        ],
      },
      options: {
        cutout: cutout,
        events: [],
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
          },
        },
      },
    })

    setChart(newChart)
  }, [dataSet])
  return (
    <Box sx={{ position: 'relative', height: height, width: height }}>
      <canvas id={eleId} />
      <Column
        sx={{
          position: 'absolute',
          textAlign: 'center',
          left: 0,
          right: 0,
          top: 'calc(50% - 45px)',
        }}
      >
        <Typography variant="body1" color="text.lightGray">
          {label}
        </Typography>
        <Typography variant="h3">{value}</Typography>
      </Column>
    </Box>
  )
}

export default DoughnutChart
