import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import DragIndicatorTwoTone from '@mui/icons-material/DragIndicatorTwoTone'
import _ from 'lodash'

import Keyword from '../FillInBlank/Choice/Keyword'
import Answer from '../Answer'
import AnswerTrueFalse from '../TrueFalse/AnswerTrueFalse'
import AnswerMatching from '../Matching/Answer'
import { onDragEnd } from './event'
import { IconDragDrop } from './Styled'
import AnswerSequence from '../Sequence/Answer'

const Index = ({ keyQuestion, indexQuestion, indexMainAnswer }) => {
  const dispatch = useDispatch()
  const { question, disableForm } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      disableForm:
        state.eTestingForm.disableForm || state.eTestingForm.onViewMode,
    }),
    shallowEqual
  )
  const { answers, questionType } = question
  const answerList = _.isUndefined(indexMainAnswer)
    ? answers
    : _.get(answers[indexMainAnswer], 'subAnswers', [])

  return (
    <DragDropContext
      onDragEnd={(e) =>
        dispatch(onDragEnd(e, answerList, keyQuestion, indexMainAnswer))
      }
    >
      <Droppable droppableId="list">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {answerList.map((item, indexAnswer) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={indexAnswer}
              >
                {(provided) => (
                  <Box
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      marginTop: -5,
                    }}
                    key={indexAnswer}
                  >
                    <IconDragDrop
                      {...provided.dragHandleProps}
                      indexAnswer={indexAnswer}
                      questionType={questionType}
                    >
                      <DragIndicatorTwoTone />
                    </IconDragDrop>
                    {questionType === 'FILL_IN_BLANK_CHOICE' && (
                      <Keyword
                        key={indexAnswer}
                        keyQuestion={keyQuestion}
                        indexQuestion={indexQuestion}
                        indexMainAnswer={indexMainAnswer}
                        indexAnswer={indexAnswer}
                        answers={answerList}
                      />
                    )}

                    {questionType === 'SEQUENCE' && (
                      <AnswerSequence
                        key={indexAnswer}
                        disableForm={disableForm}
                        keyQuestion={keyQuestion}
                        indexQuestion={indexQuestion}
                        indexAnswer={indexAnswer}
                        answers={answers}
                      />
                    )}

                    {questionType === 'MATCHING' && (
                      <AnswerMatching
                        key={indexAnswer}
                        disableForm={disableForm}
                        keyQuestion={keyQuestion}
                        indexQuestion={indexQuestion}
                        indexAnswer={indexAnswer}
                        answers={answers}
                      />
                    )}

                    {questionType === 'TRUE_FALSE' && (
                      <AnswerTrueFalse
                        key={indexAnswer}
                        disableForm={disableForm}
                        keyQuestion={keyQuestion}
                        indexQuestion={indexQuestion}
                        indexAnswer={indexAnswer}
                        answers={answers}
                      />
                    )}

                    {(questionType === 'MULTIPLE' ||
                      questionType === 'SINGLE') && (
                      <Answer
                        key={indexAnswer}
                        disableForm={disableForm}
                        keyQuestion={keyQuestion}
                        indexQuestion={indexQuestion}
                        indexAnswer={indexAnswer}
                        answers={answers}
                        questionType={questionType}
                      />
                    )}
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default Index
