import React, { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CircularContent from '../components/CircularContent'
import MediaDisplay from './MediaDisplay'
import { handleHoverOnVideo, onKeyEsc } from './events'
import { StyledLeftContent } from './Styled'

const LeftPanel = () => {
  const leftRef = useRef(null)
  const dispatch = useDispatch()
  const { isLoading, isLessonLoading, type, isFullScreen } = useSelector(
    (state) => ({
      isLoading: state.eLearningPreview.isLoading,
      isLessonLoading: state.eLearningPreview.isLessonLoading,
      type: state.eLearningPreview.selectedLesson.type,
      isFullScreen: state.eLearningPreview.isFullScreen,
    }),
    shallowEqual,
  )

  useEffect(() => {
    handleHoverOnVideo(leftRef)

    document.addEventListener('keydown', (e) => dispatch(onKeyEsc(e)))
    return () => {
      document.removeEventListener('keydown', (e) => dispatch(onKeyEsc(e)))
    }
  }, [])

  return (
    <StyledLeftContent type={type} ref={leftRef} isFullScreen={isFullScreen}>
      <CircularContent isLoading={isLoading || isLessonLoading} />
      <MediaDisplay />
    </StyledLeftContent>
  )
}

export default LeftPanel
