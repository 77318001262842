import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { breadcrumbExamination, ExaminationOption } from '../Lib/constanst'
import { StyledHeader, StyledHeaderContainer } from '../Lib/CSS/Styled'
import DropdownButton from './DropdownButton'
import MenuItem from '@mui/material/MenuItem'
import { onClickAdd } from './events'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

const Index = () => {
  const history = useHistory()
  
  return (
    <StyledHeader>
      <Box>
        <Typography variant="h4">สนามสอบ (Examination field)</Typography>
        <Breadcrumbs menuList={breadcrumbExamination()} />
      </Box>
      <StyledHeaderContainer>
        <DropdownButton label="สร้างสนามสอบ" id="create-dropdown-examination">
          {_.map(ExaminationOption, (option, index) => (
            <MenuItem
              key={index}
              data-testid={`menu-create-${index}`}
              onClick={() => onClickAdd(option.link, history)}
            >
              <Box sx={{ mr: 1, display: 'flex' }}>
                {option.icon && <>{option.icon}</>}
              </Box>
              <Box>
                <Typography variant="body1">{option.text}</Typography>
              </Box>
            </MenuItem>
          ))}
        </DropdownButton>
      </StyledHeaderContainer>
    </StyledHeader>
  )
}

export default Index
