import {
  E_LEARNING_COURSE_STATUS,
  E_LEARNING_COURSE_STATUS_LABEL,
  E_LEARNING_LEARNER_TYPE,
} from '../../../../constants/eLearning'
import { themeColor } from '../../../../utils/themeData'

const today = new Date()
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

export const initialState = {
  isLoading: false,
  isLoadingDrawer: false,
  isOpenFilterDrawer: false,
  isOpenLearnerDrawer: false,
  error: '',
  courseUuid: '',
  filter: {
    periodStart: firstDayOfMonth,
    periodEnd: today,
    startDate: firstDayOfMonth,
    endDate: today,
    dateChecked: true,
    displayDate: true,
    courseBatchChecked: false,
    courseBatchList: [],
  },
  filterTotal: 0,
  totalLearner: 0,
  notStarted: 0,
  inProgress: 0,
  completed: 0,
  totalModule: 0,
  resultModule: [],
  learnerStatusByType: [
    {
      type: E_LEARNING_LEARNER_TYPE.AGENT,
      color: themeColor.graph.green1,
      secondaryColor: themeColor.graph.green1Light,
      totalCount: 0,
      progress: [
        {
          status: E_LEARNING_COURSE_STATUS.NOT_STARTED,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.INPROGRESS,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.COMPLETED,
          count: 0,
          percent: 0,
        },
      ],
    },
    {
      type: E_LEARNING_LEARNER_TYPE.BANCA,
      color: themeColor.graph.yellow1,
      secondaryColor: themeColor.graph.yellow1Light,
      totalCount: 0,
      progress: [
        {
          status: E_LEARNING_COURSE_STATUS.NOT_STARTED,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.INPROGRESS,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.COMPLETED,
          count: 0,
          percent: 0,
        },
      ],
    },
    {
      type: E_LEARNING_LEARNER_TYPE.KTB,
      color: themeColor.blue.oceanBlue,
      secondaryColor: themeColor.graph.blue3Light,
      totalCount: 0,
      progress: [
        {
          status: E_LEARNING_COURSE_STATUS.NOT_STARTED,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.INPROGRESS,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.COMPLETED,
          count: 0,
          percent: 0,
        },
      ],
    },
    {
      type: E_LEARNING_LEARNER_TYPE.OTHER,
      color: themeColor.graph.purple1,
      secondaryColor: themeColor.graph.purple1Light,
      totalCount: 0,
      progress: [
        {
          status: E_LEARNING_COURSE_STATUS.NOT_STARTED,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.INPROGRESS,
          count: 0,
          percent: 0,
        },
        {
          status: E_LEARNING_COURSE_STATUS.COMPLETED,
          count: 0,
          percent: 0,
        },
      ],
    },
  ],
  courseBatchOptions: [],
  learnerTypeSelected: E_LEARNING_LEARNER_TYPE.ALL,
  statusLearnerDrawer: [
    {
      status: E_LEARNING_COURSE_STATUS.NOT_STARTED,
      label: E_LEARNING_COURSE_STATUS_LABEL.NOT_STARTED,
      checked: true,
      count: 0,
    },
    {
      status: E_LEARNING_COURSE_STATUS.INPROGRESS,
      label: E_LEARNING_COURSE_STATUS_LABEL.INPROGRESS,
      checked: true,
      count: 0,
    },
    {
      status: E_LEARNING_COURSE_STATUS.COMPLETED,
      label: E_LEARNING_COURSE_STATUS_LABEL.COMPLETED,
      checked: true,
      count: 0,
    },
  ],
}
