import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  setSelected,
  setSearchText,
  resetTablePage,
  resetPage,
  resetRowsPerPage,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import {
  eLearningDashboardAllCourseDownload,
  examinationDashboardExamField,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { overviewDataEnum } from '../../enums/drawerEnum'

export const examFieldTypes = [
  {
    label: 'สนามสอบบริษัท',
    fieldName: 'eExamDashboard.examFieldDrawer.isCompany',
    type: overviewDataEnum.EXAM_STATION,
  },
  {
    label: 'สนามสอบสมาคม',
    fieldName: 'eExamDashboard.examFieldDrawer.isAssociation',
    type: overviewDataEnum.EXAM_ASSOCIATION,
  },
]

export const headCellsExamField = [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'type',
    label: 'ประเภทสนามสอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'region',
    label: 'ภูมิภาค',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'province',
    label: 'จังหวัด',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'station',
    label: 'สเตชั่น',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'locationCode',
    label: 'รหัสสถานที่',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const handleCheckBox = (key, value, table) => {
  store.dispatch(
    setFieldValue({
      key: key,
      value: value,
    }),
  )
  store.dispatch(resetRowsPerPage())
  store.dispatch(
    fetchData({
      table,
    }),
  )
}

export const fetchData =
  ({ table }) =>
  async (dispatch) => {
    const { eExamDashboard } = store.getState().crud
    const realPage = table.page <= 0 ? 1 : +table.page
    dispatch(startLoading())
    const headCells = headCellsExamField
    let type = []
    if (_.get(eExamDashboard, 'examFieldDrawer.isCompany')) {
      type = [...type, 'station']
    }
    if (_.get(eExamDashboard, 'examFieldDrawer.isAssociation')) {
      type = [...type, 'association']
    }
    try {
      const body = {
        limit: _.get(table, 'limit', '100'),
        page: realPage,
        type: type,
      }
      await callAxios
        .post(examinationDashboardExamField, body)
        .then(({ data }) => {
          const results = _.get(data, 'results', [])
          const countStation = _.get(data, 'countStation', 0)
          const countAssoc = _.get(data, 'countAssoc', 0)
          let countExamField = 0
          const checkCompany = _.get(
            eExamDashboard,
            'examFieldDrawer.isCompany',
          )
          const checkAssoc = _.get(
            eExamDashboard,
            'examFieldDrawer.isAssociation',
          )
          if (checkCompany) {
            countExamField += countStation
          }
          if (checkAssoc) {
            countExamField += countAssoc
          }
          if (!checkCompany && !checkAssoc) {
            countExamField += countStation + countAssoc
          }
          store.dispatch(
            setFieldValue({
              key: 'eExamDashboard.examFieldDrawer.countTotal',
              value: countExamField,
            }),
          )
          dispatch(
            setInitialTable({
              rows: prepareRow(results),
              allCount: _.get(data, 'totalCount', 0),
              headCells: headCells,
              hideFilter: true,
              isCheckBox: false,
            }),
          )
          dispatch(setSelected([]))
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    }
    dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, drawerType, courseType, keyword, startDate, endDate, type, status) =>
  (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(
        fetchData({
          table,
          drawerType,
          courseType,
          keyword: keyword,
          startDate,
          endDate,
          type,
          status,
        }),
      )
    } else {
      dispatch(resetPage())
    }
  }

export const onDownload =
  (selected, drawerType, courseType) => async (dispatch) => {
    dispatch(startLoading())
    const body = {
      type: drawerType ? 'COURSE' : 'LEARNER',
      courseType: courseType === 'all' ? '' : courseType,
      list: selected,
    }

    await dispatch(
      downloadFile({
        url: eLearningDashboardAllCourseDownload,
        body: body,
        fileName: `รายการหลักสูตร.xlsx`,
      }),
    )
    dispatch(stopLoading())
  }

const prepareRow = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
    }
  })
}
