import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { StyledBox } from '../Styled'
import { hasPermissionDefaultAndMonthlyPlanArea } from '../../../../../utils/lib'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table2'

const Action = ({ row, rowIndex, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { viewPath, editPath } = condition
  const status = _.get(row, 'status')
  const viewUrl = `${viewPath}/${row.uuid}`
  const editUrl = `${editPath}/${row.uuid}`
  const [user] = useLocalStorage('user')
  const hasPermissionEdit = hasPermissionDefaultAndMonthlyPlanArea({
    user,
    manageClass: row,
  })
  const canEdit =
    hasPermissionEdit &&
    status !== 'CANCEL' &&
    status !== 'CANCELED' &&
    status !== 'CLOSED'

  return (
    <StyledBox>
      <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => dispatch(setOnView({ history, viewUrl }))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>

      {canEdit && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Action
