import { StyledRow } from '../../../Styled'
import React from 'react'
import useTheme from '@mui/system/useTheme'
import { Typography } from '@mui/material'
import _ from 'lodash'

export default function ColorInformation() {
  const theme = useTheme()

  const typeBookingList = [
    { color: theme.palette?.warning?.main, label: 'MONTHLY PLAN' },
    { color: theme.palette?.axa?.teal, label: 'MANAGE CLASS' },
    { color: theme.palette?.primary?.main, label: 'E-BOOKING' },
  ]
  return (
    <StyledRow
      sx={{
        background: '#F4FAFF',
        justifyContent: 'center',
        borderRadius: 1,
        gap: { sm: 2, xs: 0 },
      }}
    >
      {_.map(typeBookingList, (type) => (
        <StyledRow
          key={type.label}
          sx={{ gap: 0.75, alignItems: 'center', px: { sm: 2, xs: 1 }, py: 1 }}
        >
          <div
            style={{
              backgroundColor: type.color,
              width: 10,
              height: 10,
              borderRadius: '50%',
            }}
          />
          <Typography
            sx={{ typography: { sm: 'body2b', xs: 'body3b' }, fontWeight: 500 }}
            variant="body2"
          >
            {type.label}
          </Typography>
        </StyledRow>
      ))}
    </StyledRow>
  )
}
