import React from 'react'
import Info from './Info'
import Point from './Point'
import Lesson from './Lesson'

const CourseTab = () => {
  return (
    <>
      <Info />
      <Point />
      <Lesson />
    </>
  )
}
export default CourseTab
