export const arrayCheckbox = [
  {
    label: 'คำนำหน้า',
    nameCheckBox: 'titleChecked',
    nameInput: 'TITLE',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'fNameChecked',
    nameInput: 'FNAME',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lNameChecked',
    nameInput: 'LNAME',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'วันที่เข้าสอบ',
    nameCheckBox: 'examDateChecked',
    nameInput: 'examDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    startDate: 'startExamDate',
    finishDate: 'finishExamDate',
    type: 'date',
  },
  {
    label: 'CID',
    nameCheckBox: 'cidChecked',
    nameInput: 'CID',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ประเภท',
    nameCheckBox: 'typeChecked',
    nameInput: 'TYPE',
    placeholder: 'ค้นหา',
    type: 'text',
  },
]

export const defaultFilter = {
  fNameChecked: false,
  lNameChecked: false,
  FNAME: '',
  LNAME: '',
  examDateChecked: false,
  startExamDate: '',
  finishExamDate: '',
  resultChecked: false,
  importDateChecked: false,
  startImportDate: '',
  finishImportDate: '',
  createdByChecked: false,
  displayDate1: false,
  displayDate2: false,
}
