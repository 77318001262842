import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import LinearProgress from '@mui/material/LinearProgress'
import styled from '@mui/system/styled'

export const StyledFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(0.5) },
}))

export const StyledContentButton = styled(StyledFlex)(
  ({ theme, canStartLearn }) => ({
    justifyContent: 'space-between',
    button: {
      width: canStartLearn ? 'calc(50% - 8px)' : '100%',
      padding: `${theme.spacing(1)} 0`,
    },
  })
)

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueBorder,
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: 22,
  span: { padding: 0 },
  '& .MuiChip-label': { padding: `1px ${theme.spacing(1)}` },
}))

export const StyledTypography = styled(Typography)(({ variant }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: variant === 'overline' ? '1' : '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  lineHeight: '24px',
}))

export const StyledMediaBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 181,
  overflow: 'hidden',
  borderRadius: theme.spacing(0.5),
  margin: 'auto',
  '& > div': {
    width: '100% !important',
    height: '100% !important',
    pointerEvents: 'none',
  },
  img: { height: '100%', objectFit: 'cover', borderRadius: theme.spacing(0.5) },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 200,
    padding: 0,
  },
}))

export const StyledLikeIcon = styled('img')(() => ({
  position: 'absolute',
  right: 0,
  width: '17px !important',
  height: 17,
}))

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: 9,
  background: 'transparent',
  border: '1px solid #DADADA',
}))
