import React, { useMemo } from 'react'
import _ from 'lodash'
import TextInput from '../../../../components/Input/TextInput'
import { StyledBoxLayout, StyledBoxTextInput, StyledContainer } from './Styled'
import {
  handleAddTextInput,
  handleDefaultIsSelete,
  handleRemoveInput,
  handleTextInput,
} from './events'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'

import Add from '@mui/icons-material/Add'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'

const DocumentForm = ({ documentation, setInitialValues, formik }) => {
  const documentList = _.get(documentation, 'documentList', [])
  const errorsDocumentList = _.get(formik, 'errors.documentList', [])

  const debouncedStringChange = useMemo(
    () =>
      _.debounce(
        (e, setInitialValues, uuid) =>
          handleTextInput(e, setInitialValues, documentation, uuid),
        700
      ),
    [documentation]
  )

  return (
    <StyledContainer>
      {!_.isEmpty(documentList) &&
        _.map(documentList, (item, key) => {
          if (item?.isDefault) {
            return (
              <FormControlLabel
                key={key}
                checked={item?.isSelect}
                control={
                  <Checkbox
                    onChange={() =>
                      handleDefaultIsSelete(
                        setInitialValues,
                        documentation,
                        item.uuid
                      )
                    }
                  />
                }
                label={item.name}
              />
            )
          }
          return (
            <StyledBoxTextInput key={item.uuid}>
              <TextInput
                name={`InputTextDocument-${item.uuid}`}
                type="basic"
                required
                labelText={`เอกสาร ${key - 1}`}
                placeholder="โปรดระบุ"
                defaultValue={item?.name ?? ''}
                textError={_.get(errorsDocumentList, `[${key}].name`, '')}
                onChange={(e) =>
                  debouncedStringChange(e, setInitialValues, item.uuid)
                }
              />
              <IconButton
                data-testid="btn-delete"
                color="primary"
                component="span"
                onClick={() =>
                  handleRemoveInput(setInitialValues, documentation, item.uuid)
                }
              >
                <DeleteTwoTone color="action" />
              </IconButton>
            </StyledBoxTextInput>
          )
        })}
      <StyledBoxLayout>
        <Box>
          <Button
            data-testid="btn-add"
            startIcon={<Add />}
            size="s"
            variant="text"
            onClick={() => handleAddTextInput(setInitialValues, documentation)}
          >
            เพิ่มเอกสาร
          </Button>
        </Box>
      </StyledBoxLayout>
    </StyledContainer>
  )
}

export default DocumentForm
