import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  StyledColumn,
  StyledRow,
} from '../../../../../modules/RoomManagement/Styled'
import { Detail } from './PopUp'

export const PopUpHoliday = () => {
  const { item } = useSelector(
    (state) => ({
      item: state.roomManagement.PopupEventSummary,
    }),
    shallowEqual,
  )
  return (
    <StyledColumn sx={{ mt: 4 }}>
      <StyledRow>
        <Detail
          label="วันที่สร้าง"
          value={dayjs(item.createdAt).format('DD/MM/YYYY')}
        />
      </StyledRow>
      <StyledRow>
        <Detail label="สร้างโดย" value={item.createdBy} />
      </StyledRow>
      <StyledRow>
        <Detail
          label="วันที่แก้ไข"
          value={item.updatedAt && dayjs(item.updatedAt).format('DD/MM/YYYY')}
        />
      </StyledRow>
      <StyledRow>
        <Detail label="แก้ไขโดย" value={_.get(item, 'updatedBy', '')} />
      </StyledRow>
    </StyledColumn>
  )
}
