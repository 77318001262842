import _ from 'lodash'
import { setChangeBody } from '../../../../../../redux/slices/manageContent/station'

export const handleInputChange = (e, setValue) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  setValue((prev) => ({ ...prev, [key]: value }))
}

export const handleInputNumberChange = (e, setValue) => {
  const value = _.get(e.target, 'value', '')
    .trim()
    .replace(/[^0-9]/g, '')
  setValue((prev) => ({ ...prev, phoneNumber: value }))
}

export const handleSubmitData = (value, index) => (dispatch) => {
  const newValue = {
    ...value,
    title: _.get(value, 'title', '').trim(),
    description: _.get(value, 'description', '').trim(),
    googleMap: _.get(value, 'googleMap', '').trim(),
    phoneNumber: _.get(value, 'phoneNumber', '').trim(),
    remark: _.get(value, 'remark', '').trim(),
  }
  const payload = { value: newValue, key: `stationInformation[${index}]` }
  dispatch(setChangeBody(payload))
}
