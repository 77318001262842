export const arrayCheckbox = [
  {
    label: 'รหัสตัวแทน',
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    placeholder: 'ค้นหารหัสตัวแทน',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อ',
    type: 'text',
  },
]

export const defaultFilter = {
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
}
