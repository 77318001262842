import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Launch from '@mui/icons-material/Launch'
import RefreshTwoTone from '@mui/icons-material/RefreshTwoTone'
import { handleNumberInput } from '../../../../../../modules/CourseSetting/TestAndEvaluateSetting/TestAndEvaluateForm/ETestingForm/events'
import { MODULE_TYPE } from '../../../../../../constants/eLearning'
import Autocomplete from '../../../../../../components/Input/Autocomplete'
import TextInput from '../../../../../../components/Input/TextInput'
import {
  StyledFormCard,
  StyledFormColumn,
  StyledFormRow,
} from '../../../../Styled'
import { getStateValue, handleChange, handleChangeEvent } from '../../events'
import StatusSwitch from '../StatusSwitch'
import { handleAutocompleteDefault } from '../events'
import { TextLabel } from '..'
import {
  fetchETestingList,
  handleInitialFetch,
  handleRadioChange,
} from './events'
import { StyledPercentInput } from './Styled'
import { CRITERIA_TYPE, E_TESTING_TYPE } from './model'

const ETesting = () => {
  const { id: moduleUuid } = useParams()
  const typeField = _.camelCase(MODULE_TYPE.E_TESTING)
  const keyType = `${typeField}.type`
  const keyUuid = `${typeField}.uuid`
  const dispatch = useDispatch()
  const { isOptionLoading, option, type, uuid, onGoing } = useSelector(
    (state) => ({
      isOptionLoading: state.eLearningModuleForm.isOptionLoading,
      option: state.eLearningModuleForm.option,
      type: getStateValue(state, keyType),
      uuid: getStateValue(state, keyUuid),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const name = useMemo(
    () => handleAutocompleteDefault(option, uuid),
    [option, uuid],
  )
  const { errors } = useFormikContext()

  useEffect(() => {
    dispatch(handleInitialFetch(moduleUuid, keyType))
  }, [])

  return (
    <StyledFormCard isLoading={isOptionLoading}>
      <Typography variant="h6">E-Testing</Typography>
      <StyledFormColumn>
        <FormControl>
          <TextLabel label="เลือกประเภทแบบทดสอบ" />
          <RadioGroup
            row
            name={keyType}
            sx={{ ml: 1.5, gap: 5 }}
            value={type}
            onChange={(e) => dispatch(handleRadioChange(e, typeField))}
          >
            <FormControlLabel
              value={E_TESTING_TYPE.ONLINE_TEST}
              control={<Radio />}
              label="Online Test"
              disabled={onGoing}
            />
            <FormControlLabel
              value={E_TESTING_TYPE.STANDALONE}
              control={<Radio />}
              label="Standalone"
              disabled={onGoing}
            />
          </RadioGroup>
        </FormControl>
        {!_.isEmpty(type) && (
          <StyledFormRow>
            <Autocomplete
              name={keyUuid}
              labelText="แบบทดสอบ"
              options={[...option]}
              errorSx={{ mb: 0 }}
              renderInput={(params) => (
                <TextField
                  placeholder="กรุณาเลือกแบบทดสอบ"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'select-e-testing',
                  }}
                />
              )}
              textError={_.get(errors, keyUuid, '')}
              showTextError={_.get(errors, keyUuid, '')}
              defaultValue={name}
              handleChange={(e) => dispatch(handleChange(keyUuid, e.target.id))}
              disabled={onGoing}
              required
            />
            <IconButton
              sx={{ mt: 3 }}
              onClick={() => dispatch(fetchETestingList(type))}
            >
              <RefreshTwoTone color="primary" />
            </IconButton>
            <Button
              variant="outlined"
              startIcon={<Launch />}
              sx={{ width: 173, minWidth: 173, mt: 3 }}
              onClick={() =>
                window.open(`/e-testing/form/${_.kebabCase(type)}`, '_blank')
              }
            >
              สร้างแบบทดสอบ
            </Button>
          </StyledFormRow>
        )}
        {type === E_TESTING_TYPE.ONLINE_TEST && (
          <ETestingCriteria typeField={typeField} />
        )}
      </StyledFormColumn>
      <Divider />
      <StatusSwitch />
    </StyledFormCard>
  )
}

export default ETesting

export const ETestingCriteria = ({ typeField, sx }) => {
  const keyCriteriaType = `${typeField}.criteriaType`
  const keyCriteriaPercent = `${typeField}.criteriaPercent`
  const { criteriaType, criteriaPercent, onGoing } = useSelector(
    (state) => ({
      criteriaType: getStateValue(state, keyCriteriaType),
      criteriaPercent: getStateValue(state, keyCriteriaPercent).toString(),
      onGoing: state.eLearningModuleForm.onGoing,
    }),
    shallowEqual,
  )
  const { errors } = useFormikContext()
  const dispatch = useDispatch()

  return (
    <FormControl sx={sx}>
      <TextLabel label="เกณฑ์คะแนนผ่าน" />
      <RadioGroup
        name={keyCriteriaType}
        sx={{ ml: 1.5, gap: 1 }}
        value={criteriaType}
        onChange={(e) => dispatch(handleChangeEvent(e))}
      >
        <FormControlLabel
          value={CRITERIA_TYPE.REQUIRED}
          control={<Radio />}
          label="ต้องทำ"
          disabled={onGoing}
        />
        <FormControlLabel
          value={CRITERIA_TYPE.REQUIRED_PERCENT}
          control={<Radio />}
          label="ต้องทำ และผ่านตามเกณฑ์ (% ของคะแนนทั้งหมด)"
          disabled={onGoing}
        />
        {criteriaType === CRITERIA_TYPE.REQUIRED_PERCENT && (
          <TextInput
            boxSx={{ width: 290, ml: 3 }}
            sx={StyledPercentInput(criteriaPercent)}
            id="input-criteria-percent"
            name={keyCriteriaPercent}
            type="basic"
            placeholder="%"
            inputProps={{
              type: 'number',
              min: '0',
              max: '100',
              onInput: handleNumberInput,
            }}
            value={criteriaPercent}
            textError={_.get(errors, keyCriteriaPercent, '')}
            isShowTextError={_.get(errors, keyCriteriaPercent, '')}
            onChange={(e) => dispatch(handleChangeEvent(e))}
            disabled={onGoing}
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}
