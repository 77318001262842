import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'

import Score from '../Score'
import Question from '../Question'
import DragDropAnswer from '../DragDropAnswer'
import AddAnswer from '../AddAnswer'
import AlertErrorBox from '../AlertErrorBox'
import * as StyledForm from '../Styled'
import * as event from './event'
import { getErrorMessage } from '../event'

const Index = ({ keyQuestion, indexQuestion }) => {
  const dispatch = useDispatch()
  const { question, errorSchemaMessage } = useSelector(
    (state) => ({
      question: state.eTestingForm.questions.find(
        (item) => item.key === keyQuestion
      ),
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { answers } = question
  const correctErrorMessage = getErrorMessage({
    field: 'CORRECT',
    errorSchemaMessage,
    indexQuestion,
  })

  return (
    <>
      <Card>
        <StyledForm.BoxForm>
          <Question keyQuestion={keyQuestion} indexQuestion={indexQuestion} />
          {correctErrorMessage !== '' && <AlertErrorBox />}
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1b">คำตอบ</Typography>
            </Box>
            {answers.length > 0 && (
              <RadioGroup
                sx={{ mt: -1 }}
                aria-label="radioCheck"
                name="radio-buttons-group"
                value={event.findValueRadio(answers)}
                onChange={(e) =>
                  dispatch(
                    event.handleRadioChange({
                      value: _.get(e, 'target.value', ''),
                      keyQuestion,
                      answers,
                    })
                  )
                }
              >
                <DragDropAnswer
                  keyQuestion={keyQuestion}
                  indexQuestion={indexQuestion}
                />
              </RadioGroup>
            )}
            <AddAnswer keyQuestion={keyQuestion} />
          </Box>
        </StyledForm.BoxForm>

        <Score keyQuestion={keyQuestion} />
      </Card>
    </>
  )
}
export default Index
