import React from 'react'
import { Box, shouldForwardProp } from '@mui/system'
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material'

export const StyledUploadContainer = styled(
  Box,
  shouldForwardProp,
)(({ theme, isError }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
  padding: theme.spacing(4),
  border: '2px dashed',
  borderColor: isError ? theme.palette?.error?.main : 'rgba(0, 0, 0, 0.12)',
  borderRadius: theme.spacing(1),
}))

export const StyledColumnTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  '& #fileName': {
    color: theme.palette?.primary?.main,
    width: 200,
    overflow: 'hidden',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

export const StyledInputHidden = styled('input')({ display: 'none' })

export const StyledCropperWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 486,
  height: 324,
  background: theme.palette?.text?.primary,
  marginBottom: '16px',
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '18px',
  },
}))
export const StyledDeleteImage = styled(Box)(({ theme, disabled }) => ({
  padding: theme.spacing(0.5),
  background: disabled
    ? theme.palette?.text?.lightGray
    : theme.palette?.error?.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  cursor: disabled ? 'default' : 'pointer',
  borderRadius: '100%',
  width: 20,
  height: 20,
  top: 30,
  left: 'calc(50% - 120px)',
  svg: { color: theme.palette?.background?.paper, fontSize: '12px' },
  zIndex: 999,
}))

export const StyledLabelPercent = styled(Typography)(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}))