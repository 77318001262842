import React from 'react'
import QuizOutlined from '@mui/icons-material/QuizOutlined'
import RecordVoiceOverOutlined from '@mui/icons-material/RecordVoiceOverOutlined'
import Rule from '@mui/icons-material/Rule'
import SchoolOutlined from '@mui/icons-material/SchoolOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'

export const courseInclude = {
  attendance: {
    icon: <TimerOutlined color="primary" fontSize="large" />,
    label: 'เช็คชื่อ',
  },
  preTest: {
    icon: <QuizOutlined color="primary" fontSize="large" />,
    label: 'แบบทดสอบก่อนอบรม',
  },
  postTest: {
    icon: <Rule color="primary" fontSize="large" />,
    label: 'แบบทดสอบหลังอบรม',
  },
  evaluation: {
    icon: <RecordVoiceOverOutlined color="primary" fontSize="large" />,
    label: 'แบบประเมินการอบรม',
  },
  assessment: {
    icon: <RecordVoiceOverOutlined color="primary" fontSize="large" />,
    label: 'แบบฝึกหัด',
  },
  eCert: {
    icon: <SchoolOutlined color="primary" fontSize="large" />,
    label: 'ประกาศนียบัตร',
  },
}
