import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined'

import { TooltipDialog } from './Styled'

const ActionAnnounce = ({ row }) => {
  const status = _.get(row, 'waitingAnnounce', '')

  return (
    <Box sx={{ display: 'flex' }}>
      {status && (
        <TooltipDialog
          title={
            <Typography variant="tooltip">
              รายการมีการเปลี่ยนแปลง (รออนุมัติ)
            </Typography>
          }
          arrow
          placement="bottom"
        >
          <HourglassTopOutlinedIcon
            sx={{
              color: 'warning.main',
              cursor: 'pointer',
            }}
          />
        </TooltipDialog>
      )}
    </Box>
  )
}

export default ActionAnnounce
