import { getOptionsReportByKey } from '../../../../../services/reportComp'
import { store } from '../../../../../App'
import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const fetchOptionsReportByKey = async (
  columnSelected,
  eTestVersionUuid,
  courseCode,
) => {
  let moduleId = 'REPORT_MIX_MODULE'
  let modulePath = '/mix-module'
  let type = ''
  switch (columnSelected) {
    case 'unitCode':
      type = 'UNITCODE'
      break
    case 'userProfileUuid':
      type = 'STAFF'
      break
    case 'sRam':
      type = 'SRAM'
      break
    case 'courseCode':
      type = 'COURSE'
      break
    case 'classUuid':
      type = 'COURSE_DETAIL'
      break
    default:
      type = ''
  }
  let body = {
    columnSelected: columnSelected,
    moduleId: moduleId,
  }
  if (['UNITCODE', 'STAFF', 'SRAM', 'COURSE', 'COURSE_DETAIL'].includes(type)) {
    modulePath = '/e-testing/dashboard'
    body = {
      eTestVersionUuid: eTestVersionUuid,
      type: type,
      courseCode: type === 'COURSE_DETAIL' ? courseCode : '',
    }
  }

  let { payload: response } = await store.dispatch(
    getOptionsReportByKey({
      modulePath: modulePath,
      body: body,
    }),
  )

  if (type === 'COURSE_DETAIL') {
    store.dispatch(
      setFieldValue({
        key: `filterList[${columnSelected}]`,
        value: _.get(response, 'results', []),
      }),
    )
  }
  if (type === 'COURSE') {
    const options = _.get(response, 'results', [])
    let newResponse = [...options]
    for (const [index, data] of options.entries()) {
      switch (data.type) {
        case 'ELEARNING':
          data.type = 'E-Learning'
          break
        case 'CLASS':
          data.type = 'Classroom'
          break
      }

      newResponse[index] = data
    }
    response.results = newResponse
  }
  if (columnSelected === 'ramCode') {
    const options = _.get(response, 'results', [])
    let newResponse = [...options].sort((a, b) =>
      a.label.localeCompare(b.label),
    )
    response.results = newResponse.map((data) => {
      return { label: data.label, value: data.label }
    })
  }
  return _.get(response, 'results', [])
}
