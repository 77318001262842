import React from 'react'
import _ from 'lodash'
import Add from '@mui/icons-material/Add'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Switch from '@mui/material/Switch'

import * as Styled from './Styled'
import { handleAddQuestion, handleSuggestion } from './event'

const Index = ({ type }) => {
  const dispatch = useDispatch()

  const { questionByType, isSuggestion, disableForm } = useSelector(
    (state) => ({
      questionByType: _.get(state, `eEvaluationForm.${type}`, []),
      isSuggestion: _.get(
        state,
        `eEvaluationForm.${type}[0].isSuggestion`,
        false
      ),
      disableForm:
        state.eEvaluationForm.disableForm || state.eEvaluationForm.onViewMode,
    }),
    shallowEqual
  )
  const textColor = disableForm ? 'text.gray' : 'primary'

  return (
    <>
      <Styled.BoxAddQuestion
        isLength={!!questionByType}
        data-testid="add-question-evaluation"
        onClick={() => !disableForm && dispatch(handleAddQuestion({ type }))}
      >
        <Add color={textColor} />
        <Typography
          sx={{ ml: 0.5, mt: 0.1 }}
          variant="buttons"
          color={textColor}
        >
          เพิ่มคำถาม
        </Typography>
      </Styled.BoxAddQuestion>
      <Styled.BoxLabel>
        <Typography variant="body1b">ตัวเลือก</Typography>
        <Styled.LabelFeedback>
          ดีมาก, ดี, ปานกลาง, พอใช้, ควรปรับปรุง
        </Styled.LabelFeedback>
      </Styled.BoxLabel>
      <Divider />
      <Styled.BoxSwitch>
        <Typography variant="body1">
          กล่อง ข้อเสนอแนะ/ความคิดเห็นเพิ่มเติม
        </Typography>
        <Switch
          disabled={disableForm}
          data-testid="switch-suggestion"
          checked={isSuggestion}
          onClick={(e) =>
            !disableForm &&
            dispatch(
              handleSuggestion({
                key: `${type}[0].isSuggestion`,
                value: _.get(e, 'target.checked', false),
              })
            )
          }
        />
      </Styled.BoxSwitch>
    </>
  )
}
export default Index
