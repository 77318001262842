import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { StyledHeaderContainer } from '../../Styled'
import { StyledButton } from './Styled'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Edit from '@mui/icons-material/Edit'
import { MODULE_STATUS } from '../../../../constants/learningPoint'
import { path } from '../../../../constants/path'
import HistoryDrawer from '../../HistoryDrawer'

const Header = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const [isOpen, setOpen] = useState(false)
  const { status } = useSelector(
    (state) => ({ status: state.catalogForm.view.status }),
    shallowEqual,
  )
  const isDeleted = status === MODULE_STATUS.DELETED

  return (
    <StyledHeaderContainer>
      <StyledButton dataTestid="btn-history" onClick={() => setOpen(true)}>
        <HistoryTwoTone />
        ประวัติการแก้ไข
      </StyledButton>
      {!isDeleted && (
        <StyledButton
          variant="contained"
          dataTestid="btn-edit"
          onClick={() => history.push(`${path.catalog}/edit/${uuid}`)}
        >
          <Edit />
          แก้ไข
        </StyledButton>
      )}
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </StyledHeaderContainer>
  )
}

export default Header
