import { crudConfig } from './crudConfig'

export const contentBreadcrumbList = [
  { title: 'E-learning', link: '/', pointer: false },
  { title: 'Collection', link: '/', pointer: false },
]

export const createContentBreadcrumbList = [
  { title: 'E-learning', link: '/', pointer: false },
  { title: 'Collection', link: '/e-learning/collection', pointer: true },
  {
    title: 'สร้าง Collection',
    link: `${crudConfig.modulePath}/form`,
    pointer: false,
  },
]

export const contentTitle = {
  TEXT: 'ข้อความ',
  FILE: 'ไฟล์',
  LINK: 'ลิงก์มีเดีย',
  IMAGE: 'รูปภาพ',
}

export const contentTypeEnum = {
  COURSE: {
    name: 'หลักสูตร',
    key: 'COURSE',
  },
  LEARNING_PATH: {
    name: 'Learning Path',
    key: 'LEARNING_PATH',
  },
}
