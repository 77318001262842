
export const manageOtherDocsName = (oldDocs, newDoc) => {
    // add number to name
    let myArray = []
    if(newDoc){
        myArray = [ ...oldDocs, newDoc]
    }else{
        myArray = [ ...oldDocs ]
    }
    const groupDocs = []
    const myArray_ = myArray.map((item) => {
        if(!groupDocs[item.documentName]){
            groupDocs[item.documentName] = 0
        }
        return {
            ...item,
            type: item.documentName,
        }
    }, {})
    const newArr = myArray_.map((item) => {
        groupDocs[item.type] = groupDocs[item.type] + 1
        return {
            ...item,
            order: groupDocs[item.type],
        }
    })
    return newArr
}

