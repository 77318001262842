import React, { useRef, useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import {
  handleClose,
  handleMoreMenu,
  handleToggleDelete,
  getMoreOptionsByPermission,
} from './events'
import { StyledPopper, DialogDelete } from './Styled'
import { InputImportEva } from '../Styled'
import { handleImportTemplate } from '../event'

export const DialogContentDelete = () => {
  const dispatch = useDispatch()
  const [toggleDelete, setToggleDelete] = useState(false)

  return (
    <DialogDelete>
      <Typography variant="body1">
        การลบข้อมูล จะ
        <span style={{ color: 'red' }}>ไม่มีผลย้อนหลัง </span>
        กับหลักสูตร หรือคลาสที่เลือกไปใช้งานแล้ว หลักสูตรหรือคลาสนั้นๆ
        ยังคงสามารถใช้งานต่อไปได้ จนกว่าจะมีการเปลี่ยนแปลง ที่หน้าจัดการหลักสูตร
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox-status"
              defaultChecked={false}
              color="error"
              checked={toggleDelete}
              onChange={(e) => dispatch(handleToggleDelete(e, setToggleDelete))}
            />
          }
          label="ฉันรับทราบเงื่อนไขการลบข้อมูลแล้ว"
        />
      </Box>
    </DialogDelete>
  )
}

const DropdownButton = ({ disabled }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { setting, questions, eEvaluationType } = useSelector(
    (state) => ({
      setting: state.eEvaluationForm.setting,
      questions: state.eEvaluationForm.questions,
      eEvaluationType: state.eEvaluationForm.eEvaluationType,
    }),
    shallowEqual
  )
  const moreOptionsByPermission = getMoreOptionsByPermission(setting, disabled)

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="dropdown"
        data-testid="btn-icon-dropdown"
        disabled={disabled}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <MoreVert />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, anchorRef, setOpen)}
              >
                <MenuList autoFocusItem>
                  {moreOptionsByPermission.map((option, index) => (
                    <>
                      <label
                        key={index}
                        htmlFor={
                          option.method !== 'import'
                            ? `menu-dropdown-${index}`
                            : 'btn-import-template-eva'
                        }
                      >
                        <MenuItem
                          component="span"
                          data-testid={
                            option.method !== 'import'
                              ? `menu-dropdown-${index}`
                              : 'btn-import-template-eva'
                          }
                          onClick={() =>
                            option.method !== 'import' &&
                            dispatch(
                              handleMoreMenu(
                                option.method,
                                uuid,
                                history,
                                eEvaluationType
                              )
                            )
                          }
                        >
                          <Typography variant="body1" component="span">
                            {option.text}
                          </Typography>
                        </MenuItem>

                        {option.method === 'import' && (
                          <InputImportEva
                            data-testid="btn-import-template-eva"
                            id="btn-import-template-eva"
                            accept=".xlsx, .xls, .csv"
                            type="file"
                            onChange={(dataImport) =>
                              dispatch(
                                handleImportTemplate({
                                  dataImport,
                                  questions,
                                })
                              )
                            }
                          />
                        )}
                      </label>
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default DropdownButton
