import { store } from '../../../../../App'
import {
  replaceFieldError,
  setFieldError,
  setFieldValue,
} from '../../../../../redux/slices/crud'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'
import { openDialog } from '../../../../../redux/slices/dialog'
import { handleProcessSubmitForm } from './handleProcessSubmitForm'

const DIALOG_TITLE = 'ยืนยันบันทึกและเผยแพร่'
const DIALOG_MESSAGE = 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่'

const DIALOG_TITLE_DRAFT = 'ยืนยันบันทึกฉบับร่าง'
const DIALOG_MESSAGE_DRAFT = 'คุณต้องการบันทึกฉบับร่างข้อมูลนี้หรือไม่'

export async function validateFormData(
  formData,
  validationSchema,
  assetData,
  isDraft,
) {
  resetError()
  let checkAsset = true
  store.dispatch(
    setFieldValue({
      key: 'isAssetError',
      value: false,
    }),
  )
  if (!isDraft && assetData?.length <= 0) {
    checkAsset = false
    store.dispatch(
      setFieldValue({
        key: 'isAssetError',
        value: true,
      }),
    )
  }
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    if (!checkAsset) {
      handleScrollToError('assets')
      return false
    }
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}

function confirmDialog(uuid, postBodyMutation, isDraft) {
  store.dispatch(
    openDialog({
      title: isDraft ? DIALOG_TITLE_DRAFT : DIALOG_TITLE,
      message: isDraft ? DIALOG_MESSAGE_DRAFT : DIALOG_MESSAGE,
      handleConfirm: () =>
        handleProcessSubmitForm(uuid, postBodyMutation, isDraft),
      isCloseDialog: false,
    }),
  )
}

export const handleSubmitForm = async (props) => {
  const { uuid, validationSchema, isDraft } = props
  const { formData, assetData } = store.getState().crud
  const isValid = await validateFormData(
    formData,
    validationSchema,
    assetData,
    isDraft,
  )
  if (isValid) {
    confirmDialog(uuid, formData, isDraft)
  }
}

const resetError = () => {
  store.dispatch(replaceFieldError([]))
  store.dispatch(
    setFieldValue({
      key: 'isAssetError',
      value: false,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'isAssetDuplicate',
      value: false,
    }),
  )
}
