import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { closePointDialog } from '../../../../../../redux/slices/manage/agentProfile'
import { Row, Column, PointBox, StyledCard, StyledImage } from './Styled'
import { convertNumberWithComma } from '../../../../../../utils/lib'
import { handleRedeem } from './events'

const Confirmation = () => {
  const dispatch = useDispatch()
  const { userUuid, point, cart, cost, remaining } = useSelector(
    (state) => ({
      userUuid: state.agentProfile.initialState.info.uuid,
      point: state.agentProfile.initialState.point,
      cart: state.agentProfile.pointDialog.cart,
      cost: state.agentProfile.pointDialog.cost,
      remaining: state.agentProfile.pointDialog.remaining,
    }),
    shallowEqual,
  )
  const { table } = useSelector((state) => state.table)

  return (
    <Column>
      <PointBox>
        <Typography variant="body1b" sx={{ textAlign: 'center' }}>
          จำนวนคะแนนสะสมปัจจุบัน
        </Typography>
        <Row sx={{ alignItems: 'center', justifyContent: 'center', gap: 3 }}>
          <Typography variant="h5">{convertNumberWithComma(point)}</Typography>
          <Typography variant="body1">คะแนน</Typography>
        </Row>
      </PointBox>
      <StyledCard sx={{ mt: 0 }}>
        <Typography variant="body1b" color="primary.main" sx={{ mb: 1.5 }}>
          รายการของรางวัลที่แลก
        </Typography>

        {cart.map((ele, ind) => {
          return (
            <Row
              key={ind}
              sx={{
                mb: 1.5,
                py: 1.5,
                alignItems: 'center',
                borderBottom: '1px solid #DBE4F1',
              }}
            >
              <StyledImage
                src={`${window.__env__.REACT_APP_API_URL}/file/get${ele.image?.key}`}
              />
              <Typography sx={{ mr: 3 }}>{ele.name}</Typography>
              <Typography
                sx={{ width: 80, ml: 'auto', textAlign: 'right' }}
              >{`${convertNumberWithComma(ele.point)} คะแนน`}</Typography>
              <Typography
                sx={{ width: 48, ml: 12, textAlign: 'right' }}
              >{`x ${ele.quantity} ชิ้น`}</Typography>
            </Row>
          )
        })}

        <Row sx={{ justifyContent: 'space-between', mt: 2.5 }}>
          <Typography variant="body1b">จำนวนคะแนนที่ใช้แลกของรางวัล</Typography>
          <Row sx={{ gap: '15px' }}>
            <Typography variant="body1b">
              {convertNumberWithComma(cost)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </Row>
        </Row>

        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body1b">จำนวนคะแนนคงเหลือ</Typography>
          <Row sx={{ gap: '15px' }}>
            <Typography variant="body1b">
              {convertNumberWithComma(remaining)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </Row>
        </Row>

        <Divider />
      </StyledCard>
      <Row sx={{ gap: 3, mb: 3, justifyContent: 'center' }}>
        <Button
          variant="outlined"
          size="m"
          sx={{ minWidth: 144 }}
          onClick={() => dispatch(dispatch(closePointDialog()))}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="m"
          sx={{ minWidth: 144 }}
          disabled={cart.length <= 0}
          onClick={() =>
            dispatch(handleRedeem({ userUuid, cart, cost, table, point }))
          }
        >
          ยืนยัน
        </Button>
      </Row>
    </Column>
  )
}

export default Confirmation
