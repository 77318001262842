import { resetForm } from '../../../../../redux/slices/manageClassLearner'
import { openDialog } from '../../../../../redux/slices/dialog'
import _ from 'lodash'

export const handleIsCanDelete = (row) => {
  const status = _.get(row, 'status', '')
  return status != 'CANCEL' && status != 'CANCELED'
}

export const handleDeleteDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      title: 'ลบผู้เรียนออกจากรายการ',
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
    }),
  )
}
