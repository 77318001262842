export const COLUMN_NAME = {
  NO: 'no',
  START_DATE: 'startDate',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  COURSE_VERSION: 'courseVersion',
  BREAK_MEAL_BUDGET: 'breakMealBudget',
  ATTENDANCE_COUNT: 'attendanceCount',
  ATTENDANCE_CRITERIA: 'attendanceCriteria',
  QR_CODE_TIME: 'qrCodeTime',
  COUNT_HRS: 'countHrs',
  COURSE_CODE: 'courseCode',
  COURSE_NAME_EN: 'courseNameEN',
  COURSE_NAME_TH: 'courseNameTH',
  COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
  CERTIFICATE_NAME_TH: 'certificateNameTH',
  CERTIFICATE_NAME_EN: 'certificateNameEN',
  PRE_TEST_NAME: 'preTestName',
  POST_TEST_NAME: 'postTestName',
  ASSESSMENT_NAME: 'assessmentName',
  OTHER_TEST_NAME: 'otherTestName',
  EVALUATION_NAME: 'evaluationName',
  DOCUMENT_LIST: 'documentList',
  STAFF_ID_LIST: 'staffIdList',
  STAFF_NAME_LIST: 'staffNameList',
  STAFF_ID_1: 'staffId_1',
  STAFF_ID_2: 'staffId_2',
  STAFF_ID_3: 'staffId_3',
  STAFF_ID_4: 'staffId_4',
  STAFF_ID_5: 'staffId_5',
  STAFF_NAME_1: 'staffName_1',
  STAFF_NAME_2: 'staffName_2',
  STAFF_NAME_3: 'staffName_3',
  STAFF_NAME_4: 'staffName_4',
  STAFF_NAME_5: 'staffName_5',
  CREATED_BY: 'createdBy',
  UPDATED_BY: 'updatedBy',
  DISTRIBUTION: 'distribution',
  ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
  ROADMAP: 'roadMap',
  ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
  LEVEL_OF_LEARNER: 'levelOfLearner',
  OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
  TRAINING_PLATFORM: 'trainingPlatform',
  ACQUIRED_SKILL: 'acquiredSkill',
  PRODUCT_TYPE: 'productType',
  COURSE_LEVEL_CONTROL: 'courseLevelControl',
  IS_APPROVED: 'isApproved',
  HAS_PREREQUISITE: 'hasPrerequisite',
  HAS_BREAK_MEAL: 'hasBreakMeal',
  REQUIRE_OIC_EXAM: 'requireOicExam',
  AGENT_CONFIRMATION: 'agentConfirmation',
  IS_ATTENDANCE: 'isAttendance',
  HAS_DOCUMENT: 'hasDocument',
  STATUS: 'status',
  REQUIRE_DOCUMENT: 'requireDocument',
  COURSE_OUTLINE: 'courseOutline',
  COURSE_PURPOSE: 'coursePurpose',
}

export const COLUMN_NAME_TH = {
  NO: 'No',
  START_DATE: 'วันเริ่มต้น',
  CREATED_AT: 'วันที่สร้าง',
  UPDATED_AT: 'วันที่แก้ไขล่าสุด',
  COURSE_VERSION: 'เวอร์ชัน',
  BREAK_MEAL_BUDGET: 'ค่าใช้จ่ายต่อหัว',
  ATTENDANCE_COUNT: 'จำนวนการเช็คชื่อ',
  ATTENDANCE_CRITERIA: 'เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)',
  QR_CODE_TIME: 'อายุของ QR Code',
  COUNT_HRS: 'จำนวนชั่วโมง (ไม่รวมพักเที่ยง)',
  COURSE_CODE: 'รหัสหลักสูตร',
  COURSE_NAME_EN: 'ชื่อหลักสูตร (EN)',
  COURSE_NAME_TH: 'ชื่อหลักสูตร (TH)',
  COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
  CERTIFICATE_NAME_TH: 'E-Certificate Template',
  CERTIFICATE_NAME_EN: 'ชื่อ Certificate',
  PRE_TEST_NAME: 'แบบทดสอบ (Pre-Test)',
  POST_TEST_NAME: 'แบบทดสอบ (Post-Test)',
  ASSESSMENT_NAME: 'แบบทดสอบ (Assessment)',
  OTHER_TEST_NAME: 'แบบทดสอบ (Other)',
  EVALUATION_NAME: 'แบบประเมิน',
  DOCUMENT_LIST: 'รายการเอกสาร',
  STAFF_ID_LIST: 'Staff ID ผู้จัดทำ',
  STAFF_NAME_LIST: 'ชื่อผู้จัดทำ',
  STAFF_ID_1: 'Staff ID ผู้จัดทำ (1)',
  STAFF_ID_2: 'Staff ID ผู้จัดทำ (2)',
  STAFF_ID_3: 'Staff ID ผู้จัดทำ (3)',
  STAFF_ID_4: 'Staff ID ผู้จัดทำ (4)',
  STAFF_ID_5: 'Staff ID ผู้จัดทำ (5)',
  STAFF_NAME_1: 'ชื่อผู้จัดทำ (1)',
  STAFF_NAME_2: 'ชื่อผู้จัดทำ (2)',
  STAFF_NAME_3: 'ชื่อผู้จัดทำ (3)',
  STAFF_NAME_4: 'ชื่อผู้จัดทำ (4)',
  STAFF_NAME_5: 'ชื่อผู้จัดทำ (5)',
  CREATED_BY: 'สร้างโดย',
  UPDATED_BY: 'แก้ไขล่าสุดโดย',
  DISTRIBUTION: 'ช่องทางการจำหน่าย',
  ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
  ROADMAP: 'กลุ่มหลักสูตร',
  ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
  LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
  OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
  TRAINING_PLATFORM: 'รูปแบบของการสอน',
  ACQUIRED_SKILL: 'ระดับหลักสูตร',
  PRODUCT_TYPE: 'ทักษะที่ได้จากหลักสูตร',
  COURSE_LEVEL_CONTROL: 'ระดับการควบคุมหลักสูตร',
  IS_APPROVED: 'การอนุมัติหลักสูตร',
  HAS_PREREQUISITE: 'เงื่อนไขการเข้าอบรม',
  HAS_BREAK_MEAL: 'การเตรียมอาหาร Break / Lunch',
  REQUIRE_OIC_EXAM: 'ผลสอบใบอนุญาตตัวแทน',
  AGENT_CONFIRMATION: 'ขั้นตอนการคอนเฟิร์มตัวแทน',
  IS_ATTENDANCE: 'เกณฑ์การเข้าเรียน',
  HAS_DOCUMENT: 'เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม',
  STATUS: 'สถานะ',
  REQUIRE_DOCUMENT: 'เอกสารการสมัคร',
  COURSE_OUTLINE: 'โครงร่างหลักสูตร',
  COURSE_PURPOSE: 'จุดประสงค์หลักสูตร',
}

export const TYPE_OF_VALUE = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT_TIME: 'INPUT_TIME',
  INPUT_NUMBER: 'INPUT_NUMBER',
  INPUT_NUMBER_RANGE: 'INPUT_NUMBER_RANGE',
  INPUT_TEXT_BOX: 'INPUT_TEXT_BOX',
  INPUT_TEXT: 'INPUT_TEXT',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
}

export const COURSE_FOR = {
  ALL: 'ALL',
  AGENCY: 'AGENCY',
  BA: 'BA',
  KTB: 'KTB',
  OTHER: 'OTHER',
}

export const SHOW_COURSE_TYPE = {
  SORTED_BY_UPDATED: 'SORTED_BY_UPDATED',
  COURSE_ENROLL: 'COURSE_ENROLL',
  COURSE_OPENED: 'COURSE_OPENED',
}

export const DISTRIBUTION = {
  AGENCY: 'Agency',
  BA: 'Banc',
  KTB: 'KTB',
}

export const ABBREVIATION_DISTRIBUTION = {
  AGENCY: 'AGC',
  BA: 'BAC',
  KTB: 'KTB',
}
