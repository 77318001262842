import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { eLearningQuestion } from '../../../utils/apiPath'

export const getDetailQuestion = createAsyncThunk(
  'eLearningQuestionDetail/getgetDetailQuestionDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningQuestion}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putAnswerQuestion = createAsyncThunk(
  'eLearningQuestionDetail/putAnswerQuestion',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningQuestion, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
