import { Box, Typography } from '@mui/material'

const CustomChipLabel = ({ label = '', boxSx = {} }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#F1F4FA',
        borderRadius: 4,
        width: 'fit-content',
        px: 1.25,
        py: 1,
        display: 'flex',
        alignItems: 'center',
        ...boxSx,
      }}
    >
      <Typography variant="body2b" color="primary">
        {label}
      </Typography>
    </Box>
  )
}

export default CustomChipLabel
