import { breadcrumb } from './model/breadcrumb'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDetail } from '../../../../components/CRUD/handler/fetchDetail'
import { useSelector } from 'react-redux'
import { crudConfig, crudConfig as initialCrudConfig } from '../crudConfig'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { CPageViewContainer } from '../../../../components/CRUD/components/PageViewContainer'
import { ActionComponent } from './components/ViewHeaderAction'
import { ViewContent } from './Content'
import CHistoryDrawer from '../../../../components/CRUD/components/History'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { getBodyMutation } from '../Form/mutation/get'

export const EContentSubCategoryView = () => {
  const { uuid } = useParams()
  const { formData, isLoading } = useSelector((state) => state.crud)

  useEffect(() => {
    initialConfig(initialCrudConfig)
    if (uuid && !_.isUndefined(crudConfig))
      fetchDetail(uuid, getBodyMutation).then()
  }, [])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageViewContainer
        isLoading={isLoading}
        header={
          <CHeader
            title={`รายละเอียด${crudConfig.moduleTitleFull}`}
            breadcrumbs={breadcrumb}
            action={<ActionComponent formData={formData} uuid={uuid} />}
          />
        }
        content={<ViewContent />}
      />

      <CHistoryDrawer />
    </>
  )
}
