import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../components/Breadcrumbs'
import Table from '../../components/redux/Table'
import { fetchDataList } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledHeader,
} from './Styled'
import { breadcrumbProspect } from './model'
import FilterDrawer from './FilterDrawer'

const ProspectProfile = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const [isInitial, setInitial] = useState(true)

  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      fetchDataList(
        'initial',
        table,
        page,
        status,
        filterProp,
        searchText,
        setInitial,
        'AGENT'
      )
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList('fetch', table, page, status, filterProp, searchText)
      )
    }
  }, [limit, order, page, sort])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box>
            <Typography variant="h4">
              ข้อมูล Prospect (Prospect Profile)
            </Typography>
            <Breadcrumbs menuList={breadcrumbProspect(uuid)} />
          </Box>
        </StyledHeader>

        <StyledCard>
          <Table />
        </StyledCard>
        <FilterDrawer open={isFilterDrawer} table={table} type="PROSPECT" />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default ProspectProfile
