import { useState } from 'react'
import { StyledCard } from '../../Styled'
import { CarHeader } from '../components/CardHeader'
import { StyledRow } from '../../../Styled'
import { BookingDate } from './components/BookingDate'
import { Label } from './components/Label'
import { BookingForm } from './components/BookingForm'

export const DetailsBookingCard = () => {
  const [isShowContent, setIsShowContent] = useState(true)

  return (
    <StyledCard>
      <CarHeader
        no={2}
        title="รายละเอียดการจอง"
        isShowContent={isShowContent}
        setIsShowContent={setIsShowContent}
      />
      {isShowContent && (
        <>
          <StyledRow sx={{ gap: 2, alignItems: 'stretch' }}>
            <Label />
            <BookingDate field="startDate" />
            <BookingDate field="endDate" />
          </StyledRow>
          <BookingForm />
        </>
      )}
    </StyledCard>
  )
}
