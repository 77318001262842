import React, { useRef } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { Form, Formik } from 'formik'
import Editor from '../../../../../components/Editor/BasicEditor'

import UploadImage from './UploadImage'
import {
  StyledContent,
  StyledHeadLabel,
  StyledFooter,
} from '../../../HomePage/Form/Styled'
import TextInput from '../../../../../components/Input/TextInput'
import * as Styled from './Styled'
import { schema } from './schema'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { handleDrawer, handleSunmitDrawer, matchColor } from '../events'
import { handleEditorBlur } from '../../../HomePage/Form/Banner/BannerDrawer/events'

const Drawer = () => {
  const titleRef = useRef(null)
  const descriptionRef = useRef(null)
  const dispatch = useDispatch()

  const { drawer, loginPages } = useSelector(
    (state) => ({
      drawer: state.manageContentLogin.drawer,
      loginPages:
        state.manageContentLogin.loginPages[
          state.manageContentLogin.drawer.index
        ],
    }),
    shallowEqual
  )

  return (
    <Styled.BoxDrawer
      open={drawer.isOpen}
      onClose={() => dispatch(handleDrawer({ isClose: true }))}
    >
      <Formik
        initialValues={loginPages}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          dispatch(
            handleSunmitDrawer({
              values,
              indexData: _.get(drawer, 'index', 0),
            })
          )
        }}
      >
        {({ values, errors, setValues }) => (
          <Form>
            <StyledContent>
              <StyledHeadLabel>
                <Box id="header">
                  <Typography variant="h5">Banner {values.sequence}</Typography>
                </Box>
                <Divider />
              </StyledHeadLabel>

              <Box p={3} pb={0}>
                <Box pb={3}>
                  <Typography variant="h6">
                    Background {values.sequence}
                  </Typography>
                </Box>
                {/* btn image */}
                <UploadImage />

                <Divider sx={{ borderStyle: 'dashed' }} />
                <Box pb={3} mt={1}>
                  <Typography variant="h6">
                    Content {values.sequence}
                  </Typography>
                </Box>

                <BannerEditorLogin
                  required={true}
                  isTextLefe={true}
                  isChange={true}
                  isPopperColor={true}
                  isPasteAsText={true}
                  label="Title"
                  editorRef={titleRef}
                  showMaxLength={255}
                  value={_.get(values, 'title', '')}
                  onChange={() =>
                    handleEditorBlur(titleRef, 'title', setValues)
                  }
                  colorValue={_.get(values, 'colorTitle', '#7F7F7F')}
                  onChangeColor={(value) => {
                    setValues((prev) => ({
                      ...prev,
                      colorTitle: matchColor(value),
                    }))
                  }}
                />
                <BannerEditorLogin
                  isTextLefe={true}
                  required={true}
                  isChange={true}
                  isPopperColor={true}
                  isPasteAsText={true}
                  toolbar="undo redo"
                  label="Description"
                  showMaxLength={3000}
                  editorRef={descriptionRef}
                  value={_.get(values, 'description', '')}
                  onChange={() =>
                    handleEditorBlur(descriptionRef, 'description', setValues)
                  }
                  colorValue={_.get(values, 'colorDescription', '#7F7F7F')}
                  onChangeColor={(value) => {
                    setValues((prev) => ({
                      ...prev,
                      colorDescription: matchColor(value),
                    }))
                  }}
                />
                <Box display="flex" flexDirection="column">
                  <TextInput
                    id="remark"
                    name="remark"
                    labelText="Remark"
                    type="basic"
                    rows={3}
                    multiline={true}
                    isShowTextError={false}
                    value={_.get(values, 'remark', '')}
                    textError={_.get(errors, 'remark', '')}
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        remark: _.get(e, 'target.value', ''),
                      }))
                    }
                  />
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      variant="body2"
                      color={
                        _.get(errors, 'remark', '') ||
                        _.get(values, 'remark', '').length > 3000
                          ? 'error'
                          : 'text.primary'
                      }
                    >
                      ระบุข้อความความยาวไม่เกิน 3000 ตัวอักษร
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <StyledFooter>
                <Button
                  data-testId="btn-cancel"
                  variant="outlined"
                  onClick={() => {
                    setValues(loginPages)
                    dispatch(handleDrawer({ isClose: true }))
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  data-testId="btn-submit"
                  variant="contained"
                  type="submit"
                >
                  ตกลง
                </Button>
              </StyledFooter>
            </StyledContent>
          </Form>
        )}
      </Formik>
    </Styled.BoxDrawer>
  )
}

export default Drawer

export const BannerEditorLogin = (props) => {
  const {
    label,
    textInfo,
    editorRef,
    textError,
    onBlur,
    onChange,
    value,
    showMaxLength,
    isTextLefe,
    isChange,
    isPopperColor,
    onChangeColor,
    colorValue,
    isPasteAsText,
    required,
  } = props
  return (
    <Editor
      required={!required}
      labelText={label}
      id={`editor-${label}`}
      name={label}
      editorRef={editorRef}
      toolbar={'undo redo'}
      height={label == 'Description' ? 145 : 110}
      textInfo={textInfo}
      textError={textError}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      showMaxLength={showMaxLength}
      isTextLefe={isTextLefe}
      isChange={isChange}
      isPopperColor={isPopperColor}
      colorValue={colorValue}
      onChangeColor={onChangeColor}
      isPasteAsText={isPasteAsText}
    />
  )
}
