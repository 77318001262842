import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(() => ({
  width: '100%',
  borderLeft: 'none',
  borderRight: 'none',
}))

export const BoxContainer = styled(Box)(({ theme }) => ({
  maxHeight: 260,
  marginRight: theme.spacing(1),
  overflowY: 'auto',
  overflowX: 'none',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme?.palette?.text?.secondary,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme?.palette?.secondary?.contrast,
    boxShadow: 'inset 1px 0px 0px #DEDEDE',
  },
}))

export const BoxColor = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme?.palette?.action?.disabledBackground,
  border: `1px solid ${theme?.palette?.silver?.primary}`,
}))

export const BoxLabel = styled(Box)(({ theme, isFirst }) => ({
  maxHeight: 100,
  display: 'flex',
  marginLeft: theme.spacing(2),
  marginTop: isFirst ? theme.spacing(1) : 0,
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme?.palette?.text?.secondary,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme?.palette?.secondary?.contrast,
    boxShadow: 'inset 1px 0px 0px #DEDEDE',
  },
}))
