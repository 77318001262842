import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledHeaderTitle = styled(Box)(({ theme, section }) => ({
  width: section == 'RESULT' ? 'calc(100% - 130px)' : '100%',
  display: 'flex',
  flexDirection: 'column',
  '& > .MuiTypography-root': {
    color: theme.palette?.text?.white,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.down('sm')]: {
    width: section == 'RESULT' ? 'calc(100% - 100px)' : '100%',
  },
}))
