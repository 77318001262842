import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../../../redux/slices/crud'
import TabMenu from './TabMenu'
import { StyledFormWrapper } from '../../Styled'

import Setting from './Setting'
import { Collection } from './Collection'

export const ViewContent = () => {
  const tab = useSelector((state) => state.crud.tab)
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab !== 'collection' && tab !== 'setting') {
      dispatch(setFieldValue({ key: 'tab', value: 'collection' }))
    }
  }, [tab])

  return (
    <>
      <TabMenu tab={tab} />
      <ViewTabContent />
    </>
  )
}

export const ViewTabContent = () => {
  const tab = useSelector((state) => state.crud.tab)
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === 'collection' && <Collection />}
      {tab === 'setting' && <Setting />}
    </StyledFormWrapper>
  )
}
