import React, { useEffect, useState, useContext } from 'react'
import useTheme from '@mui/system/useTheme'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'

import Table from '../../components/Table'
import FilterDrawer from './FilterDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import { DialogContext } from '../../context/dialog-context'
import {
  distributionChannel,
  distributionDownload,
  distributionFilter,
  filterLevelOfLearner,
} from '../../utils/apiPath'
import { callAPI, handleDownload } from '../../utils/lib'
import { hasPermissionCreateMasterData } from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '50px',
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจำหน่าย',
    disablePadding: false,
  },
  {
    id: 'abbreviation',
    label: 'ชื่อย่อ',
    disablePadding: false,
    width: '100px',
    minWidth: '100px',
  },
  {
    id: 'level',
    label: 'ระดับของผู้เรียน',
    disablePadding: false,
    isArray: true,
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ช่องทางการจำหน่าย', link: '/', pointer: false },
]

export const handleAddClick = (history) => {
  history.push('/master-data/distribution-channel/form')
}
export const onView = (row, history) => {
  history.push('/master-data/distribution-channel/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/distribution-channel/edit/' + row.uuid)
}

export const onDelete = async (row, setIsLoading, setDialog, handleDelete) => {
  setIsLoading(false)
  setDialog({
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    open: true,
    onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const handleDelete = async (row, setIsLoading, setDialog) => {
  const path = distributionChannel + `/${row.uuid}`
  setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onError: (er) => {
      try {
        const message = _.get(er, 'response.data.message', '')
        setDialog({
          open: true,
          variant: 'fail',
          content: message,
          onConfirmClick: () =>
            setDialog({
              variant: 'fail',
              content: message,
              open: false,
            }),
        })
      } catch (ex) {
        console.log(ex)
      }
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const onFilterClick = async ({
  limit,
  order,
  sort,
  filter,
  setSearchText,
  setText,
  setStatus,
  setTablePage,
  fetchDistributionList,
  setFilterTotal,
}) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)
  const distributionText = filter?.distributionChecked
    ? filter.distributionText
    : ''
  const abbreviationText = filter?.abbreviationChecked
    ? filter.abbreviationText
    : ''

  const objLevelOfLearner = filter?.levelOfLearner || {}
  let listLevelOfLearner = []

  if (filter.levelOfLearnerChecked && Object.keys(objLevelOfLearner).length) {
    for (let keyIndex in objLevelOfLearner) {
      const value = objLevelOfLearner[keyIndex]
      if (value.status) {
        listLevelOfLearner.push(value.uuid)
      }
    }
  }

  setSearchText(distributionText)
  setTablePage(0)
  fetchDistributionList(
    'filter',
    statusList,
    limit,
    order,
    1,
    sort,
    distributionText,
    abbreviationText,
    listLevelOfLearner
  )
  setFilterTotal(handleFilterTotal(filter))
}

export const filterLevelName = ({ listLevel }) => {
  let result = []
  if (listLevel.length) {
    listLevel.filter((item) => {
      result.push(item.title || '')
    })
  }
  return result
}

const DistributionList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [levelOfLearnerList, setLevelOfLearnerList] = useState([])
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })

  useEffect(() => {
    setIsPageLoading(true)
    fetchDistributionList('fetch', status, limit, order, page, sort)
  }, [limit, order, sort, page])

  const onDownload = async (selected) => {
    setIsLoading(true)
    const body = {
      abbreviation: '',
      distribution: '',
      level: [],
      order: order.toUpperCase(),
      sort: sort === '' ? 'updatedAt' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
    }
    await handleDownload({
      url: distributionDownload,
      body: body,
      fileName: `รายการช่องทางการจำหน่าย.xlsx`,
    })
    setIsLoading(false)
  }

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    const statusList = []
    fetchDistributionList('search', statusList, limit, order, 1, sort, text)
  }

  const getLevelOfLearnerList = async () => {
    const body = {
      limit: -1,
      page: 1,
      order: 'ASC',
      sort: 'id',
      title: '',
      status: ['ACTIVE'],
    }
    callAPI({
      url: filterLevelOfLearner,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setLevelOfLearnerList(res.result || [])
      },
    })
  }

  const fetchDistributionList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    distributionText = '',
    abbreviationText = '',
    listLevelOfLearner = []
  ) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      abbreviation: method === 'filter' ? abbreviationText : '',
      distribution: method !== 'fetch' ? distributionText : searchText,
      level: method === 'filter' ? listLevelOfLearner : [],
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      status: statusList,
    }
    await getLevelOfLearnerList()
    await callAPI({
      url: distributionFilter,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        let resultFilter = res.result || []
        if (resultFilter.length) {
          resultFilter = resultFilter.filter((item) => {
            item.level = filterLevelName({ listLevel: item.level || [] })
            return item
          })
        }
        setDataList(resultFilter)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            ช่องทางการจำหน่าย (Distribution Channel)
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Button
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          disabled={!hasPermissionCreate}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleAddClick(history)}
        >
          เพิ่มช่องทางการจำหน่าย
        </Button>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={dataList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            onDelete={(row) => {
              onDelete(row, setIsLoading, setDialog, handleDelete)
            }}
            placeholder="ค้นหาช่องทางการจำหน่าย"
            searchKey={'distribution'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={handleQuickSearch}
            onDownload={onDownload}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) => {
          onFilterClick({
            limit,
            order,
            sort,
            filter,
            setSearchText,
            setText,
            setStatus,
            setTablePage,
            fetchDistributionList,
            setFilterTotal,
          })
        }}
        levelOfLearnerList={levelOfLearnerList}
      />
    </Box>
  )
}
export default DistributionList
