import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Header from './Header'
import Content from './Content'
import { fetchDataList } from './events'
import { LoadingPage, BoxContainer } from '../../Styled'
import { StyledContent } from '../Styled'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()

  const { isLoading, uploadDoc } = useSelector(
    (state) => state.manageClassLearnerDocument,
  )

  useEffect(() => {
    dispatch(fetchDataList(uuid))
  }, [uploadDoc])

  return (
    <BoxContainer>
      <StyledContent isLoading={isLoading}>
        <Header />
        <Content />
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </BoxContainer>
  )
}

export default Index
