import _ from 'lodash'
import { eCertificationBackgroundFilter } from '../../../utils/apiPath'
import { backgroundHeadCells } from '../../../constants/eCertification'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'

export const fetchDataList =
  (method, table, page, statusList, filterProp, keyword, isInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      status: isInitial ? [] : statusList,
      name: method === 'filter' ? keyword : title,
      codeId: _.get(filter, 'codeId', ''),
      numberOfVersions: _.get(filter, 'numberOfVersions', -1),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
    }

    const isCancel = await callAxios
      .post(eCertificationBackgroundFilter, body)
      .then(({ data }) => {
        data.result.map(
          (item) => (item.updatedBy = _.get(item, 'updatedBy.name', '-'))
        )
        dispatch(
          setInitialTable({
            isCheckBox: false,
            rows: data.result,
            allCount: data.totalCount,
            headCells: backgroundHeadCells,
            placeholder: 'ค้นหาชื่อภาพพื้นหลัง',
            maxLength: 255,
            searchKey: 'name',
            status: statusList,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
            onDownload: 'hidden',
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: backgroundHeadCells,
          })
        )
        return e.message?.includes('method')
      })

    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  } else {
    statusList.push('ACTIVE', 'DELETED', 'INACTIVE')
  }
  dispatch(setStatusFilter(statusList))

  const filterText = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    numberOfVersions: filter.numberOfVersionsChecked
      ? +filter.numberOfVersions
      : -1,
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList('filter', table, 1, statusList, filterText, filterText.name)
  )
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(fetchDataList('search', table, 1, statusList, null, text))
}
