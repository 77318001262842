import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const BoxLabel = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
