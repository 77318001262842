import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledFooter } from './Styled'
import ButtonForOne from './ButtonForOne'
import ButtonForAll from './ButtonForAll'
import { handleAnswerNumber } from './events'

const Footer = ({ isModule }) => {
  const { displayQuestion, testing, page } = useSelector(
    (state) => ({
      displayQuestion: state.eTestingPreview.setting.displayQuestion,
      testing: state.eTestingPreview.testing,
      page: state.eTestingPreview.page,
    }),
    shallowEqual
  )
  const answerAmount = useMemo(
    () => handleAnswerNumber(displayQuestion, testing, page),
    [testing, page]
  )
  return (
    <StyledFooter>
      <Box>
        <Typography
          id="progress-text"
          variant="buttonl"
          color="text.white"
          value={answerAmount}
        >
          แบบทดสอบ {answerAmount} / {testing.length} ข้อ
        </Typography>
        {displayQuestion == 'ALL' ? (
          <ButtonForAll isModule={isModule} />
        ) : (
          <ButtonForOne isModule={isModule} />
        )}
      </Box>
    </StyledFooter>
  )
}

export default Footer
