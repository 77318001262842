import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { getViewData } from './events'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from '../Styled'
import { breadcrumbListView } from '../model'
import Header from './Header'
import Content from './Content'

const View = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { isLoading } = useSelector((state) => state.catalogForm)
  useEffect(() => {
    dispatch(getViewData(uuid))
  }, [])
  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="h4">รายละเอียด Catalog</Typography>
            </Box>
            <Breadcrumbs menuList={breadcrumbListView} />
            <Header />
          </Box>
        </StyledHeader>
        <StyledCard>
          <Content />
        </StyledCard>
      </StyledContent>
    </StyledContainer>
  )
}

export default View
