import React from 'react'
import _ from 'lodash'
import { openDialog } from '../../../../redux/slices/dialog'
import { startLoading, stopLoading } from '../../../../redux/slices/table'
import * as service from '../../../../services/apiToRcms/index'
import CheckDialog from '../CheckDialog'

export const handleInputFile = (e, fetchData, id) => async (dispatch) => {
  dispatch(startLoading())
  const body = new FormData()
  if (e.target.files.length === 0) return
  body.append('rcmsUuid', id)
  body.append('file', e.target.files[0])
  const getNameImage = _.get(e, 'target.files[0].name', '')

  if (getNameImage) {
    const sizeFile = _.get(e, 'target.files[0].size', 0)
    if (sizeFile > 2000000) return
  }

  const response = await dispatch(service.uploadFileDetail(body))
  if (!response.error) {
    const uploadResult = _.get(response, 'payload.data', {})
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'md',
        disableButton: true,
        content: (
          <CheckDialog
            uploadResult={uploadResult}
            fetchData={fetchData}
            id={id}
          />
        ),
      })
    )
  }
  _.set(document.getElementById('btn-add-file'), 'value', null)
  dispatch(stopLoading())
}
