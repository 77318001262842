import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import _ from 'lodash'
import useTheme from '@mui/system/useTheme'

export default function Index(props) {
  const {
    name,
    dataTestid,
    boxSx,
    labelSx = {},
    labelText,
    required,
    placeholder,
    disabled,
    textErrorSx = {},
    textError,
    showTextError = true,
    options,
    handleChange,
    value,
    defaultValue,
    selectSx,
    handleClick,
    handleClose,
    isSelected = [],
  } = props
  const theme = useTheme()
  const filteredOptions = options?.filter(
    (opt) => !isSelected.includes(opt?.uuid)
  )

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          sx={{ ...labelSx }}
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Select
          name={name}
          onOpen={handleClick}
          onChange={handleChange}
          onClose={handleClose}
          value={value}
          displayEmpty
          disabled={disabled}
          inputProps={{
            id: 'uncontrolled-native',
            'data-testid': dataTestid,
          }}
          defaultValue={defaultValue}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={(selectValue) => {
            if (!selectValue || selectValue.length === 0 || options === null) {
              if (!_.isNil(options) && !selectValue) {
                const findValue = options?.find((item) => item.value === value)
                if (!findValue) {
                  return (
                    <Box color="text.silver" sx={{ px: 1 }}>
                      {placeholder}
                    </Box>
                  )
                }
                return <Box sx={{ px: 1 }}>{_.get(findValue, 'label', '')}</Box>
              }
              return (
                <Box color="text.silver" sx={{ px: 1 }}>
                  {placeholder}
                </Box>
              )
            } else {
              const findValue = options?.find(
                (item) => item?.value === selectValue
              )
              return (
                <Box
                  sx={{
                    px: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {findValue?.label}
                </Box>
              )
            }
          }}
          sx={{
            ...selectSx,
            '& .MuiOutlinedInput-notchedOutline, &:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline':
              {
                borderColor:
                  textError &&
                  textError != '' &&
                  `${theme?.palette?.error?.main} !important`,
              },
          }}
        >
          {filteredOptions &&
            filteredOptions.length > 0 &&
            filteredOptions.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?.label}-${index}`}
                  value={item?.value}
                  name={item?.label}
                  disabled={item?.disabled}
                >
                  {item?.labelText ? item?.labelText : item?.label}
                </MenuItem>
              )
            })}
        </Select>
        {showTextError && (
          <Box sx={{ mb: 3, ...textErrorSx }}>
            {textError && textError != '' && (
              <Typography variant="body2" color="error">
                {textError}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
