import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import dayjs from 'dayjs'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { LIST_TYPE_DATE } from '../../Text'
import { checkChangeDate } from '../Handle'
import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import DatePicker from '../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import { convertFormatDateTime } from '../../../../utils/lib'

export const checkPermission = (permissionAll, otherLeavePICPermission) => {
  if (permissionAll || otherLeavePICPermission) return false

  return true
}
export default function Leave({
  user,
  options,
  validateForm,
  setValidateForm,
  stateForm,
  setStateForm,
  optionSubWorkType,
  uuidStaff,
  state,
  type,
}) {
  const [stateDate, setStateDate] = useState(
    _.get(stateForm, 'leave.leavePeriod.value', [])
  )

  const [statePermission, setStatePermission] = useState(false)
  useMemo(() => {
    const staffProfile = _.get(options, 'staffProfile', [])
    if (staffProfile.length) {
      const createLeaveALLPermission = user.permission.find(
        (item) =>
          (item.permission === 'CREATE_ALL' ||
            item.permission === 'CREATE_ALL_EXC_HOLIDAY' ||
            item.permission === 'CREATE_LEAVE') &&
          item.owner === 'ALL' &&
          item.module === 'MONTHLY_PLAN'
      )

      const otherLeavePICPermission = user.permission.find(
        (item) =>
          (item.permission === 'CREATE_ALL_EXC_HOLIDAY' ||
            item.permission === 'CREATE_ALL') &&
          item.owner === 'PIC' &&
          item.module === 'MONTHLY_PLAN'
      )

      setStatePermission(
        checkPermission(createLeaveALLPermission, otherLeavePICPermission)
      )

      const uuid =
        createLeaveALLPermission || otherLeavePICPermission
          ? uuidStaff
          : _.get(user, 'uuid', '')
      const defaultStaff = staffProfile.find((item) => {
        return item.value === uuid
      })
      const staff = _.get(stateForm, 'leave.staff', [])
      let newStaff = [...staff]
      if (defaultStaff && staff.length <= 0) {
        newStaff = [...staff, defaultStaff]
      }

      setStateForm((prevState) => ({
        ...prevState,
        leave: {
          ...prevState.leave,
          staff: _.uniqBy(newStaff, 'value'),
        },
      }))
    }
  }, [])
  useEffect(() => {
    getDates(stateForm, setStateDate, setStateForm)
  }, [stateForm.leave.startDate, stateForm.leave.finishDate])

  const findSubWorkType = optionSubWorkType.find((item) => {
    return item.uuid === _.get(stateForm, 'leave.subWorkType', '')
  })
  const workType = _.get(stateForm, 'leave.workType', '')
  const workTypeValue = type === 'edit' ? workType.workType : workType

  return (
    <form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 1,
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Select
            dataTestid="select-work"
            id="work"
            name="work"
            labelText="ประเภทตารางงาน"
            type="basic"
            required
            disabled={true}
            options={options.work}
            placeholder="กรุณาเลือก"
            value={workTypeValue}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Select
            dataTestid="select-subWorkType"
            id="subWorkType"
            name="subWorkType"
            labelText="ประเภทวันลา"
            type="basic"
            required
            options={optionSubWorkType}
            placeholder="กรุณาเลือก"
            handleChange={(event) =>
              onChangeForm(
                event,
                'subWorkType',
                false,
                setStateForm,
                setValidateForm
              )
            }
            textError={_.get(validateForm, 'leave.subWorkType', '')}
            value={_.get(stateForm, 'leave.subWorkType', '')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: stateForm.leave.subWorkType === 'Annual Leave' ? 2 : 1,
          mb: 1,
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <DatePicker
            id="startDate"
            name="startDate"
            labelText="ตั้งแต่วันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            onChange={(value) =>
              onDateChange(value, 'startDate', setStateForm, setValidateForm)
            }
            value={
              _.get(stateForm, 'leave.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.startDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'leave.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'leave.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.finishDate', null)
            }
            isShouldDisableDate={true}
            textError={_.get(validateForm, 'leave.startDate', '')}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <DatePicker
            id="finishDate"
            name="finishDate"
            labelText="ถึงวันที่"
            disabledMonthlyPlan={
              type === 'edit' ? undefined : _.get(state, 'data', undefined)
            }
            required
            onChange={(value) =>
              onDateChange(value, 'finishDate', setStateForm, setValidateForm)
            }
            value={
              _.get(stateForm, 'leave.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.finishDate', null)
            }
            disabledStartDate={
              _.get(stateForm, 'leave.startDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.startDate', null)
            }
            disabledEndDate={
              _.get(stateForm, 'leave.finishDate', null) === 'Invalid Date'
                ? null
                : _.get(stateForm, 'leave.finishDate', null)
            }
            isShouldDisableDate={true}
            textError={_.get(validateForm, 'leave.finishDate', '')}
          />
        </Box>
      </Box>
      {stateForm.leave.startDate && stateForm.leave.finishDate && (
        <>
          <Box sx={{ width: '100%' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" sx={{ display: 'flex' }}>
                ช่วงเวลาการลา
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </FormLabel>
              <RadioGroup
                data-testid="radio-period"
                aria-label="gender"
                defaultValue={_.get(
                  stateForm,
                  'leave.leavePeriod.type',
                  'FULLDAY'
                )}
                name="radio-buttons-group"
                onChange={(event) =>
                  onChangeRadio(event, 'leavePeriod', stateDate, setStateForm)
                }
              >
                <FormControlLabel
                  data-testid="radio-period-fullday"
                  value="FULLDAY"
                  control={<Radio />}
                  label="เต็มวัน"
                />
                <FormControlLabel
                  data-testid="radio-period-custom"
                  value="CUSTOM"
                  control={<Radio />}
                  label="กำหนดเอง"
                />
              </RadioGroup>
            </FormControl>

            {stateForm.leave.leavePeriod.type === 'CUSTOM' && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
                  <Box sx={{ width: 120 }}>
                    <Typography variant="body2" color={'text.secondary'}>
                      วันที่
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3 }}>
                    <Typography variant="body2" color={'text.secondary'}>
                      วันที่
                    </Typography>
                    <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                      *
                    </Typography>
                  </Box>
                </Box>

                <FormBetweenDate
                  type={stateForm.leave.leavePeriod.type}
                  stateDate={stateDate}
                  stateForm={stateForm}
                  setStateForm={setStateForm}
                  onChangeDayType={onChangeDayType}
                />
              </>
            )}
          </Box>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 1,
          mb: 0,
          gap: 3,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <TextInput
            id="remarkLeave"
            name="remarkLeave"
            labelText="เหตุผลการลา"
            type="basic"
            required={
              _.get(findSubWorkType, 'label', '') === 'Other Leave'
                ? true
                : false
            }
            onChange={(event) =>
              onChangeForm(
                event,
                'remarkLeave',
                false,
                setStateForm,
                setValidateForm
              )
            }
            textError={_.get(validateForm, 'leave.remarkLeave', '')}
            value={_.get(stateForm, 'leave.remarkLeave', '')}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '100%', gap: 3 }}>
          <TextInput
            id="remark"
            name="remark"
            labelText="หมายเหตุ"
            type="basic"
            onChange={(event) =>
              onChangeForm(
                event,
                'remark',
                false,
                setStateForm,
                setValidateForm
              )
            }
            multiline={true}
            rows={2}
            textError={_.get(validateForm, 'leave.remark', '')}
            value={_.get(stateForm, 'leave.remark', '')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <SelectGroupChip
            id="staff"
            name="staff"
            required
            placeholder={'เลือกผู้ลา'}
            labelText="ผู้ลา"
            disabled={statePermission}
            options={options.staff}
            onChange={(event) =>
              onChangeForm(event, 'staff', true, setStateForm, setValidateForm)
            }
            textError={_.get(validateForm, 'leave.staff', '')}
            value={_.get(stateForm, 'leave.staff', '')}
          />
        </Box>
      </Box>
    </form>
  )
}

export const getDates = (stateForm, setStateDate, setStateForm) => {
  let dateArray = []
  let resultType = []

  const sDate = stateForm.leave.startDate
  const fDate = stateForm.leave.finishDate
  // console.log('sDate', sDate, fDate)
  const checkDate = checkChangeDate(sDate, fDate)
  if (!checkDate) {
    setStateDate(resultType)
    setStateForm((prevState) => ({
      ...prevState,
      leave: {
        ...prevState.leave,
        leavePeriod: {
          ...prevState.leave.leavePeriod,
          value: resultType,
        },
      },
    }))
    return false
  }

  let startDate = dayjs(stateForm.leave.startDate)
  let stopDate = dayjs(stateForm.leave.finishDate)

  let currentDate = new Date(startDate)
  while (currentDate < stopDate) {
    dateArray = [
      ...dateArray,
      dayjs(currentDate).format(window.__env__.REACT_APP_DATE_DB),
    ]
    currentDate = new Date(currentDate.getTime() + 86400000)
    stopDate = dayjs(stateForm.leave.finishDate)
  }
  dateArray = [
    ...dateArray,
    dayjs(stopDate).format(window.__env__.REACT_APP_DATE_DB),
  ]

  if (dateArray.length) {
    for (let i = 0; i < dateArray.length; i++) {
      const value = dateArray[i]
      const getDates = _.get(stateForm, 'leave.dates', [])
      const getType =
        getDates.length > 0 && i < getDates.length
          ? LIST_TYPE_DATE.find((item) => item.value === getDates[i].typeTime)
          : LIST_TYPE_DATE[0]

      resultType.push({
        date: value,
        type: getType,
      })
    }
  }

  setStateDate(resultType)

  setStateForm((prevState) => ({
    ...prevState,
    leave: {
      ...prevState.leave,
      leavePeriod: {
        ...prevState.leave.leavePeriod,
        value: resultType,
      },
    },
  }))
  return true
}

export const FormBetweenDate = ({
  type,
  stateDate,
  stateForm,
  setStateForm,
  onChangeDayType,
}) => {
  if (stateDate.length && type === 'CUSTOM') {
    return stateDate.map((item, index) => {
      const listDate = stateForm.leave.leavePeriod.value
      const objDate = listDate.find((itemDate) => {
        return itemDate.date === item.date
      })
      return (
        <Box
          key={index}
          sx={{ display: 'flex', flexDirection: 'row', mt: index ? 1 : 3 }}
        >
          <Box sx={{ width: 120 }}>
            <Typography
              variant="body2"
              color={'text.secondary'}
              sx={{ mt: 0.7 }}
            >
              {convertFormatDateTime({
                value: _.get(item, 'date', null),
                type: 'date',
              })}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', ml: 3, width: 220 }}
          >
            <Select
              dataTestid="select-date-type"
              id="dateType"
              name="dateType"
              labelText=""
              type="basic"
              options={LIST_TYPE_DATE}
              placeholder="กรุณาเลือก"
              handleChange={(event) =>
                onChangeDayType(event, item.date, stateForm, setStateForm)
              }
              value={_.get(objDate, 'type.value', 'FULL')}
              selectSx={{ height: 32 }}
            />
          </Box>
        </Box>
      )
    })
  } else {
    return <></>
  }
}

export const mapDate = (listValue, date, key) => {
  let result = []
  if (listValue.length) {
    result = listValue.filter((item) => {
      if (item.date === date) {
        item.type = LIST_TYPE_DATE.find((item) => {
          return item.value === key
        })
      }
      return item
    })
  }

  return result
}

export const onChangeDayType = (event, date, stateForm, setStateForm) => {
  const value = event.target.value
  const listValueDate = stateForm.leave.leavePeriod.value
  const listMapDate = mapDate(listValueDate, date, value)
  const result = {
    ...stateForm,
    leave: {
      ...stateForm.leave,
      leavePeriod: {
        ...stateForm.leave.leavePeriod,
        value: listMapDate,
      },
    },
  }
  setStateForm(result)
}

export const onChangeRadio = (event, key, stateDate, setStateForm) => {
  const value = event.target.value
  setStateForm((prevState) => ({
    ...prevState,
    leave: {
      ...prevState.leave,
      [key]: {
        type: event.target.value,
        value: value === 'CUSTOM' ? stateDate : [],
      },
    },
  }))
}

export const onDateChange = (event, key, setStateForm, setValidateForm) => {
  setStateForm((prevState) => ({
    ...prevState,
    leave: {
      ...prevState.leave,
      [key]: dayjs(event).format(window.__env__.REACT_APP_DATE_DB),
    },
  }))

  setValidateForm((prevState) => ({
    ...prevState,
    leave: {
      ...prevState.leave,
      startDate: '',
      finishDate: '',
    },
  }))
}

export const onChangeForm = (
  event,
  key,
  isUser,
  setStateForm,
  setValidateForm
) => {
  let value = ''
  if (isUser) {
    value = event
  } else {
    value = event.target.value
  }

  if (key === 'subWorkType' && value === 'Annual Leave') {
    let objLeavePeriod = {
      type: 'FULLDAY',
      value: [],
    }
    if (value !== 'Annual Leave') {
      objLeavePeriod = {
        type: '',
        value: [],
      }
    }
    setStateForm((prevState) => ({
      ...prevState,
      leave: {
        ...prevState.leave,
        [key]: value,
        leavePeriod: objLeavePeriod,
      },
    }))
  } else {
    setStateForm((prevState) => ({
      ...prevState,
      leave: {
        ...prevState.leave,
        [key]: value,
      },
    }))
  }
  setValidateForm((prevState) => ({
    ...prevState,
    leave: {
      ...prevState.leave,
      [key]: '',
      remarkLeave: '',
    },
  }))
}
