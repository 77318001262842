import _ from 'lodash'
import {
  examinationCompany,
  examinationCompanyDownload,
  examinationCompanyFilter,
} from '../../../../../utils/apiPath'
import { stationCells } from '../../../../../constants/MasterData/headerCell'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/table'
import { downloadFile } from '../../../../../services/util'
import { initialState } from '../../../../../redux/slices/table/model'
import callAxios from '../../../../../utils/baseService'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'
import { callAPI } from '../../../../../utils/lib'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  let body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
    isStation: true
  }
  await dispatch(
    downloadFile({
      url: examinationCompanyDownload,
      body: body,
      fileName: `รายการสเตชั่น.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, statusList, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const body = {
      search: '',
      searchStation: keyword,
      station: _.get(filter, 'name', ''),
      locationCode: _.get(filter, 'locationCode', ''),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
      status: isInitial ? [] : statusList,
      limit: tableProps.limit,
      order: tableProps.order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      initial: _.get(filter, 'initial', ''),
      academyStationName: _.get(filter, 'academyStationName', ''),
      academyStationNameEN: _.get(filter, 'academyStationNameEN', ''),
    }

    const isCancel = await callAxios
      .post(examinationCompanyFilter, body)
      .then(({ data }) => {
        dispatch(
          setInitialTable({
            rows: data.result,
            allCount: data.totalCount,
            headCells: stationCells,
            placeholder: 'ค้นหา',
            searchKey: 'station',
            status: statusList,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text, filter)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            onDelete: (selected) =>
              dispatch(
                openDialog({
                  title: 'ยืนยันลบข้อมูล',
                  message: 'คุณต้องการลบรายการนี้หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  handleConfirm: () =>
                    dispatch(onDelete(selected, table, filterProp, statusList, keyword)),
                }),
              ),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: stationCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  } else {
    statusList.push('ACTIVE', 'INACTIVE', 'DELETED')
  }
  dispatch(setStatusFilter(statusList))

  const filterText = {
    name: filter.stationNameChecked ? filter.stationName : '',
    initial: filter.initialChecked ? filter.initial : '',
    academyStationName: filter.academyStationNameChecked ? filter.academyStationName : '',
    academyStationNameEN: filter.academyStationNameENChecked ? filter.academyStationNameEN : '',
    locationCode: filter.locationCodeChecked ? filter.locationCode : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList('filter', table, 1, statusList, filterText, '')
  )
}

export const handleQuickSearch = (table, text, filter) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(fetchDataList('search', table, 1, statusList, filter, text))
}

export const onDelete = (selected, table, filterProp, statusList, keyword) => async (dispatch) => {
  dispatch(startLoading())
  await callAPI({
    method: 'delete',
    url: `${examinationCompany}/${selected.uuid}`,
  })

  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ถูกลบแล้ว',
      isCloseDialog: false,
      handleConfirm: () => {
        dispatch(
          fetchDataList(
            'search',
            table,
            1,
            statusList,
            filterProp,
            keyword,
          ),
        )
        dispatch(resetTablePage())
        dispatch(closeDialog())
      },
    }),
  )

  dispatch(stopLoading())
}
