
export const objectList = [
    'EL_UPLOAD_MAX_FILE_SIZE',
    'EL_FACE_IMAGE_RETENTION_PERIOD',
    'EL_ID_CARD_RETENTION_PERION',
    'EL_TRAINING_HISTORY_RETENTION_PERIOD',
    'EXAMINATION_UPLOAD_OTHER_DOCUMENT',
]

export const numberFormat = (number) => {
    if(number){
        const numberArr = number.split('.')
        const modifiedNumber = numberArr?.[0].toString().padStart(2, '0')
        const returnNumber = modifiedNumber+":"+numberArr?.[1]
        return returnNumber
    }else{
        return number
    }
}