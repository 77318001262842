import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { checkScrollWidth, handleSubmitData } from './events'
import { StyledBoxButton, StyledBoxHeader, StyledBoxLabelTitle } from './Styled'
import HistoryDrawer from './view/History'
import { HistoryTwoTone } from '@mui/icons-material'

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const [isScrolled, setScroll] = useState(false)
  const data = useSelector(
    (state) => state.manageContentELearningHome.data,
    shallowEqual,
  )
  const { setValues } = useFormikContext()

  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
    }
  }, [])

  return (
    <StyledBoxHeader scrolled={isScrolled}>
      <StyledBoxLabelTitle>
        <Typography variant="h4">Home E-learning</Typography>
      </StyledBoxLabelTitle>
      <StyledBoxButton>
        <Button
          data-testid="btn-history"
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          <HistoryTwoTone />
          ประวัติการแก้ไข
        </Button>
        <Button
          data-testid="btn-preview"
          variant="outlined"
          onClick={() => {
            window.open('home-e-learning/preview', '_blank')
          }}
        >
          <RemoveRedEye sx={{ mr: 1 }} /> ดูตัวอย่าง
        </Button>
        <Button
          data-testid="btn-submit-form"
          type="submit"
          variant="contained"
          onClick={() => {
            const formData = handleSubmitData(data)
            setValues(formData)
          }}
        >
          บันทึกและเผยแพร่
        </Button>
      </StyledBoxButton>
      <HistoryDrawer
        id={data.uuid}
        isOpen={isOpen}
        onCloseDrawer={() => setOpen(false)}
      />
    </StyledBoxHeader>
  )
}
export default Header
