import _ from 'lodash'
import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
  TYPE_OF_VALUE,
} from '../../../constants/report/monthlyPlan'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const getDefaultFiltersList = async () => {
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

  const user = JSON.parse(localStorage.getItem('user'))
  const defaultStation = _.find(_.get(user, 'area', []), (a) => a.default)
  const departmentUuid = _.get(defaultStation, 'department.uuid')
  const departmentName = _.get(defaultStation, 'department.department')
  const stationUuid = _.get(defaultStation, 'station.uuid')
  const stationName = _.get(defaultStation, 'station.station')

  return [
    {
      id: 1,
      key: COLUMN_NAME.TRAINING_DATE,
      title: COLUMN_NAME_TH.TRAINING_DATE,
      value: {
        startDate: firstDayOfMonth,
        endDate: today,
      },
      typeOfValue: TYPE_OF_VALUE.DATE_RANGE,
      label: `${dayjs(firstDayOfMonth)
        .utc(true)
        .format('DD/MM/YYYY')} - ${dayjs(today)
        .utc(true)
        .format('DD/MM/YYYY')}`,
    },
    {
      id: 2,
      key: COLUMN_NAME.STATION,
      title: COLUMN_NAME_TH.STATION,
      value: stationUuid,
      typeOfValue: TYPE_OF_VALUE.DROPDOWN_SINGLE,
      label: stationName,
    },
    {
      id: 3,
      key: COLUMN_NAME.DEPARTMENT,
      title: COLUMN_NAME_TH.DEPARTMENT,
      value: departmentUuid,
      typeOfValue: TYPE_OF_VALUE.DROPDOWN_SINGLE,
      label: departmentName,
    },
  ]
}
