import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Row, Column } from '../../Styled'
import { Container, StyledButton, StyledNumber, StyledName } from './Styled'

const CourseList = ({
  label,
  list = [],
  color,
  background,
  nameColor = '',
}) => {
  return (
    <Container minheight={315} width="calc(50% - 8px)">
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
        {label}
      </Typography>
      <Row sx={{ mb: 1 }}>
        <Typography
          variant="body3"
          color="text.lightGray"
          sx={{ width: 24, ml: 0.5, mr: 0.75, textAlign: 'center' }}
        >
          #
        </Typography>
        <Typography variant="body3" color="text.lightGray">
          ชื่อหลักสูตร
        </Typography>
      </Row>
      <Column sx={{ gap: 1 }}>
        {list.length > 0 ? (
          list.map((ele, ind) => {
            return (
              <List
                key={ind}
                number={ind + 1}
                data={ele}
                color={color}
                background={background}
                nameColor={nameColor}
              />
            )
          })
        ) : (
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ margin: '98px', textAlign: 'center' }}
          >
            ไม่พบข้อมูล
          </Typography>
        )}
      </Column>
    </Container>
  )
}

export default CourseList

const List = ({ number, data, color, background, nameColor }) => {
  return (
    <Row sx={{ p: 0.5, borderRadius: 2, background: background }}>
      <StyledNumber variant="caption" color="text.white" background={color}>
        {number}
      </StyledNumber>
      <StyledName variant="body2b" color={nameColor || color}>
        {data.name}
      </StyledName>
      <StyledButton
        color="text.lightGray"
        size="small"
        onClick={() =>
          window.open(`/e-learning/course/view/${data.uuid}`, '_blank')
        }
      >
        <ChevronRight fontSize="small" />
      </StyledButton>
    </Row>
  )
}
