import { store } from '../../../../../App'
import callAxios from '../../../../../utils/baseService'
import { crudConfig } from '../../crudConfig'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { handleFetchError } from '../../../../../components/CRUD/handler/handlerFetchError'

export const fetchSubCategory = async (categoryUuid) => {
  const body = {
    name: '',
    categoryName: '',
    categoryUuid,
    order: 'ASC',
    sort: 'name',
    status: ['ACTIVE'],
    limit: -1,
    page: 0,
  }

  const setField = (key, value) => {
    return store.dispatch(setFieldValue({ key, value }))
  }

  try {
    setField('masterData.subCategory', { isLoading: true, options: [] })
    setField('formData.subCategory', null)

    const response = await callAxios.post(crudConfig.filterPath, body, {
      'x-type': 'E_CONTENT_SUB_CATEGORY',
    })
    setField('masterData.subCategory', {
      isLoading: false,
      options: response.data.result.map((item) => ({
        value: item.uuid,
        label: item.name,
      })),
    })
  } catch (e) {
    handleFetchError(e)
  }
}
