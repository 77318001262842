import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import DateRangeInput from '../../../../../../../components/DateRangeInput'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../../../components/FilterDrawer/Styled'
import { store } from '../../../../../../../App'
import _ from 'lodash'
import { PRODUCT_TYPE } from '../../../../../../../constants/stock'
import TextInput from '../../../../../../../components/Input/TextInput'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../../utils/lib'
import { regex } from '../../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../../components/CRUD/handler/handleInputNumber'
import TimePicker from '../../../../../../../components/TimePicker/TimePicker'
import { convertTime } from '../../../../../../../modules/MonthlyPlan/Drawer/View/Index'

const defaultFilter = {
  dateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: '',
  dateStartTime: '',
  dateEndTime: '',
  eventChecked: false,
  event: {
    importChecked: false, //นำเข้า
    transferInChecked: false, //โอนเข้า
    transferOutChecked: false, // โอนออก
    returnChecked: false, // คืน
    withdrawChecked: false, // เบิก
    reduceChecked: false, // ปรับลด
    editChecked: false, // แก้ไข
    inActiveChecked: false,
    activeChecked: false,
    deletedChecked: false,
  },
  qtyChecked: false, //   - จำนวนทรัพย์สิน
  qtyFrom: '',
  qtyTo: '',
  stockValueChecked: false, //   - มูลค่ารวม
  stockValueFrom: '',
  stockValueTo: '',
  totalChecked: false, // - คงเหลือ
  totalFrom: '',
  totalTo: '',
  fromFilterChecked: false, // - จาก
  fromFilter: '',
  toFilterChecked: false, // - ไป
  toFilter: '',
}
const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  const handleOnLevelTextChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    })
  }

  const handleChangeEventList = (event) => {
    setFilter({
      ...filter,
      event: {
        ...filter.event,
        [event.target.name]: event.target.checked,
      },
    })
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  const { formData } = store.getState().crud
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-date"
                name="dateChecked"
                defaultChecked
                checked={filter.dateChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'dateChecked',
                  })
                }}
              />
            }
            label="วันเวลาที่เคลื่อนไหวล่าสุด"
            sx={!filter.dateChecked ? { marginBottom: -1 } : {}}
          />
          {filter.dateChecked && (
            <>
              <DateRangeInput
                placeholder={'เลือกวันที่'}
                dateState={{
                  startDate: !filter.startDate ? new Date() : filter.startDate,
                  endDate: !filter.endDate ? new Date() : filter.endDate,
                  display: filter.displayDate,
                  key: 'selection',
                }}
                onChange={(selectedDates) => {
                  handleSelectDate(selectedDates, filter, setFilter)
                }}
                labelText="วันที่"
                labelVariant="body2"
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant={'body2'} color={'text.secondary'}>
                      เริ่มต้น
                    </Typography>
                  </Box>
                  <TimePicker
                    id="dateStartTime"
                    onChange={(event) => {
                      handleOnLevelTextChange(
                        'dateStartTime',
                        event.target.value,
                      )
                    }}
                    value={
                      _.get(filter, 'dateStartTime', '')
                        ? convertTime(_.get(filter, 'dateStartTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(filter, 'dateStartTime', '')
                        ? convertTime(_.get(filter, 'dateStartTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(filter, 'dateEndTime', '')
                        ? convertTime(_.get(filter, 'dateEndTime', ''))
                        : ''
                    }
                  />
                </Box>
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant={'body2'} color={'text.secondary'}>
                      ถึง
                    </Typography>
                  </Box>
                  <TimePicker
                    id="dateEndTime"
                    onChange={(event) => {
                      handleOnLevelTextChange('dateEndTime', event.target.value)
                    }}
                    value={
                      _.get(filter, 'dateEndTime', '')
                        ? convertTime(_.get(filter, 'dateEndTime', ''))
                        : ''
                    }
                    disabledStartTime={
                      _.get(filter, 'dateStartTime', '')
                        ? convertTime(_.get(filter, 'dateStartTime', ''))
                        : ''
                    }
                    disabledEndTime={
                      _.get(filter, 'dateEndTime', '')
                        ? convertTime(_.get(filter, 'dateEndTime', ''))
                        : ''
                    }
                    disabled={filter?.dateStartTime ? false : true}
                  />
                </Box>
              </Box>
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="รายการ"
          control={
            <Checkbox
              data-testid="checkbox-level-status"
              name="eventChecked"
              checked={filter.eventChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'eventChecked',
                })
              }}
            />
          }
        />
        {filter.eventChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="นำเข้า"
              control={
                <Checkbox
                  data-testid="checkbox-import"
                  name="importChecked"
                  checked={filter.event.importChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            <FormControlLabel
              label="โอนเข้า"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="transferInChecked"
                  checked={filter.event.transferInChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            <FormControlLabel
              label="โอนออก"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="transferOutChecked"
                  checked={filter.event.transferOutChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            {![PRODUCT_TYPE.ASSET, PRODUCT_TYPE.LICENSE].includes(
              productType,
            ) && (
              <>
                <FormControlLabel
                  label="คืน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="returnChecked"
                      checked={filter.event.returnChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
                <FormControlLabel
                  label="เบิก"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="withdrawChecked"
                      checked={filter.event.withdrawChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
                {[PRODUCT_TYPE.STOCK].includes(productType) && (
                  <FormControlLabel
                    label="ปรับลด"
                    control={
                      <Checkbox
                        data-testid="checkbox-status-active"
                        name="reduceChecked"
                        checked={filter.event.reduceChecked}
                        onChange={handleChangeEventList}
                      />
                    }
                  />
                )}
              </>
            )}

            {![PRODUCT_TYPE.STOCK].includes(productType) && (
              <>
                <FormControlLabel
                  label="แก้ไข"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="editChecked"
                      checked={filter.event.editChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
                <FormControlLabel
                  label="เปิดใช้งาน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="activeChecked"
                      checked={filter.event.activeChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
                <FormControlLabel
                  label="ปิดใช้งาน"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="inActiveChecked"
                      checked={filter.event.inActiveChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
                <FormControlLabel
                  label="ลบ"
                  control={
                    <Checkbox
                      data-testid="checkbox-status-active"
                      name="deletedChecked"
                      checked={filter.event.deletedChecked}
                      onChange={handleChangeEventList}
                    />
                  }
                />
              </>
            )}
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="qtyChecked"
                defaultChecked
                checked={filter.qtyChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'qtyChecked',
                  })
                }}
              />
            }
            label="จำนวนทรัพย์สิน"
            sx={!filter.qtyChecked ? { marginBottom: -1 } : {}}
          />
          {filter.qtyChecked && (
            <Box display="flex" gap={2}>
              <TextInput
                id={`qtyFrom`}
                name={`qtyFrom`}
                labelText="ตั้งแต่"
                type="basic"
                inputProps={{
                  'data-testid': `input-qtyFrom`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.qtyChecked}
                value={filter.qtyFrom}
                onChange={(e) => {
                  handleOnLevelTextChange('qtyFrom', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
              <TextInput
                id={`qtyTo`}
                name={`qtyTo`}
                labelText="ถึง"
                type="basic"
                inputProps={{
                  'data-testid': `input-qtyTo`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.qtyChecked}
                value={filter.qtyTo}
                onChange={(e) => {
                  handleOnLevelTextChange('qtyTo', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
            </Box>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="stockValueChecked"
                defaultChecked
                checked={filter.stockValueChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'stockValueChecked',
                  })
                }}
              />
            }
            label="มูลค่ารวม (฿)"
            sx={!filter.stockValueChecked ? { marginBottom: -1 } : {}}
          />
          {filter.stockValueChecked && (
            <Box display="flex" gap={2}>
              <TextInput
                id={`stockValueFrom`}
                name={`stockValueFrom`}
                labelText="ตั้งแต่"
                type="basic"
                inputProps={{
                  'data-testid': `input-stockValueFrom`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.stockValueChecked}
                value={filter.stockValueFrom}
                onChange={(e) => {
                  handleOnLevelTextChange('stockValueFrom', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
              <TextInput
                id={`stockValueTo`}
                name={`stockValueTo`}
                labelText="ถึง"
                type="basic"
                inputProps={{
                  'data-testid': `input-stockValueTo`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.stockValueChecked}
                value={filter.stockValueTo}
                onChange={(e) => {
                  handleOnLevelTextChange('stockValueTo', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
            </Box>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="totalChecked"
                defaultChecked
                checked={filter.totalChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'totalChecked',
                  })
                }}
              />
            }
            label="คงเหลือ"
            sx={!filter.totalChecked ? { marginBottom: -1 } : {}}
          />
          {filter.totalChecked && (
            <Box display="flex" gap={2}>
              <TextInput
                id={`totalFrom`}
                name={`totalFrom`}
                labelText="ตั้งแต่"
                type="basic"
                inputProps={{
                  'data-testid': `input-totalFrom`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.totalChecked}
                value={filter.totalFrom}
                onChange={(e) => {
                  handleOnLevelTextChange('totalFrom', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
              <TextInput
                id={`totalTo`}
                name={`totalTo`}
                labelText="ถึง"
                type="basic"
                inputProps={{
                  'data-testid': `input-totalTo`,
                  onInput: (e) => {
                    handleInputNumber(e, regex.NUMBER)
                  },
                  onKeyDown: (e) => {
                    handleNumberKeyDown(e, ['.'])
                  },
                  onPaste: handlePasteFormat,
                }}
                disabled={!filter.totalChecked}
                value={filter.totalTo}
                onChange={(e) => {
                  handleOnLevelTextChange('totalTo', e.target.value)
                }}
                isShowTextError={false}
                placeholder={'ระบุจำนวน'}
              />
            </Box>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="fromFilterChecked"
                defaultChecked
                checked={filter.fromFilterChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'fromFilterChecked',
                  })
                }}
              />
            }
            label="จาก"
            sx={!filter.fromFilterChecked ? { marginBottom: -1 } : {}}
          />
          {filter.fromFilterChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.fromFilterChecked}
              placeholder="จาก"
              value={filter.fromFilter}
              onChange={(e) =>
                handleOnLevelTextChange('fromFilter', e.target.value)
              }
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="toFilterChecked"
                defaultChecked
                checked={filter.toFilterChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'toFilterChecked',
                  })
                }}
              />
            }
            label="ไป"
            sx={!filter.toFilterChecked ? { marginBottom: -1 } : {}}
          />
          {filter.toFilterChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.toFilterChecked}
              placeholder="ไป"
              value={filter.toFilter}
              onChange={(e) =>
                handleOnLevelTextChange('toFilter', e.target.value)
              }
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledFooter>
        <Button sx={{ width: '45%' }} variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          sx={{ width: '45%' }}
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}
