import { themeColor } from '../../utils/themeData'

export const defaultHeadFieldWork = ['Holiday', 'Training', 'Activity Support']

export const statusText = (status, masterPlanStatus) => {
  const waitingApprove = {
    text: 'รออนุมัติ',
    color: themeColor.other.ratingActive,
  }
  const waitingSubmit = { text: 'รอส่งตรวจ', color: themeColor.text.silver }
  if (
    masterPlanStatus === 'DRAFT' ||
    masterPlanStatus === 'RECALL' ||
    masterPlanStatus === 'REJECTED'
  ) {
    return waitingSubmit
  }
  const obj = {
    WAITING: waitingApprove,
    REQUEST_EDIT: waitingApprove,
    REQUEST_CREATE: waitingApprove,
  }
  return obj[status] || waitingApprove
}
