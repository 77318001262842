import React from 'react'
import { SetExpirySection } from './SetExpirySection/index'
import { ShowQRCodeSection } from './ShowQRCodeSection/index'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'

export const SettingQRSection = (props) => {
  const { uuid, time } = props
  const { link } = useSelector(
    (state) => state.manageClassDetail.qrEnrollProps,
    shallowEqual
  )

  return (
    <>
      <SetExpirySection uuid={uuid} />
      {!_.isNull(link) && <ShowQRCodeSection time={time} />}
    </>
  )
}
