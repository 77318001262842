// import _ from 'lodash'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const openHistoryDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: 'isShowHistoryDrawer',
      value: value,
    }),
  )
}
