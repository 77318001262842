import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { validatePermission } from '../../../../../utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

const Index = ({ onClickSubmit }) => {
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Button
        data-testid="btn-download-0"
        variant="contained"
        onClick={onClickSubmit()}
        size="m"
        disabled={!hasPermissionCreate}
      >
        บันทึกและเผยแพร่
      </Button>
    </Box>
  )
}
export default Index
