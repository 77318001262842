import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledCourseContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(9)} 0 ${theme.spacing(7.5)}`,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(5)} 0 ${theme.spacing(7)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(5)} 0 ${theme.spacing(5.5)}`,
  },
}))

export const StyledHeaderText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(2),
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1),
  },
}))

export const StyledHeaderBody = styled(Typography)(({ theme }) => ({
  width: 620,
  wordBreak: 'break-word',
  [theme.breakpoints.down('lg')]: {
    width: 372,
  },
  [theme.breakpoints.down('sm')]: {
    width: 335,
  },
}))

export const StyledGrayBg = styled('img')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  left: 0,
  zIndex: 0,
  [theme.breakpoints.down('lg')]: { height: 239 },
  [theme.breakpoints.down('sm')]: { height: 268 },
}))
