import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import TextInput from '../../../../../../components/Input/TextInput'
import { handleChange, handleDelete } from '../../Answer/event'
import Image from '../../Image'
import {
  IconUploadImage,
  getErrorMessage,
  handleIconUploadImage,
} from '../../event'
import { TooltipForm } from '../../Styled'
import * as Styled from './Styled'

const AnswerSequence = (props) => {
  const { indexQuestion, indexAnswer, answers, keyQuestion, disableForm } =
    props
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)

  const { errorSchemaMessage } = useSelector(
    (state) => ({
      errorSchemaMessage: state.eTestingForm.errorSchemaMessage,
    }),
    shallowEqual
  )

  const { title, mediaUrl } = answers[indexAnswer]

  const loadingProgress = _.get(answers, `[${indexAnswer}]`, null)
  const propsEvent = {
    keyQuestion,
    indexAnswer,
    answers,
  }
  const keyStateProps = { keyQuestion, key: `answers[${indexAnswer}].mediaUrl` }
  const answerErrorMessage = getErrorMessage({
    field: 'ANSWER',
    errorSchemaMessage,
    indexQuestion,
    indexAnswer,
  })

  return (
    <>
      <Styled.BoxAnswer>
        <Typography variant="body1b" sx={{ mt: 1.5, minWidth: 42 }}>
          {indexAnswer + 1}
        </Typography>
        <TextInput
          id="title-answer"
          name="title"
          placeholder="ตัวเลือก"
          type="basic"
          boxSx={{ mt: 1, width: '100%' }}
          disabled={disableForm}
          value={title}
          onChange={(e) =>
            dispatch(
              handleChange({
                ...propsEvent,
                key: 'title',
                value: _.get(e, 'target.value', ''),
              })
            )
          }
          textError={answerErrorMessage}
          multiline
          maxRows={2}
        />
        <Styled.BoxIcon>
          <IconUploadImage
            testId={`icon-answer-${keyQuestion}-${indexAnswer}`}
            disabled={disableForm}
            indexAnswer={indexAnswer}
            questionType="SEQUENCE"
            onUpload={(e) =>
              dispatch(
                handleIconUploadImage({
                  data: e,
                  keyQuestion: propsEvent.keyQuestion,
                  keyValue: `answers[${indexAnswer}].mediaUrl`,
                  testId: `icon-answer-${keyQuestion}-${indexAnswer}`,
                })
              )
            }
          />
          <TooltipForm
            placement="bottom"
            title={<Typography variant="tooltip">ลบ</Typography>}
          >
            <IconButton
              onMouseLeave={() => setIsHover(false)}
              onMouseOver={() => setIsHover(true)}
              onClick={() => !disableForm && dispatch(handleDelete(propsEvent))}
              data-testid={`btn-delete-${indexAnswer}`}
            >
              <CloseOutlined color={isHover ? 'primary' : 'action'} />
            </IconButton>
          </TooltipForm>
        </Styled.BoxIcon>
      </Styled.BoxAnswer>
      {loadingProgress != null && mediaUrl && (
        <Box sx={{ ml: 1.5 }}>
          <Image
            isAnswer
            uploadState={answers[indexAnswer]}
            keyState={keyStateProps}
            value={mediaUrl}
          />
        </Box>
      )}
    </>
  )
}
export default AnswerSequence
