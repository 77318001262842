import React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import _ from 'lodash'

import Dot from '../Dot'
import Truncate from './Truncate'
import Action from './Action'

import {
  setDefaultSort,
  setOrder,
  setChangeRowsPerPage,
  setChangePage,
  setSelected,
} from '../../../redux/slices/table'
import {
  setDefaultSort as setBasicDefaultSort,
  setOrder as setBasicOrder,
  setChangeRowsPerPage as setBasicChangeRowsPerPage,
  setChangePage as setBasicChangePage,
} from '../../../redux/slices/table/basicTable'
import {
  AgentUpdateProfileStatus,
  LearnerStatus,
} from '../../../modules/ManageClass/Learner'
import UserLevelList from '../../../components/Table/UserLevelList'
import ActionE from './Action/ActionE'
import ActionETestDashboard from './Action/ActionETestDashboard'
import ActionCheckExamList from './Action/ActionCheckExamList'
import ActionEVersion from './Action/ActionEVersion'
import ActionECertiticateBackground from './Action/ActionECertificateBackground'
import ActionManageClass from './Action/ManageClass'
import ActionAdminManageClass from './Action/ManageClass/AdminSelect'
import ActionELearning from './Action/ActionELearning'
import ActionStatusManageClass from './Action/ManageClass/Status'
import ActionLearner from './Action/ActionLearner'
import ActionApplicationDocuments from './Action/ApplicationDocuments'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import ActionRcms from './Action/ActionRcms'
import ActionRcmsDetail from './Action/ActionRcmsDetail'
import {
  licenseStatusText,
  rcmsStatusText,
} from '../../../modules/ApiToRcms/event'
import CheckingState from '../../../modules/CheckExam/CheckingState'
import ActionOic from './Action/ManageClassOic'
import {
  convertNumberWithComma,
  formatPhoneNo,
} from '../../../../src/utils/lib'
import ActionEvaStatusList from './Action/ApplicationEvaStatusList'
import ActionEvaStatusDetail from './Action/ActionEvaStatusDetail'
import ActionManage from './Action/Manage'
import ActionStandaloneVersion from './Action/ActionStandalone/Version'
import BatchDot from '../../../modules/ELearning/Course/Form/BatchTab/Dot'
import ActionBatch from './Action/ActionBatch'
import ActionBatchLearner from './Action/ActionBatchLearner'
import ActionQuestion from './Action/ActionQuestion'
import ActionExam from './Action/ActionExam'
import ActionAnnounce from './Action/ActionAnnounce'
import ActionStock from './Action/ActionStock'
import ActionTransfer from './Action/ActionTransfer'
import ActionTransferForm from './Action/ActionTransferForm'
import ActionCart from './Action/ActionCart'
import ActionTransferEditForm from './Action/ActionTransferEditForm'
import { STUDENT_STATUS_DOT_COLOR } from '../../../constants/eExamination'
import CheckAnnouncement from '../../../modules/EExamination/ExaminerList/CheckAnnounce'
import { ImageContainer, StyledImage } from './Styled'
import ActionStockDropown from './Action/ActionStockDropown'
import ActionRequisition from './Action/ActionRequisition'
import {
  PRODUCT_TYPE_VIEW_TEXT,
  RequisitionStatus,
  RequisitionStatusColor,
  RequisitionStatusText,
  TicketStatusColor,
  TicketStatusText,
} from '../../../constants/stock'
import PushPinTwoToneIcon from '@mui/icons-material/PushPinTwoTone'
import ActionSku from './Action/ActionSku'
import ActionViewPointHistory from './Action/ActionViewPointHistory'
import ActionViewEnrollList from './Action/ActionViewEnrollList'
import ChipContent from '../../../modules/ELearning/Dashboard/components/ChipContent'
import StatusLearner from './StatusLearner'
import ActionMcDashboard from './Action/ActionMcDashboard'
import ActionAgentUpdateProfile from './Action/ActionAgentUpdateProfile'
import ActionTransferView from './Action/ActionTransferView'
import LearnerRekognition from '../../../modules/ELearning/Course/Form/BatchTab/Learner/LearnerRekognition'
import ActionViewUserProfile from '../Table2/Action/ActionViewUserProfile'

export const checkConditionView = (headCells, row, rowIndex, cellIndex) => {
  const columnKey = headCells[cellIndex].id
  const colCodeId = columnKey === 'codeId' ? 150 : 100
  const minWidth = columnKey === 'name' ? 380 : colCodeId
  const sx = GetSxStyle(row, minWidth)

  const isCustom = _.get(headCells, `[${cellIndex}].custom`, false)
  const customWidth = _.get(headCells, `[${cellIndex}].customWidth`, 150)

  if (columnKey === 'status') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={row[`${columnKey}`]} />
      </TableCell>
    )
  } else if (columnKey === 'statusQuestionTH') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Dot status={row[`status`]} />
          {' ' + row[`statusTH`]}
        </Box>
      </TableCell>
    )
  } else if (columnKey === 'evaStatusList') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }}>
        <ActionEvaStatusList row={row} />
      </TableCell>
    )
  } else if (columnKey === 'actionEvaStatus') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }}>
        <ActionEvaStatusDetail
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'licenseStatus') {
    return (
      <TableCell key={columnKey}>
        {licenseStatusText(row?.licenseStatus)}
      </TableCell>
    )
  } else if (columnKey === 'rcmsStatus') {
    return (
      <TableCell key={columnKey}>
        {rcmsStatusText(row?.rcmsStatus, row?.error)}
      </TableCell>
    )
  } else if (columnKey === 'batchStatus') {
    return (
      <TableCell key={columnKey}>
        <BatchDot status={row[`${columnKey}`]} row={row} />
      </TableCell>
    )
  } else if (columnKey === 'numberOfVersions') {
    return (
      <TableCell key={columnKey}>
        {row.numberOfVersions === 0 ? '' : row.numberOfVersions}
      </TableCell>
    )
  } else if (columnKey === 'statusLearner') {
    return <LearnerStatus key={rowIndex} row={row} />
  } else if (columnKey === 'statusAgentUpdateProfile') {
    return <AgentUpdateProfileStatus key={rowIndex} row={row} />
  } else if (columnKey === 'actionManage') {
    return (
      <TableCell key={columnKey}>
        <ActionManage
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'action') {
    return (
      <TableCell key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionOic') {
    return (
      <TableCell key={columnKey}>
        <ActionOic
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionManageUpdateProfile') {
    return (
      <TableCell key={columnKey}>
        <ActionAgentUpdateProfile
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionE') {
    return (
      <TableCell key={columnKey}>
        <ActionE
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionRcms') {
    return (
      <TableCell key={columnKey}>
        <ActionRcms
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionRcmsDetail') {
    return (
      <TableCell key={columnKey}>
        <ActionRcmsDetail
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionCheckExamList') {
    return (
      <TableCell key={columnKey}>
        <ActionCheckExamList
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionEVersion') {
    return (
      <TableCell key={columnKey}>
        <ActionEVersion
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionAdminManageClass') {
    return (
      <TableCell key={columnKey}>
        <ActionAdminManageClass
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionELearning') {
    return (
      <TableCell key={columnKey}>
        <ActionELearning
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'iconStatus') {
    return (
      <TableCell key={columnKey}>
        <ActionStatusManageClass
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'documentStatus') {
    return (
      <TableCell key={columnKey}>
        <ActionApplicationDocuments
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionECertiticate') {
    return (
      <TableCell key={columnKey}>
        <ActionECertiticateBackground
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionManageClass') {
    return (
      <TableCell key={columnKey}>
        <ActionManageClass
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionLearner') {
    return (
      <TableCell key={columnKey}>
        <ActionLearner
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionStandaloneVersion') {
    return (
      <TableCell key={columnKey}>
        <ActionStandaloneVersion
          row={row}
          minWidth={minWidth}
          rowIndex={rowIndex}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionBatch') {
    return (
      <TableCell key={columnKey}>
        <ActionBatch
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionBatchLearner') {
    return (
      <TableCell key={columnKey}>
        <ActionBatchLearner
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionQuestion') {
    return (
      <TableCell key={columnKey}>
        <ActionQuestion
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
        />
      </TableCell>
    )
  } else if (columnKey === 'statusAnnounce') {
    return (
      <TableCell key={columnKey}>
        <ActionAnnounce
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'checkingState') {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 10 }}>
        <CheckingState data={row} />
      </TableCell>
    )
  } else if (columnKey === 'myTrainingPlatForm') {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 10 }}>
        <UserLevelList levelList={row[columnKey]} />
      </TableCell>
    )
  } else if (columnKey === 'id') {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 10 }}>
        {row[`${columnKey}`]}
      </TableCell>
    )
  } else if (columnKey === 'version') {
    return (
      <TableCell key={columnKey} sx={sx}>
        {row[`${columnKey}`] === 0 ? '' : row[`${columnKey}`]}
      </TableCell>
    )
  } else if (columnKey === 'mobileNo') {
    return (
      <TableCell key={columnKey} sx={sx}>
        {formatPhoneNo(row[columnKey])}
      </TableCell>
    )
  } else if (isCustom) {
    return (
      <TableCell key={columnKey} sx={{ minWidth: customWidth }}>
        {row[columnKey]}
      </TableCell>
    )
  } else if (columnKey === 'attendStatus' || columnKey === 'result') {
    return (
      <TableCell key={columnKey}>
        <Typography
          variant="body2"
          color={mapColorLabel(_.get(row, `${columnKey}`, '-'))}
        >
          {_.get(row, `${columnKey}`, '-')}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'RESULT') {
    return <MapResult columnKey={columnKey} sx={sx} row={row} />
  } else if (columnKey === 'UnitCode' || columnKey === 'reason') {
    const value = _.get(row, `${columnKey}`, '-') || '-'
    return (
      <TableCell key={columnKey} sx={sx}>
        <Truncate>{'' + value}</Truncate>
      </TableCell>
    )
  } else if (columnKey === 'actionExam') {
    return (
      <TableCell key={columnKey}>
        <ActionExam
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'examinerStatus') {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Dot status={STUDENT_STATUS_DOT_COLOR[row[`examinerStatus`]]} />
          {' ' + row[`examinerStatus`]}
        </Box>
      </TableCell>
    )
  } else if (columnKey === 'announcement') {
    return (
      <TableCell key={columnKey} sx={{ minWidth: 10 }}>
        <CheckAnnouncement data={row} />
      </TableCell>
    )
  } else if (columnKey === 'actionStock') {
    return (
      <TableCell key={columnKey}>
        <ActionStock
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'image' || columnKey === 'badgeImage') {
    return (
      <TableCell
        key={columnKey}
        sx={{ minWidth: headCells[cellIndex].minWidth }}
      >
        {row[columnKey]?.key ? (
          <ImageContainer>
            <StyledImage
              src={`${window.__env__.REACT_APP_API_URL}/file/get${row[columnKey].key}`}
            />
          </ImageContainer>
        ) : (
          ''
        )}
      </TableCell>
    )
  } else if (columnKey === 'actionTransfer') {
    return (
      <TableCell key={columnKey}>
        <ActionTransfer
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'actionTransferForm') {
    return (
      <TableCell key={columnKey}>
        <ActionTransferForm
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'historyPoint') {
    const color = row[columnKey].includes('+') ? 'success.dark' : 'error.dark'
    return (
      <TableCell key={columnKey}>
        <Typography color={color}>{row[columnKey]}</Typography>
      </TableCell>
    )
  } else if (columnKey === 'actionCart') {
    return (
      <TableCell key={columnKey}>
        <ActionCart row={row} />
      </TableCell>
    )
  } else if (headCells[cellIndex].type == 'number') {
    const style = headCells[cellIndex].sx || {}
    const defaultValue =
      headCells[cellIndex].defaultValue !== undefined
        ? headCells[cellIndex].defaultValue
        : '0'
    return (
      <TableCell
        key={columnKey}
        sx={{
          ...sx,
          ...style,
          minWidth: headCells[cellIndex].minWidth,
        }}
      >
        {headCells[cellIndex].haveUnit ? (
          <Truncate>{`${convertNumberWithComma(row[columnKey] || 0)} ${
            headCells[cellIndex].haveUnit
          }`}</Truncate>
        ) : (
          <Truncate>
            {row[columnKey] > 0
              ? convertNumberWithComma(row[columnKey])
              : defaultValue}
          </Truncate>
        )}
      </TableCell>
    )
  } else if (headCells[cellIndex].disableTruncate) {
    return (
      <TableCell
        key={columnKey}
        sx={{ ...sx, minWidth: headCells[cellIndex].minWidth }}
      >
        <Typography sx={{ wordBreak: 'break-word' }}>
          {_.get(row, `${columnKey}`, '-')}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'ActionStockDropown') {
    const isInventoryStock = _.get(
      headCells,
      `[${cellIndex}].isInventoryStock`,
      false,
    )
    return (
      <TableCell key={columnKey}>
        <ActionStockDropown row={row} isInventoryStock={isInventoryStock} />
      </TableCell>
    )
  } else if (columnKey === 'ticketStatus') {
    return (
      <TableCell key={columnKey}>
        {TicketStatusText?.[row?.ticketStatus] ? (
          <>
            <Typography color={TicketStatusColor?.[row?.ticketStatus]}>
              {' ' + TicketStatusText?.[row?.ticketStatus]}
            </Typography>
          </>
        ) : (
          row?.ticketStatus
        )}
      </TableCell>
    )
  } else if (columnKey === 'requisitionStatus') {
    return (
      <TableCell key={columnKey}>
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          color={RequisitionStatusColor?.[row?.status]}
        >
          {RequisitionStatusText?.[row?.status] ? (
            <>{' ' + RequisitionStatusText?.[row?.status]}</>
          ) : (
            row?.status
          )}
          {row?.status === RequisitionStatus.COMPLETED_WITH_CONDITION && (
            <Typography sx={{ ml: 0.5 }} color={'error'}>
              *
            </Typography>
          )}
        </Box>
      </TableCell>
    )
  } else if (
    columnKey === 'transferEditInput' ||
    columnKey === 'transferEditRemark'
  ) {
    return (
      <TableCell
        key={columnKey}
        sx={{ ...sx, minWidth: headCells[cellIndex].minWidth }}
      >
        <ActionTransferEditForm
          row={row}
          columnKey={columnKey}
          headCells={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'product.productType') {
    return (
      <TableCell
        key={columnKey}
        sx={{ ...sx, minWidth: headCells[cellIndex].minWidth }}
      >
        {_.get(row, `${columnKey}`, '') && (
          <Truncate>
            {'' + PRODUCT_TYPE_VIEW_TEXT?.[_.get(row, `${columnKey}`, '')] ??
              '-'}
          </Truncate>
        )}
      </TableCell>
    )
  } else if (columnKey == 'isPinned') {
    return (
      <TableCell
        key={columnKey}
        sx={{ ...sx, minWidth: headCells[cellIndex].minWidth }}
      >
        {_.get(row, `${columnKey}`, false) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PushPinTwoToneIcon color="action" />
          </Box>
        )}
      </TableCell>
    )
  } else if (columnKey === 'actionRequisition') {
    return (
      <TableCell key={columnKey}>
        <ActionRequisition
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'totalStatus') {
    return (
      <TableCell
        key={columnKey}
        sx={(theme) => ({
          ...sx,
          minWidth: headCells[cellIndex].minWidth,
          backgroundColor: !row?.[columnKey] ? theme.palette?.grey.grey100 : '',
        })}
      >
        <Typography color={(theme) => theme.palette?.secondary.main}>
          {_.get(row, `${columnKey}`)}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'actionSku') {
    return (
      <TableCell key={columnKey}>
        <ActionSku
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'returnDate') {
    return (
      <TableCell key={columnKey}>
        <Typography color={_.get(row, `${columnKey}`)?.returnDateColor ?? ''}>
          {_.get(row, `${columnKey}`)?.returnDate ?? ''}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'ActionViewPointHistory') {
    return (
      <TableCell key={columnKey} sx={{ maxWidth: 50 }}>
        <ActionViewPointHistory row={row} />
      </TableCell>
    )
  } else if (columnKey === 'ActionViewEnrollList') {
    return (
      <TableCell key={columnKey} sx={{ maxWidth: 50 }}>
        <ActionViewEnrollList row={row} />
      </TableCell>
    )
  } else if (headCells[cellIndex].chipContent) {
    return (
      <TableCell key={columnKey}>
        <ChipContent data={_.get(row, `${columnKey}`, [])} />
      </TableCell>
    )
  } else if (headCells[cellIndex].isStatus) {
    return (
      <TableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={_.get(row, `${columnKey}`, '-')} />
      </TableCell>
    )
  } else if (columnKey === 'learnerStatus') {
    return <StatusLearner key={rowIndex} row={row} />
  } else if (columnKey === 'actionMcDashboard') {
    return (
      <TableCell key={columnKey}>
        <ActionMcDashboard
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'examStatus') {
    return (
      <TableCell key={columnKey}>
        <Typography variant="body2b" color={_.get(row, 'textColor') ?? ''}>
          {_.get(row, `${columnKey}`) ?? '-'}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'countEnrollText') {
    return (
      <TableCell key={columnKey}>
        <Typography variant="body2b" color={_.get(row, 'enrollColor') ?? ''}>
          {_.get(row, `${columnKey}`) ?? '-'}
        </Typography>
      </TableCell>
    )
  } else if (columnKey === 'actionViewTransfer') {
    return (
      <TableCell key={columnKey} sx={{ maxWidth: 50 }}>
        <ActionTransferView row={row} />
      </TableCell>
    )
  } else if (columnKey === 'learnerRekognition') {
    return (
      <TableCell key={columnKey}>
        <LearnerRekognition row={row} />
      </TableCell>
    )
  } else if (columnKey === 'actionETestDashboard') {
    return (
      <TableCell key={columnKey}>
        <ActionETestDashboard
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </TableCell>
    )
  } else if (columnKey === 'ActionViewUserProfile') {
    return (
      <TableCell key={columnKey}>
        <ActionViewUserProfile row={row} condition={headCells[cellIndex]} />
      </TableCell>
    )
  }

  return (
    <TableCell
      key={columnKey}
      sx={{ ...sx, minWidth: headCells[cellIndex].minWidth }}
    >
      {_.get(row, `${columnKey}`, '') && (
        <Truncate>{'' + _.get(row, `${columnKey}`, '-')}</Truncate>
      )}
    </TableCell>
  )
}

export const GetSxStyle = (row, minWidth) => {
  const status = _.get(row, 'status', '')
  const isLearner = _.get(row, 'isLearner', false)
  const isOic = _.get(row, 'CID', null)
  const learnerCon = isLearner && status === 'CANCEL'
  if ((status === 'DELETED' || status === 'INACTIVE') && !_.isEmpty(isOic)) {
    return { minWidth, color: 'text.gray' }
  }
  if (
    status === 'DELETED' ||
    status === 'CANCELED' ||
    status === 'STEAL' ||
    status === 'TERMINATED' ||
    learnerCon ||
    status === 'INACTIVE'
  ) {
    return { minWidth, color: 'text.gray' }
  } else {
    return { minWidth }
  }
}

export const MapResult = ({ columnKey, sx, row }) => {
  const label = _.get(row, `${columnKey}`, '-')
  if (label === 'ผ่าน') {
    return (
      <TableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Check color="success" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </TableCell>
    )
  } else {
    return (
      <TableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Close color="error" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </TableCell>
    )
  }
}

export const mapColorLabel = (label) => {
  if (label === 'ไม่มาเรียน') {
    return 'error.main'
  } else if (label === 'มาเรียน') {
    return 'success.main'
  } else {
    return 'warning.main'
  }
}

export const handleClick = (selected, name) => (dispatch) => {
  const selectedIndex = selected?.indexOf(name)
  let newSelected = []
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1))
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1))
  } else {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    )
  }
  dispatch(setSelected(newSelected))
}

export const isSelected = (selected, name) => selected?.indexOf(name) !== -1

export const handleLabelDisplay = (table) => {
  const realPage = table.tablePage <= 0 ? 1 : table.tablePage + 1
  let minRowsShowed =
    table.page < 0 ? 1 : table.rowsPerPage.value * table.tablePage + 1
  let maxRowsShowed = 0

  if (table.rowsPerPage.value * realPage > table.allCount) {
    maxRowsShowed = table.allCount
  } else {
    if (realPage === 1) {
      maxRowsShowed = table.rows.length + realPage - 1
    } else {
      maxRowsShowed = table.rows.length * realPage
    }
  }

  if (maxRowsShowed === 0) {
    minRowsShowed = 0
  } else if (maxRowsShowed < 0) {
    maxRowsShowed = table.allCount
  }

  if (minRowsShowed > maxRowsShowed && realPage > 1) minRowsShowed = 1 // fix bug - rows label

  return (
    <Typography variant="caption" color="text.secondary">
      {minRowsShowed}-{maxRowsShowed} of {table.allCount}
    </Typography>
  )
}

export const handleChangePage = (newPage, isBasicTable) => (dispatch) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  const page = newPage + 1
  if (isBasicTable) {
    dispatch(setBasicChangePage({ page, tablePage }))
  } else dispatch(setChangePage({ page, tablePage }))
}

export const handleChangeRowsPerPage = (event, isBasicTable) => (dispatch) => {
  const value = parseInt(event.target.value, 10)
  const rowsPerPageChange = value > 0 ? value : -1
  const rowsPerPage = {
    label: '' + rowsPerPageChange,
    value: rowsPerPageChange,
  }
  const limit = value

  if (isBasicTable) {
    dispatch(setBasicChangeRowsPerPage({ rowsPerPage, limit }))
  } else {
    dispatch(setChangeRowsPerPage({ rowsPerPage, limit }))
  }
}

export const handleSelectAllClick = (event, rows) => (dispatch) => {
  if (event?.target?.checked) {
    const newSelecteds = rows.map((n) => n.uuid)
    dispatch(setSelected(newSelecteds))
    return
  }
  dispatch(setSelected([]))
}

export const handleRequestSort =
  (sortBy, table, headCell, isBasicTable) => (dispatch) => {
    if (headCell.hideSortIcon) return

    const defaultSort = _.get(table, 'defaultSort', undefined)
    const defaultSortId = _.get(table, 'defaultSortId', 'updatedAt')
    const sort = _.get(table, 'sort', 'updatedAt')
    const order = _.get(table, 'order', 'desc')

    if (defaultSort) {
      let isAsc = sort === sortBy && order === 'asc'
      let stateSort = defaultSort.state + 1
      let activeSort = true

      if (defaultSort.state >= 2) {
        stateSort = 0
        activeSort = false
      }
      let valueSort = !defaultSort.id ? sortBy : defaultSort.id
      if (!!defaultSort.id && defaultSort.id !== sortBy) {
        stateSort = 1
        valueSort = sortBy
        activeSort = true
      }

      let orderBy = isAsc ? 'desc' : 'asc'
      if (!activeSort) {
        sortBy = defaultSortId
        orderBy = 'desc'
      }

      if (isBasicTable) {
        dispatch(
          setBasicDefaultSort({
            state: stateSort,
            id: valueSort,
            active: activeSort,
          }),
        )

        dispatch(setBasicOrder({ order: orderBy, sort: sortBy }))
      } else {
        dispatch(
          setDefaultSort({
            state: stateSort,
            id: valueSort,
            active: activeSort,
          }),
        )

        dispatch(setOrder({ order: orderBy, sort: sortBy }))
      }
    }
  }
