import _ from 'lodash'
import { getAllProductType } from '../../../../../services/manageContent'

export const handleDefault = (data, key) => {
  const value = _.get(data, key, '')
  return value == '' ? '-' : value
}

export const fetchOptionProductType = (onEdit) => (dispatch) => {
  if (!onEdit) dispatch(getAllProductType())
}
