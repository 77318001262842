import { Card, Tooltip, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import { StyledColumn, StyledRow } from '../../Styled'
import { StyledContainedChip, StyledOutlinedChip } from './Styled'
import _ from 'lodash'
import { getColorByDistribution } from './event'

const ListItemCourse = ({
  color,
  courseNameEN,
  trainingPlatform,
  distribution,
  abbreviationDistribution,
}) => {
  const theme = useTheme()
  const hasClassroom =
    _.filter(trainingPlatform, (tp) => tp.trainingPlatform === 'Classroom')
      .length > 0
  const hasVirtualClassroom =
    _.filter(
      trainingPlatform,
      (tp) => tp.trainingPlatform === 'Virtual Classroom',
    ).length > 0

  const chipColor = getColorByDistribution(abbreviationDistribution)

  const isCourseNameTooLong = courseNameEN.length > 27
  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette?.blue?.blueLine}`,
        p: 1,
        '& .MuiTypography-root': {
          lineHeight: 1,
        },
        boxShadow: theme.palette?.boxShadow?.main,
        borderLeft: `4px solid ${color}`,
        minHeight: theme.spacing(9),
        display: 'flex',
      }}
    >
      <StyledRow sx={{ width: '100%', justifyContent: 'space-between' }}>
        <StyledRow sx={{ gap: 2 }}>
          <CollectionsBookmarkIcon
            sx={{ color: theme?.palette?.action?.active }}
          />
          <StyledColumn>
            <Typography variant="body1" color="grey.grey600">
              หลักสูตร :
            </Typography>

            {isCourseNameTooLong ? (
              <Tooltip
                title={
                  <Typography variant="tooltip">{courseNameEN}</Typography>
                }
                followCursor
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: theme?.palette?.common?.white,
                      color: theme?.palette?.common?.black,
                      boxShadow: theme?.palette?.boxShadow?.main,
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: 200,
                  }}
                  variant="body1b"
                  color="text.primary"
                >
                  {courseNameEN}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 200,
                }}
                variant="body1b"
                color="text.primary"
              >
                {courseNameEN}
              </Typography>
            )}
          </StyledColumn>
        </StyledRow>

        <StyledColumn sx={{ gap: 0.5 }}>
          {hasVirtualClassroom && <StyledOutlinedChip label="Virtual Class" />}
          {hasClassroom && <StyledOutlinedChip label="Classroom" />}
        </StyledColumn>

        <StyledContainedChip
          sx={{
            color: chipColor?.color,
            backgroundColor: chipColor?.bgColor,
            fontWeight: 400,
            p: 0.5,
            fontSize: 17,
          }}
          label={distribution}
        />
      </StyledRow>
    </Card>
  )
}

export default ListItemCourse
