import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import Template from '../../../../components/Template'
import { LoadingCircular } from '../../Styled'
import Header from './Header'
import TopSection from './TopSection'
import Content from './Content'
import { handleInitialPreview } from './events'
import { StyledWrapper } from './Styled'

const Preview = () => {
  const [data] = useLocalStorage('learningPathPreview')
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.eLearningPathPreview.isLoading,
    shallowEqual,
  )

  useEffect(() => {
    dispatch(handleInitialPreview(data))
  }, [data])

  if (isLoading) {
    return <LoadingCircular isLoading />
  }

  return (
    <StyledWrapper>
      <Header />

      <Template noMinHeight>
        <TopSection />
      </Template>

      <Content />
    </StyledWrapper>
  )
}

export default Preview
