import _ from 'lodash'
import { setInitialTable } from '../../../redux/slices/table'
import { roomManagementDownloadOperator } from '../../../utils/apiPath'
import { downloadFile } from '../../../services/util'
import { store } from '../../../App'

export const StyledTable = {
  background: '#FFFFFF',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '8px,',
}

export const headCells = [
  {
    id: 'sequence',
    disablePadding: false,
    label: 'ลำดับ',
    hideSortIcon: true,
  },
  {
    id: 'firstNameTH',
    disablePadding: false,
    label: 'ชื่อ',
    hideSortIcon: true,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'station',
    disablePadding: false,
    label: 'Station',
    hideSortIcon: true,
  },
  {
    id: 'numberOfBooking',
    disablePadding: false,
    label: 'สถิติการดำเนินการทั้งหมด',
    hideSortIcon: true,
  },
  {
    id: 'latestBooking',
    label: 'รายการล่าสุด',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const handleOperatorList = (props) => async (dispatch) => {
  const {
    // table,
    operator,
    totalOperator,
  } = props
  // const realPage = table.page <= 0 ? 1 : +table.page
  const rows = _.map(operator, (op, idx) => {
    return {
      ...op,
      sequence: idx + 1,
      numberOfBooking: `${op.numberOfBooking} รายการ`,
    }
  })
  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: rows,
      allCount: totalOperator,
      headCells: headCells,
      customStyle: StyledTable,
    }),
  )
}

export const onDownload = () => async (dispatch) => {
  const { stationUuid, month, year } = store.getState().roomManagement
  const body = {
    stationUuid: stationUuid,
    monthYear: `${year}-${month}-01`,
  }

  await dispatch(
    downloadFile({
      url: roomManagementDownloadOperator,
      body: body,
      fileName: `รายการผู้ดำเนินการ.xlsx`,
    }),
  )
}
