import { useEffect, useState } from 'react'
import TextInput from '../../../../../components/Input/TextInput'
import { CarHeader } from './CardHeader'
import { StyledCard } from '../../../Styled'
import _ from 'lodash'
import { getErrorMessage, handleChange } from '../../event'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setBookingInformation } from '../../../../../redux/slices/roomManagement/Drawer'
import { REQUEST_TYPE } from '../../../../../constants/roomManagement'
import { enumRequestType } from '../../../../../modules/RoomManagement/View/Content/CalendarCard/constanst'

export const InformationBookingCard = () => {
  const [isShowContent, setIsShowContent] = useState(true)
  const dispatch = useDispatch()

  const {
    stationName,
    bookingTopic,
    requestType,
    stationUuid,
    formError,
    courseName,
  } = useSelector(
    (state) => ({
      stationName: state.roomManagement.stationName,
      stationUuid: state.roomManagement.stationUuid,
      bookingTopic: state.roomManagementDrawer.bookingInformation.bookingTopic,
      requestType: state.roomManagementDrawer.bookingInformation.requestType,
      courseName: state.roomManagementDrawer.bookingInformation.courseName,
      formError: state.roomManagementDrawer.formError,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(setBookingInformation({ stationName, stationUuid }))
  }, [stationName])

  return (
    <StyledCard
      sx={{
        display: 'flex',
        height: 'auto',
        flexDirection: 'column',
        gap: 3,
        p: 3,
      }}
    >
      <CarHeader
        no={1}
        title="ข้อมูลการจอง"
        isShowContent={isShowContent}
        setIsShowContent={setIsShowContent}
      />
      {isShowContent && (
        <>
          <TextInput
            sx={{
              borderRadius: 16,
              '& .MuiInputBase-root': { borderRadius: 16 },
            }}
            type="basic"
            name="requestType"
            required
            labelText={'Request Type'}
            disabled
            isShowTextError={false}
            value={REQUEST_TYPE[requestType]}
          />
          <TextInput
            type="basic"
            name="station"
            required
            labelText={'Station'}
            disabled
            isShowTextError={false}
            value={stationName}
          />
          <TextInput
            type="basic"
            name="bookingTopic"
            required
            disabled={requestType === enumRequestType.MONTHLY_PLAN}
            labelText={'หัวข้อการจอง'}
            placeholder="กรุณากรอกหัวข้อ"
            onChange={(e) => {
              const value = _.get(e, 'target.value', null)
              dispatch(
                handleChange({
                  key: 'bookingTopic',
                  value: !_.isEmpty(value) ? value : null,
                }),
              )
            }}
            value={bookingTopic}
            textError={getErrorMessage(formError, 'bookingTopic')}
            isShowTextError={
              !_.isEmpty(getErrorMessage(formError, 'bookingTopic'))
            }
          />
          {requestType === enumRequestType.MONTHLY_PLAN &&
            !_.isEmpty(courseName) && (
              <TextInput
                type="basic"
                name="courseName"
                required
                labelText={'หลักสูตรอบรม'}
                disabled={true}
                value={courseName}
              />
            )}
        </>
      )}
    </StyledCard>
  )
}
