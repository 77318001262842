import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'

import {
  manageClassDocumentDownload,
  manageClassDocumentFilter,
  manageClassApprove,
} from '../../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
  setOnQuickFilterByIndex,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import {
  applicationDocuments,
  filterBody,
  defaultQuickFilter,
} from '../../../../src/constants/manageClass/applicationDocuments'
import { fetchDataApplicationDocumentsFilter } from '../../../services/manageClass'
import { DialogSendEmail } from './DialogSendEmail'
import { openDialog, closeDialog } from '../../../../src/redux/slices/dialog'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'codeId' : sort,
    type: 'CUSTOM',
    classLearnerUuidList: selected,
  }

  await dispatch(
    downloadFile({
      url: manageClassDocumentDownload,
      body: body,
      fileName: `รายการเอกสารการสมัคร.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const approveSendEmail =
  (body, table, quickSeach, manageProps) => async (dispatch) => {
    await axios
      .put(manageClassApprove, body)
      .then(() => {
        dispatch(fetchDataList('fetch', table, quickSeach, manageProps))
        dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              dispatch(closeDialog())
            },
          })
        )
      })
      .catch(() => {
        dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: 'ระบบขัดข้อง',
            handleConfirm: () => {
              dispatch(closeDialog())
            },
          })
        )
      })
  }

export const handleSendEmail =
  (selected, table, quickSeach, manageProps) => (dispatch) => {
    const body = {
      classLearnerUuidList: selected,
    }
    dispatch(
      openDialog({
        title: 'อนุมัติสิทธิ์เรียนและส่งอีเมล์การมีสิทธิ์เรียน',
        type: 'content',
        content: <DialogSendEmail />,
        disableButton: true,
        agreeText: 'อนุมัติ',
        handleConfirm: () =>
          dispatch(approveSendEmail(body, table, quickSeach, manageProps)),
      })
    )
  }

export const fetchDataList =
  (method, table, quickSeach, manageProps) => async (dispatch) => {
    dispatch(startLoading())
    const {
      table: { page, limit, order, sort },
      filter: { filterProp, quickFilter },
    } = table

    const trainingPlan = _.get(manageProps, 'uuid', '')
    const realPage = page <= 0 ? 1 : +page
    const startDate = _.get(filterProp, 'startDate', '')
    const endDate = _.get(filterProp, 'endDate', '')
    const name = _.get(filterProp, 'name', '')
    const status = _.get(filterProp, 'status', [])
    const round = _.get(filterProp, 'round', [])
    const body = {
      trainingPlanUuid: trainingPlan,
      codeId: _.get(filterProp, 'codeId', ''),
      name: method == 'search' ? quickSeach : name,
      status: status,
      round: round,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sort === 'updatedAt' ? 'codeId' : sort,
      startDate: startDate,
      endDate: endDate,
    }

    const fetch = await dispatch(fetchDataApplicationDocumentsFilter(body))

    const realQuickFilter =
      quickFilter.length > 0 ? quickFilter : defaultQuickFilter
    const quickFilterData = realQuickFilter.map((item) => {
      return {
        ...item,
        count: _.get(fetch, `payload.statusCount[${item.field}]`, 0),
      }
    })

    const filterClicked = quickFilter.filter(
      (item) => item.field == 'WAITING_CONFIRM'
    )

    await axios
      .post(manageClassDocumentFilter, body)
      .then(({ data }) => {
        const rows = data.result.map((item) => ({
          ...item,
          firstNameTH: item.firstNameTH,
          lastNameTH: item.lastNameTH,
          uuid: item.classLearnerUuid,
          documentStatus: item.status,
        }))

        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: applicationDocuments,
            quickFilter: quickFilterData,
            quickFilterTitle: 'สถานะ',
            placeholder: 'ค้นหาชื่อ',
            searchKey: 'name',
            showSendEmail: filterClicked[0]?.clicked,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(table, text, manageProps)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            onQuickFilter: (item, index) =>
              dispatch(handleQuickFilter(item, index, filterProp)),
            onSendEmail: (selected) =>
              dispatch(
                handleSendEmail(selected, table, quickSeach, manageProps)
              ),
          })
        )
      })
      .catch(() =>
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: applicationDocuments,
          })
        )
      )

    dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.waitingDocument) {
      statusList.push('WAITING_DOCUMENT')
    }
    if (filter.status.waitingCheck) {
      statusList.push('WAITING_CHECK')
    }
    if (filter.status.waitingConfirm) {
      statusList.push('WAITING_CONFIRM')
    }
    if (filter.status.rejected) {
      statusList.push('REJECTED')
    }
    if (filter.status.passChecked) {
      statusList.push('PASS_CHECKED')
    }
    if (filter.status.inEligible) {
      statusList.push('INELIGIBLE')
    }
    if (filter.status.cancled) {
      statusList.push('CANCELED_DOCUMENT')
      statusList.push('CANCELED_DOCUMENT_BY_USER')
      statusList.push('CANCELED_DOCUMENT_BY_ADMIN')
    }
  }

  const roundList = []
  if (filter.roundChecked) {
    if (filter.round.noSendChecked) {
      roundList.push(0)
    }
    if (filter.round.firstChecked) {
      roundList.push(1)
    }
    if (filter.round.secondChecked) {
      roundList.push(2)
    }
  }

  const filterText = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
    startDate: filter.dateChecked
      ? dayjs(filter.startDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    endDate: filter.dateChecked
      ? dayjs(filter.endDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    status: filter.statusChecked ? statusList : [],
    round: filter.roundChecked ? roundList : [],
  }

  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
}

export const handleQuickSearch = (table, text, manageProps) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, text, manageProps))
}

export const handleQuickFilter =
  (item, index, filterProp) => async (dispatch) => {
    const quick = { ...item, clicked: !item.clicked }

    dispatch(setOnQuickFilterByIndex({ item: quick, index: index }))
    if (item.clicked === false) {
      const statusList = []
      if (item.field === 'WAITING_DOCUMENT') {
        statusList.push('WAITING_DOCUMENT')
      }
      if (item.field === 'WAITING_CONFIRM') {
        statusList.push('WAITING_CONFIRM')
      }
      if (item.field === 'WAITING_CHECK') {
        statusList.push('WAITING_CHECK')
      }
      if (item.field === 'REJECTED') {
        statusList.push('REJECTED')
      }
      if (item.field === 'INELIGIBLE') {
        statusList.push('INELIGIBLE')
      }
      if (item.field === 'PASS_CHECKED') {
        statusList.push('PASS_CHECKED')
      }
      if (item.field === 'CANCELED') {
        statusList.push('CANCELED_DOCUMENT')
      }
      if (item.field === 'CANCELED_DOCUMENT_BY_USER') {
        statusList.push('CANCELED_DOCUMENT_BY_USER')
      }
      if (item.field === 'CANCELED_DOCUMENT_BY_ADMIN') {
        statusList.push('CANCELED_DOCUMENT_BY_ADMIN')
      }
      const body = {
        ...filterBody,
        status: statusList,
      }

      if (filterProp === null) {
        dispatch(setFilterProp(body))
      } else {
        dispatch(
          setFilterProp({
            ...filterProp,
            status: [...filterProp.status, ...statusList],
          })
        )
      }
    } else {
      let convertField = ''
      if (item.field === 'WAITING_DOCUMENT') {
        convertField = 'WAITING_DOCUMENT'
      }
      if (item.field === 'WAITING_CONFIRM') {
        convertField = 'WAITING_CONFIRM'
      }
      if (item.field === 'WAITING_CHECK') {
        convertField = 'WAITING_CHECK'
      }
      if (item.field === 'REJECTED') {
        convertField = 'REJECTED'
      }
      if (item.field === 'INELIGIBLE') {
        convertField = 'INELIGIBLE'
      }
      if (item.field === 'PASS_CHECKED') {
        convertField = 'PASS_CHECKED'
      }
      if (item.field === 'CANCELED') {
        convertField = 'CANCELED_DOCUMENT'
      }
      if (item.field === 'CANCELED_DOCUMENT_BY_USER') {
        convertField = 'CANCELED_DOCUMENT_BY_USER'
      }
      if (item.field === 'CANCELED_DOCUMENT_BY_ADMIN') {
        convertField = 'CANCELED_DOCUMENT_BY_ADMIN'
      }
      const filterStatus = filterProp.status.filter(
        (item) => item != convertField
      )
      dispatch(
        setFilterProp({
          ...filterProp,
          status: filterStatus,
        })
      )
    }
  }
