import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

import { clearFilterCalendar } from './events'

const manageClassCalendar = createSlice({
  name: 'manageClassCalendar',
  initialState: initialState,
  reducers: {
    setChange: (state, { payload }) => {
      _.set(state, payload.key, payload.value)
    },
    openDrawer: (state) => {
      state.filterCalendar.isOpen = true
    },
    closeDrawer: (state) => {
      state.filterCalendar.isOpen = false
    },
    setChangeFiler: (state, { payload }) => {
      const { type, value } = payload
      const itemFilter = state.filterCalendar[type].find(
        (item) => item.value === value
      )
      const checked = _.get(itemFilter, 'checked', false)
      _.set(itemFilter, 'checked', !checked)
    },
    setDataCalendar: (state, { payload }) => {
      state.initialState = payload
    },
    setFetchCalendar: (state, { payload }) => {
      state.initialState = _.get(payload, 'listCalendar', [])
      state.filterCalendar.scheduleType = _.get(payload, 'scheduleType', [])
      state.filterCalendar.teamCalendar = _.get(payload, 'teamCalendar', [])
      state.filterCalendar.trainingCourse = _.get(payload, 'trainingCourse', [])
    },
    setClearFilterCalendar: (state) => {
      state.filterCalendar = {
        ...state.filterCalendar,
        scheduleType: clearFilterCalendar(
          _.get(state, 'filterCalendar.scheduleType', [])
        ),
        teamCalendar: clearFilterCalendar(
          _.get(state, 'filterCalendar.teamCalendar', [])
        ),
        trainingCourse: clearFilterCalendar(
          _.get(state, 'filterCalendar.trainingCourse', [])
        ),
      }
    },
  },
})

export const {
  setChange,
  openDrawer,
  closeDrawer,
  setChangeFiler,
  setFetchCalendar,
  setDataCalendar,
  setClearFilterCalendar,
} = manageClassCalendar.actions

export default manageClassCalendar.reducer
