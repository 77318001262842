import React from 'react'
import Popper from '@mui/material/Popper'
import styled from '@mui/system/styled'

export const StyledPopper = styled((props) => (
  <Popper role={undefined} transition disablePortal {...props} />
))(() => ({
  zIndex: 99,
  '& .MuiPaper-root': {
    borderRadius: 0,
    '& .MuiDivider-root': {
      margin: '4px 0 !important',
    },
  },
}))
