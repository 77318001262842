import styled from '@mui/system/styled'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const BoxCard = styled(Card)(({ theme }) => ({
  width: '50%',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% - 48px)',
  },
}))
