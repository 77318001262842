import { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import TablePagination from '@mui/material/TablePagination'
import { Row } from '../../../../../components/Layout/Styled'
import Select from '../../../../../components/Input/Select'
import { StyledDrawer, StyledHeadLabel } from '../../Styled'
import { toggleRoomRequestDrawer } from '../../handler/handleClickOpenDrawer'
import { fetchRoomRequest } from '../../handler/fetchRoomRequest'
import { StyledLoadingBg, LoadingCircular } from '../../Styled'
import { setFieldValue } from '../../../../../redux/slices/crud'
import Table from './Table'
import { attendeeTableOrderOptions, ROW_PAGE_PER_LIST } from '../../enum/enum'
import {
  handleChangePage,
  handleChangeRowsPerPage,
  handleLabelDisplay,
} from './event'

const RoomRequestDrawer = () => {
  const dispatch = useDispatch()
  const { open, isLoading, page, order, totalCount, rowsPerPage } = useSelector(
    (state) => ({
      open: state.crud.roomManagementDashboard?.roomRequestDrawer?.isOpen,
      isLoading:
        state.crud.roomManagementDashboard?.roomRequestDrawer?.isLoading,
      page: state.crud.roomManagementDashboard?.roomRequestDrawer?.filter?.page,
      order:
        state.crud.roomManagementDashboard?.roomRequestDrawer?.filter?.order,
      totalCount:
        state.crud.roomManagementDashboard?.roomRequestDrawer?.filter
          ?.totalCount,
      rowsPerPage:
        state.crud.roomManagementDashboard?.roomRequestDrawer?.filter
          ?.rowsPerPage,
    }),
    shallowEqual,
  )
  useEffect(() => {
    dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.roomRequestDrawer.filter.order',
        value: 'desc',
      }),
    )
    dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.roomRequestDrawer.filter.rowsPerPage',
        value: { label: '10', value: 10 },
      }),
    )
    dispatch(
      setFieldValue({
        key: 'roomManagementDashboard.roomRequestDrawer.filter.page',
        value: 1,
      }),
    )
    return () =>
      dispatch(
        setFieldValue({
          key: 'roomManagementDashboard.roomRequestDrawer.filter.page',
          value: 0,
        }),
      )
  }, [])
  useEffect(() => {
    if (open && page > 0) {
      fetchRoomRequest()
    }
  }, [open, page, order, rowsPerPage])
  return (
    <StyledDrawer open={open} onClose={() => toggleRoomRequestDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">รายละเอียดการจองห้องอบรม</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleRoomRequestDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Card sx={{ mb: 2, mx: 3 }}>
        <Row sx={{ justifyContent: 'end', p: '20px 20px 24px 0' }}>
          <Typography variant="body1b" mr={2}>
            เรียงตาม
          </Typography>
          <Select
            boxSx={{ width: 250 }}
            selectSx={{
              background: '#FFFFFF',
              borderRadius: 1,
            }}
            id="attendee-order"
            name="attendee-order"
            placeholder="กรุณาเลือก"
            options={attendeeTableOrderOptions}
            showTextError={false}
            value={order || 'desc'}
            defaultValue=""
            handleChange={(e) => {
              const value = _.get(e, 'target.value', '')
              dispatch(
                setFieldValue({
                  key: 'roomManagementDashboard.roomRequestDrawer.filter.order',
                  value: value,
                }),
              )
            }}
          />
        </Row>
        <Divider />
        <Table />
        <Divider />
        <TablePagination
          rowsPerPageOptions={ROW_PAGE_PER_LIST}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage?.value || 10}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => handleLabelDisplay()}
          page={page - 1 || 0}
          onPageChange={(e, tPage) => handleChangePage(tPage)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      </Card>
      <StyledLoadingBg isLoading={isLoading}>
        <LoadingCircular isLoading={isLoading} />
      </StyledLoadingBg>
    </StyledDrawer>
  )
}

export default RoomRequestDrawer
