export const FILE_TYPE = {
  DOC: 'DOC',
  DOCX: 'DOCX',
  PPT: 'PPT',
  PPTX: 'PPTX',
  PDF: 'PDF',
  ZIP: 'ZIP',
}

export const DEFAULT_FILE_TYPE = [
  FILE_TYPE.DOC,
  FILE_TYPE.DOCX,
  FILE_TYPE.PPT,
  FILE_TYPE.PPTX,
  FILE_TYPE.PDF,
  FILE_TYPE.ZIP,
]
