import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Link as ReactRouterLink } from 'react-router-dom'

const Index = ({ sx, menuList = [] }) => {
  return (
    <Box sx={{ ...sx }}>
      <Breadcrumbs aria-label="breadcrumb">
        {menuList &&
          menuList.length > 0 &&
          menuList.map((menu, i) => {
            let colors = 'common.black'
            if (i === 0) colors = 'inherit'
            return menu.link !== '/' && menu.pointer ? (
              <Link
                key={i}
                underline="hover"
                color="inherit"
                to={menu.link}
                component={ReactRouterLink}
              >
                {menu.title}
              </Link>
            ) : (
              <Typography key={i} color={colors} sx={{ cursor: 'default' }}>
                {menu.title}
              </Typography>
            )
          })}
      </Breadcrumbs>
    </Box>
  )
}

export default Index
