import React from 'react'
import * as slicesDialog from '../../../../../redux/slices/dialog'
import ContentDialog from '../TableDetail/ContentDialog'
import { setChange } from '../../../../../redux/slices/manageClassLearnerDetail'

export const mapStatus = {
  ELIGIBLE_ANNOUNCE: {
    label: 'ประกาศสิทธิ์แล้ว',
    color: 'success.main',
    isBorder: false,
  },
  WAITING_ANNOUNCE: {
    label: 'รอประกาศสิทธิ์',
    color: 'warning.main',
    isBorder: false,
  },
  WAITING_DOCUMENT: {
    label: 'รอเอกสาร',
    color: 'silver.primary',
    isBorder: false,
  },
  WAITING_CHECK: {
    label: 'รอตรวจสอบเอกสาร',
    color: 'silver.primary',
    isBorder: false,
  },
  WAITING_CONFIRM: {
    label: 'รอคอนเฟิร์มตัวแทน',
    color: 'silver.primary',
    isBorder: false,
  },
  REJECTED_DOCUMENT: {
    label: 'เอกสารไม่ผ่าน',
    color: 'error.main',
    isBorder: false,
  },
  CANCEL: {
    label: 'ยกเลิกการสมัคร',
    color: 'unset',
    isBorder: true,
  },
  CANCELED: {
    label: 'ถูกยกเลิกการสมัคร',
    color: 'unset',
    isBorder: true,
  },
  ABSENT: {
    label: 'ขาดเรียน',
    color: 'error.main',
    isBorder: false,
  },
}

export const handlePassTraining = (body, isPass) => (dispatch) => {
  const titleText = isPass ? 'ไม่ให้ผ่านการอบรม' : 'ให้ผ่านการอบรม'
  const content = isPass
    ? 'กรุณากรอกเหตุผลที่ต้องการให้ระบบปรับสถานะการเข้าเรียนและผลสอบเป็นไม่ผ่านทั้งหมด'
    : 'กรุณากรอกเหตุผลที่ต้องการให้ระบบปรับสถานะการเข้าเรียนและผลสอบเป็นผ่านทั้งหมด'
  const type = isPass ? 'NOT_PASS_TRAINING' : 'PASS_TRAINING'
  dispatch(
    slicesDialog.openDialog({
      type: 'mini',
      maxWidth: 'sm',
      disableButton: true,
      content: (
        <ContentDialog
          body={body}
          title={titleText}
          contentText={content}
          dialogType={type}
        />
      ),
      handleCancel: () => dispatch(clearRemark()),
    })
  )
}

export const clearRemark = () => (dispatch) => {
  dispatch(
    setChange({
      key: 'remark',
      value: '',
    })
  )
}
