export const ECONTENT_TYPE_TH_TEXT = {
  E_CONTENT: 'บทความ',
  KB: 'สาระน่ารู้',
}

export const CONTENT_TYPE = {
  TEXT: 'TEXT',
  LINK: 'LINK',
  IMAGE: 'IMAGE',
  FILE: 'FILE',
  KB: 'KB',
}
