import { store } from '../../../../../../App'
import { overviewEnum } from '../enums/drawerEnum'
import _ from 'lodash'

export const courseEvaPartModel = () => {
  const { eEvaluationDashboard } = store.getState().crud
  const courseEva =
    eEvaluationDashboard?.overview?.courseEva || overviewEnum?.courseEva
  const countAnswer = _.defaultTo(courseEva.answers, []).length
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: _.get(courseEva, 'attendeeCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: countAnswer > 0 ? _.get(courseEva, 'respondentCount', 0) : 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${_.get(courseEva, 'overallPercentage', 0)} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}

export const courseManagePartModel = () => {
  const { eEvaluationDashboard } = store.getState().crud
  const courseManageEva =
    eEvaluationDashboard?.overview?.courseManageEva ||
    overviewEnum?.courseManageEva
  const countAnswer = _.defaultTo(courseManageEva.answers, []).length
  return [
    {
      label: 'จำนวนผู้เข้าอบรม',
      labelVariant: 'body2',
      number: _.get(courseManageEva, 'attendeeCount', 0),
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'จำนวนผู้ทำแบบประเมิน',
      labelVariant: 'body2',
      background: '#4976BA14',
      number:
        countAnswer > 0 ? _.get(courseManageEva, 'respondentCount', 0) : 0,
      hidePercent: true,
      unit: 'คน',
      width: '200px',
      hideClick: true,
    },
    {
      label: 'ภาพรวมที่ได้',
      labelVariant: 'body2',
      background: '#4976BA14',
      number: `${_.get(courseManageEva, 'overallPercentage', 0)} %`,
      isString: true,
      hidePercent: true,
      unit: '',
      value: 'ALL',
      type: 'Prospect',
      width: '200px',
      hideClick: true,
    },
  ]
}
