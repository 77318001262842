import React from 'react'
import _ from 'lodash'
import { InputAdornment, Typography } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import useTheme from '@mui/system/useTheme'
import { StyledRow } from '../Styled'
import TextInput from '../../../../../components/Input/TextInput'
import {
  handleNumberInput,
  isMediaWidthMoreThan,
} from '../../../../../utils/lib'
import { handleChangeNumberInput } from '../event'
import { formattedNumberToString } from '../../event'

const Index = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { errors } = useFormikContext()
  const { sqm, width, length, height } = useSelector(
    (state) => state.masterDataMeetingRoomForm.information,
    shallowEqual,
  )
  const isNotSm = !isMediaWidthMoreThan('sm')

  return (
    <StyledRow isMobile={isNotSm}>
      <TextInput
        required
        type="basic"
        name="sqm"
        labelText={'ขนาดห้อง'}
        placeholder="กรุณากรอก"
        value={formattedNumberToString(sqm) || sqm}
        startAdornment={
          <InputAdornment
            sx={{ position: 'relative', right: 0 }}
            position="start"
          >
            ตารางเมตร
          </InputAdornment>
        }
        textError={_.get(errors, `information.sqm`, '')}
        inputProps={{
          type: 'text',
          onInput: (e) => handleNumberInput(e),
        }}
        onChange={(e) => {
          dispatch(handleChangeNumberInput(e, 'sqm'))
        }}
      />
      <StyledRow
        sx={{
          alignItems: !isNotSm ? 'stretch' : 'flex-start',
          flexDirection: !isNotSm ? 'row' : 'column',
          gap: isNotSm ? 0 : theme.spacing(3),
        }}
      >
        <TextInput
          boxSx={isNotSm ? { width: 'auto' } : {}}
          type="basic"
          name="width"
          labelText={'กว้าง (เมตร)'}
          placeholder="กรุณากรอก"
          value={formattedNumberToString(width) || width}
          inputProps={{
            type: 'text',
            onInput: (e) => handleNumberInput(e),
          }}
          onChange={(e) => {
            dispatch(handleChangeNumberInput(e, 'width'))
          }}
          sxTextError={!isNotSm ? { mb: 3 } : {}}
          textError={_.get(errors, `information.width`, '')}
        />
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: !isNotSm ? '90px' : 'auto',
          }}
          variant="body1"
        >
          X
        </Typography>

        <TextInput
          boxSx={isNotSm ? { width: 'auto' } : {}}
          type="basic"
          name="length"
          labelText={'ยาว (เมตร)'}
          placeholder="กรุณากรอก"
          value={formattedNumberToString(length) || length}
          inputProps={{
            type: 'text',
            onInput: (e) => handleNumberInput(e),
          }}
          onChange={(e) => {
            dispatch(handleChangeNumberInput(e, 'length'))
          }}
          sxTextError={!isNotSm ? { mb: 3 } : {}}
          textError={_.get(errors, `information.length`, '')}
        />
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: !isNotSm ? '90px' : 'auto',
          }}
          variant="body1"
        >
          X
        </Typography>

        <TextInput
          boxSx={isNotSm ? { width: 'auto' } : {}}
          type="basic"
          name="height"
          labelText={'สูง (เมตร)'}
          placeholder="กรุณากรอก"
          value={formattedNumberToString(height) || height}
          inputProps={{ type: 'text', onInput: (e) => handleNumberInput(e) }}
          onChange={(e) => {
            dispatch(handleChangeNumberInput(e, 'height'))
          }}
          sxTextError={!isNotSm ? { mb: 3 } : {}}
          textError={_.get(errors, `information.height`, '')}
        />
      </StyledRow>
    </StyledRow>
  )
}

export default Index
