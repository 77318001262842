import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eLearningModule, eLearningModuleHistory } from '../../../utils/apiPath'

export const getDetail = createAsyncThunk(
  'eLearningModuleDetail/getDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningModule}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getModuleHistory = createAsyncThunk(
  'eLearningModuleDetail/getModuleHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningModuleHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)