import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import { setReportConfig } from '../../../redux/slices/reportComp'
import Report from '../../../components/Report'
import { reportConfigAll, reportConfigPic } from './reportConfig'

const Index = () => {
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  useEffect(() => {
    const permissionList = _.get(user, 'permission', [])
    const hasPermissionALL = _.find(permissionList, (item) => {
      return (
        item.owner === 'ALL' &&
        item.permission === 'DASHBOARD_REPORT' &&
        item.module === 'REPORT_ROOM_MANAGEMENT'
      )
    })
    dispatch(
      setReportConfig({
        reportConfig: hasPermissionALL ? reportConfigAll : reportConfigPic,
      }),
    )
  }, [])

  return (
    <>
      <Report user={user} />
    </>
  )
}
export default Index
