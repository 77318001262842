import { handleMessage } from '../../../modules/MonthlyPlan/Drawer/Handle'
import _ from 'lodash'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  openSuccessDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { cancelBorrow, putApproveBorrow } from '../../../services/speaker'
import { approveStatus } from '../../../constants/monthlyPlan/speaker'

export const handleCancelRequest = (cancelProps) => (dispatch) => {
  dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันยกเลิกคำขอ',
      message: 'คุณต้องการยกเลิกคำขอยืมวิทยากรนี้หรือไม่',
      handleConfirm: () => dispatch(handleConfirmCancel(cancelProps)),
    })
  )
}

export const handleConfirmCancel = (cancelProps) => async (dispatch) => {
  const { uuid, handleFetchData, onClose } = cancelProps

  dispatch(loadingDialog())
  const res = await dispatch(cancelBorrow(uuid))
  const payload = _.get(res, 'payload', {})
  dispatch(stopLoadingDialog())

  if (payload.status === 200) {
    dispatch(
      openSuccessDialog({
        handleConfirm: () => {
          handleFetchData()
          onClose()
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(handleError(res))
  }
}

export const handleError = (res) => (dispatch) => {
  const meta = _.get(res, 'meta', {})
  const message = _.get(meta, 'response.data.message', '')
  const constraintsKey = _.get(meta, 'response.data.constraints.key', '')
  const title =
    constraintsKey === 'IsDateAvailableConstraint'
      ? 'เพิ่มตารางงานไม่ได้ มีกิจกรรมอื่นในช่วงเวลาเดียวกัน'
      : constraintsKey === 'IsValidateStatus'
      ? _.get(meta, 'response.data.constraints.title', '')
      : 'ผิดพลาด'
  const content =
    constraintsKey === 'IsDateAvailableConstraint'
      ? handleMessage({
          listError: _.get(meta, 'response.data.constraints.value', []),
          text: 'วิทยากรและผู้เข้าร่วมประชุม',
        })
      : undefined
  const dialogMessage =
    constraintsKey === 'IsValidateStatus'
      ? _.get(meta, 'response.data.constraints.value', '')
      : message
  dispatch(
    openDialog({
      type: 'fail',
      title,
      message:
        constraintsKey !== 'IsDateAvailableConstraint'
          ? dialogMessage
          : undefined,
      content,
    })
  )
}

export const handleApproveBorrow = (submitProps) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันบันทึกข้อมูล',
      message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
      agreeText: 'บันทึก',
      handleConfirm: () => dispatch(handleConfirmApprove(submitProps)),
    })
  )
}

export const handleConfirmApprove = (submitProps) => async (dispatch) => {
  const { data, approveList, handleFetchData, onClose } = submitProps

  dispatch(loadingDialog())
  const isApproved = approveList.some(
    (approve) => approve.status === approveStatus.APPROVE
  )
  const body = {
    uuid: _.get(data, 'uuid', ''),
    speakerBorrowResponsibilitys: approveList,
    status: isApproved ? approveStatus.APPROVE : approveStatus.REJECT,
  }
  const res = await dispatch(putApproveBorrow(body))
  const payload = _.get(res, 'payload', {})
  dispatch(stopLoadingDialog())

  if (payload.status === 200) {
    dispatch(
      openSuccessDialog({
        handleConfirm: () => {
          handleFetchData()
          onClose()
          dispatch(closeDialog())
        },
      })
    )
  } else {
    dispatch(handleError(res))
  }
}
