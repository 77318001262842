import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { StyledCard } from './Styled'
import { handleInitialForm, handleSettingRadioChange } from './events'
import { initialDocumentation } from '../DefaultValue'
import DocumentForm from './DocumentForm'

const DocumentSetting = ({ initialValues, setInitialValues, formik }) => {
  const [documentation, setDocumentation] = useState(initialDocumentation)
  const requireDocument = useMemo(
    () => _.get(documentation, 'requireDocument', false),
    [documentation]
  )

  useEffect(() => {
    handleInitialForm(initialValues, setDocumentation, formik)
  }, [initialValues])

  return (
    <StyledCard>
      <Typography variant="h6">เอกสารการสมัคร</Typography>
      <RadioGroup
        data-testid="radio-setCriteria"
        sx={{ pl: 1.5, gap: 0.5, marginTop: '20px' }}
        aria-label="setCriteria"
        name="setCriteria"
        value={requireDocument ? 'yes' : 'no'}
        onChange={(e) => handleSettingRadioChange(e, setInitialValues)}
      >
        <FormControlLabel value="no" control={<Radio />} label="ไม่ต้องการ" />
        <FormControlLabel value="yes" control={<Radio />} label="ต้องการ" />
      </RadioGroup>
      {requireDocument && (
        <DocumentForm
          documentation={documentation}
          formik={formik}
          setInitialValues={setInitialValues}
        />
      )}
    </StyledCard>
  )
}

export default DocumentSetting
