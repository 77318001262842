import { store } from '../../../../../App'
import { STATUS, STATUS_ITEMS } from '../model/status'
import { getFormatDate } from '../../../../../utils/lib'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  const formatDate = 'MM/DD/YYYY'
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  let statusItems = []
  if (filterState.statusItemsChecked) {
    if (filterState.statusItems.normalChecked) {
      statusItems.push(STATUS_ITEMS.NORMAL)
    }
    if (filterState.statusItems.lowChecked) {
      statusItems.push(STATUS_ITEMS.LOW)
    }
  } else {
    statusItems = []
  }

  return {
    totalFrom: filterState.totalChecked ? filterState.totalFrom : '',
    totalTo: filterState.totalChecked ? filterState.totalTo : '',
    availableFrom: filterState.availableChecked
      ? filterState.availableFrom
      : '',
    availableTo: filterState.availableChecked ? filterState.availableTo : '',
    unitPriceFrom: filterState.unitPriceChecked
      ? filterState.unitPriceFrom
      : '',
    unitPriceTo: filterState.unitPriceChecked ? filterState.unitPriceTo : '',
    refundYear: filterState.refundChecked ? filterState.refundYear : '',
    refundMonth: filterState.refundChecked ? filterState.refundMonth : '',
    refundDay: filterState.refundChecked ? filterState.refundDay : '',
    contractStartDate: filterState.contractDateChecked
      ? getFormatDate(filterState.contractStartDate, formatDate)
      : '',
    contractEndDate: filterState.contractDateChecked
      ? getFormatDate(filterState.contractEndDate, formatDate)
      : '',
    renewContractStartDate: filterState.renewContractDate
      ? getFormatDate(filterState.renewContractStartDate, formatDate)
      : '',
    renewContractEndDate: filterState.renewContractDate
      ? getFormatDate(filterState.renewContractEndDate, formatDate)
      : '',
    startUpdatedDate: filterState.updatedDateChecked
      ? getFormatDate(filterState.startUpdatedDate, formatDate)
      : '',
    endUpdatedDate: filterState.updatedDateChecked
      ? getFormatDate(filterState.endUpdatedDate, formatDate)
      : '',
    holder: filterState.holderChecked ? filterState.holder : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    statusItems: statusItems,
    status: status,
    startCreatedDate: filterState.createdDateChecked
      ? getFormatDate(filterState.startCreatedDate, formatDate)
      : '',
    endCreatedDate: filterState.createdDateChecked
      ? getFormatDate(filterState.endCreatedDate, formatDate)
      : '',
  }
}
