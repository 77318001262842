import React from 'react'
import Box from '@mui/material/Box'
import { StyledContainerCourse } from './Styled'

export const ContainerScrollX = ({ children }) => {
  return (
    <Box sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' } }}>
      <StyledContainerCourse>{children}</StyledContainerCourse>
    </Box>
  )
}

export default ContainerScrollX
