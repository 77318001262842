import React, { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Select from '../../../../../components/Input/Select'
import TextInput from '../../../../../components/Input/TextInput'
import { styledTextInput } from '../Styled'
import { initialResponsibility } from '../model'
import {
  findOptionStation,
  formatDate,
  handleApproverShow,
  handleDepartmentUuid,
  handleFetchHeadZone,
} from './events'
import { StyledBoxFlex, styledSelect } from './Styled'

const StepOne = ({ open, optionDepartment, purposeValidate }) => {
  const { values, setValues, handleChange } = useFormikContext()
  const startDate = formatDate(values, 'startDate')
  const finishDate = formatDate(values, 'finishDate')
  const departmentUuid = useMemo(() => handleDepartmentUuid(values), [values])
  const optionStation = useMemo(
    () => findOptionStation(optionDepartment, departmentUuid),
    [departmentUuid]
  )
  const station = _.get(values, 'station', '')

  useEffect(() => {
    if (open) handleFetchHeadZone({ station, optionStation, setValues })
  }, [open, station])

  return (
    <StyledBoxFlex sx={{ flexDirection: 'column' }}>
      <StyledBoxFlex>
        <LabelNoInput label="ตั้งแต่วันที่" data={startDate} />
        <LabelNoInput label="ถึงวันที่" data={finishDate} />
      </StyledBoxFlex>
      <StyledBoxFlex>
        <Select
          boxSx={styledSelect}
          dataTestid="select-department"
          type="basic"
          name="department"
          labelText="Department"
          placeholder="เลือก Department"
          showTextError={false}
          required
          options={optionDepartment}
          value={departmentUuid}
          handleChange={(e) =>
            setValues((prev) => ({
              ...prev,
              department: optionDepartment.find(
                (item) => item.uuid === e.target.value
              ),
              station: '',
              approver: [],
              speakerBorrowResponsibility: [
                {
                  ...initialResponsibility,
                  startTime: _.get(values, 'startTime', ''),
                  endTime: _.get(values, 'endTime', ''),
                },
              ],
            }))
          }
        />
        <Select
          boxSx={styledSelect}
          dataTestid="select-station"
          type="basic"
          name="station"
          labelText="Station"
          placeholder="เลือก Station"
          showTextError={false}
          required
          options={optionStation}
          value={_.get(values, 'station', '')}
          handleChange={handleChange}
        />
      </StyledBoxFlex>
      <LabelNoInput label="ผู้อนุมัติ" data={handleApproverShow(values)} />
      <TextInput
        sx={styledTextInput}
        type="basic"
        id="purpose"
        name="purpose"
        labelText="จุดประสงค์ของการยืม"
        placeholder="กรุณากรอกจุดประสงค์ของการยืม"
        rows={3}
        multiline
        required
        value={_.get(values, 'purpose', '')}
        onChange={handleChange}
        textError={purposeValidate}
      />
    </StyledBoxFlex>
  )
}

export default StepOne

export const LabelNoInput = ({ label, data }) => {
  return (
    <StyledBoxFlex sx={{ flexDirection: 'column', width: '50%' }}>
      <Typography variant="body2b" color="text.secondary">
        {label}
      </Typography>
      <Typography>{data}</Typography>
    </StyledBoxFlex>
  )
}
