import _ from 'lodash'
import { setChangeBody } from '../../../../../../redux/slices/manageContent/home'

export const handleCourseOption = (courseCardProps) => {
  const { courses, levelDistribution, optionCourses, optionCoursesEL } =
    courseCardProps

  const levelDistributionObj = courses.find(
    (item) => item.uuid == levelDistribution,
  )
  const distributionUuid = _.get(levelDistributionObj, 'distribution.uuid', '')
  const levelUuid = _.get(levelDistributionObj, 'level.uuid', '')

  const optionC = optionCourses.filter((item) => {
    const courseVersion = _.get(item, 'courseVersion', {})
    const uuid = _.get(courseVersion, 'distribution.uuid', '')
    const levelOfLearner = _.get(courseVersion, 'levelOfLearner', [])
    return (
      uuid == distributionUuid &&
      levelOfLearner.some((e) => e.uuid == levelUuid)
    )
  })

  const optionCourseEL = _.filter(optionCoursesEL, (item) => {
    const levelOfLearner = _.get(item, 'levelOfLearner', [])
    const distributionList = _.get(item, 'setting.distribution', [])

    return (
      distributionList.some((e) => e.value == distributionUuid) &&
      levelOfLearner.some((e) => e.uuid == levelUuid)
    )
  })

  const optionAll = [...optionC, ...optionCourseEL]
  return optionAll
}

export const handleDefaultCourse = (data) => {
  const course = _.get(data, 'course', undefined)

  if (_.isEmpty(course) || course.uuid == '') return { label: '', value: '' }
  const nameCoures =
    _.get(course, 'englishName', data.englishName) || _.get(course, 'name', '')
  const label = `${course.courseCode} ${nameCoures}`
  const value = course.uuid
  return { label, value }
}

export const handleInputTabBody = (e, keyField) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')

  const payload = { value, key: `${keyField}.${key}` }
  dispatch(setChangeBody(payload))
}

export const handleSwitchTabBody = (e, keyField) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  const payload = { value: checked, key: `${keyField}.${key}` }
  dispatch(setChangeBody(payload))
}

export const handleSelectTabBody = (e, keyField, option) => (dispatch) => {
  const uuid = _.get(e.target, 'id', '')
  const value = option.find((item) => item.value == uuid)
  const payload = {
    value,
    key: `${keyField}.course`,
  }
  dispatch(setChangeBody(payload))
}

export const handleMultiSelectTabBody = (list, no) => (dispatch) => {
  const payload = {
    value: list,
    key: `coursesInformation.taps[${no - 1}].distributionChannels`,
  }
  dispatch(setChangeBody(payload))
}

export const handleSwitchCourseBody = (e, keyField) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const checked = _.get(e.target, 'checked', '')
  const payload = { value: checked, key: `${keyField}.${name}` }
  dispatch(setChangeBody(payload))
}
